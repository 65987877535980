import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Container, ListItemButton, ListItemText } from '@mui/material';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import * as React from 'react';
import PropTypes from 'prop-types';


export default function ListItemWithIcon({
  list,
  level,
  onChange,
  isNotShowIcon,
  selectedIndex,
  disabledItems,
  singleSelectionMode
}) {

  const handleClick = (value, level, index) => {
    onChange(value, level, index);
  };

  return (
    <Container sx={{
      display: 'flex',
      flex: '1 1 0%',
      flexDirection: 'column',
      padding: '0 !important',
      border: 'solid 1px #d5d8dd',
      maxHeight: '20rem',
      overflowY: 'scroll',
      overflowX: 'hidden'
    }}
    >
      <List sx={{ width: '100%', padding: '0' }}>
        {list?.length > 0 &&
          list.map((value, index) => (
            <ListItemButton
              sx={{ display: 'flex', justifyContent: 'space-between', paddingRight: '4px' }}
              className="icon-list-item"
              key={index + value.label} // eslint-disable-line
              button
              selected={index === selectedIndex}
              disabled={singleSelectionMode || disabledItems.includes(value.key)}
              onClick={() => {
                handleClick(value, level, index);
              }}
            >
              <ListItemText primary={value.label} sx={{ margin: 0 }} />
              {!isNotShowIcon && (value.children?.length > 0) && (
              <ListItemIcon sx={{ padding: 0 }}>
                <KeyboardArrowRightIcon sx={{ width: '1.5rem', height: '1.5rem' }} />
              </ListItemIcon>
              )}
            </ListItemButton>
          ))}
      </List>
    </Container>
  );
}

ListItemWithIcon.propTypes = {
  list: PropTypes.array.isRequired,
  level: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  isNotShowIcon: PropTypes.bool,
  selectedIndex: PropTypes.number,
  disabledItems: PropTypes.array,
  singleSelectionMode: PropTypes.bool,
};

ListItemWithIcon.defaultProps = {
  isNotShowIcon: false,
  selectedIndex: null,
  disabledItems: [],
  singleSelectionMode: false,
};
