import React from 'react';
import { Card, CardBody } from 'reactstrap';
import { Button } from '@mui/material';
import PageComponent from '../components/PageComponent';
import { t } from '../i18n/t';
import MContext from '../models/MContext';
import DateRangePicker from '../components/DateRangePicker';
import ProfileCoverageTestRun from './test_reports/test_run_report/ProfileCoverageTestRun';
import { IconRefresh } from '../images/CustomIcon';
import TestResultProfileChart from './test_reports/test_run_report/TestResultProfileChart';
import ProfileFilter from '../components/search-query/filter/ProfileFilter';
import ExecutionTrendTestRun from './test_reports/test_run_report/ExecutionTrendTestRun';
import ExecutionTrendTestResult from './test_reports/test_run_report/ExecutionTrendTestResult';
import PlatformCoverageChart from './test_reports/test_run_report/PlatformCoverageChart';
import { getDefaultSearchConditions, allProfilesFilterOption } from '../pages/test_reports/test_run_report/utils';
import DateHelper from '../../app/utils/DateHelper';
import GroupEvent from '../utils/track/GroupEvent';

class OverviewReports extends PageComponent {

  constructor(props) {
    super(props);
    this.meta.id = 'page-executions-overview';
    this.projectId = MContext.projectId;
    this.project = MContext.project;
    this.meta.title = t(
      'Project {{name}} - {{page}}',
      { name: this.project.name, page: t('executions_overview_title') },
    );

    this.state = {
      filterDateTimeValue: { startTime: null, endTime: null },
      executionProfile: allProfilesFilterOption
    };

    this.renderBody = this.renderBody.bind(this);
    this.renderFilter = this.renderFilter.bind(this);
    this.renderProfileCoverage = this.renderProfileCoverage.bind(this);
    this.renderExecutionTrend = this.renderExecutionTrend.bind(this);
    this.renderPlatformCoverage = this.renderPlatformCoverage.bind(this);
    this.renderButtonApplyFilter = this.renderButtonApplyFilter.bind(this);
    this.handleDateFilterChange = this.handleDateFilterChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.refreshAllCharts = this.refreshAllCharts.bind(this);
    this.profileCoverageTestRunChartRef = React.createRef();
    this.profileCoverageTestResultChartRef = React.createRef();
    this.executionTrendTestRunChartRef = React.createRef();
    this.executionTrendTestResultChartRef = React.createRef();
    this.platformCoverageChartRef = React.createRef();
  }

  // handleChange when user input date filter value
  // value will be save in the form of object { startTime: Moment Object, endTime: Moment Object }
  handleDateFilterChange(changedDateTimeValue) {
    this.setState({
      filterDateTimeValue: changedDateTimeValue
    });
  }

  componentDidMount() {
    this.setState({
      filterDateTimeValue: DateHelper.getLast6Months()
    });
  }

  refreshAllCharts() {
    if (this.profileCoverageTestResultChartRef.current) {
      this.profileCoverageTestResultChartRef.current.clearCacheAndRefreshData();
    }
    if (this.profileCoverageTestRunChartRef.current) {
      this.profileCoverageTestRunChartRef.current.clearCacheAndRefreshData();
    }
    if (this.executionTrendTestResultChartRef.current) {
      this.executionTrendTestResultChartRef.current.clearCacheAndRefreshData();
    }
    if (this.executionTrendTestRunChartRef.current) {
      this.executionTrendTestRunChartRef.current.clearCacheAndRefreshData();
    }
    if (this.platformCoverageChartRef.current) {
      this.platformCoverageChartRef.current.clearCacheAndRefreshData();
    }
  }

  renderButtonApplyFilter() {
    return (
      <Button
        onClick={this.refreshAllCharts}
        id="refresh-btn"
        title={t('refresh')}
        className="btn-secondary"
        startIcon={<IconRefresh />}
        data-trackid="refresh-executions-overview"
        data-groupid={GroupEvent.ACCESS_REPORT}
      >
        {t('refresh')}
      </Button>
    );
  }

  handleSelectChange(event, option) {
    this.setState({
      executionProfile: option
    });
  }

  renderFilter() {
    return (
      <div className="d-flex mt-2">
        <ProfileFilter allProfiles={allProfilesFilterOption} handleSelectChange={this.handleSelectChange} />
        <div className="ml-2">
          <DateRangePicker value={DateHelper.getLast6Months()} handleChange={this.handleDateFilterChange} />
        </div>
        <div className="ml-auto">
          {this.renderButtonApplyFilter()}
        </div>
      </div>
    );
  }

  getSearchConditions(profileKey) {
    const { filterDateTimeValue, executionProfile } = this.state;
    return getDefaultSearchConditions(profileKey, executionProfile.value, filterDateTimeValue.startTime, filterDateTimeValue.endTime);
  }

  renderProfileCoverage() {
    return (
      <>
        <div className="overview-header-title">{t('profile-coverage-title')}</div>
        <div className="cards-row">
          {this.renderProfileCoverageTestRun()}
          {this.renderProfileCoverageTestResult()}
        </div>
      </>
    );
  }

  renderProfileCoverageTestResult() {
    const defaultSearchConditions = this.getSearchConditions('profile');
    return (
      <TestResultProfileChart
        dataLoaderRef={this.profileCoverageTestResultChartRef}
        defaultSearchConditions={defaultSearchConditions}
      />
    );
  }

  renderProfileCoverageTestRun() {
    const defaultSearchConditions = this.getSearchConditions('ExecutionTestResult.profile');
    return (<ProfileCoverageTestRun dataLoaderRef={this.profileCoverageTestRunChartRef} defaultSearchConditions={defaultSearchConditions} />);
  }

  renderExecutionTrendTestRun() {
    const { filterDateTimeValue } = this.state;
    const defaultSearchConditions = this.getSearchConditions('ExecutionTestResult.profile');
    return (
      <ExecutionTrendTestRun
        filterDateTimeValue={filterDateTimeValue}
        dataLoaderRef={this.executionTrendTestRunChartRef}
        defaultSearchConditions={defaultSearchConditions}
      />
    );
  }

  renderExecutionTrend() {
    return (
      <>
        <div className="overview-execution-trend">{t('execution-trend-title')}</div>
        <div className="cards-row">
          {this.renderExecutionTrendTestRun()}
          {this.renderExecutionTrendTestResult()}
        </div>
      </>
    );
  }

  renderExecutionTrendTestResult() {
    const { filterDateTimeValue } = this.state;
    const defaultSearchConditions = this.getSearchConditions('profile');
    return (
      <ExecutionTrendTestResult
        dataLoaderRef={this.executionTrendTestResultChartRef}
        defaultSearchConditions={defaultSearchConditions}
        filterDateTimeValue={filterDateTimeValue}
      />
    );
  }

  renderPlatformCoverage() {
    const { filterDateTimeValue, executionProfile } = this.state;
    return (
      <PlatformCoverageChart
        dataLoaderRef={this.platformCoverageChartRef}
        profile={executionProfile.value}
        startTime={filterDateTimeValue.startTime}
        endTime={filterDateTimeValue.endTime}
      />
    );
  }

  renderBody() {
    return (
      <Card className="overview-card">
        <CardBody>
          {this.renderFilter()}
          {this.renderProfileCoverage()}
          {this.renderExecutionTrend()}
          {this.renderPlatformCoverage()}
        </CardBody>
      </Card>
    );
  }

  render() {
    return this.renderBody();
  }
}

export default OverviewReports;
