import React from 'react';
import Slider from '@mui/material/Slider';
import { t } from '../../../i18n/t';

export const THRESHOLD_GAP = 25;

const ThresholdSlider = ({ disabled = false, value, onChange, sx }) => (
  <Slider
    disabled={disabled}
    value={value}
    valueLabelDisplay="auto"
    step={THRESHOLD_GAP}
    marks={[
      {
        value: 0,
        label: t('visual-testing#pixel-sensitivity#strict')
      },
      {
        value: 25,
        label: ''
      },
      {
        value: 50,
        label: ''
      },
      {
        value: 75,
        label: ''
      },
      {
        value: 100,
        label: t('visual-testing#pixel-sensitivity#relaxed')
      }
    ]}
    min={0}
    max={100}
    sx={{
      '& .MuiSlider-markLabel': {
        color: '#808B9A'
      },
      '& .MuiSlider-markLabel:nth-child(4)': {
        paddingLeft: '2.143rem'
      },
      '& .MuiSlider-markLabel:nth-child(12)': {
        paddingRight: '2.714rem'
      },
      ...sx
    }}
    size="small"
    onChange={(e, value) => {
      if (onChange) {
        onChange(value);
      }
    }}
  />
);


export default ThresholdSlider;
