import React from 'react';
import { Card, CardBody } from 'reactstrap';
import { Button } from '@mui/material';
import Productivity from './components/Productivity';
import DefaultLayout from '../../components/DefaultLayout';
import PageComponent from '../../components/PageComponent';
import ObjectSummary from '../../components/summary/ObjectSummary';
import MContext from '../../models/MContext';
import PageButtonToolbar from '../../components/PageButtonToolbar';
import GroupEvent from '../../utils/track/GroupEvent';
import { IconRefresh } from '../../images/CustomIcon';
import { t } from '../../i18n/t';
import PlatformCoverage from './components/PlatformCoverage';
import TestActivities from './components/TestActivities';
import RequirementCoverage from './components/RequirementCoverage';
import FlakinessDistribution from './components/FlakinessDistribution';
import ReleaseReadiness from './components/ReleaseReadiness';
import TestEnvironmentsCard from '../ci_dashboard/components/TestEnvironmentsCard';
import OnboardingChecklist from './onboardings/OnboardingChecklist';
import MFlags from '../../models/MFlags';
import VirtualDataAnalyst from './components/VirtualDataAnalyst';
import { next } from '../../utils/Count';
import Services from '../../utils/Services';

export default class ProjectDashboard extends PageComponent {

  constructor(props) {
    super(props);
    this.meta.id = 'page-project-dashboard';
    this.meta.title = t('Dashboard');
    this.teamId = MContext.teamId;
    this.team = MContext.team;
    this.projectId = MContext.projectId;
    this.state = {
      refreshMetricCard: null,
    };

    this.renderHeader = this.renderHeader.bind(this);
    this.renderBody = this.renderBody.bind(this);
    this.renderRefreshButton = this.renderRefreshButton.bind(this);
    this.handleRefresh = this.handleRefresh.bind(this);
    this.totalTestRunsChartRef = React.createRef();
    this.executionResultChartRef = React.createRef();
    this.testCaseCreatedChartRef = React.createRef();
    this.flakinessDistributionRef = React.createRef();
    this.platformCoverageChartRef = React.createRef();
  }

  renderObjectSummary() {
    const team = this.team;
    const urlParams = {
      project_dashboard: t('Dashboard'),
      teamId: this.teamId,
      teamName: team.name,
    };
    return (
      <ObjectSummary params={urlParams} />
    );
  }

  getConnection() {
    return Services.getJiraConnection(this.projectId)
      .then(({ content }) => {
        const connection = content[0];
        if (connection) {
          this.setState({ isJiraIntegrated: connection.active });
        }
        return connection;
      });
  }

  componentDidMount() {
    if (MFlags.refreshReleaseStatusOnDashboardEnabled) {
      this.getConnection();
    }
  }

  renderHeader() {
    return (
      <>
        {MFlags.onboardingChecklistEnabled && <OnboardingChecklist teamId={this.teamId} />}
        {this.renderObjectSummary()}
      </>
    );
  }

  renderTestActivities() {
    return (
      <TestActivities
        totalTestRunsChartRef={this.totalTestRunsChartRef}
        executionResultChartRef={this.executionResultChartRef}
      />
    );
  }

  renderReleaseReadiness() {
    return <ReleaseReadiness />;
  }

  renderProductivity() {
    return (
      <Productivity testCaseCreatedRef={this.testCaseCreatedChartRef} />
    );
  }

  renderQuality() {
    return (
      <>
        <div className="project-dashboard-header">
          <span className="project-dashboard-header-title">{t('project-dashboard#quality')}</span>
          <span className="beta-title">{t('beta')}</span>
        </div>
        <FlakinessDistribution dataLoaderRef={this.flakinessDistributionRef} />
      </>
    );
  }

  renderPlatformCoverage() {
    return <PlatformCoverage dataLoaderRef={this.platformCoverageChartRef} />;
  }

  renderRequirementCoverage() {
    return <RequirementCoverage />;
  }

  renderLocalTestEnvironment() {
    return <TestEnvironmentsCard />;
  }

  renderVirtualDataAnalyst() {
    return <VirtualDataAnalyst refreshMetricCard={this.state.refreshMetricCard} />;
  }

  handleRefresh() {
    const { isJiraIntegrated } = this.state;
    if (this.totalTestRunsChartRef.current) {
      this.totalTestRunsChartRef.current.clearCacheAndRefreshData();
    }
    if (this.executionResultChartRef.current) {
      this.executionResultChartRef.current.clearCacheAndRefreshData();
    }
    if (this.testCaseCreatedChartRef.current) {
      this.testCaseCreatedChartRef.current.clearCacheAndRefreshData();
    }
    if (this.flakinessDistributionRef.current) {
      this.flakinessDistributionRef.current.clearCacheAndRefreshData();
    }
    if (this.platformCoverageChartRef.current) {
      this.platformCoverageChartRef.current.clearCacheAndRefreshData();
    }
    if (MFlags.refreshMetricCardEnabled) {
      this.setState({
        refreshMetricCard: next(),
      });
    }

    if (isJiraIntegrated) {
      Services.refreshExternalReleasesStatus(this.projectId);
    }
  }

  renderRefreshButton() {
    return (
      <PageButtonToolbar>
        <Button
          id="refresh-btn-project-dashboard"
          title={t('refresh')}
          className="mui-btn-secondary"
          startIcon={<IconRefresh />}
          data-trackid="refresh-project-dashboard"
          data-groupid={GroupEvent.ACCESS_REPORT}
          onClick={this.handleRefresh}
        >
          {t('refresh')}
        </Button>
      </PageButtonToolbar>
    );
  }

  renderBody() {
    return (
      <>
        {this.renderRefreshButton()}
        <Card className="no-card">
          <CardBody>
            {MFlags.dashboardAIEnabled && this.renderVirtualDataAnalyst()}
            {this.renderTestActivities()}
            {this.renderReleaseReadiness()}
            {this.renderProductivity()}
            {this.renderQuality()}
            {this.renderPlatformCoverage()}
            {this.renderRequirementCoverage()}
            {!MFlags.removeAgentOnboardingStepEnabled && this.renderLocalTestEnvironment()}
          </CardBody>
        </Card>
      </>
    );
  }

  render() {
    const team = this.team;
    if (team) {
      return (
        <DefaultLayout
          renderHeader={this.renderHeader}
          renderCustomBodyLayout={this.renderBody}
        />
      );
    }
    return null;
  }
}
