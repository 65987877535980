import React from 'react';
import { Card, CardBody } from 'reactstrap';
import { Tab, Tabs, ThemeProvider } from '@mui/material';
import Button from '@katalon-studio/katalon-ui/Button';
import { theme } from '@katalon-studio/katalon-ui/Theme';
import DefaultLayout from '../components/DefaultLayout';
import PageComponent from '../components/PageComponent';
import ObjectSummary from '../components/summary/ObjectSummary';
import { t } from '../i18n/t';
import MContext from '../models/MContext';
import TabPanel from '../components/tab/TabPanel';
import AgentSetupDocker from './agent/AgentSetupDocker';
import AgentSetupLocal from './agent/AgentSetupLocal';
import MFlags from '../models/MFlags';
import { AgentSetUpRelocateIcon } from '../images/CustomNewIcon';
import Routes, { goByLink } from '../utils/Routes';

class TestEnvInstallation extends PageComponent {
  constructor(props) {
    super(props);
    this.teamId = MContext.teamId;
    this.projectId = MContext.projectId;
    this.team = MContext.team;
    this.meta.id = 'page-test-env-installation';
    this.meta.title = t('test-env-installation');
    this.state = {
      tabIndex: 0,
    };
    this.renderHeader = this.renderHeader.bind(this);
    this.renderBody = this.renderBody.bind(this);
    this.handleChangeTab = this.handleChangeTab.bind(this);
  }

  renderObjectSummary() {
    const urlParams = {
      testEnvInstallation: t('test-env-installation'),
      teamId: this.teamId,
      teamName: this.team.name,
    };
    return (
      <ObjectSummary params={urlParams} />
    );
  }

  renderHeader() {
    return (
      <>
        {this.renderObjectSummary()}
      </>
    );
  }

  handleChangeTab(event, newValue) {
    this.setState({
      tabIndex: newValue,
    });
  }

  renderBody() {
    const { tabIndex } = this.state;
    const { moveAgentToOrgLevelPhase2Enabled } = MFlags;

    if (moveAgentToOrgLevelPhase2Enabled) {
      return this.renderBlockPage();
    }

    return (
      <Card>
        <CardBody>
          <Tabs
            value={tabIndex}
            onChange={this.handleChangeTab}
            textColor="secondary"
            indicatorColor="secondary"
          >
            <Tab label={t('agent-local')} />
            <Tab label={t('agent-docker')} />
          </Tabs>
          <TabPanel value={tabIndex} index={0}>
            <AgentSetupLocal />
          </TabPanel>
          <TabPanel value={tabIndex} index={1}>
            <AgentSetupDocker />
          </TabPanel>
        </CardBody>
      </Card>
    );
  }

  handleRunTest() {
    const route = new Routes({ organizationId: MContext.team.organizationId });
    goByLink(route.organization_setting_test_env_installation_link);
  }

  renderBlockPage() {
    return (
      <ThemeProvider theme={theme}>
        <Card className="agent-setup-block-page">
          <CardBody className="agent-setup-block-page-body">
            <AgentSetUpRelocateIcon />
            <div>
              <h3 className="mt-3">{t('agent-setup#block-page')}</h3>
              <div>
                The Agent Setup page is now located under <strong>Organization Setup</strong>.<br />
                Please proceed to this new location to continue your tasks.
              </div>
              <Button
                className="mt-3"
                variant="contained"
                color="primary"
                onClick={this.handleRunTest}
              >
                {t('agent-setup#block-page#go-to-new-location')}
              </Button>
            </div>
          </CardBody>
        </Card>
      </ThemeProvider>
    );
  }

  render() {
    const isTeamDemo = MContext.isTeamDemo;

    if (this.team && !isTeamDemo) {
      return (
        <DefaultLayout
          renderHeader={this.renderHeader}
          renderBody={this.renderBody}
        />
      );
    }
    return null;
  }
}

export default TestEnvInstallation;
