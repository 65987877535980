const DocumentLink = {
  CREATE_TEAM: 'https://docs.katalon.com/katalon-analytics/docs/setup-org-team-project.html#create-a-team',
  CREATE_PROJECT: 'https://docs.katalon.com/katalon-analytics/docs/setup-org-team-project.html#create-projects',
  LICENSE_MANAGEMENT: 'https://docs.katalon.com/katalon-studio/docs/license-management.html',
  TEAM: 'https://docs.katalon.com/katalon-analytics/docs/user-management.html#invite-a-user-to-the-team',
  SETUP_CIRCLE_CI_ENV: 'https://docs.katalon.com/docs/katalon-platform/execute/test-execution-with-testops/set-up-circleci-test-environments-for-testops',
  SETUP_EKS_ENV: 'https://docs.katalon.com/docs/katalon-platform/execute/test-execution-with-testops/set-up-kubernetes-test-environments-for-testops',
  USE_ONLINE_LICENSE: 'https://docs.katalon.com/katalon-studio/docs/use-online-license.html',

  START_AGENT: 'https://docs.katalon.com/katalon-analytics/docs/agents.html#start-an-agent',
  UPLOAD_KATALON_REPORT_DOCS: 'https://docs.katalon.com/katalon-analytics/docs/project-management-import-KS.html',
  UPLOAD_KATALON_REPORT_AUTO_DOCS: 'https://docs.katalon.com/katalon-studio/docs/katalon-analytics-beta-integration.html',
  UPLOAD_REPORT_COMMAND_DOCS: 'https://docs.katalon.com/x/FhbR',
  UPLOAD_JUNIT_REPORT_MAVEN_DOCS: 'https://github.com/katalon-studio/katalon-testops-junit-plugin',
  UPLOAD_TESTNG_REPORT_MAVEN_DOCS: 'https://github.com/katalon-studio/katalon-testops-testng',
  HOW_TO_GENERATE_COMMAND: 'https://docs.katalon.com/katalon-studio/docs/console-mode-execution.html#command-builder',

  JUNIT4_GIT_REPOSITORY: 'https://github.com/katalon-studio/testops-junit4',
  JUNIT5_GIT_REPOSITORY: 'https://github.com/katalon-studio/testops-junit5',
  TESTNG_GIT_REPOSITORY: 'https://github.com/katalon-studio/testops-testng',
  JS_REPORT_GIT_REPOSITORY: 'https://github.com/katalon-studio/testops-report-js',
  PYTEST_GIT_REPOSITORY: 'https://github.com/katalon-studio/testops-report-python',
  ROBOT_GIT_REPOSITORY: 'https://github.com/katalon-studio-samples/katalon-testops-robot-sample',

  SSO_SETTINGS: 'https://docs.katalon.com/katalon-analytics/docs/sso-settings.html',
  SUB_DOMAIN: 'https://docs.katalon.com/katalon-analytics/docs/subdomain.html',
  STRICT_DOMAIN: 'https://docs.katalon.com/katalon-analytics/docs/strict-domain.html',
  ACCEPT_SSO: 'https://docs.katalon.com/katalon-analytics/docs/accept-sso.html',

  SETUP_TEAM: 'https://docs.katalon.com/katalon-analytics/docs/setup-team.html',
  JIRA_SETTING: 'https://docs.katalon.com/katalon-analytics/docs/kt-jira-config.html',
  CI_DOCS: 'https://docs.katalon.com/katalon-analytics/docs/kt-remote-execution.html',
  VIEW_REPORT: 'https://docs.katalon.com/katalon-analytics/docs/view-reports.html',
  UPLOAD_REPORT_OVERVIEW: 'https://docs.katalon.com/katalon-analytics/docs/upload-reports-overview.html',

  BDD_SETTING: 'https://docs.katalon.com/katalon-analytics/docs/bdd-settings.html',

  JIRA_MARKET_PLACE: 'https://marketplace.atlassian.com/apps/1217501/katalon-bdd-test-automation-for-jira?hosting=cloud&tab=overview',
  UPLOAD_TEST_RESULT_USING_COMMAND_LINE: 'https://docs.katalon.com/katalon-analytics/docs/project-management-import-cli.html',

  SURVEY: 'https://www.surveymonkey.com/r/testops-prelaunch',

  KRE_DEVOPS_SUNSET: 'https://docs.katalon.com/katalon-studio/docs/kre-devops-sunset.html',

  BUG_REPORT: 'https://github.com/katalon-studio/katalon-testops/issues/new?template=bug_report.md&title=[Bug]',
  FEATURE_REQUEST: 'https://github.com/katalon-studio/katalon-testops/issues/new?template=feature_request.md&title=[Feature]',

  GITTER_TO: 'https://gitter.im/katalon-studio/katalon-testops',

  TO_DOCUMENTS: 'https://docs.katalon.com/docs',
  TO_RELEASE_NOTES: 'https://docs.katalon.com/docs/katalon-testops/release-notes/2022',

  FORUM: 'https://forum.katalon.com/',
  TESTOPS_CORE_FORUM: 'https://support.katalon.com/hc/en-us/articles/900005152783-What-are-the-differences-between-TestOps-Core-and-TestOps-Advanced',

  JIRA_DEFECTS: 'https://docs.katalon.com/katalon-analytics/docs/ka-defects.html',
  JIRA_REQUIREMENTS: 'https://docs.katalon.com/katalon-analytics/docs/ka-integration-jira.html',
  JIRA_RELEASES: 'https://docs.katalon.com/katalon-analytics/docs/kt-jira-release.html',

  KUBERNETES: 'https://kubernetes.io',

  TEST_RUNS_KOBITON: 'https://docs.katalon.com/katalon-analytics/docs/kt_kobiton_integration.html',

  KATALON_TESTOPS_PACKAGE_DETAILS: 'https://www.katalon.com/pricing/#testops',
  MAIL_KATALON_BUSINESS: 'mailto:business@katalon.com',
  STATUS: 'https://status.katalon.com',
  TESTOPS_TRIAL_PLANS: 'https://docs.katalon.com/katalon-analytics/docs/trial-plans.html#for-existing-users',

  TEST_CLOUD: 'https://www.katalon.com/testcloud',
  TEST_CLOUD_LOCAL_TESTING: 'https://docs.katalon.com/docs/execute/test-execution-with-testcloud/local-testing-with-testcloud/local-testing-with-testcloud',

  VISUAL_TESTING_SETUP: 'https://docs.katalon.com/katalon-analytics/docs/ks-visual-testing.html#set-up-visual-testing',
  VISUAL_TESTING_OVERVIEW: 'https://docs.katalon.com/katalon-analytics/docs/visual-testing-overview.html',

  ABOUT_VISUAL_TESTING_PRO: 'https://docs.katalon.com/docs/products-and-licenses/visual-testing-subscriptions/manage-visual-testing-subscriptions/upgrade-visual-testing-subscriptions',
  UPGRADE_VISUAL_TESTING_SUBSCRIPTION: 'https://docs.katalon.com/docs/products-and-licenses/visual-testing-subscriptions/feature-comparisons-in-visual-testing',
  JIRA_XRAY_DOCS: 'https://docs.getxray.app/display/XRAYCLOUD/Global+Settings%3A+API+Keys',
  JIRA_XRAY_ENABLED_DOCS: 'https://docs.katalon.com/docs/organize/integration-for-organizing-tests/xray-integration/enable-xray-integration-in-katalon-testops',

  DOWNLOAD_KATALON_OFFICIAL_PAGE: 'https://katalon.com/download',
  DOWNLOAD_KATALON_STUDIO: 'https://backend.katalon.com/download-lastest-version',

  SOLUTION_FOR_COMMON_ERRORS: 'https://docs.katalon.com/docs/maintain/suggested-solutions-for-keyword-errors',

  KATALON_TERM: 'https://katalon.com/terms/#terms-of-use',
  PRIVACY_POLICY: 'https://katalon.com/terms/#privacy-policy',

  SETUP_GIT_REPOSITORY_PERSONAL_ACCESS_TOKEN: 'https://docs.katalon.com/docs/organize/upload-test-scripts-from-the-git-repository-to-katalon-testops',
  SAMPLE_GIT_REPO_LEARN_MORE_API: 'https://docs.katalon.com/docs/get-started/sample-projects/api/sample-api-tests-project-in-katalon-studio',
  SAMPLE_GIT_REPO_LEARN_MORE_BDD: 'https://docs.katalon.com/docs/get-started/sample-projects/behavior-driven-development/sample-bdd-cucumber-tests-project-in-katalon-studio',
  SAMPLE_GIT_REPO_LEARN_MORE_DATA_DRIVEN_TEST:
    'https://docs.katalon.com/docs/get-started/sample-projects/data-driven-test/sample-webui-tests-project-with-data-driven-testing-shopping-cart-sample-in-katalon-studio',
  SAMPLE_GIT_REPO_LEARN_MORE_WEB_UI: 'https://docs.katalon.com/docs/get-started/sample-projects/webui/sample-webui-tests-project-healthcare-sample-in-katalon-studio',
  SAMPLE_GIT_REPO_LEARN_MORE_MOBILE_ANDROID: 'https://docs.katalon.com/docs/get-started/sample-projects/mobile/mobile-create-and-run-android-test-case',
  SAMPLE_GIT_REPO_LEARN_MORE_MOBILE_IOS: 'https://docs.katalon.com/docs/get-started/sample-projects/mobile/mobile-create-and-run-ios-test-case-in-katalon-studio',
  SAMPLE_GIT_REPO_LEARN_MORE_VISUAL_TESTING: 'https://docs.katalon.com/docs/analyze/analytics/visual-testing/use-testops-visual-testing',
  LEARN_MORE_ABOUT_USING_JIRA_WEBHOOK: 'https://docs.katalon.com/docs/organize/integration-for-organizing-tests/jira-'
    + 'integration/enable-katalon-testops---jira-integration-for-test-management#create-webhook-for-testops---jira-integration',
  ACTIVE_JIRA_INTEGRATION: 'https://docs.katalon.com/docs/organize/integration-for-organizing-tests/jira-integration/enable-katalon-testops' +
      '---jira-integration-for-test-management#enable-testops---jira-integration-in-katalon-testops',
  LINK_JIRA_TEST_RESULTS: 'https://docs.katalon.com/docs/analyze/integration-for-test-analyzing/jira-integration/link-test-runs-to-jira-defects-in-katalon-testops',
  CREATE_PROJECT_IN_ADMIN: 'https://docs.katalon.com/docs/administer/administration-tasks/create-an-organization-and-project#create-a-new-project-on-katalon-testops',
  SYNC_KS_PROJECT_WITH_TO: 'https://docs.katalon.com/docs/get-started/set-up-your-workspace/integrate-katalon-testops-and-katalon-testcloud-with-katalon-studio',
  CREATE_YOUR_FIRST_PROJECT: 'https://docs.katalon.com/docs/get-started/onboarding-katalon-platform/quick-guide-for-testers#create-your-first-project',
  WRITE_YOUR_FIRST_TEST_CASE: 'https://docs.katalon.com/docs/get-started/onboarding-katalon-platform/quick-guide-for-testers#write-your-first-test-case',
  PLAN_YOUR_TESTS_WITH_A_TEST_SUITE: 'https://docs.katalon.com/docs/get-started/onboarding-katalon-platform/quick-guide-for-testers#plan-your-tests-with-a-test-suite',
  ANALYZE_YOUR_TEST_ACTIVITIES_WITH_CLOUD_PLATFORM: 'https://docs.katalon.com/docs/get-started/onboarding-katalon-platform/quick-guide-for-testers#analyze-your-test-activities-with-cloud-platform',
  TRY_CROSS_BROWSER_CLOUD_TESTING: 'https://docs.katalon.com/docs/get-started/onboarding-katalon-platform/quick-guide-for-testers#try-cross-browser-cloud-testing',
  EXECUTE_KATALON_RUNTIME_ENGINE_VIA_CI_CD_PIPELINE: 'https://docs.katalon.com/docs/get-started/onboarding-katalon-platform/quick-guide-for-testers' +
      '#execute-test-via-cicd-pipeline-with-katalon-runtime-engine',
  BLOCK_ACCOUNT_DOC: 'https://docs.katalon.com/docs/administer/troubleshooting/this-account-has-been-blocked',
  INTRODUCTION_TO_TEST_PLANNING: 'https://docs.katalon.com/docs/plan/introduction-to-test-planning',
  USE_TESTCLOUD_IN_TESTOPS: 'https://docs.katalon.com/docs/execute/test-execution-with-testcloud/use-testcloud-in-testops',
  VIRTUAL_DATA_ANALYST: 'https://docs.katalon.com/docs/proof-of-concept/virtual-data-analyst',
  JENKINS_SCHEDULE_TEST_RUN: 'https://docs.katalon.com/docs/execute/schedule-test-execution/schedule-test-runs-in-testops',
  JENKINS_EXECUTE_TEST_RUN:
  'https://docs.katalon.com/docs/execute/cicd-integrations/jenkins-integration/use-katalon-plugins-for-jenkins-integration/integrate-jenkins-with-testops#execute-katalon-testops-plan-on-jenkins',
  SCRIPT_REPO_WHITELIST_IPS: 'https://docs.katalon.com/katalon-platform/troubleshooting/troubleshooting-common-administrative-issues/katalon-domain-and-ip-whitelist',
  SUPPORTED_JIRA_FIELD_TYPES:
  'https://docs.katalon.com/docs/analyze/integration-for-test-analyzing/jira-integration/link-test-runs-to-jira-defects-in-katalon-testops/supported-jira-field-types',
  DEFECT_REPORT_AUTOMATIONS:
  'https://docs.katalon.com/docs/analyze/analytics/defects-report/defect-reports-automations',
  KATALON_PLUGIN: 'https://docs.katalon.com/katalon-analytics/docs/kt-jira-issue.html',
  SUBMIT_JIRA_ISSUE: 'https://docs.katalon.com/docs/katalon-platform/analyze/integration-for-test-analyzing/jira-integration/submit-an-issue-from-katalon-testops-to-jira',
  KATALON_CLI_DOCKER_DOC: 'https://hub.docker.com/r/katalonstudio/katalon-cli',
  KATALON_API_KEY_DOC: 'https://docs.katalon.com/docs/katalon-platform/administer/settings/katalon-api-key-in-katalon-testops',
  DAILY_SUMMARY_REPORT_LEARN_MORE: 'https://docs.katalon.com/docs/katalon-platform/integrations/daily-summary-emails',
};

export default DocumentLink;
