import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Badge as BootstrapBadge } from 'reactstrap';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';

import { t } from '../../i18n/t';
import NotificationItemDisplayTime from './NotificationItemDisplayTime';

/**
 * Component to render notification item.
 */
const NotificationItem = ({ notification, onMarkAsReadClick, onClick }) => {
  const { title, content, tag, createdAt, read } = notification;
  const markAsReadLabel = read ? t('notifications#mask-as-unread') : t('notifications#mask-as-read');

  const handleMarkAsReadClick = (notification) => (e) => {
    e.stopPropagation();
    onMarkAsReadClick(notification);
  };

  return (
    <div className="notification-item" onClick={onClick}>
      <Typography className="notification-item__title">{title}</Typography>
      {!read && (
        <Tooltip
          title={markAsReadLabel}
          placement="left"
          classes={{ tooltip: 'notification-item__indicator-tooltip' }}
        >
          <div
            className={classnames({ 'notification-item__read-indicator': read, 'notification-item__unread-indicator': !read })}
            onClick={handleMarkAsReadClick(notification)}
          />
        </Tooltip>
      )}
      <BootstrapBadge className="notification-item__tag">{tag}</BootstrapBadge>
      <p className="notification-item__message">
        {content}
      </p>
      <p className="notification-item__time">
        <NotificationItemDisplayTime timestamp={createdAt} />
      </p>
    </div>
  );
};

NotificationItem.propTypes = {
  notification: PropTypes.object.isRequired,
  onMarkAsReadClick: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default NotificationItem;
