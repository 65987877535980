import Arrays from '../../utils/Arrays';
import { t } from '../../i18n/t';
import Time from '../../utils/Moment';

export const statusFilter = (key, label = 'Status', testResultStatus = false) => ({
  label,
  id: key,
  options: [
    { label: 'Passed', value: 'PASSED' },
    { label: 'Failed', value: 'FAILED' },
    ...Arrays.insertIf(testResultStatus, { label: 'Error', value: 'ERROR' }),
    ...Arrays.insertIf(testResultStatus, { label: 'Incomplete', value: 'INCOMPLETE' })
    // { label: 'Running Executions', value: 'RUNNING' },
    // { label: 'Skipped Executions', value: 'SKIPPED' },
    // { label: 'Not_run Executions', value: 'NOT_RUN' },
  ]
});

export const checkpointStatusFilter = (key, label = 'Status') => ({
  label,
  id: key,
  options: [
    { label: 'Passed', value: 'PASSED' },
    { label: 'Failed', value: 'FAILED' },
    { label: 'Unresolved', value: 'UNRESOLVED' },
  ]
});

export const statusJobFilter = (key, label = 'Status') => ({
  label,
  id: key,
  options: [
    { label: 'Queued', value: 'QUEUED' },
    { label: 'Running', value: 'RUNNING' },
    { label: 'Error', value: 'ERROR' },
    { label: 'Canceled', value: 'CANCELED' },
    { label: 'Success', value: 'SUCCESS' },
    { label: 'Failed', value: 'FAILED' },
    { label: 'Wait for trigger', value: 'WAIT_FOR_TRIGGER' },
  ]
});

export const productFilter = (key, label = 'Product') => ({
  label,
  id: key,
  options: [
    { label: t('kse_license_keys'), value: 'KSE' },
    { label: t('engine_license_keys'), value: 'ENGINE' },
    { label: t('floating_engine_license_keys'), value: 'FLOATING_ENGINE' },
  ]
});

export const userOrganizationFilter = (key, userOrganization, label = 'User') => ({
  label,
  id: key,
  options: userOrganization.map((userOrganization) => ({
    label: userOrganization.user.fullName,
    value: userOrganization.user.id
  }))
});

export const userFilter = (key, users, label = 'By') => ({
  label,
  id: key,
  options: users.map((user) => ({
    label: user.fullName,
    value: user.id
  }))
});

export const startTimeFilter = (key, label = 'Started') => ({
  label,
  id: key,
  options: [
    { label: 'Within a month', value: `>=${Time.timeAgo(1, 'month')}` },
    { label: 'A month ago', value: `<${Time.timeAgo(1, 'month')},>=${Time.timeAgo(2, 'month')}` },
    { label: '2 months ago', value: `<${Time.timeAgo(2, 'month')},>=${Time.timeAgo(3, 'month')}` },
    { label: '3 months ago', value: `<${Time.timeAgo(3, 'month')}` }
  ]
});

export const testCaseTypeFilter = (key, label = 'Type') => ({
  label,
  id: key,
  options: [
    { label: 'Test Case', value: 'TEST_CASE' },
    { label: 'Scenario', value: 'SCENARIO' },
  ]
});


export const buildDefaultFilter = (key, operator, label) => ({
  label,
  key,
  operator
});

export const inputFilter = (key, label, operator = '') => ({
  label,
  id: key,
  operator,
});

export const buildFilter = (Filter, props) => ({
  component: Filter,
  props
});
