import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import RouteConstants from '../../utils/RouteConstants';
import NavigationBar from './NavigationBar';
import { PageType } from '../../utils/Constants';

class TopCenterNavigationBar extends Component {

  render() {
    return (
      <div id="top-center-navbar">
        <Switch>
          <Route exact path={RouteConstants.login} render={() => null} />
          <Route exact path={RouteConstants.reset_password} render={() => null} />
          <Route exact path={RouteConstants.organization_new_orders} render={() => null} />
          <Route exact path={RouteConstants.organization_checkout_quote} render={() => null} />
          <Route path={RouteConstants.startup} render={() => null} />
          <Route path={RouteConstants.home_organization} render={(params) => <NavigationBar type={PageType.ORGANIZATION_HOME} {...params} onCollapseChanged={this.onCollapseChanged} />} />
          <Route path={RouteConstants.home} render={(params) => <NavigationBar type={PageType.ORGANIZATION_HOME} {...params} onCollapseChanged={this.onCollapseChanged} />} />
          <Route path={RouteConstants.project} render={(params) => <NavigationBar type={PageType.PROJECT} {...params} onCollapseChanged={this.onCollapseChanged} />} />
          <Route path={RouteConstants.team} render={(params) => <NavigationBar type={PageType.TEAM} {...params} onCollapseChanged={this.onCollapseChanged} />} />
          <Route path={RouteConstants.manage} render={(params) => <NavigationBar type={PageType.USER} {...params} onCollapseChanged={this.onCollapseChanged} />} />
          <Route path={RouteConstants.organization} render={(params) => <NavigationBar type={PageType.ORGANIZATION} {...params} onCollapseChanged={this.onCollapseChanged} />} />
          <Route path={RouteConstants.katalon_manage} render={(params) => <NavigationBar type={PageType.KATALON} {...params} onCollapseChanged={this.onCollapseChanged} />} />
        </Switch>
      </div>
    );
  }
}

export default TopCenterNavigationBar;
