import React from 'react';
import { Button } from '@mui/material';
import { IconChevronRight } from '../../../images/CustomIcon';
import { VirtualDataQuestionType } from '../../../utils/ConstantsType';

interface AIQuestionButtonProps {
  question: VirtualDataQuestionType;
  openDrawer: (question: VirtualDataQuestionType) => void;
}

function AIQuestionButton(props: AIQuestionButtonProps) {
  const { question, openDrawer } = props;

  return (
    <Button
      variant="outlined"
      className="ai-question-button"
      onClick={() => openDrawer(question)}
      data-trackid={question.dataTrackId}
      data-groupid={question.dataTrackGroup}
    >
      {question.value}
      <span className="ml-2">
        <IconChevronRight />
      </span>
    </Button>
  );
}

export default AIQuestionButton;
