import React, { useCallback, useState } from 'react';
import { useGridApiRef, DataGridPro } from '@mui/x-data-grid-pro';
import PropTypes from 'prop-types';
import { Stack } from '@mui/material';
import { isEqual } from 'lodash';
import { t } from '../../../i18n/t';
import { ALL_BROWSERS_TESTCLOUD_AGENT } from '../../../utils/Constants';
import TestSuiteDetailPanelContent from './TestSuiteDetailPanelContent';

function NoRowsOverlay() {
  return (
    <Stack className="no-row-overlay">
      {t('test-suite-collection-override#no-data-to-display')}
    </Stack>
  );
}

export default function TestSuitesDataGrid({
  isSelected, rows, columns, onCheck, classNames, nativeApps,
  testCloudEnv, onChangeTS }) {
  const [selectionModel, setSelectionModel] = useState([]);

  const handleSelected = (ids) => {
    setSelectionModel(ids);
    onCheck(ids);
  };

  const handleChangeExecutionEnvironments = useCallback((testExecutionEnvironments) => {
    const firstValue = testExecutionEnvironments.selectedItems[0];
    const existValue = rows.find((row) => row.id === testExecutionEnvironments?.testSuiteId);
    if (isEqual(existValue?.testCloudAgent, firstValue)) {
      return;
    }
    const newValue = {
      testSuiteCollectionConfigurationId: testExecutionEnvironments?.testSuiteId,
      testCloudAgent: firstValue || ALL_BROWSERS_TESTCLOUD_AGENT,
    };
    onChangeTS(newValue);
  }, [onChangeTS, rows]);

  const [
    detailPanelExpandedRowIds,
    setDetailPanelExpandedRowIds
  ] = useState([]);

  const handleDetailPanelExpandedRowIdsChange = useCallback(
    (newIds) => {
      if (newIds.length > 1) {
        setDetailPanelExpandedRowIds([newIds[newIds.length - 1]]);
      } else {
        setDetailPanelExpandedRowIds(newIds);
      }
    },
    []
  );


  const getDetailPanelContent = useCallback(
    ({ row }) => (
      <TestSuiteDetailPanelContent
        row={row}
        testCloudEnv={testCloudEnv}
        nativeApps={nativeApps}
        handleChangeExecutionEnvironments={handleChangeExecutionEnvironments}
      />
    ),
    [handleChangeExecutionEnvironments, nativeApps, testCloudEnv],
  );

  const apiRef = useGridApiRef();

  const onRowClick = useCallback(
    (params) => {
      apiRef.current.toggleDetailPanel(params.id);
    },
    [apiRef]
  );

  return (
    <Stack height={1} width={1}>
      <DataGridPro
        autoHeight
        apiRef={apiRef}
        getDetailPanelContent={getDetailPanelContent}
        getDetailPanelHeight={() => 'auto'}
        detailPanelExpandedRowIds={detailPanelExpandedRowIds}
        onDetailPanelExpandedRowIdsChange={
          handleDetailPanelExpandedRowIdsChange
        }
        onRowClick={onRowClick}
        className={classNames.tableDataGrid}
        rows={rows}
        columns={columns}
        checkboxSelection
        hideFooterPagination
        disableSelectionOnClick
        disableColumnSelector
        disableColumnResize
        onSelectionModelChange={handleSelected}
        selectionModel={isSelected ? [] : selectionModel}
        hideFooterSelectedRowCount
        hideFooterRowCount
        disableMultipleColumnsSorting
        disableColumnMenu
        components={{
          NoRowsOverlay,
        }}

      />
    </Stack>
  );
}

TestSuitesDataGrid.propTypes = {
  isSelected: PropTypes.bool,
  rows: PropTypes.array,
  columns: PropTypes.array,
  onCheck: PropTypes.func.isRequired,
  classNames: PropTypes.object.isRequired,
  nativeApps: PropTypes.array.isRequired,
  testCloudEnv: PropTypes.object.isRequired,
  onChangeTS: PropTypes.func.isRequired,
};

TestSuitesDataGrid.defaultProps = {
  rows: [],
  columns: [],
};
