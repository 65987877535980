import { ListItemText } from '@mui/material';
import React from 'react';
import DropDownFilter from './filter/DropDownFilter';

const Sort = ({ onChange: onFilterChange, ...rest }) => {
  const renderOption = (option) => (
    <ListItemText primary={option.label} />
  );
  return (
    <DropDownFilter
      searchKey="value"
      id="sort"
      label="Sort"
      renderOption={renderOption}
      onFilterChange={onFilterChange}
      {...rest}
    />
  );
};

export default Sort;
