import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@katalon-studio/katalon-ui/IconButton';
import Tooltip from '@katalon-studio/katalon-ui/Tooltip';
import { ThemeProvider } from '@mui/material/styles';
import { t } from '../../../i18n/t';
import { TestEntity } from '../../../models/model/TestEntity';
import { katalonui_theme } from '../../../katalonui-theme';
import ActionsMenu from './ActionsMenu';
import EllipsisVerticalIcon from '../../../../images/icons/katalonui/EllipsisVerticalIcon';


function MoreActionsMenu(props: {
  data: TestEntity,
  actionsWithHandler: any,
}) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const { data, actionsWithHandler } = props;

  return (
    <ThemeProvider theme={katalonui_theme}>
      <Tooltip
        label={t('more-actions')}
        size="medium"
      >
        <div>
          <IconButton
            onClick={handleClick}
            size="small"
          >
            <EllipsisVerticalIcon />
          </IconButton>
        </div>
      </Tooltip>
      <ActionsMenu
        testEntity={data}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        actionsWithHandler={actionsWithHandler}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      />
    </ThemeProvider>
  );
}

MoreActionsMenu.propTypes = {
  data: PropTypes.object.isRequired,
};

export default MoreActionsMenu;
