import React from 'react';
import { Button } from 'reactstrap';
import { IconNoFilePreview } from '../../../images/CustomIcon';
import { t } from '../../../i18n/t';

export default (props) => (
  <div className="no-preview">
    <p><IconNoFilePreview /></p>
    <p>{t('file-preview#no-preview')}<br />{t('file-preview#try-download')}</p>
    {props.file && props.file.source && (
      <a href={props.file.source} download={props.file.name}>
        <Button type="button" color="primary">{t('download')}</Button>
      </a>
    )}
  </div>
);
