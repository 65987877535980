import React from 'react';
import PropTypes from 'prop-types';
import { Progress, Row, Col, Badge, UncontrolledTooltip } from 'reactstrap';
import { next } from '../../utils/Count';

class StatusBar extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      expandItem: null,
    };
  }

  componentDidMount() {
    const {
      defaultItem,
    } = this.props;

    if (defaultItem) {
      const { items } = this.props;
      this.expandState(items[defaultItem]);
    }
  }

  expandState(item) {
    this.setState({
      expandItem: item,
    });
    if (item.onClick) {
      item.onClick(item);
    }
  }

  renderTooltip(item, totalValue) {
    const { formatTooltip } = this.props;
    if (formatTooltip) {
      return formatTooltip(item);
    } else if (item.percent) {
      return <>{item.percent}% {item.label}</>
    } else {
      return <>{((item.value / totalValue) * 100).toFixed(2)}% {item.label}</>
    }
  }

  renderProgressItems(items) {
    const totalValue = items.reduce((total, item) => total + (item.isTotal ? 0 : (item.value || 0)), 0);
    const key = next();
    return items.map((item) => {
      if (!item.isTotal) {
        return (
          <Progress
            key={item.label}
            className={`progress-bar progress-${key}-${item.label}`}
            bar
            color={item.color}
            value={item.value}
            max={totalValue}
          >
            <UncontrolledTooltip placement="top" target={`.progress-${key}-${item.label}`} fade>
              {this.renderTooltip(item, totalValue)}
            </UncontrolledTooltip>
          </Progress>
        );
      }
      return null;
    });
  }

  renderLegendItems(items, expandItem) {
    return (
      <Row className="mx-0 align-items-center flex-wrap">
        {items.map((item) => {
          const { label, color } = item;
          const value = item.value || 0;
          const isActive = item.onClick && expandItem === item;
          const diff = item.diff || 0;
          const diffPart = item.diff !== undefined
            ? <> ({diff < 0 || '+'}{diff})</>
            : null;

          let content;
          if (item.isTotal) {
            content =
              <div className={`status-bar__legend__item d-flex align-items-center
                ${isActive ? 'active' : ''}`}
              >
                <span className="status-bar__legend__item__label"><b>Total</b> {value}</span>
              </div>;
          } else {
            content =
              <div className={`status-bar__legend__item d-flex align-items-center ml-3
                ${isActive ? 'active' : ''}`}
              >
                <Badge pill className={`status-badge-${color}`}>{value}</Badge>&nbsp;
                <div className="status-bar__legend__item__label">{label}{diffPart}</div>
              </div>;
          }

          return (
            <Col
              className="px-0"
              key={item.label}
            >
              {item.onClick
                ? (
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      this.expandState(item);
                    }}
                  >
                    {content}
                  </a>)
                : content}
            </Col>
          );
        })}
      </Row>
    );
  }

  render() {
    const { expandItem } = this.state;
    const { title, items, showLegend, style } = this.props;
    return (
      <div className={style}>
        <Row className="align-items-center">
          {title && <Col xs="auto" className="status-bar__title">{title}</Col>}
          <Col className="my-1">
            <Progress multi>
              {this.renderProgressItems(items)}
            </Progress>
          </Col>
          {showLegend && (
            <Col xs={title ? 'auto' : ''} className="status-bar__legend">
              {this.renderLegendItems(items, expandItem)}
            </Col>
          )}
        </Row>
      </div>
    );
  }
}

StatusBar.propTypes = {
  items: PropTypes.array.isRequired,
  defaultItem: PropTypes.number,
  title: PropTypes.string,
  showLegend: PropTypes.bool,
  style: Progress.string,
  formatTooltip: PropTypes.func,
};

StatusBar.defaultProps = {
  items: [],
  defaultItem: 0,
  title: '',
  showLegend: true,
  style: 'status-bar',
};

export default StatusBar;
