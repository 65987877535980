import sanitizeHtml from 'sanitize-html';

/*
  https://www.npmjs.com/package/sanitize-html
  version: 2.3.3
 */

export const sanitizeScript = (dirty, options) => sanitizeHtml(dirty, options);

export const basicFilter = {
  allowedTags: ['a', 'p', 'strong', 'b', 'i', 'br'],
  nonTextTags: ['style', 'script', 'textarea', 'option', 'noscript'],
};
