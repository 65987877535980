import React, { useState } from 'react';
import { CUSTOM_FIELD_ENTITY_TYPE } from '../../../utils/Constants';
import EditCustomField from '../../EditCustomField';
import MContext from '../../../models/MContext';

const RunConfigurationCustomField = ({ hideLabel = false, onChange }) => {
  const [selectedCustomFields, setSelectedCustomFields] = useState([]);
  const runConfigurationId = MContext.runConfigurationId;

  const handleUpdateCustomFields = (selectedCustomFields) => {
    const customFieldOptions = selectedCustomFields.map((selectedCustomField) => ({
      id: selectedCustomField.customFieldOption.id,
      definitionId: selectedCustomField.customFieldDefinition.id
    }));
    setSelectedCustomFields(selectedCustomFields);
    onChange(customFieldOptions);
  };

  return (
    <EditCustomField
      entityId={runConfigurationId}
      hideLabel={hideLabel}
      entityType={CUSTOM_FIELD_ENTITY_TYPE.TEST_SCHEDULE}
      setSelectedCustomFields={handleUpdateCustomFields}
      selectedCustomFields={selectedCustomFields}
    />
  );
};

export default RunConfigurationCustomField;
