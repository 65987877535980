import queryString from 'query-string';
import React from 'react';
import { Row, Col } from 'reactstrap';
import DefaultLayout from '../../components/DefaultLayout';
import PageComponent from '../../components/PageComponent';
import ObjectSummary from '../../components/summary/ObjectSummary';
import TestCaseExecutionComparisonDataTable from '../../components/table/TestCaseExecutionComparisonDataTable';
import MContext from '../../models/MContext';
import TestCaseExecutionComparisonPieChart from '../../components/chart/TestCaseExecutionComparisonPieChart';
import ExecutionComparisonComposedChart from '../../components/chart/ExecutionComparisonComposedChart';
import { t } from '../../i18n/t';

class CompareExecution extends PageComponent {
  constructor(props) {
    super(props);
    const params = queryString.parse(this.props.location.search);

    this.renderBody = this.renderBody.bind(this);
    this.renderHeader = this.renderHeader.bind(this);
    this.executionOrders = params.id && Array.isArray(params.id) ? params.id.sort((a, b) => a - b) : [];
    this.meta.id = 'page-compare-execution';
    this.meta.title = t('execution_comparison');
  }

  renderTestCaseExecutionsComparisonTable() {
    return (
      <TestCaseExecutionComparisonDataTable
        title={t('test_results')}
        executionOrders={this.executionOrders}
      />
    );
  }

  renderStatusPieChart() {
    return (
      <TestCaseExecutionComparisonPieChart
        title={t('status')}
        executionOrders={this.executionOrders}
      />
    );
  }

  renderComposedChart() {
    return (
      <ExecutionComparisonComposedChart
        executionOrders={this.executionOrders}
        title={t('duration')}
      />
    );
  }

  renderBody() {
    return (
      <>
        <Row>
          <Col>
            {this.renderStatusPieChart()}
          </Col>
          <Col>
            {this.renderComposedChart()}
          </Col>
        </Row>
        {this.renderTestCaseExecutionsComparisonTable()}
      </>
    );
  }

  renderHeader() {
    const urlParams = {
      teamId: MContext.teamId,
      projectId: MContext.projectId,
      project: MContext.project.name,
      executionComparison: t('execution_comparison'),
    };
    return (
      <ObjectSummary params={urlParams} />
    );
  }

  render() {
    return (
      <DefaultLayout
        renderHeader={this.renderHeader}
        renderBody={this.renderBody}
      />
    );
  }
}

export default CompareExecution;
