import React from 'react';
import {
  Button, Form, FormGroup, Label, Row, Col, Card, CardBody, CardHeader
} from 'reactstrap';
import MContext from '../../models/MContext';
import MAuth from '../../models/MAuth';
import Input from '../../components/Input';
import Services from '../../utils/Services';
import Notification from '../../utils/Notification';
import { t } from '../../i18n/t';
import Routes from '../../utils/Routes';
import ImageProfileUploader from '../../components/avatar/ImageProfileUploader';

class EditOrganization extends React.Component {
  constructor(props) {
    super(props);
    this.organizationId = MContext.organizationId;
    this.isOrganizationManager = MAuth.isOrganizationManager(this.organizationId);

    this.updateOrganization = this.updateOrganization.bind(this);
    this.handleTextChange = this.handleTextChange.bind(this);
    this.onUploadAvatarFinish = this.onUploadAvatarFinish.bind(this);
    this.state = {
      orgName: null,
    };
  }

  componentDidMount() {
    const orgName = MContext.organization.name;
    this.setState({ orgName });
  }

  handleTextChange(event) {
    const value = event.target.value;
    const name = event.target.name;
    this.setState({ [name]: value });
  }

  updateOrganization(e) {
    e.preventDefault();
    const { orgName } = this.state;
    Services.updateOrganization({ id: this.organizationId, name: orgName })
      .then(({ name }) => {
        Notification.pushSuccess(`${name} was updated.`);
      });
  }

  onUploadAvatarFinish(data) {
    Services.changeOrganizationLogo(this.organizationId, {
      uploadedPath: data.path,
    }).then(() => {
      Routes.goToOrganizationSettingsPage();
    });
  }

  renderLogo() {
    const organization = MContext.organization;
    return (
      <ImageProfileUploader
        name={organization.name}
        imgUrl={organization.logoUrl}
        onUploadFinish={this.onUploadAvatarFinish}
        allowChange
      />
    );
  }

  renderOrganizationProfile() {
    const { orgName } = this.state;
    const disableForm = !this.isOrganizationManager;

    return (
      <Form data-trackid="edit-organization" onSubmit={this.updateOrganization}>
        <FormGroup>
          <Label>{t('organization_id')}</Label>
          <Input plaintext>{this.organizationId}</Input>
        </FormGroup>
        <FormGroup>
          <Label for="orgName">{t('organization_name')}</Label>
          <Input
            id="orgName"
            name="orgName"
            required
            disabled={disableForm}
            value={orgName}
            onChange={this.handleTextChange}
            type="text"
          />
        </FormGroup>
        <FormGroup>
          <Button type="submit" color="primary" disabled={disableForm}>
            {t('organization_update')}
          </Button>
        </FormGroup>
      </Form>
    );
  }

  renderForm() {
    return (
      <Card>
        <CardHeader>Organization profile</CardHeader>
        <CardBody>
          <Row className="justify-content-center">
            <Col xs="auto">
              {this.renderLogo()}
            </Col>
            <Col>
              <Col sm="12" md="8" lg="6" xl="5">
                {this.renderOrganizationProfile()}
              </Col>
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  }

  render() {
    return (
      <>
        {this.renderForm()}
      </>
    );
  }
}

export default EditOrganization;
