import React from 'react';
import { DialogContent, DialogContentText, DialogActions } from '@mui/material';
import CloseableDialogComponent from './CloseableDialogComponent';
import { t } from '../../i18n/t';
import { Button, ButtonToolbar } from 'reactstrap';
import GroupEvent from '../../utils/track/GroupEvent';

export default function ConfirmationDeleteTestSuiteDialog({ isOpen, handleClose, handleConfirmation }) {
  const trackCloseIcon = {
    'data-trackId': 'close-delete-test-suite',
    'data-groupId': GroupEvent.ACCESS_TEST_MANAGEMENT,
  };

  return (
    <CloseableDialogComponent
      isOpen={isOpen}
      handleClose={handleClose}
      title={t('test-suite-detail#delete-test-suite')}
      trackCloseIcon={trackCloseIcon}
    >
      <DialogContent>
        <DialogContentText>
          <div className="title m-0 text-left">
            {t('test-suite-detail#delete-test-suite-content')}
          </div>
        </DialogContentText>
      </DialogContent>
      <DialogActions className="popup-footer justify-content-end p-0">
        <ButtonToolbar>
          <Button
            id="cancel-confirmation-delete-test-suite-dialog"
            title={t('cancel')}
            color="secondary"
            onClick={handleClose}
            data-trackid="cancel-delete-test-suite"
            data-groupid={GroupEvent.ACCESS_TEST_MANAGEMENT}
          >
            {t('cancel')}
          </Button>
          <Button
            id="button-confirmation-delete-test-suite"
            title={t('delete')}
            color="danger"
            onClick={handleConfirmation}
            data-trackid="submit-delete-test-suite"
            data-groupid={GroupEvent.ACCESS_TEST_MANAGEMENT}
          >
            {t('delete')}
          </Button>
        </ButtonToolbar>
      </DialogActions>
    </CloseableDialogComponent>
  );
}
