import React from 'react';
import PropTypes from 'prop-types';
import { Link } from '@mui/material';
import { t } from '../i18n/t';

const ExternalLinkButton = (props) => {

  const { externalLink, isOpenNewTab, className } = props;

  const handleRedirect = () => {
    if (isOpenNewTab) {
      window.open(externalLink);
    } else {
      window.open(externalLink, '_self');
    }
  };

  return (
    <Link
      {...props}
      className={className || 'defaut-external-link-btn'}
      variant="contained"
      underline="none"
      color="primary"
      onClick={handleRedirect}
    >
      {props.children || t('learn_more')}
    </Link>
  );
};

ExternalLinkButton.propTypes = {
  className: PropTypes.string,
  externalLink: PropTypes.string,
  isOpenNewTab: PropTypes.bool,
};

ExternalLinkButton.defaultProps = {
  className: null,
  externalLink: '',
  isOpenNewTab: true,
};

export default ExternalLinkButton;
