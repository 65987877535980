import React from 'react';
import { Box, IconButton } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useGridApiContext, useGridSelector, gridDetailPanelExpandedRowsContentCacheSelector } from '@mui/x-data-grid-pro';
import PropTypes from 'prop-types';

function CustomDetailPanelToggle(props) {
  const { id, value: isExpanded } = props;
  const apiRef = useGridApiContext();

  const contentCache = useGridSelector(
    apiRef,
    gridDetailPanelExpandedRowsContentCacheSelector,
  );

  const hasDetail = React.isValidElement(contentCache[id]);

  return (
    <Box display="flex" justifyContent="flex-end" width="100%">
      <IconButton
        size="small"
        tabIndex={-1}
        disabled={!hasDetail}
        aria-label={isExpanded ? 'Close' : 'Open'}
      >
        <ExpandMoreIcon
          sx={{
            transform: `rotateZ(${isExpanded ? 180 : 0}deg)`,
            transition: (theme) =>
              theme.transitions.create('transform', {
                duration: theme.transitions.duration.shortest,
              }),
          }}
          fontSize="inherit"
        />
      </IconButton>
    </Box>
  );
}

CustomDetailPanelToggle.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  value: PropTypes.bool.isRequired,
};


export default CustomDetailPanelToggle;
