import get from 'lodash/get';
import React from 'react';
import PropTypes from 'prop-types';
import sortBy from 'lodash/sortBy';
import MTableColumnDataMapping from '../../components/table/models/MTableColumnDataMapping';
import { t } from '../../i18n/t';
import DecoratorConstants from '../../utils/DecoratorConstants';
import DeleteAgentDialog from '../dialog/DeleteAgentDialog';
import DataTable from './DataTable';
import Time from '../../utils/Moment';
import TableCore from './models/TableCore';

class TestCloudAgentDataTable extends React.Component {
  constructor(props) {
    super(props);
    this.agentTableRef = React.createRef();
    this.agentRemoveDialogRef = React.createRef();

    this.refreshData = this.refreshData.bind(this);
    this.getData = this.getData.bind(this);
    this.renderData = this.renderData.bind(this);
    this.calculateTotalDuration = this.calculateTotalDuration.bind(this);
    this.getColumnMapping = this.getColumnMapping.bind(this);

    this.state = {
      selectedAgent: {},
    };
  }

  refreshData() {
    if (this.agentTableRef && this.agentTableRef.current) {
      this.agentTableRef.current.refreshData();
    }
  }

  removeAgent(selectedAgent) {
    this.setState({ selectedAgent }, () => this.agentRemoveDialogRef.current.toggle());
  }

  getColumnMapping() {
    const { isConfigurationView } = this.props;
    const columnMapping = [
      new MTableColumnDataMapping(
        t('name'),
        'name',
        DecoratorConstants.testCloudAgentDetailDecorator,
      ),
      new MTableColumnDataMapping(
        t('agent#assigned-jobs'),
        'numAssignedJobs',
      ),
      new MTableColumnDataMapping(
        t('agent#executing-jobs'),
        'numExecutingJobs',
      ),
      new MTableColumnDataMapping(
        'OS',
        'os',
        (name, row) => {
          const osVersion = get(row, name);
          return DecoratorConstants.platform({ osVersion });
        },
      ),
    ];

    return isConfigurationView
      ? columnMapping
      : [...columnMapping,
        new MTableColumnDataMapping(
          t('test-cloud-agent#total-duration'),
          'totalDuration',
          (name, row) => {
            const time = get(row, name);
            return Time.duration(time);
          },
        )];
  }

  getData(data) {
    const { isConfigurationView } = this.props;

    if (isConfigurationView) {
      return data;
    }

    const sortedData = sortBy(data, ['os', 'browserVersion'], ['asc', 'desc']);

    const testCloudTotalDurationDatum = {
      os: 'Total',
      totalDuration: this.calculateTotalDuration(data)
    };

    return [
      ...sortedData,
      // Added extra row to show the Total of duration at the bottom line
      testCloudTotalDurationDatum
    ];
  }

  calculateTotalDuration(data = []) {
    return data.reduce((total, item) => total + item.totalDuration, 0);
  }

  renderData(data) {
    const dataToRender = this.getData(data);
    const columnMapping = this.getColumnMapping();
    return (
      <TableCore
        {...this.props}
        data={dataToRender}
        entityType="TestCloudAgent"
        tableId="TestCloudAgents"
        columnMapping={columnMapping}
      />
    );
  }

  render() {
    const { selectedAgent } = this.state;

    return (
      <div>
        <DataTable
          ref={this.agentTableRef}
          {...this.props}
          entityType="TestCloudAgent"
          tableId="TestCloudAgents"
          columnMapping={this.getColumnMapping()}
          render={this.renderData}
        />
        <DeleteAgentDialog
          ref={this.agentRemoveDialogRef}
          agent={selectedAgent}
          afterRemoval={this.refreshData}
        />
      </div>
    );
  }
}

TestCloudAgentDataTable.propTypes = {
  /**
   * Datable default search conditions
   */
  defaultSearchConditions: PropTypes.array,
  /**
   * The flag will be passed down to the DataTable
   */
  autoUpdate: PropTypes.bool,
  /**
   * Determine if the TestCloudAgentDataTable is rendered in Plan Configuration page or not
   */
  isConfigurationView: PropTypes.bool,
};

TestCloudAgentDataTable.defaultProps = {
  defaultSearchConditions: [],
  autoUpdate: false,
  isConfigurationView: false,
};

export default TestCloudAgentDataTable;
