import { Bar, CartesianGrid, ComposedChart, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis, Line } from 'recharts';
import React from 'react';
import { orderBy, startCase, toLower } from 'lodash';
import { durationFormatter, timeFormatter } from '../utils';
import colors from '../../../../scss/colors.scss';
import CustomChartTooltip from '../../../components/chart/CustomChartTooltip';
import { t } from '../../../i18n/t';

const ExecutionTrendTestRunChart = ({ data }) => {

  const customLegendPayload = [
    {
      dataKey: 'FAILED',
      color: colors.newFailed,
      value: 'FAILED',
      type: 'square',
    },
    {
      dataKey: 'PASSED',
      color: colors.newPassed,
      value: 'PASSED',
      type: 'square',
    },
    {
      dataKey: 'totalDuration',
      color: colors.totalDuration,
      value: 'totalDuration',
      type: 'line',
    },
  ];

  const formatLegendName = (name) => {
    if (name === 'totalDuration') {
      return t('totalDuration');
    }
    return startCase(toLower(name));
  };

  const renderTooltip = (active, payload) => {
    payload = orderBy(payload, ['name'], 'asc');
    return (
      <CustomChartTooltip active={active} payload={payload} />
    );
  };

  return (
    <ResponsiveContainer minWidth={200} aspect={2.25} position="center" className="custom-overview-chart">
      <ComposedChart
        margin={{
          top: 20,
          right: 20,
          bottom: 10,
          left: 0,
        }}
        data={data}
      >
        <XAxis axisLine={false} tickLine={false} tickFormatter={timeFormatter} dataKey="time" />
        <YAxis axisLine={false} tickLine={false} yAxisId="test-run-status" />
        <YAxis axisLine={false} tickLine={false} yAxisId="duration" tickFormatter={durationFormatter} orientation="right" />
        <Tooltip content={({ active, payload }) => renderTooltip(active, payload)} />
        <CartesianGrid vertical={false} stroke={colors.cardBorder} strokeWidth={0.5} />
        <Bar yAxisId="test-run-status" barSize={30} stackId="status" dataKey="PASSED" fill={colors.newPassed} isAnimationActive={false} />
        <Bar yAxisId="test-run-status" barSize={30} stackId="status" dataKey="FAILED" fill={colors.newFailed} isAnimationActive={false} />
        <Line
          yAxisId="duration"
          dataKey="totalDuration"
          stroke={colors.totalDuration}
          fill={colors.totalDuration}
          strokeWidth={2}
          isAnimationActive={false}
          activeDot={{ fill: colors.white, stroke: colors.totalDuration, strokeWidth: 3, r: 4 }}
        />
        <Legend iconSize={8} formatter={formatLegendName} verticalAlign="bottom" align="right" payload={customLegendPayload} />
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export default ExecutionTrendTestRunChart;
