import React from 'react';
import { Button, ButtonGroup, Card, CardBody, CardHeader, Row } from 'reactstrap';
import LogArea from '../../../components/LogArea';
import {
  IconDownloadActive,
  IconDownloadInactive,
  IconFullScreenActive,
  IconFullScreenInactive
} from '../../../images/CustomIcon';
import LogTreeContainer from './LogTreeContainer';
import Apis from '../../../utils/Apis';
import { t } from '../../../i18n/t';
import GroupEvent from '../../../utils/track/GroupEvent';

const LogCard = ({ stdout, log, testResult, openLogInFullScreen }) => {
  const [showLogTree, setShowLogTree] = React.useState(!!log);

  React.useEffect(() => {
    setShowLogTree(!!log);
  }, [log]);

  const setFullViewMode = () => {
    setShowLogTree(false);
  };

  const setTreeViewMode = () => {
    setShowLogTree(true);
  };

  // if (!stdout) {
  //   return null;
  // }

  return (
    <Card>
      <CardHeader>
        <Row className="mx-0 justify-content-between align-items-center flex-grow-1">
          <div className="d-flex">
            <span>{t('logs')}</span>
            <div className="d-flex">
              <Button
                type="button"
                className="two-state-button mx-1"
                color="link"
                title="View Full Screen"
                onClick={openLogInFullScreen}
                data-trackid="view-log-fullscreen"
              >
                <IconFullScreenActive className="icon-active" />
                <IconFullScreenInactive className="icon-inactive" />
              </Button>
            </div>
          </div>
          {log && (
            <ButtonGroup>
              {testResult.stdoutId && (
                <Button
                  className="action-button two-state-button"
                  color="link"
                  id="download-stdout"
                  key="download-stdout"
                  title={t('download')}
                  data-trackid="test-result-download-logs"
                  data-groupid={GroupEvent.ACCESS_REPORT}
                >
                  <a
                    href={Apis.executionTestResultLog(testResult.stdoutId)}
                    download="stdout.txt"
                  >
                    <IconDownloadActive className="icon-active" />
                    <IconDownloadInactive className="icon-inactive" />
                  </a>
                </Button>
              )}
              <Button data-trackid="view-full-log" disabled={!showLogTree} color="link" onClick={setFullViewMode}>
                {t('text_view')}
              </Button>
              <Button data-trackid="view-tree-log" disabled={showLogTree} color="link" onClick={setTreeViewMode}>
                {t('tree_view')}
              </Button>
            </ButtonGroup>
          )}
        </Row>
      </CardHeader>
      <CardBody className="shadow-none">
        {showLogTree
          ? <LogTreeContainer execution={testResult.execution} log={log} />
          : <LogArea rows="18" monoFont>{stdout}</LogArea>}
      </CardBody>
    </Card>
  );
};

export default LogCard;
