import React, { Component } from 'react';
import StatusBar from '../../components/chart/status-bar/StatusBar';
import { buildSearchFunction, buildSearchCondition } from '../../components/search/SearchUtils';
import DataLoader from '../../components/table/DataLoader';
import Arrays from '../../utils/Arrays';
import StatusConstants from '../../utils/Statuses';

class TestCaseStatusBar extends Component {
  constructor(props) {
    super(props);
    this.renderChart = this.renderChart.bind(this);
  }

  counterItems(data) {
    const statuses = {};

    let total = 0;
    data.filter((item) => !!item.content.ExecutionStatistics_status).forEach((item) => {
      statuses[item.content.ExecutionStatistics_status.toLowerCase()] = item.content.count;
      total += item.content.count;
    });

    return [
      {
        key: `total-${total}`,
        label: 'Total',
        value: total,
      },
      {
        key: `passed-${statuses.passed}`,
        label: StatusConstants.PASSED.label,
        value: statuses.passed || 0,
      },
      {
        key: `failed-${statuses.failed}`,
        label: StatusConstants.FAILED.label,
        value: statuses.failed || 0,
      },
    ];
  }

  convertDataChart(data) {
    const statuses = {
      passed: 0,
      failed: 0,
    };

    data.filter((item) => !!item.content.ExecutionStatistics_status).forEach((item) => {
      const status = item.content.ExecutionStatistics_status.toLowerCase();
      const count = item.content.count;

      if (status.includes('passed')) {
        statuses.passed += count;
      } else if (status.includes('failed')) {
        statuses.failed += count;
      }
    });

    const items = ['passed', 'failed'].reduce((prevItems, status) => {
      const count = statuses[status];
      if (count <= 0) {
        return prevItems;
      }
      return [
        ...prevItems,
        {
          key: `${status}-${count}`,
          value: count,
          label: status,
          color: status,
        },
      ];
    }, []);

    return items;
  }

  renderChart(data) {
    const counterItems = this.counterItems(data);
    const dataChart = this.convertDataChart(data);
    return (
      <StatusBar
        dataInfo={counterItems}
        dataChart={dataChart}
      />
    );
  }

  render() {
    const defaultSearchConditions = this.props.defaultSearchConditions ? this.props.defaultSearchConditions : [];
    const newDefaultSearchCondition = [
      buildSearchCondition('ExecutionStatistics.id', 'is not null', ''),
      ...Arrays.insertIf(defaultSearchConditions, ...defaultSearchConditions),
    ];

    return (
      <DataLoader
        render={this.renderChart}
        entityType="TestCase"
        defaultSearchFunctions={[
          buildSearchFunction('count', 'COUNT', ['ExecutionStatistics.id'])
        ]}
        defaultSearchConditions={newDefaultSearchCondition}
        groupBys={['ExecutionStatistics.status']}
        defaultSort={['ExecutionStatistics.status,asc']}
        noCard
        {...this.props}
      />
    );
  }
}

export default TestCaseStatusBar;
