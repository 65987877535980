import React, { useState } from 'react';
import { FormGroup, Label } from 'reactstrap';
import { badgeCountComponent } from '../../pages/execution/ExecutionDecorator';
import CreateExternalIssueBtn from '../../pages/execution_test_result/CreateExternalIssueBtn';
import { t } from '../../i18n/t';
import { ObjectType } from '../../utils/Constants';
import { useExternalConnection } from '../Hooks';
import ExternalIssueDataTable from './ExternalIssueDataTable';
import ExternalIssueList from './ExternalIssueList';
import KatalonAddonAlert from './KatalonAddonAlert';
import MContext from '../../models/MContext';
import KatalonPluginBtn from '../../pages/execution_test_result/KatalonPluginBtn';
import MFlags from '../../models/MFlags';

interface ExternalIssuesProps {
  title: string;
  projectId: string | number;
  objectType: string;
  objectId: number;
  linkIssueClassName?: string;
  pageSize?: number;
  bigSizeTitle?: boolean;
  isShortenContent?: boolean;
}

function ExternalIssues(props: Readonly<ExternalIssuesProps>) {
  const { title, projectId, objectType, objectId, linkIssueClassName, pageSize, bigSizeTitle, isShortenContent } = props;
  const connection = useExternalConnection(projectId);
  const isTeamDemo = MContext.isTeamDemo;
  const [externalIssues, setExternalIssues] = useState<object[] | null>(null);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const active = connection?.active;
  const katalonAddonElement = document.getElementById('has-katalon-add-on');
  const showCreateExternalIssue = ObjectType.EXECUTION_TEST_RESULT === objectType;
  const shouldDisableKatalonPluginBtn = !active || !katalonAddonElement || !!isTeamDemo;
  return (
    <>
      <FormGroup className={`d-flex justify-content-between ${linkIssueClassName} align-items-center mt-2 mb-0`}>
        <Label for="jira-issue" className={`d-flex align-items-center mr-2 ${bigSizeTitle && 'big-size-title-external-issue'}`}>
          {title}
          <div>
            {badgeCountComponent(externalIssues?.length)}
          </div>
        </Label>
        {MFlags.jiraSlackIntegrationEnabled
          ? <KatalonPluginBtn testRunId={objectId} disabled={shouldDisableKatalonPluginBtn} />
          : <CreateExternalIssueBtn testRunId={objectId} disabled={!active || !katalonAddonElement || isTeamDemo} />}
      </FormGroup>
      { MFlags.modifyDefectListInTestResultDetailEnabled ?
        <FormGroup className="overflow-auto">
          {!MFlags.jiraSlackIntegrationEnabled
                && active && !katalonAddonElement && showCreateExternalIssue && <KatalonAddonAlert />}
          <ExternalIssueList
            setDataState={setExternalIssues}
            emptyStateText={t('empty-state-defect#test-result')}
            projectId={projectId}
            objectType={objectType}
            objectId={objectId}
            key={objectId}
            noCard
            pageSize={pageSize}
            isShortenContent={isShortenContent}
          />
        </FormGroup> :
        <FormGroup className="pl-2">
          {!MFlags.jiraSlackIntegrationEnabled
              && active && !katalonAddonElement && showCreateExternalIssue && <KatalonAddonAlert />}
          <ExternalIssueDataTable
            projectId={projectId}
            objectType={objectType}
            objectId={objectId}
            key={objectId}
            noCard
            pageSize={pageSize}
          />
        </FormGroup>}
    </>
  );
}

export default ExternalIssues;
