import { get, remove, isEmpty } from 'lodash';
import MConfigs from '../../models/MConfigs';
import MFlags from '../../models/MFlags';
import { TestEntity } from '../../models/model/TestEntity';
import { TestProjectType } from '../../models/model/TestProjectType';
import {
  ACTIONS,
  ACTIONS_TRACKING,
  OperationType,
  SearchEntity,
  TEST_CASE_STATUS_TRACKING,
  TEST_TYPE_TRACKING_NAME,
  TestSuiteType
} from '../Constants';
import TestProjectHelper from '../TestProjectHelper';
import { sendAnalyticEventForAction } from '../SegmentAnalytics';
import { TestProject } from '../../models/model/TestProject';
import { TestType } from '../../models/model/TestType';

export default class ActionsHelper {

  static filterListActions = (actions: any, data: TestEntity, testProject: TestProject) => {
    if (isEmpty(actions)) {
      return actions;
    }

    const { testCase, testSuite } = data;
    const { removeGitSupportForCloudStudioEnabled } = MFlags;

    if (removeGitSupportForCloudStudioEnabled
        && testProject.type === TestProjectType.GIT
        && ((testCase && testCase.testType === TestType.G5_TEST_CASE) || (testSuite && testSuite.type === TestSuiteType.CLOUD_STUDIO))) {
      remove(actions, (action) => action === ACTIONS.EDIT);
    }

    return actions;
  };

  static getAllowActions(data: TestEntity) {
    const { testCase, testSuite, draftTestCase } = data;

    if (draftTestCase) {
      return [ACTIONS.EDIT, ACTIONS.DUPLICATE];
    }

    let testEntity;
    let type;
    let testProject;
    if (testCase) {
      testEntity = 'testCase';
      type = testCase.testType;
      testProject = testCase.testProject;
    } else if (testSuite) {
      testEntity = 'testSuite';
      type = testSuite.type;
      testProject = testSuite.testProject;
    } else {
      return [];
    }

    if (!testProject) {
      return [];
    }

    const { testManagementConfiguration } = MConfigs;
    const testProjectType: TestProjectType | string = get(testProject, 'type', '');
    const actions = get(testManagementConfiguration, ['testProject', testProjectType, testEntity, type, 'actions'], []);
    return this.filterListActions(actions, data, testProject);
  }

  static getEntityType(testEntity: TestEntity) {
    if (testEntity.testCase) {
      return SearchEntity.TestCase;
    } else if (testEntity.testSuite) {
      return SearchEntity.TestSuite;
    }
    return '';
  }

  static syncTrackingData(
    data: TestEntity,
    action: string,
    displayedPage: string,
    testSuiteDestination?: string,
    operationType: string = OperationType.INDIVIDUAL,
  ) {
    const { draftTestCase, testCase, testSuite } = data;
    let testProject;
    let type = '';
    let testCaseStatus: string | undefined;
    if (draftTestCase) {
      type = draftTestCase.testType;
      testCaseStatus = TEST_CASE_STATUS_TRACKING.DRAFT;
    } else if (testCase) {
      testProject = testCase.testProject;
      type = testCase.testType;
      testCaseStatus = TEST_CASE_STATUS_TRACKING.PUBLISHED;
    } else if (testSuite) {
      testProject = testSuite.testProject;
      type = testSuite.type;
    }
    if (action !== ACTIONS_TRACKING.EDIT_TEST_CASE_TRIGGERED && action !== ACTIONS_TRACKING.TEST_CASE_DUPLICATED) {
      testCaseStatus = undefined;
    }
    sendAnalyticEventForAction(
      action,
      {
        displayed_page: displayedPage,
        storage_type: testProject && TestProjectHelper.getStorageType(testProject),
        test_case_type: TEST_TYPE_TRACKING_NAME[type],
        test_case_status: testCaseStatus && testCaseStatus,
        ts_destination: testSuiteDestination,
        operation_type: operationType,
      }
    );
  }
}
