import React from 'react';
import {
  Button, Form, FormGroup, Label, Row, Col, Card, CardBody
} from 'reactstrap';
import _ from 'lodash';
import { t } from '../../i18n/t';
import Apis from '../../utils/Apis';
import Notification from '../../utils/Notification';
import Input from '../../components/Input';
import Select from '../../components/Select';
import MContext from '../../models/MContext';
import PageComponent from '../../components/PageComponent';
import Services from '../../utils/Services';
import Routes from '../../utils/Routes';
import http from '../../utils/http';
import ObjectSummary from '../../components/summary/ObjectSummary';
import DefaultLayout from '../../components/DefaultLayout';
import Time from '../../utils/Moment';
import DatePicker from '../../components/DatePicker';
import Moment from '../../utils/Moment';

class Release extends PageComponent {
  constructor(props) {
    super(props);
    this.teamId = MContext.teamId;
    this.projectId = MContext.projectId;
    this.releaseId = MContext.releaseId;
    this.state = this.getInitialState();
    this.isCreateRelease = !this.releaseId;
    if (this.isCreateRelease) {
      this.meta.id = 'page-create-release';
      this.meta.title = t('releases#create');
    } else {
      this.meta.id = 'page-update-release';
    }

    this.handleTextChange = this.handleTextChange.bind(this);
    this.getRelease = this.getRelease.bind(this);
    this.updateRelease = this.updateRelease.bind(this);
    this.onSelectProject = this.onSelectProject.bind(this);
    this.onSelectRelease = this.onSelectRelease.bind(this);
    this.checkIntegrationActive = this.checkIntegrationActive.bind(this);
    this.renderHeader = this.renderHeader.bind(this);
    this.renderBody = this.renderBody.bind(this);
    this.disableInput = this.disableInput.bind(this);
    this.enableInput = this.enableInput.bind(this);
  }

  disableInput() {
    this.setState({ disabledInput: 'disabled' });
  }

  enableInput() {
    this.setState({ disabledInput: '' });
  }

  getInitialState() {
    return {
      formData: {
        id: this.releaseId,
        name: '',
        description: '',
        projectId: this.projectId,
        startTime: null,
        endTime: null,
        externalRelease: {},
      },
      integrationProject: {},
      integrationRelease: {},
      integrationProjects: [],
      integrationReleases: [],
      isIntegrationActive: false,
      disabledInput: '',
    };
  }

  componentDidMount() {
    if (this.releaseId) {
      this.getRelease();
    }
    this.checkIntegrationActive();
    this.getIntegrationProjects();
  }

  checkIntegrationActive() {
    const params = {
      pagination: {
        page: 0,
        size: 1,
        sorts: 'order,desc',
      },
      conditions: [
        {
          key: 'Project.id',
          operator: '=',
          value: this.projectId,
        },
      ],
      type: 'ExternalConnection',
    };
    Services.search(params)
      .then(({ content }) => {
        const connection = content[0];
        if (connection) {
          this.setState({ isIntegrationActive: connection.active });
        }
      });
  }

  getIntegrationProjects() {
    http.get(Apis.integrationProjects({ projectId: this.projectId }))
      .then((response) => {
        this.setState({ integrationProjects: response });
      });
  }

  getIntegrationReleases(externalProjectId) {
    return http.get(Apis.integrationReleases({
      projectId: this.projectId,
      integrationProjectId: externalProjectId,
    }));
  }

  getRelease() {
    const params = {
      pagination: {
        page: 0,
        size: 1,
        sorts: 'order,desc',
      },
      conditions: [
        {
          key: 'id',
          operator: '=',
          value: this.releaseId,
        },
      ],
      type: 'Release',
    };
    Services.search(params)
      .then(({ content }) => {
        const release = content[0];
        this.meta.title = t(
          '{{page}} {{name}}',
          { name: release.name, page: t('release') }
        );
        const formData = this.state.formData;
        const externalRelease = release.externalRelease;
        if (!_.isEmpty(externalRelease)) {
          let integrationRelease = {};
          let integrationProject = {};
          integrationRelease = externalRelease;
          integrationProject = externalRelease.externalProject;
          this.getIntegrationReleases(integrationProject.externalProjectId)
            .then((response) => {
              this.setState({
                integrationReleases: response,
                integrationRelease,
                integrationProject,
              });
            });
        }
        if (release) {
          this.setState({
            formData: { ...formData, ...release },
          });
        }
      });
  }

  onSelectProject(event) {
    const integrationProject = event ? event.item : {};
    if (event) {
      this.getIntegrationReleases(integrationProject.externalProjectId).then((response) => {
        this.setState({
          integrationReleases: response,
        });
      });
    }
    this.setState({
      integrationProject,
      integrationRelease: {},
    });
  }

  onSelectRelease(event) {
    const integrationRelease = event ? event.item : {};
    this.setState({
      integrationRelease,
    });
  }

  handleTextChange(event) {
    const name = event.target.name;
    const value = event.target.value;
    const formData = this.state.formData;
    formData[name] = value;
    this.setState(formData);
  }

  handleChange(name, value) {
    if (value) {
      value = value.format(Moment.LOCAL_DATE_FORMAT);
    }
    const formData = this.state.formData;
    formData[name] = value;
    this.setState(formData);
  }

  updateRelease(e) {
    e.preventDefault();
    this.disableInput();
    const { formData, integrationProject, integrationRelease } = this.state;
    let externalRelease = null;
    if (!_.isEmpty(integrationRelease)) {
      externalRelease = {
        ...integrationRelease,
        externalProject: integrationProject,
      };
    }
    formData.externalRelease = externalRelease;
    if (this.isCreateRelease) {
      Services.createRelease(formData).then((release) => {
        Notification.pushSuccess(`Release ${release.name} was created.`);
        Routes.goToReleaseLink();
      })
        .catch(() => {
          this.enableInput();
        });
    } else {
      Services.updateRelease(formData).then((release) => {
        Notification.pushSuccess(`Release ${release.name} was updated.`);
        Routes.goToViewReleaseLink(this.releaseId);
      })
        .catch(() => {
          this.enableInput();
        });
    }
  }

  renderObjectSummary() {
    const { name } = this.state.formData;
    const urlParams = {
      releases: t('releases'),
      releaseUpdate: this.isCreateRelease
        ? t('releases#create')
        : name,
    };
    return (
      <ObjectSummary params={urlParams} />
    );
  }

  renderHeader() {
    return (
      <>
        {this.renderObjectSummary()}
      </>
    );
  }

  renderBody() {
    const {
      formData, integrationProjects, integrationReleases, integrationProject, integrationRelease,
      isIntegrationActive, disabledInput
    } = this.state;
    const { name, description, startTime, endTime } = formData;
    const submitButton = this.isCreateRelease
      ? t('create')
      : t('update');

    const integrationProjectOpts = integrationProjects.map((item) => ({
      value: item.externalProjectId,
      item,
      label: item.name,
    }));

    const integrationReleaseOpts = integrationReleases.map((item) => ({
      value: item.releaseId,
      item,
      label: item.name,
    }));

    const dataTrackId = this.isCreateRelease ? 'create-release' : 'update-release';
    return (
      <Card>
        <CardBody>
          <Row>
            <Col sm="12" md="8" lg="6" xl="5">
              <Form data-trackid={dataTrackId} onSubmit={this.updateRelease}>
                <FormGroup>
                  <Label for="name">Name</Label>
                  <Input
                    id="name"
                    name="name"
                    value={name}
                    disabled={disabledInput}
                    onChange={this.handleTextChange}
                    type="text"
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Label>{t('release#start-time')}</Label>
                  <DatePicker
                    id="startTime"
                    name="startTime"
                    inputFormat={Time.DATE_FORMAT}
                    value={startTime}
                    maxDate={endTime ? Time.convertToMoment(endTime) : Time.MAX_DATE}
                    onChange={(value) => this.handleChange('startTime', value)}
                  />
                </FormGroup>
                <FormGroup>
                  <Label>{t('release#end-time')}</Label>
                  <DatePicker
                    id="endTime"
                    name="endTime"
                    inputFormat={Time.DATE_FORMAT}
                    value={endTime}
                    minDate={startTime ? Time.convertToMoment(startTime) : Time.MIN_DATE}
                    onChange={(value) => this.handleChange('endTime', value)}
                  />
                </FormGroup>
                {isIntegrationActive && (
                  <>
                    <FormGroup>
                      <Label for="integrationProject">Jira Project</Label>
                      <Select
                        clearable
                        options={integrationProjectOpts}
                        onChange={this.onSelectProject}
                        disabled={disabledInput}
                        value={integrationProject.externalProjectId}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label for="integrationRelease">Jira Release</Label>
                      <Select
                        clearable
                        options={integrationReleaseOpts}
                        onChange={this.onSelectRelease}
                        disabled={disabledInput}
                        value={integrationRelease.releaseId}
                      />
                    </FormGroup>
                  </>)}
                <FormGroup>
                  <Label for="description">Description</Label>
                  <Input
                    id="description"
                    name="description"
                    value={description}
                    onChange={this.handleTextChange}
                    disabled={disabledInput}
                    type="textarea"
                    rows="5"
                  />
                </FormGroup>
                <Button type="submit" color="primary" disabled={disabledInput}>{submitButton}</Button>
              </Form>
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  }

  render() {
    const { isTeamDemo } = MContext;
    if (isTeamDemo) {
      return null;
    }
    return (
      <DefaultLayout
        renderHeader={this.renderHeader}
        renderBody={this.renderBody}
      />
    );
  }

}

export default Release;
