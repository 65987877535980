import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import { Tab } from '@mui/material';
import { TabPanel } from '@mui/lab';
import Chip from '@mui/material/Chip';
import ClearIcon from '@mui/icons-material/Clear';
import { Alert, FormGroup, FormText, Label } from 'reactstrap';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import NestedMenu from './NestedMenu';
import {
  buildDisplayExecutionEnvironment,
  buildKey,
  isMacOs,
  isMobile,
  buildDeviceKey,
} from '../smarttestscheduling/services/testcloud';

import { t } from '../../i18n/t';
import Select from '../Select';
import {
  ALL_BROWSERS_TESTCLOUD_AGENT,
  BROWSER_HEADLESS_SUFFIX,
  BrowserType,
  CloudType,
  ConfigType, OsType,
  RUN_TYPE
} from '../../utils/Constants';
import Routes from '../../utils/Routes';
import Link from '../Link';
import ToggleTunnelContainer from '../smarttestscheduling/components/ToggleTunnelContainer';
import MContext from '../../models/MContext';
import InfoIcon from '../../../images/icons/katalonui/InfoIcon';

export default function TestCloudEnvironment({
  testCloudEnv,
  enabledTestCloudTunnel,
  toggleTestCloudTunnelIntegration,
  enableApiTesting, updateEnableApiTesting,
  tunnelId, handleSelectedTunnelChange,
  apps, handleChangeExecutionEnvironments, agents, configType, requireTestEnv,
  hideProfileWithTunnel = false, hideTabPanel = false,
  testSuiteId = null,
}) {

  const initSelectedMap = new Map([
    [RUN_TYPE.MOBILE_BROWSERS, []],
    [RUN_TYPE.MOBILE_NATIVE_APP, []],
    [RUN_TYPE.DESKTOP_BROWSERS, []],
    [RUN_TYPE.WEB_SERVICES, []]
  ]);

  const initDisabledMap = new Map([
    [RUN_TYPE.MOBILE_BROWSERS, []],
    [RUN_TYPE.MOBILE_NATIVE_APP, []],
    [RUN_TYPE.DESKTOP_BROWSERS, []],
    [RUN_TYPE.WEB_SERVICES, []]
  ]);

  const getTabValue = (agent) => {
    if (BrowserType.WEB_SERVICE === agent.browserType) {
      return RUN_TYPE.WEB_SERVICES;
    }
    if (isMobile(agent.os)) {
      if (agent.appId) {
        return RUN_TYPE.MOBILE_NATIVE_APP;
      }
      return RUN_TYPE.MOBILE_BROWSERS;
    }
    return RUN_TYPE.DESKTOP_BROWSERS;
  };

  const getInitialTabValue = (agents) => {
    if (!isEmpty(agents) && agents[0]) {
      return getTabValue(agents[0]);
    }
    return RUN_TYPE.DESKTOP_BROWSERS;
  };

  const [tabValue, setTabValue] = useState(getInitialTabValue(agents));
  const [selectedExecutionEnvironmentsMap, setSelectedExecutionEnvironmentsMap] = useState(initSelectedMap);
  const [disabledExecutionEnvironmentsMap, setDisabledExecutionEnvironmentsMap] = useState(initDisabledMap);
  const [executionEnvironments, setExecutionEnvironments] = useState();
  const [app, setApp] = useState({});
  const routes = new Routes();

  const numOfMobileLevels = 4;
  const numOfDesktopLevels = 3;

  const getBrowserType = (value) => {
    switch (value) {
      case RUN_TYPE.MOBILE_BROWSERS:
      case RUN_TYPE.MOBILE_NATIVE_APP:
      case RUN_TYPE.DESKTOP_BROWSERS:
        return BrowserType.ALL;
      case RUN_TYPE.WEB_SERVICES:
        return BrowserType.WEB_SERVICE;
      default:
        return null;
    }
  };

  const getApp = (value) => {
    if (RUN_TYPE.MOBILE_NATIVE_APP === value) {
      return apps.find((a) => a.value === app);
    }
    return null;
  };

  const filteredExecutionEnvironments = (tabValue) => {
    switch (tabValue) {
      case RUN_TYPE.MOBILE_BROWSERS:
      case RUN_TYPE.MOBILE_NATIVE_APP:
        return testCloudEnv.data.filter((el) => isMobile(el.value));
      case RUN_TYPE.DESKTOP_BROWSERS:
        return testCloudEnv.data.filter((el) => !isMobile(el.value))
          .map((el) => {
            const { children, ...object } = el;
            const result = children.filter((value) => !value.headless || configType !== ConfigType.COMMAND);
            return { ...object, children: result };
          });
      case RUN_TYPE.WEB_SERVICES:
        return testCloudEnv.data.filter((el) => !isMobile(el.value) && !isMacOs(el.value))
          .map((el) => {
            const { children, ...object } = el;
            const result = children.filter((value) => !value.headless);
            return { ...object, children: result };
          });
      default:
        return [];
    }
  };

  const handleChangeTab = (e, newValue) => {
    setTabValue(newValue);
  };

  const getEnvironmentParams = (configType, selectedItems, testSuiteId) => (configType === ConfigType.TSC
    ? { selectedItems, testSuiteId }
    : selectedItems);

  const handleChangeApp = (option) => {
    setApp(option.value);
    if (isEmpty(selectedExecutionEnvironmentsMap.get(tabValue))) {
      return;
    }
    const selectedItems = selectedExecutionEnvironmentsMap.get(tabValue).map((value) => ({
      ...value,
      appId: option.value,
      appName: option.name,
      appVersion: option.appVersion,
      appVersionCode: option.appVersionCode,
    }));

    setSelectedExecutionEnvironmentsMap((prevMap) => {
      const newMap = new Map(prevMap);
      newMap.set(tabValue, selectedItems);
      return newMap;
    });

    handleChangeExecutionEnvironments(getEnvironmentParams(configType, selectedItems, testSuiteId));
  };

  const handleChangeSelectedEnvs = (tabValue, env) => {
    const app = getApp(tabValue);
    const selected = { ...env,
      browserType: getBrowserType(tabValue),
      appId: app?.value,
      appName: app?.name,
      appVersion: app?.appVersion,
      appVersionCode: app?.appVersionCode,
      cloudType: CloudType.TEST_CLOUD_AGENT,
      runType: tabValue,
      enabledTestCloudTunnel };
    const selectedItems = [...selectedExecutionEnvironmentsMap.get(tabValue), selected];
    selectedExecutionEnvironmentsMap.set(tabValue, selectedItems);
    setSelectedExecutionEnvironmentsMap(selectedExecutionEnvironmentsMap);
  };

  const handleSelectWebServices = () => {
    const selected = {
      cloudType: CloudType.TEST_CLOUD_AGENT,
      enabledTestCloudTunnel,
      runType: RUN_TYPE.WEB_SERVICES,
      os: OsType.Linux.toLowerCase(),
      browser: ALL_BROWSERS_TESTCLOUD_AGENT.browser,
      browserType: BrowserType.WEB_SERVICE,
      browserVersion: ALL_BROWSERS_TESTCLOUD_AGENT.browserVersion,
    };

    const selectedItems = [selected];
    setSelectedExecutionEnvironmentsMap((prevMap) => {
      const newMap = new Map(prevMap);
      newMap.set(RUN_TYPE.WEB_SERVICES, selectedItems);
      return newMap;
    });
    handleChangeExecutionEnvironments(getEnvironmentParams(configType, selectedItems, testSuiteId));
  };

  const handleChangeDisabledEnvs = (tabValue, env) => {
    const disableItems = [...disabledExecutionEnvironmentsMap.get(tabValue), env.key];
    disabledExecutionEnvironmentsMap.set(tabValue, disableItems);
    setDisabledExecutionEnvironmentsMap(disabledExecutionEnvironmentsMap);
  };

  const handleDeleteSelectedEnvs = (tabValue, env) => {
    setSelectedExecutionEnvironmentsMap((prevMap) => {
      const newMap = new Map(prevMap);
      const newSelectedItems = newMap.get(tabValue).filter((item) => item.key !== env.key);
      newMap.set(tabValue, newSelectedItems);
      const environmentParams = configType === ConfigType.TSC
        ? { selectedItems: [], testSuiteId }
        : newMap.get(tabValue);

      handleChangeExecutionEnvironments(environmentParams);
      return newMap;
    });
  };

  const handleDeleteDisabledEnvs = (tabValue, env) => {
    setDisabledExecutionEnvironmentsMap((prevMap) => {
      const newDisabledMap = new Map(prevMap);
      const environmentParams = configType === ConfigType.TSC ?
        [] : newDisabledMap.get(tabValue).filter((item) => item !== env.key);
      newDisabledMap.set(tabValue, environmentParams);
      return newDisabledMap;
    });
  };

  const handleSelectedItem = (env) => {
    handleChangeSelectedEnvs(tabValue, env);
    handleChangeDisabledEnvs(tabValue, env);
    handleChangeExecutionEnvironments(getEnvironmentParams(configType, selectedExecutionEnvironmentsMap.get(tabValue), testSuiteId));
  };

  const handleDeleteItem = (env) => {
    handleDeleteSelectedEnvs(tabValue, env);
    handleDeleteDisabledEnvs(tabValue, env);
  };

  const transformAgens = (agents) => {
    if (isMobile(agents[0].os)) {
      return agents.map((agent) => ({ ...agent, key: buildDeviceKey(agent.deviceId, agent.os, agent.osVersion, agent.devicePoolId) }));
    }
    return agents.map((agent) => ({ ...agent,
      key: buildKey(agent.os, agent.headless && !agent.browser?.includes(BROWSER_HEADLESS_SUFFIX) ?
        `${agent.browser}${BROWSER_HEADLESS_SUFFIX}` : agent.browser, agent.browserVersion) }));
  };

  useEffect(() => {
    if (isEmpty(agents) || !agents[0]) {
      return;
    }

    const result = transformAgens(agents);
    const keys = result.map((agent) => agent.key);
    setTabValue(() => {
      const tabValue = getTabValue(agents[0]);
      selectedExecutionEnvironmentsMap.set(tabValue, result);
      disabledExecutionEnvironmentsMap.set(tabValue, keys);
      return tabValue;
    });

  }, [agents]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isEmpty(agents) && agents[0]?.appId) {
      setApp(agents[0].appId);
    } else if (!isEmpty(apps)) {
      setApp(apps[0].value);
    }
  }, [agents, apps]);

  useEffect(() => {
    setExecutionEnvironments(filteredExecutionEnvironments(tabValue));
    const environmentParams = getEnvironmentParams(configType, selectedExecutionEnvironmentsMap.get(tabValue), testSuiteId);
    handleChangeExecutionEnvironments(environmentParams);
  }, [tabValue]); // eslint-disable-line react-hooks/exhaustive-deps

  const isSingleSelectionMode = (configType, selectedExecutionEnvironmentsMap, tabValue) => configType === ConfigType.TSC && selectedExecutionEnvironmentsMap.get(tabValue).length >= 1;

  return (
    <Box>
      <Box sx={{
        padding: '0.25rem 0.75rem',
        borderRadius: '6px',
        border: 'solid 1px #d5d8dd',
        backgroundColor: '#fff',
        minHeight: '2.5rem',
        maxHeight: '5rem',
        margin: '0.5rem 0 0.5rem 0',
        overflowY: 'scroll'
      }}
      >{
          selectedExecutionEnvironmentsMap.get(tabValue).map((item) => (
            <Chip
              sx={{ borderRadius: '5px', height: '1.5rem', margin: '0.25rem 0.5rem 0.25rem 0' }}
              deleteIcon={tabValue === RUN_TYPE.WEB_SERVICES ? undefined : <ClearIcon />}
              key={item.key}
              label={buildDisplayExecutionEnvironment(item)}
              onDelete={tabValue === RUN_TYPE.WEB_SERVICES ? null : () => handleDeleteItem(item)}
            />))
        }
      </Box>
      <TabContext value={tabValue}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList className="tab-active" onChange={handleChangeTab} aria-label="lab API tabs example">
            <Tab label="Desktop Browsers" value={RUN_TYPE.DESKTOP_BROWSERS} />
            {!hideTabPanel && <Tab label="Mobile Browsers" value={RUN_TYPE.MOBILE_BROWSERS} />}
            {MContext.testCloudMobileNativeAppEnabled && !hideTabPanel && <Tab label="Mobile Native App" value={RUN_TYPE.MOBILE_NATIVE_APP} />}
            {!hideTabPanel && <Tab
              label="Web Services"
              onClick={handleSelectWebServices}
              value={RUN_TYPE.WEB_SERVICES}
            />}
          </TabList>
        </Box>
        {!hideTabPanel &&
          <TabPanel sx={{ padding: '1.5rem 0' }} value={RUN_TYPE.MOBILE_BROWSERS}>
            <NestedMenu
              options={executionEnvironments}
              numOfLevels={numOfMobileLevels}
              handleSelectedItem={handleSelectedItem}
              disabledItems={disabledExecutionEnvironmentsMap.get(tabValue)}
              singleSelectionMode={isSingleSelectionMode(configType, selectedExecutionEnvironmentsMap, tabValue)}
            />
            {!hideProfileWithTunnel && configType !== ConfigType.TSC &&
            <ToggleTunnelContainer
              enabledTestCloudTunnel={enabledTestCloudTunnel}
              toggleTestCloudTunnelIntegration={toggleTestCloudTunnelIntegration}
              enableApiTesting={enableApiTesting}
              updateEnableApiTesting={updateEnableApiTesting}
              tunnelId={tunnelId}
              handleSelectedTunnelChange={handleSelectedTunnelChange}
            />}
          </TabPanel>}
        {!hideTabPanel && MContext.testCloudMobileNativeAppEnabled &&
          <TabPanel sx={{ padding: '1.5rem 0' }} value={RUN_TYPE.MOBILE_NATIVE_APP}>
            <NestedMenu
              options={executionEnvironments}
              numOfLevels={numOfMobileLevels}
              handleSelectedItem={handleSelectedItem}
              disabledItems={disabledExecutionEnvironmentsMap.get(tabValue)}
              singleSelectionMode={isSingleSelectionMode(configType, selectedExecutionEnvironmentsMap, tabValue)}
            />
            {configType !== ConfigType.TSC &&
            <ToggleTunnelContainer
              enabledTestCloudTunnel={enabledTestCloudTunnel}
              toggleTestCloudTunnelIntegration={toggleTestCloudTunnelIntegration}
              enableApiTesting={enableApiTesting}
              updateEnableApiTesting={updateEnableApiTesting}
              tunnelId={tunnelId}
              handleSelectedTunnelChange={handleSelectedTunnelChange}
            />}
            <FormGroup>
              <div className="d-flex">
                <Label for="mobileNative" className="normal-label">{t('Mobile Native App')}</Label>
              </div>
              <Select
                required
                clearable={false}
                id="mobileNative"
                value={app}
                onChange={handleChangeApp}
                options={apps}
                maxMenuHeight={125}
                menuPosition="fixed"
                isCustom
                styles={{
                  menu: (provided) => ({
                    ...provided,
                    position: 'relative'
                  })
                }}
              />
              <FormText>
                {t('schedule-v1#select-native-app#desc')}
                <Link href={routes.native_apps_link}>
                  {t('schedule-v1#select-native-app#click-here-to-upload')}
                </Link>.
              </FormText>
              {requireTestEnv && <small className="text-danger fs-6">This is required</small>}
            </FormGroup>
          </TabPanel>}
        <TabPanel sx={{ padding: '1.5rem 0' }} value={RUN_TYPE.DESKTOP_BROWSERS}>
          <NestedMenu
            options={executionEnvironments}
            numOfLevels={numOfDesktopLevels}
            handleSelectedItem={handleSelectedItem}
            disabledItems={disabledExecutionEnvironmentsMap.get(tabValue)}
            singleSelectionMode={isSingleSelectionMode(configType, selectedExecutionEnvironmentsMap, tabValue)}
          />
          {!hideProfileWithTunnel && configType !== ConfigType.TSC &&
            <ToggleTunnelContainer
              enabledTestCloudTunnel={enabledTestCloudTunnel}
              toggleTestCloudTunnelIntegration={toggleTestCloudTunnelIntegration}
              enableApiTesting={enableApiTesting}
              updateEnableApiTesting={updateEnableApiTesting}
              tunnelId={tunnelId}
              handleSelectedTunnelChange={handleSelectedTunnelChange}
            />}
        </TabPanel>
        {!hideTabPanel &&
          <TabPanel
            sx={{ padding: '1.5rem 0' }}
            value={RUN_TYPE.WEB_SERVICES}
          >
            <Alert color="primary"><InfoIcon /> {
                  t('run-configuration#test-suite-web-services-guide')
                }
            </Alert>
            {!hideProfileWithTunnel && configType !== ConfigType.TSC &&
            <ToggleTunnelContainer
              enabledTestCloudTunnel={enabledTestCloudTunnel}
              toggleTestCloudTunnelIntegration={toggleTestCloudTunnelIntegration}
              tunnelId={tunnelId}
              handleSelectedTunnelChange={handleSelectedTunnelChange}
            />}
          </TabPanel>}
      </TabContext>
    </Box>
  );
}

TestCloudEnvironment.propTypes = {
  testCloudEnv: PropTypes.object,
  enabledTestCloudTunnel: PropTypes.bool,
  toggleTestCloudTunnelIntegration: PropTypes.func,
  enableApiTesting: PropTypes.bool,
  updateEnableApiTesting: PropTypes.func,
  apps: PropTypes.array,
  handleChangeExecutionEnvironments: PropTypes.func,
  agents: PropTypes.array,
  configType: PropTypes.string,
  requireTestEnv: PropTypes.bool,
  hideProfileWithTunnel: PropTypes.bool,
  hideTabPanel: PropTypes.bool,
  tunnelId: PropTypes.string || null,
  handleSelectedTunnelChange: PropTypes.func,
  testSuiteId: PropTypes.number,
};

TestCloudEnvironment.defaultProps = {
  testSuiteId: null,
};
