import React from 'react';
import { Button, Card, CardBody, CardHeader, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import PageComponent from '../components/PageComponent';
import { t } from '../i18n/t';
import MAuth from '../models/MAuth';
import MContext from '../models/MContext';
import Routers from '../utils/Routes';
import { notificationIds } from '../utils/NotificationPageUtils';
import EmailConfiguration from './EmailConfiguration';
import DefaultLayout from '../components/DefaultLayout';
import ObjectSummary from '../components/summary/ObjectSummary';
import MConfigs from '../models/MConfigs';
import Services from '../utils/Services';
import Notification from '../utils/Notification';
import LicenseServerConfiguration from '../components/configuration/LicenseServerConfiguration';

export default class Configuration extends PageComponent {
  constructor(props) {
    super(props);
    this.meta.id = 'page-testops-configuration';
    this.meta.title = t('email_server_configuration');

    // default team id
    this.organizationId = MContext.organizationId;
    this.username = MAuth.email;
    this.isOnPremise = MConfigs.isOnPremise;

    this.state = {
      testOpsUrl: '',
    };

    this.renderHeader = this.renderHeader.bind(this);
    this.renderBody = this.renderBody.bind(this);
    this.handleTextChange = this.handleTextChange.bind(this);
    this.updateTestOpsConfiguration = this.updateTestOpsConfiguration.bind(this);
  }

  componentWillMount() {
    Services.getTestOpsConfiguration(this.organizationId).then((response) => this.setState(response));
  }

  handleTextChange(event) {
    const value = event.target.value;
    const name = event.target.name;
    this.setState({ [name]: value });
  }

  updateTestOpsConfiguration(event) {
    event.preventDefault();
    const configurationData = {
      testOpsUrl: this.state.testOpsUrl
    };

    Services.updateTestOpsConfiguration(configurationData, this.organizationId)
      .then((response) => {
        this.setState(response);
        Notification.pushSuccess('Update successfully');
      });
  }

  renderTestOpsLocation() {
    return (
      <Card>
        <CardHeader>{t('testOps-configuration#location')}</CardHeader>
        <CardBody>
          <Row>
            <Col sm="12" md="8" lg="6" xl="5">
              <Form data-trackid="update-testOps-configuration" onSubmit={this.updateTestOpsConfiguration}>
                <FormGroup>
                  <Label for="testOpsUrl">{t('url')}</Label>
                  <Input
                    id="testOpsUrl"
                    name="testOpsUrl"
                    required
                    value={this.state.testOpsUrl}
                    onChange={this.handleTextChange}
                    type="text"
                    placeholder="http://localhost:8080"
                  />
                </FormGroup>
                <FormGroup>
                  <Button type="submit" color="primary">{t('testOps-configuration#update')}</Button>
                </FormGroup>
              </Form>
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  }

  renderObjectSummary() {
    const urlParams = {
      configuration: t('email_server_configuration'),
    };
    return (
      <ObjectSummary params={urlParams} />
    );
  }

  renderHeader() {
    return (
      <>
        {this.renderObjectSummary()}
      </>
    );
  }

  renderBody() {
    return (
      <>
        <EmailConfiguration />
        {this.renderTestOpsLocation()}
        <LicenseServerConfiguration />
      </>
    );
  }

  render() {
    const isOrganizationManager = MAuth.isOrganizationManager(this.organizationId);

    if (!isOrganizationManager || !this.isOnPremise) {
      Routers.goToNotificationPage(notificationIds.NO_PERMISSION);
    }

    return (
      <DefaultLayout
        renderHeader={this.renderHeader}
        renderBody={this.renderBody}
      />
    );
  }
}
