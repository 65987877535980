import React from 'react';
import PageComponent from '../../components/PageComponent';
import { t } from '../../i18n/t';
import TestCasePriorityDataTable from '../../components/table/TestCasePriorityDataTable';
import MContext from '../../models/MContext';
import ObjectSummary from '../../components/summary/ObjectSummary';
import DefaultLayout from '../../components/DefaultLayout';

class TestCasePriorities extends PageComponent {

  constructor(props) {
    super(props);
    this.meta.id = 'page-test-case-priorities';
    this.project = MContext.project;
    this.meta.title = t(
      'Project {{name}} - {{page}}',
      { name: this.project.name, page: 'Test Case Priorities' },
    );
    this.renderHeader = this.renderHeader.bind(this);
    this.renderBody = this.renderBody.bind(this);
  }

  renderObjectSummary() {
    const { project } = this;
    const urlParams = {
      testReports: t('test_reports'),
      projectName: project.name,
      testReportTitle: 'Test Case Priorities',
    };
    return (
      <ObjectSummary params={urlParams} />
    );
  }

  renderHeader() {
    return (
      <>
        {this.renderObjectSummary()}
      </>
    );
  }

  renderBody() {
    return (
      <TestCasePriorityDataTable autoUpdate />
    );
  }

  render() {
    return (
      <DefaultLayout
        renderHeader={this.renderHeader}
        renderBody={this.renderBody}
      />
    );
  }
}

export default TestCasePriorities;
