import React from 'react';
import { t } from '../../i18n/t';
import PageComponent from '../../components/PageComponent';
import MContext from '../../models/MContext';
import KEyesExecutionTable from '../../components/table/KEyesExecutionTable';
import { IconAIPowered } from '../../images/CustomNewIcon';
import ObjectSummary from '../../components/summary/ObjectSummary';
import Services from '../../utils/Services';

class VisualTestRuns extends PageComponent {

  constructor(props) {
    super(props);
    this.teamId = MContext.teamId;
    this.projectId = MContext.projectId;
    this.project = MContext.project;
    this.isTeamDemo = MContext.isTeamDemo;
    this.state = {
      visualTestingPlan: null,
    };
    this.meta.id = 'page-visual-testing';
    this.meta.title = t(
      'Project {{name}} - {{page}}',
      { name: this.project.name, page: t('testops-keyes') },
    );
    this.renderBody = this.renderBody.bind(this);
    this.renderHeader = this.renderHeader.bind(this);
  }

  renderHeader() {
    const { project } = this;
    const urlParams = {
      visualTestRuns:
        (
          <div className="visual-testing-header d-flex align-items-center">
            <span>{t('keyes-executions')}</span>
            <IconAIPowered className="ml-2" />
          </div>
        ),
      projectName: project.name,
      title: t('keyes-executions'),
    };
    return (
      <ObjectSummary params={urlParams} />
    );
  }

  renderBody() {
    const { visualTestingPlan } = this.state;
    return (
      <KEyesExecutionTable visualTestingPlan={visualTestingPlan} {...this.props} />
    );
  }

  componentDidMount() {
    if (!this.isTeamDemo) {
      const organization = MContext.targetOrganization;
      Services.getVisualTestingQuota(organization.id).then((visualTestingPlan) => {
        this.setState({ visualTestingPlan });
      });
    }
  }

  render() {
    return this.renderBody();
  }

}

export default VisualTestRuns;
