import { Typography } from '@mui/material';
import React from 'react';
import { buildSearchCondition } from '../../search/SearchUtils';
import SearchableFilter from './SearchableFilter';
import { SearchEntity } from '../../../utils/Constants';
import { t } from '../../../i18n/t';

const BuildFilter = (props) => {

  const buildSearchConditions = (searchInput) => ([
    buildSearchCondition('name', 'contains', searchInput || '')
  ]);

  const renderOption = (option) => (
    <Typography noWrap>{option.name}</Typography>
  );
  return (
    <SearchableFilter
      entityType={SearchEntity.Build}
      buildSearchConditions={buildSearchConditions}
      label={t('build')}
      searchKey="name"
      placeHolder={t('build')}
      renderOption={renderOption}
      {...props}
    />
  );
}

export default BuildFilter;
