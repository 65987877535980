import React from 'react';
import MTableColumnDataMapping from '../../components/table/models/MTableColumnDataMapping';
import Routes from '../../utils/Routes';
import { t } from '../../i18n/t';
import DecoratorConstants from '../../utils/DecoratorConstants';
import MContext from '../../models/MContext';
import DataTable from './DataTable';
import { buildDefaultFilter, buildFilter } from '../search-query/FilterQueryHelper';
import { buildSortQuery } from '../search-query/SortQueryHelper';
import StatusFilter, { StatusType } from '../search-query/filter/StatusFilter';

class TestProjectDataTable extends React.Component {

  constructor(props) {
    super(props);
    this.projectId = MContext.projectId;
    this.teamId = MContext.teamId;
    this.testProjectList = null;
  }

  render() {
    const columnMapping = [
      new MTableColumnDataMapping(
        t('name'),
        'name',
        (name, row) => {
          const constructedLink = new Routes({
            projectId: row.projectId,
            testProjectId: row.id,
          });
          return DecoratorConstants.idDecorator(name, row, constructedLink.test_project_link);
        },
        undefined,
        'test-project-name-column',
      ),
      new MTableColumnDataMapping(
        t('description'),
        'description',
      ),
      new MTableColumnDataMapping(
        t('type'),
        'type',
        (name, row) => DecoratorConstants.codeRepoType(name, row)
      ),
    ];
    const defaultFilter = buildDefaultFilter('name', '~', 'Name');
    const filterQuery = [
      buildFilter(StatusFilter, { id: 'type', type: StatusType.TEST_PROJECT, label: 'Type' }),
    ];
    const sortQuery = [
      ...buildSortQuery('name', t('name')),
    ];
    const newProps = {
      ...this.props,
      filterQuery,
      sortQuery,
      defaultFilter,
    };

    return (
      <DataTable
        entityType="TestProject"
        defaultSearchConditions={[]}
        columnMapping={columnMapping}
        ref={(ref) => {
          this.testProjectList = ref;
        }}
        {...newProps}
        useSearchQuery
        {...this.props}
      />
    );
  }
}

export default TestProjectDataTable;
