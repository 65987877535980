import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TreeSelect } from 'antd';
import { isNumber } from 'lodash';
import { Button, ButtonToolbar } from 'reactstrap';
import ConvertDataHelper from '../../utils/ConvertDataHelper';
import TreeViewHelper from '../../utils/treeview/TreeViewHelper';
import { t } from '../../i18n/t';
import Helper from '../../utils/Helper';
import MFlags from '../../models/MFlags';

function TreeSelectView(props) {
  const { data, showSearch, placeholder, dropDownMaxHeight, selectedNodeCondition, treeCheckable } = props;

  const [treeData, setTreeData] = useState(data);
  const [expandedKeys, setExpandedKeys] = useState([...props.expandedKeys]);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [open, setOpen] = useState(false);

  const resetSelectedKeys = () => {
    setSelectedKeys([]);
  };

  const handleClear = () => {
    resetSelectedKeys();
    if (props.handleClear) {
      props.handleClear();
    }
  };

  const handleAddButton = () => {
    if (props.handleAdd) {
      props.handleAdd(selectedKeys, treeData);
      resetSelectedKeys();
      setOpen(false);
      if (treeData.length > 0) {
        const firstKey = treeData[0].key;
        setExpandedKeys([firstKey]);
      }
    }
  };

  const numberOfSelectedItem = () => {
    if (selectedNodeCondition) {
      const selectedItemKeys = selectedKeys.filter((key) => selectedNodeCondition(key));
      return selectedItemKeys.length;
    }

    return selectedKeys.length;
  };

  const setSelectedKeysAndRemoveDuplicate = (selectedKeys) => {
    const uniqueKeys = Helper.removeDuplicateElementInArray(selectedKeys);
    setSelectedKeys(uniqueKeys);
  };

  const renderFooter = () => (
    <div className="select-test-case-footer">
      <ButtonToolbar>
        <Button
          color="primary"
          onClick={() => handleAddButton()}
          disabled={numberOfSelectedItem() === 0}
        >
          {t('add')} ({numberOfSelectedItem()})
        </Button>
        <Button
          color="secondary"
          onClick={() => handleClear()}
        >
          {t('clear')}
        </Button>
      </ButtonToolbar>
    </div>
  );

  const dropdownRender = (originNode) => (
    <>
      {originNode}
      {(treeData.length > 0 && renderFooter && treeCheckable) && renderFooter()}
    </>
  );

  const setExpandedKeysAndRemoveDuplicate = (expandedKeys) => {
    const uniqueKeys = Helper.removeDuplicateElementInArray(expandedKeys);
    setExpandedKeys(uniqueKeys);
  };

  const onSelect = (value, node) => {
    setExpandedKeysAndRemoveDuplicate([...expandedKeys, node.key]);
    if (!isNumber(node.key)) {
      return;
    }

    props.onSelect(node.key).then((content) => {
      if (content.length === 0) {
        return;
      }

      const children = ConvertDataHelper.parseCloudStudioExtraTreeData(content, node.key);
      const keys = TreeViewHelper.getAllNodeKeys({ key: node.key, children });
      setExpandedKeysAndRemoveDuplicate([...expandedKeys, ...keys]);
      if (treeCheckable) {
        setSelectedKeysAndRemoveDuplicate([...selectedKeys, ...keys]);
      }

      setTreeData((origin) =>
        TreeViewHelper.updateTreeData(origin, node.key, children));
    });
  };

  const loadData = ({ key, children }) => new Promise((resolve) => {
    /**
     * if key is root (has prefix root_*) or nodes have children not null
     * => Dont need to fetch data
     */
    let hasChildren = false;
    if (children) {
      const childrenFolder = selectedNodeCondition ? children.filter((node) => !selectedNodeCondition(node.key)) : children;
      hasChildren = childrenFolder.length !== 0;
    }
    if (!isNumber(key) || hasChildren) {
      resolve();
      return;
    }
    if (props.loadData) {
      props.loadData(key).then((content) => {
        if (content.length === 0) {
          resolve();
          return;
        }
        children = ConvertDataHelper.parseCloudStudioExtraTreeData(content, key);
        setTreeData((origin) =>
          TreeViewHelper.updateTreeData(origin, key, children));
        resolve();
      });
    }
  });

  const isFolderKey = (key) => isNumber(key);

  const onChange = (selectedKeys) => {
    if (MFlags.addTestCaseToTestSuiteEnabled && !treeCheckable) {
      if (isFolderKey(selectedKeys)) {
        return;
      }
      if (props.handleAdd) {
        props.handleAdd(selectedKeys, treeData);
      }
    }
    setSelectedKeys(selectedKeys);
  };

  const onExpand = (expandedKeys) => {
    setExpandedKeys(expandedKeys);
  };

  const onDropdownVisibleChange = (open) => {
    setOpen(open);
  };

  return (
    <TreeSelect
      data-testid="tree-select"
      className="w-100"
      dropdownRender={dropdownRender}
      treeData={treeData}
      value={selectedKeys}
      treeCheckable={treeCheckable}
      placeholder={placeholder}
      loadData={loadData}
      tagRender={() => null}
      onSelect={onSelect}
      onChange={onChange}
      treeExpandedKeys={expandedKeys}
      onTreeExpand={onExpand}
      showSearch={showSearch}
      open={open}
      onDropdownVisibleChange={onDropdownVisibleChange}
      dropdownStyle={{
        overflow: 'auto',
        zIndex: '100000',
      }}
      listHeight={dropDownMaxHeight}
    />
  );
}

TreeSelectView.propTypes = {
  /**
   data is array of folder
   Ex:
   [
   {
        title: 'Folder1',
        key: 'Folder 1',
        children: [
          {
            title: 'Folder 1.1',
            key: 'Folder 1.1'
          }
        ],
      },
   {},
   ...
   ]
   * title: A text to show in UI
   * key **important**: used to distinguish node in tree, if duplicate => break UI
   */
  data: PropTypes.array.isRequired,
  showSearch: PropTypes.bool,
  loadData: PropTypes.func,
  placeholder: PropTypes.string,
  expandedKeys: PropTypes.array,
  dropDownMaxHeight: PropTypes.number,
  treeCheckable: PropTypes.bool,
  /**
   * Function to define condition filter to count selected node
   * Default is count all node
   * Example: TreeSelectView for select Test Case
   * define condition that just filter key startWith 'test-case'
   */
  selectedNodeCondition: PropTypes.func,
};

TreeSelectView.defaultProps = {
  showSearch: false,
  placeholder: 'Please select',
  expandedKeys: [],
  dropDownMaxHeight: 200,
  treeCheckable: true,
};
export default TreeSelectView;
