import React from 'react';
import { Table } from 'reactstrap';
import { ListItemText } from '@mui/material';
import { ObjectType, SearchEntity } from '../../../utils/Constants';
import DecoratorConstants from '../../../utils/DecoratorConstants';
import DataLoader from '../../../components/table/DataLoader';
import Time from '../../../utils/Moment';
import { t } from '../../../i18n/t';
import MTableColumnDataMapping from '../../../components/table/models/MTableColumnDataMapping';
import { next } from '../../../utils/Count';
import Routes from '../../../utils/Routes';

class TraceabilityReport extends React.Component {

  constructor(props) {
    super(props);
    this.dataRef = React.createRef();

    this.columnMapping = this.createHeader();

    this.renderData = this.renderData.bind(this);
  }

  createHeader() {
    return [
      new MTableColumnDataMapping(
        t('requirements'),
        '',
        (row) => this.renderExternalIssue(row),
      ),
      new MTableColumnDataMapping(
        t('test_cases'),
        '',
        (row) => this.renderTestCase(row),
      ),
      new MTableColumnDataMapping(
        t('test_results'),
        '',
        (row) => this.renderTestRun(row),
        undefined,
        'nowrap-column'
      ),
      new MTableColumnDataMapping(
        t('defects'),
        '',
        (row) => DecoratorConstants.externalIssueDecorator(row),
      )
    ];
  }

  renderExternalIssue(row) {
    if (row.featureName) {
      return DecoratorConstants.featureNameDecorator('featureName', row);
    }
    return DecoratorConstants.externalIssueDecorator(row);
  }

  renderTestCase(row) {
    const status = row.lastExecutionTestCase?.status;
    const link = row.webUrl;
    const fullPath = `${row.path}/${row.name}`;

    return (DecoratorConstants.externalIssueLinkedTestCaseDecorator(row, status, link, fullPath));
  }

  renderTestRun(row) {
    const status = row.status;
    const startTime = row.startTime;
    const duration = row.duration;

    const constructedLink = new Routes({
      executionId: row.execution.order,
      executionTestResultId: row.urlId,
    });
    const link = DecoratorConstants.idDecorator('id', row, constructedLink.execution_test_result_detail_link);

    return (
      <ListItemText
        primary={
          <div className="d-flex">
            {status && (
              <span className="mr-2 align-items-center">
                {DecoratorConstants.status(status)}
              </span>
            )}
            {link}
          </div>
        }
        secondary={
          <div>
            {Time.format(startTime)} {`(${Time.duration(duration)})`}
          </div>
        }
      />
    );
  }

  renderLoadingMessage(headers) {
    const loadingMessage = t('table#loading-message');
    return (
      <tr className="table-loading-message text-center">
        <td colSpan={headers.length}>{loadingMessage}</td>
      </tr>
    );
  }

  renderEmptyMessage(headers) {
    return (
      <tr className="table-empty-message text-center">
        <td colSpan={headers.length}>{t('table#empty-message')}</td>
      </tr>
    );
  }

  countRowSpanTestCase(testCase) {
    const executionTestResults = testCase.executionTestResults;

    if (executionTestResults.length === 0) {
      return 1;
    }
    let total = 0;
    executionTestResults.forEach((executionTestResult) => {
      const externalIssues = executionTestResult.externalIssues;
      total += externalIssues.length;
    });
    return total;
  }

  countRowSpanRequirement(requirement) {
    const testCases = requirement.testCases;

    let total = 0;
    testCases.forEach((testCase) => {
      total += this.countRowSpanTestCase(testCase);
    });
    return total;
  }

  renderRows(headers, rows) {
    return (
      rows.length > 0
        ? rows
        : this.renderEmptyMessage(headers)
    );
  }

  renderData(data) {
    const convertData = [];
    data.forEach((requirement) => {
      const testCases = requirement.testCases;
      testCases.forEach((testCase, testCaseIndex) => {
        const executionTestResults = testCase.executionTestResults;
        if (executionTestResults.length === 0) {
          if (testCaseIndex === 0) {
            convertData.push([
              {
                data: requirement,
                rowspan: this.countRowSpanRequirement(requirement),
                locate: 0
              },
              {
                data: testCase,
                locate: 1
              },
              null,
              null
            ]);
          } else {
            convertData.push([{ data: testCase, locate: 1 }, null, null]);
          }
        } else {
          executionTestResults.forEach((executionTestResult, executionTestResultIndex) => {
            const externalIssues = executionTestResult.externalIssues;

            externalIssues.forEach((externalIssue, externalIssueIndex) => {
              if (externalIssueIndex > 0) {
                convertData.push([{ data: externalIssue, locate: 3 }]);
              } else {
                if (executionTestResultIndex === 0) {
                  if (testCaseIndex === 0) {
                    convertData.push([
                      {
                        data: requirement,
                        rowspan: this.countRowSpanRequirement(requirement),
                        locate: 0,
                      },
                      {
                        data: testCase,
                        rowspan: this.countRowSpanTestCase(testCase),
                        locate: 1,
                      },
                      {
                        data: executionTestResult,
                        rowspan: externalIssues.length,
                        locate: 2,
                      },
                      {
                        data: externalIssue,
                        locate: 3,
                      }
                    ]);
                  } else {
                    convertData.push([
                      {
                        data: testCase,
                        rowspan: this.countRowSpanTestCase(testCase),
                        locate: 1,
                      },
                      {
                        data: executionTestResult,
                        rowspan: externalIssues.length,
                        locate: 2,
                      },
                      {
                        data: externalIssue,
                        locate: 3,
                      }
                    ]);
                  }
                } else {
                  convertData.push([
                    {
                      data: executionTestResult,
                      rowspan: externalIssues.length,
                      locate: 2,
                    },
                    {
                      data: externalIssue,
                      locate: 3,
                    }
                  ]);
                }
              }
            });
          });
        }
      });
    });

    const rows = convertData.slice().map((arrays) => {
      const row = arrays.map((object) => {
        if (object === null) {
          return (<td />);
        }

        return (
          <td
            rowSpan={object?.rowspan || 1}
            className={this.columnMapping[object.locate].className}
            key={next()}
          >
            {this.columnMapping[object.locate].decorate(object.data)}
          </td>
        );
      });
      return (<tr key={next()}>{row}</tr>);
    });
    const headers = this.columnMapping.map((header) =>
      <th key={next()} className={header.className}>{header.headerCaption}</th>);

    return (
      <Table responsive className="traceability">
        <thead>
          <tr>
            {headers}
          </tr>
        </thead>
        <tbody>
          {this.dataRef.current && this.dataRef.current.isLoading && rows.length <= 0
            ? this.renderLoadingMessage(headers)
            : this.renderRows(headers, rows)}
        </tbody>
      </Table>
    );
  }

  render() {
    return (
      <DataLoader
        ref={this.dataRef}
        render={this.renderData}
        entityType={SearchEntity.ExternalTraceability}
        objectType={ObjectType.TRACEABILITY}
      />
    );
  }
}

export default TraceabilityReport;
