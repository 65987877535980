import React from 'react';
import { t } from '../../i18n/t';
import { IconDuration, IconStartTime } from '../../images/CustomIcon';
import DecoratorConstants from '../../utils/DecoratorConstants';
import Routes from '../../utils/Routes';
import DataTable from './DataTable';
import MTableColumnDataMapping from './models/MTableColumnDataMapping';
import Link from '../Link';

const AbnormalRequestDataTable = (props) => {
  const columnMapping = [
    new MTableColumnDataMapping(
      t('name'),
      'object.name',
      (name, row) => {
        const constructedLink = new Routes({
          executionRequestId: row.id,
          executionTestResultId: row.executionTestResult.id,
          executionId: row.executionTestResult.execution.id,
        });
        return <Link href={constructedLink.execution_request_link}>{DecoratorConstants.testObjectNameDecorator(name, row)}</Link>;
      },
    ),
    new MTableColumnDataMapping(
      t('time'),
      'time',
      (name, row) => (
        <>
          <span className="d-block">
            <IconDuration title={t('duration')} className="mr-1" />
            {DecoratorConstants.apiDurationDecorator('time', row)}
          </span>
          <span className="d-block">
            <IconStartTime title={t('startTime')} className="mr-1" />
            {DecoratorConstants.timeDecorator('startedAt', row)}
          </span>
        </>
      ),
      undefined,
      'nowrap-column',
    ),
    new MTableColumnDataMapping(
      'Average Duration',
      'object.averageDuration',
      DecoratorConstants.apiDurationDecorator,
    ),
  ];

  const newProps = {
    columnMapping,
    ...props,
  };
  return (
    <DataTable
      entityType="ExecutionRequest"
      title={t('test_objects#abnormal')}
      subtitle={t('test_objects#abnormal-title')}
      {...newProps}
    />
  );
};

export default AbnormalRequestDataTable;
