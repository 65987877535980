import React, { ReactNode } from 'react';
import { IconInfoCircleWhite } from '../../../images/CustomIcon';
import TooltipComponent from '../../../components/TooltipComponent';
import DecoratorConstants from '../../../utils/DecoratorConstants';


interface MetricComponentProps {
  icon: ReactNode,
  title: string,
  tooltip?: string,
  label: ReactNode,
  subLabel?: number,
  type?: string,
}

function MetricComponent(props: MetricComponentProps) {
  return (
    <div className="metric-component">
      {props.icon}
      <div className="d-flex align-items-center mt-2">
        <div className="metric-title mr-1">
          {props.title}
        </div>
        {
          props.tooltip &&
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          <TooltipComponent text={props.tooltip} placement="bottom-end" arrow>
            <IconInfoCircleWhite />
          </TooltipComponent>
        }
      </div>
      <div className="d-flex">
        {props.label}
        {
          props.subLabel ?
            <div className="metric-sub-label">
              <span className={`label-${props.type}`}>
                {`(${props.subLabel > 0 ? '+' : ''}${DecoratorConstants.formatPercentage(props.subLabel)})`}
              </span>
            </div>
            : null
        }
      </div>
    </div>
  );
}

export default MetricComponent;
