

import _ from 'lodash';

export default class MChartDataMapping {
  constructor(label, fieldName, rgbColor, decorate = (fieldName, item) => _.get(item, fieldName)) {
    this.label = label;
    this.fieldName = fieldName;
    this.rgbColor = rgbColor;
    this.decorate = decorate;
  }
}
