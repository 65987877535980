import { get } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Divider, CircularProgress } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { t } from '../../i18n/t';
import TabPanel from '../../components/tab/TabPanel';
import DecoratorConstants, { defectNumberDecorator,
  filterDistinctRequirementsOfDefect,
  filterDistinctTestCasesOfDefect,
  filterDistinctTestResultsOfDefect } from '../../utils/DecoratorConstants';
import CollapseCard from '../card/CollapseCard';
import Routes from '../../utils/Routes';
import { IconDuration, IconStartTime } from '../../images/CustomIcon';
import MFlags from '../../models/MFlags';
import { MAX_PAGE_SIZE, SearchEntity } from '../../utils/Constants';
import MContext from '../../models/MContext';
import { buildSearchCondition } from '../search/SearchUtils';
import ExternalLinkButton from '../../components/ExternalLinkButton';
import TableWithDashedBorder from '../../components/TableWithDashedBorder';
import AutomationRuleDetail from '../../components/external_issue/AutomationRuleDetail';
import Services from '../../utils/Services';
import DocumentLink from '../../utils/DocumentLink';


function DefectDrawerContent({ chosenDefect, tabIndex }) {

  const [automationRules, setAutomationRules] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getAutomationRules = () => {
    const params = {
      pagination: {
        page: 0,
        size: MAX_PAGE_SIZE,
        sorts: ['id, desc'],
      },
      conditions: [
        buildSearchCondition('Project.id', '=', MContext.projectId),
        buildSearchCondition('ExternalIssue.id', '=', chosenDefect.id),
      ],
      type: SearchEntity.ExternalIssueAutomationRule,
    };
    setIsLoading(true);
    Services.searchRecursively(0, params, [])
      .then((content) => {
        setAutomationRules(content);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (tabIndex === 1) {
      getAutomationRules();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabIndex, chosenDefect?.id]);

  const renderHeader = (title, numberComponent) => (
    <div className="d-flex">
      <span className="mr-2 font-weight-bold">{title}</span>
      {numberComponent}
    </div>);

  const renderItem = (icon, title, description) => (
    <div className="mb-3 defect-test-case">
      <div className="d-flex mb-1 align-items-center">
        <span className="defect-test-case-icon"> {icon} </span>
        {title}
      </div>
      <div className="defect-test-case-description">
        {description}
      </div>
    </div>);

  const renderTestCaseSection = () => {
    const distinctTestCases = filterDistinctTestCasesOfDefect(chosenDefect);
    const testCaseHeader =
      renderHeader(t('test_cases'), defectNumberDecorator(distinctTestCases.length));
    const testCaseContent = distinctTestCases.map((testCase) => {
      const icon = DecoratorConstants.statusDecorator('lastExecutionTestCase.status', testCase);
      const title = DecoratorConstants.testCaseNameDecorator('name', testCase, undefined, true);
      const description = DecoratorConstants.addSourceOfTestCaseAndTestSuite(testCase.path, testCase.testProject);
      return renderItem(icon, title, description);
    });
    return <CollapseCard
      header={testCaseHeader}
      content={testCaseContent}
    />;
  };

  const renderTestResultSection = () => {
    const distinctTestResults = filterDistinctTestResultsOfDefect('testResults', chosenDefect);
    const testResultHeader =
      renderHeader(t('test_results'), defectNumberDecorator(distinctTestResults.length));
    const testResultContent = distinctTestResults.map((testResult) => {
      const icon = DecoratorConstants.executionTestResultStatusDecorator('status', testResult);

      const constructedLink = new Routes({
        executionId: get(testResult, 'execution.order'),
        executionTestResultId: testResult.urlId,
      });
      const title = DecoratorConstants.idDecorator('testCase.name', testResult, constructedLink.execution_test_result_detail_link);

      const description =
        <span className="d-flex align-items-center defect-test-result-description">
          <span className="d-flex align-items-center">
            <IconStartTime title={t('startTime')} className="mr-1" />
            {DecoratorConstants.timeDecorator('startTime', testResult)}
          </span>
          <Divider orientation="vertical" />
          <span className="d-flex align-items-center">
            <IconDuration title={t('duration')} className="mr-1" />
            {DecoratorConstants.durationDecorator('duration', testResult)}
          </span>
        </span>;

      return renderItem(icon, title, description);
    });
    return <CollapseCard
      header={testResultHeader}
      content={testResultContent}
    />;
  };

  const renderRequirementSection = () => {
    const distinctRequirements = filterDistinctRequirementsOfDefect(chosenDefect);
    const requirementHeader =
    renderHeader(t('table-header#requirements'), defectNumberDecorator(distinctRequirements.length));
    const requirementContent = distinctRequirements.map((requirement) => {
      const iconUrl = get(requirement, 'issueTypeIcon');
      const iconAlt = get(requirement, 'issueTypeName');
      const icon = <img src={iconUrl} alt={iconAlt} className="img-fluid icon-issue-type mr-2" />;

      const issueId = get(requirement, 'issueId');
      const issueUrl = get(requirement, 'url');
      const title = <a target="_blank" rel="noopener noreferrer" href={issueUrl}>{issueId}</a>;

      const description = DecoratorConstants.externalNameDecorator('summary', requirement);

      return renderItem(icon, title, description);
    });
    return <CollapseCard
      header={requirementHeader}
      content={requirementContent}
    />;
  };

  const renderAutomationRule = (element) => (
    <div className="mb-3 mt-2">
      <AutomationRuleDetail
        automationRule={element}
        getAutomationRules={getAutomationRules}
      />
    </div>
  );

  const renderAutomationRules = () => {
    if (isLoading) {
      return (
        <div className="loading-type-field text-center mt-4">
          <CircularProgress size="2rem" thickness={5} />
        </div>
      );
    } else if (automationRules.length === 0) {
      return (
        <div className="text-center mt-4">
          <div className="custom-fields__empty-message">{t('empty-automation-rules')} </div>
          <ExternalLinkButton externalLink={DocumentLink.DEFECT_REPORT_AUTOMATIONS}>
            {t('learn_more')} <OpenInNewIcon />
          </ExternalLinkButton>
        </div>
      );
    } else {
      return (
        <TableWithDashedBorder
          chosenKey="name"
          renderData={renderAutomationRule}
          data={automationRules}
        />
      );
    }
  };

  if (MFlags.automationRuleEnabled) {
    return (
      <div key={tabIndex}>
        <TabPanel value={tabIndex} index={0}>
          {renderTestCaseSection()}
          {renderTestResultSection()}
          {renderRequirementSection()}
        </TabPanel>
        <TabPanel value={tabIndex} index={1}>
          {renderAutomationRules()}
        </TabPanel>
      </div>
    );
  }
  return (
    <>
      {renderTestCaseSection()}
      {renderTestResultSection()}
      {renderRequirementSection()}
    </>
  );
}

DefectDrawerContent.propTypes = {
};

DefectDrawerContent.defaultProps = {
};

export default DefectDrawerContent;
