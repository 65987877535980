import React from 'react';
import Paper from '@mui/material/Paper';
import Icon from '@katalon-studio/katalon-ui/Icon';
import Grid from '@mui/material/Grid';
import { Button } from 'reactstrap';
import MAuth from '../../models/MAuth';
import MContext from '../../models/MContext';
import { IconOrganizationEmptyState } from '../../images/CustomIcon';
import Stepper from '../../components/wizard/Stepper';
import DocumentLink from '../../utils/DocumentLink';
import Apis from '../../utils/Apis';
import { t } from '../../i18n/t';

export default function OrganizationHomeEmptyState() {
  const organizationId = MContext.organizationId;
  const isOrganizationManager = MAuth.isOrganizationManager(organizationId);

  const renderContentForManager = () => {
    const steps = [
      {
        label: <div className="step-label">{t('organization-empty-state#admin#step-one#title')}</div>,
        content: <div className="step-content">{t('organization-empty-state#admin#step-one#content')}</div>,
      },
      {
        label: <div className="step-label">{t('organization-empty-state#admin#step-two#title')}</div>,
        content: <div className="step-content">{t('organization-empty-state#admin#step-two#content')}</div>,
      }
    ];
    return (
      <div className="mt-2">
        <Stepper
          expanded
          steps={steps}
        />
        <div className="mt-3 d-flex align-items-center">
          <Button
            target="_blank"
            rel="noreferrer"
            className="mr-4"
            color="primary"
            href={Apis.katOneAdminCreateProject(organizationId)}
          >
            {t('create_new_project')}
            <Icon
              type="fa-regular"
              name="fa-solid fa-arrow-up-right-from-square"
              textSize="14px"
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                paddingLeft: '5px',
              }}
            />
          </Button>
          <a
            target="_blank"
            rel="noreferrer"
            href={DocumentLink.CREATE_PROJECT_IN_ADMIN}
          >
            {t('learn_more')}
          </a>
        </div>
      </div>
    );
  };

  const renderContentForMember = () => (
    <div className="mt-2">
      {t('organization-empty-state#member#content')}
    </div>
  );

  return (
    <Paper className="organization-home-empty-state" elevation={0}>
      <Grid container spacing={4}>
        <Grid item xs={4} className="icon">
          <IconOrganizationEmptyState />
        </Grid>
        <Grid item xs={8} md={5.8} className="content">
          <div className="mt-3">
            <div className="title">{t('organization-empty-state#title')}</div>
            {isOrganizationManager ? renderContentForManager() : renderContentForMember()}
          </div>
        </Grid>
      </Grid>
    </Paper>
  );
}
