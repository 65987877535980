import React from 'react';
import PageComponent from '../components/PageComponent';
import ObjectSummary from '../components/summary/ObjectSummary';
import MContext from '../models/MContext';
import ExecutionDataTable from '../components/table/ExecutionDataTable';
import { buildSearchCondition } from '../components/search/SearchUtils';
import { t } from '../i18n/t';
import DefaultLayout from '../components/DefaultLayout';
import {buildFilter} from '../components/search-query/FilterQueryHelper';
import StatusFilter, {StatusType} from '../components/search-query/filter/StatusFilter';
import TimeFilter from '../components/search-query/filter/TimeFilter';
import InputFilter from '../components/search-query/filter/InputFilter';
import UserFilter from '../components/search-query/filter/UserFilter';

export default class Dashboard extends PageComponent {
  constructor(props) {
    super(props);
    this.meta.id = 'page-team-dashboard';
    this.meta.title = 'Dashboard';
    this.teamId = MContext.teamId;
    this.state = {
      team: null,
    };
    this.renderHeader = this.renderHeader.bind(this);
    this.renderBody = this.renderBody.bind(this);
  }

  getTeam() {
    this.setState({
      team: MContext.team,
    });
  }

  componentDidMount() {
    this.getTeam();
  }

  renderObjectSummary() {
    const { team } = this.state;
    const urlParams = {
      dashboard: 'Dashboard',
      teamId: this.teamId,
      teamName: team.name,
    };
    return (
      <ObjectSummary params={urlParams} />
    );
  }

  renderHeader() {
    return (
      <>
        {this.renderObjectSummary()}
      </>
    );
  }

  renderBody() {
    const filterQuery = [
      buildFilter(StatusFilter, { id: 'status', type: StatusType.EXECUTION }),
      buildFilter(TimeFilter, { id: 'startTime', label: 'Started' }),
      buildFilter(InputFilter, { id: 'ExecutionTestResult.profile', label: 'Profile', operator: '~' }),
      buildFilter(UserFilter, { id: 'User.id' }),
    ];

    return (
      <ExecutionDataTable
        title={t('executions')}
        defaultSearchConditions={[
          buildSearchCondition('Team.id', '=', this.teamId),
        ]}
        filterQuery={filterQuery}
        // autoUpdate
        useSearchQuery
        defaultSort={['startTime,desc']}
      />
    );
  }

  render() {
    const { team } = this.state;
    if (team) {
      return (
        <DefaultLayout
          renderHeader={this.renderHeader}
          renderBody={this.renderBody}
        />
      );
    }
    return null;
  }
}
