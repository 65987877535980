import PropTypes from 'prop-types';
import React from 'react';
import { Table } from 'reactstrap';
import { next } from '../../utils/Count';


class SimpleDataTable extends React.PureComponent {
  render() {
    const { data, columnMapping } = this.props;

    const headers = columnMapping.map((header) => <th key={next()}>{header.headerCaption}</th>);
    const rows = data.map((row) => {
      const cols = columnMapping.map((col) => (
        <td key={next()}>{col.decorate(col.fieldName, row)}</td>
      ));
      return <tr key={next()}>{cols}</tr>;
    });

    return (
      <Table responsive className={`${this.props.className || ''} table-hover`}>
        <thead>
          <tr>
            {headers}
          </tr>
        </thead>
        <tbody>
          {rows}
        </tbody>
      </Table>
    );
  }
}

SimpleDataTable.propTypes = {
  columnMapping: PropTypes.array,
  data: PropTypes.array,
};

SimpleDataTable.defaultProps = {
  columnMapping: [],
  data: [],
};

export default SimpleDataTable;
