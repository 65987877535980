import { Bar, CartesianGrid, ComposedChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import React from 'react';
import { orderBy } from 'lodash';
import colors from '../../../scss/colors.scss';
import CustomChartTooltip from './CustomChartTooltip';
import { kFormatter, timeFormatter } from '../../pages/test_reports/utils';

const TestCasesCreatedChart = ({ data }) => {

  const renderTooltip = (active, payload) => {
    payload = orderBy(payload, ['name'], 'asc');
    return (
      <CustomChartTooltip active={active} payload={payload} />
    );
  };

  return (
    <ResponsiveContainer minWidth={200} maxHeight={200} aspect={3} position="center">
      <ComposedChart
        margin={{
          top: 20,
          right: 0,
          bottom: 0,
          left: -20,
        }}
        data={data}
      >
        <XAxis axisLine={false} tickLine={false} tickFormatter={timeFormatter} dataKey="time" />
        <YAxis axisLine={false} tickLine={false} yAxisId="total-test-cases" tickFormatter={kFormatter} />
        <Tooltip content={({ active, payload }) => renderTooltip(active, payload)} />
        <CartesianGrid vertical={false} stroke={colors.cardBorder} strokeWidth={0.5} />
        <Bar yAxisId="total-test-cases" barSize={30} stackId="status" dataKey="totalTestCases" fill={colors.paletteDarkBlue} isAnimationActive={false} />
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export default TestCasesCreatedChart;
