import React from 'react';
import { t } from '../../i18n/t';
import TabComponent from '../../components/TabComponent';
import { buildSearchCondition } from '../../components/search/SearchUtils';
import { ProjectStatus } from '../../utils/Constants';
import ProjectDataTable from './components/ProjectDataTable';
import { IconUnarchiveProject } from '../../images/CustomIcon';

class ArchivedProjectsTab extends TabComponent {
  constructor(props) {
    super(props);
    this.meta.id = 'page-archived-projects-tab';
    this.meta.title = t('archived-projects');
    this.organizationId = props.organizationId;
  }

  renderArchivedProjects() {
    const defaultSearchConditions = [
      buildSearchCondition('Organization.id', '=', this.organizationId),
      buildSearchCondition('status', '=', 0),
    ];

    return (
      <ProjectDataTable
        defaultSearchConditions={defaultSearchConditions}
        status={ProjectStatus.ACTIVE}
        icon={IconUnarchiveProject}
        label="Unarchive"
      />
    );
  }

  render() {
    return this.renderArchivedProjects();
  }
}

export default ArchivedProjectsTab;
