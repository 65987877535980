import React from 'react';
import PropTypes from 'prop-types';
import LogoImage from '../../../images/new-icons/upgrade-subscription.png';
import { t } from '../../i18n/t';
import { getSubscriptionName } from '../../pages/testops_subscription/SubscriptionConstant';
import DocumentLink from '../../utils/DocumentLink';
import { FeatureNames } from '../../utils/Constants';

export default function UpgradeRequest({ subscription, feature, isUsePlatformMessage, platformPlan }) {

  const planName = getSubscriptionName(subscription);

  const shouldDisplayTrialUpgradeMessage = feature === FeatureNames.VISUAL_TESTING;

  function renderTrialUpgradeMessage() {
    return (
      <p>
        <span>{t('upgrade_trial', { subscription: planName })}</span>
        <a
          href={DocumentLink.TESTOPS_TRIAL_PLANS}
          target="_blank"
          rel="noreferrer noopener"
        >
          <b>{t('testops-trial-plans')}</b>
        </a>
        .
      </p>);
  }

  function renderUpgradeMessage() {
    return (
      <p>
        <b>{t('upgrade_request_new', { subscription: planName })}</b>
        <a
          href={DocumentLink.KATALON_TESTOPS_PACKAGE_DETAILS}
          target="_blank"
          rel="noreferrer noopener"
        >
          <b>{t('katalon_testops_pricing')}</b>
        </a>
        .
      </p>);
  }

  function renderUpgradeMessageForPlatform() {
    // use platform plan check and show message for premium and ultimate.
    return (
      <div className="">
        <span>{t('upgrade_request_for_premium_plan')}</span>
        <a
          href={DocumentLink.KATALON_TESTOPS_PACKAGE_DETAILS}
          target="_blank"
          rel="noreferrer noopener"
        >
          <span>{t('katalon_pricing')}</span>
        </a>.
      </div>);
  }

  function renderMessage() {
    if (isUsePlatformMessage) {
      return renderUpgradeMessageForPlatform();
    } else {
      if (shouldDisplayTrialUpgradeMessage) {
        return renderTrialUpgradeMessage();
      }
      return renderUpgradeMessage();
    }
  }

  return (
    <div className="upgrade-request w-100 bg-white">
      <div className="text-center py-1" color="white">
        <img
          src={LogoImage}
          className="logo-upgrade-request"
          alt="Logo Upgrade Request"
        />
        {renderMessage()}
      </div>
    </div>
  );
}


UpgradeRequest.propTypes = {
  isUsePlatformMessage: PropTypes.bool,
  platformPlan: PropTypes.string
};

UpgradeRequest.defaultProps = {
  isUsePlatformMessage: false,
  platformPlan: null
};
