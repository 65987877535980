import DecoratorConstants from "./DecoratorConstants";


class Text {
  static title(id, name) {
    return `#${id} - ${name}`;
  }

  static platformName(platform) {
    if (!platform) {
      return '';
    }
    const osName = DecoratorConstants.displayNameByOS(platform.osName);
    const osVersion = DecoratorConstants.displayNameByOS(platform.osVersion);
    let platformName;
    if (osVersion.indexOf(osName) < 0) {
      platformName = `${osName} ${osVersion}`;
    } else {
      platformName = osVersion;
    }
    return platformName;
  }

  static browserName(platform) {
    if (!platform) {
      return '';
    }
    const browserName = platform.browserName || '';
    const browserVersion = platform.browserVersion || '';
    let platformName;
    if (browserVersion.indexOf(browserName) < 0) {
      platformName = `${browserName} ${browserVersion}`;
    } else {
      platformName = browserVersion;
    }
    return platformName;
  }

  static copyToClipboard(content) {
    const el = document.createElement('textarea');
    el.value = content;
    el.setAttribute('readonly', '');
    el.style = { display: 'none' };
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  }
}

export default Text;
