import React from 'react';
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import PropTypes from 'prop-types';
import Select from '../Select';
import Services from '../../utils/Services';
import MContext from '../../models/MContext';
import Routes from '../../utils/Routes';

class ChooseTeamDialog extends React.Component {

  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.organizationId = MContext.organizationId;
    this.toggle = this.toggle.bind(this);
    this.selectTeam = this.selectTeam.bind(this);
    this.handleOkButtonClicked = this.handleOkButtonClicked.bind(this);
    this.generateTeamOptions = this.generateTeamOptions.bind(this);
  }

  getInitialState() {
    return {
      modal: false,
      teamOptions: [],
      selectedTeam: null,
    };
  }

  componentDidMount() {
    this.getTeam()
      .then(this.generateTeamOptions);
  }

  getTeam() {
    const params = {
      pagination: {
        page: 0,
        size: 10000,
        sorts: ['name, asc'],
      },
      conditions: [
        {
          key: 'Organization.id',
          operator: '=',
          value: this.organizationId,
        },
      ],
      type: 'Team',
    };
    return Services.search(params)
      .then(({ content }) => content);
  }

  generateTeamOptions(teams) {
    const teamOptions = teams.map((team) => ({
      value: team,
      label: team.name,
    }));
    this.setState({ teamOptions });
  }

  selectTeam(teamOption) {
    this.setState({ selectedTeam: teamOption });
  }

  handleOkButtonClicked() {
    const {
      handleApproval,
    } = this.props;

    const {
      selectedTeam,
    } = this.state;

    if (handleApproval) {
      handleApproval(selectedTeam);
    }

    this.toggle();
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  render() {
    const {
      modal,
      selectedTeam,
      teamOptions,
    } = this.state;

    const {
      handleApproval,
      primaryButtonText,
      ...props
    } = this.props;

    const route = new Routes({ organizationId: this.organizationId });
    const organizationTeamLink = route.organization_teams_link;

    return (
      <>
        <Modal isOpen={modal} toggle={this.toggle} {...props}>
          <ModalHeader toggle={this.toggle}>Choose Team</ModalHeader>
          <ModalBody>
            <Select
              clearable={false}
              onChange={this.selectTeam}
              value={selectedTeam}
              options={teamOptions}
            />
          </ModalBody>
          <ModalFooter>
            <Col>
              <a href={organizationTeamLink}>Create a new team</a>
            </Col>
            <Button color="primary" onClick={this.handleOkButtonClicked}>{primaryButtonText}</Button>
            <Button color="secondary" onClick={this.toggle}>Cancel</Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

ChooseTeamDialog.propTypes = {
  handleApproval: PropTypes.func,
  primaryButtonText: PropTypes.string,
};

ChooseTeamDialog.defaultProps = {
  primaryButtonText: 'OK',
};

export default ChooseTeamDialog;
