import React from 'react';
import { Link as RRLink } from 'react-router-dom';

const Link = (props) => {
  const link = props.to || props.href;
  const isAbsolutePath = link && (link.startsWith('http://') || link.startsWith('https://'));
  if (isAbsolutePath) {
    return <a {...props}>{props.children}</a>;
  }

  return <RRLink {...props} to={link}>{props.children}</RRLink>;
};
export default Link;
