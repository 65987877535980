import jquery from 'jquery';
import MConfigs from '../models/MConfigs';

const isLoadingSpinnerShowing = () => {
  let displayStatus = jquery('#loadingSpinner').css('display');
  return !displayStatus || displayStatus !== 'none';
};

export const AppHelper = {
  showLoadingSpinner: () => {
    if (!isLoadingSpinnerShowing()) {
      jquery('#loadingSpinner').attr('style', 'display:flex');
    }
  },

  hideLoadingSpinner: () => {
    if (isLoadingSpinnerShowing()) {
      jquery('#loadingSpinner').hide();
    }
  },

  openCustomBlockedUI: () => {
    MConfigs.openCustomBlockedUI();
    AppHelper.showLoadingSpinner();
  },

  closeCustomBlockedUI: () => {
    MConfigs.closeCustomBlockedUI();
    AppHelper.hideLoadingSpinner();
  },
};

export default AppHelper;
