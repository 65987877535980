import PropTypes from 'prop-types';
import { split, isEmpty } from 'lodash';
import React, { Component } from 'react';
import {
  Button,
  Collapse,
  Form,
  FormGroup,
  FormText,
  Label,
  CustomInput,
  InputGroup,
  InputGroupText, InputGroupAddon
} from 'reactstrap';
import Input from '../../../components/Input';
import Select from '../../../components/Select';
import MContext from '../../../models/MContext';
import Apis from '../../../utils/Apis';
import http from '../../../utils/http';
import Notification from '../../../utils/Notification';
import { t } from '../../../i18n/t';
import { GitTestProjectVCSType, WAITING_TIME_FOR_TOAST, VcsTypes } from '../../../utils/Constants';
import DocumentLink from '../../../utils/DocumentLink';
import AlertScriptRepoWhitelistIPs from '../../../components/warning_packages/AlertScriptRepoWhitelistIPs';
import TooltipComponent from '../../../components/TooltipComponent';
import MFlags from '../../../models/MFlags';
import {IconBackSlash} from "../../../images/CustomIcon";

class TestProjectConfigForm extends Component {

  constructor(props) {
    super(props);

    this.projectId = MContext.projectId;
    this.testProjectId = MContext.testProjectId;
    this.state = this.getInitialState();
    this.isCreateTestProject = !this.testProjectId;

    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleSelectBranch = this.handleSelectBranch.bind(this);
    this.handleSelectVCSType = this.handleSelectVCSType.bind(this);
    this.handleConnect = this.handleConnect.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.renderForm = this.renderForm.bind(this);
    this.gitBranches = this.gitBranches.bind(this);
  }

  getInitialState() {
    return {
      gitInfo: {
        id: null,
        repository: '',
        username: '',
        password: '',
        branch: null,
        targetDirectory: '',
        projectId: this.projectId,
        name: '',
        description: '',
        testProjectId: this.testProjectId,
        vcsType: GitTestProjectVCSType.GITHUB,
        shouldMergeTestResultsForNewScriptRepo: false,
      },
      branches: [],
      isOpenCreateGitTestProject: false,
    };
  }

  componentDidMount() {
    this.setGitInfo();
  }

  setGitInfo() {
    const { gitRepository } = this.props;

    if (!isEmpty(gitRepository)) {
      this.setState((prevState) => {
        const { id, repository, username, branch, vcsType, name, description, targetDirectory } = gitRepository;
        return {
          gitInfo: {
            ...prevState.gitInfo,
            id,
            repository,
            username,
            branch,
            vcsType,
            name,
            description,
            targetDirectory,
          },
          isOpenCreateGitTestProject: false
        };
      }, this.gitBranches);
    }
  }

  gitBranches() {
    http.post(Apis.gitBranches(), this.state.gitInfo)
        .then((responseJson) => {
          const branches = responseJson.map((branch) => ({
            label: branch,
            value: branch,
          }));
          this.setState({
            branches,
            isOpenCreateGitTestProject: true,
          });
        });
  }

  getDefaultName() {
    this.setState((prevState) => {
      const { gitInfo } = prevState;
      const object = split(gitInfo.repository, '/');
      const defaultName = object[object.length - 1];
      return {
        gitInfo: {
          ...prevState.gitInfo,
          name: defaultName,
        },
      };
    });
  }

  handleOnChange(event) {
    const value = event.target.value;
    const name = event.target.name;
    this.setState((prevState) => ({
      gitInfo: {
        ...prevState.gitInfo,
        [name]: value,
      },
    }));
  }

  handleSelectBranch(value) {
    this.setState((prevState) => ({
      gitInfo: {
        ...prevState.gitInfo,
        branch: (value ? value.value : null),
      },
    }));
  }

  handleSelectVCSType(value) {
    this.setState((prevState) => ({
      gitInfo: {
        ...prevState.gitInfo,
        vcsType: (value ? value.value : null),
      },
    }));
  }

  handleSubmit(event) {
    event.preventDefault();
    const { gitInfo } = this.state;
    if (this.testProjectId) {
      http.post(Apis.gitUpdateTestProject(), gitInfo)
          .then((responseJson) => {
            this.showNotificationAndRedirect(responseJson);
          });
    } else {
      http.post(Apis.gitCreateTestProject(), gitInfo)
          .then((responseJson) => {
            this.showNotificationAndRedirect(responseJson);
          });
    }
  }

  showNotificationAndRedirect(responseJson) {
    const { handleRedirect } = this.props;
    Notification.pushSuccess(t('update_test_project_success'));
    setTimeout(() => {
      handleRedirect(responseJson.testProjectId);
    }, WAITING_TIME_FOR_TOAST);
  }

  handleConnect(event) {
    event.preventDefault();
    this.setState({ isOpenCreateGitTestProject: false }, () => {
      http.post(Apis.gitBranches(), this.state.gitInfo)
          .then((responseJson) => {
            const branches = responseJson.map((branch) => ({
              label: branch,
              value: branch,
            }));

            if (branches.length > 0) {
              this.handleSelectBranch(branches[0]);
            }
            this.getDefaultName();
            this.setState({
              branches,
              isOpenCreateGitTestProject: true,
            });
          });
    });
  }

  handleCheckboxChange(event) {
    const shouldMergeTestResultsForNewScriptRepo = event.target.checked;
    this.setState((prevState) => ({
      gitInfo: {
        ...prevState.gitInfo,
        shouldMergeTestResultsForNewScriptRepo,
      },
    }));
  }

  generateSubmitId() {
    const { isSetUpPage } = this.props;
    if (isSetUpPage) {
      return 'connect-git-script-repository';
    }
    if (this.isCreateTestProject) {
      return 'create-git-test-project';
    }
    return 'update-git-test-project';
  }

  getInputPlaceholder(inputName, vcsType) {
    return t(`git-script-repository#${vcsType}#${inputName}#placeholder`);
  }

  renderForm() {
    const { gitInfo, branches, isOpenCreateGitTestProject } = this.state;
    const {
      repository,
      username,
      password,
      branch,
      targetDirectory,
      name,
      description,
      vcsType,
      shouldMergeTestResultsForNewScriptRepo,
      accessKeyId,
      secretAccessKey
    } = gitInfo;
    const { isSetUpPage } = this.props;

    const properties = JSON.stringify({ sourceType: vcsType });

    const passwordLabel = vcsType === GitTestProjectVCSType.BITBUCKET ?
        t('test-project#git#app-password') :
        t('test-project#git#personal-access-token');

    return (
        <>
          <Form
              data-trackid={this.generateSubmitId()}
              data-properties={properties}
              onSubmit={this.handleSubmit}
              autoComplete="off"
          >
            <FormGroup>
              <Label for="vcs-type">{t('test-project#git#vcs-type')}</Label>
              <Select
                  id="vcs-type"
                  value={vcsType}
                  options={VcsTypes}
                  onChange={this.handleSelectVCSType}
                  required
              />
            </FormGroup>
            <FormGroup>
              <Label for="repository">{t('test-project#git#repourl')}</Label>
              <Input
                  id="repository"
                  name="repository"
                  type="text"
                  value={repository}
                  onChange={this.handleOnChange}
                  required
                  placeholder={this.getInputPlaceholder('repository', vcsType)}
              />
            </FormGroup>
            <FormGroup>
              <Label for="username">{t('username')}</Label>
              <Input
                  id="username"
                  name="username"
                  type="text"
                  value={username}
                  onChange={this.handleOnChange}
                  required
                  placeholder={this.getInputPlaceholder('username', vcsType)}
              />
            </FormGroup>
            <FormGroup>
              <Label for="password">{passwordLabel}</Label>
              <Input
                  id="password"
                  name="password"
                  type="password"
                  value={password}
                  autocomplete="new-password"
                  onChange={this.handleOnChange}
                  required={this.isCreateTestProject}
                  placeholder={this.getInputPlaceholder('password', vcsType)}
              />
              <FormText color="muted">
                {'We recommend using your '}
                <a
                    href={DocumentLink.SETUP_GIT_REPOSITORY_PERSONAL_ACCESS_TOKEN}
                    target="_blank"
                    rel="noreferrer noopener"
                >
                  {passwordLabel.toLowerCase()}
                </a>
              </FormText>
            </FormGroup>
            {vcsType === GitTestProjectVCSType.AWS &&
                <>
                  <FormGroup>
                    <Label for="acessKeyId">{t('test-project#git#accessKeyId')}</Label>
                    <Input
                        id="accessKeyId"
                        name="accessKeyId"
                        type="text"
                        value={accessKeyId}
                        onChange={this.handleOnChange}
                        required={this.isCreateTestProject}
                        placeholder={this.getInputPlaceholder('accessKeyId', vcsType)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="secretAccessKey">{t('test-project#git#secretAccessKey')}</Label>
                    <Input
                        id="secretAccessKey"
                        name="secretAccessKey"
                        type="password"
                        value={secretAccessKey}
                        onChange={this.handleOnChange}
                        required={this.isCreateTestProject}
                        placeholder={this.getInputPlaceholder('secretAccessKey', vcsType)}
                    />
                  </FormGroup>
                </>}
            {
                this.isCreateTestProject &&
                <FormGroup>
                  <CustomInput
                      id="allow-merge-test-results"
                      type="checkbox"
                      checked={shouldMergeTestResultsForNewScriptRepo}
                      name="allow-merge-test-results"
                      label={t('test-project#allow-merge')}
                      onChange={this.handleCheckboxChange}
                      className="normal-label"
                  />
                </FormGroup>
            }
            <AlertScriptRepoWhitelistIPs />
            <Button
                data-trackid={isSetUpPage ? 'connect-default-git-repository' : 'connect-git'}
                type="submit"
                onClick={this.handleConnect}
                data-testid="create-new-git-connect"
            >
              {t('test-project#git#connect')}
            </Button>
            <Collapse isOpen={isOpenCreateGitTestProject}>
              <FormGroup>
                <Label for="branch">{t('test-project#git#branch')}</Label>
                <Select
                    value={branch}
                    options={branches}
                    onChange={this.handleSelectBranch}
                    required
                />
              </FormGroup>
              <FormGroup>
                <Label for="name">{t('name')}</Label>
                <Input
                    id="name"
                    name="name"
                    type="text"
                    value={name}
                    onChange={this.handleOnChange}
                    required
                    placeholder={t('git-repository#form-title#name')}
                />
              </FormGroup>
              { MFlags.supportTargetDirectoryOnGitRepositoryEnabled &&
                  <FormGroup>
                    <div className="d-flex">
                      <Label for="targetDirectory">{t('test-project#git#target-directory')}</Label>
                      <TooltipComponent text={t('test-project#git#tooltip-target-directory')} />
                    </div>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText className="bg-transparent"><IconBackSlash/></InputGroupText>
                      </InputGroupAddon>
                      <Input
                          style={{
                            borderLeft: 'none'
                          }}
                          id="targetDirectory"
                          name="targetDirectory"
                          type="text"
                          value={targetDirectory}
                          onChange={this.handleOnChange}
                          placeholder={t('git-repository#form-title#target-directory')}
                      />
                    </InputGroup>
                  </FormGroup>
              }
              <FormGroup>
                <Label for="description">{t('test-project#description')}</Label>
                <Input
                    id="description"
                    name="description"
                    value={description}
                    onChange={this.handleOnChange}
                    type="textarea"
                    rows="5"
                    placeholder={t('git-repository#form-title#description')}
                />
              </FormGroup>
              <Button type="submit" color="primary">
                {this.testProjectId ? t('test-project#update') : t('test-project#create')}
              </Button>
            </Collapse>
          </Form>
        </>
    );
  }

  render() {
    return this.renderForm();
  }
}

TestProjectConfigForm.propTypes = {
  /**
   * Handle redirect after submit
   */
  handleRedirect: PropTypes.func,

  /**
   * Info of git test project when editing
   */
  gitRepository: PropTypes.object,

  isSetUpPage: PropTypes.bool
};

TestProjectConfigForm.defaultProps = {
  gitRepository: {},
  isSetUpPage: false
};

export default TestProjectConfigForm;
