import React, { ReactNode } from 'react';
import Drawer from '@mui/material/Drawer';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import { IconCloseInactive } from './../images/CustomIcon';

interface DrawerLayoutProps {
  isOpen: boolean;
  closeDrawer: () => void;
  category?: ReactNode;
  title?: string;
  content?: ReactNode;
}

function DrawerLayout(props: DrawerLayoutProps) {

  const { isOpen, closeDrawer, category, title, content } = props;

  const toggleDrawer = () => {
    closeDrawer();
  };

  const renderHeader = () => (
    <div className="header-title">
      {title}
    </div>
  );

  const renderContent = () => (
    <div>
      {content}
    </div>
  );

  const renderCategory = () => (
    <div>
      {category}
    </div>
  );

  const renderDrawer = () => (
    <div>
      <CardHeader
        action={
          <IconButton onClick={toggleDrawer} aria-label="close">
            <IconCloseInactive />
          </IconButton>
        }
        subheader={renderCategory()}
      />
      {renderHeader()}
      <CardContent>
        {renderContent()}
      </CardContent>
    </div>
  );

  return (
    <div className="drawer-layout">
      <Drawer
        PaperProps={{
          className: 'drawer-paper',
        }}
        hideBackdrop
        variant="persistent"
        anchor="right"
        open={isOpen}
      >
        {renderDrawer()}
      </Drawer>
    </div>
  );
}

export default DrawerLayout;
