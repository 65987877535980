import React from 'react';
import { ScrollMenu } from 'react-horizontal-scrolling-menu';
import AutoScrollDrag from './utils/AutoScrollDrag';

const AutoScroll = ({ children }) => {
  const { dragStart, dragStop, dragMove } = AutoScrollDrag();

  const handleDrag = ({ scrollContainer }) => (ev) => dragMove(ev, (posDiff) => {
    if (scrollContainer.current) {
      scrollContainer.current.scrollLeft -= posDiff;
    }
  });

  return (
    <div onMouseLeave={dragStop}>
      <ScrollMenu
        onMouseDown={() => dragStart}
        onMouseUp={() => dragStop}
        onMouseMove={handleDrag}
      >
        {children}
      </ScrollMenu>
    </div>
  );
};

export default AutoScroll;
