import React, { Component } from 'react';
import { Form, FormGroup, Label, FormText, Row, Col, CustomInput } from 'reactstrap';
import MContext from '../../../models/MContext';
import Services from '../../../utils/Services';
import { t } from '../../../i18n/t';
import DocumentLink from '../../../utils/DocumentLink';
import CodeBox from '../../../components/CodeBox';
import Select from '../../../components/Select';
import RouteConstants from '../../../utils/RouteConstants';
import Stepper from '../../../components/wizard/Stepper';
import {
  IconJUnitColor,
  IconTestNGColor,
  IconJestColor,
  IconJasmineColor,
  IconMochaColor,
  IconCypressColor,
  IconPytestColor,
  IconProtractorJasmineColor,
  IconProtractorMochaColor
} from '../../../images/CustomIcon';
import { IntegrationType, FrameworksVersion, MAX_PAGE_SIZE, SearchEntity } from '../../../utils/Constants';
import MConfigs from '../../../models/MConfigs';
import Routes from '../../../utils/Routes';
import TooltipComponent from '../../../components/TooltipComponent';
import MFlags from '../../../models/MFlags';
import SelectApiKeyComponent from '../../../components/SelectApiKeyComponent';

class FrameworksIntegration extends Component {
  constructor(props) {
    super(props);
    this.projectId = MContext.projectId;
    this.serverUrl = MConfigs.serverUrl;

    this.state = this.getInitialState();
    this.handleSelectApiKey = this.handleSelectApiKey.bind(this);
    this.renderSetUpVisualBaselineCollection = this.renderSetUpVisualBaselineCollection.bind(this);
    this.handleToggleConfigBaselineCollection = this.handleToggleConfigBaselineCollection.bind(this);
    this.getBaselineCollections = this.getBaselineCollections.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.renderKeyWordForVST = this.renderKeyWordForVST.bind(this);
  }

  getInitialState() {
    return {
      tabIndex: 0,
      apiKeys: [],
      apiKey: '',
      configuration: '',
      command: '',
      hasConfigBaselineCollection: false,
      baselineCollections: [],
      baselineCollectionGroup: null,
    };
  }

  componentDidMount() {
    const { searchApiKeyEnabled } = MFlags;

    if (!searchApiKeyEnabled) {
      this.getApiKeys();
    }
    this.getBaselineCollections();
  }

  getBaselineCollections() {
    const projectId = MContext.projectId;
    const params = {
      pagination: {
        page: 0,
        size: MAX_PAGE_SIZE,
        sorts: ['order,desc'],
      },
      conditions: [
        {
          key: 'Project.id',
          operator: '=',
          value: projectId,
        },
      ],
      type: SearchEntity.BaselineCollectionGroup,
    };

    Services.searchRecursively(0, params, []).then((res) => {
      const baselineCollections = res.map((item) => ({
        id: item.id,
        value: item.order,
        label: item.name
      }));
      this.setState({
        baselineCollections,
        baselineCollectionGroup: baselineCollections[0]
      });
    });
  }

  getApiKeys() {
    Services.getApiKeys()
      .then((response) => {
        const apiKeys = response.map((apikey) => ({
          value: apikey.key,
          label: apikey.name,
        }));

        this.setState({
          apiKeys,
          apiKey: apiKeys[0],
        }, () => {
          this.generateCommand();
        });
      });
  }

  generateCommand() {
    const command = 'mvn test';
    this.setState({
      command
    });
  }

  handleSelectApiKey(apiKey) {
    this.setState(
      { apiKey },
      () => {
        this.generateCommand();
      }
    );
  }

  renderSelectCredential() {
    const { apiKey, apiKeys } = this.state;
    const { searchApiKeyEnabled } = MFlags;

    return (
      <Row>
        <Col sm="12" md="8" lg="6" xl="5">
          <FormGroup>
            <Label for="apiKey">{t('agent#api-key')}</Label>
            {searchApiKeyEnabled ?
              <SelectApiKeyComponent onChange={this.handleSelectApiKey} />
              :
              <Select
                clearable={false}
                id="apiKey"
                value={apiKey}
                onChange={this.handleSelectApiKey}
                options={apiKeys}
                data-testid="select-apikey"
              />}

            <FormText color="muted">
              {t('frameworks-integration#generate-api-key')}
              <a
                href={RouteConstants.apikey}
              >
                here.
              </a>
            </FormText>
          </FormGroup>
        </Col>
      </Row>
    );
  }

  renderRunCommand() {
    const { integrationType } = this.props;
    let { command } = this.state;
    switch (integrationType) {
      case IntegrationType.MOCHA:
        command = 'npx mocha --reporter @katalon/testops-mocha';
        break;
      case IntegrationType.CYPRESS:
        command = 'node cypress-cli.js';
        break;
      case IntegrationType.JEST:
        command = 'npx jest';
        break;
      case IntegrationType.JASMINE:
        command = 'npx jasmine';
        break;
      case IntegrationType.ROBOT:
        command = 'robot --listener testops.Listener <<your file test>>';
        break;
      case IntegrationType.PYTEST:
        command = 'pytest';
        break;
      case IntegrationType.PROTRACTOR_JASMINE:
      case IntegrationType.PROTRACTOR_MOCHA:
        command = 'npx protractor';
        break;
      default:
        break;
    }
    return (
      <FormGroup>
        <CodeBox content={command} />
      </FormGroup>
    );
  }

  renderData() {
    const { integrationType } = this.props;
    const { JUNIT4, JUNIT5, TESTNG, CYPRESS, MOCHA, JASMINE, JEST, PYTEST, PROTRACTOR_JASMINE, PROTRACTOR_MOCHA, ROBOT } = IntegrationType;
    const items = [
      {
        label: 'Mocha',
        value: MOCHA,
        content: this.renderJsSteps(),
        icon: <IconMochaColor />
      },
      {
        label: 'Cypress',
        value: CYPRESS,
        content: this.renderJsSteps(),
        icon: <IconCypressColor />
      },
      {
        label: 'Jest',
        value: JEST,
        content: this.renderJsSteps(),
        icon: <IconJestColor />
      },
      {
        label: 'Jasmine',
        value: JASMINE,
        content: this.renderJsSteps(),
        icon: <IconJasmineColor />
      },
      {
        label: 'Pytest',
        value: PYTEST,
        content: this.renderJsSteps(),
        icon: <IconPytestColor />
      },
      {
        label: 'Protractor Jasmine',
        value: PROTRACTOR_JASMINE,
        content: this.renderJsSteps(),
        icon: <IconProtractorJasmineColor />
      },
      {
        label: 'Protractor Mocha',
        value: PROTRACTOR_MOCHA,
        content: this.renderJsSteps(),
        icon: <IconProtractorMochaColor />
      },
      {
        label: 'JUnit 4',
        value: JUNIT4,
        content: this.renderJavaSteps(),
        icon: <IconJUnitColor />
      },
      {
        label: 'JUnit 5',
        value: JUNIT5,
        content: this.renderJavaSteps(),
        icon: <IconJUnitColor />
      },
      {
        label: 'TestNG',
        value: TESTNG,
        content: this.renderJavaSteps(),
        icon: <IconTestNGColor />
      },
      {
        label: t('integrations#robot'),
        value: ROBOT,
        content: this.renderJsSteps(),
        icon: <IconPytestColor />
      },
    ];

    return items.map(({ content, value }) => {
      if (value === integrationType) {
        return content;
      }
      return null;
    });
  }

  renderJunit4Plugin() {
    const plugin =
      '<plugin>\n' +
      '    <groupId>org.apache.maven.plugins</groupId>\n' +
      '    <artifactId>maven-surefire-plugin</artifactId>\n' +
      '    <version>3.0.0-M5</version>\n' +
      '    <dependencies>\n' +
      '        <dependency>\n' +
      '            <groupId>org.apache.maven.surefire</groupId>\n' +
      '            <artifactId>surefire-junit4</artifactId>\n' +
      '            <version>3.0.0-M5</version>\n' +
      '        </dependency>\n' +
      '    </dependencies>\n' +
      '    <configuration>\n' +
      '        <testFailureIgnore>true</testFailureIgnore>\n' +
      '        <properties>\n' +
      '            <property>\n' +
      '                <name>listener</name>\n' +
      '                <value>com.katalon.testops.junit.reporter.ReportListener</value>\n' +
      '            </property>\n' +
      '        </properties>\n' +
      '    </configuration>\n' +
      '</plugin>\n';
    return (
      <FormGroup>
        <CodeBox header="pom.xml" content={plugin} />
      </FormGroup>
    );
  }

  renderDependency() {
    const { integrationType } = this.props;
    let configuration =
      '<dependency>\n' +
      '    <groupId>com.katalon</groupId>\n' +
      '    <artifactId>testops-junit4</artifactId>\n' +
      `    <version>${FrameworksVersion.JUNIT4}</version>\n` +
      '</dependency>';

    let plugin = null;
    if (integrationType === IntegrationType.JUNIT4) {
      plugin = this.renderJunit4Plugin();
    }
    if (integrationType === IntegrationType.TESTNG) {
      configuration =
        '<dependency>\n' +
        '    <groupId>com.katalon</groupId>\n' +
        '    <artifactId>testops-testng</artifactId>\n' +
        `    <version>${FrameworksVersion.TESTNG}</version>\n` +
        '</dependency>';
    }
    if (integrationType === IntegrationType.JUNIT5) {
      configuration =
        '<dependency>\n' +
        '    <groupId>com.katalon</groupId>\n' +
        '    <artifactId>testops-junit5</artifactId>\n' +
        `    <version>${FrameworksVersion.JUNIT5}</version>\n` +
        '</dependency>';
    }

    const dependency = (
      <FormGroup>
        <CodeBox header="pom.xml" content={configuration} />
      </FormGroup>
    );

    return (
      <>
        {dependency}
        {plugin}
      </>
    );
  }

  renderSteps(steps) {
    return (
      <Form>
        <Stepper
          id="report-uploader-setup"
          data-trackid="report-uploader-setup"
          expanded
          steps={steps}
        />
      </Form>
    );
  }

  renderBaseJsConfiguration() {
    const { apiKey, baselineCollectionGroup, hasConfigBaselineCollection } = this.state;
    const isShowConfigBaselineCollection = hasConfigBaselineCollection && baselineCollectionGroup;
    const configuration =
      '{\n' +
      `   "apiKey": "${apiKey?.value}",\n` +
      `   "projectId": "${this.projectId}",\n` +
      '   "reportFolder": "testops-report"' +
      `${isShowConfigBaselineCollection ? `,\n   "baselineCollectionId": "${baselineCollectionGroup?.value}"\n` : '\n'}` +
      '}';
    return (
      <FormGroup>
        <Label>
          {t('frameworks-integration#configure-credentials')}
        </Label>
        <p className="stepper__description">
          {t('frameworks-integration#createfile', { fileName: 'testops-config.json' })}
        </p>
        <CodeBox header="testops-config.json" content={configuration} />
      </FormGroup>
    );
  }

  renderJsConfiguration() {
    const { integrationType } = this.props;
    const { hasConfigBaselineCollection } = this.state;
    return (
      <>
        {integrationType === IntegrationType.ROBOT && this.renderSetUpVisualBaselineCollection()}
        {this.renderBaseJsConfiguration()}
        {integrationType !== IntegrationType.MOCHA && integrationType !== IntegrationType.ROBOT && this.renderAddReport()}
        {integrationType !== IntegrationType.PYTEST && integrationType !== IntegrationType.ROBOT && this.renderProxyConfig()}
        {integrationType === IntegrationType.ROBOT && hasConfigBaselineCollection && this.renderKeyWordForVST()}
      </>
    );
  }

  renderJavaPropertiesConfiguration() {
    const { apiKey } = this.state;
    const properties =
      `testops.server-url=${this.serverUrl}\n` +
      `testops.api-key=${apiKey?.value}\n` +
      `testops.project-id=${this.projectId}\n`;
    return (
      <FormGroup>
        <CodeBox header="testops.properties" content={properties} />
      </FormGroup>
    );
  }

  renderJavaSteps() {
    const steps = [
      {
        label: t('api_key#choose'),
        content: this.renderSelectCredential(),
      },
      {
        label: t('java#dependency'),
        content: this.renderDependency(),
      },
      {
        label: t('java#properties'),
        content: this.renderJavaPropertiesConfiguration(),
        description: t('java#properties#description'),
      },
      {
        label: t('plugin#importReport'),
        content: this.renderRunCommand(),
        description:
          t('plugin#importReport#description'),
      },
      {
        label: t('frameworks-integration#view-report-header'),
        content: this.renderViewReportRoute(),
      },
      {
        label: t('plugin#step#learn-more'),
        content: this.renderLearnMore(),
      },
    ];
    return this.renderSteps(steps);
  }

  getFrameworkName() {
    const { integrationType } = this.props;
    switch (integrationType) {
      case IntegrationType.JUNIT4:
      case IntegrationType.JUNIT5:
        return t('junit#test#environment');
      case IntegrationType.TESTNG:
        return t('testng#test#environment');
      case IntegrationType.MOCHA:
        return t('mocha#test#environment');
      case IntegrationType.CYPRESS:
        return t('cypress#test#environment');
      case IntegrationType.JEST:
        return t('jest#test#environment');
      case IntegrationType.JASMINE:
        return t('jasmine#test#environment');
      case IntegrationType.PYTEST:
        return t('pytest#test#environment');
      case IntegrationType.ROBOT:
        return t('robot#test#environment');
      case IntegrationType.PROTRACTOR_JASMINE:
        return t('protractor-jasmine#test#environment');
      case IntegrationType.PROTRACTOR_MOCHA:
        return t('protractor-mocha#test#environment');
      default:
        return t('junit#test#environment');
    }
  }

  getGitRepository() {
    const { integrationType } = this.props;
    switch (integrationType) {
      case IntegrationType.JUNIT4:
        return DocumentLink.JUNIT4_GIT_REPOSITORY;
      case IntegrationType.JUNIT5:
        return DocumentLink.JUNIT5_GIT_REPOSITORY;
      case IntegrationType.TESTNG:
        return DocumentLink.TESTNG_GIT_REPOSITORY;
      case IntegrationType.MOCHA:
      case IntegrationType.CYPRESS:
      case IntegrationType.JEST:
      case IntegrationType.ROBOT:
        return DocumentLink.ROBOT_GIT_REPOSITORY;
      case IntegrationType.JASMINE:
      case IntegrationType.PROTRACTOR_JASMINE:
      case IntegrationType.PROTRACTOR_MOCHA:
        return DocumentLink.JS_REPORT_GIT_REPOSITORY;
      case IntegrationType.PYTEST:
        return DocumentLink.PYTEST_GIT_REPOSITORY;
      default:
        return DocumentLink.JUNIT4_GIT_REPOSITORY;
    }
  }

  renderLearnMore() {
    const frameworkName = this.getFrameworkName();
    const linkRepository = this.getGitRepository();

    return (
      <span>Learn how to set up a {frameworkName}&nbsp;
        <a
          href={linkRepository}
          target="_blank"
          rel="noreferrer noopener"
        >
          here
        </a>.
      </span>
    );
  }

  renderViewReportRoute() {
    const { integrationType } = this.props;
    const { hasConfigBaselineCollection } = this.state;
    const routes = new Routes();
    const isTypeRobot = integrationType === IntegrationType.ROBOT;
    return (
      <span>Navigate to&nbsp;
        <a
          href={isTypeRobot ? routes.keyes_link : routes.executions_link}
          target="_blank"
          rel="noreferrer noopener"
        >
          {isTypeRobot && hasConfigBaselineCollection ? t('testops-keyes') : t('feature-report-analytics')}
        </a> to view test results.
      </span>
    );
  }

  renderInstallationCommand() {
    const { integrationType } = this.props;
    let command;
    switch (integrationType) {
      case IntegrationType.MOCHA:
        command = 'npm i -s @katalon/testops-mocha';
        break;
      case IntegrationType.CYPRESS:
        command = 'npm i -s @katalon/testops-cypress';
        break;
      case IntegrationType.JEST:
        command = 'npm i -s @katalon/testops-jest';
        break;
      case IntegrationType.JASMINE:
        command = 'npm i -s @katalon/testops-jasmine';
        break;
      case IntegrationType.PYTEST:
        command = 'pip3 install testops-pytest';
        break;
      case IntegrationType.ROBOT:
        command = 'pip install testops-robot';
        break;
      case IntegrationType.PROTRACTOR_JASMINE:
        command = 'npm i -s @katalon/testops-jasmine @katalon/testops-protractor';
        break;
      case IntegrationType.PROTRACTOR_MOCHA:
        command = 'npm i -s @katalon/testops-mocha @katalon/testops-protractor';
        break;
      default:
        break;
    }
    return (
      <FormGroup>
        <CodeBox content={command} />
      </FormGroup>
    );
  }

  renderProxyConfig() {
    const content =
      '   "proxy": {\n' +
      '       "protocol": "", // Value: http, https\n' +
      '       "host": "",\n' +
      '       "port": "",\n' +
      '       "auth": {\n' +
      '           "username": "",\n' +
      '           "password": ""\n' +
      '       }\n' +
      '   }\n';
    const contentDisplay =
      `${'{\n' +
      '   ...\n'}${content
      }}`;
    return (
      <FormGroup>
        <Label>
          {t('frameworks-integration#configure-proxy')}
        </Label>
        <p className="stepper__description">
          {t('frameworks-integration#configure-proxy-description')}
        </p>
        <CodeBox header="testops-config.json" content={content} contentDisplay={contentDisplay} />
      </FormGroup>
    );
  }

  getApiKeyDescription() {
    const { integrationType } = this.props;
    switch (integrationType) {
      case IntegrationType.MOCHA:
        return `${t('frameworks-integration#generate-api-key-des')}Mocha.`;
      case IntegrationType.JEST:
        return `${t('frameworks-integration#generate-api-key-des')}Jest.`;
      case IntegrationType.JASMINE:
        return `${t('frameworks-integration#generate-api-key-des')}Jasmine.`;
      case IntegrationType.PYTEST:
        return `${t('frameworks-integration#generate-api-key-des')}Pytest.`;
      case IntegrationType.ROBOT:
        return `${t('frameworks-integration#generate-api-key-des')}${t('integrations#robot')}.`;
      case IntegrationType.PROTRACTOR_JASMINE:
        return `${t('frameworks-integration#generate-api-key-des')}Protractor Jasmine.`;
      case IntegrationType.PROTRACTOR_MOCHA:
        return `${t('frameworks-integration#generate-api-key-des')}Protractor Mocha.`;
      default:
        return null;
    }
  }

  renderJsSteps() {
    const steps = [
      {
        label: t('api_key#choose'),
        content: this.renderSelectCredential(),
        description: this.getApiKeyDescription()
      },
      {
        label: 'Install dependency',
        content: this.renderInstallationCommand(),
        description:
          t('dependency-js#description', { name: this.getFrameworkName() }),
      },
      {
        label: 'Configure TestOps plugin',
        content: this.renderJsConfiguration(),
      },
      {
        label: t('frameworks-integration#uploadreport'),
        content: this.renderRunCommand(),
        description:
          t('plugin#importReport#description'),
      },
      {
        label: t('frameworks-integration#view-report-header'),
        content: this.renderViewReportRoute(),
      },
      {
        label: t('plugin#step#learn-more'),
        content: this.renderLearnMore(),
      },
    ];
    return this.renderSteps(steps);
  }

  renderAddReport() {
    const { integrationType } = this.props;
    let configuration;
    let fileName;
    if (integrationType === IntegrationType.CYPRESS) {
      configuration =
        "const cypress = require('cypress')\n" +
        "const CypressTestOpsReporter = require('@katalon/testops-cypress');\n\n" +
        'cypress.run({})\n' +
        '.then((results) => {\n' +
        '   const reporter = new CypressTestOpsReporter();\n' +
        '   reporter.parseAndUploadTestResults(results);\n' +
        '})\n' +
        '.catch((err) => {\n' +
        '   console.error(err);\n' +
        '})';
      fileName = 'cypress-cli.js';
    }
    if (integrationType === IntegrationType.JEST) {
      configuration =
        'module.exports = {\n' +
        '   "reporters": ["default", "@katalon/testops-jest"]\n' +
        '}';
      fileName = 'jest.config.js';
    }
    if (integrationType === IntegrationType.JASMINE) {
      configuration =
        'import TestOpsJasmineReporter from "@katalon/testops-jasmine";\n\n' +
        'const reporter = new TestOpsJasmineReporter();\n\n' +
        'jasmine.getEnv().addReporter(reporter);';
      fileName = './tests/setup.js';
    }
    if (integrationType === IntegrationType.PYTEST) {
      configuration =
        'pytest_plugins = ["testops_pytest.listener", ]';
      fileName = 'conftest.py';
    }
    if (integrationType === IntegrationType.PROTRACTOR_JASMINE) {
      configuration =
        'const TestOpsJasmineReporter = require(\'@katalon/testops-jasmine\');\n\n' +
        'exports.config = {\n' +
        '   ...\n' +
        '   framework: "jasmine",\n' +
        '   plugins: [\n' +
        '      {\n' +
        '         package: "@katalon/testops-protractor",\n' +
        '      }\n' +
        '   ],\n' +
        '   onPrepare: () => {\n' +
        '      const reporter = new TestOpsJasmineReporter();\n' +
        '      jasmine.getEnv().addReporter(reporter);\n' +
        '   }\n' +
        '   ...\n' +
        '}';
      fileName = 'protractor.conf.js';
    }
    if (integrationType === IntegrationType.PROTRACTOR_MOCHA) {
      configuration =
        'exports.config = {\n' +
        '   ...\n' +
        '   framework: "mocha",\n' +
        '   plugins: [\n' +
        '      {\n' +
        '         package: "@katalon/testops-protractor",\n' +
        '      }\n' +
        '   ],\n' +
        '   mochaOpts: {\n' +
        '      timeout: "20s",\n' +
        '      reporter: "@katalon/testops-mocha",\n' +
        '   },\n' +
        '}';
      fileName = 'protractor.conf.js';
    }
    return (
      <FormGroup>
        <Label>
          Add report
        </Label>
        <p className="stepper__description">
          {t('frameworks-integration#createfile', { fileName })}
        </p>
        <CodeBox header={fileName} content={configuration} />
      </FormGroup>
    );
  }

  renderKeyWordForVST() {
    const configuration =
      'from testops_commons.uploader import VisualTestingUploader\n\n\n' +
      'class VisualLibrary(object):\n\n' +
      '     def __init__(self):\n' +
      '         self.__uploader = VisualTestingUploader()\n\n' +
      '     def verify_checkpoint(self, checkpoint):\n' +
      '         self.__uploader.verify_checkpoint(checkpoint)';
    const fileName = 'katalon_visual_testing.py';
    const title = t('add_keyword_for_visual_testing');
    return (
      <FormGroup>
        <Label>
          {title}
        </Label>
        <p className="stepper__description">
          {t('description_keyword_for_visual_testing')}
        </p>
        <CodeBox header={fileName} content={configuration} />
      </FormGroup>
    );
  }

  handleSelectChange(event, option) {
    this.setState({
      baselineCollectionGroup: option
    }, () => this.renderBaseJsConfiguration());
  }

  handleToggleConfigBaselineCollection() {
    const { hasConfigBaselineCollection, baselineCollections } = this.state;
    this.setState({
      hasConfigBaselineCollection: !hasConfigBaselineCollection,
      baselineCollectionGroup: hasConfigBaselineCollection ? null : baselineCollections[0]
    }, () => this.renderBaseJsConfiguration());
  }

  renderSetUpVisualBaselineCollection() {
    const { hasConfigBaselineCollection, baselineCollections, baselineCollectionGroup } = this.state;
    const placeholderSelectContent = baselineCollections.length > 0 ? t('select_baseline_collection') : t('no_options_baseline_collection');

    return (
      <FormGroup className="d-flex">
        <CustomInput
          data-testid="toggle-config-baseline-collection"
          id="config-baseline-collection"
          type="switch"
          name="config-baseline-collection"
          checked={hasConfigBaselineCollection}
          onChange={() => this.handleToggleConfigBaselineCollection()}
          className="normal-label"
        />
        <div className="ml-1">
          <div>
            <Label for="config-baseline-collection">{t('visual_baseline_collection')}</Label>
            <TooltipComponent
              text={t('no_options_baseline_collection_tooltip_content')}
              placement="right"
              arrow
            />
          </div>
          <Select
            options={baselineCollections}
            onChange={this.handleSelectChange}
            useAutocomplete
            disableClearable
            blurOnSelect
            placeholder={placeholderSelectContent}
            className="config-baseline-collection"
            disabled={!hasConfigBaselineCollection}
            key={hasConfigBaselineCollection}
            defaultValue={baselineCollectionGroup}
          />
        </div>
      </FormGroup>
    );
  }

  render() {
    return this.renderData();
  }
}

export default FrameworksIntegration;
