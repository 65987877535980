import { useEffect, useRef, useState } from 'react';
import momenttz from 'moment-timezone';
import PropTypes from 'prop-types';

import { UPDATE_DISPLAY_TIME_INTERVAL } from './constants';

const displayTimeInString = (timestamp) => momenttz(timestamp).fromNow();

/**
 * Component to display time of the notification (i.e: a few sec ago). The string will automatically update base on the
 * interval.
 */
const NotificationItemDisplayTime = (props) => {
  const { timestamp } = props;
  const [displayTime, setDisplayTime] = useState(displayTimeInString(timestamp));
  const interval = useRef();

  useEffect(() => {
    interval.current = setInterval(() => {
      const dt = displayTimeInString(timestamp);
      setDisplayTime(dt);
    }, UPDATE_DISPLAY_TIME_INTERVAL);

    return () => {
      if (interval.current) {
        clearInterval(interval.current);
      }
    };

  }, [timestamp]);

  return displayTime;
};

NotificationItemDisplayTime.propTypes = {
  timestamp: PropTypes.string.isRequired,
};

export default NotificationItemDisplayTime;
