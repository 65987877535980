import { get } from 'lodash';
import { t } from '../../i18n/t';
import Notification from '../Notification';
import Services from '../Services';

export const saveToBaseline = (keyesExecution, refreshData, checkpointsRef) => {
  Services.saveExecutionToBaseline(keyesExecution.id).then((response) => {
    const baselineCollectionGroup = get(response, 'baselineCollection.baselineCollectionGroup');
    const { name, latestVersion: { version } } = baselineCollectionGroup;
    Notification.pushSuccess(t('save_to_baseline_success', { name, version }), t('notification-title#success'));
    if (refreshData) {
      refreshData();
    }
    if (checkpointsRef.current) {
      checkpointsRef.current.refreshData();
    }
  });
};
