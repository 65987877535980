import React, { Component } from 'react';
import { t } from '../../../i18n/t';
import Routes from '../../../utils/Routes';
import DataTable from '../../../components/table/DataTable';
import MTableColumnDataMapping from '../../../components/table/models/MTableColumnDataMapping';
import DecoratorConstants from '../../../utils/DecoratorConstants';
import ActionDropdownMenu from '../../../components/action/ActionDropdownMenu';
import ActionDropdownItem from '../../../components/action/ActionDropdownItem';
import ArchiveProjectDialog from '../../../components/dialog/ArchiveProjectDialog';
import { ProjectStatus } from '../../../utils/Constants';
import UnArchiveProjectDialog from '../../../components/dialog/UnArchiveProjectDialog';
import MContext from '../../../models/MContext';

class ProjectDataTable extends Component {
  constructor(props) {
    super(props);
    this.organizationId = MContext.organizationId;
    this.archiveProjectRef = React.createRef();
    this.unArchiveProjectRef = React.createRef();
    this.projectsList = React.createRef();
    this.state = {
      project: null,
    };
    this.refreshData = this.refreshData.bind(this);
    this.refreshProjectsActiveData = this.refreshProjectsActiveData.bind(this);
  }

  refreshProjectsActiveData() {
    this.projectsList.current.refreshData();
  }

  refreshData() {
    this.refreshProjectsActiveData();
  }

  showProjectDialog(project) {
    this.setState(
      {
        project,
      },
      () => this.showDialogByStatus()
    );
  }

  showDialogByStatus() {
    const { status } = this.props;
    if (status === ProjectStatus.ARCHIVE) {
      this.archiveProjectRef.current.toggle();
    } else {
      this.unArchiveProjectRef.current.toggle();
    }
  }

  renderButton(row) {
    const { icon, label, title } = this.props;

    return (
      <ActionDropdownItem
        icon={icon}
        label={label}
        tag="button"
        onClick={() => this.showProjectDialog(row)}
        color="link"
        title={title}
      />
    );
  }

  renderProjects() {
    const columnMapping = [
      new MTableColumnDataMapping(t('name'), 'name', (name, row) => {
        const link = new Routes({
          teamId: row.team.id,
          projectId: row.id,
        }).project_link;
        return DecoratorConstants.idDecorator(name, row, link);
      }),
      new MTableColumnDataMapping('Team', 'team.name'),
      new MTableColumnDataMapping(
        t('table-header#action'),
        'id',
        (name, row) => {
          const items = [this.renderButton(row)];
          return (
            <>
              <ActionDropdownMenu hideHeader direction="left">
                {items}
              </ActionDropdownMenu>
            </>
          );
        },
        true,
        'fit-content-column'
      ),
    ];
    const newProps = {
      ...this.props,
      columnMapping,
    };
    return (
      <DataTable
        ref={this.projectsList}
        entityType="Project"
        {...newProps}
      />
    );
  }

  renderArchiveProjectsDialogs() {
    const { project } = this.state;
    return (
      <ArchiveProjectDialog
        ref={this.archiveProjectRef}
        project={project}
        status={ProjectStatus.ARCHIVE}
        afterArchiveProject={this.refreshProjectsActiveData}
      />
    );
  }

  renderUnArchiveProjectsDialogs() {
    const { project } = this.state;
    return (
      <UnArchiveProjectDialog
        ref={this.unArchiveProjectRef}
        project={project}
        status={ProjectStatus.ACTIVE}
        afterUnarchiveProject={this.refreshProjectsActiveData}
      />
    );
  }

  render() {
    return (
      <>
        {this.renderProjects()}
        {this.renderArchiveProjectsDialogs()}
        {this.renderUnArchiveProjectsDialogs()}
      </>
    );
  }
}

export default ProjectDataTable;
