import PropTypes from 'prop-types';
import React from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter/dist/cjs/light';
import { duotoneLight } from 'react-syntax-highlighter/dist/cjs/styles/prism';
import { t } from '../i18n/t';
import { IconCopy } from '../images/KitIcons';
import Notification from '../utils/Notification';

export default class CodeBox extends React.Component {

  copyToClipboard(content) {
    const el = document.createElement('textarea');
    el.value = content;
    el.setAttribute('readonly', '');
    el.style = { display: 'none' };
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  }

  copyCommand(content) {
    this.copyToClipboard(content);
    Notification.pushSuccess('Your text has been copied to your clipboard.');
  }

  render() {
    const { content, header, language, style, contentDisplay } = this.props;

    return (
      <div>
        <div className="code-box">
          <div className="text-header p-1">
            {header || 'Bash'}
          </div>
          <div className="ml-auto p-1">
            <a
              onClick={() => this.copyCommand(content)}
            >
              <IconCopy />
              {t('codeBox#copy')}
            </a>
          </div>
        </div>
        <SyntaxHighlighter
          className="m-0"
          language={language || 'bash'}
          style={style || duotoneLight}
        >
          {contentDisplay || content}
        </SyntaxHighlighter>
      </div>
    );
  }
}

CodeBox.propTypes = {
  content: PropTypes.string.isRequired,
  header: PropTypes.string,
  language: PropTypes.string,
};
