import { get, isNumber } from 'lodash';
import { ENGINE_STATUS, COMPARISON_TYPE } from '../component/Constants';

export function getLayoutContentAnalyzingStatus(compareResult, jsonDiffFile) {
  if (compareResult) {
    return get(compareResult, jsonDiffFile) ? ENGINE_STATUS.DONE : ENGINE_STATUS.ANALYZING;
  }
  return ENGINE_STATUS.SKIPPED;
}

export function getPixelAnalyzingStatus(compareResult) {
  if (compareResult) {
    const score = get(compareResult, 'diffScore');
    return isNumber(score) ? ENGINE_STATUS.DONE : ENGINE_STATUS.ANALYZING;
  }
  return ENGINE_STATUS.SKIPPED;
}

export function isComparisonMethodAnalyzing(method, checkpoint) {
  const { checkpointPixel, checkpointLayout, checkpointContent } = checkpoint;
  switch (method) {
    case COMPARISON_TYPE.PIXEL:
      return getPixelAnalyzingStatus(checkpointPixel) === ENGINE_STATUS.ANALYZING;
    case COMPARISON_TYPE.LAYOUT:
      return getLayoutContentAnalyzingStatus(checkpointLayout, 'layoutDiffZoneJsonFile') === ENGINE_STATUS.ANALYZING;
    case COMPARISON_TYPE.CONTENT:
      return getLayoutContentAnalyzingStatus(checkpointContent, 'contentDiffZoneJsonFile') === ENGINE_STATUS.ANALYZING;
    default:
      return false;
  }
}
