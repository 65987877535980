import React from 'react';
import { Button } from 'reactstrap';
import AlertDialog from '../../components/dialog/AlertDialog';
import PageButtonToolbar from '../../components/PageButtonToolbar';
import { buildSearchCondition } from '../../components/search/SearchUtils';
import Select from '../../components/Select';
import ObjectSummary from '../../components/summary/ObjectSummary';
import TabComponent from '../../components/TabComponent';
import ReleaseTable from '../../components/table/ReleaseTable';
import { t } from '../../i18n/t';
import MContext from '../../models/MContext';
import Apis from '../../utils/Apis';
import http from '../../utils/http';
import Routes from '../../utils/Routes';
import Services from '../../utils/Services';
import ReleaseStatusBar from './ReleaseStatusBar';
import Notification from '../../utils/Notification';
import DefaultLayout from '../../components/DefaultLayout';
import MFlags from '../../models/MFlags';
import ButtonLink from '../../components/ButtonLink';

class Releases extends TabComponent {
  constructor(props) {
    super(props);
    this.meta.id = 'page-releases';
    this.meta.title = t('Project {{name}} - Releases', { name: MContext.project.name });
    this.projectId = MContext.projectId;
    this.teamId = MContext.teamId;
    this.isTeamDemo = MContext.isTeamDemo;
    this.state = this.getInitialState();
    this.populateReleaseDialog = React.createRef();

    this.populateRelease = this.populateRelease.bind(this);
    this.onSelectProject = this.onSelectProject.bind(this);
    this.togglePopulateReleaseDialog = this.togglePopulateReleaseDialog.bind(this);
    this.renderHeader = this.renderHeader.bind(this);
    this.renderBody = this.renderBody.bind(this);
    this.refreshJiraReleaseStatus = this.refreshJiraReleaseStatus.bind(this);
    this.handlePopulateRelease = this.handlePopulateRelease.bind(this);
  }

  getInitialState() {
    return {
      project: MContext.project,
      active: false,
      externalReleases: [],
      integrationProject: {},
      integrationProjects: [],
    };
  }

  getIntegrationProjects() {
    return http.get(Apis.integrationProjects({ projectId: this.projectId }));
  }

  getConnection() {
    const params = {
      pagination: {
        page: 0,
        size: 1,
        sorts: 'order,desc',
      },
      conditions: [
        {
          key: 'Project.id',
          operator: '=',
          value: this.projectId,
        },
      ],
      type: 'ExternalConnection',
    };
    return Services.search(params)
      .then(({ content }) => {
        const connection = content[0];
        if (connection) {
          this.setState({ active: connection.active, defaultExternalProject: connection.defaultExternalProject });
        }
        return connection;
      });
  }

  componentDidMount() {
    this.getConnection()
      .then(({ active } = {}) => {
        if (active && !this.isTeamDemo) {
          this.getIntegrationProjects()
            .then((integrationProjects) => {
              this.setState({
                integrationProjects,
                integrationProject: integrationProjects[0] || {}
              });
            });
        }
      });
  }

  populateRelease(externalProjectId) {
    http.post(Apis.populateIntegrationReleases({
      projectId: this.projectId,
      integrationProjectId: externalProjectId,
    }))
      .then(() => {
        this.releasesTable.refreshData();
      });
  }

  handlePopulateRelease() {
    const { defaultExternalProject } = this.state;
    if (MFlags.removePopulateReleaseDialogEnabled && defaultExternalProject?.externalProjectId) {
      this.populateRelease(defaultExternalProject.externalProjectId);
    } else {
      this.togglePopulateReleaseDialog();
    }
  }

  togglePopulateReleaseDialog() {
    this.populateReleaseDialog.current.toggle();
  }

  onSelectProject(event) {
    const integrationProject = event ? event.item : {};
    this.setState({ integrationProject });
  }

  renderCreateRelease(teamId, projectId) {
    const routes = new Routes({
      teamId,
      projectId,
    });
    const {
      active,
      integrationProject,
      integrationProjects,
    } = this.state;

    const integrationProjectOpts = integrationProjects.map((item) => ({
      value: item.externalProjectId,
      item,
      label: item.name,
    }));
    return (
      <PageButtonToolbar>
        {active &&
        <>
          <AlertDialog
            ref={this.populateReleaseDialog}
            title={t('releases_populate')}
            handleApproval={() => this.populateRelease(integrationProject.externalProjectId)}
            disableApproveButton={!integrationProject.externalProjectId}
          >
            <Select
              clearable
              options={integrationProjectOpts}
              onChange={this.onSelectProject}
              value={integrationProject.externalProjectId}
              placeholder="Jira Project"
            />
          </AlertDialog>
          <Button
            data-trackid="populate-jira-releases"
            title={t('releases_populate')}
            color="secondary"
            onClick={this.handlePopulateRelease}
            disabled={this.isTeamDemo}
          >
            {t('releases_populate')}
          </Button>
        </>}
        <ButtonLink
          title={t('releases#create')}
          color="secondary"
          href={routes.create_release_link()}
          data-trackid="create-release"
          disabled={this.isTeamDemo}
        >
          {t('releases#create')}
        </ButtonLink>
        {active &&
        <Button
          color="secondary"
          variant="outlined"
          data-trackid="refresh-jira-release-status"
          title={t('refresh-jira-release-status-title')}
          onClick={this.refreshJiraReleaseStatus}
        >
          {t('refresh')}
        </Button>}
      </PageButtonToolbar>
    );
  }

  refreshJiraReleaseStatus() {
    Services.refreshExternalReleasesStatus(this.projectId)
      .then(() => {
        Notification.pushSuccess(t('refresh-jira-release-status-success'));
      });
  }

  renderReleases() {
    const { projectId, teamId } = this;
    const { active } = this.state;
    return (
      <>
        {this.renderCreateRelease(teamId, projectId)}
        <ReleaseStatusBar />
        <ReleaseTable
          ref={(ref) => {
            this.releasesTable = ref;
          }}
          defaultSort={MFlags.dashboardAIPhase2Enabled ? ['endTime,desc', 'startTime,desc', 'id,desc'] : ['endTime,asc']}
          defaultSearchConditions={[
            buildSearchCondition('Project.id', '=', this.projectId),
          ]}
          jiraConnection={active}
        />
      </>
    );
  }

  renderObjectSummary() {
    const urlParams = {
      releases: t('releases'),
      projectId: this.projectId,
    };
    return (
      <ObjectSummary params={urlParams} />
    );
  }

  renderHeader() {
    return (
      <>
        {this.renderObjectSummary()}
      </>
    );
  }

  renderBody() {
    return (
      <>
        {this.renderReleases()}
      </>
    );
  }

  render() {
    const { project } = this.state;
    if (project) {
      return (
        <DefaultLayout
          renderHeader={this.renderHeader}
          renderBody={this.renderBody}
        />
      );
    }
    return null;
  }
}

export default Releases;
