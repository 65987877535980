import React from 'react';
import PropTypes from 'prop-types';
import MContext from '../../models/MContext';
import RecentProject from './components/RecentProject';
import RecentProjects from './components/RecentProjects';
import LatestExecutions from './components/LatestExecutions';

class OrganizationDashboard extends React.Component {

  constructor(props) {
    super(props);
    this.autoUpdate = true;
    this.autoUpdateIntervalTime = 900000;
    this.organization = MContext.organization;
  }

  componentDidMount() {
  }

  renderRecentProjects() {
    return (
      <RecentProjects
        organization={this.organization}
        autoUpdate={this.autoUpdate}
        autoUpdateIntervalTime={this.autoUpdateIntervalTime}
      />
    );
  }

  renderLatestExecutions() {
    return (
      <LatestExecutions
        organization={this.organization}
        autoUpdate={this.autoUpdate}
        autoUpdateIntervalTime={this.autoUpdateIntervalTime}
      />
    );
  }

  render() {
    return (
      <>
        {this.renderRecentProjects()}
        {this.renderLatestExecutions()}
      </>
    );
  }
}

RecentProject.propTypes = {
  organization: PropTypes.object,
};

export default OrganizationDashboard;
