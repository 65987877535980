import React, { useState, useEffect, SyntheticEvent, ReactNode } from 'react';
import { Grid, Typography, Checkbox } from '@mui/material';
import Autocomplete, { AutocompleteRenderInputParams } from '@mui/material/Autocomplete';
import { cloneDeep, identity, isArray, orderBy, throttle } from 'lodash';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { IconChevronDropdown } from '../../images/CustomIcon';
import TextField from '../TextField';
import Services from '../../utils/Services';
import MContext from '../../models/MContext';
import { JiraIssueOption } from '../../models/model/JiraIssueOption';
import DecoratorConstants from '../../utils/DecoratorConstants';
import { t } from '../../i18n/t';
import MFlags from '../../models/MFlags';

interface DropdownFieldProps {
  id: string;
  displayField: string;
  iconField?: string;
  changeValueContributor?: (id: string, value: JiraIssueOption | JiraIssueOption[] | null) => void;
  placeholder?: string;
  defaultValue?: JiraIssueOption | JiraIssueOption[] | null;
  allowedValues?: JiraIssueOption[];
  autoCompleteUrl?: string;
  isMultiple?: boolean;
  renderInput?: (params: AutocompleteRenderInputParams, selectedOption: JiraIssueOption | null, placeholder?: string, required?: boolean) => ReactNode;
  renderOption?: (props: React.HTMLAttributes<HTMLLIElement>, option: JiraIssueOption, object?: { selected: boolean }) => ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  renderTags?: (value: object[], getTagProps: any) => ReactNode;
  getOptionLabel?: (option: JiraIssueOption) => ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formatData?: (content: any) => object[];
  noOptionsComponent?: ReactNode;
  defaultQuery?: string;
  required?: boolean;
  customClassName?: string;
}

function DropdownField(props: DropdownFieldProps) {
  const {
    id,
    displayField,
    iconField,
    changeValueContributor,
    placeholder,
    allowedValues,
    autoCompleteUrl,
    defaultValue,
    isMultiple,
    renderInput,
    renderOption,
    getOptionLabel,
    formatData,
    noOptionsComponent,
    defaultQuery,
    required,
    renderTags,
    customClassName
  } = props;

  const [options, setOptions] = useState<Array<object>>([]);

  const [searchValue, setSearchValue] = useState('');

  const [isLoading, setIsLoading] = useState(true);

  const [isFetchFirstTime, setIsFetchFirstTime] = useState(false);

  const [selectedOption, setSelectedOption] = useState<JiraIssueOption | JiraIssueOption[] | null>(null);

  const getData = (value: string) => {
    setIsLoading(true);
    let finalUrl;
    if (!defaultQuery || value.trim() !== '') {
      const encodedValue = window.encodeURIComponent(value);
      finalUrl = `${autoCompleteUrl}${encodedValue}`;
    } else {
      const encodedDefaultQuery = window.encodeURIComponent(defaultQuery);
      finalUrl = `${autoCompleteUrl}${encodedDefaultQuery}`;
    }
    Services.getAutoCompleteResponse(MContext.projectId, finalUrl)
      .then((content) => {
        let listData;
        if (formatData) {
          listData = formatData(content);
        } else if (isArray(content)) {
          listData = content;
        } else {
          listData = content?.suggestions;
        }
        setOptions(listData);
        setIsLoading(false);
      });
  };

  const throttledGetData = throttle(getData, 1000);

  const inputAutocomplete = (event: { preventDefault: () => void; }, newValue: string) => {
    if (event !== null) {
      event.preventDefault();
    }
    setSearchValue(newValue);
    throttledGetData(newValue);
  };

  const inputAllowedValues = (_event: { preventDefault: () => void; }, newValue: string) => {
    setSearchValue(newValue);
    if (allowedValues && allowedValues.length !== 0) {
      let listOptions = cloneDeep(allowedValues);
      if (!MFlags.enhanceDropdownFieldBehaviorEnabled || isMultiple) {
        listOptions = listOptions.filter((option) => option[displayField].toLowerCase().includes(newValue.toLowerCase()));
      }

      listOptions = orderBy(listOptions, [displayField], ['asc']);
      setOptions(listOptions);
    }
  };

  const handleChange = (_event: SyntheticEvent<Element, Event>, value: JiraIssueOption | null) => {
    if (MFlags.enhanceDropdownFieldBehaviorEnabled && value === null) {
      return;
    }

    setSelectedOption(value);
    if (changeValueContributor) {
      if (displayField === 'label') {
        if (value) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const labels = value.map((item: any) => item.label || item);
          changeValueContributor(id, labels);
        }
      } else {
        changeValueContributor(id, value);
      }
    }
  };

  const handleFetchFirstTime = () => {
    if (autoCompleteUrl && !isFetchFirstTime) {
      if (!MFlags.enhanceDropdownFieldBehaviorEnabled || isMultiple) {
        setIsFetchFirstTime(true); // for multiple select, do not fetch list again when select an option
      }
      getData('');
    }
  };

  useEffect(() => {
    if (allowedValues) {
      setOptions(allowedValues);
    }
    if (defaultValue) {
      setSelectedOption(defaultValue);
      if (changeValueContributor) {
        changeValueContributor(id, defaultValue);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allowedValues, defaultValue]);

  const commonProps = {
    loading: autoCompleteUrl ? isLoading : false,
    disableClearable: !MFlags.enhanceDropdownFieldBehaviorEnabled,
    clearOnEscape: true,
    className: 'flex-grow-1',
    options,
    onChange: handleChange,
    filterOptions: MFlags.enhanceDropdownFieldBehaviorEnabled ? undefined : identity,
    inputValue: searchValue,
    onInputChange: autoCompleteUrl ? inputAutocomplete : inputAllowedValues,
    popupIcon: <IconChevronDropdown />,
  };


  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const multiSelectProps : { renderTags?: any } = {};
  if (renderTags) {
    multiSelectProps.renderTags = renderTags;
  }

  const icon = <CheckBoxOutlineBlankIcon className="small-icon" />;
  const checkedIcon = <CheckBoxIcon className="small-icon" />;

  const renderIcon = (option: object, iconField: string) => DecoratorConstants.renderInputFieldIcon(option, iconField);

  const renderMultipleAutocomplete = () => (
    <Autocomplete
      {...multiSelectProps}
      {...commonProps}
      disableCloseOnSelect
      multiple
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      defaultValue={defaultValue as any[]}
      getOptionLabel={(option) => option[displayField] ?? option}
      renderInput={(params) => <TextField
        required={required}
        placeholder={(selectedOption === null || selectedOption.length === 0) ? placeholder : null}
        {...params}
        InputProps={{
          ...params.InputProps,
          required: required && (selectedOption === null || selectedOption.length === 0),
        }}
      />}
      renderOption={(props, option, { selected }) => (renderOption ? renderOption(props, option, { selected }) : (
        <li {...props}>
          <Grid container>
            <Grid item xs={12}>
              <div className="d-flex align-items-center">
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {iconField && renderIcon(option, iconField)}
                {option[displayField]}
              </div>
            </Grid>
          </Grid>
        </li>
      ))}
      isOptionEqualToValue={(option, value) => option[displayField] === value[displayField]}
    />
  );

  const noOptionText = <>{t('no-option')}</>;

  const renderNoOption = () => {
    options.push(
      {
        id: 'no-option-text',
        component: noOptionText,
      },
      {
        id: 'no-option',
        component: noOptionsComponent,
      }
    );
  };

  if (noOptionsComponent && !isLoading && options.length === 0) {
    renderNoOption();
  }

  const renderSingleAutocomplete = () => (
    <Autocomplete
      {...commonProps}
      blurOnSelect={MFlags.enhanceDropdownFieldBehaviorEnabled}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      defaultValue={defaultValue as any}
      getOptionLabel={(option) => (getOptionLabel ? getOptionLabel(option) : option[displayField] || '')}
      getOptionDisabled={(option) => option.id === 'no-option-text'}
      renderInput={(params) => (renderInput ? renderInput(params, selectedOption, placeholder, required) : (
        <TextField
          required={required}
          {...params}
          placeholder={placeholder}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <>
                {selectedOption && iconField && renderIcon(selectedOption, iconField)}
                {params.InputProps.startAdornment}
              </>
            )
          }}
        />
      ))}
      renderOption={(props, option) => (renderOption ? renderOption(props, option) : (
        <li {...props}>
          <Grid container>
            <Grid item xs={12}>
              <div className="d-flex align-items-center">
                {iconField && renderIcon(option, iconField)}
                <Typography variant="button" color="textPrimary">
                  {option[displayField]}
                </Typography>
              </div>
            </Grid>
          </Grid>
        </li>
      ))}
    />
  );

  return (
    <div onClick={handleFetchFirstTime} id={`id-${id}`} className={customClassName || 'w-75 dropdown-field'}>
      {isMultiple ? renderMultipleAutocomplete() : renderSingleAutocomplete()}
    </div>
  );
}

export default DropdownField;
