import React, { Component } from 'react';
import moment from 'moment';
import { Col, Row } from 'reactstrap';
import Services from '../../utils/Services';
import Moment from '../../utils/Moment';
import DecoratorConstants from '../../utils/DecoratorConstants';
import Routes from '../../utils/Routes';
import ReleaseDayDeliveryStatusBar from './components/ReleaseDayDeliveryStatusBar';
import TotalDefect from './components/TotalDefect';
import LatestBuildComponent from '../../components/latestbuild/LatestBuildComponent';
import DevelopmentProgressBar from './components/DevelopmentProgressBar';
import { t } from '../../i18n/t';
import TestReleaseComponent from '../../components/test-release/TestReleaseComponent';
import PropTypes from 'prop-types';
import Link from '../../components/Link';

class ReleaseSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      latestBuilds: []
    };
  }

  componentDidMount() {
    if (this.props.release) {
      this.getLatestBuilds();
    }
  }

  getLatestBuilds() {
    const { release } = this.props;
    const params = {
      pagination: {
        page: 0,
        size: 5,
        sorts: 'createdAt,desc',
      },
      conditions: [
        {
          key: 'releaseId',
          operator: '=',
          value: release.id,
        },
      ],
      type: 'Build',
    };
    Services.search(params)
      .then(({ content }) => {
        this.setState({
          latestBuilds: content
        });
      });
  }

  renderEmptyMessage() {
    const { release } = this.props;

    const route = new Routes({
      releaseId: release.id
    });

    return (
      <div className="text-center mt-4 blue-dark">
        <div>
          {t('table#empty-message')}
        </div>
        <Link href={route.create_build_link()} title={t('builds#create')}>{t('builds#create')}</Link>
      </div>
    );
  }

  renderLatestBuild() {
    const { latestBuilds } = this.state;
    const latestBuildBar = latestBuilds.length > 0 ? <LatestBuildComponent latestBuilds={latestBuilds} /> : this.renderEmptyMessage();
    return (
      <>
        <div className="title">
          {t('builds')}
        </div>
        <div>
          {latestBuildBar}
        </div>
      </>
    );
  }

  renderDaysToDeliveryProgressBar() {
    const { release } = this.props;
    return <ReleaseDayDeliveryStatusBar release={release} />;
  }

  renderDefect() {
    const { release, jiraIntegrated } = this.props;
    return <TotalDefect release={release} jiraIntegrated={jiraIntegrated} />;
  }

  renderDevelopment() {
    const { release } = this.props;
    return <DevelopmentProgressBar release={release} />;
  }

  renderTestReleaseComponent() {
    const { release } = this.props;
    return <TestReleaseComponent release={release} />;
  }

  render() {
    const { release } = this.props;
    const { name, endTime } = release;
    const releaseAt = !endTime ? null : moment(endTime).format(Moment.LAST_YEARS_FORMAT);
    const routes = new Routes({ releaseId: release.id });
    return (
      <Row className="release-summary">
        <Col sm="12" md="12" lg="4" xl="4" className="item">
          <div>
            {DecoratorConstants.releaseDecorator('name', release, routes.view_release_link, name, 'had-ellipsis release-title')}
            {this.renderDaysToDeliveryProgressBar()}
            {this.renderDevelopment()}
            {this.renderTestReleaseComponent()}
          </div>
        </Col>
        <Col sm="12" md="12" lg="4" xl="4" className="item">
          <p className="title">
            {t('release')} : {releaseAt}
          </p>
          {this.renderDefect()}
        </Col>
        <Col sm="12" md="12" lg="4" xl="4" className="item">
          {this.renderLatestBuild()}
        </Col>
      </Row>
    );
  }

}

ReleaseSummary.propTypes = {
  release: PropTypes.array.isRequired,
};

export default ReleaseSummary;
