import Icon from '@katalon-studio/katalon-ui/Icon';
import React from 'react';
import colors from '../../../scss/colors.scss';

function LayerPlusIcon(props) {
  const { color, textSize, type } = props;
  return <Icon
    type={`fa-${type}`}
    name="fa-layer-plus"
    textSize={textSize}
    sx={{
      color,
      width: textSize,
      height: textSize,
      display: 'flex',
      justifyContent: 'center',
      paddingBottom: '2px'
    }}
    boundingSize={null}
    color={null}
  />;
}

LayerPlusIcon.defaultProps = {
  color: colors.gray9,
  textSize: '14px',
  type: 'regular',
};

export default LayerPlusIcon;
