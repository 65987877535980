import React, { Component } from 'react';
import { Label, ButtonToolbar, Button, Badge, Form, Row, Col, FormGroup, Alert, CustomInput } from 'reactstrap';
import { Step, StepContent, StepLabel, Tab, Tabs } from '@mui/material';
import { Container, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, IconButton } from '@mui/material';
import MStepper from '@mui/material/Stepper';
import { t } from '../../../i18n/t';
import MContext from '../../../models/MContext';
import Services from '../../../utils/Services';
import Notification from '../../../utils/Notification';
import MAuth from '../../../models/MAuth';
import Routes from '../../../utils/Routes';
import DocumentLink from '../../../utils/DocumentLink';
import JiraSettingForm from './JiraSettingForm';
import Select from '../../../components/Select';
import { SearchEntity } from '../../../utils/Constants';
import JiraWebhookSetting from './JiraWebhookSetting';
import MFlags from '../../../models/MFlags';
import ChangingJiraWorkspaceWarningDialog from '../../../components/dialog/ChangingJiraWorkspaceWarningDialog';
import { next } from '../../../utils/Count';
import TabPanel from '../../../components/tab/TabPanel';
import RefreshJiraButton from '../../../pages/test_reports/components/RefreshJiraButton';
import ExternalIssueNotificationsSetting from './ExternalIssueNotificationsSetting';
import XrayIssueTypeMapping from './XrayIssueTypeMapping';
import Arrays from '../../../utils/Arrays';
import Input from '../../../components/Input';

const JiraEntityMapping = () => {
  return (
    <Row>
      <Col sm="12" md="8" lg="8" xl="8">
        <div className="note-active-connection">This mapping cannot be configured at this time.</div>
        <Form className={t('jira-setting#form-class')}>
          <FormGroup>
            <Label>TestOps Release</Label>
            <Input
              type="text"
              value='Release'
              disabled
            />
          </FormGroup>
          <FormGroup>
            <Label>TestOps Sprint (At step 3, please make sure to select the appropriate board)</Label>
            <Input
              type="text"
              value='Sprint'
              disabled
            />
          </FormGroup>
          <FormGroup>
            <Label>TestOps Requirement</Label>
            <Input
              type="text"
              value='Story, Task'
              disabled
            />
          </FormGroup>
        </Form>
      </Col>
    </Row>
  );
};

class JiraSetting extends Component {
  constructor(props) {
    super(props);
    this.projectId = MContext.projectId;
    this.project = MContext.project;
    this.teamId = MContext.teamId;
    this.organization = MContext.project.team.organization;
    this.webhookKey = 0;

    this.changeText = this.changeText.bind(this);
    this.submitSettings = this.submitSettings.bind(this);
    this.handleEnableXrayChange = this.handleEnableXrayChange.bind(this);
    this.testConnectionErrorHandler = this.testConnectionErrorHandler.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.testConnection = this.testConnection.bind(this);
    this.onChangeActiveState = this.onChangeActiveState.bind(this);
    this.onSelectProject = this.onSelectProject.bind(this);
    this.onSelectBoard = this.onSelectBoard.bind(this);
    this.onUpdateExternalSystemMapping = this.onUpdateExternalSystemMapping.bind(this);
    this.state = {
      settingsForm: {
        id: null,
        serverUrl: '',
        username: '',
        password: '',
        active: 'true',
        clientId: '',
        clientSecret: '',
        enabledXray: false,
        boardId: null,
        boardName: null,
        defaultExternalProject: null,
        externalSystemMapping: null,
        projectId: this.projectId,
      },
      currentSetting: {},
      onlyChangedState: false,
      activeStep: 0,
      integrationProjects: [],
      isOpenChangingJiraWorkspaceWarningDialog: false,
      isLoadingExternalProjects: false,
      tabIndex: 0,
      connection: null,
      isTestedConnection: false,
      integrationBoards: [],
      isLoadingBoards: false,
      isADOConnection: false,
    };
    this.handleChangeTab = this.handleChangeTab.bind(this);
    this.setTabState = this.setTabState.bind(this);
    this.getIntegrationProjectsV2 = this.getIntegrationProjectsV2.bind(this);
    this.getIntegrationBoardsV2 = this.getIntegrationBoardsV2.bind(this);
    this.getConnectionAndSetState = this.getConnectionAndSetState.bind(this);
  }

  handleBack() {
    this.setState(({ activeStep }) => ({
      activeStep: activeStep - 1,
    }));
  }

  handleNext() {
    this.setState(({ activeStep }) => ({
      activeStep: activeStep + 1,
    }));
  }

  setTabState(newValue) {
    this.setState({
      tabIndex: newValue,
    });
  }

  handleChangeTab(event, newValue) {
    this.setTabState(newValue);
  }

  getConnectionAndSetState(projectId) {
    const params = {
      pagination: {
        page: 0,
        size: 1,
        sorts: '[order,desc]',
      },
      conditions: [
        {
          key: 'Project.id',
          operator: '=',
          value: projectId,
        },
      ],
      type: SearchEntity.ExternalConnection,
    };
    Services.search(params)
      .then(({ content }) => {
        const connection = content[0];
        if (connection) {
          this.setState({
            connection,
          });
        }
      });
  }

  getConnection() {
    const params = {
      pagination: {
        page: 0,
        size: 1,
        sorts: '[order,desc]',
      },
      conditions: [
        {
          key: 'Project.id',
          operator: '=',
          value: this.projectId,
        },
      ],
      type: SearchEntity.ExternalConnection,
    };
    Services.search(params)
      .then(({ content }) => {
        const connection = content[0];
        if (connection) {
          if (connection.type === 'ADO'){
            this.setState({
              onlyChangedState: false,
              activeStep: -1,
              isADOConnection: true,
            })
          }
          else{
            const settingsForm = {
              id: connection.id,
              projectId: connection.projectId,
              serverUrl: connection.serverUrl,
              username: connection.username,
              password: connection.password,
              active: connection.active,
              boardId: connection.boardId,
              boardName: connection.boardName,
              clientId: connection.clientId,
              clientSecret: connection.clientSecret,
              enabledXray: connection.enabledXray,
              defaultExternalProject: {
                ...connection.defaultExternalProject,
                value: connection.defaultExternalProject?.externalProjectId,
                label: connection.defaultExternalProject?.name,
              },
              externalSystemMapping: connection.externalSystemMapping,
            };
            this.setState({
              currentSetting: {
                ...settingsForm
              },
              settingsForm,
              onlyChangedState: !!connection.id,
              connection,
            }, () => {
              connection.projectId && this.getIntegrationProjectsV2();
              connection.projectId && this.getIntegrationBoardsV2();
            });

            if (!connection.active) {
              this.setState({
                activeStep: -1
              });
            }
          }
        }
      });
  }

  componentDidMount() {
    this.getConnection();
  }

  getIntegrationProjects() {
    const { settingsForm } = this.state;
    const { defaultExternalProject } = settingsForm;
    const setting = { projectId: this.projectId, ...settingsForm };
    this.setState({ isLoadingExternalProjects: true });
    return Services.getJiraProjects(setting)
      .then((integrationProjects) => {

        // Reset selected external project
        const existingExternalProject = integrationProjects.find((item) => item.externalProjectId === defaultExternalProject?.externalProjectId);
        this.setState((prevState) => ({
          settingsForm: { ...prevState.settingsForm, defaultExternalProject: existingExternalProject || null }
        }));

        this.setState({
          integrationProjects
        });
      })
      .catch(() => {})
      .finally(() => {
        this.setState({ isLoadingExternalProjects: false });
      });
  }

  getIntegrationProjectsV2() {
    const { settingsForm } = this.state;
    const setting = { projectId: this.projectId, ...settingsForm };
    this.setState({ isLoadingExternalProjects: true });
    return Services.getJiraProjectsWithoutBlock(setting)
      .then((integrationProjects) => {
        const { defaultExternalProject } = this.state.settingsForm;
        // Reset selected external project
        const existingExternalProject = integrationProjects.find((item) => item.externalProjectId === defaultExternalProject?.externalProjectId);
        this.setState((prevState) => ({
          settingsForm: { ...prevState.settingsForm, defaultExternalProject: existingExternalProject || null },
          integrationProjects
        }));
      })
      .catch(() => {})
      .finally(() => {
        this.setState({ isLoadingExternalProjects: false });
      });
  }

  getIntegrationBoardsV2() {
    const { settingsForm } = this.state;
    const setting = { projectId: this.projectId, ...settingsForm };
    this.setState({ isLoadingBoards: true });
    return Services.getJiraBoardsWithoutBlock(setting)
      .then((integrationBoards) => {
        const { boardId } = this.state.settingsForm;
        // Reset selected board
        const existingBoard = integrationBoards.find((item) => item.id === boardId);
        this.setState((prevState) => ({
          settingsForm: { ...prevState.settingsForm, boardId: existingBoard?.id, boardName: existingBoard?.name },
          integrationBoards
        }));
      })
      .catch(() => {})
      .finally(() => {
        this.setState({ isLoadingBoards: false });
      });
  }

  onSelectProject(event) {
    const selectedExternalProject = event ? event.item : {};
    this.setState((prevState) => {
      if (prevState.settingsForm.defaultExternalProject?.externalProjectId !== selectedExternalProject.externalProjectId) {
        return {
          settingsForm: { ...prevState.settingsForm, defaultExternalProject: selectedExternalProject, boardId: null, boardName: null }
        };
      } else {
        return {
          settingsForm: { ...prevState.settingsForm, defaultExternalProject: selectedExternalProject }
        };
      }
    });
  }

  onSelectBoard(event) {
    const board = event ? event.item : {};
    this.setState((prevState) => ({
      settingsForm: { ...prevState.settingsForm, boardId: board.id, boardName: board.name }
    }), () => this.checkAllowSave());
  }

  onUpdateExternalSystemMapping(updatedSettings) {
    this.setState((prevState) => ({
      settingsForm: { ...prevState.settingsForm, externalSystemMapping: updatedSettings }
    }));
  }

  handleOpenChangingJiraWorkspaceWarningDialog = () => {
    this.setState({ isOpenChangingJiraWorkspaceWarningDialog: true });
  };

  handleCloseChangingJiraWorkspaceWarningDialog = () => {
    this.setState({
      isOpenChangingJiraWorkspaceWarningDialog: false,
    });
  };

  handleChangingWorkspace = (e) => {
    this.submitSettings(e);
    this.handleCloseChangingJiraWorkspaceWarningDialog();
  };

  renderChangingWorkSpaceDialog = () => {
    const {
      isOpenChangingJiraWorkspaceWarningDialog
    } = this.state;
    return (
      <ChangingJiraWorkspaceWarningDialog
        isOpen={isOpenChangingJiraWorkspaceWarningDialog}
        handleClose={this.handleCloseChangingJiraWorkspaceWarningDialog}
        handleChangeWorkspace={this.handleChangingWorkspace}
      />
    );
  };

  clickSaveBtn = (e) => {
    e.preventDefault();
    const {
      settingsForm,
      currentSetting
    } = this.state;
    if (settingsForm.serverUrl && currentSetting.serverUrl) {
      const currentDomain = new URL(currentSetting.serverUrl).hostname;
      const settingsFormDomain = new URL(settingsForm.serverUrl).hostname;
      if (currentDomain !== settingsFormDomain) {
        this.handleOpenChangingJiraWorkspaceWarningDialog();
      } else {
        this.submitSettings(e);
      }
    } else {
      this.submitSettings(e);
    }
  };

  renderProjectKeyForm() {
    const {
      settingsForm,
      integrationProjects,
      isLoadingExternalProjects
    } = this.state;

    const { defaultExternalProject } = settingsForm;

    const integrationProjectOpts = integrationProjects.map((item) => ({
      value: item.externalProjectId,
      item,
      label: item.name,
    }));

    return (
      <Row>
        <Col sm="12" md="8" lg="6" xl="5">
          <Form className={t('jira-setting#form-class')}>
            {!isLoadingExternalProjects &&
            <FormGroup>
              <Select
                id="select-projectId"
                clearable
                options={integrationProjectOpts}
                onChange={this.onSelectProject}
                value={defaultExternalProject?.externalProjectId}
                placeholder="Select Project Key"
                required
              />
            </FormGroup>}
            <ButtonToolbar>
              <Button
                disabled={!defaultExternalProject || !defaultExternalProject.externalProjectId}
                color="primary"
                onClick={this.clickSaveBtn}
                data-trackid="update-jira-settings"
              >
                {t('jira-setting#save')}
              </Button>
              <Button
                onClick={this.handleBack}
              >
                {t('stepper#back')}
              </Button>
            </ButtonToolbar>
          </Form>
        </Col>
      </Row>
    );
  }

  renderProjectKeyFormV2() {
    const {
      settingsForm,
      integrationProjects,
      isLoadingExternalProjects,
      connection,
      isTestedConnection
    } = this.state;

    const { defaultExternalProject } = settingsForm;
    const integrationProjectOpts = integrationProjects.map((item) => ({
      value: item.externalProjectId,
      item,
      label: item.name,
    }));

    const currentProject = connection?.defaultExternalProject;

    return (
      <Row>
        <Col sm="12" md="8" lg="8" xl="8">
          <Form className={t('jira-setting#form-class')}>
            <FormGroup>
              <Select
                id="select-projectId"
                isDisabled={!(isTestedConnection || connection)}
                onOpen={this.getIntegrationProjectsV2}
                isLoading={isLoadingExternalProjects}
                clearable
                options={integrationProjectOpts}
                onChange={this.onSelectProject}
                value={defaultExternalProject?.externalProjectId}
                placeholder="Select Project Key"
                required
              />
            </FormGroup>
            { defaultExternalProject &&
            <Row className="mb-3  ">
              <Col>
                <Row>
                  <Col xs="4">
                    <div className="note-active-connection">{t('current-project')}</div>
                    {defaultExternalProject?.name}
                  </Col>
                  <Col className="d-flex justify-content-end align-item-center">
                    {
                      currentProject?.externalProjectId === defaultExternalProject?.externalProjectId &&
                        <div>
                          <RefreshJiraButton
                            getConnectionAndSetState={this.getConnectionAndSetState}
                            connection={connection}
                            isPageButtonToolBar={false}
                          />
                        </div>
                    }
                  </Col>
                </Row>
              </Col>
            </Row>}
          </Form>
        </Col>
      </Row>
    );
  }

  checkAllowSave() {
    const { settingsForm, currentSetting } = this.state;
    let onlyChangedState = false;
    const keys = Object.keys(currentSetting);
    for (const key of keys) {
      if (key !== 'active' && key !== 'defaultExternalProject' && key !== 'boardId') {
        if (settingsForm[key] !== currentSetting[key]) {
          onlyChangedState = false;
          break;
        }
      } else if (settingsForm.active !== currentSetting.active || settingsForm.defaultExternalProject !== currentSetting.defaultExternalProject || settingsForm.boardId !== currentSetting.boardId) {
        onlyChangedState = true;
      }
    }
    this.setState({
      onlyChangedState
    });
  }

  changeText(event) {
    const { settingsForm } = this.state;
    const key = event.target.name;
    const value = event.target.value;
    settingsForm[key] = value;
    this.setState(
      { settingsForm },
      () => this.checkAllowSave()
    );
  }

  handleDisableConnection() {
    const { settingsForm } = this.state;
    const setting = { projectId: this.projectId, onlyChangedState: true, id: settingsForm.id };
    Services.saveJiraSettings(setting)
      .then(() => {
        Notification.pushSuccess(t('jira-integration#deactivate-success'));
        this.getConnection();
      });
  }

  onChangeActiveState(event) {
    const { settingsForm } = this.state;
    const key = event.target.name;
    const value = event.target.checked;
    settingsForm[key] = value;
    this.setState(
      { settingsForm, activeStep: value ? 0 : -1 },
      () => {
        this.checkAllowSave();
        if (!value && settingsForm.id) {
          this.handleDisableConnection();
        }
      }
    );
  }

  handleEnableXrayChange(event) {
    const { settingsForm } = this.state;
    const key = event.target.name;
    const value = event.target.checked;
    settingsForm[key] = value;
    this.setState(
      { settingsForm },
      () => this.checkAllowSave()
    );
  }

  submitSettings(e) {
    e.preventDefault();
    const settingForms = document.getElementsByClassName(t('jira-setting#form-class'));
    for (let i = 0; i < settingForms.length; i++) {
      const result = settingForms[i].reportValidity();
      if (!result) {
        return;
      }
    }
    const { settingsForm, onlyChangedState } = this.state;
    const setting = { projectId: this.projectId, onlyChangedState, ...settingsForm };
    Services.saveJiraSettings(setting, (errorMessage) => {
      Notification.pushError(errorMessage, 'There was an error saving your setting. Please try again.');
    })
      .then(() => {
        Services.refreshJiraIssueOfProject(this.projectId);
        Notification.pushSuccess(t('jira-integration#setting-success'));
        this.getConnection();
        this.webhookKey = next();
      });
  }

  testConnectionErrorHandler(message) {
    Notification.pushError(message, t('jira-integration#connection-failed-title'));
  }

  testConnection(e) {
    e.preventDefault();
    const { id, password, serverUrl, username } = this.state.settingsForm;

    if (!id && !password) {
      Notification.pushError(t('jira-integration#connection-failed'), t('jira-integration#connection-failed-title'));
      return;
    }
    if (!serverUrl && !username) {
      Notification.pushError(t('jira-integration#connection-failed'), t('jira-integration#connection-failed-title'));
      return;
    }

    const setting = { projectId: this.projectId, ...this.state.settingsForm };
    Services.testJiraConnection(setting, !MFlags.applyErrorHandlingUsingKatalonApiStandardEnabled && this.testConnectionErrorHandler)
      .then(() => {
        Notification.pushSuccess(t('jira-integration#test-connection-success#one-more-step'), t('jira-integration#connection-passed-title'));
        this.handleNext();
        this.setState({
          isTestedConnection: true,
        });
        if (!MFlags.raJiraIntegrationEnhancementPhase2Enabled) {
          this.getIntegrationProjects();
        }
      })
      .catch(() => {});
  }

  renderJiraSettingForm() {

    const { onlyChangedState, settingsForm, connection } = this.state;
    return (
      <JiraSettingForm
        settingsForm={settingsForm}
        onlyChangedState={onlyChangedState}
        onChangeText={this.changeText}
        testConnection={this.testConnection}
        onChangeActiveState={this.onChangeActiveState}
        handleEnableXrayChange={this.handleEnableXrayChange}
      />
    );
  }

  renderJiraSettingFormV2() {

    const { onlyChangedState, settingsForm } = this.state;

    return (
      <JiraSettingForm
        lg="8"
        xl="8"
        settingsForm={settingsForm}
        onlyChangedState={onlyChangedState}
        onChangeText={this.changeText}
        testConnection={this.testConnection}
        onChangeActiveState={this.onChangeActiveState}
        handleEnableXrayChange={this.handleEnableXrayChange}
      />
    );
  }

  renderChoosingBoardV2() {
    const {
      settingsForm,
      integrationBoards,
      isLoadingBoards,
      connection,
      isTestedConnection
    } = this.state;

    const { boardId, boardName, defaultExternalProject } = settingsForm;
    const integrationBoardOpts = integrationBoards.map((item) => ({
      value: item.id,
      item,
      label: item.name,
    }));

//     if (integrationBoardOpts.length === 0 && boardId) {
//       integrationBoardOpts.push({
//         value: boardId,
//         item: { id: boardId, name: boardName },
//         label: boardName,
//       });
//     }

    return (
      <Row>
        <Col sm="12" md="8" lg="8" xl="8">
          <Form className={t('jira-setting#form-class')}>
            <FormGroup>
              <Select
                id="select-boardId"
                isDisabled={!((isTestedConnection || connection) && defaultExternalProject)}
                onOpen={this.getIntegrationBoardsV2}
                isLoading={isLoadingBoards}
                isClearable
                options={integrationBoardOpts}
                onChange={this.onSelectBoard}
                value={boardId}
                placeholder="Select Sprint Board"
              />
            </FormGroup>
            { boardName &&
            <Row className="mb-3  ">
              <Col>
                <Row>
                  <Col xs="4">
                    <div className="note-active-connection">{t('current-board')}</div>
                    {boardName}
                  </Col>
                </Row>
              </Col>
            </Row>}
          </Form>
        </Col>
      </Row>
    );
  }

  renderXrayIssueTypeMapping() {
    const { settingsForm } = this.state;
    const setting = { projectId: this.projectId, ...settingsForm };
    return (
      <XrayIssueTypeMapping
        setting={setting}
        setSetting={this.onUpdateExternalSystemMapping}
      />
    );
  }

  renderData() {
    const organization = this.organization;
    const { settingsForm } = this.state;
    const { active } = settingsForm;
    const jiraMarketPlaceLink = DocumentLink.JIRA_MARKET_PLACE;
    return (
      <>
        <Alert color="primary">
          Please install{' '}
          <a href={jiraMarketPlaceLink} target="_blank" rel="noopener noreferrer">Jira add-on</a>
          {' '} to enable this feature for{' '}
          <b>Organization {organization.name} (ID: {organization.id}).</b>
        </Alert>
        {this.renderSettingsStepper()}
        {this.renderChangingWorkSpaceDialog()}
        {active && <JiraWebhookSetting key={this.webhookKey} />}
      </>
    );
  }

  renderDataV2() {
    const organization = this.organization;
    const { settingsForm, tabIndex, connection, isADOConnection } = this.state;
    const { active } = settingsForm;
    const jiraMarketPlaceLink = DocumentLink.JIRA_MARKET_PLACE;
    const renderAlert = () => {
      if (isADOConnection) {
        return (
          <Alert color="primary">
            You've successfully connected to Azure DevOps (ADO). To set up a connection with Jira, please disable the ADO integration first.
          </Alert>
        );
      } else {
        return (
          <Alert color="primary">
            Please install{' '}
            <a href={jiraMarketPlaceLink} target="_blank" rel="noopener noreferrer">Jira add-on</a>
            {' '} to enable this feature for{' '}
            <b>Organization {organization.name} (ID: {organization.id}).</b>
          </Alert>
        );
      }
    }
    return (
      <>
        {renderAlert()}
        <FormGroup>
          <CustomInput
            onChange={this.onChangeActiveState}
            name="active"
            type="switch"
            id="active"
            checked={isADOConnection ? false : active}
            label={t('jira-setting#active-button')}
            value
            disabled={isADOConnection}
          />
        </FormGroup>
        <Tabs
          className="jira-xray-tabs"
          value={tabIndex}
          onChange={this.handleChangeTab}
          textColor="secondary"
          indicatorColor="secondary"
          disabled={true}
        >
          <Tab label={t('config')} />
          <Tab label={t('jira-webhook#title')} />
          {MFlags.jiraSlackIntegrationEnabled && <Tab label={t('notifications')} />}
        </Tabs>
        <TabPanel value={tabIndex} index={0}>
          {this.renderSettingsStepperV2()}
          {this.renderChangingWorkSpaceDialog()}
        </TabPanel>
        <TabPanel value={tabIndex} index={1}>
          {active && <JiraWebhookSetting setTabState={() => this.setTabState(0)} key={this.webhookKey} />}
        </TabPanel>
        {
        MFlags.jiraSlackIntegrationEnabled &&
          <TabPanel value={tabIndex} index={2}>
            {active && <ExternalIssueNotificationsSetting setTabState={() => this.setTabState(0)} connection={connection} getConnectionAndSetState={this.getConnectionAndSetState} />}
          </TabPanel>
        }
      </>
    );
  }

  generateIndexIcon(index) {
    const { activeStep } = this.state;
    const bgColor = activeStep < index ? 'bg-grey' : 'bg-primary';
    return (
      <Badge className={`${bgColor} badge-circle-index`}>{index + 1}</Badge>
    );
  }

  generateIndexIconV2(index, isExpanded) {
    const bgColor = !isExpanded ? 'bg-grey' : 'bg-primary';
    return (
      <Badge key={bgColor} className={`${bgColor} badge-circle-index`}>{index + 1}</Badge>
    );
  }

  renderSettingsStepper() {
    const { activeStep, settingsForm } = this.state;
    const { active, enabledXray, defaultExternalProject } = settingsForm;
    const hasChosenProject = !!defaultExternalProject?.externalProjectId;

    const shouldDisplayXrayIssueTypeMapping = active && enabledXray && hasChosenProject && MFlags.mapCustomizedXrayIssueTypeEnabled;
    const steps = [
      {
        label: t('jira-integration#step1'),
        content: this.renderJiraSettingForm()
      },
      {
        label: t('jira-integration#step2'),
        content: this.renderProjectKeyForm()
      },
      ...Arrays.insertIf(shouldDisplayXrayIssueTypeMapping, {
        label: t('jira-integration#step3'),
        content: this.renderXrayIssueTypeMapping()
      })
    ];

    return (
      <>
        <FormGroup>
          <CustomInput
            onChange={this.onChangeActiveState}
            name="active"
            type="switch"
            id="active"
            checked={active}
            label={t('jira-setting#active-button')}
            value
          />
        </FormGroup>
        {active && <p className="note-active-connection">{t('jira-integration#active-connection-note')}</p>}
        <MStepper
          orientation="vertical"
          nonLinear={false}
          activeStep={activeStep}
        >
          {
            steps.map((step, index) => (
              <Step key={step.label}>
                <StepLabel
                  icon={this.generateIndexIcon(index)}
                >
                  <div className={index <= activeStep ? 'stepper__header' : 'stepper__inactive_header'}>
                    {step.label}
                  </div>
                </StepLabel>
                <StepContent>
                  {step.content}
                </StepContent>
              </Step>
            ))
          }
        </MStepper>
      </>
    );
  }

  renderSettingsStepperV2() {
    const { activeStep, settingsForm } = this.state;
    const { active, defaultExternalProject, enabledXray } = settingsForm;
    const hasChosenProject = !!defaultExternalProject?.externalProjectId;
    const shouldDisplayXrayIssueTypeMapping = active && enabledXray && hasChosenProject && MFlags.mapCustomizedXrayIssueTypeEnabled;
    const steps = [
      {
        label: t('jira-integration#step1'),
        content: this.renderJiraSettingFormV2()
      },
      {
        label: t('jira-integration#step2'),
        content: this.renderProjectKeyFormV2()
      },
      ...Arrays.insertIf(MFlags.planningG3GA, {
        label: t('jira-integration#chooseBoard'),
        content: this.renderChoosingBoardV2()
      }),
      ...Arrays.insertIf(MFlags.planningG3GA, {
        label: t('jira-integration#jiraEntityMapping'),
        content: <JiraEntityMapping />
      }),
      ...Arrays.insertIf(shouldDisplayXrayIssueTypeMapping, {
        label: t('jira-integration#step3'),
        content: this.renderXrayIssueTypeMapping()
      })
    ];

    const isExpanded = activeStep !== -1;

    return (
      <>
        {active && (
          MFlags.jiraSlackIntegrationEnabled ?
            <div className="jira-notification-info">
              {t('jira-integration#active-connection-note')}
            </div> :
            <p className="note-active-connection">{t('jira-integration#active-connection-note')}</p>)}
        <MStepper
          orientation="vertical"
          nonLinear={false}
          activeStep={activeStep}
        >
          {
            steps.map((step, index) => (
              <Step key={step.label} expanded={isExpanded}>
                <StepLabel
                  icon={this.generateIndexIconV2(index, isExpanded)}
                >
                  <div className={isExpanded ? 'stepper__header' : 'stepper__inactive_header'}>
                    {step.label}
                  </div>
                </StepLabel>
                <StepContent>
                  {step.content}
                </StepContent>
              </Step>
            ))
          }
        </MStepper>
        {
          settingsForm.active &&
          <ButtonToolbar>
            <Button
              disabled={!defaultExternalProject || !defaultExternalProject.externalProjectId}
              color="primary"
              onClick={this.clickSaveBtn}
              data-trackid="update-jira-settings"
            >
              {t('jira-setting#save')}
            </Button>
          </ButtonToolbar>
        }
      </>
    );
  }

  render() {
    const isTeamManager = MAuth.isTeamManager(this.teamId);
    if (!isTeamManager) {
      Routes.goToAccessDeniedPage();
    }
    return MFlags.raJiraIntegrationEnhancementPhase2Enabled ? this.renderDataV2() : this.renderData();
  }
}

export default JiraSetting;
