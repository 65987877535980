import React, { useEffect, useState } from 'react';
import { Prompt, useLocation } from 'react-router-dom';
import { isEmpty, filter } from 'lodash';
import { KATALON_EVENTS } from '../utils/Constants';

function KatalonPrompt() {
  const [prompts, setPrompts] = useState([]);
  const location = useLocation();

  const removePrompt = (key) =>
    setPrompts((prevPrompts) => {
      const updatedPrompts = filter(prevPrompts, (p) => p.key !== key);
      return updatedPrompts;
    });

  const clearPrompts = () => {
    setPrompts([]);
  };

  const addOrUpdatePrompt = (key, message) =>
    setPrompts((prevPrompts) => {
      const prompt = prevPrompts.find((p) => p.key === key);
      if (prompt) {
        prompt.message = message;
      } else {
        return [...prevPrompts, { key, message }];
      }

      return prevPrompts;
    });

  useEffect(() => {
    clearPrompts();
  }, [location]);

  useEffect(() => {
    document.addEventListener(KATALON_EVENTS.addPrompt, ({ detail }) => {
      const { key, message } = detail;
      if (key) {
        addOrUpdatePrompt(key, message);
      }
    });

    document.addEventListener(KATALON_EVENTS.clearPrompt, ({ detail }) => {
      const { key } = detail;
      if (key) {
        removePrompt(key);
      } else {
        clearPrompts();
      }
    });
  }, []);

  useEffect(() => {
    const unloadCallback = (event) => {
      event.preventDefault();
      event.returnValue = '';
      return '';
    };
    if (!isEmpty(prompts)) {
      window.addEventListener('beforeunload', unloadCallback);
      return () => window.removeEventListener('beforeunload', unloadCallback);
    } else {
      return () => window.removeEventListener('beforeunload', unloadCallback);
    }
  }, [prompts]);

  const firstPrompt = prompts[0];
  return (
    <Prompt
      when={!!firstPrompt}
      message={firstPrompt?.message || ''}
    />
  );
}

export default KatalonPrompt;
