import React, { Component } from 'react';
import { Col } from 'reactstrap';
import PropTypes from 'prop-types';
import { calHeight } from './Utils';
import ImageCanvas from './ImageCanvas';
import { t } from '../../../i18n/t';
import { IMAGE_TYPE, LOADING_STATE_KEY } from './Constants';
import DiffCanvasTemplate from './DiffCanvasTemplate';
import Skeleton from '../../../components/Skeleton';

class KEyesPixelBased extends Component {
  constructor(props) {
    super(props);
    this.renderBody = this.renderBody.bind(this);
    this.state = {
      height: 500,
      isLoading: {
        image: true,
        diff: true
      }
    };
    this.setHeight = this.setHeight.bind(this);
    this.setLoading = this.setLoading.bind(this);
    this.isReady = this.isReady.bind(this);
  }

  setLoading(key, value) {
    this.setState((prevState) => {
      const isLoading = { ...prevState.isLoading };
      isLoading[key] = value;
      return { isLoading };
    });
  }

  setHeight(h) {
    this.setState({ height: h });
  }

  componentDidMount() {
    const { source, canvasRef } = this.props;
    if (source && canvasRef.current) {
      calHeight(
        source,
        canvasRef.current && canvasRef.current.offsetWidth,
        this.setHeight
      );
    }
  }

  isReady() {
    const { diff, image } = this.state.isLoading;
    const { diffFile, canvasRef, source } = this.props;
    const isLoading = (diffFile && diff) || (source && image) || !canvasRef.current;
    if (isLoading === undefined) {
      return true;
    }
    return !isLoading;
  }

  renderSkeleton() {
    return <Skeleton
      height={this.state.height}
    />;
  }

  renderDiffCanvas() {
    const { diffFile, showDiff, canvasRef } = this.props;
    return diffFile && canvasRef.current && (
      <ImageCanvas
        key="diff-layer"
        className={`diff-layer ${showDiff && this.isReady() ? '' : 'd-none'}`}
        source={diffFile}
        width={canvasRef.current && canvasRef.current.offsetWidth}
        loadingState={LOADING_STATE_KEY.DIFF}
        setLoading={this.setLoading}
      />
    );
  }

  renderImageCanvas() {
    const { source, canvasRef, type } = this.props;
    return source ? (
      <div>
        <ImageCanvas
          key="image-layer"
          className={`${this.isReady() ? '' : 'd-none'}`}
          source={source}
          width={canvasRef.current && canvasRef.current.offsetWidth}
          loadingState={LOADING_STATE_KEY.IMAGE}
          setLoading={this.setLoading}
        />
        {!this.isReady() && this.renderSkeleton()}
      </div>
    ) : (
      <div className="empty-message">{type === IMAGE_TYPE.BASELINE ? t('no-baseline-image') : t('no-checkpoint-image')}</div>
    );
  }

  renderIgnoreCanvas() {
    const { source, type, canvasRef, showIgnoringZones, ignoringZones } = this.props;
    const ignoringZoneList = showIgnoringZones ? ignoringZones : [];
    return ignoringZoneList.length > 0 && canvasRef.current && (
      <DiffCanvasTemplate
        className={`${this.isReady() ? '' : 'd-none'}`}
        source={source}
        type={type}
        ignoringZoneList={ignoringZoneList}
        zoneList={[]}
        width={canvasRef.current && canvasRef.current.offsetWidth}
        isPixelBased
      />
    );
  }

  renderBody() {
    const { type, canvasRef } = this.props;
    return (
      <Col className="image-wrapper">
        {this.props.renderDescription(type)}
        <div
          ref={canvasRef}
          style={{ height: this.state.height }}
          className="canvas-wrapper"
        >
          {this.renderImageCanvas()}
          {this.renderDiffCanvas()}
          {this.renderIgnoreCanvas()}
        </div>
      </Col>
    );
  }

  render() {
    return <>{this.renderBody()}</>;
  }
}
KEyesPixelBased.propsTypes = {
  source: PropTypes.string,
  type: PropTypes.string.isRequired,
  // To filter ignoring zones
  showIgnoringZones: PropTypes.bool.isRequired,
  // To pass list of ignoring zones
  ignoringZones: PropTypes.array.isRequired,
};
export default KEyesPixelBased;
