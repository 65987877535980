import React from 'react';
import PropTypes from 'prop-types';

export default function ContentField({ id, value }) {
  return (
    <div
      id={id}
      className="content"
    >
      {value}
    </div>
  );
}

ContentField.propTypes = {
  value: PropTypes.element,
};

ContentField.defaultProps = {};
