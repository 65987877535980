import React from 'react';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import Stepper from '../components/wizard/Stepper';
import { t } from '../i18n/t';
import { IconLightbulb } from '../images/CustomIcon';

const EmptyState = ({ featureName, media, steps, className }) => {

  const defaultLayout = (step) => (
    <>
      <div>{step.label}</div>
      <div>{step.content}</div>
    </>
  );

  return (
    <Paper className={className} elevation={0}>
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <div>{media}</div>
        </Grid>
        <Grid item xs={6}>
          <IconLightbulb />
          <span className="ml-2">
            <span className="text-secondary">{t('empty-state#sub-text')}</span>
            <strong> {featureName} </strong>
          </span>
          <div className="mt-3">
            {
            steps.length > 1 ?
              <Stepper
                expanded
                steps={steps}
              />
              :
              defaultLayout(steps[0])
            }
          </div>
        </Grid>
      </Grid>
    </Paper>
  );
};

EmptyState.propTypes = {
  media: PropTypes.element.isRequired,
  featureName: PropTypes.string.isRequired,
  // steps have one element, default ui without step number
  steps: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.element.isRequired,
    content: PropTypes.element.isRequired,
  })).isRequired,
};

export default EmptyState;
