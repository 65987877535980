import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { t } from '../../../i18n/t';
import { IconRefresh } from '../../../images/CustomIcon';
import PageButtonToolbar from '../../../components/PageButtonToolbar';
import Services from '../../../utils/Services';
import MContext from '../../../models/MContext';
import Time from '../../../utils/Moment';
import Notification from '../../../utils/Notification';
import WebSocket from '../../../services/WebSocket';
import { next } from '../../../utils/Count';
import TooltipComponent from '../../../components/TooltipComponent';
import MFlags from '../../../models/MFlags';

export default function RefreshJiraButton(props) {
  const { connection, getConnectionAndSetState, isPageButtonToolBar } = props;

  const projectId = MContext.projectId;

  const refreshJiraIssues = () => {
    Services.refreshJiraIssueOfProject(projectId)
      .then(() => {
        if (getConnectionAndSetState) {
          getConnectionAndSetState(projectId);
        }
        Notification.pushSuccess(t('refresh-jira-issue-of-project'));
      });
  };

  const notifyHandler = () => {
    if (getConnectionAndSetState) {
      getConnectionAndSetState(projectId);
    }
    Notification.pushSuccess(t('refresh-jira-issue-of-project-success'));
  };

  const subscribeTopic = (handlerId) => {
    if (projectId) {
      const topic = `ProjectJiraIssues-${projectId}`;
      WebSocket.subscribe({
        projectId,
        topics: [topic],
      }, notifyHandler, handlerId);
    }
  };

  useEffect(() => {
    const handlerId = next();
    subscribeTopic(handlerId);
    return () => {
      WebSocket.unsubscribe(handlerId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (connection == null) {
    // When connection is null, return null to prevent rendering
    return null;
  }

  const isRenderLastUpdated = !connection.hasJiraWebhookConfiguration && connection.refreshedAt;

  const tooltipText = () => (
    <div>
      {t('refresh-button#action')}
      <li className="mt-1">{t('refresh-button#action-1')}</li>
      <li>{t('refresh-button#action-2')}</li>
    </div>
  );

  const renderButton = () => (
    <div className="d-flex">
      {isRenderLastUpdated &&
        <div className="time-refresh-jira">
          <span className="mr-1">
            {`${t('last-update')}:`}
          </span>
          {Time.format(connection.refreshedAt, Time.OVER_A_DAY_FORMAT)}
        </div>}
      {
        connection.refreshing ?
          <Button
            color="secondary"
            disabled
          >
            <div className="loading-spinner-icon" />
            {t('refreshing')}
          </Button> :
          <Button
            color="secondary"
            data-trackid="refresh-jira"
            onClick={refreshJiraIssues}
          >
            <IconRefresh className="icon-refresh-jira" />
            {t('refresh')}
          </Button>
        }
    </div>
  );

  const renderData = () => (MFlags.raJiraIntegrationEnhancementPhase2Enabled ? (
    <TooltipComponent text={tooltipText()} placement="bottom-end">
      {renderButton()}
    </TooltipComponent>
  ) : renderButton());

  return isPageButtonToolBar ? (
    <PageButtonToolbar>
      {renderData()}
    </PageButtonToolbar>
  ) : renderData();
}

RefreshJiraButton.propTypes = {
  connection: PropTypes.object.isRequired,
  getConnectionAndSetState: PropTypes.func,
  isPageButtonToolBar: PropTypes.bool,
};

RefreshJiraButton.defaultProps = {
  isPageButtonToolBar: true,
};

