import _ from 'lodash';
import React from 'react';
import TabComponent from '../../components/TabComponent';
import Apis from '../../utils/Apis';
import MTableColumnDataMapping from '../../components/table/models/MTableColumnDataMapping';
import Routes from '../../utils/Routes';
import { t } from '../../i18n/t';
import { buildSearchCondition } from '../../components/search/SearchUtils';
import DecoratorConstants from '../../utils/DecoratorConstants';
import DataTable from '../../components/table/DataTable';
import { IconDownload } from '../../images/CustomIcon';

class RequestsTab extends TabComponent {
  constructor(props) {
    super(props);

    this.meta.id = 'page-execution-test-result-details-request';
    this.meta.title = document.title;
  }

  render() {
    const { executionTestResultId } = this.props;
    const decorator = (name, row) => <div>{_.get(row, name, '')}</div>;
    const headers = [
      new MTableColumnDataMapping(
        t('id'),
        'id',
        (name, row) => {
          const constructedLink = new Routes({
            executionRequestId: row.id,
          });
          return DecoratorConstants.idDecorator(name, row, constructedLink.execution_request_link);
        },
      ),
      new MTableColumnDataMapping(
        t('method'),
        'method',
      ),
      new MTableColumnDataMapping(
        t('url'),
        'url',
        undefined, undefined,
        'long-text-column',
      ),
      new MTableColumnDataMapping(
        t('status'),
        'status',
        undefined, undefined,
        'fit-content-column',
      ),
      new MTableColumnDataMapping(
        t('status_text'),
        'statusText',
        undefined, undefined,
        'fit-content-column',
      ),
      new MTableColumnDataMapping(
        t('table-header#startTime'),
        'startedAt',
        DecoratorConstants.timeDecorator,
        undefined,
        'fit-content-column',
      ),
      new MTableColumnDataMapping(
        t('time'),
        'time',
        DecoratorConstants.durationDecorator,
      ),
      new MTableColumnDataMapping(
        t('execution_request#size'),
        'size',
        decorator,
      ),
      new MTableColumnDataMapping(
        t('execution_request#action'),
        'id',
        (name, row) => (
          <div>
            <a
              data-trackid="download-har"
              href={Apis.executionRequestDownload(row[name], { fileType: 'har' })}
              title="Export as HAR"
              className="btn btn-link"
            >
              <IconDownload />
            </a>
          </div>
        ),
        true,
      ),
    ];
    return (
      <DataTable
        columnMapping={headers}
        entityType="ExecutionRequest"
        defaultSearchConditions={[
          buildSearchCondition('ExecutionTestResult.id', '=', executionTestResultId),
        ]}
      />
    );
  }
}
export default RequestsTab;
