import React from 'react';
import PropTypes from 'prop-types';
import Divider from '@mui/material/Divider';
import styled from '@emotion/styled';
import { Stack } from '@mui/material';
import SymbolicCounter from './SymbolicCounter';
import colors from '../../../../scss/colors.scss';

/**
 * SymbolicCounterList component displays a summary with optional dashed dividers.
 *
 * @component
 * @example with dashed border
 * <SymbolicCounterList infoList={[{ icon: <displayed icon>, txt: <displayed text> }]} useDashedBorder={true} />
* */

const StyledTitle = styled.span`
  color: ${colors.gray9}; 
  width: fit-content;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.5;
`;

const StyledMainContainer = styled.div`
  display: flex;
  height: 34px;
  width: fit-content;
  align-items: center;
  column-gap: 8px;
`;

const StyledSubContainer = styled(Stack)`
  height: 34px;
  display: flex;
  align-items: center;
  border-radius: 4px;
  border: solid 1px ${colors.gray470};
  background-color: white;
  gap: 8px;
  padding: 0 4px;
`;

function SymbolicCounterList({ title = '', infoList, useDashedBorder = false }) {
  return (
    <StyledMainContainer>
      {title && <StyledTitle>{title}</StyledTitle>}
      <StyledSubContainer
        direction="row"
        divider={
          <Divider
            orientation="vertical"
            style={{ borderStyle: useDashedBorder ? 'dashed' : 'solid' }}
          />
        }
      >
        {infoList.map((item) => (
          <SymbolicCounter key={item.icon} item={item} />
        ))}
      </StyledSubContainer>
    </StyledMainContainer>
  );
}

SymbolicCounterList.propTypes = {
  infoList: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.node.isRequired,
      txt: PropTypes.string.isRequired,
      tooltip: PropTypes.string,
    })
  ).isRequired,
  useDashedBorder: PropTypes.bool,
  title: PropTypes.string,
};

export default SymbolicCounterList;
