import PropTypes from 'prop-types';
import React from 'react';
import { AutoSizer, InfiniteLoader, List } from 'react-virtualized';

/**
 * Infinite loader component. It can also be used to create infinite loading lists (e.g. Facebook or Twitter).
 */
const InfiniteLoaderList = (props) => {
  const {
    loadMoreRows,
    rowRenderer,
    isRowLoaded,
    rowCount,
    measurementCache,
  } = props;

  return (
    <InfiniteLoader
      isRowLoaded={isRowLoaded}
      loadMoreRows={loadMoreRows}
      rowCount={rowCount}
      minimumBatchSize={10}
      threshold={15}
    >
      {({ onRowsRendered, registerChild }) => (
        <AutoSizer>
          {({ width, height }) => (
            <List
              ref={registerChild}
              height={height}
              onRowsRendered={onRowsRendered}
              rowCount={rowCount}
              rowRenderer={rowRenderer}
              width={width}
              deferredMeasurementCache={measurementCache}
              rowHeight={measurementCache.rowHeight}
            />
          )}
        </AutoSizer>
      )}
    </InfiniteLoader>
  );
};

InfiniteLoaderList.propTypes = {
  loadMoreRows: PropTypes.func.isRequired,
  rowRenderer: PropTypes.func.isRequired,
  isRowLoaded: PropTypes.func.isRequired,
  rowCount: PropTypes.number.isRequired,
  measurementCache: PropTypes.object.isRequired,
};

export default InfiniteLoaderList;
