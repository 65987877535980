import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Autocomplete from '@mui/material/Autocomplete';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, InputGroup } from 'reactstrap';
import { t } from '../i18n/t';
import MFlags from '../models/MFlags';
import { IconLinkActive, IconLinkInactive } from '../images/CustomIcon';
import Services from '../utils/Services';
import TextField from './TextField';
import Arrays from '../utils/Arrays';
import { ObjectType, SearchEntity, TestOpsObjectType, XRAY_ISSUE_TYPE } from '../utils/Constants';
import DecoratorConstants from '../utils/DecoratorConstants';

export default class LinkIssueInput extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      value: props.value || '',
      options: [],
    };
    this.inputText = this.inputText.bind(this);
    this.saveText = this.saveText.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.throttledGetSuggestion = _.throttle(this.getSuggestion, 1000);
  }

  getSuggestion(value) {
    const { mapCustomizedXrayIssueTypeEnabled } = MFlags;
    const { objectType } = this.props;

    if (!value || value.length < 3) {
      return;
    }

    const params = {
      pagination: {
        page: 0,
        size: 10,
        sorts: ['id,desc'],
      },
      conditions: [
        {
          key: 'issue',
          operator: '=',
          value,
        },
        ...Arrays.insertIf(!mapCustomizedXrayIssueTypeEnabled && objectType === ObjectType.XRAY_TEST_CASE, {
          key: 'type',
          operator: '=',
          value: XRAY_ISSUE_TYPE.TEST,
        }),
        ...Arrays.insertIf(mapCustomizedXrayIssueTypeEnabled && objectType === ObjectType.XRAY_TEST_CASE, {
          key: 'testOpsObjectType',
          operator: '=',
          value: TestOpsObjectType.TEST_CASE,
        }),
      ],
      type: SearchEntity.JiraIssue,
    };
    Services.search(params)
      .then(({ content, pageable }) => {
        const options = content.slice(pageable.offset, pageable.pageSize);
        this.setState({ options });
      });
  }

  inputText(event, newValue) {
    this.setState({
      value: newValue,
    });
    this.throttledGetSuggestion(newValue);
  }

  handleKeyDown(event) {
    if (event.key === 'Enter') {
      event.preventDefault();
      this.saveText();
    }
  }

  resetInput() {
    this.setState({ value: '' });
  }

  saveText() {
    const { value } = this.state;
    const { onSave } = this.props;
    if (onSave) {
      onSave(value);
      this.resetInput();
    }
  }

  render() {
    const { value, options } = this.state;
    const { trackProps, disabled, ...rest } = this.props;

    return (
      <InputGroup {...rest}>
        <Autocomplete
          freeSolo
          disableClearable
          clearOnEscape
          onKeyDown={this.handleKeyDown}
          className="flex-grow-1"
          options={options}
          getOptionLabel={(option) => option.issueId || ''}
          filterOptions={_.identity}
          renderInput={(params) => <TextField {...params} />}
          renderOption={(props, option) => (
            <li {...props}>
              <Grid container>
                <Grid item xs={12}>
                  <div className="d-flex align-items-center">
                    {option.issueTypeIcon &&
                      <span className="mr-2 d-flex align-items-center">
                        <img
                          src={DecoratorConstants.getIssueIconUrl(option.issueTypeIcon, option.issueTypeName)}
                          alt=""
                          className="img-fluid icon-issue-type"
                        />
                      </span>}
                    <Typography variant="button" color="textPrimary">
                      {option.issueId}
                    </Typography>
                  </div>
                </Grid>
                <Grid>
                  <Typography variant="subtitle1" color="textSecondary">
                    {option.summary}
                  </Typography>
                </Grid>
              </Grid>
            </li>
          )}
          inputValue={value}
          onInputChange={this.inputText}
          disabled={disabled}
        />
        <Button
          {...trackProps}
          type="button"
          color="link"
          onClick={this.saveText}
          className="two-state-button px-2 py-0"
          title={t('link')}
          disabled={disabled || !value}
        >
          <IconLinkActive className="icon-active" />
          <IconLinkInactive className="icon-inactive" />
        </Button>
      </InputGroup>
    );
  }
}

LinkIssueInput.propTypes = {
  value: PropTypes.string,
  onSave: PropTypes.func,
  trackProps: PropTypes.object,
};
