import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import Time from '../../utils/Moment';
import StatusConstants from '../../utils/Statuses';
import LineChart from './LineChart';
import MChartDataMapping from './models/MChartDataMapping';
import MChartLabelMapping from './models/MChartLabelMapping';

class NumberTestRunsPerDayChart extends React.Component {

  render() {

    const { xAxis, options } = this.props;
    let { chartLabelsDecorate } = this.props;

    if (!chartLabelsDecorate) {
      chartLabelsDecorate = (fieldName, item) => `${_.get(item, fieldName)}`;
    }

    const labelsMapping = new MChartLabelMapping(
      xAxis,
      chartLabelsDecorate,
    );

    const datasetsMapping = [
      new MChartDataMapping(StatusConstants.PASSED.label, 'totalPassedTests', StatusConstants.PASSED.color),
      new MChartDataMapping(StatusConstants.FAILED.label, 'totalFailedTests', StatusConstants.FAILED.color),
      new MChartDataMapping(StatusConstants.ERROR.label, 'totalErrorTests', StatusConstants.ERROR.color),
      new MChartDataMapping(StatusConstants.INCOMPLETE.label, 'totalIncompleteTests', StatusConstants.INCOMPLETE.color),
    ];

    const defaultOptions = {
      scales: {
        xAxes: [{
          ticks: {
            callback: (value) => Time.formatDate(value, Time.DATE_FORMAT, true),
          },
        }],
      },
    };
    const chartOptions = _.merge({}, defaultOptions, options);

    return (
      <LineChart
        data={this.props.data}
        labelsMapping={labelsMapping}
        datasetsMapping={datasetsMapping}
        options={chartOptions}
        xLabel={this.props.xLabel}
        yLabel={this.props.yLabel || 'Number of test runs'}
        stacked
      />
    );
  }
}

NumberTestRunsPerDayChart.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]).isRequired,
  xLabel: PropTypes.string,
  chartLabelsDecorate: PropTypes.func,
  xAxis: PropTypes.string,
};

NumberTestRunsPerDayChart.defaultProps = {
  xAxis: 'id',
};

export default NumberTestRunsPerDayChart;
