import React from 'react';
import { Skeleton as MuiSkeleton } from '@mui/material';
import styles from '../../scss/colors.scss';

const Skeleton = ({ height, color = styles.skeletonColorKeyes }) =>
  <MuiSkeleton
    variant="rectangular"
    height={height}
    sx={{ bgcolor: color }}
  />;

export default Skeleton;
