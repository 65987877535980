
function SpinnerPage() {

  return (
   <div className="loading-spinner-page">
     <div className="loading-spinner" />
   </div>
  );
}

export default SpinnerPage;
