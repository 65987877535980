import {
  DEFAULT_URL,
  MAX_LENGTH,
  RECENT_URLS_KEY_SUFFIX,
  RECENT_URLS_LIMIT,
} from './constants';

export function buildDefaultTestCaseContent(testCaseName: string, url: string) {
  return `import katalon from "@katalon-studio/cloud-test";

  export default katalon.testCase("${testCaseName}", async ({ local, web }) => {
    await new Promise((r) => setTimeout(r, 0));
    await web.openBrowser("${url}");
  });`;
}

export function getRecentUrlsKey(projectId: string, accountId: string) {
  return `${projectId}-${accountId}-${RECENT_URLS_KEY_SUFFIX}`;
}

export function getRecentUrls(
  projectId: string,
  accountId: string,
  reverse = false
) {
  if (!projectId || !accountId) {
    return [];
  }

  const recentUrlsString = localStorage.getItem(
    getRecentUrlsKey(projectId, accountId)
  );
  const recentUrls = recentUrlsString ? JSON.parse(recentUrlsString) : [];

  return reverse ? recentUrls.reverse() : recentUrls;
}

export function addRecentUrl(
  projectId: string,
  accountId: string,
  url: string
) {
  if (!projectId || !accountId) {
    return;
  }

  const recentUrls = getRecentUrls(projectId, accountId);
  const recentUrlsKey = getRecentUrlsKey(projectId, accountId);

  if (recentUrls.includes(url)) {
    // remove duplicated url
    recentUrls.splice(recentUrls.indexOf(url), 1);
  } else if (recentUrls.length >= RECENT_URLS_LIMIT) {
    // remove the oldest url
    recentUrls.splice(0, 1);
  }

  recentUrls.push(url);
  localStorage.setItem(recentUrlsKey, JSON.stringify(recentUrls));
}

export function getDefaultUrl(projectId: string, accountId: string) {
  const recentUrls = getRecentUrls(projectId, accountId);
  return recentUrls.length > 0
    ? recentUrls[recentUrls.length - 1]
    : DEFAULT_URL;
}

export function isNotStartingWithCharAndNumber(text: string) {
  // regex check if text is starting with char and number
  const regex = /^[^\w]/g;
  return regex.test(text);
}

export function isContainingSpecialChar(text: string) {
  const regex = /[^A-Za-z0-9( ).,_-]/g;
  return regex.test(text);
}

export function isContainingConsecutiveDots(text: string) {
  return text.includes('..');
}

export function isEndingWithDot(text: string) {
  return text.endsWith('.');
}

export function isExceedingMaxLength(text: string) {
  return text.length > MAX_LENGTH;
}

export function getTestCaseNameErrors(name: string, isDuplicated: boolean) {
  const list = [];

  if (!name) {
    list.push('test_case_name#error_empty');
  }

  if (isExceedingMaxLength(name)) {
    list.push('test_case_name#error_max_length');
  }

  if (isNotStartingWithCharAndNumber(name)) {
    list.push('test_case_name#error_start_with_special_char');
  }

  if (isContainingSpecialChar(name)) {
    list.push('test_case_name#error_contain_special_char');
  }

  if (isContainingConsecutiveDots(name)) {
    list.push('test_case_name#error_contain_consecutive_dots');
  }

  if (isEndingWithDot(name)) {
    list.push('test_case_name#error_end_with_dot');
  }

  if (isDuplicated) {
    list.push('test_case_name#error_duplicate');
  }

  return list;
}
