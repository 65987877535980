import React from 'react';
import { FormGroup, Label } from 'reactstrap';
import PropTypes from 'prop-types';
import Input from '../../Input';
import { t } from '../../../i18n/t';

const G5TestCaseEditView = ({ testCaseName, testCasePath, testProjectName }) => (
  <>
    <FormGroup>
      <Label for={t('location')}>{t('location')}</Label>
      <Input plaintext id={t('location')} className="text-break">
        {(testProjectName && testCasePath) && (
          <>{testProjectName}/{testCasePath}</>
        )}
      </Input>
    </FormGroup>
    <FormGroup>
      <Label for={t('publish-test-case#test-case-name')}>{t('publish-test-case#test-case-name')}</Label>
      <Input plaintext id={t('publish-test-case#test-case-name')}>
        {testCaseName}
      </Input>
    </FormGroup>
  </>
);

G5TestCaseEditView.propTypes = {
  testCaseName: PropTypes.string.isRequired,
  testCasePath: PropTypes.string.isRequired,
  testProjectName: PropTypes.string.isRequired
};

export default G5TestCaseEditView;
