import React from 'react';
import PropTypes from 'prop-types';
import Uploader from '../Uploader';
import { t } from '../../i18n/t';
import { NUMBER_IMAGE_ALLOW_UPLOAD, MAX_FILE_SIZE_ALLOW_UPLOAD } from '../../pages/keyes/component/Constants';
import { IconUploadImages } from '../../images/CustomNewIcon';

export default function UploadMultipleImages({
  onUploadProgress,
  onUploadError,
  setFileUploader,
  setUploadFilesInput,
  onUploadFinish,
  onUploadStart,
  projectId,
  hasError = false,
  isDisabledUploader = false,
  onChange
}) {
  return (
    <div className={`upload-parent ${hasError && 'has-error'} ${isDisabledUploader && 'upload-parent-disabled'}`} onChange={onChange}>
      <div className="content">
        <div>
          <div><IconUploadImages /></div>
          <div className="header">
            <span className="link">{t('visual-testing#baseline-collection#click-to-upload')}</span>{` ${t('or')} ${t('visual-testing#baseline-collection#drag-drop')}`}
          </div>
          <div className="px-4">
            <div className="description">
              {t('visual-testing#baseline-collection#upload-image-description-one')}
            </div>
            <div className="description mt-1">
              {t('visual-testing#baseline-collection#upload-image-description-two')}
            </div>
            <div className="description mt-1">
              {t('visual-testing#baseline-collection#upload-image-description-three', { numberImages: NUMBER_IMAGE_ALLOW_UPLOAD, maxSize: MAX_FILE_SIZE_ALLOW_UPLOAD })}
            </div>
          </div>
        </div>
      </div>
      <Uploader
        signingUrl="/api/v1/files/upload-url"
        signingUrlMethod="GET"
        contentDisposition="attachment"
        accept="image/png, image/jpeg"
        preprocess={onUploadStart}
        onProgress={onUploadProgress}
        onError={onUploadError}
        onFinish={(data, file) => onUploadFinish(data, file)}
        signingUrlQueryParams={{
          projectId,
          'from-react-s3': true,
        }}
        signingUrlWithCredentials
        uploadRequestHeaders={{
          'content-type': 'application/octet-stream',
        }}
        autoUpload={false}
        ref={(uploader) => {
          setFileUploader(uploader);
        }}
        inputRef={(cmp) => {
          setUploadFilesInput(cmp);
        }}
        multiple
        className={`uploader ${isDisabledUploader && 'uploader-disabled'}`}
      />
    </div>
  );
}

UploadMultipleImages.propTypes = {
  onUploadStart: PropTypes.func,
  onUploadProgress: PropTypes.func,
  onUploadError: PropTypes.func.isRequired,
  setFileUploader: PropTypes.func.isRequired,
  setUploadFilesInput: PropTypes.func.isRequired,
  onUploadFinish: PropTypes.func,
  projectId: PropTypes.number.isRequired,
  hasError: PropTypes.bool,
  isDisabledUploader: PropTypes.bool,
  onChange: PropTypes.func
};

