import userflow from 'userflow.js';
import MConfigs from './../models/MConfigs';
import MAuth from './../models/MAuth';

/**
 * Send user's completion to Userflow as traits
 *
 * @param userAttributes
 */
export const updateUserTraits = (userAttributes) => {
  const USER_FLOW_TOKEN = MConfigs.userFlowToken;
  if (USER_FLOW_TOKEN) {
    return userflow.identify(MAuth.user.email, {
      ...userAttributes
    });
  }
  return new Promise((resolve) => resolve());
};
