import React from 'react';
import MTableColumnDataMapping from '../../components/table/models/MTableColumnDataMapping';
import { t } from '../../i18n/t';
import DecoratorConstants from '../../utils/DecoratorConstants';
import Routes from '../../utils/Routes';
import { buildDefaultFilter, buildFilter } from '../search-query/FilterQueryHelper';
import { buildSortQuery } from '../search-query/SortQueryHelper';
import DataTable from './DataTable';
import InputFilter from '../search-query/filter/InputFilter';
import StatusFilter, { StatusType } from '../search-query/filter/StatusFilter';
import TimeFilter from '../search-query/filter/TimeFilter';
import PropTypes from 'prop-types';

class TestSuiteDataTable extends React.Component {

  render() {

    const { defaultSearchConditions, tsNameTrackingProps } = this.props;

    const headers = [
      new MTableColumnDataMapping(
        t('status'),
        'lastExecutionTestSuite.status',
        DecoratorConstants.statusDecorator,
        undefined,
        'fit-content-column',
      ),
      new MTableColumnDataMapping(
        t('name'),
        'name',
        (name, row) => {
          const constructedLink = new Routes({
            teamId: row.project.teamId,
            projectId: row.project.id,
            testSuiteId: row.urlId,
          });
          const fullPath = row.path ? `${row.path}/${row.name}` : row.name;
          return (
            <>
              <div>
                {DecoratorConstants.idDecorator(
                  name,
                  row,
                  constructedLink.execution_test_suite_history_link,
                  fullPath,
                  null,
                  tsNameTrackingProps
                )}
              </div>
              <div>{row.path}</div>
            </>
          );
        },
        undefined,
        'short-text-column',
      ),
      new MTableColumnDataMapping(
        t('time'),
        'lastExecutionTestSuite',
        (name, row) => {
          const lastExecutionTestSuite = row.lastExecutionTestSuite;
          return DecoratorConstants.timeAndDurationDecorator(name, lastExecutionTestSuite);
        },
      ),
      new MTableColumnDataMapping(
        t('table-header#total'),
        'lastExecutionTestSuite.totalTests',
        DecoratorConstants.totalTestRunDecorator,
      ),
      new MTableColumnDataMapping(
        DecoratorConstants.passedBadgeDecorator(t('table-header#totalPassed')),
        'lastExecutionTestSuite.totalPassedTests',
        DecoratorConstants.passedCountDecorator,
      ),
      new MTableColumnDataMapping(
        DecoratorConstants.failedBadgeDecorator(t('table-header#totalFailed')),
        'lastExecutionTestSuite.totalFailedTests',
        DecoratorConstants.failedCountDecorator,
      ),
      new MTableColumnDataMapping(
        DecoratorConstants.errorBadgeDecorator(t('table-header#totalError')),
        'lastExecutionTestSuite.totalErrorTests',
        DecoratorConstants.errorCountDecorator,
      ),
      new MTableColumnDataMapping(
        DecoratorConstants.incompleteBadgeDecorator(t('table-header#totalIncomplete')),
        'lastExecutionTestSuite.totalIncompleteTests',
        DecoratorConstants.incompleteCountDecorator,
      ),
    ];
    const filterQuery = [
      buildFilter(InputFilter, { id: 'path', label: 'Path', operator: '~' }),
      buildFilter(StatusFilter, { id: 'ExecutionStatistics.status', type: StatusType.EXECUTION }),
      buildFilter(TimeFilter, { id: 'ExecutionStatistics.startTime', label: 'Last Run' }),
    ];
    const sortQuery = [
      ...buildSortQuery('name', t('name')),
      ...buildSortQuery('ExecutionStatistics.startTime', t('table-header#startTime')),
      ...buildSortQuery('ExecutionStatistics.duration', t('table-header#duration')),
    ];
    const defaultFilter = buildDefaultFilter('name', '~', 'Name');
    const newProps = {
      ...this.props,
      filterQuery,
      sortQuery,
      defaultFilter,
    };

    return (
      <DataTable
        entityType="TestSuite"
        defaultSearchConditions={defaultSearchConditions}
        columnMapping={headers}
        useSearchQuery
        {...newProps}
        defaultSort={['ExecutionStatistics.startTime, desc']}
      />
    );
  }
}

TestSuiteDataTable.propTypes = {
  tsNameTrackingProps: PropTypes.object,
};

TestSuiteDataTable.defaultProps = {
  tsNameTrackingProps: null,
};

export default TestSuiteDataTable;
