import React from 'react';
import { Button } from 'reactstrap';
import PageComponent from '../components/PageComponent';
import { t } from '../i18n/t';
import MTableColumnDataMapping from '../components/table/models/MTableColumnDataMapping';
import Routes from '../utils/Routes';
import ObjectSummary from '../components/summary/ObjectSummary';
import DecoratorConstants from '../utils/DecoratorConstants';
import { buildSearchCondition } from '../components/search/SearchUtils';
import MContext from '../models/MContext';
import DataTable from '../components/table/DataTable';
import DefaultLayout from '../components/DefaultLayout';
import ChooseTeamDialog from '../components/dialog/ChooseTeamDialog';
import PageButtonToolbar from '../components/PageButtonToolbar';
import MFlags from '../models/MFlags';
import MConfigs from '../models/MConfigs';
import Apis from '../utils/Apis';

class OrganizationProjects extends PageComponent {

  constructor(props) {
    super(props);
    this.meta.id = 'page-projects-settings';
    this.meta.title = t('projects');
    this.organizationId = MContext.organizationId;
    this.chooseTeamDialogRef = React.createRef();
    this.toggleChooseTeamDialog = this.toggleChooseTeamDialog.bind(this);
    this.projectHeader = this.projectHeader.bind(this);
    this.renderHeader = this.renderHeader.bind(this);
    this.renderBody = this.renderBody.bind(this);
  }

  projectHeader() {
    const { isLicenseAdminServer } = MConfigs;
    return [
      new MTableColumnDataMapping(
        t('name'),
        'name',
        (name, row) => {
          const link = isLicenseAdminServer
            ? Apis.testOpsProjectHome(row.team.id, row.id)
            : new Routes({
              teamId: row.team.id,
              projectId: row.id
            }).project_link;
          return DecoratorConstants.idDecorator(name, row, link);
        }
      ),
      new MTableColumnDataMapping(
        'Team',
        'team.name',
      )
    ];
  }

  navigateToCreateProjectPage(selectedTeam) {
    if (selectedTeam) {
      Routes.goToAdminProjectsPage(selectedTeam.value.id);
    }
  }

  toggleChooseTeamDialog() {
    this.chooseTeamDialogRef.current.toggle();
  }

  renderCreateProject() {
    return (
      <>
        <Button
          title="Create Project"
          color="secondary"
          onClick={this.toggleChooseTeamDialog}
        >
          Create Project
        </Button>
        <ChooseTeamDialog
          handleApproval={this.navigateToCreateProjectPage}
          primaryButtonText="Next"
          ref={this.chooseTeamDialogRef}
        />
      </>
    );
  }

  renderObjectSummary() {
    const urlParams = {
      organizationProjects: t('projects'),
    };
    return (
      <ObjectSummary params={urlParams} />
    );
  }

  renderHeader() {
    return (
      <>
        {this.renderObjectSummary()}
      </>
    );
  }

  renderBody() {
    const isLicenseAdminServer = MConfigs.isLicenseAdminServer;

    const projectHeader = this.projectHeader();
    return (
      <>
        {isLicenseAdminServer &&
          <PageButtonToolbar>
            {this.renderCreateProject()}
          </PageButtonToolbar>}
        <DataTable
          title={t('projects')}
          entityType="Project"
          defaultSearchConditions={[
            buildSearchCondition('Organization.id', '=', this.organizationId),
          ]}
          columnMapping={projectHeader}
        />
      </>
    );
  }

  render() {
    return (
      <DefaultLayout
        renderHeader={this.renderHeader}
        renderBody={this.renderBody}
      />
    );
  }
}

export default OrganizationProjects;
