import React from 'react';
import { IconJira } from '../images/CustomIcon';
import { t } from '../i18n/t';
import { JiraReleaseStatus } from '../utils/Constants';

const ReleaseStatusLabel = ({ jiraReleaseStatus, title, className }) => {

  const renderColor = () => {
    return jiraReleaseStatus === JiraReleaseStatus.RELEASED ? 'released' : 'unreleased';
  };

  return (
    <div className={`jira-status-label ${className} ${renderColor()}`} title={title}>
      <div className="jira-status-content">
        <IconJira className="icon" /> <div className="text">{t(jiraReleaseStatus)}</div>
      </div>
    </div>
  );

};

export default ReleaseStatusLabel;
