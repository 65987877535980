import * as React from 'react';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import classnames from 'classnames';
import { styled } from '@mui/material/styles';
import colors from '../../../scss/colors.scss';

export default function HorizontalLinearStepper({ steps, activeStep }) {

  const StyledStepper = styled((props) => (
    <Stepper
      {...props}
    />
  ))(() => ({
    '& .MuiStep-root': {
      '& .MuiStepLabel-root .Mui-completed': {
        color: colors.blue5,
      },
      '& .MuiStepLabel-root .Mui-active': {
        color: colors.blue5,
      },
      '& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel': {
        color: colors.white,
      },
      '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
        fill: colors.white,
      },
    },
    '& .MuiStepIcon-root': {
      width: '2rem',
      height: '2rem',
    },
    '& .MuiStepLabel-label': {
      '&.Mui-active': {
        color: `${colors.textDarkColor} !important`
      },
      '&.Mui-completed': {
        color: `${colors.textDarkColor} !important`
      }
    },
  }));

  return (
    <div>
      <div className="w-75">
        <StyledStepper activeStep={activeStep}>
          {steps.map((step) => (
            <Step key={step.label}>
              <StepLabel>{step.label}</StepLabel>
            </Step>
          ))}
        </StyledStepper>
      </div>
      {steps.map((step) => (
        <div
          className={classnames('mt-3', (steps.indexOf(step) !== activeStep) && 'd-none')}
          key={`${step.label}-content`}
        >
          {step.content}
        </div>
      ))}
    </div>
  );
}
