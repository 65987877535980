import React, { Component } from 'react';
import { List, ListItem, ListItemText, ListItemIcon } from '@mui/material';
import { Label, Row } from 'reactstrap';
import DataLoader from '../../../components/table/DataLoader';
import { IconGitRepo, IconKSRepo } from '../../../images/CustomIcon';
import Routes from '../../../utils/Routes';
import MContext from '../../../models/MContext';
import { t } from '../../../i18n/t';

class TestProjectsCard extends Component {

  constructor(props) {
    super(props);
    this.teamId = MContext.teamId;
    this.projectId = MContext.projectId;
  }

  renderType(type) {
    switch (type) {
      case 'GIT':
        return <span title={type}><IconGitRepo /></span>;
      case 'KS':
        return <span title={type}><IconKSRepo /></span>;
      default:
        return (
          <span title={type}>
            <IconKSRepo />
          </span>);
    }
  }

  constructorLink(id) {
    const route = new Routes({
      teamId: this.teamId,
      projectId: this.projectId,
      testProjectId: id,
    });
    return route.test_project_link;
  }

  renderListTestProjects(testProjects) {
    return (
      <List>
        {testProjects.map((testProject) =>
          <ListItem key={testProject.id} button component="a" href={this.constructorLink(testProject.id)}>
            <ListItemIcon>
              {this.renderType(testProject.type)}
            </ListItemIcon>

            <ListItemText>
              <div className="text-word-wrap" title={testProject.name}>
                {testProject.name}
              </div>
            </ListItemText>
          </ListItem>)}
      </List>
    );
  }

  renderNoTestProjects() {
    return (
      <Row className="align-items-center justify-content-center">
        <Label>{t('table#empty-message')}</Label>
      </Row>
    );
  }

  renderTestProject(testProjects) {
    return (
      <>
        {testProjects.length > 0 ?
          this.renderListTestProjects(testProjects) :
          this.renderNoTestProjects()}
      </>
    );
  }

  renderFooter() {
    const route = new Routes({
      teamId: this.teamId,
      projectId: this.projectId
    });
    const testProjectLink = route.test_project_link;
    return (
      <div className="text-right">
        <a href={testProjectLink}>{t('view-all')}</a>
      </div>
    );
  }

  render() {
    return (
      <DataLoader
        title={t('test-projects')}
        entityType="TestProject"
        render={(data) => this.renderTestProject(data)}
        defaultSort={['createdAt,desc']}
        defaultSearchConditions={[
        ]}
        pageSize={5}
        hidePaging
        renderFooter={this.renderFooter()}
        {...this.props}
      />
    );
  }
}

export default TestProjectsCard;
