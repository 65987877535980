import React, { Component } from 'react';
import TestCaseStatusBar from '../../pages/test_case/TestCaseStatusBar';
import { buildSearchCondition } from '../../components/search/SearchUtils';

class RequirementDetailsStatusBar extends Component {
  render() {
    if (!this.props.requirementId) {
      return null;
    }
    return (
      <TestCaseStatusBar
        defaultSearchConditions={[
          buildSearchCondition('ExternalIssue.id', '=', this.props.requirementId),
        ]}
      />
    );
  }
}

export default RequirementDetailsStatusBar;
