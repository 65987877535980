import { buildSearchCondition } from '../../components/search/SearchUtils';

export const getLicenseKeySearchConditions = (organizationId, feature, mode) => {
  return [
    buildSearchCondition('Organization.id', '=', organizationId),
    buildSearchCondition('type', '=', 'ENTERPRISE'),
    buildSearchCondition('feature', '=', feature),
    buildSearchCondition('mode', '=', mode),
    buildSearchCondition('status', '=', 'ACTIVE'),
  ];
}

