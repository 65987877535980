import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { DialogContent, DialogActions, Divider, Box } from '@mui/material';
import { Button } from 'reactstrap';
import { isEqual } from 'lodash';
import CloseableDialogComponent from './CloseableDialogComponent';
import { t } from '../../i18n/t';
import Services from '../../utils/Services';
import GroupEvent from '../../utils/track/GroupEvent';
import { IconDisplayKeyes } from '../../images/CustomIcon';
import { IconPencilEdit } from '../../images/CustomNewIcon';
import TooltipComponent from '../../components/TooltipComponent';
import { getFileUrl } from '../../pages/keyes/component/Utils';
import VisualTestingDraw from '../draw/VisualTestingDraw';
import colors from '../../../scss/colors.scss';
import { MAX_IGNORED_ZONES, ID_BUTTON_DRAW_ZONES, ID_DELETE_IGNORED_ZONE_DIALOG, ID_ACKNOWLEDGE_APPLY_ALL_DIALOG } from '../../utils/Constants';
import DeleteIgnoredZoneDialog from './DeleteIgnoredZoneDialog';
import AcknowledgeApplyAllBaselineCollectionDialog from './AcknowledgeApplyAllBaselineCollectionDialog';
import { getLocalStorageItem } from '../../utils/LocalStorageHelper';
import { APPLY_ALL_BASELINE_COLLECTION_ACKNOWLEDGE } from '../../utils/LocalStorageKey';

function BaselineImageEditingDialog({ isOpen, handleClose, baseline, baselineCollectionId, onEditSuccess }) {

  const baselineId = baseline.id;
  const baselineName = baseline?.screenshot?.name;
  const baselineWidth = baseline?.screenshot?.width;
  const baselineHeight = baseline?.screenshot?.height;
  const imageSource = getFileUrl(baseline, 'uploadFile.fileHandleId');
  const ignoringZones = baseline?.ignoringZones || [];
  const draftIgnoringZones = baseline?.draftIgnoringZones;
  // if draftIgnoringZones == null, we set initialAllIgnoringZones to ignoringZones
  const initialAllIgnoringZones = draftIgnoringZones || ignoringZones;

  const shapeProperties = {
    stroke: colors.borderIgnoringZonesColor,
    strokeWidth: 2,
    fill: colors.ignoringZonesColor,
    typeAllStroke: colors.borderIgnoringAllZonesColor,
    typeAllFill: colors.ignoringAllZonesColor,
  };

  const trackCloseIcon = {
    'data-trackId': 'close-define-ignoring-zone',
    'data-groupId': GroupEvent.ACCESS_REPORT,
  };

  const defaultStateOfDrawMode = false;
  const defaultStateOfDisableSave = true;

  const [isDrawMode, setDrawMode] = useState(defaultStateOfDrawMode);
  const [isDisableSave, setDisableSave] = useState(defaultStateOfDisableSave);
  const [coordinatesList, setCoordinatesList] = useState(initialAllIgnoringZones);
  const [isOpenApplyAllAcknowledgeDialog, setOpenApplyAllAcknowledgeDialog] = useState(false);
  const [isOpenDeleteIgnoredZoneDialog, setOpenDeleteIgnoredZoneDialog] = useState(false);
  const visualTestingDrawRef = useRef();

  useEffect(() => {
    // when user switch editing between baseline images, we initial the coordinates corresponding
    // and reset the default state
    setCoordinatesList(initialAllIgnoringZones);
    setDrawMode(defaultStateOfDrawMode);
    setDisableSave(defaultStateOfDisableSave);
  }, [baseline]);

  useEffect(() => {
    // when the coordinates changes, we check the change from the initial state, if yes, we allow saving draft
    if (isEqual(initialAllIgnoringZones, coordinatesList)) {
      setDisableSave(true);
    } else {
      setDisableSave(false);
    }
  }, [coordinatesList]);

  const handleSaveDraft = (event) => {
    event.preventDefault();
    Services.saveDraftIgnoredZone(baselineCollectionId, baselineId, coordinatesList)
      .then(() => {
        handleClose();
        setDrawMode(defaultStateOfDrawMode);
        setDisableSave(defaultStateOfDisableSave);
        onEditSuccess();
      });
  };

  const disableDrawMode = () => {
    setDrawMode(false);
  };

  const toggleDrawMode = () => {
    setDrawMode(!isDrawMode);
    // Remove focus from button
    document.getElementById(ID_BUTTON_DRAW_ZONES).blur();
  };

  const handleDeleteIgnoredZoneDialog = () => {
    visualTestingDrawRef.current.handleDeleteIgnoredZone();
    setOpenDeleteIgnoredZoneDialog(false);
  };

  const handleCloseDeleteIgnoredZoneDialog = () => {
    setOpenDeleteIgnoredZoneDialog(false);
  };

  const handleCloseAcknowledgeApplyAllBaselineCollectionDialog = () => {
    setOpenApplyAllAcknowledgeDialog(false);
  };

  const openAcknowledgeApplyAllBaselineCollectionDialog = () => {
    const isAcknowledgeNotShowAgain = Boolean(getLocalStorageItem(APPLY_ALL_BASELINE_COLLECTION_ACKNOWLEDGE));
    setOpenApplyAllAcknowledgeDialog(!isAcknowledgeNotShowAgain);
  };

  const onBeforeDeleteIgnoredZone = () => {
    setOpenDeleteIgnoredZoneDialog(true);
  };

  return (
    <CloseableDialogComponent
      isOpen={isOpen}
      handleClose={handleClose}
      title={<div className="edit-baseline__title">{baselineName}</div>}
      trackCloseIcon={trackCloseIcon}
      maxWidth="xl"
      headerStyle="pb-0"
    >
      <DialogContent>
        <div className="edit-baseline__sticky">
          <div className="mb-2">
            <IconDisplayKeyes className="display-icon" />
            {baselineWidth}x{baselineHeight}
          </div>
          <div>
            <Button
              id={ID_BUTTON_DRAW_ZONES}
              color="light"
              className={isDrawMode ? 'edit-baseline__draw-state-btn' : 'edit-baseline__view-state-btn'}
              onClick={toggleDrawMode}
              data-trackid="define-ignoring-zone"
              data-groupid={GroupEvent.ACCESS_REPORT}
            >
              <IconPencilEdit className="mr-2" />{t('visual-testing#edit-baseline#configIgnoredZones')}
            </Button>
            <TooltipComponent text={t('visual-testing#edit-baseline#description')} placement="bottom-end" />
          </div>
          <Box className="pt-2 pb-2">
            <Divider />
          </Box>
        </div>
        <div className="edit-baseline__horizontal-scroll pb-2">
          <VisualTestingDraw
            ref={visualTestingDrawRef}
            height={baselineHeight}
            width={baselineWidth}
            backgroundImage={imageSource}
            shapeProperties={shapeProperties}
            isAllowDraw={isDrawMode}
            coordinatesList={coordinatesList}
            setCoordinatesList={setCoordinatesList}
            maxIgnoredZones={MAX_IGNORED_ZONES}
            disableDrawMode={disableDrawMode}
            openAcknowledgeDialog={openAcknowledgeApplyAllBaselineCollectionDialog}
            onBeforeDeleteIgnoredZone={onBeforeDeleteIgnoredZone}
            dialogIds={[ID_DELETE_IGNORED_ZONE_DIALOG, ID_ACKNOWLEDGE_APPLY_ALL_DIALOG]}
          />
        </div>
        <Box>
          <Divider />
        </Box>
        <DeleteIgnoredZoneDialog
          id={ID_DELETE_IGNORED_ZONE_DIALOG}
          isOpen={isOpenDeleteIgnoredZoneDialog}
          handleClose={handleCloseDeleteIgnoredZoneDialog}
          handleDelete={handleDeleteIgnoredZoneDialog}
        />
        <AcknowledgeApplyAllBaselineCollectionDialog
          id={ID_ACKNOWLEDGE_APPLY_ALL_DIALOG}
          isOpen={isOpenApplyAllAcknowledgeDialog}
          handleClose={handleCloseAcknowledgeApplyAllBaselineCollectionDialog}
        />
      </DialogContent>
      <DialogActions className="mt-1 mb-4">
        <Button
          color="secondary"
          onClick={handleClose}
          data-trackid="cancel-define-ignoring-zone"
          data-groupid={GroupEvent.ACCESS_REPORT}
        >
          {t('cancel')}
        </Button>
        <Button
          color="primary"
          onClick={handleSaveDraft}
          disabled={isDisableSave}
          data-trackid="save-draft-ignoring-zone"
          data-groupid={GroupEvent.ACCESS_REPORT}
        >
          {t('save')}
        </Button>
      </DialogActions>
    </CloseableDialogComponent>
  );
}

BaselineImageEditingDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  baseline: PropTypes.array.isRequired,
};

BaselineImageEditingDialog.defaultProps = {
  isOpen: false,
  handleClose: null,
  baseline: null,
};

export default BaselineImageEditingDialog;
