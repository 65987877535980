import React, { useState, useEffect, useRef } from 'react';
import {
  Popper,
  Link,
  Paper,
  Collapse,
  Fade,
  Button,
  List,
  ClickAwayListener,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
} from '@mui/material';
import { groupBy } from 'lodash';
import MContext from '../../models/MContext';
import {
  IconArrowDown,
  IconAddOrganization,
  IconCheckProject,
  IconChevronDown,
  IconChevronUp,
  IconChevronUpHovered,
  IconChevronDownHovered,
} from '../../images/CustomNewIcon';
import MAuth from '../../models/MAuth';
import ImageProfile from '../avatar/ImageProfile';
import { buildSearchCondition } from '../search/SearchUtils';
import Services from '../../utils/Services';
import Routes from '../../utils/Routes';
import { t } from '../../i18n/t';
import MConfigs from '../../models/MConfigs';
import Apis from '../../utils/Apis';
import { SearchEntity } from '../../utils/Constants';
import MFlags from '../../models/MFlags';
import Helper from '../../utils/Helper';

/**
 * Render expand or collapse button, change icon color on hover
 * Props:
 *   expanded: boolean, whether to return an expand button
 *   onExpand, onCollapse: event handlers
 *   width, height: size of the icon
 */
export const ExpandOrCollapseButton = ({ expanded, onExpand, onCollapse, width, height }) => {
  const [hovered, setHovered] = useState(false);
  return (
    <div
      className="chevron"
      onClick={expanded ? onCollapse : onExpand}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      {
        (() => {
          if (expanded && hovered) {
            return <IconChevronUpHovered width={width} height={height} />;
          } else if (expanded && !hovered) {
            return <IconChevronUp width={width} height={height} />;
          } else if (!expanded && hovered) {
            return <IconChevronDownHovered width={width} height={height} />;
          } else {
            return <IconChevronDown width={width} height={height} />;
          }
        })()
      }
    </div>
  );
};

/**
 * Component for showing an organization item in the list.
 * It contains the organization's avatar, name, and a dropdown button
 *   which shows three most recently accessed projects of the organization.
 * The project items are part of this component too, they're indented slightly.
 * When the component is expanded, a request to fetch three most recently
 *   accessed projects will be sent to the server.
 *
 * Props:
 *   organization: an organization object, ideally fetched from the server
 *   showSelectedProject: boolean, whether to display a tick before the current project
 *   isCurrentOrganization: boolean, whether to display a marker before the organization
 *   hasProject: boolean, whether to display the expand/collapse button
 */
const OrganizationItem = ({ organization, defaultExpanded, showSelectedProject = false, isCurrentOrganization, hasProject }) => {
  const currentProject = MContext.project;
  const [expanded, setExpanded] = useState(defaultExpanded);
  const [loading, setLoading] = useState(false);
  const [recentProjects, setRecentProjects] = useState(null);
  const organizationLink = MConfigs.isLicenseAdminServer
    ? Apis.testOpsOrganizationHome(organization.id, null)
    : new Routes({ organizationId: organization.id }).organization_home_link;
  const organizationAllProjectsLink = MConfigs.isLicenseAdminServer
    ? new Routes({ organizationId: organization.id }).organization_projects_link
    : Apis.adminOrganizationAllProjects(organization.id);
  const getRecentProjects = () => {
    setLoading(true);
    const params = {
      pagination: {
        page: 0,
        size: 3,
        sorts: ['accessedAt,desc'],
      },
      conditions: [
        buildSearchCondition('Organization.id', '=', organization.id),
      ],
      type: SearchEntity.RecentProject,
    };
    return Services.search(params)
      .then(({ content }) => {
        setRecentProjects(content.map((project) => ({
          ...project,
          link: MConfigs.isLicenseAdminServer
            ? Apis.testOpsProjectHome(project.teamId, project.id)
            : new Routes({ teamId: project.teamId, projectId: project.id }).project_link,
        })));
      }).finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    if (!loading && expanded && recentProjects === null && hasProject) {
      getRecentProjects();
    }
  }, [expanded, loading, recentProjects]);
  const handleExpand = () => {
    setExpanded(true);
  };
  const handleCollapse = () => {
    setExpanded(false);
  };
  const renderSelectedMarker = () => {
    if (isCurrentOrganization) {
      return (<div className="itemSelectedMarker" />);
    }
    return (<div className="itemSelectedMarker invisible" />);
  };

  const renderExpandOrCollapseButton = () => {
    if (!hasProject) {
      return null;
    }
    return (
      <ExpandOrCollapseButton
        width={12}
        height={12}
        onExpand={handleExpand}
        onCollapse={handleCollapse}
        expanded={expanded}
      />
    );
  };

  return <>
    <ListItem
      button
      className="organizationItem"
      selected={defaultExpanded}
    >
      {renderSelectedMarker()}
      <ListItemIcon className="itemIcon itemOrganizationIcon">
        <Link href={organizationLink} underline="hover">
          <ImageProfile
            name={organization.name}
            imgUrl={organization.logoUrl}
            diameter={24}
          />
        </Link>
      </ListItemIcon>
      <ListItemText
        className="itemText"
        primaryTypographyProps={{
          component: Link,
          href: organizationLink,
        }}
      >
        {organization.name}
      </ListItemText>
      {renderExpandOrCollapseButton()}
    </ListItem>
    <Collapse in={expanded} timeout="auto" unmountOnExit>
      <div className="projectList">
        {loading && (
          <div className="centerText">{t('list-organizations-and-projects#loading-projects')}</div>
        )}
        {recentProjects !== null && (
          <List disablePadding>
            {recentProjects.map((proj) => (
              <ListItem
                button
                className="projectItem"
                component={Link}
                href={proj.link}
              >
                {(proj.id === currentProject?.id && showSelectedProject)
                  ? (
                    <ListItemIcon className="itemIcon itemProjectIcon">
                      <IconCheckProject />
                    </ListItemIcon>
                  ) : (
                    <div className="itemIcon itemProjectIcon" />
                  )}
                <ListItemIcon className="itemIcon itemProjectIcon">
                  <ImageProfile
                    name={proj.name}
                    diameter={24}
                  />
                </ListItemIcon>
                <ListItemText className="itemText">{proj.name}</ListItemText>
              </ListItem>
            ))}
          </List>
        )}
        {!loading && recentProjects?.length > 0 && (
          <div className="centerText projectItem">
            <Link href={organizationAllProjectsLink} underline="hover">
              {t('view_projects')}
            </Link>
          </div>
        )}
      </div>
    </Collapse>
  </>;
};

/**
 * This component renders a list of organization items, each can be expanded
 *   to show a nested list of up to 3 most recent projects of that organization.
 * The component currently takes no props, and it is meant to uniform the
 *   top left navigation bar across TO, K1, and Admin.
 * It contains two groups: Current and All. The "Current" group contains only
 *   one organization, which is the current one.
 *   The "All" group contains all organizations, including the current one.
 * @see TopLeftNavigationBar
 */
const ListOrganizationsAndProjects = () => {
  const [open, setOpen] = useState(false);
  const anchorEl = useRef(null);
  const team = MContext.team;
  const teamId = MContext.teamId;
  const isTeamDemo = MContext.isTeamDemo;
  const currentOrganization = MContext.organization || team?.organization || null;
  const [organizationProjects, setOrganizationProjects] = useState(null);
  const currentProject = MContext.project;
  const isPrivateTenant = MConfigs.isPrivateTenant;
  // some pages not require logged-in => MAuth.user can be undefined
  const myOrganizations = MAuth.user?.organizations;
  const createNewOrganizationLink = !MConfigs.isLicenseAdminServer
    ? Apis.katOneAdminCreateOrganization()
    : new Routes().organization_create_link;
  const currentAccountId = currentOrganization?.accountId;
  const filteredOrganizationsByAccount = myOrganizations.filter((org) => org.accountId === currentAccountId);
  const handleClick = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  // handle click away or click on menu item
  const handleClickAway = (event) => {
    if (anchorEl.current && anchorEl.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const convertOrganizationProjects = () => {
    // some pages not require logged-in, if user not logged-in => render nothing
    if (!MAuth.user) return;
    const { projects } = MAuth.user;
    if (projects && projects.length) {
      const groupByOrganizationId = groupBy(projects, 'team.organizationId');
      setOrganizationProjects(groupByOrganizationId);
    }
  };

  useEffect(() => {
    convertOrganizationProjects();
  }, []);

  const doesOrganizationHasProject = (organizationId) => (
    organizationProjects != null ? !!organizationProjects[organizationId] : false
  );

  /**
   * Check when data is loading, then not show component.
   */
  if (!currentOrganization) {
    return null;
  }

  const isLicenseAdminServer = MConfigs.isLicenseAdminServer;

  return (
    <div className="list-organizations-and-projects">
      <Button
        ref={anchorEl}
        endIcon={!isTeamDemo && <IconArrowDown />}
        onClick={!isTeamDemo && handleClick}
        id="select-organization"
      >
        <span className="name">{currentProject?.name ?? currentOrganization?.name}</span>
      </Button>
      <Popper
        open={open}
        anchorEl={anchorEl.current}
        placement="bottom-start"
        disablePortal
        transition
      >
        {({ TransitionProps }) => (
          <Fade
            {...TransitionProps}
            timeout={350}
          >
            <Paper elevation={1}>
              <ClickAwayListener onClickAway={handleClickAway}>
                <span>
                  <div className="lists">
                    {
                      !isPrivateTenant &&
                      <List
                        subheader={<ListSubheader disableSticky className="subheader">{t('list-organizations-and-projects#current')}</ListSubheader>}
                      >
                        <OrganizationItem
                          organization={currentOrganization}
                          defaultExpanded={!!currentProject}
                          showSelectedProject
                          isCurrentOrganization
                          hasProject={doesOrganizationHasProject(currentOrganization.id)}
                        />
                      </List>
                    }
                    <List
                      subheader={<ListSubheader disableSticky className="subheader">{t('list-organizations-and-projects#all')}</ListSubheader>}
                    >{
                      (MFlags.filterOrganizationsOnOrganizationMenuByAccount) ?
                        ((!isLicenseAdminServer) ?
                          Helper.sortArrayAlphabet(filteredOrganizationsByAccount, 'name').map((organization) => (
                            <OrganizationItem
                              organization={organization}
                              defaultExpanded={false}
                              hasProject={doesOrganizationHasProject(organization.id)}
                            />
                          )) :
                          filteredOrganizationsByAccount.map((organization) => (
                            <OrganizationItem
                              organization={organization}
                              defaultExpanded={false}
                              hasProject={doesOrganizationHasProject(organization.id)}
                            />
                          ))) :
                        ((!isLicenseAdminServer) ?
                          Helper.sortArrayAlphabet(myOrganizations, 'name').map((organization) => (
                            <OrganizationItem
                              organization={organization}
                              defaultExpanded={false}
                              hasProject={doesOrganizationHasProject(organization.id)}
                            />
                          )) :
                          myOrganizations.map((organization) => (
                            <OrganizationItem
                              organization={organization}
                              defaultExpanded={false}
                              hasProject={doesOrganizationHasProject(organization.id)}
                            />
                          )))
                    }
                    </List>
                  </div>
                  {
                    !isPrivateTenant &&
                    <div className="createOrganizationBtn">
                      <IconAddOrganization />
                      <Link href={createNewOrganizationLink} underline="hover">
                        {t('create_new_organization')}
                      </Link>
                    </div>
                  }
                </span>
              </ClickAwayListener>
            </Paper>
          </Fade>
        )}
      </Popper>
    </div>
  );
};

export default ListOrganizationsAndProjects;
