import React from 'react';
import { Card, CardBody, CardHeader } from 'reactstrap';
import classNames from 'classnames';
import { IconClose } from '../images/KitIcons';
import { IconViewSidebarActive } from '../images/CustomIcon';

class InfoSidebar extends React.Component {
  get isOpened() {
    return this.state.isOpened;
  }

  get title() {
    return this.state.title;
  }

  get content() {
    return this.state.content;
  }

  constructor(props) {
    super(props);
    this.state = {
      isOpened: false,
      title: null,
      content: null,
      linkTo: null,
      style: null,
    };

    this.toggle = this.toggle.bind(this);
    this._triggerCloseEvent = this._triggerCloseEvent.bind(this);
  }

  open() {
    this.setState({
      isOpened: true,
    });
  }

  close() {
    this.setState({
      isOpened: false,
    });
    this._triggerCloseEvent();
  }

  toggle() {
    if (this.isOpened) {
      this.close();
    } else {
      this.open();
    }
  }

  clear() {
    this.setState({
      content: null,
      title: null,
    });
  }

  closeAndClear() {
    if (this.isOpened) {
      this.close();
      this.triggerOnclose();
    }
    this.clear();
  }

  setContent(content) {
    this.setState({
      content,
    });
  }

  setTitle(title) {
    this.setState({
      title,
    });
  }

  setLinkTo(linkTo) {
    this.setState({
      linkTo,
    });
  }

  setStyle(style) {
    this.setState({
      style,
    });
  }

  show(title, content, linkTo, style) {
    this.setTitle(title);
    this.setContent(content);
    this.setLinkTo(linkTo);
    this.setStyle(style);
    this.open();
  }

  _triggerCloseEvent() {
    if (this.onCloseCallback) {
      this.onCloseCallback();
    }
  }
  onClose(callback) {
    this.onCloseCallback = callback;
  }

  render() {
    const { title, content, linkTo, style } = this.state;
    return (
      <div className={classNames(
        style || 'info-sidebar-wrapper',
        'sticky-top mt-0 border-left',
        {
          show: this.state.isOpened,
        },
      )}
      >
        <Card
          className="info-sidebar mt-0 border-left"
        >
          <CardHeader className="info-sidebar__header d-flex align-items-start text-normal">
            <div className="flex-fill pt-1 align-self-start">
              {linkTo && (
                <a
                  href={linkTo}
                >
                  <IconViewSidebarActive className="mr-1" />
                </a>
              )}
              {title}
            </div>
            <div
              className="info-sidebar__header__close-button p-2 btn btn-light align-self-start"
              onClick={this.toggle}
            >
              <IconClose />
            </div>
          </CardHeader>
          <CardBody
            className="info-sidebar__body"
          >
            {content}
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default InfoSidebar;
