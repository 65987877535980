import React from 'react';
import {
  Button,
  FormGroup,
  Form,
  FormText,
  Row, Col,
  Card, CardHeader, CardBody
} from 'reactstrap';
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import Notification from '../../utils/Notification';
import { buildSearchCondition } from '../../components/search/SearchUtils';
import MContext from '../../models/MContext';
import Services from '../../utils/Services';
import { t } from '../../i18n/t';
import ImageProfile from '../../components/avatar/ImageProfile';
import TextField from '../../components/TextField';
import MAuth from '../../models/MAuth';

class AddUserTab extends React.Component {
  constructor(props) {
    super(props);
    this.teamId = MContext.teamId;

    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.renderSelectUser = this.renderSelectUser.bind(this);
    this.addUserTeam = this.addUserTeam.bind(this);
    this.getUserInOrganization = this.getUserInOrganization.bind(this);

    this.state = {
      userSelects: [],
      userTeams: null,
      userOrganizations: null,
    };

  }

  getUserInOrganization() {
    const team = MContext.team;
    if (team.organization) {
      const params = {
        pagination: {
          page: 0,
          size: 1000000,
          sorts: 'id,asc',
        },
        conditions: [buildSearchCondition('Organization.id', '=', team.organization.id)],
        type: 'UserOrganization',
        ignoreAutoFill: true,
      };

      Services.search(params)
        .then(({ content }) => {
          const userOrgs = content.map((userOrg) => userOrg.user);
          this.setState({
            userOrganizations: userOrgs,
          });
        });
    }
  }

  getUserInTeam() {
    const team = MContext.team;
    if (team.organization) {
      const params = {
        pagination: {
          page: 0,
          size: 1000000,
          sorts: 'id,asc',
        },
        conditions: [
          buildSearchCondition('Team.id', '=', team.id)
        ],
        type: 'UserTeam',
        ignoreAutoFill: true,
      };

      Services.search(params)
        .then(({ content }) => {
          this.setState({
            userTeams: content,
          });
        });
    }
  }

  componentDidMount() {
    this.getUserInOrganization();
    this.getUserInTeam();
  }

  addUserTeam(e) {
    e.preventDefault();
    const { userSelects } = this.state;

    const userIds = userSelects.map((user) => user.id);

    Services.addUserInTeam({ teamId: this.teamId, newUserIds: userIds })
      .then((response) => {
        this.getUserInTeam();
        const totalEmails = response.length;
        Notification.pushSuccess(t('team_invite_user_success', { totalEmails }));
        this.setState(
          { userSelects: [] },
          () => {
            if (this.props.afterAction) {
              this.props.afterAction();
            }
          },
        );
      });
  }

  handleSelectChange(event, value) {
    this.setState({ userSelects: value });
  }

  renderSelectUser() {
    const { userOrganizations, userTeams, userSelects } = this.state;
    const { isDisableEditInTeam, isNewOrganizationModal } = this.props;

    const userAdds = userOrganizations
      .filter((userOrganization) => !userTeams.find(({ id }) => userOrganization.id === id));
    const isTeamManager = MAuth.isTeamManager(this.teamId);
    return (
      <Card>
        <CardHeader>{isNewOrganizationModal ?  t('add_user_to_project') : t('add_user_header')}</CardHeader>
        <CardBody>
          <Row>
            <Col sm="12" md="8" lg="6" xl="5">
              <Form data-trackid="add-user-team" onSubmit={this.addUserTeam}>
                <FormGroup>
                  <Autocomplete
                    id="userEmail"
                    multiple
                    value={userSelects}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder={isNewOrganizationModal ?  t('add_user_to_project_placeholder') : t('add_user_to_team_placeholder')}
                      />
                    )}
                    options={userAdds}
                    getOptionLabel={(option) => option.email}
                    renderOption={(props, option) => (
                      <li {...props}>
                        <div className="user-avatar" title={option.email}>
                          <div className="avatar">
                            <ImageProfile
                              name={option.fullName}
                              imgUrl={option.avatar}
                              diameter={24}
                            />
                          </div>
                          {option.fullName}
                        </div>
                      </li>
                    )}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          variant="outlined"
                          avatar={(
                            <ImageProfile
                              name={option.fullName}
                              imgUrl={option.avatar}
                              diameter={20}
                            />
                          )}
                          label={option.fullName}
                          size="small"
                          title={option.email}
                          {...getTagProps({ index })}
                        />
                      ))}
                    onChange={this.handleSelectChange}
                    disabled={!isTeamManager}
                  />
                  <FormText color="muted">
                    {isNewOrganizationModal ? t('add_user_to_project_condition') : t('add_user_condition')}
                  </FormText>
                </FormGroup>
                <FormGroup>
                  <Button
                    type="submit"
                    color="primary"
                    disabled={userSelects.length === 0 || !isTeamManager || isDisableEditInTeam}
                  >
                    {t('add')}
                  </Button>
                </FormGroup>
              </Form>
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  }

  render() {
    const { userOrganizations, userTeams } = this.state;
    if (userOrganizations && userTeams) {
      return (
        <>
          {this.renderSelectUser()}
        </>
      );
    }
    return null;
  }
}

export default AddUserTab;
