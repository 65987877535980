import { Bar, CartesianGrid, ComposedChart, Label, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import React from 'react';
import PropTypes from 'prop-types';
import { kFormatter, timeFormatter } from '../utils';
import { tooltipFormatter, formatStatusLabel } from './utils';
import colors from '../../../../scss/colors.scss';
import { t } from '../../../i18n/t';
import { TestRunDailyOptions, TestRunStatus } from '../../../utils/Constants';

const TestRunDailyChart = ({ data, viewOption, customBarSize, intervalXAxis }) => {

  const customLegendPayload = [
    {
      dataKey: TestRunStatus.FAILED,
      color: colors.failed,
      value: TestRunStatus.FAILED,
      type: 'square',
    },
    {
      dataKey: TestRunStatus.PASSED,
      color: colors.passed,
      value: TestRunStatus.PASSED,
      type: 'square',
    }
  ];


  const isMaxDayOption = viewOption === TestRunDailyOptions.TWENTY_EIGHT_DAYS.value;
  return (
    <ResponsiveContainer minWidth={200} aspect={3.9}>
      <ComposedChart
        margin={{
          bottom: 10,
          top: 10,
          left: 10,
          right: 20,
        }}
        data={data}
      >
        <CartesianGrid vertical={false} />
        <XAxis
          tickFormatter={timeFormatter}
          dataKey="time"
          interval={intervalXAxis}
          angle={isMaxDayOption ? -30 : 0}
          textAnchor={isMaxDayOption ? 'end' : 'middle'}
        />
        <YAxis tickLine={false} tickFormatter={kFormatter}>
          <Label angle={-90} dx={-25} className="gray-axis-label">
            {t('number_of_test_runs')}
          </Label>
        </YAxis>
        <Tooltip formatter={tooltipFormatter} labelFormatter={timeFormatter} itemSorter={(item) => item.name.charCodeAt(0)} />
        <Legend align="center" verticalAlign="top" formatter={formatStatusLabel} payload={customLegendPayload} />
        <Bar barSize={customBarSize} stackId="status" dataKey="PASSED" fill={colors.passed} isAnimationActive={false} />
        <Bar barSize={customBarSize} stackId="status" dataKey="FAILED" fill={colors.failed} isAnimationActive={false} />
      </ComposedChart>
    </ResponsiveContainer>
  );
};

TestRunDailyChart.propTypes = {
  customBarSize: PropTypes.number,
  intervalXAxis: PropTypes.any,
};

TestRunDailyChart.defaultProps = {
  customBarSize: 16,
  intervalXAxis: 0,
};

export default TestRunDailyChart;
