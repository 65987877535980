import React from 'react';
import { Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import TableWithDashedBorder from '../../components/TableWithDashedBorder';
import Routes from '../../utils/Routes';
import { Execution } from '../../models/model/Execution';
import { ExecutionDecorator } from './ExecutionDecorator';

const styles = makeStyles({
  idSection: {
    display: 'flex',
    fontSize: '14px',
    fontWeight: '500',
    color: '#233145',
    marginBottom: '5px',
    '& svg': {
      width: '14px',
      height: '14px',
    },
  },
  detailSection: {
    marginTop: '7px',
    display: 'flex',
    fontSize: '12px',
    color: '#808b9a',
    alignItems: 'center',
    '& svg': {
      width: '14px',
      height: '14px',
    },
  },
  testRunHover: {
    '&:hover': {
      backgroundColor: '#F5F5F5',
      borderRadius: '4px',
      cursor: 'pointer',
    },
  },
  testRunRow: {
    display: 'flex',
    alignItems: 'center',
    padding: '7px 2px',
  },
  actionSection: {
    textAlign: 'right',
    width: '100%',
  },
});
interface ExecutionsSummaryProps {
  executions: Execution[] | undefined;
}

function ExecutionsSummary(props: ExecutionsSummaryProps) {
  const classes = styles();

  const { executions } = props;

  const handleSelectRow = (row: Execution) => {
    const constructedLink = new Routes({
      executionId: row.order,
      projectId: row.project.id,
    });
    window.open(constructedLink.execution_details_link, '_blank');
  };

  const renderData = (element: Execution) => (
    <Grid container className={classes.testRunRow}>
      <Grid className="d-flex mt-1 mb-1">
        {ExecutionDecorator.lineStatusDecorator(element)}
        <Grid>
          <Grid className={classes.idSection}>
            {ExecutionDecorator.statusDecorator(undefined, element, 'd-flex align-items-start mt-1 mr-1')}
            {ExecutionDecorator.nameV2Decorator(element, undefined, 50)}
          </Grid>
          <Grid className={classes.detailSection}>
            {ExecutionDecorator.idDecorator(element)}
            {ExecutionDecorator.startTimeDecorator(element)}
            {ExecutionDecorator.durationDecorator(element)}
            {ExecutionDecorator.osDecorator(element)}
            {ExecutionDecorator.browserDecorator(element)}
            {ExecutionDecorator.profileDecoratorV2(element)}
            {ExecutionDecorator.assigneeDecorator(element)}
          </Grid>
        </Grid>
      </Grid>
      {/* Hide in this version */}
      {/* <Grid xs={1} item className={classes.actionSection}>
        ...
      </Grid> */}
    </Grid>
  );

  return (
    <TableWithDashedBorder
      data={executions}
      handleSelectRow={handleSelectRow}
      chosenKey="order"
      renderData={renderData}
    />
  );
}

export default ExecutionsSummary;
