import React from 'react';
import ChartConstants from '../../../utils/ChartConstants';
import ColorUtils from '../../../utils/ColorUtils';
import Routes from '../../../utils/Routes';
import Link from '../../../components/Link';

class CustomizedTreemap extends React.Component {

  renderBody = () => {
    const { depth, x, y, width, height, failedTestCase, externalIssue, totalTestCase } = this.props;
    const { issueId, summary, featureName } = externalIssue || {};

    const failedRatio = (failedTestCase / totalTestCase).toFixed(2);
    const passedRatio = 1 - failedRatio;
    const colorIdx = Math.round(passedRatio * (ChartConstants.numberColor - 1));
    const statusColors = ColorUtils.colorScales(ChartConstants.statusColor, ChartConstants.numberColor);
    const labelColors = ColorUtils.colorScales(ChartConstants.labelTreeMapColor, ChartConstants.numberColor);

    return (
      <g>
        <rect
          x={x}
          y={y}
          width={width}
          height={height}
          style={{
            fill: statusColors[colorIdx],
            strokeWidth: 2 / (depth + 1e-10),
            strokeOpacity: 1 / (depth + 1e-10),
          }}
        />
        {
          depth === 1 ?
            <text
              className={`tree-map-label ${passedRatio === 1 ? 'label-passed' : 'label-failed'}`}
              x={x + 6}
              y={y + 20}
              style={{
                stroke: labelColors[colorIdx],
              }}
              fontSize={14}
              fontWeight={200}
            >
              {issueId} {summary} {featureName}
            </text>
            : null
        }
      </g>
    );
  }

  render() {
    const { externalIssue } = this.props;
    const { id } = externalIssue || {};
    const constructedLink = new Routes({
      requirementId: id
    });
    return (
      <Link href={constructedLink.requirement_details_link} className="tree-map-container">
        {this.renderBody()}
      </Link>
    );
  }
}

export default CustomizedTreemap;
