import React, { useState } from 'react';
import { Grid } from '@mui/material';
import { Button } from 'reactstrap';
import GroupEvent from '../../../utils/track/GroupEvent';
import {
  IconBadgeCheck,
  IconCalendar,
  IconCodeCommit,
  IconEmptyDataDrawer,
  IconFolderLocations,
  IconImpactedLocations,
  IconProfileCalendar,
  IconStatusCircleFailed,
  IconStatusCircleOpen,
  IconStatusCirclePassed,
  IconStatusNA,
  IconTestCaseFolder,
  IconTotalDefect,
  IconTotalPlatforms,
  IconTotalRunsFailed,
  IconTotalRunsPassed,
  IconTotalTestEnviroment } from '../../../images/CustomIcon';
import AIBlock from '../../../pages/project_dashboard_v2/components/AIBlock';
import ExecutionsSummary from '../../../pages/execution/ExecutionsSummary';
import MetricComponent from '../../../pages/project_dashboard_v2/components/MetricComponent';
import { ReleaseReadiness } from '../../../models/model/ReleaseReadiness';
import { t } from '../../../i18n/t';
import Metadata from '../../../pages/project_dashboard_v2/components/Metadata';
import { verticalDivider } from '../../../pages/execution/ExecutionDecorator';
import DecoratorConstants from '../../../utils/DecoratorConstants';
import Routes from '../../../utils/Routes';
import MContext from '../../../models/MContext';
import DefectsSummary from '../../../components/external_issue/DefectsSummary';
import ShowMoreBoxComponent from '../../../components/ShowMoreBoxComponent';
import { AIProfile } from '../../../models/model/AIProfile';
import TableWithDashedBorder from '../../../components/TableWithDashedBorder';
import { AIPlatform } from '../../../models/model/AIPlatform';
import { AIImpactedLocation } from '../../../models/model/AIImpactedLocation';
import { CollapseCardType } from '../../../utils/ConstantsType';
import DrawerAIResponse from '../../../components/DrawerAIResponse';
import { VIRTUAL_DATA_QUESTIONS } from '../../../utils/Constants';
import Text from '../../../utils/Text';
import DocumentLink from '../../../utils/DocumentLink';
import { sendAnalyticEventForAction } from '../../../utils/SegmentAnalytics';

interface DrawerAIReleaseReadinessProps {
  isOpen: boolean;
  closeDrawer: () => void;
}

function DrawerAIReleaseReadiness(props: DrawerAIReleaseReadinessProps) {
  const { isOpen, closeDrawer } = props;
  const [trigger, setTrigger] = useState(0);

  const buildReleaseData = (releaseReadiness: ReleaseReadiness, aiCompletions?: ReleaseReadiness | null): CollapseCardType[] => {
    const releaseHeader = () => {
      const releaseDateValue = DecoratorConstants.timeDecorator('endTime', releaseReadiness?.release) || 'N/A';
      const releaseDateTitle = <>Release Date: {releaseDateValue}</>;
      const developmentProgressValue = DecoratorConstants.formatPercentage(releaseReadiness?.releaseDevelopmentRatio);
      const developmentProgressTitle = `Development Progress: ${developmentProgressValue}`;
      const testingProgressValue = DecoratorConstants.formatPercentage(releaseReadiness?.releaseTestRatio);
      const testingProgressTitle = `Execution Status: ${testingProgressValue} Passed`;
      const iconRelease = DecoratorConstants.statusReleaseDecorator(releaseReadiness?.release.releaseStatus);
      return (
        <div>
          <MetricComponent
            icon={iconRelease}
            title={t('release')}
            label={<div className="metric-large-label">{releaseReadiness?.release.name}</div>}
            tooltip={t('ai-release-readiness-question-title')}
          />
          <div className="desc-text">
            <div className="d-flex mt-1">
              <Metadata
                icon={<IconCalendar />}
                text={releaseDateValue}
                tooltip={releaseDateTitle}
              />
              {verticalDivider()}
              <Metadata
                icon={<IconCodeCommit />}
                text={developmentProgressValue}
                tooltip={developmentProgressTitle}
              />
              {verticalDivider()}
              <Metadata
                icon={<IconBadgeCheck />}
                text={testingProgressValue}
                tooltip={testingProgressTitle}
              />
            </div>
            <div className="mt-1">
              {releaseReadiness?.release.description}
            </div>
          </div>
        </div>
      );
    };

    const testingProgressHeader = () => (
      <div className="d-flex">
        <MetricComponent
          icon={<IconStatusNA />}
          title={t('test-progress')}
          label={<div className="metric-normal-label">{DecoratorConstants.formatPercentage(releaseReadiness?.testProgress)}</div>}
        />
        {verticalDivider('6rem', '2.1rem')}
        <MetricComponent icon={<IconStatusCirclePassed />} title={t('passed-tests')} label={<div className="metric-normal-label">{releaseReadiness?.passedTests}</div>} />
        {verticalDivider('6rem', '2.1rem')}
        <MetricComponent icon={<IconStatusCircleFailed />} title={t('failed-tests')} label={<div className="metric-normal-label">{releaseReadiness?.failedTests}</div>} />
      </div>
    );

    const testingProgressContent = () => {
      const content =
        <>
          <strong className="execution-summary-title">
            {t('latest-run')}
          </strong>
          <ExecutionsSummary executions={releaseReadiness?.executions} />
        </>;
      const constructedLink = new Routes({
        projectId: MContext.projectId,
        releaseId: releaseReadiness?.release.id
      });
      const link = constructedLink.release_executions_details_link;
      const dataTrackId = 'ra_ai_show_more_test_runs_clicked';
      const dataTrackGroup = GroupEvent.VIRTUAL_DATA_ANALYST;
      return <ShowMoreBoxComponent content={content} link={link} dataTrackId={dataTrackId} dataTrackGroup={dataTrackGroup} />;
    };

    const defectsHeader = () => (
      <div className="d-flex">
        <MetricComponent
          icon={<IconTotalDefect />}
          title={t('table-header#total')}
          label={<div className="metric-normal-label">{releaseReadiness?.totalDefects}</div>}
        />
        {verticalDivider('6rem', '2.1rem')}
        <MetricComponent
          icon={<IconStatusCircleOpen />}
          title={t('defect-chart-summary#type-open')}
          label={<div className="metric-normal-label">{releaseReadiness?.openDefects}</div>}
        />
        {verticalDivider('6rem', '2.1rem')}
        <MetricComponent
          icon={<IconStatusCirclePassed />}
          title={t('defect-chart-summary#type-resolved')}
          label={<div className="metric-normal-label">{releaseReadiness?.resolvedDefects}</div>}
        />
      </div>
    );

    const defectsContent = () => {
      const content = <DefectsSummary externalIssues={releaseReadiness?.externalIssues} />;
      const constructedLink = new Routes({
        projectId: MContext.projectId,
      });
      const link = `${constructedLink.defects_link}?q=Release.id%3A${releaseReadiness?.release.id}`;
      const dataTrackId = 'ra_ai_show_more_open_defects_clicked';
      const dataTrackGroup = GroupEvent.VIRTUAL_DATA_ANALYST;
      return <ShowMoreBoxComponent content={content} link={link} dataTrackId={dataTrackId} dataTrackGroup={dataTrackGroup} />;
    };

    const testEnviromentHeader = () => (
      <div className="d-flex">
        <MetricComponent
          icon={<IconTotalTestEnviroment />}
          title={t('table-header#total')}
          label={<div className="metric-normal-label">{releaseReadiness?.totalProfiles}</div>}
        />
      </div>
    );

    const testEnviromentContent = () => {
      const renderData = (element: AIProfile) => {
        const payload = DecoratorConstants.statusBarItem(element.totalPassed, element.totalFailed);
        const title = t('test-case-execution');
        return (
          <Grid container className="app-card-list-row mt-1 mb-1">
            <Grid item xs={6} className="d-flex align-items-center">
              <span className="fixed-1rem-icon mr-2 d-flex align-items-center">
                <IconProfileCalendar />
              </span>
              {element.profile}
            </Grid>
            <Grid item xs={3} className="d-flex mt-1 mb-1 align-items-center">
              {DecoratorConstants.buildStatusBarChartWithTooltip(payload, title)}
            </Grid>
            <Grid item xs={3} className="d-flex mt-1 mb-1 align-items-center justify-content-start">
              <IconTotalRunsPassed className="mr-2" />
              {element.totalPassed}
              <IconTotalRunsFailed className="ml-2 mr-2" />
              {element.totalFailed}
            </Grid>
          </Grid>
        );
      };

      return (
        <TableWithDashedBorder
          renderData={renderData}
          data={releaseReadiness?.aiProfiles}
          chosenKey="profile"
        />
      );
    };

    const platformsHeader = () => (
      <div className="d-flex">
        <MetricComponent
          icon={<IconTotalPlatforms />}
          title={t('table-header#total')}
          label={<div className="metric-normal-label">{releaseReadiness?.totalPlatforms}</div>}
        />
      </div>
    );

    const platformsContent = () => {
      const renderData = (element: AIPlatform) => {
        const payload = DecoratorConstants.statusBarItem(element.totalPassed, element.totalFailed);
        const title = t('test-case-execution');
        return (
          <Grid container className="app-card-list-row mt-1 mb-1">
            <Grid item xs={6} className="d-flex align-items-center">
              {
                element.platform.osName &&
                  <span className="mr-2">
                    {DecoratorConstants.iconByOS(element.platform.osName)}
                  </span>
              }
              <span className="mr-2">
                {Text.platformName(element.platform)}
              </span>
              {
                element.platform.browserName &&
                  <span className="mr-2">
                    {DecoratorConstants.iconByBrowser(element.platform.browserName)}
                  </span>
              }
              {element.platform.browserVersion && ` ${element.platform.browserVersion}`}
            </Grid>
            <Grid item xs={3} className="d-flex mt-1 mb-1 align-items-center">
              {DecoratorConstants.buildStatusBarChartWithTooltip(payload, title)}
            </Grid>
            <Grid item xs={3} className="d-flex mt-1 mb-1 align-items-center justify-content-start">
              <IconTotalRunsPassed className="mr-2" />
              {element.totalPassed}
              <IconTotalRunsFailed className="ml-2 mr-2" />
              {element.totalFailed}
            </Grid>
          </Grid>
        );
      };

      return (
        <TableWithDashedBorder
          renderData={renderData}
          data={releaseReadiness?.aiPlatforms}
          chosenKey="platform"
        />
      );
    };

    const impactedLocationsHeader = () => (
      <div className="d-flex">
        <MetricComponent
          icon={<IconTestCaseFolder />}
          title={t('test_cases')}
          label={<div className="metric-normal-label">{releaseReadiness?.totalTestCases}</div>}
        />
        {verticalDivider('6rem', '2.1rem')}
        <MetricComponent
          icon={<IconFolderLocations />}
          title={t('folder-locations')}
          label={<div className="metric-normal-label">{releaseReadiness?.totalFolderLocations}</div>}
        />
      </div>
    );

    const impactedLocationsContent = () => {
      const renderData = (element: AIImpactedLocation) => {
        const payload = DecoratorConstants.statusBarItem(element.totalPassed, element.totalFailed, element.totalUnexecuted);
        const title = t('test-case-execution');
        return (
          <Grid container className="app-card-list-row mt-1 mb-1">
            <Grid item xs={6} className="d-flex align-items-center">
              <span className="fixed-1rem-icon mr-2 d-flex align-items-center">
                <IconImpactedLocations />
              </span>
              <span title={element.rawPath}>
                {DecoratorConstants.truncateRightStringDecorator(element.path, 25)}
              </span>
            </Grid>
            <Grid item xs={3} className="d-flex mt-1 mb-1 align-items-center">
              {DecoratorConstants.buildStatusBarChartWithTooltip(payload, title)}
            </Grid>
            <Grid item xs={3} className="d-flex mt-1 mb-1 align-items-center justify-content-start">
              <IconTotalRunsPassed className="mr-2" />
              {element.totalPassed}
              <IconTotalRunsFailed className="ml-2 mr-2" />
              {element.totalFailed}
            </Grid>
          </Grid>
        );
      };

      return (
        <TableWithDashedBorder
          renderData={renderData}
          data={releaseReadiness?.aiImpactedLocations}
          chosenKey="path"
        />
      );
    };

    const refresh = () => {
      setTrigger((trigger) => trigger + 1);
      sendAnalyticEventForAction(
        'ra_ai_refresh_ai_block_clicked',
        { 'data-groupid': GroupEvent.VIRTUAL_DATA_ANALYST, question: VIRTUAL_DATA_QUESTIONS.RELEASE_READINESS.dataTrackId }
      );
    };

    const releaseReadinessDrawerData = [
      {
        header: releaseHeader(),
        content: <AIBlock
          enableAi={releaseReadiness.enableAi}
          text={aiCompletions?.completions}
          refresh={refresh}
          questionId={VIRTUAL_DATA_QUESTIONS.RELEASE_READINESS.dataTrackId}
        />,
        disableCollapse: true,
        id: 'release',
      },
      {
        title: t('testing-progress'),
        header: testingProgressHeader(),
        content: testingProgressContent(),
        id: 'testing-progress',
        tooltip: t('testing-progress-tooltip'),
      },
      {
        title: t('impacted-locations'),
        header: impactedLocationsHeader(),
        content: impactedLocationsContent(),
        id: 'impacted-locations',
        tooltip: t('impacted-locations-tooltip'),
      },
      {
        title: t('open-defects'),
        header: defectsHeader(),
        content: defectsContent(),
        id: 'defects',
        tooltip: t('open-defects-tooltip'),
      },
      {
        title: t('execution-by-platforms'),
        header: platformsHeader(),
        content: platformsContent(),
        id: 'platforms',
        tooltip: t('execution-by-platforms-tooltip'),
      },
      {
        title: t('execution-by-test-environment'),
        header: testEnviromentHeader(),
        content: testEnviromentContent(),
        id: 'test-environment',
        tooltip: t('execution-by-test-environment-tooltip'),
      }
    ];

    return releaseReadinessDrawerData;
  };

  const emptyComponent = () => {
    const goToReleases = () => {
      const route = new Routes();
      window.open(route.releases_link, '_blank');
    };
    return (
      <div className="mt-3 empty-state-drawer">
        <div>
          <div className="d-flex justify-content-center">
            <IconEmptyDataDrawer />
          </div>
          <div className="d-flex justify-content-center empty-state-drawer-title">
            {t('no-release-title')}
          </div>
          <div className="d-flex justify-content-center empty-state-drawer-desc">
            {t('no-release-desc')}
          </div>
          <div className="d-flex justify-content-center mt-2 empty-state-drawer-action">
            <Button color="primary" className="mr-3" onClick={goToReleases}>{t('go-to-releases')}</Button>
            <Button color="secondary" onClick={() => { window.open(DocumentLink.INTRODUCTION_TO_TEST_PLANNING, '_blank'); }}>{t('learn_more')}</Button>
          </div>
        </div>
      </div>
    );
  };

  const checkEmpty = (releaseReadiness: ReleaseReadiness | null) => {
    if (releaseReadiness && releaseReadiness.release != null) {
      return false;
    }
    return true;
  };

  return (
    <DrawerAIResponse
      isOpen={isOpen}
      closeDrawer={closeDrawer}
      buildData={buildReleaseData}
      question={VIRTUAL_DATA_QUESTIONS.RELEASE_READINESS.value}
      promptType={VIRTUAL_DATA_QUESTIONS.RELEASE_READINESS.promptType}
      title={VIRTUAL_DATA_QUESTIONS.RELEASE_READINESS.title}
      checkEmpty={checkEmpty}
      emptyComponent={emptyComponent}
      trigger={trigger}
    />
  );
}

export default DrawerAIReleaseReadiness;
