import React from 'react';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import PropTypes from 'prop-types';
import TriangleExclamationIcon from '../../../images/icons/katalonui/TriangleExclamationIcon';
import WarningPackageAlertComponent from '../WarningPackageAlertComponent';
import { t } from '../../i18n/t';
import DocumentLink from '../../utils/DocumentLink';

function UnsupportedTypeBanner(props) {

  const { serverUrl } = props;

  const warningMessage = () => (
    <div>
      {t('cannot-create-unsupported-field-jira')}
      <div>
        <a
          href={DocumentLink.SUPPORTED_JIRA_FIELD_TYPES}
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('learn-jira-field')}
        </a>
      </div>
      <div>
        {t('suggestion')}:
      </div>
      <ul>
        <li>{t('go-to-jira-to-create')}{' '}
          <a href={serverUrl} target="_blank" rel="noopener noreferrer">{t('go-to-jira')} <OpenInNewIcon /></a>
        </li>
        <li>{t('then-go-back-to-link-test-result')}</li>
      </ul>
    </div>
  );

  return (
    <div className="mt-2">
      <WarningPackageAlertComponent
        text={warningMessage()}
        icon={<TriangleExclamationIcon type="fa-solid" color="#f0be0d" />}
        className="blocked-account-warning-content warning"
      />
    </div>
  );
}

UnsupportedTypeBanner.propTypes = {
  serverUrl: PropTypes.string,
};

export default UnsupportedTypeBanner;
