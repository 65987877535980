export const GUIDE_ME_FLOW_ID = '078aa778-d8ed-4ee8-b336-2c2b548c1ccd';
export const DEFAULT_TEST_CASE_PATH = 'test-cases';

export const CREATE_TEST_CASE_SOURCE = {
  MAIN_NAV: 'mainNav',
  TEST_EXPLORER: 'testExplorer',
};

export const MAX_LENGTH = 255;
export const URL_PLACEHOLDER = 'E.g: https://katalon-demo-cura.herokuapp.com';
export const DEFAULT_URL = 'https://katalon-demo-cura.herokuapp.com';
export const RECENT_URLS_KEY_SUFFIX = 'recentUrls';
export const RECENT_URLS_LIMIT = 5;
