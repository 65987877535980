import React from 'react';
import _ from 'lodash';
import http from '../../../utils/http';
import Services from '../../../utils/Services';
import { CacheStatus, INTERVAL_TIME_POST_DATA } from '../../../utils/Constants';
import Notification from '../../../utils/Notification';

class MTableData {

    url = '';

    data = [];

    totalPage = 10;

    currentPage = 0;

    pageSize = 1;

    totalItems = 0;

    pageRangeDisplayed = 5;

    marginPagesDisplayed = 4;

    breakLabel = <span>...</span>;

    onChange = null;

    cacheCreatedAt = '';

    constructor(url, dataFieldName, paginationMapping, pageSize, entityType) {
      this.url = url;
      this.paginationMapping = paginationMapping;
      this.dataFieldName = dataFieldName;
      this.pageSize = pageSize || 30;
      if (!entityType) {
        this.fetchData();
      }
    }

    fetchData(filter) {
      if (!(typeof this.url === 'string' && this.url.length !== 0)) {
        return;
      }
      const params = { page: this.currentPage, size: this.pageSize };
      if (filter && !filter.isEmpty) {
        filter.items.forEach((item) => {
          const param = params[item.name];
          if (param) {
            const p = _.isArray(param) ? param : [param];
            p.push(item.value);
            params[item.name] = p;
          } else {
            params[item.name] = item.value;
          }
        });
      }
      http.get(this.url, params)
        .then((promiseData) => {
          if (this.dataFieldName) {
            this.data = promiseData[this.dataFieldName];
          } else {
            this.data = promiseData;
          }
          const mapper = this.paginationMapping;
          this.totalPage = promiseData[mapper.totalPage];
          this.currentPage = promiseData[mapper.currentPage];
          this.pageSize = promiseData[mapper.itemsPerPage];
          this.totalItems = promiseData[mapper.totalItems] ? promiseData[mapper.totalItems] : this.data.length;
          this.onChange();
        });
    }

    buildPostData(filter, sorts, aggregation, groupBys, customFieldFilters) {
      const items = filter.items;
      const { entityType, defaultConditions, currentConditions } = items[items.length - 1];
      const { defaultFunctions, currentFunctions } = aggregation;

      const params = {
        type: entityType,
        conditions: defaultConditions.concat(currentConditions),
        ...(customFieldFilters && { custom_field_conditions: customFieldFilters }),
        functions: defaultFunctions.concat(currentFunctions),
        pagination: {
          page: this.currentPage,
          size: this.pageSize,
          sorts,
        },
        groupBys,
      };
      return params;
    }

    postData(filter, sorts, aggregation, groupBys, useCache, errorMessage, customFieldFilters) {
      const params = this.buildPostData(filter, sorts, aggregation, groupBys, customFieldFilters);
      if (useCache) {
        this.cleanUpData();
      }
      return Services.search(params, useCache)
        .then((promiseData) => {
          const { cacheStatus } = promiseData;
          if (cacheStatus === CacheStatus.PROCESSING) {
            setTimeout(() => {
              this.postData(filter, sorts, aggregation, groupBys, useCache, errorMessage, customFieldFilters);
            }, INTERVAL_TIME_POST_DATA);
          } else {
            if (cacheStatus === CacheStatus.ERROR) {
              this.cleanUpData();
              if (errorMessage) {
                Notification.pushError(errorMessage);
              }
            } else {
              if (this.dataFieldName) {
                this.data = promiseData[this.dataFieldName];
              } else {
                this.data = promiseData;
              }
              this.cacheCreatedAt = promiseData.cacheCreatedAt;
              const mapper = this.paginationMapping;
              this.totalPage = promiseData[mapper.totalPage];
              this.currentPage = promiseData[mapper.currentPage];

              /**
              * Override page size may not works well with case use cache since it will create a cache with
              * a different page size and use it while the page still use the old page size cache
              */
              if (!useCache) {
                this.pageSize = promiseData[mapper.itemsPerPage];
              }

              this.totalItems = promiseData[mapper.totalItems] ? promiseData[mapper.totalItems] : this.data.length;
            }
            this.onChange();
          }
        });
    }

    fetchAllPages(filter, sorts, aggregation, groupBys) {
      this.data = [];
      return this.fetchAllPagesRecursively(filter, sorts, aggregation, groupBys);
    }

    fetchAllPagesRecursively(filter, sorts, aggregation, groupBys) {
      const params = this.buildPostData(filter, sorts, aggregation, groupBys);
      return Services.search(params)
        .then((promiseData) => {
          if (this.dataFieldName) {
            this.data.push(...promiseData[this.dataFieldName]);
          } else {
            this.data.push(...promiseData);
          }
          const mapper = this.paginationMapping;
          this.totalPage = promiseData[mapper.totalPage];
          this.currentPage = promiseData[mapper.currentPage];
          this.pageSize = promiseData[mapper.itemsPerPage];
          this.totalItems = promiseData[mapper.totalItems] ? promiseData[mapper.totalItems] : this.data.length;
          if (this.currentPage < this.totalPage - 1) {
            this.currentPage += 1;
            this.fetchAllPagesRecursively(filter, sorts, aggregation, groupBys);
          } else {
            this.currentPage = 0;
            this.totalPage = 1;
            this.onChange();
          }
        });
    }

    cleanUpData() {
      this.totalItems = 0;
      this.data = [];
    }

    get isEmpty() {
      return this.numRow === 0;
    }

    get numRow() {
      return this.data.length;
    }

}

export default MTableData;
