import React from 'react';
import { Label } from 'reactstrap';
import { JiraIssueField } from '../../models/model/JiraIssueField';
import DatePickerInput from './DatePickerInput';
import TextFieldInput from './TextFieldInput';
import DropdownField from './DropdownField';
import { JiraIssueOption } from '../../models/model/JiraIssueOption';
import UnsupportedTypeBanner from './UnsupportedTypeBanner';
import { ExecutionTestResult } from '../../models/model/ExecutionTestResult';
import DecoratorConstants from '../../utils/DecoratorConstants';
import { t } from '../../i18n/t';

interface JiraComponentMapperProps {
  jiraComponents: JiraIssueField[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  updateJsonObject: (key: string, value: any) => void;
  executionTestResult: ExecutionTestResult;
  required?: boolean;
  serverUrl: string;
}

function JiraComponentMapper(props: JiraComponentMapperProps) {
  const {
    jiraComponents,
    updateJsonObject,
    executionTestResult,
    required,
    serverUrl
  } = props;

  const handleChangeDateTime = (id: string, value: string) => {
    updateJsonObject(id, value);
  };

  const handleChangeTextField = (id: string, value: string | number) => {
    updateJsonObject(id, value);
  };

  const handleChangeDropdown = (id: string, value: JiraIssueOption | null) => {
    updateJsonObject(id, value);
  };

  const handleChangeMultiSelect = (id: string, value: JiraIssueOption | JiraIssueOption[] | null) => {
    updateJsonObject(id, value);
  };

  const getDefaultValueTextField = (isDescription: boolean, executionTestResult: ExecutionTestResult, jiraDefaultValue: JiraIssueOption | undefined) => {
    if (!isDescription) {
      return jiraDefaultValue;
    }

    const errorMessagePart = executionTestResult.errorMessage ?
      DecoratorConstants.truncateLeftStringDecorator(executionTestResult.errorMessage, 200) :
      '';

    const testOpsDefaultValue = `${t('prefill-description-jira#test-result-link')}${executionTestResult.webUrl}` +
      `${t('prefill-description-jira#error-message')}${errorMessagePart}` +
      `${t('prefill-description-jira#expected-result-and-root-cause')}`;

    if (jiraDefaultValue) {
      return testOpsDefaultValue + jiraDefaultValue;
    } else {
      return testOpsDefaultValue;
    }
  };

  const renderTextFieldComponent = (jiraComponent: JiraIssueField, isNumber: boolean) => {
    const isDescription = jiraComponent.key === 'description';
    return (
      <TextFieldInput
        required={required && jiraComponent.required}
        placeholder={jiraComponent.name}
        id={jiraComponent.key}
        changeValueContributor={(key: string, value: string | number) => handleChangeTextField(key, value)}
        isTextArea={isDescription}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        defaultValue={getDefaultValueTextField(isDescription, executionTestResult, jiraComponent.defaultValue)}
        isNumber={isNumber}
      />
    );
  };

  const renderDropdownComponent = (jiraComponent: JiraIssueField, displayField: string, iconField?: string) => (
    <DropdownField
      required={required && jiraComponent.required}
      id={jiraComponent.key}
      placeholder={jiraComponent.name}
      displayField={displayField}
      iconField={iconField}
      changeValueContributor={(id: string, value: JiraIssueOption | null) => handleChangeDropdown(id, value)}
      allowedValues={jiraComponent.allowedValues}
      defaultValue={jiraComponent.defaultValue}
      autoCompleteUrl={jiraComponent.autoCompleteUrl}
    />
  );

  const renderDateTimeComponent = (jiraComponent: JiraIssueField, dateTime: boolean) => (
    <DatePickerInput
      required={required && jiraComponent.required}
      label={jiraComponent.name}
      id={jiraComponent.key}
      changeValueContributor={(key: string, value: string) => handleChangeDateTime(key, value)}
      useDateTime={dateTime}
      defaultValue={jiraComponent.defaultValue}
    />
  );

  const renderMultiSelectComponent = (jiraComponent: JiraIssueField, displayField: string) => (
    <DropdownField
      placeholder={jiraComponent.name}
      required={required && jiraComponent.required}
      id={jiraComponent.key}
      displayField={displayField}
      changeValueContributor={(key: string, value: JiraIssueOption | JiraIssueOption[] | null) => handleChangeMultiSelect(key, value)}
      defaultValue={jiraComponent.defaultValue}
      allowedValues={jiraComponent.allowedValues}
      autoCompleteUrl={jiraComponent.autoCompleteUrl}
      isMultiple
    />
  );

  const renderComponentOnItems = (jiraComponent: JiraIssueField) => {
    const { schema } = jiraComponent;
    switch (schema.items) {
      case 'string':
        return renderMultiSelectComponent(jiraComponent, 'label');
      case 'option':
        return renderMultiSelectComponent(jiraComponent, 'value');
      default:
        return null;
    }
  };

  const renderComponentOnSchema = (jiraComponent: JiraIssueField) => {
    const { schema } = jiraComponent;
    switch (schema.type) {
      case 'string':
        return renderTextFieldComponent(jiraComponent, false);
      case 'number':
        return renderTextFieldComponent(jiraComponent, true);
      case 'date':
        return renderDateTimeComponent(jiraComponent, false);
      case 'datetime':
        return renderDateTimeComponent(jiraComponent, true);
      case 'user':
        return renderDropdownComponent(jiraComponent, 'displayName', 'avatarUrls.48x48');
      case 'option':
        return renderDropdownComponent(jiraComponent, 'value');
      case 'issuetype':
        return renderDropdownComponent(jiraComponent, 'name', 'iconUrl');
      case 'project':
        return renderDropdownComponent(jiraComponent, 'name', 'avatarUrls.48x48');
      case 'priority':
        return renderDropdownComponent(jiraComponent, 'name', 'iconUrl');
      case 'array':
        return renderComponentOnItems(jiraComponent);
      default: // not render type: issuelink, any, array(items: user)
        return null;
    }
  };

  const renderContent = () => {
    let unsupportedEncountered = false;
    const components = [];

    for (const [key, jiraComponent] of Object.entries(jiraComponents)) {
      const component = renderComponentOnSchema(jiraComponent);
      // If a required field cannot render component, set unsupportedEncountered to true
      if (jiraComponent.required && component === null) {
        unsupportedEncountered = true;
        break;
      }

      components.push(
        <div key={key}>
          {component && (
            <div>
              <div>
                <Label className="mt-3 mr-1 small fw-bold">{jiraComponent.name}</Label>
                {jiraComponent.required && <span className="text-failed-color">*</span>}
              </div>
              {component}
            </div>
          )}
        </div>
      );
    }

    if (unsupportedEncountered) {
      return <UnsupportedTypeBanner serverUrl={serverUrl} />;
    }

    return (
      <div>
        {components}
      </div>
    );
  };

  return renderContent();
}

export default JiraComponentMapper;
