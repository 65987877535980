import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import React from 'react';
import DataLoader from '../../../components/table/DataLoader';
import { t } from '../../../i18n/t';
import { IconStatus } from '../../../images/KitIcons';
import DecoratorConstants from '../../../utils/DecoratorConstants';
import Routes from '../../../utils/Routes';

const renderPlan = (plan) => {
  const routes = new Routes({ runConfigurationId: plan.id });
  const secondary =
    <>
      <div>{DecoratorConstants.timeDecorator('latestJob.startTime', plan)}</div>
      <div>{DecoratorConstants.agentsDecorator('agents', plan, true)}</div>
    </>;
  return (
    <ListItem key={plan.id} button component="a" href={routes.run_configuration_link}>
      <ListItemIcon>
        {DecoratorConstants.statusDecorator('latestJob.status', plan) || <IconStatus className="invisible" />}
      </ListItemIcon>
      <ListItemText primary={plan.name} secondary={secondary} />
    </ListItem>
  );
};

const renderData = (data) => {
  if (data.length > 0) {
    return (
      <List>
        {data.map((plan) => renderPlan(plan))}
      </List>
    );
  } else {
    return (
      <div className="text-center">
        {t('table#empty-message')}
      </div>
    );
  }
};

const PlansCard = (props) => {
  const routes = new Routes();
  const footer =
    <div className="text-right">
      <a href={routes.grid_link}>View all</a>
    </div>;
  return (
    <DataLoader
      title={t('test-planning')}
      entityType="RunConfiguration"
      render={renderData}
      defaultSort={['createdAt,desc']}
      pageSize={5}
      renderFooter={footer}
      hidePaging
      {...props}
    />
  );
};

export default PlansCard;
