import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Button, Form, FormGroup, Label } from 'reactstrap';
import { ReactMultiEmail, isEmail } from 'react-multi-email';
import { t } from '../../../i18n/t';
import MContext from '../../../models/MContext';
import Notification from '../../../utils/Notification';
import Services from '../../../utils/Services';
import 'react-multi-email/style.css';
import OrganizationUsersProductAccess from './OrganizationUsersProductAccess';
import OrganizationUsersSsoConfiguration from './OrganizationUsersSsoConfiguration';

function OrganizationUsersInvitationForm({ organizationId, afterInvitation = () => null }) {
  const [emails, setEmails] = useState([]);
  const [disabled, setDisabled] = useState(!emails.length);

  const [assignableFeatures, setAssignableFeatures] = useState(null);
  const [featureMap, setFeatureMap] = useState({});
  const [activeSsoOptions, setActiveSsoOptions] = useState(new Set());

  useEffect(() => {
    Services.getAssignableUserOrganizationFeatures(null, organizationId)
      .then((features) => {
        if (features) {
          setAssignableFeatures(features);
        }
      });
  }, []);

  const isDisabled = () => emails.length <= 0 || (MContext.isSSOEnabledOrganization && activeSsoOptions.size <= 0);

  useEffect(() => {
    setDisabled(isDisabled());
  }, [emails]);

  useEffect(() => {
    setDisabled(isDisabled());
  }, [activeSsoOptions]);

  const onChange = (_emails) => {
    setEmails(_emails);
  };

  const onFeatureChange = (feature, featureMap) => {
    setFeatureMap(featureMap);
  };

  const onOptionChange = (option, activeOptions) => {
    setActiveSsoOptions(activeOptions);
  };

  const getLabel = (email, index, removeEmail) => (
    <div data-tag key={index}>
      {email}
      <span data-tag-handle onClick={() => removeEmail(index)}>
        ×
      </span>
    </div>
  );

  const onInviteFail = (message) => {
    Notification.pushError(message);
  };

  const invite = (e) => {
    e.preventDefault();
    setDisabled(true);

    const invitationAccessibility = {
      featureMap,
      ssoOptions: [...activeSsoOptions],
    };

    Services.inviteUserForTeam({
      organizationId,
      invitedUsers: emails,
    }, invitationAccessibility, onInviteFail)
      .then((listUserInvitation) => {
        const emailLength = listUserInvitation.length;
        const totalEmails = `${emailLength} ${emailLength > 1 ? 'emails' : 'email'}`;
        const infoForMessage = { totalEmails, organization: listUserInvitation[0].organization };
        if (afterInvitation) {
          afterInvitation(infoForMessage);
        } else {
          Notification.pushSuccess(t('organization_invite_user_success', infoForMessage));
        }
      })
      .finally(() => {
        setDisabled(false);
      });
    return null;
  };

  if (!assignableFeatures) {
    return null;
  }

  return (
    <Form data-trackid="invite-user" onSubmit={invite}>
      <FormGroup>
        <Label for="invitedUser">{t('emails')}</Label>
        <ReactMultiEmail
          placeholder={t('organization_invitation_email_placeholder')}
          emails={emails}
          onChange={(_emails) => onChange(_emails)}
          validateEmail={(email) => isEmail(email)}
          getLabel={(email, index, removeEmail) => getLabel(email, index, removeEmail)}
        />
      </FormGroup>
      <OrganizationUsersProductAccess
        assignableFeatures={assignableFeatures}
        onFeatureChange={onFeatureChange}
      />
      {MContext.isSSOEnabledOrganization && (
        <OrganizationUsersSsoConfiguration
          onOptionChange={onOptionChange}
        />
      )}
      <Button color="primary" type="submit" disabled={disabled}>{t('organization_invitation_button')}</Button>
    </Form>
  );
}

OrganizationUsersInvitationForm.propTypes = {
  organizationId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  afterInvitation: PropTypes.func,
};

export default OrganizationUsersInvitationForm;
