import React, { useState } from 'react';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';
import { IconVerticalEllipsis } from '../../images/CustomIcon';
import PopoverDropdownMenu from '../action/PopoverDropdownMenu';
import { t } from '../../i18n/t';
import CheckBox from '../../components/CheckBox';
import TooltipComponent from '../TooltipComponent';

const icon = (
  <TooltipComponent text={t('baseline-images#more')} placement="bottom-end">
    <div>
      <Button color="none" className="image-edit-button">
        <IconVerticalEllipsis />
      </Button>
    </div>
  </TooltipComponent>
);

function ImageThumbnail({
  imageUrl,
  imageProps,
  header,
  className,
  title,
  thumbnailUrl,
  mainButtons,
  dropDownItems,
  imageId,
  enableSelect,
  selected,
  onSelect,
  badge
}) {

  const [isSelected, setSelected] = useState(selected);

  const handleSelect = (e) => {
    e.stopPropagation();
    if (onSelect) {
      onSelect(e.target.checked, imageId);
    }
    setSelected(e.target.checked);
  };

  const hasButtonItems = mainButtons || dropDownItems;

  return (
    <div className={`thumbnail ${className}`}>
      <div
        className={`thumbnail__image d-flex justify-content-between ${!enableSelect ? 'flex-row-reverse' : ''}`}
        style={{ backgroundImage: `url("${thumbnailUrl || imageUrl}")` }}
        {...imageProps}
      >
        {enableSelect && <CheckBox className="ml-4 mt-2" type="checkbox" checked={isSelected} onClick={handleSelect} />}
        {badge && <div className="mr-4 mt-2 pt-1">{badge}</div>}
      </div>
      <div className="d-flex">
        <div className="thumbnail__header d-flex">
          <div className="d-flex align-items-center overflow-hidden" title={title}>
            {header}
          </div>
          {hasButtonItems && (
            <div className="vertical-ellipsis">
              {mainButtons && mainButtons.map((item) => item)}
              {dropDownItems && <PopoverDropdownMenu items={dropDownItems} icon={icon} />}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
ImageThumbnail.propTypes = {
  // array of direct buttons
  mainButtons: PropTypes.array,
  // array of drop down items
  dropDownItems: PropTypes.array,
  imageUrl: PropTypes.string,
  imageProps: PropTypes.object,
  header: PropTypes.node,
  className: PropTypes.string,
  title: PropTypes.string,
  thumbnailUrl: PropTypes.string,
  imageId: PropTypes.string,
  enableSelect: PropTypes.bool,
  selected: PropTypes.bool,
  onSelect: PropTypes.func,
  badge: PropTypes.node,
};
export default ImageThumbnail;
