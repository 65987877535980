import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import MFlags from '../models/MFlags';

class DefaultLayout extends React.Component {
  render() {
    const { renderHeader, renderBody, renderCustomBodyLayout, isHeaderVisible } = this.props;
    let headerClassName = '';
    if (MFlags.testRunHideHeaderEnable) {
      headerClassName = isHeaderVisible || isHeaderVisible === undefined ? 'transition-visible' : 'transition-hidden';
    }

    return (
      <>
        {renderHeader && (
          <div className={classnames('main-header', headerClassName)}>{renderHeader()}</div>
        )}
        {renderCustomBodyLayout
          ? renderCustomBodyLayout()
          : (
            <div className="main-body-wrapper">
              <div className="main-body">
                {renderBody && renderBody()}
              </div>
            </div>)}
      </>
    );
  }
}

DefaultLayout.propTypes = {
  renderHeader: PropTypes.func,
  renderBody: PropTypes.func,
  renderCustomBodyLayout: PropTypes.func,
  isHeaderVisible: PropTypes.bool,
};

DefaultLayout.defaultProps = {
  renderHeader: null,
  renderBody: null,
  renderCustomBodyLayout: null,
  isHeaderVisible: true,
};

export default DefaultLayout;
