import React, { Component } from 'react';
import { ResponsiveContainer, PieChart, Pie, Cell, Label, Tooltip } from 'recharts';
import PropTypes from 'prop-types';
import colors from '../../../scss/colors.scss';
import DecoratorConstants from '../../utils/DecoratorConstants';
import { IconStatus } from '../../images/KitIcons';
import { t } from '../../i18n/t';

class StatusDoughnutChart extends Component {

  constructor(props) {
    super(props);
    this.tooltipFormatter = this.tooltipFormatter.bind(this);
    this.customPieChartTooltip = this.customPieChartTooltip.bind(this);
  }

  getDiff(diff) {
    if (!diff) {
      return {};
    }
    let label;
    let className;
    let description = t('since-previous-period');
    const diffFloat = parseFloat(diff);
    if (diffFloat === 0.0) {
      label = '-';
      className = '';
      description = '';
    } else if (diffFloat < 0) {
      label = `↓ ${Math.abs(diffFloat)}%`;
      className = 'label-failed';
    } else {
      label = `↑ ${Math.abs(diffFloat)}%`;
      className = 'label-passed';
    }
    return { label, className, description };
  }

  tooltipFormatter(v, n, props) {
    const { value, diff } = props.payload.payload;
    if (diff) {
      const { label, className } = this.getDiff(diff);
      const text = label === '-' ? 'no changes' : label;
      return (
        <>
          <span className="font-weight-bold">{value}</span>
          <div>(<span className={className}>{text}</span> {t('since-previous-period')})</div>
        </>
      );
    } else return <span className="font-weight-bold">{value}</span>;
  }

  customPieChartTooltip(props) {
    const { payload } = props;
    if (payload && payload[0]) {
      const item = payload[0].payload;
      const { diff } = item;
      const { label, className } = this.getDiff(diff);
      const text = label === '-' ? 'no changes' : label;
      return (
        <div className="custom-chart-tooltip">
          <div className="d-flex align-items-center">
            <IconStatus
              className="mr-2 tooltip-icon-label"
              key={item.color}
              fill={item.fill}
              style={{
                width: 8,
                height: 8
              }}
            />
            <div className="d-flex flex-grow-1 justify-content-between">
              <div className="mr-1">
                {item.label}:
              </div>
              <div>
                {item.value}
              </div>
            </div>
          </div>
          {item.diff &&
            <div>
              (<span className={className}>{text}</span> {t('since-previous-period')})
            </div>}
        </div>
      );
    }
    return null;
  }

  render() {
    const { data, type, total, diff, minWidth, aspect, isShowDescription } = this.props;
    const { label, className, description } = this.getDiff(diff);
    return (
      <ResponsiveContainer
        aspect={aspect}
        width="95%"
        minWidth={minWidth}
        debounce={Number.MAX_VALUE}
      >
        <PieChart>
          <Pie
            data={data}
            innerRadius="70%"
            outerRadius="90%"
            dataKey="value"
            startAngle={90}
            endAngle={-270}
            isAnimationActive={false}
          >
            {data.map((entry) => <Cell name={entry.label} key={`cell-${entry.key}`} fill={colors[entry.color]} />)}
            {type && <Label className="chart-label__top" position="centerTop" value={DecoratorConstants.pluralize(type, total)} />}

            <Label className="counter__value-sup-small" position="center" value={total} key={`label-total-${total}`} />
            <Label className={`chart-label__bottom ${className}`} position="centerBottom" value={label} />
            {
              isShowDescription && <Label className="chart-label__description" position="centerBottom" value={description} />
            }
          </Pie>
          <Tooltip formatter={this.tooltipFormatter} content={this.customPieChartTooltip} />
        </PieChart>
      </ResponsiveContainer>
    );
  }
}

StatusDoughnutChart.propTypes = {
  /**
   * data: {type Array, description: The data of chart}
   * data = [
   *  {
   *    key: {type: string, description: The key of each sector's name.}
   *    value: {type: number, description: The key of each sector's value }
   *    label: {type: string, description:  The name of pie for display tooltip}
   *    color: {type: string, description: The color of pie, name of color in scss/colors  }
   *    diff: {type: number, description: The values of the differences between the current time and last week}
   *    }
   *]
   * Example:
   *  const data = [
   *   {
   *   key: '$passed-test-case',
   *   label: Statuses.PASSED.label,
   *   value: 300,
   *   color: "newPassed",
   *   diff: 30
   *   },
   *   {
   *   key: '$failed-test-case',
   *   label: Statuses.FAILED.label,
   *   value: 700,
   *   color:"newFailed",
   *   diff: 70,
   *   },
   *  ];
   */
  data: PropTypes.array,
  /**
   * type: {type: string, description: The name inside of chart}
   */
  type: PropTypes.string,
  /**
   * diff: {type: string, description: The values of the differences between the current time and last week.}
   */
  diff: PropTypes.string,
  /**
   * total: {type: number, description: The total value of chart}
   */
  total: PropTypes.number,
  /**
   * minWidth: {type: string, description: The minimum width of the container.}
   */
  minWidth: PropTypes.number,
  /**
   * aspect: {type: number. description: The gap ratio between the chart element}
   */
  aspect: PropTypes.number,
  /**
   * isShowDescription: {type: boolean, description: Active to show the description of chart.}
   */
  isShowDescription: PropTypes.bool
};

StatusDoughnutChart.defaultProps = {
  data: [],
  type: '',
  diff: '',
  total: 0,
  minWidth: 100,
  aspect: 1,
  isShowDescription: false
};

export default StatusDoughnutChart;
