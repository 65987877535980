export const getImageResolution = (url, rejectTimeout) => new Promise((resolve, reject) => {
  let timer = null;

  const img = new Image();

  img.addEventListener('load', () => {
    if (timer) { clearTimeout(timer); }

    resolve({ width: img.width, height: img.height });
  });

  img.addEventListener('error', (event) => {
    if (timer) { clearTimeout(timer); }

    // eslint-disable-next-line prefer-promise-reject-errors
    reject(`${event.type}: ${event.message}`);
  });

  img.src = url;

  if (rejectTimeout) {
    // eslint-disable-next-line prefer-promise-reject-errors
    timer = setTimeout(() => reject('Timeout exception'), rejectTimeout);
  }
});
