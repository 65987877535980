import React, { useEffect, useState } from 'react';
import { FormGroup, Label } from 'reactstrap';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { t } from '../../../i18n/t';
import Select from '../../Select';
import { MAX_PAGE_SIZE, SearchEntity } from '../../../utils/Constants';
import Services from '../../../utils/Services';
import MContext from '../../../models/MContext';
import { IconJiraRelease, IconJiraReleaseInactive } from '../../../images/CustomIcon';

function ReleaseVersionSelect(props) {
  const [releases, setReleases] = useState([]);
  const [releaseOptions, setReleaseOptions] = useState([]);
  const projectId = MContext.projectId;

  const { selectedRelease, defaultSearchConditions, handleAfterGetReleases } = props;

  const generateIcon = ({ externalRelease }) => {
    if (!isEmpty(externalRelease)) {
      return (
        <>
          <IconJiraReleaseInactive className="icon-inactive" />
          <IconJiraRelease className="icon-active" />
        </>
      );
    }
    return null;
  };

  const generateReleaseOptions = () => {
    const releaseOptions = releases.map((release) => ({
      ...release,
      value: release.id,
      label: release.name,
      rightIcon: generateIcon(release)
    }));
    setReleaseOptions(releaseOptions);
  };

  const handleRelease = () => {
    generateReleaseOptions();
    if (handleAfterGetReleases) {
      handleAfterGetReleases(releases);
    }
  };

  const getReleases = () => {
    const params = {
      pagination: {
        page: 0,
        size: MAX_PAGE_SIZE,
        sorts: ['endTime,asc'],
      },
      conditions: [
        {
          key: 'Project.id',
          operator: '=',
          value: projectId,
        },
        ...defaultSearchConditions,
      ],
      type: SearchEntity.Release,
    };
    Services.search(params)
      .then(({ content }) => setReleases(content));
  };

  useEffect(() => {
    getReleases();
  }, []);

  useEffect(() => {
    handleRelease();
    const releaseOption = releases.find((item) => item.id === selectedRelease?.id);
    if (releaseOption && props.onSelectRelease) {
      props.onSelectRelease(releaseOption);
    }
  }, [releases]);

  const selectRelease = (releaseOption) => {
    if (props.onSelectRelease) {
      props.onSelectRelease(releaseOption);
    }
  };

  return (
    <FormGroup>
      <Label for="releaseId">{t('release#version')}</Label>
      <Select
        data-trackid="release-version"
        id="releaseId"
        name="releaseId"
        onChange={selectRelease}
        value={selectedRelease?.id}
        options={releaseOptions}
        clearable
        isCustom
        {...props}
      />
    </FormGroup>
  );
}

ReleaseVersionSelect.propTypes = {
  defaultSearchConditions: PropTypes.array,
  selectedRelease: PropTypes.object,
  onSelectRelease: PropTypes.func,
};

ReleaseVersionSelect.defaultProps = {
  defaultSearchConditions: []
};

export default ReleaseVersionSelect;
