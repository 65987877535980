import React, { useEffect, useState } from 'react';
import { Button, Form, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { isEmail, ReactMultiEmail } from 'react-multi-email';
import { DialogActions, DialogContent, DialogTitle } from '@mui/material';
import DialogComponent from './dialog/DialogComponent';
import Service from '../utils/Services';
import Notification from '../utils/Notification';
import { IconShareLight, IconShare } from '../images/CustomIcon';
import { t } from '../i18n/t';
import MFlags from '../models/MFlags';
import ConfirmSharingReportDialog from './dialog/ConfirmSharingReportDialog';
import GroupEvent from '../utils/track/GroupEvent';

function SharingDialog({ executionId, executionOrder, isOpen = false }) {
  const [emails, setEmails] = useState([]);
  const [open, setOpen] = React.useState(isOpen);
  const [disabled, setDisabled] = useState(!emails.length);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const handleOpenConfirmDialog = (e) => {
    e.preventDefault();
    setOpenConfirmDialog(true);
  };

  const handleCloseConfirmDialog = () => {
    setOpenConfirmDialog(false);
  };

  const handleClick = () => {
    setOpen(!open);
    setOpenConfirmDialog(false); // to close the confirm dialog
  };

  const isDisabled = () => emails.length <= 0;

  useEffect(() => {
    setDisabled(isDisabled());
  }, [emails]);

  const getLabel = (email, index, removeEmail) => (
    <div data-tag key={index}>
      {email}
      <span data-tag-handle onClick={() => removeEmail(index)}>
        ×
      </span>
    </div>
  );

  const onChange = (_emails) => {
    setEmails(_emails);
  };

  const onShareFail = (message) => {
    Notification.pushError(message);
  };

  const shareReport = (e) => {
    e.preventDefault();
    setDisabled(true);

    Service.shareExecutionReport(executionId, emails, onShareFail)
      .then(() => {
        Notification.pushSuccess(t('execution_share_user_success', { executionOrder }));
      })
      .finally(() => {
        setDisabled(false);
        handleClick();
      });
  };

  const renderDialog = () => {
    return (
      <DialogComponent
        isOpen={open}
        maxWidth="sm"
      >
        <Form data-trackid="share-execution-report" onSubmit={handleOpenConfirmDialog} data-groupid={GroupEvent.ACCESS_REPORT}>
          <DialogTitle>{t('share-report')}</DialogTitle>
            <DialogContent>
              <ReactMultiEmail
                emails={emails}
                onChange={(_emails) => onChange(_emails)}
                validateEmail={(email) => isEmail(email)}
                getLabel={(email, index, removeEmail) => getLabel(email, index, removeEmail)}
              />
            </DialogContent>
            <DialogActions>
              <Button color="secondary" onClick={handleClick}>
                {t('cancel')}
              </Button>
              <Button type="submit" color="primary" disabled={disabled}>
                {t('share')}
              </Button>
            </DialogActions>
            <ConfirmSharingReportDialog
              id="confirm-share-email-dialog"
              isOpen={openConfirmDialog}
              handleClose={handleCloseConfirmDialog}
              handleConfirm={shareReport}
              emails={emails}
            />
        </Form>
      </DialogComponent>
    );
  };

  return (
    <>
      <Button
        color="secondary"
        variant="outlined"
        onClick={handleClick}
        title={t('share-reports')}
        data-trackid="open-share-execution-report"
        data-groupid={GroupEvent.ACCESS_REPORT}
      >
        <div className="icon-inactive">
          <IconShare className="m-0" />
        </div>
        <div className="icon-active">
          <IconShareLight className="m-0" />
        </div>
      </Button>
      {renderDialog()}
    </>
  );
}

export default SharingDialog;
