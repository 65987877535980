import React from 'react';
import MTableColumnDataMapping from '../../components/table/models/MTableColumnDataMapping';
import DecoratorConstants from '../../utils/DecoratorConstants';
import MContext from '../../models/MContext';
import DataTable from './DataTable';
import { t } from '../../i18n/t';

class CircleCIAgentDataTable extends React.Component {

  constructor(props) {
    super(props);
    this.teamId = MContext.teamId;
    this.projectId = MContext.projectId;
  }

  refreshData() {
    this.incidentTable.refreshData();
  }

  render() {
    const columnMapping = [
      new MTableColumnDataMapping(
        t('name'),
        'name',
        DecoratorConstants.circleCIAgentDetailDecorator,
      ),
      new MTableColumnDataMapping(
        t('url'),
        'url',
      ),
      new MTableColumnDataMapping(
        t('circle-ci-agent#project'),
        'project',
      ),
      new MTableColumnDataMapping(
        t('circle-ci-agent#branch'),
        'branch',
      ),
      new MTableColumnDataMapping(
        t('circle-ci-agent#vcs-type'),
        'vcsType',
      ),
    ];
    const newProps = {
      columnMapping,
      ...this.props,
    };
    return (
      <DataTable
        ref={(ref) => {
          this.incidentTable = ref;
        }}
        entityType="CircleCiAgent"
        tableId="CircleCIAgents"
        {...newProps}
      />
    );
  }
}

export default CircleCIAgentDataTable;
