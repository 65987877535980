import { t } from '../../../i18n/t';
import Time from '../../../utils/Moment';
import GroupEvent from '../../../utils/track/GroupEvent';
import Arrays from '../../../utils/Arrays';

export const COMPARISON_TYPE = {
  PIXEL: 'pixel',
  LAYOUT: 'layout',
  CONTENT: 'content'
};
export const IMAGE_TYPE = {
  BASELINE: 'baseline',
  CHECKPOINT: 'checkpoint'
};

export const CONTENT_ZONE_TYPE = {
  identicalZone: 'identicalZone',
  shiftedZone: 'shiftedZone',
  missingNewZone: 'missingNewZone'
};


export const ZONE_TYPE = {
  matchZone: 'matchZone',
  diffPartZone: 'diffPartZone',
  diffZone: 'diffZone',
  ignoringZone: 'ignoringZone'
};

export const JSON_ZONE_TYPE = {
  correctMatch: 'correct_match',
  templateMatch: 'template_match',
  nonMatch: 'non_match',
  diffMatch: 'diff_match'
};

export const JSON_CONTENT_ZONE_TYPE = {
  identical: 'identical',
  notExistInCheckpoint: 'not exist in checkpoint',
  notExistInBaseline: 'not exist in baseline',
  shift: 'shift'
};

export const checkpointMatchStatus = {
  missMatch: 'MISSMATCH',
  missing: 'MISSING',
  new: 'NEW',
  match: 'MATCH',
  analyzing: 'ANALYZING'
};

export const TRACK_COMPARE_ID = {
  pixel: 'keyes-pixel-compare',
  layout: 'keyes-layout-compare',
  content: 'keyes-content-compare'
};

// release date is 09/05/2022, the old data has not been ready yet, need to define a constant to compare to notify user
// if they see the old data or not
export const releaseDate = Time.convertToMoment(1652029200000);

export const ENGINE_STATUS = {
  ANALYZING: 'ANALYZING',
  SKIPPED: 'SKIPPED',
  DONE: 'DONE',
};

export const buildCompareOptions = ({ pixel, layout, content }, aiEnabled) => [
  {
    value: COMPARISON_TYPE.PIXEL,
    label: `${t(COMPARISON_TYPE.PIXEL)}${pixel === ENGINE_STATUS.ANALYZING ?
      ` ${t('visual-comparison#status@running')}` : ''}`,
    track: {
      id: TRACK_COMPARE_ID.pixel,
      groupId: GroupEvent.ACCESS_REPORT
    },
  },
  {
    value: COMPARISON_TYPE.LAYOUT,
    label: `${t(COMPARISON_TYPE.LAYOUT)}${layout === ENGINE_STATUS.ANALYZING ?
      ` ${t('visual-comparison#status@running')}` : ''}`,
    track: {
      id: TRACK_COMPARE_ID.layout,
      groupId: GroupEvent.ACCESS_REPORT
    },
  },
  ...Arrays.insertIf(aiEnabled, {
    value: COMPARISON_TYPE.CONTENT,
    label: `${t(COMPARISON_TYPE.CONTENT)}${content === ENGINE_STATUS.ANALYZING ?
      ` ${t('visual-comparison#status@running')}` : ''}`,
    track: {
      id: TRACK_COMPARE_ID.content,
      groupId: GroupEvent.ACCESS_REPORT
    },
  }),
];

export const CHECKPOINT_STATUS = {
  NEW: 'NEW',
  MISSING: 'MISSING',
  MATCH: 'MATCH',
  MISMATCH: 'MISMATCH',
  ANALYSING: 'ANALYSING'
};

export const LOADING_STATE_KEY = {
  IMAGE: 'image',
  DIFF: 'diff'
};

export const NUMBER_IMAGE_ALLOW_UPLOAD = 20;

/**
 * Max file size system allow user upload image for visual testing is 14MB
 */
export const MAX_FILE_SIZE_ALLOW_UPLOAD = 14;

export const VisualTestRunInitialData = {
  totalCheckpoints: '-',
  passedCheckpoints: '-',
  failedCheckpoints: '-',
  unresolvedCheckpoints: '-'
};
