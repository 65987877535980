import { Card } from '@mui/material';
import React from 'react';
import { Button, CardBody } from 'reactstrap';
import { buildDefaultFilter } from '../../components/search-query/FilterQueryHelper';
import { buildSearchCondition } from '../../components/search/SearchUtils';
import TabComponent from '../../components/TabComponent';
import DataTable from '../../components/table/DataTable';
import MTableColumnDataMapping from '../../components/table/models/MTableColumnDataMapping';
import { t } from '../../i18n/t';
import { IconDownload } from '../../images/CustomIcon';
import Apis from '../../utils/Apis';
import DecoratorConstants from '../../utils/DecoratorConstants';

class FileDetailsTab extends TabComponent {
  constructor(props) {
    super(props);
    this.executionId = props.executionId;

    this.meta.id = 'page-execution-details-file';
    this.meta.title = document.title;
  }

  render() {
    const headers = [
      new MTableColumnDataMapping(t('name'), 'name', (name, row) => {
        return (
          <>
            {DecoratorConstants.executionFileNameDecorator(name, row)}
          </>
        );
      }),
      new MTableColumnDataMapping(t('table-header#action'), 'id', (name, row) => {
        const file = row;
        return (
          <div>
            <Button
              data-trackid="download-execution-file"
              title="Download"
              color="link"
              href={file.url}
              target="_blank"
            >
              <IconDownload />
            </Button>
          </div>
        );
      },
      true),
    ];

    const filterOptions = {
      useSearchQuery: true,
      defaultFilter: buildDefaultFilter('FileHandle.name', '~', ''),
    };

    return (
      <>
        <DataTable
          columnMapping={headers}
          entityType="ExecutionFile"
          defaultSearchConditions={[
            buildSearchCondition('Execution.id', '=', this.executionId),
          ]}
          defaultSort={['fileName,asc']}
          {...filterOptions}
        />
      </>
    );
  }
}

export default FileDetailsTab;
