import _ from 'lodash';
import { t } from '../../../i18n/t';
import { durationFormatter, percentFormatter } from '../utils';

export const ViewModeType = {
  RATIO: 'RATIO',
  TOTAL: 'TOTAL',
};

export const legendFormatter = (name) => {
  if (name === 'passedTestResult') {
    return t('passedTestResult');
  } else if (name === 'failedTestResult') {
    return t('failedTestResult');
  } else if (name === 'errorTestResult') {
    return t('errorTestResult');
  } else if (name === 'incompleteTestResult') {
    return t('incompleteTestResult');
  } else if (name === 'skippedTestResult') {
    return t('skippedTestResult');
  } else if (name === 'passedTestCase') {
    return t('passedTestCase');
  } else if (name === 'failedTestCase') {
    return t('failedTestCase');
  } else if (name === 'totalDuration') {
    return t('totalDuration');
  }
  if (name === 'passedTestResultRatio') {
    return `% ${t('passedTestResult')}`;
  } else if (name === 'failedTestResultRatio') {
    return `% ${t('failedTestResult')}`;
  } else if (name === 'errorTestResultRatio') {
    return `% ${t('errorTestResult')}`;
  } else if (name === 'incompleteTestResultRatio') {
    return `% ${t('incompleteTestResult')}`;
  } else if (name === 'skippedTestResultRatio') {
    return `% ${t('skippedTestResult')}`;
  } else if (name === 'passedTestCaseRatio') {
    return `% ${t('passedTestCase')}`;
  } else if (name === 'failedTestCaseRatio') {
    return `% ${t('failedTestCase')}`;
  }
  return _.startCase(name);
};

export const tooltipFormatter = (value, name, { payload }) => {
  let formattedName = _.startCase(name);
  let formattedValue = value;
  if (name === 'passedTestResult') {
    formattedName = t('passedTestResult');
  } else if (name === 'failedTestResult') {
    formattedName = t('failedTestResult');
  } else if (name === 'errorTestResult') {
    formattedName = t('errorTestResult');
  } else if (name === 'incompleteTestResult') {
    formattedName = t('incompleteTestResult');
  } else if (name === 'skippedTestResult') {
    formattedName = t('skippedTestResult');
  } else if (name === 'passedTestCase') {
    formattedName = t('passedTestCase');
  } else if (name === 'failedTestCase') {
    formattedName = t('failedTestCase');
  } else if (name === 'totalDuration') {
    formattedName = t('totalDuration');
    formattedValue = durationFormatter(value);
  }

  if (name === 'passedTestResultRatio') {
    formattedName = `% ${t('passedTestResult')}`;
  } else if (name === 'failedTestResultRatio') {
    formattedName = `% ${t('failedTestResult')}`;
  } else if (name === 'errorTestResultRatio') {
    formattedName = `% ${t('errorTestResult')}`;
  } else if (name === 'incompleteTestResultRatio') {
    formattedName = `% ${t('incompleteTestResult')}`;
  } else if (name === 'skippedTestResultRatio') {
    formattedName = `% ${t('skippedTestResult')}`;
  } else if (name === 'passedTestCaseRatio') {
    formattedName = `% ${t('passedTestCase')}`;
  } else if (name === 'failedTestCaseRatio') {
    formattedName = `% ${t('failedTestCase')}`;
  }

  if (name && name.includes('Ratio')) {
    const baseName = name.substring(0, name.length - 'Ratio'.length);
    formattedValue = `${percentFormatter(value, 1)}% (${payload[baseName] || 0})`;
  }
  return [formattedValue, formattedName];
};
