import MContext from '../models/MContext';

enum SESSION_STORAGE_KEY {
  PAGE_HISTORY = 'pageHistory'
}

export default class SessionStorageHelper {

  static genKeyWithProject(key: string) {
    return `katalon_project_${MContext.projectId}_${key}`;
  }

  static get(key: string) {
    return window.sessionStorage.getItem(this.genKeyWithProject(key));
  }

  static save(key: string, value: string) {
    window.sessionStorage.setItem(this.genKeyWithProject(key), value);
  }

  static getPageHistory() {
    const pageHistory = this.get(SESSION_STORAGE_KEY.PAGE_HISTORY);
    if (pageHistory) {
      try {
        return JSON.parse(pageHistory);
      } catch (e) {
        return {};
      }
    }
    return {};
  }

  static savePageHistory(historyState: object) {
    this.save(SESSION_STORAGE_KEY.PAGE_HISTORY, JSON.stringify(historyState));
  }
}
