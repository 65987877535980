export default class InfoSidebar {
  static get isOpened() {
    if (!this.infoSidebar) return null;
    return this.infoSidebar.isOpened;
  }

  static setup(infoSidebar) {
    this.infoSidebar = infoSidebar;
    return this;
  }

  static setLinkTo(linkTo) {
    if (!this.infoSidebar) return this;
    this.infoSidebar.setLinkTo(linkTo);
    return this;
  }

  static setTitle(title) {
    if (!this.infoSidebar) return this;
    this.infoSidebar.setTitle(title);
    return this;
  }

  static setContent(content) {
    if (!this.infoSidebar) return this;
    this.infoSidebar.setContent(content);
    return this;
  }

  static show(title, content, linkTo, style) {
    if (!this.infoSidebar) return this;
    this.infoSidebar.show(title, content, linkTo, style);
    return this;
  }

  static open() {
    if (!this.infoSidebar) return this;
    this.infoSidebar.open();
    return this;
  }

  static close() {
    if (!this.infoSidebar) return this;
    this.infoSidebar.close();
    return this;
  }

  static closeAndClear() {
    if (!this.infoSidebar) return this;
    this.infoSidebar.closeAndClear();
    return this;
  }

  static onClose(callback) {
    if (!this.infoSidebar) return this;
    this.infoSidebar.onClose(callback);
    return this;
  }
}
