import React, { useState, useRef, useEffect } from 'react';
import { DialogActions, DialogContent, DialogContentText, Grid, ListItemButton, ListItemText } from '@mui/material';
import { Button, ButtonToolbar } from 'reactstrap';
import moment from 'moment';
import { find, values } from 'lodash';
import PropTypes from 'prop-types';
import { getEndTime, getStartTime } from '../utils';
import { t } from '../../../i18n/t';
import { maxDateInChart, TestRunDailyGroupByOptions, TestRunDailyOptionsV2, defaultGroupByTime } from '../../../utils/Constants';
import DateRangePickerComponent from '../../../components/DateRangePickerComponent';
import DropDownFilter from '../../../components/search-query/filter/DropDownFilter';
import Time from '../../../utils/Moment';
import CloseableDialogComponent from '../../../components/dialog/CloseableDialogComponent';
import { next } from '../../../utils/Count';
import MFlags from '../../../models/MFlags';

const PopperDateRangePickerFilter = (props) => {

  const { timeInterval, handleChangeTimeInterval, value, isShowPopup, handleChangeDateRange, isHideTimeInterval } = props;
  const defaultTimeRange = [getStartTime(TestRunDailyOptionsV2.SEVEN_DAYS.value, defaultGroupByTime), getEndTime(defaultGroupByTime)];
  // timeRange is a array of 2 elements [startDate, endDate]
  const [timeRange, setTimeRange] = useState(defaultTimeRange);
  let testRunDailyGroupByOptions = values(TestRunDailyGroupByOptions);
  if (!MFlags.testRunReportFilterEnhancementPhase2Enabled) {
    testRunDailyGroupByOptions = testRunDailyGroupByOptions.filter((element) => element.value !== 'quarter');
  }
  const buildLabel = (groupByTime, timeRangeLabel) => {
    const label = find(testRunDailyGroupByOptions, { value: groupByTime }).label;
    if (isHideTimeInterval) {
      return (
        <span className="font-weight-bold d-flex align-items-center">
          {timeRangeLabel}
        </span>
      );
    }
    return (
      <span className="font-weight-bold d-flex align-items-center">
        {label} <div className="ellipse-dot" /> {timeRangeLabel}
      </span>
    );
  };
  const [timeRangeLabel, setTimeRangeLabel] = useState(buildLabel(timeInterval, TestRunDailyOptionsV2.SEVEN_DAYS.label));
  const [isOpenWarningFilterDialog, setIsOpenWarningFilterDialog] = useState(false);
  const [keyRerender, setKeyRerender] = useState(next());
  const [groupByTime, setGroupByTime] = useState(timeInterval);

  const filterMenu = useRef();

  const handleChangeFilter = (value) => {
    setKeyRerender(next());
    setTimeRange([getStartTime(value, defaultGroupByTime), getEndTime(defaultGroupByTime)]);
  };

  const buildFromToTimeRange = (startTime, endTime) => `${startTime} to ${endTime}`;

  const handleCancel = () => {
    setTimeRange(defaultTimeRange);
    setTimeRangeLabel(buildLabel(TestRunDailyGroupByOptions.DAILY.value, TestRunDailyOptionsV2.SEVEN_DAYS.label));
    setGroupByTime(defaultGroupByTime);
    if (handleChangeTimeInterval) {
      handleChangeTimeInterval(defaultGroupByTime);
    }
    const endTimeDate = moment(defaultTimeRange[1], Time.DATE_FORMAT).add(1, 'day').format(Time.DATE_FORMAT);
    filterMenu.current.applyFilter(`>=${defaultTimeRange[0].format(Time.DATE_FORMAT)},<${endTimeDate}`);
    if (handleChangeDateRange) {
      handleChangeDateRange([defaultTimeRange[0].format(Time.DATE_FORMAT), endTimeDate]);
    }
  };

  useEffect(() => {
    if (!!timeRange && !value) {
      setTimeRange(defaultTimeRange);
      setTimeRangeLabel(buildLabel(TestRunDailyGroupByOptions.DAILY.value, TestRunDailyOptionsV2.SEVEN_DAYS.label));
      setGroupByTime(defaultGroupByTime);
      if (handleChangeTimeInterval) {
        handleChangeTimeInterval(defaultGroupByTime);
      }
    }
  }, [value]);

  const startTime = timeRange[0].format(Time.DATE_FORMAT);
  const endTime = !timeRange[1] ? startTime : timeRange[1].format(Time.DATE_FORMAT);

  const buildFilterValue = () => {
    // We have to plus 1 day to endTime because the current behavior start filtering from 0AM not 23:59PM
    const endTimeDate = moment(endTime, Time.DATE_FORMAT).add(1, 'day').format(Time.DATE_FORMAT);
    return `>=${startTime},<${endTimeDate}`;
  };

  const buttonLabel = () => timeRangeLabel;

  const options = {
    day: {
      disableOptions: [TestRunDailyOptionsV2.TWELVE_MONTHS.value],
      defaultOptions: TestRunDailyOptionsV2.SEVEN_DAYS.value,
    },
    week: {
      disableOptions: [TestRunDailyOptionsV2.SEVEN_DAYS.value],
      defaultOptions: TestRunDailyOptionsV2.THIRTY_DAYS.value,
    },
    month: {
      disableOptions: [TestRunDailyOptionsV2.SEVEN_DAYS.value, TestRunDailyOptionsV2.THIRTY_DAYS.value],
      defaultOptions: TestRunDailyOptionsV2.NINETY_DAYS.value,
    },
    quarter: {
      disableOptions: [TestRunDailyOptionsV2.SEVEN_DAYS.value, TestRunDailyOptionsV2.THIRTY_DAYS.value],
      defaultOptions: TestRunDailyOptionsV2.NINETY_DAYS.value,
    },
  };

  const isDisableOptions = (value) => {
    const option = options[groupByTime];
    if (isHideTimeInterval) {
      return false;
    }
    if (option.disableOptions.includes(value)) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    const option = options[groupByTime];
    handleChangeFilter(option.defaultOptions);
  }, [groupByTime]);

  const testRunDailyOptionsV2 = values(TestRunDailyOptionsV2);
  const calculateDate = (timeRange[1] || timeRange[0]).diff(timeRange[0], defaultGroupByTime);
  const option = testRunDailyOptionsV2.find((element) => element.value === calculateDate);
  const isCustomDateRange = !option || isDisableOptions(option.value);

  const applyAndFilter = () => {
    const filterValue = buildFilterValue();
    if (isCustomDateRange) {
      setTimeRangeLabel(buildLabel(groupByTime, buildFromToTimeRange(startTime, endTime)));
    } else {
      setTimeRangeLabel(buildLabel(groupByTime, option.label));
    }
    if (handleChangeTimeInterval) {
      handleChangeTimeInterval(groupByTime);
    }
    setIsOpenWarningFilterDialog(false);
    filterMenu.current.applyFilter(filterValue);
    if (handleChangeDateRange) {
      handleChangeDateRange(timeRange);
    }
  };

  const handleApplyAndFilter = () => {
    const isRenderWarningPopup = !MFlags.testPerformanceForReportAndAnalyticsEnabled && isShowPopup && (calculateDate > maxDateInChart);
    if (isRenderWarningPopup) {
      setIsOpenWarningFilterDialog(true);
    } else {
      applyAndFilter();
    }
  };

  const setReasonableValue = (newTimeRange) => {
    if (!newTimeRange[0]) {
      setTimeRange([getStartTime(TestRunDailyOptionsV2.SEVEN_DAYS.value, defaultGroupByTime), getEndTime(defaultGroupByTime)]);
    } else {
      setTimeRange(newTimeRange);
    }
  };

  const handleClose = () => {
    setIsOpenWarningFilterDialog(false);
  };

  const renderWarningFilterDialog = () => (
    <CloseableDialogComponent
      id="warning-filter-dialog"
      maxWidth="sm"
      isOpen={isOpenWarningFilterDialog}
      handleClose={handleClose}
      title={t('warning-filter-dialog#title')}
    >
      <DialogContent>
        <DialogContentText>
          <div className="title m-0 text-left">
            {t('warning-filter-dialog#first-line')}<strong>{t('warning-filter-dialog#over-mt-90-days')}</strong>.
            <br />
            {t('warning-filter-dialog#second-line')}
          </div>
        </DialogContentText>
      </DialogContent>
      <DialogActions className="popup-footer justify-content-end p-0">
        <ButtonToolbar>
          <Button
            id="button-warning-cancel"
            title={t('cancel')}
            color="secondary"
            onClick={handleClose}
          >
            {t('cancel')}
          </Button>
          <Button
            id="button-warning-ok"
            title={t('ok')}
            color="primary"
            onClick={applyAndFilter}
          >
            {t('ok')}
          </Button>
        </ButtonToolbar>
      </DialogActions>
    </CloseableDialogComponent>
  );

  return (
    <DropDownFilter buttonLabel={buttonLabel()} {...props} maxHeight="none" maxWidth="none" customClassName="pt-0" ref={filterMenu}>
      <Grid container className="popper-date-range-picker d-flex">
        {
          !isHideTimeInterval &&
          <Grid item className="popper-date-range-picker__list-item">
            {testRunDailyGroupByOptions.map((option) =>
              <ListItemButton key={option.value} onClick={() => setGroupByTime(option.value)} selected={option.value === groupByTime}>
                <ListItemText primary={option.label} />
              </ListItemButton>)}
          </Grid>
        }
        <Grid item className="popper-date-range-picker__list-item">
          {testRunDailyOptionsV2.map((option) =>
            <ListItemButton
              key={option.value}
              onClick={() => handleChangeFilter(option.value)}
              selected={calculateDate === option.value}
              disabled={isDisableOptions(option.value)}
            >
              <ListItemText primary={option.label} />
            </ListItemButton>)}
          {/* In case custom, when we click -> default state is current date */}
          <ListItemButton onClick={() => handleChangeFilter(0)} selected={isCustomDateRange}>
            <ListItemText primary={t('test-run-daily-options#custom')} />
          </ListItemButton>
        </Grid>
        <Grid item className="popper-date-range-picker__calender">
          <DateRangePickerComponent
            key={keyRerender}
            displayStaticWrapperAs="mobile"
            value={timeRange}
            onChange={(newValue) => {
              setReasonableValue(newValue);
            }}
            maxDate={moment()}
            renderInput={null}
          />
        </Grid>
      </Grid>
      <div className="d-flex justify-content-end mr-3 mt-2">
        <ButtonToolbar>
          <Button color="secondary" onClick={handleCancel}>{t('search-bar#clear')}</Button>
          <Button color="primary" onClick={handleApplyAndFilter}>{t('apply_filter')}</Button>
        </ButtonToolbar>
      </div>
      {renderWarningFilterDialog()}
    </DropDownFilter>
  );
};

PopperDateRangePickerFilter.propTypes = {
  timeInterval: PropTypes.string.isRequired,
  handleChangeTimeInterval: PropTypes.func,
  /**
   * Filter value
   */
  value: PropTypes.string,
  isShowPopup: PropTypes.bool,
  /**
   * Turn on to hide time interval and remove disable option
   */
  isHideTimeInterval: PropTypes.bool,
  handleChangeDateRange: PropTypes.func,
};

PopperDateRangePickerFilter.defaultProps = {
  value: '',
  isShowPopup: true,
  isHideTimeInterval: false,
};

export default PopperDateRangePickerFilter;
