import React, { useEffect, useState } from 'react';
import { DialogContent } from '@mui/material';
import { Button } from 'reactstrap';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Select from '../Select';
import CloseableDialogComponent from './CloseableDialogComponent';
import { IconKSBig, IconLinux, IconMac, IconREBig, IconWindows } from '../../images/CustomIcon';
import { t } from '../../i18n/t';
import { IconDownloadKSNavActive } from '../../images/CustomNewIcon';
import DocumentLink from '../../utils/DocumentLink';
import TooltipComponent from '../TooltipComponent';
import Helper from '../../utils/Helper';
import Apis from '../../utils/Apis';

const options = [
  { value: 'win_64', label: 'Windows 64bit', icon: <IconWindows /> },
  { value: 'win_32', label: 'Windows 32bit', icon: <IconWindows /> },
  { value: 'mac_64', label: 'macOS ', icon: <IconMac /> },
  { value: 'linux_64', label: 'Linux ', icon: <IconLinux /> }
];

export const KS_DOWNLOAD_TYPE = {
  KSE_STANDALONE: 'kse',
  RE: 're',
  KSE_PLATFORM: 'kse_pe'
};

const PRODUCT = {
  KS: 'KS',
  RE: 'RE'
};

const DOWNLOAD_KS_DIALOG_ID = 'download-katalon-studio-dialog';

const DownloadKSDialog = ({ isOpen, handleClose }) => {
  const [ksSeOs, setKsSeOs] = useState();
  const [reOs, setReOs] = useState();

  useEffect(() => {
    const platform = Helper.getCurrentPlatformForDownload();
    setKsSeOs(platform);
    setReOs(platform);
  }, []);

  const detectDownloadTrackId = (id) => {
    if (id === 'select-ks-se-os') {
      return 'download-ks-standalone';
    } else if (id === 'select-re-os') {
      return 'download-kre';
    }
    return '';
  };

  const renderButtons = (setOs, value, id, type) => {
    const dataTrackId = detectDownloadTrackId(id);
    return (
      <span>
        <div className="menu-button">
          <div className="select-os">
            <Select
              id={id}
              clearable={false}
              onChange={(option) => setOs(option.value)}
              value={value}
              options={options}
              hasOverflowParentDiaglog={document.getElementById(DOWNLOAD_KS_DIALOG_ID)}
              isCustom
            />
          </div>
          <Button
            href={Apis.downloadKS(value, type)}
            className="download-btn"
            variant="contained"
            color="primary"
            data-trackid={dataTrackId}
          >
            <IconDownloadKSNavActive />Download
          </Button>
        </div>
      </span>
    );
  };

  const renderDownloadItem = (product, title, logo, description) => {
    let buttons;
    if (product === PRODUCT.KS) {
      buttons = renderButtons(setKsSeOs, ksSeOs, 'select-ks-se-os', KS_DOWNLOAD_TYPE.KSE_STANDALONE);
    } else {
      buttons = renderButtons(setReOs, reOs, 'select-re-os', KS_DOWNLOAD_TYPE.RE);
    }

    return (
      <ListItem
        secondaryAction={buttons}
      >
        <ListItemAvatar className="logo">
          {logo}
        </ListItemAvatar>
        <ListItemText
          secondary={description}
          className="download-description"
        >
          <h4>
            {title}
          </h4>
        </ListItemText>
      </ListItem>
    );
  };

  const ksTitle = t('katalon_studio');
  const reTitle =
    <>
      {t('product_kre')}
      <TooltipComponent
        text={t('download-ks#re-tooltip')}
        placement="bottom-end"
      />
    </>;
  const trackCloseIcon = {
    'data-trackid': 'close-download-popup'
  };
  const ksDescription = t('download-ks#ks-description');

  return (
    <CloseableDialogComponent
      id={DOWNLOAD_KS_DIALOG_ID}
      isOpen={isOpen}
      title={t('download-ks#title_old')}
      handleClose={handleClose}
      maxWidth="md"
      className="download-ks-dialog"
      headerStyle="ml-3"
      disablePortal
      trackCloseIcon={trackCloseIcon}
    >
      <DialogContent className="download-ks-dialog">
        <List>
          {renderDownloadItem(PRODUCT.KS, ksTitle, <IconKSBig />, ksDescription)}
          {renderDownloadItem(PRODUCT.RE, reTitle, <IconREBig />, t('download-ks#re-description-old'))}
        </List>
        <div className="dialog-footer">
          By downloading, you agree to&nbsp;
          <a target="_blank" rel="noopener noreferrer" href={DocumentLink.KATALON_TERM}>Katalon Terms</a> and&nbsp;
          <a target="_blank" rel="noopener noreferrer" href={DocumentLink.PRIVACY_POLICY}>Privacy Policy</a>
        </div>
      </DialogContent>
    </CloseableDialogComponent>
  );
};

export default DownloadKSDialog;
