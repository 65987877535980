export const SearchOperator = {
  NOT_EQUALITY: '!=',
  LESS: '<',
  LESS_OR_EQUAL: '<=',
  EQUALITY: '=',
  GREATER: '>',
  GREATER_OR_EQUAL: '>=',
  GREATER_OR_EQUAL_OR_NULL: '>=*',
  EQUAL_OR_NULL: '=*',
};
