import React from 'react';
import DecoratorConstants from '../../utils/DecoratorConstants';
import { buildSearchCondition } from '../../components/search/SearchUtils';
import MTableColumnDataMapping from '../../components/table/models/MTableColumnDataMapping';
import { t } from '../../i18n/t';
import DataTable from '../../components/table/DataTable';
import Routes from '../../utils/Routes';

export const JobExecutionResult = (props) => {
  const { executionId } = props;
  const columnMapping = [
    new MTableColumnDataMapping(
      t('status'),
      'status',
      DecoratorConstants.statusDecorator,
      undefined,
      'fit-content-column',
    ),
    new MTableColumnDataMapping(
      t('id'),
      'order',
      (name, row) => {
        const constructedLink = new Routes({
          projectId: row.projectId,
          runConfigurationId: row.runConfiguration.id,
          jobOrder: row.order,
        });
        return DecoratorConstants.idDecorator(name, row, constructedLink.job_link);
      },
      undefined,
      'fit-content-column',
    ),
    new MTableColumnDataMapping(
      t('test-project'),
      'testProject',
      DecoratorConstants.testProjectDecorator
    ),
    new MTableColumnDataMapping(
      t('agent'),
      'agent',
      DecoratorConstants.testEnvironmentDecorator
    ),
    new MTableColumnDataMapping(
      t('startTime'),
      'startTime',
      DecoratorConstants.timeDecorator,
    ),
    new MTableColumnDataMapping(
      t('duration'),
      'duration',
      DecoratorConstants.durationDecorator,
    )
  ];

  return (
    <DataTable
      entityType="Job"
      defaultSearchConditions={[
        buildSearchCondition('Execution.id', '=', executionId),
      ]}
      columnMapping={columnMapping}
      {...props}
    />
  );
};
