import React from 'react';
import { Alert, Button, CardBody, CardHeader, Form, FormGroup } from 'reactstrap';
import Input from '../Input';
import { t } from '../../i18n/t';
import RouteConstants from '../../utils/RouteConstants';
import Services from '../../utils/Services';
import logo from '../../../images/TestOps_logo_32px@2x.png';

export default class AdminSignUp extends React.Component {
  constructor(props) {
    super(props);

    this.submit = this.submit.bind(this);
    this.change = this.change.bind(this);
    this.showErrorAlert = this.showErrorAlert.bind(this);

    this.state = {
      showAlert: false,
      errorMessage: '',
      user: {
        email: '',
        password: '',
        confirmPassword: '',
      }
    };
  }

  showErrorAlert(message) {
    this.setState({
      showAlert: true,
      errorMessage: message,
    });
  }

  hideErrorAlert() {
    this.setState({
      showAlert: false,
      errorMessage: '',
    });
  }


  change(event) {
    const name = event.target.name;
    const value = event.target.value;
    const { user } = this.state;
    user[name] = value;
    this.setState(user);
  }

  validatePassword() {
    const { password, confirmPassword } = this.state.user;
    if (password !== confirmPassword) {
      this.showErrorAlert('Password confirmation doesn\'t match the new password');
      return false;
    } else return true;
  }

  submit(e) {
    this.hideErrorAlert();
    e.preventDefault();
    const { user } = this.state;
    if (this.validatePassword()) {
      Services.signUp(user, this.showErrorAlert)
        .then(() => {
          window.location.href = RouteConstants.login;
        });
    }
  }


  render() {
    const { showAlert, errorMessage } = this.state;
    return (
      <>
        <CardHeader>
          <div className="login-test-opts-logo mb-5">
            <img src={logo} alt="Test Opts Logo" />
          </div>
          <div className="login-tab-header">
            <div className="tab-button active">{t('startup#create_account')}</div>
          </div>
        </CardHeader>
        <CardBody>
          <Form className="login-form m-0" data-trackid="admin-signup" onSubmit={this.submit}>
            <FormGroup className="welcome-message">
              {/* <h2>{t('login#welcome')}</h2> */}
            </FormGroup>
            <FormGroup>
              <Input
                required
                type="email"
                name="email"
                placeholder={t('startup#email')}
                onChange={this.change}
              />
            </FormGroup>
            <FormGroup>
              <Input
                required
                type="password"
                name="password"
                placeholder={t('startup#password')}
                onChange={this.change}
              />
            </FormGroup>
            <FormGroup>
              <Input
                required
                type="password"
                name="confirmPassword"
                placeholder={t('startup#confirm_password')}
                onChange={this.change}
              />
            </FormGroup>
            <FormGroup>
              <Alert isOpen={showAlert} color="danger">
                {errorMessage}
              </Alert>
            </FormGroup>
            <FormGroup>
              <Button type="submit" color="primary" className="px-4">
                {t('startup#create_account_save')}
              </Button>
            </FormGroup>
          </Form>
        </CardBody>
      </>
    );
  }
}
