import React from 'react';
import DefaultLayout from '../../components/DefaultLayout';
import PageComponent from '../../components/PageComponent';
import ObjectSummary from '../../components/summary/ObjectSummary';
import KatalonRecorderBackupDataTable from '../../components/table/KatalonRecorderBackupDataTable';
import { t } from '../../i18n/t';

class KatalonRecorderBackup extends PageComponent {
  constructor(props) {
    super(props);
    this.renderHeader = this.renderHeader.bind(this);
    this.renderBody = this.renderBody.bind(this);
    this.meta.id = 'page-katalon-recorder-backup';
    this.meta.title = t('katalon_recorder_backup');
  }

  renderHeader() {
    const urlParams = {
      katalonRecorderBackup: t('katalon_recorder_backup'),
    };
    return (
      <ObjectSummary params={urlParams} />
    );
  }

  renderBody() {
    return (
      <KatalonRecorderBackupDataTable />
    );
  }

  render() {
    return (
      <DefaultLayout
        renderHeader={this.renderHeader}
        renderBody={this.renderBody}
      />
    );
  }
}

export default KatalonRecorderBackup;
