import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import Select from './Select';
import {
  GitTestProjectVCSType,
  MAX_PAGE_SIZE,
  SearchEntity,
  SUPPORTED_TEST_PROJECT_TYPE,
  TestProjectType,
} from '../utils/Constants';
import { buildSearchCondition } from './search/SearchUtils';
import Services from '../utils/Services';
import DecoratorConstants from '../utils/DecoratorConstants';
import MFlags from '../models/MFlags';

const SelectGitTestProject = ({ testProject, selectTestProject }) => {
  const [testProjectOptions, setTestProjectOptions] = useState([]);

  const onSelectTestProject = (testProject) => {
    selectTestProject(testProject);
  };

  const generateTestProjectOptions = (testProjects) => {
    const testProjectOptions = testProjects.map((testProject) => ({
      ...testProject,
      value: testProject.id,
      icon: DecoratorConstants.codeRepoType('type', testProject),
      selectedIcon: DecoratorConstants.codeRepoTypeSelected('type', testProject),
      label: testProject.name,
      type: testProject.type,
      divider: testProjects.length > 1 && testProject.type === TestProjectType.CLOUD
    }));
    setTestProjectOptions(testProjectOptions);

    if (!isEmpty(testProjectOptions)) {
      const testProject = testProjectOptions[0];
      selectTestProject(testProject);
    }
  };

  const oldGetTestProjects = () => {
    const params = {
      pagination: {
        page: 0,
        size: MAX_PAGE_SIZE,
        sorts: ['id,desc']
      },
      conditions: [
        buildSearchCondition('GitTestProject.vcsType', '=', GitTestProjectVCSType.GITHUB),
        buildSearchCondition('type', '=', TestProjectType.GIT)
      ],
      type: SearchEntity.TestProject,
    };
    return Services.searchRecursively(0, params)
      .then((testProjects) => generateTestProjectOptions(testProjects));
  };

  const getTestProjects = () => {
    const params = {
      pagination: {
        page: 0,
        size: MAX_PAGE_SIZE,
        sorts: ['type, desc', 'id,desc']
      },
      conditions: [
        buildSearchCondition('type', 'in', SUPPORTED_TEST_PROJECT_TYPE.toString())
      ],
      type: SearchEntity.TestProject,
    };
    return Services.searchRecursively(0, params)
      .then((testProjects) => {
        // API get all TestProject type GIT, CLOUD
        // Filter test project that have vcsType is GITHUB or type CLOUD
        testProjects.filter((testProject) =>
          testProject.gitRepository == null || testProject.gitRepository.vcsType === GitTestProjectVCSType.GITHUB);
        return generateTestProjectOptions(testProjects);
      });
  };

  useEffect(() => {
    if (MFlags.onCloudTestStorageEnabled) {
      getTestProjects();
    } else {
      oldGetTestProjects();
    }
  }, []);

  const renderSelectRepository = () => (
    <Select
      id="testProjectId"
      name="testProjectId"
      required
      clearable={false}
      onChange={onSelectTestProject}
      value={testProject.id}
      options={testProjectOptions}
      isCustom
    />
  );

  return renderSelectRepository();
};

SelectGitTestProject.propTypes = {
  testProject: PropTypes.object.isRequired,
  selectTestProject: PropTypes.func.isRequired
};

export default SelectGitTestProject;
