import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import { isEmpty } from 'lodash';
import {
  ACTIONS_TRACKING,
  KATALON_EVENTS,
  MAX_PAGE_SIZE,
  SearchEntity,
  TEST_SUITE_DESTINATION,
  TestSuiteType,
  KATALON_STUDIO_SUPPORTED_TEST_PROJECT_TYPE,
} from '../../../utils/Constants';
import { DomEventHandlers } from '../../../utils/EventHandler';
import { TestCase } from '../../../models/model/TestCase';
import { t } from '../../../i18n/t';
import { TestSuite } from '../../../models/model/TestSuite';
import ActionsHelper from '../../../utils/table/ActionsHelper';
import AddToTestSuiteView from './AddToTestSuiteView';
import AddNewTestSuiteView from '../testsuite/AddNewTestSuiteView';
import Notification from '../../../utils/Notification';
import TestSuiteService from '../service/TestSuiteService';
import TestObjectPublisher from '../service/TestObjectPublisher';
import NotificationHandler from '../handler/NotificationHandler';
import { TestProjectType } from '../../../models/model/TestProjectType';
import Services from '../../../utils/Services';
import { buildSearchCondition } from '../../search/SearchUtils';
import Routes from '../../../utils/Routes';
import { TestType } from '../../../models/model/TestType';
import { AppHelper } from '../../../utils/AppHelper';

function AddToTestSuiteDialog() {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [testCases, setTestCases] = useState<TestCase[]>([]);
  const [testSuite, setTestSuite] = useState<TestSuite | undefined>(undefined);
  const [switchToAddTestSuite, setSwitchToAddTestSuite] = useState<boolean>(false);
  const [displayedPageTracking, setDisplayedPageTracking] = useState<string>('');
  const [newTestSuiteName, setNewTestSuiteName] = useState<string>('');
  const [newTestSuiteLocation, setNewTestSuiteLocation] = useState<string>('');
  const [operationType, setOperationType] = useState<string>('');

  useEffect(() => {
    DomEventHandlers.eventListener(KATALON_EVENTS.openAddToTestSuiteDialog, (e: any) => {
      setIsOpen(true);
      if (e.detail.testCases) {
        setTestCases(e.detail.testCases);
      } else if (e.detail.testCase) {
        setTestCases([e.detail.testCase]);
      }
      setDisplayedPageTracking(e.detail.displayedPageTracking);
      setOperationType(e.detail.operationType);
    });
  }, []);

  const firstTestCase = testCases[0];
  if (isEmpty(testCases)) {
    return null;
  }
  const testProject = firstTestCase.testProject || null;
  if (!testProject) {
    return null;
  }

  const getTestSuiteTestCase = (testSuiteId: number) => {
    const params = {
      pagination: {
        page: 0,
        size: MAX_PAGE_SIZE,
        sorts: ['sortOrder, asc NULLS LAST'],
      },
      conditions: [
        buildSearchCondition('TestSuite.id', '=', testSuiteId)
      ],
      type: SearchEntity.TestSuiteTestCase,
    };
    return Services.searchRecursively(0, params, []);
  };

  const clearAllState = () => {
    setTestCases([]);
    setTestSuite(undefined);
    setNewTestSuiteName('');
    setNewTestSuiteLocation('');
    setSwitchToAddTestSuite(false);
    setDisplayedPageTracking('');
    setOperationType('');
  };

  const handleClose = () => {
    setIsOpen(false);
    clearAllState();
  };

  const fillIndexForLinkingTestCases = (listTestCases: any[]) => {
    listTestCases.forEach((row, index) => {
      row.row_number = index + 1;
    });
  };

  const publishTestProjectErrorHandler: any = (message: string, _label: string, jqXHR: any) =>
    NotificationHandler.publishTestProjectErrorHandler(message, jqXHR);

  const handleAfterCreateTestSuite = (id: number, name: string) => {
    AppHelper.closeCustomBlockedUI();
    const route = new Routes({ testSuiteId: id });
    const formattedMessage =
      `<div>
        ${t('add-to-ts-successfully', { testCaseName: firstTestCase.name, testSuiteName: name })}
        </br>
        <a href=${route.execution_test_suite_history_link}>${t('view-details')}</a>
      </div>`;
    const formattedMessageForBulk =
      `<div>
        ${t('bulk-operations#add-success', { testCaseQuantity: testCases.length, testSuiteName: name })}
        </br>
        <a href=${route.execution_test_suite_history_link}>${t('view-details')}</a>
      </div>`;
    const message = testCases.length === 1 ? formattedMessage : formattedMessageForBulk;
    Notification.pushSuccess(
      message,
      t('add-successfully'),
    );
    handleClose();
  };

  const addTestCasesToTestSuiteCloud = (
    testSuiteName: string,
    testSuitePath: string,
    testSuiteType: string,
    linkedTestCases: TestCase[],
    testSuiteId?: number
  ) => {
    fillIndexForLinkingTestCases(linkedTestCases);
    const publishTestSuite = TestSuiteService.buildPublishTestSuite(
      testSuiteId ?? null,
      testSuiteName,
      testSuitePath,
      testSuiteType,
      linkedTestCases
    );
    TestObjectPublisher.publishTestObject(
      testProject.id,
      [],
      [publishTestSuite],
      '',
      publishTestProjectErrorHandler
    ).then((publishedTestProject) => {
      const publishedTestSuite = publishedTestProject.testSuites[0];
      handleAfterCreateTestSuite(publishedTestSuite.id, publishedTestSuite.name);
    }).catch(() => {
      // ignore
    });
  };

  const addTestCasesToTestSuiteTestOps = (testSuiteId: number, updatedTestSuite: { testCases: TestCase[]; name: string }) => {
    Services.editTestSuite(testSuiteId, updatedTestSuite).then(() => {
      handleAfterCreateTestSuite(testSuiteId, updatedTestSuite.name);
    });
  };

  const addTestCasesToNewTestSuiteTestOps = (testSuiteName: string, testProjectId: number, testCases: TestCase[]) => {
    const testSuite = {
      name: testSuiteName,
      testProject: {
        id: testProjectId,
      },
      testCases,
    };
    Services.createTestSuite(testSuite)
      .then((res) => handleAfterCreateTestSuite(res.id, res.name));
  };

  const detectSuitableTestSuiteType = (testCaseType: TestType | undefined) => {
    if (!testCaseType || testCaseType === TestType.MANUAL_TEST_CASE || testCaseType === TestType.G4_TEST_CASE) {
      return TestSuiteType.TESTOPS;
    } else {
      return TestSuiteType.CLOUD_STUDIO;
    }
  };

  const handleAddToTestSuite = () => {
    ActionsHelper.syncTrackingData(
      { testCase: firstTestCase },
      ACTIONS_TRACKING.TEST_CASE_ADDED_TO_TEST_SUITE,
      displayedPageTracking,
      TEST_SUITE_DESTINATION.EXISTING,
      operationType
    );
    AppHelper.openCustomBlockedUI();
    if (!testSuite) {
      return;
    }
    getTestSuiteTestCase(testSuite.id)
      .then((content: any[]) => {
        const existingTestCases: TestCase[] = [];
        content.forEach((testSuiteTestCase, index) => {
          testSuiteTestCase.testCase.row_number = index + 1;
          existingTestCases.push(testSuiteTestCase.testCase);
        });
        const linkedTestCases = [...existingTestCases, ...testCases];
        if (testProject.type === TestProjectType.CLOUD) {
          addTestCasesToTestSuiteCloud(
            testSuite.name,
            testSuite.path,
            TestSuiteType.CLOUD_STUDIO,
            linkedTestCases,
            testSuite.id
          );
        } else if (KATALON_STUDIO_SUPPORTED_TEST_PROJECT_TYPE.includes(testProject.type)) {
          const updatedTestSuite = {
            name: testSuite.name,
            testCases: linkedTestCases,
          };
          addTestCasesToTestSuiteTestOps(testSuite.id, updatedTestSuite);
        }
        DomEventHandlers.createEvent(KATALON_EVENTS.addTestCaseToTestSuite);
      });
  };

  const handleCreateAndAdd = () => {
    ActionsHelper.syncTrackingData(
      { testCase: firstTestCase },
      ACTIONS_TRACKING.TEST_CASE_ADDED_TO_TEST_SUITE,
      displayedPageTracking,
      TEST_SUITE_DESTINATION.NEW
    );
    AppHelper.openCustomBlockedUI();
    if (testProject.type === TestProjectType.CLOUD) {
      addTestCasesToTestSuiteCloud(
        newTestSuiteName,
        newTestSuiteLocation,
        TestSuiteType.CLOUD_STUDIO,
        testCases
      );
    } else if (KATALON_STUDIO_SUPPORTED_TEST_PROJECT_TYPE.includes(testProject.type)) {
      addTestCasesToNewTestSuiteTestOps(newTestSuiteName, testProject.id, testCases);
    }
    DomEventHandlers.createEvent(KATALON_EVENTS.addTestCaseToTestSuite);
  };

  const testCaseType = firstTestCase.testType || null;
  const testSuiteType = detectSuitableTestSuiteType(testCaseType);

  return (
    <>
      <AddToTestSuiteView
        testCases={testCases}
        isOpen={isOpen}
        setTestSuite={setTestSuite}
        testSuiteType={testSuiteType}
        className={classnames(switchToAddTestSuite && 'd-none')}
        handleAddToTestSuite={handleAddToTestSuite}
        handleClose={handleClose}
        setSwitchToAddTestSuite={setSwitchToAddTestSuite}
        testSuite={testSuite}
      />
      <AddNewTestSuiteView
        isOpen={isOpen}
        testCases={testCases}
        testSuiteType={testSuiteType}
        newTestSuiteName={newTestSuiteName}
        setNewTestSuiteName={setNewTestSuiteName}
        newTestSuiteLocation={newTestSuiteLocation}
        setNewTestSuiteLocation={setNewTestSuiteLocation}
        className={classnames(!switchToAddTestSuite && 'd-none')}
        handleClose={handleClose}
        handleCreateAndAdd={handleCreateAndAdd}
        setSwitchToAddTestSuite={setSwitchToAddTestSuite}
      />
    </>
  );
}

export default AddToTestSuiteDialog;
