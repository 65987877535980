/* eslint-disable react/display-name */
import React, { useState, forwardRef, useImperativeHandle } from 'react';
import TextField from '@katalon-studio/katalon-ui/TextField';
import { t } from '../../../i18n/t';
import SearchIcon from '../../../../images/icons/katalonui/SearchIcon';
import CloseIcon from '../../../../images/icons/katalonui/CloseIcon';

const SearchInput = forwardRef((props: {
  onChangeInput: (searchValue: string) => void,
  onClear: () => void,
}, ref) => {

  const { onChangeInput, onClear } = props;
  const [searchValue, setSearchValue] = useState('');

  const onChange = (event: any) => {
    const searchValue = event.target.value;
    setSearchValue(searchValue);
    onChangeInput(searchValue);
  };

  const onClearInput = () => {
    setSearchValue('');
    onClear();
  };

  useImperativeHandle(ref, () => ({
    clearSearchInput() {
      onClearInput();
    }
  }));

  return (
    <div className="search-input">
      <TextField
        size="small"
        fullWidth
        value={searchValue}
        onChange={onChange}
        startAdornment={<SearchIcon />}
        endAdornment={searchValue && <div className="close-icon" onClick={onClearInput}><CloseIcon /></div>}
        placeholder={t('integration#search-input#place-holder')}
      />
    </div>
  );
});

export default SearchInput;
