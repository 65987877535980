import { FormGroup, FormText, Label } from 'reactstrap';
import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { isEmpty } from 'lodash';
import { t } from '../../../i18n/t';
import Select from '../../Select';
import ExecutionEnvironmentSelect from './ExecutionEnvironmentSelect';
import { TestProjectType, TestSuiteType } from '../../../utils/Constants';
import DecoratorConstants from '../../../utils/DecoratorConstants';
import MFlags from '../../../models/MFlags';
import Input from '../../Input';
import Link from '../../Link';

export default function TestSuiteRunConfiguration(props) {
  const {
    testProject,
    validation,
    testSuites,
    executionEnvironments,
    g5ExecutionEnvironments,
    isLoading,
    form,
    apps,
    executionProfiles,
    localAgents,
    circleCIAgents,
    k8sAgents,
    onRefresh,
    handleChangeObjectName
  } = props;
  const { control, setValue, getValues } = form;
  const [executionProfileId, setExecutionProfileId] = useState();
  const [executionProfileOptions, setExecutionProfileOptions] = useState([]);
  const [tsType, setTsType] = useState();
  const [isResetTsAgent, setIsResetTsAgent] = useState(false);


  useEffect(() => {
    let testSuiteType;
    const tsId = getValues('runConfiguration.testSuiteId');
    if (testSuites.length > 0) {
      if ((tsId && !testSuites.find((ts) => ts.value === tsId)) || !tsId) {
        setValue('runConfiguration.testSuiteId', testSuites[0].value);
        testSuiteType = testSuites[0]?.type;
        setIsResetTsAgent(true);
      } else {
        const selectedTs = testSuites.find((ts) => ts.value === tsId);
        testSuiteType = selectedTs?.type;
        setIsResetTsAgent(false);
      }
    } else {
      setValue('runConfiguration.testSuiteId', undefined);
      testSuiteType = '';
      setIsResetTsAgent(false);
    }

    if (MFlags.testCloudG5TestSuiteEnabled) {
      setTsType(testSuiteType);
      setValue('runConfiguration.tsType', testSuiteType);
      handleChangeObjectName(testSuiteType);
    }

    setExecutionProfileOptions(executionProfiles.map((el) => ({
      ...el,
      value: el?.id,
      icon: DecoratorConstants.generateIconExecutionProfile(el.default),
      label: el.name
    })));

    if (!isEmpty(executionProfiles)) {
      const profileId = getValues('runConfiguration.executionProfileId');
      if (!isEmpty(executionProfiles.filter((profile) => profile.id === profileId))) {
        setExecutionProfileId(profileId);
      } else {
        setExecutionProfileId(executionProfiles[0]?.id);
        setValue('runConfiguration.executionProfileId', executionProfiles[0]?.id);
      }
    }
  }, [testSuites, executionProfiles]); // eslint-disable-line react-hooks/exhaustive-deps


  const handleChangProfile = (field) => (item) => {
    field.onChange(item.value);
    setExecutionProfileId(item.value);
  };

  return (
    <>
      <FormGroup className="d-flex">
        <Label className="col-2 test-run-label" for="objectName">{t('Object Name')}</Label>
        <div className="p-0 col-10">
          <Controller
            name="runConfiguration.testSuiteId"
            control={control}
            rules={{ required: validation }}
            render={({ field, formState: { errors } }) =>
              <div className="d-flex flex-column flex-grow-1">
                <Select
                  {...field}
                  id="testSuiteId"
                  name="testSuiteId"
                  required
                  clearable={false}
                  onChange={(item) => {
                    field.onChange(item.value);
                    if (MFlags.testCloudG5TestSuiteEnabled) {
                      setTsType(item.type);
                      setValue('runConfiguration.tsType', item.type);
                      handleChangeObjectName(item.type);
                      setIsResetTsAgent(true);
                    }
                  }}
                  options={testSuites}
                  isLoading={isLoading}
                  isCustom
                />
                {testProject && testProject.type === TestProjectType.GIT && (
                  <FormText color="muted">
                    Can’t find your preferred Test Suite from the list?&nbsp;
                    <Link
                      href="#"
                      onClick={() => onRefresh()}
                    >Click here to refresh.
                    </Link>
                  </FormText>
                )}
                {errors.runConfiguration?.testSuiteId && !getValues('runConfiguration.testSuiteId') && <small className="text-danger fs-6">This is required</small>}
              </div>}
          />
        </div>
        {MFlags.testCloudG5TestSuiteEnabled &&
        <Controller
          name="runConfiguration.tsType"
          control={control}
          defaultValue={TestSuiteType.TESTOPS}
          render={({ field }) =>
            <div className="d-flex flex-column flex-grow-1">
              <Input
                {...field}
                required
                id="tsType"
                name="tsType"
                type="hidden"
                placeHolder={t('schedule#place-holder-name')}
              />
            </div>}
        /> }
      </FormGroup>
      <FormGroup className="d-flex">
        <Label className="col-2 test-run-label" for="environment">{t('Environment')}</Label>
        <div className="p-0 col-10">
          <Controller
            name="runConfiguration.testSuiteAgents"
            control={control}
            rules={{ required: validation }}
            render={({ field, formState: { errors } }) => (
              <div className="d-flex flex-column flex-grow-1">
                <ExecutionEnvironmentSelect
                  {...field}
                  testProject={testProject}
                  executionEnvironments={executionEnvironments}
                  g5ExecutionEnvironments={g5ExecutionEnvironments}
                  tsType={tsType}
                  isReset={isResetTsAgent}
                  apps={apps}
                  localAgents={localAgents}
                  circleCIAgents={circleCIAgents}
                  k8sAgents={k8sAgents}
                  title={t('testcloud-environment#test-suite')}
                  isShowDropdown={!MFlags.testCloudDisableTCFromScheduleDialog}
                />
                {errors.runConfiguration?.testSuiteAgents && <small className="text-danger fs-6">This is required</small>}
              </div>)}
          />
        </div>
      </FormGroup>
      {tsType !== TestSuiteType.CLOUD_STUDIO &&
      <FormGroup className="d-flex">
        <Label className="col-2 test-run-label" for="executionProfile">{t('execution_profile')}</Label>
        <div className="p-0 col-10">
          <Controller
            name="runConfiguration.executionProfileId"
            control={control}
            rules={{ required: validation }}
            render={({ field, formState: { errors } }) => (
              <div className="d-flex flex-column flex-grow-1">
                <Select
                  {...field}
                  required
                  clearable={false}
                  id="executionProfileList"
                  value={executionProfileId}
                  onChange={handleChangProfile(field)}
                  options={executionProfileOptions}
                  isLoading={isLoading}
                  isCustom
                  maxMenuHeight={125}
                />
                {errors.runConfiguration?.executionProfileId && <small className="text-danger fs-6">This is required</small>}
              </div>)}
          />
        </div>
      </FormGroup>}
    </>
  );

}
