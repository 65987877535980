import colors from '../../scss/colors.scss';
import ColorUtils from './ColorUtils';

const numberColor = 7;
const statusColor = [
  colors.newFailed,
  colors.darkOrange400,
  colors.darkOrange200,
  colors.yellow300,
  colors.darkGreen200,
  colors.darkGreen400,
  colors.newPassed
];

const labelTreeMapColor = [
  colors.treemapLabelFailed,
  colors.treemapLabelFailed,
  colors.treemapLabelFailed,
  colors.treemapLabelFailed,
  colors.treemapLabelFailed,
  colors.treemapLabelFailed,
  colors.treemapLabelFailed,
  colors.treemapLabelPassed
];

const flakinessColorsArray = [...statusColor].reverse();

const ChartConstants = {
  simpleLineChartPointColor: ColorUtils.transparentize(colors.red),
  totatExecutionTimeChartPointColor: ColorUtils.transparentize(colors.green),
  numberColor,
  statusColor,
  labelTreeMapColor,
  flakinessColors: ColorUtils.colorScales(flakinessColorsArray, 20)
};

export default ChartConstants;
