import React, { MouseEventHandler } from 'react';
import { DialogContent, DialogActions, DialogContentText } from '@mui/material';
import { Button, ButtonToolbar } from 'reactstrap';
import CloseableDialogComponent from './CloseableDialogComponent';
import { t } from '../../i18n/t';

interface DiscardAutomationRuleDialogProps {
  isOpen: boolean;
  handleClose: MouseEventHandler;
  handleDiscard: MouseEventHandler;
}

function DiscardAutomationRuleDialog(props: DiscardAutomationRuleDialogProps) {

  const {
    isOpen,
    handleClose,
    handleDiscard,
  } = props;

  const renderDialog = () => (
    <CloseableDialogComponent
      isOpen={isOpen}
      handleClose={handleClose}
      maxWidth="sm"
      title={t('discard-issue-dialog#title')}
    >
      <DialogContent>
        <DialogContentText>
          <div className="title m-0 text-left">
            {t('discard-automation-rule-dialog#content')}
          </div>
        </DialogContentText>
      </DialogContent>
      <DialogActions className="popup-footer justify-content-end p-0">
        <ButtonToolbar>
          <Button
            id="button-warning-cancel"
            title={t('continue-editing')}
            color="link"
            onClick={handleClose}
          >
            {t('continue-editing')}
          </Button>
          <Button
            id="button-warning-ok"
            title={t('discard-and-back')}
            color="primary"
            onClick={handleDiscard}
          >
            {t('discard-and-back')}
          </Button>
        </ButtonToolbar>
      </DialogActions>
    </CloseableDialogComponent>
  );

  return renderDialog();
}

export default DiscardAutomationRuleDialog;
