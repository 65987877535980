import { chain, trimStart, cloneDeep, lowerCase, forEach, find, filter, isEqual, pick } from 'lodash';
import React from 'react';
import {
  ALL_BROWSERS_TESTCLOUD_AGENT, ANY_DEVICE_ID_PREFIX,
  BROWSER_HEADLESS_SUFFIX,
  BrowserName,
  BrowserType,
  CloudType,
  ConfigType, DevicePoolType,
  DeviceType,
  OsName,
  SESSION_TYPES
} from '../../../utils/Constants';
import DecoratorConstants from '../../../utils/DecoratorConstants';
import MFlags from '../../../models/MFlags';
import { t } from '../../../i18n/t';
import { IconGroup, IconMobileBrowser, IconMobileNativeApp } from '../../../images/CustomIcon';
import { BrowserDetector, OSDetector } from '../../../utils/Environment';
import MContext from '../../../models/MContext';
import TooltipComponent from '../../TooltipComponent';

/**
 * Group by property with lodash chain enabled.
 */
export const chainGroupByProperty = (property, list) => chain(list).groupBy((item) => item[property]);

export const buildKey = (os, name, version) => (`${os.toLowerCase()}_${name.toLowerCase()}_${version.toLowerCase()}`).replaceAll(' ', '_');

export const buildDeviceKey = (id, os, version, poolId) => (`${id}_${os.toLowerCase()}_${version ? version.toLowerCase() : '*'}_${poolId ? 'private' : ''}`).replaceAll(' ', '_');
/**
 * Group TestCloud agents by os and browser.
 */
export const groupTestCloudAgentsByOsBrowser = (testCloudAgents) => chainGroupByProperty('os', testCloudAgents)
  .map((value, os) => {
    const osDisplayName = value.length > 0 ? value[0].osDisplayName : '';
    const groupByBrowser = chainGroupByProperty('browser', value)
      .map((value, browser) => {
        const headless = value.length > 0 ? value[0].headless : false;
        const browserDisplayName = value.length > 0 ? value[0].browserDisplayName : '';
        const groupByVersion = chainGroupByProperty('browserVersion', value)
          .map((value, browserVersion) => {
            const browserVersionNumber = trimStart(browserVersion, `${browser} `);
            const browserVersionType = value.length > 0 ? value[0].browserVersionType : '';
            return {
              label: DecoratorConstants.buildBrowserVersionLabel(browserVersionNumber, browserVersionType),
              value: browserVersion,
              title: browserVersionNumber,
              key: buildKey(os, browser, browserVersion),
              os,
              browser,
              browserVersion,
              browserVersionType,
              headless,
              children: []
            };
          })
          .sort((a, b) => b.title.localeCompare(a.title, undefined, { numeric: true }))
          .value();
        return {
          label: (<>{DecoratorConstants.iconByBrowser(browser)}<span className="m-2">{browserDisplayName}</span></>),
          title: browserDisplayName,
          value: browser,
          children: groupByVersion,
          headless
        };
      })
      .orderBy(['title'], ['asc'])
      .value();
    return {
      label: (<>{DecoratorConstants.iconByOS(os)}<span className="m-2">{osDisplayName}</span></>),
      value: os,
      title: osDisplayName,
      children: groupByBrowser
    };
  })
  .orderBy(['value'], ['desc'])
  .value();

export const getDeviceFamily = (os, isTablet, deviceFamily) => {
  if (os.toLowerCase() === OsName.ANDROID.toLowerCase()) {
    if (isTablet) {
      return DeviceType.Tablet;
    } else {
      return DeviceType.Phone;
    }
  }
  if (os.toString() === OsName.IOS.toLowerCase()) {
    if (isTablet) {
      return DeviceType.iPad;
    }
    return DeviceType.iPhone;
  }
  return deviceFamily;
};

export const transformDeviceName = (brand, name) => (!lowerCase(name).startsWith(lowerCase(brand)) && lowerCase(brand) !== 'ios' ? `${brand} ${name}` : name);

const renderDeviceOSLabel = (deviceOption) => (<>{DecoratorConstants.iconByOS(deviceOption.os)}<span className="m-2">{DecoratorConstants.displayNameByOS(deviceOption.os)}</span></>);

const renderDeviceOsVersionLabel = (deviceOption) => deviceOption.osVersion;

const renderDeviceFamily = (deviceOption) => (<>{DecoratorConstants.iconByDeviceType(deviceOption.deviceFamily)}<span className="m-2">{deviceOption.deviceFamily}</span></>);

const renderDeviceName = (deviceOption) => {
  if (deviceOption?.devicePoolType === DevicePoolType.PRIVATE_CLOUD_POOL) {
    return <>{deviceOption.deviceName} {DecoratorConstants.iconPrivateDeviceWithUdid(deviceOption.udid)} </>;
  } else if (deviceOption?.availability) {
    return <> {deviceOption.deviceName} {DecoratorConstants.renderAvailabilityIcon(deviceOption.availability)} </>;
  } else {
    return deviceOption.deviceName;
  }
};

const getDeviceUniqueName = (deviceOption) => (deviceOption?.devicePoolId ? (`${deviceOption.name} Private`) : deviceOption.name);

const getDeviceName = (deviceOption) => deviceOption.deviceName;

const getOsName = (deviceOption) => DecoratorConstants.displayNameByOS(deviceOption.os);

const getExtraInfoFromDeviceOption = (deviceOption) => ({
  // buildDeviceKey is logical, so keep usage of devicePoolId
  key: buildDeviceKey(deviceOption.id, deviceOption.os, deviceOption.osVersion, deviceOption.devicePoolId),
  id: deviceOption.id,
  os: deviceOption.os,
  osVersion: deviceOption.osVersion,
  deviceFamily: deviceOption.deviceFamily,
  deviceType: deviceOption.isTablet,
  deviceId: deviceOption.id,
  deviceName: deviceOption.deviceName,
  devicePoolId: deviceOption.devicePoolId,
  udid: deviceOption.udid,
  devicePoolType: deviceOption.devicePoolType,
  deviceUniqueName: deviceOption.deviceUniqueName,
  availability: deviceOption.availability,
});

const transformDeviceOptions = (deviceOption) => {
  const deviceFamily = getDeviceFamily(deviceOption.os, deviceOption.isTablet, deviceOption.deviceFamily);
  deviceOption.deviceFamily = DecoratorConstants.displayNameByDevice(deviceFamily);
  deviceOption.deviceUniqueName = getDeviceUniqueName(deviceOption);
  const manufacturer = deviceOption?.manufacturer ? deviceOption.manufacturer[0] : '';
  deviceOption.deviceName = transformDeviceName(manufacturer, deviceOption.name);
};

const transformDeviceListOptions = (deviceOptions) => {
  deviceOptions.forEach((deviceOption) => {
    transformDeviceOptions(deviceOption);
  });
};

export const deviceOrderingComparator = (a, b) => {
  // Priority private device to the top
  if (a.udid && !b.udid) {
    return -1;
  }
  if (!a.udid && b.udid) {
    return 1;
  }

  // Priority Any device
  if (a.id?.startsWith(ANY_DEVICE_ID_PREFIX)) {
    return -1;
  }
  if (b.id?.startsWith(ANY_DEVICE_ID_PREFIX)) {
    return 1;
  }

  // Sort by availability
  const availabilityOrder = { HIGH: 1, MEDIUM: 2, LOW: 3 };
  const availabilityA = availabilityOrder[a.availability] || 4;
  const availabilityB = availabilityOrder[b.availability] || 4;

  if (availabilityA !== availabilityB) {
    return availabilityA - availabilityB;
  }

  // Sort by title in descending alphabetical order
  return b.title.localeCompare(a.title, undefined, { numeric: true });
};


/**
 * Add a DeviceOption to SelectTree
 */
const addDeviceOptionToSelectTree = (parent, deviceOption, keyList, configMap) => {
  const { customLabelMap, customTitleMap, customComparatorMap, customAdditionInfoMap } = configMap;
  let current = parent;
  for (const key of keyList) {
    const value = deviceOption[key];
    let pick = current.find((component) => component.value === value);
    if (!pick) {
      pick = {
        label: customLabelMap[key](deviceOption),
        title: customTitleMap[key] ? customTitleMap[key](deviceOption) : value,
        value,
        children: [],
      };
      if (customAdditionInfoMap[key]) {
        pick = { ...pick, ...customAdditionInfoMap[key](deviceOption) };
      }
      current.push(pick);
    }
    current.sort((a, b) => b.value.localeCompare(a.value, undefined, { numeric: true }));
    if (customComparatorMap[key]) current.sort(customComparatorMap[key]);
    current = pick.children;
  }
};

/**
 * Builds device specifications for TestCloud.
 *
 * Constructs a string representing device specifications in the format required by TestCloud.
 * The pattern follows the format: `@ os=ios and osVersion=14.5 and deviceFamily=Phone and deviceName=iPhone 12`.
 *
 * @param {Object} deviceInfo - An object containing device options such as 'os', 'osVersion', 'deviceFamily', 'deviceName'.
 *
 * @returns {string} - A string representing the device specifications in the TestCloud format.
 */
export const buildDeviceSpecs = (deviceInfo) => {
  const collections = ['os', 'osVersion', 'deviceFamily', 'deviceName'];
  const deviceSpecs = collections.map((key) => {
    const value = deviceInfo[key] || '*';
    return `${key}==${value}`;
  });
  const deviceSpecsString = deviceSpecs.join(' and ');
  return `@ ${deviceSpecsString}`;
};


/**
 * Travel the tree and add "any" option to the top of each level
 */
const allOptionsLabelList = ['', 'Any OS Version', 'Any Device Type', 'Any Device'];
const pathKey = ['os', 'osVersion', 'deviceFamily', 'deviceName'];

export const expandOptionsWithAny = (treeNode, depth, traversalData) => {
  for (const node of treeNode) {
    if (node.children?.length > 0) {
      // Update traversalData
      const newTraversalData = {
        ...traversalData,
        [pathKey[depth]]: node.value
      };

      if (depth === 1 && node?.value.includes('.')) {
        // Skip osVersion if it contains a dot
      } else {
        // Travel to the next level
        expandOptionsWithAny(node.children, depth + 1, newTraversalData);
      }
    }
  }

  // Add "any" option to the top of each level
  if (depth > 1) { // depth 0 is any os, depth 1 is any osVersion
    const deviceId = buildDeviceSpecs(traversalData);
    treeNode.unshift({
      key: buildDeviceKey(deviceId, traversalData.os, traversalData.osVersion, null),
      label: allOptionsLabelList[depth], // display name
      title: allOptionsLabelList[depth],
      value: deviceId,
      children: [],
      ...traversalData,
      deviceId,
    });
  }
};

/**
 * Group TestCloud mobile private devices by OS and Device name
 */
export const groupTestCloudMobileDeviceByOSAndDeviceName = (testCloudMobileDeviceOptions) => {

  // Configuration
  const customLabelMap = {
    os: renderDeviceOSLabel,
    osVersion: renderDeviceOsVersionLabel,
    deviceFamily: renderDeviceFamily,
    deviceUniqueName: renderDeviceName,
  };

  const customTitleMap = {
    os: getOsName,
    deviceUniqueName: getDeviceName,
  };

  const customComparatorMap = {
    deviceUniqueName: deviceOrderingComparator,
  };

  const customAdditionInfoMap = {
    deviceUniqueName: getExtraInfoFromDeviceOption,
  };

  const configMap = {
    customLabelMap,
    customTitleMap,
    customComparatorMap,
    customAdditionInfoMap
  };

  const keyList = Object.keys(customLabelMap);

  // Initial Value
  const optionTree = [];

  // Add more info for testCloudMobileDeviceOptions
  transformDeviceListOptions(testCloudMobileDeviceOptions);

  // Add each deviceOption to optionTree
  testCloudMobileDeviceOptions.forEach((deviceOption) => {
    addDeviceOptionToSelectTree(optionTree, deviceOption, keyList, configMap);
  });

  return optionTree;
};

export const isMobile = (os) => {
  if (typeof os !== 'string') {
    return false;
  }
  return os?.toLowerCase() === OsName.IOS.toLowerCase() || os?.toLowerCase() === OsName.ANDROID.toLowerCase();
};

export const isSpecDevice = (deviceId) => deviceId?.startsWith('@');

export const isWebServices = (name) => name === BrowserType.WEB_SERVICE;

export const isMacOs = (os) => os.toLowerCase() === OsName.MacOS.toLowerCase();

export const isWindowsHeadless = (value) => value?.os.toLowerCase() === OsName.Windows.toLowerCase()
    && (value?.browser.toLowerCase() === BrowserType.CHROME.toLowerCase() || value?.browser.toLowerCase() === BrowserType.FIRE_FOX.toLowerCase());

export const isLinuxHeadless = (value) => value?.os.toLowerCase() === OsName.Linux.toLowerCase()
    && (value?.browser.toLowerCase() === BrowserType.CHROME.toLowerCase() || value?.browser.toLowerCase() === BrowserType.FIRE_FOX.toLowerCase());

export const isMacOSHeadless = (value) => value?.os.toLowerCase() === OsName.MacOS.toLowerCase()
    && (value?.browser.toLowerCase() === BrowserType.CHROME.toLowerCase() || value?.browser.toLowerCase() === BrowserType.FIRE_FOX.toLowerCase());

export const isDefaultTestCloudBrowser = (value) => value.browser === ALL_BROWSERS_TESTCLOUD_AGENT.browser && value.browserVersion === ALL_BROWSERS_TESTCLOUD_AGENT.browserVersion;

export const getGroupTestCloudAgentOptions = (testCloudAgentOptions, testCloudMobileDeviceOptions) => {
  const testCloudAgentOptionsFilterByOs = filter(testCloudAgentOptions, (value) => !isMobile(value?.os));
  const testCloudAgentOptionsFilterByInternetExplorer = filter(testCloudAgentOptionsFilterByOs, (value) =>
    (MFlags.testCloudInternetExplorerEnabled ? true : value?.browser !== BrowserName.IE.toLowerCase()));
  const testCloudAgentHeadlessOptions = testCloudAgentOptionsFilterByInternetExplorer.filter((value) => isWindowsHeadless(value) || isLinuxHeadless(value) || isMacOSHeadless(value))
    .map((value) => ({ ...value, headless: true, browser: `${value?.browser}${BROWSER_HEADLESS_SUFFIX}`, browserDisplayName: `${value?.browserDisplayName}${BROWSER_HEADLESS_SUFFIX}` }));

  const transformTestCloudMobileDeviceOptions = [];
  testCloudMobileDeviceOptions.forEach((device) => {
    device.capabilities.forEach((cap) => {
      transformTestCloudMobileDeviceOptions.push({
        id: device.id,
        manufacturer: device.manufacturer,
        isTablet: device.isTablet,
        name: cap.udid ? device.name : device.displayName,
        os: device.os.toLowerCase(),
        deviceFamily: device.deviceFamily,
        osVersion: cap.version,
        devicePoolId: device.devicePoolId,
        udid: cap.udid,
        devicePoolType: cap.devicePoolType,
        availability: cap.availability,
      });
    });
  });

  const mobileEnv = groupTestCloudMobileDeviceByOSAndDeviceName(transformTestCloudMobileDeviceOptions);
  return [...groupTestCloudAgentsByOsBrowser([...testCloudAgentOptionsFilterByInternetExplorer, ...testCloudAgentHeadlessOptions]),
    ...mobileEnv];
};

export const getGroupTestCloudAgentForTestSuiteCollection = (options) => options.slice();

/**
 * Filter test cloud agent options by browser type
 */
export const filterTestCloudAgentOptionsByBrowserType = (testCloudAgentOptions, browserType, configType, isHeadless, mobilePlatform) => {

  if (MContext.testCloudMobileNativeAppEnabled && browserType === BrowserType.MOBILE_NATIVE) {
    if (mobilePlatform) {
      return testCloudAgentOptions.filter((el) => el.value.toLowerCase() === mobilePlatform?.toLowerCase());
    } else {
      return testCloudAgentOptions.filter((el) => isMobile(el.value));
    }
  }
  if (MContext.testCloudMobileNativeAppEnabled && configType === ConfigType.TSC && browserType === BrowserType.ALL) {
    return testCloudAgentOptions;
  }

  if (!MContext.testCloudMobileNativeAppEnabled && configType !== ConfigType.TS) {
    return testCloudAgentOptions;
  }

  if (browserType === BrowserType.MOBILE_BROWSERS || browserType === BrowserType.MOBILE_NATIVE) {
    return testCloudAgentOptions.filter((el) => isMobile(el.value));
  }

  if (browserType === BrowserType.WEB_SERVICE) {
    return testCloudAgentOptions.filter((el) => !isMobile(el.value) && !isMacOs(el.value));
  }

  const filterTestCloudAgentOptions = [];
  cloneDeep(testCloudAgentOptions)
    .forEach((option) => {
      const browserOption = option.children.filter((child) => lowerCase(child.title) === lowerCase(browserType));
      if (browserOption.length > 0) {
        option.children = browserOption;
        filterTestCloudAgentOptions.push(option);
      }
    });
  if (isHeadless) {
    return filterTestCloudAgentOptions.filter((testCloudAgent) => lowerCase(testCloudAgent.title) !== lowerCase(OsName.ANDROID));
  }
  return filterTestCloudAgentOptions;
};

export const ExtractLastedValues = (values, options, quantity) => {
  let array = options;
  const result = [];
  const lastedValues = [];
  forEach(values, (value) => {
    const selected = find(array, { value });
    if (selected) {
      result.push(selected.value);
      array = selected.children;
    }
  });
  if (result?.length === values.length) {
    for (let i = 0; i < quantity; i++) {
      lastedValues.push([...result, array[i]?.value, array[i]?.browserVersionType]);
    }
  }
  return lastedValues;
};

export const ExtractTitlesByValues = (values, options) => {
  let array = options;
  let temp;
  const titles = [];
  const valuesTemp = [];
  forEach(values, (value) => {
    const selected = find(array, { value });
    if (selected != null) {
      temp = selected;
      titles.push(selected.title);
      valuesTemp.push(selected.value);
      if (selected?.browserVersionType) {
        titles.push(selected.browserVersionType);
        valuesTemp.push(selected.browserVersionType);
      }
      array = selected.children;
    } else {
      if (temp?.children.length > 0) {
        array = temp.children;
        forEach(array, (el) => {
          const deviceName = find(el.children, { value });
          if (deviceName != null) {
            titles.push(el.title);
            valuesTemp.push(el.value);
            titles.push(deviceName.title);
            valuesTemp.push(deviceName.value);
          }
        });
      }
    }
  });
  return { titles, values: valuesTemp };
};

export const getDeviceTypeOfAgent = (agent, testCloudAgentOptions) => {
  const result = ExtractTitlesByValues(agent, testCloudAgentOptions)?.titles;
  return result.length >= 4 ? result[2] : '';

};

export const compareObj = (values, option, params) => values.some((el) => isEqual(
  pick(el, params),
  pick(option, params)
));

export const updateBrowserTypeOptions = (cloudType, configType, browserTypeOptions) => {

  const mobileNativeAppOptions = [
    {
      label: t('browser-type#mobile-native'),
      icon: <IconMobileNativeApp />,
      value: BrowserType.MOBILE_NATIVE,
      postBadge: 'New'
    }
  ];

  const defaultOption = [
    {
      label: t('browser-type#all'),
      value: BrowserType.ALL,
      icon: <IconGroup />
    }
  ];

  const mobileBrowserOptions = [
    {
      label: t('browser-type#mobile-browsers'),
      icon: <IconMobileBrowser />,
      value: BrowserType.MOBILE_BROWSERS,
      postBadge: 'New'
    }
  ];

  const browserTypes = browserTypeOptions.filter((el) => el.value !== BrowserType.MOBILE_BROWSERS);

  if (cloudType === CloudType.TEST_CLOUD_AGENT) {
    if (configType === ConfigType.TS) {
      return [...mobileBrowserOptions, ...mobileNativeAppOptions, ...browserTypes];
    } else {
      return [...defaultOption, ...mobileBrowserOptions, ...mobileNativeAppOptions, ...browserTypes];
    }
  }
  return browserTypes;
};

export const displayedBrowserNameBasedOnHeadlessMode = (value) => (value.headless && !value.browser.endsWith(BROWSER_HEADLESS_SUFFIX)
  ? `${value.browser}${BROWSER_HEADLESS_SUFFIX}`
  : value.browser);

const buildTestCloudAgentWithoutWrapper = (value) =>
  <>
    {DecoratorConstants.displayNameByOS(value.os)} {DecoratorConstants.displayNameByBrowser(value.headless && !value.browser.endsWith(BROWSER_HEADLESS_SUFFIX) ?
      `${value.browser}${BROWSER_HEADLESS_SUFFIX}` : value.browser)} {value.browserVersion} {DecoratorConstants.iconBrowserVersionType(value.browserVersionType)}
  </>;

export const buildTestCloudAgent = (value) =>
  <div className="text-wrap">
    {buildTestCloudAgentWithoutWrapper(value)}
  </div>;

export const buildDisplayTestCloudAgentWebservice = (value) => {
  if (isDefaultTestCloudBrowser(value)) {
    return (
      <TooltipComponent placement="bottom-end" arrow text={`Web Services (TestCloud ${DecoratorConstants.displayNameByOS(value.os)} Default)`}>
        <span>
          {DecoratorConstants.iconWebService()} &nbsp;
          {DecoratorConstants.iconByOS(value.os)} &nbsp;
          {DecoratorConstants.iconByBrowser(value.headless ? `${value.browser}${BROWSER_HEADLESS_SUFFIX}` : value.browser)}
          {`Web Services (TestCloud ${DecoratorConstants.displayNameByOS(value.os)} Default)`}
        </span>
      </TooltipComponent>
    );
  }
  return (
    <>{DecoratorConstants.iconWebService()} &nbsp;
      {DecoratorConstants.iconByOS(value.os)} &nbsp;
      {DecoratorConstants.iconByBrowser(value.headless ? `${value.browser}${BROWSER_HEADLESS_SUFFIX}` : value.browser)}
      <span className="m-2">
        {`${DecoratorConstants.displayNameByOS(value.os)} ${displayedBrowserNameBasedOnHeadlessMode(value)} ${value.browserVersion}`}
      </span>
    </>);
};

export const buildDisplayTestCloudAgentEnvironmentTitle = (value) => {
  if (isWebServices(value?.browserType)) {
    return `${DecoratorConstants.displayNameWebService()}`;
  }
  if (isMobile(value.os)) {
    if (isSpecDevice(value.deviceId)) {
      let osDisplayName = DecoratorConstants.displayNameByOS(value.os);
      if (value.osVersion) {
        osDisplayName = `${osDisplayName} ${value.osVersion}`;
      }
      return `${osDisplayName} ${DecoratorConstants.displayNameByDeviceSpec(value.deviceId)}`;
    }
    return `${DecoratorConstants.displayNameByOS(value.os)} ${value.osVersion} ${value.deviceName}`;
  } else if (value.browser === 'all') {
    return `TestCloud ${DecoratorConstants.displayNameByOS(value.os)} (Default)`;
  }
  return `${DecoratorConstants.displayNameByOS(value.os)} ${DecoratorConstants.displayNameByBrowser(value.headless && !value.browser.endsWith(BROWSER_HEADLESS_SUFFIX) ?
    `${+value.browser} ${BROWSER_HEADLESS_SUFFIX}` : value.browser)} ${value.browserVersion}`;
};

export const buildDisplayTestCloudAgentEnvironment = (value) => {
  if (isWebServices(value?.browserType)) {
    return buildDisplayTestCloudAgentWebservice(value);
  }
  if (isMobile(value.os)) {
    if (isSpecDevice(value.deviceId)) {
      return (
        <>{DecoratorConstants.iconByOS(value.os)} &nbsp;
          {DecoratorConstants.iconByDevice(value.deviceType)}
          <span className="m-2">{DecoratorConstants.displayNameByOS(value.os)} {value.osVersion} {DecoratorConstants.displayNameByDeviceSpec(value.deviceId)}
          </span>
        </>);
    }
    const browserNameByMobileOs = OSDetector.isIOS(value.os) ? BrowserName.Safari : BrowserName.Chrome;
    const toolTipText = `${buildDisplayTestCloudAgentEnvironmentTitle(value)} ${value.appName ? value.appName : browserNameByMobileOs}`;
    return (
      <TooltipComponent placement="bottom-end" arrow text={toolTipText}>
        <span>
          {DecoratorConstants.iconByOS(value.os)} &nbsp;
          {DecoratorConstants.iconByDevice(value.deviceType)} &nbsp;
          {DecoratorConstants.iconMobileByConfig(value)}
          <span className="m-2">
            {DecoratorConstants.displayNameByOS(value.os)} {value.osVersion} {value.deviceName}
            {
              value.devicePoolType === DevicePoolType.PRIVATE_CLOUD_POOL ? DecoratorConstants.iconPrivateDeviceWithUdid(value.udid) : null
            }
          </span>
        </span>
      </TooltipComponent>
    );
  } else if (value.browser === 'all') {
    return (
      <TooltipComponent placement="bottom-end" arrow text={buildDisplayTestCloudAgentEnvironmentTitle(value)}>
        <span>
          {DecoratorConstants.iconByOS(value.os)}
          <span>TestCloud</span>
          <span className="m-2">{DecoratorConstants.displayNameByOS(value.os)}</span>
          <span className>(Default)</span>
        </span>
      </TooltipComponent>
    );
  }
  return (
    <TooltipComponent placement="bottom-end" arrow text={buildDisplayTestCloudAgentEnvironmentTitle(value)}>
      <span>
        {DecoratorConstants.iconByOS(value.os)} &nbsp;
        {DecoratorConstants.iconByBrowser(value.headless ? `${value.browser}${BROWSER_HEADLESS_SUFFIX}` : value.browser)}
        <span className="m-2">
          {buildTestCloudAgentWithoutWrapper(value)}
        </span>
      </span>
    </TooltipComponent>
  );
};

export const buildDisplayTestCloudAgentEnvironmentForSelect = (value) => {
  if (isMobile(value.os)) {
    const icons = [DecoratorConstants.iconByOS(value.os), DecoratorConstants.iconByDevice(value.deviceType)];
    if (value.devicePoolType === DevicePoolType.PRIVATE_CLOUD_POOL) icons.push(DecoratorConstants.iconPrivateDeviceWithUdid(value.udid));
    let label = `${DecoratorConstants.displayNameByOS(value.os)} ${value.osVersion} ${value.deviceName}`;
    if (isSpecDevice(value.deviceId)) {
      let osDisplayName = DecoratorConstants.displayNameByOS(value.os);
      if (value.osVersion) {
        osDisplayName = `${osDisplayName} ${value.osVersion}`;
      }
      label = `${osDisplayName} ${DecoratorConstants.displayNameByDeviceSpec(value.deviceId)}`;
    }
    return {
      label,
      icons,
    };
  }
  if (isWebServices(value.browserType)) {
    return {
      label: t('browser-type#web-services-linux-default'),
      icons: [DecoratorConstants.iconWebService(), DecoratorConstants.iconByOS(value.os)]
    };
  }
  return {
    prefixOption: 'TestCloud',
    label: buildTestCloudAgent(value),
    icons: [DecoratorConstants.iconByOS(value.os), DecoratorConstants.iconByBrowser(value.headless ? `${value.browser}${BROWSER_HEADLESS_SUFFIX}` : value.browser)]
  };
};

export const buildDisplayLocalAgents = (agent) => (
  <span className="d-flex">
    {DecoratorConstants.iconsAgent(agent.active, agent.os)}
    <span> {agent.name} </span>
  </span>);

export const buildDisplayCircleCIEnvironment = (agent) => (
  <span>{`CircleCI | ${agent.name}`}</span>
);

export const buildDisplayKubernetesEnvironment = (agent) => (
  <span>{`Kubernetes | ${agent.name}`}</span>
);

export const buildDisplayExecutionEnvironment = (value) => {
  switch (value.cloudType) {
    case CloudType.LOCAL_AGENT:
      return buildDisplayLocalAgents(value);
    case CloudType.TEST_CLOUD_AGENT:
      return buildDisplayTestCloudAgentEnvironment(value);
    case CloudType.CIRCLE_CI_AGENT:
      return buildDisplayCircleCIEnvironment(value);
    case CloudType.K8S_AGENT:
      return buildDisplayKubernetesEnvironment(value);
    default:
      return buildDisplayTestCloudAgentEnvironment(value);
  }
};


export const buildDisplayExecutionEnvironmentForSelect = (value) => {
  switch (value.cloudType) {
    case CloudType.LOCAL_AGENT:
      return { label: value.name, icons: [DecoratorConstants.agentStatusDecorator(value.active), DecoratorConstants.iconByOS(value.os)] };
    case CloudType.TEST_CLOUD_AGENT:
      return buildDisplayTestCloudAgentEnvironmentForSelect(value);
    case CloudType.CIRCLE_CI_AGENT:
      return { label: `CircleCI | ${value.name}` };
    case CloudType.K8S_AGENT:
      return { label: `Kubernetes | ${value.name}` };
    default:
      return buildDisplayTestCloudAgentEnvironment(value);
  }
};

export const getBrowserHeadlessTypeByBrowserType = (browser) => {
  if (BrowserDetector.isChrome(browser)) {
    return BrowserType.CHROME_HEADLESS;
  }
  if (BrowserDetector.isFirefox(browser)) {
    return BrowserType.FIRE_FOX_HEADLESS;
  }
  return '';
};

export const extractLastedValues = (executionEnvironments) => [
  ...ExtractLastedValues(['windows', 'chrome'], executionEnvironments?.data, 1),
  ...ExtractLastedValues(['linux', 'chrome'], executionEnvironments?.data, 1),
  ...ExtractLastedValues(['windows', 'firefox'], executionEnvironments?.data, 1),
  ...ExtractLastedValues(['macos', 'safari'], executionEnvironments?.data, 1),
];

export const extractG5Env = (g5ExecutionEnvironments) => [
  ...ExtractLastedValues(['linux', 'chrome'], g5ExecutionEnvironments?.data, 1),
  ...ExtractLastedValues(['linux', 'firefox'], g5ExecutionEnvironments?.data, 1),
];

export const convertTestCloudEnvironments = (testCloudEnvironments) => {
  const environmentOptions = testCloudEnvironments.map((value) => ({
    label: buildTestCloudAgent({ os: value[0], browser: value[1], browserVersion: value[2] }),
    icons: [DecoratorConstants.iconByOS(value[0]), DecoratorConstants.iconByBrowser(value[1])],
    prefixOption: 'TestCloud',
    value: {
      os: value[0],
      browser: value[1],
      browserVersion: value[2],
    },
    disabled: false
  }));
  return environmentOptions;
};

export const buildTestCloudEnvironmentResources = (testCloudEnvironments) => testCloudEnvironments.map((environment) => ({
  cloudType: CloudType.TEST_CLOUD_AGENT,
  browserType: BrowserType.ALL,
  enabledTestCloudTunnel: false,
  ...environment.value,
}));

export const checkMobileAppLicenseActive = (testSessionTypes) => {
  try {
    return testSessionTypes.testSessionFeatureSummaries.some(
      (sessionType) => sessionType.supportedSessionTypes.includes(SESSION_TYPES.MOBILE_NATIVE)
        || sessionType.supportedSessionTypes.includes(SESSION_TYPES.PRIVATE_MOBILE_NATIVE)
    );
  } catch (e) {
    return false;
  }
};

export const transformExecutionEnvironments = (executionEnvironments) => executionEnvironments.map((item) => {
  const {
    os,
    osVersion,
    deviceName,
    deviceType,
    browser,
    browserVersion,
    deviceId,
    headless,
    appId,
    appName,
    appVersion,
    appVersionCode,
    cloudType,
    browserType,
    browserVersionType,
    runType,
    key,
    devicePoolId,
    udid,
    devicePoolType,
    deviceUniqueName,
  } = item.testCloudAgent || {};

  return {
    ...item,
    testCloudAgent: {
      os,
      osVersion,
      deviceName,
      deviceType,
      browser,
      browserVersion,
      deviceId,
      headless,
      appId,
      appName,
      appVersion,
      appVersionCode,
      cloudType,
      browserType,
      browserVersionType,
      runType,
      key,
      devicePoolId,
      udid,
      devicePoolType,
      deviceUniqueName,
    }
  };
});
