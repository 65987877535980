import React from 'react';
import { LocalizationProvider, TimePicker as MuiTimePicker } from '@mui/lab';
import DateAdapter from '@mui/lab/AdapterMoment';
import AccessTime from '@mui/icons-material/AccessTime';
import TextField from './TextField';

export default function TimePicker(props) {

  const overrideComponents = {
    OpenPickerIcon: AccessTime,
  };

  const overrideInput = (params) => (
    <TextField
      size="small"
      fullWidth
      {...params}
    />
  );

  const newProps = {
    ...props,
    components: overrideComponents,
    renderInput: overrideInput
  };

  return (
    <LocalizationProvider dateAdapter={DateAdapter}>
      <MuiTimePicker
        {...newProps}
      />
    </LocalizationProvider>
  );
}
