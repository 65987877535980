import React, { useEffect, useRef } from 'react';
import { buildSearchCondition } from '../../search/SearchUtils';
import SearchableFilter from './SearchableFilter';
import { SearchEntity } from '../../../utils/Constants';
import DecoratorConstants from '../../../utils/DecoratorConstants';
import { t } from '../../../i18n/t';

const JiraAssigneeFilter = (props) => {

  const filterMenu = useRef();

  const buildSearchConditions = (searchInput) => ([
    buildSearchCondition('assignee', 'contains', searchInput || '')
  ]);

  const renderOption = (option) => (
    DecoratorConstants.renderUserAvatarDecorator(option.assignee, option.avatarUrl, false)
  );

  const dataMapping = (data) => data.map(({ content }) => ({
    id: content.assignee,
    assignee: content.assignee,
    avatarUrl: content.avatarUrl
  }));

  useEffect(() => {
    if (!props.value) {
      filterMenu.current.clear();
    }
  }, [props.value]);

  return (
    <SearchableFilter
      ref={filterMenu}
      entityType={SearchEntity.ExternalDefect}
      buildSearchConditions={buildSearchConditions}
      searchKey="assignee"
      placeHolder={t('jira-filter#assignee-place-holder')}
      renderOption={renderOption}
      groupBys={['assignee', 'avatarUrl']}
      sorts={['assignee,asc']}
      dataMapping={dataMapping}
      {...props}
    />
  );
};

export default JiraAssigneeFilter;

