import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { List, ListItemButton, ListItemText } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { t } from '../../../i18n/t';
import DataLoader from '../../../components/table/DataLoader';
import { buildSearchCondition } from '../../../components/search/SearchUtils';
import { SearchEntity } from '../../../utils/Constants';
import CardBorder from '../../../components/card/CardBorder';
import colors from '../../../../scss/colors.scss';
import { BaselineCollectionDecorator } from '../BaselineCollectionDecorator';

const ListItem = withStyles({
  root: {
    '&$selected': {
      backgroundColor: colors.treeNodeSelectedColor,
    },
    '&$selected:hover': {
      backgroundColor: colors.treeNodeSelectedHoveredColor,
    },
    '&:hover': {
      backgroundColor: colors.treeNodeHoveredColor,
    }
  },
  selected: {}
})(ListItemButton);

const BaselineRevisions = ({ baselineCollectionVersionParam, handleSelectVersion, baseLineCollectionGroup }) => {
  const [selectedVersion, setSelectedVersion] = useState(baselineCollectionVersionParam ? null : baseLineCollectionGroup.latestVersion?.version);
  const selectedVersionRef = useRef();
  selectedVersionRef.current = selectedVersion;
  const dataRef = useRef();
  const pageSize = 15;

  const handleListItemClick = (baselineCollection) => {
    setSelectedVersion(baselineCollection.version);
    handleSelectVersion(baselineCollection);
  };

  const renderVersion = (baselineCollections) => {
    return (
      <List className="pt-0 pb-0">
        {baselineCollections.map((baselineCollection) => {
          const versionNumber = baselineCollection.version;
          const versionId = baselineCollection.id;
          const latestVersion = baseLineCollectionGroup.latestVersion?.version;
          return (
            <ListItem
              key={versionId}
              selected={selectedVersion === versionNumber}
              className="baseline-version-list-item-button"
              onClick={() => {
                handleListItemClick(baselineCollection);
              }}
            >
              <ListItemText
                primary={latestVersion === versionNumber ?
                  `${BaselineCollectionDecorator.versionDecorator(baselineCollection.version)} (${t('latest')})` :
                  `${BaselineCollectionDecorator.versionDecorator(baselineCollection.version)}`}
              />
            </ListItem>
          );
        })}
      </List>
    );
  };

  /**
   * If selectedVersion version in first page, it returns in first run
   * If selectedVersion version in the middle page or last page, it returns in second run
   * If selectedVersion version is not existed then get lastest version
   * For example: First page is 0
   * Current version is 2
   * Latest version is 100
   * PageSize is 2
   * Follow the recipe: (100 - 2) / 2 = 49
   */
  const onChangeData = (data) => {
    if (selectedVersionRef.current === null && data.length !== 0) {
      const extractedData = data.filter((item) => item.version === baselineCollectionVersionParam);

      if (extractedData.length > 0) {
        setSelectedVersion(baselineCollectionVersionParam);
      } else {
        const nextPage = Math.floor((baseLineCollectionGroup.latestVersion?.version - baselineCollectionVersionParam) / pageSize);
        if (nextPage > 0 && nextPage < dataRef.current.store.totalPage) {
          dataRef.current.goToPage(nextPage);
        } else {
          setSelectedVersion(baseLineCollectionGroup.latestVersion?.version);
        }
      }
    }
  };

  const renderData = () => {
    const groupId = baseLineCollectionGroup.id;
    return (
      <DataLoader
        ref={dataRef}
        noCard
        entityType={SearchEntity.BaselineCollection}
        pageSize={pageSize}
        defaultSearchConditions={[
          buildSearchCondition('baselineCollectionGroupId', '=', groupId)
        ]}
        showEmptyMessage
        defaultSort={['version, desc']}
        render={renderVersion}
        onChangeData={onChangeData}
      />
    );
  };

  return (
    <CardBorder>
      <div className="card-border-title">
        {t('baseline-revisions')}
      </div>
      <div className="mb-4">
        {renderData()}
      </div>
    </CardBorder>
  );

};

BaselineRevisions.propTypes = {
  baselineCollectionVersionParam: PropTypes.number,
  baseLineCollectionGroup: PropTypes.object.isRequired,
  handleSelectVersion: PropTypes.func.isRequired,
};

export default BaselineRevisions;
