import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import MTableColumnDataMapping from '../../components/table/models/MTableColumnDataMapping';
import { t } from '../../i18n/t';
import DecoratorConstants from '../../utils/DecoratorConstants';
import DataTable from './DataTable';
import TestCaseExecutionStatus from "./TestCaseExecutionStatus";

class PlatformStatisticsDataTable extends React.Component {
  constructor(props) {
    super(props);
    this.maxPageSize = 1000;
    this.transformData = this.transformData.bind(this);
    this.renderStatusBar = this.renderStatusBar.bind(this);
  }

  renderStatusBar(row) {
    const totalPassed = _.get(row, 'PASSED', 0);
    const totalFailed = _.get(row, 'FAILED', 0);

    const data = {
      totalPassed,
      totalFailed
    }

    return (
      <TestCaseExecutionStatus
        key={row.id}
        statistics={data}
        showLegend={false}
      />
    );
  }

  transformData(data, columnMapping) {
    if (_.isEmpty(data)) {
      return [data, columnMapping];
    }

    const transformedData = {};
    _.forEach(data, (row) => {
      const platform = row.platform;
      if (!transformedData[platform.id]) {
        transformedData[platform.id] = { ...platform };
      }
      transformedData[platform.id][row.status] = row.count;
    });

    const tableColumnMapping = [
      ...columnMapping,
      new MTableColumnDataMapping(
        t('table-header#platform'),
        'platform',
        (name, row) => {
          const platform = row;
          return DecoratorConstants.platformReport(platform, false);
        },
      ),
      new MTableColumnDataMapping(
        t('test_cases'),
        '',
        (name, row) => {
          return this.renderStatusBar(row)
        },
        false,
        'w-25',
      ),
      new MTableColumnDataMapping(
        '',
        '',
        DecoratorConstants.platformCountDecorator,
      ),
    ];

    return [Object.values(transformedData), tableColumnMapping];
  }

  renderByExecutionStatistics(columnMapping) {
    const defaultSearchConditions = this.props.defaultSearchConditions;
    return (
      <DataTable
        key={this.lastChange}
        title="Platform Statistics"
        columnMapping={columnMapping}
        entityType="PlatformStatistics"
        tableId="PlatformStatistics"
        defaultSearchConditions={defaultSearchConditions}
        transform={this.transformData}
        pageSize={this.maxPageSize}
        disableFilterButton
        {...this.props}
      />
    );
  }

  render() {
    const columnMapping = [];
    return (
      <>
        {this.renderByExecutionStatistics(columnMapping)}
      </>
    );
  }
}

PlatformStatisticsDataTable.propTypes = {
  defaultSearchConditions: PropTypes.array,
};

PlatformStatisticsDataTable.defaultProps = {
  defaultSearchConditions: [],
};

export default PlatformStatisticsDataTable;
