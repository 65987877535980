import React from 'react';
import { Button } from 'reactstrap';
import { Divider } from '@mui/material';
import PropTypes from 'prop-types';
import { t } from '../../../i18n/t';
import Services from '../../../utils/Services';
import Routes from '../../../utils/Routes';
import { IconFinalSetup, IconInviteUser, IconUploadResult } from '../../../images/CustomIcon';
import OnBoardingState from '../../organization/components/OnBoardingState';
import Notification from '../../../utils/Notification';
import CreateOrganization from './CreateOrganization';
import CreateProjectForm from '../../organization/components/CreateProjectForm';
import OrganizationUsersInvitationForm from '../../organization_users/components/OrganizationUsersInvitationForm';
import DocumentLink from '../../../utils/DocumentLink';
import OnBoardingStepper from '../../../components/wizard/OnBoardingStepper';
import MConfigs from '../../../models/MConfigs';
import Helper from '../../../utils/Helper';
import { KatalonProduct } from '../../../utils/Constants';

class OnBoardingOrganization extends React.Component {

  constructor(props) {
    super(props);
    this.isOnPremise = MConfigs.isOnPremise;
    this.fromStep = this.props.step;
    this.organizationId = this.props.organizationId;
    this.tableRef = React.createRef();
    this.subscribe = Helper.getParameterByName('subscribe');
    this.product = Helper.getParameterByName('product');
    this.query = { type_plan: Helper.getParameterByName('type_plan') };

    this.handleAfterCreateOrganization = this.handleAfterCreateOrganization.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.handleNotice = this.handleNotice.bind(this);
    this.handleCreateSampleProject = this.handleCreateSampleProject.bind(this);
    this.handleFinal = this.handleFinal.bind(this);
    this.handleGoToAnotherPage = this.handleGoToAnotherPage.bind(this);

    this.state = {
      organization: null,
      team: null,
      project: null,
      disableUploadSampleButton: false,
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData() {
    if (this.organizationId && this.fromStep > OnBoardingState.SETUP_NEW_ORG) {
      Services.getOrganization(this.organizationId)
        .then((organization) => {
          this.setState({
            organization
          });
          this.handleNext();
        });
    }
  }

  handleGoToAnotherPage() {
    const { organization } = this.state;
    if (this.subscribe === 'true') {
      if (this.product === KatalonProduct.TESTOPS) {
        this.goToTestOpsCheckoutPage();
      } else {
        this.goToKSSubscriptionPage();
      }
    }
    Routes.goToOrganizationPage(organization.id);
  }

  handleAfterCreateOrganization(organization) {
    this.setState({
      organization
    }, () => this.handleGoToAnotherPage());
  }

  handleNext(team, project) {
    this.setState({
      project,
      team
    });

    if (this.tableRef.current) {
      this.tableRef.current.handleNext();
    }
  }

  handleNotice(infoForMessage) {
    Notification.pushSuccess(t('organization_invite_user_success', infoForMessage));
  }

  handleCreateSampleProject() {
    const { project } = this.state;

    this.setState({
      disableUploadSampleButton: true,
    });

    if (project == null) {
      Notification.pushError('Create sample project', 'Error');
    } else {
      Services.createSampleProject(project.id)
        .then(() => {
          this.tableRef.current.handleNext();
        });
    }
  }

  renderCreateOrganization() {
    return (
      <CreateOrganization
        handleAfterCreate={this.handleAfterCreateOrganization}
      />
    );
  }

  renderCreateProject() {
    return (
      <CreateProjectForm
        createDefaultTeam={!(this.fromStep && this.organizationId)}
        handleAfterCreate={this.handleNext}
      />
    );
  }

  renderInviteUsers() {
    const { organization } = this.state;
    if (organization) {
      return (
        <>
          <div className="on_boarding">
            <div className="on_boarding_svg">
              <IconInviteUser />
            </div>
            <h1>
              Share projects to your members
            </h1>
          </div>
          <OrganizationUsersInvitationForm
            organizationId={organization.id}
            afterInvitation={this.handleNotice}
          />
        </>
      );
    }
    return null;
  }

  renderUploadTestResult() {
    const { disableUploadSampleButton } = this.state;

    return (
      <div className="on_boarding">
        <div className="on_boarding_svg">
          <IconUploadResult />
        </div>
        <h1>
          Upload the first test results
        </h1>
        <div className="description">
          Learn how to upload your test results from Katalon Studio{' '}
          <a href={DocumentLink.UPLOAD_REPORT_OVERVIEW} target="_blank" rel="noopener noreferrer">
            here
          </a>
          .
          <br />
          <div className="note">
            *Make sure you have already configured the{' '}
            <a href={DocumentLink.UPLOAD_KATALON_REPORT_AUTO_DOCS} target="_blank" rel="noopener noreferrer">
              integration with Katalon Studio
            </a>
            .
          </div>
        </div>
        {!this.isOnPremise &&
        <Button
          onClick={this.handleCreateSampleProject}
          disabled={disableUploadSampleButton}
        >
          Upload sample project
        </Button>}
      </div>
    );
  }

  goToOrganizationDashboard() {
    const { organization } = this.state;
    Routes.goToOrganizationPage(organization.id);
  }

  goToKSSubscriptionPage() {
    const { organization } = this.state;
    Routes.goToOrganizationSubscriptionNewOrders(organization.id, this.query);
  }

  goToTestOpsCheckoutPage() {
    const { organization } = this.state;
    Routes.goToTestOpsSubscriptionCheckoutPage(organization.id);
  }

  goToProjectDashboard() {
    const { team, project } = this.state;
    Routes.goToProjectPage(team.id, project.id);
  }

  handleFinal() {
    const { team, project } = this.state;

    if (team && project) {
      return (
        <div>
          <div className="on_boarding">
            <h1>
              Congratulations
            </h1>
            <div className="on_boarding_svg">
              <IconFinalSetup />
            </div>
            <div className="button-zone">
              <Button
                onClick={() => this.goToProjectDashboard()}
              >
                View test results
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => this.goToOrganizationDashboard()}
              >
                Go to your organization
              </Button>
            </div>
          </div>
          <Divider />
          <div className="step-final-footer">
            <a href={DocumentLink.JIRA_SETTING} target="_blank" rel="noopener noreferrer">
              Integrate with Jira
            </a>
            <a href={DocumentLink.CI_DOCS} target="_blank" rel="noopener noreferrer">
              Execute tests remotely
            </a>
            <a href={DocumentLink.VIEW_REPORT} target="_blank" rel="noopener noreferrer">
              View reports
            </a>
          </div>
        </div>
      );
    }

    return null;
  }

  renderQuickSetup() {
    const steps = [
      {
        label: 'Create a new organization',
        labeled: 'Organization created',
        content: this.renderCreateOrganization(),
        description: (
          !MConfigs.isOnPremise ?
          <>
            Name your organization, select a package and click <b>Create</b> to get started.
          </> :
          <>
          Name your organization and click <b>Create</b> to get started.
          </> 
        ),
        nextHandle: true,
        // remove stepper for create organization step on license admin server
        // see https://katalon.atlassian.net/browse/KO-322 for more details
        disabledStepper: MConfigs.isLicenseAdminServer && true,
      },
      {
        label: 'Set up the first project',
        labeled: 'Project created',
        content: this.renderCreateProject(),
        description: (
          <>
            Create a project to view test results and leverage team collaboration.
          </>
        ),
        nextHandle: true,
      },
      {
        label: 'Share projects to your members',
        labeled: 'Projects shared',
        content: this.renderInviteUsers(),
        description: (
          <>
            Invite your members to view all test results and share the workload together.
          </>
        ),
      },
      {
        label: 'Upload the first test results',
        labeled: 'Results uploaded',
        content: this.renderUploadTestResult(),
        description: (
          <>
            Upload your test results from Katalon Studio to start working with <b>TestOps Center</b>.
          </>
        ),
      },
    ];

    return (
      <OnBoardingStepper
        ref={this.tableRef}
        id="quick-setup"
        data-trackid="quick-setup"
        steps={steps}
        startBack={2}
        handleFinal={this.handleFinal}
        startStep={this.fromStep - 1 || 0}
      />
    );
  }

  render() {
    return (this.renderQuickSetup());
  }
}

OnBoardingOrganization.propTypes = {
  step: PropTypes.number,
  organizationId: PropTypes.number,
};

OnBoardingOrganization.defaultProps = {
  step: undefined,
  organizationId: undefined,
};

export default OnBoardingOrganization;
