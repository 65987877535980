import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import MTableColumnDataMapping from '../../components/table/models/MTableColumnDataMapping';
import { t } from '../../i18n/t';
import DataTable from './DataTable';
import MachineRemovalDialog from '../dialog/MachineRemovalDialog';
import { buildDefaultFilter } from '../search-query/FilterQueryHelper';
import { IconDelete } from '../../images/KitIcons';
import DecoratorConstants from '../../utils/DecoratorConstants';

class MachineTable extends React.Component {

  constructor(props) {
    super(props);
    this.tableRef = null;
    this.deactivateRef = React.createRef();
    this.state = {
      selectedMachine: null
    };

    this.refreshData = this.refreshData.bind(this);
  }

  refreshData() {
    this.tableRef.refreshData();
  }

  showDeactivateDialog(machine) {
    this.setState(
      { selectedMachine: machine },
      () => this.deactivateRef.current.toggle(),
    );
  }

  renderDeactivateDialog() {
    const { organizationId } = this.props;
    return (
      <MachineRemovalDialog
        ref={this.deactivateRef}
        machine={this.state.selectedMachine}
        organizationId={organizationId}
        afterRemove={() => this.refreshData()}
      />
    );
  }

  headers() {
    const { showProductType } = this.props;
    let headers = [
      new MTableColumnDataMapping(
        t('table-header#machine-key'),
        'machineKey',
      ),
    ];

    if (showProductType) {
      headers = headers.concat([
        new MTableColumnDataMapping(
          t('table-header#product'),
          'feature',
          DecoratorConstants.featureDecorator,
        ),
      ]);
    }

    headers = headers.concat([
      new MTableColumnDataMapping(
        t('table-header#action'),
        'id',
        (name, row) => (
          <Button
            className="btn-icon-only"
            data-trackid="remove-active-machine"
            title="Remove Machine"
            color="link"
            onClick={() => this.showDeactivateDialog(row)}
          >
            <IconDelete />
          </Button>
        ),
        true,
      ),
    ]);


    return headers;
  }

  render() {
    const defaultSort = [
      'id,desc',
    ];

    const defaultFilter = buildDefaultFilter('machineKey', '~', t('license_keys#machine_id'));

    const newProps = {
      entityType: 'Machine',
      title: t('Machine'),
      defaultSort,
      columnMapping: this.headers(),
      defaultFilter,
      useSearchQuery: true,
      ...this.props,
    };
    return (
      <>
        <DataTable
          ref={(ref) => {
            this.tableRef = ref;
          }}
          {...newProps}
        />
        {this.renderDeactivateDialog()}
      </>
    );
  }
}

MachineTable.propTypes = {
  showProductType: PropTypes.bool,
  defaultSearchConditions: PropTypes.array.isRequired,
};

export default MachineTable;
