import React from 'react';
import IconButton from '@katalon-studio/katalon-ui/IconButton';
import { t } from '../../i18n/t';
import { IconDownloadKSNav, IconDownloadKSNavActive } from '../../images/CustomNewIcon';
import DocumentLink from '../../utils/DocumentLink';

function DownloadKSButton() {
  const handleClick = () => {
    window.open(DocumentLink.DOWNLOAD_KATALON_OFFICIAL_PAGE);
  };

  return (
    <IconButton
      title={t('download-ks-package')}
      size="medium"
      onClick={handleClick}
      data-trackid="open-download-popup"
    >
      <span className="icon"><IconDownloadKSNav /></span>
      <span className="icon-hover"><IconDownloadKSNavActive /></span>
    </IconButton>
  );

}

export default DownloadKSButton;
