import React from 'react';
import { Button, Form, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import Services from '../../utils/Services';
import Notification from '../../utils/Notification';
import MContext from '../../models/MContext';

class ArchiveProjectDialog extends React.Component {
  constructor(props) {
    super(props);
    this.orgName = MContext.organization.name;

    this.toggle = this.toggle.bind(this);
    this.saveArchive = this.saveArchive.bind(this);
    this.cancelArchive = this.cancelArchive.bind(this);
    this.state = {
      modal: false,
    };
  }

  toggle() {
    const { modal } = this.state;
    this.setState({
      modal: !modal,
    });
  }

  saveArchive(e) {
    e.preventDefault();
    const { project, status } = this.props;

    const newProjectData = {
      id: project.id,
      status: status,
    };

    Services.updateStatusProject(newProjectData)
      .then(() => {
        const { afterUnarchiveProject } = this.props;
        Notification.pushSuccess(`The project ${project.id} unarchived.`);
        if (afterUnarchiveProject) {
          afterUnarchiveProject();
        }
        this.toggle();
      })
      .catch(() => {
        this.toggle();
      });
  }

  cancelArchive() {
    this.toggle();
  }

  render() {
    const { project } = this.props;

    if (project) {
      return (
        <div>
          <Modal isOpen={this.state.modal} toggle={this.toggle}>
            <Form data-trackid="unarchive-project" onSubmit={this.saveArchive}>
              <ModalHeader>Unarchive the project</ModalHeader>
              <ModalBody>
                <p>
                  Are you sure you want to unarchive the{" "}
                  <strong>{project.name}</strong> project?
                </p>
                <p>
                  Other projects in{" "}
                  <strong>{this.orgName}</strong> may be automatically archived
                  once you have reached the maximum number of projects in your
                  Organization.
                </p>
              </ModalBody>
              <ModalFooter>
                <Button color="secondary" onClick={this.cancelArchive}>
                  Cancel
                </Button>
                <Button type="submit" color="danger">
                  Unarchive this project
                </Button>
              </ModalFooter>
            </Form>
          </Modal>
        </div>
      );
    }
    return null;
  }
}

export default ArchiveProjectDialog;
