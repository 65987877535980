import React from 'react';
import { t } from '../i18n/t';
import { IconDuration, IconProfile, IconStartTime, IconTestCloudTunnel } from '../images/CustomIcon';
import ExecutionStatusBar from '../pages/execution/ExecutionStatusBar';
import RunningExecution from '../pages/execution/RunningExecution';
import DecoratorConstants from '../utils/DecoratorConstants';
import Time from '../utils/Moment';
import { buildSearchCondition } from './search/SearchUtils';
import ObjectInfo from './summary/ObjectInfo';
import ExecutionTestResultDataTable from './table/ExecutionTestResultDataTable';
import ExecutionTestSuiteDataTable from './table/ExecutionTestSuiteDataTable';
import Routes from '../utils/Routes';
import TestCloudHelper from '../utils/TestCloudHelper';
import Arrays from '../utils/Arrays';
import MFlags from '../models/MFlags';

function TestRunSideBar({ testRun: execution }) {
  const profiles = execution.executionTestSuiteResources.map(
    (executionTestSuiteResource) => executionTestSuiteResource.profiles
  );

  const routes = new Routes({ executionId: execution.order });
  const isDisplayTestCloudTunnelIcon = TestCloudHelper.isDisplayTestCloudTunnelIcon(execution);

  const infoItems = [
    ...Arrays.insertIf(isDisplayTestCloudTunnelIcon, {
      icon: <IconTestCloudTunnel />,
      text: t('test-cloud-local-testing'),
    }),
    {
      text: (execution.executionStage === 'TERMINATE')
        || (MFlags.changeStageAndStatusExecutionRule
        && execution.executionStage === 'IMPORTING') ?
        DecoratorConstants.statusBadge(t('incomplete-test-run')) :
        DecoratorConstants.statusBadge(execution.status),
    },
    {
      icon: <IconStartTime />,
      text: Time.format(execution.startTime),
    },
    {
      icon: <IconDuration />,
      text: Time.duration(execution.elapsedDuration),
    },
    {
      icon: <IconProfile />,
      text: DecoratorConstants.profile(profiles),
    },
    {
      text: DecoratorConstants.detailsTestRun(t('details'), routes.execution_details_link),
    },
  ];

  if (execution.executionStage === 'RUNNING') {
    return <RunningExecution execution={execution} />;
  }
  return (
    <>
      <ObjectInfo items={infoItems} />
      <ExecutionStatusBar execution={execution} hideChart />
      <ExecutionTestResultDataTable
        title={t('failed_test_results')}
        defaultSearchConditions={[
          buildSearchCondition('Execution.id', '=', execution.id),
          buildSearchCondition('status', '!=', 'PASSED'),
          buildSearchCondition('status', '!=', 'SKIPPED'),
        ]}
        useSearchQuery={false}
        useInnerDialog
      />
      <ExecutionTestSuiteDataTable
        title={t('test_suites')}
        defaultSearchConditions={[
          buildSearchCondition('Execution.id', '=', execution.id),
        ]}
      />
    </>
  );
}

export default TestRunSideBar;
