/**
 * @deprecated due to KO-665
 * @see ListOrganizationsAndProjects
 */
import React from 'react';
import { Divider, ListSubheader, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { buildSearchCondition } from '../search/SearchUtils';
import Routes from '../../utils/Routes';
import MContext from '../../models/MContext';
import { PageType } from '../../utils/Constants';
import ImageProfile from '../avatar/ImageProfile';
import Arrays from '../../utils/Arrays';
import { next } from '../../utils/Count';
import MAuth from '../../models/MAuth';
import { t } from '../../i18n/t';
import Services from '../../utils/Services';
import GroupEvent from '../../utils/track/GroupEvent';

class RecentProjects extends React.Component {

  constructor(props) {
    super(props);
    const { type } = props;

    this.state = {
      recentProjects: [],
      isLoading: true,
    };

    this.teamId = MContext.teamId;

    if (MAuth.isLoggedIn && (type === PageType.TEAM || type === PageType.PROJECT)) {
      this.organizationId = MContext.team.organizationId;
    } else {
      this.organizationId = MContext.organizationId;
    }
    this.renderRecentProjectsDropdown = this.renderRecentProjectsDropdown.bind(this);
  }

  componentDidMount() {
    this.getRecentProjects();
  }

  getRecentProjects() {
    const params = {
      pagination: {
        page: 0,
        size: 6,
        sorts: ['accessedAt,desc'],
      },
      conditions: [
        buildSearchCondition('Organization.id', '=', this.organizationId),
      ],
      type: 'RecentProject',
    };
    return Services.search(params)
      .then(({ content: recentProjects }) => {
        this.setState({ recentProjects });
      }).finally(() => {
        this.setState({ isLoading: false });
      });
  }

  renderRecentProjectsDropdown() {
    const { recentProjects, isLoading } = this.state;
    const projects = recentProjects.map(({ id, name, teamId }) => {
      const route = new Routes({
        projectId: id,
        teamId
      });
      const link = route.project_link;
      const icon = (
        <ImageProfile
          name={name}
          diameter={24}
        />
      );
      return { link, name, icon };
    });

    const route = new Routes({
      organizationId: this.organizationId,
      teamId: this.teamId,
    });
    const projectMenus = [
      {
        title: t('projects'),
      },
      {
        header: t('recent_project'),
      },
      ...Arrays.insertIf(isLoading,
        {
          loading: true,
        }),
      ...Arrays.insertIf(projects.length > 0,
        ...projects),
      {
        divider: true
      },
      {
        link: route.organization_projects_link,
        name: t('view_projects'),
        dataTrackId: 'view-all-projects',
        dataGroupId: GroupEvent.ACCESS_REPORT
      },
    ];
    return (
      <div className="top-nav-dropdown recent-project">
        {projectMenus && projectMenus.map(({ name, link, icon, divider, header, title, loading, dataTrackId, dataGroupId }) => {
          if (loading) {
            return (
              <div className="text-center">
                {t('table#loading-message')}
              </div>
            );
          }

          if (divider) {
            return <Divider key={next()} />;
          }

          if (title) {
            return (
              <div className="title">
                {title}
              </div>
            );
          }

          if (header) {
            return (
              <ListSubheader key={next()} component="div">
                {header}
              </ListSubheader>
            );
          }

          if (link) {
            return (
              <ListItem
                key={next()}
                button
                component="a"
                href={link}
                data-trackid={dataTrackId}
                data-groupid={dataGroupId}
              >
                {icon && (
                  <>
                    <ListItemIcon className="dropdown-menu-recent-icon">
                      {icon}
                    </ListItemIcon>
                    <ListItemText primary={name} title={name} />
                  </>
                )}
                {!icon && (
                  <ListItemText className="alone-text" primary={name} title={name} />
                )}
              </ListItem>
            );
          }
          return null;
        })}
      </div>
    );
  }

  render() {
    return this.renderRecentProjectsDropdown();
  }
}

export default RecentProjects;
