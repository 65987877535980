import React from 'react';
import { startCase, toLower, debounce } from 'lodash';
import { Row, Col, Card, CardBody, Button, Label } from 'reactstrap';
import DefaultLayout from '../../components/DefaultLayout';
import PageComponent from '../../components/PageComponent';
import ObjectSummary from '../../components/summary/ObjectSummary';
import MContext from '../../models/MContext';
import Services from '../../utils/Services';
import { t } from '../../i18n/t';
import TestSchedules from './component/TestSchedules';
import BaselineImagesView from './component/BaselineImagesView';
import { BaselineCollectionDecorator } from './BaselineCollectionDecorator';
import { IconViewHistory, IconYellowWarning } from '../../images/CustomNewIcon';
import Routes from '../../utils/Routes';
import CardBorder from '../../components/card/CardBorder';
import ContentField from '../../components/card/ContentField';
import PageButtonToolbar from '../../components/PageButtonToolbar';
import DecoratorConstants from '../../utils/DecoratorConstants';
import GroupEvent from '../../utils/track/GroupEvent';
import Notification from '../../utils/Notification';
import Select from '../../components/Select';
import { VISUAL_TESTING_COMPARISON_METHOD, OrganizationFeature, BASELINE_COLLECTION_PROPERTY, DEBOUNCE_IN_SECONDS } from '../../utils/Constants';
import ThresholdSlider from './component/ThresholdSlider';
import { sendAnalyticEventForAction } from '../../utils/SegmentAnalytics';
import Tooltip from '../../components/TooltipComponent';
import ConfigBaselineDialog from './component/dialog/ConfigBaselineDialog';

class BaselineCollectionGroupDetail extends PageComponent {
  constructor(props) {
    super(props);
    this.meta.id = 'page-visual-testing-baseline-detail';
    this.meta.title = t('baseline-collection-group-detail#title', { order: MContext.baselineCollectionGroupOrder });

    this.baselineCollectionGroupOrder = MContext.baselineCollectionGroupOrder;
    this.state = {
      baselineCollectionGroup: null,
      visualTestingComparisonMethod: VISUAL_TESTING_COMPARISON_METHOD.PIXEL,
      visualTestingPlan: null,
      pixelSensitivity: 0,
      isOpenDialogUploadBaselines: false,
    };
    this.renderHeader = this.renderHeader.bind(this);
    this.renderBody = this.renderBody.bind(this);
    this.getBaselineCollectionGroup = this.getBaselineCollectionGroup.bind(this);
    this.handleSaveChange = this.handleSaveChange.bind(this);
    this.handleDefaultMethodChange = this.handleDefaultMethodChange.bind(this);
    this.handlePropertiesChange = this.handlePropertiesChange.bind(this);
    this.updateThresholdSlider = this.updateThresholdSlider.bind(this);
    this.handleOpenDialogUploadBaselines = this.handleOpenDialogUploadBaselines.bind(this);
    this.handleCloseDialogUploadBaselines = this.handleCloseDialogUploadBaselines.bind(this);
    this.debouncePropertiesChange = debounce(this.handlePropertiesChange, DEBOUNCE_IN_SECONDS);

    this.visualTestingComparisonMethods = [
      {
        value: VISUAL_TESTING_COMPARISON_METHOD.PIXEL,
        label: startCase(toLower(VISUAL_TESTING_COMPARISON_METHOD.PIXEL))
      },
      {
        value: VISUAL_TESTING_COMPARISON_METHOD.LAYOUT,
        label: startCase(toLower(VISUAL_TESTING_COMPARISON_METHOD.LAYOUT))
      }
    ];
  }

  componentDidMount() {
    this.getBaselineCollectionGroup();
    this.getVisualTestingPlan();
  }

  getVisualTestingPlan() {
    const { isTeamDemo } = MContext;
    if (!isTeamDemo) {
      const organization = MContext.targetOrganization;
      Services.getVisualTestingQuota(organization.id).then((visualTestingPlan) => {
        this.setState({ visualTestingPlan });
      });
    }
  }

  getBaselineCollectionGroup(isHandler = true) {
    Services.getBaselineCollectionGroup(this.baselineCollectionGroupOrder)
      .then(({ content }) => {
        if (content.length !== 0) {
          // get first item BaselineCollectionGroup
          this.setState({ baselineCollectionGroup: content[0] }, () => {
            const { baselineCollectionGroup } = this.state;
            if (isHandler && baselineCollectionGroup.latestVersion) {
              const { latestVersion } = baselineCollectionGroup;
              this.setState({
                visualTestingComparisonMethod: latestVersion.draftDefaultMethod ?? latestVersion.defaultMethod,
                pixelSensitivity: latestVersion.draftThreshold ?? latestVersion.threshold
              });
            }
          });
        }
      });
  }

  renderObjectSummary() {
    const { baselineCollectionGroup } = this.state;
    const urlParams = {
      baselineInformationGroupDetail: {
        baselineCollectionGroup
      },
    };
    return (
      <ObjectSummary params={urlParams} />
    );
  }

  renderHeader() {
    return (
      <>
        {this.renderObjectSummary()}
      </>
    );
  }

  handleOpenDialogUploadBaselines() {
    this.setState({ isOpenDialogUploadBaselines: true });
  }

  handleCloseDialogUploadBaselines() {
    this.setState({ isOpenDialogUploadBaselines: false });
  }

  getTooltipContent(isVSTPro) {
    if (!isVSTPro) {
      return t('visual-testing#baseline-collection#upload-image-tooltip-vst-standard');
    }
    return null;
  }

  renderImageComponent() {
    const { baselineCollectionGroup } = this.state;
    if (baselineCollectionGroup.latestVersion) {
      const { latestVersion } = baselineCollectionGroup;
      const { id, version } = latestVersion;
      const isVSTPro = this.isVisualTestingPro();
      const isDisabled = !isVSTPro || latestVersion.unsaved;
      const tooltipContent = this.getTooltipContent(isVSTPro);
      return (
        <>
          <div className="baseline-image-title">
            <div className="title">
              {`${t('baseline-images')} (${BaselineCollectionDecorator.versionDecorator(version)}) - ${t('baseline-latest-version')}`}
            </div>
            <Button
              className="ml-1 history-button"
              onClick={Routes.goToBaselineCollectionGroupHistory}
              color="link"
            >
              <IconViewHistory className="mr-2" /> {t('history')}
            </Button>
            <div className="ml-auto d-flex align-items-center">
              {
                latestVersion.unsaved && isVSTPro &&
                <Tooltip text={t('visual-testing#baseline-collection#upload-image-tooltip-unsaved')} placement="bottom-start">
                  <IconYellowWarning width={20} height={17} className="mr-2" />
                </Tooltip>
              }
              <Button
                id="upload-baseline-images-button"
                className="secondary"
                onClick={this.handleOpenDialogUploadBaselines}
                disabled={isDisabled}
                title={tooltipContent}
                data-trackid="upload-baseline-image"
                data-groupid={GroupEvent.ACCESS_REPORT}
              >
                {t('visual-testing#baseline-collection#upload-image')}
              </Button>
            </div>
          </div>
          <BaselineImagesView
            baselineCollectionId={id}
            isLatestVersion
            baselineCollectionGroupId={baselineCollectionGroup.id}
            onRefreshData={this.getBaselineCollectionGroup}
          />
        </>
      );
    }
    return null;
  }

  renderTestSchedules() {
    return (
      <div id="related-test-schedules">
        <TestSchedules groupOrder={this.baselineCollectionGroupOrder} />
      </div>
    );
  }

  handleDefaultMethodChange(option) {
    const { visualTestingComparisonMethod } = this.state;
    if (option?.value !== visualTestingComparisonMethod) {
      this.setState({ visualTestingComparisonMethod: option.value });
      this.debouncePropertiesChange(option.value, BASELINE_COLLECTION_PROPERTY.DEFAULT_METHOD);
    }
  }

  isVisualTestingPro() {
    const { visualTestingPlan } = this.state;
    return visualTestingPlan && (
      visualTestingPlan.trial || visualTestingPlan.visualTestingFeature === OrganizationFeature.VISUAL_TESTING_PRO);
  }

  updateThresholdSlider(value) {
    const { pixelSensitivity } = this.state;
    if (value !== pixelSensitivity) {
      this.setState({ pixelSensitivity: value });
      this.debouncePropertiesChange(value, BASELINE_COLLECTION_PROPERTY.PIXEL_SENSITIVITY);
    }
  }

  handlePropertiesChange(value, propertyKey) {
    const { baselineCollectionGroup } = this.state;
    let property = null;
    switch (propertyKey) {
      case BASELINE_COLLECTION_PROPERTY.DEFAULT_METHOD:
        property = { draftDefaultMethod: value };
        sendAnalyticEventForAction('change-default-comparison-method', { 'data-groupid': GroupEvent.ACCESS_REPORT });
        break;
      case BASELINE_COLLECTION_PROPERTY.PIXEL_SENSITIVITY:
        property = { draftThreshold: value };
        sendAnalyticEventForAction('select-pixel-tolerance', { 'data-groupid': GroupEvent.ACCESS_REPORT });
        break;
      default:
        break;
    }
    Services.saveDraftBaselineCollection({
      id: baselineCollectionGroup.latestVersion.id,
      ...property
    }).then(() => {
      this.getBaselineCollectionGroup(false);
    });
  }

  renderProperties() {
    const { baselineCollectionGroup, visualTestingComparisonMethod, pixelSensitivity } = this.state;
    const { latestVersion, lastRun, updatedAt } = baselineCollectionGroup;
    const constructedLink = new Routes({
      executionId: lastRun?.execution.order,
    });
    const isVSTPro = this.isVisualTestingPro();
    const comparisonMethod = isVSTPro ? visualTestingComparisonMethod : VISUAL_TESTING_COMPARISON_METHOD.PIXEL;
    const pixelThreshold = isVSTPro ? pixelSensitivity : 0;
    return (
      <CardBorder id="properties" className="card-border card-properties">
        <div className="card-border-title">
          {t('properties')}
        </div>
        <Row className="align-items-center property-item">
          <Col sm="7" className="py-2">
            <Label className="title mb-0">{t('updated_on')}</Label>
          </Col>
          <Col sm="5" className="hover py-2">
            <ContentField id="updatedOn" value={DecoratorConstants.timeDecoratorValue(updatedAt)} />
          </Col>
        </Row>
        <Row className="align-items-center property-item">
          <Col sm="7" className="py-2">
            <Label className="title mb-0">{t('plan#last_run')}</Label>
          </Col>
          <Col sm="5" className="hover py-2">
            <ContentField id="lastRun" value={DecoratorConstants.idDecorator('execution.order', lastRun, constructedLink.keyes_execution_details_link)} />
          </Col>
        </Row>
        <Row className="align-items-center property-item">
          <Col sm="7" className="py-2">
            <Label className="title mb-0">{t('number-of-images')}</Label>
          </Col>
          <Col sm="5" className="hover py-2">
            <ContentField id="numberOfImages" value={latestVersion.numberOfBaselines} />
          </Col>
        </Row>
        <Row className="align-items-center property-item">
          <Col sm="7" className="py-2">
            <Label className="title mb-0">{t('updated-by')}</Label>
          </Col>
          <Col sm="5" className="py-2 hover">
            <ContentField id="updatedBy" value={DecoratorConstants.renderUserWithoutNameDecorator(latestVersion.updatedBy, null)} />
          </Col>
        </Row>
        <Row className="align-items-center property-item">
          <Col sm="7" className="py-2">
            <Label className="title mb-0">
              {t('baseline-collection#properties#default-comparison-method')}
              <Tooltip text={t('visual-testing#baseline-collection#tootip-default-method')} placement="bottom" />
            </Label>
          </Col>
          <Col sm="5" className="py-2">
            <Select isDisabled={!isVSTPro} value={comparisonMethod} options={this.visualTestingComparisonMethods} onChange={this.handleDefaultMethodChange} />
          </Col>
        </Row>
        <Row className="align-items-center property-item mb-3">
          <Col sm="7" className="py-2">
            <Label className="title mb-0">
              {t('visual-testing#pixel-sensitivity')}
              <Tooltip text={t('visual-testing#baseline-collection#tootip-pixel-sensitivity')} placement="bottom" />
            </Label>
          </Col>
          <Col sm="5" className="pt-4">
            <ThresholdSlider
              disabled={!isVSTPro}
              value={pixelThreshold}
              sx={{
                width: '6.7rem',
                marginLeft: '0.4rem',
                fontSize: '0.857rem',
              }}
              onChange={this.updateThresholdSlider}
            />
          </Col>
        </Row>
      </CardBorder>
    );
  }

  handleSaveChange() {
    const { baselineCollectionGroup } = this.state;
    const { id } = baselineCollectionGroup.latestVersion;
    Services.saveIgnoringZones(id).then((baselineCollection) => {
      this.getBaselineCollectionGroup();
      Notification.pushSuccess(t('visual_testing_save_success', { version: baselineCollection.version }), t('notification-title#success'));
    });
  }

  renderBody() {
    const { baselineCollectionGroup, isOpenDialogUploadBaselines } = this.state;
    const projectId = MContext.projectId;
    const { latestVersion } = baselineCollectionGroup;
    return (
      <>
        <PageButtonToolbar>
          <Button
            color="primary"
            disabled={!latestVersion.unsaved}
            onClick={this.handleSaveChange}
            data-trackid="page-visual-testing-save"
            data-groupid={GroupEvent.ACCESS_REPORT}
          >
            {t('save_changes')}
          </Button>
        </PageButtonToolbar>
        <Card className="detail-card">
          <CardBody className="detail-card-body row">
            <Col>
              {this.renderImageComponent()}
            </Col>
            <Col xs="auto" className="detail-fixed-column">
              {this.renderProperties()}
              {this.renderTestSchedules()}
            </Col>
          </CardBody>
        </Card>
        {isOpenDialogUploadBaselines &&
          <ConfigBaselineDialog
            id="upload-baseline-images-dialog"
            isOpen={isOpenDialogUploadBaselines}
            handleClose={this.handleCloseDialogUploadBaselines}
            baselineCollectionGroupOrder={this.baselineCollectionGroupOrder}
            baselineCollectionName={baselineCollectionGroup?.name}
            title={t('visual-testing#baseline-collection#title-upload-baselines-dialog')}
            projectId={projectId}
            onRefreshData={this.getBaselineCollectionGroup}
          />}
      </>
    );
  }

  render() {
    const { baselineCollectionGroup } = this.state;
    if (baselineCollectionGroup) {
      return (
        <DefaultLayout
          renderHeader={this.renderHeader}
          renderBody={this.renderBody}
        />
      );
    }
    return null;
  }
}

export default BaselineCollectionGroupDetail;
