const MAccountFlags = {
  subscriptionAndPaymentMethodDisabled: false,

  parse(configs) {
    Object.keys(MAccountFlags).forEach((flag) => {
      if (typeof this[flag] !== 'function' && configs[flag] !== null) {
        this[flag] = configs[flag];
      }
    });
  },
};

export default MAccountFlags;
