import PropTypes from 'prop-types';
import React from 'react';
import MTableColumnDataMapping from '../../components/table/models/MTableColumnDataMapping';
import { t } from '../../i18n/t';
import MContext from '../../models/MContext';
import DecoratorConstants from '../../utils/DecoratorConstants';
import Routes from '../../utils/Routes';
import { buildDefaultFilter } from '../search-query/FilterQueryHelper';
import { buildSortQuery } from '../search-query/SortQueryHelper';
import DataTable from './DataTable';
import MPaginationMapping from './models/MPaginationMapping';
import Link from '../Link';

class TestObjectDataTable extends React.Component {

  constructor(props) {
    super(props);
    this.teamId = MContext.teamId;
    this.projectId = MContext.projectId;
  }

  render() {
    const {
      title,
      defaultSearchConditions,
    } = this.props;

    const headers = [
      new MTableColumnDataMapping(
        'Name',
        'name',
        (name, row) => {
          const constructedLink = new Routes({
            teamId: this.teamId,
            projectId: this.projectId,
            testObjectId: row.id,
          });
          return (
            <>
              <Link href={constructedLink.execution_test_object_history_link}>{DecoratorConstants.testObjectNameDecorator(name, row)}</Link>
              {DecoratorConstants.testObjectFolderDecorator(name, row)}
            </>
          );
        },
        'short-text-column',
      ),
      new MTableColumnDataMapping(
        t('table-header#averageDuration'),
        'averageDuration',
        DecoratorConstants.apiDurationDecorator,
      ),
      new MTableColumnDataMapping(
        t('table-header#maxDuration'),
        'maxDuration',
        DecoratorConstants.apiDurationDecorator,
      ),
      new MTableColumnDataMapping(
        t('table-header#minDuration'),
        'minDuration',
        DecoratorConstants.apiDurationDecorator,
      ),
    ];

    const sortQuery = [
      ...buildSortQuery('averageDuration', t('table-header#averageDuration')),
      ...buildSortQuery('maxDuration', t('table-header#maxDuration')),
      ...buildSortQuery('minDuration', t('table-header#minDuration')),
      ...buildSortQuery('name', 'Name'),
    ];
    const defaultFilter = buildDefaultFilter('name', '~', 'Name');
    const newProps = {
      ...this.props,
      sortQuery,
      defaultFilter
    };
    // TODO do not repeat DataTable's props - use spead operator
    return (
      <DataTable
        title={title}
        entityType="TestObject"
        defaultSearchConditions={defaultSearchConditions}
        columnMapping={headers}
        paginationMapping={new MPaginationMapping()}
        tableId="TestObjects"
        disableAddFilterButton
        defaultSort={['averageDuration,desc']}
        useSearchQuery
        {...newProps}
      />
    );
  }
}

TestObjectDataTable.propTypes = {
  defaultSearchConditions: PropTypes.array,
};

TestObjectDataTable.defaultProps = {
  defaultSearchConditions: [],
};

export default TestObjectDataTable;
