import React from 'react';
import { CardBody, Card, Row, Col, FormGroup } from 'reactstrap';
import { Divider } from '@mui/material';
import { values, filter, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import PageComponent from '../../components/PageComponent';
import MContext from '../../models/MContext';
import ObjectSummary from '../../components/summary/ObjectSummary';
import DefaultLayout from '../../components/DefaultLayout';
import Select from '../../components/Select';
import { t } from '../../i18n/t';
import { IntegrationType } from '../../utils/Constants';
import SlackSetting from './components/SlackSetting';
import KobitonSetting from './components/KobitonSetting';
import UploadKatalonReports from './components/UploadKatalonReport';
import FrameworksIntegration from './components/FrameworksIntegration';
import ReportUploader from './components/ReportUploader';
import MAuth from '../../models/MAuth';
import MConfigs from '../../models/MConfigs';
import Helper from '../../utils/Helper';
import UrlHelper from '../../utils/UrlHelper';
import Routes from '../../utils/Routes';
import JiraSetting from './components/JiraSetting';

class IntegrationsPage extends PageComponent {
  constructor(props) {
    super(props);
    this.meta.id = 'page-integrations';
    this.meta.title = t('integrations');

    this.state = this.getInitialState();
    this.teamId = MContext.teamId;
    this.frameworksIntegrationEnabled = MConfigs.frameworksIntegrationEnabled;
    this.renderHeader = this.renderHeader.bind(this);
    this.renderBody = this.renderBody.bind(this);
    this.selectIntegrationType = this.selectIntegrationType.bind(this);
    this.selectDefaultType = this.selectDefaultType.bind(this);
  }

  getInitialState() {
    return {
      integrationType: {},
      integrationTypeOptions: [],
    };
  }

  renderObjectSummary() {
    const urlParams = {
      integrations: t('integrations'),
    };
    return <ObjectSummary params={urlParams} />;
  }

  renderHeader() {
    return this.renderObjectSummary();
  }

  componentDidMount() {
    this.generateIntegrationsTypeOptions();
  }

  generateIntegrationsTypeOptions() {
    const isTeamManager = MAuth.isTeamManager(this.teamId);
    let integrationTypeOptions = values(IntegrationType);

    const disablePlugins = [
      IntegrationType.JUNIT4,
      IntegrationType.JUNIT5,
      IntegrationType.CYPRESS,
      IntegrationType.TESTNG,
      IntegrationType.JENKINS,
    ];

    // Ignore disable plugins
    integrationTypeOptions = filter(integrationTypeOptions, (value) => !disablePlugins.includes(value));

    if (!this.frameworksIntegrationEnabled) {
      integrationTypeOptions = filter(integrationTypeOptions, (value) => value?.isFrameworksIntegration !== true);
    }

    if (!isTeamManager) {
      integrationTypeOptions = filter(integrationTypeOptions, (value) => value?.isTeamManager !== true);
    }

    integrationTypeOptions = Helper.transformToAlphabet(integrationTypeOptions);
    this.setState({ integrationTypeOptions }, this.selectDefaultType);
  }

  selectDefaultType() {
    let { integrationType } = this.props;
    const { integrationTypeOptions } = this.state;
    const fullTypeOptions = values(IntegrationType);
    const isTeamManager = MAuth.isTeamManager(this.teamId);

    const type = UrlHelper.getSearchParam('type');
    if (type) {
      const matchType = integrationTypeOptions.find((option) => type === option.value.toLowerCase());
      if (matchType) {
        integrationType = matchType;
      } else {
        const adminType = fullTypeOptions.find((option) => type === option.value.toLowerCase());
        const adminOptions = filter(fullTypeOptions, (value) => value?.isTeamManager === true);

        if (!isTeamManager && adminOptions.includes(adminType)) {
          return Routes.goToAccessDeniedPage();
        }
      }
    }

    this.setState({ integrationType }, this.updateUrlParams);
  }

  selectIntegrationType(integrationTypeOption) {
    this.setState({
      integrationType: integrationTypeOption,
    }, this.updateUrlParams);
  }

  updateUrlParams() {
    const { integrationType } = this.state;
    const paramsValue = [{
      paramName: 'type',
      value: integrationType.value.toLowerCase(),
    }];

    // Update URL by params
    UrlHelper.setUrlParamsAndUpdate(paramsValue);
  }

  renderComponent() {
    const { integrationType } = this.state;
    switch (integrationType) {
      case IntegrationType.SLACK_INTEGRATION:
        return <SlackSetting />;
      case IntegrationType.JIRA_INTEGRATION:
        return <JiraSetting />;
      case IntegrationType.KOBITON_INTEGRATION:
        return <KobitonSetting />;
      case IntegrationType.MOCHA:
      case IntegrationType.JEST:
      case IntegrationType.JASMINE:
      case IntegrationType.PYTEST:
      case IntegrationType.PROTRACTOR_JASMINE:
      case IntegrationType.PROTRACTOR_MOCHA:
      case IntegrationType.JUNIT4:
      case IntegrationType.JUNIT5:
      case IntegrationType.CYPRESS:
      case IntegrationType.ROBOT:
      case IntegrationType.TESTNG:
        return <FrameworksIntegration integrationType={integrationType} />;
      case IntegrationType.CLI:
      case IntegrationType.DOCKER:
      case IntegrationType.GITHUB_ACTION:
      case IntegrationType.CIRCLE_CI:
      case IntegrationType.GITLAB_CI:
      case IntegrationType.AWS_CODEBUILD:
      case IntegrationType.AZURE_DEVOPS_PIPELINES:
        return <ReportUploader key={integrationType.value} integrationType={integrationType} />;
      case IntegrationType.KATALON_STUDIO_INTEGRATION:
      default:
        return <UploadKatalonReports />;
    }
  }

  renderBody() {
    const { integrationType, integrationTypeOptions } = this.state;

    return (
      <Card>
        <CardBody>
          <Row>
            <Col sm="12" md="8" lg="6" xl="5">
              <FormGroup className="mt-3">
                <Select
                  required
                  clearable={false}
                  id="integrationType"
                  value={integrationType}
                  onChange={this.selectIntegrationType}
                  options={integrationTypeOptions}
                />
              </FormGroup>
            </Col>
          </Row>
          {this.renderDataComponent()}
        </CardBody>
      </Card>
    );
  }

  renderDataComponent() {
    const { integrationType } = this.state;

    return (
      <div className="mt-4">
        <div className="integration-type-title">
          {integrationType.label}
        </div>
        <Divider />
        <div className="mt-3">
          {this.renderComponent()}
        </div>
      </div>
    );
  }

  render() {
    const { integrationType } = this.state;
    const isTeamDemo = MContext.isTeamDemo;

    if (!isEmpty(integrationType) && !isTeamDemo) {
      return (
        <DefaultLayout
          renderHeader={this.renderHeader}
          renderBody={this.renderBody}
        />
      );
    }

    return null;
  }
}

IntegrationsPage.propTypes = {
  integrationType: PropTypes.object,
};

IntegrationsPage.defaultProps = {
  integrationType: IntegrationType.KATALON_STUDIO_INTEGRATION,
};
export default IntegrationsPage;
