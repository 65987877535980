import { ListItem } from '@mui/material';
import React, { Component } from 'react';
import { Button, ButtonToolbar, Form } from 'reactstrap';
import Input from '../../Input';
import DropDownFilter from './DropDownFilter';

class InputFilter extends Component {
  constructor(props) {
    super(props);

    this.onInputSearch = this.onInputSearch.bind(this);
    this.applyFilter = this.applyFilter.bind(this);
    this.clear = this.clear.bind(this);
    this.filterMenu = React.createRef();
    this.state = this.defaultState();
  }

  defaultState() {
    return ({
      searchInput: '',
      selectedFilter: '',
    });
  }

  onInputSearch(event) {
    const searchInput = event.target.value;
    this.setState(
      { searchInput },
    );
  }

  closeDialog = () => {
    this.filterMenu.current.handleToggle();
  }

  clear() {
    this.setState(this.defaultState());
    this.filterMenu.current.applyFilter(null);
  }

  applyFilter(event) {
    event.preventDefault();
    const { searchInput } = this.state;
    const { label, operator } = this.props;
    if (searchInput !== '') {
      this.setState(
        { selectedFilter: `${label}: "${searchInput}"` },
        () => this.filterMenu.current.applyFilter(operator + searchInput)
      );
    } else {
      this.filterMenu.current.applyFilter(null);
    }
  }

  buttonLabel() {
    const { label } = this.props;
    const { selectedFilter } = this.state;
    return selectedFilter || `${label}: All`;
  }

  render() {
    const { searchInput } = this.state;
    const { label, placeHolder } = this.props;
    const defaultPlaceHolder = `Filter by ${label.toLowerCase()}`;
    return (
      <DropDownFilter buttonLabel={this.buttonLabel()} {...this.props} ref={this.filterMenu}>
        <Form className="form-filter" onSubmit={this.applyFilter}>
          <ListItem>
            <Input
              autoFocus
              onChange={this.onInputSearch}
              value={searchInput || ''}
              placeholder={placeHolder || defaultPlaceHolder}
            />
          </ListItem>
          <ListItem>
            <ButtonToolbar>
              <Button color="primary" type="submit">
                Update
              </Button>
              <Button color="link" onClick={this.clear}>
                Clear
              </Button>
            </ButtonToolbar>
          </ListItem>
        </Form>
      </DropDownFilter>
    );
  }
}

export default InputFilter;
