import React from 'react';
import { DialogContent, DialogContentText, DialogActions } from '@mui/material';
import { Button, ButtonToolbar } from 'reactstrap';
import PropTypes from 'prop-types';
import CloseableDialogComponent from './CloseableDialogComponent';
import { t } from '../../i18n/t';

function AcknowledgeDialog({ id, isOpen, handleClose, handleAcknowledge, title, content }) {

  return (
    <CloseableDialogComponent
      id={id}
      isOpen={isOpen}
      handleClose={handleClose}
      title={title}
    >
      <DialogContent>
        <DialogContentText>
          <div className="title m-0 text-left">
            {content}
          </div>
        </DialogContentText>
      </DialogContent>
      <DialogActions className="popup-footer justify-content-end p-0">
        <ButtonToolbar>
          <Button
            title={t('acknowledge')}
            color="secondary"
            onClick={handleAcknowledge}
          >
            {t('acknowledge')}
          </Button>
        </ButtonToolbar>
      </DialogActions>
    </CloseableDialogComponent>
  );
}

AcknowledgeDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  id: PropTypes.string,
  handleAcknowledge: PropTypes.func.isRequired,
  maxWidth: PropTypes.string,
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
};

export default AcknowledgeDialog;
