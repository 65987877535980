import React from 'react';
import { List, ListItem, ListItemIcon, ListItemText, IconButton, Divider, ListSubheader } from '@mui/material';
import { Button } from 'reactstrap';
import Icon from '@katalon-studio/katalon-ui/Icon';
import { t } from '../../i18n/t';
import {
  IconDocumentsSmall,
  IconCommunitySmall,
  IconLogout,
  IconSubmitATicketSmall,
  IconReleaseNotesSmall,
  IconUserGear,
  IconOpenInBrowser,
} from '../../images/CustomIcon';
import {
  IconNavLicenseManagementCircle,
  IconNavLicenseUtilizationCircle,
  IconNavOrganizationManagementCircle,
  IconNavSupportManagement,
  IconNavPaymentMethodCircle,
  IconNavSubscriptionManagementCircle,
  IconNavUserManagementCircle,
  IconProjectManagement,
  IconRecent,
  IconRecentActive,
  IconSetting,
  IconBuilding,
  IconSettingActive,
  IconTeamManagement,
  IconTestOpsHome,
  IconAutonomousTesting,
  IconTestOpsSetting
} from '../../images/CustomNewIcon';
import MAuth from '../../models/MAuth';
import MContext from '../../models/MContext';
import Arrays from '../../utils/Arrays';
import { SEARCH_PARAMS, PageType } from '../../utils/Constants';
import Routes from '../../utils/Routes';
import DropDownMenu from './DropDownMenu';
import ImageProfile from '../avatar/ImageProfile';
import MConfigs from '../../models/MConfigs';
import FeedbackDialog from '../FeedbackDialog';
import RouteConstants from '../../utils/RouteConstants';
import DocumentLink from '../../utils/DocumentLink';
import { next } from '../../utils/Count';
import ExecutionTimeline from '../../pages/project_dashboard/components/ExecutionTimeline';
import Apis from '../../utils/Apis';
import MFlags from '../../models/MFlags';
import MAccountFlags from '../../models/MAccountFlags';
import NotificationToggler from '../notification/NotificationToggler';
import GroupEvent from '../../utils/track/GroupEvent';
import DownloadKSMenu from './DownloadKSMenu';
import DownloadKSButton from './DownloadKSButton';
import UrlHelper from '../../utils/UrlHelper';
import CreateTestCaseButton from '../cloud-studio-component/testcase/CreateTestCaseButton';
import Services from '../../utils/Services';

class TopRightNavigationBar extends React.Component {

  constructor(props) {
    super(props);
    this.type = props.type;

    this.userDropdownRef = React.createRef();
    this.feedbackRef = React.createRef();

    if (MAuth.isLoggedIn && (this.type === PageType.TEAM || this.type === PageType.PROJECT)) {
      this.organizationId = MContext.team.organizationId;
      this.organization = MAuth.getOrganization(MContext.team.organizationId);
    } else {
      this.organizationId = MContext.organizationId;
      this.organization = MAuth.getOrganization(this.organizationId);
    }
    this.teamId = MContext.teamId;
    this.projectId = MContext.projectId;
    this.accountId = this.organization?.accountId;

    this.openFeedbackDialog = this.openFeedbackDialog.bind(this);
    this.navigateToEditor = this.navigateToEditor.bind(this);
    this.getOrganizationMigration = this.getOrganizationMigration.bind(this);
    this.isNewOrganizationModal = this.isNewOrganizationModal.bind(this);

    this.state = {
      organizationMigration: null,
    };
  }

  componentDidMount() {
    if (MFlags.disableTheAbilityToUpdateTeamLevel) {
      this.getOrganizationMigration();
    }
  }

  getOrganizationMigration() {
    if (this.organizationId) {
      Services.getOrganizationMigration(this.organizationId).then((organizationMigration) => {
        this.setState({ organizationMigration });
      });
    }
  }

  isNewOrganizationModal() {
    if (!MFlags.disableTheAbilityToUpdateTeamLevel) {
      return false;
    }

    const { organizationMigration } = this.state;
    if (!organizationMigration) {
      return false;
    }
    return organizationMigration.newUI;
  }

  goToUserSettings() {
    if (MConfigs.isLicenseAdminServer) {
      window.location.href = Apis.testOpsUserSettings();
    } else {
      Routes.goToTestOpsUserSettings();
    }
  }

  renderUserContext() {
    const userMenu = [
      {
        divider: true,
      },
      {
        id: 'user',
        icon: <IconUserGear />,
        handle: this.goToUserSettings,
      },
      {
        divider: true,
      },
      {
        id: 'documentation',
        icon: <IconDocumentsSmall />,
        link: DocumentLink.TO_DOCUMENTS,
        isOpenNewTab: true,
      },
      {
        divider: true,
      },
      {
        id: 'release-notes',
        icon: <IconReleaseNotesSmall />,
        link: DocumentLink.TO_RELEASE_NOTES,
        isOpenNewTab: true,
      },
      {
        divider: true,
      },
      {
        id: 'community',
        icon: <IconCommunitySmall />,
        link: DocumentLink.FORUM,
        isOpenNewTab: true,
      },
      {
        divider: true,
      },
      {
        id: 'submit-a-ticket',
        icon: <IconSubmitATicketSmall />,
        link: Apis.katOneSupportPortalPage(),
        isOpenNewTab: true,
      },
      {
        divider: true,
      },
      {
        id: 'switch-account',
        icon: <IconBuilding />,
        link: Apis.katOneWelcomePage(),
      },
      {
        divider: true,
      },
      {
        id: 'logout',
        icon: <IconLogout />,
        link: RouteConstants.logout,
      },
    ];

    const btn = (props) => (
      <IconButton key="profile" title={MAuth.user.fullName} {...props} size="large">
        <ImageProfile
          diameter={24}
          name={MAuth.user.fullName}
          imgUrl={MAuth.user.avatar}
        />
      </IconButton>
    );

    return (
      <DropDownMenu renderAnchorElement={btn} ref={this.userDropdownRef}>
        <div className="top-right-dropdown">
          <List>
            <ListItem key="user" id="user">
              <div className="user-profile">
                <ImageProfile
                  diameter={50}
                  name={MAuth.user.fullName}
                  imgUrl={MAuth.user.avatar}
                />
                <b>{MAuth.user.fullName}</b>
                <div className="text-muted">{MAuth.user.email}</div>
                {this.renderViewProfileButton()}
              </div>
            </ListItem>
            {userMenu.map((item) => this.renderMenuItem(item))}
          </List>
        </div>

      </DropDownMenu>
    );
  }

  renderViewProfileButton() {
    const isOpenNewTab = !MConfigs.isLicenseAdminServer;

    const buttonProps = {
      target: isOpenNewTab ? '_blank' : undefined,
      rel: isOpenNewTab ? 'noreferrer noopener' : undefined,
      href: Apis.katOneProfilePage()
    };

    return (
      <Button color="primary" className="btn-view-profile" {...buttonProps}>
        {t('view-profile')} <IconOpenInBrowser />
      </Button>
    );
  }

  openFeedbackDialog(event) {
    event.preventDefault();
    this.userDropdownRef.current.toggle();
    this.feedbackRef.current.toggle();
  }

  renderMenuItem({
    id,
    icon,
    link,
    isOpenNewTab,
    divider,
    handle,
    header,
    dataTrackId,
    dataGroupId,
    endAdornment
  }, showDescription) {
    if (divider) {
      return (
        <Divider />
      );
    }

    if (header) {
      return (
        <ListSubheader disableSticky key={next()} component="div">
          {header}
        </ListSubheader>
      );
    }

    const renderPrimaryHeader = () => {
      if (endAdornment) {
        return (
          <div className="dropdown-menu-item-header">
            <span>{t(`settings-${id}`)}</span>
            <span>
              {endAdornment}
            </span>
          </div>
        );
      }

      return (
        <span>{t(`settings-${id}`)}</span>
      );
    };

    if (handle) {
      return (
        <ListItem
          button
          key={id}
          component="a"
          id={id}
          href="#"
          onClick={handle}
          title={t(`settings-${id}-desc`)}
          data-trackid={dataTrackId}
          data-groupid={dataGroupId}
        >
          <ListItemIcon className="dropdown-menu-item-icon">
            {icon}
          </ListItemIcon>
          <ListItemText
            primary={renderPrimaryHeader()}
            secondary={showDescription ? t(`settings-${id}-desc`) : null}
          />
        </ListItem>
      );
    }

    const newTabProps = isOpenNewTab ? {
      target: '_blank',
      rel: 'noreferrer noopener'
    } : {};

    return (
      <ListItem
        button
        key={id}
        component="a"
        id={id}
        href={link}
        title={t(`settings-${id}-desc`)}
        {...newTabProps}
        data-trackid={dataTrackId}
        data-groupid={dataGroupId}
      >
        <ListItemIcon className="dropdown-menu-item-icon">
          {icon}
        </ListItemIcon>
        <ListItemText
          primary={renderPrimaryHeader()}
          secondary={showDescription ? t(`settings-${id}-desc`) : null}
        />
      </ListItem>
    );
  }

  renderManagements() {
    const isLicenseAdminServer = MConfigs.isLicenseAdminServer;
    const route = new Routes({ organizationId: this.organizationId });
    const managements = [
      {
        header: 'TESTOPS SETTINGS',
      },
      ...Arrays.insertIf(!this.isNewOrganizationModal(), {
        id: 'manage_teams',
        link: isLicenseAdminServer
          ? route.organization_teams_link
          : Apis.adminOrganizationAllTeams(this.organizationId),
        icon: <IconTeamManagement />,
        dataTrackId: 'open-teams-management',
        dataGroupId: [GroupEvent.ACCESS_SETTINGS]
      }),
      {
        id: 'manage_projects',
        link: isLicenseAdminServer
          ? route.organization_projects_link
          : Apis.adminOrganizationAllProjects(this.organizationId),
        icon: <IconProjectManagement />,
        dataTrackId: 'open-projects-management',
        dataGroupId: [GroupEvent.ACCESS_SETTINGS],
      }
    ];
    return (
      <>
        {managements.map((item) => this.renderMenuItem(item, true))}
      </>
    );
  }

  renderTestOpsHome() {
    const isLicenseAdminServer = MConfigs.isLicenseAdminServer;
    const route = new Routes({ organizationId: this.organizationId });
    const testOpsHome = {
      id: 'testops_homepage',
      link: isLicenseAdminServer
        ? Apis.testOpsOrganizationHome(this.organizationId, null)
        : route.organization_home_link,
      icon: <IconTestOpsHome />,
    };
    return (
      <>
        {this.renderMenuItem(testOpsHome, true)}
      </>
    );
  }

  renderOrganizationSettings() {
    const route = new Routes({ organizationId: this.organizationId });
    const organizationSetting = {
      id: 'organization',
      link: route.organization_setting_agent_link,
      icon: <IconTestOpsSetting />,
    };
    return (
      <>
        {this.renderMenuItem(organizationSetting, true)}
      </>
    );
  }

  renderKatalonSettings() {
    const route = new Routes({ organizationId: this.organizationId });
    const isBillingManager = MAuth.isBillingManager(this.organizationId);
    const isOrganizationManager = MAuth.isOrganizationManager(this.organizationId);
    const showKatalonSettings = isOrganizationManager || isBillingManager;
    const isRedirectToAdmin = !MConfigs.isLicenseAdminServer;
    const { subscriptionAndPaymentMethodDisabled } = MAccountFlags;
    const { redirectToKatOneOrganizationManagementEnabled, updateSettingsWheelItemsEnabled } = MFlags;
    const userManagementLink = Apis.katOneUserManagementPage({ orgId: this.organizationId });
    const paymentMethodLink = Apis.katOnePaymentMethodPage({ organizationId: this.organizationId });
    const subscriptionManagementLink = Apis.katOneAccountSubscriptionManagementPage({ accountId: this.accountId });
    const isLicenseServer = MConfigs.isLicenseAdminServer;

    let organizationManagementUrl = route.organization_settings_link;
    if (isRedirectToAdmin) {
      if (redirectToKatOneOrganizationManagementEnabled) {
        organizationManagementUrl = Apis.katOneOrganizationManagementPage(this.organizationId);
      } else {
        organizationManagementUrl = Apis.katOneAdminOrganizationSettings(this.organizationId);
      }
    }
    const katalonAdminMenu = [
      ...Arrays.insertIf(isOrganizationManager, {
        id: 'license-utilization',
        icon: <IconNavLicenseUtilizationCircle />,
        link: Apis.katOneLicenseUtilizationPage({ orgId: this.organizationId }),
        isOpenNewTab: !isLicenseServer,
      }),
      ...Arrays.insertIf(showKatalonSettings && !updateSettingsWheelItemsEnabled, {
        id: 'license-management',
        icon: <IconNavLicenseManagementCircle />,
        link: isRedirectToAdmin ? Apis.katOneAdminOrganizationLicenseKeys(this.organizationId) : route.organization_licenses_link,
      }),
      ...Arrays.insertIf(isOrganizationManager, {
        id: 'user-management',
        icon: <IconNavUserManagementCircle />,
        link: userManagementLink,
        isOpenNewTab: !isLicenseServer,
      }),
      ...Arrays.insertIf(isBillingManager && !MConfigs.isOnPremise && !subscriptionAndPaymentMethodDisabled && !updateSettingsWheelItemsEnabled, {
        id: 'payment-method',
        icon: <IconNavPaymentMethodCircle />,
        link: paymentMethodLink,
      }),
      ...Arrays.insertIf(isBillingManager && !MConfigs.isOnPremise && !subscriptionAndPaymentMethodDisabled, {
        id: 'subscription-management',
        icon: <IconNavSubscriptionManagementCircle />,
        link: subscriptionManagementLink,
      }),
      ...Arrays.insertIf(isOrganizationManager, {
        id: 'organization-management',
        icon: <IconNavOrganizationManagementCircle />,
        link: organizationManagementUrl,
      }),
      ...Arrays.insertIf(!updateSettingsWheelItemsEnabled, {
        id: 'support-management',
        icon: <IconNavSupportManagement />,
        link: Apis.katOneSupportManagementPage({ orgId: this.organizationId })
      }),
    ];
    return (
      <>
        {showKatalonSettings ? this.renderMenuItem({
          header: 'TESTOPS ADMIN'
        }) : null}
        {showKatalonSettings ?
          katalonAdminMenu.map((item) => this.renderMenuItem(item, true)) :
          null}
      </>
    );
  }

  renderAutonomousTestingSettings() {
    const route = new Routes({ organizationId: this.organizationId });
    const menu = [
      {
        id: 'manage_e2etg',
        icon: <IconAutonomousTesting />,
        link: route.organization_setting_true_test_link,
        dataTrackId: 'click-open-aut-management',
        dataGroupId: [GroupEvent.ACCESS_AUT_MANAGEMENT],
        endAdornment: MFlags.atgRemoveBetaTag ? null : <span className="badge badge-trial ml-2">{t('beta')}</span>,
      },
    ];
    return (
      <>
        {menu.map((item) => this.renderMenuItem(item, true))}
      </>
    );
  }

  renderSettings() {
    const { type } = this.props;
    const isOrganizationManager = MAuth.isOrganizationManager(this.organizationId);
    const btn = (props) => (
      <IconButton title={t('settings')} {...props} size="large">
        <span className="icon"><IconSetting /></span>
        <span className="icon-hover"><IconSettingActive /></span>
      </IconButton>
    );
    const { e2eTestGenerationEnabled, moveAgentToOrgLevelPhase2Enabled, atgCheckFeaturePermission, } = MFlags;
    const isTrueTestFeature = atgCheckFeaturePermission || e2eTestGenerationEnabled;
    if (type !== PageType.USER) {
      return (
        <DropDownMenu
          renderAnchorElement={btn}
        >
          <div className="top-nav-dropdown top-nav-settings new-top-nav-settings">
            <div className="title">
              {t('settings')}
            </div>
            <List>
              {this.renderKatalonSettings()}
              {this.renderManagements()}
              {isTrueTestFeature && isOrganizationManager && this.renderAutonomousTestingSettings()}
              {this.renderTestOpsHome()}
              {moveAgentToOrgLevelPhase2Enabled && this.renderOrganizationSettings()}
            </List>
          </div>
        </DropDownMenu>
      );
    } else {
      return null;
    }
  }

  renderActivity() {
    const btn = (props) => (
      <IconButton title={t('recent')} data-trackid="recent-work" {...props} size="large">
        <span className="icon"><IconRecent /></span>
        <span className="icon-hover"><IconRecentActive /></span>
      </IconButton>
    );
    return (
      <DropDownMenu
        renderAnchorElement={btn}
      >
        <div className="top-nav-dropdown recent-work ">
          <div className="title">
            {t('executions')}
          </div>
          <div className="test-run-timeline">
            <ExecutionTimeline
              pageSize={5}
              noCard
            />
          </div>
        </div>
      </DropDownMenu>
    );
  }

  renderNotificationToggler() {
    return (<NotificationToggler />);
  }

  navigateToEditor(testCase) {
    Routes.goToGen5EditorPage(testCase.id);
  }

  renderRecordNewTestButton() {
    if (MFlags.g5Editor) {
      return <CreateTestCaseButton
        sx={{
          maxHeight: '32px',
          marginRight: '12px',
          fontSize: '14px',
          alignSelf: 'center',
          '&.MuiButton-root': {
            whiteSpace: 'nowrap',
          },
        }}
        size="medium"
        variant="contained"
        color="secondary"
        startIcon={
          <Icon
            type="fa-solid"
            name="fa-plus"
            textSize="14px"
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              i: {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              },
            }}
          />
          }
        label="record_test_case"
        mainNav
      />;
    }

    return null;
  }

  render() {
    const isTeamDemo = MContext.isTeamDemo;
    const organization = MContext.targetOrganization;
    const openDownloadDialog = UrlHelper.getSearchParam(SEARCH_PARAMS.DOWNLOAD_POPUP) === 'true';
    const disappearDownloadKSDialogEnabled = MFlags.disappearDownloadKSDialogEnabled;
    const downloadKSComponent = disappearDownloadKSDialogEnabled ? <DownloadKSButton /> : <DownloadKSMenu type={this.type} open={openDownloadDialog} />;
    return (
      <div id="top-right-navbar">
        {this.projectId && this.renderRecordNewTestButton()}
        {organization && downloadKSComponent }
        {this.teamId && this.renderNotificationToggler()}
        {this.projectId && this.renderActivity()}
        {/* {this.renderNotification()} */}
        {this.organizationId && !isTeamDemo && this.renderSettings()}
        {MAuth.isLoggedIn && !isTeamDemo && this.renderUserContext()}
        <FeedbackDialog
          ref={this.feedbackRef}
        />
      </div>
    );
  }
}

export default TopRightNavigationBar;
