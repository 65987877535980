import React from 'react';
import { Button, Form, FormGroup, Label, CardBody, Card, FormText, CardFooter, CardHeader, Progress, Row, Col } from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import MContext from '../models/MContext';
import Services from '../utils/Services';
import Notification from '../utils/Notification';
import Uploader from './Uploader';
import { t } from '../i18n/t';
import DocumentLink from '../utils/DocumentLink';
import SelectCustomFieldComponent from './SelectCustomFieldComponent';
import TagInput from './TagInput';
import { next } from '../utils/Count';
import { sendAnalyticEventForAction } from '../utils/SegmentAnalytics';
import { CUSTOM_FIELD_TRACKING } from '../utils/Constants';
import TagListTsx from './tsx-components/TagListTsx';

class ImportKatalonReports extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.fileUploader = null;
    this.uploadFilesInput = null;
    this.projectId = MContext.projectId;
    this.onUploadError = this.onUploadError.bind(this);
    this.handleUpload = this.handleUpload.bind(this);
    this.renderProgressBar = this.renderProgressBar.bind(this);
    this.onUploadProgress = this.onUploadProgress.bind(this);
  }

  handleSelectedTagsChange = (selectedOptions) => {
    sendAnalyticEventForAction('assign-tag-on-manual-import', {});
    const newTags = selectedOptions.filter((tag) => tag?.isNewTag === true);
    if (!isEmpty(newTags)) {
      return Services.createTag(newTags[0]).then((content) => {
        this.setState((prevState) => ({
          selectedTags: [...prevState.selectedTags, content]
        }));
      });
    } else {
      this.setState({
        selectedTags: selectedOptions
      });
    }
    return Promise.resolve();
  };

  handleDeleteTag = (option) => {
    const { selectedTags } = this.state;
    const filteredTags = selectedTags.filter((item) => item.name !== option.name);
    this.setState({
      selectedTags: filteredTags
    });
  };

  setSelectedCustomFields = (customFields) => {
    this.setState({
      selectedCustomFields: customFields,
    });
  };

  convertDataCustomFieldOptions(selectedCustomFields) {
    return selectedCustomFields.map((selectedCustomField) => ({
      id: selectedCustomField.customFieldOption.id,
      definitionId: selectedCustomField.customFieldDefinition.id
    }));
  }

  renderSectionTitle(label) {
    return (
      <>
        <Label className="mt-1 font-weight-bold mr-1">
          {label}
        </Label>
        <span className="generate-date">
          {t('optional')}
        </span>
      </>
    );
  }

  getInitialState() {
    return {
      uploadProgress: '0',
      disableImportReport: false,
      uploadBatchFileResources: [],
      selectedCustomFields: [],
      selectedTags: [],
      tagAndCustomFieldKey: next(),
    };
  }

  onUploadProgress(percent) {
    this.setState({ uploadProgress: percent });
  }

  renderProgressBar() {
    const { uploadProgress } = this.state;
    if (uploadProgress === '0') {
      return null;
    }
    return (
      <Row>
        <Col sm="3" md="3" lg="3" xl="3">
          <FormGroup>
            <Progress value={uploadProgress} />
          </FormGroup>
        </Col>
      </Row>
    );
  }

  handleUpload(e) {
    e.preventDefault();
    this.fileLength = this.uploadFilesInput.files.length;
    this.batch = `${Date.now()}+${uuidv4()}`;

    if (this.fileLength <= 0) {
      Notification.pushError('Cannot process the report.');
    } else {
      this.fileUploader.uploadFile();
      this.setState({
        disableImportReport: true,
      });
    }
  }

  onUploadFinish(data, file) {
    const { projectId } = this;
    this.fileLength--;
    const batch = this.batch;
    const isEnd = this.fileLength === 0;
    const uploadBatchFileResource = {
      folderPath: '',
      fileName: file.name,
      uploadedPath: data.path,
      end: isEnd,
    };
    this.setState((prevState) => ({
      uploadBatchFileResources: [...prevState.uploadBatchFileResources, uploadBatchFileResource]
    }), () => {
      if (isEnd) {
        const { uploadBatchFileResources } = this.state;
        const { selectedTags, selectedCustomFields } = this.state;
        const multipleS3FileResource = {
          uploadBatchFileResources,
          tags: selectedTags,
          customFieldOptions: this.convertDataCustomFieldOptions(selectedCustomFields)
        };
        Services.uploadKatalonReportsInBatchesV2(projectId, batch, multipleS3FileResource)
          .then(() => {
            this.fileUploader.clear();
            Notification.pushSuccess('Upload successfully');
            this.setState({
              disableImportReport: false,
              uploadProgress: '0',
              uploadBatchFileResources: [],
              selectedCustomFields: [],
              selectedTags: [],
              tagAndCustomFieldKey: next(),
            });
          })
          .catch((e) => {
            Notification.pushError(e?.message, 'Cannot process the report.');
          });
      }
    });
  }

  onUploadError(message) {
    Notification.pushError(message);
  }

  renderUploadKatalon() {
    const { projectId } = this;
    const { disableImportReport } = this.state;
    const { canTriggerTestExecution } = this.props;
    const { selectedTags, selectedCustomFields, tagAndCustomFieldKey } = this.state;

    return (
      <Card>
        <CardHeader>
          {t('report#KatalonFromDevice')}
        </CardHeader>
        <CardBody>
          <Form data-trackid="upload-katalon-reports" onSubmit={this.handleUpload}>
            <FormGroup>
              <Label>Select Katalon report files to upload</Label>
              <div>
                <Uploader
                  signingUrl="/api/v1/files/upload-url"
                  signingUrlMethod="GET"
                  contentDisposition="attachment"
                  onProgress={this.onUploadProgress}
                  onFinish={(data, file) => this.onUploadFinish(data, file)}
                  onError={this.onUploadError}
                  signingUrlQueryParams={{
                    projectId,
                    'from-react-s3': true,
                  }}
                  signingUrlWithCredentials
                  uploadRequestHeaders={{
                    'content-type': 'application/octet-stream',
                  }}
                  autoUpload={false}
                  ref={(uploader) => {
                    this.fileUploader = uploader;
                  }}
                  inputRef={(cmp) => {
                    this.uploadFilesInput = cmp;
                  }}
                  multiple
                  disabled={!canTriggerTestExecution}
                />
              </div>
              <FormText color="muted">
                It may take a while to upload large report files.
              </FormText>
            </FormGroup>
            <Row>
              <Col sm="3" md="6" lg="6" xl="5">
                <FormGroup key={tagAndCustomFieldKey}>
                  {this.renderSectionTitle(t('custom-fields#title'))}
                  <SelectCustomFieldComponent
                    selectedCustomFields={selectedCustomFields}
                    setSelectedCustomFields={this.setSelectedCustomFields}
                    trackingType={CUSTOM_FIELD_TRACKING.MANUAL_IMPORT}
                  />
                </FormGroup>
              </Col>
            </Row>
            <div className="tag">
              <Row>
                <Col sm="3" md="6" lg="6" xl="5">
                  <FormGroup key={tagAndCustomFieldKey}>
                    {this.renderSectionTitle(t('tag#title'))}
                    <TagInput
                      selectedTags={selectedTags}
                      handleOnSelectChange={this.handleSelectedTagsChange}
                      enableFetchTagsEachTimeOpen
                    />
                  </FormGroup>
                </Col>
              </Row>
              <div>
                <TagListTsx
                  selectedTags={selectedTags}
                  handleOnDelete={this.handleDeleteTag}
                />
              </div>
            </div>
            <FormGroup>
              <Button
                type="submit"
                color="primary"
                disabled={disableImportReport || !canTriggerTestExecution}
              >
                {t('import')}
              </Button>
            </FormGroup>
            {this.renderProgressBar()}
          </Form>
        </CardBody>
        <CardFooter>
          <div>
            Learn how to&nbsp;
            <a
              href={DocumentLink.UPLOAD_KATALON_REPORT_AUTO_DOCS}
              target="_blank"
              rel="noopener noreferrer"
            >
              upload Katalon Studio reports automatically
            </a>
            &nbsp;or&nbsp;
            <a
              href={DocumentLink.UPLOAD_TEST_RESULT_USING_COMMAND_LINE}
              target="_blank"
              rel="noopener noreferrer"
            >
              using command line
            </a>.
          </div>
        </CardFooter>
      </Card>
    );
  }

  render() {
    return this.renderUploadKatalon();
  }
}

ImportKatalonReports.propsTypes = {
  canTriggerTestExecution: PropTypes.bool.isRequired,
};

export default ImportKatalonReports;
