import React from 'react';
import { t } from '../../../i18n/t';
import DataTable from '../../../components/table/DataTable';
import MTableColumnDataMapping from '../../../components/table/models/MTableColumnDataMapping';
import DecoratorConstants from '../../../utils/DecoratorConstants';
import { buildSearchCondition } from '../../../components/search/SearchUtils';
import { get } from 'lodash';

class FlakinessReport extends React.Component {

  render() {
    const columnMapping = [
      new MTableColumnDataMapping(
        t('name'),
        'name',
        DecoratorConstants.testCaseNameDecorator
      ),
      new MTableColumnDataMapping(
        t('maintainer'),
        'maintainer',
        (name, row) => {
          const user = get(row, name);
          return user && DecoratorConstants.renderUserDecorator(user);
        }
      ),
      new MTableColumnDataMapping(
        t('table-header#history-old-new'),
        'executionTestResults',
        DecoratorConstants.flakinessHistory,
        undefined,
        'nowrap-column',
      ),
      new MTableColumnDataMapping(
        t('table-header#flakiness'),
        'flakiness',
        DecoratorConstants.flakinessDecorator,
        undefined,
        'nowrap-column',
      ),
    ];
    return (
      <DataTable
        title={t('flakiness-report')}
        entityType="TestCaseFlakiness"
        defaultSort={['flakiness,desc']}
        defaultSearchConditions={[
          buildSearchCondition('flakiness', 'is not null', ''),
        ]}
        columnMapping={columnMapping}
        pageSize={10}
      />
    );
  }

}

export default FlakinessReport;
