import React from 'react';
import { Button } from 'reactstrap';
import { t } from '../../i18n/t';

import NoPreviewImage from '../../../images/file-preview/no-preview.svg';
import VideoPlayer from './VideoPlayer';
import MFlags from '../../models/MFlags';

class View extends React.Component {

  renderContent() {

    const { source, caption, fileType } = this.props.data;
    const lowerFileType = fileType ? fileType.toLowerCase() : fileType;
    switch (lowerFileType) {
      case 'png':
      case 'jpg':
        return <img src={source} alt={caption} />;
      case 'mp4':
      case 'webm':
        if (MFlags.videoPlayerEnabled) {
          return (<VideoPlayer src={source} fileType={lowerFileType} />);
        }
      // eslint-disable-next-line no-fallthrough
      case 'txt':
      default:
        return (
          <div className="no-preview">
            <img src={NoPreviewImage} alt="No preview available" />
            <p>We can&apos;t preview this file type.</p>
            <p>Try downloading the file to view it.</p>
            {source && (
              <a href={source} download={caption} className="text-decoration-none">
                <Button type="button" color="primary">{t('download')}</Button>
              </a>
            )}
          </div>
        );
    }
  }

  render() {
    return (
      <div className="gallery-view">
        {this.renderContent()}
      </div>

    );
  }

}

export default View;
