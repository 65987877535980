import React from 'react';
import { Button, Card, CardBody, CardHeader } from 'reactstrap';
import PageComponent from '../components/PageComponent';
import { t } from '../i18n/t';
import ObjectSummary from '../components/summary/ObjectSummary';
import LicenseKeysTable from '../components/table/LicenseKeysTable';
import DefaultLayout from '../components/DefaultLayout';
import { buildSearchCondition } from '../components/search/SearchUtils';
import MAuth from '../models/MAuth';
import MachineTable from '../components/table/MachineTable';
import Time from '../utils/Moment';
import PageButtonToolbar from '../components/PageButtonToolbar';
import Routes from '../utils/Routes';

class UserLicense extends PageComponent {
  constructor(props) {
    super(props);
    this.meta.id = 'page-user-license';
    this.meta.title = t('user_license');
    this.userId = MAuth.user.id;

    this.renderHeader = this.renderHeader.bind(this);
    this.renderBody = this.renderBody.bind(this);
  }

  renderObjectSummary() {
    const urlParams = {
      user_license: t('user_license'),
    };
    return (
      <ObjectSummary params={urlParams} />
    );
  }

  renderHeader() {
    return (
      <>
        {this.renderObjectSummary()}
      </>
    );
  }

  renderMachineTable() {
    const defaultSearchConditions = [
      buildSearchCondition('User.id', '=', MAuth.user.id)
    ];
    return (
      <>
        <MachineTable
          id="machine"
          title={t('machine')}
          defaultSearchConditions={defaultSearchConditions}
          showProductType
        />
      </>
    );
  }

  renderCreateTrialButton() {
    const routes = new Routes();
    const disabledKSE = !MAuth.canCreateOfflineKSE;
    const disableEngine = !MAuth.canCreateOfflineRE;
    return (
      <PageButtonToolbar>
        <Button
          title={t('user_license#create-kse-trial-title')}
          color="secondary"
          href={routes.user_create_kse_license_link}
          disabled={disabledKSE}
        >
          {t('user_license#create-kse-trial')}
        </Button>
        <Button
          title={t('user_license#create-engine-trial-title')}
          color="secondary"
          href={routes.user_create_engine_license_link}
          disabled={disableEngine}
        >
          {t('user_license#create-engine-trial')}
        </Button>
      </PageButtonToolbar>
    );
  }

  renderTrialPeriod() {
    const trialExp = MAuth.user.trialExpirationDate;

    let content;
    if (trialExp) {
      const now = new Date();
      const trialDate = new Date(trialExp);
      if (trialDate > now) {
        content = <>{t('user_license#trial_active')} {Time.formatDate(trialExp, Time.DAY_FULL_FORMAT)}.</>;
      } else {
        content =
          <>
            Your trial period has expired on {Time.formatDate(trialExp, Time.DAY_FULL_FORMAT)}.
            Please <a href="https://www.katalon.com/pricing/" target="_blank" rel="noreferrer noopener"> subscribe </a> to continue using
            Katalon Studio Enterprise and Runtime Engine.
          </>;
      }
    } else {
      content =
        <>
          You have a 30-day trial of Katalon Studio Enterprise and Runtime Engine floating licenses.
          Click <a href="https://www.katalon.com/download/" target="_blank" rel="noreferrer noopener">here</a> to download the app.
        </>;
    }
    return (
      <Card>
        <CardHeader>Information</CardHeader>
        <CardBody>
          <p>{content}</p>
        </CardBody>
      </Card>
    );
  }

  renderOnlineLicense() {
    const defaultSearchConditions = [
      buildSearchCondition('User.id', '=', this.userId),
      buildSearchCondition('organizationId', 'is null', ''),
      buildSearchCondition('type', '!=', 'ENTERPRISE'),
      buildSearchCondition('mode', '=', 'FLOATING'),
      buildSearchCondition('status', '=', 'ACTIVE'),
    ];
    return (
      <LicenseKeysTable
        title={t('license_keys#online')}
        defaultSearchConditions={defaultSearchConditions}
      />
    );
  }

  renderOfflineLicense() {
    const defaultSearchConditions = [
      buildSearchCondition('User.id', '=', this.userId),
      buildSearchCondition('organizationId', 'is null', ''),
      buildSearchCondition('type', '!=', 'ENTERPRISE'),
      buildSearchCondition('mode', '=', 'COMMUTER'),
      buildSearchCondition('status', '=', 'ACTIVE'),
    ];
    return (
      <LicenseKeysTable
        title={t('license_keys#offline')}
        defaultSearchConditions={defaultSearchConditions}
        isOfflineLicense={true}
      />
    );
  }

  renderBody() {
    return (
      <>
        {this.renderCreateTrialButton()}
        {this.renderTrialPeriod()}
        {this.renderOnlineLicense()}
        {this.renderOfflineLicense()}
        {this.renderMachineTable()}
      </>
    );
  }

  render() {
    return (
      <DefaultLayout
        renderHeader={this.renderHeader}
        renderBody={this.renderBody}
      />
    );
  }
}

export default UserLicense;
