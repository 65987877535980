import React from 'react';
import { Row, Col } from 'reactstrap';
import ReactMarkdown from 'react-markdown';
import Moment from '../../utils/Moment';
import DataLoader from '../../components/table/DataLoader';
import { t } from '../../i18n/t';
import ImageProfile from '../avatar/ImageProfile';

class Comment extends React.Component {

  constructor(props) {
    super(props);
    this.commentRef = React.createRef();

    this.refreshData = this.refreshData.bind(this);
    this.renderData = this.renderData.bind(this);
  }

  refreshData() {
    this.commentRef.current.refreshData();
  }

  renderData(data) {
    return (data.map((comment) => (
      <Row className="comment">
        <Col className="comment__avatar mb-3 mb-md-0 mr-0 mr-md-3" sm="12" md="auto">
          <a href="#" title={comment.displayName}>
            <ImageProfile
              imgUrl={comment.displayAvatar}
              name={comment.displayName}
              diameter={50}
            />
          </a>
        </Col>
        <Col className="comment__body" xs="12" md="auto">
          <div className="comment__summary">
            <div>
              <b className="comment__summary__name">{comment.displayName}</b>
              <span className="comment__summary__date">{Moment.format(comment.createdAt)}</span>
            </div>
            <div>{this.props.renderEditTag(comment)}</div>
          </div>
          <div className="comment__content">
            <ReactMarkdown>{comment.content}</ReactMarkdown>
          </div>
        </Col>
      </Row>
    )));
  }

  render() {
    return (
      <DataLoader
        ref={this.commentRef}
        render={this.renderData}
        title={t('comments')}
        {...this.props}
      />
    );
  }

}

export default Comment;
