

import _ from 'lodash';

export default class MChartLabelMapping {
  constructor(fieldName, decorate = (fieldName, item) => _.get(item, fieldName)) {
    this.fieldName = fieldName;
    this.decorate = decorate;
  }
}
