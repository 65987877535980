import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label } from 'reactstrap';
import { t } from '../../../i18n/t';
import Input from '../../Input';
import TestCaseLinkingWithTreeView from './TestCaseLinkingWithTreeView';
import { TEST_TYPE, TestProjectType } from '../../../utils/Constants';

const AddTestCaseView = ({ testProject, testSuitePath, selectedLinkingTestCases, setSelectedLinkingTestCases, commitMessage, onChangeCommitMessage }) => {

  const renderLocation = () => (
    <FormGroup>
      <Label for={t('location')}>{t('location')}</Label>
      <Input plaintext id={t('location')} className="text-break">
        {testProject.name}/{testSuitePath}
      </Input>
    </FormGroup>
  );

  const renderTestCaseLinking = () => {
    const trackingProps = {
      addButton: 'add-selected-test-cases-in-test-suite-creation',
      clearButton: 'clear-selected-test-cases-in-test-suite-creation',
      removeTestCaseIcon: 'delete-selected-test-cases-in-test-suite-creation',
    };
    return (
      <TestCaseLinkingWithTreeView
        key={testProject.id}
        setSelectedLinkingTestCases={setSelectedLinkingTestCases}
        selectedLinkingTestCases={selectedLinkingTestCases}
        testProject={testProject}
        testCaseType={TEST_TYPE.G5_TEST_CASE}
        isAbridgedVersion
        trackingProps={trackingProps}
      />
    );
  };

  const renderCommitMessage = () => (
    <FormGroup>
      <Label for={t('commit-message')}>{t('commit-message')}</Label>
      <Input
        required
        className="publish-dialog__text"
        type="text"
        value={commitMessage}
        onChange={(e) => onChangeCommitMessage(e.target.value)}
        placeHolder={t('commit-placeholder')}
      />
    </FormGroup>
  );

  return (
    <>
      {renderLocation()}
      {renderTestCaseLinking()}
      {testProject?.type === TestProjectType.GIT && renderCommitMessage()}
    </>
  );
};

AddTestCaseView.propTypes = {
  testProject: PropTypes.object.isRequired,
  testSuitePath: PropTypes.string.isRequired,
  selectedLinkingTestCases: PropTypes.array.isRequired,
  setSelectedLinkingTestCases: PropTypes.func.isRequired,
  commitMessage: PropTypes.string.isRequired,
  onChangeCommitMessage: PropTypes.func.isRequired,
};

export default AddTestCaseView;
