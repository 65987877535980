import React from 'react';
import PropTypes from 'prop-types';

const StatusBarDetail = ({ data }) => (
  <div className="d-inline-flex status-bar-detail">
    {data.map(({ label, value, icon }) => (
      <span className="status-bar-detail-result" key={label}>
        <span className="mr-1 d-flex align-items-center">
          {icon}
        </span>
        <span>
          {value}
        </span>
      </span>
    ))}
  </div>
);

StatusBarDetail.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.number,
    icon: PropTypes.element
  })).isRequired
};

export default StatusBarDetail;
