import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { ListItemIcon, MenuItem, Typography } from '@mui/material';
import MContext from '../../../models/MContext';
import { MAX_PAGE_SIZE, PlatformFilterType, SearchEntity } from '../../../utils/Constants';
import Services from '../../../utils/Services';
import DropDownFilter from './DropDownFilter';
import { t } from '../../../i18n/t';
import { IconSave } from '../../../images/KitIcons';

const PlatformFilter = ({ type, ...props }) => {
  const fieldName = type;
  const label = t(type);

  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isFirstOpen, setFirstOpen] = useState(true);
  const [selectedOption, setSelectedOption] = useState();
  const dropdownRef = useRef(null);

  const renderNoResult = (
    <MenuItem>{t('no-result')}</MenuItem>
  );

  const renderLoading = (
    <MenuItem>{t('table#loading-message')}</MenuItem>
  );

  const getOptions = () => {
    const projectId = MContext.projectId;
    setLoading(true);
    const params = {
      pagination: {
        page: 0,
        size: MAX_PAGE_SIZE,
        sorts: [`Platform.${fieldName},asc`],
      },
      conditions: [
        {
          key: 'Project.id',
          operator: '=',
          value: projectId,
        },
      ],
      type: SearchEntity.ExecutionTestResult,
      groupBys: [`Platform.${fieldName}`]
    };

    Services.searchRecursively(0, params, []).then((res) => {
      const options = res.map(({ content }) => (content[`Platform_${fieldName}`]))
        .filter((option) => option && option.replace(/\s+/g, ''));
      setOptions(options);
      setLoading(false);
      setFirstOpen(false);
    });
  };

  const onOpen = () => {
    if (isFirstOpen) {
      getOptions();
    }
  };

  const applyFilter = (id, option) => {
    const { onFilterChange } = props;
    onFilterChange(id, option);
  };

  const onSelect = (newOption) => {
    const selected = selectedOption === newOption ? null : newOption;
    setSelectedOption(selected);
    applyFilter(`Platform.${fieldName}`, selected);
    dropdownRef.current.handleToggle();
  };

  const renderSelectedOption = (option) => (option === selectedOption ? '' : 'invisible');

  const renderOption = (option) => (
    <Typography noWrap>{option}</Typography>
  );

  const renderOptions = !options.length ? renderNoResult : options.map((option) => (
    <>
      <MenuItem
        key={option}
        button
        onClick={() => {
          onSelect(option);
        }}
      >
        <ListItemIcon className={renderSelectedOption(option)}>
          <IconSave />
        </ListItemIcon>
        {renderOption(option)}
      </MenuItem>
    </>
  ));

  return (
    <DropDownFilter
      label={label}
      onOpen={onOpen}
      ref={dropdownRef}
      renderOption={renderOption}
      selectedOption={selectedOption}
      {...props}
    >
      { loading ? renderLoading : renderOptions }
    </DropDownFilter>
  );
};

PlatformFilter.propTypes = {
  /**
   * Filter type, default is filtering osName.
   */
  type: PropTypes.string,
  onFilterChange: PropTypes.func.isRequired,
};

PlatformFilter.defaultProps = {
  type: PlatformFilterType.BY_OS_NAME,
};

export default PlatformFilter;
