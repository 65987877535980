import React, { useState } from 'react';
import { Button, ButtonToolbar, Form } from 'reactstrap';
import PropTypes from 'prop-types';
import Input from '../Input';
import { t } from '../../i18n/t';
import CollapsibleViewModeText from './CollapsibleViewModeText';

const InlineEditInput = (props) => {
  const { currentValue = '', onChange } = props;
  const [editingValue, setEditingValue] = useState(currentValue);
  const [isEditing, setEditing] = useState(false);

  const onSave = () => {
    onChange(editingValue);
    setEditing(false);
  };

  const onCancel = () => {
    setEditingValue(currentValue);
    setEditing(false);
  };

  const onKeyDown = (event) => {
    if (event.key === 'Escape') {
      onCancel();
    }
  };

  const onBlur = () => {
    onSave();
  };

  const focusAtTheEnd = (event) => {
    const value = event.target.value;
    event.target.value = '';
    event.target.value = value;
  };

  return (
    isEditing ? (
      /**
       * Explain event triggered when click cancel button:
       * According to the HTML event priority:
       * 1. onMouseDown()
       * 2. onBlur()
       * 3. onClick()
       * Flow: editing value would be changed by event onChange() of input,
       * so cancel action must occur before what happen inside onBlur()(call API update test case),
       * that is why we need onMouseDown() event instead of onClick()
       * */
      <Form onSubmit={onSave} onBlur={onBlur}>
        <Input
          autoFocus
          className="inline-edit-input"
          value={editingValue}
          onChange={(s) => setEditingValue(s.target.value)}
          type="textarea"
          rows={4}
          onKeyDown={(e) => onKeyDown(e)}
          onFocus={(e) => focusAtTheEnd(e)}
        />
        <ButtonToolbar className="editable-field-confirm">
          <Button type="submit" title={t('save')} color="primary">{t('save')}</Button>
          <Button title={t('cancel')} color="secondary" onMouseDown={() => onCancel()}>{t('cancel')}</Button>
        </ButtonToolbar>
      </Form>) : (
        <CollapsibleViewModeText currentValue={currentValue} onClick={() => setEditing(true)} />
    )
  );
};

InlineEditInput.propTypes = {
  currentValue: PropTypes.string,
  onChange: PropTypes.func
};

InlineEditInput.defaultProps = {
  currentValue: '',
  onChange: null
};

export default InlineEditInput;
