import Icon from '@katalon-studio/katalon-ui/Icon';
import React from 'react';
import PropTypes from 'prop-types';
import colors from '../../../scss/colors.scss';

function QueueIcon({ color, size }) {
  return <Icon
    type="fa-solid"
    name="fa-hourglass-clock"
    textSize={size}
    boundingSize={size}
    color={null}
    sx={{
      color,
    }}
  />;
}

QueueIcon.defaultProps = {
  color: colors.textSecondary,
  size: '16px',
};

QueueIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
};
export default QueueIcon;
