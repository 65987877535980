import React, { Component } from 'react';
import MTableColumnDataMapping from './models/MTableColumnDataMapping';
import DecoratorConstants from '../../utils/DecoratorConstants';
import DataTable from './DataTable';
import { buildSearchCondition } from '../search/SearchUtils';
import { t } from '../../i18n/t';
import MContext from '../../models/MContext';
import Routes from '../../utils/Routes';
import Link from '../Link';

class TestCaseExecutionComparisonDataTable extends Component {
  render() {
    const { executionOrders } = this.props;
    const columnMapping = [
      new MTableColumnDataMapping(
        t('status'),
        'status',
        DecoratorConstants.testCaseComparisonStatusDecorator,
        undefined,
        'fit-content-column'
      ),
      new MTableColumnDataMapping(
        t('test_case'),
        'name',
        DecoratorConstants.testCaseNameDecorator,
        undefined,
        'short-text-column',
      )
    ];
    columnMapping.push(...executionOrders.map((id) => {
      const routes = new Routes({
        executionId: id,
        projectId: MContext.projectId,
      });
      return new MTableColumnDataMapping(
        <Link className="d-block" href={routes.execution_details_link}>#{id}</Link>,
        `executionTestCases.${id}.status`,
        DecoratorConstants.statusDecorator
      );
    }));

    const newProps = {
      ...this.props,
      columnMapping,
    };

    return (
      <DataTable
        tableId="TestCaseExecutionComparison"
        entityType="TestCaseExecutionComparison"
        defaultSearchConditions={[
          buildSearchCondition('Execution.order', '=', executionOrders.toString())
        ]}
        {...newProps}
      />
    );
  }
}

export default TestCaseExecutionComparisonDataTable;
