import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Alert } from 'reactstrap';
import PageComponent from '../../components/PageComponent';
import DefaultLayout from '../../components/DefaultLayout';
import { t } from '../../i18n/t';
import ObjectSummary from '../../components/summary/ObjectSummary';
import RouteConstants from '../../utils/RouteConstants';
import RequirementTestRunCoverage from './requirement_test_run_coverage/RequirementTestRunCoverage';
import TraceabilityReport from './components/TraceabilityReport';
import { ReportTestDesignHeaderNav } from '../../components/HeaderNav';
import MContext from '../../models/MContext';
import { analyticsPage } from '../../utils/SegmentAnalytics';
import ListTestReports from './ListTestReports';
import { buildSearchCondition } from '../../components/search/SearchUtils';
import Services from '../../utils/Services';
import JiraIntegrationFeatureHighlight from '../../components/external_issue/JiraIntegrationFeatureHighlight';
import MFlags from '../../models/MFlags';
import RefreshJiraButton from './components/RefreshJiraButton';

class TestReportTestDesign extends PageComponent {

  constructor(props) {
    super(props);
    this.meta.id = 'page-test-report-test-design';
    this.meta.title = t('test-report#application');

    this.teamId = MContext.teamId;
    this.projectId = MContext.projectId;
    this.project = MContext.project;
    this.state = {
      connection: null,
    };
    this.renderHeader = this.renderHeader.bind(this);
    this.renderBody = this.renderBody.bind(this);
  }

  renderHeader() {
    const { project } = this;
    const urlParams = {
      testReports: t('requirements'),
      projectName: project.name,
      testReportTitle: t(this.testReportId),
    };
    return (
      <ObjectSummary params={urlParams}>
        <ReportTestDesignHeaderNav teamId={this.teamId} projectId={this.projectId} />
      </ObjectSummary>
    );
  }

  componentDidMount() {
    this.getConnection(this.projectId)
      .then((connection) => {
        this.setState({ connection });
      });
  }

  getConnection(projectId) {
    const params = {
      pagination: {
        page: 0,
        size: 1,
        sorts: 'order,desc',
      },
      conditions: [
        buildSearchCondition('Project.id', '=', projectId),
        buildSearchCondition('active', '=', 'true'),
      ],
      type: 'ExternalConnection',
    };
    return Services.search(params)
      .then(({ content }) => content[0]);
  }

  getConnectionAndSetState = (projectId) => {
    this.getConnection(projectId).then((connection) => {
      this.setState({ connection });
    });
  }

  renderToolbar() {
    const { connection } = this.state;

    return (
      <RefreshJiraButton
        connection={connection}
        getConnectionAndSetState={this.getConnectionAndSetState}
      />
    );
  }

  renderComponent(component, id) {
    this.meta.title = t(id);
    analyticsPage(`page-report-${id}`);
    return (
      <>
        <Alert color="primary">
          {t(`${id}-description`)}
        </Alert>
        {component}
      </>
    );
  }

  renderBody() {
    const { connection } = this.state;

    if (connection === null) {
      return null;
    }

    const active = connection && connection.active;
    return (
      <>
        {active && this.renderToolbar()}
        {!active && <JiraIntegrationFeatureHighlight/>}
        {active &&
          <Switch>
            <Route
              exact
              path={RouteConstants.test_report_requirement_test_run}
              render={(params) =>
                this.renderComponent(
                  <RequirementTestRunCoverage {...params} />,
                  ListTestReports.requirementTestRunCoverage.id
                )}
            />
            <Route
              exact
              path={RouteConstants.test_report_traceability}
              render={(params) =>
                this.renderComponent(
                  <TraceabilityReport {...params} />,
                  ListTestReports.traceability.id
                )}
            />
          </Switch>}
      </>
    );
  }

  render() {
    return (
      <DefaultLayout
        renderHeader={this.renderHeader}
        renderBody={this.renderBody}
      />
    );
  }

}

export default TestReportTestDesign;
