import React from 'react';
import PropTypes from 'prop-types';
import { DialogContent, DialogActions } from '@mui/material';
import { Button } from 'reactstrap';
import CloseableDialogComponent from './CloseableDialogComponent';
import { t } from '../../i18n/t';
import DecoratorConstants from '../../utils/DecoratorConstants';
import Services from '../../utils/Services';
import Notification from '../../utils/Notification';
import GroupEvent from '../../utils/track/GroupEvent';

function RemoveBaselineConfirmDialog({ isOpen, handleClose, baselines, baselineCollectionGroupId, onDeleteSuccess }) {

  const baselineNames = baselines.map((bl) => bl.screenshot?.name);
  const baselinesString = baselineNames.join(',');

  const handleConfirm = (event) => {
    event.preventDefault();
    const baselineIds = baselines.map((bl) => bl.id);
    Services.deleteBaselinesLatestVersion(baselineCollectionGroupId, baselineIds)
      .then(({ id }) => {
        Notification.pushSuccess(t('visual-testing#remove-baseline#success', { selectedImageName: baselinesString, version: id }));
        if (onDeleteSuccess) {
          onDeleteSuccess();
        }
      });
  };

  const trackCloseIcon = {
    'data-trackId': 'cancel-delete-baseline-image',
    'data-groupId': GroupEvent.ACCESS_REPORT,
  };

  return (
    <CloseableDialogComponent
      isOpen={isOpen}
      handleClose={handleClose}
      title={t('visual-testing#remove-baseline')}
      trackCloseIcon={trackCloseIcon}
    >
      <DialogContent>
        <div>
          {t('visual-testing#remove-baseline#description')}&nbsp;
          {DecoratorConstants.pluralize(t('baseline').toLowerCase(), baselines.length)}: <b>{baselinesString}</b>?
        </div>
      </DialogContent>
      <DialogActions className="mb-2">
        <Button
          color="secondary"
          onClick={handleClose}
          {...trackCloseIcon}
        >
          {t('cancel')}
        </Button>
        <Button
          color="danger"
          onClick={handleConfirm}
          data-trackid="submit-delete-baseline-image"
          data-groupid={GroupEvent.ACCESS_REPORT}
        >
          {t('remove')}
        </Button>
      </DialogActions>
    </CloseableDialogComponent>
  );
}

RemoveBaselineConfirmDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  baselines: PropTypes.array.isRequired,
  baselineCollectionGroupId: PropTypes.number.isRequired
};

RemoveBaselineConfirmDialog.defaultProps = {
  isOpen: false,
  handleClose: null,
  baselines: []
};

export default RemoveBaselineConfirmDialog;
