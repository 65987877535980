import React from 'react';
import { Input } from 'reactstrap';
import classNames from 'classnames';

export default (props) => {
  const { className, children, nowrap, monoFont, ...restProps } = props;
  return (
    <div className="logarea-wrapper">
      <Input
        type="textarea"
        className={classNames(
          'border-0',
          (className || ''),
          {
            'mono-font': monoFont,
            'white-space-pre': nowrap === true,
          },
        )}
        value={children}
        readOnly
        disabled
        tabIndex="-1"
        {...restProps}
      />
    </div>);
};
