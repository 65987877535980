import React from 'react';

class LoadingSpinner extends React.Component {
  render() {
    return (
      <div className="loading-spinner-container" id="loadingSpinner">
        <div className="loading-spinner" />
      </div>
    );
  }
}

export default LoadingSpinner;
