import React, { useState } from 'react';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { IconButton } from '@mui/material';
import PropTypes from 'prop-types';
import { IconCheckKeyesGreen } from '../../../images/CustomIcon';
import TooltipComponent from '../../../components/TooltipComponent';

function CopyableDecorator({ name, row }) {
  const [isCopy, setIsCopy] = useState(false);
  const value = row[name];

  const handleCopy = () => {
    navigator.clipboard.writeText(value);
    setIsCopy(true);
    setTimeout(() => {
      setIsCopy(false);
    }, 5000);
  };

  return (
    <div className="d-flex align-items-center">
      <span>{value}</span>
      <TooltipComponent text="Copy" arrow>
        <IconButton onClick={handleCopy} size="medium">
          {isCopy ? <IconCheckKeyesGreen /> : <ContentCopyIcon />}
        </IconButton>
      </TooltipComponent>
    </div>
  );
}

CopyableDecorator.propTypes = {
  name: PropTypes.string.isRequired,
  row: PropTypes.object.isRequired,
};

export default CopyableDecorator;
