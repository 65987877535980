import React from 'react';
import { FormGroup, Label, Progress } from 'reactstrap';
import Uploader from '../Uploader';
import { IconCamera } from '../../images/CustomIcon';
import ImageProfile from './ImageProfile';
import MConfigs from '../../models/MConfigs';

export default class ImageProfileUploader extends React.Component {

  constructor(props) {
    super(props);

    this.state = this.getInitialState();

    this.onUploadFinish = this.onUploadFinish.bind(this);
    this.renderProgressBar = this.renderProgressBar.bind(this);
    this.onUploadProgress = this.onUploadProgress.bind(this);
    this.onUploadStart = this.onUploadStart.bind(this);
    this.compressFile = this.compressFile.bind(this);
  }

  getInitialState() {
    return {
      uploadProgress: '0',
    };
  }

  dataURItoBlob(dataURI, format) {
    const binary = atob(dataURI.split(',')[1]);
    const array = [];
    for (let i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], { type: format });
  }

  compressFile(file, next, options) {
    const { format } = options;
    const img = new Image();
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const outputImageAspectRatio = 1;

    img.onload = () => {
      const inputWidth = img.naturalWidth;
      const inputHeight = img.naturalHeight;
      const inputImageAspectRatio = inputWidth / inputHeight;

      let outputWidth = inputWidth;
      let outputHeight = inputHeight;

      if (inputImageAspectRatio > outputImageAspectRatio) {
        outputWidth = inputHeight * outputImageAspectRatio;
      } else if (inputImageAspectRatio < outputImageAspectRatio) {
        outputHeight = inputWidth / outputImageAspectRatio;
      }
      const outputX = (outputWidth - inputWidth) * .5;
      const outputY = (outputHeight - inputHeight) * .5;
      canvas.width = outputWidth;
      canvas.height = outputHeight;

      ctx.drawImage(img, outputX, outputY);
      next(new File([this.dataURItoBlob(canvas.toDataURL(format, 0.5))], file.name, { type: format }));
    };
    img.src = URL.createObjectURL(file);
  }

  onUploadStart(file, next) {
    if (file.size > 2000) {
      this.compressFile(file, next, { format: file.type });
    } else {
      next(file);
    }
  }

  onUploadProgress(percent) {
    this.uploadProgress(percent);
  }

  onUploadError(message) {
    const { onUploadErrorHook } = this.props;

    if (onUploadErrorHook) {
      onUploadErrorHook();
    }

    Notification.pushError(`Upload error: ${message}`);
  }

  onUploadFinish(data) {
    this.props.onUploadFinish(data);
  }

  uploadProgress(percent) {
    this.setState({ uploadProgress: percent });
  }

  renderProgressBar() {
    const { uploadProgress } = this.state;
    if (uploadProgress === '0') {
      return null;
    }
    return (
      <FormGroup>
        <Progress value={uploadProgress} />
      </FormGroup>
    );
  }

  renderHover() {
    return (
      <div className="img-hover">
        <IconCamera width="48px" height="48px" />
      </div>
    );
  }

  renderUploader() {
    return (
      <Uploader
        data-trackid="change-avatar"
        className="d-none"
        type="file"
        accept="image/jpeg, image/png"
        multiple
        signingUrl="/api/v1/files/upload-url-avatar"
        preprocess={this.onUploadStart}
        onProgress={this.onUploadProgress}
        onFinish={(data, file) => this.onUploadFinish(data, file)}
        onError={this.onUploadError}
        signingUrlQueryParams={{
          'from-react-s3': true,
        }}
        signingUrlWithCredentials
        uploadRequestHeaders={{ 'content-type': 'application/octet-stream' }}
        autoUpload
      />
    );
  }

  render() {
    const isOnPremise = MConfigs.isOnPremise;
    return (
      <>
        <Label className="d-flex justify-content-center align-items-center">
          <div className="image-profile">
            <ImageProfile {...this.props} />
            {!isOnPremise && this.renderHover()}
          </div>
          {!isOnPremise && this.renderUploader()}
        </Label>
        {!isOnPremise && this.renderProgressBar()}
      </>
    );
  }
}
