import React, { Component } from 'react';
import { Button, Collapse } from 'reactstrap';
import PropTypes from 'prop-types';
import DataLoader from '../../../components/table/DataLoader';
import Timeline from '../../../components/timeline/Timeline';
import { t } from '../../../i18n/t';
import { IconAngleRight, IconDown } from '../../../images/KitIcons';
import DecoratorConstants from '../../../utils/DecoratorConstants';
import Routes from '../../../utils/Routes';
import { ExecutionDecorator } from '../../execution/ExecutionDecorator';
import ExecutionTestRunStatus from './ExecutionTestRunStatus';
import ImageProfile from '../../../components/avatar/ImageProfile';

class ExecutionTimeline extends Component {

  constructor(props) {
    super(props);
    this.dataRef = React.createRef();
    this.state = {
      expandedExecutions: {},
    };
    this.renderData = this.renderData.bind(this);
  }

  toggleExpandExecution(id) {
    this.setState((prevState) => ({
      expandedExecutions: {
        ...prevState.expandedExecutions,
        [id]: !prevState.expandedExecutions[id]
      }
    }));
  }

  renderExecutionDetails(execution) {
    const { expandedExecutions } = this.state;
    return (
      <Collapse isOpen={expandedExecutions[execution.id]}>
        <div>
          Profile: {' '}
          {ExecutionDecorator.profileDecorator(execution)}
        </div>
        <div className="mt-2">
          <ExecutionTestRunStatus execution={execution} />
        </div>
      </Collapse>
    );
  }

  renderExpandButton(execution) {
    const { expandedExecutions } = this.state;
    const isExpanded = expandedExecutions[execution.id];
    return (
      <Button onClick={() => this.toggleExpandExecution(execution.id)} color="link">
        {isExpanded ? <IconDown /> : <IconAngleRight />}
      </Button>
    );
  }

  renderOrderAndTime(execution) {
    const constructedLink = new Routes({
      executionId: execution.order,
      projectId: execution.project.id,
    });
    return (
      <>
        <div>
          <a href={constructedLink.execution_details_link}>#{execution.order}</a>
        </div>
        <div className="text-gray">
          {DecoratorConstants.timeDecorator('startTime', execution)}
        </div>
        <div className="text-gray">
          {DecoratorConstants.durationDecorator('elapsedDuration', execution)}
        </div>
      </>
    );
  }

  renderContent(execution) {
    const { user } = execution;
    return (
      <div>
        <div className="label-text">
          {ExecutionDecorator.nameDecorator(execution)}
        </div>
        {user &&
          <div className="d-flex align-items-center">
            <div className="mr-3">
              <ImageProfile
                imgUrl={user.avatar}
                name={user.fullName}
                diameter={20}
              />
            </div>
            {ExecutionDecorator.platformDecorator(execution)}
          </div>}
      </div>
    );
  }

  renderExecutions(executions) {
    const items = executions.map((execution) => ({
      icon: ExecutionDecorator.statusDecorator('status', execution),
      title: this.renderOrderAndTime(execution),
      content: this.renderContent(execution),
      button: this.renderExpandButton(execution),
      details: this.renderExecutionDetails(execution),
    }));
    return (
      <Timeline items={items} />
    );
  }

  renderFooter() {
    const routes = new Routes();
    return (
      <div className="text-right">
        <a href={routes.executions_link}>View all</a>
      </div>
    );
  }

  renderData(data) {
    if (data.length > 0) {
      return this.renderExecutions(data);
    } else {
      return <div className="text-center">{t('table#empty-message')}</div>;
    }
  }

  render() {
    const { pageSize, noCard } = this.props;
    return (
      <DataLoader
        ref={this.dataRef}
        entityType="Execution"
        render={this.renderData}
        title="Executions"
        pageSize={pageSize}
        noCard={noCard}
        hidePaging
        renderFooter={this.renderFooter()}
        {...this.props}
      />
    );
  }
}

ExecutionTimeline.propTypes = {
  pageSize: PropTypes.number,
  noCard: PropTypes.bool,
};

ExecutionTimeline.defaultProps = {
  pageSize: 10,
  noCard: false,
};

export default ExecutionTimeline;
