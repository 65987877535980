import jquery from 'jquery';
import http from '../utils/http';
import Apis from '../utils/Apis';
import MAuth from '../models/MAuth';
import SearchHelper from './SearchHelper';
import MContext from '../models/MContext';
import { SearchEntity, MAX_PAGE_SIZE } from './Constants';
import { buildSearchCondition } from '../components/search/SearchUtils';
import { SearchOperator } from './SearchOperator';
import testCloudService from './testcloud/testCloudService';
import Helper from '../utils/Helper';
import Routes from '../utils/Routes';
import MFlags from '../models/MFlags';

let searchInfoCache;
let searchInfoPromiseCache;

const Services = {

  getZones(fileUrl) {
    return http.get(fileUrl);
  },

  searchInfo() {
    if (searchInfoCache) {
      return new Promise((resolve) => {
        resolve(searchInfoCache);
      });
    }
    if (searchInfoPromiseCache) {
      return searchInfoPromiseCache;
    }
    searchInfoPromiseCache = http.get(Apis.searchInfo).then((response) => {
      searchInfoPromiseCache = null;
      searchInfoCache = response;
      return response;
    });
    return searchInfoPromiseCache;
  },

  // {
  //   "pagination":{
  //     "page":0,
  //     "size":10,
  //     "sorts":["order,desc"]
  //   },
  //   "conditions":[
  //     {
  //       "key":"projectId",
  //       "operator":"=",
  //       "value":"11"
  //     }
  //   ],
  //   "groupBys": ['status'],
  //   "functions": [
  //     {
  //       "key: 'a',
  //       "function: 'count',
  //       "parameters: ['id']
  //     },
  //     {
  //       "function": 'group_by_week',
  //       "parameters": ['startTime']
  //     }
  //   ],
  //   "type":"Execution"
  // }
  search(params, useCache) {
    params = SearchHelper.fullParams(params);

    const q = JSON.stringify(params);

    if (useCache) {
      const ck = SearchHelper.generateCacheKey(params);
      if (q.length < 1900) {
        return http.get(Apis.searchCache({ q, ck }));
      } else {
        return http.post(Apis.searchCache({ ck }), params);
      }
    } else {
      if (q.length < 1900) {
        return http.get(Apis.search({ q }));
      } else {
        return http.post(Apis.search(), params);
      }
    }
  },
  /**
   * Provide recursive get all records by using specific SearchParams
   * @param {*} currentPageIndex
   * @param {*} searchParams
   * @param {*} items
   * @returns listOfSearchItems
   */
  searchRecursively(currentPageIndex, searchParams, items = []) {
    searchParams.pagination.page = currentPageIndex;
    return Services.search(searchParams)
      .then(({ content }) => {
        // end when calling search api get zero record and return all records
        if (content.length === 0) {
          return new Promise((resolve) => {
            resolve(items);
          });
        }
        items = items.concat(content);
        // continue to call Api
        return this.searchRecursively(currentPageIndex + 1, searchParams, items);
      });
  },

  deleteCache(key, projectId) {
    return http.delete(Apis.cacheRemove(key, projectId));
  },

  getBuildInfo() {
    return http.get(Apis.buildInfo());
  },

  getConfig() {
    return http.get(Apis.config());
  },

  getJiraReleasesStatus(releaseId) {
    return http.get(Apis.jiraReleasesStatus({
      releaseId,
    }));
  },

  getFileContent(fileHandleId) {
    return http.get(Apis.fileDownload(fileHandleId));
  },

  checkExportedFile(executionId, fileType) {
    return http.get(Apis.checkExported(executionId, fileType));
  },

  enqueueFile(executionId, fileType) {
    return http.post(Apis.executionEnqueue(executionId, fileType));
  },

  sendExportFileEmail(executionId, fileType) {
    return http.post(Apis.sendExecutionExportMail(executionId, fileType));
  },

  getOrganization(organizationId) {
    const organizations = MAuth.organizations;
    const id = parseInt(organizationId, 10);
    const organization = organizations.find((organization) => organization.id === id);
    if (organization) {
      return new Promise((resolve) => {
        resolve(organization);
      });
    } else {
      return http.get(Apis.organization(organizationId));
    }
  },

  organizationWithLicense(organizationId, feature) {
    return http.get(Apis.organizationWithLicense(organizationId, feature));
  },

  getOrganizationProjects(organizationId) {
    return http.get(Apis.getOrganizationProjects(organizationId));
  },

  getTeam(teamId) {
    const teams = MAuth.teams;
    const id = parseInt(teamId, 10);
    const team = teams.find((team) => team.id === id);

    if (team) {
      return new Promise((resolve) => {
        resolve(team);
      });
    } else {
      return http.get(Apis.teamId(teamId));
    }
  },

  getAccountSettings(accountId) {
    return http.get(Apis.accountSettings({ accountId }));
  },

  getAccountUserRole(accountId) {
    return http.get(Apis.accountUserRole({ accountId }));
  },

  getProject(projectId) {
    const isTeamDemo = MContext.isTeamDemo;
    if (!isTeamDemo) {
      try {
        Services.updateRecentProject(projectId);
      } catch (e) {
        // ignore
      }
    }

    const projects = MAuth.projects;
    const id = parseInt(projectId, 10);
    const project = projects.find((project) => project.id === id);

    if (project) {
      return new Promise((resolve) => {
        resolve(project);
      });
    } else {
      return http.get(Apis.project(projectId));
    }
  },

  getProjects(params) {
    return http.get(Apis.projects(params));
  },

  addUserInTeam(params) {
    return http.post(Apis.usersAdd(params));
  },

  removeUserInTeam(params) {
    return http.delete(Apis.usersRemove(params));
  },

  removeUserInOrganization(params) {
    return http.delete(Apis.removeUserOrganization(params));
  },

  removeInvitations(params) {
    return http.delete(Apis.invitations(params));
  },

  // params { userId, teamId, role, }
  updateTeamRole(params) {
    return http.put(Apis.permissionTeamUser, params);
  },

  getUserOrganization(userId, organizationId) {
    return http.get(Apis.getUserOrganization(userId, organizationId));
  },

  updateOrganizationRole(params) {
    return http.put(Apis.updateUserOrganization(), params);
  },

  // params { teamId, newTeamName }
  updateTeamName(team) {
    return http.put(Apis.teams(), team);
  },

  updateOrganization(organization) {
    return http.put(Apis.organizations(), organization);
  },

  updateDomain(organizationId, domain, errorHandler) {
    return http.post(Apis.organizationDomain(organizationId, domain), null, null, null, errorHandler);
  },

  updateStrictDomain(organizationId, strictDomain) {
    return http.post(Apis.organizationStrictDomain(organizationId, strictDomain));
  },

  createOrganization(orgName) {
    return http.post(Apis.organizations(), { name: orgName });
  },

  deleteOrganization(organizationId, errorHandler) {
    return http.delete(Apis.organization(organizationId), {}, errorHandler);
  },

  // params { teamId, invitedUser: email }
  inviteUserForTeam(params, invitationAccessibility, errorHandler) {
    return http.post(Apis.invitations(params), invitationAccessibility, null, null, errorHandler);
  },

  // params { organizationId, userEmail, feature }
  addOrganizationFeatures(params) {
    return http.post(Apis.organizationFeatures(), params);
  },

  // params { organizationId, userId, feature }
  removeOrganizationFeatures(params) {
    return http.delete(Apis.organizationFeatures(params));
  },

  // params { organizationId, userEmail, option }
  addUserOrganizationSso(params) {
    return http.post(Apis.userOrganizationSso(), params);
  },

  // params { organizationId, userId, option }
  removeUserOrganizationSso(params) {
    return http.delete(Apis.userOrganizationSso(), params);
  },

  getAssignedUserOrganizationSsos(userId, organizationId) {
    return http.get(Apis.userOrganizationSso({ userId, organizationId }));
  },

  getAssignedUserOrganizationFeatures(userId, organizationId) {
    return http.get(Apis.organizationFeatures({ userId, organizationId }));
  },

  getAssignableUserOrganizationFeatures(email, organizationId) {
    return http.get(Apis.assignableOrganizationFeatures({ email, organizationId }));
  },

  /**
   * @deprecated due to TOS-1891
   * Remove incidentEnabled flag
   * This update a task feature is not used in TO anymore
   */
  updateIncident(incident) {
    return http.put(Apis.incidents(), incident);
  },

  /**
   * @deprecated due to TOS-1891
   * Remove incidentEnabled flag
   * This create new task feature is not used in TO anymore
   */
  createNewIncident(params) {
    return http.post(Apis.incidents(), params);
  },

  /**
   * @deprecated due to TOS-1891
   * Remove incidentEnabled flag
   * This link feature is not used in TO anymore
   */
  linkTestResultIncident(executionTestResultId, params) {
    return http.post(Apis.executionTestResultIncidents(executionTestResultId), params);
  },

  /**
   * @deprecated due to TOS-1891
   * Remove incidentEnabled flag
   * This unlink feature is not used in TO anymore
   */
  unlinkTestResultIncident(executionTestResultId, params) {
    return http.delete(Apis.executionTestResultIncidents(executionTestResultId), params);
  },

  markAsRetestedTestRun(testResultId, description) {
    return http.post(Apis.markAsRetestedTestRun(testResultId), description);
  },

  linkExecutionBuild(executionId, buildId) {
    return http.post(Apis.linkExecutionBuild(executionId, buildId));
  },

  linkRunConfigurationBuild(runConfigurationId, buildId) {
    return http.post(Apis.linkRunConfigurationBuild(runConfigurationId, buildId));
  },

  createRelease(release) {
    return http.post(Apis.releases(), release);
  },

  createBuild(build) {
    return http.post(Apis.buildCreate(), build);
  },

  updateBuild(build, buildId) {
    return http.put(Apis.buildUpdate(buildId), build);
  },

  removeBuild(buildId) {
    return http.delete(Apis.buildDelete(buildId));
  },

  updateRelease(release) {
    return http.put(Apis.releases(), release);
  },

  deleteRelease(releaseId) {
    return http.delete(Apis.release(releaseId));
  },

  activateRelease(releaseId, closed) {
    return http.post(Apis.activateRelease(releaseId, { closed }));
  },

  getTestCaseById(testCaseId) {
    return http.get(Apis.testCase(testCaseId));
  },

  getValidTestCaseById(testCaseId) {
    const isValidUrlId = Helper.startsWithNumber(testCaseId);
    if (isValidUrlId) {
      return this.getTestCaseById(testCaseId);
    }
    return Routes.goToAccessDeniedPage();
  },

  updateTestCase(testCase, ignoreBlockUI) {
    return http.post(Apis.testCases(), testCase, null, null, null, ignoreBlockUI);
  },

  updateExecutionCustomFields(id, customFields) {
    return http.post(Apis.executionUpdateCustomFields(id), customFields, null, null, null, true);
  },

  deleteTestCaseTag(testCaseId, tag) {
    return http.delete(Apis.testCaseTag(testCaseId), tag, null, true);
  },

  updateTestCaseTag(testCaseId, tag) {
    return http.put(Apis.testCaseTag(testCaseId), tag, null, null, null, true);
  },

  updateExecutionTag(executionId, tag) {
    return http.put(Apis.updateExecutionTag(executionId), tag, null, null, null, true);
  },

  deleteExecutionTag(executionId, tag) {
    return http.delete(Apis.deleteExecutionTag(executionId), tag, null, true);
  },

  updateExecutionTestResultTag(executionTestResultId, tag) {
    return http.put(Apis.updateExecutionTestResultTag(executionTestResultId), tag, null, null, null, true);
  },

  deleteExecutionTestResultTag(executionTestResultId, tag) {
    return http.delete(Apis.deleteExecutionTestResultTag(executionTestResultId), tag, null, true);
  },

  createTag(tag, ignoreBlockUI = false) {
    return http.post(Apis.createTag(), tag, null, null, null, ignoreBlockUI);
  },

  createNewTeam(params) {
    return http.post(Apis.teams(), params);
  },

  createNewLicenseKey(params) {
    return http.post(Apis.licenseKeys(), params);
  },

  createTestingLicense(params) {
    return http.post(Apis.testingLicenseKey(), params);
  },

  cancelLicense(licenseKeyId) {
    return http.post(Apis.cancelLicense(licenseKeyId));
  },

  createNewProject(params) {
    return http.post(Apis.projects(), params);
  },

  deleteProject(projectId) {
    return http.delete(Apis.project(projectId));
  },

  deleteTeam(teamId) {
    return http.delete(Apis.teamId(teamId));
  },

  updateProject(project) {
    return http.put(Apis.projects(), project);
  },

  updateStatusProject(project) {
    return http.put(Apis.updateStatusProject(), project);
  },

  deleteExecution(projectId, order) {
    return http.delete(Apis.execution(projectId, order));
  },

  terminateExecution(projectId, order) {
    return http.post(Apis.executionTerminate(projectId, order));
  },

  pushExecutionToXray(projectId, xrayTestPlanId, externalReleaseId, errorHandler) {
    return http.post(Apis.pushExecutionToXray(projectId, xrayTestPlanId, externalReleaseId), null, null, null, errorHandler);
  },

  // params { projectId, batch, folderPath, isEnd, fileName, uploadedPath }
  uploadTestReports(params) {
    return http.post(Apis.junitReports(params));
  },

  uploadTestReportsV2(params, body) {
    return http.post(Apis.junitReportsV2(params), body);
  },

  // params { projectId, batch, folderPath, isEnd, fileName, uploadedPath }
  uploadTestNGReports(params) {
    return http.post(Apis.testNGReports(params));
  },

  // params { projectId, batch, folderPath, isEnd, fileName, uploadedPath }
  uploadKatalonReports(params) {
    return http.post(Apis.katalonReports(params));
  },

  uploadKatalonReportsInBatches(projectId, batch, uploadFiles) {
    return http.post(Apis.katalonReportsInBatches(projectId, batch), uploadFiles);
  },

  uploadKatalonReportsInBatchesV2(projectId, batch, multipleS3FileResource) {
    return http.post(Apis.katalonReportsInBatchesV2(projectId, batch), multipleS3FileResource);
  },

  createApiKey(params) {
    return http.post(Apis.apiKeys, params);
  },

  getApiKeys(params) {
    return http.get(Apis.getApiKeys(params));
  },

  createScheduler(runConfigurationId, scheduler) {
    return http.post(Apis.scheduler(runConfigurationId), scheduler);
  },

  updateScheduler(testProjectId, schedulerId, scheduler) {
    return http.put(Apis.scheduler(testProjectId, schedulerId), scheduler);
  },

  deleteScheduler(testProjectId, schedulerId) {
    return http.delete(Apis.scheduler(testProjectId, schedulerId));
  },

  getScheduler(testProjectId, schedulerId) {
    return http.get(Apis.scheduler(testProjectId, schedulerId));
  },

  removeApiKey(apiKeyId) {
    return http.delete(Apis.apiKeyRequest(apiKeyId));
  },

  linkExternalIssue(projectId, params, errorHandler) {
    return http.post(Apis.linkExternalIssue(projectId), params, null, null, errorHandler);
  },

  linkExternalIssueV2(projectId, params, errorHandler) {
    return http.post(Apis.linkExternalIssue(projectId), params, null, null, errorHandler);
  },

  unlinkExternalIssue(projectId, params) {
    return http.delete(Apis.linkExternalIssue(projectId), params);
  },

  getExternalIssuesInfo(projectId, objectId, objectType) {
    return http.post(Apis.getExternalIssuesInfo({ projectId, objectId, objectType }));
  },

  createJiraIssue(projectId, objectId, objectType, object, errorHandler) {
    return http.post(Apis.createJiraIssue({ projectId, objectId, objectType }), object, null, null, errorHandler);
  },

  createAutomationRule(object, errorHandler) {
    return http.post(Apis.createAutomationRule(), object, null, null, errorHandler);
  },

  validateAutomationRule(params, errorHandler) {
    return http.post(Apis.validateAutomationRule(), params, null, null, errorHandler);
  },

  updateTestProject(testProjectId, testProjectInfo) {
    return http.put(Apis.testProject(testProjectId), testProjectInfo);
  },

  getDefaultTestProject(params) {
    return http.get(Apis.defaultTestProject(params));
  },

  createAndUpdateRunConfiguration(runConfiguration, verifyRunConfiguration, errorHandler) {
    return http.post(Apis.createAndUpdateRunConfigurations({ verifyRunConfiguration }), runConfiguration, null, null, errorHandler);
  },

  executeRunConfiguration(runConfigurationId) {
    return http.put(Apis.executeRunConfiguration(runConfigurationId));
  },

  createRunConfigurationAndTrigger(smartScheduler, verifyRunConfiguration, errorHandler) {
    return http.post(Apis.createRunConfigurationAndTrigger({ verifyRunConfiguration }), smartScheduler, null, null, errorHandler);
  },

  createRunConfigurationAndRun(runConfiguration, verifyRunConfiguration, errorHandler) {
    return http.post(Apis.createRunConfigurationAndRun({ verifyRunConfiguration }), runConfiguration, null, null, errorHandler);
  },

  rerunExecution(executionId) {
    return http.post(Apis.executionRerun(executionId));
  },

  deleteRunConfiguration(runConfigurationId) {
    return http.delete(Apis.runConfiguration(runConfigurationId));
  },

  createSampleRunConfiguration(runConfiguration) {
    return http.post(Apis.sampleRunConfiguration(), runConfiguration);
  },

  createSampleTestProject(params) {
    return http.post(Apis.sampleTestProject(params));
  },

  createSampleGitTestProject(params) {
    return http.post(Apis.createSampleGitTestProject(params));
  },

  downloadTestProject(testProjectId) {
    return http.get(Apis.downloadTestProject(testProjectId));
  },

  updateTestProjectPackage(testProjectId, params) {
    return http.post(Apis.updateTestProjectPackage(testProjectId, params));
  },

  getJobInfo(jobId) {
    return http.get(Apis.job(jobId));
  },

  cancelJob(jobId) {
    return http.delete(Apis.job(jobId));
  },

  getJobLogs(jobId) {
    return http.get(Apis.jobLogs(jobId));
  },

  uploadTestProject(params) {
    return http.post(Apis.uploadTestProject(params));
  },

  changeAvatar(params) {
    return http.post(Apis.changeAvatar(params));
  },

  changeOrganizationLogo(organizationId, params) {
    return http.post(Apis.changeOrganizationLogo(organizationId, params));
  },

  changePassword(params, errorHandler) {
    return http.post(Apis.changePassword, params, {}, null, errorHandler);
  },

  updateUserAccount(params) {
    return http.post(Apis.updateUserAccount(params));
  },

  getJobsSummary(params) {
    return http.get(Apis.jobsSummary(params));
  },

  deleteTestProject(testProjectId) {
    return http.delete(Apis.testProject(testProjectId));
  },

  reImportExecution(id) {
    return http.post(Apis.reImportExecution(id));
  },

  removeMachine(machineKey, organizationId, feature) {
    return http.delete(Apis.removeMachine(machineKey, organizationId, feature));
  },

  createComment(params) {
    return http.post(Apis.comment(), params);
  },

  updateComment(params) {
    return http.put(Apis.comment(), params);
  },

  getNumberOfComments(params) {
    return http.get(Apis.comment(), params);
  },

  getAgent(agentId) {
    return http.get(Apis.agentRequest(agentId));
  },

  getTestCloudAgent(agentId) {
    return http.get(Apis.testCloudAgentRequest(agentId));
  },

  getTestCloudLicenses(organizationId) {
    return http.get(Apis.testCloudLicensesRequest(organizationId));
  },

  updateAgentThreshold(agent) {
    return http.put(Apis.updateAgentThreshold(), agent);
  },

  removeAgent(agentId) {
    return http.delete(Apis.agentRequest(agentId));
  },

  getLastAccessedProject(userId, organizationId) {
    const params = {
      pagination: {
        page: 0,
        size: 1,
        sorts: [],
      },
      conditions: [
        buildSearchCondition('User.id', '=', userId),
        buildSearchCondition('Organization.id', '=', organizationId)
      ],
      type: SearchEntity.RecentProject
    };
    return Services.search(params)
      .then(({ content }) => content);
  },

  getUserInTeam(teamId) {
    const params = {
      pagination: {
        page: 0,
        size: MAX_PAGE_SIZE,
        sorts: 'id,asc',
      },
      conditions: [
        buildSearchCondition('Team.id', '=', teamId)
      ],
      type: SearchEntity.UserTeam,
      ignoreAutoFill: true,
    };
    return this.searchRecursively(0, params, []);
  },

  getFileContentFromExternal(url) {
    const promise = new Promise((resolve) => {
      const configs = {
        url,
        method: 'get',
        success: (response) => {
          resolve(response);
        }
      };
      jquery.ajax(configs);
    });
    return promise;
  },

  classifyFailedTestResult(execution_id, project_id) {
    return http.post(Apis.classifyFailedTestResult(execution_id, project_id));
  },

  getAnalysisSettings(projectId) {
    return http.get(Apis.analysisSettings(projectId));
  },

  updateAnalysisSettings(projectId, analysisSettings) {
    return http.put(Apis.analysisSettings(projectId), analysisSettings);
  },

  getPlugins(storeUrl, query) {
    return http.get(Apis.getPlugins(storeUrl, query));
  },

  getSubscriptionsInfo(organizationId, allProducts) {
    return http.get(Apis.getSubscriptionsInfo(organizationId, allProducts));
  },

  getActiveSubscriptionsInfo(organizationId) {
    return http.get(Apis.getActiveSubscriptionsInfo(organizationId));
  },

  getSubscriptionProductInfo(organizationId, enterpriseProductId) {
    return http.get(Apis.getSubscriptionProductInfo(organizationId, enterpriseProductId));
  },

  getSubscriptionUrl(organizationId, subscriptionId, planId) {
    return http.get(Apis.getSubscriptionUrl(organizationId, subscriptionId, planId), null, null, 'text');
  },

  getPaymentMethod(organizationId, errorHandler) {
    return http.get(Apis.paymentMethod(organizationId), undefined, undefined, undefined, errorHandler);
  },

  getBillingInformation(organizationId) {
    return http.get(Apis.billingInformation(organizationId));
  },

  getInvoice(organizationId, invoiceNumber) {
    return http.get(Apis.getInvoices(organizationId, invoiceNumber));
  },

  fetchKsVersionList() {
    return fetch('https://download.katalon.com/katalon-studio/releases.json')
      .then((response) => response.json())
      .catch(() => fetch('https://raw.githubusercontent.com/katalon-studio/katalon-studio/master/releases.json')
        .then((response) => response.json()));
    //     return fetch('https://raw.githubusercontent.com/katalon-studio/katalon-studio/master/releases.json')
    //           .then((response) => response.json())
    //           .catch(() => fetch('https://download.katalon.com/katalon-studio/releases.json')
    //             .then((response) => response.json()));
  },

  updateMailConfiguration(params, organizationId) {
    return http.put(Apis.mailConfiguration(organizationId), params);
  },

  getMailConfiguration(organizationId) {
    return http.get(Apis.mailConfiguration(organizationId));
  },

  updateTestOpsConfiguration(params, organizationId) {
    return http.put(Apis.testOpsConfiguration(organizationId), params);
  },

  getTestOpsConfiguration(organizationId) {
    return http.get(Apis.testOpsConfiguration(organizationId));
  },

  updateLicenseServerConfiguration(organizationId, configuration) {
    return http.put(Apis.licenseServerConfiguration(organizationId), configuration);
  },

  getLicenseServerConfiguration(organizationId) {
    return http.get(Apis.licenseServerConfiguration(organizationId));
  },

  sendTestMail(params, organizationId) {
    return http.put(Apis.mailConfigurationTest(organizationId), params);
  },

  getUserSetting() {
    return http.get(Apis.userSetting());
  },

  updateUserSetting(userSetting) {
    return http.post(Apis.userSetting(), userSetting);
  },

  getUserGitCredentials(type) {
    const param = { type };
    const queryString = Apis.getQueryString(param);
    const path = Apis.userGitCredentials() + queryString;
    return http.get(path);
  },

  updateUserGitCredentials(resource) {
    return http.post(Apis.userGitCredentials(), resource);
  },

  updateUserConfiguration(userConfiguration) {
    return http.post(Apis.userConfiguration(), userConfiguration);
  },

  getProjectSetting(projectId) {
    return http.get(Apis.getProjectSetting(projectId));
  },

  getOrganizationMigration(organizationId) {
    return http.get(Apis.getOrganizationMigration(organizationId));
  },

  updateProjectSetting(projectSetting) {
    return http.post(Apis.projectSetting(), projectSetting);
  },

  getProjectConfiguration(projectId, name) {
    return http.get(Apis.getProjectConfiguration(projectId, name));
  },

  updateProjectConfiguration(projectId, projectConfiguration) {
    return http.post(Apis.projectConfigurations(projectId), projectConfiguration);
  },

  sendResetPasswordEmail(email) {
    return http.post(Apis.resetPassword({ email }));
  },

  getResetPasswordInfo(token) {
    return http.get(Apis.resetPassword({ token }));
  },

  resetPassword(resetPasswordInfo) {
    return http.put(Apis.resetPassword(), resetPasswordInfo);
  },

  signUp(user, errorHandler) {
    return http.post(Apis.signUp(), user, undefined, undefined, errorHandler);
  },

  activate(license) {
    return http.post('/public/testops/activate', { license });
  },

  getNonSubscribedOrganizations(organization_feature) {
    return http.get(Apis.nonSubscribedOrganizations(organization_feature));
  },

  getUnassignedSubscriptions() {
    return http.get(Apis.getUnassignedSubscriptions());
  },

  assignSubscription(subscriptionId, organizationId, organization_feature) {
    return http.patch(Apis.assignSubscription(subscriptionId, organizationId, organization_feature));
  },

  createQuote(organizationId, orders, errorHandler) {
    return http.post(Apis.createQuote(organizationId), { orders }, {}, null, errorHandler);
  },

  getQuote(uuid) {
    return http.get(Apis.getQuote(uuid));
  },

  checkoutQuote(cardToken, uuid) {
    return http.post(Apis.checkoutQuote(cardToken, uuid));
  },

  track(action, properties, errorHandler) {
    const data = {
      action,
      properties
    };
    // without login, this api will return 401, current flow will be disrupted
    // we don't want tracking process affect current flow
    if (!MAuth.isLoggedIn) {
      return new Promise((resolve) => { resolve(); });
    }
    return http.post(Apis.track(), data, {}, null, errorHandler, true);
  },

  testJiraConnection(setting, errorHandler) {
    return http.post(`${Apis.integrationSetting}/test-connection`, setting, {}, null, errorHandler);
  },

  saveJiraSettings(setting, errorHandler) {
    return http.post(Apis.integrationSetting, setting, {}, null, errorHandler);
  },

  saveExternalIssueNotificationConfig(setting) {
    return http.post(Apis.saveExternalIssueNotificationConfig, setting);
  },

  refreshExternalReleasesStatus(projectId) {
    return http.post(Apis.refreshExternalReleasesStatus({ projectId }));
  },

  refreshJiraIssueOfProject(projectId) {
    return http.post(Apis.refreshJiraIssueOfProject({ projectId }));
  },

  testKobitonConnection(setting, errorHandler) {
    return http.post(Apis.kobitonIntegrationSettingTest, setting, {}, null, errorHandler);
  },

  saveKobitonSettings(setting, errorHandler) {
    return http.post(Apis.kobitonIntegrationSetting, setting, {}, null, errorHandler);
  },

  testSlackConnection(setting, errorHandler) {
    return http.post(Apis.slackWebhooktSettingTest, setting, {}, null, errorHandler);
  },

  saveSlackSettings(setting, errorHandler) {
    return http.post(Apis.slackWebhookSetting, setting, {}, null, errorHandler);
  },

  saveStatusFeedback(setting, errorHandler) {
    return http.post(Apis.statusFeedback, setting, {}, null, errorHandler);
  },

  getTestObjectEntity(id) {
    return http.get(Apis.testObjectEntity(id));
  },

  createK8SAgentConfig(k8sConfig) {
    return http.post(Apis.k8sAgents(), k8sConfig);
  },

  updateK8SAgentConfig(k8sConfig) {
    return http.put(Apis.k8sAgents(), k8sConfig);
  },

  testK8SConnection(k8sConnection) {
    return http.post(`${Apis.k8sAgents()}/test-connection`, k8sConnection);
  },

  createCircleCIAgentConfig(circleCIConfig) {
    return http.post(Apis.circleCIAgents(), circleCIConfig);
  },

  updateCircleCIAgentConfig(circleCIConfig) {
    return http.put(Apis.circleCIAgents(), circleCIConfig);
  },

  testCircleCIConnection(circleCIConnection) {
    return http.post(`${Apis.circleCIAgents()}/test-connection`, circleCIConnection);
  },

  getCircleCIFollowedProjects(circleCIConnection, errorHandler) {
    return http.post(`${Apis.circleCIAgents()}/projects`, circleCIConnection, {}, null, errorHandler);
  },

  getRecentProjects(organizationId) {
    return http.get(Apis.getRecentProjects(organizationId));
  },

  updateRecentProject(projectId) {
    return http.put(Apis.updateRecentProjects(projectId));
  },

  updateWhitelistIp(organizationId, whitelistIps) {
    return http.post(Apis.whitelistIp(organizationId), { whitelistIps });
  },

  updateOrganizationConfiguration(configuration) {
    return http.post(Apis.organizationConfiguration(), configuration);
  },

  getOrganizationConfiguration(configuration) {
    return http.get(Apis.organizationConfiguration(configuration));
  },

  getOrganizationSetting(configuration) {
    return http.get(Apis.organizationSetting(configuration));
  },

  resolveCheckpoint(checkpointId, status) {
    return http.post(Apis.resolveCheckpoint(checkpointId, { status }));
  },

  resolveMultipleCheckpoints(keyesExecutionId, checkpointIds, status) {
    return http.post(Apis.resolveMultipleCheckpoints({ keyesExecutionId, checkpointIds, status }));
  },

  saveExecutionToBaseline(keyesExecutionId) {
    return http.post(Apis.saveExecutionToBaseline(keyesExecutionId));
  },

  reimportKeyesExecution(keyesExecutionId) {
    return http.post(Apis.reimportKeyesExecution(keyesExecutionId));
  },

  deleteBaselines(projectId, baselineIds) {
    return http.delete(Apis.baselines({ projectId, id: baselineIds }));
  },

  saveDraftIgnoredZone(baselineCollectionId, baselineId, draftIgnoredZones) {
    return http.post(Apis.saveDraftIgnoredZone(baselineCollectionId, baselineId), draftIgnoredZones);
  },

  /**
   * Delete baseline images of the latest version
   * @param {long} baselineCollectionGroupId
   * @param {Array} baselineIds
   * @returns new version
   */
  deleteBaselinesLatestVersion(baselineCollectionGroupId, baselineIds) {
    return http.delete(Apis.baselinesLatestVersion({ baselineCollectionGroupId, id: baselineIds }));
  },

  getSubFolders(testFolderId, numberOfLevel, excludedFolderPaths) {
    return http.get(Apis.getSubFolders({ testFolderId, numberOfLevel, excludedFolderPaths }));
  },

  updateSSO(organizationId, sso, metadata) {
    const data = {
      organizationId,
      sso,
      metadata,
    };
    return http.post(Apis.updateSamlIdp, data);
  },

  getSSO(organizationId) {
    return http.get(Apis.getSamlIdp(organizationId));
  },

  getInfoSsoInvitation(token) {
    return http.get(Apis.ssoInvitation(token));
  },

  acceptSsoInvitation(token) {
    return http.put(Apis.ssoInvitation(token));
  },

  declineSsoInvitation(token) {
    return http.put(Apis.ssoDeclineInvitation(token));
  },

  removeSsoInvitation(params) {
    return http.delete(Apis.ssoInvitation(params));
  },

  createSampleProject(projectId) {
    return http.post(Apis.createSampleProject(projectId));
  },

  populateSampleProject(projectId, errorHandler) {
    return http.post(Apis.populateSampleProject(projectId), null, null, null, errorHandler);
  },

  getExecution(executionId) {
    return http.get(Apis.executionInfo(executionId));
  },

  getTestResult(testResultId) {
    return http.get(Apis.executionTestResult(testResultId));
  },

  getLogContent(testResultLogId) {
    return http.get(Apis.executionTestResultLog(testResultLogId), null, null, 'text');
  },

  updateTestResultCustomFields(id, customFields) {
    return http.post(Apis.executionTestResultUpdateCustomFields(id), customFields, null, null, null, true);
  },

  getTimeZoneOptions() {
    return http.get(Apis.timeZoneOptions());
  },

  refreshTSC(testProjectId) {
    return http.post(Apis.refreshTSC(testProjectId));
  },

  submitOrganizationTrialRequest(organizationId, feature) {
    return http.post(Apis.organizationTrialRequest(organizationId, feature));
  },

  getOrganizationTrialRequest(organizationId) {
    return http.get(Apis.organizationTrialRequest(organizationId));
  },

  getOrganizationTestExecutionQuota(organizationId) {
    return http.get(Apis.organizationTestExecutionQuota(organizationId));
  },

  shareExecutionReport(executionId, emails, errorHandler) {
    return http.post(Apis.shareExecutionReport(executionId), { emails }, null, null, errorHandler);
  },

  getSubscriptionTestOpsInfo(organizationId) {
    return http.get(Apis.subscriptionTestOpsInfo(organizationId));
  },

  subscriptionTestOpsCheckout(organizationId, upgradeOrder, errorHandler) {
    return http.post(Apis.subscriptionTestOpsCheckout({ organizationId }), upgradeOrder, null, null, errorHandler);
  },

  subscriptionTestOpsReviewOrder(organizationId, order, errorHandler) {
    return http.post(Apis.subscriptionTestOpsReviewOrder({ organizationId }), order, null, null, errorHandler);
  },

  cancelSubscription(organizationId, order, errorHandler) {
    return http.post(Apis.cancelSubscription({ organizationId }), order, null, null, errorHandler);
  },

  getActiveTunnels(organizationId) {
    const queryString = Apis.getQueryString({ organizationId });
    const activeTunnelsUrl = `${Apis.activeTunnels}${queryString}`;
    return http.get(activeTunnelsUrl);
  },

  terminateTunnel(deleteTunnelRequest, id) {
    return http.delete(Apis.deleteTunnel(id), deleteTunnelRequest);
  },

  getSmartTestScheduler(runConfigurationId) {
    return http.get(Apis.smartScheduler(runConfigurationId));
  },

  markNotificationAsRead(notificationId) {
    return http.put(Apis.markNotificationAsRead(notificationId), undefined, {}, false, undefined, true);
  },

  markAllNotificationsAsRead() {
    return http.put(Apis.markAllNotificationsAsRead(), undefined, {}, false, undefined, true);
  },

  getNumberOfNotifications() {
    return http.get(Apis.getNumberOfNotifications(), undefined, undefined, undefined, () => {});
  },

  resetNotificationNumber() {
    return http.put(Apis.getNumberOfNotifications(), undefined, {}, false, undefined, true);
  },

  getTestSuite(testSuiteId) {
    return http.get(Apis.testSuite(testSuiteId));
  },

  createTestSuite(testSuite) {
    return http.post(Apis.createTestSuite, testSuite);
  },

  editTestSuite(testSuiteId, testSuite) {
    return http.post(Apis.editTestSuite(testSuiteId), testSuite);
  },

  deleteTestSuite(testSuiteId, queryInfo) {
    return http.delete(Apis.deleteTestSuite(testSuiteId, queryInfo));
  },

  deleteTestSuites(queryInfo) {
    return http.delete(Apis.deleteTestSuites(queryInfo));
  },

  runTestCase(testProjectId, runTestCaseResource, errorHandler) {
    return http.post(Apis.runTestCase(testProjectId), runTestCaseResource, null, null, errorHandler);
  },

  getBaselineCollectionGroup(baselineCollectionGroupOrder) {
    const params = {
      pagination: {
        page: 0,
        size: 1,
        sorts: ['name,asc'],
      },
      conditions: [
        buildSearchCondition('order', '=', baselineCollectionGroupOrder),
      ],
      type: SearchEntity.BaselineCollectionGroup,
    };
    return this.search(params);
  },
  getBaselineCollectionByBaselineCollectionGroupIdAndVersion(baselineCollectionGroupId, version) {
    const params = {
      pagination: {
        page: 0,
        size: 1,
        sorts: ['id,asc'],
      },
      conditions: [
        buildSearchCondition('baselineCollectionGroupId', '=', baselineCollectionGroupId),
        buildSearchCondition('version', '=', version),
      ],
      type: SearchEntity.BaselineCollection,
    };
    return this.search(params);
  },

  getJiraConnection(projectId) {
    const params = {
      pagination: {
        page: 0,
        size: 1,
        sorts: 'order,desc',
      },
      conditions: [
        buildSearchCondition('Project.id', '=', projectId),
        buildSearchCondition('active', '=', 'true'),
      ],
      type: SearchEntity.ExternalConnection,
    };
    return Services.search(params);
  },

  getSlackConnection(projectId) {
    const params = {
      pagination: {
        page: 0,
        size: 1,
        sorts: 'order,desc',
      },
      conditions: [
        buildSearchCondition('Project.id', '=', projectId),
        buildSearchCondition('active', '=', 'true'),
      ],
      type: SearchEntity.SlackConnection,
    };
    return Services.search(params);
  },

  getXrayTestPlanByRunConfiguration(runConfigurationId) {
    const params = {
      pagination: {
        page: 0,
        size: 1,
        sorts: 'order,desc',
      },
      conditions: [
        buildSearchCondition('RunConfiguration.id', '=', runConfigurationId),
      ],
      type: SearchEntity.ExternalXrayTestPlan,
    };
    return Services.search(params);
  },

  getTagEntities(projectId, entityId, entityType) {
    const params = {
      pagination: {
        page: 0,
        size: MAX_PAGE_SIZE,
        sorts: ['TagEntity.id, asc'],
      },
      conditions: [
        buildSearchCondition('Project.id', '=', projectId),
        buildSearchCondition('TagEntity.entityId', '=', entityId),
        buildSearchCondition('TagEntity.entityType', '=', entityType)
      ],
      type: SearchEntity.Tag,
      ignoreAutoFill: true,
    };
    return this.searchRecursively(0, params, []);
  },

  getTestCasesByNameAndPathAndTestProjectId(name, path, testProjectId) {
    const params = {
      pagination: {
        page: 0,
        size: MAX_PAGE_SIZE,
        sorts: ['name,asc'],
      },
      conditions: [
        buildSearchCondition('name', 'starts with', name),
        buildSearchCondition('path', '=', path),
        buildSearchCondition('TestProject.id', '=', testProjectId)
      ],
      type: SearchEntity.TestCase,
    };
    return this.searchRecursively(0, params, []);
  },

  getTestCasesByTestSuiteId(testSuiteId) {
    const params = {
      pagination: {
        page: 0,
        size: MAX_PAGE_SIZE,
        sorts: ['sortOrder, asc NULLS LAST']
      },
      conditions: [
        buildSearchCondition('TestSuite.id', '=', testSuiteId)
      ],
      type: SearchEntity.TestSuiteTestCase,
    };
    return Services.searchRecursively(0, params, [])
      .then((content) => content.map((testSuiteTestCase) => testSuiteTestCase.testCase));
  },

  getTestSuitesByNameAndPathAndTestProjectId(name, path, testProjectId) {
    const params = {
      pagination: {
        page: 0,
        size: MAX_PAGE_SIZE,
        sorts: ['name,asc'],
      },
      conditions: [
        buildSearchCondition('name', 'starts with', name),
        buildSearchCondition('path', '=', path),
        buildSearchCondition('TestProject.id', '=', testProjectId)
      ],
      type: SearchEntity.TestSuite,
    };
    return this.searchRecursively(0, params, []);
  },

  getTestCaseByIdAndTestProjectId(testCaseId, testProjectId) {
    const params = {
      pagination: {
        page: 0,
        size: 1,
        sorts: 'order,desc',
      },
      conditions: [
        buildSearchCondition('id', '=', testCaseId),
        buildSearchCondition('TestProject.id', '=', testProjectId),
      ],
      type: SearchEntity.TestCase,
    };
    return this.search(params);
  },

  getTestProjectById(testProjectId) {
    const params = {
      pagination: {
        page: 0,
        size: 1,
        sorts: ['id,desc']
      },
      conditions: [
        buildSearchCondition('id', '=', testProjectId)
      ],
      type: SearchEntity.TestProject,
    };
    return Services.search(params)
      .then(({ content: testProjects }) => testProjects[0]);
  },

  getTestRunById(testRunId) {
    const params = {
      pagination: {
        page: 0,
        size: 1,
        sorts: ['id,desc'],
      },
      conditions: [
        buildSearchCondition('id', '=', testRunId),
      ],
      type: SearchEntity.Execution,
    };
    return Services.search(params)
      .then(({ content: testRuns }) => testRuns[0]);
  },

  saveIgnoringZones(id) {
    return http.post(Apis.saveIgnoringZones(id));
  },
  updateBaselineCollectionGroup(id, baselineCollectionGroup) {
    return http.put(Apis.baselineCollectionGroup(id), baselineCollectionGroup);
  },

  getVisualTestingQuota(organizationId) {
    return http.get(Apis.visualTestingQuota(organizationId));
  },

  getLabelByEntityIdAndEntityType(entityId, entityType) {
    const params = {
      pagination: {
        page: 0,
        size: 1,
        sorts: ['id, asc'],
      },
      conditions: [
        buildSearchCondition('entityId', SearchOperator.EQUALITY, entityId),
        buildSearchCondition('entityType', SearchOperator.EQUALITY, entityType),
      ],
      type: SearchEntity.LabelLink,
    };
    return this.search(params);
  },

  getTestProjectByType(testProjectType) {
    const params = {
      pagination: {
        page: 0,
        size: MAX_PAGE_SIZE,
        sorts: ['id,asc']
      },
      conditions: [
        buildSearchCondition('type', '=', testProjectType)
      ],
      type: SearchEntity.TestProject,
    };
    return Services.searchRecursively(0, params);
  },

  getSystemLabel(entityType) {
    return http.get(Apis.getSystemLabel({ entityType }));
  },

  changeLabel(executtionTestResultId, systemLabel) {
    return http.post(Apis.updateLabelExecutionTestResult(executtionTestResultId), systemLabel);
  },

  checkRequiredGitRepository(projectId) {
    return http.get(Apis.checkRequiredGitRepository(projectId));
  },

  /**
   * @deprecated
   * This function is not used in TO anymore due to enhance survey features
   */
  skipTestOpsSurvey() {
    return http.post(Apis.skipTestOpsSurvey());
  },

  /**
   * @deprecated
   * This function is not used in TO anymore due to enhance survey features
   */
  oldSubmitTestOpsSurvey(jobTitle, testingIndustry, transitionGoal) {
    const data = {
      jobTitle,
      testingIndustry,
      transitionGoal,
    };

    return http.post(Apis.submitTestOpsSurvey(), data);
  },

  submitTestOpsSurvey(resource) {
    return http.post(Apis.submitTestOpsSurvey(), resource);
  },

  detectKsEdition(organizationId) {
    return http.get(Apis.detectKsEdition(organizationId));
  },

  sendActivationEmailKatOne() {
    return http.post(Apis.katOneSendActivationEmail);
  },

  sendActivationEmailKatOneUsingBasicAuth(user, errorHandler) {
    const { username, password } = user;
    const credential = btoa(`${username}:${password}`);
    const headers = { Authorization: `Basic ${credential}` };
    return http.post(Apis.katOneSendActivationEmail, null, headers, true, errorHandler, true);
  },

  createCustomFieldDefinition(customFieldDefinition) {
    return http.post(Apis.createCustomFieldDefinition(), customFieldDefinition);
  },

  editCustomFieldDefinition(customFieldDefinition) {
    return http.put(Apis.customFieldDefinition(), customFieldDefinition);
  },

  deleteCustomFieldDefinition(customFieldDefinition) {
    return http.delete(Apis.customFieldDefinition(), customFieldDefinition);
  },

  saveDraftBaselineCollection(resource) {
    return http.post(Apis.saveDraftBaselineCollection(), resource, null, null, null, true);
  },

  createPreviewCheckpointPixel(checkpointPixelId) {
    return http.post(Apis.previewCheckpointPixel(checkpointPixelId), null, null, null, null, true);
  },

  getPreviewCheckpointPixel(checkpointPixelId) {
    return http.get(Apis.previewCheckpointPixel(checkpointPixelId));
  },

  uploadBaselineImages(resource) {
    return http.post(Apis.uploadBaselineImages(), resource, null, null, null, true);
  },

  createBaselineCollection(resource) {
    return http.post(Apis.createBaselineCollection(), resource, null, null, null, true);
  },

  getJiraProjects(externalConnection) {
    return http.post(Apis.getJiraProjects(), externalConnection);
  },

  getJiraProjectsWithoutBlock(externalConnection) {
    return http.post(Apis.getJiraProjects(), externalConnection, null, null, null, true);
  },

  getJiraBoardsWithoutBlock(externalConnection) {
    return http.post(Apis.getJiraBoards(), externalConnection, null, null, null, true);
  },

  getJiraIssueTypes(externalConnection, errorHandler) {
    return http.post(Apis.getJiraIssueTypes(), externalConnection, {}, null, errorHandler, true);
  },

  trackTestCloudTestRunScheduleEvent(email, scheduleTime) {
    const data = {
      email,
      properties: {
        testcloud_testrun_schedule_time: scheduleTime,
      }
    };
    return http.post(Apis.updateContactHubSpot, data);
  },

  getTestCloudOrganizationQuota(organizationId) {
    if (MFlags.testCloudDisableTCFromScheduleDialog) {
      return Promise.resolve({});
    }
    return testCloudService.getTestCloudOrganizationQuota(organizationId)
      .then((result) => result.data).catch(() => {});
  },

  getTestCloudOrganizationQuotas(organizationId, projectId) {
    return testCloudService.getTestCloudSessionSummary(organizationId, projectId)
      .then((result) => result.data).catch(() => {});
  },

  createJiraWebhook(projectId, jiraWebhook) {
    return http.post(Apis.jiraWebhook(projectId), jiraWebhook);
  },

  rotateJiraWebhook(projectId, jiraWebhook) {
    return http.post(Apis.jiraWebhookRotating(projectId), jiraWebhook);
  },

  deleteJiraWebhook(projectId) {
    return http.delete(Apis.jiraWebhook(projectId));
  },

  getJiraWebhook(projectId) {
    return http.get(Apis.jiraWebhook(projectId));
  },

  getTestCase(testCaseId) {
    return http.get(Apis.getTestCase(testCaseId));
  },

  publishTestProject(testProjectId, publishedTestProject, errorHandler, ignoreBlockUI) {
    return http.post(Apis.publishTestProject(testProjectId), publishedTestProject, null, null, errorHandler, ignoreBlockUI);
  },

  publishProject(projectId, publishedTestProject) {
    return http.post(Apis.publishProject(projectId), publishedTestProject);
  },

  getProjectTrackingInformation(projectId, errorHandler) {
    return http.get(Apis.getProjectTrackingInformation(projectId), undefined, undefined, undefined, errorHandler);
  },

  createOrUpdateFeatureSetting(projectId, featureName, value, errorHandler) {
    return http.put(Apis.createOrUpdateFeatureSetting(projectId, featureName), value, undefined, undefined, errorHandler);
  },

  deleteFeatureSetting(projectId, featureName, errorHandler) {
    return http.delete(Apis.deleteFeatureSetting(projectId, featureName), undefined, undefined, undefined, errorHandler);
  },

  hasWritePermission(testProjectId) {
    return http.get(Apis.hasWritePermission(testProjectId));
  },

  /**
   * @deprecated Use getAllSubFoldersAndItsTestEntities() instead
   */
  getAllSubFoldersAndItsTestCases(testFolderId, numberOfLevel) {
    return http.get(Apis.getAllSubFoldersAndItsTestCases({ testFolderId, numberOfLevel }));
  },

  getAllSubFoldersAndItsTestEntities(
    testFolderId,
    numberOfLevel,
    includedTestSuiteTypes,
    excludedTestSuiteSources,
    excludedFolderPaths
  ) {
    return http.get(Apis.getAllSubFoldersAndItsTestEntities({
      testFolderId,
      numberOfLevel,
      includedTestSuiteTypes,
      excludedTestSuiteSources,
      excludedFolderPaths
    }));
  },

  getTestCasesStatistics(projectId, path, name) {
    const data = {
      projectId,
      identityResources: [
        {
          path,
          name
        }
      ]
    };
    return http.post(Apis.getTestCasesStatistics, data);
  },

  getOnboardingSteps(projectId, sourceId) {
    return http.get(Apis.getOnboardingSteps({ projectId, sourceId }));
  },

  trackOnboardingStep(onboardingStep) {
    return http.post(Apis.trackOnboardingStep(), onboardingStep);
  },

  getAIStatistics(promptType, projectId, from, to) {
    return http.get(Apis.getAIStatistics({ promptType, projectId, from, to }));
  },

  getAICompletions(object, promptType, projectId) {
    return http.post(Apis.getAICompletions({ promptType, projectId }), object, null, null, null, true);
  },

  getMetricSummaryInfo(projectId, from, to) {
    return http.get(Apis.getMetricSummaryInfo({ projectId, from, to }));
  },

  getJiraIssueTypeMetadataByName(projectId, issueTypeName) {
    return http.get(Apis.getJiraIssueTypeMetadataByName({ projectId, issueTypeName }));
  },

  getAllJiraIssueTypesMetadata(projectId) {
    return http.get(Apis.getAllJiraIssueTypesMetadata({ projectId }));
  },

  getIncomingActiveReleases(projectId) {
    return http.get(Apis.getIncomingActiveReleases({ projectId }));
  },

  getAutoCompleteResponse(projectId, autoCompleteUrl) {
    return http.get(Apis.getAutoCompleteResponse({ projectId, autoCompleteUrl }));
  },

  getMatchTestResultsByAutomationRule(projectId, currentTestResultId, errorMessagePattern, stackTracePattern, errorHandler) {
    return http.get(Apis.getMatchTestResultsByAutomationRule({
      projectId,
      currentTestResultId,
      ...(errorMessagePattern ? { errorMessagePattern } : {}),
      ...(stackTracePattern ? { stackTracePattern } : {})
    }), null, null, null, errorHandler);
  },

  deleteAutomationRule(automationRuleId, errorHandler) {
    return http.delete(Apis.deleteAutomationRule(automationRuleId), null, errorHandler);
  },

  getJiraCreateUrl(projectId, testRunId) {
    return http.get(Apis.getJiraCreateUrl({
      projectId,
      testRunId,
    }));
  },

  updateAgent(agent) {
    return http.put(Apis.updateAgent(agent.id), agent);
  },

  getSuggestedDefects(testResultId) {
    return http.get(Apis.getSuggestedDefects({ testResultId }));
  },

  getEmailDailyConfigByUser() {
    return http.get(Apis.getEmailDailyConfigByUser());
  },

  getEmailDailyConfigByProject(projectId) {
    return http.get(Apis.getEmailDailyConfigByProject({ projectId }));
  },

  subscribeUsersToDailyEmail(dailyReportSubscription) {
    return http.post(Apis.subscribeUsersToDailyEmail(), dailyReportSubscription);
  },

  unSubscribeUsersToDailyEmail(dailyReportUnsubscription) {
    return http.put(Apis.unSubscribeUsersToDailyEmail(), dailyReportUnsubscription);
  },
};

export default Services;
