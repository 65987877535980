import React, { useEffect, useState } from 'react';
import { Col, CustomInput, Row } from 'reactstrap';
import { Button, CircularProgress, Link } from '@mui/material';
import { cloneDeep } from 'lodash';
import Icon from '@katalon-studio/katalon-ui/Icon';
import DocumentLink from '../../../utils/DocumentLink';
import TableSortCore from '../../../components/table/models/TableSortCore';
import MTableColumnDataMapping from '../../../components/table/models/MTableColumnDataMapping';
import { t } from '../../../i18n/t';
import { Project } from '../../../models/model/Project';
import Services from '../../../utils/Services';
import Routes from '../../../utils/Routes';
import MAuth from '../../../models/MAuth';
import UrlHelper from '../../../utils/UrlHelper';
import ConfirmUnsubscribeDailyReportDigestDialog from '../../../components/dialog/ConfirmUnsubscribeDailyReportDigestDialog';

interface UserEmailConfigProps {
  userSetting: { mailDailyUpdateEnabled: boolean };
  setUserSetting: (userSetting: object) => void;
}

function UserEmailConfig(props: UserEmailConfigProps) {

  const { userSetting, setUserSetting } = props;

  const [projects, setProjects] = useState<Project[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const getEmailConfiguration = () => {
    Services.getEmailDailyConfigByUser()
      .then((response) => {
        setProjects(response.projects);
        setIsLoading(false);
      });
  };

  const isDailyReportEnabled: boolean = userSetting.mailDailyUpdateEnabled;

  useEffect(() => {
    getEmailConfiguration();
  }, []);

  if (isLoading) {
    return (
      <div className="d-flex w-100 justify-content-center mt-4">
        <CircularProgress size={40} />
      </div>
    );
  }

  const goToProjectSetting = (row: Project) => {
    const projectId = row.id;
    const teamId = row.teamId;
    const router = new Routes({ projectId, teamId });
    window.open(`${router.integrations_link}?type=email_settings`, '_blank');
  };

  const renderProjectsTable = () => {

    const columnMapping = [
      new MTableColumnDataMapping(
        t('email-config#project-name'),
        'name',
        undefined,
        true,
        'align-middle',
      ),
      new MTableColumnDataMapping(
        '',
        '',
        (_name, row) => (MAuth.isTeamManager(row.teamId) ? (
          <Button
            onClick={() => goToProjectSetting(row)}
          >
            <Icon
              type="fa-light"
              name="fa-gear"
              textSize="12px"
              className="mr-2"
            />
            <span className="light-button-text text-nowrap fs-12px">{t('email-config#go-to-settings')}</span>
          </Button>
        ) : <div />),
        true,
        'align-middle text-align-end',
      ),
    ];

    if (projects.length > 0) {
      return <TableSortCore data={projects} columnMapping={columnMapping} />;
    }
    return null;
  };

  const onChangeUserSetting = () => {
    const copiedUserSetting = cloneDeep(userSetting);
    copiedUserSetting.mailDailyUpdateEnabled = !userSetting.mailDailyUpdateEnabled;
    setUserSetting(copiedUserSetting);
  };

  const renderTrialRequestSuccessPopup = () => {
    const unsubscribedProjectId = UrlHelper.getSearchParam('unsubscribedProjectId');
    if (unsubscribedProjectId) {
      return <ConfirmUnsubscribeDailyReportDigestDialog projectId={unsubscribedProjectId} projectList={projects} reloadProjectList={getEmailConfiguration} />;
    } else {
      return null;
    }
  };

  return (
    <Row className="email-configuration-container">
      <Col className="mt-3 mb-4" sm="12" md="7" lg="7" xl="7">
        <Row className="user-email-header">
          <Col>
            {t('email-config#daily-summary-report')}
          </Col>
          <Col className="d-flex justify-content-end align-item-center">
            <CustomInput
              className="custom-switch"
              onChange={onChangeUserSetting}
              name="active"
              type="switch"
              id="active"
              checked={isDailyReportEnabled}
            />
          </Col>
        </Row>
        <div className="d-flex mt-1 email-content">
          <div className="email-desc mr-1">
            {t('email-config#daily-summary-report#user-desc')}
            <Link className="email-link" target="_blank" href={DocumentLink.DAILY_SUMMARY_REPORT_LEARN_MORE}>{t('learn_more')}</Link>
          </div>
        </div>
        <div className={!isDailyReportEnabled ? 'ui-state-disabled mt-3' : 'mt-3'}>
          {renderProjectsTable()}
          {renderTrialRequestSuccessPopup()}
        </div>
      </Col>
    </Row>
  );
}

export default UserEmailConfig;

