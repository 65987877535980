import _ from 'lodash';
import { t } from '../../i18n/t';
import Time from '../../utils/Moment';

// map from jsonPayload property to Label in UI
const labelMap = {
  id: 'ID',
  status: t('status'),
  statusIn: t('statusIn'),
  startTime: 'Started',
  endTime: 'To',
  duration: 'Duration',
  totalTests: 'Total',
  totalPassedTests: 'Passed',
  totalFailedTests: 'Failed',
  totalErrorTests: 'Error',
  totalIncompleteTests: 'Incomplete',
  flakiness: 'Flakiness',
  sameStatusPeriod: 'Age',
  'TestSuite.name': 'Test Suite',
  'TestCase.name': 'Test Case',
  'Platform.browserName': 'Browser',
  'Platform.browserVersion': 'Browser\'s Version',
  'Platform.osName': 'OS',
  'Platform.osVersion': 'OS\'s Version',
  'ExecutionStatistics.status': t('status'),
  'ExecutionStatistics.startTime': 'Started',
  'ExecutionStatistics.duration': 'Duration',
  'ExecutionTestResult.profile': 'Profile',
  'Tag.id': 'Tag',
  name: 'Name',
  path: 'Path',
  order: 'ID',
  value: 'Value',
  email: 'Created by',
  profile: 'Profile',
  avgDuration: 'Average Duration',
  requestCount: 'Request Count',
  totalDuration: 'Total Duration',
  'User.id': 'By',
  machineId: 'Machine ID',
  machineKey: 'Machine ID',
  'TestSuiteCollectionEntity.name': 'Test Suite Collection\'s Name',
  'Machine.machineKey': 'Machine ID',
  'TestProject.name': 'Script Repository\'s name',
  'Scheduler.id': 'Trigger',
  'ExternalRelease.id': 'External Release\'s id',
  type: 'Type',
  fullPath: 'Full Path',
  'FileHandle.name': 'Name',
  feature: 'Product',
  issueId: 'Issue ID',
  featureName: 'Feature Name',
  closed: 'closed',
  'Release.id': 'Release',
  'Build.name': 'Build',
  'Build.id': 'Build',
  buildLabel: t('buildLabel'),
  'releaseStatus': 'releaseStatus',
  averageDuration: 'Average Duration',
  updatedAt: t('search-bar#last-updated'),
  issueCreated: 'issueCreated',
  assignee: 'assignee',
  priority: 'priority',
  priorityIn: 'priorityIn',
  isLinkedDefect: 'isLinkedDefect',
};

const tableIds = {
  Execution: 'Execution',
  AllFailedTests: 'AllFailedTests',
  AllTests: 'AllTests',
  TestCases: 'TestCases',
  TestSuites: 'TestSuites',
  Comments: 'Comments',
  TestCaseExecutions: 'TestCaseExecutions',
  TestCaseExecutionStatus: 'TestCaseExecutionStatus',
  ReleaseStatisticsStatus: 'ReleaseStatisticsStatus',
  PlatformStatistics: 'PlatformStatistics',
  Agents: 'Agents',
  ExecutionTestResultGroups: 'ExecutionTestResultGroups',
  ExecutionTestResultCategories: 'ExecutionTestResultCategories',
  Release: 'Release',
};

const buildExecutionLabelMap = () => {
  const newLabelMap = _.cloneDeep(labelMap);
  return _.omit(newLabelMap, ['id']);
};

const buildReleaseLabelMap = () => {
  const newLabelMap = _.cloneDeep(labelMap);
  return _.omit(newLabelMap, ['id']);
};

const buildAllFailedTestsLabelMap = () => {
  const newLabelMap = _.cloneDeep(labelMap);
  return _.omit(newLabelMap, ['status']);
};

const buildAllTestsLabelMap = () => {
  const newLabelMap = _.cloneDeep(labelMap);
  return _.omit(newLabelMap, ['flakiness']);
};

const buildAllTestCasesLabelMap = () => {
  const newLabelMap = _.cloneDeep(labelMap);
  return _.omit(newLabelMap, ['flakiness']);
};

const buildAllTestSuitesLabelMap = () => {
  const newLabelMap = _.cloneDeep(labelMap);
  return _.omit(newLabelMap, ['flakiness']);
};

const buildAllCommentsLabelMap = () => {
  const newLabelMap = _.cloneDeep(labelMap);
  return _.pick(newLabelMap, ['email']);
};

const buildAllTestCaseExecutionsLabelMap = () => {
  const newLabelMap = _.cloneDeep(labelMap);
  return _.pick(newLabelMap, ['profile', 'status']);
};

const buildAllTestCaseExecutionStatusLabelMap = () => {
  const newLabelMap = _.cloneDeep(labelMap);
  return _.pick(newLabelMap, ['profile']);
};

const buildAllPlatformStatisticsLabelMap = () => {
  const newLabelMap = _.cloneDeep(labelMap);
  return newLabelMap;
};

const buildAllAgentsLabelMap = () => ({
  name: 'Name',
  active: 'Online',
  ip: 'IP',
  uuid: 'UUID',
  lastPing: 'Last Ping',
  os: 'OS',
  hostname: 'Hostname',
});

const buildAllTestResultGroupLabelMap = () => ({
  id: t('id'),
  occupiedPercent: t('analysis#occupied'),
  category: t('analysis#category'),
});

const buildAllTestResultCategoryLabelMap = () => ({
  'ExecutionTestResult.id': t('id'),
  'ExecutionTestResult.status': labelMap.status,
  'ExecutionTestResult.startTime': labelMap.startTime,
  category: t('analysis#category'),
  'ExecutionTestResultGroup.id': t('analysis#group_id'),
});

const filterConfigById = {
  [tableIds.Execution]: buildExecutionLabelMap(),
  [tableIds.AllFailedTests]: buildAllFailedTestsLabelMap(),
  [tableIds.AllTests]: buildAllTestsLabelMap(),
  [tableIds.TestCases]: buildAllTestCasesLabelMap(),
  [tableIds.TestSuites]: buildAllTestSuitesLabelMap(),
  [tableIds.Comments]: buildAllCommentsLabelMap(),
  [tableIds.TestCaseExecutions]: buildAllTestCaseExecutionsLabelMap(),
  [tableIds.TestCaseExecutionStatus]: buildAllTestCaseExecutionStatusLabelMap(),
  [tableIds.PlatformStatistics]: buildAllPlatformStatisticsLabelMap(),
  [tableIds.Agents]: buildAllAgentsLabelMap(),
  [tableIds.ExecutionTestResultGroups]: buildAllTestResultGroupLabelMap(),
  [tableIds.ExecutionTestResultCategories]: buildAllTestResultCategoryLabelMap(),
  [tableIds.Release]: buildReleaseLabelMap(),
};

const buildFieldTypeMap = (entity) => ({
  startTime: entity.filterConfig.startTime,
  issueCreated: entity.filterConfig.issueCreated,
  endTime: entity.filterConfig.endTime,
  status: entity.filterConfig.status,
  statusIn: entity.filterConfig.statusIn,
  duration: entity.filterConfig.duration,
  id: entity.filterConfig.id,
  order: entity.filterConfig.order,
  totalErrorTests: entity.filterConfig.totalErrorTests,
  totalFailedTests: entity.filterConfig.totalFailedTests,
  totalIncompleteTests: entity.filterConfig.totalIncompleteTests,
  totalPassedTests: entity.filterConfig.totalPassedTests,
  totalTests: entity.filterConfig.totalTests,
  flakiness: entity.filterConfig.flakiness,
  sameStatusPeriod: entity.filterConfig.sameStatusPeriod,
  'TestSuite.name': entity.filterConfig['TestSuite.name'],
  'TestCase.name': entity.filterConfig['TestCase.name'],
  'Platform.browserName': entity.filterConfig['Platform.browserName'],
  'Platform.browserVersion': entity.filterConfig['Platform.browserVersion'],
  'Platform.osName': entity.filterConfig['Platform.osName'],
  'Platform.osVersion': entity.filterConfig['Platform.osVersion'],
  'ExecutionStatistics.status': entity.filterConfig['ExecutionStatistics.status'],
  'ExecutionStatistics.startTime': entity.filterConfig['ExecutionStatistics.startTime'],
  'ExecutionStatistics.duration': entity.filterConfig['ExecutionStatistics.duration'],
  'Tag.id': entity.filterConfig['Tag.id'],
  name: entity.filterConfig.name,
  value: entity.filterConfig.value,
  path: entity.filterConfig.path,
  content: entity.filterConfig.content,
  email: entity.filterConfig.email,
  executionId: entity.filterConfig.executionId,
  profile: entity.filterConfig.profile,
  active: entity.filterConfig.active,
  ip: entity.filterConfig.ip,
  uuid: entity.filterConfig.uuid,
  lastPing: entity.filterConfig.lastPing,
  os: entity.filterConfig.os,
  hostname: entity.filterConfig.hostname,
  occupiedPercent: entity.filterConfig.occupiedPercent,
  category: entity.filterConfig.category,
  'ExecutionTestResult.id': entity.filterConfig['ExecutionTestResult.id'],
  'ExecutionTestResult.status': entity.filterConfig['ExecutionTestResult.status'],
  'ExecutionTestResult.startTime': entity.filterConfig['ExecutionTestResult.startTime'],
  'ExecutionTestResult.profile': entity.filterConfig['ExecutionTestResult.profile'],
  'ExecutionTestResultGroup.id': entity.filterConfig['ExecutionTestResultGroup.id'],
  'User.id': entity.filterConfig['User.id'],
  assignee: entity.filterConfig.assignee,
  priority: entity.filterConfig.priority,
  priorityIn: entity.filterConfig.priorityIn,
  machineId: entity.filterConfig.machineId,
  machineKey: entity.filterConfig.machineKey,
  'TestSuiteCollectionEntity.name': entity.filterConfig['TestSuiteCollectionEntity.name'],
  'Machine.machineKey': entity.filterConfig['Machine.machineKey'],
  'Scheduler.id': entity.filterConfig['Scheduler.id'],
  'TestProject.name': entity.filterConfig['TestProject.name'],
  type: entity.filterConfig.type,
  fullPath: entity.filterConfig.fullPath,
  'FileHandle.name': entity.filterConfig['FileHandle.name'],
  feature: entity.filterConfig.feature,
  issueId: entity.filterConfig.issueId,
  featureName: entity.filterConfig.featureName,
  closed: entity.filterConfig.closed,
  'ExternalRelease.id': entity.filterConfig['ExternalRelease.id'],
  'Release.id': entity.filterConfig['Release.id'],
  'Build.name': entity.filterConfig['Build.name'],
  'Build.id': entity.filterConfig['Build.id'],
  buildLabel: entity.filterConfig.buildLabel,
  releaseStatus: entity.filterConfig['releaseStatus'],
  averageDuration: entity.filterConfig.averageDuration,
  updatedAt: entity.filterConfig.updatedAt,
  isLinkedDefect: entity.filterConfig.isLinkedDefect,
});

const buildStringData = (value) => value;

export const buildDateData = (value, type, value2) => {
  if (value2 !== null) {
    return `${Time.searchApiFormat(value)},${Time.searchApiFormat(value2)}`;
  } else {
    return Time.searchApiFormat(value);
  }
};

const buildDurationData = (value) => parseInt(value, 10) * 1000;

const buildAverageDurationData = (value) => parseInt(value, 10) * 60 * 1000;

const buildFlakinessData = (value) => parseInt(value, 10) / 100;

const buildNumberData = (value, fieldName) => {
  switch (fieldName) {
    case 'averageDuration':
      return buildAverageDurationData(value);
    case 'duration':
      return buildDurationData(value);
    case 'flakiness':
      return buildFlakinessData(value);
    default:
      return buildStringData(value);
  }
};

const buildEnumData = (value) => value;

const buildBooleanData = (value) => value;

export const getTableIds = () => ({ ...tableIds });

export const buildFilterValue = (entity, fieldName, value, value2 = null) => {
  const unsupportFunc = console.log;
  const functionMap = {
    NUMBER: buildNumberData,
    DATE: buildDateData,
    STRING: buildStringData,
    ENUM: buildEnumData,
    BOOLEAN: buildBooleanData,
  };

  if (!entity) {
    return unsupportFunc;
  }

  const fieldTypeMap = buildFieldTypeMap(entity);
  let functionType = fieldTypeMap[fieldName];

  if (!functionType) {
    return unsupportFunc;
  } else if (functionType.indexOf(',') > 0) {
    functionType = 'ENUM';
  }

  const buildFunc = functionMap[functionType];
  return buildFunc(value, fieldName, value2);
};

export const getFilterLabelConfig = (id) => filterConfigById[id] || _.cloneDeep(labelMap);
export const buildSearchCondition = (key, operator, value) => {
  if (value === undefined) {
    value = null;
  }
  return ({ key, operator, value });
};

export const buildSearchConditionList = (arr) => arr.map(
  (item) => buildSearchCondition(item.key, item.operator, item.value),
);

export const buildSearchFunction = (key, functionName, parameters) => ({ key, function: functionName, parameters });
export const buildSearchFunctionList = (arr) => arr.map(
  (item) => buildSearchFunction(item.key, item.functionName, item.parameters),
);

export const buildConditions = (queryObjects) => {
  const conditions = Object.keys(queryObjects).map((key) => {
    const value = queryObjects[key];
    if (value !== undefined) {
      if (_.isNumber(value)) return buildSearchCondition(key, '=', value);
      return buildSearchCondition(key, 'equals', value);
    }
    return null;
  });
  return _.filter(conditions, (conditions) => !!conditions);
};

export const parseToSortItem = (str) => {
  let splitIndex = 3;
  if (str.startsWith('asc_nulls_last')) {
    splitIndex = 14;
  } else if (str.startsWith('desc_nulls_last') || str.startsWith('asc_nulls_first')) {
    splitIndex = 15;
  } else if (str.startsWith('desc')) {
    splitIndex = 4;
  }
  return `${str.substr(splitIndex)},${str.substr(0, splitIndex)}`;
};
