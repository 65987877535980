import React, { useEffect, useMemo, useState } from 'react';
import { isEmpty, map, maxBy } from 'lodash';
import { FormGroup, FormText } from 'reactstrap';
import { Chip } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { IconArrowRightBlue } from '../../../images/CustomIcon';
import { t } from '../../../i18n/t';
import Time from '../../../utils/Moment';
import TestSuiteExecEnvAssignDialog from '../../dialog/TestSuiteExecEnvAssignDialog';
import { CloudType } from '../../../utils/Constants';
import {
  buildDisplayExecutionEnvironment,
  buildDisplayTestCloudAgentEnvironment,
  buildDisplayTestCloudAgentEnvironmentTitle
} from '../services/testcloud';
import ChipLabel from '../../ChipLabel';
import MFlags from '../../../models/MFlags';
import ChipList from '../../ChipList';

function TscExecutionEnvironmentSelect(props) {
  const { value, testProject, testSuites, groupedTestCloudAgentsTSC, nativeApps, localAgents, circleCIAgents,
    k8sAgents, onChange, form } = props;

  const [openedTsDialog, setOpenedTsDialog] = useState(false);
  const [selectedEnv, setSelectedEnv] = useState([]);
  const [numberOfEnvDisplay, setNumberOfEnvDisplay] = useState(0);

  const { setValue } = form;


  const openTsDialog = () => {
    setOpenedTsDialog(true);
  };

  const closeTsDialog = () => {
    setOpenedTsDialog(false);
  };

  const handleSelectExecEnv = (executionEnvironment) => {
    closeTsDialog();
    onChange(executionEnvironment);
  };

  const lastConfiguration = useMemo(() => {
    if (value) {
      maxBy(map(value, 'updatedAt'), (el) => el && Time.convertToDate(el));
    }
    return '';
  }, [value]);

  const isShowEnvConfig = () => !isEmpty(value.filter((el) => (el.testCloudAgent?.browser !== 'all' ||
      el.testCloudAgent?.browserType)));

  const findTsName = (value) => testSuites.find((el) => el.id === value)?.testSuiteEntity;

  useEffect(() => {
    if (!isEmpty(value) && !isEmpty(testSuites)) {
      if (value[0]?.cloudType === CloudType.TEST_CLOUD_AGENT) {
        if (isShowEnvConfig()) {
          const env = [];
          value.forEach((el) => {
            const tsName = findTsName(el?.testSuiteCollectionConfigurationId);
            env.push(
              {
                cloudType: CloudType.TEST_CLOUD_AGENT,
                tsName,
                label: buildDisplayTestCloudAgentEnvironment(el?.testCloudAgent),
                title: buildDisplayTestCloudAgentEnvironmentTitle(el?.testCloudAgent)
              }
            );
          });
          setSelectedEnv(env);
          setNumberOfEnvDisplay(3);
        } else {
          setSelectedEnv([]);
        }
      } else {
        const env = [];
        value.forEach((el) => {
          env.push(
            {
              cloudType: el.cloudType,
              id: el?.id,
              label: buildDisplayExecutionEnvironment(el),
              title: el?.name
            }
          );
        });
        setSelectedEnv(env);
        setNumberOfEnvDisplay(5);
      }
    } else {
      setSelectedEnv([]);
    }
  }, [value, testSuites]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleDeleteEnv = (event, id) => {
    event.preventDefault();
    const item = value.find((el) => el.id === id);
    const deleteItemIndex = value.indexOf(item);
    setValue('runConfiguration.testSuiteCollectionAgents', [...value.filter((value, index) => index !== deleteItemIndex)]);
  };


  return (
    <>
      <FormGroup className="d-flex">
        <a
          href="#"
          onClick={openTsDialog}
        > {t('test-suite-collection-override#configure-environment')}
          <IconArrowRightBlue className="ml-3" width={11} height={11} />
        </a>
        <FormText color="muted">
          {lastConfiguration ?
            <>
              {`${t('test-suite-collection-override#last-configuration')}: `}
              {Time.format(lastConfiguration, Time.TIME_THEN_DATE_FORMAT)}
            </> : null}
        </FormText>
      </FormGroup>
      {MFlags.testCloudTscShowEnvironmentConfigurationEnabled &&
      <ChipList numberOfItemDisplay={numberOfEnvDisplay} handleShowMore={() => openTsDialog()}>
        {selectedEnv.map((env) => (
          env.cloudType === CloudType.TEST_CLOUD_AGENT ? <ChipLabel label={env?.tsName} name={env.label} title={env?.title} /> :
          <div className="pr-2 pb-2">
            <Chip
              sx={{ cursor: 'default !important' }}
              className="border-0 chip-bg-border"
              variant="outlined"
              label={env?.label}
              title={env?.title}
              onDelete={(e) => handleDeleteEnv(e, env?.id)}
              deleteIcon={<ClearIcon fontSize="small" onMouseDown={(event) => event.stopPropagation()} />}
              size="small"
            />
          </div>
        ))}
      </ChipList>}
      <TestSuiteExecEnvAssignDialog
        id="testsuites-dialog"
        initialValue={value}
        testProject={testProject}
        isOpen={openedTsDialog}
        onClose={closeTsDialog}
        onOk={handleSelectExecEnv}
        testSuites={testSuites}
        groupedTestCloudAgentsTSC={groupedTestCloudAgentsTSC}
        nativeApps={nativeApps}
        localAgents={localAgents}
        circleCIAgents={circleCIAgents}
        k8sAgents={k8sAgents}
      />
    </>
  );
}

export default TscExecutionEnvironmentSelect;
