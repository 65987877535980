import React from 'react';
import { Row, Col } from 'reactstrap';
import _ from 'lodash';
import PageComponent from '../components/PageComponent';
import Apis from '../utils/Apis';
import ExecutionRequestDataTable from '../components/table/ExecutionRequestDataTable';
import http from '../utils/http';
import MContext from '../models/MContext';
import { t } from '../i18n/t';
import ObjectSummary from '../components/summary/ObjectSummary';
import ObjectSummaryParametersBuilder from '../components/summary/ObjectSummaryParametersBuilder';
import { buildSearchCondition } from '../components/search/SearchUtils';
import Services from '../utils/Services';
import PerformanceLineChartForTestObject from '../components/chart/PerformanceLineChartForTestObject';
import DataLoader from '../components/table/DataLoader';
import DefaultLayout from '../components/DefaultLayout';

class ExecutionTestObjectHistory extends PageComponent {
  constructor(props) {
    super(props);
    this.meta.id = 'page-execution-test-object-history';
    this.testObjectId = MContext.testObjectId;
    this.state = {
      testObject: null,
      latestExecutionTestObjects: null,
    };
    this.renderPerformanceChart = this.renderPerformanceChart.bind(this);
    this.renderHeader = this.renderHeader.bind(this);
    this.renderBody = this.renderBody.bind(this);
  }

  getTestObject(testObjectId) {
    http.get(Apis.testObject(testObjectId))
      .then((responseJson) => {
        const testObject = responseJson;
        this.meta.title = t('Test Object {{name}}', {
          name: testObject.name,
        });
        this.setState({
          testObject,
        });
        this.getLatestExecutionTestObjects(testObject.id);
      });

  }

  getLatestExecutionTestObjects(id) {
    const { testObject } = this.state;
    const params = {
      pagination: {
        page: 0,
        size: 100,
        sorts: 'id,desc',
      },
      conditions: [
        {
          key: 'TestObject.id',
          operator: '=',
          value: id,
        },
      ],
      type: 'ExecutionRequest',
    };
    Services.search(params)
      .then((responseJson) => {
        const latestExecutionTestObjects = responseJson.content.reverse();
        _.forEach(latestExecutionTestObjects, (latestExecutionTestObject) => {
          latestExecutionTestObject.median = testObject.duration;
          latestExecutionTestObject.duration = latestExecutionTestObject.time;
        });
        this.setState({
          latestExecutionTestObjects,
        });
      });
  }

  componentDidMount() {
    this.getTestObject(this.testObjectId);
  }

  renderPerformance() {
    return (
      <DataLoader
        title={t('performance')}
        entityType="ExecutionRequest"
        defaultSearchConditions={[
          buildSearchCondition('TestObject.id', '=', this.state.testObject.id),
        ]}
        defaultSort={['startedAt,asc']}
        render={this.renderPerformanceChart}
      />
    );
  }

  renderPerformanceChart(data) {
    return (
      <PerformanceLineChartForTestObject
        data={data}
        xLabel="Requests"
      />
    );
  }

  renderHistory() {
    return (
      <ExecutionRequestDataTable
        title={t('history')}
        defaultSearchConditions={[
          buildSearchCondition('TestObject.id', '=', this.state.testObject.id),
        ]}
        defaultSort={['startedAt,desc']}
        sourceFieldName="content"
      />
    );
  }

  renderObjectSummary() {
    const { testObject } = this.state;
    const urlParams = new ObjectSummaryParametersBuilder();
    return (
      <ObjectSummary
        params={
          urlParams.toTestObjectHistoryParams(
            testObject.project,
            testObject,
          )
}
      />
    );
  }

  renderHeader() {
    return (
      <>
        {this.renderObjectSummary()}
      </>
    );
  }

  renderBody() {
    return (
      <div>
        <Row>
          <Col lg="12" md="12">
            {this.renderPerformance()}
          </Col>
        </Row>
        {this.renderHistory()}
      </div>
    );
  }

  render() {
    const { testObject, latestExecutionTestObjects } = this.state;
    if (testObject && latestExecutionTestObjects) {
      return (
        <DefaultLayout
          renderHeader={this.renderHeader}
          renderBody={this.renderBody}
        />
      );
    }
    return <div>No history</div>;
  }
}

export default ExecutionTestObjectHistory;
