import Icon from '@katalon-studio/katalon-ui/Icon';
import React from 'react';
import colors from '../../../scss/colors.scss';

function PenFieldIcon({ color, textSize }) {
  return <Icon
    type="fa-regular"
    name="fa-pen-field"
    textSize={textSize}
    sx={{
      color,
      width: textSize,
      height: textSize,
      display: 'flex',
      justifyContent: 'center',
    }}
    boundingSize={null}
    color={null}
  />;
}

PenFieldIcon.defaultProps = {
  color: colors.gray9,
  textSize: '14px',
};

export default PenFieldIcon;
