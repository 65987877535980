import moment from 'moment';
import Time from './Moment';
import { getEndTime, getStartTime } from '../pages/test_reports/utils';
import { defaultGroupByTime, TestRunDailyOptionsV2 } from './Constants';
import Mflags from '../models/MFlags';

export default {
  /**
   * Start Date: seven days ago
   * End Date: currentDate
   * Example: Current day is Nov 15, 2022.
   * startTime:  Nov 9, 2022.
   * endTime: Nov 15, 2022.
   * @returns {{startTime: (*|moment.Moment), endTime: (*|moment.Moment)}}
   */
  getLast7Days(currentDate) {
    const to = Time.endOf(currentDate, 'day');
    const sevenDaysAgo = currentDate.subtract(6, 'day');
    const from = Time.startOf(sevenDaysAgo, 'day');
    return { startTime: from, endTime: to };
  },
  /** *
   * Default End Date = The end of Current Date
   * Default Start Date:
   * Start Day: The first day of the Start Month
   * Start Month = End Month - 5
   * Start Year: Year where the Start Month is in
   * @returns {{startTime: (*|moment.Moment), endTime: (*|moment.Moment)}}
   */
  getLast6Months() {
    const endDate = Time.endOf(Time.now(), 'day');
    let startDate = Time.timeAgoFromDate(Time.now(), 5, 'months');
    // get the first day of the Start Month
    startDate = Time.startOf(startDate, 'month');
    const defaultDate = { startTime: startDate, endTime: endDate };
    return defaultDate;
  },

  getStartDate(dateRange) {
    return dateRange.length === 0 ? getStartTime(TestRunDailyOptionsV2.SEVEN_DAYS.value, defaultGroupByTime) :
      moment.min(dateRange.map((d) => moment(d)));
  },

  getNormalizeEndDate(dateRange) {
    let endDate;
    if (dateRange.length === 0) {
      endDate = getEndTime(defaultGroupByTime);
    } else {
      endDate = moment.max(dateRange.map((d) => moment(d)));
      // fix bug choose time range the x-axis have more than one day
      if (Mflags.fixTimeRangeInChartEnable) {
        endDate = moment(endDate).subtract(1, 'day');
      }
    }
    if (endDate.isAfter(new Date(), 'day')) {
      endDate = moment();
    }
    return endDate;
  },
};
