import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

function AlertComponent({ text, icon, iconClose, className }) {
  return (
    <div className={classnames('d-flex', 'alert-component', className)}>
      {icon && <span className="icon">{icon}</span>}
      {text}
      {iconClose && <span className="ml-auto">{iconClose}</span>}
    </div>
  );
}

AlertComponent.propTypes = {
  text: PropTypes.any,
  icon: PropTypes.any,
  iconClose: PropTypes.any,
  className: PropTypes.string
};

AlertComponent.defaultProps = {
  text: '',
  icon: null,
  iconClose: null,
  className: 'warning'
};

export default AlertComponent;
