import React from 'react';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';
import NoPreviewThumbnail from '../../../images/file-preview/thumbnail.svg';
import NoVideoThumbnail from '../../../images/file-preview/loading-preview.svg';
import VideoThumbnail from '../../../images/file-preview/thumbnail-video.svg';
import FileThumbnail from '../../../images/file-preview/thumbnail-file.svg';

import FilePreviewer from './FilePreviewer';
import ImageThumbnail from './ImageThumbnail';

class Gallery extends React.Component {
  constructor(props) {
    super(props);
    this.imagePreviewerRef = React.createRef();
  }


  renderThumbnail(source, caption, fileType, thumbnail, title, buttonItems, isLoading) {
    if (isLoading) {
      return (
        <ImageThumbnail
          imageUrl={NoVideoThumbnail}
          header={caption}
          title={title || caption}
          buttonItems={buttonItems}
        />
      );
    }
    const lowerFileType = fileType ? fileType.toLowerCase() : fileType;
    const fileTypeMappings = [
      { types: ['mp4', 'mov', 'avi', 'webm'], thumbnail: VideoThumbnail },
      { types: ['txt', 'log'], thumbnail: FileThumbnail },
      { types: ['png', 'jpg'], thumbnail: source },
    ];

    const fileTypeMapping = fileTypeMappings.find((mapping) => mapping.types.includes(lowerFileType));
    const imageUrl = fileTypeMapping ? fileTypeMapping.thumbnail : NoPreviewThumbnail;
    const thumbnailUrl = isEqual(imageUrl, source) ? thumbnail : undefined;

    return (
      <ImageThumbnail
        imageUrl={imageUrl}
        thumbnailUrl={thumbnailUrl}
        header={caption}
        title={title || caption}
        buttonItems={buttonItems}
      />
    );
  }

  renderGallery(images) {
    return (
      <>
        {images.map(({ source, caption, fileType, thumbnail, title, buttonItems, isLoading }, index) => (
          <div className="col-4 gallery-item" onClick={() => this.selectThumbnail(index)} key={caption} data-trackid={this.props['data-trackid']}>
            {this.renderThumbnail(source, caption, fileType, thumbnail, title, buttonItems, isLoading)}
          </div>
        ))}
      </>
    );
  }

  selectThumbnail(index) {
    this.imagePreviewerRef.current.openLightbox(index);
  }

  render() {
    const { images } = this.props;

    return (
      <>
        <div className="row">
          {this.renderGallery(images)}
        </div>
        <FilePreviewer
          ref={this.imagePreviewerRef}
          images={images}
        />
      </>
    );
  }
}

Gallery.propTypes = {
  /**
   * The list of images to show in gallery
   * For each element, please add
   *    {
   *      source: the URL of image
   *      caption: name or header of image
   *      fileType: type of image <png || jpg || etc>
   *      thumbnail: the URL of thumbnail image
   *    }
   */
  images: PropTypes.array.isRequired,
};

export default Gallery;
