import PropTypes from 'prop-types';
import React from 'react';
import LinkIssueInput from '../LinkIssueInput';
import Services from '../../utils/Services';
import Notification from '../../utils/Notification';
import { ObjectType } from '../../utils/Constants';
import { t } from '../../i18n/t';
import MFlags from '../../models/MFlags';

class LinkExternalIssue extends React.Component {
  constructor(props) {
    super(props);
    this.linkExternalIssue = this.linkExternalIssue.bind(this);
    this.projectId = this.props.projectId;
  }

  linkExternalIssue(issueId) {
    const { objectType, objectId, afterLink } = this.props;
    const { mapCustomizedXrayIssueTypeEnabled } = MFlags;

    const params = {
      issueId,
      objectType,
      objectId,
    };
    Services.linkExternalIssue(this.projectId, params, mapCustomizedXrayIssueTypeEnabled && this.errorHandler)
      .then(() => {
        let message = '';
        if (objectType === ObjectType.TEST_CASE) {
          message = t('jira-issue#requirement-added');
        } else if (objectType === ObjectType.XRAY_TEST_CASE) {
          message = t('jira-issue#xray-test-added');
        } else {
          message = t('jira-issue#defect-added');
        }
        Notification.pushSuccess(message);
        return afterLink ? afterLink() : null;
      })
      .catch(() => {});
  }

  errorHandler(message) {
    Notification.pushError(message, t('invalid-issue-type'));
  };

  render() {
    const { objectType, ...rest } = this.props;
    return (
      <LinkIssueInput
        id="jira-issue"
        onSave={this.linkExternalIssue}
        trackProps={{
          'data-trackid': 'link-jira-issue',
          'data-tracklabel': objectType,
        }}
        objectType={objectType}
        {...rest}
      />
    );
  }
}


LinkExternalIssue.propTypes = {
  projectId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  objectType: PropTypes.string,
  objectId: PropTypes.number,
  externalIssue: PropTypes.array,
  afterLink: PropTypes.func,
};


export default LinkExternalIssue;
