import React from 'react';
import Select, { components } from 'react-select';
import MenuItem from '@mui/material/MenuItem';
import { t } from '../i18n/t';

const { Option } = components;

const Menu = (props) => {

  const handleClickItem = () => {
    props.selectProps.onClickMoreOption(true);

  };
  return (
    <>
      <components.Menu {...props}>
        <div>
          {props.selectProps.menuTitle &&
          <MenuItem className="menu-label pt-4">{props.selectProps.menuTitle}
          </MenuItem>}
          <div>{props.children}</div>
          {props.selectProps.onClickMoreOption &&
          <MenuItem className="more-option-menu-label" value="1" onClick={() => handleClickItem()}> More options
          </MenuItem>}
        </div>
      </components.Menu>
    </>
  );
};

const CustomSelectOption = (props) => (
  <Option {...props} className="d-flex justify-content-between">
    <div className="d-flex">
      {props.data.prefixOption}&nbsp;
      {props.data.icons &&
      <div className="mr-1 d-flex align-items-center">
          {props.data.icons.map((icon) => (
            <>
              {icon || ''}&nbsp;
            </>
          ))}
      </div>}
      {props.data.label}
    </div>
  </Option>
);

const CustomSelectValue = (props) => (
  <div {...props} className="d-flex">
    {props.data.prefixOption}&nbsp;
    {props.data.icons &&
    <div className="mr-1 d-flex align-items-center">
      {props.data.icons.map((icon) => (
        <>
          {icon || ''}&nbsp;
        </>
      ))}
    </div>}
    {props.data.label}
  </div>
);



const CustomSelect = (props) => {
  const {
    value,
    options,
    onChange,
    onClickMoreOption,
    menuTitle,
  } = props;


  const handleChange = (value) => {
    onChange(value);
  };

  return (
    <div>
      <Select
        {...props}
        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
        className="react-select-container react-select-label"
        classNamePrefix="react-select"
        value={value}
        isMulti
        options={options}
        components={{ Menu,
          Option: CustomSelectOption,
          MultiValueLabel: CustomSelectValue }}
        onChange={handleChange}
        onClickMoreOption={onClickMoreOption}
        isOptionDisabled={(option) => option.disabled}
        menuTitle={menuTitle}
        placeholder={t('schedule#select-environment')}
      />
    </div>
  );
};
export default CustomSelect;
