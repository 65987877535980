import React from 'react';
import PropTypes from 'prop-types';
import { NavLink as ReactStrapNavLink } from 'reactstrap';
import { Link } from 'react-router-dom';

const exactMatches = (patterns, url, exactMatch) => {
  for (let i = 0; i < patterns.length; i++) {
    const pattern = patterns[i];
    if (exactMatch) {
      if (url.endsWith(pattern)) {
        return true;
      }
    } else {
      if (url.indexOf(pattern) >= 0) {
        return true;
      }
    }
  }
  return false;
};

const NavLink = (props) => {
  const newProps = {
    ...props,
  };
  const href = newProps.pattern || newProps.href;
  if (href !== '#') {
    const url = window.location.href;
    if (Array.isArray(href)) {
      newProps.active = exactMatches(href, url, newProps.exactMatch);
    } else {
      newProps.active = exactMatches(Array.of(href), url, newProps.exactMatch);
    }
  }
  delete newProps.exactMatch;
  if (!props.spa) {
    return <ReactStrapNavLink {...newProps}>{props.children}</ReactStrapNavLink>;
  }
  return (
    <ReactStrapNavLink tag={Link} to={props.href} {...newProps}>
       {props.children}
    </ReactStrapNavLink>
  );
//   return (
//     <ReactStrapNavLink {...newProps}>
//       <Link to={props.href}>{props.children}</Link>
//     </ReactStrapNavLink>
//   );
//   return (
//     <Link to={props.href}>
//       <ReactStrapNavLink {...newProps}>{props.children}</ReactStrapNavLink>
//     </Link>
//   );
};

NavLink.propsTypes = {
  spa: PropTypes.bool,
};

NavLink.defaultProps = {
  spa: true
};

export default NavLink;
