import React from 'react';
import DefaultLayout from '../../components/DefaultLayout';
import PageComponent from '../../components/PageComponent';
import { buildSearchCondition } from '../../components/search/SearchUtils';
import ObjectSummary from '../../components/summary/ObjectSummary';
import ExecutionDataTable from '../../components/table/ExecutionDataTable';
import { t } from '../../i18n/t';
import MContext from '../../models/MContext';
import { SearchEntity } from '../../utils/Constants';
import Services from '../../utils/Services';
import BuildInfo from './BuildInfo';
import BuildStatisticsStatus from './BuildStatisticsStatus';

class BuildDetails extends PageComponent {

  constructor(props) {
    super(props);
    this.releaseId = MContext.releaseId;
    this.buildId = MContext.buildId;
    this.state = {
      build: null,
      release: null,
    };
    this.renderHeader = this.renderHeader.bind(this);
    this.renderBody = this.renderBody.bind(this);
  }


  getBuild() {
    const params = {
      pagination: {
        page: 0,
        size: 1,
        sorts: 'order,desc',
      },
      conditions: [
        buildSearchCondition('id', '=', this.buildId),
      ],
      type: 'Build',
    };
    Services.search(params)
      .then(({ content }) => {
        const build = content[0];
        this.meta.title = t('build#title', { name: build.name });
        this.setState({ build });
      });
  }

  componentDidMount() {
    this.getRelease();
    if (this.buildId) {
      this.getBuild();
    }
  }

  getRelease() {
    const params = {
      pagination: {
        page: 0,
        size: 1,
        sorts: 'order,desc',
      },
      conditions: [
        buildSearchCondition('id', '=', this.releaseId),
      ],
      type: SearchEntity.Release,
    };
    Services.search(params).then(({ content = [] }) => {
      const [release] = content;
      if (release) {
        this.setState({ release });
      }
    });
  }

  renderHeader() {
    const { build, release } = this.state;
    const urlParams = {
      releaseId: this.releaseId,
      releaseName: release?.name,
      releases: t('releases'),
      buildUpdate: t('build#title', { name: build.name }),
    };
    return (
      <ObjectSummary params={urlParams} />
    );
  }


  renderBody() {
    const { build } = this.state;
    return (
      <>
        <BuildStatisticsStatus buildId={this.buildId} />
        <BuildInfo build={build} />
        <ExecutionDataTable
          title={t('executions')}
          defaultSearchConditions={[
            buildSearchCondition('Build.id', '=', this.buildId),
          ]}
          showProjectColumn={false}
        />
      </>
    );
  }


  render() {
    const { build } = this.state;
    if (build) {
      return (
        <DefaultLayout
          renderHeader={this.renderHeader}
          renderBody={this.renderBody}
        />
      );
    } else {
      return null;
    }
  }
}

export default BuildDetails;
