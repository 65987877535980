import React, { useState } from 'react';
import { Button } from 'reactstrap';
import classnames from 'classnames';
import { t } from '../../../i18n/t';
import {
  IconKatalonPlatformColor,
  IconExploreSampleProject,
  IconImportExistingProject,
  IconCreateNewProject
} from '../../../images/CustomNewIcon';
import Routes from '../../../utils/Routes';
import { SETUP_GIT_REPOSITORY_OPTIONS } from '../../../utils/Constants';
import Services from '../../../utils/Services';
import MContext from '../../../models/MContext';
import { updateUserTraits } from '../../../utils/UserFlowHelper';
import { sendAnalyticEventForAction } from '../../../utils/SegmentAnalytics';
import { KS_DOWNLOAD_TYPE } from '../../../components/dialog/DownloadKSDialog';
import MFlags from '../../../models/MFlags';

const WayToStart = () => {

  const [pickedOption, setPickedOption] = useState();
  const teamId = MContext.teamId;
  const projectId = MContext.projectId;

  const errorHandler = () => {
    // Redirect to Git set up page and auto expand sample git options in case populate sample failed
    const urlParams = {
      isOnboarding: true,
      option: SETUP_GIT_REPOSITORY_OPTIONS.USE_SAMPLE
    };
    Routes.goToGitTestProjectSetup(urlParams);
  };

  const exploreSampleProject = () => {
    Services.populateSampleProject(projectId, errorHandler)
      .then(() => {
        Routes.goToProjectPage(teamId, projectId);
      })
      .catch(() => { /* ignore */ });
  };

  const importExistingProject = () => {
    const urlParams = {
      isOnboarding: true,
      option: SETUP_GIT_REPOSITORY_OPTIONS.CREATE_NEW
    };
    Routes.goToGitTestProjectSetup(urlParams);
  };

  const createNewProject = () => {
    Routes.goToProjectPage(teamId, projectId);
  };

  const WAYS_TO_START = [
    {
      id: 1,
      title: t('way-to-start#1'),
      icon: <IconExploreSampleProject />,
      background: 'explore-sample-background',
      description: t('way-to-start#1-description'),
      handler: () => exploreSampleProject(),
      userFlowValue: 'explore_sample',
      trackingId: 'select-explore-our-sample-project-option-on-get-started-page',
      customTrackingId: 'explore-our-sample-project'
    },
    {
      id: 2,
      title: t('way-to-start#2'),
      icon: <IconImportExistingProject />,
      background: 'import-existing-background',
      description: t('way-to-start#2-description'),
      handler: () => importExistingProject(),
      userFlowValue: 'import_git',
      trackingId: 'select-import-existing-project-from-git-repository-option-on-get-started-page',
      customTrackingId: 'import-existing-project-from-git-repository'
    },
    {
      id: 3,
      title: t('way-to-start#3'),
      icon: <IconCreateNewProject />,
      background: 'create-new-background',
      description: t('way-to-start#3-description'),
      handler: () => createNewProject(),
      userFlowValue: 'new_project',
      trackingId: 'select-create-new-project-from-scratch-option-on-get-started-page',
      customTrackingId: 'create-new-project-from-scratch'
    }
  ];

  const [background, setBackground] = useState(WAYS_TO_START[2].background);
  const [description, setDescription] = useState(WAYS_TO_START[2].description);

  const getStarted = () => {
    sendAnalyticEventForAction('submit-selected-option-on-Get-started-page', { selected_option: pickedOption?.customTrackingId });
    updateUserTraits({ onboarding_option: pickedOption?.userFlowValue }).catch(() => { /* ignore */ });
    return pickedOption?.handler();
  };

  const optionPickingHandler = (answer) => {
    if (answer.id === pickedOption?.id) {
      setPickedOption(null);
    } else {
      sendAnalyticEventForAction(answer.trackingId, {});
      setPickedOption(answer);
      setBackground(answer.background);
      setDescription(answer.description);
    }
  };

  const renderAnswers = (answers) => answers.map((answer) => (
    <div key={`answer-${answer.id}`}>
      <div
        className={classnames('answer-icon', answer.id === pickedOption?.id && 'selected')}
        onClick={() => optionPickingHandler(answer)}
        data-testid={`answer-${answer.id}`}
      >
        {answer.icon}
      </div>
      <div className="answer-info">
        <h6>{answer.title}</h6>
      </div>
    </div>));

  return (
    <div className="way-to-start">
      <div className="left-screen">
        <div className="header-icon">
          <IconKatalonPlatformColor width="105.5" height="24.766" />
        </div>
        <div className="mini-header">{t('way-to-start#header')}</div>
        <div className="question-subheader">{t('way-to-start#subheader')}</div>
        <div className="question-answers">{renderAnswers(WAYS_TO_START)}</div>
        <div className="footer">
          <Button
            className="start-btn"
            color="primary"
            onClick={() => getStarted()}
            disabled={!pickedOption}
          >
            {t('get-started')}
          </Button>
        </div>
      </div>
      <div className="right-screen">
        <p className="description">{description}</p>
        <div className={classnames('background', background)} />
      </div>
    </div>
  );
};

export default WayToStart;
