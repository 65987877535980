import { Form, FormGroup, Label } from 'reactstrap';
import React from 'react';
import { Dialog, DialogBody, DialogFooter, DialogHeader } from '@katalon-studio/katalon-ui/Dialog';
import Grid from '@mui/material/Grid';
import Button from '@katalon-studio/katalon-ui/Button';
import classnames from 'classnames';
import { t } from '../../../i18n/t';
import TestSuiteSelectTree from '../testsuite/TestSuiteSelectTree';
import ConvertDataHelper from '../../../utils/ConvertDataHelper';
import { TestCase } from '../../../models/model/TestCase';
import { TestSuite } from '../../../models/model/TestSuite';
import { Source } from '../../../models/model/Source';

interface AddToTestSuiteViewProps {
  testCases: TestCase[];
  testSuite: TestSuite | undefined;
  testSuiteType: string,
  setTestSuite: (testSuite: TestSuite) => void;
  className?: string;
  setSwitchToAddTestSuite: (switchToAddTestSuite: boolean) => void;
  handleClose: () => void;
  handleAddToTestSuite: () => void;
  isOpen: boolean,
}

function AddToTestSuiteView(props: AddToTestSuiteViewProps) {
  const {
    testCases,
    testSuite,
    setTestSuite,
    className,
    setSwitchToAddTestSuite,
    handleClose,
    testSuiteType,
    handleAddToTestSuite,
    isOpen
  } = props;
  const firstTestCase = testCases[0];
  const icon = ConvertDataHelper.renderRepositoryIcon(firstTestCase.testProject?.type);
  const testProjectTitle = ConvertDataHelper.buildIconTitle(firstTestCase.testProject?.name, icon);

  const renderFooter = () => (
    <Grid
      display="flex"
      justifyContent="left"
      container
      className="d-flex align-items-center"
    >
      <Grid item xs={6} sm={6} md={6}>
        <Button
          size="medium"
          variant="text"
          color="primary"
          onClick={() => setSwitchToAddTestSuite(true)}
        >
          {t('create-test-suite')}
        </Button>
      </Grid>
      <Grid item xs={6} sm={6} md={6} container spacing={2} className="d-flex justify-content-end">
        <Grid item>
          <Button
            size="medium"
            variant="text"
            color="primary"
            onClick={handleClose}
          >
            {t('cancel')}
          </Button>
        </Grid>
        <Grid item>
          <Button
            size="medium"
            variant="contained"
            color="primary"
            onClick={handleAddToTestSuite}
            disabled={!testSuite}
          >
            {t('add')}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );

  const selectedTestCasesLabel = testCases.length > 1 ? `${testCases.length} ${t('selectedTestCases')}` : firstTestCase.name;

  return (
    <Dialog
      open={isOpen}
      maxWidth="sm"
      className={classnames(className)}
    >
      <DialogHeader onClick={handleClose}>
        {t('addToTestSuite')}
      </DialogHeader>
      <DialogBody
        sx={{ rowGap: '0.2rem' }}
      >
        <Form>
          <FormGroup style={{ marginBottom: '13px' }}>
            <div className="mb-4">{t('add')} <b>{selectedTestCasesLabel}</b> {t('to#lower-case')}:</div>
            <Label>{t('repository')}</Label>
            <div className="mb-4">{testProjectTitle}</div>
            <Label>{t('test-suite')} *</Label>
            <TestSuiteSelectTree
              testSuiteType={testSuiteType}
              testProjectId={firstTestCase.testProject?.id}
              setTestSuite={setTestSuite}
              includedTestSuiteTypes={[testSuiteType]}
              excludedTestSuiteSources={[Source.SYSTEM]}
              excludedFolderPaths={['test-suites/TestOps']}
            />
          </FormGroup>
        </Form>
      </DialogBody>
      <DialogFooter>
        {renderFooter()}
      </DialogFooter>
    </Dialog>
  );
}

export default AddToTestSuiteView;
