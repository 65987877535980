import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { t } from '../../i18n/t';
import MContext from '../../models/MContext';
import PageComponent from '../../components/PageComponent';
import ObjectSummary from '../../components/summary/ObjectSummary';
import DefaultLayout from '../../components/DefaultLayout';
import RouteConstants from '../../utils/RouteConstants';
import WebServicesTab from './WebServicesTab';
import AnomalyTab from './AnomalTab';
import { WebServiceHeaderNav } from '../../components/HeaderNav';

class TestObjects extends PageComponent {
  constructor(props) {
    super(props);
    this.projectId = MContext.projectId;
    this.teamId = MContext.teamId;
    this.project = MContext.project;
    this.meta.id = 'page-web-services';
    this.meta.title = t(
      'Project {{name}} - {{page}}',
      { name: this.project.name, page: t('test_objects') },
    );
    this.renderHeader = this.renderHeader.bind(this);
    this.renderBody = this.renderBody.bind(this);
  }

  renderObjectSummary() {
    const { project } = this;
    const urlParams = {
      testObject: t('test_objects'),
      projectName: project.name,
    };
    return (
      <ObjectSummary params={urlParams}>
        <WebServiceHeaderNav />
      </ObjectSummary>
    );
  }

  renderHeader() {
    return (
      <>
        {this.renderObjectSummary()}
      </>
    );
  }

  renderBody() {
    return (
      <>
        <Switch>
          <Route
            exact
            path={RouteConstants.web_services}
            render={(params) => <WebServicesTab {...params} />}
          />
          <Route
            exact
            path={RouteConstants.web_services_anomalies}
            render={(params) => <AnomalyTab projectId={this.projectId} {...params} />}
          />
        </Switch>
      </>
    );
  }

  render() {
    return (
      <DefaultLayout
        renderHeader={this.renderHeader}
        renderBody={this.renderBody}
      />
    );
  }
}

export default TestObjects;
