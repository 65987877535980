import React from 'react';
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';

import { buildSearchCondition } from '../../components/search/SearchUtils';
import Select from '../../components/Select';
import MTableColumnDataMapping from '../../components/table/models/MTableColumnDataMapping';
import { t } from '../../i18n/t';
import { IconDelete } from '../../images/KitIcons';
import Arrays from '../../utils/Arrays';
import { OrganizationFeature, OrganizationRole, SearchEntity } from '../../utils/Constants';
import Notification from '../../utils/Notification';
import Services from '../../utils/Services';
import BaseEngineLicensesTab from './BaseEngineLicensesTab';


class OrganizationUnlimitedEngineLicensesTab extends BaseEngineLicensesTab {
  constructor(props) {
    super({ ...props, feature: OrganizationFeature.UNLIMITED_ENGINE });
    this.meta.id = 'page-organization-unlimited-engine-licenses';
    this.meta.title = t('unlimited_engine_license_keys');
    this.state = {
      ...this.state,
      emailToAssign: '',
      emailToRemove: '',
      idToRemove: '',
      assignableUsers: [],
      removalModal: false,
    };
    this.selectChange = this.selectChange.bind(this);
    this.addFeature = this.addFeature.bind(this);
    this.openFeatureRemovalDialog = this.openFeatureRemovalDialog.bind(this);
    this.removeFeature = this.removeFeature.bind(this);
    this.closeFeatureRemovalDialog = this.closeFeatureRemovalDialog.bind(this);
  }

  decorateAssignableUsers(userOrganization) {
    return userOrganization.map((u) => {
      const role = (u.role && u.role !== OrganizationRole.USER) ? ` (${u.role})` : '';
      return {
        value: u.user.email,
        label: `${u.user.email}${role}`,
      };
    });
  }

  fetchAssignableUsers() {
    const params = {
      pagination: {
        page: 0,
        size: 1000,
        sorts: ['id,desc'],
      },
      conditions: [
        buildSearchCondition('Organization.id', '=', this.organizationId),
        ...Arrays.insertIf(this.state.unlimitedEngineUsers.length > 0,
          buildSearchCondition('User.id', 'not in', this.state.unlimitedEngineUsers.map(uof => uof.user.id).toString())),
      ],
      type: SearchEntity.UserOrganization,
    };
    Services.search(params)
      .then(({ content: assignableUsers }) => {
        this.setState({ assignableUsers: this.decorateAssignableUsers(assignableUsers) })
      });
  }

  componentDidMount() {
    super.componentDidMount();
  }

  openFeatureRemovalDialog(email, id) {
    this.setState({
      removalModal: true,
      idToRemove: id,
      emailToRemove: email,
    });
  }

  removeFeature(e) {
    e.preventDefault();
    const { idToRemove, emailToRemove } = this.state;
    Services.removeOrganizationFeatures({ organizationId: this.organizationId, userId: idToRemove, feature: this.feature })
      .then(() => {
        Notification.pushSuccess(t('license_keys#online@user_removed', { email: emailToRemove }));
        this.refreshLicensedUsersSection();
      });
    this.closeFeatureRemovalDialog();
  }

  closeFeatureRemovalDialog() {
    this.setState({
      removalModal: false,
    });
  }

  renderRemovalDialog() {
    const {
      removalModal, emailToRemove,
    } = this.state;

    return (
      <Modal isOpen={removalModal}>
        <Form data-trackid="remove-kse-feature" onSubmit={this.removeFeature}>
          <ModalHeader>{`${t('remove')}?`}</ModalHeader>
          <ModalBody>
            {t('license_keys#online@confirm_removal', { product: this.productName, email: emailToRemove })}
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              type="submit"
            >{t('remove')}
            </Button>
            <Button
              color="secondary"
              onClick={this.closeFeatureRemovalDialog}
            >{t('cancel')}
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    );
  }

  userHeader() {
    return [
      new MTableColumnDataMapping(t('name'), 'fullName'),
      new MTableColumnDataMapping(t('email'), 'email'),
      ...Arrays.insertIf(this.state.hasBothLicenseTypes, new MTableColumnDataMapping(
        '',
        'id',
        (name, row) => (
          <Button
            title={t('license_keys#online@remove_tooltip')}
            color="link"
            onClick={() => this.openFeatureRemovalDialog(row.email, row.id)}
          >
            <IconDelete />
          </Button>
        ),
        true,
      )),
    ];
  }

  selectChange(option) {
    this.setState({
      emailToAssign: option.value,
    });
  }

  addFeature(e) {
    e.preventDefault();
    const { emailToAssign } = this.state;
    Services.addOrganizationFeatures({ organizationId: this.organizationId, userEmail: emailToAssign, feature: this.feature })
      .then(() => {
        Notification.pushSuccess(t('license_keys#online@invited', { email: emailToAssign }));
        this.setState(
          { emailToAssign: '' },
          () => this.refreshLicensedUsersSection(),
        );
      });
  }

  refreshLicensedUsersSection() {
    this.kseUserList.refreshData();
    this.fetchUnlimitedEngineUsers();
  }

  renderLicensedUser() {
    const {
      emailToAssign, assignableUsers, hasBothLicenseTypes
    } = this.state;
    return (
      <Card id="LicensedUsersSection">
        <CardHeader>{t('license_keys#online@licensed_users')}</CardHeader>
        <CardBody>
          <Row>
            <Col>
              <Alert color="primary">
                {hasBothLicenseTypes
                  ? t('license_keys#online@default_notice')
                  : t('license_keys#online@unlimited_engine_notice')}
              </Alert>
            </Col>
          </Row>
          <Row>
            <Col sm="12" md="8" lg="6" xl="5">
              <Form data-trackid="add-kse-feature" onSubmit={this.addFeature}>
                <FormGroup>
                  <Select
                    required
                    clearable={false}
                    id="cloudType"
                    name="cloudType"
                    value={emailToAssign}
                    options={assignableUsers}
                    disabled={!this.state.hasBothLicenseTypes}
                    onChange={this.selectChange}
                  />
                </FormGroup>
                <FormGroup>
                  <Button
                    color="primary"
                    type="submit"
                    disabled={!hasBothLicenseTypes}
                  >
                    {t('add')}
                  </Button>
                </FormGroup>
              </Form>
            </Col>
          </Row>
          {this.renderLicensedTableOfFeature(OrganizationFeature.UNLIMITED_ENGINE)}
        </CardBody>
      </Card>
    );
  }
}

export default OrganizationUnlimitedEngineLicensesTab;
