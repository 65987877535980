import React from 'react';
import {
  Button,
  Form,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import Services from '../../utils/Services';
import Input from '../Input';
import MContext from '../../models/MContext';
import Notification from '../../utils/Notification';

class DeleteOrganizationDialog extends React.Component {
  constructor(props) {
    super(props);
    this.organization = MContext.organization;
    this.toggle = this.toggle.bind(this);
    this.changeNameOrganization = this.changeNameOrganization.bind(this);
    this.saveDelete = this.saveDelete.bind(this);
    this.cancelDelete = this.cancelDelete.bind(this);
    this.errorHandler = this.errorHandler.bind(this);
    this.state = {
      modal: false,
      userEnteredNameOrg: '',
      disableSubmit: true,
    };
  }

  toggle() {
    const { modal } = this.state;
    this.setState({
      modal: !modal,
    });
  }

  changeNameOrganization(event) {
    const userEnteredNameOrg = event.target.value;
    let disableSubmit = true;
    if (userEnteredNameOrg === this.organization.name) {
      disableSubmit = false;
    }
    this.setState({
      userEnteredNameOrg,
      disableSubmit
    });
  }

  errorHandler(message) {
    this.props.errorHandler(message);
  }

  saveDelete(e) {
    const { userEnteredNameOrg } = this.state;
    e.preventDefault();
    if (userEnteredNameOrg === this.organization.name) {
      Services.deleteOrganization(this.organization.id, this.errorHandler).then(() => {
        const { afterRemoval } = this.props;
        Notification.pushSuccess('It may take some time for the organization to be permanently removed.');
        if (afterRemoval) {
          setTimeout(() => {
            afterRemoval();
          }, 2000);
        }
      });
    } else {
      window.alert('User has entered the wrong organization name.');
    }
    this.toggle();
  }

  cancelDelete() {
    this.toggle();
  }

  render() {
    const { disableSubmit } = this.state;
    return (
      <div>
        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <Form data-trackid="delete-organization" onSubmit={this.saveDelete}>
            <ModalHeader>Are you absolutely sure?</ModalHeader>
            <ModalBody>
              This action cannot be undone. <br />
              This will permanently delete the <strong>organization {this.organization.name}</strong>, projects, test plans, licenses, and remove all team associations.
              Please type <strong>{this.organization.name}</strong> to confirm.
              <br /> <br />
              <Input required type="text" onChange={this.changeNameOrganization} />
            </ModalBody>
            <ModalFooter>
              <Button type="submit" color="danger" disabled={disableSubmit}>I understand the consequences, delete this organization</Button>
              <Button color="secondary" onClick={this.cancelDelete}>Cancel</Button>
            </ModalFooter>
          </Form>
        </Modal>
      </div>
    );
  }
}


export default DeleteOrganizationDialog;
