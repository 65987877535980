import ColorUtils from './ColorUtils';

class Statuses {

    colors = {
      PASSED: '#11AB24',
      FAILED: '#DA3A10',
      ERROR: '#C7C100',
      INCOMPLETE: '#C7D4DE',
      PLANNED: '#C7D4DE',
      UNRESOLVED: '#C7C100',
      READY: '#11AB24',
      NOT_READY: '#DA3A10',
      EMPTY: '#C7D4DE',
      EXPIRED: '#F36C4F',
      SKIPPED: '#8DACE0'
    };

    constructor() {
      this.PASSED = {
        label: 'Passed',
        color: ColorUtils.transparentize(this.colors.PASSED),
      };
      this.FAILED = {
        label: 'Failed',
        color: ColorUtils.transparentize(this.colors.FAILED),
      };
      this.ERROR = {
        label: 'Error',
        color: ColorUtils.transparentize(this.colors.ERROR),
      };
      this.INCOMPLETE = {
        label: 'Incomplete',
        color: ColorUtils.transparentize(this.colors.INCOMPLETE),
      };
      this.RUNNING = {
        label: 'Running',
      };
      this.PLANNED = {
        label: 'Planned',
        color: ColorUtils.transparentize(this.colors.PLANNED),
      };
      this.UNRESOLVED = {
        label: 'Unresolved',
        color: ColorUtils.transparentize(this.colors.UNRESOLVED),
      };
      this.READY = {
        label: 'Ready',
        color: ColorUtils.transparentize(this.colors.READY),
      };
      this.NOT_READY = {
        label: 'Not Ready',
        color: ColorUtils.transparentize(this.colors.NOT_READY),
      };
      this.EMPTY = {
        label: 'Empty',
        color: ColorUtils.transparentize(this.colors.EMPTY),
      };
      this.RESOLVED = {
        label: 'Resolved',
      };
      this.EXPIRED = {
        label: 'Expired',
        color: ColorUtils.transparentize(this.colors.EXPIRED),
      };
      this.SKIPPED = {
        label: 'Skipped',
        color: ColorUtils.transparentize(this.colors.SKIPPED),
      };
    }
}

export default new Statuses();
