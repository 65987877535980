import React from 'react';
import { Button, ButtonGroup } from 'reactstrap';
import { t } from '../../../i18n/t';
import { ViewModeType } from './utils';

const ViewMode = ({ onChange, defaultViewMode = ViewModeType.RATIO }) => {
  const [viewMode, setViewMode] = React.useState(defaultViewMode);

  const setRatioViewMode = () => {
    setViewMode(ViewModeType.RATIO);
  };

  const setQuantityViewMode = () => {
    setViewMode(ViewModeType.TOTAL);
  };

  React.useEffect(() => {
    if (onChange) {
      onChange(viewMode);
    }
  }, [viewMode]);

  return (
    <ButtonGroup>
      <Button
        data-trackid="view-test-performance-net"
        disabled={viewMode === ViewModeType.TOTAL}
        color="link"
        onClick={setQuantityViewMode}
      >
        {t('net')}
      </Button>
      <Button
        data-trackid="view-test-performance-percent"
        disabled={viewMode === ViewModeType.RATIO}
        color="link"
        onClick={setRatioViewMode}
      >
        {t('percent')}
      </Button>
    </ButtonGroup>
  );
};

export default ViewMode;
