import React from 'react';
import queryString from 'query-string';
import { IconButton } from '@mui/material';
import PageComponent from '../../components/PageComponent';
import DefaultLayout from '../../components/DefaultLayout';
import TestCaseDataTableV2 from '../../components/table/TestCaseDataTableV2';
import MContext from '../../models/MContext';
import { t } from '../../i18n/t';
import { IconArrowLeft } from '../../images/CustomIcon';
import Routes from '../../utils/Routes';

class TestCaseSearchResults extends PageComponent {

  constructor(props) {
    super(props);
    this.project = MContext.project;
    this.meta.id = 'page-testcase-search-results';
    this.meta.title = t('Project {{name}} - Test Cases Search Results', { name: this.project.name });
    this.params = queryString.parse(this.props.location.search);
    this.renderBody = this.renderBody.bind(this);
    this.renderBackButton = this.renderBackButton.bind(this);
  }

  handleBack() {
    Routes.goToTestCasesLink();
  }

  renderBackButton() {
    return (
      <div className="search-result-back-button">
        <IconButton onClick={() => this.handleBack()} size="large">
          <IconArrowLeft />
        </IconButton>
      </div>
    );
  }

  renderBody() {
    const defaultSearchString = this.params.q;

    const defaultSort = ['updatedAt, desc NULLS LAST'];

    const conditionProps = {
      defaultSort,
      defaultSearchString
    };

    return (
      <TestCaseDataTableV2
        isSearchResultsPage
        {...conditionProps}
        renderBackButton={this.renderBackButton}
      />
    );
  }

  render() {
    return (
      <DefaultLayout
        renderBody={this.renderBody}
      />
    );
  }
}

export default TestCaseSearchResults;
