import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';

class StatusBarInfoV2 extends Component {
  constructor(props) {
    super(props);

    this.data = this.props.data;
  }

  render() {
    const { valueFormatter, isValueSmall } = this.props;
    const small = isValueSmall ? '-small' : '';
    return (
      <Row className="status-bar-v2">
        {this.data.map(({ key, value, label, diff }) => {
          const diffPart = diff !== undefined
            ? <> ({diff < 0 || '+'}{diff})</>
            : null;
          return (
            <Col className="status-bar-info__item" key={key}>
              <div className="status-bar-info__label">{label}{diffPart}</div>
              <div className={`status-bar-info__value${small}`}>{valueFormatter ? valueFormatter(value) : value}</div>
            </Col>
          );
        })}
      </Row>
    );
  }
}

export default StatusBarInfoV2;
