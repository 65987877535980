import React from 'react';
import { Input, Label } from 'reactstrap';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import TreeView from './TreeView';
import { t } from '../../i18n/t';
import Services from '../../utils/Services';
import {
  KATALON_TEST_CASE_ROOT_FOLDER,
  KATALON_TEST_SUITE_ROOT_FOLDER,
  SearchEntity,
  SubFoldersLevel,
  TestFolderType,
} from '../../utils/Constants';
import { buildSearchCondition } from '../search/SearchUtils';
import DataLoader from '../table/DataLoader';
import ConvertDataHelper from '../../utils/ConvertDataHelper';
import Helper from '../../utils/Helper';
import MFlags from '../../models/MFlags';

function SelectG5Location(props) {
  const {
    publishEntity,
    testProjectId,
    onSelectFolder,
    path,
    showAlert,
    showExcludedPathsAlert,
    enableHistory,
    componentName,
    applyGen5Style,
    excludedFolderPaths,
  } = props;
  const nodeIdPrefix = 'select_location';

  const onFolderSelected = (keys, selectedFolders) => {
    const folder = selectedFolders[0];
    const rawPath = folder?.rawPath;
    onSelectFolder(rawPath);
  };

  const loadData = (key) =>
    Services.getSubFolders(key, SubFoldersLevel.NUMBER_OF_LEVEL, MFlags.preventNamingTestOpsInTestSuiteFoldersEnabled ? excludedFolderPaths : undefined);

  const titleRender = (nodeData) => (
    <div id={`${nodeIdPrefix}_${nodeData.key}`}>{nodeData.title}</div>
  );

  const validateNewNodeTitle = (newFolderName, nodeData) => {
    const valid = Helper.isValidFolderName(newFolderName);
    showAlert(!valid);
    if (MFlags.preventNamingTestOpsInTestSuiteFoldersEnabled) {
      showExcludedPathsAlert(excludedFolderPaths.includes(`${nodeData?.rawPath}/${newFolderName}`));
    }
    return valid;
  };

  const getFolderInfo = () => {
    if (publishEntity === SearchEntity.TestCase) {
      return {
        key: 'g5-test-cases',
        title: KATALON_TEST_CASE_ROOT_FOLDER.G5_TEST_CASE_FOLDER_TITLE,
        rawPath: KATALON_TEST_CASE_ROOT_FOLDER.G5_TEST_CASE_FOLDER_TITLE,
      };
    } else if (publishEntity === SearchEntity.TestSuite) {
      return {
        key: 'g5-test-suites',
        title: KATALON_TEST_SUITE_ROOT_FOLDER.G5_FOLDER_TITLE,
        rawPath: KATALON_TEST_SUITE_ROOT_FOLDER.G5_FOLDER_TITLE,
      };
    }
    return '';
  };

  const renderLocationTree = (data) => {
    const folderInfo = getFolderInfo();
    const rootFolder = ConvertDataHelper.buildG5RootFolder(data, folderInfo);
    return (
      <TreeView
        className={classNames(
          'tree-view-components',
          'publish-dialog__scrollable-tree'
        )}
        data={[rootFolder]}
        onSelect={onFolderSelected}
        loadData={loadData}
        enableEdit
        titleRender={titleRender}
        validateNewNodeTitle={validateNewNodeTitle}
        expandedKeys={enableHistory ? [] : [rootFolder.key]}
        nodeIdPrefix={nodeIdPrefix}
        enableHistory={enableHistory}
        componentName={componentName}
        excludedFolderPaths={excludedFolderPaths}
      />
    );
  };

  const getSearchTypeCondition = () => {
    if (publishEntity === SearchEntity.TestCase) {
      return TestFolderType.TEST_CASE;
    } else if (publishEntity === SearchEntity.TestSuite) {
      return TestFolderType.TEST_SUITE;
    }
    return '';
  };

  const getSearchNameCondition = () => {
    if (publishEntity === SearchEntity.TestCase) {
      return KATALON_TEST_CASE_ROOT_FOLDER.G5_TEST_CASE_FOLDER_TITLE;
    } else if (publishEntity === SearchEntity.TestSuite) {
      return KATALON_TEST_SUITE_ROOT_FOLDER.G5_FOLDER_TITLE;
    }
    return '';
  };

  if (!testProjectId) {
    return null;
  } else {
    const searchTypeCondition = getSearchTypeCondition();
    const searchNameCondition = getSearchNameCondition();
    return (
      <div className={`publish-dialog__tree-view ${applyGen5Style ? 'publish-dialog__g5-tree-view' : ''}`}>
        <Label
          className="m-0"
          for={t('select-location')}
          style={applyGen5Style && {
            fontSize: '12px',
          }}
        >
          {t('select-location')}
          <span className="ml-1">*</span>
        </Label>
        <Input
          readOnly
          required
          className="publish-dialog__text__tree-path"
          type="text"
          value={path}
          placeholder={t('tree-path-placeholder')}
        />
        <DataLoader
          noCard
          entityType="TestFolder"
          pageSize={1}
          fetchAllPages
          render={renderLocationTree}
          defaultSort={['id,asc']}
          defaultSearchConditions={[
            buildSearchCondition('TestFolder.id', 'is null', ''),
            buildSearchCondition('type', '=', searchTypeCondition),
            buildSearchCondition('TestProject.id', '=', testProjectId),
            buildSearchCondition('name', '=', searchNameCondition),
          ]}
        />
      </div>
    );
  }
}

SelectG5Location.propTypes = {
  publishEntity: PropTypes.string,
  excludedFolderPaths: PropTypes.array,
  showExcludedPathsAlert: PropTypes.func,
};

SelectG5Location.defaultProps = {
  publishEntity: SearchEntity.TestCase,
  excludedFolderPaths: [],
  showExcludedPathsAlert: () => {},
};

export default SelectG5Location;
