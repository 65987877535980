import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@mui/material';
import DownloadKSDialog from '../dialog/DownloadKSDialog';
import { t } from '../../i18n/t';
import Helper from '../../utils/Helper';
import Apis from '../../utils/Apis';
import MContext from '../../models/MContext';
import UrlHelper from '../../utils/UrlHelper';
import DefaultLayout from '../DefaultLayout';
import { IconDownloadKSNav, IconDownloadKSNavActive } from '../../images/CustomNewIcon';

const DownloadKSMenu = (props) => {
  const [open, setOpen] = useState(props.open);

  const handleClose = () => {
    const { organizationId } = MContext;
    setOpen(false);
    UrlHelper.removeAllParams();
    if (organizationId && Helper.isEmptyOrganization(organizationId)) {
      window.location.href = Apis.katOneAdminCreateProject(organizationId);
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <>
      <IconButton
        title={t('download-ks-package')}
        size="large"
        onClick={handleOpen}
        data-trackid="open-download-popup"
      >
        <span className="icon"><IconDownloadKSNav /></span>
        <span className="icon-hover"><IconDownloadKSNavActive /></span>
      </IconButton>
      {open && <DownloadKSDialog isOpen={open} handleClose={() => handleClose()} />}
    </>
  );

};

DefaultLayout.propTypes = {
  open: PropTypes.bool
};

DefaultLayout.defaultProps = {
  open: false
};

export default DownloadKSMenu;
