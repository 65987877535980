import Icon from '@katalon-studio/katalon-ui/Icon';
import React from 'react';
import colors from '../../../scss/colors.scss';

function EllipsisVerticalIcon({ color, textSize }) {
  return <Icon
    type="fa-solid"
    name="fa-ellipsis-vertical"
    textSize={textSize}
    boundingSize={null}
    color={null}
    sx={{
      color,
    }}
  />;
}

EllipsisVerticalIcon.defaultProps = {
  color: colors.gray9,
  textSize: '14px',
};

export default EllipsisVerticalIcon;
