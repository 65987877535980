import React from 'react';
import { Container, Jumbotron } from 'reactstrap';
import PageComponent from '../components/PageComponent';

class SurveyCompleted extends PageComponent {
  constructor(props) {
    super(props);
    this.meta.id = 'page-survey-completed';
    this.meta.title = 'Survey completed';
  }

  render() {
    return (
      <div>
        <Jumbotron fluid>
          <Container fluid>
            <h1 className="display-3">Thank you for your feedback!</h1>
            <hr className="my-2" />
            <p className="lead">We hope to welcome you back soon.</p>
          </Container>
        </Jumbotron>
      </div>
    );
  }
}

export default SurveyCompleted;
