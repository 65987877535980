import React from 'react';
import {
  Button,
  Form,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import Notification from '../../utils/Notification';
import Services from '../../utils/Services';
import { t } from '../../i18n/t';

class TerminateExecutionDialog extends React.Component {
  constructor(props) {
    super(props);
    this.teamId = this.props.teamId;
    this.toggle = this.toggle.bind(this);
    this.saveDelete = this.saveDelete.bind(this);
    this.cancelDelete = this.cancelDelete.bind(this);
    this.state = {
      modal: false,
    };
  }

  toggle() {
    const { modal } = this.state;
    this.setState({
      modal: !modal,
    });
  }

  saveDelete(e) {
    const { executionId, projectId } = this.props;
    const notificationMessage = t('notify_successfully_terminated_test_run', { id: executionId });
    e.preventDefault();
    Services.terminateExecution(projectId, executionId).then(() => {
      const { afterCreate } = this.props;
      Notification.pushSuccess(notificationMessage);
      if (afterCreate) {
        afterCreate();
      }
      this.toggle();
    }).catch(() => {
      this.toggle();
    });
  }

  cancelDelete() {
    this.toggle();
  }

  render() {
    return (
      <div>
        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <Form data-trackid="terminate-execution" onSubmit={this.saveDelete}>
            <ModalHeader>{t('terminate_test_run')}</ModalHeader>
            <ModalBody>
              {
                <span>
                  This action cannot be undone. Are you sure you want to terminate the following executions:
                  <strong>{this.props.executionId}</strong>?
                </span>
              }
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={this.cancelDelete}>Cancel</Button>
              <Button type="submit" color="danger">{t('terminate')}</Button>
            </ModalFooter>
          </Form>
        </Modal>
      </div>
    );
  }
}


export default TerminateExecutionDialog;
