import Icon from '@katalon-studio/katalon-ui/Icon';
import React from 'react';
import colors from '../../../scss/colors.scss';

function MicrochipAIIcon(props) {
  const { color, textSize, type } = props;
  return <Icon
    type={`fa-${type}`}
    name="fa-microchip-ai"
    textSize={textSize}
    sx={{
      color,
    }}
    boundingSize={null}
    color={null}
  />;
}

MicrochipAIIcon.defaultProps = {
  color: colors.gray9,
  textSize: '14px',
  type: 'regular'
};

export default MicrochipAIIcon;
