import React, { useState } from 'react';
import { FormGroup, FormText, Progress } from 'reactstrap';
import PropTypes from 'prop-types';
import { CircularProgress } from '@mui/material';
import Uploader from '../../../components/Uploader';
import Notification from '../../../utils/Notification';
import { MAX_APP_SIZE } from '../../../utils/Constants';
import { t } from '../../../i18n/t';
import storageService from '../../../utils/mobiletesting/mobileTestingService';
import MContext from '../../../models/MContext';

const AppUploader = ({ onFinish }) => {

  const [uploadProgress, setUploadProgress] = useState(0);
  const [isLoading, setIsLoading] = useState(false);


  const createApp = (data, file) => {
    storageService.createApp({
      storagePath: data?.storagePath,
      fileName: file?.name,
      organizationId: MContext.team?.organizationId
    }).then(() => {
      setUploadProgress(0);
      Notification.pushSuccess(t('native-app-success-message-uploader#upload-success'));
      onFinish(true);
    }).catch((e) => {
      Notification.pushError(`Upload error: ${e.message}`);
    });
  };

  const onUploadFinish = (data, file) => {
    createApp(data, file);
  };

  const onUploadProgress = (percent) => {
    setIsLoading(false);
    setUploadProgress(percent);
  };

  const onUploadError = (message) => {
    setUploadProgress(0);
    Notification.pushError(`Upload error: ${message}`);
  };

  const getSignedUrl = (file, callback) => {
    setIsLoading(true);
    storageService.getSignedUrl().then((data) => {
      callback(data?.data);
    }).catch((e) => {
      Notification.pushError(`Error when get signed url: ${e.message}`);
      setIsLoading(false);
    });
  };

  const onPreprocess = (file, next) => {
    setIsLoading(true);
    if (file.size > MAX_APP_SIZE) {
      Notification.pushError(t('native-app-error-message-uploader#app-size-validation'));
      setIsLoading(false);
      return;
    }
    if (!file.name.includes('.ipa') && !file.name.includes('.apk') && !file.name.includes('.aab')) {
      Notification.pushError(t('native-app-error-message-uploader#app-type-validation'));
      setIsLoading(false);
      return;
    }
    next(file);
  };

  return (
    <FormGroup className="form-control-app-upload">
      <div>
        {isLoading && <CircularProgress size={15} />}
      </div>
      {uploadProgress !== 0 && <Progress value={uploadProgress} />}
      <Uploader
        id="upload-app"
        preprocess={onPreprocess}
        getSignedUrl={getSignedUrl}
        signingUrlMethod="GET"
        className="input-form"
        data-trackid="upload-app"
        onProgress={onUploadProgress}
        onFinish={(data, file) => onUploadFinish(data, file)}
        onError={onUploadError}
        contentDisposition="attachment"
        signingUrlWithCredentials
        uploadRequestHeaders={{ 'content-type': 'application/octet-stream' }}
        autoUpload
        multiple
      />
      <FormText color="muted"> {t('native-app#description-uploader-1')}<br /> {t('native-app#description-uploader-2')}
      </FormText>
    </FormGroup>
  );
};

AppUploader.propTypes = {
  onFinish: PropTypes.func,
};

export default AppUploader;
