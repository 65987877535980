import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Divider } from '@mui/material';
import TestExecutionSessionsUsage from './TestExecutionSessionsUsage';
import MFlags from '../../../models/MFlags';
import colors from '../../../../scss/colors.scss';
import MContext from '../../../models/MContext';
import testCloudService from '../../../utils/testcloud/testCloudService';


const POLLING_INTERVAL = 10000; // 10 seconds


const StyledContainer = styled.div``;

const StyledDivider = styled(Divider)`
  height: 40px;
  border-style: solid;
  border-color: ${colors['gray-9']};
`;

const createDefaultSummary = (feature) => ({
  feature,
  limit: 0,
  supportedSessionTypes: [],
  queuedSessionsForOrganization: 0,
  runningSessionsForOrganization: 0,
  queuedSessionsForProject: 0,
  runningSessionsForProject: 0,
});

const defaultTestSessionFeatureSummaries = [
  createDefaultSummary('TESTCLOUD_SESSION_WEB_DESKTOP'),
  createDefaultSummary('TESTCLOUD_SESSION_CROSS_BROWSER'),
  createDefaultSummary('TESTCLOUD_SESSION_MOBILE_APP'),
];

const ensureDefaultTestSessionFeatureSummaries = (data) => {
  if (!data.testSessionFeatureSummaries) return;
  const existingFeatures = new Set(data.testSessionFeatureSummaries.map((item) => item.feature));
  defaultTestSessionFeatureSummaries.forEach((defaultItem) => {
    if (!existingFeatures.has(defaultItem.feature)) {
      data.testSessionFeatureSummaries.push({ ...defaultItem });
    }
  });
};

function TestSessionSummaryContainer() {
  const [sessionSummary, setSessionSummary] = useState({
    totalPaidSessions: 0,
    testSessionFeatureSummaries: [...defaultTestSessionFeatureSummaries],
  });

  const [isPolling, setIsPolling] = useState(false);
  const fetchAndUpdateSessionSummary = () => {
    try {
      const organizationId = MContext.team.organizationId;
      const projectId = MContext.projectId;
      if (MFlags.testcloudExecutionEnvMicroAppEnabled) {
        testCloudService.getTestCloudSessionSummary(organizationId, projectId).then(
          (response) => {
            if (response.data) {
              ensureDefaultTestSessionFeatureSummaries(response.data);
              setSessionSummary(response.data);
            }
          },
        );
      }
    } catch (e) { /* empty */ } finally {
      setIsPolling(false);
    }
  };

  useEffect(() => {
    // Initial call
    fetchAndUpdateSessionSummary();

    // Polling
    const intervalId = setInterval(() => {
      if (!isPolling) {
        fetchAndUpdateSessionSummary();
      }
    }, POLLING_INTERVAL);
    return () => {
      clearInterval(intervalId);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {MFlags.testcloudExecutionEnvMicroAppEnabled && sessionSummary.totalPaidSessions > 0 &&
      <TestExecutionSessionsUsage
        sessionSummary={sessionSummary}
      />}
      {MFlags.testcloudExecutionEnvMicroAppEnabled && sessionSummary.totalPaidSessions > 0 && <StyledContainer><StyledDivider orientation="vertical" flexItem /></StyledContainer>}
    </>
  );
}

export default TestSessionSummaryContainer;
