import _ from 'lodash';
import Time from '../../../utils/Moment';

const emptyProjectStatistics = (keyDate) => ({
  keyDate,
  totalDuration: 0,
  totalExecution: 0,
  passedExecution: 0,
  failedExecution: 0,
  totalWebService: 0,
  totalTestObject: 0,
  totalJob: 0,
  passedJob: 0,
  failedJob: 0,
  errorJob: 0,
  totalAgentDuration: 0,
});

export default class StatisticsUtils {

  static weeklyKey(week) {
    return this.startOfWeek(week);
  }

  static startOfWeek(week) {
    const currentWeek = Time.now().subtract(week - 1, 'week');
    return Time.startOf(currentWeek, 'week').format(Time.LOCAL_DATE_FORMAT);
  }

  static endOfWeek(week) {
    const currentWeek = Time.now().subtract(week - 1, 'week');
    return Time.endOf(currentWeek, 'week').format(Time.LOCAL_DATE_FORMAT);
  }

  static fillEmptyDaysInWeek(data, startOfWeek, endOfWeek) {
    const daysOfWeek = Time.timeBetween(startOfWeek, endOfWeek, 'day', Time.LOCAL_DATE_FORMAT);

    const convertedData = daysOfWeek.map((date) => {
      const statistics = _.find(data, { keyDate: date });
      return statistics || emptyProjectStatistics(date);
    });
    return convertedData;
  }
}

