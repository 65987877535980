import _ from 'lodash';
import React from 'react';
import { Button } from 'reactstrap';
import { t } from '../../i18n/t';
import { IconPlay } from '../../images/KitIcons';
import MContext from '../../models/MContext';
import WebSocket from '../../services/WebSocket';
import DecoratorConstants from '../../utils/DecoratorConstants';
import Notification from '../../utils/Notification';
import Routes from '../../utils/Routes';
import Services from '../../utils/Services';
import MTableColumnDataMapping from './models/MTableColumnDataMapping';
import DataTable from './DataTable';
import { buildDefaultFilter, buildFilter } from '../search-query/FilterQueryHelper';
import TestProjectFilter from '../search-query/filter/TestProjectFilter';
import { buildSortQuery } from '../search-query/SortQueryHelper';
import { CloudType, EXECUTION_DIALOG_NOTIFICATION_TIMEOUT } from '../../utils/Constants';
import GroupEvent from '../../utils/track/GroupEvent';
import MAuth from '../../models/MAuth';
import Link from '../Link';
import { next } from '../../utils/Count';
import MFlags from '../../models/MFlags';

class TestPlanDataTable extends React.Component {

  constructor(props) {
    super(props);
    this.projectId = MContext.projectId;
    this.handlerId = next();
    this.refreshTable = this.refreshTable.bind(this);

    this.updateInterval = 5000;
    this.notifyHandler = _.throttle(() => {
      this.refreshTable();
    }, this.updateInterval);
  }

  executePlan(configuration) {
    Services.executeRunConfiguration(configuration.id)
      .then(() => {
        if (configuration.cloudType === CloudType.TEST_CLOUD_AGENT) {
          Services.trackTestCloudTestRunScheduleEvent(MAuth.email, Date.now());
        }
        if (MFlags.testcloudExecutionEnvMicroAppEnabled) {
          const formattedMessage = DecoratorConstants.buildExecutionNotificationHistoryLink(configuration.name);
          if (MFlags.htmlInjection) {
            Notification.pushSuccess(null, 'Success', EXECUTION_DIALOG_NOTIFICATION_TIMEOUT, formattedMessage);
          } else {
            Notification.pushSuccess(formattedMessage, 'Success', EXECUTION_DIALOG_NOTIFICATION_TIMEOUT);
          }
        } else {
          Notification.pushSuccess(`Run ${configuration.name}.`);
        }
        // this.refreshTable();
      });
  }

  componentDidMount() {
    this.subscribeTopic();
  }

  subscribeTopic() {
    if (this.projectId) {
      const topic = 'Job';
      WebSocket.subscribe({
        projectId: this.projectId,
        topics: [topic],
      }, this.notifyHandler, this.handlerId);
    }
  }

  refreshTable() {
    this.planList.refreshData();
  }

  componentWillUnmount() {
    WebSocket.unsubscribe(this.handlerId);
  }

  render() {
    const { defaultSearchConditions } = this.props;
    const headers = [
      new MTableColumnDataMapping(
        'Name',
        'name',
        (name, row) => {
          const routes = new Routes({ runConfigurationId: row.id });
          const runConfigurationLink = routes.run_configuration_link;
          if (row.cloudType === CloudType.TEST_CLOUD_AGENT) {
            return DecoratorConstants.testCloudIdDecorator(name, row, runConfigurationLink);
          }
          return DecoratorConstants.idDecorator(name, row, runConfigurationLink);
        },
      ),
      new MTableColumnDataMapping(
        'Release',
        'release',
        (name, row) => {
          let buildLabel = _.get(row, 'buildLabel');
          const buildUrl = _.get(row, 'buildUrl');
          if (buildUrl && !buildLabel) {
            buildLabel = 'Build';
          }
          const release = _.get(row, name);
          let releaseLink;
          if (release) {
            const route = new Routes({
              releaseId: release.id
            });
            releaseLink = <div className="text-word-wrap"><Link href={route.view_release_link}>{release.name}</Link></div>;
          }
          return (
            <>
              <div className="text-word-wrap"><Link target="_blank" rel="noopener noreferrer" href={buildUrl}>{buildLabel}</Link></div>
              {releaseLink}
            </>
          );
        },
      ),
      new MTableColumnDataMapping(
        t('test-project'),
        'testProject.name',
      ),
      new MTableColumnDataMapping(
        'Test Environment',
        'agents',
        (name, row) => DecoratorConstants.agentsDecorator(name, row),
      ),
      new MTableColumnDataMapping(
        t('plan#last_run'),
        'latestJob.execution',
        (name, row) => {
          const execution = _.get(row, name);
          if (!_.isEmpty(execution)) {
            const project = execution.project;
            const constructedLink = new Routes({
              executionId: execution.order,
              projectId: execution.projectId,
              teamId: project.teamId,
            });
            const lastStartElement = DecoratorConstants.timeDecorator(`${name}.startTime`, row);
            return DecoratorConstants.idDecoratorWrapper(
              lastStartElement,
              constructedLink.execution_details_link,
            );
          }
          return null;
        },
      ),
      new MTableColumnDataMapping(
        t('plan#next_run'),
        'nextRunScheduler.nextTime',
        DecoratorConstants.timeDecorator,
      ),
      new MTableColumnDataMapping(
        '',
        '',
        (name, row) => (
          <Button
            className="btn-icon-only"
            data-trackid="execute-plan"
            data-groupid={GroupEvent.SCHEDULE_TEST_RUN}
            title={t('run')}
            onClick={() => this.executePlan(row)}
            color="link"
          >
            <IconPlay />
          </Button>
        ),
      ),
    ];
    const defaultFilter = buildDefaultFilter('name', '~', 'Name');
    const filterQuery = [
      buildFilter(TestProjectFilter, { id: 'TestProject.name' }),
    ];

    const sortQuery = [
      ...buildSortQuery('name', t('name')),
      ...buildSortQuery('updatedAt', t('date_modified'))
    ];

    const newProps = {
      ...this.props,
      filterQuery,
      sortQuery,
      defaultFilter,
    };
    return (
      <DataTable
        ref={(ref) => {
          this.planList = ref;
        }}
        columnMapping={headers}
        entityType="RunConfiguration"
        defaultSearchConditions={defaultSearchConditions}
        {...newProps}
      />
    );
  }
}

export default TestPlanDataTable;
