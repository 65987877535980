import React, { useState, useEffect } from 'react';
import { Paper, Collapse } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { cloneDeep, values, filter, sumBy } from 'lodash';
import OnboardingChecklistHeader from './OnboardingChecklistHeader';
import OnboardingChecklistBody from './OnboardingChecklistBody';
import { getLocalStorageItem, setLocalStorageItem } from '../../../utils/LocalStorageHelper';
import { IS_EXPAND_ONBOARDING_CHECKLIST, IS_DISMISS_ONBOARDING_CHECKLIST } from '../../../utils/LocalStorageKey';
import MAuth from '../../../models/MAuth';
import Services from '../../../utils/Services';
import { OnboardingType, DISPLAY_ONBOARDING_CHECKLIST_LIMIT_TIME } from '../../../utils/Constants';
import MContext from '../../../models/MContext';
import MFlags from '../../../models/MFlags';
import WebSocket from '../../../services/WebSocket';
import { next } from '../../../utils/Count';
import { t } from '../../../i18n/t';
import {
  OnboardingKsStep1,
  OnboardingKsStep2,
  OnboardingKsStep3,
  OnboardingKsStep4,
  OnboardingKsStep5,
  OnboardingKsStep6,
  OnboardingTestOpsStep1,
  OnboardingTestOpsStep2,
  OnboardingTestOpsStep3,
  OnboardingTestOpsStep4,
  OnboardingTestOpsStep5,
  OnboardingTestOpsStep6,
  OnboardingTestOpsStep7,
  OnboardingTestOpsStep8,
  OnboardingTestOpsStep9,
  OnboardingTestOpsStep10,
  OnboardingTestOpsStep11
} from '../../../images/Media';
import DocumentLink from '../../../utils/DocumentLink';
import UserFlowButton from '../../../components/UserFlowButton';
import ExternalLinkButton from '../../../components/ExternalLinkButton';
import Helper from '../../../utils/Helper';
import { KS_DOWNLOAD_TYPE } from '../../../components/dialog/DownloadKSDialog';

const useStyles = makeStyles(() => ({
  checklist: {
    marginBottom: '2rem',
    paddingTop: '0.1rem',
    paddingRight: '1.5rem',
    paddingLeft: '1.5rem',
  }
}));

const OnboardingUserFlowId = {
  PLATFORM_STEP_1: '7a652fa8-f831-4a83-ae81-f1f8e461a36d',
  PLATFORM_STEP_3: '866819e6-916f-4360-a6ef-addf61ac9358',
  PLATFORM_STEP_4: '9b991b8a-00ac-490c-ada2-8cf2b2c16213',
  PLATFORM_STEP_5: '740a4735-1b7a-46dc-9f70-ecd98583a669',
  PLATFORM_STEP_6: '0c1cfb12-8ad5-4b86-a5da-10f9c0c81570',
  PLATFORM_STEP_7: '6488249d-6531-4b96-9165-44641abb8c8e',
  PLATFORM_STEP_8: '2a976604-290e-42f9-8856-6786ea208f1d',
  PLATFORM_STEP_9: '02de61e7-3374-4675-8ffd-f232717b88e9',
  PLATFORM_STEP_10: '1a991b5a-a239-4911-aaf2-768c879e3f95',
  PLATFORM_STEP_11: '8358dd9a-6269-4e2d-994e-bf028fb08ace',
};

const OnboardingChecklist = (props) => {
  const classes = useStyles();
  const userId = MAuth.user.id;
  const getDefaultExpandState = () => getLocalStorageItem(`${IS_EXPAND_ONBOARDING_CHECKLIST}-${userId}`) !== 'false';
  const getDismissExpandState = () => getLocalStorageItem(`${IS_DISMISS_ONBOARDING_CHECKLIST}-${userId}`) === 'true';

  const defaultExpandState = getDefaultExpandState();
  const defaultDismissState = getDismissExpandState();
  const [expand, setExpand] = useState(defaultExpandState);
  const [platformOnboardingTrackedSteps, setPlatformOnboardingTrackedSteps] = useState([]);
  const [ksOnboardingTrackedSteps, setKsOnboardingTrackedSteps] = useState([]);
  const [isReady, setIsReady] = useState(false);
  const projectId = MContext.projectId;
  const isTeamManager = MAuth.isTeamManager(props.teamId);
  const [dismiss, setDismiss] = useState(defaultDismissState);

  const downloadKatalonStudio = () => {
    Helper.downloadKS(null, KS_DOWNLOAD_TYPE.KSE_STANDALONE);
  };

  const sendTrackEvent = () => {
    const onboardingStep = {
      projectId,
      stepId: 'DOWNLOAD_KS',
    };
    Services.trackOnboardingStep(onboardingStep).catch(() => { /* ignore */ });
    downloadKatalonStudio();
  };

  const DefaultPlatformOnboardingSteps = MFlags.onboardingChecklistEnhancementEnabled ?
    {
      DOWNLOAD_KS: {
        id: 'DOWNLOAD_KS',
        title: t('testops-onboarding-step1-title'),
        desc: t('testops-onboarding-step1-desc'),
        eta: 3,
        action: <UserFlowButton userFlowId={OnboardingUserFlowId.PLATFORM_STEP_1} triggerEvent={sendTrackEvent}> {t('download')} </UserFlowButton>,
        media: <OnboardingTestOpsStep1 />,
        isCompleted: false,
        isTeamManager: false,
      },
      SYNC_KS_PROJECT: {
        id: 'SYNC_KS_PROJECT',
        title: t('testops-onboarding-step2-title'),
        desc: t('testops-onboarding-step2-desc'),
        eta: 4,
        action: <ExternalLinkButton externalLink={DocumentLink.SYNC_KS_PROJECT_WITH_TO} />,
        media: <OnboardingTestOpsStep2 />,
        isCompleted: false,
        isTeamManager: false,
        prerequisites: ['DOWNLOAD_KS'],
      },
      SETUP_GIT_REPO: {
        id: 'SETUP_GIT_REPO',
        title: t('testops-onboarding-step4-title'),
        desc: t('testops-onboarding-step4-desc'),
        eta: 3,
        action: <UserFlowButton userFlowId={OnboardingUserFlowId.PLATFORM_STEP_4}> {t('connect-git')} </UserFlowButton>,
        media: <OnboardingTestOpsStep4 />,
        isCompleted: false,
        isTeamManager: false,
      },
      CREATE_TEST_SUITE: {
        id: 'CREATE_TEST_SUITE',
        title: t('testops-onboarding-step5-title'),
        desc: t('testops-onboarding-step5-desc'),
        eta: 5,
        action: <UserFlowButton userFlowId={OnboardingUserFlowId.PLATFORM_STEP_5}> {t('create-test-suite')} </UserFlowButton>,
        media: <OnboardingTestOpsStep5 />,
        isCompleted: false,
        isTeamManager: false,
        prerequisites: ['SETUP_GIT_REPO'],
      },
      SCHEDULE_TEST_RUN: {
        id: 'SCHEDULE_TEST_RUN',
        title: t('testops-onboarding-step6-title'),
        desc: t('testops-onboarding-step6-desc'),
        eta: 3,
        action: <UserFlowButton userFlowId={OnboardingUserFlowId.PLATFORM_STEP_6}> {t('execute-test')} </UserFlowButton>,
        media: <OnboardingTestOpsStep6 />,
        isCompleted: false,
        isTeamManager: false,
        prerequisites: ['CREATE_TEST_SUITE'],
      },
      IMPORT_TEST_DATA: {
        id: 'IMPORT_TEST_DATA',
        title: t('testops-onboarding-step9-title'),
        desc: t('testops-onboarding-step9-desc'),
        eta: 5,
        action: <UserFlowButton userFlowId={OnboardingUserFlowId.PLATFORM_STEP_9}> {t('upload-results')} </UserFlowButton>,
        media: <OnboardingTestOpsStep9 />,
        isCompleted: false,
        isTeamManager: false,
      },
      ANALYZE_TEST_RESULT: {
        id: 'ANALYZE_TEST_RESULT',
        title: t('testops-onboarding-step3-title'),
        desc: t('testops-onboarding-step3-desc'),
        eta: 5,
        action: <UserFlowButton userFlowId={OnboardingUserFlowId.PLATFORM_STEP_3}> {t('analyze-result')} </UserFlowButton>,
        media: <OnboardingTestOpsStep3 />,
        isCompleted: false,
        isTeamManager: false,
        prerequisites: ['SCHEDULE_TEST_RUN'],
      },
      INTEGRATE_WITH_JIRA: {
        id: 'INTEGRATE_WITH_JIRA',
        title: t('testops-onboarding-step8-title'),
        desc: t('testops-onboarding-step8-desc'),
        eta: 3,
        action: <UserFlowButton userFlowId={OnboardingUserFlowId.PLATFORM_STEP_8}> {t('integrate-jira')} </UserFlowButton>,
        media: <OnboardingTestOpsStep8 />,
        isCompleted: false,
        isTeamManager: true,
      },
      INVITE_TO_ORGANIZATION: {
        id: 'INVITE_TO_ORGANIZATION',
        title: t('testops-onboarding-step7-title'),
        desc: t('testops-onboarding-step7-desc'),
        eta: 2,
        action: <UserFlowButton userFlowId={OnboardingUserFlowId.PLATFORM_STEP_7}> {t('invite-members')} </UserFlowButton>,
        media: <OnboardingTestOpsStep7 />,
        isCompleted: false,
        isTeamManager: true,
      },
      SETUP_LOCAL_AGENT: {
        id: 'SETUP_LOCAL_AGENT',
        title: t('testops-onboarding-step10-title'),
        desc: t('testops-onboarding-step10-desc'),
        eta: 4,
        action: <UserFlowButton userFlowId={OnboardingUserFlowId.PLATFORM_STEP_10}> {t('setup-agent')} </UserFlowButton>,
        media: <OnboardingTestOpsStep10 />,
        isCompleted: false,
        isTeamManager: false,
      },
      CREATE_PROJECT: {
        id: 'CREATE_PROJECT',
        title: t('testops-onboarding-step11-title'),
        desc: t('testops-onboarding-step11-desc'),
        eta: 5,
        action: <UserFlowButton userFlowId={OnboardingUserFlowId.PLATFORM_STEP_11}> {t('create_project')} </UserFlowButton>,
        media: <OnboardingTestOpsStep11 />,
        isCompleted: false,
        isTeamManager: true,
      },
    }
    :
    {
      DOWNLOAD_KS: {
        id: 'DOWNLOAD_KS',
        title: t('testops-onboarding-step1-title'),
        desc: t('testops-onboarding-step1-desc'),
        eta: 3,
        action: <UserFlowButton userFlowId={OnboardingUserFlowId.PLATFORM_STEP_1} triggerEvent={sendTrackEvent}> {t('download')} </UserFlowButton>,
        media: <OnboardingTestOpsStep1 />,
        isCompleted: false,
        isTeamManager: false,
      },
      SYNC_KS_PROJECT: {
        id: 'SYNC_KS_PROJECT',
        title: t('testops-onboarding-step2-title'),
        desc: t('testops-onboarding-step2-desc'),
        eta: 4,
        action: <ExternalLinkButton externalLink={DocumentLink.SYNC_KS_PROJECT_WITH_TO} />,
        media: <OnboardingTestOpsStep2 />,
        isCompleted: false,
        isTeamManager: false,
        prerequisites: ['DOWNLOAD_KS'],
      },
      ANALYZE_TEST_RESULT: {
        id: 'ANALYZE_TEST_RESULT',
        title: t('testops-onboarding-step3-title'),
        desc: t('testops-onboarding-step3-desc'),
        eta: 5,
        action: <UserFlowButton userFlowId={OnboardingUserFlowId.PLATFORM_STEP_3}> {t('analyze-result')} </UserFlowButton>,
        media: <OnboardingTestOpsStep3 />,
        isCompleted: false,
        isTeamManager: false,
        prerequisites: ['SCHEDULE_TEST_RUN'],
      },
      SETUP_GIT_REPO: {
        id: 'SETUP_GIT_REPO',
        title: t('testops-onboarding-step4-title'),
        desc: t('testops-onboarding-step4-desc'),
        eta: 3,
        action: <UserFlowButton userFlowId={OnboardingUserFlowId.PLATFORM_STEP_4}> {t('connect-git')} </UserFlowButton>,
        media: <OnboardingTestOpsStep4 />,
        isCompleted: false,
        isTeamManager: false,
      },
      CREATE_TEST_SUITE: {
        id: 'CREATE_TEST_SUITE',
        title: t('testops-onboarding-step5-title'),
        desc: t('testops-onboarding-step5-desc'),
        eta: 5,
        action: <UserFlowButton userFlowId={OnboardingUserFlowId.PLATFORM_STEP_5}> {t('create-test-suite')} </UserFlowButton>,
        media: <OnboardingTestOpsStep5 />,
        isCompleted: false,
        isTeamManager: false,
        prerequisites: ['SETUP_GIT_REPO'],
      },
      SCHEDULE_TEST_RUN: {
        id: 'SCHEDULE_TEST_RUN',
        title: t('testops-onboarding-step6-title'),
        desc: t('testops-onboarding-step6-desc'),
        eta: 3,
        action: <UserFlowButton userFlowId={OnboardingUserFlowId.PLATFORM_STEP_6}> {t('execute-test')} </UserFlowButton>,
        media: <OnboardingTestOpsStep6 />,
        isCompleted: false,
        isTeamManager: false,
        prerequisites: ['CREATE_TEST_SUITE'],
      },
      INVITE_TO_ORGANIZATION: {
        id: 'INVITE_TO_ORGANIZATION',
        title: t('testops-onboarding-step7-title'),
        desc: t('testops-onboarding-step7-desc'),
        eta: 2,
        action: <UserFlowButton userFlowId={OnboardingUserFlowId.PLATFORM_STEP_7}> {t('invite-members')} </UserFlowButton>,
        media: <OnboardingTestOpsStep7 />,
        isCompleted: false,
        isTeamManager: true,
      },
      INTEGRATE_WITH_JIRA: {
        id: 'INTEGRATE_WITH_JIRA',
        title: t('testops-onboarding-step8-title'),
        desc: t('testops-onboarding-step8-desc'),
        eta: 3,
        action: <UserFlowButton userFlowId={OnboardingUserFlowId.PLATFORM_STEP_8}> {t('integrate-jira')} </UserFlowButton>,
        media: <OnboardingTestOpsStep8 />,
        isCompleted: false,
        isTeamManager: true,
      },
      IMPORT_TEST_DATA: {
        id: 'IMPORT_TEST_DATA',
        title: t('testops-onboarding-step9-title'),
        desc: t('testops-onboarding-step9-desc'),
        eta: 5,
        action: <UserFlowButton userFlowId={OnboardingUserFlowId.PLATFORM_STEP_9}> {t('upload-results')} </UserFlowButton>,
        media: <OnboardingTestOpsStep9 />,
        isCompleted: false,
        isTeamManager: false,
      },
      SETUP_LOCAL_AGENT: {
        id: 'SETUP_LOCAL_AGENT',
        title: t('testops-onboarding-step10-title'),
        desc: t('testops-onboarding-step10-desc'),
        eta: 4,
        action: <UserFlowButton userFlowId={OnboardingUserFlowId.PLATFORM_STEP_10}> {t('setup-agent')} </UserFlowButton>,
        media: <OnboardingTestOpsStep10 />,
        isCompleted: false,
        isTeamManager: false,
      },
      CREATE_PROJECT: {
        id: 'CREATE_PROJECT',
        title: t('testops-onboarding-step11-title'),
        desc: t('testops-onboarding-step11-desc'),
        eta: 5,
        action: <UserFlowButton userFlowId={OnboardingUserFlowId.PLATFORM_STEP_11}> {t('create_project')} </UserFlowButton>,
        media: <OnboardingTestOpsStep11 />,
        isCompleted: false,
        isTeamManager: true,
      },
    };

  const DefaultKsOnboardingSteps = {
    CREATE_PROJECT: {
      id: 'CREATE_PROJECT',
      title: t('ks-onboarding-step1-title'),
      desc: t('ks-onboarding-step1-desc'),
      eta: 1,
      action: <ExternalLinkButton externalLink={DocumentLink.CREATE_YOUR_FIRST_PROJECT} />,
      media: <OnboardingKsStep1 />,
      isCompleted: false,
    },
    CREATE_TEST_CASE: {
      id: 'CREATE_TEST_CASE',
      title: t('ks-onboarding-step2-title'),
      desc: t('ks-onboarding-step2-desc'),
      eta: 5,
      action: <ExternalLinkButton externalLink={DocumentLink.WRITE_YOUR_FIRST_TEST_CASE} />,
      media: <OnboardingKsStep2 />,
      isCompleted: false,
    },
    TEST_PLANNING: {
      id: 'TEST_PLANNING',
      title: t('ks-onboarding-step3-title'),
      desc: t('ks-onboarding-step3-desc'),
      eta: 3,
      action: <ExternalLinkButton externalLink={DocumentLink.PLAN_YOUR_TESTS_WITH_A_TEST_SUITE} />,
      media: <OnboardingKsStep3 />,
      isCompleted: false,
    },
    EXPLORE_ANALYTICS: {
      id: 'EXPLORE_ANALYTICS',
      title: t('ks-onboarding-step4-title'),
      desc: t('ks-onboarding-step4-desc'),
      eta: 3,
      action: <ExternalLinkButton externalLink={DocumentLink.ANALYZE_YOUR_TEST_ACTIVITIES_WITH_CLOUD_PLATFORM} />,
      media: <OnboardingKsStep4 />,
      isCompleted: false,
    },
    TRY_TESTCLOUD: {
      id: 'TRY_TESTCLOUD',
      title: t('ks-onboarding-step5-title'),
      desc: t('ks-onboarding-step5-desc'),
      eta: 1,
      action: <ExternalLinkButton externalLink={DocumentLink.TRY_CROSS_BROWSER_CLOUD_TESTING} />,
      media: <OnboardingKsStep5 />,
      isCompleted: false,
    },
    SET_UP_KRE: {
      id: 'SET_UP_KRE',
      title: t('ks-onboarding-step6-title'),
      desc: t('ks-onboarding-step6-desc'),
      eta: 5,
      action: <ExternalLinkButton externalLink={DocumentLink.EXECUTE_KATALON_RUNTIME_ENGINE_VIA_CI_CD_PIPELINE} />,
      media: <OnboardingKsStep6 />,
      isCompleted: false,
    },
  };

  const getOnboardingSteps = (sourceId, setSteps, setIsReady) => {
    Services.getOnboardingSteps(projectId, sourceId)
      .then((content) => {
        setSteps(content);
        if (setIsReady) {
          setIsReady(true);
        }
      });
  };

  const fetchTrackedSteps = () => {
    if (MFlags.onboardingChecklistEnhancementEnabled) {
      getOnboardingSteps(OnboardingType.KATALON_PLATFORM.sourceId, setPlatformOnboardingTrackedSteps, setIsReady);
      getOnboardingSteps(OnboardingType.KATALON_STUDIO.sourceId, setKsOnboardingTrackedSteps);
    } else {
      setIsReady(true);
    }
  };

  const notifyHandler = () => {
    fetchTrackedSteps();
  };

  const subscribeTopic = (handlerId) => {
    if (userId) {
      const topic = `OnboardingStep-${userId}`;
      WebSocket.subscribe({
        projectId,
        topics: [topic],
      }, notifyHandler, handlerId);
    }
  };

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    fetchTrackedSteps();

    if (MFlags.onboardingChecklistEnhancementEnabled) {
      const handlerId = next();
      subscribeTopic(handlerId);
      return () => {
        WebSocket.unsubscribe(handlerId);
      };
    }
  }, []);

  const handleCollapse = () => {
    const newExpandState = !expand;
    setLocalStorageItem(`${IS_EXPAND_ONBOARDING_CHECKLIST}-${userId}`, newExpandState);
    setExpand(newExpandState);
  };

  const filterByRole = (steps) => {
    if (!isTeamManager) {
      steps = filter(steps, (step) => (!step.isTeamManager));
    }
    return steps;
  };

  const filterPlatformSteps = (steps) => {
    steps = filterByRole(steps);

    if (MFlags.onboardingChecklistEnhancementEnabled) {
      steps = filter(steps, (step) => (step.id !== DefaultPlatformOnboardingSteps.SYNC_KS_PROJECT.id));
    }

    if (MFlags.hideKatOneOnboardingSteps) {
      steps = filter(steps, (step) => (step.id !== DefaultPlatformOnboardingSteps.INVITE_TO_ORGANIZATION.id && step.id !== DefaultPlatformOnboardingSteps.CREATE_PROJECT.id));
    }

    if (MFlags.removeAgentOnboardingStepEnabled) {
      steps = filter(steps, (step) => (step.id !== DefaultPlatformOnboardingSteps.SETUP_LOCAL_AGENT.id));
    }

    return steps;
  };

  const convertSteps = (steps, trackingSteps, filterSteps) => {
    if (MFlags.hideKatalonStudioOnboardingSteps && steps === DefaultKsOnboardingSteps) {
      return {
        currentSteps: 0,
        totalEta: 0,
        totalSteps: 0,
        totalCompletedEta: 0,
        totalCompletedSteps: 0,
      };
    }
    const cloneSteps = cloneDeep(steps);

    trackingSteps.forEach((row) => {
      if (row.stepStatus === 'COMPLETED' && cloneSteps[row.stepId]) {
        cloneSteps[row.stepId].isCompleted = true;
        if (row.stepId === 'DOWNLOAD_KS') {
          cloneSteps[row.stepId].action = <UserFlowButton userFlowId={OnboardingUserFlowId.PLATFORM_STEP_1}> {t('download')} </UserFlowButton>;
        }
      }
    });

    let valuesSteps = values(cloneSteps);

    if (filterSteps) {
      valuesSteps = filterSteps(valuesSteps);
    }

    const completedSteps = filter(valuesSteps, (step) => step.isCompleted === true);

    return {
      currentSteps: valuesSteps,
      totalEta: sumBy(valuesSteps, 'eta'),
      totalSteps: valuesSteps.length,
      totalCompletedEta: sumBy(completedSteps, 'eta'),
      totalCompletedSteps: completedSteps.length,
    };
  };

  const ksOnboardingSteps = convertSteps(DefaultKsOnboardingSteps, ksOnboardingTrackedSteps);
  const platformOnboardingSteps = convertSteps(DefaultPlatformOnboardingSteps, platformOnboardingTrackedSteps, filterPlatformSteps);

  const handleDismiss = () => {
    const newDismiss = !dismiss;
    setLocalStorageItem(`${IS_DISMISS_ONBOARDING_CHECKLIST}-${userId}`, true);
    setDismiss(newDismiss);
  };

  const isOldUserAccount = () => {
    const createdAt = MAuth.user.createdAt;
    const createdAtDate = new Date(createdAt);
    const createdAtTime = createdAtDate.getTime();
    const now = new Date().getTime();
    return (now - createdAtTime) > DISPLAY_ONBOARDING_CHECKLIST_LIMIT_TIME;
  };

  const isCompletedOnboardingChecklist = () => ksOnboardingSteps.totalCompletedSteps === ksOnboardingSteps.totalSteps
      && platformOnboardingSteps.totalCompletedSteps === platformOnboardingSteps.totalSteps;

  const isDisplayOnboardingChecklist = () =>
    isReady && !isOldUserAccount() &&
    !(MFlags.onboardingChecklistEnhancementEnabled && (dismiss || isCompletedOnboardingChecklist()));

  return (
    <>
      {isDisplayOnboardingChecklist() &&
      <Paper className={classes.checklist} elevation={0}>
        <OnboardingChecklistHeader
          handleCollapse={handleCollapse}
          expand={expand}
          handleDismiss={handleDismiss}
          ksOnboardingSteps={ksOnboardingSteps}
          platformOnboardingSteps={platformOnboardingSteps}
        />
        <Collapse in={expand}>
          <OnboardingChecklistBody
            ksOnboardingSteps={ksOnboardingSteps}
            platformOnboardingSteps={platformOnboardingSteps}
            teamId={props.teamId}
          />
        </Collapse>
      </Paper>}
    </>
  );
};

export default OnboardingChecklist;
