import React from 'react';
import { Button } from 'reactstrap';
import { Route, Switch } from 'react-router-dom';
import PageComponent from '../../components/PageComponent';
import { buildSearchCondition } from '../../components/search/SearchUtils';
import ObjectSummary from '../../components/summary/ObjectSummary';
import { t } from '../../i18n/t';
import MContext from '../../models/MContext';
import Routes from '../../utils/Routes';
import Services from '../../utils/Services';
import PageButtonToolbar from '../../components/PageButtonToolbar';
import DefaultLayout from '../../components/DefaultLayout';
import Time from '../../utils/Moment';
import { ReleaseDetailHeaderNav } from '../../components/HeaderNav';
import ObjectInfo from '../../components/summary/ObjectInfo';
import DecoratorConstants from '../../utils/DecoratorConstants';
import { IconStartTime, IconReleaseDate } from '../../images/CustomIcon';
import RouteConstants from '../../utils/RouteConstants';
import TestCasesTab from './TestCasesTab';
import ExecutionsTab from './ExecutionsTab';
import PlatformStatisticsDataTable from '../../components/table/PlatformStatisticsDataTable';
import BuildDataTable from '../../components/table/BuildDataTable';
import RunConfigurationsTab from './RunConfigurationsTab';
import {
  openScheduleDialogRelease,
  track
} from '../../components/smarttestscheduling/services/scheduleTestRunTracking';
import MFlags from '../../models/MFlags';
import ButtonLink from '../../components/ButtonLink';
import Arrays from '../../utils/Arrays';

class ReleaseDetails extends PageComponent {
  constructor(props) {
    super(props);
    this.meta.id = 'page-release-details';
    this.teamId = MContext.teamId;
    this.projectId = MContext.projectId;
    this.releaseId = MContext.releaseId;
    this.getRelease = this.getRelease.bind(this);
    this.renderHeader = this.renderHeader.bind(this);
    this.renderBody = this.renderBody.bind(this);
    this.activateRelease = this.activateRelease.bind(this);
    this.handleClickScheduleTestRunButton = this.handleClickScheduleTestRunButton.bind(this);
    this.state = {
      release: null,
    };
  }

  componentDidMount() {
    if (this.releaseId) {
      this.getRelease();
    }
  }

  getRelease() {
    const params = {
      pagination: {
        page: 0,
        size: 1,
        sorts: 'order,desc',
      },
      conditions: [
        {
          key: 'id',
          operator: '=',
          value: this.releaseId,
        },
      ],
      type: 'Release',
    };
    Services.search(params)
      .then(({ content }) => {
        const release = content[0];
        this.meta.title = t(
          '{{page}} {{name}}',
          { name: release.name, page: t('release') }
        );
        this.setState({ release });
      });
  }

  activateRelease() {
    const { release } = this.state;
    const closed = release.closed;
    Services.activateRelease(this.releaseId, !closed)
      .then((release) => {
        this.setState({ release });
      });
  }

  renderToolbar() {
    const routes = new Routes({ releaseId: this.releaseId });
    const { release } = this.state;
    return (
      <PageButtonToolbar>
        {this.renderCreateBuild(this.teamId, this.projectId, this.releaseId)}
        {this.renderCreateTestPlan(this.teamId, this.projectId)}
        <Button
          color={release.closed ? 'primary' : 'secondary'}
          data-trackid="make-active-release"
          onClick={(e) => {
            e.preventDefault();
            this.activateRelease();
          }}
        >
          {release.closed ? t('release#reopen') : t('release#close')}
        </Button>
        <ButtonLink
          color="secondary"
          href={routes.edit_release_link}
          title={t('edit')}
        >
          {t('edit')}
        </ButtonLink>
      </PageButtonToolbar>
    );
  }

  renderCreateBuild(teamId, projectId, releaseId) {
    const routes = new Routes({
      teamId,
      projectId,
      releaseId
    });

    return (
      <ButtonLink
        title={t('create-build')}
        color="secondary"
        href={routes.create_build_link()}
      >
        {t('create-build')}
      </ButtonLink>
    );
  }

  handleClickScheduleTestRunButton() {
    track(openScheduleDialogRelease());
  }

  renderCreateTestPlan(teamId, projectId) {
    const routes = new Routes({ teamId, projectId });
    // TODO use object info
    return (
      <ButtonLink
        title={t('create-plan')}
        color="secondary"
        href={routes.smart_test_scheduling_link({ releaseId: this.releaseId })}
        onClick={() => this.handleClickScheduleTestRunButton()}
      >
        {t('create-plan')}
      </ButtonLink>
    );
  }

  renderPlatformStatistics() {
    return (
      <PlatformStatisticsDataTable
        defaultSearchConditions={[
          ...Arrays.insertIf(!MFlags.fixReleaseExecutionStatusBarEnabled, buildSearchCondition('time', '=', 2)),
          buildSearchCondition('releaseId', '=', this.releaseId),
        ]}
        disableFilterButton
      />
    );
  }

  renderBuildTable() {
    return (
      <BuildDataTable
        defaultSearchConditions={[
          buildSearchCondition('Project.id', '=', this.projectId),
          buildSearchCondition('Release.id', '=', this.releaseId),
        ]}
        defaultSort={['name,asc']}
        disableFilterButton
      />
    );
  }

  renderHeader() {
    const { release } = this.state;
    const urlParams = {
      releases: t('releases'),
      releaseUpdate: release.name,
    };
    return (
      <ObjectSummary params={urlParams}>
        <ReleaseDetailHeaderNav
          projectId={this.projectId}
          teamId={this.teamId}
        />
        <ObjectInfo items={this.releaseInfoItems()} />
      </ObjectSummary>
    );
  }

  releaseInfoItems() {
    const { release } = this.state;
    const itemArray = [];
    itemArray.push(
      {
        text: DecoratorConstants.activeBadgeDecorator('closed', release),
      },
      {
        icon: <IconStartTime />,
        text: Time.formatDate(release.startTime, Time.DATE_FORMAT),
      },
      {
        icon: <IconReleaseDate />,
        text: Time.formatDate(release.endTime, Time.DATE_FORMAT),
      }
    );
    return itemArray;
  }

  renderBody() {
    const { release } = this.state;
    return (
      <>
        {this.renderToolbar()}
        <Switch>
          <Route
            exact
            path={RouteConstants.release_view}
            render={(params) => (
              <>
                <TestCasesTab release={release} {...params} />
                {this.renderPlatformStatistics()}
                {this.renderBuildTable()}
              </>)}
          />
          <Route
            exact
            path={RouteConstants.release_executions_details}
            render={(params) => <ExecutionsTab release={release} {...params} />}
          />
          <Route
            exact
            path={RouteConstants.release_run_configuration_details}
            render={(params) => <RunConfigurationsTab release={release} {...params} />}
          />
        </Switch>
      </>
    );
  }

  render() {
    const { release } = this.state;
    if (release) {
      return (
        <DefaultLayout
          renderHeader={this.renderHeader}
          renderBody={this.renderBody}
        />
      );
    } else {
      return null;
    }
  }
}

export default ReleaseDetails;
