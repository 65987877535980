import { TestEntity } from '../../../models/model/TestEntity';
import ActionsHelper from '../../../utils/table/ActionsHelper';
import { ACTIONS_TRACKING, DISPLAYED_PAGE_TRACKING, KATALON_EVENTS, OperationType } from '../../../utils/Constants';
import { DomEventHandlers } from '../../../utils/EventHandler';
import Routes from '../../../utils/Routes';
import { TestCase } from '../../../models/model/TestCase';

export default class ActionsMenuHandler {
  static renameHandler = (data: TestEntity, operationType: string = OperationType.INDIVIDUAL) => {
    const { testCase, testSuite } = data;
    if (testCase) {
      ActionsHelper.syncTrackingData(
        data,
        ACTIONS_TRACKING.RENAME_TEST_CASE_TRIGGERED,
        DISPLAYED_PAGE_TRACKING.ACTION_MENU,
        undefined,
        operationType
      );
    } else if (testSuite) {
      ActionsHelper.syncTrackingData(
        data,
        ACTIONS_TRACKING.RENAME_TEST_SUITE_TRIGGERED,
        DISPLAYED_PAGE_TRACKING.ACTION_MENU,
        undefined,
        operationType
      );
    }
    DomEventHandlers.createEvent(KATALON_EVENTS.openRenameDialog, { detail: { testEntity: data } });
  };

  static editHandler = (data: TestEntity, operationType: string = OperationType.INDIVIDUAL) => {
    const { testCase, testSuite, draftTestCase } = data;
    if (draftTestCase) {
      ActionsHelper.syncTrackingData(
        data,
        ACTIONS_TRACKING.EDIT_TEST_CASE_TRIGGERED,
        DISPLAYED_PAGE_TRACKING.ACTION_MENU,
        undefined,
        operationType
      );
      Routes.goToTestCaseEditLink(draftTestCase.id);
    } else if (testCase) {
      ActionsHelper.syncTrackingData(
        data,
        ACTIONS_TRACKING.EDIT_TEST_CASE_TRIGGERED,
        DISPLAYED_PAGE_TRACKING.ACTION_MENU,
        undefined,
        operationType
      );
      Routes.goToTestCaseEditLink(testCase.id);
    } else if (testSuite) {
      ActionsHelper.syncTrackingData(
        data,
        ACTIONS_TRACKING.EDIT_TEST_SUITE_TRIGGERED,
        DISPLAYED_PAGE_TRACKING.ACTION_MENU,
        undefined,
        operationType
      );
      Routes.goToTestSuiteEditLink(testSuite.id);
    }
  };

  // history: history from react-router
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static runHandler = (data: TestEntity, history: any, operationType: string = OperationType.INDIVIDUAL) => {
    const { testCase, testSuite } = data;
    if (testSuite) {
      ActionsHelper.syncTrackingData(
        data,
        ACTIONS_TRACKING.SCHEDULE_TEST_RUN_TRIGGERED,
        DISPLAYED_PAGE_TRACKING.ACTION_MENU,
        undefined,
        operationType
      );
      const routes = new Routes();
      history.push(routes.smart_test_scheduling_link({
        testProjectId: testSuite.testProject?.id,
        testSuiteId: testSuite.id
      }));
    } else if (testCase) {
      ActionsHelper.syncTrackingData(
        data,
        ACTIONS_TRACKING.INSTANT_RUN_TRIGGERED,
        DISPLAYED_PAGE_TRACKING.TES_CASE_TABLE,
        undefined,
        operationType
      );
      DomEventHandlers.createEvent(
        KATALON_EVENTS.openConfigureTestRunDialog,
        { detail: { testCase, displayPageTracking: DISPLAYED_PAGE_TRACKING.TES_CASE_TABLE } }
      );
    }
  };

  static addToTestSuiteHandler = (testEntities: TestEntity[], operationType: string = OperationType.INDIVIDUAL) => {
    const testCases: TestCase[] = testEntities.map((item: TestEntity) => item.testCase as TestCase);
    const displayedPageTracking = DISPLAYED_PAGE_TRACKING.TES_CASE_TABLE;
    ActionsHelper.syncTrackingData(
      testEntities[0],
      ACTIONS_TRACKING.ADD_TO_TEST_SUITE_TRIGGERED,
      displayedPageTracking,
      undefined,
      operationType
    );
    DomEventHandlers.createEvent(
      KATALON_EVENTS.openAddToTestSuiteDialog,
      { detail: { testCases, displayedPageTracking, operationType } }
    );
  };

  static moveHandler = (testEntities: TestEntity[], operationType: string = OperationType.INDIVIDUAL) => {
    const firstTestEntity = testEntities[0];
    const { testCase, testSuite } = firstTestEntity;
    if (testCase) {
      ActionsHelper.syncTrackingData(
        firstTestEntity,
        ACTIONS_TRACKING.MOVE_TEST_CASE_TRIGGERED,
        DISPLAYED_PAGE_TRACKING.ACTION_MENU,
        undefined,
        operationType
      );
    } else if (testSuite) {
      ActionsHelper.syncTrackingData(
        firstTestEntity,
        ACTIONS_TRACKING.MOVE_TEST_SUITE_TRIGGERED,
        DISPLAYED_PAGE_TRACKING.ACTION_MENU,
        undefined,
        operationType
      );
    }
    DomEventHandlers.createEvent(KATALON_EVENTS.openMoveG5TestEntityDialog, { detail: { testEntities, operationType } });
  };
}
