import React, { useState } from 'react';
import { Button, Col, Container, Row } from 'reactstrap';
import { map } from 'lodash';
import { IconDelete, IconPencilEdit } from '../images/CustomNewIcon';
import { t } from '../i18n/t';
import MAuth from '../models/MAuth';
import MContext from '../models/MContext';
import DeleteCustomFieldDefinitionDialog from './dialog/DeleteCustomFieldDefinitionDialog';

export default function CustomFieldDetail(props) {

  const { customFieldDefinition, openEditCustomFieldDialog, handleDeleteCustomFieldDefinition } = props;
  const teamId = MContext.teamId;
  const isTeamManager = MAuth.isTeamManager(teamId);
  const [isOpenDeleteCustomFieldDefinitionDialog, setOpenDeleteCustomFieldDefinitionDialog] = useState(false);

  const toggleDeleteCustomFieldDefinitionDialog = () => {
    setOpenDeleteCustomFieldDefinitionDialog(!isOpenDeleteCustomFieldDefinitionDialog);
  };

  const renderEditAndRemoveBtn = () => (
    <>
      <Button
        className="custom-field-detail__edit-and-remove-btn mr-2 ml-auto"
        onClick={() => openEditCustomFieldDialog(customFieldDefinition.id)}
      >
        <IconPencilEdit />
      </Button>
      <Button
        className="custom-field-detail__edit-and-remove-btn"
        onClick={() => toggleDeleteCustomFieldDefinitionDialog()}
      >
        <IconDelete />
      </Button>
    </>
  );

  const renderDeleteDialog = () => (
    <DeleteCustomFieldDefinitionDialog
      isOpen={isOpenDeleteCustomFieldDefinitionDialog}
      handleClose={toggleDeleteCustomFieldDefinitionDialog}
      handleDelete={handleDeleteCustomFieldDefinition}
      customFieldDefinition={customFieldDefinition}
    />
  );

  return (
    <Container fluid>
      <Row className="border rounded mt-4 custom-field-detail__container-btn">
        <Col md={3} className="mt-3">
          <p className="custom-field-detail__label">{t('custom-fields#display-name')}</p>
          <p className="custom-field-detail__value">{customFieldDefinition.displayName}</p>
        </Col>
        <Col md={3} className="mt-3">
          <p className="custom-field-detail__label">{t('custom-fields#key')}</p>
          <p className="custom-field-detail__value">{customFieldDefinition.key}</p>
        </Col>
        <Col md={5} className="mt-3">
          <p className="custom-field-detail__label">{t('custom-fields#values')}</p>
          <p className="custom-field-detail__value">{(map(customFieldDefinition.customFieldOptions, 'value')).join(', ')}</p>
        </Col>
        <Col md={1} className="custom-field-detail__container-btn">
          {isTeamManager && renderEditAndRemoveBtn()}
        </Col>
      </Row>
      {renderDeleteDialog()}
    </Container>
  );
}
