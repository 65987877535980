import React, { Component } from 'react';
import { Label } from 'reactstrap';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { t } from '../../../i18n/t';
import Time from '../../../utils/Moment';
import DatePicker from '../../DatePicker';
import Helper from '../../../utils/Helper';
import { ValidationDate } from '../../../utils/Constants';

class TimeFilterV2 extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();

    this.handleInputChange = this.handleInputChange.bind(this);
    this.buildFilter = this.buildFilter.bind(this);
    this.handleCheckInputClickAndBuildValueFilter = this.handleCheckInputClickAndBuildValueFilter.bind(this);
  }

  getInitialState() {
    const { value, setDisableFilter } = this.props;
    const isFormatDate = Helper.validateTimeFilterFormat(value);
    let from = null;
    let to = null;
    let valueTime = null;

    if (isFormatDate) {
      const { fromString, toString, valueTimeString } = this.detachFromTo(value);
      from = this.buildDateFromString(fromString);
      to = this.buildDateFromString(toString);
      valueTime = valueTimeString;

      if ((from && !from._isValid) || (to && !to._isValid)) {
        setDisableFilter(true);
      }
    }
    if (value && !isFormatDate) {
      setDisableFilter(true);
    }
    return {
      from,
      to,
      valueTime
    };
  }

  detachFromTo(value) {
    let fromString = null;
    let toString = null;
    let valueTimeString = null;
    const isFullFromTo = value?.includes(',');
    if (isFullFromTo) {
      const fromTo = value.split(',');
      fromString = fromTo[0].split('=')[1];
      toString = fromTo[1].split('=')[1];
      valueTimeString = value;
    } else {
      if (value?.includes('>')) {
        fromString = value.split('=')[1];
        valueTimeString = fromString;
      } else {
        toString = value.split('=')[1];
        valueTimeString = toString;
      }
    }
    return {
      fromString,
      toString,
      valueTimeString,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { value } = nextProps;
    const { valueTime } = prevState;

    /*
     * When user click button "Clear" in component SearchQuery then value will be null.
     * I check the valueTime if there is a value then set data to null to clear data.
    */
    if (!!valueTime && !value) {
      return {
        from: null,
        to: null,
        valueTime: null,
      };
    }
    return prevState;
  }

  buildFilter(value) {
    const { id, onBuildFilterSearch } = this.props;
    if (onBuildFilterSearch) {
      onBuildFilterSearch(id, value);
    }
  }

  /*
   * Cause: when getting value from, to from searchString, from, to has data type of String.
   * Solution: check if from, to is string then convert to moment.
   */
  buildDateFromString(dateString) {
    if (typeof dateString === 'string') {
      return moment(dateString, Time.DATE_FORMAT);
    }
    return null;
  }

  buildFilterValue() {
    const { from, to } = this.state;

    if (from && to) {
      return `>=${from?.format(Time.DATE_FORMAT)},<=${to?.format(Time.DATE_FORMAT)}`;
    }
    if (from) {
      return `>=${from?.format(Time.DATE_FORMAT)}`;
    }
    if (to) {
      return `<=${to?.format(Time.DATE_FORMAT)}`;
    }
    return null;
  }

  isValidDate() {
    const { from, to } = this.state;

    if (from?.format(Time.DATE_FORMAT) === ValidationDate.INVALID_DATE) {
      return false;
    }
    if (to?.format(Time.DATE_FORMAT) === ValidationDate.INVALID_DATE) {
      return false;
    }
    return true;
  }

  /*
   * Check the function when I click on the DatePicker if searchString (value) includes "Invalid date" or from not
   * valid or to not valid then re-enable the "Apply and search" button and buildFilter again.
   */
  handleCheckInputClickAndBuildValueFilter() {
    const { value, setDisableFilter } = this.props;
    const { from, to } = this.state;

    if (value?.includes(ValidationDate.INVALID_DATE)) {
      setDisableFilter(false);
      const valueFilter = this.buildFilterValue();
      this.buildFilter(valueFilter);
    }

    if (from && !from._isValid) {
      setDisableFilter(false);
      this.setState({ from: null }, () => {
        const valueFilter = this.buildFilterValue();
        this.buildFilter(valueFilter);
      });
    }

    if (to && !to._isValid) {
      setDisableFilter(false);
      this.setState({ to: null }, () => {
        const valueFilter = this.buildFilterValue();
        this.buildFilter(valueFilter);
      });
    }
  }

  handleInputChange(name, value) {
    const { setDisableFilter } = this.props;

    this.setState({
      [name]: value
    }, () => {
      const valueFilter = this.buildFilterValue();
      this.buildFilter(valueFilter);
      this.setState({ valueTime: valueFilter });
      const isValidDate = this.isValidDate();
      setDisableFilter(!isValidDate);
    });
  }

  render() {
    const { from, to } = this.state;
    const { label } = this.props;

    return (
      <div className="normal-label">
        <Label>{label}</Label>
          <div className="d-flex flex-nowrap align-items-baseline">
          <div className="p-2">{t('from')}</div>
            <DatePicker
              id="from"
              name="from"
              inputFormat={Time.DATE_FORMAT}
              value={from}
              maxDate={to || Time.MAX_DATE}
              placeholder={t('search-bar#place-holder-datetime')}
              onChange={(value) => this.handleInputChange('from', value)}
              onClick={() => this.handleCheckInputClickAndBuildValueFilter()}
            />
            <div className="p-2">{t('to')}</div>
            <DatePicker
              id="to"
              name="to"
              inputFormat={Time.DATE_FORMAT}
              value={to}
              minDate={from || Time.MIN_DATE}
              placeholder={t('search-bar#place-holder-datetime')}
              onChange={(value) => this.handleInputChange('to', value)}
              onClick={() => this.handleCheckInputClickAndBuildValueFilter()}
            />
          </div>
      </div>
    );
  }
}

TimeFilterV2.propTypes = {
  onBuildFilterSearch: PropTypes.func,
  setDisableFilter: PropTypes.func.isRequired,
  value: PropTypes.string,
  label: PropTypes.string,
  id: PropTypes.string,
};

TimeFilterV2.defaultProps = {
  value: '',
  label: '',
  id: '',
};

export default TimeFilterV2;
