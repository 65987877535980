import React from 'react';
import DefaultLayout from '../../components/DefaultLayout';
import PageComponent from '../../components/PageComponent';
import ObjectSummary from '../../components/summary/ObjectSummary';
import { t } from '../../i18n/t';
import MAuth from '../../models/MAuth';
import MContext from '../../models/MContext';
import { notificationIds } from '../../utils/NotificationPageUtils';
import Routes from '../../utils/Routes';
import { OrganizationManageProjectsHeaderNav } from '../../components/HeaderNav';
import { Route, Switch } from 'react-router-dom';
import RouteConstants from '../../utils/RouteConstants';
import ArchivedProjectsTab from './ArchivedProjectsTab';
import ActiveProjectsTab from './ActiveProjectsTab';

class OrganizationManageProjects extends PageComponent {
  constructor(props) {
    super(props);
    this.meta.id = "page-organization-manage-projects";
    this.meta.title = t("projects");
    this.organizationId = MContext.organizationId;

    this.renderHeader = this.renderHeader.bind(this);
    this.renderBody = this.renderBody.bind(this);
  }

  renderObjectSummary() {
    const urlParams = {
      organizationProjects: t("projects"),
    };

    return (
      <ObjectSummary params={urlParams}>
        <OrganizationManageProjectsHeaderNav
          projectId={this.projectId}
          teamId={this.teamId}
        />
      </ObjectSummary>
    );
  }

  renderHeader() {
    return <>{this.renderObjectSummary()}</>;
  }

  renderBody() {
    return (
      <>
        <Switch>
          <Route
            exact
            path={RouteConstants.organization_manage_projects}
            render={(params) => (
              <ActiveProjectsTab
                organizationId={this.organizationId}
                {...params}
              />
            )}
          />
          <Route
            exact
            path={RouteConstants.organization_manage_archived_projects}
            render={(params) => (
              <ArchivedProjectsTab
                organizationId={this.organizationId}
                {...params}
              />
            )}
          />
        </Switch>
      </>
    );
  }

  render() {
    const isOrganizationManager = MAuth.isOrganizationManager(
      this.organizationId
    );

    if (!isOrganizationManager) {
      Routes.goToNotificationPage(notificationIds.NO_PERMISSION);
    }

    return (
      <DefaultLayout
        renderHeader={this.renderHeader}
        renderBody={this.renderBody}
      />
    );
  }
}

export default OrganizationManageProjects;
