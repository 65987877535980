import request from '../../pages/util/request';

const testCloudService = {
  getTestCloudEnvironments(channel) {
    return request.get(
      '/test-cloud-environments',
      channel ? {
        headers: {
          'TC-Channel': channel,
        }
      } : {}
    );
  },
  getTestCloudMobileDevices(organizationId, availableOnly = true) {
    return request.get('/ees/devices', {
      headers: {
        'x-organization-id': organizationId,
      },
      params: {
        availableOnly
      }
    });
  },

  getTestCloudOrganizationQuota(organizationId) {
    return request.get(`/organization_quota/${organizationId}/trial`);
  },
  getTestCloudSessionSummary(organizationId, projectId) {
    return request.get('/ees/sessions/summary/', {
      params: {
        organizationId,
        projectId,
      },
      ignoreNotifyError: true,
    });
  }
};

export default testCloudService;
