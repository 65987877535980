import { orderBy, isEmpty } from 'lodash';
import MAuth from '../../models/MAuth';
import Routes from '../../utils/Routes';
import { buildSearchCondition } from '../../components/search/SearchUtils';
import Services from '../../utils/Services';
import { SearchEntity, AccessToken } from '../../utils/Constants';
import { Organization } from '../../models/model/Organization';

interface PropEntity {
  doesTryVisualTesting?: boolean;
}

function RedirectPageHandler(props: PropEntity) {
  const { doesTryVisualTesting } = props;

  const urlString = window.location.href;
  const accessToken = new URL(urlString).searchParams.get(AccessToken.PARAM_NAME);
  const organizations = MAuth.user.organizations;
  const projects = MAuth.user.projects;

  const getLastAccessedProject = () => {
    const params = {
      pagination: {
        page: 0,
        size: 1,
        sorts: [],
      },
      conditions: [
        buildSearchCondition('User.id', '=', MAuth.user.id)
      ],
      type: SearchEntity.RecentProject
    };
    return Services.search(params)
      .then(({ content }) => content);
  };

  const goToOrganizationDefault = () => {
    const filter = organizations.filter((organization: Organization) => organization && organization.mostRecentProjectAccessedAt);
    const sorts = orderBy(filter, ['mostRecentProjectAccessedAt'], ['desc']);
    Routes.goToOrganizationHomePage(sorts[0]?.id || organizations[0].id);
  };

  const goToVisualTestingPage = () => {
    getLastAccessedProject()
      .then((lastAccessedProject) => {
        if (isEmpty(lastAccessedProject)) {
          Routes.goToVisualTestingPage(projects[0].teamId, projects[0].id);
        } else {
          Routes.goToVisualTestingPage(lastAccessedProject[0].teamId, lastAccessedProject[0].id);
        }
      });
    return null;
  };

  // When the user is already logged in but the access token is still attached to the URL, we need to do nothing here.
  // App.jsx will logout the current user and automatically log in with the token.
  if (accessToken) {
    return null;
  }

  if (isEmpty(organizations)) {
    return Routes.goToCreateOrganization();
  }

  if (doesTryVisualTesting && !isEmpty(projects)) {
    return goToVisualTestingPage();
  }

  return goToOrganizationDefault();
}

export default RedirectPageHandler;
