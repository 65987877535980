import React from 'react';
import NormalCard from '../../../components/card/NormalCard';
import { buildSearchCondition } from '../../../components/search/SearchUtils';
import MContext from '../../../models/MContext';
import ActiveReleasesSummary from '../../release/ActiveReleasesSummary';
import MFlags from '../../../models/MFlags';

const ReleaseReadiness = () => {

  const projectId = MContext.projectId;
  const autoUpdate = true;
  const autoUpdateIntervalTime = 900000;

  return (
    <div>
      {MFlags.dashboardAIPhase2Enabled ?
        <ActiveReleasesSummary
          autoUpdate={autoUpdate}
          autoUpdateIntervalTime={autoUpdateIntervalTime}
          headerComponent={NormalCard}
          bodyComponent={NormalCard}
          useCollapseButton={false}
          cardClassName="project-dashboard-card-border shadow-none py-1"
        /> :
        <ActiveReleasesSummary
          defaultSort={['endTime,asc']}
          defaultSearchConditions={[
            buildSearchCondition('Project.id', '=', projectId),
          ]}
          autoUpdate={autoUpdate}
          autoUpdateIntervalTime={autoUpdateIntervalTime}
          headerComponent={NormalCard}
          bodyComponent={NormalCard}
          useCollapseButton={false}
          cardClassName="project-dashboard-card-border shadow-none py-1"
        />}
    </div>
  );
};

export default ReleaseReadiness;
