import React from 'react';
import TabComponent from '../../components/TabComponent';
import { t } from '../../i18n/t';
import { buildSearchCondition } from '../../components/search/SearchUtils';
import ExecutionDataTable from '../../components/table/ExecutionDataTable';

class ExecutionsTab extends TabComponent {
  constructor(props) {
    super(props);
    this.meta.id = 'page-release-details-executions';
  }

  renderExecutions() {
    const { release } = this.props;
    return (
      <ExecutionDataTable
        showProjectColumn={false}
        defaultSearchConditions={[
          buildSearchCondition('Release.id', '=', release.id),
        ]}
        showCheckboxColumn
      />
    );
  }

  render() {
    return this.renderExecutions();
  }
}

export default ExecutionsTab;
