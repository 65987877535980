import React from 'react';
import _ from 'lodash';
import { Divider } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { IconTestSuiteCollectionTable, IconCucumberBlue, IconStartTime, IconDuration, IconProfileV2 } from '../../images/CustomIcon';
import { t } from '../../i18n/t';
import DecoratorConstants from '../../utils/DecoratorConstants';
import Routes from '../../utils/Routes';
import Arrays from '../../utils/Arrays';
import GroupEvent from '../../utils/track/GroupEvent';
import Link from '../../components/Link';

export const getExecutionTarget = ({ order, executionTestSuiteResources, project }) => executionTestSuiteResources.map(({ urlId, testSuite, profiles, platform, status }) => ({
  executionId: order,
  targetName: testSuite ? testSuite.name : '',
  testSuiteName: testSuite ? testSuite.name : '',
  testSuitePath: testSuite ? testSuite.path : '',
  projectId: project.id,
  executionTestSuiteId: urlId,
  testSuiteType: testSuite ? testSuite.type : '',
  profiles,
  platform,
  status
}));

export const badgeComponent = (number) => (
  <div className="badge-number">
    +{number}
  </div>
);

export const badgeCountComponent = (number) => (
  <div className="badge-count">
    {number}
  </div>
);

export const verticalDivider = (height = '1.14rem', margin = '1rem') => (
  <Divider sx={{ height, marginRight: margin, marginLeft: margin }} orientation="vertical" />
);

const getRunConfiguration = (row) => {
  const jobs = _.get(row, 'jobs', []);
  return _.uniqBy(jobs.map((j) => j.runConfiguration), 'id');
};

const getTestSuiteCollections = (row) => _.get(row, 'testSuiteCollections', []);

const maxSuites = 3;

export const ExecutionUtils = {

  hasTestSuiteCollection: (execution) => {
    const testSuiteCollections = getTestSuiteCollections(execution);
    return testSuiteCollections.length > 0;
  },

  hasTestPlan: (execution) => {
    const jobs = _.get(execution, 'jobs', []);
    return jobs.length > 0;
  },

  isBDD: (execution) => execution.type === 'BDD'
};

export const ExecutionDecorator = {

  calendarNameDecorator: (row) => {
    let name = '';
    if (ExecutionUtils.hasTestPlan(row)) {
      const runConfigurations = getRunConfiguration(row);
      name = _.map(runConfigurations, 'name').join(', ');
    } else if (ExecutionUtils.hasTestSuiteCollection(row)) {
      const testSuiteCollections = getTestSuiteCollections(row);
      name = _.map(testSuiteCollections, 'name').join(', ');
    } else {
      const executionTarget = getExecutionTarget(row);
      name = _.map(executionTarget, 'testSuiteName').join(', ');
    }
    return name;
  },

  calendarDecorator: (row) => {
    const name = ExecutionDecorator.calendarNameDecorator(row);
    return (
      <div className="calendar-name" data-trackid="show-details-sidebar" data-groupid={[GroupEvent.ACCESS_REPORT, GroupEvent.ACCESS_TEST_PLANNING]}>
        {ExecutionDecorator.statusDecorator('status', row)}&nbsp;
        {`#${row.order}`}&nbsp;{name}
      </div>
    );
  },

  nameDecorator: (row, style, isTruncate) => {
    const name = ExecutionDecorator.getName(row, style, isTruncate);
    return (
      <div className={`d-flex align-items-baseline ${style} ${isTruncate && 'flex-column'}`}>
        {name}
        <div className="ml-1">{ExecutionDecorator.bddDecorator(row)}</div>
      </div>
    );
  },

  nameV2Decorator: (row, style, truncateCharacter) => {
    const name = ExecutionDecorator.getNameV2(row, style, truncateCharacter);
    return (
      <div className={`${style}`}>
        {name}
        <div className="ml-1">{ExecutionDecorator.bddDecorator(row)}</div>
      </div>
    );
  },

  testCloudTunnelNameDecorator: (row, style) => {
    const name = ExecutionDecorator.getName(row, style);
    return (
      <div className={`d-flex align-items-center ${style}`}>
        {DecoratorConstants.renderTestCloudTunnelIcon()}
        {name}
        <div className="ml-1">{ExecutionDecorator.bddDecorator(row)}</div>
      </div>
    );
  },

  getName: (row, style, isTruncate) => {
    if (ExecutionUtils.hasTestPlan(row)) {
      return ExecutionDecorator.testRunType(row, style, isTruncate);
    }
    if (ExecutionUtils.hasTestSuiteCollection(row)) {
      return ExecutionDecorator.testSuiteCollectionDecorator(row, style, isTruncate);
    }
    return ExecutionDecorator.testSuiteListDecorator(row, style, isTruncate);
  },

  getNameV2: (row, style, truncateCharacter) => {
    if (ExecutionUtils.hasTestPlan(row)) {
      return ExecutionDecorator.testRunTypeV2(row, style, truncateCharacter);
    }
    if (ExecutionUtils.hasTestSuiteCollection(row)) {
      return ExecutionDecorator.testSuiteCollectionDecoratorV2(row, style, truncateCharacter);
    }
    return ExecutionDecorator.testSuiteListDecoratorV2(row, style, truncateCharacter);
  },

  bddDecorator: (row) => {
    if (ExecutionUtils.isBDD(row)) {
      return <IconCucumberBlue />;
    }
    return null;
  },

  testSuiteCollectionDecorator: (row, style, isTruncate) => {
    if (!ExecutionUtils.hasTestSuiteCollection(row)) {
      return null;
    }
    const testSuiteCollections = getTestSuiteCollections(row);
    const tscName = testSuiteCollections[0].name;
    const tscPath = testSuiteCollections[0].path;

    if (isTruncate) {
      return (
        <div className="d-flex w-100">
          <div>
            <IconTestSuiteCollectionTable title={t('test-suite-collection')} />
          </div>
          {DecoratorConstants.textMiddleTruncateDecorator(tscName, '100%', tscPath, 'ml-1')}
        </div>
      );
    }

    return (
      <div className={style || ''} title={tscPath}>
        <IconTestSuiteCollectionTable title={t('test-suite-collection')} />
        {' '}
        <span title={tscName}>{tscName}</span>
      </div>
    );
  },

  testSuiteCollectionDecoratorV2: (row, style, truncateCharacter) => {
    if (!ExecutionUtils.hasTestSuiteCollection(row)) {
      return null;
    }
    const testSuiteCollections = getTestSuiteCollections(row);
    const tscName = testSuiteCollections[0].name;
    const tscPath = testSuiteCollections[0].path;

    if (truncateCharacter) {
      return (
        <div className="d-flex w-100" title={tscName}>
          {DecoratorConstants.truncateLeftStringDecorator(tscName, truncateCharacter)}
        </div>
      );
    }

    return (
      <div className={style || ''} title={tscPath}>
        {' '}
        <span title={tscName}>{tscName}</span>
      </div>
    );
  },

  statusDecorator: (name, row, style) => {
    let status;
    if (row.executionStage === 'IMPORTING' ||
      row.executionStage === 'RUNNING' ||
      row.executionStage === 'TERMINATE'
    ) {
      status = row.executionStage;
    } else {
      status = row.status;
    }
    return DecoratorConstants.status(status, style);
  },

  profileDecorator: (row) => {
    const executionTargets = getExecutionTarget(row);
    const profiles = executionTargets.map((executionTargets) => executionTargets.profiles);
    return DecoratorConstants.profile(profiles);
  },

  platformDecorator: (row) => {
    const executionTargets = getExecutionTarget(row);
    const platforms = executionTargets.map((executionTargets) => executionTargets.platform);
    return DecoratorConstants.browsers(platforms);
  },

  testSuiteListDecorator: (row, style, isTruncate) => {
    const constructedLink = new Routes({
      executionId: row.order,
      projectId: row.project.id,
      teamId: row.project.teamId,
    });

    const sortStatus = ['FAILED', 'ERROR', 'INCOMPLETE', 'PASSED'];
    const executionTargets = getExecutionTarget(row).sort((a, b) => sortStatus.indexOf(a.status) - sortStatus.indexOf(b.status));

    const decorator = (executionTarget) => {
      const fullPath = executionTarget.testSuitePath
        ? `${executionTarget.testSuitePath}/${executionTarget.testSuiteName}`
        : executionTarget.testSuiteName;

      const testSuiteName = executionTarget.testSuiteName;

      if (isTruncate) {
        return (
          <div className="d-flex w-100">
            {DecoratorConstants.statusDecorator('status', executionTarget)}
            {DecoratorConstants.textMiddleTruncateDecorator(testSuiteName, '100%', fullPath, 'ml-1')}
          </div>
        );
      }

      return (
        <div
          className={style || 'text-word-wrap'}
          title={fullPath}
        >
          {DecoratorConstants.statusDecorator('status', executionTarget)} {' '}
          <span title={fullPath}>{testSuiteName}</span>
        </div>
      );
    };

    let testSuiteLinks;
    let more;
    const length = executionTargets.length;

    if (length <= maxSuites) {
      testSuiteLinks = executionTargets.map(decorator);
      more = '';
    } else {
      testSuiteLinks = executionTargets.slice(0, maxSuites).map(decorator);
      more = <Link className="text-decoration-none" href={constructedLink.execution_details_link}>({length - maxSuites} more...)</Link>;
    }

    if (isTruncate) {
      return <>{testSuiteLinks}{more}</>;
    }

    return style ? <div className={style}>{testSuiteLinks}{more}</div> : <div>{testSuiteLinks}{more}</div>;
  },

  testSuiteListDecoratorV2: (row, style, truncateCharacter) => {
    const constructedLink = new Routes({
      executionId: row.order,
      projectId: row.project.id,
      teamId: row.project.teamId,
    });

    const sortStatus = ['FAILED', 'ERROR', 'INCOMPLETE', 'PASSED'];
    const executionTargets = getExecutionTarget(row).sort((a, b) => sortStatus.indexOf(a.status) - sortStatus.indexOf(b.status));

    const decorator = (executionTarget) => {
      const fullPath = executionTarget.testSuitePath
        ? `${executionTarget.testSuitePath}/${executionTarget.testSuiteName}`
        : executionTarget.testSuiteName;

      const testSuiteName = executionTarget.testSuiteName;

      if (truncateCharacter) {
        return (
          <div className="d-flex w-100">
            <div title={testSuiteName}>
              {DecoratorConstants.truncateLeftStringDecorator(testSuiteName, truncateCharacter)}
            </div>
          </div>
        );
      }

      return (
        <div
          className={style || 'text-word-wrap'}
          title={fullPath}
        >
          <span title={fullPath}>{testSuiteName}</span>
        </div>
      );
    };

    let testSuiteLinks;
    let more;
    const length = executionTargets.length;

    if (length <= maxSuites) {
      testSuiteLinks = executionTargets.map(decorator);
      more = '';
    } else {
      testSuiteLinks = executionTargets.slice(0, maxSuites).map(decorator);
      more = <Link className="text-decoration-none" href={constructedLink.execution_details_link}>({length - maxSuites} more...)</Link>;
    }

    if (truncateCharacter) {
      return <>{testSuiteLinks}{more}</>;
    }

    return style ? <div className={style}>{testSuiteLinks}{more}</div> : <div>{testSuiteLinks}{more}</div>;
  },

  platform: (name, row) => {
    const osVersion = _.get(row, name);
    return DecoratorConstants.platform({ osVersion });
  },

  buildAndRelease: (name, row) => {
    let buildLabel = _.get(row, 'buildLabel');
    const buildUrl = _.get(row, 'buildUrl');
    if (buildUrl && !buildLabel) {
      buildLabel = 'Build';
    }
    const release = _.get(row, 'release');
    const build = _.get(row, 'build');
    const projectId = _.get(row, 'projectId');
    let releaseLink;
    if (release) {
      const route = new Routes({
        releaseId: release.id,
        projectId,
      });
      releaseLink = <div className="text-word-wrap"><Link href={route.view_release_link}>{release.name}</Link></div>;
    }
    let buildLink;
    if (build) {
      const route = new Routes({
        buildId: build.id,
        releaseId: release.id,
        projectId,
      });
      buildLink = <div className="text-word-wrap"><Link target="_blank" href={route.build_details_link} rel="noreferrer">{build.name}</Link></div>;
    }

    const jobs = _.get(row, 'jobs');
    const jobLinks = Arrays.intersperse(jobs, ', ', (job) => {
      const { order: jobOrder, runConfigurationId } = job;
      const constructedLink = new Routes({
        runConfigurationId,
        jobOrder,
        projectId,
      });
      return <Link href={constructedLink.job_link} title={jobOrder}>#{jobOrder}</Link>;
    });
    return (
      <>
        {releaseLink}
        {buildLink}
        <div className="text-word-wrap"><a target="_blank" rel="noopener noreferrer" href={buildUrl}>{buildLabel}</a></div>
        {jobLinks.length > 0}{jobLinks}
      </>
    );
  },

  testRunTitleDecorator: (row) => {
    const runConfigurations = getRunConfiguration(row);
    return _.map(runConfigurations, 'name').join(', ');
  },

  idDecorator: (row) => {
    const order = _.get(row, 'order');
    return (
      <>
        <div>
          #{order}
        </div>
        {verticalDivider()}
      </>
    );
  },

  testRunType: (row, style, isTruncate) => {
    if (!ExecutionUtils.hasTestPlan(row)) {
      return null;
    }
    const runConfigurations = getRunConfiguration(row);
    const runConfigurationNames = runConfigurations.map((rc) => (isTruncate ? DecoratorConstants.textMiddleTruncateDecorator(rc.name, '100%') :
    <div key={`${uuidv4()}-${rc.name}`} className={style} title={rc.name}>{rc.name}</div>));
    return (
      <div>
        {runConfigurationNames}
      </div>
    );
  },

  testRunTypeV2: (row, style, truncateCharacter) => {
    if (!ExecutionUtils.hasTestPlan(row)) {
      return null;
    }
    const runConfigurations = getRunConfiguration(row);
    const runConfigurationNames = runConfigurations.map((rc) => (truncateCharacter ? <div title={rc.name}>{DecoratorConstants.truncateLeftStringDecorator(rc.name, truncateCharacter)}</div> :
    <div key={`${uuidv4()}-${rc.name}`} className={style} title={rc.name}>{rc.name}</div>));
    return (
      <div>
        {runConfigurationNames}
      </div>
    );
  },

  startTimeDecorator: (row) => (
    <>
      <span className="d-flex align-items-center">
        <span className="fixed-1rem-icon mr-1 d-flex align-items-center">
          <IconStartTime title={t('startTime')} />
        </span>
        {DecoratorConstants.timeDecorator('startTime', row)}
      </span>
      {verticalDivider()}
    </>
  ),

  durationDecorator: (row) => {
    if (!_.get(row, 'elapsedDuration')) {
      return null;
    }
    return (
      <>
        <span className="d-flex align-items-center">
          <span className="fixed-1rem-icon mr-1 d-flex align-items-center">
            <IconDuration title={t('elapsedDuration')} />
          </span>
          {DecoratorConstants.durationDecorator('elapsedDuration', row)}
        </span>
        {verticalDivider()}
      </>);
  },

  osDecorator: (row) => {
    const executionTargets = getExecutionTarget(row);
    const platforms = executionTargets.map((executionTarget) => executionTarget.platform);
    const oSNames = platforms.map((platform) => _.get(platform, 'osName', ''));
    const oSTypes = oSNames.map((oSName) => DecoratorConstants.typeByOS(oSName)).filter((oSType) => (oSType !== ''));
    const uniqueOSTypes = _.uniq(oSTypes);
    return (
      uniqueOSTypes[0] &&
      <>
        <div className="d-flex align-items-center">
          <span className="fixed-1rem-icon d-flex align-items-center">
            {DecoratorConstants.iconByOS(uniqueOSTypes[0])}
          </span>
          {uniqueOSTypes.length > 1 && badgeComponent(uniqueOSTypes.length - 1)}
        </div>
        {verticalDivider()}
      </>
    );
  },

  browserDecorator: (row) => {
    const executionTargets = getExecutionTarget(row);
    const platforms = executionTargets.map((executionTarget) => executionTarget.platform);
    const browserNames = platforms.map((platform) => _.get(platform, 'browserName', ''));
    const browserTypes = browserNames.map((browserName) => DecoratorConstants.typeByBrowsers(browserName)).filter((browserType) => (browserType !== ''));
    const uniqueBrowserTypes = _.uniq(browserTypes);
    return (
      uniqueBrowserTypes[0] &&
      <>
        <div className="d-flex align-items-center">
          <span className="fixed-1rem-icon d-flex align-items-center">
            {DecoratorConstants.iconByBrowser(uniqueBrowserTypes[0])}
          </span>
          {uniqueBrowserTypes.length > 1 && badgeComponent(uniqueBrowserTypes.length - 1)}
        </div>
        {verticalDivider()}
      </>
    );
  },

  profileDecoratorV2: (row) => {
    const executionTargets = getExecutionTarget(row);
    const profiles = executionTargets.map((executionTargets) => executionTargets.profiles);
    if (_.isArray(profiles)) {
      const profileFlatten = _.flatten(profiles);
      const profileUnique = _.uniq(profileFlatten);
      return (
        profileUnique[0] &&
        <div className="d-flex align-items-center">
          <span className="fixed-1rem-icon mr-1 d-flex align-items-center">
            <IconProfileV2 />
          </span>
          {profileUnique[0]}
          {profileUnique.length > 1 && badgeComponent(profileUnique.length - 1)}
          {verticalDivider()}
        </div>
      );
    } else {
      return (
        profiles &&
        <div className="d-flex align-items-center">
          <IconProfileV2 className="mr-1" />
          {profiles}
          {verticalDivider()}
        </div>
      );
    }
  },

  assigneeDecorator: (row) => {
    const user = _.get(row, 'user');
    if (!user) {
      return null;
    }
    const size = 16;
    return (
      <div className="d-flex align-items-center">
        {DecoratorConstants.renderUserAvatarDecorator(user.fullName, user.avatar, true, size)}
        {verticalDivider()}
      </div>
    );
  },

  lineStatusDecorator: (row) => {
    const status = row.status.toUpperCase();
    let className;
    switch (status) {
      case 'PASSED':
        className = 'line-status-passed';
        break;
      case 'FAILED':
        className = 'line-status-failed';
        break;
      case 'INCOMPLETE':
        className = 'line-status-incomplete';
        break;
      default:
        className = 'line-status-none';
        break;
    }
    return <div className={className} />;
  }
};
