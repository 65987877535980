import React from 'react';
import PageComponent from '../../components/PageComponent';
import { notificationIds } from '../../utils/NotificationPageUtils';
import { t } from '../../i18n/t';
import Routers from '../../utils/Routes';
import MAuth from '../../models/MAuth';
import MContext from '../../models/MContext';
import ObjectSummary from '../../components/summary/ObjectSummary';
import DefaultLayout from '../../components/DefaultLayout';
import UserManagement from './UserManagement';

class Users extends PageComponent {
  constructor(props) {
    super(props);
    this.meta.id = 'page-team-users';
    this.meta.title = t('Users');

    // default team id
    this.teamId = MContext.teamId;
    this.renderHeader = this.renderHeader.bind(this);
    this.renderBody = this.renderBody.bind(this);
  }

  renderObjectSummary() {
    const team = MContext.team;
    const urlParams = {
      teamId: this.teamId,
      teamName: team.name,
      users: 'Users',
    };
    return (
      <ObjectSummary params={urlParams} />
    );
  }

  renderHeader() {
    return (
      <>
        {this.renderObjectSummary()}
      </>
    );
  }

  renderBody() {
    return (
      <UserManagement {...this.props} />
    );
  }

  render() {
    const isTeamManager = MAuth.isTeamManager(this.teamId);

    if (!isTeamManager) {
      Routers.goToNotificationPage(notificationIds.NO_PERMISSION);
    }

    const team = MContext.team;

    if (team) {
      return (
        <DefaultLayout
          renderHeader={this.renderHeader}
          renderBody={this.renderBody}
        />
      );
    }
    return null;
  }
}

export default Users;
