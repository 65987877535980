import { Chip } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import TooltipComponent from './TooltipComponent';

function ChipLabel({ label, name }) {
  const buildLabel = () => (
    <div className="d-flex">
      <TooltipComponent placement="bottom-end" arrow text={label}>
        <div className="chip-text-truncate">{label}</div>
      </TooltipComponent>
      <span className="pl-1 pr-1">|</span>
      <span>{name}</span>
    </div>
  );

  return (
    <div className="pr-1 pb-1">
      <Chip
        className="border-0 chip-bg-border"
        variant="outlined"
        label={buildLabel()}
        size="small"
      />
    </div>
  );

}

ChipLabel.propTypes = {
  label: PropTypes.element.isRequired,
  name: PropTypes.element.isRequired,
};

export default ChipLabel;
