import _ from 'lodash';
import React from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import DefaultLayout from '../../components/DefaultLayout';
import PageComponent from '../../components/PageComponent';
import ObjectSummary from '../../components/summary/ObjectSummary';
import { t } from '../../i18n/t';
import MAuth from '../../models/MAuth';
import MContext from '../../models/MContext';
import Notification from '../../utils/Notification';
import { notificationIds } from '../../utils/NotificationPageUtils';
import Routes from '../../utils/Routes';
import Services from '../../utils/Services';
import ImportTestOpsLicense from '../../components/startup/ImportTestOpsLicense';

class TestOpsLicense extends PageComponent {
  constructor(props) {
    super(props);
    this.meta.id = 'page-organization-testops-op-licenses';
    this.meta.title = t('testops-license');

    this.organizationId = MContext.organizationId;

    this.state = {
      organization: null,
      machineId: '',
    };

    this.onUploadFinish = this.onUploadFinish.bind(this);
    this.renderObjectSummary = this.renderObjectSummary.bind(this);
    this.renderHeader = this.renderHeader.bind(this);
    this.renderBody = this.renderBody.bind(this);
    this.getBuildInfo = this.getBuildInfo.bind(this);
  }

  componentDidMount() {
    this.getBuildInfo();
    this.setState({ organization: MContext.organization });
  }

  getBuildInfo() {
    Services.getBuildInfo()
      .then((responseJson) => {
        const machineId = _.get(responseJson, ['onPremise', 'machineId']);
        this.setState({ machineId });
      });
  }

  onUploadFinish() {
    Notification.pushSuccess('Import successfully');
  }

  renderObjectSummary() {
    const urlParams = {
      testops: t('testops-license'),
    };
    return (
      <ObjectSummary params={urlParams} />
    );
  }

  renderHeader() {
    return (
      <>
        {this.renderObjectSummary()}
      </>
    );
  }

  renderBody() {
    const isOrganizationManager = MAuth.isOrganizationManager(this.organizationId);

    if (!isOrganizationManager) {
      Routes.goToNotificationPage(notificationIds.NO_PERMISSION);
    }
    const { organization, machineId } = this.state;
    if (organization) {
      return (
        <Card>
          <CardBody>
            <Row>
              <Col sm="12" md="8" lg="6" xl="5">
                <ImportTestOpsLicense
                  submitBtn="Import Subscription License"
                  machineId={machineId}
                  onUploadFinish={this.onUploadFinish}
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
      );
    }
    return null;
  }

  render() {
    return (
      <>
        <DefaultLayout
          renderHeader={this.renderHeader}
          renderBody={this.renderBody}
        />
      </>
    );
  }
}

export default TestOpsLicense;
