import React from 'react';
import { styled } from '@mui/material/styles';
import PageComponent from '../../components/PageComponent';
import MContext from '../../models/MContext';
import { t } from '../../i18n/t';
import Services from '../../utils/Services';
import Routes from '../../utils/Routes';
import MFlags from '../../models/MFlags';
import { G5_MICRO_APP, SUPPORTED_TEST_TYPE, TEST_TYPE, TestProjectType } from '../../utils/Constants';
import MicroApp from '../../components/MicroApp';
import MConfigs from '../../models/MConfigs';
import TestCaseObjHelper from '../../utils/TestCaseObjHelper';
import MAuth from '../../models/MAuth';
import PublishTestCaseDialog
  from '../../components/cloud-studio-component/testcase/PublishTestCaseDialog';
import TestCaseAutoUploader
  from '../../components/cloud-studio-component/service/TestCaseAutoUploader';
import TestCaseService from '../../components/cloud-studio-component/service/TestCaseService';
import SpinnerPage from '../../auth/SpinnerPage';
import { TestType } from '../../models/model/TestType';
import MicroComponent from '../../components/MicroComponent';

class TestCase extends PageComponent {

  constructor(props) {
    super(props);
    this.testCaseId = MContext.testCaseId;
    if (this.testCaseId) {
      this.meta.id = 'page-update-testcase';
    }
    this.state = {
      formData: null,
      isOpenPublishDraftDialog: false,
      isLoaded: false,
    };
    this.getTestCase = this.getTestCase.bind(this);
    this.getTestCaseContent = this.getTestCaseContent.bind(this);
    this.onClosePublishTestCaseDialog = this.onClosePublishTestCaseDialog.bind(this);

    const [autoUploadQueue] = TestCaseAutoUploader.init({
      queue: {
        onFailure: () => TestCaseService.handleAfterUploadFailed(),
        onSuccess: (item) => TestCaseService.handleAfterUploadSucceeded(item.lastUpdated),
      },
    });
    this.autoUploadQueue = autoUploadQueue;
    this.onEditedContent = this.onEditedContent.bind(this);
    this.onOnline = this.onOnline.bind(this);
  }

  componentDidMount() {
    if (MFlags.g5Editor) {
      // always overwrite local data with cloud data if auto save is enabled
      // no further check is required
      this.getValidTestCase();
    } else {
      this.getTestCase();
    }
    document.addEventListener(G5_MICRO_APP.publishDraftTestCase, () =>
      this.setState({ isOpenPublishDraftDialog: true }));

    document.addEventListener(
      G5_MICRO_APP.editedContentG5TestCase,
      this.onEditedContent
    );
    window.addEventListener('online', this.onOnline);
  }

  componentWillUnmount() {
    document.removeEventListener(G5_MICRO_APP.editedContentG5TestCase, this.onEditedContent);
    window.removeEventListener('online', this.onOnline);
  }

  isUnsupportedTestCase(testCase) {
    const { removeGitSupportForCloudStudioEnabled } = MFlags;
    if (!testCase.testProject) {
      // test case in uploaded data
      return true;
    }
    if (!SUPPORTED_TEST_TYPE.includes(testCase.testType)) {
      // manual test case
      return true;
    }
    // g5 test case in git
    return removeGitSupportForCloudStudioEnabled && testCase.testProject.type === TestProjectType.GIT && testCase.testType === TEST_TYPE.G5_TEST_CASE;
  }

  getValidTestCase() {
    Services.getValidTestCaseById(this.testCaseId)
      .then((testCase) => {
        this.meta.title = t('Test Case {{name}}', { name: testCase.name });
        if (this.isUnsupportedTestCase(testCase)) {
          Routes.goToTestCaseLink(this.testCaseId);
        }
        this.setState({
          formData: { ...testCase },
        }, () => {
          if (testCase.testType === TestType.G5_TEST_CASE) {
            this.getTestCaseContent();
          } else {
            this.setState({
              isLoaded: true
            });
          }
        });
      });
  }

  getTestCaseContent() {
    if (!MFlags.g5Editor) return;
    const { testProject, description, type, createdAt, updatedAt } = this.state.formData;
    Services.getTestCase(this.testCaseId)
      .then((testcase) => {
        const newTestCase = {
          id: this.testCaseId,
          description,
          scriptRepoId: testProject.id,
          baseCommitId: testProject.commitId,
          userId: MAuth.user.id,
          projectId: MContext.projectId,
          testProject,
          name: testcase.name,
          path: testcase.path,
          content: testcase.content,
          type,
          testType: testcase.testType,
          executionType: testcase.executionType,
          createdAt: new Date(createdAt),
          lastUpdated: new Date(updatedAt),
          lastSynced: new Date(updatedAt),
        };
        TestCaseObjHelper.updateOrCreate(newTestCase)
          .then(() => {
            this.setState({
              isNewDraftTestCase: false,
              isLoaded: true,
            });
          });
      });
  }

  onClosePublishTestCaseDialog() {
    this.setState({ isOpenPublishDraftDialog: false });
  }

  async onEditedContent() {
    const testCaseId = parseInt(this.testCaseId, 10);
    const testCaseData = await TestCaseObjHelper.get(this.testCaseId);
    TestCaseAutoUploader.publishTestCase(
      this.autoUploadQueue,
      testCaseId,
      testCaseData
    );
  }

  onOnline() {
    this.autoUploadQueue.retry();
  }

  getTestCase() {
    const params = {
      pagination: {
        page: 0,
        size: 1,
        sorts: 'order,desc',
      },
      conditions: [
        {
          key: 'id',
          operator: '=',
          value: this.testCaseId,
        },
      ],
      type: 'TestCase',
    };
    Services.search(params)
      .then(({ content }) => {
        const testCase = content[0];
        this.meta.title = t('Test Case {{name}}', {
          name: testCase.name,
        });
        if (testCase) {
          this.setState({
            formData: { ...testCase }
          });
        }
      });
  }

  renderEditor() {
    const { isOpenPublishDraftDialog, isNewDraftTestCase } = this.state;
    return (
      <EditorContainer>
        <MicroApp id="katalon-authoring" path={MConfigs.microappAuthoringDomain} />
        {isOpenPublishDraftDialog &&
          <PublishTestCaseDialog
            draftTestCaseId={this.testCaseId}
            isOpen={isOpenPublishDraftDialog}
            onClose={this.onClosePublishTestCaseDialog}
            isNewDraftTestCase={isNewDraftTestCase}
          />}
      </EditorContainer>
    );
  }

  renderVisualEditor() {
    const { formData } = this.state;
    return (
      <EditorContainer>
        <MicroComponent
          appId="katalon-authoring"
          path={MConfigs.microappAuthoringDomain}
          componentId="VisualEditor"
          props={{ testCase: formData }}
        />
      </EditorContainer>
    );
  }

  render() {
    const { isLoaded, formData } = this.state;
    if (!isLoaded) {
      return <SpinnerPage />;
    }
    if (MFlags.g5Editor) {
      if (formData.testType === TestType.G5_TEST_CASE) {
        return this.renderEditor();
      } else if (formData.testType === TestType.G4_TEST_CASE && MFlags.editorTrueTestEnabled) {
        return this.renderVisualEditor();
      }
    } else {
      Routes.goToTestCaseLink(this.testCaseId);
    }
    return null;
  }
}

export default TestCase;

const EditorContainer = styled('div')(() => ({
  height: '100%',
  width: '100%',
  padding: 16
}));
