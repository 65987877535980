import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  IconButtonCollapse,
  IconButtonCollapseHovered,
  IconButtonExpand,
  IconButtonExpandHovered,
} from '../images/CustomNewIcon';

/**
 * Render expand or collapse button, change icon color on hover
 * Props:
 *   expanded: boolean, whether to return an expand button
 *   onExpand, onCollapse: event handlers
 */
function ExpandOrCollapseButton(props) {

  const {
    expanded,
    onExpand,
    onCollapse
  } = props;

  const [hovered, setHovered] = useState(false);

  const handleExpand = () => {
    onExpand();
    setHovered(false);
  };

  const handleCollapse = () => {
    onCollapse();
    setHovered(false);
  };

  const renderButton = (expanded, hovered) => {
    if (expanded && hovered) {
      return <IconButtonCollapseHovered />;
    }
    if (expanded && !hovered) {
      return <IconButtonCollapse />;
    }
    if (!expanded && hovered) {
      return <IconButtonExpandHovered />;
    }
    return <IconButtonExpand />;
  };

  return (
    <div
      className="resize-collapse-button"
      onClick={props.expanded ? handleCollapse : handleExpand}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      {renderButton(expanded, hovered)}
    </div>
  );
}

ExpandOrCollapseButton.propTypes = {
  expanded: PropTypes.bool,
  onExpand: PropTypes.func,
  onCollapse: PropTypes.func,
};

ExpandOrCollapseButton.defaultProps = {
  expanded: true,
  onExpand: null,
  onCollapse: null
};

export default ExpandOrCollapseButton;
