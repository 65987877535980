import FormControl from '@mui/material/FormControl';
import InputBase from '@mui/material/InputBase';
import React from 'react';
import { Alert, Button, Form, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import DefaultLayout from '../components/DefaultLayout';
import Input from '../components/Input';
import PageButtonToolbar from '../components/PageButtonToolbar';
import PageComponent from '../components/PageComponent';
import ObjectSummary from '../components/summary/ObjectSummary';
import DataTable from '../components/table/DataTable';
import MTableColumnDataMapping from '../components/table/models/MTableColumnDataMapping';
import { t } from '../i18n/t';
import { IconCopy, IconDelete, IconHidden, IconVisible } from '../images/KitIcons';
import DecoratorConstants from '../utils/DecoratorConstants';
import Notification from '../utils/Notification';
import Services from '../utils/Services';
import Select from '../components/Select';
import DatePicker from '../../app/components/DatePicker';
import Time from '../utils/Moment';

class ApiKey extends PageComponent {

  constructor(props) {
    super(props);
    this.meta.id = 'page-api-key';
    this.meta.title = t('agent#api-key');
    this.selectedKeyId = null;
    this.keyName = null;
    this.apiKeyList = null;
    this.state = {
      createApiKeyDialog: false,
      deleteApiKeyDialog: false,
      openDatePicker: false,
      apiKeyVisibility: {},
      keyName: null,
      expiryDate: -2,
      datePickerTime: new Date().setDate(new Date().getDate() + 1),
    };

    this.expiryDateOptions = this.generateExpiryDateOptions();
    this.createApiKey = this.createApiKey.bind(this);
    this.deleteApiKey = this.deleteApiKey.bind(this);
    this.openDeleteApiKeyDialog = this.openDeleteApiKeyDialog.bind(this);
    this.deleteApiKeyDialogToggle = this.deleteApiKeyDialogToggle.bind(this);
    this.openCreateApiKeyDialog = this.openCreateApiKeyDialog.bind(this);
    this.createApiKeyDialogToggle = this.createApiKeyDialogToggle.bind(this);
    this.refreshApiKeyTable = this.refreshApiKeyTable.bind(this);
    this.changeKeyName = this.changeKeyName.bind(this);
    this.copyToClipboard = this.copyToClipboard.bind(this);
    this.renderHeader = this.renderHeader.bind(this);
    this.renderBody = this.renderBody.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.generateExpiryDateOptions = this.generateExpiryDateOptions.bind(this);
    this.onChangeExpiryDate = this.onChangeExpiryDate.bind(this);
    this.getLocalTimeExpiryDate = this.getLocalTimeExpiryDate.bind(this);
    this.renderLabelOrDateTimePicket = this.renderLabelOrDateTimePicket.bind(this);
    this.handleDatePickerChange = this.handleDatePickerChange.bind(this);
    this.calculateExpiryDate = this.calculateExpiryDate.bind(this);
    this.handleOnClick = this.handleOnClick.bind(this);
  }

  createApiKey(e) {
    e.preventDefault();

    let errMsg;
    if (!this.state.keyName || this.state.keyName.trim() === '') {
      errMsg = 'The key name should not be empty or spaces';
    } else if (this.state.expiryDate !== -2 && this.calculateExpiryDate() < new Date()) {
      errMsg = 'The expiration date must be in the future';
    }
    if (errMsg) {
      Notification.pushErrorTimeOut(
        errMsg,
        'Validate failed!',
        2000
      );
      return;
    }
    const params = {
      name: this.state.keyName,
      expiryDate: this.calculateExpiryDate(),
    };

    Services.createApiKey(params)
      .then((apiKey) => {
        this.copyToClipboard(apiKey.key);
        this.afterUpdate();
        Notification.pushSuccess('API Key has been created successfully and copied to your clipboard.');
      }).catch(() => {});
    this.createApiKeyDialogToggle();
  }

  deleteApiKey(e) {
    e.preventDefault();
    Services.removeApiKey(this.selectedKeyId)
      .then(() => {
        Notification.pushSuccess('Delete successfully.');
        this.afterUpdate();
      });
    this.deleteApiKeyDialogToggle();
  }

  afterUpdate() {
    this.refreshApiKeyTable();
  }

  openDeleteApiKeyDialog(keyId) {
    this.selectedKeyId = keyId;
    this.setState({
      deleteApiKeyDialog: true,
    });
  }

  deleteApiKeyDialogToggle() {
    const { deleteApiKeyDialog } = this.state;
    this.setState({
      deleteApiKeyDialog: !deleteApiKeyDialog,
    });
  }

  openCreateApiKeyDialog() {
    this.setState({
      createApiKeyDialog: true,
    });
  }

  createApiKeyDialogToggle() {
    const { createApiKeyDialog } = this.state;
    this.setState({
      createApiKeyDialog: !createApiKeyDialog,
      expiryDate: -2,
      keyName: null,
      datePickerTime: new Date().setDate(new Date().getDate() + 1)
    });
  }

  changeKeyName(event) {
    event.preventDefault();
    this.setState({ keyName: event.target.value });
  }

  copyAPIKeyToClipboard(apiKey) {
    this.copyToClipboard(apiKey);
    Notification.pushSuccess('API Key has been copied to your clipboard.');
  }

  handleInputChange(name, value) {
    const isValidDate = value?.isValid();
    if (isValidDate) {
      this.setState({
        [name]: value
      });
    }
  }

  copyToClipboard(content) {
    const el = document.createElement('textarea');
    el.value = content;
    el.setAttribute('readonly', '');
    el.style = { display: 'none' };
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  }

  refreshApiKeyTable() {
    this.apiKeyList.refreshData();
  }

  calculateExpiryDate() {
    let expiryDate;
    switch (this.state.expiryDate) {
      case -2:
        expiryDate = null;
        break;
      case -1: {
        const exp = new Date(this.state.datePickerTime);
        exp.setHours(0);
        exp.setMinutes(0);
        exp.setSeconds(0);
        expiryDate = exp.getTime();
        break;
      }
      default:
        expiryDate = this.getLocalTimeExpiryDate().getTime();
        break;
    }
    return expiryDate;
  }

  onChangeExpiryDate(event, option) {
    this.setState({
      expiryDate: option.value,
    });
  }

  generateExpiryDateOptions() {
    return [
      {
        value: 7,
        label: '7 days',
      },
      {
        value: 30,
        label: '30 days',
      },
      {
        value: 60,
        label: '60 days',
      },
      {
        value: 90,
        label: '90 days',
      },
      {
        value: -1,
        label: 'Custom',
      },
      {
        value: -2,
        label: 'No expiration',
      }
    ];
  }

  getLocalTimeExpiryDate() {
    const date = new Date();
    date.setDate(date.getDate() + this.state.expiryDate);
    return date;
  }

  handleDatePickerChange(value) {
    this.setState({ datePickerTime: value });
  }

  handleClose() {
    const state = this.state.openDatePicker;
    this.setState({ openDatePicker: !state });
  }

  renderLabelOrDateTimePicket() {
    if (this.state.expiryDate === -2) {
      return (
        <Label for="key" data-testid="api-key-active-lbl" className="expiry-explain">
          {t('api_key#expiry_explain')}
        </Label>
      );
    } else if (this.state.expiryDate === -1) {
      return (
        <DatePicker
          open={this.state.openDatePicker}
          id="expirationDatePicker"
          name="expirationDatePicker"
          dataTestId="expiry-date-dpk"
          className="date-picker"
          inputFormat={Time.DATE_FORMAT}
          maxDate={Time.MAX_DATE}
          minDate={Time.now().add(1, 'days')}
          value={this.state.datePickerTime}
          onChange={(value) => this.handleDatePickerChange(value)}
        />
      );
    } else {
      return (
        <Label for="key" data-testid="api-key-active-lbl" className="expiry-explain">
          {t('api_key#expiry_on') + this.getLocalTimeExpiryDate()
            .toLocaleDateString('en-us', { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' })}
        </Label>
      );
    }
  }

  handleOnClick(e) {
    if ((e.target.tagName === 'DIV' && e.target.getAttribute('aria-live') === 'polite')
      || (e.target.tagName === 'svg' && e.target.getAttribute('data-testid') !== null)) {
      return;
    }
    if (
      e.target.tagName === 'path'
      || e.target.id === 'expirationDatePicker'
      || (e.target.tagName === 'svg' && e.target.getAttribute('data-testid') === null)
      || (e.target.tagName === 'BUTTON' && !e.target.innerText)
    ) {
      const openDatePicker = this.state.openDatePicker;
      this.setState({
        openDatePicker: !openDatePicker
      });
    } else {
      this.setState({
        openDatePicker: false
      });
    }
  }

  renderCreateApiKeyDialog() {
    return (
      <Modal className="api-key-modal" isOpen={this.state.createApiKeyDialog} toggle={this.createApiKeyDialogToggle} onClick={(e) => this.handleOnClick(e)}>
        <Form className="api-form" data-trackid="create-api-key" onSubmit={this.createApiKey}>
          <ModalHeader>{t('api_key#create_new_key')}</ModalHeader>
          <ModalBody>
            <FormGroup>
              <Label>{t('api_key#key_name')}</Label>
              <Input
                type="text"
                name="key_name"
                id="key_name"
                value={this.state.keyName}
                placeholder="Key Name"
                onChange={this.changeKeyName}
              />
              <div>
                <Label>{t('api_key#expiration')}</Label>
                <div className="expiry-section">
                  <Select
                    dataTestId="create-api-key-sel"
                    clearable={false}
                    onChange={this.onChangeExpiryDate}
                    options={this.expiryDateOptions}
                    defaultValue={this.expiryDateOptions[5]}
                    useAutocomplete
                    disableClearable
                  />
                  {this.renderLabelOrDateTimePicket()}
                </div>
              </div>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.createApiKeyDialogToggle}>{t('cancel')}</Button>
            <Button date-testid="create-api-key-btn" type="submit" color="primary">{t('api_key#create')}</Button>
          </ModalFooter>
        </Form>
      </Modal>
    );
  }

  renderDeleteApiKeyDialog() {
    return (
      <Modal isOpen={this.state.deleteApiKeyDialog} toggle={this.deleteApiKeyDialogToggle}>
        <Form data-trackid="delete-api-key" onSubmit={this.deleteApiKey}>
          <ModalHeader>{t('api_key#delete_api')}</ModalHeader>
          <ModalBody>
            {t('api_key#delete_notice')}
          </ModalBody>
          <ModalFooter>
            <Button type="submit" color="danger">{t('delete')}</Button>
            <Button color="secondary" onClick={this.deleteApiKeyDialogToggle}>{t('cancel')}</Button>
          </ModalFooter>
        </Form>
      </Modal>
    );
  }

  renderButtonToolbar() {
    return (
      <PageButtonToolbar>
        <Button
          data-trackid="generate-api-key"
          title="Generate API Key"
          color="secondary"
          onClick={() => this.openCreateApiKeyDialog()}
        >
          {t('api_key#create_new_key')}
        </Button>
      </PageButtonToolbar>
    );
  }

  toggleVisibility(id) {
    this.setState(({ apiKeyVisibility: prevVisibility }) => ({
      apiKeyVisibility: {
        ...prevVisibility,
        [id]: !prevVisibility[id],
      },
    }));
  }

  renderApiKeyTable() {
    const { apiKeyVisibility } = this.state;
    const headers = [
      new MTableColumnDataMapping(
        t('name'),
        'name',
        undefined,
        undefined,
        'apikey-column'
      ),
      new MTableColumnDataMapping(
        t('api_key#key'),
        'key',
        (name, row) => {
          const { id, key, expiryDate } = row;
          let disabled = false;
          if (expiryDate) {
            disabled = Date.parse(expiryDate) < Date.now();
          }
          return (
            <div className="d-flex">
              <div>
                <FormControl
                  disabled={disabled}
                  fullWidth
                  onClick={() => this.toggleVisibility(id)}
                >
                  <InputBase
                    readOnly
                    type={!apiKeyVisibility[id] ? 'password' : 'text'}
                    value={key}
                    autoComplete="off"
                    margin="dense"
                    className="secret-input"
                  />
                </FormControl>
              </div>
              <div>
                <Button
                  data-trackid="toggle-apikey-visibility"
                  title={!apiKeyVisibility[id] ? 'Show API Key' : 'Hide API Key'}
                  color="link"
                  disabled={disabled}
                  onClick={() => this.toggleVisibility(id)}
                >
                  {!apiKeyVisibility[id] ? <IconVisible /> : <IconHidden />}
                </Button>
              </div>
            </div>
          );
        },
        undefined,
        'apikey-column'
      ),
      new MTableColumnDataMapping(
        t('api_key#last_used'),
        'lastUsed',
        DecoratorConstants.timeDecorator,
        undefined,
        'apikey-column'
      ),
      new MTableColumnDataMapping(
        t('api_key#created_at'),
        'createdAt',
        DecoratorConstants.timeDecorator,
        undefined,
        'apikey-column'
      ),
      new MTableColumnDataMapping(
        t('api_key#expiration_date'),
        'expiryDate',
        (name, row) => {
          if (row.expiryDate) {
            return DecoratorConstants.timeDecorator(name, row);
          } else {
            return 'N/A';
          }
        },
        undefined,
        'apikey-column'
      ),
      new MTableColumnDataMapping(
        t('api_key#status'),
        'Status',
        (name, row) => {
          if (typeof row.expiryDate === 'undefined' || Date.parse(row.expiryDate) > Date.now()) {
            return (
              <Label for="key" date-testid="api-key-active-lbl" className="apikey-status-label" style={{ backgroundColor: '#0F8461' }}>
                {t('api_key#active')}
              </Label>
            );
          } else {
            return (
              <Label for="key" date-testid="api-key-expired-lbl" className="apikey-status-label" style={{ backgroundColor: '#D24720' }}>
                {t('api_key#expired')}
              </Label>
            );
          }
        },
        undefined,
        'apikey-column'
      ),
      new MTableColumnDataMapping(
        t('api_key#action'),
        'id',
        (name, row) => {
          const { id, key, expiryDate } = row;
          let disabled = false;
          if (expiryDate) {
            disabled = Date.parse(expiryDate) < Date.now();
          }
          return (
            <div>
              <Button
                data-trackid="remove-api-key"
                title="Remove API Key"
                color="link"
                onClick={() => this.openDeleteApiKeyDialog(id)}
              >
                <IconDelete />
              </Button>
              <Button
                data-trackid="copy-api-key"
                title="Copy to Clipboard"
                color="link"
                disabled={disabled}
                onClick={() => this.copyAPIKeyToClipboard(key)}
              >
                <IconCopy />
              </Button>
            </div>
          );
        },
        true,
        'apikey-column'
      ),
    ];
    return (
      <div>
        <Alert color="primary">{t('api_key#intro')}</Alert>
        <DataTable
          className="api-key-table"
          ref={(ref) => {
            this.apiKeyList = ref;
          }}
          entityType="ApiKey"
          columnMapping={headers}
          title=""
        />
        {this.renderCreateApiKeyDialog()}
        {this.renderDeleteApiKeyDialog()}
      </div>
    );
  }

  renderObjectSummary() {
    const urlParams = {
      apikey: t('agent#api-key'),
    };
    return (
      <ObjectSummary params={urlParams} />
    );
  }

  renderHeader() {
    return (
      <>
        {this.renderObjectSummary()}
      </>
    );
  }

  renderBody() {
    return (
      <>
        {this.renderButtonToolbar()}
        {this.renderApiKeyTable()}
      </>
    );
  }

  render() {
    return (
      <DefaultLayout
        renderHeader={this.renderHeader}
        renderBody={this.renderBody}
      />
    );
  }
}

export default ApiKey;
