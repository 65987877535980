

import _ from 'lodash';

export default class MPaginationMapping {
  constructor(options = {}) {
    const defaultOptions = {
      totalItems: 'totalElements',
      itemsPerPage: 'size',
      totalPage: 'totalPages',
      currentPage: 'number',
    };
    _.assign(this, defaultOptions, _.pick(options, _.keys(defaultOptions)));
  }
}
