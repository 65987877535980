import React from 'react';
import PropTypes from 'prop-types';
import { t } from '../../../i18n/t';
import DataLoader from '../../../components/table/DataLoader';
import { buildSearchCondition } from '../../../components/search/SearchUtils';
import Routes from '../../../utils/Routes';
import DecoratorConstants from '../../../utils/DecoratorConstants';
import { SearchEntity } from '../../../utils/Constants';
import CardBorder from '../../../components/card/CardBorder';

const TestSchedules = ({ groupOrder }) => {
  const renderTestSchedules = (runConfiguration) => {
    const decorator = (runConfiguration) => {
      const routes = new Routes({ runConfigurationId: runConfiguration.id });
      const runConfigurationLink = routes.run_configuration_link;
      return DecoratorConstants.idDecorator('name', runConfiguration, runConfigurationLink, undefined, 'mb-2', undefined, true);
    };

    const schedulerLinks = runConfiguration.map(decorator);
    return <div>{schedulerLinks}</div>;
  };

  const renderData = () => (
    <DataLoader
      noCard
      entityType={SearchEntity.RunConfiguration}
      pageSize={15}
      defaultSearchConditions={[
        buildSearchCondition('BaselineCollectionGroup.order', '=', groupOrder)
      ]}
      showEmptyMessage
      render={renderTestSchedules}
    />
  );

  return (
    <CardBorder>
      <div className="card-border-title">
        {t('test-schedules')}
      </div>
      <div className="mb-4">
        {renderData()}
      </div>
    </CardBorder>
  );

};

TestSchedules.propTypes = {
  groupOrder: PropTypes.number.isRequired,
};

export default TestSchedules;
