import React from 'react';
import { DialogContent, DialogContentText, DialogActions } from '@mui/material';
import { Button, ButtonToolbar } from 'reactstrap';
import CloseableDialogComponent from './CloseableDialogComponent';
import { t } from '../../i18n/t';

export default function DismissOnboardingChecklistDialog({ isOpen, handleClose, handleDismiss }) {

  return (
    <CloseableDialogComponent
      maxWidth="sm"
      isOpen={isOpen}
      handleClose={handleClose}
      title={<div className="title-dismiss-dialog">{t('onboarding-dismiss-dialog#title')}</div>}
    >
      <DialogContent>
        <DialogContentText>
          <div className="title m-0 text-left">
            {t('onboarding-dismiss-dialog#description')}
          </div>
        </DialogContentText>
      </DialogContent>
      <DialogActions className="popup-footer justify-content-end p-0">
        <ButtonToolbar>
          <Button
            id="cancel-btn"
            title={<div className="btn-dismiss-dialog">{t('cancel')}</div>}
            color="secondary"
            onClick={handleClose}
          >
            {t('cancel')}
          </Button>
          <Button
            id="dismiss-btn"
            title={<div className="btn-dismiss-dialog">{t('onboarding-dismiss-dialog#dismiss-btn')}</div>}
            color="warning"
            onClick={handleDismiss}
          >
            {t('onboarding-dismiss-dialog#dismiss-btn')}
          </Button>
        </ButtonToolbar>
      </DialogActions>
    </CloseableDialogComponent>
  );
}
