import React from 'react';
import ReactSelect, { components } from 'react-select';
import PropTypes from 'prop-types';
import { Autocomplete } from '@mui/material';
import Input from './Input';
import { sendAnalyticEventForAction } from '../utils/SegmentAnalytics';
import TextField from './TextField';
import { IconArrowDownV2 } from '../images/CustomIcon';

const { Option } = components;

// Custom in options
export function CustomSelectOption(props) {
  return (
    <>
      <Option {...props} className="d-flex justify-content-between">
        <div className="d-flex">
          <div className="mr-1 d-flex align-items-center">
            {(props.isSelected && props.data.selectedIcon) ? props.data.selectedIcon : props.data.icon}
          </div>
          <div className="d-flex align-items-center custom-option-label">{props.data.label}</div>
          {(props.data.postBadge) &&
            <div className="ml-2 d-flex align-items-center">
              {props.data.postBadge}
            </div>}
        </div>
        <div className="d-flex">
          {(props.data.uploadedDate) &&
          <div className="ml-2 custom-select-right-text text-selected">
            {props.data.uploadedDate}
          </div>}
          {(props.data.rightIcon) &&
          <div className="mr-1 d-flex align-items-center">{props.data.rightIcon}</div>}
        </div>
      </Option>
      {props.data.divider && <div className="select-divider" />}
    </>
  );
}

// Custom in selected value
export function CustomSelectValue(props) {
  return (
    <div {...props} className="custom-select-value d-flex justify-content-between">
      <div className="d-flex">
        <div className="mr-1 d-flex align-items-center">
          {props.data.icon}
        </div>
        <div className="d-flex align-items-center">{props.data.label}</div>
        {(props.data.postBadge) &&
          <div className="ml-2 d-flex align-items-center">
            {props.data.postBadge}
          </div>}
      </div>
      <div className="d-flex">
        {(props.data.uploadedDate) &&
        <div className="ml-2 custom-select-right-text text-selected">
          {props.data.uploadedDate}
        </div>}
      </div>
    </div>
  );
}

const customSelectShape = PropTypes.shape({
  icon: PropTypes.element,
  label: PropTypes.string,
  postBadge: PropTypes.element,
  rightIcon: PropTypes.element,
  selectedIcon: PropTypes.element,
  divider: PropTypes.bool,
  uploadedDate: PropTypes.element,
}).isRequired;

CustomSelectOption.propTypes = {
  data: customSelectShape,
};

CustomSelectValue.propTypes = {
  data: customSelectShape,
};

function CustomInput(props) {
  return <components.Input {...props} autoComplete="none" />;
}

/**
 * ReactSelect doesn't pass original props to the container that makes tracking doesn't work.
 * We can pass tracking props to SelectContainer from components prop but making duplicated events.
 * Solution: Directly send tracking event from onMenuOpen listener.
 */
const sendTrackingEvent = (props) => {
  const dataTrackId = props['data-trackid'];
  const dataGroupId = props['data-groupid'];
  if (!dataTrackId) {
    return;
  }
  sendAnalyticEventForAction(dataTrackId, { 'data-groupid': dataGroupId });
};

function Select(props) {
  const { value, options, useAutocomplete, maxMenuHeight, dataTestId } = props;
  let option = value;
  if (!value?.value && options) {
    option = options.find((option) => option.value === value);
  }
  if (!option) {
    option = { value: undefined, label: '' };
  }
  const newProps = {
    ...props,
    value: option,
  };

  // Check useAutocomplete to render new Select
  if (useAutocomplete) {
    return (
      <Autocomplete
        id={newProps.id || 'auto-complete'}
        renderInput={(params) =>
          <TextField
            data-testid={dataTestId}
            inputProps={{
              'data-testid': dataTestId,
            }}
            {...params}
            placeholder={props.placeholder || ''}
          />}
        {...newProps}
        popupIcon={<IconArrowDownV2 className="mt-2" />}
        fullWidth
      />
    );
  }

  const handleChange = (e) => {
    if (e) {
      const { track } = e;
      if (track) {
        const { id, groupId } = track;
        sendAnalyticEventForAction(id, {
          'data-groupid': groupId
        });
      }
    }
    if (typeof props.onChange === 'function') {
      props.onChange(e);
    }
  };
  return (
    <>
      <ReactSelect
        {...newProps}
        menuPosition={newProps.hasOverflowParent && 'fixed'}
        menuPortalTarget={newProps.hasOverflowParentDiaglog}
        id={newProps.id || 'react-select-2'}
        className="react-select-container"
        classNamePrefix="react-select"
        onChange={handleChange}
        components={
          newProps.isCustom ? {
            Option: CustomSelectOption,
            SingleValue: CustomSelectValue,
            Input: CustomInput
          } : {
            Input: CustomInput
          }
        }
        onMenuOpen={() => {
          sendTrackingEvent(props);
          if (props.onOpen) {
            props.onOpen();
          }
        }}
        maxMenuHeight={maxMenuHeight}
      />
      <Input
        className="react-select-required-validation"
        required={props.required}
        value={newProps.value}
        tabIndex={-1}
        /**
        * Customize string as Chrome think autocomplete="off" should be ignored (TOS-1273)
        */
        autoComplete="none"
      />
    </>
  );
}

Select.propTypes = {
  /**
   * CustomSelectValue and CustomSelectOption with icon and label
   * Field 'options' should need {icon, label, value}
      Ex: value: testProject.id,
          icon: <IconKSRepo />,
          label: testProject.name,
   * Default: false: components is null
   */
  isCustom: PropTypes.bool,
  /**
   * Add property menuPortalTarget to pop up on the target element in DOM that we want to put dropdown on
   * default is null, mean it will auto find the inputto dropdown
   *  Ex: document.querySelector('.MuiDialog-container') (DOM element to be place this select on )
   *  Default: null: place on parent DOM element
   */
  hasOverflowParentDiaglog: PropTypes.object,
  /**
   * Props decide use Autocomplete of MUI
   */
  useAutocomplete: PropTypes.bool,
  /**
   * Props to limit the height of the menu
   */
  maxMenuHeight: PropTypes.number,
  /**
   * Props add data test id
   */
  dataTestId: PropTypes.string,
};

Select.defaultProps = {
  useAutocomplete: false,
};

export default Select;
