import React from 'react';
import { styled } from '@mui/material';
import PropTypes from 'prop-types';
import { Dialog, DialogBody, DialogFooter, DialogHeader } from '@katalon-studio/katalon-ui/Dialog';
import { Icon } from '@katalon-studio/katalon-ui';
import Button from '@katalon-studio/katalon-ui/Button';

export default function MigrationDialog({ open, onClose }) {
  return (
    <Dialog maxWidth="sm" open={open} onClose={onClose}>
      <DialogHeader onClick={onClose}>Draft Test Cases moved to Katalon Cloud</DialogHeader>
      <DialogBody sx={{ display: 'flex' }}>
        <Icon
          type="fa-solid"
          name="fa-info-circle"
          color="primary"
          textSize="24px"
          boundingSize="24px"
        />
        <DialogContent>
          <div>
            We are pleased to inform you that all of your draft test cases have been automatically
            moved to the Katalon Cloud!
          </div>
          <div>Now you can easily access and manage them from anywhere.</div>
        </DialogContent>
      </DialogBody>
      <DialogFooter>
        <a
          href="https://docs.katalon.com/docs/release-notes/katalon-cloud-studio/katalon-cloud-studio-beta-release"
          target="_blank"
          rel="noopener noreferrer"
        >
          <StyledButton
            size="medium"
            variant="text"
            onClick={onClose}
            endIcon={<Icon type="fa-solid" name="fa-up-right-from-square" />}
            id="g5_migration_learn_more_button"
          >
            Learn More
          </StyledButton>
        </a>

        <Button
          size="medium"
          variant="contained"
          color="primary"
          onClick={onClose}
          id="g5_migration_got_it_button"
        >
          Got It
        </Button>
      </DialogFooter>
    </Dialog>
  );
}

const DialogContent = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: theme.spacing(2),
  paddingLeft: theme.spacing(6),
}));

const StyledButton = styled(Button)(({ theme }) => ({
  color: theme.palette.secondary.contrastText,

  '& > .MuiButton-endIcon': {
    display: 'block',
  },
}));

MigrationDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

MigrationDialog.defaultProps = {
  open: false,
  onClose: () => {},
};
