import React from 'react';
import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';

class SidebarLayout extends React.Component {
  render() {
    const { renderMain, renderToolbar, renderSidebar } = this.props;
    return (
      <Row className="sidebar-layout">
        <Col>
          {renderToolbar && (
            <Row className="sidebar-layout__toolbar-wrapper">
              <div className="sidebar-layout__toolbar">{renderToolbar()}</div>
            </Row>
          )}
          <Row>
            <Col md={9} className="sidebar-layout__main-content">
              {renderMain()}
            </Col>
            <Col md={3} className="sidebar-layout__right-sidebar">
              {renderSidebar()}
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

SidebarLayout.propTypes = {
  renderMain: PropTypes.func,
  renderSidebar: PropTypes.func,
};


export default SidebarLayout;
