import React from 'react';
import { DialogContent, DialogContentText, DialogActions } from '@mui/material';
import CloseableDialogComponent from './CloseableDialogComponent';
import { t } from '../../i18n/t';
import { Button, ButtonToolbar } from 'reactstrap';

export default function ConfirmationTestProjectDialog({ isOpen, handleClose, handleNo, handleConfirmation }) {
  return (
    <CloseableDialogComponent
      isOpen={isOpen}
      handleClose={handleClose}
      title={t('test-project#title-confirmation-test-project-dialog')}
    >
      <DialogContent>
        <DialogContentText>
          <div className="title m-0 text-left">
            {t('test-project#text-confirmation-test-project')}
          </div>
        </DialogContentText>
      </DialogContent>
      <DialogActions className="popup-footer justify-content-end p-0">
        <ButtonToolbar>
          <Button
            id="no-confirmation-test-project-dialog"
            title={t('no')}
            color="secondary"
            onClick={handleNo}
            data-trackid="no-confirmation-test-project-dialog"
          >
            {t('no')}
          </Button>
          <Button
            id="button-confirmation-test-project"
            title={t('test-project#button-confirmation-test-project')}
            color="primary"
            onClick={handleConfirmation}
          >
            {t('test-project#button-confirmation-test-project')}
          </Button>
        </ButtonToolbar>
      </DialogActions>
    </CloseableDialogComponent>
  );
}
