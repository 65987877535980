import { PublishTestSuite } from '../../../models/model/PublishTestSuite';
import { TestCase } from '../../../models/model/TestCase';
import { TestSuite } from '../../../models/model/TestSuite';
import { TestSuiteType, KATALON_EVENTS } from '../../../utils/Constants';
import TestObjectPublisher from '../service/TestObjectPublisher';
import Services from '../../../utils/Services';
import StringHelper from '../../../utils/StringHelper';
import { DomEventHandlers } from '../../../utils/EventHandler';
import { t } from '../../../i18n/t';
import Notification from '../../../utils/Notification';
import { AppHelper } from '../../../utils/AppHelper';
import NotificationHandler from '../handler/NotificationHandler';
import { TestEntity } from '../../../models/model/TestEntity';

export default {

  /**
   * build test suite object to publish
   */
  buildPublishTestSuite(id: number | null, name: string, path: string, testType: string, testCases: TestCase[], deleted?: boolean) : PublishTestSuite {
    return {
      id,
      name,
      path,
      testType,
      testCases,
      deleted
    };
  },

  /**
   * build test suite objects to publish
   */
  buildDeletedTestSuites(testEntities: TestEntity[]) : PublishTestSuite[] {
    return testEntities.map((item) => {
      const { id, name, path, type } = item.testSuite as TestSuite;
      return this.buildPublishTestSuite(id, name, path, type, [], true);
    });
  },

  /**
   * build publish test suite object and publish immediately
   */
  async buildAndPublishTestSuite(
    testSuiteId: number | null,
    name: string,
    path: string,
    testType: string,
    testCases: TestCase[],
    testProjectId: number,
    errorHandler?: void,
    deleted?: boolean
  ) {
    const publishTestSuite: PublishTestSuite = this.buildPublishTestSuite(testSuiteId, name, path, testType, testCases, deleted);
    return TestObjectPublisher.publishTestObject(
      testProjectId,
      [],
      [publishTestSuite],
      '',
      errorHandler
    );
  },

  /**
   * duplicate test suites
   */
  async duplicate(chosenTestSuites: TestEntity[]) {
    const { testProject } = chosenTestSuites[0].testSuite as TestSuite;

    if (!testProject) {
      throw new Error(t('test-project#invalid'));
    }

    AppHelper.openCustomBlockedUI();

    const { id: testProjectId } = testProject;

    const publishTestSuites: PublishTestSuite[] = [];

    const foundDeletedTestSuites: TestSuite[] = [];

    await Promise.all(chosenTestSuites.map(async (testEntity) => {
      const currentTestSuite = testEntity.testSuite as TestSuite;
      const { id: testSuiteId, name, path } = currentTestSuite as TestSuite;
      const testSuites = await Services.getTestSuitesByNameAndPathAndTestProjectId(name, path, testProjectId);
      if (testSuites.length <= 0) {
        foundDeletedTestSuites.push(currentTestSuite);
        return;
      }
      const testSuiteNames = testSuites.map((testSuite: { name: string }) => testSuite.name);
      const incrementalName = StringHelper.generateIncrementalName(name, testSuiteNames);
      const testSuiteTestCases = await Services.getTestCasesByTestSuiteId(testSuiteId);
      const publishTestSuite: PublishTestSuite = this.buildPublishTestSuite(null, incrementalName, path, TestSuiteType.CLOUD_STUDIO, testSuiteTestCases);
      publishTestSuites.push(publishTestSuite);
    }));
    if (foundDeletedTestSuites.length > 0) {
      this.handleCannotDuplicateDeleteTestSuiteError(foundDeletedTestSuites);
      return;
    }
    const duplicateTestSuiteOldErrorHandler: any = (message: string, _label: string, jqXHR: any) =>
      NotificationHandler.duplicateTestSuiteOldErrorHandler(message, jqXHR, publishTestSuites);

    TestObjectPublisher.publishTestObject(
      testProjectId,
      [],
      publishTestSuites,
      '',
      duplicateTestSuiteOldErrorHandler
    ).then(({ testSuites }) => {
      this.handleAfterDuplicated(testSuites);
    });
  },

  /**
   * rename test suite
   */
  async rename(currentTestSuite: TestSuite, newName: string, errorHandler?: void) {
    const { id: testSuiteId, path, testProject } = currentTestSuite;

    if (!testProject) {
      throw new Error(t('test-project#invalid'));
    }

    AppHelper.openCustomBlockedUI();

    const { id: testProjectId } = testProject;

    const testSuiteTestCases = await Services.getTestCasesByTestSuiteId(testSuiteId);
    return this.buildAndPublishTestSuite(
      testSuiteId,
      newName,
      path,
      TestSuiteType.CLOUD_STUDIO,
      testSuiteTestCases,
      testProjectId,
      errorHandler
    ).then(({ testSuites }) => {
      this.handleAfterRename(testSuites);
    }).catch(() => {
      // ignore
    });
  },

  async move(testSuites: TestSuite[], newPath: string, errorHandler?: any) {
    const { testProject } = testSuites[0];

    if (!testProject) {
      throw new Error(t('test-project#invalid'));
    }

    AppHelper.openCustomBlockedUI();

    const { id: testProjectId } = testProject;

    const publishTestSuites: PublishTestSuite [] = [];
    await Promise.all(testSuites.map(async (testSuite) => {
      const { id, name, deleted } = testSuite;
      const testSuiteTestCases = await Services.getTestCasesByTestSuiteId(id);
      publishTestSuites.push(
        this.buildPublishTestSuite(
          id,
          name,
          newPath,
          TestSuiteType.CLOUD_STUDIO,
          testSuiteTestCases,
          deleted
        )
      );
    }));

    TestObjectPublisher.publishTestObject(testProjectId, [], publishTestSuites, '', errorHandler)
      .then(({ testSuites }) => {
        this.handleAfterMove(testSuites);
      })
      .catch(() => {
        // ignore
      });
  },

  /**
   * delete test suites
   */
  async delete(testEntities: TestEntity[]) {
    const { testProject, type } = testEntities[0].testSuite as TestSuite;
    if (!testProject) {
      throw new Error(t('test-project#invalid'));
    }

    AppHelper.openCustomBlockedUI();

    const { id: testProjectId } = testProject;
    const isTypeTestOps = type === TestSuiteType.TESTOPS;
    let request;

    if (isTypeTestOps) {
      const testSuiteIds = testEntities.map((item) => item.testSuite?.id);
      request = Services.deleteTestSuites({ ids: testSuiteIds, runInQueue: false }).then((testSuites) => testSuites);
    } else {
      const deleteTestSuitesErrorHandler: any = (message: string, _label: string, jqXHR: any) =>
        NotificationHandler.deleteTestSuitesErrorHandler(message, jqXHR, testEntities);
      const publishTestSuites = this.buildDeletedTestSuites(testEntities);
      request = TestObjectPublisher.publishTestObject(
        testProjectId,
        [],
        publishTestSuites,
        '',
        deleteTestSuitesErrorHandler
      ).then(({ testSuites }) => testSuites);
    }

    return request.then((testSuites) => {
      this.handleAfterDelete(testSuites);
    });
  },

  handleAfterDelete(testSuites: TestSuite[]) {
    AppHelper.closeCustomBlockedUI();
    DomEventHandlers.createEvent(KATALON_EVENTS.deletedG5TestSuite);
    if (testSuites.length > 1) {
      Notification.pushSuccess(
        t('delete-test-suites#success-description', { testSuiteNumber: testSuites.length }),
        t('delete#success-title')
      );
    } else {
      const testSuite = testSuites[0] as TestSuite;
      Notification.pushSuccess(
        t('delete-test-suite#success-description', { testSuiteName: testSuite.name }),
        t('delete#success-title')
      );
    }

  },

  handleAfterRename(testSuites: TestSuite[]) {
    AppHelper.closeCustomBlockedUI();
    DomEventHandlers.createEvent(KATALON_EVENTS.renamedG5TestSuite);
    const testSuite = testSuites[0];
    Notification.pushSuccess(
      t('rename-test-suite-dialog#success-message', { testSuiteName: testSuite.name }),
      t('test-suite-renamed')
    );
  },

  handleAfterDuplicated(testSuites: TestSuite[]) {
    AppHelper.closeCustomBlockedUI();
    if (testSuites.length === 1) {
      const { name } = testSuites[0];
      Notification.pushSuccess(
        t('duplicate-test-suite#success-description', { testSuiteName: name }),
        t('duplicate-test-suite#success-title')
      );
    } else if (testSuites.length > 1) {
      const testSuiteNumber = testSuites.length;
      Notification.pushSuccess(
        t('duplicate-test-suites#success-description', { testSuiteNumber }),
        t('duplicate-test-suite#success-title')
      );
    }
    DomEventHandlers.createEvent(KATALON_EVENTS.duplicatedG5TestSuite);
  },

  handleCannotDuplicateDeleteTestSuiteError(testSuites: TestSuite[]) {
    AppHelper.closeCustomBlockedUI();
    if (testSuites.length === 1) {
      const { name } = testSuites[0];
      Notification.pushError(
        t('duplicate-test-suite#duplicate-deleted-test-suite-error-description', { testSuiteName: name }),
        t('duplicate-test-suite#failed-title')
      );
    } else if (testSuites.length > 1) {
      const testSuiteNumber = testSuites.length;
      Notification.pushError(
        t('duplicate-test-suites#duplicate-deleted-test-suite-error-description', { testSuiteNumber }),
        t('duplicate-test-suite#failed-title')
      );
    }
  },

  handleAfterMove(testSuites: TestSuite[]) {
    AppHelper.closeCustomBlockedUI();
    DomEventHandlers.createEvent(KATALON_EVENTS.movedG5TestSuite, { detail: { publishedTestSuites: testSuites } });
    if (testSuites.length === 1) {
      const testSuite = testSuites[0];
      Notification.pushSuccess(
        t('moved-test-suite#successfully#message', { testSuiteName: testSuite.name, testSuitePath: testSuite.path }),
        t('moved-test-suite#successfully#title')
      );
    } else {
      Notification.pushSuccess(
        t('moved-list-test-suite#successfully#message', { numberOfTestSuites: testSuites.length, newPath: testSuites[0].path }),
        t('moved-test-suite#successfully#title')
      );
    }
  },
};
