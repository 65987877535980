import Services from '../../../utils/Services';
import { MAX_PAGE_SIZE } from '../../../utils/Constants';

export function filterExecutionFilesByContainedValueInName(executionId, value) {
  return Services.search({
    pagination: {
      page: 0,
      size: MAX_PAGE_SIZE,
      sorts: ['fileName,asc'],
    },
    conditions: [
      {
        key: 'Execution.id',
        operator: '=',
        value: executionId,
      },
      {
        key: 'FileHandle.name',
        operator: 'contains',
        value,
      },
    ],
    type: 'ExecutionFile',
  }).then((res) => res.content);
}

export function convertTimeStringToEpochTime(dateString) {
  const epochTime = Date.parse(dateString);
  if (!Number.isNaN(epochTime)) {
    return epochTime;
  }
  return null;
}
