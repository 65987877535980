import React from 'react';
import { Dialog, DialogBody, DialogFooter, DialogHeader } from '@katalon-studio/katalon-ui/Dialog';
import Button from '@katalon-studio/katalon-ui/Button';
import { sendAnalyticEventForAction } from '../../../utils/SegmentAnalytics';
import { t } from '../../../i18n/t';
import Services from '../../../utils/Services';
import { FeatureName } from '../../../models/model/FeatureName';
import Notification from '../../../utils/Notification';
import MContext from '../../../models/MContext';

function DeleteGPTDialog({ isOpen, setIsOpen, handleAfterDelete }: {
  isOpen: boolean,
  setIsOpen: (isOpen: boolean) => void,
  handleAfterDelete: () => void
}) {
  const projectId = MContext.projectId;

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleDelete = () => {
    sendAnalyticEventForAction('to_api_key_of_ai_service_deleted', { model: 'gpt' });
    Services.deleteFeatureSetting(projectId, FeatureName.GPT)
      .then(() => {
        handleAfterDelete();
        Notification.pushInfo(
          t('gpt-service#delete#toast-success'),
          2000,
          t('deleted')
        );
      });
    setIsOpen(false);
  };

  return (
    <Dialog
      open={isOpen}
      maxWidth="xs"
    >
      <DialogHeader onClick={handleClose}>
        {t('gpt-service#delete#dialog-header')}
      </DialogHeader>
      <DialogBody>
        {t('gpt-service#delete#dialog-content')}
      </DialogBody>
      <DialogFooter>
        <Button
          size="medium"
          variant="text"
          color="primary"
          onClick={handleClose}
        >
          {t('cancel')}
        </Button>
        <Button
          size="medium"
          variant="contained"
          color="error"
          onClick={handleDelete}
        >
          {t('delete')}
        </Button>
      </DialogFooter>
    </Dialog>
  );
}

export default DeleteGPTDialog;
