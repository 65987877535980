import React from 'react';
import PropTypes from 'prop-types';

function WarningPackageAlertComponent({ text, icon, iconClose, className }) {

  return (
    <div className={`d-flex ${className}`}>
      {icon && <span className="icon">{icon}</span>}
      {text}
      {iconClose && <span className="ml-auto">{iconClose}</span>}
    </div>

  );
}

WarningPackageAlertComponent.propTypes = {
  text: PropTypes.string.isRequired,
  icon: PropTypes.element,
  iconClose: PropTypes.element,
  className: PropTypes.string
};

WarningPackageAlertComponent.defaultProps = {
  text: '',
  className: 'warning-package-alert-content'
};

export default WarningPackageAlertComponent;
