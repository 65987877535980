import { createTheme } from '@mui/material/styles';
import colors from '../scss/colors.scss';

export const theme = createTheme({
  typography: {
    fontFamily: 'Inter,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Fira Sans,Droid Sans,Helvetica Neue,sans-serif',
    fontSize: '1rem',
    button: {
      textTransform: 'none',
      '&:focus, &.focus': {
        outline: 'none',
      }
    },
  },
  palette: {
    primary: {
      main: colors.textPrimary,
    },
    secondary: {
      main: colors.textSecondary,
    },
  },
  components: {
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 0,
          paddingRight: '16px',
        },
      },
    },
    MuiListSubheader: {
      styleOverrides: {
        root: {
          lineHeight: 'inherit',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          padding: '0.375rem 0.75rem',
          margin: 0,
          '&$disabled': {
            opacity: 0.65,

            '&:not(:active)': {
              cursor: 'pointer',
              pointerEvents: 'unset',
            },
          },
          minWidth: '40px',
        },
        label: {
          fontWeight: '500',
          fontSize: '1rem',
        },
        outlined: {
          padding: '0 0 0 8px',
        },
        outlinedSecondary: {
          color: colors.buttonSecondary,
          backgroundColor: colors.buttonSecondaryBg,
          borderColor: colors.buttonSecondaryBorder,

          '&:hover, &:focus, &.focus': {
            color: colors.buttonSecondaryActive,
            backgroundColor: colors.buttonSecondaryActiveBg,
            borderColor: colors.buttonSecondaryBorder,

            '& svg': {
              color: colors.buttonSecondaryActive,
            },
          },

          '&$disabled, &$disabled:hover, &$disabled:focus, &$disabled.focus': {
            color: colors.buttonSecondaryDisable,
            backgroundColor: colors.buttonSecondaryDisableBg,
            borderColor: colors.buttonSecondaryDisableBorder,
          },
        },
      },
    },
    MuiButtonGroup: {
      styleOverrides: {
        groupedOutlinedSecondary: {
          '&:hover': {
            borderColor: colors.buttonSecondaryBorder,
          },
        },
      }
    },
    MuiAutocomplete: {
      styleOverrides: {
        inputRoot: {
          '&.MuiInputBase-root .MuiInputBase-input.MuiAutocomplete-input': {
            padding: 0,
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          color: colors.textPrimary,
          backgroundColor: colors.backgroundDefault,
          fontSize: colors.smallFontSize,
          boxShadow: '0 0 24px 0 #BCC0CC',
          fontWeight: 'normal',
          lineHeight: 2,
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          '&:focus, &.focus': {
            outline: 'none',
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        elevation8: {
          boxShadow: '0 0 24px 0 rgba(171,180,204,0.24)'
        },
        elevation1: {
          boxShadow: `0px 2px 1px -1px ${colors.navShadow}, 0px 1px 1px 0px ${colors.navShadow}, 0px 1px 3px 0px ${colors.navShadow}`
        }
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
            borderColor: colors.inputBorderColorHover,
          },
          '&$focused $notchedOutline': {
            borderColor: colors.inputBorderColorHover,
            borderWidth: 1,
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          minWidth: '256px',
        },
        flexContainer: {
          alignItems: 'start',
        }
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: '8px 24px',
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          overflowX: 'hidden',
        }
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        daySelected: {
          backgroundColor: colors.blueFeature
        }
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          fontSize: '2rem',
          color: colors.cardBorder,
          '&.Mui-checked, &.MuiCheckbox-indeterminate': {
            color: colors.blueCheckbox,
          },
        },
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          '&:before': {
            borderColor: `${colors.cardBorder} !important`
          },
          '&:after': {
            borderColor: `${colors.cardBorder} !important`
          }
        },
      }
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          color: colors.blue
        }
      }
    },
  },
});
