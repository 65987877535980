import React from 'react';
import PageComponent from '../../components/PageComponent';
import DefaultLayout from '../../components/DefaultLayout';
import OnBoardingOrganization from './components/OnBoardingOrganization';


class CreateOrganizationPage extends PageComponent {

  constructor(props) {
    super(props);
    this.meta.id = 'page-create-organization';
    this.meta.title = 'Create Organization';

    this.renderBody = this.renderBody.bind(this);
  }

  renderBody() {
    return (
      <OnBoardingOrganization />
    );
  }

  render() {
    return (
      <DefaultLayout
        renderBody={this.renderBody}
      />
    );
  }
}

export default CreateOrganizationPage;
