import React, { useState, useEffect } from 'react';
import { Card, CardActions, CardContent, Collapse, Button } from '@mui/material';
import { styled } from '@mui/styles';
import { t } from '../../i18n/t';
import { IconChevronRight, IconInfoCircleWhite } from '../../images/CustomIcon';
import TooltipComponent from '../TooltipComponent';
import { CollapseCardType } from '../../utils/ConstantsType';

const ExpandMore = styled((props) => {
  const { ...other } = props;
  return <span {...other} />;
})(({ expand }) => ({
  transform: !expand ? 'rotate(90deg)' : 'rotate(270deg)',
  marginLeft: 'auto',
}));

export default function CollapseCardV2(props: CollapseCardType) {
  const { title, tooltip, header, content, disableCollapse = false } = props;

  const [expanded, setExpanded] = useState(true);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    setExpanded(true);
  }, [content]);

  return (
    <Card variant="outlined" className="collapse-card-v2">
      <div className="collapse-card-v2-header">
        {disableCollapse && title &&
          <div className="overview-header-sub-title mb-0">
            <span className="mr-2">{title}</span>
          </div>}
        {!disableCollapse &&
        <CardActions disableSpacing className="p-0">
          <div className="overview-header-sub-title mb-0">
            <span className="mr-2">{title}</span>
            {
              tooltip &&
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              <TooltipComponent text={tooltip} placement="bottom-end" arrow>
                <IconInfoCircleWhite />
              </TooltipComponent>
            }
          </div>
          <Button className="collapse-button" onClick={handleExpandClick}>
            <span className="mr-3">{t('view-details')}</span>
            <ExpandMore
              className="align-item-left"
              expand={expanded}
              aria-expanded={expanded}
              aria-label="show more"
            >
              <IconChevronRight />
            </ExpandMore>
          </Button>
        </CardActions>}
        <div className="mt-2">{header}</div>
      </div>
      <Collapse in={expanded} timeout="auto" unmountOnExit className="mt-2">
        <CardContent className="p-0">
          {content}
        </CardContent>
      </Collapse>
    </Card>
  );
}
