import React from 'react';
import { t } from '../../../i18n/t';
import TooltipComponent from '../../../components/TooltipComponent';
import DataLoader from '../../../components/table/DataLoader';
import NormalCard from '../../../components/card/NormalCard';
import { SearchEntity } from '../../../utils/Constants';
import FlakyDistributionChart from '../../../components/chart/FlakyDistributionChart';
import ChartConstants from '../../../utils/ChartConstants';
import MFlags from '../../../models/MFlags';

const FlakinessDistribution = ({ dataLoaderRef }) => {

  const renderHeader = () => (
    <div className="overview-header-sub-title">
      {t('project-dashboard#flaky')}
      <TooltipComponent text={t('project-dashboard#flaky#tooltip')} placement="bottom-end" arrow />
    </div>
  );

  const convertData = (data) => {
    const newData = [];
    data.forEach(({ numberOfTestCase, startOfRange }) => {
      const value = {
        value: startOfRange
      };
      for (let i = 0; i < numberOfTestCase; i++) {
        newData.push(value);
      }
    });
    return newData;
  };


  const renderLegend = () => {
    const flakinessColors = ChartConstants.flakinessColors;
    const colorScale = `linear-gradient(to right,${flakinessColors.map((color) => ` ${color}`).toString()})`;
    return (
      <div className="d-flex justify-content-end gradient-legend-container default-legend">
        0% <div className="ml-2 mr-2 gradient-legend" style={{ background: colorScale }} /> 100%
      </div>
    );
  };


  const renderChart = (data) => {
    const convertedData = convertData(data);
    return (
      <>
        <div className="flakiness-chart">
          <FlakyDistributionChart data={convertedData} />
        </div>
        {renderLegend()}
      </>
    );
  };
  return (
    <DataLoader
      title={renderHeader()}
      ref={dataLoaderRef}
      tableId={SearchEntity.TestCaseFlakinessStatistics}
      entityType={SearchEntity.TestCaseFlakinessStatistics}
      render={renderChart}
      useCache={!MFlags.testPerformanceForReportAndAnalyticsEnabled}
      cardClassName="project-dashboard-card-border shadow-none"
      useRefreshButton={false}
      useCollapseButton={false}
      headerComponent={NormalCard}
      bodyComponent={NormalCard}
      showEmptyMessage
      pageSize={300}
    />
  );
};

export default FlakinessDistribution;
