import React from 'react';
import { Col, Row } from 'reactstrap';
import { get, startCase, toLower } from 'lodash';
import { Legend } from 'recharts';
import TooltipComponent from '../../../components/TooltipComponent';
import { t } from '../../../i18n/t';
import DataLoader from '../../../components/table/DataLoader';
import { next } from '../../../utils/Count';
import NormalCard from '../../../components/card/NormalCard';
import { SearchEntity, TestRunStatus, MAXIMUM_PAGE_SIZE } from '../../../utils/Constants';
import Time from '../../../utils/Moment';
import StatusDoughnutChart from '../../../components/chart/StatusDoughnutChart';
import Statuses from '../../../utils/Statuses';
import colors from '../../../../scss/colors.scss';
import MFlags from '../../../models/MFlags';

const ExecutionResults = ({ defaultSearchConditions, dataLoaderRef }) => {

  const renderHeader = () => (
    <div className="overview-header-sub-title">
      {t('project-dashboard#execution-result')}
      <TooltipComponent text={t('project-dashboard#execution-result#tooltip')} placement="bottom-end" arrow />
    </div>
  );

  const calcDiff = (statistics, property) => {
    const current = get(statistics, property);
    const previous = get(statistics, `previousWeekStatistics.${property}`);
    if (!previous) return null;
    const percentage = (((current - previous) * 100) / previous).toFixed(1);
    return percentage;
  };

  const convertTestResultData = (statistics) => [
    {
      key: `$passed-test-results-${statistics.passedTestResult}`,
      label: Statuses.PASSED.label,
      value: statistics.passedTestResult,
      color: 'newPassed',
      diff: calcDiff(statistics, 'passedTestResult')
    },
    {
      key: `$failed-test-results-${statistics.failedTestResult}`,
      label: Statuses.FAILED.label,
      value: statistics.failedTestResult,
      color: 'newFailed',
      diff: calcDiff(statistics, 'failedTestResult')
    },
    {
      key: `$error-test-results-${statistics.errorTestResult}`,
      label: Statuses.ERROR.label,
      value: statistics.errorTestResult,
      color: 'newError',
      diff: calcDiff(statistics, 'errorTestResult')
    },
    {
      key: `$incomplete-test-results-${statistics.incompleteTestResult}`,
      label: Statuses.INCOMPLETE.label,
      value: statistics.incompleteTestResult,
      color: 'newIncomplete',
      diff: calcDiff(statistics, 'incompleteTestResult')
    },
    {
      key: `$skipped-test-results-${statistics.skippedTestResult}`,
      label: Statuses.SKIPPED.label,
      value: statistics.skippedTestResult,
      color: 'skipped',
      diff: calcDiff(statistics, 'skippedTestResult')
    }
  ];

  const convertTestCaseData = (statistics) => [
    {
      key: `$passed-test-case-${statistics.passedTestCase}`,
      label: Statuses.PASSED.label,
      value: statistics.passedTestCase,
      color: 'newPassed',
      diff: calcDiff(statistics, 'passedTestCase')
    },
    {
      key: `$failed-test-case-${statistics.failedTestCase}`,
      label: Statuses.FAILED.label,
      value: statistics.failedTestCase,
      color: 'newFailed',
      diff: calcDiff(statistics, 'failedTestCase')
    },
  ];

  const renderExecutionTime = (totalDuration) => (
    <div className="mt-2">
      <h6>{t('project-dashboard#execution-time')}</h6>
      <div className="statistics-title">{Time.duration(totalDuration)}</div>
    </div>
  );

  const renderLegend = () => {
    const payload = [
      {
        color: colors.newFailed,
        value: TestRunStatus.FAILED,
        type: 'square',
      },
      {
        color: colors.newPassed,
        value: TestRunStatus.PASSED,
        type: 'square',
      },
      {
        color: colors.newError,
        value: TestRunStatus.ERROR,
        type: 'square',
      },
      {
        color: colors.newIncomplete,
        value: TestRunStatus.INCOMPLETE,
        type: 'square',
      },
      {
        color: colors.skipped,
        value: TestRunStatus.SKIPPED,
        type: 'square',
      }
    ];

    const formatLegendName = (name) => startCase(toLower(name));

    return (
      <div className="d-flex justify-content-end card-footer default-legend">
        <Legend
          iconSize={8}
          formatter={formatLegendName}
          verticalAlign="bottom"
          align="right"
          payload={payload}
          margin={{ top: 0, left: 0, right: 24, bottom: 14 }}
        />
      </div>
    );
  };

  const renderChart = (data) => {
    const statistics = data[0];

    // Show empty message in case totalTestResult is 0 and totalTestCase is 0
    if (statistics.totalTestResult === 0 && statistics.totalTestCase === 0) {
      return <div className="text-center">{t('table#empty-message')}</div>;
    }

    return (
      <>
        {renderExecutionTime(statistics.totalDuration)}
        <Row>
          <Col sm="12" md="12" lg="6" xl="6">
            <StatusDoughnutChart
              data={convertTestResultData(statistics)}
              type={t('test_result')}
              total={statistics.totalTestResult}
              diff={calcDiff(statistics, 'totalTestResult')}
              aspect={1.4}
              isShowDescription
            />
          </Col>
          <Col sm="12" md="12" lg="6" xl="6">
            <StatusDoughnutChart
              data={convertTestCaseData(statistics)}
              type={t('test_case')}
              total={statistics.totalTestCase}
              diff={calcDiff(statistics, 'totalTestCase')}
              aspect={1.4}
              isShowDescription
            />
          </Col>
        </Row>
        {renderLegend()}
      </>
    );
  };

  const renderBody = () => (
    <DataLoader
      title={renderHeader()}
      key={next()}
      ref={dataLoaderRef}
      entityType={SearchEntity.ProjectStatisticsCustomizeTime}
      defaultSearchConditions={defaultSearchConditions}
      render={renderChart}
      cardClassName="project-dashboard-card-border shadow-none"
      useCache={!MFlags.testPerformanceForReportAndAnalyticsEnabled}
      useRefreshButton={false}
      useCollapseButton={false}
      headerComponent={NormalCard}
      bodyComponent={NormalCard}
      showEmptyMessage
      pageSize={MAXIMUM_PAGE_SIZE}
    />
  );

  return (
    <>
      {renderBody()}
    </>
  );
};

export default ExecutionResults;