import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import MTableColumnDataMapping from '../../components/table/models/MTableColumnDataMapping';
import { t } from '../../i18n/t';
import { IconStartTime } from '../../images/CustomIcon';
import DecoratorConstants from '../../utils/DecoratorConstants';
import Routes from '../../utils/Routes';
import StatusFilter, { StatusType } from '../search-query/filter/StatusFilter';
import { buildDefaultFilter, buildFilter } from '../search-query/FilterQueryHelper';
import { buildSortQuery, buildSortQueryNullsLast } from '../search-query/SortQueryHelper';
import DataTable from './DataTable';
import TimeFilter from '../search-query/filter/TimeFilter';
import InputFilter from '../search-query/filter/InputFilter';
import { buildSearchCondition } from '../search/SearchUtils';
import UserFilter from '../search-query/filter/UserFilter';

class TestCaseDataTable extends React.Component {

  render() {
    const { tcNameTrackingProps, lastTestRunTrackingProps, additionalFilterQuery } = this.props;

    const headers = [
      new MTableColumnDataMapping(
        t('status'),
        'lastExecutionTestCase.status',
        DecoratorConstants.statusDecorator,
        undefined,
        'fit-content-column',
      ),
      new MTableColumnDataMapping(
        t('name'),
        'name',
        (name, row) => (
          <>
            <div>{DecoratorConstants.testCaseNameDecorator(name, row, tcNameTrackingProps)}</div>
            <div>{row.path}</div>
          </>
        ),
        'short-text-column',
      ),
      new MTableColumnDataMapping(
        t('maintainer'),
        'maintainer',
        (name, row) => {
          const value = _.get(row, name);
          if (value) {
            return DecoratorConstants.renderUserDecorator(value);
          }
          return null;
        }
      ),
      new MTableColumnDataMapping(
        t('totalAssertions'),
        'testResultAssertion',
        (name, row) => {
          const value = _.get(row, name, {});
          const { totalAssertion } = value;
          return (
            <span title="Assertions">{totalAssertion}</span>
          );
        }
        ,
      ),
      new MTableColumnDataMapping(
        t('last_execution'),
        'lastExecutionTestCase.startTime',
        (name, row) => {
          if (row.lastExecutionTestCase) {
            const constructedLink = new Routes({
              teamId: row.project.teamId,
              projectId: row.project.id,
              executionId: row.lastExecutionTestCase.executionOrder,
            });
            const lastExecutionElement = DecoratorConstants.timeDecorator(name, row);
            const startTimeContent = DecoratorConstants.linkDecorator(
              lastExecutionElement,
              constructedLink.execution_details_link,
              null, 
              lastTestRunTrackingProps
            );
            return (
              <>
                <span className="d-block">
                  <IconStartTime title={t('startTime')} className="mr-1" />
                  {startTimeContent}
                </span>
                {DecoratorConstants.durationWithIconDecorator('lastExecutionTestCase.duration', row)}
              </>
            );
          } else {
            return null;
          }
        },
        undefined,
        'nowrap-column',
      ),
      new MTableColumnDataMapping(
        t('table-header#average-duration'),
        'averageDuration',
        DecoratorConstants.durationDecorator
      ),
      new MTableColumnDataMapping(
        t('table-header#flakiness'),
        'flakiness',
        DecoratorConstants.flakinessDecorator,
        undefined,
        'nowrap-column',
      ),
      new MTableColumnDataMapping(
        t('table-header#requirements'),
        'externalIssues',
        DecoratorConstants.jiraIssuesDecorator,
      ),
    ];
    let filterQuery = [
      buildFilter(InputFilter, { id: 'path', label: 'Path', operator: '~' }),
      buildFilter(StatusFilter, { id: 'ExecutionStatistics.status', type: StatusType.EXECUTION }),
      buildFilter(StatusFilter, { id: 'type', label: 'Type', type: StatusType.TEST_CASE }),
      buildFilter(TimeFilter, { id: 'ExecutionStatistics.startTime', label: 'Last Run' }),
      buildFilter(UserFilter, { id: 'User.id', label: 'Maintainer' }),
    ];

    if (additionalFilterQuery) {
      filterQuery = filterQuery.concat(additionalFilterQuery);
    }

    const sortQuery = [
      ...buildSortQuery('name', t('name')),
      ...buildSortQuery('averageDuration', t('table-header#average-duration')),
      ...buildSortQuery('ExecutionStatistics.startTime', t('last_execution')),
      ...buildSortQuery('flakiness', t('table-header#flakiness')),
      ...buildSortQueryNullsLast('TestResultAssertion.totalAssertion', t('totalAssertions')),
    ];
    const defaultFilter = buildDefaultFilter('name', '~', 'Name');
    const newProps = {
      ...this.props,
      filterQuery,
      sortQuery,
      defaultFilter
    };

    return (
      <DataTable
        columnMapping={headers}
        entityType="TestCase"
        defaultSearchConditions={[
          buildSearchCondition('ExecutionStatistics.id', 'is not null', '')
        ]}
        useSearchQuery
        defaultSort={['ExecutionStatistics.startTime, desc']}
        unitName="test case"
        {...newProps}
      />
    );
  }
}

TestCaseDataTable.propTypes = {
  tcNameTrackingProps: PropTypes.object,
  lastTestRunTrackingProps: PropTypes.object,
};

TestCaseDataTable.defaultProps = {
  tcNameTrackingProps: null,
  lastTestRunTrackingProps: null,
};

export default TestCaseDataTable;
