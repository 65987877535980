import { Step, StepLabel, Divider } from '@mui/material';
import MStepper from '@mui/material/Stepper';
import PropTypes from 'prop-types';
import React from 'react';
import { Badge, Button, Card, CardBody, CardFooter, Row, Col } from 'reactstrap';
import { t } from '../../i18n/t';
import { IconTick, IconGetStart } from '../../images/CustomIcon';

class OnBoardingStepper extends React.Component {

  constructor(props) {
    super(props);

    this.handleBack = this.handleBack.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.handleReset = this.handleReset.bind(this);

    this.state = this.getInitialState();
  }

  getInitialState() {
    const { startStep } = this.props;
    return {
      activeStep: startStep,
    };
  }

  handleBack() {
    this.setState(({ activeStep }) => ({
      activeStep: activeStep - 1,
    }));
  }

  handleNext() {
    this.setState(({ activeStep }) => ({
      activeStep: activeStep + 1,
    }));
  }

  handleReset() {
    this.setState({
      activeStep: 0,
    });
  }

  generateIndexIcon(index) {
    const { activeStep } = this.state;

    if (index === activeStep) {
      return (
        <Badge className="bg-primary badge-circle-index">{index + 1}</Badge>
      );
    }

    return (
      <Badge className="bg-inactive badge-circle-index">{index + 1}</Badge>
    );
  }

  getStep(index) {
    const { steps, handleFinal } = this.props;
    if (index >= steps.length) {
      return handleFinal();
    }
    return steps[index];
  }

  getNextHandle(index) {
    const { steps, handleFinal } = this.props;
    if (index >= steps.length) {
      return handleFinal();
    }
    return steps[index].nextHandle;
  }

  getContent(index) {
    const { steps, handleFinal } = this.props;
    const step = this.getStep(index);
    if (index >= steps.length) {
      return handleFinal();
    }
    return step.content;
  }

  renderWithoutStepper(activeStep) {
    return (
      <Card>
        <Row>
          <CardBody>
            {this.getContent(activeStep)}
          </CardBody>
        </Row>
      </Card>
    );
  }

  render() {
    const { activeStep } = this.state;
    const { steps, startBack } = this.props;
    const step = this.getStep(activeStep);

    return step.disabledStepper ? this.renderWithoutStepper(activeStep) : (
      <Card>
        <Row>
          <Col sm="12" md="8" className="align-self-center">
            <CardBody>
              {this.getContent(activeStep)}
            </CardBody>
            {!this.getNextHandle(activeStep) && (
              <CardFooter>
                <Divider />
                <div className="quick-guide__button-zone">
                  {activeStep > startBack && (
                    <Button
                      disabled={activeStep <= startBack}
                      onClick={this.handleBack}
                      variant="contained"
                    >
                      {t('stepper#back')}
                    </Button>
                  )}
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={this.handleNext}
                  >
                    {activeStep === steps.length - 1 ? t('stepper#finish') : t('stepper#next')}
                  </Button>
                </div>
              </CardFooter>
            )}
          </Col>
          <Col sm="4" md="4" className="d-flex align-items-center divider-step-container">
            <Divider orientation="vertical" className="divider-step" />
            <MStepper
              orientation="vertical"
              activeStep={activeStep}
              className="on-boarding-stepper"
            >
              {steps.map((step, index) => {
                const style = index === activeStep ? 'active' : 'disable';

                let label = step.label;
                if (step.labeled) {
                  label = index < activeStep ? step.labeled : step.label;
                }

                return (
                  <Step key={step.label} expanded>
                    <StepLabel
                      icon={index >= activeStep ? this.generateIndexIcon(index) : <IconTick />}
                    >
                      <div className={`quick-guide__header ${style}`}>
                        {label}
                      </div>
                    </StepLabel>
                    <div className={`quick-guide__content ${style}`}>
                      {step.description}
                    </div>
                  </Step>
                );
              })}
              <IconGetStart className="align-self-center" />
            </MStepper>
          </Col>
        </Row>
      </Card>
    );
  }
}

OnBoardingStepper.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.any,
    description: PropTypes.any,
    content: PropTypes.any,
  })),
  startBack: PropTypes.any,
  handleFinal: PropTypes.func,
  startStep: PropTypes.number,
};

OnBoardingStepper.defaultProps = {
  startBack: 0,
  startStep: 0,
};

export default OnBoardingStepper;
