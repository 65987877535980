import { combineReducers } from '@reduxjs/toolkit';

import counterReducer, * as fromCounter from './counterSlice';
import customFieldDialogReducer, * as fromCustomFieldDialog from './customFieldDialogSlice';
import customFieldDialogWithTypeScriptReducer, * as fromCustomFieldDialogWithTypeScript from './customFieldDialogSliceWithTypeScript';

const rootReducer = combineReducers({
  [fromCounter.COUNTER_FEATURE_KEY]: counterReducer,
  [fromCustomFieldDialog.CUSTOM_FIELD_DIALOG_KEY]: customFieldDialogReducer,
  [fromCustomFieldDialogWithTypeScript.CUSTOM_FIELD_DIALOG_KEY]: customFieldDialogWithTypeScriptReducer,
});

export {
  fromCounter,
  fromCustomFieldDialog,
  fromCustomFieldDialogWithTypeScript,
};
export default rootReducer;
