import { isEmpty } from 'lodash';
import objectHash from 'object-hash';
import MContext from '../models/MContext';
import { buildSearchCondition } from '../components/search/SearchUtils';

const SearchHelper = {
  fullParams(params) {
    if (isEmpty(params.conditions)) {
      params.conditions = [];
    }

    if (!params.ignoreAutoFill) {
      const hasTeamId = params.conditions.find((condition) => condition.key === 'Team.id');
      if (!hasTeamId) {
        if (MContext.projectId != null) {
          const projectCondition = buildSearchCondition('Project.id', '=', MContext.projectId);
          params.conditions.push(projectCondition);
        }
      }
    }
    return params;
  },

  generateCacheKey(object) {
    return objectHash(object);
  }
};

export default SearchHelper;
