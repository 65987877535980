import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { FormGroup, Label, Col, Row } from 'reactstrap';
import { t } from '../../../i18n/t';
import { OrganizationFeature } from '../../../utils/Constants';
import FeatureSwitch from './FeatureSwitch';
import { IconKSEBig, IconREBig, IconKSBig, LogoTestOpsMedium } from '../../../images/CustomIcon';

function OrganizationUsersProductAccess({ assignedFeatures = {}, assignableFeatures = [], onFeatureChange = () => null, blockUI }) {
  const initialFeatureMap = Object.keys(assignableFeatures).reduce((map, assignableFeature) => {
    map[assignableFeature] = assignedFeatures.hasOwnProperty(assignableFeature);
    return map;
  }, {});

  const [featureMap, setFeatureMap] = useState(initialFeatureMap);
  const onChange = (feature, checked) => {
    const newFeatureMap = {
      ...featureMap,
      [feature]: checked,
    };

    setFeatureMap(newFeatureMap);

    if (onFeatureChange) {
      onFeatureChange(feature, newFeatureMap, t('product_kse'));
    }
  };

  const getQuota = (productAccess) => {
    const totalQuota = assignableFeatures[OrganizationFeature[productAccess]]?.totalQuota;
    const remainingQuota = assignableFeatures[OrganizationFeature[productAccess]]?.remainingQuota;
    const quota = featureMap.hasOwnProperty(OrganizationFeature[productAccess]) && !featureMap[OrganizationFeature[productAccess]]
      ? ` (Available: ${remainingQuota}/${totalQuota})` : '';
    return quota;
  };
  return (
    <FormGroup>
      <Label>{t('product_access')}</Label>
      <Row>
        <Col>
          <FormGroup>
            <FeatureSwitch
              checked
              id="katalonStudioFree"
              label={t('product_ks')}
              icon={<IconKSBig />}
            />
            <FeatureSwitch
              data-trackid="kse-feature-switch"
              feature={OrganizationFeature.KSE}
              disabled={blockUI || !featureMap.hasOwnProperty(OrganizationFeature.KSE)}
              checked={featureMap[OrganizationFeature.KSE]}
              onChange={onChange}
              id="katalonStudioEnterprise"
              label={t('product_kse') + getQuota('KSE')}
              icon={<IconKSEBig />}
            />
            <FeatureSwitch
              data-trackid="kre-feature-switch"
              feature={OrganizationFeature.ENGINE}
              disabled
              checked={featureMap.hasOwnProperty(OrganizationFeature.ENGINE)}
              id="katalonRuntimeEngine"
              label={t('product_kre')}
              icon={<IconREBig />}
            />
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <FeatureSwitch
              checked
              onChange={onChange}
              id="katalonTestOps"
              label={t('brand_name')}
              icon={<LogoTestOpsMedium />}
            />
          </FormGroup>
        </Col>
      </Row>
    </FormGroup>
  );
}

OrganizationUsersProductAccess.propTypes = {
  assignedFeatures: PropTypes.object,
  assignableFeatures: PropTypes.array,
  onFeatureChange: PropTypes.func,
};

export default OrganizationUsersProductAccess;
