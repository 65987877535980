import React from 'react';
import { Alert, Spinner } from 'reactstrap';
import { t } from '../../i18n/t';
import { JobExecutionResult } from './JobExecutionResult';
import { RunningExecutionResultTab } from './RunningExecutionResultTab';
import DataLoader from '../../components/table/DataLoader';
import { buildSearchCondition } from '../../components/search/SearchUtils';
import MFlags from '../../models/MFlags';

class RunningExecution extends React.Component {
  constructor(props) {
    super(props);
    this.renderAlert = this.renderAlert.bind(this);
  }

  renderAlert(data) {
    return (
      (data.length !== 0 && data[0].status === 'INCOMPLETE') &&
      <Alert color="primary">
        <span title={t('running-job')}><Spinner color="primary" size="sm" /></span>
        &nbsp;This test run is in progress.
      </Alert>
    );
  }

  render() {
    return (
      <>
        { MFlags.fixIncompleteTestRunStatusOnSideBarEnabled ?
          <DataLoader
            entityType="TestRunPlanning"
            render={this.renderAlert}
            defaultSearchConditions={[
              buildSearchCondition('id', '=', this.props.execution.id),
            ]}
          /> :
          <Alert color="primary">
            <span title={t('running-job')}><Spinner color="primary" size="sm" /></span>
            &nbsp;This test run is in progress.
          </Alert>}
        <JobExecutionResult executionId={this.props.execution.id} title={t('jobs')} />
        <RunningExecutionResultTab executionId={this.props.execution.id} />
      </>
    );
  }
}

export default RunningExecution;
