import React, { ReactElement, useState } from 'react';
import { capitalize, lowerCase } from 'lodash';
import { ThemeProvider } from '@mui/material';
import { theme } from '@katalon-studio/katalon-ui/Theme';
import IconButton from '@katalon-studio/katalon-ui/IconButton';
import { Menu, MenuItem } from '@katalon-studio/katalon-ui/Menu';
import PropTypes from 'prop-types';
import Tooltip from '@katalon-studio/katalon-ui/Tooltip';
import EllipsisIcon from '../../images/icons/katalonui/EllipsisIcon';
import { next } from '../utils/Count';
import { MenuItemEntity } from '../models/model/MenuItemEntity';

function MenuItemComponent(props: {
  iconButton: ReactElement;
  items: MenuItemEntity[];
  iconButtonClassName: string;
  disabled: boolean;
  isTooltipEnabled: boolean;
  tooltipText: string;
  tooltipPlacement: 'bottom-end' | 'bottom-start' | 'bottom' | 'left-end' | 'left-start' | 'left' | 'right-end' | 'right-start' | 'right' | 'top-end' | 'top-start' | 'top' | undefined;
  tooltipSize: 'small' | 'medium';
}) {

  const {
    iconButton,
    items,
    iconButtonClassName,
    disabled,
    isTooltipEnabled,
    tooltipText,
    tooltipPlacement,
    tooltipSize
  } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (event: React.MouseEvent<HTMLLIElement, MouseEvent>, handler?: React.MouseEventHandler<HTMLLIElement>) => {
    if (handler) {
      handler(event);
    }
    setAnchorEl(null);
  };

  return (
    <ThemeProvider theme={theme}>
      <Tooltip
        label={tooltipText}
        placement={tooltipPlacement}
        disableHoverListener={!isTooltipEnabled}
        size={tooltipSize}
      >
        <div>
          <IconButton
            size="small"
            onClick={handleClick}
            disabled={disabled}
            className={iconButtonClassName}
          >
            {iconButton}
          </IconButton>
        </div>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        className="mt-2"
      >
        {items.map((item: MenuItemEntity) => (
          <MenuItem
            key={next()}
            icon={item.icon}
            onClick={(event) => handleMenuItemClick(event, item.handler)}
            divider={item.divider || false}
            sx={{ width: '200px' }}
            color={item.color}
          >
            {capitalize(lowerCase(item.action))}
          </MenuItem>
        ))}
      </Menu>
    </ThemeProvider>
  );
}

MenuItemComponent.defaultProps = {
  iconButton: <EllipsisIcon />,
  disabled: false,
  isTooltipEnabled: false,
  tooltipText: '',
  tooltipPlacement: 'bottom',
  tooltipSize: 'medium'
};

MenuItemComponent.propTypes = {
  iconButton: PropTypes.element,
  items: PropTypes.array.isRequired,
  iconButtonClassName: PropTypes.string,
  disabled: PropTypes.bool,
  isTooltipEnabled: PropTypes.bool,
  tooltipText: PropTypes.string,
  tooltipPlacement: PropTypes.string,
  tooltipSize: PropTypes.string,
};

export default MenuItemComponent;
