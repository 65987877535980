import React, { useState, useEffect } from 'react';
import WarningBannerComponent from '../WarningBannerComponent';
import MContext from '../../models/MContext';
import { t } from '../../i18n/t';
import { IconRedWarning } from '../../images/CustomNewIcon';
import Services from '../../utils/Services';
import DocumentLink from '../../utils/DocumentLink';
import { OrganizationFeature } from '../../utils/Constants';
import GroupEvent from '../../utils/track/GroupEvent';

export default function VisualTestingExceedQuotaWarning() {
  const { targetOrganization } = MContext;

  const [isExceedQuota, setExceedQuota] = useState(false);
  const [isVisualTestingPro, setVisualTestingPro] = useState(false);

  useEffect(() => {
    Services.getVisualTestingQuota(targetOrganization.id)
      .then(({ remainingQuota, visualTestingFeature }) => {
        setExceedQuota(remainingQuota <= 0);
        setVisualTestingPro(visualTestingFeature === OrganizationFeature.VISUAL_TESTING_PRO);
      });
  }, []);

  const visualTestingProMessage = {
    text: t('visual-testing#exceed-quota-warning#pro'),
    href: DocumentLink.UPGRADE_VISUAL_TESTING_SUBSCRIPTION,
    alt: t('upgradeSubscriptions'),
    dataTrackId: 'page-visual-testing-view-upgrade-subscription-doc',
  };

  const visualTestingStandardMessage = {
    text: t('visual-testing#exceed-quota-warning#standard'),
    href: DocumentLink.ABOUT_VISUAL_TESTING_PRO,
    alt: t('aboutVSTPro'),
    dataTrackId: 'page-visual-testing-view-subscription-doc',
  };

  const renderText = () => {

    const { text, href, alt, dataTrackId } = isVisualTestingPro ? visualTestingProMessage : visualTestingStandardMessage;

    return (
      <>
        {text}
        <a
          href={href}
          target="_blank"
          rel="noopener noreferrer"
          data-trackId={dataTrackId}
          data-groupId={GroupEvent.ACCESS_REPORT}
        >
          {alt}
        </a>.
      </>
    );
  };

  return isExceedQuota && (
    <div className="warning-packages-popup">
      <WarningBannerComponent
        text={renderText()}
        icon={<IconRedWarning />}
      />
    </div>
  );

}

