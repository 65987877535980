import React, { Component } from 'react';
import {
  Button,
  Form,
  FormGroup,
  Label,
  Row,
  Col, FormText,
} from 'reactstrap';
import MContext from '../../../models/MContext';
import { t } from '../../../i18n/t';
import Stepper from '../../../components/wizard/Stepper';
import MConfigs from '../../../models/MConfigs';
import Input from '../../../components/Input';
import Select from '../../../components/Select';
import CodeBox from '../../../components/CodeBox';
import { ReportUploaderType, IntegrationType } from '../../../utils/Constants';
import Services from '../../../utils/Services';
import RouteConstants from '../../../utils/RouteConstants';
import Arrays from '../../../utils/Arrays';
import MFlags from '../../../models/MFlags';
import SelectApiKeyComponent from '../../../components/SelectApiKeyComponent';

class ReportUploader extends Component {
  constructor(props) {
    super(props);

    this.reportType = [
      {
        label: 'Katalon',
        value: ReportUploaderType.KATALON,
      },
      {
        label: 'Junit',
        value: ReportUploaderType.JUNIT,
      },
      {
        label: 'Katalon Recorder',
        value: ReportUploaderType.KATALON_RECORDER,
      }
    ];

    this.state = this.getInitialState();
    this.projectId = MContext.projectId;
    this.handleChangeText = this.handleChangeText.bind(this);
    this.handleSelectReportType = this.handleSelectReportType.bind(this);
    this.handleSelectApiKey = this.handleSelectApiKey.bind(this);
  }

  getInitialState() {
    return {
      reportType: this.reportType[0],
      reportFolder: '',
      tabIndex: 0,
      apiKey: null,
      apiKeys: null,
      command: '',
      header: 'Bash',
    };
  }

  componentDidMount() {
    const { searchApiKeyEnabled } = MFlags;
    if (!searchApiKeyEnabled) {
      this.getApiKeys();
    }
  }

  getApiKeys() {
    Services.getApiKeys()
      .then((response) => {
        const apiKeys = response.map((apikey) => ({
          value: apikey.key,
          label: apikey.name,
        }));

        this.setState({
          apiKeys,
          apiKey: apiKeys[0],
        }, () => {
          this.generateCommand();
        });
      });
  }

  generateCLICommand() {
    const { reportFolder, apiKey, reportType } = this.state;

    return `java -jar katalon-report-uploader-${MConfigs.reportUploaderLatestVersion}.jar ` +
          `--projectId=${this.projectId} ` +
          `--path=${reportFolder} ` +
          `--password=${apiKey?.value} ` +
          `--type=${reportType?.value}`;
  }

  generateDockerCommand() {
    const { reportFolder, apiKey, reportType } = this.state;

    return `docker run -t --rm -v ${reportFolder}:/katalon/report -e ` +
      `PASSWORD=${apiKey?.value} -e ` +
      `PROJECT_ID=${this.projectId} -e ` +
      `TYPE=${reportType?.value} -e ` +
      'REPORT_PATH=/katalon/report ' +
      `katalonstudio/report-uploader:${MConfigs.reportUploaderLatestVersion}`;
  }

  generateGithubCommand() {
    const { reportFolder, apiKey, reportType } = this.state;

    return '- name: Katalon Report Uploader\n' +
      `  uses: katalon-studio/report-uploader@v${MConfigs.reportUploaderLatestVersion}\n` +
      '  env:\n' +
      `    PASSWORD: ${apiKey?.value}\n` +
      `    PROJECT_ID: ${this.projectId}\n` +
      `    TYPE: ${reportType?.value}\n` +
      `    REPORT_PATH: ${reportFolder}`;
  }

  generateCircleCIYml() {
    const { reportFolder, reportType } = this.state;

    return 'version: 2.1\n' +
      '\n' +
      'orbs:\n' +
      '  node: circleci/node@4.1.0\n' +
      '\n' +
      'jobs:\n' +
      '\n' +
      '  test:\n' +
      '    executor:\n' +
      '      name: node/default\n' +
      '      tag: \'10.15.3\'\n' +
      '    working_directory: /root/project\n' +
      '    steps:\n' +
      '      - checkout\n' +
      '      - run: |\n' +
      '          set -ex\n' +
      '          npm install\n' +
      '          npm test\n' +
      '      - persist_to_workspace:\n' +
      '          root: /root/project\n' +
      '          paths:\n' +
      '            - report/*\n' +
      '\n' +
      '  upload-report:\n' +
      '    docker:\n' +
      `      - image: katalonstudio/report-uploader:${MConfigs.reportUploaderLatestVersion}\n` +
      '    environment:\n' +
      `      PROJECT_ID: ${this.projectId}\n` +
      `      TYPE: ${reportType?.value}\n` +
      `      REPORT_PATH: ${reportFolder}\n` +
      '    steps:\n' +
      '      - attach_workspace:\n' +
      '          at: /katalon\n' +
      '      - run: uploader.sh\n' +
      '\n' +
      'workflows:\n' +
      '  version: 2.1\n' +
      '  upload-report:\n' +
      '    jobs:\n' +
      '      - test\n' +
      '      - upload-report:\n' +
      '          requires:\n' +
      '            - test';
  }

  generateGitlabYml() {
    const { reportFolder, reportType } = this.state;

    return 'stages:\n' +
      '  - test\n' +
      '  - upload-report\n' +
      '\n' +
      'test:\n' +
      '  stage: test\n' +
      '  image: node:10.15.3\n' +
      '  cache:\n' +
      '    paths:\n' +
      '      - node_modules/\n' +
      '  script:\n' +
      '    - npm install\n' +
      '    - npm test\n' +
      '  artifacts:\n' +
      '    paths:\n' +
      '      - report/\n' +
      '  only:\n' +
      '    - master\n' +
      '\n' +
      'upload-report:\n' +
      '  stage: upload-report\n' +
      `  image: katalonstudio/report-uploader:${MConfigs.reportUploaderLatestVersion}\n` +
      '  script:\n' +
      '    - uploader.sh\n' +
      '  variables:\n' +
      `    PROJECT_ID: ${this.projectId}\n` +
      `    TYPE: ${reportType?.value}\n` +
      `    REPORT_PATH: ${reportFolder}\n` +
      '    PUSH_TO_XRAY: false\n' +
      '  dependencies:\n' +
      '    - test\n' +
      '  only:\n' +
      '    - master\n';
  }

  generateAwsYml() {
    const { reportFolder, apiKey, reportType } = this.state;

    return 'version: 0.2\n' +
      '\n' +
      'phases:\n' +
      '  install:\n' +
      '    runtime-versions:\n' +
      '      docker: 18\n' +
      '      nodejs: 10\n' +
      '\n' +
      '    commands:\n' +
      '      - node -v\n' +
      '\n' +
      '  build:\n' +
      '    commands:\n' +
      '      - npm install && npm test\n' +
      '\n' +
      '  post_build:\n' +
      '    commands:\n' +
      '      - |\n' +
      '        set -ex\n' +
      '        export REPORT_PATH=$(pwd)/report\n' +
      '        docker run -t --rm -v \\\n' +
      `          $REPORT_PATH:${reportFolder} \\\n` +
      `          -e PASSWORD=${apiKey?.value}  \\\n` +
      `          -e PROJECT_ID=${this.projectId} \\\n` +
      `          -e TYPE=${reportType?.value} \\\n` +
      `          -e REPORT_PATH=${reportFolder} \\\n` +
      `          katalonstudio/report-uploader:${MConfigs.reportUploaderLatestVersion}\n` +
      'cache:\n' +
      '  paths:\n' +
      '    - \'node_modules/**/*\'';
  }

  generateAzureYml() {
    const { reportFolder, apiKey, reportType } = this.state;

    return 'trigger:\n' +
      '  batch: true\n' +
      '  branches:\n' +
      '    include:\n' +
      '      - master\n' +
      '\n' +
      'pr:\n' +
      '  - master\n' +
      '\n' +
      'stages:\n' +
      '  - stage: Test\n' +
      '    jobs:\n' +
      '      - job: Test\n' +
      '        pool:\n' +
      '          vmImage: \'ubuntu-latest\'\n' +
      '\n' +
      '        steps:\n' +
      '\n' +
      '          - checkout: self\n' +
      '            fetchDepth: 1\n' +
      '            clean: true\n' +
      '\n' +
      '          - bash: |\n' +
      '              npm install\n' +
      '              npm test\n' +
      '            displayName: Test\n' +
      '          - task: DockerInstaller@0\n' +
      '            displayName: Docker Installer\n' +
      '            inputs:\n' +
      '              dockerVersion: 18.09.9\n' +
      '              releaseType: stable\n' +
      '\n' +
      '          - bash: |\n' +
      '              set -ex\n' +
      '              export REPORT_PATH=$(pwd)/report\n' +
      '              docker run -t --rm -v \\\n' +
      `                $REPORT_PATH:${reportFolder} \\\n` +
      `                -e PASSWORD=${apiKey?.value}  \\\n` +
      `                -e PROJECT_ID=${this.projectId} \\\n` +
      `                -e TYPE=${reportType?.value} \\\n` +
      `                -e REPORT_PATH=${reportFolder} \\\n` +
      `                katalonstudio/report-uploader:${MConfigs.reportUploaderLatestVersion}\n` +
      '            displayName: Upload report to Katalon TestOps\n' +
      '            env:\n' +
      `              KATALON_API_KEY: ${apiKey?.value}`;
  }

  generateCommand() {
    const { integrationType } = this.props;
    let command = '';
    let header;

    switch (integrationType) {
      case IntegrationType.CLI:
        command = this.generateCLICommand();
        header = 'Bash';
        break;
      case IntegrationType.DOCKER:
        command = this.generateDockerCommand();
        header = 'Bash';
        break;
      case IntegrationType.GITHUB_ACTION:
        command = this.generateGithubCommand();
        header = 'github_action.yml';
        break;
      case IntegrationType.CIRCLE_CI:
        command = this.generateCircleCIYml();
        header = 'circle_ci.yml';
        break;
      case IntegrationType.GITLAB_CI:
        command = this.generateGitlabYml();
        header = 'gitlab_ci.yml';
        break;
      case IntegrationType.AWS_CODEBUILD:
        command = this.generateAwsYml();
        header = 'aws_codebuild.yml';
        break;
      case IntegrationType.AZURE_DEVOPS_PIPELINES:
        command = this.generateAzureYml();
        header = 'azure_devops_pipelines.yml';
        break;
      default:
        command = '';
        header = 'Bash';
    }

    this.setState({
      command,
      header
    });
  }

  handleChangeText(event) {
    const value = event.target.value;
    const name = event.target.name;
    this.setState(
      { [name]: value },
      () => {
        this.generateCommand();
      }
    );
  }

  handleDownloadAgentExecutable(event) {
    event.preventDefault();
    window.open(MConfigs.reportUploaderDownloadUrl);
  }

  renderReportUploaderCLI() {
    return (
      <Row>
        <Col sm="12" md="8" lg="6" xl="5">
          {this.renderReportDownload()}
        </Col>
      </Row>
    );
  }

  renderReportDownload() {
    const url = MConfigs.reportUploaderDownloadUrl;

    return (
      <>
        <FormGroup>
          <Button
            color="primary"
            onClick={this.handleDownloadAgentExecutable}
            disabled={!url}
          >
            Download Report Uploader
          </Button>
        </FormGroup>
        {url && (
          <FormGroup>
            <a
              target="_blank"
              rel="noreferrer noopener"
              href={url}
            >
              {url}
            </a>
          </FormGroup>
        )}
      </>
    );
  }

  renderInputReportFolder() {
    const { reportType, reportFolder } = this.state;
    return (
      <Row>
        <Col sm="12" md="8" lg="6" xl="5">
          <FormGroup>
            <Label for="reportFolder">Report Folder</Label>
            <Input
              id="reportFolder"
              name="reportFolder"
              value={reportFolder}
              type="text"
              onChange={this.handleChangeText}
            />
          </FormGroup>
          <FormGroup sm="12" md="8" lg="6" xl="5">
            <Label for="agentName">Report Type</Label>
            <Select
              clearable={false}
              id="reportType"
              name="reportType"
              value={reportType}
              type="text"
              options={this.reportType}
              onChange={this.handleSelectReportType}
            />
          </FormGroup>
        </Col>
      </Row>
    );
  }

  renderSelectCredential() {
    const { apiKey, apiKeys } = this.state;
    const { searchApiKeyEnabled } = MFlags;

    return (
      <Row>
        <Col sm="12" md="8" lg="6" xl="5">
          <FormGroup>
            <Label for="apiKey">{t('agent#api-key')}</Label>
            {searchApiKeyEnabled ?
              <SelectApiKeyComponent onChange={this.handleSelectApiKey} />
              :
              <Select
                clearable={false}
                id="apiKey"
                value={apiKey}
                onChange={this.handleSelectApiKey}
                options={apiKeys}
              />}
            <FormText color="muted">
              {t('agent#generate-api-key')}
              <a
                href={RouteConstants.apikey}
              >
                here.
              </a>
            </FormText>
          </FormGroup>
        </Col>
      </Row>
    );
  }

  renderConfigEnvVariables() {
    const { apiKey } = this.state;
    return (
      <FormGroup>
        <CodeBox
          content={`KATALON_TESTOPS_APIKEY=${apiKey?.value}`}
        />

      </FormGroup>
    );
  }

  renderCommand() {
    const { command, header } = this.state;
    return (
      <FormGroup>
        <CodeBox
          header={header}
          content={command}
        />
      </FormGroup>
    );
  }

  handleSelectReportType(reportType) {
    this.setState(
      { reportType },
      () => {
        this.generateCommand();
      }
    );
  }

  handleSelectApiKey(apiKey) {
    this.setState(
      { apiKey },
      () => {
        this.generateCommand();
      }
    );
  }

  getDescriptionCommand() {
    const { integrationType } = this.props;

    switch (integrationType) {
      case IntegrationType.CLI:
      case IntegrationType.DOCKER:
        return t('reportUploader#copy-and-paste');
      case IntegrationType.GITHUB_ACTION:
      case IntegrationType.CIRCLE_CI:
      case IntegrationType.GITLAB_CI:
      case IntegrationType.AWS_CODEBUILD:
      case IntegrationType.AZURE_DEVOPS_PIPELINES:
        return t('reportUploader#copy-snippet');
      default:
        return t('reportUploader#copy-and-paste');
    }
  }

  renderSteps() {
    const { integrationType } = this.props;
    const steps = [
      ...Arrays.insertIf(integrationType === IntegrationType.CLI, {
        label: 'Download Report Uploader',
        content: this.renderReportUploaderCLI(),
        description: (
          <div>
            You can install&nbsp;
            <a href="https://www.java.com/en/download/manual.jsp">Java JRE</a> and&nbsp;
            <a href="https://www.oracle.com/java/technologies/javase-downloads.html">Java JDK</a>.
          </div>
        )
      }),
      {
        label: 'Choose Report',
        content: this.renderInputReportFolder(),
      },
      {
        label: 'Credentials',
        content: this.renderSelectCredential()
      },
      ...Arrays.insertIf(integrationType === IntegrationType.CIRCLE_CI ||
        integrationType === IntegrationType.GITLAB_CI, {
        label: 'Create Environment Variables',
        description: 'Configure these variables in your system',
        content: this.renderConfigEnvVariables(),
      }),
      {
        label: 'Command',
        description: this.getDescriptionCommand(),
        content: this.renderCommand(),
      }
    ];

    return (
      <Form>
        <Stepper
          id="report-uploader-setup"
          data-trackid="report-uploader-setup"
          expanded
          steps={steps}
        />
      </Form>
    );
  }

  render() {
    return this.renderSteps();
  }
}
export default ReportUploader;
