import { initialize } from 'launchdarkly-js-client-sdk';
import MConfigs from '../models/MConfigs';

let ldClient = null;
let ldReady = false;
let ldAccountClient = null;
let ldAccountReady = false;
const currentOrgId = -1;
const currentAccountId = -1;

const LaunchDarklyHelper = {

  init: (key, defaultUser) => {
    if (!key || !defaultUser) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject('Invalid LD info.')
        .catch(() => {
          ldReady = false;
        });
    }
    const ldUser = {
      key: defaultUser
    };
    ldClient = initialize(key, ldUser);
    return ldClient.waitForInitialization()
      .then(() => {
        ldReady = true;
      }).catch(() => {
        ldReady = false;
      });
  },

  initAccountClient: (key, defaultUser) => {
    if (!key || !defaultUser) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject('Invalid LD info.')
        .catch(() => {
          ldReady = false;
        });
    }
    const ldUser = {
      key: defaultUser
    };
    ldAccountClient = initialize(key, ldUser);
    return ldAccountClient.waitForInitialization()
      .then(() => {
        ldAccountReady = true;
      }).catch(() => {
        ldAccountReady = false;
      });
  },

  initialized: () => ldClient && ldReady,

  accountClientInitialized: () => ldAccountClient && ldAccountReady,

  changeOrg: (organizationId) => {
    if (!LaunchDarklyHelper.initialized()) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject('LD client is not initialized.')
        .catch(() => {
          // ignore LD errors
        });
    }
    if (currentOrgId === organizationId) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject('Do not change organization.')
        .catch(() => {
          // ignore LD errors
        });
    }
    const orgUser = LaunchDarklyHelper.generateUserKey('organization', organizationId);
    return ldClient.identify({
      key: orgUser
    });
  },

  changeAccount: (accountId) => {
    if (!LaunchDarklyHelper.accountClientInitialized()) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject('LD client is not initialized.')
        .catch(() => {
          // ignore LD errors
        });
    }
    if (currentAccountId === accountId) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject('Do not change account.')
        .catch(() => {
          // ignore LD errors
        });
    }
    const accountKey = LaunchDarklyHelper.generateUserKey('account', accountId);
    return ldAccountClient.identify({
      key: accountKey
    });
  },

  allFlags: () => {
    if (!LaunchDarklyHelper.initialized()) {
      return {};
    }
    return ldClient.allFlags();
  },

  allAccountFlags: () => {
    if (!LaunchDarklyHelper.accountClientInitialized()) {
      return {};
    }
    return ldAccountClient.allFlags();
  },

  dispose: () => {
    if (ldClient) {
      ldClient.close();
    }
    if (ldAccountClient) {
      ldAccountClient.close();
    }
  },

  generateUserKey: (key, id) => {
    const launchDarklyPrefix = MConfigs.launchDarklyPrefix;
    return `${key}-${launchDarklyPrefix}-${id}`;
  }
};

export default LaunchDarklyHelper;
