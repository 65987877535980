import React from 'react';
import JobTimeline from './JobTimeline';
import { buildSearchCondition } from '../../../components/search/SearchUtils';

function RunningJobsCard() {

  const defaultSearchConditions = [
    buildSearchCondition('status', '=', '1')
  ];
  return (
    <JobTimeline
      defaultSearchConditions={defaultSearchConditions}
      title="Running Jobs"
    />
  );
}

export default RunningJobsCard;
