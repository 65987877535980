import { STORAGE_TYPE, TestProjectType } from './Constants';

const TestProjectHelper = {
  getStorageType: (testProject) => {
    if (!testProject) {
      return STORAGE_TYPE.UPLOADED_DATA;
    }

    switch (testProject.type) {
      case TestProjectType.GIT:
        return STORAGE_TYPE.GIT;
      case TestProjectType.CLOUD:
        return STORAGE_TYPE.KATALON_STORAGE;
      case TestProjectType.KS:
        return STORAGE_TYPE.UPLOADED_REPO;
      default:
        return null;
    }
  }
};

export default TestProjectHelper;
