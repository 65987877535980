import { TestProject } from './TestProject';
import { TestCase } from './TestCase';
import MFlags from '../MFlags';
import { Source } from './Source';

export interface TestSuite {
  id: number;
  name: string;
  path: string;
  type: string;
  testProject?: TestProject;
  testCases?: TestCase[];
  source?: Source;
  deleted?: boolean;
}

export function isSystemTestSuite(testSuite: TestSuite) {
  return MFlags.instantRunTestCasesEnabled && testSuite?.source === Source.SYSTEM;
}
