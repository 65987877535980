import React from 'react';
import { Dialog } from '@mui/material';
import PropTypes from 'prop-types';

export default function DialogComponent({ id, isOpen, children, maxWidth = 'xs', disablePortal }) {
  return (
    <Dialog
      id={id}
      maxWidth={maxWidth}
      fullWidth
      open={isOpen}
      className="dialog-popup"
      disablePortal={disablePortal}
    >
      {children}
    </Dialog>
  );
}

DialogComponent.propTypes = {
  id: PropTypes.string,
  isOpen: PropTypes.bool,
  children: PropTypes.node,
  maxWidth: PropTypes.string,

  /**
   *  Disable the portal behavior. The children stay within it's parent DOM hierarchy.
   */
  disablePortal: PropTypes.bool,
};

DialogComponent.defaultProps = {
  disablePortal: false
};
