import React from 'react';
import { Button, Col, Form, FormGroup, FormText, Label, Row } from 'reactstrap';
import platform from 'platform-detect';
import arch from 'arch';
import { isEmpty } from 'lodash';
import { t } from '../../i18n/t';
import RouteConstants from '../../utils/RouteConstants';
import Services from '../../utils/Services';
import Stepper from '../../components/wizard/Stepper';
import DocumentLink from '../../utils/DocumentLink';
import CodeBox from '../../components/CodeBox';
import Routes from '../../utils/Routes';
import MConfigs from '../../models/MConfigs';
import Input from '../../components/Input';
import Select from '../../components/Select';
import MAuth from '../../models/MAuth';
import MContext from '../../models/MContext';
import Link from '../../components/Link';
import MFlags from '../../models/MFlags';
import Arrays from '../../utils/Arrays';
import SelectApiKeyComponent from '../../components/SelectApiKeyComponent';

const DEFAULT_AGENT_NAME = 'My Agent';

class AgentSetupLocal extends React.Component {

  constructor(props) {
    super(props);
    this.user = MAuth.user;
    this.serverUrl = MConfigs.serverUrl;
    this.teamId = MContext.teamId;
    this.organizationId = MContext.organizationId;
    this.initState();
    this.handleChangeText = this.handleChangeText.bind(this);
    this.handleSelectApiKey = this.handleSelectApiKey.bind(this);
    this.handleSelectOS = this.handleSelectOS.bind(this);
    this.handleDownloadAgentExecutable = this.handleDownloadAgentExecutable.bind(this);
    if (MFlags.moveAgentToOrgLevelPhase2Enabled) {
      this.osOptions = Object.entries(MConfigs.agentDownloadUrls)
        .map(([label, value]) => ({
          label,
          value,
        }));
    } else {
      this.osOptions = [
        {
          label: 'Linux x64',
          value: 'https://github.com/katalon-studio/katalon-agent/releases/download/v1.7.8/katalon-agent-linux-x64-v1.7.8.zip',
        },
        {
          label: 'Windows x64',
          value: 'https://github.com/katalon-studio/katalon-agent/releases/download/v1.7.8/katalon-agent-win-x64-v1.7.8.zip',
        },
        {
          label: 'Windows x86',
          value: 'https://github.com/katalon-studio/katalon-agent/releases/download/v1.7.8/katalon-agent-win-x86-v1.7.8.zip',
        },
        {
          label: 'MacOS x64',
          value: 'https://github.com/katalon-studio/katalon-agent/releases/download/v1.7.8/katalon-agent-macos-x64-v1.7.8.zip',
        },
      ];
    }

  }

  componentDidMount() {
    const { moveAgentToOrgLevelPhase2Enabled, searchApiKeyEnabled } = MFlags;
    if (!searchApiKeyEnabled) {
      this.getApiKeys();
    } else {
      this.getOS();
    }
    if (moveAgentToOrgLevelPhase2Enabled) {
      this.getLastAccessedProject();
    }
  }

  getLastAccessedProject() {
    Services.getLastAccessedProject(this.user.id, this.organizationId)
      .then((lastAccessedProject) => {
        if (!isEmpty(lastAccessedProject)) {
          this.setState({
            lastAccessedProject: lastAccessedProject[0]
          });
        }
      });
  }

  initState() {
    this.state = {
      agentName: DEFAULT_AGENT_NAME,
      apiKey: null,
      apiKeys: [],
      selectedOS: null,
      disableDownloadAgent: true,
      configAgentCommand: '',
      startAgentCommand: '',
      lastAccessedProject: null,
    };
  }

  generateCommand() {
    const { agentName, apiKey, selectedOS } = this.state;

    let command = 'cli-win-x64'; // Default
    if (selectedOS) {
      const name = selectedOS.label;
      if (name === 'Linux x64') {
        command = './cli-linux-x64';
      } else if (name === 'Linux x86') {
        command = './cli-linux-x86';
      } else if (name === 'MacOS x64') {
        command = './cli-macos-x64';
      } else if (name === 'Windows x64') {
        command = 'cli-win-x64';
      } else if (name === 'Windows x86') {
        command = 'cli-win-x86';
      }
    }

    let configAgentCommand = `${command} config --server-url ${this.serverUrl} --username ${this.user.email} --apikey ${apiKey?.value} --teamid ${this.teamId} --agent-name "${agentName.trim()}"`;

    if (MFlags.moveAgentToOrgLevelPhase2Enabled) {
      configAgentCommand =
        `${command} config --server-url ${this.serverUrl} --username ${this.user.email} --apikey ${apiKey?.value} --organizationid ${this.organizationId} --agent-name "${agentName.trim()}"`;
    }
    const startAgentCommand = `${command} start-agent`;

    this.setState({
      configAgentCommand,
      startAgentCommand,
    });
  }

  handleChangeText(event) {
    const value = event.target.value;
    const name = event.target.name;
    this.setState(
      { [name]: value },
      () => {
        this.generateCommand();
      }
    );
  }

  handleSelectApiKey(apiKey) {
    this.setState({ apiKey }, () => {
      this.generateCommand();
    });
  }

  handleSelectOS(osOption) {
    this.setState({
      selectedOS: osOption,
      disableDownloadAgent: false,
    }, () => {
      this.generateCommand();
    });
  }

  getOS() {
    let defaultOS;
    try {
      let os = 'Windows';
      if (platform.linux) {
        os = 'Linux';
      } else if (platform.macos) {
        os = 'MacOS';
      }
      defaultOS = `${os} ${arch()}`;
    } catch (e) {
      console.error('Cannot detect platform', e);
      defaultOS = 'Windows x64';
    }

    const userOS = this.osOptions.find((option) => option.label === defaultOS);

    this.setState({
      selectedOS: userOS,
      disableDownloadAgent: false,
    }, () => {
      this.generateCommand();
    });
  }

  handleDownloadAgentExecutable(event) {
    event.preventDefault();
    const { selectedOS = {} } = this.state;
    window.open(selectedOS.value);
  }

  getApiKeys() {
    Services.getApiKeys()
      .then((response) => {
        const apiKeys = response.map((apikey) => ({
          value: apikey.key,
          label: apikey.name,
        }));

        this.setState({
          apiKeys,
          apiKey: apiKeys[0],
        }, () => {
          this.getOS();
        });
      });
  }

  renderAgentDownload() {
    const { selectedOS, disableDownloadAgent } = this.state;
    const url = selectedOS && selectedOS.value;

    return (
      <Row>
        <Col sm="12" md="8" lg="6" xl="5">
          <FormGroup>
            <Label for="agentName">{t('agent#selectOS')}</Label>
            <Select
              clearable={false}
              onChange={this.handleSelectOS}
              value={selectedOS}
              options={this.osOptions}
            />
          </FormGroup>
          {url && (
            <FormGroup>
              <a
                target="_blank"
                rel="noreferrer noopener"
                href={url}
              >
                {url}
              </a>
            </FormGroup>
          )}
          <FormGroup>
            <Button
              color="primary"
              onClick={this.handleDownloadAgentExecutable}
              disabled={disableDownloadAgent}
              data-trackid="download-agent"
            >
              {t('agent#downloadAgent')}
            </Button>
          </FormGroup>
        </Col>
      </Row>
    );
  }

  renderAgentConfig() {
    const { configAgentCommand } = this.state;
    return (
      <FormGroup>
        <CodeBox
          content={configAgentCommand}
        />
      </FormGroup>
    );
  }

  renderStartAgent() {
    const { startAgentCommand } = this.state;
    return (
      <FormGroup>
        <CodeBox
          content={startAgentCommand}
        />
      </FormGroup>
    );
  }

  renderCreatePlan() {
    const { moveAgentToOrgLevelPhase2Enabled } = MFlags;
    const { lastAccessedProject } = this.state;

    if (moveAgentToOrgLevelPhase2Enabled) {
      let link;
      if (!lastAccessedProject) {
        const routes = new Routes();
        link = routes.organization_home_link;
      } else {
        const { id: projectId, teamId } = lastAccessedProject;
        const routes = new Routes({ teamId, projectId });
        link = routes.smart_test_scheduling_link();
      }
      return (
        <span>Once the agent has been successfully assigned to your projects, it&apos;s time to <a href={link}>schedule your test runs</a>.</span>
      );
    }

    const routes = new Routes();
    return (
      <span>After the agent starts successfully, navigate to <Link href={routes.agent_link}>Test Environments</Link> and schedule a sample test run.</span>
    );
  }

  renderAssignToProjects() {
    const routes = new Routes();
    return (
      <span>After the agent successfully starts, navigate to the <Link href={routes.organization_setting_agent_link}>Test Environments</Link> and assign the agent to your projects.</span>
    );
  }

  renderAgentConfiguration() {
    const { searchApiKeyEnabled } = MFlags;
    const { agentName, apiKey, apiKeys } = this.state;
    return (
      <Row>
        <Col sm="12" md="8" lg="6" xl="5">
          <FormGroup>
            <Label for="agentName">{t('agent#agent-name')}</Label>
            <Input
              type="text"
              id="agentName"
              name="agentName"
              value={agentName}
              onChange={this.handleChangeText}
            />
          </FormGroup>
          <FormGroup>
            <Label for="apiKey">{t('agent#api-key')}</Label>
            {searchApiKeyEnabled ?
              <SelectApiKeyComponent onChange={this.handleSelectApiKey} />
              :
              <Select
                clearable={false}
                id="apiKey"
                value={apiKey}
                onChange={this.handleSelectApiKey}
                options={apiKeys}
              />}
            <FormText color="muted">
              {t('agent#generate-api-key')}
              <a
                href={RouteConstants.apikey}
              >
                here.
              </a>
            </FormText>
          </FormGroup>
        </Col>
      </Row>
    );
  }

  renderStepToSetup() {
    const { moveAgentToOrgLevelPhase2Enabled } = MFlags;

    const steps = [
      {
        label: t('agent#step#download'),
        content: this.renderAgentDownload(),
        description: (
          <>
            Select your compatible OS to download and <b>unzip</b> the Agent executable file.
          </>
        ),
      },
      {
        label: t('agent#step#config'),
        content: this.renderAgentConfiguration(),
        description: 'This information will be displayed in the agent command.',
      },
      {
        label: t('agent#step#configCommand'),
        content: this.renderAgentConfig(),
        description: (
          <>
            Open your Command-line Interface and navigate to the directory containing the Agent executable file.
            <br />
            Copy & paste this command to your Command-line Interface.
          </>
        ),
      },
      {
        label: t('agent#step#startCommand'),
        content: this.renderStartAgent(),
        description: (
          <>
            Use this command to start the agent.
            <a
              href={DocumentLink.START_AGENT}
              target="_blank"
              rel="noreferrer noopener"
            >
              {t('agent#docs#learnMore')}
            </a>
          </>
        ),
      },
      ...Arrays.insertIf(
        !moveAgentToOrgLevelPhase2Enabled,
        {
          label: t('agent#step#create-plan'),
          content: this.renderCreatePlan(),
        },
      ),
      ...Arrays.insertIf(
        moveAgentToOrgLevelPhase2Enabled,
        {
          label: t('agent#step#assign-to-project'),
          content: this.renderAssignToProjects(),
        },
        {
          label: moveAgentToOrgLevelPhase2Enabled ? t('agent#step#schedule-test-runs') : t('agent#step#create-plan'),
          content: this.renderCreatePlan(),
        }
      ),
    ];

    return (
      <Form>
        <Stepper
          id="agent-setup"
          data-trackid="agent-setup"
          expanded
          steps={steps}
        />
      </Form>
    );
  }

  render() {
    return this.renderStepToSetup();
  }
}

export default AgentSetupLocal;
