import React from 'react';
import PropTypes from 'prop-types';
import { get, isEmpty } from 'lodash';
import MTableColumnDataMapping from '../../components/table/models/MTableColumnDataMapping';
import { t } from '../../i18n/t';
import DecoratorConstants from '../../utils/DecoratorConstants';
import { buildDefaultFilter, buildFilter } from '../search-query/FilterQueryHelper';
import { buildSortQueryV2, buildSortQueryWithNull } from '../search-query/SortQueryHelper';
import { TEST_SUITE_PAGE_SIZE, KATALON_EVENTS, TestProjectType, TestSuiteType } from '../../utils/Constants';
import DataTable from './DataTable';
import TimeFilterV2 from '../search-query/filter/TimeFilterV2';
import InputFilterV2 from '../search-query/filter/InputFilterV2';
import StatusFilterV2 from '../search-query/filter/StatusFilterV2';
import MFlags from '../../models/MFlags';
import Arrays from '../../utils/Arrays';
import FixedActionMenu from '../cloud-studio-component/menu/FixedActionMenu';
import { DomEventHandlers } from '../../utils/EventHandler';
import VerticalTestManagementActions from '../cloud-studio-component/menu/VerticalTestManagementActions';
import { isSystemTestSuite } from '../../models/model/TestSuite';

class TestSuiteDataTableV2 extends React.Component {

  constructor(props) {
    super(props);
    this.dataLoaderRef = React.createRef();
    this.dataTableRef = React.createRef();
    this.handleRefresh = this.handleRefresh.bind(this);
    this.isSupportMultipleSelect = this.isSupportMultipleSelect.bind(this);
    this.handleSingleSelectTestSuite = this.handleSingleSelectTestSuite.bind(this);
  }

  componentDidMount() {
    this.resetSelectedTestSuite();
    DomEventHandlers.eventListener(KATALON_EVENTS.duplicatedG5TestSuite, this.handleRefresh);
    DomEventHandlers.eventListener(KATALON_EVENTS.renamedG5TestSuite, this.handleRefresh);
    DomEventHandlers.eventListener(KATALON_EVENTS.deletedG5TestSuite, this.handleRefresh);
  }

  resetSelectedTestSuite() {
    if (this.dataTableRef.current) {
      this.dataTableRef.current.setSelectedRows([]);
    }
  }

  handleRefresh() {
    this.resetSelectedTestSuite();
    if (this.dataLoaderRef.current) {
      this.dataLoaderRef.current.refreshData();
    }
  }
  hasAtLeastOneTestSuiteSelected() {
    const selectedTestCases = this.getSelectedRows();
    return selectedTestCases.length > 0;
  }
  getSelectedRows() {
    const { isSupportMultipleSelect } = this.props;
    if (!isSupportMultipleSelect || !this.dataTableRef.current) {
      return [];
    }
    return this.dataTableRef.current.getSelectedRows();
  }
  isTestSuiteSelected(testSuite) {
    const selectedTestSuites = this.getSelectedRows();
    return selectedTestSuites.some((item) => item.id === testSuite.id);
  }

  isSupportMultipleSelect(data) {
    const { isSupportMultipleSelect } = this.props;
    if (data.length === 0) return false;
    const testSuite = data[0];
    if (!testSuite.testProject) return false;
    const isG5CloudTestSuite = testSuite.type === TestSuiteType.CLOUD_STUDIO && !isSystemTestSuite(testSuite) && testSuite.testProject.type === TestProjectType.CLOUD;
    const isG4TOTestSuite = testSuite.type === TestSuiteType.TESTOPS;
    return isSupportMultipleSelect && (isG5CloudTestSuite || isG4TOTestSuite);
  }

  handleSingleSelectTestSuite(data) {
    const { isSupportMultipleSelect } = this.props;

    if (isSupportMultipleSelect && this.hasAtLeastOneTestSuiteSelected()) {
      this.dataTableRef.current.setSelectedRows([data.testSuite]);
    }
  }
  renderTestManagementActions(selectedTestSuites) {
    if (isEmpty(selectedTestSuites)) {
      return null;
    }
    const testEntities = selectedTestSuites.map((item) => ({ testSuite: item }));

    return <VerticalTestManagementActions data={testEntities} />;
  }
  render() {

    const { tsNameTrackingProps, isSearchResultsPage } = this.props;
    const widthResultColumns = '3.69rem';

    const headers = [
      new MTableColumnDataMapping(
        t('name'),
        'name',
        (name, row) => DecoratorConstants.testSuiteNameTruncateDecorator(name, row, tsNameTrackingProps),
        'short-text-column',
        '',
        buildSortQueryV2('name', true),
        isSearchResultsPage ? '20%' : '30%',
      ),
      new MTableColumnDataMapping(
        t('path'),
        '',
        (name, row) => {
          const sourcePath = DecoratorConstants.addSourceOfTestCaseAndTestSuite(row.path, row.testProject);
          return DecoratorConstants.textMiddleTruncateDecorator(sourcePath);
        },
        undefined,
        '',
        undefined,
        isSearchResultsPage ? '30%' : '25%',
      ),
      new MTableColumnDataMapping(
        t('last_updated'),
        'updatedAt',
        (name, row) => {
          if (row.updatedAt) {
            return DecoratorConstants.timeDecorator(name, row);
          } else {
            return null;
          }
        },
        undefined,
        'nowrap-column',
        buildSortQueryV2('updatedAt')
      ),
      new MTableColumnDataMapping(
        t('last_executed'),
        'lastExecutionTestSuite.startTime',
        (name, row) => {
          if (row.lastExecutionTestSuite) {
            return DecoratorConstants.timeDecorator(name, row);
          } else {
            return null;
          }
        },
        undefined,
        'nowrap-column',
        buildSortQueryWithNull('ExecutionStatistics.startTime')
      ),
      new MTableColumnDataMapping(
        DecoratorConstants.passedBadgeDecorator(t('table-header#totalPassed')),
        'lastExecutionTestSuite.totalPassedTests',
        DecoratorConstants.passedCountDecorator,
        undefined,
        '',
        undefined,
        widthResultColumns
      ),
      new MTableColumnDataMapping(
        DecoratorConstants.failedBadgeDecorator(t('table-header#totalFailed')),
        'lastExecutionTestSuite.totalFailedTests',
        DecoratorConstants.failedCountDecorator,
        undefined,
        '',
        undefined,
        widthResultColumns
      ),
      new MTableColumnDataMapping(
        DecoratorConstants.errorBadgeDecorator(t('table-header#totalError')),
        'lastExecutionTestSuite.totalErrorTests',
        DecoratorConstants.errorCountDecorator,
        undefined,
        '',
        undefined,
        widthResultColumns
      ),
      new MTableColumnDataMapping(
        DecoratorConstants.incompleteBadgeDecorator(t('table-header#totalIncomplete')),
        'lastExecutionTestSuite.totalIncompleteTests',
        DecoratorConstants.incompleteCountDecorator,
        undefined,
        '',
        undefined,
        widthResultColumns
      ),
      new MTableColumnDataMapping(
        DecoratorConstants.skippedBadgeDecorator(t('table-header#totalSkipped')),
        'lastExecutionTestSuite.totalSkippedTests',
        DecoratorConstants.skippedCountDecorator,
        undefined,
        '',
        undefined,
        widthResultColumns
      ),
      ...Arrays.insertIf(
        !isSearchResultsPage,
        new MTableColumnDataMapping(
          t('table-header#action'),
          'id',
          (name, row) => {
            const id = get(row, name);
            const actionData = { testSuite: row };
            return (
              <FixedActionMenu
                id={id}
                data={actionData}
                disabledButton={this.isTestSuiteSelected(row) && this.getSelectedRows().length > 1}
                handleClick={this.handleSingleSelectTestSuite}
              />
            );
          },
          true,
        ),
      ),
    ];

    const defaultFilter = buildDefaultFilter('name', '~', 'Name');
    const filterQuery = [
      buildFilter(InputFilterV2, { id: 'path', label: t('path'), operator: '~', placeholder: t('search-bar#placeholder-path') }),
      buildFilter(StatusFilterV2, { id: 'ExecutionStatistics.status' }),
      buildFilter(TimeFilterV2, { id: 'ExecutionStatistics.startTime', label: t('search-bar#last-executed') }),
    ];
    const newProps = {
      ...this.props,
      filterQuery,
      defaultFilter,
    };

    return (
      <DataTable
        unitName="test suite"
        entityType="TestSuite"
        columnMapping={headers}
        useSearchQuery
        useNewSearchBar
        useSortByColumn
        fixLayout
        placeHolder={t('search#test-suite')}
        pageSize={TEST_SUITE_PAGE_SIZE}
        {...newProps}
        ref={this.dataLoaderRef}
        dataTableRef={this.dataTableRef}
        isSupportMultipleSelect={this.isSupportMultipleSelect}
        renderBulkActions={this.renderTestManagementActions}
      />
    );
  }
}

TestSuiteDataTableV2.propTypes = {
  isSearchResultsPage: PropTypes.bool,
  tsNameTrackingProps: PropTypes.object,
};

TestSuiteDataTableV2.defaultProps = {
  tsNameTrackingProps: null,
  isSearchResultsPage: false,
};

export default TestSuiteDataTableV2;
