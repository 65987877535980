import React from 'react';
import { DialogContent, DialogContentText, DialogActions } from '@mui/material';
import { Button, ButtonToolbar } from 'reactstrap';
import PropTypes from 'prop-types';
import CloseableDialogComponent from './CloseableDialogComponent';
import { t } from '../../i18n/t';
import GroupEvent from '../../utils/track/GroupEvent';

function DeleteIgnoredZoneDialog({ id, isOpen, handleClose, handleDelete }) {
  const trackCloseIcon = {
    'data-trackId': 'close-delete-ignored-zone-all-images',
    'data-groupId': GroupEvent.ACCESS_REPORT,
  };

  return (
    <CloseableDialogComponent
      id={id}
      isOpen={isOpen}
      handleClose={handleClose}
      title={t('visual-testing#apply-all#delete-title')}
      trackCloseIcon={trackCloseIcon}
    >
      <DialogContent>
        <DialogContentText>
          <div className="title m-0 text-left">
            {t('visual-testing#apply-all#delete-content')}
          </div>
        </DialogContentText>
      </DialogContent>
      <DialogActions className="popup-footer justify-content-end p-0">
        <ButtonToolbar>
          <Button
            id="cancel-confirmation-delete"
            title={t('cancel')}
            color="secondary"
            onClick={handleClose}
            data-trackid="cancel-delete-ignored-zone-all-images"
            data-groupid={GroupEvent.ACCESS_REPORT}
          >
            {t('cancel')}
          </Button>
          <Button
            id="button-confirmation-delete"
            title={t('delete')}
            color="primary"
            onClick={handleDelete}
            data-trackid="confirm-delete-ignored-zone-all-images"
            data-groupid={GroupEvent.ACCESS_REPORT}
          >
            {t('visual-testing#apply-all#delete-button-title')}
          </Button>
        </ButtonToolbar>
      </DialogActions>
    </CloseableDialogComponent>
  );
}

DeleteIgnoredZoneDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  id: PropTypes.string,
  handleDelete: PropTypes.func.isRequired
};

export default DeleteIgnoredZoneDialog;
