import React from 'react';
import Cookies from 'cookies-js';
import ReactS3Uploader from 'react-s3-uploader';

class Uploader extends React.Component {

  constructor(props) {
    super(props);
    this.fileUploader = null;
    this.clear = this.clear.bind(this);
    this.uploadFile = this.uploadFile.bind(this);
  }

  clear() {
    this.fileUploader.clear();
  }

  uploadFile() {
    this.fileUploader.uploadFile();
  }

  render() {
    const {
      uploadRequestHeaders = {},
      disabled
    } = this.props;
    const newUploadRequestHeaders = {
      ...uploadRequestHeaders,
      'X-XSRF-TOKEN': Cookies.get('XSRF-TOKEN'),
    };
    return (
      <ReactS3Uploader
        {...this.props}
        signingUrlHeaders={{'Authorization': 'Bearer ' + Cookies.get('access_token')}}
        uploadRequestHeaders={newUploadRequestHeaders}
        ref={(uploader) => {
          this.fileUploader = uploader;
        }}
        disabled={disabled}
      />
    );
  }
}

export default Uploader;
