import { sendAnalyticEventForAction } from './SegmentAnalytics';

const checkTags = new Set(['svg', 'path', 'g', 'span', 'img', 'p', 'time']);

/**
 * Prepare data when tracking action from event to sent Analytics App.
 * Foreach action had trackId, this action will be sent to Analytics App.
 *
 * If you want to track some action, please add
 *      data-trackid for component you want to track.
 *
 * If you want to override groupId for action, please add:
 *      data-groupid below data-trackId
 *      data-groupid {array}
 *
 * Example:
 *       Page: meta.groupId=['sample-group1']
 *      <Form
 *        data-trackid='sample-event'
 *        data-groupid=['sample-group1', 'sample-group2']
 *      >
 *      groupid for this component will be ['sample-group1', 'sample-group2'].
 *
 *
 *      Page: meta.groupId=['sample-group2']
 *      <Form
 *        data-trackid='sample-event'
 *        data-groupid='sample-group2'
 *      >
 *      groupid for this component will be ['sample-group2'].
 *
 *
 *       Page: meta.groupId=['sample-group1']
 *       <Form
 *        data-trackid='sample-event'
 *       >
 *       groupid will be same groupid the page render this component.
 *       groupid for this component will be ['sample-group1'].
 *
 * @param event
 */
const sendAnalyticForEvent = (event) => {
  let {
    trackid: trackId,
    trackcategory: trackCategory,
    tracklabel: trackLabel,
    groupid: groupId,
    properties // must be json string. e.g. '{ team: 'TestOps' }'
  } = event.target.dataset;

  const tagName = event.target.tagName;

  // if target is svg, check parents
  if (checkTags.has(tagName.toLowerCase())) {
    let node = event.target.parentNode;
    while (node) {
      if (node.tagName !== 'form' && node.dataset && node.dataset.trackid) {
        trackId = node.dataset.trackid;
        trackCategory = node.dataset.trackcategory;
        trackLabel = node.dataset.tracklabel;

        if (node.dataset.groupid) {
          groupId = node.dataset.groupid;
        }

        if (node.dataset.properties) {
          properties = node.dataset.properties;
        }

        break;
      }
      node = node.parentNode;
    }
  }

  if (properties) {
    try {
      properties = JSON.parse(properties);
    } catch (e) {
      console.error('Cannot parse properties', e);
    }
  }

  if (trackId) {
    const data = {
      category: trackCategory,
      label: trackLabel,
      groupId,
      ...properties
    };
    sendAnalyticEventForAction(trackId, data);
  }
};

export const trackingEventHandlers = {
  /**
   * Listen event when user use form to submit data
   * Record this action and sent event to Analytics App
   */
  submit: sendAnalyticForEvent,

  /**
   * Listen event when user click button, link or anything
   * Record this action and sent event to Analytics App
   */
  click: sendAnalyticForEvent,
};

export const DomEventHandlers = {

  /**
   * Create and dispatch DOM events
   * @param {string} eventName - name of the trigger event, e.g. 'duplicatedTestCase'
   */
  createEvent(eventName, data = {}) {
    const event = new CustomEvent(eventName, data);
    document.dispatchEvent(event);
  },

  createEventToParent(eventName, data = {}) {
    const event = new CustomEvent(eventName, data);
    window.top.document.dispatchEvent(event);
  },

  /**
   * Listen from DOM events
   * @param {string} eventName - name of the trigger event, e.g. 'duplicatedTestCase'
   * @param {Function} handler - function to attach for event as a listener
   */
  eventListener(eventName, handler) {
    document.addEventListener(eventName, handler);
  }
};
