import React from 'react';
import { ListItem, ListItemIcon, ListItemText, List, Popover } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { NavItem } from 'reactstrap';
import { next } from '../../utils/Count';
import NavLink from '../NavLink';
import Helper from '../../utils/Helper';

const useStyles = makeStyles(() => ({
  popover: {
    pointerEvents: 'none'
  },
  popoverContent: {
    pointerEvents: 'auto',
    width: '256px',
  },
}));

export default function DropDownAuto(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorPopover, setAnchorPopover] = React.useState(null);

  const timerToClearPopoverClose = React.useRef(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    timerToClearPopoverClose.current = setTimeout(() => {
      setAnchorEl(null);
    }, 100);
  };

  const handlePopoverEnter = (event) => {
    clearTimeout(timerToClearPopoverClose.current);
    setAnchorPopover(event.currentTarget);
  };

  const handlePopoverLeave = () => {
    setAnchorPopover(null);
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const {
    key,
    item,
    element,
  } = props;
  let className;
  if (item.active && Helper.isCollapsedNavBar()) {
    className = 'active';
  } else {
    className = 'inactive';
  }
  return (
    <>
      <NavItem>
        <NavLink
          isApp={Boolean(item.items)}
          exactMatch={item.exactMatch}
          title={item.name}
          className={className}
          href={item.active ? '' : item.link}
          pattern={item.pattern}
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
          aria-owns={`mouse-over-popover-${key}`}
          aria-haspopup="true"
        >
          {element}
        </NavLink>
      </NavItem>
      <Popover
        onMouseEnter={handlePopoverEnter}
        onMouseLeave={handlePopoverLeave}
        id={`mouse-over-popover-${key}`}
        className={classes.popover}
        classes={{
          paper: classes.popoverContent
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        aria-owns={`mouse-over-popover-${key}`}
        aria-haspopup="true"
      >
        <div className="list-nav-bar">
          <List component="nav">
            {item && item.items && item.items.map(({ name, link, icon, iconInactive, pattern, exactMatch }) => {
              const href = pattern || link;
              const url = window.location.href;
              let active;
              if (Array.isArray(href)) {
                active = Helper.exactMatches(href, url, exactMatch);
              } else {
                active = Helper.exactMatches(Array.of(href), url, exactMatch);
              }
              const className = active ? 'dropdown-nav-item-active' : 'dropdown-nav-item';

              return (
                <div className={className}>
                  <ListItem key={next()} button component="a" href={link}>
                    {icon && iconInactive && (
                      <ListItemIcon>
                        <div className="icon-inactive">
                          {iconInactive}
                        </div>
                        <div className="icon-active">
                          {icon}
                        </div>
                      </ListItemIcon>
                    )}
                    <ListItemText primary={name} />
                  </ListItem>
                </div>
              );
            })}
          </List>
        </div>
      </Popover>
    </>
  );
}
