import React from 'react';
import { Button, Form, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import Services from '../../utils/Services';
import Notification from '../../utils/Notification';
import { t } from '../../i18n/t';
import { WAITING_TIME_FOR_TOAST } from '../../utils/Constants';
import MFlags from '../../models/MFlags';

class DeleteTestProjectDialog extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.saveDelete = this.saveDelete.bind(this);
    this.cancelDelete = this.cancelDelete.bind(this);

    this.state = {
      modal: false,
    };
  }

  toggle() {
    const { modal } = this.state;
    this.setState({
      modal: !modal,
    });
  }

  saveDelete(e) {
    e.preventDefault();
    Services.deleteTestProject(this.props.id).then(() => {
      Notification.pushSuccessPermanent(t('test_project_delete_success_and_wait', { id: this.props.id }));
      this.useAfterCreate();
      this.toggle();
    }).catch(() => {
      this.toggle();
    });
  }

  useAfterCreate() {
    const { afterCreate } = this.props;
    if (afterCreate) {
      afterCreate();
    }
  }

  cancelDelete() {
    this.toggle();
  }

  render() {
    return (
      <div>
        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <Form data-trackid="delete-test-project" onSubmit={this.saveDelete}>
            <ModalHeader>{t('test_project_delete')}</ModalHeader>
            <ModalBody>
              {t('test_project_delete_confirm')} <br />
              <strong>{this.props.name}</strong>
            </ModalBody>
            <ModalFooter>
              <Button type="submit" color="danger">{t('delete')}</Button>
              <Button color="secondary" onClick={this.cancelDelete}>{t('cancel')}</Button>
            </ModalFooter>
          </Form>
        </Modal>
      </div>
    );
  }
}


export default DeleteTestProjectDialog;
