import React, { useEffect, useState } from 'react';
import { Alert, Button, Col, Row } from 'reactstrap';
import { isEmpty } from 'lodash';
import MFlags from '../../../models/MFlags';
import { t } from '../../../i18n/t';
import { IconPlus, IconDelete } from '../../../images/CustomNewIcon';
import CopyingJiraWebhookDialog from './CopyingJiraWebhookDialog';
import NamingJiraWebhookDialog from './NamingJiraWebhookDialog';
import UrlHelper from '../../../utils/UrlHelper';
import Services from '../../../utils/Services';
import DocumentLink from '../../../utils/DocumentLink';
import WarningJiraWebhookDialog from './WarningJiraWebhookDialog';
import DeleteJiraWebhookDialog from './DeleteJiraWebhookDialog';
import MContext from '../../../models/MContext';
import Time from '../../../utils/Moment';
import Notification from '../../../utils/Notification';

function JiraWebhookSetting(props) {

  const projectId = MContext.projectId;
  const [jiraWebhook, setJiraWebhook] = useState();
  const [isOpenNamingJiraWebhookDialog, setOpenNamingJiraWebhookDialog] = useState(false);
  const [isOpenCopyingJiraWebhookDialog, setOpenCopyingJiraWebhookDialog] = useState(false);
  const [isOpenWarningJiraWebhookDialog, setOpenWarningJiraWebhookDialog] = useState(false);
  const [isOpenDeleteJiraWebhookDialog, setOpenDeleteJiraWebhookDialog] = useState(false);
  const [isDisabledCreateJiraWebhookBtn, setDisabledCreateJiraWebhookBtn] = useState(true);
  const [jiraWebhookName, setJiraWebhookName] = useState('');

  const getJiraWebhook = () => {
    Services.getJiraWebhook(projectId)
      .then((jiraWebhook) => {
        if (jiraWebhook && !isEmpty(jiraWebhook)) {
          setJiraWebhook(jiraWebhook);
          setJiraWebhookName(jiraWebhook.name);
        } else {
          setJiraWebhook(undefined);
        }
      });
  };

  useEffect(() => {
    Services.getJiraConnection(projectId)
      .then(({ content }) => {
        const connection = content[0];
        if (connection) {
          setDisabledCreateJiraWebhookBtn(false);
          getJiraWebhook();
        }
      });
  }, []);

  const toggleNamingJiraWebhookDialog = () => {
    setOpenNamingJiraWebhookDialog(!isOpenNamingJiraWebhookDialog);
  };

  const toggleCopyingJiraWebhookDialog = () => {
    setOpenCopyingJiraWebhookDialog(!isOpenCopyingJiraWebhookDialog);
  };

  const toggleWarningJiraWebhookDialog = () => {
    setOpenWarningJiraWebhookDialog(!isOpenWarningJiraWebhookDialog);
  };

  const toggleDeleteJiraWebhookDialog = () => {
    setOpenDeleteJiraWebhookDialog(!isOpenDeleteJiraWebhookDialog);
  };

  const handleChangeWebhookName = (e) => {
    setJiraWebhook((jiraWebhook) => ({
      ...jiraWebhook,
      name: e.target.value,
    }));
  };

  const handleCreateOrRotateJiraWebhook = (e) => {
    e.preventDefault();
    if (jiraWebhook.endpoint === undefined) {
      Services.createJiraWebhook(projectId, jiraWebhook)
        .then((jiraWebhook) => {
          setJiraWebhook(jiraWebhook);
          setJiraWebhookName(jiraWebhook.name);
          toggleNamingJiraWebhookDialog();
          toggleCopyingJiraWebhookDialog();
        });
    } else {
      Services.rotateJiraWebhook(projectId, jiraWebhook)
        .then((jiraWebhook) => {
          setJiraWebhook(jiraWebhook);
          setJiraWebhookName(jiraWebhook.name);
          toggleNamingJiraWebhookDialog();
          toggleCopyingJiraWebhookDialog();
        });
    }
  };

  const handleCopyJiraWebhook = () => {
    UrlHelper.copyToClipboard(jiraWebhook.endpoint);
  };

  const handleRotateJiraWebhook = () => {
    toggleWarningJiraWebhookDialog();
    toggleNamingJiraWebhookDialog();
  };

  const triggerCreateOrRotateWebhook = () => {
    if (jiraWebhook === undefined) {
      toggleNamingJiraWebhookDialog();
    } else {
      toggleWarningJiraWebhookDialog();
    }
  };

  const handleDeleteJiraWebhook = () => {
    Services.deleteJiraWebhook(projectId)
      .then(() => {
        getJiraWebhook();
        toggleDeleteJiraWebhookDialog();
        Notification.pushSuccess(t('jira-webhook#delete-dialog#toast-success'));
      });
  };

  const renderNamingJiraWebhookDialog = () => (
    <NamingJiraWebhookDialog
      isOpen={isOpenNamingJiraWebhookDialog}
      handleClose={toggleNamingJiraWebhookDialog}
      handleCreateOrRotate={handleCreateOrRotateJiraWebhook}
      handleChangeName={handleChangeWebhookName}
    />
  );

  const renderCopyingJiraWebhookDialog = () => (
    <CopyingJiraWebhookDialog
      isOpen={isOpenCopyingJiraWebhookDialog}
      handleClose={toggleCopyingJiraWebhookDialog}
      handleCopy={handleCopyJiraWebhook}
      jiraWebhook={jiraWebhook}
    />
  );

  const renderWarningJiraWebhookDialog = () => (
    <WarningJiraWebhookDialog
      isOpen={isOpenWarningJiraWebhookDialog}
      handleClose={toggleWarningJiraWebhookDialog}
      handleRotate={handleRotateJiraWebhook}
    />
  );

  const renderDeleteJiraWebhookDialog = () => (
    <DeleteJiraWebhookDialog
      isOpen={isOpenDeleteJiraWebhookDialog}
      handleClose={toggleDeleteJiraWebhookDialog}
      handleDelete={handleDeleteJiraWebhook}
      jiraWebhook={jiraWebhook}
    />
  );

  const switchTabAlert = () => (
    <Alert color="primary" className="mt-3  ">
      Webhook will only update Jira tickets. To refresh all Jira project fields, please navigate to the
      <a href="#" onClick={props.setTabState} className="ml-1">Configuration tab.</a>
    </Alert>
  );

  const renderHeader = () => {
    if (MFlags.raJiraIntegrationEnhancementPhase2Enabled) {
      return (
        <>
          <div>
            {t('jira-webhook#creating#description')}
          </div>
          <div>
            <a
              href={DocumentLink.LEARN_MORE_ABOUT_USING_JIRA_WEBHOOK}
              target="_blank"
              rel="noreferrer noopener"
            >
              {t('jira-webhook#creating#learn-more')}
            </a>
          </div>
          {switchTabAlert()}
        </>
      );
    }
    return (
      <>
        <p className="jira-webhook-title">{t('jira-webhook#title')}</p>
        <div>
          {t('jira-webhook#creating#description')}
        </div>
        <div className="mt-2">
          <a
            href={DocumentLink.LEARN_MORE_ABOUT_USING_JIRA_WEBHOOK}
            target="_blank"
            rel="noreferrer noopener"
          >
            {t('jira-webhook#creating#learn-more')}
          </a>
        </div>
      </>
    );
  };

  return (
    <Row className={MFlags.raJiraIntegrationEnhancementPhase2Enabled ? '' : 'ml-3'}>
      <Col
        sm="12"
        md="8"
        lg={MFlags.raJiraIntegrationEnhancementPhase2Enabled ? '8' : '6'}
        xl={MFlags.raJiraIntegrationEnhancementPhase2Enabled ? '8' : '5'}
      >
        <div className={MFlags.raJiraIntegrationEnhancementPhase2Enabled ? '' : 'mt-4'}>
          {renderHeader()}
          <Button
            id="create-new-webhook"
            color="primary"
            className="mt-2"
            data-trackid={jiraWebhook ? 'rotate-webhook' : 'create-webhook'}
            onClick={triggerCreateOrRotateWebhook}
            disabled={isDisabledCreateJiraWebhookBtn}
          >
            <IconPlus className="ml-0 mr-2" />
            {t('jira-webhook#creating#creating-btn')}
          </Button>
          {isOpenNamingJiraWebhookDialog && renderNamingJiraWebhookDialog()}
          {isOpenCopyingJiraWebhookDialog && renderCopyingJiraWebhookDialog()}
          {isOpenWarningJiraWebhookDialog && renderWarningJiraWebhookDialog()}
          {isOpenDeleteJiraWebhookDialog && renderDeleteJiraWebhookDialog()}
        </div>
        {
          jiraWebhook?.endpoint &&
            <div className="mt-4">
              <div className="font-weight-bold">
                {t('jira-webhook#active-title')}
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <div>
                  <div className="mt-3 mb-2">
                    {jiraWebhookName}
                  </div>
                  <div className="desc-fs-12px">
                    {`${t('jira-webhook#created-at')} ${Time.convertToDate(jiraWebhook?.createdAt)}`}
                  </div>
                </div>
                <Button
                  id="create-new-webhook"
                  color="secondary"
                  className="delete-webhook-button"
                  data-trackid="delete-webhook"
                  onClick={toggleDeleteJiraWebhookDialog}
                >
                  <IconDelete />
                </Button>
              </div>
            </div>
        }
      </Col>
    </Row>
  );
}

export default JiraWebhookSetting;

