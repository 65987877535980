import { Form, FormGroup, Label } from 'reactstrap';
import React, { useEffect, useState } from 'react';
import { isEmpty, throttle } from 'lodash';
import { Dialog, DialogBody, DialogFooter, DialogHeader } from '@katalon-studio/katalon-ui/Dialog';
import Grid from '@mui/material/Grid';
import Button from '@katalon-studio/katalon-ui/Button';
import classnames from 'classnames';
import { SearchEntity, TestSuiteType } from '../../../utils/Constants';
import SelectG5Location from '../../treeview/SelectG5Location';
import AlertComponent from '../../AlertComponent';
import { t } from '../../../i18n/t';
import { IconErrorXMark, IconYellowWarning } from '../../../images/CustomNewIcon';
import { TestCase } from '../../../models/model/TestCase';
import ConvertDataHelper from '../../../utils/ConvertDataHelper';
import Input from '../../Input';
import { buildSearchCondition } from '../../search/SearchUtils';
import Services from '../../../utils/Services';
import MFlags from '../../../models/MFlags';

interface AddNewTestSuiteViewProps {
  testCases: TestCase[];
  className?: string;
  testSuiteType: string;
  newTestSuiteName: string;
  setNewTestSuiteName: (testSuiteName: string) => void;
  newTestSuiteLocation: string;
  setNewTestSuiteLocation: (testSuite: string) => void;
  setSwitchToAddTestSuite: (switchToAddTestSuite: boolean) => void;
  handleCreateAndAdd: () => void;
  handleClose: () => void;
  isOpen: boolean,
}

function AddNewTestSuiteView(props: AddNewTestSuiteViewProps) {
  const {
    testCases,
    className,
    newTestSuiteName,
    setNewTestSuiteName,
    newTestSuiteLocation,
    setNewTestSuiteLocation,
    setSwitchToAddTestSuite,
    handleCreateAndAdd,
    handleClose,
    testSuiteType,
    isOpen,
  } = props;
  const [isDuplicated, setDuplicated] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [isExcludedFolderPath, setIsExcludedFolderPath] = useState(false);
  const firstTestCase = testCases[0];
  const icon = ConvertDataHelper.renderRepositoryIcon(firstTestCase.testProject?.type);
  const testProjectTitle = ConvertDataHelper.buildIconTitle(firstTestCase.testProject?.name, icon);
  const testProject = firstTestCase.testProject;
  if (!testProject) {
    return null;
  }

  const onChangeExcludedPathsAlert = (isExcludedFolderPath: boolean | ((prevState: boolean) => boolean)) => {
    setIsExcludedFolderPath(isExcludedFolderPath);
    setNewTestSuiteLocation('');
  };

  const renderSelectedRepository = () => (
    <FormGroup>
      <Label>{t('repository')}</Label>
      <div className="mb-4">{testProjectTitle}</div>
    </FormGroup>
  );

  const validateTestSuiteNameAndPath = () => {
    setDuplicated(false);
    if (testProject.id) {
      const params = {
        pagination: {
          page: 0,
          size: 1,
          sorts: ['id,desc']
        },
        conditions: [
          buildSearchCondition('name', '=', newTestSuiteName),
          buildSearchCondition('path', '=', newTestSuiteLocation),
          buildSearchCondition('TestProject.id', '=', testProject.id),
        ],
        type: SearchEntity.TestSuite,
      };
      Services.search(params)
        .then(({ content }) => {
          setDuplicated(!isEmpty(content));
        });
    }
  };

  const throttledGetTestSuiteByNameAndTestProjectId = throttle(validateTestSuiteNameAndPath, 1000);

  useEffect(() => {
    throttledGetTestSuiteByNameAndTestProjectId();
  }, [newTestSuiteName, newTestSuiteLocation]);

  const onChangeTestSuiteName = (event: { target: { value: string; }; }) => {
    setNewTestSuiteName(event.target.value);
  };

  const renderWarningTestSuiteName = () => (
    <div>
      {t('publish-test-suite#test-suite-name-existing#title')}
      <li>{t('publish-test-suite#test-suite-name-existing#option1')}</li>
      <li>{t('publish-test-suite#test-suite-name-existing#option2')}</li>
    </div>
  );

  const renderInputTestSuiteName = () => (
    <FormGroup>
      <Label>{t('publish-test-suite#test-suite-name')} *</Label>
      <Input
        required
        className="publish-dialog__text"
        type="text"
        value={newTestSuiteName}
        onChange={onChangeTestSuiteName}
        placeholder={t('enter-name')}
      />
      {isDuplicated &&
        <AlertComponent
          /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
          /* @ts-ignore */
          text={renderWarningTestSuiteName()}
          icon={<IconYellowWarning />}
        />}
    </FormGroup>
  );

  const handleBack = () => {
    setSwitchToAddTestSuite(false);
  };

  const createAndAddDisabled = isEmpty(newTestSuiteName)
    || (testSuiteType === TestSuiteType.CLOUD_STUDIO && isEmpty(newTestSuiteLocation))
    || showAlert
    || (MFlags.preventNamingTestOpsInTestSuiteFoldersEnabled && isExcludedFolderPath);

  const renderFooter = () => (
    <Grid
      display="flex"
      justifyContent="left"
      container
      className="d-flex align-items-center"
    >
      <Grid item xs={6} sm={6} md={6} />
      <Grid item xs={6} sm={6} md={6} container spacing={2} className="d-flex justify-content-end">
        <Grid item>
          <Button
            size="medium"
            variant="text"
            color="primary"
            onClick={handleBack}
          >
            {t('back')}
          </Button>
        </Grid>
        <Grid item>
          <Button
            size="medium"
            variant="contained"
            color="primary"
            onClick={handleCreateAndAdd}
            disabled={createAndAddDisabled}
          >
            {t('createAndAdd')}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <Dialog
      open={isOpen}
      maxWidth="sm"
      className={classnames(className)}
    >
      <DialogHeader onClick={handleClose}>
        {t('create-test-suite')}
      </DialogHeader>
      <DialogBody
        sx={{ rowGap: '0.2rem' }}
      >
        <Form>
          {renderInputTestSuiteName()}
          {renderSelectedRepository()}
          {testSuiteType === TestSuiteType.CLOUD_STUDIO && (
            <FormGroup>
              <SelectG5Location
                publishEntity={SearchEntity.TestSuite}
                key={testProject?.id}
                /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
                /* @ts-ignore */
                testProjectId={testProject.id}
                path={newTestSuiteLocation}
                onSelectFolder={setNewTestSuiteLocation}
                showAlert={setShowAlert}
                showExcludedPathsAlert={onChangeExcludedPathsAlert}
                excludedFolderPaths={['test-suites/TestOps']}
              />
              {showAlert &&
                <AlertComponent
                  text={t('invalid-folder-name')}
                  icon={<IconErrorXMark />}
                  className="mt-2 error"
                />}
              {MFlags.preventNamingTestOpsInTestSuiteFoldersEnabled && isExcludedFolderPath &&
                <AlertComponent
                  text={t('invalid-folder-name#specific-name')}
                  icon={<IconErrorXMark />}
                  className="mt-2 error"
                />}
            </FormGroup>
          )}
        </Form>
      </DialogBody>
      <DialogFooter>
        {renderFooter()}
      </DialogFooter>
    </Dialog>
  );
}

export default AddNewTestSuiteView;
