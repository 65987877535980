/* eslint max-len: 0 */

import React from 'react';
import Icon from 'react-icon-base';

const IconWave = (props) => (
  <Icon viewBox="0 0 23 23" {...props}>
    <defs>
      <linearGradient x1="47.1632097%" y1="65.3930405%" x2="29.606688%" y2="89.8882641%" id="linearGradient-1">
        <stop stopColor="#FFEA81" offset="0%" />
        <stop stopColor="#EBA41E" offset="100%" />
      </linearGradient>
      <linearGradient x1="53.5812139%" y1="50%" x2="71.8512228%" y2="15.616127%" id="linearGradient-2">
        <stop stopColor="#FFEA81" stopOpacity="0" offset="0%" />
        <stop stopColor="#F1A413" offset="100%" />
      </linearGradient>
      <linearGradient x1="53.5812139%" y1="50%" x2="85.103149%" y2="83.4793668%" id="linearGradient-3">
        <stop stopColor="#FFEA81" stopOpacity="0" offset="0%" />
        <stop stopColor="#F1A413" offset="100%" />
      </linearGradient>
      <linearGradient x1="33.1377194%" y1="34.1235509%" x2="8.72450804%" y2="12.111725%" id="linearGradient-4">
        <stop stopColor="#FFEA81" stopOpacity="0" offset="0%" />
        <stop stopColor="#F1A413" offset="100%" />
      </linearGradient>
      <path d="M18.1361548,10.942315 L20.0476378,8.40021435 C20.5937768,7.69205645 21.6314409,7.5286354 22.3414216,8.07337224 C23.0514022,8.61810909 23.2152439,9.65310909 22.6691049,10.361267 L20.5790522,13.1408493 C19.7100066,15.0783234 19.5901784,16.5834113 18.0269235,18.0420565 L15.569298,20.4933722 C13.7124254,22.3454775 10.7632748,22.3999512 8.90640225,20.5478459 C8.90640225,20.5478459 8.46923243,20.1117985 7.76571279,19.4100851 C7.6870002,19.3611537 7.61175807,19.3045882 7.54105476,19.2404775 L0.987386802,12.7581091 C0.386633906,12.1588986 0.441247805,11.2328459 1.0420007,10.6336354 C1.49302556,10.1837685 2.12874904,10.0716464 2.67184025,10.2742175 L1.5881397,9.21731961 C0.987386802,8.61810909 1.0420007,7.69205645 1.6427536,7.09284593 C2.18028946,6.55669013 2.98017322,6.50027063 3.57292265,6.88456435 L3.17194279,6.4936354 C2.57118989,5.89442488 2.62580379,4.96837224 3.22655669,4.36916172 C3.82730958,3.76995119 4.75574587,3.76995119 5.35649877,4.31468803 L5.82834627,4.77462784 C5.48847258,4.33542311 5.29430864,3.3417185 5.95725167,2.68047751 C6.66723236,1.97231961 7.70489645,2.3536354 8.08719375,2.73495119 C8.41487715,3.11626698 14.2585644,8.83600382 15.8969814,10.4702143 C16.8785185,11.4492315 17.6640504,11.2552363 18.1361548,10.942315 Z M11.0740091,9.88790945 L11.0909582,9.87100382 L7.30488654,6.21390567 L11.0740091,9.88790945 Z" id="path-5" />
    </defs>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-443.000000, -87.000000)" fillRule="nonzero">
        <g id="Group" transform="translate(443.000000, 87.000000)">
          <g id="icon_wave">
            <g id="Combined-Shape">
              <use fill="url(#linearGradient-1)" xlinkHref="#path-5" />
              <use fill="url(#linearGradient-2)" xlinkHref="#path-5" />
              <use fill="url(#linearGradient-3)" xlinkHref="#path-5" />
              <use fill="url(#linearGradient-4)" xlinkHref="#path-5" />
            </g>
            <path d="M18.7818533,13.9210526 L22.4227799,9.07894737" id="Shape" fill="#FFA000" />
            <path d="M5.43178893,4.23684211 L11.0145431,9.68421053 C11.2572716,9.92631579 11.2572716,10.2289474 11.0145431,10.4710526 C10.7718147,10.7131579 10.407722,10.7131579 10.2256757,10.4710526 L5.43178893,4.23684211 Z" id="Shape" fill="#DCA70D" />
            <path d="M2.45836551,6.05263158 L9.07271557,12.4684211 C9.31544402,12.7105263 9.31544402,13.0736842 9.07271557,13.2552632 C8.82998713,13.4973684 8.52657658,13.4973684 8.28384813,13.2552632 L2.45836551,6.05263158 Z" id="Shape" fill="#DCA70D" />
            <path d="M1.91222651,10.35 L7.25225225,15.4947368 C7.49498069,15.7368421 7.49498069,16.1 7.25225225,16.2815789 C7.00952381,16.4631579 6.64543115,16.5236842 6.46338481,16.2815789 L1.91222651,10.35 Z" id="Shape" fill="#DCA70D" />
            <path d="M6.34202059,22.9394737 C3.24723295,21.0631579 0.81994852,18.0973684 0.698584299,17.9763158 L1.66949807,17.1894737 C1.66949807,17.1894737 4.0967825,20.1552632 6.9488417,21.9105263 L6.34202059,22.9394737 Z" id="Shape" fill="#5EB400" />
            <path d="M16.415251,8.17105263 C14.1093308,4.11578947 10.0436293,1.75526316 9.98294723,1.75526316 L10.5897683,0.726315789 C10.7718147,0.847368421 15.0195624,3.26842105 17.507529,7.62631579 L16.415251,8.17105263 Z" id="Shape" fill="#5EB400" />
            <path d="M17.0220721,4.47894737 C15.9297941,2.23947368 13.684556,1.15 13.6238739,1.08947368 L14.1700129,0 C14.2913771,0.0605263158 16.7793436,1.33157895 18.053668,3.87368421 L17.0220721,4.47894737 Z" id="Shape" fill="#5EB400" />
          </g>
        </g>
      </g>
    </g>
  </Icon>
);

export default IconWave;
