import { sendAnalyticEventForAction } from './SegmentAnalytics';

export const TRIAL_REQUEST_TRIGGER = {
  ORG_CREATION: 'org_creation',
  TRIAL_REQUEST: 'trial_request',
  WEBSITE: 'website'
};

/**
 * Send tracking event when user successfully register trial request
 * @param user_id: username (email)
 * @param organization_id: organization’s id
 * @param trial: TESTOPS_BUSINESS | TESTOPS_ENTERPRISE
 * @param trigger: org_creation | trial_request | website
 */
export const trackTrialRequestEvent = (email, organizationId, trial, trigger) => sendAnalyticEventForAction('organization-trial-request-form', {
  user_id: email,
  organization_id: organizationId,
  trial,
  trigger,
});
