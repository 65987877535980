import React from 'react';
import { Box, DialogTitle, IconButton } from '@mui/material';
import PropTypes from 'prop-types';
import DialogComponent from './DialogComponent';
import { IconCloseInactive } from '../../images/CustomIcon';

export default function CloseableDialogComponent({ id, isOpen, handleClose, children, title, headerStyle = '', maxWidth, disablePortal, trackCloseIcon, showCloseButton = true }) {
  return (
    <DialogComponent
      id={id}
      isOpen={isOpen}
      maxWidth={maxWidth}
      disablePortal={disablePortal}
    >
      <DialogTitle className={headerStyle}>
        <Box display="flex" alignItems="center">
          {title}
          <Box flexGrow={1} />
          {showCloseButton &&
          <Box>
            <IconButton data-testid="close-dialog-button" onClick={handleClose} {...trackCloseIcon} size="large">
              <IconCloseInactive />
            </IconButton>
          </Box>}
        </Box>
      </DialogTitle>
      {children}
    </DialogComponent>
  );
}

CloseableDialogComponent.propTypes = {
  id: PropTypes.string,
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  children: PropTypes.node,
  title: PropTypes.string,
  headerStyle: PropTypes.string,
  maxWidth: PropTypes.string,

  /**
   *  Disable the portal behavior. The children stay within it's parent DOM hierarchy.
   */
  disablePortal: PropTypes.bool,

  /**
   * trackCloseIcon contains data-trackid and data-groupid of the parents component
   */
  trackCloseIcon: PropTypes.object,
  showCloseButton: PropTypes.bool
};

CloseableDialogComponent.defaultProps = {
  disablePortal: false,
  showCloseButton: true
};
