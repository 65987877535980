/* eslint-disable default-param-last */
import React from 'react';
import parse from 'html-react-parser';
import { Alert, AlertTitle } from '@katalon-studio/katalon-ui/Alert';
import { closeSnackbar, enqueueSnackbar } from 'notistack';
import { Severity } from './Constants';
import MConfigs from '../models/MConfigs';
import { t } from '../i18n/t';
import { basicFilter, sanitizeScript } from './SanitizeHelper';
import MFlags from '../models/MFlags';

const showMessage = (
  message,
  title,
  severity,
  autoHideDuration = null,
  htmlComponents,
  preventDuplicate = null,
) => {
  const handleCloseSnackbar = (key) => {
    closeSnackbar(key);
  };
  const messageCleaned = sanitizeScript(message, basicFilter);
  let finalMessages = message ? messageCleaned : htmlComponents;
  if (!MFlags.htmlInjection) {
    finalMessages = message ? parse(messageCleaned) : htmlComponents;
  }
  const key = (MFlags.applyErrorHandlingUsingKatalonApiStandardEnabled && preventDuplicate) ? messageCleaned : undefined;
//   console.log('key', key);
//   console.log('message', message);
//   console.log('messageCleaned', messageCleaned);
//   console.log('htmlComponents', htmlComponents);
//   console.log('finalMessages', finalMessages);

  enqueueSnackbar({
    key,
    message: finalMessages,
    content: (key, msg) => (
      <Alert className="toast-message-container" severity={severity} onClose={() => handleCloseSnackbar(key)}>
        {title && (<AlertTitle>{title}</AlertTitle>)}
        {msg}
      </Alert>
    ),
    autoHideDuration,
    preventDuplicate,
  });
};

export default class Notification {
  static pushSuccess = (message, title, autoHideDuration = 2000, htmlComponents) => {
    const { isReadOnly } = MConfigs;
    if (isReadOnly) {
      this.pushWarning(t('standby_warning'), '', 2000);
    } else {
      if (!title) {
        title = 'Done!';
      }
      showMessage(message, title, Severity.SUCCESS, autoHideDuration, htmlComponents);
    }
  };

  static pushSuccessPermanent(message, title) {
    if (!title) {
      title = 'Done!';
    }
    showMessage(message, title, Severity.SUCCESS);
  }

  static pushErrorTimeOut(message, title, autoHideDuration) {
    showMessage(message, title, Severity.ERROR, autoHideDuration);
  }

  static pushError = (message, title, preventDuplicate = false) => {
    showMessage(message, title, Severity.ERROR, null, null, preventDuplicate);
  };

  static pushInfo = (message, autoHideDuration, title) => {
    showMessage(message, title, Severity.INFO, autoHideDuration);
  };

  static pushWarning = (message, title, autoHideDuration) => {
    showMessage(message, title, Severity.WARNING, autoHideDuration);
  };

  static remove() {
    closeSnackbar();
  }
}

