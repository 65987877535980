import _ from 'lodash';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PageButtonToolbar from '../../components/PageButtonToolbar';
import PageComponent from '../../components/PageComponent';
import { t } from '../../i18n/t';
import ObjectSummary from '../../components/summary/ObjectSummary';
import WebSocket from '../../services/WebSocket';
import http from '../../utils/http';
import Apis from '../../utils/Apis';
import MContext from '../../models/MContext';
import { ExecutionHeaderNav } from '../../components/HeaderNav';
import Notification from '../../utils/Notification';
import RouteConstants from '../../utils/RouteConstants';
import DefectTab from './DefectTab';
import TestRunsTab from './TestRunsTab';
import FileDetailsTab from './FileDetailsTab';
import DecoratorConstants from '../../utils/DecoratorConstants';
import Time from '../../utils/Moment';
import {
  IconProfile,
  IconStartTime,
  IconDuration,
  IconConfigurationSummary,
  IconConfigurationSummaryLight,
  IconTestCloudTunnel,
} from '../../images/CustomIcon';
import ObjectInfo from '../../components/summary/ObjectInfo';
import Services from '../../utils/Services';
import { buildSearchCondition } from '../../components/search/SearchUtils';
import DefaultLayout from '../../components/DefaultLayout';
import DownloadExecutionButton from './DownloadExecutionButton';
import Routes from '../../utils/Routes';
import Arrays from '../../utils/Arrays';
import SharingDialog from '../../components/SharingDialog';
import RunPlanDropdownButton from './RunPlanDropdownButton';
import ApiHelper from '../../utils/ApiHelper';
import LinkExecutionReleaseBuild from './LinkExecutionReleaseBuild';
import { DownloadExecution } from './DownloadExecution';
import TestCloudHelper from '../../utils/TestCloudHelper';
import MFlags from '../../models/MFlags';
import ResultTabWithCustomFieldAndTag from '../../components/ResultTabWithCustomFieldAndTag';
import PushingToXrayDialog from './PushingToXrayDialog';
import RunningExecutionWithCustomFieldAndTag from './RunningExecutionWithCustomFieldAndTag';
import { next } from '../../utils/Count';
import Link from '../../components/Link';
import ButtonLink from '../../components/ButtonLink';

class Execution extends PageComponent {
  constructor(props) {
    super(props);
    this.meta.id = 'page-execution-details';
    this.teamId = MContext.teamId;
    this.projectId = MContext.projectId;
    this.executionId = MContext.executionId;
    this.handlerId = next();
    this.meta.title = t('execution#title', { order: this.executionId, name: '' });
    this.state = {
      execution: null,
      releases: [],
    };
    this.renderHeader = this.renderHeader.bind(this);
    this.renderBody = this.renderBody.bind(this);
    this.rerunPlan = this.rerunPlan.bind(this);
    this.onChangeReleaseBuild = this.onChangeReleaseBuild.bind(this);

    this.updateInterval = 5000;
    this.notifyHandler = _.throttle(() => {
      this.refreshData();
    }, this.updateInterval);
  }

  get() {
    http.get(Apis.execution(this.projectId, this.executionId))
      .then((responseJson) => {
        const execution = responseJson;
        this.setState({
          execution,
        });
      });
  }

  getReleases() {
    const params = {
      pagination: {
        page: 0,
        size: 1000000,
        sorts: ['name,asc'],
      },
      conditions: [
        buildSearchCondition('Project.id', '=', this.projectId),
        buildSearchCondition('closed', '=', false)
      ],
      type: 'Release',
    };
    Services.search(params)
      .then(({ content }) => this.setState({ releases: content }));
  }

  subscribeTopic() {
    if (this.projectId) {
      const topic = `Execution-${this.executionId}`;
      WebSocket.subscribe({
        projectId: this.projectId,
        topics: [topic],
      }, this.notifyHandler, this.handlerId);
    }
  }

  refreshData() {
    this.get();
  }

  componentDidMount() {
    this.get();
    this.getReleases();
    this.subscribeTopic();
    this.getKeyesExecution();
  }

  componentWillUnmount() {
    WebSocket.unsubscribe(this.handlerId);
  }

  getKeyesExecution() {
    const params = {
      pagination: {
        page: 0,
        size: 1,
        sorts: 'order,desc',
      },
      conditions: [
        buildSearchCondition('Execution.order', '=', this.executionId),
      ],
      type: 'KeyesExecution',
    };
    Services.search(params)
      .then((responseJson) => {
        let keyesExecution = null;
        if (responseJson.content.length > 0) {
          keyesExecution = responseJson.content[0];
        }
        this.setState({
          keyesExecution,
        });
      });
  }

  executionInfoItems() {
    const { execution, keyesExecution } = this.state;
    const constructedLink = new Routes({
      executionId: execution.order,
      projectId: execution.project.id,
    });
    const profiles = execution.executionTestSuiteResources
      .map((executionTestSuiteResource) => executionTestSuiteResource.profiles);
    const isDisplayTestCloudTunnelIcon = TestCloudHelper.isDisplayTestCloudTunnelIcon(execution);
    const itemArray = [];
    itemArray.push(
      ...Arrays.insertIf(isDisplayTestCloudTunnelIcon, {
        icon: <IconTestCloudTunnel />,
        text: t('test-cloud-local-testing'),
      }),
      {
        text: DecoratorConstants.statusBadge(execution.executionStage === 'TERMINATE'
          || (MFlags.changeStageAndStatusExecutionRule
          && execution.executionStage === 'IMPORTING') ?
          t('incomplete-test-run') :
          execution.status),
      },
      {
        icon: <IconStartTime />,
        text: Time.format(execution.startTime),
      },
      {
        icon: <IconDuration />,
        text: (
          <>
            {Time.duration(execution.elapsedDuration)}&nbsp;
          </>
        ),
      },
      {
        icon: <IconProfile />,
        text: DecoratorConstants.profile(profiles),
      },
      ...Arrays.insertIf(keyesExecution, {
        icon: DecoratorConstants.statusBadge(keyesExecution?.status || ''),
        text: <Link href={constructedLink.keyes_execution_details_link}>{t('testops-keyes')}</Link>
      }),
    );
    return itemArray;
  }

  renderObjectSummary() {
    const { execution } = this.state;
    const projectId = execution.project.id;

    const urlParams = {
      teamId: this.teamId,
      projectId,
      executions: 'Executions',
      project: execution.project.name,
      executionId: execution.order,
      execution,
    };

    if (execution.executionStage === 'RUNNING') {
      return (
        <ObjectSummary params={urlParams} />
      );
    } else {
      return (
        <ObjectSummary params={urlParams}>
          <ExecutionHeaderNav projectId={this.projectId} teamId={this.teamId} executionId={this.executionId} />
          <ObjectInfo items={this.executionInfoItems()} />
        </ObjectSummary>
      );
    }
  }

  renderHeader() {
    return (
      <>
        {this.renderObjectSummary()}
      </>
    );
  }

  rerunPlan() {
    const { execution } = this.state;
    return Services.rerunExecution(execution.id)
      .then(() => {
        Notification.pushSuccess('Plan(s) run successfully.');
      });
  }

  onDownloadHandler(fileType) {
    const { execution } = this.state;
    DownloadExecution.startDownload(fileType, execution);
  }

  onChangeReleaseBuild(execution) {
    this.setState({ execution });
  }

  renderRunConfiguration(runConfigurationId) {
    const routes = new Routes({
      runConfigurationId,
    });

    return (
      <ButtonLink
        data-trackid="testops-config"
        title={t('testops-config')}
        variant="outlined"
        color="secondary"
        href={routes.run_configuration_link}
      >
        <div className="icon-inactive">
          <IconConfigurationSummary />
        </div>
        <div className="icon-active">
          <IconConfigurationSummaryLight />
        </div>
      </ButtonLink>
    );
  }

  renderBody() {
    const { execution, releases, keyesExecution } = this.state;

    if (execution.executionStage === 'RUNNING') {
      return <RunningExecutionWithCustomFieldAndTag execution={execution} />;
    } else {
      return (
        <>
          <PageButtonToolbar>
            <PushingToXrayDialog execution={execution} />
            <LinkExecutionReleaseBuild
              color="secondary"
              execution={execution}
              onChange={this.onChangeReleaseBuild}
              releases={releases}
            />
            {execution.jobs && execution.jobs.length > 0 && (
              <RunPlanDropdownButton
                apiSupport={ApiHelper.rerunPlan(execution.id)}
                data-trackid="rerun-plan"
                color="secondary"
                variant="outlined"
                name={t('rerun')}
                onClick={this.rerunPlan}
                title={t('rerun')}
              />
            )}
            {execution.jobs && execution.jobs.length > 0 && execution.jobs[0].runConfigurationId && this.renderRunConfiguration(execution.jobs[0].runConfigurationId)}
            <DownloadExecutionButton
              variant="outlined"
              color="secondary"
              onSelectOption={(fileType) => this.onDownloadHandler(fileType)}
            />
            <SharingDialog
              executionId={execution.id}
              executionOrder={execution.order}
            />
          </PageButtonToolbar>
          <Switch>
            <Route
              exact
              path={RouteConstants.execution_details}
              render={(params) => (<ResultTabWithCustomFieldAndTag keyesExecution={keyesExecution} execution={execution} params={params} />)}
            />
            <Route
              exact
              path={RouteConstants.execution_test_run_details}
              render={(params) => <TestRunsTab executionId={execution.id} {...params} />}
            />
            <Route
              exact
              path={RouteConstants.execution_file_details}
              render={(params) => <FileDetailsTab executionId={execution.id} {...params} />}
            />
            <Route
              exact
              path={RouteConstants.execution_defect}
              render={(params) => <DefectTab executionId={execution.id} projectId={this.projectId} {...params} />}
            />
          </Switch>
        </>
      );
    }
  }

  render() {
    const { execution } = this.state;
    if (execution) {
      return (
        <DefaultLayout
          renderHeader={this.renderHeader}
          renderBody={this.renderBody}
        />
      );
    }
    return null;
  }
}

export default Execution;
