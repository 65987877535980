import React, { useState, useEffect } from 'react';
import MContext from '../../models/MContext';
import { t } from '../../i18n/t';
import { IconYellowWarning } from '../../images/CustomNewIcon';
import Services from '../../utils/Services';
import WarningPackageAlertComponent from '../WarningPackageAlertComponent';
import Apis from '../../utils/Apis';

export default function WarningPackage() {
  const { targetOrganization } = MContext;

  const [testExecutionQuota, setTestExecutionQuota] = useState({});
  const { canTriggerTestExecution = true } = testExecutionQuota;
  const usageOverQuota = Math.round(testExecutionQuota.usageOverQuota * 100);

  useEffect(() => {
    if (targetOrganization) {
      Services.getOrganizationTestExecutionQuota(targetOrganization.id)
        .then((quota) => setTestExecutionQuota(quota));
    }
  }, []);

  const renderWarningText = () => (
    <>
      {t('organization_exceed_quota_warning', { usageOverQuota })} <a href={Apis.katOneDashboardPage({ orgId: targetOrganization.id })}>{t('view_usage_quota')}</a>
    </>
  );

  const renderWarning = () => (
    <WarningPackageAlertComponent
      text={renderWarningText()}
      icon={<IconYellowWarning />}
    />
  );

  if (canTriggerTestExecution || usageOverQuota === 0) {
    return null;
  }

  return (
    <div className="warning-alert-banner">
      {renderWarning()}
    </div>
  );
}

