import React from 'react';
import { Card, CardBody } from 'reactstrap';
import { Tab, Tabs } from '@mui/material';
import DefaultLayout from '../components/DefaultLayout';
import PageComponent from '../components/PageComponent';
import ObjectSummary from '../components/summary/ObjectSummary';
import { t } from '../i18n/t';
import MContext from '../models/MContext';
import TabPanel from '../components/tab/TabPanel';
import TestCloudTunnelsSetup from './test_cloud/TestCloudTunnelsSetup';
import ActiveTunnelDataTable from '../components/table/ActiveTunnelDataTable';

class TestCloudTunnel extends PageComponent {
  constructor(props) {
    super(props);
    this.teamId = MContext.teamId;
    this.projectId = MContext.projectId;
    this.team = MContext.team;
    this.meta.id = 'page-test-cloud-tunnel';
    this.meta.title = t('test-cloud-tunnel#title');
    this.state = {
      tabIndex: 0,
    };
    this.renderHeader = this.renderHeader.bind(this);
    this.renderBody = this.renderBody.bind(this);
    this.handleChangeTab = this.handleChangeTab.bind(this);
  }

  renderObjectSummary() {
    const urlParams = {
      testCloudTunnel: t('test-cloud-tunnel#title'),
      teamId: this.teamId,
      teamName: this.team.name,
    };
    return (
      <ObjectSummary params={urlParams} />
    );
  }

  renderHeader() {
    return (
      <>
        {this.renderObjectSummary()}
      </>
    );
  }

  handleChangeTab(event, newValue) {
    this.setState({
      tabIndex: newValue,
    });
  }

  renderBody() {
    const { tabIndex } = this.state;
    return (
      <Card>
        <CardBody>
          <Tabs
            value={tabIndex}
            onChange={this.handleChangeTab}
            textColor="secondary"
            indicatorColor="secondary"
            className="mb-4 pb-1"
          >
            <Tab label={t('test-cloud-tunnel#tunnels')} id="active_tunnels" />
            <Tab label={t('test-cloud-tunnel#setup')} id="setup_tunnels" />
          </Tabs>
          <TabPanel className="tab-active-tunnels" value={tabIndex} index={0}>
            <ActiveTunnelDataTable />
          </TabPanel>
          <TabPanel value={tabIndex} index={1}>
            <TestCloudTunnelsSetup />
          </TabPanel>
        </CardBody>
      </Card>
    );
  }

  render() {
    const isTeamDemo = MContext.isTeamDemo;
    if (!this.team || isTeamDemo) {
      return null;
    }
    return (
      <DefaultLayout
        renderHeader={this.renderHeader}
        renderBody={this.renderBody}
      />
    );
  }
}

export default TestCloudTunnel;
