import React from 'react';
import MConfigs from '../models/MConfigs';
import MContext from '../models/MContext';
import MAuth from '../models/MAuth';
import MFlags from '../models/MFlags';
import WebSocket from '../services/WebSocket';
import { sendAnalyticEventForAction, analyticsPage } from '../utils/SegmentAnalytics';
import MicroApp from '../components/MicroApp';

class ReportsAndAnalytics extends React.Component {

  render() {
    const TestOpsContext = {
      MContext,
      MAuth,
      MFlags,
      MConfigs,
      TestOpsUtils: {
        sendAnalyticEventForAction,
        analyticsPage,
        WebSocket
      }
    };

    return (
      <MicroApp
        id="katalon-reports-and-analytics"
        path={MConfigs.reportsAndAnalyticsMicroAppUrl}
        context={TestOpsContext}
      />
    );
  }
}

export default ReportsAndAnalytics;
