import Autocomplete from '@katalon-studio/katalon-ui/Autocomplete';
import Button from '@katalon-studio/katalon-ui/Button';
import {
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
} from '@katalon-studio/katalon-ui/Dialog';
import Multiline from '@katalon-studio/katalon-ui/Multiline';
import TextField from '@katalon-studio/katalon-ui/TextField';
import Popper from '@mui/material/Popper';
import { styled } from '@mui/material/styles';
import { isEmpty, throttle, trim } from 'lodash';
import PropTypes from 'prop-types';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { t } from '../../../i18n/t';
import MAuth from '../../../models/MAuth';
import MContext from '../../../models/MContext';
import {
  EXECUTION_TYPE,
  MAX_PAGE_SIZE,
  SearchEntity,
  TEST_TYPE,
  TestProjectType,
} from '../../../utils/Constants';
import Routes from '../../../utils/Routes';
import { sendAnalyticEventForAction } from '../../../utils/SegmentAnalytics';
import Services from '../../../utils/Services';
import { BetaChip } from '../../BetaChip';
import { buildSearchCondition } from '../../search/SearchUtils';
import SelectG5Location from '../../treeview/SelectG5Location';
import NotificationHandler from '../handler/NotificationHandler';
import TestCaseService from '../service/TestCaseService';
import TestObjectPublisher from '../service/TestObjectPublisher';
import FolderNameError from './FolderNameError';
import {
  CREATE_TEST_CASE_SOURCE,
  DEFAULT_TEST_CASE_PATH,
  URL_PLACEHOLDER,
} from './constants';
import {
  addRecentUrl,
  buildDefaultTestCaseContent,
  getDefaultUrl,
  getRecentUrls,
  getTestCaseNameErrors
} from './utils';

const UrlContainer = styled('div')(() => ({
  display: 'flex',
}));

const UrlAutocomplete = styled(Autocomplete)(({ theme }) => ({
  flex: 1,

  '.MuiInputBase-input': {
    padding: `${theme.spacing(0, 4, 0, 0)} !important`,
  },

  '.MuiAutocomplete-endAdornment': {
    top: 'calc(50% - 12px)',
  },
}));

const UrlAutocompletePopper = styled(Popper)(() => ({
  '.MuiAutocomplete-option': {
    display: 'block !important',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
}));

export default function CreateTestCaseDialog({
  label,
  mainNav,
  open,
  onClose,
}) {
  const projectId = MContext.projectId;
  const accountId = MAuth.user.id;
  const recentUrls = getRecentUrls(projectId, accountId, true);

  const descriptionRef = useRef();
  const nameRef = useCallback((node) => {
    if (node) {
      node.select();
    }
  }, []);

  const [testProjectId, setTestProjectId] = useState();
  const [testCaseName, setTestCaseName] = useState(t('default_test_case_name'));
  const [testCasePath, setTestCasePath] = useState(DEFAULT_TEST_CASE_PATH);
  const [folderError, setFolderError] = useState(false);
  const [isDuplicated, setDuplicated] = useState(false);
  const [url, setUrl] = useState(getDefaultUrl(projectId, accountId));
  const [urlInputDirty, setUrlInputDirty] = useState(false);

  const trimmedName = useMemo(() => trim(testCaseName), [testCaseName]);
  const trimmedUrl = useMemo(() => trim(url), [url]);
  const isUrlEmpty = useMemo(() => trimmedUrl === '', [trimmedUrl]);
  const errorList = useMemo(
    () => getTestCaseNameErrors(trimmedName, isDuplicated),
    [isDuplicated, trimmedName]
  );

  const createTestCaseDisabled = useMemo(
    () => folderError || errorList.length > 0 || (urlInputDirty && isUrlEmpty),
    [errorList, folderError, isUrlEmpty, urlInputDirty]
  );

  const getKatalonCloud = useCallback(async () => {
    const params = {
      pagination: {
        page: 0,
        size: MAX_PAGE_SIZE,
        sorts: ['type, desc', 'id,desc'],
      },
      conditions: [buildSearchCondition('type', '=', TestProjectType.CLOUD)],
      type: SearchEntity.TestProject,
    };

    Services.searchRecursively(0, params)
      .then((testProjects) => {
        const katalonCloud = testProjects[0];
        setTestProjectId(katalonCloud.id);
      })
      .catch(() => {});
  }, []);

  const renderErrorList = useCallback(
    (list) =>
      list.map((error) => (
        <>
          {t(error)}
          <br />
        </>
      )),
    []
  );

  const validateTestCaseNameAndPath = useCallback(() => {
    setDuplicated(false);
    if (testProjectId) {
      const params = {
        pagination: {
          page: 0,
          size: 1,
          sorts: ['id,desc'],
        },
        conditions: [
          buildSearchCondition('name', '=', trimmedName),
          buildSearchCondition('path', '=', testCasePath),
          buildSearchCondition('TestProject.id', '=', testProjectId),
        ],
        type: SearchEntity.TestCase,
      };

      Services.search(params)
        .then(({ content }) => {
          setDuplicated(!isEmpty(content));
        })
        .catch(() => {});
    }
  }, [trimmedName, testCasePath, testProjectId]);

  const throttledGetTestCaseByNameAndTestProjectId = useCallback(() => {
    throttle(validateTestCaseNameAndPath, 1000)();
  }, [validateTestCaseNameAndPath]);

  const createTestCaseErrorHandler = (message, label, jqXHR) => {
    NotificationHandler.createTestCaseErrorHandler(
      t('create-test-case#failed#message'),
      jqXHR
    );
  };

  const createTestCase = useCallback(async () => {
    try {
      addRecentUrl(projectId, accountId, trimmedUrl);

      const testCase = {
        id: null,
        name: trimmedName,
        path: testCasePath,
        testType: TEST_TYPE.G5_TEST_CASE,
        executionType: EXECUTION_TYPE.TS,
        content: buildDefaultTestCaseContent(trimmedName, trimmedUrl),
        description: descriptionRef.current?.value,
      };

      const testCaseToCreate = TestCaseService.buildPublishTestCase(
        testCase.id,
        testCase.name,
        testCase.path,
        testCase.testType,
        testCase.executionType,
        testCase.content,
        testCase.description
      );

      const createdTestProject = await TestObjectPublisher.publishTestObject(
        testProjectId,
        [testCaseToCreate],
        [],
        '',
        createTestCaseErrorHandler
      );
      const createdTestCase = createdTestProject.testCases[0];

      onClose();
      sendAnalyticEventForAction('cs_test_case_created', {
        test_case_id: createdTestCase.urlId,
        test_case_name: createdTestCase.name,
        source: mainNav
          ? CREATE_TEST_CASE_SOURCE.MAIN_NAV
          : CREATE_TEST_CASE_SOURCE.TEST_EXPLORER,
      });

      Routes.goToGen5EditorPage(createdTestCase.urlId);
    } catch (err) {
      // ignore
    }
  }, [
    accountId,
    mainNav,
    onClose,
    projectId,
    testCasePath,
    testProjectId,
    trimmedName,
    trimmedUrl,
  ]);

  const handleNameChange = useCallback((event) => {
    setTestCaseName(event.target.value ?? '');
  }, []);

  const handleUrlChange = useCallback((_event, value) => {
    setUrl(value ?? '');
    setUrlInputDirty(true);
  }, []);

  useEffect(() => {
    getKatalonCloud();
  }, [getKatalonCloud]);

  useEffect(() => {
    // reset all fields when dialog is opened
    const defaultTestCaseName = `${t(
      'default_test_case_name'
    )} - ${new Date().getTime()}`;
    if (!open) return;
    setTestCaseName(defaultTestCaseName);
    setUrl(getDefaultUrl(projectId, accountId));
    setUrlInputDirty(false);
    setTestCasePath(DEFAULT_TEST_CASE_PATH);
  }, [open, projectId, accountId]);

  useEffect(() => {
    throttledGetTestCaseByNameAndTestProjectId();
  }, [throttledGetTestCaseByNameAndTestProjectId]);

  return (
    <Dialog maxWidth="xs" open={open} onClose={onClose}>
      <DialogHeader onClick={onClose}>
        <span>
          {t(`${label}#title`)} <BetaChip />
        </span>
      </DialogHeader>
      <DialogBody
        sx={{
          display: 'flex',
          flexDirection: 'column',
          rowGap: '16px',
        }}
      >
        <TextField
          required
          autoFocus
          inputRef={nameRef}
          id={`g5_name_${label}`}
          label="Name"
          value={testCaseName}
          onChange={handleNameChange}
          error={errorList.length > 0}
          helperText={errorList.length > 0 && renderErrorList(errorList)}
          size="small"
        />
        <SelectG5Location
          key={testProjectId}
          testProjectId={testProjectId}
          path={testCasePath}
          onSelectFolder={setTestCasePath}
          applyGen5Style
          showAlert={setFolderError}
        />
        {folderError && <FolderNameError />}
        <UrlContainer>
          <UrlAutocomplete
            id={`g5_domain_${label}`}
            autoHighlight
            openOnFocus
            freeSolo
            size="small"
            value={url}
            disableClearable={url.length === 0}
            isOptionEqualToValue={(option, value) => option === value}
            onChange={handleUrlChange}
            onInputChange={handleUrlChange}
            options={recentUrls}
            getOptionLabel={(option) => option}
            renderInput={(params) => (
              <TextField
                {...params}
                required
                label={t('test_case#url_input_label')}
                placeholder={URL_PLACEHOLDER}
                error={urlInputDirty && isUrlEmpty}
                helperText={
                  urlInputDirty && isUrlEmpty && t('test_case#url_empty_error')
                }
              />
            )}
            PopperComponent={UrlAutocompletePopper}
          />
        </UrlContainer>
        <Multiline
          id={`g5_description_${label}`}
          inputRef={descriptionRef}
          label={t('test_case#description_input_label')}
          placeholder={t('test_case#description_input_label')}
          rows={3}
        />
      </DialogBody>
      <DialogFooter>
        <Button
          size="medium"
          variant="text"
          color="primary"
          onClick={onClose}
          id={`g5_cancel_${label}`}
        >
          {t('cancel')}
        </Button>
        <Button
          size="medium"
          variant="contained"
          color="primary"
          disabled={createTestCaseDisabled}
          onClick={createTestCase}
          id={`g5_submit_${label}`}
        >
          {t(`${label}#submit`)}
        </Button>
      </DialogFooter>
    </Dialog>
  );
}

CreateTestCaseDialog.propTypes = {
  label: PropTypes.string,
  mainNav: PropTypes.bool,
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

CreateTestCaseDialog.defaultProps = {
  label: t('add_test_case'),
  mainNav: false,
  open: false,
  onClose: () => {},
};
