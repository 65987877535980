import React from 'react';
import { Button, Card, CardBody, CardHeader, Form, FormGroup, Input, Label, Row, Col } from 'reactstrap';
import Select from '../components/Select';
import Services from '../utils/Services';
import MContext from '../models/MContext';
import Notification from '../utils/Notification';
import { t } from '../i18n/t';

export default class EmailConfiguration extends React.Component {
  constructor(props) {
    super(props);
    this.organizationId = MContext.organizationId;
    this.state = {
      host: '',
      port: '',
      username: '',
      password: '',
      protocol: '',
      testRecipient: '',
    };
    this.handleTextChange = this.handleTextChange.bind(this);
    this.handleprotocolChange = this.handleprotocolChange.bind(this);
    this.updateConfiguration = this.updateConfiguration.bind(this);
    this.sendTestMail = this.sendTestMail.bind(this);
  }

  UNSAFE_componentWillMount() {
    Services.getMailConfiguration(this.organizationId).then((response) => this.setState(response));
  }

  handleTextChange(event) {
    const value = event.target.value;
    const name = event.target.name;
    this.setState({ [name]: value });
  }

  handleprotocolChange(event) {
    const protocol = event.value;
    this.setState({ protocol });
  }

  updateConfiguration(event) {
    event.preventDefault();
    const configurationData = this.state;

    Services.updateMailConfiguration(configurationData, this.organizationId)
      .then((response) => {
        this.setState(response);
        Notification.pushSuccess('Mail Server configuration was updated.');
      });
  }

  sendTestMail() {
    const configurationData = this.state;
    Services.sendTestMail(configurationData, this.organizationId)
      .then(() => {
        Notification.pushSuccess('A test mail was sent.');
      });
  }

  render() {
    const {
      host,
      port,
      username,
      password,
      protocol,
      testRecipient,
    } = this.state;

    const protocols = [
      { label: 'TLS', value: 'tls' },
      { label: 'SSL', value: 'ssl' },
      { label: 'None', value: 'none' },
    ];
    return (
      <Card>
        <CardHeader>{t('email-configuration#title')}</CardHeader>
        <CardBody>
          <Row>
            <Col sm="12" md="8" lg="6" xl="5">
              <Form data-trackid="update-email-configuration" onSubmit={this.updateConfiguration}>
                <FormGroup>
                  <Label for="host">{t('email-configuration#host')}</Label>
                  <Input
                    id="host"
                    name="host"
                    required
                    value={host}
                    onChange={this.handleTextChange}
                    type="text"
                    placeholder="smtp.gmail.com"
                  />

                  <Label for="port">{t('email-configuration#port')}</Label>
                  <Input
                    id="port"
                    name="port"
                    required
                    value={port}
                    onChange={this.handleTextChange}
                    type="number"
                    placeholder="587"
                  />
                </FormGroup>

                <FormGroup>
                  <Label for="username">{t('username')}</Label>
                  <Input
                    id="username"
                    name="username"
                    required
                    value={username}
                    onChange={this.handleTextChange}
                    type="text"
                  />
                </FormGroup>

                <FormGroup>
                  <Label for="password">{t('email-configuration#password')}</Label>
                  <Input
                    id="password"
                    name="password"
                    required
                    value={password}
                    onChange={this.handleTextChange}
                    type="password"
                  />
                </FormGroup>

                <FormGroup>
                  <Label for="protocol">{t('email-configuration#protocol')}</Label>
                  <Select
                    id="protocol"
                    value={protocol}
                    options={protocols}
                    onChange={this.handleprotocolChange}
                    defaultValue="tls"
                    clearable={false}
                  />
                </FormGroup>

                <FormGroup>
                  <Label for="test_recipient">{t('email-configuration#test-recipient')}</Label>
                  <Input
                    id="testRecipient"
                    name="testRecipient"
                    value={testRecipient}
                    onChange={this.handleTextChange}
                    type="text"
                  />
                </FormGroup>

                <FormGroup>
                  <Button type="submit" color="primary">{t('email-configuration#update')}</Button>
                  <Button
                    id="send-mail-test"
                    type="button"
                    onClick={this.sendTestMail}
                    color="primary"
                    className="ml-2"
                  >
                    {t('email-configuration#send-test-email')}
                  </Button>
                </FormGroup>

              </Form>
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  }
}
