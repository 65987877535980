
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import React from 'react';
import { Button } from 'reactstrap';
import DropDownMenu from '../../components/navigationbar/DropDownMenu';
import { IconDownloadReport, IconDownloadReportLight } from '../../images/CustomIcon';
import { t } from '../../i18n/t';
import GroupEvent from '../../utils/track/GroupEvent';

const REPORT_FILE_EXTENSION = [
  'CSV',
  'XLSX',
  'PDF',
  'ZIP'
];

function DownloadExecutionButton({ onSelectOption, renderOption, ...btnProps }) {

  const dropDownRef = React.useRef(null);

  const toggleDropdown = () => {
    dropDownRef.current.toggle();
  };

  const downloadBtn = (props) => (
    <Button
      {...btnProps}
      {...props}
      data-trackid="download-execution-file"
      data-testid="download-execution-file"
      data-groupid={GroupEvent.ACCESS_REPORT}
    >
      {/*{t('download')}*/}
      <div className="icon-inactive">
        <IconDownloadReport />
      </div>
      <div className="icon-active">
        <IconDownloadReportLight />
      </div>
    </Button>
  );

  const onClick = (fileType) => {
    onSelectOption(fileType);
    toggleDropdown();
  };

  const fileTypeMenu = REPORT_FILE_EXTENSION.map((fileType) => (
    <ListItem
      button
      key={fileType}
      component="button"
      data-trackid={`download-${fileType}`}
      data-groupid={GroupEvent.ACCESS_REPORT}
      onClick={() => onClick(fileType)}
    >
      <ListItemText primary={t(`download-execution#${fileType}`)} />
    </ListItem>
  ));


  return (
    <DropDownMenu
      renderAnchorElement={downloadBtn}
      ref={dropDownRef}
    >
      {fileTypeMenu}
    </DropDownMenu>
  );
}

export default DownloadExecutionButton;
