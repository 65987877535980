import React from 'react';
import {
  Button,
} from 'reactstrap';
import http from '../../utils/http';
import MContext from '../../models/MContext';
import { IconCreateFileActive, IconCreateFileInactive } from '../../images/CustomIcon';
import { t } from '../../i18n/t';

export default class CreateExternalIssueBtn extends React.Component {

  constructor(props) {
    super(props);
    this.state = {};
    this.projectId = MContext.projectId;
    this.testRunId = this.props.testRunId;
    this.createIssue = this.createIssue.bind(this);
  }

  getCreateUrl() {
    return http.get('/api/v1/integrations/create-url', {
      projectId: this.projectId,
      testRunId: this.testRunId,
    });
  }

  createIssue() {
    this.getCreateUrl().then(({ url, katalonScriptUrl }) => {
      if (url) {
        window.open(url, btoa(`katalon=${katalonScriptUrl}`));
      }
    });
  }

  render() {
    return (
      <Button
        type="button"
        color="link"
        onClick={this.createIssue}
        className="two-state-button mx-0 px-2 py-0"
        title={t('create')}
        disabled={this.props.disabled}
      >
        <IconCreateFileActive className="icon-active" />
        <IconCreateFileInactive className="icon-inactive" />
      </Button>
    );
  }
}
