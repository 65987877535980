import _ from 'lodash';
import PropTypes from 'prop-types';
import Cookies from 'cookies-js';
import parse from 'url-parse';
import PageComponent from '../components/PageComponent';
import { t } from '../i18n/t';
import MAuth from '../models/MAuth';
import MConfigs from '../models/MConfigs';
import MFlags from '../models/MFlags';
import Apis from '../utils/Apis';
import http from '../utils/http';
import RouteConstants from '../utils/RouteConstants';
import { AccessToken, OtpToken } from '../utils/Constants';
import UserService from '../auth/UserService';

export default class Login extends PageComponent {

  constructor(props) {
    super(props);
    this.meta.id = 'page-login';
    this.showErrorAlert = this.showErrorAlert.bind(this);
    this.authenticateSSO = this.authenticateSSO.bind(this);

    const urlString = window.location.href;
    const url = new URL(urlString.toLowerCase());
    this.otp = url.searchParams.get('otp');
    this.accessToken = new URL(urlString).searchParams.get(AccessToken.PARAM_NAME);

    if (this.otp || this.accessToken) {
      const newUrl = new URL(urlString);
      newUrl.searchParams.delete('otp');
      newUrl.searchParams.delete(AccessToken.PARAM_NAME);
      if (newUrl.searchParams.toString()) {
        window.history.replaceState(null, null, `${url.protocol}//${url.host}${url.pathname}?${newUrl.searchParams.toString()}`);
      } else {
        window.history.replaceState(null, null, `${url.protocol}//${url.host}${url.pathname}`);
      }
    }
  }

  componentDidMount() {
    if (MAuth.isLoggedIn) {
      this.navigate();
    } else {
      if (!MConfigs.isPre && !MFlags.removeSomeUnsupportedAuthenticationOptionEnabled && this.otp) {
        MAuth.login({
          username: '',
          password: `otp_${this.otp}`,
        }, this.showErrorAlert)
          .then(() => {
            if (MAuth.isLoggedIn) {
              this.navigate();
            }
          })
          .catch(() => {});
      } else if (this.accessToken) {
        if (MConfigs.isPre || MFlags.removeSomeUnsupportedAuthenticationOptionEnabled) {
          UserService.setAccessToken(this.accessToken);
          UserService.setRefreshToken(undefined);
          this.navigate();
        } else {
          this.authenticateSSO(`${AccessToken.PREFIX}${this.accessToken}`);
        }
      } else {
        MAuth.goToLoginPage();
      }
    }
  }

  showErrorAlert(message, label, jqXHR) {
    MAuth.goToLoginPage();
  }

  isRelative(url) {
    return url && url.match(/^\/[^\/\\]/);
  }

  navigate() {
    console.log("Katalon navigate");
    const url = parse(window.location.href, true);
    const redirectEncoded = url.query.redirect;
    if (redirectEncoded) {
      const redirectDecoded = window.decodeURIComponent(redirectEncoded);
      if (!this.isRelative(redirectDecoded)) {
        // prevent redirect attack
        // redirect to home
        window.location.href = RouteConstants.home;
      } else {
        window.location.href = redirectDecoded;
      }
    } else {
      window.location.href = RouteConstants.home;
    }
    // let redirectedUrl = RouteConstants.home;
    // if (redirectEncoded) {
    //   const redirectDecoded = window.decodeURIComponent(redirectEncoded);
    //   if (this.isRelative(redirectDecoded)) {
    //     redirectedUrl = redirectDecoded;
    //   }
    // }
    // this.props.history.push(redirectedUrl);
  }

  authenticateSSO(password) {
    MAuth.login({
      username: '',
      password: password,
    }, this.showErrorAlert)
      .then(() => {
        if (MAuth.isLoggedIn) {
          Cookies.set('refresh_token', undefined);
          Cookies.set('access_token', this.accessToken);
          this.navigate();
        }
      })
      .catch(() => {});
  }

  render() {
    return (
      <div className="loading-spinner-page">
        <div className="loading-spinner" />
      </div>
    );
  }
}

Login.contextTypes = {
  router: PropTypes.object,
};
