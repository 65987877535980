import { Button, Card } from 'reactstrap';
import React, { useState } from 'react';
import { DialogContent, Grid } from '@mui/material';
import AppUploader from './AppUploader';
import TabsDialogs from '../../../components/dialog/TabsDialog';
import { t } from '../../../i18n/t';
import AppDataTable from '../../../components/table/AppDataTable';
import UploadIcon from '../../../../images/icons/katalonui/UploadIcon';

function ApplicationRepository() {

  const [isOpenDialog, setOpenDialog] = useState(false);
  const [reloadTable, setReloadTable] = useState(false);

  const onChange = (value) => {
    setReloadTable(value);
    setOpenDialog(false);
  };

  return (
    <Card className="app-dashboard-card-border shadow-none">
      <Grid container justifyContent="flex-start">
        <Button
          style={{ height: '40px', marginBottom: '16px' }}
          id="upload-application"
          title={t('native-app#button-uploader')}
          color="secondary"
          onClick={() => { setOpenDialog(true); }}
        > <UploadIcon size="12px" marginRight="4px" />
          {t('native-app#button-uploader')}
        </Button>
      </Grid>
      <AppDataTable isReload={reloadTable} onReloaded={setReloadTable} />
      {isOpenDialog &&
      <TabsDialogs
        id="uploader-dialog"
        isOpen={isOpenDialog}
        handleClose={() => { setOpenDialog(false); }}
        maxWidth="md"
      >
        <DialogContent>
          <AppUploader onFinish={onChange} />
        </DialogContent>
      </TabsDialogs>}
    </Card>
  );
}

export default ApplicationRepository;
