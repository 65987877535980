import React from 'react';
import { startCase, toLower } from 'lodash';
import { Typography } from '@mui/material';
import { IconStatus } from '../../images/KitIcons';
import { IconLine } from '../../images/CustomIcon';
import { t } from '../../i18n/t';
import Time from '../../utils/Moment';
import { next } from '../../utils/Count';

const CustomChartTooltip = ({ active, payload }) => {

  const renderItemName = (name) => {
    if (name === 'totalDuration') {
      return t('totalDuration');
    } else if (name === 'totalTestCases') {
      return t('test_cases');
    }
    return startCase(toLower(name));
  };

  const renderItemLabel = (item) => {
    if (item.name === 'totalDuration') {
      return (
        <IconLine
          className="mr-1 tooltip-icon-label"
          key={item.color}
          fill={item.color}
        />);
    }
    return (
      <IconStatus
        className="mr-1 tooltip-icon-label"
        key={item.color}
        fill={item.color}
      />);
  };

  const renderItemValue = (item) => {
    if (item.name === 'totalDuration') {
      return Time.duration(item.value);
    }
    return item.value;
  };

  const renderItem = (item) => (
    <div key={next()} className="d-flex justify-content-between mb-2">
      <div className="d-flex mr-4 align-items-center">
        {renderItemLabel(item)}
        {renderItemName(item.name)}:
      </div>
      <div>
        {renderItemValue(item)}
      </div>
    </div>
  );

  const renderTooltipHeader = ({ payload }) => {
    if (payload) {
      return payload.name || Time.format(payload.time, Time.LAST_MONTHS_FORMAT, true);
    }
    return null;
  };

  return (
    <>
      {active &&
        <div className="custom-chart-tooltip">
          <Typography variant="h6" className="mb-2">
            {payload && payload[0] != null && renderTooltipHeader(payload[0])}
          </Typography>
            {payload && payload.map(renderItem)}
        </div>}
    </>
  );
};

export default CustomChartTooltip;
