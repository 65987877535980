import React from 'react';
import _, { filter, get } from 'lodash';
import { Icon } from '@katalon-studio/katalon-ui';
import { Grid } from '@mui/material';
import Link from '../../components/Link';
import Routes from '../../utils/Routes';
import { t } from '../../i18n/t';
import { ExternalIssue } from '../../models/model/ExternalIssue';
import { ExecutionTestResult } from '../../models/model/ExecutionTestResult';
import { badgeComponent, verticalDivider } from '../../pages/execution/ExecutionDecorator';
import DecoratorConstants from '../../utils/DecoratorConstants';
import { IconDefectBug, IconDuration, IconJiraPriority, IconStartTime } from '../../images/CustomIcon';

export const DefectDecorator = {
  iconDecorator: () => (
    <IconDefectBug data-testid="icon-defect-bug" className="mr-2" />
  ),

  nameDecorator: (row: ExternalIssue, isTruncate: boolean) => {
    const summary = _.get(row, 'summary');
    return (
      <div className={`d-flex align-items-baseline ${isTruncate && 'flex-column'}`}>
        {summary}
      </div>
    );
  },

  idDecorator: (row: ExternalIssue) => {
    const issuedId = _.get(row, 'issueId');
    return (
      <>
        <div>
          #{issuedId}
        </div>
        {verticalDivider()}
      </>
    );
  },

  priorityDecorator: (row: ExternalIssue) => {
    const priority = _.get(row, 'priority');
    return priority && (
      <>
        <span className="d-flex align-items-center">
          <IconJiraPriority className="mr-1" />
          {priority}
        </span>
        {verticalDivider()}
      </>
    );
  },

  startTimeDecorator: (row: ExternalIssue) => (
    <>
      <span className="d-flex align-items-center">
        <IconStartTime title={t('issue-created')} className="mr-1" />
        {DecoratorConstants.timeDecorator('issueCreated', row)}
      </span>
      {verticalDivider()}
    </>
  ),

  assigneeDecorator: (row: ExternalIssue) => {
    const avatarUrl = _.get(row, 'avatarUrl');
    const avatarName = _.get(row, 'assignee');
    if (!avatarUrl || !avatarName) {
      return null;
    }
    return (
      <Grid item className="mr-3">
        <span className="d-flex align-items-center">
          {DecoratorConstants.renderUserAvatarDecorator(avatarName, avatarUrl, true)}
        </span>
      </Grid>
    );
  },

  priorityWithIconDecorator: (row: ExternalIssue) => {
    const priority = _.get(row, 'priority');
    return priority && (
      <Grid item className="mr-3">
        <span className="d-flex align-items-center">
          {DecoratorConstants.defectPriorityDecorator(row, 'img-fluid icon-issue-type')}
        </span>
      </Grid>
    );
  },

  severityDecorator: (row: ExternalIssue) => {
    const severity = _.get(row, 'severity');
    return severity && (
      <Grid item className="mr-3">
        <span className="d-flex align-items-center text-nowrap" title={severity}>
          {DecoratorConstants.truncateLeftStringDecorator(severity, 10)}
        </span>
      </Grid>
    );
  },

  issueTimeStampDecorator: (row: ExternalIssue) => {
    const issueTimeStamp = _.get(row, 'issueTimestamp');
    if (!issueTimeStamp) {
      return null;
    }
    return (
      <Grid item className="mr-3">
        <span className="d-flex align-items-center text-nowrap">
          <div className="d-flex align-items-center">
            <IconDuration title={t('last-update')} className="mr-2 fixed-1rem-icon" />
          </div>
          {DecoratorConstants.timeDecoratorValue(issueTimeStamp, null)}
        </span>
      </Grid>
    );
  },

  testResultNameDecorator: (row: ExternalIssue, executionId?: number) => {
    const testResults: ExecutionTestResult[] | undefined = get(row, 'testResults');
    const testResultsFromExecution = filter(testResults, (testResult: ExecutionTestResult) => (testResult.execution.id === executionId));
    if (!testResultsFromExecution) {
      return null;
    }
    const firstTestResult = testResultsFromExecution[0];
    const constructedLink = new Routes({
      executionId: _.get(firstTestResult, 'execution.order'),
      executionTestResultId: firstTestResult.urlId,
    });
    return testResultsFromExecution && (
      <Grid className="d-flex align-items-center" wrap="nowrap" xs={4}>
        <span className="mr-2 d-flex align-items-center">
          <Icon
            type="fa-regular"
            name="fa-list-check"
            textSize="14px"
            sx={{
              marginRight: '0 !important',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          />
        </span>
        <Link
          className="d-block text-nowrap defect-link"
          href={constructedLink.execution_test_result_detail_link}
          target="_blank"
          rel="noopener noreferrer"
        >
          {firstTestResult?.testCase?.name}
        </Link>
        {testResultsFromExecution.length > 1 && badgeComponent(testResultsFromExecution.length - 1)}
      </Grid>
    );
  },
};
