import React from 'react';

function SubscriptionPrices(props) {
  const { tax, total, subtotal } = props?.prices || {};
  return (
    <>
      <div className="prices">
        <div className="prices__label"><b>Subtotal</b> (USD)</div>
        <div className="prices__value">{`$ ${(subtotal || 0).toLocaleString()}`}</div>
      </div>
      <div className="prices">
        <div className="prices__label"><b>Tax</b> (USD)</div>
        <div className="prices__value">{`$ ${(tax || 0).toLocaleString()}`}</div>
      </div>
      <div className="prices">
        <div className="prices__sup_label"><b>Order Total</b> (USD)</div>
        <div className="prices__sup_value">{`$ ${(total || 0).toLocaleString()}`}</div>
      </div>
    </>
  );
}

export default SubscriptionPrices;
