import _ from 'lodash';
import Apis from '../../../utils/Apis';
import { ZONE_TYPE, CONTENT_ZONE_TYPE, JSON_CONTENT_ZONE_TYPE, COMPARISON_TYPE, MAX_FILE_SIZE_ALLOW_UPLOAD } from './Constants';
import { t } from '../../../i18n/t';

export const getFileUrl = (item, path) => {
  const fileHandleId = _.get(item, path);
  if (fileHandleId) {
    return Apis.fileDownload(fileHandleId);
  } else {
    return null;
  }
};

export const calHeight = (source, width, setHeight) => {
  const image = new Image();
  image.src = source;
  image.onload = () => {
    const ratio = width / image.width;
    const height = image.height * ratio;
    setHeight(height);
  };
};

const typeConstant = { correct_match: ZONE_TYPE.matchZone, template_match: ZONE_TYPE.diffPartZone, diff_match: ZONE_TYPE.diffPartZone, non_match: ZONE_TYPE.diffZone };

export const filterZones = (filter, zone_list, mode) => {
  if (zone_list) {
    if (mode === COMPARISON_TYPE.LAYOUT) {
      return zone_list.filter((value) => filter[typeConstant[value.type]]);
    } else {
      return zone_list.filter((value) => filter[value.type]);
    }
  }
  return null;
};

export const getArea = (img) => (img.w * img.h);


export const checkZone = (img, ratio, x, y) => x > img.x * ratio && x < (img.x + img.w) * ratio &&
    y > img.y * ratio && y < (img.y + img.h) * ratio;

export const findMinZone = (hoverZoneList, zoneList, getZoneArea) => {
  if (hoverZoneList.length === 0) return -1;
  const zoneFinal = hoverZoneList.reduce((prev, current) => {
    if (getZoneArea(prev) > getZoneArea(current)) {
      return current;
    } else {
      return prev;
    }
  });
  return zoneList.indexOf(zoneFinal);
};

export const extractKeyesExecutionId = (conditions) => {
  const condition = conditions.filter((con) => con.key === 'KeyesExecution.id');
  if (condition.length !== 0) {
    return condition[0];
  }
  return null;
};

export const convertContentJson = (data) => data.map((zone) => {
  if (zone.type === JSON_CONTENT_ZONE_TYPE.notExistInBaseline || zone.type === JSON_CONTENT_ZONE_TYPE.notExistInCheckpoint) {
    return ({
      ...zone,
      type: CONTENT_ZONE_TYPE.missingNewZone
    });
  } else if (zone.type === JSON_CONTENT_ZONE_TYPE.shift) {
    return ({
      ...zone,
      type: CONTENT_ZONE_TYPE.shiftedZone
    });
  } else {
    return ({
      ...zone,
      type: CONTENT_ZONE_TYPE.identicalZone
    });
  }
});

export const drawRect = (ctx, coor, ratio, color) => {
  ctx.lineWidth = 2;
  ctx.beginPath();
  ctx.strokeStyle = color;
  ctx.rect(
    coor.x * ratio,
    coor.y * ratio,
    coor.w * ratio,
    coor.h * ratio
  );
  ctx.stroke();
};

/**
 * Function to draw a rect with border color and background color
 * @param {Array} coor - list of Coordinate
 * @param {String} strokeColor - border color
 * @param {String} color - background color
 */
export const fillRect = (ctx, coor, ratio, strokeColor, color) => {
  ctx.lineWidth = 2;
  ctx.beginPath();
  ctx.strokeStyle = strokeColor;
  ctx.fillStyle = color;
  ctx.rect(
    coor.x * ratio,
    coor.y * ratio,
    coor.w * ratio,
    coor.h * ratio
  );
  ctx.fill();
  ctx.stroke();
};


const convertImgToCoor = (img) => {
  if (img === null) return null;
  const { startpoint, endpoint } = img;
  return ({
    x: startpoint[0],
    y: startpoint[1],
    w: endpoint[0] - startpoint[0],
    h: endpoint[1] - startpoint[1]
  });
};

export const convertLayoutJSON = (data) => data.map((item) => ({
  ...item,
  bl_coor: convertImgToCoor(_.get(item, 'img1')),
  cp_coor: convertImgToCoor(_.get(item, 'img2'))
}));

const extractScreenshotNameByFileName = (fileName) => {
  const KEYES_PREFIX = 'keyes-';
  if (fileName.startsWith(KEYES_PREFIX)) {
    fileName = fileName.replace(KEYES_PREFIX, '');
  }
  return fileName.split('.').slice(0, -1).join('.');
};

export const validateImage = (image, baselines) => {

  /**
   * Convert MB to Bytes
   * by multiply it to square of 1024.
   */
  const maxImageByteSize = MAX_FILE_SIZE_ALLOW_UPLOAD * (2 ** 20);

  const isSameResolution = (image, baseline) =>
    image.width === baseline.width
    && image.height === baseline.height;

  const isSameName = (imageName, baselineName) => {
    const exactedImageName = extractScreenshotNameByFileName(imageName);
    return exactedImageName === baselineName;
  };

  if (baselines) {
    const duplicatedBaseline = _.find(baselines, (baseline) => {
      const { screenshot } = baseline;
      const { resolution: imageResolution, name } = image;
      return isSameName(name, screenshot.name) && isSameResolution(imageResolution, screenshot);
    });

    if (duplicatedBaseline) {
      return t('visual-testing#baseline-collection#duplicated-name');
    }
  }

  if (maxImageByteSize < image.size) {
    return t('visual-testing#baseline-collection#invalid-size', { size: MAX_FILE_SIZE_ALLOW_UPLOAD });
  }

  return '';
};
