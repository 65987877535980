import React from 'react';
import { ButtonToolbar } from 'reactstrap';
import jquery from 'jquery';
import ReactDOM from 'react-dom';
import { ID_MAIN_HEADER_TOOLBAR } from '../utils/Constants';

class PageButtonToolbar extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      ready: false,
    };
  }

  componentDidMount() {
    this.checkExist = setInterval(this.updateState(), 50);
  }

  updateState() {
    this.setState((prevState) => ({
      ready: !prevState.ready,
    }));
  }

  renderToolbar() {
    return (
      <ButtonToolbar>
        {this.props.children}
      </ButtonToolbar>
    );
  }

  render() {
    const toolbarQuery = jquery(`#${ID_MAIN_HEADER_TOOLBAR}`);
    let toolbar = null;
    if (toolbarQuery.length > 0) {
      toolbar = ReactDOM.createPortal(
        this.renderToolbar(),
        toolbarQuery.get(0)
      );
      clearInterval(this.checkExist);
    }

    return toolbar;
  }
}

export default PageButtonToolbar;
