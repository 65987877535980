import React from 'react';
import PropTypes from 'prop-types';
import TooltipComponent from './TooltipComponent';

const DefectChartSummary = ({ summary }) => (
  <div className="defect-chart-summary-element">
    <div>
      <span className="title">
        {summary.title}
      </span>
      <TooltipComponent text={summary.tooltipContent} placement="bottom-start" arrow />
    </div>
    <div>
      <span className={`background-icon ${summary.iconStyle}`}>
        {summary.icon}
      </span>
      <span className="total-issue">
        {summary.totalIssues}
      </span>
    </div>
  </div>
);

DefectChartSummary.propTypes = {
  summary: PropTypes.shape({
    type: PropTypes.string,
    totalIssues: PropTypes.number,
    icon: PropTypes.object,
    title: PropTypes.string,
    tooltipContent: PropTypes.string,
    iconStyle: PropTypes.string,
  }).isRequired
};

export default DefectChartSummary;
