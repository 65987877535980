import React from 'react';
import { Button, Card, CardBody, CardHeader, Col, Form, FormGroup, Label, Row } from 'reactstrap';
import Input from '../../components/Input';
import PageButtonToolbar from '../../components/PageButtonToolbar';
import PageComponent from '../../components/PageComponent';
import { buildSearchCondition } from '../../components/search/SearchUtils';
import LicenseKeysDataTable from '../../components/table/LicenseKeysTable';
import { t } from '../../i18n/t';
import MAuth from '../../models/MAuth';
import MContext from '../../models/MContext';
import { notificationIds } from '../../utils/NotificationPageUtils';
import Routes from '../../utils/Routes';
import Services from '../../utils/Services';
import DecoratorConstants from '../../utils/DecoratorConstants';
import MConfigs from '../../models/MConfigs';
import Arrays from '../../utils/Arrays';


class OrganizationTestOpsLicensesTab extends PageComponent {
  constructor(props) {
    super(props);
    this.meta.id = 'page-organization-testops-licenses';
    this.meta.title = t('testops_license_keys');
    this.organizationId = MContext.organizationId;
    this.feature = 'TESTOPS';
    this.state = {
      organization: null,
    };
  }

  componentDidMount() {
    Services.organizationWithLicense(this.organizationId, this.feature)
      .then((organization) => this.setState({ organization }));
  }

  renderTestOpsQuota() {
    const { organization } = this.state;
    const { quotaTestOps = 0, usedTestOps = 0 } = organization;
    const subscriptionExpiryDateTestOps = DecoratorConstants.timeDecorator('subscriptionExpiryDateTestOps', organization);
    return (
      <Card>
        <CardHeader>{t('license_keys#quota_header')}</CardHeader>
        <CardBody>
          <Row>
            <Col sm="12" md="8" lg="6" xl="5">
              <Form data-trackid="testops-license-key-quota">
                {subscriptionExpiryDateTestOps && (
                  <FormGroup>
                    <Label htmlFor="quota">{t('license_keys#expiry_date')}</Label>
                    <Input plaintext>{subscriptionExpiryDateTestOps}</Input>
                  </FormGroup>
                )}
                <FormGroup>
                  <Label htmlFor="quota">{t('license_keys#quota')}</Label>
                  <Input plaintext>{quotaTestOps}</Input>
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="quota">{t('license_keys#remaining')}</Label>
                  <Input plaintext>
                    {usedTestOps >= quotaTestOps ? 0 : quotaTestOps - usedTestOps}
                  </Input>
                </FormGroup>
              </Form>
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  }

  renderOfflineTestOpsLicenseKeyTable() {
    const defaultSearchConditions = [
      buildSearchCondition('Organization.id', '=', this.organizationId),
      buildSearchCondition('type', '=', 'ENTERPRISE'),
      buildSearchCondition('feature', '=', 'TESTOPS'),
      buildSearchCondition('mode', '=', 'COMMUTER'),
      ...Arrays.insertIf(!MAuth.isSystemRole, buildSearchCondition('status', '=', 'ACTIVE')),
    ];
    return (
      <>
        <LicenseKeysDataTable
          title={t('license_keys#offline')}
          defaultSearchConditions={defaultSearchConditions}
          showNumberUser
          isOfflineLicense={true}
        />
      </>
    );
  }

  renderCreateLicenseKey() {
    const organizationId = this.organizationId;
    const routes = new Routes({ organizationId });
    return (
      <PageButtonToolbar>
        <Button
          title={t('license_keys#create_license_key')}
          color="secondary"
          href={routes.organization_create_testops_license_keys_link}
        >
          {t('license_keys#create_license_key')}
        </Button>
      </PageButtonToolbar>
    );
  }

  render() {
    if (MConfigs.isOnPremise) {
      return null;
    }

    const isOrganizationManager = MAuth.isOrganizationManager(this.organizationId);
    const isBillingManager = MAuth.isBillingManager(this.organizationId);

    if (!isOrganizationManager && !isBillingManager && !MAuth.isSystemRole()) {
      Routes.goToNotificationPage(notificationIds.NO_PERMISSION);
    }

    const { organization } = this.state;
    if (organization) {
      return (
        <>
          {isOrganizationManager && this.renderCreateLicenseKey()}
          {isOrganizationManager && this.renderOfflineTestOpsLicenseKeyTable()}
        </>
      );
    } else {
      return null;
    }

  }
}

export default OrganizationTestOpsLicensesTab;
