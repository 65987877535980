import { map, filter, findIndex, concat, indexOf } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button } from 'reactstrap';
import { useHistory } from 'react-router';

import { t } from '../../i18n/t';
import MContext from '../../models/MContext';
import WebSocket from '../../services/WebSocket';
import { next } from '../../utils/Count';
import { SearchEntity, WebSocketTopics } from '../../utils/Constants';
import DropDownMenu from '../navigationbar/DropDownMenu';
import DataLoader from '../table/DataLoader';
import Services from '../../utils/Services';
import { buildSearchCondition } from '../search/SearchUtils';
import NotificationBadge from './NotificationBadge';
import Notifications from './Notifications';
import { NOTIFICATION_TYPES, PAGE_SIZE } from './constants';

/**
 * Notification dropdown component.
 */
const NotificationToggler = () => {
  const [numOfNotifications, setNumOfNotifications] = useState(0);
  const [notifications, setNotifications] = useState([]);
  const [notificationsChanged, setNotificationChanged] = useState([]);
  const [loadFinished, setLoadFinished] = useState(false);
  const history = useHistory();

  const dataRef = useRef();

  const defaultSearchConditions = MContext.teamId ? [buildSearchCondition('Team.id', '=', MContext.teamId)] : [];

  const fetchNumberOfNotifications = () => {
    Services.getNumberOfNotifications()
      .then((value) => {
        setNumOfNotifications(value);
      });
  };

  const notifyHandler = () => {
    fetchNumberOfNotifications();
  };

  const subscribeTopic = (handlerId) => {
    const { projectId, teamId } = MContext;
    if (projectId || teamId) {
      WebSocket.subscribe({
        projectId,
        teamId,
        topics: [WebSocketTopics.TEST_CLOUD_TUNNEL],
      }, notifyHandler, handlerId);
    }
  };

  useEffect(() => {
    fetchNumberOfNotifications();

    const handlerId = next();
    subscribeTopic(handlerId);

    return () => {
      WebSocket.unsubscribe(handlerId);
    };
  }, []);

  const markAllNotificationsAsRead = () => {
    Services.markAllNotificationsAsRead()
      .then(() => {
        setNotifications(map([...notifications], (notification) => ({ ...notification, read: true })));
        setNumOfNotifications(0);
      });
  };

  const renderAnchorButton = useCallback((btnProps) =>
    <NotificationBadge number={numOfNotifications} {...btnProps} />, [numOfNotifications]);

  const markNotificationAsRead = (notification, index, onSuccess) => {
    Services.markNotificationAsRead(notification.id)
      .then(() => {
        const newNotifications = [...notifications];
        newNotifications[index] = {
          ...notifications[index],
          read: true,
        };
        setNotifications(newNotifications);
        if (onSuccess) {
          onSuccess(notification);
        }
      });
  };

  const loadMore = (page) => {
    if (dataRef.current) {
      dataRef.current.goToPage(page);
    }
  };

  const onChangeData = (items, page) => {
    if (page === 0) {
      setNotifications([]);
      setLoadFinished(false);
    }
    setNotificationChanged(items);
    if (items.length < PAGE_SIZE) {
      setLoadFinished(true);
    }
  };

  const resetNotificationNumber = () => {
    if (numOfNotifications > 0) {
      Services.resetNotificationNumber()
        .then(() => {
          setNumOfNotifications(0);
        });
    }
  };

  const navigateToNotificationDetail = (notification) => {
    const { notificationType, payload = {} } = notification;
    if (notificationType && (indexOf(
      [
        NOTIFICATION_TYPES.TUNNEL_ERROR,
        NOTIFICATION_TYPES.NO_AVAILABLE_TUNNEL,
        NOTIFICATION_TYPES.LIMIT_QUOTA
      ],
      notificationType
    )) >= 0) {
      const { teamId, projectId, planId } = payload;
      if (teamId && projectId && planId) {
        window.location.href = `/team/${teamId}/project/${projectId}/grid/plan/${planId}/job`;
      }
    }
  };

  const handleNotificationItemClick = (notification, index) => {
    markNotificationAsRead(notification, index, () => {
      navigateToNotificationDetail(notification);
    });
  };

  useEffect(() => {
    const newItems = filter(notificationsChanged, ({ id }) => findIndex(notifications, { id }) === -1);
    const newData = concat(notifications, newItems);
    setNotifications(newData);
  }, [notificationsChanged]);

  return (
    <DropDownMenu renderAnchorElement={renderAnchorButton}>
      <div className="top-nav-dropdown top-nav__notification-toggler">
        <DataLoader
          ref={dataRef}
          entityType={SearchEntity.Notification}
          render={() => <></>}
          title={t('notifications')}
          pageSize={PAGE_SIZE}
          customLoading={<></>}
          defaultSearchConditions={defaultSearchConditions}
          noCard
          hidePaging
          defaultSort={['notification.createdAt, desc']}
          onChangeData={onChangeData}
        />
        <div className="title notification-toggler__title">
          {t('notifications')}
          {notifications.length > 0 && (
            <Button onClick={markAllNotificationsAsRead} color="link">
              {t('notifications#mask-all-as-read')}
            </Button>
          )}
        </div>
        <div className="top-nav__notification-listing">
          <Notifications
            notifications={notifications}
            loadFinished={loadFinished}
            loadMore={loadMore}
            markNotificationAsRead={markNotificationAsRead}
            onNotificationItemClick={handleNotificationItemClick}
            onFinishedMount={resetNotificationNumber}
          />
        </div>
      </div>
    </DropDownMenu>
  );
};

export default NotificationToggler;
