import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { IconInfoCircle } from '../images/CustomIcon';

const style = makeStyles(() => ({
  tooltip: {
    backgroundColor: '#172B4D',
    color: '#FFFFFF',
    fontWeight: '300',
    margin: '4px',
    lineHeight: '1rem',
    fontSize: '0.75rem',
    whiteSpace: 'pre-line',
  },
  arrow: {
    color: '#172B4D',
  }
}));

function TooltipComponent({ text, placement = 'right', children, arrow = false, followCursor, open, disableHoverListener, customClasses, customClassName = '', popperProps = {} }) {
  let classes;
  if (customClassName !== '') {
    classes = { tooltip: customClassName };
  } else {
    classes = customClasses || style();
  }
  return (
    <Tooltip classes={classes} title={text} placement={placement} arrow={arrow} followCursor={followCursor} open={open} disableHoverListener={disableHoverListener} PopperProps={popperProps}>
      {children || <span className="align-self-center ml-2"><IconInfoCircle /></span>}
    </Tooltip>
  );
}

TooltipComponent.propTypes = {
  text: PropTypes.string.isRequired,
  placement: PropTypes.string,
  children: PropTypes.node,
  arrow: PropTypes.bool,
  followCursor: PropTypes.bool,
  open: PropTypes.bool,
  disableHoverListener: PropTypes.bool,
  customClasses: PropTypes.object,
  customClassName: PropTypes.string,
  popperProps: PropTypes.object,
};
export default TooltipComponent;
