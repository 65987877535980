import React from 'react';
import { Form, FormGroup, Button } from 'reactstrap';
import PageComponent from '../../components/PageComponent';
import DefaultLayout from '../../components/DefaultLayout';
import ObjectSummary from '../../components/summary/ObjectSummary';
import { t } from '../../i18n/t';
import Services from '../../utils/Services';
import Plugin from './Plugin';
import Input from '../../components/Input';
import MContext from '../../models/MContext';
import Routes from '../../utils/Routes';
import { notificationIds } from '../../utils/NotificationPageUtils';
import MConfigs from '../../models/MConfigs';

export default class Plugins extends PageComponent {
  constructor(props) {
    super(props);
    this.organization = MContext.organization;
    this.storeUrl = MConfigs.storeUrl;
    this.getPlugins = this.getPlugins.bind(this);
    this.renderBody = this.renderBody.bind(this);
    this.onTextChange = this.onTextChange.bind(this);
    this.meta.id = 'page-plugin';
    this.meta.title = t('plugins');

    this.state = {
      query: '',
      plugins: [],
    };

  }
  componentDidMount() {
    this.getPlugins();
  }

  getPlugins(event) {
    if (event) {
      event.preventDefault();
    }
    const { query } = this.state;
    Services.getPlugins(this.storeUrl, query).then((data) => {
      this.setState({ plugins: data });
    });
  }

  onTextChange(event) {
    this.setState({
      query: event.target.value,
    });
  }

  renderSearch() {
    const { query } = this.state;
    return (
      <Form data-trackid="search-plugins" inline onSubmit={this.getPlugins}>
        <FormGroup>
          <Input
            className="plugins__search-input"
            type="text"
            placeholder="Search plugins"
            value={query}
            onChange={this.onTextChange}
          />
          <Button className="plugins__search-button" color="primary">Search</Button>
        </FormGroup>
      </Form>
    );
  }

  renderBody() {
    const { plugins } = this.state;

    return (
      <div className="plugins">
        {this.renderSearch()}
        <div className="row">
          {plugins.map((plugin) => <Plugin key={plugin.id} data={plugin} />)}
        </div>
      </div>
    );
  }

  renderHeader() {
    const urlParams = {
      plugins: t('plugins'),
    };
    return (
      <>
        <ObjectSummary params={urlParams} />
      </>
    );
  }

  render() {
    const isEnterpriseOrganization = MContext.isEnterpriseOrganization;
    const isOnPremise = MConfigs.isOnPremise;
    if (!isEnterpriseOrganization) {
      Routes.goToNotificationPage(notificationIds.NO_PERMISSION);
    }
    if (isOnPremise) {
      Routes.goToNotificationPage(notificationIds.NOT_FOUND);
    }
    return (
      <DefaultLayout
        renderHeader={this.renderHeader}
        renderBody={this.renderBody}
      />
    );
  }
}
