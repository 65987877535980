import React from 'react';
import { useHistory } from 'react-router-dom';
import Button from '@katalon-studio/katalon-ui/Button';
import { ThemeProvider } from '@mui/material';
import { katalonui_theme } from '../katalonui-theme';

interface KUIButtonLinkProps {
    link?: string;
    onClick?: () => void;
    children: React.ReactNode;
}
function KUIButtonLink(props: KUIButtonLinkProps) {

  const history = useHistory();

  const handleClick = () => {
    if (props.link) {
      if (props.onClick) {
        props.onClick();
      }
      history.push(props.link);
    } else if (props.onClick) {
      props.onClick();
    }
  };

  return (
    <ThemeProvider theme={katalonui_theme}>
      <Button
        {...props}
        onClick={handleClick}
      >
        {props.children}
      </Button>
    </ThemeProvider>
  );
}

export default KUIButtonLink;
