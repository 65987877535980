import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Box as MuiBox } from '@mui/material';

const style = makeStyles({
  box: {
    display: 'flex',
    alignItems: 'center',
    width: 'fit-content',
    '& hr': {
      marginLeft: '0.5rem',
      marginRight: '0.5rem'
    },
  }
});

const Box = ({ children }) => <MuiBox className={style().box}>{children}</MuiBox>;


export default Box;
