import React from 'react';
import { Button, Row, Col, Form, FormGroup, Card, CardHeader, CardBody } from 'reactstrap';
import _ from 'lodash';
import PageComponent from '../components/PageComponent';
import { t } from '../i18n/t';
import MTableColumnDataMapping from '../components/table/models/MTableColumnDataMapping';
import Routes from '../utils/Routes';
import ObjectSummary from '../components/summary/ObjectSummary';
import DecoratorConstants from '../utils/DecoratorConstants';
import Services from '../utils/Services';
import { buildSearchCondition } from '../components/search/SearchUtils';
import Notification from '../utils/Notification';
import Input from '../components/Input';
import MContext from '../models/MContext';
import DataTable from '../components/table/DataTable';
import DefaultLayout from '../components/DefaultLayout';
import { IconSettings } from '../images/CustomIcon';
import MConfigs from '../models/MConfigs';
import Apis from '../utils/Apis';

class Teams extends PageComponent {

    teamsList = null;

    constructor(props) {
      super(props);
      this.meta.id = 'page-teams-settings';
      this.meta.title = t('Teams');
      this.organizationId = MContext.organizationId;
      this.teamHeader = this.teamHeader.bind(this);
      this.handleTeamNameChange = this.handleTeamNameChange.bind(this);
      this.addTeam = this.addTeam.bind(this);
      this.renderHeader = this.renderHeader.bind(this);
      this.renderBody = this.renderBody.bind(this);
      this.state = {
        newTeamName: '',
        disabledInput: '',
      };
    }

    addTeam(e) {
      e.preventDefault();
      const newTeamName = this.state.newTeamName;

      if (newTeamName) {
        const teamData = {
          name: newTeamName,
          organizationId: this.organizationId,
        };
        this.setState({ disabledInput: 'disabled' });
        Services.createNewTeam(teamData)
          .then(() => {
            Notification.pushSuccess(`Team ${this.state.newTeamName} was created.`);

            this.teamsList.refreshData();
          })
          .finally(() => {
            this.setState({
              disabledInput: '',
              newTeamName: '',
            });
          });
      }
    }

    handleTeamNameChange(event) {
      const newTeamName = event.target.value;
      this.setState({ newTeamName });
    }

    teamHeader() {
      const { isLicenseAdminServer } = MConfigs;
      return [
        new MTableColumnDataMapping(t('name'), 'name', (name, row) => {
          const route = new Routes({
            teamId: row.id,
          });
          return DecoratorConstants.idDecorator(
            name,
            row,
            isLicenseAdminServer
              ? Apis.testOpsTeamHome(row.id)
              : route.team_link
          );
        }),
        new MTableColumnDataMapping('Role', 'role', (name, row) => {
          const role = row.role;
          const capitalizedRole = _.startCase(_.toLower(role));
          return <span>{capitalizedRole}</span>;
        }),
        new MTableColumnDataMapping(
          t('action'),
          'name',
          (name, row) => {
            const role = row.role;
            if (role === 'USER') {
              return null;
            } else {
              return (
                this.renderSettingButton(name, row)
              );
            }
          },
          true,
        ),
      ];
    }

    renderSettingButton(name, row) {
      const routes = new Routes({
        teamId: row.id,
      });
      return (
        <a href={routes.admin_team_settings_link}>
          <IconSettings />
        </a>
      );
    }

    renderObjectSummary() {
      const urlParams = {
        teams: t('teams'),
      };
      return (
        <ObjectSummary params={urlParams} />
      );
    }

    renderHeader() {
      return (
        <>
          {this.renderObjectSummary()}
        </>
      );
    }

    renderBody() {
      const teamHeader = this.teamHeader();
      const { newTeamName, disabledInput } = this.state;

      return (
        <>

          <Card>
            <CardHeader>{t('create_team')}</CardHeader>
            <CardBody>
              <Row>
                <Col sm="12" md="8" lg="6" xl="5">
                  <Form data-trackid="create-team" onSubmit={this.addTeam}>
                    <FormGroup>
                      <Input
                        id="team-name"
                        disabled={disabledInput}
                        value={newTeamName}
                        onChange={this.handleTeamNameChange}
                        type="text"
                        placeholder="Team name"
                      />
                    </FormGroup>
                    <FormGroup>
                      <Button id="create-team" type="submit" color="primary">Create</Button>
                    </FormGroup>
                  </Form>
                </Col>
              </Row>
            </CardBody>
          </Card>

          <DataTable
            title={t('teams')}
            ref={(ref) => {
              this.teamsList = ref;
            }}
            entityType="Team"
            defaultSearchConditions={[
              buildSearchCondition('Organization.id', '=', this.organizationId),
            ]}
            columnMapping={teamHeader}
          />

        </>
      );
    }

    render() {
      return (
        <DefaultLayout
          renderHeader={this.renderHeader}
          renderBody={this.renderBody}
        />
      );
    }
}

export default Teams;
