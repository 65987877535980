import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip as MuiTooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyle = makeStyles(() => ({
  tooltip: {
    padding: '1rem',
  }
}));

/**
 * Wrapped of MuiTooltip to override style with system
 * @param {*} content
 * @param {*} children
 */
const Tooltip = ({ content, children, ...props }) => {
  const newProps = {
    ...props,
  };

  const classes = useStyle();
  return (
    <MuiTooltip classes={classes} title={content} {...newProps}>
      {children}
    </MuiTooltip>
  );
};

Tooltip.propTypes = {
  content: PropTypes.any.isRequired,
  children: PropTypes.any.isRequired,
  placement: PropTypes.string,
  interactive: PropTypes.bool,
  leaveDelay: PropTypes.number,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
};

Tooltip.defaultProps = {
  placement: 'right-start',
  interactive: false,
  leaveDelay: 0,
  onOpen: undefined,
  onClose: undefined,
};

export default Tooltip;
