import React, { Component } from 'react';
import { List, ListItem, ListItemText } from '@mui/material';
import { Label } from 'reactstrap';
import DataLoader from '../../../components/table/DataLoader';
import MContext from '../../../models/MContext';
import { buildSearchCondition } from '../../../components/search/SearchUtils';
import Time from '../../../utils/Moment';
import Routes from '../../../utils/Routes';
import { t } from '../../../i18n/t';
import Link from '../../../components/Link';

class NextRunCard extends Component {

  constructor(props) {
    super(props);
    this.teamId = MContext.teamId;
    this.projectId = MContext.projectId;
  }

  renderNoScheduler() {
    return (
      <Label>
        No upcoming jobs.
      </Label>
    );
  }

  renderNextRunItem(scheduler) {
    const routes = new Routes({ runConfigurationId: scheduler.runConfigurationId });
    return (
      <ListItem
        key={scheduler.id}
        button
        component="a"
        href={routes.run_configuration_link}
      >
        <ListItemText
          primary={scheduler.runConfiguration.name}
          secondary={
            <>
              {Time.format(scheduler.nextTime)}
              <br />
              By {scheduler.name}
            </>
          }
        />
      </ListItem>
    );
  }
  renderNextRun(schedulers) {
    const route = new Routes({
      teamId: this.teamId,
      projectId: this.projectId,
    });
    return (
      <>
        {schedulers.length > 0 && (
          <List>
            {schedulers.map((scheduler) => this.renderNextRunItem(scheduler))}
          </List>
        )}
        {schedulers.length === 0 && this.renderNoScheduler()}
        <div className="cards-row__view_all">
          <Link href={route.grid_link}>
            {t('view-all')}
          </Link>
        </div>
      </>
    );
  }

  render() {
    return (
      <>
        <DataLoader
          entityType="Scheduler"
          render={(data) => this.renderNextRun(data)}
          defaultSort={['nextTime,asc']}
          defaultSearchConditions={[
            buildSearchCondition('Project.id', '=', this.projectId),
            buildSearchCondition('nextTime', 'is not null', ''),
          ]}
          pageSize={3}
          hidePaging
          title={t('ci#dashboard#upcomingJob')}
          {...this.props}
        />
      </>
    );
  }
}

export default NextRunCard;
