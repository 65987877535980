import Icon from '@katalon-studio/katalon-ui/Icon';
import React from 'react';
import colors from '../../../scss/colors.scss';

function PlayCircleIcon({ color, textSize }) {
  return <Icon
    type="fa-regular"
    name="fa-play-circle"
    textSize={textSize}
    boundingSize={null}
    color={null}
    sx={{
      color,
    }}
  />;
}

PlayCircleIcon.defaultProps = {
  color: colors.gray9,
  textSize: '14px',
};

export default PlayCircleIcon;
