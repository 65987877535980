import React from 'react';
import EmptyState from '../../utils/EmptyState';
import { t } from '../../i18n/t';
import ExternalLink from '../../utils/ExternalLink';
import DocumentLink from '../../utils/DocumentLink';

const DefectsEmptyState = () => {
  const stepActiveJira = () => (
    <>
      <div className="mb-3">{t('defect-empty-state#step-one#description')}</div>
      <a target="_blank" rel="noopener noreferrer" href={DocumentLink.ACTIVE_JIRA_INTEGRATION}>
        {t('learn_more')}
      </a>
    </>
  );

  const stepLinkTestResults = () => (
    <>
      <div className="mb-3">{t('defect-empty-state#step-two#description')}</div>
      <a target="_blank" rel="noopener noreferrer" href={DocumentLink.LINK_JIRA_TEST_RESULTS}>
        {t('learn_more')}
      </a>
    </>
  );

  const steps = [
    {
      label: <h4 className="mt-2">{t('defect-empty-state#step-one#title')}</h4>,
      content: stepActiveJira(),
    },
    {
      label: <h4 className="mt-2">{t('defect-empty-state#step-two#title')}</h4>,
      content: stepLinkTestResults(),
    }
  ];

  return (
    <EmptyState
      className="defect-empty-state"
      featureName={t('defect-report')}
      media={
        <div className="defect-empty-state-media">
          <iframe
            loading="lazy"
            title={t('defect-empty-state#media#title')}
            className="defect-empty-state-media-iframe"
            src={ExternalLink.DEFECT_EMPTY_STATE_MEDIA}
            frameBorder="0"
            allowFullScreen
          />
        </div>
    }
      steps={steps}
    />
  );
};

export default DefectsEmptyState;
