import { ListItemIcon, Typography } from '@mui/material';
import React, { Component } from 'react';
import MContext from '../../../models/MContext';
import ImageProfile from '../../avatar/ImageProfile';
import { buildSearchCondition } from '../../search/SearchUtils';
import SearchableFilter from './SearchableFilter';

class UserFilter extends Component {

  render() {
    const buildSearchConditions = (searchInput) => ([
      buildSearchCondition('Team.id', '=', MContext.teamId),
      buildSearchCondition('User.email', 'contains', searchInput || '')
    ]);

    const renderOption = (option) => (
      <>
        <ListItemIcon>
          <ImageProfile
            diameter={24}
            name={option.fullName}
            imgUrl={option.avatar}
          />
        </ListItemIcon>
        <Typography noWrap>{option.email}</Typography>
      </>
    );
    return (
      <SearchableFilter
        entityType="UserTeam"
        buildSearchConditions={buildSearchConditions}
        label="By"
        searchKey="id"
        placeHolder="Filter users"
        renderOption={renderOption}
        {...this.props}
      />
    );
  }
}

export default UserFilter;
