import React from 'react';
import { Button, Form, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { t } from '../../i18n/t';
import Notification from '../../utils/Notification';
import Services from '../../utils/Services';
import Input from '../Input';

class DeletePlanDialog extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.toggleNested = this.toggleNested.bind(this);
    this.saveDelete = this.saveDelete.bind(this);
    this.cancelDelete = this.cancelDelete.bind(this);
    this.handleIdInputChange = this.handleIdInputChange.bind(this);

    this.state = {
      modal: false,
      nestedModal: false,
    };
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  toggleNested() {
    this.setState((prevState) => ({
      nestedModal: !prevState.nestedModal,
    }));
  }

  saveDelete(e) {
    e.preventDefault();
    const { inputtedId } = this.state;
    const { id } = this.props;

    if (inputtedId.toString() !== id) {
      this.toggleNested();
    } else {
      Services.deleteRunConfiguration(id).then(() => {
        const { afterRemoval } = this.props;
        Notification.pushSuccess(t('plan#remove@success', { id }));
        if (afterRemoval) {
          afterRemoval();
        }
        this.toggle();
      }).catch(() => {
        this.toggle();
      });
    }
  }

  cancelDelete() {
    this.toggle();
  }

  handleIdInputChange(event) {
    this.setState({
      inputtedId: parseInt(event.target.value, 10),
    });
  }

  render() {
    const { id } = this.props;
    const { modal, nestedModal } = this.state;

    return (
      <div>
        <Modal isOpen={modal} toggle={this.toggle}>
          <Form data-trackid="delete-plan" onSubmit={this.saveDelete}>
            <ModalHeader>{t('delete')}</ModalHeader>
            <ModalBody>
              This Schedule and corresponding sessions will be deleted permanently. <br />
              Please type the Schedule ID (<strong>{id}</strong>) to confirm. <br /> <br />
              <Input required type="number" onChange={this.handleIdInputChange} />
              <Modal isOpen={nestedModal} toggle={this.toggleNested}>
                <ModalBody>{t('plan#remove@wrong_id')}</ModalBody>
                <ModalFooter>
                  <Button color="primary" onClick={this.toggleNested}>Ok</Button>{' '}
                </ModalFooter>
              </Modal>
            </ModalBody>
            <ModalFooter>
              <Button type="submit" color="danger">{t('delete')}</Button>
              <Button color="secondary" onClick={this.cancelDelete}>{t('cancel')}</Button>
            </ModalFooter>
          </Form>
        </Modal>
      </div>
    );
  }
}

export default DeletePlanDialog;
