/**
 * This file store all group events to tracking.
 *
 * You can access Tracking App to monitor or visit data.
 *
 * For each action, add prop: data-groupid to add groupid for each action.
 */
const GroupEvent = {
  ACCESS_CONFIGURATION: 'access-configuration',
  ACCESS_TEST_PLANNING: 'access-test-planning',
  ACCESS_TEST_MANAGEMENT: 'access-test-management',
  ACCESS_REPORT: 'access-report',
  ACCESS_REPORTS: 'access-reports',
  ACCESS_SETTINGS: 'access-settings',
  SCHEDULE_TEST_RUN: 'schedule-test-run',
  SCHEDULE_TEST_RUN_V1: 'schedule-test-run-v1',
  UPLOAD_REPORT: 'upload-report',
  ACCESS_DEMO_SITE: 'access-demo-site',
  ACCESS_PROFILE_MENU: 'access-profile-menu',
  TEST_SUITES_CONFIGURATION: 'test-suites-configuration',
  ACCESS_AUT_MANAGEMENT: 'access-aut-management',
  VIRTUAL_DATA_ANALYST: 'virtual-data-analyst',
  JIRA_DEFECTS: 'jira-defects',
};

export default GroupEvent;
