import React, { ReactNode, useEffect, useState } from 'react';
import { Stack } from '@mui/material';
import { isEmpty } from 'lodash';
import DrawerLayout from './DrawerLayout';
import { IconEmptyDataDrawer, IconShip } from '../images/CustomIcon';
import CollapseCardV2 from '../components/card/CollapseCardV2';
import MContext from '../models/MContext';
import Services from '../utils/Services';
import Spinner from '../components/Spinner';
import { PromptType, CollapseCardType } from '../utils/ConstantsType';
import DateHelper from '../utils/DateHelper';
import Time from '../utils/Moment';
import MFlags from '../models/MFlags';

interface DrawerAIResponseProps<T> {
  isOpen: boolean;
  closeDrawer: () => void;
  question: string;
  promptType: PromptType;
  title: string;
  buildData: (object: T, aiCompletions?: T | null) => CollapseCardType[],
  emptyComponent?: () => ReactNode;
  checkEmpty?: (object: T | null) => boolean;
  trigger?: number;
}

function DrawerAIResponse<T>(props: DrawerAIResponseProps<T>) {
  const { trigger, isOpen, closeDrawer, question, title, promptType, buildData, emptyComponent, checkEmpty } = props;
  const [object, setObject] = useState<T | null>(null);
  const [isReady, setIsReady] = useState(false);
  const [aiCompletions, setAICompletions] = useState<T | null>(null);

  const isEmptyObject = (object: T | null) => (checkEmpty ? checkEmpty(object) : isEmpty(object));

  function getAICompletions(object: T, promptType: PromptType) {
    Services.getAICompletions(object, promptType, MContext.projectId)
      .then((response) => {
        setAICompletions(response);
      });
  }

  const getAIStatistics = (promptType: PromptType) => {
    const currentTime = DateHelper.getLast7Days(Time.now());
    Services.getAIStatistics(
      promptType,
      MContext.projectId,
      Time.searchApiFormatWithSettingOrClientTz(currentTime.startTime),
      Time.searchApiFormatWithSettingOrClientTz(currentTime.endTime)
    )
      .then((response) => {
        setObject(response);
        setIsReady(true);

        if (isEmptyObject(response)) {
          return;
        }

        if (MFlags.genAISettingsAccountLevelEnabled && !response.enableAi) {
          return;
        }

        if (promptType !== 'COMMON_FAILURES') {
          getAICompletions(response, promptType);
        }
      });
  };

  useEffect(() => {
    if (trigger) {
      getAIStatistics(promptType);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger]);

  useEffect(() => {
    if (isOpen) {
      setIsReady(false);
      getAIStatistics(promptType);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, promptType]);

  const dataCategory = () => (
    <div>
      <IconShip /> {title}
    </div>
  );

  const dataTitleQuestion = () => question;

  const dataContent = () => (
    <Stack spacing={1} className="mb-5">
      {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
      <>{buildData(object!, aiCompletions).map((element) => (
        <CollapseCardV2
          title={element.title}
          header={element.header}
          content={element.content}
          disableCollapse={element.disableCollapse}
          key={element.id}
          tooltip={element.tooltip}
        />
      ))}
      </>
    </Stack>
  );

  const loadingComponent = () => (
    <div className="loading-drawer-layout">
      <Spinner />
    </div>
  );

  const emptyState = () => {
    if (emptyComponent) {
      return emptyComponent();
    }
    return (
      <div className="d-flex mt-3 align-items-center justify-content-center">
        <IconEmptyDataDrawer data-testid="icon-empty-data-drawer" />
      </div>
    );
  };

  const renderContent = () => {
    if (isReady) {
      if (isEmptyObject(object)) {
        return emptyState();
      } else {
        return dataContent();
      }
    }
    return loadingComponent();
  };

  return (
    <div>
      <DrawerLayout
        isOpen={isOpen}
        closeDrawer={closeDrawer}
        category={dataCategory()}
        title={dataTitleQuestion()}
        content={renderContent()}
      />
    </div>
  );
}

export default DrawerAIResponse;
