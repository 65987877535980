import Button from '@katalon-studio/katalon-ui/Button';
import { Paper, Popper, ThemeProvider, styled } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import userflow from 'userflow.js';
import { t } from '../../../i18n/t';
import { katalonui_theme } from '../../../katalonui-theme';
import { BetaChip } from '../../BetaChip';
import { GUIDE_ME_FLOW_ID } from './constants';
import CreateTestCaseDialog from './CreateTestCaseDialog';

export default function CreateTestCaseButton({
  sx,
  size,
  variant,
  color,
  startIcon,
  label,
  mainNav,
}) {
  const [isPopperShown, setIsPopperShown] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const anchorEl = React.useRef(null);

  const handleOpenDialog = () => {
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const handleShowPopper = () => {
    setIsPopperShown(true);
  };

  const handleHidePopper = () => {
    setIsPopperShown(false);
  };

  const handleClickGuideMe = () => {
    userflow.start(GUIDE_ME_FLOW_ID).catch((e) => {
      // eslint-disable-next-line no-console
      console.error(e);
    });
    handleHidePopper();
  };

  const renderButton = (dataTrackId) => (
    <Button
      ref={anchorEl}
      sx={sx}
      size={size}
      variant={variant}
      color={color}
      startIcon={startIcon}
      onClick={handleOpenDialog}
      id={`g5_${label}`}
      data-trackid={dataTrackId}
    >
      {t(label)}
    </Button>
  );

  return (
    <ThemeProvider theme={katalonui_theme}>
      {mainNav ? (
        <div
          onMouseEnter={handleShowPopper}
          onMouseLeave={handleHidePopper}
          style={{ display: 'flex' }}
        >
          {renderButton('cs_record_button_mainnav_clicked')}
          <PopperContainer open={isPopperShown} anchorEl={anchorEl.current} placement="bottom-end">
            <PopperContent>
              <PopperHeader>
                {t('cloud-studio#guide-header')} <StyledBetaChip />
              </PopperHeader>
              <PopperBody>{t('cloud-studio#guide-body')}</PopperBody>
              <PopperFooter>
                <Button
                  id="cs_guide_me_button"
                  onClick={handleClickGuideMe}
                  color="primary"
                  variant="text"
                  size="small"
                >
                  {t('cloud-studio#guide-btn')}
                </Button>
              </PopperFooter>
            </PopperContent>
          </PopperContainer>
        </div>
      ) : (
        renderButton('cs_create_tc_button_clicked')
      )}
      <CreateTestCaseDialog
        label={label}
        mainNav={mainNav}
        open={isDialogOpen}
        onClose={handleCloseDialog}
      />
    </ThemeProvider>
  );
}

CreateTestCaseButton.propTypes = {
  sx: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.func]),
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  variant: PropTypes.oneOf(['contained', 'outlined', 'text']),
  color: PropTypes.oneOf(['primary', 'secondary', 'error']),
  startIcon: PropTypes.node,
  label: PropTypes.string.isRequired,
  mainNav: PropTypes.bool,
};

CreateTestCaseButton.defaultProps = {
  sx: undefined,
  size: 'medium',
  variant: 'contained',
  color: 'primary',
  startIcon: undefined,
  mainNav: false,
};

const StyledBetaChip = styled(BetaChip)(({ theme }) => ({
  height: '22px',
  pointerEvents: 'none',
  marginLeft: theme.spacing(1),
}));

const PopperContainer = styled(Popper)({
  zIndex: 1001,
});

const PopperContent = styled(Paper)(({ theme }) => ({
  marginTop: theme.spacing(1.5),
  padding: theme.spacing(3),
  width: '360px',
  boxShadow:
    '0 3px 14px 2px rgba(153, 153, 153, 0.12), 0 8px 10px 1px rgba(153, 153, 153, 0.14), 0 5px 5px -3px rgba(153, 153, 153, 0.2)',
}));

const PopperHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  fontSize: theme.fontSize.medium,
  lineHeight: theme.lineHeight.medium,
  color: theme.palette.text.primary,
  fontWeight: 500,
}));

const PopperBody = styled('div')(({ theme }) => ({
  margin: theme.spacing(2, 0),
  fontSize: theme.fontSize.small,
  lineHeight: theme.lineHeight.medium,
  color: theme.palette.text.primary,
}));

const PopperFooter = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
});
