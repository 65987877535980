import colors from '../../scss/colors.scss';
import { JiraDefectChartStatus, TestRunStatus } from '../utils/Constants';

export const TestResultChartPayload = [
  {
    dataKey: TestRunStatus.FAILED,
    color: colors.newFailed,
  },
  {
    dataKey: TestRunStatus.PASSED,
    color: colors.newPassed,
  },
  {
    dataKey: TestRunStatus.ERROR,
    color: colors.newError,
  },
  {
    dataKey: TestRunStatus.INCOMPLETE,
    color: colors.newIncomplete,
  },
  {
    dataKey: TestRunStatus.SKIPPED,
    color: colors.skipped,
  },
];


export const DefectChartPayload = [
  {
    dataKey: JiraDefectChartStatus.CREATED,
    color: colors.issueCreated,
  },
  {
    dataKey: JiraDefectChartStatus.RESOLVED,
    color: colors.issueResolved,
  },
];
