import React from 'react';
import DecoratorConstants from '../../utils/DecoratorConstants';
import { buildSearchCondition } from '../../components/search/SearchUtils';
import MTableColumnDataMapping from '../../components/table/models/MTableColumnDataMapping';
import Statuses from '../../utils/Statuses';
import { t } from '../../i18n/t';
import DataTable from '../../components/table/DataTable';

export const RunningExecutionResultTab = ({ executionId }) => {
  const headers = [
    new MTableColumnDataMapping(
      t('status'),
      'status',
      DecoratorConstants.statusDecorator,
    ),
    new MTableColumnDataMapping('Name', 'name'),
  ];
  return (
    <>
      <DataTable
        title={t('failed_test_results')}
        entityType="RunningTestRun"
        defaultSearchConditions={[
          buildSearchCondition('Execution.id', '=', executionId),
          buildSearchCondition('status', '=', Statuses.FAILED.label),
        ]}
        columnMapping={headers}
      />
      <DataTable
        title={t('all_test_results')}
        entityType="RunningTestRun"
        defaultSearchConditions={[
          buildSearchCondition('Execution.id', '=', executionId),
        ]}
        columnMapping={headers}
      />
    </>
  );
};
