import React from 'react';
import {
  Button,
  Form,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import Services from '../../utils/Services';
import Input from '../Input';
import MContext from '../../models/MContext';
import Routes from '../../utils/Routes';
import Notification from '../../utils/Notification';

class DeleteTeamDialog extends React.Component {

  constructor(props) {
    super(props);

    this.team = MContext.team;
    this.organizationId = this.team.organizationId;

    this.toggle = this.toggle.bind(this);
    this.saveDelete = this.saveDelete.bind(this);
    this.cancelDelete = this.cancelDelete.bind(this);
    this.changeNameTeam = this.changeNameTeam.bind(this);

    this.state = {
      modal: false,
      userEnteredTeamName: '',
      disableSubmit: true,
    };
  }

  toggle() {
    const { modal } = this.state;
    this.setState({
      modal: !modal,
    });
  }

  changeNameTeam(event) {
    const userEnteredTeamName = event.target.value;
    let disableSubmit = true;
    if (userEnteredTeamName === this.team.name) {
      disableSubmit = false;
    }
    this.setState({
      userEnteredTeamName,
      disableSubmit
    });
  }

  saveDelete(e) {
    e.preventDefault();
    const { userEnteredTeamName } = this.state;
    if (userEnteredTeamName === this.team.name) {
      Services.deleteTeam(this.team.id).then(() => {
        Notification.pushSuccess('It may take some time for the team to be permanently removed.');
        setTimeout(() => {
          Routes.goToOrganizationHomePage(this.organizationId);
        }, 3000);
      });
    } else {
      window.alert('User has entered the wrong team name.');
    }
    this.toggle();
  }

  cancelDelete() {
    this.toggle();
  }

  render() {
    const { disableSubmit } = this.state;

    return (
      <div>
        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <Form data-trackid="delete-team" onSubmit={this.saveDelete}>
            <ModalHeader>Are you absolutely sure?</ModalHeader>
            <ModalBody>
              This action cannot be undone. <br />
              This will permanently delete the <strong>team {this.team.name}</strong>, and remove all associated projects.
              Please type <strong>{this.team.name}</strong> to confirm.
              <br /> <br />
              <Input required type="text" onChange={this.changeNameTeam} />
            </ModalBody>
            <ModalFooter>
              <Button type="submit" color="danger" disabled={disableSubmit}>I understand the consequences, delete this team</Button>
              <Button color="secondary" onClick={this.cancelDelete}>Cancel</Button>
            </ModalFooter>
          </Form>
        </Modal>
      </div>
    );
  }
}


export default DeleteTeamDialog;
