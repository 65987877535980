import React from 'react';
import StatusBarV2 from '../../components/chart/status-bar/StatusBar';
import { buildSearchCondition } from '../../components/search/SearchUtils';
import DataLoader from '../../components/table/DataLoader';
import { SearchEntity } from '../../utils/Constants';
import StatusConstants from '../../utils/Statuses';

const TestCaseBuildStatus = ({ data }) => {
  const statuses = {};
  let total = 0;
  data.forEach((item) => {
    statuses[item.status.toLowerCase()] = item.count;
    total += item.count;
  });

  const totalItem = {
    label: 'Test Cases',
    value: total,
    isTotal: true,
  };
  const statusBarItem = [
    {
      label: StatusConstants.PASSED.label,
      value: statuses[StatusConstants.PASSED.label.toLocaleLowerCase()] || 0,
      color: 'passed',
    },
    {
      label: StatusConstants.FAILED.label,
      value: statuses[StatusConstants.FAILED.label.toLocaleLowerCase()] || 0,
      color: 'failed',
    },
  ];

  return (
    <StatusBarV2
      dataInfo={[totalItem, ...statusBarItem]}
      dataChart={statusBarItem}
    />
  );
};


const BuildStatisticsStatus = ({ buildId }) => (
  <DataLoader
    render={(data) => <TestCaseBuildStatus data={data} />}
    entityType={SearchEntity.BuildStatistics}
    defaultSearchConditions={[
      buildSearchCondition('buildId', '=', buildId),
    ]}
    noCard
  />
);

export default BuildStatisticsStatus;
