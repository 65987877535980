import React from 'react';
import MConfigs from '../models/MConfigs';
import MContext from '../models/MContext';
import MAuth from '../models/MAuth';
import MFlags from '../models/MFlags';
import { analyticsPage, sendAnalyticEventForAction } from '../utils/SegmentAnalytics';
import WebSocket from '../services/WebSocket';
import MicroApp from '../components/MicroApp';
import Notification from '../utils/Notification';

function ExecutionEnvMicroApp() {
  const TestOpsContext = {
    MContext,
    MAuth,
    MFlags,
    MConfigs,
    TestOpsUtils: {
      sendAnalyticEventForAction,
      analyticsPage,
      WebSocket,
      Notification,
    }
  };

  return (
    <MicroApp
      id="katalon-testcloud"
      path={MConfigs.microAppExecutionEnvUrl}
      context={TestOpsContext}
    />
  );
}

export default ExecutionEnvMicroApp;
