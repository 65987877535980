import _ from 'lodash';
import React from 'react';
import { Button } from 'reactstrap';
import { t } from '../../i18n/t';
import { IconDownload } from '../../images/CustomIcon';
import { IconDelete } from '../../images/KitIcons';
import Arrays from '../../utils/Arrays';
import { OrganizationFeature } from '../../utils/Constants';
import DecoratorConstants from '../../utils/DecoratorConstants';
import RemoveLicenseDialog from '../dialog/RemoveLicenseDialog';
import { buildDefaultFilter } from '../search-query/FilterQueryHelper';
import DataTable from './DataTable';
import MTableColumnDataMapping from './models/MTableColumnDataMapping';
import { getLicenseFileName } from '../../utils/LicenseFileUtils';

class LicenseKeysDataTable extends React.Component {
  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
    this.removeLicenseDialogRef = React.createRef();
    this.state = {
      selectedLicense: null,
    };
  }

  refreshData() {
    this.tableRef.current.refreshData();
  }

  toggleCancelDialog(license) {
    this.setState(
      {
        selectedLicense: license
      },
      () => this.removeLicenseDialogRef.current.toggle()
    );
  }

  renderRemoveLicenseDialog() {
    const { selectedLicense } = this.state;
    return (
      <RemoveLicenseDialog
        ref={this.removeLicenseDialogRef}
        license={selectedLicense}
        afterRemove={() => this.refreshData()}
      />
    );
  }

  render() {
    const { showNumberUser, isOfflineLicense, showDescription } = this.props;

    const columnMapping = [
      new MTableColumnDataMapping(t('license_keys#machine_id'),
        'machineId', undefined, undefined, showDescription ? 'long-text-column' : 'short-text-column'),
      new MTableColumnDataMapping(t('table-header#product'),
        'feature', DecoratorConstants.featureDecorator),
      ...Arrays.insertIf(showDescription, new MTableColumnDataMapping(t('license_keys#description'),
        'description', undefined, undefined, 'short-text-column')),
      ...Arrays.insertIf(showNumberUser, new MTableColumnDataMapping(t('table-header#numberUser'),
        'numberUser')),
      new MTableColumnDataMapping(t('license_keys#created_at'),
        'createdAt', DecoratorConstants.timeDecorator, undefined, 'fit-content-column'),
      new MTableColumnDataMapping(
        isOfflineLicense ? t('license_keys#valid_until') : t('license_keys#expiration_date'),
        'expirationDate',
        DecoratorConstants.timeDecorator,
        undefined,
        'fit-content-column'),
      new MTableColumnDataMapping(t('license_keys#user'),
        'user.fullName', (name, row) => _.get(row, name)),
      new MTableColumnDataMapping(t('action'), undefined, (name, row) => {
        if (row.mode === 'COMMUTER') {
          const machineId = _.get(row, 'machineId');
          const id = _.get(row, 'id');
          const feature = _.get(row, 'feature');
          const file = new Blob([_.get(row, 'value')], { type: 'text/plain' });
          const downloadUrl = URL.createObjectURL(file);
          return (
            <>
              <Button
                className="btn-icon-only"
                color="link"
                title={t('download')}
                href={downloadUrl}
                download={getLicenseFileName(feature, machineId, id)}
              >
                <IconDownload />
              </Button>
            </>
          );
        } else if (row.type === 'ENTERPRISE') {
          return (
            <>
              <Button
                className="btn-icon-only"
                color="link"
                title={t('remove')}
                onClick={() => this.toggleCancelDialog(row)}
              >
                <IconDelete />
              </Button>
            </>
          );
        } else {
          return null;
        }
      }, true),
    ];
    const defaultSort = [
      'id,desc',
    ];

    const defaultFilter = buildDefaultFilter('machineId', '~', t('license_keys#machine_id'));

    const newProps = {
      entityType: 'LicenseKey',
      title: t('license_keys#license_keys'),
      defaultSort,
      columnMapping,
      defaultFilter,
      useSearchQuery: true,
      ...this.props,
    };
    return (
      <>
        <DataTable
          ref={this.tableRef}
          {...newProps}
        />
        {this.renderRemoveLicenseDialog()}
      </>
    );
  }
}

export default LicenseKeysDataTable;
