import React from 'react';
import { Button, Form, Input, FormGroup, Label } from 'reactstrap';
import PropTypes from 'prop-types';
import { brand, t } from '../../../i18n/t';
import Services from '../../../utils/Services';
import Routes from '../../../utils/Routes';
import { LogoTestOpsBig } from '../../../images/CustomIcon';
import Select from '../../../components/Select';
import { KatalonTestOpsPackage, TestOpsFeature } from '../../../utils/Constants';
import DocumentLink from '../../../utils/DocumentLink';
import { trackTrialRequestEvent, TRIAL_REQUEST_TRIGGER } from '../../../utils/EventTrackingHelper';
import MAuth from '../../../models/MAuth';
import MConfigs from '../../../models/MConfigs';
import Helper from '../../../utils/Helper';
import SuccessfullyAssignedDialog from '../../../components/dialog/SuccessfullyAssignedDialog';

const organizationPackageOptions = [
  {
    value: TestOpsFeature.TESTOPS_COMMUNITY,
    label: t('trialRequest#free'),
  },
  {
    value: TestOpsFeature.TESTOPS_BUSINESS,
    label: t('trialRequest#testOpsBusiness'),
  },
  {
    value: TestOpsFeature.TESTOPS_ENTERPRISE,
    label: t('trialRequest#testOpsEnterprise'),
  }
];

class CreateOrganization extends React.Component {

  constructor(props) {
    super(props);

    const subscribe = Helper.getParameterByName('subscribe');
    const feature = Helper.getParameterByName('package');

    this.state = {
      newOrgName: '',
      disabled: false,
      // current default package is business
      selectedPackage: organizationPackageOptions.find((x) => x.value === this.getDefaultTestOpsFeature(subscribe, feature)),
      showPopUp: false,
      organization: null,
    };

    this.isOnPremise = MConfigs.isOnPremise;
    this.onChange = this.onChange.bind(this);
    this.createOrganizationHandle = this.createOrganizationHandle.bind(this);
    this.selectTestOpsPackage = this.selectTestOpsPackage.bind(this);
    this.handlerAfterCreate = this.handlerAfterCreate.bind(this);
    this.handlerAfterCreate = this.handlerAfterCreate.bind(this);
    this.showPopupSuccessfully = this.showPopupSuccessfully.bind(this);
  }

  getDefaultTestOpsFeature(subscribe, feature) {
    if (subscribe === 'true') {
      return TestOpsFeature.TESTOPS_COMMUNITY;
    }

    if (subscribe === 'trial' && feature === KatalonTestOpsPackage.ENTERPRISE) {
      return TestOpsFeature.TESTOPS_ENTERPRISE;
    }

    return TestOpsFeature.TESTOPS_BUSINESS;
  }

  blockUI() {
    this.setState({ disabled: true });
  }

  handlerAfterCreate() {
    const { organization } = this.state;
    const { handleAfterCreate } = this.props;
    if (handleAfterCreate) {
      handleAfterCreate(organization);
    } else {
      Routes.goToOrganizationHomePage(organization.id);
    }
  }

  showPopupSuccessfully() {
    const { showPopUp, newOrgName, selectedPackage } = this.state;
    return (
      <SuccessfullyAssignedDialog
        showPopUp={showPopUp}
        orgName={newOrgName}
        plan={selectedPackage.label}
        handleOrganization={this.handlerAfterCreate}
      />
    );
  }

  createOrganizationHandle(event) {
    event.preventDefault();
    this.blockUI();
    const { newOrgName, selectedPackage } = this.state;
    Services.createOrganization(newOrgName)
      .then((organization) => {
        // Trigger create trial for new organization
        // In OP mode, hide trial
        if (!this.isOnPremise && selectedPackage.value !== TestOpsFeature.TESTOPS_COMMUNITY) {
          Services.submitOrganizationTrialRequest(organization.id, selectedPackage.value).then(() => {
            trackTrialRequestEvent(MAuth.email, organization.id, selectedPackage.value, TRIAL_REQUEST_TRIGGER.ORG_CREATION);
            this.setState({
              organization,
              showPopUp: true,
            });
          });
        } else {
          this.setState({
            organization,
          });
          this.handlerAfterCreate(organization);
        }
      });
  }

  selectTestOpsPackage(selectedPackage) {
    this.setState({ selectedPackage });
  }

  onChange(event) {
    this.setState({
      newOrgName: event.target.value
    });
  }

  render() {
    const { newOrgName, disabled, selectedPackage } = this.state;
    return (
      <>
        <Form
          className="on_boarding"
          data-trackid="create-organization"
          onSubmit={this.createOrganizationHandle}
        >
          <div className="on_boarding_svg">
            <LogoTestOpsBig />
          </div>
          <h1>
            Welcome to {brand}
          </h1>
          <div className="description">
            Create an organization to get started
          </div>
          <FormGroup>
            <Input
              autoFocus
              required
              type="text"
              value={newOrgName}
              onChange={this.onChange}
              disabled={disabled}
              placeholder="Organization name"
            />
          </FormGroup>
          {!this.isOnPremise &&
            <FormGroup>
              <Label className="text-center w-100 normal-label">
                Select a package for your organization.<br />
                You can request Business/Enterprise trial later in <b>Admin &gt; Trial Request</b>.
                See <a href={DocumentLink.KATALON_TESTOPS_PACKAGE_DETAILS} target="_blank" rel="noreferrer noopener">package details</a>
              </Label>
              <Select
                id="testops-trial-package"
                clearable={false}
                value={selectedPackage}
                options={organizationPackageOptions}
                onChange={this.selectTestOpsPackage}
              />
            </FormGroup>}
          <FormGroup>
            <Button
              id="create-organization"
              type="submit"
              color="primary"
              disabled={disabled}
              className="btn-block"
            >{t('create')}
            </Button>
          </FormGroup>
        </Form>
        {this.showPopupSuccessfully()}
      </>
    );
  }
}

CreateOrganization.propTypes = {
  handleAfterCreate: PropTypes.func,
};

export default CreateOrganization;
