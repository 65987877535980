import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { t } from '../../i18n/t';

const CollapsibleViewModeText = (props) => {
  const { currentValue, onClick } = props;
  const value = !currentValue ? t('none') : currentValue;
  const [showMore, setShowMore] = useState(false);
  const [displayShowMoreShowLess, setDisplayShowMoreShowLess] = useState(true);
  const viewModeRef = useRef(null);

  const renderShowMoreShowLess = () => {
    const element = viewModeRef.current;
    if (element) {
      setDisplayShowMoreShowLess(element.scrollHeight > element.offsetHeight);
    }
  };

  useEffect(() => {
    renderShowMoreShowLess();
  }, [currentValue]);

  return (
    <>
      <div
        className={
          classnames(
            'view-mode collapsible-3-lines',
            { 'none-text': !currentValue },
            { 'content-expanded': showMore },
            { 'content-collapsed': !showMore }
          )
        }
        onClick={onClick}
        ref={viewModeRef}
      >
        {value}
      </div>
      { displayShowMoreShowLess &&
        <a className="show-more-show-less" onClick={() => setShowMore(!showMore)}>
          {showMore ? t('show-less') : t('show-more')}
        </a>}
    </>
  );
};

CollapsibleViewModeText.propTypes = {
  currentValue: PropTypes.string,
  onClick: PropTypes.func
};

CollapsibleViewModeText.defaultProps = {
  currentValue: '',
  onClick: null
};

export default CollapsibleViewModeText;
