import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { DomEventHandlers } from '../utils/EventHandler';
import { KATALON_EVENTS } from '../utils/Constants';

function SPARoute() {
  const history = useHistory();
  useEffect(() => {
    DomEventHandlers.eventListener(KATALON_EVENTS.navigateAsSinglePageApplication, (e: any) => {
      if (e.detail.url) {
        history.push(e.detail.url);
      }
    });
  }, [history]);

  return (null);
}

export default SPARoute;
