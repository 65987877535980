import React, { useEffect } from 'react';
import { FormGroup, Label } from 'reactstrap';
import SelectGitTestProject from './../SelectGitTestProject';
import TestProjectCollaboratorWarning from './../TestProjectCollaboratorWarning';
import Services from '../../utils/Services';
import { t } from '../../i18n/t';

const SelectRepository = (props) => {
  const { testProject, setTestProject, setIsLoading, hasWritePermission, setHasWritePermission } = props;
  useEffect(() => {
    setIsLoading(true);
    if (testProject.id) {
      Services.hasWritePermission(testProject?.id).then((hasWritePermission) => {
        setHasWritePermission(hasWritePermission);
      }).finally(() => {
        setIsLoading(false);
      })
      .catch(() => {});
    }
  }, [testProject]);

  return (
    <FormGroup>
      <Label for={t('repository')}>{t('repository')}</Label>
      <SelectGitTestProject selectTestProject={setTestProject} testProject={testProject} />
      {!hasWritePermission &&
        <div className="mt-2">
          <TestProjectCollaboratorWarning {...props} />
        </div>}
    </FormGroup>
  );
};

export default SelectRepository;
