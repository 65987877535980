import React from 'react';
import PageComponent from '../components/PageComponent';
import ObjectSummary from '../components/summary/ObjectSummary';
import { t } from '../i18n/t';
import MContext from '../models/MContext';
import DefaultLayout from '../components/DefaultLayout';
import JobTable from '../components/table/JobTable';
import { buildFilter } from '../components/search-query/FilterQueryHelper';
import TestProjectFilter from '../components/search-query/filter/TestProjectFilter';
import StatusFilter, { StatusType } from '../components/search-query/filter/StatusFilter';
import TimeFilter from '../components/search-query/filter/TimeFilter';
import UserFilter from '../components/search-query/filter/UserFilter';
import SchedulerFilter from '../components/search-query/filter/SchedulerFilter';
import { buildSortQuery } from '../components/search-query/SortQueryHelper';

class Jobs extends PageComponent {
  constructor(props) {
    super(props);
    this.team = MContext.team;
    this.project = MContext.project;
    this.meta.id = 'page-jobs';
    this.meta.title = t('jobs');

    this.renderHeader = this.renderHeader.bind(this);
    this.renderBody = this.renderBody.bind(this);
  }

  renderObjectSummary() {
    const { project } = this;
    const urlParams = {
      jobs: t('jobs'),
      projectName: project.name,
    };
    return (
      <ObjectSummary params={urlParams} />
    );
  }

  renderHeader() {
    return (
      <>
        {this.renderObjectSummary()}
      </>
    );
  }

  renderBody() {
    const filterQuery = [
      buildFilter(TestProjectFilter, { id: 'TestProject.name' }),
      buildFilter(StatusFilter, { id: 'status', type: StatusType.JOB }),
      buildFilter(TimeFilter, { id: 'startTime', label: 'Started' }),
      buildFilter(UserFilter, { id: 'User.id' }),
      buildFilter(SchedulerFilter, { id: 'Scheduler.id' }),
    ];

    const sortQuery = [
      ...buildSortQuery('order', t('id')),
      ...buildSortQuery('startTime', t('table-header#startTime')),
    ];
    return (
      <JobTable
        filterQuery={filterQuery}
        sortQuery={sortQuery}
        useSearchQuery
        tableId="Jobs"
        defaultSort={['order,desc']}
      />
    );
  }

  render() {
    if (this.team) {
      return (
        <DefaultLayout
          renderHeader={this.renderHeader}
          renderBody={this.renderBody}
        />
      );
    }
    return null;
  }
}

export default Jobs;
