import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Form } from 'reactstrap';
import PropTypes from 'prop-types';

class FormDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.toggle = this.toggle.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  getInitialState() {
    return {
      modal: false,
    };
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  onSubmit(event) {
    event.preventDefault();
    const {
      handleApproval,
    } = this.props;

    if (handleApproval) {
      handleApproval();
    }

    this.toggle();
  }

  render() {
    const {
      modal,
    } = this.state;

    const {
      title,
      children,
      hideFooter,
      handleApproval,
      autoFocus,
      submitBtn,
      'data-trackid': dataTrackId,
      ...props
    } = this.props;

    return (
      <>
        <Modal isOpen={modal} toggle={this.toggle} {...props} autoFocus={autoFocus}>
          <Form data-trackid={dataTrackId} onSubmit={this.onSubmit}>
            <ModalHeader toggle={this.toggle}>{title}</ModalHeader>
            <ModalBody>
              {children}
            </ModalBody>
            {!hideFooter && (
              <ModalFooter>
                {submitBtn || <Button color="primary" type="submit">OK</Button>}
                <Button color="secondary" onClick={this.toggle}>Cancel</Button>
              </ModalFooter>
            )}
          </Form>
        </Modal>
      </>
    );
  }
}

FormDialog.propTypes = {
  handleApproval: PropTypes.func,
  title: PropTypes.any,
  hideFooter: PropTypes.bool,
  autoFocus: PropTypes.bool,
  submitBtn: PropTypes.object
};

FormDialog.defaultProps = {
  autoFocus: true
};

export default FormDialog;
