type CallbackItem = {
  attempt: number;
  lastUpdated: Date;
};

type CallbackOnFailure = (item: CallbackItem, error: unknown) => void;

type CallbackOnProgress = (item: CallbackItem) => void;

type CallbackOnSuccess = (item: CallbackItem) => void;

type PublishFunction = () => Promise<void>;

type QueueItem = {
  attempt: number;
  lastUpdated: Date;
  publish: PublishFunction;
};

export class TestCaseAutoUploadQueue {
  private isWorking = false;
  private items: QueueItem[];
  private onFailure?: CallbackOnFailure;
  private onProgress?: CallbackOnProgress;
  private onSuccess?: CallbackOnSuccess;

  constructor(options: {
    items?: QueueItem[];
    onFailure?: CallbackOnFailure;
    onProgress?: CallbackOnProgress;
    onSuccess?: CallbackOnSuccess;
  }) {
    this.items = options.items ?? [];
    this.onFailure = options.onFailure;
    this.onProgress = options.onProgress;
    this.onSuccess = options.onSuccess;
  }

  get hasItems() {
    return this.items.length > 0;
  }

  get isBusy() {
    return this.isWorking;
  }

  private async dequeue(): Promise<number> {
    if (this.isWorking) {
      // we will come back later
      return 0;
    }

    const { length } = this.items;
    if (length === 0) {
      // nothing to do here
      return 0;
    }

    // just take the last item and publish, pruning the rest
    // each test case has its own queue so we expect no data loss
    const item = this.items[length - 1];
    this.items.length = 0;

    const { attempt, lastUpdated, publish } = item;
    let count = 0;
    try {
      this.isWorking = true;
      this.onProgress?.call(item, { attempt, lastUpdated });
      await publish();
      this.onSuccess?.call(item, { attempt, lastUpdated });
      count++;
    } catch (error) {
      // we encountered some error, re-schedule for retry strategies
      this.items.unshift({ ...item, attempt: attempt + 1 });
      this.onFailure?.call(item, { attempt, lastUpdated }, error);
    } finally {
      this.isWorking = false;
    }

    if (count > 0) {
      // run recursively until the queue is empty
      count += await this.dequeue();
    }

    return count;
  }

  enqueue(lastUpdated: Date, publish: PublishFunction): Promise<number> {
    this.items.push({
      attempt: 1,
      lastUpdated,
      publish,
    });
    return new Promise((resolve) => { queueMicrotask(() => resolve(this.dequeue())); });
  }

  retry(): Promise<number> {
    return this.dequeue();
  }
}
