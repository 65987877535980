import React, { useState } from 'react';
import { Typography } from '@mui/material';
import { t } from '../../../i18n/t';
import MContext from '../../../models/MContext';
import { MAX_PAGE_SIZE, SearchEntity } from '../../../utils/Constants';
import { buildSearchCondition } from '../../search/SearchUtils';
import Services from '../../../utils/Services';
import DecoratorConstants from '../../../utils/DecoratorConstants';
import MultiSelectFilterComponent from './MultiSelectFilterComponent';

const JiraStatusFilterV2 = (props) => {

  const [multiOptions, setMultiOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isFirstOpen, setFirstOpen] = useState(true);

  const getOptions = () => {
    const projectId = MContext.projectId;
    setLoading(true);
    const params = {
      pagination: {
        page: 0,
        size: MAX_PAGE_SIZE,
        sorts: ['status,asc'],
      },
      conditions: [
        buildSearchCondition('Project.id', '=', projectId),
        buildSearchCondition('deleted', '=', false),
        buildSearchCondition('migrationError', 'is null', '')
      ],
      type: SearchEntity.ExternalDefect,
      groupBys: ['status', 'statusCategory']
    };

    Services.searchRecursively(0, params, []).then((res) => {
      const multiOptions = res.map(({ content }) => {
        const { status, statusCategory } = content;
        return { status, statusCategory };
      });
      setMultiOptions(multiOptions);
      setLoading(false);
      setFirstOpen(false);
    });
  };

  const onOpen = () => {
    if (isFirstOpen) {
      getOptions();
    }
  };

  const renderOption = (option) => (
    <Typography noWrap>{DecoratorConstants.defectStatusDecorator(option)}</Typography>);

  return (
    <MultiSelectFilterComponent
      multiOptions={multiOptions}
      renderOption={renderOption}
      loading={loading}
      onOpen={onOpen}
      searchKey="status"
      label={t('status')}
      {...props}
    />
  );

};

export default JiraStatusFilterV2;
