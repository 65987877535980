import React, { useState, useEffect } from 'react';
import { Button } from 'reactstrap';
import queryString from 'query-string';
import PropTypes from 'prop-types';
import { t } from '../../../i18n/t';
import UrlHelper from '../../../utils/UrlHelper';
import MFlags from '../../../models/MFlags';
import ScheduleTestRunDialogV1 from '../../../components/dialog/ScheduleTestRunDialogV1';
import PageButtonToolbar from '../../../components/PageButtonToolbar';

const { preventScheduleTestRunEnabled } = MFlags;


export default function ScheduleTestRunComponent(props) {
  const [isOpenScheduleTestRunDialog, setIsOpenScheduleTestRunDialog] = useState(() =>
    UrlHelper.getSearchParam('isOpenScheduleTestRunDialog') === 'true');
  const params = queryString.parse(props.location.search);

  useEffect(() => {
    if (!isOpenScheduleTestRunDialog) {
      UrlHelper.removeAllParams();
    }
  }, [isOpenScheduleTestRunDialog]);

  const handleOpenScheduleTestRunDialog = () => {
    setIsOpenScheduleTestRunDialog(true);
  };

  const handleCloseScheduleTestRunDialog = () => {
    setIsOpenScheduleTestRunDialog(false);
  };

  const renderButtonOpenScheduleTestRunDialog = () => (
    <Button
      id="open-schedule-dialog"
      title={t('create-plan')}
      color="primary"
      onClick={handleOpenScheduleTestRunDialog}
      disabled={preventScheduleTestRunEnabled}
      className={MFlags.testcloudExecutionEnvMicroAppEnabled ? 'test-cloud-micro-app-btn' : ''}
    >
      {t('create-plan')}
    </Button>
  );

  return (
    <>
      {!MFlags.testcloudExecutionEnvMicroAppEnabled && (
      <PageButtonToolbar>
        {renderButtonOpenScheduleTestRunDialog()}
      </PageButtonToolbar>
      )}

      {MFlags.testcloudExecutionEnvMicroAppEnabled && renderButtonOpenScheduleTestRunDialog()}

      {isOpenScheduleTestRunDialog && (
        <ScheduleTestRunDialogV1
          id="schedule-dialog"
          isOpen={isOpenScheduleTestRunDialog}
          handleClose={handleCloseScheduleTestRunDialog}
          params={params}
        />
      )}
    </>
  );
}

ScheduleTestRunComponent.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.any,
  }),
};
