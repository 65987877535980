

import _ from 'lodash';

class ObjectSummaryParametersBuilder {

  addExecution(execution) {
    this.executionId = execution.order;
    return this;
  }

  addProject(project) {
    this.projectId = project.id;
    this.project = project.name;
    return this;
  }

  addExecutionRequest(executionRequest) {
    this.executionRequestId = executionRequest.id;
    return this;
  }

  addExecutionTestSuite(executionTestSuite) {
    this.testSuiteName = executionTestSuite.testSuite.name;
    return this;
  }

  addTestResult(testResult) {
    this.testResultId = testResult.id;
    this.testCaseName = testResult.testCase.name;
    return this;
  }

  addTestCaseHistory(testCase) {
    this.testCaseHistory = testCase.name;
    this.testCaseType = testCase.type;
    return this;
  }

  addTestSuiteHistory(testSuite) {
    this.testSuiteHistory = testSuite.name;
    return this;
  }

  addTestObjectHistory(testObject) {
    this.testObjectHistory = testObject.name;
    return this;
  }

  toParams() {
    const params = {};
    return _.merge(params, {
      executionId: this.executionId,
      testResultId: this.testResultId,
      testCaseName: this.testCaseName,
      testCaseType: this.testCaseType,
      testSuiteName: this.testSuiteName,
      testCaseHistory: this.testCaseHistory,
      testSuiteHistory: this.testSuiteHistory,
      testObjectHistory: this.testObjectHistory,
      executionRequestId: this.executionRequestId,
    });
  }

  toExecutionTestResultDetailsParams(project, execution, testResult) {
    return this.addExecution(execution)
      .addTestResult(testResult)
      .toParams();
  }

  toExecutionRequestDetailParams(executionRequest) {
    return this.addExecutionRequest(executionRequest)
      .toParams();
  }

  toExecutionTestSuiteDetailsParams(project, execution, executionTestSuite) {
    return this.addExecution(execution)
      .addExecutionTestSuite(executionTestSuite)
      .toParams();
  }

  toTestCaseHistoryParams(project, testCase) {
    return this.addTestCaseHistory(testCase)
      .toParams();
  }

  toTestSuiteHistoryParams(project, testSuite) {
    return this.addTestSuiteHistory(testSuite)
      .toParams();
  }

  toTestObjectHistoryParams(project, testObject) {
    return this.addProject(project)
      .addTestObjectHistory(testObject)
      .toParams();
  }

}

export default ObjectSummaryParametersBuilder;
