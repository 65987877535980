import React, { useEffect, useState } from 'react';
import { DialogContent, DialogContentText, DialogActions, TableContainer } from '@mui/material';
import { Button, ButtonToolbar, Form, FormGroup, Label } from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import CloseableDialogComponent from '../../../../components/dialog/CloseableDialogComponent';
import { t } from '../../../../i18n/t';
import UploadMultipleImages from '../../../../components/upload-image/UploadMultipleImages';
import Notification from '../../../../utils/Notification';
import { getImageResolution } from '../../utils/ImageHelper';
import ImagePreviewCard from '../ImagePreviewCard';
import Services from '../../../../utils/Services';
import { AppHelper } from '../../../../utils/AppHelper';
import Input from '../../../../components/Input';
import Routes from '../../../../utils/Routes';
import { WAITING_TIME_FOR_TOAST } from '../../../../utils/Constants';
import BannerWarning from '../../../../components/banner/BannerWarning';
import { validateImage } from '../Utils';
import MContext from '../../../../models/MContext';
import { NUMBER_IMAGE_ALLOW_UPLOAD } from '../Constants';
import GroupEvent from '../../../../utils/track/GroupEvent';

export default function ConfigBaselineDialog({
  id,
  isOpen,
  handleClose,
  baselineCollectionGroupOrder,
  baselineCollectionName,
  title,
  projectId,
  onRefreshData
}) {

  const [uploadFilesInput, setUploadFilesInput] = useState(null);
  const [fileUploader, setFileUploader] = useState(null);
  const [files, setFiles] = useState(null);
  const [baselineCollectionGroupName, setBaselineCollectionGroupName] = useState(baselineCollectionName || null);
  const isCreateBaselineCollectionMode = !baselineCollectionGroupOrder;

  const [numberOfImagesError, setNumberOfImagesError] = useState(0);
  const [numberOfImagesNeedToRemove, setNumberOfImagesNeedToRemove] = useState(1);
  const [isDisabledUploader, setIsDisabledUploader] = useState(false);
  const [isDisableButtonSubmit, setIsDisableButtonSubmit] = useState(true);
  const uploadFiles = [];
  let qualifiedFiles = [];
  let filesSize = null;
  let batch = null;
  const { baselines } = MContext;

  useEffect(() => {
    if (files?.length > 0) {
      const isDisabledUploader = (files.length - numberOfImagesError) >= NUMBER_IMAGE_ALLOW_UPLOAD;
      const numberOfImagesRemove = files.length - numberOfImagesError - NUMBER_IMAGE_ALLOW_UPLOAD;
      if (isEmpty(baselineCollectionGroupName?.trim()) || numberOfImagesRemove > 0) {
        setNumberOfImagesNeedToRemove(numberOfImagesRemove + 1);
        setIsDisableButtonSubmit(true);
      } else {
        setNumberOfImagesNeedToRemove(1);
      }
      setIsDisabledUploader(isDisabledUploader);
    }
  }, [numberOfImagesError, files, baselineCollectionGroupName]);

  useEffect(() => {
    if (files?.length > 0) {
      const count = files.filter((obj) => !!obj.errorMessage).length;
      setNumberOfImagesError(count);
      if (count === files.length || files.length - count > NUMBER_IMAGE_ALLOW_UPLOAD) {
        setIsDisableButtonSubmit(true);
      } else {
        setIsDisableButtonSubmit(false);
      }
    } else {
      setIsDisableButtonSubmit(true);
      setNumberOfImagesError(0);
    }
  }, [files]);

  const handleRemoveImage = (uuid) => {
    const newFiles = files.filter((obj) => obj.uuid !== uuid);
    if (newFiles.length === 0) {
      fileUploader.clear();
    }
    setFiles(newFiles);
  };

  const onUploadError = (message) => {
    Notification.pushError(message);
  };

  const handleUploadBaselineImages = (uploadFiles, batch, projectId, baselineCollectionGroupOrder) => {
    const resource = {
      projectId,
      baselineCollectionId: baselineCollectionGroupOrder,
      uploadBatch: batch,
      baselineFiles: uploadFiles
    };
    Services.uploadBaselineImages(resource).then(({ version }) => {
      Notification.pushSuccess(t('visual-testing#baseline-collection#upload-image-success', { numberImagesUploaded: qualifiedFiles.length, baselineCollectionName, version }));
      handleClose();
      if (onRefreshData) {
        onRefreshData();
      }
    }).catch(() => {
      Notification.pushError(t('visual-testing#baseline-collection#upload-image-fail'), t('table-header#errorMessage'));
      handleClose();
    }).finally(() => {
      AppHelper.hideLoadingSpinner();
    });
  };

  const handleCreateBaselineCollection = (uploadFiles, batch, projectId) => {
    const resource = {
      baselineCollectionName: baselineCollectionGroupName,
      projectId,
      uploadBatch: batch,
      baselineFiles: uploadFiles
    };
    Services.createBaselineCollection(resource).then(({ baselineCollectionGroup: { name, order } }) => {
      Notification.pushSuccessPermanent(t('visual-testing#baseline-collection#create-baseline-collection-success', { baselineCollectionName: name, order }));
      setTimeout(() => {
        Routes.goToBaselineCollectionGroupDetail(order);
      }, WAITING_TIME_FOR_TOAST);
    }).finally(() => {
      AppHelper.hideLoadingSpinner();
    });
  };

  const onUploadFinish = (data, file) => {
    filesSize--;
    const uploadFile = {
      fileName: file.name,
      uploadedPath: data.path
    };
    uploadFiles.push(uploadFile);
    const isEnd = filesSize === 0;
    if (isEnd) {
      if (!isCreateBaselineCollectionMode) {
        handleUploadBaselineImages(uploadFiles, batch, projectId, baselineCollectionGroupOrder);
      } else {
        handleCreateBaselineCollection(uploadFiles, batch, projectId);
      }
    }
  };

  const onUploadStart = (file, next) => {
    for (let i = 0; i < qualifiedFiles.length; i++) {
      next(qualifiedFiles[i].item);
    }
    fileUploader.clear();
  };

  const handleUpload = (e) => {
    e.preventDefault();
    batch = `${Date.now()}+${uuidv4()}`;
    qualifiedFiles = files.filter((obj) => !obj.errorMessage);
    filesSize = qualifiedFiles.length;
    if (qualifiedFiles.length > 0 && !!fileUploader) {
      AppHelper.showLoadingSpinner();
      fileUploader.uploadFile();
    }
  };

  const checkExistFile = (file) => {
    if (files?.length > 0) {
      const objWithIdIndex = files.findIndex(
        (obj) => obj.name === file.name
          && obj.size === file.size
          && obj.resolution?.width === file.resolution?.width
          && obj.resolution?.height === file.resolution?.height
      );
      return objWithIdIndex >= 0;
    }
    return false;
  };

  const updateBaselineCollectionName = (e) => {
    setBaselineCollectionGroupName(e.target.value);
  };

  const checkValidateImage = (file) => validateImage(file, baselines);

  const onChange = () => {
    const promises = [];
    const listFilesTemp = uploadFilesInput?.files;
    const listFiles = [];
    Object.values(listFilesTemp).forEach((item) => {
      const objectUrl = URL.createObjectURL(item);
      const file = {
        uuid: uuidv4(),
        resolution: {},
        name: item.name,
        size: item.size,
        src: objectUrl,
        errorMessage: null,
        item
      };
      promises.push(
        getImageResolution(objectUrl).then((res) => {
          file.resolution = res;
        }).then(() => {
          if (!checkExistFile(file)) {
            file.errorMessage = checkValidateImage(file);
            listFiles.push(file);
          }
        })
      );
    });

    Promise.all(promises).then(() => {
      if (listFiles.length > 0) {
        if (files) {
          const temp = [...files, ...listFiles];
          setFiles(temp);
        } else {
          setFiles(listFiles);
        }
      }
    });
  };

  return (
    <CloseableDialogComponent
      id={id}
      isOpen={isOpen}
      handleClose={handleClose}
      title={title}
      maxWidth="md"
      trackCloseIcon={{
        'data-trackId': isCreateBaselineCollectionMode ? 'cancel-create-baseline-collection' : 'cancel-upload-baseline-image',
        'data-groupId': GroupEvent.ACCESS_REPORT,
      }}
    >
      <Form onSubmit={handleUpload}>
        <DialogContent className="upload-baseline-dialog pt-0">
          { isCreateBaselineCollectionMode &&
          <FormGroup className="baseline-collection-name">
            <Label for="baselineCollectionName">{t('visual-testing#baseline-collection#baseline-collection-name')}<span className="text-danger"> *</span></Label>
            <Input
              required
              id="baseline-collection-name"
              name="name"
              value={baselineCollectionGroupName}
              onChange={updateBaselineCollectionName}
              type="text"
              placeHolder={t('baseline-collection#place-holder-name')}
            />
          </FormGroup>}
          <DialogContentText>
            { isCreateBaselineCollectionMode && <Label for="baselines">{t('visual-testing#baseline-collection#upload-image-section-title')}</Label> }
            {
              isDisabledUploader
              && <BannerWarning title={t('visual-testing#baseline-collection#upload-image-banner-warning', {
                numberOfImages: numberOfImagesNeedToRemove,
                quota: NUMBER_IMAGE_ALLOW_UPLOAD
              })}
              />
            }
            <div className="d-flex">
              <div className="w-100">
                <UploadMultipleImages
                  onUploadError={onUploadError}
                  setUploadFilesInput={setUploadFilesInput}
                  setFileUploader={setFileUploader}
                  onUploadFinish={(data, file) => onUploadFinish(data, file)}
                  projectId={projectId}
                  onUploadStart={onUploadStart}
                  hasError={numberOfImagesError > 0}
                  isDisabledUploader={isDisabledUploader}
                  onChange={onChange}
                />
                {
                  numberOfImagesError > 0 &&
                  <div className="mt-1 danger-menu-items">{t('visual-testing#baseline-collection#number-images-error', { numberOfImagesError })}</div>
                }
              </div>
              {files?.length > 0 &&
                <TableContainer component="div" className="list-card-image">
                  {files.map((file) =>
                    <ImagePreviewCard
                      file={file}
                      onRemove={handleRemoveImage}
                      trackCloseIcon={{
                        'data-trackId': isCreateBaselineCollectionMode ? 'create-baseline-collection-remove-selected-image' : 'upload-baseline-image-remove-selected-image',
                        'data-groupId': GroupEvent.ACCESS_REPORT,
                      }}
                    />)}
                </TableContainer>}
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions className="justify-content-end">
          <ButtonToolbar>
            <Button
              id="cancel-upload-baseline-images"
              color="secondary"
              onClick={handleClose}
              data-trackid={isCreateBaselineCollectionMode ? 'cancel-create-baseline-collection' : 'cancel-upload-baseline-image'}
              data-groupid={GroupEvent.ACCESS_REPORT}
            >
              {t('cancel')}
            </Button>
            <Button
              id="submit-upload-baseline-images"
              type="submit"
              color="primary"
              disabled={isDisableButtonSubmit}
              data-trackid={isCreateBaselineCollectionMode ? 'confirm-create-baseline-collection' : 'confirm-upload-baseline-image'}
              data-groupid={GroupEvent.ACCESS_REPORT}
            >
              { isCreateBaselineCollectionMode ? t('baseline-collection#create') : t('test-project#upload') }
            </Button>
          </ButtonToolbar>
        </DialogActions>
      </Form>
    </CloseableDialogComponent>
  );
}

ConfigBaselineDialog.propTypes = {
  id: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  baselineCollectionGroupOrder: PropTypes.number,
  projectId: PropTypes.number.isRequired,
  baselineCollectionName: PropTypes.string,
  onRefreshData: PropTypes.func
};
