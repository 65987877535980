import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import PropTypes from 'prop-types';

class AlertDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.toggle = this.toggle.bind(this);
    this.handleOkButtonClicked = this.handleOkButtonClicked.bind(this);
  }

  getInitialState() {
    return {
      modal: false,
    };
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  handleOkButtonClicked() {
    const {
      handleApproval,
    } = this.props;

    if (handleApproval) {
      handleApproval();
    }

    this.toggle();
  }

  render() {
    const {
      modal,
    } = this.state;

    const {
      title,
      children,
      hideFooter,
      handleApproval,
      disableApproveButton,
      'data-trackid': dataTrackId,
      ...props
    } = this.props;

    return (
      <>
        <Modal isOpen={modal} toggle={this.toggle} {...props}>
          <ModalHeader toggle={this.toggle}>{title}</ModalHeader>
          <ModalBody>
            {children}
          </ModalBody>
          {!hideFooter && (
            <ModalFooter>
              <Button
                data-trackid={dataTrackId}
                color="primary"
                onClick={this.handleOkButtonClicked}
                disabled={disableApproveButton}
              >
                OK
              </Button>
              <Button color="secondary" onClick={this.toggle}>Cancel</Button>
            </ModalFooter>
          )}
        </Modal>
      </>
    );
  }
}

AlertDialog.propTypes = {
  handleApproval: PropTypes.func,
  title: PropTypes.any,
  hideFooter: PropTypes.bool,
  disableApproveButton: PropTypes.bool
};

AlertDialog.defaultProps = {
  disableApproveButton: false
};

export default AlertDialog;
