import React from 'react';
import _ from 'lodash';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';
import MTableColumnDataMapping from '../../components/table/models/MTableColumnDataMapping';
import { IconStop } from '../../images/KitIcons';
import { t } from '../../i18n/t';
import DecoratorConstants from '../../utils/DecoratorConstants';
import Services from '../../utils/Services';
import Notification from '../../utils/Notification';
import Routes from '../../utils/Routes';
import DataTable from './DataTable';

class JobTable extends React.Component {

  constructor(props) {
    super(props);
    this.jobList = null;
    this.cancelJob = this.cancelJob.bind(this);
    this.refreshTable = this.refreshTable.bind(this);
  }

  refreshTable() {
    this.jobList.refreshData();
  }

  cancelJob(jobId) {
    Services.cancelJob(jobId)
      .then(() => {
        Notification.pushSuccess(`Cancel job #${jobId} successfully.`);
        this.refreshTable();
      });
  }

  render() {
    const {
      defaultSearchConditions,
      autoUpdate,
      defaultSort,
      idTrackingProps
    } = this.props;

    const headers = [
      new MTableColumnDataMapping(
        t('status'),
        'status',
        DecoratorConstants.statusDecorator,
        undefined,
        'fit-content-column',
      ),
      new MTableColumnDataMapping(
        t('id'),
        'order',
        (name, row) => {
          const constructedLink = new Routes({
            projectId: row.project.id,
            runConfigurationId: row.runConfiguration.id,
            jobOrder: row.order,
            teamId: row.project.teamId,
          });
          return DecoratorConstants.idDecorator(name, row, constructedLink.job_link, null, null, idTrackingProps);
        },
        undefined,
        'fit-content-column',
      ),
      new MTableColumnDataMapping(
        t('test-project'),
        'testProject.name',
      ),
      new MTableColumnDataMapping(
        t('executions'),
        'execution.order',
        (name, row) => {
          const execution = row.execution;
          if (!_.isEmpty(execution)) {
            const project = execution.project;
            const constructedLink = new Routes({
              executionId: execution.order,
              projectId: execution.projectId,
              teamId: project.teamId,
            });
            return DecoratorConstants.idDecorator(name, row, constructedLink.execution_details_link);
          }
          return null;
        },
      ),
      new MTableColumnDataMapping(
        'Test Environment',
        'name',
        DecoratorConstants.agentDecorator,
      ),
      new MTableColumnDataMapping(
        'OS',
        'agent.os',
        (name, row) => {
          const osVersion = row.testCloudAgent ? _.get(row, 'testCloudAgent.os') : _.get(row, name);
          return DecoratorConstants.platform({ osVersion });
        },
      ),
      new MTableColumnDataMapping(
        t('job#trigger_by'),
        'scheduler.name',
        (name, row) => {
          if (row.triggerBy === 'SCHEDULE' && row.scheduler) {
            return t('testops-trigger');
          }
          if (row.triggerBy === 'MANUAL') {
            return row.user.fullName;
          }
          return row.triggerBy;
        }
      ),
      new MTableColumnDataMapping(
        t('job#start_time'),
        'startTime',
        DecoratorConstants.timeDecorator,
        undefined,
        'nowrap-column',
      ),
      new MTableColumnDataMapping(
        t('job#duration'),
        'duration',
        DecoratorConstants.durationDecorator,
        undefined,
        'nowrap-column',
      ),
      new MTableColumnDataMapping(
        t('job#action'),
        'id',
        (name, row) => {
          const {
            id,
            status,
          } = row;
          if (_.includes(['QUEUED', 'RUNNING'], status)) {
            return (
              <div>
                <Button
                  className="btn-icon-only"
                  data-trackid="cancel-job"
                  title={t('cancel')}
                  onClick={() => this.cancelJob(id)}
                  color="link"
                >
                  <IconStop />
                </Button>
              </div>);
          }
          return null;
        },
        true,
      ),
    ];
    return (
      <div>
        <DataTable
          entityType="Job"
          defaultSearchConditions={defaultSearchConditions}
          defaultSort={defaultSort}
          columnMapping={headers}
          autoUpdate={autoUpdate}
          pageSize={10}
          ref={(ref) => {
            this.jobList = ref;
          }}
          {...this.props}
        />
      </div>
    );
  }
}

JobTable.propTypes = {
  defaultSearchConditions: PropTypes.array,
  autoUpdate: PropTypes.bool,
  defaultSort: PropTypes.array,
  // Tracking props for column ID in table
  idTrackingProps: PropTypes.object
};

JobTable.defaultProps = {
  defaultSearchConditions: [],
  defaultSort: [],
  autoUpdate: false,
  idTrackingProps: null
};

export default JobTable;
