import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { t } from '../i18n/t';
import Time from '../utils/Moment';
import DatePicker from './DatePicker';

class DateRangePicker extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleDateRange(startTime, endTime) {
    return {
      startTime: startTime ? Time.startOf(startTime, 'day') : null,
      endTime: endTime ? Time.endOf(endTime, 'day') : null
    };
  }

  getInitialState() {
    const { value } = this.props;
    return this.handleDateRange(value?.startTime, value?.endTime);
  }

  buildObjectValue() {
    const { startTime, endTime } = this.state;
    return this.handleDateRange(startTime, endTime);
  }

  handleInputChange(name, value) {
    const isValidDate = value && value.isValid();
    this.setState({
      [name]: value
    }, () => {
      if (isValidDate) {
        const valueFilter = this.buildObjectValue();
        this.props.handleChange(valueFilter);
      }
    });
  }

  render() {
    const { startTime, endTime } = this.state;
    const { size, className } = this.props;
    const newProps = { size, className };
    return (
      <div className="d-flex flex-nowrap align-items-baseline">
        <div className="p-2">{t('from')}</div>
        <DatePicker
          id="startTime"
          name="startTime"
          inputFormat={Time.DATE_FORMAT}
          value={startTime}
          maxDate={endTime || Time.MAX_DATE}
          placeholder={t('search-bar#place-holder-datetime')}
          onChange={(value) => this.handleInputChange('startTime', value)}
          {...newProps}
        />
        <div className="p-2">{t('to')}</div>
        <DatePicker
          id="endTime"
          name="endTime"
          inputFormat={Time.DATE_FORMAT}
          value={endTime}
          minDate={startTime || Time.MIN_DATE}
          placeholder={t('search-bar#place-holder-datetime')}
          onChange={(value) => this.handleInputChange('endTime', value)}
          {...newProps}
        />
      </div>
    );
  }
}

DateRangePicker.propTypes = {
  /**
   * Default date value
   */
  value: PropTypes.object,
  /**
   * Handle Change function when user input date value
   */
  handleChange: PropTypes.func,
  /**
   * set size for TextField ex: "small", "medium"
   */
  size: PropTypes.string,
};

DateRangePicker.defaultProps = {
  value: { startTime: null, endTime: null },
  size: 'small',
  className: null
};

export default DateRangePicker;
