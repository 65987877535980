import axios from 'axios';

import MAuth from '../../models/MAuth';
import Notification from '../../utils/Notification';
import MContext from '../../models/MContext';

const request = axios.create({ baseURL: process.env.REACT_APP_TESTCLOUD_MOBILE_TESTING_SERVICE_URL });

const createRequestInterceptor = () => async (config) => {
  if (!MAuth.token) {
    await MAuth.fetchToken();
  }

  const { headers = '' } = config;
  const newHeaders = {
    ...headers,
    Authorization: `Bearer ${MAuth.token}`,
    'x-account-id': MContext.team?.organization?.accountUUID,
  };
  return {
    ...config,
    headers: newHeaders,
  };
};

/**
 * Notify error message.
 */
export function notifyError(error) {
  if (error.config.ignoreNotifyError === true) {
    return;
  }
  Notification.pushError(error.message);
}

/**
 * Check if the given status is user error or not.
 */
export function isUserError({ response }) {
  const status = response?.status || 0;
  return status >= 400 && status < 500;
}

/**
 * Check if the given status is server error or not.
 */
export function isServerError({ response }) {
  const status = response?.status || 0;
  return status >= 500;
}

/**
 * Check if the error is unauthorized error.
 */
export function isUnauthenticated(error) {
  const status = error && error.response && error.response.status;
  return status === 401;
}

/**
 * Check if the error is unauthorized error.
 */
export function isUnauthorized(error) {
  const status = error && error.response && error.response.status;
  return status === 403;
}

/**
 * Check if the error is response error or not.
 */
export function isResponseError({ response }) {
  return !!response;
}

export function createHandlerChain(handlers) {
  return function handlerChain(error) {
    const stack = [...handlers];
    function next() {
      if (stack.length === 0) {
        return;
      }
      const nextHandler = stack.pop();
      if (nextHandler) {
        nextHandler(error, next);
      }
    }
    next();
    return Promise.reject(error);
  };
}

export function serverErrorHandler(error, next) {
  if (isResponseError(error) && isServerError(error)) {
    notifyError(error);
  }
  next();
}

export function userErrorHandler(error, next) {
  if (!isResponseError(error) || !isUserError(error)) {
    return next();
  }
  notifyError(error);
  return next();
}

export async function unauthenticatedErrorHandler(error, next) {
  if (isUnauthenticated(error)) {
    const originRequest = error.config;
    if (!originRequest.isRetry) {
      originRequest.isRetry = true;
      await MAuth.fetchToken();
      axios.defaults.headers.common.Authorization = `Bearer ${MAuth.token}`;
      return request(originRequest);
    }
  }
  return next();
}

export function parseResultsHandler(response) {
  return response;
}

request.interceptors.request.use(createRequestInterceptor());
request.interceptors.response.use(
  parseResultsHandler,
  createHandlerChain([
    serverErrorHandler,
    userErrorHandler,
    unauthenticatedErrorHandler,
  ])
);

export default request;

