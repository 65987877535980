import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListSubheader from '@mui/material/ListSubheader';
import classnames from 'classnames';
import React from 'react';
import { Badge } from 'reactstrap';
import DecoratorConstants from '../../../utils/DecoratorConstants';
import CollapseButton from './CollapseButton';
import LogInfo from './LogInfo';

const LogChildren = ({ collapsible, isOpen, logRecord }) => {
  if (!collapsible) {
    return null;
  }

  return (
    <Collapse in={isOpen} timeout={0} unmountOnExit>
      <List dense>
        {logRecord.childLogs?.map((log) => (
          <LogTree
            key={`[${log.type}][${log.name}][${log.message.slice(0, 21)}]`}
            logRecord={log}
          />
        ))}
      </List>
    </Collapse>
  );
};

const LogHeader = ({ logRecord, statusValue, collapsible, isOpen, ...props }) => {
  if (!logRecord.name) {
    return null;
  }
  return (
    <ListSubheader
      className={classnames('log-header', 'py-1', 'px-0')}
      color="inherit"
      disableSticky
      {...props}
    >
      <CollapseButton collapsible={collapsible} isOpen={isOpen} />
      {DecoratorConstants.status(statusValue)}
      {logRecord.name}
      <NumAttachments numAttachments={logRecord.numAttachments} />
    </ListSubheader>
  );
};

const NumAttachments = ({ numAttachments }) => {
  if (!numAttachments) {
    return null;
  }
  return (
    <Badge className="mx-2 d-flex align-items-center" title={`${numAttachments} attachment(s)`}>
      <small>{numAttachments}</small>
    </Badge>
  );
};

const LogTree = ({ logRecord, expanded = false }) => {
  const [open, setOpen] = React.useState(expanded);
  const [collapsible, setCollapsible] = React.useState(true);

  const statusValue = logRecord.status?.toLowerCase() || '';
  const numChildren = logRecord.childLogs?.length;

  React.useEffect(() => {
    setCollapsible(numChildren > 0);
  }, [numChildren]);

  const handleClick = () => {
    if (!collapsible) {
      return;
    }
    setOpen(!open);
  };

  return (
    <>
      <ListItem dense disableGutters className={classnames('log-item')}>
        <div className="log-item__content">
          <LogHeader collapsible={collapsible} isOpen={open} logRecord={logRecord} statusValue={statusValue} onClick={handleClick} />
          <div className="d-flex">
            {open && <div onClick={handleClick} className={classnames('log-item__divider', `log-item__divider__${statusValue}`)} />}
            <div>
              {(logRecord.type === 'MESSAGE' || open) && <LogInfo logRecord={logRecord} />}
              <LogChildren collapsible={collapsible} isOpen={open} logRecord={logRecord} />
            </div>
          </div>
        </div>
      </ListItem>
    </>
  );
};

export default LogTree;
