import React, { useEffect, useState } from 'react';
import { DialogActions, DialogContent } from '@mui/material';
import { Button, ButtonToolbar, Form, FormGroup, Label } from 'reactstrap';
import PropTypes from 'prop-types';
import { t } from '../../../i18n/t';
import CloseableDialogComponent from '../../dialog/CloseableDialogComponent';
import Services from '../../../utils/Services';
import G5TestCaseEditView from './G5TestCaseEditView';
import G5TestCaseCreateView from './G5TestCaseCreateView';
import TestCaseObjHelper from '../../../utils/TestCaseObjHelper';
import Routes from '../../../utils/Routes';
import Notification from '../../../utils/Notification';
import Input from '../../Input';
import { sendAnalyticEventForAction } from '../../../utils/SegmentAnalytics';
import NotificationHandler from '../handler/NotificationHandler';
import { TestProjectType } from '../../../utils/Constants';
import TestProjectHelper from '../../../utils/TestProjectHelper';
import TestCaseService from '../service/TestCaseService';
import TestObjectPublisher from '../service/TestObjectPublisher';

function PublishTestCaseDialog({ isOpen, onClose, draftTestCaseId, isNewDraftTestCase }) {
  const [testCaseName, setTestCaseName] = useState();
  const [testCasePath, setTestCasePath] = useState('');
  const [commitMessage, setCommitMessage] = useState();
  const [testProject, setTestProject] = useState({});
  const [disablePublish, setDisablePublish] = useState(false);
  const [testCaseId, setTestCaseId] = useState(null);

  const isReady = testCaseName && testCasePath && testProject.id && (testProject.type === TestProjectType.CLOUD || commitMessage);

  const handleClose = () => {
    onClose();
  };

  const onChangeCommit = (event) => {
    setCommitMessage(event.target.value);
  };

  const deleteDraftTestCase = () => {
    TestCaseObjHelper.delete(draftTestCaseId);
  };

  const publishTestProjectErrorHandler = (message, label, jqXHR) => {
    NotificationHandler.publishTestProjectErrorHandler(message, jqXHR);
  };

  const handlePublish = async () => {
    const { testType, executionType, content, description } = await TestCaseObjHelper.get(draftTestCaseId);
    const publishTestCase = TestCaseService.buildPublishTestCase(testCaseId, testCaseName, testCasePath, testType, executionType, content, description);

    TestObjectPublisher.publishTestObject(testProject.id, [publishTestCase], [], commitMessage, publishTestProjectErrorHandler)
      .then((publishedTestProject) => {
        // tracking G5
        const data = {
          draft_test_case_id: draftTestCaseId,
          is_linked_to_published: true,
          created_at: new Date()
        };
        sendAnalyticEventForAction('cs_test_case_published', data);

        deleteDraftTestCase();
        const publishedTestCase = publishedTestProject.testCases[0];
        const testCaseFullPath = `${testProject.name}/${testCasePath}`;
        const successMessage = isNewDraftTestCase ? t('publish-test-case#successfully', { testCaseName, testCasePath: testCaseFullPath }) :
          t('publish-test-case#successfully-edit', { testCaseName, testCasePath: testCaseFullPath });
        Notification.pushSuccess(successMessage);
        setTimeout(() => {
          Routes.goToTestCaseLink(publishedTestCase.urlId);
        }, 2000);
      })
      .catch(() => {
        // ignore
      });
    const storageType = TestProjectHelper.getStorageType(testProject);
    sendAnalyticEventForAction('publish-cloud-studio-tests', { storage_type: storageType });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
      handlePublish();
  };

  const getData = async () => {
    const { name, path, scriptRepoId } = await TestCaseObjHelper.get(draftTestCaseId);
    setTestCaseName(name);

    if (!isNewDraftTestCase) {
      setCommitMessage(t('publish-test-case#default-commit-message', { testCaseName: name, testCasePath: path }));
      const testProject = await Services.getTestProjectById(scriptRepoId);
      setTestProject(testProject);
      setTestCasePath(path);
    }
  };

  const getTestCase = async () => {
    if (!isNewDraftTestCase) {
      const testCase = await Services.getValidTestCaseById(draftTestCaseId);
      setTestCaseId(testCase.id);
    }
  };

  useEffect(() => {
    getData();
    getTestCase();
  }, []);

  useEffect(() => {
    if (isNewDraftTestCase) {
      setTestCasePath('');
    }
  }, [testProject]);

  const renderCreateView = () => (
    <G5TestCaseCreateView
      testProject={testProject}
      selectTestProject={setTestProject}
      testCasePath={testCasePath}
      setTestCasePath={setTestCasePath}
      testCaseName={testCaseName}
      setTestCaseName={setTestCaseName}
      setDisablePublish={setDisablePublish}
    />
  );

  const renderEditView = () => (
    <G5TestCaseEditView
      testCaseName={testCaseName}
      testCasePath={testCasePath}
      testProjectName={testProject.name}
    />
  );

  return (
    <Form
      onSubmit={handleSubmit}
    >
      <CloseableDialogComponent
        isOpen={isOpen}
        handleClose={handleClose}
        maxWidth="sm"
        title={t('publish-test-case#title')}
        headerStyle="publish-dialog__header"
        disablePortal
      >
        <DialogContent>
          <div className={`publish-dialog__content ${isNewDraftTestCase && 'content-height'}`}>
            {isNewDraftTestCase ? renderCreateView() : renderEditView()}
            {testProject?.type === TestProjectType.GIT &&
            (
              <FormGroup>
                <Label for={t('commit-message')}>{t('commit-message')}</Label>
                <Input
                  required
                  className="publish-dialog__text"
                  type="text"
                  value={commitMessage}
                  onChange={onChangeCommit}
                  placeholder={t('commit-placeholder')}
                />
              </FormGroup>
            )}
          </div>
        </DialogContent>
        <DialogActions className="d-flex justify-content-end publish-dialog__footer">
          <ButtonToolbar>
            <Button
              color="secondary"
              onClick={handleClose}
            >
              {t('cancel')}
            </Button>
            <Button
              disabled={!isReady || disablePublish}
              type="submit"
              color="primary"
            >
              {t('publish')}
            </Button>
          </ButtonToolbar>
        </DialogActions>
      </CloseableDialogComponent>
    </Form>
  );
}

PublishTestCaseDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  draftTestCaseId: PropTypes.string.isRequired,
  isNewDraftTestCase: PropTypes.bool
};

PublishTestCaseDialog.defaultProps = {
  isNewDraftTestCase: true,
};

export default PublishTestCaseDialog;
