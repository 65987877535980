import React, { useEffect, useState } from 'react';
import { Stack } from '@mui/material';
import { t } from '../i18n/t';
import Services from '../utils/Services';
import { ExternalIssue } from '../models/model/ExternalIssue';
import DecoratorConstants from '../utils/DecoratorConstants';

interface SuggestedDefectsProps {
  testResultId: number;
}

function SuggestedDefects(props: SuggestedDefectsProps) {

  const [isLoading, setIsLoading] = useState(true);
  const [externalIssues, setExternalIssues] = useState<ExternalIssue[]>();

  const {
    testResultId
  } = props;

  useEffect(() => {
    setIsLoading(true);
    Services.getSuggestedDefects(testResultId)
      .then((response) => {
        setExternalIssues(response);
        setIsLoading(false);
      });
  }, [testResultId]);

  const defectDecorator = (externalIssue: ExternalIssue) => {
    const icon = DecoratorConstants.externalTypeDecorator(externalIssue);
    const id = DecoratorConstants.externalKeyDecorator(externalIssue);
    const summary = externalIssue?.summary;
    return (
      <Stack direction="row" spacing={1}>
        <span className="d-flex align-items-center icon-suggested-defects">{icon}</span>
        <span className="gray-defect-id">{id}</span>
        {DecoratorConstants.textEndLineTruncateDecorator(summary)}
      </Stack>
    );
  };

  const renderSuggestedDefects = () => {
    if (isLoading) {
      return (
        DecoratorConstants.circleLoading()
      );
    }

    if (externalIssues?.length === 0) {
      return (
        <div className="text-center mt-4 custom-fields__empty-message">{t('suggested-defects#no-data')}</div>
      );
    }

    return (
      <div>
        {externalIssues?.map((externalIssue) => (defectDecorator(externalIssue)))}
      </div>
    );
  };

  return renderSuggestedDefects();
}

export default SuggestedDefects;
