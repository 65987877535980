import React, { PureComponent } from 'react';

class ImageCanvas extends PureComponent {
  constructor(props) {
    super(props);
    this.canvasRef = React.createRef();
  }

  componentDidMount() {
    this.updateCanvas();
  }

  componentDidUpdate() {
    this.updateCanvas();
  }

  updateCanvas() {
    const { source, loadingState, setLoading } = this.props;
    const ctx = this.canvasRef.current.getContext('2d');

    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
    const image = new Image();
    image.src = source;
    image.onload = () => {
      const canvas = ctx.canvas;
      const ratio = canvas.width / image.width;
      canvas.height = image.height * ratio;
      ctx.drawImage(image, 0, 0, image.width, image.height,
        0, 0, image.width * ratio, image.height * ratio);
      if (setLoading) setLoading(loadingState, false);
    };
  }

  render() {
    const { source, ...rest } = this.props;
    return (
      <canvas ref={this.canvasRef} {...rest} />
    );
  }
}

export default ImageCanvas;
