import React from 'react';
import MContext from '../models/MContext';
import { FeatureNames, PlatformPlan } from '../utils/Constants';
import Helper from '../utils/Helper';
import TabComponent from './TabComponent';
import PageComponent from './PageComponent';
import UpgradeRequest from './subscription/UpgradeRequest';
import MFlags from './../models/MFlags';

export function withTabComponent(WrappedComponent, id, title) {
  class WithTabComponent extends TabComponent {
    constructor(props) {
      super(props);

      this.meta.id = typeof id === 'function' ? id(props) : id;
      this.meta.title = typeof title === 'function' ? title(props) : title;
    }

    render() {
      const { forwardedRef, ...rest } = this.props;
      return <WrappedComponent ref={forwardedRef} {...rest} />;
    }
  }

  WithTabComponent.displayName = `${WithTabComponent.name}(${Helper.getDisplayName(WrappedComponent)})`;
  return WithTabComponent;
}

export function withPageComponent(WrappedComponent, id, title) {
  class WithPageComponent extends PageComponent {
    constructor(props) {
      super(props);

      this.meta.id = typeof id === 'function' ? id(props) : id;
      this.meta.title = typeof title === 'function' ? title(props) : title;
    }

    render() {
      const { forwardedRef, ...rest } = this.props;
      return <WrappedComponent ref={forwardedRef} {...rest} />;
    }
  }

  WithPageComponent.displayName = `${WithPageComponent.name}(${Helper.getDisplayName(WrappedComponent)})`;
  return WithPageComponent;
}

export function withPremiumFeature(WrappedComponent, extraObject = { feature: null }) {

  const PremiumFeature = React.forwardRef((props, ref) => {
    // Allow feature for flag or key especially.
    const { allowBetaUserForFeatureRCAEnabled } = MFlags;
    if (allowBetaUserForFeatureRCAEnabled && extraObject.feature === FeatureNames.RCA_EXECUTION) {
      return <WrappedComponent ref={ref} {...props} />;
    }

    if (MContext.isRequireUpgrade) {
      return (
        <UpgradeRequest platformPlan={PlatformPlan.KATALON_PREMIUM} isUsePlatformMessage />
      );
    }
    return <WrappedComponent ref={ref} {...props} />;
  });

  PremiumFeature.displayName = `${PremiumFeature.name}(${Helper.getDisplayName(WrappedComponent)})`;
  return PremiumFeature;
}
