import Icon from '@katalon-studio/katalon-ui/Icon';
import React from 'react';
import colors from '../../../scss/colors.scss';

function ZipRepositoryIcon(props: {
  color: string,
  textSize: string,
}) {

  const { color, textSize } = props;

  return (
    <Icon
      type="fa-regular"
      name="fa-upload"
      textSize={textSize}
      sx={{
        color,
        width: textSize,
        height: textSize,
        display: 'flex',
        justifyContent: 'center',
      }}
    />
  );
}

ZipRepositoryIcon.defaultProps = {
  color: colors.gray9,
  textSize: '1rem',
};

export default ZipRepositoryIcon;
