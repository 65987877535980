import React from 'react';
import { IntegrationTypeEntity } from '../../../models/model/IntegrationTypeEntity';
import SlackSetting from './SlackSetting';
import KobitonSetting from './KobitonSetting';
import UploadKatalonReports from './UploadKatalonReport';
import FrameworksIntegration from './FrameworksIntegration';
import ReportUploader from './ReportUploader';
import JiraSetting from './JiraSetting';
import { IntegrationType } from '../../../utils/Constants';
import JenkinsIntegration from './JenkinsIntegration';
import EmailConfig from './EmailConfig';
import KatalonCliIntegration from './KatalonCliIntegration';

function IntegrationDetail(props: { integration: IntegrationTypeEntity }) {
  const { integration } = props;

  const renderComponent = () => {
    switch (integration) {
      case IntegrationType.SLACK_INTEGRATION:
        return <SlackSetting />;
      case IntegrationType.EMAIL_SETTINGS:
        return <EmailConfig />;
      case IntegrationType.KATALON_CLI:
        return <KatalonCliIntegration />;
      case IntegrationType.JIRA_INTEGRATION:
        return <JiraSetting />;
      case IntegrationType.KOBITON_INTEGRATION:
        return <KobitonSetting />;
      case IntegrationType.MOCHA:
      case IntegrationType.JEST:
      case IntegrationType.JASMINE:
      case IntegrationType.PYTEST:
      case IntegrationType.PROTRACTOR_JASMINE:
      case IntegrationType.PROTRACTOR_MOCHA:
      case IntegrationType.ROBOT:
        return <FrameworksIntegration integrationType={integration} />;
      case IntegrationType.CLI:
      case IntegrationType.DOCKER:
      case IntegrationType.GITHUB_ACTION:
      case IntegrationType.CIRCLE_CI:
      case IntegrationType.GITLAB_CI:
      case IntegrationType.AWS_CODEBUILD:
      case IntegrationType.AZURE_DEVOPS_PIPELINES:
        return <ReportUploader key={integration.value} integrationType={integration} />;
      case IntegrationType.JENKINS:
        return <JenkinsIntegration />;
      case IntegrationType.KATALON_STUDIO_INTEGRATION:
      default:
        return <UploadKatalonReports />;
    }
  };

  return (
    <div className="detail-page">
      <div className="title">
        <div>{integration.icon}</div>
        <div className="label">{integration.label}</div>
      </div>
      <div className="mt-4">
        {renderComponent()}
      </div>
    </div>
  );
}

export default IntegrationDetail;
