import _ from 'lodash';
import jquery from 'jquery';
import { v4 as uuidv4 } from 'uuid';
import Cookies from 'cookies-js';
import React from 'react';
import ReactDOM from 'react-dom';
import MAuth from '../models/MAuth';
import MFlags from '../models/MFlags';
import Notification from './Notification';
import Config from '../Config';
import Routes from './Routes';
import MContext from '../models/MContext';
import AppHelper from './AppHelper';
import MConfigs from '../models/MConfigs';
import OrganizationQuotaExceedDialog from '../components/dialog/OrganizationQuotaExceedDialog';
import { next } from './Count';
import { t } from '../i18n/t';

const SAFE_METHODS = ['get', 'head', 'trace', 'options'];

function updateProgressOnCompletion() {
  MContext.totalAPICalls--;
  if (MContext.totalAPICalls === 0) {
    jquery('#request-progress').css('display', 'none');
    AppHelper.hideLoadingSpinner();
  }
}

function renderExceedQuotaPopUp(message) {
  ReactDOM.render(
    <OrganizationQuotaExceedDialog
      key={next()}
      message={message}
    />,
    document.getElementById('katalon-testops-notification-popup'),
  );
}

const http = {

  get(url, data, headers, dataType, errorHandler) {
    return this.send('get', url, data, headers, dataType, undefined, errorHandler, null, true, MFlags.applyErrorHandlingUsingKatalonApiStandardEnabled);
  },

  put(url, data, headers = {}, useUrlEncoded = false, errorHandler, ignoreBlockUI) {
    return this.send('put', url, data, headers, null, useUrlEncoded, errorHandler, ignoreBlockUI);
  },

  post(url, data, headers, useUrlEncoded, errorHandler, ignoreBlockUI, katalonPlatform=true) {
    return this.send('post', url, data, headers, null, useUrlEncoded, errorHandler, ignoreBlockUI, katalonPlatform);
  },

  delete(url, data, errorHandler, ignoreBlockUI) {
    return this.send('delete', url, data, {}, null, false, errorHandler, ignoreBlockUI);
  },

  patch(url, data, errorHandler) {
    return this.send('patch', url, data, {}, null, false, errorHandler);
  },

  send(method, url, data, headers, dataType, useUrlEncoded, errorHandler, ignoreBlockUI, katalonPlatform = true, preventDuplicated = false) {
    const { isCustomBlockedUIEnabled } = MConfigs;

    if (MConfigs.isPre || MFlags.useIAMAccessTokenFE) {
      if (url.startsWith('/api/v1') || url.startsWith('/from-tc') || url.startsWith('/from-ks') || url.startsWith('/from-e2etg')) {
        const accessToken = Cookies.get('access_token');
        if (accessToken) {
          headers = _.merge({}, headers, {
            'Authorization': `Bearer ${accessToken}`,
          });
        }
      }
    }

    if (katalonPlatform) {
      const uuid = uuidv4();
      headers = _.merge({}, headers, {
        'X-Request-ID': uuid,
      });
      const organizationId = MContext.organizationId;
      if (organizationId) {
        headers = {
          ...headers,
          'x-organization-id': organizationId,
        };
      }
      const projectId = MContext.projectId;
      if (projectId) {
        headers = {
          ...headers,
          'x-project-id': projectId,
        };
      }
      if (!SAFE_METHODS.includes(method.toLowerCase()) && !url.startsWith(Config.apiWordpress)) {
        // prevent csrf attack
        headers = {
          ...headers,
          'X-XSRF-TOKEN': Cookies.get('XSRF-TOKEN'),
        };
      }
    }
    dataType = dataType || 'json';
    const contentType = useUrlEncoded ?
      'application/x-www-form-urlencoded; charset=UTF-8' :
      'application/json; charset=UTF-8';
    const promise = new Promise((resolve, reject) => {
      const configs = {
        contentType,
        dataType,
        url,
        headers,
        method,
        cache: false,
        beforeSend: () => {
          if (!ignoreBlockUI && !isCustomBlockedUIEnabled) {
            MContext.totalAPICalls++;
            if (MContext.totalAPICalls > 0) {
              jquery('#request-progress').css('display', 'block');
              if (method !== 'get' && !useUrlEncoded) {
                AppHelper.showLoadingSpinner();
              }
            }
          }
          return true;
        },
        success: (response) => {
          if (!ignoreBlockUI && !isCustomBlockedUIEnabled) {
            updateProgressOnCompletion();
          }
          resolve(response);
        },
        error: (jqXHR, textStatus, errorThrown) => {
          if (url === '/api/v1/users/me') {
            reject(jqXHR.responseJSON);
            return null;
          }

          const { isReadOnly } = MConfigs;
          if (isCustomBlockedUIEnabled) {
            AppHelper.closeCustomBlockedUI();
          } else if (!ignoreBlockUI) {
            updateProgressOnCompletion();
          }
          console.error('Failed to make request', method, url, textStatus, errorThrown);
          const response = jqXHR.responseJSON;
          if (jqXHR.status === 401 && !isReadOnly) {
            console.log("Katalon: Unauthorized when calling API");
            return MAuth.logout();
          }
          let message = '';
          let label = url;
          if (response) {
            if (response.message) {
              message = response.message;
            } else if (response.error_description) {
              message = response.error_description;
            } else {
              message = response.error;
            }
          } else if (errorThrown) {
            message = errorThrown;
          } else if (jqXHR.readyState === 0) {
            message = 'Cannot connect to Katalon server.';
            label = null;
          } else {
            message = textStatus;
          }
          if (message) {
            if (errorHandler) {
              errorHandler(message, label, jqXHR);
            } else {
              if (jqXHR.status === 402) {
                renderExceedQuotaPopUp(message);
              } else if (jqXHR.status === 401 || jqXHR.status === 403) {
                Routes.goToAccessDeniedPage();
              } else {
                if (message instanceof Error) {
                  Notification.pushError(message.message, label);
                } else {
                  if (isReadOnly) {
                    Notification.pushError(message, 'Error');
                  } else {
                    if (MFlags.applyErrorHandlingUsingKatalonApiStandardEnabled) {
                      if (response?.code === '02200') {
                        Notification.pushError(
                          t(`error-code-${response.code}-body`, { uri: label, message, defaultValue: message }),
                          t(`error-code-${response.code}-title`, { defaultValue: label }),
                          preventDuplicated,
                        );
                      } else {
                        Notification.pushError(
                          t(`error-code-${response.code}-body`, { defaultValue: message }),
                          t(`error-code-${response.code}-title`, { defaultValue: label }),
                          preventDuplicated,
                        );
                      }
                    } else {
                      Notification.pushError(message, label);
                    }
                  }
                }
              }
            }
          }

          let rejectData;
          if (response) {
            rejectData = response;
          } else {
            rejectData = message;
          }
          reject(rejectData);

          return null;
        },
      };
      if (data) {
        if (method === 'get' || useUrlEncoded) {
          configs.data = data;
          configs.traditional = true;
        } else {
          configs.data = JSON.stringify(data);
          configs.processData = false;
        }
      }
      jquery.ajax(configs);
    });
    return promise;
  },
};

export default http;
