import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { get, groupBy, map } from 'lodash';
import TimeSelect from '../../../components/select/TimeSelect';
import { t } from '../../../i18n/t';
import DataLoader from '../../../components/table/DataLoader';
import { next } from '../../../utils/Count';
import { ProjectStatisticsType, SearchEntity } from '../../../utils/Constants';
import { buildSearchFunction } from '../../../components/search/SearchUtils';
import Time from '../../../utils/Moment';
import ExecutionTrendTestRunChart from './ExecutionTrendTestRunChart';
import ReportHelper from '../../../utils/ReportHelper';
import TooltipComponent from '../../../components/TooltipComponent';
import NormalCard from '../../../components/card/NormalCard';
import MFlags from '../../../models/MFlags';

const ExecutionTrendTestRun = ({
  defaultSearchConditions,
  dataLoaderRef,
  filterDateTimeValue,
  headerTooltip,
  defaultGroupByTime,
  headerTitle,
  isDashboard }) => {

  const [timeFilter, setTimeFilter] = useState(defaultGroupByTime);

  const parseAggregationData = (aggData) => aggData.map((item) => item.content);

  const handleSelectChange = (event, option) => setTimeFilter(option.value);

  const renderHeader = () => (
    <div className="overview-header-sub-title">
      {headerTitle}
      <TooltipComponent text={headerTooltip} placement="bottom-end" arrow />
    </div>
  );

  const renderCustomControl = () => (
    <TimeSelect id="time-filter" className="overview-filter mr-4" size="small" defaultValue={timeFilter} onSelectChange={handleSelectChange} />
  );

  const unit = ReportHelper.getStatisticsType(timeFilter);

  const convertDataForChart = (data) => {
    const parsedData = parseAggregationData(data);
    const groupByData = groupBy(parsedData, 'time');
    const { startTime, endTime } = filterDateTimeValue;
    // Convert startTime from filter to get the first day by unit(day, week, month)
    const startTimeByUnit = Time.startOf(startTime, unit);
    const timeRange = Time.timeBetween(startTimeByUnit, endTime, `${unit}s`, Time.LOCAL_DATE_FORMAT);

    return map(timeRange, (time) => {
      const newValue = {
        time,
        total: 0,
        PASSED: 0,
        FAILED: 0,
        totalDuration: 0,
      };
      if (groupByData[time]) {
        groupByData[time].forEach((value) => {
          newValue[value.status] = get(newValue, value.status, 0) + get(value, 'total');
          newValue.total += newValue[value.status];
          newValue.totalDuration += value.totalDuration;
        });
      }
      return newValue;
    });
  };

  const renderChart = (data) => {
    const isChartNotEmpty = data.length > 0;
    const convertedData = isChartNotEmpty ? convertDataForChart(data) : data;

    let totalTestRuns = 0;
    if (isDashboard) {
      totalTestRuns = data.map((item) => item.content.total).reduce((prev, curr) => prev + curr, 0);
    }

    return (
      <>
        {isDashboard && <div className="statistics-title">{totalTestRuns}</div>}
        <ExecutionTrendTestRunChart
          data={convertedData}
        />
      </>
    );
  };

  const isFilterByProfile = () => !!defaultSearchConditions.find((item) => item.key === 'ExecutionTestResult.profile');

  const renderBody = () => {
    const param = isFilterByProfile() ? ['ExecutionTestResult.duration'] : ['duration'];
    return (
      <DataLoader
        title={renderHeader()}
        key={next()}
        ref={dataLoaderRef}
        entityType={SearchEntity.Execution}
        defaultSearchConditions={defaultSearchConditions}
        defaultSearchFunctions={[
          buildSearchFunction('time', `group_by_${unit}`, ['startTime']),
          buildSearchFunction('total', 'count_distinct', ['id']),
          buildSearchFunction('totalDuration', 'SUM', param),
        ]}
        defaultSort={['time,desc']}
        groupBys={[
          'status'
        ]}
        render={renderChart}
        cardClassName={isDashboard ? 'project-dashboard-card-border pb-0 shadow-none' : 'overview-card-border shadow-none'}
        useCache={!MFlags.testPerformanceForReportAndAnalyticsEnabled}
        useRefreshButton={false}
        useCollapseButton={false}
        headerComponent={NormalCard}
        bodyComponent={NormalCard}
        customControl={renderCustomControl()}
        showEmptyMessage
        pageSize={300}
      />
    );
  };

  return (
    <>
      {renderBody()}
    </>
  );
};

ExecutionTrendTestRun.propTypes = {
  defaultSearchConditions: PropTypes.array,
  filterDateTimeValue: PropTypes.object,
  /**
   * This param define value default for tooltip of header.
   */
  headerTooltip: PropTypes.string,
  /**
   * This param define value default for title of header.
   */
  headerTitle: PropTypes.string,
  /**
   * This param define value default for time filter.
   */
  defaultGroupByTime: PropTypes.string,
  /**
   * This is the param indicating if this is a dashboard page.
   */
  isDashboard: PropTypes.bool,
};

ExecutionTrendTestRun.defaultProps = {
  defaultSearchConditions: [],
  filterDateTimeValue: {},
  headerTooltip: t('overview-report#execution-trend-test-run#tooltip'),
  defaultGroupByTime: ProjectStatisticsType.MONTHLY,
  headerTitle: t('execution-trend-by-test-run'),
  isDashboard: false
};


export default ExecutionTrendTestRun;
