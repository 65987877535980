import React from 'react';
import { Icon403, Icon404 } from '../images/CustomIcon';

export const notificationIds = Object.freeze({
  NO_PERMISSION: 'no-permissions',
  NO_PROJECTS: 'no-projects',
  NOT_FOUND: 'not-found'
});

const notificationContents = Object.freeze({
  [notificationIds.NO_PERMISSION]: {
    pageTitle: '403 - Access Denied',
    messageTitle: 'ACCESS DENIED!',
    messageContent: 'You do not have permission to access this page.',
    icon: <Icon403 />
  },
  [notificationIds.NO_PROJECTS]: {
    pageTitle: '404 - Projects Not Found!',
    messageTitle: '404 - NOT FOUND!',
    messageContent: 'Please contact Project Owner/Admin for invitation to an existing Project.'
  },
  [notificationIds.NOT_FOUND]: {
    pageTitle: '404 - Not Found',
    messageTitle: 'PAGE NOT FOUND!',
    messageContent: 'The page you are looking for does not exist. You can click the button below to go back to the homepage.',
    icon: <Icon404 />
  },
});

export const getNotificationMessage = (id) => (notificationContents[id]);
