import PropTypes from 'prop-types';
import React from 'react';
import { FormGroup, Progress } from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';
import FileType from 'file-type/browser';
import MContext from '../../../models/MContext';
import MFlags from '../../../models/MFlags';
import Notification from '../../../utils/Notification';
import Services from '../../../utils/Services';
import Uploader from '../../../components/Uploader';
import { t } from '../../../i18n/t';
import { FileType as fileType, WAITING_TIME_FOR_TOAST } from '../../../utils/Constants';

class TestProjectUploadField extends React.Component {
  constructor(props) {
    super(props);
    this.projectId = MContext.projectId;
    this.teamId = MContext.teamId;
    this.testProjectId = MContext.testProjectId;

    this.fileUploader = null;
    this.uploadFilesInput = null;
    this.state = this.getInitialState();

    this.handleUpload = this.handleUpload.bind(this);
    this.createTestProject = this.createTestProject.bind(this);
    this.updateTestProject = this.updateTestProject.bind(this);
    this.onUploadProgress = this.onUploadProgress.bind(this);
    this.onUploadError = this.onUploadError.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  getInitialState() {
    return {
      uploadProgress: '0',
      projectId: this.projectId,
      testProjectId: this.testProjectId,
    };
  }

  renderProgressBar() {
    const { uploadProgress } = this.state;
    if (uploadProgress === '0') {
      return null;
    }
    return (
      <FormGroup>
        <Progress value={uploadProgress} />
      </FormGroup>
    );
  }

  /**
   * @param event
   * Because Uploader only accept 1 file so only need to check 1 file.
   */
  checkFileType(event) {
    const files = event.target.files;
    FileType.fromBlob(files[0]).then((res) => {
      if (res?.mime !== fileType.ZIP) {
        Notification.pushError(t('test-project#file-non-zip'));
        document.getElementById('upload-file').value = null;
      }
    });
  }

  onChange(event) {
    if (this.props.onChange) {
      this.props.onChange(event);
    }
    this.checkFileType(event);
  }

  render() {
    const { projectId } = this.state;
    const { disabled } = this.props;

    return (
      <>
        {this.renderProgressBar()}
        <Uploader
          id='upload-file'
          onChange={this.onChange}
          signingUrl='/api/v1/files/upload-url'
          signingUrlMethod='GET'
          contentDisposition='attachment'
          onFinish={(data, file) => this.onUploadFinish(data, file)}
          onError={this.onUploadError}
          onProgress={this.onUploadProgress}
          signingUrlQueryParams={{
            projectId,
            'from-react-s3': true,
            'fileType': 'REPOSITORY',
          }}
          signingUrlWithCredentials
          uploadRequestHeaders={{ 'content-type': 'application/octet-stream' }}
          autoUpload={false}
          ref={(uploader) => {
            this.fileUploader = uploader;
          }}
          inputRef={(cmp) => {
            this.uploadFilesInput = cmp;
          }}
          accept='zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed'
          className='form-control-custom'
          disabled={disabled}
        />
      </>
    );
  }

  uploadProgress(percent) {
    this.setState({ uploadProgress: percent });
  }

  onUploadProgress(percent, message) {
    const { onUploadProgressHook } = this.props;

    if (onUploadProgressHook) {
      onUploadProgressHook(percent, message);
    }

    this.uploadProgress(percent);
  }

  createTestProject(data, file) {
    this.fileLength--;
    const batch = this.batch;
    const {
      onUploadFinishHook,
      onUploadErrorHook,
      testProjectName,
      testProjectDescription,
      isDefaultTestProject,
    } = this.props;

    Services.uploadTestProject({
      name: testProjectName,
      description: testProjectDescription,
      defaultTestProject: isDefaultTestProject,
      projectId: this.projectId,
      teamId: this.teamId,
      batch,
      folderPath: '',
      fileName: file.name,
      uploadedPath: data.path,
    }).then((testProject) => {
      this.fileUploader.clear();
      this.uploadProgress('0');
      this.handleNotificationMsg();
      if (onUploadFinishHook) {
        onUploadFinishHook(testProject);
      }
    }).catch((e) => {
      if (onUploadErrorHook) {
        onUploadErrorHook(e);
      }
    });
  }

  updateTestProject(data, file) {
    this.fileLength--;
    const batch = this.batch;
    const {
      onUploadFinishHook,
      onUploadErrorHook,
    } = this.props;
    const { testProjectId } = this.state;

    Services.updateTestProjectPackage(testProjectId, {
      batch,
      folderPath: '',
      fileName: file.name,
      uploadedPath: data.path,
    }).then((testProject) => {
      this.fileUploader.clear();
      this.uploadProgress('0');
      this.handleNotificationMsg();
      if (onUploadFinishHook) {
        onUploadFinishHook(testProject);
      }
    }).catch((e) => {
      if (onUploadErrorHook) {
        onUploadErrorHook();
      }

      Notification.pushError(e, 'Cannot upload Script Repository.');
    });
  }

  handleNotificationMsg() {
    Notification.pushSuccessPermanent(t('update_test_project_success'));
  }

  onUploadFinish(data, file) {
    const { testProjectId } = this.state;
    if (!testProjectId) {
      this.createTestProject(data, file);
    } else {
      this.updateTestProject(data, file);
    }
  }

  onUploadError(message) {
    const { onUploadErrorHook } = this.props;

    if (onUploadErrorHook) {
      onUploadErrorHook();
    }

    Notification.pushError(`Upload error: ${message}`);
  }

  handleUpload() {
    this.fileLength = this.uploadFilesInput.files.length;
    this.batch = `${Date.now()}+${uuidv4()}`;
    this.fileUploader.uploadFile();
  }
}

TestProjectUploadField.propTypes = {
  onUploadProgressHook: PropTypes.func,
  onUploadFinishHook: PropTypes.func,
  onUploadErrorHook: PropTypes.func,
  testProjectName: PropTypes.string,
  testProjectDescription: PropTypes.string,
  isDefaultTestProject: PropTypes.bool,
  onChange: PropTypes.func,
};

export default TestProjectUploadField;
