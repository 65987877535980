import React from 'react';
import JiraIntegrationFeatureHighlight from '../../components/external_issue/JiraIntegrationFeatureHighlight';
import { withTabComponent } from '../../components/HigherOrderComponent';
import { useExternalConnection } from '../../components/Hooks';
import { buildSearchCondition } from '../../components/search/SearchUtils';
import { ObjectType } from '../../utils/Constants';
import ExternalIssueList from '../../components/external_issue/ExternalIssueList';
import ExternalIssueDataTable from '../../components/external_issue/ExternalIssueDataTable';
import { t } from '../../i18n/t';
import MFlags from '../../models/MFlags';

function DefectTab({ executionId, projectId }) {
  const connection = useExternalConnection(projectId);
  const active = connection && connection.active;

  if (connection === null) {
    return null;
  }

  const renderExternalIssues = () => {
    if (MFlags.modifyDefectListInTestResultDetailEnabled) {
      return (
        <ExternalIssueList
          emptyStateText={t('empty-state-defect#test-run')}
          isTestRunPage
          projectId={projectId}
          objectType={ObjectType.EXECUTION_TEST_RESULT}
          executionId={executionId}
          defaultSearchConditions={[
            buildSearchCondition('Execution.id', '=', executionId),
          ]}
        />
      );
    }
    return (
      <ExternalIssueDataTable
        projectId={projectId}
        objectType={ObjectType.EXECUTION_TEST_RESULT}
        defaultSearchConditions={[
          buildSearchCondition('Execution.id', '=', executionId),
        ]}
      />
    );
  };

  return (
    <>
      {!active && <JiraIntegrationFeatureHighlight />}
      {active && renderExternalIssues()}
    </>
  );
}

export default withTabComponent(DefectTab, 'page-execution-details-defect', document.title);
