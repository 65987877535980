

import React from 'react';
import PropTypes from 'prop-types';
import { Line } from 'react-chartjs-2';
import _ from 'lodash';

class LineChart extends React.Component {

  // componentWillMount() {
  //   window.Chart.plugins.register({
  //     afterEvent(chartInstance, chartEvent) {
  //       const legend = chartInstance.legend;
  //       const canvas = chartInstance.chart.canvas;

  //       // If mouse is over the legend, change cursor style to pointer, else don't show it
  //       const x = chartEvent.x;
  //       const y = chartEvent.y;
  //       let cursorStyle = 'default';

  //       if (x <= legend.right && x >= legend.left &&
  //                   y <= legend.bottom && y >= legend.top) {
  //         for (let i = 0; i < legend.legendHitBoxes.length; ++i) {
  //           const box = legend.legendHitBoxes[i];
  //           if (x <= box.left + box.width && x >= box.left &&
  //                           y <= box.top + box.height && y >= box.top) {
  //             cursorStyle = 'pointer';
  //             break;
  //           }
  //         }
  //       }

  //       canvas.style.cursor = cursorStyle;
  //     },
  //   });
  // }

  transformToChartData(
    data,
    sourceFieldName,
    labelsMapping,
    datasetsMapping,
    datasetOptions,
    stacked,
  ) {
    const labels = [];
    const datasets = [];

    const dataGroups = {};
    datasetsMapping.forEach((mChartDataMapping) => {
      dataGroups[mChartDataMapping.label] = [];
    });

    data.forEach((item) => {
      labels.push(labelsMapping.decorate(labelsMapping.fieldName, item));
      datasetsMapping.forEach((mChartDataMapping) => {
        const group = dataGroups[mChartDataMapping.label];
        group.push(mChartDataMapping.decorate(mChartDataMapping.fieldName, item));
      });
    });

    // fill-in the datasets
    datasetsMapping.forEach((mChartDataMapping, i) => {
      let datasetsItem = this.dataset(
        mChartDataMapping.label,
        dataGroups[mChartDataMapping.label],
        mChartDataMapping.rgbColor,
      );
      if (datasetOptions) {
        datasetsItem = _.merge({}, datasetsItem, datasetOptions[i]);
      }
      if (stacked) {
        if (i === 0) {
          datasetsItem.fill = 'origin';
        }
      } else {
        datasetsItem.fill = 'origin';
      }
      datasets.push(datasetsItem);
    });

    return {
      labels,
      datasets,
    };
  }

  dataset(
    label = 'No Label',
    data = [],
    rgbColor = `${_.random(255)}, ${_.random(255)}, ${_.random(255)}`,
  ) {
    return {
      label,
      data,
      lineTension: 0.1,
      backgroundColor: `${rgbColor}`,
      borderColor: `${rgbColor}`,
      borderWidth: 1,
      pointBorderColor: `${rgbColor}`,
      pointBackgroundColor: '#fff',
      pointBorderWidth: 1,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: `${rgbColor}`,
      pointHoverBorderColor: 'rgb(220, 220, 220)',
      pointHoverBorderWidth: 2,
      pointRadius: 1,
      pointHitRadius: 10,
      spanGaps: false,
    };
  }

  render() {

    const { stacked } = this.props;

    this.chartData = this.transformToChartData(
      this.props.data,
      this.props.sourceFieldName,
      this.props.labelsMapping,
      this.props.datasetsMapping,
      this.props.datasetOptions,
      stacked,
    );

    const defaultOptions = {
      animation: false,
      legend: {
        labels: {
          usePointStyle: true,
          boxWidth: 6,
        },
      },
      scales: {
        yAxes: [{
          ticks: {
            min: 0,
            beginAtZero: true,
            maxTicksLimit: 7,
            callback: (value) => {
              if (Math.floor(value) === value) {
                return value;
              } else {
                return null;
              }
            },
          },
        }],
      },
      hover: {
        mode: 'index',
        intersect: false,
      },
      tooltips: {
        mode: 'index',
        intersect: false,
        titleMarginBottom: 10,
        bodySpacing: 6,
        xPadding: 10,
      },
      maintainAspectRatio: false,
    };

    this.options = _.merge({}, defaultOptions, this.props.options);

    if (this.props.caption !== '') {
      this.options.title = {
        display: true,
        fontColor: '#292b2c',
        fontSize: 24,
        fontStyle: 'normal',
        position: 'left',
        text: this.props.caption,
      };
    }

    if (this.props.xLabel !== '') {
      _.merge(this.options, {
        scales: {
          xAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: this.props.xLabel,
              },
              gridLines: {
                display: true,
              },
            },
          ],
        },
      });
    }

    if (this.props.yLabel !== '') {
      _.merge(this.options, {
        scales: {
          yAxes: [{
            scaleLabel: {
              display: true,
              labelString: this.props.yLabel,
            },
          }],
        },
      });
    }

    if (stacked) {
      _.merge(this.options, {
        elements: {
          line: {
            fill: '-1',
          },
        },
        scales: {
          yAxes: [{
            stacked: true,
          }],
        },
      });
    }

    this.options.scales.yAxes[0].stacked = stacked;

    return (
      <div className="chart">
        <Line options={this.options} data={this.chartData} />
      </div>
    );
  }
}

LineChart.propTypes = {
  sourceFieldName: PropTypes.string,
  labelsMapping: PropTypes.object.isRequired,
  datasetsMapping: PropTypes.array.isRequired,
  options: PropTypes.object,
  stacked: PropTypes.bool,
  datasetOptions: PropTypes.array,
  data: PropTypes.array,
  xLabel: PropTypes.string,
  yLabel: PropTypes.string,
};

LineChart.defaultProps = {
  sourceUrl: '',
  caption: '',
  sourceFieldName: '',
  options: {},
  stacked: false,
  datasetOptions: [],
  data: [],
  xLabel: '',
  yLabel: '',
};

export default LineChart;
