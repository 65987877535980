import React, { useState } from 'react';
import { t } from '../../../i18n/t';
import DateRangePicker from '../../../components/DateRangePicker';
import ProfileFilter from '../../../components/search-query/filter/ProfileFilter';
import PlatformCoverageChart from '../../test_reports/test_run_report/PlatformCoverageChart';
import { allProfilesFilterOption } from '../../test_reports/test_run_report/utils';
import DateHelper from '../../../utils/DateHelper';

const PlatformCoverage = ({ dataLoaderRef }) => {

  const [filterDateTimeValue, setFilterDateTimeValue] = useState(DateHelper.getLast6Months());
  const [filterProfile, setFilterProfile] = useState(allProfilesFilterOption.value);

  const handleDateFilterChange = (changedDateTimeValue) => {
    setFilterDateTimeValue(changedDateTimeValue);
  };

  const handleProfileChange = (event, option) => {
    setFilterProfile(option.value);
  };

  const renderCustomControl = () => (
    <>
      <div className="ml-auto mr-3">
        <DateRangePicker className="project-dashboard-filter" value={filterDateTimeValue} handleChange={handleDateFilterChange} />
      </div>
      <ProfileFilter
        className="project-dashboard-filter autocomplete-filter"
        allProfiles={allProfilesFilterOption}
        title={t('profile')}
        handleSelectChange={(event, option) => handleProfileChange(event, option)}
      />
    </>
  );

  const renderBody = () => {
    const { startTime, endTime } = filterDateTimeValue;
    return (
      <PlatformCoverageChart
        dataLoaderRef={dataLoaderRef}
        startTime={startTime}
        endTime={endTime}
        profile={filterProfile}
        extraCustomControls={renderCustomControl()}
        tooltipMsg={t('project-dashboard#platform-coverage#tooltip')}
      />);
  };
  return renderBody();
};

export default PlatformCoverage;
