import React from 'react';
import { DialogContent, DialogContentText, DialogActions, DialogTitle } from '@mui/material';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';
import DialogComponent from './DialogComponent';
import { t } from '../../i18n/t';

function ConfirmSharingReportDialog({ isOpen, handleClose, handleConfirm, emails }) {

  return (
    <DialogComponent
      isOpen={isOpen}
      maxWidth="sm"
    >
      <DialogTitle className="confirm-header">{t('share-report-question')}</DialogTitle>
      <DialogContent className="confirm-content">
        <DialogContentText>
          <div className="title text-left">
            {t('share-report-list')}<br />
            {emails.join(', ')} <br />
            {t('share-report-warning')}
          </div>
        </DialogContentText>
      </DialogContent>
      <DialogActions className="confirm-footer">
        <Button color="secondary" onClick={handleClose}>
          {t('cancel')}
        </Button>
        <Button color="primary" onClick={handleConfirm}>
          {t('share-confirmation')}
        </Button>
      </DialogActions>
    </DialogComponent>
  );
}

ConfirmSharingReportDialog.propTypes = {
  /**
   * open the confirm sharing dialog
   */
  isOpen: PropTypes.bool,
  /**
   * Control state close of confirm dialog
   */
  handleClose: PropTypes.func,
  /**
   * Process the sharing action
   */
  handleConfirm: PropTypes.func,
  /**
   * array of string elements
   * each element is the email that user wants to share their report
   */
  emails: PropTypes.array,
};

ConfirmSharingReportDialog.defaultProps = {
  /**
   *  emails: in case null or undefine, value of emails is empty array
   */
  emails: []
};

export default ConfirmSharingReportDialog;
