import _ from 'lodash';
import { ListItemText } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { t } from '../../i18n/t';
import { SearchEntity } from '../../utils/Constants';
import DecoratorConstants from '../../utils/DecoratorConstants';
import DataTable from '../table/DataTable';
import MTableColumnDataMapping from '../table/models/MTableColumnDataMapping';

class RequirementDataTable extends React.Component {

  renderExternalIssue(row) {
    return (DecoratorConstants.externalIssueDecorator(row));
  }

  renderBDDFeature(row) {
    return (
      <ListItemText
        primary={DecoratorConstants.featureNameDecorator('featureName', row)}
      />
    );
  }

  render() {
    const {
      defaultSearchConditions: searchConditions,
      defaultSort,
      traceability,
      ...rest
    } = this.props;

    const defaultSearchConditions = [...searchConditions];
    const entityType = SearchEntity.ExternalRequirement;

    const columnMapping = [
      new MTableColumnDataMapping(
        t('type'),
        '',
        (name, row) => DecoratorConstants.externalTypeDecorator(row),
        undefined,
        'fit-content-column'
      ),
      new MTableColumnDataMapping(
        t('key'),
        'issueId',
        DecoratorConstants.requirementIdDecorator,
        undefined,
        'fit-content-column'
      ),
      new MTableColumnDataMapping(
        t('name'),
        'summary',
        (name, row) => {
          if (row.featureName) {
            return this.renderBDDFeature(row);
          }
          return _.get(row, name);
        },
      ),
      new MTableColumnDataMapping(
        t('requirements#testCaseTemplates'),
        '',
        (name, row) => DecoratorConstants.externalIssueTemplatesDecorator(name, row, 'testCases'),
      ),
    ];

    return (
      <DataTable
        columnMapping={columnMapping}
        entityType={entityType}
        defaultSearchConditions={defaultSearchConditions}
        defaultSort={defaultSort}
        {...rest}
      />
    );
  }
}

RequirementDataTable.propTypes = {
  defaultSearchConditions: PropTypes.array,
  defaultSort: PropTypes.array,
};

RequirementDataTable.defaultProps = {
  defaultSearchConditions: [],
  defaultSort: ['updatedAt,desc'],
};

export default RequirementDataTable;
