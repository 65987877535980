import moment from 'moment';
import Time from '../../utils/Moment';

export const getStartTime = (amount, unit) => Time.startOf(moment()
  .subtract(amount, unit), unit);

export const getEndTime = (unit) => moment()
  .endOf(unit);

export const timeFormatter = (value) => Time.formatDate(value, Time.LAST_MONTHS_FORMAT, true);

export const percentFormatter = (value, fixed = 0) => (value * 100).toFixed(fixed);

export const durationFormatter = (value) => Time.duration(value);

export const kFormatter = (value) => (value >= 10000 ? `${(value / 1000)}k` : value);
