import { useEffect } from 'react';
import { matchPath } from 'react-router-dom';
import Cookies from 'cookies-js';
import { jwtDecode } from 'jwt-decode';
import MAuth from '../models/MAuth';
import MConfigs from '../models/MConfigs';
import UserService from './UserService';
import MFlags from '../models/MFlags';

const AuthenticationProvider = (props) => {

  useEffect(() => {
    // Handle the case when the user is redirected from the universal login page after login successfully
    if (window.location.pathname === '/authenticate') {
      const url = new URL(window.location.href);
      const searchParams = new URLSearchParams(url.search);
      const authorizationCode = searchParams.get('code');
      if (!!authorizationCode) {
        UserService.exchangeAccessToken(authorizationCode);
      } else {
        console.log("Katalon: Not found Authorization Code");
        MAuth.logout(false);
      }
    };

    // Check to refresh access token every 5 seconds
    const interval = setInterval(() => {
      MAuth.isLoggedIn && UserService.refreshAccessToken();
    }, 5000);
    return () => clearInterval(interval);
  }, []);


  // When the access token is attached to the URL, logout the current user and automatically login with the token
  if (MAuth.isLoggedIn) {
    const url = new URL(window.location.href);
    const searchParams = new URLSearchParams(url.search);
    const otp = searchParams.get('otp');
    const accessToken = searchParams.get("katone_access_token");
    if (otp) {
      console.log("Katalon: Not support OTP login");
      MAuth.logout(false);
    } else if (accessToken) {
      try {
        if (jwtDecode(accessToken)?.email !== jwtDecode(Cookies.get('access_token'))?.email) {
          console.log("Katalon: Diff user with the current session");
          MAuth.logout(false);
        } else {
          searchParams.delete('otp');
          searchParams.delete('katone_access_token');
          if (searchParams.toString()) {
            window.history.replaceState(null, null, `${url.protocol}//${url.host}${url.pathname}?${searchParams.toString()}`);
          } else {
            window.history.replaceState(null, null, `${url.protocol}//${url.host}${url.pathname}`);
          }
        }
      } catch(err) {
        console.error(err);
      }
    }
  } else {
    const match = matchPath(window.location.pathname, {
      path: '/(reset-password|startup|error-sso|survey-completed|logout|login|authenticate|notification/not-found)/',
      exact: false,
      strict: false
    });
    if (!match) {
      console.log("Katalon: Log out if the current page matched", window.location.pathname);
      MAuth.logout(false);
    }
  }

  return props.children;
}

export default AuthenticationProvider;
