import React from 'react';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import ScheduleTestRunComponent from './ScheduleTestRunComponent';
import colors from '../../../../scss/colors.scss';
import TestSessionSummaryContainer from './TestSessionSummaryContainer';

const StyledHeaderTitleContainer = styled(Grid)`
  justify-content: flex-start;
  align-items: end;
  font-size: 24px;
  font-weight: 500;
  line-height: 1.5;
  color: ${colors.darkBlue};
`;

const StyledSessionSummaryContainer = styled(Grid)`
  justify-content: flex-end;
  align-items: center;
  column-gap: 32px;
  min-height: 62px;
`;

const StyledMainContainer = styled(Grid)`
  align-items: center;
  margin-bottom: 16px;
`;

function TestExecutionHeader(props) {
  const { headerTitle } = props;
  return (
    <StyledMainContainer container xs={12}>
      <StyledHeaderTitleContainer container xs={3}>
        {headerTitle}
      </StyledHeaderTitleContainer>
      <StyledSessionSummaryContainer container xs={9}>
        <TestSessionSummaryContainer />
        <ScheduleTestRunComponent {...props} />
      </StyledSessionSummaryContainer>
    </StyledMainContainer>
  );
}

TestExecutionHeader.propTypes = {
  headerTitle: PropTypes.string,
};

export default TestExecutionHeader;
