import React, { useRef } from 'react';
import { Divider, Grid } from '@mui/material';
import DocumentLink from '../../utils/DocumentLink';
import DecoratorConstants from '../../utils/DecoratorConstants';
import { ExternalIssue } from '../../models/model/ExternalIssue';
import { ObjectType, SearchEntity } from '../../utils/Constants';
import MContext from '../../models/MContext';
import Services from '../../utils/Services';
import DataLoader from '../table/DataLoader';
import { buildSearchCondition } from '../search/SearchUtils';
import { DefectDecorator } from './DefectDecorator';
import { IconCloseInactive, IconEmptyState } from '../../images/CustomIcon';
import TooltipComponent from '../TooltipComponent';
import { t } from '../../i18n/t';

interface ExternalIssueListProps {
  projectId: string | number;
  objectType: string;
  objectId: number;
  key: number;
  noCard: boolean;
  pageSize?: number;
  defaultSearchConditions?: object[];
  isTestRunPage?: boolean;
  emptyStateText: string;
  executionId?: number;
  isShortenContent?: boolean;
  setDataState?: (data: object[]) => void;
}

function ExternalIssueList(props: Readonly<ExternalIssueListProps>) {

  const projectId = props.projectId || MContext.projectId;

  const tableRef = useRef(null);
  const refreshData = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line no-unused-expressions
    tableRef.refreshData;
  };
  const unlinkExternalIssue = (issueId: string) => {
    const { objectType, objectId } = props;
    const params = {
      issueId,
      objectType,
      objectId,
    };
    Services.unlinkExternalIssue(projectId, params).then(
      refreshData
    );
  };

  const renderData = (defects: ExternalIssue[]) => {
    if (!defects || defects.length < 1) {
      if (props.isTestRunPage) {
        return (
          <div className="min-height-35rem">
            <div className="d-flex w-100 justify-content-center">
              <IconEmptyState />
            </div>
            {DecoratorConstants.emptyStateComponent(props.emptyStateText, DocumentLink.SUBMIT_JIRA_ISSUE)}
          </div>
        );
      } else {
        return DecoratorConstants.emptyStateComponent(props.emptyStateText, DocumentLink.SUBMIT_JIRA_ISSUE);
      }
    }

    return (
      <div className="defect-list-container">
        {defects.map((element: ExternalIssue, index: number) => (
          <>
            <Grid
              container
              key={element.issueId}
              className="defect-item"
            >
              <Grid item xs={11} md={11} className="w-100">
                <Grid className="summary mb-2 text-nowrap end-line-truncate">
                  {DecoratorConstants.textEndLineTruncateDecorator(element.summary)}
                </Grid>
                <Grid className="d-flex align-items-center" container>
                  <Grid item className="mr-3">
                    {DecoratorConstants.externalIssueIdDecorator('', element, 'defect-link')}
                  </Grid>
                  <Grid item className="mr-3">
                    <span className="d-block">
                      {DecoratorConstants.defectStatusDecorator(element, true)}
                    </span>
                  </Grid>
                  {!props.isShortenContent && DefectDecorator.priorityWithIconDecorator(element)}
                  {DefectDecorator.severityDecorator(element)}
                  {DefectDecorator.assigneeDecorator(element)}
                  {DefectDecorator.issueTimeStampDecorator(element)}
                  <Grid item className="mr-3">
                    <span className="d-block">
                      {props.isTestRunPage && DefectDecorator.testResultNameDecorator(element, props.executionId)}
                    </span>
                  </Grid>
                </Grid>
              </Grid>
              <Grid xs={1} md={1} item className="defect-action">
                {!props.isTestRunPage &&
                  <TooltipComponent
                    text={t('unlink-issue')}
                    followCursor={undefined}
                    open={undefined}
                    disableHoverListener={undefined}
                    customClasses={undefined}
                  >
                    <IconCloseInactive className="close-icon" onClick={() => unlinkExternalIssue(element.issueId)} />
                  </TooltipComponent>}
              </Grid>
            </Grid>
            {(index + 1 !== defects.length) && <Divider />}
          </>
        ))}
      </div>
    );
  };

  const {
    objectType,
    objectId,
    defaultSearchConditions: searchConditions,
    ...rest
  } = props;

  let entityType;
  let objectKey;
  const defaultSearchConditions = searchConditions ? [...searchConditions] : [];
  if (objectType === ObjectType.EXECUTION_TEST_RESULT) {
    entityType = SearchEntity.ExternalDefect;
    objectKey = `${SearchEntity.ExecutionTestResult}.id`;
  } else if (objectType === ObjectType.TEST_CASE) {
    entityType = SearchEntity.ExternalRequirement;
    objectKey = `${SearchEntity.TestCase}.id`;
  } else if (objectType === ObjectType.XRAY_TEST_CASE) {
    entityType = SearchEntity.ExternalXrayTest;
    objectKey = `${SearchEntity.TestCase}.id`;
  }

  if (objectId) {
    defaultSearchConditions.push(buildSearchCondition(objectKey, '=', objectId));
  }

  return (
    <DataLoader
      ref={tableRef}
      render={renderData}
      entityType={entityType}
      defaultSearchConditions={defaultSearchConditions}
      {...rest}
    />
  );
}

export default ExternalIssueList;
