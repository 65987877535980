import React, { useState } from 'react';
import { groupBy, get, values } from 'lodash';
import { getEndTime, getStartTime } from '../utils';
import DataLoader from '../../../components/table/DataLoader';
import { t } from '../../../i18n/t';
import { buildSearchCondition, buildSearchFunction } from '../../../components/search/SearchUtils';
import Time from '../../../utils/Moment';
import { SearchEntity, TestRunDailyOptions } from '../../../utils/Constants';
import Select from '../../../components/Select';
import TestRunDailyChart from './TestRunDailyChart';

const groupByTime = 'day';

const TestRunDailyReport = ({ defaultFilterOption = TestRunDailyOptions.SEVEN_DAYS }) => {
  const [dayFilter, setDayFilter] = useState(defaultFilterOption.value);
  const [from, setFrom] = useState(getStartTime(dayFilter, groupByTime));
  const [to, setTo] = useState(getEndTime(groupByTime));

  const convertDataForChart = (data) => {

    // extract data
    const convertedData = data.map(({ content }) => ({
      ...content
    }));

    // group data by time
    const groupByData = groupBy(convertedData, 'time') || [];
    const timeRange = Time.timeBetween(from, to, `${groupByTime}`, Time.LOCAL_DATE_FORMAT);
    return timeRange.map((time) => {
      const newValue = {
        time,
        total: 0,
        PASSED: 0,
        FAILED: 0,
      };

      // calculate total PASSED, FAILED of each day
      if (groupByData[time]) {
        groupByData[time].forEach((value) => {
          newValue[value.status] = get(newValue, value.status, 0) + get(value, 'total');
          newValue.total += newValue[value.status];
        });
      }

      if (newValue.total) {
        newValue.ratioPASSED = newValue.PASSED / newValue.total;
        newValue.ratioFAILED = 1 - newValue.ratioPASSED;
      }
      return newValue;
    });
  };

  const renderChart = (data) => {
    const convertedData = convertDataForChart(data);
    return (
      <TestRunDailyChart data={convertedData} viewOption={dayFilter} />
    );
  };

  const onSelectDayFilter = ({ value }) => {
    setDayFilter(value);
    setFrom(getStartTime(value, groupByTime));
  };

  const renderCustomControl = () => {
    const testRunDailyOptions = values(TestRunDailyOptions);

    return (
      <Select
        id="testRunDailyFilter"
        clearable={false}
        options={testRunDailyOptions}
        value={dayFilter}
        onChange={onSelectDayFilter}
        data-trackid="filter-test-run-chart"
      />
    );
  };

  return (
    <DataLoader
      title={t('test-run-report-by-status')}
      key={`group_by_day_execution_status_${dayFilter}`}
      entityType={SearchEntity.Execution}
      defaultSearchConditions={[
        buildSearchCondition('startTime', '>', from),
        buildSearchCondition('startTime', '<', to),
      ]}
      defaultSearchFunctions={[
        buildSearchFunction('total', 'count', ['id']),
        buildSearchFunction('time', 'group_by_day', ['startTime']),
      ]}
      groupBys={['status']}
      defaultSort={[
        'time, desc',
      ]}
      pageSize={100}
      render={renderChart}
      customControl={renderCustomControl()}
    />
  );
};

export default TestRunDailyReport;
