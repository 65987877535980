import React from 'react';
import PropTypes from 'prop-types';
import { IconButton, DialogContent } from '@mui/material';
import CloseableDialogComponent from './CloseableDialogComponent';
import { IconAngleLeft, IconAngleRight } from '../../images/KitIcons';
import Spinner from '../Spinner';

function BaselineImageViewDialog({ isOpen, handleClose, isFirst, isLast, previousBaseline, nextBaseline, isLoading, image }) {

  const renderLoading = () => (
    <div className="baseline-modal__loading-spinner">
      <Spinner />
    </div>
  );

  const renderImage = (image) => (
    <div className="w-100">
      <img src={image.source} className="w-100" alt={image.title} />
    </div>
  );

  const renderTitle = () => {
    if (!isLoading) {
      return <h4>{image?.title}</h4>;
    }
    return null;
  };

  return (
    <CloseableDialogComponent
      isOpen={isOpen}
      handleClose={handleClose}
      maxWidth="95vw"
      className="baseline-modal"
      title={renderTitle()}
    >
      <DialogContent>
        <div className="d-flex align-items-center mb-4">
          <div className={`baseline-modal__navigate-button ${isFirst ? 'invisible' : ''}`}>
            <IconButton onClick={previousBaseline} size="large">
              <IconAngleLeft />
            </IconButton>
          </div>
          {(isLoading || !image) ? renderLoading() : renderImage(image)}
          <div className={`baseline-modal__navigate-button ${isLast ? 'invisible' : ''}`}>
            <IconButton onClick={nextBaseline} size="large">
              <IconAngleRight />
            </IconButton>
          </div>
        </div>
      </DialogContent>
    </CloseableDialogComponent>
  );
}

BaselineImageViewDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  isFirst: PropTypes.func.isRequired,
  isLast: PropTypes.func.isRequired,
  previousBaseline: PropTypes.func.isRequired,
  nextBaseline: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  image: PropTypes.object.isRequired
};

export default BaselineImageViewDialog;
