import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Autocomplete from '@mui/material/Autocomplete';
import { includes, trim } from 'lodash';
import { Box, Typography } from '@mui/material';
import TextField from '../../TextField';
import MContext from '../../../models/MContext';
import { buildSearchCondition } from '../../search/SearchUtils';
import Services from '../../../utils/Services';
import { MAX_PAGE_SIZE, SearchEntity } from '../../../utils/Constants';
import { t } from '../../../i18n/t';
import MFlags from '../../../models/MFlags';
import TagChipTsx from '../../tsx-components/TagChipTsx';
import TagChip from '../../TagChip';

class TagFilterComponent extends Component {
  constructor(props) {
    super(props);
    this.projectId = MContext.projectId;
    this.state = {
      tags: [],
      loading: false,
    };
  }

  getAllTags = () => {
    const { projectId } = this;
    const { value } = this.props;
    const chosenTags = value ? String(value).match(/\d+/g) : [];
    this.setState({ loading: true });

    const params = {
      pagination: {
        page: 0,
        size: MAX_PAGE_SIZE,
        sorts: ['id, desc'],
      },
      conditions: [
        buildSearchCondition('Project.id', '=', projectId)
      ],
      type: SearchEntity.Tag,
      ignoreAutoFill: true,
    };

    Services.searchRecursively(0, params, [])
      .then((content) => {
        const selectedTags = content.filter(({ id }) => chosenTags.includes(String(id)));
        this.setState({
          tags: content,
          loading: false,
        });
        if (selectedTags && selectedTags.length) this.props.setSelectedTags(selectedTags);
      });
  }

  componentDidMount() {
    this.getAllTags();
  }

  onDelete = (option) => {
    const { handleDelete } = this.props;
    handleDelete(option);
  }

  onChange = (event, selectedOptions) => {
    const { handleSelectChange } = this.props;
    handleSelectChange(event, selectedOptions);
  }

  renderOption = (props, option) => (
    <li {...props}>
      <div className="user-avatar" title={option.label || option.name}>
        {option.label || option.name}
      </div>
    </li>
  )

  render() {
    const { selectedTags, autoInputClassName } = this.props;

    const { tags, loading } = this.state;

    return (
      <div className={autoInputClassName}>
        <div className="mb-2">
          <div>
            <Autocomplete
              multiple
              filterSelectedOptions
              value={selectedTags}
              options={tags}
              filterOptions={(options, state) =>
                options.filter((option) => includes(option.name, trim(state.inputValue)))}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={t('search-bar#placeholder-tag')}
                />
              )}
              renderGroup={(params) => (
                <Box key={params.key}>
                  <Typography className="tag-autocomplete__groupby-label">{params.group}</Typography>
                  {params.children}
                </Box>
              )}
              fullWidth
              disableClearable
              onChange={this.onChange}
              autoHighlight
              loading={loading}
              renderTags={() => null}
              id="tagEntity"
              isOptionEqualToValue={(option, value) => option.name === value.name}
              getOptionLabel={(option) => option.name}
              renderOption={this.renderOption}
              groupBy={() => t('tag#all-tags')}
            />
          </div>
        </div>
        <div>
          {selectedTags.map((option, index) => (
            <TagChipTsx
              key={option.name}
              name={option.name}
              handleRemove={() => this.onDelete(option, index)}
            />
          ))}
        </div>
      </div>
    );
  }
}

TagFilterComponent.propTypes = {
  handleDelete: PropTypes.func.isRequired,
  handleSelectChange: PropTypes.func.isRequired,
  selectedTags: PropTypes.array,
  setSelectedTags: PropTypes.func,
  autoInputClassName: PropTypes.string,
  /**
   * Current value of input in search bar. (For Test Management page)
   */
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]),
};

TagFilterComponent.defaultProps = {
  selectedTags: [],
  setSelectedTags: null,
  autoInputClassName: '',
};

export default TagFilterComponent;
