import React from 'react';
import { t } from '../../i18n/t';
import MContext from '../../models/MContext';
import PageComponent from '../../components/PageComponent';
import Releases from './Releases';

class ReleasesPage extends PageComponent {
  constructor(props) {
    super(props);
    this.meta.id = 'page-releases';
    this.meta.title = t('Project {{name}} - Releases', { name: MContext.project.name });
  }

  render() {
    return (
      <Releases {...this.props} />
    );
  }
}

export default ReleasesPage;
