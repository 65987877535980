import React, { useEffect, useState } from 'react';
import { Label, FormGroup, Col, Row, CustomInput } from 'reactstrap';
import { Controller } from 'react-hook-form';
import { t } from '../../../i18n/t';
import Select from '../../Select';
import Input from '../../Input';
import {
  CloudType,
  ConfigType,
  ExecutionMode,
  XrayImportReportTypeOptions
} from '../../../utils/Constants';
import TooltipComponent from '../../TooltipComponent';
import { EXECUTION_MODE_OPTION_MAP } from '../../../pages/plan/ConfigurePlanUtils';
import KreVersionSelect from '../../dialog/KreVersionSelect';
import { extractCloudTypeInAgentByConfigType } from '../services/scheduleTestRun';
import XrayImportIntegration from '../../dialog/XrayImportIntegration';
import RunConfigurationCustomField from './RunConfigurationCustomField';
import RunConfigurationTag from './RunConfigurationTag';

function AdvancedSettingsComponent(props) {
  const {
    releases,
    ksVersions,
    baselineCollectionGroups,
    form: { control, formState, getValues, setValue },
    isTestSuiteType = false,
    isXrayFeaturesEnabled,
    selectedTab,
  } = props;

  const { errors } = formState;

  const executionModeOptions = isTestSuiteType ? [EXECUTION_MODE_OPTION_MAP[ExecutionMode.SEQUENTIAL]] : [
    EXECUTION_MODE_OPTION_MAP[ExecutionMode.SEQUENTIAL],
    EXECUTION_MODE_OPTION_MAP[ExecutionMode.PARALLEL],
  ];

  const [enabledKobitonIntegration, setEnabledKobitonIntegration] = useState(false);
  const [kreVersionEnable, setKreVersionEnable] = useState(false);

  const handleToggleKobitonIntegration = (field) => (event) => {
    setEnabledKobitonIntegration(event.target.checked);
    field.onChange(event.target.checked);
  };

  useEffect(() => {
    if (formState.dirtyFields) {
      const runConfiguration = getValues('runConfiguration');
      if (runConfiguration) {
        const cloudType = extractCloudTypeInAgentByConfigType(runConfiguration, CloudType.TEST_CLOUD_AGENT, runConfiguration.configType?.value);
        setKreVersionEnable(runConfiguration.configType?.value !== ConfigType.GENERIC_COMMAND);
        const enableSelectKsLocation = cloudType === CloudType.LOCAL_AGENT;
        setValue('runConfiguration.kreVersion', {
          ksLocation: runConfiguration.kreVersion?.ksLocation,
          ksVersion: !runConfiguration.kreVersion?.ksLocation ? runConfiguration.kreVersion?.ksVersion || ksVersions[0]?.value : undefined,
          enableSelectKsLocation });
        setEnabledKobitonIntegration(runConfiguration.enabledKobitonIntegration);
      }
    }
  }, [formState]); // eslint-disable-line react-hooks/exhaustive-deps

  const renderSelectBaselineCollection = () => {
    if (selectedTab === 2 || selectedTab === 3) return null;
    return (
      <FormGroup className="d-flex">
        <Label className="test-run-label col-2 pr-0 pl-0 mt-0" for="baselineCollection">{t('testops-keyes')}
          <TooltipComponent text={t('run-configuration#baseline-collection-tooltip')} placement="bottom-start" arrow />
        </Label>

        <div className="p-0 col-10">
          <Controller
            name="runConfiguration.baselineCollectionGroupOrder"
            control={control}
            render={({ field }) =>
              <Select
                {...field}
                required
                clearable={false}
                id="baselineCollectionGroups"
                options={baselineCollectionGroups}
                onChange={(item) => {
                  field.onChange(item.value);
                }}
                isCustom
              />}
          />
        </div>
      </FormGroup>
    );
  };

  return (
    <Row>
      <Col>
        {renderSelectBaselineCollection()}
        {kreVersionEnable && <Controller
          name="runConfiguration.kreVersion"
          control={control}
          render={({ field }) =>
            <KreVersionSelect
              {...field}
              ksVersions={ksVersions}
            />}
        />}
        <FormGroup className="d-flex">
          <Label className="test-run-label col-2 pr-0 pl-0 mt-0" for="executionMode">{t('run-configuration#execution-mode')}
            <TooltipComponent text={t('schedule#tooltip-execution-mode')} />
          </Label>
          <div className="p-0 col-10">
            <Controller
              name="runConfiguration.executionMode"
              control={control}
              render={({ field }) =>
                <Select
                  {...field}
                  required
                  clearable={false}
                  id="executionMode"
                  options={executionModeOptions}
                />}
            />
          </div>
        </FormGroup>
        <FormGroup className="d-flex">
          <Label className="test-run-label col-2 pl-0" for="enabledKobitonIntegration">{t('integration')}</Label>
          <div className="d-flex flex-column p-0 col-10">
            {isXrayFeaturesEnabled &&
              <div className="p-0">
                <Controller
                  name="runConfiguration.xrayIntegration"
                  control={control}
                  render={({ field }) =>
                    <XrayImportIntegration {...field} xrayImportReportTypes={XrayImportReportTypeOptions} />}
                />
              </div>}
            <div className="flex p-0">
              <div className="d-flex">
                <Controller
                  name="runConfiguration.enabledKobitonIntegration"
                  control={control}
                  defaultValue={enabledKobitonIntegration}
                  render={({ field }) =>
                    <CustomInput
                      {...field}
                      id="kobiton-enable"
                      type="switch"
                      name="kobiton-enable"
                      checked={field.value}
                      onChange={handleToggleKobitonIntegration(field)}
                    />}
                />
                <Label for="kobitonDeviceId">{t('integrations#kobiton')}</Label>
                <TooltipComponent text={t('schedule#tooltip-integration')} />
              </div>
              {enabledKobitonIntegration &&
                <Controller
                  name="runConfiguration.kobitonDeviceId"
                  control={control}
                  render={({ field }) =>
                    <Input
                      {...field}
                      id="kobitonDeviceId"
                      name="kobitonDeviceId"
                      type="text"
                      required={enabledKobitonIntegration}
                      placeHolder={t('schedule#input-kobiton-device-id')}
                    />}
                />}
            </div>
          </div>
        </FormGroup>
        <FormGroup className="d-flex mb-1">
          <Label className="test-run-label col-2 pl-0 mt-2" for="customField">{t('custom-fields#title')}</Label>
          <div className="d-flex flex-column pt-2 pl-0 pr-0 pb-0 col-10">
            <Controller
              name="runConfiguration.customFieldOptions"
              control={control}
              render={({ field }) =>
                <RunConfigurationCustomField
                  {...field}
                  hideLabel
                />}
            />
          </div>
        </FormGroup>
        <FormGroup className="d-flex mb-1">
          <Label className="test-run-label col-2 pl-0 mt-0" for="tag">{t('tag#title')}</Label>
          <div className="p-0 col-10">
            <Controller
              name="runConfiguration.tags"
              control={control}
              render={({ field }) => (
                <RunConfigurationTag
                  {...field}
                  hideLabel
                  tags={field.value}
                />)}
            />
          </div>
        </FormGroup>
        <FormGroup className="d-flex">
          <Label className="test-run-label col-2 pl-0 mt-0" for="releaseId">{t('release#version')}</Label>
          <div className="p-0 col-10">
            <Controller
              name="runConfiguration.release"
              control={control}
              render={({ field }) =>
                <Select
                  {...field}
                  data-trackid="release-version"
                  id="releaseId"
                  name="releaseId"
                  hasOverflowParentDiaglog={document.getElementById('smart-scheduler-dialog')}
                  options={releases}
                  clearable
                  maxMenuHeight={125}
                  isCustom
                />}
            />
          </div>
        </FormGroup>
        <FormGroup className="d-flex">
          <Label className="test-run-label col-2 pl-0 mt-0" for="timeOut">{t('run-configuration#time-out')}</Label>
          <div className="p-0 col-10 mt-1">
            <Controller
              name="runConfiguration.timeOut"
              control={control}
              rules={{ required: true, min: 10, max: 999 }}
              render={({ field }) =>
                <Input
                  {...field}
                  required
                  data-trackid="test-timeout"
                  id="timeOut"
                  name="timeOut"
                  type="number"
                  min="10"
                  max="999"
                  defaultValue={180}
                />}
            />
            {errors?.runConfiguration && errors.runConfiguration?.timeOut && errors.runConfiguration.timeOut.type === 'required' &&
            <small className="text-danger fs-6"> {t('test_cloud_validation_timeout_blank')} </small>}
            {errors?.runConfiguration && errors.runConfiguration?.timeOut && errors.runConfiguration.timeOut.type !== 'required' &&
            <small className="text-danger fs-6"> {t('test_cloud_validation_timeout_invalid_value')} </small>}
          </div>
        </FormGroup>
        <FormGroup className="d-flex" />
        <FormGroup className="d-flex" />
      </Col>
    </Row>
  );
}

export default AdvancedSettingsComponent;
