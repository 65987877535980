import React from 'react';
import PropTypes from 'prop-types';
import { BetaChip } from '../BetaChip';
import { FolderType } from '../../utils/Constants';

function TreeNodeTitle(props) {

  const {
    title,
    width,
    id
  } = props;

  /**
   * If add more parent div for this, please check feature auto scroll in TreeView file(smoothScrollToFolder function)
   */

  return (
  /**
   * 180px is the initial width of TreeNodeTitle
   * A node folder UI is consist of an arrow, an icon and the title, since we are transferring the directory width
   * to the title, we have to minus the arrow and icon width (100px) to have it restrict within the directory only
   * (or else it will grow out of the directory width)
   */
    <div
      id={id}
      style={{
        width: width - 100 > 0 ? width - 100 : 180,
        maxWidth: width - 100 > 0 ? width - 100 : 180,
      }}
      className="folder-title"
    >
      {title}
      {id === FolderType.DRAFT_FOLDER && <BetaChip sx={{ marginLeft: 2 }} />}
    </div>
  );
}

TreeNodeTitle.propTypes = {
  width: PropTypes.string,
};

TreeNodeTitle.defaultProps = {
  width: 180
};

export default TreeNodeTitle;
