import React, { Component } from 'react';
import { NavbarBrand } from 'reactstrap';
import { IconButton } from '@mui/material';
import { IconArrowDown, IconKatalonPlatform, IconKatalonPlatformFull } from '../../images/CustomNewIcon';
import MContext from '../../models/MContext';
import { PageType } from '../../utils/Constants';
import Routes from '../../utils/Routes';
import DropDownMenu from './DropDownMenu';
import RecentProjects from './RecentProjects';
import RouteConstants from '../../utils/RouteConstants';
import RecentOrganizations from './RecentOrganizations';
import ListOrganizationsAndProjects from './ListOrganizationsAndProjects';
import MFlags from '../../models/MFlags';
import MConfigs from '../../models/MConfigs';
import Apis from '../../utils/Apis';

class TopLeftNavigationBar extends Component {

  constructor(props) {
    super(props);

    this.organizationId = MContext.organizationId;
    this.team = MContext.team;
    this.project = MContext.project;
    this.organization = MContext.organization || this.team?.organization || null;
  }

  renderLogo() {
    const { type } = this.props;
    let logo = <IconKatalonPlatform />;
    if (type === PageType.USER || type === undefined) {
      logo = <IconKatalonPlatformFull />;
    }

    let { organizationId } = this;
    if (!organizationId) {
      const team = MContext.team;
      organizationId = team ? team.organizationId : null;
    }

    const route = new Routes({ organizationId });
    let url = route.organization_home_link;

    if (type === PageType.PROJECT) {
      url = route.project_dashboard_link;
    }

    if (MConfigs.isLicenseAdminServer) {
      url = Apis.katOneOrganizationHomePage({ orgId: this.organizationId });
    }
    if (!organizationId) {
      url = RouteConstants.home;
    }

    const isTeamDemo = MContext.isTeamDemo;

    return (
      <NavbarBrand href={!isTeamDemo && url} style={{ pointerEvents: isTeamDemo ? 'none' : 'auto' }}>
        <span className="nav-item-icon">
          {logo}
        </span>
      </NavbarBrand>
    );
  }

  /**
   * @deprecated due to KO-665
   */
  renderRecentProject() {
    const name = this.project?.name;
    const button = (props) => (
      <IconButton {...props} size="large">
        <div className="name" title={name}>
          {name}
        </div>
        <IconArrowDown />
      </IconButton>
    );

    return (
      <DropDownMenu
        renderAnchorElement={button}
      >
        <RecentProjects {...this.props} />
      </DropDownMenu>
    );
  }

  /**
   * @deprecated due to KO-665
   */
  renderOrganizationsDropDown() {
    const name = this.organization?.name;
    const button = (props) => (
      <IconButton id="select-organization" {...props} size="large">
        <div className="name" title={name}>
          {name}
        </div>
        <IconArrowDown />
      </IconButton>
    );

    return (
      <DropDownMenu
        renderAnchorElement={button}
      >
        <RecentOrganizations {...this.props} />
      </DropDownMenu>
    );
  }

  render() {
    const { type } = this.props;
    return (
      <div id="top-left-navbar">
        {this.renderLogo()}
        {!(type === PageType.USER || type === undefined) && <ListOrganizationsAndProjects />}
      </div>
    );
  }
}

export default TopLeftNavigationBar;
