import React from 'react';
import PropTypes from 'prop-types';

class LabelSummary extends React.Component {

  renderColor(color) {
    if (color === 'failed') return 'label-summary-failed';
    if (color === 'warning') return 'label-summary-warning';
    if (color === 'success') return 'label-summary-success';
    return 'label-summary-blank';
  }

  render() {
    const { content, label, color, labelStyle } = this.props;
    return (
      <>
        <div className={labelStyle}>
          <div className={`label-summary ${this.renderColor(color)}`}>
            <div className="content">{content}</div>
            <div className="label">{label}</div>
          </div>
        </div>
      </>
    );
  }
}

LabelSummary.propTypes = {
  content: PropTypes.string,
  label: PropTypes.string,
  color: PropTypes.string,
};

LabelSummary.defaultProps = {
  content: null,
  label: null,
  color: 'blank',
};

export default LabelSummary;
