import Box from '@mui/material/Box';
import { IconButton, Radio } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React from 'react';
import { styled } from '@mui/material/styles';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const ExpandWithRadioButton = ({ title, checked, expand, handleChange, handleExpand, value }) => (
  <>
    <Box onClick={() => handleChange(value)} sx={{ display: 'flex', cursor: 'pointer' }}>
      <Box>
        <Radio
          sx={{ marginLeft: '-9px',
            '& .MuiSvgIcon-root': {
              fontSize: 24,
            },
            '&.Mui-checked': {
              color: '#276ef1',
            } }}
          checked={checked}
          onChange={() => handleChange(value)}
          value={value}
          name="radio-buttons"
          inputProps={{ 'aria-label': 'A' }}
        />
        <Box component="div" sx={{ display: 'inline', fontSize: '1.1rem', fontWeight: 500, fontStyle: 'normal' }}>{ title }</Box>
      </Box>
      <ExpandMore
        sx={{ marginLeft: 'auto' }}
        expand={expand}
        onClick={handleExpand}
        aria-expanded={expand}
        aria-label="show more"
      >
        <ExpandMoreIcon sx={{ width: '24px', height: '24px' }} />
      </ExpandMore>
    </Box>
    <Box sx={{ height: '0.5px', border: 'solid 0.5px #dbdde5', marginBottom: '16px' }} />
  </>
);

export default ExpandWithRadioButton;
