import MContext from '../models/MContext';
import MAuth from '../models/MAuth';
import MFlags from '../models/MFlags';
import MConfigs from '../models/MConfigs';
import { sendAnalyticEventForAction, analyticsPage } from './SegmentAnalytics';
import WebSocket from '../services/WebSocket';
import Notification from '../utils/Notification';

const TestOpsContext = {
  MContext,
  MAuth,
  MFlags,
  MConfigs,
  TestOpsUtils: {
    sendAnalyticEventForAction,
    analyticsPage,
    WebSocket,
    Notification,
  }
};


export const mountMicroApp = (id) => {
  window.microapps[id].mountApp(null, {
    ...TestOpsContext
  });
};

export const mountMicroComponent = (id, componentId, props) => {
  window.microapps[id].mountComponent(componentId, {
    ...TestOpsContext,
    ...props,
  });
};

const loadScripts = (indexString, mountCallBack) => {
  const doc = new DOMParser().parseFromString(indexString, 'text/html');
  const scripts = doc.getElementsByTagName('script');
  const newScripts = [];
  Array.from(scripts).forEach((script) => {
    const src = script.getAttribute('src');
    const type = script.getAttribute('type');
    const newScript = document.createElement('script');
    newScript.setAttribute('type', type || 'text/javascript');
    newScript.src = src;
    newScripts.push(newScript);
  });
  const loadNextScript = function () {
    const script = newScripts.shift();
    if (script) {
      let loaded = false;
      document.head.appendChild(script);
      // eslint-disable-next-line no-multi-assign
      script.onload = script.onreadystatechange = function () {
        const rs = this.readyState;
        if (rs && rs !== 'complete' && rs !== 'loaded') return;
        if (loaded) return;
        loaded = true;
        if (scripts.length) {
          loadNextScript();
        }
      };
    } else {
      if (window.microapps) {
        mountCallBack();
      }
    }
  };
  loadNextScript();
};

export const getIndexFile = (path, mountCallBack) => {
  const xmlhttp = new XMLHttpRequest();
  xmlhttp.onreadystatechange = function () {
    if (this.readyState === 4 && this.status === 200) {
      loadScripts(this.responseText, mountCallBack);
    }
  };
  const unCachedPath = `${path}?t=${new Date().getTime()}`;
  xmlhttp.open('GET', unCachedPath, true);
  xmlhttp.send();
};
