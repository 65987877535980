import React from 'react';
import { Button, Card, CardBody, CardHeader, FormGroup, Label } from 'reactstrap';
import DefaultLayout from '../../components/DefaultLayout';
import PageComponent from '../../components/PageComponent';
import { buildSearchCondition } from '../../components/search/SearchUtils';
import ObjectSummary from '../../components/summary/ObjectSummary';
import JobTable from '../../components/table/JobTable';
import { t } from '../../i18n/t';
import MContext from '../../models/MContext';
import Services from '../../utils/Services';
import PageButtonToolbar from '../../components/PageButtonToolbar';
import Routes from '../../utils/Routes';
import Input from '../../components/Input';
import DecoratorConstants from '../../utils/DecoratorConstants';
import Notification from '../../utils/Notification';
import {
  openScheduleDialogTcEnv,
  track
} from '../../components/smarttestscheduling/services/scheduleTestRunTracking';

class TestCloudAgentDetails extends PageComponent {
  constructor(props) {
    super(props);
    this.testCloudAgentId = MContext.testCloudAgentId;
    this.teamId = MContext.teamId;
    this.projectId = MContext.projectId;

    this.meta.id = 'page-test-cloud-agent-details';
    this.meta.title = 'TestCloud Agent Details';

    this.renderHeader = this.renderHeader.bind(this);
    this.renderBody = this.renderBody.bind(this);
    this.handleClickScheduleTestRunButton = this.handleClickScheduleTestRunButton.bind(this);
  }

  state = {
    testCloudAgent: null,
  };

  getTestCloudAgent() {
    Services.getTestCloudAgent(this.testCloudAgentId)
      .then((response) => {
        const testCloudAgent = response;
        this.meta.title = `TestCloud Agent Details - ${testCloudAgent.name}`;
        this.setState({
          testCloudAgent,
        });
      }).catch((e) => {
        Notification.pushError(e, 'Cannot get test cloud agent detail.');
      });
  }

  componentDidMount() {
    this.getTestCloudAgent();
  }

  renderObjectSummary() {
    const urlParams = {
      agentDetails: t('agent'),
    };
    return (
      <ObjectSummary params={urlParams} />
    );
  }

  renderHeader() {
    return (
      <>
        {this.renderObjectSummary()}
      </>
    );
  }

  renderAgent() {
    const { testCloudAgent } = this.state;

    if (!testCloudAgent) {
      return null;
    }

    return (
      <Card>
        <CardHeader>{t('agent#details')}</CardHeader>
        <CardBody>
          <FormGroup>
            <Label for="agent-name" className="test-cloud-label">{t('agent#name')}</Label>
            <Input
              plaintext
              id="agent-name"
              className="app-agent-row__status_agent"
            >
              {DecoratorConstants.testCloudAgentDetailDecorator(null, testCloudAgent, true)}
            </Input>
          </FormGroup>
          <FormGroup>
            <Label for="agent-num-assigned-jobs" className="test-cloud-label">{t('agent#num-assigned-jobs')}</Label>
            <Input plaintext id="agent-num-assigned-jobs">{testCloudAgent.numAssignedJobs}</Input>
          </FormGroup>
          <FormGroup>
            <Label for="agent-num-executing-jobs" className="test-cloud-label">{t('agent#num-executing-jobs')}</Label>
            <Input plaintext id="agent-num-executing-jobs">{testCloudAgent.numExecutingJobs}</Input>
          </FormGroup>
        </CardBody>
      </Card>
    );
  }

  renderJobsByAgent() {
    return (
      <JobTable
        defaultSearchConditions={[
          buildSearchCondition('Project.id', '=', this.projectId),
          buildSearchCondition('TestCloudAgent.id', '=', this.testCloudAgentId),
        ]}
        testCloudAgent
        defaultSort="order,desc"
        autoUpdate
        tableId="Jobs"
        title={t('jobs')}
      />
    );
  }

  handleClickScheduleTestRunButton() {
    track(openScheduleDialogTcEnv());
  }

  renderCreateTestPlan(teamId, projectId) {
    const routes = new Routes({ teamId, projectId });
    return (
      <Button
        title={t('create-plan')}
        color="secondary"
        href={routes.smart_test_scheduling_link()}
        onClick={() => this.handleClickScheduleTestRunButton()}
      >
        {t('create-plan')}
      </Button>
    );
  }

  renderButtons() {
    return (
      <PageButtonToolbar>
        {this.renderCreateTestPlan(this.teamId, this.projectId)}
      </PageButtonToolbar>
    );
  }

  renderBody() {
    return (
      <div>
        {this.renderButtons()}
        {this.renderAgent()}
        {this.renderJobsByAgent()}
      </div>
    );
  }

  render() {
    return (
      <DefaultLayout
        renderHeader={this.renderHeader}
        renderBody={this.renderBody}
      />
    );
  }
}

export default TestCloudAgentDetails;
