import React from 'react';
import PageComponent from '../../components/PageComponent';
import { notificationIds } from '../../utils/NotificationPageUtils';
import { t } from '../../i18n/t';
import Routers from '../../utils/Routes';
import MAuth from '../../models/MAuth';
import MContext from '../../models/MContext';
import UsersTab from './UsersTab';
import AddUserTab from './AddUserTab';
import MFlags from '../../models/MFlags';
import MConfigs from '../../models/MConfigs';

class UserManagement extends PageComponent {
  constructor(props) {
    super(props);
    this.meta.id = 'page-team-users';
    this.meta.title = t('Users');

    this.addUserTab = React.createRef();
    this.usersTable = React.createRef();

    this.teamId = MContext.teamId;
  }

  refreshTable() {
    this.usersTable.current.usersList.refreshData();
  }

  render() {
    const { isDisableEditInTeam, isNewOrganizationModal } = this.props;
    const isTeamManager = MAuth.isTeamManager(this.teamId);
    const isLicenseAdminServer = MConfigs.isLicenseAdminServer;

    const team = MContext.team;

    if (team) {
      return (
        <>
          <AddUserTab
            isNewOrganizationModal={isNewOrganizationModal}
            isDisableEditInTeam={isDisableEditInTeam}
            afterAction={() => this.refreshTable()}
            ref={this.addUserTab}
          />
          <UsersTab
            isNewOrganizationModal={isNewOrganizationModal}
            isDisableEditInTeam={isDisableEditInTeam}
            afterAction={() => this.addUserTab.current.getUserInTeam()}
            ref={this.usersTable}
          />
        </>
      );
    }
    return null;
  }
}

export default UserManagement;
