import React, { useEffect, useState } from 'react';
import TestSuiteRunConfiguration from './TestSuiteRunConfiguration';
import { TestProjectType, UploadAppStatus } from '../../../utils/Constants';
import Notification from '../../../utils/Notification';
import {
  getAgents,
  getExecutionProfileRecursively,
  getCircleCIAgents,
  getK8SAgents,
  getTestSuitesRecursively
} from '../../../pages/plan/ConfigurePlanUtils';
import storageService from '../../../utils/mobiletesting/mobileTestingService';
import DecoratorConstants from '../../../utils/DecoratorConstants';
import MContext from '../../../models/MContext';
import Services from '../../../utils/Services';
import { refreshTsEvent, track } from '../services/scheduleTestRunTracking';
import MFlags from '../../../models/MFlags';

function TsRunConfigurationContainer(props) {
  const { trackId, testProject } = props;

  const [testSuites, setTestSuites] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [apps, setApps] = useState([]);
  const [executionProfiles, setExecutionProfiles] = useState([]);
  const [localAgents, setLocalAgents] = useState([]);
  const [circleCIAgents, setCircleCIAgents] = useState([]);
  const [k8sAgents, setK8sAgents] = useState([]);

  const getNativeApps = () => storageService.getAppsByStatus(UploadAppStatus.READY, 0, 1000000, 'createdDate desc')
    .then((result) =>
      result?.items.map((el) => ({
        label: el.name || el.fileName,
        icon: DecoratorConstants.iconByOS(el.platform),
        value: el.id,
        platform: el.platform,
        groupId: el.groupId,
        name: el.name,
        postBadge: DecoratorConstants.appVersionNumberDecorator(el?.metadata),
        appVersion: el?.metadata?.version,
        appVersionCode: el?.metadata?.versionCode,
        uploadedDate: DecoratorConstants.uploadedDateDecorator(el.uploadedDate),
      })));

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    try {
      if (TestProjectType[testProject?.type] && testProject?.id) {
        setIsLoading(true);
        const [
          testSuitesRecursively,
          nativeApps,
          executionProfiles,
          localAgents,
          circleCIAgents,
          k8sAgents,
        ] = await Promise.all([
          getTestSuitesRecursively(testProject),
          !MFlags.testCloudDisableTCFromScheduleDialog ? getNativeApps() : Promise.resolve([]),
          getExecutionProfileRecursively(testProject),
          getAgents(MContext.teamId),
          getCircleCIAgents(MContext.teamId),
          getK8SAgents(MContext.teamId)
        ]);
        setTestSuites(testSuitesRecursively);
        setApps(nativeApps);
        setExecutionProfiles(executionProfiles);
        setIsLoading(false);
        setLocalAgents(localAgents);
        setCircleCIAgents(circleCIAgents);
        setK8sAgents(k8sAgents);
      }
    } catch (err) {
      Notification.pushError('Failed to fetch data');
      setIsLoading(false);
    }
  }, [testProject]);


  const refresh = async () => {
    try {
      if (testProject?.id) {
        track(refreshTsEvent(trackId));
        await Services.refreshTSC(testProject.id);
        const testSuites = await getTestSuitesRecursively(testProject);
        setTestSuites(testSuites || []);
      }
    } catch (err) {
      Notification.pushError('Failed to refresh the script repository');
      setIsLoading(false);
    }
  };

  return (
    <TestSuiteRunConfiguration
      testSuites={testSuites}
      isLoading={isLoading}
      {...props}
      apps={apps}
      executionProfiles={executionProfiles}
      localAgents={localAgents}
      onRefresh={refresh}
      circleCIAgents={circleCIAgents}
      k8sAgents={k8sAgents}
    />
  );
}

export default TsRunConfigurationContainer;
