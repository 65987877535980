import { matchPath } from 'react-router-dom';
import { AppType } from './Constants';

const home_organization = '/organization/:organizationId/home';

const team = '/team/:teamId';

const katalon_manage = '/organization/:organizationId/admin';

const organization_setting = '/organization/:organizationId/settings';

const organization = '/organization/:organizationId/teams';

const project = `${team}/project/:projectId`;

const execution_details = `${project}/executions/:executionId`;

const release_view = `${project}/releases/:releaseId`;

const build_view = `${release_view}/builds/:buildId`;

const execution_test_result_details = `${execution_details}/test-result/:executionTestResultId`;

const manage = '/user';

const admin = `${team}/admin`;

const execution_test_suite_details = `${execution_details}/test-suite/:executionTestSuiteId`;

const test_design = `${project}/test-design`;

const keyes = `${project}/katalon-eyes`;

const test_report_test_maintenance = `${project}/test-reports/test-maintenance`;

const test_report_test_execution = `${project}/test-reports/test-execution`;

const run_configuration = `${project}/grid/plan/:runConfigurationId/job`;

const autonomous = `${project}/autonomous`;

const user_journeys = `${autonomous}/user-journeys`;

const truetest = `${project}/truetest`;

const truetest_user_journeys = `${truetest}/user-journeys`;

const RouteConstants = {

  katalon_manage,

  organization_setting,
  organization_setting_agents: `${organization_setting}/agent`,
  organization_setting_k8s_agents: `${organization_setting}/agent/k8s`,
  organization_setting_circle_ci_agents: `${organization_setting}/agent/circle-ci`,
  organization_setting_test_cloud_agents: `${organization_setting}/agent/test-cloud`,
  organization_setting_test_env_installation: `${organization_setting}/test-environment-installation`,
  organization_setting_test_env_docker_agent_installation: `${organization_setting}/test-environment-installation/docker-agent`,

  organization_setting_truetest: `${organization_setting}/truetest`,
  organization_setting_truetest_detail: `${organization_setting}/truetest/:applicationUnderTestId`,

  team,
  dashboard: `${team}/dashboard`,

  home_organization,
  create_organization_home: '/home/create-organization',

  organization,
  organization_settings: `${katalon_manage}/settings`,
  organization_trial_request: `${katalon_manage}/trial-request`,
  organization_users: `${katalon_manage}/users`,
  organization_manage_projects: `${katalon_manage}/manage_projects`,
  organization_manage_archived_projects: `${katalon_manage}/manage_projects/archived`,
  organization_users_invite: `${katalon_manage}/users/invite`,
  organization_users_manage: `${katalon_manage}/users/:managedUserId`,
  organization_teams: `${organization}/settings`,
  organization_projects: `${organization}/projects`,
  organization_license_keys: `${katalon_manage}/license_keys`,
  organization_kse_timeout: `${katalon_manage}/kse_timeout`,
  organization_kse_license_keys: `${katalon_manage}/license_keys/kse`,
  organization_unlimited_kse_license_keys: `${katalon_manage}/license_keys/unlimited_kse`,
  organization_engine_license_keys: `${katalon_manage}/license_keys/engine`,
  organization_unlimited_engine_license_keys: `${katalon_manage}/license_keys/unlimited_engine`,
  organization_floating_engine_license_keys: `${katalon_manage}/license_keys/floating_engine`,
  organization_test_cloud_license_keys: `${katalon_manage}/license_keys/test_cloud`,
  organization_testops_license_keys: `${katalon_manage}/license_keys/testops`,
  organization_creat_license_keys: `${katalon_manage}/license_keys/:licenseFeature/create`,
  organization_plugins: `${katalon_manage}/plugins`,
  organization_configuration: `${katalon_manage}/configuration`,
  organization_subscription: `${katalon_manage}/subscriptions`,
  organization_subscription_edit: `${katalon_manage}/subscriptions/edit`,
  organization_new_orders: `${katalon_manage}/new-orders`,
  organization_new_orders_quote: `${katalon_manage}/new-orders/quote`,
  organization_upgrade_order: `${katalon_manage}/upgrade-order/:enterpriseProductId`,
  organization_upgrade_order_quote: `${katalon_manage}/upgrade-order/:enterpriseProductId/quote`,
  organization_payment_success: `${katalon_manage}/payment-success`,
  organization_payment_method: `${katalon_manage}/payment-method`,
  organization_checkout_quote: '/checkout-quote',
  checkout_quote_payment_success: '/checkout-quote/payment-success',
  testops_checkout_quote_payment_success: '/testops/checkout-quote/payment-success',
  organization_add_card: `${katalon_manage}/add-card`,
  organization_testops: `${organization}/testops`,
  organization_session: `${katalon_manage}/session`,

  testops_subscription: `${katalon_manage}/testops`,
  testops_subscription_checkout: `${katalon_manage}/testops/checkout`,
  testops_subscription_quote: `${katalon_manage}/testops/quote`,
  testops_subscription_checkout_quote: '/testops/checkout-quote',

  project,
  project_setting: `${project}/settings`,
  executions: `${project}/executions`,
  executions_compare: `${project}/executions/compare`,

  overview: `${project}/overview`,
  failed_test_result: `${project}/failed-test-result`,

  import_manual_reports: `${project}/import-manual-reports`,

  test_design,
  test_cases: `${test_design}/test-cases`,
  test_cases_search: `${test_design}/test-cases/search`,
  test_suites: `${test_design}/test-suites`,
  test_suites_search: `${test_design}/test-suites/search`,

  keyes,
  keyes_execution_details: `${keyes}/:executionId`,
  keyes_execution_detail_comment: `${keyes}/:executionId/comments`,
  keyes_execution_details_checkpoints: `${keyes}/:executionId/checkpoints`,
  baseline_collection: `${project}/baseline-collection`,
  baseline_collection_current: `${project}/baseline-collection/:screenshotId`,
  baseline_collection_history: `${project}/baseline-collection/:screenshotId/previous-baselines`,
  baseline_collection_group_detail: `${project}/baseline-collection/:baselineCollectionGroupOrder`,
  baseline_collection_group_history: `${project}/baseline-collection/:baselineCollectionGroupOrder/version`,

  autonomous,
  user_journeys,
  truetest,
  truetest_user_journeys,

  test_projects: `${project}/test-project`,
  test_project: `${project}/test-project/:testProjectId`,
  test_project_edit: `${project}/test-project/:testProjectId/edit`,
  test_project_git_edit: `${project}/test-project/:testProjectId/git/edit`,
  test_project_creation: `${project}/test-project/create`,
  test_project_git_creation: `${project}/test-project/create/git`,
  git_test_project_setup: `${project}/git-test-project/setup`,

  jobs: `${project}/jobs`,
  web_services: `${project}/web-services`,
  web_services_anomalies: `${project}/web-services-anomalies`,
  test_object_entities: `${test_design}/test-objects`,
  test_object_entity_details: `${test_design}/test-objects/:testObjectEntityId`,

  test_reports: `${project}/test-reports`,
  test_report_test_design: `${project}/test-reports/test-design`,
  test_report_requirement_test_run: `${project}/test-reports/test-design/requirement-test-run-coverage`,
  test_report_traceability: `${project}/test-reports/test-design/traceability-report`,

  test_report_test_execution,
  test_report_execution: `${test_report_test_execution}/execution-report`,
  test_report_test_performance: `${test_report_test_execution}/test-performance`,
  test_report_frequency: `${test_report_test_execution}/frequency-report`,

  test_report_test_maintenance,
  test_report_flakiness: `${test_report_test_maintenance}/flakiness-report`,
  test_report_platform_statistics: `${test_report_test_maintenance}/platform-statistics`,
  test_cases_active: `${test_report_test_maintenance}/active`,
  test_cases_stale: `${test_report_test_maintenance}/stale`,
  test_cases_slowest: `${test_report_test_maintenance}/slowest`,
  // test_report: `${project}/test-reports/:testReportId`,

  ci_dashboard: `${project}/ci`,
  grid: `${project}/grid`,
  agent: `${project}/agent`,
  agent_details: `${project}/agent/local/:agentId`,
  organization_agent_details: `${organization_setting}/agent/local/:agentId`,

  test_env_installation: `${project}/test-environment-installation`,

  k8s_agents: `${project}/agent/k8s`,
  k8s_agent: `${project}/agent/k8s/:k8sAgentId`,
  k8s_agent_create: `${project}/agent/k8s/create`,
  organization_k8s_agent: `${organization_setting}/agent/k8s/:k8sAgentId`,
  organization_k8s_create: `${organization_setting}/agent/k8s/create`,

  circle_ci_agents: `${project}/agent/circle-ci`,
  circle_ci_agent: `${project}/agent/circle-ci/:circleCIAgentId`,
  circle_ci_agent_create: `${project}/agent/circle-ci/create`,
  organization_circle_ci_agent: `${organization_setting}/agent/circle-ci/:circleCIAgentId`,
  organization_ci_agent_create: `${organization_setting}/agent/circle-ci/create`,

  test_cloud_agents: `${project}/agent/test-cloud`,
  test_cloud_agent: `${project}/agent/test-cloud/:testCloudAgentId`,
  test_cloud_tunnel: `${project}/testCloudTunnel`,

  requirements: `${project}/requirements`,
  requirement_details: `${project}/requirements/:requirementId`,
  defects: `${project}/defects`,

  project_dashboard: `${project}/dashboard`,

  releases: `${project}/releases`,
  release_create: `${project}/releases/create`,

  release_view,
  release_edit: `${release_view}/edit`,
  release_executions_details: `${release_view}/executions`,
  release_run_configuration_details: `${release_view}/plans`,

  build_create: `${release_view}/builds/create`,
  build_view,
  build_edit: `${build_view}/edit`,

  run_configuration,
  run_configuration_session: `${run_configuration}/session`,
  run_configuration_summary: `${run_configuration}/summary`,

  job: `${project}/grid/plan/:runConfigurationId/job/:jobOrder`,
  configure_plans: `${project}/grid/plan`,
  configure_plan: `${project}/grid/plan/:runConfigurationId`,
  sample_plan: `${project}/grid/plan/sample`,
  test_plans: `${project}/test-plans`,
  scheduler_create: `${project}/grid/plan/:runConfigurationId/schedule/`,
  test_runs: `${project}/test-runs`,
  test_planning: `${project}/test-planning`,
  native_apps: `${project}/native-apps`,

  mobile_referral_link: '/mobile-referral-link',

  priorities: `${project}/test-reports/priorities`,

  execution_details,
  execution_file_details: `${execution_details}/files`,
  execution_test_run_details: `${execution_details}/test-runs`,
  execution_test_object_details: `${execution_details}/test-objects`,
  execution_analysis_details: `${execution_details}/analysis`,
  execution_defect: `${execution_details}/defect`,

  test_case_overview: `${project}/test-design/test-cases/:testCaseId/overview`,
  test_case_steps: `${project}/test-design/test-cases/:testCaseId/steps`,
  test_case_local_variables: `${project}/test-design/test-cases/:testCaseId/local-variables`,
  test_case_edit: `${project}/test-design/test-cases/:testCaseId/edit`,
  test_suite_edit: `${project}/test-design/test-suites/:testSuiteId/edit`,

  execution_test_result_details,

  execution_request_detail: `${execution_test_result_details}/requests/:executionRequestId`,
  execution_test_result_requests: `${execution_test_result_details}/requests`,
  execution_test_result_test_objects: `${execution_test_result_details}/test-objects`,
  execution_test_result_treeviewer_link: `${execution_test_result_details}/tree-viewer`,
  execution_test_result_comment: `${execution_test_result_details}/comments`,
  execution_test_result_rerun: `${execution_test_result_details}/re-runs`,
  execution_test_result_similar_failures: `${execution_test_result_details}/similar-failures`,

  execution_test_result_history: `${test_design}/test-cases/:testCaseId`,
  execution_test_suite_history: `${test_design}/test-suites/:testSuiteId`,
  execution_test_object_history: `${project}/web-services/:testObjectId`,

  execution_test_suite_details,
  execution_test_suite_test_runs: `${execution_test_suite_details}/test-runs`,
  execution_test_suite_context_variables: `${execution_test_suite_details}/environments`,
  execution_test_suite_comment: `${execution_test_suite_details}/comments`,

  incidents: `${test_design}/tasks`,
  incident: `${test_design}/tasks/:incidentOrder/view`,
  incident_create: `${test_design}/tasks/create`,
  incident_edit: `${test_design}/tasks/:incidentOrder/edit`,

  jira_setting: `${project}/jira-setting`,
  kobiton_setting: `${project}/kobiton-setting`,
  slack_setting: `${project}/slack-setting`,
  analysis_settings: `${project}/analysis-settings`,

  login: '/login',
  logout: '/logout',
  startup: '/startup',
  startup_activate: '/startup/activate',
  startup_create_account: '/startup/create-account',
  accept_invitation: '/accept-invitation',
  accept_sso: '/accept-sso',
  reset_password: '/reset-password',
  notification: '/notification/:notificationId',
  survey_completed: '/survey-completed',
  no_execution_history: '/no-execution-history',
  welcome: '/welcome',
  way_to_start: `${project}/way-to-start`,
  welcome_download_ks: '/welcome/download-ks',
  home: '/home',
  profile: `${manage}/profile`,
  user_settings: `${manage}/settings`,
  change_password: `${manage}/profile/change-password`,
  user_license: `${manage}/license`,
  user_license_create: `${manage}/license/create/:licenseFeature`,
  apikey: `${manage}/apikey`,
  assign_subscription: `${manage}/assign-subscription`,
  katalon_recorder_backup: `${manage}/katalon-recorder-backup`,
  user_integration: `${manage}/integrations`,

  admin_teams: `${manage}/teams`,
  admin_users: `${admin}/users`,
  admin_users_invite: `${admin}/users/invite`,
  admin_projects: `${admin}/project`,
  admin_team_settings: `${admin}/settings`,

  integrations: `${project}/integrations`,
  upload_katalon_reports: `${project}/upload-katalon-reports`,
  frameworks_integration: `${project}/frameworks-integration`,
  quick_guide: `${project}/quick-guide`,
  error_sso: '/error-sso',
  report_uploader_guide: `${project}/report-uploader`,

  download: `${project}/download/:fileHandleId`,
  download_ks: '/download',
  try_visual_testing: '/try-visual-testing',

  custom_fields: `${project}/custom-fields`,
  artificial_intelligence: `${project}/artificial-intelligence`,

  manage,
  subscriptionAndPaymentMethodRoutes() {
    return [
      this.organization_subscription,
      this.organization_subscription_edit,
      this.organization_trial_request,
      this.organization_new_orders,
      this.organization_new_orders_quote,
      this.organization_upgrade_order,
      this.organization_upgrade_order_quote,
      this.organization_payment_method,
      this.testops_subscription,
      this.testops_subscription_checkout,
      this.testops_subscription_quote,
    ];
  },

  testExecutionPaths() {
    return [
      this.test_planning,
      this.native_apps,
      this.grid,
      this.test_runs,
    ];
  },

  getAppType(url) {

    // const visionRoutes = [
    //   this.keyes,
    //   this.baseline_collection,
    // ];
    //
    // for (let i = 0; i < visionRoutes.length; i++) {
    //   const path = visionRoutes[i];
    //   const match = matchPath(url, { path });
    //   if (match) {
    //     return AppType.KEYES;
    //   }
    // }

    const gridRoutes = [
      this.ci_dashboard,
      this.jobs,
    ];

    for (let i = 0; i < gridRoutes.length; i++) {
      const path = gridRoutes[i];
      const match = matchPath(url, { path });
      if (match) {
        return AppType.CI;
      }
    }

    const reportsRoutes = [
      this.executions,
      this.test_reports,
      this.import_manual_reports,
      this.defects,
      this.overview,
      this.keyes,
      this.baseline_collection,
      this.failed_test_result
    ];

    for (const path of reportsRoutes) {
      if (matchPath(url, { path })) {
        return AppType.REPORTS;
      }
    }

    const configurationRoutes = [
      this.project_setting,
      this.test_projects,
      this.slack_setting,
      this.kobiton_setting,
      this.jira_setting,
      this.agent,
      this.test_env_installation,
      this.frameworks_integration,
      this.upload_katalon_reports,
      this.report_uploader_guide,
      this.integrations,
      this.test_cloud_tunnel,
      this.git_test_project_setup,
      this.custom_fields,
      this.artificial_intelligence,
    ];

    for (const path of configurationRoutes) {
      if (matchPath(url, { path })) {
        return AppType.CONFIGURATIONS;
      }
    }

    const testPlanningRoutes = [
      this.releases,
      this.requirements,
    ];

    for (const path of testPlanningRoutes) {
      if (matchPath(url, { path })) {
        return AppType.TEST_PLANNING;
      }
    }

    const centerRoutes = [
      this.test_cases,
      this.test_suites,
      this.web_services,
      this.web_services_anomalies,
      this.test_object_entities,
    ];

    for (const path of centerRoutes) {
      if (matchPath(url, { path })) {
        return AppType.CENTER;
      }
    }

    const visuaTestingRoutes = [
      this.keyes,
      this.baseline_collection,
    ];

    for (const path of visuaTestingRoutes) {
      if (matchPath(url, { path })) {
        return AppType.KEYES;
      }
    }

    const testExecution = [
      this.test_runs,
      this.test_planning,
      this.grid,
      this.native_apps
    ];

    for (const path of testExecution) {
      if (matchPath(url, { path })) {
        return AppType.TEST_EXECUTION;
      }
    }

    return AppType.OTHERS;
  },

  isNewOrdersPage(url) {
    const routes = [
      this.organization_new_orders,
      this.organization_checkout_quote,
      this.testops_subscription_checkout_quote,
      this.testops_checkout_quote_payment_success,
      this.checkout_quote_payment_success,
    ];
    for (let i = 0; i < routes.length; i++) {
      const path = routes[i];
      const match = matchPath(url, { path, exact: true });
      if (match) {
        return true;
      }
    }
    return false;
  }
};

export default RouteConstants;
