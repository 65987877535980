import Autocomplete from '@mui/material/Autocomplete';
import React, { useState } from 'react';
import { includes, trim } from 'lodash';
import PropTypes from 'prop-types';
import TextField from './TextField';
import { t } from '../i18n/t';
import MContext from '../models/MContext';
import { MAX_PAGE_SIZE, SearchEntity } from '../utils/Constants';
import { buildSearchCondition } from './search/SearchUtils';
import Services from '../utils/Services';

const TagInput = ({ handleOnSelectChange, selectedTags, enableFetchTagsEachTimeOpen }) => {

  const [tags, setTags] = useState([]);
  const [newTag, setNewTag] = useState();
  const [isLoading, setLoading] = useState(false);
  const [alreadyGetTag, setAlreadyGetTag] = useState(false);

  const projectId = MContext.projectId;

  const getAllTags = () => {
    setLoading(true);
    const params = {
      pagination: {
        page: 0,
        size: MAX_PAGE_SIZE,
        sorts: ['id, desc'],
      },
      conditions: [
        buildSearchCondition('Project.id', '=', projectId)
      ],
      type: SearchEntity.Tag,
      ignoreAutoFill: true,
    };

    Services.searchRecursively(0, params, [])
      .then((content) => {
        setTags(content);
        setLoading(false);
      });
  };

  const onOpen = () => {
    if (!alreadyGetTag) {
      getAllTags();
      setAlreadyGetTag(true);
    }
  };

  const onClose = () => {
    if (enableFetchTagsEachTimeOpen) {
      setAlreadyGetTag(false);
    }
  };

  const handleInputChange = (event, value) => {
    setNewTag(trim(value));
  };

  const handleSelectChange = (event, value, reason) => {
    if (reason !== 'removeOption' && reason !== 'createOption') {
      handleOnSelectChange(value).then(() => getAllTags());
    }
  };

  const allTags = [...tags];
  if (!!newTag && allTags.filter((e) => e.name === newTag).length === 0) {
    allTags.push(
      {
        name: newTag,
        label: `${newTag} (new tag)`,
        isNewTag: true,
        projectId,
      }
    );
  }

  return (
    <Autocomplete
      id="tagEntity"
      multiple
      filterSelectedOptions
      value={selectedTags}
      onOpen={onOpen}
      onClose={onClose}
      filterOptions={(options, state) =>
        options.filter((option) => includes(option.name, trim(state.inputValue)))}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={t('tag#select-tags')}
        />
      )}
      disableClearable
      isOptionEqualToValue={(option, value) => option.name === value.name}
      groupBy={() => t('tag#all-tags')}
      options={allTags}
      getOptionLabel={(option) => option.name}
      renderOption={(props, option) => (
        <li {...props}>
          <div title={option.label || option.name}>
            {option.label || option.name}
          </div>
        </li>
      )}
      renderTags={() => null}
      onChange={handleSelectChange}
      onInputChange={handleInputChange}
      autoHighlight
      loading={isLoading}
    />
  );
};

TagInput.defaultProps = {
  enableFetchTagsEachTimeOpen: false,
};

TagInput.prototype = {
  enableFetchTagsEachTimeOpen: PropTypes.bool,
};

export default TagInput;
