import MConfigs from '../../models/MConfigs';
import LaunchDarklyHelper from '../LaunchDarklyHelper';
import OnPremiseFlags from './OnPremiseFlags';

const FlagHelper = {

  /**
   * Get all flags
   * Cloud: use Launch Darkly
   * On-premise: get flags from OnPremise Flags
   * @return { Flags }
   */
  allFlags : () => {
    if (MConfigs.isOnPremise) {
      return OnPremiseFlags;
    } else {
      return LaunchDarklyHelper.allFlags();
    }
  },


  /**
   * Get all flags by Launch Darkly account's client
   * Cloud: use Launch Darkly
   * On-premise: get flags from OnPremise Flags
   * @return { Flags }
   */
  allAccountFlags: () => {
    if (MConfigs.isOnPremise) {
      return OnPremiseFlags;
    } else {
      return LaunchDarklyHelper.allAccountFlags();
    }
  },

  /**
   * When change organization in TestOps, notification for Launch Darkly this action.
   * On-premise: Do nothing.
   * @param organizationId
   * @returns {Promise}
   */
  changeOrganization: (organizationId) => {
    if (MConfigs.isOnPremise) {
      return new Promise(resolve => resolve());
    } else {
      return LaunchDarklyHelper.changeOrg(organizationId);
    }
  },


  /**
   * When change account in TestOps
   * @param accountId
   * @returns {Promise}
   */
  changeAccount: (accountId) => {
    if (MConfigs.isOnPremise) {
      return new Promise(resolve => resolve());
    } else {
      return LaunchDarklyHelper.changeAccount(accountId);
    }
  },

  /**
   * Close client.
   * Cloud: use Launch Darkly, need close client in componentWillUnmount
   * On-premise: Do nothing.
   */
  dispose: () => {
    if (!MConfigs.isOnPremise) {
      LaunchDarklyHelper.dispose();
    }
  }
}

export default FlagHelper;