import React, { useEffect, useState, forwardRef, useImperativeHandle, useRef } from 'react';
import { get } from 'lodash';
import { Button } from 'reactstrap';
import { Typography } from '@mui/material';
import { buildSearchFunction } from '../../../components/search/SearchUtils';
import MTableColumnDataMapping from '../../../components/table/models/MTableColumnDataMapping';
import { t } from '../../../i18n/t';
import { SearchEntity } from '../../../utils/Constants';
import {
  getDefaultTestResultExceptionSearchConditions,
  getExceptionClass,
  getExceptionMessage,
  getTestResultSearchConditions_old,
  getTestResultSearchConditions,
  getSearchConditionsForFailedTestResultPage,
} from './utils';
import { IconViewSidebarActive } from '../../../images/CustomIcon';
import InfoSidebar from '../../../utils/InfoSidebar';
import TestResultDataTable from './TestResultDataTable';
import DataLoader from '../../../components/table/DataLoader';
import TableSortCore from '../../../components/table/models/TableSortCore';
import TooltipComponent from '../../../components/TooltipComponent';
import Apis from '../../../utils/Apis';
import ExecutionTestResultExceptionDialog from '../../../components/dialog/ExecutionTestResultExceptionDialog';
import { buildSortQuery } from '../../../components/search-query/SortQueryHelper';
import MFlags from '../../../models/MFlags';

const TopNExceptionsDataTable = forwardRef(({
  numberOfExceptions,
  dataLoaderRef,
  allFilterValue
}, ref) => {

  useImperativeHandle(ref, () => ({
    closeInfoSideBar() {
      if (InfoSidebar.isOpened) {
        InfoSidebar.close();
      }
    },
  }));

  useEffect(() => {
    if (InfoSidebar.isOpened) {
      InfoSidebar.close();
    }
  }, [allFilterValue]);

  const [activeException, setActiveException] = useState();
  const [isOpenExceptionDialog, setOpenExceptionDialog] = useState(false);

  const exceptionDialogRef = useRef();

  const exceptionClassDecorator = (name, row) => {
    const exception = get(row, name);
    const exceptionClass = getExceptionClass(exception);
    return (
      <TooltipComponent text={exceptionClass} placement="bottom">
        <span>
          {exceptionClass}
        </span>
      </TooltipComponent>);
  };

  const exceptionDecorator = (name, row) => {
    const exception = get(row, name);
    const exceptions = getExceptionMessage(exception);
    return (
      <TooltipComponent text={exceptions} placement="bottom">
        <span>
          {exceptions}
        </span>
      </TooltipComponent>);
  };

  const setTabContent = (errorDetail, errorDetailsId) => {
    exceptionDialogRef.current.setTabContent([
      {
        title: t('errors'),
        file: {
          content: errorDetail,
          name: 'error-stack-trace.txt',
          type: 'text-log',
          source: Apis.executionTestResultLog(errorDetailsId),
        },
        customClass: 'text-danger',
      }]);
  };

  const handleOpenExceptionDialog = (errorDetail, errorDetailsId) => {
    setOpenExceptionDialog(true);
    setTabContent(errorDetail, errorDetailsId);
  };

  const handleCloseExceptionDialog = () => {
    setOpenExceptionDialog(false);
  };

  const numberOfTestResultDecorator = (name, row) => {

    const buildTestResultSlideDOM = (exception) => {
      if (!exception) return null;
      let defaultSearchConditions;
      if (MFlags.revampFailedTestResultEnabled) {
        defaultSearchConditions = getTestResultSearchConditions(exception, ...Object.values(allFilterValue));
      } else {
        defaultSearchConditions = getTestResultSearchConditions_old(exception, ...Object.values(allFilterValue));
      }
      return (
        <div>
          <TestResultDataTable
            defaultSearchConditions={defaultSearchConditions}
            customFieldConditionsProp={allFilterValue.customField}
            openExceptionDialog={handleOpenExceptionDialog}
          />
        </div>
      );
    };

    const buildSideBarTitle = (exception) => {
      const exceptionClass = getExceptionClass(exception);
      const exceptionCause = getExceptionMessage(exception);
      return (
        <div>
          <Typography variant="h5" color="secondary">{exceptionClass}</Typography>
          <div>{exceptionCause}</div>
        </div>
      );
    };

    const toggleShowTestResultsByExceptionOnSlideBar = (exception) => {
      if (exception === activeException) {
        if (InfoSidebar.isOpened) {
          InfoSidebar.close();
        }
        setActiveException(null);
      } else {
        const title = buildSideBarTitle(exception);
        const testResultTableDOM = buildTestResultSlideDOM(exception);
        setActiveException(exception);
        InfoSidebar.show(title, testResultTableDOM);
        InfoSidebar.onClose(() => {
          setActiveException(null);
        });
      }
    };
    const numberOfTestResults = get(row, name);

    const exception = get(row, 'content.exception');

    return (
      <Button
        onClick={() => toggleShowTestResultsByExceptionOnSlideBar(exception)}
        color="link"
      >
        <IconViewSidebarActive className="mr-1" />{t('top-exception#table-cell#number-of-results', { numberOfTestResults })}
      </Button>
    );
  };

  const columnMapping = [
    new MTableColumnDataMapping(t('top-exception#table-title#index'), 'index', undefined, undefined, 'fit-content-colum', undefined, '3.85rem'),
    new MTableColumnDataMapping(t('top-exception#table-title#exception-class'), 'content.exception', exceptionClassDecorator, undefined, '', undefined, '36%'),
    new MTableColumnDataMapping(t('top-exception#table-title#exceptions'), 'content.exception', exceptionDecorator),
    new MTableColumnDataMapping(t('top-exception#table-title#number-of-results'), 'content.testResults', numberOfTestResultDecorator, undefined, '', undefined, '15%')
  ];

  const renderHeader = () => {
    if (MFlags.revampFailedTestResultEnabled) {
      return (
        <div className="failed-test-result-header-title mb-0">
          {t('top-exception#header-title-all-exceptions')}
        </div>
      );
    } else {
      return (
        <div className="failed-test-result-header-title">
          {t('top-exception#header-title', { numberOfExceptions })}
        </div>
      );
    }
  };

  const renderTable = (data) => {
    data = data.map((value, index) => ({
      ...value,
      index: index + 1,
    }));
    return (
      <TableSortCore data={data} columnMapping={columnMapping} />
    );
  };


  const renderBody = () => {
    let defaultSearchConditions;
    if (MFlags.revampFailedTestResultEnabled) {
      defaultSearchConditions = getSearchConditionsForFailedTestResultPage(...Object.values(allFilterValue));
    } else {
      defaultSearchConditions = getDefaultTestResultExceptionSearchConditions(...Object.values(allFilterValue));
    }
    const key = Object.values(allFilterValue).join('');
    const dynamicProps = MFlags.revampFailedTestResultEnabled ?
      {
        sortQuery: [...buildSortQuery('testResults', t('top-exception#table-title#number-of-results'))],
        pageSize: 15,
        useSearchQuery: true,
        useSortAndPaginationOnly: true,
        unitName: 'exception'
      } :
      {
        hidePaging: true,
        pageSize: 10
      };
    return (
      <DataLoader
        key={key}
        entityType={SearchEntity.ExecutionTestResultException}
        columnMapping={columnMapping}
        ref={dataLoaderRef}
        useSortByColumn
        noCard
        defaultSearchConditions={defaultSearchConditions}
        defaultSearchFunctions={[buildSearchFunction('testResults', 'count', ['id'])]}
        customFieldConditionsProp={allFilterValue.customField}
        groupBys={['exception']}
        defaultSort={['testResults, desc']}
        render={renderTable}
        {...dynamicProps}
      />
    );
  };

  return (
    <>
      {renderHeader()}
      {renderBody()}
      <ExecutionTestResultExceptionDialog
        id="execution-exception-dialog"
        isOpen={isOpenExceptionDialog}
        onClose={handleCloseExceptionDialog}
        ref={exceptionDialogRef}
      />
    </>
  );
});

export default TopNExceptionsDataTable;
