import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import React, { Component } from 'react';

/**
 * The scroll is not blocked with PopperMenu
 * The placement of the popper updates with the available area in the viewport.
 */
export default class PopperMenu extends Component {

  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
    this.anchorRef = React.createRef();
    this.handleClose = this.handleClose.bind(this);
    this.handleListKeyDown = this.handleListKeyDown.bind(this);
    this.handleToggle = this.handleToggle.bind(this);
  }

  componentDidUpdate(_, prevState) {
    const { open } = this.state;
    if (this.props.onOpen && prevState.open === false && open === true) {
      this.props.onOpen();
    }
  }

  componentDidMount() {
    const { autoOpen } = this.props;
    this.setState({
      open: autoOpen,
    });
  }

  handleToggle() {
    this.setState((prevState) => ({
      open: !prevState.open
    }));
  }

  handleClose(event) {
    if (this.anchorRef.current && this.anchorRef.current.contains(event.target)) {
      return;
    }
    this.setState({
      open: false
    });
  }

  handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      this.setState({
        open: false
      });
    }
  }

  render() {
    const { renderAnchorElement, children, onOpen, maxHeight, maxWidth, customClassName, ...rest } = this.props;
    const { open } = this.state;
    return (
      <>
        {renderAnchorElement({ ref: this.anchorRef, onClick: this.handleToggle })}
        <Popper {...rest} open={open} anchorEl={this.anchorRef.current} placement="bottom-start" role={undefined} transition>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                maxWidth,
                maxHeight,
                overflow: 'auto'
              }}
            >
              <Paper elevation={5}>
                <ClickAwayListener onClickAway={this.handleClose}>
                  <MenuList className={customClassName} variant="menu" autoFocusItem={open} onKeyDown={this.handleListKeyDown}>
                    {children}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </>
    );
  }
}

PopperMenu.defaultProps = {
  maxHeight: '19rem',
  maxWidth: '35rem',
  customClassName: '',
  autoOpen: false,
};

