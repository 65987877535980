import React from 'react';
import BaseKSELicensesTab from './BaseKSELicensesTab';
import { t } from '../../i18n/t';
import { OrganizationFeature } from '../../utils/Constants';

class OrganizationUnlimitedKSELicensesTab extends BaseKSELicensesTab {
  constructor(props) {
    super({ ...props, feature: OrganizationFeature.UNLIMITED_KSE });
    this.meta.id = 'page-organization-unlimited-kse-licenses';
    this.meta.title = t('unlimited_kse_license_keys');
  }
}

export default OrganizationUnlimitedKSELicensesTab;
