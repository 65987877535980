import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { t } from '../../i18n/t';
import Notification from '../../utils/Notification';
import Services from '../../utils/Services';

class DeleteBuildDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.toggle = this.toggle.bind(this);
    this.removeBuild = this.removeBuild.bind(this);
  }

  getInitialState() {
    return {
      modal: false,
    };
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  removeBuild() {
    const { build, afterDeleteBuild } = this.props;

    Services.removeBuild(build.id)
      .then(() => {
        Notification.pushSuccess(`The build ${build.name} has been removed.`);

        if (afterDeleteBuild) {
          afterDeleteBuild();
        }

        this.toggle();
      })
      .catch(() => this.toggle());
  }

  render() {
    const { modal } = this.state;
    const { build } = this.props;

    if (build) {
      return (
        <>
          <Modal isOpen={modal} toggle={this.toggle}>
            <ModalHeader toggle={this.toggle}>{t('build#remove')}</ModalHeader>
            <ModalBody>
              This action cannot be undone. Are you sure you want to delete <strong>{build.name}</strong>?
            </ModalBody>
            <ModalFooter>
              <Button
                data-trackid="delete-agent"
                color="danger"
                onClick={this.removeBuild}
              >
                {t('delete')}
              </Button>
              <Button color="secondary" onClick={this.toggle}>
                {t('cancel')}
              </Button>
            </ModalFooter>
          </Modal>
        </>
      );
    }
    return null;
  }
}

export default DeleteBuildDialog;
