import React from 'react';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';
import Tooltip from '@katalon-studio/katalon-ui/Tooltip';
import IconButton from '@katalon-studio/katalon-ui/IconButton';
import Button from '@katalon-studio/katalon-ui/Button';
import { styled, ThemeProvider } from '@mui/material/styles';
import ActionsHelper from '../../../utils/table/ActionsHelper';
import {
  ACTIONS,
  ACTIONS_TRACKING,
  DISPLAYED_PAGE_TRACKING,
  KATALON_EVENTS, OperationType, SINGLE_ACTION_ALLOWED
} from '../../../utils/Constants';
import { TestEntity } from '../../../models/model/TestEntity';
import PenIcon from '../../../../images/icons/katalonui/PenIcon';
import PenFieldIcon from '../../../../images/icons/katalonui/PenFieldIcon';
import ArrowTurnDownRightIcon from '../../../../images/icons/katalonui/ArrowTurnDownRightIcon';
import DeleteIcon from '../../../../images/icons/katalonui/DeleteIcon';
import CopyIcon from '../../../../images/icons/katalonui/CopyIcon';
import Routes from '../../../utils/Routes';
import { DomEventHandlers } from '../../../utils/EventHandler';
import PlayCircleIcon from '../../../../images/icons/katalonui/PlayCircleIcon';
import colors from '../../../../scss/colors.scss';
import { t } from '../../../i18n/t';
import LayerPlusIcon from '../../../../images/icons/katalonui/LayerPlusIcon';
import { isSystemTestSuite } from '../../../models/model/TestSuite';
import { katalonui_theme } from '../../../katalonui-theme';
import MoreActionsMenu from './MoreActionsMenu';
import { next } from '../../../utils/Count';
import ActionsMenuHandler from '../handler/ActionsMenuHandler';
import TestCaseService from '../service/TestCaseService';
import TestSuiteService from '../service/TestSuiteService';

const ActionsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: `0px ${theme.spacing(4)} ${theme.spacing(2)} ${theme.spacing(4)}`,
  columnGap: theme.spacing(1),
}));

function VerticalTestManagementActions(props: {
    data: TestEntity[];
}) {
  const { data } = props;
  const testEntity = data[0];
  const { testCase, testSuite } = testEntity;
  const actions = ActionsHelper.getAllowActions(testEntity);

  const singleActions = actions.filter((item: string) => SINGLE_ACTION_ALLOWED.includes(item));

  const bulkOperationActions = actions.filter((item: string) => !SINGLE_ACTION_ALLOWED.includes(item));

  const isSystemTs = testSuite ? isSystemTestSuite(testSuite) : false;

  const history = useHistory();

  const renameHandler = () => {
    ActionsMenuHandler.renameHandler(testEntity, OperationType.BULK);
  };

  const editHandler = () => {
    ActionsMenuHandler.editHandler(testEntity, OperationType.BULK);
  };

  const duplicateHandler = () => {
    if (testCase) {
      TestCaseService.duplicate(data);
      ActionsHelper.syncTrackingData(
        testEntity,
        ACTIONS_TRACKING.TEST_CASE_DUPLICATED,
        DISPLAYED_PAGE_TRACKING.ACTION_MENU,
        undefined,
        OperationType.BULK
      );
    } else if (testSuite) {
      TestSuiteService.duplicate(data);
      ActionsHelper.syncTrackingData(
        testEntity,
        ACTIONS_TRACKING.TEST_SUITE_DUPLICATED,
        DISPLAYED_PAGE_TRACKING.ACTION_MENU,
        undefined,
        OperationType.BULK
      );
    }
  };

  const moveHandler = () => {
    ActionsMenuHandler.moveHandler(data, OperationType.BULK);
  };

  const deleteHandler = () => {
    let displayPageTracking;
    if (testCase) {
      displayPageTracking = DISPLAYED_PAGE_TRACKING.TES_CASE_TABLE;
      ActionsHelper.syncTrackingData(
        testEntity,
        ACTIONS_TRACKING.DELETE_TEST_CASE_TRIGGERED,
        DISPLAYED_PAGE_TRACKING.ACTION_MENU,
        undefined,
        OperationType.BULK
      );
    } else if (testSuite) {
      displayPageTracking = DISPLAYED_PAGE_TRACKING.TEST_SUITE_TABLE;
      ActionsHelper.syncTrackingData(
        testEntity,
        ACTIONS_TRACKING.DELETE_TEST_SUITE_TRIGGERED,
        DISPLAYED_PAGE_TRACKING.ACTION_MENU,
        undefined,
        OperationType.BULK
      );
    }
    DomEventHandlers.createEvent(KATALON_EVENTS.openDeleteTestEntityDialog, { detail: { testEntities: data, displayPageTracking, operationType: OperationType.BULK } });
  };

  const runHandler = () => {
    if (testSuite) {
      ActionsHelper.syncTrackingData(
        testEntity,
        ACTIONS_TRACKING.SCHEDULE_TEST_RUN_TRIGGERED,
        DISPLAYED_PAGE_TRACKING.ACTION_MENU
      );
      const routes = new Routes();
      history.push(routes.smart_test_scheduling_link({
        testProjectId: testSuite.testProject?.id,
        testSuiteId: testSuite.id
      }));
    } else if (testCase) {
      ActionsHelper.syncTrackingData(
        testEntity,
        ACTIONS_TRACKING.INSTANT_RUN_TRIGGERED,
        DISPLAYED_PAGE_TRACKING.TES_CASE_TABLE
      );
      DomEventHandlers.createEvent(KATALON_EVENTS.openConfigureTestRunDialog, { detail: { testCase, displayPageTracking: DISPLAYED_PAGE_TRACKING.TES_CASE_TABLE } });
    }
  };

  const addToTestSuiteHandler = () => {
    ActionsMenuHandler.addToTestSuiteHandler(data, OperationType.BULK);
  };

  const actionWithHandler = (actions: string[]) => actions.map((action: string) => {
    switch (action) {
      case ACTIONS.RUN:
        return {
          action,
          handler: runHandler,
          icon: <PlayCircleIcon />,
          divider: actions.length > 1,
          isDisabled: data.length > 1,
        };
      case ACTIONS.RENAME:
        return {
          action,
          handler: renameHandler,
          icon: <PenFieldIcon color={!isSystemTs && colors.gray9} />,
          isDisabled: isSystemTs || data.length > 1
        };
      case ACTIONS.EDIT:
        return {
          action,
          handler: editHandler,
          icon: <PenIcon color={!isSystemTs && colors.gray9} />,
          isDisabled: isSystemTs || data.length > 1
        };
      case ACTIONS.DUPLICATE:
        return {
          action,
          handler: duplicateHandler,
          icon: <CopyIcon color={!isSystemTs && colors.gray9} type="fa-solid" />,
          isDisabled: isSystemTs
        };
      case ACTIONS.MOVE:
        return {
          action,
          handler: moveHandler,
          icon: <ArrowTurnDownRightIcon color={!isSystemTs && colors.gray9} />,
          isDisabled: isSystemTs
        };
      case ACTIONS.DELETE:
        return {
          action,
          handler: deleteHandler,
          icon: <DeleteIcon color={!isSystemTs && colors.red5} />,
          color: !isSystemTs ? 'error' : undefined,
          isDisabled: isSystemTs,
          label: t('delete')
        };
      case ACTIONS.ADD_TO_TEST_SUITE:
        return {
          action,
          handler: addToTestSuiteHandler,
          icon: <LayerPlusIcon />,
          color: 'secondary',
          label: t('addToTestSuite')
        };
      default:
        return {};
    }
  });

  const bulkOperationActionsWithHandler = actionWithHandler(bulkOperationActions);

  const singleActionsWithHandler = actionWithHandler(singleActions);

  return (
    <ThemeProvider theme={katalonui_theme}>
      <ActionsContainer>
        <span>{data.length} selected</span>
        {bulkOperationActionsWithHandler.map(
          (item: {
              icon?: React.ReactNode;
              action?: string;
              handler?: React.MouseEventHandler<HTMLButtonElement>;
              divider?: boolean;
              color?: string;
              isDisabled?: boolean;
              label?: string;
            }) => (
              <Tooltip
                key={next()}
                label={t(`test-management-actions#${item.action}`)}
                size="medium"
              >
                {bulkOperationActions.length === 1 ? (
                  <Button
                    variant="outlined"
                    size="small"
                    startIcon={React.cloneElement(item.icon as React.ReactElement, { color: 'secondary' })}
                    color={item.color as 'inherit' | 'error' | 'secondary' | 'primary' | 'success' | 'info' | 'warning' | undefined}
                    onClick={item.handler}
                    sx={{ marginLeft: '1rem' }}
                  >
                    {item.label}
                  </Button>
                ) :
                  <IconButton
                    size="small"
                    onClick={item.handler}
                  >
                    {item.icon}
                  </IconButton>}
              </Tooltip>
          )
        )}
        {singleActionsWithHandler.length > 0 && <MoreActionsMenu data={testEntity} actionsWithHandler={singleActionsWithHandler} />}
      </ActionsContainer>
    </ThemeProvider>
  );
}

VerticalTestManagementActions.propTypes = {
  data: PropTypes.object.isRequired
};

export default VerticalTestManagementActions;
