import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import makeStyles from '@mui/styles/makeStyles';
import colors from '../../../../scss/colors.scss';
import TooltipComponent from '../../../components/TooltipComponent';

const StyledItemContainer = styled.span`
  display: flex;
  padding: 8px;
  gap: 4px;
  align-items: center;
`;

const StyledText = styled.span`
  min-width: 14px;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.5;
  color: ${colors.darkBlue};
`;

const StyledIconBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
`;

const tooltipStyle = makeStyles(() => ({
  tooltip: {
    backgroundColor: '#1a2534',
    color: '#FFFFFF',
    fontWeight: 'normal',
    padding: '4px 8px',
    borderRadius: '4px',
    fontSize: '14px',
    lineHeight: '1.43'
  },
  arrow: {
    color: '#1a2534',
  }
}));

function SymbolicCounter({ item }) {
  return (
    <TooltipComponent
      text={item.tooltip}
      placement="bottom-start"
      arrow
      customClasses={tooltipStyle()}
    >
      <StyledItemContainer style={{ cursor: 'pointer' }}>
        <StyledIconBox>{item.icon}</StyledIconBox>
        <StyledText>{item.txt}</StyledText>
      </StyledItemContainer>
    </TooltipComponent>
  );
}

SymbolicCounter.propTypes = {
  item: PropTypes.shape({
    tooltip: PropTypes.string.isRequired,
    icon: PropTypes.node.isRequired,
    txt: PropTypes.string.isRequired,
  }),
};

export default SymbolicCounter;
