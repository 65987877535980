import React, { useState } from 'react';
import { Tabs, Tab } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Progress } from 'antd';
import { t } from '../../../i18n/t';
import OnboardingStepper from '../../../components/OnboardingStepper';
import TabPanel from '../../../components/tab/TabPanel';
import { OnboardingType } from '../../../utils/Constants';
import TooltipComponent from '../../../components/TooltipComponent';
import MFlags from '../../../models/MFlags';

const useStyles = makeStyles(() => ({
  tabs: {
    '& .MuiTab-textColorPrimary.Mui-selected': {
      color: '#1847ca'
    },
    '& .MuiTabs-indicator': {
      backgroundColor: '#1847ca'
    },
    borderBottom: '#d6dae4 1px solid',
  },
}));

const OnboardingChecklistBody = (props) => {
  const classes = useStyles();
  const [selectedTab, setSelectedTab] = useState(OnboardingType.KATALON_PLATFORM.index);
  const { ksOnboardingSteps, platformOnboardingSteps } = props;

  const handleSelectedTab = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const renderKatalonStudioStepper = () => (
    <OnboardingStepper
      steps={ksOnboardingSteps.currentSteps}
      className="onboarding-stepper"
    />
  );

  const renderKatalonPlatformStepper = () => (
    <OnboardingStepper
      steps={platformOnboardingSteps.currentSteps}
      className="onboarding-stepper"
    />
  );

  const generateLabelTabs = (detailSteps, text, tooltipText) => {
    const ratio = (detailSteps.totalCompletedSteps / detailSteps.totalSteps) * 100;

    return (
      <div className="d-flex align-items-center">
        {
          MFlags.onboardingChecklistEnhancementEnabled
          && <Progress type="circle" percent={ratio} strokeWidth={15} strokeColor="#1847ca" className="onboarding-progress" />
        }
        {text}
        {tooltipText &&
        <div>
          <TooltipComponent text={tooltipText} />
        </div>}
      </div>
    );
  };

  return (
    <div className="onboarding-checklist-tabs">
      <Tabs
        className={classes.tabs}
        value={selectedTab}
        onChange={handleSelectedTab}
      >
        <Tab label={generateLabelTabs(platformOnboardingSteps, t('katalon-platform'))} />
        { !MFlags.hideKatalonStudioOnboardingSteps && <Tab label={generateLabelTabs(ksOnboardingSteps, t('katalon-studio'), t('ks-onboarding#tooltip'))} /> }
      </Tabs>
      <TabPanel index={OnboardingType.KATALON_PLATFORM.index} value={selectedTab}>
        {renderKatalonPlatformStepper()}
      </TabPanel>
      <TabPanel index={OnboardingType.KATALON_STUDIO.index} value={selectedTab}>
        {renderKatalonStudioStepper()}
      </TabPanel>
    </div>
  );
};

export default OnboardingChecklistBody;
