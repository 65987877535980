import React, { useState } from 'react';
import _ from 'lodash';
import { Grid } from '@mui/material';
import { Button, ButtonToolbar, Form } from 'reactstrap';
import Stack from '@mui/material/Stack';
import { t } from '../../../i18n/t';
import DocumentLink from '../../../utils/DocumentLink';
import Services from '../../../utils/Services';
import MContext from '../../../models/MContext';
import storageService from '../../../utils/mobiletesting/mobileTestingService';
import Notification from '../../../utils/Notification';
import MFlags from '../../../models/MFlags';
import githubService from '../../../utils/testcloud/githubService';

const SAMPLE_GIT_REPOSITORY_TYPE = {
  API: 'API',
  BDD: 'BDD',
  DATA_DRIVEN_TEST: 'DATA_DRIVEN_TEST',
  WEB_UI: 'WEB_UI',
  MOBILE_ANDROID: 'MOBILE_ANDROID',
  MOBILE_IOS: 'MOBILE_IOS',
  VISUAL_TESTING: 'VISUAL_TESTING'
};

const SAMPLE_GIT_REPOSITORY_NAME = {
  MOBILE_ANDROID: 'android-mobile-tests',
  MOBILE_IOS: 'ios-mobile-tests',
};

export const SampleGitRepositoryOptions = ({ handleRedirect }) => {
  const [selectedType, setSelectedType] = useState(SAMPLE_GIT_REPOSITORY_TYPE.API);
  const sampleOption = (type) => (
    <Grid item xs={2} sm={2} md={3} key={type}>
      <div
        className={`sample-git-repository-option ${selectedType === type ? 'selected' : ''}`}
        onClick={() => setSelectedType(type)}
      >
        {t(`sample-git-repository#${type}`)}
      </div>
    </Grid>
  );

  const getAppPath = async () => {
    const xmlFile = await githubService.getXmlFileFromGithub('katalon-studio-samples', SAMPLE_GIT_REPOSITORY_NAME[selectedType], 'Profiles/default.glbl');
    if (xmlFile) {
      const globalVariableEntities = xmlFile.getElementsByTagName('GlobalVariableEntity');
      for (let i = 0; i < globalVariableEntities.length; i++) {
        if (globalVariableEntities[i].getElementsByTagName('name')[0].childNodes[0].nodeValue === 'G_AppPath') {
          return globalVariableEntities[i].getElementsByTagName('initValue')[0].childNodes[0].nodeValue?.replaceAll('\'', '');
        }
      }
    }
    return null;
  };

  const uploadApp = async () => {
    const appPath = await getAppPath();
    if (appPath) {
      const appPathArr = appPath.split('/');
      const appName = appPathArr[appPathArr.length - 1];
      const url = `${process.env.REACT_APP_GITHUB_SAMPLE_PROJECT_URL}/${SAMPLE_GIT_REPOSITORY_NAME[selectedType]}/blob/master/${encodeURI(appPath)}?raw=true`;
      try {
        await storageService.createApp({
          storagePath: url,
          fileName: appName,
          organizationId: MContext.team?.organizationId
        });
      } catch (e) {
        Notification.pushError(`Upload app error: ${e.message}`);
      }
    }
  };

  const isMobileType = () => selectedType === SAMPLE_GIT_REPOSITORY_TYPE.MOBILE_ANDROID || selectedType === SAMPLE_GIT_REPOSITORY_TYPE.MOBILE_IOS;

  const onSubmit = async (e) => {
    e.preventDefault();
    const { projectId } = MContext;
    const params = { projectId, type: selectedType };
    if (MFlags.testCloudUploadSampleNativeAppEnabled && isMobileType()) {
      await uploadApp();
    }
    Services.createSampleGitTestProject(params).then((newRepo) => {
      const { id } = newRepo;
      handleRedirect(id);
    });
  };

  const properties = JSON.stringify({ sampleTitle: t(`sample-git-repository#${selectedType}`) });
  return (
    <Form data-trackid="create-sample-git-script-repository" data-properties={properties} onSubmit={onSubmit}>
      <Stack spacing={3}>
        <Grid
          container
          alignItems="stretch"
          spacing={2}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          {_.map(SAMPLE_GIT_REPOSITORY_TYPE, sampleOption)}
        </Grid>
        <ButtonToolbar>
          <Button
            type="submit"
            color="primary"
            data-testid="sample-git-connect"
          >
            {t('test-project#git#connect')}
          </Button>
          <Button
            color="link"
            target="_blank"
            rel="noreferrer noopener"
            href={DocumentLink[`SAMPLE_GIT_REPO_LEARN_MORE_${selectedType}`]}
          >
            {t('learn-more-about-this-sample')}
          </Button>
        </ButtonToolbar>
      </Stack>
    </Form>
  );

};
