import React from 'react';
import { Button, Card, CardBody, Col, CustomInput, Form, FormGroup, Label, Row, Alert } from 'reactstrap';
import DefaultLayout from '../components/DefaultLayout';
import ObjectSummary from '../components/summary/ObjectSummary';
import { t } from '../i18n/t';
import MAuth from '../models/MAuth';
import { MailExecutionStatus } from '../utils/Constants';
import Notification from '../utils/Notification';
import Services from '../utils/Services';
import UserEmailConfig from './integrations/components/UserEmailConfig';
import MFlags from '../models/MFlags';


export default class UserSettings extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      disabled: false,
      userSetting: null,
    };
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.getUserSetting = this.getUserSetting.bind(this);
    this.submit = this.submit.bind(this);
    this.renderHeader = this.renderHeader.bind(this);
    this.renderBody = this.renderBody.bind(this);
    this.handleMailExecutionChange = this.handleMailExecutionChange.bind(this);
  }

  componentDidMount() {
    this.getUserSetting();
  }

  getUserSetting() {
    Services.getUserSetting().then((userSetting) => {
      if (!userSetting.mailExecution) {
        userSetting.mailExecutionStatus = '';
      }
      this.setState({ userSetting });
    });
  }

  handleCheckboxChange(event) {
    const name = event.target.name;
    const { userSetting } = this.state;
    userSetting[name] = !userSetting[name];
    this.setState({ userSetting });
  }

  handleMailExecutionChange(event) {
    const { checked, value, name, id } = event.target;
    this.setState(({ userSetting }) => {
      const newUserSetting = { ...userSetting };
      if (!checked || id === 'mailExecutionNever') {
        newUserSetting.mailExecution = false;
        newUserSetting[name] = '';
      } else {
        newUserSetting.mailExecution = true;
        newUserSetting[name] = value;
      }
      return { userSetting: newUserSetting };
    });
  }

  submit(event) {
    event.preventDefault();
    this.setState({ disabled: true });
    const { userSetting } = this.state;

    Services.updateUserSetting(userSetting)
      .then((userSetting) => {
        this.setState({ userSetting, disabled: false });
        Notification.pushSuccess('User setting was updated successfully.');
      });
  }

  renderExecutionOptions() {
    const {
      mailExecution,
      mailExecutionStatus,
    } = this.state.userSetting;
    return (
      <FormGroup>
        <CustomInput
          data-trackid="mail-execution-all"
          id="mailExecutionAll"
          name="mailExecutionStatus"
          onClick={this.handleMailExecutionChange}
          checked={mailExecution && mailExecutionStatus === MailExecutionStatus.ALL}
          value={MailExecutionStatus.ALL}
          type="radio"
          label={t('user-settings#execution-all')}
          className="setting-custom-control"
        />
        <CustomInput
          data-trackid="mail-execution-failed"
          id="mailExecutionFailed"
          name="mailExecutionStatus"
          onClick={this.handleMailExecutionChange}
          checked={mailExecution && mailExecutionStatus === MailExecutionStatus.FAILED}
          value={MailExecutionStatus.FAILED}
          type="radio"
          label={t('user-settings#execution-failed')}
          className="setting-custom-control"
        />
        <CustomInput
          data-trackid="mail-testcase-failed"
          id="mailFailedTestCases"
          name="mailExecutionStatus"
          onClick={this.handleMailExecutionChange}
          checked={mailExecution && mailExecutionStatus === MailExecutionStatus.ASSIGNED_MAINTAINER}
          value={MailExecutionStatus.ASSIGNED_MAINTAINER}
          type="radio"
          label={t('user-settings#testcase-failed')}
          className="setting-custom-control"
        />
        <CustomInput
          data-trackid="mail-execution-never"
          id="mailExecutionNever"
          name="mailExecutionStatus"
          onClick={this.handleMailExecutionChange}
          checked={!mailExecution}
          value=""
          type="radio"
          label={t('user-settings#execution-never')}
          className="setting-custom-control"
        />
      </FormGroup>
    );
  }

  renderWeeklyUpdateOptions() {
    const { mailWeeklyUpdate } = this.state.userSetting;
    return (
      <FormGroup>
        <CustomInput
          id="mailWeeklyUpdate"
          name="mailWeeklyUpdate"
          onClick={this.handleCheckboxChange}
          checked={mailWeeklyUpdate}
          type="checkbox"
          label={t('user-settings#weeklyupdate-detail')}
          className="setting-custom-control"
        />
      </FormGroup>
    );
  }

  renderObjectSummary() {
    const urlParams = {
      userSettings: t('notifications'),
    };
    return (
      <ObjectSummary params={urlParams} />
    );
  }

  renderHeader() {
    return (
      <>
        {this.renderObjectSummary()}
      </>
    );
  }

  renderBody() {
    const email = MAuth.email;
    return (
      <>
        <Alert color="primary">
          These settings are only applied to the account {email}.
        </Alert>
        {this.renderSettings()}
      </>
    );
  }

  updateUserSetting = (userSetting) => {
    this.setState({ userSetting });
  };

  renderSettings() {
    const { disabled, userSetting } = this.state;
    return (
      <Card>
        <CardBody>
          <Row>
            <Col>
              <Form data-trackid="update-user-settings" onSubmit={this.submit}>
                <FormGroup>
                  <Label>{t('executions')}</Label>
                  {this.renderExecutionOptions()}
                </FormGroup>
                <FormGroup>
                  <Label>{t('user-settings#weeklyupdate')}</Label>
                  {this.renderWeeklyUpdateOptions()}
                </FormGroup>
                {
                  MFlags.dailyExecutionEmailEnabled &&
                  <FormGroup>
                    <UserEmailConfig userSetting={userSetting} setUserSetting={this.updateUserSetting} />
                  </FormGroup>
                }
                <FormGroup>
                  <Button type="submit" color="primary" disabled={disabled}>{t('update')}</Button>
                </FormGroup>
              </Form>
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  }

  render() {
    const { userSetting } = this.state;
    if (userSetting) {
      return (
        <DefaultLayout
          renderHeader={this.renderHeader}
          renderBody={this.renderBody}
        />
      );
    }
    return null;
  }
}
