import React from 'react';
import moment from 'moment-timezone';
import 'moment-duration-format';
import Moment from 'react-moment';
import { DayOfWeek } from './Constants';
import MContext from '../models/MContext';

moment.updateLocale('en', {
  relativeTime: {
    s: 'a few secs',
    ss: '%d secs',
    m: 'a min',
    mm: '%d mins',
    h: 'an hour',
    hh: '%d hours',
    d: 'a day',
    dd: '%d days',
    M: 'a month',
    MM: '%d months',
    y: 'a year',
    yy: '%d years',
  },
  week: {
    dow: 1,
    doy: 1,
  },
});

export class TimeZone {
  static guess() {
    return moment.tz.guess(true);
  }

  static isValid(zoneId) {
    return moment.tz.zone(zoneId);
  }

  static setDefault(timezone) {
    moment.tz.setDefault(timezone);
  }
}

class Time {
  /*
  https://momentjs.com/docs/#/parsing/string-format/
  moment format
   */

  static get DF_DATE_TZ_FORMAT() {
    return 'MM/DD/YYYY HH:mm:ss Z';
  }

  static get DF_DATE_FORMAT() {
    return 'MM/DD/YYYY HH:mm:ss';
  }

  static get DF_DATE_NOT_SS_FORMAT() {
    return 'MM/DD/YYYY HH:mm';
  }

  static get FULL_DATE_TIME_FORMAT() {
    return 'ddd MMM DD HH:mm';
  }

  static get FULL_DATE_TIME_WITH_YEAR_FORMAT() {
    return 'MMM DD, YYYY, HH:mm';
  }

  static get TIME_THEN_DATE_FORMAT() {
    return 'H:mm, MMM DD, YYYY';
  }

  static get DF_DURATION_FORMAT() {
    return 'h[h] m[m] s[s]';
  }

  static get DATE_FORMAT() {
    return 'MM/DD/YYYY';
  }

  static get OVER_A_DAY_FORMAT() {
    return 'MMM DD, HH:mm';
  }

  static get LAST_MONTHS_FORMAT() {
    return 'MMM DD';
  }

  static get LAST_YEARS_FORMAT() {
    return 'MMM DD, YYYY';
  }

  static get DAY_FULL_FORMAT() {
    return 'MMMM DD, YYYY';
  }

  static get YEAR_FORMAT() {
    return 'YYYY';
  }

  static get YEAR_MONTH_FORMAT() {
    return 'YYYY/MM';
  }

  static get MONTH_DAY_FORMAT() {
    return 'MM/DD';
  }

  static get WEEK_FORMAT() {
    return 'ww';
  }

  static get DATE_OF_WEEK_FORMAT() {
    return 'ddd';
  }

  static get DATE_OF_MONTH_FORMAT() {
    return 'DD';
  }

  static get MONTH_YEAR_FORMAT() {
    return 'MMMM YYYY';
  }

  static get LOCAL_DATE_FORMAT() {
    return 'YYYY-MM-DD';
  }

  static get FULL_HOUR_TIME() {
    return 'HH:mm';
  }

  static get CURRENT_TIMEZONE() {
    const { project } = MContext;
    if (project && project.timezone) {
      return project.timezone;
    } else {
      return '';
    }
  }

  /**
   *
   * Get client (browser timeZone)
   */
  static get CLIENT_TIMEZONE() {
    return TimeZone.guess();
  }

  /**
   * Format date for Search API
   */
  static searchApiFormat(date) {
    const tz = this.CURRENT_TIMEZONE;
    return moment.tz(date, this.DF_DATE_FORMAT, tz).format();
  }

  /**
   * Format date for Search API with TZ (if no setting timezone is found, get client timezone instead)
   */
  static searchApiFormatWithSettingOrClientTz(date) {
    let tz = this.CURRENT_TIMEZONE;
    if (!tz) {
      tz = this.CLIENT_TIMEZONE;
    }
    return moment.tz(date, this.DF_DATE_FORMAT, tz).format();
  }

  static convertToDate(date) {
    const tz = this.CURRENT_TIMEZONE;
    const time = moment.tz(date, tz);
    // const newDate = new Date(time.year(), time.month(), time.date(), time.hours(), time.minutes(), time.seconds(), time.milliseconds());
    return time.toDate();
  }

  static formatDate(date, format, noAgo) {
    return Time.format(date, format, noAgo, undefined, format);
  }

  static format(date, format, noAgo, timezone, titleFormat) {
    let defaultFormat = Time.DF_DATE_FORMAT;
    let tz = timezone;
    if (!tz) {
      tz = this.CURRENT_TIMEZONE;
    }
    if (date) {
      if (noAgo) {
        return moment.tz(date, tz).format(format || defaultFormat);
      } else {
        if (!format) {
          const now = new Date();
          const isLastYears = Math.abs(now.getFullYear() - moment(date).get('year')) >= 1;
          const isLastMonths = Math.abs(now.getMonth() - moment(date).get('month')) >= 1;
          const isOverADay = Math.abs(moment(now).dayOfYear() - moment(date).dayOfYear()) >= 1;
          if (isLastYears) {
            defaultFormat = Time.LAST_YEARS_FORMAT;
          } else if (isLastMonths) {
            defaultFormat = Time.LAST_MONTHS_FORMAT;
          } else if (isOverADay) {
            defaultFormat = Time.OVER_A_DAY_FORMAT;
          } else {
            defaultFormat = undefined;
          }
        }
        return <Moment
          date={date}
          fromNow
          tz={tz}
          format={format || defaultFormat}
          titleFormat={titleFormat || Time.DF_DATE_TZ_FORMAT}
          withTitle
        />;
      }
    }
    return null;
  }

  /**
   * Format a Date() object via user's format without being affected by timezone
   *
   * @param {Date() object} value
   * @param {Type format: String} format
   * @returns
   */
  static formatWithoutTimeZone(value, format) {
    return moment(value).format(format);
  }

  static unixFormat(date, format) {
    format = format || Time.DATE_FORMAT;
    return moment.unix(date / 1000).format(format);
  }

  static duration(duration, format) {
    // var formatValue = (format) ? format : Time.DF_DURATION_FORMAT;
    // var convertedDuration = duration/60000;
    // if (duration %60000 >= 30000)convertedDuration +=1;
    // return moment.duration(convertedDuration,"minutes").format(formatValue);

    let formatValue;
    if (format) {
      formatValue = format;
    } else if (duration < 1000) {
      formatValue = 'S[ms]';
    } else {
      formatValue = Time.DF_DURATION_FORMAT;
    }
    return moment.duration(duration).format(formatValue);
  }

  static trimDuration(duration, format) {
    return moment.duration(duration).format(format || Time.DF_DURATION_FORMAT, { trim: 'both' });
  }

  static timeAgoFromDate(date, amount, unit, format) {
    format = format || Time.DATE_FORMAT;
    return moment(date).subtract(amount, unit).format(format);
  }

  static countDays(startDate, endDate) {
    const start = moment(startDate, Time.LOCAL_DATE_FORMAT);
    const end = moment(endDate, Time.LOCAL_DATE_FORMAT);
    const daysLeft = moment.duration(end.diff(start)).asDays();

    if (Number.isNaN(daysLeft)) {
      return 0;
    }
    return Math.round(daysLeft);
  }

  static timeAgo(amount, unit, format) {
    format = format || Time.DATE_FORMAT;
    return moment().subtract(amount, unit).format(format);
  }

  static timeBetween(dateStart, dateEnd, metric, format = null) {
    let start = moment(dateStart);
    const end = moment(dateEnd);
    const days = [];
    while (end.isSameOrAfter(start)) {
      days.push(start.format(format));
      start = Time.startOf(start.add(1, metric), metric);
    }
    return days;
  }

  static startOf(date, metric) {
    const d = moment(date);
    if (metric && metric.toLowerCase() === 'week') {
      metric = 'isoWeek';
    }
    return d.startOf(metric);
  }

  static endOf(date, metric) {
    const d = moment(date);
    if (metric && metric.toLowerCase() === 'week') {
      metric = 'isoWeek';
    }
    return d.endOf(metric);
  }

  static dayOfWeek(date) {
    return date && DayOfWeek[moment(date).format('dddd').toUpperCase()];
  }

  static now() {
    return moment();
  }

  /*
    Need to keep this convert to moment function
    to use with LocalizationProvider
    so we not change any other format logic
  */
  static convertToMoment(date) {
    const tz = this.CURRENT_TIMEZONE;
    return moment.tz(date, tz);
  }

  /**
   * Get max date - 31 Dec 2099
   */
  static get MAX_DATE() {
    return Time.convertToMoment('20991231');
  }

  /**
   * Get min date - 01 Jan 1900
   */
  static get MIN_DATE() {
    return Time.convertToMoment('19000101');
  }
}

export default Time;
