import React, { Component } from 'react';
import DecoratorConstants from '../../utils/DecoratorConstants';
import Routes from '../../utils/Routes';
import DataTable from './DataTable';
import MTableColumnDataMapping from './models/MTableColumnDataMapping';

class TestObjectEntityDataTable extends Component {
  render() {
    const headers = [
      new MTableColumnDataMapping(
        'ID',
        'id',
        (name, row) => {
          const routes = new Routes({
            testObjectEntityId: row.id,
          });
          return DecoratorConstants.idDecorator(name, row, routes.test_object_entity_details_link);
        }
      ),
      new MTableColumnDataMapping(
        'Name',
        'name',
      ),
    ];
    return (
      <DataTable
        entityType="TestObjectEntity"
        tableId="TestObjectEntity"
        columnMapping={headers}
        defaultSort={['name,asc']}
        {...this.props}
      />
    );
  }
}

export default TestObjectEntityDataTable;
