
import { Typography } from '@mui/material';
import React from 'react';
import { buildSearchCondition } from '../../search/SearchUtils';
import SearchableFilter from './SearchableFilter';

const TestSuiteCollectionFilter = (props) => {
  const buildSearchConditions = (searchInput) => ([
    buildSearchCondition('name', 'contains', searchInput || '')
  ]);

  const renderOption = (option) => (
    <Typography noWrap>{option.name}</Typography>
  );
  return (
    <SearchableFilter
      entityType="TestSuiteCollectionEntity"
      label="Test Suite Collection"
      searchKey="name"
      buildSearchConditions={buildSearchConditions}
      renderOption={renderOption}
      {...props}
    />
  );
};

export default TestSuiteCollectionFilter;
