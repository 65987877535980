import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import { Divider, Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Progress } from 'antd';
import { t } from '../../../i18n/t';
import { IconChecklistHeader, IconDismiss, IconDuration } from '../../../images/CustomIcon';
import { IconCollapseGray, IconExpandGray } from '../../../images/CustomNewIcon';
import DismissOnboardingChecklistDialog from '../../../components/dialog/DismissOnboardingChecklistDialog';
import MFlags from '../../../models/MFlags';


const OnboardingChecklistHeader = ({ handleCollapse, expand, handleDismiss, ksOnboardingSteps, platformOnboardingSteps }) => {

  const [isOpenDismissOnboardingChecklistDialog, setOpenDismissOnboardingChecklistDialog] = useState(false);
  const headerText = t('onboarding-checklist#header#title');
  const headerContent = t('onboarding-checklist#header#description');
  const style = makeStyles(() => ({
    tooltip: {
      backgroundColor: '#172B4D',
      color: '#FFFFFF',
      fontWeight: '300',
      margin: '4px',
      lineHeight: '1rem',
      fontSize: '0.75rem',
    },
    arrow: {
      color: '#172B4D',
    }
  }));

  const toggleDismissOnboardingChecklistDialog = () => {
    setOpenDismissOnboardingChecklistDialog(!isOpenDismissOnboardingChecklistDialog);
  };

  const renderDismissOnboardingChecklistDialog = () => (
    <DismissOnboardingChecklistDialog
      isOpen={isOpenDismissOnboardingChecklistDialog}
      handleClose={toggleDismissOnboardingChecklistDialog}
      handleDismiss={handleDismiss}
    />
  );

  const renderProgressSection = () => {
    const totalSteps = ksOnboardingSteps.totalSteps + platformOnboardingSteps.totalSteps;
    const totalCompletedSteps = ksOnboardingSteps.totalCompletedSteps + platformOnboardingSteps.totalCompletedSteps;
    const totalEta = ksOnboardingSteps.totalEta + platformOnboardingSteps.totalEta;
    const totalCompletedEta = ksOnboardingSteps.totalCompletedEta + platformOnboardingSteps.totalCompletedEta;

    const ratio = (totalCompletedSteps / totalSteps) * 100;
    const remainingEta = totalEta - totalCompletedEta;

    return (
      <div className="d-flex align-items-center onboarding-header-progress mr-2">
        <Progress type="circle" percent={ratio} strokeWidth={15} strokeColor="#1847ca" className="onboarding-progress" />
        {totalCompletedSteps}/{totalSteps} tasks completed
        <Divider sx={{ height: '1.14rem', marginRight: '1.143rem', marginLeft: '1.143rem' }} orientation="vertical" />
        <IconDuration className="mr-2 ml-1 icon-duration" />
        {remainingEta}m left
      </div>
    );
  };

  return (
    <div className="mt-3 mb-3 row ml-0">
      <IconChecklistHeader />
      <div className="col mt-1">
        <span className="h4 font-weight-600">{headerText}</span>
        <div className="light-text font-weight-light mt-2">
          {headerContent}
        </div>
      </div>
      <div className="col-4 d-flex align-items-center justify-content-end onboarding-header-action">
        {MFlags.onboardingChecklistEnhancementEnabled && renderProgressSection()}
        <IconButton aria-label="collapse" onClick={handleCollapse}>
          {expand ?
            <Tooltip classes={style()} title={t('collapse')} placement="bottom-end" arrow="arrow">
              <IconCollapseGray />
            </Tooltip> :
            <Tooltip classes={style()} title={t('expand')} placement="bottom-end" arrow="arrow">
              <IconExpandGray />
            </Tooltip>}
        </IconButton>
        {MFlags.onboardingChecklistEnhancementEnabled &&
        <IconButton aria-label="dismiss" onClick={toggleDismissOnboardingChecklistDialog}>
          <Tooltip classes={style()} title={t('dismiss')} placement="bottom-end" arrow="arrow">
            <IconDismiss />
          </Tooltip>
        </IconButton>}
      </div>
      {renderDismissOnboardingChecklistDialog()}
    </div>
  );
};

export default OnboardingChecklistHeader;
