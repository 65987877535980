import _ from 'lodash';
import React from 'react';
import { t } from '../../i18n/t';
import { IconDuration, IconStartTime } from '../../images/CustomIcon';
import DecoratorConstants from '../../utils/DecoratorConstants';
import Routes from '../../utils/Routes';
import DataTable from './DataTable';
import MTableColumnDataMapping from './models/MTableColumnDataMapping';

class ExecutionRequestDataTable extends React.Component {
  render() {
    const columnMapping = [
      new MTableColumnDataMapping(
        '',
        'abnormal',
        DecoratorConstants.abnormalDecorator,
        undefined,
        'fit-content-column',
      ),
      new MTableColumnDataMapping(
        t('id'),
        'id',
        (name, row) => {
          const constructedLink = new Routes({
            executionRequestId: row.id,
            executionTestResultId: row.executionTestResult.id,
            executionId: row.executionTestResult.execution.id,
          });
          return DecoratorConstants.idDecorator(name, row, constructedLink.execution_request_link);
        },
      ),
      new MTableColumnDataMapping(
        t('test_case'),
        'executionTestResult.testCase.name',
        (name, row) => {
          const constructedLink = new Routes({
            executionId: _.get(row, 'executionTestResult.execution.order'),
            executionTestResultId: _.get(row, 'executionTestResult.urlId'),
          });
          return DecoratorConstants.idDecorator(name, row, constructedLink.execution_test_result_detail_link);
        },
      ),
      new MTableColumnDataMapping(
        t('time'),
        'time',
        (name, row) => (
          <>
            <span className="d-block">
              <IconDuration title={t('duration')} className="mr-1" />
              {DecoratorConstants.apiDurationDecorator('time', row)}
            </span>
            <span className="d-block">
              <IconStartTime title={t('startTime')} className="mr-1" />
              {DecoratorConstants.timeDecorator('startedAt', row)}
            </span>
          </>
        ),
        undefined,
        'nowrap-column',
      ),
    ];
    const newProps = {
      columnMapping,
      ...this.props,
    };
    return (
      <DataTable
        entityType="ExecutionRequest"
        {...newProps}
      />
    );
  }
}

export default ExecutionRequestDataTable;
