import { AppType, PlatformTier, TestOpsFeature } from '../utils/Constants';
import MAuth from './MAuth';
import MConfigs from './MConfigs';

function isSSOConfigured(organization) {
  return !!organization?.samlSSO;
}

function getOrganization(team) {
  if (MAuth.isLoggedIn && team?.organizationId) {
    return MAuth.getOrganization(team?.organizationId);
  }
  return null;
}

const MContext = {

  team: null,
  project: null,
  organization: null,

  teamId: 0,
  projectId: 0,
  organizationId: 0,
  executionId: 0,
  executionTestSuiteId: 0,
  executionTestResultId: 0,
  testCaseId: 0,
  testSuiteId: 0,
  notificationId: '',
  executionRequestId: 0,
  testProjectId: 0,
  testProjectGitId: 0,
  incidentId: 0,
  testObjectId: 0,
  testObjectEntityId: 0,
  incidentOrder: 0,
  runConfigurationId: 0,
  schedulerId: 0,
  releaseId: 0,
  buildId: 0,
  testReportId: 0,
  jobOrder: 0,
  licenseFeature: '',
  enterpriseProductId: 0,
  k8sAgentId: 0,
  circleCIAgentId: 0,
  agentId: 0,
  testCloudAgentId: 0,
  keyesExecutionId: 0,
  managedUserId: 0,
  requirementId: '',

  screenshotId: 0,
  appType: AppType.CENTER,

  newOrders: false,

  totalAPICalls: 0,
  baselineCollectionGroupOrder: 0,

  baselines: null,
  /* This is list of  organization's scope feature flag and currently pass to E2e to use and maintain flags */
  featureFlags: null,
  testCloudMobileNativeAppEnabled: false,

  parse(params) {
    this.organizationId = params.organizationId;
    this.teamId = params.teamId;
    this.projectId = params.projectId;
    this.executionId = params.executionId;
    this.screenshotId = params.screenshotId;
    this.executionTestSuiteId = params.executionTestSuiteId;
    this.executionTestResultId = params.executionTestResultId;
    this.testCaseId = params.testCaseId;
    this.testSuiteId = params.testSuiteId;
    this.notificationId = params.notificationId;
    this.executionRequestId = params.executionRequestId;
    if (Number.isNaN(parseInt(params.testProjectId, 10))) {
      this.testProjectId = undefined;
    } else {
      this.testProjectId = params.testProjectId;
    }
    this.testProjectGitId = params.testProjectGitId;
    this.testReportId = params.testReportId;
    this.incidentId = params.incidentId;
    this.testObjectId = params.testObjectId;
    this.testObjectEntityId = params.testObjectEntityId;
    this.incidentOrder = params.incidentOrder;
    this.runConfigurationId = params.runConfigurationId;
    this.jobOrder = params.jobOrder;
    if (Number.isNaN(parseInt(params.schedulerId, 10))) {
      this.schedulerId = undefined;
    } else {
      this.schedulerId = params.schedulerId;
    }
    this.releaseId = params.releaseId;
    this.buildId = params.buildId;
    this.licenseFeature = params.licenseFeature;
    this.enterpriseProductId = params.enterpriseProductId;
    this.k8sAgentId = params.k8sAgentId;
    this.circleCIAgentId = params.circleCIAgentId;
    this.agentId = params.agentId;
    this.testCloudAgentId = params.testCloudAgentId;
    this.keyesExecutionId = params.keyesExecutionId;
    this.managedUserId = params.managedUserId;
    this.requirementId = params.requirementId;
    this.fileHandleId = params.fileHandleId;
    this.baselineCollectionGroupOrder = params.baselineCollectionGroupOrder;
  },

  get isEnterpriseOrganization() {
    return this.organization &&
      (this.organization.quotaKSE > 0 || this.organization.quotaEngine > 0);
  },

  get isSSOEnabledOrganization() {
    return this.isSSOFeatureEnabled && isSSOConfigured(this.organization);
  },

  get isSSOFeatureEnabled() {
    const isSSOEnabled = !!this.organization?.orgFeatureFlag?.sso
                            || this.isTestOpsEnterprise
                            || this.isPlatformEnterprise
                            || this.isPlatformBusiness;
    return isSSOEnabled && !MConfigs.isOnPremise;
  },

  get isSubDomainFeatureEnabled() {
    // Disable this feature on OP
    if (MConfigs.isOnPremise) {
      return false;
    }

    return !!this.organization?.orgFeatureFlag?.subDomain
            || this.isTestOpsEnterprise
            || this.isPlatformEnterprise
            || this.isPlatformBusiness;
  },

  get isWhitelistIpFeatureEnabled() {
    return !!this.organization?.orgFeatureFlag?.whitelistIp
            || this.isTestOpsEnterprise
            || this.isPlatformEnterprise
            || this.isPlatformBusiness;
  },

  get isCircleCiFeatureEnabled() {
    return !!getOrganization(this.team)?.orgFeatureFlag?.circleCi;
  },

  get isTestOpsIntegrationEnabled() {
    return !!this.organization?.orgFeatureFlag?.testOpsIntegration;
  },

  /**
   * Get the current organization when user at Organization/Team/Project level
   */
  get targetOrganization() {
    return this.organization || getOrganization(this.team);
  },

  get isTestOpsEnterprise() {
    return this.testOpsFeature === TestOpsFeature.TESTOPS_ENTERPRISE;
  },

  get isTestOpsBusiness() {
    return this.testOpsFeature === TestOpsFeature.TESTOPS_BUSINESS;
  },

  get isTestOpsSubscription() {
    return this.isTestOpsBusiness || this.isTestOpsEnterprise;
  },

  /**
   * @deprecated
   */
  get isTestOpsCommunity() {
    return (this.testOpsFeature === TestOpsFeature.TESTOPS_COMMUNITY) && !this.isTeamDemo;
  },

  get isPlatformProfessional() {
    return (this.platformTier === PlatformTier.PLATFORM_PROFESSIONAL) && !this.isTeamDemo;
  },

  get isPlatformBusiness() {
    return (this.platformTier === PlatformTier.PLATFORM_BUSINESS) && !this.isTeamDemo;
  },

  get isPlatformEnterprise() {
    return (this.platformTier === PlatformTier.PLATFORM_ENTERPRISE) && !this.isTeamDemo;
  },

  get isPlatformPremium() {
    return MContext.isPlatformProfessional;
  },

  get isPlatformUltimate() {
    return MContext.isPlatformBusiness || MContext.isPlatformEnterprise;
  },

  get testOpsFeature() {
    const organization = this.targetOrganization;
    const feature = organization?.testOpsFeature;
    return feature || TestOpsFeature.TESTOPS_COMMUNITY;
  },

  get platformTier() {
    const organization = this.targetOrganization;
    return organization?.tier;
  },

  get isTeamDemo() {
    return MContext.teamId && MAuth.isTeamDemo(MContext.teamId);
  },

  get isRequireUpgrade() {
    const isOldPlan = this.isTestOpsBusiness || this.isTestOpsEnterprise;
    const isPremiumPlan = this.isPlatformPremium;
    const isUltimatePlan = this.isPlatformUltimate;
    const isTeamDemo = this.isTeamDemo;
    return !isOldPlan && !isPremiumPlan && !isUltimatePlan && !isTeamDemo;
  },

  get isAllowVisualTesting() {
    return MContext.isTestOpsEnterprise
          || MContext.isPlatformEnterprise
          || MContext.isPlatformBusiness;
  }
};

export default MContext;
