import React from 'react';
import {Alert, Card, CardBody, CardHeader, Col, Form, FormGroup, Label, Row} from 'reactstrap';
import Input from '../../components/Input';
import PageComponent from '../../components/PageComponent';
import { buildSearchCondition } from '../../components/search/SearchUtils';
import LicenseKeysDataTable from '../../components/table/LicenseKeysTable';
import { t } from '../../i18n/t';
import MAuth from '../../models/MAuth';
import MContext from '../../models/MContext';
import { notificationIds } from '../../utils/NotificationPageUtils';
import Routes from '../../utils/Routes';
import MachineTable from '../../components/table/MachineTable';
import Services from '../../utils/Services';
import DecoratorConstants from '../../utils/DecoratorConstants';


class OrganizationFloatingEngineLicensesTab extends PageComponent {
  constructor(props) {
    super(props);
    this.meta.id = 'page-organization-floating-engine-licenses';
    this.meta.title = t('engine_license_keys');
    this.organizationId = MContext.organizationId;
    this.feature = 'FLOATING_ENGINE';
    this.state = {
      organization: null,
    };

  }

  componentDidMount() {
    Services.organizationWithLicense(this.organizationId, this.feature)
      .then((organization) => this.setState({ organization }));
  }

  renderEngineQuota() {
    const { organization } = this.state;
    const { quotaFloatingEngine = 0, usedFloatingEngine = 0 } = organization;
    const subscriptionExpiryDateFloatingEngine = DecoratorConstants.timeDecorator('subscriptionExpiryDateFloatingEngine', organization);
    return (
      <Card>
        <CardHeader>{t('license_keys#quota_header')}</CardHeader>
        <CardBody>
          <Row>
            <Col sm="12" md="8" lg="6" xl="5">
              <Form data-trackid="floating-engine-license-key-quota">
                {subscriptionExpiryDateFloatingEngine && (
                  <FormGroup>
                    <Label htmlFor="quota">{t('license_keys#expiry_date')}</Label>
                    <Input plaintext>{subscriptionExpiryDateFloatingEngine}</Input>
                  </FormGroup>
                )}
                <FormGroup>
                  <Label htmlFor="quota">{t('license_keys#quota')}</Label>
                  <Input plaintext>{quotaFloatingEngine}</Input>
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="quota">{t('license_keys#remaining')}</Label>
                  <Input plaintext>
                    {usedFloatingEngine >= quotaFloatingEngine ? 0 : quotaFloatingEngine - usedFloatingEngine}
                  </Input>
                </FormGroup>
              </Form>
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  }

  renderOnlineEngineLicenseKeyTable() {
    const defaultSearchConditions = [
      buildSearchCondition('Organization.id', '=', this.organizationId),
      buildSearchCondition('type', '=', 'ENTERPRISE'),
      buildSearchCondition('feature', '=', 'FLOATING_ENGINE'),
      buildSearchCondition('mode', '=', 'FLOATING'),
      buildSearchCondition('status', '=', 'ACTIVE'),
    ];
    return (
      <Card>
        <CardHeader>{t('license_keys#online')}</CardHeader>
        <CardBody>
          <Row>
            <Col>
              <Alert color="primary">
                {t('license_keys#online-info')}
              </Alert>
            </Col>
          </Row>
          <LicenseKeysDataTable
            title={t('license_keys#online')}
            defaultSearchConditions={defaultSearchConditions}
            noCard
          />
        </CardBody>
      </Card>
    );
  }

  renderMachineTable() {
    const defaultSearchConditions = [
      buildSearchCondition('Organization.id', '=', this.organizationId),
      buildSearchCondition('feature', '=', 'FLOATING_ENGINE'),
    ];
    return (
      <>
        <MachineTable
          id="machine"
          title={t('machine')}
          organizationId={this.organizationId}
          defaultSearchConditions={defaultSearchConditions}
        />
      </>
    );
  }

  render() {
    const isOrganizationManager = MAuth.isOrganizationManager(this.organizationId);
    const isBillingManager = MAuth.isBillingManager(this.organizationId);

    if (!isOrganizationManager && !isBillingManager) {
      Routes.goToNotificationPage(notificationIds.NO_PERMISSION);
    }
    const { organization } = this.state;

    if (organization) {
      return (
        <>
          {this.renderEngineQuota()}
          {isOrganizationManager && this.renderOnlineEngineLicenseKeyTable()}
          {isOrganizationManager && this.renderMachineTable()}
        </>
      );
    } else {
      return null;
    }

  }
}

export default OrganizationFloatingEngineLicensesTab;
