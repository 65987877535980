import { percentFormatter } from '../utils';
import { TestRunStatus } from '../../../utils/Constants';
import { t } from '../../../i18n/t';
import { buildSearchCondition } from '../../../components/search/SearchUtils';
import Arrays from '../../../utils/Arrays';

export const formatStatusLabel = (name) => (name === TestRunStatus.PASSED ? t('totalPassedTests') : t('totalFailedTests'));

export const tooltipFormatter = (value, name, { payload }) => {
  const formattedName = `ratio${name}`;
  const formattedValue = `${value} (${percentFormatter(payload[formattedName] || 0, 1)}%)`;
  return [formattedValue, formatStatusLabel(name)];
};

export const allProfilesFilterOption = {
  value: '@all/profiles',
  label: 'All Profiles'
};

/** *
   *
   * @param profileKey: Search Entity type for profile (exp: 'profile', 'ExecutionTestResult.profile', ...)
   * As default, executionProfile will be equal to '@all/profiles' (ALl profile),
   * in this case, searchCondition for profile will be skipped
   * @returns {*[]}
   */
export const getDefaultSearchConditions = (profileKey, profile, startTime, endTime) => {
  const defaultSearchConditions = [
    buildSearchCondition('startTime', '>=', startTime),
    buildSearchCondition('startTime', '<=', endTime),
    ...Arrays.insertIf(profile !== allProfilesFilterOption.value, buildSearchCondition(profileKey, '=', profile)),
  ];
  return defaultSearchConditions;
};
