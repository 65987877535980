import Icon from '@katalon-studio/katalon-ui/Icon';
import React from 'react';
import colors from '../../../scss/colors.scss';

function ErrorIcon({ color }) {
  return <Icon
    type="fa-solid"
    name="fa-circle-xmark"
    textSize="14px"
    boundingSize={null}
    color={null}
    sx={{
      color,
    }}
  />;
}

ErrorIcon.defaultProps = {
  color: colors.red7,
};

export default ErrorIcon;
