import { Divider, IconButton } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import TooltipComponent from '../TooltipComponent';

function CanvasToolbar({ toolbarItems, onClose, toolbarProperties }) {

  const renderItem = (item) => {
    if (item.divider) {
      return (
        <Divider className="m-1" orientation="vertical" flexItem />
      );
    }
    return (
      <TooltipComponent text={item.tooltip} placement="top">
        <span>
          <IconButton className="rounded" onClick={item.handle}>
            {item.icon}
          </IconButton>
        </span>
      </TooltipComponent>
    );
  };

  return toolbarProperties ? (
    <div
      className="canvas-toolbar"
      style={{
        top: toolbarProperties?.top,
        left: toolbarProperties?.left,
      }}
      onClick={onClose}
    >
      {toolbarItems.map(renderItem)}
    </div>
  ) : null;
}

CanvasToolbar.propTypes = {
  /**
   * example:
   * toolbarItems = [
   *  {
   *    icon: <IconApply />,
   *    tooltip: 'text',
   *    handle: applyIgnoringZoneToAll,
   *  },
   *  {
   *    divider: true,
   *  },
   *  {
   *    icon: <IconDelete />,
   *    tooltip: 'text',
   *    handle: deleteIgnoringZone,
   *  }
   * ];
   */
  toolbarItems: PropTypes.array.isRequired,
  onClose: PropTypes.func,
  toolbarProperties: PropTypes.object,
};

export default CanvasToolbar;
