import React from 'react';
import { find, isEmpty, orderBy } from 'lodash';
import { LoadingOutlined } from '@ant-design/icons';
import Icon from '@katalon-studio/katalon-ui/Icon';
import { ListItem } from '@mui/material';
import { t } from '../i18n/t';
import {
  FolderType,
  KATALON_TEST_CASE_ROOT_FOLDER,
  TestProjectType,
  KATALON_TEST_SUITE_ROOT_FOLDER,
  SUPPORTED_TEST_PROJECT_TYPE,
} from './Constants';
import Services from '../utils/Services';
import { buildSearchCondition } from '../components/search/SearchUtils';
import { IconFolderUploadedData, IconFolderGit, IconFolderTestCaseCloudStudio, IconFolderTestCaseKatalonStudio, IconFolderManualTestCase } from '../images/CustomIcon';
import MFlags from '../models/MFlags';
import colors from '../../scss/colors.scss';
import TreeNodeTitle from '../components/treeview/TreeNodeTitle';
import PopoverDropdownMenu from '../components/action/PopoverDropdownMenu';
import Notification from './Notification';
import Arrays from './Arrays';
import KatalonStorageIcon from '../../images/icons/katalonui/KatalonStorageIcon';
import LayerPlusIcon from '../../images/icons/katalonui/LayerPlusIcon';
import ZipRepositoryIcon from '../../images/icons/katalonui/ZipRepositoryIcon';

const ConvertDataHelper = {
  /**
   * Convert to Data tree with list objects of TestFolder and key of their parent
   * @param {Object} objects: content of api get sub-folder
   * @param {long} parentKey: key of parent folder
   * @param {string} dataType: type of elements in objects
   * @returns json Data tree
   * * Ex:
   *   [
   *    {
   *      title: object.name,
   *      key: object.testFolderId,
   *      children: [
   *        title: object.name,
   *        key: object.testFolderId,
   *      ],
   *    }
   *  ]
   */
  parseSubDataTree(objects, parentKey) {
    const result = [];
    const level = { result };
    // Use parent key and treePath to search seperated point
    const dataTemp = find(objects, { parentId: parentKey });
    const lengthOfPathParentFolder = dataTemp.treePath.split('.').length;

    objects.forEach((object) => {
      const testProject = object?.testProject;
      const arrTemp = object.rawPath.split('/');
      arrTemp.slice(lengthOfPathParentFolder - 1, arrTemp.length).reduce((r, title) => {
        if (!r[title]) {
          r[title] = { result: [] };
          r.result.push({
            title,
            key: object.id,
            testProjectId: testProject?.id,
            children: r[title].result,
            rawPath: object.rawPath,
            iconFolder: ConvertDataHelper.renderIconForSubFolder(object)
          });
        }
        return r[title];
      }, level);
    });
    return result;
  },

  /**
   * Convert to Data tree with list objects of TestFolder and and its test cases
   * @param {Object} objects: content of api get sub-folder
   * @param {long} parentKey: key of parent folder
   * @returns json Data tree and its test cases
   * Ex:
   * [
   *    {
   *      title: testCase.name,
   *      key: testCase.id,
   *    },
   *    {
   *      title: object.name,
   *      key: object.testFolderId,
   *      children: [
   *        {
   *          title: testCase.name,
   *          key: testCase.id,
   *        },
   *        {
   *          title: object.name,
   *          key: object.testFolderId,
   *        }
   *      ],
   *    }
   * ]
   */
  parseCloudStudioExtraTreeData(objects, folderKey) {
    const result = [];
    const level = { result };
    const dataTemp = find(objects, { parentId: folderKey });

    // In case current folder has sub folder
    let lengthOfPathParentFolder;
    if (dataTemp) {
      lengthOfPathParentFolder = dataTemp.treePath.split('.').length;
    }

    objects.forEach(({ id, testProject, rawPath, testCases, testSuites }) => {
      const arrTemp = rawPath.split('/');
      let convertTestEntitiesTree;
      if (testCases) {
        convertTestEntitiesTree = orderBy(testCases, ['name'], ['asc'])
          .map((testCase) => this.convertTestCaseTemplate(testCase, testProject.id));
      } else if (testSuites && MFlags.addTestCaseToTestSuiteEnabled) {
        convertTestEntitiesTree = orderBy(testSuites, ['name'], ['asc'])
          .map((testSuite) => this.convertTestSuiteTemplate(testSuite, testProject.id));
      }
      if (id === folderKey) {
        convertTestEntitiesTree.map((entity) => result.push(entity));
      } else {
        arrTemp.slice(lengthOfPathParentFolder - 1, arrTemp.length).reduce((r, title) => {
          if (!r[title]) {
            r[title] = { result: convertTestEntitiesTree };
            r.result.unshift(this.convertSubTreeTemplate(title, id, testProject.id, r[title].result));
          }
          return r[title];
        }, level);
      }
    });
    return result;
  },

  convertTestCaseTemplate(testCase, testProjectId) {
    const { id, name } = testCase;
    const icon = <Icon
      type="fa-regular"
      name="fa-file-lines"
      textSize="16px"
      boundingSize={null}
      color={null}
      sx={{
        color: colors.gray9
      }}
    />;
    return {
      title: ConvertDataHelper.buildIconTitle(name, icon),
      key: `test-case-${id}`,
      testProjectId,
      isLeaf: true,
      info: testCase,
      value: `test-case-${id}`
    };
  },

  convertTestSuiteTemplate(testSuite, testProjectId) {
    const { id, name } = testSuite;
    const icon = <LayerPlusIcon textSize="16px" />;
    return {
      title: ConvertDataHelper.buildIconTitle(name, icon),
      key: `test-suite-${id}`,
      testProjectId,
      isLeaf: true,
      info: testSuite,
      value: `test-suite-${id}`
    };
  },

  convertSubTreeTemplate(title, key, testProjectId, children) {
    const icon = <Icon
      type="fa-regular"
      name="fa-folder-open"
      textSize="16px"
      boundingSize={null}
      color={null}
      sx={{
        color: colors.gray9
      }}
    />;
    return {
      title: ConvertDataHelper.buildIconTitle(title, icon),
      key,
      testProjectId,
      children,
      value: key
    };
  },

  getScriptRepos(projectId) {
    const params = {
      pagination: {
        page: 0,
        size: 100,
        sorts: [...Arrays.insertIf(MFlags.onCloudTestStorageEnabled, 'type,desc'), 'name,asc'],
      },
      conditions: [
        buildSearchCondition('Project.id', '=', projectId),
        ...Arrays.insertIf(
          MFlags.onCloudTestStorageEnabled,
          buildSearchCondition('type', 'in', SUPPORTED_TEST_PROJECT_TYPE.toString())
        ),
        ...Arrays.insertIf(
          !MFlags.onCloudTestStorageEnabled,
          buildSearchCondition('type', 'in', [TestProjectType.GIT, TestProjectType.KS].toString())
        ),
      ],
      type: 'TestProject',
    };

    return Services.searchRecursively(0, params, []);
  },

  getLoadingIcon() {
    return <LoadingOutlined className="loading-icon" />;
  },

  isScriptRepoRootFolder(nodeData) {
    if (nodeData.testProjectId) {
      return nodeData.key === ConvertDataHelper.generateScriptRepoRootFolderKey(nodeData.testProjectId);
    }
    return false;
  },

  generateScriptRepoRootFolderKey(scriptRepoId) {
    return `root_${scriptRepoId}`;
  },

  renderRepositoryIcon(scriptRepoType) {
    if (scriptRepoType === TestProjectType.CLOUD) {
      return <KatalonStorageIcon />;
    }
    if (scriptRepoType === TestProjectType.GIT) {
      return <IconFolderGit />;
    }
    if (scriptRepoType === TestProjectType.KS) {
      return <ZipRepositoryIcon />;
    }
    return null;
  },

  initScriptRepoData(scriptRepo) {
    return {
      key: ConvertDataHelper.generateScriptRepoRootFolderKey(scriptRepo.id),
      title: scriptRepo.name,
      testProjectId: scriptRepo.id,
      selectable: !scriptRepo.dirty,
      children: [],
      icon: scriptRepo.dirty && ConvertDataHelper.getLoadingIcon,
      iconFolder: !scriptRepo.dirty && ConvertDataHelper.renderRepositoryIcon(scriptRepo.type),
      isLeaf: scriptRepo.dirty,
      testProjectType: scriptRepo.type,
    };
  },

  renderIconForSubFolder(testFolder) {
    const { name: folderName, testProject, treePath, rawPath } = testFolder;

    if (treePath === 'root') {
      if (folderName === KATALON_TEST_CASE_ROOT_FOLDER.G4_TEST_CASE_FOLDER_TITLE
        || folderName === KATALON_TEST_SUITE_ROOT_FOLDER.G4_FOLDER_TITLE) {
        return <IconFolderTestCaseKatalonStudio title={t('katalon-studio')} />;
      } else if (folderName === KATALON_TEST_CASE_ROOT_FOLDER.G5_TEST_CASE_FOLDER_TITLE
        || folderName === KATALON_TEST_SUITE_ROOT_FOLDER.G5_FOLDER_TITLE) {
        return <IconFolderTestCaseCloudStudio title={t('cloud-studio')} />;
      }
    } else if (!testProject && rawPath === KATALON_TEST_CASE_ROOT_FOLDER.MANUAL_TEST_CASE_FOLDER_TITLE) {
      return <IconFolderManualTestCase title={t('manual-test-case')} />;
    }

    return null;
  },

  renderIconForRootFolder(testFolder) {
    const folderName = testFolder.name;
    if (testFolder.treePath === 'root') {
      if (folderName === KATALON_TEST_CASE_ROOT_FOLDER.G4_TEST_CASE_FOLDER_TITLE
        || folderName === KATALON_TEST_SUITE_ROOT_FOLDER.G4_FOLDER_TITLE) {
        return <Icon
          type="fa-regular"
          name="fa-hard-drive"
          textSize="16px"
          boundingSize={null}
          color={null}
          sx={{
            color: colors.gray9
          }}
        />;
      } else if (folderName === KATALON_TEST_CASE_ROOT_FOLDER.G5_TEST_CASE_FOLDER_TITLE
        || folderName === KATALON_TEST_SUITE_ROOT_FOLDER.G5_FOLDER_TITLE) {
        return <Icon
          type="fa-regular"
          name="fa-cloud"
          textSize="16px"
          boundingSize={null}
          color={null}
          sx={{
            color: colors.gray9
          }}
        />;
      }
    }
    return null;
  },

  buildRootDataTree(data, scriptRepos) {
    let isEmpty = false;
    const scriptReposData = scriptRepos.map(this.initScriptRepoData);
    const uploadedData = [];
    if (data.length > 0) {
      data.forEach((testFolder) => {
        const testProject = testFolder?.testProject;
        const dataTemp = ({
          key: testFolder.id,
          title: testFolder.name,
          testProjectId: testProject?.id,
          iconFolder: ConvertDataHelper.renderIconForSubFolder(testFolder)
        });
        // If testFolder is Script Repository
        if (testFolder.testProject) {
          // searchScriptRepository always exists because the scriptRepos will be passed from the parent component.
          const searchScriptRepository = find(scriptReposData, { key: `root_${testFolder.testProject.id}` });
          if (!testFolder.testProject.dirty && searchScriptRepository) {
            // If dirty is not assigned, it will push data to the children of the corresponding repo script that can be searched.
            searchScriptRepository.children.push(dataTemp);
          }
        } else {
          uploadedData.push(dataTemp);
        }
      });
    } else {
      isEmpty = true;
    }

    const defaultFolder = {
      key: FolderType.ROOT_UPLOADED,
      title: t('uploaded-data'),
      children: uploadedData,
      iconFolder: <IconFolderUploadedData />
    };

    return {
      isEmpty,
      dataRootFolder: [defaultFolder, ...scriptReposData],
    };
  },

  buildG5RootFolder(testFolders, folderInfo) {
    if (!testFolders || isEmpty(testFolders)) {
      return {
        key: folderInfo.key,
        title: folderInfo.title,
        children: [],
        rawPath: folderInfo.rawPath,
        iconFolder: <IconFolderTestCaseCloudStudio title={t('cloud-studio')} />
      };
    }
    const testFolder = testFolders[0];
    return {
      key: testFolder.id,
      title: testFolder.name,
      testProjectId: testFolder.testProject.id,
      children: [],
      rawPath: testFolder.rawPath,
      iconFolder: <IconFolderTestCaseCloudStudio title={t('cloud-studio')} />
    };
  },

  buildIconTitle(title, icon) {
    return (
      <div className="d-flex align-items-center">
        <div className="mr-2">
          {icon}
        </div>
        <span>{title}</span>
      </div>
    );
  },

  renderFolderTitle(nodeData, directoryWidth) {
    const isScriptRepoRootFolder = ConvertDataHelper.isScriptRepoRootFolder(nodeData);
    const items = [
      (
        <ListItem
          key={`refresh-${nodeData.key}`}
          button
          component="button"
          onClick={() =>
            Services.refreshTSC(nodeData.testProjectId)
              .then(() => {
                Notification.pushSuccess(t('tree-view#refresh-test-case-noti'));
              })}
        >
          {t('refresh')}
        </ListItem>
      )
    ];
    return (
      <>
        <TreeNodeTitle id={nodeData.key} width={directoryWidth} title={nodeData.title} key={directoryWidth} />
        {isScriptRepoRootFolder && nodeData.testProjectType === TestProjectType.GIT && <PopoverDropdownMenu items={items} />}
      </>
    );
  },
};

export default ConvertDataHelper;
