import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { t } from '../../i18n/t';
import PageComponent from '../../components/PageComponent';
import MContext from '../../models/MContext';
import ObjectSummary from '../../components/summary/ObjectSummary';
import DefaultLayout from '../../components/DefaultLayout';
import { VisualTestingHeaderNav } from '../../components/HeaderNav';
import RouteConstants from '../../utils/RouteConstants';
import VisualTestRuns from './VisualTestRuns';
import VisualBaselineCollections from './VisualBaselineCollections';
import { IconAIPowered } from '../../images/CustomNewIcon';

class KEyes extends PageComponent {
  constructor(props) {
    super(props);
    this.projectId = MContext.projectId;
    this.project = MContext.project;
    this.meta.id = 'page-keyes-executions';
    this.meta.title = t(
      'Project {{name}} - {{page}}',
      { name: this.project.name, page: t('keyes-executions') },
    );

    this.renderHeader = this.renderHeader.bind(this);
    this.renderBody = this.renderBody.bind(this);
  }

  renderObjectSummary() {
    const { project } = this;
    const urlParams = {
      visualBaselineCollections: (
        <div className="d-flex align-items-center">
          <span data-testid="visual-testing-label">{t('testops-keyes')}</span>
          <IconAIPowered className="ml-2" />
        </div>
      ),
      projectName: project.name
    };
    return (
      <ObjectSummary params={urlParams}>
        <VisualTestingHeaderNav />
      </ObjectSummary>
    );
  }

  renderHeader() {
    return (
      <>
        {this.renderObjectSummary()}
      </>
    );
  }

  renderBody() {
    return (
      <Switch>
        <Route exact path={RouteConstants.keyes} component={((params) => <VisualTestRuns {...params} />)} />
        <Route exact path={RouteConstants.baseline_collection} component={((params) => <VisualBaselineCollections {...params} />)} />
      </Switch>
    );
  }

  render() {
    return (
      <DefaultLayout
        renderHeader={this.renderHeader}
        renderBody={this.renderBody}
      />
    );
  }
}

export default KEyes;
