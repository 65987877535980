import React from 'react';
import { Box, IconButton } from '@mui/material';
import PropTypes from 'prop-types';
import { IconArrowLeft } from '../../images/CustomIcon';
import CloseableDialogComponent from './CloseableDialogComponent';

export default function TabsDialogs({
  id, isOpen, dialogName, handleGoBack, isOnInitialTab = true,
  children, maxWidth, handleClose, headerStyle, disablePortal,
  trackCloseIcon, showCloseButton = true
}) {

  const renderTabDialogTitle = () =>
    <>
      {!isOnInitialTab &&
        <Box>
          <IconButton onClick={handleGoBack} size="large" data-testid="go-back-button">
            <IconArrowLeft />
          </IconButton>
        </Box>}
      <Box>
        {dialogName}
      </Box>
    </>;

  return (
    <CloseableDialogComponent
      id={id}
      isOpen={isOpen}
      title={renderTabDialogTitle()}
      maxWidth={maxWidth}
      handleClose={handleClose}
      disablePortal={disablePortal}
      headerStyle={headerStyle}
      trackCloseIcon={trackCloseIcon}
      showCloseButton={showCloseButton}
    >
      {children}
    </CloseableDialogComponent>
  );
}

TabsDialogs.propTypes = {
  id: PropTypes.string,
  isOpen: PropTypes.bool,
  dialogName: PropTypes.string,
  handleGoBack: PropTypes.func,
  isOnInitialTab: PropTypes.bool,
  children: PropTypes.element,
  maxWidth: PropTypes.string,
  handleClose: PropTypes.func,

  /**
   *  Disable the portal behavior. The children stay within it's parent DOM hierarchy.
   */
  disablePortal: PropTypes.bool,

  /**
   * trackCloseIcon contains data-trackid and data-groupid of the parents component
   */
  trackCloseIcon: PropTypes.object,
  showCloseButton: PropTypes.bool
};

TabsDialogs.defaultProps = {
  disablePortal: false,
  showCloseButton: true
};
