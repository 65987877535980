import chroma from 'chroma-js';
import Color from 'color';

export default {

  colorScales: (scaleColor, numberColor, mode) => {
    const colorScale = chroma.scale(scaleColor).mode(mode || 'lrgb');
    return colorScale.colors(numberColor || 7);
  },

  transparentize: (color, alpha = 0.7) => Color(color).alpha(alpha).string()
};
