import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { Alert, Label } from 'reactstrap';
import { isEmpty } from 'lodash';
import { t } from '../../../i18n/t';
import Select from '../../Select';
import TooltipComponent from '../../TooltipComponent';
import Routes from '../../../utils/Routes';
import MContext from '../../../models/MContext';
import MFlags from '../../../models/MFlags';
import CustomSelect from '../../CustomSelect';
import { compareObj } from '../services/testcloud';
import Link from '../../Link';

const KubernetesEnvironment = (props) => {

  const { value, k8sAgents, browserTypeOptions, hideBrowserTypeSelect = false, onChange } = props;

  const [selectedK8sAgents, setSelectedK8sAgents] = useState([]);
  const [selectedBrowserType, setSelectedBrowserType] = useState();
  const [k8sAgentOptions, setK8sAgentOptions] = useState([]);

  const handleChangeBrowserType = (browserType) => {
    setSelectedBrowserType(browserType.value);
    if (!isEmpty(selectedK8sAgents)) {
      onChange(selectedK8sAgents.map((k8sAgent) => ({
        ...k8sAgent.value,
        browserType: browserType.value,
      })));
    } else {
      onChange([{ browserType: browserType.value, isNotTestEnv: true }]);
    }
  };

  const handleChangeK8sAgent = (values) => {
    setSelectedK8sAgents(values);
    if (isEmpty(values)) {
      onChange([{ browserType: selectedBrowserType, isNotTestEnv: true }]);
    } else {
      onChange(values.map((el) => el.value).map((value) => ({
        ...value,
        browserType: selectedBrowserType,
      })));
    }
  };

  useEffect(() => {
    const filterK8sAgents = value.filter(({ isNotTestEnv }) => isNotTestEnv !== true);
    setK8sAgentOptions(k8sAgents.map((k8sAgent) => ({
      ...k8sAgent,
      label: `Kubernetes | ${k8sAgent.value?.name}`,
      disabled: compareObj(filterK8sAgents, k8sAgent.value, ['id', 'name'])
    })));
    if (filterK8sAgents && filterK8sAgents.length > 0) {
      setSelectedK8sAgents(filterK8sAgents.map((el) => ({
        value: el,
        label: `Kubernetes | ${el?.name}`,
      })));
      setSelectedBrowserType(filterK8sAgents[0].browserType);
    } else {
      if (browserTypeOptions && browserTypeOptions.length > 0) {
        setSelectedBrowserType(value[0]?.browserType || browserTypeOptions[0].value);
      }
    }
  }, [value, k8sAgents]);

  const routes = new Routes({
    organizationId: MContext.team.organizationId,
    teamId: MContext.teamId,
    projectId: MContext.projectId,
  });

  return (
    <Box sx={{ marginBottom: '1.5rem' }}>
      <Label for="k8sList">{t('k8s_agent')}</Label>
      <CustomSelect
        value={selectedK8sAgents}
        options={k8sAgentOptions}
        onChange={handleChangeK8sAgent}
        menuPosition="fixed"
      />
      <Box sx={{ marginTop: '0.8rem' }}>
        <Alert color="primary">
          Create a Kubernetes test environment{' '}
          <a
            href={MFlags.moveAgentToOrgLevelPhase2Enabled ? routes.organization_k8s_agent_create_link : routes.k8s_agent_create_link}
          >
            here.
          </a>
        </Alert>
      </Box>
      {!hideBrowserTypeSelect &&
        <>
          <div className="d-flex">
            <Label for="browserType" className="normal-label">{t('run_with')}</Label>
            <TooltipComponent text={t('run-configuration#not-supported-mobile')} />
          </div>
          <Select
            required
            clearable={false}
            id="browserType"
            value={selectedBrowserType}
            onChange={handleChangeBrowserType}
            options={browserTypeOptions}
            maxMenuHeight={180}
            menuPosition="fixed"
            isCustom
          />
        </>}
    </Box>
  );
};

export default KubernetesEnvironment;
