import PropTypes from 'prop-types';
import React from 'react';
import jquery from 'jquery';

class DynamicFilter extends React.Component {
  constructor(props) {
    super(props);
    this.filterRef = React.createRef();
    this.createStructuredFilter = this.createStructuredFilter.bind(this);
  }

  createStructuredFilter(data) {
    if (!this.structFilter) {
      const $filter = jquery(this.filterRef.current);
      const { handleFilterChange } = this.props;
      const options = {
        ...data,
        buttonLabels: true
      };
      this.structFilter = $filter.structFilter(options)
        .on('submit.search change.search', () => {
          handleFilterChange($filter.structFilter('val'));
        });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.createStructuredFilter(nextProps.data);
  }

  componentDidMount() {
    const { data } = this.props;
    if (data.fields && data.fields.length > 0) {
      this.createStructuredFilter(data);
    }
  }

  render() {
    return (
      <div ref={this.filterRef} className="dynamic-filter" />
    );
  }
}

DynamicFilter.propTypes = {
  handleFilterChange: PropTypes.func,
  data: PropTypes.object
};

DynamicFilter.defaultProps = {
  handleFilterChange: (val) => console.log(val),
  data: { fields: [] }
};

export default DynamicFilter;
