import React from 'react';
import { object, string } from 'prop-types';
import { StatelessComponent } from '@katalon-studio/katalon-ui-utils';
import MConfigs from '../models/MConfigs';
import MContext from '../models/MContext';
import MAuth from '../models/MAuth';
import MFlags from '../models/MFlags';
import { sendAnalyticEventForAction, analyticsPage } from '../utils/SegmentAnalytics';
import WebSocket from '../services/WebSocket';

function MicroComponent({ appId, componentId, path, props }) {
  const TestOpsContext = {
    MContext,
    MAuth,
    MFlags,
    MConfigs,
    TestOpsUtils: {
      sendAnalyticEventForAction,
      analyticsPage,
      WebSocket,
    }
  };
  return <StatelessComponent
    id={appId}
    path={path}
    componentId={componentId}
    context={TestOpsContext}
    props={props}
  />;
}

MicroComponent.propTypes = {
  appId: string.isRequired,
  componentId: string.isRequired,
  path: string.isRequired,
  props: object,
};

export default MicroComponent;
