import React from 'react';
import Apis from '../../utils/Apis';
import http from '../../utils/http';
import Services from '../../utils/Services';
import ReleaseDropdownButton from './ReleaseDropdownButton';

const LinkExecutionReleaseBuild = ({ execution = {}, onChange, ...props }) => {

  const handleSelectBuild = (build) => {
    Services.linkExecutionBuild(execution.id, build.id).then((execution) => {
      onChange(execution);
    });
  };

  const handleSelectRelease = (value) => {
    http.post(Apis.linkExecutionRelease(execution.projectId, execution.id, value.id))
      .then((execution) => {
        onChange(execution);
      });
  };

  const unlinkRelease = () => {
    http.post(Apis.unlinkExecutionRelease(execution.projectId, execution.id))
      .then((execution) => {
        onChange(execution);
      });
  };
  return (
    <ReleaseDropdownButton
      release={execution.release}
      build={execution.build}
      onSelectRelease={handleSelectRelease}
      onSelectBuild={handleSelectBuild}
      unlinkRelease={unlinkRelease}
      {...props}
    />
  );
};

export default LinkExecutionReleaseBuild;
