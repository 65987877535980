import { Checkbox, DialogActions, DialogContent } from '@mui/material';
import React, { useState } from 'react';
import { Button } from 'reactstrap';
import { t } from '../../i18n/t';
import CloseableDialogComponent from './CloseableDialogComponent';
import { setLocalStorageItem } from '../../utils/LocalStorageHelper';
import { APPLY_ALL_BASELINE_COLLECTION_ACKNOWLEDGE } from '../../utils/LocalStorageKey';

function AcknowledgeApplyAllBaselineCollectionDialog({ id, isOpen, handleClose }) {

  const [isChecked, setChecked] = useState();

  const handleCheckboxChange = () => {
    setChecked(!isChecked);
  };

  const confirmAcknowledgement = () => {
    if (isChecked) {
      setLocalStorageItem(APPLY_ALL_BASELINE_COLLECTION_ACKNOWLEDGE, true);
    }
    handleClose();
  };

  return (
    <CloseableDialogComponent
      id={id}
      isOpen={isOpen}
      title={t('acknowledgement')}
      handleClose={confirmAcknowledgement}
    >
      <DialogContent className="pb-0">
        <div>
          {t('acknowledge_apply_all_baseline_collection_message')}
        </div>
        <div>
          <Checkbox className="pl-0" disableRipple onChange={handleCheckboxChange} checked={isChecked} />
          {t('do_not_show_again')}
        </div>
      </DialogContent>
      <DialogActions className="confirm-footer">
        <Button color="secondary" onClick={confirmAcknowledgement}>
          {t('acknowledge')}
        </Button>
      </DialogActions>
    </CloseableDialogComponent>
  );
}

export default AcknowledgeApplyAllBaselineCollectionDialog;
