import MConfigs from '../models/MConfigs';
import { TestRunStatus } from './Constants';

export default {
  isFailedTestResult(testResult) {
    return testResult.status === TestRunStatus.FAILED || testResult.status === TestRunStatus.ERROR;
  },

  isShowSimilarFailures(testResult) {
    const { isOnPremise } = MConfigs;
    return !isOnPremise && this.isFailedTestResult(testResult);
  },
};
