import React from 'react';
import Select from '../Select';
import { t } from '../../i18n/t';

class DynamicSort extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      removeSelected: true,
      stayOpen: false,
      value: []
    };
    this.selectRef = React.createRef();
    this.lastValue = '';
  }

  handleSelectChange(value) {
    this.props.sortData(value);
    this.lastValue = value;

    this.setState({ value });
  }

  render() {
    const {
      stayOpen, value
    } = this.state;
    const sortOptions = this.props.options;

    const buildSortElem = (elem, asc) => {
      const direction = asc === 'asc' ? [t('ascending'), 'asc'] : [t('descending'), 'desc'];
      return {
        label: `${elem.label} (${direction[0]})`,
        value: `${direction[1]}${elem.val}`
      };
    };
    const { labelMap } = this.props;

    const processedOptions = sortOptions
      .filter((option) => labelMap[option])
      .map((key) => ({ label: labelMap[key], val: key }))
      .sort((a, b) => a.label.localeCompare(b.label))
      .reduce((res, elem) => {
        res.push(buildSortElem(elem, 'asc'));
        res.push(buildSortElem(elem, 'desc'));
        return res;
      }, []);

    return (
      <div ref={this.selectRef}>
        <Select
          closeOnSelect={!stayOpen}
          multi
          onChange={(value) => this.handleSelectChange(value)}
          options={processedOptions}
          placeholder="Sort"
          removeSelected={this.state.removeSelected}
          simpleValue
          value={value}
        />
      </div>
    );
  }
}

export default DynamicSort;
