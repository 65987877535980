import React, { Component } from 'react';
import DataLoader from '../../components/table/DataLoader';
import GrayCard from '../../components/GrayCard';
import StatusBarInfo from '../../components/chart/status-bar/StatusBarInfo';
import StatusBarChart from '../../components/chart/status-bar/StatusBarChart';
import Arrays from '../../utils/Arrays';
import { t } from '../../i18n/t';

class RequirementTypeBar extends Component {

  renderCounterItems(data) {
    let items = [];
    if (data.length > 0) {
      const { totalRequirement, jiraRequirement, testopsRequirement } = data[0];

      items = [
        {
          key: `total-${totalRequirement}`,
          label: 'Total',
          value: totalRequirement || 0,
        },
        {
          key: `jira-${jiraRequirement}`,
          label: 'Jira Issues',
          value: jiraRequirement || 0,
        },
        {
          key: `bdd-${testopsRequirement}`,
          label: 'BDD Features',
          value: testopsRequirement || 0,
        },
      ];
    }
    return (
      <StatusBarInfo
        data={items}
      />
    );
  }

  counterItems() {
    return (
      <DataLoader
        render={this.renderCounterItems}
        entityType="ProjectRequirementStatistic"
        noCard
      />
    );
  }

  renderConvertDataChart(data) {
    let items = [];
    if (data.length > 0) {
      const { mappedTestCase, notMappedTestCase } = data[0];
      items = [
        ...Arrays.insertIf(mappedTestCase !== 0, {
          key: `mapped-${mappedTestCase}`,
          value: mappedTestCase,
          label: 'mapped',
          color: 'mapped',
          displayValue: t('test-case-with-requirements'),
        }),
        ...Arrays.insertIf(notMappedTestCase !== 0, {
          key: `notMapped-${notMappedTestCase}`,
          value: notMappedTestCase,
          label: 'notMapped',
          color: 'not_mapped',
          displayValue: t('test-case-without-requirements'),
        })
      ];
    }

    return (
      <StatusBarChart
        data={items}
      />
    );
  }

  convertDataChart() {
    return (
      <DataLoader
        render={this.renderConvertDataChart}
        entityType="TestCaseRequirementStatistic"
        noCard
      />
    );
  }

  render() {
    return (
      <GrayCard>
        <div className="status-bar-v2">
          {this.counterItems()}
          {this.convertDataChart()}
        </div>
      </GrayCard>
    );
  }
}

export default RequirementTypeBar;
