import React from 'react';
import Select from '../../../components/Select';

function SelectBoxTime(props) {
  const { week } = props;

  const timeOptions = [
    {
      value: 1,
      label: 'This Week',
    },
    {
      value: 2,
      label: 'Last Week',
    }
  ];
  return (
    <Select
      clearable={false}
      onChange={props.setWeek}
      value={week}
      options={timeOptions}
    />
  );
}

export default SelectBoxTime;
