import React, { Component } from 'react';
import { isEmpty } from 'lodash';
import { t } from '../../../i18n/t';
import ProgressBar from './ProgressBar';
import Services from '../../../utils/Services';
import StatusConstants from '../../../utils/Statuses';
import { jiraIssuesStatus } from '../../../utils/Constants';
import MFlags from '../../../models/MFlags';

class DevelopmentProgressBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      jiraReleaseStatus: {
        totalResolved: 0,
        totalUnresolved: 0,
        total: 0,
      },
    };
    this.getJiraReleaseStatus = this.getJiraReleaseStatus.bind(this);
    this.formatTooltip = this.formatTooltip.bind(this);
  }

  componentDidMount() {
    this.getJiraReleaseStatus();
  }

  getJiraReleaseStatus() {
    const { release } = this.props;

    Services.getJiraReleasesStatus(release.id)
      .then((response) => {
        if (!isEmpty(response)) {
          this.setState({ jiraReleaseStatus: response });
        }
      })
      .catch(() => {
        // eslint-disable-next-line no-console
      });
  }

  convertPercent(jiraReleaseStatus) {
    const { total, totalResolved } = jiraReleaseStatus;
    let percent = 0;
    if ((total !== 0)) {
      percent = ((totalResolved / total) * 100).toFixed(2);
    }
    return Number(percent);
  }

  convertStatistics(jiraReleaseStatus) {
    const statusBarItem = [
      {
        label: StatusConstants.RESOLVED.label,
        value: jiraReleaseStatus.totalResolved || 0,
        color: jiraIssuesStatus.RESOLVED,
      },
      {
        label: StatusConstants.UNRESOLVED.label,
        value: jiraReleaseStatus.totalUnresolved || 0,
        color: jiraIssuesStatus.UNRESOLVED,
      },
    ];

    return statusBarItem;
  }

  formatTooltip(item) {
    return (
      <>
        {item.value} {item.label}
      </>
    );
  }

  render() {
    const { jiraReleaseStatus } = this.state;
    const data = this.convertStatistics(jiraReleaseStatus);
    const percent = this.convertPercent(jiraReleaseStatus);

    return (
      <ProgressBar
        data={data}
        primary={t("release-summary#development")}
        secondary={`${percent} %`}
        showLegend={false}
        formatTooltip={this.formatTooltip}
      />
    );
  }
}

export default DevelopmentProgressBar;
