import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import {
  KATALON_TEST_SUITE_ROOT_FOLDER,
  TestFolderType,
  TestSuiteType,
} from '../../../utils/Constants';
import TreeSelectView from '../../treeview/TreeSelectView';
import Services from '../../../utils/Services';
import { t } from '../../../i18n/t';
import { buildSearchCondition } from '../../search/SearchUtils';
import DataLoader from '../../table/DataLoader';
import TreeViewHelper from '../../../utils/treeview/TreeViewHelper';
import MFlags from '../../../models/MFlags';

function TestSuiteSelectTree(props) {
  const {
    testSuiteType,
    testProjectId,
    setTestSuite,
    includedTestSuiteTypes,
    excludedTestSuiteSources,
    excludedFolderPaths
  } = props;

  const numberOfLevel = 1;

  const loadData = (key) => Services.getAllSubFoldersAndItsTestEntities(
    key,
    numberOfLevel,
    includedTestSuiteTypes,
    excludedTestSuiteSources,
    MFlags.preventNamingTestOpsInTestSuiteFoldersEnabled ? excludedFolderPaths : undefined
  );

  const getSearchNameCondition = () => {
    if (testSuiteType === TestSuiteType.CLOUD_STUDIO) {
      return KATALON_TEST_SUITE_ROOT_FOLDER.G5_FOLDER_TITLE;
    } else {
      return KATALON_TEST_SUITE_ROOT_FOLDER.G4_FOLDER_TITLE;
    }
  };

  const onSelect = (key) => Services.getAllSubFoldersAndItsTestEntities(
    key,
    numberOfLevel,
    includedTestSuiteTypes,
    excludedTestSuiteSources,
    MFlags.preventNamingTestOpsInTestSuiteFoldersEnabled ? excludedFolderPaths : undefined
  );

  const handleChooseTestSuite = (selectedKey, treeData) => {
    const selectedTestSuites = TreeViewHelper.findTreeNodes(treeData, selectedKey)
      .map((node) => node.info)
      .filter((x) => x !== undefined);
    setTestSuite(selectedTestSuites[0]);
  };

  const renderTree = (data) => {
    const treeData = TreeViewHelper.buildRootFolder(data);
    const expandedKeys = isEmpty(treeData) ? [] : [treeData[0].key];
    return (
      <TreeSelectView
        loadData={loadData}
        data={treeData}
        expandedKeys={expandedKeys}
        placeholder={t('select-test-suite-tree#placeholder')}
        onSelect={onSelect}
        treeCheckable={false}
        handleAdd={handleChooseTestSuite}
      />
    );
  };

  if (!testProjectId) {
    return null;
  }

  return (
    <DataLoader
      noCard
      entityType="TestFolder"
      pageSize={1}
      fetchAllPages
      render={renderTree}
      defaultSort={['id,asc']}
      defaultSearchConditions={[
        buildSearchCondition('TestFolder.id', 'is null', ''),
        buildSearchCondition('type', '=', TestFolderType.TEST_SUITE),
        buildSearchCondition('TestProject.id', '=', testProjectId),
        buildSearchCondition('name', '=', getSearchNameCondition())
      ]}
    />
  );
}

TestSuiteSelectTree.propTypes = {
  testSuiteType: PropTypes.string,
  testProjectId: PropTypes.number,
  setTestSuite: PropTypes.func.isRequired,
  includedTestSuiteTypes: PropTypes.array,
  excludedTestSuiteSources: PropTypes.array,
  excludedFolderPaths: PropTypes.array,
};

TestSuiteSelectTree.defaultProps = {
  testSuiteType: TestSuiteType.CLOUD_STUDIO,
  includedTestSuiteTypes: [],
  excludedTestSuiteSources: [],
  excludedFolderPaths: [],
};

export default TestSuiteSelectTree;
