import React from 'react';
import TabComponent from '../../components/TabComponent';
import TestObjectProjectStatistics from '../project_dashboard/components/TestObjectProjectStatistics';
import TestObjectDataTable from '../../components/table/TestObjectDataTable';

class WebServicesTab extends TabComponent {

  renderData() {
    return (
      <>
        <TestObjectProjectStatistics dataKey="totalWebService" />
        <TestObjectDataTable />
      </>
    );
  }

  render() {
    return this.renderData();
  }
}

export default WebServicesTab;
