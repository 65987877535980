import React, { MouseEventHandler, useEffect } from 'react';
import { DialogContent, DialogActions } from '@mui/material';
import { Button, Label, FormGroup, Form } from 'reactstrap';
import { useSelector } from 'react-redux';
import { cloneDeep } from 'lodash';
import Notification from '../../utils/Notification';
import MConfigs from '../../models/MConfigs';
import { CustomFieldDefinition } from '../../models/model/CustomFieldDefinition';
import { IconDelete, IconPlus } from '../../images/CustomNewIcon';
import CloseableDialogComponent from './CloseableDialogComponent';
import { t } from '../../i18n/t';
import Input from '../Input';
import MContext from '../../models/MContext';
import { fromCustomFieldDialogWithTypeScript, useAppDispatch } from '../../store';

interface CustomFieldDialogWithReduxAndTypeScriptProps {
  isOpen: boolean;
  handleClose: MouseEventHandler;
  customFieldDefinitionId?: number;
  handleCreateCustomFieldDefinition: (customFieldDefinition: CustomFieldDefinition) => void;
  handleEditCustomFieldDefinition: (customFieldDefinition: CustomFieldDefinition) => void;
}

function CustomFieldDialogWithReduxAndTypeScript(props: CustomFieldDialogWithReduxAndTypeScriptProps) {
  const isEditing = !!props.customFieldDefinitionId;

  const createOrEditTextBtn = () => (isEditing ? t('save_changes') : t('create'));

  const createOrEditTextTitle = () => (isEditing ? t('custom-fields#edit-custom-field') : t('custom-fields#create-new-custom-field'));

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isEditing) {
      const fetchInfo = async (): Promise<void> => {
        await Promise.all([
          dispatch(fromCustomFieldDialogWithTypeScript.doGetCustomFieldDefinition(props.customFieldDefinitionId as number)),
        ]);
      };
      fetchInfo().catch(() => { /* ignore */ });
    }
  }, [props.customFieldDefinitionId]);

  useEffect(() => {
    if (!isEditing) {
      dispatch(fromCustomFieldDialogWithTypeScript.doResetEntity());
    }
  }, []);

  const displayName = useSelector(fromCustomFieldDialogWithTypeScript.selectDisplayName());

  const customFieldKey = useSelector(fromCustomFieldDialogWithTypeScript.selectCustomFieldKey());

  const optionValues = useSelector(fromCustomFieldDialogWithTypeScript.selectOptionValues());

  const loading = useSelector(fromCustomFieldDialogWithTypeScript.selectLoading());

  const addValueField = () => {
    dispatch(fromCustomFieldDialogWithTypeScript.setOptionValues([...optionValues, {
      value: '',
    }]));
  };

  const removeValueField = (index: number) => {
    const rows = cloneDeep(optionValues);
    rows.splice(index, 1);
    dispatch(fromCustomFieldDialogWithTypeScript.setOptionValues(rows));
  };

  const handleChangeDisplayName = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(fromCustomFieldDialogWithTypeScript.setDisplayName(e.target.value));
  };

  const handleChangeKeyValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(fromCustomFieldDialogWithTypeScript.setCustomFieldKey(e.target.value));
  };

  const handleOnValueChange = (i: number, e: React.ChangeEvent<HTMLInputElement>) => {
    const newOptionValues = cloneDeep(optionValues);
    newOptionValues[i][e.target.name] = e.target.value;
    dispatch(fromCustomFieldDialogWithTypeScript.setOptionValues(newOptionValues));
  };

  const onFormSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (MConfigs.atgCustomFieldKey === customFieldKey) {
      Notification.pushError(t('custom-fields#atg-key-error'));
      return;
    }
    const customFieldDefinition: CustomFieldDefinition = {
      id: props.customFieldDefinitionId,
      displayName,
      key: customFieldKey,
      customFieldOptions: optionValues,
      projectId: MContext.projectId,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      organizationId: MContext.team?.organizationId,
    };
    if (isEditing) {
      props.handleEditCustomFieldDefinition(customFieldDefinition);
    } else {
      props.handleCreateCustomFieldDefinition(customFieldDefinition);
    }
  };

  const renderDisplayNameField = () => (
    <FormGroup>
      <Label for="display-name">{t('custom-fields#display-name')}</Label>
      <Input
        required
        id="display-name"
        name="displayName"
        type="text"
        placeholder={t('custom-fields#placeholder#display-name')}
        value={displayName}
        onChange={handleChangeDisplayName}
      />
    </FormGroup>
  );

  const renderKeyField = () => (
    <FormGroup>
      <Label for="key" className="mt-0">{t('custom-fields#key')}</Label>
      <Input
        required
        id="key"
        name="key"
        type="text"
        disabled={isEditing}
        placeholder={t('custom-fields#placeholder#key')}
        value={customFieldKey}
        onChange={handleChangeKeyValue}
      />
    </FormGroup>
  );

  const renderValuesField = () => {
    // Disable delete button if custom field has less than 1 value
    const isOneValue = optionValues.length <= 1;
    return (
      <FormGroup>
        <Label for="values">{t('custom-fields#values')}</Label>
        {optionValues.map((element, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <div className="d-flex flex-nowrap mb-3" key={element.id}>
            <Input
              required
              id="value"
              name="value"
              type="text"
              value={element.value}
              placeholder={t('custom-fields#placeholder#value')}
              onChange={(e: React.ChangeEvent<HTMLInputElement>): void => handleOnValueChange(index, e)}
            />
            <Button
              className="ml-3 custom-field-dialog__delete-value-btn"
              disabled={isOneValue}
              onClick={(): void => removeValueField(index)}
            >
              <IconDelete className="custom-field-dialog__icon-delete" />
            </Button>
          </div>
        ))}
      </FormGroup>
    );
  };

  const renderAddValueButton = () => (
    <a
      className="mr-3 custom-field-dialog__add-value-btn"
      onClick={(): void => addValueField()}
    >
      <span>
        <IconPlus className="custom-field-dialog__icon-plus ml-0 mr-2" />
        {t('custom-fields#add-new-value')}
      </span>
    </a>
  );

  const renderDialog = () => (
    <CloseableDialogComponent
      isOpen={props.isOpen}
      handleClose={props.handleClose}
      maxWidth="md"
      title={createOrEditTextTitle()}
    >
      <Form onSubmit={onFormSubmit}>
        <DialogContent className="py-0">
          {renderKeyField()}
          {renderDisplayNameField()}
          {renderValuesField()}
          {renderAddValueButton()}
        </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            className="ml-3"
            title={t('cancel')}
            onClick={props.handleClose}
          >
            {t('cancel')}
          </Button>
          <Button
            type="submit"
            color="primary"
            className="ml-3"
            title={createOrEditTextBtn()}
          >
            {createOrEditTextBtn()}
          </Button>
        </DialogActions>
      </Form>
    </CloseableDialogComponent>
  );

  if (!loading && props.customFieldDefinitionId) {
    return null;
  }
  return renderDialog();
}

export default CustomFieldDialogWithReduxAndTypeScript;
