import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import { Button, Card, CardBody, CardHeader, Col, CustomInput, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import PageComponent from '../../components/PageComponent';
import { t } from '../../i18n/t';
import ObjectSummary from '../../components/summary/ObjectSummary';
import DefaultLayout from '../../components/DefaultLayout';
import MContext from '../../models/MContext';
import MAuth from '../../models/MAuth';
import Routes from '../../utils/Routes';
import { notificationIds } from '../../utils/NotificationPageUtils';
import MConfigs from '../../models/MConfigs';
import { OrganizationConfigurationService } from '../../utils/ConfigurationServices';
import Notification from '../../utils/Notification';
import { IconInformation } from '../../images/CustomIcon';

const DEFAULT_TIMEOUT = 120;

class KSETimeoutSettings extends PageComponent {

  constructor(props) {
    super(props);
    this.meta.id = 'page-organization-kse-timeout-setting';
    this.meta.title = t('kse_timeout_setting');
    this.organizationId = MContext.organizationId;
    this.serverUrl = MConfigs.serverUrl;

    this.state = {
      timeout: DEFAULT_TIMEOUT,
      enabled: false,
      timeoutTimes: null,
    };

    this.renderHeader = this.renderHeader.bind(this);
    this.renderBody = this.renderBody.bind(this);
    this.renderTimeoutConfig = this.renderTimeoutConfig.bind(this);
    this.toggleTimeout = this.toggleTimeout.bind(this);
    this.handleTimeoutChange = this.handleTimeoutChange.bind(this);
    this.updateTimeout = this.updateTimeout.bind(this);
  }

  componentDidMount() {
    this.getOrganizationConfiguration();
  }

  renderObjectSummary() {
    const urlParams = {
      kse_timeout_setting: t('kse_timeout_setting'),
    };
    return (
      <ObjectSummary params={urlParams} />
    );
  }

  renderHeader() {
    return (
      <>
        {this.renderObjectSummary()}
      </>
    );
  }

  renderBody() {
    return (
      <>
        {this.renderTimeoutConfig()}
      </>
    );
  }

  toggleTimeout(event) {
    const { checked } = event.target;
    this.setState({
      enabled: checked
    }, () => {
      if (!checked) {
        this.updateConfiguration();
      }
    });
  }

  getOrganizationConfiguration() {
    OrganizationConfigurationService.getOrganizationSetting(this.organizationId)
      .then(({ kseIdleTimeout, kseIdleTimeoutEnabled, maxContinuousIdleSessions }) => {
        this.setState({
          enabled: kseIdleTimeoutEnabled,
          timeout: kseIdleTimeout || DEFAULT_TIMEOUT,
          timeoutTimes: maxContinuousIdleSessions,
        });
      })
      .catch(() => { /* ignored */ });
  }

  updateConfiguration() {
    const { timeout, enabled, timeoutTimes } = this.state;
    const timeoutValue = enabled ? timeout : null;
    const timeoutTimesValue = (enabled && timeoutTimes) ? timeoutTimes : null;

    Promise.all([
      OrganizationConfigurationService.updateKSETimeout(this.organizationId, timeoutValue),
      OrganizationConfigurationService.updateMaxTimesContinuousKSE(this.organizationId, timeoutTimesValue),
    ])
      .then(() => {
        this.setState({
          timeout: timeoutValue ?? DEFAULT_TIMEOUT,
          timeoutTimes: timeoutTimesValue,
        });
        Notification.pushSuccess(t('kse_timeout_changed'));
      });
  }

  updateTimeout(e) {
    e.preventDefault();
    this.updateConfiguration();
  }

  handleTimeoutChange(event) {
    const value = event.target.value;
    const name = event.target.name;
    this.setState({ [name]: value });
  }

  renderTimeoutConfig() {
    const { timeout, enabled, timeoutTimes } = this.state;
    const isOrganizationManager = MAuth.isOrganizationManager(this.organizationId);
    const disabledForm = !isOrganizationManager;
    const label = enabled ? t('kse_timeout_enabled') : t('kse_timeout_disabled');
    return (
      <>
        <Card>
          <CardHeader>
            {t('kse_timeout#title')}
          </CardHeader>
          <CardBody>
            <Form data-trackid="kse-timeout-organization" onSubmit={this.updateTimeout}>
              <FormGroup>
                <Label for="kse-timeout">{t('kse_timeout#enable_timeout')}</Label>
                <CustomInput
                  id="kse-timeout"
                  type="switch"
                  name="kse-timeout"
                  checked={enabled}
                  onChange={this.toggleTimeout}
                  label={label}
                  disabled={disabledForm}
                  className="normal-label"
                />
              </FormGroup>
              {enabled &&
                <>
                  <Row>
                    <Col sm="12" md="6" lg="6" xl="5">
                      <FormGroup>
                        <Label for="timeOut">{t('kse_timeout#time-out')}</Label>
                        <Input
                          required
                          id="timeout"
                          name="timeout"
                          value={timeout}
                          onChange={this.handleTimeoutChange}
                          type="number"
                          disabled={disabledForm}
                          min={1}
                        />
                      </FormGroup>
                    </Col>
                    <Col sm="12" md="6" lg="6" xl="5">
                      <FormGroup>
                        <Label for="timeoutTimes">
                          {t('kse_timeout#time-out-bypass')}
                          &nbsp;
                          <Tooltip placement="top" title={t('kse_timeout#time-out-bypass#description')}>
                            <IconButton size="small" className="align-top">
                              <IconInformation />
                            </IconButton>
                          </Tooltip>
                        </Label>
                        <Input
                          id="timeoutTimes"
                          name="timeoutTimes"
                          value={timeoutTimes}
                          onChange={this.handleTimeoutChange}
                          type="number"
                          disabled={disabledForm}
                          min={0}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <FormGroup>
                    <Button id="update-kse-timeout" type="submit" color="primary" disabled={disabledForm}>
                      {t('kse_timeout#update')}
                    </Button>
                  </FormGroup>
                </>}
            </Form>
          </CardBody>
        </Card>
      </>
    );
  }

  render() {
    const { organization } = MContext;
    if (organization) {
      const isOrganizationManager = MAuth.isOrganizationManager(organization.id);

      if (isOrganizationManager) {
        return (
          <DefaultLayout
            renderHeader={this.renderHeader}
            renderBody={this.renderBody}
          />
        );
      }

      Routes.goToNotificationPage(notificationIds.NO_PERMISSION);
    }
    return null;
  }
}

export default KSETimeoutSettings;
