import React from 'react';
import Moment from '../../../utils/Moment';
import ProgressBar from './ProgressBar';
import { releaseDeliveryStatus } from '../../../utils/Constants';
import { t } from '../../../i18n/t';

function ReleaseDayDeliveryStatusBar({ release }) {

  const getDaysLeftContent = () => {
    const { endTime } = release;
    const daysLeft = Moment.countDays(Moment.now(), endTime);
    const label = daysLeft <= 0 ? t('release-summary#overdue') : t('release-summary#days-left', { daysLeft });
    let labelClass;
    if (daysLeft <= 10) {
      labelClass = 'custom-secondary';
    }
    return { label, labelClass };
  };

  const formatTooltip = (item) => {
    const { value, displayValue } = item;
    return (
      <>
        {value} {displayValue}
      </>
    );
  };
  const { startTime, endTime, createdAt } = release;
  const start = startTime || createdAt;
  let daysPassed = Moment.countDays(start, Moment.now());
  let daysLeft = Moment.countDays(Moment.now(), endTime);

  if (!daysLeft || daysLeft < 0) {
    daysLeft = 0;
  }
  if (!daysPassed || daysPassed < 0) {
    daysPassed = 0;
  }

  const daysLeftContent = getDaysLeftContent();
  const statusBarItem = [
    {
      label: releaseDeliveryStatus.DAYS_PASSED,
      value: daysPassed,
      color: releaseDeliveryStatus.DAYS_PASSED,
      displayValue: 'Days'
    },
    {
      label: releaseDeliveryStatus.DAYS_LEFT,
      value: daysLeft,
      color: releaseDeliveryStatus.DAYS_LEFT,
      displayValue: 'Days'
    }
  ];

  return (
    <ProgressBar
      data={statusBarItem}
      primary={t('release-summary#day-to-delivery')}
      secondary={daysLeftContent.label}
      secondaryStyle={daysLeftContent.labelClass}
      showLegend={false}
      formatTooltip={formatTooltip}
    />
  );
}

export default ReleaseDayDeliveryStatusBar;
