import React, { useEffect, useRef, useState } from 'react';
import {
  getAgents, getCircleCIAgents,
  getK8SAgents,
  getTestCloudEnvironments,
  getTestCloudMobileDevices,
  getTSCs
} from '../../../pages/plan/ConfigurePlanUtils';
import Notification from '../../../utils/Notification';
import TestSuiteCollectionRunConfiguration from './TestSuiteCollectionRunConfiguration';
import { getGroupTestCloudAgentForTestSuiteCollection, getGroupTestCloudAgentOptions } from '../services/testcloud';
import storageService from '../../../utils/mobiletesting/mobileTestingService';
import { TestProjectType, UploadAppStatus } from '../../../utils/Constants';
import DecoratorConstants from '../../../utils/DecoratorConstants';
import Services from '../../../utils/Services';
import MContext from '../../../models/MContext';
import { refreshTscEvent, track } from '../services/scheduleTestRunTracking';
import MFlags from '../../../models/MFlags';

function TscRunConfigurationContainer(props) {
  const { trackId, testProject } = props;

  const [testSuiteCollections, setTestSuiteCollections] = useState([]);
  const [groupedTestCloudAgentsTSC, setGroupedTestCloudAgentsTSC] = useState([]);

  const [nativeApps, setNativeApps] = useState([]);
  const [localAgents, setLocalAgents] = useState([]);
  const [circleCIAgents, setCircleCIAgents] = useState([]);
  const [k8sAgents, setK8sAgents] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const latestTestProjectRef = useRef(testProject.id);

  const fetchData = async (testProject) => {
    const [
      testSuiteCollections,
      testCloudAgents,
      testCloudMobileDevices,
      nativeApps,
      localAgents,
      circleCIAgents,
      k8sAgents,
    ] = await Promise.all([
      getTSCs(testProject),
      getTestCloudEnvironments(),
      getTestCloudMobileDevices(MContext.team?.organizationId),
      !MFlags.testCloudDisableTCFromScheduleDialog ? storageService.getAppsByStatus(UploadAppStatus.READY, 0, 1000000, 'createdDate desc') : Promise.resolve({ items: [] }),
      getAgents(MContext.teamId),
      getCircleCIAgents(MContext.teamId),
      getK8SAgents(MContext.teamId)
    ]);

    const updatedNativeApps = nativeApps?.items.map((el) => ({
      label: el.name || el.fileName,
      icon: DecoratorConstants.iconByOS(el.platform),
      value: el.id,
      platform: el.platform,
      groupId: el.groupId,
      name: el.name,
      postBadge: DecoratorConstants.appVersionNumberDecorator(el?.metadata),
      appVersion: el?.metadata?.version,
      appVersionCode: el?.metadata?.versionCode,
      uploadedDate: DecoratorConstants.uploadedDateDecorator(el.uploadedDate),
    }));

    if (latestTestProjectRef.current === testProject.id) {
      setTestSuiteCollections(testSuiteCollections || []);
      const groupedTestCloudAgentsTS = getGroupTestCloudAgentOptions(testCloudAgents, [...testCloudMobileDevices]);
      const groupedTestCloudAgentsTSC = getGroupTestCloudAgentForTestSuiteCollection(groupedTestCloudAgentsTS);
      setGroupedTestCloudAgentsTSC(groupedTestCloudAgentsTSC);
      setNativeApps(updatedNativeApps);
      setLocalAgents(localAgents);
      setCircleCIAgents(circleCIAgents);
      setK8sAgents(k8sAgents);
      setIsLoading(false);
    }
  };

  const refreshTsc = async () => {
    try {
      if (testProject?.id) {
        track(refreshTscEvent(trackId));
        await Services.refreshTSC(testProject.id);
        const testSuiteCollections = await getTSCs(testProject);
        if (latestTestProjectRef.current === testProject.id) {
          setTestSuiteCollections(testSuiteCollections || []);
        }
      }
    } catch (err) {
      Notification.pushError('Failed to refresh the script repository');
      setIsLoading(false);
    }
  };

  useEffect(() => {
    latestTestProjectRef.current = testProject.id;
    const fetchDataAsync = async () => {
      try {
        if (TestProjectType[testProject?.type] && testProject?.id) {
          setIsLoading(true);
          await fetchData(testProject);
        }
      } catch (err) {
        Notification.pushError(err, 'Error');
        setIsLoading(false);
      }
    };
    fetchDataAsync().then((r) => r);
  }, [testProject]);

  return (
    <TestSuiteCollectionRunConfiguration
      testProject={testProject}
      testSuiteCollections={testSuiteCollections}
      groupedTestCloudAgentsTSC={groupedTestCloudAgentsTSC}
      nativeApps={nativeApps}
      isLoading={isLoading}
      onRefreshTsc={refreshTsc}
      localAgents={localAgents}
      circleCIAgents={circleCIAgents}
      k8sAgents={k8sAgents}
      {...props}
    />
  );
}

export default TscRunConfigurationContainer;
