import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Form, FormGroup, Input, InputGroup, Button, InputGroupAddon, ButtonToolbar } from 'reactstrap';
import { Paper, Popper, Fade, ClickAwayListener, Menu, MenuItem, ListSubheader } from '@mui/material';
import searchParser from 'search-query-parser';
import { union, remove, cloneDeep } from 'lodash';
import { t } from '../i18n/t';
import Filter from './search-query/Filter';
import Sort from './search-query/Sort';
import { IconCopy } from '../images/KitIcons';
import SearchTextField from './SearchTextField';
import UrlHelper from '../utils/UrlHelper';
import { IconPlus } from '../images/CustomNewIcon';
import CheckBox from './CheckBox';
import MFlags from '../models/MFlags';

class SearchQuery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lastSortValue: '',
      defaultFilterValue: [],
      lastFilterValue: {},
      searchString: props.searchString,
      parserOptions: {
        keywords: ['sort'],
        alwaysArray: true,
        tokenize: false
      },
      isOpenDialogSearch: false,
      isDisableFilter: false,
      listFilterComponent: props.defaultDisplayFilterQuery,
      anchorEl: null,
    };
    this.anchorDialogSearchRef = React.createRef();
    this.filterRefs = [];

    this.handleSearchInputChange = this.handleSearchInputChange.bind(this);
    this.applySearchOptions = this.applySearchOptions.bind(this);
    this.onSearchInputKeyDown = this.onSearchInputKeyDown.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.buildFilterChange = this.buildFilterChange.bind(this);
    this.handleOpenDialogSearch = this.handleOpenDialogSearch.bind(this);
    this.handleCloseDialogSearch = this.handleCloseDialogSearch.bind(this);
    this.handleClearFilter = this.handleClearFilter.bind(this);
    this.handleEscapeDialogSearch = this.handleEscapeDialogSearch.bind(this);
    this.handleApplyAndSearch = this.handleApplyAndSearch.bind(this);
    this.setDisableFilter = this.setDisableFilter.bind(this);
    this.deleteFilter = this.deleteFilter.bind(this);
    this.handleResetDynamicFilter = this.handleResetDynamicFilter.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { sorts, filterOptions, defaultFilterConditions } = nextProps;
    let filterFields = [];
    let defaultFilterValue = [];
    let lastSortValue = [];

    if (filterOptions?.fields) {
      filterFields = filterOptions.fields.map((filterField) => filterField.id);
    }

    if (sorts?.length > 0) {
      lastSortValue = sorts.map((sort) => sort.replace(',', '-').replace(/\s/g, ''));
    }

    prevState.parserOptions.keywords = ['sort', 'CustomField', 'statusIn', 'priorityIn', ...filterFields];

    if (defaultFilterConditions) {
      defaultFilterValue = defaultFilterConditions.map((condition) => condition.value);
    }

    return {
      parserOptions: prevState.parserOptions,
      lastSortValue,
      defaultFilterValue
    };
  }

  componentDidMount() {
    const { searchString } = this.state;
    // update the filter value
    if (searchString) {
      this.setSearchString(searchString);
    }
  }

  setSearchString(searchString) {
    const searchOptions = this.getSearchOptions(searchString);
    const { searchSorts = [], searchFilters = {}, customField, statusIn, priorityIn } = searchOptions;
    const cloneSearchFilters = { ...searchFilters };
    if (customField) {
      cloneSearchFilters.CustomeField = customField;
    }
    if (statusIn) {
      cloneSearchFilters.statusIn = statusIn;
    }
    if (priorityIn) {
      cloneSearchFilters.priorityIn = priorityIn;
    }
    Object.entries(cloneSearchFilters)
      .forEach(([filterKey, filterValue]) => {
        cloneSearchFilters[filterKey] = filterValue.join(',');
      });

    this.setState({
      lastSortValue: (searchSorts && searchSorts.join(',')) || '',
      lastFilterValue: cloneSearchFilters,
      searchString
    });
  }

  triggerSearch() {
    this.applySearchOptions();
  }

  onSearchInputKeyDown(event) {
    const { useNewSearchBar, dynamicFilterQuery } = this.props;
    const appendSearchValueOnUrl = MFlags.shareFilterResultByUrlEnabled && dynamicFilterQuery;
    if (event.keyCode === 13) {
      if (useNewSearchBar || appendSearchValueOnUrl) {
        this.handleApplyAndSearch();
      } else {
        this.triggerSearch();
      }
    }
  }

  getSearchOptions(searchString) {
    const { parserOptions } = this.state;
    const { defaultFilter } = this.props;
    let searchOptions = searchParser.parse(searchString != null
      ? searchString
      : this.state.searchString, parserOptions);

    if (typeof searchOptions === 'string') {
      if (defaultFilter == null) {
        return searchOptions;
      }
      searchOptions = {
        [defaultFilter.key]: [`${defaultFilter.operator}${searchOptions}`]
      };
    }

    const { offsets, exclude, sort, CustomField: customField, statusIn, priorityIn, ...searchFilters } = searchOptions;

    const mappedSearchFilters = Object.keys(searchFilters)
      .map((filterKey) => ({ [filterKey]: searchFilters[filterKey] }))
      .reduce((filters, filter) => Object.assign(filters, filter), {});

    let unknownFilters = {};
    if ((defaultFilter || this.props.allowUnknownFilters) && mappedSearchFilters.text != null) {
      const unknownFilterTexts = typeof mappedSearchFilters.text === 'string'
        ? [mappedSearchFilters.text]
        : mappedSearchFilters.text;
      unknownFilters = unknownFilterTexts.map((filterText) => {
        const separatorOffset = filterText.indexOf(':');
        if (separatorOffset < 0) {
          return {
            name: 'text',
            value: filterText
          };
        }
        const filterName = filterText.slice(0, separatorOffset);
        const filterValue = [filterText.slice(separatorOffset + 1)];
        return {
          name: filterName,
          value: filterValue
        };
      })
        .filter((filter) => filter && filter.value)
        .reduce((filters, filter) => {
          const value = filter.name === 'text' ? `${filters.text || ''} ${filter.value}`.trim() : filter.value;
          return Object.assign(filters, { [filter.name]: value });
        }, {});
    }
    delete mappedSearchFilters.text;

    if (defaultFilter != null && unknownFilters.text) {
      mappedSearchFilters[defaultFilter.key] = [`${defaultFilter.operator}${unknownFilters.text}`];
    }
    delete unknownFilters.text;

    const mappedSearchSorts = sort && sort.map((sortField) =>
      (!sortField.endsWith('-desc') && !sortField.endsWith('-asc')
        ? `${sortField}-asc`
        : sortField));

    return {
      searchFilters: { ...mappedSearchFilters, ...unknownFilters },
      searchSorts: mappedSearchSorts,
      exclude,
      offsets,
      customField,
      statusIn,
      priorityIn,
    };
  }

  applySearchOptions(event) {
    if (event) event.preventDefault();
    const searchOptions = this.getSearchOptions();

    if (typeof searchOptions === 'string') {
      if (this.props.onFilterChange) {
        this.props.onFilterChange([]);
      }
      if (this.props.onCustomFieldFilterChange) {
        this.props.onCustomFieldFilterChange(null);
      }
      if (this.props.onSortChange) {
        this.props.onSortChange('');
      }
      return;
    }

    const { /* offsets, exclude,  */searchSorts, searchFilters, customField, statusIn, priorityIn } = searchOptions;

    const customFieldConditions = this.buildCustomFieldConditions(customField)?.filter((item) => (item.key !== ''));
    if (this.props.onCustomFieldFilterChange) {
      this.props.onCustomFieldFilterChange(customFieldConditions);
    }

    const filtersWithInOperator = [
      {
        filterValues: statusIn,
        filterKey: 'statusIn',
      },
      {
        filterValues: priorityIn,
        filterKey: 'priorityIn',
      }
    ];
    let filterOptions = this.buildFilterOptions(searchFilters);
    filtersWithInOperator.forEach((filter) => {
      const filterWithInOpertor = this.buildFilterOptionsWithInOperator(filter.filterValues, filter.filterKey)?.filter((item) => (item.key !== ''));
      if (filterWithInOpertor?.length > 0) {
        filterOptions = filterOptions.concat(filterWithInOpertor);
      }
    });
    if (this.props.onFilterChange) {
      this.props.onFilterChange(filterOptions);
    }

    const sortOptions = this.buildSortOptions(searchSorts);
    if (this.props.onSortChange) {
      this.props.onSortChange(sortOptions);
    }
  }

  buildFilterOptions(searchFilters) {
    const { filterOptions } = this.props;
    if (!filterOptions || !filterOptions.fields) return [];

    return Object.keys(searchFilters)
      .map((filterKey) => {
        const filterOption = filterOptions.fields.find((filterOption) =>
          filterOption.id === filterKey);
        if (!filterOption) return null;

        return searchFilters[filterKey]
          .map((filterText) => this.parseValueAndOperator(filterKey, filterText))
          .filter((filter) => filter && filter.operator && filter.value)
          .map((filter) => ({
            field: {
              value: filterKey
            },
            operator: filter.operator,
            value: {
              label: filter.value,
              value: filter.value
            }
          }));
      })
      .filter((filterOption) => filterOption)
      .reduce((filters, filter) =>
        (filter.length != null
          ? [...filters, ...filter]
          : [...filters, filter]), [])
      .filter((filterOption) => filterOption);
  }

  buildCustomFieldConditions(searchCustomFields) {
    if (!searchCustomFields) {
      return null;
    }
    let customFieldConditions = [];
    searchCustomFields.forEach((filterText) => {
      customFieldConditions = customFieldConditions
        .concat(this.parseCustomFieldCondition(filterText));
    });
    return customFieldConditions;
  }

  parseCustomFieldCondition(filterText) {
    const object = filterText.split('=');
    return {
      key: object[0],
      operator: '=',
      value: object[1],
    };
  }

  buildFilterOptionsWithInOperator(optionValues, fieldValue) {
    if (!optionValues) {
      return null;
    }
    const optionValue = optionValues.join(',');
    return [{
      field: {
        value: fieldValue,
      },
      operator: {
        label: '=',
        value: 'eq'
      },
      value: {
        label: optionValue,
        value: optionValue
      }
    }];
  }

  parseValueAndOperator(filterKey, filterText) {
    const operator = filterText.match(/^(.*?)(\w.*)/);
    if (!operator) return null;
    if (filterKey === 'ExternalRelease.id') {
      if (operator[0] === 'is not null' || operator[0] === 'is null') {
        operator[1] = operator[0];
        operator[0] = '';
      }
    }
    const convertedOperator = this.convertOperator(operator[1]);
    let convertedValue;
    switch (convertedOperator.value) {
      case 'nbw':
        convertedValue = operator[2].replace(';', ',');
        break;
      default:
        convertedValue = operator[2];
        break;
    }
    return {
      operator: convertedOperator,
      value: convertedValue
    };
  }

  convertOperator(operator) {
    const operatorMap = {
      '': { label: '=', value: 'eq' },
      '=': { label: '=', value: 'eq' },
      '>': { label: '>', value: 'gt' },
      '>=': { label: '>=', value: 'geq' },
      '<': { label: '<', value: 'lt' },
      '<=': { label: '<=', value: 'leq' },
      '~': { label: 'contains', value: 'ct' },
      'is null': { label: 'is null', value: 'in' },
      'is not null': { label: 'is not null', value: 'inn' }
    };
    const defaultOperator = operatorMap['='];
    return operatorMap[operator] || defaultOperator;
  }

  buildSortOptions(sorts) {
    const { sortOptions } = this.props;
    if (!sorts || !sortOptions) return '';
    if (typeof sorts === 'string') {
      sorts = [sorts];
    }

    return sorts.map((sort) => {
      const [sortProperty, sortDirection] = sort.split('-');
      // if (!sortOptions.includes(sortProperty)) return null;
      const validSortDirection = sortDirection === 'desc' || sortDirection === 'desc_nulls_last' || sortDirection === 'asc_nulls_last';
      return `${validSortDirection ? sortDirection : 'asc'}${sortProperty}`;
    })
      .filter((sortOption) => sortOption)
      .join(',');
  }

  handleSearchInputChange(event) {
    const searchString = event.target.value;
    this.setSearchString(searchString);
    if (this.props.onSearchInputChange) {
      this.props.onSearchInputChange(event);
    }
  }

  handleSortChange(sortValue) {
    this.setState((prevState) => {
      const searchStringNoSort = prevState.searchString.replace(/sort:.*?(\s|$)/g, '');
      const sortString = sortValue
        ? sortValue.split(',')
          .filter((sort) => sort)
          .map((sort) => `sort:${sort}`).join(' ')
        : '';
      return {
        lastSortValue: sortValue,
        searchString: `${searchStringNoSort} ${sortString}`
          .trim().replace(/\s{2,}/g, ' ')
      };
    }, () => {
      this.triggerSearch();
    });
  }

  buildFilterChange(filterKey, filterValue) {
    const { searchSorts = [] } = this.getSearchOptions();

    if (filterValue) {
      filterValue = filterValue.toString().includes(' ') ? `"${filterValue}"` : filterValue;
    }
    let newFilter = filterValue ? `${filterKey}:${filterValue}` : '';
    this.setState((prevState) => {
      if (!filterValue) {
        delete prevState.lastFilterValue[filterKey];
      } else if (filterKey === 'Tag.id' || filterKey === 'CustomField') {
        prevState.lastFilterValue[filterKey] = union(prevState.lastFilterValue[filterKey], [filterValue]);
        newFilter = prevState.lastFilterValue[filterKey].length > 0 ? `${filterKey}:${prevState.lastFilterValue[filterKey]}` : '';
      } else {
        prevState.lastFilterValue[filterKey] = filterValue;
      }

      const searchStringNoSort = prevState.searchString.replace(/sort:.*?(\s|$)/g, '')
        .replace(new RegExp(`${filterKey}:\\".*\\"`, 'g'), '')
        .replace(new RegExp(`${filterKey}:.*?(\\s|$)`, 'g'), '');

      const sortString = searchSorts
        ? searchSorts.map((sort) => `sort:${sort}`).join(' ')
        : '';

      return {
        lastFilterValue: prevState.lastFilterValue,
        searchString: `${searchStringNoSort} ${newFilter} ${sortString}`
          .trim().replace(/\s{2,}/g, ' ')
      };
    });
  }

  handleFilterChange(filterKey, filterValue) {
    const { dynamicFilterQuery } = this.props;
    const appendSearchValueOnUrl = MFlags.shareFilterResultByUrlEnabled && dynamicFilterQuery;
    const promise = Promise.resolve();
    promise
      .then(() => this.buildFilterChange(filterKey, filterValue))
      .then(() => this.triggerSearch()).then(() => {
        if (appendSearchValueOnUrl) {
          this.appendUrlWithSearchValue();
        }
      });
  }

  setDisableFilter(isDisableFilter) {
    this.setState({ isDisableFilter });
  }

  renderFilterSelection() {
    const { filterQuery } = this.props;

    const { lastFilterValue, defaultFilterValue } = this.state;
    if (!filterQuery || !filterQuery.length) return null;

    return (
      <>
        {filterQuery.map((filter) => {
          if (filter.component) {
            const CustomFilter = filter.component;
            const filterProps = filter.props;
            return (
              <div key={filterProps.id} className="filter">
                <CustomFilter
                  onFilterChange={this.handleFilterChange}
                  value={lastFilterValue[filter.props.id]}
                  defaultValue={defaultFilterValue}
                  onBuildFilterSearch={this.buildFilterChange}
                  setDisableFilter={this.setDisableFilter}
                  {...filterProps}
                />
              </div>
            );
          } else {
            return (
              <div key={filter.id} className="filter filter-select">
                <Filter
                  onChange={this.handleFilterChange}
                  value={lastFilterValue[filter.id]}
                  {...filter}
                />
              </div>
            );
          }
        })}
      </>
    );
  }

  buildDefaultSortSelectOptions() {
    const { sortOptions, sortLabelMap } = this.props;
    const buildSortElem = (elem, asc) => {
      const direction = asc === 'asc' ? [t('ascending'), 'asc'] : [t('descending'), 'desc'];
      return {
        label: `${elem.label} (${direction[0]})`,
        value: `${elem.val}-${direction[1]}`
      };
    };
    const sortSelectOptions = sortOptions
      .filter((sortKey) => sortLabelMap[sortKey])
      .map((sortKey) => ({ label: sortLabelMap[sortKey], val: sortKey }))
      .sort((a, b) => a.label.localeCompare(b.label))
      .reduce((res, elem) => {
        res.push(buildSortElem(elem, 'asc'));
        res.push(buildSortElem(elem, 'desc'));
        return res;
      }, []);
    return sortSelectOptions;
  }

  renderSortSelection() {
    const { sortOptions, sortLabelMap, sortQuery } = this.props;
    if (!sortOptions || !sortLabelMap || !sortQuery || !sortQuery.length) return null;
    const { lastSortValue } = this.state;
    // const defaultSortSelectOptions = this.buildDefaultSortSelectOptions();
    return (
      <Sort
        onChange={(_, value) => this.handleSortChange(value)}
        options={sortQuery}
        defaultValue={lastSortValue}
      />
    );
  }

  renderSearchOptions() {
    return (
      <div className="mt-2 mb-2 d-flex justify-content-between filter-selection-toolbar">
        <div className="filter-toolbar">
          {this.renderFilterSelection()}
        </div>
        <div className="filter-sort">
          {this.renderSortSelection()}
        </div>
      </div>
    );
  }

  renderSearchOptionsV2() {
    return (
      <>
        {this.renderFilterSelection()}
      </>
    );
  }

  handleOpenDialogSearch() {
    const { isOpenDialogSearch } = this.state;
    this.setState({
      isOpenDialogSearch: !isOpenDialogSearch,
      isDisableFilter: false,
    }, () => {
      if (isOpenDialogSearch) {
        window.removeEventListener('keydown', this.handleEscapeDialogSearch);
      } else {
        window.addEventListener('keydown', this.handleEscapeDialogSearch);
      }
    });
  }

  appendUrlWithSearchValue() {
    const { searchString } = this.state;
    if (searchString) {
      const paramsValue = [{
        paramName: 'q',
        value: searchString,
      }];
      UrlHelper.setUrlParamsAndUpdate(paramsValue);
    } else {
      UrlHelper.removeAllParams();
    }
  }

  handleApplyAndSearch() {
    const { goToResultPage } = this.props;
    if (goToResultPage) {
      const searchParams = {
        q: this.state.searchString
      };
      goToResultPage(searchParams);
    } else {
      this.triggerSearch();
      this.appendUrlWithSearchValue();
      this.setState({ isOpenDialogSearch: false });
    }
  }

  handleClearFilter() {
    this.setState({
      searchString: '',
      lastFilterValue: {},
      isDisableFilter: false,
    });
  }

  handleCloseDialogSearch() {
    this.setState({
      isOpenDialogSearch: false,
    });
  }

  handleEscapeDialogSearch(event) {
    if (event.keyCode === 27) {
      this.handleCloseDialogSearch();
    }
  }

  renderDialogSearchBar() {
    const { isOpenDialogSearch, isDisableFilter } = this.state;
    const { isFromTestCase } = this.props;

    return (
      <Popper
        open={isOpenDialogSearch}
        anchorEl={this.anchorDialogSearchRef.current}
        placement="bottom-end"
        className="mt-3"
        id="dialog-search-filter"
      >
        <Fade>
          <ClickAwayListener onClickAway={this.handleCloseDialogSearch}>
            <Paper>
              <Form className="search_filter" onSubmit={this.handleApplyAndSearch}>
                <div className="search_filter__content">
                  {this.renderSearchOptionsV2()}
                </div>
                <div className="d-flex justify-content-end mt-3">
                  <ButtonToolbar>
                    <Button id="clear-filter" color="secondary" onClick={this.handleClearFilter}>{t('search-bar#clear')}</Button>
                    <Button
                      color="primary"
                      type="submit"
                      disabled={isDisableFilter}
                      data-trackid={isFromTestCase && 'search-by-custom-field-and-tag'}
                    >
                      {t('search-bar#apply-and-search')}
                    </Button>
                  </ButtonToolbar>
                </div>
              </Form>
            </Paper>
          </ClickAwayListener>
        </Fade>
      </Popper>
    );
  }

  renderSearchBarV2() {
    const { placeHolder, renderBackButton, renderLeftComponent } = this.props;
    return (
      <div className="d-flex">
        {renderBackButton && renderBackButton()}
        {renderLeftComponent && renderLeftComponent()}
        <SearchTextField
          value={this.state.searchString}
          placeHolder={placeHolder}
          onChange={this.handleSearchInputChange}
          handleOpenDialogSearch={this.handleOpenDialogSearch}
          anchorDialogSearchRef={this.anchorDialogSearchRef}
          onSearchInputKeyDown={this.onSearchInputKeyDown}
        />
        {this.renderDialogSearchBar()}
      </div>
    );
  }

  deleteFilter(filterId) {
    const { listFilterComponent } = this.state;
    const cloneListFilterComponent = cloneDeep(listFilterComponent);
    remove(cloneListFilterComponent, cloneListFilterComponent
      .find((filterComponent) => filterComponent.props.id === filterId));
    this.setState({
      listFilterComponent: cloneListFilterComponent,
    });
  }

  renderCustomFilterSelection() {
    const { lastFilterValue, defaultFilterValue, listFilterComponent } = this.state;
    const { dynamicFilterQuery } = this.props;

    return (
      <>
        {listFilterComponent.map((filter) => {
          if (filter.component) {
            const CustomFilter = filter.component;
            const filterProps = filter.props;
            return (
              <div id={filterProps.id} key={filterProps.id} className={`filter mt-2 mr-2 ${dynamicFilterQuery ? 'dynamic-filter-query__item' : ''}`}>
                <CustomFilter
                  ref={(ref) => { this.filterRefs[filterProps.id] = ref; }}
                  onFilterChange={this.handleFilterChange}
                  value={lastFilterValue[filter.props.id]}
                  defaultValue={defaultFilterValue}
                  onBuildFilterSearch={this.buildFilterChange}
                  setDisableFilter={this.setDisableFilter}
                  onDeleteFilter={this.deleteFilter}
                  {...filterProps}
                />
              </div>
            );
          } else {
            return (
              <div key={filter.id} className="filter filter-select">
                <Filter
                  onChange={this.handleFilterChange}
                  value={lastFilterValue[filter.id]}
                  {...filter}
                />
              </div>
            );
          }
        })}
      </>
    );
  }

  handleCloseMenu = () => {
    this.setState({
      anchorEl: null,
    });
  }

  handleAddFilter = (filter) => {
    const { listFilterComponent } = this.state;
    const isFilterExisting = listFilterComponent.find((filterComponent) => filterComponent.props.id === filter.props.id);
    filter.props.autoOpen = true;
    filter.props.allowDelete = true;
    if (!isFilterExisting) {
      this.setState((prev) => ({
        listFilterComponent: [...prev.listFilterComponent, { ...filter }],
      }));
    } else {
      // Remove filter component on search bar
      this.deleteFilter(filter.props.id);
      // Remove search query related to that filter
      this.handleFilterChange(filter.props.id, '');
    }
    this.handleCloseMenu();
  }

  handleOpenPopperMenu(event) {
    this.setState({
      anchorEl: event.currentTarget
    });
  }

  renderPopperMenu() {
    const { anchorEl, listFilterComponent } = this.state;
    const { dynamicFilterQuery } = this.props;
    if (!dynamicFilterQuery || !dynamicFilterQuery.length) {
      return null;
    }

    return (
      <Menu
        id="dynamic-popper-filter-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={this.handleCloseMenu}
      >
        <ListSubheader
          sx={{
            color: '#808b9a',
            fontSize: 12,
            fontWeight: 'bold',
            marginTop: 0.8,
            marginBottom: 1,
          }}
        >
          {t('search-bar#all-filters').toUpperCase()}
        </ListSubheader>
        {dynamicFilterQuery.map((filter) => {
          const isFilterExisting = listFilterComponent.find((filterComponent) => filterComponent.props.id === filter.props.id);
          return (
            <MenuItem
              onClick={() => this.handleAddFilter(filter)}
            >
              <CheckBox checked={!!isFilterExisting} />
              {filter.props?.label}
            </MenuItem>
          );
        })}
      </Menu>
    );
  }

  handleResetDynamicFilter() {
    this.setState({
      searchString: '',
      lastFilterValue: {},
      isDisableFilter: false,
      listFilterComponent: this.props.defaultDisplayFilterQuery,
    }, () => {
      this.triggerSearch();
      UrlHelper.removeAllParams();
    });
    Object.entries(this.filterRefs).forEach((filterRef) => {
      // Calling clear filter on every filter component
      const filterComponent = filterRef[1];
      if (filterComponent !== null) {
        filterComponent.clear();
        if (typeof filterComponent.closeDialog === 'function') {
          filterComponent.closeDialog();
        }
      }
    });
  }

  renderButtonAddMore() {
    return (
      <Button
        className="dynamic-filter-query__add-more-btn mt-2 mr-2"
        color="secondary"
        onClick={(event) => this.handleOpenPopperMenu(event)}
      >
        <div className="filter-btn-content align-items-center">
          <IconPlus className="dynamic-filter-query__plus-icon mr-2" />
          {t('search-bar#add-more')}
        </div>
      </Button>
    );
  }

  renderButtonReset() {
    const { listFilterComponent, searchString } = this.state;
    const { defaultDisplayFilterQuery } = this.props;
    // Always rendered if any filter value inserted
    if (!searchString && listFilterComponent.length === defaultDisplayFilterQuery.length) {
      return null;
    }
    return (
      <div className="filter-btn mt-2 mr-2 btn dynamic-filter-reset-btn">
        <a
          onClick={this.handleResetDynamicFilter}
        >
          {t('reset_filter')}
        </a>
      </div>
    );
  }

  renderDynamicFilter() {
    const { placeHolder, dynamicFilterQuery, showSearchBar } = this.props;
    const isRenderButtonAddMore = !!dynamicFilterQuery.length;
    return (
      <div className="d-flex flex-wrap mb-4">
        {
          (!MFlags.removeSearchQueryInputInDynamicFilterEnabled || showSearchBar) && (
            <SearchTextField
              value={this.state.searchString}
              placeHolder={placeHolder}
              onChange={this.handleSearchInputChange}
              onSearchInputKeyDown={this.onSearchInputKeyDown}
              hideIconOnSearchBar
              customClass="dynamic-filter-query__search-text-field mt-2 mr-2"
            />
          )
        }
        {this.renderCustomFilterSelection()}
        {isRenderButtonAddMore && this.renderButtonAddMore()}
        {this.renderButtonReset()}
        {this.renderPopperMenu()}
      </div>
    );
  }

  renderSearchQueryType(useNewSearchBar, dynamicFilterQuery) {
    if (useNewSearchBar) {
      return this.renderSearchBarV2();
    } else if (dynamicFilterQuery) {
      return this.renderDynamicFilter();
    } else {
      return this.renderSearchInput();
    }
  }

  renderSearchInput() {
    const { defaultFilter } = this.props;
    const placeholder = defaultFilter && defaultFilter.label && `Filter by ${defaultFilter.label}`;

    return (
      <>
        <FormGroup className="mb-2">
          <InputGroup>
            <Input
              type="text"
              id="search-input"
              placeholder={placeholder}
              value={this.state.searchString}
              onChange={this.handleSearchInputChange}
              onKeyDown={this.onSearchInputKeyDown}
              autoComplete="off"
              // autoCorrect="off"
              autoCapitalize="off"
              spellCheck="false"
            />
            <InputGroupAddon addonType="append">
              <Button
                data-trackid="copy-api-key"
                title={t('copyRequestParams')}
                color="link"
                onClick={() => this.props.onCopyButtonClick()}
              >
                <IconCopy />
              </Button>
            </InputGroupAddon>
          </InputGroup>
        </FormGroup>
        {this.renderSearchOptions()}
      </>
    );
  }

  render() {
    const { useNewSearchBar, useSortAndPaginationOnly, dynamicFilterQuery } = this.props;
    return useSortAndPaginationOnly ?
      (
        <Form className="float-right" onSubmit={(event) => event.preventDefault()}>
          {this.renderSortSelection()}
        </Form>
      )
      :
      (
        <Form onSubmit={(event) => event.preventDefault()} className="mt-2">
          {this.renderSearchQueryType(useNewSearchBar, dynamicFilterQuery)}
        </Form>
      );
  }
}

SearchQuery.propTypes = {
  filterOptions: PropTypes.object,
  sortOptions: PropTypes.arrayOf(PropTypes.string),
  sortLabelMap: PropTypes.object,
  searchString: PropTypes.string,
  onSortChange: PropTypes.func,
  onSearchInputChange: PropTypes.func,
  allowUnknownFilters: PropTypes.bool,
  defaultFilter: PropTypes.object,
  useNewSearchBar: PropTypes.bool,
  placeHolder: PropTypes.string,
  renderBackButton: PropTypes.func,
  /**
   * Use sort and pagination only (without filter and search input)
   */
  useSortAndPaginationOnly: PropTypes.bool,
  defaultDisplayFilterQuery: PropTypes.array,
  dynamicFilterQuery: PropTypes.array,
  isFromTestCase: PropTypes.bool,
  showSearchBar: PropTypes.bool,
};

SearchQuery.defaultProps = {
  filterOptions: null,
  sortOptions: null,
  sortLabelMap: null,
  onFilterChange: null,
  onSortChange: null,
  onSearchInputChange: null,
  allowUnknownFilters: true,
  defaultFilter: null,
  useNewSearchBar: false,
  defaultDisplayFilterQuery: [],
  dynamicFilterQuery: null,
  useSortAndPaginationOnly: false,
  isFromTestCase: false,
  showSearchBar: false,
};

export default SearchQuery;
