

import React from 'react';
import { Button, Form, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import Services from '../../utils/Services';
import Notification from '../../utils/Notification';
import DecoratorConstants from '../../utils/DecoratorConstants';

class DeleteExecutionDialog extends React.Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.saveDelete = this.saveDelete.bind(this);
    this.cancelDelete = this.cancelDelete.bind(this);
    this.state = {
      modal: false,
    };
  }

  toggle() {
    const { modal } = this.state;
    this.setState({
      modal: !modal,
    });
  }

  saveDelete(e) {
    e.preventDefault();
    const { executionId, projectId } = this.props;
    Services.deleteExecution(projectId, executionId).then(() => {
      const { afterCreate } = this.props;
      const executionIdStr = Array.isArray(executionId)
        ? executionId.map((id) => `#${id}`).join(', ')
        : `#${executionId}`;
      Notification.pushSuccess(`The execution ${executionIdStr} is being deleted.`);
      if (afterCreate) {
        afterCreate();
      }
      this.toggle();
    }).catch(() => {
      this.toggle();
    });
  }

  cancelDelete() {
    this.toggle();
  }

  render() {
    const { executionId } = this.props;
    const executionIdArr = Array.isArray(executionId) ? executionId : Array.of(executionId);

    return (
      <div>
        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <Form data-trackid="delete-execution" onSubmit={this.saveDelete}>
            <ModalHeader>Delete Execution</ModalHeader>
            <ModalBody>
              This action cannot be undone. Are you sure you want to delete the following&nbsp;
              {DecoratorConstants.pluralize('execution', executionIdArr.length)}:
              <strong> {executionIdArr.map((id) => `#${id}`).join(', ')}</strong>?
            </ModalBody>
            <ModalFooter>
              <Button type="submit" color="danger">Delete</Button>
              <Button color="secondary" onClick={this.cancelDelete}>Cancel</Button>
            </ModalFooter>
          </Form>
        </Modal>
      </div>
    );
  }
}


export default DeleteExecutionDialog;
