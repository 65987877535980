import { remove } from 'lodash';
import { TestOpsFeature } from '../../utils/Constants';
import { t } from '../../i18n/t';

export const FEATURE = {
  TESTOPS_FREE: 'TESTOPS_FREE',
  TESTOPS_BUSINESS: 'TESTOPS_BUSINESS',
  TESTOPS_ENTERPRISE: 'TESTOPS_ENTERPRISE',
};

export const INTERVAL = {
  YEAR: 'YEAR',
  MONTH: 'MONTH',
};

export const MAX_TEST_RESULTS = 500000;

export const TEST_EXECUTION_UNIT = 1000;

export const MIN_ESTIMATE_PRICE = 0;

export const STEP_ESTIMATE_PRICE = 10;

export const DISCOUNT_UPTO_ANNUAL = '15%';

export const getFeaturePriority = (feature) => {
  switch (feature) {
    case FEATURE.TESTOPS_BUSINESS:
      return 1;
    case FEATURE.TESTOPS_ENTERPRISE:
      return 2;
    case FEATURE.TESTOPS_FREE:
    default:
      return 0;
  }
};

export const getUnlimitedValue = (tiers) => parseInt(STEP_ESTIMATE_PRICE, 0) + parseInt(tiers[tiers.length - 1].endingQuantity, 0);

export const formatTier = (value) => {
  const nValue = Number(value);
  if (nValue > 1000) {
    return `${nValue / 1000}k`;
  }
  return nValue;
};

export const getMarks = (tiers, isAddUnlimited, isSlider) => {
  const step = STEP_ESTIMATE_PRICE;
  let pre = MIN_ESTIMATE_PRICE;
  return tiers.map((tier) => {
    const mark = {
      value: isSlider ? pre : tier.endingQuantity,
      label: formatTier(tier.endingQuantity),
      realValue: tier.endingQuantity,
    };
    pre += step;
    return mark;
  });
};

export const getValue = ((marks, value, attrCompare, attrReturn) => {
  const real = marks.filter((mark) => mark[attrCompare] != null && Number(mark[attrCompare]) === Number(value));
  if (real && real[0]) {
    if (real[0][attrReturn]) {
      return real[0][attrReturn];
    }
    return real[0][attrReturn];
  }
  return 0;
});


export const getMinTier = () => MIN_ESTIMATE_PRICE;

export const getMaxTier = (tiers) => (tiers.length - 1) * STEP_ESTIMATE_PRICE;

export const getTiers = (plan) => {
  const tiers = plan.tiers;
  if (tiers) {
    const lastTierIndex = tiers.length - 1;
    tiers[lastTierIndex].endingQuantity = MAX_TEST_RESULTS;
    remove(tiers, (tier) => tier.currencies[0]?.unitAmount === 0);
  }
  return tiers;
};

export const getSubscriptionName = (subscription) => {
  if (subscription === TestOpsFeature.TESTOPS_ENTERPRISE) {
    return t(TestOpsFeature.TESTOPS_ENTERPRISE);
  }
  if (subscription === TestOpsFeature.TESTOPS_BUSINESS) {
    return t(TestOpsFeature.TESTOPS_BUSINESS);
  }
}
