import _, { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, ButtonToolbar, CustomInput } from 'reactstrap';
import DeleteExecutionDialog from '../../components/dialog/DeleteExecutionDialog';
import TerminateExecutionDialog from '../../components/dialog/TerminateExecutionDialog';
import MTableColumnDataMapping from '../../components/table/models/MTableColumnDataMapping';
import { t } from '../../i18n/t';
import {
  IconMarkCompleted,
  IconReImport
} from '../../images/CustomIcon';
import { IconClose, IconDelete } from '../../images/KitIcons';
import MAuth from '../../models/MAuth';
import MConfigs from '../../models/MConfigs';
import MContext from '../../models/MContext';
import { ExecutionDecorator, ExecutionUtils, getExecutionTarget } from '../../pages/execution/ExecutionDecorator';
import Apis from '../../utils/Apis';
import Arrays from '../../utils/Arrays';
import DecoratorConstants from '../../utils/DecoratorConstants';
import Helper from '../../utils/Helper';
import Notification from '../../utils/Notification';
import Routes from '../../utils/Routes';
import Services from '../../utils/Services';
import ActionDropdownItem from '../action/ActionDropdownItem';
import ActionDropdownMenu from '../action/ActionDropdownMenu';
import StatusFilter, { StatusType } from '../search-query/filter/StatusFilter';
import TestSuiteCollectionFilter from '../search-query/filter/TestSuiteCollectionFilter';
import TestSuiteFilter from '../search-query/filter/TestSuiteFilter';
import TimeFilter from '../search-query/filter/TimeFilter';
import UserFilter from '../search-query/filter/UserFilter';
import InputFilter from '../search-query/filter/InputFilter';
import { buildFilter } from '../search-query/FilterQueryHelper';
import { buildSortQuery } from '../search-query/SortQueryHelper';
import DownloadExecutionButton from '../../pages/execution/DownloadExecutionButton';
import ReleaseBuildFilter from '../search-query/filter/ReleaseBuildFilter';
import TestCloudHelper from '../../utils/TestCloudHelper';
import GroupEvent from '../../utils/track/GroupEvent';
import DataTable from './DataTable';
import PlatformFilter from '../search-query/filter/PlatformFilter';
import { PlatformFilterType, TestSuiteType } from '../../utils/Constants';
import MFlags from '../../models/MFlags';
import AcknowledgeDialog from '../dialog/AcknowledgeDialog';

class ExecutionDataTable extends React.Component {

  constructor(props) {
    super(props);
    this.teamId = MContext.teamId;
    this.projectId = MContext.projectId;
    this.isTeamDemo = MContext.isTeamDemo;
    this.state = {
      rowOrder: null,
      projectId: null,
      selectedExecutions: {},
      isOpenDialogAcknowledgeReimport: false,
      visualTestingPlan: null,
      idReimport: null
    };
    this.executionList = null;
    this.deleteRef = React.createRef();
    this.terminateRef = React.createRef();
    this.maxSuites = 3;
    this.renderSelected = this.renderSelected.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.handleCompareBtn = this.handleCompareBtn.bind(this);
    this.handleDeleteBtn = this.handleDeleteBtn.bind(this);
    this.handleDownloadResultsBtn = this.handleDownloadResultsBtn.bind(this);
    this.handleCloseReimportDialog = this.handleCloseReimportDialog.bind(this);
    this.handleReimportExecution = this.handleReimportExecution.bind(this);
    this.isTeamDemo = MAuth.isTeamDemo(this.teamId);
  }

  componentDidMount() {
    if (!this.isTeamDemo) {
      const organization = MContext.targetOrganization;
      Services.getVisualTestingQuota(organization.id).then((visualTestingPlan) => {
        this.setState({ visualTestingPlan });
      });
    }
  }

  showDeleteExecutionDialog(projectId, rowOrder) {
    this.setState(
      {
        rowOrder,
        projectId,
      },
      () => this.deleteRef.current.toggle(),
    );
  }

  showTerminateExecutionDialog(projectId, rowOrder) {
    this.setState(
      {
        rowOrder,
        projectId,
      },
      () => this.terminateRef.current.toggle(),
    );
  }

  clearSelectedExecution() {
    this.setState({ selectedExecutions: {} });
  }

  handleCheckboxChange(event) {
    const order = event.target.value;
    this.toggleSelectExecution(order);
  }

  toggleSelectExecution(order) {
    this.setState((prevState) => ({
      selectedExecutions: {
        ...prevState.selectedExecutions,
        [order]: !prevState.selectedExecutions[order],
      },
    }));
  }

  getSelectedExecutionOrders() {
    const { selectedExecutions } = this.state;
    return Object.keys(selectedExecutions)
      .filter((id) => selectedExecutions[id]);
  }

  handleCompareBtn() {
    const executionOrders = this.getSelectedExecutionOrders();
    const maxExecution = MConfigs.maxExecutionComparison;
    if (executionOrders.length > maxExecution) {
      Notification.pushError(`You can compare only ${maxExecution} executions at the same time.`, 'Unable to compare');
    } else {
      Routes.gotoCompareExecutionPage(executionOrders);
    }
  }

  handleDownloadResultsBtn(fileType) {
    const executionOrders = this.getSelectedExecutionOrders();
    const maxExecution = MConfigs.maxExecutionDownload;
    if (executionOrders.length > maxExecution) {
      Notification.pushError(`You can download only ${maxExecution} executions at the same time.`, 'Unable to download');
    } else {
      Helper.download(Apis.executionBulkDownload(this.projectId, executionOrders, fileType));
    }
  }

  handleDeleteBtn() {
    if (this.isTeamDemo) {
      Notification.pushError(t('read-only-mode#error-message'), 'Error');
      return;
    }
    const executionOrders = this.getSelectedExecutionOrders();
    this.setState(
      {
        rowOrder: executionOrders,
        projectId: this.projectId,
      },
      () => this.deleteRef.current.toggle(),
    );
  }

  renderSelected() {
    const executionOrders = this.getSelectedExecutionOrders();
    const total = executionOrders.length;
    const disableCompareBtn = total < 2;
    if (total > 0) {
      return (
        <ButtonToolbar className="mb-3">
          <Button data-trackid="bulk-delete-execution" color="danger" onClick={this.handleDeleteBtn}>Delete</Button>
          <DownloadExecutionButton
            color="primary"
            onSelectOption={(fileType) => this.handleDownloadResultsBtn(fileType)}
          />
          <Button
            data-trackid="compare-execution"
            disabled={disableCompareBtn}
            color="primary"
            onClick={this.handleCompareBtn}
          >
            Compare
          </Button>
          {executionOrders.map((order) => (
            <Button key={order} onClick={() => this.toggleSelectExecution(order)}>#{order}<IconClose /></Button>
          ))}
        </ButtonToolbar>
      );
    }
    return null;
  }

  renderDeleteDialogs() {
    const { projectId, rowOrder } = this.state;
    const { teamId } = this;
    return (
      <>
        {
          <TerminateExecutionDialog
            ref={this.terminateRef}
            executionId={rowOrder}
            projectId={projectId}
            teamId={teamId}
          // afterCreate={() => this.executionList.refreshData()}
          />
        }
        {
          <DeleteExecutionDialog
            ref={this.deleteRef}
            executionId={rowOrder}
            projectId={projectId}
            afterCreate={() => this.clearSelectedExecution()}
          />
        }
      </>
    );
  }

  renderDeleteButtons(row) {
    const { teamId } = this;
    const isShowDeleteExecutionIcon = MAuth.isTeamManager(teamId);
    if (isShowDeleteExecutionIcon) {
      if (row.executionStage === 'RUNNING') {
        return (
          <ActionDropdownItem
            icon={IconMarkCompleted}
            label={t('terminate')}
            tag="button"
            onClick={() => this.showTerminateExecutionDialog(row.project.id, row.order)}
            color="link"
            title={t('terminate')}
          />
        );
      } else {
        return (
          <ActionDropdownItem
            icon={IconDelete}
            label="Delete"
            tag="button"
            onClick={() => this.showDeleteExecutionDialog(row.project.id, row.order)}
            color="link"
            title="Delete Execution"
          />
        );
      }
    }
    return null;
  }

  renderReImportButton(row) {
    const isSampleData = row.dataType === 'SAMPLE_DATA';
    return (
      <ActionDropdownItem
        data-trackid="re-import-execution"
        icon={IconReImport}
        label="Re-import"
        tag="button"
        onClick={() => this.handleReimportExecution(row)}
        color="link"
        title="Re-import Execution"
        disabled={isSampleData}
      />
    );
  }

  handleReimportExecution(row) {
    const { visualTestingPlan } = this.state;
    const { kEyesExecution, id } = row;
    const isShowDialog = kEyesExecution && visualTestingPlan?.remainingQuota < 0;
    if (isShowDialog) {
      this.setState({
        isOpenDialogAcknowledgeReimport: isShowDialog,
        idReimport: id,
      });
    } else {
      Services.reImportExecution(id);
    }
  }

  renderReimportDialogs() {
    const { isOpenDialogAcknowledgeReimport } = this.state;
    return (
      <AcknowledgeDialog
        isOpen={isOpenDialogAcknowledgeReimport}
        id="acknowledge-reimport-dialog"
        handleClose={this.handleCloseReimportDialog}
        handleAcknowledge={() => this.handleAcknowledge()}
        title={t('acknowledge-reimport-execution-title')}
        content={t('acknowledge-reimport-execution-content')}
      />
    );
  }

  handleCloseReimportDialog() {
    this.setState({
      isOpenDialogAcknowledgeReimport: false,
    });
  }

  handleAcknowledge() {
    const { idReimport } = this.state;
    Services.reImportExecution(idReimport);
    this.handleCloseReimportDialog();
  }

  getOrderFromRow(row) {
    return _.get(row, 'order');
  }

  render() {
    const {
      showProjectColumn, showCheckboxColumn, additionalFilterQuery
    } = this.props;
    let { filterQuery } = this.props;
    const { selectedExecutions } = this.state;
    let columnMapping = [
      ...Arrays.insertIf(showCheckboxColumn, new MTableColumnDataMapping(
        t('table-header#action'),
        'id',
        (name, row) => {
          if (row.executionStage === 'RUNNING') {
            return null;
          } else {
            return (<CustomInput
              type="checkbox"
              id={row.id}
              value={row.order}
              checked={selectedExecutions[row.order]}
              onChange={this.handleCheckboxChange}
            />);
          }
        },
        undefined,
        'fit-content-column',
      )),
      new MTableColumnDataMapping(
        t('status'),
        '',
        ExecutionDecorator.statusDecorator,
        undefined,
        'fit-content-column',
      ),
      new MTableColumnDataMapping(
        t('id'),
        'order',
        (name, row) => {
          const constructedLink = new Routes({
            executionId: row.order,
            projectId: row.project.id,
          });
          const linkProps = {
            'data-trackid': 'click-test-run-details',
            'data-groupid': GroupEvent.ACCESS_REPORT
          };
          return DecoratorConstants.idDecorator(name, row, constructedLink.execution_details_link, null, null, linkProps);
        },
        undefined,
        'fit-content-column',
      ),
    ];
    if (showProjectColumn) {
      columnMapping.push(
        new MTableColumnDataMapping(
          t('table-header#project'),
          'project.name',
        ),
      );
    }
    columnMapping = columnMapping.concat([
      new MTableColumnDataMapping(
        t('table-header#testsuite'),
        '',
        (name, row) => {
          const isDisplayTestCloudTunnelIcon = TestCloudHelper.isDisplayTestCloudTunnelIcon(row);
          const nameContent = isDisplayTestCloudTunnelIcon ? ExecutionDecorator.testCloudTunnelNameDecorator(row) : ExecutionDecorator.nameDecorator(row);
          const testSuiteCollectionContent = ExecutionDecorator.testSuiteCollectionDecorator(row);
          const testSuiteList = ExecutionDecorator.testSuiteListDecorator(row);

          const hasPlan = ExecutionUtils.hasTestPlan(row);
          const hasTestSuiteCollection = ExecutionUtils.hasTestSuiteCollection(row);

          return (
            <>
              <div className="label-text">{nameContent}</div>
              {hasPlan && testSuiteCollectionContent}
              {(hasPlan || hasTestSuiteCollection) && testSuiteList}
            </>
          );
        },
      ),
      new MTableColumnDataMapping(
        t('table-header#platform-profile'),
        '',
        (name, row) => {
          const executionTargets = getExecutionTarget(row);
          const platforms = executionTargets.map((executionTargets) => executionTargets.platform);
          const testSuiteType = executionTargets.map((executionTargets) => executionTargets.testSuiteType);
          return (
            <div className="d-flex flex-column">
              <span>
                {DecoratorConstants.osDecorator(platforms)}
              </span>
              <span>
                {DecoratorConstants.browsersDecorator(platforms)}
              </span>
              { MContext.testCloudMobileNativeAppEnabled &&
                <span>
                  {DecoratorConstants.appNameDecorator(platforms)}
                </span>}
              {MFlags.testCloudG5TestSuiteEnabled && !isEmpty(testSuiteType) && testSuiteType[0] === TestSuiteType.CLOUD_STUDIO ?
                <></>
                :
                <span>
                  {ExecutionDecorator.profileDecorator(row)}
                </span>}
            </div>
          );
        }
      ),
      new MTableColumnDataMapping(
        t('time'),
        '',
        DecoratorConstants.executionElapsedTimeAndDurationDecorator,
        undefined,
        'nowrap-column',
      ),
      new MTableColumnDataMapping(
        t('table-header#total'),
        'totalTests',
        DecoratorConstants.totalTestRunDecorator,
      ),
      new MTableColumnDataMapping(
        DecoratorConstants.passedBadgeDecorator(t('table-header#totalPassed')),
        'totalPassedTests',
        DecoratorConstants.passedCountDecorator,
      ),
      new MTableColumnDataMapping(
        DecoratorConstants.failedBadgeDecorator(t('table-header#totalFailed')),
        'totalFailedTests',
        DecoratorConstants.failedCountDecorator,
      ),
      new MTableColumnDataMapping(
        DecoratorConstants.errorBadgeDecorator(t('table-header#totalError')),
        'totalErrorTests',
        DecoratorConstants.errorCountDecorator,
      ),
      new MTableColumnDataMapping(
        DecoratorConstants.incompleteBadgeDecorator(t('table-header#totalIncomplete')),
        'totalIncompleteTests',
        DecoratorConstants.incompleteCountDecorator,
      ),
      new MTableColumnDataMapping(
        DecoratorConstants.skippedBadgeDecorator(t('table-header#totalSkipped')),
        'totalSkippedTests',
        DecoratorConstants.skippedCountDecorator,
      ),
      new MTableColumnDataMapping(
        t('config'),
        '',
        ExecutionDecorator.buildAndRelease,
      ),
      new MTableColumnDataMapping(
        'By',
        'user',
        DecoratorConstants.avatarOnlyDecorator,
        undefined,
        'fit-content-column',
      ),
      new MTableColumnDataMapping(
        t('table-header#action'),
        'id',
        (name, row) => {
          const id = _.get(row, name);
          const items = [
            this.renderDeleteButtons(row),
            this.renderReImportButton(row),
          ];
          return (
            <>
              <ActionDropdownMenu hideHeader rowId={`execution_${id}`} direction="left">
                {items}
              </ActionDropdownMenu>
              {row.hasComment &&
                <div className="btn btn-link">
                  {DecoratorConstants.totalCommentDecorator('hasComment', row)}
                </div>}
            </>
          );
        },
        true,
      ),
    ]);

    if (!filterQuery) {
      filterQuery = [
        buildFilter(TestSuiteFilter, { id: 'TestSuite.name' }),
        buildFilter(TestSuiteCollectionFilter, { id: 'TestSuiteCollectionEntity.name' }),
        buildFilter(StatusFilter, { id: 'status', type: StatusType.EXECUTION }),
        buildFilter(TimeFilter, { id: 'startTime', label: 'Started' }),
        buildFilter(InputFilter, { id: 'ExecutionTestResult.profile', label: 'Profile', operator: '~' }),
        buildFilter(UserFilter, { id: 'User.id' }),
        buildFilter(ReleaseBuildFilter, { id: 'ReleaseBuild' }),
        buildFilter(InputFilter, { id: 'buildLabel', label: t('buildLabel'), operator: '~' }),
        buildFilter(PlatformFilter, { id: 'platform.osName', type: PlatformFilterType.BY_OS_NAME }),
        buildFilter(PlatformFilter, { id: 'platform.browserName', type: PlatformFilterType.BY_BROWSER_NAME })
      ];
    }

    if (additionalFilterQuery) {
      filterQuery = filterQuery.concat(additionalFilterQuery);
    }

    const sortQuery = [
      ...buildSortQuery('order', t('id')),
      ...buildSortQuery('startTime', t('table-header#startTime')),
      ...buildSortQuery('duration', t('table-header#duration')),
    ];
    const newProps = {
      ...this.props,
      columnMapping,
      filterQuery,
      sortQuery,
    };
    return (
      <>
        <DataTable
          title={t('test-run-title')}
          ref={(ref) => {
            this.executionList = ref;
          }}
          renderSelect={this.renderSelected}
          entityType="Execution"
          showTable
          {...newProps}
        />
        {this.renderDeleteDialogs()}
        {this.renderReimportDialogs()}
      </>
    );
  }
}

ExecutionDataTable.propTypes = {
  showProjectColumn: PropTypes.bool,
  showCheckboxColumn: PropTypes.bool,
};

ExecutionDataTable.defaultProps = {
  showProjectColumn: true,
  showCheckboxColumn: false,
};

export default ExecutionDataTable;
