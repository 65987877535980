
import React from 'react';
import PageComponent from '../components/PageComponent';
import { t } from '../i18n/t';
import EmptyProject from '../components/EmptyProject';

export default class NoExecutionHistory extends PageComponent {
  constructor(props) {
    super(props);
    this.meta.id = 'page-no-execution-history';
    this.meta.title = t('no_execution_history#tab_name');
  }

  render() {
    return <EmptyProject />;
  }
}
