import React from 'react';
import parse from 'url-parse';
import { Alert, Button, Col, Row } from 'reactstrap';
import { t } from '../i18n/t';
import Apis from '../utils/Apis';
import http from '../utils/http';
import PageComponent from '../components/PageComponent';
import Routes from '../utils/Routes';
import ImageProfile from '../components/avatar/ImageProfile';
import MAuth from '../models/MAuth';
import Notification from '../utils/Notification';
import DocumentLink from '../utils/DocumentLink';
import { IconInvalidInvitationToken } from '../images/CustomIcon';


class AcceptInvitation extends PageComponent {
  constructor(props) {
    super(props);
    this.meta.id = 'page-accept-invitation';
    this.meta.title = t('Accept Invitation');
    const url = parse(window.location.href, true);
    this.token = url.query.token;
    this.user = MAuth.user;
    this.state = {
      accepted: false,
      organization: null,
      pendingSsoInvitation: false,
      isLoading: true,
    };

    this.handleAccept = this.handleAccept.bind(this);
    this.handleDecline = this.handleDecline.bind(this);
  }

  getInvitationFromToken() {
    http.get(Apis.invitations({ token: this.token }))
      .then((userInvitation) => {
        this.setState({
          organization: userInvitation.organization,
        });
      })
      .finally(() => {
        this.setState({
          isLoading: false,
        });
      });
  }

  handleAccept() {
    http.put(Apis.acceptInvitation({ token: this.token }))
      .then((userInvitation) => {
        this.setState({
          accepted: true,
          organization: userInvitation.organization,
          pendingSsoInvitation: userInvitation.pendingSsoInvitation,
        });
        Notification.pushSuccess('The invitation has been accepted');
      });
  }

  handleDecline() {
    const { organization } = this.state;
    this.setState({
      disableButton: true
    });
    http.put(Apis.declineInvitation({ organizationId: organization.id, token: this.token }));
    Routes.goToHomePage();
  }

  componentWillMount() {
    this.getInvitationFromToken();
  }

  renderAcceptInvitation() {
    const { organization, disableButton } = this.state;

    return (
      <Col>
        <Col sm="12" md="10" lg="8" xl="8">
          <div className="org-name">
            {organization.name}
          </div>
          <div className="header">
            Invitation to join organization
          </div>
          <div className="content">
            The Organization Admin has invited you to join this organization.
            <br />
            <span className="font-weight-bold">Your account:</span> {this.user.email}
            {organization.subdomainUrl && (
              <p><span className="font-weight-bold">Subdomain:</span> {organization.subdomainUrl}</p>
            )}
          </div>
          <div className="button-zone">
            <Button
              color="danger"
              onClick={this.handleDecline}
              disabled={disableButton}
            >
              Decline
            </Button>
            <Button
              color="primary"
              onClick={this.handleAccept}
              disabled={disableButton}
            >
              {`Join ${organization.name}`}
            </Button>
          </div>
          <div className="footer">
            If you do not recognize this action, report to us via <b>support@katalon.com</b>.
          </div>
        </Col>
      </Col>
    );
  }

  renderWelcome() {
    const { organization, pendingSsoInvitation, disableButton } = this.state;
    const route = new Routes({
      organizationId: organization ? organization.id : null,
    });

    return (
      <Col>
        <Col sm="12" md="10" lg="8" xl="8">
          <div className="org-name">
            {`Welcome to ${organization.name}`}
          </div>
          <div className="content">
            <span className="font-weight-bold">Your account:</span> {this.user.email}
            {organization.subdomainUrl && (
              <p><span className="font-weight-bold">Subdomain:</span> {organization.subdomainUrl}</p>
            )}
          </div>
          {pendingSsoInvitation && (
            <Alert color="primary" className="alert">
              {`The Organization Admin at ${organization.name} has requested to enable Single Sign-On (SSO) for your account.
              Please check your inbox and confirm the request. `}
              <a href={DocumentLink.ACCEPT_SSO} target="_blank" rel="noopener noreferrer">Learn more</a>.
            </Alert>
          )}
          <Button
            className="mt-4"
            color="primary"
            href={organization.subdomainUrl || route.organization_link}
            disabled={disableButton}
          >
            Go to {organization.name}
          </Button>
        </Col>
      </Col>
    );
  }

  render() {
    const { organization, accepted, isLoading } = this.state;

    if (isLoading) {
      return null;
    }

    return (
      <div className="invitation">
        {organization && (
          <Row className="justify-content-center">
            <Col xs="auto">
              <ImageProfile
                name={organization.name}
                imgUrl={organization.logoUrl}
              />
            </Col>
            {!accepted && this.renderAcceptInvitation()}
            {accepted && this.renderWelcome()}
          </Row>
        )}
        {!organization && !accepted && (
          <Row>
            <Col xs="12" sm="12" md="12" className="align-self-center">
              <div className="d-flex flex-column align-items-center text-center">
                <IconInvalidInvitationToken />
                <br />
                <h3 className="font-weight-bolder">
                  {t('accept_invitation.invalid_token.title')}
                </h3>
                <br />
                <h5 className="font-weight-normal">
                  {t('accept_invitation.invalid_token.info')}
                </h5>
                <h5 className="font-weight-normal">
                  {t('accept_invitation.invalid_token.contact')}
                </h5>
              </div>
            </Col>
          </Row>
        )}
      </div>
    );
  }
}

export default AcceptInvitation;
