import React from 'react';
import { Modal, ModalHeader, ModalBody, Button } from 'reactstrap';
import classNames from 'classnames';
import LogTreeContainer from '../../pages/execution_test_result/components/LogTreeContainer';
import TextViewer from './file_viewers/Text';
import TextLogViewer from './file_viewers/TextLog';
import NoPreviewViewer from './file_viewers/NoPreview';

import {
  // IconToggleLineSpacingActive,
  // IconToggleLineSpacingInactive,
  IconDownloadActive,
  IconDownloadInactive,
  IconCloseActive,
  IconCloseInactive,
} from '../../images/CustomIcon';

class FilePreview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      tabs: [],
      activeTab: null,
    };
    this.toggle = this.toggle.bind(this);
  }

  toggle = () => {
    this.setState((state) => ({
      isOpen: !state.isOpen,
    }));
  };

  setTabs(tabs, defaultTab = 0) {
    this.setState({
      tabs,
      activeTab: tabs[defaultTab],
    });
  }

  setActiveTab(tab) {
    this.setState({
      activeTab: tab,
    });
  }

  renderTabButtons() {
    return this.state.tabs.map((tab) =>
      <Button
        key={tab.title}
        onClick={() => this.setActiveTab(tab)}
        className={classNames('tab-button border-0 rounded-0', { active: tab === this.state.activeTab })}
        color="link"
      >
        {tab.title}
      </Button>);
  }

  renderActionButtons() {
    const { activeTab } = this.state;
    return (
      <div className="d-flex">
        {/* <Button
          className="action-button two-state-button"
          color="link"
          key="expand"
          title="Line Spacing"
        >
          <IconToggleLineSpacingActive className="icon-active" />
          <IconToggleLineSpacingInactive className="icon-inactive" />
        </Button> */}
        {activeTab && activeTab.file && activeTab.file.source && (
          <Button
            className="action-button two-state-button"
            color="link"
            key="download"
            title="Download"
          >
            <a
              href={activeTab.file.source}
              download={activeTab.file.name}
            >
              <IconDownloadActive className="icon-active" />
              <IconDownloadInactive className="icon-inactive" />
            </a>
          </Button>)}
        <Button
          className="action-button two-state-button"
          color="link"
          key="close"
          title="Close"
          onClick={this.toggle}
        >
          <IconCloseActive className="icon-active" />
          <IconCloseInactive className="icon-inactive" />
        </Button>
      </div>);
  }

  renderTabContent() {
    const { activeTab } = this.state;
    if (!activeTab || !activeTab.file) return null;
    switch (activeTab.file.type) {
      case 'text-log':
        return <TextLogViewer content={activeTab.file.content} className={activeTab.customClass} />;
      case 'text':
        return <TextViewer content={activeTab.file.content} className={activeTab.customClass} />;
      case 'tree-log':
        return <LogTreeContainer log={activeTab.file.content} className={activeTab.customClass} {...activeTab.props} />;
      default:
        return <NoPreviewViewer file={activeTab.file} className={activeTab.customClass} />;
    }
  }

  render() {
    const { isOpen } = this.state;

    return (
      <Modal
        {...this.props}
        isOpen={isOpen}
        size="lg"
        aria-labelledby=""
        centered
        wrapClassName="file-preview"
        fade={false}
        // backdrop="false"
      >
        <ModalHeader>
          <div className="d-flex flex-wrap">
            <div className="file-preview__tab-buttons">{this.renderTabButtons()}</div>
            <div className="file-preview__action-button-group">{this.renderActionButtons()}</div>
          </div>
        </ModalHeader>
        <ModalBody>
          <div className="file-preview__content-wrapper">
            {this.renderTabContent()}
          </div>
        </ModalBody>
      </Modal>
    );
  }
}

export default FilePreview;
