import { Chip } from '@mui/material';
import React from 'react';
import ChipGroup from './ChipGroup';

const TagChip = ({ name, handleRemove }) => (
  <ChipGroup className="mr-2 mb-2" handleRemove={handleRemove} isRemovable>
    <Chip
      variant="outlined"
      label={name}
      title={name}
      size="small"
    />
  </ChipGroup>
);

export default TagChip;
