import React from 'react';
import { Button } from 'reactstrap';
import { t } from '../../i18n/t';
import PageComponent from '../../components/PageComponent';
import MContext from '../../models/MContext';
import { IconAIPowered } from '../../images/CustomNewIcon';
import VisualBaselineCollectionsDataTable from './component/VisualBaselineCollectionsDataTable';
import ObjectSummary from '../../components/summary/ObjectSummary';
import Services from '../../utils/Services';
import { OrganizationFeature } from '../../utils/Constants';
import PageButtonToolbar from '../../components/PageButtonToolbar';
import ConfigBaselineDialog from './component/dialog/ConfigBaselineDialog';
import GroupEvent from '../../utils/track/GroupEvent';

class VisualBaselineCollections extends PageComponent {

  constructor(props) {
    super(props);
    this.teamId = MContext.teamId;
    this.projectId = MContext.projectId;
    this.project = MContext.project;
    this.isTeamDemo = MContext.isTeamDemo;
    this.meta.id = 'page-visual-testing';
    this.meta.title = t(
      'Project {{name}} - {{page}}',
      { name: this.project.name, page: t('testops-keyes') },
    );
    this.state = {
      visualTestingPlan: null,
      isOpenDialogBaselineCollection: false
    };
    this.renderBody = this.renderBody.bind(this);
    this.renderHeader = this.renderHeader.bind(this);
    this.renderAddBaselineCollection = this.renderAddBaselineCollection.bind(this);
    this.openDialogCreateBaselineCollection = this.openDialogCreateBaselineCollection.bind(this);
    this.closeDialogCreateBaselineCollection = this.closeDialogCreateBaselineCollection.bind(this);
  }

  componentDidMount() {
    if (!this.isTeamDemo) {
      const organization = MContext.targetOrganization;
      Services.getVisualTestingQuota(organization.id).then((visualTestingPlan) => {
        this.setState({ visualTestingPlan });
      });
    }
  }

  renderHeader() {
    const { project } = this;
    const urlParams = {
      visualBaselineCollections: (
        <div className="d-flex align-items-center">
          <span>{t('visual_baseline_collections')}</span>
          <IconAIPowered className="ml-2" />
        </div>
      ),
      projectName: project.name,
      title: t('baseline_collection'),
    };
    return (
      <ObjectSummary params={urlParams} />
    );
  }

  renderAddBaselineCollection() {
    const { visualTestingPlan } = this.state;
    const enabledCreateBaseliseCollection = visualTestingPlan?.visualTestingFeature === OrganizationFeature.VISUAL_TESTING_PRO;
    return (
      <PageButtonToolbar>
        <Button
          disabled={!enabledCreateBaseliseCollection}
          id="create-baseline-collection-btn"
          title={enabledCreateBaseliseCollection ?
            t('visual-testing#baseline-collection#create-baseline-collection-btn') :
            t('visual-testing#baseline-collection#upload-image-tooltip-vst-standard')}
          color="primary"
          onClick={this.openDialogCreateBaselineCollection}
          data-trackid="create-baseline-collection"
          data-groupid={GroupEvent.ACCESS_REPORT}
        >
          {t('visual-testing#baseline-collection#create-baseline-collection-btn')}
        </Button>
      </PageButtonToolbar>
    );
  }

  openDialogCreateBaselineCollection() {
    this.setState({ isOpenDialogBaselineCollection: true });
  }

  closeDialogCreateBaselineCollection() {
    this.setState({ isOpenDialogBaselineCollection: false });
  }

  renderBody() {
    const { isOpenDialogBaselineCollection } = this.state;
    return (
      <>
        {this.renderAddBaselineCollection()}
        <VisualBaselineCollectionsDataTable {...this.props} />
        {isOpenDialogBaselineCollection &&
          <ConfigBaselineDialog
            id="create-baseline-collection-dialog"
            isOpen={isOpenDialogBaselineCollection}
            handleClose={this.closeDialogCreateBaselineCollection}
            title={t('visual-testing#baseline-collection#create-baseline-collection-title')}
            projectId={this.projectId}
          />}
      </>
    );
  }

  render() {
    return this.renderBody();
  }

}

export default VisualBaselineCollections;
