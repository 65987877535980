import React from 'react';
import { Alert, Card, CardBody, CardHeader, Col, Row } from 'reactstrap';

import MTableColumnDataMapping from '../../components/table/models/MTableColumnDataMapping';
import { t } from '../../i18n/t';
import { OrganizationFeature, SearchEntity } from '../../utils/Constants';
import BaseEngineLicensesTab from './BaseEngineLicensesTab';

class OrganizationEngineLicensesTab extends BaseEngineLicensesTab {
  constructor(props) {
    super({ ...props, feature: OrganizationFeature.ENGINE });
    this.meta.id = 'page-organization-engine-licenses';
    this.meta.title = t('engine_license_keys');
  }

  userHeader() {
    return [
      new MTableColumnDataMapping(t('name'), 'fullName'),
      new MTableColumnDataMapping(t('email'), 'email'),
    ];
  }

  renderLicensedUser() {
    return (
      <Card id="LicensedUsersSection">
        <CardHeader>{t('license_keys#online@licensed_users')}</CardHeader>
        <CardBody>
          <Row>
            <Col>
              <Alert color="primary">
                {t('license_keys#online@engine_notice')}
              </Alert>
            </Col>
          </Row>
          {this.renderLicensedTableOfFeature(OrganizationFeature.UNLIMITED_ENGINE)}
        </CardBody>
      </Card>
    );
  }
}

export default OrganizationEngineLicensesTab;
