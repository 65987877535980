import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'reactstrap';
import MFlags from '../../models/MFlags';
import { t } from '../../i18n/t';
import { IconDelete } from '../../images/KitIcons';
import MContext from '../../models/MContext';
import { ObjectType, SearchEntity } from '../../utils/Constants';
import DecoratorConstants from '../../utils/DecoratorConstants';
import Services from '../../utils/Services';
import { buildSearchCondition } from '../search/SearchUtils';
import DataTable from '../table/DataTable';
import MTableColumnDataMapping from '../table/models/MTableColumnDataMapping';

class ExternalIssueDataTable extends React.Component {

  projectId = this.props.projectId || MContext.projectId;

  unlinkExternalIssue({ issueId }) {
    const { objectType, objectId } = this.props;
    const params = {
      issueId,
      objectType,
      objectId,
    };
    Services.unlinkExternalIssue(this.projectId, params);
  }

  isJiraIssue(row) {
    return _.get(row, 'issueId') != null;
  }

  render() {
    const {
      objectType,
      objectId,
      defaultSearchConditions: searchConditions,
      defaultSort,
      ...rest
    } = this.props;

    let entityType;
    let objectKey;
    const defaultSearchConditions = [...searchConditions];
    if (objectType === ObjectType.EXECUTION_TEST_RESULT) {
      entityType = SearchEntity.ExternalDefect;
      objectKey = `${SearchEntity.ExecutionTestResult}.id`;
    } else if (objectType === ObjectType.TEST_CASE) {
      entityType = SearchEntity.ExternalRequirement;
      objectKey = `${SearchEntity.TestCase}.id`;
    } else if (objectType === ObjectType.XRAY_TEST_CASE) {
      entityType = SearchEntity.ExternalXrayTest;
      objectKey = `${SearchEntity.TestCase}.id`;
    }

    const columnMapping = [
      new MTableColumnDataMapping(
        'ID/Feature',
        '',
        (name, row) => {
          if (this.isJiraIssue(row)) {
            return DecoratorConstants.externalIssueIdDecorator(name, row);
          } else {
            return DecoratorConstants.featureNameDecorator('featureName', row);
          }
        },
        undefined,
        'nowrap-column',
      ),
      new MTableColumnDataMapping(
        'Summary',
        'summary',
      ),
      new MTableColumnDataMapping(
        'Status',
        'status',
        (name, row) => {
          if (MFlags.jiraIssueLinkingCreatingEnabled) {
            return DecoratorConstants.defectStatusDecorator(row);
          } else {
            return (<div>{_.get(row, name)}</div>);
          }
        },
        undefined,
        'nowrap-column',
      ),
    ];

    if (objectId) {
      defaultSearchConditions.push(buildSearchCondition(objectKey, '=', objectId));
      columnMapping.push(new MTableColumnDataMapping(
        t('table-header#action'),
        'issueId',
        (name, row) => {
          if (this.isJiraIssue(row)) {
            return MFlags.jiraIssueLinkingCreatingEnabled ?
              (
                <div className="text-right">
                  <Button
                    data-trackid="unlink-jira-issue"
                    className="btn-icon-only"
                    color="link"
                    onClick={() => this.unlinkExternalIssue(row)}
                    title={t('unlink-issue')}
                  >
                    <IconDelete />
                  </Button>
                </div>) :
              (
                <Button
                  data-trackid="unlink-jira-issue"
                  className="btn-icon-only"
                  color="link"
                  onClick={() => this.unlinkExternalIssue(row)}
                  title={t('unlink-issue')}
                >
                  <IconDelete />
                </Button>
              );
          }
          return null;
        }
      ));
    }

    return (
      <DataTable
        columnMapping={columnMapping}
        entityType={entityType}
        defaultSearchConditions={defaultSearchConditions}
        defaultSort={defaultSort}
        {...rest}
      />
    );
  }
}

ExternalIssueDataTable.propTypes = {
  projectId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  objectType: PropTypes.string,
  defaultSearchConditions: PropTypes.array,
  defaultSort: PropTypes.array,
};

ExternalIssueDataTable.defaultProps = {
  defaultSearchConditions: [],
  defaultSort: ['updatedAt,desc'],
};

export default ExternalIssueDataTable;
