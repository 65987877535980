import React from 'react';
import _ from 'lodash';
import { Card, CardHeader, CardBody, Button } from 'reactstrap';
import { t } from '../../../../i18n/t';
import BillingInformation from '../../../organization_licenses/BillingInformation';
import BillingInformationForm from '../../../organization_licenses/BillingInformationForm';
import Services from '../../../../utils/Services';
import MContext from '../../../../models/MContext';
import SubscriptionPrices from '../../../SubscriptionPrices';
import PaymentMethodInformation from '../../../organization_licenses/PaymentMethodInformation';

export default class extends React.Component {
  get paymentMethodForm() {
    return this.paymentMethodFormRef.current && this.paymentMethodFormRef.current.paymentMethodForm();
  }

  get isReadyToPay() {
    const { paymentMethod } = this.props;
    return !_.isEmpty(paymentMethod) || (this.paymentMethodForm && this.paymentMethodForm.isReadyToPay);
  }

  get isBillingInfoReady() {
    const { billingInformation } = this.state;
    return !_.isEmpty(billingInformation) || this.billingInfoFormRef?.current?.isReady();
  }

  constructor(props) {
    super(props);
    this.paymentMethodFormRef = React.createRef();
    this.billingInfoFormRef = React.createRef();
    this.handlePaymentMethodChange = this.handlePaymentMethodChange.bind(this);
    this.handleCheckout = this.handleCheckout.bind(this);
    this.handleBillingFormChange = this.handleBillingFormChange.bind(this);
    this.checkout = this.checkout.bind(this);
    this.renderBody = this.renderBody.bind(this);
    this.handleBillingInfoUpdated = this.handleBillingInfoUpdated.bind(this);
    this.changeCardStatus = this.changeCardStatus.bind(this);

    this.organizationId = MContext.organizationId;

    this.state = {
      billingInformation: null,
      cardUpdating: false
    };
  }

  componentDidMount() {
    const { billingInformation } = this.props;
    if (billingInformation) {
      this.setState({ billingInformation });
    } else {
      this.getBillingInfo();
    }
  }

  getBillingInfo() {
    Services.getBillingInformation(this.organizationId)
      .then((billingInformation) => this.setState({ billingInformation }));
  }

  handlePaymentMethodChange() {
    this.forceUpdate();
  }

  handleBillingFormChange() {
    this.forceUpdate();
  }

  updateBillingInfoAndCheckout() {
    if (this.billingInfoFormRef.current && this.billingInfoFormRef.current.hasChanged) {
      this.billingInfoFormRef.current.handleSubmit();
    } else {
      this.checkout();
    }
  }

  checkout() {
    const { paymentMethod } = this.props;
    if (this.paymentMethodForm && _.isEmpty(paymentMethod)) {
      this.paymentMethodForm.createToken()
        .then((token) => {
          if (this.props.onCheckout) {
            this.props.onCheckout(token);
          }
        });
    } else {
      if (this.props.onCheckout) {
        this.props.onCheckout();
      }
    }
  }

  handleBillingInfoUpdated() {
    this.getBillingInfo();
    if (this.props.getUpcomingInvoice) {
      this.forceUpdate(() => {
        this.props.getUpcomingInvoice();
      });
    }
  }

  handleCheckout() {
    const { paymentMethod, disabled, preventCheckout } = this.props;
    if (disabled || preventCheckout
      || (!paymentMethod && (!this.paymentMethodForm || !this.isReadyToPay))) {
      return;
    }
    this.updateBillingInfoAndCheckout();
  }

  renderBillingInfo() {
    const { billingInformation } = this.state;
    return (
      <div className="checkout-card__form">
        {_.isEmpty(billingInformation) ? (
          <>
            <div className="billing-information-remind">
              Please save billing information before checkout.
            </div>
            <BillingInformationForm
              title={null}
              ref={this.billingInfoFormRef}
              billingInfo={billingInformation}
              onUpdated={this.handleBillingInfoUpdated}
              onChange={this.handleBillingFormChange}
            />
          </>
        ) : (
          <BillingInformation
            title={null}
            billingInfo={billingInformation}
          />
        )}
      </div>
    );
  }

  renderBillingInformation() {
    return (
      <div>
        <div className="billing-information-header">
          Billing Information
        </div>
        <div className="billing-information-description">
          This information will be displayed on your invoice.
        </div>
        {this.renderBillingInfo()}
      </div>
    );
  }

  changeCardStatus() {
    this.setState((prevState) => ({
      cardUpdating: !prevState.cardUpdating
    }));
  }

  renderPayment() {
    const {
      paymentMethod,
      isTestOps,
      organizationId } = this.props;
    const { cardUpdating } = this.state;
    return (
      <>
        {isTestOps && (
          <div className="billing-information-header">
            {t('payment')}
            <hr className="mt-3" />
          </div>
        )}
        <div className="checkout-card__form">
          <PaymentMethodInformation
            ref={this.paymentMethodFormRef}
            paymentMethod={paymentMethod}
            cancelLabel={cardUpdating && t('cancel')}
            submitLabel={cardUpdating ? t('save') : ''}
            onChange={this.handlePaymentMethodChange}
            onChangeCardStatus={this.changeCardStatus}
            fullColumn
            organizationId={organizationId}
          />
        </div>
      </>
    );
  }

  renderCheckout() {
    const {
      'data-trackid': dataTrackId,
      disabled,
      preventCheckout,
      prices,
      isTestOps } = this.props;
    const { billingInformation, cardUpdating } = this.state;
    return (
      <>
        {isTestOps ?
          (<SubscriptionPrices prices={prices} />)
          :
          (
            <div className="checkout-card__total d-flex align-items-center justify-content-between mt-4">
              <div className="checkout-card__total__label"><b>Total</b> (USD)</div>
              <div className="checkout-card__total__value">{`$ ${(prices?.total || 0).toLocaleString()}`}</div>
            </div>
          )}
        <hr className="my-3" />
        <div className="d-flex mt-4 mb-3">
          <Button
            data-trackid={dataTrackId}
            color="primary"
            onClick={this.handleCheckout}
            disabled={!this.isReadyToPay || disabled || preventCheckout || !this.isBillingInfoReady || _.isEmpty(billingInformation) || cardUpdating}
          >
            {t('checkout')}
          </Button>
        </div>
      </>
    );
  }

  renderBody() {
    const { isTestOps } = this.props;
    if (isTestOps) {
      return (
        <div className="testops-billing-checkout">
          <div className="billing-information">
            {this.renderBillingInformation()}
          </div>
          <div className="checkout-card">
            {this.renderPayment()}
            {this.renderCheckout()}
          </div>
        </div>
      );
    }

    return (
      <>
        {this.renderPayment()}
        {this.renderBillingInformation()}
        {this.renderCheckout()}
      </>
    );
  }

  render() {
    const {
      isTestOps = false
    } = this.props;

    if (isTestOps) {
      return this.renderBody();
    }
    return (
      <Card className="checkout-card">
        <CardHeader>{t('checkout')}</CardHeader>
        <CardBody>
          {this.renderBody()}
        </CardBody>
      </Card>
    );
  }
}
