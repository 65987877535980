import React from 'react';
import TabComponent from '../../components/TabComponent';
import { buildSearchCondition } from '../../components/search/SearchUtils';
import ExecutionTestResultDataTable from '../../components/table/ExecutionTestResultDataTable';
import { SearchEntity } from '../../utils/Constants';

class SimilarFailuresTab extends TabComponent {
  constructor(props) {
    super(props);
    this.meta.id = 'page-execution-test-result-details-similar-failures';
    this.meta.title = document.title;
  }

  render() {
    const { testResult } = this.props;
    return (
      <ExecutionTestResultDataTable
        useSearchQuery={false}
        entityType={SearchEntity.SimilarFailure}
        showColumnSimilarFailures
        defaultSearchConditions={[
          buildSearchCondition('testResultId', '=', testResult.id),
        ]}
      />
    );
  }
}
export default SimilarFailuresTab;
