

import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Time from '../../utils/Moment';
import LineChart from './LineChart';
import MChartLabelMapping from './models/MChartLabelMapping';
import MChartDataMapping from './models/MChartDataMapping';
import StatusConstants from '../../utils/Statuses';
import MomentFormatProvider from '../../utils/MomentFormatProvider';
import { testCloudStatus } from '../../utils/Constants';

class PerformanceLineChart extends React.Component {

  render() {

    const { data, xAxis } = this.props;
    let { chartLabelsDecorate } = this.props;

    if (!chartLabelsDecorate) {
      chartLabelsDecorate = (fieldName, item) => `#${_.get(item, fieldName)}`;
    }

    const chartLabelsMapping = new MChartLabelMapping(
      xAxis,
      chartLabelsDecorate,
    );

    const chartDatasetsMapping = [
      new MChartDataMapping('Duration', 'duration', 'rgba(187, 217, 255, 0.7)'),
    ];

    const durationValues = data.map((item) => item.duration);
    const durationFormat = MomentFormatProvider.inferDurationFormat(durationValues);
    const options = {
      scales: {
        xAxes: [
          {
            scaleLabel: {
              display: true,
              labelString: this.props.xLabel,
            },
            gridLines: {
              display: true,
            },
          },
        ],
        yAxes: [{
          scaleLabel: {
            display: true,
            labelString: 'Duration',
          },
          ticks: {
            callback: (value) => Time.duration(value, durationFormat),
          },
        }],

      },
      tooltips: {
        callbacks: {
          label: (tooltipitem) => `Duration: ${Time.duration(tooltipitem.yLabel, durationFormat)}`,
        },
      },
      legend: {
        display: false,
      },
    };

    const pointColors = [];
    data.forEach((item) => {
      if (item.status === 'PASSED') {
        pointColors.push(StatusConstants.PASSED.color);
      } else if (item.status === 'FAILED') {
        pointColors.push(StatusConstants.FAILED.color);
      } else if (item.status === 'ERROR') {
        pointColors.push(StatusConstants.ERROR.color);
      } else {
        pointColors.push(StatusConstants.INCOMPLETE.color);
      }
    });
    const datasetOptions = [{
      pointBackgroundColor: pointColors,
      pointRadius: 3,
      pointBorderColor: pointColors,
      pointHoverBackgroundColor: pointColors,
      pointHoverBorderColor: pointColors,
      borderWidth: 1,
    }];

    return (
      <LineChart
        data={data}
        labelsMapping={chartLabelsMapping}
        datasetsMapping={chartDatasetsMapping}
        options={options}
        datasetOptions={datasetOptions}
        xLabel={this.props.xLabel}
      />
    );
  }
}

PerformanceLineChart.propTypes = {
  data: PropTypes.array.isRequired,
  chartLabelsDecorate: PropTypes.func,
  xLabel: PropTypes.string.isRequired,
  xAxis: PropTypes.string,
};

PerformanceLineChart.defaultProps = {
  xAxis: 'id',
};

export default PerformanceLineChart;
