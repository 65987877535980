import React from 'react';
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import PropTypes from 'prop-types';
import { MenuItem } from '@mui/material';

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

const DropDownButton = (props) => {
  const { title, options, className, startIcon } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={className}>
      <Button
        style={{
          backgroundColor: '#1847ca',
          width: '100%'
        }}
        color="primary"
        variant="contained"
        disableElevation
        onClick={handleClick}
        startIcon={startIcon}
        endIcon={<KeyboardArrowDownIcon />}
      >
        {title}
      </Button>
      <StyledMenu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
      >
        {options.map((item) => (
          <MenuItem onClick={item.handleClick} disableRipple>
            {item.icon}
            <div className="ml-2">{item.title}</div>
          </MenuItem>
        ))}
      </StyledMenu>
    </div>
  );
};

DropDownButton.propTypes = {
  title: PropTypes.string,
  /**
   * Example:
   * [
   *   {
   *     title: 'option 1',
   *     handleClick: () => {}
   *   },
   *   {
   *     title: 'option 2',
   *     handleClick: () => {}
   *   }
   * ]
   */
  options: PropTypes.any,
  className: PropTypes.string,
  startIcon: PropTypes.any,
};

DropDownButton.defaultProps = {
  title: '',
  options: undefined,
  className: undefined,
  startIcon: undefined,
};

export default DropDownButton;
