import React from 'react';
import { Button, Form, FormGroup, Label } from 'reactstrap';
import Input from '../Input';
import { t } from '../../i18n/t';
import Services from '../../utils/Services';

export default class ImportTestOpsLicense extends React.Component {
  constructor(props) {
    super(props);

    this.fileReader = null;

    this.handleUpload = this.handleUpload.bind(this);
    this.onChange = this.onChange.bind(this);

    this.state = {
      license: '',
    };
  }

  onChange(e) {
    const file = e.target.files[0];
    this.fileReader = new FileReader();
    this.fileReader.onloadend = () => {
      const license = this.fileReader.result;
      this.setState({ license });
    };
    this.fileReader.readAsText(file);
  }

  handleUpload(e) {
    e.preventDefault();
    const { onUploadFinish } = this.props;
    const { license } = this.state;
    Services.activate(license)
      .then(() => onUploadFinish())
      .finally(() => this.setState({ license: '' }));
  }

  render() {
    const { machineId, submitBtn } = this.props;
    return (
      <Form data-trackid="import-license" onSubmit={this.handleUpload}>
        <FormGroup>
          <Label>{t('license_keys#machine_id')}</Label>
          <Input plaintext>{machineId}</Input>
        </FormGroup>
        <FormGroup>
          <Label>{t('license')}</Label>
          <div>
            <Input
              required
              type="file"
              placeholder={t('testops-license#placeholder')}
              onChange={this.onChange}
            />
          </div>
        </FormGroup>
        <FormGroup>
          <Button type="submit" color="primary">{submitBtn}</Button>
        </FormGroup>
      </Form>
    );
  }

}
