import { Label, FormGroup } from 'reactstrap';
import React, { useState, useEffect } from 'react';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { t } from '../i18n/t';
import Services from '../utils/Services';
import { IconExternalIssuesLink } from '../images/CustomIcon';
import MContext from '../models/MContext';
import DataLoader from '../components/table/DataLoader';
import NormalCard from '../components/card/NormalCard';
import { buildSearchCondition } from './search/SearchUtils';
import { SearchEntity } from '../utils/Constants';

const ExecutionLinkedIssues = ({ executionId }) => {
  const [isXrayFeaturesEnabled, setXrayFeaturesEnabled] = useState(false);

  const getJiraConnection = () => {
    Services.getJiraConnection(MContext.projectId)
      .then(({ content }) => {
        const connection = content[0];
        const isXrayFeaturesEnabled = connection && connection.enabledXray;
        if (isXrayFeaturesEnabled) {
          setXrayFeaturesEnabled(isXrayFeaturesEnabled);
        }
      });
  };

  useEffect(() => {
    getJiraConnection();
  }, []);

  const renderTitle = () => <Label for="linked-xray-test-execution">{t('xray-test-execution#linked-title')}</Label>;

  const renderData = (data) => {
    if (isEmpty(data)) {
      return null;
    }

    const { issueId, url } = data[0];
    return (
      <a className="d-block mt-2" title={issueId} href={url} target="_blank" rel="noopener noreferrer">
        {issueId}
        <IconExternalIssuesLink className="ml-2" />
      </a>
    );
  };

  // Show nothing when user disable jira xray settings
  if (!isXrayFeaturesEnabled) {
    return null;
  }

  return (
    <FormGroup>
      <DataLoader
        title={renderTitle()}
        defaultSort={['id, desc']}
        defaultSearchConditions={[
          buildSearchCondition('Execution.id', '=', executionId),
        ]}
        entityType={SearchEntity.ExternalXrayTestExecution}
        render={renderData}
        headerComponent={NormalCard}
        bodyComponent={NormalCard}
        pageSize={1}
        cardClassName="shadow-none mb-0"
        useCollapseButton={false}
        hidePaging
      />
    </FormGroup>
  );
};

export default ExecutionLinkedIssues;

ExecutionLinkedIssues.propTypes = {
  executionId: PropTypes.number.isRequired,
};
