import React, { Component } from 'react';
import MTableColumnDataMapping from './models/MTableColumnDataMapping';
import DataTable from './DataTable';
import { t } from '../../i18n/t';
import DecoratorConstants from '../../utils/DecoratorConstants';
import { inputFilter, userOrganizationFilter, productFilter } from '../search-query/FilterQueryHelper';
import { buildSearchCondition } from '../search/SearchUtils';
import Services from '../../utils/Services';
import MContext from '../../models/MContext';
import { buildSortQuery } from '../search-query/SortQueryHelper';

class KsSessionDataTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: []
    };
  }

  componentDidMount() {
    this.getUsers();
  }

  getUsers() {
    const params = {
      pagination: {
        page: 0,
        size: 1000000,
        sorts: ['id,asc'],
      },
      conditions: [buildSearchCondition('Organization.id', '=', MContext.organizationId)],
      type: 'UserOrganization',
    };

    Services.search(params)
      .then(({ content }) => {
        this.setState({
          users: content,
        });
      });
  }

  render() {
    const columnMapping = [
      new MTableColumnDataMapping(
        'User',
        'user.fullName'
      ),
      new MTableColumnDataMapping(
        'Session ID',
        'sessionId'
      ),
      new MTableColumnDataMapping(
        'Machine ID',
        'machineId'
      ),
      new MTableColumnDataMapping(
        t('table-header#product'),
        'feature',
        DecoratorConstants.featureDecorator,
      ),
      new MTableColumnDataMapping(
        t('table-header#startTime'),
        'startTime',
        DecoratorConstants.timeDecorator
      ),
      new MTableColumnDataMapping(
        t('table-header#duration'),
        'duration',
        DecoratorConstants.durationDecorator
      ),
    ];

    const defaultSort = [
      'startTime,desc',
    ];

    const { users } = this.state;

    const filterQuery = [
      userOrganizationFilter('User.id', users),
      inputFilter('machineId', 'Machine ID', '~'),
      productFilter('feature', 'Product'),
    ];

    const sortQuery = [
      ...buildSortQuery('startTime', t('table-header#startTime')),
      ...buildSortQuery('duration', t('table-header#duration')),
    ];

    const newProps = {
      columnMapping,
      defaultSort,
      filterQuery,
      sortQuery,
      ...this.props,
    };
    return (
      <DataTable
        entityType="KsSession"
        title="Sessions"
        useSearchQuery
        {...newProps}
      />
    );
  }
}

export default KsSessionDataTable;