import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import Button from '@katalon-studio/katalon-ui/Button';
import TextField from '@katalon-studio/katalon-ui/TextField';
import { isEmpty } from 'lodash';
import IconButton from '@katalon-studio/katalon-ui/IconButton';
import MicrochipAIIcon from '../../../../images/icons/katalonui/MicrochipAIIcon';
import { t } from '../../../i18n/t';
import LearnMoreDialog from './LearnMoreDialog';
import DeleteGPTDialog from './DeleteGPTDialog';
import { sendAnalyticEventForAction } from '../../../utils/SegmentAnalytics';
import Notification from '../../../utils/Notification';
import PenIcon from '../../../../images/icons/katalonui/PenIcon';
import TrashIcon from '../../../../images/icons/katalonui/TrashIcon';
import MAuth from '../../../models/MAuth';
import MContext from '../../../models/MContext';
import { buildSearchCondition } from '../../search/SearchUtils';
import Services from '../../../utils/Services';
import { SearchEntity } from '../../../utils/Constants';
import { FeatureName } from '../../../models/model/FeatureName';
import { AIKeyType } from '../../../models/model/AIKeyType';
import { Feature } from '../../../models/model/Feature';
import colors from '../../../../scss/colors.scss';
import TableLoading from '../../table/TableLoading';

function GPTService() {
  const [openInfo, setOpenInfo] = useState(false);
  const [editingKey, setEditingKey] = useState<string>();
  const [key, setKey] = useState<string>();
  const [isEditing, setEditing] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [isReady, setReady] = useState(false);
  const teamId = MContext.teamId;
  const isTeamManager = MAuth.isTeamManager(teamId);
  const projectId = MContext.projectId;

  const fetchAllAPIKeys = () => {
    const params = {
      pagination: {
        page: 0,
        size: 1,
        sorts: ['id,desc']
      },
      conditions: [
        buildSearchCondition('Connection.type', '=', AIKeyType.OPEN_AI),
        buildSearchCondition('name', '=', FeatureName.GPT),
      ],
      type: SearchEntity.AIFeature,
    };
    Services.search(params)
      .then(({ content }) => {
        const feature: Feature = content[0];
        const tokenValue = feature?.connections[0]?.value?.tokenValue;
        setKey(tokenValue);
      }).finally(() => setReady(true));
  };

  useEffect(() => {
    fetchAllAPIKeys();
  }, []);

  const learnMoreLink = () => (
    <Button
      variant="text"
      className="btn-link"
      onClick={() => setOpenInfo(true)}
    >
      {t('learn_more')}
    </Button>
  );

  const renderGPTServiceInformation = () => (
    <Grid display="flex" justifyContent="left" container rowSpacing={1} columnSpacing={{ md: 2 }}>
      <Grid item xs={1} sm={1} md={1}>
        <MicrochipAIIcon textSize="40px" type="light" />
      </Grid>
      <Grid item xs={11} sm={11} md={11}>
        <div className="service-header">{t('gpt-service')}</div>
        <div className="service-under-header">
          {t('gpt-service#description')}
          {learnMoreLink()}
        </div>
        <div className="service-ad">{t('service-ad')}</div>
      </Grid>
    </Grid>
  );

  const save = () => {
    sendAnalyticEventForAction('to_api_key_of_ai_service_saved', { model: 'gpt' });
    const featureSetting = {
      connections: [{
        type: AIKeyType.OPEN_AI,
        value: {
          tokenValue: editingKey
        }
      }]
    };
    Services.createOrUpdateFeatureSetting(projectId, FeatureName.GPT, featureSetting)
      .then((latestFeature: Feature) => {
        setEditingKey('');
        setKey(latestFeature.connections[0].value.tokenValue);
        Notification.pushSuccess(
          t('gpt-service#notification#save-successfully'),
          t('gpt-service#save')
        );
      });
    setEditing(false);
  };

  const cancel = () => {
    setEditingKey('');
    setEditing(false);
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      save();
    } else if (event.key === 'Escape') {
      cancel();
    }
  };

  const renderEditingMode = () => (
    <>
      <TextField
        autoFocus
        label={t('gpt-service#key#placeholder')}
        value={editingKey}
        size="small"
        variant="outlined"
        fullWidth
        onChange={(event) => setEditingKey(event.target.value)}
        onKeyDown={onKeyDown}
      />
      <div className="mt-2">
        <Button
          className="mr-2"
          variant="contained"
          disabled={isEmpty(editingKey)}
          onClick={save}
        >
          {t('save')}
        </Button>
        <Button variant="contained" color="secondary" onClick={cancel}>{t('cancel')}</Button>
      </div>
    </>
  );

  const clickToEdit = () => {
    sendAnalyticEventForAction('to_api_key_of_ai_service_edited', { model: 'gpt' });
    setEditing(true);
  };

  const clickToAddKey = () => {
    sendAnalyticEventForAction('to_add_api_key_of_ai_service_triggered', { model: 'gpt' });
    setEditing(true);
  };

  const handleAfterDelete = () => {
    setEditingKey('');
    setKey('');
  };

  const clickToDelete = () => {
    setOpenDelete(true);
  };

  const renderViewMode = () => (
    <div className="border rounded p-3">
      <b style={{ fontSize: '16px' }}>{t('gpt-service#key#placeholder')}</b>
      <Grid
        display="flex"
        justifyContent="left"
        container
        rowSpacing={1}
        columnSpacing={{ md: 2 }}
        className="d-flex align-items-center"
      >
        <Grid item xs={10} sm={10} md={10}>
          <div>{key || t('EMPTY')}</div>
        </Grid>
        {isTeamManager &&
          <Grid item xs={2} sm={2} md={2} className="d-flex justify-content-end" style={{ height: '32px' }}>
            {!key && <Button variant="contained" onClick={clickToAddKey}>{t('gpt-service#add-key')}</Button>}
            {key &&
              <>
                <IconButton onClick={clickToEdit} size="small">
                  <PenIcon textSize="16px" type="solid" color={colors.textDarkColor} />
                </IconButton>
                <IconButton onClick={clickToDelete} size="small">
                  <TrashIcon textSize="16px" type="solid" color={colors.textDarkColor} />
                </IconButton>
              </>}
          </Grid>}
      </Grid>
    </div>
  );

  return (
    <>
      {
        !isReady ?
          <TableLoading message={undefined} /> :
          <Grid container rowSpacing={1} columnSpacing={{ md: 3 }}>
            <Grid item xs={12} sm={12} md={6}>
              {renderGPTServiceInformation()}
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              {isEditing ? renderEditingMode() : renderViewMode()}
            </Grid>
          </Grid>
      }
      <LearnMoreDialog isOpen={openInfo} setIsOpen={setOpenInfo} />
      <DeleteGPTDialog isOpen={openDelete} setIsOpen={setOpenDelete} handleAfterDelete={handleAfterDelete} />
    </>
  );
}

export default GPTService;
