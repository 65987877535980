import React, { useState } from 'react';
import { FormText, CustomInput } from 'reactstrap';
import { FormControlLabel } from '@mui/material';

function FeatureSwitch(props) {
  const {
    feature,
    disabled,
    checked,
    id,
    label,
    onChange,
    text,
    icon,
    ...rest
  } = props;
  const [isChecked, setIsChecked] = useState(checked);
  const onChangeHandler = (event) => {
    if (feature) {
      setIsChecked(event.target.checked);

      if (onChange) {
        onChange(feature, event.target.checked, label);
      }
    }
  };

  return (
    <div className="switch-product-access">
      <div>
        {icon}
      </div>
      <FormControlLabel
        value="top"
        control={
          <CustomInput
            type="switch"
            color="primary"
            checked={isChecked}
            onChange={onChangeHandler}
            size="small"
            disabled={disabled || !feature}
            id={label}
          />
        }
        label={label}
        labelPlacement="top"
        disabled={disabled || !feature}
        {...rest}
      />
      {text && (
        <FormText color="muted">
          {text}
        </FormText>
      )}
    </div>
  );
}

export default FeatureSwitch;
