import React, { Component } from 'react';
import { ResponsiveContainer, ComposedChart, Line, Bar, XAxis, YAxis, Legend, Tooltip, Label } from 'recharts';
import { buildSearchCondition } from '../search/SearchUtils';
import DataLoader from '../table/DataLoader';
import StatusConstants from '../../utils/Statuses';
import MomentFormatProvider from '../../utils/MomentFormatProvider';
import Time from '../../utils/Moment';
import { t } from '../../i18n/t';

class ExecutionComparisonComposedChart extends Component {
  constructor(props) {
    super(props);

    this.renderData = this.renderData.bind(this);
    this.executionFormatter = this.executionFormatter.bind(this);
    this.tooltipFormatter = this.tooltipFormatter.bind(this);
    this.legendFormatter = this.legendFormatter.bind(this);
  }

  executionFormatter(value) {
    return `#${value}`;
  }

  tooltipFormatter(value, name) {
    if (name === 'duration') return [Time.duration(value, this.durationFormat), t(name)];
    if (name !== 'duration' && value === 0) return [null, null];
    else return [value, t(name)];
  }

  legendFormatter(value) {
    return t(value);
  }

  renderData(data) {
    data.sort((a, b) => a.order - b.order);
    const durationValues = data.map((item) => item.duration);
    this.durationFormat = MomentFormatProvider.inferDurationFormat(durationValues);
    return (
      <ResponsiveContainer width="100%" height={300}>
        <ComposedChart data={data} margin={{ top: 30, right: 30, left: 30, bottom: 30 }}>
          <XAxis
            tickFormatter={this.executionFormatter}
            dataKey="order"
          />
          <YAxis
            orientation="left"
            yAxisId="numberTests"
            type="number"
          >
            <Label
              value="Test Runs"
              position="left"
              angle={-90}
              style={{ textAnchor: 'middle' }}
              offset={10}
            />
          </YAxis>
          <YAxis
            orientation="right"
            yAxisId="duration"
            type="number"
            tickFormatter={(value) => Time.duration(value, this.durationFormat)}
          >
            <Label
              value="Duration"
              position="right"
              angle={90}
              style={{ textAnchor: 'middle' }}
              offset={10}
            />
          </YAxis>
          <Tooltip labelFormatter={this.executionFormatter} formatter={this.tooltipFormatter} />
          <Line yAxisId="duration" dataKey="duration" />
          <Bar yAxisId="numberTests" barSize={20} stackId="numberTests" dataKey="totalPassedTests" fill={StatusConstants.PASSED.color} />
          <Bar yAxisId="numberTests" barSize={20} stackId="numberTests" dataKey="totalFailedTests" fill={StatusConstants.FAILED.color} />
          <Bar yAxisId="numberTests" barSize={20} stackId="numberTests" dataKey="totalErrorTests" fill={StatusConstants.ERROR.color} />
          <Bar yAxisId="numberTests" barSize={20} stackId="numberTests" dataKey="totalIncompleteTests" fill={StatusConstants.INCOMPLETE.color} />
          <Legend formatter={this.legendFormatter} verticalAlign="bottom" height={36} />
        </ComposedChart>
      </ResponsiveContainer>
    );
  }

  render() {
    const { executionOrders } = this.props;
    const newProps = {
      ...this.props,
    };

    return (
      <DataLoader
        tableId="Execution"
        entityType="Execution"
        render={this.renderData}
        defaultSearchConditions={[
          buildSearchCondition('order', 'in', executionOrders.toString())
        ]}
        {...newProps}
      />
    );
  }
}

export default ExecutionComparisonComposedChart;
