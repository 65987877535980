import React from 'react';
import { Modal, ModalGateway } from 'react-images';
import CloseableDialogComponent from '../../../components/dialog/CloseableDialogComponent';
import MFlags from '../../../models/MFlags';
import DecoratorConstants from '../../../utils/DecoratorConstants';

class GalleryModal extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };

    this.onClose = this.onClose.bind(this);
  }

  open() {
    this.setState({ isOpen: true });
  }

  close() {
    this.setState({ isOpen: false });
  }

  onClose() {
    this.close();
    if (this.props.onClose) {
      this.props.onClose();
    }
  }
  renderHeader() {
    return (
      <>
        <div className="status-badge-header">
          {DecoratorConstants.statusBadge(this.props.matchStatus)}
        </div>
        <h4>{this.props.title}</h4>
      </>
    );
  }
  render() {
    const { children, ...props } = this.props;

    if (!this.state.isOpen) {
      return null;
    }

    return (
      <ModalGateway>
        <CloseableDialogComponent
          maxWidth="95vw"
          id="checkpoint-dialog"
          className="checkpoint-comparison"
          isOpen={this.state.isOpen}
          handleClose={this.onClose}
          title={<div className="status-badge-header-title">{this.renderHeader()}</div>}
        >
          {children}
        </CloseableDialogComponent>
      </ModalGateway>
    );
  }
}

export default GalleryModal;
