import React from 'react';
import { Skeleton, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import CardBorder from './card/CardBorder';

const SkeletonOverlay = styled(Grid)(() => ({
  position: 'fixed',
  width: '100%',
  height: '100%',
  zIndex: '9999',
}));

function LoadingSkeleton() {
  return (
    <SkeletonOverlay
      container
      direction="column"
      alignItems="center"
      justify="center"
      width="100%"
      height="100%"
      border="solid thin #D5D8DD"
      borderRadius="0.42rem"
    >
      <CardBorder className="px-5 w-100 h-100 align-items-start justify-content-start">
        <Skeleton variant="text" animation="wave" width="30%" height={40} />
        <Skeleton variant="rectangular" animation="wave" width="100%" height={200} />
        <Skeleton variant="text" animation="wave" width="100%" height={30} />
        <Skeleton variant="text" animation="wave" width="100%" height={30} />
        <Skeleton variant="text" animation="wave" width="100%" height={30} />
        <Skeleton variant="text" animation="wave" width="100%" height={30} />
        <Skeleton variant="text" animation="wave" width="100%" height={30} />
        <Skeleton variant="text" animation="wave" width="100%" height={30} />
        <Skeleton variant="text" animation="wave" width="100%" height={30} />
        <Skeleton variant="text" animation="wave" width="100%" height={30} />
      </CardBorder>
    </SkeletonOverlay>
  );
}

export default LoadingSkeleton;
