import React from 'react';
import TabComponent from '../../components/TabComponent';
import AbnormalRequestDataTable from '../../components/table/AbnormalRequestDataTable';
import { buildSearchCondition } from '../../components/search/SearchUtils';
import { withPremiumFeature } from '../../components/HigherOrderComponent';

class AnomalTab extends TabComponent {
  renderAbnormalRequest() {
    const { projectId } = this.props;

    return (
      <AbnormalRequestDataTable
        defaultSearchConditions={[
          buildSearchCondition("Project.id", "=", projectId),
          buildSearchCondition("abnormal", "=", "true"),
        ]}
        defaultSort={["startedAt,desc"]}
        hidePaging
      />
    );
  }

  render() {
    return this.renderAbnormalRequest();
  }
}

export default withPremiumFeature(AnomalTab);
