import React from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import { IconDownload } from '../../images/CustomIcon';
import { t } from '../../i18n/t';
import Apis from '../../utils/Apis';
import MContext from '../../models/MContext';
import http from '../../utils/http';


export default class Plugin extends React.Component {
  download(url) {
    const a = document.createElement('a');
    const body = document.getElementsByTagName('body')[0];
    a.setAttribute('href', url);
    a.setAttribute('target', '_blank');
    body.appendChild(a);
    a.click();
    setTimeout(() => {
      body.removeChild(a);
    }, 50);
  }

  downloadPlugin(organizationId, pluginId) {
    http.get(Apis.downloadPlugin(organizationId, parseInt(pluginId, 10)), undefined, undefined, 'text')
      .then((url) => this.download(url));
  }

  render() {
    const organizationId = MContext.organizationId;
    const { data } = this.props;
    return (
      <div className={`col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2dot4 plugin plugin-${data.id}`}>
        <div className={`plugin-card plugin-card-${data.id}`}>
          <div className="plugin-card__cover">
            <img
              className="plugin-card__cover__image"
              width="90px"
              height="90px"
              src={data.logoUrl}
              alt="Change Avatar"
            />
          </div>
          <h5 className="plugin-card__name">{data.name}</h5>
          <div className="plugin-card__description">{data.description}</div>
          <span
            className="plugin-card__download"
            data-trackid="download-plugin"
            title={t('download')}
            onClick={() => this.downloadPlugin(organizationId, data.id)}
          >
            Download <IconDownload />
          </span>
        </div>
        <UncontrolledTooltip target={`.plugin-card-${data.id}`}>
          {data.description}
        </UncontrolledTooltip>
      </div>
    );
  }

}
