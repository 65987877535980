import React, { useEffect } from 'react';
import { FormGroup, Label } from 'reactstrap';
import {
  MAX_PAGE_SIZE,
  SearchEntity,
} from '../utils/Constants';
import { buildSearchCondition } from '../components/search/SearchUtils';
import Services from '../utils/Services';
import { t } from '../i18n/t';
import SelectCustomFieldComponent from './SelectCustomFieldComponent';

const EditCustomField = (props) => {

  const { setSelectedCustomFields, selectedCustomFields, entityId, entityType, hideLabel = false } = props;

  const getEntityCustomFields = () => {
    const params = {
      pagination: {
        page: 0,
        size: MAX_PAGE_SIZE,
        sorts: ['id,asc']
      },
      conditions: [
        buildSearchCondition('entityId', '=', entityId),
        buildSearchCondition('entityType', '=', entityType),
      ],
      type: SearchEntity.CustomFieldValue,
    };
    return Services.searchRecursively(0, params, [])
      .then((responseJson) => {
        setSelectedCustomFields(responseJson, false);
      });
  };

  useEffect(() => {
    if (entityId) {
      getEntityCustomFields();
    }
  }, []);

  return (
    <FormGroup className="edit-custom-field">
      {!hideLabel && <Label for="customFields">{t('custom-fields#title')}</Label>}
      <SelectCustomFieldComponent
        {...props}
        setSelectedCustomFields={setSelectedCustomFields}
        selectedCustomFields={selectedCustomFields}
        trackingType={entityType}
      />
    </FormGroup>);
};

export default EditCustomField;
