import React from 'react';
import { Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ExternalIssue } from '../../models/model/ExternalIssue';
import { DefectDecorator } from './DefectDecorator';
import TableWithDashedBorder from '../TableWithDashedBorder';

const styles = makeStyles({
  idSection: {
    alignItems: 'center',
    display: 'flex',
    fontSize: '14px',
    fontWeight: '500',
    color: '#233145',
    marginBottom: '5px',
    '& svg': {
      width: '14px',
      height: '14px',
    },
  },
  detailSection: {
    marginTop: '7px',
    display: 'flex',
    fontSize: '12px',
    color: '#808b9a',
    alignItems: 'center',
    '& svg': {
      width: '14px',
      height: '14px',
    },
  },
  actionSection: {
    textAlign: 'right',
    width: '100%',
  },
});

interface DefectsSummaryProps {
  externalIssues: ExternalIssue[] | undefined;
}

function DefectsSummary(props: DefectsSummaryProps) {
  const classes = styles();
  const { externalIssues } = props;

  const renderData = (element: ExternalIssue) => (
    <Grid container className="app-card-list-row">
      <Grid item xs={12} className="d-flex mt-1 mb-1">
        <Grid>
          <Grid item xs={12} className={classes.idSection}>
            {DefectDecorator.iconDecorator()}
            {DefectDecorator.nameDecorator(element, true)}
          </Grid>
          <Grid item xs={12} className={classes.detailSection}>
            {DefectDecorator.idDecorator(element)}
            {DefectDecorator.priorityDecorator(element)}
            {DefectDecorator.startTimeDecorator(element)}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <TableWithDashedBorder
      chosenKey="id"
      renderData={renderData}
      data={externalIssues}
    />
  );
}

export default DefectsSummary;
