import React from 'react';
import PageComponent from '../../components/PageComponent';
import { buildSearchCondition } from '../../components/search/SearchUtils';
import ExecutionTestResultDataTable from '../../components/table/ExecutionTestResultDataTable';
import MContext from '../../models/MContext';
import DefaultLayout from '../../components/DefaultLayout';
import ObjectSummary from '../../components/summary/ObjectSummary';
import { t } from '../../i18n/t';
import Services from '../../utils/Services';

class TestObjectEntity extends PageComponent {
  constructor(props) {
    super(props);
    this.project = MContext.project;
    this.testObjectEntityId = MContext.testObjectEntityId;
    this.renderHeader = this.renderHeader.bind(this);
    this.renderBody = this.renderBody.bind(this);
    this.state = {
      testObject: null
    };
    this.meta.id = 'page-test-object-details';
  }

  componentDidMount() {
    this.getTestObject();
  }

  getTestObject() {
    Services.getTestObjectEntity(this.testObjectEntityId)
      .then((testObject) => {
        this.meta.title = t('Test Object {{name}}', { name: testObject.name });
        this.setState({ testObject });
      });
  }

  renderTestResultTable() {
    return (
      <ExecutionTestResultDataTable
        title="Test Run History"
        defaultSearchConditions={[
          buildSearchCondition('TestObjectEntity.id', '=', this.testObjectEntityId),
        ]}
        defaultSort={['startTime,desc']}
      />
    );
  }

  renderBody() {
    return (
      <>
        {this.renderTestResultTable()}
      </>
    );
  }

  renderHeader() {
    const { testObject } = this.state;
    const urlParams = {
      testObjectEntity: testObject.name,
      projectName: this.project.name,
    };
    return (
      <ObjectSummary params={urlParams} />
    );
  }

  render() {
    const { testObject } = this.state;
    if (testObject) {
      return (
        <DefaultLayout
          renderHeader={this.renderHeader}
          renderBody={this.renderBody}
        />
      );
    } else return null;

  }
}

export default TestObjectEntity;
