import MContext from '../models/MContext';
import MFlags from '../models/MFlags';
import TrackUtils from './track/TrackUtils';

const DEFAULT_EVENTS = {
};

export const removeProjectTrackingInformation = () => {
  window.sessionStorage.removeItem('projectTrackingInformation');
};

export const getProjectTrackingInformation = () => {
  const item = window.sessionStorage.getItem('projectTrackingInformation');
  return JSON.parse(item);
};

export const getProjectIdFromProjectTrackingInformation = () => {
  const projectTrackingInformation = getProjectTrackingInformation();
  return projectTrackingInformation?.project.id.toString();
};

export const setProjectTrackingInformation = (value) =>
  window.sessionStorage.setItem('projectTrackingInformation', JSON.stringify(value));

const shouldAddProjectTrackingInformation = (projectTrackingInformation) => {
  const projectId = getProjectIdFromProjectTrackingInformation();
  return projectId === MContext.projectId && projectTrackingInformation;
};

const sendAnalytics = (spec, data = {}, traits) => {
  const analytics = window.analytics;

  if (analytics) {
    const { category, label, ...extraProperties } = data;

    if (!MFlags.identifierForProductTrackingEnabled) {
      return analytics.track(spec, {
        event_category: category,
        event_label: label,
        ...extraProperties,
      }, {
        traits
      });
    } else {
      const projectTrackingInformation = getProjectTrackingInformation();
      return analytics.track(spec, {
        event_category: category,
        event_label: label,
        ...extraProperties,
        ...(shouldAddProjectTrackingInformation(projectTrackingInformation) && { projectTrackingInformation }),
      }, {
        traits
      });
    }
  }
  return Promise.resolve({});
};

const identify = (credential, userAttributes) => {
  const analytics = window.analytics;
  const email = credential.email;

  if (analytics) {
    analytics.identify(email, {
      user_id: email,
      email,
      ...userAttributes
    });
  }
};

/**
 * The track API call is how you record any actions your users perform,
 * along with any properties that describe the action.
 * @param trackingInfo
 */
const track = (trackingInfo) => {
  const [action, data, traits] = trackingInfo;

  let { groupId } = data;
  if (!groupId) {
    const path = window.location.pathname;
    groupId = TrackUtils.getGroup(path);
    data.groupId = groupId;
  } else {
    data.groupId = TrackUtils.convertGroupIdToArray(groupId);
  }

  return sendAnalytics(action, data, traits);
};

/**
 * The page call lets you record whenever a user see a page of your website,
 * along with any optional properties about the page.
 * @param pageInfo
 * @param groupId
 */
const page = (pageInfo, groupId) => {
  const analytics = window.analytics;

  if (analytics) {
    if (!groupId) {
      const path = window.location.pathname;
      groupId = TrackUtils.getGroup(path);
    } else {
      groupId = TrackUtils.convertGroupIdToArray(groupId);
    }
    if (!MFlags.identifierForProductTrackingEnabled) {
      analytics.page(pageInfo, {
        page_title: pageInfo,
        group_id: groupId,
      });
    } else {
      const projectTrackingInformation = getProjectTrackingInformation();
      analytics.page(pageInfo, {
        page_title: pageInfo,
        group_id: groupId,
        ...(shouldAddProjectTrackingInformation(projectTrackingInformation) && { projectTrackingInformation }),
      });
    }
  }
};

export const analyticsIdentify = (user, userAttributes) => identify(user, userAttributes);

/**
 * Sent event to Analytics App when user access to any page.
 *
 * For setting, you can see this.meta.id and this.meta.groupId.
 * You can find them in PageComponent.jsx
 *
 * By default, create the new page, please set this.meta.id and set the
 * new page to one group in trackUtils#GROUP_EVENT_TRACKS
 *
 * Or you want set groupId for page, please add value for this.meta.groupId
 *
 * @param pageInfo
 * @param groupId {array}
 */
export const analyticsPage = (pageInfo, groupId) => page(pageInfo, groupId);

export const sendAnalyticEventForId = (id) => track(DEFAULT_EVENTS[id] || [id]);

export const sendAnalyticEvent = (event) => track(event);

export const sendAnalyticEventForAction = (action, data, traits) => track([action, data, traits]);

export default sendAnalyticEventForAction;
