import React from 'react';
import {
  Button,
  Form,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import PropTypes from 'prop-types';
import Notification from '../../utils/Notification';
import Services from '../../utils/Services';

class RemoveLicenseDialog extends React.Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.removeLicense = this.removeLicense.bind(this);
    this.state = {
      modal: false,
    };
  }

  toggle() {
    const { modal } = this.state;
    this.setState({
      modal: !modal,
    });
  }

  removeLicense(e) {
    e.preventDefault();
    const { license, afterRemove } = this.props;
    Services.cancelLicense(license.id)
      .then(() => {
        Notification.pushSuccess('License has been removed.');
        if (afterRemove) {
          afterRemove();
        }
      })
      .finally(this.toggle);
  }

  render() {
    const { license } = this.props;
    if (license) {
      return (
        <div>
          <Modal isOpen={this.state.modal} toggle={this.toggle}>
            <Form data-trackid="remove-license" onSubmit={this.removeLicense}>
              <ModalHeader>Remove license</ModalHeader>
              <ModalBody>
                Are you sure you want to remove the license from the machine <strong>{license.machineId}</strong>?<br />
                This will terminate the current session in Katalon Studio.
              </ModalBody>
              <ModalFooter>
                <Button type="submit" color="danger">Remove</Button>
                <Button color="secondary" onClick={this.toggle}>Cancel</Button>
              </ModalFooter>
            </Form>
          </Modal>
        </div>
      );
    }
    return null;
  }
}

RemoveLicenseDialog.propTypes = {
  afterRemove: PropTypes.func,
};

export default RemoveLicenseDialog;
