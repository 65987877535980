import React from 'react';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import { t } from '../../i18n/t';
import DefaultLayout from '../../components/DefaultLayout';
import PageComponent from '../../components/PageComponent';
import ObjectSummary from '../../components/summary/ObjectSummary';
import UserGitCredentialsForm from './UserGitCredentialsForm';

class UserIntegrations extends PageComponent {
  constructor(props) {
    super(props);
    this.renderHeader = this.renderHeader.bind(this);
    this.renderBody = this.renderBody.bind(this);
    this.meta.id = 'page-user-integration';
    this.meta.title = t('integrations');
  }

  renderHeader() {
    const urlParams = {
      katalonRecorderBackup: t('integrations')
    };
    return (
      <ObjectSummary params={urlParams} />
    );
  }

  renderBody() {
    return (
      <Card>
        <CardHeader>{t('github-integration')}</CardHeader>
        <CardBody>
          <Row>
            <Col sm="12" md="8" lg="6" xl="5">
              <UserGitCredentialsForm />
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  }

  render() {
    return (
      <DefaultLayout
        renderHeader={this.renderHeader}
        renderBody={this.renderBody}
      />
    );
  }
}

export default UserIntegrations;
