import React from 'react';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';

const ButtonLink = (props) => {
  return (
    <Button
      {...props}
      to={props.to || props.href}
      tag={Link}
    >
      {props.children}
    </Button>
  );
};

export default ButtonLink;
