import React, { useEffect } from 'react';
import { DialogContent, DialogActions } from '@mui/material';
import { Button, Label, FormGroup, Form } from 'reactstrap';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { cloneDeep } from 'lodash';
import { IconDelete, IconPlus } from '../../images/CustomNewIcon';
import CloseableDialogComponent from './CloseableDialogComponent';
import { t } from '../../i18n/t';
import Input from '../Input';
import MContext from '../../models/MContext';
import { fromCustomFieldDialog, useAppDispatch } from '../../store';

function CustomFieldDialogWithRedux({ isOpen, handleClose, customFieldDefinitionId, handleCreateCustomFieldDefinition, handleEditCustomFieldDefinition }) {
  const isEditing = !!customFieldDefinitionId;

  const createOrEditTextBtn = () => {
    if (isEditing) {
      return t('save_changes');
    } else {
      return t('create');
    }
  };

  const createOrEditTextTitle = () => {
    if (isEditing) {
      return t('custom-fields#edit-custom-field');
    } else {
      return t('custom-fields#create-new-custom-field');
    }
  };

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isEditing) {
      const fetchInfo = async () => {
        await Promise.all([
          dispatch(fromCustomFieldDialog
            .doGetCustomFieldDefinition(customFieldDefinitionId)),
        ]);
      };
      fetchInfo().catch(() => { /* ignore */ });
    }
  }, [customFieldDefinitionId]);

  useEffect(() => {
    if (!isEditing) {
      dispatch(fromCustomFieldDialog.doResetEntity());
    }
  }, []);

  const displayName = useSelector(fromCustomFieldDialog.selectDisplayName());

  const customFieldKey = useSelector(fromCustomFieldDialog.selectCustomFieldKey());

  const optionValues = useSelector(fromCustomFieldDialog.selectOptionValues());

  const loading = useSelector(fromCustomFieldDialog.selectLoading());

  const addValueField = () => {
    dispatch(fromCustomFieldDialog.setOptionValues([...optionValues, {
      value: '',
    }]));
  };

  const removeValueField = (index) => {
    const rows = cloneDeep(optionValues);
    rows.splice(index, 1);
    dispatch(fromCustomFieldDialog.setOptionValues(rows));
  };

  const handleChangeDisplayName = (e) => {
    dispatch(fromCustomFieldDialog.setDisplayName(e.target.value));
  };

  const handleChangeKeyValue = (e) => {
    dispatch(fromCustomFieldDialog.setCustomFieldKey(e.target.value));
  };

  const handleOnValueChange = (i, e) => {
    const newOptionValues = cloneDeep(optionValues);
    newOptionValues[i][e.target.name] = e.target.value;
    dispatch(fromCustomFieldDialog.setOptionValues(newOptionValues));
  };

  const onFormSubmit = (e) => {
    e.preventDefault();
    const customFieldDefinition = {
      id: customFieldDefinitionId,
      displayName,
      key: customFieldKey,
      customFieldOptions: optionValues,
      projectId: MContext.projectId,
      organizationId: MContext.team?.organizationId,
    };
    if (isEditing) {
      handleEditCustomFieldDefinition(customFieldDefinition);
    } else {
      handleCreateCustomFieldDefinition(customFieldDefinition);
    }
  };

  const renderDisplayNameField = () => (
    <FormGroup>
      <Label for="display-name">{t('custom-fields#display-name')}</Label>
      <Input
        required
        id="display-name"
        name="displayName"
        type="text"
        placeholder={t('custom-fields#placeholder#display-name')}
        value={displayName}
        onChange={handleChangeDisplayName}
      />
    </FormGroup>
  );

  const renderKeyField = () => (
    <FormGroup>
      <Label for="key" className="mt-0">{t('custom-fields#key')}</Label>
      <Input
        required
        id="key"
        name="key"
        type="text"
        disabled={isEditing}
        placeholder={t('custom-fields#placeholder#key')}
        value={customFieldKey}
        onChange={handleChangeKeyValue}
      />
    </FormGroup>
  );

  const renderValuesField = () => {
    // Disable delete button if custom field has less than 1 value
    const isOneValue = optionValues.length <= 1;
    return (
      <FormGroup>
        <Label for="values">{t('custom-fields#values')}</Label>
        {optionValues.map((element, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <div className="d-flex flex-nowrap mb-3" key={element.id}>
            <Input
              required
              id="value"
              name="value"
              type="text"
              value={element.value}
              placeholder={t('custom-fields#placeholder#value')}
              onChange={(e) => handleOnValueChange(index, e)}
            />
            <Button
              className="ml-3 custom-field-dialog__delete-value-btn"
              disabled={isOneValue}
              onClick={() => removeValueField(index)}
            >
              <IconDelete className="custom-field-dialog__icon-delete" />
            </Button>
          </div>
        ))}
      </FormGroup>
    );
  };

  const renderAddValueButton = () => (
    <a
      className="mr-3 custom-field-dialog__add-value-btn"
      onClick={() => addValueField()}
    >
      <span>
        <IconPlus className="custom-field-dialog__icon-plus ml-0 mr-2" />
        {t('custom-fields#add-new-value')}
      </span>
    </a>
  );

  const renderDialog = () => (
    <CloseableDialogComponent
      isOpen={isOpen}
      handleClose={handleClose}
      maxWidth="md"
      title={createOrEditTextTitle()}
    >
      <Form onSubmit={onFormSubmit}>
        <DialogContent className="py-0">
          {renderKeyField()}
          {renderDisplayNameField()}
          {renderValuesField()}
          {renderAddValueButton()}
        </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            className="ml-3"
            title={t('cancel')}
            onClick={handleClose}
          >
            {t('cancel')}
          </Button>
          <Button
            type="submit"
            color="primary"
            className="ml-3"
            title={createOrEditTextBtn()}
          >
            {createOrEditTextBtn()}
          </Button>
        </DialogActions>
      </Form>
    </CloseableDialogComponent>
  );

  if (!loading && customFieldDefinitionId) {
    return null;
  }
  return renderDialog();
}

CustomFieldDialogWithRedux.propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  customFieldDefinitionId: PropTypes.number,
  handleCreateCustomFieldDefinition: PropTypes.func,
  handleEditCustomFieldDefinition: PropTypes.func,
};

CustomFieldDialogWithRedux.defaultProps = {
  isOpen: false,
  handleClose: null,
  customFieldDefinitionId: null,
  handleCreateCustomFieldDefinition: null,
  handleEditCustomFieldDefinition: null,
};

export default CustomFieldDialogWithRedux;
