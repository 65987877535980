import PropTypes from 'prop-types';
import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { t } from '../../i18n/t';
import Notification from '../../utils/Notification';
import Services from '../../utils/Services';

class StopTunnelDialog extends React.Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.stopTunnel = this.stopTunnel.bind(this);
    this.state = {
      modal: false
    };
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  stopTunnel() {
    const {
      tunnel,
      onTunnelStopped,
    } = this.props;

    const { secret, id, username, organizationId } = tunnel;
    const deleteTunnelRequest = { secret, id, username, organizationId };
    Services.terminateTunnel(deleteTunnelRequest, id)
      .then(() => {
        Notification.pushSuccess(t('test-cloud-stop-dialog#success-message', { id }));
        if (onTunnelStopped) {
          onTunnelStopped();
        }
        this.toggle();
      })
      .catch((e) => {
        this.toggle();
        Notification.pushError(e, t('test-cloud-stop-dialog#fail-message'));
      });
  }

  render() {
    const {
      modal,
    } = this.state;

    return (
      <Modal isOpen={modal} toggle={this.toggle}>
        <ModalHeader toggle={this.toggle}>{t('test-cloud-stop-dialog#title')}</ModalHeader>
        <ModalBody>
          {t('test-cloud-stop-dialog#content')}
        </ModalBody>
        <ModalFooter>
          <Button data-trackid="btn-delete-tunnel" color="danger" onClick={this.stopTunnel}>{t('test-cloud-stop-dialog#btn-stop')}</Button>
          <Button data-trackid="btn-cancel-stop-tunnel" color="secondary" onClick={this.toggle}>{t('test-cloud-stop-dialog#btn-cancel')}</Button>
        </ModalFooter>
      </Modal>
    );
  }
}

StopTunnelDialog.propTypes = {
  onTunnelStopped: PropTypes.func,
};

export default StopTunnelDialog;
