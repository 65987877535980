import React from 'react';
import { CSSTransition } from 'react-transition-group';
import { Nav, Navbar, NavItem } from 'reactstrap';
import { IconButton } from '@mui/material';
import { t } from '../../i18n/t';
import {
  IconChevronDown,
  IconChevronUp,
  IconNavIdleTimeoutActive,
  IconNavIdleTimeoutInactive,
  IconNavLicenseManagementActive,
  IconNavLicenseManagementInactive,
  IconNavLicenseUtilizationInactive,
  IconNavOrganizationManagementActive,
  IconNavOrganizationManagementInactive,
  IconNavPaymentMethodActive,
  IconNavPaymentMethodInactive,
  IconNavSubscriptionManagementInactive,
  IconNavUserManagementActive,
  IconNavUserManagementInactive,
  IconSidebarArrowLeft,
  IconSidebarArrowRight,
  IconAccountSettingsInactive,
} from '../../images/CustomNewIcon';
import {
  IconKSEBig,
  IconSettings,
  IconTestOps,
  IconArrowLeft
} from '../../images/CustomIcon';
import MAuth from '../../models/MAuth';
import MConfigs from '../../models/MConfigs';
import MContext from '../../models/MContext';
import MFlags from '../../models/MFlags';
import MAccountFlags from '../../models/MAccountFlags';
import Apis from '../../utils/Apis';
import Arrays from '../../utils/Arrays';
import { PageType } from '../../utils/Constants';
import Routes, { goByLink } from '../../utils/Routes';
import NavLink from '../NavLink';

class LeftNavigationBar extends React.Component {

  constructor(props) {
    super(props);
    this.organizationId = MContext.organizationId;
    this.teamId = MContext.teamId;
    this.projectId = MContext.projectId;
    this.team = MContext.team;
    this.project = MContext.project;
    this.organization = MContext.organization;
    this.accountId = this.organization?.accountId;

    this.hideReporting = MConfigs.hideReporting;

    this.renderItem = this.renderItem.bind(this);
    this.state = {
      productUtilizationSubmenuOpen: true,
      subscriptionSubmenuOpen: true,
      sidebarOpen: true,
    };
  }

  renderItem(item) {
    if (item.isShowBackButton) {
      return (
        <li className="nav-header-title-container">
          <div onClick={() => goByLink(item.link)}>
            <IconButton size="medium">
              <IconArrowLeft width="21px" height="24px"/>
            </IconButton>
          </div>
          <span className="nav-item-title">{item.name}</span>
        </li>
      );
    }

    if (item.isTitle) {
      return (
        <li className="nav-item nav-group-title">
          <span className="nav-item-label">{item.name}</span>
        </li>
      );
    }
    if (item.collapsible) {
      return (
        <>
          <div className="nav-item" onClick={item.onClick}>
            <div className="nav-link">
              <span className="nav-item-icon inactive">{item.iconInactive}</span>
              <span className="nav-item-icon active">{item.iconActive}</span>
              <span className="nav-item-label">{item.name}</span>
              <span className="nav-adornment-end">
                {item.open ? <IconChevronUp /> : <IconChevronDown />}
              </span>
            </div>
          </div>
          {item.open ? item.subItems.map(this.renderItem) : null}
        </>
      );
    }
    return (
      <NavItem>
        <NavLink
          className={item.indented ? 'indented' : ''}
          exactMatch={item.exactMatch}
          title={item.name}
          href={item.active ? '#' : item.link}
          pattern={item.pattern}
          target={item.target}
          rel={item.rel}
          spa={item.spa}
        >
          <span className="nav-item-icon inactive">{item.iconInactive}</span>
          <span className="nav-item-icon active">{item.iconActive}</span>
          <span className="nav-item-label">{item.name}</span>
          {item.endAdornment}
        </NavLink>
      </NavItem>
    );
  }

  render() {
    const { type } = this.props;
    const { teamId, projectId, organizationId, accountId } = this;
    const isOnPremise = MConfigs.isOnPremise;
    const isLicenseServerOnPremise = MConfigs.isLicenseServerOnPremise;
    const {
      moveAgentToOrgLevelPhase2Enabled,
      e2eTestGenerationEnabled,
      atgCheckFeaturePermission,
      atgRemoveBetaTag,
    } = MFlags;
    const { subscriptionAndPaymentMethodDisabled } = MAccountFlags;
    const route = new Routes(projectId, teamId);
    let organizationItems = [];
    const isOrganizationScope = MAuth.isLoggedIn && type === PageType.ORGANIZATION;
    const isCheckFeaturePermission = atgCheckFeaturePermission || e2eTestGenerationEnabled;
    const isTrueTestFeature = MAuth.isLoggedIn
      && MAuth.isOrganizationManager(organizationId)
      && isCheckFeaturePermission;

    let organizationSettingItems = [];
    if (moveAgentToOrgLevelPhase2Enabled && type === PageType.TESTOPS_ORGANIZATION_SETTING) {
      organizationSettingItems = organizationItems.concat([
        {
          name: t('settings-organization'),
          link: route.organization_home_link,
          isShowBackButton: true,
          spa: true,
        },
        {
          name: t('agent'),
          link: route.organization_setting_agent_link,
          spa: true,
        },
        {
          name: t('test-env-installation'),
          link: route.organization_setting_test_env_installation_link,
          spa: true,
        },
      ]);
      if (isTrueTestFeature) {
        organizationSettingItems.push({
          name: t('true-test-configuration'),
          link: route.organization_setting_true_test_link,
          endAdornment: atgRemoveBetaTag ? null : <span className="badge badge-trial mx-2">{t('beta')}</span>,
        });
      }
    }

    if (isOrganizationScope && this.hideReporting) {
      organizationItems = organizationItems.concat([
        {
          name: t('teams'),
          link: route.organization_teams_link,
          // iconActive: <IconTeamsActive />,
          // iconInactive: <IconTeamsInactive />,
        },
      ]);
      organizationItems = organizationItems.concat([
        {
          name: t('projects'),
          link: route.organization_projects_link,
          // iconActive: <IconProjectsActive />,
          // iconInactive: <IconProjectsInactive />,
        },
      ]);

      // if (isEnterpriseOrganization && !isOnPremise) {
      //   organizationItems = organizationItems.concat([
      //     {
      //       name: t('plugins'),
      //       link: route.organization_plugins_link,
      //       iconActive: <IconPluginsActive />,
      //       iconInactive: <IconPluginsInactive />
      //     },
      //   ]);
      // }
    }

    let katalonMangeItems = [
      {
        name: 'Admin',
        isTitle: true,
      }
    ];
    const isKatalonMangeScope = MAuth.isLoggedIn && type === PageType.KATALON;
    if (isKatalonMangeScope) {
      const isOrganizationManager = MAuth.isOrganizationManager(this.organizationId);
      const isBillingManager = MAuth.isBillingManager(this.organizationId);
      const isOrganizationOwner = MAuth.isOrganizationOwner(this.organizationId);
      const isAdminLicense = MConfigs.isLicenseAdminServer;

      // if (isOrganizationOwner) {
      //   katalonMangeItems = katalonMangeItems.concat([
      //     {
      //       name: organization.name,
      //       // link: route.admin_organization_link,
      //       icon: <IconOrganization />,
      //       // iconActive: <IconOrganizationActive />,
      //       // iconInactive: <IconOrganizationInactive />,
      //       exactMatch: true,
      //     },
      //   ]);
      // }

      if (isOrganizationManager || isBillingManager) {
        const productUtilizationSubitems = [
          ...Arrays.insertIf(isOrganizationManager, ({
            name: t('katalon_studio'),
            link: Apis.katOneLicenseUtilizationPage({ orgId: this.organizationId }),
            iconInactive: <IconKSEBig />,
            iconActive: <IconKSEBig />,
            indented: true,
          })),
          ...Arrays.insertIf(isOrganizationManager || isBillingManager, ({
            name: t('katalon_testops'),
            link: Apis.katOneDashboardPage({ orgId: this.organizationId }),
            iconInactive: <IconTestOps />,
            iconActive: <IconTestOps />,
            indented: true,
          })),
        ];
        katalonMangeItems = katalonMangeItems.concat([
          {
            name: t('left-nav#product-utilization'),
            iconInactive: <IconNavLicenseUtilizationInactive />,
            collapsible: true,
            open: this.state.productUtilizationSubmenuOpen,
            onClick: () => this.setState((prevState) => ({ ...prevState, productUtilizationSubmenuOpen: !prevState.productUtilizationSubmenuOpen })),
            subItems: productUtilizationSubitems,
          }
        ]);
      }
      if (isOrganizationManager || isBillingManager) {
        katalonMangeItems = katalonMangeItems.concat([
          {
            name: t('left-nav#license-management'),
            link: route.organization_kse_licenses_link,
            iconInactive: <IconNavLicenseManagementInactive />,
            iconActive: <IconNavLicenseManagementActive />,
            pattern: route.organization_licenses_link,
          },
        ]);
      }
      if (isOrganizationManager) {
        katalonMangeItems = katalonMangeItems.concat([
          {
            name: t('left-nav#user-management'),
            link: Apis.katOneUserManagementPage({ orgId: this.organizationId }),
            iconInactive: <IconNavUserManagementInactive />,
            iconActive: <IconNavUserManagementActive />,
          },
        ]);
      }
      if (isBillingManager && !isOnPremise && !subscriptionAndPaymentMethodDisabled) {
        katalonMangeItems = katalonMangeItems.concat([
          {
            name: t('payment'),
            link: Apis.katOnePaymentMethodPage({ organizationId }),
            iconInactive: <IconNavPaymentMethodInactive />,
            iconActive: <IconNavPaymentMethodActive />,
          },
        ]);
      }
      if (isBillingManager && !isOnPremise && !subscriptionAndPaymentMethodDisabled) {
        const subscriptionSubItems = [
          {
            name: t('katalon_studio'),
            link: route.organization_subscription,
            iconInactive: <IconKSEBig />,
            iconActive: <IconKSEBig />,
            indented: true,
          },
          ...Arrays.insertIf(MConfigs.testOpsSubscriptionEnabled, (
            {
              name: t('katalon_testops'),
              link: route.organization_testops_subscription,
              iconInactive: <IconTestOps />,
              iconActive: <IconTestOps />,
              indented: true,
            }
          )),
          {
            name: t('organization_trial_request_nav'),
            link: route.organization_trial_request,
            iconInactive: <IconTestOps />,
            iconActive: <IconTestOps />,
            indented: true,
          },
        ];
        katalonMangeItems = katalonMangeItems.concat([
          {
            name: 'Subscription Management',
            iconInactive: <IconNavSubscriptionManagementInactive />,
            collapsible: false,
            open: this.state.subscriptionSubmenuOpen,
            link: Apis.katOneAccountSubscriptionManagementPage({ accountId }),
          }
        ]);
      }
      // Security is available on both OP and Cloud
      if (isOrganizationOwner || isOrganizationManager) {
        katalonMangeItems = katalonMangeItems.concat([
          {
            name: t('settings-organization-management'),
            link: route.organization_settings_link,
            iconInactive: <IconNavOrganizationManagementInactive />,
            iconActive: <IconNavOrganizationManagementActive />
          },
        ]);
      }
      if (isOrganizationManager) {
        katalonMangeItems = katalonMangeItems.concat([
          {
            name: t('kse_timeout_setting'),
            link: route.organization_kse_timeout_setting_link,
            iconInactive: <IconNavIdleTimeoutInactive />,
            iconActive: <IconNavIdleTimeoutActive />
          },
        ]);
      }
      if (isOrganizationManager) {
        katalonMangeItems = katalonMangeItems.concat([
          {
            name: t('account_settings'),
            link: Apis.katOneAccountSettingsPage({ organizationId }),
            iconInactive: <IconAccountSettingsInactive />,
          },
        ]);
      }
      if ((isOrganizationManager || isBillingManager) && isLicenseServerOnPremise) {
        katalonMangeItems = katalonMangeItems.concat([
          {
            name: t('testops-license'),
            link: route.organization_testops_link,
            // icon: <IconLicense />,
          },
        ]);
      }

      if (isOrganizationManager && isOnPremise) {
        organizationItems = organizationItems.concat([
          {
            name: t('email_server_configuration'),
            link: route.organization_configuration_link,
            icon: <IconSettings />,
            // iconActive: <IconSettingsActive />,
            // iconInactive: <IconSettingsInactive />,
          },
        ]);
      }

      if ((isOrganizationOwner || isOrganizationManager) && !isAdminLicense) {
        katalonMangeItems = katalonMangeItems.concat([
          {
            name: t('projects'),
            link: route.organization_manage_projects_link,
            // icon: <IconProjects />,
          }
        ]);
      }
    }

    let userItems = [];
    if (MAuth.isLoggedIn && type === PageType.USER) {
      userItems = userItems.concat([
        ...Arrays.insertIf(!isOnPremise, {
          name: t('user_license'),
          link: route.user_license_link,
          // iconActive: <IconLicenseActive />,
          // iconInactive: <IconLicenseInactive />,
          exactMatch: true,
        }),
        {
          name: t('agent#api-key'),
          link: route.apikey_link,
          // iconActive: <IconApiKeyActive />,
          // iconInactive: <IconApiKeyInactive />,
          exactMatch: false,
        },
        ...Arrays.insertIf(!this.hideReporting, {
          name: t('notifications'),
          link: route.user_settings_link,
          // iconActive: <IconSettingsActive />,
          // iconInactive: <IconSettingsInactive />,
          exactMatch: true,
        }),
        ...Arrays.insertIf(!isOnPremise, {
          name: t('katalon_recorder_backup'),
          link: route.user_katalon_recorder_backup,
          // iconActive: <IconKatalonRecorderBackupActive />,
          // iconInactive: <IconKatalonRecorderBackupInactive />,
          exactMatch: true,
        }),
        {
          name: t('integrations'),
          link: route.user_integrations,
          exactMatch: true,
        },
      ]);
    }

    let teamItems = [];
    const isTeamScope = MAuth.isLoggedIn && type === PageType.TEAM;
    if (isTeamScope) {
      const isTeamManager = MAuth.isTeamManager(this.teamId);
      const { isLicenseAdminServer } = MConfigs;
      teamItems = teamItems.concat([
        {
          name: t('dashboard'),
          link: isLicenseAdminServer
            ? Apis.testOpsTeamHome(this.teamId)
            : route.teams_dashboard_link,
          // iconActive: <IconDashboardActive />,
          // iconInactive: <IconDashboardInactive />,
          exactMatch: true,
        },
        {
          name: t('projects'),
          link: !isLicenseAdminServer
            ? Apis.adminTeamProject(this.teamId)
            : route.admin_projects_link,
          // iconActive: <IconProjectsActive />,
          // iconInactive: <IconProjectsInactive />,
        },
      ]);
      if (isTeamManager && isLicenseAdminServer) {
        teamItems = teamItems.concat([
          {
            name: t('users'),
            link: !isLicenseAdminServer
              ? Apis.adminTeamUsers(this.teamId)
              : route.admin_users_link,
            // iconActive: <IconUserActive />,
            // iconInactive: <IconUserInactive />,
          },
          {
            name: t('settings'),
            link: !isLicenseAdminServer
              ? Apis.adminTeamSettings(this.teamId)
              : route.admin_team_settings_link,
            // iconActive: <IconSettingsActive />,
            // iconInactive: <IconSettingsInactive />,
          },
        ]);
      }
    }

    if (!organizationItems.length
      && !userItems.length
      && katalonMangeItems.length <= 1
      && !teamItems.length
      && !organizationSettingItems.length) {
      return null;
    }

    return (
      <div id="left-navbar">
        <div
          className="left-navbar-collapse-button"
          onClick={() => this.setState((prevState) => ({ ...prevState, sidebarOpen: !prevState.sidebarOpen }))}
        >
          {this.state.sidebarOpen ? <IconSidebarArrowLeft /> : <IconSidebarArrowRight />}
        </div>
        <div className="left-navbar-menu-container">
          <CSSTransition in={this.state.sidebarOpen} timeout={3000} classNames="left-navbar-transition" unmountOnExit>
            <Navbar className="custom-navbar">
              <Nav navbar className="sidebar-main">
                {organizationItems.map(this.renderItem)}
                {userItems.map(this.renderItem)}
                {katalonMangeItems.length > 1 && katalonMangeItems.map(this.renderItem)}
                {teamItems.map(this.renderItem)}
                {moveAgentToOrgLevelPhase2Enabled && organizationSettingItems.map(this.renderItem)}
              </Nav>
            </Navbar>
          </CSSTransition>
        </div>
      </div>
    );
  }
}

export default LeftNavigationBar;
