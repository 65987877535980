import React from 'react';
import _ from 'lodash';
import { Label, FormGroup, Badge, Row, Col } from 'reactstrap';
import filesize from 'filesize';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import Icon from '@katalon-studio/katalon-ui/Icon';
import MTableColumnDataMapping from '../../components/table/models/MTableColumnDataMapping';
import MContext from '../../models/MContext';
import Arrays from '../../utils/Arrays';
import Routes from '../../utils/Routes';
import DecoratorConstants from '../../utils/DecoratorConstants';
import { t } from '../../i18n/t';
import ExternalIssue from '../external_issue/ExternalIssue';
import DataTable from './DataTable';
import Apis from '../../utils/Apis';
import http from '../../utils/http';
import InfoSidebarUtil from '../../utils/InfoSidebar';
import {
  buildDefaultFilter,
  buildFilter
} from '../search-query/FilterQueryHelper';
import { buildSortQuery } from '../search-query/SortQueryHelper';
import Gallery from '../../components/gallery/Gallery';
import {
  IconDefectAdd,
  IconLinkDefect,
  IconViewSidebarActive,
  IconMarkAsRetested,
  IconChevronRight,
  IconDuration,
  IconStartTime,
  IconProfileV2,
  IconTestSuiteTable,
} from '../../images/CustomIcon';
import ActionDropdownMenu from '../action/ActionDropdownMenu';
import ActionDropdownItem from '../action/ActionDropdownItem';
import TestSuiteFilter from '../search-query/filter/TestSuiteFilter';
import StatusFilter, { StatusType } from '../search-query/filter/StatusFilter';
import TimeFilter from '../search-query/filter/TimeFilter';
import InputFilter from '../search-query/filter/InputFilter';
import SimilarFailureHelper from '../../utils/SimilarFailureHelper';
import MConfigs from '../../models/MConfigs';
import MarkAsRetestedDialog from '../dialog/MarkAsRetestedDialog';
import GroupEvent from '../../utils/track/GroupEvent';
import MFlags from '../../models/MFlags';
import ResultActionButtons from '../../pages/execution_test_result/ResultActionButtons';
import ExternalIssues from '../external_issue/ExternalIssues';
import ReportDefectDialog from '../dialog/ReportDefectDialog';
import FilterUnresolvedTestResult from '../search-query/filter/FilterUnresolvedTestResult';

class ExecutionTestResultDataTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeExecutionTestResult: null,
      errorDetails: null,
      isOpenMarkAsRetestedDialog: false,
      isOpenReportDefectDialog: false,
    };
    this.markAsRetestedRef = React.createRef();
    this.executionTestResultList = null;
    this.projectId = MContext.projectId;
    this.handleCloseMarkAsRetestedDialog = this.handleCloseMarkAsRetestedDialog.bind(this);
    this.handleCloseReportDefectDialog = this.handleCloseReportDefectDialog.bind(this);
    this.toggleShowTestCaseOnInfoSidebar = this.toggleShowTestCaseOnInfoSidebar.bind(this);
  }

  amIclickedElement(e, id) {
    // eslint-disable-next-line no-restricted-globals
    e = e || event;
    if (!e || !id) return false;
    const target = e.target || e.srcElement;
    if ((target.id === id) || (target.parentElement.id === id)) return true;
    else return false;
  }

  toggleShowTestCaseOnInfoSidebar(executionTestResult, event) {
    if (this.amIclickedElement(event, 'mark-status-button')) return;
    if (this.amIclickedElement(event, 'report-defect-button')) return;
    if (this.amIclickedElement(event, 'external-link-button')) return;
    if (executionTestResult === this.state.activeExecutionTestResult) {
      if (InfoSidebarUtil.isOpened) {
        InfoSidebarUtil.close();
      }
      this.clearActiveExecutionTestResult();
    } else {
      this.fetchErrorDetails({ errorDetailsId: executionTestResult.errorDetailsId })
        .then((errorDetails) => {
          const testResultInfoDOM = this.buildTestResultInfoDOM(executionTestResult, errorDetails);
          InfoSidebarUtil.show(executionTestResult.testCase.name, testResultInfoDOM);
          this.setState({
            activeExecutionTestResult: executionTestResult,
            errorDetails
          });
          InfoSidebarUtil.onClose(() => {
            this.clearActiveExecutionTestResult();
          });
        });

      this.fetchAttachments(executionTestResult.id)
        .then((executionTestResult) => {
          if (!executionTestResult) {
            return;
          }
          const attachments = executionTestResult.attachments.map((item) => ({
            source: item.url,
            caption: `${item.name} (${filesize(item.size)})`,
            fileType: item.name.split('.').pop(),
          }));

          if (executionTestResult.errorDetailsId) {
            attachments.push({
              source: Apis.executionTestResultLog(executionTestResult.errorDetailsId),
              caption: 'error-stack-trace.txt',
              fileType: 'txt',
            });
          }

          if (executionTestResult.stdoutId) {
            attachments.push({
              source: Apis.executionTestResultLog(executionTestResult.stdoutId),
              caption: 'stdout.txt',
              fileType: 'txt',
            });
          }

          const constructedLink = new Routes({
            executionId: executionTestResult.execution.order,
            executionTestResultId: executionTestResult.urlId,
          });
          const testResultDetailsLink = constructedLink.execution_test_result_detail_link;
          const testResultInfoDOM = this.buildTestResultInfoDOM(
            executionTestResult,
            this.state.errorDetails,
            attachments
          );
          InfoSidebarUtil.show(executionTestResult.testCase.name, testResultInfoDOM, testResultDetailsLink);
        });
    }
  }

  renderJiraIssues(testResult) {
    return MFlags.jiraIssueLinkingCreatingEnabled ?
      (
        <ExternalIssues
          title={t('defects')}
          projectId={this.projectId}
          objectType="EXECUTION_TEST_RESULT"
          objectId={testResult.id}
          isShortenContent
        />
      ) :
      (
        <ExternalIssue
          title={t('jira_defect')}
          projectId={this.projectId}
          objectType="EXECUTION_TEST_RESULT"
          objectId={testResult.id}
        />
      );
  }

  clearActiveExecutionTestResult() {
    this.setState({
      activeExecutionTestResult: null,
    });
  }

  fetchErrorDetails({ errorDetailsId }) {
    if (errorDetailsId) {
      return http.get(Apis.executionTestResultLog(errorDetailsId), null, null, 'text');
    } else {
      return Promise.resolve();
    }
  }

  fetchAttachments(executionTestResultId) {
    if (executionTestResultId) {
      return http.get(Apis.executionTestResult(executionTestResultId));
    } else {
      return null;
    }
  }

  showReportDefectDialog(executionTestResult) {
    this.setState({
      executionTestResult,
      isOpenReportDefectDialog: true
    });
  }

  renderReportDefectDialog() {
    const { executionTestResult, isOpenReportDefectDialog } = this.state;
    return (
      <ReportDefectDialog
        isOpen={isOpenReportDefectDialog}
        executionTestResult={executionTestResult}
        handleClose={this.handleCloseReportDefectDialog}
      />
    );
  }

  handleCloseReportDefectDialog() {
    this.setState({
      isOpenReportDefectDialog: false,
    });
  }

  buildTestResultInfoDOM(executionTestResult, errorDetails, attachments) {
    if (!executionTestResult) return null;
    return (
      <form>
        {MFlags.jiraIssueLinkingCreatingEnabled && (
          <ResultActionButtons
            onMarkStatus={() => this.showMarkAsRetestedConfirmDialog(executionTestResult.id, executionTestResult.status)}
            onReportDefect={() => this.showReportDefectDialog(executionTestResult)}
            useInnerDialog={this.props.useInnerDialog}
            executionTestResult={executionTestResult}
            isRowItem={false}
          />
        )}
        {this.renderJiraIssues(executionTestResult)}
        {executionTestResult.errorMessage && (
        <FormGroup>
          <Label>Summary</Label>
          <div className="text-failed-color px-2">
            <pre className="text-wrap">{executionTestResult.errorMessage}</pre>
          </div>
        </FormGroup>)}
        {attachments && (
        <FormGroup>
          <Label>Attachments</Label>
          <div className="px-2">
            {attachments.length && <Gallery images={attachments} data-trackid="view-test-result-attachment" />}
          </div>
        </FormGroup>)}
        {errorDetails && (
        <FormGroup>
          <Label>Errors</Label>
          <div
            className="text-failed-color white-space-pre px-2"
          >
            <pre>{errorDetails ? errorDetails.trim() : ''}</pre>
          </div>
        </FormGroup>)}
      </form>
    );
  }

  defectDecorator(name, row) {
    const maxDefectShown = 3;
    let totalDefects = _.get(row, 'totalDefects', 0);
    let externalIssues = _.get(row, 'externalIssues', []);
    externalIssues = _.filter(externalIssues, (issue) => (!issue.deleted && !issue.migrationError));
    totalDefects = externalIssues.length;

    const defectTooltip = externalIssues.map((e) => e.issueId).join(', ');
    const defectContent = Arrays.intersperse(externalIssues, ', ', (e) => (
      <a href={e.url} target="_blank" rel="noopener noreferrer">{e.issueId}</a>
    ));

    return (
      <span
        className="d-block"
        title={defectTooltip}
      >
        <a
          data-trackid="show-defects"
          color="link"
          onClick={() => this.toggleShowTestCaseOnInfoSidebar(row)}
          className={`font-weight-normal ${totalDefects ? '' : 'defect-add'}`}
        >
          {totalDefects ? <IconLinkDefect title={t('defects')} className="mr-1" /> : <IconDefectAdd />}
        </a>
        {totalDefects > maxDefectShown ? totalDefects : defectContent}
      </span>
    );
  }

  testCaseDecorator(name, row) {
    const testCaseName = _.get(row, 'testCase.name');
    const testCasePath = _.get(row, 'testCase.path');
    const fullPath = `${testCasePath}/${testCaseName}`;
    return (
      <span
        title={fullPath}
      >
        <a
          data-trackid="show-details-sidebar"
          data-groupid={GroupEvent.ACCESS_REPORT}
          color="link"
          onClick={() => this.toggleShowTestCaseOnInfoSidebar(row)}
        >
          <IconViewSidebarActive className="mr-1" />{testCaseName}
        </a>
      </span>
    );
  }

  rerunDecorator(name, row) {
    const lastRetryTestId = _.get(row, 'lastRetryTestId');
    const currentRetryId = _.get(row, 'currentRetry');
    return (
      <>
        {lastRetryTestId > 0 &&
          <>
            <Badge className="status-badge-false">
              {t('rerun')} #{currentRetryId}
            </Badge>
          </>}
      </>
    );
  }

  showMarkAsRetestedConfirmDialog(testResultId, status) {
    this.setState({
      testResultId,
      status,
      isOpenMarkAsRetestedDialog: true
    });
  }

  renderMarkAsRetestedDialog() {
    const { testResultId, status, isOpenMarkAsRetestedDialog } = this.state;
    return (
      <MarkAsRetestedDialog
        isOpen={isOpenMarkAsRetestedDialog}
        testRunId={testResultId}
        status={status}
        handleClose={this.handleCloseMarkAsRetestedDialog}
      />
    );
  }

  handleCloseMarkAsRetestedDialog() {
    this.setState({
      isOpenMarkAsRetestedDialog: false,
    });
  }

  refreshTestRun() {
    this.executionTestResultList.refreshData();
  }

  renderResultActionButtons(row) {
    return (
      <ResultActionButtons
        isRowItem
        executionTestResult={row}
        onMarkStatus={() => this.showMarkAsRetestedConfirmDialog(row.id, row.status)}
        onReportDefect={() => this.showReportDefectDialog(row)}
        isShownExternalLink
      />
    );
  }

  renderMarkAsRetested(row) {
    const status = _.get(row, 'status');
    if (status === 'PASSED') {
      return (
        <ActionDropdownItem
          data-trackid="mark-as-failed"
          icon={IconMarkAsRetested}
          label="Mark as Failed"
          tag="button"
          onClick={() => this.showMarkAsRetestedConfirmDialog(row.id, status)}
          afterCreate={() => this.refreshTestRun()}
          color="link"
          title="Mark as Failed"
        />
      );
    } else {
      return (
        <ActionDropdownItem
          data-trackid="mark-as-passed"
          icon={IconMarkAsRetested}
          label="Mark as Passed"
          tag="button"
          onClick={() => this.showMarkAsRetestedConfirmDialog(row.id, status)}
          afterCreate={() => this.refreshTestRun()}
          color="link"
          title="Mark as Passed"
        />
      );
    }
  }

  render() {
    const { showColumnSimilarFailures, additionalFilterQuery, isLinkedDefectedDefaultValue } = this.props;
    const columnMapping = [
      new MTableColumnDataMapping(
        t('status'),
        'status',
        DecoratorConstants.executionTestResultStatusDecorator,
        undefined,
        'fit-content-column',
      ),
      new MTableColumnDataMapping(
        t('id'),
        'id',
        (name, row) => {
          const constructedLink = new Routes({
            executionId: _.get(row, 'execution.order'),
            executionTestResultId: row.urlId,
          });
          const linkProps = {
            'data-trackid': 'click-test-result-details',
            'data-groupid': GroupEvent.ACCESS_REPORT
          };
          return DecoratorConstants.idDecorator(name, row, constructedLink.execution_test_result_detail_link,
            null, null, linkProps);
        },
        undefined,
        'fit-content-column',
      ),
      new MTableColumnDataMapping(
        t('name'),
        '',
        (name, row) => {
          const tcContent = this.testCaseDecorator('testCase.name', row);
          const rerun = this.rerunDecorator('lastRerunTestId', row);
          const tsContent = DecoratorConstants.testSuiteDecorator('testSuite.name', row);
          const errorContent = DecoratorConstants.errorMessageDecorator('errorMessage', row);
          const profileContent = DecoratorConstants.profileDecorator('profile', row);
          return (
            <>
              <Row>
                <Col md="auto">{tcContent}</Col>
                <Col>{rerun}</Col>
              </Row>
              <div>{tsContent}</div>
              <div>{t('profile')}: {profileContent}</div>
              <div>{errorContent}</div>
            </>
          );
        },
        undefined,
        'long-text-column',
      ),
      ...Arrays.insertIf(showColumnSimilarFailures && !MConfigs.isOnPremise,
        new MTableColumnDataMapping(
          t('similar_failures'),
          '',
          (name, row) => {
            if (SimilarFailureHelper.isFailedTestResult(row)) {
              const constructedLink = new Routes({
                executionId: _.get(row, 'execution.order'),
                executionTestResultId: row.urlId,
              });
              return DecoratorConstants.linkDecorator(t('view_analysis'), constructedLink.execution_test_result_similar_failures_link);
            }
            return null;
          }
        )
      ),
      ...Arrays.insertIf(
        MFlags.jiraIssueLinkingCreatingEnabled,
        new MTableColumnDataMapping(
          t('defects'),
          '',
          (name, row) => (DecoratorConstants.defectListWithBadgeDecorator(row)),
        ),
      ),
      new MTableColumnDataMapping(
        t('time'),
        '',
        DecoratorConstants.timeAndDurationDecorator,
        undefined,
        'nowrap-column',
      ),
      new MTableColumnDataMapping(
        t('table-header#totalAssertion'),
        '',
        DecoratorConstants.assertionDecorator,
      ),
      ...Arrays.insertIf(
        MFlags.jiraIssueLinkingCreatingEnabled,
        new MTableColumnDataMapping(
          t('table-header#action'),
          'id',
          (name, row) => (
            <>
              {this.renderResultActionButtons(row)}
            </>
          ),
          true,
        ),
      ),
      ...Arrays.insertIf(
        !MFlags.jiraIssueLinkingCreatingEnabled,
        new MTableColumnDataMapping(
          t('links'),
          '',
          (name, row) => {
            const defectContent = this.defectDecorator('totalDefects', row);
            return (
              <>
                {defectContent}
              </>
            );
          },
        ),
      ),
      new MTableColumnDataMapping(
        t('table-header#comments'),
        'hasComment',
        DecoratorConstants.totalCommentDecorator,
      ),
      ...Arrays.insertIf(
        !MFlags.jiraIssueLinkingCreatingEnabled,
        new MTableColumnDataMapping(
          t('table-header#action'),
          'id',
          (name, row) => {
            const id = _.get(row, name);
            return (
              <ActionDropdownMenu hideHeader rowId={`execution_${id}`} direction="left">
                {this.renderMarkAsRetested(row)}
              </ActionDropdownMenu>
            );
          },
          true,
        ),
      ),
    ];
    let filterQuery = [
      ...Arrays.insertIf(
        MFlags.filterUnresolvedTestResultEnabled,
        buildFilter(FilterUnresolvedTestResult, {
          id: 'isLinkedDefect',
          label: 'isLinkedDefect',
          operator: '=',
          value: isLinkedDefectedDefaultValue,
        })
      ),
      buildFilter(StatusFilter, { id: 'status', type: StatusType.EXECUTION_TEST_RESULT }),
      buildFilter(TestSuiteFilter, { id: 'TestSuite.name' }),
      buildFilter(TimeFilter, { id: 'startTime', label: 'Started' }),
      buildFilter(InputFilter, { id: 'profile', label: 'Profile', operator: '~' }),
    ];
    if (additionalFilterQuery) {
      filterQuery = filterQuery.concat(additionalFilterQuery);
    }
    const sortQuery = [
      ...buildSortQuery('id', t('id')),
      ...buildSortQuery('TestCase.name', t('name')),
      ...buildSortQuery('startTime', t('table-header#startTime')),
      ...buildSortQuery('duration', t('table-header#duration')),
    ];
    const defaultFilter = buildDefaultFilter('TestCase.name', '~', 'Name');
    const newProps = {
      filterQuery,
      sortQuery,
      defaultFilter,
      columnMapping,
      ...this.props,
    };
    const { entityType, useSearchQuery, hideAllFilter } = this.props;
    if (MFlags.testResultListEnhancementEnabled) {
      const columnMappingV2 = [
        new MTableColumnDataMapping(
          t('summary'),
          '',
          (name, row) => (
            <div className="min-width-30-rem">
              <div className="mb-1 d-flex align-items-center">
                <span className="mr-2 pb-2px">
                  {DecoratorConstants.executionTestResultStatusDecorator('status', row)}
                </span>
                <span className="text-dark-color fw-bold mr-2">
                  {DecoratorConstants.truncateLeftStringDecorator(row.testCase.name, 80)}
                </span>
                {this.rerunDecorator(name, row)}
              </div>
              <Grid className="d-flex align-items-center execution-test-result-details" container>
                <Grid item className="mr-3">
                  #{row.id}
                </Grid>
                <Grid item className="mr-3">
                  <span className="d-block">
                    <Icon title={t('startTime')} type="fa-regular" name="fa-calendar" className="mr-1" />
                    {DecoratorConstants.timeDecorator('startTime', row)}
                  </span>
                </Grid>
                <Grid item className="mr-3">
                  <span className="d-block">
                    <IconDuration title={t('duration')} className="mr-1" />
                    {DecoratorConstants.durationDecorator('duration', row)}
                  </span>
                </Grid>
                {row.profile &&
                  <Grid item className="mr-3">
                    <span>
                      <IconProfileV2 title={t('profile')} className="mr-1" />
                      {DecoratorConstants.profileDecorator('profile', row)}
                    </span>
                  </Grid>}
                {row.testSuite.name &&
                  <Grid item className="mr-3">
                    <span>
                      <IconTestSuiteTable title={t('profile')} className="mr-1" />
                      {DecoratorConstants.testSuiteDecorator('testSuite.name', row)}
                    </span>
                  </Grid>}
              </Grid>
              {row.errorMessage && DecoratorConstants.buildErrorMessageTooltip(row.errorMessage)}
            </div>
          ),
          undefined,
          'long-text-column',
          '',
          undefined,
          ''
        ),
        new MTableColumnDataMapping(
          t('table-header#comments'),
          'hasComment',
          DecoratorConstants.totalCommentDecorator,
        ),
        new MTableColumnDataMapping(
          t('table-header#totalAssertion'),
          '',
          DecoratorConstants.assertionDecorator,
        ),
        ...Arrays.insertIf(
          MFlags.jiraIssueLinkingCreatingEnabled,
          new MTableColumnDataMapping(
            t('defects'),
            '',
            (name, row) => (DecoratorConstants.defectListWithBadgeDecorator(row)),
          ),
        ),
        new MTableColumnDataMapping(
          t('table-header#action'),
          'id',
          (name, row) => (
            <>
              {this.renderResultActionButtons(row)}
            </>
          ),
          true,
          'align-middle',
        ),
        new MTableColumnDataMapping(
          '',
          '',
          () => <IconChevronRight />,
          undefined,
          'align-middle',
        ),
      ];
      const filterNeedReview = [
        buildFilter(FilterUnresolvedTestResult, {
          id: 'isLinkedDefect',
          label: 'isLinkedDefect',
          operator: '=',
          value: isLinkedDefectedDefaultValue,
        })
      ];
      const newPropsV2 = {
        sortQuery,
        defaultFilter,
        ...this.props,
      };
      return (
        <>
          <DataTable
            isTableContainer
            filterQuery={hideAllFilter ? filterNeedReview : filterQuery}
            useSortByColumn
            columnMapping={columnMappingV2}
            ref={(ref) => {
              this.executionTestResultList = ref;
            }}
            entityType={entityType}
            useSearchQuery={useSearchQuery}
            {...newPropsV2}
            handleSelectRow={this.toggleShowTestCaseOnInfoSidebar}
          />
          {this.renderMarkAsRetestedDialog()}
          {this.state.isOpenReportDefectDialog && this.renderReportDefectDialog()}
        </>
      );
    }
    return (
      <>
        <DataTable
          ref={(ref) => {
            this.executionTestResultList = ref;
          }}
          entityType={entityType}
          useSearchQuery={useSearchQuery}
          {...newProps}
        />
        {this.renderMarkAsRetestedDialog()}
        {MFlags.hideResultActionWhenParsingNotCompletedEnabled && this.state.isOpenReportDefectDialog && this.renderReportDefectDialog()}
      </>
    );
  }
}

ExecutionTestResultDataTable.defaultProps = {
  useSearchQuery: true,
  entityType: 'ExecutionTestResult',
  useInnerDialog: false,
  isLinkedDefectedDefaultValue: false,
};

ExecutionTestResultDataTable.propTypes = {
  useSearchQuery: PropTypes.bool,
  entityType: PropTypes.string,
  useInnerDialog: PropTypes.bool,
  isLinkedDefectedDefaultValue: PropTypes.bool,
};

export default ExecutionTestResultDataTable;
