import React from 'react';
import { Card, CardBody } from 'reactstrap';

export default (props) => {
  const { children } = props;
  return (
    <Card>
      <CardBody>
        {children}
      </CardBody>
    </Card>
  );
};
