import React from 'react';
import { get } from 'lodash';
import Routes from '../../utils/Routes';
import DecoratorConstants from '../../utils/DecoratorConstants';
import GroupEvent from '../../utils/track/GroupEvent';
import Link from '../../components/Link';

const MAX_SCHEDULERS = 3;

export const BaselineCollectionDecorator = {

  versionDecorator: (version) => {
    if (version) {
      return `v.${version}`;
    }
    return null;
  },

  numberOfImagesDecorator: (name, row) => {
    const numberOfBaselines = get(row, name);

    return numberOfBaselines;
  },

  listScheduleDecorator: (name, row, style) => {
    const runConfigurations = get(row, name);

    const decorator = (runConfiguration) => {
      const routes = new Routes({ runConfigurationId: runConfiguration.id });
      const runConfigurationLink = routes.run_configuration_link;
      return DecoratorConstants.idDecorator('name', runConfiguration, runConfigurationLink, undefined, undefined, undefined, true);
    };

    let schedulerLinks;
    let more;
    const length = runConfigurations.length;

    if (length <= MAX_SCHEDULERS) {
      schedulerLinks = runConfigurations.map(decorator);
      more = '';
    } else {
      schedulerLinks = runConfigurations.slice(0, MAX_SCHEDULERS).map(decorator);

      const constructedLink = new Routes({
        baselineCollectionGroupOrder: row.order,
      });

      more = <Link className="text-decoration-none" href={constructedLink.baseline_collection_group_detail_link}>({length - MAX_SCHEDULERS} more...)</Link>;
    }
    return style ? <div className={style}>{schedulerLinks}{more}</div> : <div>{schedulerLinks}{more}</div>;
  },

  comparedBaselineDecorator: (name, row) => {
    const baselineCollection = get(row, name, {});
    const baselineCollectionName = get(baselineCollection, 'baselineCollectionGroup.name', '');
    const version = baselineCollection?.version;
    if (baselineCollectionName && version) {
      const order = get(baselineCollection, 'baselineCollectionGroup.order', '');
      const constructedLink = new Routes({
        baselineCollectionGroupOrder: order,
      });
      const link = constructedLink.baseline_collection_group_history_link({ v: version });
      const trackingProps = {
        'data-trackid': 'page-visual-testing-click-baseline-version',
        'data-groupId': GroupEvent.ACCESS_REPORT,
      };
      return DecoratorConstants.nameAndVersionLinkDecorator(baselineCollectionName, version, link, trackingProps);
    }
    return '';
  }
};
