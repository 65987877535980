import BaseKSELicensesTab from './BaseKSELicensesTab';
import { t } from '../../i18n/t';
import { OrganizationFeature } from '../../utils/Constants';

class OrganizationKSELicensesTab extends BaseKSELicensesTab {
  constructor(props) {
    super({ ...props, feature: OrganizationFeature.KSE });
    this.meta.id = 'page-organization-kse-licenses';
    this.meta.title = t('kse_license_keys');
  }
}

export default OrganizationKSELicensesTab;
