import React, { ReactElement, ReactNode } from 'react';
import { Tooltip, TooltipProps } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

interface TooltipErrorDetailProps {
  text: ReactNode;
  placement?: TooltipProps['placement'];
  children: ReactElement;
}

const style = makeStyles(() => ({
  tooltip: {
    backgroundColor: '#FFFFFF',
    fontWeight: '300',
    margin: '0.3rem',
    lineHeight: '1rem',
    fontSize: '0.8rem',
    width: '32rem',
    maxWidth: '32rem',
  }
}));

function TooltipErrorDetail(props: TooltipErrorDetailProps) {

  const {
    text,
    placement = 'right',
    children,
  } = props;

  const classes = style();
  return (
    <Tooltip classes={classes} title={text} placement={placement}>
      {children}
    </Tooltip>
  );
}

export default TooltipErrorDetail;
