import React from 'react';
import _ from 'lodash';
import MTableColumnDataMapping from './models/MTableColumnDataMapping';
import DecoratorConstants from '../../utils/DecoratorConstants';
import DataTable from './DataTable';
import { t } from '../../i18n/t';
import Routes from '../../utils/Routes';
import ActionDropdownMenu from '../action/ActionDropdownMenu';
import ActionDropdownItem from '../action/ActionDropdownItem';
import { IconDelete, IconEdit } from '../../images/KitIcons';
import MContext from '../../models/MContext';
import DeleteBuildDialog from '../dialog/DeleteBuildDialog';
import TestCaseExecutionStatus from './TestCaseExecutionStatus';
import { buildSearchCondition } from '../search/SearchUtils';

export default class BuildDataTable extends React.Component {
  constructor(props) {
    super(props);
    this.teamId = MContext.teamId;
    this.buildRef = React.createRef();
    this.buildsList = React.createRef();
    this.state = {
      build: null,
    };
    this.refreshData = this.refreshData.bind(this);
    this.buildStatistics = this.buildStatistics.bind(this);
  }

  refreshData() {
    this.buildsList.current.refreshData();
  }

  renderDeleteButton(row) {
    return (
      <ActionDropdownItem
        icon={IconDelete}
        label="Delete"
        tag="button"
        onClick={() => this.showDeleteBuildDialog(row)}
        color="link"
        title="Delete Build"
      />
    );
  }

  renderEditButton(row) {
    return (
      <ActionDropdownItem
        icon={IconEdit}
        label="Edit"
        tag="button"
        onClick={() =>
          Routes.goToEditBuildPage(this.teamId, row.projectId, row.id)}
        color="link"
        title="Edit Build"
      />
    );
  }

  showDeleteBuildDialog(build) {
    this.setState(
      {
        build,
      },
      () => this.showDialog()
    );
  }

  showDialog() {
    this.buildRef.current.toggle();
  }

  renderDeleteBuildDialogs() {
    const { build } = this.state;
    return (
      <DeleteBuildDialog
        ref={this.buildRef}
        build={build}
        afterDeleteBuild={this.refreshData}
      />
    );
  }

  buildStatistics(name, row) {
    const buildStatistics = _.get(row, name);
    const emptyStatistics = {
      totalPassed: 0,
      totalFailed: 0,
      totalExecution: 0
    };
    return (
      <TestCaseExecutionStatus
        key={row.id}
        statistics={buildStatistics || emptyStatistics}
        showLegend={false}
        defaultSearchConditions={[
          buildSearchCondition('buildId', '=', row.id),
        ]}
      />
    );
  }

  renderBuilds() {
    const columnMapping = [
      new MTableColumnDataMapping(t('name'), 'name', (name, row) => {
        const routes = new Routes({ buildId: row.id });
        return DecoratorConstants.idDecorator(name, row, routes.build_details_link);
      }),
      new MTableColumnDataMapping(
        t('executions'),
        'buildStatistics',
        (name, row) => {
          const value = _.get(row, name, {});
          const { totalExecution = 0 } = value;
          return (
            <span title={t('executions')}>{totalExecution}</span>
          );
        },
        undefined,
        'short-text-column'
      ),
      new MTableColumnDataMapping(
        t('test_cases'),
        'buildStatistics',
        this.buildStatistics,
        false,
        'w-25',
      ),
      new MTableColumnDataMapping(
        '',
        '',
        DecoratorConstants.buildCountDecorator,
      ),
      new MTableColumnDataMapping(
        t('table-header#action'),
        'id',
        (name, row) => {
          const id = _.get(row, name);
          const items = [
            this.renderEditButton(row),
            this.renderDeleteButton(row),
          ];
          return (
            <>
              <ActionDropdownMenu
                hideHeader
                rowId={`build${id}`}
                direction="left"
              >
                {items}
              </ActionDropdownMenu>
            </>
          );
        },
        true,
        'fit-content-column'
      ),
    ];
    const newProps = {
      columnMapping,
      ...this.props,
    };
    return (
      <DataTable
        ref={this.buildsList}
        entityType="Build"
        defaultSort={['name,asc']}
        tableId="Builds"
        title="Builds"
        {...newProps}
      />
    );
  }

  render() {
    return (
      <>
        {this.renderBuilds()}
        {this.renderDeleteBuildDialogs()}
      </>
    );
  }
}
