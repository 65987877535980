import { Step, StepContent, StepLabel } from '@mui/material';
import MStepper from '@mui/material/Stepper';
import PropTypes from 'prop-types';
import React from 'react';
import { Badge, Button } from 'reactstrap';
import { t } from '../../i18n/t';
import { IconTick } from '../../images/CustomIcon';

class Stepper extends React.Component {

  constructor(props) {
    super(props);

    this.handleBack = this.handleBack.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.handleOnSubmit = this.handleOnSubmit.bind(this);

    this.state = this.getInitialState();
  }

  getInitialState() {
    return {
      activeStep: 0,
    };
  }

  handleBack() {
    this.setState(({ activeStep }) => ({
      activeStep: activeStep - 1,
    }));
  }

  handleNext(e) {
    e.preventDefault();
    this.setState(({ activeStep }) => ({
      activeStep: activeStep + 1,
    }));
  }

  handleReset() {
    this.setState({
      activeStep: 0,
    });
  }

  generateIndexIcon(index) {
    return (
      <Badge className="bg-primary badge-circle-index">{index + 1}</Badge>
    );
  }

  handleOnSubmit(e) {
    const { handleOnSubmit } = this.props;
    if (handleOnSubmit) {
      handleOnSubmit(e);
    }
  }

  render() {
    const { activeStep } = this.state;
    const { steps, expanded } = this.props;

    return (
      <MStepper
        activeStep={expanded ? -1 : activeStep}
        orientation="vertical"
        nonLinear={false}
      >
        {
          steps.map((step, index) => (
            <Step key={step.label} expanded={expanded}>
              <StepLabel
                icon={index >= activeStep ? this.generateIndexIcon(index) : <IconTick />}
              >
                <div className="stepper__header">
                  {step.label}
                </div>
                <div className="stepper__description">
                  {step.description}
                </div>
              </StepLabel>
              <StepContent>
                {step.content}
                {!expanded && (
                  <div className="stepper__button-zone">
                    <Button
                      disabled={activeStep === 0}
                      onClick={this.handleBack}
                    >
                      {t('stepper#back')}
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      onClick={this.handleNext}
                    >
                      {activeStep === steps.length - 1 ? t('create') : t('stepper#next')}
                    </Button>
                  </div>
                )}
              </StepContent>
            </Step>
          ))
        }
      </MStepper>
    );
  }
}

Stepper.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.any,
    description: PropTypes.any,
    content: PropTypes.any,
  })),
  expanded: PropTypes.bool,
  handleOnSubmit: PropTypes.func,
};

export default Stepper;
