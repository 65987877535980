import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import { startCase, toLower } from 'lodash';
import { Legend } from 'recharts';
import DataLoader from '../../../components/table/DataLoader';
import MContext from '../../../models/MContext';
import { buildSearchCondition, buildSearchFunction } from '../../../components/search/SearchUtils';
import Routes from '../../../utils/Routes';
import StatusDoughnutChart from '../../../components/chart/StatusDoughnutChart';
import { OSDetector } from '../../../utils/Environment';
import { t } from '../../../i18n/t';
import GroupEvent from '../../../utils/track/GroupEvent';
import colors from '../../../../scss/colors.scss';
import TooltipComponent from '../../../components/TooltipComponent';
import NormalCard from '../../../components/card/NormalCard';
import Link from '../../../components/Link';

const operationSystemPayload = [
  {
    color: colors.windowsColor,
    value: 'Windows',
    type: 'square',
  },
  {
    color: colors.macColor,
    value: 'Mac',
    type: 'square',
  },
  {
    color: colors.linuxColor,
    value: 'Linux',
    type: 'square',
  },
  {
    color: colors.othersColor,
    value: 'Others',
    type: 'square',
  }
];

class TestEnvironmentsCard extends Component {
  constructor(props) {
    super(props);
    this.teamId = MContext.teamId;
    this.projectId = MContext.projectId;
  }

  renderLegend = () => {
    const formatLegendName = (name) => startCase(toLower(name));

    return (
      <div className="d-flex justify-content-end card-footer default-legend">
        <Legend
          iconSize={8}
          formatter={formatLegendName}
          verticalAlign="bottom"
          align="right"
          payload={operationSystemPayload}
          margin={{ top: 0, left: 0, right: 3, bottom: 7 }}
        />
      </div>
    );
  };

  renderHeader = () => (
    <div className="project-dashboard-header project-dashboard-header-title">
      {t('project-dashboard#local-test-environments#title')}
      <TooltipComponent text={t('project-dashboard#local-test-environments#tooltip')} placement="bottom-end" arrow />
    </div>
  );

  renderDonutChart(agents, type) {
    if (agents.length === 0) {
      return null;
    }
    const statistics = this.countByOS(agents);
    const title = `${type} agent`;
    const newProps = {
      aspect: 2,
    };
    return (
      <Col sm="6" md="6">
        <StatusDoughnutChart
          key={`agent-${type}-chart-${statistics.id}`}
          data={this.convertAgentData(statistics, type)}
          type={startCase(title)}
          total={statistics.total}
          {...newProps}
        />
      </Col>
    );
  }

  countByOS(data) {
    const os = {
      windows: 0,
      linux: 0,
      mac: 0,
      others: 0,
      total: 0
    };

    data.forEach((item) => {
      const osName = item.content.os;
      const count = item.content.count;
      if (OSDetector.isWindows(osName)) {
        os.windows += count;
      } else if (OSDetector.isLinux(osName)) {
        os.linux += count;
      } else if (OSDetector.isMacOS(osName)) {
        os.mac += count;
      } else {
        os.other += count;
      }
      os.total += count;
    });
    return os;
  }

  convertAgentData(statistics, type) {
    const payload = operationSystemPayload;
    const items = payload.reduce((prevItems, os) => {
      const count = statistics[toLower(os.value)];
      if (count <= 0) {
        return prevItems;
      }
      return [
        ...prevItems,
        {
          key: `${type}-${os.value}-${count}`,
          value: count,
          label: os.value,
          color: `${toLower(os.value)}Color`,
        },
      ];
    }, []);
    return items;
  }

  renderChart(data) {
    const onlineAgents = data.filter((agent) => agent.content.active);
    const offlineAgents = data.filter((agent) => !agent.content.active);
    if (data.length > 0) {
      return (
        <>
          <Row className="justify-content-center">
            {this.renderDonutChart(onlineAgents, 'online')}
            {this.renderDonutChart(offlineAgents, 'offline')}
          </Row>
          <Row>
            <Col>
              {this.renderLegend()}
            </Col>
          </Row>
          <Row>
            <Col>
              {this.renderFooter()}
            </Col>
          </Row>
        </>
      );
    }
    return (
      <>
        {this.renderEmptyMessage()}
        {this.renderFooter()}
      </>
    );
  }

  renderFooter() {
    const routes = new Routes();

    return (
      <div className="text-right mr-2">
        <Link
          href={routes.agent_link}
          data-trackid="dashboard-view-all-local-agents"
          data-groupid={GroupEvent.ACCESS_REPORT}
        >
          {t('project-dashboard#local-test-environments#view-all')}
        </Link>
      </div>
    );
  }

  renderEmptyMessage() {
    return (
      <>
        <Row className="align-items-center justify-content-center">
          <Row>
            <Col sm="12" md="12">
              <div className="text-center"> {t('table#empty-message')} </div>
            </Col>
          </Row>
        </Row>
      </>
    );
  }

  renderDataloader = () => {
    const defaultSearchConditions = [
      buildSearchCondition('Team.id', '=', this.teamId),
    ];

    const newProps = {
      bodyComponent: NormalCard,
      headerComponent: NormalCard,
      cardClassName: 'project-dashboard-card-border shadow-none',
    };

    return (
      <DataLoader
        render={(data) => this.renderChart(data)}
        entityType="Agent"
        defaultSearchConditions={defaultSearchConditions}
        defaultSearchFunctions={[
          buildSearchFunction('count', 'COUNT', ['id']),
        ]}
        defaultSort={['os,desc']}
        groupBys={['os', 'active']}
        {...newProps}
      />
    );
  }

  render() {
    return (
      <>
        {this.renderHeader()}
        <div className="cards-row">
          {this.renderDataloader()}
        </div>
      </>
    );
  }
}

export default TestEnvironmentsCard;
