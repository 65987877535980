import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PageComponent from '../../components/PageComponent';
import Apis from '../../utils/Apis';
import { t } from '../../i18n/t';
import http from '../../utils/http';
import ObjectSummary from '../../components/summary/ObjectSummary';
import ObjectInfo from '../../components/summary/ObjectInfo';
import MContext from '../../models/MContext';
import { ExecutionTestSuiteHeaderNav } from '../../components/HeaderNav';
import Services from '../../utils/Services';
import RouteConstants from '../../utils/RouteConstants';
import TestRunsTab from './TestRunsTab';
import { ResultTab } from './ResultTab';
import CommentsTab from './CommentsTab';
import DecoratorConstants from '../../utils/DecoratorConstants';
import Time from '../../utils/Moment';
import { IconStartTime, IconDuration, IconProfile } from '../../images/CustomIcon';
import Routes from '../../utils/Routes';
import DefaultLayout from '../../components/DefaultLayout';

class ExecutionTestSuite extends PageComponent {
  constructor(props) {
    super(props);
    this.meta.id = 'page-execution-test-suite-details';
    this.urlId = MContext.executionTestSuiteId;
    this.projectId = MContext.projectId;
    this.teamId = MContext.teamId;
    this.state = {
      executionTestSuite: null,
      latestExecutionTestSuites: null,
    };
    this.renderHeader = this.renderHeader.bind(this);
    this.renderBody = this.renderBody.bind(this);
  }

  get(projectId, executionId, urlId) {
    http.get(Apis.executionTestSuite(projectId, executionId, urlId))
      .then((responseJson) => {
        const executionTestSuite = responseJson;
        this.meta.title = t('Test Suite {{name}}', {
          name: executionTestSuite.testSuite.name,
        });
        this.getLatestExecutionTestSuites(executionTestSuite);
        this.setState({
          executionTestSuite,
        });
      });
  }

  getLatestExecutionTestSuites(executionTestSuite) {
    const params = {
      pagination: {
        page: 0,
        size: 30,
        sorts: 'order,desc',
      },
      conditions: [
        {
          key: 'TestSuite.id',
          operator: '=',
          value: executionTestSuite.testSuite.id,
        },
      ],
      type: 'ExecutionTestSuite',
    };
    Services.search(params)
      .then((responseJson) => {
        const latestExecutionTestSuites = responseJson.content.reverse();
        this.setState({
          latestExecutionTestSuites,
        });
      });
  }

  componentDidMount() {
    this.get(MContext.projectId, MContext.executionId, this.urlId);
  }

  executionTestSuiteInfoItems() {
    const { executionTestSuite } = this.state;
    const routes = new Routes({
      projectId: this.projectId,
      teamId: this.teamId,
      testSuiteId: executionTestSuite.testSuite.id,
    });
    return [
      {
        text: DecoratorConstants.statusBadge(executionTestSuite.status),
      },
      {
        icon: <IconStartTime />,
        text: Time.format(executionTestSuite.startTime),
      },
      {
        icon: <IconDuration />,
        text: Time.duration(executionTestSuite.duration),
      },
      {
        icon: <IconProfile />,
        text: DecoratorConstants.profile(executionTestSuite.profiles),
      },
      {
        // icon: <IconPlatform />,
        text: DecoratorConstants.platformHeader(executionTestSuite.platform),
      },
      {
        text: (
          <a
            className="d-block"
            href={routes.execution_test_suite_history_link}
          >
            History
          </a>
        ),
      },
    ];
  }

  renderObjectTabInfo() {
    return (
      <>
        <ExecutionTestSuiteHeaderNav
          projectId={this.projectId}
          teamId={this.teamId}
          executionTestSuiteId={this.urlId}
        />
        <ObjectInfo items={this.executionTestSuiteInfoItems()} />
      </>
    );
  }

  renderObjectSummary() {
    const project = this.state.executionTestSuite.testSuite.project;
    const { executionTestSuite } = this.state;
    const urlParams = {
      teamId: MContext.teamId,
      projectId: project.id,
      project: project.name,
      executionTestSuiteId: executionTestSuite.execution.order,
      urlId: this.urlId,
      testSuiteName: executionTestSuite.testSuite.name,
    };
    return (
      <ObjectSummary params={urlParams}>
        {this.renderObjectTabInfo()}
      </ObjectSummary>
    );
  }

  renderHeader() {
    return (
      <>
        {this.renderObjectSummary()}
      </>
    );
  }

  renderBody() {
    const { executionTestSuite, latestExecutionTestSuites } = this.state;
    const projectId = executionTestSuite.testSuite.project.id;
    const teamId = MContext.teamId;

    return (
      <Switch>
        <Route
          exact
          path={RouteConstants.execution_test_suite_details}
          render={(params) => (
            <ResultTab
              executionTestSuite={executionTestSuite}
              latestExecutionTestSuites={latestExecutionTestSuites}
              {...params}
            />
          )}
        />
        <Route
          exact
          path={RouteConstants.execution_test_suite_test_runs}
          render={(params) => <TestRunsTab executionTestSuite={executionTestSuite} {...params} />}
        />
        <Route
          exact
          path={RouteConstants.execution_test_suite_comment}
          render={(params) => <CommentsTab
            projectId={projectId}
            teamId={teamId}
            objectId={executionTestSuite.id}
            objectType="EXECUTION_TEST_SUITE"
            {...params}
          />}
        />
      </Switch>
    );
  }

  render() {
    const { executionTestSuite, latestExecutionTestSuites } = this.state;
    if (executionTestSuite && latestExecutionTestSuites) {
      return (
        <DefaultLayout
          renderHeader={this.renderHeader}
          renderBody={this.renderBody}
        />
      );
    }
    return null;
  }
}

export default ExecutionTestSuite;
