/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import Icon from '@katalon-studio/katalon-ui/Icon';
import DocumentLink from '../../../utils/DocumentLink';
import CodeBox from '../../../components/CodeBox';
import MContext from '../../../models/MContext';
import MAuth from '../../../models/MAuth';
import Services from '../../../utils/Services';
import { ApiKeyOption } from '../../../models/model/ApiKeyOption';
import { ApiKey } from '../../../models/model/ApiKey';

function KatalonCliIntegration() {

  const [apiKey, setApiKey] = useState<ApiKeyOption>();

  const projectId = MContext.projectId;
  const user = MAuth.user;

  const getApiKeys = () => {
    Services.getApiKeys()
      .then((response) => {
        const apiKeys: ApiKeyOption[] = response.map(({ key, name } : ApiKey) => ({
          value: key,
          label: name,
        }));
        setApiKey(apiKeys[0]);
      });
  };

  useEffect(() => {
    getApiKeys();
  }, []);

  const dockerInstallCommand = 'docker run katalonstudio/katalon-cli schedule <parameters>';

  const runWithApiKeyAndScheduleIdCommand = `docker run katalonstudio/katalon-cli schedule trigger --api-key=${apiKey?.value} --operator=IDS_EXACT_MATCHES --values=Schedule ID`;

  const runWithAccountCommand = `docker run katalonstudio/katalon-cli schedule trigger --username=${user.email} --password=password --project-id=${projectId} --operator=NAME_CONTAINS --values="multiple"`;

  return (
    <div className="katalon-cli-page">
      <div>
        <div className="header">Download</div>
        Katalon CLI is available on Docker Hub: &nbsp;
        <a href={DocumentLink.KATALON_CLI_DOCKER_DOC} target="_blank" rel="noopener noreferrer">
          Katalon CLI &nbsp;
          <Icon type="fa-solid" name="fa-arrow-up-right-from-square" />
        </a>
      </div>
      <br />
      <div>
        <div className="header">Usage</div>
        With Docker installed and running, you can start triggering a schedule.
        <div className="mt-2">
          <CodeBox content={dockerInstallCommand} />
        </div>
        <br />
        <div className="sub-header">Parameters</div>
        <div className="param-decorator">
          <li>
            <span className="code-block">--api-key</span>&nbsp;
            : your Katalon API key, see:&nbsp;
            <a
              href={DocumentLink.KATALON_API_KEY_DOC}
              target="_blank"
              rel="noopener noreferrer"
            >
              Katalon API key&nbsp;
              <Icon type="fa-solid" name="fa-arrow-up-right-from-square" />
            </a>
          </li>
          <li>
            <span className="code-block">--username</span>
            &nbsp;and&nbsp;
            <span className="code-block">--password</span>&nbsp;
            : the username and password of your TestOps account.
          </li>
          <li>
            <span className="code-block">--project-id</span>&nbsp;
            : the ID of the project that contains your test schedule. This parameter is not required when using <span className="code-block">IDS_EXACT_MATCHES</span> for <span className="code-block">operator</span>.
          </li>
          <li><span className="code-block">--values</span> : the value that corresponds with the operator in use.</li>
        </div>
        <br />
        <div className="sub-header">Environment variables</div>
        You can use environment variables instead of parameters.
        <div className="param-decorator">
          <li><span className="code-block">API_KEY</span></li>
          <li><span className="code-block">USERNAME</span></li>
          <li><span className="code-block">PASSWORD</span></li>
          <li><span className="code-block">PROJECT_ID</span></li>
          <li><span className="code-block">OPERATOR</span></li>
          <li><span className="code-block">VALUES</span></li>
        </div>
      </div>
      <br />
      <div>
        <div className="header">Trigger command for TestOps schedules</div>
        The following are some sample commands to trigger an execution from the schedule:
        <div className="mt-2 sub-header">Trigger an execution with Katalon API key and schedule ID</div>
        <div className="mt-2">
          <CodeBox content={runWithApiKeyAndScheduleIdCommand} />
        </div>
        <br />
        <div className="sub-header">Trigger an execution with username / password and name pattern matching</div>
        <div className="param-decorator">
          <li><span className="code-block">--project-id</span> is required.</li>
          <li>This command triggers all schedules whose names contain the word &quot;multiple&quot;.</li>
        </div>
        <div className="mt-2 mb-3">
          <CodeBox content={runWithAccountCommand} />
        </div>
      </div>
    </div>
  );
}

export default KatalonCliIntegration;
