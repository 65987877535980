import React, { useEffect, useState } from 'react';
import { FormGroup, Label } from 'reactstrap';
import { Radio } from '@mui/material';
import PropTypes from 'prop-types';
import { t } from '../../i18n/t';
import Select from '../Select';
import Input from '../Input';
import TooltipComponent from '../TooltipComponent';

function KreVersionSelect(props) {
  const { value, ksVersions, onChange } = props;

  const [ksVersion, setKsVersion] = useState();
  const [ksLocation, setKsLocation] = useState();
  const [enableSelectKsLocation, setEnableSelectKsLocation] = useState(false);
  const [isSelectKsVersion, setIsSelectKsVersion] = useState(false);

  const handleKsVersionChange = (item) => {
    setKsVersion(item.value);
    onChange({ enableSelectKsLocation, ksVersion: item.value, ksLocation: undefined });
  };

  const handleSelectKsVersion = (event) => {
    setIsSelectKsVersion(event.target.checked);
    onChange({ enableSelectKsLocation, ksVersion: ksVersion || ksVersions[0]?.value, ksLocation: undefined });
  };

  const handleSelectKsLocation = (event) => {
    setIsSelectKsVersion(!event.target.checked);
    onChange({ enableSelectKsLocation, ksVersion: undefined, ksLocation });
  };

  const handleKsLocationChange = (item) => {
    setKsLocation(item.target.value);
    onChange({ enableSelectKsLocation, ksVersion: undefined, ksLocation: item.target.value });
  };

  useEffect(() => {
    if (value && (value?.ksVersion || ksVersion || value?.ksLocation || ksLocation)) {
      const { enableSelectKsLocation } = value;
      setKsVersion(value?.ksVersion || ksVersion);
      setKsLocation(value?.ksLocation || ksLocation);
      setEnableSelectKsLocation(enableSelectKsLocation);
      if (value?.ksLocation) {
        setIsSelectKsVersion(false);
      } else {
        setIsSelectKsVersion(!!value?.ksVersion);
      }
    } else {
      if (ksVersions && ksVersions.length > 0) {
        setKsVersion(ksVersions[0].value);
        setEnableSelectKsLocation(false);
        setIsSelectKsVersion(true);
      }
    }
  }, [value, ksVersions]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <FormGroup className="d-flex">
      <Label className="test-run-label col-2 pl-0 mt-0" for="ksVersion">{t('agent#kre-version')}</Label>
      <div className="d-flex flex-column p-0 col-10 mt-1">
        <FormGroup>
          {enableSelectKsLocation &&
          <div className="d-flex">
            <Radio
              sx={{ marginLeft: '-9px',
                '& .MuiSvgIcon-root': {
                  fontSize: 22,
                },
                '&.Mui-checked': {
                  color: '#276ef1',
                } }}
              id="chooseKsVersion"
              name="chooseKsVersion"
              className="pr-1"
              checked={isSelectKsVersion}
              onChange={handleSelectKsVersion}
              label={t('agent#select-version')}
              inputProps={{ 'aria-label': 'A' }}
            />
            <Label className="test-run-label p-0" for="ksVersionLabel">{t('agent#select-version')}</Label>
          </div>}
          <Select
            type="text"
            id="ksVersion"
            options={ksVersions}
            value={ksVersion}
            onChange={handleKsVersionChange}
            isDisabled={!isSelectKsVersion}
          />
        </FormGroup>
        {enableSelectKsLocation &&
        <FormGroup>
          <div className="d-flex">
            <Radio
              sx={{ marginLeft: '-9px',
                '& .MuiSvgIcon-root': {
                  fontSize: 22,
                },
                '&.Mui-checked': {
                  color: '#276ef1',
                } }}
              id="chooseKsLocation"
              name="chooseKsLocation"
              className="pr-1"
              checked={!isSelectKsVersion}
              onChange={handleSelectKsLocation}
              label={t('agent#select-version')}
              inputProps={{ 'aria-label': 'A' }}
            />
            <Label className="p-0" for="ksVersionLabel">{t('agent#kre-location')}</Label>
            <TooltipComponent text={`${t('agent#ks-location-rationale')} ${t('agent#ks-location-example')}`} />
          </div>
          <Input
            required={!isSelectKsVersion}
            id="ksLocation"
            name="ksLocation"
            value={ksLocation}
            onChange={handleKsLocationChange}
            type="text"
            disabled={isSelectKsVersion}
          />
        </FormGroup>}
      </div>
    </FormGroup>
  );
}

KreVersionSelect.propTypes = {
  value: PropTypes.shape({
    ksVersion: PropTypes.string,
  }),
};

export default KreVersionSelect;
