import React from 'react';
import DataLoader from '../../../components/table/DataLoader';
import { t } from '../../../i18n/t';

const Gallery = React.forwardRef(({ entityType, renderImage, renderTools, convertData, ...props }, dataRef) => {

  if (!dataRef) {
    dataRef = React.useRef(null);
  }

  const renderGallery = (data) => {
    const images = convertData(data) || [];
    if (images.length === 0) {
      return <div className="text-center">{t('table#empty-message')}</div>;
    }
    return (
      <>
        {
          renderTools &&
          <div className="mb-4">
            {renderTools()}
          </div>
        }
        <div className="row">
          {images.map((image, index) => renderImage(image, index))}
        </div>
      </>
    );
  };

  const renderData = (data) => {
    if (dataRef.current && dataRef.current.isLoading && data.length <= 0) {
      return <div className="text-center">{t('table#loading-message')}</div>;
    } else if (data.length > 0) {
      return renderGallery(data);
    } else {
      return <div className="text-center">{t('table#empty-message')}</div>;
    }
  };

  return (
    <DataLoader
      ref={dataRef}
      entityType={entityType}
      render={renderData}
      {...props}
    />
  );
});


export default Gallery;
