import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { isEmpty, throttle, trim, head, List } from 'lodash';
import Button from '@katalon-studio/katalon-ui/Button';
import TextField from '@katalon-studio/katalon-ui/TextField';
import { Dialog, DialogBody, DialogFooter, DialogHeader } from '@katalon-studio/katalon-ui/Dialog';
import AlertComponent from '../../AlertComponent';
import { t } from '../../../i18n/t';
import { buildSearchCondition } from '../../search/SearchUtils';
import Services from '../../../utils/Services';
import ActionsHelper from '../../../utils/table/ActionsHelper';
import { ACTIONS_TRACKING, DISPLAYED_PAGE_TRACKING, KATALON_EVENTS } from '../../../utils/Constants';
import TestCaseService from '../service/TestCaseService';
import { TestEntity } from '../../../models/model/TestEntity';
import InfoIcon from '../../../../images/icons/katalonui/InfoIcon';
import { DomEventHandlers } from '../../../utils/EventHandler';
import { TestCase } from '../../../models/model/TestCase';
import { TestSuite } from '../../../models/model/TestSuite';
import NotificationHandler from '../handler/NotificationHandler';
import testSuiteService from '../service/TestSuiteService';
import MFlags from '../../../models/MFlags';

const MAX_LENGTH = 255;

const checkNotStartWithCharAndNumRegex = /^[^a-zA-z0-9]/i;
const checkSpecialCharacterRegex = /[^a-zA-Z0-9( ).,_-]/i;

function isNotStartingWithCharAndNumber(text: string) {
  return checkNotStartWithCharAndNumRegex.test(text);
}

function isContainingSpecialChar(text: string) {
  return checkSpecialCharacterRegex.test(text);
}

function isExceedingMaxLength(text: string) {
  return text.length > MAX_LENGTH;
}

function isInvalidDot(text: string) {
  return text.includes('..') || text.endsWith('.');
}

function RenameDialog() {
  const [name, setName] = useState('');
  const [isDuplicated, setDuplicated] = useState(false);
  const [testEntity, setTestEntity] = useState<TestEntity | undefined>(undefined);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    DomEventHandlers.eventListener(KATALON_EVENTS.openRenameDialog, (e: any) => {
      setTestEntity(e.detail.testEntity as TestEntity);
      setName(e.detail.testEntity?.testCase?.name || e.detail.testEntity?.testSuite?.name || '');
      setIsOpen(true);
    });
  }, []);

  const validateNameAndPath = () => {
    if (!testEntity) {
      return;
    }

    const entityData: TestCase | TestSuite | undefined = testEntity.testCase || testEntity.testSuite;
    if (!entityData || !entityData.testProject) {
      return;
    }

    const { id, testProject, path } = entityData;
    const entityType = ActionsHelper.getEntityType(testEntity);
    if (testProject?.id && path) {
      const params = {
        pagination: {
          page: 0,
          size: 1,
          sorts: ['id,desc']
        },
        conditions: [
          buildSearchCondition('name', '=', trim(name)),
          buildSearchCondition('path', '=', trim(path)),
          buildSearchCondition('TestProject.id', '=', testProject?.id),
          buildSearchCondition('id', '!=', id),
        ],
        type: entityType,
      };
      Services.search(params)
        .then(({ content }) => setDuplicated(!isEmpty(content)))
        .catch(() => setDuplicated(false));
    } else {
      setDuplicated(false);
    }
  };

  const throttledGetByNameAndTestProjectId = throttle(validateNameAndPath, 1000);

  useEffect(() => {
    throttledGetByNameAndTestProjectId();
  }, [name]);

  const handleClose = () => {
    setName('');
    setDuplicated(false);
    setTestEntity(undefined);
    setIsOpen(false);
  };

  const handleRename = (e: any) => {
    e.preventDefault();
    if (!testEntity) {
      return;
    }
    const { testCase, testSuite } = testEntity ?? {};
    if (testCase) {
      ActionsHelper.syncTrackingData(
        testEntity,
        ACTIONS_TRACKING.TEST_CASE_RENAMED,
        DISPLAYED_PAGE_TRACKING.TES_CASE_TABLE
      );
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const renameTestCaseErrorHandler = (message: string, label: string, jqXHR: any) => {
        NotificationHandler.renameTestCasesErrorHandler(message, jqXHR, testCase);
      };
      TestCaseService.rename(testCase, name, renameTestCaseErrorHandler).then(() => {
        handleClose();
      });
    } else if (testSuite) {
      ActionsHelper.syncTrackingData(
        testEntity,
        ACTIONS_TRACKING.TEST_SUITE_RENAMED,
        DISPLAYED_PAGE_TRACKING.TEST_SUITE_TABLE
      );
      const renameTestSuiteErrorHandler = (message: string, jqXHR: any) => {
        NotificationHandler.renameTestSuiteErrorHandler(message, jqXHR, testSuite);
      };
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      testSuiteService.rename(testSuite, name, renameTestSuiteErrorHandler).then(() => {
        handleClose();
      });
    }
  };

  const errorList = useMemo(() => {
    const list = [];
    const trimName = name.trim();
    if (!trimName) {
      list.push('test_case_name#error_empty');
    }
    if (isNotStartingWithCharAndNumber(name)) {
      list.push('test_case_name#error_start_with_special_char');
    }
    if (isContainingSpecialChar(trimName)) {
      list.push('test_case_name#error_contain_special_char');
    }
    if (isExceedingMaxLength(trimName)) {
      list.push('test_case_name#error_max_length');
    }
    if (isDuplicated) {
      const { testCase, testSuite } = testEntity ?? {};
      let duplicatedError = '';
      if (testCase) {
        duplicatedError = t('test-case#duplicated');
      } else if (testSuite) {
        duplicatedError = t('test-suite#duplicated');
      }
      list.push(duplicatedError);
    }
    if (isInvalidDot(trimName)) {
      list.push('nameInvalidDotError');
    }
    return list;
  }, [name, isDuplicated]);

  const renderErrorList = useCallback((list: List<any> | null | undefined) => t(head(list)), []);

  if (!testEntity) {
    return null;
  }

  const entityData: TestCase | TestSuite | undefined = testEntity.testCase || testEntity.testSuite;
  if (!entityData) {
    return null;
  }

  const isValidTestEntityName = errorList.length === 0;
  const disabled = trim(name) === entityData.name || !isValidTestEntityName;
  return (
    <Dialog
      open={isOpen}
      maxWidth="xs"
    >
      <DialogHeader onClick={handleClose}>
        {t('rename-test-case-dialog#title')}
      </DialogHeader>
      <DialogBody
        className="d-flex flex-column"
        sx={{
          rowGap: '0.2rem',
        }}
      >
        <TextField
          autoFocus
          error={!isValidTestEntityName}
          helperText={!isValidTestEntityName && renderErrorList(errorList)}
          label={testEntity.testCase ? t('rename-test-case-dialog#test-case-name') : t('rename-test-suite-dialog#test-suite-name')}
          value={name}
          size="small"
          variant="outlined"
          fullWidth
          onChange={(event) => setName(event.target.value)}
        />
        {!MFlags.removeDraftTestCasePublishTermInCloudStudioAlertMessagesEnabled && testEntity.testCase && <AlertComponent
          text={t('rename-test-case-dialog#info-message')}
          icon={<InfoIcon />}
          className="info"
        />}
      </DialogBody>
      <DialogFooter>
        <Button
          size="medium"
          variant="text"
          color="primary"
          onClick={handleClose}
        >
          {t('rename-test-case-dialog#cancel-button')}
        </Button>
        <Button
          size="medium"
          variant="contained"
          color="primary"
          onClick={handleRename}
          disabled={disabled}
        >
          {t('rename-test-case-dialog#rename-button')}
        </Button>
      </DialogFooter>
    </Dialog>
  );
}

export default RenameDialog;
