import React from 'react';
import PropTypes from 'prop-types';
import { groupBy, uniqWith, isEqual, map } from 'lodash';
import DataLoader from '../../components/table/DataLoader';
import { buildSearchFunction } from '../search/SearchUtils';
import {
  TestRunDailyGroupByOptions,
} from '../../utils/Constants';
import Time from '../../utils/Moment';
import { DefectChartPayload } from '../../utils/ChartHelper';
import HorizontalLineChart from '../chart/HorizontalLineChart';
import DefectChartSummary from '../DefectChartSummary';
import { t } from '../../i18n/t';
import { IconDefectCreated, IconDefectOpen, IconDefectResolved } from '../../images/CustomIcon';
import DateHelper from '../../utils/DateHelper';

const DefectChart = ({ dateRange, timeInterval, urlSearchValue, ...props }) => {

  const startDate = DateHelper.getStartDate(dateRange);

  const endDate = DateHelper.getNormalizeEndDate(dateRange);

  const convertDataForRender = (data) => {
    // extract data
    const convertedData = uniqWith(map(data, 'content'), isEqual);

    // group data by time
    const groupByIssueCreatedTime = groupBy(convertedData, 'issueCreatedTime') || [];

    const groupByResolvedTime = groupBy(convertedData, 'resolvedAt') || [];
    // Convert startTime from filter to get the first day by unit(day, week, month)
    const startTimeByUnit = Time.startOf(startDate, timeInterval);
    const timeRange = Time.timeBetween(startTimeByUnit, endDate, `${timeInterval}`, Time.LOCAL_DATE_FORMAT);

    let prevTotalCreated = 0;
    const convertByIssueCreatedTime = timeRange.map((time) => {
      const newValue = {
        time,
        CREATED: 0,
        RESOLVED: 0,
      };
      if (groupByIssueCreatedTime[time]) {
        groupByIssueCreatedTime[time].forEach((value) => {
          newValue.CREATED += value.id;
        });
      }
      newValue.CREATED += prevTotalCreated;
      // Get accumulative data
      prevTotalCreated = newValue.CREATED;
      return newValue;
    });


    let prevTotalResolved = 0;
    return convertByIssueCreatedTime.map((newValue) => {
      if (groupByResolvedTime[newValue.time]) {
        groupByResolvedTime[newValue.time].forEach((value) => {
          newValue.RESOLVED += value.id;
        });
      }
      newValue.RESOLVED += prevTotalResolved;
      // Get accumulative data
      prevTotalResolved = newValue.RESOLVED;
      return newValue;
    });
  };

  const renderSummary = (summaryData) => (
    <div className="defect-chart-summary-box">
      {summaryData.map((summary) => (<DefectChartSummary summary={summary} />))}
    </div>);

  const renderChart = (convertedData) => (
    <HorizontalLineChart
      verticalLabel={t('number_of_defects')}
      customClassName="defect-chart"
      aspect={5}
      legendSize={12}
      legendVerticalPosition="top"
      data={convertedData}
      payload={DefectChartPayload}
    />
  );

  const render = (data) => {
    const convertedData = convertDataForRender(data);
    const lastDay = convertedData[convertedData.length - 1];
    const totalCreatedIssue = lastDay.CREATED;
    const totalResolvedIssue = lastDay.RESOLVED;
    const totalOpenIssue = totalCreatedIssue - totalResolvedIssue;
    const summaryData = [
      {
        totalIssues: totalCreatedIssue,
        icon: <IconDefectCreated />,
        title: t('defect-chart-summary#type-created'),
        tooltipContent: t('defect-chart-summary#tooltip-content-created'),
        iconStyle: 'created-icon',
      },
      {
        totalIssues: totalResolvedIssue,
        icon: <IconDefectResolved />,
        title: t('defect-chart-summary#type-resolved'),
        tooltipContent: t('defect-chart-summary#tooltip-content-resolved'),
        iconStyle: 'resolved-icon',
      },
      {
        totalIssues: totalOpenIssue,
        icon: <IconDefectOpen />,
        title: t('defect-chart-summary#type-open'),
        tooltipContent: t('defect-chart-summary#tooltip-content-open'),
        iconStyle: 'open-icon',
      },
    ];
    return (
      <>
        {renderSummary(summaryData)}
        {renderChart(convertedData)}
      </>
    );
  };

  const onFilterChange = (filterStr) => {
    if (props.handleFilterChange) {
      props.handleFilterChange(filterStr);
    }
  };

  return (
    <DataLoader
      onSearchQueryChange={onFilterChange}
      useSearchQuery
      pageSize={300}
      fetchAllPages
      defaultSearchFunctions={[
        buildSearchFunction('id', 'count_distinct', ['id']),
        buildSearchFunction('issueCreatedTime', `group_by_${timeInterval}`, ['issueCreated']),
        buildSearchFunction('resolvedAt', `group_by_${timeInterval}`, ['resolvedAt']),
      ]}
      render={render}
      defaultSearchString={urlSearchValue}
      {...props}
    />
  );
};

DefectChart.propTypes = {
  handleFilterChange: PropTypes.func,
  timeInterval: PropTypes.string,
  urlSearchValue: PropTypes.string,
  dateRange: PropTypes.array,
};

DefectChart.defaultProps = {
  handleFilterChange: null,
  timeInterval: TestRunDailyGroupByOptions.DAILY.value,
  urlSearchValue: '',
  dateRange: [],
};

export default DefectChart;
