import React, { Component } from 'react';
import { Bar, ComposedChart, ResponsiveContainer, Tooltip, XAxis, LabelList } from 'recharts';
import colors from '../../../../scss/colors.scss';
import { t } from '../../../i18n/t';
import Time from '../../../utils/Moment';

const RotatedAxisTick = ({ x, y, payload }) => {
  const date = Time.format(payload.value, Time.DATE_OF_WEEK_FORMAT, true);
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-35)">{date}</text>
    </g>
  );
};

class DailyExecutionChart extends Component {
  timeFormatter(value) {
    return Time.format(value, Time.LAST_MONTHS_FORMAT, true);
  }

  tooltipFormatter(value, name) {
    if (name === 'totalDuration') return [Time.duration(value), t(name)];
    else return [value, t('total')];
  }

  renderLabel({ value }) {
    if (value !== 0) {
      return value;
    }
    return null;
  }

  render() {
    const { data, dataKey } = this.props;
    return (
      <ResponsiveContainer width="99%" minWidth={200} aspect={4}>
        <ComposedChart margin={{ top: 20, right: 0, bottom: 25, left: 0 }} data={data}>
          <XAxis
            tickFormatter={this.timeFormatter}
            dataKey="keyDate"
            tick={<RotatedAxisTick />}
            interval={0}
          />
          <Tooltip labelFormatter={this.timeFormatter} formatter={this.tooltipFormatter} />
          <Bar yAxisId={dataKey} barSize={15} stackId={dataKey} dataKey={dataKey} fill={colors.blue}>
            <LabelList dataKey={dataKey} position="top" content={this.renderLabel} />
          </Bar>
        </ComposedChart>
      </ResponsiveContainer>
    );
  }
}

export default DailyExecutionChart;
