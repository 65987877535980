import React, { useState } from 'react';
import {
  Tab,
  Tabs,
  colors,
  DialogContent,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import TabPanel from '../../components/tab/TabPanel';
import { t } from '../../i18n/t';
import ExpandIcon from '../../../images/icons/katalonui/ExpandIcon';
import CloseableDialogComponent from './CloseableDialogComponent';

interface ErrorDetailsSectionProps {
  errorDetail?: string;
  errorMessage?: string;
}

const useStyles = makeStyles(() => ({
  tabs: {
    '& .MuiButtonBase-root': {
      minHeight: '2rem',
      height: '2.5rem',
      marginBottom: '0.4rem',
      padding: '0.357rem 0.571rem',
      fontSize: '0.857rem',
      borderRadius: '0.214rem',
    },
    '& .MuiTab-textColorPrimary.Mui-selected': {
      backgroundColor: '#fff'
    },
    '& .MuiTabs-indicator': {
      backgroundColor: 'rgba(34, 40, 60, 0.04)'
    },
    borderBottom: '#d6dae4 1px solid',
    minHeight: '2.5rem',
    alignItems: 'center',
  },
}));

function ErrorDetailsSection(props: Readonly<ErrorDetailsSectionProps>) {

  const {
    errorDetail,
    errorMessage,
  } = props;

  const classes = useStyles();

  const [tabIndex, setTabIndex] = useState(0);
  const [open, setOpen] = useState(false);

  const handleOpenDialog = () => {
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const handleChangeTab = (_event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };
  const isErrorMessageTab = tabIndex === 0;

  const renderContent = (text?: string) => {
    if (text) {
      return (
        <pre className="text-failed-color">
          {text}
        </pre>
      );
    }
    return <div className="text-center mt-4 custom-fields__empty-message">{t('table#empty-message')} </div>;
  };

  const renderFullScreenDialog = () => (
    <CloseableDialogComponent
      maxWidth="md"
      isOpen={open}
      handleClose={handleClose}
      title={isErrorMessageTab ? t('error-message-details') : t('stack-trace-details')}
    >
      <DialogContent className="ai-block-dialog">
        <div className="report-defect-error-block">
          {renderContent(isErrorMessageTab ? errorMessage : errorDetail)}
        </div>
      </DialogContent>
    </CloseableDialogComponent>
  );

  return (
    <>
      <div className="report-defect-section-title mb-1"> {t('error-details')} </div>
      <div className="report-defect-error-block">
        <Tabs
          className={classes.tabs}
          value={tabIndex}
          onChange={handleChangeTab}
        >
          <Tab label={t('error-message')} />
          <Tab label={t('stack-trace')} />
          <div className="report-defect-expand-button" onClick={handleOpenDialog} onKeyDown={handleOpenDialog}>
            <ExpandIcon color={colors.common.black} />
          </div>
        </Tabs>
        <TabPanel className="report-defect-error-box" value={tabIndex} index={0}>
          {renderContent(errorMessage)}
        </TabPanel>
        <TabPanel className="report-defect-error-box" value={tabIndex} index={1}>
          {renderContent(errorDetail)}
        </TabPanel>
      </div>
      {open && renderFullScreenDialog()}
    </>
  );
}

export default ErrorDetailsSection;
