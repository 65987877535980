import React, { Component } from 'react';
import { Label } from 'reactstrap';
import { Autocomplete } from '@mui/material';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import MContext from '../../../models/MContext';
import { buildSearchCondition } from '../../search/SearchUtils';
import Services from '../../../utils/Services';
import DecoratorConstants from '../../../utils/DecoratorConstants';
import TextField from '../../TextField';

class UserFilterV2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userTeams: [],
      user: null,
      loading: false,
    };

    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.buildFilter = this.buildFilter.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { value } = nextProps;
    const { user } = prevState;

    /*
     * When user click button "Clear" in component SearchQuery then value will be null.
     * I check the user if there is a value then set it to null to clear data.
     */
    if (!!user && !value) {
      return {
        user: null
      };
    }
    return prevState;
  }

  componentDidMount() {
    this.getUserInTeam();
  }

  getUserInTeam() {
    const { value } = this.props;
    const team = MContext.team;
    this.setState({ loading: true });

    if (team.organization) {
      const params = {
        pagination: {
          page: 0,
          size: 300,
          sorts: 'id,asc',
        },
        conditions: [
          buildSearchCondition('Team.id', '=', MContext.teamId)
        ],
        type: 'UserTeam',
        ignoreAutoFill: true,
      };
      Services.search(params)
        .then(({ content }) => {
          const user = content.find(({ id }) => id === Number(value));
          this.setState({
            userTeams: content,
            user,
            loading: false,
          });
        });
    }
  }

  handleSelectChange(event, option) {
    this.setState({
      user: option
    }, this.buildFilter(get(option, 'id')));
  }

  buildFilter(value) {
    const { id, onBuildFilterSearch } = this.props;
    if (onBuildFilterSearch) {
      onBuildFilterSearch(id, value);
    }
  }

  render() {
    const { userTeams, user, loading } = this.state;
    const { label, placeholder } = this.props;

    return (
      <div>
        <Label>{label}</Label>
        <Autocomplete
          id="user"
          value={user}
          options={userTeams}
          getOptionLabel={(option) => option.fullName}
          renderOption={(props, option) => (
            <li {...props}>
              {DecoratorConstants.renderUserDecorator(option)}
            </li>
          )}
          renderInput={(params) =>
            <TextField
              {...params}
              placeholder={placeholder}
            />}
          onChange={this.handleSelectChange}
          loading={loading}
        />
      </div>
    );
  }
}

UserFilterV2.propTypes = {
  onBuildFilterSearch: PropTypes.func,
  value: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  id: PropTypes.string,
};

UserFilterV2.defaultProps = {
  value: '',
  label: '',
  placeholder: '',
  id: '',
};

export default UserFilterV2;
