import React, { useState, useEffect } from 'react';
import { groupBy, map } from 'lodash';
import { buildSearchFunction } from '../../../components/search/SearchUtils';
import MTableColumnDataMapping from '../../../components/table/models/MTableColumnDataMapping';
import { t } from '../../../i18n/t';
import { AmountReport, ProjectStatisticsType, SearchEntity, REPORT_PAGE_SIZE, PlatformPlan } from '../../../utils/Constants';
import DecoratorConstants from '../../../utils/DecoratorConstants';
import { getEndTime, getStartTime } from '../utils';
import TestPerformanceChart from './TestPerformanceChart';
import TimeSelect from './TimeSelect';
import { ViewModeType } from './utils';
import ViewMode from './ViewMode';
import Time from '../../../utils/Moment';
import DataLoader from '../../../components/table/DataLoader';
import TableCore from '../../../components/table/models/TableCore';
import {
  buildFilter
} from '../../../components/search-query/FilterQueryHelper';
import TestSuiteFilter from '../../../components/search-query/filter/TestSuiteFilter';
import TestSuiteCollectionFilter from '../../../components/search-query/filter/TestSuiteCollectionFilter';
import InputFilter from '../../../components/search-query/filter/InputFilter';
import TableLoading from '../../../components/table/TableLoading';
import { DummyUpgradeCard } from '../../../pages/test_reports/components/DummyUpgradeCard';
import MContext from '../../../models/MContext';


const getDateUnitFromStatisticsType = (projectStatisticsType) => {
  if (projectStatisticsType === ProjectStatisticsType.MONTHLY) {
    return 'month';
  }
  if (projectStatisticsType === ProjectStatisticsType.WEEKLY) {
    return 'week';
  }
  return 'day';
};

const getAmount = (filterType) => {
  if (filterType === ProjectStatisticsType.MONTHLY) {
    return AmountReport.MONTHLY;
  }
  if (filterType === ProjectStatisticsType.WEEKLY) {
    return AmountReport.WEEKLY;
  }
  return AmountReport.DAILY;
};

const TestPerformance = ({ defaultPageSize = AmountReport.WEEKLY }) => {
  const [groupByTime, setGroupByTime] = useState(ProjectStatisticsType.WEEKLY);
  const [from, setFrom] = useState(getStartTime(defaultPageSize, getDateUnitFromStatisticsType(groupByTime)));
  const [to, setTo] = useState(getEndTime(getDateUnitFromStatisticsType(groupByTime)));
  const [viewMode, setViewMode] = useState(ViewModeType.RATIO);
  const [unit, setUnit] = useState(getDateUnitFromStatisticsType(groupByTime));
  const [groupByCondition, setGroupByCondition] = useState(`group_by_${unit}`);


  useEffect(() => {
    setFrom(getStartTime(getAmount(groupByTime), getDateUnitFromStatisticsType(groupByTime)));
    setTo(getEndTime(getDateUnitFromStatisticsType(groupByTime)));
    setUnit(getDateUnitFromStatisticsType(groupByTime));
    setGroupByCondition(`group_by_${getDateUnitFromStatisticsType(groupByTime)}`);
  }, [groupByTime]);

  const parseAggregationData = (aggData) => {
    return aggData.map((item) => item.content);
  }

  const customData = (data) => data.map((item) => {
    const val = {
      ...item,
      startTime: item.time,
      endTime: Time.endOf(item.time, unit).format(),
      passedTestCase: 0,
    };
    val.passedTestCase = val.totalTestCase - val.failedTestCase;
    return val;
  });

  const groupByTimeFilter = (data) => {
    const parsedData = parseAggregationData(data);
    const refactorData = customData(parsedData);
    const groupByData = groupBy(refactorData, 'startTime');
    const times = Time.timeBetween(from, to, `${unit}s`, Time.LOCAL_DATE_FORMAT);

    const resultData = map(times, (time) => {
      const val = {
        startTime: time,
        endTime: Time.endOf(time, unit).format(),
        errorTestResult: 0,
        failedTestResult: 0,
        incompleteTestResult: 0,
        skippedTestResult: 0,
        passedTestResult: 0,
        totalDuration: 0,
        totalTestResult: 0,
        totalTestCase: 0,
        failedTestCase: 0,
        passedTestCase: 0
      };
      if (groupByData[time]) {
        return groupByData[time][0];
      }
      return val;
    });
    return resultData;
  };

  const convertDataForRender = (data) => {
    const dataByTimeFilter = groupByTimeFilter(data);

    return dataByTimeFilter.map((item) => {
      const val = {
        ...item,
        passedTestResultRatio: 0,
        failedTestResultRatio: 0,
        errorTestResultRatio: 0,
        incompleteTestResultRatio: 0,
        skippedTestResultRatio: 0,
        passedTestCaseRatio: 0,
        failedTestCaseRatio: 0,
      };
      val.passedTestResultRatio = 0;
      if (val.totalTestResult) {
        val.passedTestResultRatio = val.passedTestResult / val.totalTestResult;
        val.failedTestResultRatio = val.failedTestResult / val.totalTestResult;
        val.errorTestResultRatio = val.errorTestResult / val.totalTestResult;
        val.incompleteTestResultRatio = val.incompleteTestResult / val.totalTestResult;
        val.skippedTestResultRatio = val.skippedTestResult / val.totalTestResult;
      }
      if (val.totalTestCase) {
        val.passedTestCaseRatio = val.passedTestCase / val.totalTestCase;
        val.failedTestCaseRatio = val.failedTestCase / val.totalTestCase;
      }
      return val;
    });
  };

  const renderChart = (data) => {
    return <TestPerformanceChart data={data} viewMode={viewMode} />;
  }

  const renderCustomControl = () => (
    <div className="row d-flex align-items-center">
      <div className="col-6 d-flex justify-content-end">
        <ViewMode onChange={setViewMode} defaultViewMode={viewMode} />
      </div>
      <div className="col-6">
        <TimeSelect onSelectChange={setGroupByTime} defaultValue={groupByTime} />
      </div>
    </div>
  );

  const renderCustomLoading = () => (
    <TableLoading message={t('report#prepare-message')} />
  );

  const detailsColumnMapping = [
    new MTableColumnDataMapping(
      t('from'),
      'startTime',
      DecoratorConstants.timeDecorator,
    ),
    new MTableColumnDataMapping(
      t('to'),
      'endTime',
      DecoratorConstants.timeDecorator,
    ),
    new MTableColumnDataMapping(
      t('totalTestResult'),
      'totalTestResult',
      DecoratorConstants.totalTestRunDecorator,
    ),
    new MTableColumnDataMapping(
      t('passedTestResult'),
      'passedTestResult',
      DecoratorConstants.passedCountDecorator,
    ),
    new MTableColumnDataMapping(
      t('failedTestResult'),
      'failedTestResult',
      DecoratorConstants.failedCountDecorator,
    ),
    new MTableColumnDataMapping(
      t('errorTestResult'),
      'errorTestResult',
      DecoratorConstants.errorCountDecorator,
    ),
    new MTableColumnDataMapping(
      t('incompleteTestResult'),
      'incompleteTestResult',
      DecoratorConstants.incompleteCountDecorator,
    ),
    new MTableColumnDataMapping(
      t('skippedTestResult'),
      'skippedTestResult',
      DecoratorConstants.skippedCountDecorator,
    ),
    new MTableColumnDataMapping(
      t('totalTestCase'),
      'totalTestCase',
      DecoratorConstants.totalTestCaseDecorator,
    ),
    new MTableColumnDataMapping(
      t('passedTestCase'),
      'passedTestCase',
      DecoratorConstants.passedCountDecorator,
    ),
    new MTableColumnDataMapping(
      t('failedTestCase'),
      'failedTestCase',
      DecoratorConstants.failedCountDecorator,
    ),
    new MTableColumnDataMapping(
      t('totalDuration'),
      'totalDuration',
      DecoratorConstants.durationDecorator,
    ),
  ];

  const renderTable = (data) => {
    const reverseData = [...data].reverse();
    return (
      <div className="data-table-with-chart">
        <TableCore
          data={reverseData}
          columnMapping={detailsColumnMapping}
        />
      </div>
    );
  };

  const renderData = (data) => {
    const convertedData = convertDataForRender(data);
    return (
      <>
        {renderChart(convertedData)}
        {renderTable(convertedData)}
      </>
    );
  };

  const filterQuery = [
    buildFilter(InputFilter, { id: 'profile', label: 'Profile', operator: '~' }),
    buildFilter(TestSuiteFilter, { id: 'TestSuite.name' }),
    buildFilter(TestSuiteCollectionFilter, { id: 'TestSuiteCollectionEntity.name' }),
  ];

  const newProps = {
    filterQuery,
  };

  const renderRequireUpgrade = () => {
    return (
      <DummyUpgradeCard
        title={t('test-performance')}
        customControl={renderCustomControl()}
        useCache={true}
        requiredSubscription={PlatformPlan.KATALON_PREMIUM}
        isUsePlatformMessage
      />
    );
  }

  const isRequireUpgrade = () => {
    return (groupByTime === ProjectStatisticsType.MONTHLY) && MContext.isRequireUpgrade;
  }

  const renderReport = () => {
    return (
      <DataLoader
      title={t('test-performance')}
      key={`${groupByTime}_test_performance`}
      entityType={SearchEntity.ExecutionTestResult}
      defaultSearchFunctions={[
        buildSearchFunction('time', groupByCondition, ['startTime']),
        // test result
        buildSearchFunction('totalTestResult', 'count', ['id']),
        buildSearchFunction('passedTestResult', 'sum', ['case when a.status = 0 then 1 else 0 end']),
        buildSearchFunction('failedTestResult', 'sum', ['case when a.status = 1 then 1 else 0 end']),
        buildSearchFunction('errorTestResult', 'sum', ['case when a.status = 2 then 1 else 0 end']),
        buildSearchFunction('incompleteTestResult', 'sum', ['case when a.status = 3 then 1 else 0 end']),
        buildSearchFunction('skippedTestResult', 'sum', ['case when a.status = 5 then 1 else 0 end']),
        // test case
        buildSearchFunction('totalTestCase', 'count_distinct', ['testCaseId']),
        buildSearchFunction('failedTestCase', 'count_distinct',
          ['case when a.status != 0 and a.status != 5 then a.testCaseId else null end']),
        // duration
        buildSearchFunction('totalDuration', 'SUM', ['duration']),
      ]}
      defaultSort={['time,desc']}
      render={renderData}
      pageSize={REPORT_PAGE_SIZE}
      hidePaging
      customControl={renderCustomControl()}
      useSearchQuery
      {...newProps}
      customLoading={renderCustomLoading()}
      useCache
      errorMessage={t('report#cannot-parse')}
    />
    );
  }

  return (
    <>
    {isRequireUpgrade() ? renderRequireUpgrade() : renderReport()}
    </>
  );
};

export default TestPerformance;
