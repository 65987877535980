import React, { Component } from 'react';
import _ from 'lodash';
import { Card, CardBody, CardHeader, Input, Row, Col, CardFooter } from 'reactstrap';
import parse from 'url-parse';
import DefaultLayout from '../../../components/DefaultLayout';
import ObjectSummary from '../../../components/summary/ObjectSummary';
import { t } from '../../../i18n/t';
import Services from '../../../utils/Services';
import DecoratorConstants from '../../../utils/DecoratorConstants';
import Routes from '../../../utils/Routes';
import CheckoutCard from './components/CheckoutCard';
import ProductLogo from '../../../images/ProductLogo';
import MAuth from '../../../models/MAuth';
import Notification from '../../../utils/Notification';
import { PRODUCT_ORDER } from './Subscriptions';

class CheckoutQuote extends Component {

  constructor(props) {
    super(props);

    this.renderBody = this.renderBody.bind(this);
    this.renderHeader = this.renderHeader.bind(this);
    this.getQuote = this.getQuote.bind(this);
    this.blockUI = this.blockUI.bind(this);
    this.unBlockUI = this.unBlockUI.bind(this);
    this.checkout = this.checkout.bind(this);

    const url = parse(window.location.href, true);
    this.uuid = url.query.token;

    this.state = {
      orders: [],
      total: 0,
      paymentMethod: null,
      billingInformation: null,
      isBlocked: false,
      organizationId: null,
    };
  }

  componentDidMount() {
    this.getQuote();
  }

  blockUI() {
    this.setState({
      isBlocked: true
    });
  }

  unBlockUI() {
    this.setState({
      isBlocked: false
    });
  }

  getQuote() {
    Services.getQuote(this.uuid)
      .then(({ quote, billingInformation, paymentMethod }) => {
        const organizationId = quote.organization.id;
        if (paymentMethod) {
          if (!MAuth.isLoggedIn) {
            MAuth.logout();
            return;
          }
          if (!MAuth.isBillingManager(organizationId)) {
            Notification.pushError('You don\'t have permission to manage billing for this organization.');
            return;
          }
        }
        const { orders, invoices } = quote;
        let total = 0;
        invoices.forEach((invoice) => {
          const order = orders.find((o) => o.enterprisePlan.planId === invoice.planId);
          if (order) {
            order.invoice = invoice;
            total += invoice.total;
          }
        });
        this.setState({ orders, total, billingInformation, paymentMethod, organizationId });
      });
  }

  renderOrderRow(order) {
    const { price, quota, enterprisePlan: plan, invoice, coupon } = order;
    const { enterpriseProduct: product } = plan;
    return (
      <Card key={order.enterprisePlan.planId} className="product-card new-order-row">
        <CardHeader><ProductLogo productId={product.productId} />{product.name}</CardHeader>
        <CardBody>
          <Row>
            <Col>
              <div className="d-flex mb-2 product-card__label">
                {DecoratorConstants.billingIntervalDecorator('interval', plan)} subscription
              </div>
              <div>
                <span className="product-card__price">
                  {`$ ${(price || 0).toLocaleString()}`}
                </span>
                <span>/License</span>
              </div>
            </Col>
            <Col>
              <div className="product-card__label">No. of users</div>
              <Input
                required
                className="new-order-row__number-users mt-2"
                type="number"
                min={0}
                value={quota}
                disabled
              />
            </Col>
            <Col>
              <div className="product-card__label">Tax (USD)</div>
              <div className="product-card__sub-total">{`$ ${(invoice.tax || 0).toLocaleString()}`}</div>
            </Col>
            <Col>
              <div className="product-card__label">Subtotal (USD)</div>
              <div className="product-card__sub-total">{`$ ${(invoice.total || 0).toLocaleString()}`}</div>
            </Col>
          </Row>
        </CardBody>
        <CardFooter>
          {coupon &&
            <div className="discount-applied mt-2 text-success">
              <span className="discount-applied-coupon">
                Applied discount code: {coupon}
              </span>
            </div>}
        </CardFooter>
      </Card>
    );

  }

  renderProduct() {
    const { orders } = this.state;
    orders.sort((o1, o2) =>
      PRODUCT_ORDER[o1.enterprisePlan.enterpriseProduct.productId] - PRODUCT_ORDER[o2.enterprisePlan.enterpriseProduct.productId]);
    return (
      <Row>
        <Col>
          {orders.map((order) => this.renderOrderRow(order))}
        </Col>
      </Row>
    );
  }

  checkout(token) {
    let cardToken;
    if (token && token.token) {
      cardToken = token.token.id;
    }
    const { organizationId } = this.state;
    this.blockUI();
    Services.checkoutQuote(cardToken, this.uuid)
      .then((invoices) => {
        const invoiceSuccess = [];
        let orderFailedName = null;
        let orderFailedError = null;
        invoices.forEach((invoice) => {
          if (invoice.errorMessage) {
            orderFailedName = invoice.nameProduct;
            orderFailedError = invoice.errorMessage;
          } else {
            invoiceSuccess.push(invoice);
          }
        });
        const invoiceNumbers = invoiceSuccess.map((invoice) => invoice.invoiceNumber);
        if (organizationId && MAuth.isLoggedIn && MAuth.isBillingManager(organizationId)) {
          const route = new Routes({ organizationId });
          if (orderFailedError && orderFailedName) {
            window.location.href = route.organization_payment_success_link({ invoiceNumbers, orderFailedName, orderFailedError });
          } else {
            window.location.href = route.organization_payment_success_link({ invoiceNumbers });
          }
        } else {
          window.location.href = '/checkout-quote/payment-success';
        }
      })
      .catch(() => this.unBlockUI());
  }

  renderCheckout() {
    const { paymentMethod, billingInformation, isBlocked, orders, total, organizationId } = this.state;
    const isAnyProductSelected = orders && Object.values(orders).some((order) => +order.quota);
    if (!_.isEmpty(orders)) {
      return (
        <CheckoutCard
          data-trackid="checkout-quote"
          disabled={isBlocked}
          preventCheckout={!isAnyProductSelected}
          paymentMethod={paymentMethod}
          billingInformation={billingInformation}
          prices={{ total }}
          onCheckout={this.checkout}
          isShowPaymentMethod
          organizationId={organizationId}
        />
      );
    } else return null;

  }

  renderBody() {
    return (
      <Row>
        <Col xs="12" md="8">
          {this.renderProduct()}
        </Col>
        <Col xs="12" md="4">
          {this.renderCheckout()}
        </Col>
      </Row>
    );
  }

  renderHeader() {
    const urlParams = {
      subscription: t('subscriptions'),
    };
    return (
      <ObjectSummary params={urlParams} />
    );
  }

  render() {
    return (
      <div>
        <DefaultLayout
          renderHeader={this.renderHeader}
          renderBody={this.renderBody}
        />
      </div>
    );
  }
}

export default CheckoutQuote;
