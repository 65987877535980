import React, { Component } from 'react';
import { Button, Collapse } from 'reactstrap';
import PropTypes from 'prop-types';
import DataLoader from '../../../components/table/DataLoader';
import Timeline from '../../../components/timeline/Timeline';
import { t } from '../../../i18n/t';
import { IconAngleRight, IconDown } from '../../../images/KitIcons';
import DecoratorConstants from '../../../utils/DecoratorConstants';
import Routes from '../../../utils/Routes';
import { ExecutionDecorator } from '../../execution/ExecutionDecorator';
import { IconGitRepo, IconKSRepo, IconTestSuiteCollectionTable } from '../../../images/CustomIcon';
import ImageProfile from '../../../components/avatar/ImageProfile';
import Link from '../../../components/Link';

class JobTimeline extends Component {
  constructor(props) {
    super(props);
    this.dataRef = React.createRef();
    this.state = {
      expandedJobs: {},
    };
    this.renderData = this.renderData.bind(this);
  }

  toggleExpandExecution(id) {
    this.setState((prevState) => ({
      expandedJobs: {
        ...prevState.expandedJobs,
        [id]: !prevState.expandedJobs[id],
      },
    }));
  }

  renderType(type) {
    switch (type) {
      case 'GIT':
        return (
          <span title={type}>
            <IconGitRepo />
          </span>
        );
      case 'KS':
        return (
          <span title={type}>
            <IconKSRepo />
          </span>
        );
      default:
        return (
          <span title={type}>
            <IconKSRepo />
          </span>
        );
    }
  }

  renderJobDetails(job) {
    const { expandedJobs } = this.state;
    let type = '';
    let agent = '';
    if (job.agent) {
      type = 'localAgent';
      agent = job.agent;
    }
    if (job.k8sAgent) {
      type = 'k8sAgent';
      agent = job.k8sAgent;
    }
    if (job.circleCiAgent) {
      type = 'circleCiAgent';
      agent = job.circleCiAgent;
    }

    const infoItems = [
      {
        label: this.renderType(job.testProject.type),
        text: job.testProject.name,
        title: job.testProject.name,
      },
      {
        label: DecoratorConstants.iconByAgentDecorator(agent, type),
        text: DecoratorConstants.agentDecorator('name', job)
      },
    ];
    return (
      <Collapse isOpen={expandedJobs[job.id]}>
        {infoItems.map((item) => (
          <div className="text-word-wrap" title={item.title}>
            {item.label ? item.label : null} {item.text}
          </div>
        ))}
      </Collapse>
    );
  }

  renderExpandButton(job) {
    const { expandedJobs } = this.state;
    const isExpanded = expandedJobs[job.id];
    return (
      <Button onClick={() => this.toggleExpandExecution(job.id)} color="link">
        {isExpanded ? <IconDown /> : <IconAngleRight />}
      </Button>
    );
  }

  renderOrderAndTime(job) {
    const constructedLink = new Routes({
      runConfigurationId: job.runConfiguration.id,
      jobOrder: job.order,
      projectId: job.project.id,
    });

    return (
      <>
        <div>
          <Link href={constructedLink.job_link}>#{job.order}</Link>
        </div>
        <div className="text-gray">
          {DecoratorConstants.timeDecorator('startTime', job)}
        </div>
        <div className="text-gray">
          {DecoratorConstants.durationDecorator('duration', job)}
        </div>
      </>
    );
  }

  renderContent(job) {
    return (
      <div>
        <div className="label-text text-word-wrap">
          <IconTestSuiteCollectionTable />{' '}
          <span title={job.runConfiguration.name}>{job.runConfiguration.name}</span>
        </div>
        {job.user && (
          <div className="d-flex align-items-center">
            <div className="mr-1">
              <ImageProfile
                imgUrl={job.user.avatar}
                name={job.user.fullName}
                diameter={20}
              />
            </div>
            {job.user.fullName}
          </div>
        )}
      </div>
    );
  }

  renderJobs(jobs) {
    const items = jobs.map((job) => ({
      icon: ExecutionDecorator.statusDecorator('status', job),
      title: this.renderOrderAndTime(job),
      content: this.renderContent(job),
      button: this.renderExpandButton(job),
      details: this.renderJobDetails(job),
    }));
    return <Timeline items={items} />;
  }

  renderData(data) {
    if (data.length > 0) {
      return this.renderJobs(data);
    } else {
      return <div className="text-center">{t('table#empty-message')}</div>;
    }
  }

  render() {
    return (
      <DataLoader
        ref={this.dataRef}
        entityType="Job"
        render={this.renderData}
        hidePaging
        {...this.props}
      />
    );
  }
}

JobTimeline.propTypes = {
  pageSize: PropTypes.number,
  hidePaging: PropTypes.bool,
};

JobTimeline.defaultProps = {
  pageSize: 5,
  hidePaging: false,
};

export default JobTimeline;
