import _ from 'lodash';
import React, { Component } from 'react';
import StatusBarInfoV2 from '../../../components/chart/status-bar/StatusBarInfoV2';
import { buildSearchCondition } from '../../../components/search/SearchUtils';
import DataLoader from '../../../components/table/DataLoader';
import { t } from '../../../i18n/t';
import DailyTestObjectChart from './DailyTestObjectChart';
import Routes from '../../../utils/Routes';
import Time from '../../../utils/Moment';
import SelectBoxTime from './SelectBoxTime';
import StatisticsUtils from './StatisticsUtils';

class TestObjectProjectStatistics extends Component {
  constructor(props) {
    super(props);
    this.dataRef = React.createRef();
    this.state = {
      week: 1,
    };
    this.setWeek = this.setWeek.bind(this);
    this.renderTestObjectData = this.renderTestObjectData.bind(this);
    this.renderDailyTestObjectChart = this.renderDailyTestObjectChart.bind(this);
  }

  renderDailyTestObjectChart(data, from, to) {
    const { dataKey } = this.props;
    const convertedData = StatisticsUtils.fillEmptyDaysInWeek(data, from, to);
    return <DailyTestObjectChart data={convertedData} dataKey={dataKey} />;
  }

  renderDailyTestObject() {
    const { week } = this.state;
    const startOfWeek = StatisticsUtils.startOfWeek(week);
    const endOfWeek = StatisticsUtils.endOfWeek(week);
    return (
      <DataLoader
        key={`daily-test-object-chart-${week}`}
        entityType="ProjectStatistics"
        render={(data) => this.renderDailyTestObjectChart(data, startOfWeek, endOfWeek)}
        defaultSort={['keyDate, asc']}
        title="Project Statistics"
        defaultSearchConditions={[
          buildSearchCondition('statisticsType', '=', 'DAILY'),
          buildSearchCondition('keyDate', '>=', startOfWeek),
          buildSearchCondition('keyDate', '<=', endOfWeek)
        ]}
        hidePaging
        noCard
      />
    );
  }

  renderTestObjectDuration(statistics) {
    const { dataKey } = this.props;
    const data = [{
      key: `total-test-object-${statistics.id}`,
      value: statistics[dataKey],
      label: t('total')
    }];
    if (dataKey === 'totalWebService') {
      data.push({
        key: `avg-duration-${statistics.id}`,
        value: Time.duration(statistics.averageDurationWebService),
        label: 'Average Duration'
      });
    }
    return (
      <div className="d-flex flex-column">
        <StatusBarInfoV2 key={`duration-status-${statistics.id}`} data={data} />
      </div>
    );
  }

  renderTestObjectStatistics(data) {
    const { dataKey } = this.props;
    const statistics = data[0];
    return (
      <>
        {this.renderTestObjectDuration(statistics, dataKey)}
        {this.renderDailyTestObject(statistics, dataKey)}
      </>
    );
  }

  renderStatistics(data) {
    const { dataKey } = this.props;
    return (
      <>
        {
          data.length > 0 && data[0][dataKey] ? this.renderTestObjectStatistics(data) :
            (<div className="text-center">{t('table#empty-message')}</div>)
        }
      </>
    );
  }

  renderTestObjectData(data) {
    const { dataKey } = this.props;
    return this.renderStatistics(data, dataKey);
  }

  setWeek(week) {
    this.setState({ week: week.value });
  }

  renderCustomControl() {
    const { week } = this.state;
    return (
      <SelectBoxTime week={week} setWeek={this.setWeek} />
    );
  }

  render() {
    const { dataKey } = this.props;
    const { week } = this.state;
    const weeklyKey = StatisticsUtils.weeklyKey(week);
    let title = 'Web Services';
    if (dataKey === 'totalTestObject') {
      title = 'Test Objects';
    }
    return (
      <DataLoader
        key={`test-object-statistics-week-${week}`}
        ref={this.dataRef}
        entityType="ProjectStatistics"
        render={this.renderTestObjectData}
        customControl={this.renderCustomControl()}
        defaultSort={['keyDate, desc']}
        title={title}
        defaultSearchConditions={[
          buildSearchCondition('statisticsType', '=', 'WEEKLY'),
          buildSearchCondition('keyDate', '=', weeklyKey),
        ]}
        pageSize={1}
        hidePaging
      />
    );
  }
}

export default TestObjectProjectStatistics;
