import { Button, Card, CardBody, CardHeader } from 'reactstrap';
import React from 'react';
import DocumentLink from '../../../utils/DocumentLink';
import DefaultLayout from '../../../components/DefaultLayout';
import { t } from '../../../i18n/t';
import ObjectSummary from '../../../components/summary/ObjectSummary';
import RouteConstants from '../../../utils/RouteConstants';

export default function CheckoutQuotePaymentSuccess({ isTestOps }) {

  const renderHeader = () => {
    const urlParams = {
      subscription: t('subscriptions'),
    };
    return (
      <ObjectSummary params={urlParams} />
    );
  };

  const packageType = isTestOps ? t('katalon_testops') : t('katalon_studio');

  const renderGoToLoginButton = () =>
    (
      <Button color="primary" href={RouteConstants.login}>
        {t('login-to-testops')}
      </Button>
    );

  const renderBody = () => (
    <Card>
      <CardHeader>
        {t('payment-success#thanks')}
      </CardHeader>
      <CardBody>
        <p>
          {t('payment-success#subscribed-info', { packageType })}<br />
          {t('payment-success#subscribed-invoices')}<br />
          If you do not receive the email, please contact us via <a href="mailto:billing@katalon.com">billing@katalon.com</a><br />
        </p>
        <div>
          {renderGoToLoginButton()}
        </div>
        {!isTestOps &&
          <>
            <br />
            <a href={DocumentLink.USE_ONLINE_LICENSE} target="_blank" rel="noopener noreferrer">
              {t('assign-licenses')}
            </a>
          </>}
      </CardBody>
    </Card>
  );

  return (
    <DefaultLayout
      renderBody={renderBody}
      renderHeader={renderHeader}
    />
  );
}
