import React from 'react';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import TestCloudEnvironment from '../../dialog/TestCloudEnvironment';
import { ALL_BROWSERS_TESTCLOUD_AGENT, BrowserType, ConfigType } from '../../../utils/Constants';


const getAgents = (row) => {
  const agents = row.testCloudAgent ? [row.testCloudAgent] : [];

  if (agents[0]?.os === ALL_BROWSERS_TESTCLOUD_AGENT.os
      && agents[0]?.browser === ALL_BROWSERS_TESTCLOUD_AGENT.browser
      && agents[0]?.browserType !== BrowserType.WEB_SERVICE) {
    return [];
  }

  return agents;
};

export default function TestSuiteDetailPanelContent({
  row,
  testCloudEnv,
  nativeApps,
  handleChangeExecutionEnvironments,
}) {

  return (
    <Box sx={{ pt: 1, pb: 1 }}>
      <TestCloudEnvironment
        testSuiteId={row.id}
        tunnelId={null}
        handleSelectedTunnelChange={null}
        enableApiTesting={false}
        updateEnableApiTesting={null}
        testCloudEnv={testCloudEnv}
        toggleTestCloudTunnelIntegration={null}
        enabledTestCloudTunnel={false}
        apps={nativeApps}
        handleChangeExecutionEnvironments={handleChangeExecutionEnvironments}
        agents={getAgents(row)}
        configType={ConfigType.TSC}
        hideProfileWithTunnel={false}
        hideTabPanel={false}
        requireTestEnv={false}
      />
    </Box>
  );
}

TestSuiteDetailPanelContent.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  }).isRequired,
  testCloudEnv: PropTypes.object.isRequired,
  nativeApps: PropTypes.array.isRequired,
  handleChangeExecutionEnvironments: PropTypes.func.isRequired,
};
