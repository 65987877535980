import React from 'react';
import { Alert } from 'reactstrap';
import Routes from '../../utils/Routes';
import { t } from '../../i18n/t';

function ExternalConnectionAlert() {
  const routes = new Routes();
  const jiraSettingLink = routes.jira_setting_link;
  return (
    <Alert color="warning">
      Please configure Jira integration and try again.{' '}
      <a href={jiraSettingLink} target="_blank" rel="noopener noreferrer">{t('go-to-configuration')}</a>
    </Alert>
  );
}

export default ExternalConnectionAlert;
