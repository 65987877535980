import { FormGroup, FormText, Label } from 'reactstrap';
import React from 'react';
import { Controller } from 'react-hook-form';
import { t } from '../../../i18n/t';
import Input from '../../Input';
import DocumentLink from '../../../utils/DocumentLink';
import ExecutionEnvironmentSelect from './ExecutionEnvironmentSelect';
import { ConfigType } from '../../../utils/Constants';
import MFlags from '../../../models/MFlags';

function KatalonCommand(props) {
  const { testProject, executionEnvironments, validation, form, circleCIAgents, k8sAgents, localAgents } = props;
  const { control } = form;

  const excludeParams = [
    'katalonc',
    '-runMode',
    '-projectPath',
    '-apiKey',
    '-noSplash',
  ];

  return (
    <>
      <FormGroup className="d-flex">
        <Label className="col-2 test-run-label" for="command">{t('run-configuration#katalon-command')}</Label>
        <div>
          <Controller
            name="runConfiguration.command"
            control={control}
            rules={{ required: validation }}
            render={({ field, formState: { errors } }) =>
              <div className="d-flex flex-column flex-grow-1">
                <Input
                  {...field}
                  required
                  id="command"
                  name="command"
                  type="textarea"
                  rows={5}
                />
                {errors.runConfiguration?.command && <small className="text-danger fs-6">This is required</small>}
              </div>}
          />
          <FormText color="muted">
            Please enter your command excluding the following parameters:{' '}
            {excludeParams.map((param) => <><code>{param}</code>{', '}</>)}
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            eg. <code>-retry=0 -executionProfile="default" -browserType="Web Service"</code>
          </FormText>
          <FormText color="muted">
            Learn how to generate command&nbsp;
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={DocumentLink.HOW_TO_GENERATE_COMMAND}
            >here
            </a>.
          </FormText>
        </div>
      </FormGroup>
      <FormGroup className="d-flex">
        <Label className="col-2 test-run-label" for="environment">{t('Environment')}</Label>
        <div className="p-0 col-10">
          <Controller
            name="runConfiguration.commandAgents"
            control={control}
            rules={{ required: validation }}
            render={({ field, formState: { errors } }) =>
              <div className="d-flex flex-column flex-grow-1">
                <ExecutionEnvironmentSelect
                  {...field}
                  testProject={testProject}
                  executionEnvironments={executionEnvironments}
                  configType={ConfigType.COMMAND}
                  circleCIAgents={circleCIAgents}
                  k8sAgents={k8sAgents}
                  localAgents={localAgents}
                  title={t('testcloud-environment#katalon-command')}
                  isShowDropdown={!MFlags.testCloudDisableTCFromScheduleDialog}
                />
                {errors.runConfiguration?.commandAgents && <small className="text-danger fs-6">This is required</small>}
              </div>}
          />
        </div>
      </FormGroup>
    </>
  );
}

export default KatalonCommand;
