import React from 'react';
import { Col, Row } from 'reactstrap';
import DefaultLayout from '../../components/DefaultLayout';
import PageComponent from '../../components/PageComponent';
import ObjectSummary from '../../components/summary/ObjectSummary';
import MContext from '../../models/MContext';
import TestProjectsCard from './components/TestProjectsCard';
import RunningJobsCard from './components/RunningJobsCard';
import JobStatistics from './components/JobStatistics';
import NextRunCard from './components/NextRunCard';
import NumberJobsChart from '../../components/chart/NumberJobsChart';
import { t } from '../../i18n/t';
import TestEnvironmentsCard from './components/TestEnvironmentsCard';
import LatestJob from './components/LatestJob';
import PlansCard from './components/PlansCard';

class CIDashboard extends PageComponent {

  constructor(props) {
    super(props);
    this.meta.id = 'page-project-dashboard';
    this.meta.title = t('ci#dashboard');
    this.teamId = MContext.teamId;
    this.projectId = MContext.projectId;
    this.project = MContext.project;
    this.team = MContext.team;
    this.renderHeader = this.renderHeader.bind(this);
    this.renderBody = this.renderBody.bind(this);
  }

  renderObjectSummary() {
    const team = this.team;
    const urlParams = {
      ci_dashboard: t('ci#dashboard'),
      teamId: this.teamId,
      teamName: team.name,
    };
    return (
      <ObjectSummary params={urlParams} />
    );
  }

  renderHeader() {
    return (
      <>
        {this.renderObjectSummary()}
      </>
    );
  }

  renderBody() {
    return (
      <Row>
        <Col sm="12" md="12" lg="4" xl="4">
          <Row>
            <Col>
              <NextRunCard />
            </Col>
          </Row>
          <Row>
            <Col>
              <RunningJobsCard />
            </Col>
          </Row>
          <Row>
            <Col>
              <LatestJob cardClassName="small-card" />
            </Col>
          </Row>
        </Col>
        <Col sm="12" md="12" lg="4" xl="4">
          <Row>
            <Col>
              <NumberJobsChart />
            </Col>
          </Row>
          <Row>
            <Col>
              <JobStatistics />
            </Col>
          </Row>
        </Col>
        <Col sm="12" md="12" lg="4" xl="4">
          <Row>
            <Col>
              <TestEnvironmentsCard />
            </Col>
          </Row>
          <Row>
            <Col>
              <TestProjectsCard />
            </Col>
          </Row>
          <Row>
            <Col>
              <PlansCard />
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }

  render() {
    const team = this.team;
    if (team) {
      return (
        <DefaultLayout
          renderHeader={this.renderHeader}
          renderBody={this.renderBody}
        />
      );
    }
    return null;
  }
}

export default CIDashboard;
