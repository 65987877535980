import React, { ReactNode } from 'react';
import TooltipComponent from '../../../components/TooltipComponent';
import DecoratorConstants from '../../../utils/DecoratorConstants';

interface MetadataProps {
  icon: ReactNode,
  text: string | ReactNode | null,
  trailing?: ReactNode,
  onClick?: () => void,
  maxCharacters?: number,
  tooltip?: string | ReactNode,
}

function Metadata(props: MetadataProps) {
  return (
    <div className="d-flex align-items-center">
      {props.icon}
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <TooltipComponent text={props.tooltip ? props.tooltip : props.text} arrow placement="bottom-end">
        <div className="ml-2">
          {
            props.onClick ?
              <div className="metadata-box" onClick={props.onClick}>
                {DecoratorConstants.truncateLeftStringDecorator(props.text, props.maxCharacters ?? 20)}
              </div> :
              <>{DecoratorConstants.truncateLeftStringDecorator(props.text, props.maxCharacters ?? 20)}</>
          }
        </div>
      </TooltipComponent>
      {
        props.trailing &&
          <div className="ml-2">
            {props.trailing}
          </div>
      }
    </div>
  );
}

export default Metadata;
