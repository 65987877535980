import { styled } from '@mui/material';
import React from 'react';
import { t } from '../../../i18n/t';

const ErrorContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(-2),
  color: theme.palette.error.main,
  fontSize: theme.fontSize.small,
}));

const ErrorText = styled('p')(({ theme }) => ({
  margin: theme.spacing(0),
}));

const ErrorList = styled('ul')(({ theme }) => ({
  margin: theme.spacing(0),
}));

function FolderNameError() {
  return (
    <ErrorContainer>
      <ErrorText>{t('folder_name#error_header')}</ErrorText>
      <ErrorList>
        <li>{t('folder_name#error_start_with_special_char')}</li>
        <li>{t('folder_name#error_contain_special_char')}</li>
        <li>{t('folder_name#error_contain_consecutive_dots')}</li>
        <li>{t('folder_name#error_end_with_dot')}</li>
        <li>{t('folder_name#error_max_length')}</li>
      </ErrorList>
    </ErrorContainer>
  );
}

export default FolderNameError;
