import React from 'react';
import {
  Button,
  Form,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import Services from '../../utils/Services';
import Input from '../Input';
import MContext from '../../models/MContext';
import Routes from '../../utils/Routes';
import Notification from '../../utils/Notification';

class DeleteProjectDialog extends React.Component {
  constructor(props) {
    super(props);

    this.project = MContext.project;

    this.toggle = this.toggle.bind(this);
    this.saveDelete = this.saveDelete.bind(this);
    this.cancelDelete = this.cancelDelete.bind(this);
    this.changeNameProject = this.changeNameProject.bind(this);

    this.state = {
      modal: false,
      userEnteredProjectName: '',
      disableSubmit: true,
    };
  }

  toggle() {
    const { modal } = this.state;
    this.setState({
      modal: !modal,
    });
  }

  changeNameProject(event) {
    const userEnteredProjectName = event.target.value;
    let disableSubmit = true;
    if (userEnteredProjectName === this.project.name) {
      disableSubmit = false;
    }
    this.setState({
      userEnteredProjectName,
      disableSubmit
    });
  }

  saveDelete(e) {
    e.preventDefault();
    const { userEnteredProjectName } = this.state;
    if (userEnteredProjectName === this.project.name) {
      Services.deleteProject(this.project.id).then(() => {
        Notification.pushSuccess('It may take some time for the project to be permanently removed.');
        setTimeout(() => {
          Routes.goToAdminProjectsPage(MContext.teamId);
        }, 3000);
      })
        .catch(() => { /* ignored */ });
    } else {
      window.alert('User has entered the wrong Project ID. The deletion is now canceled.');
    }
    this.toggle();
  }

  cancelDelete() {
    this.toggle();
  }

  render() {
    const { disableSubmit } = this.state;

    return (
      <div>
        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <Form data-trackid="delete-organization" onSubmit={this.saveDelete}>
            <ModalHeader>Are you absolutely sure?</ModalHeader>
            <ModalBody>
              This action cannot be undone. <br />
              This will permanently delete the <strong>project {this.project.name}</strong>, and remove all test plans associated.
              Please type <strong>{this.project.name}</strong> to confirm.
              <br /> <br />
              <Input required type="text" onChange={this.changeNameProject} />
            </ModalBody>
            <ModalFooter>
              <Button type="submit" color="danger" disabled={disableSubmit}>I understand the consequences, delete this project</Button>
              <Button color="secondary" onClick={this.cancelDelete}>Cancel</Button>
            </ModalFooter>
          </Form>
        </Modal>
      </div>
    );
  }
}


export default DeleteProjectDialog;
