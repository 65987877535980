import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import React, { useRef } from 'react';
import { t } from '../../../i18n/t';
import DropDownFilter from './DropDownFilter';
import { ResultFilterType } from '../../../utils/Constants';
import TooltipComponent from '../../TooltipComponent';

export const optionsResultFilter = [
  {
    id: 'all-test-results',
    value: ResultFilterType.ALL_RESULTS,
    label: t('test_result'),
  },
  {
    id: 'latest-results',
    value: ResultFilterType.LATEST_RESULTS,
    label: t('test_case'),
    tooltipMessage: t('view-latest-test-result'),
  },
];

function ViewByFilter({ value, ...restProps }) {
  const options = optionsResultFilter;
  const filterMenu = useRef();

  const renderOption = (option) => (
    <>
      {option.icon &&
        <ListItemIcon>
          {option.icon}
        </ListItemIcon>}
      <ListItemText primary={option.label} />
      {option.tooltipMessage &&
        <TooltipComponent
          text={option.tooltipMessage}
          placement="bottom-end"
          arrow
        />}
    </>
  );

  return (
    <DropDownFilter
      ref={filterMenu}
      searchKey="value"
      label={t('view-by')}
      options={options}
      renderOption={renderOption}
      defaultValue={[options[0].value]}
      {...restProps}
    />
  );
}

export default ViewByFilter;
