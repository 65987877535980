import React, { Component } from 'react';
import { ListItem } from '@mui/material';
import { Button, ButtonToolbar, Form } from 'reactstrap';
import Time from '../../../utils/Moment';
import DatePicker from '../../DatePicker';
import DropDownFilter from './DropDownFilter';
import { ValidationDate } from '../../../utils/Constants';

class ReleaseTimeFilter extends Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleTypeChange = this.handleTypeChange.bind(this);
    this.handleChangeEvent = this.handleChangeEvent.bind(this);
    this.applyFilter = this.applyFilter.bind(this);
    this.clear = this.clear.bind(this);
    this.filterMenu = React.createRef();
    this.state = this.defaultState();
  }

  defaultState() {
    return ({
      selectedFilter: '',
      between: {
        from: null,
        to: null,
      }
    });
  }

  handleChangeEvent(event) {
    const { value, name } = event.target;
    this.handleChange(value, name);
  }

  handleChange(value, name) {
    let parent, child;
    [parent, child] = name.split('.');
    this.setState((prevState) => ({
      [parent]: {
        ...prevState[parent],
        [child]: value
      }
    }));
  }

  betweenFilter() {
    const { between } = this.state;
    const { from, to } = between;
    return (
      <div className="d-flex flex-nowrap align-items-baseline normal-label">
        <div class="p-2">Between</div>
        <DatePicker
          onChange={(date) => this.handleChange(date, 'between.from')}
          id="between-from-date"
          value={from}
          inputFormat={Time.DATE_FORMAT}
          maxDate={to || Time.MAX_DATE}
          required
        />
        <div class="p-2">and</div>
        <DatePicker
          onChange={(date) => this.handleChange(date, 'between.to')}
          id="between-to-date"
          value={to}
          inputFormat={Time.DATE_FORMAT}
          minDate={from || Time.MIN_DATE}
          required
        />
      </div>
    );
  }

  handleTypeChange(event) {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  }

  buildFilterValue() {
    const { between } = this.state;
    const { from, to } = between;
    return `>=${from.format(Time.LOCAL_DATE_FORMAT)},<=${to.format(Time.LOCAL_DATE_FORMAT)}`;
  }

  buildFilterLabel() {
    const { between } = this.state;
    const { from, to } = between;
    return `Between ${from.format(Time.DATE_FORMAT)} and ${to.format(Time.DATE_FORMAT)}`;
  }

  clear() {
    this.setState(this.defaultState());
    this.filterMenu.current.applyFilter(null);
  }

  isValidDate() {
    const { between } = this.state;
    const { from, to } = between;

    if (from != null) {
      if (from.format(Time.DATE_FORMAT) === ValidationDate.INVALID_DATE) {
        return false;
      }
    }

    if (to != null) {
      if (to.format(Time.DATE_FORMAT) === ValidationDate.INVALID_DATE) {
        return false;
      }
    }

    return true;
  }

  applyFilter(event) {
    event.preventDefault();
    const filterValue = this.buildFilterValue();
    const filterLabel = this.buildFilterLabel();
    this.setState(
      { selectedFilter: filterLabel },
      () => this.filterMenu.current.applyFilter(filterValue)
    );
  }

  buttonLabel() {
    const { label } = this.props;
    const { selectedFilter } = this.state;
    const displayValue = selectedFilter || 'All';
    return `${label}: ${displayValue}`;
  }

  render() {
    return (
      <DropDownFilter buttonLabel={this.buttonLabel()} {...this.props} ref={this.filterMenu}>
        <Form className="form-filter" onSubmit={this.applyFilter}>
          <ListItem>
            {this.betweenFilter()}
          </ListItem>
          <ListItem>
            <ButtonToolbar>
              <Button color="primary" type="submit" disabled={!this.isValidDate()}>
                Update
              </Button>
              <Button color="link" onClick={this.clear}>
                Clear
              </Button>
            </ButtonToolbar>
          </ListItem>
        </Form>
      </DropDownFilter>
    );
  }
}

export default ReleaseTimeFilter;
