import React from 'react';
import { t } from '../../../i18n/t';

class CustomizedTooltipTreemap extends React.Component {

  convertData(data) {
    return [
      {
        label: t('test_runs'),
        value: data.totalTestRun,
      },
      {
        label: t('test_cases'),
        value: data.totalTestCase,
      },
      {
        label: t('failedTestCase'),
        value: data.failedTestCase,
      }
    ];
  }

  render() {
    const { active, payload } = this.props;
    if (active && payload && payload[0]) {
      const data = payload[0].payload;
      const { issueId, summary, featureName } = data.externalIssue || {};
      const convertData = this.convertData(data);

      return (
        <div className="custom-tooltip">
          <div className="header">{issueId} {summary} {featureName}</div>
          {convertData.map((object) => (
            <div className="content">
              <div className="label">{`${object.label}: `}</div>
              <div className="value">{object.value}</div>
            </div>
          ))}
        </div>
      );
    }

    return null;
  }
}

export default CustomizedTooltipTreemap;
