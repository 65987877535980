import React from 'react';
import { Button, Col, CustomInput, Form, FormGroup, FormText, Label, Row } from 'reactstrap';
import platform from 'platform-detect';
import arch from 'arch';
import { t } from '../../i18n/t';
import Select from '../../components/Select';
import RouteConstants from '../../utils/RouteConstants';
import Services from '../../utils/Services';
import Stepper from '../../components/wizard/Stepper';
import CodeBox from '../../components/CodeBox';
import Routes from '../../utils/Routes';
import MConfigs from '../../models/MConfigs';
import MAuth from '../../models/MAuth';
import MContext from '../../models/MContext';
import Notification from '../../utils/Notification';
import Link from '../../components/Link';
import SelectApiKeyComponent from '../../components/SelectApiKeyComponent';
import MFlags from '../../models/MFlags';

class TestCloudTunnelsSetup extends React.Component {

  constructor(props) {
    super(props);
    this.user = MAuth.user;
    this.proxyTunnelServerUrl = MConfigs.proxyTunnelServerUrl;
    this.orgId = MContext.team.organizationId;
    this.initState();
    this.handleSelectApiKey = this.handleSelectApiKey.bind(this);
    this.handleSelectOS = this.handleSelectOS.bind(this);
    this.handleDownloadTunnelExecutable = this.handleDownloadTunnelExecutable.bind(this);
    this.osOptions = Object.entries(MConfigs.proxyTunnelDownloadUrls)
      .map(([label, value]) => ({
        label,
        value,
      }));
    this.toggleShare = this.toggleShare.bind(this);
  }

  componentDidMount() {
    const { searchApiKeyEnabled } = MFlags;
    if (!searchApiKeyEnabled) {
      this.getApiKeys();
    } else {
      this.getOS();
      this.setState({ disableSwitchShared: false });
    }
  }

  initState() {
    this.state = {
      apiKey: null,
      apiKeys: null,
      selectedOS: null,
      disableDownloadTunnel: true,
      configTunnelCommand: '',
      startTunnelCommand: '',
      shared: true,
      disableSwitchShared: true,
    };
  }

  generateCommand() {
    const { apiKey, selectedOS, shared } = this.state;

    let command;
    if (selectedOS) {
      const name = selectedOS.label;
      if (name === 'Linux x64' || name === 'Linux x86') {
        command = './kt';
      } else if (name === 'MacOS x64') {
        command = '/Applications/kt';
      } else if (name === 'Windows x64' || name === 'Windows x86') {
        command = 'kt';
      }
    }

    // We still keep flag --team-id for backward compatible to old tunnel client, but it's never used in client
    const configTunnelCommand = `${command} config --server=${this.proxyTunnelServerUrl} --api-key=${apiKey?.value} --username=${this.user.email}` +
    ` --organization-id=${this.orgId} --tenant=TestOps --private-tunnel=${!shared} --team-id=${this.orgId}`;
    const startTunnelCommand = `${command} start`;
    this.setState({
      configTunnelCommand,
      startTunnelCommand,
    });
  }

  handleSelectApiKey(apiKey) {
    this.setState({ apiKey }, () => {
      this.generateCommand();
    });
  }

  handleSelectOS(osOption) {
    this.setState({
      selectedOS: osOption,
      disableDownloadTunnel: false,
    }, () => {
      this.generateCommand();
    });
  }

  getOS() {
    let defaultOS;
    try {
      let os = 'Windows';
      if (platform.linux) {
        os = 'Linux';
      } else if (platform.macos) {
        os = 'MacOS';
      }
      defaultOS = `${os} ${arch()}`;
    } catch (e) {
      defaultOS = 'Windows x64';
    }
    const userOS = this.osOptions.find((option) => option.label === defaultOS);
    this.setState({
      selectedOS: userOS,
      disableDownloadTunnel: false,
    }, () => {
      this.generateCommand();
    });
  }

  handleDownloadTunnelExecutable(event) {
    event.preventDefault();
    const { selectedOS = {} } = this.state;
    window.open(selectedOS.value);
  }

  getApiKeys() {
    Services.getApiKeys()
      .then((response) => {
        const apiKeys = response.map((apikey) => ({
          value: apikey.key,
          label: apikey.name,
        }));
        this.setState({
          apiKeys,
          apiKey: apiKeys[0],
        }, () => {
          this.getOS();
          this.setState({ disableSwitchShared: false });
        });
      })
      .catch((e) => {
        Notification.pushError(e, t('test-cloud-tunnel#fail-get-active-tunnels'));
      });
  }

  renderTunnelDownload() {
    const { selectedOS, disableDownloadTunnel } = this.state;
    const url = selectedOS && selectedOS.value;

    return (
      <Row>
        <Col sm="12" md="8" lg="6" xl="5">
          <FormGroup>
            <Label for="osName">{t('test-cloud-tunnel#selectOS')}</Label>
            <Select
              clearable={false}
              onChange={this.handleSelectOS}
              value={selectedOS}
              options={this.osOptions}
            />
          </FormGroup>
          {url && (
            <FormGroup>
              <a
                target="_blank"
                rel="noreferrer noopener"
                href={url}
              >
                {url}
              </a>
            </FormGroup>
          )}
          <FormGroup>
            <Button
              color="primary"
              onClick={this.handleDownloadTunnelExecutable}
              disabled={disableDownloadTunnel}
            >
              {t('test-cloud-tunnel#download')}
            </Button>
          </FormGroup>
        </Col>
      </Row>
    );
  }

  renderTunnelConfig() {
    const { configTunnelCommand } = this.state;
    return (
      <FormGroup>
        <CodeBox
          content={configTunnelCommand}
        />
      </FormGroup>
    );
  }

  renderStartTunnel() {
    const { startTunnelCommand } = this.state;
    return (
      <FormGroup>
        <CodeBox
          content={startTunnelCommand}
        />
      </FormGroup>
    );
  }

  renderCreatePlan() {
    const routes = new Routes();
    return (
      <span>After the TestCloud tunnel starts successfully, navigate to <Link href={routes.grid_link}>Test Run Types</Link> and execute your test run.</span>
    );
  }

  toggleShare(event) {
    this.setState({
      shared: event.currentTarget.checked,
    }, () => {
      this.generateCommand();
    });
  }

  renderTunnelConfiguration() {
    const { apiKey, apiKeys, shared, disableSwitchShared } = this.state;
    const { searchApiKeyEnabled } = MFlags;

    return (
      <Row>
        <Col sm="12" md="8" lg="6" xl="5">
          <FormGroup>
            <Label for="apiKey">{t('agent#api-key')}</Label>

            {searchApiKeyEnabled ?
              <SelectApiKeyComponent onChange={this.handleSelectApiKey} />
              :
              <Select
                clearable={false}
                id="apiKey"
                value={apiKey}
                onChange={this.handleSelectApiKey}
                options={apiKeys}
              />}
            <FormText color="muted">
              {t('agent#generate-api-key')}
              <a
                href={RouteConstants.apikey}
              >
                here.
              </a>
            </FormText>
          </FormGroup>
          <FormGroup>
            <Label>{t('test-cloud-tunnel#sharedTunnel')}</Label>
            <FormText color="muted">{t('test-cloud-tunnel#share-description')}</FormText>
            <CustomInput
              id="kobiton-enable"
              type="switch"
              name="kobiton-enable"
              checked={shared}
              onChange={this.toggleShare}
              className="normal-label"
              disabled={disableSwitchShared}
            />
          </FormGroup>
        </Col>
      </Row>
    );
  }

  renderStepToSetup() {
    const steps = [
      {
        label: t('test-cloud-tunnel#step#download'),
        content: this.renderTunnelDownload(),
        description: (
          <>
            Select your compatible OS to download and <b>unzip</b> the TC-tunnel executable file.
          </>
        ),
      },
      {
        label: t('test-cloud-tunnel#step#config'),
        content: this.renderTunnelConfiguration(),
        description: 'This information will be displayed in the TC-tunnel command.',
      },
      {
        label: t('test-cloud-tunnel#step#configCommand'),
        content: this.renderTunnelConfig(),
        description: (
          <>
            Open your Command-line Interface and navigate to the directory containing the TC-tunnel executable file.
            <br />
            Copy & paste this command to your Command-line Interface.
          </>
        ),
      },
      {
        label: t('test-cloud-tunnel#step#startCommand'),
        content: this.renderStartTunnel(),
      },
      {
        label: t('test-cloud-tunnel#step#execute'),
        content: this.renderCreatePlan(),
      },
    ];

    return (
      <Form>
        <Stepper
          id="tunnel-setup"
          data-trackid="tunnel-setup"
          expanded
          steps={steps}
        />
      </Form>
    );
  }

  render() {
    return this.renderStepToSetup();
  }
}

export default TestCloudTunnelsSetup;
