import React, { useState } from 'react';
import { Button, ButtonToolbar, Form, FormGroup, Label } from 'reactstrap';
import PropTypes from 'prop-types';
import { DialogActions, DialogContent } from '@mui/material';
import Input from '../../Input';
import { t } from '../../../i18n/t';
import CloseableDialogComponent from '../../dialog/CloseableDialogComponent';
import { TestSuiteType, TestProjectType } from '../../../utils/Constants';
import Services from '../../../utils/Services';
import Notification from '../../../utils/Notification';
import Routes from '../../../utils/Routes';
import NotificationHandler from '../handler/NotificationHandler';
import { sendAnalyticEventForAction } from '../../../utils/SegmentAnalytics';
import TestProjectHelper from '../../../utils/TestProjectHelper';
import MFlags from '../../../models/MFlags';
import TestSuiteService from '../service/TestSuiteService';
import TestObjectPublisher from '../service/TestObjectPublisher';

function G5TestSuiteEditView({ testSuite, selectedLinkingTestCases, isOpen, handleClose }) {
  const [commitMessage, setCommitMessage] = useState('');

  const { testProject, name, path, id: testSuiteId } = testSuite;

  const defaultCommitMessage = t('publish-test-suite#default-commit-message', { testCaseName: name, testCasePath: path });

  const onChangeCommit = (event) => {
    setCommitMessage(event.target.value);
  };

  const publishTestProjectErrorHandler = (message, label, jqXHR) => {
    NotificationHandler.publishTestProjectErrorHandler(message, jqXHR);
  };

  const handlePublish = () => {
    const publishTestSuite = TestSuiteService.buildPublishTestSuite(testSuiteId, name, path, TestSuiteType.CLOUD_STUDIO, selectedLinkingTestCases);

    TestObjectPublisher.publishTestObject(testProject.id, [], [publishTestSuite], commitMessage || defaultCommitMessage, publishTestProjectErrorHandler)
      .then((publishedTestProject) => {
        const publishedTestSuite = publishedTestProject.testSuites[0];
        Notification.pushSuccess(t('publish-test-suite#successfully-edit', { testSuiteName: name, testSuitePath: `${testProject.name}/${path}` }));
        setTimeout(() => {
          Routes.goToTestSuitesDetailsLink(publishedTestSuite.urlId);
        }, 2000);
      })
      .catch(() => {});
    const storageType = TestProjectHelper.getStorageType(testProject);
    sendAnalyticEventForAction('publish-cloud-studio-ts', { storage_type: storageType });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
      handlePublish();
  };

  return (
    <Form
      onSubmit={handleSubmit}
    >
      <CloseableDialogComponent
        isOpen={isOpen}
        handleClose={handleClose}
        maxWidth="sm"
        title={t('publish-test-suite#title')}
        headerStyle="publish-dialog__header"
        disablePortal
      >
        <DialogContent>
          <div className="publish-dialog__content">
            <FormGroup>
              <Label for={t('location')}>{t('location')}</Label>
              <Input plaintext id={t('location')} data-testid="location" className="text-break">
                {testProject.name}/{path}
              </Input>
            </FormGroup>
            {testProject?.type === TestProjectType.GIT &&
            <FormGroup>
              <Label for={t('commit-message')}>{t('commit-message')}</Label>
              <Input
                className="publish-dialog__text"
                data-testid="commit-message"
                type="text"
                value={commitMessage}
                onChange={onChangeCommit}
                placeholder={defaultCommitMessage}
              />
            </FormGroup>}
          </div>
        </DialogContent>
        <DialogActions className="d-flex justify-content-end publish-dialog__footer">
          <ButtonToolbar>
            <Button
              color="secondary"
              onClick={handleClose}
            >
              {t('cancel')}
            </Button>
            <Button
              type="submit"
              color="primary"
            >
              {t('publish')}
            </Button>
          </ButtonToolbar>
        </DialogActions>
      </CloseableDialogComponent>
    </Form>
  );
}

G5TestSuiteEditView.propTypes = {
  testSuite: PropTypes.object.isRequired,
  selectedLinkingTestCases: PropTypes.array.isRequired,
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default G5TestSuiteEditView;
