import { isEmpty } from 'lodash';
import { TestCaseObjStore } from './IndexedDBHelper';
import { draftTestCaseIdPrefix, SUPPORTED_TEST_TYPE, PROJECT_SUPPORTED_TEST_TYPE } from '../utils/Constants';
import Services from '../utils/Services';

export default {

  isSupportedTestCase(testCase) {
    return (testCase.testProject && SUPPORTED_TEST_TYPE.includes(testCase.testType))
    || (!testCase.testProject && PROJECT_SUPPORTED_TEST_TYPE.includes(testCase.testType));
  },

  async updateOrCreate(testcase) {
    return TestCaseObjStore.put(testcase);
  },

  async get(testCaseId) {
    return TestCaseObjStore.get(testCaseId);
  },

  async getAll() {
    return TestCaseObjStore.getAll();
  },

  delete(testCaseId) {
    return TestCaseObjStore.delete(testCaseId);
  },

  /**
   * Generate id for new draft test case
   * @param {String} draftTestCaseName
   * @returns
   */
  generateNewDraftTestCaseId(draftTestCaseName) {
    const currentDate = new Date();
    return `${draftTestCaseIdPrefix}-${currentDate.getTime()}-${draftTestCaseName.replace(/ /g, '-')}`;
  },

  /**
   * Check whether the current draft test case is a new draft or an existing one
   * @param {String} testCaseId
   * @returns
   */
  async isNewDraftTestCase(testCaseId) {
    const draftTestCase = await this.get(testCaseId);

    if (draftTestCase === null) {
      return false;
    }

    const isTestCaseIdStartsWithPrefix = testCaseId.startsWith(draftTestCaseIdPrefix);
    if (isTestCaseIdStartsWithPrefix) {
      return true;
    }

    const { content: existingTestCase } = await Services.getTestCaseByIdAndTestProjectId(testCaseId, draftTestCase.scriptRepoId);
    return isEmpty(existingTestCase);
  }
};
