import React, { useCallback } from 'react';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';
import MAuth from '../models/MAuth';
import { IconStop } from '../images/CustomIcon';
import { t } from '../i18n/t';

function StopButton(props) {
  const { row, onClick } = props;
  const email = MAuth.user.email;
  const isOrganizationManager = MAuth.isOrganizationManager(row.organizationId);
  const onStopButtonClick = useCallback(() => {
    onClick(row);
  }, [row, onClick]);
  return (
    <Button
      className="btn-icon-only"
      data-trackid="btn-stop-tunnel"
      title={t('Stop')}
      onClick={onStopButtonClick}
      color={email === row.username ? 'active-tunnels-stop' : 'active-tunnels'}
      disabled={email !== row.username && !isOrganizationManager}
    >
      <IconStop />
    </Button>
  );
}

StopButton.propTypes = {
  row: PropTypes.shape({
    organizationId: PropTypes.number.isRequired,
    username: PropTypes.string.isRequired,
  }).isRequired,
};
export default React.memo(StopButton);
