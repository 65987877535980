

import React from 'react';
import MTableColumnDataMapping from './models/MTableColumnDataMapping';
import Routes from '../../utils/Routes';
import { t } from '../../i18n/t';
import DataTable from './DataTable';
import Link from '../Link';

class TestCasePriorityDataTable extends React.Component {

  render() {
    const { defaultSearchConditions } = this.props;
    const headers = [
      new MTableColumnDataMapping(
        'Priority score',
        'priority',
      ),
      new MTableColumnDataMapping(
        'Priority type',
        'type',
      ),
      new MTableColumnDataMapping(
        t('name'),
        'testCase.name',
        (name, row) => {
          const constructedLink = new Routes({
            teamId: row.project.teamId,
            projectId: row.project.id,
            testCaseId: row.testCase.urlId,
          });
          const fullPath = `${row.testCase.path}/${row.testCase.name}`;
          return <Link href={constructedLink.execution_test_result_history_link} title={fullPath}>{row.testCase.name}</Link>;
        },
      ),
    ];

    return (
      <DataTable
        columnMapping={headers}
        entityType="TestCasePriority"
        defaultSearchConditions={defaultSearchConditions}
        defaultSort={['type,asc', 'priority,desc']}
        {...this.props}
      />
    );
  }
}

export default TestCasePriorityDataTable;
