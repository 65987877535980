import React from 'react';

import LogoKSE from '../../images/logos/icon_logo_KSE@2x.png';
import LogoEngine from '../../images/logos/icon_logo_KRE@2x.png';
import LogoTestOps from '../../images/icons/testop-logo/testops_icon.svg';
import LogoTestOpsEnterprise from '../../images/icons/testop-logo/testops_icon_enterprise.svg';

export default (props) => {
  const { productId } = props;
  const src = {
    KSE: LogoKSE,
    ENGINE: LogoEngine,
    FLOATING_ENGINE: LogoEngine,
    UNLIMITED_KSE: LogoKSE,
    UNLIMITED_ENGINE: LogoEngine,
    TESTOPS_BUSINESS: LogoTestOps,
    TESTOPS_ENTERPRISE: LogoTestOpsEnterprise,
  }[productId];

  return <img src={src} alt="" width="auto" height="32px" className="product-card__logo" />;
};
