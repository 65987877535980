import { IconButton } from '@mui/material';
import classNames from 'classnames';
import React from 'react';
import { IconXmark } from '../images/CustomIcon';

const ChipGroup = ({ children, className, isRemovable, handleRemove }) => (
  <div className={classNames(className, 'chip-group')}>
    {React.Children.map(children, (child) =>
      React.cloneElement(child, {
        className: classNames(child.props.className, 'custom-chip')
      }))}
    {isRemovable &&
      <IconButton
        color="inherit"
        className="custom-chip remove-btn"
        variant="outlined"
        onClick={() => handleRemove()}
      >
        <IconXmark />
      </IconButton>}
  </div>
);

export default ChipGroup;
