import React, { Component } from 'react';
import { Button, Card, CardBody, CardHeader, Col, Form, FormGroup, Label, Row } from 'reactstrap';
import { t } from '../i18n/t';
import ObjectSummary from '../components/summary/ObjectSummary';
import DefaultLayout from '../components/DefaultLayout';
import Input from '../components/Input';
import MContext from '../models/MContext';
import Services from '../utils/Services';
import Routes from '../utils/Routes';
import MAuth from '../models/MAuth';
import Notification from '../utils/Notification';
import DeleteTeamDialog from '../components/dialog/DeleteTeamDialog';

class TeamSettings extends Component {
  constructor(props) {
    super(props);
    this.teamId = MContext.teamId;
    const team = MContext.team;
    this.state = {
      team,
      teamName: team.name,
      disableForm: false,
    };

    this.deleteTeamDialog = React.createRef();

    this.renderHeader = this.renderHeader.bind(this);
    this.renderBody = this.renderBody.bind(this);
    this.handleTextChange = this.handleTextChange.bind(this);
    this.updateTeamName = this.updateTeamName.bind(this);
    this.afterRemovedTeam = this.afterRemovedTeam.bind(this);
    this.handlerErrorMessage = this.handlerErrorMessage.bind(this);
    this.deleteTeam = this.deleteTeam.bind(this);
  }

  updateTeamName(e) {
    e.preventDefault();
    const { team, teamName } = this.state;

    if (teamName) {
      this.setState({ disableForm: true });
      const newTeamData = {
        id: team.id,
        name: teamName,
      };
      Services.updateTeamName(newTeamData)
        .then((team) => {
          MContext.team = team;
          Notification.pushSuccess(`Team ${teamName} was updated.`);
        }).finally(() => this.setState({ disableForm: false }));
    }
  }

  renderObjectSummary() {
    const urlParams = {
      teamSettings: t('team_settings'),
    };
    return (
      <ObjectSummary params={urlParams} />
    );
  }

  renderHeader() {
    return (
      <>
        {this.renderObjectSummary()}
      </>
    );
  }

  handleTextChange(event) {
    const value = event.target.value;
    const name = event.target.name;
    this.setState({ [name]: value });
  }

  afterRemovedTeam() {
    Routes.goToOrganizationPage(MContext.organizationId);
  }

  deleteTeam(event) {
    event.preventDefault();
    this.deleteTeamDialog.current.toggle();
  }

  handlerErrorMessage(message) {
    Notification.pushError(message);
  }

  renderEditTeam() {
    const { team, teamName, disableForm } = this.state;

    return (
      <Card>
        <CardHeader>
          {t('team_profile')}
        </CardHeader>
        <CardBody>
          <Row>
            <Col sm="12" md="8" lg="6" xl="5">
              <Form data-trackid="edit-team" onSubmit={this.updateTeamName}>
                <FormGroup>
                  <Label>{t('team_id')}</Label>
                  <Input plaintext>{team.id}</Input>
                </FormGroup>
                <FormGroup>
                  <Label for="teamName">{t('team_name')}</Label>
                  <Input
                    id="teamName"
                    name="teamName"
                    required
                    disabled={disableForm}
                    value={teamName}
                    onChange={this.handleTextChange}
                    type="text"
                  />
                </FormGroup>
                <FormGroup>
                  <Button type="submit" color="primary" disabled={disableForm}>
                    {t('team_update')}
                  </Button>
                </FormGroup>
              </Form>
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  }

  renderDangerZone() {
    const { id } = MContext.team;
    const isTeamOwner = MAuth.isTeamOwner(id);

    if (!isTeamOwner) {
      return null;
    }

    const deleteTeam = (
      <Form data-trackid="delete-team">
        <FormGroup>
          <Label for="teamName">Delete This Team</Label>
          <p>Once deleted, it will be gone forever. Please be certain.</p>
        </FormGroup>
        <FormGroup>
          <Button type="submit" color="danger" onClick={this.deleteTeam}>
            {t('team_delete_btn')}
          </Button>
        </FormGroup>
      </Form>
    );
    return (
      <>
        <Card>
          <CardHeader>
            Danger zone
          </CardHeader>
          <CardBody>
            {isTeamOwner && deleteTeam}
          </CardBody>
        </Card>
        <DeleteTeamDialog
          ref={this.deleteTeamDialog}
          afterRemoval={this.afterRemovedTeam}
          errorHandler={this.handlerErrorMessage}
        />
      </>
    );
  }

  renderBody() {
    const isTeamManager = MAuth.isTeamManager(this.teamId);

    if (!isTeamManager) {
      Routes.goToAccessDeniedPage();
    }

    return (
      <>
        {this.renderEditTeam()}
        {this.renderDangerZone()}
      </>
    );
  }
  render() {
    return (
      <DefaultLayout
        renderHeader={this.renderHeader}
        renderBody={this.renderBody}
      />
    );
  }
}

export default TeamSettings;
