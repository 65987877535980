import parse from 'url-parse';
import { includes } from 'lodash';
import RouteConstants from './RouteConstants';

const UrlHelper = {

  getSearchParam(paramName) {
    const urlString = window.location.href;
    const params = new URL(urlString).searchParams;
    return params.get(paramName);
  },

  /**
   * Create a array to contain list of params.
   * Ex: paramsValue = [{paramName: 'type', value: 1}]
   * @param {paramName} The param name on search url.
   * @param {value}     The value of param on search url.
  */
  setUrlParamsAndUpdate(paramsValue) {
    const urlString = window.location.href;
    const url = new URL(urlString);
    paramsValue.map(({ paramName, value }) => url.searchParams.set(paramName, value));
    window.history.pushState(null, null, url.toString());
  },

  generateParams(paramName, value) {
    const urlParams = new URLSearchParams();
    urlParams.set(paramName, value);
    return `?${urlParams.toString()}`;
  },

  /**
   * Remove all params in URL without having to reload the page
   * Ex: .../test_planning?isOpenScheduleTestRunDialog=true&testProjectId=1 -> .../test_planning
   */
  removeAllParams() {
    window.history.pushState(null, null, `${window.location.pathname}`);
  },

  /**
   * Get redirectURL with forward url except excludeForwardURLs
   * Ex: currentUrl: /home
   * redirectURL: /welcome -> return /welcome?redirect=home
   */
  getRedirectUrlWithForwardUrl(redirectURL, excludeForwardURLs) {
    if (window.location.pathname !== RouteConstants.login && window.location.pathname !== RouteConstants.logout && window.location.pathname !== redirectURL) {
      const urlString = window.location.href;
      const url = new URL(urlString);
      const searchParams = new URLSearchParams(url.search);

      const currentPath = window.location.pathname;

      if (includes(excludeForwardURLs, currentPath)) {
        return redirectURL;
      }

      const forwardURL =
        window.encodeURIComponent(`${currentPath}?${searchParams.toString()}`);
      const redirectParams = new URLSearchParams();
      redirectParams.set('redirect', forwardURL);

      return `${redirectURL}?${redirectParams.toString()}`;
    }
    return null;
  },

  isRelative(url) {
    return url && url.match(/^\/[^/\\]/);
  },

  navigateToForwardURL() {
    const url = parse(window.location.href, true);
    const redirectEncoded = url.query.redirect;
    if (redirectEncoded) {
      const redirectDecoded = window.decodeURIComponent(redirectEncoded);
      if (!UrlHelper.isRelative(redirectDecoded)) {
        // prevent redirect attack
        // redirect to home
        window.location.href = RouteConstants.home;
      } else {
        window.location.href = redirectDecoded;
      }
    } else {
      window.location.href = RouteConstants.home;
    }
  },

  copyToClipboard(content) {
    navigator.clipboard.writeText(content);
  },
};

export default UrlHelper;

