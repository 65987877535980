import React from 'react';
import TestCasePlatformStatisticsDataTable from '../../../components/table/TestCasePlatformStatisticsDataTable';

class PlatformStatisticsReport extends React.Component {

  render() {
    return (
      <TestCasePlatformStatisticsDataTable />
    );
  }

}

export default PlatformStatisticsReport;
