

class MTableFilter {

    items = [];

    onChange = null;

    constructor(onChange) {
      this.onChange = onChange;
    }

    add(child) {
      const index = this.findItemIndex(child);
      if (index < 0) {
        this.items.push(child);
        this.onChange();
      }
    }

    insert(child, isUpdate = false) {
      this.items.push(child);
      if (isUpdate) {
        this.onChange();
      }
    }

    remove(item) {
      const index = this.items.indexOf(item);
      if (index >= 0) {
        this.items.splice(index, 1);
        this.onChange();
      }
    }

    findItemIndex(itemToFind) {
      for (let i = 0, length = this.items.length; i < length; i++) {
        const item = this.items[i];
        if (item.name === itemToFind.name && item.value === itemToFind.value) {
          return i;
        }
      }
      return -1;
    }

    clear() {
      this.items = [];
      this.onChange();
    }

    isEmpty() {
      return this.items.length === 0;
    }

    toString() {
      return JSON.stringify(this.items);
    }

    clearNoTrigger() {
      this.items = [];
    }
}

export default MTableFilter;
