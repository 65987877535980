import Apis from './Apis';
import MConfigs from '../models/MConfigs';

export const methodSupport = {
  POST: 'POST',
  PUT: 'PUT',
  GET: 'GET',
  DELETE: 'DELETE',
};

const createApiSupport = (url, method, header, body) => {
  const headerContent = header ||
    'Headers:\n' +
    '    - Authorization: <base_64(email:api_key)> \n' +
    '    - Content-Type: application/json';

  const bodyContent = body || '';

  return `${method} ${url}\n${headerContent}${bodyContent}`;
};

const ApiHelper = {
  rerunPlan: (testPlanId) => {
    const url = `${MConfigs.serverUrl}${Apis.executionRerun(testPlanId)}`;
    return createApiSupport(url, methodSupport.POST);
  },

  executePlan: (configurationId) => {
    const url = `${MConfigs.serverUrl}${Apis.executeRunConfiguration(configurationId)}`;
    return createApiSupport(url, methodSupport.PUT);
  }
};

export default ApiHelper;
