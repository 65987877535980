import React from 'react';
import PropTypes from 'prop-types';
import { ProjectStatisticsType } from '../../utils/Constants';
import Select from '../Select';
import { t } from '../../i18n/t';

const TimeSelect = ({ defaultValue, onSelectChange, ...props }) => {

  const options = [
    {
      value: ProjectStatisticsType.DAILY,
      label: t('daily'),
    },
    {
      value: ProjectStatisticsType.WEEKLY,
      label: t('weekly'),
    },
    {
      value: ProjectStatisticsType.MONTHLY,
      label: t('monthly'),
    },
  ];

  return (
    <Select
      options={options}
      value={defaultValue}
      onChange={onSelectChange}
      useAutocomplete
      disableClearable
      blurOnSelect
      {...props}
    />
  );
};

TimeSelect.propTypes = {
  defaultValue: PropTypes.string,
  onSelectChange: PropTypes.func,
};

TimeSelect.defaultProps = {
  defaultValue: ProjectStatisticsType.DAILY,
};

export default TimeSelect;
