import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import React, { useEffect, useRef } from 'react';
import {
  IconStatusPassed,
  IconStatusFailed,
  IconStatusIncomplete,
  IconStatusError,
  IconGitRepo, IconKSRepo, IconReadyStatus, IconNotReadyStatus, IconEmptyStatus
} from '../../../images/CustomIcon';
import { t } from '../../../i18n/t';
import DropDownFilter from './DropDownFilter';
import DecoratorConstants from '../../../utils/DecoratorConstants';
import { DefectPriority, ReleaseStatus } from '../../../utils/Constants';
import ZipRepositoryIcon from '../../../../images/icons/katalonui/ZipRepositoryIcon';
import MFlags from '../../../models/MFlags';
import KatalonStorageIcon from '../../../../images/icons/katalonui/KatalonStorageIcon';

export const StatusType = {
  EXECUTION: 'EXECUTION',
  EXECUTION_TEST_RESULT: 'EXECUTION_TEST_RESULT',
  JOB: 'JOB',
  TEST_CASE: 'TEST_CASE',
  RELEASE: 'RELEASE',
  JIRA_RELEASE: 'JIRA_RELEASE',
  RELEASE_STATUS: 'RELEASE_STATUS',
  CHECKPOINT: 'CHECKPOINT',
  CHECKPOINT_RESULT: 'CHECKPOINT_RESULT',
  TEST_PROJECT: 'TEST_PROJECT',
  DEFECT_PRIORITY: 'DEFECT_PRIORITY',
};

export const StatusOption = {
  EXECUTION: [
    { icon: <IconStatusPassed />, label: 'Passed', value: 'PASSED', id: 'passed' },
    { icon: <IconStatusFailed />, label: 'Failed', value: 'FAILED', id: 'failed' },
  ],
  EXECUTION_TEST_RESULT: [
    { icon: <IconStatusPassed />, label: 'Passed', value: 'PASSED', id: 'passed' },
    { icon: <IconStatusFailed />, label: 'Failed', value: 'FAILED', id: 'failed' },
    { icon: <IconStatusError />, label: 'Error', value: 'ERROR', id: 'error' },
    { icon: <IconStatusIncomplete />, label: 'Incomplete', value: 'INCOMPLETE', id: 'incomplete' }
  ],
  JOB: [
    { icon: DecoratorConstants.status('Queued'), label: 'Queued', value: 'QUEUED', id: 'Queued' },
    { icon: DecoratorConstants.status('Running'), label: 'Running', value: 'RUNNING', id: 'Running' },
    { icon: DecoratorConstants.status('Error'), label: 'Error', value: 'ERROR', id: 'Error' },
    { icon: DecoratorConstants.status('Canceled'), label: 'Canceled', value: 'CANCELED', id: 'Canceled' },
    { icon: DecoratorConstants.status('Success'), label: 'Success', value: 'SUCCESS', id: 'Success' },
    { icon: DecoratorConstants.status('Failed'), label: 'Failed', value: 'FAILED', id: 'Failed' },
    { icon: DecoratorConstants.status('Wait for trigger'), label: 'Wait for trigger', value: 'WAIT_FOR_TRIGGER', id: 'Wait for trigger' },
  ],
  TEST_CASE: [
    { label: 'Test Case', value: 'TEST_CASE', id: 'test-case' },
    { label: 'Scenario', value: 'SCENARIO', id: 'scenario' },
  ],
  RELEASE: [
    { label: t('active'), value: 'false', id: 'active' },
    { label: t('archived'), value: 'true', id: 'closed' },
  ],
  JIRA_RELEASE: [
    { label: 'Jira', value: 'is not null', id: 'jira' },
    { label: 'TestOps', value: 'is null', id: 'testops' },
  ],
  RELEASE_STATUS: [
    { icon: <IconReadyStatus />, label: t(ReleaseStatus.READY), value: ReleaseStatus.READY, id: 'ready' },
    { icon: <IconNotReadyStatus />, label: t(ReleaseStatus.NOT_READY), value: ReleaseStatus.NOT_READY, id: 'not_ready' },
    { icon: <IconEmptyStatus />, label: t(ReleaseStatus.EMPTY), value: ReleaseStatus.EMPTY, id: 'empty' },
  ],
  CHECKPOINT_RESULT: [
    { icon: <IconStatusFailed />, label: 'Failed', value: 'FAILED', id: 'failed' },
    { icon: DecoratorConstants.status('unresolved'), label: 'Unresolved', value: 'UNRESOLVED', id: 'unresolved' },
  ],
  CHECKPOINT: [
    { icon: <IconStatusPassed />, label: 'Passed', value: 'PASSED', id: 'passed' },
    { icon: <IconStatusFailed />, label: 'Failed', value: 'FAILED', id: 'failed' },
    { icon: DecoratorConstants.status('unresolved'), label: 'Unresolved', value: 'UNRESOLVED', id: 'unresolved' },
  ],
  TEST_PROJECT: [
    { icon: <KatalonStorageIcon />, label: t('cloudStorage'), value: 'CLOUD', id: 'cloud' },
    { icon: <IconGitRepo />, label: t('git'), value: 'GIT', id: 'git' },
    { icon: <ZipRepositoryIcon />, label: t('uploadedRepository'), value: 'KS', id: 'ks' },
  ],
  TEST_PROJECT_OLD: [
    { icon: <IconKSRepo />, label: 'Katalon Studio', value: 'KS', id: 'ks' },
    { icon: <IconGitRepo />, label: 'Git', value: 'GIT', id: 'git' },
  ],
  DEFECT_PRIORITY: [
    { icon: DefectPriority.HIGHEST.icon, label: DefectPriority.HIGHEST.value, value: DefectPriority.HIGHEST.value, id: 'highest' },
    { icon: DefectPriority.HIGH.icon, label: DefectPriority.HIGH.value, value: DefectPriority.HIGH.value, id: 'high' },
    { icon: DefectPriority.MEDIUM.icon, label: DefectPriority.MEDIUM.value, value: DefectPriority.MEDIUM.value, id: 'medium' },
    { icon: DefectPriority.LOW.icon, label: DefectPriority.LOW.value, value: DefectPriority.LOW.value, id: 'low' },
    { icon: DefectPriority.LOWEST.icon, label: DefectPriority.LOWEST.value, value: DefectPriority.LOWEST.value, id: 'lowest' },
  ],
};

const StatusFilter = ({ type, value, ...restProps }) => {
  const options = StatusOption[type];
  const filterMenu = useRef();

  useEffect(() => {
    if (!value) {
      filterMenu.current.resetSelectedOption();
    }
  }, [value]);

  const renderOption = (option) => (
    <>
      {option.icon &&
        <ListItemIcon>
          {option.icon}
        </ListItemIcon>}
      <ListItemText primary={option.label} />
    </>
  );

  const renderDefectPriority = (option) => (
    <>
      {option.icon &&
        <div>
          <span className="mr-1"> {option.icon} </span> {option.label}
        </div>}
    </>
  );

  const isRenderDefectPriority = type === 'DEFECT_PRIORITY';

  return (
    <DropDownFilter
      ref={filterMenu}
      searchKey="value"
      label="Status"
      options={options}
      renderOption={isRenderDefectPriority ? renderDefectPriority : renderOption}
      {...restProps}
    />
  );
};

export default StatusFilter;
