import { IconButton } from '@mui/material';
import React, { useState } from 'react';
import { Form } from 'reactstrap';
import jquery from 'jquery';
import PropTypes, { number, string } from 'prop-types';
import { IconPencil } from '../images/CustomNewIcon';
import { ID_EDITABLE_INLINE_FORM, KeyboardButton } from '../utils/Constants';
import { sendAnalyticEventForAction } from '../utils/SegmentAnalytics';
import Input from './Input';
import TooltipComponent from './TooltipComponent';

function EditableField({
  children,
  text = '',
  editButtonTooltip = '',
  onSubmitEdit,
  onChangeEditable,
  className,
  maxLength = 256,
  trackingProps,
  ...props }) {

  const [isEditableMode, setEditableMode] = useState(false);
  const [value, setValue] = useState(text);

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const sendTrackingEvent = (dataTrackId) => {
    const { dataGroupId } = trackingProps;
    if (dataTrackId && dataGroupId) {
      sendAnalyticEventForAction(dataTrackId, { 'data-groupid': dataGroupId });
    }
  };

  const changeEditableMode = (editable) => {
    setEditableMode(editable);
    if (onChangeEditable) {
      onChangeEditable(editable);
    }
  };

  const openInlineEdit = () => {
    const { openTrackId } = trackingProps;
    sendTrackingEvent(openTrackId);
    changeEditableMode(true);
  };

  const handleSubmit = () => {
    const { submitTrackId } = trackingProps;
    const submitValue = value.trim();
    sendTrackingEvent(submitTrackId);
    setValue(submitValue);
    onSubmitEdit(submitValue);
    changeEditableMode(false);
  };

  // Check if the field is not empty before close input and call api
  const handleBlur = () => {
    const form = jquery(`#${ID_EDITABLE_INLINE_FORM}`);
    const isValid = form[0].reportValidity();
    if (isValid) {
      handleSubmit();
    }
  };

  const handleCancelSubmit = () => {
    const { cancelTrackId } = trackingProps;
    sendTrackingEvent(cancelTrackId);
    changeEditableMode(false);
    setValue(text);
  };

  const onKeyDown = (e) => {
    switch (e.keyCode) {
      case KeyboardButton.ESC:
        handleCancelSubmit();
        break;
      default:
        break;
    }
  };

  return (
    <>
      {
        isEditableMode ?
          <Form id={ID_EDITABLE_INLINE_FORM} onSubmit={handleSubmit} onBlur={handleBlur}>
            <Input
              autoFocus
              fullWidth
              type="text"
              value={value}
              className={className}
              onChange={handleChange}
              onKeyDown={onKeyDown}
              maxLength={maxLength}
              pattern="^$|.*\S+.*" // avoid only space string
              required
              {...props}
            />
          </Form>
          :
          children && (
            <div className="mr-5">
              {children}
              <TooltipComponent text={editButtonTooltip} placement="bottom-end" arrow>
                <span>
                  <IconButton title="" onClick={openInlineEdit} className="rounded">
                    <IconPencil />
                  </IconButton>
                </span>
              </TooltipComponent>
            </div>
          )
      }
    </>

  );
}

EditableField.propTypes = {
  children: PropTypes.element.isRequired,
  text: PropTypes.oneOf([string, number]),
  editButtonTooltip: PropTypes.string,
  onSubmitEdit: PropTypes.func,
  /**
   * This function will help us to work with editable state from outside
   * Ex: handle hide and show another html tag near this component on input field rendering
   */
  onChangeEditable: PropTypes.func,
  className: PropTypes.string,
  maxLength: PropTypes.number,
  trackingProps: PropTypes.object,
};

export default EditableField;
