import React from 'react';
import PropTypes from 'prop-types';
import { Histogram } from '@ant-design/plots';
import { Typography } from '@mui/material';
import { t } from '../../i18n/t';
import { IconStatus } from '../../images/KitIcons';
import ChartConstants from '../../utils/ChartConstants';

const FlakyDistributionChart = ({ data }) => {
  const bindWidth = 5;

  const renderHeader = (title, color) => (
    <div className="d-flex mr-4 align-items-center">
      <div className="d-flex justify-content-between mb-1">
        <IconStatus className="mr-1" key={title} fill={color} size="0.8rem" />
      </div>
      <Typography variant="subtitle1" className="mb-1">{title}</Typography>
    </div>
  );

  const renderItem = (value) => (
    <div className="d-flex justify-content-between mb-2">
      <div className="d-flex mr-4 align-items-center">
        {t('number-of-test-case')}:
      </div>
      {value}
    </div>
  );

  const config = {
    data,
    binWidth: bindWidth,
    binField: 'value',
    tooltip: {
      title: (title) => {
        const items = title.split(',');
        return `${items[0]}% - ${items[1]}% Flakiness`;
      },
      customContent: (title, items) => {
        const color = items[0]?.color;
        const value = items[0]?.value;
        return (
          <div>
            {renderHeader(title, color)}
            {renderItem(value)}
          </div>
        );
      }
    },
    xAxis: {
      title: {
        text: t('flakiness-%'),
        style: {
          fontWeight: 500,
          fontSize: 12
        }
      },
      max: 100,
      min: 0,
      tickInterval: 5,
      tickLine: {
        length: 0
      },
      top: true,
    },
    yAxis: {
      title: {
        text: t('number-of-test-case'),
        style: {
          fontWeight: 500,
          fontSize: 12
        }
      }
    },
    colorField: 'type',
    color: ({ range }) => {
      const startOfRange = range[0];
      const flakinessColors = ChartConstants.flakinessColors;
      return flakinessColors[startOfRange / bindWidth];
    },
  };
  return <Histogram {...config} />;
};

FlakyDistributionChart.propTypes = {

  /**
   * Exs:
   * [
       {
          value: 1.2,
        },
       {
          value: 3.4,
        },
       {
          value: 3.7,
        },
       {
          value: 4.3,
        }
      ]
   */
  data: PropTypes.array.isRequired
};

export default FlakyDistributionChart;
