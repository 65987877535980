import React, { useEffect, useState } from 'react';
import { Container } from 'reactstrap';
import Box from '@mui/material/Box';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import ListItemWithIcon from './ListItemWithIcon';

function NestedMenu({ options, numOfLevels, handleSelectedItem, disabledItems, singleSelectionMode = false }) {
  const [data, setData] = useState([]);
  const [selected, setSelected] = useState([]);

  const buildMenuLevel = (list, selectedLevel = 0) => {
    if (isEmpty(list)) {
      return [];
    }
    const result = [];
    for (let i = selectedLevel; i < numOfLevels; i++) {
      result.push(list);
      if (i < numOfLevels - 1) {
        list = list[0]?.children;
      }
    }
    return result;
  };

  const initSelectedItem = () => {
    const list = [];
    for (let i = 0; i < numOfLevels - 1; i++) { // Hide selected background for the latest level
      list.push({ selectedIndex: 0, level: i });
    }
    return list;
  };

  const changeSelectedItem = (value, level, index) => {
    if (level < numOfLevels - 1) { // Hide selected background for the latest level
      selected[level] = { selectedIndex: index, level };
    }
    for (let i = level + 1; i < numOfLevels - 1; i++) {
      selected[i] = { selectedIndex: 0, level: 0 };
    }
    setSelected(selected);
  };

  const onChange = (value, level, index) => {
    setData([...data.slice(0, level + 1), ...buildMenuLevel(value.children, level + 1)]);
    changeSelectedItem(value, level, index);
    if (value.children && Array.isArray(value.children) && value.children.length === 0) { // the last layer
      handleSelectedItem(value, level, index);
    }
  };

  useEffect(() => {
    setData(buildMenuLevel(options));
    setSelected(initSelectedItem());
  }, [options]); // eslint-disable-line

  return (
    <Container className="p-0">
      <Box sx={{ display: 'flex' }}>
        {
            data.map((item, index) => (
              <ListItemWithIcon
                list={item}
                level={index}
                onChange={onChange}
                selectedIndex={selected[index]?.selectedIndex}
                disabledItems={disabledItems}
                singleSelectionMode={singleSelectionMode}
                isNotShowIcon={index === numOfLevels - 1}
              />
            ))
        }
      </Box>
    </Container>
  );
}

NestedMenu.propTypes = {
  options: PropTypes.array.isRequired,
  numOfLevels: PropTypes.number.isRequired,
  handleSelectedItem: PropTypes.func.isRequired,
  disabledItems: PropTypes.array,
  singleSelectionMode: PropTypes.bool,
};

NestedMenu.defaultProps = {
  disabledItems: [],
  singleSelectionMode: false,
};

export default NestedMenu;
