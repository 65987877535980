import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Select from './Select';
import Services from '../utils/Services';
import { t } from '../i18n/t';
import { ApiKey } from '../models/model/ApiKey';
import { ApiKeyOption } from '../models/model/ApiKeyOption';

function SelectApiKeyComponent(props: {
  onChange: (apiKey: ApiKeyOption) => void;
}) {
  const { onChange } = props;
  const [apiKeys, setApiKeys] = useState<ApiKeyOption[]>([]);
  const [apiKey, setApiKey] = useState<ApiKeyOption>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getApiKeys = (value: string) => {
    setIsLoading(true);
    const params = { name: value };
    return Services.getApiKeys(params)
      .then((response) => {
        const apiKeys: ApiKeyOption[] = response.map(({ key, name } : ApiKey) => ({
          value: key,
          label: name,
        }));
        setIsLoading(false);
        setApiKeys(apiKeys);
        return apiKeys;
      });
  };

  const selectApiKey = (apiKey: ApiKeyOption) => {
    setApiKey(apiKey);
    onChange(apiKey);
  };

  const handleInput = (value: string, actionMeta: any) => {
    if (actionMeta.action === 'input-change') {
      setApiKeys([]);
      getApiKeys(value);
    }
  };

  const selectProps = {
    clearable: false,
    id: 'apiKey',
    value: apiKey,
    onChange: selectApiKey,
    options: apiKeys,
    placeholder: t('api_key#choose'),
    onInputChange: handleInput,
    isLoading,
    isSearchable: true,
  };

  useEffect(() => {
    getApiKeys('')
      .then((apiKeys) => selectApiKey(apiKeys[0]));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Select {...selectProps} />
  );
}

SelectApiKeyComponent.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default SelectApiKeyComponent;
