import React, { Component } from 'react';
import PropTypes from 'prop-types';
import StatusBar from '../../../components/chart/StatusBar';

class ProgressBar extends Component {
  renderProgressBar(data) {
    const { label } = this.props;

    let total = 0;
    data.forEach((item) => {
      total += item.value;
    });

    const totalItem = {
      label,
      value: total,
      isTotal: true,
    };

    return (
      <StatusBar
        items={[totalItem, ...data]}
        totalValue={total}
        {...this.props}
      />
    );
  }

  render() {
    const { data, primary, secondary, processBarStyle, primaryStyle, barStatusStyle, secondaryStyle } = this.props;
    return (
      <div className={processBarStyle}>
        <div className={primaryStyle}>{primary}</div>
        <div className={barStatusStyle}>{this.renderProgressBar(data)}</div>
        <div className={secondaryStyle}>{secondary}</div>
      </div>
    );
  }
}

ProgressBar.propTypes = {
  data: PropTypes.array.isRequired,
  primary: PropTypes.any,
  secondary: PropTypes.any,
  processBarStyle: PropTypes.string,
  primaryStyle: PropTypes.string,
  barStatusStyle: PropTypes.string,
  secondaryStyle: PropTypes.string,
};

ProgressBar.defaultProps = {
  data: [], // [{label: StatusConstants.PASSED.label, value: 3, color: 'passed'}];
  primary: null,
  secondary: null,
  processBarStyle: 'process-bar',
  primaryStyle: 'primary',
  barStatusStyle: 'bar-status',
  secondaryStyle: 'secondary',
};

export default ProgressBar;
