import React, { useState, useEffect } from 'react';
import { Label, FormGroup, Row, Col, Form } from 'reactstrap';
import Select from '../../../components/Select';
import { t } from '../../../i18n/t';
import Services from '../../../utils/Services';
import DecoratorConstants from '../../../utils/DecoratorConstants';
import XrayIssueUndefined from '../../../../images/icons/xray-undefined.svg';
import { XRAY_ISSUE_TYPE, XRAY_TESTOPS_OBJECT_TYPE } from '../../../utils/Constants';

export default function XrayIssueTypeMapping(props) {
  const { setting, setSetting } = props;
  const [externalSystemMapping, setExternalSystemMapping] = useState(setting?.externalSystemMapping);
  const [isLoading, setIsLoading] = useState(false);
  const [issueTypes, setIssueTypes] = useState([]);
  const [currentExternalProjectId, setCurrentExternalProjectId] = useState(setting?.defaultExternalProject?.externalProjectId);

  const getIconComponent = (data) => {
    const iconSrc = DecoratorConstants.getIssueIconUrl(data?.iconUrl, data?.name);
    const onImageError = (e) => {
      e.target.src = XrayIssueUndefined;
    };
    return <img src={iconSrc} width={16} height={16} alt="" onError={onImageError} />;
  };

  const getValidIssueType = (testOpsObjectType, currentMapping, issueTypes) => {
    if (!Array.isArray(currentMapping) || !Array.isArray(issueTypes)) {
      return null;
    }
    const result = currentMapping.find((e) => e.testOpsObjectType === testOpsObjectType)?.externalObjectType;
    const isValidType = !!issueTypes.find((e) => e.value === result);
    if (isValidType) {
      return result;
    }
    const updatedProjectId = setting?.defaultExternalProject?.externalProjectId;
    if (updatedProjectId !== currentExternalProjectId) {
      switch (testOpsObjectType) {
        case XRAY_TESTOPS_OBJECT_TYPE.TEST_CASE:
          return issueTypes.find((e) => e.value === XRAY_ISSUE_TYPE.TEST) ? XRAY_ISSUE_TYPE.TEST : null;
        case XRAY_TESTOPS_OBJECT_TYPE.TEST_PLAN:
          return issueTypes.find((e) => e.value === XRAY_ISSUE_TYPE.TEST_PLAN) ? XRAY_ISSUE_TYPE.TEST_PLAN : null;
        case XRAY_TESTOPS_OBJECT_TYPE.TEST_EXECUTION:
          return issueTypes.find((e) => e.value === XRAY_ISSUE_TYPE.TEST_EXECUTION) ? XRAY_ISSUE_TYPE.TEST_EXECUTION : null;
        default:
      }
    }
    return null;
  };

  const onSelect = (testOpsObjectType, externalObjectType) => {
    let externalSystemMapping = setting?.externalSystemMapping;
    if (externalSystemMapping && Array.isArray(externalSystemMapping)) {
      const needUpdatedIndex = externalSystemMapping.findIndex((e) => e.testOpsObjectType === testOpsObjectType);
      if (needUpdatedIndex === -1) {
        externalSystemMapping.push({ testOpsObjectType, externalObjectType });
      } else {
        externalSystemMapping[needUpdatedIndex] = { testOpsObjectType, externalObjectType };
      }
    } else {
      externalSystemMapping = [{ testOpsObjectType, externalObjectType }];
    }
    setSetting(externalSystemMapping);
    setExternalSystemMapping(externalSystemMapping);
  };
  const onSelectTestCase = (event) => {
    const chosenIssueType = event?.value;
    onSelect(XRAY_TESTOPS_OBJECT_TYPE.TEST_CASE, chosenIssueType);
  };
  const onSelectTestPlan = (event) => {
    const chosenIssueType = event?.value;
    onSelect(XRAY_TESTOPS_OBJECT_TYPE.TEST_PLAN, chosenIssueType);
  };
  const onSelectTestExecution = (event) => {
    const chosenIssueType = event?.value;
    onSelect(XRAY_TESTOPS_OBJECT_TYPE.TEST_EXECUTION, chosenIssueType);
  };

  const getExternalObjectType = (externalSystemMapping, testOpsObjectType) => {
    if (!Array.isArray(externalSystemMapping) || !Array.isArray(issueTypes)) {
      return null;
    }
    return externalSystemMapping?.find((e) => e.testOpsObjectType === testOpsObjectType)?.externalObjectType;
  };

  useEffect(() => {
    setIsLoading(true);
    Services.getJiraIssueTypes(setting).then((response) => {
      const newIssueTypes = response.map((data) => (
        { value: data?.name, label: data?.name, icon: getIconComponent(data) }));
      let updatedMapping = externalSystemMapping;
      const updatedProjectId = setting?.defaultExternalProject?.externalProjectId;
      if (currentExternalProjectId !== updatedProjectId) {
        updatedMapping = [{
          testOpsObjectType: XRAY_TESTOPS_OBJECT_TYPE.TEST_CASE,
          externalObjectType: XRAY_ISSUE_TYPE.TEST,
        }, {
          testOpsObjectType: XRAY_TESTOPS_OBJECT_TYPE.TEST_PLAN,
          externalObjectType: XRAY_ISSUE_TYPE.TEST_PLAN,
        }, {
          testOpsObjectType: XRAY_TESTOPS_OBJECT_TYPE.TEST_EXECUTION,
          externalObjectType: XRAY_ISSUE_TYPE.TEST_EXECUTION,
        },
        ];
      }
      updatedMapping = updatedMapping?.map((e) => {
        const testOpsObjectType = e.testOpsObjectType;
        const externalObjectType = getValidIssueType(testOpsObjectType, updatedMapping, newIssueTypes);
        return { testOpsObjectType, externalObjectType };
      });
      setCurrentExternalProjectId(updatedProjectId);
      setSetting(updatedMapping);
      setExternalSystemMapping(updatedMapping);
      setIssueTypes(newIssueTypes);
      setIsLoading(false);
    }).catch(() => {});
  }, [setting.defaultExternalProject]);

  return (
    <Row>
      <Col sm="12" md="8" lg="8" xl="8">
        <div className="note-active-connection">{t('jira-integration-note-xray-issue-type-mapping')}</div>
        <Form className={t('jira-setting#form-class')}>
          <FormGroup>
            <Label style={{ display: 'flex', flexDirection: 'row' }}>
              <p>{t('jira-integration#xray-issue-type-mapping#test-case')}</p>
              <p style={{ color: 'red', fontSize: 17, height: 13 }}>&nbsp;*</p>
            </Label>
            <Select
              isLoading={isLoading}
              isCustom
              options={issueTypes}
              onChange={onSelectTestCase}
              value={getExternalObjectType(externalSystemMapping, XRAY_TESTOPS_OBJECT_TYPE.TEST_CASE)}
              placeholder={t('jira-integration#xray-issue-type-mapping#place-holder')}
              required
            />
          </FormGroup>
          <FormGroup>
            <Label style={{ display: 'flex', flexDirection: 'row' }}>
              <p>{t('jira-integration#xray-issue-type-mapping#test-plan')}</p>
              <p style={{ color: 'red', fontSize: 17, height: 13 }}>&nbsp;*</p>
            </Label>
            <Select
              isLoading={isLoading}
              isCustom
              options={issueTypes}
              onChange={onSelectTestPlan}
              value={getExternalObjectType(externalSystemMapping, XRAY_TESTOPS_OBJECT_TYPE.TEST_PLAN)}
              placeholder={t('jira-integration#xray-issue-type-mapping#place-holder')}
              required
            />
          </FormGroup>
          <FormGroup>
            <Label style={{ display: 'flex', flexDirection: 'row' }}>
              <p>{t('jira-integration#xray-issue-type-mapping#test-execution')}</p>
              <p style={{ color: 'red', fontSize: 17, height: 13 }}>&nbsp;*</p>
            </Label>
            <Select
              isLoading={isLoading}
              isCustom
              options={issueTypes}
              onChange={onSelectTestExecution}
              value={getExternalObjectType(externalSystemMapping, XRAY_TESTOPS_OBJECT_TYPE.TEST_EXECUTION)}
              placeholder={t('jira-integration#xray-issue-type-mapping#place-holder')}
              required
            />
          </FormGroup>
        </Form>
      </Col>
    </Row>
  );
}
