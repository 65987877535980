import React from 'react';
import { Col, Row } from 'reactstrap';
import parse from 'url-parse';
import MConfigs from '../../models/MConfigs';
import DefaultLayout from '../../components/DefaultLayout';
import { t } from '../../i18n/t';
import ObjectSummary from '../../components/summary/ObjectSummary';
import CheckoutCard from '../organization/subscriptions/components/CheckoutCard';
import Services from '../../utils/Services';
import { IconTestOpsFull } from '../../images/CustomIcon';
import Routes from '../../utils/Routes';
import { FEATURE, INTERVAL, TEST_EXECUTION_UNIT } from './SubscriptionConstant';
import MAuth from '../../models/MAuth';
import PageComponent from '../../components/PageComponent';
import Input from '../../components/Input';
import DecoratorConstants from '../../utils/DecoratorConstants';
import Notification from '../../utils/Notification';
import RouteConstants from '../../utils/RouteConstants';

class TestOpsCheckoutQuote extends PageComponent {

  constructor(props) {
    super(props);
    this.meta.id = 'page-organization-testops-subscription-checkout-quote';
    this.meta.title = t('checkout');

    const { minTestExecutionOrdered } = MConfigs;

    const url = parse(window.location.href, true);
    this.uuid = url.query.token;

    this.state = {
      isShowPaymentMethod: false,
      order: {
        interval: INTERVAL.YEAR,
        testRuns: minTestExecutionOrdered,
      },
      prices: {},
      productSelected: null,
      isReady: false,
    };

    this.renderHeader = this.renderHeader.bind(this);
    this.renderBody = this.renderBody.bind(this);
    this.handleCheckout = this.handleCheckout.bind(this);
  }

  componentDidMount() {
    this.getQuote();
  }

  blockUI() {
    this.setState({
      isBlocked: true
    });
  }

  unBlockUI() {
    this.setState({
      isBlocked: false
    });
  }

  getQuote() {
    Services.getQuote(this.uuid)
      .then(({ quote, billingInformation, paymentMethod }) => {
        const organizationId = quote.organization.id;

        if (paymentMethod) {
          if (!MAuth.isLoggedIn) {
            MAuth.logout();
            return;
          }
          if (!MAuth.isBillingManager(organizationId)) {
            Notification.pushError(t('no-billing-management-permission'));
            return;
          }
        }
        const { orders, invoices, organization } = quote;

        // only get TESTOPS_BUSINESS feature from invoice and order
        const invoice = invoices.filter((invoice) => invoice.productId === FEATURE.TESTOPS_BUSINESS)[0];
        const validOrder = orders.find((o) => o.enterprisePlan.planId === invoice.planId);

        if (validOrder && invoice) {
          const { quota: testRuns, enterprisePlan, coupon } = validOrder;
          const { total, tax, subtotal } = invoice;
          const { enterpriseProduct, interval } = enterprisePlan;
          this.setState({
            order: { testRuns, coupon, interval },
            isReady: true,
            productSelected: enterpriseProduct,
            prices: { tax, total, subtotal },
            organization,
            paymentMethod,
            billingInformation
          });
        }
      });
  }

  handleCheckout(token) {
    let cardToken;
    if (token && token.token) {
      cardToken = token.token.id;
    }
    const { id: organizationId } = this.state.organization;
    this.blockUI();
    Services.checkoutQuote(cardToken, this.uuid)
      .then((invoices) => {
        const invoiceSuccess = [];
        let orderFailedName = null;
        let orderFailedError = null;
        invoices.forEach((invoice) => {
          const { errorMessage, nameProduct } = invoice;
          if (errorMessage) {
            orderFailedName = nameProduct;
            orderFailedError = errorMessage;
          } else {
            invoiceSuccess.push(invoice);
          }
        });

        const invoiceNumbers = invoiceSuccess.map((invoice) => invoice.invoiceNumber);
        if (organizationId && MAuth.isLoggedIn && MAuth.isBillingManager(organizationId)) {
          const route = new Routes({ organizationId });
          if (orderFailedError && orderFailedName) {
            window.location.href = route.organization_payment_success_link({ invoiceNumbers, orderFailedName, orderFailedError });
          } else {
            window.location.href = route.organization_payment_success_link({ invoiceNumbers });
          }
        } else {
          window.location.href = RouteConstants.testops_checkout_quote_payment_success;
        }
      })
      .catch(() => this.unBlockUI());
  }

  renderHeader() {
    const urlParams = {
      testopsSubscription: t('subscriptions'),
    };
    return (
      <ObjectSummary params={urlParams} />
    );
  }

  convertTestRun(testRun) {
    return testRun / TEST_EXECUTION_UNIT;
  }

  renderSubscription() {
    const { order, organization, productSelected } = this.state;
    const { coupon } = order;
    return (
      <div className="details">
        <IconTestOpsFull />
        <div className="header">
          {t('current-subscription')}
        </div>
        <hr />
        <div className="plan-name">
          {productSelected.name}
        </div>
        <div className="label">{t('organization')}</div>
        <div className="value">{organization.name}</div>
        <div className="label">{t('organization_id')}</div>
        <div className="value">{organization.id}</div>
        <div className="label">{t('subscription-term')}</div>
        <div className="value">
          {DecoratorConstants.billingIntervalDecorator('interval', order)} subscription
        </div>
        <div className="label">{t('number-monthly-test-result')}</div>
        <div className="value">
          <Row className="align-items-center">
            <Col xs="3">
              <div className="upto-label">{t('up-to')}</div>
            </Col>
            <Col xs="4">
              <Input
                disabled
                id="testRun"
                name="testRun"
                value={this.convertTestRun(order.testRuns)}
                type="number"
                className="text-center font-weight-bold"
              />
            </Col>
            000
          </Row>
        </div>
        <div className="value">
          {coupon &&
          <div className="discount-applied mt-2 text-success">
            <span className="discount-applied-coupon">
              {t('applied-discount-code', { coupon })}
            </span>
          </div>}
        </div>
      </div>
    );
  }

  renderBody() {
    const { paymentMethod, isShowPaymentMethod, prices, billingInformation, organization, isBlocked } = this.state;

    return (
      <div className="testops-summary">
        {this.renderSubscription()}
        <div className="checkout-info">
          <CheckoutCard
            data-trackid="testops-checkout"
            disabled={isBlocked}
            paymentMethod={paymentMethod}
            isShowPaymentMethod={isShowPaymentMethod}
            prices={prices}
            onCheckout={this.handleCheckout}
            billingInformation={billingInformation}
            isTestOps
            organizationId={organization.id}
          />
        </div>
      </div>
    );
  }

  render() {

    const { isReady } = this.state;
    if (!isReady) {
      return null;
    }

    return (
      <DefaultLayout
        renderHeader={this.renderHeader}
        renderBody={this.renderBody}
      />
    );
  }
}

export default TestOpsCheckoutQuote;
