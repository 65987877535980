import React from 'react';
import PropTypes from 'prop-types';
import { Doughnut } from 'react-chartjs-2';
import CategoryConstants from '../../utils/CategoryConstants';

class CategoryPieChart extends React.Component {
  constructor(props) {
    super(props);

    this.chartData = this.chartData.bind(this);
  }

  chartData(categoryCounts) {
    return {
      datasets: [{
        data: [
          categoryCounts.application,
          categoryCounts.automation,
          categoryCounts.environment,
          categoryCounts.unknown,
        ],
        backgroundColor: [
          CategoryConstants.APPLICATION.color,
          CategoryConstants.AUTOMATION.color,
          CategoryConstants.ENVIRONMENT.color,
          CategoryConstants.UNKNOWN.color,
        ],
      }],
      labels: [
        CategoryConstants.APPLICATION.label,
        CategoryConstants.AUTOMATION.label,
        CategoryConstants.ENVIRONMENT.label,
        CategoryConstants.UNKNOWN.label,
      ],
    };
  }

  render() {
    const {
      categoryCounts,
    } = this.props;

    const chartOptions = {
      animation: false,
      legend: {
        position: 'right',
      },
      tooltips: {
        callbacks: {
          label(tooltipItem, data) {
            const dataset = data.datasets[tooltipItem.datasetIndex];
            const total = dataset.data.reduce((previousValue, currentValue) => previousValue + currentValue);
            const currentValue = dataset.data[tooltipItem.index];
            const label = data.labels[tooltipItem.index];
            const percentage = ((currentValue / total) * 100).toFixed(2);

            return `${label}: ${percentage} %`;
          },
        },
      },
    };
    return <Doughnut data={this.chartData(categoryCounts)} options={chartOptions} />;
  }
}

CategoryPieChart.propTypes = {
  categoryCounts: PropTypes.object.isRequired,
};

export default CategoryPieChart;
