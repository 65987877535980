import React from 'react';
import {
  Alert, Button,
} from 'reactstrap';
import { Route, Switch } from 'react-router-dom';
import PageComponent from '../components/PageComponent';
import ObjectSummary from '../components/summary/ObjectSummary';
import AgentDataTable from '../components/table/AgentDataTable';
import TestCloudAgentDataTable from '../components/table/TestCloudAgentDataTable';
import { t } from '../i18n/t';
import MContext from '../models/MContext';
import Apis from '../utils/Apis';
import http from '../utils/http';
import RouteConstants from '../utils/RouteConstants';
import DefaultLayout from '../components/DefaultLayout';
import { AgentHeaderNav } from '../components/HeaderNav';
import K8SAgentDataTable from '../components/table/K8SAgentDataTable';
import PageButtonToolbar from '../components/PageButtonToolbar';
import Routes from '../utils/Routes';
import CircleCIAgentDataTable from '../components/table/CircleCIAgentDataTable';
import AgentStatusBar from '../components/chart/status-bar/AgentStatusBar';
import { analyticsPage } from '../utils/SegmentAnalytics';
import DocumentLink from '../utils/DocumentLink';
import { buildSearchCondition } from '../components/search/SearchUtils';
import MConfigs from '../models/MConfigs';
import MFlags from '../models/MFlags';
import ButtonLink from '../components/ButtonLink';
import Arrays from '../utils/Arrays';
import Services from '../utils/Services';

class Agent extends PageComponent {
  constructor(props) {
    super(props);
    this.isOnPremise = MConfigs.isOnPremise;
    this.teamId = MContext.teamId;
    this.projectId = MContext.projectId;
    this.organizationId = MContext.organizationId;


    this.meta.id = 'page-agent';
    this.meta.title = t('agent');
    this.initState();
    this.k8sAgentTableRef = React.createRef();

    this.renderHeader = this.renderHeader.bind(this);
    this.renderBody = this.renderBody.bind(this);
    this.renderK8SAgent = this.renderK8SAgent.bind(this);
    this.renderLocalAgent = this.renderLocalAgent.bind(this);
    this.renderTestCloudAgent = this.renderTestCloudAgent.bind(this);
    this.refreshK8SAgentTable = this.refreshK8SAgentTable.bind(this);
    this.renderCircleCIAgent = this.renderCircleCIAgent.bind(this);
  }

  initState() {
    this.state = {
      team: null,
      organization: null,
      isOrganizationScope: !!this.organizationId,
    };
  }

  getTeam() {
    http.get(Apis.teamId(this.teamId))
      .then((responseJson) => {
        const team = responseJson;
        this.setState({
          team,
        });
      });
  }

  getOrganization() {
    Services.getOrganization(this.organizationId)
      .then((organization) => {
        this.setState({
          organization,
          isOrganizationScope: true
        });
      });
  }

  componentDidMount() {
    const { moveAgentToOrgLevelPhase2Enabled } = MFlags;
    if (moveAgentToOrgLevelPhase2Enabled && this.organizationId) {
      this.getOrganization();
    } else {
      this.getTeam();
    }
  }

  renderObjectSummary() {
    const { team, isOrganizationScope } = this.state;
    let urlParams = {
      agent: t('agent'),
    };
    if (!isOrganizationScope) {
      urlParams = {
        ...urlParams,
        teamId: this.teamId,
        teamName: team.name,
      };
    }
    return (
      <ObjectSummary params={urlParams}>
        <AgentHeaderNav isOrganizationScope={isOrganizationScope} />
      </ObjectSummary>
    );
  }

  renderHeader() {
    return (
      <>
        {this.renderObjectSummary()}
      </>
    );
  }

  renderLocalAgent() {
    const { isOrganizationScope } = this.state;
    analyticsPage('page-agent-local');
    const routes = new Routes({ organizationId: this.organizationId || MContext.team.organizationId });
    return (
      <>
        <PageButtonToolbar>
          {MFlags.moveAgentToOrgLevelPhase2Enabled || MFlags.removeAgentSetupPageOnProjectSettingsEnabled ?
            <Button
              title={t('agent#create-local-test-env')}
              color="secondary"
              href={routes.organization_setting_test_env_installation_link}
              data-trackid="create-local-agent"
            >
              {t('agent#create-local-test-env')}
            </Button> :
            <ButtonLink
              title={t('agent#create-local-test-env')}
              color="secondary"
              href={routes.test_env_installation_link}
              data-trackid="create-local-agent"
            >
              {t('agent#create-local-test-env')}
            </ButtonLink>}
        </PageButtonToolbar>
        <AgentStatusBar />
        <AgentDataTable
          defaultSearchConditions={[
            ...Arrays.insertIf(!MFlags.moveAgentToOrgLevelPhase2Enabled, buildSearchCondition('Team.id', '=', this.teamId)),
            ...Arrays.insertIf(MFlags.moveAgentToOrgLevelPhase2Enabled && isOrganizationScope, buildSearchCondition('Organization.id', '=', this.organizationId)),
          ]}
        />
      </>
    );
  }

  renderTestCloudAgent() {
    analyticsPage('page-agent-testcloud');
    return (
      <TestCloudAgentDataTable
        defaultSearchConditions={[
          buildSearchCondition('Project.id', '=', this.projectId)
        ]}
      />
    );
  }

  refreshK8SAgentTable() {
    this.k8sAgentTableRef.current.refreshData();
  }

  renderK8SAgent() {
    const { isOrganizationScope } = this.state;
    analyticsPage('page-agent-k8s');
    const routes = new Routes({
      teamId: this.teamId,
      projectId: this.projectId,
      organizationId: this.organizationId || MContext.team.organizationId,
    });
    return (
      <>
        <Alert color="primary">
          Visit
          <a
            href={DocumentLink.SETUP_EKS_ENV}
            target="_blank"
            rel="noreferrer noopener"
          >
            {' here '}
          </a>
          to learn how to set up a Kubernetes Test Environment.
        </Alert>
        <PageButtonToolbar>
          {MFlags.moveAgentToOrgLevelPhase2Enabled ?
            <Button
              title={t('k8s-agent#create-button')}
              color="secondary"
              href={routes.organization_k8s_agent_create_link}
              data-trackid="create-k8s-environment"
            >
              {t('k8s-agent#create-button')}
            </Button> :
            <ButtonLink
              title={t('k8s-agent#create-button')}
              color="secondary"
              href={routes.k8s_agent_create_link}
              data-trackid="create-k8s-environment"
            >
              {t('k8s-agent#create-button')}
            </ButtonLink>}
        </PageButtonToolbar>
        <K8SAgentDataTable
          ref={this.k8sAgentTableRef}
          title="Agents"
          defaultSearchConditions={[
            ...Arrays.insertIf(!MFlags.moveAgentToOrgLevelPhase2Enabled, buildSearchCondition('Team.id', '=', this.teamId)),
            ...Arrays.insertIf(isOrganizationScope, buildSearchCondition('Organization.id', '=', this.organizationId)),
          ]}
        />
      </>
    );
  }

  renderCreateCircleCIAgentButton() {
    const { moveAgentToOrgLevelPhase2Enabled } = MFlags;
    if (!moveAgentToOrgLevelPhase2Enabled) {
      const routes = new Routes({
        teamId: this.teamId,
        projectId: this.projectId,
      });
      return (
        <ButtonLink
          title={t('circle-ci-agent#create-button')}
          color="secondary"
          href={routes.circle_ci_agent_create_link}
          data-trackid="create-circleci-environment"
        >
          {t('circle-ci-agent#create-button')}
        </ButtonLink>
      );
    }
    const routes = new Routes({
      organizationId: this.organizationId || MContext.team.organizationId,
    });

    return (
      <Button
        title={t('circle-ci-agent#create-button')}
        color="secondary"
        href={routes.organization_circle_ci_agent_create_link}
        data-trackid="create-circleci-environment"
      >
        {t('circle-ci-agent#create-button')}
      </Button>
    );
  }

  renderCircleCIAgent() {
    const { isOrganizationScope } = this.state;
    analyticsPage('page-agent-circleci');

    return (
      <>
        <Alert color="primary">
          Visit
          <a
            href={DocumentLink.SETUP_CIRCLE_CI_ENV}
            target="_blank"
            rel="noreferrer noopener"
          >
            {' here '}
          </a>
          to learn how to set up an CircleCI Test Environment.
        </Alert>
        <PageButtonToolbar>
          {this.renderCreateCircleCIAgentButton()}
        </PageButtonToolbar>
        <CircleCIAgentDataTable
          title="Agents"
          defaultSearchConditions={[
            ...Arrays.insertIf(!MFlags.moveAgentToOrgLevelPhase2Enabled, buildSearchCondition('Team.id', '=', this.teamId)),
            ...Arrays.insertIf(isOrganizationScope, buildSearchCondition('Organization.id', '=', this.organizationId)),
          ]}
        />
      </>
    );
  }

  renderBody() {
    const { isOnPremise } = MConfigs;
    const { isOrganizationScope } = this.state;
    return (
      <Switch>
        <Route
          exact
          path={isOrganizationScope ? RouteConstants.organization_setting_agents : RouteConstants.agent}
          component={this.renderLocalAgent}
        />
        {!isOnPremise &&
          <Route
            exact
            path={isOrganizationScope ? RouteConstants.organization_setting_k8s_agents : RouteConstants.k8s_agents}
            component={this.renderK8SAgent}
          />}
        {!isOnPremise &&
          <Route
            exact
            path={isOrganizationScope ? RouteConstants.organization_setting_circle_ci_agents : RouteConstants.circle_ci_agents}
            component={this.renderCircleCIAgent}
          />}
        <Route
          exact
          path={isOrganizationScope ? RouteConstants.organization_setting_test_cloud_agents : RouteConstants.test_cloud_agents}
          component={this.renderTestCloudAgent}
        />
      </Switch>
    );
  }

  render() {
    const { team, organization } = this.state;
    const isTeamDemo = MContext.isTeamDemo;
    if ((team && !isTeamDemo) || organization) {
      return (
        <DefaultLayout
          renderHeader={this.renderHeader}
          renderBody={this.renderBody}
        />
      );
    }
    return null;
  }
}

export default Agent;
