import React from 'react';
import {
  Button,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Alert,
} from 'reactstrap';
import _ from 'lodash';
import { notificationIds } from '../../utils/NotificationPageUtils';
import { t } from '../../i18n/t';
import MTableColumnDataMapping from '../../components/table/models/MTableColumnDataMapping';
import Services from '../../utils/Services';
import Routers from '../../utils/Routes';
import Notification from '../../utils/Notification';
import Apis from '../../utils/Apis';
import MAuth from '../../models/MAuth';
import { IconDelete, IconEdit } from '../../images/KitIcons';
import MContext from '../../models/MContext';
import http from '../../utils/http';
import { buildSearchCondition } from '../../components/search/SearchUtils';
import Input from '../../components/Input';
import DataTable from '../../components/table/DataTable';
import DecoratorConstants from '../../utils/DecoratorConstants';
import MFlags from '../../models/MFlags';

class UsersTab extends React.Component {
  email = '';
  userId = '';

  usersList = null;

  constructor(props) {
    super(props);

    this.teamId = MContext.teamId;
    this.team = MContext.team;
    this.project = MContext.project;

    this.state = {
      modal: false,
      modalUserTeam: false,
      userTeam: {
        email: '',
        firstName: '',
        fullName: '',
        lastName: '',
        role: '',
        teamId: '',
        userId: '',
      },
      removedUserName: '',
    };


    this.changeTeamRole = this.changeTeamRole.bind(this);
    this.closeRemoveUserDialog = this.closeRemoveUserDialog.bind(this);
    this.closeUserTeamDialog = this.closeUserTeamDialog.bind(this);
    this.openRemoveUserDialog = this.openRemoveUserDialog.bind(this);
    this.openUserTeamDialog = this.openUserTeamDialog.bind(this);
    this.removeUser = this.removeUser.bind(this);
    this.updateTeamRole = this.updateTeamRole.bind(this);
  }

  openRemoveUserDialog(email, id, fullName) {
    this.userId = id;
    this.email = email;
    this.setState({
      modal: true,
      removedUserName: fullName,
    });
  }

  toUserTeam(user) {
    const {
      id: userId, email, firstName, lastName, fullName, teams,
    } = user;
    const { id: teamId, role } = teams.find((team) => `${team.id}` === `${this.teamId}`);
    const userTeam = {
      email,
      firstName,
      fullName,
      lastName,
      role,
      teamId,
      userId,
    };
    return userTeam;
  }

  openUserTeamDialog(user) {
    const userTeam = this.toUserTeam(user);
    this.setState({
      modalUserTeam: true,
      userTeam,
    });
  }

  removeUser(e) {
    e.preventDefault();
    const { removedUserName } = this.state;

    Services.removeUserInTeam({ teamId: this.teamId, userId: this.userId })
      .then(() => {
        this.closeRemoveUserDialog();
        if (this.props.afterAction) {
          this.props.afterAction();
        }
        const successMessage = t('project-settings#remove-user-success-message', { userName: removedUserName });
        Notification.pushSuccess(successMessage);
        this.usersList.refreshData();
      });
  }

  changeTeamRole(e) {
    const { userTeam } = this.state;
    userTeam.role = e.target.value;
    this.setState({ userTeam });
  }

  updateTeamRole(e) {
    e.preventDefault();
    const {
      userId, teamId, role, email,
    } = this.state.userTeam;
    Services.updateTeamRole({ userId, teamId, role })
      .then(() => {
        this.closeUserTeamDialog();
        Notification.pushSuccess(`User ${email} is now an ${role}.`);
        this.usersList.refreshData();
      });
  }

  closeRemoveUserDialog() {
    this.setState({
      modal: false,
    });
  }

  closeUserTeamDialog() {
    this.setState({ modalUserTeam: false });
  }

  userHeader() {
    const { isDisableEditInTeam } = this.props;
    const isTeamManager = MAuth.isTeamManager(this.teamId);
    return [
      new MTableColumnDataMapping(t('name'), 'fullName', (name, row) => {
        if (row === null) {
          return null;
        }
        const fullName = row.fullName;
        const avatar = row.avatar;
        return DecoratorConstants.renderUserAvatarDecorator(fullName, avatar);
      }),
      new MTableColumnDataMapping(t('email'), 'email'),
      new MTableColumnDataMapping(t('role'), 'id', (name, row) => {
        const role = row.teams.find((team) => `${team.id}` === `${this.teamId}`).role;
        const capitalizedRole = _.startCase(_.toLower(role));
        return <span>{capitalizedRole}</span>;
      }),
      new MTableColumnDataMapping(
        t('action'),
        'id',
        (name, row) => {
          const { email: userEmail, id: userId, fullName } = row;
          const role = row.teams.find((team) => `${team.id}` === `${this.teamId}`).role;
          if (role === 'OWNER') {
            return null;
          } else {
            return (
              <div>
                <Button
                  title="Edit user"
                  color="link"
                  onClick={() => this.openUserTeamDialog(row)}
                  disabled={!isTeamManager || isDisableEditInTeam}
                >
                  <IconEdit />
                </Button>
                <Button
                  title="Remove user"
                  color="link"
                  onClick={() => this.openRemoveUserDialog(userEmail, userId, fullName)}
                  disabled={!isTeamManager || isDisableEditInTeam}
                >
                  <IconDelete />
                </Button>
              </div>
            );
          }
        },
        true,
      ),
    ];
  }

  getTeam() {
    http.get(Apis.teamId(this.teamId))
      .then((responseJson) => {
        const team = responseJson;
        this.setState({
          team,
        });
      });
  }

  componentDidMount() {
    this.getTeam();
  }

  renderAlert() {
    return (
      <Alert color="warning">
        {t('team_transfer_ownership_warning')}
      </Alert>
    );
  }

  render() {
    const isTeamManager = MAuth.isTeamManager(this.teamId);
    const isTeamOwner = MAuth.isTeamOwner(this.teamId);
    const { isNewOrganizationModal } = this.props;
    const roles = ['ADMIN', 'USER'];
    if (isTeamOwner) {
      roles.push('OWNER');
    }

    const {
      modal, modalUserTeam, userTeam, team, removedUserName
    } = this.state;

    if (team) {
      return (
        <>
          <DataTable
            ref={(ref) => {
              this.usersList = ref;
            }}
            entityType="UserTeam"
            sourceFieldName="content"
            defaultSearchConditions={[
              buildSearchCondition('Team.id', '=', this.teamId),
            ]}
            columnMapping={this.userHeader()}
            title={t('users')}
          />
          <Modal isOpen={modal}>
            <Form data-trackid="remove-user-team" onSubmit={this.removeUser}>
              <ModalHeader>Remove?</ModalHeader>
              <ModalBody>
                {isNewOrganizationModal ? (
                  <>Do you want to remove <strong>{removedUserName}</strong> from <strong>{this.project.name}</strong> project?</>
                ) : (
                  <>Do you want to remove <strong>{removedUserName}</strong> from <strong>{this.team.name}</strong> team?</>
                )}
              </ModalBody>
              <ModalFooter>
                <Button color="danger" type="submit">
                  {t('remove')}
                </Button>
                <Button
                  color="secondary"
                  onClick={this.closeRemoveUserDialog}
                >
                  {t('cancel')}
                </Button>
              </ModalFooter>
            </Form>
          </Modal>
          <Modal isOpen={modalUserTeam}>
            <Form data-trackid="update-teamrole" onSubmit={this.updateTeamRole}>
              <ModalHeader>Edit Current User</ModalHeader>
              <ModalBody>
                <FormGroup>
                  <Label for="userTeamFullName">Full name</Label>
                  <Input id="userTeamFullName" type="text" readOnly value={userTeam.fullName} />
                </FormGroup>
                <FormGroup>
                  <Label for="userTeamEmail">Email</Label>
                  <Input id="userTeamEmail" type="text" readOnly value={userTeam.email} />
                </FormGroup>
                <FormGroup>
                  <Label for="userTeamRole">Role</Label>
                  {userTeam.role === 'OWNER' && this.renderAlert()}
                  <Input id="userTeamRole" value={userTeam.role} type="select" onChange={this.changeTeamRole}>
                    {roles.map((role) => {
                      const capitalizedRole = _.startCase(_.toLower(role));
                      return <option key={role} value={role}>{capitalizedRole}</option>;
                    })}
                  </Input>
                </FormGroup>
              </ModalBody>
              <ModalFooter>
                <Button
                  type="submit"
                  color="primary"
                >Save
                </Button>
                <Button
                  color="secondary"
                  onClick={this.closeUserTeamDialog}
                >Cancel
                </Button>
              </ModalFooter>
            </Form>
          </Modal>
        </>
      );
    }
    return null;
  }
}

export default UsersTab;
