import ValidateJS from 'validate.js';

const formConstraints = {
  email: {
    presence: {
      allowEmpty: false, // disallow empty string, instead of only checking for null
    },
    email: true
  },
  currentPassword: {
    presence: {
      allowEmpty: false, // disallow empty string, instead of only checking for null
    },
  },
  password: {
    presence: {
      allowEmpty: false, // disallow empty string, instead of only checking for null
    },
    equality: {
      attribute: 'currentPassword',
      message: 'must differ from current password.',
      comparator: (v1, v2) => v1 !== v2,
    },
  },
  confirmPassword: {
    presence: {
      allowEmpty: false, // disallow empty string, instead of only checking for null
    },
    equality: {
      attribute: 'password',
      message: "^Password confirmation doesn't match the new password",
    },
  },
};

function validate(attributes, constraints) {
  const errors = ValidateJS.validate(attributes, constraints) || {};
  Object.entries(errors).forEach(([attribute, error]) => {
    errors[attribute] = error[0];
  });
  return errors;
}

export {
  formConstraints,
  validate,
};
