import React, { useEffect, useState } from 'react';
import { CustomInput, FormText } from 'reactstrap';

function SsoAuthSwitch(props) {
  const {
    optionValue,
    disabled,
    checked,
    id,
    label,
    onChange,
    text,
    ...rest
  } = props;
  const [isChecked, setIsChecked] = useState(false);
  useEffect(() => setIsChecked(!!checked), [checked]);

  const onChangeHandler = (event) => {
    if (optionValue) {
      setIsChecked(event.target.checked);

      if (onChange) {
        onChange(optionValue, event.target.checked, label);
      }
    }
  };

  return (
    <>
      <CustomInput
        type="switch"
        checked={isChecked}
        id={id}
        label={label}
        onChange={onChangeHandler}
        disabled={disabled}
        {...rest}
      />
      {text && (
        <FormText color="muted">
          {text}
        </FormText>
      )}
    </>
  );
}

export default SsoAuthSwitch;
