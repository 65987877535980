import React, { Component } from 'react';
import { FormControlLabel, ListItem, Radio, RadioGroup, NativeSelect } from '@mui/material';
import { Button, ButtonToolbar, Form } from 'reactstrap';
import Time from '../../../utils/Moment';
import Input from '../../Input';
import DatePicker from '../../DatePicker';
import DropDownFilter from './DropDownFilter';
import DecoratorConstants from '../../../utils/DecoratorConstants';
import { ValidationDate } from '../../../utils/Constants';

const FilterType = {
  WITH_IN: 'with-in',
  MORE_THAN: 'more-than',
  BETWEEN: 'between',
};

const TimeUnit = {
  MINUTE: 'minute',
  HOUR: 'hour',
  DAY: 'day',
  WEEK: 'week'
};

class TimeFilter extends Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleTypeChange = this.handleTypeChange.bind(this);
    this.handleChangeEvent = this.handleChangeEvent.bind(this);
    this.applyFilter = this.applyFilter.bind(this);
    this.clear = this.clear.bind(this);
    this.filterMenu = React.createRef();
    this.state = this.defaultState();
  }

  defaultState() {
    return ({
      selectedFilter: '',
      filterType: FilterType.WITH_IN,
      withIn: {
        value: '',
        unit: TimeUnit.MINUTE,
      },
      moreThan: {
        value: '',
        unit: TimeUnit.MINUTE,
      },
      between: {
        from: null,
        to: null,
      }
    });
  }

  timeUnitOptions() {
    return (
      <>
        <option value={TimeUnit.MINUTE}>minutes</option>
        <option value={TimeUnit.HOUR}>hours</option>
        <option value={TimeUnit.DAY}>days</option>
        <option value={TimeUnit.WEEK}>weeks</option>
      </>
    );
  }

  withInFilter() {
    const { filterType, withIn } = this.state;
    const { value, unit } = withIn;
    return (
      <div className="d-flex flex-nowrap align-items-baseline">
        Within the last &nbsp;
        <Input
          name="withIn.value"
          id="within-value"
          value={value}
          className="short-input"
          type="number"
          min={0}
          onChange={this.handleChangeEvent}
          required={filterType === FilterType.WITH_IN}
        />&nbsp;
        <NativeSelect
          value={unit}
          onChange={this.handleChangeEvent}
          inputProps={{
            name: 'withIn.unit',
            id: 'with-in-time-unit',
          }}
        >
          {this.timeUnitOptions()}
        </NativeSelect>
      </div>
    );
  }

  moreThanFilter() {
    const { filterType, moreThan } = this.state;
    const { value, unit } = moreThan;
    return (
      <div className="d-flex flex-nowrap align-items-baseline">
        More than &nbsp;
        <Input
          name="moreThan.value"
          id="more-than-value"
          value={value}
          className="short-input"
          type="number"
          min={0}
          onChange={this.handleChangeEvent}
          required={filterType === FilterType.MORE_THAN}
        />&nbsp;
        <NativeSelect
          value={unit}
          onChange={this.handleChangeEvent}
          inputProps={{
            name: 'moreThan.unit',
            id: 'more-than-time-unit',
          }}
        >
          {this.timeUnitOptions()}
        </NativeSelect> &nbsp;
        ago
      </div>
    );
  }

  handleChangeEvent(event) {
    const { value, name } = event.target;
    this.handleChange(value, name);
  }

  handleChange(value, name) {
    let parent, child;
    [parent, child] = name.split('.');
    this.setState((prevState) => ({
      [parent]: {
        ...prevState[parent],
        [child]: value
      }
    }));
  }

  betweenFilter() {
    const { filterType, between } = this.state;
    const { from, to } = between;

    return (
      <div className="d-flex flex-nowrap align-items-baseline normal-label">
        <div className="p-2">Between</div>
        <DatePicker
          onChange={(date) => this.handleChange(date, 'between.from')}
          id="between-from-date"
          value={from}
          inputFormat={Time.DATE_FORMAT}
          maxDate={to || Time.MAX_DATE}
          required={filterType === FilterType.BETWEEN}
        />
        <div className="p-2">and</div>
        <DatePicker
          onChange={(date) => this.handleChange(date, 'between.to')}
          id="between-to-date"
          value={to}
          inputFormat={Time.DATE_FORMAT}
          minDate={from || Time.MIN_DATE}
          required={filterType === FilterType.BETWEEN}
        />
      </div>
    );
  }

  handleTypeChange(event) {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  }

  getFormattedDate(state) {
    const { value, unit } = state;
    return Time.timeAgo(value, unit, Time.DF_DATE_FORMAT);
  }

  buildFilterValue() {
    const { filterType, between } = this.state;
    const { from, to } = between;

    switch (filterType) {
      case FilterType.WITH_IN:
        return `>=${this.getFormattedDate(this.state.withIn)}`;
      case FilterType.MORE_THAN:
        return `<=${this.getFormattedDate(this.state.moreThan)}`;
      case FilterType.BETWEEN:
        return `>=${from.format(Time.DATE_FORMAT)},<=${to.format(Time.DATE_FORMAT)}`;
      default:
        return null;
    }
  }

  buildFilterLabel() {
    const { filterType, between, withIn, moreThan } = this.state;
    const { from, to } = between;

    switch (filterType) {
      case FilterType.WITH_IN:
        return `Within the last ${withIn.value} ${DecoratorConstants.pluralize(withIn.unit, parseInt(withIn.value, 10))}`;
      case FilterType.MORE_THAN:
        return `More than ${moreThan.value} ${DecoratorConstants.pluralize(moreThan.unit, parseInt(moreThan.value, 10))} ago`;
      case FilterType.BETWEEN:
        return `Between ${from.format(Time.DATE_FORMAT)} and ${to.format(Time.DATE_FORMAT)}`;
      default:
        return null;
    }
  }

  clear() {
    this.setState(this.defaultState());
    this.filterMenu.current.applyFilter(null);
  }

  isValidDate() {
    const { filterType, between } = this.state;
    const { from, to } = between;

    if (filterType !== FilterType.BETWEEN) {
      return true;
    }

    if (from != null) {
      if (from.format(Time.DATE_FORMAT) === ValidationDate.INVALID_DATE) {
        return false;
      }
    }

    if (to != null) {
      if (to.format(Time.DATE_FORMAT) === ValidationDate.INVALID_DATE) {
        return false;
      }
    }

    return true;
  }

  applyFilter(event) {
    event.preventDefault();
    const filterValue = this.buildFilterValue();
    const filterLabel = this.buildFilterLabel();
    this.setState(
      { selectedFilter: filterLabel },
      () => this.filterMenu.current.applyFilter(filterValue)
    );
  }

  buttonLabel() {
    const { label } = this.props;
    const { selectedFilter } = this.state;
    const displayValue = selectedFilter || 'All';
    return `${label}: ${displayValue}`;
  }

  render() {
    const { filterType } = this.state;
    return (
      <DropDownFilter buttonLabel={this.buttonLabel()} {...this.props} ref={this.filterMenu}>
        <Form className="form-filter" onSubmit={this.applyFilter}>
          <ListItem>
            <RadioGroup name="filterType" onChange={this.handleTypeChange} value={filterType}>
              <FormControlLabel className="normal-label" value={FilterType.WITH_IN} control={<Radio />} label={this.withInFilter()} />
              <FormControlLabel value={FilterType.MORE_THAN} control={<Radio />} label={this.moreThanFilter()} />
              <FormControlLabel value={FilterType.BETWEEN} control={<Radio />} label={this.betweenFilter()} />
            </RadioGroup>
          </ListItem>
          <ListItem>
            <ButtonToolbar>
              <Button color="primary" type="submit" disabled={!this.isValidDate()}>
                Update
              </Button>
              <Button color="link" onClick={this.clear}>
                Clear
              </Button>
            </ButtonToolbar>
          </ListItem>
        </Form>
      </DropDownFilter>
    );
  }
}

export default TimeFilter;
