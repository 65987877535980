import { BROWSER_HEADLESS_SUFFIX } from './Constants';

export class OSDetector {
  static isWindows(osName) {
    return osName.toLowerCase().includes('windows');
  }

  static isLinux(osName) {
    return osName.toLowerCase().includes('linux');
  }

  static isMacOS(osName) {
    return osName.toLowerCase().includes('mac') || osName.toLowerCase().includes('darwin');
  }

  static isIOS(osName) {
    return osName.toLowerCase().includes('ios');
  }

  static isAndroid(osName) {
    return osName.toLowerCase().includes('android');
  }
}

export class BrowserDetector {
  static isChrome(browserName) {
    return browserName.toLowerCase().includes('chrome');
  }

  static isFirefox(browserName) {
    return browserName.toLowerCase().includes('firefox');
  }

  static isIE(browserName) {
    return browserName.toLowerCase().includes('internet explorer') || browserName.toLowerCase().includes('ie');
  }

  static isEdge(browserName) {
    return browserName.toLowerCase().includes('edge');
  }

  static isSafari(browserName) {
    return browserName.toLowerCase().includes('safari');
  }

  static isEdgeChromium(browserName) {
    return browserName.toLowerCase().includes('edge chromium');
  }

  static isChromium(browserName) {
    return browserName.toLowerCase().includes('chromium');
  }

  static isMsEdge(browserName) {
    return browserName.toLowerCase().includes('msedge');
  }

  static isChromeHeadless(browserName) {
    return browserName?.toLowerCase().startsWith('chrome') && browserName?.toLowerCase().endsWith(BROWSER_HEADLESS_SUFFIX.toLowerCase());
  }

  static isFirefoxHeadless(browserName) {
    return browserName?.toLowerCase().startsWith('firefox') && browserName?.toLowerCase().endsWith(BROWSER_HEADLESS_SUFFIX.toLowerCase());
  }
}

export class DeviceDetector {
  static isIpad(deviceType) {
    return deviceType.toLowerCase().includes('ipad');
  }

  static isTablet(deviceType) {
    return deviceType.toLowerCase().includes('tablet');
  }
}

export default {
  OSDetector,
  BrowserDetector,
  DeviceDetector,
};
