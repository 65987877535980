import React from 'react';
import { t } from '../../../i18n/t';
import MContext from '../../../models/MContext';
import LabelSummary from './LabelSummary';

class TotalDefect extends React.Component {

  constructor(props) {
    super(props);
    this.projectId = MContext.projectId;
  }

  haveTotalDefects(release) {
    const { jiraIntegrated } = this.props;
    const { releaseStatistics } = release;
    return jiraIntegrated && releaseStatistics;
  }

  renderColor(release) {
    const { releaseStatistics } = release;
    if (this.haveTotalDefects(release) && releaseStatistics.totalDefect != null) {
      return releaseStatistics.totalDefect > 0 ? 'warning' : 'success';
    }
    return 'blank';
  }

  renderContent(release) {
    const { releaseStatistics } = release;
    if (this.haveTotalDefects(release)) {
      return releaseStatistics.totalDefect != null ? releaseStatistics.totalDefect : '-';
    }
    return '-';
  }


  render() {
    const { release } = this.props;
    return (
      <LabelSummary
        labelStyle="total-defect-summary"
        label={t('totalDefect')}
        content={this.renderContent(release)}
        color={this.renderColor(release)}
      />
    );
  }

}

export default TotalDefect;

TotalDefect.propTypes = {
  release: {}
};
TotalDefect.defaultProps = {
  release: null,
};
