import React from 'react';
import PropTypes from 'prop-types';
import { Button, Form, FormGroup } from 'reactstrap';
import MContext from '../../../models/MContext';
import Services from '../../../utils/Services';
import Input from '../../../components/Input';
import Select from '../../../components/Select';
import Notification from '../../../utils/Notification';
import Routes from '../../../utils/Routes';
import MAuth from '../../../models/MAuth';
import { IconSetupProject } from '../../../images/CustomIcon';
import DocumentLink from '../../../utils/DocumentLink';
import GroupEvent from '../../../utils/track/GroupEvent';


class CreateProjectForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.organizationId = MContext.organizationId;

    this.generateTeamOptions = this.generateTeamOptions.bind(this);
    this.selectTeam = this.selectTeam.bind(this);
    this.handleProjectNameChange = this.handleProjectNameChange.bind(this);
    this.addProject = this.addProject.bind(this);
  }

  getInitialState() {
    return {
      teamOptions: [],
      selectedTeam: null,
      projectName: '',
    };
  }

  componentDidMount() {
    this.getTeam().then(() => {
      const { teamOptions } = this.state;
      const { createDefaultTeam } = this.props;
      if (createDefaultTeam || (teamOptions.length === 0 && MAuth.isOrganizationOwner(this.organizationId))) {
        this.createDefaultTeam();
      }
    });
  }

  createDefaultTeam() {
    const teamData = {
      name: 'First Team',
      organizationId: this.organizationId,
    };
    Services.createNewTeam(teamData).then(() => this.getTeam());
  }

  getTeam() {
    const params = {
      pagination: {
        page: 0,
        size: 10000,
        sorts: ['name, asc'],
      },
      conditions: [
        {
          key: 'Organization.id',
          operator: '=',
          value: this.organizationId,
        },
      ],
      type: 'Team',
    };
    return Services.search(params)
      .then(({ content }) => {
        this.generateTeamOptions(content);
      });
  }

  generateTeamOptions(teams) {
    const teamOptions = teams.map((team) => ({
      value: team,
      label: team.name,
    }));
    this.setState({
      teamOptions,
      selectedTeam: teamOptions[0]
    });
  }

  selectTeam(teamOption) {
    this.setState({ selectedTeam: teamOption });
  }

  handleProjectNameChange(event) {
    const projectName = event.target.value;
    this.setState({ projectName });
  }

  addProject(e) {
    e.preventDefault();
    const { projectName, selectedTeam } = this.state;
    if (projectName) {
      const projectData = {
        name: projectName,
        teamId: selectedTeam.value.id,
      };
      Services.createNewProject(projectData)
        .then((project) => {
          const { handleAfterCreate } = this.props;
          if (handleAfterCreate) {
            handleAfterCreate(selectedTeam.value, project);
          } else {
            Notification.pushSuccess(`Project ${project.name} was created.`);
            Routes.goToProjectPage(project.team.id, project.id);
          }
        });
    }
  }

  render() {
    const { selectedTeam, teamOptions, projectName } = this.state;
    return (
      <Form
        className="on_boarding"
        data-trackid="create-project"
        data-groupid={GroupEvent.ACCESS_REPORT}
        onSubmit={this.addProject}
      >
        <div className="on_boarding_svg">
          <IconSetupProject />
        </div>
        <h1>
          Set up the first project
        </h1>
        <div className="description">
          Name your project after choosing a team.
          <br />
          <a target="_blank" rel="noopener noreferrer" href={DocumentLink.SETUP_TEAM}>
            Create a new team
          </a>
          {' '}before setting up your project. If you have not had any
          <br />
          teams yet, your new project will be stored in the First Team.
        </div>
        <FormGroup>
          <Select
            placeholder="Select team"
            clearable={false}
            onChange={this.selectTeam}
            value={selectedTeam}
            options={teamOptions}
            required
          />
        </FormGroup>
        <FormGroup>
          <Input
            autoFocus
            required
            id="project-name"
            value={projectName}
            onChange={this.handleProjectNameChange}
            type="text"
            placeholder="Project name"
          />
        </FormGroup>
        <FormGroup>
          <Button
            id="create-project"
            type="submit"
            color="primary"
            className="btn-block"
          >
            Create
          </Button>
        </FormGroup>
      </Form>
    );
  }
}

CreateProjectForm.propTypes = {
  createDefaultTeam: PropTypes.bool,
  handleAfterCreate: PropTypes.func,
};

CreateProjectForm.defaultProps = {
  createDefaultTeam: false,
};

export default CreateProjectForm;
