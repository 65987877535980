import React, { MouseEventHandler, useState, useEffect, ReactNode } from 'react';
import {
  DialogContent,
  DialogActions,
  Tab,
  Tabs,
  Grid,
  Typography,
  AutocompleteRenderInputParams,
  Box,
  Button as ButtonMui,
  Stack
} from '@mui/material';
import { Button, Form, Label, ButtonToolbar } from 'reactstrap';
import { makeStyles } from '@mui/styles';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { closeSnackbar } from 'notistack';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { isArray, isEqual } from 'lodash';
import Icon from '@katalon-studio/katalon-ui/Icon';
import DocumentLink from '../../utils/DocumentLink';
import { IconChevronDropdown } from '../../images/CustomIcon';
import { JiraIssueOption } from '../../models/model/JiraIssueOption';
import MContext from '../../models/MContext';
import TabsDialogs from './TabsDialog';
import CloseableDialogComponent from './CloseableDialogComponent';
import TabPanel from '../../components/tab/TabPanel';
import { t } from '../../i18n/t';
import DropdownField from '../external_issue/DropdownField';
import TextField from '../TextField';
import DecoratorConstants from '../../utils/DecoratorConstants';
import { ExecutionTestResult } from '../../models/model/ExecutionTestResult';
import { ObjectType, SearchEntity } from '../../utils/Constants';
import Services from '../../utils/Services';
import Routes from '../../utils/Routes';
import { buildSearchCondition } from '../search/SearchUtils';
import CreateIssuesContent from '../external_issue/CreateIssuesContent';
import Notification from '../../utils/Notification';
import JiraIntegrationRequiredDialog from '../../pages/integrations/components/JiraIntegrationRequiredDialog';
import { IconListDropdown } from '../../images/CustomNewIcon';
import DiscardIssueDialog from './DiscardIssueDialog';
import ErrorDetailsSection from './ErrorDetailsSection';
import MFlags from '../../models/MFlags';
import { ExternalIssue } from '../../models/model/ExternalIssue';
import MatchTestResultDialog from './MatchTestResultDialog';
import SuggestedDefects from '../SuggestedDefects';
import TooltipComponent from '../TooltipComponent';
import { ExternalIssueAutomationRule } from '../../models/model/ExternalIssueAutomationRule';
import DiscardAutomationRuleDialog from './DiscardAutomationRuleDialog';
import GroupEvent from '../../utils/track/GroupEvent';
import { sendAnalyticEventForAction } from '../../utils/SegmentAnalytics';

interface ReportDefectDialogProps {
  isOpen: boolean;
  handleClose: MouseEventHandler;
  executionTestResult: ExecutionTestResult;
}

const useStyles = makeStyles(() => ({
  tabs: {
    '& .MuiTab-textColorPrimary.Mui-selected': {
      color: '#1847ca',
    },
    '& .MuiTabs-indicator': {
      backgroundColor: '#1847ca'
    },
    borderBottom: '#d6dae4 1px solid',
  },
}));

type AutomationRulePayload = Pick<ExternalIssueAutomationRule, 'name' | 'errorMessagePattern' | 'stackTracePattern'>;

function ReportDefectDialog(props: Readonly<ReportDefectDialogProps>) {

  const {
    isOpen,
    handleClose,
    executionTestResult
  } = props;


  const classes = useStyles();
  const objectType = ObjectType.EXECUTION_TEST_RESULT;
  const projectId = MContext.projectId;
  const MIN_RULE_NAME_LENGTH = 1;
  const MAX_RULE_NAME_LENGTH = 255;
  const MIN_PATTERN_LENGTH = 2;
  const MAX_PATTERN_LENGTH = 2048;

  const [tabIndex, setTabIndex] = useState(0);
  const [connection, setConnection] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedOption, setSelectedOption] = useState<JiraIssueOption | null>(null);
  const [jsonObject, setJsonObject] = useState({ fields: {} });
  const [isOpenDiscardDialog, setIsOpenDiscardDialog] = useState(false);
  const [isOpenDiscardAutomationRuleDialog, setIsOpenDiscardAutomationRuleDialog] = useState(false);
  const [isOnInitialTab, setIsOnInitialTab] = useState(true);
  const [isOpenMatchTestResultDialog, setIsOpenMatchTestResultDialog] = useState(false);
  const [isUsingErrorMessageForMatching, setIsUsingErrorMessageForMatching] = useState(false);

  const [ruleName, setRuleName] = useState<string>('');
  const [errorMessagePattern, setErrorMessagePattern] = useState<string>('');
  const [stackTracePattern, setStackTracePattern] = useState<string>('');
  const [automationRule, setAutomationRule] = useState<AutomationRulePayload>();

  const updateJsonObject = (key: string, value: JiraIssueOption) => {
    setJsonObject((prevObject: { fields: JiraIssueOption; }) => ({
      fields: {
        ...prevObject.fields,
        [key]: value,
      },
    }));
  };

  const setInitJsonObject = () => {
    setJsonObject({ fields: {} });
  };

  const getConnection = (projectId: number) => {
    const params = {
      pagination: {
        page: 0,
        size: 1,
        sorts: '[order,desc]',
      },
      conditions: [
        buildSearchCondition('Project.id', '=', projectId),
        buildSearchCondition('active', '=', 'true'),
      ],
      type: SearchEntity.ExternalConnection,
    };
    Services.search(params)
      .then(({ content }) => {
        const connection = content[0];
        if (connection) {
          setConnection(connection);
        }
      }).finally(() => {
        setIsLoading(false);
      });
  };

  const createAutomationRuleErrorHandler = (issueId: string) => () => {
    Notification.pushError(t('create-automation-rule#error-message', { issueId }), t('error'));
  };

  const createAutomationRule = (e: React.MouseEvent<Element, MouseEvent>, response: ExternalIssue) => {
    if (automationRule?.name && (automationRule?.errorMessagePattern || automationRule?.stackTracePattern)) {
      const issueId = response.issueId;
      const params = {
        projectId,
        name: automationRule.name,
        errorMessagePattern: automationRule.errorMessagePattern,
        stackTracePattern: automationRule.stackTracePattern,
        externalIssue: {
          issueId
        }
      };
      Services.createAutomationRule(params, createAutomationRuleErrorHandler(issueId))
        .then(() => {
          const linkType = tabIndex === 0 ? 'linkExistingIssue' : 'createAndLinkNewIssue';
          sendAnalyticEventForAction(
            'ra_jira_defect_automation_rule_created',
            {
              groupId: GroupEvent.JIRA_DEFECTS,
              linkType,
            },
          );
          Notification.pushSuccess(t('create-automation-rule#success-message', { ruleName }), t('success'));
          handleClose(e);
        });
    } else {
      handleClose(e);
    }
  };

  const linkExternalIssueErrorHandler = (issueId: string, testResultId: string) => () => {
    Notification.pushError(t('link-external-issue#error-message', { issueId, testResultId }), t('error'));
  };

  const linkExternalIssue = (e: React.MouseEvent<Element, MouseEvent>, issueId: string, objectId: number) => {
    const params = {
      issueId,
      objectType,
      objectId,
    };
    if (MFlags.automationRuleEnabled) {
      const testResultId = objectId.toString();
      Services.linkExternalIssueV2(projectId, params, linkExternalIssueErrorHandler(issueId, testResultId))
        .then((response) => {
          if (response) {
            sendAnalyticEventForAction(
              'ra_jira_defect_existing_issue_linked',
              {
                groupId: GroupEvent.JIRA_DEFECTS,
              },
            );
            Notification.pushSuccess(t('link-external-issue#success-message', { issueId, testResultId }), t('success'));
            createAutomationRule(e, response);
          }
        });
    } else {
      Services.linkExternalIssue(projectId, params)
        .then((response) => {
          if (response) {
            const message = t('jira-issue#defect-added');
            Notification.pushSuccess(message);
            handleClose(e);
          }
        });
    }
  };

  const resetAutomationState = () => {
    setRuleName('');
    setErrorMessagePattern('');
    setStackTracePattern('');
  };

  const resetState = () => {
    setSelectedOption(null);
    setInitJsonObject();
    setAutomationRule(undefined);
    resetAutomationState();
  };

  const resetTabState = () => {
    setSelectedOption(null);
    setInitJsonObject();
  };

  const isEditAutomationRule = automationRule !== undefined
      && Object.values(automationRule).some((prop) => prop !== undefined && prop !== '');

  const backToInitialTab = () => {
    setIsOpenDiscardAutomationRuleDialog(false);
    setIsOnInitialTab(true);
    resetAutomationState();
  };

  const handleGoBack = () => {
    const isNewAutomationRule = (!isEditAutomationRule && (ruleName || errorMessagePattern || stackTracePattern));

    const isRuleUpdated = (isEditAutomationRule && (automationRule?.name !== ruleName ||
        automationRule?.errorMessagePattern !== errorMessagePattern ||
        automationRule?.stackTracePattern !== stackTracePattern));

    if (isNewAutomationRule || isRuleUpdated) {
      setIsOpenDiscardAutomationRuleDialog(true);
    } else {
      backToInitialTab();
    }
  };

  const renderErrorMessagePatternValidity = (message: string) => {
    const errorMessagePatternInput = document.getElementById('error-message-pattern-input') as HTMLInputElement;
    errorMessagePatternInput?.setCustomValidity(message);
    errorMessagePatternInput?.reportValidity();
  };

  const updateErrorMessagePattern = (value: string) => {
    setErrorMessagePattern(value);
    if (value) {
      // clear validity message
      renderErrorMessagePatternValidity('');
    }
  };

  const updateStackTracePattern = (value: string) => {
    setStackTracePattern(value);
    if (value) {
      // clear validity message
      renderErrorMessagePatternValidity('');
    }
  };

  const hasAtLeast1Pattern = () => {
    if (!errorMessagePattern && !stackTracePattern) {
      renderErrorMessagePatternValidity(t('validate-automation-rule#null-pattern'));
      return false;
    }
    return true;
  };

  const handleSaveAutomationRule = (e: React.MouseEvent<Element, MouseEvent> | React.SyntheticEvent) => {
    e.preventDefault();
    if (hasAtLeast1Pattern()) {
      const issueId = selectedOption?.key;
      const params = {
        projectId,
        name: ruleName,
        errorMessagePattern,
        stackTracePattern,
        externalIssue: {
          issueId
        }
      };
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      Services.validateAutomationRule(params, () => {})
        .then(() => {
          setAutomationRule({
            name: ruleName,
            errorMessagePattern,
            stackTracePattern,
          });
          backToInitialTab();
        })
        .catch((error) => {
          Notification.pushError(error.message, t('error'));
        });
    }
  };

  const handleContinueEditingAutomationRule = () => {
    setIsOpenDiscardAutomationRuleDialog(false);
  };

  useEffect(() => {
    if (isOpen) {
      getConnection(projectId);
      resetState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, projectId]);

  const closeNotification = () => {
    closeSnackbar();
  };

  const renderSuccessMessage = (issueKey: string, testResultId: number, issueUrl: string) => {
    const htmlMessages =
      <div>
        {t('jira-issue')} <b>{issueKey}</b> has been created and linked to test result <b>{testResultId}</b> successfully.
        <div className="mt-1" onClick={closeNotification}>
          <a href={issueUrl} target="_blank" rel="noopener noreferrer">{t('go-to-issue')} <OpenInNewIcon className="fixed-1rem-icon" /></a>
        </div>
      </div>;

    Notification.pushSuccess(null, t('success'), 5000, htmlMessages);
  };

  const createErrorHandler = (testResultId: number) => () => {
    Notification.pushError(t('create-external-issue#error-message', { testResultId }), t('error'));
  };

  const onFormSubmit = (e: React.MouseEvent<Element, MouseEvent> | React.SyntheticEvent) => {
    e.preventDefault();
    const { id } = executionTestResult;
    // Handle link existing issue
    if (tabIndex === 0 && selectedOption && selectedOption?.key) {
      linkExternalIssue(e as React.MouseEvent<Element, MouseEvent>, selectedOption?.key, id);
    }
    // Handle create new issue
    if (tabIndex === 1) {
      const errorHandler = createErrorHandler(id);
      Services.createJiraIssue(projectId, id, objectType, jsonObject, errorHandler)
        .then((response) => {
          if (response) {
            sendAnalyticEventForAction(
              'ra_jira_defect_new_issue_created_and_linked',
              {
                groupId: GroupEvent.JIRA_DEFECTS,
              },
            );
            renderSuccessMessage(response.issueId, id, response.url);
            if (MFlags.automationRuleEnabled) {
              createAutomationRule(e as React.MouseEvent<Element, MouseEvent>, response);
            } else {
              handleClose(e as React.MouseEvent<Element, MouseEvent>);
            }
          }
        });
    }
    const linkType = tabIndex === 0 ? 'linkExistingIssue' : 'createAndLinkNewIssue';
    sendAnalyticEventForAction(
      'ra_jira_defect_link_defect_button_clicked',
      {
        groupId: GroupEvent.JIRA_DEFECTS,
        linkType,
      },
    );
  };

  const iconSelect = () => <IconChevronDropdown className="mr-2" />;

  const renderCurrentProjectSection = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const defaultProject = connection?.defaultExternalProject;
    if (defaultProject) {
      const routes = new Routes();
      const { name } = defaultProject;
      return (
        <div className="mb-3">
          <div>
            <Label for="title" className="mt-0 small fw-bold">{t('current-jira-project')}</Label>
          </div>
          <FormControl sx={{ width: '75%', backgroundColor: '#E8EBED' }} size="small" disabled>
            <Select
              id="current-jira-project"
              defaultValue={0}
              IconComponent={iconSelect}
            >
              <MenuItem value={0}>{name}</MenuItem>
            </Select>
          </FormControl>
          <div className="text-secondary mt-2">
            <small>
              {t('you-can-change-jira')}{' '}
              <a
                href={routes.jira_setting_link}
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('integration')}.
              </a>
            </small>
          </div>
        </div>
      );
    }
    return null;
  };

  const errorDetail = executionTestResult?.errorDetail;
  const errorMessage = executionTestResult?.errorMessage;

  const renderErrorDetailsSection = () => (
    <ErrorDetailsSection
      errorDetail={errorDetail}
      errorMessage={errorMessage}
    />
  );

  const toggleMatchTestResultDialog = () => {
    setIsOpenMatchTestResultDialog(!isOpenMatchTestResultDialog);
  };

  const viewMatchResultByErrorMessage = () => {
    toggleMatchTestResultDialog();
    setIsUsingErrorMessageForMatching(true);
  };

  const viewMatchResultByStackTrace = () => {
    toggleMatchTestResultDialog();
    setIsUsingErrorMessageForMatching(false);
  };

  const renderMatchTestResultDialog = () => (
    <MatchTestResultDialog
      isOpen={isOpenMatchTestResultDialog}
      handleClose={toggleMatchTestResultDialog}
      currentTestResultId={executionTestResult.id}
      errorMessagePattern={isUsingErrorMessageForMatching ? errorMessagePattern : ''}
      stackTracePattern={!isUsingErrorMessageForMatching ? stackTracePattern : ''}
    />
  );

  const isDisableStackTrace = stackTracePattern.length < 2;
  const isDisableErrorMessage = errorMessagePattern.length < 2;

  const renderViewMatchResultButton = (action: () => void, isDisable: boolean) => {
    if (!isDisable) {
      return (
        <div className="report-defect-view-match-button">
          <ButtonMui
            variant="text"
            color="secondary"
            onClick={action}
          >
            {t('view-matched-results')}
          </ButtonMui>
        </div>
      );
    }
    return (
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      <TooltipComponent text={t('match-results-tooltip')}>
        <div className="report-defect-view-match-button">
          <ButtonMui
            variant="text"
            color="secondary"
            disabled
          >
            {t('view-matched-results')}
          </ButtonMui>
        </div>
      </TooltipComponent>
    );
  };

  const renderViewMatchResultByErrorMessageButton = () => (renderViewMatchResultButton(viewMatchResultByErrorMessage, isDisableErrorMessage));

  const renderViewMatchResultByStackTraceButton = () => (renderViewMatchResultButton(viewMatchResultByStackTrace, isDisableStackTrace));

  const buildCreateRuleTooltip = (children: ReactNode, content: string) => (
    DecoratorConstants.buildButtonLinkTooltip(
      children,
      content,
      <>
        {t('learn_more')}
        <OpenInNewIcon className="ml-2 fixed-1rem-icon" />
      </>,
      DocumentLink.DEFECT_REPORT_AUTOMATIONS,
    )
  );

  useEffect(() => {
    // Initialize ruleName state with automationRule.name when component mounts
    if (isEditAutomationRule) {
      setRuleName(automationRule.name);
      if (automationRule.stackTracePattern) {
        setStackTracePattern(automationRule.stackTracePattern);
      }
      if (automationRule.errorMessagePattern) {
        setErrorMessagePattern(automationRule.errorMessagePattern);
      }
    }
  }, [isEditAutomationRule, automationRule, isOnInitialTab]);

  const renderCreateRuleSection = () => (
    <Form onSubmit={handleSaveAutomationRule} className="mt-4 mb-0">
      <div className="report-defect-section-title">
        {t('create-rule')}
        {buildCreateRuleTooltip(<Icon
          type="fa-regular"
          name="fa-question-circle"
          textSize="14px"
          className="ml-1"
          sx={{
            color: '#808b9a',
            backgroundColor: '#fff',
          }}
        />, t('create-rule-tooltip'),)}
      </div>
      <div>
        <div>
          <Label className="mt-2 mr-1 small fw-bold">{t('rule-name')}</Label>
        </div>
        <TextField
          size="small"
          variant="outlined"
          fullWidth
          placeholder={t('rule-name-placeholder')}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => setRuleName(event.target?.value)}
          value={ruleName}
          required
          inputProps={{
            minLength: MIN_RULE_NAME_LENGTH,
            maxLength: MAX_RULE_NAME_LENGTH
          }}
          id="rule-name-input"
        />
      </div>
      <div>
        <div className="position-relative">
          <Label className="mt-3 mr-1 small fw-bold">{t('error-message-pattern')}</Label>
          {renderViewMatchResultByErrorMessageButton()}
        </div>
        <TextField
          rows={2}
          size="small"
          variant="outlined"
          fullWidth
          placeholder={t('error-message-pattern-placeholder')}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => updateErrorMessagePattern(event.target?.value)}
          value={errorMessagePattern}
          inputProps={{
            minLength: MIN_PATTERN_LENGTH,
            maxLength: MAX_PATTERN_LENGTH,
          }}
          id="error-message-pattern-input"
        />
      </div>
      <div>
        <div className="position-relative">
          <Label className="mt-3 mr-1 small fw-bold">{t('stack-trace-pattern')}</Label>
          {renderViewMatchResultByStackTraceButton()}
        </div>
        <TextField
          rows={2}
          size="small"
          variant="outlined"
          fullWidth
          placeholder={t('stack-trace-pattern-placeholder')}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => updateStackTracePattern(event.target?.value)}
          value={stackTracePattern}
          inputProps={{
            minLength: MIN_PATTERN_LENGTH,
            maxLength: MAX_PATTERN_LENGTH
          }}
          id="stack-trace-pattern-input"
        />
      </div>
      <ButtonToolbar className="mt-3 mb-1 justify-content-end">
        <ButtonMui
          size="medium"
          variant="text"
          className="report-dialog-create-rule-btn"
          color="primary"
          onClick={handleGoBack}
        >
          {t('back')}
        </ButtonMui>
        <Button
          color="primary"
          variant="contained"
          type="submit"
        >
          {t('save')}
        </Button>
      </ButtonToolbar>
      <DiscardAutomationRuleDialog
        isOpen={isOpenDiscardAutomationRuleDialog}
        handleClose={handleContinueEditingAutomationRule}
        handleDiscard={backToInitialTab}
      />
    </Form>
  );

  const handleChangeTab = (_event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
    resetTabState();
  };

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const serverUrl = connection?.serverUrl;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const projectKey = connection?.defaultExternalProject?.externalProjectKey;

  const suggestionsPickerAutoCompleteUrl = !serverUrl ?
    undefined :
    `${serverUrl}/rest/api/2/issue/picker?showSubTasks=true&currentProjectId=${projectKey}&currentJQL=&query=`;

  const changeValueContributor = (_id: string, value: JiraIssueOption | JiraIssueOption[] | null) => {
    if (!isArray(value)) {
      if (value?.id === 'no-option') {
        setTabIndex(1);
      } else {
        setSelectedOption(value);
      }
    }
  };

  const renderOption = (props: React.HTMLAttributes<HTMLLIElement>, option: JiraIssueOption) => (
    <li {...props} key={option?.id}>
      <Grid container>
        <Grid item xs={12}>
          {option.id === 'no-option' || option.id === 'no-option-text' ?
            option.component :
            <div className="d-flex align-items-center">
              {DecoratorConstants.renderInputFieldIcon(option, 'img', serverUrl)}
              <Typography noWrap>
                {`${option.key} ${option.summaryText}`}
              </Typography>
            </div>}
        </Grid>
      </Grid>
    </li>
  );

  const renderInput = (params: AutocompleteRenderInputParams, selectedOption: JiraIssueOption | null, placeholder?: string, required?: boolean) => (
    <TextField
      {...params}
      required={required}
      placeholder={placeholder}
      InputProps={{
        ...params.InputProps,
        startAdornment: (
          <>
            {selectedOption && DecoratorConstants.renderInputFieldIcon(selectedOption, 'img', serverUrl)}
            {params.InputProps.startAdornment}
          </>
        )
      }}
    />
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formatData = (content: any) => (content.sections[1] ? content.sections[1].issues : []);

  const getOptionLabel = (option: JiraIssueOption) => `${option.key} ${option?.summaryText}`;

  const noOptionsComponent = (
    <Button
      color="link"
    >
      {`+ ${t('create-new-issue')}`}
    </Button>
  );

  const renderTabsComponent = () => (
    <>
      <Tabs
        className={classes.tabs}
        value={tabIndex}
        onChange={handleChangeTab}
      >
        <Tab label={t('link-existing-issue')} />
        <Tab label={t('create-new-issue')} />
      </Tabs>
      <TabPanel sx={{ padding: '12px 0px' }} value={tabIndex} index={0}>
        <div>
          {MFlags.viewSuggestedDefectEnabled &&
            (
              <>
                <Stack direction="row" spacing={1}>
                  <Label className="mt-0 text-dark-color fw-bold">{t('suggested-defects')}</Label>
                  <TooltipComponent
                    text={t('suggested-defects#tooltip')}
                    placement="bottom-start"
                    arrow
                    followCursor={undefined}
                    open={undefined}
                    disableHoverListener={undefined}
                    customClasses={undefined}
                  >
                    <div>
                      <Icon
                        type="fa-regular"
                        name="fa-question-circle"
                        textSize="14px"
                        sx={{
                          color: '#808b9a',
                          backgroundColor: '#fff',
                        }}
                      />
                    </div>
                  </TooltipComponent>
                </Stack>
                <SuggestedDefects testResultId={executionTestResult.id} />
                <Label className="text-dark-color fw-bold mt-4">{t('select-existing-issue')}</Label>
              </>
            )}
          <div>
            <Label className="mt-0 mr-1 small fw-bold">{t('jira-issue')}</Label>
            <span className="text-failed-color">*</span>
          </div>
          <DropdownField
            required={tabIndex === 0}
            id="issue-picker"
            displayField="key"
            renderOption={renderOption}
            renderInput={renderInput}
            getOptionLabel={getOptionLabel}
            formatData={formatData}
            autoCompleteUrl={suggestionsPickerAutoCompleteUrl}
            defaultQuery={projectKey}
            noOptionsComponent={noOptionsComponent}
            changeValueContributor={changeValueContributor}
          />
          <div className="d-flex justify-content-center">
            <div>
              <Box
                display="flex"
                alignItems="center"
                flexDirection="column"
                textAlign="center"
                justifyContent="center"
                sx={{
                  width: 300,
                  height: 200
                }}
              >
                {!selectedOption && (
                <>
                  <div className="mb-2">
                    <IconListDropdown />
                  </div>
                  <div className="custom-fields__empty-message">
                    {t('select-issue-link-defect')}
                  </div>
                </>
                )}
              </Box>
            </div>
          </div>
        </div>
      </TabPanel>
      <TabPanel sx={{ padding: '12px 0px' }} value={tabIndex} index={1}>
        <div>
          <div>
            <Label className="mt-0 mr-1 small fw-bold">{t('issue-type')}</Label>
            <span className="text-failed-color">*</span>
          </div>
          <CreateIssuesContent
            shouldValidate={tabIndex === 1}
            projectId={projectId}
            updateJsonObject={updateJsonObject}
            executionTestResult={executionTestResult}
            setInitJsonObject={setInitJsonObject}
            serverUrl={serverUrl}
          />
        </div>
      </TabPanel>
    </>
  );

  const handleContinueEditing = (e: React.MouseEvent<Element, MouseEvent>) => {
    if (tabIndex === 0 && selectedOption && selectedOption?.key) {
      setIsOpenDiscardDialog(!isOpenDiscardDialog);
    } else if (tabIndex === 1 && !isEqual(jsonObject, { fields: {} })) {
      setIsOpenDiscardDialog(!isOpenDiscardDialog);
    } else {
      setIsOpenDiscardDialog(false);
      handleClose(e);
    }
  };

  const handleDiscardIssue = (e: React.MouseEvent<Element, MouseEvent>) => {
    setIsOpenDiscardDialog(false);
    handleClose(e);
  };

  const handleCloseReportDefectDialog = (e: React.MouseEvent<Element, MouseEvent>) => {
    handleContinueEditing(e);
  };

  const handleCreateAutomationRule = () => {
    setIsOnInitialTab(false);
  };

  const renderDialogContent = () => (
    <span className={!isOnInitialTab ? 'd-none' : ''}>
      {renderCurrentProjectSection()}
      {renderTabsComponent()}
    </span>
  );

  const renderTabsDialog = () => (
    <TabsDialogs
      headerStyle="schedule-header"
      isOnInitialTab={isOnInitialTab}
      handleGoBack={handleGoBack}
      isOpen={isOpen}
      handleClose={handleCloseReportDefectDialog}
      showCloseButton={isOnInitialTab}
      maxWidth="sm"
      dialogName={isOnInitialTab ? t('report-defect') : t('create-automation-rule')}
    >
      <>
        <span className={isOnInitialTab ? 'd-none' : 'px-4'}>
          {renderErrorDetailsSection()}
          {renderCreateRuleSection()}
          {isOpenMatchTestResultDialog && renderMatchTestResultDialog()}
        </span>
        <Form onSubmit={onFormSubmit}>
          <DialogContent className="py-0">
            {renderDialogContent()}
          </DialogContent>
          {
            isOnInitialTab &&
            <DialogActions className="d-flex justify-content-between">
              <div className="display-evenly-flex">
                <ButtonMui
                  className="report-dialog-create-rule-btn"
                  color="primary"
                  variant="outlined"
                  onClick={handleCreateAutomationRule}
                  disabled={!!(!errorDetail && !errorMessage)}
                >
                  {isEditAutomationRule ? t('edit-automation-rule') : t('create-automation-rule')}
                </ButtonMui>
                {buildCreateRuleTooltip(<Icon
                  type="fa-regular"
                  name="fa-question-circle"
                  textSize="14px"
                  className="ml-2"
                  sx={{
                    color: '#808b9a',
                    backgroundColor: '#fff',
                  }}
                />, t('create-rule-main-tooltip'))}
              </div>
              <ButtonToolbar>
                <Button
                  color="link"
                  className="ml-3"
                  title={t('cancel')}
                  onClick={handleCloseReportDefectDialog}
                >
                  {t('cancel')}
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  className="ml-3"
                  title={t('link-defect')}
                >
                  {t('link-defect')}
                </Button>
              </ButtonToolbar>
            </DialogActions>
          }
        </Form>
        <DiscardIssueDialog
          isOpen={isOpenDiscardDialog}
          handleClose={handleContinueEditing}
          handleDiscardIssue={handleDiscardIssue}
        />
      </>
    </TabsDialogs>
  );

  const renderSingleDialog = () => (
    <CloseableDialogComponent
      isOpen={isOpen}
      handleClose={handleCloseReportDefectDialog}
      maxWidth="sm"
      title={t('report-defect')}
    >
      <Form onSubmit={onFormSubmit}>
        <DialogContent className="py-0">
          {renderCurrentProjectSection()}
          {renderTabsComponent()}
        </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            className="ml-3"
            title={t('cancel')}
            onClick={handleCloseReportDefectDialog}
          >
            {t('cancel')}
          </Button>
          <Button
            type="submit"
            color="primary"
            className="ml-3"
            title={t('link-defect')}
          >
            {t('link-defect')}
          </Button>
        </DialogActions>
      </Form>
      <DiscardIssueDialog
        isOpen={isOpenDiscardDialog}
        handleClose={handleContinueEditing}
        handleDiscardIssue={handleDiscardIssue}
      />
    </CloseableDialogComponent>
  );

  const renderDialog = () => (MFlags.automationRuleEnabled ? renderTabsDialog() : renderSingleDialog());

  if (isLoading) {
    return null;
  }

  return (
    <div>
      {connection ? (
        renderDialog()
      ) : (
        <JiraIntegrationRequiredDialog
          isOpen={isOpen}
          handleClose={handleClose}
        />)}
    </div>
  );
}

export default ReportDefectDialog;
