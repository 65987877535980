import { DialogActions, DialogContent } from '@mui/material';
import { Button, ButtonToolbar, Label, Form } from 'reactstrap';
import React, { useEffect, useState } from 'react';
import CloseableDialogComponent from '../../components/dialog/CloseableDialogComponent';
import XrayIssueInput from '../../components/XrayIssueInput';
import { TestOpsObjectType, XRAY_ISSUE_TYPE } from '../../utils/Constants';
import { t } from '../../i18n/t';
import GroupEvent from '../../utils/track/GroupEvent';
import Services from '../../utils/Services';
import MContext from '../../models/MContext';
import Notification from '../../utils/Notification';
import ReleaseVersionSelect from '../../components/smarttestscheduling/components/ReleaseVersionSelect';
import { buildSearchCondition } from '../../components/search/SearchUtils';
import MFlags from '../../models/MFlags';

function PushingToXrayDialog({ execution }) {
  const [isOpen, setOpen] = useState(false);
  const [issueId, setIssueId] = useState(null);
  const [releaseOption, setReleaseOption] = useState(null);
  const [defaultTestPlanId, setDefaultTestPlanId] = useState(null);
  const [isXrayEnabled, setIsXrayEnabled] = useState(false);
  const projectId = MContext.projectId;

  const getTestPlanId = () => {
    if (isXrayEnabled && execution.jobs && execution.jobs.length > 0 && execution.jobs[0].runConfigurationId) {
      // Get test plan id in run configuration
      Services
        .getXrayTestPlanByRunConfiguration(execution.jobs[0].runConfigurationId)
        .then(({ content }) => {
          const externalIssue = content[0];
          if (externalIssue) {
            setIssueId(externalIssue.issueId);
            setDefaultTestPlanId(externalIssue.issueId);
          }
        });
    } else {
      setIssueId(null);
    }
  };

  const getJiraConnection = () => {
    Services.getJiraConnection(projectId)
      .then(({ content }) => {
        const connection = content[0];
        const isXrayEnabled = connection && connection.enabledXray;
        setIsXrayEnabled(isXrayEnabled);
      });
  };

  useEffect(() => {
    getTestPlanId();
  }, [isXrayEnabled]);

  useEffect(() => {
    getJiraConnection();
  }, []);

  useEffect(() => {
    setIssueId(defaultTestPlanId);
    setReleaseOption(execution.release);
  }, [isOpen]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleIssueInputChange = (value) => {
    setIssueId(value);
  };

  const errorHandler = (message) => {
    Notification.pushError(message, t('invalid-issue-type'));
  };

  const handleSubmit = () => {
    const { mapCustomizedXrayIssueTypeEnabled } = MFlags;
    Services.pushExecutionToXray(execution.id, issueId, releaseOption?.externalRelease?.id, mapCustomizedXrayIssueTypeEnabled && errorHandler).then((issue) => {
      Notification
        .pushSuccess(`The new execution <a href=${issue.url} target='_blank' rel='noopener noreferrer'>${issue.issueId}</a> will take a while to be fully generated.`,
          undefined,
          4000);
      setOpen(false);
    })
    .catch(() => {});
  };

  const selectRelease = (releaseOption) => {
    setReleaseOption(releaseOption);
  };

  if (!isXrayEnabled) return null;

  return (
    <>
      <Button
        color="secondary"
        variant="outlined"
        onClick={handleOpen}
        title={t('xray#test-plan#push')}
      >
        {t('xray#test-plan#push')}
      </Button>

      <CloseableDialogComponent
        id="push-execution-to-xray-dialog"
        isOpen={isOpen}
        handleClose={handleClose}
        title={t('xray#test-plan#push')}
        maxWidth="sm"
      >
        <DialogContent>
          <Form>
            <Label>{t('xray#test-plan')}</Label>
            <XrayIssueInput
              xrayIssueType={XRAY_ISSUE_TYPE.TEST_PLAN}
              testOpsObjectType={TestOpsObjectType.TEST_PLAN}
              handleIssueInputChange={handleIssueInputChange}
              value={issueId}
              placeholder={t('xray#test-plan#placehoder')}
            />
            <ReleaseVersionSelect
              onSelectRelease={selectRelease}
              defaultSearchConditions={[
                buildSearchCondition('ExternalRelease.id', 'is not null', '')
              ]}
              selectedRelease={releaseOption}
              hasOverflowParent
            />
          </Form>
        </DialogContent>
        <DialogActions className="popup-footer justify-content-end p-0">
          <ButtonToolbar>
            <Button
              id="cancel-xray-push"
              title={t('cancel')}
              color="secondary"
              onClick={handleClose}
            >
              {t('cancel')}
            </Button>
            <Button
              id="submit-xray-push"
              title={t('submit')}
              color="primary"
              data-trackid="push-to-xray"
              data-groupid={GroupEvent.ACCESS_REPORT}
              onClick={() => handleSubmit()}
            >
              {t('submit')}
            </Button>
          </ButtonToolbar>
        </DialogActions>
      </CloseableDialogComponent>
    </>
  );
}

export default PushingToXrayDialog;
