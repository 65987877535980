import React from 'react';
import { Chip } from '@mui/material';
import PropTypes from 'prop-types';
import ChipGroup from './ChipGroup';

export default function CustomFieldChip(props) {

  const { definition, option, isRemovable, handleRemoveCustomField } = props;

  return (
    <ChipGroup className="mr-2 mb-2" isRemovable={isRemovable} handleRemove={() => handleRemoveCustomField(definition, option)}>
      <Chip
        className="custom-chip__definition"
        label={definition.displayName}
        title={definition.displayName}
      />
      <Chip
        label={option.value}
        title={option.value}
        variant="outlined"
      />
    </ChipGroup>
  );
}

CustomFieldChip.propTypes = {
  definition: PropTypes.object,
  option: PropTypes.object,
  isRemovable: PropTypes.bool,
  handleRemoveCustomField: PropTypes.func,
};

CustomFieldChip.defaultProps = {
  definition: null,
  option: null,
  isRemovable: false,
  handleRemoveCustomField: null,
};
