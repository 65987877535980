import React from 'react';
import { Col, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import PropTypes from 'prop-types';
import { Typography, Button } from '@mui/material';
import MTableColumnDataMapping from '../table/models/MTableColumnDataMapping';
import Routes from '../../utils/Routes';
import DecoratorConstants from '../../utils/DecoratorConstants';
import SimpleDataTable from '../table/SimpleDataTable';
import mauth from '../../models/MAuth';
import { KatalonProduct } from '../../utils/Constants';
import { toTestOpsFeature } from '../../utils/TrialRequestHelper';
import Services from '../../utils/Services';
import { trackTrialRequestEvent, TRIAL_REQUEST_TRIGGER } from '../../utils/EventTrackingHelper';
import DocumentLink from '../../utils/DocumentLink';
import { t } from '../../i18n/t';
import SuccessfullyAssignedDialog from './SuccessfullyAssignedDialog';

class ChooseOrganizationDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.toggle = this.toggle.bind(this);
    this.requestTrial = this.requestTrial.bind(this);
    this.renderModalFooter = this.renderModalFooter.bind(this);
    this.showPopUpSuccessful = this.showPopUpSuccessful.bind(this);
    this.handleClickGoToHomeButton = this.handleClickGoToHomeButton.bind(this);
  }

  getInitialState() {
    return {
      modal: false,
      isGetQuote: false,
      successModal: false,
      organization: null,
      organizationName: '',
      testOpsFeature: null,
    };
  }

  toggle(isGetQuote = false) {
    this.setState((prevState) => ({
      modal: !prevState.modal,
      isGetQuote,
    }));
  }

  handleClickGoToHomeButton() {
    const { organization, testOpsFeature } = this.state;
    return Routes.goToOrganizationHomePage(organization.id, { testOpsFeature });
  }

  getLink(organization) {
    const { id: organizationId, subscribed } = organization;
    const { isGetQuote } = this.state;
    const { query, isSubscribe, isFree } = this.props;
    const routes = new Routes({
      organizationId,
    });

    if (isFree) {
      return routes.organization_home_link;
    }
    if (isSubscribe && (query.product === KatalonProduct.TESTOPS)) {
      return routes.organization_testops_subscription_checkout;
    }
    if (isGetQuote) {
      return routes.organization_quote_new_orders(query);
    }
    if (!subscribed) {
      return routes.organization_subscription_new_orders(query);
    }
    return routes.organization_subscription;
  }

  requestTrial(organization) {
    const { feature } = this.props;
    const testOpsFeature = toTestOpsFeature(feature);
    Services.submitOrganizationTrialRequest(organization.id, testOpsFeature)
      .then(() => {
        trackTrialRequestEvent(mauth.email, organization.id, testOpsFeature, TRIAL_REQUEST_TRIGGER.WEBSITE);
        this.setState({
          modal: false,
          successModal: true,
          organization,
          organizationName: organization.name,
          testOpsFeature,
        });
      });
  }

  showPopUpSuccessful() {
    const { successModal, organizationName } = this.state;
    const { plan } = this.props;
    return (
      <SuccessfullyAssignedDialog
        showPopUp={successModal}
        orgName={organizationName}
        plan={plan}
        handleOrganization={this.handleClickGoToHomeButton}
      />
    );
  }


  renderModalFooter() {
    const { isFree } = this.props;
    if (!isFree) {
      return (
          <ModalFooter className="modal-footer">
            <Button
              variant="contained"
              color="primary"
              className="btn-slt-plan"
              href={DocumentLink.KATALON_TESTOPS_PACKAGE_DETAILS}
            >
              {t('organization_choose_another')}
            </Button>
          </ModalFooter>
      );
    } else {
      return null;
    }
  }

  render() {
    const {
      modal,
      isGetQuote,
    } = this.state;

    const {
      title,
      description,
      children,
      query,
      isTrial,
      ...props
    } = this.props;

    const columnMapping = [
      new MTableColumnDataMapping(
        'ID',
        'id',
      ),
      new MTableColumnDataMapping(
        'name',
        'name',
        (name, row) => {
          if (isTrial) {
            return DecoratorConstants.idDecoratorWithHandler(name, row, this.requestTrial);
          } else {
            return DecoratorConstants.idWithoutSPADecorator(name, row, this.getLink(row));
          }
        }
      )
    ];
    const newProps = {
      columnMapping,
      ...this.props,
    };

    let organizations = mauth.user.organizations;
    if (isGetQuote) {
      organizations = organizations.filter((o) => !o.subscribed);
    }

    return (
      <>
        <Modal scrollable className="choose_organization_modal" isOpen={modal} {...props}>
          <ModalHeader className="modal-header">
            <Typography variant="h2" className="h2">{title}</Typography>
            <Typography variant="h3" className="subtitle">{description}</Typography>
          </ModalHeader>
          <ModalBody className="modal-body">
            <Col>
              <SimpleDataTable
                data={organizations}
                className="column-choose-org"
                {...newProps}
              />
            </Col>
          </ModalBody>
          {this.renderModalFooter()}
        </Modal>
        {this.showPopUpSuccessful()}
      </>
    );
  }
}

ChooseOrganizationDialog.propTypes = {
  handleApproval: PropTypes.func,
  title: PropTypes.any,
  query: PropTypes.object,
};

export default ChooseOrganizationDialog;
