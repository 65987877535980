import React from 'react';
import JobTimeline from './JobTimeline';
import { buildSearchCondition } from '../../../components/search/SearchUtils';
import Routes from '../../../utils/Routes';

function LatestJob(props) {

  const renderFooter = () => {
    const routes = new Routes();
    return (
      <div className="text-right">
        <a href={routes.jobs_link}>View all</a>
      </div>
    );
  };

  const defaultSearchConditions = [
    buildSearchCondition('status', '!=', '1'),
    buildSearchCondition('status', '!=', '0')
  ];
  return (
    <JobTimeline
      defaultSearchConditions={defaultSearchConditions}
      renderFooter={renderFooter()}
      title="Latest Jobs"
      {...props}
    />
  );
}

export default LatestJob;
