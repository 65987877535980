import React from 'react';
import { Grid } from '@mui/material';
import Tooltip from '@katalon-studio/katalon-ui/Tooltip';
import { IntegrationTypeEntity } from '../../../models/model/IntegrationTypeEntity';
import MContext from '../../../models/MContext';
import MAuth from '../../../models/MAuth';
import { t } from '../../../i18n/t';

function IntegrationBlocks(props: {
  data: IntegrationTypeEntity[],
  onBlockClick: (integrationId: string) => void
}) {

  const { data, onBlockClick } = props;

  const teamId = MContext.teamId;
  const isTeamManager = MAuth.isTeamManager(teamId);

  const renderIntegrationBlocks = () => {
    const integrationBlocks = data.map(({ id, icon, label, isTeamManager: featureForTeamManager }) => {
      const disabled = !isTeamManager && featureForTeamManager;
      const toolTip = disabled ? t('integration#restrict-access-title') : '';
      return (
        <Grid item xs={4} sm={4} md={4} key={id}>
          <Tooltip label={toolTip} size="medium">
            <div
              className={`integration-block ${disabled ? 'disabled' : ''}`}
              onClick={() => {
                if (!disabled) {
                  onBlockClick(id);
                }
              }}
            >
              <div>{icon}</div>
              <div className="ml-3">{label}</div>
            </div>
          </Tooltip>
        </Grid>
      );
    });
    return integrationBlocks;
  };

  const renderIntegrations = () => {
    const content = renderIntegrationBlocks();
    return (
      <Grid
        container
        alignItems="stretch"
        spacing={6}
        columns={{ xs: 4, sm: 4, md: 12 }}
      >
        {content}
      </Grid>
    );
  };

  return renderIntegrations();
}

export default IntegrationBlocks;
