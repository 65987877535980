import { Button, IconButton, Tooltip } from '@mui/material';
import chroma from 'chroma-js';
import _ from 'lodash';
import moment from 'moment';
import React, { useState } from 'react';
import Select from '../Select';
import colors from '../../../scss/colors.scss';
import { t } from '../../i18n/t';
import { IconStatus } from '../../images/KitIcons';
import DecoratorConstants from '../../utils/DecoratorConstants';
import Helper from '../../utils/Helper';
import Text from '../../utils/Text';
import { buildSearchCondition } from '../search/SearchUtils';
import DataTable from './DataTable';
import MTableColumnDataMapping from './models/MTableColumnDataMapping';
import { withPremiumFeature } from '../HigherOrderComponent';

const colorScale = chroma.scale([colors.failed, colors.error, colors.passed]).mode('lrgb');
const NUMBER_COLOR = 7;
const statusColors = colorScale.colors(NUMBER_COLOR);

const timeOptions = [
  {
    value: '2-month',
    label: 'Last 2 months',
  },
  {
    value: '1-month',
    label: 'Last month',
  },
  {
    value: '1-week',
    label: 'Last week',
  },
];

const TestCasePlatformStatisticsDataTable = (props) => {
  const [time, setTime] = useState('2-month');

  const columnMapping = [
    new MTableColumnDataMapping(
      t('test_cases'),
      'name',
      (name, row) => (
        <>
          <div>{DecoratorConstants.testCaseNameDecorator(name, row)}</div>
          <div>{row.path}</div>
        </>
      ),
      'short-text-column',
    ),
  ];

  const toolTipsContent = (statistics) => {
    const {
      platform,
      totalTests,
      totalPassedTests,
      totalFailedTests,
      totalErrorTests,
      totalIncompleteTests,
      totalSkippedTests
    } = statistics;
    return (
      <div className="">
        <div>
          {DecoratorConstants.platformReport(platform)}
        </div>
        <div>
          Total test: {totalTests}
        </div>
        {totalPassedTests !== 0 && <div> Passed test: {DecoratorConstants.passedCountDecorator('totalPassedTests', statistics)}</div>}
        {totalFailedTests !== 0 && <div> Failed test: {DecoratorConstants.failedCountDecorator('totalFailedTests', statistics)}</div>}
        {totalErrorTests !== 0 && <div> Error test: {DecoratorConstants.errorCountDecorator('totalErrorTests', statistics)}</div>}
        {totalIncompleteTests !== 0 && <div> Incomplete test: {DecoratorConstants.incompleteCountDecorator('totalIncompleteTests', statistics)}</div>}
        {totalSkippedTests !== 0 && <div> Skipped test: {DecoratorConstants.skippedCountDecorator('totalSkippedTests', statistics)}</div>}
      </div>
    );
  };

  const calcBubbleSize = (numberTests) => {
    const logValue = Helper.getLogBase(20, numberTests);
    const size = logValue + 1.0;
    return `${size.toFixed(2)}rem`;
  };

  const statisticBubbleDecorator = (name, row) => {
    const statistics = _.get(row, name);
    if (!statistics) return null;
    const { totalPassedTests, totalTests, totalSkippedTests } = statistics;
    const passedRatio = ((totalPassedTests + totalSkippedTests) / totalTests).toFixed(2);
    const colorIdx = Math.round(passedRatio * (NUMBER_COLOR - 1));
    const size = calcBubbleSize(totalTests);
    return (
      <Tooltip title={toolTipsContent(statistics)}>
        <IconButton size="large">
          <IconStatus fill={statusColors[colorIdx]} style={{ width: size, height: size }} />
        </IconButton>
      </Tooltip>
    );
  };

  const platformHeader = (platform) => {
    const osName = Text.platformName(platform);
    const browserName = Text.browserName(platform);
    const osIcon = DecoratorConstants.iconByOS(osName);
    const browserIcon = DecoratorConstants.iconByBrowser(browserName);
    const tooltip =
      <>
        <div>{osIcon} {osName}</div>
        <div>{browserIcon} {browserName}</div>
      </>;
    return (
      <Tooltip title={tooltip}>
        <Button>
          {DecoratorConstants.platformReport(platform)}
        </Button>
      </Tooltip>
    );
  };

  const transformData = (data, columnMapping) => {
    const platformStatistics = data.map((row) => Object.values(row.platformStatistics));
    const platforms = _.map(_.flatten(platformStatistics), 'platform');
    const uniqPlatform = _.uniqBy(platforms, 'id');
    const newColumnMapping = [
      ...columnMapping,
      ...uniqPlatform.map((platform) => {
        return new MTableColumnDataMapping(
          platformHeader(platform),
          `platformStatistics.${platform.id}`,
          statisticBubbleDecorator,
          undefined,
          'text-center'
        );
      }),
    ];
    return [data, newColumnMapping];
  };

  const renderFooter = () => {
    const colorScale = statusColors.map((color) => <IconStatus className="m-1" fill={color} />);
    return (
      <div className="d-flex justify-content-end">
        Failed {colorScale} Passed
      </div>
    );
  };

  const selectTimeRange =
    <Select
      clearable={false}
      options={timeOptions}
      onChange={({ value }) => setTime(value)}
      value={time}
    />;

  const [number, unit] = time.split('-');
  const timeValue = moment().subtract(number, unit).format();

  return (
    <DataTable
      key={`platform-statistics-${time}`}
      title={t('platform-statistics')}
      columnMapping={columnMapping}
      entityType="TestCasePlatformStatistics"
      tableId="PlatformStatistics"
      transform={transformData}
      disableFilterButton
      renderFooter={renderFooter()}
      customControl={selectTimeRange}
      defaultSearchConditions={[
        buildSearchCondition('ExecutionStatistics.startTime', '>=', timeValue)
      ]}
      defaultSort={['name,asc']}
      pageSize={10}
      {...props}
    />
  );
};
export default withPremiumFeature(TestCasePlatformStatisticsDataTable);
