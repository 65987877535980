import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ResizableBox } from 'react-resizable';
import ExpandOrCollapseButton from '../ExpandOrCollapseButton';

function ResizingDirectoryView(props) {

  const [directoryWidth, setDirectoryWidth] = useState(props.width);
  const [expanded, setExpanded] = useState(true);

  const {
    width,
    children,
    className,
    axis,
    maxConstraints,
    minConstraints,
    collapseWidth,
    useCollapseButton
  } = props;

  const handleExpand = () => {
    setExpanded(true);
    setDirectoryWidth(width);
    props.directoryWidthOnChange(width);
  };

  const handleCollapse = () => {
    setExpanded(false);
    setDirectoryWidth(minConstraints[0]);
    props.directoryWidthOnChange(minConstraints[0]);
  };

  const onResize = (event, { size }) => {
    props.directoryWidthOnChange(size.width);
    if (size.width <= collapseWidth) {
      setExpanded(false);
      setDirectoryWidth(minConstraints[0]);
    }
  };

  return (
    <div className="resizeable-directory">
      <ResizableBox
        onResize={onResize}
        width={directoryWidth}
        className={className}
        axis={axis}
        maxConstraints={maxConstraints}
        minConstraints={minConstraints}
      >
        {useCollapseButton &&
        (<ExpandOrCollapseButton
          onExpand={handleExpand}
          onCollapse={handleCollapse}
          expanded={expanded}
        />)}
        {children}
      </ResizableBox>
    </div>
  );
}

ResizingDirectoryView.propTypes = {
  children: PropTypes.any,
  /**
   * Initial width of resizeable component, will be the width when click on expand
   */
  width: PropTypes.string,
  className: PropTypes.string,
  axis: PropTypes.string,
  /**
   * Maximum width of resizeable component
   */
  maxConstraints: PropTypes.array,
  /**
   * Minumum width of resizeable component
   */
  minConstraints: PropTypes.array,
  /**
   * On resize, if the directory width is smaller than {collapseWidth} pixel, auto collapse the resizeable directory
   */
  collapseWidth: PropTypes.string,
  /**
   * Checking whether should collapseButton be rendered or not
   */
  useCollapseButton: PropTypes.bool
};

ResizingDirectoryView.defaultProps = {
  width: 280,
  className: 'box',
  axis: 'x',
  maxConstraints: [600, Infinity],
  minConstraints: [28, Infinity],
  collapseWidth: 110,
  useCollapseButton: true
};

export default ResizingDirectoryView;
