import React, { Component } from 'react';
import { Label } from 'reactstrap';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import MContext from '../../../models/MContext';
import TagFilterComponent from './TagFilterComponent';
import { sendAnalyticEventForAction } from '../../../utils/SegmentAnalytics';

class TagFilter extends Component {
  constructor(props) {
    super(props);
    this.projectId = MContext.projectId;
    this.state = {
      selectedTags: [],
    };

    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { value } = nextProps;
    const { selectedTags } = prevState;

    if (!!selectedTags && !value) {
      return {
        selectedTags: []
      };
    }
    return prevState;
  }

  handleDelete(option) {
    const { selectedTags } = this.state;
    const filteredTags = selectedTags.filter((item) => item.name !== option.name);
    this.buildFilter(null);
    this.setState({
      selectedTags: filteredTags
    }, ...filteredTags.map((tag) => this.buildFilter(get(tag, 'id'))));
  }

  buildFilter(value) {
    const { id, onBuildFilterSearch } = this.props;
    if (id && onBuildFilterSearch) {
      onBuildFilterSearch(id, value);
    }
  }

  setSelectedTags = (tags) => {
    this.setState({
      selectedTags: tags,
    });
  };

  handleSelectChange(event, selectedOptions) {
    sendAnalyticEventForAction('add-tag-to-filter-set', {});
    this.setState({
      selectedTags: selectedOptions
    }, ...selectedOptions.map((tag) => this.buildFilter(get(tag, 'id'))));
  }

  renderSectionTitle(label) {
    const { selectedTags } = this.state;
    return (
      <span className="font-weight-bold">
        <Label className="mt-0">{label}</Label>
        { selectedTags.length > 0 ?
          <span className="ml-1 custom-field-filter__badge"> {selectedTags.length} </span> :
          '' }
      </span>
    );
  }

  render() {
    const { label, placeholder, ...props } = this.props;

    const { selectedTags } = this.state;

    return (
      <div>
        {this.renderSectionTitle(label)}
        <TagFilterComponent
          handleSelectChange={this.handleSelectChange}
          handleDelete={this.handleDelete}
          selectedTags={selectedTags}
          placeholder={placeholder}
          setSelectedTags={this.setSelectedTags}
          {...props}
        />
      </div>
    );
  }
}

TagFilter.propTypes = {
  onBuildFilterSearch: PropTypes.func,
  value: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
};

TagFilter.defaultProps = {
  value: '',
  label: '',
  placeholder: '',
};

export default TagFilter;
