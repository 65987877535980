import React from 'react';
import { t } from '../i18n/t';
import {
  IconChrome,
  IconChromeHeadless,
  IconCrossBrowser,
  IconCrossBrowserGrey,
  IconDesktopBrowser,
  IconDesktopBrowserGrey,
  IconEdge,
  IconEdgeChromium,
  IconFirefox, IconFirefoxHeadless,
  IconMobileNativeAppGrey,
  IconMobileNativeAppMain,
  IconMultiPlatform,
  IconMultiPlatformGrey,
  IconPriorityHigh,
  IconPriorityHighest,
  IconPriorityLow,
  IconPriorityLowest,
  IconPriorityMedium,
  IconSafari,
  IconWebServices
} from '../images/CustomIcon';
import { VirtualDataQuestionObjectType } from './ConstantsType';
import GroupEvent from './track/GroupEvent';
import {
  KatalonStudioIntegrationIcon,
  JiraIntegrationIcon,
  KobitonIntegrationIcon,
  SlackIntegrationIcon,
  MochaIntegrationIcon,
  JestIntegrationIcon,
  JasmineIntegrationIcon,
  PytestIntegrationIcon,
  ProtractorIntegrationIcon,
  CliIntegrationIcon,
  DockerIntegrationIcon,
  GithubIntegrationIcon,
  CircleCiIntegrationIcon,
  GitlabCiIntegrationIcon,
  AwsCodeBuildIntegrationIcon,
  AzureDevopsPinelineIntegrationIcon,
  RobotIntegrationIcon,
  JenkinIntegrationIcon,
  EmailSettingIcon,
  ReportUploaderIcon
} from '../images/CustomNewIcon';

export const AppType = {
  CENTER: 'CENTER',
  CI: 'CI',
  REPORTS: 'REPORTS',
  KEYES: 'KEYES',
  CONFIGURATIONS: 'CONFIGURATIONS',
  TEST_PLANNING: 'TEST_PLANNING',
  TEST_EXECUTION: 'TEST_EXECUTION',
  TRUETEST: 'TRUETEST',
  OTHERS: 'OTHERS',
};

export const AuthenticationType = {
  BASIC_AUTH: 'BASIC_AUTH',
  BEARER_TOKEN: 'BEARER_TOKEN',
  EKS_AUTH: 'EKS_AUTH',
};

export const CloudType = {
  LOCAL_AGENT: 'LOCAL_AGENT',
  TEST_CLOUD_AGENT: 'TEST_CLOUD_AGENT',
  K8S_AGENT: 'K8S_AGENT',
  CIRCLE_CI_AGENT: 'CIRCLE_CI_AGENT',
};

export const ConfigType = {
  TSC: 'TSC',
  COMMAND: 'COMMAND',
  GENERIC_COMMAND: 'GENERIC_COMMAND',
  TS: 'TEST_SUITE'
};

export const ExecutionType = {
  G4: 'G4',
  G5: 'G5',
  GENERIC_COMMAND: 'GENERIC_COMMAND',
};

export const Framework = {
  PLAYWRIGHT: 'PLAYWRIGHT',
  SELENIUM: 'SELENIUM'
};

export const ObjectType = {
  EXECUTION_TEST_SUITE: 'EXECUTION_TEST_SUITE',
  EXECUTION_TEST_RESULT: 'EXECUTION_TEST_RESULT',
  TEST_CASE: 'TEST_CASE',
  INCIDENT: 'INCIDENT',
  KEYES_EXECUTION: 'KEYES_EXECUTION',
  TRACEABILITY: 'TRACEABILITY',
  XRAY_TEST_CASE: 'XRAY_TEST_CASE',
};

export const TestOpsObjectType = {
  TEST_CASE: 'TEST_CASE',
  TEST_PLAN: 'TEST_PLAN'
};

export const OverviewStatuses = {
  GOOD: 0,
  WARNING: 1,
  BAD: 2,
};

export const PageType = {
  KATALON: 'katalon',
  PROJECT: 'project',
  TEAM: 'team',
  USER: 'user',
  ORGANIZATION: 'organization',
  ORGANIZATION_HOME: 'organization-home',
  CHECKOUT_QUOTE: 'checkout-quote',
  TESTOPS_ORGANIZATION_SETTING: 'testops-organization-setting',
};

export const SearchEntity = {
  ExternalDefect: 'ExternalDefect',
  ExternalRequirement: 'ExternalRequirement',
  ExecutionTestResult: 'ExecutionTestResult',
  ExternalXrayTest: 'ExternalXrayTest',
  TestCase: 'TestCase',
  ExternalTraceability: 'ExternalTraceability',
  Release: 'Release',
  ExternalPriority: 'ExternalPriority',
  Build: 'Build',
  BuildStatistics: 'BuildStatistics',
  ExternalConnection: 'ExternalConnection',
  UserOrganization: 'UserOrganization',
  UserOrganizationFeature: 'UserOrganizationFeature',
  SimilarFailure: 'SimilarFailure',
  RecentProject: 'RecentProject',
  Execution: 'Execution',
  Notification: 'Notification',
  TestSuite: 'TestSuite',
  TestSuiteTestCase: 'TestSuiteTestCase',
  ExecutionProfile: 'ExecutionProfile',
  LastExecutionTestResultStatistics: 'LastExecutionTestResultStatistics',
  TestCaseFlakinessStatistics: 'TestCaseFlakinessStatistics',
  ProjectStatisticsCustomizeTime: 'ProjectStatisticsCustomizeTime',
  BaselineCollectionGroup: 'BaselineCollectionGroup',
  BaselineCollection: 'BaselineCollection',
  Baseline: 'Baseline',
  RunConfiguration: 'RunConfiguration',
  LabelLink: 'LabelLink',
  ExecutionTestResultException: 'ExecutionTestResultException',
  LastFailureReasonStatistics: 'LastFailureReasonStatistics',
  CustomFieldDefinition: 'CustomFieldDefinition',
  Tag: 'Tag',
  CustomFieldValue: 'CustomFieldValue',
  JiraIssue: 'JiraIssue',
  ExternalXrayTestPlan: 'ExternalXrayTestPlan',
  ExternalXrayTestExecution: 'ExternalXrayTestExecution',
  UserTeam: 'UserTeam',
  TestProject: 'TestProject',
  TestFolder: 'TestFolder',
  AIFeature: 'AIFeature',
  ExternalIssueAutomationRule: 'ExternalIssueAutomationRule',
  SlackConnection: 'SlackConnection',
};

export const TestSuiteType = {
  KATALON_STUDIO: 'KATALON_STUDIO',
  TESTOPS: 'TESTOPS',
  CLOUD_STUDIO: 'CLOUD_STUDIO',
};

export const OrganizationRole = {
  OWNER: 'OWNER',
  ADMIN: 'ADMIN',
  USER: 'USER',
  BILLING_MANAGER: 'BILLING_MANAGER',
};

export const OrganizationFeature = {
  KSE: 'KSE',
  UNLIMITED_KSE: 'UNLIMITED_KSE',
  ENGINE: 'ENGINE',
  UNLIMITED_ENGINE: 'UNLIMITED_ENGINE',
  TESTOPS: 'TESTOPS',
  FLOATING_ENGINE: 'FLOATING_ENGINE',
  VISUAL_TESTING_PRO: 'VISUAL_TESTING_PRO',
};

export const LicenseMode = {
  COMMUTER: 'COMMUTER',
  FLOATING: 'FLOATING',
};

export const TestProjectType = {
  KS: 'KS',
  GIT: 'GIT',
  CLOUD: 'CLOUD',
};

export const SsoOption = {
  SSO_AUTH: 'SSO_AUTH',
  BASIC_AUTH: 'BASIC_AUTH',
};

export const SsoOptionStatus = {
  PENDING: 'PENDING',
  ACTIVE: 'ACTIVE',
  DECLINED: 'DECLINED',
};

export const subDomainParam = 'subDomain';

export const dayOption = {
  value: 'day',
  label: 'Day'
};

export const weekOption = {
  value: 'week',
  label: 'Week',
};

export const monthOption = {
  value: 'month',
  label: 'Month'
};

export const DayOfWeek = {
  MONDAY: 'MONDAY',
  TUESDAY: 'TUESDAY',
  WEDNESDAY: 'WEDNESDAY',
  THURSDAY: 'THURSDAY',
  FRIDAY: 'FRIDAY',
  SATURDAY: 'SATURDAY',
  SUNDAY: 'SUNDAY'
};

export const twitterTestOps = 'KatalonTestOps';

export const ProjectStatisticsType = {
  DAILY: 'DAILY',
  WEEKLY: 'WEEKLY',
  MONTHLY: 'MONTHLY',
};

export const AmountReport = {
  DAILY: 6, // Actual showing 7 days
  WEEKLY: 11, // Actual showing 12 weeks
  MONTHLY: 5, // Actual showing 6 months
};

export const OrganizationTestOpsAdvanced = {
  ENABLED: 'ENABLED',
  DISABLED: 'DISABLED',
};

export const TestOpsFeature = {
  TESTOPS_ENTERPRISE: 'TESTOPS_ENTERPRISE',
  TESTOPS_BUSINESS: 'TESTOPS_BUSINESS',
  TESTOPS_COMMUNITY: 'TESTOPS_COMMUNITY',
};

export const PlatformPlan = {
  KATALON_ULTIMATE: 'KATALON_ULTIMATE',
  KATALON_PREMIUM: 'KATALON_PREMIUM',
  KATALON_FREE: 'KATALON_FREE',
};

export const PlatformTier = {
  PLATFORM_FREE: 'FREE',
  PLATFORM_PROFESSIONAL: 'PROFESSIONAL',
  PLATFORM_BUSINESS: 'BUSINESS',
  PLATFORM_ENTERPRISE: 'ENTERPRISE',
};

export const VisualTestingFeature = {
  VISUAL_TESTING_PRO: 'VISUAL_TESTING_PRO'
};

export const MailExecutionStatus = {
  ALL: 'ALL',
  FAILED: 'FAILED',
  ASSIGNED_MAINTAINER: 'ASSIGNED_MAINTAINER',
};

export const TestRunStatus = {
  PASSED: 'PASSED',
  FAILED: 'FAILED',
  ERROR: 'ERROR',
  INCOMPLETE: 'INCOMPLETE',
  SKIPPED: 'SKIPPED',
};

export const GitTestProjectVCSType = {
  GITHUB: 'GITHUB',
  BITBUCKET: 'BITBUCKET',
  GITLAB: 'GITLAB',
  OTHERS: 'OTHERS',
  AZURE: 'AZURE',
  AWS: 'AWS',
};

export const ProjectStatus = {
  ARCHIVE: 'ARCHIVE',
  ACTIVE: 'ACTIVE',
};

export const FrameworksVersion = {
  JUNIT4: '1.1.4',
  JUNIT5: '1.0.0',
  TESTNG: '1.1.3',
};

export const ReportUploaderType = {
  KATALON: 'katalon',
  JUNIT: 'junit',
  KATALON_RECORDER: 'katalon_recorder',
};

export const MAX_DAY_OF_MONTH = 31;

export const sizeCompanyOptions = [
  {
    value: '1',
    label: '1',
  },
  {
    value: '2-5',
    label: '2-5',
  },
  {
    value: '6-10',
    label: '6-10',
  },
  {
    value: '11-20',
    label: '11-20',
  },
  {
    value: '21-50',
    label: '21-50',
  },
  {
    value: '51+',
    label: '51+',
  }
];

export const timeAppliedTOOptions = [
  {
    value: '0-3m',
    label: 'Less than 3 months',
  },
  {
    value: '3m-6m',
    label: '3 to 6 months',
  },
  {
    value: '6m-1y',
    label: '6 months to 1 year',
  },
  {
    value: '1y-more',
    label: 'More than 1 years'
  }
];

export const productOptions = [
  {
    value: 'KSE-floating',
    label: 'Katalon Studio Enterprise (Floating)',
  },
  {
    value: 'KSE-node-locked',
    label: 'Katalon Studio Enterprise (Node-locked)',
  },
  {
    value: 'KRE-floating-devops',
    label: 'Katalon Runtime Engine (Floating/DevOps)',
  },
  {
    value: 'KRE-node-locked',
    label: 'Katalon Runtime Engine (Node-locked)',
  }
];

export const testOpsPackageType = [
  {
    value: 'Cloud',
    label: 'Cloud',
  },
  {
    value: 'On-premise',
    label: 'On-premise',
  },
];

export const organizationTrialRequestStatus = {
  PENDING: 'PENDING',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
};

export const UploadAppStatus = {
  NEW: 'NEW',
  ERROR: 'ERROR',
  READY: 'READY',
  UPLOADING: 'UPLOADING',
  PROCESSING: 'PROCESSING'
};

export const dockerType = {
  DOCKER_COMPOSE: 'DOCKER_COMPOSE',
  DOCKER_COMMAND: 'DOCKER_COMMAND',
};

export const Processor = {
  LINUX_AMD64: 'linux/amd64'
};

export const ExecutionMode = {
  SEQUENTIAL: 'SEQUENTIAL',
  PARALLEL: 'PARALLEL',
};

export const ExecutionExport = {
  TOTAL_DURATION: 10000,
  ONE_PING: 1000,
};

export const releaseDeliveryStatus = {
  DAYS_PASSED: 'day-passed',
  DAYS_LEFT: 'day-left',
};

export const KatalonProduct = {
  TESTOPS: 'TO',
};

export const KatalonTestOpsPackage = {
  ENTERPRISE: 'ENTERPRISE',
  BUSINESS: 'BUSINESS',
};

export const jiraIssuesStatus = {
  RESOLVED: 'issues-resolved',
  UNRESOLVED: 'issues-unresolved',
};
export const testCloudStatus = {
  EXPIRED: 'EXPIRED',
  TRIGGER_EXPIRED: 'TRIGGER_EXPIRED'
};

export const CacheStatus = {
  PROCESSING: 'PROCESSING',
  DONE: 'DONE',
  ERROR: 'ERROR',
};

const isFrameworksIntegration = true;
const isTeamManager = true;

export const IntegrationType = {
  KATALON_STUDIO_INTEGRATION: {
    id: 'KATALON_STUDIO_INTEGRATION',
    icon: <KatalonStudioIntegrationIcon />,
    value: 'KATALON_STUDIO_INTEGRATION',
    label: t('integrations#katalon-studio')
  },
  JIRA_INTEGRATION: {
    id: 'JIRA_INTEGRATION',
    icon: <JiraIntegrationIcon />,
    value: 'JIRA_INTEGRATION',
    label: t('integrations#jira-xray'),
    isTeamManager,
  },
  KOBITON_INTEGRATION: {
    id: 'KOBITON_INTEGRATION',
    icon: <KobitonIntegrationIcon />,
    value: 'KOBITON_INTEGRATION',
    label: t('integrations#kobiton'),
    isTeamManager,
  },
  SLACK_INTEGRATION: {
    id: 'SLACK_INTEGRATION',
    icon: <SlackIntegrationIcon />,
    value: 'SLACK_INTEGRATION',
    label: t('integrations#slack'),
    isTeamManager
  },
  EMAIL_SETTINGS: {
    id: 'EMAIL_SETTINGS',
    icon: <EmailSettingIcon />,
    value: 'EMAIL_SETTINGS',
    label: t('email'),
    isTeamManager
  },
  MOCHA: {
    id: 'MOCHA',
    icon: <MochaIntegrationIcon />,
    value: 'MOCHA',
    label: t('integrations#mocha'),
    isFrameworksIntegration,
  },
  JEST: {
    id: 'JEST',
    icon: <JestIntegrationIcon />,
    value: 'JEST',
    label: t('integrations#jest'),
    isFrameworksIntegration,
  },
  JASMINE: {
    id: 'JASMINE',
    icon: <JasmineIntegrationIcon />,
    value: 'JASMINE',
    label: t('integrations#jasmine'),
    isFrameworksIntegration
  },
  PYTEST: {
    id: 'PYTEST',
    icon: <PytestIntegrationIcon />,
    value: 'PYTEST',
    label: t('integrations#pytest'),
    isFrameworksIntegration
  },
  PROTRACTOR_JASMINE: {
    id: 'PROTRACTOR_JASMINE',
    icon: <ProtractorIntegrationIcon />,
    value: 'PROTRACTOR_JASMINE',
    label: t('integrations#protractor-jasmine'),
    isFrameworksIntegration
  },
  PROTRACTOR_MOCHA: {
    id: 'PROTRACTOR_MOCHA',
    icon: <ProtractorIntegrationIcon />,
    value: 'PROTRACTOR_MOCHA',
    label: t('integrations#protractor-mocha'),
    isFrameworksIntegration
  },
  CLI: {
    id: 'CLI',
    icon: <ReportUploaderIcon />,
    value: 'CLI',
    label: t('integrations#report-uploader')
  },
  DOCKER: {
    id: 'DOCKER',
    icon: <DockerIntegrationIcon />,
    value: 'DOCKER',
    label: t('integrations#docker')
  },
  GITHUB_ACTION: {
    id: 'GITHUB_ACTION',
    icon: <GithubIntegrationIcon />,
    value: 'GITHUB_ACTION',
    label: t('integrations#github-action')
  },
  CIRCLE_CI: {
    id: 'CIRCLE_CI',
    icon: <CircleCiIntegrationIcon />,
    value: 'CIRCLE_CI',
    label: t('integrations#circle-ci')
  },
  GITLAB_CI: {
    id: 'GITLAB_CI',
    icon: <GitlabCiIntegrationIcon />,
    value: 'GITLAB_CI',
    label: t('integrations#gitlab-ci')
  },
  AWS_CODEBUILD: {
    id: 'AWS_CODEBUILD',
    icon: <AwsCodeBuildIntegrationIcon />,
    value: 'AWS_CODEBUILD',
    label: t('integrations#aws-codebuild')
  },
  AZURE_DEVOPS_PIPELINES: {
    id: 'AZURE_DEVOPS_PIPELINES',
    icon: <AzureDevopsPinelineIntegrationIcon />,
    value: 'AZURE_DEVOPS_PIPELINES',
    label: t('integrations#azure-devops-pipelines')
  },
  JUNIT4: {
    id: 'JUNIT4',
    icon: <AzureDevopsPinelineIntegrationIcon />,
    value: 'JUNIT4',
    label: t('integrations#junit-4'),
    isFrameworksIntegration,
  },
  JUNIT5: {
    id: 'JUNIT5',
    icon: <AzureDevopsPinelineIntegrationIcon />,
    value: 'JUNIT5',
    label: t('integrations#junit-5'),
    isFrameworksIntegration,
  },
  TESTNG: {
    id: 'TESTNG',
    icon: <AzureDevopsPinelineIntegrationIcon />,
    value: 'TESTNG',
    label: t('integrations#test-ng'),
    isFrameworksIntegration,
  },
  CYPRESS: {
    id: 'CYPRESS',
    icon: <AzureDevopsPinelineIntegrationIcon />,
    value: 'CYPRESS',
    label: t('integrations#cypress'),
    isFrameworksIntegration,
  },
  ROBOT: {
    id: 'ROBOT',
    icon: <RobotIntegrationIcon />,
    value: 'ROBOT',
    label: t('integrations#robot'),
    isFrameworksIntegration,
  },
  JENKINS: {
    id: 'JENKINS',
    icon: <JenkinIntegrationIcon />,
    value: 'JENKINS',
    label: t('integrations#jenkins'),
  },
  KATALON_CLI: {
    id: 'KATALON_CLI',
    icon: <CliIntegrationIcon />,
    value: 'KATALON_CLI',
    label: t('integrations#katalon-cli'),
  },
};

export const UnicodeCharater = {
  NO_BREAK_SPACE: '\u00A0'
};
export const INTERVAL_TIME_POST_DATA = 5000;

export const REPORT_PAGE_SIZE = 12;

export const NO_AVAILABLE_MESSAGE = 'N/A';

export const ReleaseStatus = {
  NOT_READY: 'NOT_READY',
  READY: 'READY',
  EMPTY: 'EMPTY',
};

export const JiraReleaseStatus = {
  RELEASED: 'RELEASED',
  UNRELEASED: 'UNRELEASED',
};

export const IntervalUnitOptions = [
  { value: 'MINUTE', label: 'Minute(s)' },
  { value: 'HOUR', label: 'Hour(s)' },
  { value: 'DAY', label: 'Day(s)' },
  { value: 'WEEK', label: 'Week(s)' }
];

export const AccessToken = {
  PREFIX: 'access_token_',
  PARAM_NAME: 'katone_access_token',
};

export const TestRunDailyOptionsV2 = {
  SEVEN_DAYS: {
    value: 6, // Actual showing 7 days
    label: t('test-run-daily-options#past-senven-days'),
  },
  THIRTY_DAYS: {
    value: 29, // Actual showing 30 days
    label: t('test-run-daily-options#past-thirty-days'),
  },
  NINETY_DAYS: {
    value: 89, // Actual showing 90 days
    label: t('test-run-daily-options#past-ninety-days'),
  },
  TWELVE_MONTHS: {
    value: 364, // Actual showing 365 days
    label: t('test-run-daily-options#past-twelve-months'),
  }
};

export const maxDateInChart = TestRunDailyOptionsV2.NINETY_DAYS.value;

export const TestRunDailyGroupByOptions = {
  DAILY: {
    value: 'day',
    label: 'Daily',
  },
  WEEKLY: {
    value: 'week',
    label: 'Weekly',
  },
  MONTHLY: {
    value: 'month',
    label: 'Monthly',
  },
  QUARTERLY: {
    value: 'quarter',
    label: 'Quarterly',
  },
};

export const defaultGroupByTime = TestRunDailyGroupByOptions.DAILY.value;


export const TestRunDailyOptions = {
  SEVEN_DAYS: {
    value: 6, // Actual showing 7 days
    label: '7 days',
  },
  FOURTEEN_DAYS: {
    value: 13, // Actual showing 14 days
    label: '14 days',
  },
  TWENTY_EIGHT_DAYS: {
    value: 27, // Actual showing 28 days
    label: '28 days',
  }
};

export const FileType = {
  ZIP: 'application/zip',
  ANDROID_PACKAGE_ARCHIVE: 'application/vnd.android.package-archive',
  OCTET_STREAM: 'application/octet-stream',
  X_AUTHORWARE_BIN: 'application/x-authorware-bin'
};

export const WebSocketTopics = {
  TEST_CLOUD_TUNNEL: 'TestCloudTunnel',
};


export const FolderType = {
  ROOT_UPLOADED: 'root_uploaded_data',
};

export const IndexedDBKeys = {
  DATABASE: 'katalon_test_management',
  TEST_CASES: 'test_case',
};

export const TestFolderType = {
  TEST_CASE: 'TEST_CASE',
  TEST_SUITE: 'TEST_SUITE',
};

export const TEST_CASE_PAGE_SIZE = 15;

export const TEST_CASE_TEST_SUITE_PAGE_SIZE = 10;

export const TEST_SUITE_PAGE_SIZE = 15;

export const EXECUTION_TEST_SUITE_PAGE_SIZE = 10;

export const MAX_PAGE_SIZE = 100;

export const WAITING_TIME_FOR_TOAST = 3 * 1000;

export const MAX_IGNORED_ZONES = 20;

export const MAX_APP_SIZE = 1024 * 1024 * 1024;

export const MAX_APP_PAGE_SIZE = 30;

export const SubFoldersLevel = {
  NUMBER_OF_LEVEL: 3,
};

export const ValidationDate = {
  INVALID_DATE: 'Invalid date',
};

/*
 * Example:
 * String of FULL_FROM_TO: >=12/23/1999,<=12/23/1999
 * String of MISS_TO: >=12/23/1999
 * String of MISS_FROM: <=12/23/1999
 * String of INVALID_FROM: ">=Invalid date,<=12/23/1999"
 * String of INVALID_TO: ">=12/23/1999,<=Invalid date"
 */
export const RegexTimeFilterFormat = {
  FULL_FROM_TO: '^>=\\d{1,2}[/]\\d{1,2}[/]\\d+[,]<=\\d{1,2}[/]\\d{1,2}[/]\\d+$',
  MISS_FROM: '^<=\\d{1,2}[/]\\d{1,2}[/]\\d+$',
  MISS_TO: '^>=\\d{1,2}[/]\\d{1,2}[/]\\d+$',
  INVALID_FROM: '^">=Invalid date[,]<=\\d{1,2}[/]\\d{1,2}[/]\\d+"$',
  INVALID_TO: '^">=\\d{1,2}[/]\\d{1,2}[/]\\d+[,]<=Invalid date"$',
};

export const SORT_TYPE = {
  ASC: 'asc',
  DESC: 'desc',
};

export const BrowserType = {
  CHROME: 'CHROME',
  CHROME_HEADLESS: 'CHROME_HEADLESS',
  FIRE_FOX: 'FIREFOX',
  FIRE_FOX_HEADLESS: 'FIREFOX_HEADLESS',
  SAFARI: 'SAFARI',
  EDGE: 'EDGE',
  EDGE_CHROMIUM: 'EDGE_CHROMIUM',
  WEB_SERVICE: 'WEB_SERVICE',
  MOBILE_BROWSERS: 'MOBILE_BROWSERS',
  MOBILE_NATIVE: 'MOBILE_NATIVE',
  DEFAULT: 'DEFAULT',
  ALL: 'ALL'
};

export const TestCloudAgentExecutionType = {
  WEB_SERVICE: 'WEB_SERVICE'
};

export const RUN_TYPE = {
  MOBILE_BROWSERS: 'MOBILE_BROWSERS',
  MOBILE_NATIVE_APP: 'MOBILE_NATIVE_APP',
  DESKTOP_BROWSERS: 'DESKTOP_BROWSERS',
  WEB_SERVICES: 'WEB_SERVICES'
};

export const BROWSER_TYPE_HEADLESS_SUFFIX = '_HEADLESS';

export const ResultFilterType = {
  ALL_RESULTS: 'ALL_RESULTS',
  LATEST_RESULTS: 'LATEST_RESULT',
};

export const PlatformCoverageFilterType = {
  BY_TEST_RUN: 'BY_TEST_RUN',
  BY_TEST_RESULT: 'BY_TEST_RESULT',
};

export const NOT_AVAILABLE = 'N/A';

export const ALL_BROWSERS_TESTCLOUD_AGENT = {
  os: 'linux',
  browser: 'all',
  browserVersion: 'latest'
};

export const PlatformFilterType = {
  BY_OS_NAME: 'osName',
  BY_BROWSER_NAME: 'browserName',
};

export const MAXIMUM_PAGE_SIZE = 300;

export const BrowserName = {
  Chrome: t('browser-type#chrome'),
  FireFox: t('browser-type#firefox'),
  IE: t('browser-type#ie'),
  EdgeChromium: t('browser-type#edge_chromium'),
  Edge: t('browser-type#edge'),
  Safari: t('browser-type#safari'),
  Chromium: t('browser-type#chromium'),
  Others: t('others'),
};

export const DeviceType = {
  Tablet: t('device-type#tablet'),
  Phone: t('device-type#phone'),
  iPhone: t('device-type#iphone'),
  iPad: t('device-type#ipad')
};

export const OsName = {
  MacOS: t('os-name#mac-os'),
  Windows: t('os-name#windows'),
  Linux: t('os-name#linux'),
  IOS: t('os-name#iOS'),
  ANDROID: t('os-name#android'),
  Others: t('others'),
};

export const FeatureNames = {
  VISUAL_TESTING: 'VISUAL_TESTING',
  RCA_EXECUTION: 'RCA_EXECUTION',
};

export const CheckpointStatus = {
  UNRESOLVED: 'UNRESOLVED',
  PASSED: 'PASSED',
  FAILED: 'FAILED',
};

export const IgnoredZoneType = {
  ALL: 'ALL',
  SINGLE: 'SINGLE',
};

export const ID_BUTTON_DRAW_ZONES = 'draw-ignore-zones';

export const KeyboardButton = {
  ESC: 27,
  DELETE: 46,
  BACKSPACE: 8,
};

export const ID_MAIN_HEADER_TOOLBAR = 'main-header-tool-bar';

export const ID_EDITABLE_INLINE_FORM = 'editable-inline-form';

export const ID_DELETE_IGNORED_ZONE_DIALOG = 'delete-ignored-zone-dialog';

export const ID_ACKNOWLEDGE_APPLY_ALL_DIALOG = 'acknowledge-apply-all-ignored-zone';

export const XRAY_ISSUE_TYPE = {
  XRAY_TEST: 'Xray Test',
  TEST: 'Test',
  TEST_EXECUTION: 'Test Execution',
  TEST_PLAN: 'Test Plan',
  TEST_SET: 'Test Set',
  PRE_CONDITION: 'Pre-condition',
  SUB_TEST_EXECUTION: 'Sub Test Execution',
};

export const XRAY_TESTOPS_OBJECT_TYPE = {
  TEST_CASE: 'TEST_CASE',
  TEST_PLAN: 'TEST_PLAN',
  TEST_EXECUTION: 'TEST_EXECUTION',
};

export const OsType = {
  Windows: 'Windows',
  Linux: 'Linux',
  MacOs: 'MacOs',
};

export const MarketingPlatform = {
  Windows: 'win',
  Linux: 'linux',
  MacOs: 'mac',
};

export const MarketingArchitecture = {
  x86: '32',
  x64: '64',
};

export const LABEL_ENTITY_TYPE = {
  EXECUTION_TEST_RESULT: 'EXECUTION_TEST_RESULT',
};

export const FAILURE_CATEGORY = {
  UNCATEGORIZED: 'Uncategorized',
  AUTOMATION_BUG: 'Automation Bug',
  APPLICATION_BUG: 'Application Bug',
  NETWORK_THIRD_ISSUE: 'Network / Third Parties Issue'
};

export const USER_SURVEY_STATUS = {
  NOT_SUBMITTED: 'NOT_SUBMITTED',
};

export const VcsTypes = [
  {
    label: 'AWS CodeCommit',
    value: GitTestProjectVCSType.AWS
  },
  {
    label: 'Azure Repos',
    value: GitTestProjectVCSType.AZURE
  },
  {
    label: 'Bitbucket',
    value: GitTestProjectVCSType.BITBUCKET
  },
  {
    label: 'Github',
    value: GitTestProjectVCSType.GITHUB
  },
  {
    label: 'Gitlab',
    value: GitTestProjectVCSType.GITLAB
  },
  {
    label: 'Others',
    value: GitTestProjectVCSType.OTHERS
  }
];

export const USER_SURVEY_PATH = {
  MANAGER: 'manager',
  TESTER: 'tester',
};

export const RCA_SYSTEM_CATEGORY = {
  AUTOMATION_BUG: 'Automation Bug',
  APLICATION_BUG: 'Application Bug',
  NETWORK_THIRD_PARTIES: 'Network Issues / Third Parties issues',
  UNCATEGORIZED: 'Uncategorized'
};

export const SEARCH_PARAMS = {
  DOWNLOAD_POPUP: 'download_popup',
};

export const TAG_ENTITY_TYPE = {
  EXECUTION_TEST_RESULT: 'EXECUTION_TEST_RESULT',
  TEST_CASE: 'TEST_CASE',
  EXECUTION: 'EXECUTION',
  TEST_SCHEDULE: 'TEST_SCHEDULE'
};

export const VISUAL_TESTING_COMPARISON_METHOD = {
  PIXEL: 'PIXEL',
  LAYOUT: 'LAYOUT',
};

export const CUSTOM_FIELD_ENTITY_TYPE = {
  EXECUTION_TEST_RESULT: 'EXECUTION_TEST_RESULT',
  TEST_CASE: 'TEST_CASE',
  EXECUTION: 'EXECUTION',
  TEST_SCHEDULE: 'TEST_SCHEDULE'
};

export const BASELINE_COLLECTION_PROPERTY = {
  DEFAULT_METHOD: 'DEFAULT_METHOD',
  PIXEL_SENSITIVITY: 'PIXEL_SENSITIVITY'
};

export const DEBOUNCE_IN_SECONDS = 1000;

export const DEFAULT_AUTO_UPDATE_INTERVAL_TIME = 5000;

export const CLOUD_TYPE_TS_PROPERTY_MAPPING = {
  [CloudType.TEST_CLOUD_AGENT]: 'testCloudAgents',
  [CloudType.LOCAL_AGENT]: 'agents',
  [CloudType.K8S_AGENT]: 'k8sAgents',
  [CloudType.CIRCLE_CI_AGENT]: 'circleCIAgents',
};


export const CLOUD_TYPE_TSC_PROPERTY_MAPPING = {
  [CloudType.TEST_CLOUD_AGENT]: 'testCloudTestSuiteCollectionAgents',
  [CloudType.LOCAL_AGENT]: 'agents',
  [CloudType.K8S_AGENT]: 'k8sAgents',
  [CloudType.CIRCLE_CI_AGENT]: 'circleCIAgents',
};

export const CLOUD_TYPE_AGENT_MAPPING = {
  [CloudType.LOCAL_AGENT]: 'localAgentId',
  [CloudType.K8S_AGENT]: 'k8sAgentId',
  [CloudType.CIRCLE_CI_AGENT]: 'circleCIAgentId',
};

export const CLOUD_TYPE_AGENT_TYPE_MAPPING = {
  [CloudType.TEST_CLOUD_AGENT]: 'TEST_CLOUD',
  [CloudType.LOCAL_AGENT]: 'LOCAL',
  [CloudType.K8S_AGENT]: 'K8S',
  [CloudType.CIRCLE_CI_AGENT]: 'CIRCLE_CI',
};

export const BROWSER_TYPE_OPTIONS = [
  {
    label: t('browser-type#chrome'),
    icon: <IconChrome />,
    value: BrowserType.CHROME,
  },
  {
    label: t('browser-type#firefox'),
    icon: <IconFirefox />,
    value: BrowserType.FIRE_FOX,
  },
  {
    label: t('browser-type#chrome-headless'),
    icon: <IconChromeHeadless />,
    value: BrowserType.CHROME_HEADLESS,
  },
  {
    label: t('browser-type#firefox-headless'),
    icon: <IconFirefoxHeadless />,
    value: BrowserType.FIRE_FOX_HEADLESS,
  },
  {
    label: t('browser-type#safari'),
    icon: <IconSafari />,
    value: BrowserType.SAFARI,
  },
  {
    label: t('browser-type#edge'),
    icon: <IconEdge />,
    value: BrowserType.EDGE,
  },
  {
    label: t('browser-type#edge_chromium'),
    icon: <IconEdgeChromium />,
    value: BrowserType.EDGE_CHROMIUM,
  },
  {
    label: t('test_objects'),
    icon: <IconWebServices />,
    value: BrowserType.WEB_SERVICE,
  },
];

export const CLOUD_TYPE_BROWSER_TYPE_PROPERTY_MAPPING = {
  [CloudType.LOCAL_AGENT]: BROWSER_TYPE_OPTIONS[0].value,
  [CloudType.K8S_AGENT]: BROWSER_TYPE_OPTIONS[0].value,
  [CloudType.CIRCLE_CI_AGENT]: BROWSER_TYPE_OPTIONS[0].value,
};

export const CLOUD_TYPE_SELECTED_PROFILE_PROPERTY_MAPPING = {
  [CloudType.TEST_CLOUD_AGENT]: '',
  [CloudType.LOCAL_AGENT]: '',
  [CloudType.K8S_AGENT]: '',
  [CloudType.CIRCLE_CI_AGENT]: ''
};

export const CLOUD_TYPE_TEST_ENVIRONMENT_PROPERTY_MAPPING = {
  [CloudType.TEST_CLOUD_AGENT]: [],
  [CloudType.LOCAL_AGENT]: [],
  [CloudType.K8S_AGENT]: [],
  [CloudType.CIRCLE_CI_AGENT]: []
};

export const DEFAULT_TRUNCATE_MAX_LENGTH = 20;

export const XrayImportReportType = {
  PUSH_MANUALLY: 'PUSH_MANUALLY',
  PUSH_ON_PASSING_RUNS: 'PUSH_ON_PASSING_RUNS',
  PUSH_ON_ALL_RUNS: 'PUSH_ON_ALL_RUNS',
};

export const XrayImportReportTypeOptions = [
  {
    value: XrayImportReportType.PUSH_MANUALLY,
    label: t('xray#import-report-type#push-manually'),
  },
  {
    value: XrayImportReportType.PUSH_ON_ALL_RUNS,
    label: t('xray#import-report-type#push-on-all-runs'),
  },
  {
    value: XrayImportReportType.PUSH_ON_PASSING_RUNS,
    label: t('xray#import-report-type#push-on-passing-runs'),
  }
];

export const CUSTOM_FIELD_TRACKING = {
  TEST_MANAGEMENT_TEST_CASE: 'TEST_MANAGEMENT_TEST_CASE',
  MANUAL_IMPORT: 'MANUAL_IMPORT',
};

export const BROWSER_HEADLESS_SUFFIX = ' (headless)';

export const NEW_SCHEDULE_DIALOG_VARIANT = 'schedule-dialog-20221216';

export const DefectPriority = {
  HIGHEST: {
    value: 'Highest',
    icon: <IconPriorityHighest />,
  },
  HIGH: {
    value: 'High',
    icon: <IconPriorityHigh />,
  },
  MEDIUM: {
    value: 'Medium',
    icon: <IconPriorityMedium />,
  },
  LOW: {
    value: 'Low',
    icon: <IconPriorityLow />,
  },
  LOWEST: {
    value: 'Lowest',
    icon: <IconPriorityLowest />,
  },
};

export const JIRA_STATUS_CATEGORY = [
  {
    value: 'TO_DO',
    style: 'badge-jira-status-category badge-jira-status-category-to-do',
  },
  {
    value: 'COMPLETE',
    style: 'badge-jira-status-category badge-jira-status-category-complete',
  },
  {
    value: 'IN_PROGRESS',
    style: 'badge-jira-status-category badge-jira-status-category-in-progress',
  },
  {
    value: 'UNDEFINED',
    style: 'badge-jira-status-category badge-jira-status-category-undefined',
  },
];

export const JiraDefectChartStatus = {
  CREATED: 'CREATED',
  RESOLVED: 'RESOLVED',
};

export const TEST_TYPE = {
  G4_TEST_CASE: 'G4_TEST_CASE',
  G5_TEST_CASE: 'G5_TEST_CASE',
  MANUAL_TEST_CASE: 'MANUAL_TEST_CASE',
};

export const PROJECT_SUPPORTED_TEST_TYPE = [];

export const SUPPORTED_TEST_TYPE = [
  TEST_TYPE.G5_TEST_CASE,
  TEST_TYPE.G4_TEST_CASE
];

export const EXECUTION_TYPE = {
  TS: 'TS',
  TEXT: 'TEXT'
};


export const KATALON_EVENTS = {
  addPrompt: 'addPrompt',
  clearPrompt: 'clearPrompt',
  createG5TestCase: 'createG5TestCase',
  createSampleG5TestCase: 'createSampleG5TestCase',
  duplicatedG5TestCase: 'duplicatedG5TestCase',
  renamedG5TestCase: 'renamedG5TestCase',
  movedG5TestCase: 'movedG5TestCase',
  deletedG5TestCase: 'deletedG5TestCase',
  duplicatedG5TestSuite: 'duplicatedG5TestSuite',
  renamedG5TestSuite: 'renamedG5TestSuite',
  movedG5TestSuite: 'movedG5TestSuite',
  deletedG5TestSuite: 'deletedG5TestSuite',
  openMoveG5TestEntityDialog: 'openMoveG5TestEntityDialog',
  openRenameDialog: 'openRenameDialog',
  openDeleteTestEntityDialog: 'openDeleteTestEntityDialog',
  openConfigureTestRunDialog: 'openConfigureTestRunDialog',
  openAddToTestSuiteDialog: 'openAddToTestSuiteDialog',
  uploadSucceededG5TestCase: 'uploadSucceededG5TestCase',
  uploadFailedG5TestCase: 'uploadFailedG5TestCase',
  addTestCaseToTestSuite: 'addTestCaseToTestSuite',
  navigateAsSinglePageApplication: 'navigateAsSinglePageApplication',
};

export const G5_MICRO_APP = {
  editedContentG5TestCase: 'editedContentG5TestCase',
  publishDraftTestCase: 'publishDraftTestCase'
};

export const KATALON_TEST_CASE_ROOT_FOLDER = {
  G4_TEST_CASE_FOLDER_TITLE: 'Test Cases',
  G5_TEST_CASE_FOLDER_TITLE: 'test-cases',
  G5_SAMPLE_TEST_CASE_FOLDER_TITLE: 'test-cases/Sample Test Case',
  MANUAL_TEST_CASE_FOLDER_TITLE: 'test-cases/Manual'
};

export const KATALON_TEST_SUITE_ROOT_FOLDER = {
  G4_FOLDER_TITLE: 'Test Suites',
  G5_FOLDER_TITLE: 'test-suites'
};

export const Channel = {
  KATALON_TEST_OPS: 'TestOps',
  KATALON_STUDIO: 'KatalonStudio',
  G5: 'G5'
};

export const draftTestCaseIdPrefix = 'draft';

export const AI_TEST_GENERATION_FOLDER = 'AI-Generated';

export const TMEvent = {
  CS_TEST_CASE_DELETED: 'cs_test_case_deleted',
};

export const TEST_CASE_NODE_KEY_PREFIX = 'test-case';

export const TEST_TYPE_TRACKING_NAME = {
  G4_TEST_CASE: 'katalon-studio',
  G5_TEST_CASE: 'cloud-studio',
  KATALON_STUDIO: 'katalon-studio',
  CLOUD_STUDIO: 'cloud-studio',
  TESTOPS: 'katalon-studio',
};

export const TEST_CASE_STATUS_TRACKING = {
  DRAFT: 'draft',
  PUBLISHED: 'published',
};

export const DISPLAYED_PAGE_TRACKING = {
  ACTION_MENU: 'action-menu',
  TES_CASE_TABLE: 'test-case-table',
  TEST_SUITE_TABLE: 'test-suite-table',
  TEST_CASE_DETAILS: 'test-case-details',
  TEST_SUITE_DETAILS: 'test-suite-details',
  CLOUD_STUDIO_EDITOR: 'cloud-studio-editor',
};

export const ACTIONS_TRACKING = {
  RENAME_TEST_CASE_TRIGGERED: 'to_rename_test_case_triggered',
  RENAME_TEST_SUITE_TRIGGERED: 'to_rename_test_suite_triggered',
  TEST_CASE_RENAMED: 'to_test_case_renamed',
  TEST_SUITE_RENAMED: 'to_test_suite_renamed',
  TEST_CASE_DELETED: 'to_test_case_deleted',
  TEST_SUITE_DELETED: 'to_test_suite_deleted',
  EDIT_TEST_CASE_TRIGGERED: 'to_edit_test_case_triggered',
  EDIT_TEST_SUITE_TRIGGERED: 'to_edit_test_suite_triggered',
  TEST_CASE_DUPLICATED: 'to_test_case_duplicated',
  TEST_SUITE_DUPLICATED: 'to_test_suite_duplicated',
  MOVE_TEST_CASE_TRIGGERED: 'to_move_test_case_triggered',
  MOVE_TEST_SUITE_TRIGGERED: 'to_move_test_suite_triggered',
  DELETE_TEST_CASE_TRIGGERED: 'to_delete_test_case_triggered',
  DELETE_TEST_SUITE_TRIGGERED: 'to_delete_test_suite_triggered',
  TEST_CASE_MOVED: 'to_test_case_moved',
  TEST_SUITE_MOVED: 'to_test_suite_moved',
  SCHEDULE_TEST_RUN_TRIGGERED: 'to_schedule_test_run_triggered',
  INTEGRATION_DETAIL_PAGE_OPENED: 'to_integration_detail_page_opened',
  ADD_TO_TEST_SUITE_TRIGGERED: 'to_add_to_test_suite_triggered',
  TEST_CASE_ADDED_TO_TEST_SUITE: 'to_test_case_added_to_test_suite',
  INSTANT_RUN_TRIGGERED: 'to_instant_run_triggered',
  TEST_CASE_WAS_RAN_INSTANTLY: 'to_test_case_was_ran_instantly',
  SCRIPT_REPO_SELECTED: 'to_imported_script_repository_selected',
};

export const TEST_SUITE_DESTINATION = {
  EXISTING: 'existing-test-suite',
  NEW: 'new-test-suite',
};

export const REPORT_TYPE = {
  CLOUD_STUDIO: 'CLOUD_STUDIO',
};

export const SETUP_GIT_REPOSITORY_OPTIONS = {
  USE_SAMPLE: 'use-sample',
  CREATE_NEW: 'create-new'
};

export const DISPLAY_ONBOARDING_CHECKLIST_LIMIT_TIME = 1000 * 60 * 60 * 24 * 30; // 30 days

export const DISABLED_RESEND_VERIFY_EMAIL_TIMEOUT = 1000 * 30; // 30s

export const OnboardingType = {
  KATALON_PLATFORM: {
    index: 0,
    sourceId: 'PL'
  },
  KATALON_STUDIO: {
    index: 1,
    sourceId: 'KS',
  }
};

export const Severity = {
  SUCCESS: 'success',
  ERROR: 'error',
  INFO: 'info',
  WARNING: 'warning',
};

export const SUPPORTED_TEST_PROJECT_TYPE = [
  TestProjectType.GIT,
  TestProjectType.CLOUD,
  TestProjectType.KS,
];

export const KATALON_STUDIO_SUPPORTED_TEST_PROJECT_TYPE = [
  TestProjectType.GIT,
  TestProjectType.KS,
];

export const STORAGE_TYPE = {
  UPLOADED_DATA: 'uploaded-data-folder',
  GIT: 'git',
  KATALON_STORAGE: 'katalon-storage',
  UPLOADED_REPO: 'uploaded_repo',
};

export const VIRTUAL_DATA_QUESTIONS: VirtualDataQuestionObjectType = {
  RELEASE_READINESS: {
    promptType: 'RELEASE_READINESS',
    value: t('ai-release-readiness-question'),
    title: t('ai-release-readiness'),
    dataTrackId: 'ra_ai_ready_to_release_clicked',
    dataTrackGroup: GroupEvent.VIRTUAL_DATA_ANALYST
  },
  COMMON_FAILURES: {
    promptType: 'COMMON_FAILURES',
    value: t('ai-common-failures-question'),
    title: t('ai-common-failures'),
    dataTrackId: 'ra_ai_common_errors_clicked',
    dataTrackGroup: GroupEvent.VIRTUAL_DATA_ANALYST
  },
  TEST_STABILITY: {
    promptType: 'TEST_STABILITY',
    value: t('ai-test-stability-question'),
    title: t('ai-test-stability'),
    dataTrackId: 'ra_ai_test_stability_clicked',
    dataTrackGroup: GroupEvent.VIRTUAL_DATA_ANALYST
  },
};

export const ACTIONS = {
  RUN: 'RUN',
  RENAME: 'RENAME',
  EDIT: 'EDIT',
  DUPLICATE: 'DUPLICATE',
  MOVE: 'MOVE',
  DELETE: 'DELETE',
  ADD_TO_TEST_SUITE: 'ADD_TO_TEST_SUITE',
};

export const SINGLE_ACTION_ALLOWED = [
  ACTIONS.RUN,
  ACTIONS.EDIT,
  ACTIONS.RENAME,
];

export const VideoExtension = {
  AVI: '.avi',
  MOV: '.mov',
  MP4: '.mp4',
};

export const TimeLimitMilliseconds = 15 * 60 * 1000;

export const KeyesExecutionStatus = {
  RUNNING: 'RUNNING',
  ANALYSING: 'ANALYSING',
  UNRESOLVED: 'UNRESOLVED',
  PASSED: 'PASSED',
  FAILED: 'FAILED',
};

export const OperationType = {
  INDIVIDUAL: 'individual',
  BULK: 'bulk',
};

export const EXECUTION_DIALOG_NOTIFICATION_TIMEOUT = 10000; // 10 seconds

export const DevicePoolType = {
  MAIN_SHARED_POOL: 'main_shared_pool',
  SUB_SHARED_POOL: 'sub_shared_pool',
  PRIVATE_CLOUD_POOL: 'private_cloud',
};

export const SESSION_TYPES = {
  DESKTOP_BROWSER: 'DESKTOP_BROWSER',
  MOBILE_BROWSER: 'MOBILE_BROWSER',
  MOBILE_NATIVE: 'MOBILE_NATIVE',
  PRIVATE_MOBILE_NATIVE: 'PRIVATE_MOBILE_NATIVE',
};

export const SESSION_TYPE_MAPPING = {
  TESTCLOUD_SESSION_WEB_DESKTOP: 'Desktop Browser',
  TESTCLOUD_SESSION_MOBILE_APP: 'Mobile Native App',
  TESTCLOUD_SESSION_CROSS_BROWSER: 'Desktop & Mobile Browser',
  TESTCLOUD_SESSION_WEB: 'Multi Platform',
};

export const TestType = {
  MANUAL: 'MANUAL',
};

export const SessionTypeDecoratorMapping = {
  TESTCLOUD_SESSION_WEB_DESKTOP: {
    tooltip: SESSION_TYPE_MAPPING.TESTCLOUD_SESSION_WEB_DESKTOP,
    icon: <IconDesktopBrowserGrey />,
    mainIcon: <IconDesktopBrowser />
  },
  TESTCLOUD_SESSION_CROSS_BROWSER: {
    tooltip: SESSION_TYPE_MAPPING.TESTCLOUD_SESSION_CROSS_BROWSER,
    icon: <IconCrossBrowserGrey />,
    mainIcon: <IconCrossBrowser />
  },
  TESTCLOUD_SESSION_MOBILE_APP: {
    tooltip: SESSION_TYPE_MAPPING.TESTCLOUD_SESSION_MOBILE_APP,
    icon: <IconMobileNativeAppGrey />,
    mainIcon: <IconMobileNativeAppMain /> },
  TESTCLOUD_SESSION_WEB: {
    tooltip: SESSION_TYPE_MAPPING.TESTCLOUD_SESSION_WEB,
    icon: <IconMultiPlatformGrey />,
    mainIcon: <IconMultiPlatform /> },
};

export const ANY_DEVICE_ID_PREFIX = 'any_';
