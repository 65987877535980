import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { string, bool } from 'prop-types';
import { getIndexFile, mountMicroApp } from '../utils/MicroFrontend';
import LoadingSkeleton from './LoadingSkeleton';

const MicroAppContainer = styled('div')(() => ({
  height: '100%',
  width: '100%',
}));

function MicroApp({ id, path, useLoading = false }) {
  const [isMounted, setMounted] = useState(false);

  useEffect(() => {
    document.addEventListener(`parent-app:handleMounted-${id}`, () => setMounted(true));
    getIndexFile(path, () => mountMicroApp(id));
    return () => {
      if (window.microapps) {
        document.removeEventListener(`parent-app:handleMounted-${id}`, () => setMounted(true));
        window.microapps[id].unmountApp();
      }
    };
  }, [id, path]);

  return (
    <>
      { useLoading && !isMounted &&
      <LoadingSkeleton />}
      <MicroAppContainer id={id} />
    </>
  );

}

MicroApp.propsTypes = {
  id: string.isRequired,
  path: string.isRequired,
  useLoading: bool,
};

export default MicroApp;
