import Icon from '@katalon-studio/katalon-ui/Icon';
import React from 'react';
import colors from '../../../scss/colors.scss';

function KatalonStorageIcon({ color }) {
  return <Icon
    type="fa-regular"
    name="fa-box-circle-check"
    textSize="16px"
    boundingSize={null}
    color={null}
    sx={{
      color,
    }}
  />;
}

KatalonStorageIcon.defaultProps = {
  color: colors.gray9,
};

export default KatalonStorageIcon;
