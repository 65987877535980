import { FormGroup, Label } from 'reactstrap';
import React from 'react';
import ProjectInput from './ProjectInput';
import { t } from '../../i18n/t';
import LinkedProjectList from './LinkedProjectList';
import { Project } from '../../models/model/Project';

interface AgentProjectLinkProps {
  selectedProjects: Project[],
  handleOnSelectChange: (projects: Project[]) => void,
}

function AgentProjectLink({ selectedProjects, handleOnSelectChange }: AgentProjectLinkProps) {

  const handleSelectChange = (options: Project[]) => {
    const projectsAdded = options
      .filter((project: Project) => !selectedProjects.find(({ id }) => project.id === id));

    if (handleOnSelectChange && projectsAdded.length > 0) {
      handleOnSelectChange([...selectedProjects, ...projectsAdded]);
    }
  };

  const handleDelete = (option: Project) => {
    const newSelectedProjects = selectedProjects.filter((project) => project.id !== option.id);
    handleOnSelectChange(newSelectedProjects);
  };

  return (
    <>
      <Label>{t('linked-projects')}</Label>
      <FormGroup>
        <ProjectInput selectedProjects={selectedProjects} handleOnSelectChange={handleSelectChange} />
      </FormGroup>
      <LinkedProjectList selectedProjects={selectedProjects} handleOnDelete={handleDelete} />
    </>
  );
}

export default AgentProjectLink;
