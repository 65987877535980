import { FormGroup, Label } from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import { TEST_TYPE, TEST_TYPE_TRACKING_NAME } from '../../../utils/Constants';
import TestCaseTestSuiteDataTable from '../../table/TestCaseTestSuiteDataTable';
import { t } from '../../../i18n/t';
import SelectTestCaseTreeView from '../testcase/SelectTestCaseTreeView';
import { sendAnalyticEventForAction } from '../../../utils/SegmentAnalytics';

const TestCaseLinkingWithTreeView = ({
  selectedLinkingTestCases,
  setSelectedLinkingTestCases,
  testCaseType,
  testProject,
  isAbridgedVersion,
  trackingProps
}) => {

  const { addButton, clearButton, removeTestCaseIcon } = trackingProps;

  const fillIndexForLinkingTestCases = (listTestCases) => {
    listTestCases.forEach((row, index) => {
      row.row_number = index + 1;
    });
  };

  const selectedLinkingTestCasesWithSuffix = selectedLinkingTestCases.map((testCase) => {
    const suffix = uuidv4();
    const testCaseId = `${testCase.id}#${suffix}`;
    return {
      ...testCase,
      id: testCaseId
    };
  });

  const removeLinkingTestCases = (testCaseValue) => {
    let newSelectedLinkingTestCases = selectedLinkingTestCasesWithSuffix;
    newSelectedLinkingTestCases = newSelectedLinkingTestCases.filter((item) => item !== testCaseValue);
    newSelectedLinkingTestCases = newSelectedLinkingTestCases.map((testCase) => {
      // sample test case id: 179649#f977a92f-8c9a-4b49-bab3-c862c04f9022
      const idParts = testCase.id.split('#');
      const originalId = parseInt(idParts[0], 10);
      return {
        ...testCase,
        id: originalId
      };
    });
    fillIndexForLinkingTestCases(newSelectedLinkingTestCases);
    setSelectedLinkingTestCases(newSelectedLinkingTestCases);
    if (removeTestCaseIcon) {
      sendAnalyticEventForAction(removeTestCaseIcon, { 'test-case-type': TEST_TYPE_TRACKING_NAME[testCaseType] });
    }
  };

  const addLinkingTestCases = (testCases) => {
    const clonedTestCases = testCases.map((testCase) => ({ ...testCase }));
    const newSelectedLinkingTestCases = [...selectedLinkingTestCases, ...clonedTestCases];
    fillIndexForLinkingTestCases(newSelectedLinkingTestCases);
    setSelectedLinkingTestCases(newSelectedLinkingTestCases);
  };

  const renderSelectTestCase = () => (
    <div className={classnames(!isAbridgedVersion && 'w-50')}>
      <SelectTestCaseTreeView
        addLinkingTestCases={addLinkingTestCases}
        testProjectId={testProject.id}
        testCaseType={testCaseType}
        addBtnTrackingId={addButton}
        clearBtnTrackingId={clearButton}
      />
    </div>
  );

  const renderTestCaseTestSuiteDataTable = () => (
    <TestCaseTestSuiteDataTable
      isAbridgedVersion={isAbridgedVersion}
      testProject={testProject}
      selectedLinkingTestCases={selectedLinkingTestCasesWithSuffix}
      removeLinkingTestCases={removeLinkingTestCases}
      removeIconTrackingId={removeTestCaseIcon}
    />
  );

  return (
    <FormGroup>
      {isAbridgedVersion ? <Label>{t('test_cases')}</Label> : <h5>{t('test_cases')}</h5>}
      {renderSelectTestCase()}
      {renderTestCaseTestSuiteDataTable()}
    </FormGroup>
  );
};

TestCaseLinkingWithTreeView.propTypes = {
  testProject: PropTypes.object.isRequired,
  selectedLinkingTestCases: PropTypes.array.isRequired,
  setSelectedLinkingTestCases: PropTypes.func.isRequired,
  testCaseType: PropTypes.string,
  isAbridgedVersion: PropTypes.bool,
};

TestCaseLinkingWithTreeView.defaultProps = {
  testProject: {},
  testCaseType: TEST_TYPE.G4_TEST_CASE,
  selectedLinkingTestCases: [],
  isAbridgedVersion: false,
};

export default TestCaseLinkingWithTreeView;
