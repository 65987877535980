import React from 'react';
import { buildSearchCondition } from '../../components/search/SearchUtils';
import TabComponent from '../../components/TabComponent';
import ExecutionTestResultTestObjectDataTable from '../../components/table/ExecutionTestResultTestObjectDataTable';
import MContext from '../../models/MContext';

class TestObjectsTab extends TabComponent {
  constructor(props) {
    super(props);

    this.meta.id = 'page-execution-test-result-details-test-object';
    this.meta.title = document.title;
  }

  render() {
    return (
      <ExecutionTestResultTestObjectDataTable
        defaultSearchConditions={[
          buildSearchCondition('ExecutionTestResult.id', '=', MContext.executionTestResultId)
        ]}
      />
    );
  }
}

export default TestObjectsTab;
