import React from 'react';
import { Button } from 'reactstrap';
import PageComponent from '../components/PageComponent';
import { t } from '../i18n/t';
import MContext from '../models/MContext';
import { notificationIds, getNotificationMessage } from '../utils/NotificationPageUtils';
import RouteConstants from '../utils/RouteConstants';
import UrlHelper from '../utils/UrlHelper';
import MConfig from '../models/MConfigs';
import MFlags from '../models/MFlags';

export default class Error extends PageComponent {
  constructor(props) {
    super(props);
    let notificationId = MContext.notificationId;
    this.message = getNotificationMessage(notificationId);
    if (!this.message) {
      notificationId = notificationIds.NOT_FOUND;
      this.message = getNotificationMessage(notificationId);
    }
    this.meta.id = 'page-error';
    this.meta.title = t(this.message.pageTitle);
  }

  returnHomePage = () => {
    const isInvalidDomain = UrlHelper.getSearchParam('isInvalidDomain');
    window.location.href = isInvalidDomain
      ? `${MConfig.testOpsBaseUrl}${RouteConstants.home}`
      : RouteConstants.home;
  };

  render() {
    const { messageTitle: title, messageContent: content, icon } = this.message;
    return (
      <div className="m-auto text-center">
        {icon}
        <div className="error-title">{title}</div>
        <div className="error-content">{content}</div>
        <Button color="primary" onClick={this.returnHomePage}>{t('return-homepage')}</Button>
      </div>
    );
  }
}
