import React, { useEffect, useState } from 'react';
import { FormGroup, Label } from 'reactstrap';
import { t } from '../../i18n/t';
import Select from '../Select';
import XrayIssueInput from '../XrayIssueInput';
import { TestOpsObjectType, XRAY_ISSUE_TYPE } from '../../utils/Constants';

const XrayImportIntegration = (props) => {
  const { value, xrayImportReportTypes, onChange } = props;

  const [xrayImportReportType, setXrayImportReportType] = useState();
  const [externalTestPlanId, setExternalTestPlanId] = useState();

  const handleSelectXrayImportReportType = (item) => {
    setXrayImportReportType(item.value);
    onChange({ externalTestPlanId, xrayImportReportType: item.value });
  };

  const handleIssueInputChange = (externalTestPlanId) => {
    setExternalTestPlanId(externalTestPlanId);
    onChange({ xrayImportReportType, externalTestPlanId });
  };

  useEffect(() => {
    if (value) {
      if (value.xrayImportReportType) {
        setXrayImportReportType(value.xrayImportReportType);
      }

      if (value.externalTestPlanId) {
        setExternalTestPlanId(value.externalTestPlanId);
      }
    } else {
      if (xrayImportReportTypes && xrayImportReportTypes.length > 0) {
        setXrayImportReportType(xrayImportReportTypes[0].value);
      }
    }
  }, [value, xrayImportReportTypes]);

  return (
    <>
      <FormGroup>
        <Label for="externalTestPlanId">{t('run-configuration#xray-test-plan')}</Label>
        <XrayIssueInput
          xrayIssueType={XRAY_ISSUE_TYPE.TEST_PLAN}
          testOpsObjectType={TestOpsObjectType.TEST_PLAN}
          handleIssueInputChange={handleIssueInputChange}
          value={externalTestPlanId}
          placeholder={t('xray#test-plan#placehoder')}
        />
      </FormGroup>
      <FormGroup>
        <Label for="xrayImportReportType">{t('run-configuration#xray-import-report-type')}</Label>
        <Select
          data-trackid="xray-push-mechanism"
          id="xrayImportReportType"
          name="xrayImportReportType"
          hasOverflowParentDiaglog={document.getElementById('smart-scheduler-dialog')}
          onChange={handleSelectXrayImportReportType}
          value={xrayImportReportType}
          options={xrayImportReportTypes}
          clearable
          required
        />
      </FormGroup>
    </>
  );
};

export default XrayImportIntegration;
