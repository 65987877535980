import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Input from '../Input';
import Select from '../Select';

class Filter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputValue: '',
    };
    this.onSelect = this.onSelect.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.onSearchInputKeyDown = this.onSearchInputKeyDown.bind(this);
  }

  onSelect({ value }) {
    const { id, onChange } = this.props;
    onChange(id, value);
  }

  onInputChange(event) {
    const inputValue = event.target.value;
    this.setState({ inputValue });
  }

  onSearchInputKeyDown(event) {
    const { id, onChange, operator } = this.props;
    const { inputValue } = this.state;

    const value = inputValue ? `${operator}${inputValue}` : '';
    if (event.keyCode === 13) {
      onChange(id, value);
    }
  }

  render() {
    const { label, options, value } = this.props;
    const { inputValue } = this.state;
    return (
      <>
        {options ?
          <Select
            closeOnSelect
            // multi
            onChange={this.onSelect}
            options={options}
            placeholder={label}
            // removeSelected={this.state.removeSelected}
            simpleValue
            value={value}
          /> :
          <Input
            onChange={this.onInputChange}
            onKeyDown={this.onSearchInputKeyDown}
            placeholder={label}
            value={inputValue}
            title={label}
          />}
      </>
    );
  }
}

Filter.propTypes = {
  onChange: PropTypes.func.isRequired,
  operator: PropTypes.string,
  options: PropTypes.object,
  label: PropTypes.string,
};

Filter.defaultProps = {
  operator: '',
  options: null,
  label: '',
};

export default Filter;
