import React, { Component } from 'react';
import AutomaticallyUploadReport from '../../../components/guide/AutomaticallyUploadReport';
import ManuallyUploadReport from '../../../components/guide/ManuallyUploadReport';

class UploadKatalonReports extends Component {

  render() {
    return (
      <>
        <div className="mt-2">
          <AutomaticallyUploadReport style="custom-katalon-studio-title" />
        </div>
        <div className="mt-5">
          <ManuallyUploadReport style="custom-katalon-studio-title" />
        </div>
      </>
    );
  }
}

export default UploadKatalonReports;
