import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { Label } from 'reactstrap';
import { isEmpty } from 'lodash';
import { t } from '../../../i18n/t';
import Select from '../../Select';
import TooltipComponent from '../../TooltipComponent';
import { compareObj } from '../services/testcloud';
import CustomSelect from '../../CustomSelect';
import DecoratorConstants from '../../../utils/DecoratorConstants';

const LocalEnvironment = (props) => {
  const { value, localAgents, browserTypeOptions, hideBrowserTypeSelect = false, onChange } = props;

  const [selectedLocalAgents, setSelectedLocalAgents] = useState([]);
  const [selectedBrowserType, setSelectedBrowserType] = useState();
  const [localAgentOptions, setLocalAgentOptions] = useState([]);

  const handleChangeBrowserType = (browserType) => {
    setSelectedBrowserType(browserType.value);
    if (!isEmpty(selectedLocalAgents)) {
      onChange(selectedLocalAgents.map((localAgent) => ({
        ...localAgent.value,
        browserType: browserType.value,
      })));
    } else {
      onChange([{ browserType: browserType.value, isNotTestEnv: true }]);
    }
  };

  const handleChange = (values) => {
    setSelectedLocalAgents(values);
    if (isEmpty(values)) {
      onChange([{ browserType: selectedBrowserType, isNotTestEnv: true }]);
    } else {
      onChange(values.map((el) => el.value).map((value) => ({
        ...value,
        browserType: selectedBrowserType,
      })));
    }

  };

  useEffect(() => {
    const filterLocalAgents = value.filter(({ isNotTestEnv }) => isNotTestEnv !== true);

    setLocalAgentOptions(localAgents.map((localAgent) => ({
      ...localAgent,
      label: localAgent.value?.name,
      icons: [DecoratorConstants.agentStatusDecorator(localAgent.value?.active), DecoratorConstants.iconByOS(localAgent.value?.os)],
      disabled: compareObj(filterLocalAgents, localAgent.value, ['id', 'name'])
    })));

    if (filterLocalAgents && filterLocalAgents.length > 0) {
      setSelectedLocalAgents(filterLocalAgents.map((el) => ({
        value: el,
        label: el?.name,
        icons: [DecoratorConstants.agentStatusDecorator(el.active), DecoratorConstants.iconByOS(el.os)],
      })));
      setSelectedBrowserType(filterLocalAgents[0].browserType);
    } else {
      if (browserTypeOptions && browserTypeOptions.length > 0) {
        setSelectedBrowserType(value[0]?.browserType || browserTypeOptions[0].value);
      }
    }
  }, [value, localAgents]);

  return (
    <Box sx={{ marginBottom: '1.5rem' }}>
      <CustomSelect
        value={selectedLocalAgents}
        options={localAgentOptions}
        onChange={handleChange}
        menuPosition="fixed"
      />
      {!hideBrowserTypeSelect &&
        <>
          <div className="d-flex">
            <Label for="browserType" className="normal-label">{t('run_with')}</Label>
            <TooltipComponent text={t('run-configuration#not-supported-mobile')} />
          </div>
          <Select
            required
            clearable={false}
            id="browserType"
            value={selectedBrowserType}
            onChange={handleChangeBrowserType}
            options={browserTypeOptions}
            maxMenuHeight={180}
            menuPosition="fixed"
            isCustom
          />
        </>}
    </Box>
  );
};

export default LocalEnvironment;
