import _ from 'lodash';
import React from 'react';
import { Button, Card, CardBody, CardHeader, Form, FormGroup, Label, ListGroup, ListGroupItem } from 'reactstrap';
import Input from '../../components/Input';
import PageComponent from '../../components/PageComponent';
import SidebarLayout from '../../components/SidebarLayout';
import ObjectInfo from '../../components/summary/ObjectInfo';
import ObjectSummary from '../../components/summary/ObjectSummary';
import MTableColumnDataMapping from '../../components/table/models/MTableColumnDataMapping';
import { t } from '../../i18n/t';
import { IconAgents, IconDownload, IconDuration, IconStartTime, IconExecution } from '../../images/CustomIcon';
import MContext from '../../models/MContext';
import WebSocket from '../../services/WebSocket';
import Arrays from '../../utils/Arrays';
import { CloudType, ConfigType } from '../../utils/Constants';
import DecoratorConstants from '../../utils/DecoratorConstants';
import Time from '../../utils/Moment';
import Routes from '../../utils/Routes';
import Services from '../../utils/Services';
import DefaultLayout from '../../components/DefaultLayout';
import { buildSearchCondition } from '../../components/search/SearchUtils';
import PageButtonToolbar from '../../components/PageButtonToolbar';
import Notification from '../../utils/Notification';
import MAuth from '../../models/MAuth';
import StringHelper from '../../utils/StringHelper';
import { next } from '../../utils/Count';

const LogContent = React.memo((props) => (
  <Card>
    <CardHeader>
      {props.logName ? props.logName : 'Logs'}
    </CardHeader>
    <CardBody>
      <pre>{props.log}</pre>
    </CardBody>
  </Card>
));

class Job extends PageComponent {

  constructor(props) {
    super(props);
    this.meta.id = 'page-job';
    this.projectId = MContext.projectId;
    this.teamId = MContext.teamId;
    this.project = MContext.project;
    this.jobOrder = MContext.jobOrder;
    this.runConfigurationId = MContext.runConfigurationId;
    this.handlerId = next();

    this.state = this.getInitialState();

    this.cancelJob = this.cancelJob.bind(this);
    this.getLog = this.getLog.bind(this);
    this.renderLog = this.renderLog.bind(this);
    this.renderInfo = this.renderInfo.bind(this);
    this.renderHeader = this.renderHeader.bind(this);
    this.renderBody = this.renderBody.bind(this);
    this.getJob = this.getJob.bind(this);
    this.changeSelectedLogContent = this.changeSelectedLogContent.bind(this);
    this.changeSelectedLog = this.changeSelectedLog.bind(this);
    this.executePlan = this.executePlan.bind(this);

    this.updateInterval = 5000;
    this.notifyHandler = _.throttle(() => {
      this.refreshData();
    }, this.updateInterval);
  }

  getInitialState() {
    return {
      selectedLogIndex: 0,
      log: '',
      logs: [],
      jobInfo: null,
    };
  }

  componentDidMount() {
    const isTeamDemo = MContext.isTeamDemo;
    if (isTeamDemo) {
      Notification.pushError(t('read-only-mode#error-message'), 'Error');
    } else {
      this.getJob()
        .then((job) => {
          this.getLog(job.id);
          this.subscribeTopic();
        });
    }
  }

  getJob() {
    const params = {
      type: 'Job',
      conditions: [
        buildSearchCondition('order', '=', this.jobOrder),
      ],
      pagination: {
        page: 0,
        size: 1,
        sorts: 'order,desc',
      },
    };

    return Services.search(params)
      .then(({ content }) => {
        const job = content[0];
        this.meta.title = t('job#title', { name: job.order });
        this.setState({
          jobInfo: job,
        });
        return job;
      });
  }

  subscribeTopic() {
    if (this.projectId) {
      const topic = `Job-${this.state.jobInfo.id}`;
      WebSocket.subscribe({
        projectId: this.projectId,
        topics: [topic],
      }, this.notifyHandler, this.handlerId);
    }
  }

  refreshData() {
    this.getJob()
      .then((job) => this.getLog(job.id));
  }

  componentWillUnmount() {
    WebSocket.unsubscribe(this.handlerId);
  }

  getLog(jobId) {
    Services.getJobLogs(jobId)
      .then((result) => {
        const { selectedLogIndex } = this.state;
        this.setState({ logs: result }, () => this.changeSelectedLog(selectedLogIndex));
      });
  }

  changeSelectedLogContent() {
    const { logs, selectedLogIndex } = this.state;
    const log = logs[selectedLogIndex];
    if (log) {
      const logFileUrl = log.logFileUrl;
      if (logFileUrl) {
        Services.getFileContentFromExternal(logFileUrl)
          .then((logFileContent) => {
            logs[selectedLogIndex].content = logFileContent;
            this.setState({ log: logFileContent, logs });
          });
      } else {
        this.setState({ log: log.content });
      }
    }
  }

  changeSelectedLog(selectedLogIndex) {
    this.setState({ selectedLogIndex }, () => this.changeSelectedLogContent());
  }

  renderLog() {
    const { log, selectedLogIndex, logs, jobInfo } = this.state;
    let logContent = log;
    if (typeof log === 'object') {
      if (jobInfo.circleCiAgent) {
        logContent = '';
        _.forEach(log, (l) => {
          logContent += l.message;
        });
      }
    }
    const selectLog = logs[selectedLogIndex] || {};
    return <LogContent logName={selectLog.name} log={logContent} />;
  }

  renderAttachments() {
    const { logs } = this.state;
    const content = logs.map((item, index) => {
      const logFileUrl = item.logFileUrl;
      return (
        <ListGroupItem>
          <a
            data-trackid="job-log"
            title={t('logs')}
            href="#"
            onClick={(e) => {
              e.preventDefault();
              this.changeSelectedLog(index);
            }}
          >
            <span>{} {item.name}</span>
          </a>
          {logFileUrl && (
            <Button
              data-trackid="job-log"
              title={t('logs')}
              color="link"
              href={logFileUrl}
              target="_blank"
            >
              <IconDownload />
            </Button>
          )}
        </ListGroupItem>
      );
    });
    return content.length > 0 ? (
      <FormGroup>
        <Label for="attachment">Attachments</Label>
        <ListGroup flush id="attachment">{content}</ListGroup>
      </FormGroup>
    ) : null;
  }

  renderJobInfo() {
    const { jobInfo } = this.state;
    const configType = _.get(jobInfo, 'parameter.configType');

    const mapping = [
      new MTableColumnDataMapping(
        configType === ConfigType.GENERIC_COMMAND ? 'Generic Command' : 'Katalon Command Arguments',
        'parameter.command',
        (name, row) => {
          const command = _.get(row, name);
          return <pre>{StringHelper.maskLog(command)}</pre>;
        },
      ),
      ...Arrays.insertIf(
        [ConfigType.COMMAND, ConfigType.TSC, ConfigType.TS].includes(configType)
        && !_.get(jobInfo, 'parameter.ksLocation'),
        new MTableColumnDataMapping(
          t('agent#kre-version'),
          'parameter.ksVersion',
        ),
      ),
      ...Arrays.insertIf(
        [ConfigType.COMMAND, ConfigType.TSC, ConfigType.TS].includes(configType)
        && _.get(jobInfo, 'parameter.ksLocation'),
        new MTableColumnDataMapping(
          t('agent#kre-location'),
          'parameter.ksLocation',
        ),
      ),
    ];
    return (
      <>
        {mapping.map((item) => {
          const title = item.headerCaption;
          const id = _.camelCase(title);
          const value = item.decorate(item.fieldName, jobInfo);
          return (
            <FormGroup key={id}>
              <Label for={id}>{title}</Label>
              <Input plaintext id={id}>
                {value}
              </Input>
            </FormGroup>
          );
        })}
      </>
    );
  }

  renderInfo() {
    return (
      <Card>
        <CardBody>
          <Form>
            {this.renderJobInfo()}
            {this.renderAttachments()}
          </Form>
        </CardBody>
      </Card>
    );
  }

  getJobInfoItems() {
    const { jobInfo } = this.state;
    if (_.isEmpty(jobInfo)) {
      return [];
    }
    const { execution } = jobInfo;
    let executionLink;
    if (!_.isEmpty(execution)) {
      const project = execution.project;
      const constructedLink = new Routes({
        executionId: execution.order,
        projectId: execution.projectId,
        teamId: project.teamId,
      });
      executionLink = DecoratorConstants.idDecorator('execution.order', jobInfo, constructedLink.execution_details_link);
    }

    return [
      {
        text: DecoratorConstants.statusBadge(jobInfo.status),
      },
      {
        icon: <IconStartTime />,
        text: Time.format(_.get(jobInfo, 'startTime')),
      },
      {
        icon: <IconDuration />,
        text: Time.duration(_.get(jobInfo, 'duration')),
      },
      {
        icon: <IconAgents />,
        text: DecoratorConstants.agentDecorator('name', jobInfo),
      },
      ...Arrays.insertIf(executionLink, (
        {
          icon: <IconExecution />,
          text: executionLink,
        }
      )),
      {
        text: DecoratorConstants.platformHeader({
          osVersion: _.get(jobInfo, 'agent.os'),
        }),
      },
    ];
  }

  cancelJob() {
    const { id, order } = this.state.jobInfo;

    Services.cancelJob(id)
      .then(() => {
        Notification.pushSuccess(`Cancel session #${order} successfully.`);
      });
  }

  renderObjectSummary() {
    const project = this.project;
    const { runConfiguration } = this.state.jobInfo;
    const urlParams = {
      project: project.name,
      projectId: project.id,
      runConfiguration: runConfiguration?.name,
      runConfigurationId: this.runConfigurationId,
      job: this.jobOrder,
      jobOrder: this.jobOrder
    };

    const jobInfoItems = this.getJobInfoItems();
    return (
      <ObjectSummary params={urlParams}>
        <ObjectInfo items={jobInfoItems} />
      </ObjectSummary>
    );
  }

  renderHeader() {
    return (
      <>
        {this.renderObjectSummary()}
      </>
    );
  }

  executePlan() {
    const { runConfiguration } = this.state.jobInfo || {};
    Services.executeRunConfiguration(this.runConfigurationId)
      .then(() => {
        if (runConfiguration.cloudType === CloudType.TEST_CLOUD_AGENT) {
          Services.trackTestCloudTestRunScheduleEvent(MAuth.email, Date.now());
        }
        Routes.gotoRunConfigurationId(this.runConfigurationId);
        Notification.pushSuccessPermanent(`Run ${runConfiguration.name}.`);
      });
  }

  renderCancelButton() {
    const { status } = this.state.jobInfo;

    return (
      <PageButtonToolbar>
        {!_.includes(['QUEUED', 'RUNNING'], status) && (
          <Button
            data-trackid="run-plan"
            title={t('run')}
            color="secondary"
            onClick={this.executePlan}
          >
            {t('run')}
          </Button>
        )}
        {_.includes(['QUEUED', 'RUNNING'], status) && (
          <Button
            data-trackid="cancel-job"
            title="Cancel"
            color="secondary"
            onClick={this.cancelJob}
          >
            Cancel
          </Button>
        )}
      </PageButtonToolbar>
    );
  }

  renderBody() {
    return (
      <>
        {this.renderCancelButton()}
        <SidebarLayout
          renderMain={this.renderLog}
          renderSidebar={this.renderInfo}
        />
      </>
    );
  }

  render() {
    const { jobInfo } = this.state;
    if (jobInfo) {
      return (
        <DefaultLayout
          renderHeader={this.renderHeader}
          renderBody={this.renderBody}
        />
      );
    }
    return null;
  }
}

export default Job;
