import React from 'react';
import Stack from '@mui/material/Stack';
import { Divider, Tab, Tabs } from '@mui/material';
import { makeStyles } from '@mui/styles';
import DecoratorConstants from '../../utils/DecoratorConstants';
import MFlags from '../../models/MFlags';
import { t } from '../../i18n/t';

const useStyles = makeStyles(() => ({
  tabs: {
    '& .MuiTab-textColorPrimary.Mui-selected': {
      color: '#1847ca'
    },
    '& .MuiTabs-indicator': {
      backgroundColor: '#1847ca'
    },
    borderBottom: '#d6dae4 1px solid',
    marginLeft: '-1.7rem',
    marginTop: '0.5rem',
  },
}));

function DefectDrawerTitle({ chosenDefect, tabIndex, handleChangeTabIndex }) {
  const classes = useStyles();
  return (
    <div className="defect-drawer-title">
      <span className="defect-drawer-id">
        {DecoratorConstants.defectIdDecorator(chosenDefect)}
      </span>
      <span className="defect-drawer-summary">
        {DecoratorConstants.externalNameDecorator('summary', chosenDefect)}
      </span>
      <Stack
        direction="row"
        spacing={{ xs: 1 }}
        alignItems="center"
        sx={{ height: '1.7rem' }}
        divider={<Divider sx={{ height: '1.14rem' }} orientation="vertical" />}
      >
        {DecoratorConstants.defectStatusDecorator(chosenDefect)}
        {(MFlags.externalSeverityMigrationEnabled ?
          DecoratorConstants.defectPriorityDecorator(chosenDefect) :
          DecoratorConstants.defectPriorityDecoratorWithoutIcon(chosenDefect))}
        {MFlags.externalSeverityMigrationEnabled && DecoratorConstants.defectSeverityDecorator(chosenDefect)}
        {DecoratorConstants.defectAssigneeDecorator(chosenDefect)}
      </Stack>
      {
        MFlags.automationRuleEnabled &&
        <Tabs
          className={classes.tabs}
          value={tabIndex}
          onChange={(event, newValue) => handleChangeTabIndex(event, newValue)}
        >
          <Tab label={t('executions_overview')} />
          <Tab label={t('automation-rule')} />
        </Tabs>
      }
    </div>
  );
}

export default DefectDrawerTitle;
