import { Breadcrumbs } from '@mui/material';
import React from 'react';
import { Button, Card, CardBody, Col, Input, Label, Row, Form, FormGroup } from 'reactstrap';
import DefaultLayout from '../../components/DefaultLayout';
import PageButtonToolbar from '../../components/PageButtonToolbar';
import PageComponent from '../../components/PageComponent';
import { buildSearchCondition } from '../../components/search/SearchUtils';
import ObjectSummary from '../../components/summary/ObjectSummary';
import ObjectSummaryParametersBuilder from '../../components/summary/ObjectSummaryParametersBuilder';
import { t } from '../../i18n/t';
import MContext from '../../models/MContext';
import {
  MAX_PAGE_SIZE,
  SearchEntity,
  TEST_TYPE,
  TestSuiteType,
  WAITING_TIME_FOR_TOAST,
  TestProjectType
} from '../../utils/Constants';
import DecoratorConstants from '../../utils/DecoratorConstants';
import Services from '../../utils/Services';
import Notification from '../../utils/Notification';
import PageHistoryHelper from '../../utils/PageHistoryHelper';
import GroupEvent from '../../utils/track/GroupEvent';
import MFlags from '../../models/MFlags';
import G5TestSuiteEditView from '../../components/cloud-studio-component/testsuite/G5TestSuiteEditView';
import TestCaseLinkingWithTreeView from '../../components/cloud-studio-component/testsuite/TestCaseLinkingWithTreeView';
import NotificationHandler from '../../components/cloud-studio-component/handler/NotificationHandler';
import TestSuiteService from '../../components/cloud-studio-component/service/TestSuiteService';
import TestObjectPublisher from '../../components/cloud-studio-component/service/TestObjectPublisher';
import Routes from '../../utils/Routes';
import TestProjectHelper from '../../utils/TestProjectHelper';
import { sendAnalyticEventForAction } from '../../utils/SegmentAnalytics';
import { isSystemTestSuite } from '../../models/model/TestSuite';

class EditTestSuite extends PageComponent {

  constructor(props) {
    super(props);
    this.testSuiteId = MContext.testSuiteId;
    this.state = {
      testSuite: null,
      selectedLinkingTestCases: [],
      testSuiteName: null,
      openPublishTestCloudTestSuite: false,
    };
    this.renderHeader = this.renderHeader.bind(this);
    this.renderBody = this.renderBody.bind(this);
    this.handleChangeName = this.handleChangeName.bind(this);
    this.handleCancelPublishCloudStudioTS = this.handleCancelPublishCloudStudioTS.bind(this);
    this.renderTableWithAddedOptions = this.renderTableWithAddedOptions.bind(this);
    this.setSelectLinkingTestCases = this.setSelectLinkingTestCases.bind(this);
    this.handlePublish = this.handlePublish.bind(this);
  }

  componentDidMount() {
    this.getTestSuite(this.testSuiteId);
    this.getTestCases(this.testSuiteId);
  }

  getTestSuite(testSuiteId) {
    Services.getTestSuite(testSuiteId)
      .then((responseJson) => {
        const testSuite = responseJson;
        this.meta.title = t('Test Suite {{name}}', {
          name: testSuite.name,
        });
        this.setState({
          testSuite,
          testSuiteName: testSuite.name
        });
      }).catch(() => { /* ignored */ });
  }

  getTestCases(testSuiteId) {
    const params = {
      pagination: {
        page: 0,
        size: MAX_PAGE_SIZE,
        sorts: ['sortOrder, asc NULLS LAST'],
      },
      conditions: [
        buildSearchCondition('TestSuite.id', '=', testSuiteId)
      ],
      type: SearchEntity.TestSuiteTestCase,
    };
    Services.searchRecursively(0, params, [])
      .then((content) => {
        const selectedLinkingTestCases = [];
        content.forEach((testSuiteTestCase, index) => {
          testSuiteTestCase.testCase.row_number = index + 1;
          selectedLinkingTestCases.push(testSuiteTestCase.testCase);
        });
        this.setState({ selectedLinkingTestCases });
      });
  }

  renderTestSuiteBreadcrumbs() {
    const { testSuite } = this.state;
    const paths = DecoratorConstants.addSourceOfTestCaseAndTestSuite(testSuite.path, testSuite.testProject).split('/').map((path) => <div>{path}</div>);
    return (
      <Breadcrumbs maxItems={8} itemsAfterCollapse={2}>
        {paths}
      </Breadcrumbs>
    );
  }

  renderObjectSummary() {
    const project = this.state.testSuite.project;
    const urlParams = new ObjectSummaryParametersBuilder();
    return (
      <ObjectSummary
        params={
          urlParams.toTestSuiteHistoryParams(
            project,
            this.state.testSuite,
          )
        }
      />
    );
  }

  renderHeader() {
    return (
      <>
        {this.renderTestSuiteBreadcrumbs()}
        <div className="header-with-back-icon">
          {this.renderObjectSummary()}
        </div>
      </>
    );
  }

  handleSave() {
    const { selectedLinkingTestCases, testSuiteName, testSuite } = this.state;
    const testSuiteId = testSuite.id;
    if (selectedLinkingTestCases.length !== 0) {
      const updatedTestSuite = {
        name: testSuiteName,
        testCases: selectedLinkingTestCases
      };
      Services.editTestSuite(testSuiteId, updatedTestSuite).then(() => {
        Notification.pushSuccess(t('test-suite-update#notification-success', { name: testSuiteName }));
        setTimeout(() => {
          PageHistoryHelper.redirectInEditTestSuite();
        }, WAITING_TIME_FOR_TOAST);
      });
    } else {
      Notification.pushError(t('test-suite-update#notification-empty-test-cases'));
    }
  }

  handleCancel() {
    PageHistoryHelper.redirectInEditTestSuite();
  }

  handleChangeName(event) {
    const input = event.target.value;
    this.setState({ testSuiteName: input });
  }

  publishTestProjectErrorHandler(message, label, jqXHR) {
    NotificationHandler.publishTestProjectErrorHandler(message, jqXHR);
  }

  handlePublish() {
    const { testSuite, selectedLinkingTestCases } = this.state;
    const { testProject, name, path, id } = testSuite;
    const publishTestSuite = TestSuiteService.buildPublishTestSuite(id, name, path, TestSuiteType.CLOUD_STUDIO, selectedLinkingTestCases);

    TestObjectPublisher.publishTestObject(testProject.id, [], [publishTestSuite], '', this.publishTestProjectErrorHandler)
      .then((publishedTestProject) => {
        const publishedTestSuite = publishedTestProject.testSuites[0];
        Notification.pushSuccess(t('publish-test-suite#successfully-save', { testSuiteName: name, testSuitePath: `${testProject.name}/${path}` }));
        setTimeout(() => {
          Routes.goToTestSuitesDetailsLink(publishedTestSuite.urlId);
        }, 2000);
      })
      .catch(() => {});
    const storageType = TestProjectHelper.getStorageType(testProject);
    sendAnalyticEventForAction('publish-cloud-studio-ts', { storage_type: storageType });
  }

  handlePublishCloudStudioTS() {
    if (MFlags.removeGitSupportForCloudStudioEnabled) {
      this.handlePublish();
    } else {
      this.setState({
        openPublishTestCloudTestSuite: true,
      });
    }
  }

  handleCancelPublishCloudStudioTS() {
    this.setState({
      openPublishTestCloudTestSuite: false,
    });
  }

  renderPublishCloudStudioButton() {
    const title = MFlags.removeGitSupportForCloudStudioEnabled ? t('save') : t('save_and_publish');
    return (
      <Button
        id="save-btn"
        title={title}
        color="primary"
        onClick={() => this.handlePublishCloudStudioTS()}
        data-trackid="save-publish-cloud-studio-test-suite"
        data-groupid={GroupEvent.ACCESS_TEST_MANAGEMENT}
      >
        {title}
      </Button>
    );
  }

  renderToolbar() {
    const { type } = this.state.testSuite;
    return (
      <PageButtonToolbar>
        <Button
          id="cancel-btn"
          title={t('cancel')}
          color="secondary"
          onClick={() => this.handleCancel()}
          data-trackid="cancel-edit-test-suite"
          data-groupid={GroupEvent.ACCESS_TEST_MANAGEMENT}
        >
          { t('cancel') }
        </Button>
        {type === TestSuiteType.TESTOPS && (
          <Button
            id="save-btn"
            title={t('save')}
            color="primary"
            onClick={() => this.handleSave()}
            data-trackid="save-edit-test-suite"
            data-groupid={GroupEvent.ACCESS_TEST_MANAGEMENT}
          >
            { t('save') }
          </Button>
        )}
        {type === TestSuiteType.CLOUD_STUDIO && this.renderPublishCloudStudioButton()}
      </PageButtonToolbar>
    );
  }

  renderInputNameOfTestSuite() {
    const { testSuiteName } = this.state;
    return (
      <>
        <Form>
          <FormGroup>
            <Label className="label-edit-name-ts" for="name">{t('test_suite_name')}</Label>
            <Input
              id="name"
              name="name"
              value={testSuiteName}
              type="text"
              onChange={this.handleChangeName}
            />
          </FormGroup>
        </Form>
      </>
    );
  }

  fillIndexForLinkingTestCases = (listTestCases) => {
    listTestCases.forEach((row, index) => {
      row.row_number = index + 1;
    });
  };

  addLinkingTestCases = (testCaseValue) => {
    let newSelectedLinkingTestCases = this.state.selectedLinkingTestCases;
    newSelectedLinkingTestCases = newSelectedLinkingTestCases.concat(testCaseValue);
    this.fillIndexForLinkingTestCases(newSelectedLinkingTestCases);
    this.setState({
      selectedLinkingTestCases: newSelectedLinkingTestCases
    });
  };

  removeLinkingTestCases = (testCaseValue) => {
    let newSelectedLinkingTestCases = this.state.selectedLinkingTestCases;
    newSelectedLinkingTestCases = newSelectedLinkingTestCases.filter((item) => item !== testCaseValue);
    this.fillIndexForLinkingTestCases(newSelectedLinkingTestCases);
    this.setState({
      selectedLinkingTestCases: newSelectedLinkingTestCases
    });
  };

  setSelectLinkingTestCases = (testCaseValue) => {
    this.setState({
      selectedLinkingTestCases: testCaseValue
    });
  }

  renderTableWithAddedOptions() {
    const { selectedLinkingTestCases, testSuite } = this.state;
    const { testProject, type } = testSuite;
    const testType = type === TestSuiteType.TESTOPS ? TEST_TYPE.G4_TEST_CASE : TEST_TYPE.G5_TEST_CASE;
    const trackingProps = {
      addButton: 'add-selected-test-cases-in-test-suite-details',
      clearButton: 'clear-selected-test-cases-in-test-suite-details',
      removeTestCaseIcon: 'delete-selected-test-cases-in-test-suite-details',
    };
    return (
      <TestCaseLinkingWithTreeView
        key={testProject.id}
        setSelectedLinkingTestCases={this.setSelectLinkingTestCases}
        selectedLinkingTestCases={selectedLinkingTestCases}
        testProject={testProject}
        testCaseType={testType}
        trackingProps={trackingProps}
      />
    );
  }

  renderBody() {
    const { testSuite, selectedLinkingTestCases, openPublishTestCloudTestSuite } = this.state;
    return (
      <>
        {this.renderToolbar()}
        <Card className="edit-test-suite-card">
          <CardBody>
            <Row className="edit-test-suite-component">
              <Col>
                {testSuite.type === TestSuiteType.TESTOPS && this.renderInputNameOfTestSuite()}
                {this.renderTableWithAddedOptions()}
                {openPublishTestCloudTestSuite && (
                  <G5TestSuiteEditView
                    selectedLinkingTestCases={selectedLinkingTestCases}
                    handleClose={this.handleCancelPublishCloudStudioTS}
                    isOpen={openPublishTestCloudTestSuite}
                    testSuite={testSuite}
                  />
                )}
              </Col>
            </Row>
          </CardBody>
        </Card>
      </>
    );
  }

  render() {
    const { removeGitSupportForCloudStudioEnabled } = MFlags;
    const { testSuite } = this.state;

    const g5Editor = testSuite
      && MFlags.g5Editor
      && testSuite.testProject
      && testSuite.type === TestSuiteType.CLOUD_STUDIO &&
      (removeGitSupportForCloudStudioEnabled ? testSuite.testProject.type === TestProjectType.CLOUD : true);

    if (!isSystemTestSuite(testSuite) && ((testSuite && testSuite.type === TestSuiteType.TESTOPS) || g5Editor)) {
      return (
        <DefaultLayout
          renderHeader={this.renderHeader}
          renderCustomBodyLayout={this.renderBody}
        />
      );
    }
    return <div>No history</div>;
  }
}

export default EditTestSuite;
