import { Button, ButtonToolbar } from 'reactstrap';
import { CircularProgress, DialogActions, DialogContent, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { isEmpty } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import Notification from '../../utils/Notification';
import TabsDialogs from './TabsDialog';
import { t } from '../../i18n/t';
import {
  createAndUpdateConfiguration,
  createConfigurationAndRun,
  createConfigurationAndTrigger,
  EXECUTION_MODE_OPTION_MAP,
  getBaselineCollectionGroups,
  getDefaultRunConfiguration,
  getDefaultScheduler,
  getDefaultSchedulerButtonName,
  getDefaultSchedulerEnableRepeat,
  getDefaultSchedulerTypeExecutionOptions,
  getDefaultTestProject,
  getKSVersions,
  getSmartTestScheduler,
  getTestCloudEnvironments,
  getTestCloudMobileDevices,
  getTestProjects, KRE_LATEST_OPTION, KRE_LATEST_OPTION_VALUE,
  trackTestCloudScheduleTestRunSuccessEvent,
} from '../../pages/plan/ConfigurePlanUtils';
import { TypeExecutionOptions, } from '../smarttestscheduling/utils/SmartTestSchedulingConstants';
import MContext from '../../models/MContext';
import { IconJiraRelease, IconJiraReleaseInactive } from '../../images/CustomIcon';
import ScheduleTestRun from '../smarttestscheduling/components/ScheduleTestRun';
import MenuButton from '../smarttestscheduling/components/MenuButton';
import {
  checkMobileAppLicenseActive,
  getGroupTestCloudAgentOptions,
  groupTestCloudAgentsByOsBrowser,
} from '../smarttestscheduling/services/testcloud';
import {
  Channel,
  CloudType,
  ConfigType, EXECUTION_DIALOG_NOTIFICATION_TIMEOUT,
  ExecutionMode,
  SearchEntity, TAG_ENTITY_TYPE, TestSuiteType
} from '../../utils/Constants';
import Services from '../../utils/Services';
import {
  runConfigurationToFormData,
  schedulerToFormData,
  formDataToRunConfiguration, formDataToScheduler, extractCloudTypeInAgentByConfigType
} from '../smarttestscheduling/services/scheduleTestRun';
import {
  closeScheduleTestRunDialogEvent,
  clickScheduleTestRunButtonEvent,
  track,
  clickRunTestRunButtonEvent,
  clickSaveConfigurationButtonEvent,
  scheduleTestRunSuccessEvent,
  goToAdvanceTab,
  openScheduleTestRunDialogEvent
} from '../smarttestscheduling/services/scheduleTestRunTracking';
import DecoratorConstants from '../../utils/DecoratorConstants';
import TooltipComponent from '../TooltipComponent';
import Routes from '../../utils/Routes';
import MFlags from '../../models/MFlags';
import cookieUtil from '../../utils/testcloud/cookieUtil';

function ScheduleTestRunDialogV1({ isOpen, handleClose, params }) {
  const form = useForm();
  const { handleSubmit, reset } = form;
  const { trigger } = form;

  const typeExecutions = [
    {
      label: t('create-schedule'),
      title: 'schedule',
      value: TypeExecutionOptions.TESTOPS_SCHEDULER
    },
    {
      label: t('run'),
      title: 'run',
      value: TypeExecutionOptions.TESTOPS_SCHEDULER
    },
    {
      label: <><span>{t('save-configuration')}</span><TooltipComponent arrow placement="top" text={t('schedule-v1#manual-trigger-tooltip')} /></>,
      title: 'save-configuration',
      value: TypeExecutionOptions.MANUAL_TRIGGER
    }
  ];

  const { runConfigurationId, teamId, projectId, team } = MContext;

  const [trackId] = useState(() => uuidv4());
  const [isOnInitialTab, setOnInitialTab] = useState(true);
  const [loading, setLoading] = useState(true);
  const [testProjects, setTestProjects] = useState();
  const [releases, setReleases] = useState([]);
  const [originKsVersions, setOriginKsVersions] = useState([]);
  const [ksVersions, setKsVersions] = useState([]);
  const [runConfiguration, setRunConfiguration] = useState(() => getDefaultRunConfiguration());
  const [scheduler, setScheduler] = useState(() => getDefaultScheduler());
  const [executionEnvironments, setExecutionEnvironments] = useState({ raw: [], data: [] });
  const [g5ExecutionEnvironments, setG5ExecutionEnvironments] = useState({ raw: [], data: [] });
  const [baselineCollectionGroups, setBaselineCollectionGroups] = useState([]);
  const [buttonName, setButtonName] = useState(() => getDefaultSchedulerButtonName());
  const [typeExecutionOptions, setTypeExecutionOptions] = useState(() => getDefaultSchedulerTypeExecutionOptions(typeExecutions));
  const [isXrayFeaturesEnabled, setIsXrayFeaturesEnabled] = useState(false);
  const [isSaveConfiguration, setSaveConfiguration] = useState(false);
  const [isTrialUser, setIsTrialUser] = useState();
  const [isReady, setIsReady] = useState(false);
  const [tsType, setTsType] = useState();
  const [configType, setConfigType] = useState();
  const [cloudType, setCloudType] = useState();

  useEffect(() => {
    setConfigType(runConfiguration.configType?.value || ConfigType.TS);
  }, [runConfiguration]);

  useEffect(() => {
    const { testCloudKRELatestOptionEnabled, localAgentKRELatestOptionEnabled } = MFlags;
    if (!testCloudKRELatestOptionEnabled) {
      return;
    }
    const runConfiguration = form.getValues('runConfiguration');
    if (runConfiguration && originKsVersions.length > 0) {
      const extractedCloudType = extractCloudTypeInAgentByConfigType(runConfiguration, undefined, runConfiguration.configType?.value);
      if (extractedCloudType && extractedCloudType !== cloudType) {
        if (cloudType) {
          switch (extractedCloudType) {
            case CloudType.LOCAL_AGENT:
              setKsVersions(localAgentKRELatestOptionEnabled ? [KRE_LATEST_OPTION, ...originKsVersions] : [...originKsVersions]);
              form.setValue('runConfiguration.kreVersion', {
                ksLocation: undefined,
                ksVersion: localAgentKRELatestOptionEnabled ? KRE_LATEST_OPTION_VALUE : originKsVersions[0].value,
                enableSelectKsLocation: true
              });
              break;

            case CloudType.TEST_CLOUD_AGENT:
              setKsVersions([KRE_LATEST_OPTION, ...originKsVersions]);
              form.setValue('runConfiguration.kreVersion', {
                ksLocation: undefined,
                ksVersion: KRE_LATEST_OPTION_VALUE,
                enableSelectKsLocation: false
              });
              break;

            default:
              setKsVersions(localAgentKRELatestOptionEnabled ? [KRE_LATEST_OPTION, ...originKsVersions] : [...originKsVersions]);
              form.setValue('runConfiguration.kreVersion', {
                ksLocation: undefined,
                ksVersion: localAgentKRELatestOptionEnabled ? KRE_LATEST_OPTION_VALUE : originKsVersions[0].value,
                enableSelectKsLocation: false
              });
          }
        }
        setCloudType(extractedCloudType);
      }
    }
  }, [form.formState]); // eslint-disable-line react-hooks/exhaustive-deps

  const generateIcon = ({ externalRelease }) => {
    if (!isEmpty(externalRelease)) {
      return (
        <>
          <IconJiraReleaseInactive className="icon-inactive" />
          <IconJiraRelease className="icon-active" />
        </>
      );
    }
    return null;
  };

  const toTestProjectOptions = (testProjects) => testProjects.map((testProject) => ({
    ...testProject,
    value: testProject.id,
    icon: DecoratorConstants.codeRepoType('type', testProject),
    selectedIcon: DecoratorConstants.codeRepoTypeSelected('type', testProject),
    label: testProject.name,
  }));

  const toReleaseOptions = (testopsReleases) => testopsReleases.map((release) => ({
    ...release,
    value: release.id,
    label: release.name,
    rightIcon: generateIcon(release)
  }));

  const getRelease = (releaseId, releases) => toReleaseOptions(releases)?.find((value) => value?.value === releaseId);

  const getTestProject = (id, testProjects, defaultTestProject) => {
    if (id) {
      return testProjects.find((tp) => tp.id === Number(id));
    }

    return defaultTestProject?.id ? defaultTestProject : testProjects[0];
  };

  const getExternalTestPlanId = async (xrayFeaturesEnabled, runConfigurationId) => {
    if (xrayFeaturesEnabled) {
      const xrayTestPlan = await Services.getXrayTestPlanByRunConfiguration(runConfigurationId);
      const externalIssue = xrayTestPlan && xrayTestPlan.content.length > 0 ? xrayTestPlan.content[0] : false;
      if (externalIssue) {
        return externalIssue.issueId;
      }
    }

    return undefined;
  };

  const fetchData = async () => {
    const { testCloudKRELatestOptionEnabled, localAgentKRELatestOptionEnabled } = MFlags;
    const releaseSearchParams = {
      pagination: {
        page: 0,
        size: 1000000,
        sorts: ['id,asc'],
      },
      conditions: [
        {
          key: 'Project.id',
          operator: '=',
          value: MContext.projectId,
        },
        {
          key: 'closed',
          operator: '=',
          value: false
        }
      ],
      type: SearchEntity.Release,
    };

    const [
      testProjects,
      defaultTestProject,
      testcloudEnvironments,
      testcloudMobileDevices,
      g5Environments,
      testopsReleases,
      ksVersions,
      baselineCollectionGroups,
      jiraConnection,
      testExecutionQuota,
      testSessionTypes,
    ] = await Promise.all([
      getTestProjects(),
      getDefaultTestProject(MContext.projectId),
      getTestCloudEnvironments(),
      getTestCloudMobileDevices(team?.organizationId),
      getTestCloudEnvironments(Channel.G5),
      Services.search(releaseSearchParams),
      getKSVersions(),
      getBaselineCollectionGroups(MContext.projectId),
      Services.getJiraConnection(MContext.projectId),
      Services.getTestCloudOrganizationQuota(team?.organizationId),
      Services.getTestCloudOrganizationQuotas(team?.organizationId, MContext.projectId),
    ]);

    const isMobileNativeAppEnabled = checkMobileAppLicenseActive(testSessionTypes);
    MContext.testCloudMobileNativeAppEnabled = isMobileNativeAppEnabled;
    setTestProjects(toTestProjectOptions(testProjects));
    setIsReady(true);
    setReleases(toReleaseOptions(testopsReleases.content));
    setOriginKsVersions(ksVersions);
    setExecutionEnvironments({
      raw: testcloudEnvironments,
      data: getGroupTestCloudAgentOptions(testcloudEnvironments, [...testcloudMobileDevices])
    });
    setG5ExecutionEnvironments({
      raw: g5Environments,
      data: groupTestCloudAgentsByOsBrowser(g5Environments)
    });
    setIsTrialUser(testExecutionQuota?.trialUser);

    const defaultOption = {
      value: null,
      label: t('run-configuration#baseline-collection-default'),
    };
    const updatedBaselineCollectionGroups = baselineCollectionGroups.map(({ order, name }) => ({
      value: order,
      label: DecoratorConstants.nameBaselineCollectionAndIdDecorator(order, name)
    }));
    setBaselineCollectionGroups([defaultOption, ...updatedBaselineCollectionGroups]);

    const connection = jiraConnection.content && jiraConnection.content.length > 0 ? jiraConnection.content[0] : {};
    const xrayFeaturesEnabled = connection && connection.enabledXray;
    setIsXrayFeaturesEnabled(xrayFeaturesEnabled);
    if (runConfigurationId) {
      const [
        { runConfiguration, scheduler },
        runConfigurationTags,
        externalTestPlanId
      ] = await Promise.all([
        getSmartTestScheduler(runConfigurationId),
        Services.getTagEntities(projectId, runConfigurationId, TAG_ENTITY_TYPE.TEST_SCHEDULE),
        getExternalTestPlanId(xrayFeaturesEnabled, runConfigurationId)
      ]);

      const runConfigurationFormData = runConfigurationToFormData({ ...runConfiguration, externalTestPlanId });
      const schedulerFormData = schedulerToFormData(scheduler);
      const repeatEnable = (runConfiguration.triggerMode === TypeExecutionOptions.MANUAL_TRIGGER ? getDefaultSchedulerEnableRepeat() : !!scheduler);
      const baselineCollectionGroupOrder = runConfiguration.baselineCollectionGroupOrder || defaultOption.value;
      const release = getRelease(runConfigurationFormData?.release?.id, testopsReleases?.content);

      const shouldIncludeLatestKREOption = testCloudKRELatestOptionEnabled && (localAgentKRELatestOptionEnabled || runConfiguration.cloudType === CloudType.TEST_CLOUD_AGENT);
      setKsVersions(shouldIncludeLatestKREOption ? [KRE_LATEST_OPTION, ...ksVersions] : [...ksVersions]);

      reset({
        scheduler: { ...schedulerFormData, repeatEnable },
        runConfiguration: {
          ...runConfigurationFormData,
          tags: runConfigurationTags,
          baselineCollectionGroupOrder,
          release
        }
      });
      setRunConfiguration(runConfigurationFormData);
      setScheduler({ ...schedulerFormData, repeatEnable });
      setButtonName(repeatEnable ? t('create-schedule') : t('run'));
      setTypeExecutionOptions(repeatEnable ? typeExecutions?.filter((t) => t.title !== 'run') : typeExecutions?.filter((t) => t.title !== 'schedule'));
    } else {
      const { testProjectId, testSuiteCollectionId, releaseId, testSuiteId } = params || {};
      const kreVersion = ksVersions && ksVersions.length > 0 ? {
        ksVersion: MFlags.testCloudKRELatestOptionEnabled ? KRE_LATEST_OPTION_VALUE : ksVersions[0].value
      } : {};
      const testProject = getTestProject(testProjectId, testProjects, defaultTestProject);

      if (MFlags.testCloudKRELatestOptionEnabled) {
        setKsVersions([KRE_LATEST_OPTION, ...ksVersions]);
        setCloudType(CloudType.TEST_CLOUD_AGENT);
      } else {
        setKsVersions(ksVersions);
      }
      reset(
        {
          runConfiguration: {
            name: runConfiguration.name,
            testProjectId: testProject?.id,
            kreVersion,
            executionMode: EXECUTION_MODE_OPTION_MAP[ExecutionMode.SEQUENTIAL],
            timeOut: 180,
            enabledKobitonIntegration: false,
            baselineCollectionGroupOrder: defaultOption.value,
            xrayIntegration: {},
            configType: { value: testSuiteCollectionId ? ConfigType.TSC : ConfigType.TS },
            testSuiteCollectionId: testSuiteCollectionId ? Number(testSuiteCollectionId) : runConfiguration.testSuiteCollectionId,
            testSuiteId: testSuiteId ? Number(testSuiteId) : runConfiguration.testSuiteId,
            release: releaseId && getRelease(Number(params?.releaseId), testopsReleases?.content),
          }
        }
      );

      setRunConfiguration({
        ...runConfiguration,
        testProject,
        executionMode: EXECUTION_MODE_OPTION_MAP[ExecutionMode.SEQUENTIAL],
        enabledKobitonIntegration: false,
        baselineCollectionGroupOrder: defaultOption.value,
        timeOut: 180,
        kreVersion,
        xrayIntegration: {},
        configType: { value: testSuiteCollectionId ? ConfigType.TSC : ConfigType.TS },
        testSuiteCollectionId: testSuiteCollectionId ? Number(testSuiteCollectionId) : runConfiguration.testSuiteCollectionId,
        release: params?.releaseId && getRelease(Number(params?.releaseId), testopsReleases?.content),
      });
    }
  };

  const handleGoBack = () => {
    trigger('runConfiguration.timeOut').then((result) => {
      if (result === true) {
        setOnInitialTab(true);
      }
    });
  };

  const validateData = (formData) => {
    const { agents, cloudType } = formData;
    if (cloudType?.value === CloudType.LOCAL_AGENT && !isEmpty(agents)) {
      if (agents.find((agent) => !agent.active)) {
        return t('msg#local-agent-inactive');
      }
    }
    return null;
  };

  const handleGoToAdvance = () => {
    setOnInitialTab(false);
    track(goToAdvanceTab(trackId));
  };

  const addCookiesToShowNewTestRunSurvey = () => {
    const { testCloudNewScheduleTestRunSurveyEnabled } = MFlags;
    if (testCloudNewScheduleTestRunSurveyEnabled) {
      cookieUtil.setCookie('scheduleTestRunSuccess', 'enabled');
    }
  };

  const onSubmitScheduleTestRun = (item) => (formData) => {
    const runConfigurationFormData = formDataToRunConfiguration(formData);
    const schedulerFormData = formDataToScheduler(formData);
    const { name: runConfigurationName } = runConfigurationFormData;
    if (item.value === TypeExecutionOptions.MANUAL_TRIGGER) {
      createAndUpdateConfiguration(runConfigurationFormData, teamId, projectId, runConfigurationId, trackId).then(async () => {
        await track(scheduleTestRunSuccessEvent(trackId));
        addCookiesToShowNewTestRunSurvey();
        if (MFlags.testcloudExecutionEnvMicroAppEnabled && !runConfigurationId) { // turn on flag and new run configuration
          const formattedMessage = DecoratorConstants.buildExecutionNotificationGridLink(runConfigurationName);
          if (MFlags.htmlInjection) {
            Notification.pushSuccess(null, 'Success', EXECUTION_DIALOG_NOTIFICATION_TIMEOUT, formattedMessage);
          } else {
            Notification.pushSuccess(formattedMessage, 'Success', EXECUTION_DIALOG_NOTIFICATION_TIMEOUT);
          }
          handleClose();
        } else {
          Routes.goToGridLink();
        }
      });
    } else {
      if (schedulerFormData.repeatEnable) {
        createConfigurationAndTrigger(runConfigurationFormData, teamId, projectId, schedulerFormData, runConfigurationId, trackId).then(async () => {
          if (MFlags.testCloudTrialScheduleTestRunSuccessEnabled && runConfiguration.cloudType === CloudType.TEST_CLOUD_AGENT && isTrialUser) {
            await trackTestCloudScheduleTestRunSuccessEvent();
          }
          await track(scheduleTestRunSuccessEvent(trackId));
          addCookiesToShowNewTestRunSurvey();
          if (MFlags.testcloudExecutionEnvMicroAppEnabled && !runConfigurationId) { // turn on flag and new run configuration
            const formattedMessage = DecoratorConstants.buildExecutionNotificationGridLink(runConfigurationName);
            if (MFlags.htmlInjection) {
              Notification.pushSuccess(null, 'Success', EXECUTION_DIALOG_NOTIFICATION_TIMEOUT, formattedMessage);
            } else {
              Notification.pushSuccess(formattedMessage, 'Success', EXECUTION_DIALOG_NOTIFICATION_TIMEOUT);
            }
            handleClose();
          } else {
            Routes.goToGridLink();
          }
        });
      } else {
        const msg = validateData(runConfigurationFormData);
        if (msg) {
          Notification.pushError(msg);
        } else {
          createConfigurationAndRun(runConfigurationFormData, teamId, projectId, runConfigurationId, trackId).then(async () => {
            if (MFlags.testCloudTrialScheduleTestRunSuccessEnabled && runConfiguration.cloudType === CloudType.TEST_CLOUD_AGENT && isTrialUser) {
              await trackTestCloudScheduleTestRunSuccessEvent();
            }
            await track(scheduleTestRunSuccessEvent(trackId));
            addCookiesToShowNewTestRunSurvey();
            if (MFlags.testcloudExecutionEnvMicroAppEnabled && !runConfigurationId) { // turn on flag and new run configuration
              const formattedMessage = DecoratorConstants.buildExecutionNotificationHistoryLink(runConfigurationName);
              if (MFlags.htmlInjection) {
                Notification.pushSuccess(null, 'Success', EXECUTION_DIALOG_NOTIFICATION_TIMEOUT, formattedMessage);
              } else {
                Notification.pushSuccess(formattedMessage, 'Success', EXECUTION_DIALOG_NOTIFICATION_TIMEOUT);
              }
              handleClose();
            } else {
              Routes.goToGridLink();
            }
          });
        }
      }
    }
  };

  const onErrorScheduleTestRun = (errors, e) => console.log(errors, e);

  const handleClickScheduleButton = (item) => {
    if (item.value === TypeExecutionOptions.MANUAL_TRIGGER) {
      track(clickSaveConfigurationButtonEvent(trackId));
    } else if (buttonName === t('run')) {
      track(clickRunTestRunButtonEvent(trackId));
    } else if (buttonName === t('create-schedule')) {
      track(clickScheduleTestRunButtonEvent(trackId));
    }
    setSaveConfiguration(item.value === TypeExecutionOptions.MANUAL_TRIGGER);
    handleSubmit(onSubmitScheduleTestRun(item), onErrorScheduleTestRun)();
  };

  const closeDialog = (e) => {
    handleClose(e);
    track(closeScheduleTestRunDialogEvent(trackId));
  };

  useEffect(() => {
    if (isOpen) {
      track(openScheduleTestRunDialogEvent(trackId));
    }
  }, [isOpen, trackId]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    try {
      await fetchData();
    } catch (err) {
      Notification.pushError(err, 'Error');
    }
    setLoading(false);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleRepeatToggle = (value) => {
    if (!value) {
      setTypeExecutionOptions(typeExecutions.filter((t) => t.title !== 'schedule'));
      setButtonName(t('run'));
    } else {
      setTypeExecutionOptions(typeExecutions.filter((t) => t.title !== 'run'));
      setButtonName(t('create-schedule'));
    }
  };

  return (
    <form>
      <TabsDialogs
        id="smart-scheduler-dialog"
        isOpen={isOpen}
        handleClose={closeDialog}
        isOnInitialTab={isOnInitialTab}
        handleGoBack={handleGoBack}
        maxWidth="md"
        dialogName={t('create-plan')}
        headerStyle="schedule-header"
        disablePortal
      >
        <DialogContent style={{ minHeight: '50vh' }} className={`schedule-content ${loading ? 'opacity-40' : ''}`}>
          {loading &&
          <Stack sx={{ zIndex: '9999', position: 'absolute', marginTop: '20%', marginLeft: '45%', marginRight: '50%' }}>
            <CircularProgress color="secondary" />
          </Stack>}
          <ScheduleTestRun
            runConfiguration={runConfiguration}
            scheduler={scheduler}
            testProjects={testProjects}
            executionEnvironments={executionEnvironments}
            g5ExecutionEnvironments={g5ExecutionEnvironments}
            releases={releases}
            ksVersions={ksVersions}
            baselineCollectionGroups={baselineCollectionGroups}
            advanceMode={!isOnInitialTab}
            isXrayFeaturesEnabled={isXrayFeaturesEnabled}
            form={form}
            trackId={trackId}
            handleRepeatToggle={handleRepeatToggle}
            handleChangeObjectName={(value) => setTsType(value)}
            handleChangeTab={(value) => setConfigType(value)}
            isSaveConfiguration={isSaveConfiguration}
            isReady={isReady}
          />
        </DialogContent>
        {isOnInitialTab &&
          <DialogActions className="d-flex justify-content-between schedule-footer">
            {(!isEmpty(testProjects) || !isReady) &&
            <>
              <div>
                { MFlags.testCloudG5TestSuiteEnabled && tsType === TestSuiteType.CLOUD_STUDIO && configType === ConfigType.TS ? null :
                <Button
                  id="go-to-advance-tab"
                  color="link"
                  onClick={handleGoToAdvance}
                >
                  {t('advanced-setting')}
                </Button>}
              </div>
              <ButtonToolbar>
                <MenuButton
                  title={buttonName}
                  options={typeExecutionOptions}
                  onChange={handleClickScheduleButton}
                />
              </ButtonToolbar>
            </>}
          </DialogActions>}
      </TabsDialogs>
    </form>
  );
}

export default ScheduleTestRunDialogV1;
