import { union } from 'lodash';
import Routes from './Routes';
import MFlags from '../models/MFlags';
import SessionStorageHelper from './SessionStorageHelper';

export const PageHistoryComponents = {
  TESTCASE_TREE_VIEW: 'testCaseTreeView',
  TESTSUITE_TREE_VIEW: 'testSuiteTreeView',
  TESTSUITE_VIEW: 'testSuiteView',
  MOVE_TO_LOCATION_DIALOG: 'moveToLocationDialog'
};

const PageHistoryHelper = {

  isRetainHistoryEnabled: (component) => MFlags.openLastAccessedFolderTestCase &&
    component.toString() === PageHistoryComponents.TESTCASE_TREE_VIEW,

  /**
   * Get state of current page from history
   * @param {PageHistoryComponents} component component name got from PageHistoryComponents
   * @returns history state
   */
  getState: (component) => {
    const oldState = PageHistoryHelper.isRetainHistoryEnabled(component) ?
      SessionStorageHelper.getPageHistory() :
      window.history.state;
    if (oldState) {
      return oldState[component];
    }
    return null;
  },

  /**
   * Save history state for current page
   * @param {*} state object to be saved
   * @param component component name got from PageHistoryComponents
   */
  saveState: (component, state) => {
    const retainHistoryEnabled = PageHistoryHelper.isRetainHistoryEnabled(component);
    let historyState = retainHistoryEnabled ? SessionStorageHelper.getPageHistory() : window.history.state;
    if (!historyState) {
      historyState = {};
    }
    historyState[component] = state;
    if (retainHistoryEnabled) {
      SessionStorageHelper.savePageHistory(historyState);
    } else {
      window.history.pushState(historyState, '');
    }
  },

  goBack: () => {
    window.history.back();
  },

  goBackInTestCaseDetail: () => {
    if (document.referrer && window.history.length > 1) {
      PageHistoryHelper.goBack();
    } else {
      // When we access with URL or open in new tab, go back to test_cases_link with initial view
      Routes.goToTestCasesLink();
    }
  },

  goBackInTestSuiteDetail: () => {
    const linkEditPage = `${window.location.href}/edit`;
    if (document.referrer && window.history.length > 1 && document.referrer !== linkEditPage) {
      PageHistoryHelper.goBack();
    } else {
      // When we access with URL or open in new tab, go back to test_cases_link with initial view
      Routes.goToTestSuitesLink();
    }
  },

  redirectInEditTestSuite: () => {
    if (document.referrer && window.history.length > 1) {
      PageHistoryHelper.goBack();
    } else {
      // When we access with URL or open in new tab, go back to test_cases_link with initial view
      Routes.goToTestSuitesDetailsLink();
    }
  },
  handleTreePathToExpand: (testFolder, testProjectId) => {
    const { treePath, id } = testFolder;
    const expandedKeys = treePath.split('.').map(Number);
    // first expandedKeys is Nah, so I set it is "root_{testProjectId)"
    expandedKeys[0] = `root_${testProjectId}`;
    expandedKeys.push(id);
    return expandedKeys;
  },

  updateHistory: (testFolder, testProjectId, component) => {
    const historyState = PageHistoryHelper.getState(component);
    const selectedKeys = [testFolder.id];
    const expandedKeysTemp = PageHistoryHelper.handleTreePathToExpand(testFolder, testProjectId);
    // if expandedKeys is null -> set it, if is not null, combine 2 array without duplicate elements
    const expandedKeys = historyState?.expandedKeys
      ? union(historyState.expandedKeys, expandedKeysTemp)
      : expandedKeysTemp;
    PageHistoryHelper.saveState(component, { selectedKeys, expandedKeys });
  }

};

export default PageHistoryHelper;
