import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';

function VideoPlayer(props) {
  const videoRef = useRef(null);
  const playerRef = useRef(null);
  const { src, fileType } = props;

  const options = {
    controls: true,
    width: 800,
    sources: [
      {
        src,
        type: `video/${fileType}`,
      },
    ],
  };

  const handlePlayerReady = (player) => {
    playerRef.current = player;

    player.on('error', (error) => {
      videojs.log('The following error occurred:', error);
    });
  };

  useEffect(() => {
    let player = playerRef.current;
    // Make sure Video.js player is only initialized once
    if (!player) {
      // The Video.js player needs to be _inside_ the component el for React 18 Strict Mode.
      const videoElement = document.createElement('video-js');

      videoElement.classList.add('vjs-big-play-centered');
      videoRef.current.appendChild(videoElement);

      player = videojs(videoElement, options);
      handlePlayerReady(player);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Dispose the Video.js player when the functional component unmounts
  useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player && !player.isDisposed()) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  return (
    <div data-vjs-player>
      <div ref={videoRef} />
    </div>
  );
}

VideoPlayer.propTypes = {
  src: PropTypes.string.isRequired,
  fileType: PropTypes.string.isRequired,
};

export default VideoPlayer;
