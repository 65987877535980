import React, { Component } from 'react';
import StatusBar from '../../components/chart/status-bar/StatusBar';
import { buildSearchFunction, buildSearchCondition } from '../../components/search/SearchUtils';
import DataLoader from '../../components/table/DataLoader';
import StatusConstants from '../../utils/Statuses';
import MContext from '../../models/MContext';

class ReleaseStatusBar extends Component {
  constructor(props) {
    super(props);
    this.renderChart = this.renderChart.bind(this);
    this.releaseId = MContext.releaseId;
    this.projectId = MContext.projectId;
  }

  convertData(data) {
    const statuses = {};
    data.forEach((item) => {
      statuses[item.status.toLowerCase()] = item.count;
    });

    return statuses;
  }

  counterItems(data) {
    const statuses = this.convertData(data);

    let total = 0;
    data.forEach((item) => {
      total += item.count;
    });

    return [
      {
        key: `total-${total}`,
        label: 'Total Active',
        value: total,
      },
      {
        key: statuses.ready,
        label: StatusConstants.READY.label,
        value: statuses.ready || 0,
      },
      {
        key: statuses.not_ready,
        label: StatusConstants.NOT_READY.label,
        value: statuses.not_ready || 0,
      },
      {
        key: statuses.empty,
        label: StatusConstants.EMPTY.label,
        value: statuses.empty || 0,
      },
    ];
  }

  convertDataChart(data) {
    const statuses = this.convertData(data);

    const items = ['ready', 'not_ready', 'empty'].reduce((prevItems, status) => {
      const count = statuses[status];
      if (count <= 0) {
        return prevItems;
      }
      return [
        ...prevItems,
        {
          key: status,
          value: count || 0,
          label: status,
          color: status,
        },
      ];
    }, []);

    return items;
  }

  renderChart(data) {
    const counterItems = this.counterItems(data);
    const dataChart = this.convertDataChart(data);
    return <StatusBar dataInfo={counterItems} dataChart={dataChart} />;
  }

  render() {
    return (
      <DataLoader
        render={this.renderChart}
        entityType="ReleaseStatisticsStatus"
        defaultSearchFunctions={[]}
        defaultSearchConditions={[
          buildSearchCondition('Project.id', '=', this.projectId),
        ]}
        defaultSort={['id,asc']}
        noCard
        {...this.props}
      />
    );
  }
}

export default ReleaseStatusBar;
