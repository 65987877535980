import React, { Component } from 'react';
import { Label } from 'reactstrap';
import PropTypes from 'prop-types';
import Input from '../../Input';

class InputFilterV2 extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();

    this.buildFilter = this.buildFilter.bind(this);
    this.onInputSearchChange = this.onInputSearchChange.bind(this);
  }

  /*
   * Value transmission from search bar to component has 2 case:
   * 1. "~katalon"
   * 2. ~katalon
   * Check to get value is: katalon
   * Using: check first character
   */
  getInitialState() {
    const { value } = this.props;
    const isFull = value?.startsWith('"');
    let searchValue = '';
    searchValue = isFull ? value.substring(2, value.length - 1) : value.substring(1);
    return {
      searchInput: searchValue
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { value } = nextProps;
    const { searchInput } = prevState;

    /*
     * When user click button "Clear" in component SearchQuery then value will be null.
     * I check the searchInput if there is a value then set it to null to clear data.
     */
    if (!!searchInput && !value) {
      return {
        searchInput: null
      };
    }
    return prevState;
  }

  onInputSearchChange(event) {
    const { target: { value } } = event;
    const { operator } = this.props;
    this.setState({
      searchInput: value
    }, this.buildFilter(operator + value));
  }

  buildFilter(value) {
    const { id, onBuildFilterSearch } = this.props;
    if (id && onBuildFilterSearch) {
      onBuildFilterSearch(id, value);
    }
  }

  render() {
    const { searchInput } = this.state;
    const { label, placeholder } = this.props;

    return (
      <div>
        <Label>{label}</Label>
        <Input
          onChange={this.onInputSearchChange}
          value={searchInput || ''}
          placeholder={placeholder}
        />
      </div>
    );
  }
}

InputFilterV2.propTypes = {
  onBuildFilterSearch: PropTypes.func,
  /**
   * Current value of input in search bar.
   */
  value: PropTypes.string,
  /**
   * Title of  filed input in search dialog.
   */
  label: PropTypes.string,
  /**
   * Placeholder of  filed input in search dialog.
   */
  placeholder: PropTypes.string,
  /**
   * Id to build filter search.
   */
  id: PropTypes.string,
};

InputFilterV2.defaultProps = {
  value: '',
  label: '',
  placeholder: '',
  id: '',
};

export default InputFilterV2;
