import React from 'react';
import DataLoader from '../../../components/table/DataLoader';
import TooltipComponent from '../../../components/TooltipComponent';
import NormalCard from '../../../components/card/NormalCard';
import { SearchEntity, ResultFilterType } from '../../../utils/Constants';
import { buildSearchFunction } from '../../../components/search/SearchUtils';
import { t } from '../../../i18n/t';
import { next } from '../../../utils/Count';
import FailedTestResultByCategoryChart from '../components/FailedTestResultByCategoryChart';


const FailedTestResultChart = ({
  selectedOption,
  dataLoaderRef,
  defaultSearchCondition,
  customFieldConditionsProp }) => {

  const renderChart = (data) => <FailedTestResultByCategoryChart data={data} />;

  const renderHeader = () => (
    <div className="failed-test-result-header-sub-title">
      {t('failure-test-result#chart-title')}
      <TooltipComponent text={t('failure-test-result#chart#tooltip-msg')} placement="bottom-end" arrow />
    </div>
  );

  const renderBody = () => (
    <DataLoader
      key={next()}
      title={renderHeader()}
      refreshDataOnPropsChange
      ref={dataLoaderRef}
      entityType={
        selectedOption.value !== ResultFilterType.ALL_RESULTS ?
          SearchEntity.LastFailureReasonStatistics :
          SearchEntity.LabelLink
      }
      defaultSearchConditions={defaultSearchCondition}
      defaultSearchFunctions={[
        buildSearchFunction('TestResults', 'count', ['entityId']),
      ]}
      customFieldConditionsProp={customFieldConditionsProp}
      groupBys={[
        'Label.name'
      ]}
      defaultSort={[
        'Label.name, asc'
      ]}
      render={renderChart}
      useTitleNoCard
      useCache
      cardClassName="failed-test-result-card-border shadow-none"
      useRefreshButton={false}
      useCollapseButton={false}
      headerComponent={NormalCard}
      bodyComponent={NormalCard}
      showEmptyMessage
      pageSize={300}
    />
  );

  return (
    <>
      <div className="failed-test-result-header-title">{t('failure-test-result#header-title')}</div>
      <div className="cards-row">
        {renderBody()}
      </div>
    </>
  );
};

export default FailedTestResultChart;
