import React from 'react';
import { groupBy, kebabCase } from 'lodash';
import platform from 'platform-detect';
import arch from 'arch';
import { t } from '../i18n/t';
import MAuth from '../models/MAuth';
import { MarketingArchitecture, MarketingPlatform, OsType, RegexTimeFilterFormat, TestRunStatus } from './Constants';
import Apis from './Apis';
import DocumentLink from './DocumentLink';

export default {

  download: (url, filename) => {
    const a = document.createElement('a');
    const body = document.getElementsByTagName('body')[0];
    a.setAttribute('href', url);
    a.setAttribute('target', '_blank');
    if (filename) {
      a.setAttribute('download', filename);
    }
    body.appendChild(a);
    a.click();
    setTimeout(() => {
      body.removeChild(a);
    }, 50);
  },
  getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[[\]]/g, '\\$&');
    const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
    const results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  },

  getDisplayName(WrappedComponent) {
    if (typeof WrappedComponent === 'string') {
      return WrappedComponent;
    }
    if (!WrappedComponent) {
      return '';
    }
    return WrappedComponent.displayName || WrappedComponent.name || 'Component';
  },

  /**
   *
   * Return logarithm base x of y
   */
  getLogBase(x, y) {
    return Math.log(y) / Math.log(x);
  },

  isCollapsedNavBar() {
    const userId = MAuth.isLoggedIn ? MAuth.user.id : '';
    const collapsed = localStorage.getItem(`katalon-collapsed-nav-bar-${userId}`) || '';
    return collapsed === 'true';
  },

  exactMatches(patterns, url, exactMatch) {
    for (let i = 0; i < patterns.length; i++) {
      const pattern = patterns[i];
      if (exactMatch) {
        if (url.endsWith(pattern)) {
          return true;
        }
      } else {
        if (url.indexOf(pattern) >= 0) {
          return true;
        }
      }
    }
    return false;
  },

  calculateThePercentage(numerator, denominator) {
    let percentage = 0;
    if (denominator !== 0) {
      percentage = ((numerator / denominator) * 100).toFixed(2);
    }
    return Number(percentage);
  },

  transformToAlphabet(options) {
    return options.sort(function (a, b) {
      return a.label.localeCompare(b.label);
    });
  },

  sortArrayAlphabet(array, sortField) {
    return array.sort((a, b) => a[sortField].localeCompare(b[sortField]));
  },

  /**
   * function return opposition the input state.
   * @param status
   * @returns {string}
   */
  getOppositionStatus(status) {
    switch (status) {
      case TestRunStatus.PASSED:
        return TestRunStatus.FAILED;
      default:
        return TestRunStatus.PASSED;
    }
  },

  /**
   * Used to check the date format when converting from string to date.
   * @param value : {string} is built buildFilter (SearchQuery).
   * @returns {boolean}
   */
  validateTimeFilterFormat(value) {
    const { FULL_FROM_TO, MISS_FROM, MISS_TO, INVALID_FROM, INVALID_TO } = RegexTimeFilterFormat;

    if (value.match(FULL_FROM_TO) || value.match(MISS_FROM) || value.match(MISS_TO) || value.match(INVALID_FROM) ||
      value.match(INVALID_TO)) {
      return true;
    }
    return false;
  },

  generatePaginationText(totalItem, unitName) {
    const recordName = unitName !== '' ? unitName : 'record';
    const textPagination = <span>{t('pagination-text', { totalItem, unitName: recordName, isMany: totalItem > 1 ? 's' : '' })}</span>;
    return textPagination;
  },

  isEmptyOrganization(organizationId) {
    if (!MAuth.user) {
      return true;
    }
    const { projects } = MAuth.user;
    let organizationsByProject = {};
    if (projects && projects.length) {
      organizationsByProject = groupBy(projects, 'team.organizationId');
    }
    return organizationsByProject[organizationId] == null;
  },

  getCurrentOS() {
    let os = OsType.Windows;
    let architecture = 'x64';
    try {
      if (platform.linux) {
        os = OsType.Linux;
      } else if (platform.macos) {
        os = OsType.MacOs;
      }
      architecture = arch();
    } catch (e) {
      console.error('Cannot detect platform', e);
    }
    return {
      os,
      architecture
    };
  },

  getKeywordName(fullKeyword) {
    const splitIndex = fullKeyword.lastIndexOf('.');
    if (splitIndex === -1 || (fullKeyword.length === (splitIndex + 1))) {
      return null;
    }
    const keyword = fullKeyword.substring(splitIndex + 1);
    return kebabCase(keyword);
  },

  /**
   * Used to detect current OS of user and download Katalon Studio.
   * Author: Nhan Nguyen
   */
  downloadKatalonStudio() {
    let platform;

    const { os, architecture } = this.getCurrentOS();
    const marketingOS = MarketingPlatform[os];
    let marketingOSBit = MarketingArchitecture.x64;

    if (marketingOS === MarketingPlatform.Windows) {
      marketingOSBit = MarketingArchitecture[architecture];
    }

    const isOsAcceptable = Object.values(MarketingPlatform).includes(marketingOS);
    const isArchitectureAcceptable = Object.values(MarketingArchitecture).includes(marketingOSBit);

    if (isOsAcceptable && isArchitectureAcceptable) {
      platform = `${marketingOS}_${marketingOSBit}`;
    }
    this.download(Apis.downloadKatalonStudioLink(DocumentLink.DOWNLOAD_KATALON_STUDIO, platform));
  },

  /**
   * Get platform to download Katalon Studio
   * Returns platform with format follows latest_release.json of Katalon Studio
   * {@link external:https://raw.githubusercontent.com/katalon-studio/katalon-studio/master/lastest_release.json}
   */
  getCurrentPlatformForDownload() {
    const { os, architecture } = this.getCurrentOS();
    const marketingOS = MarketingPlatform[os];
    let marketingOSBit = MarketingArchitecture.x64;

    if (marketingOS === MarketingPlatform.Windows) {
      marketingOSBit = MarketingArchitecture[architecture];
    }

    const isOsAcceptable = Object.values(MarketingPlatform).includes(marketingOS);
    const isArchitectureAcceptable = Object.values(MarketingArchitecture).includes(marketingOSBit);

    if (isOsAcceptable && isArchitectureAcceptable) {
      return `${marketingOS}_${marketingOSBit}`;
    }
    return null;
  },

  downloadKS(platform, type) {
    if (!platform) {
      platform = this.getCurrentPlatformForDownload();
    }
    // KS platform does not support win32
    if (platform === 'win_32' && type === 'kse_pe') {
      platform = 'win_64';
    }
    this.download(Apis.downloadKS(platform, type));
  },

  isValidFolderName(folderName) {
    const fullRegex = new RegExp(/^$|^\w[\w,\s\-().]*$/g);
    return fullRegex.test(folderName)
      && folderName.length < 256
      && !folderName.includes('..')
      && !folderName.endsWith('.');
  },

  startsWithNumber(str) {
    return /^\d/.test(str);
  },

  getUUIDFromTestStepTemplate(compareStr) {
    if (compareStr) {
      // Get uuid from descriptio
      // Ex: Autonomous_93d1b802-0023-4db4-9a55-eb0e8074db10 (Do not modify this string, but you can have any desired description after it).
      return compareStr.substring(11, 47);
    }
    return '';
  },

  removeDuplicateElementInArray(array) {
    return [...new Set(array)];
  },
};
