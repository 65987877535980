import React, { Component } from 'react';
import { Badge, Col, Progress, Row, UncontrolledTooltip } from 'reactstrap';
import PropTypes from 'prop-types';

class StatusBarChart extends Component {
  constructor(props) {
    super(props);
    this.data = this.props.data;
  }

  labelHandler(label) {
    return label.replace(/_/g," ");
  }

  render() {
    const totalValue = this.data.reduce((total, item) => total + item.value, 0);

    const { valueFormatter, max, showLegend, showTooltip, customClassName } = this.props;
    return (
      <div className={customClassName || 'status-bar-v2__progress'}>
        <Row className="flex-column">
          <Col>
            <Progress multi>
              {this.data.map(({ key, value, label, color }) => (
                <Progress
                  key={key}
                  className={`progress-bar progress-${key}-${label}`}
                  bar
                  color={color}
                  value={value}
                  max={max || totalValue}
                >
                  {
                    showTooltip && (
                    <UncontrolledTooltip placement="top" target={`.progress-${key}-${label}`} fade>
                      {valueFormatter ? valueFormatter(value) : value}
                    </UncontrolledTooltip>
                    )
                  }
                </Progress>
              ))}
            </Progress>
          </Col>
          {
            showLegend && (
            <Col className="my-1 col-3">
              <div className="d-flex justify-content-between flex-row">
                {this.data.map(({ key, label, color, displayValue }) => (
                  <div className="status-bar-v2__legend__item d-flex ml-3 align-items-center">
                    <Badge className={`status-badge-${color}`} key={key} />
                    <div className="status-bar-v2__legend__item__label ml-2">{displayValue || this.labelHandler(label)}</div>
                  </div>
                ))}
              </div>
            </Col>
            )
          }
        </Row>
      </div>
    );
  }
}

StatusBarChart.propTypes = {
  customClassName: PropTypes.string,
  showLegend: PropTypes.bool,
  showTooltip: PropTypes.bool,
};

StatusBarChart.defaultProps = {
  customClassName: undefined,
  showLegend: true,
  showTooltip: true,
};

export default StatusBarChart;
