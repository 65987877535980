import queryString from 'query-string';
import React, { Component } from 'react';
import { Card, CardBody, CardHeader, ButtonToolbar, Button } from 'reactstrap';
import _ from 'lodash';
import DefaultLayout from '../../../components/DefaultLayout';
import ObjectSummary from '../../../components/summary/ObjectSummary';
import { t } from '../../../i18n/t';
import MContext from '../../../models/MContext';
import Services from '../../../utils/Services';
import SimpleDataTable from '../../../components/table/SimpleDataTable';
import MTableColumnDataMapping from '../../../components/table/models/MTableColumnDataMapping';
import Time from '../../../utils/Moment';
import DecoratorConstants from '../../../utils/DecoratorConstants';
import DocumentLink from '../../../utils/DocumentLink';
import Routes from '../../../utils/Routes';
import Apis from '../../../utils/Apis';

class PaymentSuccess extends Component {
  constructor(props) {
    super(props);
    const params = queryString.parse(this.props.location.search);
    this.invoiceNumbers = params.invoiceNumbers;
    this.type = params.type;
    this.orderFailed = {
      name: params.orderFailedName,
      error: params.orderFailedError
    };
    this.organizationId = MContext.organizationId;
    this.organization = MContext.organization;
    this.state = {
      invoices: []
    };
    this.renderBody = this.renderBody.bind(this);
  }

  componentDidMount() {
    if (this.organizationId) {
      Services.getInvoice(this.organizationId, this.invoiceNumbers)
        .then((invoices) => this.setState({ invoices }));
    }
  }

  renderHeader() {
    const urlParams = {
      subscription: t('subscriptions'),
    };
    return (
      <ObjectSummary params={urlParams} />
    );
  }

  renderOrderFailed() {
    const columnMapping = [
      new MTableColumnDataMapping(t('table-header#product'), 'name'),
      new MTableColumnDataMapping(t('Error'), 'error', DecoratorConstants.errorDecorator)
    ];
    if (this.orderFailed.name && this.orderFailed.error) {
      return (
        <Card>
          <CardHeader>
            Failed Orders
          </CardHeader>
          <CardBody>
            <p>The following orders could not be completed. Please retry placing a new order one day after the failed one or contact business@katalon.com for prompt support.</p>
            <SimpleDataTable
              data={[this.orderFailed]}
              columnMapping={columnMapping}
            />
          </CardBody>
        </Card>
      );
    } else return null;
  }

  renderOrderSummary() {
    const { invoices } = this.state;
    const columnMapping = [
      new MTableColumnDataMapping(t('table-header#product'), 'plan.enterpriseProduct.name'),
      new MTableColumnDataMapping(t('description'), 'plan.enterpriseProduct.name', (name, row) => {
        const start = Time.formatDate(row.startDate, Time.DAY_FULL_FORMAT);
        const end = Time.formatDate(row.endDate, Time.DAY_FULL_FORMAT);
        const interval = DecoratorConstants.billingIntervalDecorator('plan.interval', row);
        return (
          <>
            {interval} subscription <br />
            {start} - {end}
          </>);
      }),
      new MTableColumnDataMapping(t('table-header#paid-amount'), 'total'),
    ];
    if (invoices && invoices.length > 0) {
      return (
        <Card>
          <CardHeader>
            Order Summary
          </CardHeader>
          <CardBody>
            <SimpleDataTable
              data={invoices}
              columnMapping={columnMapping}
            />
          </CardBody>
        </Card>
      );
    } else return null;
  }

  renderLicenseButton() {
    const { invoices } = this.state;
    if (invoices && invoices.length > 0) {
      const routes = new Routes();
      const invoice = invoices[0];

      let manageLicenseLink;
      let feature;
      if (this.type && this.type === 'testops') {
        manageLicenseLink = routes.organization_testops_subscription;
      } else {
        feature = _.get(invoice, 'plan.enterpriseProduct.feature');
        switch (feature) {
          case 'KSE':
            manageLicenseLink = routes.organization_kse_licenses_link;
            break;
          case 'ENGINE':
            manageLicenseLink = routes.organization_engine_licenses_link;
            break;
          case 'FLOATING_ENGINE':
            manageLicenseLink = routes.organization_floating_engine_licenses_link;
            break;
          default:
            manageLicenseLink = routes.organization_licenses_link;
        }
      }
      const testOpsHomePageLink = Apis.testOpsOrganizationHome(this.organizationId);

      return (
          <ButtonToolbar>
            <Button color="primary" href={manageLicenseLink}>
              {t('manage_license')}
            </Button>
            <Button color="secondary" href={testOpsHomePageLink}>
              {t('goto_testops')}
            </Button>
          </ButtonToolbar>

      );
    } else return null;
  }

  renderBody() {
    return (
      <>
        <Card>
          <CardHeader>
            Thank you for the purchase!
          </CardHeader>
          <CardBody>
            <p>
              You have subscribed to Katalon licenses with <strong>Organization {this.organization.name}</strong>.<br />
              An invoice has been sent to the Owner and Billing Manager of this organization.<br />
              If you do not receive the email, please contact us via <a href="mailto:billing@katalon.com">billing@katalon.com</a><br />
            </p>
            <div>
              {this.renderLicenseButton()}
            </div>
            <br />
          </CardBody>
        </Card>
        {this.renderOrderSummary()}
        {this.renderOrderFailed()}
      </>
    );
  }


  render() {
    return (
      <DefaultLayout
        renderBody={this.renderBody}
        renderHeader={this.renderHeader}
      />
    );
  }
}

export default PaymentSuccess;
