import React from 'react';
import { Alert, Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import parse from 'url-parse';
import PageComponent from '../components/PageComponent';
import { t } from '../i18n/t';
import Routes from '../utils/Routes';
import Services from '../utils/Services';
import ChangePasswordForm from './users/ChangePasswordForm';

const RESET_PASSWORD_STATUS = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  ACCEPTED: 'ACCEPTED',
};

class ResetPassword extends PageComponent {
  constructor(props) {
    super(props);
    this.meta.id = 'page-reset-password';
    this.meta.title = t('reset_password');
    const url = parse(window.location.href, true);
    this.token = url.query.token;
    this.state = this.getInitialState();

    this.resetPassword = this.resetPassword.bind(this);
    this.handleFormDataChange = this.handleFormDataChange.bind(this);
  }

  getInitialState() {
    const formData = {
      password: '',
      confirmPassword: '',
    };
    return {
      valid: false,
      info: null,
      formData,
      showAlert: false,
      errorMessage: '',
    };
  }

  getResetPasswordInfo() {
    Services.getResetPasswordInfo(this.token)
      .then((info) => {
        this.setState({
          valid: info.status === RESET_PASSWORD_STATUS.ACTIVE,
          info,
        }, () => this.validateResetPasswordInfo(this.state.info));
      });
  }

  validateResetPasswordInfo(info) {
    if (info.status === RESET_PASSWORD_STATUS.INACTIVE) {
      this.showErrorAlert(t('reset_password#token_expired'));
    }

    if (info.status === RESET_PASSWORD_STATUS.ACCEPTED) {
      this.showErrorAlert(t('reset_password#token_accepted'));
    }
  }

  resetPassword() {
    const { info, formData } = this.state;

    info.newPassword = formData.password;
    Services.resetPassword(info)
      .then(() => {
        const url = parse(window.location.href, true);
        const redirect = url.query.redirect;
        if (redirect) {
          const redirectURL = window.encodeURIComponent(redirect);
          window.location.href = `/login?redirect=${redirectURL}`;
        } else {
          Routes.goToHomePage();
        }
      });
  }

  handleFormDataChange(formData) {
    this.setState({ formData });
  }

  showErrorAlert(message) {
    this.setState({
      showAlert: true,
      errorMessage: message,
    });
  }

  componentWillMount() {
    this.getResetPasswordInfo();
  }

  render() {
    const { valid, showAlert, errorMessage } = this.state;
    return (
      <>
        <Row className="login-page flex-fill align-items-center mx-0">
          <Col
            sm={{
              size: 8,
              offset: 4,
            }}
            md={{
              size: 7,
              offset: 5,
            }}
            lg={{
              size: 4,
              offset: 7,
            }}
          >
            <Card className="login-card mx-auto">
              <CardHeader className="mb-4">
                <div className="login-tab-header">
                  <div className="tab-button active">{t('reset_password')}</div>
                </div>
              </CardHeader>
              <CardBody>
                <Alert isOpen={showAlert} color="danger">
                  {errorMessage}
                </Alert>
                {valid && (
                  <ChangePasswordForm
                    data-trackid="reset-password-ka"
                    handleFormDataChange={this.handleFormDataChange}
                    handleFormSubmission={this.resetPassword}
                    submitButtonTitle={t('reset_password#reset_password_button')}
                    hideCurrentPassword
                  />
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}

export default ResetPassword;
