const OnPremiseFlags = {

  /**
   *
   * TEST CLOUD FEATURE
   *
   * DO NOT SUPPORT
   * TEST CLOUD FEATURE
   * Disable Test Cloud for on-premise version
   * Release version: TestCloud
   */
  testCloudEnabled: false,
  testCloudProxyTunnelEnabled: false,

  /**
   * Redirect K1-handled features to admin
   */
  redirectToAdminEnabled: true,
}

export default OnPremiseFlags;