import React from 'react';
import { Button } from 'reactstrap';
import { get } from 'lodash';
import { t } from '../../i18n/t';
import MContext from '../../models/MContext';
import CheckpointGallery from './component/CheckpointGallery';
import { buildSearchCondition } from '../../components/search/SearchUtils';
import KeyesExecutionStatusBar from './component/KeyesExecutionStatusBar';
import { next } from '../../utils/Count';
import PageButtonToolbar from '../../components/PageButtonToolbar';
import TabComponent from '../../components/TabComponent';
import { saveToBaseline } from '../../utils/keyes/KeyesUtils';
import GroupEvent from '../../utils/track/GroupEvent';
import { StatusType } from '../../components/search-query/filter/StatusFilter';
import { CheckpointStatus } from '../../utils/Constants';

class KEyesExecutionResultTab extends TabComponent {
  constructor(props) {
    super(props);
    this.meta.id = 'page-keyes-execution-details';
    this.projectId = MContext.projectId;
    this.executionId = MContext.executionId;
    this.meta.title = t('execution#title', { order: this.executionId, name: '' });

    this.renderBody = this.renderBody.bind(this);
    this.onSaveToBaselineClick = this.onSaveToBaselineClick.bind(this);
    this.checkpointsRef = React.createRef();
  }

  renderStatusBar() {
    const { keyesExecution } = this.props;

    return (
      <KeyesExecutionStatusBar key={next()} keyesExecution={keyesExecution} />
    );
  }

  onSaveToBaselineClick() {
    const { keyesExecution, refreshData } = this.props;
    saveToBaseline(keyesExecution, refreshData, this.checkpointsRef);
  }

  renderToolbar() {
    const { keyesExecution } = this.props;

    return (
      <PageButtonToolbar>
        <Button
          title={t('save_to_baseline')}
          color="secondary"
          onClick={this.onSaveToBaselineClick}
          disabled={!keyesExecution.unsaved}
          data-trackid="page-keyes-execution-details-save-baseline"
          data-groupid={GroupEvent.ACCESS_REPORT}
        >
          {t('save_to_baseline')}
        </Button>
      </PageButtonToolbar>
    );
  }

  renderBody() {
    const { keyesExecution } = this.props;
    const defaultMethod = get(keyesExecution, 'baselineCollection.defaultMethod', null);
    const defaultSearchConditions = [
      buildSearchCondition('KeyesExecution.id', '=', keyesExecution.id)
    ];
    const newProps = {
      statusType: StatusType.CHECKPOINT,
      title: t('keyes#execution#checkpoints'),
      defaultFilterConditions: [
        buildSearchCondition('status', '=', CheckpointStatus.UNRESOLVED)
      ],
    };

    return (
      <>
        {this.renderToolbar()}
        {this.renderStatusBar()}
        <CheckpointGallery
          ref={this.checkpointsRef}
          defaultSearchConditions={defaultSearchConditions}
          defaultMethod={defaultMethod}
          {...newProps}
        />
      </>
    );
  }

  render() {
    return <>{this.renderBody()}</>;
  }
}

export default KEyesExecutionResultTab;
