import Services from '../../utils/Services';
import { ExecutionExport } from '../../utils/Constants';
import { t } from '../../i18n/t';
import MAuth from '../../models/MAuth';
import Notification from '../../utils/Notification';
import Apis from '../../utils/Apis';
import Helper from '../../utils/Helper';

export const DownloadExecution = {

  startDownload(fileType, execution) {
    // plus 1 to prevent the gap between toastr export and send email toastr
    Notification.pushInfo(t('wait_for_export'), ExecutionExport.TOTAL_DURATION + 1);
    Services.checkExportedFile(execution.id, { fileType }).then(
      (isExistedFile) => {
        if (isExistedFile) {
          this.downloadExecution(execution.id, { fileType });
        } else {
          this.createQueue(fileType, execution)
            .then(() => this.checkExportedFileInterval(execution, fileType));
        }
      }
    );
  },

  createQueue(fileType, execution) {
    return Services.enqueueFile(execution.id, { fileType });
  },

  checkExistedFile(executionId, fileType) {
    return Services.checkExportedFile(executionId, { fileType });
  },

  downloadExecution(executionId, fileType) {
    Notification.remove();
    Helper.download(Apis.executionExport(executionId, fileType));
  },

  checkExportedFileInterval(execution, fileType) {
    let count = 0;
    const times = ExecutionExport.TOTAL_DURATION / ExecutionExport.ONE_PING;
    const checking = setInterval(() => {
      this.checkExistedFile(execution.id, fileType)
        .then((isExistedFile) => {
          if (isExistedFile) {
            this.downloadExecution(execution.id, { fileType });
            clearInterval(checking);
          } else count++;
          if (count >= times) {
            this.sendExecutionExportEmail(execution, { fileType });
            clearInterval(checking);
          }
        });
    }, ExecutionExport.ONE_PING);
  },

  sendExecutionExportEmail(execution, fileType) {
    Notification.remove();
    Notification.pushInfo(t('send_mail_for_export', { email: MAuth.email }));
    Services.sendExportFileEmail(execution.id, fileType);
  },
};
