import objectHash from 'object-hash';
import MAuth from '../models/MAuth';

export const generateLocalStorageHashKey = (storageKey) => {
  const { id } = MAuth.user;
  return storageKey + objectHash(id);
};

export const getLocalStorageItem = (storageKey) => localStorage.getItem(generateLocalStorageHashKey(storageKey));

export const setLocalStorageItem = (storageKey, value) => localStorage.setItem(generateLocalStorageHashKey(storageKey), value);

export const getLocalStorageJSONItem = (storageKey, defaultValue) => {
  try {
    const rawString = localStorage.getItem(generateLocalStorageHashKey(storageKey));
    return JSON.parse(rawString) || defaultValue;
  } catch (e) {
    return defaultValue;
  }
};

export const setLocalStorageJSONItem = (storageKey, value) => {
  const stringValue = JSON.stringify(value);
  return localStorage.setItem(generateLocalStorageHashKey(storageKey), stringValue);
};
