import React from 'react';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Label,
  Row,
} from 'reactstrap';
import DefaultLayout from '../../components/DefaultLayout';
import ObjectSummary from '../../components/summary/ObjectSummary';
import MAuth from '../../models/MAuth';
import MContext from '../../models/MContext';
import PageComponent from '../../components/PageComponent';
import { t } from '../../i18n/t';
import Input from '../../components/Input';
import Services from '../../utils/Services';
import Notification from '../../utils/Notification';
import DeleteProjectDialog from '../../components/dialog/DeleteProjectDialog';
import UserManagement from '../users/UserManagement';
import ProjectSettingCard from './ProjectSettingCard';
import TimeZoneConfiguration from './TimeZoneConfiguration';
import { ProjectConfigurationService } from '../../utils/ConfigurationServices';
import MFlags from '../../models/MFlags';

class ProjectSetting extends PageComponent {

  constructor(props) {
    super(props);
    this.meta.id = 'page-projects-settings';
    this.meta.title = 'Project Settings';

    this.project = MContext.project;
    this.team = MContext.team;
    this.teamId = MContext.teamId;
    this.projectId = MContext.projectId;

    this.deleteProjectDialog = React.createRef();

    this.renderHeader = this.renderHeader.bind(this);
    this.renderBody = this.renderBody.bind(this);
    this.handleTextChange = this.handleTextChange.bind(this);
    this.updateProjectName = this.updateProjectName.bind(this);
    this.updateAssertionPattern = this.updateAssertionPattern.bind(this);
    this.deleteProject = this.deleteProject.bind(this);
    this.isDisableEditInTeam = this.isDisableEditInTeam.bind(this);
    this.isNewOrganizationModal = this.isNewOrganizationModal.bind(this);

    this.state = {
      projectName: '',
      disableForm: false,
      projectSetting: {},
      organizationMigration: null,
    };
  }

  componentDidMount() {
    if (MFlags.disableTheAbilityToUpdateTeamLevel) {
      this.getOrganizationMigration();
    }
    this.getProjectSetting();
    this.setState({ projectName: this.project.name });
    this.getTestCaseAssertion();
  }

  getOrganizationMigration() {
    Services.getOrganizationMigration(this.team.organizationId).then((organizationMigration) => {
      this.setState({ organizationMigration });
    });
  }

  getProjectSetting() {
    Services.getProjectSetting(this.project.id).then((projectSetting) => {
      this.setState({ projectSetting });
    });
  }

  getTestCaseAssertion() {
    ProjectConfigurationService.getAssertionPattern(this.projectId).then(({ value }) => {
      this.setState({
        patternList: value
      });
    });
  }

  handleTextChange(event) {
    const value = event.target.value;
    const name = event.target.name;
    this.setState({ [name]: value });
  }

  updateProjectName(e) {
    e.preventDefault();
    const { projectName } = this.state;

    if (projectName) {
      this.setState({ disableForm: true });
      const newProjectData = {
        id: this.project.id,
        name: projectName,
      };
      Services.updateProject(newProjectData)
        .then((project) => {
          MContext.project = project;
          const { afterCreate } = this.props;
          Notification.pushSuccess(`Project ${projectName} was updated.`);
          if (afterCreate) {
            afterCreate();
          }
        })
        .catch(() => { /* ignored */ })
        .finally(() => this.setState({ disableForm: false }));
    }
  }

  deleteProject(event) {
    event.preventDefault();
    this.deleteProjectDialog.current.toggle();
  }

  renderObjectSummary() {
    const urlParams = {
      projectSetting: t('projects'),
      teamId: this.teamId,
      teamName: this.team.name,
      projectName: this.project.name
    };
    return (
      <ObjectSummary params={urlParams} />
    );
  }

  renderHeader() {
    return (
      <>
        {this.renderObjectSummary()}
      </>
    );
  }

  renderEditProject() {
    const isTeamManager = MAuth.isTeamManager(this.teamId);
    const { projectName, disableForm } = this.state;
    return (
      <Card>
        <CardHeader>
          {t('project_profile')}
        </CardHeader>
        <CardBody>
          <Row>
            <Col sm="12" md="8" lg="6" xl="5">
              <Form data-trackid="edit-project" onSubmit={this.updateProjectName}>
                {!this.isNewOrganizationModal() &&
                  <FormGroup>
                    <Label>{t('team')}</Label>
                    <Input plaintext>{this.team.name}</Input>
                  </FormGroup>}
                <FormGroup>
                  <Label>{t('project_id')}</Label>
                  <Input plaintext>{this.projectId}</Input>
                </FormGroup>
                <FormGroup>
                  <Label for="projectName">{t('project_name')}</Label>
                  <Input
                    id="projectName"
                    name="projectName"
                    required
                    disabled={disableForm || !isTeamManager}
                    value={projectName}
                    onChange={this.handleTextChange}
                    type="text"
                  />
                </FormGroup>
                <FormGroup>
                  <Button type="submit" color="primary" disabled={disableForm || !isTeamManager}>
                    {t('project_update')}
                  </Button>
                </FormGroup>
              </Form>
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  }

  renderTeamInvitations() {
    return (
      <UserManagement isDisableEditInTeam={this.isDisableEditInTeam()} isNewOrganizationModal={this.isNewOrganizationModal()} {...this.props} />
    );
  }

  isNewOrganizationModal() {
    if (!MFlags.disableTheAbilityToUpdateTeamLevel) {
      return false;
    }

    const { organizationMigration } = this.state;
    if (!organizationMigration) {
      return false;
    }
    return organizationMigration.newUI;
  }

  isDisableEditInTeam() {
    return false;
  }

  renderDangerZone() {
    const isTeamManager = MAuth.isTeamManager(this.teamId);
    return (
      <>
        <Card>
          <CardHeader>
            {t('project_danger_zone')}
          </CardHeader>
          <CardBody>
            <Form data-trackid="delete-project">
              <FormGroup>
                <p>{t('project_msg_delete_project')}</p>
              </FormGroup>
              <FormGroup>
                <Button
                  type="submit"
                  color="danger"
                  onClick={this.deleteProject}
                  disabled={!isTeamManager || this.isDisableEditInTeam()}
                >
                  {t('project_button_delete')}
                </Button>
              </FormGroup>
            </Form>
          </CardBody>
        </Card>
        <DeleteProjectDialog
          ref={this.deleteProjectDialog}
        />
      </>
    );
  }

  updateAssertionPattern(event) {
    event.preventDefault();
    const { patternList } = this.state;
    ProjectConfigurationService.updateAssertionPattern(this.projectId, patternList)
      .then(({ value }) => {
        Notification.pushSuccess(t('saved_changes_success'));
        this.setState({
          patternList: value
        });
      });
  }

  renderTestCaseAssertion() {
    const { patternList } = this.state;
    const isTeamManager = MAuth.isTeamManager(this.teamId);
    return (
      <>
        <Card>
          <CardHeader>
            Assertion Pattern Configurations
          </CardHeader>
          <CardBody>
            <Form data-trackid="pattern-list-project" onSubmit={this.updateAssertionPattern}>
              <FormGroup>
                <Input
                  id="pattern-list"
                  value={patternList}
                  name="patternList"
                  onChange={this.handleTextChange}
                  type="textarea"
                  placeholder="Enter valid comma-separated assertion patterns (E.g: **.verify*,**.assert*)."
                  rows="5"
                  disabled={!isTeamManager}
                />
              </FormGroup>
              <FormGroup>
                <Button type="submit" color="primary" disabled={!isTeamManager}>
                  Update
                </Button>
              </FormGroup>
            </Form>
          </CardBody>
        </Card>
      </>
    );
  }

  renderBody() {
    return (
      <>
        {this.renderEditProject()}
        {this.renderTeamInvitations()}
        <ProjectSettingCard project={this.project} />
        <TimeZoneConfiguration />
        {this.renderTestCaseAssertion()}
        {this.renderDangerZone()}
      </>
    );
  }

  render() {
    if (this.project) {
      return (
        <DefaultLayout
          renderHeader={this.renderHeader}
          renderBody={this.renderBody}
        />
      );
    }
    return null;
  }
}

export default ProjectSetting;
