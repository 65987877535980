export const RunConfigurationActionType = {
  ADD_TEST_SUITE_COLLECTIONS: 'ADD_TEST_SUITE_COLLECTIONS',
  ADD_TESTCLOUD_TEST_SUITE_COLLECTION_AGENTS: 'ADD_TESTCLOUD_TEST_SUITE_COLLECTION_AGENTS',
  CLEAR_TESTCLOUD_TEST_SUITE_COLLECTION_AGENTS: 'CLEAR_TESTCLOUD_TEST_SUITE_COLLECTION_AGENTS',
  UPDATE_NAME: 'UPDATE_NAME',
  UPDATE_COMMAND: 'UPDATE_COMMAND',
  ADD_AGENT: 'ADD_AGENT',
  REMOVE_AGENT: 'REMOVE_AGENT',
  UPDATE_TEST_SUITE_COLLECTION_ID: 'UPDATE_TEST_SUITE_COLLECTION_ID',
  UPDATE_EXECUTION_PROFILE_ID: 'UPDATE_EXECUTION_PROFILE_ID',
  UPDATE_TEST_SUITE_ID: 'UPDATE_TEST_SUITE_ID',
  UPDATE_ENABLED_KOBITON_INTEGRATION: 'UPDATE_ENABLED_KOBITON_INTEGRATION',
  UPDATE_EXECUTION_MODE: 'SELECT_EXECUTION_MODE',
  UPDATE_TEST_PROJECT: 'UPDATE_TEST_PROJECT',
  UPDATE_CONFIG_TYPE: 'UPDATE_CONFIG_TYPE',
  UPDATE_KOBITON_DEVICE_ID: 'UPDATE_KOBITON_DEVICE_ID',
  UPDATE_GENERIC_COMMAND: 'UPDATE_GENERIC_COMMAND',
  SELECT_TEST_PROJECT: 'SELECT_TEST_PROJECT',
  ADD_TESTCLOUD_AGENT: 'ADD_TESTCLOUD_AGENT',
  REMOVE_TESTCLOUD_AGENT: 'REMOVE_TESTCLOUD_AGENT',
  CLEAR_TESTCLOUD_AGENT: 'CLEAR_TESTCLOUD_AGENT',
  UPDATE_ENABLED_TESTCLOUD_TUNNEL: 'UPDATE_ENABLED_TESTCLOUD_TUNNEL',
  ADD_K8S_AGENT: 'ADD_K8S_AGENT',
  REMOVE_K8S_AGENT: 'REMOVE_K8S_AGENT',
  ADD_CIRCLE_CI_AGENT: 'ADD_CIRCLE_CI_AGENT',
  REMOVE_CIRCLE_CI_AGENT: 'REMOVE_CIRCLE_CI_AGENT',
  UPDATE_CLOUD_TYPE: 'UPDATE_CLOUD_TYPE',
  UPDATE_KS_VERSION: 'UPDATE_KS_VERSION',
  UPDATE_KS_LOCATION: 'UPDATE_KS_LOCATION',
  UPDATE_TIME_OUT: 'UPDATE_TIME_OUT',
  ADD_RELEASE_VERSION: 'RELEASE_VERSION',
  INIT_RUN_CONFIGURATION: 'INIT_RUN_CONFIGURATION',
  ADD_BROWSER_TYPE: 'ADD_BROWSER_TYPE',
  ADD_PREV_BROWSER_TYPE_TS: 'ADD_PREV_BROWSER_TYPE_TS',
  ADD_PREV_BROWSER_TYPE_TSC: 'ADD_PREV_BROWSER_TYPE_TSC',
  UPDATE_BASELINE_COLLECTION: 'UPDATE_BASELINE_COLLECTION',
  ADD_APP_ID: 'ADD_APP_ID',
  ADD_MOBILE_PLATFORM: 'ADD_MOBILE_PLATFORM',
  UPDATE_XRAY_IMPORT_REPORT_TYPE: 'UPDATE_XRAY_IMPORT_REPORT_TYPE',
  UPDATE_XRAY_TEST_PLAN: 'UPDATE_XRAY_TEST_PLAN',
  UPDATE_CUSTOM_FIELDS: 'UPDATE_CUSTOM_FIELDS',
  UPDATE_TAGS: 'UPDATE_TAGS',
};

export const SchedulerActionType = {
  UPDATE_START_TIME: 'UPDATE_START_TIME',
  UPDATE_END_TIME: 'UPDATE_END_TIME',
  UPDATE_INTERVAL: 'UPDATE_INTERVAL',
  UPDATE_INTERVAL_UNIT: 'UPDATE_INTERVAL_UNIT',
  INIT_SCHEDULER: 'INIT_SCHEDULER',
};

export const TypeExecutionOptions = {
  MANUAL_TRIGGER: 'MANUAL_TRIGGER',
  TESTOPS_SCHEDULER: 'TESTOPS_SCHEDULER',
};
