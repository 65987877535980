import React from 'react';
import { CardHeader, CardBody } from 'reactstrap';
import Notification from '../../utils/Notification';
import Routes from '../../utils/Routes';
import MConfigs from '../../models/MConfigs';
import ImportTestOpsLicense from './ImportTestOpsLicense';
import logo from '../../../images/TestOps_logo_beta_32px@2x.png';

export default class ActivateTestOps extends React.Component {
  constructor(props) {
    super(props);

    this.onUploadFinish = this.onUploadFinish.bind(this);
    this.machineId = MConfigs.machineId;
  }

  onUploadFinish() {
    Notification.pushSuccess('Import successfully');
    Routes.goToStartUpPage();
  }

  render() {
    return (
      <>
        <CardHeader>
          <div className="login-test-opts-logo mb-5">
            <img src={logo} alt="Test Opts Logo" />
          </div>
          <div className="login-tab-header">
            <div className="tab-button active">Activate TestOps</div>
          </div>
        </CardHeader>
        <CardBody>
          <ImportTestOpsLicense
            submitBtn="Activate"
            machineId={this.machineId}
            onUploadFinish={this.onUploadFinish}
          />
        </CardBody>
      </>
    );
  }

}
