import PropTypes from 'prop-types';
import React from 'react';
import StatusConstants from '../../utils/Statuses';
import StatusBarV2 from '../chart/status-bar/StatusBar';
import StatusBar from '../chart/StatusBar';
import DataLoader from './DataLoader';

class TestCaseExecutionStatus extends React.Component {
  constructor(props) {
    super(props);
    this.renderStatusBar = this.renderStatusBar.bind(this);
    this.convertStatistics = this.convertStatistics.bind(this);
  }

  convertStatistics(statistics) {
    const data = [];
    data.push({
      status: StatusConstants.PASSED.label,
      count: statistics.totalPassed,
    });
    data.push({
      status: StatusConstants.FAILED.label,
      count: statistics.totalFailed,
    });
    return data;
  }

  renderStatusBar(data) {
    const statuses = {};
    const { onSelectItem, title, useStatusBarV2 } = this.props;

    let total = 0;
    data.forEach((item) => {
      statuses[item.status.toLowerCase()] = item.count;
      total += item.count;
    });

    const totalItem = {
      label: 'Test Cases',
      value: total,
      onClick: onSelectItem,
      isTotal: true,
    };
    const statusBarItem = [
      {
        label: StatusConstants.PASSED.label,
        value: statuses[StatusConstants.PASSED.label.toLocaleLowerCase()] || 0,
        color: 'passed',
        onClick: onSelectItem,
      },
      {
        label: StatusConstants.FAILED.label,
        value: statuses[StatusConstants.FAILED.label.toLocaleLowerCase()] || 0,
        color: 'failed',
        onClick: onSelectItem,
      },
    ];

    let totalPercent = 0;
    for (let i = 0; i < statusBarItem.length; i++) {
      if (i === statusBarItem.length - 1) {
        statusBarItem[i].percent = (100 - totalPercent).toFixed(2);
      } else {
        statusBarItem[i].percent = ((statusBarItem[i].value / total) * 100).toFixed(2);
        totalPercent += statusBarItem[i].percent;
      }
    }

    const defaultItem = 2;

    if (useStatusBarV2) {
      return <StatusBarV2
        dataInfo={[totalItem, ...statusBarItem]}
        dataChart={statusBarItem}
        {...this.props}
      />;
    } else {
      return (
        <StatusBar
          title={title}
          items={[totalItem, ...statusBarItem]}
          totalValue={total}
          defaultItem={defaultItem}
          {...this.props}
        />
      );
    }

  }

  render() {
    const { statistics } = this.props;
    if (statistics) {
      const data = this.convertStatistics(statistics);
      return this.renderStatusBar(data);
    } else {
      return (
        <DataLoader
          render={this.renderStatusBar}
          entityType="TestCaseExecutionStatus"
          defaultSort={['name,asc']}
          noCard
          {...this.props}
        />
      );
    }
  }
}

TestCaseExecutionStatus.propTypes = {
  title: PropTypes.string,
  onSelectItem: PropTypes.func,
  defaultSearchConditions: PropTypes.array,
  useStatusBarV2: PropTypes.bool,
};

TestCaseExecutionStatus.defaultProps = {
  title: '',
  onSelectItem: undefined,
  defaultSearchConditions: undefined,
  useStatusBarV2: false,
};

export default TestCaseExecutionStatus;
