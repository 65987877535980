import React, { Component } from 'react';
import { Label } from 'reactstrap';
import PropTypes from 'prop-types';
import { NativeSelect } from '@mui/material';
import { SearchOperator } from '../../../utils/SearchOperator';
import { t } from '../../../i18n/t';
import Input from '../../Input';

const compareFilterOptions = [
  { label: t('search-bar#more-than'), value: SearchOperator.GREATER_OR_EQUAL },
  { label: t('search-bar#less-than'), value: SearchOperator.LESS_OR_EQUAL },
];

class DurationFilter extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();

    this.buildFilter = this.buildFilter.bind(this);
    this.onInputSearchChange = this.onInputSearchChange.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
  }

  /*
   * Value is format true.
   * Ex: >=145
   * Check to get value is: >=145
   * Using: check first character
   */
  getInitialState() {
    const { value } = this.props;
    let [searchValue, searchOperator] = ['', compareFilterOptions[0].value];

    if (value) {
      // get compare operator ex: >=, <=
      const operator = value.substring(0, 2);
      const listOperator = [SearchOperator.GREATER_OR_EQUAL, SearchOperator.LESS_OR_EQUAL];
      // check operator existed else get the default compare '>='
      if (listOperator.includes(operator)) {
        searchOperator = compareFilterOptions.filter((item) => item.value === operator)[0];
      }
      // get input value ex: 145 ,...
      searchValue = value.substring(2, value.length);
    }

    return {
      searchInput: searchValue,
      selectedOperator: searchOperator.value
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { value } = nextProps;
    const { searchInput, selectedOperator } = prevState;

    /*
     * When user click button "Clear" in component SearchQuery then value will be null.
     * I check the searchInput and selectedOperator if there is a value then set it to null to clear data.
     */
    if (!!searchInput && !!selectedOperator && !value) {
      return {
        searchInput: null,
        selectedOperator: compareFilterOptions[0].value
      };
    }
    return prevState;
  }

  onInputSearchChange(event) {
    const { target: { value } } = event;
    const { selectedOperator } = this.state;

    if (selectedOperator) {
      this.setState({
        searchInput: value,
      }, this.buildFilter(selectedOperator + value));
    } else {
      this.setState({
        selectedOperator: compareFilterOptions[0].value,
        searchInput: value,
      }, this.buildFilter(compareFilterOptions[0].value + value));
    }
  }

  buildFilter(value) {
    const { id, onBuildFilterSearch } = this.props;
    if (id && onBuildFilterSearch) {
      onBuildFilterSearch(id, value);
    }
  }

  handleFilterChange = (event) => {
    const { searchInput } = this.state;
    const { value } = event.target;
    this.setState({ selectedOperator: value }, this.buildFilter(value + searchInput));
  };

  compareOptions() {
    return compareFilterOptions.map((item) => <option value={item.value}>{item.label}</option>);
  }

  render() {
    const { searchInput, selectedOperator } = this.state;
    const { label, placeholder } = this.props;

    return (
      <div>
        <Label>{label}</Label>
        <div className="d-flex">
          <NativeSelect
            id="average-duration-operator"
            defaultValue={compareFilterOptions[0].value}
            className="search-compare-field"
            value={selectedOperator}
            onChange={this.handleFilterChange}
          >
            {this.compareOptions()}
          </NativeSelect>
          <Input
            id="average-duration-value"
            onChange={this.onInputSearchChange}
            value={searchInput || ''}
            placeholder={placeholder}
            min={0}
            type="number"
          />
        </div>
      </div>
    );
  }
}

DurationFilter.propTypes = {
  onBuildFilterSearch: PropTypes.func,
  /**
   * Current value of input in search bar.
   */
  value: PropTypes.string,
  /**
   * Title of  filed input in search dialog.
   */
  label: PropTypes.string,
  /**
   * Placeholder of  filed input in search dialog.
   */
  placeholder: PropTypes.string,
  /**
   * Id to build filter search.
   */
  id: PropTypes.string,
};

DurationFilter.defaultProps = {
  value: '',
  label: '',
  placeholder: '',
  id: '',
};

export default DurationFilter;
