import React from 'react';
import { InputAdornment } from '@mui/material';
import PropTypes from 'prop-types';
import { IconSearch, IconSearchFilter } from '../images/CustomIcon';
import TextField from './TextField';

export default function SearchTextField({ value, placeHolder, onChange, onSearchInputKeyDown, handleOpenDialogSearch, anchorDialogSearchRef, hideIconOnSearchBar, customClass }) {

  const onKeyDown = (event) => {
    if (onSearchInputKeyDown) {
      onSearchInputKeyDown(event);
    }
  };

  return (
    <TextField
      type="text"
      id="search-input"
      placeholder={placeHolder}
      value={value}
      onChange={onChange}
      onKeyDown={onKeyDown}
      autoComplete="off"
      autoCapitalize="off"
      spellCheck="false"
      size="small"
      className={`search_text_field ${customClass}`}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <IconSearch />
          </InputAdornment>
        ),
        endAdornment: !hideIconOnSearchBar && (
          <InputAdornment position="end">
            <IconSearchFilter role="button" ref={anchorDialogSearchRef} onClick={handleOpenDialogSearch} />
          </InputAdornment>
        )
      }}
    />
  );
}

SearchTextField.propTypes = {
  value: PropTypes.string,
  placeHolder: PropTypes.string,
  onChange: PropTypes.func,
  onSearchInputKeyDown: PropTypes.func,
  handleOpenDialogSearch: PropTypes.func,
  hideIconOnSearchBar: PropTypes.bool,
  customClass: PropTypes.string,
};

SearchTextField.defaultProps = {
  placeHolder: '',
  value: null,
  hideIconOnSearchBar: false,
  customClass: '',
};
