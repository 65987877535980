import React from 'react';
import Card from 'reactstrap/lib/Card';
import PropTypes from 'prop-types';
import moment from 'moment';
import _ from 'lodash';
import { Col, Row } from 'reactstrap';
import {
  IconTeams
} from '../../../images/CustomIcon';
import { t } from '../../../i18n/t';
import Routes from '../../../utils/Routes';
import OrganizationCard from './OrganizationCard';
import DataLoader from '../../../components/table/DataLoader';
import { buildSearchCondition } from '../../../components/search/SearchUtils';
import StatusDoughnutChart from '../../../components/chart/StatusDoughnutChart';
import Statuses from '../../../utils/Statuses';
import Time from '../../../utils/Moment';
import StatusBarInfo from '../../../components/chart/status-bar/StatusBarInfo';
import ImageProfile from '../../../components/avatar/ImageProfile';


class RecentProject extends React.Component {

  constructor(props) {
    super(props);
    this.dataRef = React.createRef();

    this.project = this.props.project;

    this.renderData = this.renderData.bind(this);
  }

  calcDiff(statistics, property) {
    const current = _.get(statistics, property);
    const previous = _.get(statistics, `previousStatistics.${property}`);
    if (!previous) return null;
    const percentage = (((current - previous) * 100) / previous).toFixed(1);
    return percentage;
  }

  convertTestCaseData(statistics) {
    return [
      {
        key: `$passed-test-case-${statistics.passedTestCase}`,
        label: Statuses.PASSED.label,
        value: statistics.passedTestCase,
        color: 'passed',
        diff: this.calcDiff(statistics, 'passedTestCase')
      },
      {
        key: `$failed-test-case-${statistics.failedTestCase}`,
        label: Statuses.FAILED.label,
        value: statistics.failedTestCase,
        color: 'failed',
        diff: this.calcDiff(statistics, 'failedTestCase')
      },
    ];
  }

  renderDuration(statistics) {
    const data = [{
      key: `total-duration-${statistics.id}`,
      value: Time.duration(statistics.totalDuration),
      label: 'Execution Time'
    },
    {
      key: `avg-duration-${statistics.id}`,
      value: Time.duration(statistics.averageDuration),
      label: 'Average Duration'
    }];
    return (
      <div className="d-flex flex-column">
        <StatusBarInfo key={`duration-status-${statistics.id}`} data={data} isValueSmall />
      </div>
    );
  }

  renderStatistics(data) {
    const statistics = data[0];
    return (
      <Row>
        <Col sm="6" md="6">
          <StatusDoughnutChart
            key={`test-case-chart-${statistics.id}`}
            data={this.convertTestCaseData(statistics)}
            type="Test Case"
            total={statistics.totalTestCase}
            diff={this.calcDiff(statistics, 'totalTestCase')}
            minWidth={125}
          />
        </Col>
        <Col sm="6" md="6">
          {this.renderDuration(statistics)}
        </Col>
      </Row>
    );
  }

  renderData(data) {
    if (data.length > 0) {
      return this.renderStatistics(data);
    } else {
      return <div className="text-center">{t('table#empty-message')}</div>;
    }
  }

  renderContent(routes) {
    const from = moment().subtract(1, 'week').format();
    const to = moment().subtract(0, 'week').format();
    return (
      <>
        <DataLoader
          key="project-statistics-week-1"
          ref={this.dataRef}
          entityType="ProjectStatistics"
          render={this.renderData}
          defaultSort={['startTime, desc']}
          title="Project performance"
          defaultSearchConditions={[
            buildSearchCondition('Project.id', '=', this.project.id),
            buildSearchCondition('statisticsType', '=', 'WEEKLY'),
            buildSearchCondition('startTime', '>=', from),
            buildSearchCondition('startTime', '<=', to)
          ]}
          pageSize={1}
          hidePaging
          noCard
        />
        {/* <div className="bottom-left-quick-icon" title={this.project.team.name}>
          <IconTeams width="18px" height="18px" />
          {this.project.team.name}
        </div> */}
        {/*<div className="quick-icon">*/}
        {/*  <a title={t('test-management')} href={routes.project_dashboard_link}>*/}
        {/*    <IconDot />*/}
        {/*    <IconCenter />*/}
        {/*  </a>*/}
        {/*  <a title={t('continuous-testing')} href={routes.ci_dashboard_link}>*/}
        {/*    <IconDot />*/}
        {/*    <IconCI />*/}
        {/*  </a>*/}
        {/*  <a title={t('testops-report')} href={routes.test_reports_link}>*/}
        {/*    <IconDot />*/}
        {/*    <IconTestOpsReports />*/}
        {/*  </a>*/}
        {/*  <a title={t('testops-keyes')} href={routes.keyes_link}>*/}
        {/*    <IconDot />*/}
        {/*    <IconKEyes />*/}
        {/*  </a>*/}
        {/*  <a title={t('testops-config')} href={routes.agent_link}>*/}
        {/*    <IconDot />*/}
        {/*    <IconConfiguration />*/}
        {/*  </a>*/}
        {/*</div>*/}
      </>
    );
  }

  renderHeader() {
    return (
      <div title={this.project.name} className="project-header">
        <div className="avatar">
          <ImageProfile
            name={this.project.name}
            diameter={24}
          />
        </div>
        {this.project.name}
      </div>
    );
  }

  render() {
    const routes = new Routes({ teamId: this.project.teamId, projectId: this.project.id });
    return (
      <Card>
        <a href={routes.project_link}>
          <OrganizationCard
            header={this.renderHeader()}
            content={this.renderContent(routes)}
          />
        </a>
      </Card>
    );
  }
}

RecentProject.propTypes = {
  project: PropTypes.object.isRequired,
};

export default RecentProject;
