import React, { Component } from 'react';
import DataLoader from '../../../components/table/DataLoader';
import { SearchEntity, TestRunStatus } from '../../../utils/Constants';
import { TestResultChartPayload } from '../../../utils/ChartHelper';
import { buildSearchFunction } from '../../../components/search/SearchUtils';
import ReportHelper from '../../../utils/ReportHelper';
import VerticalBarChart from '../../../components/chart/VerticalBarChart';
import { next } from '../../../utils/Count';
import { t } from '../../../i18n/t';
import NormalCard from '../../../components/card/NormalCard';
import TooltipComponent from '../../../components/TooltipComponent';
import MFlags from '../../../models/MFlags';

class TestResultProfileChart extends Component {

  constructor(props) {
    super(props);

    this.renderChart = this.renderChart.bind(this);
    this.dataLoaderRef = props.dataLoaderRef;
  }

  renderHeader() {
    return (
      <div className="overview-header-sub-title">
        {t('profile-coverage-by-test-result')}
        <TooltipComponent
          text={t('profile-coverage-by-test-result-tooltip')}
          placement="bottom-end"
          arrow
        />
      </div>
    );
  }

  renderChart(data) {
    const statusList = [
      TestRunStatus.PASSED,
      TestRunStatus.FAILED,
      TestRunStatus.ERROR,
      TestRunStatus.INCOMPLETE,
      TestRunStatus.SKIPPED,
    ];
    const convertedDataWithStatus = ReportHelper.convertData(data, statusList, 'profile');
    const convertedData = ReportHelper.handleEmptyProfile(convertedDataWithStatus);
    const payload = TestResultChartPayload;

    return (
      <VerticalBarChart
        data={convertedData}
        payload={payload}
      />
    );
  }

  render() {
    const { defaultSearchConditions } = this.props;
    return (
      <DataLoader
        ref={this.dataLoaderRef}
        key={next()}
        title={this.renderHeader()}
        entityType={SearchEntity.ExecutionTestResult}
        defaultSearchConditions={defaultSearchConditions}
        defaultSearchFunctions={[
          // test result
          buildSearchFunction('total', 'count', ['status']),
        ]}
        groupBys={['profile', 'status']}
        defaultSort={['profile,asc']}
        render={this.renderChart}
        useCache={!MFlags.testPerformanceForReportAndAnalyticsEnabled}
        pageSize={300}
        cardClassName="overview-card-border shadow-none"
        useRefreshButton={false}
        useCollapseButton={false}
        headerComponent={NormalCard}
        bodyComponent={NormalCard}
        showEmptyMessage
      />
    );
  }
}

export default TestResultProfileChart;
