import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import Routes from '../../utils/Routes';
import { buildSearchCondition } from '../../components/search/SearchUtils';
import { t } from '../../i18n/t';
import MContext from '../../models/MContext';
import ReleaseSummary from '../readiness_releases_dashboard/ReleaseSummary';
import DataTable from '../../components/table/DataTable';
import MTableColumnDataMapping from '../../components/table/models/MTableColumnDataMapping';
import Services from '../../utils/Services';
import { SearchEntity } from '../../utils/Constants';
import GroupEvent from '../../utils/track/GroupEvent';
import TooltipComponent from '../../components/TooltipComponent';
import Link from '../../components/Link';
import Apis from '../../utils/Apis';
import MFlags from '../../models/MFlags';
import WebSocket from '../../services/WebSocket';
import { next } from '../../utils/Count';

class ActiveReleasesSummary extends React.Component {
  constructor(props) {
    super(props);
    this.releaseList = null;
    this.dataRef = React.createRef();
    this.teamId = MContext.teamId;
    this.projectId = MContext.projectId;
    this.state = { isJiraIntegrated: false };
    this.onJiraSettingClick = this.onJiraSettingClick.bind(this);
  }

  getConnection() {
    const params = {
      pagination: {
        page: 0,
        size: 1,
        sorts: 'order,desc',
      },
      conditions: [
        {
          key: 'Project.id',
          operator: '=',
          value: this.projectId,
        },
      ],
      type: SearchEntity.ExternalConnection,
    };
    return Services.search(params)
      .then(({ content }) => {
        const connection = content[0];
        if (connection) {
          this.setState({ isJiraIntegrated: connection.active });
        }
        return connection;
      });
  }

  notifyHandler() {
    if (this.dataRef?.current) {
      this.dataRef.current.refreshData();
    }
  }

  subscribeTopic(handlerId) {
    const topic = SearchEntity.Release;
    WebSocket.subscribe({
      projectId: this.projectId,
      topics: [topic],
    }, () => this.notifyHandler(), handlerId);
  }

  componentDidMount() {
    this.getConnection();
    if (MFlags.refreshReleaseStatusOnDashboardEnabled) {
      const handlerId = next();
      this.subscribeTopic(handlerId);
    }
  }

  renderReleasesItems(release) {
    const { isJiraIntegrated } = this.state;
    return (
      <ReleaseSummary release={release} jiraIntegrated={isJiraIntegrated} />
    );
  }

  renderEmptyMessage() {
    const message = t('table#empty-message');
    const route = new Routes();
    const createRelease = <> Please <Link href={route.releases_link}>create release</Link> to view. </>;
    return (
      <span className="blue-dark">
        <div>
          {message}
        </div>
        <div>
          {createRelease}
        </div>
      </span>
    );
  }

  renderFooter() {
    const route = new Routes();
    return (
      <div className="text-right">
        <Link
          href={route.releases_link}
          data-trackid="dashboard-view-all-releases"
          data-groupid={GroupEvent.ACCESS_REPORT}
        >
          {t('view-all')}
        </Link>
      </div>
    );
  }

  onJiraSettingClick() {
    return Routes.goToJiraSetting();
  }

  renderEnableJiraButton() {
    const { isJiraIntegrated } = this.state;
    return (
      <div className="btn-jira-setting">
        <Button
          color="primary"
          onClick={this.onJiraSettingClick}
          disabled={isJiraIntegrated}
        >
          {t('jira-integration#enable')}
        </Button>
      </div>
    );
  }

  render() {
    const { defaultSearchConditions = [] } = this.props;
    const { isJiraIntegrated } = this.state;
    const customSearchConditions = [
      ...defaultSearchConditions,
      buildSearchCondition('closed', '=', 'false'),
    ];

    const columnDataMappings = [
      new MTableColumnDataMapping(
        null,
        '',
        (name, row) => this.renderReleasesItems(row),
      ),
    ];

    return (
      <>
        <div className="d-flex project-dashboard-header">
          <div className="project-dashboard-header-title">
            {t('release_readiness')}
            <TooltipComponent text={t('project-dashboard#release-readiness#tooltip')} placement="bottom-end" arrow />
          </div>
          <div className="ml-auto align-items-center d-flex">
            {this.renderEnableJiraButton()}
          </div>
        </div>
        <div
          className="active-releases-summary"
          title={!isJiraIntegrated && t('jira-integration#details')}
        >
          {MFlags.dashboardAIPhase2Enabled ?
            <DataTable
              ref={this.dataRef}
              columnMapping={columnDataMappings}
              sourceUrl={Apis.getIncomingActiveReleases(this.projectId)}
              pageSize={2}
              renderFooter={this.renderFooter()}
              emptyMessage={this.renderEmptyMessage()}
              hidePaging
              {...this.props}
            /> :
            <DataTable
              columnMapping={columnDataMappings}
              ref={(ref) => {
                this.releaseList = ref;
              }}
              {...this.props}
              entityType="Release"
              sourceFieldName="content"
              pageSize={2}
              defaultSearchConditions={customSearchConditions}
              renderFooter={this.renderFooter()}
              emptyMessage={this.renderEmptyMessage()}
              hidePaging
            />}
        </div>
      </>
    );
  }
}

ActiveReleasesSummary.propTypes = {
  defaultSearchConditions: PropTypes.array.isRequired,
};

export default ActiveReleasesSummary;
