import PropTypes from 'prop-types';
import React from 'react';
import { ButtonToolbar, FormGroup, Label } from 'reactstrap';
import CreateExternalIssueBtn from '../../pages/execution_test_result/CreateExternalIssueBtn';
import { ObjectType } from '../../utils/Constants';
import { useExternalConnection } from '../Hooks';
import ExternalConnectionAlert from './ExternalConnectionAlert';
import ExternalIssueDataTable from './ExternalIssueDataTable';
import KatalonAddonAlert from './KatalonAddonAlert';
import LinkExternalIssue from './LinkExternalIssue';
import MContext from '../../models/MContext';

function ExternalIssue({ title, projectId, objectType, objectId, linkIssueClassName, pageSize }) {
  const connection = useExternalConnection(projectId);
  const isTeamDemo = MContext.isTeamDemo;
  const active = connection && connection.active;
  const katalonAddonElement = document.getElementById('has-katalon-add-on');
  const showCreateExternalIssue = ObjectType.EXECUTION_TEST_RESULT === objectType;

  return (
    <>
      <FormGroup className={linkIssueClassName}>
        <Label for="jira-issue" className="mr-2">{title}</Label>
        <ButtonToolbar>
          <LinkExternalIssue
            projectId={projectId}
            objectType={objectType}
            objectId={objectId}
            key={objectId}
            disabled={!active || isTeamDemo}
            className="flex-grow-1"
          />
          {showCreateExternalIssue &&
          <CreateExternalIssueBtn testRunId={objectId} disabled={!active || !katalonAddonElement || isTeamDemo} />}
        </ButtonToolbar>
      </FormGroup>
      <FormGroup>
        {!active && <ExternalConnectionAlert />}
        {active && !katalonAddonElement && showCreateExternalIssue && <KatalonAddonAlert />}
        <ExternalIssueDataTable
          projectId={projectId}
          objectType={objectType}
          objectId={objectId}
          key={objectId}
          noCard
          pageSize={pageSize}
        />
      </FormGroup>
    </>
  );
}

ExternalIssue.propTypes = {
  title: PropTypes.string,
  projectId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  objectType: PropTypes.string,
  objectId: PropTypes.number,
  pageSize: PropTypes.number,
};

export default ExternalIssue;
