import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Tree } from 'antd';
import { isNumber } from 'lodash';
import { Input, IconButton } from '@mui/material';
import { t } from '../../i18n/t';
import { IconFolder, IconFolderOpen, IconFolderSelected, IconFolderOpenSelected } from '../../images/CustomIcon';
import ConvertDataHelper from '../../utils/ConvertDataHelper';
import PageHistoryHelper from '../../utils/PageHistoryHelper';
import { IconGreyPlus } from '../../images/CustomNewIcon';
import MFlags from '../../models/MFlags';
import StringHelper from '../../utils/StringHelper';
import TreeViewHelper from '../../utils/treeview/TreeViewHelper';

const { DirectoryTree } = Tree;

function TreeView(props) {

  const {
    data,
    className,
    draggable,
    enableHistory,
    componentName,
    titleRender,
    enableEdit = false,
    validateNewNodeTitle,
    navigateDirectSubFolder = null,
    nodeIdPrefix,
    excludedFolderPaths,
  } = props;

  const [treeData, setTreeData] = useState(data);

  // TreeView controlled state for expand keys on the list.
  const [expandedKeys, setExpandedKeys] = useState([...props.expandedKeys]);

  /**
   * TreeView controlled state for select keys on the list. Currently, we only support single select (Folder with background).
   * Folder can be selected regardless of it't parent state.
   */
  const [selectedKeys, setSelectedKeys] = useState(props.defaultSelectedKeys);

  const [newNodeTitle, setNewNodeTitle] = useState('');

  const [isReady, setIsReady] = useState(true);

  const loop = (data, key, callback) => {
    for (let i = 0; i < data.length; i++) {
      if (data[i].key === key) {
        return callback(data[i], i, data);
      }
      if (data[i].children) {
        loop(data[i].children, key, callback);
      }
    }
    return null;
  };

  const onDrop = (info) => {
    const dropKey = info.node.key;
    const dragKey = info.dragNode.key;
    const dropPos = info.node.pos.split('-');
    const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);

    const data = [...treeData];

    // Find dragObject
    let dragObject;
    loop(data, dragKey, (item, index, arr) => {
      arr.splice(index, 1);
      dragObject = item;
    });

    if (!info.dropToGap) {
      // Drop on the content
      loop(data, dropKey, (item) => {
        item.children = item.children || [];
        item.children.unshift(dragObject);
      });
    } else if (
      (info.node.props.children || []).length > 0 && // Has children
      info.node.props.expanded && // Is expanded
      dropPosition === 1 // On the bottom gap
    ) {
      loop(data, dropKey, (item) => {
        item.children = item.children || [];
        item.children.unshift(dragObject);
        // in previous version, we use item.children.push(dragObj) to insert the
        // item to the tail of the children
      });
    } else {
      let ar;
      let i;
      loop(data, dropKey, (item, index, arr) => {
        ar = arr;
        i = index;
      });
      if (dropPosition === -1) {
        ar.splice(i, 0, dragObject);
      } else {
        ar.splice(i + 1, 0, dragObject);
      }
    }

    setTreeData(data);
  };

  const saveSelectedKeysToHistory = (selectedKeys) => {
    let historyState = PageHistoryHelper.getState(componentName);
    if (!historyState) {
      historyState = {
        expandedKeys,
        selectedKeys,
      };
    }
    historyState.selectedKeys = selectedKeys;
    PageHistoryHelper.saveState(componentName, historyState);
  };

  const saveExpandedKeysToHistory = (expandedKeys) => {
    let historyState = PageHistoryHelper.getState(componentName);
    if (!historyState) {
      historyState = {
        expandedKeys,
        selectedKeys,
      };
    }
    historyState.expandedKeys = expandedKeys;
    PageHistoryHelper.saveState(componentName, historyState);
  };

  const selectNodes = (selectedKeys, selectedNodes) => {
    if (props.onSelect) {
      props.onSelect(selectedKeys, selectedNodes);
    }
    setSelectedKeys(selectedKeys);
    if (enableHistory) {
      saveSelectedKeysToHistory(selectedKeys);
    }
  };

  const onSelect = (selectedKeys, info) => {
    selectNodes(selectedKeys, info?.selectedNodes);
  };

  const onExpand = (expandedKeys) => {
    setExpandedKeys(expandedKeys);
    if (props.onExpand) {
      props.onExpand();
    }
    if (enableHistory) {
      saveExpandedKeysToHistory(expandedKeys);
    }
  };

  const renderIcon = (treeNode) => {
    const { expanded, selected, iconFolder } = treeNode;

    if (iconFolder) {
      return <div className={`folder-icon ${selected && 'selected'}`}>{iconFolder}</div>;
    }

    if (selected && expanded) {
      return (<IconFolderOpenSelected />);
    }

    if (selected && !expanded) {
      return (<IconFolderSelected />);
    }

    if (!selected && expanded) {
      return <IconFolderOpen />;
    }

    return <IconFolder />;
  };

  const updateNodeData = (origin, key, newNodeData) => origin.map((node) => {
    if (node.key === key) {
      return { ...node, ...newNodeData };
    }
    if (node.children) {
      return { ...node, children: updateNodeData(node.children, key, newNodeData) };
    }
    return node;
  });

  const loadData = ({ key, children }) => new Promise((resolve) => {
    /**
     * if key is root (has prefix root_*) or nodes have children not null
     * => Dont need to fetch data
     */
    if (!isNumber(key) || (children && children.length !== 0)) {
      resolve();
      return;
    }
    if (props.loadData) {
      props.loadData(key).then((content) => {
        if (content.length === 0) {
          resolve();
          return;
        }
        children = ConvertDataHelper.parseSubDataTree(content, key);
        setTreeData((origin) =>
          TreeViewHelper.updateTreeData(origin, key, children));
        resolve();
      });
    }
  });

  const smoothScrollToFolder = (folderId) => {
    if (folderId) {
      const nodeId = nodeIdPrefix ? `${nodeIdPrefix}_${folderId}` : folderId;
      const treeTitle = document.getElementById(nodeId);
      if (treeTitle) {
        // Call offsetParent twice to get tree node
        const treeNode = treeTitle.offsetParent.offsetParent;

        treeNode.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
      } else {
        setTimeout(() => smoothScrollToFolder(folderId), 2000);
      }
    }
  };

  const expandPreviousSelectedFolder = () => {
    const oldState = PageHistoryHelper.getState(componentName);
    if (!oldState) {
      return;
    }

    const { expandedKeys, selectedKeys } = oldState;
    if (expandedKeys) {
      setExpandedKeys(expandedKeys);
    }
    if (selectedKeys) {
      setSelectedKeys(selectedKeys);
      smoothScrollToFolder(selectedKeys[0]);
    }
  };

  const handlerNavigateDirectSubFolder = () => {
    const { expandedKeys, folderTitle } = navigateDirectSubFolder;

    if (expandedKeys?.length > 0) {
      expandedKeys.forEach((key) => {
        if (isNumber(key) && props.loadData) {
          props.loadData(key).then((content) => {
            if (content.length === 0) {
              return;
            }

            const children = ConvertDataHelper.parseSubDataTree(content, key);

            const child = children.filter((item) => item.title === folderTitle)[0];

            setTreeData((origin) =>
              TreeViewHelper.updateTreeData(origin, key, children));

            if (child) {
              setExpandedKeys([...expandedKeys, child.key]);
              setSelectedKeys([child.key]);
              smoothScrollToFolder(child.key);
            }
          });
        }
      });
    }
  };

  useEffect(() => {
    if (navigateDirectSubFolder) {
      handlerNavigateDirectSubFolder();
    } else if (enableHistory) {
      expandPreviousSelectedFolder();
    }
  }, []);

  const removeNodeData = (origin, key) => {
    const filteredNodes = origin.filter((node) => node.key !== key);
    if (origin.length > filteredNodes.length) {
      return filteredNodes;
    } else {
      return origin.map((node) => {
        if (node.children) {
          return { ...node, children: removeNodeData(node.children, key) };
        }
        return node;
      });
    }
  };

  const cancelCreate = (nodeData) => {
    setTreeData((origin) => removeNodeData(origin, nodeData.key));
    setIsReady(true);
    setNewNodeTitle('');
    validateNewNodeTitle('');
  };

  const saveNewNode = (nodeData) => {
    if (!isReady || newNodeTitle === ''
      || (MFlags.preventNamingTestOpsInTestSuiteFoldersEnabled && excludedFolderPaths.includes(`${nodeData.rawPath}/${newNodeTitle}`))) {
      cancelCreate(nodeData);
    } else {
      const newNode = {
        ...nodeData,
        title: newNodeTitle,
        isEditing: false,
        rawPath: `${nodeData.rawPath}/${newNodeTitle}`
      };
      setTreeData((origin) => updateNodeData(origin, nodeData.key, newNode));
      setNewNodeTitle('');
      selectNodes([nodeData.key], [newNode]);
    }
  };

  const onClickAddNode = (e, nodeData) => {
    e.stopPropagation();
    setIsReady(false);
    selectNodes([nodeData.key], [nodeData]);
    const isExist = expandedKeys.find((key) => key === nodeData.key);
    if (!isExist) {
      setExpandedKeys((prevExpandedKeys) => [...prevExpandedKeys, nodeData.key]);
    }
    const newNode = {
      title: '',
      key: `${nodeData.key}-${nodeData.children.length}`,
      testProjectId: nodeData.testProjectId,
      children: [],
      rawPath: nodeData.rawPath,
      isEditing: true
    };
    setTreeData((origin) =>
      TreeViewHelper.updateTreeData(origin, nodeData.key, [newNode], true));
    selectNodes([newNode.key], [newNode]);
  };

  const onKeyDownNewNode = (event, nodeData) => {
    if (event.key === 'Enter') {
      saveNewNode(nodeData);
    }
    if (event.key === 'Escape') {
      cancelCreate(nodeData);
    }
  };

  const onChangeNewFolder = (event, nodeData) => {
    const value = StringHelper.removeExtraSpace(event.target.value);
    setNewNodeTitle(value);
    const valid = validateNewNodeTitle(value, nodeData);
    setIsReady(valid);
  };

  const renderTitle = (nodeData) => {
    if (enableEdit && selectedKeys && selectedKeys.includes(nodeData.key) && nodeData.isEditing) {
      return (
        <Input
          id={`node-input-${nodeData.key}`}
          fullWidth
          placeholder={t('untitled-folder')}
          autoFocus
          onChange={(event) => onChangeNewFolder(event, nodeData)}
          onBlur={() => saveNewNode(nodeData)}
          onKeyDown={(event) => onKeyDownNewNode(event, nodeData)}
          sx={{
            minWidth: '300px'
          }}
        />
      );
    } else {
      return (
        <>
          {titleRender(nodeData)}
          {enableEdit && isReady &&
            <IconButton
              onClick={(e) => onClickAddNode(e, nodeData)}
              className="tree-action-btn"
              title={t('add-new-folder-title')}
              size="large"
            >
              <IconGreyPlus />
            </IconButton>}
        </>
      );
    }
  };

  return (
    <DirectoryTree
      className={`${className || 'draggable-tree'}`}
      draggable={draggable}
      onDrop={onDrop}
      treeData={treeData}
      icon={renderIcon}
      onSelect={onSelect}
      onExpand={onExpand}
      loadData={loadData}
      selectedKeys={selectedKeys}
      expandedKeys={expandedKeys}
      componentName={props.componentName}
      titleRender={renderTitle}
    />
  );
}

TreeView.propTypes = {
  /**
    data is array of folder
    Ex:
     [
      {
        title: 'Folder1',
        key: 'Folder 1',
        children: [
          {
            title: 'Folder 1.1',
            key: 'Folder 1.1'
          }
        ],
      },
      {},
      ...
    ]
   * title: A text to show in UI
   * key **important**: used to distinguish node in tree, if duplicate => break UI
   * (Note: key of root have prefix root_..., key of sub-folder is ID of testFolder)
   * children: sub-node of this node
   */
  data: PropTypes.object,
  onSelect: PropTypes.func,
  onExpand: PropTypes.func,
  className: PropTypes.string,
  draggable: PropTypes.bool,
  enableHistory: PropTypes.bool,
  componentName: PropTypes.string,
  enableEdit: PropTypes.bool,
  /**
   * {
   *    folderTitle: "targetFolderName",
   *    expandedKeys: [root_1, 1, 2, ...]
   * }
   *
   * folderTitle: Provide name of folder to selected correct folder.
   * expandedKeys: Provide all keys that we want to expanded.
   */
  navigateDirectSubFolder: PropTypes.object,
  /**
   * smoothScrollToFolder need id of node, so to distinguishing trees when one page has more than one tree
   * need nodeIdPrefix
   */
  nodeIdPrefix: PropTypes.string,
};

TreeView.defaultProps = {
  expandedKeys: []
};

export default TreeView;
