import React, { useState } from 'react';
import { DialogContent, DialogContentText, DialogActions } from '@mui/material';
import CloseableDialogComponent from './CloseableDialogComponent';
import { IconTrialRequestSuccess } from '../../images/CustomNewIcon';
import { t } from '../../i18n/t';
import { Button } from 'reactstrap';
import { TestOpsFeature, VisualTestingFeature } from '../../utils/Constants';

export default function TrialRequestSuccessDialog({ testOpsFeature }) {
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(!open);
  };

  const renderMessage = () => {
    switch (testOpsFeature) {
      case TestOpsFeature.TESTOPS_ENTERPRISE:
        return t('trialRequest#enterprise-success');
      case VisualTestingFeature.VISUAL_TESTING_PRO:
        return t('trialRequest#visual-testing-pro-success');
      default:
        return t('trialRequest#bussiness-success');
    }
  }

  if (testOpsFeature === TestOpsFeature.TESTOPS_BUSINESS
    || testOpsFeature === TestOpsFeature.TESTOPS_ENTERPRISE
    || testOpsFeature === VisualTestingFeature.VISUAL_TESTING_PRO) {
    return (
      <CloseableDialogComponent
        isOpen={open}
        handleClose={handleClose}
      >
        <DialogContent>
          <div className="image">
            <IconTrialRequestSuccess />
          </div>
          <DialogContentText>
            <div className="title">
              {renderMessage()}
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions className="popup-footer">
          <Button onClick={handleClose} color="primary">
            {t('trialRequest#explore-button')}
          </Button>
        </DialogActions>
      </CloseableDialogComponent>
    );
  }

  return null;

}
