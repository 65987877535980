import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { FormGroup, Label } from 'reactstrap';
import Input from '../../../components/Input';
import { t } from '../../../i18n/t';
import { SsoOption, SsoOptionStatus } from '../../../utils/Constants';
import SsoAuthSwitch from './SsoAuthSwitch';

function toMap(assignedOptions) {
  return new Map(assignedOptions.map((option) => [option.option, option]));
}

function isPending(option) {
  return option && option.status === SsoOptionStatus.PENDING;
}

function isDeclined(option) {
  return option && option.status === SsoOptionStatus.DECLINED;
}

function formatOption(option) {
  if (isPending(option)) {
    return ' (Pending)';
  } else if (isDeclined(option)) {
    return ' (Declined)';
  } else {
    return '';
  }
}

function isChecked(activeOptions, assignedOptionsMap, optionValue) {
  const option = assignedOptionsMap.get(optionValue);
  if (isPending(option)) {
    return true;
  }
  if (isDeclined(option)) {
    return false;
  }
  return !!activeOptions.has(optionValue);
}

function isDisabled(assignedOptionsMap, optionValue) {
  return isPending(assignedOptionsMap.get(optionValue));
}

function OrganizationUsersSsoConfiguration({ assignedOptions, onOptionChange = () => null, blockUI }) {
  const [assignedOptionsMap, setAssignedOptionsMap] = useState(new Map());
  const [activeOptions, setActiveOptions] = useState(new Set());

  useEffect(() => {
    const newAssignedOptionsMap = toMap(assignedOptions);
    setAssignedOptionsMap(newAssignedOptionsMap);

    const newActiveOptions = new Set([...newAssignedOptionsMap.keys()]);
    setActiveOptions(newActiveOptions);
  }, [assignedOptions]);

  const onChange = (option, checked, label) => {
    const newActiveOptions = new Set([...activeOptions]);
    if (!checked) {
      newActiveOptions.delete(option);
    } else {
      newActiveOptions.add(option);
    }

    setActiveOptions(newActiveOptions);

    if (onOptionChange) {
      onOptionChange(option, newActiveOptions, label);
    }
  };

  return (
    <FormGroup>
      <Label>{t('sso_configuration')}</Label>
      <Input plaintext>Please select at least one login option</Input>
      <FormGroup>
        <SsoAuthSwitch
          data-trackid="sso-authentication-switch"
          id="ssoAuthOption"
          label={t('sso_authentication') + formatOption(assignedOptionsMap.get(SsoOption.SSO_AUTH))}
          optionValue={SsoOption.SSO_AUTH}
          onChange={onChange}
          checked={isChecked(activeOptions, assignedOptionsMap, SsoOption.SSO_AUTH)}
          disabled={blockUI || isDisabled(assignedOptionsMap, SsoOption.SSO_AUTH)}
          className="normal-label"
        />
        <SsoAuthSwitch
          data-trackid="basic-authentication-switch"
          id="basicAuthOption"
          label={t('basic_authentication') + formatOption(assignedOptionsMap.get(SsoOption.BASIC_AUTH))}
          optionValue={SsoOption.BASIC_AUTH}
          onChange={onChange}
          checked={isChecked(activeOptions, assignedOptionsMap, SsoOption.BASIC_AUTH)}
          disabled={blockUI || isDisabled(assignedOptionsMap, SsoOption.BASIC_AUTH)}
          className="normal-label"
        />
      </FormGroup>
    </FormGroup>
  );
}

OrganizationUsersSsoConfiguration.propTypes = {
  assignedOptions: PropTypes.array,
  onOptionChange: PropTypes.func,
};

OrganizationUsersSsoConfiguration.defaultProps = {
  assignedOptions: [],
};

export default OrganizationUsersSsoConfiguration;
