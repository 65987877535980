import React, { Component } from 'react';
import MContext from '../../../models/MContext';
import { buildSearchCondition } from '../../search/SearchUtils';
import Services from '../../../utils/Services';
import ReleaseBuildDropdown from './ReleaseBuildDropDown';
import { SearchEntity } from '../../../utils/Constants';

class ReleaseBuildFilter extends Component {
  constructor(props) {
    super(props);
    this.clear = this.clear.bind(this);
    this.filterMenu = React.createRef();
    this.state = {
      releases: [],
    };
  }

  componentDidMount() {
    this.getReleases();
  }

  clear() {
    this.filterMenu.current.clear();
  }

  getReleases() {
    const params = {
      pagination: {
        page: 0,
        size: 1000000,
        sorts: ['name,asc'],
      },
      conditions: [
        buildSearchCondition('Project.id', '=', MContext.projectId),
        buildSearchCondition('closed', '=', false)
      ],
      type: SearchEntity.Release,
    };
    Services.search(params)
      .then(({ content }) => this.setState({ releases: content }));
  }

  render() {
    const { releases } = this.state;
    return (
      <ReleaseBuildDropdown
        releases={releases}
        ref={this.filterMenu}
        {...this.props}
      />
    );
  }
}

export default ReleaseBuildFilter;
