import { DraftTestCase } from '../../../models/model/DraftTestCase';
import TestObjectPublisher from './TestObjectPublisher';
import { TestCaseAutoUploadQueue } from './TestCaseAutoUploadQueue';
import TestCaseService from './TestCaseService';

export default {
  init(options: {
    queue: ConstructorParameters<typeof TestCaseAutoUploadQueue>[0];
  }): [TestCaseAutoUploadQueue] {
    const newQueue = new TestCaseAutoUploadQueue(options.queue);
    return [newQueue];
  },

  publishTestCase(
    queue: TestCaseAutoUploadQueue,
    testCaseId: number,
    testCaseData: DraftTestCase
  ): boolean {
    const { scriptRepoId } = testCaseData;
    if (typeof scriptRepoId !== 'number') {
      // this may happen on legacy data (draft-only test case)
      return false;
    }

    const publishTestCase = TestCaseService.buildPublishTestCase(
      testCaseId,
      testCaseData.name,
      testCaseData.path,
      testCaseData.testType,
      testCaseData.executionType,
      testCaseData.content,
      testCaseData.description
    );

    queue.enqueue(testCaseData.lastUpdated, async () => {
      const noOpErrorHandler: any = () => {
        // intentionally left empty
      };
      const ignoreBlockUI = true;
      await TestObjectPublisher.publishTestObject(
        scriptRepoId,
        [publishTestCase],
        [],
        '', // message
        noOpErrorHandler,
        ignoreBlockUI
      );
    });

    return true;
  },
};
