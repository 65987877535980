import React from 'react';
import { t } from '../../i18n/t';
import DocumentLink from '../../utils/DocumentLink';
import AlertComponent from '../AlertComponent';
import InfoIcon from '../../../images/icons/katalonui/InfoIcon';

function AlertScriptRepoWhitelistIPs() {
  const renderWhitelistIPsTextBanner = () => (
    <div>
      <span>
        {t('test-project#need-whitelist-ip-addresses-info-banner-1')}
      </span>
      <a href={DocumentLink.SCRIPT_REPO_WHITELIST_IPS} target="_blank" rel="noopener noreferrer">
        {t('test-project#document-info-banner')}
      </a>
      <span>
        {t('test-project#need-whitelist-ip-addresses-info-banner-2')}
      </span>
    </div>
  );
  return (
    <AlertComponent
      text={renderWhitelistIPsTextBanner()}
      icon={<InfoIcon />}
      className="info mb-4"
    />
  );
}

export default AlertScriptRepoWhitelistIPs;
