import React from 'react';
import { IconAngleRight, IconDown } from '../../../images/KitIcons';

const CollapseButton = ({ collapsible, isOpen }) => (
  <span>
    {collapsible && isOpen ? <IconDown /> : <IconAngleRight className={!collapsible && 'invisible'} />}
  </span>
);

export default CollapseButton;
