import React from 'react';
import { Route, Switch } from 'react-router-dom';
import queryString from 'query-string';
import { t } from '../i18n/t';
import ExecutionDataTable from '../components/table/ExecutionDataTable';
import PageComponent from '../components/PageComponent';
import MContext from '../models/MContext';
import ObjectSummary from '../components/summary/ObjectSummary';
import PerformanceLineChart from '../components/chart/PerformanceLineChart';
import DefaultLayout from '../components/DefaultLayout';
import { TestRunHeaderNav } from '../components/HeaderNav';
import RouteConstants from '../utils/RouteConstants';
import TestReportTestExecution from './test_reports/TestReportTestExecution';
import Routes from '../utils/Routes';
import PageButtonToolbar from '../components/PageButtonToolbar';
import DecoratorConstants from '../utils/DecoratorConstants';
import GroupEvent from '../utils/track/GroupEvent';
import TestRunDailyReport from './test_reports/test_run_report/TestRunDailyReport';
import MFlags from '../models/MFlags';
import OverviewReports from './OverviewReports';
import FailedTestResults from './test_reports/FailedTestResults';
import { buildFilter } from '../components/search-query/FilterQueryHelper';
import TagFilterV2 from '../components/search-query/filter/TagFilterV2';
import CustomFieldFilter from '../components/CustomFieldFilter';
import ExecutionDataTableV2 from '../components/table/ExecutionDataTableV2';
import { SearchEntity } from '../utils/Constants';
import { IconPenField, IconTags } from '../images/CustomIcon';
import ButtonLink from '../components/ButtonLink';
import FailedTestResultsV2 from './test_reports/FailedTestResultsV2';
import ReportsAndAnalytics from './ReportsAndAnalytics';

class Executions extends PageComponent {
  constructor(props) {
    super(props);
    this.renderPerformance = this.renderPerformance.bind(this);
    this.projectId = MContext.projectId;
    this.project = MContext.project;
    this.renderHeader = this.renderHeader.bind(this);
    this.renderBody = this.renderBody.bind(this);
    this.renderExecutions = this.renderExecutions.bind(this);
    this.renderUploadReports = this.renderUploadReports.bind(this);
    this.state = {
      isHeaderVisible: true,
    };
    this.meta.id = 'page-executions';
    this.meta.title = t(
      'Project {{name}} - {{page}}',
      { name: this.project.name, page: t('executions') },
    );
  }

  renderPerformance(data) {
    const chartLabelsDecorate = (fieldName, row) => DecoratorConstants.dateAndOrderDecorator('', row);

    return (
      <PerformanceLineChart
        data={data.map(({ elapsedDuration, order, status, startTime }) => ({
          duration: elapsedDuration,
          order,
          status,
          startTime
        }))}
        xLabel={t('test_runs')}
        chartLabelsDecorate={chartLabelsDecorate}
      />
    );
  }

  renderObjectSummary() {
    const { project } = this;
    const urlParams = {
      executions: t('executions'),
      projectName: project.name
    };
    return (
      <ObjectSummary params={urlParams}>
        <TestRunHeaderNav />
      </ObjectSummary>
    );
  }

  renderHeader() {
    return (
      <>
        {this.renderObjectSummary()}
      </>
    );
  }

  renderUploadReports() {
    const routes = new Routes();
    return (
      <PageButtonToolbar>
        <ButtonLink
          title={t('report#uploadReports')}
          color="secondary"
          href={routes.import_manual_reports_link}
          data-trackid="upload-test-run-reports"
          data-groupid={GroupEvent.UPLOAD_REPORT}
        >
          {t('report#uploadReports')}
        </ButtonLink>
      </PageButtonToolbar>
    );
  }

  renderExecutions() {
    if (MFlags.useRAMicroFrontEndEnabled) {
      return (
        <ReportsAndAnalytics />
      );
    }

    const params = queryString.parse(this.props.location.search);

    return MFlags.testRunReportFilterEnhancementEnabled ?
      (
        <div id="executions__data-table" className="executions__data-table">
          <ExecutionDataTableV2
            useCollapseButton={false}
            entityType={SearchEntity.Execution}
            showProjectColumn={false}
            showCheckboxColumn
            additionalFilterQuery={[
              buildFilter(CustomFieldFilter, { id: 'CustomField', icon: <IconPenField /> }),
              buildFilter(TagFilterV2, { id: 'Tag.id', label: t('search-bar#tag'), icon: <IconTags /> }),
            ]}
            urlSearchValue={params.q}
          />
        </div>
      )
      :
      (
        <>
          <TestRunDailyReport />
          <div className="executions__data-table">
            <ExecutionDataTable
              showProjectColumn={false}
              defaultSearchConditions={[]}
              useSearchQuery
              showCheckboxColumn
              renderChart={this.renderPerformance}
              defaultSort={['order,desc']}
              additionalFilterQuery={[
                buildFilter(CustomFieldFilter, { id: 'CustomField' }),
                buildFilter(TagFilterV2, { id: 'Tag.id', label: t('search-bar#tag') }),
              ]}
            />
          </div>
        </>
      );
  }

  componentDidMount() {
    if (MFlags.testRunHideHeaderEnable) {
      const executionElm = document.getElementById('executions__data-table');
      if (executionElm) {
        // Taking parentElm since windowScroll is always 0
        const parentElm = executionElm.parentElement;
        parentElm.onscroll = () => {
          const currentScrollPos = parentElm.scrollTop;
          if (currentScrollPos > 0) {
            this.setState({ isHeaderVisible: false });
          } else {
            this.setState({ isHeaderVisible: true });
          }
        };
      }
    }
  }

  renderBody() {
    return (
      <>
        {this.renderUploadReports()}
        <Switch>
          <Route
            exact
            path={RouteConstants.overview}
            component={(params) => <OverviewReports {...params} />}
          />
          <Route
            exact
            path={RouteConstants.executions}
            component={this.renderExecutions}
          />
          <Route
            path={RouteConstants.test_report_test_execution}
            component={(params) => <TestReportTestExecution {...params} />}
          />
          <Route
            exact
            path={RouteConstants.failed_test_result}
            component={(params) => {
              if (MFlags.revampFailedTestResultEnabled) {
                return <FailedTestResultsV2 {...params} />;
              } else {
                return <FailedTestResults {...params} />;
              }
            }}
          />
        </Switch>
      </>
    );
  }

  render() {
    return (
      <DefaultLayout
        isHeaderVisible={this.state.isHeaderVisible}
        renderHeader={this.renderHeader}
        renderBody={this.renderBody}
      />
    );
  }
}

export default Executions;
