import React from 'react';
import TagChip from './TagChip';

const TagList = ({ handleOnDelete, selectedTags }) => {

  const handleDelete = (option) => {
    handleOnDelete(option);
  };

  return (
    <>
      {selectedTags.map((option) => (
        <TagChip
          name={option.name}
          handleRemove={() => handleDelete(option)}
        />
      ))}
    </>
  );
};

export default TagList;
