import React, { Component } from 'react';
import { ListItem } from '@mui/material';
import { Button, ButtonToolbar, Form } from 'reactstrap';
import { t } from '../../../i18n/t';
import DropDownFilter from './DropDownFilter';
import MContext from '../../../models/MContext';
import TagFilterComponent from './TagFilterComponent';

class TagFilterV2 extends Component {
  constructor(props) {
    super(props);
    this.projectId = MContext.projectId;
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.filterMenu = React.createRef();
    this.state = {
      selectedTags: [],
    };
  }

  buttonLabel() {
    const { selectedTags } = this.state;
    const { label } = this.props;
    return (
      <span className="font-weight-bold">
        {label}
        { selectedTags.length > 0 ?
          <span className="ml-1 custom-field-filter__badge"> {selectedTags.length} </span> :
          '' }
      </span>
    );
  }

  handleSelectChange(event, selectedOptions) {
    this.setState({
      selectedTags: selectedOptions
    });
  }

  handleDelete= (option) => {
    const { selectedTags } = this.state;
    const filteredTags = selectedTags.filter((item) => item.name !== option.name);
    this.setState({
      selectedTags: filteredTags
    });
  }

  setSelectedTags = (tags) => {
    this.setState({
      selectedTags: tags,
    });
  };

  buildFilterValue= (selectedTags) => (selectedTags.length === 1 ? `${selectedTags[0].id}` : selectedTags.map((tag) =>
    `${tag.id}`))

  applyFilter= (event) => {
    event.preventDefault();
    const { selectedTags } = this.state;
    this.filterMenu.current.applyFilter(null);
    if (selectedTags.length > 0) {
      const filterValue = this.buildFilterValue(selectedTags);
      this.filterMenu.current.applyFilter(filterValue);
      this.filterMenu.current.handleToggle();
    }
  }

  closeDialog = () => {
    this.filterMenu.current.handleToggle();
  }

  clear = () => {
    this.setState({
      selectedTags: []
    });
    this.filterMenu.current.applyFilter(null);
  }

  render() {
    const { selectedTags } = this.state;
    const { placeholder, ...props } = this.props;
    return (
      <DropDownFilter buttonLabel={this.buttonLabel()} {...this.props} ref={this.filterMenu}>
        <Form className="form-filter" onSubmit={this.applyFilter}>
          <ListItem>
            <TagFilterComponent
              handleSelectChange={this.handleSelectChange}
              handleDelete={this.handleDelete}
              selectedTags={selectedTags}
              placeholder={placeholder}
              setSelectedTags={this.setSelectedTags}
              autoInputClassName="tag-autocomplete"
              {...props}
            />
          </ListItem>
          <ListItem>
            <ButtonToolbar>
              <Button
                color="primary"
                type="submit"
                data-trackid="filter-by-tag"
              >
                {t('custom-fields#filter-update')}
              </Button>
              <Button color="link" onClick={this.clear}>
                {t('search-bar#clear')}
              </Button>
            </ButtonToolbar>
          </ListItem>
        </Form>
      </DropDownFilter>
    );
  }
}

export default TagFilterV2;
