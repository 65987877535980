

const Config = {

  auth: {
    client_id: 'kit',
    client_secret: 'kit',
    grant_type: 'password',
    getHeader: () => `Basic ${btoa(`${Config.auth.client_id}:${Config.auth.client_secret}`)}`,
  },

  api: '/api/v1',
  apiWordpress: 'https://web-api.katalon.com/wp-json/restful_api/v1',
  payment: {
    country: 'US',
    currency: 'usd',
  }
};


export default Config;
