import React, { useState } from 'react';
import {
  Typography,
  Button,
  Stack,
} from '@mui/material';
import Icon from '@katalon-studio/katalon-ui/Icon';
import { t } from '../../i18n/t';
import Time from '../../utils/Moment';
import DecoratorConstants from '../../utils/DecoratorConstants';
import colors from '../../../scss/colors.scss';
import { ExternalIssueAutomationRule } from '../../models/model/ExternalIssueAutomationRule';
import ConfirmDeleteAutomationRuleDialog from '../dialog/ConfirmDeleteAutomationRuleDialog';
import Services from '../../utils/Services';
import Notification from '../../utils/Notification';
import GroupEvent from '../../utils/track/GroupEvent';
import { sendAnalyticEventForAction } from '../../utils/SegmentAnalytics';

interface AutomationRuleDetailProps {
  automationRule: ExternalIssueAutomationRule;
  getAutomationRules: () => void;
}

function AutomationRuleDetail(props: AutomationRuleDetailProps) {
  const { automationRule, getAutomationRules } = props;
  const {
    name,
    createdAt,
    errorMessagePattern,
    stackTracePattern,
    createdBy
  } = automationRule;
  const [isConfirmDeleteAutomationRuleDialogOpen, setIsConfirmDeleteAutomationRuleDialogOpen] = useState(false);

  const handleOpenConfirmDeleteAutomationRuleDialog = () => {
    setIsConfirmDeleteAutomationRuleDialogOpen(true);
  };

  const handleCloseConfirmDeleteAutomationRuleDialog = () => {
    setIsConfirmDeleteAutomationRuleDialogOpen(false);
  };

  const createErrorHandler = (ruleName: string) => () => {
    Notification.pushError(t('automation-rule#delete-failed-description', { ruleName }), t('automation-rule#delete-failed-title'));
  };

  const handleConfirmDelete = () => {
    const ruleName = automationRule.name;
    const errorHandler = createErrorHandler(ruleName);
    Services.deleteAutomationRule(automationRule.id, errorHandler).then(() => {
      Notification.pushSuccess(t('automation-rule#delete-success-description', { ruleName }), t('automation-rule#delete-success-title'));
      handleCloseConfirmDeleteAutomationRuleDialog();
      getAutomationRules();
      sendAnalyticEventForAction(
        'ra_jira_defect_automation_rule_deleted',
        {
          groupId: GroupEvent.JIRA_DEFECTS,
        },
      );
    });
  };

  const renderConfirmDeleteAutomationRuleDialog = (automationRule: ExternalIssueAutomationRule) => (
    <ConfirmDeleteAutomationRuleDialog
      isOpen={isConfirmDeleteAutomationRuleDialogOpen}
      handleClose={handleCloseConfirmDeleteAutomationRuleDialog}
      handleConfirm={handleConfirmDelete}
      defectId={automationRule.externalIssue.issueId}
      automationRuleName={automationRule.name}
    />
  );

  return (
    <div>
      <div className="automation-rule-detail">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h6" className="mb-1">
            {name}
          </Typography>
          <Button
            aria-label="delete"
            size="large"
            onClick={handleOpenConfirmDeleteAutomationRuleDialog}
          >
            <Icon
              type="fa-solid"
              name="fa-trash-can"
              textSize="14px"
              sx={{
                color: colors.gray9
              }}
            />
          </Button>
        </Stack>
        <Stack
          direction="row"
          spacing={{ xs: 1 }}
          alignItems="center"
          className="mt-1"
          sx={{ height: '0.1rem' }}
        >
          <Typography className="error-message pt-1">
            {t('created-at')} {' '}
            {Time.format(createdAt, Time.DF_DATE_NOT_SS_FORMAT)}, {' '}
            {t('by')}
          </Typography>
          {DecoratorConstants.renderUserWithoutNameDecorator(createdBy)}
        </Stack>
        <div className="mt-3">
          <Typography className="title-pattern mt-1">
            {t('error-message-pattern')}
          </Typography>
          <Typography className="error-message">
            {errorMessagePattern ?? t('not-defined')}
          </Typography>
        </div>
        <div>
          <Typography className="title-pattern mt-1">
            {t('stack-trace-pattern')}
          </Typography>
          <Typography className="error-message">
            {stackTracePattern ?? t('not-defined')}
          </Typography>
        </div>
      </div>
      {renderConfirmDeleteAutomationRuleDialog(automationRule)}
    </div>
  );
}

export default AutomationRuleDetail;
