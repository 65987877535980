import React, { useState, useEffect } from 'react';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import Autocomplete from '@katalon-studio/katalon-ui/Autocomplete';
import TextField from '@katalon-studio/katalon-ui/TextField';
import Chip from '@katalon-studio/katalon-ui/Chip';
import { t } from '../i18n/t';
import { Channel } from '../utils/Constants';
import {
  groupTestCloudAgentsByOsBrowser,
  extractG5Env,
  convertTestCloudEnvironments,
} from './smarttestscheduling/services/testcloud';
import { getTestCloudEnvironments } from '../pages/plan/ConfigurePlanUtils';
import { TestEnvironmentOption } from '../models/model/TestEnvironmentOption';
import { Framework } from '../models/model/Framework';

function TestEnvironmentSelector(props: {
  onSelectEnvironment: (values: TestEnvironmentOption[]) => void,
  frameWork: Framework,
}) {

  const { onSelectEnvironment, frameWork } = props;

  const [selectedEnvironments, setSelectedEnvironments] = useState<TestEnvironmentOption[]>([]);
  const [suggestedEnvironments, setSuggestedEnvironments] = useState<TestEnvironmentOption[]>([]);
  const [isLoading, setLoading] = useState(false);

  const getG4TestCloudEnvironments = async (): Promise<TestEnvironmentOption[]> => {
    const g4TestCloudEnvironments: TestEnvironmentOption[] = [];
    return Promise.resolve(g4TestCloudEnvironments);
  };

  const getG5TestCloudEnvironments = async (): Promise<TestEnvironmentOption[]> => {
    const g5TestCloudEnvironments = await getTestCloudEnvironments(Channel.G5);
    const groupByOsBrowser = {
      raw: g5TestCloudEnvironments,
      data: groupTestCloudAgentsByOsBrowser(g5TestCloudEnvironments)
    };

    return convertTestCloudEnvironments(extractG5Env(groupByOsBrowser));
  };

  const getTestEnvironments = () => {
    setLoading(true);

    let request;
    if (frameWork === Framework.KATALON_STUDIO) {
      request = getG4TestCloudEnvironments();
    } else {
      request = getG5TestCloudEnvironments();
    }
    request
      .then((testCloudEnvironments) => {
        setSuggestedEnvironments(testCloudEnvironments);
        setSelectedEnvironments([testCloudEnvironments[0]]);
        onSelectEnvironment([testCloudEnvironments[0]]);
        setLoading(false);
      });
  };

  useEffect(() => {
    getTestEnvironments();
  }, []);

  const handleSelectChange = (_event: any, values: TestEnvironmentOption[]) => {
    setSelectedEnvironments(values);
    onSelectEnvironment(values);
  };

  const isEmptyList = isEmpty(selectedEnvironments);
  const isError = !isLoading && isEmptyList;

  const renderValue = (option: TestEnvironmentOption) => (
    <div className="d-flex justify-content-between mt-1 mb-1">
      {option.prefixOption}&nbsp;
      {option.icons &&
      <div className="mr-1 d-flex align-items-center">
        {option.icons.map((icon) => (
          <>
            {icon || ''}&nbsp;
          </>
        ))}
      </div>}
      {option.label}
    </div>
  );

  const renderSelectTestEnvironments = () => (
    <Autocomplete
      id="configureTestRun"
      multiple
      value={selectedEnvironments}
      options={suggestedEnvironments}
      onChange={handleSelectChange}
      loading={isLoading}
      size="medium"
      renderInput={(params) => (
        <TextField
          {...params}
          label={t('test-environment')}
          placeholder={isEmptyList ? t('selete-test-environment') : ''}
          error={isError}
          helperText={isError && t('test_case_name#error_empty')}
        />
      )}
      renderOption={(props, option) => (
        <li {...props}>
          {renderValue(option)}
        </li>
      )}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => {
          const label = renderValue(option);
          return (
            <div key={option}>
              <Chip
                variant="filled"
                label={label}
                size="small"
                {...getTagProps({ index })}
              />
            </div>
          );
        })}
    />
  );

  return renderSelectTestEnvironments();
}

TestEnvironmentSelector.prototype = {
  onSelectEnvironment: PropTypes.func.isRequired,
  frameWork: PropTypes.string.isRequired,
};

export default TestEnvironmentSelector;
