import Icon from '@katalon-studio/katalon-ui/Icon';
import React from 'react';
import colors from '../../../scss/colors.scss';

function CloseIcon(props: {
  color: string,
  textSize: string,
}) {

  const { color, textSize } = props;

  return (
    <Icon
      type="fa-solid"
      name="fa-times"
      textSize={textSize}
      sx={{
        color,
        width: textSize,
        height: textSize,
      }}
    />
  );
}

CloseIcon.defaultProps = {
  color: colors.gray17,
  textSize: '14px',
};

export default CloseIcon;
