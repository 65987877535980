import React from 'react';
import { t } from '../../i18n/t';
import TabComponent from '../../components/TabComponent';
import ProjectDataTable from './components/ProjectDataTable';
import { buildSearchCondition } from '../../components/search/SearchUtils';
import { ProjectStatus } from '../../utils/Constants';
import { IconArchiveProject } from '../../images/CustomIcon';

class ActiveProjectsTab extends TabComponent {
  constructor(props) {
    super(props);
    this.meta.id = 'page-active-projects-tab';
    this.meta.title = t('active-projects');
    this.organizationId = props.organizationId;
  }

  renderActiveProjects() {
    const defaultSearchConditions = [
      buildSearchCondition('Organization.id', '=', this.organizationId),
      buildSearchCondition('status', '!=', 0),
    ];

    return (
      <ProjectDataTable
        defaultSearchConditions={defaultSearchConditions}
        status={ProjectStatus.ARCHIVE}
        icon={IconArchiveProject}
        label="Archive"
      />
    );
  }

  render() {
    return this.renderActiveProjects();
  }
}

export default ActiveProjectsTab;
