import React from 'react';
import MTableColumnDataMapping from '../../components/table/models/MTableColumnDataMapping';
import DecoratorConstants from '../../utils/DecoratorConstants';
import MContext from '../../models/MContext';
import DataTable from './DataTable';
import { t } from '../../i18n/t';

class K8SAgentDataTable extends React.Component {

  constructor(props) {
    super(props);
    this.teamId = MContext.teamId;
  }

  refreshData() {
    this.incidentTable.refreshData();
  }

  render() {
    const columnMapping = [
      new MTableColumnDataMapping(
        t('name'),
        'name',
        DecoratorConstants.k8sAgentDetailDecorator,
      ),
      new MTableColumnDataMapping(
        t('url'),
        'url',
      ),
      new MTableColumnDataMapping(
        t('k8s-agent#namespace'),
        'namespace',
      ),
    ];
    const newProps = {
      columnMapping,
      ...this.props,
    };
    return (
      <DataTable
        ref={(ref) => {
          this.incidentTable = ref;
        }}
        entityType="K8SAgent"
        tableId="K8SAgents"
        {...newProps}
      />
    );
  }
}

export default K8SAgentDataTable;
