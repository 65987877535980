import { configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import rootReducer, {
  fromCustomFieldDialog,
  fromCustomFieldDialogWithTypeScript
} from './rootReducer';

export const store = configureStore({
  reducer: rootReducer
});

type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export type AppState = ReturnType<typeof rootReducer>;
export const buildSearchCondition = (key: string, operator: string, value: string | number) => ({ key, operator, value });

export {
  fromCustomFieldDialog,
  fromCustomFieldDialogWithTypeScript
};
