import React from 'react';
import { Col, Row } from 'reactstrap';
import ImageCard from '../ImageCard';
import { t } from '../../i18n/t';
// import FilePreviewer from './FilePreviewer';

const learnMoreLink = (link) => (
  <a href={link} target="_blank" rel="noopener noreferrer">{t('learn_more')}</a>
);

function FeatureHighlight({ features }) {
  // const previewerRef = React.useRef(null);

  return (
    <>
      <Row className="flex-wrap">
        {features.map(({ name, description, source, learnMore, button, index }) => {
          let footer = null;
          if (button) {
            footer = button;
          } else if (learnMore) {
            footer = learnMoreLink(learnMore);
          }
          return (
            <Col key={index} className="d-flex flex-column gallery-item mb-0">
              <ImageCard
                className="h-100"
                // onClickImage={() => previewerRef.current.openLightbox(index)}
                imageUrl={source}
                title={name}
                description={description}
                footer={footer}
              />
            </Col>
          );
        })}
      </Row>
      {/*<FilePreviewer*/}
      {/*  ref={previewerRef}*/}
      {/*  images={features}*/}
      {/*/>*/}
    </>
  );
}

export default FeatureHighlight;
