import React from 'react';
import { Alert } from 'reactstrap';
import MFlags from '../../models/MFlags';
import { t } from '../../i18n/t';

function ExternalConnectionAlert() {
  const jiraDocsLink = 'https://docs.katalon.com/katalon-analytics/docs/kt-jira-issue.html';
  return MFlags.jiraIssueLinkingCreatingEnabled ?
    (
      <Alert color="warning">
        {t('jira-integration-add-on-new')}
        <a href={jiraDocsLink} target="_blank" rel="noopener noreferrer">{t('learn_more')}</a>
      </Alert>
    ) :
    (
      <Alert color="warning">
        {t('jira-integration-add-on-old')}
        <a href={jiraDocsLink} target="_blank" rel="noopener noreferrer">{t('learn_more')}</a>
      </Alert>
    );
}

export default ExternalConnectionAlert;
