import React from 'react';
import StatusBar from '../../components/chart/status-bar/StatusBar';
import DefaultLayout from '../../components/DefaultLayout';
import PageComponent from '../../components/PageComponent';
import { buildSearchCondition, buildSearchFunction } from '../../components/search/SearchUtils';
import ObjectSummary from '../../components/summary/ObjectSummary';
import DataLoader from '../../components/table/DataLoader';
import KsSessionDataTable from '../../components/table/KsSessionDataTable';
import { t } from '../../i18n/t';
import MContext from '../../models/MContext';
import Time from '../../utils/Moment';
import MAuth from '../../models/MAuth';
import Routers from '../../utils/Routes';
import { notificationIds } from '../../utils/NotificationPageUtils';
import Arrays from '../../utils/Arrays';

class OrganizationSession extends PageComponent {
  constructor(props) {
    super(props);
    this.meta.id = 'page-organization-session';
    this.meta.title = t('organization_session');
    this.renderHeader = this.renderHeader.bind(this);
    this.renderBody = this.renderBody.bind(this);
    this.renderSessionSummary = this.renderSessionSummary.bind(this);
    this.organization = MContext.organization;
    this.organizationId = MContext.organizationId;
  }

  renderSessionSummary(data) {
    const features = {};
    let total = 0;
    data.forEach((item) => {
      features[item.content.feature] = item.content.totalDuration;
      total += item.content.totalDuration;
    });
    const quotaMilliseconds = this.organization.paygoQuota * 60 * 60 * 1000;
    const paygoQuotaItem = {
      key: 'paygo-quota',
      label: 'Quota',
      value: quotaMilliseconds,
    };

    const totalItem = {
      key: 'duration-total',
      label: 'Total',
      value: total,
    };

    const counterItems = Object.keys(features).map((key) => ({
      key: `duration-${key}`,
      label: t(key),
      value: features[key],
      color: key.toLowerCase()
    }));

    return (
      <StatusBar
        dataInfo={[...Arrays.insertIf(this.organization.paygoQuota, paygoQuotaItem), totalItem, ...counterItems]}
        dataChart={counterItems}
        valueFormatter={(value) => Time.trimDuration(value, 'h[h] m[m]')}
        max={quotaMilliseconds}
      />
    );
  }

  renderBody() {
    return (
      <>
        <DataLoader
          entityType="KsSession"
          defaultSearchConditions={[
            buildSearchCondition('Organization.id', '=', MContext.organizationId),
          ]}
          defaultSearchFunctions={[
            buildSearchFunction('totalDuration', 'SUM', ['duration']),
          ]}
          groupBys={['feature']}
          defaultSort={['feature,asc']}
          render={this.renderSessionSummary}
          noCard
        />
        <KsSessionDataTable
          defaultSearchConditions={[
            buildSearchCondition('Organization.id', '=', MContext.organizationId),
          ]}
        />
      </>
    );
  }

  renderHeader() {
    const urlParams = {
      organizationSession: t('organization_session'),
    };
    return (
      <ObjectSummary params={urlParams} />
    );
  }

  render() {
    const isOrganizationManager = MAuth.isOrganizationManager(this.organizationId);
    // const canUsePaygoLicenses = this.organization.ksePaygo || this.organization.krePaygo;

    if (!isOrganizationManager) {
      Routers.goToNotificationPage(notificationIds.NO_PERMISSION);
    }

    return (
      <DefaultLayout
        renderBody={this.renderBody}
        renderHeader={this.renderHeader}
      />
    );
  }
}

export default OrganizationSession;
