import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Tooltip from '../../../components/TooltipComponent';
import { CONTENT_ZONE_TYPE, IMAGE_TYPE, JSON_ZONE_TYPE } from './Constants';
import { checkZone, drawRect, findMinZone, getArea, fillRect } from './Utils';

import colors from '../../../../scss/colors.scss';

class DiffCanvasTemplate extends PureComponent {
  constructor(props) {
    super(props);
    this.canvasRef = React.createRef();
    this.state = {
      cvMouseX: 0,
      cvMouseY: 0,
      ratio: 1,
    };
    this.drawRects = this.drawRects.bind(this);
    this.handleMouseout = this.handleMouseout.bind(this);
    this.handleMouseMove = this.handleMouseMove.bind(this);
    this.area = this.area.bind(this);
  }

  componentDidMount() {
    this.updateCanvas();
  }

  componentDidUpdate() {
    this.updateCanvas();
  }

  area(zone) {
    if (this.props.type === IMAGE_TYPE.BASELINE) {
      return getArea(zone.bl_coor);
    } else {
      return getArea(zone.cp_coor);
    }
  }

  getZoneHover() {
    const { cvMouseX, cvMouseY, ratio } = this.state;
    const hoverZoneList = this.props.zoneList.filter((zone) => {
      if (this.props.type === IMAGE_TYPE.BASELINE) {
        if (zone.bl_coor) {
          return checkZone(zone.bl_coor, ratio, cvMouseX, cvMouseY);
        }
        return false;
      } else {
        if (zone.cp_coor) {
          return checkZone(zone.cp_coor, ratio, cvMouseX, cvMouseY);
        }
        return false;
      }
    });
    return findMinZone(hoverZoneList, this.props.zoneList, this.area);
  }

  drawHighlightRects(ctx, coor, index, color, ratio) {
    if (index === parseInt(this.props.zoneHover.index, 10)) {
      ctx.beginPath();
      ctx.strokeStyle = color;
      ctx.lineWidth = 10;
      ctx.globalAlpha = 0.5;
      ctx.rect(coor.x * ratio, coor.y * ratio, coor.w * ratio, coor.h * ratio);
      ctx.stroke();
      ctx.globalAlpha = 1;
    }
  }

  drawRects(item, ctx, imgType, ratio, index) {
    const { type, bl_coor, cp_coor } = item;
    const { identicalZone, shiftedZone, missingNewZone } = CONTENT_ZONE_TYPE;
    const { correctMatch, diffMatch, templateMatch, nonMatch } = JSON_ZONE_TYPE;
    const { BASELINE, CHECKPOINT } = IMAGE_TYPE;
    const { matchZoneColor, diffPartZoneColor, diffZoneColor, shiftedZoneColor } = colors;
    const coor =
      imgType === BASELINE ? bl_coor : cp_coor;
    if (type === identicalZone || type === correctMatch) {
      const label = type === BASELINE ? item.bl_text : item.cp_text;
      drawRect(ctx, coor, ratio, matchZoneColor, label);
      this.drawHighlightRects(ctx, coor, index, matchZoneColor, ratio);
    } else if (type === shiftedZone) {
      const label = type === BASELINE ? item.bl_text : item.cp_text;
      drawRect(ctx, coor, ratio, shiftedZoneColor, label);
      this.drawHighlightRects(ctx, coor, index, shiftedZoneColor, ratio);
    } else if (type === diffMatch || type === templateMatch) {
      const label = type === BASELINE ? item.bl_text : item.cp_text;
      drawRect(ctx, coor, ratio, diffPartZoneColor, label);
      this.drawHighlightRects(ctx, coor, index, diffPartZoneColor, ratio);
    } else {

      if ((type === missingNewZone || type === nonMatch) && coor) {
        if (imgType === BASELINE) {
          const coor = item.bl_coor;
          const label = item.bl_text;
          drawRect(ctx, coor, ratio, colors.diffZoneColor, label);
          this.drawHighlightRects(ctx, coor, index, diffZoneColor, ratio);
        } else if (imgType === CHECKPOINT) {
          const coor = item.cp_coor;
          const label = item.cp_text;
          drawRect(ctx, coor, ratio, colors.diffZoneColor, label);
          this.drawHighlightRects(ctx, coor, index, diffZoneColor, ratio);
        }
      }
    }
  }

  updateCanvas() {
    const { borderIgnoringZonesColor, ignoringZonesColor } = colors;
    const { source, type, setLoading, loadingState } = this.props;
    const ctx = this.canvasRef.current.getContext('2d');
    const image = new Image();
    image.src = source;
    image.onload = () => {
      const canvas = ctx.canvas;
      const ratio = canvas.width / image.width;
      this.setState({ ratio });
      canvas.height = image.height * ratio;
      this.props.zoneList.forEach((item, index) =>
        this.drawRects(item, ctx, type, ratio, index));
      this.props.ignoringZoneList.forEach((item) =>
        fillRect(ctx, item, ratio, borderIgnoringZonesColor, ignoringZonesColor));
      if (setLoading) setLoading(loadingState, false);
    };
  }

  handleMouseMove(x, y) {
    const { setHover, type } = this.props;
    const ctx = this.canvasRef.current.getContext('2d');
    const { cvMouseX, cvMouseY } = this.getMousePos(ctx.canvas, x, y);
    this.setState({
      cvMouseX,
      cvMouseY,
    });
    const zoneHover = this.getZoneHover();
    setHover(zoneHover, type);
  }

  getMousePos(canvas, x, y) {
    const rect = canvas.getBoundingClientRect();
    return {
      cvMouseX: x - rect.left,
      cvMouseY: y - rect.top,
    };
  }

  handleMouseout() {
    const { setHover, type } = this.props;
    setHover(-1, type);
  }

  renderCanvasOfPixel() {
    const { ...rest } = this.props;
    return (
      <canvas
        onBlur
        ref={this.canvasRef}
        {...rest}
      />);
  }

  render() {
    const { isPixelBased } = this.props;
    if (isPixelBased) return this.renderCanvasOfPixel();

    const { source, zoneHover, type, zoneList, ...rest } = this.props;
    const label = zoneHover.index !== -1 ? ((type === IMAGE_TYPE.BASELINE ? zoneList[zoneHover.index].bl_text : zoneList[zoneHover.index].cp_text) || `Zone ${zoneHover.index}`) : '';
    const open = zoneHover.index >= 0 && zoneHover.type === type;
    return (
      <>
        <Tooltip
          open={open}
          text={`${label}`}
          followCursor
        >
          <canvas
            className="canvas-test"
            title={this.state.title}
            onMouseMove={(e) => this.handleMouseMove(e.clientX, e.clientY)}
            onMouseOut={this.handleMouseout}
            onBlur
            ref={this.canvasRef}
            {...rest}
          />
        </Tooltip>
      </>
    );
  }
}


DiffCanvasTemplate.propsTypes = {
  zoneHover: PropTypes.object.isRequired,
  source: PropTypes.string,
  type: PropTypes.string.isRequired,
  zoneList: PropTypes.array.isRequired,
  isPixelBased: PropTypes.bool,
};

DiffCanvasTemplate.defaultProps = {
  isPixelBased: false,
};

export default DiffCanvasTemplate;
