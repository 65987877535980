import { useEffect, useState } from 'react';
import Services from '../utils/Services';

function getConnection(projectId) {
  const params = {
    pagination: {
      page: 0,
      size: 1,
      sorts: 'order,desc',
    },
    conditions: [
      {
        key: 'Project.id',
        operator: '=',
        value: projectId,
      },
    ],
    type: 'ExternalConnection',
  };
  return Services.search(params)
    .then(({ content }) => content[0]);
}

export function useExternalConnection(projectId) {
  const [connection, setConnection] = useState(null);

  useEffect(() => {
    getConnection(projectId)
      .then((connection) => {
        setConnection(connection);
      });
  }, []);

  return connection;
}
