import React from 'react';
import PageComponent from '../components/PageComponent';
import ObjectSummary from '../components/summary/ObjectSummary';
import DefaultLayout from '../components/DefaultLayout';
import { t } from '../i18n/t';
import ImportJUnitReports from '../components/ImportJUnitReports';
import ImportKatalonReports from '../components/ImportKatalonReports';
import Services from '../utils/Services';
import MContext from '../models/MContext';

class UploadReports extends PageComponent {
  constructor(props) {
    super(props);
    this.renderHeader = this.renderHeader.bind(this);
    this.renderBody = this.renderBody.bind(this);

    this.state = {
      canTriggerTestExecution: true,
      isLoading: true
    };

    this.meta.id = 'page-import-manual-reports';
    this.meta.title = 'Import Manual Reports';
  }

  componentDidMount() {
    this.getOrganizationTestExecutionQuota();
  }

  getOrganizationTestExecutionQuota() {
    const { targetOrganization, isTestOpsSubscription } = MContext;

    if (!targetOrganization || isTestOpsSubscription) {
      this.setState({ isLoading: false });
    } else {
      Services.getOrganizationTestExecutionQuota(targetOrganization.id)
        .then(({ canTriggerTestExecution }) => {
          this.setState({
            canTriggerTestExecution,
            isLoading: false
          });
        });
    }
  }

  renderObjectSummary() {
    const urlParams = {
      importManualReports: t('import-manual-reports'),
    };
    return <ObjectSummary params={urlParams} />;
  }

  renderHeader() {
    return <>{this.renderObjectSummary()}</>;
  }

  renderBody() {
    const { canTriggerTestExecution } = this.state;

    return (
      <>
        <ImportJUnitReports canTriggerTestExecution={canTriggerTestExecution} />
        <ImportKatalonReports canTriggerTestExecution={canTriggerTestExecution} />
      </>
    );
  }

  render() {
    const { isLoading } = this.state;

    if (isLoading) {
      return null;
    }

    return (
      <DefaultLayout
        renderHeader={this.renderHeader}
        renderBody={this.renderBody}
      />
    );
  }
}
export default UploadReports;
