import React from 'react';
import { Button, Form, FormGroup, Label, FormText, Row, Col, Card, CardBody, CustomInput } from 'reactstrap';
import Input from '../../components/Input';
import PageComponent from '../../components/PageComponent';
import ObjectSummary from '../../components/summary/ObjectSummary';
import { t } from '../../i18n/t';
import MContext from '../../models/MContext';
import Apis from '../../utils/Apis';
import http from '../../utils/http';
import Notification from '../../utils/Notification';
import Services from '../../utils/Services';
import TestProjectUploadField from './components/TestProjectUploadField';
import Routers from '../../utils/Routes';
import DefaultLayout from '../../components/DefaultLayout';
import ConfirmationTestProjectDialog from '../../components/dialog/ConfirmationTestProjectDialog';
import TooltipComponent from '../../components/TooltipComponent';
import MFlags from '../../models/MFlags';
import { TestProjectType, ACTIONS_TRACKING } from '../../utils/Constants';
import ZipRepositoryIcon from '../../../images/icons/katalonui/ZipRepositoryIcon';
import colors from '../../../scss/colors.scss';
import { sendAnalyticEventForAction } from '../../utils/SegmentAnalytics';

class TestProject extends PageComponent {

  constructor(props) {
    super(props);
    this.teamId = MContext.teamId;
    this.projectId = MContext.projectId;
    this.team = MContext.team;
    this.project = MContext.project;
    this.testProjectId = MContext.testProjectId;
    this.uploadField = null;
    this.state = this.getInitialState();
    this.isCreateTestProject = !this.testProjectId;

    if (this.isCreateTestProject) {
      this.meta.id = 'page-create-test-project';
      this.meta.title = t('test-project');
    } else {
      this.meta.id = 'page-update-test-project';
    }

    this.handleTestProjectNameChange = this.handleTestProjectNameChange.bind(this);
    this.handleTestProjectDescriptionChange = this.handleTestProjectDescriptionChange.bind(this);
    this.updateTestProject = this.updateTestProject.bind(this);
    this.createTestProject = this.createTestProject.bind(this);
    this.disableInput = this.disableInput.bind(this);
    this.enableInput = this.enableInput.bind(this);
    this.onUploadProgressHook = this.onUploadProgressHook.bind(this);
    this.onUploadFinishHook = this.onUploadFinishHook.bind(this);
    this.onUploadErrorHook = this.onUploadErrorHook.bind(this);
    this.renderUpdateTestProjectInfo = this.renderUpdateTestProjectInfo.bind(this);
    this.renderHeader = this.renderHeader.bind(this);
    this.toggleSetDefaultRepository = this.toggleSetDefaultRepository.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSaveTestProject = this.handleSaveTestProject.bind(this);
    this.handleOpenConfirmationTestProjectDialog = this.handleOpenConfirmationTestProjectDialog.bind(this);
    this.handleCloseConfirmationTestProjectDialog = this.handleCloseConfirmationTestProjectDialog.bind(this);
    this.handleNoConfirmationTestProjectDialog = this.handleNoConfirmationTestProjectDialog.bind(this);
    this.handleConfirmationSetDefaultRepository = this.handleConfirmationSetDefaultRepository.bind(this);
  }

  getInitialState() {
    return {
      testProject: null,
      testProjectName: '',
      testProjectDescription: '',
      isDefaultTestProject: false,
      disabledInput: '',
      isOpenConfirmationTestProjectDialog: false,
      defaultTestProjectId: null,
      isZipRepositorySelected: false,
    };
  }

  getTeam() {
    const team = MContext.team;
    this.setState({ team });
  }

  getTestProject() {
    http.get(Apis.testProject(this.testProjectId))
      .then((testProject) => {
        this.meta.title = t('Script Repository {{name}}', { name: testProject.name });
        this.setState({
          testProject,
          testProjectName: testProject.name,
          testProjectDescription: testProject.description,
        });
      });
  }

  checkTestProjectIsDefault() {
    const params = { projectId: this.projectId };
    Services.getDefaultTestProject(params)
      .then((testProject) => {
        if (testProject.id) {
          this.setState({ defaultTestProjectId: testProject.id });
          if (this.testProjectId) {
            const isDefaultTestProject = testProject.id === Number(this.testProjectId);
            this.setState({ isDefaultTestProject });
          }
        }
      });
  }

  componentDidMount() {
    this.getTeam();
    if (this.testProjectId) {
      this.getTestProject();
    }
    this.checkTestProjectIsDefault();
  }

  updateTestProject() {
    const {
      testProject,
      testProjectName,
      testProjectDescription,
      isDefaultTestProject,
    } = this.state;
    const { zipScriptRepoDisabled } = MFlags;
    if (!testProject) {
      return;
    }

    this.disableInput();

    const testProjectData = {
      teamId: this.teamId,
      projectId: this.projectId,
      name: testProjectName,
      description: testProjectDescription,
      defaultTestProject: isDefaultTestProject,
    };

    if (!zipScriptRepoDisabled) {
      this.uploadField.handleUpload();
    }

    Services.updateTestProject(this.testProjectId, testProjectData)
      .then((updatedTestProject) => {
        Notification.pushSuccess(`Script Repository ${testProjectData.name} was updated.`);
        this.testProject = updatedTestProject;
        this.setState({ testProject: updatedTestProject });
        this.enableInput();
      });
  }

  createTestProject() {
    this.disableInput();
    this.uploadField.handleUpload();
    this.enableInput();
  }

  handleSaveTestProject() {
    if (this.testProjectId) {
      this.updateTestProject();
    } else {
      this.createTestProject();
    }
  }

  handleSubmit(e) {
    e.preventDefault();
    const { isDefaultTestProject, defaultTestProjectId } = this.state;
    // Check if editing script repository is set to default then no need to confirm.
    const isEditDefaultTestProject = this.testProjectId && Number(this.testProjectId) === defaultTestProjectId;
    // Check need show dialog confirm
    const needConfirmDefaultTestProject = isDefaultTestProject && defaultTestProjectId && !isEditDefaultTestProject;

    if (needConfirmDefaultTestProject) {
      this.handleOpenConfirmationTestProjectDialog();
    } else {
      this.handleSaveTestProject();
    }
  }

  handleTestProjectNameChange(event) {
    const testProjectName = event.target.value;
    this.setState({ testProjectName });
  }

  handleTestProjectDescriptionChange(event) {
    const testProjectDescription = event.target.value;
    this.setState({ testProjectDescription });
  }

  disableInput() {
    this.setState({ disabledInput: 'disabled' });
  }

  enableInput() {
    this.setState({ disabledInput: '' });
  }

  onUploadProgressHook() {
    this.disableInput();
  }

  onUploadFinishHook(testProject) {
    const route = new Routers({
      teamId: this.teamId,
      projectId: this.projectId,
      testProjectId: testProject.id,
    });
    this.props.history.push(route.test_project_link);
  }

  onUploadErrorHook() {
    this.enableInput();
  }

  toggleSetDefaultRepository(event) {
    const { checked } = event.target;
    this.setState({ isDefaultTestProject: checked });
  }

  handleOpenConfirmationTestProjectDialog() {
    this.setState({ isOpenConfirmationTestProjectDialog: true });
  }

  handleCloseConfirmationTestProjectDialog() {
    this.setState({
      isOpenConfirmationTestProjectDialog: false,
      isDefaultTestProject: false,
    });
  }

  handleNoConfirmationTestProjectDialog() {
    this.setState({
      isOpenConfirmationTestProjectDialog: false,
      isDefaultTestProject: false,
    }, this.handleSaveTestProject);
  }

  handleConfirmationSetDefaultRepository() {
    this.setState({ isOpenConfirmationTestProjectDialog: false });
    this.handleSaveTestProject();
  }

  handleChange(event) {
    const { preventExceedZipFileEnabled } = MFlags;

    const kiloBytes = 1024; // 1 Kilobyte is 1024 bytes
    const megaBytes = kiloBytes * kiloBytes; // 1 MB is 1024 KB
    const limitSize = 1000; // MB
    const maxFileSizeByBytes = megaBytes * limitSize;

    const files = event.target.files;
    const file = files[0];
    let importStatus;

    if (preventExceedZipFileEnabled && file.size > maxFileSizeByBytes) {
      importStatus = 'failed';
      Notification.pushError(t('test-project#exceed-limit-size', { fileName: file.name }));
      document.getElementById('upload-file').value = null;
      this.setState({ isZipRepositorySelected: false });
    } else {
      importStatus = 'successful';
      this.setState({ isZipRepositorySelected: files.length > 0 });
    }

    sendAnalyticEventForAction(
      ACTIONS_TRACKING.SCRIPT_REPO_SELECTED, 
      {
        import_status: importStatus,
        file_size_in_mb: (file.size / megaBytes).toFixed(3),
      }
    );
  }

  renderUpdateTestProjectInfo() {
    const {
      disabledInput,
      testProjectName,
      testProjectDescription,
      isDefaultTestProject,
      isOpenConfirmationTestProjectDialog,
      isZipRepositorySelected
    } = this.state;
    const { zipScriptRepoDisabled } = MFlags;
    const saveBtnDisabled = !testProjectName || (!this.testProjectId && !isZipRepositorySelected);
    const saveBtnLabel = this.testProjectId ? t('test-project#update') : t('import');
    return (
      <Card>
        <CardBody>
          <Row>
            <Col sm="12" md="8" lg="6" xl="5">
              <Form
                data-trackid={this.isCreateTestProject ? 'create-test-project' : 'update-test-project'}
                onSubmit={this.handleSubmit}
              >
                <FormGroup>
                  <Label for="name">{t('name')}<Label style={{ color: colors.red7 }}>&nbsp;*</Label></Label>
                  <Input
                    id="name"
                    name="name"
                    disabled={disabledInput}
                    value={testProjectName}
                    onChange={this.handleTestProjectNameChange}
                    type="text"
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="description">{t('test-project#description')}</Label>
                  <Input
                    id="description"
                    name="description"
                    disabled={disabledInput}
                    value={testProjectDescription}
                    onChange={this.handleTestProjectDescriptionChange}
                    type="textarea"
                    rows="5"
                  />
                </FormGroup>
                <div className="d-flex">
                  <CustomInput
                    id="default-repository"
                    type="switch"
                    name="default-repository"
                    checked={isDefaultTestProject}
                    onChange={this.toggleSetDefaultRepository}
                    label={t('test-project#default-repository')}
                    className="normal-label mb-2"
                    data-trackid="default-repository"
                    disabled={disabledInput}
                  />
                  <TooltipComponent text={t('test-project#tooltip-default-repository')} />
                </div>
                {
                  !zipScriptRepoDisabled &&
                  <FormGroup>
                    <Label for="upload">
                      {t('test-project#upload')}
                      <Label style={{ color: colors.red7 }}>&nbsp;*</Label>
                    </Label>
                    <TestProjectUploadField
                      onChange={(event) => this.handleChange(event)}
                      onUploadProgressHook={this.onUploadProgressHook}
                      onUploadFinishHook={this.onUploadFinishHook}
                      onUploadErrorHook={this.onUploadErrorHook}
                      testProjectName={testProjectName}
                      testProjectDescription={testProjectDescription}
                      isDefaultTestProject={isDefaultTestProject}
                      ref={(uploadField) => { this.uploadField = uploadField; }}
                      disabled={disabledInput}
                    />
                    <FormText color="muted">{t('test-project#upload-hint')}
                      <a href="https://github.com/katalon-studio-samples/ci-samples/archive/master.zip">
                        {'ci-sample-master.zip'}
                      </a>
                    </FormText>
                  </FormGroup>
                }
                <Button
                  type="submit"
                  color="primary"
                  disabled={disabledInput || saveBtnDisabled}
                  data-trackid="to_script_repository_imported"
                >
                  {saveBtnLabel}
                </Button>
              </Form>
            </Col>
            <ConfirmationTestProjectDialog
              id="confirmation-test-project-dialog"
              isOpen={isOpenConfirmationTestProjectDialog}
              handleClose={this.handleCloseConfirmationTestProjectDialog}
              handleNo={this.handleNoConfirmationTestProjectDialog}
              handleConfirmation={this.handleConfirmationSetDefaultRepository}
            />
          </Row>
        </CardBody>
      </Card>
    );
  }

  renderObjectSummary() {
    let urlParams;
    const { testProject } = this.state;
    if (this.testProjectId) {
      urlParams = {
        testProjectId: this.testProjectId,
        testProjectName: testProject.name,
      };
    } else {
      urlParams = {
        codeRepo: t('test-projects'),
      };
    }
    urlParams = {
      ...urlParams,
      projectId: this.projectId,
      project: this.project.name,
      icon: <ZipRepositoryIcon />
    };
    return (
      <ObjectSummary params={urlParams} />
    );
  }

  renderHeader() {
    return (
      <>
        {this.renderObjectSummary()}
      </>
    );
  }

  render() {
    const { team, testProject } = this.state;
    const { onCloudTestStorageEnabled } = MFlags;

    if (!team) return <div>No team</div>;

    if (this.testProjectId && !testProject) {
      return null;
    }

    if (onCloudTestStorageEnabled && this.testProjectId && testProject && testProject.type === TestProjectType.CLOUD) {
      return Routers.goToAccessDeniedPage();
    }

    return (
      <DefaultLayout
        renderHeader={this.renderHeader}
        renderBody={this.renderUpdateTestProjectInfo}
      />
    );
  }
}

export default TestProject;
