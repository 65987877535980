import React from 'react';
import {
	Form, FormGroup, Card, CardBody, CardHeader, Button, Input, Label, CustomInput, Row, Col, FormText
} from 'reactstrap';
import PageComponent from '../../components/PageComponent';
import ObjectSummary from '../../components/summary/ObjectSummary';
import MContext from '../../models/MContext';
import EditOrganization from './EditOrganization';
import EditOrganizationDomain from './EditOrganizationDomain';
import { t } from '../../i18n/t';
import DefaultLayout from '../../components/DefaultLayout';
import DeleteOrganizationDialog from '../../components/dialog/DeleteOrganizationDialog';
import SwitchStrictDomainDialog, { enableStrictDomainWarning, disableStrictDomainWarning }
  from '../../components/dialog/SwitchStrictDomainDialog';
import Routes from '../../utils/Routes';
import Notification from '../../utils/Notification';
import MAuth from '../../models/MAuth';
import { notificationIds } from '../../utils/NotificationPageUtils';
import Services from '../../utils/Services';
import { buildSearchCondition } from '../../components/search/SearchUtils';
import MConfigs from '../../models/MConfigs';
import DocumentLink from '../../utils/DocumentLink';
import { OrganizationConfigurationService } from '../../utils/ConfigurationServices';

class OrganizationSettings extends PageComponent {
  constructor(props) {
    super(props);
    this.meta.id = 'page-organization-settings';
    this.meta.title = t('settings-organization-management');
    this.organizationId = MContext.organizationId;
    this.serverUrl = MConfigs.serverUrl;

    const organization = MContext.organization;
    const { domain, strictDomain } = organization;

    this.state = {
      organization: MContext.organization,
      sso: false,
      metadata: '',
      strictDomain,
      domain,
      advancedFeatures: false,
      enableTestOpsIntegration: true,
      sessionTimeOut: '',
    };

    this.deleteOrganizationDialog = React.createRef();
    this.switchStrictDomainDialogRef = React.createRef();

    this.renderHeader = this.renderHeader.bind(this);
    this.renderBody = this.renderBody.bind(this);
    this.deleteOrganization = this.deleteOrganization.bind(this);
    this.afterRemovedOrganization = this.afterRemovedOrganization.bind(this);
    this.handlerErrorMessage = this.handlerErrorMessage.bind(this);
    this.handleTextChange = this.handleTextChange.bind(this);
    this.updateIpList = this.updateIpList.bind(this);
    this.renderSSO = this.renderSSO.bind(this);
    this.updateSSO = this.updateSSO.bind(this);
    this.changeSSO = this.changeSSO.bind(this);
    this.onChangeCheckbox = this.onChangeCheckbox.bind(this);
    this.openSwitchStrictDomainDialog = this.openSwitchStrictDomainDialog.bind(this);
    this.saveConfigure = this.saveConfigure.bind(this);
    this.saveSessionTimeOut = this.saveSessionTimeOut.bind(this);
  }

  componentDidMount() {
    Services.search({
      pagination: {
        page: 0,
        size: 1000000,
        sorts: ['id,asc'],
      },
      conditions: [buildSearchCondition('Organization.id', '=', this.organizationId)],
      type: 'WhitelistIp',
    }).then(({ content }) => {
      const whitelistIps = content.map((i) => i.ip).join(', ');
      this.setState({ whitelistIps });
    });

    Services.getSSO(this.organizationId)
      .then(({ sso, metadata }) => {
        this.setState({ sso, metadata });
      });
    this.getTestOpsIntegration();
    this.getOrgSessionTimeOut();
  }

  getTestOpsIntegration() {
    OrganizationConfigurationService.getTestOpsIntegration(this.organizationId).then(({ value }) => {
      if (value) {
        const isEnable = (value === 'true');
        this.setState({
          enableTestOpsIntegration: isEnable
        });
      }
    });
  }

  getOrgSessionTimeOut() {
    OrganizationConfigurationService.getOrgSessionTimeOut(this.organizationId).then(({ value }) => {
      if (value) {
        this.setState({
          sessionTimeOut: value
        });
      }
    });
  }

  deleteOrganization(event) {
    event.preventDefault();
    this.deleteOrganizationDialog.current.toggle();
  }

  afterRemovedOrganization() {
    Routes.goToHomePage();
  }

  handlerErrorMessage(message) {
    const formMessage = 'You still have active subscriptions. Please cancel all subscriptions before deleting this organization.';
    if (message === formMessage) {
      const { organization } = this.state;
      const route = new Routes({
        organizationId: organization.id,
      });
      const editKSSubsUrl = route.organization_subscription_edit;
      const testOpsSubsUrl = route.organization_testops_subscription;
      const messageS = `You still have active subscriptions. Please cancel <a href="${editKSSubsUrl}">Studio Subscriptions</a> and 
        <a href="${testOpsSubsUrl}">TestOps Subscriptions</a> before deleting this organization.`;
      Notification.pushError(messageS);
    } else {
      Notification.pushError(message);
    }
  }

  renderObjectSummary() {
    const urlParams = {
      organization_settings: t('settings-organization-management'),
    };
    return (
      <ObjectSummary params={urlParams} />
    );
  }

  renderHeader() {
    return (
      <>
        {this.renderObjectSummary()}
      </>
    );
  }

  handleTextChange(event) {
    const value = event.target.value;
    const name = event.target.name;
    this.setState({ [name]: value });
  }

  updateIpList(e) {
    e.preventDefault();
    const { whitelistIps } = this.state;
    Services.updateWhitelistIp(this.organizationId, whitelistIps)
      .then(() => {
        Notification.pushSuccess('Whitelist IP was updated.');
      });
  }

  renderIpRestrict() {
    const { whitelistIps } = this.state;
    return (
      <>
        <Card>
          <CardHeader>
            IP Address Restrictions
          </CardHeader>
          <CardBody>
            <Form data-trackid="white-list-organization" onSubmit={this.updateIpList}>
              <FormGroup>
                <p>These settings can prevent unauthorized access from external sources.</p>
              </FormGroup>
              <FormGroup>
                <Input
                  id="whitelist-ip"
                  value={whitelistIps}
                  name="whitelistIps"
                  onChange={this.handleTextChange}
                  type="textarea"
                  placeholder="Enter valid comma-separated IP addresses (E.g: 192.168.1.1,195.138.124.1,1.154.127.1) or
                  IP address ranges (e.g.: 10.199.24.211/220)."
                  rows="5"
                />
              </FormGroup>
              <FormGroup>
                <Button type="submit" color="primary">
                  Update
                </Button>
              </FormGroup>
            </Form>
          </CardBody>
        </Card>
      </>
    );
  }

  changeSSO(event) {
    const sso = event.target.checked;
    this.setState({ sso });
  }

  updateSSO(e) {
    e.preventDefault();
    const { sso, metadata } = this.state;
    Services.updateSSO(this.organizationId, sso, metadata)
      .then((content) => {
        this.setState(content);
        Notification.pushSuccess('SSO was updated.');
      });
  }

  renderSSO() {
    const { sso, metadata } = this.state;
    return (
      <>
        <Card>
          <CardHeader>
            {t('sso')}
          </CardHeader>
          <CardBody>
            <Form data-trackid="sso-organization" onSubmit={this.updateSSO}>
              <FormGroup>
                <p>Learn how to configure SSO <a href={DocumentLink.SSO_SETTINGS} target="_blank" rel="noopener noreferrer">here</a>.</p>
              </FormGroup>
              <FormGroup>
                <Label for="sso">{t('sso#enable')}</Label>
                <CustomInput
                  id="sso"
                  type="switch"
                  name="sso"
                  checked={sso}
                  onChange={this.changeSSO}
                  label="active"
                />
              </FormGroup>
              <FormGroup>
                <Label for="metadata">{t('sso#metadata')}</Label>
                <Input
                  id="metadata"
                  value={metadata}
                  name="metadata"
                  onChange={this.handleTextChange}
                  type="textarea"
                  rows="10"
                  required={sso}
                  disabled={!sso}
                />
              </FormGroup>
              <FormGroup>
                <Button type="submit" color="primary">
                  Update
                </Button>
              </FormGroup>
            </Form>
          </CardBody>
        </Card>
      </>
    );
  }

  onChangeCheckbox(event) {
    const name = event.target.name;
    const value = event.target.checked;
    this.setState({ [name]: value });
  }

  openSwitchStrictDomainDialog() {
    this.switchStrictDomainDialogRef.current.toggle();
  }

  renderStrictDomain() {
    const { strictDomain } = this.state;
    return (
      <>
        <Form>
          <FormGroup>
            <Label for="strictDomain">{t('strict-domain')}</Label>
            {strictDomain
              ? disableStrictDomainWarning(this.serverUrl)
              : enableStrictDomainWarning(this.serverUrl)}
          </FormGroup>
          <FormGroup>
            <Button color="danger" onClick={this.openSwitchStrictDomainDialog}>
              {strictDomain ? t('strict-domain#disable-btn') : t('strict-domain#enable-btn')}
            </Button>
          </FormGroup>
        </Form>
        <SwitchStrictDomainDialog
          ref={this.switchStrictDomainDialogRef}
          currentStrictDomain={strictDomain}
          afterUpdate={(strictDomain) => this.setState({ strictDomain })}
        />
      </>
    );
  }

  saveConfigure(e) {
    e.preventDefault();
    const { enableTestOpsIntegration } = this.state;

    OrganizationConfigurationService.updateTestOpsIntegration(this.organizationId, enableTestOpsIntegration)
      .then(() => {
        Notification.pushSuccess(t('Your setting has been saved successfully.'));
      });
  }

  renderConfiguration() {
    const { enableTestOpsIntegration } = this.state;
    return (
      <>
        <Card>
          <CardHeader>
            {t('katalon-studio-integration')}
          </CardHeader>
          <CardBody>
            <Form
              data-trackid="update-organization-configuration"
              onSubmit={this.saveConfigure}
            >
              <FormGroup>
                <FormText color="muted">
                  {t('organization-configuration-integration-description')}
                </FormText>
                <CustomInput
                  id="enableTestOpsIntegration"
                  name="enableTestOpsIntegration"
                  onChange={this.onChangeCheckbox}
                  type="switch"
                  checked={enableTestOpsIntegration}
                  label={t('organization-configuration-integration-label')}
                  value={enableTestOpsIntegration}
                  className="normal-label"
                />
              </FormGroup>
              <FormGroup>
                <Button type="submit" color="primary">{t('update')}</Button>
              </FormGroup>
            </Form>
          </CardBody>
        </Card>
      </>
    );
  }


  saveSessionTimeOut(e) {
    e.preventDefault();
    let { sessionTimeOut } = this.state;
    let parseInt = Number(sessionTimeOut);

    if (parseInt === 0) {
      parseInt = null;
    }

    OrganizationConfigurationService.updateOrgSessionTimeOut(this.organizationId, parseInt)
      .then(() => {
        Notification.pushSuccess(t('Your setting has been saved successfully.'));
      });
  }

  renderSessionTimeout() {
    const { sessionTimeOut } = this.state;
    const symbolsArr = ["e", "E", "+", "-", "."];

    return (
      <Card>
        <CardHeader>
          {t('organization_session_timeout_header')}
        </CardHeader>
        <CardBody>
          <Row>
            <Col sm="12" md="8" lg="8" xl="5">
              <Form data-trackid="edit-session-timeout" onSubmit={this.saveSessionTimeOut}>
                <FormGroup>
                  <FormText color="muted">
                    {t('organization_session_timeout_description')}
                  </FormText>
                  <Label for="sessionTimeOut">{t('organization_session_timeout_title')}</Label>
                  <Input
                    id="sessionTimeOut"
                    name="sessionTimeOut"
                    value={sessionTimeOut}
                    onChange={this.handleTextChange}
                    type="number"
                    min={0}
                    onKeyDown={e => symbolsArr.includes(e.key) && e.preventDefault()}
                  />
                </FormGroup>
                <FormGroup>
                  <Button type="submit" color="primary">
                    {t('organization_update_session_timeout')}
                  </Button>
                </FormGroup>
              </Form>
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  }

  renderDangerZone() {

    const { id } = MContext.organization;
    const isOrganizationManager = MAuth.isOrganizationManager(id);
    const isOrganizationOwner = MAuth.isOrganizationOwner(id);

    const deleteOrg = (
      <Form data-trackid="delete-organization">
        <FormGroup>
          <Label for="orgName">Delete This Organization</Label>
          <p>Once deleted, it will be gone forever. Please be certain.</p>
        </FormGroup>
        <FormGroup>
          <Button type="submit" color="danger" onClick={this.deleteOrganization}>
            {t('organization-settings#delete-btn')}
          </Button>
        </FormGroup>
      </Form>
    );
    return (
      <>
        <Card>
          <CardHeader>
            Danger zone
          </CardHeader>
          <CardBody>
            {MContext.isSubDomainFeatureEnabled && isOrganizationManager && this.renderStrictDomain()}
            {isOrganizationOwner && deleteOrg}
          </CardBody>
        </Card>
        <DeleteOrganizationDialog
          ref={this.deleteOrganizationDialog}
          afterRemoval={this.afterRemovedOrganization}
          errorHandler={this.handlerErrorMessage}
        />
      </>
    );
  }

  renderBody() {
    const { id } = MContext.organization;
    const isOrganizationManager = MAuth.isOrganizationManager(id);

    return (
      <>
        <EditOrganization />
        {MContext.isSubDomainFeatureEnabled && <EditOrganizationDomain />}
        {MContext.isSubDomainFeatureEnabled && MContext.isSSOFeatureEnabled && this.renderSSO()}
        {MContext.isWhitelistIpFeatureEnabled && this.renderIpRestrict()}
        {this.renderSessionTimeout()}
        {MContext.isTestOpsIntegrationEnabled && this.renderConfiguration()}
        {isOrganizationManager && this.renderDangerZone()}
      </>
    );
  }

  render() {
    const { organization } = MContext;
    if (organization) {
      const isOrganizationOwner = MAuth.isOrganizationOwner(organization.id);
      const isOrganizationManager = MAuth.isOrganizationManager(organization.id);

      if (isOrganizationOwner || isOrganizationManager) {
        return (
          <DefaultLayout
            renderHeader={this.renderHeader}
            renderBody={this.renderBody}
          />
        );
      }

      Routes.goToNotificationPage(notificationIds.NO_PERMISSION);
    }
    return null;
  }
}

export default OrganizationSettings;
