import React, { Component } from 'react';
import { Label } from 'reactstrap';
import PropTypes from 'prop-types';
import Input from '../../Input';

class FlakinessFilter extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();

    this.buildFilter = this.buildFilter.bind(this);
    this.onInputSearchChange = this.onInputSearchChange.bind(this);
  }

  /*
   * Value is format true.
   * Ex: >=145
   * Check to get value is: >=145
   * Using: check first character
   */
  getInitialState() {
    const { value, operator } = this.props;
    const isFormat = value?.startsWith(operator);
    let searchValue = '';
    if (isFormat) {
      // I used substring to remove the operator.
      searchValue = value.substring(operator.length, value.length);
    }
    return {
      searchInput: searchValue
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { value } = nextProps;
    const { searchInput } = prevState;

    /*
     * When user click button "Clear" in component SearchQuery then value will be null.
     * I check the searchInput if there is a value then set it to null to clear data.
     */
    if (!!searchInput && !value) {
      return {
        searchInput: null
      };
    }
    return prevState;
  }

  onInputSearchChange(event) {
    const { target: { value } } = event;
    const { operator } = this.props;
    this.setState({
      searchInput: value
    }, this.buildFilter(operator + value));
  }

  buildFilter(value) {
    const { id, onBuildFilterSearch } = this.props;
    if (id && onBuildFilterSearch) {
      onBuildFilterSearch(id, value);
    }
  }

  render() {
    const { searchInput } = this.state;
    const { label, placeholder, itemTitle } = this.props;

    return (
      <div>
        <Label>{label}</Label>
        <div className="d-flex">
          <div className="search_item_title">{itemTitle}</div>
          <Input
            onChange={this.onInputSearchChange}
            value={searchInput || ''}
            placeholder={placeholder}
            type="number"
            max={100}
            min={0}
          />
        </div>
      </div>
    );
  }
}

FlakinessFilter.propTypes = {
  onBuildFilterSearch: PropTypes.func,
  /**
   * Current value of input in search bar.
   */
  value: PropTypes.string,
  /**
   * Title of  filed input in search dialog.
   */
  label: PropTypes.string,
  /**
   * Placeholder of  filed input in search dialog.
   */
  placeholder: PropTypes.string,
  /**
   * Id to build filter search.
   */
  id: PropTypes.string,
  /**
   * Title of 1 item filter
   */
  itemTitle: PropTypes.string,
};

FlakinessFilter.defaultProps = {
  value: '',
  label: '',
  placeholder: '',
  id: '',
  itemTitle: null
};

export default FlakinessFilter;
