import PropTypes from 'prop-types';
import React from 'react';
import { DropdownItem } from 'reactstrap';

class ActionDropdownItem extends React.Component {
  constructor(props) {
    super(props);
    this.icon = props.icon;
    this.label = props.label;
  }

  render() {
    const {
      icon,
      label,
      hideLabel,
      ...props
    } = this.props;

    return (
      <DropdownItem className="d-flex align-items-center" {...props}>
        {icon ? <this.icon className="mr-2" /> : icon}{!hideLabel && this.label}
      </DropdownItem>
    );
  }
}

ActionDropdownItem.propTypes = {
  icon: PropTypes.any,
  label: PropTypes.string,
  hideLabel: PropTypes.bool,
};

ActionDropdownItem.defaultProps = {
  hideLabel: false,
};

export default ActionDropdownItem;
