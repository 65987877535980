import React from 'react';
import PropTypes from 'prop-types';
import { Card, Badge } from 'reactstrap';
import _ from 'lodash';
import DataLoader from '../../../components/table/DataLoader';
import { t } from '../../../i18n/t';
import { ExecutionDecorator } from '../../execution/ExecutionDecorator';
import OrganizationCard from './OrganizationCard';
import Time from '../../../utils/Moment';
import ImageProfile from '../../../components/avatar/ImageProfile';
import Routes from '../../../utils/Routes';
import ExecutionTestRunStatus from '../../project_dashboard/components/ExecutionTestRunStatus';
import { next } from '../../../utils/Count';


class LatestExecutions extends React.Component {

  constructor(props) {
    super(props);
    this.dataRef = React.createRef();
    this.organization = this.props.organization;
    this.maxExecutions = this.props.maxExecutions;

    this.getUniqueProfiles = this.getUniqueProfiles.bind(this);
    this.renderData = this.renderData.bind(this);
  }

  renderObject(object, noDiv) {
    return noDiv ? (
      <>
        {object && object.icon ? object.icon : null}
        {object && object.value ? object.value : null}
      </>
    ) : (
      <div
        className={(object && object.class) ? object.class : ''}
        title={(object && object.value && object.class) ? object.value : null}
      >
        {object && object.icon ? object.icon : null}
        {object && object.value ? object.value : null}
      </div>
    );
  }

  renderNumberTest(object) {
    return (
      <div>
        <Badge pill className={`status-badge-${object.status}`} title={_.toUpper(object.status)}>
          {object.value}
        </Badge>
      </div>
    );
  }

  getUniqueProfiles(array) {
    const uniqueArray = new Set(array);
    return [...uniqueArray];
  }

  renderContent(execution) {
    const profiles = this.getUniqueProfiles(execution.executionTestSuiteResources
      .map((executionTestSuiteResource) => executionTestSuiteResource.profiles)
      .flat())
      .filter((profile) => profile)
      .join(', ');

    return (
      <>
        <div className="small-card-content">
          {this.renderObject({ value: ExecutionDecorator.nameDecorator(execution, 'had-ellipsis') }, true)}
        </div>
        <div className="small-card-content">
          {this.renderObject({ icon: ExecutionDecorator.platformDecorator(execution), value: profiles, class: 'had-ellipsis' })}
        </div>
        <div className="small-card-content" />
        <div className="quick-icon text-muted">
          <div>
            {Time.format(execution.startTime, undefined, undefined, execution.project.timezone)}
          </div>
          <div>
            &nbsp;
            ({Time.duration(execution.elapsedDuration)})
          </div>
        </div>
      </>
    );
  }

  isRunningExecution(execution) {
    return execution.executionStage === 'IMPORTING' ||
      execution.executionStage === 'RUNNING';
  }

  renderHeader(execution) {
    return (
      <div>
        <div className="text-header__multi">
          {this.isRunningExecution(execution) && (
            <div className="status">
              {ExecutionDecorator.statusDecorator('', execution)}
            </div>
          )}
          <div title={execution.status}>
            #{execution.order}
          </div>
          <div className="text-header__multi__avatar">
            <ImageProfile
              name={execution.project.name}
              diameter={24}
            />
          </div>
          <div className="text-header__multi__avatar">
            <ImageProfile
              imgUrl={execution.user.avatar}
              name={execution.user.fullName}
              diameter={24}
            />
          </div>
        </div>
        <div className="top-right-quick-icon">
          <ExecutionTestRunStatus execution={execution} showLegend={false} />
        </div>
      </div>
    );
  }

  renderCard(execution) {
    const constructedLink = new Routes({
      executionId: execution.order,
      projectId: execution.project.id,
      teamId: execution.project.teamId
    });
    const status = _.toLower(execution.status);
    return (
      <Card
        className={`custom-card-${status}`}
        key={`org-dashboard-latest-executions-${next()}`}
      >
        <a href={constructedLink.execution_details_link}>
          <OrganizationCard
            header={this.renderHeader(execution)}
            content={this.renderContent(execution)}
          />
        </a>
      </Card>
    );
  }

  renderExecutions(executions) {
    return (
      <>
        <div className="dashboard-header">
          {t('org-dashboard-latestExecution')}
        </div>
        <div className="dashboard-card">
          {executions.map((execution) => this.renderCard(execution))}
        </div>
      </>
    );
  }

  renderData(data) {
    if (data.length > 0) {
      return this.renderExecutions(data);
    }
    return null;
  }

  render() {
    const sourceUrl = `/api/v1/organizations/${this.organization.id}/latest-executions`;
    const { autoUpdate, autoUpdateIntervalTime } = this.props;

    return (
      <DataLoader
        key="org-dashboard-latest-executions"
        ref={this.dataRef}
        pageSize={this.maxExecutions}
        render={this.renderData}
        title="Executions"
        hidePaging
        noCard
        sourceUrl={sourceUrl}
        autoUpdate={autoUpdate}
        autoUpdateIntervalTime={autoUpdateIntervalTime}
      />
    );
  }
}

LatestExecutions.propTypes = {
  organization: PropTypes.object.isRequired,
  maxExecutions: PropTypes.number,
};

LatestExecutions.defaultProps = {
  maxExecutions: 6
};

export default LatestExecutions;
