import React from 'react';
import { Box, Typography, Divider } from '@mui/material';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { IconSummaryLoading } from '../../../images/CustomIcon';
import QueueIcon from '../../../../images/icons/katalonui/QueueIcon';
import { StyledStack, StyledTypography } from './TestSessionSummaryPopover';

const StyledSessionInfoContainer = styled(Box)({
  height: '39px',
  display: 'flex',
  flexDirection: 'row',
  gap: '12px',
  justifyContent: 'end',
});

const StyledSessionInfo = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '4px',
});

function SessionSummaryInfo({ title, runningKey, queuedKey, sessionTypes, paddingRight }) {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="end"
      gap="8px"
    >
      <StyledTypography paddingRight={paddingRight ?? '0px'}>{title}</StyledTypography>
      <StyledStack spacing={1} divider={<Divider />}>
        {sessionTypes.map((sessionType) => (
          <Box key={sessionType.feature} paddingRight={paddingRight ?? '0px'}>
            <StyledSessionInfoContainer>
              <StyledSessionInfo>
                <IconSummaryLoading />
                <Typography>{sessionType[runningKey]}</Typography>
              </StyledSessionInfo>
              <StyledSessionInfo>
                <QueueIcon size="12px" />
                <Typography>{sessionType[queuedKey]}</Typography>
              </StyledSessionInfo>
            </StyledSessionInfoContainer>
          </Box>
        ))}
      </StyledStack>
    </Box>
  );
}

SessionSummaryInfo.propTypes = {
  title: PropTypes.string.isRequired,
  runningKey: PropTypes.string.isRequired,
  queuedKey: PropTypes.string.isRequired,
  sessionTypes: PropTypes.array.isRequired,
  paddingRight: PropTypes.string,
};

export default SessionSummaryInfo;
