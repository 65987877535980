import React from 'react';
import ReactPaginate from 'react-paginate';

import {
  IconChevronLeft,
  IconChevronRight,
} from '../../images/CustomNewIcon';

const Pagination = ({ store, onPageChange, pageKey, unitName }) => {

  // Calculate the first element order in table.
  const calculateFirstElementOrder = () => store.pageSize * (store.currentPage + 1) - (store.pageSize - 1);

  // Calculate last element order in table.
  const calculateLastElementOrder = () => {
    const lastElementOrder = store.pageSize * (store.currentPage + 1);
    return lastElementOrder < store.totalItems ? lastElementOrder : store.totalItems;
  };

  const renderSumaryText = () => {
    const firstElementOrder = calculateFirstElementOrder();
    const lastElementOrder = calculateLastElementOrder();
    const { totalItems } = store;
    const postFix = totalItems > 1 ? 's' : '';
    if (totalItems > 0) {
      return `${firstElementOrder} - ${lastElementOrder} of ${totalItems} ${unitName}${postFix}`;
    }
    return '';
  };

  return (
    <>
      <ReactPaginate
        previousLabel={
          <IconChevronLeft />
        }
        nextLabel={
          <IconChevronRight />
        }
        breakLabel={store.breakLabel}
        pageCount={store.totalPage}
        forcePage={store.currentPage}
        onPageChange={onPageChange}
        containerClassName={store.totalPage > 1 ? 'pagination' : 'd-none'}
        subContainerClassName="pages pagination"
        activeClassName="active"
      />
      <div
        key={pageKey}
        className="pagination-item-count"
      >
        {renderSumaryText()}
      </div>
    </>
  );
};

export default Pagination;
