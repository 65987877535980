import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import { startCase, toLower } from 'lodash';
import Tooltip from './Tooltip';
import { IconInfoCircle } from '../images/CustomIcon';
import { t } from '../i18n/t';
import { TestRunStatus } from '../utils/Constants';
import Link from './Link';

/**
 * @param {String} link
 * @param {String} originalStatus
 * @param {String} latestStatus
 * @param {String} changedBy
 * @returns {Tooltip}
 */
const EditedExecutionTestResultTooltip = ({ link, originalStatus, latestStatus, changedBy }) => {

  const renderStatusTextStyle = (status) => `text-${toLower(status)}-color`;

  const renderResult = (label, result) => {
    return (
      <div className="result-group">
        <Typography>{label}</Typography>
        <Typography className={`status ${renderStatusTextStyle(result)}`}>{startCase(toLower(result))}</Typography>
      </div>
    );
  };

  const renderContent = () => (
    <div className="edited-test-result-tooltip">
      {renderResult(`${t('original_result')}:`, originalStatus)}
      {renderResult(`${t('latest_result')}:`, latestStatus)}
      <Typography color="textSecondary">{t('changed_by', { name: changedBy })}</Typography>
      <div className="edited-test-result-link">
        <Link target="_blank" href={link} data-trackId="otr-view-comments">{t('view_changes_log')}</Link>
      </div>
    </div>
  );

  return (
    <Tooltip content={renderContent()} placement="right-start" interactive>
      <IconInfoCircle className="edited-test-result-tooltip-icon" />
    </Tooltip>
  );
};

EditedExecutionTestResultTooltip.propTypes = {
  link: PropTypes.string,
  originalStatus: PropTypes.string,
  latestStatus: PropTypes.string,
  changedBy: PropTypes.string,
};

EditedExecutionTestResultTooltip.defaultProps = {
  link: '',
  originalStatus: TestRunStatus.FAILED,
  latestStatus: TestRunStatus.FAILED,
  changedBy: '',
};

export default EditedExecutionTestResultTooltip;
