import React from 'react';
import { next } from '../../utils/Count';

class ObjectInfo extends React.Component {

  render() {
    const items = this.props.items;
    const renderedItems = [];
    items.forEach((item) => {
      if (item.text) {
        renderedItems.push(
          <div key={next()} className="object-info__item">
            {item.icon ? <span className="info-icon">{item.icon}</span> : null }
            {item.text}
          </div>,
        );
      }
    });
    return (
      <div className="object-info">
        {renderedItems}
      </div>
    );
  }


}

export default ObjectInfo;
