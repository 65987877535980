import filesize from 'filesize';
import React from 'react';
import { Alert, Button, Card, CardBody, CardHeader, Form, FormGroup, Label, Row } from 'reactstrap';
import { ListItemText } from '@mui/material';
import { TreeItem, TreeView } from '@mui/lab';
import ExternalIssue from '../../components/external_issue/ExternalIssue';
import FileTabsPreview from '../../components/file_preview/FilePreview';
import Gallery from '../../components/gallery/Gallery';
import Input from '../../components/Input';
import LogArea from '../../components/LogArea';
import SidebarLayout from '../../components/SidebarLayout';
import TabComponent from '../../components/TabComponent';
import { t } from '../../i18n/t';
import {
  IconDownloadActive,
  IconDownloadInactive,
  IconFullScreenActive,
  IconFullScreenInactive,
  IconFolderTestCaseCloudStudio,
} from '../../images/CustomIcon';
import MContext from '../../models/MContext';
import Apis from '../../utils/Apis';
import Arrays from '../../utils/Arrays';
import {
  CUSTOM_FIELD_ENTITY_TYPE,
  FAILURE_CATEGORY,
  LABEL_ENTITY_TYPE,
  ObjectType,
  SearchEntity,
  TAG_ENTITY_TYPE,
  TEST_TYPE,
  TestSuiteType,
  VideoExtension,
  TimeLimitMilliseconds,
  CloudType,
} from '../../utils/Constants';
import Routes from '../../utils/Routes';
import Services from '../../utils/Services';
import LogCard from './components/LogCard';
import DecoratorConstants from '../../utils/DecoratorConstants';
import { IconDown, IconAngleRight, IconExternalLink } from '../../images/KitIcons';
import DataLoader from '../../components/table/DataLoader';
import { buildSearchCondition } from '../../components/search/SearchUtils';
import SimilarFailureHelper from '../../utils/SimilarFailureHelper';
import MConfigs from '../../models/MConfigs';
import GroupEvent from '../../utils/track/GroupEvent';
import MFlags from '../../models/MFlags';
import Select from '../../components/Select';
import { next } from '../../utils/Count';
import WebSocket from '../../services/WebSocket';
import Helper from '../../utils/Helper';
import DocumentLink from '../../utils/DocumentLink';
import { sendAnalyticEventForAction } from '../../utils/SegmentAnalytics';
import EditCustomField from '../../components/EditCustomField';
import Link from '../../components/Link';
import TagTsx from '../../components/tsx-components/TagTsx';
import ExternalIssues from '../../components/external_issue/ExternalIssues';
import {
  filterExecutionFilesByContainedValueInName,
  convertTimeStringToEpochTime,
} from './services/executionService';
import InfoIcon from '../../../images/icons/katalonui/InfoIcon';
import { TestType } from '../../models/model/TestType';

const optionUncategorized = {
  value: FAILURE_CATEGORY.UNCATEGORIZED,
  label: FAILURE_CATEGORY.UNCATEGORIZED
};


class ResultTab extends TabComponent {
  constructor(props) {
    super(props);
    this.projectId = MContext.projectId;
    this.executionTestResultId = MContext.executionTestResultId;
    this.externalIssuesData = null;
    this.handlerId = next();
    this.isShowFailureReason = (MContext.isTestOpsEnterprise || MContext.isTestOpsBusiness
          || MContext.isPlatformProfessional || MContext.isPlatformBusiness || MContext.isPlatformEnterprise || MFlags.allowBetaUserForFeatureRCAEnabled)
      && SimilarFailureHelper.isFailedTestResult(props.testResult);
    this.logFullScreenViewerRef = React.createRef();

    this.openErrorInFullScreen = this.openErrorInFullScreen.bind(this);
    this.openLogInFullScreen = this.openLogInFullScreen.bind(this);
    this.renderSameFailures = this.renderSameFailures.bind(this);
    this.renderFailureReason = this.renderFailureReason.bind(this);
    this.handleSelectLabelChange = this.handleSelectLabelChange.bind(this);
    this.getSystemLabel = this.getSystemLabel.bind(this);
    this.getCurrentLabel = this.getCurrentLabel.bind(this);
    this.subscribeTopic = this.subscribeFailureReasonTopic.bind(this);
    this.handleUpdateCustomFields = this.handleUpdateCustomFields.bind(this);
    this.handleAddNewTag = this.handleAddNewTag.bind(this);
    this.handleDeleteTag = this.handleDeleteTag.bind(this);
    this.getExecutionVideoFiles = this.getExecutionVideoFiles.bind(this);

    this.meta.id = 'page-execution-test-result-details-result';
    this.meta.title = document.title;
    this.hasNoVideo = false;

    this.state = {
      errorDetails: null,
      stdout: null,
      description: null,
      log: null,
      selectedLabel: optionUncategorized,
      isShowMessageSystemSuggested: false,
      systemLabelOptions: [],
      selectedCustomFields: [],
    };
  }

  componentDidMount() {
    const { testResult } = this.props;
    const { execution } = testResult;
    this.getErrorDetails(testResult);
    this.getStdout(testResult);
    this.getDescription(testResult);
    this.getLog(testResult);
    if (this.isShowFailureReason) {
      this.getSystemLabel();
      this.getCurrentLabel();
      this.subscribeFailureReasonTopic();
    }
    this.getExecutionVideoFiles(execution.id);
  }

  handleUpdateCustomFields(selectedCustomFields, shouldCallApiToUpdate = true) {
    if (!shouldCallApiToUpdate) {
      this.setState({
        selectedCustomFields
      });
    } else {
      const customFieldOptions = selectedCustomFields.map((selectedCustomField) => ({
        id: selectedCustomField.customFieldOption.id,
        definitionId: selectedCustomField.customFieldDefinition.id
      }));

      Services.updateTestResultCustomFields(this.executionTestResultId, customFieldOptions)
        .then(() => {
          this.setState({
            selectedCustomFields
          });
        });
    }
  }

  handleAddNewTag(addedTag) {
    return Services.updateExecutionTestResultTag(this.executionTestResultId, addedTag);
  }

  handleDeleteTag(deletedTag) {
    return Services.deleteExecutionTestResultTag(this.executionTestResultId, deletedTag);
  }

  renderCustomFieldsAndTags() {
    const { testResult } = this.props;
    const { selectedCustomFields } = this.state;
    return (
      <>
        <EditCustomField
          maxCharacterTruncate={15}
          keyPaperWidth={200}
          optionPaperWidth={150}
          entityId={testResult?.id}
          entityType={CUSTOM_FIELD_ENTITY_TYPE.EXECUTION_TEST_RESULT}
          setSelectedCustomFields={this.handleUpdateCustomFields}
          selectedCustomFields={selectedCustomFields}
        />
        <Label for="tag">{t('tag#title')}</Label>
        <TagTsx
          ignoreWidthSize
          entityId={testResult?.id}
          entityType={TAG_ENTITY_TYPE.EXECUTION_TEST_RESULT}
          handleOnSelectChange={this.handleAddNewTag}
          handleOnDeleteTag={this.handleDeleteTag}
        />
      </>
    );
  }

  subscribeFailureReasonTopic() {
    const { testResult } = this.props;
    if (this.projectId) {
      const topic = `${SearchEntity.LabelLink}-${testResult.id}`;
      WebSocket.subscribe(
        {
          projectId: this.projectId,
          topics: [topic],
        },
        this.getCurrentLabel,
        this.handlerId
      );
    }
  }

  componentWillUnmount() {
    WebSocket.unsubscribe(this.handlerId);
  }

  getCurrentLabel() {
    const { testResult } = this.props;
    Services.getLabelByEntityIdAndEntityType(testResult?.id, LABEL_ENTITY_TYPE.EXECUTION_TEST_RESULT).then((res) => {
      if (res.content.length > 0) {
        const { label, userId } = res.content[0];
        const selectedLabel = {
          id: label.systemLabel?.id,
          value: label.name,
          label: label.name
        };
        const isShowMessageSystemSuggested = selectedLabel.value !== FAILURE_CATEGORY.UNCATEGORIZED && !userId;
        this.setState({
          selectedLabel,
          isShowMessageSystemSuggested
        });
      }
    });
  }

  getSystemLabel() {
    Services.getSystemLabel(LABEL_ENTITY_TYPE.EXECUTION_TEST_RESULT).then((res) => {
      const systemLabelOptions = res.map((content) => ({
        id: content.id,
        value: content.name,
        label: content.name
      }));
      this.setState({ systemLabelOptions });
    });
  }

  getExecutionVideoFiles(executionId) {
    const { testResult } = this.props;
    const { testCase } = testResult;
    filterExecutionFilesByContainedValueInName(executionId, testCase.name).then((res) => {
      this.setState({ executionVideoFiles: res });
    });
  }

  getErrorDetails({ errorDetailsId }) {
    if (errorDetailsId) {
      Services.getLogContent(errorDetailsId)
        .then((response) => {
          const errorDetails = response || '';
          this.setState({
            errorDetails,
          });
        });
    } else {
      this.setState({
        errorDetails: '',
      });
    }
  }

  getDescription({ descriptionId }) {
    if (descriptionId) {
      Services.getLogContent(descriptionId)
        .then((response) => {
          const description = response || '';
          this.setState({
            description,
          });
        });
    } else {
      this.setState({
        description: '',
      });
    }
  }

  getLog({ logId }) {
    if (logId) {
      Services.getLogContent(logId)
        .then((response) => {
          this.setState({
            log: response || '',
          });
        });
    } else {
      this.setState({
        log: '',
      });
    }
  }

  getStdout({ stdoutId }) {
    if (stdoutId) {
      Services.getLogContent(stdoutId)
        .then((response) => {
          const stdout = response || '';
          this.setState({
            stdout,
          });
        });
    } else {
      this.setState({
        stdout: '',
      });
    }
  }

  openErrorInFullScreen() {
    this.setTabContent(0);
    this.logFullScreenViewerRef.current.toggle();
  }

  openLogInFullScreen() {
    this.setTabContent(1);
    this.logFullScreenViewerRef.current.toggle();
  }

  setTabContent(defaultTab) {
    const { testResult } = this.props;
    const { errorDetails, stdout, log } = this.state;
    this.logFullScreenViewerRef.current.setTabs([
      {
        title: 'Errors',
        file: {
          content: errorDetails,
          name: 'error-stack-trace.txt',
          type: 'text-log',
          source: Apis.executionTestResultLog(testResult.errorDetailsId),
        },
        customClass: 'text-danger',
      },
      {
        title: 'Text Log',
        file: {
          content: stdout,
          name: 'stdout.txt',
          type: 'text-log',
          source: Apis.executionTestResultLog(testResult.stdoutId),
        },
      },
      ...Arrays.insertIf(!!log, {
        title: 'Tree Log',
        file: {
          content: log,
          name: 'log.json',
          type: 'tree-log',
          source: Apis.executionTestResultLog(testResult.logId),
        },
        props: {
          execution: testResult.execution,
          maxHeight: null,
        },
      }),
    ], defaultTab);
  }

  renderFullScreenLog() {
    return <FileTabsPreview ref={this.logFullScreenViewerRef} />;
  }

  renderErrorMessage() {
    const { testResult } = this.props;
    if (testResult.errorMessage) {
      return (
        <Card>
          <CardHeader>Error Message</CardHeader>
          <CardBody className="shadow-none">
            <pre className="text-failed-color text-wrap">
              {testResult.errorMessage}
            </pre>
          </CardBody>
        </Card>
      );
    }
    return null;
  }

  renderFailedAssertions() {
    const { testResult } = this.props;
    const { testResultAssertionsFailed } = testResult;

    const assertionsFailed = testResultAssertionsFailed.map(({ id, message, stacktrace }) => {
      const result =
        <TreeItem nodeId={id} label={message} className="mb-2 tree-item">
          <ListItemText className="ml-3 text-failed-color">
            <pre>
              {stacktrace.trim()}
            </pre>
          </ListItemText>
        </TreeItem>;
      return result;
    });

    return (
      <Card>
        <CardHeader>
          <Row className="mx-0 justify-content-between">
            <span>{t('failed_assertions')}</span>
          </Row>
        </CardHeader>
        <CardBody className="shadow-none">
          {testResultAssertionsFailed &&
            <TreeView
              className="collapse-assertion"
              defaultCollapseIcon={<IconDown />}
              defaultExpandIcon={<IconAngleRight />}
            >
              {assertionsFailed}
            </TreeView>}
          {testResultAssertionsFailed.length <= 0 &&
            <div className="text-center">{t('table#empty-message')}</div>}
        </CardBody>
      </Card>
    );
  }

  renderErrorDetails() {
    const { errorDetails } = this.state;
    if (!errorDetails) {
      return null;
    }
    const { testResult } = this.props;
    return (
      <Card>
        <CardHeader>
          <Row className="mx-0 justify-content-between align-items-center flex-grow-1">
            <div className="d-flex">
              <span>{t('errors')}</span>
              <Button
                type="button"
                className="two-state-button mx-1"
                color="link"
                title="View Full Screen"
                onClick={this.openErrorInFullScreen}
                data-trackid="view-error-fullscreen"
              >
                <IconFullScreenActive className="icon-active" />
                <IconFullScreenInactive className="icon-inactive" />
              </Button>
            </div>
            {testResult?.errorDetailsId && (
              <Button
                className="action-button two-state-button"
                color="link"
                id="download-error-stack-trace"
                key="download-error-stack-trace"
                title={t('download')}
                data-trackid="test-result-download-logs"
                data-groupid={GroupEvent.ACCESS_REPORT}
              >
                <a
                  href={Apis.executionTestResultLog(testResult.errorDetailsId)}
                  download="error-stack-trace.txt"
                >
                  <IconDownloadActive className="icon-active" />
                  <IconDownloadInactive className="icon-inactive" />
                </a>
              </Button>
            )}
          </Row>
        </CardHeader>
        <CardBody className="shadow-none">
          <LogArea className="text-failed-color" rows="12" monoFont>{errorDetails}</LogArea>
        </CardBody>
      </Card>
    );
  }

  isVideoMatchingTestCase(video, testCaseName, startTime) {
    return (
      video.name.includes(testCaseName) && video.name.includes(startTime)
    );
  }

  isExceedTimeLimit() {
    const { testResult } = this.props;
    const { execution } = testResult;
    const currentTime = new Date().getTime();
    const executionTime = new Date(execution.endTime).getTime();
    return currentTime - executionTime > TimeLimitMilliseconds;
  }

  isGen4TestCloudAgent() {
    const { execution, testResult: { testCase: { testType } } } = this.props;
    if (testType !== TestType.G4_TEST_CASE) return false;
    const { jobs } = execution;
    if (jobs.length > 0) {
      const { runConfiguration } = jobs[0];
      return (runConfiguration.cloudType === CloudType.TEST_CLOUD_AGENT);
    }
    return false;
  }

  getProperVideoForTestResult() {
    if (!this.isGen4TestCloudAgent()) {
      return null; // not supported
    }

    const { testResult } = this.props;
    const { attachments, testCase } = testResult;
    const videoFile = attachments.find((value) => value.name.endsWith(VideoExtension.AVI) || value.name.endsWith(VideoExtension.MOV));

    if (videoFile) {
      return null; // already have video
    }

    const { executionVideoFiles } = this.state;
    if (!executionVideoFiles) return null;

    const startTimeEpoch = convertTimeStringToEpochTime(testResult.startTime);

    const matchVideos = executionVideoFiles.filter(
      (video) => this.isVideoMatchingTestCase(video, testCase.name, startTimeEpoch)
    );

    if (!matchVideos) {
      if (this.isExceedTimeLimit()) {
        this.hasNoVideo = true;
        return null;
      }
      return [{
        caption: DecoratorConstants.videoInProcessingCaption(),
        isLoading: true,
      }];
    }
    return matchVideos.map((matchVideo) => ({
      source: matchVideo.url,
      thumbnail: matchVideo.thumbnailUrl,
      caption: `${matchVideo.name} (${filesize(matchVideo.size)})`,
      fileType: matchVideo.name.split('.').pop(),
    }));
  }

  renderAttachmentsPreview() {
    const { testResult } = this.props;
    const { attachments } = testResult;

    const files = attachments.map((item) => ({
      source: item.url,
      thumbnail: item.thumbnailUrl,
      caption: `${item.name} (${filesize(item.size)})`,
      fileType: item.name.split('.').pop(),
    }));

    const attachedVideos = this.getProperVideoForTestResult();
    if (attachedVideos) {
      files.push(...attachedVideos);
    }

    return files.length > 0 ? (
      <Card>
        <CardHeader>
          {t('attachments')}
        </CardHeader>
        <CardBody className="shadow-none">
          { this.hasNoVideo && <Alert color="primary"><InfoIcon /> {t('test-result#no-video')}</Alert> }
          <Gallery images={files} data-trackid="view-test-result-attachment" />
        </CardBody>
      </Card>
    ) : null;
  }

  renderLog() {
    const { testResult } = this.props;
    const { stdout, log } = this.state;
    return <LogCard stdout={stdout} log={log} testResult={testResult} openLogInFullScreen={this.openLogInFullScreen} />;
  }

  renderDescription() {
    const { description } = this.state;
    if (!description) {
      return null;
    }
    return (
      <Card>
        <CardHeader>
          <Row className="mx-0 justify-content-between">
            <span>{t('descriptions')}</span>
          </Row>
        </CardHeader>
        <CardBody className="shadow-none">
          <pre>{description && description.trim()}</pre>
        </CardBody>
      </Card>
    );
  }

  renderSimilarTestResults() {
    const { testResult } = this.props;
    const { isOnPremise } = MConfigs;
    if (SimilarFailureHelper.isFailedTestResult(testResult) && !isOnPremise) {
      return (
        <FormGroup>
          <Label for="same_failures">{t('similar_failures')}</Label>
          {this.renderSameFailureTestResultLinks()}
        </FormGroup>
      );
    }
    return null;
  }

  handleSelectLabelChange(event, option) {
    const { selectedLabel } = this.state;
    if (selectedLabel.value !== option.value) {
      Services.changeLabel(this.executionTestResultId, option).then(() => {
        this.setState({
          selectedLabel: option,
          isShowMessageSystemSuggested: false,
        });
      });
      const dataTrackId = 'failed-test-result-change-suggested-reason';
      const dataGroupId = GroupEvent.ACCESS_REPORT;
      sendAnalyticEventForAction(dataTrackId, { 'data-groupid': dataGroupId });
    }
  }

  renderFailureReason() {
    const { selectedLabel, systemLabelOptions, isShowMessageSystemSuggested } = this.state;
    const isDisabledSelect = !selectedLabel?.id;
    return (
      <FormGroup>
        <Label for="failure-reason">{t('failure-reason')}</Label>
        <span className="badge badge-trial ml-2 mb-2">{t('beta')}</span>
        <Select
          key={next()}
          id="failure-reason-select"
          getOptionLabel={(option) => option.label}
          defaultValue={selectedLabel}
          options={systemLabelOptions}
          onChange={this.handleSelectLabelChange}
          useAutocomplete
          disableClearable
          disabled={isDisabledSelect}
          blurOnSelect
        />
        {
          isShowMessageSystemSuggested && <div className="text-secondary mt-2">{t('system-suggested')}</div>
        }
        {this.renderSuggestedSolution()}
      </FormGroup>
    );
  }

  renderSuggestedSolution() {
    const { testResult: { errorKeyword } } = this.props;
    if (!errorKeyword) {
      return null;
    }
    const keywordName = Helper.getKeywordName(errorKeyword);
    if (!keywordName) {
      return null;
    }
    return (
      <a
        href={`${DocumentLink.SOLUTION_FOR_COMMON_ERRORS}#${keywordName}`}
        target="_blank"
        rel="noreferrer"
        className="suggest_solution_link"
        data-trackId="failed-test-result-click-suggested-solution"
        data-groupId={GroupEvent.ACCESS_REPORT}
      >
        <IconExternalLink />{` ${t('suggested_solution')}`}
      </a>
    );
  }

  renderTestSuite() {
    const { testResult } = this.props;
    const { testCase } = testResult;
    const isTestCaseCloudType = testCase?.testType === TEST_TYPE.G5_TEST_CASE;
    return (
      <Card>
        <CardBody>
          <Form>
            <FormGroup>
              <Label for="test_suites">{t('test-suite')}</Label>
              {this.renderTestSuiteLinks()}
            </FormGroup>
            <FormGroup>
              <Label for="path">{t('test_case')}</Label>
              <Input
                plaintext
                id="test_case_name"
              >
                {isTestCaseCloudType
                    && <IconFolderTestCaseCloudStudio className="mr-1 fa-lg" title={t('cloud-studio')} />}
                {DecoratorConstants.testCaseNameDecorator('name', testCase)}
              </Input>
              <Input
                plaintext
                id="path"
              >
                {testCase.path}
              </Input>
            </FormGroup>
            {this.renderSimilarTestResults()}
            {this.isShowFailureReason && this.renderFailureReason()}
            <FormGroup>
              <Label for="maintainer">{t('maintainer')}</Label>
              {this.renderTestCaseMaintainer()}
            </FormGroup>
            {this.renderCustomFieldsAndTags()}
          </Form>
        </CardBody>
      </Card>
    );
  }

  renderJiraIssues() {
    const { testResult } = this.props;
    return (
      <Card>
        <CardBody>
          <Form>
            <ExternalIssue
              title={t('jira_defect')}
              projectId={this.projectId}
              objectType={ObjectType.EXECUTION_TEST_RESULT}
              objectId={testResult.id}
            />
          </Form>
        </CardBody>
      </Card>
    );
  }

  renderJiraIssuesV2() {
    const { testResult } = this.props;
    return (
      <Card>
        <CardBody>
          <Form>
            <ExternalIssues
              title={t('defects')}
              projectId={this.projectId}
              objectType={ObjectType.EXECUTION_TEST_RESULT}
              objectId={testResult.id}
              bigSizeTitle
            />
          </Form>
        </CardBody>
      </Card>
    );
  }

  renderResult() {
    return (
      <>
        {this.renderTestSuite()}
        {!MFlags.jiraIssueLinkingCreatingEnabled && this.renderJiraIssues()}
      </>
    );
  }

  renderTestSuiteLinks() {
    const { testResult } = this.props;
    const { testSuite } = testResult;
    const isTestSuiteCloudType = testSuite?.type === TestSuiteType.CLOUD_STUDIO;
    // testResult.executionTestSuite does not available until statistics event complete
    if (testSuite && testResult.executionTestSuite) {
      const constructedLink = new Routes({
        executionId: this.executionId,
        executionTestSuiteId: testResult.executionTestSuite.urlId,
      });
      return (
        <Input plaintext>
          {isTestSuiteCloudType
              && <IconFolderTestCaseCloudStudio className="mr-1 fa-lg" title={t('cloud-studio')} />}
          <Link href={constructedLink.execution_test_suite_detail_link}>{testSuite.name}</Link>
        </Input>
      );
    }
    return null;
  }

  renderTestCaseMaintainer() {
    const { testResult } = this.props;
    const { testCase } = testResult;
    if (testCase && testCase.maintainer) {
      return DecoratorConstants.renderUserDecorator(testCase.maintainer);
    }
    return null;
  }

  renderSameFailures(data) {
    const content = [];
    let i = 0;
    const length = data.length;
    for (const result of data) {
      const { id, execution } = result;
      const constructedLink = new Routes({
        executionId: execution?.order,
        executionTestResultId: id,
      });
      content.push((
        <div key={id} className="col-form-label">
          {DecoratorConstants.linkDecorator(`#${id}`, constructedLink.execution_test_result_detail_link)}
          {i !== length - 1 && (<span className="mr-2">, </span>)}
        </div>
      ));
      i++;
    }
    if (length > 0) {
      const analysisRouter = new Routes();
      content.push(
        <div className="ml-2">
          {DecoratorConstants.linkDecorator(t('view_analysis'), analysisRouter.execution_test_result_similar_failures_link)}
        </div>
      );
    }
    const none = <div className="col-form-label">None</div>;
    return <div className="d-flex flex-wrap">{content.length > 0 ? content : none}</div>;
  }
  renderSameFailureTestResultLinks() {
    const { testResult } = this.props;
    return (
      <DataLoader
        key={`${testResult.id}_similar_failures`}
        entityType={SearchEntity.SimilarFailure}
        defaultSearchConditions={[
          buildSearchCondition('testResultId', '=', testResult.id),
        ]}
        render={this.renderSameFailures}
        pageSize={3}
        hidePaging
        noCard
        disableFilterButton
      />
    );
  }

  render() {
    return (
      <SidebarLayout
        renderMain={() =>
          <>
            {MFlags.jiraIssueLinkingCreatingEnabled && this.renderJiraIssuesV2()}
            {this.renderErrorMessage()}
            {this.renderAttachmentsPreview()}
            {this.renderDescription()}
            {this.renderFailedAssertions()}
            {this.renderErrorDetails()}
            {this.renderLog()}
            {this.renderFullScreenLog()}
          </>}
        renderSidebar={() =>
          <>
            {this.renderResult()}
          </>}
      />
    );
  }
}

export default ResultTab;
