import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import React from 'react';
import MAuth from '../../../models/MAuth';
import Routes from '../../../utils/Routes';
import OrganizationDashboard from '../../organization/OrganizationDashboard';


class OrganizationHomeComponent extends React.Component {

  constructor(props) {
    super(props);

    this.organization = props.organization;
    this.organizationId = this.organization && this.organization.id;
    this.renderQuickActions = this.renderQuickActions.bind(this);
  }

  quickActions() {
    const route = new Routes();
    return ([
      {
        name: 'View your licenses',
        link: route.organization_licenses_link,
        condition: MAuth.isOrganizationManager(this.organizationId),
      },
      {
        name: 'Invite users to this organization',
        link: route.organization_users_invite_link,
        condition: MAuth.isOrganizationManager(this.organizationId)
      },
      {
        name: 'Subscribe to Katalon products',
        link: route.organization_subscription,
        condition: MAuth.isBillingManager(this.organizationId)
      },
      {
        name: 'Assign Katalon licenses to users',
        link: route.organization_kse_licenses_link,
        condition: MAuth.isOrganizationManager(this.organizationId)
      },
      {
        name: 'Get the Organization ID',
        link: route.organization_settings_link,
        condition: MAuth.isOrganizationOwner(this.organizationId)
      },
      {
        name: 'Generate an API key',
        link: route.apikey_link,
        condition: true
      },
      {
        name: 'Manage your profile',
        link: route.user_profile_link,
        condition: true
      },
    ]);
  }

  renderQuickActions() {
    const quickActions = this.quickActions();
    return (
      <div className="sticky-top">
        <List component="nav">
          <ListItem>
            <h5>Quick Actions</h5>
          </ListItem>
          {quickActions.map(({ name, link, condition }) => {
            if (condition) {
              return (
                <ListItem key={name} button component="a" href={link}>
                  <ListItemText primary={name} />
                </ListItem>);
            } else return null;
          })}
        </List>
      </div>
    );
  }

  renderDashboard() {
    return (
      <>
        <OrganizationDashboard />
      </>
    );
  }

  render() {
    return this.renderDashboard();
  }
}

export default OrganizationHomeComponent;
