import React from 'react';
import { StatelessApp } from '@katalon-studio/katalon-ui-utils';
import PageComponent from '../components/PageComponent';
import { t } from '../i18n/t';
import MConfigs from '../models/MConfigs';
import MContext from '../models/MContext';
import MAuth from '../models/MAuth';
import MFlags from '../models/MFlags';
import WebSocket from '../services/WebSocket';
import { sendAnalyticEventForAction, analyticsPage } from '../utils/SegmentAnalytics';
import GroupEvent from '../utils/track/GroupEvent';

class AutonomousTesting extends PageComponent {
  constructor(props) {
    super(props);
    this.meta.id = 'page-truetest';
    this.meta.groupId = GroupEvent.ACCESS_AUT_MANAGEMENT;
    this.meta.title = t('userJourneys');
  }

  render() {
    const TestOpsContext = {
      MContext,
      MAuth,
      MFlags,
      MConfigs,
      TestOpsUtils: {
        sendAnalyticEventForAction,
        analyticsPage,
        WebSocket
      }
    };
    return (
      <StatelessApp
        id="katalon-autonomous"
        path={MConfigs.microAppAutonomousUrl}
        context={TestOpsContext}
      />
    );
  }
}

export default AutonomousTesting;
