import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { CardActions, CardContent, Collapse, IconButton } from '@mui/material';
import { styled } from '@mui/styles';
import { t } from '../../i18n/t';
import { IconChevronRight } from '../../images/CustomIcon';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ expand }) => ({
  transform: !expand ? 'rotate(90deg)' : 'rotate(270deg)',
  marginLeft: 'auto',
}));

export default function CollapseCard({ header, content }) {
  const [expanded, setExpanded] = useState(true);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    setExpanded(true);
  }, [content]);

  return (
    <div>
      <CardActions disableSpacing className="p-0">
        {header && <div> {header} </div>}
        <ExpandMore
          className="align-item-left"
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <IconChevronRight />
        </ExpandMore>
      </CardActions>
      <hr className="mb-1 mt-1" />
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        {content.length === 0 ?
          <div className="text-center">{t('table#empty-message')}</div> :
          <CardContent className="pl-0 pb-2 pt-2">
            {content}
          </CardContent>}
      </Collapse>
    </div>
  );
}

CollapseCard.propTypes = {
  header: PropTypes.element,
  content: PropTypes.element
};

CollapseCard.defaultProps = {
  header: null,
  content: [],
};
