import React, { Component } from 'react';
import { Button } from 'reactstrap';
import { t } from '../../i18n/t';
import MContext from '../../models/MContext';
import Services from '../../utils/Services';
import FormDialog from './FormDialog';
import Notification from '../../utils/Notification';
import MConfigs from '../../models/MConfigs';

export const enableStrictDomainWarning = (serverUrl) =>
  <p>
    When this setting is enabled, all members in your Organization will be restricted
    from <a href={serverUrl} target="_blank" rel="noopener noreferrer">{serverUrl}</a>.
  </p>;

export const disableStrictDomainWarning = (serverUrl) =>
  <p>
    When this setting is disabled, the domain <a href={serverUrl} target="_blank" rel="noopener noreferrer">{serverUrl}</a> is
    accessible for all members.
  </p>;


class SwitchStrictDomainDialog extends Component {
  constructor(props) {
    super(props);
    this.dialogRef = React.createRef();
    this.organizationId = MContext.organizationId;
    this.toggle = this.toggle.bind(this);
    this.updateStrictDomain = this.updateStrictDomain.bind(this);
  }

  toggle() {
    this.dialogRef.current.toggle();
  }

  updateStrictDomain() {
    const { currentStrictDomain, afterUpdate } = this.props;
    const strictDomain = !currentStrictDomain;
    Services.updateStrictDomain(this.organizationId, strictDomain)
      .then(({ name }) => {
        Notification.pushSuccess(`${name} was updated.`);
        afterUpdate(strictDomain);
      });
  }

  render() {
    const { currentStrictDomain } = this.props;
    const title = currentStrictDomain ? t('strict-domain#disable-btn') : t('strict-domain#enable-btn');
    const serverUrl = MConfigs.serverUrl;
    const submitBtn =
      <Button type="submit" color="danger">
        {currentStrictDomain ? t('strict-domain#disable-confirm-btn') : t('strict-domain#enable-confirm-btn')}
      </Button>;
    return (
      <FormDialog
        handleApproval={this.updateStrictDomain}
        dataTrackid="enable-strict-domain-organization"
        submitBtn={submitBtn}
        title={title}
        ref={this.dialogRef}
      >
        {currentStrictDomain ? disableStrictDomainWarning(serverUrl) : enableStrictDomainWarning(serverUrl)}
      </FormDialog>
    );
  }
}

export default SwitchStrictDomainDialog;
