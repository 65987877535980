import React from 'react';
import PropTypes from 'prop-types';
import { Label } from 'reactstrap';
import { get } from 'lodash';
import { IconButton } from '@mui/material';
import Icon from '@katalon-studio/katalon-ui/Icon';
import classnames from 'classnames';
import { t } from '../../i18n/t';
import MTableColumnDataMapping from './../table/models/MTableColumnDataMapping';
import DecoratorConstants from '../../utils/DecoratorConstants';
import TableSortCore from './../table/models/TableSortCore';
import Arrays from '../../utils/Arrays';
import colors from '../../../scss/colors.scss';

export default function TestCaseTestSuiteDataTable(props) {

  const {
    removeLinkingTestCases,
    selectedLinkingTestCases,
    isAbridgedVersion,
    testProject,
  } = props;

  const removeSelectedValue = (value) => {
    removeLinkingTestCases(value);
  };

  const columnMapping = [
    new MTableColumnDataMapping(
      t('no'),
      'row_number',
      (name, row) => {
        if (row.row_number) {
          return DecoratorConstants.orderDecorator(name, row);
        } else {
          return null;
        }
      },
      undefined,
      'fit-content-column',
      undefined,
      '5%'
    ),
    new MTableColumnDataMapping(
      t('name'),
      'name',
      (name, row) => DecoratorConstants.testCaseNameDecorator(name, row, null, true),
      'short-text-column',
      '',
      undefined,
      '35%'
    ),
    new MTableColumnDataMapping(
      t('path'),
      '',
      (name, row) => {
        const sourcePath = DecoratorConstants.addSourceOfTestCaseAndTestSuite(row.path, testProject || row.testProject);
        return DecoratorConstants.textMiddleTruncateDecorator(sourcePath);
      },
      undefined,
      '',
      undefined,
      '35%'
    ),
    ...Arrays.insertIf(!isAbridgedVersion,
      new MTableColumnDataMapping(
        t('maintainer'),
        'maintainer',
        (name, row) => {
          const value = get(row, name);
          if (value) {
            return DecoratorConstants.renderUserWithoutNameDecorator(value);
          }
          return null;
        }
      ),
      new MTableColumnDataMapping(
        t('last_updated'),
        'updatedAt',
        (name, row) => {
          if (row.updatedAt) {
            return DecoratorConstants.timeDecorator(name, row);
          } else {
            return null;
          }
        },
        undefined,
        'nowrap-column',
      )),
    new MTableColumnDataMapping(
      t('table-header#action'),
      'issueId',
      (name, row) => (
        <IconButton
          aria-label="delete"
          onClick={() => removeSelectedValue(row)}
          title={t('remove')}
        >
          <Icon
            type="fa-regular"
            name="fa-trash-can"
            textSize="16px"
            boundingSize={null}
            color={null}
            sx={{
              color: colors.darkBlue
            }}
          />
        </IconButton>
      ),
      undefined,
      '',
      undefined,
      isAbridgedVersion ? '10%' : '5%'
    )
  ];

  const renderInformation = () => (
    <div className={classnames('mt-3', !isAbridgedVersion && 'd-inline-flex')}>
      {isAbridgedVersion ? <Label className="mr-2">{t('selected')}</Label> : <h6 className="mr-2 mb-0">{t('selected')}</h6>}
      {DecoratorConstants.roundGreyQuantityBadge(selectedLinkingTestCases.length)}
    </div>
  );

  const renderTestCasesDataTable = () => (
    <div className={
      classnames(!isAbridgedVersion && 'mt-3',
        isAbridgedVersion ? 'add-linked-test-cases' : 'edit-linked-test-cases')
      }
    >
      <TableSortCore
        data={selectedLinkingTestCases}
        columnMapping={columnMapping}
        fixLayout
        stickyHeader
      />
    </div>
  );

  return (
    <>
      {renderInformation()}
      {renderTestCasesDataTable()}
    </>
  );
}

TestCaseTestSuiteDataTable.propTypes = {
  removeLinkingTestCases: PropTypes.func,
  selectedLinkingTestCases: PropTypes.array,

  // table in abridged version will have less column than normal version
  isAbridgedVersion: PropTypes.bool,
};

TestCaseTestSuiteDataTable.defaultProps = {
  removeLinkingTestCases: null,
  selectedLinkingTestCases: [],
  isAbridgedVersion: false,
};
