import React from 'react';
import { Card, CardBody } from 'reactstrap';
import { Button } from '@mui/material';
import PageComponent from '../../components/PageComponent';
import MContext from '../../models/MContext';
import { t } from '../../i18n/t';
import DateRangePicker from '../../components/DateRangePicker';
import DateHelper from '../../utils/DateHelper';
import { IconRefresh } from '../../images/CustomIcon';
import GroupEvent from '../../utils/track/GroupEvent';
import Select from '../../components/Select';
import { FeatureNames, ResultFilterType } from '../../utils/Constants';
import { sendAnalyticEventForAction } from '../../utils/SegmentAnalytics';
import { withPremiumFeature } from '../../components/HigherOrderComponent';
import FailedTestResultChart from './test_run_report/FailedTestResultChart';
import { getDefaultTestResultSearchConditions } from './test_result_reports/utils';
import TopNExceptionsDataTable from './test_result_reports/TopNExceptionsDataTable';

const optionsResultFilter = [
  {
    value: ResultFilterType.ALL_RESULTS,
    label: t('result-filter-all-results'),
  },
  {
    value: ResultFilterType.LATEST_RESULTS,
    label: t('result-filter-latest-result'),
  },
];

class FailedTestResults extends PageComponent {

  constructor(props) {
    super(props);
    this.meta.id = 'page-failed-test-result-dashboard';
    this.projectId = MContext.projectId;
    this.project = MContext.project;
    this.meta.title = t(
      'Project {{name}} - {{page}}',
      { name: this.project.name, page: t('failed_test_results') },
    );

    this.state = {
      filterDateTimeValue: DateHelper.getLast6Months(),
      select: optionsResultFilter[0]
    };

    sendAnalyticEventForAction('page-failed-test-result-dashboard',
      { 'data-groupid': GroupEvent.ACCESS_REPORT });

    this.renderFilter = this.renderFilter.bind(this);
    this.renderButtonApplyFilter = this.renderButtonApplyFilter.bind(this);
    this.handleDateFilterChange = this.handleDateFilterChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.refreshAllCharts = this.refreshAllCharts.bind(this);
    this.failedTestResultChartRef = React.createRef();
    this.topNExceptionsDataTableRef = React.createRef();
    this.topNExceptionsRef = React.createRef();
  }

  handleSelectChange(event, option) {
    this.setState({
      select: option
    }, () => {
      sendAnalyticEventForAction('select-all-latest-failed-test-result-dashboard',
        { 'data-groupid': GroupEvent.ACCESS_REPORT });
    });
  }

  // handleChange when user input date filter value
  // value will be save in the form of object { startTime: Moment Object, endTime: Moment Object }
  handleDateFilterChange(changedDateTimeValue) {
    this.setState({
      filterDateTimeValue: changedDateTimeValue
    });
  }

  refreshAllCharts() {
    if (this.failedTestResultChartRef.current) {
      this.failedTestResultChartRef.current.clearCacheAndRefreshData();
    }
    if (this.topNExceptionsRef.current) {
      this.topNExceptionsRef.current.closeInfoSideBar();
    }
  }

  renderButtonApplyFilter() {
    return (
      <Button
        onClick={this.refreshAllCharts}
        id="refresh-btn"
        title={t('refresh')}
        className="btn-secondary"
        startIcon={<IconRefresh />}
        data-trackid="refresh-failed-test-result-dashboard"
        data-groupid={GroupEvent.ACCESS_REPORT}
      >
        {t('refresh')}
      </Button>
    );
  }

  renderFilter() {
    const { filterDateTimeValue } = this.state;
    return (
      <div className="d-flex mt-2">
        <div className="d-flex flex-wrap">
          <div className="d-flex align-items-center mr-3 mb-2">
            <div className="mr-3">{t('show-data')}</div>
            <Select
              id="result-filter"
              useAutocomplete
              options={optionsResultFilter}
              disableClearable
              blurOnSelect
              defaultValue={optionsResultFilter[0]}
              className="filter-test-results ml-2"
              onChange={this.handleSelectChange}
            />
          </div>
          <DateRangePicker value={filterDateTimeValue} handleChange={this.handleDateFilterChange} />
        </div>
        <div className="ml-auto">
          {this.renderButtonApplyFilter()}
        </div>
      </div>
    );
  }

  renderFailedTestResultChart() {
    const { filterDateTimeValue, select } = this.state;
    const defaultSearchCondition =
      getDefaultTestResultSearchConditions(...Object.values(filterDateTimeValue));

    return (
      <FailedTestResultChart
        dataLoaderRef={this.failedTestResultChartRef}
        selectedOption={select}
        customControls={this.renderFilter}
        filterDateValue={filterDateTimeValue}
        defaultSearchCondition={defaultSearchCondition}
      />
    );
  }

  renderTopNExceptionTable() {
    const { filterDateTimeValue } = this.state;
    return (
      <TopNExceptionsDataTable
        ref={this.topNExceptionsRef}
        numberOfExceptions={10}
        dataLoaderRef={this.topNExceptionsDataTableRef}
        allFilterValue={filterDateTimeValue}
      />
    );
  }

  renderBody() {
    return (
      <Card className="failed-test-result-card">
        <CardBody>
          {this.renderFilter()}
          {this.renderFailedTestResultChart()}
          {this.renderTopNExceptionTable()}
        </CardBody>
      </Card>
    );
  }

  render() {
    return this.renderBody();
  }

}

export default withPremiumFeature(FailedTestResults, { feature: FeatureNames.RCA_EXECUTION });
