// /images/cards/VISA@2x-disabled.png
import activeVISA from './../../../images/cards/VISA/Active/Active-VISA@2x.png';
import disableVISA from './../../../images/cards/VISA/Inactive/Inactive-VISA@2x.png';
import activeJCB from './../../../images/cards/JCB/Active/Active-JCB@2x.png';
import disableJCB from './../../../images/cards/JCB/Inactive/Inactive-JCB@2x.png';
import activeMastercard from './../../../images/cards/Master Card/Active/Active-Mastercard@2x.png';
import disableMastercard from './../../../images/cards/Master Card/Inactive/Inactive-Mastercard@2x.png';
import activeDinerClub from './../../../images/cards/Diner Club/Active/Active-Diner-Club@2x.png';
import disableDinerClub from './../../../images/cards/Diner Club/Inactive/Inactive-Diner-Club@2x.png';
import activeDiscover from './../../../images/cards/Discover/Active/Active-Discover@2x.png';
import disableDiscover from './../../../images/cards/Discover/Inactive/Inactive-Discover@2x.png';
import activeAmericanExpress from './../../../images/cards/American Express/Active/Active-American Express@2x.png';
import disableAmericanExpress from './../../../images/cards/American Express/Inactive/Inactive-American Express@2x.png';
import activeUnionPay from './../../../images/cards/Union Pay/Active/Active-Union@2x.png';
import disableUnionPay from './../../../images/cards/Union Pay/Inactive/Inactive-Union@2x.png';

export default {
  visa: {
    title: 'Visa',
    id: 'visa',
    imageUrl: activeVISA,
    imageDisableUrl: disableVISA,
  },
  jcb: {
    title: 'JCB',
    id: 'jcb',
    imageUrl: activeJCB,
    imageDisableUrl: disableJCB,
  },
  masterCard: {
    title: 'Master Card',
    id: 'mastercard',
    imageUrl: activeMastercard,
    imageDisableUrl: disableMastercard,
  },
  dinerClub: {
    title: 'Diner Club',
    id: 'diners',
    imageUrl: activeDinerClub,
    imageDisableUrl: disableDinerClub,
  },
  discover: {
    title: 'Discover',
    id: 'discover',
    imageUrl: activeDiscover,
    imageDisableUrl: disableDiscover,
  },
  americanExpress: {
    title: 'American Express',
    id: 'amex',
    imageUrl: activeAmericanExpress,
    imageDisableUrl: disableAmericanExpress,
  },
  unionPay: {
    title: 'UnionPay',
    id: 'unionpay',
    imageUrl: activeUnionPay,
    imageDisableUrl: disableUnionPay,
  },
};
