import React from 'react';
import {
  Form,
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
} from 'reactstrap';
import ReactMarkdown from 'react-markdown';
import SimpleMDEReact from 'react-simplemde-editor';
import Services from '../../utils/Services';
import Notification from '../../utils/Notification';
import { buildSearchCondition } from '../search/SearchUtils';
import MAuth from '../../models/MAuth';
import Comment from './Comment';
import MTableColumnDataMapping from '../../components/table/models/MTableColumnDataMapping';
import { t } from '../../i18n/t';
import { IconEdit } from '../../images/CustomIcon';
import { next } from '../../utils/Count';
import ImageProfile from '../avatar/ImageProfile';
import GroupEvent from '../../utils/track/GroupEvent';

class CommentComponent extends React.Component {

  constructor(props) {
    super(props);
    this.objectId = this.props.objectId;
    this.email = MAuth.email;
    this.currentUserAvatar = MAuth.user.avatar;
    this.currentUserName = MAuth.user.fullName;
    this.state = {};
    this.renderEditTag = this.renderEditTag.bind(this);
    this.submitButton = React.createRef();
    this.lastChangeComment = next();
  }

  onEditClicked(comment) {
    this.setState({
      editing: true,
      content: comment.content,
      id: comment.id,
    });
  }

  renderEditTag(comment) {
    return (
      MAuth.isLoggedIn && comment.email === this.email &&
      <a
        href="#"
        onClick={(e) => {
          e.preventDefault();
          this.onEditClicked(comment);
        }}
      >
        <IconEdit /> {t('comments#edit')}
      </a>
    );
  }

  onCommentContentChange(content) {
    this.setState({
      content,
    });
  }

  onSubmitClicked(event, objectId) {
    event.preventDefault();
    const { id, content } = this.state;
    const { projectId, objectType, teamId } = this.props;
    const email = this.email;

    const params = {
      id,
      objectId,
      content,
      projectId,
      objectType,
      teamId,
      email,
    };

    if (this.state.editing) {
      Services.updateComment(params)
        .then(() => {
          Notification.pushSuccess(`Comment was updated by user ${email}.`);
          this.lastChangeComment = next();
          this.setState(
            {
              editing: false,
              content: '',
            },
            () => this.commentTable.refreshData(),
          );
        });
    } else {
      Services.createComment(params)
        .then(() => {
          Notification.pushSuccess(`Comment was created by user ${email}.`);
          this.lastChangeComment = next();
          this.commentTable.refreshData();
          this.setState({
            content: '',
          });
        });
    }
  }

  renderCreateComment() {
    const { content } = this.state;
    const { objectType } = this.props;
    return (
      <>
        <Form
          className="create-comment-form"
          data-trackid="add-comment"
          data-tracklabel={objectType}
          data-groupid={GroupEvent.ACCESS_REPORT}
          onSubmit={(e) => this.onSubmitClicked(e, this.objectId)}
        >
          <Row className="w-100 mx-0">
            <Col className="create-comment-form__comment-preview custom-caret">
              <ReactMarkdown source={content} />
            </Col>
          </Row>
          <Row className="w-100 mx-0">
            <Col
              className="create-comment-form__user-avatar comment__avatar
                px-0 mb-3 mb-md-0 mr-0 mr-md-3 d-none d-md-block"
              sm="12"
              md="auto"
            >
              <ImageProfile
                imgUrl={this.currentUserAvatar}
                name={this.currentUserName}
                diameter={50}
              />
            </Col>
            <Col
              className="create-comment-form__comment-input
                px-0 mb-3 mb-md-0 mr-0 mr-md-3"
              xs="12"
              md="auto"
            >
              <SimpleMDEReact
                value={content}
                key={this.lastChangeComment}
                onChange={(e) => this.onCommentContentChange(e)}
                options={{
                  toolbar: ['bold', 'italic', 'heading', '|',
                    'quote', 'unordered-list', 'ordered-list', '|',
                    'link', 'image', '|',
                    'guide'],
                }}
              />
            </Col>
            <Col
              className="create-comment-form__action-buttons px-0 mt-2"
              sm="12"
              md="auto"
            >
              <Button
                type="submit"
                color="primary"
                ref={this.submitButton}
                disabled={!content}
              >{t('submit')}
              </Button>
            </Col>
          </Row>
        </Form>
      </>
    );
  }

  render() {
    const { objectId } = this.props;
    const { objectType } = this.props;
    const columnMapping = [
      new MTableColumnDataMapping(
        'Content',
        'content',
      ),
      new MTableColumnDataMapping(
        'Team ID',
        'teamId',
      ),
      new MTableColumnDataMapping(
        'Created by',
        'email',
      ),
    ];
    return (
      <Card>
        <CardHeader>
          {t('comments#title')}
        </CardHeader>
        <CardBody>
          <Comment
            ref={(ref) => {
              this.commentTable = ref;
            }}
            noCard
            renderEditTag={this.renderEditTag}
            tableId="Comments"
            entityType="Comment"
            columnMapping={columnMapping}
            disableFilterButton
            defaultSearchConditions={[
              (objectId && buildSearchCondition('objectId', '=', objectId)),
              (objectType && buildSearchCondition('objectType', '=', objectType)),
            ]}
          />
          {this.renderCreateComment()}
        </CardBody>
      </Card>
    );
  }

}

export default CommentComponent;
