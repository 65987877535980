import React from 'react';
import { Card, CardBody, CardHeader, Col, Form, FormGroup, Label, Row } from 'reactstrap';
import Input from '../../components/Input';
import PageComponent from '../../components/PageComponent';
import { t } from '../../i18n/t';
import MContext from '../../models/MContext';
import Services from '../../utils/Services';
import { NO_AVAILABLE_MESSAGE } from '../../utils/Constants';
import { isEmpty } from 'lodash';

class OrganizationTestCloudLicensesTab extends PageComponent {
  constructor(props) {
    super(props);
    this.meta.id = 'page-organization-test-cloud-licenses';
    this.meta.title = t('test_cloud_license_keys');
    this.organizationId = MContext.organizationId;
    this.state = {
      testCloudLicenses: {},
    };
  }

  componentDidMount() {
    Services.getTestCloudLicenses(this.organizationId)
      .then((testCloudLicenses) => this.setState({ testCloudLicenses }));
  }

  getTestingTimeMessage(testingTime, testingTimeLeft, unlimitedTestingTime) {
    if (unlimitedTestingTime) {
      return t('test_cloud_license_keys#subscription#unlimited-testing-time')
    }
    return `${testingTime != null ? testingTime : NO_AVAILABLE_MESSAGE} minutes (${ testingTimeLeft != null ? testingTimeLeft : NO_AVAILABLE_MESSAGE } mins left)`
  }

  getParallelTestsMessage(parallelTests, unlimitedParallelTests) {
    if (unlimitedParallelTests) {
      return t('test_cloud_license_keys#subscription#unlimited-parallel-tests')
    }
    return parallelTests != null ? parallelTests : NO_AVAILABLE_MESSAGE
  }

  render() {
    const { testCloudLicenses } = this.state;
    const { parallelTests , testingTime , testingTimeLeft, unlimitedTestingTime, unlimitedParallelTests } = testCloudLicenses;
    return (
      <>
      { !isEmpty(testCloudLicenses) &&
        <Card>
        <CardHeader>{t('license_keys#quota_header')}</CardHeader>
        <CardBody>
          <Row>
            <Col sm="12" md="8" lg="6" xl="5">
              <Form data-trackid="floating-engine-license-key-quota">
                <FormGroup>
                  <Label htmlFor="quota">{t('test_cloud_license_keys#subscription#parallel-tests')}</Label>
                  <Input plaintext>{this.getParallelTestsMessage(parallelTests, unlimitedParallelTests)}</Input>
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="quota">{t('test_cloud_license_keys#subscription#testing-time')}</Label>
                  <Input plaintext>{this.getTestingTimeMessage(testingTime, testingTimeLeft, unlimitedTestingTime)}</Input>
                </FormGroup>
              </Form>
            </Col>
          </Row>
        </CardBody>
      </Card> }
      </>
    );
  }
}

export default OrganizationTestCloudLicensesTab;
