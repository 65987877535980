import React from 'react';
import { CommonFailures } from '../../../models/model/CommonFailures';
import { CollapseCardType } from '../../../utils/ConstantsType';
import DrawerAIResponse from '../../../components/DrawerAIResponse';
import { VIRTUAL_DATA_QUESTIONS } from '../../../utils/Constants';
import { verticalDivider } from '../../execution/ExecutionDecorator';
import DecoratorConstants from '../../../utils/DecoratorConstants';
import MTableColumnDataMapping from '../../../components/table/models/MTableColumnDataMapping';
import TableSortCore from '../../../components/table/models/TableSortCore';
import { t } from '../../../i18n/t';
import MetricComponent from './MetricComponent';
import { Failure } from '../../../models/model/Failure';
import CommonFailureAIBlock from './CommonFailureAIBlock';

interface DrawerAICommonFailuresProps {
  isOpen: boolean;
  closeDrawer: () => void;
}

function DrawerAICommonFailures(props: DrawerAICommonFailuresProps) {
  const { isOpen, closeDrawer } = props;

  const buildCommonFailuresData = (commonFailures: CommonFailures): CollapseCardType[] => {

    const commonErrorLast7DaysHeader = () => {
      const impactedTests = DecoratorConstants.getIconAndType(commonFailures.impactedTestsTrend, false);
      const errorCount = DecoratorConstants.getIconAndType(commonFailures.errorCountTrend, false);
      return (
        <div className="d-flex">
          <MetricComponent
            icon={impactedTests.icon}
            title={t('total-impacted-tests')}
            label={
              <div className="metric-normal-label">
                {commonFailures.totalImpactedTests}
              </div>
            }
            subLabel={commonFailures.impactedTestsTrend}
            type={impactedTests.type}
            tooltip={t('total-impacted-tests-tooltip')}
          />
          {verticalDivider('6rem', '2.1rem')}
          <MetricComponent
            icon={errorCount.icon}
            title={t('total-error-count')}
            label={
              <div className="metric-normal-label">
                {commonFailures.totalErrorCount}
              </div>
            }
            subLabel={commonFailures.errorCountTrend}
            type={errorCount.type}
            tooltip={t('total-error-count-tooltip')}
          />
        </div>
      );
    };

    const commonErrorLast7DaysContent = () => {
      const data = commonFailures.failures;
      const columnMapping = [
        new MTableColumnDataMapping(
          t('most-common-errors'),
          '',
          (name: string, row: Failure) => DecoratorConstants.mostCommonErrorDecorator(name, row),
          undefined,
          'fit-content-column',
          undefined,
          '50%'
        ),
        new MTableColumnDataMapping(
          t('impacted-tests'),
          'totalImpactedTests'
        ),
        new MTableColumnDataMapping(
          t('error-count'),
          'totalErrorCount'
        ),
      ];

      const underRowComponent = {
        component: CommonFailureAIBlock,
        props: {
          totalImpactedTests: commonFailures.totalImpactedTests,
          totalErrorCount: commonFailures.totalErrorCount,
          enableAi: commonFailures.enableAi,
        }
      };

      return <TableSortCore className="drawer-card-table" data={data} columnMapping={columnMapping} underRowComponent={underRowComponent} />;
    };

    const commonFailuresDrawerData = [
      {
        title: t('common-errors-in-past-7-days'),
        header: commonErrorLast7DaysHeader(),
        content: commonErrorLast7DaysContent(),
        disableCollapse: true,
        id: 'common-errors-last-7-days'
      },
    ];

    return commonFailuresDrawerData;
  };

  return (
    <DrawerAIResponse
      isOpen={isOpen}
      closeDrawer={closeDrawer}
      buildData={buildCommonFailuresData}
      question={VIRTUAL_DATA_QUESTIONS.COMMON_FAILURES.value}
      promptType={VIRTUAL_DATA_QUESTIONS.COMMON_FAILURES.promptType}
      title={VIRTUAL_DATA_QUESTIONS.COMMON_FAILURES.title}
    />
  );
}

export default DrawerAICommonFailures;
