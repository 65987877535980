import React from 'react';
import { Button } from 'reactstrap';
import { t } from '../../i18n/t';
import DocumentLink from '../../utils/DocumentLink';
import Routes from '../../utils/Routes';

import JiraReleasesImg from '../../../images/docs/jira-releases.png';
import JiraRequirementsImg from '../../../images/docs/jira-requirements.png';
import JiraDefectsImg from '../../../images/docs/jira-defects.png';
import FeatureHighlight from '../gallery/FeatureHighlight';

const features = [
  {
    source: JiraReleasesImg,
    caption: 'Jira releases',
    fileType: 'png',
    name: t('releases'),
    description: t('jira-releases-description'),
    learnMore: DocumentLink.JIRA_RELEASES
  },
  {
    source: JiraRequirementsImg,
    caption: 'Jira requirements',
    fileType: 'png',
    name: t('requirements'),
    description: t('jira-requirements-description'),
    learnMore: DocumentLink.JIRA_REQUIREMENTS
  },
  {
    source: JiraDefectsImg,
    caption: 'Jira defects',
    fileType: 'png',
    name: t('defects'),
    description: t('jira-defects-description'),
    learnMore: DocumentLink.JIRA_DEFECTS
  }
];

function JiraIntegrationFeatureHighlight() {

  const routes = new Routes();

  return (
    <>
      <div className="text-center pb-4">
        <h2>
          {t('jira-integration')}
        </h2>
        <h5 className="text-gray">
          {t('jira-integration-description')}
        </h5>
      </div>
      <FeatureHighlight features={features} />
      <div className="text-center">
        <Button color="primary" href={routes.jira_setting_link}>{t('jira-integration#enable')}</Button>
      </div>
    </>
  );
}

export default JiraIntegrationFeatureHighlight;
