import React, { ReactNode } from 'react';
import Button from '@katalon-studio/katalon-ui/Button';
import { ThemeProvider } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import classnames from 'classnames';
import MContext from '../../models/MContext';
import { t } from '../../i18n/t';
import { KatalonStudioIntegrationIcon } from '../../images/CustomNewIcon';
import { katalonui_theme } from '../../katalonui-theme';
import { theme } from '../../theme';
import DecoratorConstants from '../../utils/DecoratorConstants';
import DocumentLink from '../../utils/DocumentLink';
import TooltipComponent from '../../components/TooltipComponent';
import Services from '../../utils/Services';

interface KatalonPluginBtnProps {
  testRunId: number;
  disabled: boolean;
}

function KatalonPluginBtn(props: Readonly<KatalonPluginBtnProps>) {
  const { testRunId, disabled } = props;

  const projectId = MContext.projectId;

  const getCreateUrl = async () => Services.getJiraCreateUrl(projectId, testRunId);

  const createIssue = async () => {
    try {
      const { url, katalonScriptUrl } = await getCreateUrl();

      if (url) {
        window.open(url, btoa(`katalon=${katalonScriptUrl}`));
      }
    } catch (error) {
      console.error('Error getting Jira url: ', error);
    }
  };

  const buildKatalonPluginTooltip = (children: ReactNode, content: string) => (
    DecoratorConstants.buildButtonLinkTooltip(
      children,
      content,
      <>
        {t('learn_more')}
        <OpenInNewIcon className="ml-2 fixed-1rem-icon" />
      </>,
      DocumentLink.KATALON_PLUGIN,
    )
  );

  const buildKatalonPluginNavigateTooltip = (children: ReactNode, content: string) => (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <TooltipComponent text={content} customClasses placement="bottom">
      <div>
        {children}
      </div>
    </TooltipComponent>
  );

  const buildButtonWithTooltip = (buttonContent: ReactNode) => (
    disabled
      ? buildKatalonPluginTooltip(buttonContent, t('jira-integration-add-on-new'))
      : buildKatalonPluginNavigateTooltip(buttonContent, t('jira-navigate'))
  );

  const curTheme = disabled ? theme : katalonui_theme;

  return (
    <ThemeProvider theme={curTheme}>
      {buildButtonWithTooltip(
        <Button
          type="button"
          color="secondary"
          variant="text"
          size="medium"
          onClick={createIssue}
          disabled={disabled}
        >
          <KatalonStudioIntegrationIcon className={classnames('katalon-plugin-svg', disabled && 'opacity-40')} />
          <span>{t('katalon-plugin')}</span>
        </Button>
      )}
    </ThemeProvider>
  );
}

export default KatalonPluginBtn;
