import React from 'react';
import { t } from '../../i18n/t';
import UploadExecutionStep2Img from '../../../images/upload-execution-step-2.png';
import PropTypes from 'prop-types';

class AutomaticallyUploadReport extends React.Component {

  render() {
    const { hideHeader, style } = this.props;
    return (
      <>
        {!hideHeader && (
          <div className={style}>
            {t('report#automaticUpload')}
          </div>
        )}
        <p>
          Test reports can also be uploaded automatically after executing a Test Suite or Test Suite Collection.
        </p>
        <p>
          In Katalon Studio, go to&nbsp;
          <strong>
            Project &gt; Settings &gt; Integration &gt; Katalon TestOps
          </strong>
          &nbsp;and check the&nbsp;
          <strong>
            Automatically submit test run result
          </strong>
          &nbsp;option.
        </p>
        <div className="img-quick-guide">
          <img
            className="img-fluid img-thumbnail"
            alt="Automatically Upload Report"
            src={UploadExecutionStep2Img}
          />
        </div>
      </>
    );
  }
}

AutomaticallyUploadReport.propTypes = {
  style: PropTypes.string,
};

AutomaticallyUploadReport.defaultProps = {
  style: 'katalon-studio-title',
};


export default AutomaticallyUploadReport;
