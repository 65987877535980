import React from 'react';
import { Col, Card, CardBody } from 'reactstrap';
import { isNumber, isNaN, isEmpty } from 'lodash';
import { ListItem } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import Icon from '@katalon-studio/katalon-ui/Icon';
import DefaultLayout from '../components/DefaultLayout';
import PageComponent from '../components/PageComponent';
import { t } from '../i18n/t';
import MContext from '../models/MContext';
import TreeView from '../components/treeview/TreeView';
import { buildSearchCondition } from '../components/search/SearchUtils';
import TestCaseDataTableV2 from '../components/table/TestCaseDataTableV2';
import CreateTestCaseButton from '../components/cloud-studio-component/testcase/CreateTestCaseButton';
import {
  AI_TEST_GENERATION_FOLDER,
  FolderType,
  KATALON_EVENTS,
  SearchEntity,
  SubFoldersLevel,
  TestFolderType,
  TestProjectType
} from '../utils/Constants';
import Routes from '../utils/Routes';
import DataLoader from '../components/table/DataLoader';
import Services from '../utils/Services';
import PageHistoryHelper, { PageHistoryComponents } from '../utils/PageHistoryHelper';
import Notification from '../utils/Notification';
import ResizingDirectoryView from '../components/resizing_directory_view/ResizingDirectoryView';
import MFlags from '../models/MFlags';
import ConvertDataHelper from '../utils/ConvertDataHelper';
import PopoverDropdownMenu from '../components/action/PopoverDropdownMenu';
import TreeNodeTitle from '../components/treeview/TreeNodeTitle';
import UrlHelper from '../utils/UrlHelper';
import Link from '../components/Link';
import MoveToLocationDialog from '../components/cloud-studio-component/testcase/MoveToLocationDialog';
import { DomEventHandlers } from '../utils/EventHandler';
import { katalonui_theme } from '../katalonui-theme';
import { next } from '../utils/Count';
import RenameDialog from '../components/cloud-studio-component/testcase/RenameDialog';
import TestCaseService from '../components/cloud-studio-component/service/TestCaseService';
import MigrationDialog from '../components/cloud-studio-component/MigrationDialog';

class TestCasesV2 extends PageComponent {
  constructor(props) {
    super(props);

    this.meta.id = 'page-test-cases';
    this.project = MContext.project;
    this.projectId = MContext.projectId;
    this.meta.title = t('Project {{name}} - Test Cases', { name: this.project.name });
    this.renderBody = this.renderBody.bind(this);
    this.renderTreeView = this.renderTreeView.bind(this);
    this.onFolderSelected = this.onFolderSelected.bind(this);
    this.enableBrowserHistoryTree = true;
    this.loadData = this.loadData.bind(this);
    this.renderFolderTitle = this.renderFolderTitle.bind(this);
    this.refreshTSC = this.refreshTSC.bind(this);
    this.extractKeys = this.extractKeys.bind(this);
    this.getFolderByNameAndTestProjectId = this.getFolderByNameAndTestProjectId.bind(this);
    this.closeMigrationDialog = this.closeMigrationDialog.bind(this);
    this.state = {
      currentFolderId: null,
      isReady: false,
      scriptRepos: [],
      testProjectId: !isNaN(UrlHelper.getSearchParam('testProjectId')) ? Number(UrlHelper.getSearchParam('testProjectId')) : null,
      isFromE2E: (String(UrlHelper.getSearchParam('fromE2e')).toLowerCase() === 'true'),
      testGenFolder: AI_TEST_GENERATION_FOLDER,
      modifiedTestCase: null,
      showMigrationDialog: false,
    };
    this.directoryWidth = 0;
  }

  loadData(key) {
    return Services.getSubFolders(key, SubFoldersLevel.NUMBER_OF_LEVEL);
  }

  renderMessageEmptyTestFolders() {
    const routes = new Routes();
    return (
      <div className="empty-test-folders-message">
        <p> Get started by
          <Link href={routes.import_manual_reports_link} className="ml-1 mr-1">
            {t('uploading-your-data')}
          </Link>
          or
          <Link href={routes.test_project_create_git_link} className="ml-1">
            {t('setting-up-script-repository')}
          </Link>
        </p>
      </div>
    );
  }

  /**
   * Root folder is not contain test cases data
   * and have the key with String prefix 'root-*' tend to break API
   * So I will exclude this key and replace by -1
   * When user click to root folder, the UI will clean Data and do not show error
   */
  onFolderSelected(folder) {
    const folderName = folder[0];
    if (
      folderName &&
      isNumber(folderName)
    ) {
      this.setState({ currentFolderId: folderName });
    } else {
      this.setState({ currentFolderId: -1 });
    }
  }

  refreshTSC(testProjectId) {
    Services.refreshTSC(testProjectId)
      .then(() => {
        Notification.pushSuccess(t('tree-view#refresh-test-case-noti'));
      });
  }

  directoryWidthOnChange = (directoryWidth) => {
    this.directoryWidth = directoryWidth;
  };

  renderFolderTitle(nodeData) {
    const isScriptRepoRootFolder = ConvertDataHelper.isScriptRepoRootFolder(nodeData);
    const items = [
      (
        <ListItem
          button
          component="button"
          onClick={() => this.refreshTSC(nodeData.testProjectId)}
        >
          {t('refresh')}
        </ListItem>
      )
    ];
    return (
      <>
        <TreeNodeTitle id={nodeData.key} width={this.directoryWidth} title={nodeData.title} key={this.directoryWidth} />
        {isScriptRepoRootFolder && <PopoverDropdownMenu items={items} />}
      </>
    );
  }

  extractKeys(obj, expandedKeys = []) {
    if (obj?.key) {
      expandedKeys.push(obj.key);
    }
    if (obj?.children) {
      for (const child of obj.children) {
        this.extractKeys(child, expandedKeys);
      }
    }
    return expandedKeys;
  }

  renderCreateNewTestButton() {
    return (
      <CreateTestCaseButton
        sx={{
          marginBottom: '14px',
          '&.MuiButton-root': {
            whiteSpace: 'nowrap',
          },
        }}
        size="medium"
        variant="contained"
        color="primary"
        startIcon={
          <Icon
            type="fa-solid"
            name="fa-plus"
            textSize="16px"
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              i: {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              },
            }}
          />
        }
        label="add_test_case"
      />
    );
  }

  renderTreeView(data) {
    const { scriptRepos, testProjectId, testGenFolder, isFromE2E, modifiedTestCase } = this.state;
    const objectRootFolder = ConvertDataHelper.buildRootDataTree(data, scriptRepos);

    const dataRootFolder = [...objectRootFolder.dataRootFolder];

    let selectedScriptRepo = {};
    let expandedKeys = [];

    if (isFromE2E) {
      selectedScriptRepo = objectRootFolder?.dataRootFolder.filter((item) => item.testProjectId === testProjectId)[0];

      expandedKeys = this.extractKeys(selectedScriptRepo, []);
    }
    return (
      <Col xs="auto" className="scroll-container">
        {MFlags.g5Editor && this.renderCreateNewTestButton()}
        <TreeView
          key={modifiedTestCase}
          className="tree-view-components"
          data={dataRootFolder}
          defaultSelectedKeys={[FolderType.ROOT_UPLOADED]}
          onSelect={this.onFolderSelected}
          loadData={this.loadData}
          convertSubFoldersData={this.convertSubFoldersData}
          enableHistory={this.enableBrowserHistoryTree}
          componentName={PageHistoryComponents.TESTCASE_TREE_VIEW}
          titleRender={
            (nodeData) =>
              (MFlags.onCloudTestStorageEnabled
                ? ConvertDataHelper.renderFolderTitle(nodeData, this.directoryWidth)
                : this.renderFolderTitle(nodeData))
          }
          navigateDirectSubFolder={isEmpty(expandedKeys) ? null : { expandedKeys, folderTitle: testGenFolder }}
        />
        {objectRootFolder.isEmpty && this.renderMessageEmptyTestFolders()}
      </Col>
    );
  }

  folderTree() {
    const { isReady } = this.state;
    if (!isReady) {
      return null;
    }
    return (
      <DataLoader
        noCard
        entityType="TestFolder"
        pageSize={300}
        fetchAllPages
        render={this.renderTreeView}
        defaultSort={['id,asc']}
        defaultSearchConditions={[
          buildSearchCondition('TestFolder.id', 'is null', ''),
          buildSearchCondition('type', '=', TestFolderType.TEST_CASE)
        ]}
      />
    );
  }

  goToTestCaseSearchPage(searchParams) {
    Routes.goToTestCaseSearchPage(searchParams);
  }

  renderDataTable(currentFolderId) {
    const defaultSearchConditions = [
      buildSearchCondition('TestFolder.id', '=', currentFolderId || '-1'),
    ];
    const defaultSort = ['updatedAt, desc NULLS LAST'];
    const tcNameTrackingProps = {
      'data-trackid': 'test-case-detail',
    };
    const conditionProps = {
      defaultSearchConditions,
      defaultSort
    };

    return (
      <Col className="scroll-container">
        <TestCaseDataTableV2
          key={currentFolderId} // not to change key while user apply filter
          tcNameTrackingProps={tcNameTrackingProps}
          noCard
          goToResultPage={this.goToTestCaseSearchPage}
          {...conditionProps}
          isSupportMultipleSelect
        />
      </Col>
    );
  }

  getFolderByNameAndTestProjectId(testProjectId) {
    const { testGenFolder } = this.state;
    const params = {
      pagination: {
        page: 0,
        size: 1,
        sorts: ['id,desc'],
      },
      conditions: [
        {
          key: 'name',
          operator: '=',
          value: testGenFolder,
        },
        {
          key: 'TestProject.id',
          operator: '=',
          value: testProjectId,
        }
      ],
      type: SearchEntity.TestFolder,
    };
    Services.search(params).then(({ content }) => {
      if (content.length > 0) {
        const { id } = content[0];
        this.setState({ currentFolderId: id });
      }
    });
  }

  closeMigrationDialog() {
    this.setState({
      showMigrationDialog: false,
    });
  }

  componentDidMount() {
    const { testProjectId, isFromE2E } = this.state;

    DomEventHandlers.eventListener(KATALON_EVENTS.movedG5TestCase, ({ detail }) => {
      this.handleAfterMoveTestCaseSuccess(detail.publishedTestCases);
    });

    ConvertDataHelper.getScriptRepos(this.projectId)
      .then((response) => {
        this.setState({
          scriptRepos: response,
          isReady: true,
        });

        // migrate to Katalon Cloud - G5
        const cloudScriptRepos = response.find((el) => el.type === TestProjectType.CLOUD);
        if (cloudScriptRepos) {
          TestCaseService.migrateDraftTestCaseToKatalonCloud(cloudScriptRepos.id)
            .then((isMigrated) => {
              this.setState({
                showMigrationDialog: isMigrated,
              });
            })
            .catch(() => {
            /** ignore */
            });
        }
      });

    if (isFromE2E && testProjectId) {
      this.getFolderByNameAndTestProjectId(testProjectId);
    } else if (this.enableBrowserHistoryTree) {
      const treeState = PageHistoryHelper.getState(PageHistoryComponents.TESTCASE_TREE_VIEW);
      if (treeState) {
        const { selectedKeys } = treeState;
        if (selectedKeys && selectedKeys.length > 0) {
          const selectedKey = selectedKeys[0];
          if (isNumber(selectedKey)) {
            this.setState({ currentFolderId: selectedKey });
          }
        }
      }
    }
  }

  handleAfterMoveTestCaseSuccess = (publishedTestCases) => {
    const testCase = publishedTestCases[0];

    Services.getTestCaseById(testCase.id)
      .then((publishedTestCase) => {
        if (publishedTestCase?.testFolder && publishedTestCase.testProject) {
          this.expandKeysAndUpdateHistory(
            publishedTestCase.testFolder,
            publishedTestCase.testProject.id,
            publishedTestCase.id
          );
        }
      })
      .catch(() => {});
  };

  expandKeysAndUpdateHistory(testFolder, testProjectId) {
    PageHistoryHelper.updateHistory(testFolder, testProjectId, PageHistoryComponents.TESTCASE_TREE_VIEW);
    this.setState({
      currentFolderId: testFolder.id,
      currentTestProjectId: testProjectId,
      modifiedTestCase: next(),
    });
  }

  renderBody() {
    const { currentFolderId, showMigrationDialog } = this.state;

    return (
      <Card className="tree-view-card">
        <CardBody className="tree-view-card-body row">
          <ResizingDirectoryView directoryWidthOnChange={this.directoryWidthOnChange}>
            {this.folderTree()}
          </ResizingDirectoryView>
          {this.renderDataTable(currentFolderId)}
          <ThemeProvider theme={katalonui_theme}>
            <MoveToLocationDialog />
            <RenameDialog />
            <MigrationDialog open={showMigrationDialog} onClose={this.closeMigrationDialog} />
          </ThemeProvider>
        </CardBody>
      </Card>
    );
  }

  render() {
    return (
      <DefaultLayout
        renderBody={this.renderBody}
      />
    );
  }
}

export default TestCasesV2;
