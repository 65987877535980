import React from 'react';
import { Button, Row, Col, Form, FormGroup, Card, CardBody, Label } from 'reactstrap';
import PageComponent from '../../components/PageComponent';
import { t } from '../../i18n/t';
import Input from '../../components/Input';
import MContext from '../../models/MContext';
import MAuth from '../../models/MAuth';
import CreateLicenseKeyDialog from '../../components/dialog/CreateLicenseKeyDialog';
import DefaultLayout from '../../components/DefaultLayout';
import ObjectSummary from '../../components/summary/ObjectSummary';
import Routes from '../../utils/Routes';

class UserCreateLicenseKey extends PageComponent {
  constructor(props) {
    super(props);
    this.meta.id = 'page-user-create-licenses';
    this.meta.title = t('license_keys');
    this.licenseFeature = MContext.licenseFeature.toUpperCase();

    this.createLicenseKeyDialogRef = React.createRef();

    this.handleInputChange = this.handleInputChange.bind(this);
    this.createLicenseKey = this.createLicenseKey.bind(this);
    this.onCreatingLicenseKey = this.onCreatingLicenseKey.bind(this);
    this.onCreatingDoneLicenseKey = this.onCreatingDoneLicenseKey.bind(this);
    this.onCreatingSuccess = this.onCreatingSuccess.bind(this);
    this.renderBody = this.renderBody.bind(this);
    this.renderHeader = this.renderHeader.bind(this);

    this.state = this.getDefaultState();
  }

  getDefaultState() {
    let disabledInput;
    switch (this.licenseFeature) {
      case 'KSE':
        disabledInput = !MAuth.canCreateOfflineKSE;
        break;
      case 'ENGINE':
        disabledInput = !MAuth.canCreateOfflineRE;
        break;
      default:
        disabledInput = false;
    }
    return {
      disabledInput,
      newLicenseKey: {
        machineId: '',
        number: 1,
      }
    };

  }

  createLicenseKey(e) {
    e.preventDefault();
    this.createLicenseKeyDialogRef.current.toggle();
  }

  onCreatingLicenseKey() {
    this.setState({ disabledInput: true });
  }

  onCreatingDoneLicenseKey() {
    this.setState(this.getDefaultState());
  }

  onCreatingSuccess() {
    Routes.gotoUserLicensesPage();
  }

  handleInputChange(event) {
    const value = event.target.value;
    const name = event.target.name;
    this.setState((prevState) => ({
      newLicenseKey: {
        ...prevState.newLicenseKey,
        [name]: value
      }
    }));
  }

  renderCreateLicenseKeyDialog() {
    const { newLicenseKey } = this.state;
    const licenseType = 'TRIAL';
    const feature = this.licenseFeature;
    return <CreateLicenseKeyDialog
      ref={this.createLicenseKeyDialogRef}
      licenseKey={newLicenseKey}
      organizationId={this.organizationId}
      type={licenseType}
      feature={feature}
      onCreating={this.onCreatingLicenseKey}
      onCreatingDone={this.onCreatingDoneLicenseKey}
      onCreatingSuccess={this.onCreatingSuccess}
    />;
  }

  renderBody() {
    const { newLicenseKey, disabledInput } = this.state;
    const { machineId } = newLicenseKey;

    return (
      <>
        <Card>
          <CardBody>
            <Row>
              <Col sm="12" md="8" lg="6" xl="5">
                <Form data-trackid="create-user-license-key" onSubmit={this.createLicenseKey}>
                  <FormGroup>
                    <Label htmlFor="machine-id">{t('license_keys#machine_id')}</Label>
                    <Input
                      id="machine-id"
                      name="machineId"
                      disabled={disabledInput}
                      value={machineId}
                      onChange={this.handleInputChange}
                      type="text"
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Button
                      id="create-license-key"
                      type="submit"
                      color="primary"
                      disabled={disabledInput}
                    >{t('create')}
                    </Button>
                  </FormGroup>
                </Form>
              </Col>
            </Row>
          </CardBody>
        </Card>
        {this.renderCreateLicenseKeyDialog()}
      </>
    );
  }

  renderHeader() {
    const urlParams = {
      license_keys: t('license_keys'),
    };
    if (this.licenseFeature === 'KSE') {
      urlParams.new_user_license = t('license_keys#create_kse_license_key');
    } else {
      urlParams.new_user_license = t('license_keys#create_engine_license_key');
    }
    return (
      <ObjectSummary params={urlParams} />
    );
  }

  render() {
    return (
      <DefaultLayout
        renderHeader={this.renderHeader}
        renderBody={this.renderBody}
      />
    );
  }
}

export default UserCreateLicenseKey;
