import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Autocomplete from '@mui/material/Autocomplete';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import Services from '../utils/Services';
import TextField from './TextField';
import Arrays from '../utils/Arrays';
import DecoratorConstants from '../utils/DecoratorConstants';
import { SearchEntity } from '../utils/Constants';
import MFlags from '../models/MFlags';

function XrayIssueInput(props) {

  const [value, setValue] = useState(props.value || '');
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (props.value) {
      setValue(props.value);
    }
  }, [props.value]);

  const getSuggestion = (value) => {
    const { mapCustomizedXrayIssueTypeEnabled } = MFlags;

    if (!value || value.length < 3) {
      return;
    }

    const { xrayIssueType, testOpsObjectType } = props;

    const params = {
      pagination: {
        page: 0,
        size: 10,
        sorts: ['id,desc'],
      },
      conditions: [
        {
          key: 'issue',
          operator: '=',
          value,
        },
        ...Arrays.insertIf(!mapCustomizedXrayIssueTypeEnabled && xrayIssueType, {
          key: 'type',
          operator: '=',
          value: xrayIssueType,
        }),
        ...Arrays.insertIf(mapCustomizedXrayIssueTypeEnabled && testOpsObjectType, {
          key: 'testOpsObjectType',
          operator: '=',
          value: testOpsObjectType,
        }),
      ],
      type: SearchEntity.JiraIssue,
    };
    Services.search(params)
      .then(({ content, pageable }) => {
        const options = content.slice(pageable.offset, pageable.pageSize);
        setOptions(options);
      });
  };

  const throttledGetSuggestion = _.throttle(getSuggestion, 1000);

  const inputText = (event, newValue) => {
    setValue(newValue);
    throttledGetSuggestion(newValue);
    if (props.handleIssueInputChange) {
      props.handleIssueInputChange(newValue);
    }
  };

  const { placeholder } = props;

  return (
    <Autocomplete
      freeSolo
      disableClearable
      clearOnEscape
      className="flex-grow-1"
      options={options}
      getOptionLabel={(option) => option.issueId || ''}
      filterOptions={_.identity}
      renderInput={(params) => <TextField {...params} placeholder={placeholder} />}
      renderOption={(props, option) => (
        <li {...props}>
          <Grid container>
            <Grid item xs={12}>
              <div className="d-flex align-items-center">
                {option.issueTypeIcon &&
                <span className="mr-2 d-flex align-items-center">
                  <img
                    src={DecoratorConstants.getIssueIconUrl(option.issueTypeIcon, option.issueTypeName)}
                    alt=""
                    className="img-fluid icon-issue-type"
                  />
                </span>}
                <Typography variant="button" color="textPrimary">
                  {option.issueId}
                </Typography>
              </div>
            </Grid>
            <Grid>
              <Typography variant="subtitle1" color="textSecondary">
                {option.summary}
              </Typography>
            </Grid>
          </Grid>
        </li>
      )}
      inputValue={value}
      onInputChange={inputText}
    />
  );
}

export default XrayIssueInput;

XrayIssueInput.propTypes = {
  value: PropTypes.string,
  xrayIssueType: PropTypes.string,
  testOpsObjectType: PropTypes.string,
  placeholder: PropTypes.string,
};

XrayIssueInput.defaultProps = {
  value: ''
};
