import Icon from '@katalon-studio/katalon-ui/Icon';
import React from 'react';
import colors from '../../../scss/colors.scss';

function CopyIcon({ color, textSize, type }) {
  return <Icon
    type={type}
    name="fa-copy"
    textSize={textSize}
    sx={{
      color,
    }}
    boundingSize={null}
    color={null}
  />;
}

CopyIcon.defaultProps = {
  color: colors.gray9,
  textSize: '14px',
  type: 'fa-regular',
};

export default CopyIcon;
