import { Button } from '@mui/material';
import React, { ReactNode } from 'react';
import { t } from '../i18n/t';
import { IconArrowRight } from '../images/CustomIcon';

interface ShowMoreBoxComponentProps {
  content: ReactNode;
  link: string;
  dataTrackId?: string;
  dataTrackGroup?: string;
}

function ShowMoreBoxComponent(props: ShowMoreBoxComponentProps) {
  const { content, link, dataTrackId, dataTrackGroup } = props;

  const handleClick = () => {
    window.open(link, '_blank');
  };

  return (
    <>
      {content}
      <div className="d-flex align-items-end p-1">
        <Button
          className="collapse-button"
          onClick={handleClick}
          data-trackid={dataTrackId}
          data-groupid={dataTrackGroup}
        >
          <span className="mr-2">{t('show-more')}</span>
          <IconArrowRight />
        </Button>
      </div>
    </>
  );
}

export default ShowMoreBoxComponent;
