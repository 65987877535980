import React, { useState } from 'react';
import { t } from '../../../i18n/t';
import MContext from '../../../models/MContext';
import { MAX_PAGE_SIZE, SearchEntity } from '../../../utils/Constants';
import DecoratorConstants from '../../../utils/DecoratorConstants';
import Services from '../../../utils/Services';
import { buildSearchCondition } from '../../search/SearchUtils';
import MultiSelectFilterComponent from './MultiSelectFilterComponent';

function JiraPriorityFilterV2(props) {

  const [multiOptions, setMultiOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isFirstOpen, setFirstOpen] = useState(true);

  const getOptions = () => {
    const projectId = MContext.projectId;
    setLoading(true);
    const params = {
      pagination: {
        page: 0,
        size: MAX_PAGE_SIZE,
        sorts: ['optionOrder,asc'],
      },
      conditions: [
        buildSearchCondition('Project.id', '=', projectId)
      ],
      type: SearchEntity.ExternalPriority,
    };
    Services.search(params)
      .then(({ content }) => {

        const multiOptions = content.map((item) => {
          const { name } = item;
          return { priority: name, externalPriority: item };
        });
        setMultiOptions(multiOptions);
        setLoading(false);
        setFirstOpen(false);
      });
  };

  const onOpen = () => {
    if (isFirstOpen) {
      getOptions();
    }
  };

  const renderOption = (option) => DecoratorConstants.defectPriorityDecorator(option);

  return (
    <MultiSelectFilterComponent
      multiOptions={multiOptions}
      renderOption={renderOption}
      loading={loading}
      onOpen={onOpen}
      searchKey="priority"
      label={t('jira-filter#priority-label')}
      {...props}
    />
  );
}

export default JiraPriorityFilterV2;
