

import React from 'react';
import PropTypes from 'prop-types';
import { Doughnut } from 'react-chartjs-2';
import StatusConstants from '../../utils/Statuses';

class ExecutionStatusPieChart extends React.Component {

  chartData(execution) {
    return {
      datasets: [{
        data: [
          execution.totalPassedTests,
          execution.totalFailedTests,
          execution.totalErrorTests,
          execution.totalIncompleteTests,
        ],
        backgroundColor: [
          StatusConstants.PASSED.color,
          StatusConstants.FAILED.color,
          StatusConstants.ERROR.color,
          StatusConstants.INCOMPLETE.color,
        ],
      }],
      labels: [
        StatusConstants.PASSED.label,
        StatusConstants.FAILED.label,
        StatusConstants.ERROR.label,
        StatusConstants.INCOMPLETE.label,
      ],
    };
  }

  render() {
    const chartOptions = {
      animation: false,
      legend: {
        position: 'right',
      },
      tooltips: {
        callbacks: {
          label(tooltipItem, data) {
            const dataset = data.datasets[tooltipItem.datasetIndex];
            const total = dataset.data.reduce((previousValue, currentValue) => previousValue + currentValue);
            const currentValue = dataset.data[tooltipItem.index];
            const label = data.labels[tooltipItem.index];
            const percentage = ((currentValue / total) * 100).toFixed(2);

            return `${label}: ${percentage} %`;
          },
        },
      },
    };
    return <Doughnut data={this.chartData(this.props.execution)} options={chartOptions} />;
  }
}

ExecutionStatusPieChart.propTypes = {
  execution: PropTypes.object.isRequired,
};

export default ExecutionStatusPieChart;
