import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import {
  KATALON_TEST_CASE_ROOT_FOLDER,
  SubFoldersLevel, TEST_CASE_NODE_KEY_PREFIX, TEST_TYPE, TestFolderType,
  TEST_TYPE_TRACKING_NAME
} from '../../../utils/Constants';
import TreeSelectView from '../../treeview/TreeSelectView';
import Services from '../../../utils/Services';
import { t } from '../../../i18n/t';
import { buildSearchCondition } from '../../search/SearchUtils';
import DataLoader from '../../table/DataLoader';
import TreeViewHelper from '../../../utils/treeview/TreeViewHelper';
import { sendAnalyticEventForAction } from '../../../utils/SegmentAnalytics';
import MFlags from '../../../models/MFlags';

function SelectTestCaseTreeView(props) {
  const { testCaseType, testProjectId, addLinkingTestCases, addBtnTrackingId, clearBtnTrackingId } = props;

  const loadData = (key) => (MFlags.addTestCaseToTestSuiteEnabled
    ? Services.getAllSubFoldersAndItsTestEntities(key, SubFoldersLevel.NUMBER_OF_LEVEL)
    : Services.getAllSubFoldersAndItsTestCases(key, SubFoldersLevel.NUMBER_OF_LEVEL));

  const handleClear = () => {
    if (clearBtnTrackingId) {
      sendAnalyticEventForAction(clearBtnTrackingId, { 'test-case-type': TEST_TYPE_TRACKING_NAME[testCaseType] });
    }
  };

  const handleAddTestCases = (selectedKeys, treeData) => {
    const selectedTestCaseKeys = selectedKeys.filter((key) => String(key).startsWith(TEST_CASE_NODE_KEY_PREFIX));
    const selectedTestCases = TreeViewHelper.findTreeNodes(treeData, selectedTestCaseKeys).map((node) => node.info);
    addLinkingTestCases(selectedTestCases);
    if (addBtnTrackingId) {
      sendAnalyticEventForAction(addBtnTrackingId, {
        'test-case-type': TEST_TYPE_TRACKING_NAME[testCaseType],
        'no-selected-test-case': selectedTestCases.length
      });
    }
  };

  const getSearchNameCondition = () => {
    if (testCaseType === TEST_TYPE.G5_TEST_CASE) {
      return KATALON_TEST_CASE_ROOT_FOLDER.G5_TEST_CASE_FOLDER_TITLE;
    } else if (testCaseType === TEST_TYPE.G4_TEST_CASE) {
      return KATALON_TEST_CASE_ROOT_FOLDER.G4_TEST_CASE_FOLDER_TITLE;
    }
    return '';
  };

  const selectedNodeCondition = (key) => String(key).startsWith(TEST_CASE_NODE_KEY_PREFIX);

  const onSelect = (key) => (MFlags.addTestCaseToTestSuiteEnabled
    ? Services.getAllSubFoldersAndItsTestEntities(key)
    : Services.getAllSubFoldersAndItsTestCases(key));

  const renderTree = (data) => {
    const treeData = TreeViewHelper.buildRootFolder(data);
    const expandedKeys = isEmpty(treeData) ? [] : [treeData[0].key];
    return (
      <TreeSelectView
        loadData={loadData}
        data={treeData}
        expandedKeys={expandedKeys}
        handleAdd={handleAddTestCases}
        placeholder={t('select-test-case-tree#placeholder')}
        onSelect={onSelect}
        selectedNodeCondition={selectedNodeCondition}
        handleClear={handleClear}
      />
    );
  };

  if (!testProjectId) {
    return null;
  }

  return (
    <DataLoader
      noCard
      entityType="TestFolder"
      pageSize={1}
      fetchAllPages
      render={renderTree}
      defaultSort={['id,asc']}
      defaultSearchConditions={[
        buildSearchCondition('TestFolder.id', 'is null', ''),
        buildSearchCondition('type', '=', TestFolderType.TEST_CASE),
        buildSearchCondition('TestProject.id', '=', testProjectId),
        buildSearchCondition('name', '=', getSearchNameCondition())
      ]}
    />
  );
}

SelectTestCaseTreeView.propTypes = {
  testCaseType: PropTypes.string,
  testProjectId: PropTypes.number,
  addLinkingTestCases: PropTypes.func,
};

SelectTestCaseTreeView.defaultProps = {
  testCaseType: TEST_TYPE.G4_TEST_CASE,
};

export default SelectTestCaseTreeView;
