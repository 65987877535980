import React from 'react';
import { DialogContent, DialogContentText } from '@mui/material';
import ReactMarkdown from 'react-markdown';
import CloseableDialogComponent from '../../../components/dialog/CloseableDialogComponent';
import { t } from '../../../i18n/t';

interface AIBlockDialogProps {
  isOpen: boolean,
  onClose: () => void,
  text: string,
}

function AIBlockDialog(props: AIBlockDialogProps) {
  const { isOpen, onClose, text } = props;

  return (
    <CloseableDialogComponent
      maxWidth="md"
      isOpen={isOpen}
      handleClose={onClose}
      title={t('ai-insights')}
    >
      <DialogContent className="ai-block-dialog">
        <DialogContentText>
          <div className="ai-block-dialog-text">
            <ReactMarkdown>{text}</ReactMarkdown>
          </div>
        </DialogContentText>
      </DialogContent>
    </CloseableDialogComponent>
  );
}

export default AIBlockDialog;
