import React, { Component } from 'react';
import { buildSearchCondition, buildSearchFunction } from '../../search/SearchUtils';
import DataLoader from '../../../components/table/DataLoader';
import MContext from '../../../models/MContext';
import { OSDetector } from '../../../utils/Environment';
import StatusBarChart from './StatusBarChart';
import StatusBarInfo from './StatusBarInfo';
import GrayCard from '../../GrayCard';
import MFlags from '../../../models/MFlags';
import Arrays from '../../../utils/Arrays';

class AgentStatusBar extends Component {
  constructor(props) {
    super(props);
    this.teamId = MContext.teamId;
    this.organizationId = MContext.organizationId;
    this.isOrganizationScope = !!MContext.organizationId;
    this.renderActiveStatus = this.renderActiveStatus.bind(this);
    this.renderOS = this.renderOS.bind(this);
  }

  renderActiveStatus(data) {
    const statuses = {
      active: 0,
      inactive: 0,
    };

    let total = 0;
    data.forEach((item) => {
      const { active, count } = item.content;
      statuses[active ? 'active' : 'inactive'] = count;
      total += count;
    });

    const counterItems = [
      {
        key: `total-${total}`,
        value: total,
        label: 'total',
      },
      {
        key: `online-${statuses.active}`,
        value: statuses.active,
        label: 'online',
      },
      {
        key: `offline-${statuses.inactive}`,
        value: statuses.inactive,
        label: 'offline',
      },
    ];

    return (
      <StatusBarInfo
        data={counterItems}
      />
    );
  }

  renderOS(data) {
    const os = {
      windows: 0,
      linux: 0,
      mac: 0,
      other: 0,
    };

    data.forEach((item) => {
      const osName = item.content.os;
      const count = item.content.count;
      if (OSDetector.isWindows(osName)) {
        os.windows += count;
      } else if (OSDetector.isLinux(osName)) {
        os.linux += count;
      } else if (OSDetector.isMacOS(osName)) {
        os.mac += count;
      } else {
        os.other += count;
      }
    });

    const items = ['windows', 'mac', 'linux', 'other'].reduce((prevItems, osName) => {
      const count = os[osName];
      if (count <= 0) {
        return prevItems;
      }
      return [
        ...prevItems,
        {
          key: `${osName}-${count}`,
          value: count,
          label: osName,
          color: osName,
        },
      ];
    }, []);

    return (
      <StatusBarChart
        data={items}
      />
    );
  }

  render() {
    const defaultSearchConditions = [
      ...Arrays.insertIf(!MFlags.moveAgentToOrgLevelPhase2Enabled, buildSearchCondition('Team.id', '=', this.teamId)),
      ...Arrays.insertIf(MFlags.moveAgentToOrgLevelPhase2Enabled && this.isOrganizationScope, buildSearchCondition('Organization.id', '=', this.organizationId))
    ];

    return (
      <GrayCard>
        <div className="status-bar-v2">
          <DataLoader
            render={this.renderActiveStatus}
            entityType="Agent"
            defaultSearchConditions={defaultSearchConditions}
            defaultSearchFunctions={[
              buildSearchFunction('count', 'COUNT', ['id']),
            ]}
            defaultSort={['active,desc']}
            groupBys={['active']}
            noCard
          />
          <DataLoader
            render={this.renderOS}
            entityType="Agent"
            defaultSearchConditions={defaultSearchConditions}
            defaultSearchFunctions={[
              buildSearchFunction('count', 'COUNT', ['id']),
            ]}
            defaultSort={['os,desc']}
            groupBys={['os']}
            noCard
          />
        </div>
      </GrayCard>
    );
  }
}

export default AgentStatusBar;
