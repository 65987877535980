import React from 'react';
import { Col, Card, CardBody } from 'reactstrap';
import { isNaN } from 'lodash';
import { IconButton } from '@mui/material';
import DefaultLayout from '../../components/DefaultLayout';
import PageComponent from '../../components/PageComponent';
import ObjectSummary from '../../components/summary/ObjectSummary';
import MContext from '../../models/MContext';
import Services from '../../utils/Services';
import BaselineRevisions from './component/BaselineRevisions';
import { BaselineCollectionDecorator } from './BaselineCollectionDecorator';
import BaselineImagesView from './component/BaselineImagesView';
import { t } from '../../i18n/t';
import UrlHelper from '../../utils/UrlHelper';
import { IconArrowLeft } from '../../images/CustomIcon';
import Routes from '../../utils/Routes';

class BaselineCollectionGroupVersionInformation extends PageComponent {
  constructor(props) {
    super(props);
    this.meta.id = 'page-visual-testing-baseline-version-history';
    this.meta.title = t('baseline-collection-group-history#title', { order: MContext.baselineCollectionGroupOrder });
    this.baselineCollectionGroupOrder = MContext.baselineCollectionGroupOrder;
    this.state = {
      baselineCollectionGroup: null,
      baselineCollectionId: null,
      isLoading: false,
      baselineCollectionVersionParam: !isNaN(UrlHelper.getSearchParam('v')) ? Number(UrlHelper.getSearchParam('v')) : null
    };
    this.renderHeader = this.renderHeader.bind(this);
    this.renderBody = this.renderBody.bind(this);
    this.handleSelectVersion = this.handleSelectVersion.bind(this);
    this.goToDirectVersionPage = this.goToDirectVersionPage.bind(this);
  }

  componentDidMount() {
    const { baselineCollectionVersionParam } = this.state;
    this.setState({ isLoading: true });
    Services.getBaselineCollectionGroup(this.baselineCollectionGroupOrder)
      .then(({ content }) => {
        // Check existed baseline collection group then set group detail
        if (content.length !== 0) {
          const baselineCollectionGroup = content[0];
          let baselineCollectionId = baselineCollectionGroup.latestVersion ? baselineCollectionGroup.latestVersion.id : null;
          // Check if url version exist then get baseline collection by groupId and verion
          if (baselineCollectionVersionParam > 0) {
            Services.getBaselineCollectionByBaselineCollectionGroupIdAndVersion(baselineCollectionGroup.id, baselineCollectionVersionParam)
              .then((response) => {
                const extractedContent = response.content;
                // If existed baseline collection then update baseline collection id else use latest version and update query param
                if (extractedContent.length !== 0) {
                  baselineCollectionId = extractedContent[0]?.id;
                  this.setState({ baselineCollectionId });
                } else {
                  this.handleSelectVersion(baselineCollectionGroup.latestVersion);
                }
              });
          } else {
            this.handleSelectVersion(baselineCollectionGroup.latestVersion);
          }
          this.setState({ baselineCollectionGroup });
        }
      }).finally(() => this.setState({ isLoading: false }));
  }

  goToDirectVersionPage(versionId) {
    UrlHelper.setUrlParamsAndUpdate([{ paramName: 'v', value: versionId }]);
  }

  renderBackButton() {
    const route = new Routes({ baselineCollectionGroupOrder: this.baselineCollectionGroupOrder });
    return (
      <div className="back-button">
        <IconButton href={route.baseline_collection_group_detail_link} size="large">
          <IconArrowLeft />
        </IconButton>
      </div>
    );
  }

  renderObjectSummary() {
    const { baselineCollectionGroup, baselineCollectionVersionParam } = this.state;
    const urlParams = {
      baselineInformationGroupVersion:
        BaselineCollectionDecorator.versionDecorator(baselineCollectionVersionParam || baselineCollectionGroup.latestVersion?.version),
    };

    return (
      <div className="header-with-back-icon">
        {this.renderBackButton()}
        <ObjectSummary params={urlParams} />
      </div>
    );
  }

  handleSelectVersion(baselineCollection) {
    this.goToDirectVersionPage(baselineCollection.version);
    this.setState({
      baselineCollectionId: baselineCollection.id,
      baselineCollectionVersionParam: baselineCollection.version
    });
  }

  renderHeader() {
    const { isLoading } = this.state;
    if (!isLoading) {
      return (
        <>
          {this.renderObjectSummary()}
        </>
      );
    }
    return null;
  }

  renderImagesView() {
    const { baselineCollectionId } = this.state;
    if (baselineCollectionId) {
      return (
        <BaselineImagesView
          key={baselineCollectionId}
          baselineCollectionId={baselineCollectionId}
        />
      );
    }
    return null;
  }

  renderBody() {
    const { baselineCollectionGroup, baselineCollectionVersionParam } = this.state;
    return (
      <Card className="detail-card">
        <CardBody className="detail-card-body row">
          <Col>
            {this.renderImagesView()}
          </Col>
          <Col xs="auto" className="detail-fixed-column">
            <BaselineRevisions
              baselineCollectionVersionParam={baselineCollectionVersionParam}
              handleSelectVersion={this.handleSelectVersion}
              baseLineCollectionGroup={baselineCollectionGroup}
            />
          </Col>
        </CardBody>
      </Card>
    );
  }

  render() {
    const { baselineCollectionGroup } = this.state;
    if (baselineCollectionGroup) {
      return (
        <DefaultLayout
          renderHeader={this.renderHeader}
          renderBody={this.renderBody}
        />
      );
    }
    return null;
  }
}

export default BaselineCollectionGroupVersionInformation;
