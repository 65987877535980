import React, { useState, useEffect } from 'react';
import { Button, Card, CardBody, CardHeader, Col, Form, FormGroup, Label, Row } from 'reactstrap';
import { t } from '../../i18n/t';
import MContext from '../../models/MContext';
import Services from '../../utils/Services';
import Notification from '../../utils/Notification';
import Input from '../../components/Input';

const LicenseServerConfiguration = () => {
  const organizationId = MContext.organizationId;
  const [licenseServerUrl, setLicenseServerUrl] = useState('');

  //Temporarily unused
  // const onLicenseServerUrlChange = (event) => {
  //   event.preventDefault();
  //   setLicenseServerUrl(event.target.value);
  // };

  useEffect(() => {
    Services.getLicenseServerConfiguration(organizationId)
      .then((configuration) => setLicenseServerUrl(configuration.licenseServerUrl));
  }, []);

  //Temporarily unused function onSubmit()
  // const onSubmit = (event) => {
  //   event.preventDefault();
  //   Services.updateLicenseServerConfiguration(organizationId, { licenseServerUrl }).then((configuration) => {
  //     setLicenseServerUrl(configuration.licenseServerUrl);
  //     Notification.pushSuccess('Update successfully');
  //   });
  // };

  return (
    <Card>
      <CardHeader>{t('licenseServer#location')}</CardHeader>
      <CardBody>
        <Row>
          <Col sm="12" md="8" lg="6" xl="5">
            <Form data-trackid="update-testOps-configuration">
              <FormGroup>
                <Label for="testOpsUrl">{t('url')}</Label>
                <Input
                  id="licenseServerUrl"
                  name="licenseServerUrl"
                  plaintext
                >
                  {licenseServerUrl}
                </Input>
              </FormGroup>
              {/*Temporarily unused*/}
              {/*<FormGroup>*/}
              {/*  <Button type="submit" color="primary">{t('update')}</Button>*/}
              {/*</FormGroup>*/}
            </Form>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default LicenseServerConfiguration;
