import React from 'react';
import { IconStatus } from '../images/KitIcons';

/**
 * Show scale of color by IconStatus.
 * Input an array of colors and number of color we want to scale.
 * example: colors = [
 * colors.newFailed,
 * colors.darkOrange400,
 * colors.darkOrange200,
 * colors.yellow300,
 * colors.darkGreen200,
 * colors.darkGreen400,
 * colors.newPassed
 * ]
 * Input two text from the beginning and the end of the scale component.
 * Example:
 * beginningText = 'Start'
 * endText = 'End'
 * @param {Array} colors
 * @param {String} beginningText
 * @param {String} endText
 * @returns
 */
const ColorScales = ({ colors, beginningText, endText }) => {


  const colorScale = colors.map((color) => <IconStatus className="m-1" key={color} fill={color} />);
  return (
    <div className="d-flex justify-content-end">
      {beginningText} {colorScale} {endText}
    </div>
  );
};

export default ColorScales;
