import { DialogActions, DialogContent } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Button, Label, Form, FormGroup } from 'reactstrap';
import CloseableDialogComponent from './CloseableDialogComponent';
import { t } from '../../i18n/t';
import Input from '../Input';
import GroupEvent from '../../utils/track/GroupEvent';
import Select from '../Select';
import {
  getTestProjects,
} from '../../pages/plan/ConfigurePlanUtils';
import Services from '../../utils/Services';
import TooltipComponent from '../TooltipComponent';
import { buildSearchCondition } from '../search/SearchUtils';
import { TestProjectType, KATALON_STUDIO_SUPPORTED_TEST_PROJECT_TYPE } from '../../utils/Constants';
import MFlags from '../../models/MFlags';
import TestCaseLinkingWithTreeView from '../cloud-studio-component/testsuite/TestCaseLinkingWithTreeView';
import ConvertDataHelper from '../../utils/ConvertDataHelper';
import { sendAnalyticEventForAction } from '../../utils/SegmentAnalytics';
import TestProjectHelper from '../../utils/TestProjectHelper';

const CreateNewTestSuiteDialog = ({ id, isOpen, handleClose, currentTestProjectId, handleAfterCreateTestSuite }) => {

  const [ready, setReady] = useState(false);
  const [testSuiteName, setTestSuiteName] = useState('');
  const [selectedScriptRepositoryId, setSelectedScriptRepositoryId] = useState();
  const [scriptRepositoryOptions, setScriptRepositoryOptions] = useState([]);
  const [selectedLinkingTestCases, setSelectedLinkingTestCases] = useState([]);

  /**
   * Check exist object by using test project id with property id in an array object list repo options
   * @param testProjectId: number
   * @param scriptRepositoryOptions: array object
   * @return boolean
   */
  const isCurrentSelectedScriptRepoExist = (testProjectId, scriptRepositoryOptions) => !!scriptRepositoryOptions.find(({ id }) => id === Number(testProjectId));

  const generateScriptRepositoryOptions = (scriptRepositories) => {
    const scriptRepositoryOptions = scriptRepositories.map((scriptRepository) => ({
      ...scriptRepository,
      value: scriptRepository.id,
      label: scriptRepository.name,
      icon: ConvertDataHelper.renderRepositoryIcon(scriptRepository.type),
    }));
    setScriptRepositoryOptions(scriptRepositoryOptions);
    if (isCurrentSelectedScriptRepoExist(currentTestProjectId, scriptRepositoryOptions)) {
      setSelectedScriptRepositoryId(currentTestProjectId);
    } else {
      setSelectedScriptRepositoryId(scriptRepositoryOptions[0]?.id);
    }
  };

  const getScriptRepositories = () => {
    const defaultSearchConditions = [buildSearchCondition('type', 'in', KATALON_STUDIO_SUPPORTED_TEST_PROJECT_TYPE.toString())];
    getTestProjects(defaultSearchConditions)
      .then((scriptRepositories) => {
        generateScriptRepositoryOptions(scriptRepositories);
      })
      .then(() => setReady(true));
  };

  useEffect(() => {
    getScriptRepositories();
  }, []);

  const renderToolBar = () => {
    const hasTestCases = selectedLinkingTestCases.length > 0;
    return (
      <DialogActions>
        <Button
          id="close-schedule-dialog"
          title={t('cancel')}
          color="secondary"
          onClick={handleClose}
          data-trackid="cancel-create-test-suite"
          data-groupid={GroupEvent.ACCESS_TEST_MANAGEMENT}
        >
          {t('cancel')}
        </Button>
        <Button
          disabled={!hasTestCases}
          id="create-schedule"
          title={t('create')}
          color="primary"
          type="submit"
        >
          {t('create')}
        </Button>
      </DialogActions>
    );
  };

  const trackCloseIcon = {
    'data-trackid': 'close-create-test-suite',
    'data-groupid': GroupEvent.ACCESS_TEST_MANAGEMENT,
  };

  const handleOnTestSuiteNameChange = (event) => {
    const name = event.target.value;
    setTestSuiteName(name);
  };

  const renderInputTestSuiteName = () => (
    <FormGroup>
      <Label for="name" className="mt-0">{t('test_suite_name')}</Label>
      <Input
        required
        id="name"
        name="name"
        type="text"
        placeholder={t('schedule#place-holder-name')}
        onChange={handleOnTestSuiteNameChange}
      />
    </FormGroup>
  );

  const onScriptRepositorySelected = (scriptRepository) => {
    setSelectedScriptRepositoryId(scriptRepository.id);
    setSelectedLinkingTestCases([]);
  };

  const renderScriptRepositorySelector = () => {
    const { g5Editor } = MFlags;
    const hasTestCases = selectedLinkingTestCases.length > 0;
    const textTooltip = hasTestCases ? t('test-project#not-select-another-script-repo') : '';
    return (
      <FormGroup className={`${!g5Editor && 'w-50'}`}>
        <Label for="script-repository-selector">{t('test-project')}</Label>
        <TooltipComponent text={textTooltip} placement="bottom">
          <div>
            <Select
              required
              type="text"
              id="script-repository-selector"
              key={selectedScriptRepositoryId}
              value={selectedScriptRepositoryId}
              onChange={onScriptRepositorySelected}
              options={scriptRepositoryOptions}
              isDisabled={scriptRepositoryOptions.length < 1 || hasTestCases}
              hasOverflowParentDiaglog={document.getElementById(id)}
              isCustom={true}
            />
          </div>
        </TooltipComponent>
      </FormGroup>
    );
  };

  const renderTestCaseLinkingComponent = () => {
    const testProject = scriptRepositoryOptions.find((option) => option.id === selectedScriptRepositoryId);
    const trackingProps = {
      addButton: 'add-selected-test-cases-in-test-suite-creation',
      clearButton: 'clear-selected-test-cases-in-test-suite-creation',
      removeTestCaseIcon: 'delete-selected-test-cases-in-test-suite-creation',
    };
    return (
      <TestCaseLinkingWithTreeView
        key={selectedScriptRepositoryId}
        setSelectedLinkingTestCases={setSelectedLinkingTestCases}
        selectedLinkingTestCases={selectedLinkingTestCases}
        testProject={testProject}
        isAbridgedVersion
        trackingProps={trackingProps}
      />
    );
  };

  const onFormSubmit = (e) => {
    e.preventDefault();
    const currentScriptRepository = scriptRepositoryOptions.find((scriptRepo) => scriptRepo.id === selectedScriptRepositoryId);
    sendAnalyticEventForAction(
      'create-katalon-studio-ts',
      {
        'data-groupid': GroupEvent.ACCESS_TEST_MANAGEMENT,
        storage_type: currentScriptRepository && TestProjectHelper.getStorageType(currentScriptRepository),
      }
    );
    const testSuite = {
      name: testSuiteName,
      testProject: {
        id: selectedScriptRepositoryId,
      },
      testCases: selectedLinkingTestCases
    };
    Services.createTestSuite(testSuite)
      .then((res) => {
        if (handleAfterCreateTestSuite) {
          handleAfterCreateTestSuite(res);
        } else {
          handleClose();
        }
      });
  };

  const renderDialog = () => (
    <CloseableDialogComponent
      id={id}
      isOpen={isOpen}
      maxWidth={MFlags.g5Editor ? 'sm' : 'md'}
      handleClose={handleClose}
      title={t('add_test_suite#title')}
      trackCloseIcon={trackCloseIcon}
    >
      <Form onSubmit={onFormSubmit}>
        <DialogContent className="py-0">
          {renderInputTestSuiteName()}
          {renderScriptRepositorySelector()}
          {renderTestCaseLinkingComponent()}
        </DialogContent>
        {renderToolBar()}
      </Form>
    </CloseableDialogComponent>
  );
  return ready && renderDialog();
};

export default CreateNewTestSuiteDialog;
