import { Button, FormGroup, FormText, Label } from 'reactstrap';
import React, { useEffect, useState } from 'react';
import { Box, Tab, Tabs } from '@mui/material';
import { Controller } from 'react-hook-form';
import { isEmpty } from 'lodash';
import { t } from '../../../i18n/t';
import Input from '../../Input';
import Select from '../../Select';
import TabPanel from '../../TabPanel';
import TsRunConfigurationContainer from './TsRunConfigurationContainer';
import TscRunConfigurationContainer from './TscRunConfigurationContainer';
import { ConfigType } from '../../../utils/Constants';
import AdvancedSettingsComponent from './AdvancedSettingsComponent';
import Routes from '../../../utils/Routes';
import KatalonCommandContainer from './KatalonCommandContainer';
import GenericCommandContainer from './GenericCommandContainer';
import WhenToRun from './WhenToRun';
import MFlags from '../../../models/MFlags';
import Link from '../../Link';

const ConfigTypeMapping = [ConfigType.TS, ConfigType.TSC, ConfigType.COMMAND, ConfigType.GENERIC_COMMAND];

export default function ScheduleTestRun(props) {
  const {
    runConfiguration,
    scheduler,
    testProjects,
    executionEnvironments,
    releases,
    ksVersions,
    baselineCollectionGroups,
    advanceMode,
    isXrayFeaturesEnabled,
    form,
    trackId,
    handleRepeatToggle,
    handleChangeTab,
    isSaveConfiguration,
    isReady,
  } = props;

  const { control, setValue } = form;
  const routes = new Routes();

  const [selectedTab, setSelectedTab] = useState(0);
  const [scheduleTypeValidation, setScheduleTypeValidation] = useState(0);
  const [testProject, setTestProject] = useState({});

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
    setScheduleTypeValidation(newValue);
    setValue('runConfiguration.configType.value', ConfigTypeMapping[newValue]);
    handleChangeTab(ConfigTypeMapping[newValue]);
  };

  useEffect(() => {
    if (runConfiguration) {
      const { testProject, configType } = runConfiguration;
      setTestProject(testProject);
      if (configType.value) {
        const index = ConfigTypeMapping.findIndex((ct) => ct === runConfiguration.configType.value);
        setSelectedTab(index >= 0 ? index : 0);
        setScheduleTypeValidation(index >= 0 ? index : 0);
      }
      setValue('runConfiguration.ksVersions', ksVersions || []);
    }
  }, [runConfiguration]);

  return (
    <>
      <div style={{ display: (!advanceMode ? 'block' : 'none') }}>
        <FormGroup className="d-flex">
          <Label className="col-2 test-run-label" for="name">{t('test-run-name')}</Label>
          <div className="p-0 col-10">
            <Controller
              name="runConfiguration.name"
              control={control}
              rules={{ required: false }}
              render={({ field }) =>
                <div className="d-flex flex-column flex-grow-1">
                  <Input
                    {...field}
                    required
                    id="name"
                    name="name"
                    type="text"
                    onBlur={(event) => {
                      if (isEmpty(event.target.value)) {
                        event.target.value = t('schedule#default-test-run-name');
                      }
                      field.onChange(event);
                    }}
                    placeHolder={t('schedule#place-holder-name')}
                  />
                </div>}
            />
          </div>
          <Controller
            name="runConfiguration.configType.value"
            control={control}
            rules={{ required: true }}
            defaultValue={ConfigType.TS}
            render={({ field }) =>
              <div className="d-flex flex-column flex-grow-1">
                <Input
                  {...field}
                  required
                  id="configType"
                  name="configType"
                  type="hidden"
                  placeHolder={t('schedule#place-holder-name')}
                />
              </div>}
          />
        </FormGroup>
        <FormGroup className="d-flex">
          <Label className="test-run-label col-2" for="testProjectId">{t('test-project')}</Label>
          <div className="p-0 col-10">
            <Controller
              name="runConfiguration.testProjectId"
              control={control}
              rules={{ required: true }}
              render={({ field, formState: { errors } }) =>
                <div className="d-flex flex-column flex-grow-1">
                  <Select
                    {...field}
                    id="testProjectId"
                    name="testProjectId"
                    required
                    clearable={false}
                    onChange={(testProject) => {
                      setTestProject(testProject);
                      setValue('runConfiguration.testSuiteCollectionId', undefined);
                      field.onChange(testProject.value);
                    }}
                    options={testProjects}
                    isCustom
                  />
                  <FormText>
                    {t('plan#step1#desc')}
                    <Link href={routes.test_project_link}>
                      here
                    </Link>.
                  </FormText>
                  {errors.runConfiguration?.testProjectId && <small className="text-danger fs-6">This is required</small>}
                </div>}
            />
          </div>
        </FormGroup>
        {MFlags.testCloudInstructionCreateGitRepoEnabled && isEmpty(testProjects) && isReady ?
          <>
            <Box className="ml-3 box-border">
              <Box className="pt-3">
                <FormText>
                  {t('git-repository#warning#empty-list-repository')}
                </FormText>
                <Box className="pt-2">
                  <Button
                    style={{ color: '#fff', backgroundColor: '#1847CA' }}
                    title={t('connect_git_script_repository')}
                    onClick={() => Routes.goToGitTestProjectSetup()}
                  >
                    {t('connect_git_script_repository')}
                  </Button>
                  <Button
                    id="learn_more"
                    title={t('learn_more')}
                    color="link"
                    href="https://docs.katalon.com/docs/organize/upload-test-scripts-from-the-git-repository-to-katalon-testops"
                  >
                    {t('learn_more')}
                  </Button>
                </Box>
              </Box>
            </Box>
          </> :
          <>
            <Tabs
              className="tab-active"
              sx={{ borderBottom: 1, borderColor: 'divider' }}
              value={selectedTab}
              onChange={handleChange}
            >
              <Tab label="Test Suite" wrapped />
              <Tab label="Test Suite Collection" wrapped />
              <Tab label="Katalon Command" wrapped />
              <Tab label="Generic Command" wrapped />
            </Tabs>
            <TabPanel value={selectedTab} index={0}>
              <TsRunConfigurationContainer
                trackId={trackId}
                testProject={testProject}
                envOptions={executionEnvironments.data}
                validation={scheduleTypeValidation === 0}
                {...props}
              />
            </TabPanel>
            <TabPanel value={selectedTab} index={1}>
              <TscRunConfigurationContainer
                trackId={trackId}
                testProject={testProject}
                validation={scheduleTypeValidation === 1}
                {...props}
              />
            </TabPanel>
            <TabPanel value={selectedTab} index={2}>
              <KatalonCommandContainer
                runConfiguration={runConfiguration}
                scheduler={scheduler}
                testProject={testProject}
                executionEnvironments={executionEnvironments}
                validation={scheduleTypeValidation === 2}
                form={form}
              />
            </TabPanel>
            <TabPanel value={selectedTab} index={3}>
              <GenericCommandContainer
                runConfiguration={runConfiguration}
                scheduler={scheduler}
                testProject={testProject}
                executionEnvironments={executionEnvironments}
                validation={scheduleTypeValidation === 3}
                form={form}
              />
            </TabPanel>
            <WhenToRun
              runConfiguration={runConfiguration}
              scheduler={scheduler}
              handleRepeatToggle={handleRepeatToggle}
              isSaveConfiguration={isSaveConfiguration}
              form={form}
            />
          </>}
      </div>
      <div style={{ display: (advanceMode ? 'block' : 'none') }}>
        <AdvancedSettingsComponent
          form={form}
          releases={releases}
          ksVersions={ksVersions}
          baselineCollectionGroups={baselineCollectionGroups}
          runConfiguration={runConfiguration}
          isTestSuiteType={selectedTab === 0}
          isXrayFeaturesEnabled={isXrayFeaturesEnabled}
          selectedTab={selectedTab}
        />
      </div>
    </>
  );

}
