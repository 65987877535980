import { LABEL_ENTITY_TYPE } from '../../../utils/Constants';
import { buildSearchCondition } from '../../../components/search/SearchUtils';
import Arrays from '../../../utils/Arrays';

const EXCEPTION_CLASS_REGEX = /(([a-z]+\.)+([a-zA-Z]+){1})(: (.*))*/;
const EXCEPTION_MESSAGE_GROUP = 5;
const EXCEPTION_GROUP = 1;

export const getDefaultTestResultSearchConditions = (startTime, endTime) => {
  const defaultSearchConditions = [
    buildSearchCondition('ExecutionTestResult.startTime', '>=', startTime),
    buildSearchCondition('ExecutionTestResult.startTime', '<=', endTime),
    buildSearchCondition('entityType', '=', LABEL_ENTITY_TYPE.EXECUTION_TEST_RESULT)
  ];
  return defaultSearchConditions;
};

export const getDefaultTestResultExceptionSearchConditions = (startTime, endTime) => {
  const defaultSearchConditions = [
    buildSearchCondition('ExecutionTestResult.startTime', '>=', startTime),
    buildSearchCondition('ExecutionTestResult.startTime', '<=', endTime),
  ];
  return defaultSearchConditions;
};

export const getSearchConditionsForFailedTestResultPage = (startTime, endTime, testSuite, profile, customField, tag, typeFilter, entityType) => {
  const defaultSearchConditions = [
    buildSearchCondition('ExecutionTestResult.startTime', '>=', startTime),
    buildSearchCondition('ExecutionTestResult.startTime', '<=', endTime),
    ...Arrays.insertIf(testSuite, buildSearchCondition('TestSuite.name', '=', testSuite)),
    ...Arrays.insertIf(profile, buildSearchCondition('ExecutionTestResult.profile', '=', profile)),
    ...Arrays.insertIf(typeFilter, buildSearchCondition('TypeFilter', '=', typeFilter)),
    ...Arrays.insertIf(entityType, buildSearchCondition('entityType', '=', entityType)),
  ];

  if (tag) {
    if (Array.isArray(tag)) {
      tag.forEach((tagId) => {
        defaultSearchConditions.push(buildSearchCondition('Tag.id', '=', tagId));
      });
    } else {
      defaultSearchConditions.push(buildSearchCondition('Tag.id', '=', tag));
    }
  }

  return defaultSearchConditions;
};

export const getTestResultSearchConditions_old = (exception, startTime, endTime) => {
  const defaultSearchConditions = [
    buildSearchCondition('ExecutionTestResultException.exception', '=', exception),
    buildSearchCondition('startTime', '>=', startTime),
    buildSearchCondition('startTime', '<=', endTime),
  ];
  return defaultSearchConditions;
};

export const getTestResultSearchConditions = (exception, startTime, endTime, testSuite, profile, customField, tag, typeFilter) => {
  const defaultSearchConditions = [
    buildSearchCondition('ExecutionTestResultException.exception', '=', exception),
    buildSearchCondition('ExecutionTestResult.startTime', '>=', startTime),
    buildSearchCondition('ExecutionTestResult.startTime', '<=', endTime),
    ...Arrays.insertIf(testSuite, buildSearchCondition('TestSuite.name', '=', testSuite)),
    ...Arrays.insertIf(profile, buildSearchCondition('ExecutionTestResult.profile', '=', profile)),
    ...Arrays.insertIf(typeFilter, buildSearchCondition('TypeFilter', '=', typeFilter)),
  ];

  if (tag) {
    if (Array.isArray(tag)) {
      tag.forEach((tagId) => {
        defaultSearchConditions.push(buildSearchCondition('Tag.id', '=', tagId));
      });
    } else {
      defaultSearchConditions.push(buildSearchCondition('Tag.id', '=', tag));
    }
  }

  return defaultSearchConditions;
};

const divideException = (exception) => exception.match(EXCEPTION_CLASS_REGEX) ?? [];

export const getExceptionClass = (exception) => divideException(exception)[EXCEPTION_GROUP] ?? '';

export const getExceptionMessage = (exception) => {
  const exceptionMatch = divideException(exception);
  if (exceptionMatch[EXCEPTION_MESSAGE_GROUP]) {
    return exceptionMatch[EXCEPTION_MESSAGE_GROUP];
  }
  if (!exceptionMatch[EXCEPTION_GROUP]) {
    return exception;
  }
  return '';
};
