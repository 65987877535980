import { IntegrationRootFolderIDs } from '../../../models/model/IntegrationRootFolderIDs';
import { TreeItemEntity } from '../../../models/model/TreeItemEntity';
import { IntegrationType } from '../../../utils/Constants';
import { t } from '../../../i18n/t';
import MFlags from '../../../models/MFlags';
import Arrays from '../../../utils/Arrays';

export default {

  generateIntegrationDataTreeItems(isTeamManager: boolean) : TreeItemEntity[] {
    const { jenkinsIntegrationEnabled, dailyExecutionEmailEnabled, katalonCliIntegrationEnabled } = MFlags;

    return [
      {
        id: IntegrationRootFolderIDs.DEFAULT,
        label: t('all')
      },
      {
        id: IntegrationRootFolderIDs.CODELESS_AUTOMATION_TOOL,
        label: t('integration#codeless-automation-tool'),
        children: [
          {
            id: IntegrationType.KATALON_STUDIO_INTEGRATION.id,
            label: IntegrationType.KATALON_STUDIO_INTEGRATION.label
          }
        ]
      },
      {
        id: IntegrationRootFolderIDs.ALM_TEST_MANAGEMENT,
        label: t('integration#alm-test-management'),
        children: [
          {
            id: IntegrationType.JIRA_INTEGRATION.id,
            label: IntegrationType.JIRA_INTEGRATION.label,
            disabled: !isTeamManager
          }
        ]
      },
      {
        id: IntegrationRootFolderIDs.CI_CD_PINELINE,
        label: t('integration#ci-cd-pineline'),
        children: [
          {
            id: IntegrationType.AWS_CODEBUILD.id,
            label: IntegrationType.AWS_CODEBUILD.label
          },
          {
            id: IntegrationType.AZURE_DEVOPS_PIPELINES.id,
            label: IntegrationType.AZURE_DEVOPS_PIPELINES.label
          },
          {
            id: IntegrationType.CIRCLE_CI.id,
            label: IntegrationType.CIRCLE_CI.label
          },
          {
            id: IntegrationType.CLI.id,
            label: IntegrationType.CLI.label
          },
          {
            id: IntegrationType.DOCKER.id,
            label: IntegrationType.DOCKER.label
          },
          {
            id: IntegrationType.GITHUB_ACTION.id,
            label: IntegrationType.GITHUB_ACTION.label
          },
          {
            id: IntegrationType.GITLAB_CI.id,
            label: IntegrationType.GITLAB_CI.label
          },
          ...Arrays.insertIf(jenkinsIntegrationEnabled, {
            id: IntegrationType.JENKINS.id,
            label: IntegrationType.JENKINS.label
          }),
          ...Arrays.insertIf(katalonCliIntegrationEnabled, {
            id: IntegrationType.KATALON_CLI.id,
            label: IntegrationType.KATALON_CLI.label
          }),
        ]
      },
      {
        id: IntegrationRootFolderIDs.COLLABORATION,
        label: t('integration#collaboration'),
        children: [
          ...Arrays.insertIf(dailyExecutionEmailEnabled, {
            id: IntegrationType.EMAIL_SETTINGS.id,
            label: IntegrationType.EMAIL_SETTINGS.label,
            disabled: !isTeamManager
          }),
          {
            id: IntegrationType.SLACK_INTEGRATION.id,
            label: IntegrationType.SLACK_INTEGRATION.label,
            disabled: !isTeamManager
          },
        ]
      },
      {
        id: IntegrationRootFolderIDs.MOBILE_DEVICE_TESTING,
        label: t('integration#mobile-device-testing'),
        children: [
          {
            id: IntegrationType.KOBITON_INTEGRATION.id,
            label: IntegrationType.KOBITON_INTEGRATION.label,
            disabled: !isTeamManager
          }
        ]
      },
      {
        id: IntegrationRootFolderIDs.TESTING_FRAMEWORK,
        label: t('integration#testing-framework'),
        children: [
          {
            id: IntegrationType.JASMINE.id,
            label: IntegrationType.JASMINE.label
          },
          {
            id: IntegrationType.JEST.id,
            label: IntegrationType.JEST.label
          },
          {
            id: IntegrationType.MOCHA.id,
            label: IntegrationType.MOCHA.label
          },
          {
            id: IntegrationType.PROTRACTOR_JASMINE.id,
            label: IntegrationType.PROTRACTOR_JASMINE.label
          },
          {
            id: IntegrationType.PROTRACTOR_MOCHA.id,
            label: IntegrationType.PROTRACTOR_MOCHA.label
          },
          {
            id: IntegrationType.PYTEST.id,
            label: IntegrationType.PYTEST.label
          },
          {
            id: IntegrationType.ROBOT.id,
            label: IntegrationType.ROBOT.label
          }
        ]
      }
    ];
  }
};
