import React, { useState } from 'react';
import { t } from '../../../i18n/t';
import ExecutionTrendTestRun from '../../test_reports/test_run_report/ExecutionTrendTestRun';
import { ProjectStatisticsType } from '../../../utils/Constants';
import DateRangePicker from '../../../components/DateRangePicker';
import Time from '../../../utils/Moment';
import DateHelper from '../../../utils/DateHelper';
import { buildSearchCondition } from '../../../components/search/SearchUtils';
import ExecutionResults from './ExecutionResults';

const getDefaultDate = () => {
  const currentDate = Time.now();
  return DateHelper.getLast7Days(currentDate);
};

const TestActivities = ({ totalTestRunsChartRef, executionResultChartRef }) => {
  const [filterDateTimeValue, setFilterDateTimeValue] = useState(getDefaultDate());

  const handleDateFilterChange = (changedDateTimeValue) => {
    setFilterDateTimeValue(changedDateTimeValue);
  };

  const getSearchConditions = () => {
    const defaultSearchConditions = [
      buildSearchCondition('startTime', '>=', Time.searchApiFormatWithSettingOrClientTz(filterDateTimeValue.startTime)),
      buildSearchCondition('startTime', '<=', Time.searchApiFormatWithSettingOrClientTz(filterDateTimeValue.endTime)),
    ];
    return defaultSearchConditions;
  };

  const renderHeader = () => (
    <div className="d-flex">
      <div className="test-activities-header-title">{t('testing_activites')}</div>
      <div className="ml-auto align-items-center d-flex">
        <DateRangePicker className="project-dashboard-filter" value={filterDateTimeValue} handleChange={handleDateFilterChange} />
      </div>
    </div>
  );

  const renderTotalTestRun = () => {
    const headerTooltip = t('project-dashboard#total-test-run#tooltip');
    const defaultGroupByTime = ProjectStatisticsType.DAILY;
    const headerTitle = t('project-dashboard#total-test-run');
    const defaultSearchConditions = getSearchConditions();

    return (
      <ExecutionTrendTestRun
        dataLoaderRef={totalTestRunsChartRef}
        filterDateTimeValue={filterDateTimeValue}
        defaultSearchConditions={defaultSearchConditions}
        headerTooltip={headerTooltip}
        defaultGroupByTime={defaultGroupByTime}
        headerTitle={headerTitle}
        isDashboard
      />
    );
  };

  const renderExecutionResult = () => {
    const defaultSearchConditions = getSearchConditions();
    return (
      <ExecutionResults
        dataLoaderRef={executionResultChartRef}
        defaultSearchConditions={defaultSearchConditions}
      />
    );
  };

  return (
    <>
      {renderHeader()}
      <div className="cards-row">
        {renderTotalTestRun()}
        {renderExecutionResult()}
      </div>
    </>
  );
};

export default TestActivities;
