import React from 'react';
import DefaultLayout from '../../components/DefaultLayout';
import { t } from '../../i18n/t';
import PageComponent from '../../components/PageComponent';
import MContext from '../../models/MContext';
import TestExecutionHeaderContainer from './components/TestExecutionHeaderContainer';
import ExecutionEnvMicroApp from '../ExecutionEnvMicroApp';

class TestRunHistoryPage extends PageComponent {

  constructor(props) {
    super(props);
    this.projectId = MContext.project.id;
    this.project = MContext.project;
    this.meta.id = 'page-test-run-history';
    this.meta.title = t('Project {{name}} - {{page}}', { name: this.project.name, page: t('test-execution#histories') });
    this.renderBody = this.renderBody.bind(this);
  }

  renderToolbar() {
    return (
      <TestExecutionHeaderContainer headerTitle={t('test-execution#histories')} {...this.props} />
    );
  }

  renderExecutionEnvMicroApp() {
    return (
      <ExecutionEnvMicroApp />
    );
  }

  renderBody() {
    return (
      <>
        {this.renderToolbar()}
        {this.renderExecutionEnvMicroApp()}
      </>
    );
  }

  render() {
    const { projectId } = this;
    if (projectId) {
      return (
        <DefaultLayout
          renderBody={this.renderBody}
        />
      );
    }
    return null;
  }
}

export default TestRunHistoryPage;
