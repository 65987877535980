import { get } from 'lodash';
import React from 'react';
import MTableColumnDataMapping from '../../components/table/models/MTableColumnDataMapping';
import StopButton from '../StopButton';
import { t } from '../../i18n/t';
import Time from '../../utils/Moment';
import { IconStatusOnline } from '../../images/CustomIcon';
import TableCore from './models/TableCore';
import Services from '../../utils/Services';
import MContext from '../../models/MContext';
import TableLoading from '../../components/table/TableLoading';
import Notification from '../../utils/Notification';
import StopTunnelDialog from '../dialog/StopTunnelDialog';

class ActiveTunnelDataTable extends React.Component {
  constructor(props) {
    super(props);
    this.team = MContext.team;
    this.getColumnMapping = this.getColumnMapping.bind(this);
    this.stopTunnel = this.stopTunnel.bind(this);
    this.stopTunnelDialogRef = React.createRef();
    this.onTunnelStopped = this.onTunnelStopped.bind(this);
    this.state = {
      activeTunnels: [],
      isLoading: true,
      selectedTunnel: {},
    };
  }

  stopTunnel(row) {
    this.setState({ selectedTunnel: row }, () => this.stopTunnelDialogRef.current.toggle());
  }

  onTunnelStopped() {
    const { activeTunnels, selectedTunnel } = this.state;
    this.setState({
      activeTunnels: [...activeTunnels].filter((tunnel) => tunnel.id !== selectedTunnel.id),
    });
  }

  getColumnMapping() {
    const columnMapping = [
      new MTableColumnDataMapping(
        t('status'),
        '',
        () => (
          <div className="d-flex align-items-center">
            <IconStatusOnline />
            <div className="ml-2">{t('test-cloud-tunnel#active')}</div>
          </div>
        )
      ),

      new MTableColumnDataMapping(
        t('test-cloud-tunnel#tunnelId'),
        'id',
      ),

      new MTableColumnDataMapping(
        t('test-cloud-tunnel#tunnelVersion'),
        'version',
      ),

      new MTableColumnDataMapping(
        t('test-cloud-tunnel#owner'),
        'username',
      ),

      new MTableColumnDataMapping(
        t('test-cloud-tunnel#tunnelType'),
        'privateTunnel',
        (name, row) => {
          const tunnelType = get(row, name);
          return tunnelType ? t('test-cloud-tunnel#private') : t('test-cloud-tunnel#shared');
        },
      ),

      new MTableColumnDataMapping(
        t('test-cloud-tunnel#duration'),
        'updatedAt',
        (name, row) => {
          const now = new Date();
          const startTime = new Date(get(row, name));
          return Time.duration(now - startTime);
        },
      ),

      new MTableColumnDataMapping(
        '',
        '',
        (name, row) => (
          <StopButton row={row} onClick={this.stopTunnel} />
        ),
      ),
    ];
    return columnMapping;
  }

  componentDidMount() {
    this.getActiveTunnels();
  }

  getActiveTunnels() {
    Services.getActiveTunnels(this.team.organizationId)
      .then((tunnels) => {
        this.setState({
          activeTunnels: tunnels,
          isLoading: false,
        });
      })
      .catch((e) => {
        Notification.pushError(e, t('test-cloud-tunnel#fail-get-api-keys'));
      });
  }

  render() {
    const { activeTunnels, isLoading, selectedTunnel } = this.state;
    if (isLoading) return <TableLoading />;
    return (
      <>
        <TableCore
          columnMapping={this.getColumnMapping()}
          data={activeTunnels}
        />
        <StopTunnelDialog
          ref={this.stopTunnelDialogRef}
          tunnel={selectedTunnel}
          onTunnelStopped={this.onTunnelStopped}
        />
      </>
    );
  }
}

export default ActiveTunnelDataTable;
