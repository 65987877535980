import Icon from '@katalon-studio/katalon-ui/Icon';
import React from 'react';

function EllipsisIcon({ textSize }) {
  return <Icon
    type="fa-regular"
    name="fa-ellipsis"
    textSize={textSize}
    sx={{
      width: textSize,
      height: textSize,
      marginRight: '0px !important'
    }}
  />;
}

EllipsisIcon.defaultProps = {
  textSize: '20px',
};

export default EllipsisIcon;
