import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TreeView from '@mui/lab/TreeView';
import TreeItem from '@mui/lab/TreeItem';
import Tooltip from '@katalon-studio/katalon-ui/Tooltip';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { TreeItemEntity } from '../../../models/model/TreeItemEntity';
import { IntegrationRootFolderIDs } from '../../../models/model/IntegrationRootFolderIDs';

function TreeViewComponent(props: {
  data: TreeItemEntity[],
  defaultSelectedKeys: string[],
  defaultExpandedKeys: string[],
  onSelect: (nodeIds: string[]) => void,
  onExpand: (nodeIds: string[]) => void,
  customDisabledToolTip: string,
}) {

  const { data, defaultSelectedKeys, defaultExpandedKeys, customDisabledToolTip } = props;

  const [treeData] = useState(data);
  const [selectedKeys, setSelectedKeys] = useState<string[]>(defaultSelectedKeys);
  const [expandedKeys, setExpandedKeys] = useState<string[]>(defaultExpandedKeys);

  const onNodeSelect = (_event: React.SyntheticEvent, nodeIds: string[]) => {
    setSelectedKeys(nodeIds);
    if (props.onSelect) {
      props.onSelect(nodeIds);
    }
  };

  const onExpand = (_event: React.SyntheticEvent, nodeIds: string[]) => {
    setExpandedKeys(nodeIds);
    if (props.onExpand) {
      props.onExpand(nodeIds);
    }
  };

  const renderTreeItem = (nodes: TreeItemEntity[]) => {
    const treeItems = nodes.map(({ id, label, children, disabled }) => (
      <Tooltip label={`${disabled ? customDisabledToolTip : ''}`} size="medium" key={id}>
        <TreeItem
          key={id}
          nodeId={id}
          label={label}
          className={`customize-tree-item ${id === IntegrationRootFolderIDs.DEFAULT && 'disable-icon'}`}
          disabled={disabled}
        >
          {Array.isArray(children) ? renderTreeItem(children) : null}
        </TreeItem>
      </Tooltip>
    ));
    return treeItems;
  };

  return (
    <TreeView
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
      expanded={expandedKeys}
      selected={selectedKeys}
      onNodeSelect={onNodeSelect}
      onNodeToggle={onExpand}
    >
      {renderTreeItem(treeData)}
    </TreeView>
  );
}

TreeViewComponent.propTypes = {
  /**
    data is array of folder
    Ex:
     [
      {
        id: 'Folder1',
        label: 'Folder 1',
        children: [
          {
            id: 'Folder 1.1',
            label: 'Folder 1.1'
          }
        ],
      },
      {},
      ...
    ]
   * label: A text to show in UI
   * id **important**: used to distinguish node in tree, if duplicate => break UI
   * (Note: key of root have prefix root_..., key of sub-folder is ID of testFolder)
   * children: sub-node of this node
   */
  data: PropTypes.object.isRequired,
  defaultSelectedKeys: PropTypes.array,
  defaultExpandedKeys: PropTypes.array,
  onSelect: PropTypes.func,
  onExpand: PropTypes.func,
  customDisabledToolTip: PropTypes.string,
};

TreeViewComponent.defaultProps = {
  defaultSelectedKeys: [],
  defaultExpandedKeys: [],
  customDisabledToolTip: ''
};

export default TreeViewComponent;
