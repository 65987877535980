import React from 'react';
import {
  Col, Row, Card, CardHeader, CardBody
} from 'reactstrap';
import PageComponent from '../../components/PageComponent';
import { t } from '../../i18n/t';
import ObjectSummary from '../../components/summary/ObjectSummary';
import DefaultLayout from '../../components/DefaultLayout';
import Services from '../../utils/Services';
import ChangePasswordForm from './ChangePasswordForm';
import Notification from '../../utils/Notification';

class ChangePassword extends PageComponent {

  constructor(props) {
    super(props);
    this.meta.id = 'page-user-change-password';
    this.meta.title = t('user#title_change_password');
    this.state = this.getInitialState();
    this.requestChangePassword = this.requestChangePassword.bind(this);
    this.renderChangePassword = this.renderChangePassword.bind(this);
    this.renderHeader = this.renderHeader.bind(this);
    this.handleFormDataChange = this.handleFormDataChange.bind(this);

    this.form = React.createRef();
  }

  getInitialState() {
    const formData = {
      currentPassword: '',
      password: '',
      confirmPassword: '',
    };

    return {
      valide: false,
      info: null,
      formData,
    };
  }

  handleFormDataChange(formData) {
    this.setState({ formData });
  }

  requestChangePassword() {
    const { formData } = this.state;
    const {
      currentPassword,
      password,
    } = formData;
    const request = {
      currentPassword,
      password
    };
    Services.changePassword(request)
      .then((result) => {
        if (result) {
          Notification.pushSuccess('Your password has been successfully changed!');
        }
      });
  }

  renderChangePassword() {
    return (
      <Card>
        <CardHeader>{t('user#change_password')}</CardHeader>
        <CardBody>
          <Row>
            <Col sm="12" md="8" lg="6" xl="5">
              <ChangePasswordForm
                handleFormDataChange={this.handleFormDataChange}
                data-trackid="change-password"
                handleFormSubmission={this.requestChangePassword}
                ref={this.form}
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  }

  renderObjectSummary() {
    const urlParams = {
      profile: t('profile'),
    };
    return (
      <ObjectSummary params={urlParams} />
    );
  }

  renderHeader() {
    return (
      <>
        {this.renderObjectSummary()}
      </>
    );
  }

  render() {
    return (
      <DefaultLayout
        renderHeader={this.renderHeader}
        renderBody={this.renderChangePassword}
      />
    );
  }
}

export default ChangePassword;
