import PropTypes from 'prop-types';
import React from 'react';
import { IconButton, Badge } from '@mui/material';

import { IconBell, IconBellHover } from '../../images/CustomIcon';

/**
 * Notification badge component.
 */
const NotificationBadge = ({ number, ...rest }) => (
  <IconButton aria-label="notification" {...rest} size="large">
    <Badge badgeContent={number} color="secondary" className="notification-toggler__badge">
      <span className="icon">
        <IconBell />
      </span>
      <span className="icon-hover">
        <IconBellHover />
      </span>
    </Badge>
  </IconButton>
);

NotificationBadge.propTypes = {
  number: PropTypes.number.isRequired,
};

NotificationBadge.defaultProps = {
  number: 0,
};

export default NotificationBadge;
