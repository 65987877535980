

class MomentFormatProvider {
  static inferDurationFormat(data) {
    const maxValue = Math.max(...data);
    let format;
    if (maxValue < 1000) {
      format = 'S[ms]';
    } else if (maxValue / 1000 < 60) {
      format = 's[s]';
    } else if (maxValue / 60000 < 60) {
      format = 'm[m] s[s]';
    } else {
      format = 'h[h] m[m]';
    }
    return format;
  }

  static inferMedianFormat() {
    const format = 'S[ms]';
    return format;
  }
}

export default MomentFormatProvider;
