import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Card, CardBody } from 'reactstrap';
import { t } from '../i18n/t';
import RouteConstants from '../utils/RouteConstants';
import DefaultLayout from '../components/DefaultLayout';
import { TestEnvInstallationHeaderNav } from '../components/HeaderNav';
import ObjectSummary from '../components/summary/ObjectSummary';
import AgentSetupLocal from './agent/AgentSetupLocal';
import AgentSetupDocker from './agent/AgentSetupDocker';

function TestEnvInstallationV2() {
  document.title = `${t('test-env-installation')} - ${t('brand_name')}`;

  const renderObjectSummary = () => {
    const urlParams = {
      testEnvInstallation: t('test-env-installation'),
    };
    return (
      <ObjectSummary params={urlParams}>
        <TestEnvInstallationHeaderNav />
      </ObjectSummary>
    );
  };

  const renderHeader = () => (
    <>
      {renderObjectSummary()}
    </>
  );

  const renderLocalAgentInstallation = () => (
    <Card>
      <CardBody>
        <AgentSetupLocal />
      </CardBody>
    </Card>
  );

  const renderDockerAgentInstallation = () => (
    <Card>
      <CardBody>
        <AgentSetupDocker />
      </CardBody>
    </Card>
  );

  const renderBody = () => {
    return (
      <Switch>
        <Route
          exact
          path={RouteConstants.organization_setting_test_env_installation}
          component={renderLocalAgentInstallation}
        />
        <Route
          exact
          path={RouteConstants.organization_setting_test_env_docker_agent_installation}
          component={renderDockerAgentInstallation}
        />
      </Switch>
    );
  };

  return (
    <DefaultLayout
      renderHeader={renderHeader}
      renderBody={renderBody}
    />
  );
}

export default TestEnvInstallationV2;
