/* eslint-disable max-len */
import { trim } from 'lodash';
import { EXECUTION_TYPE, KATALON_EVENTS, KATALON_TEST_CASE_ROOT_FOLDER, TEST_TYPE } from '../../../utils/Constants';
import Services from '../../../utils/Services';
import TestCaseObjHelper from '../../../utils/TestCaseObjHelper';
import MAuth from '../../../models/MAuth';
import MContext from '../../../models/MContext';
import TestObjectPublisher from './TestObjectPublisher';
import { TestCase } from '../../../models/model/TestCase';
import { TestCaseType } from '../../../models/model/TestCaseType';
import { PublishTestCase } from '../../../models/model/PublishTestCase';
import { DraftTestCase } from '../../../models/model/DraftTestCase';
import { TestEntity } from '../../../models/model/TestEntity';
import StringHelper from '../../../utils/StringHelper';
import { DomEventHandlers } from '../../../utils/EventHandler';
import { t } from '../../../i18n/t';
import Notification from '../../../utils/Notification';
import NotificationHandler from '../handler/NotificationHandler';
import { AppHelper } from '../../../utils/AppHelper';

const deleteDraftTestCase = (draftTestCaseId: string) => {
  TestCaseObjHelper.delete(draftTestCaseId);
};

const createDraftTestCase = async (description: string, scriptRepoId: number | undefined, name: string, path: string, content: string, testType: string, executionType: string): Promise<DraftTestCase> => {
  const currentDate = new Date();
  const id = TestCaseObjHelper.generateNewDraftTestCaseId(name);

  const draftTestCase = {
    id,
    description,
    scriptRepoId,
    baseCommitId: '',
    userId: MAuth.user.id,
    projectId: MContext.projectId,
    name,
    path,
    content,
    type: TestCaseType.TEST_CASE,
    testType,
    executionType,
    createdAt: currentDate,
    lastUpdated: currentDate,
  };
  await TestCaseObjHelper.updateOrCreate(draftTestCase);
  return draftTestCase;
};

export default {

  /**
   * build test case object to publish
   */
  buildPublishTestCase(id: number | null, name: string, path: string, testType: string, executionType: string | null, content: string, description: string, deleted?: boolean): PublishTestCase {
    return {
      id,
      name: trim(name),
      path: trim(path),
      testType,
      executionType,
      content,
      description,
      deleted,
    };
  },

  /**
   * get test case content
   */
  async getTestCaseContent(testCaseId: number) {
    return Services.getTestCase(testCaseId);
  },

  /**
   * duplicate draft test case
   */
  async duplicateDraftTestCase(draftTestCase: DraftTestCase) {
    const { name, description, scriptRepoId, path, content, testType, executionType } = draftTestCase;
    const draftTestCases = await TestCaseObjHelper.getAll();
    const testCaseNames = draftTestCases.map((testCase: { name: string }) => testCase.name);
    const incrementalName = StringHelper.generateIncrementalName(name, testCaseNames);
    createDraftTestCase(description, scriptRepoId, incrementalName, path, content, testType, executionType)
      .then(() => {
        this.handleAfterDuplicatedOne(draftTestCase.name);
      })
      .catch(() => {
        NotificationHandler.duplicateTestCaseOldErrorHandler(name);
      });
  },

  /**
   * Bulk duplicate test case
   */
  async duplicate(chosenTestEntities: TestEntity[]) {
    if (chosenTestEntities.length <= 0) {
      return;
    }
    const { testProject } = chosenTestEntities[0].testCase as TestCase;
    if (!testProject) {
      throw new Error(t('test-project#invalid'));
    }
    const { id: testProjectId } = testProject;
    const publishTestCases: PublishTestCase[] = [];
    AppHelper.openCustomBlockedUI();

    const promises: Promise<void>[] = [];
    await chosenTestEntities.forEach((testEntity) => {
      if (testEntity.testCase === undefined) {
        return;
      }
      const { id: testCaseId, name, path, description } = testEntity.testCase as TestCase;
      promises.push(
        this.getTestCaseContent(testCaseId).then(async (testCase) => {
          const testCases = await Services.getTestCasesByNameAndPathAndTestProjectId(name, path, testProjectId);
          const testCaseNames = testCases.map((testCase: { name: string }) => testCase.name);
          const newTestCaseName = StringHelper.generateIncrementalName(name, testCaseNames);
          const publishTestCase: PublishTestCase = this.buildPublishTestCase(null, newTestCaseName, path, testCase.testType, testCase.executionType, testCase.content, description);

          publishTestCases.push(publishTestCase);
        })
      );
    });
    const duplicateTestCaseErrorHandler: any = (message: string, _label: string, jqXHR: any) => NotificationHandler.duplicateTestCaseErrorHandler(message, jqXHR, publishTestCases);
    Promise.all(promises).then(() => {
      TestObjectPublisher.publishTestObject(testProjectId, publishTestCases, [], '', duplicateTestCaseErrorHandler)
        .then(({ testCases }) => {
          this.handleAfterDuplicated(testCases);
        })
        .catch(() => {
        // ignore
        });
    });
  },

  /**
   * rename current test case to new name
   */
  async rename(currentTestCase: TestCase, newName: string, errorHandler: any) {
    const { id: testCaseId, urlId, description, testProject, path } = currentTestCase;

    if (!testProject) {
      throw new Error(t('test-project#invalid'));
    }

    AppHelper.openCustomBlockedUI();

    const { id: testProjectId } = testProject;

    const draftTestCase = await TestCaseObjHelper.get(urlId);

    if (draftTestCase) {
      const renameTestCase = { ...draftTestCase, name: newName };
      await TestCaseObjHelper.updateOrCreate(renameTestCase);
      const publishTestCase: PublishTestCase = this.buildPublishTestCase(testCaseId, newName, path, renameTestCase.testType, renameTestCase.executionType, renameTestCase.content, description);
      return TestObjectPublisher.publishTestObject(testProjectId, [publishTestCase], [], '', errorHandler)
        .then(({ testCases }) => {
          this.handleAfterRename(testCases);
          deleteDraftTestCase(urlId);
        })
        .catch(() => {
          TestCaseObjHelper.updateOrCreate(draftTestCase);
        });
    } else {
      return this.getTestCaseContent(testCaseId)
        .then(async (testcase) => {
          const draftTestCase: DraftTestCase = await createDraftTestCase(description, testProjectId, newName, path, testcase.content, testcase.testType, testcase.executionType);
          const publishTestCase: PublishTestCase = this.buildPublishTestCase(testCaseId, newName, path, testcase.testType, testcase.executionType, testcase.content, description);
          TestObjectPublisher.publishTestObject(testProjectId, [publishTestCase], [], '', errorHandler)
            .then(({ testCases }) => {
              this.handleAfterRename(testCases);
            })
            .catch(() => {
              // ignore
            })
            .finally(() => {
              deleteDraftTestCase(draftTestCase.id);
            });
        });
    }
  },

  /**
   * move current test cases to new folder
   */
  async move(testCases: TestCase[], newPath: string, errorHandler: any) {
    const { testProject } = testCases[0];

    if (!testProject) {
      throw new Error(t('test-project#invalid'));
    }

    AppHelper.openCustomBlockedUI();

    const { id: testProjectId } = testProject;

    const getTestCasesContent: any[] = [];

    testCases.forEach((testCase) => {
      getTestCasesContent.push(this.getTestCaseContent(testCase.id));
    });

    const testCasesContent = await Promise.all(getTestCasesContent);
    const publishTestCases: PublishTestCase [] = testCasesContent.map((item) => {
      const { id, name, testType, executionType, content, description } = item;
      return this.buildPublishTestCase(id, name, newPath, testType, executionType, content, description);
    });

    TestObjectPublisher.publishTestObject(testProjectId, publishTestCases, [], '', errorHandler)
      .then(({ testCases }) => {
        this.handleAfterMove(testCases);
      })
      .catch(() => {
        // ignore
      });
  },

  /**
   * delete list of test cases
   */
  async delete(testEntities: TestEntity[]) {
    const { testProject } = testEntities[0].testCase as TestCase;
    if (!testProject) {
      throw new Error(t('test-project#invalid'));
    }

    AppHelper.openCustomBlockedUI();

    const publishTestCases: PublishTestCase[] = [];

    testEntities.forEach((testEntity) => {
      const { id: testCaseId, name, path, testType } = testEntity.testCase as TestCase;
      const publishTestCase: PublishTestCase = this.buildPublishTestCase(testCaseId, name, path, testType, null, '', '', true);
      publishTestCases.push(publishTestCase);
    });
    const { id: testProjectId } = testProject;

    const errorHandler: any = (message: string, _label: string, jqXHR: any) => NotificationHandler.deleteTestCaseErrorHandler(message, jqXHR, publishTestCases[0].name);
    return TestObjectPublisher.publishTestObject(testProjectId, publishTestCases, [], '', testEntities.length === 1 ? errorHandler : null)
      .then(({ testCases }) => {
        this.handleAfterDelete(testCases);
      })
      .catch(() => {
        // ignore
      });
  },

  handleAfterDuplicatedOne(testCaseName: string) {
    AppHelper.closeCustomBlockedUI();
    Notification.pushSuccess(t('duplicate-test-case#success-description', { testCaseName }), t('duplicate-test-case-old#success-title'));
    DomEventHandlers.createEvent(KATALON_EVENTS.duplicatedG5TestCase);
  },

  handleAfterDuplicated(testCases: TestCase[]) {
    AppHelper.closeCustomBlockedUI();
    DomEventHandlers.createEvent(KATALON_EVENTS.duplicatedG5TestCase);
    if (testCases.length === 1) {
      const testCaseName = testCases[0].name;
      Notification.pushSuccess(t('duplicate-test-case#success-description', { testCaseName }), t('duplicate-test-case#success-title'));
    } else if (testCases.length > 1) {
      const testCaseNumber = testCases.length;
      Notification.pushSuccess(t('duplicate-test-cases#success-description', { testCaseNumber }), t('duplicate-test-case#success-title'));
    }
  },

  handleAfterMove(testCases: TestCase[]) {
    AppHelper.closeCustomBlockedUI();
    DomEventHandlers.createEvent(KATALON_EVENTS.movedG5TestCase, { detail: { publishedTestCases: testCases } });
    if (testCases.length === 1) {
      const testCase = testCases[0];
      Notification.pushSuccess(
        t('moved-test-case#successfully#message', { testCaseName: testCase.name, testCasePath: testCase.path }),
        t('moved-test-case#successfully#title')
      );
    } else {
      Notification.pushSuccess(
        t('moved-list-test-case#successfully#message', { numberOfTestCases: testCases.length, newPath: testCases[0].path }),
        t('moved-test-case#successfully#title')
      );
    }
  },

  handleAfterRename(testCases: TestCase[]) {
    AppHelper.closeCustomBlockedUI();
    DomEventHandlers.createEvent(KATALON_EVENTS.renamedG5TestCase);
    const testCase = testCases[0];
    Notification.pushSuccess(
      t('rename-test-case-dialog#success-message', { testCaseName: testCase.name }),
      t('testCaseRenamed')
    );
  },

  handleAfterDelete(testCases: TestCase[]) {
    AppHelper.closeCustomBlockedUI();
    DomEventHandlers.createEvent(KATALON_EVENTS.deletedG5TestCase);
    if (testCases.length === 1) {
      const testCase = testCases[0];
      Notification.pushSuccess(
        t('deleted-test-case#successfully#message', { testCaseName: testCase.name }),
        t('deleted-test-case#successfully#title')
      );
    } else if (testCases.length > 1) {
      Notification.pushSuccess(
        t('deleted-test-cases#successfully#message', { testCaseNumber: testCases.length }),
        t('deleted-test-case#successfully#title')
      );
    }
  },

  handleAfterUploadFailed() {
    DomEventHandlers.createEvent(KATALON_EVENTS.uploadFailedG5TestCase);
  },

  handleAfterUploadSucceeded(lastUpdated: Date) {
    DomEventHandlers.createEvent(KATALON_EVENTS.uploadSucceededG5TestCase, { detail: { lastUpdated } });
  },

  /**
   * migrate from IndexedDB to Katalon Cloud
   */
  async migrateDraftTestCaseToKatalonCloud(testProjectId: number) {
    const listTestCases = await TestCaseObjHelper.getAll().then(
      (result: DraftTestCase[]) =>
        result.filter(
          (el) => {
            if (el.projectId !== MContext.projectId || el.userId !== MAuth.user.id) {
              // different project / user -> do not migrate
              return false;
            }
            if (el.lastSynced) {
              // already synced = it's already on the cloud, do nothing
              return false;
            }
            return true;
          }
        )
    );
    if (!listTestCases || listTestCases.length <= 0) {
      return false;
    }

    const listModifiedTC = [];
    const listModifiedTCId: string[] = [];

    for (const item of listTestCases) {
      let publishingItem: PublishTestCase;
      if (item.id.includes('draft')) {
        publishingItem = this.buildPublishTestCase(
          null,
          item.name,
          KATALON_TEST_CASE_ROOT_FOLDER.G5_TEST_CASE_FOLDER_TITLE,
          TEST_TYPE.G5_TEST_CASE,
          EXECUTION_TYPE.TS,
          item.content,
          item.description
        );
      } else {
        publishingItem = this.buildPublishTestCase(
          null,
          item.name,
          item.path,
          item.testType,
          item.executionType,
          item.content,
          item.description
        );
      }
      listModifiedTC.push(publishingItem);
      listModifiedTCId.push(item.id);
    }

    if (!listModifiedTC || listModifiedTC.length <= 0) {
      return false;
    }

    const numberOfRequest = Math.ceil(listTestCases.length / 10);
    const requests = [];
    for (let index = 0; index < numberOfRequest; index++) {
      const itemInList = listModifiedTC.slice(index * 10, (index + 1) * 10);
      const idInList = listModifiedTCId.slice(index * 10, (index + 1) * 10);
      const noOpErrorHandler: any = () => {
        // intentionally left empty
      };
      const ignoreBlockUI = true;
      requests.push(
        TestObjectPublisher.publishTestObject(
          testProjectId,
          itemInList,
          [],
          '',
          noOpErrorHandler,
          ignoreBlockUI
        ).then(() => {
          for (const item of idInList) {
            deleteDraftTestCase(item);
          }
        })
      );
    }

    try {
      await Promise.all(requests);
    } catch (error) {
      console.error('Failed to publish: ', error);
      return false;
    }

    return true;
  },
};
