import PageComponent from '../components/PageComponent';
import MContext from '../models/MContext';
import Helper from '../utils/Helper';
import Routes from '../utils/Routes';
import MAuth from '../models/MAuth';
import Apis from '../utils/Apis';

class DownloadFile extends PageComponent {
  constructor(props) {
    super(props);
    this.fileHandleId = MContext.fileHandleId;
    this.meta.id = 'page-download-execution';
    this.meta.title = 'Downloading...';
  }

  componentDidMount() {
    if (MAuth.isLoggedIn) {
      Helper.download(Apis.fileDownload(this.fileHandleId));
    }
  }

  render() {
    Routes.goToHomePage();
    return (
      null
    );
  }
}

export default DownloadFile;
