import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Moment from '../../utils/Moment';
import LineChart from './LineChart';
import MChartLabelMapping from './models/MChartLabelMapping';
import MChartDataMapping from './models/MChartDataMapping';
import StatusConstants from '../../utils/Statuses';
import MomentFormatProvider from '../../utils/MomentFormatProvider';

class PerformanceLineChartForTestObject extends React.Component {

  render() {

    const { data } = this.props;
    let { chartLabelsDecorate } = this.props;

    if (!chartLabelsDecorate) {
      chartLabelsDecorate = (fieldName, item) => `#${_.get(item, fieldName)}`;
    }

    const chartLabelsMapping = new MChartLabelMapping(
      'id',
      chartLabelsDecorate,
    );

    const chartDatasetMapping = [
      new MChartDataMapping('Duration', 'time', 'rgba(63, 159, 191, 0.51)'),
    ];

    const durationValues = data.map((item) => item.duration);
    const durationFormat = MomentFormatProvider.inferMedianFormat(durationValues);
    const options = {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        xAxes: [
          {
            scaleLabel: {
              display: true,
              labelString: this.props.xLabel,
            },
            gridLines: {
              display: true,
            },
          },
        ],
        yAxes: [{
          scaleLabel: {
            display: true,
            labelString: 'Duration',
          },
          ticks: {
            callback: (value) => Moment.duration(value, durationFormat),
          },
        }],

      },
      tooltips: {
        callbacks: {
          label: (tooltipItem) => `Duration: ${Moment.duration(tooltipItem.yLabel, durationFormat)}`,
        },
      },
      legend: {
        display: false,
      },
    };

    const pointColors = [];
    data.forEach((item) => {
      const abnormal = item.abnormal;
      if (abnormal === true) {
        pointColors.push(StatusConstants.FAILED.color);
      } else if (abnormal === false) {
        pointColors.push(StatusConstants.PASSED.color);
      } else {
        pointColors.push(StatusConstants.INCOMPLETE.color);
      }
    });
    const datasetOptions = [{
      pointBackgroundColor: pointColors,
      pointRadius: 5,
      pointBorderColor: pointColors,
      pointHoverBackgroundColor: pointColors,
      pointHoverBorderColor: pointColors,
      borderWidth: 1,
    }];

    return (
      <LineChart
        data={data}
        labelsMapping={chartLabelsMapping}
        datasetsMapping={chartDatasetMapping}
        options={options}
        datasetOptions={datasetOptions}
        xLabel={this.props.xLabel}
      />
    );
  }
}

PerformanceLineChartForTestObject.propTypes = {
  data: PropTypes.array.isRequired,
  chartLabelsDecorate: PropTypes.func,
  xLabel: PropTypes.string.isRequired,
};

export default PerformanceLineChartForTestObject;
