import _ from 'lodash';
import React from 'react';
import MTableColumnDataMapping from '../../components/table/models/MTableColumnDataMapping';
import { t } from '../../i18n/t';
import MContext from '../../models/MContext';
import DecoratorConstants from '../../utils/DecoratorConstants';
import Widget from '../widget/Widget';

class ExecutionResultAnalysisGroupDataTable extends React.Component {

  constructor(props) {
    super(props);
    this.executionList = null;
    this.executionId = MContext.executionId;
  }

  percentDecorator(name, row) {
    const percent = _.get(row, name, '');
    if (percent) {
      return `(${percent}%)`;
    } else {
      return percent;
    }
  }

  render() {
    const {
      disableCategory,
      ...props
    } = this.props;

    const categoryColumn = new MTableColumnDataMapping(
      t('analysis#category'),
      'category',
      DecoratorConstants.categoryDecorator,
    );

    const headers = [
      new MTableColumnDataMapping(
        t('analysis#total_failed_results'),
        'totalTestResults',
        (name, row) => {
          const occupiedPercent = this.percentDecorator('occupiedPercent', row);
          const totalTestResults = _.get(row, name, '');
          return <div className="text-wrap">{`${totalTestResults} ${occupiedPercent}`}</div>;
        },
      ),
      new MTableColumnDataMapping(
        t('analysis#representative_error_log'),
        'representativeTestResult.errorDetail',
        DecoratorConstants.rawTextDecorator,
      ),
    ];
    if (!disableCategory) {
      headers.splice(1, 0, categoryColumn);
    }

    return (
      <Widget
        widgetId="ExecutionTestResultGroupDataTable"
        widgetType="DataTable"
        entityType="ExecutionTestResultGroup"
        widgetRef={(ref) => {
          this.executionList = ref;
        }}
        tableId="ExecutionTestResultGroups"
        columnMapping={headers}
        {...props}
      />
    );
  }
}

export default ExecutionResultAnalysisGroupDataTable;
