import React, { useCallback, useEffect, useState } from 'react';
import MContext from '../../models/MContext';
import { EXECUTION_TYPE, KATALON_EVENTS, KATALON_TEST_CASE_ROOT_FOLDER, TEST_TYPE } from '../../utils/Constants';
import Routes from '../../utils/Routes';
import TestCaseService from './service/TestCaseService';
import TestProjectService from './service/TestProjectService';
import TestObjectPublisher from './service/TestObjectPublisher';

const testCaseName = 'Sample test case';

const testCaseContent = `
import katalon from "@katalon-studio/cloud-test";

export default katalon.testCase("${testCaseName}", async ({ local, web }) => {
  await new Promise((r) => setTimeout(r, 0));
  await web.openBrowser("https://katalon-demo-cura.herokuapp.com");
  await web.click({
    type: "Web",
    id: "49bd8d4e-a8b9-4131-8e98-6f2e8255f65e",
    attributes: {
      id: "btn-make-appointment",
      href: "./profile.php#login",
      class: "btn btn-dark btn-lg",
    },
    childIndex: 4,
    hashes: { "md5.v1": "3caec008087ccb7a1cb3fc698fbf83c2" },
    name: "a - Make Appointment",
    selectors: [
      {
        id: "afd0e650-1eb4-4d84-918a-3ea531ff1856",
        type: "CSS",
        value: "#btn-make-appointment",
        isDefault: true,
      },
      {
        id: "a67763b2-8b84-4748-a748-881ce9115e6c",
        type: "Attribute",
        value: null,
        isDefault: true,
      },
    ],
    selectorType: "CSS",
    tag: "a",
    text: "Make Appointment",
    pageUrl: "https://katalon-demo-cura.herokuapp.com/",
  });
  await web.setText(
    {
      type: "Web",
      id: "4193d1f9-5e46-4f07-8a08-31be22b08f33",
      attributes: {
        type: "text",
        class: "form-control",
        id: "txt-username",
        name: "username",
        placeholder: "Username",
        autocomplete: "off",
      },
      childIndex: 1,
      hashes: { "md5.v1": "57f176ba19cb19cc93acaba2dbde6b10" },
      name: "input - Username",
      selectors: [
        {
          id: "2a48e0f4-17a2-463a-96aa-f5248dfc3296",
          type: "CSS",
          value: "#txt-username",
          isDefault: true,
        },
        {
          id: "c91b7829-721b-479c-a9b9-c5673a3ee1ed",
          type: "Attribute",
          value: null,
          isDefault: true,
        },
      ],
      selectorType: "CSS",
      tag: "input",
      pageUrl: "https://katalon-demo-cura.herokuapp.com/profile.php#login",
    },
    "John Doe"
  );
  await web.setEncryptedText(
    {
      type: "Web",
      id: "d2f3fbf8-4408-4f2b-801e-a5252c979c53",
      attributes: {
        type: "password",
        class: "form-control",
        id: "txt-password",
        name: "password",
        placeholder: "Password",
        autocomplete: "off",
      },
      childIndex: 1,
      hashes: { "md5.v1": "fe634c654141065106a978262a410740" },
      name: "input - Password",
      selectors: [
        {
          id: "2de8aa15-1ad5-4a35-acfa-a7c8707eeb51",
          type: "CSS",
          value: "#txt-password",
          isDefault: true,
        },
        {
          id: "062b2bdd-05ad-4298-9e3a-2d4584f9682b",
          type: "Attribute",
          value: null,
          isDefault: true,
        },
      ],
      selectorType: "CSS",
      tag: "input",
      pageUrl: "https://katalon-demo-cura.herokuapp.com/profile.php#login",
    },
    "0cde93f54e8a78c4e885eabdf2ad7525-U2FsdGVkX19dh7hOCdCAPV0LkJjR52/D4LnMS/apGkZbkNDqcGu0o1esDbdvUW1W"
  );
  await web.click({
    type: "Web",
    id: "1a34b64f-5aab-4cc4-90d1-3693e38458c2",
    attributes: { id: "btn-login", type: "submit", class: "btn btn-default" },
    childIndex: 1,
    hashes: { "md5.v1": "50aa64147a00037c420def1e18673ac9" },
    name: "button - Login",
    selectors: [
      {
        id: "5c2086e9-b1f5-458a-8b90-722197f6127a",
        type: "CSS",
        value: "#btn-login",
        isDefault: true,
      },
      {
        id: "c2f1fe05-3c3e-4bff-959b-b8d077d9b629",
        type: "Attribute",
        value: null,
        isDefault: true,
      },
    ],
    selectorType: "CSS",
    tag: "button",
    text: "Login",
    pageUrl: "https://katalon-demo-cura.herokuapp.com/profile.php#login",
  });
  await web.verifyElementPresent({
    type: "Web",
    id: "8255b50c-8c8c-4b8a-b6d9-bb309cbef6c6",
    attributes: {},
    childIndex: 1,
    hashes: { "md5.v1": "de9ca85128fd393fefc5579b4f6d7ea4" },
    name: "h2 - Make Appointment",
    selectors: [
      {
        id: "4160f85c-a77d-4aee-8bda-ceab64bba41a",
        type: "CSS",
        value: "h2",
        isDefault: true,
      },
      {
        id: "3bd075d9-b64b-44db-850b-69165ad8e621",
        type: "Attribute",
        value: null,
        isDefault: true,
      },
    ],
    selectorType: "CSS",
    tag: "h2",
    text: "Make Appointment",
    pageUrl: "https://katalon-demo-cura.herokuapp.com/#appointment",
  });
  await web.closeBrowser();
});
`;

function SampleG5TestCaseCreator() {
  const projectId = `${MContext.projectId ?? ''}`;
  const [isCreated, setIsCreated] = useState(false);
  const [url, setUrl] = useState('');

  const onEvent = useCallback<() => void>(async () => {
    let cloudRepoId = 0;
    if (projectId.length > 0) {
      cloudRepoId = await TestProjectService.getCloudRepoId(projectId);
    }

    if (cloudRepoId > 0) {
      const testCase = TestCaseService.buildPublishTestCase(
        null,
        testCaseName,
        KATALON_TEST_CASE_ROOT_FOLDER.G5_SAMPLE_TEST_CASE_FOLDER_TITLE,
        TEST_TYPE.G5_TEST_CASE,
        EXECUTION_TYPE.TS,
        testCaseContent,
        'Create sample test case'
      );
      const { testCases } = await TestObjectPublisher.publishTestObject(cloudRepoId, [testCase], [], '');
      if (Array.isArray(testCases) && testCases.length === 1) {
        const route = new Routes({ testCaseId: testCases[0].urlId });
        setUrl(route.edit_test_case_link);
        setIsCreated(true);
      }
    }
  }, [projectId, setIsCreated, setUrl]);

  useEffect(() => {
    let addedEventListener = false;

    if (!isCreated && projectId.length > 0) {
      // only listen to event when the test case is not created yet
      document.addEventListener(KATALON_EVENTS.createSampleG5TestCase, onEvent);
      addedEventListener = true;
    }

    return () => {
      if (addedEventListener) {
        document.removeEventListener(KATALON_EVENTS.createSampleG5TestCase, onEvent);
      }
    };
  }, [isCreated, onEvent, projectId]);

  return <div id="sample-test-case-creator" data-is-created={isCreated} data-url={url} />;
}

export default React.memo(SampleG5TestCaseCreator);
