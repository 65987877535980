import React from 'react';
import { Checkbox } from '@mui/material';
import { CustomInput } from 'reactstrap';
import { sendAnalyticEventForAction } from '../utils/SegmentAnalytics';

/**
 * This is a wrapper for CustomInput checkbox/Mui Checkbox to support tracking.
 */
const CheckBox = ({ useMUI, ...props }) => {

  const sendTrackingEvent = () => {
    const dataTrackId = props['data-trackid'];
    const dataGroupId = props['data-groupid'];
    if (dataTrackId && dataGroupId) {
      sendAnalyticEventForAction(dataTrackId, { 'data-groupid': dataGroupId });
    }
  };

  const handleOnChange = (e) => {
    sendTrackingEvent();
    const { onChange } = props;
    if (onChange) {
      onChange(e);
    }
  };

  if (props.className) {
    props.className += ' checkbox';
  } else {
    props.className = 'checkbox';
  }

  // remove props.onChange to let this handle
  const newProps = { ...props };
  delete newProps.onChange;

  return (
    <>
      { useMUI && <Checkbox onChange={handleOnChange} {...newProps} /> }
      { !useMUI && <CustomInput onChange={handleOnChange} type="checkbox" {...newProps} /> }
    </>
  );
};

export default CheckBox;
