import React, { Component } from 'react';
import StatusBarInfo from './StatusBarInfo';
import StatusBarChart from './StatusBarChart';
import GrayCard from '../../GrayCard';

class StatusBar extends Component {
  render() {
    const { dataInfo, dataChart, hideInfo = false, hideChart = false, ...rest } = this.props;
    return (
      <GrayCard>
        <div className="status-bar-v2">
          {!hideInfo && <StatusBarInfo
            data={dataInfo}
            {...rest}
          />}
          {!hideChart && <StatusBarChart
            data={dataChart}
            {...rest}
          />}
        </div>
      </GrayCard>
    );
  }
}

export default StatusBar;
