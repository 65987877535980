import React, { useEffect, useState } from 'react';
import { FormGroup, Row, Col } from 'reactstrap';
import TagListTsx from '../TagList';
import { MAX_PAGE_SIZE, SearchEntity, TAG_ENTITY_TYPE } from '../../utils/Constants';
import Services from '../../utils/Services';
import MContext from '../../models/MContext';
import { Tag as TagModel } from '../../../models/tag';
import Notification from '../../utils/Notification';
import { sendAnalyticEventForAction } from '../../utils/SegmentAnalytics';

const TagInput = require('../TagInput').default;

interface TagProps {
  entityId: number,
  entityType: string,
  handleOnSelectChange: (tag: TagModel) => Promise<any>,
  handleOnDeleteTag: (tag: TagModel) => Promise<any>,
  ignoreWidthSize: boolean,
}

interface ServiceParam {
  pagination: { page: number, size: number, sorts: string[] },
  conditions: { key: string; operator: string; value: any; }[],
  type: string,
  ignoreAutoFill: boolean;
}

export const buildSearchCondition = (key: string, operator: string, value: any) => ({ key, operator, value });

function TagTsx(props: TagProps) {
  const { entityId, entityType, handleOnSelectChange, handleOnDeleteTag, ignoreWidthSize } = props;

  const [selectedTags, setSelectedTags] = useState<TagModel[]>([]);

  const projectId = MContext.projectId;

  const getSelectedTags = () => {
    const params: ServiceParam = {
      pagination: {
        page: 0,
        size: MAX_PAGE_SIZE,
        sorts: ['TagEntity.id, asc'],
      },
      conditions: [
        buildSearchCondition('Project.id', '=', projectId),
        buildSearchCondition('TagEntity.entityId', '=', entityId),
        buildSearchCondition('TagEntity.entityType', '=', entityType)
      ],
      type: SearchEntity.Tag,
      ignoreAutoFill: true,
    };

    Services.searchRecursively(0, params, [])
      .then((content: TagModel[]) => {
        setSelectedTags(content);
      });
  };

  useEffect(() => {
    getSelectedTags();
  }, []);

  const sendTrackingEvent = () => {
    let trackId;
    switch (entityType) {
      case TAG_ENTITY_TYPE.TEST_CASE:
        trackId = 'assign-tag-to-test-case';
        break;
      case TAG_ENTITY_TYPE.EXECUTION:
        trackId = 'assign-tag-to-test-run';
        break;
      default:
        return;
    }
    sendAnalyticEventForAction(trackId, {});
  };

  const handleSelectChange = (options: TagModel[]) => {
    sendTrackingEvent();
    const tagAdded = options
      .filter((tag: TagModel) => !selectedTags.find(({ name }) => tag.name === name));

    if (handleOnSelectChange && tagAdded.length > 0) {
      return handleOnSelectChange(tagAdded[0])
        .then(() => getSelectedTags());
    }
    return Promise.resolve();
  };

  const handleDelete = (option: TagModel) => {
    if (handleOnDeleteTag) {
      handleOnDeleteTag(option)
        .then(() => getSelectedTags())
        .catch((error) => {
          // Handle the error from handleOnDeleteTag or getSelectedTags
          Notification.pushError(error);
        });
    }
  };

  if (ignoreWidthSize) {
    return (
      <div className="tag">
        <FormGroup>
          <TagInput selectedTags={selectedTags} handleOnSelectChange={handleSelectChange} />
        </FormGroup>
        <TagListTsx selectedTags={selectedTags} handleOnDelete={handleDelete} />
      </div>
    );
  }

  return (
    <div className="tag">
      <Row>
        <Col sm="12" md="8" lg="8" xl="5">
          <FormGroup>
            <TagInput
              selectedTags={selectedTags}
              handleOnSelectChange={handleSelectChange}
            />
          </FormGroup>
        </Col>
      </Row>
      <div>
        <TagListTsx selectedTags={selectedTags} handleOnDelete={handleDelete} />
      </div>
    </div>
  );
}

export default TagTsx;
