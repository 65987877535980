export default {
  requirementTestRunCoverage: {
    id: 'requirement-test-run-coverage',
    main: 'application',
  },
  traceability: {
    id: 'traceability-report',
    main: 'application',
  },
  executionReport: {
    id: 'execution-report',
    main: 'test-execution',
  },
  testPerformance: {
    id: 'test-performance',
    main: 'test-execution',
  },
  frequencyReport: {
    id: 'frequency-report',
    main: 'test-execution',
  },
  flakinessReport: {
    id: 'flakiness-report',
    main: 'test-maintenance',
  },
  platformStatistics: {
    id: 'platform-statistics',
    main: 'test-maintenance',
  }
};
