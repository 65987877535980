import React, { Component } from 'react';
import { Alert, Button, ButtonToolbar, CustomInput, Form, FormGroup, Label } from 'reactstrap';
import { isNil } from 'lodash';
import Divider from '@mui/material/Divider';
import PropTypes from 'prop-types';
import Box from './Box';
import DecoratorConstants from '../../../utils/DecoratorConstants';
import Services from '../../../utils/Services';
import KEyesPixelBased from './KEyesPixelBased';
import {
  IconIndicator,
  IconIndicatorRed,
  IconIndicatorPink,
  IconCheckKeyes,
  IconTimesKeyes,
  IconTimesKeyesWhite,
  IconCheckKeyesWhite,
  IconDisplayKeyes,
  IconEyes,
  IconEyesSlash,
  IconIndicatorBlue,
  IconInfoAlert,
  IconIndicatorPurple,
  IconVisualTestingBot
} from '../../../images/CustomIcon';
import Tooltip from '../../../components/TooltipComponent';
import { t } from '../../../i18n/t';
import { COMPARISON_TYPE,
  IMAGE_TYPE,
  ZONE_TYPE,
  CONTENT_ZONE_TYPE,
  checkpointMatchStatus,
  buildCompareOptions,
  releaseDate,
  CHECKPOINT_STATUS
} from './Constants';
import Select from '../../../components/Select';
import { TestRunStatus, DEFAULT_AUTO_UPDATE_INTERVAL_TIME } from '../../../utils/Constants';
import KEyesAI from './KEyesAI';
import Time from '../../../utils/Moment';
import CheckBox from '../../../components/CheckBox';
import GroupEvent from '../../../utils/track/GroupEvent';
import DocumentLink from '../../../utils/DocumentLink';
import { getPixelAnalyzingStatus, getLayoutContentAnalyzingStatus, isComparisonMethodAnalyzing } from '../utils/VisualTestingHelper';
import { getFileUrl } from './Utils';
import ThresholdSlider from './ThresholdSlider';
import Spinner from '../../../components/Spinner';
import { sendAnalyticEventForAction } from '../../../utils/SegmentAnalytics';
import MFlags from '../../../models/MFlags';

class ComparisonView extends Component {

  constructor(props) {
    super(props);
    this.baselineRef = React.createRef();
    this.state = {
      showIgnoringZones: true,
      showDiff: {
        baseline: true,
        checkpoint: true
      },
      zoneHover: {
        index: -1,
        type: IMAGE_TYPE.BASELINE
      },
      control: {
        matchZone: true,
        diffPartZone: true,
        diffZone: true,
      },
      contentControl: {
        identicalZone: true,
        shiftedZone: true,
        missingNewZone: true
      },
      pixelSensitivity: this.props.data.checkpointPixel?.threshold ?? 0,
      isLoadingPreviewPixel: false,
      pixelTempDiffFile: null,
      pixelTempDiffCount: null,
      isDisablePixelSensitivity: false
    };
    this.toggleDiff = this.toggleDiff.bind(this);
    this.handleCompareOptionChange = this.handleCompareOptionChange.bind(this);
    this.renderDescription = this.renderDescription.bind(this);
    this.renderImageBaseline = this.renderImageBaseline.bind(this);
    this.setZoneHover = this.setZoneHover.bind(this);
    this.handleCheckboxClick = this.handleCheckboxClick.bind(this);
    this.handleContentCheckboxClick = this.handleContentCheckboxClick.bind(this);
    this.handleIgnoringZoneClick = this.handleIgnoringZoneClick.bind(this);
    this.handleThresholdChange = this.handleThresholdChange.bind(this);
    this.isPreviewPixelMode = this.isPreviewPixelMode.bind(this);

    this.timer = null;
    this.checkpointPixelTemporaries = null;

    const { data: { checkpointLayout, checkpointContent } } = props;
    this.layoutDiffZoneJsonFile = getFileUrl(checkpointLayout, 'layoutDiffZoneJsonFile.fileHandleId');
    this.contentDiffZoneJsonFile = getFileUrl(checkpointContent, 'contentDiffZoneJsonFile.fileHandleId');
  }

  setZoneHover(index, type) {
    this.setState((prevState) => {
      const zoneHover = { ...prevState.zoneHover };
      zoneHover.index = index;
      zoneHover.type = type;
      return { zoneHover };
    });
  }

  disableInput() {
    return !this.props.data.source || !this.props.data.baseline || this.props.data.matchStatus === checkpointMatchStatus.match;
  }

  disableInputWhenNotMethodPixelOrVSPro() {
    const { isMethodPixelOrVSPro } = this.props;
    return this.disableInput() || !isMethodPixelOrVSPro;
  }

  renderDescription(type) {
    return (
      <>
        <div className="image-wrapper__description-wrapper">
          <div className="image-wrapper__description">
            <b>{t(type.toLocaleLowerCase())}</b>
          </div>
          <div className="keyes-platform-des">
            {this.renderPlatformDescription()}
            {this.props.comparisonMethod === COMPARISON_TYPE.PIXEL
              ? this.renderControlDiff(type)
              : null}
          </div>
        </div>
      </>
    );
  }

  renderPlatformDescription() {
    const { width, height } = this.props.data;
    if (width && height) {
      return (
        <div className="checkpoint-comparison__platform-description">
          <Box>
            <span>
              <IconDisplayKeyes className="display-icon" />
            </span>
            {width}x{height}
          </Box>
        </div>
      );
    }
    return null;
  }

  renderDisplayOption() {
    if (this.props.comparisonMethod === COMPARISON_TYPE.LAYOUT) return this.renderControlZone();
    if (this.props.comparisonMethod === COMPARISON_TYPE.CONTENT) return this.renderControlZoneForContent();
    return null;
  }

  handleCheckboxClick(e) {
    const name = e.target.name;
    this.setState((prevState) => {
      const control = { ...prevState.control };
      control[name] = !control[name];
      return { control };
    });
  }

  handleContentCheckboxClick(e) {
    const name = e.target.name;
    this.setState((prevState) => {
      const contentControl = { ...prevState.contentControl };
      contentControl[name] = !contentControl[name];
      return { contentControl };
    });
  }

  handleIgnoringZoneClick() {
    this.setState((prevState) => {
      let showIgnoringZones = prevState.showIgnoringZones;
      showIgnoringZones = !showIgnoringZones;
      return { showIgnoringZones };
    });
  }

  renderControlZone() {
    const { matchZone, diffPartZone, diffZone } = this.state.control;
    const { comparisonMethod, data } = this.props;
    return (
      <>
        <CustomInput
          type="checkbox"
          label={
            <Tooltip text={t('identical-tooltip')} placement="bottom">
              <div className="inner-tooltip">
                <IconIndicator
                  className="indicator"
                />
                {t('identical')}
              </div>
            </Tooltip>
          }
          id="cb-zone-green"
          name={ZONE_TYPE.matchZone}
          className="display-option"
          checked={matchZone}
          disabled={
            this.disableInputWhenNotMethodPixelOrVSPro()
            || isComparisonMethodAnalyzing(comparisonMethod, data)
          }
          onChange={this.handleCheckboxClick}
        />
        <CustomInput
          type="checkbox"
          label={
            <Tooltip placement="bottom" text={t('distinguishable-tooltip')}>
              <div className="inner-tooltip">
                <IconIndicatorRed className="indicator" /> {t('distinguishable')}
              </div>
            </Tooltip>
          }
          className="display-option"
          name={ZONE_TYPE.diffPartZone}
          id="cb-zone-red"
          checked={diffPartZone}
          disabled={
            this.disableInputWhenNotMethodPixelOrVSPro()
            || isComparisonMethodAnalyzing(comparisonMethod, data)
          }
          onChange={this.handleCheckboxClick}
        />
        <CustomInput
          type="checkbox"
          label={
            <Tooltip text={t('new-missing-tooltip')} placement="bottom">
              <div className="inner-tooltip">
                <IconIndicatorPink className="indicator" /> {t('new-missing')}
              </div>
            </Tooltip>
          }
          className="display-option"
          name={ZONE_TYPE.diffZone}
          onChange={this.handleCheckboxClick}
          checked={diffZone}
          disabled={
            this.disableInputWhenNotMethodPixelOrVSPro()
            || isComparisonMethodAnalyzing(comparisonMethod, data)
          }
          id="cb-zone-pink"
          inline
        />
      </>
    );
  }

  renderControlForIgnoringZone() {
    const { showIgnoringZones } = this.state;
    const { isMethodPixelOrVSPro } = this.props;
    return (
      <>
        <CheckBox
          label={
            <Tooltip text={t('ignored-zones-tooltip')} placement="bottom">
              <div className="inner-tooltip">
                <IconIndicatorPurple className="indicator" />
                {t('ignored-zones')}
              </div>
            </Tooltip>
          }
          id="cb-zone-purple"
          name={ZONE_TYPE.ignoringZone}
          className="display-option"
          checked={showIgnoringZones}
          disabled={!isMethodPixelOrVSPro}
          onChange={this.handleIgnoringZoneClick}
          data-trackid="keyes-show-hide-ignoring-zone"
          data-groupid={GroupEvent.ACCESS_REPORT}
        />
      </>
    );
  }

  renderControlZoneForContent() {
    const { identicalZone, shiftedZone, missingNewZone } = this.state.contentControl;
    const { comparisonMethod, data } = this.props;
    return (
      <>
        <CustomInput
          type="checkbox"
          label={
            <Tooltip text={t('identical-zone-tooltip')} placement="bottom">
              <div className="inner-tooltip">
                <IconIndicator className="indicator" />
                {t('identical-zone')}
              </div>
            </Tooltip>
          }
          id="cb-zone-green"
          name={CONTENT_ZONE_TYPE.identicalZone}
          className="display-option"
          checked={identicalZone}
          onChange={this.handleContentCheckboxClick}
          disabled={
            this.disableInputWhenNotMethodPixelOrVSPro()
            || isComparisonMethodAnalyzing(comparisonMethod, data)
          }
        />
        <CustomInput
          type="checkbox"
          label={
            <Tooltip placement="bottom" text={t('shifted-zone-tooltip')}>
              <div className="inner-tooltip">
                <IconIndicatorBlue className="indicator" />
                {t('shifted-zone')}
              </div>
            </Tooltip>
          }
          className="display-option"
          name={CONTENT_ZONE_TYPE.shiftedZone}
          id="cb-zone-red"
          checked={shiftedZone}
          onChange={this.handleContentCheckboxClick}
          disabled={
            this.disableInputWhenNotMethodPixelOrVSPro()
            || isComparisonMethodAnalyzing(comparisonMethod, data)
          }
        />
        <CustomInput
          type="checkbox"
          label={
            <Tooltip text={t('missing-new-zone-tooltip')} placement="bottom">
              <div className="inner-tooltip">
                <IconIndicatorPink className="indicator" />
                {t('missing-new-zone')}
              </div>
            </Tooltip>
          }
          className="display-option"
          name={CONTENT_ZONE_TYPE.missingNewZone}
          onChange={this.handleContentCheckboxClick}
          checked={missingNewZone}
          disabled={
            this.disableInputWhenNotMethodPixelOrVSPro()
            || isComparisonMethodAnalyzing(comparisonMethod, data)
          }
          id="cb-zone-pink"
          inline
        />
      </>
    );
  }

  renderImageCheckpoint() {
    const { data, comparisonMethod, selectedIndex } = this.props;
    const { id, source, diffFile, ignoringZones } = data;
    const layoutDiffZoneJsonFile = this.layoutDiffZoneJsonFile;
    const contentDiffZoneJsonFile = this.contentDiffZoneJsonFile;
    const { showIgnoringZones } = this.state;
    const filterFollowMethod = comparisonMethod === COMPARISON_TYPE.CONTENT ? this.state.contentControl : this.state.control;
    const filter = { ...filterFollowMethod, showIgnoringZones };
    if (comparisonMethod === COMPARISON_TYPE.PIXEL) {
      const { pixelTempDiffFile } = this.state;
      return (
        <KEyesPixelBased
          type={IMAGE_TYPE.CHECKPOINT}
          source={source}
          showDiff={this.state.showDiff.checkpoint}
          diffFile={this.isPreviewPixelMode() ? pixelTempDiffFile : diffFile}
          renderDescription={this.renderDescription}
          canvasRef={this.baselineRef}
          showIgnoringZones={showIgnoringZones}
          ignoringZones={ignoringZones}
        />
      );
    } else {
      const jsonFile = comparisonMethod === COMPARISON_TYPE.CONTENT ? contentDiffZoneJsonFile : layoutDiffZoneJsonFile;
      return (
        <KEyesAI
          key={selectedIndex}
          type={IMAGE_TYPE.CHECKPOINT}
          source={source}
          jsonFile={jsonFile}
          diffFile={diffFile}
          filter={filter}
          zoneHover={this.state.zoneHover}
          setZoneHover={this.setZoneHover}
          renderDescription={this.renderDescription}
          canvasRef={this.baselineRef}
          mode={comparisonMethod}
          id={id}
          ignoringZones={ignoringZones}
        />
      );
    }
  }

  renderImageBaseline() {
    const { data, comparisonMethod, selectedIndex } = this.props;
    const { id, baseline, diffFile, ignoringZones } = data;
    const layoutDiffZoneJsonFile = this.layoutDiffZoneJsonFile;
    const contentDiffZoneJsonFile = this.contentDiffZoneJsonFile;
    const { showIgnoringZones } = this.state;
    if (comparisonMethod === COMPARISON_TYPE.PIXEL) {
      const { pixelTempDiffFile } = this.state;
      return (
        <KEyesPixelBased
          type={IMAGE_TYPE.BASELINE}
          source={baseline}
          showDiff={this.state.showDiff.baseline}
          diffFile={this.isPreviewPixelMode() ? pixelTempDiffFile : diffFile}
          renderDescription={this.renderDescription}
          canvasRef={this.baselineRef}
          showIgnoringZones={showIgnoringZones}
          ignoringZones={ignoringZones}
        />
      );
    } else {
      const jsonFile = comparisonMethod === COMPARISON_TYPE.CONTENT ? contentDiffZoneJsonFile : layoutDiffZoneJsonFile;
      const filterFollowMethod = comparisonMethod === COMPARISON_TYPE.CONTENT ? this.state.contentControl : this.state.control;
      const filter = { ...filterFollowMethod, showIgnoringZones };
      return (
        <KEyesAI
          key={selectedIndex}
          type={IMAGE_TYPE.BASELINE}
          source={baseline}
          jsonFile={jsonFile}
          diffFile={diffFile}
          filter={filter}
          zoneHover={this.state.zoneHover}
          setZoneHover={this.setZoneHover}
          renderDescription={this.renderDescription}
          canvasRef={this.baselineRef}
          mode={comparisonMethod}
          id={id}
          ignoringZones={ignoringZones}
        />
      );
    }
  }

  toggleDiff(type) {
    this.setState((prevState) => {
      const showDiff = { ...prevState.showDiff };
      showDiff[type] = !showDiff[type];
      return { showDiff };
    });
  }

  renderMatchStatus() {
    const { matchStatus } = this.props.data;
    return <div>{DecoratorConstants.statusBadge(matchStatus)}</div>;
  }

  renderControlDiff(type) {
    const { showDiff } = this.state;
    const controlIcon = showDiff[type] ? (
      <IconEyes className="icon-status-control" />
    ) : (
      <IconEyesSlash className="icon-status-control" />
    );
    return (
      <div className="checkpoint-comparison__control__diff-new">
        <ButtonToolbar>
          <Button
            color="link"
            variant="text"
            onClick={() => this.toggleDiff(type)}
            disabled={this.disableInput()}
          >
            {controlIcon} {t('diffs')}
          </Button>
        </ButtonToolbar>
      </div>
    );
  }

  handleCompareOptionChange(e) {
    this.props.setComparisonMethod(e.value);
  }

  handleThresholdChange(value) {
    sendAnalyticEventForAction('keyes-preview-pixel-tolerance', { 'data-groupid': GroupEvent.ACCESS_REPORT });
    clearInterval(this.timer);

    const { data: { checkpointPixel } } = this.props;
    if (value === checkpointPixel?.threshold) { // Default Sensitivity
      this.setState({ pixelSensitivity: checkpointPixel?.threshold ?? 0, isLoadingPreviewPixel: false });
    } else {
      // Find from cache
      if (this.checkpointPixelTemporaries) {
        const cpt = this.checkpointPixelTemporaries?.find((item) => item.threshold === value && !!item.diffFile);
        if (cpt) {
          const diffFile = getFileUrl(cpt, 'diffFile.fileHandleId');
          this.setState({ pixelTempDiffFile: diffFile, isLoadingPreviewPixel: false, pixelTempDiffCount: cpt.diffCount, pixelSensitivity: value });
          return;
        }
      }

      // Create event and listen for result
      this.setState({ pixelSensitivity: value });
      if (checkpointPixel?.id) {
        this.setState({ isLoadingPreviewPixel: true, isDisablePixelSensitivity: true });
        const maxRetry = 36; // 36 x DEFAULT_AUTO_UPDATE_INTERVAL_TIME = 3mins
        Services.createPreviewCheckpointPixel(checkpointPixel.id)
          .then(() => {
            let tryCount = 0;
            this.timer = setInterval(() => {
              this.handlePreviewPixel();
              if (++tryCount >= maxRetry) {
                clearInterval(this.timer);
              }
            }, DEFAULT_AUTO_UPDATE_INTERVAL_TIME);
            this.setState({ isDisablePixelSensitivity: false });
          });
      }
    }
  }

  handlePreviewPixel() {
    const { data: { checkpointPixel } } = this.props;
    const { pixelSensitivity } = this.state;
    Services.getPreviewCheckpointPixel(checkpointPixel.id)
      .then((cp) => {
        const tmp = cp.checkpointPixelTemporaries?.find((item) => item.threshold === pixelSensitivity && !!item.diffFile);
        if (tmp) {
          const diffFile = getFileUrl(tmp, 'diffFile.fileHandleId');
          clearInterval(this.timer);
          this.checkpointPixelTemporaries = cp.checkpointPixelTemporaries;
          this.setState({ pixelTempDiffFile: diffFile, isLoadingPreviewPixel: false, pixelTempDiffCount: tmp.diffCount });
        }
      });
  }

  isPreviewPixelMode() {
    const { data: { checkpointPixel } } = this.props;
    const { pixelSensitivity } = this.state;
    return checkpointPixel?.threshold !== pixelSensitivity;
  }

  renderCompareOption() {
    const { comparisonMethod, isVisualTestingPro, aiEnabled } = this.props;
    const { checkpointLayout, checkpointContent, checkpointPixel, matchStatus } = this.props.data;
    const { pixelSensitivity, pixelTempDiffCount, isDisablePixelSensitivity } = this.state;
    const { vstSettingsFromAccountLevel } = MFlags;

    let enabledOptions = true;
    if (vstSettingsFromAccountLevel) {
      enabledOptions = aiEnabled;
    }

    return (
      <div className="checkpoint-comparison__comparisonOption">
        <Form inline className="w-100 mt-1">
          <FormGroup className="mb-4 mr-sm-2 mb-sm-0 comparison-method-wrapper">
            <Label className="mr-sm-2">{t('comparison-method')}</Label>
            <Select
              options={buildCompareOptions({
                pixel: getPixelAnalyzingStatus(checkpointPixel),
                layout: getLayoutContentAnalyzingStatus(checkpointLayout, 'layoutDiffZoneJsonFile'),
                content: getLayoutContentAnalyzingStatus(checkpointContent, 'contentDiffZoneJsonFile')
              }, enabledOptions)}
              defaultValue={{ value: comparisonMethod, label: t(comparisonMethod) }}
              onChange={this.handleCompareOptionChange}
              className="checkpoint-comparison__compare-option"
              id="compareOption"
              useAutocomplete={false}
              isDisabled={this.disableInput()}
            />
            {((comparisonMethod === COMPARISON_TYPE.PIXEL)
              && (matchStatus === CHECKPOINT_STATUS.MATCH || matchStatus === CHECKPOINT_STATUS.MISMATCH)) &&
              <>
                <Tooltip text={t('visual-testing#pixel-sensitivity#tootip-preview')} placement="bottom">
                  <div className="inner-tooltip">
                    <Label className="mr-sm-2 ml-2">{t('visual-testing#pixel-sensitivity')}</Label>
                  </div>
                </Tooltip>
                <ThresholdSlider
                  disabled={!isVisualTestingPro || isDisablePixelSensitivity}
                  value={pixelSensitivity}
                  sx={{
                    width: '13rem',
                    marginRight: '1rem',
                    marginLeft: '1.5rem',
                    marginTop: '1.5rem',
                    fontSize: '0.857rem',
                  }}
                  onChange={this.handleThresholdChange}
                />
                {(this.isPreviewPixelMode() && !isNil(pixelTempDiffCount)) &&
                  <Label className="sm-2 mr-4 checkpoint-comparison__diff-count">{t('visual-testing#pixel-sensitivity#diffs', { diff: pixelTempDiffCount })}</Label>}
              </>}
          </FormGroup>
          {this.renderControlForIgnoringZone()}
          {this.renderDisplayOption()}
        </Form>
      </div>
    );
  }

  renderControlStatus() {
    const { id, status } = this.props.data;
    const isPassed = status === TestRunStatus.PASSED;
    const isFailed = status === TestRunStatus.FAILED;
    const { isMethodPixelOrVSPro, comparisonMethod, data } = this.props;

    return (
      <div className={`checkpoint-comparison__control__status checkpoint-comparison__control__status-new ${comparisonMethod === COMPARISON_TYPE.PIXEL ? 'mt-3' : ''}`}>
        <ButtonToolbar>
          {isPassed ? (
            <Button
              color="success"
              disabled={isPassed}
              onClick={() => this.resolveCheckpoint(id, TestRunStatus.PASSED)}
              title={t('totalPassedTests')}
              size="large"
            >
              <IconCheckKeyesWhite className="icon-status-control" /> {t('totalPassedTests')}
            </Button>
          ) : (
            <Button
              onClick={() => this.resolveCheckpoint(id, TestRunStatus.PASSED)}
              title={t('mark-as-passed')}
              size="large"
              data-trackid="keyes-mark-passed"
              data-groupid="access-report"
              disabled={
                !isMethodPixelOrVSPro
                || isComparisonMethodAnalyzing(comparisonMethod, data)
              }
            >
              <IconCheckKeyes className="icon-status-control" /> {t('mark-as-passed')}
            </Button>
          )}
        </ButtonToolbar>
        <ButtonToolbar>
          {isFailed ? (
            <Button
              color="danger"
              className={`two-state-button ${isFailed ? 'active' : ''}`}
              disabled={isFailed}
              onClick={() => this.resolveCheckpoint(id, TestRunStatus.FAILED)}
              title={t('totalFailedTests')}
              size="large"
            >
              <IconTimesKeyesWhite className="icon-status-control" /> {t('totalFailedTests')}
            </Button>
          ) : (
            <Button
              className={`two-state-button ${isFailed ? 'active' : ''}`}
              onClick={() => this.resolveCheckpoint(id, TestRunStatus.FAILED)}
              title={t('mark-as-failed')}
              size="large"
              data-trackid="keyes-mark-failed"
              data-groupid="access-report"
              disabled={
                !isMethodPixelOrVSPro
                || isComparisonMethodAnalyzing(comparisonMethod, data)
              }
            >
              <IconTimesKeyes className="icon-status-control" /> {t('mark-as-failed')}
            </Button>
          )}
        </ButtonToolbar>
      </div>
    );
  }

  resolveCheckpoint(checkpointId, status) {
    const { refreshData } = this.props;
    Services.resolveCheckpoint(checkpointId, status).then(() => {
      if (refreshData) {
        refreshData();
      }
    });
  }

  renderMessage() {
    const { comparisonMethod, data } = this.props;
    const { createdAt, layoutDiffZoneJsonFile, contentDiffZoneJsonFile } = data;
    return (
      (Time.convertToDate(createdAt) < releaseDate) &&
      ((comparisonMethod === COMPARISON_TYPE.LAYOUT && !layoutDiffZoneJsonFile) ||
        (comparisonMethod === COMPARISON_TYPE.CONTENT && !contentDiffZoneJsonFile)) &&
      !this.disableInput() &&
      <Alert className="checkpoint-comparison__alert">
        <div className="checkpoint-comparison__message">
          <IconInfoAlert className="checkpoint-comparison__alert-icon" />
          <span>
            {t('visual-testing#alert1')}
          </span>
        </div>
      </Alert>);
  }

  renderCompareBaselines() {
    const { isLoadingPreviewPixel } = this.state;
    if (isLoadingPreviewPixel) {
      return (
        <div className="checkpoint-comparison__loading-spinner">
          <Spinner />
        </div>
      );
    }
    return (
      <div className="image-row-wrapper">
        {this.renderImageBaseline()}
        <Divider className="mx-2" orientation="vertical" flexItem />
        {this.renderImageCheckpoint()}
      </div>);
  }

  renderUpgradeVisualTestingSubscriptionMessage() {
    return (
      <div className="upgrade-visual-testing-subscription-wrapper">
        <IconVisualTestingBot className="icon" />
        <p className="content">{t('visual-testing#plan-validation#message')}
          <a
            href={DocumentLink.ABOUT_VISUAL_TESTING_PRO}
            target="_blank"
            rel="noopener noreferrer"
            data-trackid="keyes-view-upgrade-subscription-doc"
            data-groupid={GroupEvent.ACCESS_REPORT}
          >
            {t('upgradeSubscriptions')}
          </a>.
        </p>
      </div>);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  render() {
    const { isMethodPixelOrVSPro } = this.props;
    return (
      <div className="checkpoint-comparison">
        <div className="checkpoint-comparison__control">
          {this.renderCompareOption()}
          {this.renderControlStatus()}
        </div>
        {isMethodPixelOrVSPro && this.renderMessage()}
        <Divider />
        {isMethodPixelOrVSPro ? this.renderCompareBaselines() : this.renderUpgradeVisualTestingSubscriptionMessage()}
      </div>
    );
  }
}

ComparisonView.propTypes = {
  comparisonMethod: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  setComparisonMethod: PropTypes.func.isRequired,
  isMethodPixelOrVSPro: PropTypes.func.isRequired,
};


export default ComparisonView;
