import _ from 'lodash';
import React from 'react';
import MTableColumnDataMapping from '../../components/table/models/MTableColumnDataMapping';
import { t } from '../../i18n/t';
import { IconReImport } from '../../images/CustomIcon';
import MContext from '../../models/MContext';
import { ExecutionDecorator } from '../../pages/execution/ExecutionDecorator';
import DecoratorConstants from '../../utils/DecoratorConstants';
import Routes from '../../utils/Routes';
import Services from '../../utils/Services';
import ActionDropdownItem from '../action/ActionDropdownItem';
import { inputFilter, startTimeFilter, statusFilter, userFilter } from '../search-query/FilterQueryHelper';
import { buildSortQuery } from '../search-query/SortQueryHelper';
import { buildSearchCondition } from '../search/SearchUtils';
import DataTable from './DataTable';
import ActionDropdownMenu from '../action/ActionDropdownMenu';
import { IconDelete } from '../../images/KitIcons';
import MAuth from '../../models/MAuth';
import DeleteExecutionDialog from '../dialog/DeleteExecutionDialog';
import GroupEvent from '../../utils/track/GroupEvent';
import { IconVisualTestingWelcome } from '../../images/CustomNewIcon';
import DocumentLink from '../../utils/DocumentLink';
import { BaselineCollectionDecorator } from '../../pages/keyes/BaselineCollectionDecorator';
import VisualTestingExceedQuotaWarning from '../warning_packages/VisualTestingExceedQuotaWarning';
import AcknowledgeDialog from '../dialog/AcknowledgeDialog';

class KEyesExecutionTable extends React.Component {

  constructor(props) {
    super(props);
    this.teamId = MContext.teamId;
    this.projectId = MContext.projectId;
    this.isTeamDemo = MContext.isTeamDemo;
    this.state = {
      rowOrder: null,
      projectId: null,
      users: [],
      isOpenDialogAcknowledgeReimport: false,
    };
    this.getUsers = this.getUsers.bind(this);
    this.renderReImportButton = this.renderReImportButton.bind(this);
    this.handleCloseReimportDialog = this.handleCloseReimportDialog.bind(this);
    this.getOrderFromRow = this.getOrderFromRow.bind(this);
    this.deleteRef = React.createRef();
  }

  componentDidMount() {
    this.getUsers();
  }

  getOrderFromRow(row) {
    return _.get(row, 'order');
  }

  getUsers() {
    const params = {
      pagination: {
        page: 0,
        size: 1000000,
        sorts: ['id,asc'],
      },
      conditions: [buildSearchCondition('Team.id', '=', this.teamId)],
      type: 'UserTeam',
    };

    Services.search(params)
      .then(({ content }) => {
        this.setState({
          users: content,
        });
      });
  }

  renderReImportButton(row) {
    return (
      <ActionDropdownItem
        data-trackid="re-import-keyes-execution"
        icon={IconReImport}
        label="Re-import"
        tag="button"
        onClick={() => this.handleReimportKeyesExecution(row.id)}
        color="link"
        title="Re-import"
      />
    );
  }

  handleReimportKeyesExecution(id) {
    const { visualTestingPlan } = this.props;
    const isShowDialog = visualTestingPlan?.remainingQuota < 0;
    if (isShowDialog) {
      this.setState({
        isOpenDialogAcknowledgeReimport: isShowDialog
      });
    } else {
      Services.reimportKeyesExecution(id);
    }
  }

  renderReimportDialogs() {
    const { isOpenDialogAcknowledgeReimport } = this.state;
    return (
      <AcknowledgeDialog
        isOpen={isOpenDialogAcknowledgeReimport}
        id="acknowledge-reimport-dialog"
        handleClose={this.handleCloseReimportDialog}
        handleAcknowledge={this.handleCloseReimportDialog}
        title={t('acknowledge-reimport-execution-title')}
        content={t('acknowledge-reimport-execution-content')}
      />
    );
  }

  handleCloseReimportDialog() {
    this.setState({
      isOpenDialogAcknowledgeReimport: false,
    });
  }

  showDeleteExecutionDialog(projectId, rowOrder) {
    this.setState(
      {
        rowOrder,
        projectId,
      },
      () => this.deleteRef.current.toggle(),
    );
  }

  renderDeleteButton(row) {
    const { teamId } = this;
    const isShowDeleteExecutionIcon = MAuth.isTeamManager(teamId);
    if (isShowDeleteExecutionIcon) {
      return (
        <ActionDropdownItem
          icon={IconDelete}
          label="Delete"
          tag="button"
          onClick={() => this.showDeleteExecutionDialog(this.projectId, row.execution.order)}
          color="link"
          title="Delete Execution"
        />
      );
    } else {
      return null;
    }
  }

  renderDeleteDialogs() {
    const { projectId, rowOrder } = this.state;
    return (
      <DeleteExecutionDialog
        ref={this.deleteRef}
        executionId={rowOrder}
        projectId={projectId}
        afterCreate={() => this.clearSelectedExecution()}
      />
    );
  }

  renderEmptyMessage() {
    return (
      <div className="visual-testing-welcome">
        <IconVisualTestingWelcome className="icon" />
        <h3 className="title">{t('visual-testing#welcome#title')}</h3>
        <p className="content">{t('visual-testing#welcome#content')}</p>
        <div>
          <a
            href={DocumentLink.VISUAL_TESTING_SETUP}
            target="_blank"
            rel="noopener noreferrer"
            className="btn button-setup"
            data-trackid="page-visual-testing-view-setup-doc"
            data-groupid={GroupEvent.ACCESS_REPORT}
          >
            {t('visual-testing#welcome#setup')}
          </a>
          <a
            href={DocumentLink.VISUAL_TESTING_OVERVIEW}
            target="_blank"
            rel="noopener noreferrer"
            className="btn button-learn-more"
            data-trackid="page-visual-testing-view-overview-doc"
            data-groupid={GroupEvent.ACCESS_REPORT}
          >
            {t('learn_more')}
          </a>
        </div>
      </div>
    );
  }

  render() {
    const defaultSort = [
      'id,desc',
    ];

    const columnMapping = [
      new MTableColumnDataMapping(
        t('status'),
        'status',
        DecoratorConstants.statusDecorator,
        undefined,
        '',
      ),
      new MTableColumnDataMapping(
        t('id'),
        'execution.order',
        (name, row) => {
          const execution = row.execution;
          const constructedLink = new Routes({
            executionId: row.execution.order,
            projectId: execution.project.id,
          });
          const linkProps = {
            'data-trackid': 'click-visual-test-result-details',
            'data-groupid': GroupEvent.ACCESS_REPORT
          };
          return DecoratorConstants.idDecorator(name, row, constructedLink.keyes_execution_details_link, null, null, linkProps);
        },
        undefined,
        '',
      ),
      new MTableColumnDataMapping(
        t('table-header#testsuite'),
        '',
        (name, row) => {
          const execution = row.execution;
          const nameContent = <span className="label-text">{ExecutionDecorator.nameDecorator(execution, null, true)}</span>;
          const profileContent = <>{ExecutionDecorator.profileDecorator(execution)}</>;
          const platformContent = <>{ExecutionDecorator.platformDecorator(execution)}</>;

          return (
            <>
              <div>{nameContent}</div>
              <div>{platformContent} {profileContent}</div>
            </>
          );
        },
        undefined,
        '',
        undefined,
        '25%',
      ),
      new MTableColumnDataMapping(
        t('compare_baseline_collection'),
        'baselineCollection',
        BaselineCollectionDecorator.comparedBaselineDecorator,
        undefined,
        '',
        undefined,
        '20%'
      ),
      new MTableColumnDataMapping(
        t('table-header#total'),
        'totalCheckpoints',
        DecoratorConstants.keyesTotalTestRunDecorator,
      ),
      new MTableColumnDataMapping(
        DecoratorConstants.passedBadgeDecorator(t('table-header#totalPassed')),
        'passedCheckpoints',
        DecoratorConstants.keyesPassedCountDecorator,
      ),
      new MTableColumnDataMapping(
        DecoratorConstants.failedBadgeDecorator(t('table-header#totalFailed')),
        'failedCheckpoints',
        DecoratorConstants.keyesFailedCountDecorator,
      ),
      new MTableColumnDataMapping(
        DecoratorConstants.unresolvedBadgeDecorator(t('table-header#totalUnresolved')),
        'unresolvedCheckpoints',
        DecoratorConstants.keyesUnresolvedCountDecorator,
      ),
      new MTableColumnDataMapping(
        t('time'),
        '',
        (name, row) => DecoratorConstants.timeAndDurationDecorator(name, row.execution),
        undefined,
        'nowrap-column',
        undefined,
        '10%',
      ),
      new MTableColumnDataMapping(
        'By',
        'execution.user',
        (name, row) => DecoratorConstants.avatarOnlyDecorator(name, row),
        undefined,
        '',
      ),
      new MTableColumnDataMapping(
        t('table-header#action'),
        'id',
        (name, row) => {
          const id = _.get(row, name);
          const items = [
            this.renderDeleteButton(row),
            this.renderReImportButton(row)
          ];
          return (
            <ActionDropdownMenu hideHeader rowId={`execution_${id}`} direction="left">
              {items}
            </ActionDropdownMenu>
          );
        },
        true,
      ),
    ];

    const { users } = this.state;
    const filterQuery = [
      inputFilter('TestSuite.name', 'Test Suite\'s Name', '~'),
      inputFilter('TestSuiteCollectionEntity.name', 'Test Suite Collection\'s Name', '~'),
      statusFilter('status'),
      startTimeFilter('startTime'),
      userFilter('User.id', users),
    ];
    const sortQuery = [
      ...buildSortQuery('order', t('table-header#id')),
      ...buildSortQuery('startTime', t('table-header#startTime')),
      ...buildSortQuery('duration', t('table-header#duration')),
    ];
    const newProps = {
      ...this.props,
      defaultSort,
      columnMapping,
      filterQuery,
      sortQuery,
    };

    newProps.emptyMessage = this.renderEmptyMessage();
    newProps.showEmptyMessage = true;

    return (
      <>
        {!this.isTeamDemo && <VisualTestingExceedQuotaWarning />}
        <DataTable
          useSortByColumn
          renderSelect={this.renderSelected}
          entityType="KeyesExecution"
          fixLayout
          {...newProps}
        />
        {this.renderDeleteDialogs()}
        {this.renderReimportDialogs()}
      </>
    );
  }
}

export default KEyesExecutionTable;
