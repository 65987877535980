import React from 'react';
import { Label, FormGroup, Form } from 'reactstrap';
import countryList from 'country-list';
import { t } from '../../i18n/t';
import Input from '../../components/Input';


export default class extends React.Component {

  render() {
    const {
      billingInfo: {
        address1 = '', address2 = '',
        city = '', state = '', country = '', postalCode = '',
        vatNumber = '', fullBusinessName = '', ccEmails = ''
      } = {}
    } = this.props;
    const { title = t('payment#billing_information') } = this.props;

    return (
      <div>
        {title && (
          <div className="checkout__title">{title}</div>
        )}
        <Form className="billing-information-review">
          <FormGroup>
            <Label for="billing-info-ccEmails">{t('payment#billing_information@ccEmails')}</Label>
            <Input plaintext id="billing-info-ccEmails" title={ccEmails}>{ccEmails}</Input>
          </FormGroup>
          <FormGroup>
            <Label for="billing-info-address1">{t('payment#billing_information@address1')}</Label>
            <Input plaintext id="billing-info-address1" title={address1}>{address1}</Input>
          </FormGroup>
          <FormGroup>
            <Label for="billing-info-address2">{t('payment#billing_information@address2')}</Label>
            <Input plaintext id="billing-info-address2" title={address2}>{address2}</Input>
          </FormGroup>
          <FormGroup>
            <Label for="billing-info-city">{t('payment#billing_information@city')}</Label>
            <Input plaintext id="billing-info-city" title={city}>{city}</Input>
          </FormGroup>
          <FormGroup>
            <Label for="billing-info-state">{t('payment#billing_information@state')}</Label>
            <Input plaintext id="billing-info-state" title={state}>{state}</Input>
          </FormGroup>
          <FormGroup>
            <Label for="billing-info-country">{t('payment#billing_information@country')}</Label>
            <Input plaintext id="billing-info-country" title={countryList.getName(country)}>{countryList.getName(country)}</Input>
          </FormGroup>
          <FormGroup>
            <Label for="billing-info-postal_code">{t('payment#billing_information@postal_code')}</Label>
            <Input plaintext id="billing-info-postal_code" title={postalCode}>{postalCode}</Input>
          </FormGroup>
          <FormGroup>
            <Label for="billing-info-vat_number">{t('payment#billing_information@vat_number')}</Label>
            <Input plaintext id="billing-info-vat_number" title={vatNumber}>{vatNumber}</Input>
          </FormGroup>
          <FormGroup>
            <Label for="billing-info-full_business_name">{t('payment#billing_information@full_business_name')}</Label>
            <Input plaintext id="billing-info-full_business_name" title={fullBusinessName}>{fullBusinessName}</Input>
          </FormGroup>
        </Form>
      </div>
    );
  }
}
