/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react';
import { values, filter, toString, sortBy, remove, isEmpty, union } from 'lodash';
import { debounce } from '@mui/material/utils';
import { Card, CardContent as CardBody, ThemeProvider } from '@mui/material';
import { theme } from '@katalon-studio/katalon-ui/Theme';
import ObjectSummary from '../../components/summary/ObjectSummary';
import DefaultLayout from '../../components/DefaultLayout';
import { t } from '../../i18n/t';
import { IntegrationType, ACTIONS_TRACKING } from '../../utils/Constants';
import { IntegrationRootFolderIDs } from '../../models/model/IntegrationRootFolderIDs';
import { IntegrationPageTrackingIds } from '../../models/model/IntegrationPageTrackingIds';
import { IntegrationTypeEntity } from '../../models/model/IntegrationTypeEntity';
import TreeViewComponent from './components/TreeViewComponent';
import UrlHelper from '../../utils/UrlHelper';
import IntegrationBlocks from './components/IntegrationBlocks';
import IntegrationDetail from './components/IntegrationDetail';
import IntegrationService from './service/IntegrationService';
import MContext from '../../models/MContext';
import Helper from '../../utils/Helper';
import { sendAnalyticEventForAction } from '../../utils/SegmentAnalytics';
import MAuth from '../../models/MAuth';
import Routes from '../../utils/Routes';
import { TreeItemEntity } from '../../models/model/TreeItemEntity';
import MFlags from '../../models/MFlags';
import SearchInput from './components/SearchInput';

const handleListIntegrations = () : IntegrationTypeEntity[] => {
  let integrationTypeOptions = values(IntegrationType);

  const { jenkinsIntegrationEnabled, dailyExecutionEmailEnabled, katalonCliIntegrationEnabled } = MFlags;
  if (!jenkinsIntegrationEnabled) {
    remove(integrationTypeOptions, (integration) => integration === IntegrationType.JENKINS);
  }

  if (!dailyExecutionEmailEnabled) {
    remove(integrationTypeOptions, (integration) => integration === IntegrationType.EMAIL_SETTINGS);
  }

  if (!katalonCliIntegrationEnabled) {
    remove(integrationTypeOptions, (integration) => integration === IntegrationType.KATALON_CLI);
  }

  const disablePlugins = [
    IntegrationType.JUNIT4.id,
    IntegrationType.JUNIT5.id,
    IntegrationType.CYPRESS.id,
    IntegrationType.TESTNG.id,
  ];

  // Ignore disable plugins
  integrationTypeOptions = filter(integrationTypeOptions, ({ id }) => !disablePlugins.includes(id));

  // Transform to alphabet
  integrationTypeOptions = Helper.transformToAlphabet(integrationTypeOptions);

  // move katalon studio option to first
  integrationTypeOptions = sortBy(integrationTypeOptions, ({ id }) => (id === IntegrationType.KATALON_STUDIO_INTEGRATION.id ? 0 : 1));

  return integrationTypeOptions;
};

function IntegrationsPageV2() {
  const isTeamDemo = MContext.isTeamDemo;
  const teamId = MContext.teamId;
  const isTeamManager = MAuth.isTeamManager(teamId);

  const DataTreeItems: TreeItemEntity[] = IntegrationService.generateIntegrationDataTreeItems(isTeamManager);
  const originalIntegrationTypeOptions: IntegrationTypeEntity[] = handleListIntegrations();
  const [currentIntegrationOptions, setCurrentIntegrationOptions] = useState<IntegrationTypeEntity[]>(originalIntegrationTypeOptions);
  const [currentFolderId, setCurrentFolderId] = useState<string>(IntegrationRootFolderIDs.DEFAULT);
  const [selectedKeys, setSelectedKeys] = useState<string[]>([currentFolderId]);
  const [expandedKeys, setExpandedKeys] = useState<string[]>([currentFolderId]);

  const searchRef = useRef<any>();
  const [searchValue, setSearchValue] = useState('');
  const [isSearching, updateSearchStatus] = useState(false);
  const [filteredIntegrations, setFilteredIntegrations] = useState<IntegrationTypeEntity[]>([]);

  const { searchIntegrationItemsEnabled } = MFlags;

  const isCategoryFolder = (currentNodeId: string) => {
    if (currentNodeId in IntegrationRootFolderIDs) {
      return true;
    }
    return false;
  };

  const updateUrlParams = (currentFolderId: string) => {
    const paramsValue = [{
      paramName: 'type',
      value: currentFolderId.toLowerCase(),
    }];
    UrlHelper.setUrlParamsAndUpdate(paramsValue);
  };

  const getParentIdOfIntegrationType = (integrationId: string): string => {
    let parentFolderId = '';
    for (const item of DataTreeItems) {
      if (item.children) {
        const matchItem = item.children.find(({ id }) => integrationId === id);
        if (matchItem) {
          parentFolderId = item.id;
          break;
        }
      }
    }
    return parentFolderId;
  };

  const updateExpandKeys = (key: string) => {
    let parentFolderId: string = key;
    if (!isCategoryFolder(key)) {
      parentFolderId = getParentIdOfIntegrationType(key);
    }
    if (!expandedKeys.includes(parentFolderId)) {
      const newExpandedKeys = [...expandedKeys, parentFolderId];
      setExpandedKeys(newExpandedKeys);
    }
  };

  const getCategoryFolderId = (type: string) => {
    const key = type.toUpperCase();
    if (isCategoryFolder(key)) {
      return IntegrationRootFolderIDs[key];
    }
    return null;
  };

  const updateIntegrationOptions = (key: string) => {
    const category = filter(DataTreeItems, (value) => value.id === key)[0];
    if (category.children) {
      const integrationIds = category.children.map(({ id }) => id);
      const belongingIntegrations = filter(originalIntegrationTypeOptions, ({ id }) => integrationIds.includes(id));
      setCurrentIntegrationOptions(belongingIntegrations);
    }
  };

  const displayCorrectFolderAndPage = (key: string) => {
    updateUrlParams(key);
    setCurrentFolderId(key);
    setSelectedKeys([key]);
    updateExpandKeys(key);
  };

  const selectDefaultIntegration = () => {
    const type = UrlHelper.getSearchParam('type');

    if (!type) {
      return;
    }

    const categoryFolderId = getCategoryFolderId(type);
    if (categoryFolderId) {
      updateIntegrationOptions(type.toUpperCase());
      displayCorrectFolderAndPage(categoryFolderId);
    } else {
      const integration = originalIntegrationTypeOptions.find(({ id }) => type === id.toLowerCase());
      if (!integration) {
        UrlHelper.removeAllParams();
        return;
      }

      const adminOptions: IntegrationTypeEntity[] = filter(originalIntegrationTypeOptions, (value) => value?.isTeamManager === true);
      if (!isTeamManager && adminOptions.includes(integration)) {
        Routes.goToAccessDeniedPage();
        return;
      }

      displayCorrectFolderAndPage(integration.id);
    }
  };

  useEffect(() => {
    selectDefaultIntegration();
  }, []);

  const syncTrackingData = (integrationId: string, triggerFrom: string) => {
    const integration = filter(originalIntegrationTypeOptions, (value) => value.id === integrationId)[0];
    sendAnalyticEventForAction(
      ACTIONS_TRACKING.INTEGRATION_DETAIL_PAGE_OPENED,
      {
        integration_name: integration.label,
        trigger_from: triggerFrom
      }
    );
  };

  const closeSearchProcess = () => {
    if (isSearching && searchRef.current) {
      searchRef.current.clearSearchInput();
    }
  };

  const handleSelect = (nodeIds: string[]) => {
    const node: string = toString(nodeIds);
    setCurrentFolderId(node);
    setSelectedKeys([node]);
    closeSearchProcess();

    const isRootFolder: boolean = isCategoryFolder(node);
    if (isRootFolder) {
      if (node === IntegrationRootFolderIDs.DEFAULT) {
        UrlHelper.removeAllParams();
        setCurrentIntegrationOptions(originalIntegrationTypeOptions);
      } else {
        updateUrlParams(node);
        updateIntegrationOptions(node);
      }
    } else {
      updateUrlParams(node);
      syncTrackingData(node, IntegrationPageTrackingIds.TREE_VIEW);
    }
  };

  const handleToggle = (nodeIds: string[]) => {
    setExpandedKeys(nodeIds);
  };

  const renderSideLeft = () => (
    <div className="side-left">
      <TreeViewComponent
        key={currentFolderId}
        data={DataTreeItems}
        onSelect={handleSelect}
        onExpand={handleToggle}
        defaultSelectedKeys={selectedKeys}
        defaultExpandedKeys={expandedKeys}
        customDisabledToolTip={t('integration#restrict-access-title')}
      />
    </div>
  );

  const onBlockClick = (integrationId: string) => {
    syncTrackingData(integrationId, IntegrationPageTrackingIds.BLOCKS);
    displayCorrectFolderAndPage(integrationId);
    closeSearchProcess();
  };

  const onClear = () => {
    setSearchValue('');
    updateSearchStatus(false);
    setFilteredIntegrations([]);
  };

  const onChangeInput = debounce((value: string) => {
    setSearchValue(value);
  }, 200);

  useEffect(() => {
    if (searchValue) {
      updateSearchStatus(true);
      const stringValue = searchValue.toLowerCase();

      // Get matched integrations
      const matchedIntegrations = filter(originalIntegrationTypeOptions, ({ label }) => label.toLowerCase().includes(stringValue));

      // Get matched categories items
      let categoriesItems: IntegrationTypeEntity[] = [];
      const matchedCategories = filter(DataTreeItems, ({ label }) => label.toLowerCase().includes(stringValue));
      matchedCategories.forEach((category) => {
        if (category.children) {
          const integrationIds = category.children.map(({ id }) => id);
          const integrations = filter(originalIntegrationTypeOptions, ({ id }) => integrationIds.includes(id));
          categoriesItems = [...categoriesItems, ...integrations];
        }
      });

      // Merge and distinct integrations
      let filteredIntegrations = union(matchedIntegrations, categoriesItems);

      // Transform to alphabet
      filteredIntegrations = Helper.transformToAlphabet(filteredIntegrations);

      // Move katalon studio option to first if exist
      filteredIntegrations = sortBy(filteredIntegrations, ({ id }) => (id === IntegrationType.KATALON_STUDIO_INTEGRATION.id ? 0 : 1));

      setFilteredIntegrations(filteredIntegrations);
    } else {
      onClear();
    }
  }, [searchValue]);

  const renderSearchInput = () => <SearchInput ref={searchRef} onChangeInput={onChangeInput} onClear={onClear} />;

  const renderSearchResults = () => {
    if (isEmpty(filteredIntegrations)) {
      return t('integration#search-results#no-data', { searchValue });
    }
    return <IntegrationBlocks data={filteredIntegrations} onBlockClick={onBlockClick} />;
  };

  const renderBelongingIntegrations = () => <IntegrationBlocks data={currentIntegrationOptions} onBlockClick={onBlockClick} />;

  const renderDetailPage = () => {
    const integration = filter(originalIntegrationTypeOptions, (value) => value.id === currentFolderId)[0];
    return <IntegrationDetail integration={integration} />;
  };

  const renderSideRight = () => {
    let content;
    if (isSearching) {
      content = renderSearchResults();
    } else if (isCategoryFolder(currentFolderId)) {
      content = renderBelongingIntegrations();
    } else {
      content = renderDetailPage();
    }

    return (
      <div className="side-right">
        {searchIntegrationItemsEnabled && renderSearchInput()}
        {content}
      </div>
    );
  };

  const renderHeader = () => {
    const urlParams = {
      integrations: t('integrations'),
    };
    return <ObjectSummary params={urlParams} />;
  };

  const renderBody = () => (
    <ThemeProvider theme={theme}>
      <Card className="integration-view-card">
        <CardBody className="integration-view-card-body">
          {renderSideLeft()}
          {renderSideRight()}
        </CardBody>
      </Card>
    </ThemeProvider>
  );

  if (isTeamDemo) {
    return null;
  }

  return (
    <DefaultLayout
      renderHeader={renderHeader}
      renderBody={renderBody}
    />
  );
}

export default IntegrationsPageV2;
