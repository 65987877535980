import React from 'react';
import { Route } from 'react-router-dom';
import DefaultLayout from '../../components/DefaultLayout';
import ObjectSummary from '../../components/summary/ObjectSummary';
import { t } from '../../i18n/t';
import PageComponent from '../../components/PageComponent';
import MContext from '../../models/MContext';
import ApplicationRepository from './components/ApplicationRepository';
import TestExecutionHeaderContainer from './components/TestExecutionHeaderContainer';
import MFlags from '../../models/MFlags';
import ScheduleTestRunComponent from './components/ScheduleTestRunComponent';
import Services from '../../utils/Services';
import { checkMobileAppLicenseActive } from '../../components/smarttestscheduling/services/testcloud';
import Error from '../Error';

class MobileNativeAppPage extends PageComponent {

  constructor(props) {
    super(props);
    this.projectId = MContext.project.id;
    this.project = MContext.project;
    this.meta.id = 'page-test-native-app';
    this.meta.title = t('Project {{name}} - {{page}}', { name: this.project.name, page: t('test-execution#apps') });
    this.renderHeader = this.renderHeader.bind(this);
    this.renderBody = this.renderBody.bind(this);
    this.state = {
      isMobileNativeAppEnabled: true,
    };
  }

  async getOrganizationMobileNativeQuotas() {
    const organizationId = this.project?.team?.organizationId;
    const { projectId } = this;

    if (organizationId) {
      const quota = await Services.getTestCloudOrganizationQuotas(organizationId, projectId);
      const isMobileNativeAppEnabled = checkMobileAppLicenseActive(quota);
      MContext.testCloudMobileNativeAppEnabled = isMobileNativeAppEnabled;

      if (this.state.isMobileNativeAppEnabled !== isMobileNativeAppEnabled) {
        this.setState({ isMobileNativeAppEnabled });
      }
    }
  }

  componentDidMount() {
    this.getOrganizationMobileNativeQuotas();
  }

  renderToolbar() {
    return (
      <>
        {!MFlags.testcloudExecutionEnvMicroAppEnabled && (
        <ScheduleTestRunComponent {...this.props} />
        )}
        { MFlags.testcloudExecutionEnvMicroAppEnabled && <TestExecutionHeaderContainer {...this.props} headerTitle={t('test-execution#apps')} /> }
        <ApplicationRepository />
      </>
    );
  }


  renderHeader() {
    const project = this.project;
    const urlParams = {
      applicationRepository: t('test-execution#apps'),
      projectId: project.id,
      project: project.name,
    };
    return (
      <ObjectSummary params={urlParams} />
    );
  }

  renderBody() {
    return (
      <>
        {this.renderToolbar()}
      </>
    );
  }

  render() {
    const { projectId } = this;
    if (projectId) {
      if (this.state.isMobileNativeAppEnabled) {
        return (
          <DefaultLayout
            renderHeader={!MFlags.testcloudExecutionEnvMicroAppEnabled ? this.renderHeader : null}
            renderBody={this.renderBody}
          />
        );
      } else {
        return (
          <Route component={Error} />
        );
      }
    }
    return null;
  }
}

export default MobileNativeAppPage;
