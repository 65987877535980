import { IndexedDBKeys } from './Constants';
import MContext from '../models/MContext';
import MAuth from '../models/MAuth';

export class IndexedDBObjectStore {
  db = null;
  collection = null;
  constructor(collection) {
    this.collection = collection;
    this.open();
  }

  get(key) {
    return new Promise((resolve) => {
      if (!this.db) {
        resolve(null);
        return;
      }
      const transaction = this.db.transaction([this.collection]);
      const objectStore = transaction.objectStore(this.collection);
      const request = objectStore.get(key);
      request.onerror = () => {
        // eslint-disable-next-line no-console
        console.error('Transaction failed');
        resolve(null);
      };

      request.onsuccess = () => {
        if (request.result) {
          const result = request.result;
          resolve(result);
        } else {
          resolve(null);
        }
      };
    });
  }

  getAll() {
    return new Promise((resolve) => {
      if (!this.db) {
        resolve([]);
        return;
      }
      const transaction = this.db.transaction([this.collection]);
      const objectStore = transaction.objectStore(this.collection);
      const request = objectStore.getAll();
      request.onerror = () => {
        // eslint-disable-next-line no-console
        console.error('Transaction failed');
        resolve([]);
      };

      request.onsuccess = () => {
        if (request.result) {
          // eslint-disable-next-line no-console
          console.log('Transaction succeed');
          const listItems = request.result.filter((el) => el.userId === MAuth.user.id && el.projectId === MContext.projectId);
          resolve(listItems);
        } else {
          // eslint-disable-next-line no-console
          console.log('No data record');
          resolve([]);
        }
      };
    });
  }

  put(data) {
    return new Promise((resolve) => {
      if (!this.db) {
        resolve(false);
        return;
      }
      const objectStore = this.db
        .transaction([this.collection], 'readwrite')
        .objectStore(this.collection);

      const request = objectStore.put(data);

      request.onsuccess = () => {
        // eslint-disable-next-line no-console
        console.log('The data has been updated successfully');
        resolve(true);
      };

      request.onerror = () => {
        // eslint-disable-next-line no-console
        console.error('The data has been updated failed');
        resolve(false);
      };
    });
  }

  add(data) {
    return new Promise((resolve) => {
      if (!this.db) {
        resolve(false);
        return;
      }
      const objectStore = this.db
        .transaction([this.collection], 'readwrite')
        .objectStore(this.collection);

      const request = objectStore.add(data);

      request.onsuccess = () => {
        // eslint-disable-next-line no-console
        console.log('The data has been added successfully');
        resolve(true);
      };

      request.onerror = () => {
        // eslint-disable-next-line no-console
        console.error('The data has been added failed');
        resolve(false);
      };
    });
  }

  delete(id) {
    return new Promise((resolve) => {
      if (!this.db) {
        resolve(false);
        return;
      }
      const request = this.db
        .transaction([this.collection], 'readwrite')
        .objectStore(this.collection)
        .delete(id);

      request.onsuccess = () => {
        // eslint-disable-next-line no-console
        console.log('The data has been deleted successfully');
        resolve(true);
      };

      request.onerror = () => {
        // eslint-disable-next-line no-console
        console.error('The data has been deleted failed');
        resolve(false);
      };
    });
  }

  open() {
    const req = indexedDB.open(IndexedDBKeys.DATABASE);
    req.onerror = () => {
      // eslint-disable-next-line no-console
      console.error('db open fail');
    };
    req.onsuccess = () => {
      if (!req) {
        return;
      }
      this.db = req.result;
    };

    req.onupgradeneeded = (event) => {
      const db = event.target.result;
      if (!db.objectStoreNames.contains(this.collection)) {
        db.createObjectStore(this.collection, {
          keyPath: 'id',
          autoIncrement: true,
        });
      }
    };
  }
}

export const TestCaseObjStore = new IndexedDBObjectStore(
  IndexedDBKeys.TEST_CASES
);
