/**
 * @deprecated due to KO-665
 * @see ListOrganizationsAndProjects
 */
import React from 'react';
import { Divider, ListSubheader, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import Routes from '../../utils/Routes';
import ImageProfile from '../avatar/ImageProfile';
import Arrays from '../../utils/Arrays';
import { next } from '../../utils/Count';
import MAuth from '../../models/MAuth';
import { t } from '../../i18n/t';
import MConfigs from '../../models/MConfigs';
import Apis from '../../utils/Apis';

class RecentOrganizations extends React.Component {

  constructor(props) {
    super(props);
    this.organizations = MAuth.user.organizations;
    this.isSubdomain = MConfigs.isUsingSubDomain;

    this.renderOrganizationsDropdown = this.renderOrganizationsDropdown.bind(this);
  }

  renderOrganizationsDropdown() {
    const organizations = this.organizations.map((org) => {
      const avatar = (
        <ImageProfile
          name={org.name}
          imgUrl={org.logoUrl}
          diameter={24}
        />
      );
      const routeOrg = new Routes({ organizationId: org.id });
      return {
        name: org.name,
        link: routeOrg.organization_home_link,
        icon: avatar,
      };
    });
    const isRedirectToAdmin = !MConfigs.isLicenseAdminServer;
    const route = new Routes();
    const organizationMenus = [
      {
        title: t('organizations'),
      },
      ...Arrays.insertIf(organizations.length > 0,
        ...organizations),
      {
        divider: !MConfigs.isOnPremise
      },
      ...Arrays.insertIf(!this.isSubdomain && !MConfigs.isOnPremise,
        {
          link: isRedirectToAdmin ? Apis.katOneAdminCreateOrganization() : route.organization_create_link,
          name: t('create_organization'),
          isOpenNewTab: false, // Stay same tab when direct to admin.
        }),
    ];
    return (
      <div className="top-nav-dropdown recent-project" id="recent-organization-dropdown">
        {organizationMenus && organizationMenus.map(({ name, link, isOpenNewTab, icon, divider, header, title }) => {
          if (divider) {
            return <Divider key={next()} />;
          }

          if (title) {
            return (
              <div className="title">
                {title}
              </div>
            );
          }

          if (header) {
            return (
              <ListSubheader key={next()} component="div">
                {header}
              </ListSubheader>
            );
          }

          if (link) {
            const newTabProps = isOpenNewTab ? {
              target: "_blank",
              rel: "noreferrer noopener"
            } : { };
            return (
              <ListItem key={next()} button component="a" href={link} {...newTabProps}>
                {icon && (
                  <>
                    <ListItemIcon className="dropdown-menu-recent-icon">
                      {icon}
                    </ListItemIcon>
                    <ListItemText primary={name} title={name} />
                  </>
                )}
                {!icon && (
                  <ListItemText className="alone-text" primary={name} title={name} />
                )}
              </ListItem>
            );
          }
          return null;
        })}
      </div>
    );
  }

  render() {
    return this.renderOrganizationsDropdown();
  }
}

export default RecentOrganizations;
