import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import _ from 'lodash';
import 'url-polyfill';
import Cookies from 'cookies-js';
import jquery from 'jquery';
import { Progress } from 'reactstrap';
import { Provider } from 'react-redux';
import App from './app/App';
import MAuth from './app/models/MAuth';
import { trackingEventHandlers } from './app/utils/EventHandler';
import loadSegment from './app/utils/segment';
import RouteConstants from './app/utils/RouteConstants';
import './scss/index.scss';
import MConfigs from './app/models/MConfigs';
import { PageType } from './app/utils/Constants';
import LaunchDarklyHelper from './app/utils/LaunchDarklyHelper';
import MFlags from './app/models/MFlags';
import { store } from './app/store';
import Services from './app/utils/Services';
import AuthenticationProvider from './app/auth/AuthenticationProvider';

global.jQuery = jquery;
window.Chart.defaults.global.defaultFontFamily = 'Roboto, sans-serif';

const setupSegment = () => {
  const SEGMENT_WRITE_KEY = Cookies.get('segment-write-key');
  if (!MConfigs.isOnPremise) {
    if (SEGMENT_WRITE_KEY) {
      loadSegment(SEGMENT_WRITE_KEY);
    } else {
      console.error('Cannot get segment write key');
    }
  }
};


const setupLaunchDarkly = async () => {
  if (!MConfigs.isOnPremise) {
    await LaunchDarklyHelper
      .init(MConfigs.launchDarklyClientId, MConfigs.launchDarklyDefaultUser)
      .then(() => {
        MFlags.parse(LaunchDarklyHelper.allFlags());
      });
    await LaunchDarklyHelper
      .initAccountClient(MConfigs.launchDarklyClientId, MConfigs.launchDarklyDefaultUser);
  }
};

const getBuildInfo = () => {
  return Services.getBuildInfo()
    .then((responseJson) => {
      const activeProfiles = _.get(responseJson, ['profiles', 'active']);
      MConfigs.profiles = activeProfiles;
      const onPremiseConfigs = _.get(responseJson, ['onPremise']);
      if (onPremiseConfigs) {
        MConfigs.parseOnPremise(onPremiseConfigs);
      }
      const authorizationServerUrl = _.get(responseJson, 'authorizationServerUrl');
      if (authorizationServerUrl) {
        MConfigs.authorizationServerUrl = authorizationServerUrl;
      }
      const testOpsBaseUrl = _.get(responseJson, 'testOpsBaseUrl');
      if (testOpsBaseUrl) {
        MConfigs.testOpsBaseUrl = testOpsBaseUrl;
      }
    });
  }

const getConfig = () => {
  return Services.getConfig()
    .then((responseJson) => {
      const configs = responseJson;
//      console.log('QQQQQ configs', configs);
      configs && MConfigs.parse(configs);
//      console.log('QQQQQ1 configs', configs);
//      console.log('QQQQQ2 configs', MConfigs.isUsingSubDomain);
    });
  }

const render = async () => {
  await getBuildInfo();
  await getConfig();
  setupSegment();
  await setupLaunchDarkly();
  const browserRoute =
  // eslint-disable-next-line react/jsx-filename-extension
    <BrowserRouter forceRefresh={false}>
      <Switch>
        <Route path={RouteConstants.katalon_manage} render={(params) => <App type={PageType.KATALON} {...params} />} />
        <Route path={RouteConstants.organization_checkout_quote} render={(params) => <App type={PageType.CHECKOUT_QUOTE} {...params} />} />
        <Route path={RouteConstants.project} render={(params) => <App type={PageType.PROJECT} {...params} />} />
        <Route path={RouteConstants.team} render={(params) => <App type={PageType.TEAM} {...params} />} />
        <Route path={RouteConstants.manage} render={(params) => <App type={PageType.USER} {...params} />} />
        <Route path={RouteConstants.organization} render={(params) => <App type={PageType.ORGANIZATION} {...params} />} />
        <Route path={RouteConstants.home_organization} render={(params) => <App type={PageType.ORGANIZATION_HOME} {...params} />} />
        <Route path={RouteConstants.organization_setting} render={(params) => <App type={PageType.TESTOPS_ORGANIZATION_SETTING} {...params} />} />
        <Route component={App} />
      </Switch>
    </BrowserRouter>;
  ReactDOM.render(
    <Provider store={store}>
      <AuthenticationProvider>
        {browserRoute}
      </AuthenticationProvider>
    </Provider>,
    document.getElementById('app'),
  );
  ReactDOM.render(
    <Progress animated color="request-progress" value="100" />,
    document.getElementById('request-progress'),
  );
};

MAuth.getMe(true)
  .then(render, render);

jquery(() => {
  _.forOwn(trackingEventHandlers, (handler, name) => {
    document.addEventListener(name, handler);
  });
});
