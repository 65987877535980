import React from 'react';
import { Switch, Route } from 'react-router-dom';
import {
  Card,
  CardBody,
  CardHeader,
  Form,
  FormGroup,
  Label,
  Nav,
  NavLink,
  TabContent,
  TabPane,
  Row,
  Col,
  Button,
} from 'reactstrap';
import { IconButton, Breadcrumbs } from '@mui/material';
import { isEmpty } from 'lodash';
import { StatelessApp } from '@katalon-studio/katalon-ui-utils';
import { IconArrowLeft, IconDuration, IconFlakiness, IconLatestRun } from '../images/CustomIcon';
import ExternalIssue from '../components/external_issue/ExternalIssue';
import ExternalIssueDataTable from '../components/external_issue/ExternalIssueDataTable';
import PageComponent from '../components/PageComponent';
import { t } from '../i18n/t';
import {
  CUSTOM_FIELD_ENTITY_TYPE,
  ObjectType,
  TAG_ENTITY_TYPE,
  SUPPORTED_TEST_TYPE,
  TEST_TYPE,
  EXECUTION_TYPE,
  KATALON_EVENTS,
  ACTIONS,
  ACTIONS_TRACKING,
  DISPLAYED_PAGE_TRACKING,
} from '../utils/Constants';
import http from '../utils/http';
import Apis from '../utils/Apis';
import MContext from '../models/MContext';
import MAuth from '../models/MAuth';
import ObjectSummaryParametersBuilder from '../components/summary/ObjectSummaryParametersBuilder';
import ObjectSummary from '../components/summary/ObjectSummary';
import ExecutionTestResultDataTable from '../components/table/ExecutionTestResultDataTable';
import { buildSearchCondition } from '../components/search/SearchUtils';
import Services from '../utils/Services';
import DataLoader from '../components/table/DataLoader';
import PerformanceLineChart from '../components/chart/PerformanceLineChart';
import DefaultLayout from '../components/DefaultLayout';
import ExternalConnectionAlert from '../components/external_issue/ExternalConnectionAlert';
import Routes from '../utils/Routes';
import PageButtonToolbar from '../components/PageButtonToolbar';
import DecoratorConstants from '../utils/DecoratorConstants';
import MFlags from '../models/MFlags';
import { buildFilter } from '../components/search-query/FilterQueryHelper';
import TagFilterV2 from '../components/search-query/filter/TagFilterV2';
import CustomFieldFilter from '../components/CustomFieldFilter';
import InlineEditInput from '../components/field/InlineEditInput';
import EditCustomField from '../components/EditCustomField';
import TestCaseMaintainer from '../components/field/TestCaseMaintainer';
import TestCaseObjHelper from '../utils/TestCaseObjHelper';
import MicroComponent from '../components/MicroComponent';
import MConfigs from '../models/MConfigs';
import Helper from '../utils/Helper';
import { sendAnalyticEventForAction, analyticsPage } from '../utils/SegmentAnalytics';
import ButtonLink from '../components/ButtonLink';
import TagTsx from '../components/tsx-components/TagTsx';
import TestProjectHelper from '../utils/TestProjectHelper';
import DeleteIcon from '../../images/icons/katalonui/DeleteIcon';
import MenuItemComponent from '../components/MenuItemComponent';
import colors from '../../scss/colors.scss';
import { TestProjectType } from '../models/model/TestProjectType';
import { DomEventHandlers } from '../utils/EventHandler';
import ActionsHelper from '../utils/table/ActionsHelper';
import PenIcon from '../../images/icons/katalonui/PenIcon';
import PlayCircleIcon from '../../images/icons/katalonui/PlayCircleIcon';
import LayerPlusIcon from '../../images/icons/katalonui/LayerPlusIcon';
import { TrueTestTestCaseHeaderNav } from '../components/HeaderNav';
import RouteConstants from '../utils/RouteConstants';

const ExternalType = {
  XRAY_TEST: {
    key: 'XrayTest',
    label: 'Xray Test'
  },
  REQUIREMENT: {
    key: 'Requirement',
    label: 'Requirement'
  },
  DEFECT: {
    key: 'Defect',
    label: 'Defect'
  }
};

const InlineEditTrackingAction = {
  UPDATE_DESCRIPTION: 'update description',
  UPDATE_MAINTAINER: 'update maintainer',
  UPDATE_CUSTOM_FIELDS: 'update custom fields',
  UPDATE_TAGS: 'update tags',
  UPDATE_TEST_STEPS: 'update test steps',
};

const UPDATE_TEST_CASE_TRACK_ID = 'update-test-case';

class ExecutionTestResultHistory extends PageComponent {
  constructor(props) {
    super(props);
    this.meta.id = 'page-execution-test-result-history';
    this.testCaseId = MContext.testCaseId;
    this.projectId = MContext.projectId;
    this.externalIssuesData = null;
    this.state = {
      isNewDraftTestCase: false,
      testCase: null,
      testCaseContent: null,
      latestTestResults: null,
      activeTab: ExternalType.REQUIREMENT.key,
      isXrayEnabled: false,
      selectedCustomFields: [],
      testStepUUID: null,
      testCaseStatistics: null,
    };
    this.router = new Routes();
    this.renderPerformanceChart = this.renderPerformanceChart.bind(this);
    this.renderHeader = this.renderHeader.bind(this);
    this.renderBody = this.renderBody.bind(this);
    this.renderDraftTestCasesBody = this.renderDraftTestCasesBody.bind(this);
    this.renderViewTestStepBtnDialog = this.renderViewTestStepBtnDialog.bind(this);
    this.handleAddNewTag = this.handleAddNewTag.bind(this);
    this.handleDeleteTag = this.handleDeleteTag.bind(this);
    this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
    this.handleMaintainerChange = this.handleMaintainerChange.bind(this);
    this.handleManualTestCaseContentChange = this.handleManualTestCaseContentChange.bind(this);
    this.deleteTestCase = this.deleteTestCase.bind(this);
    this.handleInstantRunTestCase = this.handleInstantRunTestCase.bind(this);
    this.handleAddToTestSuite = this.handleAddToTestSuite.bind(this);
    this.renderTrueTestTestCaseOverview = this.renderTrueTestTestCaseOverview.bind(this);
    this.renderTrueTestTestCaseContentOld = this.renderTrueTestTestCaseContentOld.bind(this);
    this.renderTrueTestTestCaseContentNew = this.renderTrueTestTestCaseContentNew.bind(this);
    this.renderTrueTestTestCaseBody = this.renderTrueTestTestCaseBody.bind(this);
  }

  renderPerformance() {
    return (
      <DataLoader
        title={t('performance')}
        entityType="ExecutionTestResult"
        defaultSearchConditions={[
          buildSearchCondition('TestCase.id', '=', this.state.testCase.id),
        ]}
        render={this.renderPerformanceChart}
      />
    );
  }

  renderPerformanceChart(data) {
    return (
      <PerformanceLineChart
        data={data}
        xLabel="Executions"
      />
    );
  }

  renderHistory() {
    return (
      <ExecutionTestResultDataTable
        title={t('history')}
        defaultSearchConditions={[
          buildSearchCondition('TestCase.id', '=', this.state.testCase.id),
        ]}
        defaultSort={['startTime,desc']}
        additionalFilterQuery={[
          buildFilter(CustomFieldFilter, { id: 'CustomField' }),
          buildFilter(TagFilterV2, { id: 'Tag.id', label: t('search-bar#tag') }),
        ]}
      />
    );
  }

  async getDraftTestCase() {
    const draftTestCase = await TestCaseObjHelper.get(this.testCaseId);
    this.meta.title = t('Test Case {{name}}', { name: draftTestCase.name });
    this.setState({
      testCase: draftTestCase,
      isNewDraftTestCase: true
    });
  }

  getEditButtonLabel() {
    if (MFlags.instantRunTestCasesEnabled) {
      return <div><PenIcon color={colors.textDarkColor} />{t('edit-test-steps')}</div>;
    }
    return t('edit-test-steps');
  }

  componentDidMount() {
    const routes = new Routes();
    DomEventHandlers.eventListener(KATALON_EVENTS.deletedG5TestCase, () => {
      this.props.history.push(routes.test_cases_link);
    });
    const { g5Editor } = MFlags;
    if (g5Editor) {
      TestCaseObjHelper.isNewDraftTestCase(this.testCaseId)
        .then((isNewDraftTestCase) => {
          if (isNewDraftTestCase) {
            this.getDraftTestCase();
          } else {
            this.getValidTestCase();
          }
        });
    } else {
      this.getTestCase(this.testCaseId);
      this.getConnection();
    }
  }

  componentDidUpdate() {
    const { testCase } = this.state;
    const router = new Routes();

    if (
      MFlags.editorTrueTestEnabled &&
      testCase?.flowUuid &&
      window.location.pathname === router.execution_test_result_history_link
    ) {
      this.props.history.replace(router.test_case_steps_link);
    }
  }

  getValidTestCase() {
    Services.getValidTestCaseById(this.testCaseId)
      .then((testCase) => {
        this.meta.title = t('Test Case {{name}}', {
          name: testCase.name,
        });
        this.getLatestTestResults(testCase.id);
        this.getConnection();
        this.setState({ testCase }, () => this.getManualTestCaseContent());
        if (MFlags.addMoreInformationToTestCaseDetailEnabled && !testCase.testProject) {
          this.getTestCasesStatistics(testCase.path, testCase.name);
        }
      });
  }

  getConnection() {
    Services.getJiraConnection(this.projectId)
      .then(({ content }) => {
        const connection = content[0];
        const isXrayEnabled = connection && connection.enabledXray;
        this.setState({
          connection,
          activeTab: isXrayEnabled ? ExternalType.XRAY_TEST.key : ExternalType.REQUIREMENT.key,
          isXrayEnabled
        });
      });
  }

  getTestCasesStatistics(path, name) {
    Services.getTestCasesStatistics(this.projectId, path, name)
      .then((response) => {
        const testCaseStatistics = response;
        this.setState({
          testCaseStatistics,
        });
      })
      .catch(() => {});
  }

  getTestCase(testCaseId) {
    http.get(Apis.testCase(testCaseId))
      .then((responseJson) => {
        const testCase = responseJson;
        this.meta.title = t('Test Case {{name}}', {
          name: testCase.name,
        });
        this.getLatestTestResults(testCase.id);
        this.setState({ testCase }, () => this.getManualTestCaseContent());
        if (MFlags.addMoreInformationToTestCaseDetailEnabled && !testCase.testProject) {
          this.getTestCasesStatistics(testCase.path, testCase.name);
        }
      });
  }

  getManualTestCaseContent() {
    const { testCase } = this.state;
    if (testCase && testCase.testType === TEST_TYPE.MANUAL_TEST_CASE) {
      Services.getTestCase(this.testCaseId)
        .then((testCaseContent) => {
          this.setState({ testCaseContent });
        })
        .catch(() => { /* ignore */ });
    }
  }

  handleAddNewTag(addedTag) {
    const { testCase } = this.state;
    sendAnalyticEventForAction(UPDATE_TEST_CASE_TRACK_ID, { 'action-name': InlineEditTrackingAction.UPDATE_TAGS, storage_type: TestProjectHelper.getStorageType(testCase.testProject) });
    return Services.updateTestCaseTag(testCase.id, addedTag);
  }

  handleDeleteTag(deletedTag) {
    const { testCase } = this.state;
    sendAnalyticEventForAction(UPDATE_TEST_CASE_TRACK_ID, { 'action-name': InlineEditTrackingAction.UPDATE_TAGS, storage_type: TestProjectHelper.getStorageType(testCase.testProject) });
    return Services.deleteTestCaseTag(testCase.id, deletedTag);
  }

  handleManualTestCaseContentChange(manualTestCaseContent) {
    const { testManagementG3Phase1Enabled } = MFlags;
    const { testCase, testCaseContent } = this.state;
    if (testCaseContent && testCaseContent.content !== manualTestCaseContent) {
      const testCaseContentData = {
        ...testCaseContent,
        content: manualTestCaseContent
      };
      const publishedTestProject = {
        testCases: [{
          id: testCase.id,
          name: testCase.name,
          path: testCase.path,
          testType: TEST_TYPE.MANUAL_TEST_CASE,
          executionType: EXECUTION_TYPE.TEXT,
          content: manualTestCaseContent
        }]
      };

      let request;
      if (testCase.testProject) {
        request = Services.publishTestProject(testCase.testProject.id, publishedTestProject);
      } else {
        request = Services.publishProject(this.projectId, publishedTestProject);
      }
      request
        .then(() => {
          sendAnalyticEventForAction(UPDATE_TEST_CASE_TRACK_ID, { 'action-name': InlineEditTrackingAction.UPDATE_TEST_STEPS, storage_type: TestProjectHelper.getStorageType(testCase.testProject) });
          this.setState({ testCaseContent: testCaseContentData });
        })
        .catch(() => { /* ignore */ });
    }
  }

  async handleDescriptionChange(description) {
    const { testCase } = this.state;
    if (description !== this.state.testCase.description) {
      let testCaseData = {
        ...this.state.testCase,
        description
      };
      if (this.state.isNewDraftTestCase) {
        testCaseData = { ...testCaseData, lastUpdated: new Date() };
        TestCaseObjHelper.updateOrCreate(testCaseData)
          .then(() => {
            this.setState({ testCase: testCaseData });
          });
      } else {
        Services.updateTestCase(testCaseData, true)
          .then((testCase) => {
            this.setState({ testCase });
          });
        // Update lastUpdated in indexDB
        let draftTestCase = await TestCaseObjHelper.get(this.testCaseId);
        if (draftTestCase) {
          draftTestCase = { ...draftTestCase, lastUpdated: new Date() };
          await TestCaseObjHelper.updateOrCreate(draftTestCase);
        }
        await sendAnalyticEventForAction(
          UPDATE_TEST_CASE_TRACK_ID,
          { 'action-name': InlineEditTrackingAction.UPDATE_DESCRIPTION, storage_type: TestProjectHelper.getStorageType(testCase.testProject) }
        );
      }
    }
  }

  setSelectedCustomFields = (selectedCustomFields, shouldCallApiToUpdate = true) => {
    if (!shouldCallApiToUpdate) {
      if (!isEmpty(selectedCustomFields)) {
        const testStepUUIDValue = selectedCustomFields.find((customField) => customField.customFieldDefinition.key === MConfigs.atgCustomFieldKey);
        const newCustomFieldValues = selectedCustomFields.filter((customField) => isEmpty(customField.textValue));
        let newProps = {};
        if (testStepUUIDValue?.textValue) {
          newProps = { testStepUUID: testStepUUIDValue.textValue };
        } else if (this.state.testStepUUID != null) {
          newProps = { testStepUUID: Helper.getUUIDFromTestStepTemplate(this.state.testCase.description), };
        }
        this.setState({
          selectedCustomFields: newCustomFieldValues,
          ...newProps,
        });
      }
    } else {
      const customFieldOptions = selectedCustomFields.map((selectedCustomField) => ({
        id: selectedCustomField.customFieldOption.id,
        definitionId: selectedCustomField.customFieldDefinition.id
      }));
      const testCaseData = {
        ...this.state.testCase,
        customFieldOptions
      };
      Services.updateTestCase(testCaseData, true)
        .then((testCase) => {
          this.setState({
            testCase,
            selectedCustomFields
          });
          sendAnalyticEventForAction(UPDATE_TEST_CASE_TRACK_ID, { 'action-name': InlineEditTrackingAction.UPDATE_CUSTOM_FIELDS, storage_type: TestProjectHelper.getStorageType(testCase.testProject) });
        })
        .catch(() => {});
    }
  };


  handleMaintainerChange(maintainer) {
    const testCaseData = {
      ...this.state.testCase,
      maintainer
    };
    Services.updateTestCase(testCaseData, true)
      .then((testCase) => {
        this.setState({
          testCase
        });
        sendAnalyticEventForAction(UPDATE_TEST_CASE_TRACK_ID, { 'action-name': InlineEditTrackingAction.UPDATE_MAINTAINER, storage_type: TestProjectHelper.getStorageType(testCase.testProject) });
      })
      .catch(() => {});
  }

  handleTracking() {
    sendAnalyticEventForAction('open_editor_button_clicked', {
      source: 'test_property',
      type: this.state.testCase.testType === TEST_TYPE.G5_TEST_CASE ? 'cloudstudio' : 'truetest',
    });
  }

  renderViewTestStepBtnDialog() {
    const { microAppAutonomousUrl, autonomousDefaultUserId } = MConfigs;
    const { testStepUUID, testCase } = this.state;
    const { maintainer } = testCase;
    if (!MFlags.editorTrueTestEnabled && testStepUUID && autonomousDefaultUserId === maintainer?.id) {
      return (
        <div className="ml-2 view-test-steps-btn">
          <MicroComponent
            appId="katalon-autonomous"
            path={microAppAutonomousUrl}
            componentId="ViewTestStep"
            props={{ testStepUUID }}
          />
        </div>
      );
    }
    return null;
  }

  renderTestCaseInfo() {
    const { testCase, selectedCustomFields, isNewDraftTestCase } = this.state;
    const { description, maintainer, id } = testCase;
    return (
      <Card>
        <CardHeader>
          Properties
        </CardHeader>
        <CardBody>
          <Row>
            <Col sm="12" md="8" lg="8" xl="5">
              <Form className="m-0">
                <FormGroup>
                  <Label for="description">{t('description')}</Label>
                  <InlineEditInput
                    id="description"
                    currentValue={description}
                    onChange={this.handleDescriptionChange}
                  />
                </FormGroup>
                {!isNewDraftTestCase &&
                <FormGroup>
                  <Label for="maintainer">{t('maintainer')}</Label>
                  <TestCaseMaintainer
                    currentValue={maintainer}
                    onChange={this.handleMaintainerChange}
                  />
                </FormGroup>}
                {!isNewDraftTestCase &&
                <FormGroup>
                  <Label for="customFields">{t('custom-fields#title')}</Label>
                  <EditCustomField
                    hideLabel
                    entityId={id}
                    entityType={CUSTOM_FIELD_ENTITY_TYPE.TEST_CASE}
                    setSelectedCustomFields={this.setSelectedCustomFields}
                    selectedCustomFields={selectedCustomFields}
                  />
                </FormGroup>}
                {!isNewDraftTestCase &&
                <FormGroup>
                  <Label for="tag">{t('tag#title')}</Label>
                  <TagTsx
                    ignoreWidthSize
                    entityType={TAG_ENTITY_TYPE.TEST_CASE}
                    entityId={testCase.id}
                    handleOnSelectChange={this.handleAddNewTag}
                    handleOnDeleteTag={this.handleDeleteTag}
                  />
                </FormGroup>}
              </Form>
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  }

  handleInstantRunTestCase() {
    const { testCase } = this.state;
    const data = { testCase };
    ActionsHelper.syncTrackingData(
      data,
      ACTIONS_TRACKING.INSTANT_RUN_TRIGGERED,
      DISPLAYED_PAGE_TRACKING.TEST_CASE_DETAILS
    );
    DomEventHandlers.createEvent(KATALON_EVENTS.openConfigureTestRunDialog, { detail: { testCase, displayPageTracking: DISPLAYED_PAGE_TRACKING.TEST_CASE_DETAILS } });
  }

  handleAddToTestSuite() {
    const { testCase } = this.state;
    const displayedPageTracking = DISPLAYED_PAGE_TRACKING.TEST_CASE_DETAILS;
    ActionsHelper.syncTrackingData(
      { testCase },
      ACTIONS_TRACKING.ADD_TO_TEST_SUITE_TRIGGERED,
      displayedPageTracking
    );
    DomEventHandlers.createEvent(
      KATALON_EVENTS.openAddToTestSuiteDialog,
      { detail: { testCase, displayedPageTracking } }
    );
  }

  renderToolbar() {
    const { g5Editor, removeGitSupportForCloudStudioEnabled } = MFlags;
    let isShowEditButton = true;
    const { isNewDraftTestCase, testCase } = this.state;
    const { testProject, testType } = testCase;

    if (!g5Editor) {
      isShowEditButton = false;
    } else {
      isShowEditButton = isNewDraftTestCase
        || (testProject && SUPPORTED_TEST_TYPE.includes(testType) && (removeGitSupportForCloudStudioEnabled ? testProject.type === TestProjectType.CLOUD : true));
    }

    const isCloudTestProject = testProject && testProject.type === TestProjectType.CLOUD;
    const isGitTestProject = testProject && testProject.type === TestProjectType.GIT;
    const isZipTestProject = testProject && testProject.type === TestProjectType.KS;
    const routes = new Routes();

    return (
      <PageButtonToolbar>
        {isShowEditButton &&
          <ButtonLink
            color="secondary"
            href={routes.edit_test_case_link}
            title={t('edit-test-steps')}
            onClick={() => this.handleTracking()}
          >
            {this.getEditButtonLabel()}
          </ButtonLink>}
        {MFlags.instantRunTestCasesEnabled && isCloudTestProject &&
          <Button
            color="secondary"
            title={t('run')}
            onClick={this.handleInstantRunTestCase}
          >
            <PlayCircleIcon color={colors.textDarkColor} />{t('run')}
          </Button>}
        {MFlags.addTestCaseToTestSuiteEnabled
          && ((isCloudTestProject && testCase.testType === TEST_TYPE.G5_TEST_CASE)
            || (isGitTestProject && testCase.testType === TEST_TYPE.G4_TEST_CASE)
            || (isZipTestProject && testCase.testType === TEST_TYPE.G4_TEST_CASE)) &&
            <Button
              color="secondary"
              title={t('addToTestSuite')}
              onClick={this.handleAddToTestSuite}
            >
              <LayerPlusIcon color={colors.textDarkColor} />{t('addToTestSuite')}
            </Button>}
        {(testCase.testType === TEST_TYPE.G4_TEST_CASE) &&
          this.renderViewTestStepBtnDialog()}
        {isCloudTestProject && this.renderActionItems()}
      </PageButtonToolbar>
    );
  }

  getLatestTestResults(id) {
    const params = {
      pagination: {
        page: 0,
        size: 30,
        sorts: 'order,desc',
      },
      conditions: [
        {
          key: 'TestCase.id',
          operator: '=',
          value: id,
        },
      ],
      type: 'ExecutionTestResult',
    };
    Services.search(params)
      .then((responseJson) => {
        const latestTestResults = responseJson.content.reverse();
        this.setState({
          latestTestResults,
        });
      });
  }

  renderCustomControlTab() {
    const { activeTab, isXrayEnabled } = this.state;
    return (
      <Nav className="page-tab__nav float-right">
        {isXrayEnabled && (
          <NavLink
            className={`card-header-title page-tab__nav__nav-link tab-button ${activeTab === ExternalType.XRAY_TEST.key ? 'active' : ''}`}
            onClick={() => this.toggle('XrayTest')}
          >
            {ExternalType.XRAY_TEST.label}
          </NavLink>
        )}
        <NavLink
          className={`card-header-title page-tab__nav__nav-link tab-button ${activeTab === ExternalType.REQUIREMENT.key ? 'active' : ''}`}
          onClick={() => this.toggle('Requirement')}
        >
          {ExternalType.REQUIREMENT.label}
        </NavLink>
        <NavLink
          className={`page-tab__nav__nav-link tab-button ${activeTab === ExternalType.DEFECT.key ? 'active' : ''}`}
          onClick={() => this.toggle('Defect')}
        >
          {ExternalType.DEFECT.label}
        </NavLink>
      </Nav>
    );
  }

  toggle(activeTab) {
    this.setState({
      activeTab
    });
  }

  renderLinkedIssue() {
    const { activeTab, isXrayEnabled } = this.state;
    return (
      <Card>
        <CardHeader className="d-flex align-items-center justify-content-between">
          <div className="d-flex">
            <div className="card-header-title">Linked Issue</div>
          </div>
          <div className="data-loader-header-control">
            {this.renderCustomControlTab()}
          </div>
        </CardHeader>
        <CardBody>
          <TabContent activeTab={activeTab}>
            {isXrayEnabled && (
              <TabPane tabId={ExternalType.XRAY_TEST.key}>
                {this.renderExternalXrayTest()}
              </TabPane>
            )}
            <TabPane tabId={ExternalType.REQUIREMENT.key}>
              {this.renderExternalIssue()}
            </TabPane>
            <TabPane tabId={ExternalType.DEFECT.key}>
              {this.renderExternalDefect()}
            </TabPane>
          </TabContent>
        </CardBody>
      </Card>
    );
  }

  renderExternalIssue() {
    const { testCase } = this.state;

    return (
      <Form>
        <ExternalIssue
          title={`${t('table-header#jira-issues')} (e.g. DEMO-1)`}
          projectId={this.projectId}
          objectType={ObjectType.TEST_CASE}
          objectId={testCase.id}
          linkIssueClassName="col-md-12 col-lg-6 px-0"
        />
      </Form>
    );
  }

  renderExternalXrayTest() {
    const { testCase } = this.state;

    return (
      <Form>
        <ExternalIssue
          title={t('jira-issue#xray-test-title')}
          projectId={this.projectId}
          objectType={ObjectType.XRAY_TEST_CASE}
          objectId={testCase.id}
          linkIssueClassName="col-md-12 col-lg-6 px-0"
        />
      </Form>
    );
  }

  renderExternalDefect() {
    const { testCase } = this.state;
    const { connection } = this.state;
    const active = connection && connection.active;

    return (
      <>
        {!active && <ExternalConnectionAlert />}
        <ExternalIssueDataTable
          projectId={this.projectId}
          objectType={ObjectType.EXECUTION_TEST_RESULT}
          defaultSearchConditions={[
            buildSearchCondition('TestCase.id', '=', testCase.id),
          ]}
          pageSize={5}
          noCard
        />
      </>
    );
  }

  renderObjectSummary() {
    const { testCase } = this.state;
    const urlParams = new ObjectSummaryParametersBuilder();
    const project = testCase.project;
    return (
      <ObjectSummary
        params={urlParams.toTestCaseHistoryParams(project, testCase)}
        isTrueTestTestCase={!!testCase?.flowUuid}
        isG5TestCase={testCase.testType === TEST_TYPE.G5_TEST_CASE}
      />
    );
  }

  renderTags() {
    const { testCase } = this.state;
    return (
      <Card>
        <CardHeader>{t('tag#title')}</CardHeader>
        <CardBody>
          <TagTsx
            entityType={TAG_ENTITY_TYPE.TEST_CASE}
            entityId={testCase.id}
            handleOnSelectChange={this.handleAddNewTag}
            handleOnDeleteTag={this.handleDeleteTag}
          />
        </CardBody>
      </Card>
    );
  }

  handleBack() {
    Routes.goToTestCasesLink();
  }

  renderBackButton() {
    return (
      <div className="back-button">
        <IconButton onClick={() => this.handleBack()} size="large">
          <IconArrowLeft />
        </IconButton>
      </div>
    );
  }

  renderTestCaseBreadcrumbs() {
    const { testCase, isNewDraftTestCase } = this.state;
    /** Hide test case name in breadcrumbs for draft test case */
    const path = isNewDraftTestCase ? '' : testCase.path;
    const paths = DecoratorConstants.addSourceOfTestCaseAndTestSuite(path, testCase.testProject)
      .split('/')
      .map((path, index) => <div key={path + index.toString()}>{path}</div>);
    return (
      <Breadcrumbs maxItems={8} itemsAfterCollapse={2}>
        {paths}
      </Breadcrumbs>
    );
  }

  renderHeaderWithNavigation() {
    const { testCase, testCaseStatistics } = this.state;
    return (
      <>
        {this.renderTestCaseBreadcrumbs()}
        <div className="header-with-back-icon">
          {this.renderBackButton()}
          {this.renderObjectSummary()}
        </div>
        {MFlags.editorTrueTestEnabled && testCase?.flowUuid && (
          <TrueTestTestCaseHeaderNav />
        )}
        { MFlags.addMoreInformationToTestCaseDetailEnabled && testCaseStatistics && testCaseStatistics[0] &&
        <div className="information-tool-bar">
          <div className="mr-4 d-flex align-items-center">
            <IconFlakiness className="toolbar-icon" />
            <span className="toolbar-title">
              {t('table-header#flakiness')}
            </span>
            <span className="toolbar-value"> {DecoratorConstants.flakinessDecorator('flakiness', testCaseStatistics[0])} </span>
          </div>
          <div className="mr-4 d-flex align-items-center">
            <IconDuration className="toolbar-icon" />
            <span className="toolbar-title">
              {t('avgDuration')}
            </span>
            <span className="toolbar-value"> {DecoratorConstants.durationDecorator('averageDuration', testCaseStatistics[0])} </span>
          </div>
          <div className="d-flex align-items-center">
            <IconLatestRun className="toolbar-icon" />
            <span className="toolbar-title">
              {t('latest-run')}
            </span>
            {DecoratorConstants.lastRunDecorator(testCaseStatistics[0].executionTestCases)}
          </div>
        </div>}
      </>
    );
  }

  renderHeader() {
    return (
      <>
        {this.renderHeaderWithNavigation()}
      </>
    );
  }

  renderManualTestCaseContent() {
    const { testCase, testCaseContent } = this.state;
    if (testCase && testCase.testType === TEST_TYPE.MANUAL_TEST_CASE) {
      return (
        <Card>
          <CardHeader>
            {t('test-steps')}
          </CardHeader>
          <CardBody>
            <Row>
              <Col sm="12" md="8" lg="8" xl="5">
                <Form className="m-0">
                  <FormGroup>
                    {testCaseContent &&
                      <InlineEditInput
                        id="manual-test-case-content"
                        currentValue={testCaseContent.content}
                        onChange={this.handleManualTestCaseContentChange}
                      />}
                  </FormGroup>
                </Form>
              </Col>
            </Row>
          </CardBody>
        </Card>
      );
    }
    return null;
  }

  renderBody() {
    return (
      <>
        {this.renderToolbar()}
        {this.renderManualTestCaseContent()}
        {this.renderTestCaseInfo()}
        {this.renderLinkedIssue()}
        {this.renderPerformance()}
        {this.renderHistory()}
      </>
    );
  }

  renderDraftTestCasesBody() {
    return (
      <>
        {this.renderToolbar()}
        {this.renderTestCaseInfo()}
      </>
    );
  }

  renderTrueTestTestCaseOverview() {
    return (
      <div
        className="w-100 h-100"
        style={{
          display:
            window.location.pathname !== this.router.test_case_overview_link &&
            'none',
        }}
      >
        {this.renderTestCaseInfo()}
        {this.renderLinkedIssue()}
        {this.renderPerformance()}
        {this.renderHistory()}
      </div>
    );
  }

  renderTrueTestTestCaseContentOld() {
    const { testCase } = this.state;

    return (
      <MicroComponent
        appId="katalon-authoring"
        path={MConfigs.microappAuthoringDomain}
        componentId="VisualEditor"
        props={{ testCase }}
      />
    );
  }

  renderTrueTestTestCaseContentNew() {
    const { testCase } = this.state;
    const context = {
      MContext,
      MAuth,
      MFlags,
      MConfigs,
      TestOpsUtils: {
        sendAnalyticEventForAction,
        analyticsPage,
        WebSocket
      },
      testCase,
    };

    return (
      <div
        className="w-100 h-100"
        style={{
          display:
            window.location.pathname !== this.router.test_case_steps_link &&
            window.location.pathname !==
              this.router.test_case_local_variables_link &&
            'none',
        }}
      >
        <StatelessApp
          id="katalon-authoring"
          path={MConfigs.microappAuthoringDomain}
          context={context}
        />
      </div>
    );
  }

  renderTrueTestTestCaseBody() {
    return (
      <>
        {this.renderToolbar()}
        {MFlags.editTestCaseData ? (
          <>
            {this.renderTrueTestTestCaseOverview()}
            {this.renderTrueTestTestCaseContentNew()}
          </>
        ) : (
          <Switch>
            <Route
              exact
              path={RouteConstants.test_case_overview}
              component={this.renderTrueTestTestCaseOverview}
            />
            <Route
              exact
              path={RouteConstants.test_case_steps}
              component={this.renderTrueTestTestCaseContentOld}
            />
          </Switch>
        )}
      </>
    );
  }

  deleteTestCase() {
    const { testCase } = this.state;
    const data = { testCase };
    const displayPageTracking = DISPLAYED_PAGE_TRACKING.TEST_CASE_DETAILS;
    DomEventHandlers.createEvent(KATALON_EVENTS.openDeleteTestEntityDialog, { detail: { testEntity: data, displayPageTracking } });
    ActionsHelper.syncTrackingData(
      { testCase },
      ACTIONS_TRACKING.DELETE_TEST_CASE_TRIGGERED,
      displayPageTracking
    );
  }

  renderActionItems() {
    const actionItems = [
      {
        action: ACTIONS.DELETE,
        handler: this.deleteTestCase,
        icon: <DeleteIcon color={colors.red5} />,
        color: colors.red5
      }
    ];
    return (
      <MenuItemComponent items={actionItems} iconButtonClassName="ml-2" />
    );
  }

  render() {
    const { testCase, latestTestResults, isNewDraftTestCase } = this.state;

    if (testCase && isNewDraftTestCase) {
      return (
        <DefaultLayout
          renderHeader={this.renderHeader}
          renderBody={this.renderDraftTestCasesBody}
        />
      );
    } else if (MFlags.editorTrueTestEnabled && testCase?.flowUuid) {
      return (
        <DefaultLayout
          renderHeader={this.renderHeader}
          renderBody={this.renderTrueTestTestCaseBody}
        />
      );
    } else if (testCase && latestTestResults) {
      return (
        <DefaultLayout
          renderHeader={this.renderHeader}
          renderBody={this.renderBody}
        />
      );
    } else {
      return <div />;
    }
  }
}

export default ExecutionTestResultHistory;
