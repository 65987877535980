import { IconButton, FormControlLabel } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Button } from 'reactstrap';
import StatusFilter, { StatusType } from '../../../components/search-query/filter/StatusFilter';
import { buildFilter } from '../../../components/search-query/FilterQueryHelper';
import { IconAngleLeft, IconAngleRight } from '../../../images/KitIcons';
import DecoratorConstants from '../../../utils/DecoratorConstants';
import Routes from '../../../utils/Routes';
import Gallery from './Gallery';
import ImageThumbnail from '../../../components/gallery/ImageThumbnail';
import GalleryModal from './GalleryModal';
import { extractKeyesExecutionId, getFileUrl } from './Utils';
import ComparisonView from './ComparisonView';
import { COMPARISON_TYPE, CHECKPOINT_STATUS } from './Constants';
import Services from '../../../utils/Services';
import { next } from '../../../utils/Count';
import Spinner from '../../../components/Spinner';
import Notification from '../../../utils/Notification';
import { CheckpointStatus, OrganizationFeature } from '../../../utils/Constants';
import { t } from '../../../i18n/t';
import {
  IconCheckKeyes,
  IconTimesKeyes,
} from '../../../images/CustomIcon';
import CheckBox from '../../../components/CheckBox';
import GroupEvent from '../../../utils/track/GroupEvent';
import MContext from '../../../models/MContext';
import TooltipComponent from '../../../components/TooltipComponent';

const CheckpointSelectStatus = {
  ALL: 'ALL',
  NONE: 'NONE',
  INDETERMINATE: 'INDETERMINATE',
};

class CheckpointGallery extends Component {
  constructor(props) {
    super(props);
    this.isTeamDemo = MContext.isTeamDemo;
    this.state = {
      selectedIndex: 0,
      selectedMethod: COMPARISON_TYPE.PIXEL,
      jsonLayoutDiffFile: [],
      isLoading: false,
      checkpointSelection: new Map(),
      isVisualTestingPro: false,
      isMethodPixelOrVSPro: true,
    };

    this.nextCheckpoint = this.nextCheckpoint.bind(this);
    this.previousCheckpoint = this.previousCheckpoint.bind(this);
    this.onChangeData = this.onChangeData.bind(this);
    this.refreshData = this.refreshData.bind(this);
    this.closeLightbox = this.closeLightbox.bind(this);
    this.renderGallery = this.renderGallery.bind(this);
    this.onChangeLoading = this.onChangeLoading.bind(this);
    this.pageSize = 12;
    this.dataRef = React.createRef();
    this.modalRef = React.createRef();
    this.renderTools = this.renderTools.bind(this);
    this.selectCheckpoint = this.selectCheckpoint.bind(this);
    this.selectAll = this.selectAll.bind(this);
    this.resolveCheckpoints = this.resolveCheckpoints.bind(this);
    this.clearAllCheckpointSelect = this.clearAllCheckpointSelect.bind(this);
    this.handleCloseCompareDialog = this.handleCloseCompareDialog.bind(this);
    this.handleDefaultComparisonMethod = this.handleDefaultComparisonMethod.bind(this);
  }

  componentDidMount() {
    if (!this.isTeamDemo) {
      const { targetOrganization } = MContext;
      Services.getVisualTestingQuota(targetOrganization.id)
        .then(({ visualTestingFeature, aiEnabled }) => {
          const isVisualTestingPro = visualTestingFeature === OrganizationFeature.VISUAL_TESTING_PRO;
          this.setState({
            isVisualTestingPro,
            aiEnabled
          }, this.handleDefaultComparisonMethod);
        });
    }
  }

  handleDefaultComparisonMethod() {
    const { isVisualTestingPro } = this.state;
    if (isVisualTestingPro) {
      const { defaultMethod } = this.props;
      this.handleChangeComparison(COMPARISON_TYPE[defaultMethod] ?? COMPARISON_TYPE.PIXEL);
    } else {
      this.handleChangeComparison(COMPARISON_TYPE.PIXEL);
    }
  }

  openLightbox = (event, selectedIndex) => {
    event.preventDefault();
    this.setState({
      selectedIndex,
    }, () => {
      this.modalRef.current.open();
    });
  };

  closeLightbox = (event) => {
    event.preventDefault();
    this.modalRef.current.close();
  };

  getCheckpointSelectStatus() {
    const { currentPage, data } = this.dataRef.current.store;
    const { checkpointSelection } = this.state;
    const pageSelection = checkpointSelection.get(currentPage);

    if (!pageSelection || pageSelection.size === 0) {
      return CheckpointSelectStatus.NONE;
    }

    if (pageSelection.size === data.length) {
      return CheckpointSelectStatus.ALL;
    }
    return CheckpointSelectStatus.INDETERMINATE;
  }

  selectAll() {
    const isSelectedAll = this.getCheckpointSelectStatus() === CheckpointSelectStatus.ALL;
    const { currentPage } = this.dataRef.current.store;
    const { checkpointSelection, images } = this.state;
    let pageSelection = checkpointSelection.get(currentPage);
    if (!pageSelection) {
      pageSelection = new Set();
    }
    if (!isSelectedAll) {
      images.map((i) => i.id).forEach(pageSelection.add, pageSelection);
    } else {
      pageSelection.clear();
    }
    this.setState((prevState) => {
      const checkpointSelection = new Map(prevState.checkpointSelection);
      checkpointSelection.set(currentPage, pageSelection);
      return ({
        ...prevState,
        checkpointSelection,
      });
    });
  }

  clearAllCheckpointSelect() {
    this.setState((prevState) => ({
      ...prevState,
      checkpointSelection: new Map(),
    }));
  }

  resolveCheckpoints(status) {
    const { defaultSearchConditions } = this.props;
    const keyesExecutionId = extractKeyesExecutionId(defaultSearchConditions);
    const { checkpointSelection } = this.state;
    const checkpointIds = Array.from(checkpointSelection.values()).reduce((a, b) => [...a, ...b], []);
    Services.resolveMultipleCheckpoints(keyesExecutionId.value, checkpointIds, status)
      .then(() => {
        const message = checkpointIds.length === 1 ? t('item-marked-as', { count: checkpointIds.length, status: _.startCase(status.toLowerCase()) })
          : t('items-marked-as', { count: checkpointIds.length, status: _.startCase(status.toLowerCase()) });
        Notification.pushSuccess(message);
        this.clearAllCheckpointSelect();
        this.dataRef.current.goToPage(0);
      });
  }

  renderTools() {
    const { checkpointSelection } = this.state;
    const selectionStatus = this.getCheckpointSelectStatus();
    const checked = selectionStatus === CheckpointSelectStatus.ALL;
    const indeterminate = selectionStatus === CheckpointSelectStatus.INDETERMINATE;
    const isSelectedNoCheckpoint = selectionStatus === CheckpointSelectStatus.NONE;
    const label = isSelectedNoCheckpoint ? t('select-all') : '';
    const selectedItems = Array.from(checkpointSelection.values()).map((page) => page.size).reduce((a, b) => a + b, 0);

    return (
      <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex justify-content-between align-items-center">
          <FormControlLabel
            control={
              <CheckBox
                useMUI
                data-trackid="page-keyes-execution-details-select-deselect-all"
                data-groupid={GroupEvent.ACCESS_REPORT}
                checked={checked}
                indeterminate={indeterminate}
                onChange={this.selectAll}
              />
            }
            label={label}
          />
          {
            !isSelectedNoCheckpoint &&
            <Button
              className="mr-2"
              onClick={() => this.resolveCheckpoints(CheckpointStatus.PASSED)}
              title={t('mark-as-passed')}
              size="large"
              data-trackid="page-keyes-execution-details-mark-passed"
              data-groupid={GroupEvent.ACCESS_REPORT}
            >
              <IconCheckKeyes className="icon-status-control" /> {t('mark-as-passed')}
            </Button>
          }
          {
            !isSelectedNoCheckpoint &&
            <Button
              onClick={() => this.resolveCheckpoints(CheckpointStatus.FAILED)}
              title={t('mark-as-failed')}
              size="large"
              data-trackid="page-keyes-execution-details-mark-failed"
              data-groupid={GroupEvent.ACCESS_REPORT}
            >
              <IconTimesKeyes className="icon-status-control" /> {t('mark-as-failed')}
            </Button>
          }
        </div>
        <p><b>{t('a-of-b', { a: selectedItems, b: this.dataRef.current.store.totalItems })}</b> {t('items-selected')}</p>
      </div>
    );
  }

  selectCheckpoint(selected, imageId) {
    const { currentPage } = this.dataRef.current.store;
    const { checkpointSelection } = this.state;
    let pageSelection = checkpointSelection.get(currentPage);
    if (!pageSelection) {
      pageSelection = new Set();
    }
    if (selected) {
      pageSelection.add(imageId);
    } else {
      pageSelection.delete(imageId);
    }
    this.setState((prevState) => {
      const checkpointSelection = new Map(prevState.checkpointSelection);
      checkpointSelection.set(currentPage, pageSelection);
      return ({
        ...prevState,
        checkpointSelection,
      });
    });
  }

  isCheckpointSelected(imageId) {
    const { checkpointSelection } = this.state;
    const pageSelection = checkpointSelection.get(this.dataRef.current.store.currentPage);
    return !!pageSelection && pageSelection.has(imageId);
  }

  renderThumbnail({ source, name, baseline, status, matchStatus, unsaved, uploadFile, id }) {
    const { viewOnly } = this.props;
    const className = status ?
      status.toLowerCase() :
      '';
    const imageUrl = source || baseline;
    const thumbnailUrl = getFileUrl(uploadFile, 'thumbnailId');
    const statusBadge = matchStatus && DecoratorConstants.statusBadge(matchStatus);
    const header = (
      <TooltipComponent text={name} placement="bottom">
        <div className="d-flex">
          {name}
          {unsaved ? ' *' : ''}
        </div>
      </TooltipComponent>
    );
    const selected = this.isCheckpointSelected(id);
    return (
      <ImageThumbnail
        key={next()}
        enableSelect={!viewOnly}
        selected={selected}
        onSelect={this.selectCheckpoint}
        imageId={id}
        imageUrl={imageUrl}
        thumbnailUrl={thumbnailUrl}
        className={className}
        header={header}
        badge={statusBadge}
      />
    );
  }

  onClickCheckpoint(event, index) {
    const { viewOnly } = this.props;
    if (viewOnly) {
      Routes.goToKeyesExecution();
    } else {
      this.openLightbox(event, index);
    }
  }

  renderGallery(image, index) {
    return (
      <div
        className="col-3 gallery-item"
        onClick={(event) => this.onClickCheckpoint(event, index)}
        key={image.key}
      >
        {this.renderThumbnail(image)}
      </div>
    );
  }

  nextCheckpoint() {
    const { selectedIndex } = this.state;
    if (selectedIndex === this.pageSize - 1) {
      const currentPage = this.dataRef.current.store.currentPage;
      this.dataRef.current.goToPage(currentPage + 1);
      this.setState({ selectedIndex: 0 });
    } else {
      this.setState({ selectedIndex: selectedIndex + 1 });
    }
  }

  previousCheckpoint() {
    const { selectedIndex } = this.state;
    if (selectedIndex === 0) {
      const currentPage = this.dataRef.current.store.currentPage;
      this.dataRef.current.goToPage(currentPage - 1);
      this.setState({ selectedIndex: this.pageSize - 1 });
    } else {
      this.setState({ selectedIndex: selectedIndex - 1 });
    }
  }

  getCurrentIdx() {
    const { selectedIndex } = this.state;
    const { currentPage } = this.dataRef.current.store;
    return (currentPage * this.pageSize) + selectedIndex;
  }

  isFirstCheckpoint() {
    const currentIdx = this.getCurrentIdx();
    return currentIdx === 0;
  }

  isLastCheckpoint() {
    const { totalItems } = this.dataRef.current.store;
    const currentIdx = this.getCurrentIdx();
    return currentIdx === totalItems - 1;
  }

  convertData(data) {
    if (data) {
      return data.map((item) => ({
        ...item,
        name: _.get(item, 'screenshot.name'),
        width: _.get(item, 'screenshot.width'),
        height: _.get(item, 'screenshot.height'),
        source: getFileUrl(item, 'uploadFile.fileHandleId'),
        baseline: getFileUrl(item, 'baseline.uploadFile.fileHandleId'),
        diffFile: getFileUrl(item, 'checkpointPixel.diffFile.fileHandleId'),
        key: `checkpoint_${item.id}`,
        matchStatus: _.get(item, 'matchStatus', CHECKPOINT_STATUS.ANALYSING),
        ignoringZones: _.get(item, 'baseline.ignoringZones') ? _.get(item, 'baseline.ignoringZones') : [],
        checkpointLayout: _.get(item, 'checkpointLayout') || null,
        checkpointContent: _.get(item, 'checkpointContent') || null,
      }));
    }
    return [];
  }

  onChangeData(data) {
    this.setState({ images: this.convertData(data) });
  }

  refreshData() {
    this.dataRef.current.refreshData();
  }

  handleChangeComparison(selectedMethod) {
    const { isVisualTestingPro } = this.state;
    this.setState({
      selectedMethod,
      isMethodPixelOrVSPro: selectedMethod === COMPARISON_TYPE.PIXEL || isVisualTestingPro
    });
  }

  onChangeLoading(isLoading) {
    this.setState({ isLoading });
  }

  handleCloseCompareDialog() {
    this.handleDefaultComparisonMethod();
  }

  renderComparisonView(image) {
    const { isMethodPixelOrVSPro, isVisualTestingPro, aiEnabled } = this.state;
    return (
      <ComparisonView
        data={image}
        index={this.state.selectedIndex}
        key={next()}
        onClose={this.closeLightbox}
        refreshData={this.refreshData}
        onChangeData={this.onChangeData}
        comparisonMethod={this.state.selectedMethod}
        setComparisonMethod={(e) => this.handleChangeComparison(e)}
        isMethodPixelOrVSPro={isMethodPixelOrVSPro}
        isVisualTestingPro={isVisualTestingPro}
        aiEnabled={aiEnabled}
        {...this.props}
      />
    );
  }

  renderLoading() {
    return (
      <div className="checkpoint-modal__loading-spinner">
        <Spinner />
      </div>
    );
  }

  renderModal() {
    const isFirst = this.isFirstCheckpoint();
    const isLast = this.isLastCheckpoint();
    const { selectedIndex, images } = this.state;
    const image = images?.[selectedIndex];
    if (image) {
      const { isLoading } = this.state;
      return (
        <GalleryModal title={isLoading ? null : image.name} matchStatus={isLoading ? null : image.matchStatus} className="checkpoint-modal" ref={this.modalRef} onClose={this.handleCloseCompareDialog}>
          <div className="d-flex align-items-center">
            <div className={`checkpoint-modal__navigate-button ${isFirst ? 'invisible' : ''}`}>
              <IconButton onClick={this.previousCheckpoint} size="large">
                <IconAngleLeft />
              </IconButton>
            </div>
            {
              isLoading ?
                this.renderLoading() :
                this.renderComparisonView(image)
            }
            <div className={`checkpoint-modal__navigate-button ${isLast ? 'invisible' : ''}`}>
              <IconButton onClick={this.nextCheckpoint} size="large">
                <IconAngleRight />
              </IconButton>
            </div>
          </div>
        </GalleryModal>
      );
    }
    return null;
  }

  render() {
    const { statusType, viewOnly } = this.props;
    const filterQuery = [
      buildFilter(StatusFilter, { id: 'status', type: statusType || StatusType.CHECKPOINT_RESULT }),
    ];

    const newProps = {
      filterQuery,
      ...this.props
    };

    if (!viewOnly) {
      newProps.onSearchQueryChange = this.clearAllCheckpointSelect;
      newProps.renderTools = this.renderTools;
    }

    return (
      <>
        <Gallery
          ref={this.dataRef}
          entityType="Checkpoint"
          renderImage={this.renderGallery}
          convertData={this.convertData}
          pageSize={this.pageSize}
          onChangeData={this.onChangeData}
          onChangeLoading={this.onChangeLoading}
          useSearchQuery
          {...newProps}
        />
        {this.dataRef.current && this.renderModal()}
      </>
    );
  }
}

CheckpointGallery.propTypes = {
  viewOnly: PropTypes.bool,
};

CheckpointGallery.defaultProps = {
  viewOnly: false,
};

export default CheckpointGallery;
