import React from 'react';
import MTableColumnDataMapping from '../../components/table/models/MTableColumnDataMapping';
import { t } from '../../i18n/t';
import DecoratorConstants from '../../utils/DecoratorConstants';
import DataTable from '../../components/table/DataTable';
import { EXECUTION_TEST_SUITE_PAGE_SIZE, TestSuiteType } from '../../utils/Constants';
import { buildSortQueryV2 } from '../search-query/SortQueryHelper';
import MFlags from '../../models/MFlags';

class ExecutionTestSuiteHistoryDataTableV2 extends React.Component {
  render() {
    const headers = [
      new MTableColumnDataMapping(
        t('status'),
        'status',
        (name, row) => DecoratorConstants.statusDecorator(name, row),
        false,
        '',
        undefined,
        '10%'
      ),
      new MTableColumnDataMapping(
        t('name'),
        'testSuite.name',
        DecoratorConstants.historyExecutionTestSuiteNameTruncateDecorator,
        false,
        '',
        undefined,
        '25%'
      ),
      new MTableColumnDataMapping(
        t('table-header#profile'),
        'profiles',
        (name, row) => {
          const testSuiteType = row?.testSuite?.type;
          return MFlags.testCloudG5TestSuiteEnabled && testSuiteType === TestSuiteType.CLOUD_STUDIO ? <></> : DecoratorConstants.profileDecorator(name, row);
        },
        false,
        '',
        undefined,
        '10%'
      ),
      new MTableColumnDataMapping(
        t('table-header#platform'),
        'platform',
        DecoratorConstants.platformDecorator,
        false,
        '',
        undefined,
        '10%'
      ),
      new MTableColumnDataMapping(
        t('table-header#startTime'),
        'startTime',
        DecoratorConstants.timeDecorator,
        false,
        '',
        buildSortQueryV2('startTime'),
        '15%'
      ),
      new MTableColumnDataMapping(
        t('table-header#duration'),
        '',
        (name, row) => DecoratorConstants.durationDecorator('duration', row),
        false,
        '',
        undefined,
        '10%'
      ),
      new MTableColumnDataMapping(
        t('table-header#total'),
        'totalTests',
        DecoratorConstants.totalTestRunDecorator,
        false,
        '',
        undefined,
        '10%'
      ),
      new MTableColumnDataMapping(
        DecoratorConstants.passedBadgeDecorator(t('table-header#totalPassed')),
        'totalPassedTests',
        DecoratorConstants.passedCountDecorator,
        false,
        '',
        undefined,
        '6%'
      ),
      new MTableColumnDataMapping(
        DecoratorConstants.failedBadgeDecorator(t('table-header#totalFailed')),
        'totalFailedTests',
        DecoratorConstants.failedCountDecorator,
        false,
        '',
        undefined,
        '6%'
      ),
      new MTableColumnDataMapping(
        DecoratorConstants.errorBadgeDecorator(t('table-header#totalError')),
        'totalErrorTests',
        DecoratorConstants.errorCountDecorator,
        false,
        '',
        undefined,
        '6%'
      ),
      new MTableColumnDataMapping(
        DecoratorConstants.incompleteBadgeDecorator(t('table-header#totalIncomplete')),
        'totalIncompleteTests',
        DecoratorConstants.incompleteCountDecorator,
        false,
        '',
        undefined,
        '6%'
      ),
      new MTableColumnDataMapping(
        DecoratorConstants.skippedBadgeDecorator(t('table-header#totalSkipped')),
        'totalSkippedTests',
        DecoratorConstants.skippedCountDecorator,
        false,
        '',
        undefined,
        '6%'
      ),
    ];


    return (
      <DataTable
        fixLayout
        columnMapping={headers}
        entityType="ExecutionTestSuite"
        unitName="execution test suite"
        pageSize={EXECUTION_TEST_SUITE_PAGE_SIZE}
        useTitleNoCard
        useSortByColumn
        {...this.props}
      />
    );
  }
}

export default ExecutionTestSuiteHistoryDataTableV2;
