import _ from 'lodash';
import React from 'react';
import parse from 'url-parse';
import PageComponent from '../../components/PageComponent';
import SpinnerPage from '../../auth/SpinnerPage';
import Routes from '../../utils/Routes';
import DefaultLayout from '../../components/DefaultLayout';
import MAuth from '../../models/MAuth';
import Services from '../../utils/Services';
import ChooseOrganizationDialog from '../../components/dialog/ChooseOrganizationDialog';
import { t } from '../../i18n/t';
import { KatalonProduct, AccessToken, KatalonTestOpsPackage } from '../../utils/Constants';
import { toTestOpsFeature } from '../../utils/TrialRequestHelper';
import { trackTrialRequestEvent, TRIAL_REQUEST_TRIGGER } from '../../utils/EventTrackingHelper';
import SuccessfullyAssignedDialog from '../../components/dialog/SuccessfullyAssignedDialog';

export default class HomeV2 extends PageComponent {

  constructor(props) {
    super(props);
    this.meta.id = 'page-home';
    this.meta.title = 'Home';
    this.state = {
      organizations: [],
      goToSubscriptions: false,
      successModal: false,
      testOpsFeature: null,
      newOrgName: '',
      ready: false,
    };
    this.chooseOrganizationDialog = React.createRef();
    this.chooseOrganizationErrorDialog = React.createRef();

    this.renderBody = this.renderBody.bind(this);
    this.createOrganization = this.createOrganization.bind(this);
    this.subscriptionsHandler = this.subscriptionsHandler.bind(this);
    this.goToSubscriptionPage = this.goToSubscriptionPage.bind(this);
    this.goToQuotePage = this.goToQuotePage.bind(this);
    this.requestTrialTestOps = this.requestTrialTestOps.bind(this);
    this.handleGoToHomeButton = this.handleGoToHomeButton.bind(this);
    this.getPlan = this.getPlan.bind(this);
    this.getTestOpsPlan = this.getTestOpsPlan.bind(this);

    const url = parse(window.location.href, true);
    this.query = url.query;
    delete this.query.otp;
  }

  componentDidMount() {
    const { isGetQuote, isSubscribe, isTrial } = this.props;
    // Only support trial for TestOps
    if (isTrial) {
      this.validateTrialPackage();
    }
    const { product } = this.query;
    this.getOrganizations(() => {
      if (Object.keys(this.query).length === 0) {
        if (this.state.organizations.length === 0) {
          return Routes.goToCreateOrganization();
        }
        return this.goToOrganizationDefault();
      }
      if (isSubscribe) {
        this.subscriptionsHandler(product);
      } else if (isTrial) {
        this.trialHandler();
      } else if (isGetQuote) {
        this.quoteHandler();
      } else {
        this.freeOrganizationHandler();
      }
    });
  }

  getTestOpsPlan() {
    const { feature } = this.props;
    let plan;
    if (this.query.subscribe === 'trial') {
      plan = (feature === KatalonTestOpsPackage.ENTERPRISE) ?
        t('trialRequest#testOpsEnterprise') : t('trialRequest#testOpsBusiness');
    } else {
      plan = (feature === KatalonTestOpsPackage.ENTERPRISE) ?
        t('testOpsEnterprise') : t('testOpsBusiness');
    }
    return plan;
  }


  getPlan() {
    const { feature } = this.props;
    if (this.query.product === KatalonProduct.TESTOPS && !feature) {
      return '';
    } else if (this.query.product !== KatalonProduct.TESTOPS) {
      return t('katalon_organization_choose');
    } else {
      const plan = this.getTestOpsPlan();
      return t('organization_choose_description', { plan });
    }
  }


  getOrganizations(callback) {
    const email = MAuth.user.email;
    const name = email.split('@')[0];
    this.setState({
      organizations: MAuth.user.organizations,
      newOrgName: `Organization ${name}`,
      ready: true,
    }, callback);
  }

  goToOrganizationDefault() {
    const { organizations } = this.state;
    const { isDownloadKs } = this.props;
    const filter = organizations.filter((organization) => organization && organization.mostRecentProjectAccessedAt);
    const sorts = _.orderBy(filter, ['mostRecentProjectAccessedAt'], ['desc']);
    Routes.goToOrganizationHomePage(
      sorts[0]?.id || organizations[0].id,
      isDownloadKs ? { download_popup: isDownloadKs } : undefined,
      false);
  }

  goToLicenseServerHome(organization) {
    Routes.goToLicenseAdminHomePage(organization.id);
  }

  handleGoToHomeButton() {
    const { organizations, testOpsFeature } = this.state;
    Routes.goToOrganizationHomePage(organizations[0].id, { testOpsFeature });
  }

  renderBody() {
    const { isSubscribe, isGetQuote, isTrial, feature } = this.props;
    const { organizations, successModal } = this.state;
    const isFree = !isSubscribe && !isGetQuote && !isTrial;

//     if (Object.keys(this.query).length === 0) {
//       if (organizations.length === 0) {
//         return Routes.goToCreateOrganization();
//       }
//       return this.goToOrganizationDefault();
//     }

    let plan;
    if (feature === KatalonTestOpsPackage.ENTERPRISE) {
      plan = t('trialRequest#testOpsEnterprise');
    } else {
      plan = t('trialRequest#testOpsBusiness');
    }
    if (successModal) {
      return (
        <SuccessfullyAssignedDialog
          showPopUp={successModal}
          handleOrganization={this.handleGoToHomeButton}
          orgName={organizations[0].name}
          plan={plan}
        />
      );
    }
    const title = isFree ? t('organization_choose_free') : t('organization_choose');
    return (
      <ChooseOrganizationDialog
        ref={this.chooseOrganizationDialog}
        title={title}
        description={this.getPlan()}
        isFree={isFree}
        query={this.query}
        plan={plan}
        {...this.props}
      />
    );
  }

  goToSubscriptionPage(organization) {
    const organizationId = organization.id;
    const subscribed = organization.subscribed;
    if (subscribed) {
      Routes.goToOrganizationSubscription(organizationId);
    } else {
      Routes.goToOrganizationSubscriptionNewOrders(organizationId, this.query);
    }
  }

  goToQuotePage(organization) {
    const organizationId = organization.id;
    Routes.goToOrganizationQuoteNewOrders(organizationId, this.query);
  }

  goToTestOpsCheckoutPage(organization) {
    Routes.goToTestOpsSubscriptionCheckoutPage(organization.id);
  }

  goToTrialRequestPage(organization) {
    Routes.goToTrialRequest(organization.id);
  }

  goToOrganizationHomePage(organization) {
    Routes.goToOrganizationHomePage(organization.id);
  }

  subscriptionsHandler(product) {
    const organizations = this.state.organizations;
    // only check product is TO --> go to TO checkout page. feature (package) not use in this case
    if (product === KatalonProduct.TESTOPS) {
      this.handleTestOpsSubscription(organizations);
    } else {
      this.handleKSSubscription(organizations);
    }
  }

  handleTestOpsSubscription(organizations) {
    this.handleOrganizations(organizations, this.goToTestOpsCheckoutPage);
  }

  handleKSSubscription(organizations) {
    this.handleOrganizations(organizations, this.goToSubscriptionPage);
  }

  trialHandler() {
    const organizations = this.state.organizations;
    // current don't check product, only TestOps has trial.
    this.handleOrganizations(organizations, this.requestTrialTestOps);
  }

  freeOrganizationHandler() {
    const organizations = this.state.organizations;
    this.handleOrganizations(organizations, this.goToOrganizationHomePage);
  }

  handleOrganizations(organizations, goToPage) {
    if (organizations.length === 0) {
      Routes.goToCreateOrganization(this.query);
    } else if (organizations.length === 1) {
      goToPage(organizations[0]);
    } else {
      this.chooseOrganizationDialog.current && this.chooseOrganizationDialog.current.toggle();
    }
  }

  requestTrialTestOps(organization) {
    const { feature } = this.props;
    const testOpsFeature = toTestOpsFeature(feature);
    Services.submitOrganizationTrialRequest(organization.id, testOpsFeature)
      .then(() => {
        trackTrialRequestEvent(MAuth.email, organization.id, testOpsFeature, TRIAL_REQUEST_TRIGGER.WEBSITE);
        this.setState({
          successModal: true,
          testOpsFeature,
        });
      });
  }

  quoteHandler() {
    const { isGetQuote } = this.props;
    const { organizations } = this.state;

    const nonSubscribedOrganizations = organizations.filter((o) => !o.subscribed);
    if (organizations.length === 0) {
      // no organization
      this.createOrganization(this.goToQuotePage);
    } else if (nonSubscribedOrganizations.length === 0) {
      // all organizations have subscribed
      this.chooseOrganizationErrorDialog.current.toggle();
    } else if (nonSubscribedOrganizations.length === 1) {
      // has only 1 non-subscribed organization
      this.goToQuotePage(nonSubscribedOrganizations[0]);
    } else {
      // has many non-subscribed organization
      this.chooseOrganizationDialog.current.toggle(isGetQuote);
    }
  }

  createOrganization(navigationHandler) {
    const { newOrgName } = this.state;
    Services.createOrganization(newOrgName)
      .then((organization) => {
        const organizationId = organization.id;
        if (navigationHandler) {
          navigationHandler(organization);
          return;
        }

        Routes.goToOrganizationPage(organizationId);
      });
  }

  validateTrialPackage() {
    const { feature } = this.props;

    if (!toTestOpsFeature(feature)) {
      Routes.goToHomePage();
    }
  }

  render() {
    const urlString = window.location.href;
    const accessToken = new URL(urlString).searchParams.get(AccessToken.PARAM_NAME);
    /*
    render nothing when wating App.jsx do logout the current user and automatically login with the token.
    if renderBody, window will redirect to another url without access token.
    if try to renderBody and redirect to other url with access token
    => it can be wrong because we redirect denpend on number of organizations of logged-in user
    */
    if (!this.state.ready || accessToken) {
      return <SpinnerPage />;
    }
    return (
      <DefaultLayout
        renderBody={this.renderBody}
      />
    );
  }
}
