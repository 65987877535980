import React, { useState } from 'react';
import { Alert } from 'reactstrap';
import PropTypes from 'prop-types';
import { IconWarningV2 } from '../../images/CustomNewIcon';

export default function BannerWarning({ title }) {
  const [isShowFlag, setIsShowFlag] = useState(true);

  const handleChange = () => {
    setIsShowFlag(false);
  };

  return (
    <Alert
      className="banner-warning"
      color="primary"
      isOpen={isShowFlag}
      toggle={handleChange}
    >
      <IconWarningV2 />
      <div className="ml-2">
        {title}
      </div>
    </Alert>
  );
}

BannerWarning.propTypes = {
  title: PropTypes.string.isRequired
};
