import React from 'react';
import { Col } from 'reactstrap';

export default function WarningBannerComponent({ text, icon, iconFrame }) {
  return (
    <>
      <Col sm="auto" md="auto" lg="auto" xl="auto">{icon}</Col>
      <Col>{text}</Col>
      <Col sm="auto" md="auto" lg="auto" xl="auto" className="px-0">
        <div className="image-frame-resize">
          {iconFrame}
        </div>
      </Col>
    </>
  );
}
