import { t } from '../../i18n/t';

export const buildSortQuery = (key, label) => ([
  { id: `${key}-asc`, label: `${label} (Ascending)`, value: `${key}-asc` },
  { id: `${key}-desc`, label: `${label} (Descending)`, value: `${key}-desc` }
]);

export const buildSortQueryNullsLast = (key, label) => ([
  { id: `${key}-asc`, label: `${label} (Ascending)`, value: `${key}-asc_nulls_last` },
  { id: `${key}-desc`, label: `${label} (Descending)`, value: `${key}-desc_nulls_last` }
]);

export const buildSortQueryV2 = (key, isNameField) => (
  {
    key,
    asc: {
      key: `asc${key}`,
      label: isNameField ? t('sort-name-asc') : t('sort-asc')
    },
    desc: {
      key: `desc${key}`,
      label: isNameField ? t('sort-name-desc') : t('sort-desc')
    }
  }
);

export const buildSortQueryWithNull = (key, isNameField) => (
  {
    key,
    asc: {
      key: `asc_nulls_first${key}`,
      label: isNameField ? t('sort-name-asc') : t('sort-asc'),
    },
    desc: {
      key: `desc_nulls_last${key}`,
      label: isNameField ? t('sort-name-desc') : t('sort-desc'),
    }
  }
);
