import React from 'react';
import { Box, Typography } from '@mui/material';

class TabPanel extends React.Component {

  render() {
    const { children, value, index, sx, ...other } = this.props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`nav-tabpanel-${index}`}
        aria-labelledby={`nav-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={0} sx={sx}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );

  }
}

export default TabPanel;
