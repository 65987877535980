import React, { MouseEventHandler } from 'react';
import { DialogContent, DialogActions, DialogContentText } from '@mui/material';
import { Button } from '@katalon-studio/katalon-ui';
import CloseableDialogComponent from './CloseableDialogComponent';
import { t } from '../../i18n/t';

interface ConfirmDeleteAutomationRuleDialogProps {
  isOpen: boolean;
  handleClose: MouseEventHandler;
  handleConfirm: MouseEventHandler;
  defectId: string;
  automationRuleName: string;
}

function ConfirmDeleteAutomationRuleDialog(props: Readonly<ConfirmDeleteAutomationRuleDialogProps>) {

  const {
    isOpen,
    handleClose,
    handleConfirm,
    defectId,
    automationRuleName,
  } = props;

  return (
    <CloseableDialogComponent
      isOpen={isOpen}
      handleClose={handleClose}
      maxWidth="sm"
      title={t('delete-automation-rule#title')}
    >
      <DialogContent>
        <DialogContentText className="title ml-0 mt-3 text-left">
          {t('delete-automation-rule#content#part-one')}
          <b>{defectId}</b>.{' '}
          {t('delete-automation-rule#content#part-two')}
        </DialogContentText>
        <DialogContentText className="title ml-0 mt-3 text-left">
          {t('delete-automation-rule#content#part-three')}
          <b>{automationRuleName}</b>?
        </DialogContentText>
      </DialogContent>
      <DialogActions className="popup-footer justify-content-end p-0">
        <Button
          id="button-cancel"
          color="secondary"
          variant="contained"
          className="ml-3 btn-secondary"
          size="small"
          disableElevation
          onClick={handleClose}
        >
          {t('cancel')}
        </Button>
        <Button
          id="button-confirm"
          variant="contained"
          color="error"
          size="small"
          disableElevation
          onClick={handleConfirm}
        >
          {t('confirm')}
        </Button>
      </DialogActions>
    </CloseableDialogComponent>
  );
}

export default ConfirmDeleteAutomationRuleDialog;
