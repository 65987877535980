import React from 'react';
import { DialogContent, DialogContentText, DialogActions } from '@mui/material';
import { Button, ButtonToolbar, FormGroup } from 'reactstrap';
import PropTypes from 'prop-types';
import { t } from '../../../i18n/t';
import Input from '../../../components/Input';
import CloseableDialogComponent from '../../../components/dialog/CloseableDialogComponent';

function CopyingJiraWebhookDialog({ isOpen, handleClose, handleCopy, jiraWebhook }) {

  return (
    <CloseableDialogComponent
      id="copying-jira-webhook-dialog"
      isOpen={isOpen}
      handleClose={handleClose}
      maxWidth="sm"
      title={t('jira-webhook#naming-and-copying-dialog#title')}
    >
      <DialogContent>
        <DialogContentText className="text-dark">
          <p>{t('jira-webhook#copying-dialog#description')}</p>
          <FormGroup>
            <Input
              required
              id="webhook-name"
              name="webhook-name"
              type="text"
              className="text-dark bg-white"
              disabled
              value={jiraWebhook.endpoint}
            />
          </FormGroup>
        </DialogContentText>
      </DialogContent>
      <DialogActions className="popup-footer justify-content-end p-0">
        <ButtonToolbar>
          <Button
            id="close-copying-dialog-btn"
            title={t('close')}
            color="secondary"
            onClick={handleClose}
          >
            {t('close')}
          </Button>
          <Button
            id="copy-jira-webhook-btn"
            title={t('codeBox#copy')}
            color="primary"
            onClick={handleCopy}
          >
            {t('codeBox#copy')}
          </Button>
        </ButtonToolbar>
      </DialogActions>
    </CloseableDialogComponent>
  );
}

CopyingJiraWebhookDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleCopy: PropTypes.func.isRequired,
  jiraWebhook: PropTypes.object.isRequired,
};

export default CopyingJiraWebhookDialog;
