import { Container } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Chip from '@mui/material/Chip';

export default function ChipList(props) {
  const { children, numberOfItemDisplay, handleShowMore, ...other } = props;

  const [numberOfItemHidden, setNumberOfItemHidden] = useState(0);
  const [groupItemHidden, setGroupItemHidden] = useState([]);

  useEffect(() => {
    setNumberOfItemHidden(children?.length - numberOfItemDisplay);
    setGroupItemHidden(children?.filter((child, index) => index >= numberOfItemDisplay));
  }, [children, numberOfItemDisplay]);

  return (
    <Container
      className="p-0 d-flex flex-wrap"
      {...other}
    >
      { children.map((child, index) => (
        index < numberOfItemDisplay && child
      )) }
      {numberOfItemHidden > 0 && <Chip
        className="border-0 chip-bg-border"
        variant="outlined"
        label={`+${numberOfItemHidden}`}
        clickable
        onClick={() => handleShowMore(groupItemHidden)}
        size="small"
      /> }
    </Container>
  );
}
