import React from 'react';
import Chip from '@katalon-studio/katalon-ui/Chip';
import { styled } from '@mui/material/styles';
import { theme } from '@katalon-studio/katalon-ui/Theme';
import { t } from '../i18n/t';

export function BetaChip(props) {
  return <PrivateChip {...props} label={t('gen5-beta-chip')} />;
}

const PrivateChip = styled(Chip)(() => ({
  width: 47,
  height: 24,
  padding: theme.spacing(0, 2),
  borderRadius: 4,
  backgroundColor: '#eee0ff',
  fontSize: 14,
  fontWeight: 'normal',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 1.43,
  letterSpacing: 'normal',
  textAlign: 'left',
  color: '#233145',

  '& .MuiChip-label': {
    padding: 0,
    marginRight: `${theme.spacing(0)} !important`,
  }
}));

