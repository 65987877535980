import React from 'react';
import { Button, Form, FormGroup, Label, CardBody, Card, FormText, CardFooter, CardHeader, Col, Row } from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import MContext from '../models/MContext';
import Services from '../utils/Services';
import Notification from '../utils/Notification';
import Uploader from './Uploader';
import { t } from '../i18n/t';
import DocumentLink from '../utils/DocumentLink';
import SelectCustomFieldComponent from './SelectCustomFieldComponent';
import TagInput from './TagInput';
import { next } from '../utils/Count';
import { sendAnalyticEventForAction } from '../utils/SegmentAnalytics';
import { CUSTOM_FIELD_TRACKING } from '../utils/Constants';
import TagListTsx from './tsx-components/TagListTsx';

class ImportJUnitReports extends React.Component {
  constructor(props) {
    super(props);
    this.fileUploader = null;
    this.uploadFilesInput = null;
    this.projectId = MContext.projectId;

    this.state = {
      selectedCustomFields: [],
      selectedTags: [],
      tagAndCustomFieldKey: next(),
    };
    this.handleSelectChangeTags = this.handleSelectChangeTags.bind(this);
    this.handleDeleteTags = this.handleDeleteTags.bind(this);
    this.handleUpload = this.handleUpload.bind(this);
  }

  handleReset() {
    this.setState({
      selectedCustomFields: [],
      selectedTags: [],
      tagAndCustomFieldKey: next(),
    });
  }

  handleSelectChangeTags(selectedOptions) {
    sendAnalyticEventForAction('assign-tag-on-manual-import', {});
    const newTags = selectedOptions.filter((tag) => tag?.isNewTag === true);
    if (!isEmpty(newTags)) {
      return Services.createTag(newTags[0]).then((content) => {
        this.setState((prevState) => ({
          selectedTags: [...prevState.selectedTags, content]
        }));
      });
    } else {
      this.setState({
        selectedTags: selectedOptions
      });
    }
    return Promise.resolve();
  }

  setSelectedCustomFields = (customFields) => {
    this.setState({
      selectedCustomFields: customFields,
    });
  };

  handleDeleteTags(option) {
    const { selectedTags } = this.state;
    const filteredTags = selectedTags.filter((item) => item.name !== option.name);
    this.setState({
      selectedTags: filteredTags
    });
  }

  renderSectionTitle(label) {
    return (
      <>
        <Label className="mt-1 font-weight-bold mr-1">
          {label}
        </Label>
        <span className="generate-date">
          {t('optional')}
        </span>
      </>
    );
  }

  handleUpload(e) {
    e.preventDefault();
    this.fileLength = this.uploadFilesInput.files.length;
    this.batch = `${Date.now()}+${uuidv4()}`;

    if (this.fileLength <= 0) {
      Notification.pushError('Cannot process the report.');
    } else {
      this.fileUploader.uploadFile();
      this.setState({
        disableImportReport: true,
      });
    }
  }

  convertDataCustomFieldOptions(selectedCustomFields) {
    return selectedCustomFields.map((selectedCustomField) => ({
      id: selectedCustomField.customFieldOption.id,
      definitionId: selectedCustomField.customFieldDefinition.id
    }));
  }

  onUploadFinish(data, file) {
    const { projectId } = this;
    this.fileLength--;
    let isEnd = 'false';
    const batch = this.batch;
    if (this.fileLength === 0) isEnd = 'true';

    const { selectedTags, selectedCustomFields } = this.state;
    const body = {
      tags: selectedTags,
      customFieldOptions: this.convertDataCustomFieldOptions(selectedCustomFields)
    };
    Services.uploadTestReportsV2({
      projectId,
      batch,
      folderPath: '',
      isEnd,
      fileName: file.name,
      uploadedPath: data.path,
    }, body).then(() => {
      if (isEnd === 'true') {
        this.fileUploader.clear();
        this.handleReset();
        Notification.pushSuccess('Upload successfully');
      }
    }).catch((e) => {
      Notification.pushError(e, 'Cannot process the report.');
    });
  }

  onUploadError(message) {
    Notification.pushError(message);
  }

  renderUploadJUnit() {
    const { canTriggerTestExecution } = this.props;
    const { projectId } = this;
    const { selectedTags, selectedCustomFields, tagAndCustomFieldKey } = this.state;
    return (
      <Card>
        <CardHeader>
          {t('report#JunitFromDevice')}
        </CardHeader>
        <CardBody>
          <Form data-trackid="upload-junit-reports" onSubmit={this.handleUpload}>
            <FormGroup>
              <Label>
                Select JUnit XML report files to upload
              </Label>
              <div>
                <Uploader
                  signingUrl="/api/v1/files/upload-url"
                  signingUrlMethod="GET"
                  contentDisposition="attachment"
                  onFinish={(data, file) => this.onUploadFinish(data, file)}
                  onError={this.onUploadError}
                  signingUrlQueryParams={{
                    projectId,
                    'from-react-s3': true,
                  }}
                  signingUrlWithCredentials
                  uploadRequestHeaders={{
                    'content-type': 'application/octet-stream',
                  }}
                  autoUpload={false}
                  ref={(uploader) => {
                    this.fileUploader = uploader;
                  }}
                  inputRef={(cmp) => {
                    this.uploadFilesInput = cmp;
                  }}
                  multiple
                  disabled={!canTriggerTestExecution}
                />
              </div>
              <FormText color="muted">
                It may take a while to upload large report files
              </FormText>
            </FormGroup>
            <Row>
              <Col sm="3" md="6" lg="6" xl="5">
                <FormGroup key={tagAndCustomFieldKey}>
                  {this.renderSectionTitle(t('custom-fields#title'))}
                  <SelectCustomFieldComponent
                    selectedCustomFields={selectedCustomFields}
                    setSelectedCustomFields={this.setSelectedCustomFields}
                    trackingType={CUSTOM_FIELD_TRACKING.MANUAL_IMPORT}
                  />
                </FormGroup>
              </Col>
            </Row>
            <div className="tag">
              <Row>
                <Col sm="3" md="6" lg="6" xl="5">
                  <FormGroup key={tagAndCustomFieldKey}>
                    {this.renderSectionTitle(t('tag#title'))}
                    <TagInput
                      selectedTags={selectedTags}
                      handleOnSelectChange={this.handleSelectChangeTags}
                      enableFetchTagsEachTimeOpen
                    />
                  </FormGroup>
                </Col>
              </Row>
              <div>
                <TagListTsx
                  selectedTags={selectedTags}
                  handleOnDelete={this.handleDeleteTags}
                />
              </div>
            </div>
            <FormGroup>
              <Button
                type="submit"
                color="primary"
                disabled={!canTriggerTestExecution}
              >
                {t('import')}
              </Button>
            </FormGroup>
          </Form>
        </CardBody>
        <CardFooter>
          <div>
            Submit Test Reports using Maven Plugin.&nbsp;
            <a
              href={DocumentLink.UPLOAD_JUNIT_REPORT_MAVEN_DOCS}
              target="_blank"
              rel="noopener noreferrer"
            >
              Learn more
            </a>
          </div>
          <div>
            Learn how to&nbsp;
            <a
              href={DocumentLink.UPLOAD_KATALON_REPORT_AUTO_DOCS}
              target="_blank"
              rel="noopener noreferrer"
            >
              upload Katalon Studio reports automatically
            </a>
            &nbsp;or&nbsp;
            <a
              href={DocumentLink.UPLOAD_TEST_RESULT_USING_COMMAND_LINE}
              target="_blank"
              rel="noopener noreferrer"
            >
              using command line
            </a>
            .
          </div>
        </CardFooter>
      </Card>
    );
  }

  render() {
    return this.renderUploadJUnit();
  }
}

ImportJUnitReports.propsTypes = {
  canTriggerTestExecution: PropTypes.bool.isRequired,
};

export default ImportJUnitReports;
