import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { next } from '../../utils/Count';

class Timeline extends Component {
  render() {
    const { items } = this.props;
    return (
      <div>
        {items.map((item) => (
          <div key={next()} className="timeline-item">
            <div className="timeline-item__badge">
              {item.icon}
            </div>
            <div className="timeline-item__body">
              <div className="timeline-item__body__title">
                {item.title}
              </div>
              <div className="flex-grow-1">
                {item.content}
                <div className="mt-2">{item.details}</div>
              </div>
              <div>
                {item.button}
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }
}

export default Timeline;

Timeline.propTypes = {
  items: PropTypes.array.isRequired
};

Timeline.defaultProps = {
  items: []
};
