import React from 'react';
import { Button } from 'reactstrap';
import { IconDownload, IconClose } from '../../images/CustomIcon';

export const Header = ({ currentView, modalProps }) => {
  const { onClose } = modalProps;
  const { source } = currentView;

  return (
    <div className="gallery-header">
      <span>
        <Button data-trackid="download-gallery-file" href={source} title="Download">
          <IconDownload />
        </Button>
        <Button onClick={onClose} title="Close">
          <IconClose />
        </Button>
      </span>

    </div>
  );
};
