import React, { useState } from 'react';
import { Popover, ListItem } from '@mui/material';
import PropTypes from 'prop-types';
import { IconDropDownMenu } from '../../images/CustomIcon';


/**
 * Display a Popover dropdown menu when actors click on an icon in UI
 * @param {Array} items
 * @param {ReactComponent} icon
 * @param {String} anchorOriginVertical
 * @param {String} anchorOriginHorizontal
 * @param {String} transformOriginVertical
 * @param {String} transformOriginHorizontal
 * @returns {Popover}
 */
const PopoverDropdownMenu = ({
  items,
  icon = (<IconDropDownMenu />),
  anchorOriginVertical = 'bottom',
  anchorOriginHorizontal = 'center',
  transformOriginVertical = 'top',
  transformOriginHorizontal = 'left',
}) => {

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'popover-dropdown-toggle' : undefined;

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };


  return (
    <div className={`${id || 'popover-dropdown-menu'}`}>
      { icon &&
      <div aria-describedby={id} onClick={handleClick}>
        {icon}
      </div> }
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: anchorOriginVertical,
          horizontal: anchorOriginHorizontal,
        }}
        transformOrigin={{
          vertical: transformOriginVertical,
          horizontal: transformOriginHorizontal,
        }}
      >
        {items.map((item) => (
          <ListItem onClick={handleClose}>
            {item}
          </ListItem>
        ))}
      </Popover>
    </div>
  );
};

PopoverDropdownMenu.propTypes = {
  items: PropTypes.array.isRequired,
  icon: PropTypes.element,
  anchorOriginVertical: PropTypes.oneOf(['top', 'center', 'bottom']), // Anchor origin vertical position
  anchorOriginHorizontal: PropTypes.oneOf(['left', 'center', 'right']), // Anchor origin horizontal position
  transformOriginVertical: PropTypes.oneOf(['top', 'center', 'bottom']), // Tranform origin vertical position
  transformOriginHorizontal: PropTypes.oneOf(['left', 'center', 'right']), // Anchor origin horizontal position
};

export default PopoverDropdownMenu;
