import React, { useEffect, useState } from 'react';
import { Button, ButtonToolbar, Col, CustomInput, Form, Label, Row } from 'reactstrap';
import { Checkbox, Chip, CircularProgress, DialogActions, DialogContent, Grid, Link } from '@mui/material';
import Icon from '@katalon-studio/katalon-ui/Icon';
import { filter } from 'lodash';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import DocumentLink from '../../../utils/DocumentLink';
import { JiraIssueOption } from '../../../models/model/JiraIssueOption';
import ImageProfile from '../../../components/avatar/ImageProfile';
import DropdownField from '../../../components/external_issue/DropdownField';
import { buildSearchCondition } from '../../../components/search/SearchUtils';
import CloseableDialogComponent from '../../../components/dialog/CloseableDialogComponent';
import TableSortCore from '../../../components/table/models/TableSortCore';
import MTableColumnDataMapping from '../../../components/table/models/MTableColumnDataMapping';
import { t } from '../../../i18n/t';
import { IconPlus } from '../../../images/CustomNewIcon';
import { User } from '../../../models/model/User';
import Services from '../../../utils/Services';
import MContext from '../../../models/MContext';
import Notification from '../../../utils/Notification';

function EmailConfig() {

  const projectId = MContext.projectId;
  const [isDailyReportEnabled, setIsDailyReportEnabled] = useState(false);
  const [usersAdded, setUsersAdded] = useState<User[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isOpenAddUserDialog, setIsOpenAddUserDialog] = useState(false);
  const [usersInOrg, setUsersInOrg] = useState<User[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<User[]>([]);

  const getEmailConfiguration = () => {
    setIsLoading(true);
    Services.getEmailDailyConfigByProject(projectId)
      .then((response) => {
        setUsersAdded(response.users);
        setIsDailyReportEnabled(response.projectDailyReportEnabled);
        setIsLoading(false);
      });
  };

  const removeUser = (row: User) => {
    const dailyReportUnsubscription = {
      projectId,
      userId: row.id,
    };
    Services.unSubscribeUsersToDailyEmail(dailyReportUnsubscription)
      .then((response) => {
        getEmailConfiguration();
        const userEmail = response.email;
        Notification.pushSuccess(`<b>${userEmail}</b> has been removed successfully.`);
      });
  };

  const getUsersInOrganization = () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const team: any = MContext.team;
    if (team.organization) {
      const params = {
        pagination: {
          page: 0,
          size: 1000000,
          sorts: 'id,asc',
        },
        conditions: [buildSearchCondition('Organization.id', '=', team.organization.id)],
        type: 'UserOrganization',
        ignoreAutoFill: true,
      };

      Services.search(params)
        .then(({ content }) => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          let userOrgs = content.map((userOrg: any) => userOrg.user);
          usersAdded.forEach((user: User) => {
            userOrgs = filter(userOrgs, (userOrg) => (user.email !== userOrg.email));
          });
          setUsersInOrg(userOrgs);
        });
    }
  };

  useEffect(() => {
    getEmailConfiguration();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return (
      <div className="d-flex w-100 justify-content-center mt-4">
        <CircularProgress size={40} />
      </div>
    );
  }

  const renderImage = (option: User) => (
    <ImageProfile
      name={option.email}
      imgUrl={option.avatar}
      diameter={30}
    />
  );

  const renderUsersTable = () => {

    const columnMapping = [
      new MTableColumnDataMapping(
        t('username'),
        '',
        (_name, row) => (
          <div className="d-flex align-items-center">
            {renderImage(row)}
            <div className="ml-2 fw-bold">
              {row.fullName}
            </div>
          </div>
        )
      ),
      new MTableColumnDataMapping(
        t('email'),
        '',
        (_name, row) => row.email,
      ),
      new MTableColumnDataMapping(
        '',
        '',
        (_name, row) => <Icon
          className="pointer"
          onClick={() => removeUser(row)}
          type="fas"
          name="fa-trash-alt"
          textSize="12px"
        />,
      ),
    ];

    if (usersAdded.length > 0) {
      return <TableSortCore data={usersAdded} columnMapping={columnMapping} />;
    }
    return null;

    return <TableSortCore data={usersAdded} columnMapping={columnMapping} />;
  };

  const onFormSubmit = (e: React.MouseEvent<Element, MouseEvent> | React.SyntheticEvent) => {
    e.preventDefault();
    const userIds = selectedUsers.map((user) => user.id);
    const dailyReportSubscription = {
      projectId,
      userIds,
    };
    Services.subscribeUsersToDailyEmail(dailyReportSubscription)
      .then((response) => {
        getEmailConfiguration();
        setSelectedUsers([]);
        setIsOpenAddUserDialog(false);
        const userEmails = response.userResources.map((user: User) => user.email);
        Notification.pushSuccess(`<b>${userEmails.join(', ')}</b> has been added successfully.`);
      });
  };

  const handleCloseAddUsersDialog = () => {
    setIsOpenAddUserDialog(false);
  };

  const handleOpenAddUsersDialog = () => {
    getUsersInOrganization();
    setIsOpenAddUserDialog(true);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const renderTags = (value: User[], getTagProps: any) => (
    value.map((option: User, index) => (
      <Chip
        key={option.email}
        avatar={renderImage(option)}
        label={option.email}
        title={option.email}
        {...getTagProps({ index })}
      />
    )));

  const icon = <CheckBoxOutlineBlankIcon className="small-icon" />;
  const checkedIcon = <CheckBoxIcon className="small-icon" />;

  const renderOption = (props: React.HTMLAttributes<HTMLLIElement>, option: JiraIssueOption, object?: { selected: boolean }) => (
    <li {...props}>
      <Grid container>
        <Grid item xs={12}>
          <div className="d-flex align-items-center">
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={object?.selected}
            />
            {renderImage(option)}
            <div className="ml-2">
              {option.email}
            </div>
          </div>
        </Grid>
      </Grid>
    </li>);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const changeValueContributor = (_id: string, value: any) => {
    setSelectedUsers(value);
  };

  const renderAddUsersDialog = () => (
    <CloseableDialogComponent
      isOpen={isOpenAddUserDialog}
      handleClose={handleCloseAddUsersDialog}
      maxWidth="sm"
      title={t('email-config#add-user')}
    >
      <Form onSubmit={onFormSubmit} className="m-0">
        <DialogContent className="pt-1">
          <div>
            <div>
              <Label className="mr-1 small fs-12px">{t('home-org-user')}</Label>
              <span className="text-failed-color">*</span>
            </div>
            <DropdownField
              placeholder="Select user..."
              required
              allowedValues={usersInOrg}
              isMultiple
              id="email"
              displayField="email"
              renderTags={renderTags}
              customClassName="w-100 email-dropdown"
              renderOption={renderOption}
              changeValueContributor={changeValueContributor}
            />
          </div>
          <div className="desc-fs-12px mt-1">
            {t('email-config#add-user-dialog#desc')}
          </div>
        </DialogContent>
        <DialogActions className="popup-footer justify-content-end p-0">
          <ButtonToolbar>
            <Button
              id="button-cancel"
              title={t('cancel')}
              color="link"
              onClick={handleCloseAddUsersDialog}
            >
              {t('cancel')}
            </Button>
            <Button
              type="submit"
              id="button-add"
              title={t('add')}
              color="primary"
            >
              {t('add')}
            </Button>
          </ButtonToolbar>
        </DialogActions>
      </Form>
    </CloseableDialogComponent>
  );

  const onChangeProjectSetting = () => {
    const projectSetting = {
      projectId,
      dailyReportEnabled: !isDailyReportEnabled,
    };
    Services.updateProjectSetting(projectSetting)
      .then((projectSetting) => {
        setIsDailyReportEnabled(projectSetting.dailyReportEnabled);
        Notification.pushSuccess('Project setting was updated successfully.');
      });
  };

  return (
    <Row className="email-configuration-container">
      <Col sm="12" md="7" lg="7" xl="7">
        <Row className="email-header">
          <Col>
            {t('email-config#daily-summary-report')}
          </Col>
          <Col className="d-flex justify-content-end align-item-center">
            <CustomInput
              className="custom-switch"
              onChange={() => onChangeProjectSetting()}
              name="active"
              type="switch"
              id="active"
              checked={isDailyReportEnabled}
            />
          </Col>
        </Row>
        <div className="d-flex mt-1 email-content">
          <div className="email-desc mr-1">
            {t('email-config#daily-summary-report#desc')}
            <Link className="email-link" target="_blank" href={DocumentLink.DAILY_SUMMARY_REPORT_LEARN_MORE}>{t('learn_more')}</Link>
          </div>
        </div>
        <div className="mt-3 email-content">
          <div className="fw-bold mr-1">
            {t('email-config#add-user')}
          </div>
          <div className="email-desc mr-1">
            {t('email-config#add-user#desc')}
          </div>
        </div>
        <Button
          id="add-user"
          color="primary"
          className="mt-3 mb-2"
          disabled={!isDailyReportEnabled}
          onClick={handleOpenAddUsersDialog}
        >
          <IconPlus className="ml-0 mr-2" />
          {t('email-config#add-user-header')}
        </Button>
        <div className={!isDailyReportEnabled ? 'ui-state-disabled mt-3' : 'mt-3'}>
          {renderUsersTable()}
        </div>
      </Col>
      {renderAddUsersDialog()}
    </Row>
  );
}

export default EmailConfig;

