import React from 'react';
import Apis from '../../utils/Apis';
import http from '../../utils/http';
import Services from '../../utils/Services';
import ReleaseDropdownButton from '../execution/ReleaseDropdownButton';

const LinkRunConfigurationReleaseBuild = ({ runConfiguration = {}, onChange, ...props }) => {

  const handleSelectBuild = (build) => {
    Services.linkRunConfigurationBuild(runConfiguration.id, build.id).then((runConfiguration) => {
      onChange(runConfiguration);
    });
  };

  const handleSelectRelease = (value) => {
    http.post(Apis.linkRunConfigurationRelease(runConfiguration.projectId, runConfiguration.id, value.id))
      .then((runConfiguration) => {
        onChange(runConfiguration);
      });
  };

  const unlinkRelease = () => {
    http.post(Apis.unlinkRunConfigurationRelease(runConfiguration.projectId, runConfiguration.id))
      .then((runConfiguration) => {
        onChange(runConfiguration);
      });
  };

  return (
    <ReleaseDropdownButton
      release={runConfiguration.release}
      build={runConfiguration.build}
      onSelectRelease={handleSelectRelease}
      onSelectBuild={handleSelectBuild}
      unlinkRelease={unlinkRelease}
      {...props}
    />
  );
};

export default LinkRunConfigurationReleaseBuild;