import { loadStripe } from '@stripe/stripe-js/pure';
import React, { useEffect, useState } from 'react';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  Elements,
  injectStripe,
  StripeProvider,
} from 'react-stripe-elements';
import { Button, Form, FormGroup, Label } from 'reactstrap';
import { OrganizationLicensesHeaderNav } from '../../components/HeaderNav';
import Input from '../../components/Input';
import ObjectSummary from '../../components/summary/ObjectSummary';
import { t } from '../../i18n/t';
import MConfigs from '../../models/MConfigs';
import ImageBrandCard from './ImageBrandCard';
import ListBrandCards from './ListBrandCards';
import Services from '../../utils/Services';
import MContext from '../../models/MContext';
import MAuth from '../../models/MAuth';

class _CardForm extends React.Component {
  get isReadyToPay() {
    const {
      cardNumberComplete,
      expirationComplete,
      cvcComplete,
      cardHolderComplete
    } = this.state;
    return cardNumberComplete && expirationComplete && cvcComplete && cardHolderComplete;
  }

  constructor(props) {
    super(props);
    this.organizationId = MContext.organizationId;
    this.user = MAuth.user;

    this.initState();

    this.handleCardHolder = this.handleCardHolder.bind(this);
    this.handleCardNumber = this.handleCardNumber.bind(this);
    this.handleExpirationDate = this.handleExpirationDate.bind(this);
    this.handleCvc = this.handleCvc.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.showErrorAlert = this.showErrorAlert.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.clearData = this.clearData.bind(this);
  }

  initState() {
    this.state = {
      cardNumberComplete: false,
      cvcComplete: false,
      expirationComplete: false,
      cardHolder: '',
      cardHolderComplete: false,
      brand: '',
      message: '',
      errorMessages: {},
      isBlocked: false
    };
  }

  clearData() {
    this._cardNumber.clear();
    this._cardCvc.clear();
    this._cardExpiry.clear();
    this.setState({
      cardHolder: ''
    });
  }

  blockUI() {
    this.setState({
      isBlocked: true
    });
  }

  unBlockUI() {
    this.setState({
      isBlocked: false
    });
  }

  renderObjectSummary() {
    const urlParams = {
      license_keys: t('license_keys'),
    };
    return (
      <ObjectSummary params={urlParams}>
        <OrganizationLicensesHeaderNav organizationId={this.organizationId} />
      </ObjectSummary>
    );
  }

  getNextErrorMessage() {
    return Object.keys(this.state.errorMessages)
      .sort()
      .reduce((maybeFoundError, key) => maybeFoundError || this.state.errorMessages[key], null);
  }

  setErrorMessage(message, idx) {
    this.setState((prevState) => {
      const errorMessages = { ...prevState.errorMessages };
      errorMessages[idx] = message;
      return {
        message,
        errorMessages
      };
    });
  }

  handleErrorMessage(event) {
    if (event.error) {
      const message = event.error.message;
      this.setErrorMessage(message, event.elementType);
    } else {
      this.setErrorMessage(null, event.elementType);
      const message = this.getNextErrorMessage();
      this.setState({ message });
    }
  }

  handleMessage(message, event) {
    this.setErrorMessage(message, event.elementType);
  }

  handleCardNumber(event) {
    this.handleErrorMessage(event);
    const { brand, complete } = event;
    if (brand === 'unknown' && complete) {
      const message = t('payment#error_invalid_card');
      this.handleMessage(message, event);
      this.setState({
        cardNumberComplete: !complete,
      }, () => {
        this.dispatchOnChangeEvent(event);
      });
    } else {
      this.setState({
        brand,
        cardNumberComplete: complete,
      }, () => {
        this.dispatchOnChangeEvent(event);
      });
    }
  }

  handleExpirationDate(event) {
    this.handleErrorMessage(event);
    const complete = event.complete;
    this.setState({ expirationComplete: complete }, () => {
      this.dispatchOnChangeEvent(event);
    });
  }

  handleCvc(event) {
    this.handleErrorMessage(event);
    const complete = event.complete;
    this.setState({ cvcComplete: complete }, () => {
      this.dispatchOnChangeEvent(event);
    });
  }

  handleCardHolder(event) {
    const value = event.target.value;
    this.setState({ cardHolder: value, cardHolderComplete: !!value }, () => {
      this.dispatchOnChangeEvent(event);
    });
  }

  dispatchOnChangeEvent(event) {
    if (this.props.onChange) {
      this.props.onChange(event);
    }
  }

  formatMessage(message) {
    // Stripe's message format: <message>;<type_error>;<request_id>
    const result = message.split(';');
    return result[0];
  }

  showErrorAlert(message) {
    const messageFormat = this.formatMessage(message);
    this.setState({
      message: messageFormat,
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    this.createToken();
  }

  createToken() {
    // anonymous user can checkout quote with payment info, so this.user can be null
    const user = this.user;

    this.blockUI();
    if (this.props.stripe) {
      return this.props.stripe.createToken({ name: this.state.cardHolder })
        .catch((error) => {
          this.unBlockUI();
          this.showErrorAlert(error && error.message);
        })
        .then((token) => {
          this.unBlockUI();
          Services.track('PAYMENT_METHOD', {
            category: 'Payment Method',
            email: user?.email,
            first_name: user?.firstName,
            label: 'Payment Method',
            last_name: user?.lastName,
            organizationId: `organization-${this.organizationId}`,
            value: true
          });
          if (this.props.onTokenCreated) {
            this.props.onTokenCreated(token, this.showErrorAlert);
            this.clearData();
          }
          return token;
        });
    } else {
      return Promise.reject(new Error('Stripe.js hasn\'t loaded yet.'));
    }
  }

  handleCancel() {
    if (this.props.onCancel) {
      this.props.onCancel();
      this.clearData();
    }
  }

  render() {
    const style = {
      base: {
        fontSize: '18px',
        fontWeight: 300,
        fontFamily: 'Source Code Pro, monospace',
        letterSpacing: '2px',
        fontSmoothing: 'antialiased',

        ':focus': {
          color: '#081D36',
        },
        '::placeholder': {
          color: '#A1A9B3',
          letterSpacing: '5px',
          fontWeight: 100,
        },
        ':focus::placeholder': {
          color: '#CFD7DF',
        },
      },
      invalid: {
        color: '#ff8080',
        ':focus': {
          color: '#ff5050',
        },
        '::placeholder': {
          color: '#FFCCA5',
        },
      },
    };
    const customStyle = {
      background: 'none',
      border: 'none',
      padding: 0,
    };


    const { disabled, title = t('payment'), submitLabel = 'Pay now', alignButtons = 'left', cancelLabel } = this.props;
    const {
      message,
      brand,
      isBlocked,
      cardHolder
    } = this.state;
    const isDisabled = isBlocked || disabled || false;


    return (
      <Form
        data-trackid="update-payment-method"
        onSubmit={this.handleSubmit}
        target="_blank"
        className="checkout"
      >
        <FormGroup>
          {title && <div className="checkout__title">{title}</div>}
          <div className="d-flex flex-wrap checkout__card-details__supported-cards">
            {Object.values(ListBrandCards)
              .map((listBrandCard) =>
                <ImageBrandCard
                  highLightCard={brand}
                  {...listBrandCard}
                />)}
          </div>
        </FormGroup>
        <FormGroup>
          <Label for="cardNumber">{t('payment#card_number')}</Label>
          <CardNumberElement
            onReady={(e) => this._cardNumber = e}
            style={style}
            placeholder="---- ---- ---- ----"
            onChange={this.handleCardNumber}
            disabled={isDisabled}
          />
        </FormGroup>
        <FormGroup>
          <Label for="expiration">{t('payment#expiration')}</Label>
          <CardExpiryElement
            onReady={(e) => this._cardExpiry = e}
            style={style}
            placeholder="MM / YY"
            onChange={this.handleExpirationDate}
            disabled={isDisabled}
          />
        </FormGroup>
        <FormGroup>
          <Label for="cvc">{t('payment#cvc')}</Label>
          <CardCvcElement
            onReady={(e) => this._cardCvc = e}
            style={style}
            placeholder="CVC"
            onChange={this.handleCvc}
            disabled={isDisabled}
          />
        </FormGroup>
        <FormGroup>
          <Label for="cardHolder">{t('payment#card_holder')}</Label>
          <Input
            style={{ ...style.base, ...customStyle }}
            type="text"
            autoComplete="off"
            value={cardHolder}
            id="cardHolder"
            placeholder="Name on Card"
            onChange={this.handleCardHolder}
            disabled={isDisabled}
            required
          />
        </FormGroup>
        <FormGroup>
          <div className="checkout__error__message text-danger">{message}</div>
        </FormGroup>
        <div className="d-flex align-items-center">
          {submitLabel && (
            <FormGroup className={`text-${alignButtons}`}>
              <Button color="primary" disabled={!this.isReadyToPay || isBlocked || disabled}>
                {submitLabel}
              </Button>
            </FormGroup>
          )}
          {cancelLabel && (
            <FormGroup className="ml-4">
              <Button
                color="secondary"
                onClick={this.handleCancel}
              >
                {cancelLabel}
              </Button>
            </FormGroup>
          )}
        </div>
      </Form>
    );
  }
}

const CardForm = injectStripe(_CardForm, { withRef: true });

export default React.forwardRef((props, ref) => {
  const [stripe, setStripe] = useState(null);

  useEffect(() => {
    loadStripe(publicKey).then(setStripe);
  }, []);

  return (
    <StripeProvider stripe={stripe}>
      <Elements>
        <CardForm {...props} ref={ref} />
      </Elements>
    </StripeProvider>
  );
});
