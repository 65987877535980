import React from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import ResultTab from '../pages/execution/ResultTab';
import ExecutionCustomFieldAndTag from './ExecutionCustomFieldAndTag';

const ResultTabWithCustomFieldAndTag = (props) => {

  const { keyesExecution, execution, params } = props;

  return (
    <Row className="sidebar-layout">
      <Col>
        <Row>
          <Col md={9} className="sidebar-layout__main-content">
            <ResultTab keyesExecution={keyesExecution} execution={execution} {...params} />
          </Col>
          <Col md={3} className="sidebar-layout__right-sidebar">
            <Card>
              <CardBody>
                <ExecutionCustomFieldAndTag execution={execution} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default ResultTabWithCustomFieldAndTag;
