import React, { Component } from 'react';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import { t } from '../../i18n/t';
import testReports from '../test_reports/ListTestReports';
import TestReportsComponents from '../../components/TestReportComponent';

class CoverageReport extends Component {

  renderCard() {
    return (
      <Card>
        <CardHeader>{t('reports')}</CardHeader>
        <CardBody>
          <Row className="report-main">
            <Col sm="12" md="12" lg="4" xl="4">
              <TestReportsComponents report={testReports.requirementTestRunCoverage} />
            </Col>
            <Col sm="12" md="12" lg="4" xl="4">
              <TestReportsComponents report={testReports.traceability} />
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  }

  render() {
    return this.renderCard();
  }
}

export default CoverageReport;
