import template from 'url-template';
import jquery from 'jquery';
import MConfigs from "../models/MConfigs";

const projectTemplate = template.parse('/api/v1/projects/{projectId}');

const executionTemplate = template.parse('/api/v1/executions/{executionId}');

const executionRerunTemplate = template.parse('/api/v1/executions/{executionId}/rerun');

const executionExportTemplate = template.parse('/api/v1/executions/{executionId}/export');

const executionEnqueueTemplate = template.parse('/api/v1/executions/{executionId}/export/enqueue-file');

const executionGetExportedFile = template.parse('/api/v1/executions/{executionId}/export/check-exist-file');

const sendExecutionExportMail = template.parse('/api/v1/executions/{executionId}/export/send-mail');

const executionDownloadFileTemplate = template.parse('/api/v1/executions/{executionId}/download-file');

const executionLinkReleaseTemplate = template.parse('/api/v1/executions/{executionId}/link-release');

const executionLinkBuildTemplate = template.parse('/api/v1/executions/{executionId}/link-build');

const executionUnlinkReleaseTemplate = template.parse('/api/v1/executions/{executionId}/unlink-release');

const executionShareReportTemplate = template.parse('/api/v1/executions/{executionId}/share-report');

const executionRequestDownloadTemplate = template.parse('/api/v1/requests/{executionRequestId}/download');

const executionTestSuiteTemplate = template.parse('/api/v1/execution-test-suites/{executionTestSuiteId}');

const executionTestResultTemplate = template.parse('/api/v1/test-results/{executionTestResultId}');

const executionTestResultUpdateCustomFieldTemplate = template.parse('/api/v1/test-results/{executionTestResultId}/custom-fields');

const executionTestResultLogTemplate = template.parse('/api/v1/test-results/logs/{testResultLogId}');

const executionTestResultIncidentTemplate = template.parse('/api/v1/test-results/{executionTestResultId}/incidents');

const updateLabelExecutionTestResultTemplate = template.parse('/api/v1/test-results/{executionTestResultId}/label');

const testSuiteTemplate = template.parse('/api/v1/test-suites/{testSuiteId}');

const testSuitesTemplate = template.parse('/api/v1/test-suites');

const editTestSuiteTemplate = template.parse('/api/v1/test-suites/{testSuiteId}/edit');

const runTestCase = template.parse('/api/v1/test-projects/{id}/run-test-cases');

const deleteTestSuiteTemplate = template.parse('/api/v1/test-suites/{testSuiteId}');

const testCaseTemplate = template.parse('/api/v1/test-cases/{testCaseId}');

const testCaseTagTemplate = template.parse('/api/v1/test-cases/{testCaseId}/tags');

const executionTagTemplate = template.parse('/api/v1/executions/{executionId}/tags');

const executionTestReultTagTemplate = template.parse('/api/v1/test-results/{executionTestResultId}/tags');

const testObjectTemplate = template.parse('/api/v1/test-objects/{testObjectId}');

const testObjectEntityTemplate = template.parse('/api/v1/test-object-entities/{testObjectEntityId}');

const organizationTemplate = template.parse('/api/v1/organizations/{organizationId}');

const organizationSubscriptionLicenseTemplate = template.parse('/api/v1/organizations/{organizationId}/subscription-license');

const organizationProjectsTemplate = template.parse('/api/v1/organizations/{organizationId}/projects');

const teamTemplate = template.parse('/api/v1/teams/{teamId}');

const incidentTemplate = template.parse('/api/v1/incidents/{incidentId}');

const releaseTemplate = template.parse('/api/v1/releases/{releaseId}');

const executionRequestTemplate = template.parse('/api/v1/requests/{executionRequestId}');

const apiKeyTemplate = template.parse('/api/v1/api-keys/{apiKeyId}');

const testProjectTemplate = template.parse('/api/v1/test-projects/{testProjectId}');

const testProjectGitTemplate = template.parse('/api/v1/git/{testProjectGitId}');

const runConfigurationTemplate = template.parse('/api/v1/run-configurations/{runConfigurationId}');

const executeRunConfigurationTemplate = template.parse('/api/v1/run-configurations/{runConfigurationId}/execute');

const runConfigurationLinkReleaseTemplate = template.parse('/api/v1/run-configurations/{runConfigurationId}/link-release');

const runConfigurationLinkBuildTemplate = template.parse('/api/v1/run-configurations/{runConfigurationId}/link-build');

const runConfigurationUnlinkReleaseTemplate = template.parse('/api/v1/run-configurations/{runConfigurationId}/unlink-release');

const createSampleGitTestProjectTemplate = template.parse('/api/v1/test-projects/sample-git-test-project');

const downloadTestProjectTemplate = template.parse('/api/v1/test-projects/{testProjectId}/download');

const refreshTSCTestProjectTemplate = template.parse('/api/v1/test-projects/{testProjectId}/refresh-tsc');

const updateTestProjectPackageTemplate = template.parse('/api/v1/test-projects/{testProjectId}/update-script-repo');

const schedulerTemplate = template.parse('/api/v1/test-projects/{runConfigurationId}/schedulers/{schedulerId}');

const fileDownloadTemplate = template.parse('/api/v1/files/{fileHandleId}');

const jobTemplate = template.parse('/api/v1/jobs/{jobId}');

const jobLogsTemplate = template.parse('/api/v1/jobs/{jobId}/get-log');

const agentTemplate = template.parse('/api/v1/agent/{agentId}');

const testCloudAgentTemplate = template.parse('/api/v1/test-cloud/{agentId}');

const testCloudLicensesTemplate = template.parse('/api/v1/test-cloud/organizations/{organizationId}/licenses');

const buildTemplate = template.parse('/api/v1/build/{buildId}');

const analysisSettingsTemplate = template.parse('/api/v1/analysis/settings/{projectId}');

const getSubscriptionUrlTemplate = template.parse('/api/v1/subscription/{subscriptionId}');

const getSubscriptionsInfoTemplate = template.parse('/api/v1/subscription/info/{organizationId}');

const getActiveSubscriptionsInfoTemplate = template.parse('/api/v1/subscription/info/{organizationId}/active');

const assignSubscriptionTemplate = template.parse('/api/v1/subscription/{subscriptionId}');

const gitGetBranches = template.parse('/api/v1/git/branch');

const gitCreateTestProject = template.parse('/api/v1/git/create');

const gitUpdateTestProject = template.parse('/api/v1/git/update');

const getQuoteTemplate = template.parse('/public/quotes/{uuid}');

const checkoutQuoteTemplate = template.parse('/public/quotes/{uuid}/checkout');

const k8sAgentTemplate = template.parse('/api/v1/k8s-agent/{k8sAgentId}');

const circleCIAgentTemplate = template.parse('/api/v1/circle-ci-agent/{circleCIAgentId}');

const downloadKatalonRecorderBackupTemplate = template.parse('/api/v1/katalon-recorder/backup/{backupId}/download');

const recentProjects = template.parse('/api/v1/recent/organization/{organizationId}/projects');

const recentProject = template.parse('/api/v1/recent/project/{projectId}');

const whitelistIps = template.parse('/api/v1/organizations/{organizationId}/whitelist-ips');

const organizationDomain = template.parse('/api/v1/organizations/{organizationId}/domain');

const organizationStrictDomain = template.parse('/api/v1/organizations/{organizationId}/strict-domain');

const updateOrganizationLogo = template.parse('/api/v1/organizations/{organizationId}/logo');

const organizationTrialRequest = template.parse('/api/v1/organizations/{organizationId}/trial-request');

const organizationTestExecutionQuota = template.parse('/api/v1/organizations/{organizationId}/test-execution-quota');

const organizationLicensedUsers = template.parse('/api/v1/organizations/{organizationId}/licensed-users');

const resolveCheckpointTemplate = template.parse('/api/v1/checkpoints/{checkpointId}/resolve');

const resolveMultipleCheckpointTemplate = template.parse('/api/v1/checkpoints/resolve');

const saveExecutionToBaseline = template.parse('/api/v1/keyes-executions/{keyesExecutionId}/save');

const reimportKeyesExecutionTemplate = template.parse('/api/v1/keyes-executions/{keyesExecutionId}/reimport');

const markAsRetestedTestRunTemplate = template.parse('/api/v1/test-results/{testResultId}/mask-as-retested');

const cancelLicenseTemplate = template.parse('/api/v1/license-keys/{licenseKeyId}/cancel');

const getUserOrganizationTemplate = template.parse('/api/v1/user-organization/{userId}');

const getSamlIdpTemplate = template.parse('/api/v1/saml-idps/{organizationId}');

const projectSettingTemplate = template.parse('/api/v1/project-settings/{projectId}');

const projectConfigurationTemplate = template.parse('/api/v1/project-configurations/{projectId}');

const createSampleProject = template.parse('/api/v1/projects/{projectId}/sample-data');

const populateSampleProject = template.parse('/api/v1/projects/{projectId}/populate-sample-data');

const subscriptionTestOpsInfo = template.parse('/api/v1/subscription-testops/info/{organizationId}');

const subscriptionTestOpsCheckout = template.parse('/api/v1/subscription-testops/checkout');

const subscriptionTestOpsReviewOrder = template.parse('/api/v1/subscription-testops/review-order');

const cancelSubscription = template.parse('/api/v1/subscription/cancel-order');

const katOneOrganizationHomeSSOPage = template.parse('/api/v1/katone/page/organization-home');

const katOneDashboardSSOPage = template.parse('/api/v1/katone/page/dashboard');

const katOneLicenseUtilizationSSOPage = template.parse('/api/v1/katone/page/license-utilization');

const katOneUserManagementSSOPage = template.parse('/api/v1/katone/page/user-management');

const katOneWelcomeSSOPage = template.parse('/api/v1/katone/page/welcome');

const katOnePaymentMethodSSOPage = template.parse('/api/v1/katone/page/payment-method');

const katOneAccountSubscriptionManagementSSOPage = template.parse('/account/subscription-management');

const katOneAccountSettingsSSOPage = template.parse('/api/v1/katone/page/account-settings');

const katOneUserManagementPendingSSOPage = template.parse('/api/v1/katone/page/user-management/pending-invitations');

const katOneProfilePage = template.parse('/api/v1/katone/page/profile');

const katOneSupportPortalPage = template.parse('/api/v1/katone/page/oauth/service-cloud');

const katOneSupportManagementPage = template.parse('/api/v1/katone/page/support-management');

const katOneOrganizationManagementPage = template.parse('/api/v1/katone/page/organization/{organizationId}/management');

const cache = template.parse('/api/v1/cache');

const searchCache = template.parse('/api/v1/search/cache');

const smartSchedulerCreateTrigger = template.parse('/api/v1/smart-scheduler/schedule');

const smartSchedulerRunNow = template.parse('/api/v1/smart-scheduler/run');

const smartSchedulerCreateAndUpdate = template.parse('/api/v1/smart-scheduler/save-configuration');

const smartSchedulerTemplate = template.parse('/api/v1/smart-scheduler/{runConfigurationId}');

const refreshJiraReleaseStatus = template.parse('/api/v1/integrations/refresh-jira-releases');

const refreshJiraIssueOfProject = template.parse('/api/v1/integrations/refresh-jira-issues-of-project');

const katone_admin_profile = template.parse('/api/v1/katone/page/admin/profile');

const katone_admin_organization_settings = template.parse('/api/v1/katone/page/admin/organization/{organizationId}/settings');

const katone_admin_organization_users = template.parse('/api/v1/katone/page/admin/organization/{organizationId}/users');

const katone_admin_payment_method = template.parse('/api/v1/katone/page/admin/organization/{organizationId}/payment-method');

const katone_admin_organization_subscriptions = template.parse('/api/v1/katone/page/admin/organization/{organizationId}/subscriptions');

const katone_admin_organization_subscriptions_testops = template.parse('/api/v1/katone/page/admin/organization/{organizationId}/testops');

const katone_admin_organization_license_keys = template.parse('/api/v1/katone/page/admin/organization/{organizationId}/license_keys');

const katone_admin_create_organization = template.parse('/api/v1/katone/page/admin/create_organization');

const katone_admin_create_project = template.parse('/api/v1/katone/page/admin/organization/{organizationId}/create_project');

const testops_organization_home = template.parse('/api/v1/katone/page/testops/organization/{organizationId}/home');

const testops_project_home = template.parse('/api/v1/katone/page/testops/team/{teamId}/project/{projectId}');

const testops_project_settings = template.parse('/api/v1/katone/page/testops/team/{teamId}/project/{projectId}/settings');

const testops_team_home = template.parse('/api/v1/katone/page/testops/team/{teamId}');

const admin_team_project = template.parse('/api/v1/katone/page/admin/team/{teamId}/project');

const admin_team_users = template.parse('/api/v1/katone/page/admin/team/{teamId}/users');

const admin_team_settings = template.parse('/api/v1/katone/page/admin/team/{teamId}/settings');

const testops_organization_all_projects = template.parse('/api/v1/katone/page/testops/organization/{organizationId}/teams/projects');

const testops_organization_all_teams = template.parse('/api/v1/katone/page/testops/organization/{organizationId}/teams/settings');

const admin_organization_all_projects = template.parse('/api/v1/katone/page/admin/organization/{organizationId}/teams/projects');

const admin_organization_all_teams = template.parse('/api/v1/katone/page/admin/organization/{organizationId}/teams/settings');

const testops_user_settings = template.parse('/api/v1/katone/page/testops/user/settings');

const markNotificationAsRead = template.parse('/api/v1/notifications/{notificationId}/users');

const markAllNotificationsAsRead = template.parse('/api/v1/notifications/users');

const getNumberOfNotifications = template.parse('/api/v1/notifications/number-of-notifications');

const getSubFolders = template.parse('/api/v1/test-folder/sub-folders');

const deleteTunnel = template.parse('/api/v1/test-cloud/tunnels/{id}');

const demoSite = template.parse('/api/v1/demo-site');

const baselinesLatestVersion = template.parse('/api/v1/baselines-latest-version');

const saveIgnoringZones = template.parse('/api/v1/baseline-collections/{id}/ignored-zones/save');

const saveDraftIgnoredZones = template.parse('/api/v1/baseline-collections/{id}/ignored-zones/save-draft');

const baselineCollectionGroup = template.parse('/api/v1/baseline-collection-groups/{id}');

const visualTestingQuota = template.parse('/api/v1/organizations/{organizationId}/visual-testing-quota');

const organizationMigration = template.parse('/api/v1/organizations/{organizationId}/organization-migration');

const getSystemLabel = template.parse('/api/v1/labels/system-labels');

const checkRequiredGitRepository = template.parse('/api/v1/projects/{projectId}/check-required-git-repo');

const skipTestOpsSurvey = template.parse('/api/v1/survey/skip');

const submitTestOpsSurvey = template.parse('/api/v1/survey/submit');

const detectKsEdition = template.parse('/api/v1/organizations/{organizationId}/detect-ks-edition');

const createCustomFieldDefinition = template.parse('/api/v1/custom-field-definitions/create');

const customFieldDefinition = template.parse('/api/v1/custom-field-definitions');

const saveDraftBaselineCollection = template.parse('/api/v1/baseline-collections/save-draft');

const previewCheckpointPixel = template.parse('/api/v1/checkpoint-pixels/{id}/preview');

const executionUpdateCustomFieldsTemplate = template.parse('/api/v1/executions/{executionId}/custom-fields');

const uploadBaselineImages = template.parse('/api/v1/baseline-collections/upload-baselines');

const createBaselineCollection = template.parse('/api/v1/baseline-collections');

const executionPushToXrayTemplate = template.parse('/api/v1/executions/{executionId}/push-to-xray');

const jiraWebhook = template.parse('/api/v1/project/{projectId}/webhooks/jira');

const jiraWebhookRotating = template.parse('/api/v1/project/{projectId}/webhooks/jira/rotate');

const testManagementTestCaseTemplate = template.parse('/api/v1/test-management/test-cases/{id}');

const testManagementHasWritePermissionTemplate = template.parse('/api/v1/test-management/test-projects/{id}/has-write-permission');

const publishTestProjectTemplate = template.parse('/api/v1/test-management/test-projects/{id}/publish');

const publishProjectTemplate = template.parse('/api/v1/test-management/projects/{id}/publish');

const projectTrackingInformationTemplate = template.parse('/api/v1/projects/{projectId}/tracking-information');

const projectFeatureTemplate = template.parse('/api/v1/projects/{projectId}/ai/features/{featureName}');

/**
 * @deprecated Use getAllSubFoldersAndItsTestEntities() instead
 */
const getAllSubFoldersAndItsTestCasesTemplate = template.parse('/api/v1/test-folder/sub-folders-test-cases');

const getAllSubFoldersAndItsTestEntitiesTemplate = template.parse('/api/v1/test-folder/sub-folders-test-entities');

const onboardingStepsTemplate = template.parse('/api/v1/onboarding-steps');

const aiStatistics = template.parse('/api/v1/open-ai/statistics');

const aiCompletions = template.parse('/api/v1/open-ai/completions');

const metricSummaryInfo = template.parse('/api/v1/open-ai/metric-summary');

const latestIncomingActiveReleases = template.parse('/api/v1/releases/latest-incoming-active');

const getAutoCompleteResponse = template.parse('/api/v1/integrations/auto-complete');

const getAllJiraIssueTypesMetadata = template.parse('/api/v1/integrations/createmeta/issuetypes');

const getJiraIssueTypeMetadataByName = template.parse('/api/v1/integrations/createmeta/issuetype-fields');

const getMatchTestResultsByAutomationRule = template.parse('/api/v1/integrations/automation-rule-matched-results');

const createAutomationRule = template.parse('/api/v1/integrations/external-issue-automation-rule');

const deleteAutomationRuleTemplate = template.parse('/api/v1/integrations/external-issue-automation-rule/{id}');

const validateAutomationRule = template.parse('/api/v1/integrations/external-issue-automation-rule/validate');

const jiraCreateUrl = template.parse('/api/v1/integrations/create-url');

const suggestedDefectsTempleate = template.parse('/api/v1/integrations/suggested-defects');

const getEmailDailyConfigByUser = template.parse('/api/v1/daily-digest/get-config-by-user');

const getEmailDailyConfigByProjectTemplate = template.parse('/api/v1/daily-digest/get-config-by-project');

const subscribeUsersToDailyEmailTemplate = template.parse('/api/v1/daily-digest/subscribe');

const unsubscribeUsersToDailyEmailTemplate = template.parse('/api/v1/daily-digest/unsubscribe');

const getQueryString = (urlObject) => {
  if (urlObject) {
    return `?${jquery.param(urlObject, true)}`;
  }
  return '';
};

const Apis = {
  getQueryString,

  token: '/oauth/token',

  logout: '/api/v1/users/logout',

  searchInfo: '/api/v1/search/info',

  permissionTeamUser: '/api/v1/permission/team/user',

  apiKeys: '/api/v1/api-keys',

  integrationSetting: '/api/v1/integrations/setting',

  saveExternalIssueNotificationConfig: '/api/v1/integrations/save-external-issue-notification-config',

  changePassword: '/api/v1/users/change-password',

  kobitonIntegrationSetting: '/api/v1/kobiton/setting',

  kobitonIntegrationSettingTest: '/api/v1/kobiton/setting/test-connection',

  slackWebhookSetting: '/api/v1/slackwebhook/setting',

  slackWebhooktSettingTest: '/api/v1/slackwebhook/setting/test-connection',

  statusFeedback: '/api/v1/feedback',

  samlSSO: '/saml/login',

  updateSamlIdp: '/api/v1/saml-idps/update',

  activeTunnels: '/api/v1/test-cloud/tunnels',

  createTestSuite: '/api/v1/test-suites/create',

  updateContactHubSpot: `${process.env.REACT_APP_MARKETING_API_URL}/wp-json/restful_api/v1/hubspot/update-contact`,

  getTestCasesStatistics: '/api/v1/test-cases/statistics',

  fileDownload: (fileHandleId) => {
    const pathVariables = { fileHandleId };
    const path = fileDownloadTemplate.expand(pathVariables);
    return path;
  },

  checkExported: (executionId, fileType) => {
    const pathVariables = { executionId };
    const queryString = getQueryString(fileType);
    const path = executionGetExportedFile.expand(pathVariables);
    return path + queryString;
  },

  search: (queryInfo) => {
    const path = '/api/v1/search';
    const queryString = getQueryString(queryInfo);
    return path + queryString;
  },

  searchCache: (queryInfo) => {
    const path = searchCache.expand();
    const queryString = getQueryString(queryInfo);
    return path + queryString;
  },

  markAsRetestedTestRun: (testResultId) => {
    const pathVariables = { testResultId };
    return markAsRetestedTestRunTemplate.expand(pathVariables);
  },

  /**
   * @deprecated due to TOS-1891
   * Remove incidentEnabled flag
   * This api is to link/unlink test result to a task
   */
  executionTestResultIncidents: (executionTestResultId, queryInfo) => {
    const pathVariables = { executionTestResultId };
    const path = executionTestResultIncidentTemplate.expand(pathVariables);
    const queryString = getQueryString(queryInfo);
    return path + queryString;
  },

  /**
   * @deprecated due to TOS-1891
   * Remove incidentEnabled flag
   * This api is to create/update a task
   */
  incidents: (queryInfo) => {
    const path = '/api/v1/incidents';
    const queryString = getQueryString(queryInfo);
    return path + queryString;
  },

  /**
   * @deprecated due to TOS-1891
   * Remove incidentEnabled flag
   * This api is to get a task detail
   */
  incidentId: (id) => {
    const pathVariables = { incidentId: id };
    const path = incidentTemplate.expand(pathVariables);
    return path;
  },

  releases: (queryInfo) => {
    const path = '/api/v1/releases';
    const queryString = getQueryString(queryInfo);
    return path + queryString;
  },

  buildCreate: (queryInfo) => {
    const path = '/api/v1/build';
    const queryString = getQueryString(queryInfo);
    return path + queryString;
  },

  buildUpdate: (buildId) => {
    const pathVariables = { buildId };
    const path = buildTemplate.expand(pathVariables);
    return path;
  },

  buildDelete: (buildId) => {
    const pathVariables = { buildId };
    const path = buildTemplate.expand(pathVariables);
    return path;
  },

  release: (id) => {
    const pathVariables = { releaseId: id };
    const path = releaseTemplate.expand(pathVariables);
    return path;
  },

  activateRelease: (id, queryInfo) => {
    const path = '/active';
    const pathVariables = { releaseId: id };
    const queryString = getQueryString(queryInfo);
    const fullPath = releaseTemplate.expand(pathVariables) + path + queryString;
    return fullPath;
  },

  projects: (queryInfo) => {
    const path = '/api/v1/projects';
    const queryString = getQueryString(queryInfo);
    return path + queryString;
  },

  updateStatusProject: (queryInfo) => {
    const path = '/api/v1/projects/update-status';
    const queryString = getQueryString(queryInfo);
    return path + queryString;
  },

  project: (id) => {
    const pathVariables = { projectId: id };
    const path = projectTemplate.expand(pathVariables);
    return path;
  },

  execution: (projectId, order) => {
    const path = '/api/v1/executions';
    const queryInfo = { projectId, order };
    const queryString = getQueryString(queryInfo);
    return path + queryString;
  },

  executionInfo: (executionId) => {
    const pathVariables = { executionId };
    return executionTemplate.expand(pathVariables);
  },

  pushExecutionToXray: (executionId, xrayTestPlanId, externalReleaseId) => {
    const pathVariables = { executionId };
    const queryInfo = { xrayTestPlanId, externalReleaseId };
    const queryString = getQueryString(queryInfo);
    return executionPushToXrayTemplate.expand(pathVariables) + queryString;
  },

  linkExecutionRelease: (projectId, executionId, releaseId) => {
    const path = executionLinkReleaseTemplate.expand({ executionId });
    const queryInfo = { projectId, releaseId };
    const queryString = getQueryString(queryInfo);
    return path + queryString;
  },

  executionUpdateCustomFields: (executionId) => {
    const path = executionUpdateCustomFieldsTemplate.expand({ executionId });
    return path;
  },

  executionTestResultUpdateCustomFields: (id) => {
    const pathVariables = { executionTestResultId: id };
    const path = executionTestResultUpdateCustomFieldTemplate.expand(pathVariables);
    return path;
  },

  linkExecutionBuild: (executionId, buildId) => {
    const path = executionLinkBuildTemplate.expand({ executionId });
    const queryString = getQueryString({ buildId });
    return path + queryString;
  },

  unlinkExecutionRelease: (projectId, executionId) => {
    const path = executionUnlinkReleaseTemplate.expand({ executionId });
    const queryInfo = { projectId };
    const queryString = getQueryString(queryInfo);
    return path + queryString;
  },

  shareExecutionReport(executionId) {
    const pathVariables = { executionId };
    const path = executionShareReportTemplate.expand(pathVariables);
    return path;
  },

  executionTerminate: (projectId, order) => {
    const path = '/api/v1/executions/terminate';
    const queryInfo = { projectId, order };
    const queryString = getQueryString(queryInfo);
    return path + queryString;
  },

  executionRerun: (executionId) => {
    const path = executionRerunTemplate.expand({ executionId });
    return path;
  },

  executionExport: (executionId, params) => {
    const path = executionExportTemplate.expand({ executionId });
    const queryString = getQueryString(params);
    return path + queryString;
  },

  executionEnqueue: (executionId, params) => {
    const path = executionEnqueueTemplate.expand({ executionId });
    const queryString = getQueryString(params);
    return path + queryString;
  },

  sendExecutionExportMail: (executionId, params) => {
    const path = sendExecutionExportMail.expand({ executionId });
    const queryString = getQueryString(params);
    return path + queryString;
  },

  executionBulkDownload: (projectId, orders, fileType) => {
    const path = '/api/v1/executions/download';
    const queryInfo = { projectId, id: orders, fileType };
    const queryString = getQueryString(queryInfo);
    return path + queryString;
  },

  executionDownloadFile: (executionId) => {
    const path = executionDownloadFileTemplate.expand({ executionId });
    return path;
  },

  executionRequestDownload: (executionRequestId, params) => {
    const path = executionRequestDownloadTemplate.expand({ executionRequestId });
    const queryString = getQueryString(params);
    return path + queryString;
  },

  executionTestSuite: (projectId, executionId, id) => executionTestSuiteTemplate.expand({
    projectId,
    executionId,
    executionTestSuiteId: id
  }),

  executionTestResult: (id) => {
    const pathVariables = { executionTestResultId: id };
    const path = executionTestResultTemplate.expand(pathVariables);
    return path;
  },

  linkExternalIssue: (projectId) => {
    const path = '/api/v1/external-issue';
    const queryString = getQueryString({ projectId });
    return path + queryString;
  },

  getExternalIssuesInfo: (queryInfo) => {
    const path = '/api/v1/integrations/issues';
    const queryString = getQueryString(queryInfo);
    return path + queryString;
  },

  createJiraIssue: (queryInfo) => {
    const path = '/api/v1/integrations/create-jira-issue';
    const queryString = getQueryString(queryInfo);
    return path + queryString;
  },

  executionTestResultLog: (id) => {
    const pathVariables = { testResultLogId: id };
    const path = executionTestResultLogTemplate.expand(pathVariables);
    return path;
  },

  testSuite: (id) => testSuiteTemplate.expand({ testSuiteId: id }),

  editTestSuite: (id) => editTestSuiteTemplate.expand({ testSuiteId: id }),

  runTestCase: (testProjectId) => runTestCase.expand({ id: testProjectId }),

  deleteTestSuite: (id, queryInfo) => {
    const path = deleteTestSuiteTemplate.expand({ testSuiteId: id });
    const queryString = getQueryString(queryInfo);
    return path + queryString;
  },

  deleteTestSuites: (queryInfo) => {
    const queryString = getQueryString(queryInfo);
    return testSuitesTemplate.expand() + queryString;
  },

  testCase: (id) => testCaseTemplate.expand({ testCaseId: id }),

  testCaseTag: (id) => testCaseTagTemplate.expand({ testCaseId: id }),

  updateExecutionTag: (id) => executionTagTemplate.expand({ executionId: id }),

  deleteExecutionTag: (id) => executionTagTemplate.expand({ executionId: id }),

  updateExecutionTestResultTag: (id) => executionTestReultTagTemplate.expand({ executionTestResultId: id }),

  deleteExecutionTestResultTag: (id) => executionTestReultTagTemplate.expand({ executionTestResultId: id }),

  createTag: (queryInfo) => {
    const path = '/api/v1/tags';
    const queryString = getQueryString(queryInfo);
    return path + queryString;
  },

  testCases: (queryInfo) => {
    const path = '/api/v1/test-cases/update';
    const queryString = getQueryString(queryInfo);
    return path + queryString;
  },

  testObject: (id) => testObjectTemplate.expand({ testObjectId: id }),

  testObjectEntity: (id) => testObjectEntityTemplate.expand({ testObjectEntityId: id }),

  usersMe: '/api/v1/users/me',
  userToken: '/api/v1/users/token',
  userTestopsAccessToken: '/api/v1/users/testops-access-token',

  usersAdd: (queryInfo) => {
    const path = '/api/v1/users/add';
    const queryString = getQueryString(queryInfo);
    return path + queryString;
  },

  usersRemove: (queryInfo) => {
    const path = '/api/v1/users/remove';
    const queryString = getQueryString(queryInfo);
    return path + queryString;
  },

  invitations: (queryInfo) => {
    const path = '/api/v1/invitations';
    const queryString = getQueryString(queryInfo);
    return path + queryString;
  },

  acceptInvitation: (queryInfo) => {
    const path = '/api/v1/invitations/accept';
    const queryString = getQueryString(queryInfo);
    return path + queryString;
  },

  declineInvitation: (queryInfo) => {
    const path = '/api/v1/invitations/decline';
    const queryString = getQueryString(queryInfo);
    return path + queryString;
  },

  ssoInvitation: (queryInfo) => {
    const path = '/api/v1/sso-invitation';
    const queryString = getQueryString(queryInfo);
    return path + queryString;
  },

  ssoDeclineInvitation: (queryInfo) => {
    const path = '/api/v1/sso-invitation/decline';
    const queryString = getQueryString(queryInfo);
    return path + queryString;
  },

  organizationFeatures: (queryInfo) => {
    const path = '/api/v1/user-organization-features';
    const queryString = getQueryString(queryInfo);
    return path + queryString;
  },

  userOrganizationSso: (queryInfo) => {
    const path = '/api/v1/user-organization-sso';
    const queryString = getQueryString(queryInfo);
    return path + queryString;
  },

  assignableOrganizationFeatures: (queryInfo) => {
    const path = '/api/v1/user-organization-features/assignable';
    const queryString = getQueryString(queryInfo);
    return path + queryString;
  },

  organization: (organizationId) => {
    const path = organizationTemplate.expand({ organizationId });
    return path;
  },

  organizationWithLicense: (organizationId, feature) => {
    const path = organizationSubscriptionLicenseTemplate.expand({ organizationId });
    const queryString = getQueryString({ feature });
    return path + queryString;
  },

  organizations: (queryInfo) => {
    const path = '/api/v1/organizations';
    const queryString = getQueryString(queryInfo);
    return path + queryString;
  },

  getOrganizationProjects: (organizationId) => organizationProjectsTemplate.expand({ organizationId }),

  getUserOrganization: (userId, organizationId) => {
    const pathVariables = { userId };
    const path = getUserOrganizationTemplate.expand(pathVariables);
    const queryInfo = { organizationId };
    const queryString = getQueryString(queryInfo);
    return path + queryString;
  },

  removeUserOrganization: (queryInfo) => {
    const path = '/api/v1/user-organization/remove';
    const queryString = getQueryString(queryInfo);
    return path + queryString;
  },

  updateUserOrganization: () => {
    const path = '/api/v1/user-organization/update';
    return path;
  },

  teams: (queryInfo) => {
    const path = '/api/v1/teams';
    const queryString = getQueryString(queryInfo);
    return path + queryString;
  },

  licenseKeys: () => {
    const path = '/api/v1/license-keys';
    return path;
  },

  testingLicenseKey: () => {
    const path = '/api/v1/license-keys/test';
    return path;
  },

  cancelLicense: (id) => {
    const path = cancelLicenseTemplate.expand({ licenseKeyId: id });
    return path;
  },

  teamId: (id) => {
    const pathVariables = { teamId: id };
    const path = teamTemplate.expand(pathVariables);
    return path;
  },

  buildInfo: () => {
    const path = '/info';
    return path;
  },

  config: () => {
    const path = '/public/configuration';
    return path;
  },
  
  junitReports: (queryInfo) => {
    const path = '/api/v1/junit/test-reports';
    const queryString = getQueryString(queryInfo);
    return path + queryString;
  },

  accountSettings: (queryInfo) => {
    const path = '/api/v1/katone/account-settings';
    const queryString = getQueryString(queryInfo);
    return path + queryString;
  },

  accountUserRole: (queryInfo) => {
    const path = '/api/v1/katone/account-users';
    const queryString = getQueryString(queryInfo);
    return path + queryString;
  },

  junitReportsV2: (queryInfo) => {
    const path = '/api/v1/junit/test-reports-v2';
    const queryString = getQueryString(queryInfo);
    return path + queryString;
  },

  testNGReports: (queryInfo) => {
    const path = '/api/v1/testng/test-reports';
    const queryString = getQueryString(queryInfo);
    return path + queryString;
  },

  katalonReports: (queryInfo) => {
    const path = '/api/v1/katalon/test-reports';
    const queryString = getQueryString(queryInfo);
    return path + queryString;
  },

  katalonReportsInBatches: (projectId, batch) => {
    const path = '/api/v1/katalon/test-reports/multiple';
    const queryString = getQueryString({ projectId, batch });
    return path + queryString;
  },

  katalonReportsInBatchesV2: (projectId, batch) => {
    const path = '/api/v1/katalon/test-reports/multiple-v2';
    const queryString = getQueryString({ projectId, batch });
    return path + queryString;
  },

  executionRequest: (executionRequestId) => {
    const pathVariables = { executionRequestId };
    const path = executionRequestTemplate.expand(pathVariables);
    return path;
  },

  runningTestRuns: (projectId, order, queryInfo) => {
    const path = '/api/v1/executions/running';
    const queryInfos = { projectId, order, ...queryInfo };
    const queryString = getQueryString(queryInfos);
    return path + queryString;
  },

  apiKeyRequest: (apiKeyId) => {
    const pathVariables = { apiKeyId };
    const path = apiKeyTemplate.expand(pathVariables);
    return path;
  },

  testProject: (testProjectId) => {
    const pathVariables = { testProjectId };
    const path = testProjectTemplate.expand(pathVariables);
    return path;
  },

  defaultTestProject: (queryInfo) => {
    const path = '/api/v1/test-projects/default';
    const queryString = getQueryString(queryInfo);
    return path + queryString;
  },

  getApiKeys: (queryInfo) => {
    const path = Apis.apiKeys;
    const queryString = getQueryString(queryInfo);
    return path + queryString;
  },

  testProjectGit: (testProjectGitId) => {
    const pathVariables = { testProjectGitId };
    const path = testProjectGitTemplate.expand(pathVariables);
    return path;
  },

  gitBranches: (gitRepo) => {
    const pathVariables = { gitRepo };
    const path = gitGetBranches.expand(pathVariables);
    return path;
  },

  gitCreateTestProject: (gitRepo) => {
    const pathVariables = { gitRepo };
    const path = gitCreateTestProject.expand(pathVariables);
    return path;
  },

  gitUpdateTestProject: (gitRepo) => {
    const pathVariables = { gitRepo };
    const path = gitUpdateTestProject.expand(pathVariables);
    return path;
  },

  runConfiguration: (id) => {
    const pathVariables = { runConfigurationId: id };
    const path = runConfigurationTemplate.expand(pathVariables);
    return path;
  },

  createAndUpdateRunConfigurations: (queryInfo) => {
    const path = smartSchedulerCreateAndUpdate.expand();
    const queryString = getQueryString(queryInfo);
    return path + queryString;
  },

  executeRunConfiguration: (runConfigurationId) => {
    const pathVariables = { runConfigurationId };
    const path = executeRunConfigurationTemplate.expand(pathVariables);
    return path;
  },

  createRunConfigurationAndTrigger: (queryInfo) => {
    const path = smartSchedulerCreateTrigger.expand();
    const queryString = getQueryString(queryInfo);
    return path + queryString;
  },

  createRunConfigurationAndRun: (queryInfo) => {
    const path = smartSchedulerRunNow.expand();
    const queryString = getQueryString(queryInfo);
    return path + queryString;
  },

  linkRunConfigurationRelease: (projectId, runConfigurationId, releaseId) => {
    const path = runConfigurationLinkReleaseTemplate.expand({ runConfigurationId });
    const queryInfo = { projectId, releaseId };
    const queryString = getQueryString(queryInfo);
    return path + queryString;
  },

  linkRunConfigurationBuild: (runConfigurationId, buildId) => {
    const path = runConfigurationLinkBuildTemplate.expand({ runConfigurationId });
    const queryString = getQueryString({ buildId });
    return path + queryString;
  },

  unlinkRunConfigurationRelease: (projectId, runConfigurationId) => {
    const path = runConfigurationUnlinkReleaseTemplate.expand({ runConfigurationId });
    const queryInfo = { projectId };
    const queryString = getQueryString(queryInfo);
    return path + queryString;
  },

  sampleRunConfiguration: (queryInfo) => {
    const path = '/api/v1/run-configurations/sample';
    const queryString = getQueryString(queryInfo);
    return path + queryString;
  },

  sampleTestProject: (queryInfo) => {
    const path = '/api/v1/test-projects/sample';
    const queryString = getQueryString(queryInfo);
    return path + queryString;
  },

  createSampleGitTestProject: (queryInfo) => {
    const path = createSampleGitTestProjectTemplate.expand();
    const queryString = getQueryString(queryInfo);
    return path + queryString;
  },

  downloadTestProject: (testProjectId) => {
    const pathVariables = { testProjectId };
    const path = downloadTestProjectTemplate.expand(pathVariables);
    return path;
  },

  updateTestProjectPackage: (testProjectId, queryInfo) => {
    const pathVariables = { testProjectId };
    const path = updateTestProjectPackageTemplate.expand(pathVariables);
    const queryString = getQueryString(queryInfo);
    return path + queryString;
  },

  scheduler: (runConfigurationId, schedulerId) => {
    const pathVariables = { runConfigurationId, schedulerId };
    const path = schedulerTemplate.expand(pathVariables);
    return path;
  },

  uploadTestProject: (queryInfo) => {
    const path = '/api/v1/test-projects/upload-script-repo';
    const queryString = getQueryString(queryInfo);
    return path + queryString;
  },

  changeAvatar: (queryInfo) => {
    const path = '/api/v1/users/avatar';
    const queryString = getQueryString(queryInfo);
    return path + queryString;
  },

  changeOrganizationLogo: (organizationId, queryInfo) => {
    const pathVariables = { organizationId };
    const path = updateOrganizationLogo.expand(pathVariables);
    const queryString = getQueryString(queryInfo);
    return path + queryString;
  },

  organizationTrialRequest: (organizationId, feature) => {
    const pathVariables = { organizationId };
    const path = organizationTrialRequest.expand(pathVariables);
    const queryString = getQueryString({ feature });
    return path + queryString;
  },

  organizationTestExecutionQuota: (organizationId) => {
    const pathVariables = { organizationId };
    const path = organizationTestExecutionQuota.expand(pathVariables);
    return path;
  },

  organizationLicensedUsers: (organizationId, feature) => {
    const pathVariables = { organizationId };
    const path = organizationLicensedUsers.expand(pathVariables);
    const queryString = getQueryString({ feature });
    return path + queryString;
  },

  updateUserAccount: (queryInfo) => {
    const path = '/api/v1/users';
    const queryString = getQueryString(queryInfo);
    return path + queryString;
  },

  downloadKatalonRecorderBackup: (backupId) => downloadKatalonRecorderBackupTemplate.expand({ backupId }),

  jobsSummary: (queryInfo) => {
    const path = '/api/v1/jobs/summary';
    const queryString = getQueryString(queryInfo);
    return path + queryString;
  },

  job: (jobId) => {
    const pathVariables = { jobId };
    const path = jobTemplate.expand(pathVariables);
    return path;
  },

  jobLogs: (jobId) => {
    const pathVariables = { jobId };
    const path = jobLogsTemplate.expand(pathVariables);
    return path;
  },

  reImportExecution: (id) => {
    const path = '/api/v1/executions/reimport';
    const queryInfo = { id };
    const queryString = getQueryString(queryInfo);
    return path + queryString;
  },

  removeMachine: (machineKey, organizationId, feature) => {
    const queryInfo = {
      machineKey,
      package: feature,
      organizationId,
    };
    const path = '/api/v1/machine';
    const queryString = getQueryString(queryInfo);
    return path + queryString;
  },

  comment: (queryInfo) => {
    const path = '/api/v1/comments';
    const queryString = getQueryString(queryInfo);
    return path + queryString;
  },

  agentRequest: (agentId) => {
    const pathVariables = { agentId };
    const path = agentTemplate.expand(pathVariables);
    return path;
  },

  testCloudAgentRequest: (agentId) => testCloudAgentTemplate.expand({ agentId }),

  testCloudLicensesRequest: (organizationId) => testCloudLicensesTemplate.expand({ organizationId }),

  updateAgentThreshold: () => {
    const path = '/api/v1/agent/threshold';
    return path;
  },

  k8sAgents: (queryInfo) => {
    const path = '/api/v1/k8s-agent';
    const queryString = getQueryString(queryInfo);
    return path + queryString;
  },

  k8sAgent: (k8sAgentId, queryInfo) => {
    const pathVariables = { k8sAgentId };
    const path = k8sAgentTemplate.expand(pathVariables);
    const queryString = getQueryString(queryInfo);
    return path + queryString;
  },

  circleCIAgents: (queryInfo) => {
    const path = '/api/v1/circle-ci-agent';
    const queryString = getQueryString(queryInfo);
    return path + queryString;
  },

  circleCIAgent: (circleCIAgentId, queryInfo) => {
    const pathVariables = { circleCIAgentId };
    const path = circleCIAgentTemplate.expand(pathVariables);
    const queryString = getQueryString(queryInfo);
    return path + queryString;
  },

  log: (queryInfo) => {
    const path = '/api/v1/log';
    const queryString = getQueryString(queryInfo);
    return path + queryString;
  },

  integrationProjects: (queryInfo) => {
    const path = '/api/v1/integrations/projects';
    const queryString = getQueryString(queryInfo);
    return path + queryString;
  },

  getJiraProjects: () => {
    const path = '/api/v1/integrations/projects';
    return path;
  },

  getJiraBoards: () => {
    const path = '/api/v1/integrations/boards';
    return path;
  },

  getJiraIssueTypes: () => {
    const path = '/api/v1/integrations/issue-types';
    return path;
  },

  integrationReleases: (queryInfo) => {
    const path = '/api/v1/integrations/releases';
    const queryString = getQueryString(queryInfo);
    return path + queryString;
  },

  jiraReleasesStatus: (queryInfo) => {
    const path = '/api/v1/integrations/jira-releases-status';
    const queryString = getQueryString(queryInfo);
    return path + queryString;
  },

  populateIntegrationReleases: (queryInfo) => {
    const path = '/api/v1/integrations/populate-releases';
    const queryString = getQueryString(queryInfo);
    return path + queryString;
  },

  refreshExternalReleasesStatus: (queryInfo) => {
    const path = refreshJiraReleaseStatus.expand();
    const queryString = getQueryString(queryInfo);
    return path + queryString;
  },

  refreshJiraIssueOfProject: (queryInfo) => {
    const path = refreshJiraIssueOfProject.expand();
    const queryString = getQueryString(queryInfo);
    return path + queryString;
  },

  classifyFailedTestResult: (executionId, projectId) => {
    const path = '/api/v1/analysis/classify/test-results';
    const queryInfo = { executionId, projectId };
    const queryString = getQueryString(queryInfo);
    return path + queryString;
  },

  analysisSettings: (projectId) => {
    const pathVariables = { projectId };
    const path = analysisSettingsTemplate.expand(pathVariables);
    return path;
  },

  getPlugins(storeUrl, query) {
    const path = `${storeUrl}/api/search`;
    const queryString = getQueryString({ query, groupByCategory: false });
    return path + queryString;
  },

  getSubscriptionsInfo(organizationId, allProducts) {
    const pathVariables = { organizationId };
    const path = getSubscriptionsInfoTemplate.expand(pathVariables);
    const queryString = getQueryString({ allProducts });
    return path + queryString;
  },

  getActiveSubscriptionsInfo(organizationId) {
    const pathVariables = { organizationId };
    const path = getActiveSubscriptionsInfoTemplate.expand(pathVariables);
    return path;
  },

  getSubscriptionProductInfo(organizationId, enterpriseProductId) {
    const queryInfo = { organizationId, enterpriseProductId };
    const path = '/api/v1/subscription/product';
    const queryString = getQueryString(queryInfo);
    return path + queryString;
  },


  getSubscriptionUrl(organizationId, subscriptionId, planId) {
    const pathVariables = { subscriptionId };
    const path = getSubscriptionUrlTemplate.expand(pathVariables);
    const queryString = getQueryString({ organizationId, planId });
    return path + queryString;
  },

  paymentMethod(organizationId) {
    const path = `/api/v1/payment-method?organizationId=${organizationId}`;
    return path;
  },

  getInvoices(organizationId, invoiceNumber) {
    const path = '/api/v1/invoice';
    const queryString = getQueryString({ organizationId, invoiceNumber });
    return path + queryString;
  },

  billingInformation(organizationId) {
    const path = `/api/v1/billing-information?organizationId=${organizationId}`;
    return path;
  },

  downloadPlugin: (organizationId, pluginId) => {
    const path = '/api/v1/plugins/download';
    const queryString = getQueryString({ organizationId, pluginId });
    return path + queryString;
  },

  mailConfiguration: (organizationId) => {
    const path = '/api/v1/config/mail-server';
    const queryInfo = { organizationId };
    const queryString = getQueryString(queryInfo);
    return path + queryString;
  },

  testOpsConfiguration: (organizationId) => {
    const path = '/api/v1/config/testops';
    const queryInfo = { organizationId };
    const queryString = getQueryString(queryInfo);
    return path + queryString;
  },

  licenseServerConfiguration: (organizationId) => {
    const path = '/api/v1/config/license-server';
    const queryInfo = { organizationId };
    const queryString = getQueryString(queryInfo);
    return path + queryString;
  },

  mailConfigurationTest: (organizationId) => {
    const path = '/api/v1/config/mail-server/test';
    const queryInfo = { organizationId };
    const queryString = getQueryString(queryInfo);
    return path + queryString;
  },

  userSetting: () => {
    const path = '/api/v1/user-settings';
    return path;
  },

  userConfiguration: () => {
    const path = '/api/v1/user-configuration';
    return path;
  },

  userGitCredentials: () => '/api/v1/users/git-credentials',

  projectSetting: () => '/api/v1/project-settings',

  getProjectSetting: (projectId) => {
    const queryInfo = { projectId };
    return projectSettingTemplate.expand(queryInfo);
  },

  getOrganizationMigration: (organizationId) => {
    const queryInfo = { organizationId };
    return organizationMigration.expand(queryInfo);
  },

  getProjectConfiguration: (projectId, name) => {
    const queryString = getQueryString({ name });
    return Apis.projectConfigurations(projectId) + queryString;
  },

  projectConfigurations: (projectId) => projectConfigurationTemplate.expand({ projectId }),

  resetPassword: (queryInfo) => {
    const path = '/public/reset-password';
    const queryString = getQueryString(queryInfo);
    return path + queryString;
  },

  signUp: () => {
    const path = '/public/sign-up';
    return path;
  },

  track: () => {
    const path = '/api/v1/segment-tracking';
    return path;
  },

  nonSubscribedOrganizations: (feature) => {
    const path = '/api/v1/organizations/non-subscribed';
    const queryInfo = { feature };
    const queryString = getQueryString(queryInfo);
    return path + queryString;
  },

  getUnassignedSubscriptions() {
    const path = '/api/v1/subscription/unassigned';
    return path;
  },

  assignSubscription(subscriptionId, organizationId, feature) {
    const pathVariables = { subscriptionId };
    const path = assignSubscriptionTemplate.expand(pathVariables);
    const queryString = getQueryString({ organizationId, feature });
    return path + queryString;
  },

  createQuote(organizationId) {
    const path = '/api/v1/quotes';
    const queryString = getQueryString({ organizationId });
    return path + queryString;
  },

  getQuote(uuid) {
    const path = getQuoteTemplate.expand({ uuid });
    return path;
  },

  checkoutQuote(cardToken, uuid) {
    const path = checkoutQuoteTemplate.expand({ uuid });
    const queryString = getQueryString({ cardToken });
    return path + queryString;
  },

  getRecentProjects(organizationId) {
    return recentProjects.expand({ organizationId });
  },

  updateRecentProjects(projectId) {
    return recentProject.expand({ projectId });
  },

  whitelistIp(organizationId) {
    const path = whitelistIps.expand({ organizationId });
    return path;
  },

  organizationConfiguration(queryInfo) {
    const path = '/api/v1/organizations/configuration';
    const queryString = getQueryString(queryInfo);
    return path + queryString;
  },

  organizationSetting(queryInfo) {
    const path = '/api/v1/organizations/setting';
    const queryString = getQueryString(queryInfo);
    return path + queryString;
  },

  organizationDomain(organizationId, domain) {
    const path = organizationDomain.expand({ organizationId });
    const queryString = getQueryString({ domain });
    return path + queryString;
  },

  organizationStrictDomain(organizationId, strictDomain) {
    const path = organizationStrictDomain.expand({ organizationId });
    const queryString = getQueryString({ strictDomain });
    return path + queryString;
  },

  resolveCheckpoint(checkpointId, queryInfo) {
    const path = resolveCheckpointTemplate.expand({ checkpointId });
    const queryString = getQueryString(queryInfo);
    return path + queryString;
  },

  resolveMultipleCheckpoints(queryInfo) {
    const path = resolveMultipleCheckpointTemplate.expand();
    const queryString = getQueryString(queryInfo);
    return path + queryString;
  },

  saveExecutionToBaseline(keyesExecutionId) {
    const path = saveExecutionToBaseline.expand({ keyesExecutionId });
    return path;
  },

  reimportKeyesExecution(keyesExecutionId) {
    const path = reimportKeyesExecutionTemplate.expand({ keyesExecutionId });
    return path;
  },

  baselines(params) {
    const path = '/api/v1/baselines';
    const queryString = getQueryString(params);
    return path + queryString;
  },

  baselinesLatestVersion(params) {
    const path = baselinesLatestVersion.expand();
    const queryString = getQueryString(params);
    return path + queryString;
  },

  getSubFolders(params) {
    const path = getSubFolders.expand();
    const queryString = getQueryString(params);
    return path + queryString;
  },

  getSamlIdp(organizationId) {
    const path = getSamlIdpTemplate.expand({ organizationId });
    return path;
  },

  createSampleProject(projectId) {
    const path = createSampleProject.expand({ projectId });
    return path;
  },

  populateSampleProject(projectId) {
    return populateSampleProject.expand({ projectId });
  },

  refreshTSC(testProjectId) {
    const path = refreshTSCTestProjectTemplate.expand({ testProjectId });
    return path;
  },

  timeZoneOptions() {
    return '/api/v1/time-zones';
  },

  subscriptionTestOpsInfo(organizationId) {
    const path = subscriptionTestOpsInfo.expand({ organizationId });
    return path;
  },

  subscriptionTestOpsReviewOrder(queryInfo) {
    const queryString = getQueryString(queryInfo);
    const path = subscriptionTestOpsReviewOrder.expand();
    return path + queryString;
  },

  subscriptionTestOpsCheckout(queryInfo) {
    const queryString = getQueryString(queryInfo);
    const path = subscriptionTestOpsCheckout.expand();
    return path + queryString;
  },

  cancelSubscription(queryInfo) {
    const queryString = getQueryString(queryInfo);
    const path = cancelSubscription.expand();
    return path + queryString;
  },

  katOneOrganizationHomePage(queryInfo) {
    const queryString = getQueryString(queryInfo);
    const path = katOneOrganizationHomeSSOPage.expand();
    return path + queryString;
  },

  katOneDashboardPage(queryInfo) {
    const queryString = getQueryString(queryInfo);
    const path = katOneDashboardSSOPage.expand();
    return path + queryString;
  },

  katOneLicenseUtilizationPage(queryInfo) {
    const queryString = getQueryString(queryInfo);
    const path = katOneLicenseUtilizationSSOPage.expand();
    return path + queryString;
  },

  katOneSupportManagementPage(queryInfo) {
    const queryString = getQueryString(queryInfo);
    const path = katOneSupportManagementPage.expand();
    return path + queryString;
  },

  katOneOrganizationManagementPage(organizationId) {
    const path = katOneOrganizationManagementPage.expand({ organizationId });
    return path;
  },

  katOneUserManagementPage(queryInfo) {
    const queryString = getQueryString(queryInfo);
    const path = katOneUserManagementSSOPage.expand();
    return path + queryString;
  },

  katOneWelcomePage() {
    return katOneWelcomeSSOPage.expand();
  },

  katOneUserManagementPendingPage(queryInfo) {
    const queryString = getQueryString(queryInfo);
    const path = katOneUserManagementPendingSSOPage.expand();
    return path + queryString;
  },

  katOneProfilePage() {
    return katOneProfilePage.expand();
  },

  katOnePaymentMethodPage(queryInfo) {
    const queryString = getQueryString(queryInfo);
    const path = katOnePaymentMethodSSOPage.expand();
    return path + queryString;
  },

  katOneAccountSubscriptionManagementPage(queryInfo) {
    const queryString = getQueryString(queryInfo);
    const path = katOneAccountSubscriptionManagementSSOPage.expand();
    return MConfigs.katOneHomeUrl + path + queryString;
  },

  katOneAccountSettingsPage(queryInfo) {
    const queryString = getQueryString(queryInfo);
    const path = katOneAccountSettingsSSOPage.expand();
    return path + queryString;
  },

  katOneSupportPortalPage() {
    return katOneSupportPortalPage.expand();
  },

  cacheRemove: (key, projectId) => {
    const path = cache.expand();
    const queryInfo = { key, projectId };
    const queryString = getQueryString(queryInfo);
    return path + queryString;
  },

  smartScheduler: (runConfigurationId) => smartSchedulerTemplate.expand({ runConfigurationId }),

  katOneAdminProfile: () => {
    const path = katone_admin_profile.expand();
    return path;
  },

  katOneAdminCreateOrganization: () => {
    const path = katone_admin_create_organization.expand();
    return path;
  },

  katOneAdminCreateProject: (organizationId) => {
    const path = katone_admin_create_project.expand({ organizationId });
    return path;
  },

  katOneAdminOrganizationSettings: (organizationId) => {
    const path = katone_admin_organization_settings.expand({ organizationId });
    return path;
  },

  katOneAdminOrganizationUsers: (organizationId) => {
    const path = katone_admin_organization_users.expand({ organizationId });
    return path;
  },

  katOneAdminPaymentMethod: (organizationId) => katone_admin_payment_method.expand({ organizationId }),

  katOneAdminOrganizationSubscriptions: (organizationId) => {
    const path = katone_admin_organization_subscriptions.expand({ organizationId });
    return path;
  },

  katOneAdminOrganizationSubscriptionsTestops: (organizationId) => {
    const path = katone_admin_organization_subscriptions_testops.expand({ organizationId });
    return path;
  },

  katOneAdminOrganizationLicenseKeys: (organizationId) => {
    const path = katone_admin_organization_license_keys.expand({ organizationId });
    return path;
  },

  katOneSendActivationEmail: '/api/v1/katone/send-activation-email',

  testOpsOrganizationHome: (organizationId, testOpsFeature) => {
    const path = testops_organization_home.expand({ organizationId });
    const queryString = getQueryString({ testOpsFeature });
    return testOpsFeature ? path + queryString : path;
  },

  testOpsProjectHome: (teamId, projectId) => testops_project_home.expand({ teamId, projectId }),

  testOpsProjectSettings: (teamId, projectId) => testops_project_settings.expand({ teamId, projectId }),

  testOpsTeamHome: (teamId) => testops_team_home.expand({ teamId }),

  adminTeamProject: (teamId) => admin_team_project.expand({ teamId }),

  adminTeamUsers: (teamId) => admin_team_users.expand({ teamId }),

  adminTeamSettings: (teamId) => admin_team_settings.expand({ teamId }),

  testOpsOrganizationAllProjects: (organizationId) => testops_organization_all_projects.expand({ organizationId }),

  testOpsOrganizationAllTeams: (organizationId) => testops_organization_all_teams.expand({ organizationId }),

  adminOrganizationAllProjects: (organizationId) => admin_organization_all_projects.expand({ organizationId }),

  adminOrganizationAllTeams: (organizationId) => admin_organization_all_teams.expand({ organizationId }),

  testOpsUserSettings: () => {
    const path = testops_user_settings.expand();
    return path;
  },

  markNotificationAsRead: (notificationId) => {
    const pathVariables = { notificationId };
    const path = markNotificationAsRead.expand(pathVariables);
    return path;
  },

  markAllNotificationsAsRead: () => markAllNotificationsAsRead.expand(),

  getNumberOfNotifications: () => getNumberOfNotifications.expand(),

  deleteTunnel: (id) => {
    const pathVariables = { id };
    return deleteTunnel.expand(pathVariables);
  },

  demoSite: () => {
    const path = demoSite.expand();
    return path;
  },

  saveIgnoringZones: (id) => saveIgnoringZones.expand({ id }),

  saveDraftIgnoredZone(baselineCollectionId, baselineId) {
    const path = saveDraftIgnoredZones.expand({ id: baselineCollectionId });
    const queryString = getQueryString({ baselineId });
    return path + queryString;
  },

  baselineCollectionGroup: (id) => baselineCollectionGroup.expand({ id }),

  visualTestingQuota: (organizationId) => visualTestingQuota.expand({ organizationId }),

  downloadKatalonStudioLink: (path, platform) => {
    const queryString = getQueryString({ platform });
    return path + queryString;
  },

  downloadKS: (platform, type) => {
    const path = '/api/v1/katalon/download';
    const queryString = getQueryString({ platform, type });
    return path + queryString;
  },

  getSystemLabel(params) {
    const path = getSystemLabel.expand();
    const queryString = getQueryString(params);
    return path + queryString;
  },

  updateLabelExecutionTestResult: (executionTestResultId) => updateLabelExecutionTestResultTemplate.expand({ executionTestResultId }),

  checkRequiredGitRepository: (projectId) => checkRequiredGitRepository.expand({ projectId }),

  skipTestOpsSurvey: () => skipTestOpsSurvey.expand(),

  submitTestOpsSurvey: () => submitTestOpsSurvey.expand(),

  detectKsEdition: (organizationId) => detectKsEdition.expand({ organizationId }),

  createCustomFieldDefinition: () => createCustomFieldDefinition.expand(),

  customFieldDefinition: () => customFieldDefinition.expand(),

  saveDraftBaselineCollection: () => saveDraftBaselineCollection.expand(),

  previewCheckpointPixel: (id) => previewCheckpointPixel.expand({ id }),

  uploadBaselineImages: () => uploadBaselineImages.expand(),

  createBaselineCollection: () => createBaselineCollection.expand(),

  jiraWebhook: (projectId) => {
    const pathVariables = { projectId };
    const path = jiraWebhook.expand(pathVariables);
    return path;
  },

  jiraWebhookRotating: (projectId) => {
    const pathVariables = { projectId };
    const path = jiraWebhookRotating.expand(pathVariables);
    return path;
  },

  getTestCase: (testCaseId) => testManagementTestCaseTemplate.expand({ id: testCaseId }),

  publishTestProject: (testProjectId) => publishTestProjectTemplate.expand({ id: testProjectId }),

  publishProject: (projectId) => publishProjectTemplate.expand({ id: projectId }),

  getProjectTrackingInformation: (projectId) => projectTrackingInformationTemplate.expand({ projectId }),

  createOrUpdateFeatureSetting: (projectId, featureName) => projectFeatureTemplate.expand({ projectId, featureName }),

  deleteFeatureSetting: (projectId, featureName) => projectFeatureTemplate.expand({ projectId, featureName }),

  hasWritePermission: (testProjectId) => testManagementHasWritePermissionTemplate.expand({ id: testProjectId }),

  deleteAutomationRule: (automationRuleId) => deleteAutomationRuleTemplate.expand({ id: automationRuleId }),
  /**
   * @deprecated Use getAllSubFoldersAndItsTestEntities() instead
   */
  getAllSubFoldersAndItsTestCases(params) {
    const path = getAllSubFoldersAndItsTestCasesTemplate.expand();
    const queryString = getQueryString(params);
    return path + queryString;
  },

  getAllSubFoldersAndItsTestEntities(params) {
    const path = getAllSubFoldersAndItsTestEntitiesTemplate.expand();
    const queryString = getQueryString(params);
    return path + queryString;
  },

  getOnboardingSteps(params) {
    const path = onboardingStepsTemplate.expand();
    const queryString = getQueryString(params);
    return path + queryString;
  },

  getAIStatistics(params) {
    const path = aiStatistics.expand();
    const queryString = getQueryString(params);
    return path + queryString;
  },

  getAICompletions(params) {
    const path = aiCompletions.expand();
    const queryString = getQueryString(params);
    return path + queryString;
  },

  getMetricSummaryInfo(params) {
    const path = metricSummaryInfo.expand();
    const queryString = getQueryString(params);
    return path + queryString;
  },

  getIncomingActiveReleases: (projectId) => {
    const path = latestIncomingActiveReleases.expand();
    const queryString = getQueryString({ projectId });
    return path + queryString;
  },

  getAutoCompleteResponse: (params) => {
    const path = getAutoCompleteResponse.expand();
    const queryString = getQueryString(params);
    return path + queryString;
  },

  getAllJiraIssueTypesMetadata: (params) => {
    const path = getAllJiraIssueTypesMetadata.expand();
    const queryString = getQueryString(params);
    return path + queryString;
  },

  getJiraIssueTypeMetadataByName: (params) => {
    const path = getJiraIssueTypeMetadataByName.expand();
    const queryString = getQueryString(params);
    return path + queryString;
  },

  getMatchTestResultsByAutomationRule: (params) => {
    const path = getMatchTestResultsByAutomationRule.expand();
    const queryString = getQueryString(params);
    return path + queryString;
  },

  createAutomationRule: () => {
    const path = createAutomationRule.expand();
    return path;
  },

  validateAutomationRule: () => {
    const path = validateAutomationRule.expand();
    return path;
  },

  trackOnboardingStep: () => onboardingStepsTemplate.expand(),

  updateAgent: (agentId) => agentTemplate.expand({ agentId }),

  getJiraCreateUrl: (params) => {
    const path = jiraCreateUrl.expand();
    const queryString = getQueryString(params);
    return path + queryString;
  },

  getSuggestedDefects: (params) => {
    const path = suggestedDefectsTempleate.expand();
    const queryString = getQueryString(params);
    return path + queryString;
  },

  getEmailDailyConfigByUser: () => getEmailDailyConfigByUser.expand(),

  getEmailDailyConfigByProject: (params) => {
    const path = getEmailDailyConfigByProjectTemplate.expand();
    const queryString = getQueryString(params);
    return path + queryString;
  },

  subscribeUsersToDailyEmail: () => subscribeUsersToDailyEmailTemplate.expand(),

  unSubscribeUsersToDailyEmail: () => unsubscribeUsersToDailyEmailTemplate.expand(),
};

export default Apis;
