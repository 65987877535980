import { Button, ButtonToolbar } from 'reactstrap';
import {
  DialogActions,
  DialogContent,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { cloneDeep, isEmpty } from 'lodash';
import Box from '@mui/material/Box';
import { t } from '../../i18n/t';
import TabsDialogs from './TabsDialog';
import GroupEvent from '../../utils/track/GroupEvent';
import TestCloudEnvironment from './TestCloudEnvironment';
import ExpandWithRadioButton from './ExpandWithRadioButton';
import {
  BROWSER_TYPE_OPTIONS,
  CLOUD_TYPE_TEST_ENVIRONMENT_PROPERTY_MAPPING,
  CloudType, ConfigType, RUN_TYPE
} from '../../utils/Constants';
import LocalEnvironment from '../smarttestscheduling/components/LocalEnvironment';
import CircleCIEnvironment from '../smarttestscheduling/components/CircleCIEnvironment';
import KubernetesEnvironment from '../smarttestscheduling/components/KubernetesEnvironment';
import MFlags from '../../models/MFlags';

function SelectTestEnvironmentDialog({ isOpen, executionEnvironments, onOk, onClose, apps, agents, localAgents, circleCIAgents, k8sAgents, configType, title }) {
  const [enabledTestCloudTunnel, setEnabledTestCloudTunnel] = useState(false);
  const [enableApiTesting, setEnableApiTesting] = useState(false);
  const [tunnelId, setTunnelId] = useState(null);
  const [selectedLocalAgents, setSelectedLocalAgents] = useState([]);
  const [selectedK8sAgents, setSelectedK8sAgents] = useState([]);
  const [selectedCircleCIAgents, setSelectedCircleCIAgents] = useState([]);
  const [selectedTestCloudAgents, setSelectedTestCloudAgents] = useState([]);

  const [expandedTestCloudEnv, setExpandedTestCloudEnv] = useState(true);
  const [expandedLocalEnv, setExpandedLocalEnv] = useState(MFlags.testCloudDisableTCFromScheduleDialog || configType === ConfigType.GENERIC_COMMAND);
  const [expandedKubernetesEnv, setExpandedKubernetesEnv] = useState(false);
  const [expandedCircleCIEnv, setExpandedCircleCIEnv] = useState(false);
  const [selectedTestEnv, setSelectedTestEnv] = useState(MFlags.testCloudDisableTCFromScheduleDialog || configType === ConfigType.GENERIC_COMMAND ? CloudType.LOCAL_AGENT : CloudType.TEST_CLOUD_AGENT);
  const [selectedExecutionEnvironments, setSelectedExecutionEnvironments] = useState([]);
  const [selectedTestEnvironmentMap, setSelectedTestEnvironmentMap] = useState(CLOUD_TYPE_TEST_ENVIRONMENT_PROPERTY_MAPPING);
  const [requireTestEnv, setRequireTestEnv] = useState(false);
  const [isDisabledButton, setIsDisabledButton] = useState(true);

  const initExpandedEnvironment = (cloudType) => {
    setExpandedTestCloudEnv(cloudType === CloudType.TEST_CLOUD_AGENT);
    setExpandedLocalEnv(cloudType === CloudType.LOCAL_AGENT);
    setExpandedCircleCIEnv(cloudType === CloudType.CIRCLE_CI_AGENT);
    setExpandedKubernetesEnv(cloudType === CloudType.K8S_AGENT);
  };

  const handleChangeTestEnv = (value) => {
    setSelectedTestEnv(value);
    setExpandedTestCloudEnv(value === CloudType.TEST_CLOUD_AGENT && !expandedTestCloudEnv);
    setExpandedLocalEnv(value === CloudType.LOCAL_AGENT && !expandedLocalEnv);
    setExpandedCircleCIEnv(value === CloudType.CIRCLE_CI_AGENT && !expandedCircleCIEnv);
    setExpandedKubernetesEnv(value === CloudType.K8S_AGENT && !expandedKubernetesEnv);
  };

  const handleChangeExecutionEnvironments = (testExecutionEnvironments) => {
    setSelectedExecutionEnvironments(testExecutionEnvironments);
  };

  const transformExecutionEnvironments = (executionEnvironments) => {
    const result = executionEnvironments.map((item) => {
      const {
        os,
        osVersion,
        deviceName,
        deviceType,
        browser,
        browserVersion,
        deviceId,
        headless,
        appId,
        appName,
        appVersion,
        appVersionCode,
        cloudType,
        browserType,
        browserVersionType,
        runType,
        devicePoolId,
        udid,
        devicePoolType,
        deviceUniqueName,
      } = item;
      return {
        os,
        osVersion,
        deviceName,
        deviceType,
        browser,
        browserVersion,
        deviceId,
        headless,
        appId,
        appName,
        appVersion,
        appVersionCode,
        cloudType,
        enabledTestCloudTunnel,
        enableApiTesting,
        tunnelId,
        browserType,
        browserVersionType,
        runType,
        devicePoolId,
        udid,
        devicePoolType,
        deviceUniqueName,
      };
    });
    if (isEmpty(result)) {
      // This case happens when users select tunnel without selecting test execution environments
      return [{ enabledTestCloudTunnel, enableApiTesting, tunnelId, isNotTestEnv: true }];
    }
    return result;
  };

  const transformLocalAgents = (localAgents) => localAgents.map((localAgent) => ({ ...localAgent, cloudType: CloudType.LOCAL_AGENT }));

  const transformK8sAgents = (k8sAgents) => k8sAgents.map((k8sAgent) => ({ ...k8sAgent, cloudType: CloudType.K8S_AGENT }));

  const transformCircleCIAgents = (selectedCircleCIAgents) => selectedCircleCIAgents.map((selectedCircleCIAgent) => ({ ...selectedCircleCIAgent, cloudType: CloudType.CIRCLE_CI_AGENT }));

  const getTestEnvironments = (cloudType) => {
    switch (cloudType) {
      case CloudType.TEST_CLOUD_AGENT:
        return transformExecutionEnvironments(selectedExecutionEnvironments);
      case CloudType.LOCAL_AGENT:
        return transformLocalAgents(selectedLocalAgents);
      case CloudType.CIRCLE_CI_AGENT:
        return transformCircleCIAgents(selectedCircleCIAgents);
      case CloudType.K8S_AGENT:
        return transformK8sAgents(selectedK8sAgents);
      default:
        return [];
    }
  };

  const handleClickSaveBtn = () => {
    const validateSelectedTestEnv = getTestEnvironments(selectedTestEnv).some((el) => el.runType === RUN_TYPE.MOBILE_NATIVE_APP && !el.appId);
    setRequireTestEnv(validateSelectedTestEnv);
    if (!validateSelectedTestEnv) {
      onClose();
      onOk(getTestEnvironments(selectedTestEnv));
    }
  };

  useEffect(() => {
    const env = getTestEnvironments(selectedTestEnv);
    setIsDisabledButton(isEmpty(env) || env[0]?.isNotTestEnv);
  }, [selectedTestEnv, selectedExecutionEnvironments, selectedLocalAgents, selectedK8sAgents, selectedCircleCIAgents]); // eslint-disable-line react-hooks/exhaustive-deps

  // set trackid and groupid for icon close in schedule dialog
  const trackCloseIcon = {
    'data-trackid': 'close-schedule-dialog',
    'data-groupid': GroupEvent.SCHEDULE_TEST_RUN,
  };

  const handleExpandCircleCIClick = () => {
    setExpandedCircleCIEnv(!expandedCircleCIEnv);
    if (!expandedCircleCIEnv) {
      initExpandedEnvironment(CloudType.CIRCLE_CI_AGENT);
    }
  };

  const handleExpandTestCloudClick = () => {
    setExpandedTestCloudEnv(!expandedTestCloudEnv);
    if (!expandedTestCloudEnv) {
      initExpandedEnvironment(CloudType.TEST_CLOUD_AGENT);
    }
  };

  const handleExpandLocalClick = () => {
    setExpandedLocalEnv(!expandedLocalEnv);
    if (!expandedLocalEnv) {
      initExpandedEnvironment(CloudType.LOCAL_AGENT);
    }
  };

  const handleExpandKubernetesClick = () => {
    setExpandedKubernetesEnv(!expandedKubernetesEnv);
    if (!expandedKubernetesEnv) {
      initExpandedEnvironment(CloudType.K8S_AGENT);
    }
  };

  const toggleTestCloudTunnelIntegration = (event) => {
    const { checked } = event.target;
    setEnabledTestCloudTunnel(checked);
  };

  const updateEnableApiTesting = (event) => {
    const { checked } = event.target;
    setEnableApiTesting(checked);
  };

  const handleSelectedTunnelChange = (id) => {
    setTunnelId(id);
  };

  const initTestEnvironmentMap = (cloudType) => {
    selectedTestEnvironmentMap[cloudType] = agents;
    setSelectedTestEnvironmentMap(cloneDeep(selectedTestEnvironmentMap));
  };

  useEffect(() => {
    if (!isEmpty(agents)) {
      const cloudType = agents[0]?.cloudType;
      initExpandedEnvironment(cloudType);
      setSelectedTestEnv(cloudType);
      initTestEnvironmentMap(cloudType);
    }
  }, [agents]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChangeLocalEnvironment = (localAgents) => {
    setSelectedLocalAgents(localAgents);
  };

  const handleChangeK8sEnvironment = (k8sAgent) => {
    setSelectedK8sAgents(k8sAgent);
  };

  const handleChangeCircleCIAgents = (circleCIAgents) => {
    setSelectedCircleCIAgents(circleCIAgents);
  };

  useEffect(() => {
    if (agents && agents.length > 0) {
      const cloudType = agents[0].cloudType;
      setSelectedTestEnv(cloudType);

      initExpandedEnvironment(cloudType);

      // TestCloud agents
      const testCloudAgents = agents.filter((agent) => agent.cloudType === CloudType.TEST_CLOUD_AGENT);
      setSelectedTestCloudAgents(testCloudAgents);
      setSelectedExecutionEnvironments(testCloudAgents);
      if (testCloudAgents.length > 0) {
        setEnabledTestCloudTunnel(testCloudAgents[0].enabledTestCloudTunnel);
        setEnableApiTesting(testCloudAgents[0].enableApiTesting);
        setTunnelId(testCloudAgents[0].tunnelId);
      }

      // Local agents
      const localAgents = agents.filter((agent) => agent.cloudType === CloudType.LOCAL_AGENT);
      setSelectedLocalAgents([...localAgents]);

      // K8s
      const k8sAgents = agents.filter((agent) => agent.cloudType === CloudType.K8S_AGENT);
      setSelectedK8sAgents([...k8sAgents]);

      // Circle CI
      const circleCIAgents = agents.filter((agent) => agent.cloudType === CloudType.CIRCLE_CI_AGENT);
      setSelectedCircleCIAgents([...circleCIAgents]);
    } else {
      setSelectedTestCloudAgents([]);
      setSelectedLocalAgents([]);
      setSelectedK8sAgents([]);
      setSelectedCircleCIAgents([]);
    }
  }, [agents]);

  const handleClose = () => {
    const testCloudAgents = agents.filter((agent) => agent.cloudType === CloudType.TEST_CLOUD_AGENT);
    if (testCloudAgents.length > 0) {
      setEnabledTestCloudTunnel(testCloudAgents[0].enabledTestCloudTunnel);
      setEnableApiTesting(testCloudAgents[0].enableApiTesting);
      setTunnelId(testCloudAgents[0].tunnelId);
    } else {
      setEnabledTestCloudTunnel(false);
      setEnableApiTesting(false);
    }
    onClose();
  };

  return (
    <TabsDialogs
      id="test-environment-dialog"
      isOpen={isOpen}
      handleClose={handleClose}
      maxWidth="md"
      dialogName={title}
      headerStyle="schedule-header"
      disablePortal
      trackCloseIcon={trackCloseIcon}
      showCloseButton={false}
    >
      <DialogContent className="schedule-content test-environment-dialog-content">
        {configType !== ConfigType.GENERIC_COMMAND && !MFlags.testCloudDisableTCFromScheduleDialog &&
        <ExpandWithRadioButton
          expand={expandedTestCloudEnv}
          handleChange={handleChangeTestEnv}
          handleExpand={handleExpandTestCloudClick}
          checked={selectedTestEnv === CloudType.TEST_CLOUD_AGENT}
          value={CloudType.TEST_CLOUD_AGENT}
          title={t('testcloud-environment#title')}
        />}
        <Box sx={{ display: (expandedTestCloudEnv && configType !== ConfigType.GENERIC_COMMAND && !MFlags.testCloudDisableTCFromScheduleDialog ? 'block' : 'none') }}>
          <TestCloudEnvironment
            tunnelId={tunnelId}
            handleSelectedTunnelChange={handleSelectedTunnelChange}
            enableApiTesting={enableApiTesting}
            updateEnableApiTesting={updateEnableApiTesting}
            testCloudEnv={executionEnvironments}
            toggleTestCloudTunnelIntegration={toggleTestCloudTunnelIntegration}
            enabledTestCloudTunnel={enabledTestCloudTunnel}
            apps={apps}
            handleChangeExecutionEnvironments={handleChangeExecutionEnvironments}
            agents={selectedTestCloudAgents}
            configType={configType}
            hideProfileWithTunnel={configType === ConfigType.GENERIC_COMMAND}
            hideTabPanel={configType === ConfigType.COMMAND}
            requireTestEnv={requireTestEnv}
          />
        </Box>
        <ExpandWithRadioButton
          expand={expandedLocalEnv}
          handleChange={handleChangeTestEnv}
          handleExpand={handleExpandLocalClick}
          checked={selectedTestEnv === CloudType.LOCAL_AGENT}
          value={CloudType.LOCAL_AGENT}
          title="Local"
        />
        <Box sx={{ display: (expandedLocalEnv ? 'block' : 'none') }}>
          <LocalEnvironment
            value={selectedLocalAgents}
            localAgents={localAgents}
            browserTypeOptions={BROWSER_TYPE_OPTIONS}
            onChange={handleChangeLocalEnvironment}
            hideBrowserTypeSelect={configType === ConfigType.COMMAND || configType === ConfigType.GENERIC_COMMAND}
          />
        </Box>
        {!MFlags.testCloudDisableTCFromScheduleDialog &&
        <ExpandWithRadioButton
          expand={expandedKubernetesEnv}
          handleChange={handleChangeTestEnv}
          handleExpand={handleExpandKubernetesClick}
          checked={selectedTestEnv === CloudType.K8S_AGENT}
          value={CloudType.K8S_AGENT}
          title="Kubernetes"
        />}
        <Box sx={{ display: (expandedKubernetesEnv && !MFlags.testCloudDisableTCFromScheduleDialog ? 'block' : 'none') }}>
          <KubernetesEnvironment
            value={selectedK8sAgents}
            k8sAgents={k8sAgents}
            browserTypeOptions={BROWSER_TYPE_OPTIONS}
            onChange={handleChangeK8sEnvironment}
            hideBrowserTypeSelect={configType === ConfigType.COMMAND || configType === ConfigType.GENERIC_COMMAND}
          />
        </Box>
        {!MFlags.testCloudDisableTCFromScheduleDialog &&
        <ExpandWithRadioButton
          expand={expandedCircleCIEnv}
          handleChange={handleChangeTestEnv}
          handleExpand={handleExpandCircleCIClick}
          checked={selectedTestEnv === CloudType.CIRCLE_CI_AGENT}
          value={CloudType.CIRCLE_CI_AGENT}
          title="CircleCI"
        />}
        <Box sx={{ display: (expandedCircleCIEnv && !MFlags.testCloudDisableTCFromScheduleDialog ? 'block' : 'none') }}>
          <CircleCIEnvironment
            value={selectedCircleCIAgents}
            circleCIAgents={circleCIAgents}
            browserTypeOptions={BROWSER_TYPE_OPTIONS}
            onChange={handleChangeCircleCIAgents}
            hideBrowserTypeSelect={configType === ConfigType.COMMAND || configType === ConfigType.GENERIC_COMMAND}
          />
        </Box>
      </DialogContent>
      <DialogActions className="d-flex footer-content">
        <ButtonToolbar>
          <Button
            id="close-configure-ts-dialog"
            title={t('cancel')}
            color="secondary"
            onClick={handleClose}
          >
            {t('cancel')}
          </Button>
          <Button
            id="save-configure-ts"
            title="Save"
            disabled={isDisabledButton}
            color="primary"
            type="submit"
            onClick={() => handleClickSaveBtn()}
          >
            Save
          </Button>
        </ButtonToolbar>
      </DialogActions>
    </TabsDialogs>
  );
}

export default SelectTestEnvironmentDialog;

