import React from 'react';
import DoughnutChartWithLabel from '../../../components/chart/DoughnutChartWithLabel';
import { RCA_SYSTEM_CATEGORY } from '../../../utils/Constants';
import styles from '../../../../scss/colors.scss';
import { t } from '../../../i18n/t';

const FailedTestResultByCategoryChart = ({ data }) => {

  let chartData = [];
  const legendPayload = [];

  let totalTestResults = 0;

  const getLabelColor = (label) => {
    switch (label) {
      case RCA_SYSTEM_CATEGORY.APLICATION_BUG:
        return styles.rcaCategoryApplicationBug;
      case RCA_SYSTEM_CATEGORY.AUTOMATION_BUG:
        return styles.rcaCategoryAutomationBug;
      case RCA_SYSTEM_CATEGORY.NETWORK_THIRD_PARTIES:
        return styles.rcaCategoryNetworkThirdParties;
      case RCA_SYSTEM_CATEGORY.UNCATEGORIZED:
        return styles.rcaCategoryUncategorized;
      default: return 'red';
    }
  };

  // convert to: [{ name: 'network 1', value: 2, color: 'red' }, { name: 'network 3', value: 4, color: 'blue' }];
  const convertData = () => {
    if (!data) {
      return;
    }
    // row.content, Label_name, TestResults for All Result, row, label, total for Latest Result
    chartData = data.map((row) => row.content ?? row).map((item) => ({
      name: item.Label_name ?? item.label,
      value: item.TestResults ?? item.total,
      color: getLabelColor(item.Label_name ?? item.label)
    }));
  };

  const buildLegendData = () => {
    chartData.forEach(((item) => {
      legendPayload.push({
        dataKey: item.name,
        value: item.name,
        color: item.color,
        type: 'square'
      });
    }));
  };

  const renderChart = () => {
    const width = '100%';
    const height = 450;
    const description = {
      title: t('test_results'),
      content: totalTestResults
    };
    return (<DoughnutChartWithLabel data={chartData} description={description} legend={legendPayload} width={width} height={height} />);
  };

  convertData();

  totalTestResults = chartData.map((item) => item.value).reduce((prev, current) => prev + current, 0);

  buildLegendData();

  return (
    <>
      {renderChart()}
    </>);
};

export default FailedTestResultByCategoryChart;
