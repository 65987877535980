import React from 'react';
import { withTabComponent } from '../../components/HigherOrderComponent';
import { buildSearchCondition } from '../../components/search/SearchUtils';
import ExecutionTestResultDataTable from '../../components/table/ExecutionTestResultDataTable';

const TestRunsTab = ({ executionTestSuite }) => {
  const { execution, testSuite, platform } = executionTestSuite;
  return (
    <ExecutionTestResultDataTable
      defaultSearchConditions={[
        buildSearchCondition('Execution.id', '=', execution.id),
        buildSearchCondition('TestSuite.id', '=', testSuite.id),
        buildSearchCondition('Platform.id', '=', platform.id),
      ]}
    />
  );
};

export default withTabComponent(TestRunsTab, 'page-execution-test-suite-details-test-run', document.title);
