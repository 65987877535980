import React from 'react';
import { DatePicker as MuiDateDicker, DateTimePicker as MuiDateTimePicker, LocalizationProvider } from '@mui/lab';
import DateAdapter from '@mui/lab/AdapterMoment';
import { IconCalendar } from '../images/CustomIcon';
import TextField from './TextField';

export default function DatePicker(props) {
  const { id, name, required, useDateTime, className, dataTestId } = props;

  const overrideComponents = {
    OpenPickerIcon: IconCalendar,
  };

  const overrideInput = (params) => (
    <TextField
      data-testid={dataTestId}
      id={id}
      className={className}
      name={name}
      size="small"
      required={required}
      fullWidth
      {...params}
    />
  );

  const newProps = {
    ...props,
    inputProps: { disableUnderline: true },
    components: overrideComponents,
    renderInput: overrideInput
  };

  if (useDateTime) {
    return (
      <LocalizationProvider dateAdapter={DateAdapter}>
        <MuiDateTimePicker
          {...newProps}
        />
      </LocalizationProvider>
    );
  }

  return (
    <LocalizationProvider dateAdapter={DateAdapter}>
      <MuiDateDicker
        {...newProps}
      />
    </LocalizationProvider>
  );
}
