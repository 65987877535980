import React from 'react';
import PageComponent from '../../components/PageComponent';
import { t } from '../../i18n/t';
import WayToStart from './components/WayToStart';

class OnboardingWayToStartPage extends PageComponent {

  constructor(props) {
    super(props);
    this.meta.id = 'page-first-project-setup';
    this.meta.title = t('first-project-setup');
  }

  render() {
    return (
      <WayToStart />
    );
  }
}

export default OnboardingWayToStartPage;
