import { Typography } from '@mui/material';
import { get } from 'lodash';
import React from 'react';
import { Button } from 'reactstrap';
import DataTable from '../../../components/table/DataTable';
import MTableColumnDataMapping from '../../../components/table/models/MTableColumnDataMapping';
import { t } from '../../../i18n/t';
import { SearchEntity } from '../../../utils/Constants';
import { next } from '../../../utils/Count';
import DecoratorConstants from '../../../utils/DecoratorConstants';
import Routes from '../../../utils/Routes';
import GroupEvent from '../../../utils/track/GroupEvent';
import MFlags from '../../../models/MFlags';

function TestResultDataTable({ defaultSearchConditions, customFieldConditionsProp, openExceptionDialog }) {

  const handleOpenExceptionDialog = (row) => {
    const errorDetail = get(row, 'errorDetail');
    const errorDetailsId = get(row, 'errorDetailsId');
    openExceptionDialog(errorDetail, errorDetailsId);
  };

  const testResultDecorator = (name, row) => {
    const testCaseName = get(row, 'testCase.name');
    const path = get(row, 'testCase.path');
    return (
      <div>
        <Typography variant="h6">
          {testCaseName}
        </Typography>
        <span className="text-secondary">
          {DecoratorConstants.timeAndDurationDecorator(name, row)}
        </span>
        <span className="text-secondary">
          {path}
        </span>
        <div className="mt-1">
          <Button
            onClick={() => handleOpenExceptionDialog(row)}
            className="p-0"
            color="link"
            data-trackid="view-error-failed-test-result-dashboard"
            data-groupid={GroupEvent.ACCESS_REPORT}
          >
            {t('view-error')}
          </Button>
        </div>
      </div>
    );
  };

  const testResultIdDecorator = (name, row) => {
    const constructedLink = new Routes({
      executionId: get(row, 'execution.order'),
      executionTestResultId: row.urlId,
    });
    return DecoratorConstants.idDecorator(name, row, constructedLink.execution_test_result_detail_link,
      null, null);
  };

  const columnMapping = [
    new MTableColumnDataMapping(t('id'), 'id', testResultIdDecorator),
    new MTableColumnDataMapping(t('test_results'), '', testResultDecorator),
  ];

  return (
    <DataTable
      key={next()}
      useSortByColumn
      entityType={MFlags.revampFailedTestResultEnabled ? SearchEntity.ExecutionTestResultException : SearchEntity.ExecutionTestResult}
      defaultSearchConditions={defaultSearchConditions}
      customFieldConditionsProp={customFieldConditionsProp}
      columnMapping={columnMapping}
    />
  );
}

export default TestResultDataTable;
