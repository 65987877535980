import React from 'react';
import { DialogContent, DialogContentText, DialogActions } from '@mui/material';
import { Button, ButtonToolbar } from 'reactstrap';
import PropTypes from 'prop-types';
import { t } from '../../../i18n/t';
import CloseableDialogComponent from '../../../components/dialog/CloseableDialogComponent';

function DeleteJiraWebhookDialog({ isOpen, handleClose, handleDelete, jiraWebhook }) {

  const titleDialog = `${t('jira-webhook#delete-webhook#title')} ${jiraWebhook?.name}`;

  return (
    <CloseableDialogComponent
      id="delete-jira-webhook-dialog"
      isOpen={isOpen}
      handleClose={handleClose}
      maxWidth="sm"
      title={titleDialog}
    >
      <DialogContent>
        <DialogContentText className="text-dark">
          <p>{t('jira-webhook#delete-dialog#description')}</p>
        </DialogContentText>
      </DialogContent>
      <DialogActions className="popup-footer justify-content-end p-0">
        <ButtonToolbar>
          <Button
            id="close-delete-dialog-btn"
            title={t('cancel')}
            color="secondary"
            onClick={handleClose}
          >
            {t('cancel')}
          </Button>
          <Button
            id="delete-jira-webhook-btn"
            title={t('delete')}
            color="danger"
            onClick={handleDelete}
          >
            {t('delete')}
          </Button>
        </ButtonToolbar>
      </DialogActions>
    </CloseableDialogComponent>
  );
}

DeleteJiraWebhookDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  jiraWebhook: PropTypes.object.isRequired,
};

export default DeleteJiraWebhookDialog;
