
import { ListItemIcon, Typography } from '@mui/material';
import React from 'react';
import { t } from '../../../i18n/t';
import DecoratorConstants from '../../../utils/DecoratorConstants';
import { buildSearchCondition } from '../../search/SearchUtils';
import SearchableFilter from './SearchableFilter';

const TestProjectFilter = (props) => {
  const buildSearchConditions = (searchInput) => ([
    buildSearchCondition('name', 'contains', searchInput || '')
  ]);

  const renderOption = (option) => (
    <>
      <ListItemIcon>
        {DecoratorConstants.codeRepoType('type', option)}
      </ListItemIcon>
      <Typography noWrap>{option.name}</Typography>
    </>
  );
  return (
    <SearchableFilter
      entityType="TestProject"
      label={t('test-project')}
      searchKey="name"
      buildSearchConditions={buildSearchConditions}
      renderOption={renderOption}
      {...props}
    />
  );
};

export default TestProjectFilter;
