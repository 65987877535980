import React from 'react';
import Chip from '@katalon-studio/katalon-ui/Chip';
import { styled } from '@mui/material/styles';
import { theme } from '@katalon-studio/katalon-ui/Theme';
import colors from '../../scss/colors.scss';

export function LabelChip({ name }) {
  return <CustomLabelChip label={name} />;
}

const CustomLabelChip = styled(Chip)(() => ({
  height: 12,
  padding: theme.spacing(2, 2),
  borderRadius: 4,
  backgroundColor: colors.ignoringZonesColor,
  fontSize: 12,
  fontWeight: 'normal',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 1.43,
  letterSpacing: 'normal',
  textAlign: 'left',
  color: colors.indigo,

  '& .MuiChip-label': {
    padding: 0,
    marginRight: `${theme.spacing(0)} !important`,
  }
}));

