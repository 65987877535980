/* eslint max-len: 0 */

import React from 'react';
import { matchPath, Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { Container } from 'reactstrap';
import queryString from 'query-string';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { IdleTimeoutManager } from 'idle-timer-manager';
import userflow from 'userflow.js';
import { LicenseInfo } from '@mui/x-license-pro';
import { katalonui_theme } from './katalonui-theme';
import Executions from './pages/Executions';
import ResetPassword from './pages/ResetPassword';
import UploadReports from './pages/UploadReports';
import Users from './pages/users/Users';
import Projects from './pages/Projects';
import Teams from './pages/Teams';
import UserSettings from './pages/UserSettings';
import TeamSettings from './pages/TeamSettings';
import ApiKey from './pages/ApiKey';
import AcceptInvitation from './pages/AcceptInvitation';
import ExecutionDetails from './pages/execution/Execution';
import ExecutionTestResult from './pages/execution_test_result/ExecutionTestResult';
import ExecutionTestSuite from './pages/execution_test_suite/ExecutionTestSuite';
import ExecutionTestResultHistory from './pages/ExecutionTestResultHistory';
import ExecutionTestSuiteHistoryV2 from './pages/ExecutionTestSuiteHistoryV2';
import ExecutionTestObjectHistory from './pages/ExecutionTestObjectHistory';
import TestProject from './pages/test_project/TestProject';
import Login from './pages/Login';
import Logout from './pages/Logout';
import RouteConstants from './utils/RouteConstants';
import Error from './pages/Error';
import NoExecutionHistory from './pages/NoExecutionHistory';
import Dashboard from './pages/Dashboard';
import Request from './pages/Request';
import RunConfiguration from './pages/plan/RunConfiguration';
import CodeRepo from './pages/test_project/CodeRepo';
import Agent from './pages/Agent';
import Jobs from './pages/Jobs';
import QuickGuide from './pages/QuickGuide';
import ProjectDashboard from './pages/project_dashboard_v2/ProjectDashboard';
import MContext from './models/MContext';
import MAuth from './models/MAuth';
import MConfigs from './models/MConfigs';
import Services from './utils/Services';
import Routes from './utils/Routes';
import HomeV2 from './pages/home/HomeV2';
import RedirectPageHandler from './pages/home/RedirectPageHandler';
import OrganizationManageProjects from './pages/organization_manage_projects/OrganizationManageProjects';
import Release from './pages/release/Release';
import CreateEditBuild from './pages/release_build/CreateEditBuild';
import Job from './pages/plan/Job';
import TestDesign from './pages/TestDesign';
import TestCasePriorities from './pages/test_case_priorities/TestCasePriorities';
import ReleaseDetails from './pages/release/ReleaseDetails';
import InfoSidebar from './components/InfoSidebar';
import InfoSidebarUtil from './utils/InfoSidebar';
import TestProjectDetails from './pages/test_project/TestProjectDetails';
import Configuration from './pages/Configuration';
import UserLicense from './pages/UserLicense';
import Plugins from './pages/plugin/Plugins';
import ChangePassword from './pages/users/ChangePassword';
import OrganizationLicenses from './pages/organization_licenses/OrganizationLicenses';
import TestEnvInstallationV2 from './pages/TestEnvInstallationV2';
import OrganizationCreateLicenseKey from './pages/organization_licenses/OrganizationCreateLicenseKey';
import UserCreateLicenseKey from './pages/user/UserCreateLicenseKey';
import TestOpsLicense from './pages/organization/TestOpsLicense';
import StartUp from './components/startup/StartUp';
import PaymentSuccess from './pages/organization/subscriptions/PaymentSuccess';
import CheckoutQuote from './pages/organization/subscriptions/CheckoutQuote';
import Helper from './utils/Helper';
import OrganizationSettings from './pages/organization/OrganizationSettings';
import Notification from './utils/Notification';
import TestProjectGit from './pages/test_project/TestProjectGit';
import K8SAgent from './pages/K8SAgent';
import CircleCIAgent from './pages/CircleCiAgent';
import TestCasesV2 from './pages/TestCaseV2';
import TestSuitesV2 from './pages/TestSuitesV2';
import TestObjects from './pages/test_objects/TestObjects';
import OrganizationHome from './pages/home/OrganizationHome';
import TestEnvInstallation from './pages/TestEnvInstallation';
import CreateOrganizationPage from './pages/home/CreateOrganizationPage';
import { AppType, IntegrationType, PageType, USER_SURVEY_STATUS } from './utils/Constants';
import KatalonRecorderBackup from './pages/users/KatalonRecorderBackup';
import UserIntegrations from './pages/users/UserIntegrations';
import CompareExecution from './pages/execution/CompareExecution';
import TestObjectEntities from './pages/test_object_entity/TestObjectEntities';
import TestObjectEntity from './pages/test_object_entity/TestObjectEntity';
import OrganizationProjects from './pages/OrganizationProjects';
import ProjectSetting from './pages/project/ProjectSetting';
import Defects from './pages/Defects';
import Requirements from './pages/Requirements';
import RequirementDetails from './pages/requirement_details/RequirementDetails';
import AgentDetails from './pages/agent/AgentDetails';
import TopRightNavigationBar from './components/navigationbar/TopRightNavigationBar';
import TopLeftNavigationBar from './components/navigationbar/TopLeftNavigationBar';
import { theme } from './theme';
import OrganizationSession from './pages/organization_licenses/OrganizationSession';
import CIDashboard from './pages/ci_dashboard/CIDashboard';
import KEyesExecution from './pages/keyes/KEyesExecution';
import AcceptSSO from './pages/AcceptSSO';
import ErrorSSO from './pages/ErrorSSO';
import TestCase from './pages/test_case/TestCase';
import TestCaseSearchResults from './pages/test_case/TestCaseSearchResults';
import LoadingSpinner from './components/LoadingSpinner';
import SurveyCompleted from './pages/SurveyCompleted';
import KSETimeoutSettings from './pages/organization/KSETimeoutSettings';
import { TimeZone } from './utils/Moment';
import TestReportTestDesign from './pages/test_reports/TestReportTestDesign';
import TestReportTestMaintenance from './pages/test_reports/TestReportTestMaintenance';
import TopCenterNavigationBar from './components/navigationbar/TopCenterNavigationBar';
import BuildDetails from './pages/release_build/BuildDetails';
import LeftNavigationBar from './components/navigationbar/LeftNavigationBar';
import MFlags from './models/MFlags';
import WarningPopup from './components/maintenance/WarningPopup';
import { t } from './i18n/t';
import TestCloudAgentDetails from './pages/test_cloud/TestCloudAgentDetails';
import IntegrationsPage from './pages/integrations/IntegrationsPage';
import IntegrationsPageV2 from './pages/integrations/IntegrationsPageV2';
import UrlHelper from './utils/UrlHelper';
import DownloadFile from './pages/DownloadFile';
import TestOpsCheckoutQuote from './pages/testops_subscription/TestOpsCheckoutQuote';
import WarningPackage from './components/warning_packages/WarningPackage';
import FlagHelper from './utils/flags/FlagHelper';
import CheckoutQuotePaymentSuccess from './pages/organization/subscriptions/CheckoutQuotePaymentSuccess';
import TestCloudTunnel from './pages/TestCloudTunnel';
import Apis from './utils/Apis';
import TestRunCalendarPage from './pages/testexecution/TestRunsCalendarPage';
import TestRunListPage from './pages/testexecution/TestRunListPage';
import ReleasesPage from './pages/release/ReleasesPage';
import EditTestSuite from './pages/test_suite/EditTestSuite';
import TestSuiteSearchResults from './pages/test_suite/TestSuiteSearchResults';
import BaselineCollectionGroupVersionInformation from './pages/keyes/BaselineCollectionGroupVersionInformation';
import BaselineCollectionGroupDetail from './pages/keyes/BaselineCollectionGroupDetail';
import { notificationIds } from './utils/NotificationPageUtils';
import GitTestProjectSetUp from './pages/test_project/GitTestProjectSetUp';
import OnboardingSurveyV3 from './pages/survey/OnboardingSurveyV3';
import MobileNativeAppPage from './pages/testexecution/MobileNativeAppPage';
import CustomFields from './pages/CustomFields';
import MAccountFlags from './models/MAccountFlags';
import WelcomeDownloadKs from './pages/WelcomeDownloadKs';
import TestcloudTrialWarning from './components/warning_packages/TestcloudTrialWarning';
import AutonomousTesting from './pages/AutonomousTesting';
import { getProjectIdFromProjectTrackingInformation,
  removeProjectTrackingInformation,
  setProjectTrackingInformation } from './utils/SegmentAnalytics';
import { next } from './utils/Count';
import VerifyEmailUserBannerWarning from './components/warning_packages/VerifyEmailUserBannerWarning';
import RedirectWithApiLink from './components/RedirectWithApiLink';
import OnboardingWayToStartPage from './pages/survey/OnboardingWayToStartPage';
import NotificationProvider from './components/notification/NotificationProvider';
import KatalonPrompt from './components/KatalonPrompt';
import ArtificialIntelligence from './pages/ai/ArtificialIntelligence';
import DeleteTestEntityDialog from './components/cloud-studio-component/DeleteTestEntityDialog';
import AddToTestSuiteDialog from './components/cloud-studio-component/testcase/AddToTestSuiteDialog';
import SampleG5TestCaseCreator from './components/cloud-studio-component/SampleG5TestCaseCreator';
import ConfigureTestRunDialog from './components/dialog/ConfigureTestRunDialog';
import SpinnerPage from './auth/SpinnerPage';
import KEyes from './pages/keyes/KEyes';
import AutonomousManagement from './pages/truetest/AutonomousManagement';
import TestRunHistoryPage from './pages/testexecution/TestRunHistoryPage';
import SPARoute from './components/SPARoute';
import { updateUserTraits } from './utils/UserFlowHelper';

class App extends React.Component {

  constructor(props) {
    super(props);
    MContext.parse(props.match.params);
    MContext.newOrders = RouteConstants.isNewOrdersPage(props.location.pathname);
    this.teamId = MContext.teamId;
    this.projectId = MContext.projectId;
    this.organizationId = MContext.organizationId;
    this.sessionTimeout = MAuth.user?.sessionTimeout;
    this.message = queryString.parse(this.props.location.search).message;

    LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_X_PRO_LICENSE_KEY || '');

    this.state = {
      team: null,
      project: null,
      organization: null,
    };

    this.resize = this.resize.bind(this);
    this.handleNavigate = this.handleNavigate.bind(this);
  }

  onGotInfoSidebarRef(infoSidebar) {
    InfoSidebarUtil.setup(infoSidebar);
  }

  getNewProjectTrackingInformationErrorHandler = (message) => {
    // in case getProjectTrackingInformation failed, we don't show notification for users
    console.error(message);
  };

  getNewProjectTrackingInformation() {
    Services.getProjectTrackingInformation(this.projectId, this.getNewProjectTrackingInformationErrorHandler)
      .then((content) => {
        setProjectTrackingInformation(content);
      });
  }

  clearOldProjectTrackingInformation() {
    removeProjectTrackingInformation();
  }

  handleNavigate({ detail }) {
    const { history } = this.props;
    const { path } = detail;
    history.replace(path);
  }

  componentDidMount() {
    if (MAuth.isLoggedIn) {
      const { type } = this.props;
      if (type === PageType.PROJECT) {
        this.getProject();
        if (MFlags.identifierForProductTrackingEnabled) {
          const projectId = getProjectIdFromProjectTrackingInformation();
          if (this.projectId !== projectId) {
            this.clearOldProjectTrackingInformation();
            this.getNewProjectTrackingInformation();
          }
        }
        this.getTeam();
      }
      if (type === PageType.TEAM) {
        this.getTeam();
      }
      if (type === PageType.ORGANIZATION || type === PageType.ORGANIZATION_HOME || type === PageType.TESTOPS_ORGANIZATION_SETTING) {
        this.getOrganization();
      }
      if (type === PageType.KATALON) {
        this.getOrganization();
      }
    }
    this.smoothScrollToHash();

    this.setupAutonomousTracking();

    window.addEventListener('resize', this.resize);
    this.resize();

    if (this.sessionTimeout) {
      const manager = new IdleTimeoutManager({
        timeout: this.sessionTimeout * 60,
        onExpired: () => {
          console.log("Katalon: Session Expired");
          MAuth.logout();
        }
      });
      return () => manager.clear();
    }

    window.addEventListener('micro-app:navigate', this.handleNavigate);
    return () => {};
  }

  dispatchParentAppNavigateEvent(prevProps) {
    const { location } = this.props;
    const { pathname, search } = location;
    const fullPath = `${pathname}${search}`;
    const { pathname: prevPathName, search: prevSearch } = prevProps.location;
    const prevFullPath = `${prevPathName}${prevSearch}`;
    if (fullPath !== prevFullPath) {
      const navigateEvent = new CustomEvent('parent-app:navigate', { detail: { path: fullPath } });
      window.dispatchEvent(navigateEvent);
    }
  }

  componentDidUpdate(prevProps) {
    this.dispatchParentAppNavigateEvent(prevProps);
  }

  componentWillUnmount() {
    FlagHelper.dispose();
    window.removeEventListener('micro-app:navigate', this.handleNavigate);
  }

  setupAutonomousTracking() {
    const katalonTrafficAgent = document.createElement('script');
    katalonTrafficAgent.async = true;
    katalonTrafficAgent.defer = true;
    katalonTrafficAgent.src = MConfigs.autonomousAgentUrl;
    katalonTrafficAgent.id = 'katalonTrafficAgent';

    document.head.appendChild(katalonTrafficAgent);

    document.getElementById('katalonTrafficAgent').addEventListener('load', () => {
      window.startTrafficAgent(
        MConfigs.autonomousClientCode,
        { hover: false }
      );
    });
  }

  initLaunchDarkly(orgId) {
    if (orgId) {
      FlagHelper.changeOrganization(orgId).then(() => {
        const featureFlags = FlagHelper.allFlags();
        MContext.featureFlags = featureFlags;
        MFlags.parse(featureFlags);
        // We need add it after parse Flag, but before setState. Because when we parse flag after setState, components is not re-render
        MContext.appType = RouteConstants.getAppType(this.props.location.pathname);
        this.setState({ featureFlags });
      });
    } else {
      const featureFlags = FlagHelper.allFlags();
      MContext.featureFlags = featureFlags;
      MFlags.parse(featureFlags);
      // We need add it after parse Flag, but before setState. Because when we parse flag after setState, components is not re-render
      MContext.appType = RouteConstants.getAppType(this.props.location.pathname);
      this.setState({ featureFlags });
    }
  }

  initLaunchDarklyAccount(accountId) {
    if (!MConfigs.isOnPremise && accountId) {
      FlagHelper.changeAccount(accountId).then(() => {
        const accountFlags = FlagHelper.allAccountFlags();
        MAccountFlags.parse(accountFlags);
        // We need add it after parse Flag, but before setState. Because when we parse flag after setState, components is not re-render
        MContext.appType = RouteConstants.getAppType(this.props.location.pathname);
        this.setState({ accountFlags });
      });
    } else {
      const accountFlags = FlagHelper.allFlags();
      MAccountFlags.parse(accountFlags);
      // We need add it after parse Flag, but before setState. Because when we parse flag after setState, components is not re-render
      MContext.appType = RouteConstants.getAppType(this.props.location.pathname);
      this.setState({ accountFlags });
    }
  }

  resize() {
    const currentMinimizeLogo = (window.innerWidth <= 900);
    if (currentMinimizeLogo !== this.state.minimizeLogo) {
      this.setState({ minimizeLogo: currentMinimizeLogo });
    }
  }

  smoothScrollToHash() {
    const hash = window.location.hash.slice(1);
    if (hash) {
      const target = document.getElementById(hash);
      if (target) {
        target.scrollIntoView({ behavior: 'smooth', block: 'start' });
      } else {
        setTimeout(this.smoothScrollToHash, 2000);
      }
    }
  }

  showNotification() {
    Notification.pushSuccessPermanent(this.message, 'Success');
  }

  getProject() {
    const { teamId, projectId } = this;
    Services.getProject(projectId)
      .then((project) => {
        const route = new Routes({
          teamId,
          projectId,
        });
        project.link = route.project_link;
        MContext.project = project;
        const { timezone } = project;
        if (timezone) {
          TimeZone.setDefault(timezone);
        }
        this.setState({
          project,
        });
      });
  }

  updateUserTraits(accountId) {
    Promise.all([
      Services.getAccountSettings(accountId),
      Services.getAccountUserRole(accountId),
    ]).then(([accountSetting, accountRole]) => {
      const isAIAdopted = accountSetting?.enableAi || accountRole?.role !== 'OWNER';
      updateUserTraits({ ai_adopted: isAIAdopted.toString() });
    });
  }

  getTeam() {
    const { teamId } = this;
    Services.getTeam(teamId)
      .then((team) => {
        this.initLaunchDarkly(team.organization.id);
        this.initLaunchDarklyAccount(team.organization.accountId);
        this.updateUserTraits(team.organization.accountId);
        const route = new Routes({
          teamId,
        });
        team.link = route.team_link;
        MContext.team = team;
        this.setState({
          team,
        });
      });
  }

  getOrganization() {
    const { organizationId } = this;
    this.initLaunchDarkly(organizationId);
    Services.getOrganization(organizationId)
      .then((organization) => {
        const route = new Routes({
          organizationId,
        });
        this.initLaunchDarklyAccount(organization.accountId);
        this.updateUserTraits(organization.accountId);
        organization.link = route.organization_link;
        MContext.organization = organization;
        this.setState({ organization });
      });
  }

  get hiddenMainComponentRoutes() {
    if (MConfigs.hideReporting) {
      return [RouteConstants.user_settings];
    }

    const hiddenRoutes = [
      RouteConstants.organization_projects,
      RouteConstants.organization_teams,
    ];

    return hiddenRoutes.map((path) => <Route path={path} component={Error} />);
  }

  setupSurvicate() {
    const s = document.createElement('script');
    const workspace = MConfigs.isLicenseAdminServer ? '4e21c758c81e120089a49434877a58f4' : 'cbd1b8cf1669511930aa5891e54982c7';
    s.src = `https://survey.survicate.com/workspaces/${workspace}/web_surveys.js`;
    s.async = true;
    document.body.appendChild(s);
  }

  setupUserFlow() {
    const USER_FLOW_TOKEN = MConfigs.userFlowToken;
    if (USER_FLOW_TOKEN && MAuth.isLoggedIn) {
      const { id, fullName, email, createdAt } = MAuth.user;
      userflow.init(USER_FLOW_TOKEN);
      return userflow.identify(email, {
        name: fullName,
        email,
        signed_up_at: createdAt,
        user_id: id
      });
    }
    return Promise.resolve();
  }

  /**
   * @param fromPath
   * @param component
   * @return redirect to notification not found page if value of MFlags.subscriptionAndPaymentMethodDisabled is true.
   * Otherwise, return component
   */
  // eslint-disable-next-line react/no-unused-class-component-methods
  renderPageIfSubscriptionAndPaymentMethodEnabled(fromPath, component) {
    const { subscriptionAndPaymentMethodDisabled } = MAccountFlags;
    if (subscriptionAndPaymentMethodDisabled) {
      const route = new Routes({ notificationId: notificationIds.NOT_FOUND });
      return (<Redirect from={fromPath} to={{ pathname: route.notification_link }} />);
    } else {
      return component;
    }
  }

  buildTestPlanningUrl() {
    const projects = MAuth?.user?.projects;
    if (projects && projects.length > 0) {
      const teamId = projects[0]?.team?.id;
      const projectId = projects[0]?.id;
      return `/team/${teamId}/project/${projectId}/test-planning`;
    }
    return '';
  }

  render() {
    const { type } = this.props;
    const { team, project, organization, featureFlags, accountFlags } = this.state;
    const isOnPremise = MConfigs.isOnPremise;
    const isReadOnly = MConfigs.isReadOnly;

    let ready = false;
    const {
      zipScriptRepoDisabled,
      openAiApiKeyManagementEnabled,
      revampIntegrationsEnabled,
      disableVerifyEmailUserBanner,
      removeAgentSetupPageOnProjectSettingsEnabled
    } = MFlags;

    if (MAuth.isLoggedIn) {
      // Go to user survey page if the user doesn't take the survey yet
      if (MAuth.user.surveyStatus === USER_SURVEY_STATUS.NOT_SUBMITTED) {
        const newURL = UrlHelper.getRedirectUrlWithForwardUrl(RouteConstants.welcome, [RouteConstants.home, '/']);
        if (newURL) {
          window.location.href = newURL;
          return <SpinnerPage />;
        }
      }

      switch (type) {
        case PageType.TEAM:
          ready = team != null && featureFlags && accountFlags;
          break;
        case PageType.PROJECT:
          ready = team != null && project != null && featureFlags && accountFlags;
          break;
        case PageType.TESTOPS_ORGANIZATION_SETTING:
        case PageType.ORGANIZATION_HOME:
        case PageType.ORGANIZATION:
        case PageType.KATALON:
          ready = organization != null && featureFlags && accountFlags;
          break;
        case PageType.USER:
        default:
          ready = true;
      }
      MContext.appType = RouteConstants.getAppType(this.props.location.pathname);
    } else {
      const match = matchPath(window.location.pathname, {
        path: '/(reset-password|startup|error-sso|survey-completed|logout|login|authenticate|notification/not-found)/',
        exact: false,
        strict: false
      });
      if (!match) {
        return <SpinnerPage />;
      }
      ready = isOnPremise != null;
    }

    if (ready) {
      const isLicenseAdminServer = MConfigs.isLicenseAdminServer;
      const isLicenseServerOnPremise = MConfigs.isLicenseServerOnPremise;
      const showTopRightNavBar = MAuth.isLoggedIn && !MContext.newOrders;
      const showTopCenterNavBar = MAuth.isLoggedIn;
      const showTopNavBar = (MAuth.isLoggedIn
        || MContext.newOrders
        || matchPath(window.location.pathname, {
          path: '/(error-sso)',
          exact: false,
          strict: false
        })) && !matchPath(window.location.pathname, {
        path: [
          RouteConstants.way_to_start,
          RouteConstants.welcome
        ],
        exact: true,
        strict: false,
      });

      // Check url match git set up page
      const matchGitSetupPage = matchPath(window.location.pathname, {
        path: '/team/:id/project/:id/git-test-project/setup',
        exact: false,
        strict: false
      });

      const appType = MContext.appType;
      const appTypes = [
        AppType.CENTER,
        AppType.REPORTS,
        AppType.CONFIGURATIONS,
        AppType.TEST_PLANNING,
        AppType.TEST_EXECUTION
      ];

      const showUnderTopNavBar = MAuth.isLoggedIn && type === PageType.PROJECT && appTypes.includes(appType) && !matchGitSetupPage;
      const {
        instantRunTestCasesEnabled,
        moveAgentToOrgLevelPhase2Enabled,
        e2eTestGenerationEnabled,
        atgCheckFeaturePermission,
      } = MFlags;
      const { subscriptionAndPaymentMethodDisabled } = MAccountFlags;
      const hideLeftNavigationBar = subscriptionAndPaymentMethodDisabled && matchPath(window.location.pathname, {
        path: RouteConstants.subscriptionAndPaymentMethodRoutes(),
        exact: false,
        strict: false,
      });
      const isDisplayTestcloudTrialBanner = matchPath(window.location.pathname, {
        path: [
          RouteConstants.testExecutionPaths(),
        ],
        exact: false,
        strict: false,
      });

      const isDisplayBanner = !matchPath(window.location.pathname, {
        path: [
          RouteConstants.welcome,
          RouteConstants.welcome_download_ks,
          RouteConstants.way_to_start,
        ],
        exact: true,
        strict: false,
      });
      const isTrueTestFeature = atgCheckFeaturePermission || e2eTestGenerationEnabled;

      const organizations = MAuth.isLoggedIn ? MAuth.user.organizations : [];
      this.setupSurvicate();
      this.setupUserFlow().catch(() => { /* ignored */ });
      return (
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <KatalonPrompt />
            <NotificationProvider />
            <SPARoute />
            <div id="app-content">
              {showTopNavBar &&
                <div id="top-navbar">
                  <TopLeftNavigationBar type={type} />
                  {showTopCenterNavBar && <TopCenterNavigationBar />}
                  {showTopRightNavBar && <TopRightNavigationBar type={type} />}
                </div>}
              {!disableVerifyEmailUserBanner && isDisplayBanner && <VerifyEmailUserBannerWarning />}
              {MFlags.testCloudTrialWarningEnabled && isDisplayTestcloudTrialBanner && !localStorage.getItem('isHiddenWarning') && <TestcloudTrialWarning />}
              <div id="under-top-navbar" className={!showUnderTopNavBar && 'd-none'} />
              {/* <div id="navbar-breadcrumb" /> */}
              {
                isReadOnly &&
                <WarningPopup text={t('maintenance_warning')} />
              }
              {isDisplayBanner && <WarningPackage />}
              <div id="main-body">
                {!hideLeftNavigationBar && <LeftNavigationBar type={type} />}
                <Container id="main-content" fluid>
                  <Switch>
                    <Route exact path="/authenticate" component={SpinnerPage} />
                    <Route exact path="/" component={Login} />
                    <Route exact path="/index.html" component={Login} />
                    {!isLicenseAdminServer &&
                    <Route
                      exact
                      path={RouteConstants.welcome}
                      component={OnboardingSurveyV3}
                    />}
                    {!isLicenseAdminServer &&
                      <Route
                        exact
                        path={RouteConstants.way_to_start}
                        render={(params) => <OnboardingWayToStartPage {...params} />}
                      />}
                    <Route exact path={RouteConstants.git_test_project_setup} component={GitTestProjectSetUp} />
                    {this.hiddenMainComponentRoutes}
                    {/* New license admin organization home page implement by KatOne => Redirect license admin organization home page to K1 URL */}
                    <Route
                      exact
                      path={RouteConstants.home_organization}
                      render={(params) => {
                        if (isLicenseAdminServer) {
                          return <RedirectWithApiLink to={Apis.katOneOrganizationHomePage({ orgId: this.organizationId })} />;
                        } else if (organizations.length === 0) {
                          // handle when the organization does not exist
                          return <RedirectWithApiLink to={Apis.katOneAdminCreateOrganization()} />;
                        } else {
                          return (<OrganizationHome {...params} />);
                        }
                      }}
                    />
                    <Route
                      exact
                      path={RouteConstants.create_organization_home}
                      render={(params) => {
                        if (!isLicenseAdminServer) {
                          return <RedirectWithApiLink to={Apis.katOneAdminCreateOrganization()} />;
                        } else {
                          return <CreateOrganizationPage {...params} />;
                        }
                      }}
                    />
                    {/* <Route exact path={RouteConstants.katalon_manage} component={OrganizationSettings} /> */}
                    <Route exact path={RouteConstants.project} component={ProjectDashboard} />
                    <Route exact path={RouteConstants.ci_dashboard} component={CIDashboard} />
                    <Route exact path={RouteConstants.project_setting} component={ProjectSetting} />
                    {!moveAgentToOrgLevelPhase2Enabled && !isOnPremise && <Route exact path={RouteConstants.k8s_agent_create} component={K8SAgent} />}
                    {!moveAgentToOrgLevelPhase2Enabled && !isOnPremise && <Route exact path={RouteConstants.k8s_agent} component={K8SAgent} />}
                    {moveAgentToOrgLevelPhase2Enabled && !isOnPremise && <Route exact path={RouteConstants.organization_k8s_create} component={K8SAgent} />}
                    {moveAgentToOrgLevelPhase2Enabled && !isOnPremise && <Route exact path={RouteConstants.organization_k8s_agent} component={K8SAgent} />}
                    {!moveAgentToOrgLevelPhase2Enabled && !isOnPremise && <Route exact path={RouteConstants.circle_ci_agent_create} component={CircleCIAgent} />}
                    {!moveAgentToOrgLevelPhase2Enabled && !isOnPremise && <Route exact path={RouteConstants.circle_ci_agent} component={CircleCIAgent} />}
                    {moveAgentToOrgLevelPhase2Enabled && !isOnPremise && <Route exact path={RouteConstants.organization_ci_agent_create} component={CircleCIAgent} />}
                    {moveAgentToOrgLevelPhase2Enabled && !isOnPremise && <Route exact path={RouteConstants.organization_circle_ci_agent} component={CircleCIAgent} />}
                    {!moveAgentToOrgLevelPhase2Enabled && <Route exact path={RouteConstants.agent_details} component={AgentDetails} />}
                    {moveAgentToOrgLevelPhase2Enabled && <Route exact path={RouteConstants.organization_agent_details} component={AgentDetails} />}
                    {isTrueTestFeature && <Route exact path={RouteConstants.organization_setting_truetest} component={AutonomousManagement} />}
                    {isTrueTestFeature && <Route exact path={RouteConstants.organization_setting_truetest_detail} component={AutonomousManagement} />}
                    {!isOnPremise && <Route exact path={RouteConstants.test_cloud_agent} component={TestCloudAgentDetails} />}
                    <Route path={RouteConstants.agent} component={Agent} />
                    <Route exact path={RouteConstants.organization_settings} component={OrganizationSettings} />
                    {/** Disable due to https://katalon.atlassian.net/browse/KO-2414 */}
                    {/* <Route exact path={RouteConstants.organization_users} component={OrganizationUsers} /> */}
                    {!isLicenseAdminServer && <Route path={RouteConstants.organization_manage_projects} component={OrganizationManageProjects} />}
                    {/** Disable due to https://katalon.atlassian.net/browse/KO-2414 */}
                    {/* <Route exact path={RouteConstants.organization_users_invite} component={OrganizationUsersInvitation} /> */}
                    {/* <Route exact path={RouteConstants.organization_users_manage} component={OrganizationUsersManagement} /> */}
                    {isLicenseAdminServer && <Route exact path={RouteConstants.organization_teams} component={Teams} />}
                    <Route exact path={RouteConstants.organization_projects} component={OrganizationProjects} />
                    <Route exact path={RouteConstants.organization_creat_license_keys} component={OrganizationCreateLicenseKey} />
                    <Route path={RouteConstants.organization_license_keys} component={OrganizationLicenses} />
                    {moveAgentToOrgLevelPhase2Enabled && <Route path={RouteConstants.organization_setting_agents} component={Agent} />}
                    {moveAgentToOrgLevelPhase2Enabled && <Route exact path={RouteConstants.organization_setting_test_env_installation} component={TestEnvInstallationV2} />}
                    {moveAgentToOrgLevelPhase2Enabled && <Route exact path={RouteConstants.organization_setting_test_env_docker_agent_installation} component={TestEnvInstallationV2} />}
                    <Route path={RouteConstants.organization_kse_timeout} component={KSETimeoutSettings} />
                    {!isOnPremise && <Route exact path={RouteConstants.organization_plugins} component={Plugins} />}
                    {!isOnPremise && <Route exact path={RouteConstants.organization_payment_success} component={PaymentSuccess} />}
                    {!isOnPremise && <Route exact path={RouteConstants.checkout_quote_payment_success} component={CheckoutQuotePaymentSuccess} />}
                    {!isOnPremise && <Route exact path={RouteConstants.testops_checkout_quote_payment_success} render={(params) => <CheckoutQuotePaymentSuccess isTestOps {...params} />} />}
                    {!isOnPremise && <Route exact path={RouteConstants.organization_checkout_quote} component={CheckoutQuote} />}
                    {isLicenseServerOnPremise && <Route exact path={RouteConstants.organization_testops} component={TestOpsLicense} />}
                    <Route exact path={RouteConstants.organization_session} component={OrganizationSession} />
                    {!isOnPremise
                    && <Route
                      exact
                      path={RouteConstants.testops_subscription_checkout_quote}
                      component={TestOpsCheckoutQuote}
                    />}
                    <Route exact path={RouteConstants.team} component={Dashboard} />
                    <Route exact path={RouteConstants.requirements} render={(params) => <Requirements {...params} />} />
                    <Route exact path={RouteConstants.requirement_details} component={RequirementDetails} />
                    <Route exact path={RouteConstants.defects} render={(params) => <Defects {...params} />} />
                    <Route exact path={RouteConstants.priorities} component={TestCasePriorities} />
                    <Route path={RouteConstants.test_report_requirement_test_run} component={TestReportTestDesign} />
                    <Route path={RouteConstants.test_report_traceability} component={TestReportTestDesign} />
                    <Route path={RouteConstants.test_report_flakiness} component={TestReportTestMaintenance} />
                    <Route path={RouteConstants.test_report_platform_statistics} component={TestReportTestMaintenance} />
                    <Route exact path={RouteConstants.test_cases_active} component={TestReportTestMaintenance} />
                    <Route exact path={RouteConstants.test_cases_slowest} component={TestReportTestMaintenance} />
                    <Route exact path={RouteConstants.test_cases_stale} component={TestReportTestMaintenance} />
                    {MFlags.testcloudExecutionEnvMicroAppEnabled && <Route exact path={RouteConstants.test_runs} component={TestRunHistoryPage} />}
                    <Route exact path={RouteConstants.grid} render={(params) => <TestRunListPage {...params} />} /> :
                    <Route exact path={RouteConstants.test_planning} render={(params) => <TestRunCalendarPage {...params} />} /> :
                    <Route exact path={RouteConstants.native_apps} render={(params) => <MobileNativeAppPage {...params} />} />

                    {MFlags.testCloudMobileEnvironmentReferralLinkEnabled && <Route
                      exact
                      path={RouteConstants.mobile_referral_link}
                      render={() => <Redirect
                        from={RouteConstants.mobile_referral_link}
                        to={{ pathname: this.buildTestPlanningUrl(), state: { userFlowId: process.env.REACT_APP_MOBILE_USER_FLOW_ID } }}
                      />}
                    />}

                    <Route exact path={RouteConstants.releases} component={ReleasesPage} />
                    <Route exact path={RouteConstants.jobs} component={Jobs} />
                    <Route exact path={RouteConstants.release_create} component={Release} />
                    <Route exact path={RouteConstants.release_edit} component={Release} />
                    <Route exact path={RouteConstants.build_create} component={CreateEditBuild} />
                    <Route exact path={RouteConstants.build_view} component={BuildDetails} />
                    <Route exact path={RouteConstants.build_edit} component={CreateEditBuild} />
                    <Route path={RouteConstants.release_view} component={ReleaseDetails} />
                    <Route exact path={RouteConstants.test_projects} component={CodeRepo} />
                    {!removeAgentSetupPageOnProjectSettingsEnabled && <Route exact path={RouteConstants.test_env_installation} component={TestEnvInstallation} />}
                    {
                      zipScriptRepoDisabled ?
                        <Route exact path={RouteConstants.test_project_creation} component={TestProjectGit} />
                        : <Route exact path={RouteConstants.test_project_creation} component={TestProject} />
                    }
                    <Redirect from={RouteConstants.test_project_git_creation} to={{ pathname: RouteConstants.git_test_project_setup }} />
                    <Route exact path={RouteConstants.test_project} component={TestProjectDetails} />
                    <Route exact path={RouteConstants.test_project_git_edit} component={TestProjectGit} />
                    <Route exact path={RouteConstants.test_project_edit} component={TestProject} />
                    <Route
                      exact
                      path={RouteConstants.run_configuration}
                      render={(props) =>
                        <RunConfiguration
                          {...props}
                          key={props.match.params.runConfigurationId}
                        />}
                    />
                    <Route exact path={RouteConstants.run_configuration_session} component={RunConfiguration} />
                    <Route exact path={RouteConstants.run_configuration_summary} component={RunConfiguration} />
                    <Route exact path={RouteConstants.job} component={Job} />
                    <Route
                      exact
                      path={RouteConstants.sample_plan}
                      render={() => <Route component={Error} />}
                    />
                    <Route exact path={RouteConstants.execution_request_detail} component={Request} />
                    <Route
                      path={RouteConstants.execution_test_result_details}
                      render={(props) =>
                        <ExecutionTestResult
                          {...props}
                          key={props.match.params.executionTestResultId}
                        />}
                    />
                    <Route exact path={RouteConstants.executions_compare} component={CompareExecution} />
                    <Route exact path={RouteConstants.test_cases_search} component={TestCaseSearchResults} />
                    <Route exact path={RouteConstants.execution_test_result_history} component={ExecutionTestResultHistory} />
                    <Route exact path={RouteConstants.test_case_overview} component={ExecutionTestResultHistory} />
                    <Route exact path={RouteConstants.test_case_steps} component={ExecutionTestResultHistory} />
                    <Route exact path={RouteConstants.test_case_local_variables} component={ExecutionTestResultHistory} />
                    <Route exact path={RouteConstants.test_case_edit} component={TestCase} />
                    <Route exact path={RouteConstants.test_suites_search} component={TestSuiteSearchResults} />
                    <Route exact path={RouteConstants.execution_test_suite_history} component={ExecutionTestSuiteHistoryV2} />
                    <Route exact path={RouteConstants.test_suite_edit} component={EditTestSuite} />
                    <Route exact path={RouteConstants.execution_test_object_history} component={ExecutionTestObjectHistory} />
                    <Route path={RouteConstants.execution_test_suite_details} component={ExecutionTestSuite} />
                    <Route path={RouteConstants.execution_details} component={ExecutionDetails} />
                    <Route exact path={RouteConstants.overview} component={Executions} />
                    <Route exact path={RouteConstants.executions} component={Executions} />
                    <Route path={RouteConstants.test_report_test_execution} component={Executions} />
                    <Route path={RouteConstants.failed_test_result} component={Executions} />
                    <Route exact path={RouteConstants.import_manual_reports} component={UploadReports} />
                    <Route path={RouteConstants.keyes_execution_details} component={KEyesExecution} />
                    <Route path={RouteConstants.baseline_collection_group_history} component={BaselineCollectionGroupVersionInformation} />
                    <Route path={RouteConstants.baseline_collection_group_detail} component={BaselineCollectionGroupDetail} />
                    <Route path={RouteConstants.baseline_collection} component={KEyes} />
                    <Route path={RouteConstants.keyes} component={KEyes} />
                    <Route exact path={RouteConstants.test_design} component={TestDesign} />
                    <Route exact path={RouteConstants.test_suites} component={TestSuitesV2} />
                    <Route exact path={RouteConstants.test_cases} component={TestCasesV2} />
                    <Route exact path={RouteConstants.web_services} component={TestObjects} />
                    <Route exact path={RouteConstants.web_services_anomalies} component={TestObjects} />
                    <Route path={RouteConstants.test_object_entity_details} component={TestObjectEntity} />
                    <Route exact path={RouteConstants.test_object_entities} component={TestObjectEntities} />
                    <Route
                      exact
                      path={RouteConstants.integrations}
                      render={(props) => {
                        if (revampIntegrationsEnabled) {
                          return <IntegrationsPageV2 {...props} />;
                        }
                        return <IntegrationsPage {...props} />;
                      }}
                    />
                    {isOnPremise && <Route exact path={RouteConstants.organization_configuration} component={Configuration} />}
                    {isLicenseAdminServer && <Route path={RouteConstants.admin_users} component={Users} />}
                    <Route exact path={RouteConstants.admin_projects} component={Projects} />
                    {isLicenseAdminServer && <Route exact path={RouteConstants.admin_team_settings} component={TeamSettings} />}
                    {/* K1 is centralizing profile page to Katalon One => Redirect profile page to K1 URL */}
                    <Route
                      exact
                      path={RouteConstants.profile}
                      render={() => <RedirectWithApiLink to={Apis.katOneProfilePage()} />}
                    />
                    <Route exact path={RouteConstants.user_settings} component={UserSettings} />
                    {!isOnPremise && <Route exact path={RouteConstants.katalon_recorder_backup} component={KatalonRecorderBackup} />}
                    <Route exact path={RouteConstants.user_integration} component={UserIntegrations} />
                    {!isOnPremise && <Route exact path={RouteConstants.user_license} component={UserLicense} />}
                    {!isOnPremise && <Route path={RouteConstants.user_license_create} component={UserCreateLicenseKey} />}
                    <Route exact path={RouteConstants.apikey} component={ApiKey} />
                    <Route exact path={RouteConstants.accept_invitation} component={AcceptInvitation} />
                    <Route exact path={RouteConstants.accept_sso} component={AcceptSSO} />
                    {isOnPremise && <Route exact path={RouteConstants.reset_password} component={ResetPassword} />}
                    {isOnPremise && <Route path={RouteConstants.startup} component={StartUp} />}
                    <Route exact path={RouteConstants.login} render={(params) => <Login isLoggedInUrl {...params} />} />
                    <Route exact path={RouteConstants.logout} component={Logout} />
                    <Route exact path={RouteConstants.no_execution_history} component={NoExecutionHistory} />
                    <Route exact path={RouteConstants.notification} component={Error} />
                    <Route exact path={RouteConstants.survey_completed} component={SurveyCompleted} />
                    <Route exact path={RouteConstants.quick_guide} component={QuickGuide} />
                    <Route exact path={RouteConstants.error_sso} component={ErrorSSO} />
                    <Route
                      exact
                      path={RouteConstants.home}
                      render={(props) => {
                        const subscribe = Helper.getParameterByName('subscribe');
                        const isSubscribe = subscribe === 'true';
                        const isTrial = subscribe === 'trial';
                        const isGetQuote = Helper.getParameterByName('quote') === 'true';
                        const feature = Helper.getParameterByName('package');
                        return (
                          <HomeV2
                            isSubscribe={isSubscribe}
                            isTrial={isTrial}
                            isGetQuote={isGetQuote}
                            feature={feature}
                            {...props}
                          />
                        );
                      }}
                    />
                    <Route exact path={RouteConstants.test_cloud_tunnel} component={TestCloudTunnel} />
                    <Route exact path={RouteConstants.custom_fields} component={CustomFields} />
                    {openAiApiKeyManagementEnabled && <Route exact path={RouteConstants.artificial_intelligence} component={ArtificialIntelligence} />}
                    <Redirect from={RouteConstants.upload_katalon_reports} to={{ pathname: RouteConstants.integrations, search: UrlHelper.generateParams('type', IntegrationType.KATALON_STUDIO_INTEGRATION.value.toLowerCase()) }} />
                    <Redirect from={RouteConstants.frameworks_integration} to={{ pathname: RouteConstants.integrations, search: UrlHelper.generateParams('type', IntegrationType.MOCHA.value.toLowerCase()) }} />
                    <Redirect from={RouteConstants.report_uploader_guide} to={{ pathname: RouteConstants.integrations, search: UrlHelper.generateParams('type', IntegrationType.CLI.value.toLowerCase()) }} />
                    <Redirect from={RouteConstants.jira_setting} to={{ pathname: RouteConstants.integrations, search: UrlHelper.generateParams('type', IntegrationType.JIRA_INTEGRATION.value.toLowerCase()) }} />
                    <Redirect from={RouteConstants.kobiton_setting} to={{ pathname: RouteConstants.integrations, search: UrlHelper.generateParams('type', IntegrationType.KOBITON_INTEGRATION.value.toLowerCase()) }} />
                    <Redirect from={RouteConstants.slack_setting} to={{ pathname: RouteConstants.integrations, search: UrlHelper.generateParams('type', IntegrationType.SLACK_INTEGRATION.value.toLowerCase()) }} />
                    <Redirect from={RouteConstants.configure_plan} to={{ pathname: RouteConstants.run_configuration, search: UrlHelper.generateParams('isOpenScheduleTestRunDialog', true) }} />
                    <Redirect from={RouteConstants.configure_plans} to={{ pathname: RouteConstants.test_planning, search: UrlHelper.generateParams('isOpenScheduleTestRunDialog', true) }} />
                    {isOnPremise && <Route exact path={RouteConstants.change_password} component={ChangePassword} />}
                    <Route exact path={RouteConstants.download} component={DownloadFile} />
                    {isTrueTestFeature && <Route path={RouteConstants.truetest_user_journeys} component={AutonomousTesting} />}
                    <Route
                      exact
                      path={RouteConstants.download_ks}
                      render={(props) => (
                        <HomeV2
                          isDownloadKs
                          {...props}
                        />
                      )}
                    />
                    <Route
                      exact
                      path={RouteConstants.try_visual_testing}
                      render={(props) => (
                        <RedirectPageHandler
                          doesTryVisualTesting
                          {...props}
                        />
                      )}
                    />
                    <Route exact path={RouteConstants.welcome_download_ks} component={WelcomeDownloadKs} />
                    <Route component={Error} />
                  </Switch>
                </Container>
                <InfoSidebar ref={(ref) => this.onGotInfoSidebarRef(ref)} key={next()} />
              </div>
              {this.message && this.showNotification()}
            </div>
            <LoadingSpinner />
            {MFlags.g5CreateSampleTestCaseEnabled && <SampleG5TestCaseCreator />}
          </ThemeProvider>
          <ThemeProvider theme={katalonui_theme}>
            <DeleteTestEntityDialog />
            <AddToTestSuiteDialog />
            {instantRunTestCasesEnabled && <ConfigureTestRunDialog />}
          </ThemeProvider>
        </StyledEngineProvider>
      );
    } else {
      return <SpinnerPage />;
    }
  }
}

export default withRouter(App);
