import React from 'react';
import {
  Button,
  Form,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import PropTypes from 'prop-types';
import Notification from '../../utils/Notification';
import Services from '../../utils/Services';

class MachineRemovalDialog extends React.Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.removeMachine = this.removeMachine.bind(this);
    this.cancelDelete = this.cancelDelete.bind(this);
    this.state = {
      modal: false,
    };
  }

  toggle() {
    const { modal } = this.state;
    this.setState({
      modal: !modal,
    });
  }

  removeMachine(e) {
    e.preventDefault();
    const { machine, organizationId } = this.props;
    Services.removeMachine(machine.machineKey, organizationId, machine.feature).then(() => {
      const { afterRemove } = this.props;
      Notification.pushSuccess(`The machine ${machine.machineKey} was deactivated.`);
      if (afterRemove) {
        afterRemove();
      }
      this.toggle();
    }).catch(() => {
      this.toggle();
    });
  }

  cancelDelete() {
    this.toggle();
  }

  render() {
    const { machine } = this.props;
    if (machine) {
      return (
        <div>
          <Modal isOpen={this.state.modal} toggle={this.toggle}>
            <Form data-trackid="deactivate-machine" onSubmit={this.removeMachine}>
              <ModalHeader>Deactivate Machine</ModalHeader>
              <ModalBody>
                This machine <strong>{machine.machineKey}</strong> will be deactivated
              </ModalBody>
              <ModalFooter>
                <Button type="submit" color="danger">Remove</Button>
                <Button color="secondary" onClick={this.cancelDelete}>Cancel</Button>
              </ModalFooter>
            </Form>
          </Modal>
        </div>
      );
    }
    return null;
  }
}

MachineRemovalDialog.propTypes = {
  afterRemove: PropTypes.func,
};

export default MachineRemovalDialog;
