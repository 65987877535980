import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from '@mui/material/styles';
import Tooltip from '@katalon-studio/katalon-ui/Tooltip';
import { Checkbox } from '@katalon-studio/katalon-ui/Checkbox';
import MPaginationMapping from '../../components/table/models/MPaginationMapping';
import DataLoader from './DataLoader';
import { t } from '../../i18n/t';
import TableCore from './models/TableCore';
import TableSortCore from './models/TableSortCore';
import Arrays from '../../utils/Arrays';
import MTableColumnDataMapping from './models/MTableColumnDataMapping';
import { katalonui_theme } from '../../katalonui-theme';
import { next } from '../../utils/Count';

class DataTable extends React.Component {
  constructor(props) {
    super(props);
    this.renderData = this.renderData.bind(this);
    this.onHandleSortChange = this.onHandleSortChange.bind(this);
    this.hasAtLeastOneRowSelected = this.hasAtLeastOneRowSelected.bind(this);
    this.handleSelectAll = this.handleSelectAll.bind(this);
    this.updateData = this.updateData.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
    this.dataLoaderRef = this.props.forwardedRef || React.createRef();

    let { defaultSort } = this.props;
    if (defaultSort[0]) {
      // We split `,` to get sorted key and sorted mode here.
      // example: defaultSort = 'sortOrder, asc NULLS LAST'
      //  --> sortedKey: 'sortOrder'
      //      sortedMode: ' asc NULLS LAST'
      // So, defaultSort can support format: 'sortOrder, asc NULLS LAST' or 'sortOrder,asc NULLS LAST'
      defaultSort = defaultSort[0].split(',');
    }

    this.state = {
      currentSort: {
        sortedKey: defaultSort[0],
        // We have to trim sortedMode because space can exist in the first character
        // sortedMode: ' asc NULLS LAST' --> trim 'asc NULLS LAST' --> split and get first 'asc'
        sortedMode: defaultSort[1] ? defaultSort[1].trim().split(' ')[0] : '',
      },
      selectedRows: [],
      data: [],
    };
  }

  onPageChange() {
    this.setSelectedRows([]);
  }

  getSelectedRows() {
    const { selectedRows } = this.state;
    return selectedRows;
  }

  allRowsSelected() {
    const { selectedRows, data } = this.state;
    return selectedRows.length > 0 && data.length === selectedRows.length;
  }

  hasAtLeastOneRowSelected() {
    const { selectedRows } = this.state;
    return selectedRows.length > 0;
  }

  setSelectedRows(selectedRows) {
    this.setState({
      selectedRows,
    });
  }

  handleSelectAll() {
    if (this.hasAtLeastOneRowSelected()) {
      this.setSelectedRows([]);
    } else {
      const { data } = this.state;
      this.setSelectedRows(data);
    }
  }

  onSelectRow(event, row) {
    event.preventDefault();
    const { checked } = event.target;
    const { selectedRows } = this.state;

    if (checked) {
      this.setSelectedRows([...selectedRows, row]);
    } else {
      this.setSelectedRows(selectedRows.filter((selected) => selected.id !== row.id));
    }
  }

  isRowSelected(row) {
    const { selectedRows } = this.state;
    return selectedRows.some((item) => item.id === row.id);
  }

  getCheckboxTooltipLabel(isSelected, isSelectAllCheckbox) {
    if (isSelected) {
      return `Deselect ${isSelectAllCheckbox ? 'all' : ''}`;
    }
    return `Select ${isSelectAllCheckbox ? 'all' : ''}`;
  }

  renderSelectAll() {
    const { entityType } = this.props;
    return (
      <ThemeProvider theme={katalonui_theme}>
        <Tooltip
          key={next()}
          label={this.getCheckboxTooltipLabel(
            this.hasAtLeastOneRowSelected(),
            true
          )}
          size="medium"
        >
          <Checkbox
            key={this.allRowsSelected()}
            id={`select-all-${entityType}-checkbox`}
            data-testid={`select-all-${entityType}-checkbox`}
            size="small"
            checked={this.allRowsSelected()}
            indeterminate={
              this.hasAtLeastOneRowSelected() &&
              !this.allRowsSelected()
            }
            onChange={this.handleSelectAll}
          />
        </Tooltip>
      </ThemeProvider>
    );
  }

  onHandleSortChange(sortColumn, currentSort) {
    this.setState({ currentSort, selectedRows: [] });
    this.dataLoaderRef.current.handleSearchSortChange(sortColumn);
  }

  updateData(dataUpdate) {
    const { data } = this.state;

    if (data !== dataUpdate) {
      this.setState({
        data: dataUpdate
      });
    }
  }

  mapDataRender(data) {
    return data.map((item) => ({ ...item, isSelected: this.isRowSelected(item) }));
  }

  renderData(data) {
    this.updateData(data);
    const dataRender = this.mapDataRender(data);
    const { useSortByColumn, renderBulkActions, columnMapping, isSupportMultipleSelect, entityType } = this.props;
    const { currentSort, selectedRows } = this.state;
    const newColumnMapping = [
      ...Arrays.insertIf(
        isSupportMultipleSelect && isSupportMultipleSelect(data),
        new MTableColumnDataMapping(
          this.renderSelectAll(),
          '',
          (name, row) => (
            <ThemeProvider theme={katalonui_theme}>
              <Tooltip
                key={next()}
                label={this.getCheckboxTooltipLabel(
                  this.isRowSelected(row),
                  false
                )}
                size="medium"
              >
                <Checkbox
                  key={this.isRowSelected(row)}
                  id={`select-${entityType}-${row.id}-checkbox`}
                  size="small"
                  data-testid={`select-${entityType}-${row.id}-checkbox`}
                  checked={this.isRowSelected(row)}
                  onChange={(e) => this.onSelectRow(e, row)}
                />
              </Tooltip>
            </ThemeProvider>
          ),
          undefined,
          'fit-content-column',
          undefined,
          '5%'
        )
      ),
      ...columnMapping];
    const newProps = { ...this.props, columnMapping: newColumnMapping };
    if (useSortByColumn) {
      return (
        <>
          {isSupportMultipleSelect && isSupportMultipleSelect(data) && selectedRows.length > 0 && renderBulkActions && renderBulkActions(selectedRows)}
          <TableSortCore data={dataRender} onColumnHeaderClick={this.onHandleSortChange} {...newProps} currentSort={currentSort} />
        </>
      );
    }
    return (
      <TableCore data={data} {...this.props} />
    );
  }

  render() {
    return (
      <DataLoader ref={this.dataLoaderRef} render={this.renderData} {...this.props} onPageChange={this.onPageChange} />
    );
  }
}

DataTable.propTypes = {
  sourceUrl: PropTypes.string,
  sourceFieldName: PropTypes.string,
  columnMapping: PropTypes.array,
  paginationMapping: PropTypes.object,
  hidePaging: PropTypes.bool,
  autoUpdate: PropTypes.bool,
  autoUpdateIntervalTime: PropTypes.number,
  defaultSearchConditions: PropTypes.array,
  defaultSort: PropTypes.array,
  transform: PropTypes.func,
  emptyMessage: PropTypes.string,
  loadingMessage: PropTypes.string,
  useSortByColumn: PropTypes.bool,
  onColumnHeaderClick: PropTypes.func,
  dataTableRef: PropTypes.func,
  onDataChanged: PropTypes.func,
  onSelectChange: PropTypes.func,
  renderBulkActions: PropTypes.func,
  isSupportMultipleSelect: PropTypes.func,
};

DataTable.defaultProps = {
  sourceUrl: '',
  sourceFieldName: 'content',
  columnMapping: [],
  paginationMapping: new MPaginationMapping(),
  hidePaging: false,
  autoUpdate: false,
  autoUpdateIntervalTime: 10000,
  defaultSearchConditions: [],
  defaultSort: [],
  emptyMessage: t('table#empty-message'),
  loadingMessage: t('table#loading-message'),
  useSortByColumn: false,
};

export default React.forwardRef((props, ref) => <DataTable {...props} forwardedRef={ref} ref={props.dataTableRef} />);
