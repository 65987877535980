import React from 'react';
import DefaultLayout from '../../components/DefaultLayout';
import PageComponent from '../../components/PageComponent';
import ObjectSummary from '../../components/summary/ObjectSummary';
import TestObjectEntityDataTable from '../../components/table/TestObjectEntityDataTable';
import { t } from '../../i18n/t';
import MContext from '../../models/MContext';
import TestObjectProjectStatistics from '../project_dashboard/components/TestObjectProjectStatistics';

class TestObjectEntities extends PageComponent {

  constructor(props) {
    super(props);
    this.projectId = MContext.projectId;
    this.teamId = MContext.teamId;
    this.project = MContext.project;
    this.meta.id = 'page-test-objects';
    this.meta.title = t('Project {{name}} - Test Objects', { name: this.project.name });
    this.renderHeader = this.renderHeader.bind(this);
    this.renderBody = this.renderBody.bind(this);
  }

  renderHeader() {
    const urlParams = {
      testObjectEntities: t('test_object_entities'),
      projectName: this.project.name,
    };
    return (
      <ObjectSummary params={urlParams} />
    );
  }

  renderBody() {
    return (
      <>
        <TestObjectProjectStatistics dataKey="totalTestObject" />
        <TestObjectEntityDataTable />
      </>
    );
  }

  render() {
    return (
      <DefaultLayout
        renderBody={this.renderBody}
        renderHeader={this.renderHeader}
      />
    );
  }
}

export default TestObjectEntities;
