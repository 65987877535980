import React from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import DefaultLayout from '../../components/DefaultLayout';
import PageComponent from '../../components/PageComponent';
import ObjectSummary from '../../components/summary/ObjectSummary';
import MContext from '../../models/MContext';
import Routers from '../../utils/Routes';
import { t } from '../../i18n/t';
import { IconGitRepo } from '../../images/CustomIcon';
import TestProjectConfigForm from './components/TestProjectConfigForm';
import Apis from '../../utils/Apis';
import http from '../../utils/http';

class TestProjectGit extends PageComponent {

  constructor(props) {
    super(props);
    this.teamId = MContext.teamId;
    this.projectId = MContext.projectId;
    this.project = MContext.project;
    this.testProjectId = MContext.testProjectId;
    this.isCreateTestProject = !this.testProjectId;

    this.state = {
      gitInfo: {},
    };

    if (this.isCreateTestProject) {
      this.meta.id = 'page-create-git-test-project';
      this.meta.title = t('git-repository');
    } else {
      this.meta.id = 'page-update-git-test-project';
    }

    this.renderObjectSummary = this.renderObjectSummary.bind(this);
    this.handleRedirect = this.handleRedirect.bind(this);
    this.renderGitIntegration = this.renderGitIntegration.bind(this);
    this.renderHeader = this.renderHeader.bind(this);
  }

  componentDidMount() {
    if (this.testProjectId) {
      this.getTestProject();
    }
  }

  getTestProject() {
    http.get(Apis.testProject(this.testProjectId))
      .then((responseJson) => {
        const testProject = responseJson;
        this.meta.title = t('git-repository#name', { name: testProject.name });
        const gitRepository = testProject.gitRepository;
        const gitInfo = {
          ...gitRepository,
          name: testProject.name,
          description: testProject.description
        };
        this.setState({
          gitInfo
        });
      });
  }

  renderObjectSummary() {
    let urlParams;
    if (this.testProjectId) {
      const { gitInfo } = this.state;
      urlParams = {
        testProjectId: this.testProjectId,
        testProjectName: gitInfo.name,
      };
    } else {
      urlParams = {
        codeRepo: t('test-projects'),
      };
    }
    urlParams = {
      ...urlParams,
      projectId: this.projectId,
      project: this.project.name,
      icon: <IconGitRepo />,
    };
    return (
      <ObjectSummary params={urlParams} />
    );
  }

  handleRedirect(testProjectId) {
    Routers.goToTestProjectPage(this.teamId, this.projectId, testProjectId);
  }

  renderGitIntegration() {
    const { gitInfo } = this.state;
    return (
      <Card>
        <CardBody>
          <Row>
            <Col sm="12" md="8" lg="6" xl="5">
              <TestProjectConfigForm
                handleRedirect={this.handleRedirect}
                gitRepository={gitInfo}
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  }

  renderHeader() {
    return this.renderObjectSummary();
  }

  render() {
    const { gitInfo } = this.state;
    if (this.testProjectId && !gitInfo.id) {
      return null;
    }

    return (
      <DefaultLayout
        renderHeader={this.renderHeader}
        renderBody={this.renderGitIntegration}
      />
    );
  }
}

export default TestProjectGit;
