import React from 'react';
import { Badge, Spinner } from 'reactstrap';
import classnames from 'classnames';
import {
  chain,
  flatten,
  get,
  groupBy,
  isArray, isEmpty,
  isNumber,
  isString,
  lowerCase,
  orderBy, reverse,
  startCase,
  toLower,
  uniq,
  uniqBy,
  map,
  sortBy,
  upperFirst,
  cloneDeep,
  sumBy,
  filter
} from 'lodash';
import { ListItemText, Divider, ListItem, ListItemIcon, Tooltip, IconButton, Typography, Grid, CircularProgress } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Icon from '@katalon-studio/katalon-ui/Icon';
import ReadMore from '../components/ReadMore';
import Time from '../utils/Moment';
import Routes from './Routes';
import { IconComments, IconExternalLink, IconStatus } from '../images/KitIcons';
import {
  IconStatusPassed,
  IconStatusFailed,
  IconStatusError,
  IconStatusIncomplete,
  IconWindows,
  IconLinux,
  IconMac,
  IconChrome,
  IconFirefox,
  IconIE,
  IconEdge,
  IconSafari,
  IconQueued,
  IconCancel,
  IconStatusOnline,
  IconStatusOffline,
  IconStatusSkipped,
  IconGitRepo,
  IconEdgeChromium,
  IconChromium,
  IconDuration,
  IconStartTime,
  IconKSE,
  IconRE, IconKubernetes, IconCircleCi,
  IconCucumberBlue,
  IconCucumberGreen,
  IconProfile,
  IconTestCloudTunnel,
  IconEmptyStatus,
  IconNotReadyStatus,
  IconReadyStatus,
  IconDefaultExecutionProfile,
  IconExecutionProfile,
  IconUnknownFile,
  IconStatusTerminate,
  IconIOS,
  IconAndroid,
  IconIpad,
  IconIphone,
  IconExclamationCircle,
  IconCheckKeyesGreen,
  IconMobileNativeApp,
  IconChromeHeadless,
  IconFirefoxHeadless,
  IconErrorStatusV2,
  IconPassedLastRun,
  IconPassedRun,
  IconFailedRun,
  IconErrorRun,
  IconIncompleteRun,
  IconSkippedRun,
  IconFailedLastRun,
  IconFolderTestCaseCloudStudio,
  IconWebService,
  IconTrendNA,
  IconTrendUp,
  IconTrendDown,
  IconTrendUpRed,
  IconTrendDownGreen,
  IconImpactedLocations,
  IconTotalRunsFailed,
  IconNotReady,
  IconConfirmRelease,
  IconStatusImporting,
  IconMultiPlatform,
  IconMultiPlatformGrey,
  IconLowAvailability
} from '../images/CustomIcon';
import Text from '../utils/Text';
import XrayIssueTestIcon from '../../images/icons/xray-test.png';
import XrayIssueTestExecutionIcon from '../../images/icons/xray-test-execution.png';
import XrayIssueTestPlanIcon from '../../images/icons/xray-test-plan.png';
import XrayIssueTestSetIcon from '../../images/icons/xray-test-set.png';
import XrayIssuePreCondition from '../../images/icons/xray-pre-condition.svg';
import XrayIssueSubTestExecution from '../../images/icons/xray-sub-test-execution.svg';
import { getExceptionClass, getExceptionMessage } from '../pages/test_reports/test_result_reports/utils';
import MContext from '../models/MContext';

import { OSDetector, BrowserDetector, DeviceDetector } from './Environment';
import { t } from '../i18n/t';
import {
  dayOption,
  monthOption,
  OrganizationFeature,
  SsoOption,
  CloudType,
  weekOption,
  UnicodeCharater,
  ALL_BROWSERS_TESTCLOUD_AGENT,
  BrowserName,
  OsName,
  DeviceType,
  ReleaseStatus,
  XRAY_ISSUE_TYPE,
  NOT_AVAILABLE,
  UploadAppStatus,
  JIRA_STATUS_CATEGORY,
  KeyesExecutionStatus,
  SessionTypeDecoratorMapping, DevicePoolType,
} from './Constants';
import ImageProfile from '../components/avatar/ImageProfile';
import MomentFormatProvider from './MomentFormatProvider';
import colors from '../../scss/colors.scss';
import MFlags from '../models/MFlags';

import ReleaseStatusLabel from '../components/ReleaseStatusLabel';
import EditedExecutionTestResultTooltip from '../components/EditedExecutionTestResultTooltip';
import TextMiddleTruncate from '../components/TextMiddleTruncate';
import TooltipComponent from '../components/TooltipComponent';
import { isMobile } from '../components/smarttestscheduling/services/testcloud';
import StatusBarChart from '../components/chart/status-bar/StatusBarChart';
import ExternalLinkButton from '../components/ExternalLinkButton';
import StatusBarDetail from '../components/StatusBarDetail';
import Link from '../components/Link';
import KatalonStorageIcon from '../../images/icons/katalonui/KatalonStorageIcon';
import ErrorDetailPopper from '../pages/project_dashboard_v2/components/ErrorDetailPopper';
import TooltipErrorDetail from '../pages/project_dashboard_v2/components/TooltipErrorDetail';
import { LabelChip } from '../components/LabelChip';
import LoadingIcon from '../../images/icons/katalonui/LoadingIcon';
import ZipRepositoryIcon from '../../images/icons/katalonui/ZipRepositoryIcon';
import StringHelper from './StringHelper';

const MAX_DISPLAY_REQUIREMENTS = 3;

const statusCountDecorator = (status) =>
  function (name, row) {
    const count = get(row, name, 0);
    const statusCapitalized = status ? startCase(toLower(status)) : status;
    if (status.toLowerCase() === 'passed') {
      return <span title={statusCapitalized} className="text-passed-color">{count}</span>;
    } else {
      if (count === 0) {
        return <span title={statusCapitalized} className="text-gray">{count}</span>;
      } else {
        return (
          <Badge
            pill
            title={statusCapitalized}
            className={`status-badge status-badge-${(status) ? status.toLowerCase() : status}`}
          >
            {count}
          </Badge>
        );
      }
    }
  };

const keyesStatusCountDecorator = (status) =>
  function (name, row) {
    const count = get(row, name, 0);
    const executionStatus = get(row, 'status');
    const statusCapitalized = status ? startCase(toLower(status)) : status;
    const result = executionStatus === KeyesExecutionStatus.ANALYSING ? '-' : count;

    if (status.toLowerCase() === 'passed') {
      return <span title={statusCapitalized} className="text-passed-color">{result}</span>;
    } else if (count === 0) {
      return <span title={statusCapitalized} className="text-gray">{result}</span>;
    } else {
      return (
        <Badge
          pill
          title={statusCapitalized}
          className={`status-badge status-badge-${(status) ? status.toLowerCase() : status}`}
        >
          {result}
        </Badge>
      );
    }
  };

const failedOnTotalDecorator = (passed, failed, labelFailed, labelTotal) =>
  function (name, row) {
    let passedData = get(row, passed);
    let failedData = get(row, failed);

    if (passedData >= 0 && failedData === undefined) {
      failedData = 0;
    } else if (failedData >= 0 && passedData === undefined) {
      passedData = 0;
    }

    const totalData = passedData + failedData;
    if (totalData >= 0) {
      return (
        <div className="total-data">
          <div title={labelFailed}>
            {failedData > 0 && <Badge pill className="status-badge status-badge-failed">{failedData}</Badge>}
            {failedData === 0 && <span className="text-gray">{failedData}</span>}
          </div>
          <div title={labelTotal}>
            /{totalData}
          </div>
        </div>
      );
    }
    return null;
  };

const buildStatusBarTitle = (header, payload, totalTests) => (
  <div className="custom-test-run-tooltip">
    <Typography variant="h6" className="mb-2">
      {header}
    </Typography>
    { payload.map((item) => (
      <div className="d-flex justify-content-between mb-1">
        <div className="d-flex mr-4 align-items-center">
          <div className={`icon-rectangle icon-rectangle__${item.color}`} />
          {item.label}
        </div>
        <div>
          {item.value}
        </div>
      </div>
    ))}
    <div className="d-flex justify-content-between">
      <div className="d-flex mr-4 align-items-center">
        <Typography variant="h6">
          {t('total')}
        </Typography>
      </div>
      <div>
        {totalTests}
      </div>
    </div>
  </div>
);

export const defectNumberDecorator = (number) => <span className="badge-defect-number">{number}</span>;

export const getRows = (name, row) => get(row, name);

export const filterDistinctTestCasesOfDefect = (row) => uniqBy(row.testResults, (testResult) => testResult.testCase.id).map((testResults) => testResults.testCase);

export const filterDistinctTestResultsOfDefect = (name, row) => getRows(name, row);

export const filterDistinctRequirementsOfDefect = (row) => chain(row.testResults)
  .map('testCase.externalIssues')
  .flatten()
  .uniqBy((requirement) => requirement?.id)
  .value();

export const statusRunDecorator = (status) => {
  status = status.toUpperCase();
  const iconTitle = startCase(toLower(status));
  let statusIcon;
  switch (status) {
    case 'PASSED':
      statusIcon = <IconPassedRun />;
      break;
    case 'FAILED':
      statusIcon = <IconFailedRun />;
      break;
    case 'ERROR':
      statusIcon = <IconErrorRun />;
      break;
    case 'INCOMPLETE':
      statusIcon = <IconIncompleteRun />;
      break;
    case 'SKIPPED':
      statusIcon = <IconSkippedRun />;
      break;
    default:
      break;
  }
  return <span title={iconTitle} className="icon-run">{statusIcon}</span>;
};

export const statusLastRunDecorator = (status) => {
  status = status.toUpperCase();
  const iconTitle = startCase(toLower(status));
  let statusIcon = null;
  switch (status) {
    case 'PASSED':
      statusIcon = <IconPassedLastRun />;
      break;
    case 'FAILED':
      statusIcon = <IconFailedLastRun />;
      break;
    case 'ERROR':
      statusIcon = <IconErrorStatusV2 className="icon-error-status-v2" />;
      break;
    case 'INCOMPLETE':
      statusIcon = <IconStatusTerminate />;
      break;
    case 'SKIPPED':
      statusIcon = <IconStatusSkipped />;
      break;
    default:
      break;
  }
  return <span title={iconTitle} className="icon-last-run">{statusIcon}</span>;
};

const DecoratorConstants = {
  defaultDecorator: (name, row) => <div className="text-wrap">{get(row, name, '')}</div>,
  status: (status, style) => {
    status = status.toUpperCase();
    let iconTitle = startCase(toLower(status));
    let statusIcon;
    switch (status) {
      case 'RUNNING':
      case 'ANALYSING':
        statusIcon = <Spinner className="align-middle" color="primary" size="sm" />;
        iconTitle = t('visual-comparison#title@analyzing');
        break;
      case 'SUCCESS':
      case 'PASSED':
        statusIcon = <IconStatusPassed />;
        break;
      case 'FAILED':
        statusIcon = <IconStatusFailed />;
        break;
      case 'ERROR':
        statusIcon = <IconStatusError />;
        break;
      case 'INCOMPLETE':
        statusIcon = <IconStatusIncomplete />;
        break;
      case 'QUEUED':
        statusIcon = <IconQueued />;
        break;
      case 'CANCELED':
        statusIcon = <IconCancel />;
        break;
      case 'SKIPPED':
        statusIcon = <IconStatusSkipped />;
        break;
      case 'TERMINATE':
        statusIcon = <IconStatusTerminate />;
        iconTitle = t('incomplete-test-run');
        break;
      case 'IMPORTING':
        statusIcon = MFlags.changeImportingStatusEnabled ?
          <IconStatusImporting /> :
          <IconStatus className={`icon-status icon-status-${(status) ? status.toLowerCase() : status}`} />;
        break;
      default:
        statusIcon = <IconStatus className={`icon-status icon-status-${(status) ? status.toLowerCase() : status}`} />;
        break;
    }
    return <span title={iconTitle} className={style || ''}>{statusIcon}</span>;
  },

  statusBadge: (status) => {
    let titleStatus;
    switch (status) {
      case 'RUNNING':
        return <Spinner color="primary" size="sm" />;
      case 'ANALYSING':
        titleStatus = t('visual-comparison#title@analyzing');
        break;
      default:
        titleStatus = status;
        break;
    }
    return (titleStatus && (
      <Badge className={`status-badge status-badge-${titleStatus.toLowerCase()}`}>
        {titleStatus.toLowerCase()}
      </Badge>
    ));
  },

  statusCountDecorator: (status) => statusCountDecorator(status),

  statusDecorator: (name, row) => {
    const value = get(row, name);
    if (value) {
      return (
        <span title={startCase(toLower(value))}>
          {DecoratorConstants.status(value)}
        </span>
      );
    } else {
      return '';
    }
  },
  executionTestResultStatusDecorator: (name, row) => {
    const route = new Routes({
      executionId: get(row, 'execution.order'),
      executionTestResultId: row.urlId
    });
    const commentLink = route.execution_test_result_comment_link;
    const { originalStatus, status, lastChangedBy } = row;
    return (
      <>
        {DecoratorConstants.statusDecorator(name, row)}
        {row.statusEdited &&
          <span className="ml-2">
            <EditedExecutionTestResultTooltip
              link={commentLink}
              originalStatus={originalStatus}
              latestStatus={status}
              changedBy={lastChangedBy.fullName}
            />
          </span>}
      </>
    );
  },
  category: (category) => (
    <Badge className={`category-badge category-badge-${(category) ? category.toLowerCase() : category}`}>
      {category.toLowerCase()}
    </Badge>
  ),
  categoryDecorator: (name, row) => {
    const value = get(row, name);
    if (value) {
      return DecoratorConstants.category(value);
    } else {
      return '';
    }
  },

  /**
   * @deprecated due to TOS-1891
   * Remove incidentEnabled flag
   * This decorator is only used in execution test result data table
   * To decorate for task info in link column
   */
  incidentDecorator: (name, row) => {
    const value = get(row, name);

    const incidents = value.map((inc) => {
      const routes = new Routes({ incidentOrder: inc.order });
      return (
        <Link
          href={routes.incident_link}
          key={inc.order}
        >{inc.order}
        </Link>
      );
    });
    if (incidents.length === 0) {
      return null;
    }
    return (
      <>
        {incidents.reduce((prev, curr) => [prev, ', ', curr])}
      </>
    );
  },

  productAccessDecorator: (name, row) => {
    const features = get(row, name);
    return (
      <>
        {
          features.map((item) => {
            let iconFeature;
            if (item.feature === OrganizationFeature.KSE) iconFeature = <IconKSE key={item.feature} />;
            if (item.feature === OrganizationFeature.ENGINE) iconFeature = <IconRE key={item.feature} />;
            return iconFeature;
          })
        }
      </>
    );
  },

  loginOptionDecorator: (name, row) => {
    const ssoOptions = get(row, name);
    if (ssoOptions === null) {
      return null;
    }
    return (
      <>
        {
          ssoOptions.map((ssoOption) => {
            if (ssoOption.option === SsoOption.BASIC_AUTH) {
              return 'Username & Password';
            } else if (ssoOption.option === SsoOption.SSO_AUTH) {
              return 'SSO';
            }
            return '';
          }).join(', ')
        }
      </>
    );
  },

  timeDecoratorValue: (value, timezone) => {
    if (value) {
      return Time.format(value, undefined, undefined, timezone);
    } else {
      return '';
    }
  },

  dateDecorator: (name, row) => {
    const value = get(row, name);
    if (value) {
      return Time.formatDate(value, Time.DATE_FORMAT);
    } else {
      return '';
    }
  },

  fullDateDecorator: (name, row) => {
    const value = get(row, name);
    if (value) {
      return Time.format(value, Time.OVER_A_DAY_FORMAT);
    } else {
      return NOT_AVAILABLE;
    }
  },

  textMiddleTruncateDecorator: (value, width, title, className) => (
    <TextMiddleTruncate width={width} title={title} className={className}>
      <span title={value}>{value}</span>
    </TextMiddleTruncate>),

  textEndLineTruncateDecorator: (value, width = 100) => (
    <TooltipComponent
      text={value}
      placement="bottom-start"
      arrow
      followCursor={undefined}
      open={undefined}
      disableHoverListener={undefined}
      customClasses={undefined}
    >
      <span className={`end-line-truncate w-${width}`}>{value}</span>
    </TooltipComponent>
  ),

  addSourceOfTestCaseAndTestSuite: (path, testProject) => {
    if (testProject) {
      return `${testProject.name}/${path}`;
    }
    return `${t('uploaded-data')}/${path}`;
  },

  monthDateDecorator: (name, row) => {
    const value = get(row, name);
    if (value) {
      return Time.formatDate(value, Time.LAST_MONTHS_FORMAT);
    } else {
      return '';
    }
  },

  timeDecorator: (name, row) => {
    const value = get(row, name);
    const project = get(row, 'project');
    let timezone = '';
    if (project && project.timezone) {
      timezone = project.timezone;
    }
    return DecoratorConstants.timeDecoratorValue(value, timezone);
  },

  durationDecorator: (name, row) => {
    const value = get(row, name);
    if (isNumber(value)) {
      return Time.duration(value);
    } else {
      return '';
    }
  },

  lastRunDecorator: (rows) => {
    const reversedRows = reverse(cloneDeep(rows));
    return reversedRows.map((row, index) => {
      if (index === reversedRows.length - 1) {
        return statusLastRunDecorator(row.status);
      } else {
        return statusRunDecorator(row.status);
      }
    });
  },

  displayValue: (name, row) => get(row, name) || NOT_AVAILABLE,

  displayAppSizeValue: (name, row) => {
    const bytes = get(row, name) || 0;
    if (bytes === 0) return NOT_AVAILABLE;

    const MB = bytes / (1000 * 1000);
    if (MB >= 1) {
      return `${MB.toFixed(1)} MB`;
    }

    const KB = bytes / 1000;
    return `${KB.toFixed(1)} KB`;
  },

  appStatusDecorator: (name, row) => {
    const value = get(row, name);
    if (value === UploadAppStatus.ERROR) {
      return <span className="app-status error" title={row?.reason || value}><IconExclamationCircle /> {value}</span>;
    }
    if (value === UploadAppStatus.NEW || value === UploadAppStatus.UPLOADING) {
      return (
        <span className="app-status processing" title={UploadAppStatus.PROCESSING}>
          <Spinner color="#174291" size="sm" /> {UploadAppStatus.PROCESSING}
        </span>);
    }
    if (value === UploadAppStatus.READY) {
      return <span className="app-status ready" title={value}><IconCheckKeyesGreen /> {value}</span>;
    }
    return '';
  },

  apiDurationDecorator: (name, row) => {
    const value = get(row, name);
    if (isNumber(value)) {
      return Time.duration(value, MomentFormatProvider.inferMedianFormat());
    } else {
      return '';
    }
  },

  durationWithIconDecorator: (name, row) => (
    <span className="d-block">
      <IconDuration title={t('duration')} className="mr-1" />
      {DecoratorConstants.durationDecorator(name, row)}
    </span>
  ),

  capitalizeDecorator: (name, row) => {
    const value = lowerCase(get(row, name));
    return <span className="text-capitalize">{value}</span>;
  },

  localAgentDetailDecorator: (name, row, plainText = false) => {
    if (MFlags.moveAgentToOrgLevelPhase2Enabled) {
      return DecoratorConstants.organizationLocalAgentDetailDecorator(name, row, plainText);
    }
    const content = <div className="text-wrap">{get(row, name)}</div>;
    if (!row.deleted && !plainText) {
      const routes = new Routes({ agentId: row.id });
      return DecoratorConstants.linkDecorator(content, routes.agent_details_link);
    }
    return content;
  },

  organizationLocalAgentDetailDecorator: (name, row, plainText = false) => {
    const content = <div className="text-wrap">{get(row, name)}</div>;
    const organizationId = MContext.organizationId || MContext.team.organizationId;
    if (!plainText) {
      const routes = new Routes({ agentId: row.id, organizationId });
      return DecoratorConstants.linkDecoratorWithoutSPA(content, routes.organization_local_agent_link);
    }
    return content;
  },

  testCloudAgentDetailDecorator: (name, row, plainText = false, displayBrowserVersionType = true) => {
    if (row.os !== 'Total') {
      const browserVersion = row.browserVersion ? row.browserVersion : '';
      const headless = `${row.headless ? ' headless' : ''}`;
      let title = `TestCloud ${DecoratorConstants.displayNameByOS(row.os)}`;
      if (isMobile(row.os)) {
        title += ` ${row.osVersion || ''} ${row.deviceName || ''}`;
        title += ` ${row.appName || ''}`;
      } else if (row.browser === ALL_BROWSERS_TESTCLOUD_AGENT.browser) {
        title += ' (Default)';
      } else {
        title += ` ${DecoratorConstants.displayNameByBrowser(row.browser)}${headless} ${browserVersion}`;
      }

      const { appVersion, appVersionCode, devicePool, udid, browserVersionType } = row?.metadata || {};
      const browserVersionBadge = displayBrowserVersionType && browserVersionType && DecoratorConstants.iconBrowserVersionType(browserVersionType);
      const appVersionBadge = appVersion && DecoratorConstants.appVersionDecorator(appVersion, t('app#version-name-column-tooltip'));
      const appVersionCodeBadge = appVersionCode && DecoratorConstants.appVersionDecorator(appVersionCode, t('app#version-code-column-tooltip'));
      const privateDeviceBadge = (devicePool === DevicePoolType.PRIVATE_CLOUD_POOL) && DecoratorConstants.iconPrivateDeviceWithUdid(udid);
      const content = (
        <div className="text-wrap">
          {title} {appVersionBadge} {appVersionCodeBadge} {privateDeviceBadge} {browserVersionBadge}
        </div>
      );

      if (!plainText) {
        const routes = new Routes({ testCloudAgentId: row.id });
        return DecoratorConstants.linkDecorator(content, routes.test_cloud_agent_link);
      }
      return content;
    }
    return null;
  },

  displayNameByBrowser: (browser) => {
    switch (browser) {
      case 'msedge':
        return 'Edge Chromium';
      default:
        return browser ? startCase(browser) : '';
    }
  },

  displayNameWebService: () => t('browser-type#web-services'),

  displayNameWebServiceLinuxDefault: () => t('browser-type#web-services-linux-default'),

  displayNameByOS: (os) => {
    if (OsName.IOS.toLowerCase() === os?.toLowerCase()) {
      return OsName.IOS;
    } else if (OsName.MacOS.toLowerCase() === os?.toLowerCase()) {
      return OsName.MacOS;
    }
    return os ? upperFirst(os.toLowerCase()) : '';
  },

  displayNameByDevice: (deviceName) => {
    if (deviceName.toLowerCase() === DeviceType.iPhone.toLowerCase()) {
      return DeviceType.iPhone;
    }
    if (deviceName.toLowerCase() === DeviceType.iPad.toLowerCase()) {
      return DeviceType.iPad;
    }
    return deviceName ? startCase(lowerCase(deviceName)) : '';
  },

  displayNameByDeviceSpec: (deviceSpec) => {
    if (deviceSpec.includes('osVersion==*')) {
      return 'Any OS Version';
    }
    if (deviceSpec.includes('deviceFamily==*')) {
      return 'Any Device Type';
    }
    return 'Any Device';
  },

  k8sAgentDetailDecorator: (name, row, plainText = false) => {
    if (MFlags.moveAgentToOrgLevelPhase2Enabled) {
      return DecoratorConstants.organizationK8sAgentDetailDecorator(name, row, plainText);
    }
    const content = <div className="text-wrap">{get(row, name)}</div>;
    if (!plainText) {
      const routes = new Routes({ k8sAgentId: row.id });
      return DecoratorConstants.linkDecorator(content, routes.k8s_agent_link);
    }
    return content;
  },

  organizationK8sAgentDetailDecorator: (name, row, plainText = false) => {
    const content = <div className="text-wrap">{get(row, name)}</div>;
    const organizationId = MContext.organizationId || MContext.team.organizationId;
    if (!plainText) {
      const routes = new Routes({ k8sAgentId: row.id, organizationId });
      return DecoratorConstants.linkDecoratorWithoutSPA(content, routes.organization_k8s_agent_link);
    }
    return content;
  },

  circleCIAgentDetailDecorator: (name, row, plainText = false) => {
    if (MFlags.moveAgentToOrgLevelPhase2Enabled) {
      return DecoratorConstants.organizationCircleCIAgentDetailDecorator(name, row, plainText);
    }
    const content = <div className="text-wrap">{get(row, name)}</div>;
    if (!plainText) {
      const routes = new Routes({ circleCIAgentId: row.id });
      return DecoratorConstants.linkDecorator(content, routes.circle_ci_agent_link);
    }
    return content;
  },

  organizationCircleCIAgentDetailDecorator: (name, row, plainText = false) => {
    const content = <div className="text-wrap">{get(row, name)}</div>;
    const organizationId = MContext.organizationId || MContext.team.organizationId;
    if (!plainText) {
      const routes = new Routes({ circleCIAgentId: row.id, organizationId });
      return DecoratorConstants.linkDecoratorWithoutSPA(content, routes.organization_circle_ci_agent_link);
    }
    return content;
  },

  agentsDecorator: (name, row, plainText = false) => {
    if (row.cloudType === 'LOCAL_AGENT') {
      return (
        <>
          {
            orderBy(get(row, name), ['lastPing', 'id'], ['desc', 'asc'])
              .map((agent) => DecoratorConstants.localAgentDetailDecorator('name', agent, plainText))
          }
        </>
      );
    }

    if (row.cloudType === CloudType.TEST_CLOUD_AGENT && row.configType === 'TSC'
        && !isEmpty(get(row, 'testCloudTestSuiteCollectionAgents'))) {
      return (
        <>
          {
            get(row, 'testCloudTestSuiteCollectionAgents').map((agent) => DecoratorConstants.testCloudAgentDetailDecorator('name', agent?.testCloudAgent, plainText, false))
          }
        </>
      );
    }

    if (row.cloudType === CloudType.TEST_CLOUD_AGENT) {
      return (
        <>
          {
            get(row, 'testCloudAgents').map((agent) => DecoratorConstants.testCloudAgentDetailDecorator('name', agent, plainText, false))
          }
        </>
      );
    }

    if (row.cloudType === 'K8S_AGENT') {
      return (
        <>
          {
              get(row, 'k8sAgents').map((agent) => DecoratorConstants.k8sAgentDetailDecorator('name', agent, plainText))
            }
        </>
      );
    }

    if (row.cloudType === 'CIRCLE_CI_AGENT') {
      return (
        <>
          {
            get(row, 'circleCIAgents').map((agent) => DecoratorConstants.circleCIAgentDetailDecorator('name', agent, plainText))
          }
        </>
      );
    }

    return (<div className="text-wrap">TestOps Cloud</div>);
  },

  agentDecorator: (name, row) => {
    let agent = null;
    let content = null;
    if (row.agent) {
      agent = row.agent;
      content = DecoratorConstants.localAgentDetailDecorator(name, agent);
    }
    if (row.k8sAgent) {
      agent = row.k8sAgent;
      content = DecoratorConstants.k8sAgentDetailDecorator(name, agent);
    }
    if (row.circleCiAgent) {
      agent = row.circleCiAgent;
      content = DecoratorConstants.circleCIAgentDetailDecorator(name, agent);
    }
    if (row.testCloudAgent) {
      agent = row.testCloudAgent;
      content = DecoratorConstants.testCloudAgentDetailDecorator(name, agent);
    }
    if (agent) {
      return content;
    }
    return (<div className="text-wrap">TestOps Cloud</div>);
  },

  agentJobDecorator: (agent, typeAgent) => {
    let content = null;
    if (typeAgent === 'localAgent') {
      content = DecoratorConstants.localAgentDetailDecorator('name', agent);
    }
    if (typeAgent === 'circleCiAgent') {
      content = DecoratorConstants.circleCIAgentDetailDecorator('name', agent);
    }
    if (typeAgent === 'k8sAgent') {
      content = DecoratorConstants.k8sAgentDetailDecorator('name', agent);
    }
    return (
      <ListItem key={agent.id} button component="a">
        <ListItemIcon>{DecoratorConstants.iconByAgentDecorator(agent, typeAgent)}</ListItemIcon>
        <ListItemText> {content}</ListItemText>
      </ListItem>
    );
  },

  iconByAgentDecorator: (agent, typeAgent) => {
    if (typeAgent === 'localAgent') {
      const osVersion = agent.os;
      return DecoratorConstants.platform({ osVersion });
    }
    if (typeAgent === 'circleCiAgent') {
      return <IconCircleCi title="Circle CI" />;
    }
    if (typeAgent === 'k8sAgent') {
      return <IconKubernetes title="Kubernetes" />;
    }
    return null;
  },

  agentStatusDecorator: (active) => {
    if (active) {
      return <IconStatusOnline />;
    } else {
      return <IconStatusOffline />;
    }
  },

  abnormalDecorator: (name, row) => {
    const value = get(row, name);
    if (value) {
      return <span title={t('test_objects#abnormal-title')}><IconStatusError /></span>;
    }
    return null;
  },

  percentageDecorator: (name, row) => {
    const value = get(row, name);
    if (isNumber(value)) {
      return (<div className="text-wrap">{value} %</div>);
    } else {
      return '';
    }
  },

  errorMessageDecorator: (name, row) => {
    const value = get(row, name);
    if (isString(value)) {
      const content = value.replace('com.kms.katalon.core.exception.StepFailedException: ', '');
      return (<pre className="text-wrap">{content} %</pre>);
    } else {
      return '';
    }
  },

  errorDecorator: (name, row) => {
    const value = get(row, name);
    if (isString(value)) {
      return (<pre className="text-failed-color">{value}</pre>);
    } else {
      return '';
    }
  },

  totalTestRunDecorator: (name, row) => {
    const value = get(row, name, 0);
    return <span title="Test Results">{value}</span>;
  },

  keyesTotalTestRunDecorator: (name, row) => {
    const status = get(row, 'status');
    let value = null;

    if (status !== KeyesExecutionStatus.ANALYSING) {
      value = get(row, name, 0);
    } else {
      value = '-';
    }

    return <span title={t('test_results')}>{value}</span>;
  },

  statusBarTestRunDecorator: (name, row) => {
    const {
      totalPassedTests,
      totalFailedTests,
      totalErrorTests,
      totalIncompleteTests,
      totalSkippedTests,
      totalTests
    } = row;

    const payload = [
      {
        value: totalPassedTests,
        color: 'passed',
        label: t('totalPassedTests'),
        icon: <IconStatusPassed />
      },
      {
        value: totalFailedTests,
        color: 'failed',
        label: t('totalFailedTests'),
        icon: <IconStatusFailed />
      },
      {
        value: totalErrorTests,
        color: 'error',
        label: t('totalErrorTests'),
        icon: <IconErrorStatusV2 className="icon-error-status-v2" />
      },
      {
        value: totalIncompleteTests,
        color: 'incomplete',
        label: t('totalIncompleteTests'),
        icon: <IconStatusTerminate />
      },
      {
        value: totalSkippedTests,
        color: 'skipped',
        label: t('totalSkippedTests'),
        icon: <IconStatusSkipped />
      },
    ];

    const style = makeStyles(() => ({
      tooltip: {
        fontWeight: '300',
        lineHeight: '1rem',
        fontSize: '0.75rem',
      }
    }));
    const tooltipTitle = buildStatusBarTitle(t('testCaseStatus'), payload, totalTests);

    return (
      <TooltipComponent text={tooltipTitle} customClasses={style} placement="bottom">
        <div className="pt-1">
          <StatusBarChart
            data={payload}
            showLegend={false}
            showTooltip={false}
            customClassName={
              MFlags.displayTestCaseNumberEnabled
                ? 'status-bar-v2__test-run-progress-fixed-width'
                : 'status-bar-v2__test-run-progress'
            }
          />
          {
            MFlags.displayTestCaseNumberEnabled &&
            <div className="pt-3">
              <StatusBarDetail data={payload} />
            </div>
          }
        </div>
      </TooltipComponent>
    );
  },

  totalTestCaseDecorator: (name, row) => {
    const value = get(row, name, 0);
    return <span title="Test Cases">{value}</span>;
  },

  passedBadgeDecorator: (text) => <Badge className="bg-success badge-circle-sm" title="Passed">{text}</Badge>,
  failedBadgeDecorator: (text) => <Badge className="bg-danger badge-circle-sm" title="Failed">{text}</Badge>,
  errorBadgeDecorator: (text) => <Badge className="bg-warning badge-circle-sm" title="Error">{text}</Badge>,
  incompleteBadgeDecorator: (text) => <Badge className="bg-gray badge-circle-sm" title="Incomplete">{text}</Badge>,
  skippedBadgeDecorator: (text) => <Badge className="bg-skipped badge-circle-sm" title="Skipped">{text}</Badge>,
  unresolvedBadgeDecorator: (text) => <Badge className="bg-warning badge-circle-sm" title="Unresolved">{text}</Badge>,
  statusBadgeDecorator: (status, text) => {
    switch (status.toLowerCase()) {
      case 'passed':
        return DecoratorConstants.passedBadgeDecorator(text);
      case 'failed':
        return DecoratorConstants.failedBadgeDecorator(text);
      case 'error':
        return DecoratorConstants.errorBadgeDecorator(text);
      case 'incomplete':
        return DecoratorConstants.incompleteBadgeDecorator(text);
      default:
        return null;
    }
  },

  assertionDecorator: failedOnTotalDecorator('passedAssertion', 'failedAssertion', t('failed_assertions'), t('total_assertions')),
  releaseCountDecorator: failedOnTotalDecorator('releaseStatistics.totalPassed', 'releaseStatistics.totalFailed', t('failed'), t('total')),
  buildCountDecorator: failedOnTotalDecorator('buildStatistics.totalPassed', 'buildStatistics.totalFailed', t('failed'), t('total')),
  platformCountDecorator: failedOnTotalDecorator('PASSED', 'FAILED', t('failed'), t('total')),
  passedCountDecorator: statusCountDecorator('passed'),
  failedCountDecorator: statusCountDecorator('failed'),
  errorCountDecorator: statusCountDecorator('error'),
  incompleteCountDecorator: statusCountDecorator('incomplete'),
  skippedCountDecorator: statusCountDecorator('skipped'),
  unresolvedCountDecorator: statusCountDecorator('unresolved'),
  onlineStatusDecorator: statusCountDecorator('online'),
  offlineStatusDecorator: statusCountDecorator('offline'),
  activeStatusDecorator: statusCountDecorator('active'),
  inactiveStatusDecorator: statusCountDecorator('inactive'),
  keyesPassedCountDecorator: keyesStatusCountDecorator('passed'),
  keyesFailedCountDecorator: keyesStatusCountDecorator('failed'),
  keyesUnresolvedCountDecorator: keyesStatusCountDecorator('unresolved'),

  activeBadgeDecorator: (name, row) => {
    const isClosed = get(row, name);
    if (isClosed) {
      return <Badge pill className="bg-gray" title={t('badge#closed')}>{t('badge#closed')}</Badge>;
    }
    return <Badge pill className="bg-passed" title={t('badge#active')}>{t('badge#active')}</Badge>;
  },

  /**
   * Decorate for column's value in table
   * @param {String} name - The key name of value in object.
   * @param {Object} row - The row contains object value.
   * @param {String} link - The link to redirect when click.
   * @param {String} title - The title of value when hover.
   * @param {String} className - The css classname.
   * @param {Object} trackingProps - The object contains traking data.
   * @param {boolean} isTruncate - Does need truncate text?
   * Ex: trackingProps = {'data-trackid': "sample-trackid", 'data-groupid': "sample-group"}
   * @returns
   */
  idDecorator: (name, row, link, title, className, trackingProps, isTruncate) => {
    const value = get(row, name) || UnicodeCharater.NO_BREAK_SPACE;
    if (MContext.organizationId) {
      return (
        <a className={`d-block ${className && className}`} title={title} href={link} {...trackingProps}>
          {isTruncate ? DecoratorConstants.textMiddleTruncateDecorator(value) : value}
        </a>);
    } else {
      return (
        <Link className={`d-block ${className || ''}`} title={title} href={link} {...trackingProps}>
          {isTruncate ? DecoratorConstants.textMiddleTruncateDecorator(value) : value}
        </Link>);
    }
  },

  idCloudStudioDecorator: (name, row, link, title) => {
    const value = get(row, name) || UnicodeCharater.NO_BREAK_SPACE;
    return (
      <div className="d-inline-flex">
        <IconFolderTestCaseCloudStudio className="mr-1 fa-lg" title={t('cloud-studio')} />
        <Link title={title} href={link}>
          {value}
        </Link>
      </div>
    );
  },


  idWithoutSPADecorator: (name, row, link, title, className) => {
    const value = get(row, name) || UnicodeCharater.NO_BREAK_SPACE;
    return (
      <a className={classnames('d-block', className)} title={title} href={link}>
        {value}
      </a>);
  },

  appDecorator: (name, row) => {
    const value = get(row, name);
    if (value) {
      return (
        <p className="app-name">
          {DecoratorConstants.textMiddleTruncateDecorator(value)}
        </p>);
    }
    return (<p className="app-name">{row?.fileName}</p>);
  },

  mobilePlatformDecorator: (name, row) => {
    const value = get(row, name);
    if (value) {
      return (
        <>{DecoratorConstants.iconByOS(value)}<span className="m-2">{DecoratorConstants.displayNameByOS(value)}</span>
        </>
      );
    }
    return NOT_AVAILABLE;
  },

  /**
   * Decorate for column's order value in table
   * @param {String} name - The key name of value in object.
   * @param {Object} row - The row contains object value.
   * ex: name: orderSort - value: 1
   * @returns
   */
  orderDecorator: (name, row) => (<div>{get(row, name)}</div>),

  releaseStatusDecorator: (name, row) => {
    const releaseStatus = get(row, name);
    if (releaseStatus === ReleaseStatus.READY) {
      return (<IconReadyStatus title={t(ReleaseStatus.READY)} />);
    }
    if (releaseStatus === ReleaseStatus.NOT_READY) {
      return (<IconNotReadyStatus title={t(ReleaseStatus.NOT_READY)} />);
    }
    return (<IconEmptyStatus title={t(ReleaseStatus.EMPTY)} />);
  },

  idDecoratorWithHandler: (name, row, handler, title, className) =>
    (<Link className={`d-block ${className || ''}`} title={title} href="#" onClick={() => handler(row)}>{get(row, name) || UnicodeCharater.NO_BREAK_SPACE}</Link>),

  testCloudIdDecorator: (name, row, link, title, className) => {
    const useTestCloudProxyTunnel = row.enabledTestCloudTunnel;
    if (useTestCloudProxyTunnel) {
      return (
        <Link className={`d-block ${className || ''}`} title={title} href={link}>
          {DecoratorConstants.renderTestCloudTunnelIcon()}
          {get(row, name) || UnicodeCharater.NO_BREAK_SPACE}
        </Link>);
    }
    return (<Link className={`d-block ${className || ''}`} title={title} href={link}>{get(row, name) || UnicodeCharater.NO_BREAK_SPACE}</Link>);
  },

  renderTestCloudTunnelIcon: () => (
    <TooltipComponent text={t('test-cloud-tunnel')} placement="bottom-end">
      <IconTestCloudTunnel className="testcloud-tunnel-icon mr-1" />
    </TooltipComponent>
  ),

  renderAvailabilityIcon: (availability) => {
    if (availability === 'LOW') {
      return (
        <TooltipComponent
          text={t('low-availability')}
          placement="right"
          popperProps={{ style: { zIndex: 9999 } }}
        >
          <span className="ml-2">
            <IconLowAvailability />
          </span>
        </TooltipComponent>);
    } else {
      return null;
    }
  },
  detailsTestRun: (name, link, className) =>
    <Link className={`d-block ${className || ''}`} href={link}>{(name) || '\u00A0'}</Link>,
  idDecoratorWrapper: (innerContent, link) => <Link className="d-block" href={link}>{innerContent}</Link>,

  /**
   * Decorate for link
   * @param {String} innerContent - The content of link.
   * @param {String} link - The link to redirect when click.
   * @param {String} title - The title of value when hover.
   * @param {Object} trackingProps - The object contains traking data.
   * Ex: trackingProps = {'data-trackid': "sample-trackid", 'data-groupid': "sample-group"}
   * @returns
   */
  linkDecorator: (innerContent, link, title, trackingProps) => <Link key={link} href={link} title={title} {...trackingProps}>{innerContent}</Link>,

  /**
   * Decorate for link
   * @param {String} innerContent - The content of link.
   * @param {String} link - The link to redirect when click.
   * @param {String} title - The title of value when hover.
   * @param {Object} trackingProps - The object contains traking data.
   * Ex: trackingProps = {'data-trackid': "sample-trackid", 'data-groupid': "sample-group"}
   * @returns
   */
  linkDecoratorWithoutSPA: (innerContent, link, title, trackingProps) => <a key={link} href={link} title={title} {...trackingProps}>{innerContent}</a>,

  rawTextDecorator: (name, row) => <ReadMore><pre>{get(row, name, '')}</pre></ReadMore>,

  totalComment: (value, link) => {
    const renderLink = (content, link) => {
      if (link) {
        return <Link href={link}>{content}</Link>;
      } else return <> {content} </>;
    };

    if (value) {
      return (
        <>
          <IconComments className="fa-reg-comments" />
          {renderLink(value, link)}
        </>
      );
    } else {
      return '';
    }
  },

  totalCommentDecorator: (name, row) => {
    const value = get(row, name);
    return DecoratorConstants.totalComment(value);
  },

  profile(profiles) {
    if (isArray(profiles)) {
      const profileFlatten = flatten(profiles);
      const profileUnique = uniq(profileFlatten);
      const profilesContent = profileUnique.join(', ');
      if (profileUnique.length > 3) {
        return (<span title={profilesContent}>{profileUnique.length} profiles</span>);
      } else return (<span title={profilesContent}>{profilesContent}</span>);
    } else return (<span>{profiles}</span>);
  },

  profileDecorator: (name, row) => {
    const value = get(row, name);
    return DecoratorConstants.profile(value);
  },

  platformHeader(platform) {
    const osName = Text.platformName(platform);
    const browserName = Text.browserName(platform);
    const osIcon = DecoratorConstants.iconByOS(osName);
    const browserIcon = DecoratorConstants.iconByBrowser(browserName);
    return (
      <div className="d-flex">
        {osIcon && osName && (
          <div className="d-flex">
            <span className="platform-icon">{osIcon}</span>
            {osName}
          </div>
        )}
        {browserIcon && browserName && (
          <div className="d-flex ml-3">
            <span className="platform-icon">{browserIcon}</span>
            {browserName}
          </div>
        )}
      </div>
    );
  },

  platform(platform, noPlatform = '', iconOnly = true) {
    const osName = Text.platformName(platform);
    const browserName = Text.browserName(platform);
    const osIcon = DecoratorConstants.iconByOS(osName);
    const browserIcon = DecoratorConstants.iconByBrowser(browserName);
    const deviceName = platform.deviceName || '';
    const appName = platform.appName || '';

    if (osName === 'Total') {
      return (
        <span className="platform-info">
          <span title={osName}>
            <span>&nbsp;{osName}</span>
          </span>
        </span>
      );
    }

    return (
      <span className="platform-info">
        <span title={osName}>
          <span>{osIcon}</span>
          {iconOnly || !osName ? '' : <span>&nbsp;{osName}</span>}
        </span>
        {osName && browserName ? <span>&nbsp;</span> : ''}
        <span title={browserName}>
          {browserIcon}
          {iconOnly || !browserName ? '' : <span>&nbsp;{browserName}</span>}
        </span>
        {deviceName && (
          <>
            <span>&nbsp;</span>
            <span title={deviceName}>
              <IconIphone />
              {iconOnly ? '' : <span>&nbsp;{deviceName}</span>}
            </span>
          </>
        )}
        {appName && (
        <>
          <span>&nbsp;</span>
          <span title={appName}>
            <IconMobileNativeApp />
            {iconOnly ? '' : <span>&nbsp;{appName}</span>}
          </span>
        </>
        )}
        {!osName && !browserName && noPlatform}
      </span>
    );
  },

  platformReport: (platform, iconOnly = true) => DecoratorConstants.platform(platform, 'Unknown', iconOnly),

  platformDecorator: (name, row) => {
    const value = get(row, name);
    return DecoratorConstants.platform(value);
  },

  normalizeBrowserName(browserName) {
    let normalizedBrowserName = browserName;
    if (BrowserDetector.isChrome(browserName)) {
      normalizedBrowserName = 'Chrome';
    } else if (BrowserDetector.isFirefox(browserName)) {
      normalizedBrowserName = 'Firefox';
    } else if (BrowserDetector.isIE(browserName)) {
      normalizedBrowserName = 'IE';
    } else if (BrowserDetector.isEdgeChromium(browserName)) {
      normalizedBrowserName = 'Edge Chromium';
    } else if (BrowserDetector.isEdge(browserName)) {
      normalizedBrowserName = 'Edge';
    } else if (BrowserDetector.isSafari(browserName)) {
      normalizedBrowserName = 'Safari';
    } else if (BrowserDetector.isChromium(browserName)) {
      normalizedBrowserName = 'Chromium';
    }
    return normalizedBrowserName;
  },

  browsers(platforms) {
    const browsers = map(platforms, (platform) => {
      const browserName = Text.browserName(platform);
      const browserIcon = DecoratorConstants.iconByBrowser(browserName);
      const normalizedBrowserName = DecoratorConstants.normalizeBrowserName(browserName);
      return {
        browserName: normalizedBrowserName,
        browserIcon,
      };
    });
    const uniqBrowsers = sortBy(uniqBy(browsers, 'browserName'), 'browserName');
    return map(uniqBrowsers, ({ browserName, browserIcon }) => (
      <>
        <span title={browserName}>{browserIcon}</span>
        {' '}
      </>
    ));
  },

  browsersDecorator(platforms) {
    const browsers = map(platforms, (platform) => {
      const browserName = Text.browserName(platform);
      const browserType = DecoratorConstants.typeByBrowsers(browserName);
      return {
        browserName,
        browserType
      };
    });
    const uniqBrowsersByType = groupBy(browsers, 'browserType');
    const browserTypes = Object.keys(uniqBrowsersByType);

    // Move others type to end of array
    const sortOthersToEnd = sortBy(browserTypes, (browserType) => browserType === BrowserName.Others);

    return map(sortOthersToEnd, (browserType) => {
      const browserNames = Array.from(new Set(uniqBrowsersByType[browserType].map((browser) => browser.browserName)));
      const contentOfBrowserTooltip = browserNames.map((browserName) => <div>{browserName}</div>);
      return (
        <>
          <TooltipComponent placement="bottom-end" text={contentOfBrowserTooltip}>
            <span>{this.iconByBrowser(browserType)}</span>
          </TooltipComponent>
          {' '}
        </>
      );
    });
  },

  osDecorator(platforms) {
    const operatingSystems = map(platforms, (platform) => {
      const osName = Array.from(new Set(Text.platformName(platform).split(' '))).join(' ');
      const osType = DecoratorConstants.typeByOS(osName);
      return {
        osName,
        osType
      };
    });
    const uniqOsByType = groupBy(operatingSystems, 'osType');
    const osTypes = Object.keys(uniqOsByType);

    // Move others type to end of array
    const sortOthersToEnd = sortBy(osTypes, (osType) => osType === OsName.Others);

    return map(sortOthersToEnd, (osType) => {
      const osNames = Array.from(new Set(uniqOsByType[osType].map((os) => os.osName)));
      const contentOfOSTooltip = osNames.map((osName) => <div>{osName}</div>);
      return (
        <>
          <TooltipComponent placement="bottom-end" text={contentOfOSTooltip}>
            <span>{this.iconByOS(osType)}</span>
          </TooltipComponent>
          {' '}
        </>
      );
    });
  },

  appNameDecorator(platforms) {
    const deviceName = platforms.find((el) => el?.deviceName)?.deviceName;
    const appNames = platforms
      .map((el) => el?.appName)
      .filter((appName) => appName);

    if (appNames.length === 0 || !deviceName) {
      return null;
    }

    const uniqueAppNames = [...new Set(appNames)];
    const appNamesList = uniqueAppNames.join('\n');

    return (
      <TooltipComponent placement="bottom-end" text={appNamesList}>
        <span><IconMobileNativeApp /></span>
      </TooltipComponent>
    );
  },

  appVersionDecorator(appVersion, tooltipText) {
    if (!appVersion) return null;
    return (
      <TooltipComponent placement="bottom-end" arrow text={tooltipText}>
        <div className="badge post-select-badge">{appVersion}</div>
      </TooltipComponent>
    );
  },

  appVersionNumberDecorator(metadata) {
    const { version, versionCode } = metadata;

    if (!version && !versionCode) {
      return null;
    }

    return (
      <>
        {version && (
          <TooltipComponent placement="bottom-end" arrow text={t('app#version-name-column-tooltip')}>
            <div className="badge post-select-badge">{version}</div>
          </TooltipComponent>
        )}
        {versionCode && (
          <TooltipComponent placement="bottom-end" arrow text={t('app#version-code-column-tooltip')}>
            <div className="ml-2 d-flex align-items-center">
              <div className="badge post-select-badge">{versionCode}</div>
            </div>
          </TooltipComponent>
        )}
      </>
    );
  },

  typeByBrowsers: (browserName = '') => {
    if (BrowserDetector.isChrome(browserName)) {
      return BrowserName.Chrome;
    } else if (BrowserDetector.isFirefox(browserName)) {
      return BrowserName.FireFox;
    } else if (BrowserDetector.isIE(browserName)) {
      return BrowserName.IE;
    } else if (BrowserDetector.isEdgeChromium(browserName)) {
      return BrowserName.EdgeChromium;
    } else if (BrowserDetector.isEdge(browserName)) {
      return BrowserName.Edge;
    } else if (BrowserDetector.isSafari(browserName)) {
      return BrowserName.Safari;
    } else if (BrowserDetector.isChromium(browserName)) {
      return BrowserName.Chromium;
    } else if (browserName !== '') {
      return BrowserName.Others;
    }
    return '';
  },

  typeByOS: (osName = '') => {
    if (OSDetector.isWindows(osName)) {
      return OsName.Windows;
    } else if (OSDetector.isAndroid(osName)) {
      return OsName.ANDROID;
    } else if (OSDetector.isIOS(osName)) {
      return OsName.IOS;
    } else if (OSDetector.isLinux(osName)) {
      return OsName.Linux;
    } else if (OSDetector.isMacOS(osName)) {
      return OsName.MacOS;
    } else if (osName !== '') {
      return OsName.Others;
    }
    return '';
  },

  iconWebService: () => <IconWebService style={{ width: 20, height: 20 }} />,

  iconByOS: (osName = '') => {
    if (OSDetector.isWindows(osName)) {
      return <IconWindows />;
    } else if (OSDetector.isLinux(osName)) {
      return <IconLinux />;
    } else if (OSDetector.isMacOS(osName)) {
      return <IconMac />;
    } else if (OSDetector.isIOS(osName)) {
      return <IconIOS />;
    } else if (OSDetector.isAndroid(osName)) {
      return <IconAndroid />;
    } else if (osName === OsName.Others) {
      return <IconUnknownFile />;
    }
    return '';
  },

  iconByDeviceType: (deviceType = '') => {
    if (DeviceDetector.isIpad(deviceType) || DeviceDetector.isTablet(deviceType)) {
      return <IconIpad />;
    }
    return <IconIphone />;
  },

  // only show browser version type if it's dev or beta
  iconBrowserVersionType: (type) => {
    if (type && type.toLowerCase() !== 'stable') {
      return <LabelChip name={type.toUpperCase()} />;
    }
    return null;
  },

  buildBrowserVersionLabel: (browserVersionNumber, browserVersionType) => (
    <>
      <span className="m-2">{browserVersionNumber}</span>
      {DecoratorConstants.iconBrowserVersionType(browserVersionType)}
    </>
  ),

  iconPrivateDeviceWithUdid: (udid) => {
    const shortUdid = udid.substring(0, 6);
    return <LabelChip name={`UDID: ${shortUdid}`} />;
  },

  iconByDevice: (isTablet = false) => (isTablet ? <IconIpad /> : <IconIphone />),

  iconsAgent: (isActive, osName) => (
    <div className="d-flex">
      <div className="mr-2">
        {DecoratorConstants.agentStatusDecorator(isActive)}
      </div>
      <div className="mr-1">
        {DecoratorConstants.iconByOS(osName)}
      </div>
    </div>
  ),

  iconMobileByConfig: (config) => {
    const { appId, os, appName } = config;
    if (appId && appName) {
      return <IconMobileNativeApp data-testid="icon-mobile-native-app" />;
    }
    return OSDetector.isIOS(os) ? <IconSafari data-testid="icon-safari" /> : <IconChrome data-testid="icon-chrome" />;
  },

  iconByBrowser: (browserName = '') => {
    if (BrowserDetector.isChromeHeadless(browserName)) {
      return <IconChromeHeadless />;
    } else if (BrowserDetector.isFirefoxHeadless(browserName)) {
      return <IconFirefoxHeadless />;
    } else if (BrowserDetector.isChrome(browserName)) {
      return <IconChrome />;
    } else if (BrowserDetector.isFirefox(browserName)) {
      return <IconFirefox />;
    } else if (BrowserDetector.isIE(browserName)) {
      return <IconIE />;
    } else if (BrowserDetector.isEdgeChromium(browserName)) {
      return <IconEdgeChromium />;
    } else if (BrowserDetector.isMsEdge(browserName)) {
      return <IconEdgeChromium />;
    } else if (BrowserDetector.isEdge(browserName)) {
      return <IconEdge />;
    } else if (BrowserDetector.isSafari(browserName)) {
      return <IconSafari />;
    } else if (BrowserDetector.isChromium(browserName)) {
      return <IconChromium />;
    } else if (browserName === BrowserName.Others) {
      return <IconUnknownFile />;
    }
    return '';
  },

  flakinessDecorator: (name, row) => (row[name] >= 0 ? (row[name] * 100).toFixed(2) : ''),

  flakinessHistory: (name, row) => {
    const executionTestResults = reverse(get(row, name));

    const history = executionTestResults.map((executionTestResult, index) => {
      const status = executionTestResult.status;
      let color;
      let size;
      switch (status) {
        case 'PASSED':
          color = colors.passed;
          size = 10;
          break;
        case 'FAILED':
          color = colors.failed;
          size = 16;
          break;
        case 'ERROR':
          color = colors.error;
          size = 16;
          break;
        case 'INCOMPLETE':
          color = colors.incomplete;
          size = 16;
          break;
        default:
          color = colors.unknown;
          size = 12;
      }
      const title = (
        <div className="line-chart__tooltip">
          <p>
            {DecoratorConstants.statusDecorator('status', executionTestResult)} {executionTestResult.id}
          </p>
          <div>
            <IconProfile /> {DecoratorConstants.profileDecorator('profile', executionTestResult)}
          </div>
          <div>
            <IconStartTime /> {Time.format(executionTestResult.startTime, Time.OVER_A_DAY_FORMAT)}
          </div>
          <div>
            <IconDuration /> {DecoratorConstants.durationDecorator('duration', executionTestResult)}
          </div>
          <div>
            {DecoratorConstants.platformDecorator('platform', executionTestResult)}
          </div>
        </div>
      );

      return (
        <>
          <Tooltip title={title} className="line-chart__point">
            <IconButton size="large">
              <IconStatus fill={color} style={{ width: size, height: size }} />
            </IconButton>
          </Tooltip>
          {index < executionTestResults.length - 1 && <Divider />}
        </>
      );
    });

    return (
      <div className="line-chart">
        {history}
      </div>
    );
  },

  linkJiraIssuesDecorator: (name, row, style) => {
    const issues = get(row, name);

    const decorator = (issues) => {
      const routes = new Routes({ requirementId: issues.id });
      const requirementLink = routes.requirement_details_link;
      return DecoratorConstants.idDecorator('issueId', issues, requirementLink, undefined, undefined, undefined, true);
    };

    let issueLinks;
    let more;
    const numberIssues = issues ? issues.length : 0;
    if (numberIssues <= MAX_DISPLAY_REQUIREMENTS) {
      issueLinks = issues.map(decorator);
      more = '';
    } else {
      issueLinks = issues.slice(0, MAX_DISPLAY_REQUIREMENTS).map(decorator);

      const constructedLink = new Routes({
        teamId: row.project.teamId,
        projectId: row.project.id,
        testCaseId: row.urlId,
      });

      more = (
        <Link className="text-decoration-none" href={constructedLink.execution_test_result_history_link}>
          ({numberIssues - MAX_DISPLAY_REQUIREMENTS } more...)
        </Link>
      );
    }
    return <div className={style || ''}>{issueLinks}{more}</div>;
  },

  jiraIssuesDecorator: (name, row) => {
    const issues = get(row, name);
    return issues.map(({ featureName, issueId }) => issueId || featureName).join(', ');
  },

  releaseDecorator: (name, row, link, title, className) => {
    const routes = new Routes({ releaseId: row.id });
    const { externalRelease } = row;
    const { jiraReleaseStatus } = externalRelease;
    const hasJiraStatus = !isEmpty(jiraReleaseStatus);
    const releaseLink = link || routes.view_release_link;
    const alternativeClassName = className && 'text-truncate';
    return (
      <div className="release-decorator">
        {DecoratorConstants.idDecorator(name, row, releaseLink, title, className)}
        {hasJiraStatus && (
          <ReleaseStatusLabel className={alternativeClassName} jiraReleaseStatus={jiraReleaseStatus} title={`Jira: ${externalRelease.name}`} />
        )}
      </div>
    );
  },

  jiraLinkDecorator: (name, row) => {
    const externalRelease = get(row, name);
    const hasExternalRelease = !isEmpty(externalRelease);
    if (hasExternalRelease) {
      return (
        <Link
          href={externalRelease.webUrl}
          target="_blank"
          rel="noopener noreferrer"
          className="defect-add"
        >
          <IconExternalLink title={`Jira: ${externalRelease.name}`} />
        </Link>
      );
    }
    return null;
  },

  pluralize: (singular, count) => ((count === 1 || count === 0) ? singular : `${singular}s`),

  featureDecorator: (name, row) => {
    const feature = get(row, name);
    switch (feature) {
      case OrganizationFeature.KSE:
        return <span title={t('kse_license_keys')}>{t('kse_license_keys#abbr')}</span>;
      case OrganizationFeature.UNLIMITED_KSE:
        return <span title={t('unlimited_kse_license_keys')}>{t('unlimited_kse_license_keys#abbr')}</span>;
      case OrganizationFeature.ENGINE:
        return <span title={t('engine_license_keys')}>{t('engine_license_keys#abbr')}</span>;
      case OrganizationFeature.UNLIMITED_ENGINE:
        return <span title={t('unlimited_engine_license_keys')}>{t('unlimited_engine_license_keys#abbr')}</span>;
      case OrganizationFeature.TESTOPS:
        return <span title={t('testops_license_keys')}>{t('testops_license_keys#abbr')}</span>;
      case OrganizationFeature.FLOATING_ENGINE:
        return <span title={t('floating_engine_license_keys')}>{t('floating_engine_license_keys#abbr')}</span>;
      default:
        return feature;
    }
  },

  billingIntervalDecorator: (name, row) => {
    const billingInterval = get(row, name);
    switch (billingInterval) {
      case 'YEAR':
        return 'Annual';
      case 'MONTH':
        return 'Monthly';
      default:
        return 'N/A';
    }
  },

  intervalDecorator: (name, row) => {
    const interval = get(row, name);
    const unit = get(row, 'intervalUnit');
    if (isNumber(interval) && isString(unit)) {
      const capitalizedUnit = startCase(toLower(unit));
      return <>{interval} {DecoratorConstants.pluralize(capitalizedUnit, interval)}</>;
    } else return '';
  },

  paymentCardNumber: (name, row) => {
    const value = get(row, name);
    return `**** ${value}`;
  },

  codeRepoType: (name, row) => {
    const value = get(row, name);
    switch (value) {
      case 'GIT':
        return <span title={t('git')}><IconGitRepo /></span>;
      case 'KS':
        return <span title={t('uploadedRepository')}><ZipRepositoryIcon textSize="18px" /></span>;
      case 'CLOUD':
        return <span title={t('cloudStorage')}><KatalonStorageIcon /></span>;
      default:
        return (
          <span title={t('uploadedRepository')}>
            <ZipRepositoryIcon textSize="18px" />
          </span>);
    }
  },

  codeRepoTypeSelected: (name, row) => {
    const value = get(row, name);
    switch (value) {
      case 'CLOUD':
        return <span title={value}><KatalonStorageIcon color="white" /></span>;
      case 'KS':
        return <span title={t('uploadedRepository')}><ZipRepositoryIcon color="white" textSize="18px" /></span>;
      default:
        return null;
    }
  },

  buildTestCaseContentMiddleTruncate: (name, row) => {
    const content = get(row, name);
    let children = <span title={content}>{content}</span>;
    if (row.type === 'SCENARIO') {
      children = <>{children} <IconCucumberBlue title="Scenario" /></>;
    }
    return <TextMiddleTruncate>{children}</TextMiddleTruncate>;
  },

  /**
   * Decorate for test case name column in table
   * @param {String} name - The key name of value in object.
   * @param {Object} row - The row contains object value.
   * @param {Object} trackingProps - The object contains traking data.
   * @param {boolean} isTruncate - Decide that text will be truncated or not
   * Ex: trackingProps = {'data-trackid': "sample-trackid", 'data-groupid': "sample-group"}
   * @returns
   */
  testCaseNameDecorator: (name, row, trackingProps, isTruncate) => {
    const constructedLink = new Routes({
      teamId: row.project?.teamId,
      projectId: row.project?.id,
      testCaseId: row.urlId,
    });
    const fullPath = `${row.path}/${row.name}`;
    let content = get(row, name);

    if (isTruncate) {
      content = DecoratorConstants.buildTestCaseContentMiddleTruncate(name, row);
    } else if (row.type === 'SCENARIO') {
      content = <>{content} <IconCucumberBlue title="Scenario" /></>;
    }

    return DecoratorConstants.linkDecorator(
      content,
      constructedLink.execution_test_result_history_link,
      fullPath,
      trackingProps
    );
  },

  testSuiteNameTruncateDecorator: (name, row, trackingProps) => {
    const constructedLink = new Routes({
      teamId: row.project.teamId,
      projectId: row.project.id,
      testSuiteId: row.urlId,
    });

    const nameTestSuite = get(row, name);
    const fullPath = row.path ? `${row.path}/${row.name}` : row.name;
    const content = <TextMiddleTruncate><span title={nameTestSuite}>{nameTestSuite}</span></TextMiddleTruncate>;

    return DecoratorConstants.linkDecorator(
      content,
      constructedLink.execution_test_suite_history_link,
      fullPath,
      trackingProps
    );
  },

  historyExecutionTestSuiteNameTruncateDecorator: (name, row) => {
    const constructedLink = new Routes({
      executionId: row.execution.order,
    });
    const content = row.testSuite.name;

    return (
      <TextMiddleTruncate>
        {DecoratorConstants.linkDecorator(
          content,
          constructedLink.execution_details_link,
          content,
          undefined
        )}
      </TextMiddleTruncate>
    );
  },

  testCaseComparisonStatusDecorator: (name, row) => {
    const status = get(row, name, '').toLowerCase();
    return (
      <Badge className={`status-badge status-badge-${status}`}>
        {t(status)}
      </Badge>
    );
  },

  testSuiteDecorator: (name, row) => {
    const testSuitePath = get(row, 'testSuite.path');
    const testSuiteName = get(row, 'testSuite.name');
    const fullPath = `${testSuitePath}/${testSuiteName}`;
    return (
      <span title={fullPath}>{testSuiteName}</span>
    );
  },

  testObjectNameDecorator: (name, row) => {
    const fullPath = get(row, name);
    const testObjectName = fullPath ? fullPath.split('/').pop() : fullPath;
    return (
      <span title={fullPath}>{testObjectName}</span>
    );
  },

  testObjectFolderDecorator: (name, row) => {
    const fullPath = get(row, name) || '';
    const fullPathParts = fullPath.split('/');
    if (fullPathParts.length > 1) {
      fullPathParts.pop();
      const folder = fullPathParts.join('/');
      return (
        <div>{folder}</div>
      );
    }
    return '';
  },

  getIssueIconUrl: (iconUrl, type) => {
    // with xray issue type, iconUrl need auth to access, so we define icon for it
    switch (type) {
      case XRAY_ISSUE_TYPE.XRAY_TEST:
      case XRAY_ISSUE_TYPE.TEST:
        return XrayIssueTestIcon;
      case XRAY_ISSUE_TYPE.TEST_EXECUTION:
        return XrayIssueTestExecutionIcon;
      case XRAY_ISSUE_TYPE.TEST_PLAN:
        return XrayIssueTestPlanIcon;
      case XRAY_ISSUE_TYPE.TEST_SET:
        return XrayIssueTestSetIcon;
      case XRAY_ISSUE_TYPE.PRE_CONDITION:
        return XrayIssuePreCondition;
      case XRAY_ISSUE_TYPE.SUB_TEST_EXECUTION:
        return XrayIssueSubTestExecution;
      default:
        return iconUrl;
    }
  },

  externalIssueIdDecorator: (name, row, customLink = '', isAddStatus = false) => {
    const issueId = get(row, 'issueId');
    const issueUrl = get(row, 'url');
    const iconUrl = DecoratorConstants.getIssueIconUrl(get(row, 'issueTypeIcon'), get(row, 'issueTypeName'));
    const iconAlt = get(row, 'issueTypeName');
    return (
      <div className="d-flex align-items-center" title={iconAlt}>
        {iconUrl &&
          <span className="mr-2 d-flex align-items-center">
            <img src={iconUrl} alt={iconAlt} className="img-fluid icon-issue-type" />
          </span>}
        <Link className={`d-block text-nowrap ${customLink}`} href={issueUrl} target="_blank" rel="noopener noreferrer">{issueId}</Link>
        {isAddStatus &&
        <div className="ml-2">
          {DecoratorConstants.defectStatusDecorator(row, true)}
        </div>}
      </div>
    );
  },

  featureNameDecorator: (name, row) => {
    const featureName = get(row, name);
    const constructedLink = new Routes({
      requirementId: row.id
    });
    const link = constructedLink.requirement_details_link;
    return <span><Link href={link}>{featureName}</Link> <IconCucumberGreen title="Feature" /></span>;
  },

  externalIssueTemplatesDecorator: (name, row, externalKey) => {
    const templates = get(row, externalKey);
    return templates.length;
    // return (
    //   <>
    //     {templates.map((template, index) => {
    //       const divider = index < templates.length - 1 ? <Divider /> : null;
    //       let status;
    //       let templateData;
    //       let link;
    //
    //       if (externalKey === 'testResults') {
    //         status = template.status;
    //         templateData = template.testCase;
    //         const constructedLink = new Routes({
    //           teamId: templateData.project.teamId,
    //           projectId: templateData.project.id,
    //           executionTestResultId: template.urlId,
    //           executionId: template.execution?.id
    //         });
    //         link = constructedLink.execution_test_result_detail_link;
    //       }
    //
    //       if (externalKey === 'testCases') {
    //         status = template.lastExecutionTestCase?.status;
    //         templateData = template;
    //         const constructedLink = new Routes({
    //           teamId: templateData.project.teamId,
    //           projectId: templateData.project.id,
    //           testCaseId: templateData.urlId
    //         });
    //         link = constructedLink.execution_test_result_history_link;
    //       }
    //       const fullPath = `${templateData.path}/${templateData.name}`;
    //
    //       return (
    //         <div
    //           id={template.id}
    //           key={template.id}
    //         >
    //           {DecoratorConstants.externalIssueLinkedTestCaseDecorator(templateData, status, link, fullPath)}
    //           {divider}
    //         </div>
    //       );
    //     })}
    //   </>
    // );
  },

  externalIssueLinkedTestCaseDecorator: (templateData, status, link, fullPath) => (
    <ListItemText
      primary={
        <div className="d-flex">
          {status && (
            <span className="mr-2 align-items-center">
              {DecoratorConstants.status(status)}
            </span>
          )}
          <Link
            className="d-block"
            href={link}
          >
            {templateData.name} {templateData?.type === 'SCENARIO' && <IconCucumberBlue title="Scenario" />}
          </Link>
        </div>
      }
      secondary={
        <div>
          {templateData.path}
        </div>
      }
      title={fullPath}
    />
  ),

  executionFileNameDecorator: (name, row) => {
    const fullPath = `${row.path}/${row.name}`;
    const id = DecoratorConstants.idWithoutSPADecorator(
      name,
      row,
      row.url,
      fullPath,
    );
    return (
      <>
        {id}
        <div>{fullPath}</div>
      </>
    );
  },

  executionElapsedTimeAndDurationDecorator: (name, row) => {
    const durationContent = (
      <span className="d-flex align-items-center">
        <IconDuration title={t('elapsedDuration')} className="mr-2" />
        {DecoratorConstants.durationDecorator('elapsedDuration', row)}
      </span>
    );
    const startTimeContent = (
      <span className="d-flex align-items-center">
        <IconStartTime title={t('startTime')} className="mr-2" />
        {DecoratorConstants.timeDecorator('startTime', row)}
      </span>
    );
    return (
      <>
        {durationContent}
        {startTimeContent}
      </>
    );
  },

  timeAndDurationDecorator: (name, row) => {
    const durationContent = (
      <span className="d-block">
        <IconDuration title={t('duration')} className="mr-1" />
        {DecoratorConstants.durationDecorator('duration', row)}
      </span>
    );
    const startTimeContent = (
      <span className="d-block">
        <IconStartTime title={t('startTime')} className="mr-1" />
        {DecoratorConstants.timeDecorator('startTime', row)}
      </span>
    );
    return (
      <>
        {durationContent}
        {startTimeContent}
      </>
    );
  },

  renderUserWithoutNameDecorator: (user, className = '') => (
    <div title={user.fullName} className={className} id={`user-${user.email}`}>
      {DecoratorConstants.renderUserAvatarDecorator(user.fullName, user.avatar, true)}
    </div>
  ),

  renderUserDecorator: (user, className = '') => (
    <div title={user.email} className={className} id={`user-${user.email}`}>
      {DecoratorConstants.renderUserAvatarDecorator(user.fullName, user.avatar)}
    </div>
  ),

  renderUserAvatarDecorator: (name, avatar, hideName = false, diameter = 24) => (
    <div className={`${hideName ? '' : 'img-name'}`}>
      <span>
        <ImageProfile
          name={name}
          imgUrl={avatar}
          diameter={diameter}
        />
      </span>
      {!hideName && name}
    </div>
  ),

  requirementIdDecorator: (name, row) => {
    const requirementId = get(row, 'id');
    if (!get(row, name)) return null;
    const constructedLink = new Routes({
      requirementId,
    });
    const link = constructedLink.requirement_details_link;
    return DecoratorConstants.idDecorator(name, row, link);
  },

  requirementDetailsDecorator: (name, row) => {
    const getRequirementId = get(row, 'id');
    const constructedLink = new Routes({
      requirementId: getRequirementId
    });
    const link = constructedLink.requirement_details_link;
    const iconUrl = get(row, 'issueTypeIcon');
    const iconAlt = get(row, 'issueTypeName');
    const summary = get(row, 'summary');
    const issueId = get(row, 'issueId');
    return (
      <ListItemText
        primary={
          <div className="d-flex align-items-center">
            {iconUrl &&
              <span className="mr-2">
                <img src={iconUrl} alt={iconAlt} className="img-fluid icon-issue-type" />
              </span>}
            {link ?
              <Link
                className="d-block text-nowrap"
                href={link}
                rel="noopener noreferrer"
              >
                {issueId}
              </Link> :
              issueId}&nbsp;{summary}
          </div>
        }
        // secondary={summary}
        title={iconAlt}
      />
    );
  },

  dateAndOrderDecorator: (name, row) => {
    const order = `#${get(row, 'order')}`;
    const startTime = get(row, 'startTime');
    const startTimeFormat = Time.formatDate(startTime, Time.DATE_FORMAT, true);
    return `${startTimeFormat} ${order}`;
  },

  avatarOnlyDecorator: (name, row) => {
    const user = get(row, name);
    if (!user) {
      return null;
    }
    return DecoratorConstants.renderUserAvatarDecorator(user.fullName, user.avatar, true);
  },

  userWithAvatarDecorator: (name, row) => {
    const user = row.user;

    if (user === null) {
      return null;
    }
    return DecoratorConstants.renderUserAvatarDecorator(user.fullName, user.avatar);
  },

  externalTypeDecorator: (row) => {
    const iconUrl = get(row, 'issueTypeIcon');
    const iconAlt = get(row, 'issueTypeName');
    return (
      <span title={iconAlt} className="mr-2">
        <img src={iconUrl} alt={iconAlt} className="img-fluid icon-issue-type" />
      </span>
    );
  },

  defectIdDecorator: (row) => {
    const iconUrl = get(row, 'issueTypeIcon');
    const iconAlt = get(row, 'issueTypeName');
    const issueId = get(row, 'issueId');
    const issueUrl = get(row, 'url');

    return (
      <span title={iconAlt} className="mr-2 d-flex align-items-center">
        <img src={iconUrl} alt={iconAlt} className="img-fluid icon-issue-type mr-2" />
        <Link target="_blank" rel="noopener noreferrer" href={issueUrl}>{issueId}</Link>
      </span>
    );
  },

  defectStatusDecorator: (row, isTruncate = false) => {
    const status = get(row, 'status');
    const statusCategory = get(row, 'statusCategory');
    const style = JIRA_STATUS_CATEGORY.find((option) => statusCategory === option.value)?.style;

    if (isTruncate) {
      return (
        <span className={`${style} text-nowrap`} title={status}>
          {DecoratorConstants.truncateLeftStringDecorator(status, 10)}
        </span>
      );
    }

    return (
      <span className={style}>
        {status}
      </span>
    );
  },

  defectPriorityDecoratorWithoutIcon: (row) => {
    const priority = get(row, 'priority');

    return (
      <div>
        {priority}
      </div>
    );
  },

  defectSeverityDecorator: (row) => {
    const severity = get(row, 'severity');

    if (!severity) {
      return null;
    }

    return (
      <div>
        {severity}
      </div>
    );
  },

  defectPriorityDecorator: (row, customIcon = '') => {
    const priority = get(row, 'priority');
    const externalPriority = get(row, 'externalPriority');

    // In case we can not map external priority to get icon, we only display the string
    if (externalPriority === undefined || externalPriority === null) {
      return (
        <div>
          {priority}
        </div>
      );
    } else {
      return (
        <div className="d-flex align-items-center">
          <img src={externalPriority?.iconUrl} alt={priority} className={customIcon === '' ? 'img-fluid icon-issue-type mr-2' : customIcon} />
          <span className="ml-2">
            {priority}
          </span>
        </div>
      );
    }
  },

  defectTestCasesDecorator: (row) => {
    const distinctTestCases = filterDistinctTestCasesOfDefect(row);
    return defectNumberDecorator(distinctTestCases.length);
  },

  defectTestResultsDecorator: (name, row) => {
    const testResults = filterDistinctTestResultsOfDefect(name, row);
    return defectNumberDecorator(testResults.length);
  },

  defectRequirementsDecorator: (row) => {
    const distinctRequirements = filterDistinctRequirementsOfDefect(row);
    return defectNumberDecorator(distinctRequirements.length);
  },

  defectAssigneeDecorator: (row) => {
    const avatarUrl = get(row, 'avatarUrl');
    const avatarName = get(row, 'assignee');
    if (!avatarUrl || !avatarName) {
      return null;
    }
    return DecoratorConstants.renderUserAvatarDecorator(avatarName, avatarUrl, true);
  },

  externalKeyDecorator: (row) => {
    const issueId = get(row, 'issueId');
    const issueUrl = get(row, 'url');
    if (!issueUrl) {
      return (
        <span>{issueId}</span>
      );
    }
    return (
      <Link
        className="d-block text-nowrap"
        href={issueUrl}
        target="_blank"
        rel="noopener noreferrer"
      >
        {issueId}
      </Link>
    );
  },

  externalNameDecorator: (name, row) => {
    const summary = get(row, name);
    return summary || null;
  },

  externalIssueDecorator: (row) => {
    const issueId = get(row, 'issueId');
    const issueUrl = get(row, 'url');
    const iconUrl = get(row, 'issueTypeIcon');
    const iconAlt = get(row, 'issueTypeName');
    const summary = get(row, 'summary');
    return (
      <ListItemText
        primary={
          <div className="d-flex align-items-center">
            {iconUrl &&
              <span className="mr-2">
                <img src={iconUrl} alt={iconAlt} className="img-fluid icon-issue-type" />
              </span>}
            {issueUrl ?
              <Link
                className="d-block text-nowrap"
                href={issueUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                {issueId}
              </Link> :
              issueId}
          </div>
        }
        secondary={summary}
        title={iconAlt}
      />
    );
  },

  dateLabelDecorator: (typeOfDate) => {
    let labelDate;
    switch (typeOfDate) {
      case dayOption.value:
        labelDate = 'Date';
        break;
      case weekOption.value:
        labelDate = 'First day of week';
        break;
      case monthOption.value:
        labelDate = 'First day of month';
        break;
      default:
        labelDate = 'Date';
    }
    return labelDate;
  },

  testProjectDecorator: (name, row) => {
    const testProject = get(row, name);
    let type;
    switch (testProject.type) {
      case 'GIT':
        type = <IconGitRepo />;
        break;
      case 'KS':
        type = <ZipRepositoryIcon />;
        break;
      case 'CLOUD':
        type = <KatalonStorageIcon />;
        break;
      default:
        type = <ZipRepositoryIcon />;
    }
    const { project } = row;
    const route = new Routes({
      teamId: project.teamId,
      projectId: project.id,
      testProjectId: testProject.id,
    });

    return (
      <div className="repo-icon-text">
        <div className="icon-with-margin">{type}</div>
        <Link href={route.test_project_link}>{testProject.name}</Link>
      </div>
    );
  },

  testEnvironmentDecorator: (name, row) => {
    const agent = get(row, name);
    return (
      <div className="icon-text">
        {agent && agent.os && DecoratorConstants.platform({ osVersion: agent.os })}
        {DecoratorConstants.agentDecorator('name', row)}
      </div>
    );
  },

  /**
   * function used to combine status and text.
   * @param status
   * @param text
   * @returns {JSX.Element}
   */
  combineIconStatusForText: (status, text) => (
    <div className="d-flex icon-text">
      {DecoratorConstants.status(status)}
      <div className="text-capitalize">{text}</div>
    </div>
  ),

  /**
   * function used to combine default ExecutionProfileIcon with name or not default
   * @param {*} isDefault
   * @returns {JSX.Element}
   */
  generateIconExecutionProfile(isDefault) {
    if (isDefault) {
      return <IconDefaultExecutionProfile />;
    }
    return <IconExecutionProfile />;
  },

  /**
   * function help combine path and name together.
   * @param {*} path
   * @param {*} name
   * @returns {String}
   */
  addPathOfName(path, name) {
    return path ? `${path}/${name}` : name;
  },

  nameAndVersionLinkDecorator: (name, version, link, trackingProps) => {
    const innerContent = `${name} - v.${version}`;
    return (
      <TextMiddleTruncate>
        {DecoratorConstants.linkDecorator(innerContent, link, innerContent, trackingProps)}
      </TextMiddleTruncate>
    );
  },

  nameBaselineCollectionAndIdDecorator: (order, name) => `${name} (ID: ${order})`,

  baselineCollectionGroupTitleDecorator: (baselineCollectionGroup) => {
    let baselineCollectionTitle = '';
    if (baselineCollectionGroup) {
      baselineCollectionTitle = `${baselineCollectionGroup.name} (${t('baseline-collection-group-detail#title-id')} ${baselineCollectionGroup.order})`;
    }
    return baselineCollectionTitle;
  },

  unsavedBadgeDecorator: (unsaved) => (unsaved ? <Badge className="mt-1 ml-2 status-badge status-badge-header-unsaved">{t('unsaved')}</Badge> : null),

  truncateLeftStringDecorator: (str, n) => ((str && (str.length > n)) ? `${str.slice(0, n - 1)}...` : str),

  truncateRightStringDecorator: (str, n) => ((str && (str.length > n)) ? `...${str.slice(-(n - 1))}` : str),

  roundGreyQuantityBadge: (quantity) => (
    <Badge className="badge-quantity">
      {quantity}
    </Badge>
  ),

  formatPercentage: (number) => {
    if (number === -1) {
      return '-';
    } else {
      const inputString = number.toString();
      const parts = inputString.split('.');
      let result;
      if (parts.length === 2) {
        const integerPart = parts[0];
        const decimalPart = parts[1];
        if (decimalPart.length >= 2) {
          result = number.toFixed(2);
        } else if (decimalPart.length >= 1) {
          result = number.toFixed(1);
        } else {
          result = integerPart;
        }
      } else {
        result = number;
      }
      return `${result}%`;
    }
  },

  /**
   * @param trendValue
   * @param isGoodSignal : true mean if the trendValue is positive, it is good signal green,
   * false mean if the trendValue is positive, it is bad signal red
   * @returns {{icon: ReactNode, type: string}}
   */
  getIconAndType: (trendValue, isGoodSignal) => {
    let icon;
    let type;
    if (trendValue === 0 || trendValue === undefined) {
      icon = <IconTrendNA />;
      type = undefined;
    } else if (isGoodSignal) {
      if (trendValue > 0) {
        icon = <IconTrendUp />;
        type = 'green';
      } else {
        icon = <IconTrendDown />;
        type = 'red';
      }
    } else {
      if (trendValue > 0) {
        icon = <IconTrendUpRed />;
        type = 'red';
      } else {
        icon = <IconTrendDownGreen />;
        type = 'green';
      }
    }
    return { icon, type };
  },

  nameAndPathDecorator: (name, row, rawPath) => {
    const entityName = get(row, 'name');
    const entityRawPath = rawPath || get(row, 'rawPath');
    const entityPath = get(row, 'path');
    const webUrl = get(row, 'webUrl');
    return (
      <div className="name-and-path-decorator">
        <div className="entity-name-decorator">
          <span title={entityName}>
            {MFlags.dashboardAIPhase2Enabled ?
              <Link href={webUrl} className="text-dark" target="_blank" rel="noopener noreferrer">
                {DecoratorConstants.truncateLeftStringDecorator(entityName, 35)}
              </Link> : DecoratorConstants.truncateLeftStringDecorator(entityName, 35)}
          </span>
        </div>
        <div className="d-flex align-items-center entity-path-decorator">
          <span className="fixed-1rem-icon mr-2 d-flex align-items-center">
            <IconImpactedLocations />
          </span>
          <div>
            <span title={entityRawPath}>
              {DecoratorConstants.truncateRightStringDecorator(entityPath, 35)}
            </span>
          </div>
        </div>
      </div>
    );
  },

  folderLocationDecorator: (name, row) => {
    const path = get(row, 'path');
    const rawPath = get(row, 'rawPath');
    return (
      <div className="d-flex align-items-center">
        <span className="fixed-1rem-icon mr-2 d-flex align-items-center">
          <IconImpactedLocations />
        </span>
        <div>
          <span title={rawPath}>
            {DecoratorConstants.truncateRightStringDecorator(path, 25)}
          </span>
        </div>
      </div>
    );
  },

  statusBarItem: (totalPassed, totalFailed, totalUnexecuted) => {
    const payload = [];
    if (totalPassed !== undefined) {
      payload.push({
        value: totalPassed,
        color: 'passed',
        label: t('totalPassedTests'),
      });
    }

    if (totalFailed !== undefined) {
      payload.push({
        value: totalFailed,
        color: 'failed',
        label: t('totalFailedTests'),
      });
    }

    if (totalUnexecuted !== undefined) {
      payload.push({
        value: totalUnexecuted,
        color: 'unexecuted',
        label: t('totalUnexecutedTests'),
      });
    }

    return payload;
  },

  buildStatusBarChartWithTooltip: (payload, title) => {
    const totalTests = sumBy(payload, 'value');
    const tooltipTitle = buildStatusBarTitle(title, payload, totalTests);
    const style = makeStyles(() => ({
      tooltip: {
        fontWeight: '300',
        lineHeight: '1rem',
        fontSize: '0.75rem',
      }
    }));
    return (
      <TooltipComponent text={tooltipTitle} customClasses={style} placement="bottom">
        <span className="w-100 mr-2">
          <StatusBarChart
            data={payload}
            showLegend={false}
            showTooltip={false}
            customClassName="status-bar-v2__test-run-progress-w-100"
          />
        </span>
      </TooltipComponent>
    );
  },

  failureByLocationsDecorator: (name, row, totalFailedTestResults) => {
    const totalFailed = get(row, 'totalFailedResults');
    const payload = DecoratorConstants.statusBarItem(undefined, totalFailed, totalFailedTestResults - totalFailed);
    const title = t('test-case-execution');
    return (
      <Grid container>
        <Grid item xs={6} className="d-flex mt-1 mb-1 align-items-center">
          {DecoratorConstants.buildStatusBarChartWithTooltip(payload, title)}
        </Grid>
        <div className="d-flex mt-1 mb-1 align-items-center justify-content-start">
          <IconTotalRunsFailed className="ml-2 mr-2" />
          {totalFailed}
        </div>
      </Grid>
    );
  },

  mostCommonErrorDecorator: (name, row) => {
    const exception = get(row, 'exception');
    const exceptionMessage = getExceptionMessage(exception);
    const exceptionClass = getExceptionClass(exception);

    const errorDetailProps = {
      exception,
      lastSeen: row.lastSeen,
      lastTestResultName: row.lastTestResultName,
      lastTestResultUrl: row.lastTestResultUrl,
      stackTrace: row.stackTrace,
    };
    const tooltipTitle = <ErrorDetailPopper {...errorDetailProps} />;
    return (
      <TooltipErrorDetail text={tooltipTitle} placement="bottom-end">
        <div className="common-error-decorator mb-2 mt-2">
          <div className="exception">
            <span title={exceptionMessage}>
              {DecoratorConstants.truncateLeftStringDecorator(exceptionMessage, 30)}
            </span>
          </div>
          <div className="exception-class">
            <span title={exceptionClass}>
              {DecoratorConstants.truncateLeftStringDecorator(exceptionClass, 30)}
            </span>
          </div>
        </div>
      </TooltipErrorDetail>
    );
  },

  statusReleaseDecorator: (status) => {
    status = status.toUpperCase();
    const iconTitle = startCase(toLower(status));
    let statusIcon;
    switch (status) {
      case 'READY':
        statusIcon = <IconConfirmRelease />;
        break;
      case 'NOT_READY':
        statusIcon = <IconNotReady />;
        break;
      case 'EMPTY':
        statusIcon = <IconTrendNA />;
        break;
      default:
        break;
    }
    return <span title={iconTitle}>{statusIcon}</span>;
  },

  severityDecorator: (severity) => {
    const lowerCaseSeverity = severity.toLowerCase();
    return (
      <div className={`severity-decorator severity-decorator__${lowerCaseSeverity}`}>
        {severity}
      </div>
    );
  },

  videoInProcessingCaption: () => (<><LoadingIcon /> &nbsp; Video is processing...</>),

  renderInputFieldIcon: (option, iconField, preUrl) => {
    const iconUrl = get(option, iconField);
    const fullPath = preUrl ? preUrl + iconUrl : iconUrl;

    return (
      iconUrl && <img src={fullPath} alt="iconUrl" className="img-fluid icon-issue-type mr-2" />
    );
  },

  badgeTextDecorator: (text) => (
    <div className="badge-text">
      {text}
    </div>
  ),

  defectListWithBadgeDecorator: (row) => {
    const maxDefectShown = 3;
    let externalDefects = get(row, 'externalIssues', []);
    externalDefects = filter(externalDefects, (issue) => (!issue.deleted && !issue.migrationError));
    const totalDefects = externalDefects.length;

    const defectShown = externalDefects.slice(0, maxDefectShown);
    const buildDefectTooltip = (e) => {
      const btnLabel = t('open_in_jira');
      const link = e.url;

      return (
        <Grid container>
          <Grid item xs={12}>
            <div className="d-flex align-items-center">
              {DecoratorConstants.externalIssueIdDecorator(null, e, '', true)}
            </div>
            <p className="mb-0">{DecoratorConstants.externalNameDecorator('summary', e)}</p>

            {/* Project */}
            <Grid container className="d-flex align-items-center">
              <Grid item xs={4} className="defect-tooltip-title">
                <span>{t('table-header#project')}</span>
              </Grid>
              <Grid item xs={8}>
                <span>{DecoratorConstants.externalNameDecorator('externalProjectName', e)}</span>
              </Grid>
            </Grid>

            {/* Assignee */}
            <Grid container className="d-flex align-items-center">
              <Grid item xs={4} className="defect-tooltip-title">
                <span>{t('table-header#assignee')}</span>
              </Grid>
              <Grid item xs={8} className="d-flex align-items-center">
                <span className="mr-2">{DecoratorConstants.defectAssigneeDecorator(e)} </span>
                <span>{DecoratorConstants.externalNameDecorator('assignee', e)}</span>
              </Grid>
            </Grid>

            {/* Priority */}
            <Grid container className="d-flex align-items-center">
              <Grid item xs={4} className="defect-tooltip-title">
                <span>{t('table-header#priority')}</span>
              </Grid>
              <Grid item xs={8}>
                <span>{DecoratorConstants.defectPriorityDecorator(e, 'img-fluid icon-issue-type')}</span>
              </Grid>
            </Grid>

            {/* Severity */}
            {DecoratorConstants.defectSeverityDecorator(e) && (
              <Grid container className="d-flex align-items-center">
                <Grid item xs={4} className="defect-tooltip-title">
                  <span>{t('table-header#severity')}</span>
                </Grid>
                <Grid item xs={8}>
                  <span>{DecoratorConstants.defectSeverityDecorator(e)}</span>
                </Grid>
              </Grid>
            )}

            {/* Actions and Time */}
            <div className="d-flex justify-content-between">
              <span>
                <ExternalLinkButton externalLink={link}>
                  <span title={t('open_in_jira')} className="mr-2">{btnLabel}</span>
                  <Icon type="fa-regular" name="fa-external-link" textSize="12px" />
                </ExternalLinkButton>
              </span>
              <span className="defect-tooltip-title">
                <Icon title={t('incident#updatedAt')} type="fa-regular" name="fa-calendar" textSize="12px" className="mr-1" />
                <span>{DecoratorConstants.timeDecorator('updatedAt', e)}</span>
              </span>
            </div>
          </Grid>
        </Grid>
      );
    };

    const defectList = MFlags.testResultListEnhancementEnabled
      ? defectShown.map((e) => (
        <TooltipComponent
          key={e.id}
          text={buildDefectTooltip(e)}
          customClassName="defect-tooltip"
          customClasses
          placement="bottom"
        >
          <div>
            {DecoratorConstants.externalIssueIdDecorator(null, e, '', true)}
          </div>
        </TooltipComponent>
      ))
      : defectShown.map((e) => DecoratorConstants.externalIssueIdDecorator(null, e));

    if (totalDefects <= maxDefectShown) {
      return defectList;
    } else {
      const defectTooltipContent = externalDefects.map((e) => e.issueId).join(', ');
      const defectTooltip = <span> {defectTooltipContent} </span>;
      return (
        <>
          {defectList}
          <TooltipComponent text={defectTooltip} customClasses placement="bottom">
            {DecoratorConstants.badgeTextDecorator(`+${totalDefects - maxDefectShown}`)}
          </TooltipComponent>
        </>
      );
    }
  },

  buildButtonLinkTooltip: (children, text, btnLabel, link) => {
    const buildTooltip = (text, btnLabel, link) => (
      <div className="button-link-tooltip-title">
        {text}
        <br />
        <ExternalLinkButton externalLink={link}>
          {btnLabel}
        </ExternalLinkButton>
      </div>
    );
    const tooltipTitle = buildTooltip(text, btnLabel, link);

    return (
      <TooltipComponent text={tooltipTitle} customClassName="button-link-tooltip" customClasses placement="bottom">
        <div>
          {children}
        </div>
      </TooltipComponent>
    );
  },

  buildErrorMessageTooltip: (errorMessage) => {
    const buildTooltip = (text) => (
      <div className="error-message-tooltip-block">
        <pre className="text-failed-color pre-wrap">
          {text}
        </pre>
      </div>
    );
    const tooltipTitle = buildTooltip(errorMessage);

    return (
      <TooltipComponent text={tooltipTitle} customClassName="error-message-tooltip" customClasses placement="bottom-end">
        <pre className="text-failed-color text-wrap error-message-box mt-2 mb-0">
          {DecoratorConstants.truncateLeftStringDecorator(errorMessage, 80)}
        </pre>
      </TooltipComponent>
    );
  },

  emptyStateComponent: (text, link) => (
    <div className="text-center mt-4">
      <div className="custom-fields__empty-message"> {text} </div>
      <ExternalLinkButton externalLink={link}>
        {t('learn_more')} <OpenInNewIcon />
      </ExternalLinkButton>
    </div>
  ),

  circleLoading: () => (
    <div className="loading-type-field text-center mt-4">
      <CircularProgress size="2rem" thickness={5} />
      <div className="light-text">{t('table#loading-message')}</div>
    </div>
  ),

  buildExecutionNotificationGridLink: (runConfigurationName) => {
    const route = new Routes();
    const testRunLink = `${t('testcloud-execution#view-detail', { testRunName: runConfigurationName })}`;
    if (MFlags.htmlInjection) {
      return (
        <div>
          {t('testcloud-execution#new-execution')} <a href={route.grid_link}>{testRunLink}</a>
        </div>
      );
    } else {
      return `<div>
            ${t('testcloud-execution#new-execution')}
              <a href=${route.grid_link}>${testRunLink}</a>
           </div>`;
    }
  },

  buildExecutionNotificationHistoryLink: (runConfigurationName) => {
    const route = new Routes();
    const testRunLink = `${t('testcloud-execution#view-detail', { testRunName: runConfigurationName })}`;
    if (MFlags.htmlInjection) {
      return (
        <div>
          {t('testcloud-execution#new-execution')} <a href={route.test_runs_link}>{testRunLink}</a>
        </div>
      );
    } else {
      return `<div>
            ${t('testcloud-execution#new-execution')}
              <a href=${route.test_runs_link}>${testRunLink}</a>
           </div>`;
    }
  },

  uploadedDateDecorator: (uploadedDate) => {
    if (uploadedDate) {
      const time = Time.format(uploadedDate, Time.FULL_DATE_TIME_WITH_YEAR_FORMAT);
      return (
        <div>
          {t('schedule-v1#select-native-app#uploaded-on')} {time}
        </div>
      );
    }
    return null;
  },

  sessionTypesDecorator(sessionType) {
    return SessionTypeDecoratorMapping[sessionType.feature] || {
      tooltip: StringHelper.normalizeString(sessionType.feature),
      icon: <IconMultiPlatformGrey />,
      mainIcon: <IconMultiPlatform />,
    };
  },

};

export default DecoratorConstants;
