import { Button, ButtonToolbar, Col, CustomInput, Form, FormGroup, FormText, Label, Row } from 'reactstrap';
import React, { useState } from 'react';
import PropType from 'prop-types';
import { t } from '../../../i18n/t';
import Input from '../../../components/Input';
import DocumentLink from '../../../utils/DocumentLink';
import MFlags from '../../../models/MFlags';

function JiraSettingForm(props) {

  const { settingsForm, onChangeText, handleEnableXrayChange, testConnection, lg, xl } = props;
  const { serverUrl, username, password, clientId, clientSecret, enabledXray, id, defaultExternalProject } = settingsForm;
  const [isChangePW, setIsChangePW] = useState(false);

  const renderEnabledJiraLabel = () => (
    <>
      {t('jira-integration#enable-xray-integration-title')}
      &nbsp;
      <a
        href={DocumentLink.JIRA_XRAY_ENABLED_DOCS}
        target="_blank"
        rel="noreferrer noopener"
      >
        {t('learn_more')}
      </a>
    </>
  );

  return (
    <Row>
      <Col sm="12" md="8" lg={lg} xl={xl}>
        <Form className={t('jira-setting#form-class')} onSubmit={testConnection}>
          <FormGroup>
            <Label for="serverUrl" style={{ display: 'flex', flexDirection: 'row' }}>
              <div>{`${t('url')}`}</div>
              <div style={{ color: 'red', fontSize: 17, height: 13 }}>&nbsp;*</div>
            </Label>
            <Input
              onChange={(e) => onChangeText(e)}
              name="serverUrl"
              type="text"
              value={serverUrl}
              required
            />
          </FormGroup>
          <FormGroup>
            <Label for="username" style={{ display: 'flex', flexDirection: 'row' }}>
              <div>{t('username')}</div>
              <div style={{ color: 'red', fontSize: 17, height: 13 }}>&nbsp;*</div>
            </Label>
            <Input
              onChange={(e) => onChangeText(e)}
              name="username"
              type="text"
              value={username}
              required
            />
          </FormGroup>
          <FormGroup>
            <Label for="password" style={{ display: 'flex', flexDirection: 'row' }}>
              <div>{t('jira-setting#password-api-key')}</div>
              <div style={{ color: 'red', fontSize: 17, height: 13 }}>&nbsp;*</div>
            </Label>
            {(defaultExternalProject && MFlags.raJiraIntegrationEnhancementPhase2Enabled && !isChangePW) ?
              <Input
                onFocus={() => setIsChangePW(true)}
                name="password"
                type="password"
                value="********************************************************"
                required={!id}
              /> :
              <Input
                onBlur={() => setIsChangePW(false)}
                onChange={(e) => onChangeText(e)}
                name="password"
                type="password"
                value={password}
                autocomplete="new-password"
                required={!id}
              />}
            <FormText color="muted">
              <a
                href="https://confluence.atlassian.com/cloud/api-tokens-938839638.html"
                target="_blank"
                rel="noreferrer noopener"
              >
                {t('jira-setting#api-key-learmore')}
              </a>
            </FormText>
          </FormGroup>
          <FormGroup>
            <CustomInput
              id="enabledXray"
              name="enabledXray"
              type="switch"
              checked={enabledXray}
              label={renderEnabledJiraLabel()}
              onChange={handleEnableXrayChange}
              className="normal-label"
            />
          </FormGroup>
          {enabledXray &&
          <>
            <FormGroup>
              <Label for="clientId" style={{ display: 'flex', flexDirection: 'row' }}>
                <p>{t('jira-integration#clientId')}</p>
                <p style={{ color: 'red', fontSize: 17, height: 13 }}>&nbsp;*</p>
              </Label>
              <Input
                onChange={(e) => onChangeText(e)}
                name="clientId"
                type="text"
                value={clientId}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="clientSecret" style={{ display: 'flex', flexDirection: 'row' }}>
                <p>{t('jira-integration#clientSecret')}</p>
                <p style={{ color: 'red', fontSize: 17, height: 13 }}>&nbsp;*</p>
              </Label>
              <Input
                onChange={(e) => onChangeText(e)}
                name="clientSecret"
                type="password"
                value={clientSecret}
                required={!id}
              />
              <FormText color="muted">
                <a
                  href={DocumentLink.JIRA_XRAY_DOCS}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  {t('jira-integration#xray-learmore')}
                </a>
              </FormText>
            </FormGroup>
          </>}
          <ButtonToolbar>
            <Button data-trackid="test-jira-connection" type="submit" color="primary">
              {t('jira-setting#test-connection')}
            </Button>
          </ButtonToolbar>
        </Form>
      </Col>
    </Row>
  );
}

JiraSettingForm.propTypes = {
  lg: PropType.string,
  xl: PropType.string
};

JiraSettingForm.defaultProps = {
  lg: '6',
  xl: '5'
};

export default JiraSettingForm;
