import React from 'react';
import { Button, Form, FormGroup, Label, Row, Col, Card, CardBody } from 'reactstrap';
import { t } from '../../i18n/t';
import { SearchEntity } from '../../utils/Constants';
import Notification from '../../utils/Notification';
import Input from '../../components/Input';
import MContext from '../../models/MContext';
import PageComponent from '../../components/PageComponent';
import Services from '../../utils/Services';
import Routes from '../../utils/Routes';
import ObjectSummary from '../../components/summary/ObjectSummary';
import DefaultLayout from '../../components/DefaultLayout';
import { buildSearchCondition } from '../../components/search/SearchUtils';
import Moment from '../../utils/Moment';
import DatePicker from '../../components/DatePicker';
import Time from '../../utils/Moment';

class CreateEditBuild extends PageComponent {

  constructor(props) {
    super(props);
    this.teamId = MContext.teamId;
    this.projectId = MContext.projectId;
    this.releaseId = MContext.releaseId;
    this.buildId = MContext.buildId;
    this.state = this.getInitialState();
    this.isCreateBuild = !this.buildId;
    if (this.isCreateBuild) {
      this.meta.id = 'page-create-build';
      this.meta.title = t('create-build');
    } else {
      this.meta.id = 'page-update-build';
    }

    this.handleTextChange = this.handleTextChange.bind(this);
    this.getBuild = this.getBuild.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.updateBuild = this.updateBuild.bind(this);
    this.createBuild = this.createBuild.bind(this);
    this.renderHeader = this.renderHeader.bind(this);
    this.renderBody = this.renderBody.bind(this);
    this.disableInput = this.disableInput.bind(this);
    this.enableInput = this.enableInput.bind(this);
  }

  disableInput() {
    this.setState({ disabledInput: true });
  }

  enableInput() {
    this.setState({ disabledInput: false });
  }

  getInitialState() {
    return {
      formData: {
        name: '',
        releaseId: this.releaseId,
        date: null
      },
      release: null,
      disabledInput: false,
    };
  }

  componentDidMount() {
    this.getRelease();
    if (this.buildId) {
      this.getBuild();
    }
  }

  getRelease() {
    const params = {
      pagination: {
        page: 0,
        size: 1,
        sorts: 'order,desc',
      },
      conditions: [
        buildSearchCondition('id', '=', this.releaseId),
      ],
      type: SearchEntity.Release,
    };
    Services.search(params).then(({ content = [] }) => {
      const [release] = content;
      if (release) {
        this.setState({ release });
      }
    });
  }

  getBuild() {
    const params = {
      pagination: {
        page: 0,
        size: 1,
        sorts: 'order,desc',
      },
      conditions: [
        buildSearchCondition('id', '=', this.buildId),
      ],
      type: SearchEntity.Build,
    };
    Services.search(params)
      .then(({ content }) => {
        const build = content[0];

        this.meta.title = t('build#title', { name: build.name });
        const formData = this.state.formData;
        this.setState({
          formData: { ...formData, ...build },
        });
      });
  }

  handleTextChange(event) {
    const name = event.target.name;
    const value = event.target.value;
    const formData = this.state.formData;
    formData[name] = value;
    this.setState(formData);
  }

  handleDateChange(name, value) {
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        [name]: value,
      }
    }));
  }

  onSubmit(e) {
    e.preventDefault();
    this.disableInput();
    if (this.isCreateBuild) {
      return this.createBuild();
    } else {
      return this.updateBuild();
    }
  }

  createBuild() {
    const { formData } = this.state;
    Services.createBuild(formData, this.buildId)
      .then((build) => {
        Notification.pushSuccess(`Build ${build.name} was created.`);
        Routes.goToViewReleaseLink(this.releaseId);
      })
      .finally(() => {
        this.enableInput();
      });
  }

  updateBuild() {
    const { formData } = this.state;
    Services.updateBuild(formData, this.buildId)
      .then((build) => {
        Notification.pushSuccess(`Build ${build.name} was updated.`);
        Routes.goToViewReleaseLink(this.releaseId);
      })
      .finally(() => {
        this.enableInput();
      });
  }

  renderObjectSummary() {
    const { formData: { name }, release } = this.state;
    const urlParams = {
      releaseId: this.releaseId,
      releaseName: release?.name,
      releases: t('releases'),
      buildUpdate: this.isCreateBuild
        ? t('builds#create')
        : t('build#title', { name }),
    };
    return (
      <ObjectSummary params={urlParams} />
    );
  }

  renderHeader() {
    return (
      <>
        {this.renderObjectSummary()}
      </>
    );
  }

  renderBody() {
    const { formData, disabledInput } = this.state;
    const { name, description, date } = formData;
    const submitButton = this.isCreateBuild
      ? t('create')
      : t('update');

    const dataTrackId = this.isCreateBuild ? 'create-build' : 'update-build';
    return (
      <Card>
        <CardBody>
          <Row>
            <Col sm="12" md="8" lg="6" xl="5">
              <Form data-trackid={dataTrackId} onSubmit={this.onSubmit}>
                <FormGroup>
                  <Label for="name">{t('name')}</Label>
                  <Input
                    id="name"
                    name="name"
                    value={name}
                    disabled={disabledInput}
                    onChange={this.handleTextChange}
                    type="text"
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="description">{t('description')}</Label>
                  <Input
                    id="description"
                    name="description"
                    value={description}
                    onChange={this.handleTextChange}
                    disabled={disabledInput}
                    type="textarea"
                    rows="5"
                  />
                </FormGroup>
                <FormGroup>
                  <Label>{t('date')}</Label>
                  <DatePicker
                    name="date"
                    inputFormat={Moment.DF_DATE_FORMAT}
                    useDateTime
                    ampm={false}
                    value={date}
                    minDate={Time.MIN_DATE}
                    maxDate={Time.MAX_DATE}
                    disabled={disabledInput}
                    onChange={(value) => this.handleDateChange('date', value)}
                  />
                </FormGroup>
                <Button type="submit" color="primary" disabled={disabledInput}>{submitButton}</Button>
              </Form>
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  }

  render() {
    return (
      <DefaultLayout
        renderHeader={this.renderHeader}
        renderBody={this.renderBody}
      />
    );
  }
}

export default CreateEditBuild;
