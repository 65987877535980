import PropTypes from 'prop-types';
import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { t } from '../../i18n/t';
import Notification from '../../utils/Notification';
import Services from '../../utils/Services';
import MFlags from '../../models/MFlags';

class DeleteAgentDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.toggle = this.toggle.bind(this);
    this.removeAgent = this.removeAgent.bind(this);
  }

  componentDidMount() {
    if (MFlags.moveAgentToOrgLevelPhase2Enabled) {
      this.getAgent();
    }
  }

  getInitialState() {
    return {
      modal: false,
    };
  }

  getAgent() {
    const { id } = this.props.agent;
    if (id) {
      Services.getAgent(id)
        .then((agent) => {
          this.setState({
            filteredLinkedProjects: agent.filteredLinkedProjects,
          });
        });
    }
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  removeAgent() {
    const {
      agent,
      afterRemoval,
    } = this.props;

    Services.removeAgent(agent.id)
      .then(() => {
        Notification.pushSuccess(`The agent ${agent.name} has been removed.`);
        if (afterRemoval) {
          afterRemoval();
        }
        this.toggle();
      })
      .catch(() => this.toggle());
  }

  render() {
    const { moveAgentToOrgLevelPhase2Enabled } = MFlags;
    const { modal, filteredLinkedProjects } = this.state;
    const { agent, ...props } = this.props;
    if (agent === undefined) {
      return null;
    }

    if (moveAgentToOrgLevelPhase2Enabled) {
      if (filteredLinkedProjects === undefined) {
        return null;
      }
    }

    return (
      <Modal isOpen={modal} toggle={this.toggle} {...props}>
        <ModalHeader toggle={this.toggle}>{t('agent#remove')}</ModalHeader>
        {moveAgentToOrgLevelPhase2Enabled && (
        <ModalBody>
          <p>
            Deleting an Agent will sever its connection with the linked projects and this action cannot be undone.&nbsp;
            Please make sure before take action. <strong>{agent.name}</strong> is currently associated with <strong>{filteredLinkedProjects.length} project(s)</strong>.
          </p>
        </ModalBody>
        )}
        {!moveAgentToOrgLevelPhase2Enabled && (
        <ModalBody>
          This action cannot be undone. Are you sure you want to delete <strong>{agent.name}</strong>?
        </ModalBody>
        )}
        <ModalFooter>
          <Button data-trackid="delete-agent" color="danger" onClick={this.removeAgent}>Delete</Button>
          <Button color="secondary" onClick={this.toggle}>Cancel</Button>
        </ModalFooter>
      </Modal>
    );
  }
}

DeleteAgentDialog.propTypes = {
  afterRemoval: PropTypes.func,
};

export default DeleteAgentDialog;
