import { SnackbarProvider } from 'notistack';
import { node } from 'prop-types';
import React from 'react';
import { makeStyles } from '@mui/styles';
import { theme } from '@katalon-studio/katalon-ui';
import { ThemeProvider } from '@mui/material';

const useStyles = makeStyles({
  root: {
    flexDirection: 'column-reverse',
    maxHeight: 'none !important',
  },
});

function NotificationProvider({ children }) {
  const classes = useStyles();
  const maxSnack = 10;
  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        maxSnack={maxSnack}
        classes={{
          containerRoot: classes.root,
        }}
      >
        {children}
      </SnackbarProvider>
    </ThemeProvider>
  );
}

NotificationProvider.propTypes = {
  children: node,
};

export default NotificationProvider;
