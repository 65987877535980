import React, { Component } from 'react';
import { Button, Card, CardBody, CardHeader, Collapse, Form, FormGroup, Label } from 'reactstrap';
import { t } from '../../i18n/t';
import Input from '../../components/Input';
import {
  CONFIG_TYPE_OPTION_MAP,
  getTSC,
  getTestSuite
} from './ConfigurePlanUtils';
import Routes from '../../utils/Routes';
import { ConfigType } from '../../utils/Constants';
import { IconAngleRight, IconDown } from '../../images/KitIcons';
import DecoratorConstants from '../../utils/DecoratorConstants';
import Link from '../../components/Link';

class SummaryPlan extends Component {

  constructor(props) {
    super(props);
    this.state = {
      testSuite: {},
      testSuiteCollection: '',
      collapsed: false
    };
    this.renderSummary = this.renderSummary.bind(this);
  }

  componentDidMount() {
    const { configType } = this.props.runConfiguration;
    if (configType === ConfigType.TSC) {
      this.getTSC();
    } else if (configType === ConfigType.TS) {
      this.getTestSuite();
    }
  }

  toggleCollapse() {
    this.setState((prevState) => ({
      collapsed: !prevState.collapsed
    }));
  }

  getTSC() {
    const { runConfiguration } = this.props;
    getTSC(runConfiguration.testSuiteCollectionId)
      .then((testSuiteCollection) => {
        this.setState({
          testSuiteCollection: testSuiteCollection.name
        });
      });
  }

  getTestSuite() {
    const { runConfiguration } = this.props;
    if (runConfiguration.testSuiteId) {
      getTestSuite(runConfiguration.testSuiteId)
        .then((testSuite) => {
          this.setState({
            testSuite
          });
        });
    }
  }

  getConfigName() {
    const { testSuiteCollection, testSuite: { path = '', name = '' } } = this.state;
    const { configType, command, genericCommand } = this.props.runConfiguration;
    let configName = '';
    if (configType === ConfigType.TSC) {
      configName = testSuiteCollection;
    } else if (configType === ConfigType.COMMAND) {
      configName = command;
    } else if (configType === ConfigType.TS) {
      configName = DecoratorConstants.addPathOfName(path, name);
    } else {
      configName = genericCommand;
    }
    return configName;
  }

  renderSummary() {
    const { testProject, projectId, configType, timeOut } = this.props.runConfiguration;

    const configName = this.getConfigName();
    const configTypeName = CONFIG_TYPE_OPTION_MAP[configType].label;

    const constructedLink = new Routes({
      projectId,
      testProjectId: testProject.id,
    });

    return (
      <Form>
        <FormGroup>
          <Label for="test_project">{t('test-project')}</Label>
          <Input
            plaintext="true"
            id="test_project"
          >
            <Link href={constructedLink.test_project_link}>{testProject.name}</Link>
          </Input>
        </FormGroup>
        <FormGroup>
          <Label for="config_type">{configTypeName}</Label>
          <Input
            plaintext="true"
            id="config_type"
          >
            {configName}
          </Input>
        </FormGroup>
        <FormGroup>
          <Label for="time_out">Timeout In Minutes</Label>
          <Input
            plaintext="true"
            id="time_out"
          >
            {timeOut}
          </Input>
        </FormGroup>
      </Form>
    );
  }

  render() {
    const { collapsed } = this.state;
    return (
      <Card>
        <CardHeader className="d-flex align-items-center justify-content-between">
          <div className="d-flex">
            <div className="card-header-title">{t('run-configuration#summary')}</div>
            <Button
              className="mr-1"
              onClick={() => this.toggleCollapse()}
              color="link"
            >
              {collapsed ? <IconAngleRight /> : <IconDown />}
            </Button>
          </div>
        </CardHeader>
        <Collapse isOpen={!collapsed}>
          <CardBody>
            {this.renderSummary()}
          </CardBody>
        </Collapse>
      </Card>
    );
  }
}

export default SummaryPlan;
