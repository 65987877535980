import React, { useMemo } from 'react';
import {
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Text,
} from 'recharts';
import PropTypes from 'prop-types';
import { startCase, toLower } from 'lodash';
import colors from '../../../scss/colors.scss';
import CustomChartTooltip from './CustomChartTooltip';

const MEASUREMENT_SPAN_ID = 'recharts_measurement_span';

const MEASUREMENT_SPAN_STYLE = 'position: absolute; top: -20000px; left: 0px; padding: 0px; margin: 0px; border: none; white-space: pre;';

// refer from rechart source https://github.com/recharts/recharts/blob/master/src/util/DOMUtils.ts
const getTextWidth = (text) => {
  try {
    let measurementSpan = document.getElementById(MEASUREMENT_SPAN_ID);
    if (!measurementSpan) {
      measurementSpan = document.createElement('span');
      measurementSpan.setAttribute('id', MEASUREMENT_SPAN_ID);
      measurementSpan.setAttribute('aria-hidden', 'true');
      measurementSpan.setAttribute('style', MEASUREMENT_SPAN_STYLE);
      document.body.appendChild(measurementSpan);
    }

    measurementSpan.textContent = text;

    const rect = measurementSpan.getBoundingClientRect();

    return rect.width;
  } catch (e) {
    return 0;
  }
};

const EllipsisAxisTick = ({ maxLines = 1, payload, ...rest }) => {

  const ellipsisMemo = useMemo(() => {

    const text = payload.value;
    let tempText = text;
    let tempTextWidth = getTextWidth(tempText);

    // rest.width is width of YAxis component default to 60.
    if (tempTextWidth <= rest.width) {
      return text;
    }

    // slice the text using the ratio
    const ratio = rest.width / tempTextWidth;
    const trimIndex = Math.round(tempText.length * ratio);
    tempText = tempText.substring(0, trimIndex);

    // optimize the result by remove/add one by one char
    tempTextWidth = getTextWidth(tempText);
    if (tempTextWidth > rest.width) {
      while (getTextWidth(`${tempText}...`) > rest.width) {
        tempText = tempText.slice(0, -1);
      }
    } else {
      while (getTextWidth(`${tempText}...`) < rest.width) {
        tempText += text[tempText.length];
      }
      // adding lastime may cause the width large than expected width. Then recheck.
      if (getTextWidth(`${tempText}...`) > rest.width) {
        tempText = tempText.slice(0, -1);
      }
    }

    return `${tempText}...`;
  }, [maxLines, rest.width, payload.value]);

  return (
    <g>
      <Text {...rest}>
        {ellipsisMemo}
      </Text>
      <title>{payload.value}</title>
    </g>
  );
};

const VerticalBarChart = ({ data, payload, aspect }) => {

  const barRender = () => {
    const rows = [];
    payload.forEach((item) => {
      rows.push(
        <Bar
          dataKey={item.dataKey}
          fill={item.color}
          isAnimationActive={false}
        />
      );
    });
    return rows;
  };

  const formatLegendName = (name) => startCase(toLower(name));

  return (
    <ResponsiveContainer minWidth={200} aspect={aspect} position="center" className="custom-overview-chart">
      <ComposedChart
        layout="vertical"
        data={data}
        barCategoryGap="10%"
        barGap="2%"
        margin={{
          top: 20,
          right: 20,
          bottom: 10,
          left: 10,
        }}
      >
        <CartesianGrid horizontal={false} stroke={colors.cardBorder} strokeWidth={0.5} />
        <XAxis textAnchor="middle" type="number" axisLine={false} tickLine={false} />
        <YAxis dataKey="name" type="category" axisLine={false} tickLine={false} tick={<EllipsisAxisTick maxLines={1} />} />
        <Tooltip content={<CustomChartTooltip />} />
        <Legend iconSize={8} iconType="square" formatter={formatLegendName} verticalAlign="bottom" align="right" />
        {barRender()}
      </ComposedChart>
    </ResponsiveContainer>
  );
};

VerticalBarChart.propTypes = {
  /** *
     * data: { type: Array, description: Data of chart }
     Example:
     const data = [
        {
            name: 'Page A',
            PASSED: 4000,
            FAILED: 2400,
            ERROR: 2400,
            INCOMPLETE: 4000
        },
        {
            name: 'Page B',
            PASSED: 3000,
            FAILED: 1398,
            ERROR: 2210,
            INCOMPLETE: 1232
        },
        {
            name: 'Page C',
            PASSED: 2780,
            FAILED: 2000,
            ERROR: 1890,
            INCOMPLETE: 1232
        }
     ];
     */
  data: PropTypes.array.isRequired,
  /** *
     * payload: { type: Array of objects, description: Define element payload of chart }
     Example:
     const payload = [
        {
            dataKey: TestRunStatus.FAILED,
            color: colors.failed,
        },
        {
            dataKey: TestRunStatus.PASSED,
            color: colors.passed,
        },
        {
            dataKey: TestRunStatus.ERROR,
            color: colors.error,
        },
        {
            dataKey: TestRunStatus.INCOMPLETE,
            color: colors.incomplete,
        }
     ];
     */
  payload: PropTypes.array.isRequired,
  /** *
     * aspect: {type: number. description: The gap ratio between the chart element}
     */
  aspect: PropTypes.number
};

VerticalBarChart.defaultProps = {
  data: [],
  payload: [],
  aspect: 3
};
export default VerticalBarChart;


