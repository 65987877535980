import React, { useEffect, useMemo, useState } from 'react';
import { CustomInput, FormText, Label } from 'reactstrap';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';

import {
  Collapse,
  Checkbox, FormControlLabel, Stack, Typography
} from '@mui/material';
import { t } from '../../i18n/t';
import Link from '../Link';
import LoadingIcon from '../../../images/icons/katalonui/LoadingIcon';
import DocumentLink from '../../utils/DocumentLink';
import Select from '../Select';
import TooltipComponent from '../TooltipComponent';

function ToggleTunnel({
  isLoading,
  isChecked,
  handleToggle,
  enableApiTesting,
  updateEnableApiTesting,
  routes,
  activeTunnels,
  tunnelId,
  handleSelectedTunnelChange,
}) {

  const autoSelectTunnelOption = {
    label: t('schedule-v1#select-tunnel#auto-select-tunnel'),
    value: '*',
  };

  const [selectedTunnel, setSelectedTunnel] = useState(autoSelectTunnelOption);

  const activeTunnelsOptions = useMemo(() => {
    const options = activeTunnels.map((tunnel) => ({
      label: `${tunnel.username} (${tunnel.id})`,
      value: tunnel.id,
      postBadge: tunnel.version,
    }));
    return [autoSelectTunnelOption, ...options];
  }, [activeTunnels]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setSelectedTunnel(activeTunnelsOptions.find((tunnel) => tunnel.value === tunnelId) || autoSelectTunnelOption);
  }, [activeTunnelsOptions]); // eslint-disable-line react-hooks/exhaustive-deps

  const onChangeSelectedTunnel = (event) => {
    if (event.value === autoSelectTunnelOption.value) {
      handleSelectedTunnelChange(null);
    } else {
      handleSelectedTunnelChange(event.value);
    }
    setSelectedTunnel(event.value);
  };

  return (
    <Stack direction="row">
      <CustomInput
        id="testcloud-tunnel-enable"
        type="switch"
        name="testcloud-tunnel-enable"
        checked={isChecked}
        onChange={handleToggle}
        className="normal-label"
      />
      <Stack spacing={0.5} flex className="w-100">
        <Label>{t('test-cloud-local-testing')}</Label>
        <Stack spacing={0.5} direction="row">
          <span>
            {t('test-cloud-local-testing#description')}
          </span>
          <Link
            href={DocumentLink.TEST_CLOUD_LOCAL_TESTING}
            target="_blank"
            rel="noopener noreferrer"
          >{t('learn_more')}.
          </Link>
        </Stack>

        <div className="tunnel-collapse-container">
          <Collapse in={isChecked}>
            {isLoading && (
            <Box sx={{ display: 'flex' }}>
              <LoadingIcon /> <span className="tunnel-message-checking"> {t('schedule-v1#select-tunnel#checking')} </span>
            </Box>
            )}
            {!isLoading &&
            <Box>
              <Select
                id="selectActiveTunnels"
                value={selectedTunnel}
                onChange={onChangeSelectedTunnel}
                options={activeTunnelsOptions}
                isCustom
                placeholder="Select tunnel..."
              />
              <FormText>
                {t('schedule-v1#select-tunnel#desc')}
                <Link href={routes.test_cloud_tunnel_link} target="_blank" rel="noopener noreferrer">{t('schedule-v1#select-tunnel#how-to-setup')}</Link>
              </FormText>
            </Box>}
          </Collapse>
        </div>
        <div className="d-flex">
          {updateEnableApiTesting !== undefined && isChecked && (
          <Stack flex direction="row">
            <FormControlLabel
              className="m-0 mt-2"
              control={
                <Checkbox
                  className="p-0 mr-1"
                  checked={enableApiTesting}
                  onChange={updateEnableApiTesting}
                  sx={{
                    '& .MuiSvgIcon-root': { fontSize: 20 },
                    color: '#646464',
                  }}
                />
                  }
              label={
                <Typography
                  variant="body2"
                  sx={{
                    color: (theme) =>
                      theme.palette.text.primary,
                  }}
                >
                  {t('test-cloud-local-testing#enable-api-testing')}
                </Typography>
            }
            />
            <TooltipComponent text={t('test-cloud-local-testing#enable-api-testing#warn-message')} />
          </Stack>
          )}
        </div>
      </Stack>
    </Stack>
  );
}

ToggleTunnel.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isChecked: PropTypes.bool.isRequired,
  enableApiTesting: PropTypes.bool.isRequired,
  handleToggle: PropTypes.func.isRequired,
  updateEnableApiTesting: PropTypes.func.isRequired,
  tunnelId: PropTypes.string.isRequired,
  handleSelectedTunnelChange: PropTypes.func.isRequired,
  routes: PropTypes.arrayOf(PropTypes.string).isRequired,
  activeTunnels: PropTypes.array,
};

export default ToggleTunnel;
