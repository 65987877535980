import React, { useEffect, useState } from 'react';
import TextField from '../TextField';
import { JiraIssueOption } from '../../models/model/JiraIssueOption';

interface TextFieldInputProps {
  id: string;
  changeValueContributor: (key: string, value: string | number) => void;
  isTextArea?: boolean;
  placeholder?: string;
  defaultValue?: JiraIssueOption;
  isNumber?: boolean; // only work with simple text field
  required?: boolean;
}

function TextFieldInput(props: TextFieldInputProps) {
  const {
    id,
    changeValueContributor,
    isTextArea,
    placeholder,
    defaultValue,
    isNumber,
    required
  } = props;

  const [textValue, setTextValue] = useState<string | number>();

  const updateAndFormatValue = (value: string) => {
    const formatValue = isNumber ? Number(value) : value;
    setTextValue(formatValue);
    changeValueContributor(id, formatValue);
  };

  const handleChangeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    updateAndFormatValue(value);
  };

  useEffect(() => {
    if (defaultValue) {
      updateAndFormatValue(String(defaultValue));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps


  const renderSimpleTextField = () => (
    <TextField
      required={required}
      size="small"
      variant="outlined"
      fullWidth
      placeholder={placeholder}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChangeValue(event)}
      value={textValue}
      defaultValue={defaultValue}
      type={isNumber ? 'number' : null}
    />
  );

  const renderTextAreaField = () => (
    <TextField
      required={required}
      size="small"
      variant="outlined"
      multiline
      rows={5}
      fullWidth
      value={textValue}
      placeholder={placeholder}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChangeValue(event)}
      defaultValue={defaultValue}
    />
  );

  return (
    <div>
      { isTextArea ? renderTextAreaField() : renderSimpleTextField() }
    </div>
  );
}

export default TextFieldInput;
