import React, { useState } from 'react';
import { DialogContent, DialogContentText } from '@mui/material';
import CloseableDialogComponent from './CloseableDialogComponent';
import { IconExceeded } from '../../images/CustomNewIcon';
import DocumentLink from '../../utils/DocumentLink';
import { t } from '../../i18n/t';

export default function OrganizationQuotaExceedDialog({ message }) {
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(!open);
  };

  return (
    <CloseableDialogComponent
      isOpen={open}
      handleClose={handleClose}
      headerStyle="header"
    >
      <DialogContent>
        <div className="image">
          <IconExceeded />
        </div>
        <DialogContentText>
          <div className="title">
            {message}
            <a
              href={DocumentLink.KATALON_TESTOPS_PACKAGE_DETAILS}
              target="_blank"
              rel="noreferrer noopener"
            >
              {t('katalon_testops_pricing')}
            </a>
            .
          </div>
        </DialogContentText>
      </DialogContent>
    </CloseableDialogComponent>
  );
}

