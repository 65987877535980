import { debounce } from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { CellMeasurer, CellMeasurerCache } from 'react-virtualized';
import ResizeObserver from 'rc-resize-observer';
import PropTypes from 'prop-types';

import { DEBOUNCE_IN_MILLIS, PAGE_SIZE } from './constants';
import InfiniteLoaderList from './InfiniteLoaderList';
import NotificationItem from './NotificationItem';
import NotificationItemPlaceholder from './NotificationItemPlaceholder';
import { t } from '../../i18n/t';

/**
 * List of notifications component.
 */
const Notifications = ({
  notifications,
  loadFinished,
  loadMore,
  markNotificationAsRead,
  onNotificationItemClick,
  onFinishedMount
}) => {
  const measurementCache = useRef(new CellMeasurerCache({
    defaultHeight: 50,
    fixedWidth: true
  }));

  const rowCount = useMemo(
    () => (loadFinished ? notifications.length : notifications.length + 1),
    [notifications, loadFinished]
  );

  const isItemLoaded = useCallback(
    ({ index }) => index < notifications.length,
    [notifications]
  );

  const loadMoreRows = debounce(({ startIndex }) => {
    const page = Math.floor(startIndex / PAGE_SIZE);
    loadMore(page);
  }, DEBOUNCE_IN_MILLIS);



  const notificationRowRender = useCallback(({ index, key, style, parent }) => {
    const isLoaded = isItemLoaded({ index });
    return (
      <CellMeasurer
        cache={measurementCache.current}
        columnIndex={0}
        key={key}
        parent={parent}
        rowIndex={index}
      >
        {({ measure, registerChild }) => (
          <div style={style} key={key} ref={registerChild}>
            <ResizeObserver onResize={measure}>
              {isLoaded && (<NotificationItem
                key={key}
                measureHeight={measure}
                notification={notifications[index]}
                index={index}
                onMarkAsReadClick={(notification) => markNotificationAsRead(notification, index)}
                onClick={() => onNotificationItemClick(notifications[index], index)}
              />)}
              {!isLoaded && (<NotificationItemPlaceholder />)}
            </ResizeObserver>
          </div>
        )}
      </CellMeasurer>
    );
  }, [notifications, isItemLoaded, measurementCache]);

  useEffect(() => {
    onFinishedMount();
  }, []);

  return (
    <>
      {notifications.length > 0 && (
        <div className="top-nav__notification-list">
          <InfiniteLoaderList
            rowRenderer={notificationRowRender}
            loadMoreRows={loadMoreRows}
            isRowLoaded={isItemLoaded}
            rowCount={rowCount}
            measurementCache={measurementCache.current}
          />
        </div>
      )}
      {loadFinished && notifications.length === 0 && (
        <div className="top-nav__notification-list__empty">
          <div className="text-center">{t('notifications#empty')}</div>
        </div>
      )}
    </>
  );
};

Notifications.propTypes = {
  notifications: PropTypes.array.isRequired,
  loadFinished: PropTypes.bool.isRequired,
  loadMore: PropTypes.func.isRequired,
  markNotificationAsRead: PropTypes.func.isRequired,
  onNotificationItemClick: PropTypes.func.isRequired,
  onFinishedMount: PropTypes.func.isRequired,
};

export default Notifications;
