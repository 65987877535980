import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { groupBy, map, values } from 'lodash';
import { t } from '../../../i18n/t';
import { buildSearchFunction } from '../../../components/search/SearchUtils';
import { SearchEntity, TestRunStatus, ProjectStatisticsType, ResultFilterType } from '../../../utils/Constants';
import { TestResultChartPayload } from '../../../utils/ChartHelper';
import DataLoader from '../../../components/table/DataLoader';
import TimeSelect from '../../../components/select/TimeSelect';
import Select from '../../../components/Select';
import { next } from '../../../utils/Count';
import Time from '../../../utils/Moment';
import ReportHelper from '../../../utils/ReportHelper';
import TooltipComponent from '../../../components/TooltipComponent';
import HorizontalLineChart from '../../../components/chart/HorizontalLineChart';
import NormalCard from '../../../components/card/NormalCard';
import MFlags from '../../../models/MFlags';

const optionsResultFilter = [
  {
    value: ResultFilterType.ALL_RESULTS,
    label: t('result-filter-all-results'),
  },
  {
    value: ResultFilterType.LATEST_RESULTS,
    label: t('result-filter-latest-result'),
  },
];

const ExecutionTrendTestResult = ({ defaultSearchConditions, dataLoaderRef, filterDateTimeValue }) => {
  const [timeFilter, setTimeFilter] = useState(ProjectStatisticsType.MONTHLY);
  const [resultFilter, setResultFilter] = useState(ResultFilterType.ALL_RESULTS);
  const [unit, setUnit] = useState(ReportHelper.getStatisticsType(timeFilter));
  const [groupByCondition, setGroupByCondition] = useState(`group_by_${unit}`);

  const handleTimeFilterChange = (event, option) => {
    setTimeFilter(option);
    setUnit(ReportHelper.getStatisticsType(option.value));
    setGroupByCondition(`group_by_${ReportHelper.getStatisticsType(option.value)}`);
  };

  const handleResultFilterChange = (event, option) => setResultFilter(option.value);

  const renderHeader = () => (
    <div className="overview-header-sub-title">
      {t('execution-trend-by-test-result')}
      <TooltipComponent
        text={t('execution-trend-by-test-result-tooltip')}
        placement="bottom-end"
        arrow
      />
    </div>
  );

  const renderCustomControl = () => (
    <div className="d-flex ml-auto mr-4">
      <TimeSelect id="time-filter" className="overview-filter" size="small" defaultValue={timeFilter} onSelectChange={handleTimeFilterChange} />
      <Select
        id="result-filter"
        useAutocomplete
        options={optionsResultFilter}
        value={resultFilter}
        disableClearable
        blurOnSelect
        className="overview-filter ml-2"
        size="small"
        onChange={handleResultFilterChange}
      />
    </div>
  );

  const convertDataForRender = (data) => {
    const { startTime, endTime } = filterDateTimeValue;
    const statusList = values(TestRunStatus);

    const isShowAllResults = (resultFilter === ResultFilterType.ALL_RESULTS);
    let extractedData = data;
    if (isShowAllResults) {
      extractedData = data.map((item) => item.content);
    }

    const groupByData = groupBy(extractedData, 'time');
    let newValueTemplate = {};

    statusList.forEach((status) => {
      newValueTemplate = {
        ...newValueTemplate,
        [status]: 0
      };
    });

    // Convert startTime from filter to get the first day by unit(day, week, month)
    const startTimeByUnit = Time.startOf(startTime, unit);
    const times = Time.timeBetween(startTimeByUnit, endTime, `${unit}s`, Time.LOCAL_DATE_FORMAT);

    const resultData = map(times, (time) => {
      const val = {
        time,
        ...newValueTemplate,
      };
      if (groupByData[time]) {
        groupByData[time].forEach(({ status, total }) => {
          val[status] = total;
        });
      }
      return val;
    });
    return resultData;
  };

  const renderChart = (data) => {
    const convertedData = convertDataForRender(data);
    const payload = TestResultChartPayload;
    return (
      <HorizontalLineChart
        data={convertedData}
        payload={payload}
      />
    );
  };

  const renderAllResultsChart = () => (
    <DataLoader
      title={renderHeader()}
      key={next()}
      ref={dataLoaderRef}
      entityType={SearchEntity.ExecutionTestResult}
      defaultSearchConditions={defaultSearchConditions}
      defaultSearchFunctions={[
        buildSearchFunction('time', groupByCondition, ['startTime']),
        buildSearchFunction('total', 'count', ['id']),
      ]}
      groupBys={['status']}
      defaultSort={['time,desc']}
      render={renderChart}
      cardClassName="overview-card-border shadow-none"
      useCache={!MFlags.testPerformanceForReportAndAnalyticsEnabled}
      useRefreshButton={false}
      useCollapseButton={false}
      headerComponent={NormalCard}
      bodyComponent={NormalCard}
      customControl={renderCustomControl()}
      showEmptyMessage
      pageSize={300}
    />
  );

  const renderLatestResultsChart = () => (
    <DataLoader
      title={renderHeader()}
      key={next()}
      ref={dataLoaderRef}
      entityType={SearchEntity.LastExecutionTestResultStatistics}
      defaultSearchConditions={defaultSearchConditions}
      defaultSearchFunctions={[
        buildSearchFunction('time', groupByCondition, ['start_time'])
      ]}
      render={renderChart}
      cardClassName="overview-card-border shadow-none"
      useCache={!MFlags.testPerformanceForReportAndAnalyticsEnabled}
      useRefreshButton={false}
      useCollapseButton={false}
      headerComponent={NormalCard}
      bodyComponent={NormalCard}
      customControl={renderCustomControl()}
      showEmptyMessage
      pageSize={300}
    />
  );

  const renderBody = () => {
    const isShowAllResults = (resultFilter === ResultFilterType.ALL_RESULTS);
    if (isShowAllResults) {
      return renderAllResultsChart();
    }
    return renderLatestResultsChart();
  };

  return (
    <>
      {renderBody()}
    </>
  );
};

ExecutionTrendTestResult.propTypes = {
  defaultSearchConditions: PropTypes.array,
  filterDateTimeValue: PropTypes.object,
};

ExecutionTrendTestResult.defaultProps = {
  defaultSearchConditions: [],
  filterDateTimeValue: {},
};


export default ExecutionTrendTestResult;

