import React, { useEffect, useRef, useState } from 'react';
import { ListItem, MenuItem } from '@mui/material';
import { Button, ButtonToolbar, Form } from 'reactstrap';
import { cloneDeep, find, remove } from 'lodash';
import PropTypes from 'prop-types';
import { t } from '../../../i18n/t';
import DropDownFilter from './DropDownFilter';
import CheckBox from '../../CheckBox';

const MultiSelectFilterComponent = (props) => {

  const {
    multiOptions,
    renderOption,
    loading,
    searchKey,
    label,
  } = props;
  const [selectedOptions, setSelectedOptions] = useState([]);
  const filterMenu = useRef(null);

  const renderNoResult = (
    <MenuItem>{t('no-result')}</MenuItem>
  );

  const renderLoading = (
    <MenuItem>{t('table#loading-message')}</MenuItem>
  );

  useEffect(() => {
    if (!props.value) {
      setSelectedOptions([]);
    }
  }, [props.value]);

  const buttonLabel = () => (
    <span className="font-weight-bold">
      {label}
      { selectedOptions.length > 0 ?
        <span className="ml-1 custom-field-filter__badge"> {selectedOptions.length} </span> :
        '' }
    </span>
  );

  const buildFilterValue = () => selectedOptions.map((value) => value[searchKey]);

  const applyFilter = () => {
    const filterValue = buildFilterValue();
    if (filterValue.length > 0) {
      filterMenu.current.applyFilter(filterValue);
    } else {
      filterMenu.current.applyFilter(null);
    }
  };

  const clear = () => {
    setSelectedOptions([]);
    filterMenu.current.applyFilter(null);
  };

  const isExistingOption = (option) => !!find(selectedOptions, option);

  const onSelect = (option) => {
    const newSelectedOptions = cloneDeep(selectedOptions);
    if (isExistingOption(option)) {
      remove(newSelectedOptions, option);
    } else {
      newSelectedOptions.push(option);
    }
    setSelectedOptions(newSelectedOptions);
  };

  const renderOptions = !multiOptions.length ? renderNoResult : multiOptions.map((option) => (
    <>
      <ListItem
        key={option[searchKey]}
        onClick={() => {
          onSelect(option);
        }}
      >
        <CheckBox useMUI checked={isExistingOption(option)} className="multi-filter-check-box-icon" />
        {renderOption(option)}
      </ListItem>
    </>
  ));

  const renderFilter = () => (
    <>
      <Form className="form-filter" onSubmit={applyFilter}>
        {loading ? renderLoading : renderOptions}
        <ListItem>
          <ButtonToolbar>
            <Button
              color="primary"
              type="submit"
            >
              {t('search-bar#update')}
            </Button>
            <Button color="link" onClick={clear}>
              {t('search-bar#clear')}
            </Button>
          </ButtonToolbar>
        </ListItem>
      </Form>
    </>);

  return (
    <DropDownFilter
      buttonLabel={buttonLabel()}
      ref={filterMenu}
      {...props}
    >
      {renderFilter()}
    </DropDownFilter>
  );

};

MultiSelectFilterComponent.propTypes = {
  multiOptions: PropTypes.array.isRequired,
  renderOption: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  label: PropTypes.string.isRequired,
  searchKey: PropTypes.string.isRequired,
};

MultiSelectFilterComponent.defaultProps = {
  loading: false,
};

export default MultiSelectFilterComponent;
