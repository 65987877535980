import { isEmpty } from 'lodash';
import { CloudType } from './Constants';


const TestCloudHelper = {
  isDisplayTestCloudTunnelIcon: (execution) => {
    const runConfiguration = execution.jobs && execution.jobs.length > 0 ? execution.jobs[0].runConfiguration : {};
    if (isEmpty(runConfiguration)) return false;
    return runConfiguration.cloudType === CloudType.TEST_CLOUD_AGENT
            && execution.useTestCloudTunnel;
  }

};

export default TestCloudHelper;
