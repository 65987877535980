import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { DialogActions, DialogContent } from '@mui/material';
import { Button, ButtonToolbar, Form } from 'reactstrap';
import CloseableDialogComponent from '../../dialog/CloseableDialogComponent';
import { t } from '../../../i18n/t';
import HorizontalLinearStepper from '../../wizard/HorizontalLinearStepper';
import PublishTestSuiteLocationStep from './PublishTestSuiteLocationStep';
import AddTestCaseView from './AddTestCaseView';
import { TestSuiteType, TestProjectType } from '../../../utils/Constants';
import Services from '../../../utils/Services';
import Notification from '../../../utils/Notification';
import colors from '../../../../scss/colors.scss';
import NotificationHandler from '../handler/NotificationHandler';
import { sendAnalyticEventForAction } from '../../../utils/SegmentAnalytics';
import TestProjectHelper from '../../../utils/TestProjectHelper';

function PublishTestSuiteDialog({ isOpen, onClose, handleAfterPublishTestSuite }) {
  const [testProject, setTestProject] = useState({});
  const [testSuiteName, setTestSuiteName] = useState('');
  const [testSuitePath, setTestSuitePath] = useState('');
  const [activeStep, setActiveStep] = React.useState(0);
  const [disablePublish, setDisablePublish] = useState(false);
  const [commitMessage, setCommitMessage] = useState();
  const [selectedLinkingTestCases, setSelectedLinkingTestCases] = useState([]);

  useEffect(() => {
    setSelectedLinkingTestCases([]);
  }, [testProject]);

  useEffect(() => {
    setDisablePublish(!testProject || !testSuiteName || !testSuitePath || selectedLinkingTestCases.length === 0 || (testProject.type === TestProjectType.CLOUD ? false : !commitMessage));
  }, [testProject, testSuiteName, testSuitePath, commitMessage, selectedLinkingTestCases]);

  const publishTestProjectErrorHandler = (message, label, jqXHR) => {
    NotificationHandler.publishTestProjectErrorHandler(message, jqXHR);
  };

  const handlePublish = (event) => {
    event.preventDefault();
    const testSuites = [
      {
        name: testSuiteName,
        path: testSuitePath,
        testType: TestSuiteType.CLOUD_STUDIO,
        testCases: [...selectedLinkingTestCases],
      }
    ];

    const publishedTestProject = {
      message: commitMessage,
      testSuites
    };

    Services.publishTestProject(testProject.id, publishedTestProject, publishTestProjectErrorHandler).then((res, title, timeOut = 2000) => {
      const publishedTestSuite = res.testSuites[0];
      Notification.pushSuccess(t('publish-test-suite#successfully', {
        testSuiteName,
        testSuitePath: `${testProject.name}/${testSuitePath}`
      }), title, timeOut);
      if (publishedTestSuite?.id) {
        handleAfterPublishTestSuite(publishedTestSuite.id, testProject.id);
      }
    }).catch(() => {});
    const storageType = TestProjectHelper.getStorageType(testProject);
    sendAnalyticEventForAction('create-cloud-studio-ts', { storage_type: storageType });
  };

  const handleClose = () => {
    onClose();
  };

  const renderPublishLocationStep = () => (
    <PublishTestSuiteLocationStep
      testProject={testProject}
      setTestProject={setTestProject}
      testSuiteName={testSuiteName}
      setTestSuiteName={setTestSuiteName}
      testSuitePath={testSuitePath}
      setTestSuitePath={setTestSuitePath}
      setDisablePublish={setDisablePublish}
    />
  );

  const renderAddTestCaseStep = () => (
    <AddTestCaseView
      testProject={testProject}
      testSuitePath={testSuitePath}
      selectedLinkingTestCases={selectedLinkingTestCases}
      setSelectedLinkingTestCases={setSelectedLinkingTestCases}
      commitMessage={commitMessage}
      onChangeCommitMessage={setCommitMessage}
    />
  );

  const steps = [{
    label: t('published-location'),
    content: renderPublishLocationStep()
  }, {
    label: t('add-test-case'),
    content: renderAddTestCaseStep(),
  }];

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const renderFooter = () => (
    <DialogActions className="d-flex justify-content-between publish-dialog__footer">
      <Button
        className={activeStep === 0 && 'invisible'}
        onClick={handleBack}
        color="link"
        style={{
          color: colors.blue5
        }}
      >
        {t('stepper#back')}
      </Button>
      <ButtonToolbar>
        <Button
          color="secondary"
          onClick={handleClose}
        >
          {t('cancel')}
        </Button>
        <Button
          disabled={!(testSuiteName && testSuitePath)}
          color="primary"
          onClick={handleNext}
          className={activeStep !== 0 && 'd-none'}
        >
          {t('continue')}
        </Button>
        <Button
          className={activeStep === 0 && 'd-none'}
          disabled={disablePublish}
          type="submit"
          color="primary"
        >
          {t('create')}
        </Button>
      </ButtonToolbar>
    </DialogActions>
  );

  return (
    <Form
      onSubmit={handlePublish}
    >
      <CloseableDialogComponent
        isOpen={isOpen}
        handleClose={handleClose}
        maxWidth="sm"
        title={t('publish-test-suite#dialog-create-title')}
        headerStyle="publish-dialog__header"
        disablePortal
      >
        <DialogContent>
          <div className="publish-dialog__content content-height">
            <HorizontalLinearStepper
              expanded
              orientation="horizontal"
              steps={steps}
              activeStep={activeStep}
              setActiveStep={setActiveStep}
            />
          </div>
        </DialogContent>
        {renderFooter()}
      </CloseableDialogComponent>
    </Form>
  );
}

PublishTestSuiteDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

PublishTestSuiteDialog.defaultProps = {
  isOpen: false,
  onClose: null,
};

export default PublishTestSuiteDialog;
