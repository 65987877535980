import React from 'react';
import CategoryPieChart from '../chart/CategoryPieChart';
import SimpleLineChart from '../chart/SimpleLineChart';
import PerformanceLineChart from '../chart/PerformanceLineChart';
import StatusAreaChart from '../../components/chart/StatusAreaChart';
import ExecutionStatusPieChart from '../chart/ExecutionStatusPieChart';
import NumberTestRunsPerDayChart from '../chart/NumberTestRunsPerDayChart';
import PerformanceLineChartForTestObject from '../chart/PerformanceLineChartForTestObject';
import DataTable from '../../components/table/DataTable';

const types = {
  SimpleLineChart,
  PerformanceLineChart,
  StatusAreaChart,
  ExecutionStatusPieChart,
  CategoryPieChart,
  DataTable,
  NumberTestRunsPerDayChart,
  PerformanceLineChartForTestObject,
};

class Widget extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  getWidget(type) {
    return types[type];
  }

  render() {

    const configs = {};

    const wProps = {
      ...configs, ...this.props,
    };

    const widget = this.getWidget(wProps.widgetType);
    const children = null;
    return React.createElement(
      widget,
      {
        ...wProps,
        ref: (ref) => {
          if (wProps.widgetRef) {
            wProps.widgetRef(ref);
          }
        },
      },
      children,
    );
  }
}

export default Widget;
