const MFlags = {
  zipScriptRepoDisabled: false,
  subscriptionAndPaymentMethodDisabled: false,
  testCloudDynamicDeviceSelectionEnabled: false,
  testCloudDisableRepeatByMinute: false,
  preventScheduleTestRunEnabled: false,
  allowBetaUserForFeatureRCAEnabled: false,
  testRunReportFilterEnhancementEnabled: false,
  testCloudDefaultSchedulerSettingEnabled: false,
  testCloudDisableSCDeviceResourceByOrg: false,
  removePopulateReleaseDialogEnabled: false,
  testRunReportFilterEnhancementPhase2Enabled: false,
  e2eTestGenerationEnabled: false,
  g5Editor: false,
  testCloudMobileEnvironmentReferralLinkEnabled: false,
  profileFilterEnhancementEnabled: false,
  testCloudInternetExplorerEnabled: false,
  testCloudTrialWarningEnabled: false,
  testCloudTrialScheduleTestRunSuccessEnabled: false,
  testPerformanceForReportAndAnalyticsEnabled: false,
  shareFilterResultByUrlEnabled: false,
  displayTestCaseNumberEnabled: false,
  testCloudNewScheduleTestRunSurveyEnabled: false,
  testCloudUploadSampleNativeAppEnabled: false,
  fixPercentageTestRunChart: false,
  testCloudInstructionCreateGitRepoEnabled: false,
  fixTimeRangeInChartEnable: false,
  testRunHideHeaderEnable: false,
  identifierForProductTrackingEnabled: false,
  g5DevMode: false,
  g5CreateSampleTestCaseEnabled: false,
  testCloudTscShowEnvironmentConfigurationEnabled: false,
  testCloudG5TestSuiteEnabled: false,
  addMoreInformationToTestCaseDetailEnabled: false,
  onboardingChecklistEnabled: false,
  onboardingChecklistEnhancementEnabled: false,
  hideKatOneOnboardingSteps: false,
  hideKatalonStudioOnboardingSteps: false,
  collapseTestRunsReportChartEnabled: false,
  convertToTypeScriptEnabled: false,
  onCloudTestStorageEnabled: false,
  dashboardAIEnabled: false,
  notDisplayLoginPageWhenRedirectBetweenSites: false,
  openAiApiKeyManagementEnabled: false,
  revampIntegrationsEnabled: false,
  redirectToKatOneOrganizationManagementEnabled: false,
  dashboardAIPhase2Enabled: false,
  videoPlayerEnabled: false,
  removeDuplicatedSearchRequest: false,
  jenkinsIntegrationEnabled: false,
  projectSettingsEnhancementEnabled: false,
  raJiraIntegrationEnhancementPhase2Enabled: false,
  fixReleaseExecutionStatusBarEnabled: false,
  changeStageAndStatusExecutionRule: false,
  instantRunTestCasesEnabled: false,
  disappearDownloadKSDialogEnabled: false,
  updateDeleteTestOpsTestSuiteDialog: false,
  addTestCaseToTestSuiteEnabled: false,
  updateSettingsWheelItemsEnabled: false,
  externalSeverityMigrationEnabled: false,
  katalonPlatformMaintainerWarningModeEnabled: false,
  fixTestRunReportPageSizeEnabled: false,
  removeGitSupportForCloudStudioEnabled: false,
  jiraIssueLinkingCreatingEnabled: false,
  searchIntegrationItemsEnabled: false,
  fixIncompleteTestRunStatusOnSideBarEnabled: false,
  changeImportingStatusEnabled: false,
  automationRuleEnabled: false,
  removeDraftTestCasePublishTermInCloudStudioAlertMessagesEnabled: false,
  preventNamingTestOpsInTestSuiteFoldersEnabled: false,
  hideResultActionWhenParsingNotCompletedEnabled: false,
  moveAgentToOrgLevelPhase2Enabled: true,
  removeSearchQueryInputInDynamicFilterEnabled: false,
  testCloudKRELatestOptionEnabled: false,
  disableTheAbilityToUpdateTeamLevel: true,
  editorTrueTestEnabled: false,
  filterUnresolvedTestResultEnabled: false,
  jiraSlackIntegrationEnabled: false,
  openLastAccessedFolderTestCase: false,
  testcloudExecutionEnvMicroAppEnabled: false,
  removeAgentOnboardingStepEnabled: false,
  viewSuggestedDefectEnabled: false,
  refreshMetricCardEnabled: false,
  modifyDefectListInTestResultDetailEnabled: false,
  abilityToSelectProcessorEnabled: true,
  useRoutesUtilAsSinglePageNavigationEnabled: false,
  testResultListEnhancementEnabled: false,
  revampFailedTestResultEnabled: false,
  enhanceDropdownFieldBehaviorEnabled: false,
  disableVerifyEmailUserBanner: true,
  preventExceedZipFileEnabled: true,
  dailyExecutionEmailEnabled: false,
  genAISettingsAccountLevelEnabled: false,
  searchApiKeyEnabled: true,
  getTestOpsDomainFromOriginRequestEnabled: true,
  useRAMicroFrontEndEnabled: false,
  localAgentKRELatestOptionEnabled: false,
  refreshReleaseStatusOnDashboardEnabled: true,
  consumeIAMTokenFE: false,
  htmlInjection: true,
  mapCustomizedXrayIssueTypeEnabled: true,
  removeAgentSetupPageOnProjectSettingsEnabled: true,
  katalonCliIntegrationEnabled: true,
  supportTargetDirectoryOnGitRepositoryEnabled: true,
  applyErrorHandlingUsingKatalonApiStandardEnabled: true,
  atgCheckFeaturePermission: false,
  useIAMAccessTokenFE: true,
  editTestCaseData: false,
  ignoreTestOpsToken: true,
  testCloudDisableTCFromScheduleDialog: false,
  filterOrganizationsOnOrganizationMenuByAccount: false,
  removeUnusedFieldsGetMeApiEnabled: true,
  removeSomeUnsupportedAuthenticationOptionEnabled: true,
  vstSettingsFromAccountLevel: false,
  testManagementG3Phase1Enabled: false,
  planningG3GA: false,
  atgRemoveBetaTag: false,
  hideManualTestG2Enabled: false,

  parse(configs) {
    Object.entries(configs).forEach(([key, value]) => {
      MFlags[key] = value;
    });
  },
};

export default MFlags;
