import React from 'react';
import PropTypes from 'prop-types';

import _ from 'lodash';
import ReactPaginate from 'react-paginate';
import { Card, CardHeader, CardBody, CardFooter, Button, Collapse } from 'reactstrap';
import jquery from 'jquery';
import { badgeCountComponent } from '../../pages/execution/ExecutionDecorator';
import MContext from '../../models/MContext';
import WebSocket from '../../services/WebSocket';
import { next } from '../../utils/Count';
import 'jquery-ui';
import 'jquery-ui/ui/widgets/button';
import 'jquery-ui/ui/effects/effect-highlight';
import 'jquery-ui/ui/widgets/datepicker';
import Time from '../../utils/Moment';

import {
  buildFilterValue,
  getFilterLabelConfig,
  parseToSortItem,
  getTableIds, buildSearchCondition
} from '../search/SearchUtils';

import MTableFilter from './models/MTableFilter';
import MTableData from './models/MTableData';
import DynamicSort from '../search/DynamicSort';
import DynamicFilter from '../search/DynamicFilter';
import Services from '../../utils/Services';
import {
  IconFilter,
  IconSort,
  IconAngleLeft,
  IconAngleRight,
} from '../../images/KitIcons';
import MPaginationMapping from '../../components/table/models/MPaginationMapping';
import SearchQuery from '../SearchQuery';
import { sendAnalyticEventForAction } from '../../utils/SegmentAnalytics';
import Notification from '../../utils/Notification';
import MConfigs from '../../models/MConfigs';
import { IconCollapse, IconExpand } from '../../images/CustomNewIcon';
import TableLoading from './TableLoading';
import SearchHelper from '../../utils/SearchHelper';
import { t } from '../../i18n/t';
import Pagination from './Pagination';
import Helper from '../../utils/Helper';

window.$ = jquery;
window.jQuery = jquery;
require('structured-filter');

class DataLoader extends React.Component {
  get isLoading() { return this.state.isLoading; }

  constructor(props) {
    super(props);
    const { defaultCollapse, customFieldConditionsProp, statusInConditionsProp, priorityInConditionsProp } = this.props;
    this.projectId = MContext.projectId;
    this.state = {
      lastUpdate: 0,
      sorts: null,
      filters: null,
      entity: null,
      filterFields: { fields: [] },
      showFilter: false,
      isLoading: false,
      collapsed: defaultCollapse,
      searchString: '',
      customFieldConditions: customFieldConditionsProp || null,
      statusInConditions: statusInConditionsProp || null,
      priorityInConditions: priorityInConditionsProp || null,
    };
    this.structuredFilter = new MTableFilter(() => {
      this.goToPage(0);
    });
    this.store = new MTableData(
      props.sourceUrl,
      props.sourceFieldName,
      props.paginationMapping,
      props.pageSize,
      props.entityType,
    );
    this.store.onChange = () => {
      this.setState({
        lastUpdate: next(),
      });
      if (props.setDataState) {
        props.setDataState(this.store.data);
      }
      this.onChangeLoading(false);
      if (props.onChangeData) {
        props.onChangeData(this.store.data, this.store.currentPage);
      }
    };
    this.timer = null;
    this.onPageChange = this.onPageChange.bind(this);
    this.refreshData = this.refreshData.bind(this);
    this.getData = this.getData.bind(this);
    this.capitalizeString = this.capitalizeString.bind(this);
    this.sortData = this.sortData.bind(this);
    this.getSearchConfigurations = this.getSearchConfigurations.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.buildStructuredFilterData = this.buildStructuredFilterData.bind(this);
    this.buildStructuredAggregationData = this.buildStructuredAggregationData.bind(this);
    this.setupAutoUpdate = this.setupAutoUpdate.bind(this);
    this.copySearchParamsToClipboard = this.copySearchParamsToClipboard.bind(this);
    this.clearCacheAndRefreshData = this.clearCacheAndRefreshData.bind(this);
    this.renderHeader = this.renderHeader.bind(this);

    this.updateInterval = 5000;
    this.notifyHandler = _.throttle(() => {
      this.refreshData();
    }, this.updateInterval);
    this.handlerId = next();
    this.handleSearchFilterChange = this.handleSearchFilterChange.bind(this);
    this.handleSearchCustomFieldFilterChange = this.handleSearchCustomFieldFilterChange.bind(this);
    this.handleSearchSortChange = this.handleSearchSortChange.bind(this);
    this.onChangeLoading = this.onChangeLoading.bind(this);
    this.defaultSearchQueryRef = React.createRef();
  }

  onChangeLoading(isLoading) {
    this.setState({
      isLoading
    }, () => {
      const { onChangeLoading } = this.props;
      if (onChangeLoading) {
        onChangeLoading(isLoading);
      }
    });
  }

  getSearchConfigurations() {
    this.onChangeLoading(true);
    return Services.searchInfo()
      .then((responseJson) => {
        const searchConfigurations = responseJson;
        const entity = searchConfigurations[this.capitalizeString(this.props.entityType)];
        if (entity) {
          this.setState({
            entity
          });
          this.updateFilterFields(searchConfigurations, this.state.entity, this.props.entityType);
        }
      });
  }

  /**
   * propKeys is array contains the values we want to check the difference between current props and prevProps to refresh data.
   */
  componentDidUpdate(prevProps) {
    const { refreshDataOnPropsChange, clearStructuredFilterOnPropsChange, clearStructuredFilterNoTriggerOnPropsChange } = this.props;
    const propKeys = ['defaultSearchConditions', 'entityType'];
    if (refreshDataOnPropsChange) {
      for (const propKey of propKeys) {
        if (!_.isEqual(this.props[propKey], prevProps[propKey])) {
          this.structuredFilter.clear();
          this.refreshData();
          break;
        }
      }
    }
    const defaultSearchConditionsKey = 'defaultSearchConditions';
    if (clearStructuredFilterNoTriggerOnPropsChange) {
      if (!_.isEqual(this.props[defaultSearchConditionsKey], prevProps[defaultSearchConditionsKey])) {
        this.structuredFilter.clearNoTrigger();
      }
    }
    if (clearStructuredFilterOnPropsChange) {
      if (!_.isEqual(this.props[defaultSearchConditionsKey], prevProps[defaultSearchConditionsKey])) {
        this.structuredFilter.clear();
      }
    }
  }

  refreshData() {
    this.goToPage(this.store.currentPage);
  }
  getData() {
    return this.store.data;
  }
  clearCacheAndRefreshData() {
    Notification.remove();
    const { useCache, entityType, defaultSearchFunctions, groupBys } = this.props;
    if (useCache) {
      const { sorts, customFieldConditions, statusInConditions, priorityInConditions } = this.state;
      const structuredAggData = this.buildStructuredAggregationData(entityType, defaultSearchFunctions);
      const params = this.store.buildPostData(this.structuredFilter, sorts, structuredAggData, groupBys, customFieldConditions, statusInConditions, priorityInConditions);
      const fullParams = SearchHelper.fullParams(params);
      const key = SearchHelper.generateCacheKey(fullParams);
      Services.deleteCache(key, this.projectId).then(() => {
        this.store.cleanUpData();
        this.refreshData();
      });
    } else {
      this.refreshData();
    }
  }

  goToPage(pageIndex) {
    const { entity, sorts, customFieldConditions, statusInConditions, priorityInConditions } = this.state;
    const { defaultSearchFunctions, groupBys, useCache, errorMessage } = this.props;
    const structuredAggData = this.buildStructuredAggregationData(this.props.entityType, defaultSearchFunctions);
    this.store.currentPage = pageIndex;
    if (this.structuredFilter.isEmpty()) {
      const { defaultSearchConditions, entityType } = this.props;
      const structuredFilterData = this.buildStructuredFilterData(entityType, defaultSearchConditions);
      this.structuredFilter.insert(structuredFilterData);
    }
    if (sorts === null || sorts.length === 0) {
      let { defaultSort } = this.props;
      if (defaultSort == null || defaultSort.length === 0) {
        if (entity) {
          defaultSort = entity.sortDefault;
        }
      }
      if (defaultSort == null || defaultSort.length === 0) {
        defaultSort = ['id, desc'];
      }
      this.setState(
        {
          sorts: defaultSort
        },
        () => {
          this.goToPage(0);
        },
      );
    } else {
      this.onChangeLoading(true);
      if (entity) {
        if (this.props.fetchAllPages) {
          this.store.fetchAllPages(this.structuredFilter, sorts, structuredAggData, groupBys);
        } else {
          this.store.postData(this.structuredFilter, sorts, structuredAggData, groupBys, useCache, errorMessage, customFieldConditions, statusInConditions, priorityInConditions);
        }
      } else {
        this.store.fetchData(this.structuredFilter);
      }
    }
  }

  onPageChange(zeroBasedPage) {
    this.goToPage(zeroBasedPage.selected);
    if (this.props.onPageChange) {
      this.props.onPageChange();
    }
  }

  convertStructuredFilterData(data) {
    const { entity } = this.state;
    const newData = data.map((item) => {
      const { value: fieldName } = item.field;
      const { value, value2 } = item.value;
      const filterDataEntry = {
        key: item.field.value,
        operator: item.operator.label,
        value: buildFilterValue(entity, fieldName, value, value2)
      };
      return filterDataEntry;
    });
    return newData;
  }

  buildStructuredFilterData(entityType, defaultConditions = [], currentConditions = []) {
    return {
      defaultConditions,
      currentConditions,
      entityType: this.capitalizeString(entityType),
    };
  }

  buildStructuredAggregationData(entityType, defaultFunctions = [], currentFunctions = [], groupBys = []) {
    return {
      defaultFunctions,
      currentFunctions,
      entityType: this.capitalizeString(entityType),
      groupBys
    };
  }

  capitalizeString(str) {
    return str && (str.charAt(0).toUpperCase() + str.slice(1));
  }

  sortData(currentSorts) {
    const { defaultSort } = this.state.entity;

    let sortItems = [];
    if (currentSorts.indexOf(',') > 0) {
      sortItems = currentSorts.split(',').map((sort) =>
        parseToSortItem(sort));
    } else if (currentSorts) {
      sortItems.push(parseToSortItem(currentSorts));
    } else if (defaultSort) {
      sortItems = sortItems.concat(defaultSort);
    }

    this.setState({
      sorts: [].concat(sortItems)
    }, () => {
      this.goToPage(0);
    });
  }

  handleSearchSortChange(sortOptions) {
    if (!_.isEmpty(sortOptions)) {
      sendAnalyticEventForAction('sort', { label: this.props.entityType });
    }
    this.sortData(sortOptions);
  }

  updateFilterFields(searchConfigurations, entity, entityType) {
    if (searchConfigurations) {
      const searchConfigs = this.buildFilterFields(searchConfigurations, entity, entityType);
      const { defaultSearchConditions } = this.props;
      const structuredFilterData = this.buildStructuredFilterData(entityType, defaultSearchConditions);

      // set default search, sort conditions
      this.structuredFilter.insert(structuredFilterData, false);
      this.setState({ filterFields: searchConfigs });
    }
  }

  buildFilterFields(searchConfigurations, entity, entityType) {
    const { tableId } = this.props;
    let filterConfig = null;
    if (entityType && entity) {
      filterConfig = entity.filterConfig;
    }
    const labelMap = getFilterLabelConfig(tableId);
    const isAcceptedConfig = (k) => labelMap[k];
    const mapToConfigWithStatus = (k) => {
      const status = filterConfig[k];
      const statusCollection = status.split(',');
      const isExecutionTable = tableId === getTableIds().Execution;
      const justFailedAndPassedOnly = (status) => status === 'FAILED' || status === 'PASSED';
      const statusContents =
        statusCollection
          .filter((status) => !isExecutionTable || (isExecutionTable && justFailedAndPassedOnly(status)))
          .map((status) =>
            ({ id: status, label: this.capitalizeString(status.toLowerCase()) }));
      return {
        id: k, type: 'list', list: statusContents, label: labelMap[k]
      };
    };
    const mapToConfigWithoutStatus = (k) => ({ id: k, type: filterConfig[k].toLowerCase(), label: labelMap[k] });

    if (filterConfig) {
      const configurations =
        Object.keys(filterConfig)
          .filter(isAcceptedConfig)
          .map((k) => ((k === 'status' || k === 'ExecutionStatistics.status')
            ? mapToConfigWithStatus(k) : mapToConfigWithoutStatus(k)))
          .sort((a, b) => a.label.localeCompare(b.label));
      return { fields: configurations };
    }

    return [];
  }

  handleFilterChange(currentFilterVal) {
    const { defaultSearchConditions, entityType } = this.props;
    const currentConditions = this.convertStructuredFilterData(currentFilterVal);
    const structuredFilterData = this.buildStructuredFilterData(entityType, defaultSearchConditions, currentConditions);
    this.structuredFilter.insert(structuredFilterData);
  }

  handleCustomFieldFilterChange(customFieldConditions) {
    this.setState({
      customFieldConditions,
    });
  }

  handleDefaultFilterConditions(currentConditions) {
    const searchString = currentConditions.map((condition) => `${condition.key}:${condition.value}`).join(' ');
    this.setState({ searchString });
    const { defaultSearchConditions, entityType } = this.props;
    const structuredFilterData = this.buildStructuredFilterData(entityType, defaultSearchConditions, currentConditions);
    this.structuredFilter.insert(structuredFilterData);
  }

  /**
   * Handle default search string
   * @param {String} defaultSearchString - A string is obtained from search params
   * Ex: defaultSearchString = 'ExecutionStatistics.status:PASSED User.id:1 Test case name'
   */
  handleDefaultSearchString(defaultSearchString) {
    this.setState({
      searchString: defaultSearchString
    }, this.refreshSearch);
  }


  refreshSearch() {
    this.defaultSearchQueryRef.current.triggerSearch();
  }

  subscribeTopic() {
    const { projectId, teamId } = MContext;
    if (projectId || teamId) {
      const topic = this.props.entityType;
      WebSocket.subscribe({
        projectId,
        teamId,
        topics: [topic],
      }, this.notifyHandler, this.handlerId);
    }
  }

  handleSearchFilterChange(filterOptions) {
    if (!_.isEmpty(filterOptions)) {
      sendAnalyticEventForAction('filter', { label: this.props.entityType });
    }
    this.handleFilterChange(filterOptions);
    const { onSearchQueryChange } = this.props;
    if (onSearchQueryChange) {
      onSearchQueryChange(filterOptions);
    }
  }

  handleSearchCustomFieldFilterChange(customFieldConditions) {
    if (!_.isEmpty(customFieldConditions)) {
      sendAnalyticEventForAction('filter', { label: this.props.entityType });
    }
    this.handleCustomFieldFilterChange(customFieldConditions);
    const { onCustomFieldConditionChange } = this.props;
    if (onCustomFieldConditionChange) {
      onCustomFieldConditionChange(customFieldConditions);
    }
  }

  componentDidMount() {
    this.getSearchConfigurations().then(() => {
      if (this.props.autoLoad !== false) {
        if (this.props.defaultFilterConditions) {
          this.handleDefaultFilterConditions(this.props.defaultFilterConditions);
        } else if (this.props.defaultSearchString) {
          this.handleDefaultSearchString(this.props.defaultSearchString);
        }
        this.goToPage(0);
      }
    });
    if (this.props.autoLoad !== false) {
      this.setupAutoUpdate();
    }
    /** *
     * Remove webSocket subscribe since this will cause continuously refresh when useCache is enabled
     */
    if (!this.props.useCache) {
      this.subscribeTopic();
    }
  }

  setupAutoUpdate() {
    const {
      autoUpdate,
      autoUpdateIntervalTime
    } = this.props;
    if (autoUpdate) {
      this.timer = setInterval(() => this.goToPage(this.store.currentPage), autoUpdateIntervalTime);
    }
  }

  componentWillUnmount() {
    /** *
     * Remove webSocket unsubscribe since webSocket won't be subscribed when useCache is enabled
     */
    if (!this.props.useCache) {
      WebSocket.unsubscribe(this.handlerId);
    }
    clearInterval(this.timer);
  }

  toggleFilter() {
    const { showFilter } = this.state;
    this.setState({
      showFilter: !showFilter
    });
  }

  toggleCollapse() {
    const { collapsed } = this.state;
    this.setState({
      collapsed: !collapsed
    });
  }

  renderLoading() {
    const { customLoading } = this.props;
    if (customLoading) {
      return customLoading;
    }

    return (
      <TableLoading />
    );
  }

  renderEmptyMessage() {
    const { emptyMessage } = this.props;
    if (emptyMessage) {
      return emptyMessage;
    }
    return (
      <div className="text-center">{t('table#empty-message')}</div>
    );
  }

  renderBody() {
    let sortConfig = null;
    const {
      entityType,
      tableId,
      hidePaging,
      disableFilterButton,
      disableSortButton,
      disableAddFilterButton,
      useSearchQuery,
      filterQuery,
      sortQuery,
      defaultFilter,
      noCard,
      renderFooter,
      defaultFilterConditions,
      useNewSearchBar,
      placeHolder,
      renderBackButton,
      useTitleNoCard,
      showEmptyMessage,
      unitName,
      useSortAndPaginationOnly
    } = this.props;
    const { showFilter, filterFields, filters, sorts, isLoading, searchString } = this.state;
    if (entityType && this.state.entity) {
      sortConfig = this.state.entity.sortConfig;
    }
    const sortLabelMap = getFilterLabelConfig(tableId);

    let table = null;
    if (isLoading && this.store.totalItems <= 0) {
      table = this.renderLoading();
    } else if (showEmptyMessage && !isLoading && this.store.totalItems <= 0) {
      table = this.renderEmptyMessage();
    } else {
      table = this.props.render(this.store.data);
    }
    const paginationClassName = useSortAndPaginationOnly ? 'top-pagination sort-pagination-only' : 'top-pagination';
    return (
      <>
        {!disableFilterButton &&
          <div className={`sort-filter-wrapper ${(showFilter || useSearchQuery) ? '' : 'd-none'}`}>
            {!useSearchQuery && !disableAddFilterButton &&
              <div key={this.props.entityType}>
                {this.props.entityType && this.state.entity &&
                  <div className="filter-toolbar">
                    <div className="icon-container">
                      <IconFilter className="icon" />
                    </div>
                    <DynamicFilter
                      data={filterFields}
                      handleFilterChange={this.handleFilterChange}
                    />
                  </div>}
              </div>}
            {!useSearchQuery && !disableSortButton &&
              <div className="dynamic-sort-col">
                {this.props.entityType && this.state.entity &&
                  <div className="filter-toolbar">
                    <div className="icon-container">
                      <IconSort className="icon" />
                    </div>
                    <DynamicSort
                      className="dynamic-sort"
                      sortData={this.sortData}
                      labelMap={sortLabelMap}
                      options={sortConfig}
                    />
                  </div>}
              </div>}
          </div>}
        {useSearchQuery &&
        <div className="d-flex mb-3 align-items-center">
          <div className="flex-grow-1">
            <SearchQuery
              key={searchString}
              searchString={searchString}
              filterOptions={filterFields}
              filters={filters}
              defaultFilter={defaultFilter}
              sortOptions={sortConfig}
              sortLabelMap={sortLabelMap}
              sorts={sorts}
              onFilterChange={this.handleSearchFilterChange}
              onSortChange={this.handleSearchSortChange}
              onCustomFieldFilterChange={this.handleSearchCustomFieldFilterChange}
              filterQuery={filterQuery}
              sortQuery={sortQuery}
              allowUnknowFilters
              onCopyButtonClick={this.copySearchParamsToClipboard}
              defaultFilterConditions={defaultFilterConditions}
              ref={this.defaultSearchQueryRef}
              placeHolder={placeHolder}
              useNewSearchBar={useNewSearchBar}
              renderBackButton={renderBackButton}
              {...this.props}
            />
          </div>
            { (useNewSearchBar || useSortAndPaginationOnly) &&
              <div className={paginationClassName}>
                <Pagination store={this.store} onPageChange={this.onPageChange} pageKey={this.state.lastUpdate} unitName={this.props.unitName} />
              </div>}
        </div>}

        {this.props.renderSelect && this.props.renderSelect()}
        {noCard && useTitleNoCard && this.renderTitle()}
        <React.Fragment key={this.state.lastUpdate}>
          {table}
        </React.Fragment>

        {
          !useNewSearchBar && !hidePaging && this.store.totalPage > 1 && this.store.totalItems > 0 && !useSortAndPaginationOnly && (
            <div>
              <ReactPaginate
                previousLabel={
                  <IconAngleLeft />
                }
                nextLabel={
                  <IconAngleRight />
                }
                breakLabel={this.store.breakLabel}
                pageCount={this.store.totalPage}
                pageRangeDisplayed={this.store.pageRangeDisplayed}
                marginPagesDisplayed={this.store.marginPagesDisplayed}
                forcePage={this.store.currentPage}
                onPageChange={this.onPageChange}
                containerClassName={this.store.totalPage > 1 ? 'pagination' : 'd-none'}
                subContainerClassName="pages pagination"
                activeClassName="active"
              />
              <div
                key={this.state.lastUpdate}
                className="pagination-item-count"
              >
                {Helper.generatePaginationText(this.store.totalItems, unitName)}
              </div>
            </div>
          )
        }
        {noCard && (renderFooter || null)}
      </>
    );
  }

  copySearchParamsToClipboard() {
    const { entity, sorts, customFieldConditions, statusInConditions, priorityInConditions } = this.state;
    const { defaultSearchFunctions, groupBys } = this.props;
    const structuredAggData = this.buildStructuredAggregationData(this.props.entityType, defaultSearchFunctions);

    let params;
    if (entity) {
      params = this.store.buildPostData(this.structuredFilter, sorts, structuredAggData, groupBys, customFieldConditions, statusInConditions, priorityInConditions);
    }

    if (!params.ignoreAutoFill) {
      const hasTeamId = params.conditions.find((condition) => condition.key === 'Team.id');
      if (!hasTeamId) {
        if (MContext.projectId != null) {
          const projectCondition = buildSearchCondition('Project.id', '=', MContext.projectId);
          params.conditions.push(projectCondition);
        }
      }
    }

    const configuration = this.configureParams(params);
    this.copyToClipboard(configuration);
    Notification.pushSuccess('Request params has copied.');
  }

  configureParams(params) {
    params = JSON.stringify(params, null, 2);
    const url = `${MConfigs.serverUrl}/api/v1/search`;

    const configuration =
      `POST ${url}\n` +
      'Headers:\n' +
      '    - Authorization: <base_64(email:api_key)> \n' +
      '    - Content-Type: application/json \n' +
      `Body:\n${
        params}`;
    return configuration;
  }

  copyToClipboard(configuration) {
    const el = document.createElement('textarea');
    el.value = configuration;
    el.setAttribute('readonly', '');
    el.style = { display: 'none' };
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  }

  renderHeader() {
    const {
      disableFilterButton,
      useSearchQuery,
      title,
      customControl,
      subtitle,
      useCache,
      useRefreshButton,
      useCollapseButton,
      showCountBadge
    } = this.props;
    const { collapsed, isLoading } = this.state;
    const { cacheCreatedAt } = this.store;
    return (
      <>
        <div>
          <div className="card-header-title d-flex align-items-center">
            {title}
            {showCountBadge && !isLoading &&
              <div>
                {badgeCountComponent(this.store.data.length)}
              </div>}
          </div>
          <div className="card-subtitle">{subtitle}</div>
          {(useCache && cacheCreatedAt && !isLoading) &&
          <div className="generate-date">
            {`${t('last-update')}: `}
            {Time.format(cacheCreatedAt, Time.OVER_A_DAY_FORMAT)}
          </div>}
        </div>
        {!useSearchQuery && !disableFilterButton &&
        <div className="data-loader-header-control">
          <Button
            onClick={() => this.toggleFilter()}
            color="link"
          >
            <IconFilter />
          </Button>
        </div>}
        {customControl &&
        <div className="data-loader-header-control">
          {customControl}
        </div>}
        {useCache && useRefreshButton &&
        <Button
          disabled={isLoading}
          className="mr-1"
          onClick={() => this.clearCacheAndRefreshData()}
          color="secondary"
        >
          {t('refresh')}
        </Button>}
        {useCollapseButton &&
          <Button
            className="mr-1"
            onClick={() => this.toggleCollapse()}
            color="link"
          >
            {collapsed ? <IconExpand /> : <IconCollapse />}
          </Button>}
      </>
    );
  }

  renderCard() {
    const {
      disableFilterButton,
      title,
      cardClassName,
      id,
      renderFooter,
      headerComponent: OverrideHeaderComponent,
      bodyComponent: OverrideBodyComponent,
    } = this.props;
    const { collapsed } = this.state;

    return (
      <Card className={`data-loader ${cardClassName}`} id={id}>
        {(title || !disableFilterButton) &&
          <OverrideHeaderComponent className="d-flex align-items-center justify-content-between">
            {this.renderHeader()}
          </OverrideHeaderComponent>}
        <Collapse isOpen={!collapsed}>
          <OverrideBodyComponent>
            {this.renderBody()}
          </OverrideBodyComponent>
          {renderFooter &&
            <CardFooter>
              {renderFooter}
            </CardFooter>}
        </Collapse>
      </Card>
    );
  }

  render() {
    const {
      noCard
    } = this.props;

    return noCard ? this.renderBody() : this.renderCard();
  }

  renderTitle() {
    const {
      title
    } = this.props;
    return <div className="header-title">{title}</div>;
  }
}

DataLoader.propTypes = {
  sourceUrl: PropTypes.string,
  sourceFieldName: PropTypes.string,
  paginationMapping: PropTypes.object,
  hidePaging: PropTypes.bool,
  autoLoad: PropTypes.bool,
  autoUpdate: PropTypes.bool,
  autoUpdateIntervalTime: PropTypes.number,
  defaultSearchConditions: PropTypes.array,
  customFieldConditionsProp: PropTypes.array,
  statusInConditionsProp: PropTypes.array,
  priorityInConditionsProp: PropTypes.array,
  defaultSearchFunctions: PropTypes.array,
  defaultSort: PropTypes.array,
  render: PropTypes.func,
  groupBys: PropTypes.array,
  disableFilterButton: PropTypes.bool,
  disableSortButton: PropTypes.bool,
  disableAddFilterButton: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  subtitle: PropTypes.string,
  noCard: PropTypes.bool,
  cardClassName: PropTypes.string,
  useSearchQuery: PropTypes.bool,
  defaultFilter: PropTypes.object,
  defaultCollapse: PropTypes.bool,
  customControl: PropTypes.object,
  customLoading: PropTypes.object,
  fetchAllPages: PropTypes.bool,
  useNewSearchBar: PropTypes.bool,
  placeHolder: PropTypes.string,
  /**
   * Display name of entity for new pagination style.
   */
  unitName: PropTypes.string,
  /**
   * This param use with noCard = true in case that we want to
   * use table feature that having title with no Card body above the table.
   */
  useTitleNoCard: PropTypes.bool,
  /**
   * This param use with useNewHeader = true in case that we want to
   * use header with customControl new.
   */
  headerComponent: PropTypes.elementType,
  /**
   * This param use with bodyComponent = true in case that we want to
   * use new body with bodyComponent.
   */
  bodyComponent: PropTypes.elementType,
  /**
   * This param use with useRefreshButton = true in case that we want to use button refresh of cache.
   */
  useRefreshButton: PropTypes.bool,
  /**
   * This param use with useCollapseButton = true in case that we want to use button Collapse.
   */
  useCollapseButton: PropTypes.bool,
  /**
   * This param use with showEmptyMessage = true in case that we want to show empty message when no data.
   */
  showEmptyMessage: PropTypes.bool,
  /**
   * This param use with refreshDataOnPropChange = true in case that we want refresh data when props change.
   */
  refreshDataOnPropsChange: PropTypes.bool,

  /**
   * Custom empty component. In DataTable, this props only effect if showEmptyMessage included.
   */
  emptyMessage: PropTypes.element,

  /**
   * Callback when search query change. Must be used along with `useSearchQuery`
   */
  onSearchQueryChange: PropTypes.func,
  useSortAndPaginationOnly: PropTypes.bool,
  /**
   * Remove defaultSearchConditions to prevent duplication in searchConditionProps
   */
  clearStructuredFilterOnPropsChange: PropTypes.bool,
  /**
   * Remove defaultSearchConditions to prevent duplication in searchConditionProps without trigger
   * search again on test run page
   */
  clearStructuredFilterNoTriggerOnPropsChange: PropTypes.bool,
  onPageChange: PropTypes.func,
  showCountBadge: PropTypes.bool,
  setDataState: PropTypes.func,
};

DataLoader.defaultProps = {
  sourceUrl: '',
  sourceFieldName: 'content',
  paginationMapping: new MPaginationMapping(),
  hidePaging: false,
  autoLoad: true,
  autoUpdate: false,
  autoUpdateIntervalTime: 20000,
  defaultSearchConditions: [],
  customFieldConditionsProp: null,
  statusInConditionsProp: null,
  priorityInConditionsProp: null,
  defaultSearchFunctions: [],
  defaultSort: [],
  groupBys: [],
  disableFilterButton: true,
  disableSortButton: false,
  disableAddFilterButton: false,
  title: '',
  subtitle: '',
  noCard: false,
  cardClassName: '',
  useSearchQuery: false,
  defaultFilter: null,
  defaultCollapse: false,
  customControl: null,
  customLoading: null,
  fetchAllPages: false,
  useNewSearchBar: false,
  placeHolder: '',
  unitName: '',
  useTitleNoCard: false,
  headerComponent: CardHeader,
  bodyComponent: CardBody,
  useRefreshButton: true,
  useCollapseButton: true,
  showEmptyMessage: false,
  refreshDataOnPropsChange: false,
  useSortAndPaginationOnly: false,
  clearStructuredFilterOnPropsChange: false,
  clearStructuredFilterNoTriggerOnPropsChange: false,
  showCountBadge: false
};

export default DataLoader;
