import React from 'react';
import { Form, FormGroup, Label, Card, CardBody, CardHeader } from 'reactstrap';
import Input from '../../components/Input';
import DecoratorConstants from '../../utils/DecoratorConstants';

const BuildInfo = ({ build }) => {
  return (
    <Card>
      <CardHeader>Summary</CardHeader>
      <CardBody>
        <Form className="m-0">
          <FormGroup>
            <Label for="description">Description</Label>
            <Input plaintext id="description">
              {build.description}
            </Input>
          </FormGroup>
          <FormGroup>
            <Label for="date">Date</Label>
            <Input plaintext id="date">
              {DecoratorConstants.timeDecorator('date', build)}
            </Input>
          </FormGroup>
        </Form>
      </CardBody>
    </Card>
  );
};

export default BuildInfo;