import React, { useState, useEffect } from 'react';
import { Autocomplete } from '@mui/material';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { t } from '../../i18n/t';
import DecoratorConstants from '../../utils/DecoratorConstants';
import TextField from '../../components/TextField';
import MContext from '../../models/MContext';
import Services from '../../utils/Services';

function TestCaseMaintainer({ currentValue, onChange }) {
  const [isLoading, setLoading] = useState(false);
  const [userTeams, setUserTeams] = useState([]);
  const [isEditing, setEditing] = useState(false);

  const getUserInTeam = () => {
    setLoading(true);
    Services.getUserInTeam(MContext.teamId)
      .then((content) => {
        setUserTeams(content);
        setLoading(false);
      });
  };

  useEffect(() => {
    getUserInTeam();
  }, []);

  const onChangeValue = (e, value) => {
    onChange(value);
    setEditing(false);
  };

  /**
   * Cancel editting state and show current value
   * When user click out side the select field
   */
  const onBlur = () => {
    setEditing(false);
  };

  const handleOnClick = () => {
    setEditing(true);
  };

  const renderEditMode = () => (
    <Autocomplete
      id="maintainer"
      open
      onBlur={onBlur}
      renderInput={(params) => (
        <TextField
          {...params}
          autoFocus
          placeholder={t('test-case#maintainer#placeholder-select')}
        />
      )}
      defaultValue={currentValue}
      options={userTeams}
      getOptionLabel={(option) => option.fullName}
      renderOption={(props, option) => <li {...props}>{DecoratorConstants.renderUserDecorator(option)}</li>}
      onChange={onChangeValue}
      loading={isLoading}
    />
  );

  const renderViewMode = () => (
    <div
      className={classnames('view-mode', { 'none-text': !currentValue })}
      onClick={handleOnClick}
    >
      {currentValue ? DecoratorConstants.renderUserDecorator(currentValue) : t('none')}
    </div>
  );

  if (isEditing) {
    return renderEditMode();
  }

  return renderViewMode();
}

TestCaseMaintainer.propTypes = {
  currentValue: PropTypes.object,
  onChange: PropTypes.func.isRequired,
};

export default TestCaseMaintainer;
