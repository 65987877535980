import React, { useEffect, useState } from 'react';
import Button from '@katalon-studio/katalon-ui/Button';
import { Dialog, DialogBody, DialogFooter, DialogHeader } from '@katalon-studio/katalon-ui/Dialog';
import { t } from '../../i18n/t';
import { KATALON_EVENTS, ACTIONS_TRACKING } from '../../utils/Constants';
import { TestEntity } from '../../models/model/TestEntity';
import TestSuiteService from './service/TestSuiteService';
import TestCaseService from './service/TestCaseService';
import ActionsHelper from '../../utils/table/ActionsHelper';

function DeleteTestEntityDialog() {
  const [testEntities, setTestEntities] = useState<TestEntity[]>([]);
  const [isOpen, setOpen] = useState<boolean>(false);
  const [displayPageTracking, setDisplayPage] = useState<string>('');
  const [operationType, setOperationType] = useState<string>();

  useEffect(() => {
    document.addEventListener(KATALON_EVENTS.openDeleteTestEntityDialog, (e: any) => {
      const testEntity = e.detail.testEntity as TestEntity;
      const testEntities = e.detail.testEntities as TestEntity[];
      if (testEntity) {
        setTestEntities([testEntity]);
      } else if (testEntities) {
        setTestEntities(testEntities);
      }
      const displayPageTracking = e.detail.displayPageTracking;
      setDisplayPage(displayPageTracking);
      setOpen(true);
      const operationType = e.detail.operationType;
      setOperationType(operationType);
    });
  }, []);

  const handleClose = () => {
    setOpen(false);
    setTestEntities([]);
    setDisplayPage('');
  };

  const handleDelete = (e: any) => {
    e.preventDefault();
    const firstEntity = testEntities[0];
    const { testCase, testSuite } = firstEntity || {};
    if (testCase) {
      TestCaseService.delete(testEntities).then(() => {
        handleClose();
      });
      ActionsHelper.syncTrackingData(
        firstEntity,
        ACTIONS_TRACKING.TEST_CASE_DELETED,
        displayPageTracking,
        undefined,
        operationType
      );
    } else if (testSuite) {
      ActionsHelper.syncTrackingData(
        firstEntity,
        ACTIONS_TRACKING.TEST_SUITE_DELETED,
        displayPageTracking,
        operationType
      );
      TestSuiteService.delete(testEntities)
        .then(() => {
          handleClose();
        });
    }
  };

  const generateHeaderTitle = () => {
    const { testCase, testSuite } = testEntities[0] || {};
    if (testCase) {
      if (testEntities.length === 1) {
        return t('delete-test-case');
      } else if (testEntities.length > 1) {
        return t('delete-test-cases');
      }
    }
    if (testSuite) {
      if (testEntities.length > 1) {
        return t('delete-test-suites', { numberOfTestSuites: testEntities.length });
      }
      return t('delete-test-suite');
    }
    return null;
  };

  const generateContent = () => {
    const { testCase, testSuite } = testEntities[0] || {};
    if (testCase) {
      if (testEntities.length === 1) {
        return (
          <>
            {t('delete-test-case#content')}
            <strong>{testCase.name}</strong>?
          </>
        );
      } else if (testEntities.length > 1) {
        return (
          <p>{t('delete-test-cases#content', { testCaseNumber: testEntities.length })}</p>
        );
      }
    } else if (testSuite) {
      if (testEntities.length > 1) {
        return t('delete-test-suites#content', { numberOfTestSuites: testEntities.length });
      }
      return (
        <>
          {t('delete-test-suite#content')}&nbsp;
          <strong>{testSuite.name}</strong>
          ?
        </>
      );
    }
    return null;
  };

  return (
    <Dialog
      open={isOpen}
      maxWidth="sm"
    >
      <DialogHeader onClick={handleClose}>
        {generateHeaderTitle()}
      </DialogHeader>
      <DialogBody>
        {generateContent()}
      </DialogBody>
      <DialogFooter className="d-flex justify-content-end publish-dialog__footer">
        <Button
          size="medium"
          variant="text"
          color="primary"
          onClick={handleClose}
        >
          {t('cancel')}
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={handleDelete}
        >
          {t('delete')}
        </Button>
      </DialogFooter>
    </Dialog>
  );
}

export default DeleteTestEntityDialog;
