import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { t } from '../i18n/t';
import { IconPlus } from '../images/CustomNewIcon';
import CustomFieldAddNew from './CustomFieldAddNew';
import CustomFieldChip from './CustomFieldChip';
import { sendAnalyticEventForAction } from '../utils/SegmentAnalytics';
import { CUSTOM_FIELD_ENTITY_TYPE, CUSTOM_FIELD_TRACKING } from '../utils/Constants';

const SelectCustomFieldComponent = (props) => {

  const { setSelectedCustomFields, selectedCustomFields, trackingType } = props;

  const [openAddNewComponent, setOpenAddNewComponent] = useState(false);

  const sendTrackingForAddCustomFieldEvent = () => {
    let trackId;
    switch (trackingType) {
      case CUSTOM_FIELD_ENTITY_TYPE.TEST_CASE:
        trackId = 'assign-custom-field-to-test-case';
        break;
      case CUSTOM_FIELD_ENTITY_TYPE.EXECUTION:
        trackId = 'assign-custom-field-to-test-run';
        break;
      case CUSTOM_FIELD_TRACKING.TEST_MANAGEMENT_TEST_CASE:
        trackId = 'add-custom-field-to-filter-set';
        break;
      case CUSTOM_FIELD_TRACKING.MANUAL_IMPORT:
        trackId = 'assign-custom-field-on-manual-import';
        break;
      default:
        return;
    }
    sendAnalyticEventForAction(trackId, {});
  };

  const handleAddCustomField = (customFieldDefinition, customFieldOption) => {
    let newSelectedCustomFields = selectedCustomFields;
    newSelectedCustomFields = newSelectedCustomFields.concat([{
      customFieldDefinition,
      customFieldOption,
    }]);
    setSelectedCustomFields(newSelectedCustomFields);
    sendTrackingForAddCustomFieldEvent();
  };

  const handleRemoveCustomField = (customFieldDefinition, customFieldOption) => {
    let newSelectedCustomFields = selectedCustomFields;
    newSelectedCustomFields = newSelectedCustomFields
      .filter((item) => !(item.customFieldOption.value === customFieldOption.value && item.customFieldDefinition.key === customFieldDefinition.key));
    setSelectedCustomFields(newSelectedCustomFields);
  };

  const sendTrackingForClickAddNewTextCustomFieldEvent = () => {
    let trackId;
    switch (trackingType) {
      case CUSTOM_FIELD_ENTITY_TYPE.TEST_CASE:
        trackId = 'click-on-add-new-custom-field-on-test-case';
        break;
      case CUSTOM_FIELD_ENTITY_TYPE.EXECUTION:
        trackId = 'click-on-add-new-custom-field-on-test-run';
        break;
      case CUSTOM_FIELD_TRACKING.MANUAL_IMPORT:
        trackId = 'click-on-add-new-custom-field-on-manual-import';
        break;
      default:
        return;
    }
    sendAnalyticEventForAction(trackId, {});
  };

  const toggleOpenAddNewComponent = () => {
    setOpenAddNewComponent(!openAddNewComponent);
  };

  const renderAddNewCustomFieldComponent = () => (
    <CustomFieldAddNew
      {...props}
      selectedCustomFields={selectedCustomFields}
      toggleOpenAddNewComponent={toggleOpenAddNewComponent}
      handleAddCustomField={handleAddCustomField}
    />
  );

  const renderAddNewButton = () => (
    <a
      className="mr-3 custom-field-dialog__add-value-btn d-flex mb-3"
      onClick={() => {
        toggleOpenAddNewComponent();
        sendTrackingForClickAddNewTextCustomFieldEvent();
      }}
    >
      <span>
        <IconPlus className="custom-field-dialog__icon-plus ml-0 mr-2" />
        {t('custom-fields#add-new')}
      </span>
    </a>
  );

  const renderCustomFieldChips = () => selectedCustomFields.map((selectedCustomField) =>
    <CustomFieldChip
      key={`${selectedCustomField.customFieldDefinition.key}${selectedCustomField.customFieldOption.value}`}
      definition={selectedCustomField.customFieldDefinition}
      option={selectedCustomField.customFieldOption}
      isRemovable
      handleRemoveCustomField={handleRemoveCustomField}
    />);

  const renderCustomFieldFilter = () => (
    <div className="mb-0">
      {openAddNewComponent ? renderAddNewCustomFieldComponent() : renderAddNewButton()}
      <div className="custom-field-filter__dialog mt-2">
        {renderCustomFieldChips()}
      </div>
    </div>
  );
  return renderCustomFieldFilter();
};

SelectCustomFieldComponent.propTypes = {
  setSelectedCustomField: PropTypes.func,
  selectedCustomFields: PropTypes.array,
};

SelectCustomFieldComponent.defaultProps = {
  setSelectedCustomField: null,
  selectedCustomFields: [],
};

export default SelectCustomFieldComponent;
