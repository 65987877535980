import React, { MouseEventHandler, useEffect, useState } from 'react';
import { Form } from 'reactstrap';
import Button from '@katalon-studio/katalon-ui/Button';
import { CircularProgress, DialogActions, DialogContent, Stack, ThemeProvider } from '@mui/material';
import { t } from '../../i18n/t';
import Services from '../../utils/Services';
import { ExecutionTestResult } from '../../models/model/ExecutionTestResult';
import MContext from '../../models/MContext';
import CloseableDialogComponent from './CloseableDialogComponent';
import { katalonui_theme } from '../../katalonui-theme';
import DecoratorConstants from '../../utils/DecoratorConstants';
import Routes from '../../utils/Routes';
import Notification from '../../utils/Notification';

interface MatchTestResultProps {
  isOpen: boolean;
  handleClose: MouseEventHandler;
  currentTestResultId?: number;
  errorMessagePattern: string;
  stackTracePattern: string;
}

function MatchTestResultDialog(props: MatchTestResultProps) {

  const [isLoading, setIsLoading] = useState(true);
  const [currentExecutionTestResult, setCurrentExecutionTestResult] = useState<ExecutionTestResult>();
  const [executionTestResults, setExecutionTestResults] = useState<ExecutionTestResult[]>();
  const [totalMatchedResults, setTotalMatchedResults] = useState<number>(0);

  const {
    isOpen,
    handleClose,
    currentTestResultId,
    errorMessagePattern,
    stackTracePattern
  } = props;

  const projectId = MContext.projectId;

  const MAX_MATCHED_RESULTS = 5;
  const MAX_TEST_RESULT_NAME_LENGTH = 40;

  useEffect(() => {
    setIsLoading(true);
    const effectiveErrorMessagePattern = errorMessagePattern.trim() !== '' ? errorMessagePattern : null;
    const effectiveStackTracePattern = stackTracePattern.trim() !== '' ? stackTracePattern : null;
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    Services.getMatchTestResultsByAutomationRule(projectId, currentTestResultId, effectiveErrorMessagePattern, effectiveStackTracePattern, () => {})
      .then((response) => {
        setCurrentExecutionTestResult(response.currentExecutionTestResult);
        setExecutionTestResults(response.executionTestResults);
        setTotalMatchedResults(response.totalMatchedResults);
        setIsLoading(false);
      })
      .catch((error) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        handleClose();
        Notification.pushError(error.message, t('error'));
      });
  }, [currentTestResultId, errorMessagePattern, handleClose, projectId, stackTracePattern]);

  const renderMatchedResultsAmount = () => (
    <Stack direction="row" spacing={2} className="fw-bold mt-2">
      <span>{t('match-results-dialog#title')}</span>
      <span className="match-test-result-dialog-small-text">{DecoratorConstants.badgeTextDecorator(totalMatchedResults)}</span>
    </Stack>
  );

  const renderMoreResultsAmount = () => {
    const moreResultsAmount = totalMatchedResults - MAX_MATCHED_RESULTS;
    if (moreResultsAmount > 0) {
      return (
        <span className="match-test-result-dialog-small-text">{`+${moreResultsAmount} ${t('more')}`}</span>
      );
    } else {
      return null;
    }
  };

  const testResultDecorator = (testResult: ExecutionTestResult, isCurrentTestResult: boolean) => {
    const statusIcon = DecoratorConstants.executionTestResultStatusDecorator('status', testResult);

    const constructedLink = new Routes({
      executionId: testResult.execution.order,
      executionTestResultId: testResult.urlId,
    });
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const link = DecoratorConstants.idDecorator('id', testResult, constructedLink.execution_test_result_detail_link, testResult.id.toString(), '', { target: '_blank' }, null);
    const name = DecoratorConstants.truncateLeftStringDecorator(testResult.testCase?.name, MAX_TEST_RESULT_NAME_LENGTH);
    return (
      <Stack direction="row" spacing={2}>
        <span>{statusIcon}</span>
        <span>{link}</span>
        <span>{name}</span>
        {isCurrentTestResult && <span className="match-test-result-dialog-small-text">{DecoratorConstants.badgeTextDecorator(t('current'))}</span>}
      </Stack>
    );
  };

  const renderDialogContent = () => {
    if (isLoading) {
      return (
        <div className="loading-type-field text-center mt-4">
          <CircularProgress size="2rem" thickness={5} />
          <div>{t('table#loading-message')}</div>
        </div>
      );
    } else if (totalMatchedResults === 0) {
      return (
        <>
          {renderMatchedResultsAmount()}
          <div className="text-center mt-4 custom-fields__empty-message">{t('match-results-dialog#no-match')}</div>
        </>
      );
    } else {
      return (
        <div className="fw-bold">
          {renderMatchedResultsAmount()}
          <div className="mt-2 mb-2">
            {currentExecutionTestResult && testResultDecorator(currentExecutionTestResult, true)}
            {executionTestResults?.map((testResult) => (testResultDecorator(testResult, false)))}
          </div>
          {renderMoreResultsAmount()}
        </div>
      );
    }
  };

  const renderDialog = () => (
    <ThemeProvider theme={katalonui_theme}>
      <CloseableDialogComponent
        isOpen={isOpen}
        handleClose={handleClose}
        maxWidth="sm"
        title={t('match-results-dialog#title')}
      >
        <Form>
          <DialogContent className="py-0">
            <div className="automation-rule-matching-constraint">
              {t('match-results-dialog#constraint')}
            </div>
            {renderDialogContent()}
          </DialogContent>
          <DialogActions className="py-0">
            <Button
              id="close-view-match-test-result-dialog-btn"
              onClick={handleClose}
              title={t('back')}
              color="primary"
              variant="text"
              size="medium"
            >
              {t('back')}
            </Button>
          </DialogActions>
        </Form>
      </CloseableDialogComponent>
    </ThemeProvider>
  );

  return renderDialog();
}

export default MatchTestResultDialog;
