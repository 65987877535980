import React from 'react';
import { Card, CardBody, Container, Row, Col } from 'reactstrap';
import HowToUpload from '../components/guide/HowToUpload';
import PageComponent from '../components/PageComponent';
import { t } from '../i18n/t';

class QuickGuide extends PageComponent {
  constructor(props) {
    super(props);
    this.state = {};
    this.meta.id = 'page-quick-guide';
    this.meta.title = t('quick_guide');
  }

  render() {
    return (
      <>
        <Card>
          <CardBody>
            <Container className="empty-project-page">
              <Row>
                <Col>
                  <HowToUpload />
                </Col>
              </Row>
            </Container>
          </CardBody>
        </Card>
      </>
    );
  }
}

export default QuickGuide;
