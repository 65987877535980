import { CustomInput, FormGroup, FormText, Label } from 'reactstrap';
import React, { useEffect, useMemo, useState } from 'react';
import { Controller } from 'react-hook-form';
import { t } from '../../../i18n/t';
import DatePicker from '../../DatePicker';
import Time from '../../../utils/Moment';
import Input from '../../Input';
import Select from '../../Select';
import { IntervalUnitOptions } from '../../../utils/Constants';
import { getDefaultSchedulerEnableRepeat, getIntervalUnitOptions } from '../../../pages/plan/ConfigurePlanUtils';

function WhenToRun(props) {
  const { form: { control, setValue, register, getValues }, scheduler, handleRepeatToggle, isSaveConfiguration } = props;
  const { startTime, endTime, intervalUnit, interval } = scheduler;

  const [selectedStartTime, setSelectedStartTime] = useState(startTime);
  const [selectedEndTime, setSelectedEndTime] = useState(endTime);
  const [selectedIntervalUnit, setSelectedIntervalUnit] = useState(intervalUnit);
  const [selectedInterval, setSelectedInterval] = useState(interval);
  const [enableRepeat, setEnableRepeat] = useState(() => getDefaultSchedulerEnableRepeat());
  const [minInterval, setMinInterval] = useState(1);

  const timeFormatter = (value) => {
    if (value) {
      return Time.formatWithoutTimeZone(value, Time.FULL_DATE_TIME_FORMAT);
    }
    return null;
  };

  const generateConfirmMessage = useMemo(() => {
    if (selectedIntervalUnit === null) {
      return '';
    }
    const timeRange = IntervalUnitOptions.find((option) => selectedIntervalUnit === option.value).label;
    const startTimeFormat = timeFormatter(selectedStartTime);
    const endTimeFormat = timeFormatter(selectedEndTime);
    let message = '';

    if (!startTimeFormat && endTimeFormat) {
      message = t('schedule#confirmation#without-start-time', { interval: selectedInterval, timeRange, endTimeFormat });
    } else if (startTimeFormat && !endTimeFormat) {
      message = t('schedule#confirmation#without-end-time', { interval: selectedInterval, timeRange, startTimeFormat });
    } else if (!startTimeFormat && !endTimeFormat) {
      message = t('schedule#confirmation#without-start-time-and-end-time', { interval: selectedInterval, timeRange });
    } else {
      message = t('schedule#confirmation', { interval: selectedInterval, timeRange, startTimeFormat, endTimeFormat });
    }
    return message;
  }, [selectedStartTime, selectedEndTime, selectedIntervalUnit, selectedInterval]);

  const handleRepeatSwitch = (checked) => {
    setEnableRepeat(checked);
    if (!checked) {
      const now = new Date();
      setSelectedStartTime(now);
      setValue('scheduler.startTime', now);
    }
    handleRepeatToggle(checked);
  };

  const onChangeIntervalUnit = (item, field) => {
    const interval = getValues('scheduler.interval');
    setSelectedIntervalUnit(item.value);
    setMinInterval(item.value === 'MINUTE' ? 10 : 1);
    if (item.value === 'MINUTE' && interval < 10) {
      setValue('scheduler.interval', 10);
      setSelectedInterval(10);
    }
    field.onChange(item.value);
  };

  useEffect(() => {
    if (typeof scheduler.repeatEnable === 'boolean') {
      setEnableRepeat(scheduler.repeatEnable);
      setSelectedStartTime(scheduler.startTime);
      setSelectedEndTime(scheduler.endTime);
      setSelectedInterval(scheduler.interval);
      setSelectedIntervalUnit(scheduler.intervalUnit);
    }
  }, [scheduler]);

  const intervalUnitOptions = getIntervalUnitOptions();

  return (
    <FormGroup className="d-flex">
      <Label className="col-2 test-run-label" for="testProjectId">Schedule at</Label>
      <div className="col-10 p-0">
        <div className="d-flex">
          <FormGroup className="col-6 pr-1 pl-0">
            <div><Label>{t('start')}<span className="text-danger"> * </span></Label></div>
            <Controller
              name="scheduler.startTime"
              control={control}
              rules={{ required: true }}
              defaultValue={startTime}
              render={({ field, formState: { errors } }) => (
                <div>
                  <DatePicker
                    {...field}
                    id="startTime"
                    name="startTime"
                    inputFormat={Time.DF_DATE_NOT_SS_FORMAT}
                    ampm={false}
                    {...register('confirmStartTime', {
                      validate: () => {
                        if (!enableRepeat || isSaveConfiguration) {
                          return true;
                        }
                        const startDate = getValues('scheduler.startTime');
                        if (startDate && startDate._isValid !== undefined) {
                          return startDate._isValid || 'Invalid time';
                        }
                        return true;
                      }
                    })}
                    maxDateTime={selectedEndTime ? Time.convertToMoment(selectedEndTime) : Time.MAX_DATE}
                    onChange={(value) => {
                      setSelectedStartTime(value);
                      field.onChange(value);
                    }}
                    placeholder={t('place-holder-datetime')}
                    disabled={!enableRepeat}
                    useDateTime
                  />
                  {errors.scheduler?.startTime && !selectedStartTime && enableRepeat && <small className="text-danger fs-6">This is required</small>}
                  {errors.confirmStartTime && !errors.scheduler?.startTime && <small className="text-danger fs-6">{errors.confirmStartTime?.message}</small>}
                </div>)}
            />
          </FormGroup>
          {enableRepeat &&
          <FormGroup className="col-6 pr-0 pl-1">
            <div><Label>{t('end')}</Label></div>
            <Controller
              name="scheduler.endTime"
              control={control}
              defaultValue={null}
              render={({ field, formState: { errors } }) => (
                <div>
                  <DatePicker
                    {...field}
                    id="endTime"
                    name="endTime"
                    {...register('confirmEndTime', {
                      validate: () => {
                        const endTime = getValues('scheduler.endTime');
                        if (endTime && endTime._isValid !== undefined) {
                          return endTime._isValid || 'Invalid time';
                        }
                        return true;
                      }
                    })}
                    inputFormat={Time.DF_DATE_NOT_SS_FORMAT}
                    minDateTime={selectedStartTime ? Time.convertToMoment(selectedStartTime) : Time.MIN_DATE}
                    onChange={(value) => {
                      setSelectedEndTime(value);
                      field.onChange(value);
                    }}
                    ampm={false}
                    placeholder={t('place-holder-datetime')}
                    useDateTime
                  />
                  {errors.confirmEndTime && <small className="text-danger fs-6">{errors.confirmEndTime?.message}</small>}
                </div>)}
            />
          </FormGroup>}
        </div>
        <div className="d-flex">
          <Controller
            name="scheduler.repeatEnable"
            control={control}
            defaultValue={enableRepeat}
            render={({ field }) =>
              <CustomInput
                {...field}
                id="repeat-enable"
                type="switch"
                name="repeat-enable"
                checked={field.value}
                onChange={(event) => {
                  handleRepeatSwitch(event.target.checked);
                  field.onChange(event);
                }}
                className="normal-label"
              />}
          />
          <Label for="repeat-enable">{t('repeat')}</Label>
        </div>

        {enableRepeat &&
        <>
          <div style={{ display: '-webkit-inline-box' }} className="trigger-repeat-frame">
            <div className="title">
              <Label>{t('every')}</Label>
            </div>
            <div className="interval">
              <Controller
                name="scheduler.interval"
                control={control}
                rules={{ required: enableRepeat && !isSaveConfiguration }}
                defaultValue={interval}
                render={({ field, formState: { errors } }) => (
                  <div style={{ paddingTop: '0.75rem', minWidth: '4.6rem' }}>
                    <Input
                      {...field}
                      id="interval"
                      required
                      type="number"
                      name="interval"
                      min={minInterval}
                      max={999}
                      {...register('confirmInterval', {
                        validate: () => {
                          if (isSaveConfiguration) {
                            return true;
                          }
                          const interval = getValues('scheduler.interval');
                          const intervalUnit = getValues('scheduler.intervalUnit');
                          if (interval) {
                            if (intervalUnit === 'MINUTE') {
                              return (interval >= 10 && interval <= 999) || 'Value must be between 10 and 999.';
                            }
                            return (interval >= 1 && interval <= 999) || 'Value must be between 1 and 999.';
                          }
                          return true;
                        }
                      })}
                      onChange={(event) => {
                        setSelectedInterval(event.target.value);
                        field.onChange(event);
                      }}
                    />
                    {errors.scheduler?.interval && <small className="text-danger text-nowrap fs-6">This is required</small>}
                    {!errors.scheduler?.interval && errors.confirmInterval &&
                        ((getValues('scheduler.interval') < 10 && getValues('scheduler.intervalUnit') === 'MINUTE')
                        || (getValues('scheduler.interval') < 1 && getValues('scheduler.intervalUnit') !== 'MINUTE')
                        || getValues('scheduler.interval') > 999)
                      && <small className="text-danger text-nowrap fs-6">{errors.confirmInterval?.message}</small>}
                  </div>)}
              />
            </div>
            <div className="intervalUnit">
              <Controller
                name="scheduler.intervalUnit"
                control={control}
                defaultValue={intervalUnit}
                rules={{ required: enableRepeat && !isSaveConfiguration }}
                render={({ field, formState: { errors } }) => (
                  <div className="pt-2 ml-3" style={{ minWidth: '8.6rem' }}>
                    <Select
                      {...field}
                      id="intervalUnit"
                      required
                      name="intervalUnit"
                      options={intervalUnitOptions}
                      onChange={(item) => {
                        onChangeIntervalUnit(item, field);
                      }}
                      hasOverflowParentDiaglog={document.getElementById('smart-scheduler-dialog')}
                      clearable={false}
                    />
                    {errors.scheduler?.intervalUnit && <small className="text-danger fs-6">This is required</small>}
                  </div>)}
              />
            </div>
          </div>
          <FormText color="muted">
            {generateConfirmMessage}
          </FormText>
        </>}
      </div>
    </FormGroup>
  );
}

export default WhenToRun;
