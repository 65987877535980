import { Button, ButtonToolbar } from 'reactstrap';
import {
  DialogActions,
  DialogContent,
  Stack,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { t } from '../../i18n/t';
import TabsDialogs from './TabsDialog';
import GroupEvent from '../../utils/track/GroupEvent';
import ExpandWithRadioButton from './ExpandWithRadioButton';
import { ALL_BROWSERS_TESTCLOUD_AGENT, BROWSER_TYPE_OPTIONS, CloudType, TestProjectType } from '../../utils/Constants';
import TestSuiteCollectionSelectionTable from '../smarttestscheduling/components/TestSuiteCollectionSelectionTable';
import LocalEnvironment from '../smarttestscheduling/components/LocalEnvironment';
import KubernetesEnvironment from '../smarttestscheduling/components/KubernetesEnvironment';
import CircleCIEnvironment from '../smarttestscheduling/components/CircleCIEnvironment';
import MFlags from '../../models/MFlags';
import ToggleTunnelContainer from '../smarttestscheduling/components/ToggleTunnelContainer';
import { transformExecutionEnvironments } from '../smarttestscheduling/services/testcloud';

function TestSuiteExecEnvAssignDialog(props) {
  const {
    isOpen,
    onOk,
    onClose,
    testSuites,
    groupedTestCloudAgentsTSC,
    nativeApps,
    localAgents,
    circleCIAgents,
    k8sAgents,
    initialValue,
    testProject
  } = props;

  const [expandedTestCloudEnv, setExpandedTestCloudEnv] = useState(true);
  const [expandedLocalEnv, setExpandedLocalEnv] = useState(false);
  const [expandedKubernetesEnv, setExpandedKubernetesEnv] = useState(false);
  const [expandedCircleCIEnv, setExpandedCircleCIEnv] = useState(false);
  const [selectedCircleCIAgents, setSelectedCircleCIAgents] = useState([]);
  const [selectedK8sAgents, setSelectedK8sAgents] = useState([]);
  const [selectedTestEnv, setSelectedTestEnv] = useState(CloudType.TEST_CLOUD_AGENT);
  const [isDisabledButton, setIsDisabledButton] = useState(true);

  const [testSuiteCollectionAgents, setTestSuiteCollectionAgents] = useState([]);

  const [enabledTestCloudTunnel, setEnabledTestCloudTunnel] = useState(false);
  const [enableApiTesting, setEnableApiTesting] = useState(false);

  const [tunnelId, setTunnelId] = useState(null);

  const [selectedLocalAgents, setSelectedLocalAgents] = useState([]);

  const collapseOrExpandExecutionEnv = (cloudType) => {
    setExpandedTestCloudEnv(cloudType === CloudType.TEST_CLOUD_AGENT);
    setExpandedLocalEnv(cloudType === CloudType.LOCAL_AGENT);
    setExpandedCircleCIEnv(cloudType === CloudType.CIRCLE_CI_AGENT);
    setExpandedKubernetesEnv(cloudType === CloudType.K8S_AGENT);
  };

  const toggleTestCloudTunnelIntegration = (event) => {
    const { checked } = event.target;
    setEnabledTestCloudTunnel(checked);
  };

  const updateEnableApiTesting = (event) => {
    const { checked } = event.target;
    setEnableApiTesting(checked);
  };

  const handleSelectedTunnelChange = (id) => {
    setTunnelId(id);
  };

  const handleChangeTestEnv = (value) => {
    setSelectedTestEnv(value);
    setExpandedTestCloudEnv(value === CloudType.TEST_CLOUD_AGENT && !expandedTestCloudEnv);
    setExpandedLocalEnv(value === CloudType.LOCAL_AGENT && !expandedLocalEnv);
    setExpandedCircleCIEnv(value === CloudType.CIRCLE_CI_AGENT && !expandedCircleCIEnv);
    setExpandedKubernetesEnv(value === CloudType.K8S_AGENT && !expandedKubernetesEnv);
  };

  const handleChangeK8sEnvironment = (k8sAgent) => {
    setSelectedK8sAgents(k8sAgent);
  };

  const handleChangeCircleCIAgents = (circleCIAgents) => {
    setSelectedCircleCIAgents(circleCIAgents);
  };

  const populateExtraPropertiesForTscAgents = (tscAgents) => tscAgents.map((value) => ({
    ...value,
    testCloudAgent: {
      ...value.testCloudAgent,
      enableApiTesting,
      tunnelId,
    },
    cloudType: selectedTestEnv,
    enabledTestCloudTunnel,
  }));

  const filterAndTransformTscAgents = (tscAgents) => {
    const transformedAgents = transformExecutionEnvironments(tscAgents);
    return populateExtraPropertiesForTscAgents(transformedAgents);
  };

  const populateExtraPropertiesForLocalAgents = (localAgents) =>
    localAgents.map((localAgent) => ({ ...localAgent, cloudType: CloudType.LOCAL_AGENT }));

  const populateExtraPropertiesForCircleCiAgents = (circleCIAgents) =>
    circleCIAgents.map((circleCIAgent) => ({ ...circleCIAgent, cloudType: CloudType.CIRCLE_CI_AGENT }));

  const populateExtraPropertiesForK8sAgents = (k8sAgents) =>
    k8sAgents.map((k8sAgent) => ({ ...k8sAgent, cloudType: CloudType.K8S_AGENT }));

  const getSelectedAgents = () => {
    switch (selectedTestEnv) {
      case CloudType.TEST_CLOUD_AGENT:
        return filterAndTransformTscAgents(testSuiteCollectionAgents);
      case CloudType.LOCAL_AGENT:
        return populateExtraPropertiesForLocalAgents(selectedLocalAgents);
      case CloudType.K8S_AGENT:
        return populateExtraPropertiesForK8sAgents(selectedK8sAgents);
      case CloudType.CIRCLE_CI_AGENT:
        return populateExtraPropertiesForCircleCiAgents(selectedCircleCIAgents);
      default:
        return [];
    }
  };

  useEffect(() => {
    const env = getSelectedAgents();
    if (selectedTestEnv === CloudType.TEST_CLOUD_AGENT && isEmpty(env) && !isEmpty(testSuites)) {
      const defaultTscAgents = testSuites.map(({ id }) => ({
        testSuiteCollectionConfigurationId: id,
        testCloudAgent: ALL_BROWSERS_TESTCLOUD_AGENT
      }));
      setTestSuiteCollectionAgents(defaultTscAgents);
      setEnabledTestCloudTunnel(false);
      setEnableApiTesting(false);
      setIsDisabledButton(false);
    } else {
      setIsDisabledButton(isEmpty(env) || env[0]?.isNotTestEnv || (isEmpty(testSuites) && testProject?.type === TestProjectType.GIT));
    }
  }, [selectedTestEnv, testSuiteCollectionAgents, selectedLocalAgents, selectedK8sAgents, selectedCircleCIAgents]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleClickSaveBtn = () => {
    onOk(getSelectedAgents());
  };

  // set trackid and groupid for icon close in schedule dialog
  const trackCloseIcon = {
    'data-trackid': 'close-schedule-dialog',
    'data-groupid': GroupEvent.SCHEDULE_TEST_RUN,
  };

  const handleExpandCircleCIClick = () => {
    setExpandedCircleCIEnv(!expandedCircleCIEnv);
    if (!expandedCircleCIEnv) {
      collapseOrExpandExecutionEnv(CloudType.CIRCLE_CI_AGENT);
    }
  };

  const handleExpandTestCloudClick = () => {
    setExpandedTestCloudEnv(!expandedTestCloudEnv);
    if (!expandedTestCloudEnv) {
      collapseOrExpandExecutionEnv(CloudType.TEST_CLOUD_AGENT);
    }
  };

  const handleExpandLocalClick = () => {
    setExpandedLocalEnv(!expandedLocalEnv);
    if (!expandedLocalEnv) {
      collapseOrExpandExecutionEnv(CloudType.LOCAL_AGENT);
    }
  };

  const handleExpandKubernetesClick = () => {
    setExpandedKubernetesEnv(!expandedKubernetesEnv);
    if (!expandedKubernetesEnv) {
      collapseOrExpandExecutionEnv(CloudType.K8S_AGENT);
    }
  };

  const customStyle = {
    tableContainer: 'custom-datagrid-container',
    tableDataGrid: 'custom-col-header-table'
  };

  const init = () => {
    if (initialValue && initialValue.length > 0) {
      const cloudType = initialValue[0].cloudType;
      setSelectedTestEnv(MFlags.testCloudDisableTCFromScheduleDialog && cloudType === CloudType.TEST_CLOUD_AGENT ? CloudType.LOCAL_AGENT : cloudType);
      collapseOrExpandExecutionEnv(MFlags.testCloudDisableTCFromScheduleDialog && cloudType === CloudType.TEST_CLOUD_AGENT ? CloudType.LOCAL_AGENT : cloudType);

      // TestCloud agents
      const testCloudAgents = initialValue.filter((agent) => agent.cloudType === CloudType.TEST_CLOUD_AGENT);
      setTestSuiteCollectionAgents(testCloudAgents);
      if (testCloudAgents.length > 0) {
        setEnabledTestCloudTunnel(testCloudAgents[0].enabledTestCloudTunnel);
        setEnableApiTesting(testCloudAgents[0].testCloudAgent?.enableApiTesting);
        setTunnelId(testCloudAgents[0].testCloudAgent?.tunnelId);
      }

      // Local agents
      const localAgents = initialValue.filter((agent) => agent.cloudType === CloudType.LOCAL_AGENT);
      setSelectedLocalAgents([...localAgents]);
      // K8s
      const k8sAgents = initialValue.filter((agent) => agent.cloudType === CloudType.K8S_AGENT);
      setSelectedK8sAgents([...k8sAgents]);
      // Circle CI
      const circleCIAgents = initialValue.filter((agent) => agent.cloudType === CloudType.CIRCLE_CI_AGENT);
      setSelectedCircleCIAgents([...circleCIAgents]);
    } else {
      const defaultTscAgents = testSuites.map(({ id }) => ({
        testSuiteCollectionConfigurationId: id,
        testCloudAgent: ALL_BROWSERS_TESTCLOUD_AGENT
      }));
      setTestSuiteCollectionAgents(defaultTscAgents);

      setEnabledTestCloudTunnel(false);
      setEnableApiTesting(false);
      setSelectedLocalAgents([]);
      setSelectedK8sAgents([]);
      setSelectedCircleCIAgents([]);
      setSelectedTestEnv(MFlags.testCloudDisableTCFromScheduleDialog ? CloudType.LOCAL_AGENT : CloudType.TEST_CLOUD_AGENT);
      setExpandedTestCloudEnv(!MFlags.testCloudDisableTCFromScheduleDialog);
      setExpandedLocalEnv(MFlags.testCloudDisableTCFromScheduleDialog);
      setExpandedCircleCIEnv(false);
      setExpandedKubernetesEnv(false);
    }
  };

  const handleClose = () => {
    init();
    onClose();
  };

  useEffect(() => {
    init();
  }, [nativeApps, initialValue]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <TabsDialogs
      id="smart-scheduler-dialog"
      isOpen={isOpen}
      handleClose={handleClose}
      maxWidth="md"
      dialogName={t('testcloud-environment#test-suite-collection')}
      headerStyle="schedule-header"
      disablePortal
      trackCloseIcon={trackCloseIcon}
      showCloseButton={false}
    >
      <DialogContent className="schedule-content test-environment-dialog-content">
        <>
          {!MFlags.testCloudDisableTCFromScheduleDialog &&
            <ExpandWithRadioButton
              expand={expandedTestCloudEnv}
              handleChange={handleChangeTestEnv}
              handleExpand={handleExpandTestCloudClick}
              checked={selectedTestEnv === CloudType.TEST_CLOUD_AGENT}
              value={CloudType.TEST_CLOUD_AGENT}
              title={t('testcloud-environment#title')}
            />}
          {
          expandedTestCloudEnv &&
          (
            <Stack height="100%" spacing={2} width="100%">
              <TestSuiteCollectionSelectionTable
                initialValue={testSuiteCollectionAgents}
                testSuites={testSuites}
                options={groupedTestCloudAgentsTSC}
                onChange={setTestSuiteCollectionAgents}
                classNames={customStyle}
                nativeApps={nativeApps}
              />
              <ToggleTunnelContainer
                enabledTestCloudTunnel={enabledTestCloudTunnel}
                toggleTestCloudTunnelIntegration={toggleTestCloudTunnelIntegration}
                enableApiTesting={enableApiTesting}
                updateEnableApiTesting={updateEnableApiTesting}
                handleSelectedTunnelChange={handleSelectedTunnelChange}
                tunnelId={tunnelId}
              />
            </Stack>
          )
        }
        </>
        <ExpandWithRadioButton
          expand={expandedLocalEnv}
          handleChange={handleChangeTestEnv}
          handleExpand={handleExpandLocalClick}
          checked={selectedTestEnv === CloudType.LOCAL_AGENT}
          value={CloudType.LOCAL_AGENT}
          title="Local"
        />
        {expandedLocalEnv &&
          <LocalEnvironment
            value={selectedLocalAgents}
            localAgents={localAgents}
            browserTypeOptions={BROWSER_TYPE_OPTIONS}
            hideBrowserTypeSelect
            onChange={setSelectedLocalAgents}
          />}
        {!MFlags.testCloudDisableTCFromScheduleDialog &&
        <ExpandWithRadioButton
          expand={expandedKubernetesEnv}
          handleChange={handleChangeTestEnv}
          handleExpand={handleExpandKubernetesClick}
          checked={selectedTestEnv === CloudType.K8S_AGENT}
          value={CloudType.K8S_AGENT}
          title="Kubernetes"
        />}
        {expandedKubernetesEnv &&
        <KubernetesEnvironment
          value={selectedK8sAgents}
          k8sAgents={k8sAgents}
          browserTypeOptions={BROWSER_TYPE_OPTIONS}
          hideBrowserTypeSelect
          onChange={handleChangeK8sEnvironment}
        />}
        {!MFlags.testCloudDisableTCFromScheduleDialog &&
        <ExpandWithRadioButton
          expand={expandedCircleCIEnv}
          handleChange={handleChangeTestEnv}
          handleExpand={handleExpandCircleCIClick}
          checked={selectedTestEnv === CloudType.CIRCLE_CI_AGENT}
          value={CloudType.CIRCLE_CI_AGENT}
          title="CircleCI"
        />}
        {expandedCircleCIEnv &&
        <CircleCIEnvironment
          value={selectedCircleCIAgents}
          circleCIAgents={circleCIAgents}
          browserTypeOptions={BROWSER_TYPE_OPTIONS}
          hideBrowserTypeSelect
          onChange={handleChangeCircleCIAgents}
        />}
      </DialogContent>
      <DialogActions className="d-flex footer-content">
        <ButtonToolbar>
          <Button
            id="close-configure-tsc-dialog"
            title={t('cancel')}
            color="secondary"
            onClick={handleClose}
          >
            {t('cancel')}
          </Button>
          <Button
            id="save-configure-tsc"
            title="Save"
            disabled={isDisabledButton}
            color="primary"
            type="submit"
            onClick={() => handleClickSaveBtn()}
          >
            Save
          </Button>
        </ButtonToolbar>
      </DialogActions>
    </TabsDialogs>
  );
}

TestSuiteExecEnvAssignDialog.propTypes = {
  nativeApps: PropTypes.array,
};

export default TestSuiteExecEnvAssignDialog;

