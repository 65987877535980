import React from 'react';
import { Button, Card, CardBody, CardHeader, Table, Alert } from 'reactstrap';
import DefaultLayout from '../../../components/DefaultLayout';
import PageComponent from '../../../components/PageComponent';
import ObjectSummary from '../../../components/summary/ObjectSummary';
import { t } from '../../../i18n/t';
import MContext from '../../../models/MContext';
import Time from '../../../utils/Moment';
import Services from '../../../utils/Services';
import Routes from '../../../utils/Routes';
import ProductLogo from '../../../images/ProductLogo';
import PageButtonToolbar from '../../../components/PageButtonToolbar';
import DecoratorConstants from '../../../utils/DecoratorConstants';
import MAuth from '../../../models/MAuth';
import { notificationIds } from '../../../utils/NotificationPageUtils';
import DocumentLink from '../../../utils/DocumentLink';

export const PRODUCT_ORDER = {
  KSE: 0,
  UNLIMITED_KSE: 1,
  ENGINE: 2,
  UNLIMITED_ENGINE: 3,
  FLOATING_ENGINE: 4,
};

class Subscriptions extends PageComponent {
  constructor(props) {
    super(props);
    this.meta.id = 'page-organization-testops-subscription';
    this.meta.title = t('subscription-management');

    this.organizationId = MContext.organizationId;
    this.state = {
      products: [],
    };

    this.renderHeader = this.renderHeader.bind(this);
    this.renderBody = this.renderBody.bind(this);
  }

  componentDidMount() {
    this.getProducts();
  }

  getProducts() {
    Services.getSubscriptionsInfo(this.organizationId, true)
      .then((products) => this.setState({ products }));
  }

  renderObjectSummary() {
    const urlParams = {
      subscription: t('subscriptions'),
    };
    return (
      <ObjectSummary params={urlParams} />
    );
  }

  renderHeader() {
    return (
      <>
        {this.renderObjectSummary()}
      </>
    );
  }

  renderCanceledAt(canceledAt) {
    return (
      <div>Canceled: {canceledAt}</div>
    );
  }

  renderProducts() {
    const { products } = this.state;
    const firstOrder = !(products.find((item) => item.activeSubscription && true) && true);
    products.sort((p1, p2) => PRODUCT_ORDER[p1.productId] - PRODUCT_ORDER[p2.productId]);
    return (
      <Table className="text-middle">
        <thead>
          <tr>
            <th>Name</th>
            <th>Billing cycle</th>
            <th>No. of license</th>
            <th>Expiry Date</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {products.filter(product => product.feature !== 'FLOATING_ENGINE').map((product) => this.renderProduct(product, firstOrder))}
        </tbody>
      </Table>
    );
  }

  renderProduct(product, firstOrder) {
    const { activeSubscription, name, productId, feature } = product;
    const organizationId = this.organizationId;
    const enterpriseProductId = product.id;
    let quota = 0;
    let expiryTime = 'N/A';
    const routes = new Routes({
      organizationId,
      enterpriseProductId
    });
    const buttonUrl = firstOrder ? routes.organization_subscription_new_orders() : routes.organization_subscription_upgrade;
    const quoteUrl = firstOrder ? routes.organization_quote_new_orders() : routes.organization_quote_upgrade;
    let buttonLabel = 'Subscribe';
    let btnColor = 'primary';
    let canceledAt = null;
    let subscribed = false;

    if (activeSubscription) {
      expiryTime = Time.format(activeSubscription.endDate);
      quota = activeSubscription.quantity;
      subscribed = activeSubscription.status === 'ACTIVE';
      if (subscribed) {
        buttonLabel = 'Upgrade';
        btnColor = 'success';
      } else {
        buttonLabel = 'Subscribe';
        btnColor = 'primary';
        canceledAt = Time.format(activeSubscription.canceledAt);
      }
    }

    return (
      <tr>
        <td><ProductLogo productId={productId} /> {name}</td>
        <td>{DecoratorConstants.billingIntervalDecorator('interval', activeSubscription)}</td>
        <td>{quota}</td>
        <td>
          <div>{expiryTime}</div>
          <div>{canceledAt ? (this.renderCanceledAt(canceledAt)) : null}</div>
        </td>
        <td>
          <Button
            color={btnColor}
            href={buttonUrl}
            data-trackid={`click-${buttonLabel}`}
          >{buttonLabel}
          </Button>
          {firstOrder && (
            <Button
              color={btnColor}
              href={quoteUrl}
              className="ml-1"
              data-trackid="click-get-quote"
            >
              {t('get_quote')}
            </Button>
          )}
        </td>
      </tr>
    );
  }

  renderEditButton() {
    const organizationId = this.organizationId;
    const router = new Routes(organizationId);
    return (
      <PageButtonToolbar>
        <Button
          data-trackid="click-edit-subscriptions"
          title="Edit Subscriptions"
          color="secondary"
          href={router.organization_subscription_edit}
        >
          {t('edit-subscription')}
        </Button>
      </PageButtonToolbar>
    );
  }

  renderBody() {
    const devopsSunsetLink =  DocumentLink.KRE_DEVOPS_SUNSET;
    return (
      <>
        <Alert color="primary">
        <h4 className="alert-heading">IMPORTANT</h4>
          We had recently sunset the Katalon Runtime Engine DevOps license option. For monthly subscription,
          if you would like to edit your licenses, please reach out to us via: <a href="mailto:success@katalon.com">success@katalon.com</a>. For annual subscription,
          please refer to this <a href={devopsSunsetLink} target="_blank" rel="noopener noreferrer">Documentation</a>.
        </Alert>
        {this.renderEditButton()}
        <Card>
          <CardHeader>Billing Summary</CardHeader>
          <CardBody>
            {this.renderProducts()}
          </CardBody>
        </Card>
      </>
    );
  }

  render() {
    const isBillingManager = MAuth.isBillingManager(this.organizationId);

    if (!isBillingManager) {
      Routes.goToNotificationPage(notificationIds.NO_PERMISSION);
    }
    return (
      <div>
        <DefaultLayout
          renderHeader={this.renderHeader}
          renderBody={this.renderBody}
        />
      </div>
    );
  }
}

export default Subscriptions;
