import React from 'react';
import TagChip from '../TagChip';
import { Project } from '../../models/model/Project';

interface LinkedProjectListProps {
  handleOnDelete: (project: Project) => void
  selectedProjects: Project[],
}
function LinkedProjectList({ handleOnDelete, selectedProjects }: LinkedProjectListProps) {

  const handleDelete = (option: Project) => {
    handleOnDelete(option);
  };

  return (
    <>
      {selectedProjects.map((option) => (
        <TagChip
          key={option.id}
          name={option.name}
          handleRemove={() => handleDelete(option)}
        />
      ))}
    </>
  );
}

export default LinkedProjectList;
