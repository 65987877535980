import React from 'react';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import IconButton from '@katalon-studio/katalon-ui/IconButton';
import { ThemeProvider } from '@mui/material/styles';
import { t } from '../../../i18n/t';
import TooltipComponent from '../../TooltipComponent';
import TestManagementActions from './TestManagementActions';
import ActionsHelper from '../../../utils/table/ActionsHelper';
import { TestEntity } from '../../../models/model/TestEntity';
import { katalonui_theme } from '../../../katalonui-theme';
import { ACTIONS, TestSuiteType } from '../../../utils/Constants';
import MFlags from '../../../models/MFlags';
import { TestType } from '../../../models/model/TestType';
import EllipsisVerticalIcon from '../../../../images/icons/katalonui/EllipsisVerticalIcon';
import colors from '../../../../scss/colors.scss';

function FixedActionMenu(props: { id: number; data: TestEntity, disabledButton: boolean, handleClick: (data: TestEntity) => void }) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { addTestCaseToTestSuiteEnabled, g5Editor } = MFlags;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    if ('handleClick' in props) {
      props.handleClick(props.data);
    }
  };

  const { id, data, disabledButton } = props;
  const { testCase, testSuite } = data;

  const actions = ActionsHelper.getAllowActions(data);
  const isDisabled = isEmpty(actions)
    || (actions.length === 1 && actions[0] === ACTIONS.ADD_TO_TEST_SUITE && !addTestCaseToTestSuiteEnabled)
    || (!g5Editor && testCase && testCase.testType === TestType.G5_TEST_CASE)
    || (!g5Editor && testSuite && testSuite.type === TestSuiteType.CLOUD_STUDIO);

  return (
    <ThemeProvider theme={katalonui_theme}>
      <TooltipComponent
        text={t('not-support-actions')}
        placement="bottom-end"
        disableHoverListener={!isDisabled}
        followCursor={undefined}
        open={undefined}
        customClasses={undefined}
      >
        <div>
          <IconButton
            id={`icon-button-${id}`}
            onClick={handleClick}
            disabled={disabledButton || isDisabled}
            size="small"
          >
            <EllipsisVerticalIcon color={(disabledButton || isDisabled) ? colors.gray470 : colors.gray9} />
          </IconButton>
        </div>
      </TooltipComponent>
      <TestManagementActions data={data} anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
    </ThemeProvider>
  );
}

FixedActionMenu.propTypes = {
  id: PropTypes.number.isRequired,
  data: PropTypes.object.isRequired,
  disabledButton: PropTypes.bool,
};

export default FixedActionMenu;
