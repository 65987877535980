import React from 'react';
import NormalCard from '../../../components/card/NormalCard';
import TooltipComponent from '../../../components/TooltipComponent';
import { t } from '../../../i18n/t';
import RequirementTestRunCoverage from '../../test_reports/requirement_test_run_coverage/RequirementTestRunCoverage';

const RequirementCoverage = () => {

  const renderHeader = () => (
    <div className="project-dashboard-header project-dashboard-header-title">
      {t('project-dashboard#requirement-coverage')}
      <TooltipComponent text={t('project-dashboard#requirement-coverage#tooltip')} placement="bottom-end" arrow />
    </div>
  );

  return (
    <RequirementTestRunCoverage
      title={renderHeader()}
      headerComponent={NormalCard}
      bodyComponent={NormalCard}
      showEmptyMessage
      useCollapseButton={false}
      cardClassName="shadow-none"
    />
  );
};

export default RequirementCoverage;
