import React from 'react';
import Icon from '@katalon-studio/katalon-ui/Icon';
import { CustomInput } from 'reactstrap';
import TooltipComponent from '../../TooltipComponent';
import { t } from '../../../i18n/t';

interface FilterUnresolvedTestResultProps {
  id: string;
  value: boolean;
  onFilterChange: (id: string, string: string) => void;
}

function FilterUnresolvedTestResult(props: Readonly<FilterUnresolvedTestResultProps>) {
  const { id, value, onFilterChange } = props;

  const applyFilter = (event : React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      onFilterChange(id, 'false');
    } else {
      onFilterChange(id, '');
    }
  };

  const renderLabel = () => (
    <span className="ml-1 mr-1">{t('need_review')}</span>
  );

  return (
    <div className="d-flex align-items-center">
      <CustomInput
        id="filter-unresolved-defects"
        name="filterUnresolvedDefects"
        type="switch"
        onChange={applyFilter}
        defaultChecked={value}
        bsSize="lg"
        label={renderLabel()}
        className="normal-label-switch"
      />
      <TooltipComponent
        text={t('filter-unresolved-test-result#tooltip')}
        placement="bottom-end"
        arrow
        followCursor={undefined}
        open={undefined}
        disableHoverListener={undefined}
        customClasses={undefined}
      >
        <div>
          <Icon
            type="fa-regular"
            name="fa-question-circle"
            textSize="14px"
            className="ml-1"
            sx={{
              color: '#808b9a',
              backgroundColor: '#fff',
            }}
          />
        </div>
      </TooltipComponent>
    </div>
  );
}

export default FilterUnresolvedTestResult;
