import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import MTableColumnDataMapping from '../../components/table/models/MTableColumnDataMapping';
import { t } from '../../i18n/t';
import { IconDelete } from '../../images/KitIcons';
import MAuth from '../../models/MAuth';
import MContext from '../../models/MContext';
import MFlags from '../../models/MFlags';
import DeleteAgentDialog from '../dialog/DeleteAgentDialog';
import DataTable from './DataTable';
import DecoratorConstants from '../../utils/DecoratorConstants';
import Arrays from '../../utils/Arrays';
import Services from '../../utils/Services';
import Notification from '../../utils/Notification';

class AgentDataTable extends React.Component {

  constructor(props) {
    super(props);
    this.organizationId = MContext.organizationId || MContext.team.organizationId;
    this.isOrganizationScope = !!MContext.organizationId;
        
    this.agentTableRef = React.createRef();
    this.agentRemoveDialogRef = React.createRef();

    this.state = {
      selectedAgent: {},
    };
    this.refreshData = this.refreshData.bind(this);
    this.enableDeleteButton = this.enableDeleteButton.bind(this);
  }

  refreshData() {
    this.agentTableRef.current.refreshData();
  }

  removeAgent(selectedAgent) {
    this.setState({ selectedAgent }, () => this.agentRemoveDialogRef.current.toggle());
  }

  enableDeleteButton() {
    const { enableDeleteButton } = this.props;
    if (enableDeleteButton === undefined) {
      return !!this.isOrganizationScope;
    }
    return enableDeleteButton ;
  }

  render() {
    const { selectedAgent } = this.state;
    const { disableDeleteButton } = this.props;
    const isOrganizationManager = MAuth.isOrganizationManager(this.organizationId);
    const enableDeleteButton = !MFlags.moveAgentToOrgLevelPhase2Enabled || (isOrganizationManager && this.enableDeleteButton());

    const columnMapping = [
      new MTableColumnDataMapping(
        t('status'),
        'active',
        (name, row) => {
          const active = _.get(row, name);
          const title = active ? 'Online' : 'Offline';
          return (
            <span title={title}>
              {DecoratorConstants.agentStatusDecorator(active)}
            </span>
          );
        },
        undefined,
        'fit-content-column',
      ),
      new MTableColumnDataMapping(
        t('agent#name'),
        'name',
        DecoratorConstants.localAgentDetailDecorator,
      ),
      new MTableColumnDataMapping(
        t('agent#ip'),
        'ip',
      ),
      new MTableColumnDataMapping(
        t('agent#uuid'),
        'uuid',
      ),
      new MTableColumnDataMapping(
        t('agent#assigned-jobs'),
        'numAssignedJobs',
        (name, row) => {
          const numAssignedJobs = _.get(row, name);
          const threshold = _.get(row, 'threshold', 0);
          return (
            <div>
              <span title={`${t('agent#num-assigned-jobs')}: ${numAssignedJobs}`}>{numAssignedJobs}</span>
              /
              <span title={`${t('agent#threshold')}: ${threshold}`}>{threshold}</span>
            </div>
          );
        },
      ),
      new MTableColumnDataMapping(
        t('agent#executing-jobs'),
        'numExecutingJobs',
      ),
      new MTableColumnDataMapping(
        t('agent#last-ping'),
        'lastPing',
        DecoratorConstants.timeDecorator,
        undefined,
        'nowrap-column',
      ),
      new MTableColumnDataMapping(
        t('table-header#osVersion'),
        'os',
        (name, row) => {
          const osVersion = _.get(row, name);
          return DecoratorConstants.platform({ osVersion });
        },
      ),
      new MTableColumnDataMapping(
        t('agent#hostname'),
        'hostname',
      ),
      ...Arrays.insertIf(enableDeleteButton, new MTableColumnDataMapping(
        t('table-header#action'),
        'id',
        (name, row) => (
          <Button
            className="btn-icon-only"
            color="link"
            onClick={() => this.removeAgent(row)}
            title={t('agent#remove')}
          >
            <IconDelete />
          </Button>
        ),
      )),
    ];
    const newProps = {
      columnMapping,
      ...this.props,
    };
    return (
      <>
        <DataTable
          ref={this.agentTableRef}
          entityType="Agent"
          tableId="Agents"
          {...newProps}
        />
        {
          (!MFlags.moveAgentToOrgLevelPhase2Enabled || !disableDeleteButton) &&
          <DeleteAgentDialog
            key={selectedAgent.id}
            ref={this.agentRemoveDialogRef}
            agent={selectedAgent}
            afterRemoval={this.refreshData}
          />
        }
      </>
    );
  }
}

AgentDataTable.propTypes = {
  enableDeleteButton: PropTypes.bool,
};

export default AgentDataTable;
