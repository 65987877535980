import { OrganizationFeature } from "./Constants";

export const getFeatureFullName = (feature) => {
    let featureName = "Katalon";
    
    if(feature) {
      switch(feature) {
        case OrganizationFeature.ENGINE: 
          featureName = "KRE_Node_Locked";
          break;
        case OrganizationFeature.UNLIMITED_ENGINE:
          featureName = "KRE_Floating";
          break;
        case OrganizationFeature.KSE:
          featureName = "KSE_Node_Locked";
          break;
        case OrganizationFeature.UNLIMITED_KSE: 
          featureName = "KSE_Floating";
          break;
        default: 
          featureName = `${OrganizationFeature[feature]}`;
      }
    }

    return featureName;
}

export const getTestingLicenseFileName = (feature, machineId) => { 
    return `Testing_${getFeatureFullName(feature)}_${machineId}.lic`;
}

export const getLicenseFileName = (feature, machineId, id) => {     
    return `${getFeatureFullName(feature)}_${machineId}_${id}.lic`;
}