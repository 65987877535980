import React from 'react';
import { Box, Typography, Divider, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import TooltipComponent from '../../../components/TooltipComponent';
import { t } from '../../../i18n/t';
import { IconInfoCircleWhite } from '../../../images/CustomIcon';
import SessionSummaryInfo from './SessionSummaryInfo';
import { SESSION_TYPE_MAPPING } from '../../../utils/Constants';
import DecoratorConstant from '../../../utils/DecoratorConstants';
import StringHelper from '../../../utils/StringHelper';

export const StyledStack = styled(Stack)({
  width: '100%',
  '& .MuiDivider-root': {
    borderStyle: 'dashed',
    height: '1px',
  },
});

export const StyledTypography = styled(Typography)({
  fontSize: '12px',
  fontWeight: 500,
  color: '#808b9a',
});

const StyledMainContainer = styled(Box)({
  minWidth: '460px',
  alignItems: 'stretch',
  display: 'flex',
  flexDirection: 'row',
  flexGrow: 1,
  borderRadius: '4px',
  boxShadow: '0 4px 10px -2px rgba(153, 153, 153, 0.14)',
  padding: '20px',
  gap: '2px',
});

const StyledSessionTypeContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  flexGrow: 1,
});

const StyledSessionInfoContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  gap: '2px',
});

const tooltipStyle = makeStyles(() => ({
  tooltip: {
    backgroundColor: '#1a2534',
    color: '#FFFFFF',
    fontWeight: 'normal',
    padding: '4px 8px',
    borderRadius: '4px',
    fontSize: '14px',
    lineHeight: '1.43'
  },
  arrow: {
    color: '#1a2534',
  }
}));

const getCustomTooltipText = (sessionType) => {
  const isMobileNativeSupported = sessionType.supportedSessionTypes.includes('MOBILE_NATIVE');
  if (isMobileNativeSupported) {
    return t('test-cloud-session-summary#multi-platform-tooltip');
  } else {
    return t('test-cloud-session-summary#cross-browser-tooltip');
  }
};

const renderSessionType = (sessionType) => (
  <Stack
    height="39px"
    display="flex"
    direction="row"
    spacing={1}
    alignItems="center"
    paddingRight="6px"
  >
    <Box>{DecoratorConstant.sessionTypesDecorator(sessionType).mainIcon}</Box>
    <Box display="flex" flexDirection="column" paddingRight="23px">
      <Typography fontWeight={500} color="#233145">
        {SESSION_TYPE_MAPPING[sessionType.feature] === SESSION_TYPE_MAPPING.TESTCLOUD_SESSION_WEB ? (
          <Box display="flex" flexDirection="row" alignItems="center" gap="4px">
            {SESSION_TYPE_MAPPING[sessionType.feature]}
            <TooltipComponent
              text={getCustomTooltipText(sessionType)}
              placement="bottom-start"
              arrow
              customClasses={tooltipStyle()}
            >
              <IconInfoCircleWhite data-testid="info-icon" style={{ cursor: 'pointer' }} />
            </TooltipComponent>
          </Box>
        ) : (
          SESSION_TYPE_MAPPING[sessionType.feature] || StringHelper.normalizeString(sessionType.feature)
        )}
      </Typography>
      <StyledTypography>
        {sessionType.limit} Sessions
      </StyledTypography>
    </Box>
  </Stack>
);

function TestSessionSummaryPopover({ sessionTypes }) {
  return (
    <StyledMainContainer>
      <StyledSessionTypeContainer>
        <StyledTypography paddingRight="29px">Product Name</StyledTypography>
        <StyledStack spacing={1} divider={<Divider />}>
          {sessionTypes.map((sessionType) => (
            <Box key={sessionType.feature}>
              {renderSessionType(sessionType)}
            </Box>
          ))}
        </StyledStack>
      </StyledSessionTypeContainer>
      <StyledSessionInfoContainer>
        <SessionSummaryInfo
          title="Organization"
          runningKey="runningSessionsForOrganization"
          queuedKey="queuedSessionsForOrganization"
          sessionTypes={sessionTypes}
          paddingRight="32px"
        />
        <SessionSummaryInfo
          title="Project"
          runningKey="runningSessionsForProject"
          queuedKey="queuedSessionsForProject"
          sessionTypes={sessionTypes}
        />
      </StyledSessionInfoContainer>
    </StyledMainContainer>
  );
}

TestSessionSummaryPopover.propTypes = {
  sessionTypes: PropTypes.arrayOf(
    PropTypes.shape({
      limit: PropTypes.number,
      feature: PropTypes.string,
      runningSessionsForOrganization: PropTypes.number,
      runningSessionsForProject: PropTypes.number,
      queuedSessionsForOrganization: PropTypes.number,
      queuedSessionsForProject: PropTypes.number,
      supportedSessionTypes: PropTypes.arrayOf(PropTypes.string),
    }),
  ),
};

export default TestSessionSummaryPopover;
