import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import LineChart from './LineChart';
import ChartConstants from '../../utils/ChartConstants';
import MChartDataMapping from './models/MChartDataMapping';
import MChartLabelMapping from './models/MChartLabelMapping';

class SimpleLineChart extends React.Component {

  render() {

    const { data, xAxis, yAxis, options } = this.props;
    let { chartLabelsDecorate } = this.props;

    const yAxisLabel = _.upperFirst(yAxis);

    if (!chartLabelsDecorate) {
      chartLabelsDecorate = (fieldName, item) => `${_.get(item, fieldName)}`;
    }

    const chartLabelsMapping = new MChartLabelMapping(
      xAxis,
      chartLabelsDecorate,
    );

    const chartDatasetsMapping = [
      new MChartDataMapping(yAxisLabel, yAxis, 'rgba(63, 159, 191, 0.51)'),
    ];

    let isdisplay = true;
    if (this.props.xLabel === undefined) {
      isdisplay = false;
    }

    const defaultOptions = {
      scales: {
        xAxes: [
          {
            scaleLabel: {
              display: isdisplay,
              labelString: this.props.xLabel,
            },
            gridLines: {
              display: true,
            },
          },
        ],
        yAxes: [{
          scaleLabel: {
            display: true,
            labelString: yAxisLabel,
          },
          ticks: {
            callback: (value) => value,
          },
        }],

      },
      tooltips: {
        callbacks: {
          label: (tooltipitem) => `${yAxisLabel}: ${tooltipitem.yLabel}`,
        },
      },
      legend: {
        display: false,
      },
    };
    const chartOptions = _.merge({}, defaultOptions, options);

    const pointColors = data.map(() => this.props.pointColor);
    const datasetOptions = [{
      pointBackgroundColor: pointColors,
      pointRadius: 5,
      pointBorderColor: pointColors,
      pointHoverBackgroundColor: pointColors,
      pointHoverBorderColor: pointColors,
      borderWidth: 1,
    }];

    return (
      <LineChart
        data={data}
        labelsMapping={chartLabelsMapping}
        datasetsMapping={chartDatasetsMapping}
        options={chartOptions}
        datasetOptions={datasetOptions}
        xLabel={this.props.xLabel}
        yLabel={this.props.yLabel}
      />
    );
  }
}

SimpleLineChart.propTypes = {
  data: PropTypes.array.isRequired,
  chartLabelsDecorate: PropTypes.func,
  xLabel: PropTypes.string,
  xAxis: PropTypes.string,
  yAxis: PropTypes.string,
  pointColor: PropTypes.string,
};

SimpleLineChart.defaultProps = {
  xAxis: 'id',
  pointColor: ChartConstants.simpleLineChartPointColor,
};

export default SimpleLineChart;
