import { Route, Switch } from 'react-router-dom';
import React from 'react';
import { Alert } from 'reactstrap';
import moment from 'moment';
import PageComponent from '../../components/PageComponent';
import MContext from '../../models/MContext';
import { t } from '../../i18n/t';
import ObjectSummary from '../../components/summary/ObjectSummary';
import { ReportTestMaintenanceHeaderNav } from '../../components/HeaderNav';
import RouteConstants from '../../utils/RouteConstants';
import DefaultLayout from '../../components/DefaultLayout';
import FlakinessReport from './components/FlakinessReport';
import PlatformStatisticsReport from './components/PlatformStatisticsReport';
import { analyticsPage } from '../../utils/SegmentAnalytics';
import ListTestReports from './ListTestReports';
import { withTabComponent } from '../../components/HigherOrderComponent';
import TestCaseDataTable from '../../components/table/TestCaseDataTable';
import { buildSearchCondition } from '../../components/search/SearchUtils';
import { buildFilter } from '../../components/search-query/FilterQueryHelper';
import CustomFieldFilter from '../../components/CustomFieldFilter';
import TagFilterV2 from '../../components/search-query/filter/TagFilterV2';

const twoMonthAgo = moment().subtract(2, 'month').format();

const StaleTestCasesTab = withTabComponent(
  (props) => <TestCaseDataTable
    additionalFilterQuery={[
      buildFilter(CustomFieldFilter, { id: 'CustomField' }),
      buildFilter(TagFilterV2, { id: 'Tag.id', label: t('search-bar#tag') }),
    ]}
    defaultSearchConditions={[
      buildSearchCondition('ExecutionStatistics.startTime', '<', twoMonthAgo)
    ]}
    defaultSort={['ExecutionStatistics.startTime,asc']}
    {...props}
  />,
  'page-test-cases-stale',
  'Stale Test Cases'
);

const SlowestTestCasesTab = withTabComponent(
  (props) => <TestCaseDataTable
    additionalFilterQuery={[
      buildFilter(CustomFieldFilter, { id: 'CustomField' }),
      buildFilter(TagFilterV2, { id: 'Tag.id', label: t('search-bar#tag') }),
    ]}
    defaultSearchConditions={[
      buildSearchCondition('ExecutionStatistics.startTime', '>=', twoMonthAgo)
    ]}
    defaultSort={['averageDuration,desc']}
    {...props}
  />,
  'page-test-cases-slowest',
  'Slowest Test Cases'
);

const ActiveTestCasesTab = withTabComponent(
  (props) => <TestCaseDataTable
    additionalFilterQuery={[
      buildFilter(CustomFieldFilter, { id: 'CustomField' }),
      buildFilter(TagFilterV2, { id: 'Tag.id', label: t('search-bar#tag') }),
    ]}
    defaultSearchConditions={[
      buildSearchCondition('ExecutionStatistics.startTime', '>=', twoMonthAgo)
    ]}
    defaultSort={['ExecutionStatistics.startTime,desc']}
    {...props}
  />,
  'page-test-cases-active',
  'Active Test Cases'
);

class TestReportTestMaintenance extends PageComponent {

  constructor(props) {
    super(props);
    this.meta.id = 'page-test-report-test-maintenance';
    this.meta.title = t('test_cases');

    this.teamId = MContext.teamId;
    this.projectId = MContext.projectId;
    this.project = MContext.project;

    this.renderHeader = this.renderHeader.bind(this);
    this.renderBody = this.renderBody.bind(this);
  }

  renderHeader() {
    const { project } = this;
    const urlParams = {
      testReports: t('test_cases'),
      projectName: project.name,
      testReportTitle: t(this.testReportId),
    };
    return (
      <ObjectSummary params={urlParams}>
        <ReportTestMaintenanceHeaderNav teamId={this.teamId} projectId={this.projectId} />
      </ObjectSummary>
    );
  }

  renderComponent(component, id) {
    this.meta.title = t(id);
    analyticsPage(`page-report-${id}`);
    return (
      <>
        <Alert color="primary">
          {t(`${id}-description`)}
        </Alert>
        {component}
      </>
    );
  }

  renderBody() {
    return (
      <Switch>
        <Route
          exact
          path={RouteConstants.test_report_flakiness}
          render={(params) =>
            this.renderComponent(
              <FlakinessReport {...params} />,
              ListTestReports.flakinessReport.id
            )}
        />
        <Route
          exact
          path={RouteConstants.test_report_platform_statistics}
          render={(params) =>
            this.renderComponent(
              <PlatformStatisticsReport {...params} />,
              ListTestReports.platformStatistics.id
            )}
        />
        <Route
          exact
          path={RouteConstants.test_cases_active}
          render={(params) => <ActiveTestCasesTab {...params} />}
        />
        <Route
          exact
          path={RouteConstants.test_cases_stale}
          render={(params) => <StaleTestCasesTab {...params} />}
        />
        <Route
          exact
          path={RouteConstants.test_cases_slowest}
          render={(params) => <SlowestTestCasesTab {...params} />}
        />
      </Switch>
    );
  }

  render() {
    return (
      <DefaultLayout
        renderHeader={this.renderHeader}
        renderBody={this.renderBody}
      />
    );
  }
}

export default TestReportTestMaintenance;
