import React from 'react';
import PropTypes from 'prop-types';
import MiddleEllipsis from 'react-middle-ellipsis';

function TextMiddleTruncate({ width, children, title, className }) {
  return (
    <div style={{ width, whiteSpace: 'nowrap' }} title={title} className={className || ''}>
      <MiddleEllipsis>
        {children}
      </MiddleEllipsis>
    </div>
  );
}

TextMiddleTruncate.propTypes = {
  /**
   * children must contain <span>text</span>
   */
  children: PropTypes.object.isRequired,
  width: PropTypes.string
};

export default TextMiddleTruncate;
