import React, { useState } from 'react';
import { Button } from 'reactstrap';
import { AIImpactedLocation } from '../../../models/model/AIImpactedLocation';
import { TestCase } from '../../../models/model/TestCase';
import { FailedTestCase } from '../../../models/model/FailedTestCase';
import { TestStability } from '../../../models/model/TestStability';
import { CollapseCardType } from '../../../utils/ConstantsType';
import DrawerAIResponse from '../../../components/DrawerAIResponse';
import { VIRTUAL_DATA_QUESTIONS } from '../../../utils/Constants';
import { t } from '../../../i18n/t';
import AIBlock from './AIBlock';
import MetricComponent from './MetricComponent';
import { IconStatusCircleFailed, IconStatusCircleIncomplete, IconStatusCircleOpen, IconEmptyDataDrawer } from '../../../images/CustomIcon';
import { verticalDivider } from '../../execution/ExecutionDecorator';
import DecoratorConstants from '../../../utils/DecoratorConstants';
import MTableColumnDataMapping from '../../../components/table/models/MTableColumnDataMapping';
import TableSortCore from '../../../components/table/models/TableSortCore';
import Routes from '../../../utils/Routes';
import DocumentLink from '../../../utils/DocumentLink';
import GroupEvent from '../../../utils/track/GroupEvent';
import { sendAnalyticEventForAction } from '../../../utils/SegmentAnalytics';

interface DrawerAITestStabilityProps {
  isOpen: boolean;
  closeDrawer: () => void;
}

function DrawerAITestStability(props: DrawerAITestStabilityProps) {
  const { isOpen, closeDrawer } = props;
  const [trigger, setTrigger] = useState(0);

  const buildTestStabilityData = (testStability: TestStability, aiCompletions?: TestStability | null): CollapseCardType[] => {

    const testHealthSummaryHeader = () => {
      const flakyTests = DecoratorConstants.getIconAndType(testStability.flakyTestsTrend, false);
      const newFailure = DecoratorConstants.getIconAndType(testStability.newFailuresTrend, false);
      const alwaysFailing = DecoratorConstants.getIconAndType(testStability.alwaysFailingTrend, false);
      return (
        <div className="d-flex">
          <MetricComponent
            icon={flakyTests.icon}
            title={t('flaky-tests')}
            label={
              <div className="metric-normal-label">
                {testStability.totalFlakyTests}
              </div>
            }
            subLabel={testStability.flakyTestsTrend}
            type={flakyTests.type}
            tooltip={t('flaky-tests-tooltip')}
          />
          {verticalDivider('6rem', '2.1rem')}
          <MetricComponent
            icon={newFailure.icon}
            title={t('new-failure')}
            label={
              <div className="metric-normal-label">
                {testStability.totalNewFailures}
              </div>
            }
            subLabel={testStability.newFailuresTrend}
            type={newFailure.type}
            tooltip={t('new-failure-tooltip')}
          />
          {verticalDivider('6rem', '2.1rem')}
          <MetricComponent
            icon={alwaysFailing.icon}
            title={t('always-failing')}
            label={
              <div className="metric-normal-label">
                {testStability.totalAlwaysFailing}
              </div>
            }
            subLabel={testStability.alwaysFailingTrend}
            type={alwaysFailing.type}
            tooltip={t('always-failing-tooltip')}
          />
        </div>
      );
    };

    const top5FailuresHeader = () => {
      const failureTests = DecoratorConstants.getIconAndType(testStability.failureTestsTrend, false);
      const avgFailureRate = DecoratorConstants.getIconAndType(testStability.averageFailureRateTrend, false);
      return (
        <div className="d-flex">
          <MetricComponent
            icon={failureTests.icon}
            title={t('failure-tests')}
            label={
              <div className="metric-normal-label">
                {testStability.totalFailureTests}
              </div>
            }
            subLabel={testStability.failureTestsTrend}
            type={failureTests.type}
          />
          {verticalDivider('6rem', '2.1rem')}
          <MetricComponent
            icon={avgFailureRate.icon}
            title={t('avg-failure-rate')}
            label={
              <div className="metric-normal-label">
                {DecoratorConstants.formatPercentage(testStability.averageFailureRate)}
              </div>
            }
            subLabel={testStability.averageFailureRateTrend}
            type={avgFailureRate.type}
          />
        </div>
      );
    };

    const top5FailuresContent = () => {
      const data = testStability.failureTests;
      const columnMapping = [
        new MTableColumnDataMapping(
          t('top-5-failed-test-cases'),
          '',
          (name: string, row: FailedTestCase) => DecoratorConstants.nameAndPathDecorator(name, row),
          undefined,
          '',
          undefined,
          '60%'
        ),
        new MTableColumnDataMapping(
          t('failed-run'),
          '',
          (_name: string, row: FailedTestCase) => <div>{`${row.totalFailed}/${row.total}`}</div>,
        ),
        new MTableColumnDataMapping(
          t('failure-rate'),
          '',
          (_name: string, row: FailedTestCase) => <div>{DecoratorConstants.formatPercentage(row.failureRate)}</div>,
        ),
      ];
      return <TableSortCore className="drawer-card-table" data={data} columnMapping={columnMapping} />;
    };

    const failureByLocationsContent = () => {
      const data = testStability.failureTestByLocations;
      const columnMapping = [
        new MTableColumnDataMapping(
          t('folder-location'),
          '',
          (name: string, row: AIImpactedLocation) => DecoratorConstants.folderLocationDecorator(name, row),
          undefined,
          '',
          undefined,
          '50%'
        ),
        new MTableColumnDataMapping(
          t('failed-results'),
          '',
          (name: string, row: AIImpactedLocation) => DecoratorConstants.failureByLocationsDecorator(name, row, testStability.totalFailedTestResults),
        ),
      ];
      return <TableSortCore className="drawer-card-table" data={data} columnMapping={columnMapping} />;
    };

    const top5SlowestTestsHeader = () => {
      const avgDuration = DecoratorConstants.getIconAndType(testStability.averageDurationTrend, false);
      return (
        <div className="d-flex">
          <MetricComponent
            icon={avgDuration.icon}
            title={t('avgDuration')}
            label={
              <div className="metric-normal-label">
                {DecoratorConstants.durationDecorator('averageDuration', testStability)}
              </div>
            }
            subLabel={testStability.averageDurationTrend}
            type={avgDuration.type}
          />
        </div>
      );
    };

    const top5SlowestTestsContent = () => {
      const data = testStability.slowestTests;
      const columnMapping = [
        new MTableColumnDataMapping(
          t('top-5-slowest-test-cases'),
          '',
          (name: string, row: TestCase) => DecoratorConstants.nameAndPathDecorator(name, row, row.testFolder?.rawPath),
          undefined,
          '',
          undefined,
          '60%'
        ),
        new MTableColumnDataMapping(
          t('total-runs'),
          'numberOfExecutions',
        ),
        new MTableColumnDataMapping(
          t('avgDuration'),
          '',
          (_name: string, row: TestCase) => <div>{DecoratorConstants.durationDecorator('averageDuration', row)}</div>,
        ),
      ];
      return <TableSortCore className="drawer-card-table" data={data} columnMapping={columnMapping} />;
    };

    const topFlakyTestsHeader = () => (
      <div className="d-flex">
        <MetricComponent
          icon={<IconStatusCircleFailed />}
          title={t('high-severity')}
          label={
            <div className="metric-normal-label">
              {testStability.totalHighSeverityTests}
            </div>
          }
          tooltip={t('high-severity-tooltip')}
        />
        {verticalDivider('6rem', '2.1rem')}
        <MetricComponent
          icon={<IconStatusCircleOpen />}
          title={t('medium-severity')}
          label={
            <div className="metric-normal-label">
              {testStability.totalMediumSeverityTests}
            </div>
          }
          tooltip={t('medium-severity-tooltip')}
        />
        {verticalDivider('6rem', '2.1rem')}
        <MetricComponent
          icon={<IconStatusCircleIncomplete />}
          title={t('low-severity')}
          label={
            <div className="metric-normal-label">
              {testStability.totalLowSeverityTests}
            </div>
          }
          tooltip={t('low-severity-tooltip')}
        />
      </div>
    );

    const topFlakyTestsContent = () => {
      const data = testStability.flakyTests;
      const columnMapping = [
        new MTableColumnDataMapping(
          t('test_case'),
          '',
          (name: string, row: TestCase) => DecoratorConstants.nameAndPathDecorator(name, row, row.testFolder?.rawPath),
          undefined,
          '',
          undefined,
          '60%'
        ),
        new MTableColumnDataMapping(
          t('flaky-rate'),
          '',
          (_name: string, row: TestCase) => <div>{DecoratorConstants.formatPercentage(row.flakiness)}</div>,
        ),
        new MTableColumnDataMapping(
          t('severity'),
          '',
          (_name: string, row: TestCase) => <div>{DecoratorConstants.severityDecorator(row.flakySeverity)}</div>,
        ),
      ];
      return <TableSortCore className="drawer-card-table" data={data} columnMapping={columnMapping} />;
    };

    const refresh = () => {
      setTrigger((trigger) => trigger + 1);
      sendAnalyticEventForAction(
        'ra_ai_refresh_ai_block_clicked',
        { 'data-groupid': GroupEvent.VIRTUAL_DATA_ANALYST, question: VIRTUAL_DATA_QUESTIONS.TEST_STABILITY.dataTrackId }
      );
    };

    const testStabilityDrawerData = [
      {
        title: t('test-health-summary'),
        header: testHealthSummaryHeader(),
        content: <AIBlock
          enableAi={testStability.enableAi}
          text={aiCompletions?.completions}
          refresh={refresh}
          questionId={VIRTUAL_DATA_QUESTIONS.TEST_STABILITY.dataTrackId}
        />,
        disableCollapse: true,
        id: 'test-health-summary'
      },
      {
        title: t('top-5-failures'),
        header: top5FailuresHeader(),
        content: top5FailuresContent(),
        id: 'top-5-failures',
        tooltip: t('top-5-failures-tooltip')
      },
      {
        title: t('failure-by-locations'),
        header: <span />,
        content: failureByLocationsContent(),
        id: 'failure-by-locations',
        tooltip: t('failure-by-locations-tooltip')
      },
      {
        title: t('top-5-slowest-tests'),
        header: top5SlowestTestsHeader(),
        content: top5SlowestTestsContent(),
        id: 'top-5-slowest'
      },
      {
        title: t('top-flaky-tests'),
        header: topFlakyTestsHeader(),
        content: topFlakyTestsContent(),
        id: 'top-flaky-tests',
        tooltip: t('top-flaky-tests-tooltip')
      }
    ];

    return testStabilityDrawerData;
  };

  const emptyComponent = () => {
    const goToExecutions = () => {
      const route = new Routes();
      window.open(route.test_planning_link, '_blank');
    };
    return (
      <div className="mt-3 empty-state-drawer">
        <div>
          <div className="d-flex justify-content-center">
            <IconEmptyDataDrawer />
          </div>
          <div className="d-flex justify-content-center empty-state-drawer-title">
            {t('no-test-stability-title')}
          </div>
          <div className="d-flex justify-content-center empty-state-drawer-desc">
            {t('no-test-stability-desc')}
          </div>
          <div className="d-flex justify-content-center mt-2 empty-state-drawer-action">
            <Button color="primary" className="mr-3" onClick={goToExecutions}>{t('go-to-executions')}</Button>
            <Button color="secondary" onClick={() => { window.open(DocumentLink.USE_TESTCLOUD_IN_TESTOPS, '_blank'); }}>{t('learn_more')}</Button>
          </div>
        </div>
      </div>
    );
  };

  const checkEmpty = (testStability: TestStability | null) => {
    if (testStability && testStability.totalExecutedTestCases > 0) {
      return false;
    }
    return true;
  };

  return (
    <DrawerAIResponse
      isOpen={isOpen}
      closeDrawer={closeDrawer}
      buildData={buildTestStabilityData}
      question={VIRTUAL_DATA_QUESTIONS.TEST_STABILITY.value}
      promptType={VIRTUAL_DATA_QUESTIONS.TEST_STABILITY.promptType}
      title={VIRTUAL_DATA_QUESTIONS.TEST_STABILITY.title}
      checkEmpty={checkEmpty}
      emptyComponent={emptyComponent}
      trigger={trigger}
    />
  );
}

export default DrawerAITestStability;
