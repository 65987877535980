import React from 'react';
import PropTypes from 'prop-types';
import StatusConstants from '../../utils/Statuses';
import StatusBar from '../../components/chart/status-bar/StatusBar';
import { t } from '../../i18n/t';

class ExecutionStatusBar extends React.Component {

  statusBarItem() {
    const {
      totalPassedTests,
      totalFailedTests,
      totalErrorTests,
      totalIncompleteTests,
      totalSkippedTests
    } = this.props.execution;

    return [
      {
        label: StatusConstants.PASSED.label,
        value: totalPassedTests,
        color: 'passed',
      },
      {
        label: StatusConstants.FAILED.label,
        value: totalFailedTests,
        color: 'failed',
      },
      {
        label: StatusConstants.ERROR.label,
        value: totalErrorTests,
        color: 'error',
      },
      {
        label: StatusConstants.INCOMPLETE.label,
        value: totalIncompleteTests,
        color: 'incomplete',
      },
      {
        label: StatusConstants.SKIPPED.label,
        value: totalSkippedTests,
        color: 'skipped',
      },
    ];
  }

  totalItem = () => {
    const {
      totalTests
    } = this.props.execution;
    return {
      label: t('test_results'),
      value: totalTests,
    };
  }

  render() {
    return (
      <StatusBar
        title={t('test_results')}
        dataChart={this.statusBarItem()}
        dataInfo={[this.totalItem(), ...this.statusBarItem()]}
        {...this.props}
      />
    );
  }
}

ExecutionStatusBar.propTypes = {
  execution: PropTypes.object.isRequired,
};

export default ExecutionStatusBar;
