import React from 'react';
import { CardHeader, CardBody } from 'reactstrap';
import PropTypes from 'prop-types';


class OrganizationCard extends React.Component {

  constructor(props) {
    super(props);

    this.header = this.props.header;
    this.content = this.props.content;
  }

  render() {
    return (
      <>
        <CardHeader>
          {this.header}
        </CardHeader>
        <CardBody>
          {this.content}
        </CardBody>
      </>
    );
  }
}

OrganizationCard.propTypes = {
  header: PropTypes.any.isRequired,
  content: PropTypes.any.isRequired
};

export default OrganizationCard;
