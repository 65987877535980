import _ from 'lodash';
import React, { Component } from 'react';
import { Button } from 'reactstrap';
import { IconDownload } from '../../images/CustomIcon';
import Apis from '../../utils/Apis';
import DecoratorConstants from '../../utils/DecoratorConstants';
import DataTable from './DataTable';
import MTableColumnDataMapping from './models/MTableColumnDataMapping';

class KatalonRecorderBackupDataTable extends Component {
  render() {
    const columnMapping = [
      new MTableColumnDataMapping(
        'Time',
        'updatedAt',
        DecoratorConstants.timeDecorator
      ),
      new MTableColumnDataMapping(
        'Download',
        'id',
        (name, row) => {
          const id = _.get(row, name);
          return (
            <Button
              className="btn-icon-only"
              data-trackid="download-kr-backup"
              title="Download"
              color="link"
              href={Apis.downloadKatalonRecorderBackup(id)}
              target="_blank"
              rel="noopener noreferrer"
            >
              <IconDownload />
            </Button>
          );
        }
      ),
    ];
    const newProps = {
      columnMapping,
      ...this.props,
    };
    return (
      <DataTable
        entityType="KatalonRecorderBackup"
        defaultSort={['updatedAt,desc']}
        {...newProps}
      />
    );
  }
}

export default KatalonRecorderBackupDataTable;
