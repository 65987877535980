import React, { Component } from 'react';
import { Alert, Form, FormGroup, ButtonToolbar, Button, Label, Col, Row, CustomInput } from 'reactstrap';
import { t } from '../../../i18n/t';
import MContext from '../../../models/MContext';
import Input from '../../../components/Input';
import Services from '../../../utils/Services';
import Notification from '../../../utils/Notification';
import MAuth from '../../../models/MAuth';
import Routes from '../../../utils/Routes';
import DocumentLink from '../../../utils/DocumentLink';

class KobitonSetting extends Component {
  constructor(props) {
    super(props);
    this.projectId = MContext.projectId;
    this.project = MContext.project;
    this.teamId = MContext.teamId;

    this.changeText = this.changeText.bind(this);
    this.submitSettings = this.submitSettings.bind(this);
    this.state = {
      settingsForm: {
        id: null,
        serverUrl: '',
        username: '',
        apiKey: '',
        active: true
      },
    };
  }

  getConnection() {
    const params = {
      pagination: {
        page: 0,
        size: 1,
        sorts: 'order,desc',
      },
      conditions: [
        {
          key: 'Project.id',
          operator: '=',
          value: this.projectId,
        },
      ],
      type: 'KobitonConnection',
    };
    Services.search(params)
      .then(({ content }) => {
        const connection = content[0];
        if (connection) {
          const settingsForm = {
            id: connection.id,
            serverUrl: connection.serverUrl,
            username: connection.username,
            apiKey: connection.apiKey,
            active: connection.active
          };
          this.setState({ settingsForm });
        }
      });
  }

  componentDidMount() {
    this.getConnection();
  }

  changeText(event) {
    const { settingsForm } = this.state;
    const key = event.target.name;
    const value = event.target.value;
    settingsForm[key] = value;

    this.setState({ settingsForm });
  }

  onChangeCheckbox(event) {
    const { settingsForm } = this.state;
    const key = event.target.name;
    const value = event.target.checked;
    settingsForm[key] = value;
    this.setState({ settingsForm });
  }

  submitSettings(e) {
    e.preventDefault();
    const { settingsForm } = this.state;
    const setting = { projectId: this.projectId, ...settingsForm };
    Services.saveKobitonSettings(setting, () => {
      Notification.pushError('There was an error saving your setting. Please try again.');
    })
      .then(() => {
        Notification.pushSuccess('Your setting has been saved successfully.');
        this.getConnection();
      });
  }

  testConnection(e) {
    e.preventDefault();
    const { username, apiKey } = this.state.settingsForm;

    if (!username || !apiKey) {
      Notification.pushError('Please check your setting and try again.', 'Connection failed!');
      return;
    }

    const setting = { projectId: this.projectId, ...this.state.settingsForm };
    Services.testKobitonConnection(setting, () => {
      Notification.pushError('Please check your setting and try again.', 'Connection failed!');
    })
      .then(() => {
        Notification.pushSuccess('Please save your setting before leaving the page.', 'Connect Successfully');
      });
  }

  renderKobitonSettingForm() {
    const { serverUrl, username, apiKey, active } = this.state.settingsForm;
    return (
      <Row>
        <Col sm="12" md="8" lg="6" xl="5">
          <Form
            data-trackid="update-kobiton-settings"
            onSubmit={(e) => this.submitSettings(e)}
          >
            <FormGroup>
              <Label for="serverUrl">{t('kobiton-setting#url')}</Label>
              <Input
                onChange={(e) => this.changeText(e)}
                name="serverUrl"
                type="url"
                value={serverUrl}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="username">{t('username')}</Label>
              <Input
                onChange={(e) => this.changeText(e)}
                name="username"
                type="text"
                value={username}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="apiKey">{t('kobiton-setting#api-key')}</Label>
              <Input
                onChange={(e) => this.changeText(e)}
                name="apiKey"
                type="password"
                value={apiKey}
                required
              />
            </FormGroup>
            <FormGroup>
              <CustomInput
                onChange={(e) => this.onChangeCheckbox(e)}
                name="active"
                type="switch"
                id="active"
                checked={active}
                label={t('kobiton-setting#active-button')}
                value
              />
            </FormGroup>
            <ButtonToolbar>
              <Button data-trackid="test-kobiton-connection" type="button" color="secondary" onClick={(e) => this.testConnection(e)}>
                {t('kobiton-setting#test-connection')}
              </Button>
              <Button type="submit" color="primary">{t('save')}</Button>
            </ButtonToolbar>
          </Form>
        </Col>
      </Row>
    );
  }

  renderData() {
    return (
      <>
        <Alert color="warning">
          This feature is only compatible with Katalon Studio version 8.0 or later.
          <br />
          Learn how to schedule test runs with Kobiton devices <a target="_blank" rel="noreferrer noopener" href={DocumentLink.TEST_RUNS_KOBITON}>here</a>.
        </Alert>
        {this.renderKobitonSettingForm()}
      </>
    );
  }

  render() {
    const isTeamManager = MAuth.isTeamManager(this.teamId);
    if (!isTeamManager) {
      Routes.goToAccessDeniedPage();
    }

    return this.renderData();
  }
}

export default KobitonSetting;
