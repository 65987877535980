import React, { Component } from 'react';
import { Label } from 'reactstrap';
import { find } from 'lodash';
import { MAX_PAGE_SIZE, CUSTOM_FIELD_TRACKING, SearchEntity } from '../../../utils/Constants';
import Services from '../../../utils/Services';
import SelectCustomFieldComponent from '../../SelectCustomFieldComponent';

class CustomFieldFilterField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCustomFields: [],
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { value } = nextProps;
    const { selectedCustomFields } = prevState;

    if (!!selectedCustomFields && !value) {
      return {
        selectedCustomFields: [],
      };
    }
    return prevState;
  }

  componentDidMount() {
    this.getAllCustomFields();
  }

  getAllCustomFields() {
    const params = {
      pagination: {
        page: 0,
        size: MAX_PAGE_SIZE,
        sorts: ['id,desc'],
      },
      type: SearchEntity.CustomFieldDefinition,
    };
    return Services.searchRecursively(0, params, [])
      .then((allCustomFieldDefinitions) => this.initSelectedCustomFieldsFromSearchBar(allCustomFieldDefinitions));
  }

  initSelectedCustomFieldsFromSearchBar(allCustomFieldDefinitions) {
    let selectedCustomFieldsOnSearchBar = this.props.value || [];
    // In case user copy and paste in search bar directly
    if (typeof selectedCustomFieldsOnSearchBar === 'string') {
      selectedCustomFieldsOnSearchBar = selectedCustomFieldsOnSearchBar.split(',');
    }
    const initialSelectedCustomFields = [];
    selectedCustomFieldsOnSearchBar.forEach((selectedCustomField) => {
      // The format of selectedCustomField will be like this: framework.opt_id=94, auto123.opt_id=7
      const definitionKey = selectedCustomField.substr(0, selectedCustomField.indexOf('.')); // framework
      const optionId = Number(selectedCustomField.split('=')[1]); // 94, 7
      const customFieldDefinition = find(allCustomFieldDefinitions, { key: definitionKey });
      const customFieldOption = find(customFieldDefinition?.customFieldOptions, { id: optionId });
      if (customFieldDefinition && customFieldOption) {
        initialSelectedCustomFields.push({
          customFieldDefinition,
          customFieldOption
        });
      }
    });
    if (initialSelectedCustomFields.length > 0) {
      this.setSelectedCustomField(initialSelectedCustomFields);
    }
  }

  buildFilterValue(selectedCustomFields) {
    return selectedCustomFields.map((customField) =>
      `${customField.customFieldDefinition.key}.opt_id=${customField.customFieldOption.id}`);
  }

  buildFilter(value) {
    const { id, onBuildFilterSearch } = this.props;
    if (id && onBuildFilterSearch) {
      onBuildFilterSearch(id, value);
    }
  }

  setSelectedCustomField = (customFields) => {
    const filterValues = this.buildFilterValue(customFields);
    this.buildFilter(null);
    this.setState({
      selectedCustomFields: customFields,
    }, ...filterValues.map((value) => this.buildFilter(value)));
  };

  renderSectionTitle() {
    const { selectedCustomFields } = this.state;
    const { label } = this.props;
    return (
      <span className="font-weight-bold">
        <Label>{label}</Label>
        { selectedCustomFields.length > 0 ?
          <span className="ml-1 custom-field-filter__badge"> {selectedCustomFields.length} </span> :
          '' }
      </span>
    );
  }

  render() {
    const { selectedCustomFields } = this.state;
    return (
      <div>
        {this.renderSectionTitle()}
        <SelectCustomFieldComponent
          setSelectedCustomFields={this.setSelectedCustomField}
          selectedCustomFields={selectedCustomFields}
          trackingType={CUSTOM_FIELD_TRACKING.TEST_MANAGEMENT_TEST_CASE}
        />
      </div>
    );
  }
}

export default CustomFieldFilterField;
