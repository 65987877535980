import React, { useEffect, useState } from 'react';
import { Grid, Stack, Skeleton } from '@mui/material';
import { VirtualDataQuestionType } from '../../../utils/ConstantsType';
import MetricCard from './MetricCard';
import MetricComponent from './MetricComponent';
import AIQuestion from './AIQuestion';
import DrawerAIReleaseReadiness from './DrawerAIReleaseReadiness';
import DrawerAICommonFailures from './DrawerAICommonFailures';
import DrawerAITestStability from './DrawerAITestStability';
import { MetricSummary } from '../../../models/model/MetricSummary';
import DateHelper from '../../../utils/DateHelper';
import Time from '../../../utils/Moment';
import MContext from '../../../models/MContext';
import Services from '../../../utils/Services';
import { t } from '../../../i18n/t';
import DecoratorConstants from '../../../utils/DecoratorConstants';

interface VirtualDataAnalystProps {
  refreshMetricCard?: number;
}

function VirtualDataAnalyst(props: Readonly<VirtualDataAnalystProps>) {
  const { refreshMetricCard } = props;
  const [isOpenDrawer, setOpenDrawer] = useState(false);
  const [question, setQuestion] = useState<VirtualDataQuestionType | null>(null);
  const [metricSummary, setMetricSummary] = useState<MetricSummary | null>(null);

  const getMetricSummaryInfo = () => {
    const currentTime = DateHelper.getLast7Days(Time.now());
    Services.getMetricSummaryInfo(MContext.projectId, Time.searchApiFormatWithSettingOrClientTz(currentTime.startTime), Time.searchApiFormatWithSettingOrClientTz(currentTime.endTime))
      .then((response) => {
        setMetricSummary(response);
      });
  };

  useEffect(() => {
    if (refreshMetricCard) {
      setMetricSummary(null);
    }
    getMetricSummaryInfo();
  }, [refreshMetricCard]);

  const openDrawer = (question: VirtualDataQuestionType) => {
    setQuestion(question);
    setOpenDrawer(true);
  };

  const closeDrawer = () => {
    setOpenDrawer(false);
  };

  const createMetricComponent = (
    title: string,
    tooltip: string,
    value: number | undefined,
    trendValue: number | undefined,
    isGoodSignal: boolean
  ) => {
    const { icon, type } = DecoratorConstants.getIconAndType(trendValue, isGoodSignal);
    let label;
    if (value === undefined) {
      label = <Skeleton variant="rounded" width={50} height={30} />;
    } else {
      label = <div className="metric-normal-label">{value}</div>;
    }
    return (
      <MetricComponent
        icon={icon}
        title={title}
        tooltip={tooltip}
        label={label}
        subLabel={trendValue}
        type={type}
      />
    );
  };

  const data = [
    {
      content: createMetricComponent(
        t('new-failures'),
        t('new-failures-tooltip'),
        metricSummary?.totalNewFailures,
        metricSummary?.newFailuresTrend,
        false,
      ),
      time: t('last-seven-days'),
      id: 'new-failures',
    },
    {
      content: createMetricComponent(
        t('new-defects'),
        t('new-defects-tooltip'),
        metricSummary?.totalNewDefects,
        metricSummary?.newDefectsTrend,
        false,
      ),
      time: t('last-seven-days'),
      id: 'new-defects'
    },
    {
      content: createMetricComponent(
        t('always-failing'),
        t('always-failing-tooltip'),
        metricSummary?.totalAlwaysFailing,
        metricSummary?.alwaysFailingTrend,
        false,
      ),
      time: t('last-seven-days'),
      id: 'always-failing'
    }
  ];

  const renderMetricCards = () => (
    <Stack direction="row" spacing={2} justifyContent="flex-end">
      {data.map((element) => (
        <MetricCard content={element.content} time={element.time} key={element.id} />
      ))}
    </Stack>
  );

  const renderDrawer = () => {
    const newProps = {
      isOpen: isOpenDrawer,
      closeDrawer,
    };

    if (question) {
      switch (question.promptType) {
        case 'RELEASE_READINESS':
          return <DrawerAIReleaseReadiness {...newProps} />;
        case 'COMMON_FAILURES':
          return <DrawerAICommonFailures {...newProps} />;
        case 'TEST_STABILITY':
          return <DrawerAITestStability {...newProps} />;
        default:
          return null;
      }
    } else {
      return null;
    }
  };

  return (
    <>
      <Grid container spacing={2} className="mb-3">
        <Grid item xs={5} className="virtual-data-analyst">
          <AIQuestion openDrawer={openDrawer} />
        </Grid>
        <Grid item xs={7}>
          {renderMetricCards()}
        </Grid>
      </Grid>
      {renderDrawer()}
    </>
  );
}

export default VirtualDataAnalyst;
