import React from 'react';
import { brand, t } from '../../i18n/t';
import UploadExecutionStep1Img from '../../../images/upload-execution-step-1.png';
import PropTypes from 'prop-types';

class ManuallyUploadReport extends React.Component {

  render() {
    const { hideHeader, style } = this.props;
    return (
      <>
        {!hideHeader && (
          <div className={style}>
            {t('report#manualUpload')}
          </div>
        )}
        <p>In Test Report detail views, click <strong>Katalon TestOps &gt; Upload</strong>.</p>
        <p>Once upload successfully, refresh {brand} to view a detailed analysis of test execution reports.</p>
        <div className="img-quick-guide">
          <img
            className="img-thumbnail img-quick-guide"
            alt="Manual Upload Report"
            src={UploadExecutionStep1Img}
          />
        </div>
      </>
    );
  }
}

ManuallyUploadReport.propTypes = {
  style: PropTypes.string,
};

ManuallyUploadReport.defaultProps = {
  style: 'katalon-studio-title',
};

export default ManuallyUploadReport;
