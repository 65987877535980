import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { get, groupBy } from 'lodash';
import { IconButton, Tooltip, Typography } from '@mui/material';
import { t } from '../../../i18n/t';
import TooltipComponent from '../../../components/TooltipComponent';
import { getDefaultSearchConditions } from './utils';
import DataLoader from '../../../components/table/DataLoader';
import { NOT_AVAILABLE, PlatformCoverageFilterType, SearchEntity, TestRunStatus } from '../../../utils/Constants';
import { buildSearchFunction } from '../../../components/search/SearchUtils';
import TableSortCore from '../../../components/table/models/TableSortCore';
import colors from '../../../../scss/colors.scss';
import { IconStatus } from '../../../images/KitIcons';
import MTableColumnDataMapping from '../../../components/table/models/MTableColumnDataMapping';
import Helper from '../../../utils/Helper';
import Select from '../../../components/Select';
import ColorScales from '../../../components/ColorScales';
import ChartConstants from '../../../utils/ChartConstants';
import NormalCard from '../../../components/card/NormalCard';
import Arrays from '../../../utils/Arrays';
import MFlags from '../../../models/MFlags';
import { IconAllSkippedTestResult } from '../../../images/CustomIcon';
import DecoratorConstants from '../../../utils/DecoratorConstants';

const PlatformCoverageChartTooltip = ({ payload }) => {

  const renderItemName = (name) => {
    if (name === 'Total') {
      return <b>{name}</b>;
    }
    return name;
  };

  const renderItemLabel = (item) => (
    <IconStatus
      className="mr-2 tooltip-icon-label"
      key={item.color}
      fill={item.color}
    />
  );

  const renderItemValue = (item) => {
    if (item.name === 'Total') {
      return <b>{item.value}</b>;
    }
    return item.value;
  };

  const renderSubItems = (subItems) => (
    <>
      {
        subItems.map((i) => (
          <div className="d-flex justify-content-between">
            <div>{renderItemName(i.name)}</div>
            <div>{renderItemValue(i)}</div>
          </div>))
      }
    </>
  );

  const renderItem = (item) => (
    <div>
      <div className="d-flex justify-content-between mt-1">
        <div className="d-flex mr-4 align-items-center">
          {renderItemLabel(item)}
          {renderItemName(item.name)}
        </div>
        <div>
          {renderItemValue(item)}
        </div>
      </div>
      {item.subItems &&
        <div className="subitems">
          {renderSubItems(item.subItems)}
        </div>}
    </div>
  );

  const renderTooltipHeader = (header) => {
    if (header) {
      return (
        <>
          <div>
            { header.browserName }
          </div>
          <div>
            { header.osName }
          </div>
        </>
      );
    }
    return null;
  };

  return (
    <div className="m-2 overview-platform-coverage-tooltip">
      <Typography variant="h6">
        {payload && renderTooltipHeader(payload.header)}
      </Typography>
      {payload?.items && payload.items.map(renderItem)}
    </div>
  );
};

const optionsPlatformCoverageFilter = [
  {
    value: PlatformCoverageFilterType.BY_TEST_RUN,
    label: t('profile-coverage-by-test-run'),
  },
  {
    value: PlatformCoverageFilterType.BY_TEST_RESULT,
    label: t('profile-coverage-by-test-result'),
  },
];

const PlatformCoverageChart = ({
  startTime,
  endTime,
  profile,
  dataLoaderRef,
  tooltipMsg,
  extraCustomControls,
  platformCoverageFilterSize }) => {
  const [searchByEntityFilter, setSearchByEntityFilter] = useState(PlatformCoverageFilterType.BY_TEST_RUN);

  const handlePlatformCoverageFilterChange = (event, option) => setSearchByEntityFilter(option.value);

  const renderHeader = () => (
    <div className="overview-header-title">
      {t('overview-report#platform-coverage')}
      <TooltipComponent text={tooltipMsg} placement="bottom-end" arrow />
    </div>
  );

  const renderPlatformCoverageFilter = () => (
    <Select
      id="platform-coverage-filter"
      useAutocomplete
      options={optionsPlatformCoverageFilter}
      value={searchByEntityFilter}
      onChange={handlePlatformCoverageFilterChange}
      disableClearable
      blurOnSelect
      className="overview-filter ml-2"
      size={platformCoverageFilterSize}
    />
  );

  const renderCustomControl = () => (
    <div className="d-flex align-items-center">
      {extraCustomControls && extraCustomControls}
      <div className="ml-auto">
        {renderPlatformCoverageFilter()}
      </div>
    </div>
  );

  const renderLegend = (
    <div className="mt-3 default-legend">
      <ColorScales
        colors={ChartConstants.statusColor}
        beginningText={t('failed')}
        endText={t('passed')}
      />
    </div>
  );

  const renderChart = (data) => {

    const calcBubbleSize = (numberTests) => {
      const logValue = Helper.getLogBase(20, numberTests);
      const size = logValue + 1.0;
      return `${size.toFixed(2)}rem`;
    };

    const exactedData = data.map((item) => item.content);

    const handleNotAvailableValue = (value) => ((!value || value === '') ? NOT_AVAILABLE : value);

    // each column is an array with many browserName
    const columnDecorator = (name, row, browserName) => {
      // data structure after filter
      // [
      //   {
      //     "Platform_osName":"Linux",
      //     "total":1,
      //     "Platform_browserName":"Chrome ",
      //     "status":"PASSED"
      //   },
      //  {
      //    "Platform_osName":"Linux",
      //    "total":1,
      //    "Platform_browserName":"Chrome ",
      //    "status":"FAILED"
      //  },
      //  {
      //    "Platform_osName":"Linux",
      //    "total":1,
      //    "Platform_browserName":"Chrome ",
      //    "status":"INCOMPLETE"
      //  }
      // ]
      const cell = row.filter((item) => browserName === get(item, name));
      let total = 0;
      let passed = 0;
      let failed = 0;
      let error = 0;
      let incomplete = 0;
      let skipped = 0;
      const isPlatformCoverageByTestResult = searchByEntityFilter === PlatformCoverageFilterType.BY_TEST_RESULT;

      if (cell) {
        cell.forEach((item) => {
          total += item.total;
          if (item.status === TestRunStatus.PASSED) {
            passed += item.total;
          }
          if (item.status === TestRunStatus.FAILED) {
            failed += item.total;
          }
          if (isPlatformCoverageByTestResult) {
            if (item.status === TestRunStatus.ERROR) {
              error += item.total;
            }
            if (item.status === TestRunStatus.INCOMPLETE) {
              incomplete += item.total;
            }
            if (item.status === TestRunStatus.SKIPPED) {
              skipped += item.total;
            }
          }
        });
      }

      const passedRatio = (passed / (total - skipped)).toFixed(2);
      const colorIdx = Math.round(passedRatio * (ChartConstants.numberColor - 1));
      const size = calcBubbleSize(total);
      const isAllSkippedTestResult = isPlatformCoverageByTestResult && total > 0 && skipped === total;

      const tooltipPayload = {
        header: {
          browserName: `${handleNotAvailableValue(browserName)}`,
          osName: `${handleNotAvailableValue(cell[0]?.Platform_osName)}`
        },
        items: [
          {
            name: 'Failed',
            color: colors.newFailed,
            value: !isPlatformCoverageByTestResult ? failed : (failed + error + incomplete),
            subItems: !isPlatformCoverageByTestResult ? null : [
              {
                name: 'Failed',
                value: failed
              },
              {
                name: 'Error',
                value: error
              },
              {
                name: 'Incomplete',
                value: incomplete
              }
            ]
          },
          {
            name: 'Passed',
            color: colors.newPassed,
            value: passed,
          },
          ...Arrays.insertIf(isPlatformCoverageByTestResult, {
            name: 'Skipped',
            color: colors.white,
            value: skipped,
          }),
          {
            name: 'Total',
            color: colors.white,
            value: total,
          },
        ]
      };

      if (total > 0) {
        return (
          !isAllSkippedTestResult ?
            <Tooltip title={<PlatformCoverageChartTooltip payload={tooltipPayload} />}>
              <IconButton size="large">
                <IconStatus
                  fill={ChartConstants.statusColor[colorIdx]}
                  style={{
                    width: size,
                    height: size,
                  }}
                />
              </IconButton>
            </Tooltip> :
            <Tooltip title={<PlatformCoverageChartTooltip payload={tooltipPayload} />}>
              <IconButton size="large">
                <IconAllSkippedTestResult />
              </IconButton>
            </Tooltip>
        );
      }
      return null;
    };

    // this row in an array contains data group by os name
    // cause of group by, so value in
    const rowHeaderDecorator = (name, row) => {
      const value = handleNotAvailableValue(get(row[0], name));
      return (
        <>
          {value}
          {value === NOT_AVAILABLE &&
            <TooltipComponent text={t('overview-report#platform-coverage#tooltip-undefined-os')} />}
        </>
      );
    };

    /**
     * Sort by alphabet and empty string last algorithm
     * @param {*} a
     * @param {*} b
     * @returns
     */
    const sortAlphabetEmptyLast = (a, b) => !a - !b || a.localeCompare(b);

    // group data by OS name and sort empty string to last
    const groupDataByOsName = (data) => {
      const groupByOsName = groupBy(data, 'Platform_osName');
      return Object.values(groupByOsName)
        .sort((a, b) => sortAlphabetEmptyLast(a[0].Platform_osName, b[0].Platform_osName));
    };

    // group data by browserName and get array of keys to render column, sort empty string to last
    const getBrowserName = (data) => {
      const groupByBrowserName = groupBy(data, 'Platform_browserName');
      return Object.keys(groupByBrowserName).sort((a, b) => sortAlphabetEmptyLast(a, b));
    };

    const columnMapping = [
      new MTableColumnDataMapping(
        '',
        'Platform_osName',
        rowHeaderDecorator,
        undefined,
        'center-column font-weight-bold MuiTableRow-head',
      ),
    ];

    const browserNames = getBrowserName(exactedData);
    browserNames.forEach(((browserName) => {
      const value = handleNotAvailableValue(browserName);
      columnMapping.push(
        new MTableColumnDataMapping(
          <>
            {value}
            {value === NOT_AVAILABLE &&
              <TooltipComponent text={t('overview-report#platform-coverage#tooltip-undefined-browser')} />}
          </>,
          'Platform_browserName',
          (name, row) => columnDecorator(name, row, browserName),
          undefined,
          'center-column text-center',
        )
      );
    }));

    /**
     * Process undefined field before convert and group data.
     * @param {*} data
     * @returns
     */
    const processEmptyData = (data) => {
      data.forEach((item) => {
        if (!item.Platform_browserName) {
          item.Platform_browserName = '';
        }
        if (!item.Platform_osName) {
          item.Platform_osName = '';
        }
      });
      return data;
    };

    const processDisplayPlatformName = (data) => {
      data.forEach((item) => {
        item.Platform_osName = DecoratorConstants.displayNameByOS(item.Platform_osName);
      });
      return data;
    };

    const processedEmptyData = processEmptyData(exactedData);
    const processedData = processDisplayPlatformName(processedEmptyData);
    const convertedData = groupDataByOsName(processedData);
    return (
      <>
        <TableSortCore data={convertedData} columnMapping={columnMapping} />
        {renderLegend}
      </>
    );
  };

  const checkSearchEntity = () => {
    const isPlatformCoverageByTestRun = searchByEntityFilter === PlatformCoverageFilterType.BY_TEST_RUN;
    const profileKey = isPlatformCoverageByTestRun ? 'ExecutionTestResult.profile' : 'profile';
    const countFunc = isPlatformCoverageByTestRun ? 'count_distinct' : 'count';
    const searchEntity = isPlatformCoverageByTestRun ? SearchEntity.Execution : SearchEntity.ExecutionTestResult;

    return {
      profileKey,
      countFunc,
      searchEntity
    };
  };

  const renderBody = () => {
    const { profileKey, countFunc, searchEntity } = checkSearchEntity();
    const defaultSearchConditions = getDefaultSearchConditions(profileKey, profile, startTime, endTime);

    return (
      <DataLoader
        title={renderHeader()}
        refreshDataOnPropsChange
        ref={dataLoaderRef}
        tableId={searchEntity}
        entityType={searchEntity}
        defaultSearchConditions={defaultSearchConditions}
        defaultSearchFunctions={[
          buildSearchFunction('total', countFunc, ['id'])
        ]}
        groupBys={[
          'Platform.osName',
          'Platform.browserName',
          'status'
        ]}
        defaultSort={[
          'Platform.osName, asc',
          'Platform.browserName, asc'
        ]}
        render={renderChart}
        useCache={!MFlags.testPerformanceForReportAndAnalyticsEnabled}
        cardClassName="shadow-none"
        useRefreshButton={false}
        useCollapseButton={false}
        headerComponent={NormalCard}
        bodyComponent={NormalCard}
        customControl={renderCustomControl()}
        showEmptyMessage
        pageSize={300}
      />
    );
  };

  return (
    <>
      {renderBody()}
    </>
  );
};

PlatformCoverageChart.propTypes = {
  startTime: PropTypes.object,
  endTime: PropTypes.object,
  profile: PropTypes.string,
  dataLoaderRef: PropTypes.object,
  /**
   * set difference message for tooltip
   */
  tooltipMsg: PropTypes.string,
  /**
   * render more filters for platform coverage chart
   */
  extraCustomControls: PropTypes.element,
  /**
   * set size for filter "By test runs/ By test results"
   */
  platformCoverageFilterSize: PropTypes.string,
};

PlatformCoverageChart.defaultProps = {
  startTime: null,
  endTime: null,
  profile: null,
  dataLoaderRef: null,
  tooltipMsg: t('overview-report#platform-coverage#tooltip'),
  extraCustomControls: null,
  platformCoverageFilterSize: 'small'
};

export default PlatformCoverageChart;
