import React, { Component } from 'react';
import { Col } from 'reactstrap';
import PropTypes from 'prop-types';
import Services from '../../../utils/Services';
import ImageCanvas from './ImageCanvas';
import DiffCanvasTemplate from './DiffCanvasTemplate';
import { calHeight, filterZones, convertContentJson, convertLayoutJSON } from './Utils';
import { t } from '../../../i18n/t';
import { COMPARISON_TYPE, IMAGE_TYPE, LOADING_STATE_KEY } from './Constants';
import Skeleton from '../../../components/Skeleton';

class KEyesAI extends Component {
  constructor(props) {
    super(props);
    this.renderBody = this.renderBody.bind(this);
    this.state = {
      zoneList: [],
      height: 500,
      isLoading: {
        image: true,
        diff: true
      }
    };
    this.setHeight = this.setHeight.bind(this);
    this.getZones = this.getZones.bind(this);
    this.setLoading = this.setLoading.bind(this);
    this.isReady = this.isReady.bind(this);
  }

  componentDidMount() {
    const { source, canvasRef, jsonFile } = this.props;
    if (source && canvasRef.current) {
      calHeight(
        source,
        canvasRef.current && canvasRef.current.offsetWidth,
        this.setHeight
      );
    }
    if (jsonFile) {
      this.getZones(jsonFile);
    }
  }

  isReady() {
    const { diff, image } = this.state.isLoading;
    const { jsonFile, canvasRef, source } = this.props;
    const isLoading = (jsonFile && diff) || (source && image) || !canvasRef.current;
    if (isLoading === undefined) {
      return true;
    }
    return !isLoading;
  }

  setLoading(key, value) {
    this.setState((prevState) => {
      const isLoading = { ...prevState.isLoading };
      isLoading[key] = value;
      return { isLoading };
    });
  }

  setHeight(h) {
    this.setState({ height: h });
  }

  renderSkeleton() {
    return <Skeleton
      height={this.state.height}
    />;
  }

  getZones(params) {
    Services.getZones(params)
      .then((res) => {
        if (this.props.mode === COMPARISON_TYPE.LAYOUT) {
          const zoneList = convertLayoutJSON(res);
          this.setState({ zoneList });
        } else {
          const zoneList = convertContentJson(res);
          this.setState({ zoneList });
        }
      });
  }

  renderImageCanvas() {
    const { source, canvasRef, type } = this.props;
    return source ? (
      <div>
        <ImageCanvas
          className={`${this.isReady() ? '' : 'd-none'}`}
          source={source}
          width={canvasRef.current && canvasRef.current.offsetWidth}
          loadingState={LOADING_STATE_KEY.IMAGE}
          setLoading={this.setLoading}
        />
        {!this.isReady() && this.renderSkeleton()}
      </div>
    ) : (
      <div className="empty-message">{type === IMAGE_TYPE.BASELINE ? t('no-baseline-image') : t('no-checkpoint-image')}</div>
    );
  }

  renderDiffCanvas() {
    const { source, canvasRef, type, filter, mode, ignoringZones } = this.props;
    const { zoneList } = this.state;
    const ignoringZoneList = filter.showIgnoringZones ? ignoringZones : [];
    return (zoneList.length > 0 || ignoringZoneList.length > 0) && canvasRef.current && (
      <DiffCanvasTemplate
        className={`${this.isReady() ? '' : 'd-none'}`}
        source={source}
        mode={mode}
        type={type}
        zoneList={filterZones(filter, zoneList, mode)}
        ignoringZoneList={ignoringZoneList}
        zoneHover={this.props.zoneHover}
        setHover={this.props.setZoneHover}
        width={canvasRef.current && canvasRef.current.offsetWidth}
        loadingState={LOADING_STATE_KEY.DIFF}
        setLoading={this.setLoading}
      />
    );
  }

  renderBody() {
    const { canvasRef, type, renderDescription } = this.props;
    return (
      <Col className="image-wrapper">
        {renderDescription(type)}
        <div
          ref={canvasRef}
          className="canvas-wrapper"
          style={{ height: this.state.height }}
        >
          {this.renderImageCanvas()}
          {this.renderDiffCanvas()}
        </div>
      </Col>
    );
  }

  render() {
    return <>{this.renderBody()}</>;
  }
}

KEyesAI.propsTypes = {
  zoneHover: PropTypes.string.isRequired,
  keyesExecutionId: PropTypes.string.isRequired,
  source: PropTypes.string,
  type: PropTypes.string.isRequired,
  jsonFile: PropTypes.array.isRequired
};

export default KEyesAI;
