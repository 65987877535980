import { sendAnalyticEvent } from '../../../utils/SegmentAnalytics';
import GroupEvent from '../../../utils/track/GroupEvent';
import MContext from '../../../models/MContext';
import { NEW_SCHEDULE_DIALOG_VARIANT } from '../../../utils/Constants';

export const openScheduleTestRunDialogEvent = (id) => ([
  'open-schedule-dialog',
  { groupId: GroupEvent.SCHEDULE_TEST_RUN },
  { id, orgId: MContext.team.organizationId, teamId: MContext.teamId, projectId: MContext.projectId, variant: NEW_SCHEDULE_DIALOG_VARIANT }
]);

export const closeScheduleTestRunDialogEvent = (id) => ([
  'close-schedule-dialog',
  { groupId: GroupEvent.SCHEDULE_TEST_RUN },
  { id, orgId: MContext.team.organizationId, teamId: MContext.teamId, projectId: MContext.projectId, variant: NEW_SCHEDULE_DIALOG_VARIANT }
]);

export const clickScheduleTestRunButtonEvent = (id) => ([
  'create-test-schedule',
  { groupId: GroupEvent.SCHEDULE_TEST_RUN },
  { id, orgId: MContext.team.organizationId, teamId: MContext.teamId, projectId: MContext.projectId, variant: NEW_SCHEDULE_DIALOG_VARIANT }
]);

export const clickRunTestRunButtonEvent = (id) => ([
  'create-immediate-test-run',
  { groupId: GroupEvent.SCHEDULE_TEST_RUN },
  { id, orgId: MContext.team.organizationId, teamId: MContext.teamId, projectId: MContext.projectId, variant: NEW_SCHEDULE_DIALOG_VARIANT }
]);

export const clickSaveConfigurationButtonEvent = (id) => ([
  'create-test-configuration',
  { groupId: GroupEvent.SCHEDULE_TEST_RUN },
  { id, orgId: MContext.team.organizationId, teamId: MContext.teamId, projectId: MContext.projectId, variant: NEW_SCHEDULE_DIALOG_VARIANT }
]);

export const scheduleTestRunSuccessEvent = (id) => ([
  'schedule-test-run-success',
  { groupId: GroupEvent.SCHEDULE_TEST_RUN },
  { id, orgId: MContext.team.organizationId, teamId: MContext.teamId, projectId: MContext.projectId, variant: NEW_SCHEDULE_DIALOG_VARIANT }
]);

export const scheduleTestRunFailedEvent = (id) => ([
  'schedule-test-run-fail',
  { groupId: GroupEvent.SCHEDULE_TEST_RUN },
  { id, orgId: MContext.team.organizationId, teamId: MContext.teamId, projectId: MContext.projectId, variant: NEW_SCHEDULE_DIALOG_VARIANT }
]);

export const refreshTscEvent = (id) => ([
  'schedule-test-refresh-tsc',
  { groupId: GroupEvent.SCHEDULE_TEST_RUN },
  { id, orgId: MContext.team.organizationId, teamId: MContext.teamId, projectId: MContext.projectId, variant: NEW_SCHEDULE_DIALOG_VARIANT }
]);

export const refreshTsEvent = (id) => ([
  'schedule-test-refresh-ts',
  { groupId: GroupEvent.SCHEDULE_TEST_RUN },
  { id, orgId: MContext.team.organizationId, teamId: MContext.teamId, projectId: MContext.projectId, variant: NEW_SCHEDULE_DIALOG_VARIANT }
]);

export const goToAdvanceTab = (id) => ([
  'go-to-advance-tab',
  { groupId: GroupEvent.SCHEDULE_TEST_RUN },
  { id, orgId: MContext.team.organizationId, teamId: MContext.teamId, projectId: MContext.projectId, variant: NEW_SCHEDULE_DIALOG_VARIANT }
]);

export const openScheduleDialogRelease = (id) => ([
  'open-schedule-dialog-release',
  { groupId: GroupEvent.SCHEDULE_TEST_RUN },
  { id, orgId: MContext.team.organizationId, teamId: MContext.teamId, projectId: MContext.projectId }
]);

export const openScheduleDialogLocalEnv = (id) => ([
  'open-schedule-dialog-local-env',
  { groupId: GroupEvent.SCHEDULE_TEST_RUN },
  { id, orgId: MContext.team.organizationId, teamId: MContext.teamId, projectId: MContext.projectId }
]);

export const openScheduleDialogTcEnv = (id) => ([
  'open-schedule-dialog-tc-env',
  { groupId: GroupEvent.SCHEDULE_TEST_RUN },
  { id, orgId: MContext.team.organizationId, teamId: MContext.teamId, projectId: MContext.projectId }
]);

export const track = (event) => sendAnalyticEvent(event);


