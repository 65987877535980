import React from 'react';
import { DialogContent, DialogContentText, DialogActions } from '@mui/material';
import { Button, ButtonToolbar } from 'reactstrap';
import CloseableDialogComponent from './CloseableDialogComponent';
import { t } from '../../i18n/t';

export default function ChangingJiraWorkspaceWarningDialog({ isOpen, handleClose, handleChangeWorkspace }) {

  return (
    <CloseableDialogComponent
      maxWidth="sm"
      isOpen={isOpen}
      handleClose={handleClose}
      title={t('jira-settings#change-workspace-dialog#title')}
    >
      <DialogContent>
        <DialogContentText>
          <div className="title m-0 text-left">
            {t('jira-settings#change-workspace-dialog#description')}
            <br />
            <ul className="mt-1">
              <li>{t('jira-settings#change-workspace-dialog#description#first-line')}</li>
              <li>{t('jira-settings#change-workspace-dialog#description#second-line')}</li>
            </ul>
            {t('jira-settings#change-workspace-dialog#description#third-line')}
          </div>
        </DialogContentText>
      </DialogContent>
      <DialogActions className="popup-footer justify-content-end p-0">
        <ButtonToolbar>
          <Button
            id="cancel-confirmation-changing-workspace-dialog"
            title={t('cancel')}
            color="secondary"
            onClick={handleClose}
          >
            {t('cancel')}
          </Button>
          <Button
            id="button-confirmation-changing-workspace"
            title={t('jira-settings#change-workspace-dialog#confirm-btn')}
            color="warning"
            onClick={handleChangeWorkspace}
          >
            {t('jira-settings#change-workspace-dialog#confirm-btn')}
          </Button>
        </ButtonToolbar>
      </DialogActions>
    </CloseableDialogComponent>
  );
}
