import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { Label, FormGroup } from 'reactstrap';
import { TAG_ENTITY_TYPE } from '../../../utils/Constants';
import MContext from '../../../models/MContext';
import { t } from '../../../i18n/t';
import TagInput from './../../TagInput';
import Services from '../../../utils/Services';
import MFlags from '../../../models/MFlags';
import TagList from '../../TagList';
import TagListTsx from '../../tsx-components/TagListTsx';

const RunConfigurationTag = ({ tags, hideLabel, onChange }) => {
  const runConfigurationId = MContext.runConfigurationId;
  const projectId = MContext.projectId;

  const getSelectedTags = () => {
    Services.getTagEntities(projectId, runConfigurationId, TAG_ENTITY_TYPE.TEST_SCHEDULE)
      .then((content) => {
        onChange(content);
      });
  };

  useEffect(() => {
    if (runConfigurationId) {
      getSelectedTags();
    }
  }, []);

  const handleSelectChangeTags = (selectedOptions) => {
    const newTags = selectedOptions.filter((tag) => tag?.isNewTag === true);

    if (!isEmpty(newTags)) {
      newTags.forEach((addedTag) => {
        Services.createTag(addedTag, true).then((content) => {
          onChange([...tags, content]);
        });
      });
    } else {
      onChange(selectedOptions);
    }
    return Promise.resolve();
  };

  const handleDelete = (deletedTag) => {
    const filteredTags = tags.filter((item) => item.name !== deletedTag.name);
    onChange(filteredTags);
  };

  return (
    <div className="tag">
      <FormGroup>
        {!hideLabel && <Label for="tag">{t('tag#title')}</Label>}
        <TagInput
          selectedTags={tags}
          handleOnSelectChange={handleSelectChangeTags}
        />
      </FormGroup>
      <TagListTsx selectedTags={tags} handleOnDelete={handleDelete} />
    </div>
  );
};

export default RunConfigurationTag;

RunConfigurationTag.propTypes = {
  tags: PropTypes.array.isRequired,
  hideLabel: PropTypes.bool,
  onChange: PropTypes.func,
};

RunConfigurationTag.defaultProps = {
  tags: [],
  hideLabel: false,
};
