import React from 'react';
import TagChipTsx from './TagChipTsx';
import { Tag } from '../../../models/tag';

interface TagListProps {
    selectedTags: Tag[],
    handleOnDelete: (tag: Tag) => void,
}

const TagListTsx = (props: TagListProps) => {
  const { selectedTags, handleOnDelete } = props;

  const handleDelete = (option: Tag) => {
    handleOnDelete(option);
  };

  return (
    <>
      {selectedTags.map((option: Tag) => (
        <TagChipTsx
          key={option.id}
          name={option.name}
          handleRemove={() => handleDelete(option)}
        />
      ))}
    </>
  );
};

export default TagListTsx;
