import React from 'react';
import classnames from 'classnames';
import ListBrandCards from './ListBrandCards';


export default React.memo((props) => {
  const { className, title, brand, expiration, last4, small, ...restProps } = props;
  const cardBrand = Object.values(ListBrandCards).find((cardBrand) => cardBrand.id === brand);
  if (!cardBrand) return null;

  return (
    <div
      className={classnames(
        'saved-payment-method d-flex align-items-center',
        className || '',
        { small }
      )}
      {...restProps}
    >
      <img className="saved-payment-method__brand mr-3" src={cardBrand.imageUrl} alt={brand} />
      <div>
        <div className="saved-payment-method__number">
          <span className="text-capitalize">{brand}</span> •••• {last4}
        </div>
        <div className="saved-payment-method__expires">Expires {expiration}</div>
      </div>
    </div>
  );
});
