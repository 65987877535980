import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { Alert, Label } from 'reactstrap';
import { isEmpty } from 'lodash';
import Routes from '../../../utils/Routes';
import MContext from '../../../models/MContext';
import Select from '../../Select';
import { t } from '../../../i18n/t';
import TooltipComponent from '../../TooltipComponent';
import CustomSelect from '../../CustomSelect';
import { compareObj } from '../services/testcloud';
import Link from '../../Link';
import MFlags from '../../../models/MFlags';

const CircleCIEnvironment = (props) => {

  const teamId = MContext.teamId;
  const projectId = MContext.projectId;
  const { value, circleCIAgents, browserTypeOptions, hideBrowserTypeSelect = false, onChange } = props;

  const [selectedCircleCIAgents, setSelectedCircleCIAgents] = useState([]);
  const [selectedCircleCIOptions, setSelectedCircleCIOptions] = useState([]);
  const [selectedBrowserType, setSelectedBrowserType] = useState();

  useEffect(() => {
    const filterCircleCIAgents = value.filter(({ isNotTestEnv }) => isNotTestEnv !== true);

    setSelectedCircleCIOptions(circleCIAgents.map((circleCi) => ({
      ...circleCi,
      label: `CirCleCI | ${circleCi.value?.name}`,
      disabled: compareObj(filterCircleCIAgents, circleCi.value, ['id', 'name'])
    })));

    if (filterCircleCIAgents && filterCircleCIAgents.length > 0) {
      setSelectedCircleCIAgents(filterCircleCIAgents.map((el) => ({
        value: el,
        label: `CirCleCI | ${el?.name}`,
      })));
      setSelectedBrowserType(filterCircleCIAgents[0].browserType);
    } else {
      if (browserTypeOptions && browserTypeOptions.length > 0) {
        setSelectedBrowserType(value[0]?.browserType || browserTypeOptions[0].value);
      }
    }
  }, [value, circleCIAgents]);

  const handleChangeBrowserType = (browserType) => {
    setSelectedBrowserType(browserType.value);
    if (!isEmpty(selectedCircleCIAgents)) {
      onChange(selectedCircleCIAgents.map((circleCI) => ({
        ...circleCI.value,
        browserType: browserType.value,
      })));
    } else {
      onChange([{ browserType: browserType.value, isNotTestEnv: true }]);
    }

  };

  const handleChangeCircleCIAgent = (values) => {
    setSelectedCircleCIAgents(values);
    if (isEmpty(values)) {
      onChange([{ browserType: selectedBrowserType, isNotTestEnv: true }]);
    } else {
      onChange(values.map((el) => el.value).map((value) => ({
        ...value,
        browserType: selectedBrowserType,
      })));
    }
  };

  const routes = new Routes({
    teamId: MContext.teamId,
    projectId: MContext.projectId,
  });

  const renderCreateCircleCILink = () => {
    const { moveAgentToOrgLevelPhase2Enabled } = MFlags;
    if (!moveAgentToOrgLevelPhase2Enabled) {
      const routes = new Routes({
        teamId,
        projectId,
      });
      return (
        <Link
          href={routes.circle_ci_agent_create_link}
        >
          {' here. '}
        </Link>
      );
    }
    const routes = new Routes({
      organizationId: MContext.organizationId || MContext.team.organizationId,
    });

    return (
      <a
        href={routes.organization_circle_ci_agent_create_link}
      >
        {' here. '}
      </a>
    );
  };

  return (
    <Box sx={{ marginBottom: '1.5rem' }}>
      <Label for="circleCIAgentList">CircleCI Environments</Label>
      <CustomSelect
        value={selectedCircleCIAgents}
        options={selectedCircleCIOptions}
        onChange={handleChangeCircleCIAgent}
        menuPosition="fixed"
      />
      <Box sx={{ marginTop: '0.8rem' }}>
        <Alert color="primary">
          Create a CircleCI test environment
          {renderCreateCircleCILink()}
        </Alert>
      </Box>
      {!hideBrowserTypeSelect &&
        <>
          <div className="d-flex">
            <Label for="browserType" className="normal-label">{t('run_with')}</Label>
            <TooltipComponent text={t('run-configuration#not-supported-mobile')} />
          </div>
          <Select
            required
            clearable={false}
            id="browserType"
            value={selectedBrowserType}
            onChange={handleChangeBrowserType}
            options={browserTypeOptions}
            maxMenuHeight={180}
            menuPosition="fixed"
            isCustom
          />
        </>}
    </Box>
  );
};

export default CircleCIEnvironment;
