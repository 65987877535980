import { FormGroup, FormText, Label } from 'reactstrap';
import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { isEmpty } from 'lodash';
import { t } from '../../../i18n/t';
import Select from '../../Select';
import TscExecutionEnvironmentSelect from './TscExecutionEnvironmentSelect';
import { ALL_BROWSERS_TESTCLOUD_AGENT, CloudType, TestProjectType } from '../../../utils/Constants';
import TooltipComponent from '../../TooltipComponent';

export default function TestSuiteCollectionRunConfiguration(props) {
  const {
    form,
    testProject,
    runConfiguration,
    isLoading,
    validation,
    testSuiteCollections,
    groupedTestCloudAgentsTSC,
    nativeApps,
    localAgents,
    circleCIAgents,
    k8sAgents,
    onRefreshTsc,
  } = props;
  const { control, setValue, getValues } = form;

  const [testSuites, setTestSuites] = useState([]);

  const getTestSuitesByTscId = (testSuiteCollectionId) => {
    if (testSuiteCollections.length > 0 && testSuiteCollectionId) {
      const testSuiteCollection = testSuiteCollections.find((el) => el.value === testSuiteCollectionId);
      return testSuiteCollection ? testSuiteCollection.testSuiteCollectionConfigurations : [];
    }
    return [];
  };

  const getDefaultTscAgents = (testSuites, nativeApp, tscAgents) => {
    if (tscAgents && tscAgents.length > 0 && tscAgents[0].cloudType !== CloudType.TEST_CLOUD_AGENT) {
      return tscAgents;
    }
    return testSuites.map(({ id }) => ({
      cloudType: CloudType.TEST_CLOUD_AGENT,
      enabledTestCloudTunnel: false,
      testSuiteCollectionConfigurationId: id,
      testCloudAgent: {
        ...ALL_BROWSERS_TESTCLOUD_AGENT,
        appId: nativeApp?.value,
        appName: nativeApp?.label,
      }
    }));
  };

  const handleTscChange = (field) => (item) => {
    field.onChange(item.value);
    const testSuites = getTestSuitesByTscId(item.value);
    setTestSuites(testSuites);
    const nativeApp = undefined; // reset native app
    setValue('runConfiguration.testSuiteCollectionAgents', getDefaultTscAgents(testSuites, nativeApp, getValues('runConfiguration.testSuiteCollectionAgents')));
  };

  useEffect(() => {
    const tscId = getValues('runConfiguration.testSuiteCollectionId');
    if (!isEmpty(testSuiteCollections) && !tscId) {
      setValue('runConfiguration.testSuiteCollectionId', testSuiteCollections[0].value);

      const testSuites = getTestSuitesByTscId(testSuiteCollections[0].value);
      setTestSuites(testSuites);

      const nativeApp = undefined; // reset native app
      setValue('runConfiguration.testSuiteCollectionAgents', getDefaultTscAgents(testSuites, nativeApp, getValues('runConfiguration.testSuiteCollectionAgents')));
    } else {
      const testSuitesInTsc = getTestSuitesByTscId(tscId);
      if (!isEmpty(testSuitesInTsc)) {
        setTestSuites(testSuitesInTsc);
        const testSuiteCollectionAgent = getValues('runConfiguration.testSuiteCollectionAgents');
        if (isEmpty(testSuiteCollectionAgent)) {
          const nativeApp = undefined; // reset native app
          setValue('runConfiguration.testSuiteCollectionAgents', getDefaultTscAgents(testSuites, nativeApp, getValues('runConfiguration.testSuiteCollectionAgents')));
        }
      } else if (!isEmpty(testSuiteCollections)) {
        setValue('runConfiguration.testSuiteCollectionId', testProject?.type === TestProjectType.GIT || testProject?.type === TestProjectType.KS ? testSuiteCollections[0].value : tscId);
        setTestSuites(testSuiteCollections[0].testSuiteCollectionConfigurations || []);
        const nativeApp = undefined; // reset native app
        setValue('runConfiguration.testSuiteCollectionAgents', getDefaultTscAgents(testSuites, nativeApp, getValues('runConfiguration.testSuiteCollectionAgents')));
      } else {
        setTestSuites([]);
      }
    }
  }, [runConfiguration, testSuiteCollections, nativeApps]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <FormGroup className="d-flex">
        <Label className="col-2 test-run-label" for="objectName">{t('Object Name')}</Label>
        <div className="p-0 col-10">
          <Controller
            name="runConfiguration.testSuiteCollectionId"
            control={control}
            rules={{ required: validation }}
            render={({ field, formState: { errors } }) =>
              <div className="d-flex flex-column flex-grow-1">
                <Select
                  {...field}
                  id="testSuiteCollectionId"
                  name="testSuiteCollectionId"
                  required
                  clearable={false}
                  onChange={handleTscChange(field)}
                  isLoading={isLoading}
                  options={testSuiteCollections}
                  isCustom
                  value={field.value || null}
                />
                {!isEmpty(testSuiteCollections) && isEmpty(testSuites) && testProject?.type === TestProjectType.GIT &&
                <small className="text-danger fs-6">
                  {t('schedule-v1#warning#select-tsc')}
                </small>}
                {errors.runConfiguration?.testSuiteCollectionId && !getValues('runConfiguration.testSuiteCollectionId') && <small className="text-danger fs-6">This is required</small>}
                {testProject && testProject.type === TestProjectType.GIT && (
                  <FormText color="muted">
                    Can’t find your preferred Test Suite Collection from the list?&nbsp;
                    <a
                      href="#"
                      onClick={() => onRefreshTsc()}
                    >Click here to refresh.
                    </a>
                  </FormText>
                )}
              </div>}
          />
        </div>

      </FormGroup>
      <FormGroup className="d-flex">
        <div className="col-2 flex">
          <Label className="test-run-label" for="environment">{t('Environment')}</Label>
          <TooltipComponent text={t('test-cloud-agent#default-agent-for-tsc-message')} />
        </div>
        <div className="pt-3 pr-0 pl-0 col-10">
          <Controller
            name="runConfiguration.testSuiteCollectionAgents"
            control={control}
            rules={{ required: validation }}
            render={({ field, formState: { errors } }) =>
              <div className="d-flex flex-column flex-grow-1">
                <TscExecutionEnvironmentSelect
                  {...field}
                  testSuites={testSuites}
                  groupedTestCloudAgentsTSC={groupedTestCloudAgentsTSC}
                  nativeApps={nativeApps}
                  localAgents={localAgents}
                  circleCIAgents={circleCIAgents}
                  k8sAgents={k8sAgents}
                  {...props}
                />
                {errors.runConfiguration?.testSuiteCollectionAgents && <small className="text-danger fs-6">This is required</small>}
              </div>}
          />
        </div>
      </FormGroup>
    </>
  );
}
