import React, { ReactNode } from 'react';
import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { IconEmptySet } from '../images/CustomIcon';
import { t } from '../i18n/t';

interface TableWithDashedBorderProps<T> {
  data: T[] | undefined;
  renderData: (element: T) => ReactNode;
  handleSelectRow?: (element: T) => void;
  chosenKey: string;
}


const styles = makeStyles({
  testRunHover: {
    '&:hover': {
      backgroundColor: '#F5F5F5',
      borderRadius: '4px',
      cursor: 'pointer',
    },
  },
});

function TableWithDashedBorder<T>(props: TableWithDashedBorderProps<T>) {
  const { data, chosenKey, renderData, handleSelectRow } = props;
  const classes = styles();

  if (!data || data.length < 1) {
    return (
      <div className="no-data-card">
        <IconEmptySet className="mb-1" />
        <div className="d-flex justify-content-center">
          {t('table#empty-message')}
        </div>
      </div>
    );
  }

  return (
    <div>
      {data.map((element: T, index: number) => (
        <Grid
          key={element[chosenKey]}
          onClick={() => handleSelectRow && handleSelectRow(element)}
          className={handleSelectRow && classes.testRunHover}
        >
          {renderData(element)}
          {(index + 1 !== data.length) && <hr className="dashedBorder" />}
        </Grid>
      ))}
    </div>
  );
}

export default TableWithDashedBorder;
