import React from 'react';
import { Calendar, Views, Navigate, momentLocalizer, dateFnsLocalizer } from 'react-big-calendar';
import { IconButton } from '@mui/material';
import { Button } from 'reactstrap';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import getDay from 'date-fns/getDay';
import { enUS } from 'date-fns/locale';
import { subMonths, endOfDay, endOfMonth, endOfWeek, startOfDay, startOfMonth, startOfWeek } from 'date-fns';
import DataLoader from './table/DataLoader';
import { t } from '../i18n/t';
import { IconArrowLeftBlue, IconArrowRightBlue } from '../images/CustomIcon';
import InfoSidebar from '../utils/InfoSidebar';
import { ExecutionDecorator } from '../pages/execution/ExecutionDecorator';
import Time, { TimeZone } from '../utils/Moment';
import { buildFilter } from './search-query/FilterQueryHelper';
import TestSuiteFilter from './search-query/filter/TestSuiteFilter';
import TestSuiteCollectionFilter from './search-query/filter/TestSuiteCollectionFilter';
import StatusFilter, { StatusType } from './search-query/filter/StatusFilter';
import TimeFilter from './search-query/filter/TimeFilter';
import InputFilter from './search-query/filter/InputFilter';
import UserFilter from './search-query/filter/UserFilter';
import ReleaseFilter from './search-query/filter/ReleaseFilter';
import { buildSearchCondition } from './search/SearchUtils';
import TestRunSideBar from './TestRunSideBar';
import UrlHelper from '../utils/UrlHelper';
import Services from '../utils/Services';
import MFlags from '../models/MFlags';

class TestRunCalendar extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      view: Views.MONTH,
      testRun: null,
      defaultDate: null,
      startDate: null,
      endDate: null,
    };
    this.renderData = this.renderData.bind(this);
    this.renderCustomEvent = this.renderCustomEvent.bind(this);
    this.handleSelectEvent = this.handleSelectEvent.bind(this);
    this.setDefaultDate = this.setDefaultDate.bind(this);
    this.customToolbar = this.customToolbar.bind(this);
    this.onNavigate = this.onNavigate.bind(this);
  }

  componentDidMount() {
    this.setDefaultDate();
    this.selectDefaultTestRun();
  }

  selectDefaultTestRun() {
    const { instantRunTestCasesEnabled } = MFlags;
    const defaultTestRunId = UrlHelper.getSearchParam('testRunId');
    if (instantRunTestCasesEnabled && defaultTestRunId) {
      Services.getTestRunById(defaultTestRunId)
        .then((testRun) => {
          if (testRun) {
            this.toggleShowTestRunInfoSidebar(testRun);
          }
        });
    }
  }

  toggleShowTestRunInfoSidebar(testRun) {
    if (testRun === this.state.testRun) {
      if (InfoSidebar.isOpened) {
        InfoSidebar.close();
        this.setState({ testRun: null });
      }
    } else {
      const header = t('execution#title', {
        order: testRun.order,
        name: ExecutionDecorator.testRunTitleDecorator(testRun)
      });
      const testRunDetails = <TestRunSideBar key={testRun.id} testRun={testRun} />;
      InfoSidebar.show(header, testRunDetails);
    }
  }

  handleSelectEvent(event) {
    this.setState({ testRun: event.execution });
    this.toggleShowTestRunInfoSidebar(event.testRun);
  }

  onClickCheckMoveBack3Months(toolbar, view) {
    if (this.checkMoveBackFutherThan3Months(new Date(), view)) {
      this.triggerShowBannerEvent();
    }
    toolbar.onView(view);
  }

  customToolbar(toolbar) {
    return (
      <div className="test-run-calender__header">
        <div className="navigate">
          <IconButton onClick={() => toolbar.onNavigate(Navigate.TODAY)} size="large">
            {t('today')}
          </IconButton>
          <IconButton onClick={() => toolbar.onNavigate(Navigate.PREVIOUS)} size="large">
            <IconArrowLeftBlue />
          </IconButton>
          <IconButton onClick={() => toolbar.onNavigate(Navigate.NEXT)} size="large">
            <IconArrowRightBlue />
          </IconButton>
        </div>
        <div className="time">
          {toolbar.label}
        </div>
        <div className="view">
          <Button data-trackid="calendar-month-view" onClick={() => this.onClickCheckMoveBack3Months(toolbar, Views.MONTH)}>
            {t('month')}
          </Button>
          <Button data-trackid="calendar-week-view" onClick={() => this.onClickCheckMoveBack3Months(toolbar, Views.WEEK)}>
            {t('week')}
          </Button>
          <Button data-trackid="calendar-day-view" onClick={() => this.onClickCheckMoveBack3Months(toolbar, Views.DAY)}>
            {t('day')}
          </Button>
          <Button data-trackid="calendar-agenda-view" onClick={() => this.onClickCheckMoveBack3Months(toolbar, Views.AGENDA)}>
            {t('agenda')}
          </Button>
        </div>
      </div>
    );
  }

  renderCustomEvent(event) {
    let className;
    switch (event.testRun.status) {
      case 'PASSED':
        className = 'test-run-calender__passed';
        break;
      case 'FAILED':
        className = 'test-run-calender__failed';
        break;
      default:
        className = 'test-run-calender__default';
    }
    return {
      className,
    };
  }

  setDefaultDate() {
    const { view } = this.state;
    let defaultDate;
    switch (view) {
      case Views.AGENDA:
        defaultDate = Time.convertToDate(Time.timeAgo(3, 'day'));
        break;
      case Views.MONTH:
      case Views.WEEK:
      case Views.WORK_WEEK:
      case Views.DAY:
      default:
        defaultDate = Time.convertToDate(new Date());
    }
    const startDate = this.startDate(defaultDate, view);
    const endDate = this.endDate(defaultDate, view);
    this.setState({ defaultDate, startDate, endDate });
  }

  startOfConverter(view) {
    switch (view) {
      case Views.MONTH:
        return startOfMonth;
      case Views.DAY:
        return startOfDay;
      case Views.WEEK:
      case Views.WORK_WEEK:
      case Views.AGENDA:
      default:
        return startOfWeek;
    }
  }

  endOfConverter(view) {
    switch (view) {
      case Views.MONTH:
        return endOfMonth;
      case Views.DAY:
        return endOfDay;
      case Views.WEEK:
      case Views.WORK_WEEK:
      case Views.AGENDA:
      default:
        return endOfWeek;
    }
  }

  startDate(defaultDate, view) {
    const converter = this.startOfConverter(view);
    return converter(defaultDate);
  }

  endDate(defaultDate, view) {
    const converter = this.endOfConverter(view);
    return converter(defaultDate);
  }

  triggerShowBannerEvent() {
    this.props.checkIsMorethan3Months(true);
  }

  checkMoveBackFutherThan3Months(newDate, view) {
    const startDate = view == Views.AGENDA ? newDate : this.startDate(newDate, view);
    const moveBack3Months = subMonths(new Date(), 3);

    return startDate <= moveBack3Months;
  }

  onNavigate(newDate, view) {
    const startDate = this.startDate(newDate, view);
    const endDate = this.endDate(newDate, view);
    this.setState({ defaultDate: newDate, startDate, endDate });
    if (this.checkMoveBackFutherThan3Months(newDate, view)) {
      this.triggerShowBannerEvent();
    }
  }

  customComponents() {
    return {
      toolbar: this.customToolbar,
    };
  }

  renderData(data) {
    const events = data.map((testRun) => ({
      start: Time.convertToDate(testRun.startTime),
      end: testRun.endTime ? Time.convertToDate(testRun.endTime) : Time.convertToDate(testRun.startTime),
      title: ExecutionDecorator.calendarDecorator(testRun),
      titleHover: ExecutionDecorator.calendarNameDecorator(testRun),
      testRun,
    }));

    const { view, defaultDate } = this.state;

    const locales = {
      'en-US': enUS,
    };
    const localizer = dateFnsLocalizer({
      format,
      parse,
      startOfWeek,
      getDay,
      locales,
    });

    return (
      <div className="test-run-calender">
        <Calendar
          length={7}
          events={events}
          onSelectEvent={this.handleSelectEvent}
          defaultDate={defaultDate}
          localizer={localizer}
          defaultView={view}
          onView={(view) => {
            this.setState({ view }, () => this.setDefaultDate());
          }}
          popup
          eventPropGetter={this.renderCustomEvent}
          components={this.customComponents()}
          onNavigate={this.onNavigate}
          key={`test-run-calendar-${view}`}
          tooltipAccessor={(event) => event.titleHover}
        />
      </div>
    );

  }

  render() {
    const { startDate, endDate } = this.state;
    const filterQuery = [
      buildFilter(TestSuiteFilter, { id: 'TestSuite.name' }),
      buildFilter(TestSuiteCollectionFilter, { id: 'TestSuiteCollectionEntity.name' }),
      buildFilter(StatusFilter, { id: 'status', type: StatusType.EXECUTION }),
      buildFilter(TimeFilter, { id: 'startTime', label: 'Started' }),
      buildFilter(InputFilter, { id: 'ExecutionTestResult.profile', label: 'Profile', operator: '~' }),
      buildFilter(UserFilter, { id: 'User.id' }),
      buildFilter(ReleaseFilter, { id: 'Release.id' })
    ];
    const newProps = {
      ...this.props,
      filterQuery,
    };
    return (
      <DataLoader
        entityType="TestRunPlanning"
        render={this.renderData}
        defaultSearchConditions={[
          buildSearchCondition('startTime', '>=', startDate),
          buildSearchCondition('startTime', '<=', endDate),
        ]}
        pageSize={100}
        fetchAllPages
        key={`execution-${startDate}-${endDate}`}
        showFilter
        useSearchQuery
        renderFooter={`Time zone: ${TimeZone.guess()}`}
        {...newProps}
      />
    );
  }

}

export default TestRunCalendar;
