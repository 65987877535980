const StringHelper = {
  /**
   *
   * @param str: string
   * @param sensitiveInfos: array contain sensitive info keys that want to hide
   * @returns string new string that is replaced sensitive info to ********
   */
  maskLog(str, sensitiveInfos = ['-apiKey', '-executionUUID']) {
    if (str) {
      const arStr = str.split(' ');
      sensitiveInfos.forEach((item) => {
        const index = arStr.findIndex((t) => t.startsWith(item));
        if (index >= 0) {
          arStr[index] = arStr[index].replace(/=.*/, '=********');
        }
      });
      return arStr.join(' ');
    }
    return '';
  },

  removeExtraSpace(s) {
    return s.trim().replace(/\s+/, ' ');
  },

  generateIncrementalName(currentName, existingNames) {
    let i = 1;
    let name = currentName;

    while (existingNames.includes(name)) {
      name = `${currentName} (${i})`;
      i++;
    }
    return name;
  },

  normalizeString(str) {
    const words = str.toLowerCase().split(/[_-]/);
    const capitalizedWords = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1));
    return capitalizedWords.join(' ');
  },
};

export default StringHelper;
