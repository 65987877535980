import React from 'react';
import _ from 'lodash';
import { Button } from 'reactstrap';
import { Route, Switch } from 'react-router-dom';
import Tooltip from '@katalon-studio/katalon-ui/Tooltip';
import { ThemeProvider } from '@mui/material/styles';
import DeletePlanDialog from '../../components/dialog/DeletePlanDialog';
import PageComponent from '../../components/PageComponent';
import { t } from '../../i18n/t';
import MContext from '../../models/MContext';
import Services from '../../utils/Services';
import Notification from '../../utils/Notification';
import ObjectSummary from '../../components/summary/ObjectSummary';
import Routes from '../../utils/Routes';
import { buildSearchCondition } from '../../components/search/SearchUtils';
import JobTable from '../../components/table/JobTable';
import PageButtonToolbar from '../../components/PageButtonToolbar';
import DefaultLayout from '../../components/DefaultLayout';
import http from '../../utils/http';
import Apis from '../../utils/Apis';
import { RunConfigurationHeaderNav } from '../../components/HeaderNav';
import RouteConstants from '../../utils/RouteConstants';
import ExecutionDataTable from '../../components/table/ExecutionDataTable';
import PlanConfiguration from './PlanConfiguration';
import PerformanceLineChart from '../../components/chart/PerformanceLineChart';
import RunPlanDropdownButton from '../execution/RunPlanDropdownButton';
import ApiHelper from '../../utils/ApiHelper';
import LinkRunConfigurationReleaseBuild from './LinkRunConfigurationReleaseBuild';
import UrlHelper from '../../utils/UrlHelper';
import GroupEvent from '../../utils/track/GroupEvent';
import ScheduleTestRunDialogV1 from '../../components/dialog/ScheduleTestRunDialogV1';
import { CloudType } from '../../utils/Constants';
import MAuth from '../../models/MAuth';
import MFlags from '../../models/MFlags';
import { Source } from '../../models/model/Source';
import { katalonui_theme } from '../../katalonui-theme';

function renderPerformance(data) {
  return (
    <PerformanceLineChart
      data={data.map(({ elapsedDuration, order, status }) => ({
        duration: elapsedDuration,
        order,
        status,
      }))}
      xLabel={t('executions')}
      xAxis="order"
    />
  );
}

function RunConfigurationExecution({ runConfigurationId }) {
  return <ExecutionDataTable
    showProjectColumn={false}
    defaultSearchConditions={[
      buildSearchCondition('RunConfiguration.id', '=', runConfigurationId),
    ]}
    useSearchQuery
    showCheckboxColumn
    defaultSort={['order,desc']}
    renderChart={renderPerformance}
  />;
}

function RunConfigurationJob({ runConfigurationId }) {
  const idTrackingProps = {
    'data-trackid': 'click-session-details-trt'
  };

  return (
    <JobTable
      defaultSearchConditions={[
        buildSearchCondition('RunConfiguration.id', '=', runConfigurationId),
      ]}
      defaultSort={['order,desc']}
      tableId="Jobs"
      idTrackingProps={idTrackingProps}
    />
  );
}


class RunConfiguration extends PageComponent {

  constructor(props) {
    super(props);
    this.meta.id = 'page-run-configuration';
    this.teamId = MContext.teamId;
    this.projectId = MContext.projectId;
    this.project = MContext.project;
    this.runConfigurationId = MContext.runConfigurationId;
    this.state = this.getInitialState();
    this.deleteRef = React.createRef();

    this.getRunConfiguration = this.getRunConfiguration.bind(this);
    this.renderHeader = this.renderHeader.bind(this);
    this.renderBody = this.renderBody.bind(this);
    this.onChangeReleaseBuild = this.onChangeReleaseBuild.bind(this);
    this.handleCloseScheduleTestRunDialog = this.handleCloseScheduleTestRunDialog.bind(this);
  }

  getInitialState() {
    // Get param isOpenScheduleTestRunDialog in url to set open dialog.
    const isOpenScheduleTestRunDialog = UrlHelper.getSearchParam('isOpenScheduleTestRunDialog') === 'true';

    return {
      selectedAgentOption: null,
      agentOptions: [],
      tscOptions: [],
      testProjectOptions: [],
      project: this.project,
      runConfiguration: null,
      disableSchedule: false,
      isOpenScheduleTestRunDialog,
    };
  }

  componentWillMount() {
    this.getRunConfiguration();
    this.getReleases();
  }

  getReleases() {
    const params = {
      pagination: {
        page: 0,
        size: 1000000,
        sorts: ['id,asc'],
      },
      conditions: [
        {
          key: 'Project.id',
          operator: '=',
          value: this.projectId,
        },
        {
          key: 'closed',
          operator: '=',
          value: false,
        },
      ],
      type: 'Release',
    };
    Services.search(params)
      .then(({ content }) => this.setState({ releases: content }));
  }

  getRunConfiguration() {
    const params = {
      pagination: {
        page: 0,
        size: 1,
        sorts: 'order,desc',
      },
      conditions: [
        {
          key: 'id',
          operator: '=',
          value: this.runConfigurationId,
        },
      ],
      type: 'RunConfiguration',
    };
    Services.search(params)
      .then(({ content }) => {
        const runConfiguration = content[0];
        if (MFlags.instantRunTestCasesEnabled && runConfiguration?.source === Source.SYSTEM) {
          this.handleCloseScheduleTestRunDialog();
        }
        this.meta.title = t('{{name}}', {
          name: runConfiguration.name,
        });
        const agents = _.get(runConfiguration, 'agents');
        const testCloudAgents = _.get(runConfiguration, 'testCloudAgents');
        const k8sAgents = _.get(runConfiguration, 'k8sAgents');
        const circleCIAgents = _.get(runConfiguration, 'circleCIAgents');
        const disableSchedule = (agents == null || agents.length === 0)
          && (testCloudAgents == null || testCloudAgents === 0)
          && (k8sAgents == null || k8sAgents.length === 0)
          && (circleCIAgents == null || circleCIAgents.length === 0);
        this.setState({ runConfiguration, disableSchedule });
      });
  }

  handleSelectRelease(value) {
    const { runConfiguration } = this.state;
    http.post(Apis.linkRunConfigurationRelease(this.projectId, runConfiguration.id, value.id))
      .then((runConfiguration) => {
        this.setState({
          runConfiguration,
        });
      });
  }

  unlinkRelease() {
    const { runConfiguration } = this.state;
    http.post(Apis.unlinkRunConfigurationRelease(this.projectId, runConfiguration.id))
      .then((runConfiguration) => {
        this.setState({
          runConfiguration,
        });
      });
  }

  executePlan() {
    const { runConfiguration } = this.state;
    Services.executeRunConfiguration(this.runConfigurationId)
      .then(() => {
        if (runConfiguration.cloudType === CloudType.TEST_CLOUD_AGENT) {
          Services.trackTestCloudTestRunScheduleEvent(MAuth.email, Date.now());
        }
        Notification.pushSuccess(`Run ${runConfiguration.name}.`);
      });
  }

  showDeletePlanDialog() {
    this.deleteRef.current.toggle();
  }

  renderDeleteDialog() {
    return (
      <DeletePlanDialog
        ref={this.deleteRef}
        id={this.runConfigurationId}
        afterRemoval={() => Routes.goToGridLink()}
      />
    );
  }

  onChangeReleaseBuild(runConfiguration) {
    this.setState({ runConfiguration });
  }

  renderEditScheduleTestRunButton() {
    const { runConfiguration } = this.state;
    const isSystemRunConfiguration = MFlags.instantRunTestCasesEnabled && runConfiguration?.source === Source.SYSTEM;
    return (
      <Button
        data-trackid="edit-schedule-dialog"
        data-groupid={GroupEvent.SCHEDULE_TEST_RUN}
        title={t('edit')}
        color="secondary"
        onClick={() => this.setState({ isOpenScheduleTestRunDialog: true })}
        disabled={isSystemRunConfiguration}
      >
        {t('edit')}
      </Button>
    );
  }

  renderToolbar() {
    const { testProjectOptions, releases, runConfiguration } = this.state;
    const isSystemRunConfiguration = MFlags.instantRunTestCasesEnabled && runConfiguration?.source === Source.SYSTEM;

    if (testProjectOptions) {
      return (
        <PageButtonToolbar>
          <LinkRunConfigurationReleaseBuild
            color="secondary"
            releases={releases}
            runConfiguration={runConfiguration}
            onChange={this.onChangeReleaseBuild}
          />
          <RunPlanDropdownButton
            apiSupport={ApiHelper.executePlan(this.runConfigurationId)}
            dataTrackId="run-plan"
            dataGroupId={GroupEvent.SCHEDULE_TEST_RUN}
            title={t('run')}
            color="secondary"
            onClick={() => this.executePlan()}
            variant="outlined"
            name={t('run')}
          />
          <ThemeProvider theme={katalonui_theme}>
            <Tooltip
              className="ml-2"
              label={t('schedule-cannot-modified')}
              size="medium"
              placement="bottom"
              disableHoverListener={!isSystemRunConfiguration}
            >
              <div>
                {this.renderEditScheduleTestRunButton()}
              </div>
            </Tooltip>
            <Tooltip
              className="ml-2"
              label={t('schedule-cannot-modified')}
              size="medium"
              placement="bottom"
              disableHoverListener={!isSystemRunConfiguration}
            >
              <div>
                <Button
                  title={t('delete')}
                  color="secondary"
                  onClick={() => this.showDeletePlanDialog()}
                  disabled={isSystemRunConfiguration}
                >
                  {t('delete')}
                </Button>
              </div>
            </Tooltip>
          </ThemeProvider>
        </PageButtonToolbar>
      );
    }
    return null;
  }

  renderObjectSummary() {
    const { runConfiguration, project } = this.state;
    const urlParams = {
      projectId: project.id,
      project: project.name,
      grid: t('grid'),
      runConfiguration: runConfiguration.name,
    };
    return (
      <ObjectSummary params={urlParams}>
        <RunConfigurationHeaderNav />
      </ObjectSummary>
    );
  }

  renderHeader() {
    return (
      <>
        {this.renderObjectSummary()}
      </>
    );
  }

  handleCloseScheduleTestRunDialog() {
    const isOpenScheduleTestRunDialog = false;
    this.setState({ isOpenScheduleTestRunDialog });
    // When close dialog remove param.
    UrlHelper.removeAllParams();
  }

  renderBody() {
    const { runConfiguration, isOpenScheduleTestRunDialog } = this.state;
    return (
      <>
        {this.renderToolbar()}
        {this.renderDeleteDialog()}
        <Switch>
          <Route
            exact
            path={RouteConstants.run_configuration}
            render={(params) => <RunConfigurationExecution runConfigurationId={this.runConfigurationId} {...params} />}
          />
          <Route
            exact
            path={RouteConstants.run_configuration_summary}
            render={(params) => <PlanConfiguration runConfiguration={runConfiguration} {...params} />}
          />
          <Route
            exact
            path={RouteConstants.run_configuration_session}
            render={(params) => <RunConfigurationJob runConfigurationId={this.runConfigurationId} {...params} />}
          />
        </Switch>
        {
          isOpenScheduleTestRunDialog &&
          <ScheduleTestRunDialogV1
            id="schedule-dialog"
            isOpen={isOpenScheduleTestRunDialog}
            handleClose={this.handleCloseScheduleTestRunDialog}
            params={this.params}
            editableRunConfiguration={runConfiguration}
          />
        }
      </>
    );
  }

  render() {
    const { runConfiguration } = this.state;
    if (runConfiguration) {
      return (
        <DefaultLayout
          renderHeader={this.renderHeader}
          renderBody={this.renderBody}
        />
      );
    } else {
      return null;
    }
  }
}

export default RunConfiguration;
