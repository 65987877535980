import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Box, Typography, Popover, Fade } from '@mui/material';
import SymbolicCounterList from './SymbolicCounterList';
import { t } from '../../../i18n/t';
import colors from '../../../../scss/colors.scss';
import TestSessionSummaryPopover from './TestSessionSummaryPopover';
import DecoratorContants from '../../../utils/DecoratorConstants';

const StyledSubscribeInfo = styled.div`
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 12px;
  line-height: 1.5;
  color: ${colors.darkBlue};
`;

const StyledMainContainer = styled.div`
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const StyledSymbolicListContainer = styled.div`
  display: flex;
  column-gap: 8px;
  align-items: center;
`;

const generateRunningSessionSummaryList = (sessionTypes) => sessionTypes.map((sessionType) => {
  const { tooltip, icon } = DecoratorContants.sessionTypesDecorator(sessionType);
  return {
    tooltip,
    icon,
    txt: `${sessionType.runningSessionsForOrganization}/${sessionType.limit}`,
  };
});

function TestExecutionSessionsUsage({ sessionSummary }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const openPopover = Boolean(anchorEl);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  return (
    <StyledMainContainer>
      <StyledSubscribeInfo>
        {`Subscribed ${sessionSummary?.totalPaidSessions || ''} TestCloud Sessions.`}
        <Box
          alignItems="flex-start"
          display="flex"
          flexDirection="column"
          gap="8px"
        >
          <Typography
            component="a"
            onClick={handlePopoverOpen}
            height="18px"
            flexGrow={0}
            fontSize="12px"
            fontWeight={500}
            lineHeight={1.5}
            color="#1847ca"
            marginLeft="4px"
          >
            {t('test-cloud-session-summary#show-details-button')}
          </Typography>
          <Popover
            open={openPopover}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            onClose={handlePopoverClose}
            TransitionComponent={Fade}
            sx={{
              marginTop: '12px',
            }}
          >
            <TestSessionSummaryPopover sessionTypes={sessionSummary.testSessionFeatureSummaries} />
          </Popover>
        </Box>
      </StyledSubscribeInfo>
      <StyledSymbolicListContainer>
        <SymbolicCounterList
          title={t('test-cloud-session-summary#tite-summary-session')}
          infoList={generateRunningSessionSummaryList(sessionSummary.testSessionFeatureSummaries)}
        />
      </StyledSymbolicListContainer>
    </StyledMainContainer>
  );
}

TestExecutionSessionsUsage.propTypes = {
  sessionSummary: PropTypes.shape({
    totalPaidSessions: PropTypes.number,
    testSessionFeatureSummaries: PropTypes.arrayOf(
      PropTypes.shape({
        limit: PropTypes.number,
        feature: PropTypes.string,
        runningSessionsForOrganization: PropTypes.number,
        runningSessionsForProject: PropTypes.number,
        queuedSessionsForOrganization: PropTypes.number,
        queuedSessionsForProject: PropTypes.number,
      }),
    ),
  }),
};

export default TestExecutionSessionsUsage;
