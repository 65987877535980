import React, { useState } from 'react';
import { DialogContent, DialogActions, DialogContentText } from '@mui/material';
import { Button, ButtonToolbar } from 'reactstrap';
import CloseableDialogComponent from './CloseableDialogComponent';
import { t } from '../../i18n/t';
import Services from '../../utils/Services';
import mauth from '../../models/MAuth';
import Notification from '../../utils/Notification';
import { Project } from '../../models/model/Project';

interface ConfirmUnsubscribeDailyReportDigestDialogProps {
  projectId: string;
  projectList: Project[],
  reloadProjectList: () => void;
}

function ConfirmUnsubscribeDailyReportDigestDialog(props: Readonly<ConfirmUnsubscribeDailyReportDigestDialogProps>) {

  const {
    projectId,
    projectList,
    reloadProjectList
  } = props;

  const [isOpen, setIsOpen] = useState(true);

  const unsubscribedProject = projectList.find((project) => String(project.id) === projectId);

  const handleConfirm = () => {
    const userId = mauth.user.id;
    const dailyReportUnsubscription = {
      projectId,
      userId,
    };
    Services.unSubscribeUsersToDailyEmail(dailyReportUnsubscription)
      .then(() => {
        setIsOpen(!isOpen);
        reloadProjectList();
        Notification.pushSuccess(t('user-settings#unsubscribe-daily-report-dialog#success-toast', { projectName: unsubscribedProject?.name }));
      });
  };

  const handleClose = () => {
    setIsOpen(!isOpen);
  };

  if (!unsubscribedProject) {
    return null;
  }

  return (
    <CloseableDialogComponent
      isOpen={isOpen}
      handleClose={handleClose}
      maxWidth="sm"
      title={t('unsubscribe')}
    >
      <DialogContent>
        <DialogContentText className="title ml-0 mt-3 text-left">
          {t('user-settings#unsubscribe-daily-report-dialog#content')}
          <strong>{unsubscribedProject.name}</strong>?
        </DialogContentText>
      </DialogContent>
      <DialogActions className="popup-footer justify-content-end p-0">
        <ButtonToolbar>
          <Button
            color="link"
            className="ml-3"
            title={t('cancel')}
            onClick={handleClose}
          >
            {t('cancel')}
          </Button>
          <Button
            color="danger"
            className="ml-3"
            title={t('confirm')}
            onClick={handleConfirm}
          >
            {t('confirm')}
          </Button>
        </ButtonToolbar>
      </DialogActions>
    </CloseableDialogComponent>
  );
}

export default ConfirmUnsubscribeDailyReportDigestDialog;
