import React from 'react';
import _ from 'lodash';

export default class MTableColumnDataMapping {
  constructor(
    headerCaption,
    fieldName,
    decorate = (name, row) => <div className="text-wrap">{_.get(row, name, '')}</div>,
    isToolbar = false,
    className = '',
    sortQuery,
    width = '',
    enableHeaderTooltip = false,
    headerTooltipText = '',
  ) {
    this.headerCaption = headerCaption;
    this.fieldName = fieldName;
    this.decorate = decorate;
    this.isToolbar = isToolbar;
    this.className = className;
    this.sortQuery = sortQuery;
    this.width = width;
    this.enableHeaderTooltip = enableHeaderTooltip;
    this.headerTooltipText = headerTooltipText;
  }
}
