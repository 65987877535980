import _ from 'lodash';
import React from 'react';
import {
  CloudType,
  ConfigType,
  DevicePoolType,
  ExecutionMode,
  IntervalUnitOptions,
  MAX_PAGE_SIZE, MAXIMUM_PAGE_SIZE,
  SearchEntity,
  XrayImportReportType
} from '../../utils/Constants';
import { buildSearchCondition } from '../../components/search/SearchUtils';
import Services from '../../utils/Services';
import Notification from '../../utils/Notification';
import MContext from '../../models/MContext';
import DecoratorConstants from '../../utils/DecoratorConstants';
import { t } from '../../i18n/t';
import Time from '../../utils/Moment';
import MFlags from '../../models/MFlags';
import testCloudService from '../../utils/testcloud/testCloudService';
import MAuth from '../../models/MAuth';
import Arrays from '../../utils/Arrays';
import { scheduleTestRunFailedEvent, track } from '../../components/smarttestscheduling/services/scheduleTestRunTracking';

export const CONFIG_TYPE_OPTION_MAP = {
  [ConfigType.TS]: { value: ConfigType.TS, label: 'Test Suite' },
  [ConfigType.TSC]: { value: ConfigType.TSC, label: 'Test Suite Collection' },
  [ConfigType.COMMAND]: { value: ConfigType.COMMAND, label: 'Katalon Command' },
  [ConfigType.GENERIC_COMMAND]: { value: ConfigType.GENERIC_COMMAND, label: 'Generic Command' },
};

export const CLOUD_TYPE_OPTION_MAP = {
  [CloudType.LOCAL_AGENT]: { value: CloudType.LOCAL_AGENT, label: 'Local Test Environment' },
  [CloudType.TEST_CLOUD_AGENT]: !MFlags.testCloudHideGATrialEnabled ?
    { value: CloudType.TEST_CLOUD_AGENT, label: 'TestCloud Test Environment' } : { value: CloudType.TEST_CLOUD_AGENT, label: 'TestCloud Test Environment', postBadge: 'TRIAL' },
  [CloudType.K8S_AGENT]: { value: CloudType.K8S_AGENT, label: 'Kubernetes Test Environment' },
  [CloudType.CIRCLE_CI_AGENT]: { value: CloudType.CIRCLE_CI_AGENT, label: 'CircleCI Test Environment' },
};

export const EXECUTION_MODE_OPTION_MAP = {
  [ExecutionMode.SEQUENTIAL]: { value: ExecutionMode.SEQUENTIAL, label: 'Sequential' },
  [ExecutionMode.PARALLEL]: { value: ExecutionMode.PARALLEL, label: 'Parallel' },
};

export const KRE_LATEST_OPTION_VALUE = 'latest';

export const KRE_VERSION_SUPPORT_FROM = '8.6.8';

export const KRE_LATEST_OPTION = { value: KRE_LATEST_OPTION_VALUE, label: t('schedule#latest-kre-option') };

export const getIntervalUnitOptions = () => (MFlags.testCloudDisableRepeatByMinute
  ? IntervalUnitOptions.filter((option) => option.value !== 'MINUTE')
  : IntervalUnitOptions);

function getBrowserName(characters) {
  return characters.toLowerCase().slice(0, characters.search(/\d/));
}

function generateAgentOptions(agents) {
  return agents.reverse().map((agent) => ({
    value: agent,
    icon: DecoratorConstants.iconsAgent(agent.active, agent.os),
    label: agent.name,
  }));
}

export function generateTestCloudOsOrBrowserNameOptions(options, isSystemOption = true) {
  return options.map((option) => ({
    value: option,
    label: (
      <div className="d-flex align-items-center">
        <div>{isSystemOption ? DecoratorConstants.iconByOS(option.toLowerCase()) : DecoratorConstants.iconByBrowser(getBrowserName(option))}</div>
        <div className="ml-3">{option}</div>
      </div>
    ),
  }));
}

function generateK8SAgentOptions(agents) {
  return agents.reverse().map((agent) => ({
    value: agent,
    label: agent.name
  }));
}

function generateCircleCIAgentOptions(agents) {
  return agents.reverse().map((agent) => ({
    value: agent,
    label: agent.name
  }));
}

export function getAgents(teamId) {
  const params = {
    pagination: {
      page: 0,
      size: 1000000,
      sorts: ['name,desc', 'lastPing,desc'],
    },
    conditions: [
      ...Arrays.insertIf(!MFlags.moveAgentToOrgLevelPhase2Enabled, buildSearchCondition('Team.id', '=', teamId)),
    ],
    type: 'Agent',
  };
  return Services.search(params)
    .then(({ content: agents }) => generateAgentOptions(agents));
}

const MOBILE_NAMES = ['android', 'ios'];
export async function getTestCloudMobileDevices(organizationId) {
  if (MFlags.testCloudDisableTCFromScheduleDialog) {
    return Promise.resolve([]);
  }

  const result = await testCloudService.getTestCloudMobileDevices(organizationId);
  if (MFlags.testCloudDisableSCDeviceResourceByOrg) {
    const filteredDevices = result.data.reduce((deviceAccumulator, device) => {
      if (MOBILE_NAMES.includes(device.os.toLowerCase())) {
        const filteredCapabilities = device.capabilities.filter((capability) => capability.devicePoolType !== DevicePoolType.SUB_SHARED_POOL);
        if (filteredCapabilities.length > 0) {
          if (filteredCapabilities[0].devicePoolType === DevicePoolType.PRIVATE_CLOUD_POOL) {
            device.devicePoolId = organizationId;
          }
          deviceAccumulator.push({
            ...device,
            capabilities: filteredCapabilities
          });
        }
      }
      return deviceAccumulator;
    }, []);
    return filteredDevices;
  }
  return result.data
    .filter(({ os }) => MOBILE_NAMES.includes(os?.toLowerCase()))
    .map((device) => {
      if (device.capabilities.length > 0 &&
          device.capabilities[0].devicePoolType === DevicePoolType.PRIVATE_CLOUD_POOL) {
        device.devicePoolId = organizationId;
      }
      return device;
    });

}

export function getTestCloudEnvironments(channel) {
  if (MFlags.testCloudDisableTCFromScheduleDialog) {
    return Promise.resolve([]);
  }
  return testCloudService.getTestCloudEnvironments(channel)
    .then((result) => result.data);
}

export function getK8SAgents(teamId) {
  const params = {
    pagination: {
      page: 0,
      size: 1000000,
      sorts: ['name,desc'],
    },
    conditions: [
      ...Arrays.insertIf(!MFlags.moveAgentToOrgLevelPhase2Enabled, buildSearchCondition('Team.id', '=', teamId)),
    ],
    type: 'K8SAgent',
  };
  return Services.search(params)
    .then(({ content: agents }) => generateK8SAgentOptions(agents));
}

export function getCircleCIAgents(teamId) {
  const params = {
    pagination: {
      page: 0,
      size: 1000000,
      sorts: ['name,desc'],
    },
    conditions: [
      ...Arrays.insertIf(!MFlags.moveAgentToOrgLevelPhase2Enabled, buildSearchCondition('Team.id', '=', teamId)),
    ],
    type: 'CircleCiAgent',
  };
  return Services.search(params)
    .then(({ content: agents }) => generateCircleCIAgentOptions(agents));
}

/**
 * Compare two semver versions. Returns true if version 1 is greater than
 * version 2
 * @param {string} version1
 * @param {string} version2
 * @returns {boolean}
 */
export function semverGreaterThan(version1, version2) {
  if (version1.startsWith(`${version2}-`)) return false;
  if (version2.startsWith(`${version1}-`)) return true;

  return version1.localeCompare(version2, undefined, { numeric: true, sensitivity: 'case', caseFirst: 'upper' }) >= 0;
}

export function getKSVersions() {
  return Services.fetchKsVersionList()
    .then((data) => {
      const ksVersions = _.uniqBy(data, 'version')
        .map((value) => value.version);
      const filteredVersions = _.filter(ksVersions, (item) => semverGreaterThan(item, KRE_VERSION_SUPPORT_FROM));

      return filteredVersions.map((version) => ({
        value: version,
        label: version,
      }));
    });
}

export function getTSCs(testProject) {
  if (!testProject.id) {
    return null;
  }
  const params = {
    pagination: {
      page: 0,
      size: MAXIMUM_PAGE_SIZE,
      sorts: ['name,asc'],
    },
    conditions: [
      buildSearchCondition('TestProject.id', '=', testProject.id),
    ],
    type: 'TestSuiteCollection',
  };
  return Services.searchRecursively(0, params, [])
    .then((content) => {
      if (content) {
        return content.map((tsc) => ({
          value: tsc.id,
          label: tsc.name,
          testSuiteCollectionConfigurations: tsc.testSuiteCollectionConfigurations?.filter((item) => item.runEnabled)
        }));
      }
      return null;
    });
}

export function getTSC(tscId) {
  const params = {
    pagination: {
      page: 0,
      size: 1,
      sorts: ['name,asc'],
    },
    conditions: [
      buildSearchCondition('id', '=', tscId),
    ],
    type: 'TestSuiteCollection',
  };
  return Services.search(params)
    .then(({ content }) => content[0]);
}

export function getTestSuite(tsId) {
  const params = {
    pagination: {
      page: 0,
      size: 1,
      sorts: ['name,asc'],
    },
    conditions: [
      buildSearchCondition('id', '=', tsId),
    ],
    type: SearchEntity.TestSuite,
  };
  return Services.search(params)
    .then(({ content }) => content[0]);
}

export function getTestSuitesRecursively(testProject) {
  if (!testProject.id) {
    return null;
  }
  const params = {
    pagination: {
      page: 0,
      size: MAX_PAGE_SIZE,
      sorts: ['name,asc'],
    },
    conditions: [
      buildSearchCondition('TestProject.id', '=', testProject.id),
    ],
    type: SearchEntity.TestSuite,
  };

  return Services.searchRecursively(0, params, [])
    .then((content) => content.map((ts) => ({
      value: ts.id,
      ...ts,
      label: DecoratorConstants.addPathOfName(ts.path, ts.name)
    })));
}

export function getExecutionProfileRecursively(testProject) {
  const params = {
    pagination: {
      page: 0,
      size: MAX_PAGE_SIZE,
      sorts: [
        'isDefault, desc',
        'name, asc'
      ],
    },
    conditions: [
      buildSearchCondition('TestProject.id', '=', testProject.id),
    ],
    type: SearchEntity.ExecutionProfile,
  };

  return Services.searchRecursively(0, params, [])
    .then((content) => content);
}

export function getRunConfiguration(runConfigurationId) {
  const params = {
    pagination: {
      page: 0,
      size: 1,
      sorts: 'order,desc',
    },
    conditions: [
      buildSearchCondition('id', '=', runConfigurationId),
    ],
    type: 'RunConfiguration',
  };
  return Services.search(params)
    .then(({ content }) => {
      const configuration = content[0];
      if (configuration) {
        if (!configuration.agents) {
          configuration.agents = [];
        }
        if (!configuration.testCloudAgents) {
          configuration.testCloudAgents = [];
        }
        if (!configuration.k8sAgents) {
          configuration.k8sAgents = [];
        }
        if (!configuration.circleCIAgents) {
          configuration.circleCIAgents = [];
        }
        if (!configuration.testSuiteCollection) {
          configuration.testSuiteCollections = [];
        }

        if (!configuration.testCloudTestSuiteCollectionAgents) {
          configuration.testCloudTestSuiteCollectionAgents = [];
        }

        if (!configuration.testSuitesRunConfig) {
          configuration.testSuitesRunConfig = [];
        }

        configuration.configType = CONFIG_TYPE_OPTION_MAP[configuration.configType] || {};
        configuration.cloudType = CLOUD_TYPE_OPTION_MAP[configuration.cloudType] || {};
        configuration.executionMode = EXECUTION_MODE_OPTION_MAP[configuration.executionMode] || {};
        return configuration;
      }
      return null;
    });
}

export function getBaselineCollectionGroups(projectId) {
  const params = {
    pagination: {
      page: 0,
      size: MAX_PAGE_SIZE,
      sorts: ['order, desc'],
    },
    conditions: [
      buildSearchCondition('Project.id', '=', projectId),
    ],
    type: SearchEntity.BaselineCollectionGroup,
  };

  return Services.searchRecursively(0, params, []);
}

/**
 * Run configuration from BE will not contains some props
 * we will create an empty array instead of let those props undefined
 * for configType, cloudType, executionMode we will create an empty object if it undefined
 * @param {*} configuration;
 * @returns configuration
 */
export function updateRunConfigurationAttributes(configuration) {
  if (configuration) {
    if (!configuration.agents) {
      configuration.agents = [];
    }
    if (!configuration.testCloudAgents) {
      configuration.testCloudAgents = [];
    }
    if (!configuration.k8sAgents) {
      configuration.k8sAgents = [];
    }
    if (!configuration.circleCIAgents) {
      configuration.circleCIAgents = [];
    }

    if (!configuration.testSuiteCollection) {
      configuration.testSuiteCollections = [];
    }
    if (!configuration.testCloudTestSuiteCollectionAgents) {
      configuration.testCloudTestSuiteCollectionAgents = [];
    }

    if (!configuration.testSuitesRunConfig) {
      configuration.testSuitesRunConfig = [];
    }

    configuration.configType = CONFIG_TYPE_OPTION_MAP[configuration.configType] || {};
    configuration.cloudType = CLOUD_TYPE_OPTION_MAP[configuration.cloudType] || {};
    configuration.executionMode = EXECUTION_MODE_OPTION_MAP[configuration.executionMode] || {};
    return configuration;
  }
  return null;
}

/**
 * Scheduler fetched from BE startTime and endTime is a String,
 * It will parse this String to Date for handling in UI
 * @param {*} scheduler
 * @returns scheduler
 */
export function updateSchedulerAttributes(scheduler) {
  const startTime = scheduler.startTime;
  const endTime = scheduler.endTime;
  scheduler.startTime = !startTime ? null : new Date(startTime);
  scheduler.endTime = !endTime ? null : new Date(endTime);
  scheduler.intervalUnit = getIntervalUnitOptions().find((option) => option.value === scheduler.intervalUnit)?.value ?? null;
  return scheduler;
}

export function getTestProjects(defaultSearchConditions) {
  const params = {
    pagination: {
      page: 0,
      size: 1000000,
      sorts: 'id,desc',
    },
    conditions: defaultSearchConditions || [],
    type: 'TestProject',
  };
  return Services.search(params)
    .then(({ content: testProjects }) => testProjects);
}

export function getDefaultTestProject(projectId) {
  const params = { projectId };
  return Services.getDefaultTestProject(params);
}

export function getInitialState() {
  const defaultTimeOut = 60; // timeout in minutes
  const defaultScheduleName = t('schedule#default-test-run-name');

  return {
    selectedOsOption: null,
    selectedBrowserOption: null,
    selectedOsAndBrowser: [],
    groupByTestCloudOption: {},
    selectedAgentOption: null,
    selectedK8SAgentOption: null,
    testCloudOsOptions: [],
    testCloudBrowserOptions: [],
    agentOptions: [],
    k8sAgentOptions: [],
    circleCOAgentOptions: [],
    tscOptions: [],
    testProjectOptions: [],
    ksVersionOptions: [],
    executionModeOptions: [],
    formData: {
      name: defaultScheduleName,
      command: '',
      agents: [],
      testCloudAgents: [],
      k8sAgents: [],
      circleCIAgents: [],
      cloudType: {},
      configType: {},
      browserType: '',
      executionProfileId: null,
      testProject: {
        id: null,
        type: 'KS',
      },
      testSuiteCollectionId: null,
      testSuiteId: null,
      genericCommand: '',
      ksVersion: '',
      ksLocation: '',
      timeOut: defaultTimeOut,
      release: {},
      kobitonDeviceId: '',
      executionMode: {},
      enabledKobitonIntegration: false,
      enabledTestCloudTunnel: false,
      baselineCollectionGroupOrder: null,
      testSuiteCollections: [],
      testCloudTestSuiteCollectionAgents: [],
      testSuitesRunConfig: [],
      xrayImportReportType: XrayImportReportType.PUSH_MANUALLY,
      externalTestPlanId: '',
      customFieldOptions: [],
      tags: [],
    },
    configTypeOptions: [],
    cloudTypeOptions: [],
    chooseKsVersion: true,
    isReady: false,
    environmentReady: false,
    testProjectReady: false,
    createScheduler: Boolean(MContext.runConfigurationId),
  };
}

export function getDefaultRunConfiguration() {
  return getInitialState().formData;
}

export function getSmartTestScheduler(runConfigurationId) {
  return Services.getSmartTestScheduler(runConfigurationId);
}

export function getDefaultScheduler() {
  const scheduler = {
    name: '',
    startTime: Time.now(),
    endTime: null,
    interval: 1,
    intervalUnit: 'WEEK',
    active: true,
  };
  return scheduler;
}

export const getDefaultSchedulerButtonName = () => {
  if (MFlags.testCloudDefaultSchedulerSettingEnabled) {
    return t('run');
  }
  return t('create-schedule');
};

export const getDefaultSchedulerTypeExecutionOptions = (typeExecutions) => {
  if (MFlags.testCloudDefaultSchedulerSettingEnabled) {
    return typeExecutions?.filter((t) => t.title !== 'schedule');
  }
  return typeExecutions.filter((e) => e.title !== 'run');
};

export const getDefaultSchedulerEnableRepeat = () => !MFlags.testCloudDefaultSchedulerSettingEnabled;

function handlerErrorMessage(message, trackId) {
  if (trackId) {
    track(scheduleTestRunFailedEvent(trackId));
  }
  Notification.pushError(message);
}

const handleErrorWithTrackId = (trackId) => (message) => handlerErrorMessage(message, trackId);

function toRunConfiguration(formData, teamId, projectId, runConfigurationId) {
  const runConfiguration = {
    name: formData.name,
    command: formData.command,
    teamId,
    projectId,
    testProjectId: formData.testProject.id,
    id: runConfigurationId,
    agents: formData.agents,
    testCloudAgents: formData.testCloudAgents,
    k8sAgents: formData.k8sAgents,
    circleCIAgents: formData.circleCIAgents,
    cloudType: formData.cloudType.value,
    browserType: formData.browserType,
    executionProfileId: formData.executionProfileId,
    configType: formData.configType.value,
    testSuiteCollectionId: formData.testSuiteCollectionId,
    testSuiteId: formData.testSuiteId,
    genericCommand: formData.genericCommand,
    ksVersion: formData.ksVersion,
    ksLocation: formData.ksLocation,
    timeOut: formData.timeOut,
    releaseId: formData.release?.id,
    kobitonDeviceId: formData.kobitonDeviceId,
    executionMode: formData.executionMode?.value || ExecutionMode.SEQUENTIAL,
    enabledKobitonIntegration: formData.enabledKobitonIntegration,
    enabledTestCloudTunnel: formData.enabledTestCloudTunnel,
    baselineCollectionGroupOrder: formData.baselineCollectionGroupOrder,
    testSuiteCollections: formData.testSuiteCollections,
    testCloudTestSuiteCollectionAgents: formData.testCloudTestSuiteCollectionAgents,
    testSuitesRunConfig: formData.testSuitesRunConfig,
    xrayImportReportType: formData.xrayImportReportType,
    externalTestPlanId: formData.externalTestPlanId,
    customFieldOptions: formData.customFieldOptions,
    tags: formData.tags,
    testRunConfig: formData.testRunConfig,
    source: formData.source,
  };
  return runConfiguration;
}

export function createAndUpdateConfiguration(formData, teamId, projectId, runConfigurationId, trackId) {
  const configuration = toRunConfiguration(formData, teamId, projectId, runConfigurationId);

  return Services.createAndUpdateRunConfiguration(configuration, true, handleErrorWithTrackId(trackId));
}

function findFirstActiveOptionEquals(options, value) {
  return options.find((option) => value === option.value && !option.disabled);
}

function findFirstActiveOption(options) {
  return options.find((option) => !option.disabled);
}

export function findMatchedOrFirstActiveOption(options, value) {
  if (value) {
    return findFirstActiveOptionEquals(options, value);
  }
  return findFirstActiveOption(options);
}

export function trackTestCloudScheduleTestRunSuccessEvent() {
  Services.trackTestCloudTestRunScheduleEvent(MAuth.email, Date.now());
}

export function createConfigurationAndTrigger(formData, teamId, projectId, scheduler, runConfigurationId, trackId) {
  const runConfiguration = toRunConfiguration(formData, teamId, projectId, runConfigurationId);
  scheduler.name = runConfiguration.name;

  return Services.createRunConfigurationAndTrigger({ runConfiguration, scheduler }, true, handleErrorWithTrackId(trackId));
}

export function createConfigurationAndRun(formData, teamId, projectId, runConfigurationId, trackId) {
  const runConfiguration = toRunConfiguration(formData, teamId, projectId, runConfigurationId);

  return Services.createRunConfigurationAndRun(runConfiguration, true, handleErrorWithTrackId(trackId));
}
