import Icon from '@katalon-studio/katalon-ui/Icon';
import React from 'react';
import colors from '../../../scss/colors.scss';

function ClockIcon({ color, textSize }) {
  return <Icon
    type="fa-regular"
    name="fa-clock"
    textSize={textSize}
    sx={{
      color,
    }}
    boundingSize={null}
    color={null}
  />;
}

ClockIcon.defaultProps = {
  color: colors.gray9,
  textSize: '1rem',
};

export default ClockIcon;
