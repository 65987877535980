import React, { useState } from 'react';
import { Button, CustomInput, Form, FormGroup } from 'reactstrap';
import { DialogActions, DialogContent, DialogTitle } from '@mui/material';
import PropTypes from 'prop-types';
import DialogComponent from './DialogComponent';
import { t } from '../../i18n/t';
import Input from '../Input';
import { IconArrowRight, IconExclamationTriangle } from '../../images/CustomIcon';
import DecoratorConstants from '../../utils/DecoratorConstants';
import Helper from '../../utils/Helper';
import Services from '../../utils/Services';
import Notification from '../../utils/Notification';
import Routes from '../../utils/Routes';
import { TestRunStatus } from '../../utils/Constants';

const ValidationText = {
  NO_SELECT: t('test-runs#no-select'),
  OTHER_OPTION_NULL: t('test-runs#other-option-null'),
  OTHER_OPTION_MAX: t('test-runs#other-option-max-length'),
};

const listReasons = [
  {
    id: 'manual-result',
    data_track_id: 'otr-select-manual-result',
    name: t('test-runs#option-manual-result'),
  },
  {
    id: 'expected-result',
    data_track_id: 'otr-select-expected-result',
    name: t('test-runs#option-expected-result'),
  },
  {
    id: 'flaky-test',
    data_track_id: 'otr-select-flaky-test',
    name: t('test-runs#option-flaky-test'),
  },
  {
    id: 'updated-function',
    data_track_id: 'otr-select-updated-function',
    name: t('test-runs#option-updated-function'),
  },
  {
    id: 'other-option',
    data_track_id: 'otr-select-other-option',
    name: t('test-runs#option-other-option'),
  },
];

function MarkAsRetestedDialog({ testRunId, status, isOpen, handleClose }) {
  const [reason, setReason] = useState(null);
  const [otherOptionDescription, setOtherOptionDescription] = useState('');
  const [validationSelected, setValidationSelected] = useState(null);
  const otherOptionId = listReasons[4].id;

  const getStatus = () => {
    const oppositionStatus = Helper.getOppositionStatus(status);
    const currentStatus = DecoratorConstants.combineIconStatusForText(status, status.toLowerCase());
    const newStatus = DecoratorConstants.combineIconStatusForText(oppositionStatus, oppositionStatus.toLowerCase());

    return (
      <div className="d-flex text">
        {currentStatus}
        <div className="mr-4 ml-4"><IconArrowRight /></div>
        {newStatus}
      </div>
    );
  };

  const checkValidationSelected = () => {
    const validationSelected = {
      checked: false,
      value: '',
    };
    if (reason === null) {
      validationSelected.checked = true;
      validationSelected.value = ValidationText.NO_SELECT;
      setValidationSelected(validationSelected);
      return false;
    }

    if (reason.id === otherOptionId) {
      if (otherOptionDescription === '') {
        validationSelected.checked = true;
        validationSelected.value = ValidationText.OTHER_OPTION_NULL;
        setValidationSelected(validationSelected);
        return false;
      }
      if (otherOptionDescription.length > 250) {
        validationSelected.checked = true;
        validationSelected.value = ValidationText.OTHER_OPTION_MAX;
        setValidationSelected(validationSelected);
        return false;
      }
    }
    return true;
  };

  const handleCancel = () => {
    setReason(null);
    setValidationSelected(null);
    handleClose();
  };

  const goToCommentForTestResultPage = () => {
    Services.getTestResult(testRunId)
      .then((testResult) => {
        const executionTestResultId = testResult.urlId;
        const executionId = testResult.execution.order;
        Routes.goToCommentForTestResultPage(executionId, executionTestResultId);
      });
  };

  const handleOpenConfirmDialog = (e) => {
    e.preventDefault();
    if (checkValidationSelected()) {
      const reasonChangeStatus = {
        description: otherOptionId === reason.id ? otherOptionDescription : reason.value,
      };
      Services.markAsRetestedTestRun(testRunId, reasonChangeStatus).then(() => {
        const changeStatus = (status === TestRunStatus.PASSED ? t('totalFailedTests') : t('totalPassedTests')).toLowerCase();
        Notification.pushSuccess(t('test-runs#notification-success', { testRunId, changeStatus }));
        handleClose();
        goToCommentForTestResultPage();
      }).catch(() => {
        Notification.pushError(t('test-runs#notification-error'));
      });
    }
  };

  const handleChangeReason = (event) => {
    const { value, id } = event.target;
    const newReason = { id, value };
    setReason(newReason);
    setValidationSelected(null);
  };

  const onChangeDescriptionOtherReason = (event) => {
    setValidationSelected(null);
    const description = event.target.value;
    setOtherOptionDescription(description);
  };

  return (
    <DialogComponent
      isOpen={isOpen}
      maxWidth="sm"
    >
      <Form data-trackid="otr-submit-change-status" onSubmit={handleOpenConfirmDialog}>
        <DialogTitle>{t('test-runs#title-change-status')}</DialogTitle>
        <DialogContent>
          <h5>
            {t('test-runs#action-change-status', { testRunId })}
          </h5>
          {status && getStatus()}
          <div className="label-text required mt-2">
            {t('test-runs#option-change-status')}
          </div>
          <FormGroup>
            {listReasons.map(({ id, data_track_id, name }) => (
              <CustomInput
                data-trackid={data_track_id}
                id={id}
                name={id}
                type="radio"
                key={id}
                onClick={handleChangeReason}
                checked={reason?.id === id}
                value={name}
                label={name}
                className="setting-custom-control"
              />
            ))}
            {(reason?.id === otherOptionId) &&
            <Input
              id="description-other-option"
              name="descriptionOtherOption"
              value={otherOptionDescription}
              onChange={onChangeDescriptionOtherReason}
              placeholder={t('test-runs#other-option-placeholder')}
              type="textarea"
              rows="4"
              className="mt-2"
            />}
          </FormGroup>
          {validationSelected?.checked &&
          <div className="d-flex icon-text">
            <IconExclamationTriangle />
            <div className="text-danger">{validationSelected?.value}</div>
          </div>}
        </DialogContent>
        <DialogActions>
          <Button data-trackid="otr-cancel-change-status" color="secondary" onClick={handleCancel}>
            {t('cancel')}
          </Button>
          <Button type="submit" color="primary">
            {t('submit')}
          </Button>
        </DialogActions>
      </Form>
    </DialogComponent>
  );
}

MarkAsRetestedDialog.propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  /**
   * Current status of Test Run.
   */
  status: PropTypes.string,
  testRunId: PropTypes.number,
};

MarkAsRetestedDialog.defaultProps = {
  isOpen: false,
  handleClose: null,
};

export default MarkAsRetestedDialog;
