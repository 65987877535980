import { isEmpty } from 'lodash';
import ConvertDataHelper from '../../utils/ConvertDataHelper';

const TreeViewHelper = {
  updateTreeData(origin, key, children, mergeChildren = false) {
    return origin.map((node) => {
      if (node.key === key) {
        if (mergeChildren) { // prepend children to existing children
          const mergedChildren = [...children, ...node.children];
          return { ...node, children: mergedChildren };
        } else { // override existing children with new children
          return { ...node, children };
        }
      }
      if (node.children) {
        return { ...node, children: this.updateTreeData(node.children, key, children, mergeChildren) };
      }
      return node;
    });
  },

  buildRootFolder(testFolders) {
    if (!testFolders || isEmpty(testFolders)) {
      return [];
    }
    const testFolder = testFolders[0];
    const icon = ConvertDataHelper.renderIconForRootFolder(testFolder);

    return [
      {
        key: testFolder.id,
        value: testFolder.id,
        title: ConvertDataHelper.buildIconTitle(testFolder.name, icon),
        testProjectId: testFolder.testProject.id,
        children: [],
        rawPath: testFolder.rawPath,
      }
    ];
  },

  getAllNodeKeys(node) {
    let keys = [];

    keys.push(node.key);
    if (node.children) {
      node.children.forEach((item) => {
        const childrenKeys = this.getAllNodeKeys(item);
        keys = [...keys, ...childrenKeys];
      });
    }
    return keys;
  },

  findTreeNodes(treeData, keys) {
    let foundNodes = [];
    for (const node of treeData) {
      if (keys.includes(node.key)) {
        foundNodes.push(node);
      }
      if (node.children) {
        const childrenNodes = this.findTreeNodes(node.children, keys);
        foundNodes = [...foundNodes, ...childrenNodes];
      }
    }
    return foundNodes;
  },
};
export default TreeViewHelper;

