

import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import MChartLabelMapping from './models/MChartLabelMapping';
import MChartDataMapping from './models/MChartDataMapping';
import StatusConstants from '../../utils/Statuses';
import LineChart from './LineChart';

class StatusAreaChart extends React.Component {

  render() {

    const { xAxis } = this.props;
    let { chartLabelsDecorate } = this.props;

    if (!chartLabelsDecorate) {
      chartLabelsDecorate = (fieldName, item) => `#${_.get(item, fieldName)}`;
    }

    const labelsMapping = new MChartLabelMapping(
      xAxis,
      chartLabelsDecorate,
    );

    const datasetsMapping = [
      new MChartDataMapping(StatusConstants.PASSED.label, 'totalPassedTests', StatusConstants.PASSED.color),
      new MChartDataMapping(StatusConstants.FAILED.label, 'totalFailedTests', StatusConstants.FAILED.color),
      new MChartDataMapping(StatusConstants.ERROR.label, 'totalErrorTests', StatusConstants.ERROR.color),
      new MChartDataMapping(StatusConstants.INCOMPLETE.label, 'totalIncompleteTests', StatusConstants.INCOMPLETE.color),
    ];
    return (
      <LineChart
        data={this.props.data}
        labelsMapping={labelsMapping}
        datasetsMapping={datasetsMapping}
        xLabel={this.props.xLabel}
        yLabel="Number of tests"
        stacked
      />
    );
  }
}

StatusAreaChart.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]).isRequired,
  xLabel: PropTypes.string.isRequired,
  chartLabelsDecorate: PropTypes.func,
  xAxis: PropTypes.string,
};

StatusAreaChart.defaultProps = {
  xAxis: 'id',
};

export default StatusAreaChart;
