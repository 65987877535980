import React from 'react';
import { isEmpty } from 'lodash';
import { Button, Col, Collapse, Row } from 'reactstrap';
import SavedPaymentMethod from './SavedPaymentMethod';
import { t } from '../../i18n/t';
import DeletePaymentMethodDialog from '../../components/dialog/DeletePaymentMethodDialog';
import PaymentForm from './PaymentForm';
import http from '../../utils/http';
import Apis from '../../utils/Apis';
import Notification from '../../utils/Notification';
import Services from '../../utils/Services';
import MContext from '../../models/MContext';

export default class PaymentMethodInformation extends React.Component {

  constructor(props) {
    super(props);
    this.deletePaymentMethodDialog = React.createRef();
    this.cardFormRef = React.createRef();

    this.organizationId = MContext.organizationId || props.organizationId;

    this.saveCard = this.saveCard.bind(this);
    this.refreshPaymentMethod = this.refreshPaymentMethod.bind(this);
    this.handleOpenUpdatePaymentMethod = this.handleOpenUpdatePaymentMethod.bind(this);
    this.handleDeletePaymentMethod = this.handleDeletePaymentMethod.bind(this);

    this.state = {
      paymentMethod: props.paymentMethod,
      isOpenPaymentMethod: isEmpty(props.paymentMethod)
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let { paymentMethod, isOpenPaymentMethod } = prevState;
    if (!paymentMethod && nextProps.paymentMethod !== paymentMethod) {
      paymentMethod = nextProps.paymentMethod;
      isOpenPaymentMethod = isEmpty(paymentMethod);
    }

    return {
      paymentMethod,
      isOpenPaymentMethod
    };
  }

  paymentMethodForm() {
    return this.cardFormRef.current && this.cardFormRef.current.getWrappedInstance();
  }

  handleOpenUpdatePaymentMethod() {
    this.setState((prevState) => ({
      isOpenPaymentMethod: !prevState.isOpenPaymentMethod
    }));
    this.handleChangeCardStatus();
  }

  refreshPaymentMethod() {
    Services.getPaymentMethod(this.organizationId, () => {
      this.setState({
        isOpenPaymentMethod: true
      });
    })
      .then((paymentMethod) => {
        this.setState({
          paymentMethod,
          isOpenPaymentMethod: isEmpty(paymentMethod)
        });
      });
  }

  saveCard(token, errorHandler) {
    if (token && token.token) {
      const cardToken = token.token.id;
      http.post(Apis.paymentMethod(this.organizationId), {
        cardToken
      }, {}, null, errorHandler)
        .then(() => {
          this.refreshPaymentMethod();
          this.handleChangeCardStatus();
        });
    } else {
      Notification.pushError(t('payment#error_invalid_card'));
    }
  }

  handleDeletePaymentMethod() {
    this.deletePaymentMethodDialog.current.toggle();
    this.paymentMethodForm().clearData();
  }

  handleChangeCardStatus() {
    if (this.props.onChangeCardStatus) {
      this.props.onChangeCardStatus();
    }
  }

  render() {
    const { paymentMethod, isOpenPaymentMethod } = this.state;
    const { showDelete, cancelLabel, fullColumn, submitLabel } = this.props;
    const { brand, expiration, last4 } = paymentMethod || {};
    return (
      <>
        {!isEmpty(paymentMethod) && (
          <div className="d-flex align-items-center">
            <SavedPaymentMethod
              brand={brand}
              expiration={expiration}
              last4={last4}
            />
            <Button
              className="ml-3"
              color="secondary"
              disabled={cancelLabel}
              onClick={this.handleOpenUpdatePaymentMethod}
            >{t('payment#update')}
            </Button>
            {showDelete &&
              <>
                <Button
                  className="ml-3"
                  color="danger"
                  onClick={this.handleDeletePaymentMethod}
                >{t('payment#delete')}
                </Button>
                <DeletePaymentMethodDialog
                  ref={this.deletePaymentMethodDialog}
                  title={t('delete')}
                  refreshData={this.refreshPaymentMethod}
                />
              </>}
          </div>
        )}
        <Collapse isOpen={isOpenPaymentMethod}>
          {!isEmpty(paymentMethod) && <hr className="mt-4" />}
          <Row>
            <Col sm="14" md="10" lg={fullColumn ? 12 : 6} xl={fullColumn ? 12 : 6}>
              <PaymentForm
                ref={this.cardFormRef}
                title={null}
                onTokenCreated={submitLabel && this.saveCard}
                onCancel={this.handleOpenUpdatePaymentMethod}
                {...this.props}
              />
            </Col>
          </Row>
        </Collapse>
      </>
    );
  }
}
