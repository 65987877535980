import React from 'react';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import { values } from 'lodash';
import { VIRTUAL_DATA_QUESTIONS } from '../../../utils/Constants';
import AIQuestionButton from './AIQuestionButton';
import { t } from '../../../i18n/t';
import { VirtualDataQuestionType } from '../../../utils/ConstantsType';

interface AIQuestionProps {
  openDrawer: (question: VirtualDataQuestionType) => void;
}

const valuesVirtualDataQuestions: VirtualDataQuestionType[] = values(VIRTUAL_DATA_QUESTIONS);

function AIQuestion({ openDrawer }: AIQuestionProps) {
  return (
    <>
      <div className="virtual-data-analyst-header">
        <div className="virtual-data-analyst-title">
          {t('ai-happening-now')}
          <AutoAwesomeIcon className="svg_icons" />
          <span className="virtual-data-analyst-banner-title">
            {t('ai')}
          </span>
        </div>
        <span className="trial-badge">{t('beta')}</span>
      </div>
      <div className="virtual-data-analyst-description">
        {t('ai-suggestions')}
      </div>
      {valuesVirtualDataQuestions.map((question: VirtualDataQuestionType) => (
        <div className="mt-2" key={question.promptType}>
          <AIQuestionButton question={question} openDrawer={openDrawer} />
        </div>
      ))}
    </>
  );
}

export default AIQuestion;
