import ListItemText from '@mui/material/ListItemText';
import filesize from 'filesize';
import React from 'react';
import Gallery from '../../../components/gallery/Gallery';
import Services from '../../../utils/Services';
import ExecutionContext from './ExecutionContext';

function searchAttachmentFile(executionId, fullPath) {
  return Services.search({
    pagination: {
      page: 0,
      size: 1,
      sorts: ['fileName,asc'],
    },
    conditions: [
      {
        key: 'Execution.id',
        operator: '=',
        value: executionId,
      },
      {
        key: 'fullPath',
        operator: '=',
        value: fullPath,
      },
    ],
    type: 'ExecutionFile',
  });
}

function buildGalleryItemFromFileHandleResource(fileHandleResource) {
  const { url, name, size } = fileHandleResource;
  return {
    source: url,
    caption: `${name} (${filesize(size)})`,
    fileType: name.split('.')
      .pop(),
  };
}

const LogInfo = ({ logRecord }) => {
  const { message = '', type, attachment } = logRecord;
  const [galleryItems, setGalleryItems] = React.useState([]);
  const execution = React.useContext(ExecutionContext);

  React.useEffect(() => {
    if (type === 'MESSAGE' && attachment) {

      searchAttachmentFile(execution.id, attachment)
        .then(({ content }) => {
          setGalleryItems(content.map(buildGalleryItemFromFileHandleResource));
        });
    }
  }, [attachment, type, execution.id]);

  return (
    <>
      <ListItemText className="ml-3">
        <pre>
          {message.trim()}
        </pre>
      </ListItemText>
      {galleryItems?.length > 0 && <Gallery images={galleryItems} data-trackid="view-test-step-attachment" />}
    </>
  );
};

export default LogInfo;
