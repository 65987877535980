import React from 'react';
import MTableColumnDataMapping from '../../components/table/models/MTableColumnDataMapping';
import Routes from '../../utils/Routes';
import DecoratorConstants from '../../utils/DecoratorConstants';
import { t } from '../../i18n/t';
import DataTable from './DataTable';
import Arrays from '../../utils/Arrays';
import MFlags from '../../models/MFlags';
import { TestSuiteType } from '../../utils/Constants';

class ExecutionTestSuiteDataTable extends React.Component {

  render() {
    const { showStartTime } = this.props;
    const columnMapping = [
      new MTableColumnDataMapping(
        t('status'),
        'status',
        DecoratorConstants.statusDecorator,
        undefined,
        'fit-content-column',
      ),
      new MTableColumnDataMapping(
        t('name'),
        'testSuite.name',
        (name, row) => {
          const constructedLink = new Routes({
            testSuiteId: row.testSuite.id,
          });
          const fullPath = row.testSuite.path
            ? `${row.testSuite.path}/${row.testSuite.name}`
            : row.testSuite.name;
          const testSuiteType = row?.testSuite?.type;
          return testSuiteType === TestSuiteType.CLOUD_STUDIO ?
            DecoratorConstants.idCloudStudioDecorator(name, row, constructedLink.execution_test_suite_history_link, fullPath)
            : DecoratorConstants.idDecorator(name, row, constructedLink.execution_test_suite_history_link, fullPath);
        },
      ),
      new MTableColumnDataMapping(
        t('table-header#profile'),
        'profiles',
        (name, row) => {
          const testSuiteType = row?.testSuite?.type;
          return MFlags.testCloudG5TestSuiteEnabled && testSuiteType === TestSuiteType.CLOUD_STUDIO ? <></> : DecoratorConstants.profileDecorator(name, row);
        }
      ),
      new MTableColumnDataMapping(
        t('table-header#platform'),
        'platform',
        DecoratorConstants.platformDecorator,
      ),
      ...Arrays.insertIf(showStartTime, new MTableColumnDataMapping(
        t('table-header#startTime'),
        'startTime',
        DecoratorConstants.timeDecorator,
      )),
      new MTableColumnDataMapping(
        t('table-header#duration'),
        '',
        DecoratorConstants.timeAndDurationDecorator,
      ),
      new MTableColumnDataMapping(
        t('table-header#total'),
        'totalTests',
        DecoratorConstants.totalTestRunDecorator,
      ),
      new MTableColumnDataMapping(
        DecoratorConstants.passedBadgeDecorator(t('table-header#totalPassed')),
        'totalPassedTests',
        DecoratorConstants.passedCountDecorator,
      ),
      new MTableColumnDataMapping(
        DecoratorConstants.failedBadgeDecorator(t('table-header#totalFailed')),
        'totalFailedTests',
        DecoratorConstants.failedCountDecorator,
      ),
      new MTableColumnDataMapping(
        DecoratorConstants.errorBadgeDecorator(t('table-header#totalError')),
        'totalErrorTests',
        DecoratorConstants.errorCountDecorator,
      ),
      new MTableColumnDataMapping(
        DecoratorConstants.incompleteBadgeDecorator(t('table-header#totalIncomplete')),
        'totalIncompleteTests',
        DecoratorConstants.incompleteCountDecorator,
      ),
      new MTableColumnDataMapping(
        DecoratorConstants.skippedBadgeDecorator(t('table-header#totalSkipped')),
        'totalSkippedTests',
        DecoratorConstants.skippedCountDecorator,
      ),
      new MTableColumnDataMapping(
        '',
        'hasComment',
        DecoratorConstants.totalCommentDecorator,
      ),
    ];
    const newProps = {
      columnMapping,
      ...this.props,
    };
    return (
      <DataTable
        entityType="ExecutionTestSuite"
        ref={(ref) => {
          this.executionList = ref;
        }}
        {...newProps}
      />
    );
  }
}

export default ExecutionTestSuiteDataTable;
