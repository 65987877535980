import React from 'react';
import { get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import MTableColumnDataMapping from '../../components/table/models/MTableColumnDataMapping';
import { t } from '../../i18n/t';
import DecoratorConstants from '../../utils/DecoratorConstants';
import { buildDefaultFilter, buildFilter } from '../search-query/FilterQueryHelper';
import DataTable from './DataTable';
import { TEST_CASE_PAGE_SIZE, KATALON_EVENTS, TEST_TYPE, TestProjectType } from '../../utils/Constants';
import Arrays from '../../utils/Arrays';
import UserFilterV2 from '../search-query/filter/UserFilterV2';
import TimeFilterV2 from '../search-query/filter/TimeFilterV2';
import {
  buildSortQueryV2,
  buildSortQueryWithNull
} from '../search-query/SortQueryHelper';
import FlakinessFilter from '../search-query/filter/FlakinessFilter';
import DurationFilter from '../search-query/filter/DurationFilter';
import { SearchOperator } from '../../utils/SearchOperator';
import CustomFieldFilterField from '../search-query/filter/CustomFieldFilterField';
import TagFilter from '../search-query/filter/TagFilter';
import FixedActionMenu from '../cloud-studio-component/menu/FixedActionMenu';
import { DomEventHandlers } from '../../utils/EventHandler';
import VerticalTestManagementActions from '../cloud-studio-component/menu/VerticalTestManagementActions';

class TestCaseDataTableV2 extends React.Component {

  constructor(props) {
    super(props);
    this.dataLoaderRef = React.createRef();
    this.dataTableRef = React.createRef();
    this.handleRefresh = this.handleRefresh.bind(this);
    this.handleSingleSelectTestCase = this.handleSingleSelectTestCase.bind(this);
    this.resetSelectedTestCase = this.resetSelectedTestCase.bind(this);
    this.renderTestManagementActions = this.renderTestManagementActions.bind(this);
    this.isSupportMultipleSelect = this.isSupportMultipleSelect.bind(this);
  }

  componentDidMount() {
    DomEventHandlers.eventListener(KATALON_EVENTS.createG5TestCase, this.handleRefresh);
    DomEventHandlers.eventListener(KATALON_EVENTS.duplicatedG5TestCase, this.handleRefresh);
    DomEventHandlers.eventListener(KATALON_EVENTS.renamedG5TestCase, this.handleRefresh);
    DomEventHandlers.eventListener(KATALON_EVENTS.deletedG5TestCase, this.handleRefresh);
    DomEventHandlers.eventListener(KATALON_EVENTS.addTestCaseToTestSuite, this.handleRefresh);
  }

  resetSelectedTestCase() {
    if (this.dataTableRef.current) {
      this.dataTableRef.current.setSelectedRows([]);
    }
  }

  handleRefresh() {
    this.resetSelectedTestCase();
    if (this.dataLoaderRef.current) {
      this.dataLoaderRef.current.refreshData();
    }
  }

  hasAtLeastOneTestCaseSelected() {
    const selectedTestCases = this.getSelectedRows();
    return selectedTestCases.length > 0;
  }

  getSelectedRows() {
    const { isSupportMultipleSelect } = this.props;
    if (!isSupportMultipleSelect || !this.dataTableRef.current) {
      return [];
    }
    return this.dataTableRef.current.getSelectedRows();
  }
  isTestCaseSelected(testCase) {
    const selectedTestCases = this.getSelectedRows();
    return selectedTestCases.some((item) => item.id === testCase.id);
  }

  isSupportMultipleSelect(data) {
    const { isSupportMultipleSelect } = this.props;
    if (data.length === 0) return false;
    const testCase = data[0];
    if (!testCase.testProject) return false;
    const isG5CloudTestCase = testCase.testType === TEST_TYPE.G5_TEST_CASE && testCase.testProject.type === TestProjectType.CLOUD;
    const isG4TestCase = testCase.testType === TEST_TYPE.G4_TEST_CASE;
    return isSupportMultipleSelect && (isG4TestCase || isG5CloudTestCase);
  }

  handleSingleSelectTestCase(data) {
    const { isSupportMultipleSelect } = this.props;

    if (isSupportMultipleSelect && this.hasAtLeastOneTestCaseSelected()) {
      this.dataTableRef.current.setSelectedRows([data.testCase]);
    }
  }

  renderTestManagementActions(selectedTestCases) {
    if (isEmpty(selectedTestCases)) {
      return null;
    }
    const testEntities = selectedTestCases.map((item) => ({ testCase: item }));

    return <VerticalTestManagementActions data={testEntities} />;
  }

  render() {
    const { tcNameTrackingProps, isSearchResultsPage } = this.props;

    const headers = [
      new MTableColumnDataMapping(
        '',
        'lastExecutionTestCase.status',
        (name, row) => {
          if (row.lastExecutionTestCase) {
            return DecoratorConstants.statusDecorator(name, row);
          } else {
            return null;
          }
        },
        undefined,
        'fit-content-column',
        undefined
      ),
      new MTableColumnDataMapping(
        t('name'),
        'name',
        (name, row) => DecoratorConstants.testCaseNameDecorator(name, row, tcNameTrackingProps, true),
        'short-text-column',
        '',
        buildSortQueryV2('name', true),
        isSearchResultsPage ? '20%' : '30%',
      ),
      ...Arrays.insertIf(
        isSearchResultsPage, (
          new MTableColumnDataMapping(
            t('path'),
            '',
            (name, row) => {
              const sourcePath = DecoratorConstants.addSourceOfTestCaseAndTestSuite(row.path, row.testProject);
              return DecoratorConstants.textMiddleTruncateDecorator(sourcePath);
            },
            undefined,
            '',
            undefined,
            '20%'
          )
        )
      ),
      new MTableColumnDataMapping(
        t('maintainer'),
        'maintainer',
        (name, row) => {
          const value = get(row, name);
          if (value) {
            return DecoratorConstants.renderUserWithoutNameDecorator(value);
          }
          return null;
        }
      ),
      new MTableColumnDataMapping(
        t('last_executed'),
        'lastExecutionTestCase.startTime',
        (name, row) => {
          if (row.lastExecutionTestCase) {
            return DecoratorConstants.timeDecorator(name, row);
          } else {
            return null;
          }
        },
        undefined,
        'nowrap-column',
        buildSortQueryWithNull('ExecutionStatistics.startTime')
      ),
      new MTableColumnDataMapping(
        t('last_updated'),
        'updatedAt',
        (name, row) => {
          if (row.updatedAt) {
            return DecoratorConstants.timeDecorator(name, row);
          } else {
            return null;
          }
        },
        undefined,
        'nowrap-column',
        buildSortQueryV2('updatedAt')
      ),
      new MTableColumnDataMapping(
        t('table-header#number-of-executions'),
        'numberOfExecutions',
        (name, row) => get(row, name),
        undefined,
        'nowrap-column',
        buildSortQueryV2('numberOfExecutions')
      ),
      new MTableColumnDataMapping(
        t('table-header#average-duration'),
        'averageDuration',
        DecoratorConstants.durationDecorator,
        undefined,
        '',
        buildSortQueryWithNull('averageDuration')
      ),
      new MTableColumnDataMapping(
        t('table-header#flakiness'),
        'flakiness',
        DecoratorConstants.flakinessDecorator,
        undefined,
        'nowrap-column',
        buildSortQueryWithNull('flakiness'),
      ),
      new MTableColumnDataMapping(
        t('table-header#requirements'),
        'externalIssues',
        DecoratorConstants.linkJiraIssuesDecorator,
      ),
      ...Arrays.insertIf(
        !isSearchResultsPage,
        new MTableColumnDataMapping(
          t('table-header#action'),
          'id',
          (name, row) => {
            const id = get(row, name);
            const actionData = { testCase: row };
            return (
              <FixedActionMenu
                id={id}
                data={actionData}
                disabledButton={this.isTestCaseSelected(row) && this.getSelectedRows().length > 1}
                handleClick={this.handleSingleSelectTestCase}
              />
            );
          },
          true,
        )
      ),
    ];

    const defaultFilter = buildDefaultFilter('name', '~', 'Name');
    const filterQuery = [
      buildFilter(UserFilterV2, { id: 'User.id',
        label: t('search-bar#maintainer'),
        placeholder: t('search-bar#placeholder-maintainer') }),
      buildFilter(FlakinessFilter, { id: 'flakiness',
        label: t('search-bar#flakiness'),
        operator: SearchOperator.GREATER_OR_EQUAL,
        itemTitle: t('search-bar#more-than') }),
      buildFilter(DurationFilter, { id: 'averageDuration',
        label: t('search-bar#average-duration'),
        placeholder: t('search-bar#duration-placeholder') }),
      buildFilter(TimeFilterV2, { id: 'ExecutionStatistics.startTime', label: t('search-bar#last-executed') }),
      buildFilter(TimeFilterV2, { id: 'updatedAt', label: t('search-bar#last-updated') }),
      buildFilter(CustomFieldFilterField, { id: 'CustomField', label: t('custom-fields#title') }),
      buildFilter(TagFilter, { id: 'Tag.id', label: t('search-bar#tag') }),
    ];
    const newProps = {
      ...this.props,
      filterQuery,
      defaultFilter,
    };

    return (
      <DataTable
        columnMapping={headers}
        entityType="TestCase"
        unitName="test case"
        useSearchQuery
        useNewSearchBar
        useSortByColumn
        pageSize={TEST_CASE_PAGE_SIZE}
        placeHolder={t('search#test-case')}
        fixLayout
        isFromTestCase
        ref={this.dataLoaderRef}
        dataTableRef={this.dataTableRef}
        {...newProps}
        renderBulkActions={this.renderTestManagementActions}
        isSupportMultipleSelect={this.isSupportMultipleSelect}
      />
    );
  }
}

TestCaseDataTableV2.propTypes = {
  isSearchResultsPage: PropTypes.bool,
  tcNameTrackingProps: PropTypes.object,
  isSupportMultipleSelect: PropTypes.bool,
};

TestCaseDataTableV2.defaultProps = {
  tcNameTrackingProps: null,
  isSearchResultsPage: false,
  isSupportMultipleSelect: false,
};

export default TestCaseDataTableV2;
