import React, { Component } from 'react';
import { ResponsiveContainer, ComposedChart, XAxis, Tooltip, Line } from 'recharts';
import { Col, Row } from 'reactstrap';
import _ from 'lodash';
import moment from 'moment';
import { t } from '../../i18n/t';
import Time from '../../utils/Moment';
import { buildSearchCondition } from '../search/SearchUtils';
import DataLoader from '../table/DataLoader';
import StatusDoughnutChart from './StatusDoughnutChart';
import Statuses from '../../utils/Statuses';
import StatusBarInfo from './status-bar/StatusBarInfo';
import SelectBoxTime from '../../pages/project_dashboard/components/SelectBoxTime';
import StatisticsUtils from '../../pages/project_dashboard/components/StatisticsUtils';

const RotatedAxisTick = ({ x, y, payload }) => {
  const date = Time.formatDate(payload.value, Time.DATE_OF_WEEK_FORMAT, true);
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-35)">{date}</text>
    </g>
  );
};

class NumberJobsChart extends Component {
  constructor(props) {
    super(props);
    this.dataRef = React.createRef();
    this.renderChart = this.renderChart.bind(this);
    this.tooltipFormatter = this.tooltipFormatter.bind(this);
    this.renderData = this.renderData.bind(this);
    this.renderDailyJobChart = this.renderDailyJobChart.bind(this);
    this.renderJobExecutedOverview = this.renderJobExecutedOverview.bind(this);
    this.setWeek = this.setWeek.bind(this);

    this.state = {
      week: 1,
    };
  }

  calcDiff(statistics, property) {
    const current = _.get(statistics, property);
    const previous = _.get(statistics, `previousStatistics.${property}`);
    if (!previous) return null;
    const percentage = (((current - previous) * 100) / previous).toFixed(1);
    return percentage;
  }

  setWeek(week) {
    this.setState({ week: week.value });
  }

  renderCustomControl() {
    const { week } = this.state;
    return <SelectBoxTime week={week} setWeek={this.setWeek} />;
  }

  tooltipFormatter(value, name) {
    if (name === 'totalAgentDuration') return [Time.duration(value), t(name)];
    else return [value, t(name)];
  }

  timeFormatter(value) {
    return Time.formatDate(value, Time.LAST_MONTHS_FORMAT, true);
  }

  renderChart(data) {
    return (
      <ResponsiveContainer width="90%" minWidth={200} aspect={2}>
        <ComposedChart data={data} margin={{ bottom: 25, top: 10 }}>
          <XAxis
            tickFormatter={this.timeFormatter}
            dataKey="keyDate"
            tick={<RotatedAxisTick />}
            interval={0}
          />
          <Tooltip
            labelFormatter={this.timeFormatter}
            formatter={this.tooltipFormatter}
          />
          <Line yAxisId="totalAgentDuration" dataKey="totalAgentDuration" />
        </ComposedChart>
      </ResponsiveContainer>
    );
  }

  getTotalJobs(jobs) {
    return [
      {
        key: `$passed-job-${jobs.passedJob}`,
        label: Statuses.PASSED.label,
        value: jobs.passedJob,
        color: 'passed',
        diff: this.calcDiff(jobs, 'passedJob'),
      },
      {
        key: `$failed-job-${jobs.failedJob}`,
        label: Statuses.FAILED.label,
        value: jobs.failedJob,
        color: 'failed',
        diff: this.calcDiff(jobs, 'failedJob')
      },
      {
        key: `$error-job-${jobs.errorJob}`,
        label: Statuses.ERROR.label,
        value: jobs.errorJob,
        color: 'error',
        diff: this.calcDiff(jobs, 'errorJob')
      },
    ];
  }

  renderDailyJobChart(data, from, to) {
    const convertedData = StatisticsUtils.fillEmptyDaysInWeek(data, from, to)
    return this.renderChart(convertedData);
  }

  renderDailyJob() {
    const { week } = this.state;
    const startOfWeek = StatisticsUtils.startOfWeek(week);
    const endOfWeek = StatisticsUtils.endOfWeek(week);

    return (
      <DataLoader
        key={`daily-job-chart-${week}`}
        entityType="CiDashboardStatistics"
        render={(data) => this.renderDailyJobChart(data, startOfWeek, endOfWeek)}
        defaultSort={['keyDate, asc']}
        title="Job Summary"
        defaultSearchConditions={[
          buildSearchCondition('statisticsType', '=', 'DAILY'),
          buildSearchCondition('keyDate', '>=', startOfWeek),
          buildSearchCondition('keyDate', '<=', endOfWeek)
        ]}
        hidePaging
        noCard
      />
    );
  }

  renderJobExecutedOverview(jobStatistics) {
    return (
      <StatusDoughnutChart
        key={`job-chart-${jobStatistics.id}`}
        data={this.getTotalJobs(jobStatistics)}
        type="Job"
        total={jobStatistics.totalJob}
        diff={this.calcDiff(jobStatistics, 'totalJob')}
      />
    );
  }

  renderAvgAgentDuration(jobStatistics) {
    const data = [
      {
        key: `avg-duration-${jobStatistics.id}`,
        value: Time.duration(jobStatistics.averageAgentDuration),
        label: 'Average Duration',
      },
    ];
    return (
      <div className="d-flex flex-column ">
        <StatusBarInfo
          key={`duration-status-${jobStatistics.id}`}
          data={data}
        />
      </div>
    );
  }

  renderCiStatistics(data) {
    const jobStatistics = data[0];
    return (
      <>
        <Row className="align-items-center ml-2">
          {this.renderAvgAgentDuration(jobStatistics)}
        </Row>
        <Row className="align-items-center justify-content-center">
          {this.renderDailyJob()}
        </Row>
      </>
    );
  }

  renderEmptyMessage() {
    return (
      <>
        <Row className="align-items-center justify-content-center">
          <Row>
            <Col sm="12" md="12">
              <div className="text-center"> {t('table#empty-message')} </div>
            </Col>
          </Row>
        </Row>
      </>
    );
  }

  renderData(data) {
    if (data.length > 0) {
      return this.renderCiStatistics(data);
    } else {
      return this.renderEmptyMessage();
    }
  }

  render() {
    const { week } = this.state;
    const weeklyKey = StatisticsUtils.weeklyKey(week);

    return (
      <DataLoader
        key={`ci-project-statistics-week-${week}`}
        ref={this.dataRef}
        entityType="CiDashboardStatistics"
        render={this.renderData}
        defaultSort={['keyDate, desc']}
        defaultSearchConditions={[
          buildSearchCondition('statisticsType', '=', 'WEEKLY'),
          buildSearchCondition('keyDate', '=', weeklyKey),
        ]}
        pageSize={1}
        hidePaging
        title="Job Summary"
        customControl={this.renderCustomControl()}
      />
    );
  }
}

export default NumberJobsChart;
