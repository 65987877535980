import { Form, Label } from 'reactstrap';
import React, { useState } from 'react';
import EditCustomField from './EditCustomField';
import { CUSTOM_FIELD_ENTITY_TYPE, TAG_ENTITY_TYPE } from '../utils/Constants';
import { t } from '../i18n/t';
import Services from '../utils/Services';
import ExecutionLinkedIssues from './ExecutionLinkedIssues';
import TagTsx from './tsx-components/TagTsx';

const ExecutionCustomFieldAndTag = (props) => {

  const { execution } = props;
  const [selectedCustomFields, setSelectedCustomFields] = useState([]);

  const handleUpdateCustomFields = (selectedCustomFields, shouldCallApiToUpdate = true) => {
    if (!shouldCallApiToUpdate) {
      setSelectedCustomFields(selectedCustomFields);
    } else {
      const customFieldOptions = selectedCustomFields.map((selectedCustomField) => ({
        id: selectedCustomField.customFieldOption.id,
        definitionId: selectedCustomField.customFieldDefinition.id
      }));
      Services.updateExecutionCustomFields(execution.id, customFieldOptions)
        .then(() => {
          setSelectedCustomFields(selectedCustomFields);
        });
    }
  };

  const handleAddNewTag = (addedTag) => Services.updateExecutionTag(execution.id, addedTag);

  const handleDeleteTag = (deletedTag) => Services.deleteExecutionTag(execution.id, deletedTag);

  const renderLinkedXrayTestExecution = () => <ExecutionLinkedIssues executionId={execution.id} />;

  return (
    <Form>
      {renderLinkedXrayTestExecution()}
      <EditCustomField
        maxCharacterTruncate={15}
        keyPaperWidth={200}
        optionPaperWidth={150}
        entityId={execution.id}
        entityType={CUSTOM_FIELD_ENTITY_TYPE.EXECUTION}
        setSelectedCustomFields={handleUpdateCustomFields}
        selectedCustomFields={selectedCustomFields}
      />
      <>
        <Label for="tag">{t('tag#title')}</Label>
        <TagTsx
          ignoreWidthSize
          entityId={execution.id}
          entityType={TAG_ENTITY_TYPE.EXECUTION}
          handleOnSelectChange={handleAddNewTag}
          handleOnDeleteTag={handleDeleteTag}
        />
      </>
    </Form>
  );
};

export default ExecutionCustomFieldAndTag;
