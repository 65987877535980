import jquery from 'jquery';
import parse from 'url-parse';
import http from '../utils/http';
import Apis from '../utils/Apis';
import RouteConstants from '../utils/RouteConstants';
import Config from '../Config';
import { analyticsIdentify, removeProjectTrackingInformation } from '../utils/SegmentAnalytics';
import MConfigs from './MConfigs';
import { AccessToken } from '../utils/Constants';
import UserService from '../auth/UserService';
import MFlags from './MFlags';


class MAuth {

//   loginSSO() {
//     return http.get(Apis.samlSSO)
//       .then((result) => result);
//   }

  login(user, errorHandler) {
    if (MConfigs.isPre || MFlags.ignoreTestOpsToken) {
      return this.getMe();
    }

    const headers = {
      Authorization: Config.auth.getHeader(),
    };
    const data = {
      username: user.username,
      password: user.password,
      grant_type: Config.auth.grant_type,
      rememberMe: user.rememberMe,
      webLogin: true,
      reCaptchaResponse: user.reCaptchaResponse,
    };
    return http.post(Apis.token, data, headers, true, errorHandler)
      .then(() => this.getMe());
  }

  async fetchToken() {
    const result = await http.get(Apis.userToken);
    this.kcToken = result?.accessToken;
    return result;
  }

  async fetchAccessToken() {
    const result = await http.get(Apis.userTestopsAccessToken);
    this.testOpsAccessToken = result?.accessToken;
    return result;
  }

  getMe(useCache) {
    console.log('Katalon: Get me ', useCache, window.location.href);
    let p;
    if (useCache) {
      const cache = jquery('#api-get-me');
      if (cache.length > 0) {
        const json = cache.text();
        if (json.indexOf('{') >= 0) {
          p = new Promise((resolve) => {
            resolve(JSON.parse(json));
          });
        }
      }
    }
    if (!p) {
      p = http.get(Apis.usersMe);
    }
    return p.then((responseJson) => this.saveUser(responseJson));
  }

  saveUser(user) {
    console.log('Katalon: Save user', user);
    if (user && user.id) {
      this.user = user;
      const {
        id,
        email,
        fullName,
        firstName,
        lastName,
        avatar,
        configs,
      } = this.user;

      if (!MConfigs.isPre && !MFlags.removeUnusedFieldsGetMeApiEnabled) {
        MConfigs.parse(configs);
      }
      analyticsIdentify({ id, email, fullName, firstName, lastName, avatar });
    } else {
      this.user = null;
      if (!MConfigs.isPre && !MFlags.removeUnusedFieldsGetMeApiEnabled) {
        if (user) {
          const { configs } = user;
          MConfigs.parse(configs);
        }
      }
    }
  }

  isSystemRole() {
    return !!(this.user.systemRole && this.user.systemRole === 'KATALON_ADMIN');
  }

  isTeamManager(teamId) {
    const teamRole = this.getTeamRole(teamId);
    return teamRole === 'ADMIN' || teamRole === 'OWNER';
  }

  isTeamOwner(teamId) {
    const teamRole = this.getTeamRole(teamId);
    return teamRole === 'OWNER';
  }

  isTeamDemo(teamId) {
    // user does not belong to team => team demo
    const currentTeam = this.getTeam(teamId);
    return !currentTeam;
  }

  /**
   * Only use this function when don't have teamId
   */
  isProjectDemo(projectId) {
    // user does not has project => project demo
    const currentProject = this.getProject(projectId);
    return !currentProject;
  }

  getTeam(teamId) {
    const teamIdAsNumber = parseInt(teamId, 10);
    return this.teams.find((t) => t.id === teamIdAsNumber);
  }

  getProject(projectId) {
    const projectIdAsNumber = parseInt(projectId, 10);
    return this.projects.find((p) => p.id === projectIdAsNumber);
  }

  getTeamRole(teamId) {
    let teamRole = 'USER';
    const { teams } = this.user;
    if (teams && teams.length) {
      const teamIdAsNumber = parseInt(teamId, 10);
      const team = teams.filter((t) => t.id === teamIdAsNumber)[0];
      if (team) {
        teamRole = team.role;
      }
    }

    return teamRole;
  }

  isOrganizationManager(organizationId) {
    const organizationRole = this.getOrganizationRole(organizationId);
    return organizationRole === 'ADMIN' || organizationRole === 'OWNER';
  }

  isOrganizationOwner(organizationId) {
    const organizationRole = this.getOrganizationRole(organizationId);
    return organizationRole === 'OWNER';
  }

  isBillingManager(organizationId) {
    const organizationRole = this.getOrganizationRole(organizationId);
    return ['OWNER', 'BILLING_MANAGER'].includes(organizationRole);
  }

  getOrganization(organizationId) {
    const { organizations } = this.user;
    if (organizations && organizations.length) {
      const id = parseInt(organizationId, 10);
      return organizations.filter((t) => t.id === id)[0];
    }
    return null;
  }

  getOrganizationRole(organizationId) {
    let organizationRole = 'USER';
    const organization = this.getOrganization(organizationId);
    if (organization) {
      organizationRole = organization.role;
    }
    return organizationRole;
  }

  goToLoginPage(redirectParams) {
    if (redirectParams?.get('otp') || redirectParams?.get('katone_access_token')) {
      console.log('Go to login page');
      window.location.href = `/login?${redirectParams.toString()}`;
    } else {
      console.log('Go to universal login page');
      const redirectURL = redirectParams?.get('redirect');
      UserService.login(redirectURL);
    }
  }

  isRelative(url) {
    return url && url.match(/^\/[^\/\\]/);
  }

  navigate() {
    const url = parse(window.location.href, true);
    const redirectEncoded = url.query.redirect;
    if (redirectEncoded) {
      const redirectDecoded = window.decodeURIComponent(redirectEncoded);
      if (!this.isRelative(redirectDecoded)) {
        // prevent redirect attack
        // redirect to home
        window.location.href = RouteConstants.home;
      } else {
        window.location.href = redirectDecoded;
      }
    } else {
      window.location.href = RouteConstants.home;
    }
  }

  authenticateAndNavigate = (keyCloakAccessToken) => {
    this.login({
      username: '',
      password: `access_token_${keyCloakAccessToken}`,
    })
      .then(() => {
        this.isLoggedIn && this.navigate();
      })
      .catch((error) => {
        console.error(error);
        this.logout();
      });
  }

  authenticate = (keyCloakAccessToken) => {
    return this.login({
      username: '',
      password: `access_token_${keyCloakAccessToken}`,
    });
  }

  logout = (keycloak = true) => {
    if (window.location.pathname !== '/login') {
      console.log('Katalon: Logout');

      // Build redirect url
      const urlString = window.location.href;
      const url = new URL(urlString);
      const searchParams = new URLSearchParams(url.search);
      const otp = searchParams.get('otp');
      searchParams.delete('otp');
      const accessToken = searchParams.get(AccessToken.PARAM_NAME);
      searchParams.delete(AccessToken.PARAM_NAME);
      const redirectURL = window.encodeURIComponent(`${window.location.pathname}?${searchParams.toString()}`);
      const redirectParams = new URLSearchParams();
      redirectParams.set('redirect', redirectURL);
      otp && redirectParams.set('otp', otp);
      accessToken && redirectParams.set(AccessToken.PARAM_NAME, accessToken);

      // Clear local storage
      this.user = null;
      localStorage.removeItem('isHiddenWarning');
      MConfigs.isOnPremise && localStorage.removeItem('k1.login');
      removeProjectTrackingInformation();

      // Logout
      const p1 = keycloak ? UserService.logout() : Promise.resolve();
      const p2 = MConfigs.isPre || MFlags.ignoreTestOpsToken ? Promise.resolve() : http.get(Apis.logout, null, null, 'text', null, null, false);
      return Promise.all([p1, p2]).then(() => {
        if (window.location.pathname !== '/logout' && window.location.pathname !== '/authenticate') {
          this.goToLoginPage(redirectParams);
        } else {
          this.goToLoginPage();
        }
      }, () => {
        this.goToLoginPage(redirectParams);
      });
    }
  }

  get token() {
    return this.kcToken;
  }

  get organizations() {
    const user = this.user;
    if (user == null) {
      return [];
    } else {
      return user.organizations;
    }
  }

  get teams() {
    const user = this.user;
    if (user == null) {
      return [];
    } else {
      return user.teams;
    }
  }

  get projects() {
    const user = this.user;
    if (user == null) {
      return [];
    } else {
      return user.projects;
    }
  }

  get accessToken() {
    return UserService.getAccessToken();
  }

  get email() {
    const user = this.user;
    if (user == null) {
      return null;
    } else {
      return user.email;
    }
  }

  get isLoggedIn() {
    return this.user != null;
  }

//   get isBusinessUser() {
//     return this.user.businessUser;
//   }

  get canCreateOfflineKSE() {
    return this.user.canCreateOfflineKSE;
  }

  get canCreateOfflineRE() {
    return this.user.canCreateOfflineRE;
  }
}

const mauth = new MAuth();

export default mauth;
