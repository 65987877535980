import React from 'react';
import PropTypes from 'prop-types';
import { Table, TableHead, TableBody, TableRow, TableCell, TableContainer } from '@mui/material';
import jquery from 'jquery';
import classnames from 'classnames';
import { next } from '../../../utils/Count';
import 'jquery-ui';
import 'jquery-ui/ui/widgets/button';
import 'jquery-ui/ui/effects/effect-highlight';
import 'jquery-ui/ui/widgets/datepicker';
import { t } from '../../../i18n/t';
import {
  IconLongArrowDownActive, IconLongArrowUp,
  IconLongArrowUpActive
} from '../../../images/CustomIcon';
import TooltipComponent from '../../TooltipComponent';
import { SORT_TYPE } from '../../../utils/Constants';

window.$ = jquery;
window.jQuery = jquery;
require('structured-filter');

class TableSortCore extends React.Component {

  constructor(props) {
    super(props);
    const { sortedKey, sortedMode } = this.props.currentSort;

    this.state = {
      sortedKey,
      sortedMode,
    };
  }

  // get sort mode will be change
  getBeSortedMode(fieldKey) {
    const { sortedMode, sortedKey } = this.state;
    if (sortedKey === fieldKey && sortedMode !== SORT_TYPE.DESC) {
      return SORT_TYPE.DESC;
    }
    return SORT_TYPE.ASC;
  }

  // get tooltip text for sort mode will be change
  generateBeSortedText(sortQuery) {
    const sortedMode = this.getBeSortedMode(sortQuery.key);

    return sortQuery[sortedMode].label;
  }

  renderRows(headers, rows) {
    return (
      rows.length > 0
        ? rows
        : this.renderEmptyMessage(headers)
    );
  }

  renderEmptyMessage(headers) {
    const { emptyMessage } = this.props;
    return (
      <TableRow>
        <TableCell className="sort-table-empty-message" colSpan={headers.length}>{emptyMessage}</TableCell>
      </TableRow>
    );
  }

  handleColumnHeaderClick(sortQuery) {
    if (!sortQuery) {
      return;
    }
    const { onColumnHeaderClick } = this.props;
    const sortedMode = this.getBeSortedMode(sortQuery.key);
    const sortedKey = sortQuery.key;
    this.setState({ sortedKey, sortedMode }, () => {
      if (onColumnHeaderClick) {
        onColumnHeaderClick(sortQuery[sortedMode].key, { sortedKey, sortedMode });
      }
    });
  }

  renderSortIcon(fieldKey) {
    const { sortedKey, sortedMode } = this.state;
    if (fieldKey === sortedKey) {
      if (sortedMode === SORT_TYPE.ASC) {
        return (
          <IconLongArrowUpActive />
        );
      }
      return <IconLongArrowDownActive />;
    }

    return <span className="be-sorted-icon"><IconLongArrowUp /></span>;
  }

  renderHeaderContent(headerCaption, sortQuery) {
    return (
      <div className={`${sortQuery && 'clickable-header'}`} onClick={() => this.handleColumnHeaderClick(sortQuery)}>
        {headerCaption}
        {sortQuery && <span className="sort-icon">{this.renderSortIcon(sortQuery.key)}</span>}
      </div>
    );
  }

  renderData(data) {
    const { transform, className, fixLayout, stickyHeader, handleSelectRow, isTableContainer } = this.props;
    let { columnMapping } = this.props;
    const { underRowComponent } = this.props;

    if (transform) {
      [data, columnMapping] = transform(data, columnMapping);
    }

    const rows = data.slice().map((row) => {
      const cols = columnMapping.map((col) => (
        <TableCell key={col.fieldName} className={col.className}>{col.decorate(col.fieldName, row)}</TableCell>
      ));
      if (underRowComponent) {
        const UnderRowComponent = underRowComponent.component;
        const componentProps = underRowComponent.props;
        return (
          <>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} key={next()}>{cols}</TableRow>
            <TableRow key={next()}>
              <TableCell className="table-row-under-component" colSpan={6} key={next()}>
                <UnderRowComponent
                  row={row}
                  {...componentProps}
                />
              </TableCell>
            </TableRow>
          </>
        );
      }
      if (handleSelectRow) {
        return <tr key={row.id} className={classnames(row.isSelected && 'selected')} role="button" onClick={() => handleSelectRow(row)}>{cols}</tr>;
      }
      return <TableRow key={row.id} className={classnames(row.isSelected && 'selected')}>{cols}</TableRow>;
    });

    const headers = columnMapping.map(({ className, sortQuery, headerCaption, width }) =>
      <TableCell
        style={{ width }}
        key={next()}
        className={`${className}`}
      >
        {sortQuery ? (
          <TooltipComponent placement="top" text={this.generateBeSortedText(sortQuery)} arrow>
            {this.renderHeaderContent(headerCaption, sortQuery)}
          </TooltipComponent>
        ) : headerCaption}
      </TableCell>);

    if (isTableContainer) {
      return (
        <TableContainer>
          <Table className={`${className || ''} table-sort ${fixLayout && 'table-fix-layout'}`}>
            <TableHead className={classnames('table-sort-header', stickyHeader && 'custom-sticky-top')}>
              <TableRow>
                {headers}
              </TableRow>
            </TableHead>
            <TableBody>
              {this.renderRows(headers, rows)}
            </TableBody>
          </Table>
        </TableContainer>
      );
    }
    return (
      // ignore eslint to keep the old logic
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <>
        <Table className={`${className || ''} table-sort ${fixLayout && 'table-fix-layout'}`}>
          <TableHead className={classnames('table-sort-header', stickyHeader && 'custom-sticky-top')}>
            <TableRow>
              {headers}
            </TableRow>
          </TableHead>
          <TableBody>
            {this.renderRows(headers, rows)}
          </TableBody>
        </Table>
      </>
    )
  }

  render() {
    const { data } = this.props;
    return this.renderData(data);
  }
}

TableSortCore.propTypes = {
  emptyMessage: PropTypes.string,
  onColumnHeaderClick: PropTypes.func,
  fixLayout: PropTypes.bool,
  stickyHeader: PropTypes.bool,
  underRowComponent: PropTypes.node,
};

TableSortCore.defaultProps = {
  emptyMessage: t('table#empty-message'),
  currentSort: {},
  stickyHeader: false,
  underRowComponent: undefined
};

export default TableSortCore;
