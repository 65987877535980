import React from 'react';
import { Input as ReactStrapInput } from 'reactstrap';

const Input = (props) => {
  const {
    className,
    plaintext,
    children,
    ...rest
  } = props;
  if (plaintext) {
    const newProps = {
      ...rest,
      className: `col-form-label ${className || ''}`
    };
    return (
      <p {...newProps}>
        {children}
      </p>
    );
  } else {
    return <ReactStrapInput {...props} />;
  }
};

export default Input;
