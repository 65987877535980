import React from 'react';
import { SearchEntity, TestRunStatus } from '../../../utils/Constants';
import DataLoader from '../../../components/table/DataLoader';
import { buildSearchFunction } from '../../../components/search/SearchUtils';
import VerticalBarChart from '../../../components/chart/VerticalBarChart';
import colors from '../../../../scss/colors.scss';
import ReportHelper from '../../../utils/ReportHelper';
import { next } from '../../../utils/Count';
import { t } from '../../../i18n/t';
import NormalCard from '../../../components/card/NormalCard';
import TooltipComponent from '../../../components/TooltipComponent';
import MFlags from '../../../models/MFlags';

const chartPayload = [
  {
    dataKey: TestRunStatus.FAILED,
    color: colors.newFailed,
  },
  {
    dataKey: TestRunStatus.PASSED,
    color: colors.newPassed,
  }
];

const renderHeader = () => {
  return (
    <div className="overview-header-sub-title">
      {t('profile-coverage-by-test-run')}
      <TooltipComponent text={t('profile-coverage-by-test-run-tooltip')} placement="bottom-end" arrow />
    </div>
  );
}

const renderChart = (data) => {
  const convertedData = ReportHelper.convertData(data,
    [TestRunStatus.FAILED, TestRunStatus.PASSED],
    'ExecutionTestResult_profile');
  const checkedBlankProfiles = ReportHelper.handleEmptyProfile(convertedData);

  return (
    <VerticalBarChart
      data={checkedBlankProfiles}
      payload={chartPayload}
    />
  );
};


const ProfileCoverageTestRun = ({ defaultSearchConditions, dataLoaderRef }) => (
  <DataLoader
    key={next()}
    ref={dataLoaderRef}
    title={renderHeader()}
    tableId={SearchEntity.Execution}
    entityType={SearchEntity.Execution}
    defaultSearchConditions={defaultSearchConditions}
    defaultSearchFunctions={[
      buildSearchFunction('total', 'count_distinct', ['id']),
    ]}
    groupBys={[
      'ExecutionTestResult.profile',
      'status'
    ]}
    defaultSort={[
      'ExecutionTestResult.profile,asc'
    ]}
    render={renderChart}
    useCache={!MFlags.testPerformanceForReportAndAnalyticsEnabled}
    pageSize={300}
    cardClassName="overview-card-border shadow-none"
    useRefreshButton={false}
    useCollapseButton={false}
    headerComponent={NormalCard}
    bodyComponent={NormalCard}
    showEmptyMessage
  />
);

export default ProfileCoverageTestRun;
