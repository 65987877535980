import React from 'react';
import DefaultLayout from '../components/DefaultLayout';
import PageComponent from '../components/PageComponent';
import ObjectSummary from '../components/summary/ObjectSummary';
import { t } from '../i18n/t';
import MContext from '../models/MContext';
import RequirementTypeBar from './requirement/RequirementTypeBar';
import { buildFilter } from '../components/search-query/FilterQueryHelper';
import InputFilter from '../components/search-query/filter/InputFilter';
import CoverageReport from './requirement/CoverageReport';
import RequirementDataTable from '../components/external_issue/RequirementDataTable';

class Requirements extends PageComponent {
  constructor(props) {
    super(props);
    this.teamId = MContext.teamId;
    this.projectId = MContext.projectId;
    this.project = MContext.project;
    this.meta.id = 'page-external-requirements';
    this.meta.title = t(
      'Project {{name}} - {{page}}',
      { name: this.project.name, page: t('requirements') },
    );
    this.renderHeader = this.renderHeader.bind(this);
    this.renderBody = this.renderBody.bind(this);
  }

  renderObjectSummary() {
    const { project } = this;
    const urlParams = {
      requirements: t('requirements'),
      projectName: project.name,
    };
    return (
      <ObjectSummary params={urlParams} />
    );
  }

  renderHeader() {
    return (
      <>
        {this.renderObjectSummary()}
      </>
    );
  }

  renderBody() {
    const filterQuery = [
      buildFilter(InputFilter, { id: 'issueId', label: t('key'), operator: '~' }),
      buildFilter(InputFilter, { id: 'featureName', label: 'Feature', operator: '~' }),
    ];
    return (
      <>
        <RequirementTypeBar />
        <CoverageReport />
        <RequirementDataTable
          useSearchQuery
          filterQuery={filterQuery}
          title={t('requirements')}
        />
      </>
    );
  }

  render() {
    return (
      <DefaultLayout
        renderBody={this.renderBody}
        renderHeader={this.renderHeader}
      />
    );
  }
}

export default Requirements;
