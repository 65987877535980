/* eslint-disable max-len */
import React from 'react';
import parse from 'url-parse';
import { Button, Col, Progress } from 'reactstrap';
import Slide from '@mui/material/Slide';
import { Grid } from '@mui/material';
import { filter, isEmpty } from 'lodash';
import classnames from 'classnames';
import PageComponent from '../../components/PageComponent';
import { t } from '../../i18n/t';
import MAuth from '../../models/MAuth';
import { USER_SURVEY_STATUS } from '../../utils/Constants';
import UrlHelper from '../../utils/UrlHelper';
import { IconKatalonPlatformColor } from '../../images/CustomNewIcon';
import { SURVEY_PRIMARY_ROLE, SURVEY_MAIN_OBJECTIVES, OLD_SURVEY_MAIN_OBJECTIVES } from './constants/SurveyConstants';
import Services from '../../utils/Services';
import Routes from '../../utils/Routes';
import { analyticsIdentify } from '../../utils/SegmentAnalytics';
import RouteConstants from '../../utils/RouteConstants';
import MFlags from '../../models/MFlags';

const QUESTION = {
  FIRST_QUESTION: 1,
  SECOND_QUESTION: 2
};

const PROGRESS_BAR_PERCENT = {
  ZERO_PERCENT: 0,
  ONE_HUNDRED_PERCENT: 100
};

const SLIDE_DIRECTION = {
  LEFT: 'left',
  RIGHT: 'right'
};

class OnboardingSurveyV3 extends PageComponent {
  constructor(props) {
    super(props);
    this.meta.id = 'testops-welcome-survey';
    this.meta.title = t('survey#title');
    this.user = MAuth.user;

    this.state = {
      firstAnswer: null,
      secondAnswer: [],
      questionNumber: QUESTION.FIRST_QUESTION,
      firstAnswerBarPercent: PROGRESS_BAR_PERCENT.ONE_HUNDRED_PERCENT,
      secondAnswerBarPercent: PROGRESS_BAR_PERCENT.ZERO_PERCENT,
    };

    this.onBack = this.onBack.bind(this);
    this.onContinue = this.onContinue.bind(this);
    this.submitSurvey = this.submitSurvey.bind(this);
  }

  onBack() {
    this.setState({
      questionNumber: QUESTION.FIRST_QUESTION,
      secondAnswerBarPercent: PROGRESS_BAR_PERCENT.ZERO_PERCENT
    });
  }

  goToPageAfterOnBoardingQuestionnaires() {
    const url = parse(window.location.href, true);
    const redirectEncoded = url.query.redirect;
    // if has redirect query param, redirect to that url (case invite user)
    if (redirectEncoded) {
      const redirectDecoded = window.decodeURIComponent(redirectEncoded);
      window.location.href = redirectDecoded;
    } else {
      // get default project
      let projects = MAuth.user?.projects || [];
      projects = projects.filter((project) => project.sampleProject === true);
      projects.sort((a, b) => b.id - a.id);
      const defaultProject = projects[0];

      // if user has default project, redirect to that project's way to start page
      if (defaultProject) {
        Routes.goToWayToStartPage(defaultProject.teamId, defaultProject.id);
      } else {
        window.location.href = RouteConstants.home;
      }
    }
  }

  submitSurvey(toSurveyPrimaryRole, toSurveyTestingObjectives) {
    Services
      .submitTestOpsSurvey({ toSurveyPrimaryRole, toSurveyTestingObjectives })
      .then(() => {
        this.sendTrackingInfo(toSurveyPrimaryRole, toSurveyTestingObjectives);
        this.goToPageAfterOnBoardingQuestionnaires();
      });
  }

  onContinue() {
    const { firstAnswer, secondAnswer } = this.state;
    const surveyMainObjectives = SURVEY_MAIN_OBJECTIVES;
    const toSurveyPrimaryRole = this.findByIdFromArray(SURVEY_PRIMARY_ROLE, firstAnswer)?.title;
    const toSurveyTestingObjectives = filter(surveyMainObjectives, ({ id }) => secondAnswer.includes(id)).map(({ title }) => title);
    this.submitSurvey(toSurveyPrimaryRole, toSurveyTestingObjectives);
  }

  sendTrackingInfo(toSurveyPrimaryRole, toSurveyTestingObjectives) {
    const { email } = this.user;
    const data = {
      primary_role: toSurveyPrimaryRole,
      main_objectives: toSurveyTestingObjectives
    };
    analyticsIdentify({ email }, data);
  }

  pickFirstAnswer(answerOptionId) {
    const toSurveyPrimaryRole = this.findByIdFromArray(SURVEY_PRIMARY_ROLE, answerOptionId)?.title;
    this.submitSurvey(toSurveyPrimaryRole, null);
  }

  pickSecondAnswer(answerOptionId) {
    const { secondAnswer } = this.state;
    const index = secondAnswer.indexOf(answerOptionId);
    let newSecondAnswer;

    if (index >= 0) {
      newSecondAnswer = filter(secondAnswer, (id) => id !== answerOptionId);
    } else {
      newSecondAnswer = [...secondAnswer, answerOptionId];
    }

    this.setState({
      secondAnswer: newSecondAnswer
    });
  }

  findByIdFromArray(array, id) {
    return array.find((item) => item.id === id);
  }

  renderHeader() {
    const { user } = this;

    return (
      <div className="welcome-header">
        <div className="icon-katalon"><IconKatalonPlatformColor /></div>
        <div className="welcome-message">{t('survey#welcome-message', { fullName: user?.fullName })}</div>
        <div className="under-header">
          <div className="sub-title">{t('survey#sub-title')}</div>
        </div>
      </div>
    );
  }

  renderProgressBars() {
    const { questionNumber, firstAnswerBarPercent, secondAnswerBarPercent } = this.state;

    return (
      <>
        <div className="progress-bar-item">
          <Progress value={firstAnswerBarPercent} />
          <Progress value={secondAnswerBarPercent} className="ml-2" />
        </div>
        <div className="page-number">{questionNumber}/{QUESTION.SECOND_QUESTION}</div>
      </>
    );
  }

  renderBody() {
    const { questionNumber } = this.state;
    const subTitle = t('survey#question2#main-objectives#sub-title');
    const surveyMainObjectives = SURVEY_MAIN_OBJECTIVES;
    return (
      <Col sm="12" md="7" lg="7" xl="7" className="pl-0 mb-3">
        {questionNumber === QUESTION.FIRST_QUESTION && this.renderLayoutAnswer('first-question', SLIDE_DIRECTION.RIGHT, QUESTION.FIRST_QUESTION, SURVEY_PRIMARY_ROLE, t('survey#question1#header'))}
        {questionNumber === QUESTION.SECOND_QUESTION && (
          <>
            {this.renderLayoutAnswer('second-question', SLIDE_DIRECTION.LEFT, QUESTION.SECOND_QUESTION, surveyMainObjectives, t('survey#question2#main-objectives'), subTitle)}
            {this.renderButtonFooter()}
          </>
        )}
      </Col>
    );
  }

  renderLayoutAnswer(id, direction, questionNumber, answerOptions, header, headerDescription) {
    const content = this.renderAnswerOption(answerOptions, questionNumber);
    return (
      <Slide direction={direction} in={questionNumber}>
        <div id={id}>
          <div className="d-flex">
            <div className="mini-header">{header}</div>
            &nbsp;
            {headerDescription && <div className="mini-header-description">{headerDescription}</div>}
          </div>
          <Grid
            container
            alignItems="stretch"
            spacing={2}
            columns={{ xs: 6, sm: 6, md: 12 }}
            className="mt-2"
          >
            {content}
          </Grid>
        </div>
      </Slide>
    );
  }

  renderAnswerOption(answerOptions, questionNumber) {
    const { firstAnswer, secondAnswer } = this.state;

    const option = answerOptions.map(({ id, icon, title }) => {
      let selectedOption = false;
      if (questionNumber === QUESTION.FIRST_QUESTION && firstAnswer === id) {
        selectedOption = true;
      } else if (questionNumber === QUESTION.SECOND_QUESTION && secondAnswer.includes(id)) {
        selectedOption = true;
      }

      return (
        <Grid item xs={6} sm={6} md={6} key={id}>
          <div
            className={classnames('answer-option', selectedOption && 'selected')}
            onClick={() => {
              switch (questionNumber) {
                case QUESTION.FIRST_QUESTION:
                  return this.pickFirstAnswer(id);
                case QUESTION.SECOND_QUESTION:
                  return this.pickSecondAnswer(id);
                default:
                  return null;
              }
            }}
          >
            <div className={classnames('icon-box')}>{icon}</div>
            <div className="ml-2">{title}</div>
          </div>
        </Grid>
      );
    });

    return option;
  }

  renderButtonFooter() {
    const { secondAnswer } = this.state;
    const disableContinueButton = isEmpty(secondAnswer);

    return (
      <div className="mt-5">
        <Button onClick={this.onBack} title={t('back')}>
          {t('back')}
        </Button>
        <Button color="primary" onClick={this.onContinue} className="ml-2" disabled={disableContinueButton} title={t('continue')}>
          {t('continue')}
        </Button>
      </div>
    );
  }

  render() {
    const { user } = this;

    // If user already take survey, redirect to home or forward url
    if (user?.surveyStatus !== USER_SURVEY_STATUS.NOT_SUBMITTED) {
      UrlHelper.navigateToForwardURL();
      return null;
    }

    return (
      <div className={classnames('welcome-testops', 'no-background')}>
        {this.renderHeader()}
        {this.renderBody()}
      </div>
    );
  }
}

export default OnboardingSurveyV3;
