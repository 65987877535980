import React, { useEffect, useState } from 'react';
import { TestProjectType } from '../../../utils/Constants';
import Notification from '../../../utils/Notification';
import {
  getAgents, getCircleCIAgents,
  getK8SAgents
} from '../../../pages/plan/ConfigurePlanUtils';
import KatalonCommand from './KatalonCommand';
import MContext from '../../../models/MContext';

const KatalonCommandContainer = (props) => {
  const { testProject } = props;

  const [localAgents, setLocalAgents] = useState([]);
  const [circleCIAgents, setCircleCIAgents] = useState([]);
  const [k8sAgents, setK8sAgents] = useState([]);

  useEffect(async () => {
    try {
      if (TestProjectType[testProject?.type] && testProject?.id) {
        const [
          localAgents,
          circleCIAgents,
          k8sAgents
        ] = await Promise.all([
          getAgents(MContext.teamId),
          getCircleCIAgents(MContext.teamId),
          getK8SAgents(MContext.teamId)
        ]);
        setLocalAgents(localAgents);
        setCircleCIAgents(circleCIAgents);
        setK8sAgents(k8sAgents);
      }
    } catch (err) {
      Notification.pushError(err, 'Error');
    }
  }, [testProject]);

  return (
    <KatalonCommand
      {...props}
      circleCIAgents={circleCIAgents}
      k8sAgents={k8sAgents}
      localAgents={localAgents}
    />);
};

export default KatalonCommandContainer;
