import Services from './Services';

const OrganizationConfigurationName = {
  ENABLE_TESTOPS_ADVANCED: 'ENABLE_TESTOPS_ADVANCED',
  KSE_IDLE_TIMEOUT: 'KSE_IDLE_TIMEOUT',
  ENABLE_TESTOPS_INTEGRATION: 'ENABLE_TESTOPS_INTEGRATION',
  SESSION_TIMEOUT: 'SESSION_TIMEOUT',
  MAX_TIMES_CONTINUOUS_KSE: 'MAX_TIMES_CONTINUOUS_KSE',
};

const ProjectConfigurationName = {
  TIMEZONE: 'TIMEZONE',
  ASSERTION_PATTERN: 'ASSERTION_PATTERN',
};


export const UserConfigurationServices = {
  getUserConfiguration(name) {
    const params = {
      pagination: {
        page: 0,
        size: 1,
        sorts: 'id,desc',
      },
      type: 'UserConfiguration',
      conditions: [
        {
          key: 'name',
          operator: '=',
          value: name,
        },
      ],
    };
    return Services.search(params).then(({ content }) => {
      if (content.length > 0) {
        return content[0].value;
      }
      return '';
    });
  },
};

export const OrganizationConfigurationService = {
  updateMaxTimesContinuousKSE(organizationId, value) {
    return Services.updateOrganizationConfiguration({
      name: OrganizationConfigurationName.MAX_TIMES_CONTINUOUS_KSE,
      organizationId,
      value,
    });
  },

  updateKSETimeout(organizationId, value) {
    return Services.updateOrganizationConfiguration({
      name: OrganizationConfigurationName.KSE_IDLE_TIMEOUT,
      organizationId,
      value,
    });
  },

  updateTestOpsIntegration(organizationId, value) {
    return Services.updateOrganizationConfiguration({
      name: OrganizationConfigurationName.ENABLE_TESTOPS_INTEGRATION,
      organizationId,
      value,
    });
  },

  getTestOpsIntegration(organizationId) {
    return Services.getOrganizationConfiguration({
      organizationId,
      name: OrganizationConfigurationName.ENABLE_TESTOPS_INTEGRATION,
    });
  },

  updateOrgSessionTimeOut(organizationId, value) {
    return Services.updateOrganizationConfiguration({
      name: OrganizationConfigurationName.SESSION_TIMEOUT,
      organizationId,
      value,
    });
  },

  getOrgSessionTimeOut(organizationId) {
    return Services.getOrganizationConfiguration({
      organizationId,
      name: OrganizationConfigurationName.SESSION_TIMEOUT,
    });
  },

  getOrganizationSetting(organizationId) {
    return Services.getOrganizationSetting({
      organizationId
    });
  }
};


export const ProjectConfigurationService = {
  updateTimezone(projectId, timezone) {
    return Services.updateProjectConfiguration(projectId, {
      name: ProjectConfigurationName.TIMEZONE,
      value: timezone
    });
  },

  getTimezone(projectId) {
    return Services.getProjectConfiguration(projectId, ProjectConfigurationName.TIMEZONE);
  },

  updateAssertionPattern(projectId, patternList) {
    return Services.updateProjectConfiguration(projectId, {
      name: ProjectConfigurationName.ASSERTION_PATTERN,
      value: patternList
    });
  },

  getAssertionPattern(projectId) {
    return Services.getProjectConfiguration(projectId, ProjectConfigurationName.ASSERTION_PATTERN);
  }
};
