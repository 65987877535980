import _ from 'lodash';
import MFlags from './MFlags';


const MConfigs = {
  webSocketUrl: '',
  storeUrl: '',
  profiles: [],
  machineId: '',
  adminCreated: true,
  activated: false,
  buildVersion: '',
  commitId: '',
  sentryDsn: '',
  sentryEnv: '',
  sentryTracesSampleRate: 1.0,
  maxExecutionComparison: 0,
  maxExecutionDownload: 0,
  agentDownloadUrls: {},
  proxyTunnelDownloadUrls: {},
  reportUploaderDownloadUrl: '',
  reportUploaderLatestVersion: '',
  serverUrl: '',
  ioServerUrl: '',
  proxyTunnelServerUrl: '',
  usingSubDomain: false,
  subDomainPattern: '',
  cancellationSurveyUrl: '',
  advancedFeatureEnabled: false,
  frameworksIntegrationEnabled: false,
  testOpsSubscriptionEnabled: false,
  launchDarklyClientId: '',
  launchDarklyDefaultUser: '',
  launchDarklyPrefix: '',
  minTestExecutionOrdered: 0,
  userFlowToken: '',
  demoProjectUrl: '',
  microappAuthoringDomain: '',
  autonomousAgentUrl: '',
  autonomousClientCode: '',
  microAppAutonomousUrl: '',
  autonomousDefaultUserId: null,
  atgCustomFieldKey: '',
  testManagementConfiguration: {},
  customBlockedUI: false,
  testOpsBaseUrl: '',
  katOneHomeUrl: '',
  authorizationServerUrl: '',
  microAppExecutionEnvUrl: '',
  reportsAndAnalyticsMicroAppUrl: '',
  subDomain: undefined,

  parse(configs) {
    this.webSocketUrl = configs.webSocketUrl;
    this.storeUrl = configs.storeUrl;
    this.profiles = configs.profiles;
    this.buildVersion = configs.buildVersion;
    this.commitId = configs.commitId;
    this.sentryDsn = configs.sentryDsn;
    this.sentryEnv = configs.sentryEnv;
    this.sentryTracesSampleRate = configs.sentryTracesSampleRate;
    this.maxExecutionComparison = configs.maxExecutionComparison;
    this.maxExecutionDownload = configs.maxExecutionDownload;
    this.reportUploaderDownloadUrl = configs.reportUploaderDownloadUrl;
    this.reportUploaderLatestVersion = configs.reportUploaderLatestVersion;
    this.agentDownloadUrls = configs.agentDownloadUrls;
    this.proxyTunnelDownloadUrls = configs.proxyTunnelDownloadUrls;
    this.serverUrl = configs.ioServerUrl;
    this.ioServerUrl = configs.ioServerUrl;
    this.proxyTunnelServerUrl = configs.proxyTunnelServerUrl;
    this.usingSubDomain = configs.usingSubDomain;
    this.subDomainPattern = configs.subDomainPattern;
    this.cancellationSurveyUrl = configs.cancellationSurveyUrl;
    this.advancedFeatureEnabled = configs.advancedFeatureEnabled;
    this.frameworksIntegrationEnabled = configs.frameworksIntegrationEnabled;
    this.testOpsSubscriptionEnabled = configs.testOpsSubscriptionEnabled;
    this.launchDarklyClientId = configs.launchDarklyClientId;
    this.launchDarklyDefaultUser = configs.launchDarklyDefaultUser;
    this.launchDarklyPrefix = configs.launchDarklyPrefix;
    this.minTestExecutionOrdered = configs.minTestExecutionOrdered;
    this.userFlowToken = configs.userFlowToken;
    this.demoProjectUrl = configs.demoProjectUrl;
    this.microappAuthoringDomain = configs.microappAuthoringDomain;
    this.autonomousAgentUrl = configs.autonomousAgentUrl;
    this.autonomousClientCode = configs.autonomousClientCode;
    this.microAppAutonomousUrl = configs.microAppAutonomousUrl;
    this.microAppExecutionEnvUrl = configs.microAppExecutionEnvUrl;
    this.reportsAndAnalyticsMicroAppUrl = configs.reportsAndAnalyticsMicroAppUrl;
    this.autonomousDefaultUserId = configs.autonomousDefaultUserId;
    this.atgCustomFieldKey = configs.atgCustomFieldKey;
    this.testManagementConfiguration = configs.testManagementConfiguration;
    this.testOpsBaseUrl = configs.testOpsBaseUrl;
    this.katOneHomeUrl = configs.katOneHomeUrl;
    this.subDomain = configs.subDomain;
  },

  parseOnPremise(opConfigs) {
    this.adminCreated = opConfigs.adminCreated;
    this.activated = opConfigs.activated;
    this.machineId = opConfigs.machineId;
  },

  openCustomBlockedUI() {
    this.customBlockedUI = true;
  },

  closeCustomBlockedUI() {
    this.customBlockedUI = false;
  },

  get isCustomBlockedUIEnabled() {
    return this.customBlockedUI;
  },

  get isMaintainerWarningMode() {
    return _.includes(this.profiles, 'maintainer');
  },

  get isOnPremise() {
    return _.includes(this.profiles, 'on-premise');
  },

  get isPre() {
    return _.includes(this.profiles, 'pre-production');
  },

  get isLicenseAdminServer() {
    return _.includes(this.profiles, 'license-admin');
  },

  get isIoServer() {
    return _.includes(this.profiles, 'production-io');
  },

  get isLicenseServerOnPremise() {
    return this.isOnPremise && this.isLicenseAdminServer;
  },

  get isTestOpsOnPremise() {
    return this.isOnPremise && !this.isLicenseAdminServer;
  },

  get isOnStartUp() {
    const isLicenseServerStartUp = this.isLicenseServerOnPremise && (!this.activated || !this.adminCreated);
    const isTestOpsStartUp = this.isTestOpsOnPremise && !this.adminCreated;
    return isLicenseServerStartUp || isTestOpsStartUp;
  },

  get hideReporting() {
    return this.isLicenseAdminServer;
  },

  get isUsingSubDomain() {
    return this.usingSubDomain;
  },

  get isReadOnly() {
    return _.includes(this.profiles, 'readonly');
  },

  get isStagingServer() {
    return _.includes(this.profiles, 'staging');
  },

  get isPrivateTenant() {
    return _.includes(this.profiles, 'private-tenant');
  },

  get isCustomDomain() {
    return this.isUsingSubDomain;
  },
};

export default MConfigs;
