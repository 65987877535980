import React from 'react';
import TabComponent from '../../components/TabComponent';
import ExecutionTestResultDataTable from '../../components/table/ExecutionTestResultDataTable';
import { buildSearchCondition } from '../../components/search/SearchUtils';
import DataLoader from '../../components/table/DataLoader';
import { t } from '../../i18n/t';
import ExecutionStatusBar from '../execution/ExecutionStatusBar';
import StatusAreaChart from '../../components/chart/StatusAreaChart';
import PerformanceLineChart from '../../components/chart/PerformanceLineChart';

export class ResultTab extends TabComponent {
  constructor(props) {
    super(props);
    this.renderStatus = this.renderStatus.bind(this);
    this.renderPerformance = this.renderPerformance.bind(this);

    this.meta.id = 'page-execution-test-suite-details-result';
    this.meta.title = document.title;
  }

  renderStatus(data) {
    return (
      <StatusAreaChart
        data={data}
        xLabel="Executions"
        xAxis="execution.order"
      />
    );
  }

  renderPerformance(data) {
    return (
      <PerformanceLineChart
        data={data}
        xLabel="Executions"
        xAxis="execution.order"
      />
    );
  }

  renderChart() {
    const { executionTestSuite } = this.props;
    const { testSuite } = executionTestSuite;

    const defaultSearchConditions = [
      buildSearchCondition('TestSuite.id', '=', testSuite.id),
    ];

    return (
      <>
        <DataLoader
          entityType="ExecutionTestSuite"
          defaultSearchConditions={defaultSearchConditions}
          render={this.renderStatus}
          title={t('status_trend')}
        />
        <DataLoader
          entityType="ExecutionTestSuite"
          defaultSearchConditions={defaultSearchConditions}
          render={this.renderPerformance}
          title={t('performance_trend')}
        />
      </>
    );
  }

  renderAllFailedTests() {
    const { executionTestSuite } = this.props;
    const { execution, testSuite, platform } = executionTestSuite;
    return (
      <ExecutionTestResultDataTable
        defaultSearchConditions={[
          buildSearchCondition('Execution.id', '=', execution.id),
          buildSearchCondition('TestSuite.id', '=', testSuite.id),
          buildSearchCondition('Platform.id', '=', platform.id),
          buildSearchCondition('status', '!=', 'PASSED'),
          buildSearchCondition('status', '!=', 'SKIPPED'),
        ]}
        title="Failed Test Runs"
      />
    );
  }

  render() {
    const { executionTestSuite } = this.props;
    return (
      <>
        <ExecutionStatusBar execution={executionTestSuite} />
        {this.renderChart()}
        {this.renderAllFailedTests()}
      </>
    );
  }
}
