import { Step, StepContent, StepLabel } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useState, useEffect } from 'react';
import { Badge } from 'reactstrap';
import Grid from '@mui/material/Grid';
import Stepper from '@mui/material/Stepper';
import PropTypes from 'prop-types';
import { findIndex, findLastIndex } from 'lodash';
import { IconCheckBlue, IconClock } from '../images/CustomNewIcon';
import TooltipComponent from './TooltipComponent';
import { IconPrerequisites } from '../images/CustomIcon';
import { t } from '../i18n/t';
import MFlags from '../models/MFlags';

const OnboardingStepper = ({ steps, className }) => {

  const findActiveStep = (steps) => {
    // if all steps are completed, choose the first step
    if (steps.every((step) => step.isCompleted === true)) {
      return 0;
    }
    // if the last step is completed, expand the first step which isn't completed
    if (steps[steps.length - 1].isCompleted === true) {
      return findIndex(steps, { isCompleted: false });
    }
    // in normal case, expand the next of the last completed step
    const lastActiveStepIndex = findLastIndex(steps, { isCompleted: true });
    return lastActiveStepIndex + 1;
  };

  const [activeStep, setActiveStep] = useState(findActiveStep(steps));
  const [isScroll, setIsScroll] = useState(true);

  const scrollIntoActiveStep = () => {
    const exactParentNode = document.getElementById('onboarding-stepper');
    if (exactParentNode) {
      const activeElement = exactParentNode.querySelector('.Mui-active');
      if (activeElement) {
        exactParentNode.scrollTop = activeElement.offsetTop - exactParentNode.offsetTop - 25;
      }
    }
  };

  useEffect(() => {
    if (isScroll) {
      scrollIntoActiveStep();
    }
  }, [activeStep]);

  const generateIndexIcon = (index, step) => {
    if (step.isCompleted === true) {
      return <Badge className="bg-completed badge-circle-index"><IconCheckBlue className="icon-checked" /></Badge>;
    }
    const bgColor = activeStep === index ? 'bg-active' : 'bg-inactive';
    return (
      <Badge className={`${bgColor} badge-circle-index`}>{index + 1}</Badge>
    );
  };

  const handleActiveStep = (index, step, isScroll = false) => {
    setActiveStep(index);
    setIsScroll(isScroll);
  };

  const handleChosenPrerequisite = (index, step) => {
    handleActiveStep(index, step, true);
  };


  const renderETA = (eta) => (
    <span className="stepper__title_eta">
      <IconClock className="mr-1" />
      <span className="mr-1">ETA |</span>
      {eta}m
    </span>
  );

  const renderPrerequisitesComponent = (prerequisites) => {
    const style = makeStyles(() => ({
      tooltip: {
        maxWidth: 'none',
        padding: '0.571rem 0.9rem'
      }
    }));

    const renderStep = (step, index) => (
      <Step key={step.title}>
        <div onClick={() => handleChosenPrerequisite(index, step)} className="card-hover-pointer">
          <StepLabel
            icon={generateIndexIcon(index, step)}
          >
            <div className={index === activeStep ? 'stepper__header' : 'stepper__inactive_header'}>
              <span className={`${step.isCompleted && 'text-decoration-line-through'} stepper__title`}>{step.title}</span>
            </div>
          </StepLabel>
        </div>
      </Step>
    );

    const buildPrerequisitesTitle = () => (
      <div className={`${className} onboarding-prerequisites`}>
        <span className="prerequisites-text">
          {t('prerequisites-text')}
        </span>
        <Stepper
          orientation="vertical"
          nonLinear
        >
          {
            prerequisites.map((prerequisite) => {
              const index = findIndex(steps, { id: prerequisite });
              return renderStep(steps[index], index);
            })
          }
        </Stepper>
      </div>
    );

    return (
      <TooltipComponent text={buildPrerequisitesTitle()} customClasses={style()}>
        <IconPrerequisites className="ml-2 card-hover-pointer" />
      </TooltipComponent>
    );
  };

  const stepLabelClassName = (index) => {
    let className = index === activeStep ? 'stepper__header' : 'stepper__inactive_header';
    className += ' card-hover-pointer';
    return className;
  };

  return (
    <Grid
      className={className}
      container
      spacing={4}
    >
      <Grid className="stepper__cont" id="onboarding-stepper" item xs={5}>
        <Stepper
          activeStep={activeStep}
          orientation="vertical"
          nonLinear
        >
          {steps.map((step, index) => (
            <Step key={step.title}>
              <div>
                <StepLabel
                  icon={generateIndexIcon(index, step)}
                >
                  <div onClick={() => handleActiveStep(index, step)} className={stepLabelClassName(index)}>
                    <span className={`${step.isCompleted && 'text-decoration-line-through'} stepper__title`}>{step.title}</span>
                    {renderETA(step.eta)}
                  </div>
                </StepLabel>
                <StepContent transitionDuration="auto">
                  <div className="stepper__desc">
                    {step.desc}
                  </div>
                  <div className="mt-3">
                    {step.action}
                    {
                      MFlags.onboardingChecklistEnhancementEnabled && step.prerequisites && renderPrerequisitesComponent(step.prerequisites)
                    }
                  </div>
                </StepContent>
              </div>
            </Step>
          ))}
        </Stepper>
      </Grid>
      <Grid className="stepper__media" item xs={7}>
        {steps[activeStep].media}
      </Grid>
    </Grid>
  );
};


OnboardingStepper.propTypes = {
  steps: PropTypes.array,
  className: PropTypes.string,
};

OnboardingStepper.defaultProps = {
  steps: [],
  className: '',
};

export default OnboardingStepper;
