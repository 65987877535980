import { TestProject } from '../../../models/model/TestProject';
import { TestProjectType } from '../../../utils/Constants';
import Services from '../../../utils/Services';

export default {
  async getCloudRepoId(projectId: string) {
    const params = {
      pagination: {
        page: 0,
        size: 1,
        sorts: ['name,asc'],
      },
      conditions: [
        { key: 'Project.id', operator: '=', value: projectId },
        { key: 'type', operator: '=', value: TestProjectType.CLOUD },
      ],
      type: 'TestProject',
    };
    const scriptRepos: TestProject[] | undefined = await Services.search(params)
      .then(({ content }) => content);
    if (Array.isArray(scriptRepos) && scriptRepos.length > 0) {
      return scriptRepos[0].id;
    } else {
      return 0;
    }
  },
};
