import React from 'react';
import { Button } from 'reactstrap';
import { Typography, DialogContent } from '@mui/material';
import { t } from '../../i18n/t';
import { IconSuccessCreateOrg } from '../../images/CustomIcon';
import DialogComponent from './DialogComponent';


export default function SuccessfullyAssignedDialog(props) {
    const { showPopUp, handleOrganization, orgName, plan } = props
    const title = t('organization_assign_plan_success', { orgName, plan });
    return (
      <DialogComponent isOpen={ showPopUp }>
        <DialogContent className="success-dialog-content">
          <IconSuccessCreateOrg className="icon-success" />
          <Typography varient="h4" className="h4">{title}</Typography>
          <Button className="btn-go-to-home" color="primary" onClick={ handleOrganization }>{t('go_to_home')}</Button>
        </DialogContent>
      </DialogComponent>
    );
}
