import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'reactstrap';
import jquery from 'jquery';
import { next } from '../../../utils/Count';
import 'jquery-ui';
import 'jquery-ui/ui/widgets/button';
import 'jquery-ui/ui/effects/effect-highlight';
import 'jquery-ui/ui/widgets/datepicker';
import { t } from '../../../i18n/t';
import TooltipComponent from '../../TooltipComponent';
import InfoIcon from '../../../../images/icons/katalonui/InfoIcon';

window.$ = jquery;
window.jQuery = jquery;
require('structured-filter');

class TableCore extends React.Component {

  renderRows(headers, rows) {
    return (
      rows.length > 0
        ? rows
        : this.renderEmptyMessage(headers)
    );
  }

  renderEmptyMessage(headers) {
    const { emptyMessage } = this.props;
    return (
      <tr className="table-empty-message text-center">
        <td colSpan={headers.length}>{emptyMessage}</td>
      </tr>
    );
  }

  renderData(data) {
    const { transform, className, handleSelectRow } = this.props;
    let { columnMapping } = this.props;

    if (transform) {
      [data, columnMapping] = transform(data, columnMapping);
    }

    const rows = data.slice().map((row) => {
      const cols = columnMapping.map((col) => (
        <td className={col.className} key={next()}>{col.decorate(col.fieldName, row)}</td>
      ));
      if (handleSelectRow) {
        return <tr key={next()} role="button" onClick={() => handleSelectRow(row)}>{cols}</tr>;
      }
      return <tr key={next()}>{cols}</tr>;
    });

    const headers = columnMapping.map((header, index) => {
      const headerKey = `header-${index}`;
      return (
        <th key={next()} className={header.className}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span id={headerKey} style={{ marginRight: '5px' }}>
              {header.headerCaption}
            </span>
            {header.headerTooltipText && (
              <TooltipComponent placement="bottom-end" arrow text={header.headerTooltipText}>
                <div>
                  <InfoIcon />
                </div>
              </TooltipComponent>
            )}
          </div>
        </th>
      );
    });

    return (
      <Table responsive hover className={`${className || ''}`}>
        <thead>
          <tr>
            {headers}
          </tr>
        </thead>
        <tbody>
          {this.renderRows(headers, rows)}
        </tbody>
      </Table>
    );
  }

  render() {
    const { data } = this.props;
    return this.renderData(data);
  }
}

TableCore.propTypes = {
  emptyMessage: PropTypes.string,
  handleSelectRow: PropTypes.func,
  columnMapping: PropTypes.arrayOf(PropTypes.shape({
    className: PropTypes.string,
    decorate: PropTypes.func,
    fieldName: PropTypes.string,
    enableHeaderTooltip: PropTypes.bool,
    headerTooltipText: PropTypes.string,
    headerCaption: PropTypes.string,
  })),
};

TableCore.defaultProps = {
  emptyMessage: t('table#empty-message'),
  handleSelectRow: null,
};

export default TableCore;
