import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { t } from '../../../i18n/t';
import { MAX_PAGE_SIZE, SearchEntity } from '../../../utils/Constants';
import Services from '../../../utils/Services';
import MContext from '../../../models/MContext';
import Select from '../../../components/Select';

const ProfileFilter = ({ allProfiles, handleSelectChange, title, className, size }) => {
  const [profiles, setProfiles] = useState([]);
  const [loading, setLoading] = useState(false);

  const getAllProfiles = () => {
    setLoading(true);
    const projectId = MContext.projectId;

    const params = {
      pagination: {
        page: 0,
        size: MAX_PAGE_SIZE,
        sorts: ['profile,asc'],
      },
      conditions: [
        {
          key: 'Project.id',
          operator: '=',
          value: projectId,
        },
      ],
      type: SearchEntity.ExecutionTestResult,
      groupBys: ['profile']
    };

    Services.searchRecursively(0, params, []).then((res) => {
      // Filter null or empty string profile(report upload from frameworks)
      const profiles = res.map(({ content }) => ({
        value: content.profile,
        label: content.profile
      })).filter((profile) => profile.value && profile.value !== '');

      setProfiles([allProfiles, ...profiles]);
      setLoading(false);
    });
  };

  const handleOnOpen = () => {
    // Because after call function getAllProfiles length of profiles more 1, so profiles.length === 0 is the first open dropdown profile filter.
    if (profiles.length === 0) {
      getAllProfiles();
    }
  };

  return (
    <>
      <div className="mr-3 d-flex align-items-center">{title}</div>
      <Select
        useAutocomplete
        id="profile"
        options={profiles}
        getOptionLabel={(option) => option.label}
        defaultValue={allProfiles}
        onChange={handleSelectChange}
        disableClearable
        className={className}
        blurOnSelect
        loading={loading}
        onOpen={handleOnOpen}
        size={size}
      />
    </>
  );
};

ProfileFilter.propTypes = {
  allProfiles: PropTypes.object,
  handleSelectChange: PropTypes.func,
  /**
   * Title will be replace with label of profile select
   */
  title: PropTypes.string,
  /**
   * set size for Autocomplete ex: "small", "medium"
   */
  size: PropTypes.string,
  /**
   * set className for Autocomplete
   */
  className: PropTypes.string,
};

ProfileFilter.defaultProps = {
  allProfiles: {},
  handleSelectChange: null,
  title: t('show-data'),
  size: 'medium',
  className: 'overview-filter-profile',
};

export default ProfileFilter;
