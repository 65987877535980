import React, { useState, useEffect } from 'react';
import Stack from '@mui/material/Stack';
import DatePicker from '../DatePicker';
import TimePicker from '../TimePicker';
import Time from '../../utils/Moment';
import { JiraIssueOption } from '../../models/model/JiraIssueOption';

interface DatePickerInputProps {
  id: string;
  label: string;
  required?: boolean;
  changeValueContributor: (key: string, value: string) => void;
  useDateTime: boolean;
  defaultValue?: JiraIssueOption;
}

function DatePickerInput(props: Readonly<DatePickerInputProps>) {
  const {
    id,
    label,
    required,
    changeValueContributor,
    useDateTime = false,
    defaultValue,
  } = props;
  const initValue = defaultValue ? new Date(Number(defaultValue)) : null;
  const [timeValue, setTimeValue] = useState<Date | null>(null);

  const handleChangeValue = (value: Date | null) => {
    setTimeValue(value);
    const formatValue = value?.toISOString();
    changeValueContributor(id, String(formatValue));
  };

  useEffect(() => {
    if (initValue) {
      handleChangeValue(initValue);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Stack direction="row" spacing={2}>
      <div className="mw-100">
        <DatePicker
          required={required}
          id={id}
          name={label}
          value={timeValue}
          inputFormat={Time.DATE_FORMAT}
          disableHighlightToday
          onChange={(value: Date) => handleChangeValue(value)}
        />
      </div>
      {
        useDateTime && (
          <div className="mw-100">
            <TimePicker
              value={timeValue}
              inputFormat={Time.FULL_HOUR_TIME}
              onChange={(value: Date) => handleChangeValue(value)}
            />
          </div>
        )
      }
    </Stack>
  );
}

export default DatePickerInput;
