import { ListSubheader } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, CardHeader, Col, Form, FormGroup, FormText, Row } from 'reactstrap';
import { t } from '../../i18n/t';
import MContext from '../../models/MContext';
import { ProjectConfigurationService } from '../../utils/ConfigurationServices';
import { next } from '../../utils/Count';
import { TimeZone } from '../../utils/Moment';
import Notification from '../../utils/Notification';
import Services from '../../utils/Services';
import TextField from '../../components/TextField';
import MAuth from '../../models/MAuth';

const TimeZoneConfiguration = () => {
  const projectId = MContext.projectId;
  const teamId = MContext.teamId;
  const isTeamManager = MAuth.isTeamManager(teamId);
  const [timezone, setTimezone] = useState(null);
  const [options, setOptions] = useState([]);
  const suggestTimezone = TimeZone.guess();

  useEffect(() => {
    ProjectConfigurationService.getTimezone(projectId).then(({ value }) => setTimezone(value));
    Services.getTimeZoneOptions().then((options) => {
      const filteredOptions = options
        .filter(({ zoneId }) => TimeZone.isValid(zoneId))
        .map((option) => {
          if (option.zoneId === suggestTimezone) {
            option.suggest = true;
          } else {
            option.suggest = false;
          }
          return option;
        })
        .sort((z1, z2) => z2.suggest - z1.suggest);
      setOptions(filteredOptions);
    });
  }, []);

  const renderGroup = (params) => [
    <ListSubheader disableSticky key={params.key} component="div">
      {params.group ? 'Suggested time zone' : 'All time zones'}
    </ListSubheader>,
    params.children,
  ];

  const onSubmit = (event) => {
    event.preventDefault();
    ProjectConfigurationService.updateTimezone(projectId, timezone)
      .then(() => Notification.pushSuccess(t('saved_changes_success')));
  };

  const renderOption = (props, option) => (
    option && <li {...props}>{`(UTC${option.offset === 'Z' ? '' : ` ${option.offset}`}) ${option.zoneId}`}</li>
  );

  const renderLabelOption = (option) => (
    option && `(UTC${option.offset === 'Z' ? '' : ` ${option.offset}`}) ${option.zoneId}`
  );

  const renderInput = (params) => (
    <TextField
      {...params}
      placeholder="Select time zone"
    />
  );
  return (
    <Card>
      <CardHeader>
        Time Zone Settings
      </CardHeader>
      <CardBody>
        <Row>
          <Col sm="12" md="8" lg="6" xl="5">
            <Form onSubmit={onSubmit}>
              <FormGroup>
                <Autocomplete
                  key={next()}
                  id="timezone-config"
                  options={options}
                  disabled={!isTeamManager}
                  value={options.find((option) => option.zoneId === timezone)}
                  onChange={(event, option) => {
                    const { zoneId } = option || {};
                    setTimezone(zoneId);
                  }}
                  renderOption={renderOption}
                  renderInput={renderInput}
                  getOptionLabel={renderLabelOption}
                  renderGroup={renderGroup}
                  groupBy={(option) => option.suggest}
                />
                <FormText color="muted">
                  <div>{t('project_timezone_notice')}</div>
                  <div>{t('project_timezone_notice_cont')}</div>
                </FormText>
              </FormGroup>
              <FormGroup>
                <Button
                  type="submit"
                  color="primary"
                  disabled={!isTeamManager}
                >
                  {t('save')}
                </Button>
              </FormGroup>
            </Form>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default TimeZoneConfiguration;
