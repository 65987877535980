import React from 'react';
import { Col, Card, CardBody, Label, FormGroup, Button } from 'reactstrap';
import { IconButton, Breadcrumbs } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import Tooltip from '@katalon-studio/katalon-ui/Tooltip';
import PageComponent from '../components/PageComponent';
import Apis from '../utils/Apis';
import http from '../utils/http';
import MContext from '../models/MContext';
import { t } from '../i18n/t';
import ObjectSummary from '../components/summary/ObjectSummary';
import ObjectSummaryParametersBuilder from '../components/summary/ObjectSummaryParametersBuilder';
import Services from '../utils/Services';
import DefaultLayout from '../components/DefaultLayout';
import { buildSearchCondition } from '../components/search/SearchUtils';
import ExecutionTestSuiteHistoryDataTableV2 from '../components/table/ExecutionTestSuiteHistoryDataTableV2';
import DecoratorConstants from '../utils/DecoratorConstants';
import PageHistoryHelper from '../utils/PageHistoryHelper';
import { IconArrowLeft } from '../images/CustomIcon';
import CardBorder from '../components/card/CardBorder';
import ContentField from '../components/card/ContentField';
import TestCaseDataTable from './test_suite/TestCaseDataTable';
import PageButtonToolbar from '../components/PageButtonToolbar';
import {
  ACTIONS_TRACKING,
  DISPLAYED_PAGE_TRACKING,
  TestSuiteType,
  WAITING_TIME_FOR_TOAST,
  ACTIONS,
  TestProjectType,
  KATALON_EVENTS,
} from '../utils/Constants';
import Routes from '../utils/Routes';
import ConfirmationDeleteTestSuiteDialog from '../components/dialog/ConfirmationDeleteTestSuiteDialog';
import GroupEvent from '../utils/track/GroupEvent';
import Notification from '../utils/Notification';
import MFlags from '../models/MFlags';
import ButtonLink from '../components/ButtonLink';
import KUIButtonLink from '../components/KUIButtonLink';
import ActionsHelper from '../utils/table/ActionsHelper';
import colors from '../../scss/colors.scss';
import DeleteIcon from '../../images/icons/katalonui/DeleteIcon';
import MenuItemComponent from '../components/MenuItemComponent';
import { DomEventHandlers } from '../utils/EventHandler';
import { katalonui_theme } from '../katalonui-theme';
import { isSystemTestSuite } from '../models/model/TestSuite';

class ExecutionTestSuiteHistoryV2 extends PageComponent {
  constructor(props) {
    super(props);
    this.meta.id = 'page-execution-test-suite-history';
    this.testSuiteId = MContext.testSuiteId;
    this.state = {
      testSuite: null,
      latestExecutionTestSuites: null,
      isOpenConfirmationDeleteTestSuiteDialog: false,
    };
    this.renderHeader = this.renderHeader.bind(this);
    this.renderBody = this.renderBody.bind(this);
    this.handleOpenConfirmationDeleteTestSuiteDialog = this.handleOpenConfirmationDeleteTestSuiteDialog.bind(this);
    this.handleCloseConfirmationDeleteTestSuiteDialog = this.handleCloseConfirmationDeleteTestSuiteDialog.bind(this);
    this.handleConfirmDeleteTestSuite = this.handleConfirmDeleteTestSuite.bind(this);
    this.trackingScheduleTestRun = this.trackingScheduleTestRun.bind(this);
  }

  componentDidMount() {
    this.getTestSuite(this.testSuiteId);
    DomEventHandlers.eventListener(KATALON_EVENTS.deletedG5TestSuite, this.handleAfterDelete);
  }

  getTestSuite(testSuiteId) {
    http.get(Apis.testSuite(testSuiteId))
      .then((responseJson) => {
        const testSuite = responseJson;
        this.meta.title = t('Test Suite {{name}}', {
          name: testSuite.name,
        });
        this.getLatestExecutionTestSuites(testSuite.id);
        this.setState({
          testSuite,
        });
      });
  }

  renderToolbar() {
    const { testSuite } = this.state;
    const { testProject, type } = testSuite;
    const {
      g5Editor,
      removeGitSupportForCloudStudioEnabled,
      updateDeleteTestOpsTestSuiteDialog,
    } = MFlags;

    const isTypeTestOps = testSuite.type === TestSuiteType.TESTOPS;
    const isCloudStudioType = g5Editor
      && testProject
      && type === TestSuiteType.CLOUD_STUDIO
      && (removeGitSupportForCloudStudioEnabled ? testProject.type === TestProjectType.CLOUD : true);
    const editButtonDataTrackId = isTypeTestOps ? 'edit-katalon-studio-ts' : 'edit-cloud-studio-ts';
    const isCloudTestProject = testProject != null && testProject.type === TestProjectType.CLOUD;

    return (
      <PageButtonToolbar>
        {testSuite.testProject && this.renderScheduleButton()}
        {(isTypeTestOps || isCloudStudioType) && this.renderEditButton(editButtonDataTrackId)}
        {(!updateDeleteTestOpsTestSuiteDialog && isTypeTestOps) && this.renderDeleteButtonOld()}
        {(isCloudTestProject || (updateDeleteTestOpsTestSuiteDialog && isTypeTestOps))
          && this.renderDeleteButton()}
      </PageButtonToolbar>
    );
  }

  renderEditButton(editButtonDataTrackId) {
    const { testSuite } = this.state;
    const routes = new Routes();
    const isDisabled = isSystemTestSuite(testSuite);
    return (
      <ThemeProvider theme={katalonui_theme}>
        <Tooltip
          className="ml-2"
          label={t('test-suite-cannot-modified')}
          size="medium"
          placement="bottom"
          disableHoverListener={!isDisabled}
        >
          <div>
            <ButtonLink
              id="edit-btn"
              title={t('edit')}
              href={routes.edit_test_suite_link}
              color="secondary"
              data-trackid={editButtonDataTrackId}
              data-groupid={GroupEvent.ACCESS_TEST_MANAGEMENT}
              disabled={isDisabled}
            >
              {t('edit')}
            </ButtonLink>
          </div>
        </Tooltip>
      </ThemeProvider>
    );
  }

  trackingScheduleTestRun() {
    const { testSuite } = this.state;
    ActionsHelper.syncTrackingData(
      { testSuite },
      ACTIONS_TRACKING.SCHEDULE_TEST_RUN_TRIGGERED,
      DISPLAYED_PAGE_TRACKING.TEST_SUITE_DETAILS
    );
  }

  renderScheduleButton() {
    const { testSuite } = this.state;
    const routes = new Routes();
    return (
      <KUIButtonLink
        id="schedule-test-run-btn"
        title={t('create-plan')}
        link={routes.smart_test_scheduling_link({
          testProjectId: testSuite.testProject.id,
          testSuiteId: testSuite.id, })}
        variant="contained"
        color="primary"
        onClick={this.trackingScheduleTestRun}
        className="mt-1"
      >
        {t('create-plan')}
      </KUIButtonLink>
    );
  }

  renderDeleteButtonOld() {
    const { testSuite } = this.state;
    const isDisabled = isSystemTestSuite(testSuite);
    return (
      <ThemeProvider theme={katalonui_theme}>
        <Tooltip
          className="ml-2"
          label={t('test-suite-cannot-modified')}
          size="medium"
          placement="bottom"
          disableHoverListener={!isDisabled}
        >
          <div>
            <Button
              id="delete-testsuite-testops-btn"
              title={t('delete')}
              onClick={() => this.handleOpenConfirmationDeleteTestSuiteDialog()}
              color="secondary"
              data-trackid="delete-test-suite"
              data-groupid={GroupEvent.ACCESS_TEST_MANAGEMENT}
            >
              {t('delete')}
            </Button>
          </div>
        </Tooltip>
      </ThemeProvider>
    );
  }

  deleteTestSuite = () => {
    const { testSuite } = this.state;
    const { testProject } = testSuite;
    const { updateDeleteTestOpsTestSuiteDialog } = MFlags;
    const isTypeTestOps = testSuite.type === TestSuiteType.TESTOPS;
    const isCloudTestProject = testProject != null && testProject.type === TestProjectType.CLOUD;

    if (!updateDeleteTestOpsTestSuiteDialog && isTypeTestOps) {
      this.handleOpenConfirmationDeleteTestSuiteDialog();
    } else if (isCloudTestProject || isTypeTestOps) {
      ActionsHelper.syncTrackingData(
        { testSuite },
        ACTIONS_TRACKING.DELETE_TEST_SUITE_TRIGGERED,
        DISPLAYED_PAGE_TRACKING.TEST_SUITE_DETAILS
      );
      const data = { testSuite };
      DomEventHandlers.createEvent(KATALON_EVENTS.openDeleteTestEntityDialog, { detail: { testEntity: data, displayPageTracking: DISPLAYED_PAGE_TRACKING.TEST_SUITE_DETAILS } });
    }
  };

  actionItems = [
    {
      action: ACTIONS.DELETE,
      handler: this.deleteTestSuite,
      icon: <DeleteIcon color={colors.red5} />,
      color: colors.red5
    }
  ];

  renderDeleteButton() {
    const { testSuite } = this.state;
    const isDisabled = isSystemTestSuite(testSuite);
    return (
      <MenuItemComponent
        items={this.actionItems}
        iconButtonClassName="ml-2"
        disabled={isDisabled}
        isTooltipEnabled={isDisabled}
        tooltipText={t('test-suite-cannot-modified')}
      />
    );
  }

  getLatestExecutionTestSuites(id) {
    const params = {
      pagination: {
        page: 0,
        size: 30,
        sorts: 'order,desc',
      },
      conditions: [
        {
          key: 'TestSuite.id',
          operator: '=',
          value: id,
        },
      ],
      type: 'ExecutionTestSuite',
    };
    Services.search(params)
      .then((responseJson) => {
        const latestExecutionTestSuites = responseJson.content.reverse();
        this.setState({
          latestExecutionTestSuites,
        });
      });
  }

  renderObjectSummary() {
    const project = this.state.testSuite.project;
    const urlParams = new ObjectSummaryParametersBuilder();
    return (
      <ObjectSummary
        params={
          urlParams.toTestSuiteHistoryParams(
            project,
            this.state.testSuite,
          )
        }
      />
    );
  }

  handleBack() {
    PageHistoryHelper.goBackInTestSuiteDetail();
  }

  handleOpenConfirmationDeleteTestSuiteDialog() {
    this.setState({ isOpenConfirmationDeleteTestSuiteDialog: true });
  }

  handleCloseConfirmationDeleteTestSuiteDialog() {
    this.setState({
      isOpenConfirmationDeleteTestSuiteDialog: false,
    });
  }

  handleConfirmDeleteTestSuite() {
    const { testSuite } = this.state;
    Services.deleteTestSuite(testSuite.id).then(() => {
      Notification.pushSuccess(t('test-suite-detail#delete-test-suite-toast-message'));
      setTimeout(() => {
        Routes.goToTestSuitesLink();
      }, WAITING_TIME_FOR_TOAST);
    });
  }

  handleAfterDelete = () => {
    const routes = new Routes();
    this.props.history.push(routes.test_suites_page_link());
  };

  renderBackButton() {
    return (
      <div className="back-button">
        <IconButton onClick={() => this.handleBack()} size="large">
          <IconArrowLeft />
        </IconButton>
      </div>
    );
  }

  renderTestSuiteBreadcrumbs() {
    const { testSuite } = this.state;
    const paths = DecoratorConstants.addSourceOfTestCaseAndTestSuite(testSuite.path, testSuite.testProject).split('/').map((path) => <div>{path}</div>);
    return (
      <Breadcrumbs maxItems={8} itemsAfterCollapse={2}>
        {paths}
      </Breadcrumbs>
    );
  }

  renderHeader() {
    return (
      <>
        {this.renderTestSuiteBreadcrumbs()}
        <div className="header-with-back-icon">
          {this.renderBackButton()}
          {this.renderObjectSummary()}
        </div>
      </>
    );
  }

  renderHistoryExecutionTestSuite() {
    const { testSuite } = this.state;
    return (
      <ExecutionTestSuiteHistoryDataTableV2
        noCard
        title={t('history')}
        defaultSearchConditions={[
          buildSearchCondition('TestSuite.id', '=', testSuite.id),
        ]}
        defaultSort={['startTime, desc']}
      />
    );
  }

  renderTestSuiteDetails() {
    const { testSuite } = this.state;
    const { testProject } = testSuite;
    const lastExecuted = testSuite.lastExecutionTestSuite ? testSuite.lastExecutionTestSuite.startTime : null;

    return (
      <CardBorder id="card-ts-detail">
        { testProject && (
          <>
            <FormGroup>
              <Label className="title">{t('created_on')}</Label>
              <ContentField id="createdAt" value={DecoratorConstants.timeDecoratorValue(testSuite.createdAt) || '-'} />
            </FormGroup>
            <FormGroup>
              <Label className="title">{t('last_updated')}</Label>
              <ContentField id="updatedAt" value={DecoratorConstants.timeDecoratorValue(testSuite.updatedAt) || '-'} />
            </FormGroup>
          </>
        )}
        <FormGroup>
          <Label className="title">{t('last_executed')}</Label>
          <ContentField id="lastExecuted" value={DecoratorConstants.timeDecoratorValue(lastExecuted) || '-'} />
        </FormGroup>
      </CardBorder>
    );
  }

  renderTestCaseDataTable() {
    const { testSuite } = this.state;
    const { testProject } = testSuite;
    const currentTestSuiteIdSearch = testProject ? testSuite.id : -1;

    const defaultSearchConditions = [
      buildSearchCondition('TestSuite.id', '=', currentTestSuiteIdSearch)
    ];
    const defaultSort = ['sortOrder, asc NULLS LAST'];
    const tcNameTrackingProps = {
      'data-trackid': 'test-case-detail',
    };

    const conditionProps = {
      defaultSearchConditions,
      defaultSort
    };

    return (
      <TestCaseDataTable
        noCard
        title={t('test_cases')}
        tcNameTrackingProps={tcNameTrackingProps}
        isScriptRepoFolder={!!testProject}
        {...conditionProps}
      />
    );
  }

  renderBody() {
    const { isOpenConfirmationDeleteTestSuiteDialog } = this.state;
    return (
      <>
        {this.renderToolbar()}
        <Card className="ts-details-card">
          <CardBody className="ts-details-card-body row">
            <Col className="ts-details-scroll-component">
              <div className="mb-4">
                {this.renderTestCaseDataTable()}
              </div>
              { this.renderHistoryExecutionTestSuite() }
            </Col>
            <Col xs="auto" className="ts-details-fixed-component">
              {this.renderTestSuiteDetails()}
            </Col>
          </CardBody>
        </Card>
        <ConfirmationDeleteTestSuiteDialog
          id="confirmation-delete-test-suite-dialog"
          isOpen={isOpenConfirmationDeleteTestSuiteDialog}
          handleClose={this.handleCloseConfirmationDeleteTestSuiteDialog}
          handleConfirmation={this.handleConfirmDeleteTestSuite}
        />
      </>
    );
  }

  render() {
    const { testSuite, latestExecutionTestSuites } = this.state;
    if (testSuite && latestExecutionTestSuites) {
      return (
        <DefaultLayout
          renderHeader={this.renderHeader}
          renderCustomBodyLayout={this.renderBody}
        />
      );
    }
    return <div>No history</div>;
  }
}

export default ExecutionTestSuiteHistoryV2;
