import Icon from '@katalon-studio/katalon-ui/Icon';
import React from 'react';
import PropTypes from 'prop-types';
import colors from '../../../scss/colors.scss';

function UploadIcon({ color, size, ...props }) {
  return <Icon
    type="fa-solid"
    name="fa-upload"
    textSize={size}
    boundingSize={null}
    color={null}
    sx={{
      color,
      ...props
    }}
  />;
}

UploadIcon.defaultProps = {
  color: colors.newIncomplete,
  size: '16px',
};

UploadIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
};

export default UploadIcon;
