import { Route, Switch, Redirect } from 'react-router-dom';
import React from 'react';
import { Alert } from 'reactstrap';
import PageComponent from '../../components/PageComponent';
import MContext from '../../models/MContext';
import { t } from '../../i18n/t';
import RouteConstants from '../../utils/RouteConstants';
import TestPerformance from './test_performance/TestPerformance';
import FrequencyReport from './components/FrequencyReport';
import { analyticsPage } from '../../utils/SegmentAnalytics';
import ListTestReports from './ListTestReports';

class TestReportTestExecution extends PageComponent {

  constructor(props) {
    super(props);
    this.meta.id = 'page-test-report-test-execution';
    this.meta.title = t('test-report#test-execution');

    this.teamId = MContext.teamId;
    this.projectId = MContext.projectId;
    this.project = MContext.project;

    this.renderBody = this.renderBody.bind(this);
  }

  renderComponent(component, id) {
    this.meta.title = t(id);
    analyticsPage(`page-report-${id}`);
    return (
      <>
        <Alert color="primary">
          {t(`${id}-description`)}
        </Alert>
        {component}
      </>
    );
  }

  renderBody() {
    return (
      <Switch>
        <Redirect from={RouteConstants.test_report_execution} to={{ pathname: RouteConstants.executions }} />
        <Route
          exact
          path={RouteConstants.test_report_test_performance}
          render={(params) =>
            this.renderComponent(
              <TestPerformance {...params} />,
              ListTestReports.testPerformance.id
            )}
        />
        <Route
          exact
          path={RouteConstants.test_report_frequency}
          render={(params) =>
            this.renderComponent(
              <FrequencyReport {...params} />,
              ListTestReports.frequencyReport.id
            )
          }
        />
      </Switch>
    );
  }

  render() {
    return this.renderBody();
  }

}

export default TestReportTestExecution;
