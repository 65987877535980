import { ButtonGroup, Divider, ListItem, ListItemText } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import React from 'react';
import DropDownMenu from '../../components/navigationbar/DropDownMenu';
import { t } from '../../i18n/t';
import { IconReleaseSmall, IconReleaseSmallLight } from '../../images/CustomIcon';
import Routes from '../../utils/Routes';
import GroupEvent from '../../utils/track/GroupEvent';
import ButtonLink from '../../components/ButtonLink';

const useStyles = makeStyles((theme) => ({
  nested: {
    paddingLeft: theme?.spacing ? theme.spacing(4) : 4,
  },
}));

function ReleaseDropdownButton({ release, build, releases = [], onSelectRelease, onSelectBuild, unlinkRelease, ...props }) {

  const classes = useStyles();
  const dropDownRef = React.useRef(null);

  const toggleDropdown = () => {
    dropDownRef.current.toggle();
  };

  const selectReleaseButton = (props) => (
    <Button {...props} data-trackid="open-link-execution-release" data-groupid={GroupEvent.ACCESS_REPORT}>
      {release ? (
        <>
          <div className="icon-inactive">
            <IconReleaseSmall className="m-0" />
          </div>
          <div className="icon-active">
            <IconReleaseSmallLight className="m-0" />
          </div>
        </>
      ) : t('linkRelease')}
    </Button>
  );

  const menuItems = releases.map((item) => {
    const { builds } = item;
    const buildItems = builds.map((build) => (
      <ListItem
        className={classes.nested}
        key={build.id}
        button
        data-trackid="link-execution-release"
        data-groupid={GroupEvent.ACCESS_REPORT}
        onClick={() => {
          onSelectBuild(build);
          toggleDropdown();
        }}
      >
        <ListItemText>
          {build.name}
        </ListItemText>
      </ListItem>
    ));

    return (
      <>
        <ListItem
          key={item.id}
          button
          data-trackid="link-execution-release"
          data-groupid={GroupEvent.ACCESS_REPORT}
          onClick={() => {
            onSelectRelease(item);
            toggleDropdown();
          }}
        >
          <ListItemText>
            {item.name}
          </ListItemText>
        </ListItem>
        { buildItems}
      </>
    );
  });


  let linkedReleaseBuild;
  if (build) {
    const routes = new Routes({ buildId: build.id, releaseId: build.releaseId });
    linkedReleaseBuild = (
      <ButtonLink href={routes.build_details_link} className="release" title={build.name}>
        <span className="had-ellipsis">{build.name}</span>
      </ButtonLink>
    );
  } else if (release) {
    const routes = new Routes({ releaseId: release.id });
    linkedReleaseBuild = (
      <ButtonLink href={routes.view_release_link} className="release" title={release.name}>
        <span className="had-ellipsis">{release.name}</span>
      </ButtonLink>
    );
  }

  return (
    <>
      <ButtonGroup {...props} className="group-btn" title={t('linkRelease')}>
        {linkedReleaseBuild}
        <DropDownMenu
          renderAnchorElement={selectReleaseButton}
          expandDirection="right"
          ref={dropDownRef}
          className="release-dropdown"
        >
          <ListItem
            button
            data-trackid="clear-execution-release"
            data-groupid={GroupEvent.ACCESS_REPORT}
            onClick={() => {
              unlinkRelease();
              toggleDropdown();
            }}
          >
            <ListItemText>
              {t('clearRelease')}
            </ListItemText>
          </ListItem>
          <Divider />
          {menuItems}
        </DropDownMenu>
      </ButtonGroup>
    </>
  );
}

ReleaseDropdownButton.propTypes = {
  release: PropTypes.object,
  releases: PropTypes.array,
  onSelectRelease: PropTypes.func.isRequired,
  unlinkRelease: PropTypes.func.isRequired,
};

ReleaseDropdownButton.defaultProps = {
  release: null,
  releases: [],
  onSelectRelease: () => null,
  unlinkRelease: () => null,
};

export default ReleaseDropdownButton;
