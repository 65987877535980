import Services from '../../../utils/Services';
import { PublishTestCase } from '../../../models/model/PublishTestCase';
import { PublishTestSuite } from '../../../models/model/PublishTestSuite';

export default {

  async publishTestObject(scriptRepoId: number, testCases: PublishTestCase[], testSuites?: PublishTestSuite[], message?: string, errorHandler?: void, ignoreBlockUI?: boolean) {
    const publishedTestProject = {
      message,
      testCases,
      testSuites
    };
    return Services.publishTestProject(scriptRepoId, publishedTestProject, errorHandler, ignoreBlockUI);
  },
};
