import React from 'react';
import DataTable from '../table/DataTable';
import { defaultGroupByTime, SearchEntity, TestRunDailyOptionsV2 } from '../../utils/Constants';
import MTableColumnDataMapping from '../table/models/MTableColumnDataMapping';
import DecoratorConstants from '../../utils/DecoratorConstants';
import { t } from '../../i18n/t';
import DefectChart from './DefectChart';
import { buildFilter } from '../search-query/FilterQueryHelper';
import { buildDateData, buildSearchCondition } from '../search/SearchUtils';
import PopperDateRangePickerFilter from '../../pages/test_reports/test_run_report/PopperDateRangePickerFilter';
import { IconCalendarClock, IconChevronRight, IconFilterPriority, IconFilterUser, IconShip, IconStatusFilter } from '../../images/CustomIcon';
import { getEndTime, getStartTime } from '../../pages/test_reports/utils';
import ReleaseBuildFilter from '../search-query/filter/ReleaseBuildFilter';
import JiraAssigneeFilter from '../search-query/filter/JiraAssigneeFilter';
import StatusFilter, { StatusType } from '../search-query/filter/StatusFilter';
import JiraStatusFilter from '../search-query/filter/JiraStatusFilter';
import JiraStatusFilterV2 from '../search-query/filter/JiraStatusFilterV2';
import JiraPriorityFilter from '../search-query/filter/JiraPriorityFilter';
import { buildSortQuery } from '../search-query/SortQueryHelper';
import InfoSidebar from '../../utils/InfoSidebar';
import DefectDrawerTitle from './DefectDrawerTitle';
import DefectDrawerContent from './DefectDrawerContent';
import Arrays from '../../utils/Arrays';
import MFlags from '../../models/MFlags';
import JiraPriorityFilterV2 from '../search-query/filter/JiraPriorityFilterV2';

class DefectDataTableV2 extends React.Component {

  constructor(props) {
    super(props);
    this.defaultSearchConditions = [
      buildSearchCondition('issueCreated', '>', getStartTime(TestRunDailyOptionsV2.SEVEN_DAYS.value, defaultGroupByTime)),
      buildSearchCondition('issueCreated', '<', getEndTime(defaultGroupByTime))
    ];
    this.state = {
      searchConditions: this.defaultSearchConditions,
      dateRange: [],
      timeInterval: defaultGroupByTime,
      chosenDefect: null,
      tabIndex: 0,
    };
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.handleOpenDrawerWithDefectId = this.handleOpenDrawerWithDefectId.bind(this);
    this.handleCloseSideBar = this.handleCloseSideBar.bind(this);
  }

  handleCloseSideBar() {
    InfoSidebar.close();
    this.setState({ chosenDefect: null });
  }

  handleOpenDrawerWithDefectId(data, currentPage) {
    if (MFlags.jiraSlackIntegrationEnabled) {
      const { searchConditions } = this.state;
      const element = searchConditions.find((searchCondition) => searchCondition.key === 'id');
      if (currentPage === 0 && data.length === 1) {
        if (element?.value === String(data[0].id)) {
          // open the first tab index as default when auto open sidebar
          this.renderSideBar(0, data[0]);
          this.setState({ chosenDefect: data[0] });
        }
      } else {
        this.handleCloseSideBar();
      }
    }
  }

  handleFilterChange(filterOptions) {
    let searchConditions = [];
    const dateRange = [];
    for (let i = 0; i < filterOptions.length; i++) {
      const key = filterOptions[i].field.value;
      const operator = filterOptions[i].operator.label;
      let value;
      if (key === 'issueCreated') {
        value = buildDateData(filterOptions[i].value.label, null, null);
        dateRange.push(value);
      } else {
        value = filterOptions[i].value.label;
      }
      searchConditions.push(buildSearchCondition(key, operator, value));
    }
    if (dateRange.length === 0) {
      searchConditions = searchConditions.concat(this.defaultSearchConditions);
    }
    this.setState({ searchConditions, dateRange });
  }

  handleChangeTimeInterval = (value) => {
    this.setState({
      timeInterval: value,
    });
  };

  renderSideBar = (newValue, chosenDefect) => {
    const header = <DefectDrawerTitle chosenDefect={chosenDefect} tabIndex={newValue} handleChangeTabIndex={this.handleChangeTabIndex} />;
    const defectDetail = <DefectDrawerContent chosenDefect={chosenDefect} tabIndex={newValue} />;
    InfoSidebar.show(header, defectDetail, undefined, 'info-sidebar-wrapper sticky-top mt-0 p-0 border-left defect-sidebar');
  };

  handleChangeTabIndex = (event, newValue) => {
    const { chosenDefect } = this.state;
    this.renderSideBar(newValue, chosenDefect);
    this.setState({
      tabIndex: newValue,
    });
  };

  toggleShowTestRunInfoSidebar = (chosenDefect) => {
    const { tabIndex } = this.state;
    if (chosenDefect === this.state.chosenDefect && InfoSidebar.isOpened) {
      this.handleCloseSideBar();
    } else {
      if (MFlags.automationRuleEnabled) {
        this.renderSideBar(tabIndex, chosenDefect);
      } else {
        const header = <DefectDrawerTitle chosenDefect={chosenDefect} />;
        const defectDetail = <DefectDrawerContent chosenDefect={chosenDefect} />;
        InfoSidebar.show(header, defectDetail);
      }
    }
  };

  handleSelectDefectRow = (chosenDefect) => {
    this.setState({ chosenDefect });
    this.toggleShowTestRunInfoSidebar(chosenDefect);
  };


  render() {
    const columnMapping = [
      new MTableColumnDataMapping(
        t('id'),
        '',
        (name, row) => DecoratorConstants.defectIdDecorator(row),
        undefined,
        'fit-content-column'
      ),
      new MTableColumnDataMapping(
        t('table-header#title'),
        'summary',
        DecoratorConstants.externalNameDecorator,
      ),
      new MTableColumnDataMapping(
        t('table-header#status'),
        'status',
        (name, row) => DecoratorConstants.defectStatusDecorator(row),
      ),
      new MTableColumnDataMapping(
        t('table-header#priority'),
        '',
        (name, row) => (MFlags.externalSeverityMigrationEnabled ?
          DecoratorConstants.defectPriorityDecorator(row) :
          DecoratorConstants.defectPriorityDecoratorWithoutIcon(row)),
      ),
      ...Arrays.insertIf(
        MFlags.externalSeverityMigrationEnabled,
        new MTableColumnDataMapping(
          t('table-header#severity'),
          '',
          (name, row) => DecoratorConstants.defectSeverityDecorator(row),
        ),
      ),
      new MTableColumnDataMapping(
        t('test_cases'),
        '',
        (name, row) => DecoratorConstants.defectTestCasesDecorator(row),
      ),
      new MTableColumnDataMapping(
        t('test-results'),
        'testResults',
        DecoratorConstants.defectTestResultsDecorator,
      ),
      new MTableColumnDataMapping(
        t('table-header#requirements'),
        '',
        (name, row) => DecoratorConstants.defectRequirementsDecorator(row),
      ),
      new MTableColumnDataMapping(
        t('table-header#assignee'),
        '',
        (name, row) => DecoratorConstants.defectAssigneeDecorator(row),
      ),
      new MTableColumnDataMapping(
        '',
        '',
        () => <IconChevronRight />,
      ),
    ];

    const sortQuery = [
      // Will be implement in another ticket
      // ...buildSortQuery('priority', t('jira-filter#priority-label')),
      ...buildSortQuery('issueCreated', t('jira-webhook#created-at')),
      ...buildSortQuery('issueTimestamp', t('last-update')),
    ];

    const { searchConditions, dateRange, timeInterval } = this.state;

    const defaultDisplayFilterQuery = [
      buildFilter(PopperDateRangePickerFilter, {
        id: 'issueCreated',
        label: 'issueCreated',
        timeInterval,
        handleChangeTimeInterval: this.handleChangeTimeInterval,
        isShowPopup: false,
        icon: <IconCalendarClock />
      }),
      buildFilter(JiraStatusFilterV2, {
        id: 'statusIn',
        icon: <IconStatusFilter />
      }),
      ...Arrays.insertIf(
        MFlags.externalSeverityMigrationEnabled,
        buildFilter(JiraPriorityFilterV2, {
          id: 'priorityIn',
          icon: <IconFilterPriority />
        })
      ),
      ...Arrays.insertIf(
        !MFlags.externalSeverityMigrationEnabled,
        buildFilter(JiraPriorityFilter, {
          id: 'priorityIn',
          icon: <IconFilterPriority />
        })
      ),
      buildFilter(ReleaseBuildFilter, { id: 'ReleaseBuild', icon: <IconShip /> }),
      buildFilter(JiraAssigneeFilter, {
        id: 'assignee',
        label: t('jira-filter#assignee-label'),
        icon: <IconFilterUser />
      }),
    ];

    return (
      <>
        <DefectChart
          handleFilterChange={this.handleFilterChange}
          useSearchQuery
          dynamicFilterQuery
          dateRange={dateRange}
          timeInterval={timeInterval}
          defaultDisplayFilterQuery={defaultDisplayFilterQuery}
          entityType={SearchEntity.ExternalDefect}
          defaultSearchConditions={searchConditions}
          clearStructuredFilterOnPropsChange
          showSearchBar={MFlags.jiraSlackIntegrationEnabled}
          {...this.props}
        />
        <DataTable
          columnMapping={columnMapping}
          entityType={SearchEntity.ExternalDefect}
          clearStructuredFilterOnPropsChange
          showTable
          useSearchQuery
          useSortAndPaginationOnly
          unitName="defect"
          sortQuery={sortQuery}
          defaultSearchConditions={searchConditions}
          defaultSort={['issueCreated,desc']}
          useCollapseButton={false}
          handleSelectRow={this.handleSelectDefectRow}
          onChangeData={this.handleOpenDrawerWithDefectId}
          {...this.props}
        />
      </>
    );
  }
}

export default DefectDataTableV2;
