import React from 'react';

const StripedShape = ({ fill, spaceWidth = 8, stripeDistance = 8, stripeWidth = 4, stripeHeight = 8, stripeProps, ...props }) => (
  <g>
    <pattern
      id="pattern-stripe"
      width={spaceWidth}
      height={stripeDistance}
      patternUnits="userSpaceOnUse"
      patternTransform="rotate(45)"
    >
      <rect width={stripeWidth} height={stripeHeight} transform="translate(0,0)" fill="white" {...stripeProps} />
    </pattern>
    <mask id="mask-stripe">
      <rect x="0" y="0" width="100%" height="100%" fill="url(#pattern-stripe)" />
    </mask>
    <rect {...props} stroke={fill} fill={fill} mask="url(#mask-stripe)" />
    <rect {...props} stroke={fill} fill="transparent" />
  </g>
);

export default StripedShape;
