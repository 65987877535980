import React, { Component } from 'react';
import {
  FormGroup, ButtonToolbar, CustomInput,
  Button, Label, Col, Row, Form, Alert
} from 'reactstrap';
import { t } from '../../../i18n/t';
import MContext from '../../../models/MContext';
import Input from '../../../components/Input';
import Services from '../../../utils/Services';
import Notification from '../../../utils/Notification';
import MAuth from '../../../models/MAuth';
import Routes from '../../../utils/Routes';

class SlackSetting extends Component {
  constructor(props) {
    super(props);
    this.teamId = MContext.teamId;
    this.projectId = MContext.projectId;
    this.project = MContext.project;
    this.changeText = this.changeText.bind(this);
    this.submitSettings = this.submitSettings.bind(this);
    this.testConnection = this.testConnection.bind(this);
    this.state = {
      settingsForm: {
        id: null,
        url: '',
        active: true,
      },
    };
  }

  getConnection() {
    const params = {
      pagination: {
        page: 0,
        size: 1,
        sorts: 'order,desc',
      },
      conditions: [
        {
          key: 'Project.id',
          operator: '=',
          value: this.projectId,
        },
      ],
      type: 'SlackConnection',
    };
    Services.search(params)
      .then(({ content }) => {
        const connection = content[0];
        if (connection) {
          const settingsForm = {
            id: connection.id,
            url: connection.url,
            active: connection.active,
          };
          this.setState({ settingsForm });
        }
      });
  }

  componentDidMount() {
    this.getConnection();
  }

  changeText(event) {
    const { settingsForm } = this.state;
    const key = event.target.name;
    const value = event.target.value;
    settingsForm[key] = value;
    this.setState({ settingsForm });
  }

  onChangeCheckbox(event) {
    const { settingsForm } = this.state;
    const key = event.target.name;
    const value = event.target.checked;
    settingsForm[key] = value;
    this.setState({ settingsForm });
  }

  submitSettings(e) {
    e.preventDefault();
    const setting = { projectId: this.projectId, ...this.state.settingsForm };
    Services.saveSlackSettings(setting, () => {
      Notification.pushError('There was an error saving your setting. Please try again.');
    })
      .then(() => {
        Notification.pushSuccess('Your setting has been saved successfully.');
        this.getConnection();
      });
  }

  testConnection(e) {
    e.preventDefault();
    const setting = { projectId: this.projectId, ...this.state.settingsForm };

    Services.testSlackConnection(setting, () => {
      Notification.pushError('Please check your setting and try again.', 'Connection failed!');
    })
      .then(() => {
        Notification.pushSuccess('Please save your setting before leaving the page.', 'Connect Successfully');
      });

  }

  renderSlackSettingForm() {
    const { url, active } = this.state.settingsForm;
    return (
      <Row>
        <Col sm="12" md="8" lg="6" xl="5">
          <Form
            data-trackid="update-slack-settings"
            onSubmit={this.submitSettings}
          >
            <FormGroup>
              <Label for="url">{t('incoming_webhook_url')}</Label>
              <Input
                onChange={this.changeText}
                name="url"
                type="text"
                value={url}
                required
              />
            </FormGroup>
            <FormGroup>
              <CustomInput
                onChange={(e) => this.onChangeCheckbox(e)}
                name="active"
                type="switch"
                id="active"
                checked={active}
                label={t('slack-setting#active-button')}
                value
              />
            </FormGroup>
            <ButtonToolbar>
              <Button
                disabled={!url}
                data-trackid="test-slack-connection"
                type="button"
                color="secondary"
                onClick={this.testConnection}
              >
                {t('slack-setting#test-connection')}
              </Button>
              <Button
                disabled={!url}
                data-trackid="save-slack-connection"
                type="submit"
                color="primary"
              >
                {t('slack-setting#save')}
              </Button>
            </ButtonToolbar>
          </Form>
        </Col>
      </Row>
    );
  }

  renderData() {
    const slackGuideLink = 'https://api.slack.com/messaging/webhooks';
    return (
      <>
        <Alert color="primary">
          Please follow <a href={slackGuideLink} target="_blank" rel="noopener noreferrer">this Slack article</a> to create an Incoming Webhook.
        </Alert>
        {this.renderSlackSettingForm()}
      </>
    );
  }

  render() {
    const isTeamManager = MAuth.isTeamManager(this.teamId);
    if (!isTeamManager) {
      Routes.goToAccessDeniedPage();
    }

    return this.renderData();
  }
}

export default SlackSetting;
