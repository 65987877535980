import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import PropTypes from 'prop-types';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { isEmpty } from 'lodash';
import MTableColumnDataMapping from './models/MTableColumnDataMapping';
import { t } from '../../i18n/t';
import TableCore from './models/TableCore';
import NormalCard from '../card/NormalCard';
import DecoratorConstants from '../../utils/DecoratorConstants';
import storageService from '../../utils/mobiletesting/mobileTestingService';
import { IconAngleLeft, IconAngleRight, IconDelete } from '../../images/KitIcons';
import TableLoading from './TableLoading';
import Helper from '../../utils/Helper';
import { MAX_APP_PAGE_SIZE, UploadAppStatus } from '../../utils/Constants';
import Notification from '../../utils/Notification';
import SearchTextField from '../SearchTextField';
import CopyableDecorator from '../../pages/testexecution/components/CopyableDecorator';

function AppDataTable({ isReload, onReloaded }) {

  const [apps, setApps] = useState([]);
  const [totalItem, setTotalItem] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [pageIndex, setPageIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [deletedItem, setDeletedItem] = useState({});
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const [searchText, setSearchText] = useState('');

  const getApps = (isLoading) => {
    setIsLoading(isLoading);
    storageService.fetchAllApps(pageIndex, MAX_APP_PAGE_SIZE, 'createdDate desc').then((result) => {
      setApps(result.items);
      setTotalItem(result.count);
      setIsLoading(false);
    });
  };

  const handleSearch = () => {
    setIsLoading(true);
    storageService.searchAppsByName(searchText, pageIndex, MAX_APP_PAGE_SIZE, 'createdDate desc').then((result) => {
      setApps(result.items);
      setTotalItem(result.count);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (searchText !== '') {
      handleSearch();
    } else {
      getApps(true);
    }
  }, [pageIndex]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isReload) {
      getApps(true);
      onReloaded(!isReload);
    }
  });

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isEmpty(apps.filter((value) => (value.status === UploadAppStatus.NEW || value.status === UploadAppStatus.UPLOADING)))) {
        getApps(false);
      }
    }, 5000);
    return () => clearInterval(interval);
  });

  useEffect(() => {
    setTotalPage(totalItem / MAX_APP_PAGE_SIZE);
  }, [totalItem]);

  const handleSelect = (row) => {
    setIsOpenPopup(true);
    setDeletedItem(row);
  };

  const columnMapping = [

    new MTableColumnDataMapping(
      t('app#application-name-column'),
      'name',
      (name, row) => DecoratorConstants.appDecorator(name, row),
      undefined,
      'app-name-column'
    ),

    new MTableColumnDataMapping(
      t('app#app-size'),
      'size',
      (name, row) => DecoratorConstants.displayAppSizeValue(name, row),
      undefined,
      'app-medium-width'
    ),

    new MTableColumnDataMapping(
      t('app#version-name-column'),
      'metadata.version',
      (name, row) => DecoratorConstants.displayValue(name, row),
      undefined,
      'app-column',
      undefined,
      undefined,
      true,
      t('app#version-name-column-tooltip'),
    ),

    new MTableColumnDataMapping(
      t('app#version-code-column'),
      'metadata.versionCode',
      (name, row) => DecoratorConstants.displayValue(name, row),
      undefined,
      'app-column',
      undefined,
      undefined,
      true,
      t('app#version-code-column-tooltip'),
    ),

    new MTableColumnDataMapping(
      t('app#operating-system-column'),
      'platform',
      (name, row) => DecoratorConstants.mobilePlatformDecorator(name, row),
      undefined,
      'app-medium-width'
    ),

    new MTableColumnDataMapping(
      t('app#status-column'),
      'status',
      (name, row) => DecoratorConstants.appStatusDecorator(name, row),
      undefined,
      'app-status-column'

    ),

    new MTableColumnDataMapping(
      t('app#identify-column'),
      'metadata.identifier',
      (name, row) => DecoratorConstants.displayValue(name, row),
      undefined,
      'app-column'
    ),

    new MTableColumnDataMapping(
      t('app#app-id'),
      'id',
      (name, row) => <CopyableDecorator name={name} row={row} />,
      undefined,
      'app-id-column'
    ),

    new MTableColumnDataMapping(
      t('app#uploaded-date-column'),
      'uploadedDate',
      (name, row) => DecoratorConstants.fullDateDecorator(name, row),
      undefined,
      'app-uploaded-date-column'
    ),

    new MTableColumnDataMapping(
      t('table-header#action'),
      'id',
      (name, row) => (
        <Button
          className="btn-icon-only"
          color="link"
          onClick={() => handleSelect(row)}
          title={t('app#remove-app')}
          disabled={row.status === UploadAppStatus.NEW || row.status === UploadAppStatus.UPLOADING}
        >
          <IconDelete />
        </Button>
      )
    ),
  ];

  const onPageChange = (index) => {
    setPageIndex(index.selected);
  };

  const removeApp = () => {
    storageService.deleteApp(deletedItem?.id).then(() => {
      Notification.pushSuccess(`The application ${deletedItem?.name} has been removed.`);
      getApps();
    }).catch(() => {
      Notification.pushError(`Error when removing application ${deletedItem?.name}`);
    });
    setIsOpenPopup(false);
    setDeletedItem({});
  };

  const cancel = () => {
    setIsOpenPopup(false);
    setDeletedItem({});
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  const handleChangeSearchInput = (e) => setSearchText(e.target.value);

  return (
    <>
      <Modal isOpen={isOpenPopup}>
        <ModalHeader>{t('app#remove-app')}</ModalHeader>
        <ModalBody>
          This action cannot be undone. Are you sure you want to delete <strong>{deletedItem?.name}</strong>?
        </ModalBody>
        <ModalFooter>
          <Button data-trackid="delete-agent" color="danger" onClick={() => { removeApp(); }}>Delete</Button>
          <Button color="secondary" onClick={() => { cancel(); }}>Cancel</Button>
        </ModalFooter>
      </Modal>

      <SearchTextField
        placeHolder={t('search#application-name')}
        onSearchInputKeyDown={handleKeyPress}
        onChange={handleChangeSearchInput}
        hideIconOnSearchBar
        customClass="full-width-search"
      />

      {isLoading ?
        <TableLoading />
        :
        <>
          <NormalCard>
            <TableCore data={apps} columnMapping={columnMapping} />
          </NormalCard>
          <div>
            <ReactPaginate
              previousLabel={
                <IconAngleLeft />
                    }
              nextLabel={
                <IconAngleRight />
                    }
              breakLabel={<span>...</span>}
              pageCount={totalPage}
              pageRangeDisplayed={5}
              marginPagesDisplayed={4}
              forcePage={pageIndex}
              onPageChange={onPageChange}
              containerClassName={totalPage > 1 ? 'pagination' : 'd-none'}
              subContainerClassName="pages pagination"
              activeClassName="active"
            />
            <div
              className="pagination-item-count pt-4"
            >
              {Helper.generatePaginationText(totalItem, '')}
            </div>
          </div>
        </>}
    </>
  );

}

AppDataTable.propTypes = {
  isReload: PropTypes.bool,
  onReloaded: PropTypes.func
};

export default AppDataTable;
