import { IconButton, ListItemIcon } from '@mui/material';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { IconDown, IconSave } from '../../../images/KitIcons';
import { IconXmark } from '../../../images/CustomIcon';
import { next } from '../../../utils/Count';
import PopperMenu from '../../PopperMenu';
import MFlags from '../../../models/MFlags';

const compareId = (option1, option2) => option1?.id === option2?.id;

class DropDownFilter extends Component {

  constructor(props) {
    super(props);
    this.filterBtn = this.filterBtn.bind(this);
    this.select = this.select.bind(this);
    this.state = {
      selectedOption: null,
    };
    this.dropdownRef = React.createRef();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { defaultValue, searchKey, options } = nextProps;
    let selectedOption = null;

    if (defaultValue) {
      for (let i = 0; i < defaultValue.length; i++) {
        selectedOption = _.find(options, { [searchKey]: defaultValue[i] });
        if (selectedOption != null) {
           break; 
        }
      }
    }
    
    if (!prevState.selectedOption) {
      return {
        selectedOption
      };
    } else {
      return null;
    }
  }

  select(newOption) {
    const { searchKey } = this.props;
    this.setState(
      (prevState) => {
        if (prevState.selectedOption === newOption) return { selectedOption: null };
        else return { selectedOption: newOption };
      },
      () => this.applyFilter(_.get(this.state.selectedOption, searchKey))
    );
  }

  applyFilter(value) {
    const { id, onFilterChange } = this.props;
    onFilterChange(id, value);
    this.handleToggle();
  }

  handleDeleteFilter() {
    const { id, onFilterChange, onDeleteFilter } = this.props;
    onDeleteFilter(id);
    onFilterChange(id, '');
  }

  filterBtn(props) {
    const { selectedOption } = this.state;
    const { label, renderOption, buttonLabel, selectedOption: externalSelectedOption, allowDelete, icon } = this.props;
    const renderValue = externalSelectedOption || selectedOption;
    const defaultLabel =
    renderValue ? <>{label}: &nbsp; {renderOption(renderValue) }</> : `${label}: All`;
    return (
      <Button
        className="filter-btn"
        {...props}
        startIcon={MFlags.testRunReportFilterEnhancementPhase2Enabled && icon}
        endIcon={!allowDelete && <IconDown />}
      >
        <div className="filter-btn-content">
          {buttonLabel || defaultLabel}
        </div>
        {
          allowDelete &&
            <IconButton
              className="dynamic-filter-query__icon-delete"
              aria-label="delete"
              onClick={() => this.handleDeleteFilter()}
            >
              <IconXmark />
            </IconButton>
        }
      </Button>
    );
  }

  resetSelectedOption() {
    this.setState({ selectedOption: null });
  }

  handleToggle() {
    this.dropdownRef.current.handleToggle();
  }

  render() {
    const { options, renderOption, children, onOpen, maxHeight, maxWidth, customClassName, autoOpen } = this.props;
    const { selectedOption } = this.state;
    const optionsJsx = options?.map((option) => (
      <MenuItem key={next()} button onClick={() => this.select(option)}>
        <ListItemIcon className={!compareId(selectedOption, option) ? 'invisible' : ''}>
          <IconSave />
        </ListItemIcon>
        {renderOption(option)}
      </MenuItem>
    ));

    return (
      <PopperMenu
        maxHeight={maxHeight}
        maxWidth={maxWidth}
        ref={this.dropdownRef}
        renderAnchorElement={this.filterBtn}
        onOpen={onOpen}
        id="dropdown-filter"
        customClassName={customClassName}
        autoOpen={autoOpen}
      >
        {children}
        {optionsJsx}
      </PopperMenu>
    );
  }
}

export default DropDownFilter;


DropDownFilter.propTypes = {
  options: PropTypes.array,
  label: PropTypes.string,
  id: PropTypes.string,
  onFilterChange: PropTypes.func,
  searchKey: PropTypes.string,
  renderOption: PropTypes.func,
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]),
  buttonLabel: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  allowDelete: PropTypes.bool,
  icon: PropTypes.element
};

DropDownFilter.defaultProps = {
  allowDelete: false,
  icon: undefined,
};
