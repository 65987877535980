import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ProgressBar from '../../pages/readiness_releases_dashboard/components/ProgressBar';
import StatusConstants from '../../utils/Statuses';
import DecoratorConstants from '../../utils/DecoratorConstants';
import Routes from '../../utils/Routes';

class LatestBuildComponent extends Component {

  convertData(totalPassed, totalFailed) {
    const data = [
      {
        label: StatusConstants.PASSED.label,
        value: totalPassed,
        color: 'new-passed'
      },
      {
        label: StatusConstants.FAILED.label,
        value: totalFailed,
        color: 'new-failed'
      }
    ];
    return data;
  }

  renderLatestBuild(latestBuild) {
    const { buildStatistics } = latestBuild;
    const totalPassed = buildStatistics?.totalPassed || 0;
    const totalFailed = buildStatistics?.totalFailed || 0;
    const data = this.convertData(totalPassed, totalFailed);
    const routes = new Routes({ buildId: latestBuild.id, releaseId: latestBuild.releaseId });
    return (
      <ProgressBar
        data={data}
        primary={DecoratorConstants.idDecorator('name', latestBuild, routes.build_details_link, latestBuild.name, 'had-ellipsis')}
        showLegend={false}
      />
    );
  }

  render() {
    const { latestBuilds } = this.props;
    return (
      latestBuilds.map((latestBuild) => this.renderLatestBuild(latestBuild))
    );
  }
}

LatestBuildComponent.propTypes = {
  latestBuilds: PropTypes.array.isRequired,
};

LatestBuildComponent.defaultProps = {
  latestBuilds: null,
};

export default LatestBuildComponent;
