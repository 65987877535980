import Autocomplete from '@mui/material/Autocomplete';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TextField from '../TextField';
import { t } from '../../i18n/t';
import MContext from '../../models/MContext';
import Services from '../../utils/Services';
import { Project } from '../../models/model/Project';

interface ProjectInputProps {
    handleOnSelectChange: (projects: Project[]) => void,
    selectedProjects: Project[],
    enableFetchTagsEachTimeOpen: boolean,
}

function ProjectInput({ handleOnSelectChange, selectedProjects, enableFetchTagsEachTimeOpen }: ProjectInputProps) {

  const [projects, setProjects] = useState<Project[]>([]);
  const [isLoading, setLoading] = useState(false);
  const [alreadyGetProject, setAlreadyGetProject] = useState(false);

  const organizationId = MContext.organizationId;

  const getAllProjects = () => {
    setLoading(true);

    Services.getOrganizationProjects(organizationId)
      .then((content: []) => {
        setProjects(content);
        setLoading(false);
      });
  };

  const onOpen = () => {
    if (!alreadyGetProject) {
      getAllProjects();
      setAlreadyGetProject(true);
    }
  };

  const onClose = () => {
    if (enableFetchTagsEachTimeOpen) {
      setAlreadyGetProject(false);
    }
  };

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const handleSelectChange = (event: any, value: Project[], reason: string) => {
    if (reason !== 'removeOption' && reason !== 'createOption') {
      handleOnSelectChange(value);
    }
  };

  const onKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  return (
    <Autocomplete
      id="AgentProjectLink"
      multiple
      filterSelectedOptions
      value={selectedProjects}
      onOpen={onOpen}
      onClose={onClose}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={t('select_projects')}
          onKeyDown={onKeyDown}
        />
      )}
      disableClearable
      isOptionEqualToValue={(option, value) => option.id === value.id}
      options={projects}
      getOptionLabel={(option) => option.name}
      renderOption={(props, option) => (
        <li {...props}>
          <div title={option.name}>
            {option.name}
          </div>
        </li>
      )}
      renderTags={() => null}
      onChange={handleSelectChange}
      autoHighlight
      loading={isLoading}
    />
  );
}

ProjectInput.defaultProps = {
  enableFetchTagsEachTimeOpen: false,
};

ProjectInput.prototype = {
  enableFetchTagsEachTimeOpen: PropTypes.bool,
};

export default ProjectInput;
