import React from 'react';
import { Card, CardBody } from 'reactstrap';
import { ThemeProvider } from '@mui/material/styles';
import PageComponent from '../../components/PageComponent';
import { t } from '../../i18n/t';
import DefaultLayout from '../../components/DefaultLayout';
import ObjectSummary from '../../components/summary/ObjectSummary';
import MContext from '../../models/MContext';
import { katalonui_theme } from '../../katalonui-theme';
import GPTService from '../../components/ai/gptservice/GPTService';

class ArtificialIntelligence extends PageComponent {
  constructor(props) {
    super(props);
    this.teamId = MContext.teamId;
    this.projectId = MContext.projectId;
    this.team = MContext.team;
    this.meta.id = 'page-artificial-intelligence';
    this.meta.title = t('artificial-intelligence#title');
    this.renderHeader = this.renderHeader.bind(this);
    this.renderBody = this.renderBody.bind(this);
  }

  renderObjectSummary() {
    const urlParams = {
      artificialIntelligence: t('artificial-intelligence#title'),
      teamId: this.teamId,
      teamName: this.team.name,
    };
    return (
      <ObjectSummary params={urlParams} />
    );
  }

  renderHeader() {
    return (
      <>
        {this.renderObjectSummary()}
      </>
    );
  }

  renderGPTService() {
    return <GPTService />;
  }

  renderBody() {
    return (
      <Card className="artificial-intelligence">
        <CardBody>
          {this.renderGPTService()}
        </CardBody>
      </Card>
    );
  }

  render() {
    return (
      <ThemeProvider theme={katalonui_theme}>
        <DefaultLayout
          renderHeader={this.renderHeader}
          renderBody={this.renderBody}
        />
      </ThemeProvider>
    );
  }
}

export default ArtificialIntelligence;
