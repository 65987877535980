import React from 'react';
import { Alert, Button, Card, CardBody, CardHeader, Col, Form, FormGroup, Label, Row } from 'reactstrap';
import Input from '../../components/Input';
import PageButtonToolbar from '../../components/PageButtonToolbar';
import PageComponent from '../../components/PageComponent';
import { buildSearchCondition } from '../../components/search/SearchUtils';
import { getLicenseKeySearchConditions } from '../../components/search/LicenseUtils';
import LicenseKeysDataTable from '../../components/table/LicenseKeysTable';
import { t } from '../../i18n/t';
import MAuth from '../../models/MAuth';
import MContext from '../../models/MContext';
import { notificationIds } from '../../utils/NotificationPageUtils';
import Routes from '../../utils/Routes';
import Services from '../../utils/Services';
import MachineTable from '../../components/table/MachineTable';
import DecoratorConstants from '../../utils/DecoratorConstants';
import MConfigs from '../../models/MConfigs';
import { SearchEntity, OrganizationFeature, LicenseMode } from '../../utils/Constants';
import TableCore from '../../components/table/models/TableCore';
import DataTable from '../../components/table/DataTable';
import Apis from '../../utils/Apis';


class BaseEngineLicensesTab extends PageComponent {
  constructor(props) {
    super(props);
    // Define in subclasses
    // this.meta.id = 'page-organization-engine-licenses';
    // this.meta.title = t('engine_license_keys');
    this.organizationId = MContext.organizationId;
    this.feature = this.props.feature;
    this.isUnlimited = !(this.feature === OrganizationFeature.ENGINE);
    this.productName = this.isUnlimited ? t('unlimited_engine_license_keys') : t('engine_license_keys');
    this.fieldSuffix = this.isUnlimited ? 'UnlimitedEngine' : 'Engine';
    this.state = {
      organization: null,
      unlimitedEngineUsers: [],
    };
  }

  fetchAssignableUsers() {

  }

  // We have to fetch UNLIMITED_ENGINE users on both tabs
  // 1. On Engine tab: We need UNLIMITED_ENGINE users to extract ENGINE users to show on table
  // 2. On Unlimited Engine tab: same as above, but show in the dropdown list
  // Thus we fetch this in the Base tab.
  fetchUnlimitedEngineUsers() {
    // Because when the org purchase only ENGINE or UNLIMITED_ENGINE, there is no need to filter or extract
    if (!this.state.hasBothLicenseTypes) return;
    const params = {
      pagination: {
        page: 0,
        size: 1000,
        sorts: ['id,desc'],
      },
      conditions: [
        buildSearchCondition('Organization.id', '=', this.organizationId),
        buildSearchCondition('feature', '=', OrganizationFeature.UNLIMITED_ENGINE),
      ],
      type: SearchEntity.UserOrganizationFeature,
    };
    Services.search(params)
      .then(({ content: unlimitedEngineUsers }) => {
        this.setState({ unlimitedEngineUsers });
        if (this.isUnlimited) this.fetchAssignableUsers();
      });
  }

  componentDidMount() {
    Services.organizationWithLicense(this.organizationId, this.feature)
      .then((organization) => {
        const hasBothLicenseTypes = organization.quotaUnlimitedEngine > 0 && organization.quotaEngine > 0;
        this.setState({
          organization,
          hasBothLicenseTypes,
        });
        if (hasBothLicenseTypes) this.fetchUnlimitedEngineUsers();
      });
  }

  renderEngineQuota() {
    const { organization } = this.state;
    const quota = organization[`quota${this.fieldSuffix}`];
    const used = organization[`used${this.fieldSuffix}`];
    const machineQuota = organization[`machineQuota${this.fieldSuffix}`] ?? 0;
    const subscriptionExpiryDate = DecoratorConstants.timeDecorator(`subscriptionExpiryDate${this.fieldSuffix}`, organization);
    return (
      <Card>
        <CardHeader>{t('license_keys#quota_header')}</CardHeader>
        <CardBody>
          <Row>
            <Col sm="12" md="8" lg="6" xl="5">
              <Form data-trackid="engine-license-key-quota">
                {quota > 0 && (
                  <FormGroup>
                    <Label htmlFor="expiry_date">{t('license_keys#expiry_date')}</Label>
                    <Input id="expiry_date" plaintext>{subscriptionExpiryDate}</Input>
                  </FormGroup>
                )}
                <FormGroup>
                  <Label htmlFor="quota">{t('license_keys#quota')}</Label>
                  <Input id="quota" plaintext>{quota}</Input>
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="remaining">{t('license_keys#remaining')}</Label>
                  <Input id="remaining" plaintext>
                    {used >= quota ? 0 : quota - used}
                  </Input>
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="machine_quota">{t('license_keys#machine_quota')}</Label>
                  <Input id="machine_quota" plaintext>{this.isUnlimited && quota > 0 ? t('license_keys#unlimited') : machineQuota}</Input>
                </FormGroup>
              </Form>
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  }

  renderOnlineEngineLicenseKeyTable() {
    const defaultSearchConditions = getLicenseKeySearchConditions(this.organizationId, this.feature, LicenseMode.FLOATING);
    return (
      <Card>
        <CardHeader>{t('license_keys#online')}</CardHeader>
        <CardBody>
          <Row>
            <Col>
              <Alert color="primary">
                {t('license_keys#online-info')}
              </Alert>
            </Col>
          </Row>
          <LicenseKeysDataTable
            id="online-license"
            title={t('license_keys#online')}
            defaultSearchConditions={defaultSearchConditions}
            noCard
          />
        </CardBody>
      </Card>
    );
  }

  renderOfflineEngineLicenseKeyTable() {
    if (MConfigs.isOnPremise) {
      return null;
    }
    const defaultSearchConditions = getLicenseKeySearchConditions(this.organizationId, this.feature, LicenseMode.COMMUTER);
    return (
      <Card id="offline-license">
        <CardHeader>{t('license_keys#offline')}</CardHeader>
        <CardBody>
          <LicenseKeysDataTable
            title={t('license_keys#offline')}
            defaultSearchConditions={defaultSearchConditions}
            noCard
            isOfflineLicense={true}
            showDescription
          />
        </CardBody>
      </Card>
    );
  }

  renderMachineTable() {
    const defaultSearchConditions = [
      buildSearchCondition('Organization.id', '=', this.organizationId),
      buildSearchCondition('feature', '=', this.feature),
    ];
    return (
      <>
        <MachineTable
          id="machine"
          title={t('machine')}
          organizationId={this.organizationId}
          defaultSearchConditions={defaultSearchConditions}
        />
      </>
    );
  }

  renderCreateLicenseKey() {
    if (MConfigs.isOnPremise) {
      return null;
    }
    const organizationId = this.organizationId;
    const routes = new Routes({ organizationId });
    const { organization } = this.state;
    const quota = organization[`quota${this.fieldSuffix}`];
    const used = organization[`used${this.fieldSuffix}`];
    const canCreateOffline = organization[`canCreateOffline${this.isUnlimited ? 'UnlimitedEngine' : 'RE'}`];
    const disabled = !canCreateOffline || (used >= quota);
    const link = this.isUnlimited ?
      routes.organization_create_unlimited_engine_license_keys_link :
      routes.organization_create_engine_license_keys_link;
    return (
      <PageButtonToolbar>
        <Button
          title={t('license_keys#create_license_key')}
          color="secondary"
          href={link}
          disabled={disabled}
        >
          {t('license_keys#create_license_key')}
        </Button>
      </PageButtonToolbar>
    );
  }

  // Define in child classes
  renderLicensedUser() {
    return null;
  }

  // Define in child classes
  renderRemovalDialog() {
    return null;
  }

  renderLicensedTableOfFeature(feature) {
    const { organization } = this.state;
    const quota = organization[`quota${this.fieldSuffix}`];
    const shouldRenderData = quota > 0 && [OrganizationFeature.ENGINE, OrganizationFeature.UNLIMITED_ENGINE].includes(feature);

    return shouldRenderData ? (
      <DataTable
        id="users"
        ref={(ref) => {
          this.kseUserList = ref;
        }}
        sourceUrl={Apis.organizationLicensedUsers(this.organizationId, this.feature)}
        noCard
        columnMapping={this.userHeader()}
      />
    ) : (
      <TableCore
        data={[]}
        columnMapping={this.userHeader()}
      />
    );
  }

  render() {
    const isOrganizationManager = MAuth.isOrganizationManager(this.organizationId);
    const isBillingManager = MAuth.isBillingManager(this.organizationId);

    if (!isOrganizationManager && !isBillingManager) {
      Routes.goToAccessDeniedPage(notificationIds.NO_PERMISSION);
    }

    const {
      organization,
    } = this.state;

    if (organization) {
      return (
        <>
          {isOrganizationManager && this.renderCreateLicenseKey()}
          {this.renderEngineQuota()}
          {
            isOrganizationManager &&
            this.renderLicensedUser()
          }
          {isOrganizationManager && this.renderOnlineEngineLicenseKeyTable()}
          {isOrganizationManager && this.renderOfflineEngineLicenseKeyTable()}
          {isOrganizationManager && this.renderMachineTable()}
          {isOrganizationManager && organization.quotaEngine > 0 && organization.quotaUnlimitedEngine > 0 && this.renderRemovalDialog()}
        </>
      );
    } else {
      return null;
    }
  }
}

export default BaseEngineLicensesTab;
