import Icon from '@katalon-studio/katalon-ui/Icon';
import React from 'react';
import colors from '../../../scss/colors.scss';

function InfoIcon({ color }) {
  return <Icon
    type="fa-solid"
    name="fa-circle-info"
    textSize="14px"
    boundingSize={null}
    color={null}
    sx={{
      color,
    }}
  />;
}

InfoIcon.defaultProps = {
  color: colors.blue13,
};

export default InfoIcon;
