import React from 'react';
import parse from 'url-parse';
import { Alert, Button, Col, Row } from 'reactstrap';
import { t } from '../i18n/t';
import PageComponent from '../components/PageComponent';
import ImageProfile from '../components/avatar/ImageProfile';
import Services from '../utils/Services';
import Routes from '../utils/Routes';
import MAuth from '../models/MAuth';

class AcceptSSO extends PageComponent {
  constructor(props) {
    super(props);
    this.meta.id = 'page-accept-sso';
    this.meta.title = t('Accept SSO');
    const url = parse(window.location.href, true);
    this.token = url.query.token;

    this.user = MAuth.user;

    this.state = {
      valid: false,
      organization: null,
      disableButton: false,
      isLoading: true,
    };

    this.handleAccept = this.handleAccept.bind(this);
    this.handleDecline = this.handleDecline.bind(this);
  }

  getInfoRequest() {
    Services.getInfoSsoInvitation({ token: this.token })
      .then((userSsoInvitation) => {
        this.setState({
          organization: userSsoInvitation.organization,
          user: userSsoInvitation.user,
          valid: true,
        });
      })
      .finally(() => {
        this.setState({
          isLoading: false,
        });
      });
  }

  componentWillMount() {
    this.getInfoRequest();
  }

  handleAccept(event) {
    event.preventDefault();
    this.setState({ disableButton: true });

    Services.acceptSsoInvitation({ token: this.token })
      .then((userSsoInvitation) => {
        const organizationUrl = userSsoInvitation.organization.subdomainUrl;
        window.location.href = organizationUrl;
      });
  }

  handleDecline(event) {
    event.preventDefault();
    this.setState({ disableButton: true });

    Services.declineSsoInvitation({ token: this.token });
    Routes.goToHomePage();
  }

  render() {
    const { organization, valid, user, disableButton, isLoading } = this.state;

    if (isLoading) {
      return null;
    }

    return (
      <div className="invitation">
        {organization && valid && (
          <Row className="justify-content-center">
            <Col xs="auto">
              <ImageProfile
                name={organization.name}
                imgUrl={organization.logoUrl}
              />
            </Col>
            <Col>
              <Col xs="auto">
                <div className="org-name">
                  {organization.name}
                </div>
                <div className="header">
                  Configure Single Sign-On
                </div>
                <div className="content">
                  The Organization Admin ({user.email}) at <b>{organization.name}</b> ({organization.subdomainUrl})
                  has requested to enable Single Sign-On (SSO) for your account ({this.user.email}).
                </div>
                <Alert color="warning">
                  Once you have confirmed, you can access Katalon TestOps WITHOUT Katalon's username and password.
                </Alert>
                <div className="button-zone">
                  <Button
                    color="secondary"
                    onClick={this.handleDecline}
                    disabled={disableButton}
                  >
                    Do not allow SSO system to authenticate this account
                  </Button>
                  <Button
                    color="danger"
                    onClick={this.handleAccept}
                    disabled={disableButton}
                  >
                    {`Allow this account to access ${organization.name} via SSO`}
                  </Button>
                </div>
                <div className="footer">
                  If you do not recognize this action, report to us via <b>support@katalon.com</b>.
                </div>
              </Col>
            </Col>
          </Row>
        )}
        {!valid && (
          <Alert color="danger" className="invitation alert">
            <span>Invalid SSO invitation token.</span>
          </Alert>
        )}
      </div>
    );
  }
}

export default AcceptSSO;
