import Icon from '@katalon-studio/katalon-ui/Icon';
import React from 'react';
import colors from '../../../scss/colors.scss';

function DeleteIcon({ color, textSize }) {
  return <Icon
    type="fa-regular"
    name="fa-trash"
    textSize={textSize}
    sx={{
      color
    }}
    boundingSize={null}
    color={null}
  />;
}

DeleteIcon.defaultProps = {
  color: colors.gray9,
  textSize: '14px',
};

export default DeleteIcon;
