import React from 'react';
import { Button, Card, CardBody } from 'reactstrap';
import CustomFieldDetail from '../components/CustomFieldDetail';
import DefaultLayout from '../components/DefaultLayout';
import CustomFieldDialog from '../components/dialog/CustomFieldDialog';
import PageComponent from '../components/PageComponent';
import ObjectSummary from '../components/summary/ObjectSummary';
import { t } from '../i18n/t';
import { IconPlus } from '../images/CustomNewIcon';
import MContext from '../models/MContext';
import { MAX_PAGE_SIZE, SearchEntity } from '../utils/Constants';
import Services from '../utils/Services';
import MAuth from '../models/MAuth';
import Notification from '../utils/Notification';
import { sendAnalyticEventForAction } from '../utils/SegmentAnalytics';
import MFlags from '../models/MFlags';
import CustomFieldDialogWithRedux from '../components/dialog/CustomFieldDialogWithRedux';
import CustomFieldDialogWithReduxAndTypeScript from '../components/dialog/CustomFieldDialogWithReduxAndTypeScript';

class CustomFields extends PageComponent {
  constructor(props) {
    super(props);
    this.teamId = MContext.teamId;
    this.projectId = MContext.projectId;
    this.team = MContext.team;
    this.meta.id = 'page-custom-fields';
    this.meta.title = t('custom-fields#title');
    this.renderHeader = this.renderHeader.bind(this);
    this.renderBody = this.renderBody.bind(this);
    this.state = {
      isOpenCustomFieldDialog: false,
      customFieldDefinitions: [],
      chosenCustomFieldDefinitionId: null,
    };
  }

  componentDidMount() {
    this.getCustomFieldDefinitions();
  }

  getCustomFieldDefinitions = () => {
    const params = {
      pagination: {
        page: 0,
        size: MAX_PAGE_SIZE,
        sorts: ['id,desc'],
      },
      type: SearchEntity.CustomFieldDefinition,
    };
    return Services.searchRecursively(0, params, [])
      .then((customFieldDefinitions) => this.setState({ customFieldDefinitions }));
  }

  toggleCustomFieldDialog = () => {
    const { isOpenCustomFieldDialog } = this.state;
    this.setState({
      isOpenCustomFieldDialog: !isOpenCustomFieldDialog
    });
  }

  renderCreateBtn() {
    return (
      <Button
        id="create-new-custom-field"
        title={t('custom-fields#create-new-custom-field')}
        color="primary"
        onClick={() => this.openCreateCustomFieldDialog()}
        className="mt-4"
      >
        <IconPlus className="ml-0 mr-2" />
        {t('custom-fields#create-new-custom-field')}
      </Button>
    );
  }

  renderCustomFieldDetailList = () => {
    const { customFieldDefinitions } = this.state;
    if (customFieldDefinitions.length === 0) {
      return (
        <div className="text-center mt-4 custom-fields__empty-message">{t('table#empty-message')}</div>
      );
    }

    return customFieldDefinitions.map(
      (customFieldDefinition) =>
        <CustomFieldDetail
          customFieldDefinition={customFieldDefinition}
          openEditCustomFieldDialog={this.openEditCustomFieldDialog}
          handleDeleteCustomFieldDefinition={this.handleDeleteCustomFieldDefinition}
          key={customFieldDefinition.id}
        />
    );
  }

  renderObjectSummary() {
    const urlParams = {
      customFields: t('custom-fields#title'),
      teamId: this.teamId,
      teamName: this.team.name,
    };
    return (
      <ObjectSummary params={urlParams} />
    );
  }

  renderHeader() {
    return (
      <>
        {this.renderObjectSummary()}
      </>
    );
  }

  openEditCustomFieldDialog = (id) => {
    this.setState({
      chosenCustomFieldDefinitionId: id,
    });
    this.toggleCustomFieldDialog();
  }

  openCreateCustomFieldDialog = () => {
    this.setState({
      chosenCustomFieldDefinitionId: null,
    });
    this.toggleCustomFieldDialog();
  }

  handleEditCustomFieldDefinition = (customFieldDefinition) => {
    Services.editCustomFieldDefinition(customFieldDefinition)
      .then(() => {
        Notification.pushSuccess(t('custom-fields#edit-success'));
        this.toggleCustomFieldDialog();
        this.getCustomFieldDefinitions();
      });
  }

  handleCreateCustomFieldDefinition = (customFieldDefinition) => {
    Services.createCustomFieldDefinition(customFieldDefinition)
      .then(() => {
        Notification.pushSuccess(t('custom-fields#create-success'));
        this.toggleCustomFieldDialog();
        this.getCustomFieldDefinitions();
      });
    sendAnalyticEventForAction('create-new-custom-field', {});
  }

  handleDeleteCustomFieldDefinition = (customFieldDefinition) => {
    const displayName = customFieldDefinition.displayName;
    Services.deleteCustomFieldDefinition(customFieldDefinition)
      .then(() => {
        Notification.pushSuccess(t('custom-fields#delete-success', { displayName }));
        this.getCustomFieldDefinitions();
      });
  };

  renderCustomFieldDialog() {
    const { isOpenCustomFieldDialog, chosenCustomFieldDefinitionId } = this.state;
    if (MFlags.convertToTypeScriptEnabled) {
      return (
        <CustomFieldDialogWithReduxAndTypeScript
          isOpen={isOpenCustomFieldDialog}
          handleClose={this.toggleCustomFieldDialog}
          customFieldDefinitionId={chosenCustomFieldDefinitionId}
          handleCreateCustomFieldDefinition={this.handleCreateCustomFieldDefinition}
          handleEditCustomFieldDefinition={this.handleEditCustomFieldDefinition}
        />
      );
    } else {
      return (
        <CustomFieldDialogWithRedux
          isOpen={isOpenCustomFieldDialog}
          handleClose={this.toggleCustomFieldDialog}
          customFieldDefinitionId={chosenCustomFieldDefinitionId}
          handleCreateCustomFieldDefinition={this.handleCreateCustomFieldDefinition}
          handleEditCustomFieldDefinition={this.handleEditCustomFieldDefinition}
        />
      );
    }
  }

  renderBody() {
    const isTeamManager = MAuth.isTeamManager(this.teamId);
    const { isOpenCustomFieldDialog } = this.state;

    return (
      <Card className="custom-fields__full-page">
        <CardBody
          className="mb-4"
        >
          {isTeamManager && this.renderCreateBtn()}
          {this.renderCustomFieldDetailList()}
        </CardBody>
        {isOpenCustomFieldDialog && this.renderCustomFieldDialog()}
      </Card>
    );
  }

  render() {
    return (
      <DefaultLayout
        renderHeader={this.renderHeader}
        renderBody={this.renderBody}
      />
    );
  }
}

export default CustomFields;
