import React from 'react';
import PropTypes from 'prop-types';
import userflow from 'userflow.js';
import { Button } from 'reactstrap';

const UserFlowButton = (props) => {

  const { userFlowId, triggerEvent } = props;

  const startUserFlow = () => {
    userflow.start(userFlowId).catch((e) => {
      // eslint-disable-next-line no-console
      console.error(e);
    });

    if (triggerEvent) {
      triggerEvent();
    }
  };

  return (
    <Button
      {...props}
      color="primary"
      variant="contained"
      onClick={startUserFlow}
    >
      {props.children}
    </Button>
  );
};

UserFlowButton.propTypes = {
  userFlowId: PropTypes.string.isRequired,
};

export default UserFlowButton;
