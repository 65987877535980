import React, { forwardRef, useEffect, useRef, useState, useImperativeHandle } from 'react';
import { fabric } from 'fabric';
import PropTypes from 'prop-types';
import { clamp, isNil, round } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import AutoScroll from './AutoScroll';
import Notification from '../../utils/Notification';
import { t } from '../../i18n/t';
import { sendAnalyticEventForAction } from '../../utils/SegmentAnalytics';
import GroupEvent from '../../utils/track/GroupEvent';
import { IgnoredZoneType, KeyboardButton, ID_BUTTON_DRAW_ZONES } from '../../utils/Constants';
import CanvasToolbar from './CanvasToolbar';
import { IconApply, IconDelete } from '../../images/CustomNewIcon';
import MFlags from '../../models/MFlags';
import Arrays from '../../utils/Arrays';

const VisualTestingDraw = forwardRef(({
  isAllowDraw,
  shapeProperties,
  width,
  height,
  backgroundImage,
  coordinatesList,
  setCoordinatesList,
  maxIgnoredZones,
  disableDrawMode,
  openAcknowledgeDialog,
  onBeforeDeleteIgnoredZone,
  dialogIds }, ref) => {
  const canvasRef = useRef(null);
  const [canvas, setCanvas] = useState('');
  const [toolbarProperties, setToolbarProperties] = useState();
  let rect;
  let isDown;
  let origX;
  let origY;
  // Is the state when the user selects the image to scale, maintain the image.
  let onScale = false;
  // Is the state when the user selects the image to resize, maintain the image.
  let onMoving = false;
  const { stroke, strokeWidth, fill, typeAllStroke, typeAllFill } = shapeProperties;

  const handleCloseToolbar = () => {
    setToolbarProperties(null);
  };

  const handleOpenToolbar = (activeObject, event) => {

    // Default: toolbar is open on the top right of object and higher than object.
    let left = activeObject.left + activeObject.width;
    let top = activeObject.top - 50;

    const isTopLimit = top <= 0;
    const isRightLimit = left + 200 >= width;

    // To define a gap between image width and ignored zone width
    const isWidthLimit = width - activeObject.width <= 250;

    const mouseLeft = event.layerX;
    const mouseTop = event.layerY;

    // To define is mouse position will reach nearly max-width of the image
    const maxMouseLeft = width - mouseLeft <= 100;

    // if the object is reached the top of canvas, render toolbar next to object
    if (isTopLimit) {
      left = activeObject.left + activeObject.width + 20;
      top = activeObject.top;
    }

    // if the object is reached the right of canvas, render toolbar in the left of object
    if (isRightLimit) {
      left = activeObject.left - 30;
    }

    // if the object is both reached top and right of canvas, render toolbar in the left and next to object
    if (isTopLimit && isRightLimit) {
      top = activeObject.top;
      left = activeObject.left - 100;
    }

    // if the ignoring zone width nearly reach image width, render toolbar on mouse position
    if (isWidthLimit) {
      top = mouseTop;
      // if the mouse left is nearly image width limit, render toolbar on the left of mouse position
      left = maxMouseLeft ? mouseLeft - 100 : mouseLeft;
    }


    setToolbarProperties({ left, top, type: activeObject.ignoredZoneType });
  };

  const updateIgnoringAllZoneProperty = (rect) => {
    rect.fill = typeAllFill;
    rect.stroke = typeAllStroke;
  };

  const convertToRect = (coordinates) => {
    const newRect = new fabric.Rect({
      left: coordinates?.x,
      top: coordinates?.y,
      width: coordinates?.w,
      height: coordinates?.h,
      fill: fill || '',
      stroke: stroke || 'red',
      type: 'rect',
      uuid: coordinates?.uuid,
      // The ignoredZoneType of rect is the type of coordinates
      ignoredZoneType: coordinates?.type,
      strokeWidth: strokeWidth || '5',
    });
    // Not allow to rotate rect
    newRect.setControlVisible('mtr', false);
    if (coordinates?.type === IgnoredZoneType.ALL) {
      updateIgnoringAllZoneProperty(newRect);
    }
    return newRect;
  };

  const convertToCoordinates = (rect) => ({
    x: round(rect.left),
    y: round(rect.top),
    w: round(rect.width),
    h: round(rect.height),
    uuid: rect.uuid,
    // The ignoredZoneType of rect is the type of coordinates
    type: rect.ignoredZoneType,
  });

  const objectMouseDownListener = (o) => {
    isDown = true;
    const pointer = canvas.getPointer(o.e);
    origX = pointer.x;
    origY = pointer.y;
    if (!onMoving) {
      rect = new fabric.Rect({
        left: origX,
        top: origY,
        width: pointer.x - origX,
        height: pointer.y - origY,
        fill: fill || '',
        stroke: stroke || 'red',
        type: 'rect',
        uuid: uuidv4(),
        ignoredZoneType: IgnoredZoneType.SINGLE,
        strokeWidth: strokeWidth || '5',
      });
      // Not allow to rotate rect
      rect.setControlVisible('mtr', false);

      if (!canvas.getActiveObject()) {
        canvas.add(rect);
      }
    }
  };

  const objectMouseMoveListener = (o) => {
    if (isDown) {
      const pointer = canvas.getPointer(o.e);
      // Check when draw to limit area
      pointer.x = clamp(pointer.x, 0, pointer.x); // Minimum x coordinate limit is 0
      pointer.y = clamp(pointer.y, 0, pointer.y); // Minimum y coordinate limit is 0

      if (!onMoving) {
        if (origX > pointer.x) {
          rect.set({ left: pointer.x });
        }
        if (origY > pointer.y) {
          rect.set({ top: pointer.y });
        }

        rect.set({ width: Math.abs(origX - pointer.x) });
        rect.set({ height: Math.abs(origY - pointer.y) });
      }
      handleCloseToolbar();
      canvas.renderAll();
    }
  };

  const objectMouseUpListener = ({ e }) => {
    onMoving = false;
    isDown = false;

    // Check when scale to limit area
    const activeObject = canvas.getActiveObject();
    if (activeObject) {
      // Check if coordinates x (activeObject.left) < 0 then set x = 0 and minus the amount that was spilled for width.
      if (activeObject.left < 0) {
        activeObject.width -= Math.abs(activeObject.left);
        activeObject.left = 0;
      }
      // Check if coordinates x (activeObject.left) >= and x + width > width then minus the amount that was spilled for width.
      if (activeObject.left >= 0 && activeObject.left + activeObject.width > width) {
        activeObject.width = width - activeObject.left;
      }
      // Check if coordinates y (activeObject.top) < 0 then set y = 0 and minus the amount that was spilled for height.
      if (activeObject.top < 0) {
        activeObject.height -= Math.abs(activeObject.top);
        activeObject.top = 0;
      }
      // Check if coordinates y (activeObject.top) >= and y + height > height then minus the amount that was spilled for height.
      if (activeObject.top >= 0 && activeObject.top + activeObject.height > height) {
        activeObject.height = height - activeObject.top;
      }

      handleOpenToolbar(activeObject, e);

    } else {
      handleCloseToolbar();
    }

    if (onScale) {
      // Remove old rect.
      canvas.remove(activeObject);

      // Create new rect with value edited - to scale.
      const newRect = new fabric.Rect({
        left: activeObject.left,
        top: activeObject.top,
        width: activeObject.width,
        height: activeObject.height,
        fill: activeObject.fill,
        stroke: activeObject.stroke,
        type: 'rect',
        uuid: activeObject.uuid,
        ignoredZoneType: activeObject.ignoredZoneType,
        strokeWidth: activeObject.strokeWidth,
      });

      // Not allow to rotate rect
      newRect.setControlVisible('mtr', false);

      // Add rect to canvas
      canvas.add(newRect);

      // Re set state for onScale
      onScale = false;
    }

    // Check when draw to limit area
    if (rect.left >= 0 && rect.left + rect.width > width) { // Check if coordinates x (rect.left) >= and x + width > width then minus the amount that was spilled for width.
      rect.width = width - rect.left;
    }
    if (rect.top >= 0 && rect.top + rect.height > height) { // Check if coordinates y (rect.top) >= and y + height > height then minus the amount that was spilled for height.
      rect.height = height - rect.top;
    }
    // Check rect has is a click not draw
    if (round(rect.width) === 0 || round(rect.height) === 0) {
      canvas.remove(rect);
    }
    // Remove when user draw > maxIgnoredZones.
    const objsTemp = canvas.getObjects();
    if (objsTemp.length > maxIgnoredZones) {
      canvas.remove(rect);
      Notification.pushError(t('visual-testing#max-ignored-zones'));
    }
    // Set coordinates for proper mouse interaction
    const objs = canvas.getObjects();
    const newCoordinatesList = [];
    for (let i = 0; i < objs.length; i++) {
      objs[i].setCoords();
      newCoordinatesList.push(convertToCoordinates(objs[i]));
    }
    setCoordinatesList(newCoordinatesList);
  };

  const objectMouseMovingListener = () => {
    // Check when moving to limit area
    const activeObject = canvas.getActiveObject();
    // Minimum activeObject.left coordinate limit is 0
    activeObject.left = clamp(activeObject.left, 0, activeObject.left);
    // Check if coordinates x (activeObject.left) >= and x + width > width then minus the amount that was spilled for width.
    if (activeObject.left >= 0 && activeObject.left + activeObject.width > width) {
      activeObject.left = width - activeObject.width;
    }
    // Minimum activeObject.top coordinate limit is 0
    activeObject.top = clamp(activeObject.top, 0, activeObject.top);
    // Check if coordinates y (activeObject.top) >= and y + height > height then minus the amount that was spilled for height.
    if (activeObject.top >= 0 && activeObject.top + activeObject.height > height) {
      activeObject.top = height - activeObject.height;
    }
    onMoving = true;
  };

  const objectScaleListener = () => {
    onMoving = true;
  };

  const objectMouseModifiedListener = (e) => {
    onMoving = true;
    try {
      const obj = e.target;
      obj.width *= obj.scaleX;
      obj.height *= obj.scaleY;
      obj.scaleX = 1;
      obj.scaleY = 1;
      onScale = true;
      // eslint-disable-next-line no-empty
    } catch (e) {
    }
  };

  const handleDeleteRectangle = () => {
    const activeObject = canvas.getActiveObject();
    if (!isNil(activeObject)) {
      sendAnalyticEventForAction('delete-ignoring-zone', { 'data-groupid': GroupEvent.ACCESS_REPORT });
      if (activeObject.ignoredZoneType === IgnoredZoneType.SINGLE) {
        canvas.remove(activeObject);
        const newCoordinatesList = canvas.getObjects().map((item) => convertToCoordinates(item));
        setCoordinatesList(newCoordinatesList);
      } else if (activeObject.ignoredZoneType === IgnoredZoneType.ALL) {
        if (onBeforeDeleteIgnoredZone) {
          onBeforeDeleteIgnoredZone();
        }
      }
    }
  };

  const handleOnKeyDown = (event) => {
    // Handle delete
    if (event.keyCode === KeyboardButton.DELETE || event.keyCode === KeyboardButton.BACKSPACE) {
      handleDeleteRectangle();
      handleCloseToolbar();
    }
  };

  useEffect(() => {
    const canvas = new fabric.Canvas('canvas-visual-testing', {
      height,
      width,
      backgroundImage,
    });
    canvas.selection = false;
    // initial canvas from coordinatesList
    for (const coordinates of coordinatesList) {
      canvas.add(convertToRect(coordinates));
    }
    setCanvas(canvas);
  }, []);

  const checkDialogAvailable = () => dialogIds.some((item) => !!document.getElementById(item));

  const handleDisableDrawModeWhenPressEsc = (event) => {
    if (event.keyCode === KeyboardButton.ESC && disableDrawMode && !checkDialogAvailable()) {
      handleCloseToolbar();
      disableDrawMode();
      // Remove focus from button
      document.getElementById(ID_BUTTON_DRAW_ZONES).blur();
    }
  };

  const handleDisableDrawModeWhenClickOutside = (event) => {
    // if user not click inside the canvas or the button trigger draw mode, then disable draw mode
    if (canvasRef.current
      && !canvasRef.current.contains(event.target)
      && event.target.id !== ID_BUTTON_DRAW_ZONES
      && !checkDialogAvailable()
      && disableDrawMode) {
      handleCloseToolbar();
      disableDrawMode();
    }
  };

  useEffect(() => {
    if (canvas) {
      if (isAllowDraw) {
        canvas.on('mouse:down', objectMouseDownListener);

        canvas.on('mouse:move', objectMouseMoveListener);

        canvas.on('mouse:up', objectMouseUpListener);

        canvas.on('object:modified', objectMouseModifiedListener);

        canvas.on('object:moving', objectMouseMovingListener);

        canvas.on('object:scaling', objectScaleListener);

        // Handle allow rect in canvas select.
        canvas.forEachObject((rect) => {
          rect.selectable = true;
        });
        canvas.hoverCursor = 'move';

        // Add event key down and mousedown
        window.addEventListener('keydown', handleOnKeyDown);
        document.addEventListener('keydown', handleDisableDrawModeWhenPressEsc, true);
        document.addEventListener('mousedown', handleDisableDrawModeWhenClickOutside);
      } else {
        // Check move not allow draw
        canvas.__eventListeners = {};
        canvas.forEachObject((rect) => {
          rect.selectable = false;
        });
        if (canvas.getActiveObject()) {
          canvas.discardActiveObject().renderAll();
        }
        canvas.hoverCursor = 'default';
      }
    }
    return () => {
      document.removeEventListener('keydown', handleDisableDrawModeWhenPressEsc, true);
      document.removeEventListener('mousedown', handleDisableDrawModeWhenClickOutside);
      window.removeEventListener('keydown', handleOnKeyDown);
    };
  }, [canvas, isAllowDraw]);

  const applyIgnoringZoneToAll = () => {
    const activeObject = canvas.getActiveObject();
    if (activeObject) {
      // Create new rect with value edited - to update to type all
      const newRect = new fabric.Rect({
        left: activeObject.left,
        top: activeObject.top,
        width: activeObject.width,
        height: activeObject.height,
        fill: typeAllFill || '',
        stroke: typeAllStroke || 'red',
        type: 'rect',
        uuid: activeObject.uuid,
        ignoredZoneType: IgnoredZoneType.ALL,
        strokeWidth: strokeWidth || '5',
      });
      newRect.setControlVisible('mtr', false);
      canvas.remove(activeObject);
      canvas.add(newRect);
      const newCoordinates = canvas.getObjects().map((canvasObject) => {
        canvasObject.setCoords();
        return convertToCoordinates(canvasObject);
      });
      setCoordinatesList(newCoordinates);
      sendAnalyticEventForAction('apply-ignored-zone-all-images', { 'data-groupid': GroupEvent.ACCESS_REPORT });
      if (openAcknowledgeDialog) {
        openAcknowledgeDialog();
      }
    }
  };

  const deleteIgnoringZone = () => {
    handleDeleteRectangle();
  };

  useImperativeHandle(ref, () => ({

    handleDeleteIgnoredZone() {
      const activeObject = canvas.getActiveObject();
      canvas.remove(activeObject);
      const newCoordinatesList = canvas.getObjects().map((item) => convertToCoordinates(item));
      setCoordinatesList(newCoordinatesList);
    }

  }));

  const toolbarItems = [
    ...Arrays.insertIf(toolbarProperties && toolbarProperties.type !== IgnoredZoneType.ALL, {
      icon: <IconApply />,
      handle: applyIgnoringZoneToAll,
      tooltip: t('apply_all_ignored_zone'),
    }),
    ...Arrays.insertIf(toolbarProperties && toolbarProperties.type !== IgnoredZoneType.ALL, {
      divider: true,
    }),
    {
      icon: <IconDelete />,
      tooltip: t('delete_ignored_zone'),
      handle: deleteIgnoringZone,
    }
  ];

  return (
    <AutoScroll>
      <div ref={canvasRef}>
        <canvas id="canvas-visual-testing" />
        <CanvasToolbar
          toolbarProperties={toolbarProperties}
          onClose={handleCloseToolbar}
          toolbarItems={toolbarItems}
        />
      </div>
    </AutoScroll>
  );
});

VisualTestingDraw.propTypes = {
  /**
   * Flag allow draw
   */
  isAllowDraw: PropTypes.bool,
  /**
   * Properties of the shape like stroke,...
   */
  shapeProperties: PropTypes.object,
  /**
   * Width of the drawing frame
   */
  width: PropTypes.number,
  /**
   * Height of the drawing frame
   */
  height: PropTypes.number,
  /**
   * Background image of the drawing frame
   */
  backgroundImage: PropTypes.string,
  /**
   * Max Ignored Zones user can draw.
   */
  maxIgnoredZones: PropTypes.number,
  /**
   * Handle open confirm delete ignoring-type-all zone dialog
   */
  onBeforeDeleteIgnoredZone: PropTypes.func,
  /**
   * List dialog ids will interactive in this Visual Testing Draw component
   */
  dialogIds: PropTypes.array
};

VisualTestingDraw.defaultProps = {
  shapeProperties: {},
  width: 0,
  height: 0,
  backgroundImage: null,
  isAllowDraw: false,
  maxIgnoredZones: 20
};

export default VisualTestingDraw;
