import React, { useEffect, useState } from 'react';
import Button from '@katalon-studio/katalon-ui/Button';
import { Dialog, DialogBody, DialogFooter, DialogHeader } from '@katalon-studio/katalon-ui/Dialog';
import { isEmpty } from 'lodash';
import { closeSnackbar } from 'notistack';
import { AppHelper } from '../../utils/AppHelper';
import Notification from '../../utils/Notification';
import Routes from '../../utils/Routes';
import { t } from '../../i18n/t';
import { KATALON_EVENTS, ACTIONS_TRACKING, CloudType, DISPLAYED_PAGE_TRACKING } from '../../utils/Constants';
import { TestCase } from '../../models/model/TestCase';
import InfoIcon from '../../../images/icons/katalonui/InfoIcon';
import AlertComponent from '../AlertComponent';
import { DomEventHandlers } from '../../utils/EventHandler';
import TestEnvironmentSelector from '../TestEnvironmentSelector';
import { TestEnvironmentOption } from '../../models/model/TestEnvironmentOption';
import ActionsHelper from '../../utils/table/ActionsHelper';
import Services from '../../utils/Services';
import NotificationHandler from '../cloud-studio-component/handler/NotificationHandler';
import { TestType } from '../../models/model/TestType';
import { Framework } from '../../models/model/Framework';
import { buildTestCloudEnvironmentResources } from '../smarttestscheduling/services/testcloud';
import Link from '../Link';

function ConfigureTestRunDialog() {
  const [testCase, setTestCase] = useState<TestCase | null>();
  const [isOpen, setOpen] = useState<boolean>(false);
  const [currentEnvironments, setCurrentEnvironments] = useState<TestEnvironmentOption[]>([]);
  const [displayPageTracking, setDisplayPage] = useState<string>('');

  useEffect(() => {
    DomEventHandlers.eventListener(KATALON_EVENTS.openConfigureTestRunDialog, (e: any) => {
      const testCase: TestCase = e.detail.testCase;
      const displayPageTracking = e.detail.displayPageTracking;
      setTestCase(testCase);
      setDisplayPage(displayPageTracking);
      setOpen(true);
    });
  }, []);

  if (testCase === null || testCase === undefined || testCase.testType === TestType.MANUAL_TEST_CASE) {
    return null;
  }

  const { testProject } = testCase;
  if (!testProject) {
    return null;
  }

  const handleClose = () => {
    setCurrentEnvironments([]);
    setTestCase(null);
    setOpen(false);
  };

  const closeNotification = () => {
    closeSnackbar();
  };

  const renderSuccessMessage = (testRunId: number) => {
    const routes = new Routes();
    const htmlMessages =
      <div>
        Test case <b>{testCase.name}</b> has been executed.
        <div className="mt-1" onClick={closeNotification}>
          <Link href={routes.goToTestPlanningPage({ testRunId })}>{t('view-run-progress')}</Link>
        </div>
      </div>;

    Notification.pushSuccess(null, t('done'), 5000, htmlMessages);
  };

  const renderErrorMessage = (message: string, _label: string, jqXHR: any) => {
    NotificationHandler.runTestCaseErrorHandler(message, jqXHR, testCase.name);
  };

  const sendTrackingData = () => {
    const data = { testCase };
    ActionsHelper.syncTrackingData(
      data,
      ACTIONS_TRACKING.TEST_CASE_WAS_RAN_INSTANTLY,
      displayPageTracking
    );
  };

  const buildRunTestCaseResources = () => {
    // Build list test cases
    const { name, path } = testCase;
    const testCaseResources = [{ name, path }];

    // Build run configuration resource
    const convertedTestEnvironments = buildTestCloudEnvironmentResources(currentEnvironments);
    const runConfiguration = {
      cloudType: CloudType.TEST_CLOUD_AGENT,
      testCloudAgents: convertedTestEnvironments
    };

    const runTestCasesResources = {
      testCases: testCaseResources,
      runConfiguration,
    };
    return runTestCasesResources;
  };

  const handleRunTest = (e: any) => {
    e.preventDefault();
    sendTrackingData();

    Services.runTestCase(testProject.id, buildRunTestCaseResources(), renderErrorMessage)
      .then((executions) => {
        const testRunId = executions[0].id;

        if (displayPageTracking === DISPLAYED_PAGE_TRACKING.CLOUD_STUDIO_EDITOR) {
          AppHelper.openCustomBlockedUI();
          Routes.goToPlannedTestRunPage(testRunId);
        } else {
          renderSuccessMessage(testRunId);
          handleClose();
        }
      });
  };

  const generateFramework = () => {
    const { testType } = testCase;
    let frameWork = Framework.KATALON_STUDIO;
    if (testType === TestType.G5_TEST_CASE) {
      frameWork = Framework.CLOUD_STUDIO;
    }
    return frameWork;
  };

  const generateContent = () => (
    <>
      <TestEnvironmentSelector
        onSelectEnvironment={setCurrentEnvironments}
        frameWork={generateFramework()}
      />
      <AlertComponent
        text={t('configure-test-run#info')}
        icon={<InfoIcon />}
        className="info mt-2"
      />
    </>
  );

  const disabled = isEmpty(currentEnvironments);

  return (
    <Dialog
      open={isOpen}
      maxWidth="sm"
    >
      <DialogHeader onClick={handleClose}>
        {t('configure-test-run')}
      </DialogHeader>
      <DialogBody>
        {generateContent()}
      </DialogBody>
      <DialogFooter className="d-flex justify-content-end publish-dialog__footer">
        <Button
          size="medium"
          variant="text"
          color="primary"
          onClick={handleClose}
        >
          {t('cancel')}
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleRunTest}
          disabled={disabled}
        >
          {t('run-test')}
        </Button>
      </DialogFooter>
    </Dialog>
  );
}

export default ConfigureTestRunDialog;
