import _ from 'lodash';
import React from 'react';
import { Button, Card, CardBody, CardHeader, Col, Label, Row } from 'reactstrap';
import PageComponent from '../components/PageComponent';
import ObjectSummary from '../components/summary/ObjectSummary';
import { t } from '../i18n/t';
import MContext from '../models/MContext';
import Apis from '../utils/Apis';
import http from '../utils/http';
import Services from '../utils/Services';
import Input from '../components/Input';
import DefaultLayout from '../components/DefaultLayout';
import PageButtonToolbar from '../components/PageButtonToolbar';
import Routes from '../utils/Routes';
import Link from '../components/Link';

class Request extends PageComponent {
  constructor(props) {
    super(props);
    this.meta.id = 'page-execution-request-detail';
    this.meta.title = t('Request');
    this.executionRequestId = MContext.executionRequestId;
    this.executionTestResultId = MContext.executionTestResultId;
    this.executionId = MContext.executionId;
    this.renderHeader = this.renderHeader.bind(this);
    this.renderBody = this.renderBody.bind(this);
    this.state = {
      executionRequest: null,
      testResult: null,
    };
  }

  componentDidMount() {
    this.getExecutionRequest();
    this.getTestResult(this.executionTestResultId);
  }

  getExecutionRequest() {
    http.get(Apis.executionRequest(this.executionRequestId))
      .then((responseJson) => {
        const executionRequest = responseJson;
        Services.getFileContent(executionRequest.uploadFile.fileHandleId).then((response) => {
          executionRequest.harFile = response;
          executionRequest.harEntry = _.find(executionRequest.harFile.log.entries, () => true);
          this.setState({
            executionRequest,
          });
        });
      });
  }

  getTestResult(id) {
    http.get(Apis.executionTestResult(id))
      .then((responseJson) => {
        const testResult = responseJson;
        this.setState({
          testResult,
        });
      });
  }

  renderToolbar() {
    return (
      <PageButtonToolbar>
        <Button
          data-trackid="download-har"
          href={Apis.executionRequestDownload(this.executionRequestId, { fileType: 'har' })}
        >Export as HAR
        </Button>
      </PageButtonToolbar>
    );
  }

  renderHeaders() {
    return (
      <div>
        {this.renderGeneral()}
        {this.renderResponseHeaders()}
        {this.renderRequestHeaders()}
        {this.renderParameters()}
        {this.renderRequestPayload()}
      </div>
    );
  }

  renderGeneral() {
    const {
      executionRequest,
    } = this.state;
    const { object } = executionRequest;
    const routes = new Routes({
      testObjectId: object.id,
    });
    const testObjectLink = <Link href={routes.execution_test_object_history_link}>{object.name}</Link>;
    return (
      <Card>
        <CardHeader>
          {t('execution_request#general')}
        </CardHeader>
        <CardBody>
          {this.renderRow('name', t('name'), testObjectLink)}
          {this.renderRow('method', t('method'), executionRequest.method)}
          {this.renderRow('url', t('url'), executionRequest.url)}
          {this.renderRow('status', t('status'), executionRequest.status)}
          {this.renderRow('statusText', t('status_text'), executionRequest.statusText)}
          {this.renderTimeRow('time', t('time'), executionRequest.time)}
          {this.renderSizeRow('size', t('execution_request#size'), executionRequest.size)}
        </CardBody>
      </Card>
    );
  }

  renderRequestHeaders() {
    const {
      headers,
    } = this.state.executionRequest.harEntry.request;
    return (
      <Card>
        <CardHeader>
          {t('execution_request#request_header')}
        </CardHeader>
        <CardBody>
          {headers.map((header) => this.renderRow('', t(header.name), header.value))}
        </CardBody>
      </Card>
    );
  }

  renderResponseHeaders() {
    const harEntry = this.state.executionRequest.harEntry;
    const headers = _.get(harEntry, ['response', 'headers'], []);
    return (
      <Card>
        <CardHeader>
          {t('execution_request#response_header')}
        </CardHeader>
        <CardBody>
          {headers.map((header) => this.renderRow('', t(header.name), header.value))}
        </CardBody>
      </Card>
    );
  }

  renderParameters() {
    const {
      queryString,
    } = this.state.executionRequest.harEntry.request;
    if (!_.isEmpty(queryString)) {
      return (
        <Card>
          <CardHeader>
            {t('execution_request#request_params')}
          </CardHeader>
          <CardBody>
            {queryString.map((header) => this.renderRow('', t(header.name), header.value))}
          </CardBody>
        </Card>
      );
    } else {
      return null;
    }
  }

  renderRequestPayload() {
    const {
      payload,
      postData
    } = this.state.executionRequest.harEntry.request;
    const requestBody = payload || postData?.text;
    if (!_.isEmpty(requestBody)) {
      return (
        <Card>
          <CardHeader>
            {t('execution_request#request_payload')}
          </CardHeader>
          <CardBody>
            <Row>
              <Col md={12}>
                {requestBody}
              </Col>
            </Row>
          </CardBody>
        </Card>
      );
    } else {
      return null;
    }
  }

  renderResponse() {
    const harEntry = this.state.executionRequest.harEntry;
    const responseBody = _.get(harEntry, ['response', 'content', 'text']);
    return (
      <Card>
        <CardHeader>
          {t('execution_request#response_content')}
        </CardHeader>
        <CardBody>
          <Row>
            <Col md={12}>
              {responseBody}
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  }

  renderTiming() {
    const {
      timings,
    } = this.state.executionRequest.harEntry;
    return (
      <Card>
        <CardHeader>
          {t('execution_request#timings')}
        </CardHeader>
        <CardBody>
          {_.map(timings, (value, key) => this.renderTimeRow('', t(key), value))}
        </CardBody>
      </Card>
    );
  }

  renderTimeRow(id, name, value) {
    const displayValue = _.isNumber(value) ? `${value} ms` : value;
    return this.renderRow(id, name, displayValue);
  }

  renderSizeRow(id, name, value) {
    const displayValue = _.isNumber(value) ? `${value} bytes` : value;
    return this.renderRow(id, name, displayValue);
  }

  renderRow(id, name, value) {
    return (
      <Row key={name}>
        <Label md={4} lg={2} for={id}>{name}</Label>
        <Col md={8} lg={10}>
          <Input plaintext id={id}>{value}</Input>
        </Col>
      </Row>
    );
  }

  renderObjectSummary() {
    const { testResult } = this.state;
    const urlParams = {
      testCaseName: testResult.testCase.name,
      executionId: testResult.execution.order,
      requestExecution: t('requests'),
    };
    return <ObjectSummary params={urlParams} />;
  }

  renderHeader() {
    return (
      <>
        {this.renderObjectSummary()}
      </>
    );
  }

  renderBody() {
    return (
      <>
        {this.renderToolbar()}
        {this.renderHeaders()}
        {this.renderResponse()}
        {this.renderTiming()}
      </>
    );
  }

  render() {
    const {
      executionRequest,
    } = this.state;
    if (executionRequest) {
      return (
        <DefaultLayout
          renderHeader={this.renderHeader}
          renderBody={this.renderBody}
        />
      );
    }
    return null;
  }
}

export default Request;
