import React, { useEffect, useMemo, useState } from 'react';
import { isEmpty, pick, isEqual } from 'lodash';
import SelectTestEnvironmentDialog from '../../dialog/SelectTestEnvironmentDialog';
import {
  buildDisplayExecutionEnvironmentForSelect,
  buildTestCloudAgent,
  ExtractLastedValues,
  isMobile
} from '../services/testcloud';
import { BrowserType, CloudType, ConfigType, TestSuiteType } from '../../../utils/Constants';
import { t } from '../../../i18n/t';
import CustomSelect from '../../CustomSelect';
import DecoratorConstants from '../../../utils/DecoratorConstants';
import MFlags from '../../../models/MFlags';

function ExecutionEnvironmentSelect(props) {

  const { value, apps, testProject, executionEnvironments, onChange, localAgents, circleCIAgents, k8sAgents, configType,
    title, g5ExecutionEnvironments, tsType, isReset, isShowDropdown = true } = props;
  const [openedDialog, setOpenedDialog] = useState(false);
  const [envSelected, setEnvSelected] = useState([]);
  const [testSuiteAgents, setTestSuiteAgents] = useState([]);

  const [suggestedEnvironments, setSuggestedEnvironments] = useState([]);

  const extractLastedValues = () => [
    ...ExtractLastedValues(['windows', 'chrome'], executionEnvironments?.data, 1),
    ...ExtractLastedValues(['linux', 'chrome'], executionEnvironments?.data, 1),
    ...ExtractLastedValues(['windows', 'firefox'], executionEnvironments?.data, 1),
    ...ExtractLastedValues(['macos', 'safari'], executionEnvironments?.data, 1),
  ];

  const extractG5Env = () => [
    ...ExtractLastedValues(['linux', 'chrome'], g5ExecutionEnvironments?.data, 1),
    ...ExtractLastedValues(['linux', 'firefox'], g5ExecutionEnvironments?.data, 1),
  ];// eslint-disable-line react-hooks/exhaustive-deps

  const topEnv = useMemo(
    () =>
      (tsType === TestSuiteType.CLOUD_STUDIO && MFlags.testCloudG5TestSuiteEnabled ? extractG5Env() : extractLastedValues()).map((value) => ({
        label: buildTestCloudAgent({ os: value[0], browser: value[1], browserVersion: value[2], browserVersionType: value[3] }),
        icons: [DecoratorConstants.iconByOS(value[0]), DecoratorConstants.iconByBrowser(value[1])],
        prefixOption: 'TestCloud',
        value: {
          os: value[0],
          browser: value[1],
          browserVersion: value[2],
          browserVersionType: value[3],
        },
        disabled: configType === ConfigType.GENERIC_COMMAND
      })),
    [tsType, executionEnvironments] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const validateSelected = (values, option) => values.some((el) => {
    if (el.value?.headless) {
      return isEqual(
        pick(el.value, ['os', 'browser', 'browserVersion', 'headless', 'browserVersionType']),
        pick(option, ['os', 'browser', 'browserVersion', 'headless', 'browserVersionType'])

      );
    } else {
      return isEqual(
        pick(el.value, ['os', 'browser', 'browserVersion', 'browserVersionType']),
        pick(option, ['os', 'browser', 'browserVersion', 'browserVersionType'])

      );
    }
  });

  const getTopEnv = (values, cloudType, browserType) => {
    if (isEmpty(values) || (cloudType === CloudType.TEST_CLOUD_AGENT && !isMobile(values[0]?.value?.os) && browserType !== BrowserType.WEB_SERVICE)) {
      return topEnv.map((el) => ({
        ...el,
        disabled: configType === ConfigType.GENERIC_COMMAND || validateSelected(values, el.value)
      }));
    }
    return topEnv.map((el) => ({
      ...el,
      disabled: true,
    }));
  };
  useEffect(() => {
    setSuggestedEnvironments(topEnv);
  }, [topEnv]);

  const selectTestEnvironment = (selectedExecutionEnvironments) => {
    setOpenedDialog(false);
    const filterExecutionEnvironments = selectedExecutionEnvironments.filter(({ isNotTestEnv }) => isNotTestEnv !== true);
    const result = filterExecutionEnvironments.map((el) => ({
      value: { ...el },
      ...buildDisplayExecutionEnvironmentForSelect(el)
    }));
    setSuggestedEnvironments(getTopEnv(result, filterExecutionEnvironments[0]?.cloudType, filterExecutionEnvironments[0]?.browserType));
    setEnvSelected(result);
    setTestSuiteAgents(selectedExecutionEnvironments);
    onChange(filterExecutionEnvironments);
  };

  useEffect(() => {
    if (!isEmpty(value)) {
      const agents = value.map((el) => ({
        value: { ...el },
        ...buildDisplayExecutionEnvironmentForSelect(el)
      }));
      setEnvSelected(agents);
      setTestSuiteAgents(value);
      setSuggestedEnvironments(getTopEnv(agents, value[0]?.cloudType, value[0]?.browserType));
    }
  }, [value, tsType]); // eslint-disable-line react-hooks/exhaustive-deps


  const closeDialog = () => {
    setOpenedDialog(false);
    onChange();
  };

  const selectDefaultEnv = (values) => {
    const enabledTestCloudTunnel = !isEmpty(testSuiteAgents) ? testSuiteAgents[0].enabledTestCloudTunnel : false;
    const result = values.map((el) => {
      const { label, ...object } = el;
      return {
        cloudType: CloudType.TEST_CLOUD_AGENT,
        browserType: BrowserType.ALL,
        enabledTestCloudTunnel,
        ...object.value,
      };
    });
    setSuggestedEnvironments(getTopEnv(values, result[0]?.cloudType, result[0]?.browserType));
    setTestSuiteAgents(result);
    setEnvSelected(values);
    onChange(result);
  };

  useEffect(() => {
    if (isReset) {
      selectDefaultEnv([]);
    }
  }, [tsType]); // eslint-disable-line react-hooks/exhaustive-deps

  const openDialog = () => {
    setOpenedDialog(true);
  };

  const handleClickSelectEnvs = () => {
    if (!isShowDropdown) {
      openDialog();
    }
  };

  const filterTestSuiteAgents = testSuiteAgents.filter(({ isNotTestEnv }) => isNotTestEnv !== true);

  return (
    <>
      <CustomSelect
        value={envSelected}
        options={suggestedEnvironments}
        onClickMoreOption={tsType === TestSuiteType.CLOUD_STUDIO && MFlags.testCloudG5TestSuiteEnabled ? undefined : openDialog}
        onChange={selectDefaultEnv}
        menuTitle={t('schedule#suggested-environments')}
        menuPosition="fixed"
        onMenuOpen={handleClickSelectEnvs}
      />
      <SelectTestEnvironmentDialog
        isOpen={openedDialog}
        testProject={testProject}
        executionEnvironments={executionEnvironments}
        onOk={selectTestEnvironment}
        onClose={closeDialog}
        apps={apps}
        agents={filterTestSuiteAgents}
        localAgents={localAgents}
        circleCIAgents={circleCIAgents}
        k8sAgents={k8sAgents}
        configType={configType}
        title={title}
      />
    </>
  );
}

export default ExecutionEnvironmentSelect;
