import React from 'react';
import { get } from 'lodash';
import DataTable from '../../../components/table/DataTable';
import MTableColumnDataMapping from '../../../components/table/models/MTableColumnDataMapping';
import DecoratorConstants from '../../../utils/DecoratorConstants';
import { BaselineCollectionDecorator } from '../BaselineCollectionDecorator';
import { t } from '../../../i18n/t';
import { SearchEntity } from '../../../utils/Constants';
import Routes from '../../../utils/Routes';

const VisualBaselineCollectionsDataTable = () => {

  const columnMapping = [
    new MTableColumnDataMapping(
      t('baseline-collection-id'),
      'order',
      (name, row) => {
        const constructedLink = new Routes({
          baselineCollectionGroupOrder: row.order,
        });
        return DecoratorConstants.idDecorator(name, row, constructedLink.baseline_collection_group_detail_link);
      },
    ),
    new MTableColumnDataMapping(
      t('name'),
      'name',
      (name, row) => {
        const bcName = get(row, name);
        return DecoratorConstants.textMiddleTruncateDecorator(bcName);
      },
      'short-text-column',
      '',
      undefined,
      '35%',
    ),
    new MTableColumnDataMapping(
      t('latest-version'),
      'latestVersion.version',
      (name, row) => {
        const baselineCollection = get(row, name);
        return BaselineCollectionDecorator.versionDecorator(baselineCollection);
      },
    ),
    new MTableColumnDataMapping(
      t('number-of-images'),
      'latestVersion.numberOfBaselines',
      (name, row) => BaselineCollectionDecorator.numberOfImagesDecorator(name, row),
    ),
    new MTableColumnDataMapping(
      t('test-schedules'),
      'runConfigurations',
      (name, row) => BaselineCollectionDecorator.listScheduleDecorator(name, row),
      undefined,
      '',
      undefined,
      '25%'
    ),
  ];

  return (
    <DataTable
      entityType={SearchEntity.BaselineCollectionGroup}
      columnMapping={columnMapping}
      defaultSort={['order,desc']}
      useSortByColumn
      fixLayout
    />
  );
};

export default VisualBaselineCollectionsDataTable;
