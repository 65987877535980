import React from 'react';
import Select from '../../../components/Select';
import { ProjectStatisticsType } from '../../../utils/Constants';

const TimeSelect = ({ defaultValue = 'day', onSelectChange }) => {
  const [value, setValue] = React.useState(defaultValue);

  const options = [
    {
      value: ProjectStatisticsType.DAILY,
      label: 'Day',
    },
    {
      value: ProjectStatisticsType.WEEKLY,
      label: 'Week',
    },
    {
      value: ProjectStatisticsType.MONTHLY,
      label: 'Month',
    },
  ];

  const onChange = ({ value }) => {
    setValue(value);
    if (onSelectChange) {
      onSelectChange(value);
    }
  };

  return (
    <Select
      clearable={false}
      options={options}
      value={value}
      onChange={onChange}
    />
  );
};

export default TimeSelect;
