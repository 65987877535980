import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import PageComponent from '../../components/PageComponent';
import MContext from '../../models/MContext';
import MAuth from '../../models/MAuth';
import Routers from '../../utils/Routes';
import { t } from '../../i18n/t';
import { notificationIds } from '../../utils/NotificationPageUtils';
import { OrganizationLicensesHeaderNav } from '../../components/HeaderNav';
import ObjectSummary from '../../components/summary/ObjectSummary';
import RouteConstants from '../../utils/RouteConstants';
import DefaultLayout from '../../components/DefaultLayout';
import OrganizationKSELicensesTab from './OrganizationKSELicensesTab';
import OrganizationUnlimitedKSELicensesTab from './OrganizationUnlimitedKSELicensesTab';
import OrganizationEngineLicensesTab from './OrganizationEngineLicensesTab';
import OrganizationUnlimitedEngineLicensesTab from './OrganizationUnlimitedEngineLicensesTab';
import OrganizationFloatingEngineLicensesTab from './OrganizationFloatingEngineLicensesTab';
import OrganizationTestOpsLicensesTab from './OrganizationTestOpsLicensesTab';
import OrganizationTestCloudLicensesTab from './OrganizationTestCloudLicensesTab';
import MConfigs from '../../models/MConfigs';


class OrganizationLicenses extends PageComponent {
  constructor(props) {
    super(props);
    this.meta.id = 'page-organization-licenses';
    this.meta.title = t('license_keys');
    this.organizationId = MContext.organizationId;
    this.state = {
      organization: null,
    };
    this.renderHeader = this.renderHeader.bind(this);
    this.renderBody = this.renderBody.bind(this);
  }

  componentDidMount() {
    this.setState({ organization: MContext.organization });
  }

  renderObjectSummary() {
    const urlParams = {
      license_keys: t('license_keys'),
    };
    return (
      <ObjectSummary params={urlParams}>
        <OrganizationLicensesHeaderNav organizationId={this.organizationId} />
      </ObjectSummary>
    );
  }

  renderHeader() {
    return (
      <>
        {this.renderObjectSummary()}
      </>
    );
  }

  renderBody() {
    const routes = new Routers({
      organizationId: this.organizationId
    });
    return (
      <Switch>
        <Route
          exact
          path={RouteConstants.organization_kse_license_keys}
          component={OrganizationKSELicensesTab}
        />
        <Route
          exact
          path={RouteConstants.organization_unlimited_kse_license_keys}
          component={OrganizationUnlimitedKSELicensesTab}
        />
        <Route
          exact
          path={RouteConstants.organization_engine_license_keys}
          component={OrganizationEngineLicensesTab}
        />
        <Route
          exact
          path={RouteConstants.organization_unlimited_engine_license_keys}
          component={OrganizationUnlimitedEngineLicensesTab}
        />
        {
          !MConfigs.isOnPremise && (
          <Route
            exact
            path={RouteConstants.organization_floating_engine_license_keys}
            component={OrganizationFloatingEngineLicensesTab}
          />
          )
        }
        {
          !MConfigs.isOnPremise && MAuth.isSystemRole() &&
          <Route
            exact
            path={RouteConstants.organization_testops_license_keys}
            component={OrganizationTestOpsLicensesTab}
          />
        }
        <Route
          exact
          path={RouteConstants.organization_test_cloud_license_keys}
          component={OrganizationTestCloudLicensesTab}
        />
        <Redirect to={routes.organization_kse_licenses_link} />
      </Switch>
    );
  }

  render() {
    const isOrganizationManager = MAuth.isOrganizationManager(this.organizationId);
    const isBillingManager = MAuth.isBillingManager(this.organizationId);

    if (!isOrganizationManager && !isBillingManager) {
      Routers.goToNotificationPage(notificationIds.NO_PERMISSION);
    }

    const { organization } = this.state;
    if (organization) {
      return (
        <DefaultLayout
          renderHeader={this.renderHeader}
          renderBody={this.renderBody}
        />
      );
    }
    return null;
  }
}

export default OrganizationLicenses;
