import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import MContext from '../../../models/MContext';
import Services from '../../../utils/Services';
import Notification from '../../../utils/Notification';
import { t } from '../../../i18n/t';
import ToggleTunnel from '../../dialog/ToggleTunnel';
import Routes from '../../../utils/Routes';

function ToggleTunnelContainer({ enabledTestCloudTunnel,
  toggleTestCloudTunnelIntegration,
  enableApiTesting,
  updateEnableApiTesting,
  tunnelId,
  handleSelectedTunnelChange,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [isChecked, setIsChecked] = useState(enabledTestCloudTunnel);
  const [isActiveTunnel, setIsActiveTunnel] = useState(false);
  const routes = new Routes();

  const [activeTunnels, setActiveTunnels] = useState([]);

  useEffect(() => {
    if (isChecked) {
      const organizationId = MContext.team.organizationId;
      setIsLoading(true);

      Services.getActiveTunnels(organizationId)
        .then((tunnels) => {
          setIsActiveTunnel(tunnels?.length > 0);
          setActiveTunnels(tunnels);
        })
        .catch((e) => {
          Notification.pushError(e, t('test-cloud-local-testing#fail-get-active-tunnels'));
          setIsActiveTunnel(false);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [isChecked]);

  const handleToggle = (event) => {
    toggleTestCloudTunnelIntegration(event);
    setIsChecked(!isChecked);
  };

  return (
    <ToggleTunnel
      handleToggle={handleToggle}
      isLoading={isLoading}
      isChecked={isChecked}
      isActiveTunnel={isActiveTunnel}
      routes={routes}
      enableApiTesting={enableApiTesting}
      updateEnableApiTesting={updateEnableApiTesting}
      activeTunnels={activeTunnels}
      tunnelId={tunnelId}
      handleSelectedTunnelChange={handleSelectedTunnelChange}
    />
  );
}

ToggleTunnelContainer.propTypes = {
  enabledTestCloudTunnel: PropTypes.bool,
  toggleTestCloudTunnelIntegration: PropTypes.func,
  enableApiTesting: PropTypes.bool,
  updateEnableApiTesting: PropTypes.func,
  tunnelId: PropTypes.string,
  handleSelectedTunnelChange: PropTypes.func,
};

export default ToggleTunnelContainer;
