import { Chip } from '@mui/material';
import React from 'react';
import ChipGroup from '../ChipGroup';
import { Tag } from '../../../models/tag';

interface TagChipProps {
    name: string,
    handleRemove: (tag: Tag) => void,
}

const TagChipTsx = (props: TagChipProps) => {
  const { name, handleRemove } = props;
  return (
    <ChipGroup className="mr-2 mb-2" handleRemove={handleRemove} isRemovable>
      <Chip
        variant="outlined"
        label={name}
        title={name}
        size="small"
      />
    </ChipGroup>
  );
};

export default TagChipTsx;
