import React from 'react';
import classnames from 'classnames';
import Stack from '@mui/material/Stack';
import { Collapse } from '@mui/material';
import DefaultLayout from '../../components/DefaultLayout';
import PageComponent from '../../components/PageComponent';
import MContext from '../../models/MContext';
import Routers from '../../utils/Routes';
import { SETUP_GIT_REPOSITORY_OPTIONS } from '../../utils/Constants';
import { t } from '../../i18n/t';
import { updateUserTraits } from '../../utils/UserFlowHelper';
import { ExpandOrCollapseButton } from '../../components/navigationbar/ListOrganizationsAndProjects';
import { SampleGitRepositoryOptions } from './components/SampleGitRepositoryOptions';
import TestProjectConfigForm from './components/TestProjectConfigForm';
import UrlHelper from '../../utils/UrlHelper';

class GitTestProjectSetUp extends PageComponent {

  constructor(props) {
    super(props);
    this.meta.id = 'page-set-up-default-git-repository';
    this.meta.title = t('git-repository#set-up#page-title');

    this.teamId = MContext.teamId;
    this.projectId = MContext.projectId;
    this.project = MContext.project;
    this.handleRedirect = this.handleRedirect.bind(this);
    this.renderBody = this.renderBody.bind(this);
    this.state = {
      expandOption: null,
      isOnboarding: false
    };
  }

  componentDidMount() {
    this.handleAutoExpandCreateNewOption();
  }

  handleAutoExpandCreateNewOption() {
    const isOnboarding = UrlHelper.getSearchParam('isOnboarding');
    const expandOption = UrlHelper.getSearchParam('option');
    if (isOnboarding && expandOption) {
      this.setState({
        isOnboarding,
        expandOption
      });
    }
  }

  handleRedirect(testProjectId) {
    const { isOnboarding } = this.state;
    updateUserTraits({ git_setup: true }).catch(() => { /* ignore */ });
    if (isOnboarding) {
      Routers.goToProjectPage(this.teamId, this.projectId);
    } else {
      Routers.goToTestProjectPage(this.teamId, this.projectId, testProjectId);
    }
  }

  selectOption(option) {
    const { expandOption } = this.state;
    if (option === expandOption) { // deselect
      this.setState({ expandOption: null });
    } else { // select
      this.setState({ expandOption: option });
    }
  }

  renderOption(option, content) {
    const { expandOption } = this.state;
    const isExpand = expandOption === option;
    const onClick = () => this.selectOption(option);
    return (
      <div className={classnames('option', { selected: isExpand })} onClick={onClick} data-testid={`git-set-up-${option}`}>
        <div className="option-header">
          <div>
            <h5>
              {t(`git-repository#${option}#title`)}
            </h5>
            <div className="option-description">
              {t(`git-repository#${option}#description`)}
            </div>
          </div>
          <div className="icon">
            <ExpandOrCollapseButton
              width={12}
              height={12}
              expanded={isExpand}
            />
          </div>
        </div>
        <Collapse in={isExpand}>
          <Stack spacing={2}>
            <div />
            <div onClick={(e) => e.stopPropagation()}>
              {content}
            </div>
          </Stack>
        </Collapse>
      </div>
    );
  }

  renderBody() {
    const { project } = this;

    return (
      <div className="git-repository-setup">
        <div className="banner-left" />
        <div className="content">
          <div className="mt-4">
            <Stack spacing={3}>
              <div>
                <h3>{t('git-repository#set-up#title', { name: project.name })}</h3>
                <div>{t('git-repository#set-up#sub-title')}</div>
              </div>
              {this.renderOption(SETUP_GIT_REPOSITORY_OPTIONS.USE_SAMPLE,
                <SampleGitRepositoryOptions handleRedirect={this.handleRedirect} />)}
              {this.renderOption(SETUP_GIT_REPOSITORY_OPTIONS.CREATE_NEW,
                <TestProjectConfigForm isSetUpPage handleRedirect={this.handleRedirect} />)}
              <div />
            </Stack>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <DefaultLayout
        renderCustomBodyLayout={this.renderBody}
      />
    );
  }
}

export default GitTestProjectSetUp;
