import Notification from '../../../utils/Notification';
import { t } from '../../../i18n/t';

export default class NotificationHandler {

  static publishTestProjectErrorHandler = (message, jqXHR) => {
    const finalMessage = (jqXHR.status === 500 ? t('publish-test-project-failed#desription') : message);
    Notification.pushError(finalMessage, t('publish-test-project-failed#title'));
  };

  static moveTestCasesErrorHandler = (message, jqXHR, testEntities) => {
    let finalMessage = '';
    if (testEntities.length > 1) {
      finalMessage = (jqXHR.status === 500 ? t('moved-list-test-case#failed#message', { numberOfTestCases: testEntities.length }) : message);
    } else {
      const { testCase } = testEntities[0];
      finalMessage = (jqXHR.status === 500 ? t('moved-test-case#failed#message', testCase.name) : message);
    }

    Notification.pushError(finalMessage, t('moved-test-case#failed#title'));
  };

  static moveTestSuitesErrorHandler = (message, jqXHR, testEntities) => {
    let finalMessage = '';
    if (testEntities.length > 1) {
      finalMessage = (jqXHR.status === 500 ? t('moved-list-test-suite#failed#message', { numberOfTestSuites: testEntities.length }) : message);
    } else {
      const { testSuite } = testEntities[0];
      finalMessage = (jqXHR.status === 500 ? t('moved-test-suite#failed#message', testSuite.name) : message);
    }
    Notification.pushError(finalMessage, t('moved-test-suite#failed#title'));
  };

  static renameTestCasesErrorHandler = (message, jqXHR, testCase) => {
    const finalMessage = (jqXHR.status === 500 ? t('renamed-test-case#failed#message', testCase.name) : message);
    Notification.pushError(finalMessage, t('renamed-test-case#failed#title'));
  };

  static renameTestSuiteErrorHandler = (message, jqXHR, testSuite) => {
    const finalMessage = (jqXHR.status === 500 ? t('renamed-test-suite#failed#message', testSuite.name) : message);
    Notification.pushError(finalMessage, t('renamed-test-suite#failed#title'));
  };

  static createTestCaseErrorHandler = (message, jqXHR) => {
    const finalMessage = (jqXHR.status === 500 ? t('create-test-case#failed#message') : message);
    Notification.pushError(finalMessage, t('create-test-case#failed#title'));
  };

  static duplicateTestCaseOldErrorHandler = (message, jqXHR, testCaseName) => {
    const finalMessage = (jqXHR.status === 500 ? t('duplicate-test-case-old#failed-description', { testCaseName }) : message);
    Notification.pushError(finalMessage, t('duplicate-test-case-old#failed-title'));
  };

  static duplicateTestCaseErrorHandler = (message, jqXHR, testCases) => {
    let finalMessage = '';
    if (testCases.length === 1) {
      const testCaseName = testCases[0].name;
      finalMessage = (jqXHR.status === 500 ? t('duplicate-test-case#failed-description', { testCaseName }) : message);
    } else if (testCases.length > 1) {
      const testCaseNumber = testCases.length;
      finalMessage = (jqXHR.status === 500 ? t('duplicate-test-cases#failed-description', { testCaseNumber }) : message);
    }
    Notification.pushError(finalMessage, t('duplicate-test-case#failed-title'));
  };

  static deleteTestCaseErrorHandler = (message, jqXHR, testCaseName) => {
    const finalMessage = (jqXHR.status === 500 ? t('delete-test-case#failed-description', { testCaseName }) : message);
    Notification.pushError(finalMessage, t('delete-test-case#failed-title'));
  };

  static duplicateTestSuiteOldErrorHandler = (message, jqXHR, testSuiteName) => {
    const finalMessage = (jqXHR.status === 500 ? t('duplicate-test-suite#failed-description-old', { testSuiteName }) : message);
    Notification.pushError(finalMessage, t('duplicate-test-suite#failed-title-old'));
  };

  static duplicateTestSuiteErrorHandler = (message, jqXHR, testSuiteName) => {
    const finalMessage = (jqXHR.status === 500 ? t('duplicate-test-suite#failed-description', { testSuiteName }) : message);
    Notification.pushError(finalMessage, t('duplicate-test-suite#failed-title'));
  };

  static deleteTestSuiteErrorHandler = (message, jqXHR, testSuiteName) => {
    const finalMessage = (jqXHR.status === 500 ? t('delete-test-suite#failed-description', { testSuiteName }) : message);
    Notification.pushError(finalMessage, t('delete-test-suite#failed-title'));
  };

  static deleteTestSuitesErrorHandler = (message, jqXHR, testEntities) => {
    let finalMessage = '';
    if (testEntities.length > 1) {
      finalMessage = (jqXHR.status === 500 ? t('delete-test-suites#failed-description', { testSuiteNumber: testEntities.length }) : message);
    } else {
      const { testSuite } = testEntities[0];
      finalMessage = (jqXHR.status === 500 ? t('delete-test-suite#failed-description', { testSuiteName: testSuite.name }) : message);
    }
    Notification.pushError(finalMessage, t('delete-test-suite#failed-title'));
  };

  static runTestCaseErrorHandler = (message, jqXHR, testCaseName) => {
    const finalMessage = (jqXHR.status === 500 ? t('instant-test-run#execute-failed', { testCaseName }) : message);
    Notification.pushError(finalMessage, t('failed'));
  };
}
