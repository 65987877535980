import React from 'react';
import { Route, Switch } from 'react-router-dom';
import DefaultLayout from '../../components/DefaultLayout';
import { ExecutionTestRunHeaderNav } from '../../components/HeaderNav';
import PageComponent from '../../components/PageComponent';
import ObjectInfo from '../../components/summary/ObjectInfo';
import ObjectSummary from '../../components/summary/ObjectSummary';
import ObjectSummaryParametersBuilder from '../../components/summary/ObjectSummaryParametersBuilder';
import { t } from '../../i18n/t';
import { IconDuration, IconProfile, IconStartTime } from '../../images/CustomIcon';
import MContext from '../../models/MContext';
import DecoratorConstants from '../../utils/DecoratorConstants';
import Time from '../../utils/Moment';
import RouteConstants from '../../utils/RouteConstants';
import Routes from '../../utils/Routes';
import Services from '../../utils/Services';
import CommentsTab from './CommentsTab';
import RequestsTab from './RequestsTab';
import ResultTab from './ResultTab';
import TestObjectsTab from './TestObjectsTab';
import RerunTab from './RerunTab';
import SimilarFailuresTab from './SimilarFailuresTab';
import MConfigs from '../../models/MConfigs';
import ResultActionButtons from './ResultActionButtons';
import MFlags from '../../models/MFlags';
import PageButtonToolbar from '../../components/PageButtonToolbar';
import MarkAsRetestedDialog from '../../components/dialog/MarkAsRetestedDialog';
import ReportDefectDialog from '../../components/dialog/ReportDefectDialog';

class ExecutionTestResult extends PageComponent {
  constructor(props) {
    super(props);
    this.meta.id = 'page-execution-test-result-details';
    this.executionId = MContext.executionId;
    this.executionTestResultId = MContext.executionTestResultId;
    this.projectId = MContext.projectId;
    this.teamId = MContext.teamId;
    this.state = {
      execution: null,
      testResult: null,
      isOpenMarkAsRetestedDialog: false,
      isOpenReportDefectDialog: false,
    };
    this.renderHeader = this.renderHeader.bind(this);
    this.renderBody = this.renderBody.bind(this);
    this.handleCloseMarkAsRetestedDialog = this.handleCloseMarkAsRetestedDialog.bind(this);
    this.handleCloseReportDefectDialog = this.handleCloseReportDefectDialog.bind(this);
  }

  getTestResult(id) {
    Services.getTestResult(id)
      .then((responseJson) => {
        const testResult = responseJson;
        this.meta.title = t('Test Result {{name}}', {
          name: testResult.testCase.name,
        });
        this.getExecution(testResult.execution.id);
        this.setState({
          testResult,
        });
      });
  }

  getExecution(id) {
    Services.getExecution(id)
      .then((responseJson) => {
        const execution = responseJson;
        this.setState({
          execution,
        });
      });
  }

  componentDidMount() {
    this.getTestResult(this.executionTestResultId);
  }

  renderSameFailureTestResultLinks() {
    const content = [];
    let i = 0;
    const { testResult } = this.state;
    const length = testResult.sameFailureResults.length;
    for (const result of testResult.sameFailureResults) {
      const constructedLink = new Routes({
        executionId: result.executionId,
        executionTestResultId: result.id,
      });
      content.push((
        <div key={result.id} className="col-form-label">
          <a
            href={constructedLink.execution_test_result_detail_link}
          >
            {result.executionId}
          </a>
          {i !== length - 1 && (<span className="mr-2">, </span>)}
        </div>
      ));
      i++;
    }
    const none = <div className="col-form-label">None</div>;
    return <div className="d-flex flex-wrap">{content.length > 0 ? content : none}</div>;
  }

  executionTestResultInfoItems() {
    const { testResult } = this.state;
    const itemArray = [];
    itemArray.push(
      {
        text: DecoratorConstants.statusBadge(testResult.status),
      },
      {
        icon: <IconStartTime />,
        text: Time.format(testResult.startTime),
      },
      {
        icon: <IconDuration />,
        text: Time.duration(testResult.duration),
      },
      {
        icon: <IconProfile />,
        text: DecoratorConstants.profile(testResult.profile),
      },
      {
        // icon: <IconPlatform />,
        text: DecoratorConstants.platformHeader(testResult.platform),
      },
    );
    return itemArray;
  }

  handleCloseMarkAsRetestedDialog() {
    this.setState({
      isOpenMarkAsRetestedDialog: false,
    });
  }

  handleCloseReportDefectDialog() {
    this.setState({
      isOpenReportDefectDialog: false,
    });
  }

  showMarkAsRetestedConfirmDialog(testResultId, status) {
    this.setState({
      testResultId,
      status,
      isOpenMarkAsRetestedDialog: true
    });
  }

  showReportDefectDialog(executionTestResult) {
    this.setState({
      executionTestResult,
      isOpenReportDefectDialog: true
    });
  }

  renderMarkAsRetestedDialog() {
    const { testResultId, status, isOpenMarkAsRetestedDialog } = this.state;
    return (
      <MarkAsRetestedDialog
        isOpen={isOpenMarkAsRetestedDialog}
        testRunId={testResultId}
        status={status}
        handleClose={this.handleCloseMarkAsRetestedDialog}
      />
    );
  }

  renderReportDefectDialog() {
    const { executionTestResult, isOpenReportDefectDialog } = this.state;
    return (
      <ReportDefectDialog
        isOpen={isOpenReportDefectDialog}
        executionTestResult={executionTestResult}
        handleClose={this.handleCloseReportDefectDialog}
      />
    );
  }

  renderObjectTabInfo() {
    const { testResult } = this.state;
    return (
      <>
        <ExecutionTestRunHeaderNav
          projectId={this.projectId}
          teamId={this.teamId}
          executionId={this.executionId}
          executionTestResultId={this.executionTestResultId}
          testResult={testResult}
        />
        <ObjectInfo items={this.executionTestResultInfoItems()} />
      </>
    );
  }

  renderObjectSummary() {
    const urlParams = new ObjectSummaryParametersBuilder();
    const { execution, testResult } = this.state;
    return (
      <ObjectSummary
        params={urlParams.toExecutionTestResultDetailsParams(
          execution.project,
          execution,
          testResult,
        )}
      >
        {this.renderObjectTabInfo()}
      </ObjectSummary>
    );
  }

  renderHeader() {
    return (
      <>
        {this.renderObjectSummary()}
      </>
    );
  }

  renderToolbar() {
    const { testResult } = this.state;

    return (
      <PageButtonToolbar>
        <ResultActionButtons
          executionTestResult={testResult}
          isRowItem={false}
          onMarkStatus={() => this.showMarkAsRetestedConfirmDialog(testResult.id, testResult.status)}
          onReportDefect={() => this.showReportDefectDialog(testResult)}
        />
      </PageButtonToolbar>
    );
  }

  renderBody() {
    const { testResult, execution } = this.state;
    return (
      <>
        {MFlags.jiraIssueLinkingCreatingEnabled && this.renderToolbar()}
        <Switch>
          <Route
            exact
            path={RouteConstants.execution_test_result_details}
            render={(params) => (
              <ResultTab
                projectId={this.projectId}
                teamId={this.teamId}
                executionTestResultId={this.executionTestResultId}
                testResult={testResult}
                execution={execution}
                {...params}
              />
            )}
          />
          <Route
            exact
            path={RouteConstants.execution_test_result_requests}
            render={(params) => <RequestsTab executionTestResultId={this.executionTestResultId} {...params} />}
          />
          <Route
            exact
            path={RouteConstants.execution_test_result_test_objects}
            render={(params) => <TestObjectsTab {...params} />}
          />
          {!MConfigs.isOnPremise && <Route
            exact
            path={RouteConstants.execution_test_result_similar_failures}
            render={(params) => <SimilarFailuresTab
              testResult={testResult}
              {...params}
            />}
          />}
          {/* <Route
        exact
        path={RouteConstants.execution_test_result_treeviewer_link}
        render={() => <StepsTab executionTestResultId={this.executionTestResultId} />}
      /> */}
          <Route
            exact
            path={RouteConstants.execution_test_result_comment}
            render={(params) => <CommentsTab
              projectId={this.projectId}
              teamId={this.teamId}
              objectId={testResult.id}
              objectType="EXECUTION_TEST_RESULT"
              {...params}
            />}
          />
          <Route
            exact
            path={RouteConstants.execution_test_result_rerun}
            render={(params) => <RerunTab
              testResult={testResult}
              {...params}
            />}
          />
        </Switch>
        {MFlags.hideResultActionWhenParsingNotCompletedEnabled && this.renderMarkAsRetestedDialog()}
        {MFlags.hideResultActionWhenParsingNotCompletedEnabled && this.renderReportDefectDialog()}
      </>
    );
  }

  render() {
    const { execution, testResult } = this.state;

    if (execution && testResult) {
      return (
        <DefaultLayout
          renderHeader={this.renderHeader}
          renderBody={this.renderBody}
          // renderCustomBodyLayout={this.renderBody}
        />
      );
    }
    return null;
  }
}

export default ExecutionTestResult;
