import React from 'react';
import { ModalBody, ModalFooter, ModalHeader, Modal, Row, Col, Button, Input, Form } from 'reactstrap';
import { IconButton } from '@mui/material';
import { IconSmile, IconFrown, IconTwitter } from '../images/CustomIcon';
import Services from '../utils/Services';
import Notification from '../utils/Notification';
import { t } from '../i18n/t';
import { twitterTestOps } from '../utils/Constants';
import DocumentLink from '../utils/DocumentLink';

class FeedbackDialog extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      textAreaFeedback: '',
      textMood: 'happy',
      modal: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleFeelingHappy = this.handleFeelingHappy.bind(this);
    this.handleFeelingSad = this.handleFeelingSad.bind(this);
    this.saveStatus = this.saveStatus.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  isHappy(mood) {
    return mood === 'happy';
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  handleSubmit() {
    const { textMood, textAreaFeedback } = this.state;
    let textMoodString;
    if (this.isHappy(textMood)) {
      textMoodString = 'Feeling Happy :) \n';
    } else {
      textMoodString = 'Feeling Sad :( \n';
    }
    window.open(`https://twitter.com/intent/tweet?text=${encodeURIComponent(`${textMoodString + textAreaFeedback}\nvia @${twitterTestOps}`)}`);
    this.setState({
      modal: false,
      textAreaFeedback: '',
      textMood: 'happy',
    });
  }

  handleFeelingHappy() {
    this.setState({ textMood: 'happy' });
  }

  handleFeelingSad() {
    this.setState({ textMood: 'sad' });
  }

  handleChange(event) {
    const input = event.target.value;
    this.setState({ textAreaFeedback: input });
  }

  saveStatus(e) {
    e.preventDefault();
    const { textAreaFeedback, textMood } = this.state;
    const id = null;
    const userFeedback = textAreaFeedback;
    const userMood = textMood;
    const status = { id, userFeedback, userMood };
    Services.saveStatusFeedback(status, () => {
      Notification.pushError('Something Wrong!!! Please try again');
    })
      .then(() => {
        Notification.pushSuccess('Successfully, Thank you for your feedback!');
        this.handleSubmit();
      });
  }

  render() {
    const { textAreaFeedback, modal } = this.state;

    const smileBtn =
      <IconButton
        onClick={this.handleFeelingHappy}
        className={this.isHappy(this.state.textMood) ? 'active' : 'inactive'}
        key="smile"
        title="Smiley"
        size="large">
        <IconSmile />
      </IconButton>;

    const frownBtn =
      <IconButton
        onClick={this.handleFeelingSad}
        className={this.isHappy(this.state.textMood) ? 'inactive' : 'active'}
        key="frown"
        title="Frowny"
        size="large">
        <IconFrown />
      </IconButton>;

    return (
      <Modal
        isOpen={modal}
        toggle={this.handleClose}
      >
        <Form data-trackid="submit-feedback" onSubmit={this.saveStatus}>
          <ModalHeader>
            <IconTwitter />
            &nbsp;Tweet us your feedback
          </ModalHeader>
          <ModalBody className="feedback">
            <Row>
              <Col>
                <div className="title">
                  How was your experience?
                </div>
                <div className="react">
                  {smileBtn}  or  {frownBtn}
                </div>
                <div className="title">
                  Tell me why?
                </div>
              </Col>
              <Col>
                <div>
                  <div className="title">
                    {t('feedbackTitle')}
                  </div>
                  <a href={DocumentLink.BUG_REPORT} target="_blank" rel="noopener noreferrer">
                    Submit a bug
                  </a>
                  <br />
                  <a href={DocumentLink.FEATURE_REQUEST} target="_blank" rel="noopener noreferrer">
                    Request a new feature
                  </a>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <Input
                  className="textAreaFeedback"
                  id="tweet"
                  type="textarea"
                  rows={5}
                  value={textAreaFeedback}
                  onChange={this.handleChange}
                />
                <div className="text-remaining text-muted">
                  {`${253 - textAreaFeedback.length} characters remaining`}
                </div>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              type="submit"
              target="_blank"
              color="primary"
            >
              {t('feedback-tweet')}
            </Button>
            <Button
              onClick={this.toggle}
            >
              {t('cancel')}
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    );
  }
}

export default FeedbackDialog;
