import React from 'react';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  Label
} from 'recharts';
import PropTypes from 'prop-types';
import { startCase, toLower } from 'lodash';
import classnames from 'classnames';
import colors from '../../../scss/colors.scss';
import CustomChartTooltip from './CustomChartTooltip';
import { timeFormatter } from '../../pages/test_reports/utils';

const HorizontalLineChart = ({ data, payload, aspect, legendVerticalPosition, legendSize, customClassName, verticalLabel }) => {

  const renderLines = () => {
    const rows = [];
    payload.forEach((item) => {
      rows.push(
        <Line
          activeDot={{ fill: colors.white, stroke: item.color, strokeWidth: 3, r: 4 }}
          dataKey={item.dataKey}
          stroke={item.color}
          fill={item.color}
          strokeWidth={2}
          isAnimationActive={false}
          shape="around"
        />
      );
    });
    return rows;
  };

  const formatLegendName = (name) => startCase(toLower(name));

  return (
    <ResponsiveContainer
      minWidth={200}
      aspect={aspect}
      position="center"
      className={classnames('custom-overview-chart', customClassName)}
    >
      <LineChart
        data={data}
        margin={{
          top: 20,
          right: 20,
          bottom: 10,
          left: 0,
        }}
      >
        <CartesianGrid vertical={false} stroke={colors.cardBorder} strokeWidth={0.5} />
        <XAxis
          padding={{ left: 30, right: 0 }}
          tickFormatter={timeFormatter}
          dataKey="time"
          axisLine={false}
          tickLine={false}
        />
        <YAxis
          type="number"
          textAnchor="middle"
          axisLine={false}
          tickLine={false}
        >
          {verticalLabel &&
            <Label angle={-90} dx={-25} className="gray-axis-label">
              {verticalLabel}
            </Label>}
        </YAxis>
        <Tooltip content={<CustomChartTooltip />} />
        <Legend iconSize={legendSize} iconType="square" formatter={formatLegendName} verticalAlign={legendVerticalPosition} align="right" />
        {renderLines()}
      </LineChart>
    </ResponsiveContainer>
  );
};

HorizontalLineChart.propTypes = {
  /**
   * data: { type: Array, description: Data of chart }
   * Example:
   *  const data = [
   *    {
   *      time: '2022-01-10',
   *      FAILED: 2400,
   *      PASSED: 4000,
   *      ERROR: 2400,
   *      INCOMPLETE: 4000
   *    },
   *    {
   *      time: '2022-01-11',
   *      FAILED: 1398,
   *      PASSED: 3000,
   *      ERROR: 2210,
   *      INCOMPLETE: 1232
   *    }
   *  ];
   */
  data: PropTypes.array.isRequired,
  /**
   * payload: { type: Array of objects, description: Define element payload of chart }
   * Example:
   *  const payload = [
   *    {
   *      dataKey: TestRunStatus.FAILED,
   *      color: colors.failed
   *    },
   *    {
   *      dataKey: TestRunStatus.PASSED,
   *      color: colors.passed
   *    },
   *    {
   *      dataKey: TestRunStatus.ERROR,
   *      color: colors.error
   *    },
   *    {
   *      dataKey: TestRunStatus.INCOMPLETE,
   *      color: colors.incomplete,
   *    }
   *  ];
   */
  payload: PropTypes.array.isRequired,
  /**
   * aspect: {type: number. description: The gap ratio between the chart element}
   */
  aspect: PropTypes.number,
  /**
   * legendVerticalPosition: {type: string. description: Vertical position of legend ()}
   */
  legendVerticalPosition: PropTypes.string,
  /**
   * legendSize: {type: number. description: Legend size ()}
   */
  legendSize: PropTypes.number,
  customClassName: PropTypes.string,
  verticalLabel: PropTypes.string,
};

HorizontalLineChart.defaultProps = {
  data: [],
  payload: [],
  aspect: 3,
  legendSize: 8,
  legendVerticalPosition: 'bottom',
  customClassName: '',
  verticalLabel: null,
};
export default HorizontalLineChart;


