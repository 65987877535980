import React from 'react';
import {
  DropdownItem,
  Nav,
  Navbar,
  NavItem,
  PopoverBody,
  UncontrolledPopover,
} from 'reactstrap';
import jquery from 'jquery';
import ReactDOM from 'react-dom';
import RouteConstants from '../../utils/RouteConstants';
import MAuth from '../../models/MAuth';
import Routes from '../../utils/Routes';
import MContext from '../../models/MContext';
import { t } from '../../i18n/t';
import {
  IconDashboardActive,
  IconDashboardInactive,
  IconSettingsActive,
  IconLogin,
  IconCenterActive,
  IconTestOpsReportsActive,
  IconConfigurationActive,
  IconCenterInactive,
  IconTestOpsReportsInactive,
  IconConfigurationInactive,
  IconTestCloudTunnelActive,
  IconTestCloudTunnelDefault,
  IconTestCloudTunnelHover,
} from '../../images/CustomIcon';
import {
  IconTestRun,
  IconTestRunHover,
  IconTestRunSelect,
  IconRequirementCoverage,
  IconRequirementCoverageActive,
  IconRequirementCoverageHover,
  IconTestCaseAnalytics,
  IconTestCaseAnalyticsActive,
  IconTestCaseAnalyticsHover,
  IconWebService,
  IconWebServiceActive,
  IconWebServiceHover,
  IconTestObject,
  IconTestObjectActive,
  IconTestObjectHover,
  IconTestCase,
  IconTestCaseActive,
  IconTestCaseHover,
  IconTestSuite,
  IconTestSuiteActive,
  IconTestSuiteHover,
  IconDefect,
  IconDefectActive,
  IconDefectHover,
  IconScriptRepository,
  IconScriptRepositoryActive,
  IconScriptRepositoryHover,
  IconTestEnvironment,
  IconTestEnvironmentActive,
  IconTestEnvironmentHover,
  IconAgentSetup,
  IconAgentSetupActive,
  IconAgentSetupHover,
  IconIntegrations,
  IntegrationsActive,
  IntegrationsHover,
} from '../../images/CustomNewIcon';
import NavLink from '../NavLink';
import { AppType, PageType } from '../../utils/Constants';
import ImageProfile from '../avatar/ImageProfile';
import { IconAngleRight, IconDown } from '../../images/KitIcons';
import DropDownAuto from './DropDownAuto';
import MFlags from '../../models/MFlags';
import GroupEvent from '../../utils/track/GroupEvent';
import Arrays from '../../utils/Arrays';
import Services from '../../utils/Services';
import { checkMobileAppLicenseActive } from '../smarttestscheduling/services/testcloud';

class NavigationBar extends React.Component {

  constructor(props) {
    super(props);
    MContext.parse(props.match.params);
    this.state = {
      collapsed: false,
      ready: false,
      isMobileNativeAppEnabled: false,
    };

    this.teamId = MContext.teamId;
    this.projectId = MContext.projectId;
    this.project = MContext.project;
    const userId = MAuth.isLoggedIn ? MAuth.user.id : '';

    this.collapsedLStorageKey = `katalon-collapsed-nav-bar-${userId}`;
    this.renderOption = this.renderOption.bind(this);
    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.renderOptionTopNav = this.renderOptionTopNav.bind(this);
    this.renderLoginButton = this.renderLoginButton.bind(this);
    this.renderDropdownIcon = this.renderDropdownIcon.bind(this);
    this.renderSettingButton = this.renderSettingButton.bind(this);
    this.generateAvatar = this.generateAvatar.bind(this);
  }

  toggleNavbar(event) {
    event.preventDefault();

    const { collapsed } = this.state;
    const { onCollapseChanged } = this.props;

    const nextCollapsed = !collapsed;
    localStorage.setItem(this.collapsedLStorageKey, nextCollapsed);
    this.setState({
      collapsed: nextCollapsed,
    });

    if (onCollapseChanged) {
      onCollapseChanged(collapsed);
    }
  }

  renderLoginButton() {
    return (
      <NavItem>
        <NavLink title={t('login')} data-trackid="login-click" href={RouteConstants.login}>
          <span className="nav-item-icon"><IconLogin /></span>
          <span className="nav-item-label">{t('login')}</span>
        </NavLink>
      </NavItem>
    );
  }

  renderIcon({ icon, iconActive, iconInactive, bigSize }) {
    if (bigSize) {
      return <span className="nav-item-icon-big">{icon}</span>;
    }
    return iconActive && iconInactive
      ? (
        <>
          <span className="nav-item-icon icon-inactive">{iconInactive}</span>
          <span className="nav-item-icon icon-active">{iconActive}</span>
        </>)
      : <span className="nav-item-icon">{icon}</span>;
  }

  renderDirection(item) {
    if (item.items && item.active) {
      return <IconDown className="nav-item-icon-end ml-auto" color="#7EB1FF" />;
    }
    if (item.items && !item.active) {
      return <IconAngleRight className="nav-item-icon-end ml-auto" color="#7EB1FF" />;
    }
    return null;
  }

  renderDropdownIcon(id, info, item) {
    if (info.length === 0) {
      return null;
    } else {
      return (
        <>
          <NavItem id={id} className="flex-fill">
            <NavLink href={item.link}>
              {this.renderIcon(item)}
              {item.name && <span className="nav-item-label">{item.name}</span>}
            </NavLink>
          </NavItem>
          <UncontrolledPopover
            className="navbar-popover"
            placement="right"
            target={id}
            hideArrow
            trigger="hover"
            fade={false}
          >
            <PopoverBody>
              <Nav vertical>
                {info.map((option) => {
                  if (option.divider) {
                    return <DropdownItem divider />;
                  } else {
                    return (
                      <NavItem
                        key={option.link}
                        tag="a"
                        href={option.link}
                        target="_self"
                      >
                        {option.name}
                      </NavItem>
                    );
                  }
                })}
              </Nav>
            </PopoverBody>
          </UncontrolledPopover>
        </>
      );
    }
  }

  renderSettingButton() {
    const { type } = this.props;
    const { teamId, projectId } = this;

    let url;
    let name;
    if (type === PageType.PROJECT) {
      const routes = new Routes({ teamId, projectId });
      url = routes.project_settings_link;
      name = 'Project Settings';
    }

    if (url && name) {
      return (
        <NavItem id="setting" className="flex-fill">
          <NavLink href={url}>
            <span className="nav-item-icon"><IconSettingsActive /></span>
            <span className="nav-item-label">{name}</span>
          </NavLink>
        </NavItem>
      );
    }
    return null;

  }

  async getOrganizationMobileNativeQuotas() {
    const organizationId = this.project?.team?.organizationId;
    const { projectId } = this;

    if (organizationId) {
      const quota = await Services.getTestCloudOrganizationQuotas(organizationId, projectId);
      const isMobileNativeAppEnabled = checkMobileAppLicenseActive(quota);
      MContext.testCloudMobileNativeAppEnabled = isMobileNativeAppEnabled;

      if (this.state.isMobileNativeAppEnabled !== isMobileNativeAppEnabled) {
        this.setState({ isMobileNativeAppEnabled });
      }
    }
  }

  componentDidMount() {
    this.getOrganizationMobileNativeQuotas();
    const collapsed = localStorage.getItem(this.collapsedLStorageKey) || '';
    this.setState({
      collapsed: collapsed === 'true',
    });
    this.checkExist = setInterval(this.updateState(), 50);
  }

  renderOption(item) {
    const { collapsed } = this.state;
    if (item.divider) {
      return (
        <DropdownItem divider />
      );
    } else {
      if (!item.exactMatch) {
        item.exactMatch = false;
      }
      let className = '';
      if (item.items) {
        if (item.active && collapsed) {
          // className = 'project-app-active';
        } else {
          className = 'inactive';
        }
      } else if (item.inApp) {
        className = 'project-item';
      } else if (item.currentProject) {
        className = 'project-header-item';
      }

      const nav = (
        <>
          {!item.inApp && this.renderIcon(item)}
          {item.inApp && <div className="line-select" />}
          <span className="nav-item-label">{item.name}</span>
          {this.renderDirection(item)}
        </>
      );
      return (
        <>
          {(!item.items || (item.active && !collapsed)) && (
            <NavItem>
              <NavLink
                isProjectItem={Boolean(item.inApp)}
                exactMatch={item.exactMatch}
                title={item.name}
                className={className}
                href={item.active ? '#' : item.link}
                pattern={item.pattern}
              >
                {nav}
              </NavLink>
            </NavItem>
          )}
          {(item.items && (!item.active || collapsed)) &&
            <DropDownAuto
              item={item}
              element={nav}
            />}
        </>
      );
    }
  }

  updateState() {
    this.setState((prevState) => ({
      ready: !prevState.ready,
    }));
  }
  renderChildApp(items) {
    if (items == null) {
      return null;
    }
    return (
      <div className="sub-navbar">
        {(items.map((item) => {
          let className = '';
          if (item.active) {
            className = 'active';
          }
          return (
            <NavItem>
              <NavLink
                exactMatch={item.exactMatch}
                title={item.name}
                className={className}
                href={item.active ? '#' : item.link}
                pattern={item.pattern}
              >
                <span className="nav-item-label">{item.name}</span>
                {item?.endAdornment}
              </NavLink>
            </NavItem>
          );
        }))}
      </div>
    );
  }
  renderOptionTopNav(item) {
    const { items, active } = item;
    const id = '#under-top-navbar';
    const underTopNavQuery = jquery(id);
    let childApp = null;
    if (underTopNavQuery.length > 0) {
      childApp = ReactDOM.createPortal(
        this.renderChildApp(items),
        underTopNavQuery.get(0)
      );
      clearInterval(this.checkExist);
    }
    let className = '';
    if (item.active) {
      className = 'active';
    }
    return (
      <>
        {active && (childApp || null)}
        <NavItem>
          <NavLink
            isProjectItem={Boolean(item.inApp)}
            exactMatch={item.exactMatch}
            title={item.name}
            className={className}
            href={item.active ? '#' : item.link}
            pattern={item.pattern}
            data-trackid={item.dataTrackId}
            data-groupid={item.dataGroupId}
            data-testid={item.dataTestId}
          >
            <span className="nav-item-label">{item.name}</span>
          </NavLink>
        </NavItem>
      </>
    );
  }

  render() {
    const { type } = this.props;
    const { collapsed } = this.state;
    const { teamId, project, projectId } = this;
    const route = new Routes(projectId, teamId);

    let projectItems = [];
    const isNewProjectScope = MAuth.isLoggedIn && type === PageType.PROJECT && project;
    const isTeamDemo = MContext.isTeamDemo;

    if (isNewProjectScope) {
      const appType = MContext.appType;

      const planningItems = [
        {
          name: t('releases'),
          link: route.releases_link,
          inApp: true,
        },
        {
          name: t('requirements'),
          link: route.requirements_link,
          inApp: true,
        },
      ];

      const centerItems = [
        {
          name: t('test_cases'),
          link: route.test_cases_link,
          inApp: true,
          icon: <IconTestCase />,
          iconActive: <IconTestCaseActive />,
          iconHover: <IconTestCaseHover />,
        },
        {
          name: t('test_suites'),
          link: route.test_suites_link,
          inApp: true,
          icon: <IconTestSuite />,
          iconActive: <IconTestSuiteActive />,
          iconHover: <IconTestSuiteHover />,
        },
        {
          name: t('test_object_entities'),
          link: route.test_object_entities_link,
          inApp: true,
          icon: <IconTestObject />,
          iconActive: <IconTestObjectActive />,
          iconHover: <IconTestObjectHover />,
        },
        {
          name: t('test_objects'),
          link: route.web_services_link,
          inApp: true,
          icon: <IconWebService />,
          iconActive: <IconWebServiceActive />,
          iconHover: <IconWebServiceHover />,
        },
      ];

      const reportItems = [
        {
          name: t('executions'),
          link: route.overview_link,
          inApp: true,
          pattern: [
            route.overview_link,
            route.executions_link,
            route.import_manual_reports_link,
            route.test_report_execution_report,
            route.test_report_test_performance,
            route.test_report_frequency_report,
            route.failed_test_result
          ],
          icon: <IconTestRun />,
          iconHover: <IconTestRunHover />,
          iconActive: <IconTestRunSelect />,
        },
        {
          name: t('requirements'),
          pattern: route.test_report_test_design,
          link: route.test_report_requirement_test_run_coverage,
          inApp: true,
          icon: <IconRequirementCoverage />,
          iconHover: <IconRequirementCoverageHover />,
          iconActive: <IconRequirementCoverageActive />,
        },
        {
          name: t('test_cases'),
          pattern: route.test_report_test_maintenance,
          link: route.test_cases_active_link,
          inApp: true,
          icon: <IconTestCaseAnalytics />,
          iconActive: <IconTestCaseAnalyticsActive />,
          iconHover: <IconTestCaseAnalyticsHover />,
        },
        {
          name: t('defects'),
          link: route.defects_link,
          inApp: true,
          icon: <IconDefect />,
          iconActive: <IconDefectActive />,
          iconHover: <IconDefectHover />,
        },
        {
          name: t('testops-keyes'),
          pattern: [
            route.keyes_link,
            route.baseline_collection_link,
            route.keyes_execution_details_link,
            route.baseline_collection_group_history_link,
            route.baseline_collection_group_detail_link
          ],
          link: route.keyes_link,
          inApp: true,
        },
      ];

      const configurationItems = [
        {
          name: t('general'),
          link: route.project_settings_link,
          inApp: true,
        },
        {
          name: t('test-projects'),
          link: route.test_projects_link,
          inApp: true,
          icon: <IconScriptRepository />,
          iconActive: <IconScriptRepositoryActive />,
          iconHover: <IconScriptRepositoryHover />,
        },
        ...Arrays.insertIf(
          !isTeamDemo,
          {
            name: t('agent'),
            link: route.agent_link,
            inApp: true,
            icon: <IconTestEnvironment />,
            iconActive: <IconTestEnvironmentActive />,
            iconHover: <IconTestEnvironmentHover />,
          },
          ...(!MFlags.removeAgentSetupPageOnProjectSettingsEnabled ? [{
            name: t('test-env-installation'),
            link: route.test_env_installation_link,
            inApp: true,
            icon: <IconAgentSetup />,
            iconActive: <IconAgentSetupActive />,
            iconHover: <IconAgentSetupHover />,
          }] : []),
          {
            name: t('integrations'),
            link: route.integrations_link,
            inApp: true,
            icon: <IconIntegrations />,
            iconActive: <IntegrationsActive />,
            iconHover: <IntegrationsHover />,
          }
        ),
      ];
      const nativeAppItems = [
        {
          name: t('test-execution#apps'),
          link: route.native_apps_link,
          inApp: true,
        }
      ];

      const testExecutionItems = [
        ...(MFlags.testcloudExecutionEnvMicroAppEnabled ? [{
          name: t('test-execution#histories'),
          link: route.test_runs_link,
          inApp: true,
        }] : []),
        {
          name: t('test-execution#test-run-calendar'),
          link: route.test_planning_link,
          inApp: true,
        },
        {
          name: t('test-execution#test-run-list'),
          link: route.grid_link,
          inApp: true,
        },
        ...(this.state.isMobileNativeAppEnabled ? nativeAppItems : []),
      ];


      if (!isTeamDemo) {
        const testCloudTunnelMenu = {
          name: t('test-cloud-tunnel#title'),
          link: route.test_cloud_tunnel_link,
          inApp: true,
          icon: <IconTestCloudTunnelDefault />,
          iconActive: <IconTestCloudTunnelActive />,
          iconHover: <IconTestCloudTunnelHover />,
        };
        configurationItems.push(testCloudTunnelMenu);
      }

      const customFieldsMenu = {
        name: t('custom-fields#title'),
        link: route.custom_fields,
        inApp: true,
      };
      configurationItems.push(customFieldsMenu);

      if (MFlags.openAiApiKeyManagementEnabled) {
        const artificialIntelligenceMenu = {
          name: t('artificial-intelligence#title'),
          link: route.artificial_intelligence_link,
          inApp: true,
        };
        configurationItems.push(artificialIntelligenceMenu);
      }

      projectItems = projectItems.concat([
        {
          name: t('dashboard'),
          link: route.project_dashboard_link,
          iconActive: <IconDashboardActive />,
          iconInactive: <IconDashboardInactive />,
          exactMatch: true,
        },
      ]);

      projectItems = projectItems.concat([
        {
          name: t('planning'),
          // To redirect requirements page
          link: planningItems[0]?.link || '#',
          active: appType === AppType.TEST_PLANNING,
          items: planningItems,
          key: 'planning',
        },
      ]);

      projectItems = projectItems.concat([
        {
          name: t('tests'),
          link: centerItems[0]?.link || '#',
          iconActive: <IconCenterActive />,
          iconInactive: <IconCenterInactive />,
          active: appType === AppType.CENTER,
          items: centerItems,
          key: 'test-management',
        },
      ]);
      // if (appType === AppType.CENTER) {
      //   projectItems = projectItems.concat(centerItems);
      // }


      projectItems = projectItems.concat([
        {
          name: t('report#executions'),
          link: testExecutionItems[0].link || '#',
          active: appType === AppType.TEST_EXECUTION,
          items: testExecutionItems,
          key: 'continuous-testing'
        }
      ]);

      projectItems = projectItems.concat([
        {
          name: t('reports'),
          link: reportItems[0]?.link || '#',
          iconActive: <IconTestOpsReportsActive />,
          iconInactive: <IconTestOpsReportsInactive />,
          active: appType === AppType.REPORTS,
          items: reportItems,
          key: 'testops-report',
          dataTrackId: 'menu-reports',
          dataGroupId: GroupEvent.ACCESS_REPORT,
        }]);
      // if (appType === AppType.REPORTS) {
      //   projectItems = projectItems.concat(reportItems);
      // }
      const isTrueTestFeature = MFlags.atgCheckFeaturePermission || MFlags.e2eTestGenerationEnabled;
      if (isTrueTestFeature) {
        projectItems = projectItems.concat([
          {
            name: t('settings-manage_e2etg'),
            link: route.truetest_user_journeys_link,
            key: 'truetest',
            active: appType === AppType.TRUETEST,
            dataTrackId: 'menu-truetest',
            dataGroupId: GroupEvent.ACCESS_AUT_MANAGEMENT,
            dataTestId: 'menu-truetest',
          }
        ]);
      }

      projectItems = projectItems.concat([
        {
          name: t('settings'),
          link: configurationItems[0]?.link || '#',
          iconActive: <IconConfigurationActive />,
          iconInactive: <IconConfigurationInactive />,
          active: appType === AppType.CONFIGURATIONS,
          items: configurationItems,
          key: 'testops-config',
        }]);
      // if (appType === AppType.CONFIGURATIONS) {
      //   projectItems = projectItems.concat(configurationItems);
      // }
    }

    const className = type === PageType.KATALON ? 'custom-navbar admin-theme' : 'custom-navbar testops-theme';

    return (
      <Navbar className={collapsed ? `${className} collapsed` : `${className} expanded`}>
        <Nav navbar className="sidebar-main">
          {projectItems.map(this.renderOptionTopNav)}
        </Nav>
      </Navbar>
    );
  }

  generateAvatar(org) {
    return (
      <ImageProfile
        name={org.name}
        imgUrl={org.logoUrl}
        diameter={30}
      />
    );
  }
}

export default NavigationBar;
