import React from 'react';
import { Button, CustomInput, Form, Modal, ModalBody, ModalFooter, ModalHeader, Alert } from 'reactstrap';
import Services from '../../utils/Services';
import Notification from '../../utils/Notification';
import Routes from '../../utils/Routes';

class CreateLicenseKeyDialog extends React.Component {

  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.createLicenseKey = this.createLicenseKey.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.state = {
      modal: false,
      confirm: {
        machineId: false,
        expireDate: false,
      },
      allowCreate: false,
    };
  }

  toggle() {
    const { modal } = this.state;
    this.setState({
      modal: !modal,
    });
  }

  licensePageUrl() {
    const { feature } = this.props;
    const route = new Routes();
    switch (feature) {
      case 'KSE':
        return route.organization_offline_kse_licenses_link;
      case 'UNLIMITED_KSE':
        return route.organization_offline_unlimited_kse_licenses_link;
      case 'UNLIMITED_ENGINE':
        return route.organization_offline_unlimited_engine_licenses_link;
      case 'TESTOPS':
        return route.organization_testops_license_link;
      case 'ENGINE':
      default:
        return route.organization_offline_engine_licenses_link;
    }
  }

  createLicenseKey(event) {
    if (event && event.preventDefault) {
      event.preventDefault();
    }
    const { licenseKey, organizationId, type, feature } = this.props;

    if (licenseKey) {
      const licenseKeyData = {
        ...licenseKey,
        organizationId,
        type,
        feature
      };
      this.emitCreatingEvent();
      Services.createNewLicenseKey(licenseKeyData)
        .then(() => {
          const url = this.licensePageUrl();
          const message = `Your offline license has been created.<br/> <a href=${url}>View my license</a>`;
          Notification.pushSuccessPermanent(message, 'Successful');
          this.emitCreatingSuccessEvent();
        })
        .finally(() => {
          this.emitCreatingDoneEvent();
        });
    }
    this.toggle();
  }

  emitCreatingSuccessEvent() {
    if (this.props.onCreatingSuccess) {
      this.props.onCreatingSuccess();
    }
  }

  emitCreatingEvent() {
    if (this.props.onCreating) {
      this.props.onCreating();
    }
  }

  emitCreatingDoneEvent() {
    if (this.props.onCreatingDone) {
      this.props.onCreatingDone();
    }
  }

  handleCheckboxChange(event) {
    const name = event.target.name;
    const { confirm } = this.state;
    confirm[name] = !confirm[name];

    const allowCreate = confirm.machineId && confirm.expireDate;

    this.setState({ confirm, allowCreate });
  }

  render() {
    const { machineId, expirationDate } = this.props.licenseKey;
    const { feature } = this.props;
    const { confirm, allowCreate } = this.state;
    return (
      <Modal isOpen={this.state.modal} toggle={this.toggle}>
        <Form data-trackid="create-license-key" data-tracklabel={feature} onSubmit={this.createLicenseKey}>
          <ModalHeader>Create an offline license</ModalHeader>
          <ModalBody>
            <Alert color="warning">
              <b>Warning:{' '}</b>
              This action cannot be undone. Once the license activation key has been generated, the license cannot be re-issued or revoked until its expiration.
              <br />
              Please use the Test function to verify if the license file operates on the designated machine before generating the offline key.
            </Alert>
            To make sure, please confirm:
            <CustomInput
              id="machineId"
              name="machineId"
              onChange={this.handleCheckboxChange}
              type="checkbox"
              checked={confirm.machineId}
              label={<>Machine ID: <b>{machineId}</b></>}
              className="normal-label"
            />
            <CustomInput
              id="expireDate"
              name="expireDate"
              onChange={this.handleCheckboxChange}
              type="checkbox"
              checked={confirm.expireDate}
              label={<>Expiry date: <b>{expirationDate?.toString()}</b></>}
              className="normal-label"
            />
          </ModalBody>
          <ModalFooter>
            <Button type="submit" color="primary" disabled={!allowCreate}>Create</Button>
            <Button color="secondary" onClick={this.toggle}>Cancel</Button>
          </ModalFooter>
        </Form>
      </Modal>
    );
  }
}


export default CreateLicenseKeyDialog;
