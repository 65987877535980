import React from 'react';
import {
  Button, Form, FormGroup, Label, Row, Col, Card, CardBody, CardHeader
} from 'reactstrap';
import template from 'url-template';
import MConfigs from '../../models/MConfigs';
import MContext from '../../models/MContext';
import MAuth from '../../models/MAuth';
import Input from '../../components/Input';
import { subDomainParam } from '../../utils/Constants';
import Services from '../../utils/Services';
import Notification from '../../utils/Notification';
import { t } from '../../i18n/t';

function getSubDomainUrl(subDomain = 'yoursubdomain') {
  const subDomainTemplate = template.parse(MConfigs.subDomainPattern);
  return subDomainTemplate.expand({ [subDomainParam]: subDomain });
}

function getDomainUrl() {
  return MConfigs.subDomainPattern.slice(MConfigs.subDomainPattern.indexOf('}') + 1);
}

class EditOrganizationDomain extends React.Component {
  constructor(props) {
    super(props);
    this.organizationId = MContext.organizationId;
    this.isOrganizationManager = MAuth.isOrganizationManager(this.organizationId);

    this.updateDomain = this.updateDomain.bind(this);
    this.handleTextChange = this.handleTextChange.bind(this);
    this.state = {
      domain: null,
    };
  }

  componentDidMount() {
    const domain = MContext.organization.domain;
    this.setState({ domain });
  }

  handleTextChange(event) {
    const value = event.target.value;
    const name = event.target.name;
    this.setState({ [name]: value });
  }

  onInvalidDomain(message) {
    Notification.pushError(message);
  };

  updateDomain(e) {
    e.preventDefault();
    const { domain } = this.state;
    const domainVal = domain === '' ? null : domain;
    Services.updateDomain(this.organizationId, domainVal, this.onInvalidDomain)
      .then(({ name, strictDomain }) => {
        Notification.pushSuccess(`${name} was updated.`);
        this.setState({ strictDomain });
      });
  }

  renderOrganizationDomain() {
    const { domain, strictDomain } = this.state;
    const href = getSubDomainUrl(domain);
    const disableForm = !this.isOrganizationManager;

    return (
      <Form data-trackid="edit-organization" onSubmit={this.updateDomain}>
        <FormGroup>
          <Label for="orgName">Domain Name</Label>
          <Row>
            <Col>
              <Input
                placeHolder="Your subdomain"
                id="domain"
                name="domain"
                disabled={disableForm}
                value={domain}
                required={strictDomain}
                onChange={this.handleTextChange}
                type="text"
              />
            </Col>
            <Col className="domain-label">
              {getDomainUrl()}
            </Col>
          </Row>
        </FormGroup>
        <p>
          You can access Katalon TestOps using this subdomain (
          <a target="_blank" rel="noopener noreferrer" href={href}>{href}</a>).
        </p>
        <FormGroup>
          <Button type="submit" color="primary" disabled={disableForm}>
            {t('organization_update')}
          </Button>
        </FormGroup>
      </Form>
    );
  }

  renderForm() {
    return (
      <Card>
        <CardHeader>Custom Domain</CardHeader>
        <CardBody>
          <Row>
            <Col sm="12" md="10" lg="8" xl="8">
              {this.renderOrganizationDomain()}
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  }

  render() {
    return (
      <>
        {this.renderForm()}
      </>
    );
  }
}

export default EditOrganizationDomain;
