import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { buildSearchCondition } from '../../components/search/SearchUtils';
import { t } from '../../i18n/t';
import Arrays from '../../utils/Arrays';
import DecoratorConstants from '../../utils/DecoratorConstants';
import StatusFilter, { StatusType } from '../search-query/filter/StatusFilter';
import { buildDefaultFilter, buildFilter } from '../search-query/FilterQueryHelper';
import { buildSortQuery } from '../search-query/SortQueryHelper';
import DataTable from './DataTable';
import MPaginationMapping from './models/MPaginationMapping';
import MTableColumnDataMapping from './models/MTableColumnDataMapping';
import TestCaseExecutionStatus from './TestCaseExecutionStatus';
import ReleaseTimeFilter from '../search-query/filter/ReleaseTimeFilter';
import BuildFilter from '../search-query/filter/BuildFilter';
import MFlags from '../../models/MFlags';

class ReleaseTable extends React.Component {
  constructor(props) {
    super(props);
    this.releaseList = null;
    this.state = {};
  }

  refreshData() {
    this.releaseList.refreshData();
  }

  releaseDetail(name, row) {
    return (
      <>
        <TestCaseExecutionStatus
          key={row.id}
          statistics={row.releaseStatistics || {}}
          defaultSearchConditions={[
            ...Arrays.insertIf(!MFlags.fixReleaseExecutionStatusBarEnabled, buildSearchCondition('time', '=', 2)),
            buildSearchCondition('releaseId', '=', row.id),
          ]}
          showLegend={false}
        />
      </>
    );
  }

  headers() {
    const { jiraConnection } = this.props;
    const headers = [
      new MTableColumnDataMapping(
        t('status'),
        'releaseStatus',
        DecoratorConstants.releaseStatusDecorator,
        undefined,
        'active-status-column',
      ),
      new MTableColumnDataMapping(
        t('name'),
        'name',
        DecoratorConstants.releaseDecorator,
      ),
      new MTableColumnDataMapping(
        t('totalDuration'),
        'releaseStatistics',
        (name, row) => {
          const releaseStatistics = _.get(row, name);
          const totalDuration = releaseStatistics?.totalDuration;
          return (
            <>
              {!totalDuration || totalDuration === 0 ? 0 :
                DecoratorConstants.durationDecorator('totalDuration', releaseStatistics)}
            </>);
        },
        undefined,
        'nowrap-column'
      ),
      new MTableColumnDataMapping(
        t('release#totalExecution'),
        'releaseStatistics',
        (name, row) => {
          const value = _.get(row, name, {});
          const { totalExecution = 0 } = value;
          return (
            <span title={t('executions')}>{totalExecution}</span>
          );
        }
      ),
      new MTableColumnDataMapping(
        t('test_cases'),
        'id',
        this.releaseDetail,
        false,
        'w-25',
      ),
      new MTableColumnDataMapping(
        '',
        '',
        DecoratorConstants.releaseCountDecorator,
      ),
      new MTableColumnDataMapping(
        t('release#start-time'),
        'startTime',
        DecoratorConstants.dateDecorator,
      ),
      new MTableColumnDataMapping(
        t('release#end-time'),
        'endTime',
        DecoratorConstants.dateDecorator,
      ),
      ...Arrays.insertIf(jiraConnection, new MTableColumnDataMapping(
        '',
        'externalRelease',
        DecoratorConstants.jiraLinkDecorator,
      )),
    ];
    return headers;
  }

  render() {
    const { jiraConnection } = this.props;
    const filterQuery = [
      buildFilter(StatusFilter, { id: 'closed', label: 'Release', type: StatusType.RELEASE }),
      ...Arrays.insertIf(jiraConnection,
        buildFilter(StatusFilter, { id: 'ExternalRelease.id', label: 'Source', type: StatusType.JIRA_RELEASE })),
      buildFilter(StatusFilter, { id: 'releaseStatus', type: StatusType.RELEASE_STATUS }),
      buildFilter(ReleaseTimeFilter, { id: 'startTime', label: t('release#start-time') }),
      buildFilter(ReleaseTimeFilter, { id: 'endTime', label: t('release#end-time') }),
      buildFilter(BuildFilter, { id: 'Build.name' }),
    ];
    const sortQuery = [
      ...buildSortQuery('name', t('name')),
      ...buildSortQuery('startTime', t('release#start-time')),
      ...buildSortQuery('endTime', t('release#end-time')),
      ...buildSortQuery('ReleaseStatistics.totalPassed', 'Passed'),
      ...buildSortQuery('ReleaseStatistics.totalFailed', 'Failed'),
    ];
    const defaultFilter = buildDefaultFilter('name', '~', 'Name');
    const newProps = {
      ...this.props,
      filterQuery,
      sortQuery,
      defaultFilter
    };

    return (
      <DataTable
        ref={(ref) => {
          this.releaseList = ref;
        }}
        tableId="Release"
        entityType="Release"
        sourceFieldName="content"
        defaultSearchConditions={this.props.defaultSearchConditions}
        defaultFilterConditions={[
          buildSearchCondition('closed', '=', 'false')
        ]}
        columnMapping={this.headers()}
        paginationMapping={new MPaginationMapping()}
        useSearchQuery
        {...newProps}
      />
    );
  }
}

ReleaseTable.propTypes = {
  defaultSearchConditions: PropTypes.array.isRequired,
};

export default ReleaseTable;
