import Icon from '@katalon-studio/katalon-ui/Icon';
import React from 'react';
import PropTypes from 'prop-types';
import colors from '../../../scss/colors.scss';

function LoadingIcon({ color, size }) {
  return <Icon
    type="fas"
    name="fa-spinner-third fa-spin"
    textSize={size}
    boundingSize={size}
    color={null}
    sx={{
      color,
    }}
  />;
}

LoadingIcon.defaultProps = {
  color: colors.blueFeature,
  size: '16px',
};

LoadingIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
};

export default LoadingIcon;
