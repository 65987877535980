import React from 'react';
import PageComponent from '../../components/PageComponent';
import PageButtonToolbar from '../../components/PageButtonToolbar';
import MContext from '../../models/MContext';
import ObjectSummary from '../../components/summary/ObjectSummary';
import TestProjectDataTable from '../../components/table/TestProjectDataTable';
import { t } from '../../i18n/t';
import Routes from '../../utils/Routes';
import DefaultLayout from '../../components/DefaultLayout';
import GroupEvent from '../../utils/track/GroupEvent';
import MFlags from '../../models/MFlags';
import ButtonLink from '../../components/ButtonLink';

class CodeRepo extends PageComponent {
  constructor(props) {
    super(props);
    this.projectId = MContext.projectId;
    this.teamId = MContext.teamId;
    this.project = MContext.project;
    this.renderHeader = this.renderHeader.bind(this);
    this.renderBody = this.renderBody.bind(this);
    this.meta.id = 'page-code-repo';
    this.meta.title = t(
      'Project {{name}} - {{page}}',
      { name: this.project.name, page: t('test-projects') }
    );
  }

  renderTestProjects() {
    return (
      <TestProjectDataTable
        defaultSort={['name,asc']}
      />
    );
  }

  renderTestProjectUploader() {
    const { projectId, teamId } = this;
    const constructedLink = new Routes({
      projectId,
      teamId,
    });
    return (
      <PageButtonToolbar>
        <ButtonLink
          title={t('import-script-repo')}
          color="secondary"
          href={constructedLink.test_project_create_link}
          data-trackid="to_import_script_repository_form_opened"
        >
          {t('import-script-repo')}
        </ButtonLink>
        <ButtonLink
          title={t('connect_git_script_repository')}
          color="primary"
          href={constructedLink.git_test_project_setup}
          data-trackid="connect-git-script-repository"
          data-groupid={GroupEvent.ACCESS_CONFIGURATION}
        >
          {t('connect_git_script_repository')}
        </ButtonLink>
      </PageButtonToolbar>
    );
  }

  renderObjectSummary() {
    const { project } = this;
    const urlParams = {
      codeRepo: t('test-projects'),
      projectId: project.id,
      project: project.name,
    };
    return (
      <ObjectSummary params={urlParams} />
    );
  }

  renderHeader() {
    return (
      <>
        {this.renderObjectSummary()}
      </>
    );
  }

  renderBody() {
    return (
      <>
        {this.renderTestProjectUploader()}
        {this.renderTestProjects()}
      </>
    );
  }

  render() {
    const { projectId } = this;
    if (projectId) {
      return (
        <DefaultLayout
          renderHeader={this.renderHeader}
          renderBody={this.renderBody}
        />
      );
    }
    return null;
  }
}

export default CodeRepo;
