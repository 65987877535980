import _ from 'lodash';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Badge } from 'reactstrap';
import DefaultLayout from '../../components/DefaultLayout';
import PageComponent from '../../components/PageComponent';
import ObjectSummary from '../../components/summary/ObjectSummary';
import { t } from '../../i18n/t';
import MContext from '../../models/MContext';
import ObjectInfo from '../../components/summary/ObjectInfo';
import DecoratorConstants from '../../utils/DecoratorConstants';
import { buildSearchCondition } from '../../components/search/SearchUtils';
import Services from '../../utils/Services';
import { IconStartTime, IconDuration, IconCenter } from '../../images/CustomIcon';
import Time from '../../utils/Moment';
import Routes from '../../utils/Routes';
import { ExecutionTestOpsVisionHeaderNav } from '../../components/HeaderNav';
import RouteConstants from '../../utils/RouteConstants';
import KEyesExecutionResultTab from './KEyesExecutionResultTab';
import KEyesExecutionCommentsTab from './KEyesExecutionCommentsTab';
import WebSocket from '../../services/WebSocket';
import GroupEvent from '../../utils/track/GroupEvent';
import Link from '../../components/Link';
import { next } from '../../utils/Count';

class KEyesExecution extends PageComponent {
  constructor(props) {
    super(props);
    this.meta.id = 'page-keyes-execution-details';
    this.teamId = MContext.teamId;
    this.projectId = MContext.projectId;
    this.executionId = MContext.executionId;
    this.handlerId = next();
    this.meta.title = t('execution#title', { order: this.executionId, name: '' });
    this.state = {
      keyesExecution: null,
    };
    this.renderHeader = this.renderHeader.bind(this);
    this.renderBody = this.renderBody.bind(this);
    this.getKeyesExecution = this.getKeyesExecution.bind(this);

    this.updateInterval = 5000;
    this.notifyHandler = _.throttle(() => {
      this.getKeyesExecution();
    }, this.updateInterval);
  }

  componentDidMount() {
    this.getKeyesExecution();
    this.subscribeTopic();
  }

  subscribeTopic() {
    if (this.projectId) {
      const topic = `KeyesExecution-${this.executionId}`;
      WebSocket.subscribe(
        {
          projectId: this.projectId,
          topics: [topic],
        },
        this.notifyHandler,
        this.handlerId
      );
    }
  }

  componentWillUnmount() {
    WebSocket.unsubscribe(this.handlerId);
  }

  getKeyesExecution() {
    const params = {
      pagination: {
        page: 0,
        size: 1,
        sorts: 'order,desc',
      },
      conditions: [
        buildSearchCondition('Execution.order', '=', this.executionId),
      ],
      type: 'KeyesExecution',
    };
    Services.search(params)
      .then((responseJson) => {
        const keyesExecution = responseJson.content[0];
        this.setState({
          keyesExecution
        });
      });
  }

  comparedBaselineCollection() {
    const { keyesExecution } = this.state;
    const { baselineCollection } = keyesExecution;

    if (baselineCollection) {
      const name = _.get(baselineCollection, 'baselineCollectionGroup.name', '');
      const version = _.get(baselineCollection, 'version', '');
      const order = _.get(baselineCollection, 'baselineCollectionGroup.order', '');
      const constructedLink = new Routes({
        baselineCollectionGroupOrder: order,
      });
      const historyVersionLink = constructedLink.baseline_collection_group_history_link({ v: version });
      const trackingProps = {
        'data-trackid': 'page-keyes-execution-details-click-baseline-version',
        'data-groupId': GroupEvent.ACCESS_REPORT,
      };

      return (
        <>
          <span>{t('compared-to-baseline-collection')}</span>
          <span className="ml-2">
            {DecoratorConstants.nameAndVersionLinkDecorator(name, version, historyVersionLink, trackingProps)}
          </span>
        </>
      );
    }
    return null;
  }


  getInfoItems() {
    const { keyesExecution } = this.state;
    const { execution } = keyesExecution;
    const routes = new Routes({ executionId: this.executionId });
    const infoItems = [
      {
        text: this.comparedBaselineCollection(),
      },
      {
        icon: <IconStartTime />,
        text: Time.format(execution.startTime),
      },
      {
        icon: <IconDuration />,
        text: Time.duration(execution.duration)
      },
      {
        icon: <IconCenter />,
        text: <Link href={routes.execution_details_link}>{t('test-results')}</Link>,
      }
    ];
    return infoItems;
  }

  getStatus() {
    const { status } = this.state.keyesExecution;
    return status ? DecoratorConstants.status(status, 'd-flex align-content-center') : null;
  }

  getUnsavedStatus() {
    const { unsaved } = this.state.keyesExecution;
    return unsaved ? <Badge title={t('unsaved')} className="status-badge status-badge-header-unsaved">{t('unsaved')}</Badge> : null;
  }

  renderObjectSummary() {
    const status = this.getStatus();
    const unsaved = this.getUnsavedStatus();
    const urlParams = {
      keyesExecutionId: this.executionId,
      status,
      unsaved
    };

    const infoItems = this.getInfoItems();

    return (
      <ObjectSummary params={urlParams}>
        <ExecutionTestOpsVisionHeaderNav
          projectId={this.projectId}
          teamId={this.teamId}
          executionId={this.executionId}
        />
        <ObjectInfo items={infoItems} />
      </ObjectSummary>
    );
  }

  renderHeader() {
    return <>{this.renderObjectSummary()}</>;
  }

  renderBody() {
    const { keyesExecution } = this.state;
    return (
      <>
        <Switch>
          <Route
            exact
            path={RouteConstants.keyes_execution_details}
            render={(params) => (
              <KEyesExecutionResultTab
                keyesExecution={keyesExecution}
                refreshData={this.getKeyesExecution}
                {...params}
              />
            )}
          />
          <Route
            exact
            path={RouteConstants.keyes_execution_detail_comment}
            render={(params) => (
              <KEyesExecutionCommentsTab
                projectId={this.projectId}
                teamId={this.teamId}
                objectId={keyesExecution.id}
                objectType="KEYES_EXECUTION"
                {...params}
              />
            )}
          />
          <Redirect from={RouteConstants.keyes_execution_details_checkpoints} to={RouteConstants.keyes_execution_details} />
        </Switch>
      </>
    );
  }

  render() {
    const { keyesExecution } = this.state;
    if (keyesExecution) {
      return (
        <DefaultLayout
          renderHeader={this.renderHeader}
          renderBody={this.renderBody}
        />
      );
    } else {
      return null;
    }

  }
}

export default KEyesExecution;
