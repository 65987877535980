import React from 'react';
import CustomFieldFilter from '../../components/CustomFieldFilter';
import { withTabComponent } from '../../components/HigherOrderComponent';
import TagFilterV2 from '../../components/search-query/filter/TagFilterV2';
import { buildFilter } from '../../components/search-query/FilterQueryHelper';
import { buildSearchCondition } from '../../components/search/SearchUtils';
import ExecutionTestResultDataTable from '../../components/table/ExecutionTestResultDataTable';
import { t } from '../../i18n/t';

const TestRunsTab = ({ executionId }) => {
  const additionalFilterQuery = [
    buildFilter(CustomFieldFilter, { id: 'CustomField' }),
    buildFilter(TagFilterV2, { id: 'Tag.id', label: t('search-bar#tag') }),
  ];
  return (
    <ExecutionTestResultDataTable
      defaultSearchConditions={[
        buildSearchCondition('Execution.id', '=', executionId)
      ]}
      additionalFilterQuery={additionalFilterQuery}
    />
  );
};


export default withTabComponent(TestRunsTab, 'page-execution-details-test-run', (props) => t('Execution #{{name}}', { name: props.executionId }));
