import React, { useState } from 'react';
import UpgradeRequest from '../../../components/subscription/UpgradeRequest';
import { Card, CardHeader, CardBody, Button, Collapse } from 'reactstrap';
import { IconCollapse, IconExpand } from '../../../images/CustomNewIcon';
import { t } from '../../../i18n/t';

export const DummyUpgradeCard = ({ title, customControl, useCache, requiredSubscription, isUsePlatformMessage }) => {
    const [collapsed, setCollapsed] = useState(false);

    return (
        <Card className="data-loader">
        {title &&
          <CardHeader className="d-flex align-items-center justify-content-between">
            <div>
              <div className="card-header-title">{title}</div>
            </div>
            {customControl &&
              <div className="data-loader-header-control">
                {customControl}
              </div>}
            {useCache &&
              <Button
                disabled={true}
                className="mr-1"
                color="secondary"
              >
              {t('refresh')}
            </Button>}
            <Button
              className="mr-1"
              onClick={() => setCollapsed(!collapsed)}
              color="link"
            >
              {collapsed ? <IconExpand /> : <IconCollapse />}
            </Button>
          </CardHeader>}
        <Collapse isOpen={!collapsed}>
          <CardBody>
            <UpgradeRequest subscription={requiredSubscription} isUsePlatformMessage />
          </CardBody>
        </Collapse>
      </Card>
    );
}
