import React, { useEffect, useState } from 'react';
import Button from '@katalon-studio/katalon-ui/Button';
import { Label, FormGroup } from 'reactstrap';
import { isEmpty, throttle } from 'lodash';
import { Dialog, DialogBody, DialogFooter, DialogHeader } from '@katalon-studio/katalon-ui/Dialog';
import SelectG5Location from '../../treeview/SelectG5Location';
import AlertComponent from '../../AlertComponent';
import { TestCase } from '../../../models/model/TestCase';
import { t } from '../../../i18n/t';
import { buildSearchCondition } from '../../search/SearchUtils';
import Services from '../../../utils/Services';
import { TestSuite } from '../../../models/model/TestSuite';
import InfoIcon from '../../../../images/icons/katalonui/InfoIcon';
import ErrorIcon from '../../../../images/icons/katalonui/ErrorIcon';
import ActionsHelper from '../../../utils/table/ActionsHelper';
import { ACTIONS_TRACKING, DISPLAYED_PAGE_TRACKING, KATALON_EVENTS, OperationType } from '../../../utils/Constants';
import TestCaseService from '../service/TestCaseService';
import NotificationHandler from '../handler/NotificationHandler';
import { TestEntity } from '../../../models/model/TestEntity';
import TestSuiteService from '../service/TestSuiteService';
import PageHistoryHelper, { PageHistoryComponents } from '../../../utils/PageHistoryHelper';
import Input from '../../Input';
import { next } from '../../../utils/Count';
import MFlags from '../../../models/MFlags';
import { IconErrorXMark } from '../../../images/CustomNewIcon';

function MoveToLocationDialog() {
  const [path, setPath] = useState<string>('');
  const [invalidFolderName, setInvalidFolderName] = useState<boolean>(false);
  const [isDuplicated, setDuplicated] = useState<boolean>(false);
  const [testEntities, setTestEntities] = useState<TestEntity[]>([]);
  const firstTestEntity = testEntities[0];
  const [isOpen, setOpen] = useState<boolean>(false);
  const [key, setKey] = useState(next());
  const [operationType, setOperationType] = useState(OperationType.INDIVIDUAL);
  const [isExcludedFolderPath, setIsExcludedFolderPath] = useState<boolean>(false);

  const onChangeExcludedPathsAlert = (isExcludedFolderPath: boolean | ((prevState: boolean) => boolean)) => {
    setIsExcludedFolderPath(isExcludedFolderPath);
    setPath('');
  };

  const handleClose = () => {
    setOpen(false);
    setPath('');
    setTestEntities([]);
    setDuplicated(false);
    setInvalidFolderName(false);
    setIsExcludedFolderPath(false);

    PageHistoryHelper.saveState(PageHistoryComponents.MOVE_TO_LOCATION_DIALOG, { expandedKeys: [], selectedKeys: [] });
  };

  const handleSelectTestCaseFolder = (testEntity: TestEntity) => {
    const { testCase } = testEntity;
    if (testCase) {
      setPath(testCase.path);
      Services.getTestCaseById(testCase.id)
        .then((testCase) => {
          if (testCase?.testFolder && testCase.testProject) {
            PageHistoryHelper.updateHistory(testCase.testFolder, testCase.testProject.id, PageHistoryComponents.MOVE_TO_LOCATION_DIALOG);
            setKey(next());
          }
        })
        .catch(() => {
          // ignore
        });
    }
  };

  const handleSelectTestSuiteFolder = (testEntity: TestEntity) => {
    const { testSuite } = testEntity;
    if (testSuite) {
      setPath(testSuite.path);
      Services.getTestSuite(testSuite.id)
        .then((testSuite) => {
          if (testSuite?.testFolder && testSuite.testProject) {
            PageHistoryHelper.updateHistory(testSuite.testFolder, testSuite.testProject.id, PageHistoryComponents.MOVE_TO_LOCATION_DIALOG);
            setKey(next());
          }
        })
        .catch(() => {
          // ignore
        });
    }
  };

  const handleSelectCurrentFolder = (testEntity: TestEntity) => {
    if (testEntity?.testCase) {
      handleSelectTestCaseFolder(testEntity);
    } else if (testEntity?.testSuite) {
      handleSelectTestSuiteFolder(testEntity);
    }
  };

  useEffect(() => {
    document.addEventListener(KATALON_EVENTS.openMoveG5TestEntityDialog, (e: any) => {
      const testEntity = e.detail.testEntity as TestEntity;
      const testEntities = e.detail.testEntities as TestEntity[];
      const operationType = e.detail.operationType;
      if (operationType) {
        setOperationType(operationType);
      }
      if (testEntity) {
        setTestEntities([testEntity]);
      } else if (testEntities) {
        setTestEntities(testEntities);
      }
      setOpen(true);
      handleSelectCurrentFolder(testEntity || testEntities[0]);
    });

    document.addEventListener(KATALON_EVENTS.movedG5TestCase, () => {
      handleClose();
    });

    document.addEventListener(KATALON_EVENTS.movedG5TestSuite, () => {
      handleClose();
    });
  }, []);

  const validateTestCaseNameAndPath = () => {
    if (!firstTestEntity) {
      return;
    }
    const objectData: TestCase | TestSuite | undefined = firstTestEntity.testCase || firstTestEntity.testSuite;

    if (!objectData || !objectData.testProject) {
      return;
    }

    const { testProject, name } = objectData;
    const entityType = ActionsHelper.getEntityType(firstTestEntity);
    setDuplicated(false);
    if (testProject.id) {
      const params = {
        pagination: {
          page: 0,
          size: 1,
          sorts: ['id,desc']
        },
        conditions: [
          buildSearchCondition('name', '=', name),
          buildSearchCondition('path', '=', path),
          buildSearchCondition('TestProject.id', '=', testProject.id),
          buildSearchCondition('id', '!=', objectData.id),
        ],
        type: entityType,
      };
      Services.search(params)
        .then(({ content }) => {
          setDuplicated(!isEmpty(content));
        })
        .catch(() => {
          // ignore
        });
    }
  };

  const throttledGetTestCaseByNameAndTestProjectId = throttle(validateTestCaseNameAndPath, 1000);

  useEffect(() => {
    if (testEntities.length === 1) {
      throttledGetTestCaseByNameAndTestProjectId();
    }
  }, [path]);

  if (!firstTestEntity) {
    return null;
  }

  const objectData: TestCase | TestSuite | undefined = firstTestEntity.testCase || firstTestEntity.testSuite;

  if (!objectData || !objectData.testProject) {
    return null;
  }
  const { testProject } = objectData;

  const disabledMove = !path || path === objectData.path || isDuplicated || invalidFolderName
    || (MFlags.preventNamingTestOpsInTestSuiteFoldersEnabled && isExcludedFolderPath);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const moveTestCaseErrorHandler = (message: string, label: string, jqXHR: any) => {
    NotificationHandler.moveTestCasesErrorHandler(message, jqXHR, testEntities);
  };

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const moveTestSuiteErrorHandler = (message: string, label: string, jqXHR: any) => {
    NotificationHandler.moveTestSuitesErrorHandler(message, jqXHR, testEntities);
  };

  const handleMove = (e: any) => {
    e.preventDefault();

    const { testCase, testSuite } = firstTestEntity || {};

    if (testCase) {
      ActionsHelper.syncTrackingData(
        firstTestEntity,
        ACTIONS_TRACKING.TEST_CASE_MOVED,
        DISPLAYED_PAGE_TRACKING.TES_CASE_TABLE,
        undefined,
        operationType
      );

      // @ts-ignore
      const testCases: TestCase[] = testEntities.map((item) => item.testCase);
      TestCaseService.move(testCases, path, moveTestCaseErrorHandler);
    } else if (testSuite) {
      ActionsHelper.syncTrackingData(
        firstTestEntity,
        ACTIONS_TRACKING.TEST_SUITE_MOVED,
        DISPLAYED_PAGE_TRACKING.TEST_SUITE_TABLE,
        undefined,
        operationType
      );

      // @ts-ignore
      const testSuites: TestSuite[] = testEntities.map((item) => item.testSuite);
      TestSuiteService.move(testSuites, path, moveTestSuiteErrorHandler);
    }
  };

  const onChangeShowAlert = (valid: boolean) => {
    // Event onBlur is called before onClick, show need to delay 300ms to show or hide alert, for Cancel button can close dialog
    setTimeout(() => {
      setInvalidFolderName(valid);
    }, 300);
  };

  const entityType = ActionsHelper.getEntityType(firstTestEntity);
  const renderSelectG5Location = () => {
    let duplicatedMessage = '';
    if (firstTestEntity.testCase) {
      duplicatedMessage = t('test-case#duplicated');
    } else if (firstTestEntity.testSuite) {
      duplicatedMessage = t('test-suite#duplicated');
    }
    return (
      <>
        <SelectG5Location
          key={key}
          publishEntity={entityType}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          testProjectId={testProject.id}
          path={path}
          onSelectFolder={setPath}
          showAlert={onChangeShowAlert}
          enableHistory
          componentName={PageHistoryComponents.MOVE_TO_LOCATION_DIALOG}
          showExcludedPathsAlert={onChangeExcludedPathsAlert}
          excludedFolderPaths={['test-suites/TestOps']}
        />
        {invalidFolderName &&
          <AlertComponent
            text={t('invalid-folder-name')}
            icon={<ErrorIcon />}
            className="mt-2 error"
          />}
        {MFlags.preventNamingTestOpsInTestSuiteFoldersEnabled && isExcludedFolderPath &&
          <AlertComponent
            text={t('invalid-folder-name#specific-name')}
            icon={<IconErrorXMark />}
            className="mt-2 error"
          />}
        {isDuplicated &&
          <AlertComponent
            text={duplicatedMessage}
            icon={<ErrorIcon />}
            className="mt-2 error"
          />}
      </>
    );
  };

  const currentPath = firstTestEntity.testCase?.path || firstTestEntity.testSuite?.path;
  return (
    <Dialog
      open={isOpen}
      maxWidth="sm"
    >
      <DialogHeader onClick={handleClose}>
        {testEntities.length > 1 ? t('move-number-items-to', { numberItems: testEntities.length }) : t('move-to')}
      </DialogHeader>
      <DialogBody className="move-dialog-content">
        <FormGroup>
          <Label for={t('current_location')}>{t('current_location')}</Label>
          <Input plaintext id={t('current_location')} className="text-break">
            {currentPath}
          </Input>
        </FormGroup>
        {renderSelectG5Location()}
        {!MFlags.removeDraftTestCasePublishTermInCloudStudioAlertMessagesEnabled && firstTestEntity.testCase && <AlertComponent
          text={t('test-case#moving#info')}
          icon={<InfoIcon />}
          className="mt-2 info"
        />}
      </DialogBody>
      <DialogFooter className="d-flex justify-content-end publish-dialog__footer">
        <Button
          size="medium"
          variant="text"
          color="primary"
          onClick={handleClose}
        >
          {t('cancel')}
        </Button>
        <Button
          variant="contained"
          disabled={disabledMove}
          color="primary"
          onClick={handleMove}
        >
          {t('move')}
        </Button>
      </DialogFooter>
    </Dialog>
  );
}

export default MoveToLocationDialog;
