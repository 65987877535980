import React from 'react';
import { Button, Form, FormGroup, Label, Row, Col, Card, CardBody, CardHeader, FormText, ButtonToolbar } from 'reactstrap';
import { IconButton } from '@mui/material';
import { t } from '../i18n/t';
import { IconArrowLeft } from '../images/CustomIcon';
import MContext from '../models/MContext';
import MFlags from '../models/MFlags';
import Apis from '../utils/Apis';
import http from '../utils/http';
import Notification from '../utils/Notification';
import Services from '../utils/Services';
import RouteConstants from '../utils/RouteConstants';
import { AuthenticationType } from '../utils/Constants';
import Input from '../components/Input';
import Select from '../components/Select';
import PageComponent from '../components/PageComponent';
import ObjectSummary from '../components/summary/ObjectSummary';
import Routes from '../utils/Routes';
import Link from '../components/Link';
import DefaultLayout from '../components/DefaultLayout';
import AgentProjectLink from '../components/agentproject/AgentProjectLink';

class K8SAgent extends PageComponent {

  constructor(props) {
    super(props);
    this.teamId = MContext.teamId;
    this.k8sAgentId = MContext.k8sAgentId;
    this.updateMode = !!this.k8sAgentId;
    this.organizationId = MContext.organizationId;

    if (this.updateMode) {
      this.meta.id = 'page-update-k8s-agent-setting';
    } else {
      this.meta.id = 'page-create-k8s-agent-setting';
    }
    this.meta.title = t('Kubernetes Agent');

    this.initState();

    this.handleAgentName = this.handleAgentName.bind(this);
    this.handleK8SPassword = this.handleK8SPassword.bind(this);
    this.handleK8SUrl = this.handleK8SUrl.bind(this);
    this.handleK8SUsername = this.handleK8SUsername.bind(this);
    this.handleK8SNamespace = this.handleK8SNamespace.bind(this);
    this.updateK8SAgentConfig = this.updateK8SAgentConfig.bind(this);
    this.renderBody = this.renderBody.bind(this);
    this.renderHeader = this.renderHeader.bind(this);
    this.handleK8SToken = this.handleK8SToken.bind(this);
    this.handleEKSCluster = this.handleEKSCluster.bind(this);
    this.handleEKSRegion = this.handleEKSRegion.bind(this);
    this.handleEKSAccessKey = this.handleEKSAccessKey.bind(this);
    this.handleEKSPrivateAccessKey = this.handleEKSPrivateAccessKey.bind(this);
    this.handleK8SCertificateAuthority = this.handleK8SCertificateAuthority.bind(this);
    this.selectAuthenticationType = this.selectAuthenticationType.bind(this);
    this.testConnection = this.testConnection.bind(this);
    this.createK8SAgentConfig = this.createK8SAgentConfig.bind(this);
    this.handleApiKey = this.handleApiKey.bind(this);
    this.handleOnSelectChange = this.handleOnSelectChange.bind(this);
  }

  initState() {
    this.k8sAuthenticationOptions = [
      { value: AuthenticationType.BASIC_AUTH, label: t('k8s-agent#basic-auth') },
      { value: AuthenticationType.BEARER_TOKEN, label: t('k8s-agent#bearer-token') },
      { value: AuthenticationType.EKS_AUTH, label: t('k8s-agent#eks-auth') },
    ];
    this.state = {
      agentName: '',
      team: null,
      k8sAgent: null,

      apiKey: '',

      k8sUrl: '',
      k8sCertificateAuthority: '',
      k8sNamespace: '',

      k8sAuthenticationType: null,
      // Basic Auth
      k8sUsername: '',
      k8sPassword: '',
      // Bearer token
      k8sToken: '',
      // EKS authentication
      eksCluster: '',
      eksRegion: '',
      eksAccessKey: '',
      eksPrivateAccessKey: '',

      linkedProjects: [],
    };
  }

  getTeam() {
    http.get(Apis.teamId(this.teamId))
      .then((responseJson) => {
        const team = responseJson;
        this.setState({
          team,
        });
      });
  }

  getK8SAgentConfig() {
    http.get(Apis.k8sAgent(this.k8sAgentId))
      .then((responseJson) => {
        const k8sAgent = responseJson;
        if (k8sAgent) {
          this.setState({
            k8sAgent,
            agentName: k8sAgent.name,
            apiKey: k8sAgent.apiKey,
            k8sUrl: k8sAgent.url,
            k8sCertificateAuthority: k8sAgent.certificateAuthority,
            k8sNamespace: k8sAgent.namespace,
            k8sAuthenticationType: k8sAgent.authenticationType,
            k8sUsername: k8sAgent.username,
            k8sPassword: k8sAgent.password,
            k8sToken: k8sAgent.token,
            eksCluster: k8sAgent.cluster,
            eksRegion: k8sAgent.region,
            eksAccessKey: k8sAgent.accessKey,
            eksPrivateAccessKey: k8sAgent.privateAccessKey,
            linkedProjects: k8sAgent.filteredLinkedProjects || [],
          });
        }
      });
  }

  componentDidMount() {
    const { moveAgentToOrgLevelPhase2Enabled } = MFlags;
    if (!moveAgentToOrgLevelPhase2Enabled) {
      this.getTeam();
    }
    if (this.updateMode) {
      this.getK8SAgentConfig();
    }
  }

  handleK8SNamespace(event) {
    const k8sNamespace = event.target.value;
    this.setState({ k8sNamespace });
  }

  selectAuthenticationType(authenticationOption) {
    const k8sAuthenticationType = authenticationOption ? authenticationOption.value : null;
    this.setState({ k8sAuthenticationType });
  }

  handleAgentName(event) {
    const agentName = event.target.value;
    this.setState({ agentName });
  }

  handleK8SUrl(event) {
    const k8sUrl = event.target.value;
    this.setState({ k8sUrl });
  }

  handleK8SUsername(event) {
    const k8sUsername = event.target.value;
    this.setState({ k8sUsername });
  }

  handleK8SPassword(event) {
    const k8sPassword = event.target.value;
    this.setState({ k8sPassword });
  }

  handleK8SToken(event) {
    const k8sToken = event.target.value;
    this.setState({ k8sToken });
  }

  handleEKSCluster(event) {
    const eksCluster = event.target.value;
    this.setState({ eksCluster });
  }

  handleEKSRegion(event) {
    const eksRegion = event.target.value;
    this.setState({ eksRegion });
  }

  handleEKSAccessKey(event) {
    const eksAccessKey = event.target.value;
    this.setState({ eksAccessKey });
  }

  handleEKSPrivateAccessKey(event) {
    const eksPrivateAccessKey = event.target.value;
    this.setState({ eksPrivateAccessKey });
  }

  handleK8SCertificateAuthority(event) {
    const k8sCertificateAuthority = event.target.value;
    this.setState({ k8sCertificateAuthority });
  }

  handleApiKey(event) {
    const apiKey = event.target.value;
    this.setState({ apiKey });
  }

  createK8SAgentConfig(e) {
    e.preventDefault();
    let data = {
      teamId: this.teamId,
      name: this.state.agentName,
      apiKey: this.state.apiKey,
      url: this.state.k8sUrl,
      certificateAuthority: this.state.k8sCertificateAuthority,
      namespace: this.state.k8sNamespace,
      authenticationType: this.state.k8sAuthenticationType,
      username: this.state.k8sUsername,
      password: this.state.k8sPassword,
      token: this.state.k8sToken,
      cluster: this.state.eksCluster,
      region: this.state.eksRegion,
      accessKey: this.state.eksAccessKey,
      privateAccessKey: this.state.eksPrivateAccessKey,
    };
    if (MFlags.moveAgentToOrgLevelPhase2Enabled) {
      data = { ...data, organizationId: this.organizationId };
    } else {
      data = { ...data, teamId: this.teamId };
    }
    Services.createK8SAgentConfig(data).then(() => {
      if (!MFlags.moveAgentToOrgLevelPhase2Enabled) {
        Routes.goToK8SAgentsLink();
      } else {
        Routes.goToOrganizationK8sAgentsLink();
      }
    });
  }

  updateK8SAgentConfig(e) {
    e.preventDefault();
    const data = {
      id: this.k8sAgentId,
      name: this.state.agentName,
      apiKey: this.state.apiKey,
      url: this.state.k8sUrl,
      certificateAuthority: this.state.k8sCertificateAuthority,
      namespace: this.state.k8sNamespace,
      username: this.state.k8sUsername,
      password: this.state.k8sPassword,
      token: this.state.k8sToken,
      cluster: this.state.eksCluster,
      region: this.state.eksRegion,
      accessKey: this.state.eksAccessKey,
      privateAccessKey: this.state.eksPrivateAccessKey,
      authenticationType: this.state.k8sAuthenticationType,
      projectIds: this.state.linkedProjects.map((project) => project.id),
    };
    Services.updateK8SAgentConfig(data).then(() => {
      if (!MFlags.moveAgentToOrgLevelPhase2Enabled) {
        Routes.goToK8SAgentsLink();
      } else {
        Routes.goToOrganizationK8sAgentsLink();
      }
    });
  }

  testConnection(e) {
    e.preventDefault();
    const data = {
      url: this.state.k8sUrl,
      certificateAuthority: this.state.k8sCertificateAuthority,
      namespace: this.state.k8sNamespace,
      username: this.state.k8sUsername,
      password: this.state.k8sPassword,
      token: this.state.k8sToken,
      cluster: this.state.eksCluster,
      region: this.state.eksRegion,
      accessKey: this.state.eksAccessKey,
      privateAccessKey: this.state.eksPrivateAccessKey,
      authenticationType: this.state.k8sAuthenticationType,
    };
    Services.testK8SConnection(data).then(() => {
      Notification.pushSuccess('Connect Successfully');
    });
  }

  handleOnSelectChange(selectedProjects) {
    this.setState({
      linkedProjects: selectedProjects,
    });
  }

  renderBody() {
    const {
      agentName,
      apiKey,
      k8sUrl, k8sCertificateAuthority, k8sNamespace,
      k8sAuthenticationType,
      k8sUsername, k8sPassword,
      k8sToken,
      eksCluster, eksRegion, eksAccessKey, eksPrivateAccessKey,
      linkedProjects,
    } = this.state;
    const routes = new Routes({ organizationId: this.organizationId });
    
    return (
      <Card>
        <CardHeader>
          {MFlags.moveAgentToOrgLevelPhase2Enabled ?
            <div className='app-agent-header'>
               <IconButton size='small' href={routes.organization_setting_k8s_agent_link}>
                 <IconArrowLeft className='app-agent-header__back_button' />
               </IconButton>
               <div>{t('k8s-agent#title')}</div>
             </div> :
             t('k8s-agent#title')
            }
        </CardHeader>
        <CardBody>
          <Row>
            <Col sm="12" md="8" lg="6" xl="5">
              <Form
                data-trackid="update-k8s-agent-config"
                onSubmit={this.updateMode ? this.updateK8SAgentConfig : this.createK8SAgentConfig}
              >
                <FormGroup>
                  <Label for="k8s-name">{t('agent#agent-name')}</Label>
                  <Input
                    required
                    type="text"
                    id="k8s-name"
                    value={agentName}
                    onChange={this.handleAgentName}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="k8s-url">{t('url')}</Label>
                  <Input
                    required
                    type="text"
                    id="k8s-url"
                    value={k8sUrl}
                    onChange={this.handleK8SUrl}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="k8s-certificate-authority">{t('k8s-agent#certificate-authority')}</Label>
                  <Input
                    required
                    type="textarea"
                    id="k8s-certificate-authority"
                    value={k8sCertificateAuthority}
                    rows="5"
                    onChange={this.handleK8SCertificateAuthority}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="k8s-namespace">{t('k8s-agent#namespace')}</Label>
                  <Input
                    required
                    type="text"
                    id="k8s-namespace"
                    value={k8sNamespace}
                    onChange={this.handleK8SNamespace}
                  />
                </FormGroup>
                {!MFlags.moveAgentToOrgLevelPhase2Enabled && (
                  <FormGroup>
                    <Label for="apiKey">{t('agent#api-key')}</Label>
                    <Input
                      required
                      type="text"
                      id="apiKey"
                      value={apiKey}
                      onChange={this.handleApiKey}
                    />
                    <FormText color="muted">
                      {t('agent#generate-api-key')}
                      <Link
                        href={RouteConstants.apikey}
                      >
                        here.
                      </Link>
                    </FormText>
                  </FormGroup>
                )}
                <FormGroup>
                  <Label for="authenticationType">{t('k8s-agent#authenticate-type')}</Label>
                  <Select
                    id="authenticationType"
                    name="authenticationType"
                    onChange={this.selectAuthenticationType}
                    value={k8sAuthenticationType}
                    options={this.k8sAuthenticationOptions}
                  />
                </FormGroup>
                {k8sAuthenticationType === AuthenticationType.BASIC_AUTH && (
                  <>
                    <FormGroup>
                      <Label for="k8s-username">{t('username')}</Label>
                      <Input
                        type="text"
                        id="k8s-username"
                        value={k8sUsername}
                        onChange={this.handleK8SUsername}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label for="k8s-password">{t('k8s-agent#password')}</Label>
                      <Input
                        type="password"
                        id="k8s-password"
                        value={k8sPassword}
                        onChange={this.handleK8SPassword}
                      />
                    </FormGroup>
                  </>
                )}
                {k8sAuthenticationType === AuthenticationType.BEARER_TOKEN && (
                  <FormGroup>
                    <Label for="k8s-token">{t('k8s-agent#token')}</Label>
                    <Input
                      type="password"
                      id="k8s-token"
                      value={k8sToken}
                      onChange={this.handleK8SToken}
                    />
                  </FormGroup>
                )}

                {k8sAuthenticationType === AuthenticationType.EKS_AUTH && (
                  <>
                    <FormGroup>
                      <Label for="eks-region">{t('k8s-agent#eks-region')}</Label>
                      <Input
                        type="text"
                        id="eks-region"
                        value={eksRegion}
                        onChange={this.handleEKSRegion}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label for="eks-cluster">{t('k8s-agent#eks-cluster')}</Label>
                      <Input
                        type="text"
                        id="eks-cluster"
                        value={eksCluster}
                        onChange={this.handleEKSCluster}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label for="eks-access-key">{t('k8s-agent#eks-access-key')}</Label>
                      <Input
                        type="password"
                        id="eks-access-key"
                        value={eksAccessKey}
                        onChange={this.handleEKSAccessKey}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label for="eks-private-access-key">{t('k8s-agent#eks-private-access-key')}</Label>
                      <Input
                        type="password"
                        id="eks-private-access-key"
                        value={eksPrivateAccessKey}
                        onChange={this.handleEKSPrivateAccessKey}
                      />
                    </FormGroup>
                  </>
                )}
                {MFlags.moveAgentToOrgLevelPhase2Enabled && this.updateMode && (
                  <FormGroup>
                    <AgentProjectLink selectedProjects={linkedProjects} handleOnSelectChange={this.handleOnSelectChange}/>
                  </FormGroup>
                )}
                <ButtonToolbar>
                  <Button
                    type="button"
                    color="secondary"
                    onClick={this.testConnection}
                  >
                    {t('k8s-agent#test-connection')}
                  </Button>
                  <Button type="submit" color="primary">
                    {this.updateMode ? t('k8s-agent#update') : t('k8s-agent#create')}
                  </Button>
                </ButtonToolbar>
              </Form>
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  }

  renderObjectSummary() {
    const { team } = this.state;
    const urlParams = {
      agent: t('agent'),
      teamId: this.teamId,
    };
    return (
      <ObjectSummary params={urlParams} />
    );
  }

  renderHeader() {
    return (
      <>
        {this.renderObjectSummary()}
      </>
    );
  }

  render() {
    const { team } = this.state;
    if (!MFlags.moveAgentToOrgLevelPhase2Enabled && !team) return <div>No team</div>;
    if (MFlags.moveAgentToOrgLevelPhase2Enabled && !this.organizationId) return <div>No organization</div>;
    return (
      <DefaultLayout
        renderHeader={this.renderHeader}
        renderBody={this.renderBody}
      />
    );
  }
}

export default K8SAgent;
