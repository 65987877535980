import React from 'react';
import { buildSearchCondition } from '../../components/search/SearchUtils';
import SummaryPlan from './SummaryPlan';
import AgentDataTable from '../../components/table/AgentDataTable';
import K8SAgentDataTable from '../../components/table/K8SAgentDataTable';
import TestCloudAgentDataTable from '../../components/table/TestCloudAgentDataTable';
import CircleCIAgentDataTable from '../../components/table/CircleCIAgentDataTable';
import { CloudType } from '../../utils/Constants';
import MContext from '../../models/MContext';
import Arrays from '../../utils/Arrays';
import MFlags from '../../models/MFlags';


const PlanTestCloudAgents = ({ runConfigurationId }) => (
  <TestCloudAgentDataTable
    title="TestCloud Agents"
    defaultSearchConditions={[
      buildSearchCondition('RunConfiguration.id', '=', runConfigurationId),
    ]}
    isConfigurationView
  />
);

const PlanAgents = ({ runConfigurationId }) => (
  <AgentDataTable
    title="Agents"
    defaultSearchConditions={[
      ...Arrays.insertIf(!MFlags.moveAgentToOrgLevelPhase2Enabled, buildSearchCondition('Team.id', '=', MContext.teamId)),
      buildSearchCondition('RunConfiguration.id', '=', runConfigurationId),
    ]}
 />
);

const PlanK8sAgents = ({ runConfigurationId }) => (
  <K8SAgentDataTable
    title="K8S Agents"
    defaultSearchConditions={[
      ...Arrays.insertIf(!MFlags.moveAgentToOrgLevelPhase2Enabled, buildSearchCondition('Team.id', '=', MContext.teamId)),
      buildSearchCondition('RunConfiguration.id', '=', runConfigurationId),
    ]}
  />
);

const PlanCircleCIAgents = ({ runConfigurationId }) => (
  <CircleCIAgentDataTable
    title="Circle CI Agents"
    defaultSearchConditions={[
      ...Arrays.insertIf(!MFlags.moveAgentToOrgLevelPhase2Enabled, buildSearchCondition('Team.id', '=', MContext.teamId)),
      buildSearchCondition('RunConfiguration.id', '=', runConfigurationId),
    ]}
  />
);

const renderAgents = (runConfiguration) => (
  <>
    {runConfiguration.cloudType === CloudType.LOCAL_AGENT &&
      <PlanAgents runConfigurationId={runConfiguration.id} />}

    {runConfiguration.cloudType === CloudType.TEST_CLOUD_AGENT &&
      <PlanTestCloudAgents runConfigurationId={runConfiguration.id} />}

    {runConfiguration.cloudType === CloudType.K8S_AGENT &&
      <PlanK8sAgents runConfigurationId={runConfiguration.id} />}

    {runConfiguration.cloudType === CloudType.CIRCLE_CI_AGENT &&
      <PlanCircleCIAgents runConfigurationId={runConfiguration.id} />}
  </>
);

const PlanConfiguration = ({ runConfiguration }) => {
  return (
    <>
      <SummaryPlan runConfiguration={runConfiguration} />
      {!MContext.isTeamDemo && renderAgents(runConfiguration)}
    </>
  );
};

export default PlanConfiguration;
