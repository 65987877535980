import React from 'react';
import { Card, CardBody } from 'reactstrap';
import { Button } from '@mui/material';
import PageComponent from '../../components/PageComponent';
import MContext from '../../models/MContext';
import { t } from '../../i18n/t';
import { IconCalendarClock, IconLayerGroup, IconPenField, IconProfileFilter, IconRefresh, IconTags } from '../../images/CustomIcon';
import {
  defaultGroupByTime,
  FeatureNames,
  LABEL_ENTITY_TYPE,
  ResultFilterType,
  TestRunDailyOptionsV2
} from '../../utils/Constants';
import GroupEvent from '../../utils/track/GroupEvent';
import { sendAnalyticEventForAction } from '../../utils/SegmentAnalytics';
import { withPremiumFeature } from '../../components/HigherOrderComponent';
import FailedTestResultChart from './test_run_report/FailedTestResultChart';
import { getSearchConditionsForFailedTestResultPage } from './test_result_reports/utils';
import TopNExceptionsDataTable from './test_result_reports/TopNExceptionsDataTable';
import PopperDateRangePickerFilter from './test_run_report/PopperDateRangePickerFilter';
import { buildFilter } from '../../components/search-query/FilterQueryHelper';
import TestSuiteFilter from '../../components/search-query/filter/TestSuiteFilter';
import ProfileFilterV2 from '../../components/search-query/filter/ProfileFilterV2';
import Time from '../../utils/Moment';
import { getEndTime, getStartTime } from './utils';
import CustomFieldFilter from '../../components/CustomFieldFilter';
import ViewByFilter, { optionsResultFilter } from '../../components/search-query/filter/ViewByFilter';
import TagFilterV2 from '../../components/search-query/filter/TagFilterV2';

class FailedTestResultsV2 extends PageComponent {

  constructor(props) {
    super(props);
    this.meta.id = 'page-failed-test-result-dashboard';
    this.projectId = MContext.projectId;
    this.project = MContext.project;
    this.meta.title = t(
      'Project {{name}} - {{page}}',
      { name: this.project.name, page: t('failed_test_results') },
    );

    this.state = {
      allFilterValue: {
        startTime: getStartTime(TestRunDailyOptionsV2.SEVEN_DAYS.value, defaultGroupByTime),
        endTime: getEndTime(defaultGroupByTime),
        testSuite: null,
        profile: null,
        customField: null,
        tag: null,
        TypeFilter: null,
      },
      select: optionsResultFilter[0]
    };

    sendAnalyticEventForAction(
      'page-failed-test-result-dashboard',
      { 'data-groupid': GroupEvent.ACCESS_REPORT }
    );

    this.renderFilter = this.renderFilter.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.handleDateRange = this.handleDateRange.bind(this);
    this.handleChangeDateRange = this.handleChangeDateRange.bind(this);
    this.handleUpdateFilter = this.handleUpdateFilter.bind(this);
    this.renderButtonApplyFilter = this.renderButtonApplyFilter.bind(this);
    this.refreshAllCharts = this.refreshAllCharts.bind(this);
    this.failedTestResultChartRef = React.createRef();
    this.topNExceptionsDataTableRef = React.createRef();
    this.topNExceptionsRef = React.createRef();
  }

  handleDateRange(startTime, endTime) {
    this.setState((prevState) => {
      const updatedState = { ...prevState };
      updatedState.allFilterValue = {
        ...updatedState.allFilterValue,
        startTime: startTime ? Time.startOf(startTime, 'day') : null,
        endTime: endTime ? Time.endOf(endTime, 'day') : null
      };
      return updatedState;
    });
  }

  handleUpdateFilter(key, value) {
    this.setState((prevState) => {
      const updatedState = { ...prevState };
      updatedState.allFilterValue = {
        ...updatedState.allFilterValue,
        [key]: value
      };
      return updatedState;
    });
  }

  handleChangeDateRange(dateRange) {
    this.handleDateRange(dateRange[0], dateRange[1]);
  }

  handleFilterChange(filterKey, filterValue) {
    if (filterKey === 'customField' && filterValue) {
      const convertedFilterValue = filterValue.map((customField) => {
        const object = customField.split('=');
        return {
          key: object[0],
          operator: '=',
          value: object[1],
        };
      });
      this.handleUpdateFilter(filterKey, convertedFilterValue);
    } else if (filterKey === 'TypeFilter') {
      const selectedOption = optionsResultFilter.find((option) => option.value === filterValue);
      this.setState({ select: selectedOption });
      if (filterValue === optionsResultFilter[1].value) {
        this.handleUpdateFilter(filterKey, ResultFilterType.LATEST_RESULTS);
      } else {
        this.handleUpdateFilter(filterKey, null);
      }
    } else if (filterKey !== 'TimeFilter') {
      this.handleUpdateFilter(filterKey, filterValue);
    }
  }

  refreshAllCharts() {
    if (this.failedTestResultChartRef.current) {
      this.failedTestResultChartRef.current.clearCacheAndRefreshData();
    }
    if (this.topNExceptionsRef.current) {
      this.topNExceptionsRef.current.closeInfoSideBar();
    }
  }

  renderButtonApplyFilter() {
    return (
      <Button
        onClick={this.refreshAllCharts}
        id="refresh-btn"
        title={t('refresh')}
        className="btn-secondary"
        startIcon={<IconRefresh />}
        data-trackid="refresh-failed-test-result-dashboard"
        data-groupid={GroupEvent.ACCESS_REPORT}
      >
        {t('refresh')}
      </Button>
    );
  }

  renderFilter() {
    const listFilterComponent = [
      buildFilter(ViewByFilter, {
        id: 'TypeFilter',
      }),
      buildFilter(PopperDateRangePickerFilter, {
        id: 'TimeFilter',
        label: 'TimeFilter',
        handleChangeDateRange: this.handleChangeDateRange,
        timeInterval: defaultGroupByTime,
        isHideTimeInterval: true,
        icon: <IconCalendarClock />
      }),
      buildFilter(TestSuiteFilter, { id: 'testSuite', icon: <IconLayerGroup /> }),
      buildFilter(ProfileFilterV2, { id: 'profile', label: 'Profile', icon: <IconProfileFilter /> }),
      buildFilter(CustomFieldFilter, { id: 'customField', icon: <IconPenField /> }),
      buildFilter(TagFilterV2, { id: 'tag', label: t('search-bar#tag'), icon: <IconTags /> }),
    ];
    return (
      <div className="d-flex mt-2">
        <div className="d-flex flex-wrap">
          {listFilterComponent.map((filter) => {
            const CustomFilter = filter.component;
            const filterProps = filter.props;
            return (
              <div id={filterProps.id} key={filterProps.id} className="filter mt-2 mr-2 dynamic-filter-query__item">
                <CustomFilter
                  onFilterChange={this.handleFilterChange}
                  {...filterProps}
                />
              </div>
            );
          })}
        </div>
        <div className="ml-auto">
          {this.renderButtonApplyFilter()}
        </div>
      </div>
    );
  }

  renderFailedTestResultChart() {
    const { allFilterValue, select } = this.state;
    const entityType = LABEL_ENTITY_TYPE.EXECUTION_TEST_RESULT;
    const defaultSearchCondition =
      getSearchConditionsForFailedTestResultPage(...Object.values(allFilterValue), entityType);

    return (
      <FailedTestResultChart
        dataLoaderRef={this.failedTestResultChartRef}
        selectedOption={select}
        defaultSearchCondition={defaultSearchCondition}
        customFieldConditionsProp={allFilterValue.customField}
      />
    );
  }

  renderTopNExceptionTable() {
    const { allFilterValue } = this.state;
    return (
      <TopNExceptionsDataTable
        ref={this.topNExceptionsRef}
        numberOfExceptions={10}
        dataLoaderRef={this.topNExceptionsDataTableRef}
        allFilterValue={allFilterValue}
      />
    );
  }

  renderBody() {
    return (
      <Card className="failed-test-result-card">
        <CardBody>
          {this.renderFilter()}
          {this.renderFailedTestResultChart()}
          {this.renderTopNExceptionTable()}
        </CardBody>
      </Card>
    );
  }

  render() {
    return this.renderBody();
  }
}

export default withPremiumFeature(FailedTestResultsV2, { feature: FeatureNames.RCA_EXECUTION });
