import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ProgressBar from '../../pages/readiness_releases_dashboard/components/ProgressBar';
import StatusConstants from '../../utils/Statuses';
import { t } from '../../i18n/t';
import Helper from '../../utils/Helper';

class TestReleaseComponent extends Component {

  convertData(totalPassed, totalFailed) {
    const data = [
      {
        label: StatusConstants.PASSED.label,
        value: totalPassed,
        color: 'new-passed'
      },
      {
        label: StatusConstants.FAILED.label,
        value: totalFailed,
        color: 'new-failed'
      }
    ];
    return data;
  }

  render() {
    const { release } = this.props;
    const { releaseStatistics } = release;
    const totalPassed = releaseStatistics?.totalPassed || 0;
    const totalFailed = releaseStatistics?.totalFailed || 0;
    const data = this.convertData(totalPassed, totalFailed);
    const percentage = Helper.calculateThePercentage(totalPassed, totalPassed + totalFailed);
    return (
      <ProgressBar
        data={data}
        primary={t('release-summary#test')}
        secondary={`${percentage} %`}
        showLegend={false}
      />
    );
  }
}

TestReleaseComponent.propTypes = {
  release: PropTypes.object,
};

TestReleaseComponent.defaultProps = {
  release: null,
};

export default TestReleaseComponent;
