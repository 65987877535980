import React from 'react';
import { DialogContent, DialogContentText, DialogActions } from '@mui/material';
import { Button, ButtonToolbar, FormGroup, Form, Label } from 'reactstrap';
import PropTypes from 'prop-types';
import { t } from '../../../i18n/t';
import Input from '../../../components/Input';
import CloseableDialogComponent from '../../../components/dialog/CloseableDialogComponent';

function NamingJiraWebhookDialog({ isOpen, handleClose, handleCreateOrRotate, handleChangeName }) {

  return (
    <CloseableDialogComponent
      id="naming-jira-webhook-dialog"
      isOpen={isOpen}
      handleClose={handleClose}
      maxWidth="sm"
      title={t('jira-webhook#naming-and-copying-dialog#title')}
      headerStyle="pb-0"
    >
      <Form onSubmit={handleCreateOrRotate} className="m-0">
        <DialogContent>
          <DialogContentText className="text-dark">
            <span>{t('jira-webhook#naming-dialog#description')}</span>
            <FormGroup>
              <Label for="webhook-name" className="naming-dialog-input-label">{t('jira-webhook#naming-dialog#input-label')}</Label>
              <Input
                required
                id="webhook-name"
                name="webhook-name"
                type="text"
                className="text-dark"
                placeholder={t('jira-webhook#naming-dialog#input-placeholder')}
                onChange={handleChangeName}
              />
            </FormGroup>
          </DialogContentText>
        </DialogContent>
        <DialogActions className="popup-footer justify-content-end p-0">
          <ButtonToolbar>
            <Button
              id="cancel-create-jira-webhook-btn"
              title={t('cancel')}
              color="secondary"
              onClick={handleClose}
            >
              {t('cancel')}
            </Button>
            <Button
              id="confirm-create-jira-webhook-btn"
              title={t('create')}
              color="primary"
              type="submit"
            >
              {t('create')}
            </Button>
          </ButtonToolbar>
        </DialogActions>
      </Form>
    </CloseableDialogComponent>
  );
}

NamingJiraWebhookDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleCreateOrRotate: PropTypes.func.isRequired,
  handleChangeName: PropTypes.func.isRequired,
};

export default NamingJiraWebhookDialog;
