import React, { ReactNode } from 'react';
import { Card } from '@mui/material';

interface MetricCardProps {
  content: ReactNode;
  time: string;
}

function MetricCard(props: MetricCardProps) {
  const { content, time } = props;

  return (
    <Card variant="outlined" className="metric-card">
      {content}
      <div className="footer">
        <hr className="thematic-break" />
        <p className="time">{time}</p>
      </div>
    </Card>
  );
}

export default MetricCard;
