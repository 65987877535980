import React from 'react';
import template from 'url-template';
import _ from 'lodash';
import jquery from 'jquery';
import MContext from '../models/MContext';
import { notificationIds } from './NotificationPageUtils';
import { t } from '../i18n/t';
import {
  IconTestProject,
  IconRequest
} from '../images/KitIcons';

import {
  IconAgents,
  IconReports,
  IconDashboard,
  IconCodeRepo,
  IconExecution,
  IconGrid,
  IconTasks,
  IconFrameworksIntegration,
  IconUser,
  IconProjects,
  IconTeams,
  IconRelease,
  IconOrganization,
  IconSettings,
  IconTestDesign,
  IconTestPlan,
  IconQuery,
  IconLicense,
  IconPlugins,
  IconApiKey,
  IconSubscription,
  IconPricingLight,
  IconPaymentMethod,
  IconKatalonRecorderBackup,
  IconTestEnvironmentInstallation,
  IconTestObject,
  IconWebService,
  IconTestCase,
  IconTestSuite,
  IconDefect,
  IconRequirement,
  IconLicensesUtilization,
  IconKEyes,
  IconTrialRequestLight,
  IconBaselineCollection,
  IconKS,
} from '../images/CustomIcon';
import DecoratorConstants from './DecoratorConstants';
import { ExecutionDecorator } from '../pages/execution/ExecutionDecorator';
import EditableBaselineCollectionGroupTitle from '../components/EditableBaselineCollectionGroupTitle';
import { DomEventHandlers } from './EventHandler';
import { KATALON_EVENTS } from './Constants';

const createOrganization = '/home/create-organization';

const organizationHomeTemplate = '/organization/{organizationId}/home';

const organizationCreateTemplate = '/home/create-organization';

const katalonManage = '/organization/{organizationId}/admin';

const organizationSetting = '/organization/{organizationId}/settings';

const teamTemplate = '/team/{teamId}';

const manageTemplate = '/user';

const adminTemplate = `${teamTemplate}/admin`;

const projectTemplate = `${teamTemplate}/project/{projectId}`;

const autonomousTemplate = `${projectTemplate}/autonomous`;

const truetestTemplate = `${projectTemplate}/truetest`;

const executionTemplate = `${projectTemplate}/executions/{executionId}`;

const releaseTemplate = `${projectTemplate}/releases/{releaseId}`;

const buildTemplate = `${releaseTemplate}/builds/{buildId}`;

const executionTestResultTemplate = `${executionTemplate}/test-result/{executionTestResultId}`;

const executionTestSuiteTemplate = `${executionTemplate}/test-suite/{executionTestSuiteId}`;

const organizationTemplate = '/organization/{organizationId}/teams';

const testDesignTemplate = `${projectTemplate}/test-design`;

const keyesTemplate = `${projectTemplate}/katalon-eyes`;

const testMaintenanceTemplate = `${projectTemplate}/test-reports/test-maintenance`;

const organizationHome = template.parse(organizationHomeTemplate);
const katalon_manage = template.parse(katalonManage);
const organization_create = template.parse(organizationCreateTemplate);
// const organization = template.parse(organizationTemplate);
const organization_settings = template.parse(`${katalonManage}/settings`);
const organization_trial_request = template.parse(`${katalonManage}/trial-request`);
const organization_users = template.parse(`${katalonManage}/users`);
const organization_manage_projects = template.parse(`${katalonManage}/manage_projects`);
const organization_manage_archived_projects = template.parse(`${katalonManage}/manage_projects/archived`);
const organization_users_invite = template.parse(`${katalonManage}/users/invite`);
const organization_users_manage = template.parse(`${katalonManage}/users/{userId}`);
const organization_teams = template.parse(`${organizationTemplate}/settings`);
const organization_projects = template.parse(`${organizationTemplate}/projects`);
const organization_license_keys = template.parse(`${katalonManage}/license_keys`);
const organization_kse_timeout = template.parse(`${katalonManage}/kse_timeout`);
const organization_plugins = template.parse(`${katalonManage}/plugins`);
const organization_configuration = template.parse(`${katalonManage}/configuration`);
const organization_licenses = template.parse(`${katalonManage}/license_keys`);
const organization_kse_licenses = template.parse(`${katalonManage}/license_keys/kse`);
const organization_unlimited_kse_licenses = template.parse(`${katalonManage}/license_keys/unlimited_kse`);
const organization_offline_kse_licenses = template.parse(`${katalonManage}/license_keys/kse#offline-license`);
const organization_offline_unlimited_kse_licenses = template.parse(`${katalonManage}/license_keys/unlimited_kse#offline-license`);
const organization_testops = template.parse(`${organizationTemplate}/testops`);
const organization_kse_licenses_create = template.parse(`${katalonManage}/license_keys/kse/create`);
const organization_unlimited_kse_licenses_create = template.parse(`${katalonManage}/license_keys/unlimited_kse/create`);
const organization_offline_engine_licenses = template.parse(`${katalonManage}/license_keys/engine#offline-license`);
const organization_offline_unlimited_engine_licenses = template.parse(`${katalonManage}/license_keys/unlimited_engine#offline-license`);
const organization_engine_licenses = template.parse(`${katalonManage}/license_keys/engine`);
const organization_unlimited_engine_licenses = template.parse(`${katalonManage}/license_keys/unlimited_engine`);
const organization_engine_licenses_create = template.parse(`${katalonManage}/license_keys/engine/create`);
const organization_unlimited_engine_licenses_create = template.parse(`${katalonManage}/license_keys/unlimited_engine/create`);
const organization_floating_engine_licenses = template.parse(`${katalonManage}/license_keys/floating_engine`);
const organization_test_cloud_licenses = template.parse(`${katalonManage}/license_keys/test_cloud`);
const organization_testops_licenses = template.parse(`${katalonManage}/license_keys/testops`);
const organization_testops_licenses_create = template.parse(`${katalonManage}/license_keys/testops/create`);
const organization_subscription = template.parse(`${katalonManage}/subscriptions`);
const organization_payment_method = template.parse(`${katalonManage}/payment-method`);
const organization_subscription_new_orders = template.parse(`${katalonManage}/new-orders`);
const organization_subscription_upgrade = template.parse(`${katalonManage}/upgrade-order/{enterpriseProductId}`);
const organization_quote_new_orders = template.parse(`${katalonManage}/new-orders/quote`);
const organization_quote_upgrade = template.parse(`${katalonManage}/upgrade-order/{enterpriseProductId}/quote`);
const organization_payment_success = template.parse(`${katalonManage}/payment-success`);
const organization_subscription_edit = template.parse(`${katalonManage}/subscriptions/edit`);
const organization_session = template.parse(`${katalonManage}/session`);
const organization_testops_subscription = template.parse(`${katalonManage}/testops`);
const organization_testops_subscription_checkout = template.parse(`${katalonManage}/testops/checkout`);
const organization_testops_subscription_quote = template.parse(`${katalonManage}/testops/quote`);

const team = template.parse(teamTemplate);
// const teams_dashboard = template.parse(`${teamTemplate}/dashboard`);
const agent = template.parse(`${projectTemplate}/agent`);

const organizationSettingAgent = template.parse(`${organizationSetting}/agent`);
const organizationSettingK8sAgent = template.parse(`${organizationSetting}/agent/k8s`);
const organizationSettingCircleCiAgent = template.parse(`${organizationSetting}/agent/circle-ci`);
const organizationSettingTestCloudAgent = template.parse(`${organizationSetting}/agent/test-cloud`);
const organizationSettingTestEnvInstallation = template.parse(`${organizationSetting}/test-environment-installation`);
const organizationSettingTestEnvDockerAgentInstallation = template.parse(`${organizationSetting}/test-environment-installation/docker-agent`);

const organizationSettingTrueTest = template.parse(`${organizationSetting}/truetest`);

const agentDetails = template.parse(`${projectTemplate}/agent/local/{agentId}`);
const organizationLocalAgent = template.parse(`${organizationSetting}/agent/local/{agentId}`);

const testCloudAgent = template.parse(`${projectTemplate}/agent/test-cloud/{testCloudAgentId}`);
const testCloudAgents = template.parse(`${projectTemplate}/agent/test-cloud`);
const testCloudTunnel = template.parse(`${projectTemplate}/testCloudTunnel`);

const testEnvInstallation = template.parse(`${projectTemplate}/test-environment-installation`);

const k8sAgents = template.parse(`${projectTemplate}/agent/k8s`);
const k8sAgent = template.parse(`${projectTemplate}/agent/k8s/{k8sAgentId}`);
const k8sAgentCreate = template.parse(`${projectTemplate}/agent/k8s/create`);
const organizationK8sAgent = template.parse(`${organizationSetting}/agent/k8s/{k8sAgentId}`);
const organizationSettingK8sAgents = template.parse(`${organizationSetting}/agent/k8s`);
const organizationSettingK8sAgentCreate = template.parse(`${organizationSetting}/agent/k8s/create`);

const circleCIAgents = template.parse(`${projectTemplate}/agent/circle-ci`);
const circleCIAgent = template.parse(`${projectTemplate}/agent/circle-ci/{circleCIAgentId}`);
const circleCIAgentCreate = template.parse(`${projectTemplate}/agent/circle-ci/create`);
const organizationCircleCIAgent = template.parse(`${organizationSetting}/agent/circle-ci/{circleCIAgentId}`);
const organizationSettingCircleCIAgents = template.parse(`${organizationSetting}/agent/circle-ci`);
const organizationSettingCircleCIAgentCreate = template.parse(`${organizationSetting}/agent/circle-ci/create`);

const project = template.parse(projectTemplate);

const incidents = template.parse(`${testDesignTemplate}/tasks`);
const incident_create = template.parse(`${testDesignTemplate}/tasks/create`);
const incident = template.parse(`${testDesignTemplate}/tasks/{incidentOrder}/view`);
const incident_edit = template.parse(`${testDesignTemplate}/tasks/{incidentOrder}/edit`);

const releases = template.parse(`${projectTemplate}/releases`);
const release_create = template.parse(`${projectTemplate}/releases/create`);
const release_edit = template.parse(`${releaseTemplate}/edit`);
const release_view = template.parse(`${releaseTemplate}`);
const release_executions_details = template.parse(`${releaseTemplate}/executions`);
const release_run_configuration_details = template.parse(`${releaseTemplate}/plans`);

const build_create = template.parse(`${releaseTemplate}/builds/create`);
const build_view = template.parse(buildTemplate);
const build_edit = template.parse(`${buildTemplate}/edit`);

const executions = template.parse(`${projectTemplate}/executions`);
const executions_compare = template.parse(`${projectTemplate}/executions/compare`);

const overview = template.parse(`${projectTemplate}/overview`);
const failed_test_result = template.parse(`${projectTemplate}/failed-test-result`);

const import_manual_reports = template.parse(`${projectTemplate}/import-manual-reports`);

const keyes = template.parse(`${keyesTemplate}`);
const keyes_execution_details_link = template.parse(`${keyesTemplate}/{executionId}`);
const keyes_execution_detail_comment_link = template.parse(`${keyesTemplate}/{executionId}/comments`);

const baseline_collection = template.parse(`${projectTemplate}/baseline-collection`);
const baseline_collection_current = template.parse(`${projectTemplate}/baseline-collection/{screenshotId}`);
const baseline_collection_history = template.parse(`${projectTemplate}/baseline-collection/{screenshotId}/previous-baselines`);

const baseline_collection_group_detail = template.parse(`${projectTemplate}/baseline-collection/{baselineCollectionGroupOrder}`);
const baseline_collection_group_history = template.parse(`${projectTemplate}/baseline-collection/{baselineCollectionGroupOrder}/version`);

const test_design = template.parse(testDesignTemplate);
const test_cases = template.parse(`${testDesignTemplate}/test-cases`);
const test_cases_search = template.parse(`${testDesignTemplate}/test-cases/search`);

const test_projects = template.parse(`${projectTemplate}/test-project`);
const test_project = template.parse(`${projectTemplate}/test-project/{testProjectId}`);
const test_project_create = template.parse(`${projectTemplate}/test-project/create`);
const test_project_create_git = template.parse(`${projectTemplate}/test-project/create/git`);
const test_project_edit = template.parse(`${projectTemplate}/test-project/{testProjectId}/edit`);
const test_project_edit_git = template.parse(`${projectTemplate}/test-project/{testProjectId}/git/edit`);

const run_configuration = template.parse(`${projectTemplate}/grid/plan/{runConfigurationId}/job`);
const run_configuration_summary = template.parse(`${projectTemplate}/grid/plan/{runConfigurationId}/job/summary`);
const run_configuration_session = template.parse(`${projectTemplate}/grid/plan/{runConfigurationId}/job/session`);

const jobs = template.parse(`${projectTemplate}/jobs`);
const job = template.parse(`${projectTemplate}/grid/plan/{runConfigurationId}/job/{jobOrder}`);
const sample_plan = template.parse(`${projectTemplate}/grid/plan/sample`);
const configure_plan = template.parse(`${projectTemplate}/grid/plan/{runConfigurationId}`);

const scheduler_create = template.parse(`${projectTemplate}/grid/plan/{runConfigurationId}/schedule/create`);
const scheduler_edit =
  template.parse(`${projectTemplate}/grid/plan/{runConfigurationId}/schedule/{schedulerId}`);

const test_planning = template.parse(`${projectTemplate}/test-planning`);
const native_apps = template.parse(`${projectTemplate}/native-apps`);
const test_runs = template.parse(`${projectTemplate}/test-runs`);
const test_plans = template.parse(`${projectTemplate}/test-plans`);
const test_suites = template.parse(`${testDesignTemplate}/test-suites`);
const execution_test_result_history = template.parse(`${testDesignTemplate}/test-cases/{testCaseId}`);
const execution_test_suite_history = template.parse(`${testDesignTemplate}/test-suites/{testSuiteId}`);
const execution_test_object_history = template.parse(`${projectTemplate}/web-services/{testObjectId}`);
const frameworks_integration = template.parse(`${projectTemplate}/frameworks-integration`);
const upload_katalon_reports = template.parse(`${projectTemplate}/upload-katalon-reports`);
const integrations = template.parse(`${projectTemplate}/integrations`);
const report_uploader = template.parse(`${projectTemplate}/report-uploader`);
const quick_guide = template.parse(`${projectTemplate}/quick-guide`);
const ci_dashboard = template.parse(`${projectTemplate}/ci`);
const grid = template.parse(`${projectTemplate}/grid`);
const jira_setting = template.parse(`${projectTemplate}/jira-setting`);
const kobiton_setting = template.parse(`${projectTemplate}/kobiton-setting`);
const slack_setting = template.parse(`${projectTemplate}/slack-setting`);
const analysis_settings = template.parse(`${projectTemplate}/analysis-settings`);
const test_object_entities = template.parse(`${testDesignTemplate}/test-objects`);
const test_object_entity_details = template.parse(`${testDesignTemplate}/test-objects/{testObjectEntityId}`);

const test_case_overview = template.parse(`${testDesignTemplate}/test-cases/{testCaseId}/overview`);
const test_case_steps = template.parse(`${testDesignTemplate}/test-cases/{testCaseId}/steps`);
const test_case_local_variables = template.parse(`${testDesignTemplate}/test-cases/{testCaseId}/local-variables`);
const test_case_edit = template.parse(`${testDesignTemplate}/test-cases/{testCaseId}/edit`);
const test_suite_edit = template.parse(`${testDesignTemplate}/test-suites/{testSuiteId}/edit`);

const test_reports = template.parse(`${projectTemplate}/test-reports`);
// const test_report_detail = template.parse(`${projectTemplate}/test-reports/{testReportId}`);
const test_report_test_design = template.parse(`${projectTemplate}/test-reports/test-design`);
const test_report_requirement_test_run = template.parse(`${projectTemplate}/test-reports/test-design/requirement-test-run-coverage`);
const test_report_traceability = template.parse(`${projectTemplate}/test-reports/test-design/traceability-report`);
const test_report_test_execution = template.parse(`${projectTemplate}/test-reports/test-execution`);
const test_report_execution = template.parse(`${projectTemplate}/test-reports/test-execution/execution-report`);
const test_report_test_performance = template.parse(`${projectTemplate}/test-reports/test-execution/test-performance`);
const test_report_frequency = template.parse(`${projectTemplate}/test-reports/test-execution/frequency-report`);
const test_report_test_maintaince = template.parse(`${testMaintenanceTemplate}`);
const test_report_flakiness = template.parse(`${testMaintenanceTemplate}/flakiness-report`);
const test_report_platform_statistics = template.parse(`${testMaintenanceTemplate}/platform-statistics`);
const test_cases_active = template.parse(`${testMaintenanceTemplate}/active`);
const test_cases_stale = template.parse(`${testMaintenanceTemplate}/stale`);
const test_cases_slowest = template.parse(`${testMaintenanceTemplate}/slowest`);

const web_services = template.parse(`${projectTemplate}/web-services`);
const web_services_anomalies = template.parse(`${projectTemplate}/web-services-anomalies`);

const defects = template.parse(`${projectTemplate}/defects`);
const requirements = template.parse(`${projectTemplate}/requirements`);
const requirement_details = template.parse(`${projectTemplate}/requirements/{requirementId}`);

const priorities = template.parse(`${projectTemplate}/test-reports/priorities`);

const project_settings = template.parse(`${projectTemplate}/settings`);

const execution = template.parse(executionTemplate);
const execution_test_run_details = template.parse(`${executionTemplate}/test-runs`);
const execution_analysis_details = template.parse(`${executionTemplate}/analysis`);

const test_suite = template.parse(executionTestSuiteTemplate);
const test_suite_test_runs = template.parse(`${executionTestSuiteTemplate}/test-runs`);
const test_suite_search = template.parse(`${testDesignTemplate}/test-suites/search`);

const execution_test_object_details = template.parse(`${executionTemplate}/test-objects`);

const test_result = template.parse(executionTestResultTemplate);
const execution_test_result_context_variables = template.parse(`${executionTestResultTemplate}/environments`);
const execution_test_suite_context_variables = template.parse(`${executionTestSuiteTemplate}/environments`);
const execution_test_suite_comment_link = template.parse(`${executionTestSuiteTemplate}/comments`);

const execution_test_result_request_link = template.parse(`${executionTestResultTemplate}/requests`);
const execution_request_detail = template.parse(`${executionTestResultTemplate}/requests/{executionRequestId}`);
const execution_file_details = template.parse(`${executionTemplate}/files`);
const execution_defect = template.parse(`${executionTemplate}/defect`);
const execution_test_result_treeviewer_link = template.parse(`${executionTestResultTemplate}/tree-viewer`);
const execution_test_result_comment_link = template.parse(`${executionTestResultTemplate}/comments`);
const execution_test_result_test_objects_link = template.parse(`${executionTestResultTemplate}/test-objects`);
const execution_test_result_rerun_link = template.parse(`${executionTestResultTemplate}/re-runs`);
const execution_test_result_similar_failures_link = template.parse(`${executionTestResultTemplate}/similar-failures`);

const admin_users = template.parse(`${adminTemplate}/users`);
const admin_users_invite = template.parse(`${adminTemplate}/users/invite`);
const admin_projects = template.parse(`${adminTemplate}/project`);
const admin_team_settings = template.parse(`${adminTemplate}/settings`);

const user_profile = template.parse(`${manageTemplate}/profile`);
const user_settings = template.parse(`${manageTemplate}/settings`);
const user_license = template.parse(`${manageTemplate}/license`);
const user_license_create_kse = template.parse(`${manageTemplate}/license/create/kse`);
const user_license_create_engine = template.parse(`${manageTemplate}/license/create/engine`);
const admin_teams = template.parse(`${manageTemplate}/teams`);
const user_change_password = template.parse(`${manageTemplate}/profile/change-password`);

const apikey = template.parse(`${manageTemplate}/apikey`);
const assign_subscription = template.parse(`${manageTemplate}/assign-subscription`);
const katalon_recorder_backup = template.parse(`${manageTemplate}/katalon-recorder-backup`);
const user_integrations = template.parse(`${manageTemplate}/integrations`);

const notification = template.parse('/notification/{notificationId}');

const customFields = template.parse(`${projectTemplate}/custom-fields`);
const artificialIntelligence = template.parse(`${projectTemplate}/artificial-intelligence`);

const welcomeDownloadKs = template.parse('/welcome/download-ks');
const way_to_start = template.parse(`${projectTemplate}/way-to-start`);

const git_test_project_setup = template.parse(`${projectTemplate}/git-test-project/setup`);

const user_journeys = template.parse(`${autonomousTemplate}/user-journeys`);

const truetest_user_journeys = template.parse(`${truetestTemplate}/user-journeys`);

export const goByLink = (link, urlObject, navigateAsSPA = false) => {
  let finalLink = link;
  if (urlObject) {
    const queryString = `?${jquery.param(urlObject, true)}`;
    finalLink += queryString;
  }
  if (navigateAsSPA) {
    DomEventHandlers.createEvent(
      KATALON_EVENTS.navigateAsSinglePageApplication,
      { detail: { url: finalLink } }
    );
  } else {
    window.location.href = finalLink;
  }
};

class Routes {
  constructor(params) {
    this.params = params;
  }

  static goToGridLink() {
    const route = new Routes();
    goByLink(route.grid_link);
  }

  static goToGitTestProjectSetup(urlParams) {
    const route = new Routes();
    goByLink(route.git_test_project_setup, urlParams);
  }

  static goToK8SAgentsLink() {
    const route = new Routes();
    goByLink(route.k8s_agents_link);
  }

  static goToCircleCIAgentsLink() {
    const route = new Routes();
    goByLink(route.circle_ci_agents_link);
  }

  static goToOrganizationCircleCIAgentsLink() {
    const route = new Routes();
    goByLink(route.organization_circle_ci_agents_link);
  }

  static goToOrganizationK8sAgentsLink() {
    const route = new Routes();
    goByLink(route.organization_k8s_agents_link);
  }

  static goToEditBuildPage(teamId, projectId, buildId) {
    const route = new Routes({
      teamId,
      projectId,
      buildId,
    });
    goByLink(route.build_edit_link);
  }

  static gotoRunConfigurationId(runConfigurationId) {
    const routes = new Routes({ runConfigurationId });
    goByLink(routes.run_configuration_link);
  }

  static goToReleaseLink() {
    const route = new Routes();
    goByLink(route.releases_link);
  }

  static goToViewReleaseLink(releaseId) {
    const route = new Routes({ releaseId });
    goByLink(route.view_release_link);
  }

  static goToTestCaseLink(testCaseId) {
    const route = new Routes({ testCaseId });
    goByLink(route.execution_test_result_history_link);
  }

  static goToTestCaseEditLink(testCaseId) {
    const route = new Routes({ testCaseId });
    goByLink(route.edit_test_case_link, null, true);
  }

  static goToTestCasesLink() {
    const route = new Routes();
    goByLink(route.test_cases_link);
  }

  static goToTestSuitesLink() {
    const route = new Routes();
    goByLink(route.test_suites_link);
  }

  /**
   * @deprecated
   * Please use goToTestSuitesLink if you want to go to test suites page
   */
  static goToTestSuitesDetailsLink() {
    const route = new Routes();
    goByLink(route.execution_test_suite_history_link);
  }

  static goToTestSuiteEditLink(testSuiteId) {
    const route = new Routes({ testSuiteId });
    goByLink(route.edit_test_suite_link, null, true);
  }

  /**
   * @deprecated due to TOS-1891
   * Remove incidentEnabled flag
   * This action is to redirect to tasks page, but it is not used in TO anymore
   */
  static goToIncidentsLink() {
    const route = new Routes();
    goByLink(route.incidents_link);
  }

  /**
   * @deprecated due to TOS-1891
   * Remove incidentEnabled flag
   * This action is to redirect to task detail, but it is not used in TO anymore
   */
  static goToIncidentLink(incidentOrder) {
    const route = new Routes({
      incidentOrder,
    });
    goByLink(route.incident_link);
  }

  static goToExternalLink(link) {
    goByLink(link);
  }

  static goToHomePage() {
    goByLink('/');
  }

  static goToHomePageWithMessage(message) {
    goByLink(`/home?message=${message}`);
  }

  static goToDashboardPage(teamId) {
    const route = new Routes({
      teamId,
    });
    goByLink(route.teams_dashboard_link);
  }

  static goToProjectPage(teamId, projectId) {
    const route = new Routes({
      teamId,
      projectId,
    });
    goByLink(route.project_link);
  }

  static gotoCodeRepoLink() {
    const route = new Routes();
    goByLink(route.test_projects_link);
  }

  static goToTestProjectPage(teamId, projectId, testProjectId) {
    const route = new Routes({
      teamId,
      projectId,
      testProjectId,
    });
    goByLink(route.test_project_link);
  }

  static goToJiraSetting() {
    const route = new Routes();
    goByLink(route.jira_setting_link);
  }

  static goToTestProjectsPage(teamId, projectId) {
    const route = new Routes({
      teamId,
      projectId,
    });
    goByLink(route.test_projects_link);
  }

  static goToTestEnvironmentsPage(teamId, projectId) {
    const route = new Routes({
      teamId,
      projectId,
    });
    goByLink(route.agent_link);
  }

  static goToExecutionsPage(teamId, projectId) {
    const route = new Routes({
      teamId,
      projectId,
    });
    goByLink(route.executions_link);
  }

  static goToAdminProjectsPage(teamId) {
    const route = new Routes({ teamId });
    goByLink(route.admin_projects_link);
  }

  static goToNotificationPage(notificationId) {
    const route = new Routes({ notificationId });
    goByLink(route.notification_link);
  }

  static goToNoProjectsPage() {
    Routes.goToNotificationPage(notificationIds.NO_PROJECTS);
  }

  static goToAccessDeniedPage() {
    Routes.goToNotificationPage(notificationIds.NO_PERMISSION);
  }

  static goToTestPlanLink() {
    const route = new Routes();
    goByLink(route.test_plan_link);
  }

  static goToProfile() {
    const route = new Routes();
    goByLink(route.user_profile_link);
  }

  static goToOrganizationHomePage(organizationId, urlParams, navigateAsSPA) {
    const route = new Routes({ organizationId });
    goByLink(route.organization_home_link, urlParams, navigateAsSPA);
  }

  static goToVisualTestingPage(teamId, projectId) {
    const route = new Routes({ teamId, projectId });
    goByLink(route.keyes_link);
  }

  static goToLicenseAdminHomePage(organizationId) {
    const route = new Routes({ organizationId });
    goByLink(route.license_admin_home_link);
  }

  static goToOrganizationPage(organizationId) {
    const route = new Routes({ organizationId });
    goByLink(route.organization_link);
  }

  static goToOrganizationSettingsPage(organizationId) {
    const route = new Routes({ organizationId });
    goByLink(route.organization_settings_link);
  }

  static goToOfflineKSELicensesPage() {
    const route = new Routes();
    goByLink(route.organization_offline_kse_licenses_link);
  }

  static goToKSELicensesPage() {
    const route = new Routes();
    goByLink(route.organization_kse_licenses_link);
  }

  static goToOfflineEngineLicensesPage() {
    const route = new Routes();
    goByLink(route.organization_offline_engine_licenses_link);
  }

  static goToEngineLicensesPage() {
    const route = new Routes();
    goByLink(route.organization_engine_licenses_link);
  }

  static gotoTestOpsLicensesPage() {
    const route = new Routes();
    goByLink(route.organization_testops_license_link);
  }

  static gotoPaymentSuccessPage(object) {
    const route = new Routes();
    goByLink(route.organization_payment_success_link(object));
  }

  static gotoRunConfigurationSummary(runConfigurationId) {
    const route = new Routes({
      runConfigurationId,
    });
    goByLink(route.run_configuration_summary);
  }

  static goToStartUpPage() {
    goByLink('/startup');
  }

  static goToSubscriptionUpgradePage(organizationId, subscriptionId) {
    const route = new Routes({
      organizationId,
      subscriptionId,
    });
    goByLink(route.organization_subscription_upgrade);
  }

  static goToOrganizationSubscription(organizationId) {
    const route = new Routes({
      organizationId,
    });
    goByLink(route.organization_subscription);
  }

  static goToOrganizationSubscriptionNewOrders(organizationId, query) {
    const route = new Routes({
      organizationId,
    });
    goByLink(route.organization_subscription_new_orders(query));
  }

  static goToOrganizationQuoteNewOrders(organizationId, query) {
    const routes = new Routes({
      organizationId,
    });
    goByLink(routes.organization_quote_new_orders(query));
  }

  static gotoUserLicensesPage() {
    const route = new Routes();
    goByLink(route.user_license_link);
  }

  static goToCreateSchedulerPage(runConfigurationId) {
    const routes = new Routes({ runConfigurationId });
    goByLink(routes.create_scheduler_link);
  }

  static gotoCompareExecutionPage(executionOrders) {
    const route = new Routes();
    goByLink(route.executions_compare_link({ id: executionOrders }));
  }

  static goToOrganizationUsersPage(urlObject) {
    const route = new Routes();
    goByLink(route.organization_users_link, urlObject);
  }

  static goToCreateOrganization(urlObject) {
    const route = new Routes();
    goByLink(route.create_organization, urlObject);
  }

  static goToBaselineCollection() {
    const route = new Routes();
    goByLink(route.baseline_collection_link);
  }

  static goToBaselineCollectionGroupDetail(baselineCollectionGroupOrder) {
    const route = new Routes({ baselineCollectionGroupOrder });
    goByLink(route.baseline_collection_group_detail_link);
  }

  static goToBaselineCollectionGroupHistory() {
    const route = new Routes();
    goByLink(route.baseline_collection_group_history_link());
  }

  static goToKeyesExecution() {
    const route = new Routes();
    goByLink(route.keyes_execution_details_link);
  }

  static goToTrialRequest(organizationId) {
    const route = new Routes({
      organizationId,
    });
    goByLink(route.organization_trial_request);
  }

  static goToTestOpsCheckoutPage(order) {
    const route = new Routes();
    goByLink(route.organization_testops_subscription_checkout, order);
  }

  static goToTestOpsSubscriptionCheckoutPage(organizationId) {
    const route = new Routes({
      organizationId,
    });
    goByLink(route.organization_testops_subscription_checkout);
  }

  static goToTestOpsSubscriptionPage(organizationId) {
    const route = new Routes({
      organizationId,
    });
    goByLink(route.organization_testops_subscription);
  }

  static goToTestOpsUserSettings() {
    const route = new Routes();
    goByLink(route.user_settings_link);
  }

  static goToCommentForTestResultPage(executionId, executionTestResultId) {
    const route = new Routes({
      executionId,
      executionTestResultId,
    });
    goByLink(route.execution_test_result_comment_link);
  }

  static goToTestCaseSearchPage(searchParams) {
    const route = new Routes();
    goByLink(route.test_cases_search_link(searchParams));
  }

  static goToTestSuiteSearchPage(searchParams) {
    const route = new Routes();
    goByLink(route.test_suites_search_link(searchParams));
  }

  static goToWelcomeDownloadKsPage() {
    const route = new Routes();
    goByLink(route.welcomeDownloadKs + window.location.search);
  }

  static goToWayToStartPage(teamId, projectId) {
    const route = new Routes({
      teamId,
      projectId,
    });
    goByLink(route.way_to_start_link);
  }

  static goToGen5EditorPage(testCaseId) {
    const route = new Routes({ testCaseId });
    const query = { action: 'record' };
    goByLink(route.edit_test_case_link, query);
  }

  static goToPlannedTestRunPage(testRunId) {
    const route = new Routes();
    const query = { testRunId };
    goByLink(route.test_planning_link, query);
  }

  toUrl(urlTemplate) {
    const mergedParams = _.merge({}, MContext, this.params);
    return urlTemplate.expand(mergedParams);
  }

  get welcomeDownloadKs() {
    return this.toUrl(welcomeDownloadKs);
  }

  get create_organization() {
    return createOrganization;
  }

  get startup_create_account_link() {
    return '/startup/create-account';
  }

  get startup_activate_link() {
    return '/startup/activate';
  }

  get team_link() {
    return this.toUrl(team);
  }

  get project_link() {
    return this.toUrl(project);
  }

  get jobs_link() {
    return this.toUrl(jobs);
  }

  get project_settings_link() {
    return this.toUrl(project_settings);
  }

  get executions_link() {
    return this.toUrl(executions);
  }

  get overview_link() {
    return this.toUrl(overview);
  }

  get keyes_link() {
    return this.toUrl(keyes);
  }

  get keyes_execution_details_link() {
    return this.toUrl(keyes_execution_details_link);
  }

  get keyes_execution_detail_comments_link() {
    return this.toUrl(keyes_execution_detail_comment_link);
  }

  get baseline_collection_link() {
    return this.toUrl(baseline_collection);
  }

  get baseline_collection_current_link() {
    return this.toUrl(baseline_collection_current);
  }

  get baseline_collection_history_link() {
    return this.toUrl(baseline_collection_history);
  }

  get baseline_collection_group_detail_link() {
    return this.toUrl(baseline_collection_group_detail);
  }

  baseline_collection_group_history_link(urlObject = {}) {
    const queryString = !_.isEmpty(urlObject)
      ? `?${jquery.param(urlObject, true)}`
      : '';
    return this.toUrl(baseline_collection_group_history) + queryString;
  }

  executions_compare_link(urlObject) {
    const queryString = !_.isEmpty(urlObject)
      ? `?${jquery.param(urlObject, true)}`
      : '';
    return this.toUrl(executions_compare) + queryString;
  }

  /**
   * @deprecated due to TOS-1891
   * Remove incidentEnabled flag
   * This action is to redirect to tasks page, but it is not used in TO anymore
   */
  get incidents_link() {
    return this.toUrl(incidents);
  }

  /**
   * @deprecated due to TOS-1891
   * Remove incidentEnabled flag
   * This action is to redirect to create task page, but it is not used in TO anymore
   */
  create_incident_link(urlObject) {
    let queryString;
    if (urlObject) {
      queryString = `?${jquery.param(urlObject, true)}`;
    } else {
      queryString = '';
    }
    return this.toUrl(incident_create) + queryString;
  }

  get import_manual_reports_link() {
    return this.toUrl(import_manual_reports);
  }

  get organization_home_link() {
    return this.toUrl(organizationHome);
  }

  get organization_create_link() {
    return this.toUrl(organization_create);
  }

  /**
   * @deprecated due to TOS-1891
   * Remove incidentEnabled flag
   * This action is to redirect to edit task page, but it is not used in TO anymore
   */
  get edit_incident_link() {
    return this.toUrl(incident_edit);
  }

  /**
   * @deprecated due to TOS-1891
   * Remove incidentEnabled flag
   * This action is to redirect to task detail page, but it is not used in TO anymore
   */
  get incident_link() {
    return this.toUrl(incident);
  }

  get releases_link() {
    return this.toUrl(releases);
  }

  get edit_release_link() {
    return this.toUrl(release_edit);
  }

  get test_case_overview_link() {
    return this.toUrl(test_case_overview);
  }

  get test_case_steps_link() {
    return this.toUrl(test_case_steps);
  }

  get test_case_local_variables_link() {
    return this.toUrl(test_case_local_variables);
  }

  get edit_test_case_link() {
    return this.toUrl(test_case_edit);
  }

  get edit_test_suite_link() {
    return this.toUrl(test_suite_edit);
  }

  get view_release_link() {
    return this.toUrl(release_view);
  }

  get release_executions_details_link() {
    return this.toUrl(release_executions_details);
  }

  get release_run_configuration_details_link() {
    return this.toUrl(release_run_configuration_details);
  }

  get way_to_start_link() {
    return this.toUrl(way_to_start);
  }

  create_release_link(urlObject) {
    let queryString;
    if (urlObject) {
      queryString = `?${jquery.param(urlObject, true)}`;
    } else {
      queryString = '';
    }
    return this.toUrl(release_create) + queryString;
  }

  test_cases_search_link(urlObject) {
    const queryString = !_.isEmpty(urlObject)
      ? `?${jquery.param(urlObject, true)}`
      : '';
    return this.toUrl(test_cases_search) + queryString;
  }

  create_build_link(urlObject) {
    let queryString;
    if (urlObject) {
      queryString = `?${jquery.param(urlObject, true)}`;
    } else {
      queryString = '';
    }
    return this.toUrl(build_create) + queryString;
  }

  test_suites_search_link(urlObject) {
    const queryString = !_.isEmpty(urlObject)
      ? `?${jquery.param(urlObject, true)}`
      : '';
    return this.toUrl(test_suite_search) + queryString;
  }

  get test_design_link() {
    return this.toUrl(test_design);
  }

  get test_cases_link() {
    return this.toUrl(test_cases);
  }

  get test_cases_active_link() {
    return this.toUrl(test_cases_active);
  }

  get test_cases_stale_link() {
    return this.toUrl(test_cases_stale);
  }

  get test_cases_slowest_link() {
    return this.toUrl(test_cases_slowest);
  }

  get agent_link() {
    return this.toUrl(agent);
  }

  get organization_setting_agent_link() {
    return this.toUrl(organizationSettingAgent);
  }

  get organization_setting_k8s_agent_link() {
    return this.toUrl(organizationSettingK8sAgent);
  }

  get organization_setting_circle_ci_agent_link() {
    return this.toUrl(organizationSettingCircleCiAgent);
  }

  get organization_setting_testcloud_agent_link() {
    return this.toUrl(organizationSettingTestCloudAgent);
  }

  get organization_setting_test_env_installation_link() {
    return this.toUrl(organizationSettingTestEnvInstallation);
  }

  get organization_setting_true_test_link() {
    return this.toUrl(organizationSettingTrueTest);
  }

  get organization_setting_test_env_docker_agent_installation_link() {
    return this.toUrl(organizationSettingTestEnvDockerAgentInstallation);
  }

  get test_cloud_agent_link() {
    return this.toUrl(testCloudAgent);
  }

  get test_cloud_agents_link() {
    return this.toUrl(testCloudAgents);
  }

  get test_cloud_tunnel_link() {
    return this.toUrl(testCloudTunnel);
  }

  get agent_details_link() {
    return this.toUrl(agentDetails);
  }

  get test_env_installation_link() {
    return this.toUrl(testEnvInstallation);
  }

  get k8s_agent_create_link() {
    return this.toUrl(k8sAgentCreate);
  }

  get k8s_agent_link() {
    return this.toUrl(k8sAgent);
  }

  get k8s_agents_link() {
    return this.toUrl(k8sAgents);
  }

  get circle_ci_agent_create_link() {
    return this.toUrl(circleCIAgentCreate);
  }

  get organization_circle_ci_agent_create_link() {
    return this.toUrl(organizationSettingCircleCIAgentCreate);
  }

  get organization_k8s_agent_create_link() {
    return this.toUrl(organizationSettingK8sAgentCreate);
  }

  get organization_k8s_agent_link() {
    return this.toUrl(organizationK8sAgent);
  }

  get organization_local_agent_link() {
    return this.toUrl(organizationLocalAgent);
  }

  get circle_ci_agent_link() {
    return this.toUrl(circleCIAgent);
  }

  get organization_circle_ci_agent_link() {
    return this.toUrl(organizationCircleCIAgent);
  }

  get circle_ci_agents_link() {
    return this.toUrl(circleCIAgents);
  }

  get organization_circle_ci_agents_link() {
    return this.toUrl(organizationSettingCircleCIAgents);
  }

  get organization_k8s_agents_link() {
    return this.toUrl(organizationSettingK8sAgents);
  }

  get test_reports_link() {
    return this.toUrl(test_reports);
  }

  // get test_report_detail_link() {
  //   return this.toUrl(test_report_detail);
  // }

  get test_report_test_design() {
    return this.toUrl(test_report_test_design);
  }

  get test_report_requirement_test_run_coverage() {
    return this.toUrl(test_report_requirement_test_run);
  }

  get test_report_traceability_report() {
    return this.toUrl(test_report_traceability);
  }

  get test_report_test_execution() {
    return this.toUrl(test_report_test_execution);
  }

  get test_report_execution_report() {
    return this.toUrl(test_report_execution);
  }

  get test_report_test_performance() {
    return this.toUrl(test_report_test_performance);
  }

  get test_report_test_maintenance() {
    return this.toUrl(test_report_test_maintaince);
  }

  get test_report_frequency_report() {
    return this.toUrl(test_report_frequency);
  }

  get failed_test_result() {
    return this.toUrl(failed_test_result);
  }

  get test_report_flakiness_report() {
    return this.toUrl(test_report_flakiness);
  }

  get test_report_platform_statistics() {
    return this.toUrl(test_report_platform_statistics);
  }

  get web_services_link() {
    return this.toUrl(web_services);
  }

  get web_services_anomalies_link() {
    return this.toUrl(web_services_anomalies);
  }

  get test_projects_link() {
    return this.toUrl(test_projects);
  }

  get ci_dashboard_link() {
    return this.toUrl(ci_dashboard);
  }

  get grid_link() {
    return this.toUrl(grid);
  }

  get priorities_link() {
    return this.toUrl(priorities);
  }

  get test_plans_link() {
    return this.toUrl(test_plans);
  }

  get test_suites_link() {
    return this.toUrl(test_suites);
  }

  get frameworks_integration_link() {
    return this.toUrl(frameworks_integration);
  }

  get build_edit_link() {
    return this.toUrl(build_edit);
  }

  get build_details_link() {
    return this.toUrl(build_view);
  }

  get integrations_link() {
    return this.toUrl(integrations);
  }

  get upload_katalon_reports_link() {
    return this.toUrl(upload_katalon_reports);
  }

  get report_uploader_link() {
    return this.toUrl(report_uploader);
  }

  get quick_guide_link() {
    return this.toUrl(quick_guide);
  }

  get execution_details_link() {
    return this.toUrl(execution);
  }

  get defects_link() {
    return this.toUrl(defects);
  }

  get requirements_link() {
    return this.toUrl(requirements);
  }

  get requirement_details_link() {
    return this.toUrl(requirement_details);
  }

  get execution_test_run_details_link() {
    return this.toUrl(execution_test_run_details);
  }

  get execution_test_object_details_link() {
    return this.toUrl(execution_test_object_details);
  }

  get execution_analysis_details_link() {
    return this.toUrl(execution_analysis_details);
  }

  get execution_test_result_context_variables_link() {
    return this.toUrl(execution_test_result_context_variables);
  }

  get execution_test_suite_context_variables_link() {
    return this.toUrl(execution_test_suite_context_variables);
  }

  get execution_test_suite_comment_link() {
    return this.toUrl(execution_test_suite_comment_link);
  }

  get execution_test_result_detail_link() {
    return this.toUrl(test_result);
  }

  get execution_test_result_request_link() {
    return this.toUrl(execution_test_result_request_link);
  }

  get execution_test_result_test_objects_link() {
    return this.toUrl(execution_test_result_test_objects_link);
  }

  get execution_test_result_similar_failures_link() {
    return this.toUrl(execution_test_result_similar_failures_link);
  }

  get execution_test_result_treeviewer_link() {
    return this.toUrl(execution_test_result_treeviewer_link);
  }

  get execution_test_result_comment_link() {
    return this.toUrl(execution_test_result_comment_link);
  }

  get execution_test_result_rerun_link() {
    return this.toUrl(execution_test_result_rerun_link);
  }

  get execution_test_result_history_link() {
    return this.toUrl(execution_test_result_history);
  }

  get execution_test_object_history_link() {
    return this.toUrl(execution_test_object_history);
  }

  get test_object_entities_link() {
    return this.toUrl(test_object_entities);
  }

  get test_object_entity_details_link() {
    return this.toUrl(test_object_entity_details);
  }

  get test_project_link() {
    return this.toUrl(test_project);
  }

  get test_project_create_link() {
    return this.toUrl(test_project_create);
  }

  get test_project_create_git_link() {
    return this.toUrl(test_project_create_git);
  }

  get test_project_edit_link() {
    return this.toUrl(test_project_edit);
  }

  get test_project_edit_git_link() {
    return this.toUrl(test_project_edit_git);
  }

  get sample_plan_link() {
    return this.toUrl(sample_plan);
  }

  // New flow with smart test scheduling, toUrl(test_planning) and open modal with old params
  smart_test_scheduling_link(urlObject) {
    let queryString = '?isOpenScheduleTestRunDialog=true';
    if (urlObject) {
      queryString += `&${jquery.param(urlObject, true)}`;
    }
    return this.toUrl(test_planning) + queryString;
  }

  goToTestPlanningPage(urlObject) {
    const queryString = !_.isEmpty(urlObject) ? `?${jquery.param(urlObject, true)}` : '';
    return this.toUrl(test_planning) + queryString;
  }

  test_suites_page_link() {
    return this.toUrl(test_suites);
  }

  get configure_plan_link() {
    return this.toUrl(configure_plan);
  }

  get create_scheduler_link() {
    return this.toUrl(scheduler_create);
  }

  get edit_scheduler_link() {
    return this.toUrl(scheduler_edit);
  }

  get run_configuration_link() {
    return this.toUrl(run_configuration);
  }

  get run_configuration_session() {
    return this.toUrl(run_configuration_session);
  }

  get run_configuration_summary() {
    return this.toUrl(run_configuration_summary);
  }

  get job_link() {
    return this.toUrl(job);
  }

  get test_planning_link() {
    return this.toUrl(test_planning);
  }

  get native_apps_link() {
    return this.toUrl(native_apps);
  }

  get test_runs_link() {
    return this.toUrl(test_runs);
  }

  get test_plan_link() {
    return this.toUrl(test_plans);
  }

  get execution_test_suite_detail_link() {
    return this.toUrl(test_suite);
  }

  get execution_test_suite_test_run_link() {
    return this.toUrl(test_suite_test_runs);
  }

  get execution_test_suite_history_link() {
    return this.toUrl(execution_test_suite_history);
  }

  get apikey_link() {
    return this.toUrl(apikey);
  }

  get user_profile_link() {
    return this.toUrl(user_profile);
  }

  get user_settings_link() {
    return this.toUrl(user_settings);
  }

  get user_license_link() {
    return this.toUrl(user_license);
  }

  get user_create_kse_license_link() {
    return this.toUrl(user_license_create_kse);
  }

  get user_create_engine_license_link() {
    return this.toUrl(user_license_create_engine);
  }

  user_change_password_link() {
    return this.toUrl(user_change_password);
  }

  get user_assign_subscription_link() {
    return this.toUrl(assign_subscription);
  }

  get user_katalon_recorder_backup() {
    return this.toUrl(katalon_recorder_backup);
  }

  get user_integrations() {
    return this.toUrl(user_integrations);
  }

  get admin_teams_link() {
    return this.toUrl(admin_teams);
  }

  get admin_users_link() {
    return this.toUrl(admin_users);
  }

  get admin_team_settings_link() {
    return this.toUrl(admin_team_settings);
  }

  get admin_users_invite_link() {
    return this.toUrl(admin_users_invite);
  }

  get admin_projects_link() {
    return this.toUrl(admin_projects);
  }

  get admin_organization_link() {
    return this.toUrl(katalon_manage);
  }

  get organization_link() {
    return this.toUrl(organizationHome);
  }

  get organization_settings_link() {
    return this.toUrl(organization_settings);
  }

  get organization_trial_request() {
    return this.toUrl(organization_trial_request);
  }

  get organization_users_link() {
    return this.toUrl(organization_users);
  }

  get organization_manage_projects_link() {
    return this.toUrl(organization_manage_projects);
  }

  get organization_manage_archived_projects_link() {
    return this.toUrl(organization_manage_archived_projects);
  }

  get organization_licenses_link() {
    return this.toUrl(organization_licenses);
  }

  get organization_offline_kse_licenses_link() {
    return this.toUrl(organization_offline_kse_licenses);
  }

  get organization_kse_licenses_link() {
    return this.toUrl(organization_kse_licenses);
  }

  get organization_unlimited_kse_licenses_link() {
    return this.toUrl(organization_unlimited_kse_licenses);
  }

  get organization_testops_link() {
    return this.toUrl(organization_testops);
  }

  get organization_testops_license_link() {
    return this.toUrl(organization_testops_licenses);
  }

  get organization_offline_engine_licenses_link() {
    return this.toUrl(organization_offline_engine_licenses);
  }

  get organization_engine_licenses_link() {
    return this.toUrl(organization_engine_licenses);
  }

  get organization_unlimited_engine_licenses_link() {
    return this.toUrl(organization_unlimited_engine_licenses);
  }

  get organization_floating_engine_licenses_link() {
    return this.toUrl(organization_floating_engine_licenses);
  }

  get organization_test_cloud_link() {
    return this.toUrl(organization_test_cloud_licenses);
  }

  get organization_users_invite_link() {
    return this.toUrl(organization_users_invite);
  }

  get organization_users_manage_link() {
    return this.toUrl(organization_users_manage);
  }

  get organization_teams_link() {
    return this.toUrl(organization_teams);
  }

  get organization_projects_link() {
    return this.toUrl(organization_projects);
  }

  get organization_license_keys_link() {
    return this.toUrl(organization_license_keys);
  }

  get organization_kse_timeout_setting_link() {
    return this.toUrl(organization_kse_timeout);
  }

  get organization_create_kse_license_keys_link() {
    return this.toUrl(organization_kse_licenses_create);
  }

  get organization_create_engine_license_keys_link() {
    return this.toUrl(organization_engine_licenses_create);
  }

  get organization_create_testops_license_keys_link() {
    return this.toUrl(organization_testops_licenses_create);
  }

  get organization_plugins_link() {
    return this.toUrl(organization_plugins);
  }

  get organization_configuration_link() {
    return this.toUrl(organization_configuration);
  }

  get organization_subscription() {
    return this.toUrl(organization_subscription);
  }

  get organization_subscription_upgrade() {
    return this.toUrl(organization_subscription_upgrade);
  }

  get organization_payment_method() {
    return this.toUrl(organization_payment_method);
  }

  organization_subscription_new_orders(urlObject) {
    const queryString = !_.isEmpty(urlObject)
      ? `?${jquery.param(urlObject, true)}`
      : '';
    return this.toUrl(organization_subscription_new_orders) + queryString;
  }

  get organization_subscription_edit() {
    return this.toUrl(organization_subscription_edit);
  }

  organization_quote_new_orders(urlObject) {
    const queryString = !_.isEmpty(urlObject)
      ? `?${jquery.param(urlObject, true)}`
      : '';
    return this.toUrl(organization_quote_new_orders) + queryString;
  }

  get organization_quote_upgrade() {
    return this.toUrl(organization_quote_upgrade);
  }

  organization_payment_success_link(urlObject) {
    let queryString;
    if (urlObject) {
      queryString = `?${jquery.param(urlObject, true)}`;
    } else {
      queryString = '';
    }
    return this.toUrl(organization_payment_success) + queryString;
  }

  get organization_session_link() {
    return this.toUrl(organization_session);
  }

  get organization_testops_subscription() {
    return this.toUrl(organization_testops_subscription);
  }

  get organization_testops_subscription_checkout() {
    return this.toUrl(organization_testops_subscription_checkout);
  }

  get organization_testops_subscription_quote() {
    return this.toUrl(organization_testops_subscription_quote);
  }

  get notification_link() {
    return this.toUrl(notification);
  }

  get teams_dashboard_link() {
    return this.toUrl(team);
  }

  get project_dashboard_link() {
    return this.toUrl(project);
  }

  get execution_request_link() {
    return this.toUrl(execution_request_detail);
  }

  get execution_file_details() {
    return this.toUrl(execution_file_details);
  }

  get execution_defect_link() {
    return this.toUrl(execution_defect);
  }

  get jira_setting_link() {
    return this.toUrl(jira_setting);
  }

  get kobiton_setting_link() {
    return this.toUrl(kobiton_setting);
  }

  get slack_setting_link() {
    return this.toUrl(slack_setting);
  }

  get project_analysis_settings_link() {
    return this.toUrl(analysis_settings);
  }

  get organization_offline_unlimited_kse_licenses_link() {
    return this.toUrl(organization_offline_unlimited_kse_licenses);
  }

  get organization_create_unlimited_kse_license_keys_link() {
    return this.toUrl(organization_unlimited_kse_licenses_create);
  }

  get organization_offline_unlimited_engine_licenses_link() {
    return this.toUrl(organization_offline_unlimited_engine_licenses);
  }

  get organization_create_unlimited_engine_license_keys_link() {
    return this.toUrl(organization_unlimited_engine_licenses_create);
  }

  get custom_fields() {
    return this.toUrl(customFields);
  }

  get artificial_intelligence_link() {
    return this.toUrl(artificialIntelligence);
  }

  get git_test_project_setup() {
    return this.toUrl(git_test_project_setup);
  }

  get user_journeys_link() {
    return this.toUrl(user_journeys);
  }

  get truetest_user_journeys_link() {
    return this.toUrl(truetest_user_journeys);
  }

  get routeInfo() {
    let routeType;
    const routeArray = [];
    let iconType;
    let title;

    const { team, project, organization } = MContext;

    if (organization) {
      routeArray.push({
        name: organization.name,
        link: this.organization_link,
      });
    }

    if (team) {
      routeType = t('teams');
      iconType = <IconDashboard />;

      const { organization } = team;
      if (project === null && organization !== undefined) {
        this.params.organizationId = organization.id;
        routeArray.push({
          name: organization.name,
          link: this.organization_link,
        });
      }

      routeArray.push({
        name: team.name,
        link: this.team_link,
      });
    }

    if (project) {
      routeType = t('projects');
      iconType = <IconTestProject />;
      const projectObj = {
        name: project.name,
        link: this.project_link,
      };
      routeArray.push(projectObj);
    }

    if (this.params.organizationHome) {
      routeType = this.params.organizationHome;
      // iconType = <IconOrganization />;
    }

    if (this.params.organization) {
      routeType = this.params.organization;
      iconType = <IconOrganization />;
    }

    if (this.params.organization_settings) {
      routeType = this.params.organization_settings;
      iconType = <IconSettings />;
    }

    if (this.params.organization_trial_request) {
      routeType = this.params.organization_trial_request;
      iconType = <IconTrialRequestLight />;
    }

    if (this.params.users) {
      routeType = t('manage_users');
      iconType = <IconUser />;
    }

    if (this.params.invite_user) {
      routeType = t('invite_user');
      iconType = <IconUser />;
    }

    if (this.params.manage_organization_user) {
      routeType = `${this.params.manage_organization_user}'s detail`;
      iconType = this.params.icon || <IconUser />;
    }

    if (this.params.license_keys) {
      routeType = this.params.license_keys;
      iconType = <IconLicense />;
    }

    if (this.params.kse_timeout_setting) {
      routeType = this.params.kse_timeout_setting;
      iconType = <IconLicense />;
    }

    if (this.params.new_user_license) {
      routeType = this.params.new_user_license;
      routeArray.push({
        name: this.params.license_keys,
        link: this.user_license_link,
      });
    }

    if (this.params.new_kse_license) {
      routeType = this.params.new_kse_license;
      routeArray.push({
        name: this.params.license_keys,
        link: this.organization_kse_licenses_link,
      });
    }

    if (this.params.new_unlimited_kse_license) {
      routeType = this.params.new_unlimited_kse_license;
      routeArray.push({
        name: this.params.license_keys,
        link: this.organization_unlimited_kse_licenses_link,
      });
    }

    if (this.params.new_engine_license) {
      routeType = this.params.new_engine_license;
      routeArray.push({
        name: this.params.license_keys,
        link: this.organization_engine_licenses_link,
      });
    }

    if (this.params.new_unlimited_engine_license) {
      routeType = this.params.new_unlimited_engine_license;
      routeArray.push({
        name: this.params.license_keys,
        link: this.organization_unlimited_engine_licenses_link,
      });
    }

    if (this.params.new_testops_license) {
      routeType = this.params.new_testops_license;
      routeArray.push({
        name: this.params.license_keys,
        link: this.organization_testops_license_link,
      });
    }

    if (this.params.plugins) {
      routeType = this.params.plugins;
      iconType = <IconPlugins />;
    }

    if (this.params.subscription) {
      routeType = this.params.subscription;
      iconType = <IconPricingLight />;
    }

    if (this.params.edit_subscription) {
      routeType = this.params.edit_subscription;
      iconType = <IconPricingLight />;
      routeArray.push({
        name: t('subscriptions'),
        link: this.organization_subscription,
      });
    }

    if (this.params.payment) {
      routeType = this.params.payment;
      iconType = <IconPaymentMethod />;
    }

    if (this.params.testops) {
      routeType = this.params.testops;
      iconType = <IconLicense />;
    }

    if (this.params.user_license) {
      routeType = this.params.user_license;
      iconType = <IconLicense />;
    }

    if (this.params.organizationSession) {
      routeType = this.params.organizationSession;
      iconType = <IconLicensesUtilization />;
    }

    if (this.params.uploadReport) {
      routeType = this.params.uploadReport;
      iconType = <IconFrameworksIntegration />;
    }

    if (this.params.uploadKSReport) {
      routeType = this.params.uploadKSReport;
      iconType = <IconKS />;
    }

    if (this.params.integrations) {
      routeType = this.params.integrations;
    }

    if (this.params.testCase) {
      routeType = this.params.testCase;
      iconType = <IconTestCase />;
    }

    if (this.params.testDesign) {
      routeType = this.params.testDesign;
      iconType = <IconTestDesign />;
    }

    if (this.params.testObjectEntities) {
      routeType = this.params.testObjectEntities;
      iconType = <IconTestObject />;
    }

    if (this.params.testObjectEntity) {
      routeType = `Test Object: ${this.params.testObjectEntity}`;
      iconType = <IconTestObject />;
      routeArray.push({
        name: t('test_object_entities'),
        link: this.test_object_entities_link,
      });
    }

    if (this.params.testReports) {
      routeType = this.params.testReports;
      iconType = <IconReports />;
    }

    if (this.params.testObject) {
      routeType = this.params.testObject;
      iconType = <IconWebService />;
    }

    if (this.params.testSuite) {
      routeType = this.params.testSuite;
      iconType = <IconTestSuite />;
    }

    if (this.params.executions) {
      routeType = this.params.executions;
      iconType = <IconExecution />;
    }

    if (this.params.importManualReports) {
      routeType = this.params.importManualReports;
      iconType = <IconExecution />;
    }

    if (this.params.baselineInformation) {
      routeType = this.params.baselineInformation;
      iconType = <IconBaselineCollection />;
      routeArray.push({
        name: t('baseline_collection'),
        link: this.baseline_collection_link,
      });
    }

    if (this.params.baselineCollection) {
      routeType = this.params.baselineCollection;
      iconType = <IconBaselineCollection />;
    }

    if (this.params.keyes) {
      routeType = this.params.keyes;
      iconType = <IconKEyes />;
    }

    if (this.params.keyesExecutionId) {
      title = `Test Run: #${this.params.keyesExecutionId}`;
      routeType = (
        <div className="d-inline-flex align-items-center">
          {this.params.status}
          <span>{title}</span>
          {this.params.unsaved}
        </div>
      );
      iconType = <IconExecution />;
      routeArray.push({
        name: t('keyes-executions'),
        link: this.keyes_link,
      });
    }

    if (this.params.visualTestRuns) {
      routeType = this.params.visualTestRuns;
      title = this.params.title;
    }

    if (this.params.visualBaselineCollections) {
      routeType = this.params.visualBaselineCollections;
      title = this.params.title;
    }

    if (this.params.requirements) {
      routeType = this.params.requirements;
      iconType = <IconRequirement />;
    }

    if (this.params.requirementDetails) {
      routeType = this.params.requirementDetails;
      iconType = <IconRequirement />;
    }

    if (this.params.defects) {
      routeType = this.params.defects;
      iconType = <IconDefect />;
    }

    if (this.params.jobs) {
      routeType = this.params.jobs;
      iconType = <IconTasks />;
    }

    if (this.params.incidents) {
      routeType = this.params.incidents;
      iconType = <IconTasks />;
    }

    if (this.params.grid) {
      routeType = this.params.grid;
      iconType = <IconGrid />;
    }

    if (this.params.releases) {
      routeType = this.params.releases;
      iconType = <IconRelease />;
    }

    if (this.params.builds) {
      routeType = this.params.builds;
      iconType = <IconRelease />;
    }

    if (this.params.codeRepo) {
      routeType = this.params.codeRepo;
      iconType = this.params.icon || <IconCodeRepo />;
    }

    if (this.params.query) {
      routeType = this.params.query;
      iconType = <IconQuery />;
    }

    if (this.params.executionId) {
      routeType = t('execution#title', {
        order: this.params.executionId,
        name: ExecutionDecorator.testRunTitleDecorator(this.params.execution),
      });
      iconType = <IconExecution />;
      routeArray.push({
        name: t('executions'),
        link: this.executions_link,
      });
    }

    if (this.params.executionComparison) {
      routeType = this.params.executionComparison;
      iconType = <IconExecution />;
      routeArray.push({
        name: t('executions'),
        link: this.executions_link,
      });
    }

    if (this.params.testSuiteName) {
      routeType = `Test Suite Result: ${this.params.testSuiteName}`;
      iconType = <IconTestSuite />;
      routeArray.push(
        {
          name: t('executions'),
          link: this.executions_link,
        },
        {
          name: `#${this.params.executionTestSuiteId}`,
          link: this.execution_details_link,
        }
      );
    }

    if (this.params.testProjectId) {
      routeType = `${t('test-project')} : ${this.params.testProjectName}`;
      iconType = this.params.icon || <IconCodeRepo />;
      routeArray.push({
        name: t('test-projects'),
        link: this.test_projects_link,
      });
    }

    if (this.params.testCaseName) {
      routeType = `Test Result: ${this.params.testCaseName}`;

      iconType = <IconTestCase />;
      routeArray.push({
        name: `#${this.params.executionId}`,
        link: this.execution_details_link,
      });
    }

    if (this.params.requestExecution) {
      routeType = 'Request Result';
      iconType = <IconRequest />;
      routeArray.push({
        name: this.params.testCaseName,
        link: this.execution_test_result_detail_link,
      });
    }

    if (this.params.incidentOrder) {
      routeType = t('incidents');
      iconType = <IconTasks />;
      routeArray.push({
        name: this.params.incidentName,
        link: this.incident_link,
      });
    }

    if (this.params.incidentView) {
      routeType = `Task: ${this.params.incidentName}`;
      iconType = <IconTasks />;
      routeArray.push({
        name: t('incidents'),
        link: this.incidents_link,
      });
    }

    if (this.params.incidentUpdate) {
      routeType = `Task: ${this.params.incidentName}`;
      iconType = <IconTasks />;
      routeArray.push({
        name: t('incidents'),
        link: this.incidents_link,
      });
    }

    if (this.params.newIncident) {
      routeType = t('incident#create-task');
      iconType = <IconTasks />;
      routeArray.push({
        name: t('incidents'),
        link: this.incidents_link,
      });
    }

    if (this.params.releaseUpdate) {
      routeType = this.params.releaseUpdate;
      iconType = <IconRelease />;
      routeArray.push({
        name: t('releases'),
        link: this.releases_link,
      });
    }

    if (this.params.buildUpdate) {
      routeType = this.params.buildUpdate;
      routeArray.push({
        name: t('releases'),
        link: this.releases_link,
      });
    }

    if (this.params.releaseId) {
      routeArray.push({
        name: this.params.releaseName || this.params.releaseId,
        link: this.view_release_link,
      });
    }

    if (this.params.testCaseHistory) {
      routeType = `${t(this.params.testCaseType)}: ${
        this.params.testCaseHistory
      }`;
      iconType = <IconTestCase />;
      routeArray.push({
        name: t('test_cases'),
        link: this.test_cases_link,
      });
    }

    if (this.params.testSuiteHistory) {
      routeType = `Test Suite: ${this.params.testSuiteHistory}`;
      iconType = <IconTestSuite />;
      routeArray.push({
        name: t('test_suites'),
        link: this.test_suites_link,
      });
    }

    if (this.params.testReportTitle) {
      routeType = `Report: ${this.params.testReportTitle}`;
      iconType = <IconReports />;
      routeArray.push({
        name: t('test_reports'),
        link: this.test_reports_link,
      });
    }

    if (this.params.testObjectHistory) {
      routeType = `Request: ${this.params.testObjectHistory}`;
      iconType = <IconWebService />;
      routeArray.push({
        name: t('test_objects'),
        link: this.web_services_link,
      });
    }

    if (this.params.testPlans) {
      routeType = t('test_plans');
      iconType = <IconTestPlan />;
      routeArray.push({
        name: this.params.testPlans,
        link: this.test_plans_link,
      });
    }

    if (this.params.runConfiguration) {
      routeType = this.params.runConfiguration;
      const name = this.params.runConfigurationName;
      if (name && name.length > 0) {
        routeType = `Test run type: ${name}`;
      }
      iconType = <IconGrid />;
      routeArray.push({
        name: t('grid'),
        link: this.grid_link,
      });
    }

    if (this.params.scheduler) {
      routeType = this.params.scheduler;
      routeArray.push({
        name: `${this.params.runConfiguration}`,
        link: this.run_configuration_summary,
      });
    }

    if (this.params.job) {
      routeType = t('job#title', { name: this.params.jobOrder });
      routeArray.push({
        name: `${this.params.runConfiguration}`,
        link: this.run_configuration_link,
      });
    }

    // Team level
    if (this.params.projects) {
      routeType = t('manage_projects');
      iconType = <IconProjects />;
    }
    if (this.params.projectSetting) {
      routeType = t('project-name-settings', { projectName: this.params.projectName });
      iconType = <IconProjects />;
    }

    if (this.params.organizationProjects) {
      routeType = t('projects');
      iconType = <IconProjects />;
    }

    if (this.params.teams) {
      routeType = t('manage_teams');
      iconType = <IconTeams />;
    }

    if (this.params.teamSettings) {
      routeType = this.params.teamSettings;
      iconType = <IconSettings />;
    }

    if (this.params.dashboard) {
      routeType = t('Dashboard');
      iconType = <IconDashboard />;
    }

    if (this.params.project_dashboard) {
      routeType = t('Dashboard');
      iconType = <IconDashboard />;
    }

    if (this.params.ci_dashboard) {
      routeType = this.params.ci_dashboard;
      iconType = <IconDashboard />;
    }

    if (this.params.agent) {
      routeType = t('agent');
      iconType = <IconAgents />;
    }

    if (this.params.agentDetails) {
      routeType = t('agent');
      iconType = <IconAgents />;
      routeArray.push({
        name: t('agent'),
        link: this.agent_link,
      });
    }

    if (this.params.testEnvInstallation) {
      routeType = t('test-env-installation');
      iconType = <IconTestEnvironmentInstallation />;
    }

    if (this.params.testCloudTunnel) {
      routeType = t('test-cloud-tunnel#title');
      iconType = <IconTestEnvironmentInstallation />;
    }

    if (this.params.profile) {
      routeType = t('profile');
      iconType = <IconUser />;
    }

    if (this.params.apikey) {
      routeType = t('agent#api-key');
      iconType = <IconApiKey />;
    }

    if (this.params.subscriptionAssignment) {
      routeType = this.params.subscriptionAssignment;
      iconType = <IconSubscription />;
    }

    if (this.params.katalonRecorderBackup) {
      routeType = this.params.katalonRecorderBackup;
      iconType = <IconKatalonRecorderBackup />;
    }

    if (this.params.userSettings) {
      routeType = this.params.userSettings;
      iconType = <IconSettings />;
    }

    if (this.params.analysis_settings) {
      routeType = t('analysis_settings');
      iconType = <IconSettings />;
    }

    if (this.params.configuration) {
      routeType = this.params.configuration;
      iconType = <IconSettings />;
    }

    if (this.params.userSettings) {
      routeType = t('notifications');
      iconType = <IconSettings />;
    }

    if (this.params.testopsSubscription) {
      routeType = this.params.testopsSubscription;
    }

    if (this.params.testRunCalendar) {
      routeType = this.params.testRunCalendar;
    }

    if (this.params.testRunList) {
      routeType = this.params.testRunList;
    }

    if (this.params.applicationRepository) {
      routeType = this.params.applicationRepository;
    }

    // Etc
    if (this.params.routeType) {
      routeType = this.params.routeType;
      iconType = <IconDashboard />;
    }

    if (this.params.baselineInformationGroupVersion) {
      routeType = this.params.baselineInformationGroupVersion;
    }

    if (this.params.baselineInformationGroupDetail) {
      const { baselineCollectionGroup } =
        this.params.baselineInformationGroupDetail;
      title = DecoratorConstants.baselineCollectionGroupTitleDecorator(
        baselineCollectionGroup
      );
      routeType = (
        <EditableBaselineCollectionGroupTitle
          className="object-title"
          baselineCollectionGroup={baselineCollectionGroup}
        />
      );
    }

    if (this.params.customFields) {
      routeType = this.params.customFields;
    }

    if (this.params.artificialIntelligence) {
      routeType = this.params.artificialIntelligence;
    }

    if (this.params.truetestConfiguration) {
      routeType = t('true-test-configuration');
    }

    return {
      routeArray,
      routeType,
      iconType,
      title,
    };
  }
}

export default Routes;
