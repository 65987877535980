import { FormText } from 'reactstrap';
import React, { useEffect, useState, useCallback } from 'react';
import { forEach, isEmpty, isEqual } from 'lodash';
import PropTypes from 'prop-types';
import { IconButton, Tooltip, Box, Chip } from '@mui/material';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';

import makeStyles from '@mui/styles/makeStyles';
import {
  GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
} from '@mui/x-data-grid-pro';
import { t } from '../../../i18n/t';
import {
  buildDisplayTestCloudAgentEnvironment,
} from '../services/testcloud';
import TestCloudEnvironment from '../../dialog/TestCloudEnvironment';
import { ConfigType } from '../../../utils/Constants';
import TestSuitesDataGrid from './TestSuitesDataGrid';
import CustomDetailPanelToggle from './CustomDetailPanelToogle';
import TooltipComponent from '../../TooltipComponent';


function TestSuiteCollectionSelectionTable({ initialValue, testSuites, options, classNames, onChange, nativeApps }) {

  const [output, setOutput] = useState(initialValue);

  const [isChecked, setIsChecked] = useState(false);

  const [isSelected, setIsSelected] = useState(false);

  const [testSuiteIdsSelected, setTestSuiteIdsSelected] = useState([]);

  const [rows, setRows] = useState([]);

  const [countRowsSelected, setCountRowsSelected] = useState(0);

  const [total, setTotal] = useState(0);

  const [showTestCloudEnv, setShowTestCloudEnv] = useState(false);

  useEffect(() => {
    setRows(() => {
      const newRows = [];
      forEach(testSuites, (element) => {
        const testSuiteRunConfiguration = initialValue.find((el) => el.testSuiteCollectionConfigurationId === element.id);
        element.disabled = false;
        element.testCloudAgent = testSuiteRunConfiguration?.testCloudAgent;
        newRows.push(element);
      });
      return newRows;
    });
    setTotal(testSuites?.length);
  }, [initialValue, testSuites]);

  const handleToggleTestCloudEnv = () => {
    setShowTestCloudEnv(!showTestCloudEnv);
  };

  const handleSelectOneTestSuite = useCallback((value) => {
    setOutput((prevOutput) => {
      const newOutput = [...prevOutput];
      const existItem = newOutput.find((el) => el.testSuiteCollectionConfigurationId === value.testSuiteCollectionConfigurationId);

      if (existItem && !isEqual(existItem.testCloudAgent, value.testCloudAgent)) {
        const selectedIdx = newOutput.indexOf(existItem);
        newOutput[selectedIdx] = { ...newOutput[selectedIdx], ...value };
      }

      forEach(testSuites, (testSuite) => {
        if (value.testSuiteCollectionConfigurationId === testSuite.id && !isEqual(testSuite.testCloudAgent, value?.testCloudAgent)) {
          testSuite.disabled = false;
          testSuite.testCloudAgent = value?.testCloudAgent;
        }
      });
      if (!isEqual(newOutput, prevOutput)) {
        onChange(newOutput);
      }
      return newOutput;
    });
  }, [onChange, testSuites]);

  useEffect(() => {
    if (!isEqual(output, initialValue)) {
      setOutput(initialValue);
    }
  }, [initialValue, output]);

  const columns = [
    {
      field: 'testSuiteEntity',
      headerName: t('test-suite-collection-override#header-colunm-name-test-suite'),
      minWidth: 350,
      renderCell: (params) => (
        <Tooltip
          classes={makeStyles(() => ({
            tooltip: {
              backgroundColor: '#172B4D',
              color: '#FFFFFF',
              fontWeight: '300',
              margin: '4px',
              lineHeight: '1rem',
              fontSize: '0.75rem',
            },
            arrow: {
              color: '#172B4D',
            }
          }))()}
          title={params.value}
          arrow
          placement="bottom-start"
        >
          <span>{params.value}</span>
        </Tooltip>
      ),
    },
    {
      field: 'testCloudenv',
      headerName: t('test-suite-collection-override#header-colunm-name-testcloud-environment'),
      width: 350,
      renderCell: (params) => (
        <Chip
          className="border-0 chip-bg-border"
          sx={{
            '&:hover': {
              cursor: 'pointer',
            },
          }}
          variant="outlined"
          label={params.row?.testCloudAgent ?
            buildDisplayTestCloudAgentEnvironment(params.row?.testCloudAgent) : ''}
          size="small"
        />
      ),
    },
    {
      ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
      minWidth: 70,
      renderCell: (params) => (
        <CustomDetailPanelToggle id={params.id} value={params.value} />
      ),
    },
  ];

  const onCheck = (value) => {
    if (value.length > 0) {
      setIsChecked(true);
    } else {
      setIsChecked(false);
      setShowTestCloudEnv(false);
    }
    forEach(rows, (row) => {
      row.disabled = !!value.includes(row.id);
    });
    setCountRowsSelected(value?.length);
    setRows(rows);
    setTestSuiteIdsSelected(value);
    setIsSelected(false);
  };

  const handleTestSuitesSelected = useCallback((value) => {
    if (!isEmpty(value.selectedItems) && value.selectedItems[0]) {
      const firstExecutionEnvironment = value.selectedItems[0];
      forEach(testSuiteIdsSelected, (item) => {
        const output = {
          testSuiteCollectionConfigurationId: item,
          testCloudAgent: firstExecutionEnvironment,
        };
        handleSelectOneTestSuite(output);
      });
    }

  }, [handleSelectOneTestSuite, testSuiteIdsSelected]);

  return (
    <>
      {isChecked &&
        <div className="mb-6 d-flex justify-content ">
          <div className="col-6 d-flex align-items-center">
            <FormText>
              {t('test-suite-collection-override#selected-rows-count', { countRowsSelected, total })}
            </FormText>
            <TooltipComponent text={t('test-suite-collection-override#configure-selected-test-suite')} placement="bottom-start" arrow>
              <IconButton onClick={handleToggleTestCloudEnv} size="small">
                <SettingsSuggestIcon
                  color="primary"
                  sx={{
                    fontSize: 20,
                  }}
                />
              </IconButton>
            </TooltipComponent>
          </div>
        </div>}

      {showTestCloudEnv && (
      <Box>
        <TestCloudEnvironment
          testSuiteId={null}
          tunnelId={null}
          handleSelectedTunnelChange={null}
          enableApiTesting={false}
          updateEnableApiTesting={null}
          testCloudEnv={{ data: options }}
          toggleTestCloudTunnelIntegration={null}
          enabledTestCloudTunnel={false}
          apps={nativeApps}
          handleChangeExecutionEnvironments={handleTestSuitesSelected}
          agents={null}
          configType={ConfigType.TSC}
          hideProfileWithTunnel={false}
          hideTabPanel={false}
          requireTestEnv={false}
        />
      </Box>
      )}
      <TestSuitesDataGrid
        isSelected={isSelected}
        rows={rows}
        columns={columns}
        onCheck={onCheck}
        classNames={classNames}
        nativeApps={nativeApps}
        testCloudEnv={{ data: options }}
        onChangeTS={handleSelectOneTestSuite}
      />
    </>
  );

}

TestSuiteCollectionSelectionTable.propTypes = {
  initialValue: PropTypes.array,
  options: PropTypes.array.isRequired,
  testSuites: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  classNames: PropTypes.object,
  nativeApps: PropTypes.array,
};

TestSuiteCollectionSelectionTable.defaultProps = {
  initialValue: [],
  options: [],
  classNames: {},
  nativeApps: [],
};

export default TestSuiteCollectionSelectionTable;
