import { isEmpty } from 'lodash';
import {
  ALL_BROWSERS_TESTCLOUD_AGENT,
  BROWSER_HEADLESS_SUFFIX,
  BrowserType,
  CLOUD_TYPE_AGENT_MAPPING,
  CLOUD_TYPE_AGENT_TYPE_MAPPING,
  CLOUD_TYPE_TS_PROPERTY_MAPPING,
  CLOUD_TYPE_TSC_PROPERTY_MAPPING,
  CloudType,
  ConfigType,
  ExecutionType,
  Framework, OsType, TestCloudAgentExecutionType,
  TestSuiteType
} from '../../../utils/Constants';
import {
  getDefaultScheduler,
  updateRunConfigurationAttributes,
  updateSchedulerAttributes
} from '../../../pages/plan/ConfigurePlanUtils';
import { getBrowserHeadlessTypeByBrowserType } from './testcloud';
import MFlags from '../../../models/MFlags';

export const extractPropertyInAgentByName = (formData, propertyName, defaultValue) => {
  const {
    testSuiteAgents,
    testSuiteCollectionAgents,
    commandAgents,
    genericCommandAgents,
  } = formData;

  const kAgents = [testSuiteAgents, testSuiteCollectionAgents, commandAgents, genericCommandAgents];
  const foundAgent = kAgents.find((item) => item && item.length > 0);
  return foundAgent ? foundAgent[0][propertyName] : defaultValue;
};


export const extractCloudTypeInAgentByConfigType = (formData, defaultValue, configType) => {
  const {
    testSuiteAgents,
    testSuiteCollectionAgents,
    commandAgents,
    genericCommandAgents,
  } = formData;
  switch (configType) {
    case ConfigType.TSC: return !isEmpty(testSuiteCollectionAgents) ? testSuiteCollectionAgents[0].cloudType : defaultValue;
    case ConfigType.TS: return !isEmpty(testSuiteAgents) ? testSuiteAgents[0].cloudType : defaultValue;
    case ConfigType.GENERIC_COMMAND: return !isEmpty(genericCommandAgents) ? genericCommandAgents[0].cloudType : defaultValue;
    case ConfigType.COMMAND: return !isEmpty(commandAgents) ? commandAgents[0].cloudType : defaultValue;
    default: return defaultValue;
  }
};


const extractByProperty = (items, propertyName) => {
  if (items.length > 0) {
    return items[0][propertyName];
  }

  return '';
};

const getExecutionTypeByConfigType = (formData) => {
  const {
    tsType,
    configType,
  } = formData;

  if (configType.value === ConfigType.TS && tsType === TestSuiteType.CLOUD_STUDIO) return ExecutionType.G5;
  if (configType.value === ConfigType.GENERIC_COMMAND) return ExecutionType.GENERIC_COMMAND;
  return ExecutionType.G4;
};

const getExecutionEnv = (formData) => {
  const {
    testSuiteCollectionAgents,
    commandAgents,
    genericCommandAgents,
    tsType,
    testSuiteAgents,
    configType } = formData;

  const cloudType = extractCloudTypeInAgentByConfigType(formData, CloudType.TEST_CLOUD_AGENT, configType?.value);

  switch (configType.value) {
    case ConfigType.TSC: {
      if (cloudType === CloudType.TEST_CLOUD_AGENT) {
        return testSuiteCollectionAgents.map((value) => ({
          platform: value.testCloudAgent.os,
          platformVersion: value.testCloudAgent.osVersion,
          browser: value.testCloudAgent.browser,
          browserVersion: value.testCloudAgent.browserVersion,
          framework: Framework.SELENIUM,
          script: value.testSuiteCollectionConfigurationId,
          [CLOUD_TYPE_AGENT_MAPPING[cloudType]]: value.id,
        }));
      } else {
        return testSuiteCollectionAgents.map((value) => ({
          platform: value.os,
          framework: Framework.SELENIUM,
          [CLOUD_TYPE_AGENT_MAPPING[cloudType]]: value.id,
        }));
      }

    }
    case ConfigType.COMMAND: {
      return commandAgents.map((value) => ({
        platform: value.os,
        platformVersion: value.osVersion,
        browser: value.browser,
        browserVersion: value.browserVersion,
        framework: Framework.SELENIUM,
        [CLOUD_TYPE_AGENT_MAPPING[cloudType]]: value.id,
      }));
    }
    case ConfigType.GENERIC_COMMAND: {
      return genericCommandAgents.map((value) => ({
        platform: value.os,
        platformVersion: value.osVersion,
        browser: value.browser,
        browserVersion: value.browserVersion,
        framework: Framework.SELENIUM,
        [CLOUD_TYPE_AGENT_MAPPING[cloudType]]: value.id,
      }));
    }
    case ConfigType.TS: {
      return testSuiteAgents.map((value) => ({
        platform: value.os,
        platformVersion: value.osVersion,
        browser: value.browser,
        browserVersion: value.browserVersion,
        framework: tsType === TestSuiteType.CLOUD_STUDIO ? Framework.PLAYWRIGHT : Framework.SELENIUM,
        [CLOUD_TYPE_AGENT_MAPPING[cloudType]]: value.id,
      }));
    }
    default: {
      return [];
    }
  }
};

const getExecutionMetadata = (formData) => {

  const {
    tsType,
    configType,
    kreVersion,
    testSuiteId,
    testSuiteCollectionId,
    ksVersions,
  } = formData;

  const cloudType = extractCloudTypeInAgentByConfigType(formData, CloudType.TEST_CLOUD_AGENT, configType?.value);

  return {
    agentType: CLOUD_TYPE_AGENT_TYPE_MAPPING[cloudType],
    engine: configType.value === ConfigType.TS && tsType === TestSuiteType.CLOUD_STUDIO ? {
      type: ExecutionType.G5,
      version: 'latest'
    } : {
      type: ExecutionType.G4,
      version: kreVersion?.ksVersion || ksVersions[0]?.value
    },
    config: {
      testSuiteId,
      testSuiteCollectionId,
      executionEnvs: getExecutionEnv(formData),
    }
  };
};

const getEnabledTestCloudTunnel = (formData) => {
  const {
    testSuiteCollectionAgents,
    commandAgents,
    testSuiteAgents,
    configType } = formData;

  switch (configType.value) {
    case ConfigType.TSC: {
      return extractByProperty(testSuiteCollectionAgents, 'enabledTestCloudTunnel');
    }
    case ConfigType.COMMAND: {
      return commandAgents[0]?.enabledTestCloudTunnel;
    }
    case ConfigType.GENERIC_COMMAND: {
      return false;
    }
    case ConfigType.TS: {
      return extractByProperty(testSuiteAgents, 'enabledTestCloudTunnel');
    }
    default: {
      return false;
    }
  }
};


const getSettings = (formData) => {
  const { executionMode, kobitonDeviceId, enabledKobitonIntegration } = formData;
  return {
    executionMode: executionMode.value,
    kobitonDeviceId,
    enabledKobitonIntegration,
    enabledTestCloudTunnel: getEnabledTestCloudTunnel(formData),
  };
};

export const formDataToScheduler = (formData) => ({ ...formData.scheduler, active: formData.scheduler.repeatEnable });

const transformTestRunConfig = (formData) => ({
  executionType: getExecutionTypeByConfigType(formData.runConfiguration),
  executionMetadata: getExecutionMetadata(formData.runConfiguration),
  settings: getSettings(formData.runConfiguration),
  scheduler: formDataToScheduler(formData)
});

const transformAgentsToRunConfiguration = (formData) => {
  const {
    configType,
    testSuiteAgents,
    testSuiteCollectionAgents,
    commandAgents,
    genericCommandAgents,
    testCloudAgents,
    kreVersion,
    xrayIntegration,
    executionProfileId,
    tsType,
    ...rest
  } = formData.runConfiguration;
  const cloudType = extractCloudTypeInAgentByConfigType(formData.runConfiguration, CloudType.TEST_CLOUD_AGENT, configType?.value);
  const testRunConfig = transformTestRunConfig(formData);
  const { ksVersion, ksLocation } = kreVersion;
  const { xrayImportReportType = 'PUSH_MANUALLY', externalTestPlanId } = xrayIntegration;

  // eslint-disable-next-line default-case
  switch (configType.value) {
    case ConfigType.TSC: {
      const enabledTestCloudTunnel = extractByProperty(testSuiteCollectionAgents, 'enabledTestCloudTunnel');
      return {
        ...rest,
        cloudType: { value: cloudType },
        configType,
        ksVersion,
        ksLocation,
        xrayImportReportType,
        externalTestPlanId,
        testRunConfig,
        enabledTestCloudTunnel,
        [CLOUD_TYPE_TSC_PROPERTY_MAPPING[cloudType]]: cloudType === CloudType.TEST_CLOUD_AGENT ?
          testSuiteCollectionAgents.map(({ testCloudAgent, ...data }) => {
            const { browser, browserVersion, os, headless, browserVersionType, devicePoolId, devicePoolType, udid, appVersion, appVersionCode, enableApiTesting, tunnelId, ...object } = testCloudAgent;
            return {
              testCloudAgent: {
                ...object,
                headless,
                os,
                browser: (headless ? browser?.replace(BROWSER_HEADLESS_SUFFIX, '') : browser) || (os === 'ios' ? 'safari' : 'chrome'),
                browserVersion: browserVersion || 'latest',
                metadata: {
                  browserVersionType,
                  devicePoolId,
                  devicePool: devicePoolType,
                  udid,
                  ...(appVersion && { appVersion }),
                  ...(appVersionCode && { appVersionCode }),
                  enableApiTesting: enabledTestCloudTunnel ? enableApiTesting : undefined,
                  tunnelId: enabledTestCloudTunnel ? tunnelId : undefined,
                },
              },
              ...data
            };
          }) : testSuiteCollectionAgents
      };
    }
    case ConfigType.COMMAND: {
      const enabledTestCloudTunnel = extractByProperty(commandAgents, 'enabledTestCloudTunnel');
      return {
        ...rest,
        cloudType: { value: cloudType },
        configType,
        ksVersion,
        ksLocation,
        xrayImportReportType,
        externalTestPlanId,
        testRunConfig,
        [CLOUD_TYPE_TS_PROPERTY_MAPPING[cloudType]]: commandAgents.map(
          ({ enableApiTesting, tunnelId, ...rest }) => ({
            ...rest,
            metadata: {
              enableApiTesting: enabledTestCloudTunnel ? enableApiTesting : undefined,
              tunnelId: enabledTestCloudTunnel ? tunnelId : undefined,
            }
          })
        ),
        browserType: commandAgents[0]?.browserType,
        enabledTestCloudTunnel,
      };
    }
    case ConfigType.GENERIC_COMMAND: {
      return { ...rest,
        cloudType: { value: cloudType },
        configType,
        ksVersion,
        ksLocation,
        xrayImportReportType,
        externalTestPlanId,
        testRunConfig,
        [CLOUD_TYPE_TS_PROPERTY_MAPPING[cloudType]]: genericCommandAgents,
        browserType: genericCommandAgents[0]?.browserType,
        enabledTestCloudTunnel: false
      };
    }
    case ConfigType.TS: {
      const enabledTestCloudTunnel = extractByProperty(testSuiteAgents, 'enabledTestCloudTunnel');
      return {
        ...rest,
        configType,
        cloudType: { value: cloudType },
        ksVersion: MFlags.testCloudG5TestSuiteEnabled && tsType === TestSuiteType.CLOUD_STUDIO ? undefined : ksVersion,
        ksLocation: MFlags.testCloudG5TestSuiteEnabled && tsType === TestSuiteType.CLOUD_STUDIO ? undefined : ksLocation,
        xrayImportReportType,
        externalTestPlanId,
        testRunConfig,
        browserType: extractByProperty(testSuiteAgents, 'browserType'),
        enabledTestCloudTunnel,
        executionProfileId: executionProfileId || -1,
        [CLOUD_TYPE_TS_PROPERTY_MAPPING[cloudType]]:
            testSuiteAgents.map(({
              os,
              osVersion,
              browser,
              browserVersion,
              browserType,
              browserVersionType,
              headless,
              devicePoolId,
              devicePoolType,
              udid,
              appVersion,
              appVersionCode,
              enableApiTesting,
              tunnelId,
              ...data
            }) => ({
              ...data,
              os,
              osVersion: osVersion ?? '',
              headless,
              browser: (headless ? browser?.replace(BROWSER_HEADLESS_SUFFIX, '') : browser) || (os === 'ios' ? 'safari' : 'chrome'),
              browserVersion: browserVersion || 'latest',
              browserType: headless ? getBrowserHeadlessTypeByBrowserType(browser) : browserType,
              metadata: {
                browserVersionType,
                devicePoolId,
                devicePool: devicePoolType,
                udid,
                appVersion,
                appVersionCode,
                enableApiTesting: (enabledTestCloudTunnel && browserType !== BrowserType.WEB_SERVICE) ? enableApiTesting : undefined,
                tunnelId: enabledTestCloudTunnel ? tunnelId : undefined,
              },
            }))
      };
    }
  }
  return {};
};

const transformToRunConfiguration = (formData) => ({ ...formData, testProject: { id: formData.testProjectId } });

const groupingWebServiceAgent = (agents) => {
  const otherAgents = agents.filter((agent) => agent.browserType !== BrowserType.WEB_SERVICE);
  const webServiceAgents = agents.filter((agent) => agent.browserType === BrowserType.WEB_SERVICE);

  if (webServiceAgents.length > 0) {
    const webServiceAgent = {
      ...webServiceAgents[0],
      os: OsType.Linux.toLowerCase(),
      browser: ALL_BROWSERS_TESTCLOUD_AGENT.browser,
      browserType: BrowserType.WEB_SERVICE,
      browserVersion: ALL_BROWSERS_TESTCLOUD_AGENT.browserVersion
    };
    return [...otherAgents, webServiceAgent];
  }
  return agents;
};

const agentToFormData = (agents, runConfiguration) => {
  const { enabledTestCloudTunnel, cloudType, browserType } = runConfiguration;

  const transformTestCloudAgent = (testCloudAgent) => {
    if (testCloudAgent) {
      const { headless, browser, ...data } = testCloudAgent;
      return {
        ...data,
        headless,
        browserType: testCloudAgent?.browserType || (testCloudAgent?.executionType === TestCloudAgentExecutionType.WEB_SERVICE ? BrowserType.WEB_SERVICE : null),
        browser: headless ? `${browser}${BROWSER_HEADLESS_SUFFIX}` : browser,
        devicePoolId: data?.metadata?.devicePoolId,
        devicePoolType: data?.metadata?.devicePool,
        enableApiTesting: data?.metadata?.enableApiTesting === 'true',
        tunnelId: data?.metadata?.tunnelId,
        udid: data?.metadata?.udid,
        browserVersionType: data?.metadata?.browserVersionType,
        appVersion: data?.metadata?.appVersion,
      };
    }
    return testCloudAgent;
  };

  return groupingWebServiceAgent(agents.map((agent) => {
    agent.testCloudAgent = transformTestCloudAgent(agent?.testCloudAgent);
    return {
      ...agent,
      browserType,
      cloudType: cloudType.value,
      enabledTestCloudTunnel,
      browserVersionType: agent?.metadata?.browserVersionType,
      devicePoolId: agent?.metadata?.devicePoolId,
      devicePoolType: agent?.metadata?.devicePool,
      udid: agent?.metadata?.udid,
      appVersion: agent?.metadata?.appVersion,
      enableApiTesting: agent?.metadata?.enableApiTesting === 'true',
      tunnelId: agent?.metadata?.tunnelId,
    };
  }));
};

export const formDataToRunConfiguration = (formData) => {
  const runConfigurationWithAgentsFormData = transformAgentsToRunConfiguration(formData);
  return transformToRunConfiguration(runConfigurationWithAgentsFormData);
};

export const runConfigurationToFormData = (runConfiguration) => {
  const updatedRunConfiguration = updateRunConfigurationAttributes(runConfiguration);
  const { configType, cloudType, ksVersion, ksLocation, xrayImportReportType, externalTestPlanId } = updatedRunConfiguration;
  const kreVersion = { ksVersion, ksLocation, enableSelectKsLocation: cloudType === CloudType.LOCAL_AGENT };
  const xrayIntegration = { xrayImportReportType, externalTestPlanId };

  // eslint-disable-next-line default-case
  switch (configType.value) {
    case ConfigType.TSC: {
      return {
        ...updatedRunConfiguration,
        kreVersion,
        xrayIntegration,
        testSuiteCollectionAgents: agentToFormData(runConfiguration[CLOUD_TYPE_TSC_PROPERTY_MAPPING[cloudType.value]], runConfiguration),
      };
    }
    case ConfigType.COMMAND: {
      return {
        ...updatedRunConfiguration,
        kreVersion,
        xrayIntegration,
        commandAgents: agentToFormData(runConfiguration[CLOUD_TYPE_TS_PROPERTY_MAPPING[cloudType.value]], runConfiguration),
      };
    }
    case ConfigType.GENERIC_COMMAND: {
      return {
        ...updatedRunConfiguration,
        kreVersion,
        xrayIntegration,
        genericCommandAgents: agentToFormData(runConfiguration[CLOUD_TYPE_TS_PROPERTY_MAPPING[cloudType.value]], runConfiguration),
      };
    }
    case ConfigType.TS:
      return {
        ...updatedRunConfiguration,
        kreVersion,
        xrayIntegration,
        testSuiteAgents: agentToFormData(runConfiguration[CLOUD_TYPE_TS_PROPERTY_MAPPING[cloudType.value]], runConfiguration),
      };
  }
  return updatedRunConfiguration;
};

export const schedulerToFormData = (scheduler) => updateSchedulerAttributes(scheduler || getDefaultScheduler());
