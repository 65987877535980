import React from 'react';
import { Nav } from 'reactstrap';
import { NavLink as RRNavLink } from 'react-router-dom';
import PropTypes, { number, array } from 'prop-types';
import Routes from '../utils/Routes';
import { t } from '../i18n/t';
import MConfigs from '../models/MConfigs';
import MAuth from '../models/MAuth';
import MFlags from '../models/MFlags';
import Arrays from '../utils/Arrays';
import SimilarFailureHelper from '../utils/SimilarFailureHelper';
import MContext from '../models/MContext';

function HeaderNav({ tabHeaders = [] }) {
  return (
    <div className="page-tab">
      <Nav className="page-tab__nav">
        {tabHeaders.map(({ startAdornment, endAdornment, name, href, dataTestId }) => {
          const active = window.location.pathname === href;
          return (
            <RRNavLink
              exact
              key={name}
              className={`page-tab__nav__nav-link tab-button ${active ? 'active' : ''} d-flex align-items-center`}
              to={href}
              data-testid={dataTestId}
            >
              {startAdornment}
              {name}
              {endAdornment}
            </RRNavLink>
          );
        })}
      </Nav>
    </div>
  );
}

HeaderNav.propTypes = {
  tabHeaders: array,
};

function ReleaseDetailHeaderNav({ projectId, teamId, releaseId }) {
  const router = new Routes({ projectId, teamId, releaseId });
  const tabHeaders = [
    {
      name: t('test_cases'),
      href: router.view_release_link,
    },
    {
      name: t('executions'),
      href: router.release_executions_details_link,
    },
    {
      name: t('plans'),
      href: router.release_run_configuration_details_link,
    },
  ];

  return <HeaderNav tabHeaders={tabHeaders} />;
}

function WebServiceHeaderNav() {
  const router = new Routes();
  const tabHeaders = [
    {
      name: t('test_objects'),
      href: router.web_services_link,
    },
    {
      name: t('web_services#abnormal'),
      href: router.web_services_anomalies_link,
    },
  ];

  return <HeaderNav tabHeaders={tabHeaders} />;
}

function ExecutionHeaderNav({ projectId, teamId, executionId }) {
  const router = new Routes({ projectId, teamId, executionId });
  const executionDetailsLink = router.execution_details_link;
  const executionTestRunDetails = router.execution_test_run_details_link;
  const executionFileDetails = router.execution_file_details;
  const executionDefectLink = router.execution_defect_link;
  const tabHeaders = [
    {
      name: t('summary'),
      href: executionDetailsLink,
    },
    {
      name: t('test_results'),
      href: executionTestRunDetails,
    },
    {
      name: t('files'),
      href: executionFileDetails,
    },
    {
      name: t('defects'),
      href: executionDefectLink,
    },
  ];

  return <HeaderNav tabHeaders={tabHeaders} />;
}

function TestDesignHeaderNav({ projectId, teamId }) {
  const router = new Routes({ projectId, teamId });
  const tabHeaders = [
    {
      name: t('test_cases'),
      href: router.test_cases_link,
    },
    {
      name: t('test_suites'),
      href: router.test_suites_link,
    },
    {
      name: t('test_objects'),
      href: router.web_services_link,
    },
  ];

  return <HeaderNav tabHeaders={tabHeaders} />;
}

function ExecutionTestSuiteHeaderNav({ projectId, teamId, executionTestSuiteId }) {
  const router = new Routes({ projectId, teamId, executionTestSuiteId });
  const tabHeaders = [{
    name: t('result'),
    href: router.execution_test_suite_detail_link,
  },
  {
    name: t('test_results'),
    href: router.execution_test_suite_test_run_link,
  },
  {
    name: t('comments'),
    href: router.execution_test_suite_comment_link,
  },
  ];
  return <HeaderNav tabHeaders={tabHeaders} />;
}

function ExecutionTestOpsVisionHeaderNav({ projectId, teamId, executionId }) {
  const router = new Routes({ projectId, teamId, executionId });
  const tabHeaders = [
    {
      name: t('results'),
      href: router.keyes_execution_details_link,
    },
    {
      name: t('comments'),
      href: router.keyes_execution_detail_comments_link,
    },
  ];

  return <HeaderNav tabHeaders={tabHeaders} />;
}

function ExecutionTestRunHeaderNav(props) {
  const router = new Routes(props);
  const { testResult } = props;
  const isShowSimilarFailures = SimilarFailureHelper.isShowSimilarFailures(testResult);
  const tabHeaders = [
    {
      name: t('result'),
      href: router.execution_test_result_detail_link,
    },
    {
      name: t('requests'),
      href: router.execution_test_result_request_link,
    },
    {
      name: t('test_object_entities'),
      href: router.execution_test_result_test_objects_link,
    },
    ...Arrays.insertIf(
      isShowSimilarFailures,
      {
        name: t('similar_failures'),
        href: router.execution_test_result_similar_failures_link,
      }
    ),
    {
      name: t('comments'),
      href: router.execution_test_result_comment_link,
    },
    {
      name: t('reruns'),
      href: router.execution_test_result_rerun_link,
    },
  ];
  return <HeaderNav tabHeaders={tabHeaders} />;
}

function UsersHeaderNav({ projectId }) {
  const router = new Routes({ projectId });
  const tabHeaders = [{
    name: t('Users'),
    href: router.admin_users_link,
  },
  {
    name: t('Invitation'),
    href: router.admin_users_invite_link,
  }];
  return <HeaderNav tabHeaders={tabHeaders} />;
}

function UsersOrganizationHeaderNav({ organizationId }) {
  const router = new Routes({ organizationId });
  const tabHeaders = [
    {
      name: t('Users'),
      href: router.organization_users_link,
    },
    {
      name: t('Invitations'),
      href: router.organization_users_invite_link,
    },
  ];
  return <HeaderNav tabHeaders={tabHeaders} />;
}

function OrganizationManageProjectsHeaderNav({ organizationId }) {
  const router = new Routes({ organizationId });
  const tabHeaders = [
    {
      name: t('active_projects'),
      href: router.organization_manage_projects_link,
    },
    {
      name: t('archived_projects'),
      href: router.organization_manage_archived_projects_link,
    },
  ];

  return <HeaderNav tabHeaders={tabHeaders} />;
}

function OrganizationLicensesHeaderNav({ organizationId }) {
  const router = new Routes({ organizationId });
  let tabHeaders = [
    {
      name: t('kse_license_keys'),
      href: router.organization_kse_licenses_link,
    },
    {
      name: t('unlimited_kse_license_keys'),
      href: router.organization_unlimited_kse_licenses_link,
    },
    {
      name: t('engine_license_keys'),
      href: router.organization_engine_licenses_link,
    },
    {
      name: t('unlimited_engine_license_keys'),
      href: router.organization_unlimited_engine_licenses_link,
    },
    ...Arrays.insertIf(!MConfigs.isOnPremise, {
      name: t('floating_engine_license_keys'),
      href: router.organization_floating_engine_licenses_link,
    }),
    {
      name: t('test_cloud_license_keys'),
      href: router.organization_test_cloud_link,
    },
  ];

  if (!MConfigs.isOnPremise && MAuth.isSystemRole()) {
    tabHeaders = tabHeaders.concat([
      {
        name: t('testops_license_keys'),
        href: router.organization_testops_license_link,
      },
    ]);
  }
  return <HeaderNav tabHeaders={tabHeaders} />;
}

function AgentHeaderNav({ isOrganizationScope }) {
  const router = new Routes();
  const { isOnPremise } = MConfigs;
  const tabHeaders = [
    {
      name: t('local-agent'),
      href: isOrganizationScope ? router.organization_setting_agent_link : router.agent_link,
    },
    ...Arrays.insertIf(!isOnPremise, {
      name: t('k8s-agent'),
      href: isOrganizationScope ? router.organization_setting_k8s_agent_link : router.k8s_agents_link,
    }),
    ...Arrays.insertIf(!isOnPremise, {
      name: t('circle-ci-agent'),
      href: isOrganizationScope ? router.organization_setting_circle_ci_agent_link : router.circle_ci_agents_link,
    }),
    ...Arrays.insertIf(!isOrganizationScope, {
      name: t('test-cloud-agent'),
      href: isOrganizationScope ? router.organization_setting_testcloud_agent_link : router.test_cloud_agents_link,
    })
  ];
  return <HeaderNav tabHeaders={tabHeaders} />;
}

function TestEnvInstallationHeaderNav() {
  const router = new Routes();
  const tabHeaders = [
    {
      name: t('agent-local'),
      href: router.organization_setting_test_env_installation_link,
    },
    {
      name: t('agent-docker'),
      href: router.organization_setting_test_env_docker_agent_installation_link,
    },
  ];
  return <HeaderNav tabHeaders={tabHeaders} />;
}

function BaselineDetailHeaderNav() {
  const router = new Routes();
  const tabHeaders = [
    {
      name: t('baseline-detail-current'),
      href: router.baseline_collection_current_link,
    },
    {
      name: t('baseline-detail-history'),
      href: router.baseline_collection_history_link,
    },
  ];
  return <HeaderNav tabHeaders={tabHeaders} />;
}

function GridsHeaderNav({ projectId }) {
  const router = new Routes({ projectId });
  const tabHeaders = [{
    name: t('test-project'),
    href: router.test_projects_link,
  },
  {
    name: 'Test Plan',
    href: router.test_plan_link,
  }];
  return <HeaderNav tabHeaders={tabHeaders} />;
}

function ReportTestDesignHeaderNav({ teamId, projectId }) {
  const router = new Routes({ teamId, projectId });
  const tabHeaders = [
    {
      name: t('requirement-test-run-coverage'),
      href: router.test_report_requirement_test_run_coverage,
    },
    {
      name: t('traceability-report'),
      href: router.test_report_traceability_report,
    },
  ];
  return <HeaderNav tabHeaders={tabHeaders} />;
}

function TestRunHeaderNav({ teamId, projectId }) {
  const router = new Routes({ teamId, projectId });
  const tabHeaders = [
    {
      name: t('executions_overview'),
      href: router.overview_link,
    },
    {
      name: t('executions'),
      href: router.executions_link,
    },
    {
      name: t('test-performance'),
      href: router.test_report_test_performance,
    },
    {
      name: t('frequency-report'),
      href: router.test_report_frequency_report,
    },
    {
      endAdornment: <span className="badge badge-trial ml-2">{t('beta')}</span>,
      name: t('failed_test_results'),
      href: router.failed_test_result,
    }
  ];
  return <HeaderNav tabHeaders={tabHeaders} />;
}

function TestCloudHeaderNav({ teamId, projectId }) {
  const router = new Routes({ teamId, projectId });
  const tabHeaders = [
    {
      name: t('test-execution#test-run-calendar'),
      href: router.test_planning_link,
    },
    {
      name: t('test-execution#test-run-list'),
      href: router.grid_link,
    }
  ];

  if (MFlags.testcloudExecutionEnvMicroAppEnabled) {
    tabHeaders.unshift({
      name: t('test-execution#histories'),
      href: router.test_runs_link,
    });
  }

  if (MContext.testCloudMobileNativeAppEnabled) {
    tabHeaders.push({
      name: t('test-execution#apps'),
      href: router.native_apps_link,
    });
  }

  return <HeaderNav tabHeaders={tabHeaders} />;
}

function ReportTestMaintenanceHeaderNav({ teamId, projectId }) {
  const router = new Routes({ teamId, projectId });
  const tabHeaders = [
    {
      name: t('test_cases#active'),
      href: router.test_cases_active_link,
    },
    {
      name: t('test_cases#stale'),
      href: router.test_cases_stale_link,
    },
    {
      name: t('test_cases#slowest'),
      href: router.test_cases_slowest_link,
    },
    {
      name: t('flakiness-report'),
      href: router.test_report_flakiness_report,
    },
    {
      name: t('platform-statistics'),
      href: router.test_report_platform_statistics,
    },
  ];
  return <HeaderNav tabHeaders={tabHeaders} />;
}

function VisualTestingHeaderNav({ teamId, projectId }) {
  const router = new Routes({ teamId, projectId });
  const tabHeaders = [
    {
      name: t('keyes-executions'),
      href: router.keyes_link,
      dataTestId: 'visual-test-run',
    },
    {
      name: t('visual_baseline_collections'),
      href: router.baseline_collection_link,
      dataTestId: 'visual-baselines-collection',
    },
  ];
  return <HeaderNav tabHeaders={tabHeaders} />;
}

VisualTestingHeaderNav.propTypes = {
  teamId: number,
  projectId: number,
};

function RunConfigurationHeaderNav() {
  const router = new Routes();
  const tabHeaders = [
    {
      name: t('executions'),
      href: router.run_configuration_link,
    },
    {
      name: t('config'),
      href: router.run_configuration_summary,
    },
    {
      name: t('jobs'),
      href: router.run_configuration_session,
    },
  ];
  return <HeaderNav tabHeaders={tabHeaders} />;
}

function TrueTestTestCaseHeaderNav({ teamId, projectId }) {
  const router = new Routes({ teamId, projectId });
  const tabHeaders = MFlags.editTestCaseData
    ? [
      {
        name: t('executions_overview'),
        href: router.test_case_overview_link,
      },
      {
        name: t('steps'),
        href: router.test_case_steps_link,
      },
      {
        name: t('local-variables'),
        href: router.test_case_local_variables_link,
      },
    ]
    : [
      {
        name: t('executions_overview'),
        href: router.test_case_overview_link,
      },
      {
        name: t('steps'),
        href: router.test_case_steps_link,
      },
    ];
  return <HeaderNav tabHeaders={tabHeaders} />;
}

ReleaseDetailHeaderNav.propTypes = {
  projectId: PropTypes.any,
  teamId: PropTypes.any,
  releaseId: PropTypes.any,
};

ExecutionHeaderNav.propTypes = {
  projectId: PropTypes.any,
  teamId: PropTypes.any,
  executionId: PropTypes.any,
};

TestDesignHeaderNav.propTypes = {
  projectId: PropTypes.any,
  teamId: PropTypes.any,
};

ExecutionTestSuiteHeaderNav.propTypes = {
  projectId: PropTypes.any,
  teamId: PropTypes.any,
  executionTestSuiteId: PropTypes.any,
};

ExecutionTestOpsVisionHeaderNav.propTypes = {
  projectId: PropTypes.any,
  teamId: PropTypes.any,
  executionId: PropTypes.any,
};

UsersHeaderNav.propTypes = {
  projectId: PropTypes.any,
};

UsersOrganizationHeaderNav.propTypes = {
  organizationId: PropTypes.any,
};

OrganizationManageProjectsHeaderNav.propTypes = {
  organizationId: PropTypes.any,
};

OrganizationLicensesHeaderNav.propTypes = {
  organizationId: PropTypes.any,
};

AgentHeaderNav.propTypes = {
  isOrganizationScope: PropTypes.any,
};

GridsHeaderNav.propTypes = {
  projectId: PropTypes.any,
};

ReportTestDesignHeaderNav.propTypes = {
  teamId: PropTypes.any,
  projectId: PropTypes.any,
};

TestRunHeaderNav.propTypes = {
  teamId: PropTypes.any,
  projectId: PropTypes.any,
};

TestCloudHeaderNav.propTypes = {
  teamId: PropTypes.any,
  projectId: PropTypes.any,
};

ReportTestMaintenanceHeaderNav.propTypes = {
  teamId: PropTypes.any,
  projectId: PropTypes.any,
};

TrueTestTestCaseHeaderNav.propTypes = {
  teamId: PropTypes.any,
  projectId: PropTypes.any,
};

export {
  HeaderNav,
  ExecutionHeaderNav,
  WebServiceHeaderNav,
  ExecutionTestSuiteHeaderNav,
  ExecutionTestRunHeaderNav,
  UsersOrganizationHeaderNav,
  UsersHeaderNav,
  GridsHeaderNav,
  TestDesignHeaderNav,
  OrganizationLicensesHeaderNav,
  AgentHeaderNav,
  ReleaseDetailHeaderNav,
  OrganizationManageProjectsHeaderNav,
  BaselineDetailHeaderNav,
  ExecutionTestOpsVisionHeaderNav,
  ReportTestDesignHeaderNav,
  TestRunHeaderNav,
  ReportTestMaintenanceHeaderNav,
  VisualTestingHeaderNav,
  RunConfigurationHeaderNav,
  TestEnvInstallationHeaderNav,
  TestCloudHeaderNav,
  TrueTestTestCaseHeaderNav,
};
