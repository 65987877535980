import React from 'react';
import queryString from 'query-string';
import DefaultLayout from '../components/DefaultLayout';
import PageComponent from '../components/PageComponent';
import { buildSearchCondition } from '../components/search/SearchUtils';
import ObjectSummary from '../components/summary/ObjectSummary';
import { t } from '../i18n/t';
import MContext from '../models/MContext';
import Services from '../utils/Services';
import DefectDataTableV2 from '../components/external_issue/DefectDataTableV2';
import RefreshJiraButton from './test_reports/components/RefreshJiraButton';
import DefectsEmptyState from '../components/external_issue/DefectsEmptyState';

class Defects extends PageComponent {
  constructor(props) {
    super(props);
    this.projectId = MContext.projectId;
    this.project = MContext.project;
    this.meta.id = 'page-external-defects';
    this.meta.title = t(
      'Project {{name}} - {{page}}',
      { name: this.project.name, page: t('defects') },
    );
    this.renderHeader = this.renderHeader.bind(this);
    this.renderBody = this.renderBody.bind(this);
    this.state = {
      connection: null,
    };
  }

  componentDidMount() {
    this.getConnection(this.projectId)
      .then((connection) => {
        this.setState({ connection });
      });
  }

  getConnection(projectId) {
    const params = {
      pagination: {
        page: 0,
        size: 1,
        sorts: 'order,desc',
      },
      conditions: [
        buildSearchCondition('Project.id', '=', projectId),
        buildSearchCondition('active', '=', 'true'),
      ],
      type: 'ExternalConnection',
    };
    return Services.search(params)
      .then(({ content }) => content[0]);
  }

  getConnectionAndSetState = (projectId) => {
    this.getConnection(projectId).then((connection) => {
      this.setState({ connection });
    });
  };

  renderObjectSummary() {
    const { project } = this;
    const urlParams = {
      defects: t('defects'),
      projectName: project.name,
    };
    return (
      <ObjectSummary params={urlParams} />
    );
  }

  renderHeader() {
    return (
      <>
        {this.renderObjectSummary()}
      </>
    );
  }

  renderToolbar() {
    const { connection } = this.state;

    return (
      <RefreshJiraButton
        connection={connection}
        getConnectionAndSetState={this.getConnectionAndSetState}
      />
    );
  }

  renderBody() {
    const { connection } = this.state;

    if (connection === null) {
      return null;
    }

    const params = queryString.parse(this.props.location.search);

    const active = connection && connection.active;

    return (
      <>
        {active && this.renderToolbar()}
        {!active && <DefectsEmptyState />}
        {active && <DefectDataTableV2 urlSearchValue={params.q} />}
      </>
    );
  }

  render() {
    return (
      <DefaultLayout
        renderBody={this.renderBody}
        renderHeader={this.renderHeader}
      />
    );
  }
}

export default Defects;
