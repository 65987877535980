import Icon from '@katalon-studio/katalon-ui/Icon';
import React from 'react';
import colors from '../../../scss/colors.scss';

function RotateRightIcon({ color, textSize, type }) {
  return <Icon
    type={type}
    name="fa-rotate-right"
    textSize={textSize}
    sx={{
      color,
    }}
    boundingSize={null}
    color={null}
  />;
}

RotateRightIcon.defaultProps = {
  color: colors.gray9,
  textSize: '1rem',
  type: 'fa-regular',
};

export default RotateRightIcon;
