import React, { forwardRef, useState, useImperativeHandle } from 'react';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { IconCloseActive, IconCloseInactive, IconDownloadActive, IconDownloadInactive } from '../../images/CustomIcon';
import TextLogViewer from '../file_preview/file_viewers/TextLog';
import TextViewer from '../file_preview/file_viewers/Text';
import LogTreeContainer from '../../pages/execution_test_result/components/LogTreeContainer';
import NoPreviewViewer from '../file_preview/file_viewers/NoPreview';
import { t } from '../../i18n/t';

const ExecutionTestResultExceptionDialog = forwardRef(({
  id, isOpen, onClose
}, ref) => {

  const [activeTab, setActiveTab] = useState(null);
  const [tabs, setTabs] = useState([]);

  useImperativeHandle(ref, () => ({

    setTabContent(tabs) {
      setTabs(tabs);
      setActiveTab(tabs[0]);
    }
  }));

  const renderTabButtons = () => tabs.map((tab) =>
    <Button
      key={tab.title}
      onClick={() => setActiveTab(tab)}
      className={classNames('tab-button border-0 rounded-0', { active: tab === activeTab })}
      color="link"
    >
      {tab.title}
    </Button>);

  const renderActionButtons = () => (
    <div>
      {activeTab && activeTab.file && activeTab.file.source && (
        <Button
          className="action-button two-state-button"
          color="link"
          key="download"
          title={t('download')}
        >
          <a
            href={activeTab.file.source}
            download={activeTab.file.name}
          >
            <IconDownloadActive className="icon-active" />
            <IconDownloadInactive className="icon-inactive" />
          </a>
        </Button>)}
      <Button
        className="action-button two-state-button"
        color="link"
        key="close"
        title={t('close')}
        onClick={onClose}
      >
        <IconCloseActive className="icon-active" />
        <IconCloseInactive className="icon-inactive" />
      </Button>
    </div>);

  const renderTabContent = () => {
    if (!activeTab || !activeTab.file) return null;
    switch (activeTab.file.type) {
      case 'text-log':
        return <TextLogViewer content={activeTab.file.content} className={activeTab.customClass} />;
      case 'text':
        return <TextViewer content={activeTab.file.content} className={activeTab.customClass} />;
      case 'tree-log':
        return <LogTreeContainer log={activeTab.file.content} className={activeTab.customClass} {...activeTab.props} />;
      default:
        return <NoPreviewViewer file={activeTab.file} className={activeTab.customClass} />;
    }
  };

  return (
    <Modal
      id={id}
      isOpen={isOpen}
      aria-labelledby=""
      centered
      wrapClassName="file-preview"
      fade={false}
    >
      <ModalHeader>
        <div className="d-flex flex-wrap">
          <div className="file-preview__tab-buttons">{renderTabButtons()}</div>
          <div className="file-preview__action-button-group">{renderActionButtons()}</div>
        </div>
      </ModalHeader>
      <ModalBody>
        <div className="file-preview__content-wrapper">
          {renderTabContent()}
        </div>
      </ModalBody>
    </Modal>
  );
});

ExecutionTestResultExceptionDialog.propTypes = {
  /**
   * id of exception dialog.
   */
  id: PropTypes.string,
  /**
   * Var allow open exception dialog.
   */
  isOpen: PropTypes.bool,
  /**
   * Function close exception dialog.
   */
  onClose: PropTypes.func
};

ExecutionTestResultExceptionDialog.defaultProps = {
  id: null,
  isOpen: false,
  onClose: null
};

export default ExecutionTestResultExceptionDialog;
