import React, { Component } from 'react';
import { ListItem } from '@mui/material';
import { Button, ButtonToolbar, Form } from 'reactstrap';
import SelectCustomFieldComponent from './SelectCustomFieldComponent';
import DropDownFilter from './search-query/filter/DropDownFilter';
import { t } from '../i18n/t';

class CustomFieldFilter extends Component {
  constructor(props) {
    super(props);
    this.filterMenu = React.createRef();
    this.state = {
      selectedCustomFields: [],
    };

    this.applyFilter = this.applyFilter.bind(this);
  }

  closeDialog = () => {
    this.filterMenu.current.handleToggle();
  }

  buttonLabel() {
    const { selectedCustomFields } = this.state;
    return (
      <span className="font-weight-bold">
        {t('custom-fields#title')}
        { selectedCustomFields.length > 0 ?
          <span className="ml-1 custom-field-filter__badge"> {selectedCustomFields.length} </span> :
          '' }
      </span>

    );
  }

  setSelectedCustomFields = (customFields) => {
    this.setState({
      selectedCustomFields: customFields,
    });
  };

  buildFilterValue() {
    const { selectedCustomFields } = this.state;
    return selectedCustomFields.map((customField) =>
      `${customField.customFieldDefinition.key}.opt_id=${customField.customFieldOption.id}`);
  }

  applyFilter(event) {
    event.preventDefault();
    const filterValue = this.buildFilterValue();
    this.filterMenu.current.applyFilter(null);
    if (filterValue.length > 0) {
      this.filterMenu.current.applyFilter(filterValue);
      this.filterMenu.current.handleToggle();
    }
  }

  clear = () => {
    this.setSelectedCustomFields([]);
    this.filterMenu.current.applyFilter(null);
  }

  render() {
    const { selectedCustomFields } = this.state;
    return (
      <DropDownFilter buttonLabel={this.buttonLabel()} {...this.props} ref={this.filterMenu}>
        <Form className="form-filter" onSubmit={this.applyFilter}>
          <ListItem>
            <SelectCustomFieldComponent
              setSelectedCustomFields={this.setSelectedCustomFields}
              selectedCustomFields={selectedCustomFields}
            />
          </ListItem>
          <ListItem>
            <ButtonToolbar>
              <Button
                color="primary"
                type="submit"
                data-trackid="filter-by-custom-field"
              >
                {t('custom-fields#filter-update')}
              </Button>
              <Button color="link" onClick={this.clear}>
                {t('search-bar#clear')}
              </Button>
            </ButtonToolbar>
          </ListItem>
        </Form>
      </DropDownFilter>
    );
  }
}

export default CustomFieldFilter;
