import React from 'react';
import { IconWarning, IconFrameWarning } from '../../images/CustomNewIcon';
import WarningBannerComponent from '../WarningBannerComponent';

export default function WarningPopup({ text }) {
  return (
    <div className="warning-popup">
      <WarningBannerComponent
        text={text}
        icon={<IconWarning />}
        iconFrame={<IconFrameWarning />}
      />
    </div>
  );
}

