import React from 'react';
import { Card, CardBody, CardFooter, CardHeader } from 'reactstrap';

const ImageCard = ({ className, imageUrl, onClickImage, description, title, footer }) => (
  <Card className={`image-card ${className}`}>
    <div
      onClick={onClickImage}
      className="image image-card__image border rounded"
      style={{ backgroundImage: `url(${imageUrl})` }}
    />
    <CardHeader className="no-border">
      {title}
    </CardHeader>
    <CardBody>
      {description}
    </CardBody>
    <CardFooter>
      {footer}
    </CardFooter>
  </Card>
);

export default ImageCard;
