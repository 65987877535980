import React from 'react';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';
import ActionsHelper from '../../../utils/table/ActionsHelper';
import {
  ACTIONS,
  ACTIONS_TRACKING,
  DISPLAYED_PAGE_TRACKING,
  KATALON_EVENTS,
  OperationType,
} from '../../../utils/Constants';
import { TestEntity } from '../../../models/model/TestEntity';
import PenIcon from '../../../../images/icons/katalonui/PenIcon';
import PenFieldIcon from '../../../../images/icons/katalonui/PenFieldIcon';
import ArrowTurnDownRightIcon from '../../../../images/icons/katalonui/ArrowTurnDownRightIcon';
import DeleteIcon from '../../../../images/icons/katalonui/DeleteIcon';
import CopyIcon from '../../../../images/icons/katalonui/CopyIcon';
import TestCaseService from '../service/TestCaseService';
import { DomEventHandlers } from '../../../utils/EventHandler';
import PlayCircleIcon from '../../../../images/icons/katalonui/PlayCircleIcon';
import TestSuiteService from '../service/TestSuiteService';
import colors from '../../../../scss/colors.scss';
import { t } from '../../../i18n/t';
import LayerPlusIcon from '../../../../images/icons/katalonui/LayerPlusIcon';
import { isSystemTestSuite } from '../../../models/model/TestSuite';
import ActionsMenu from './ActionsMenu';
import ActionsMenuHandler from '../handler/ActionsMenuHandler';

function TestManagementActions(props: {
  data: TestEntity;
  anchorEl: HTMLElement | null;
  setAnchorEl: (value: React.SetStateAction<HTMLElement | null>) => void
}) {
  const { data, anchorEl, setAnchorEl } = props;
  const { draftTestCase, testCase, testSuite } = data;
  const actions = ActionsHelper.getAllowActions(data);
  const isSystemTs = testSuite ? isSystemTestSuite(testSuite) : false;

  const history = useHistory();

  const renameHandler = () => {
    ActionsMenuHandler.renameHandler(data);
  };

  const editHandler = () => {
    ActionsMenuHandler.editHandler(data);
  };

  const duplicateHandler = () => {
    if (draftTestCase) {
      ActionsHelper.syncTrackingData(data, ACTIONS_TRACKING.TEST_CASE_DUPLICATED, DISPLAYED_PAGE_TRACKING.ACTION_MENU);
      TestCaseService.duplicateDraftTestCase(draftTestCase);
    } else if (testCase) {
      ActionsHelper.syncTrackingData(data, ACTIONS_TRACKING.TEST_CASE_DUPLICATED, DISPLAYED_PAGE_TRACKING.ACTION_MENU);
      TestCaseService.duplicate([data]);
    } else if (testSuite) {
      ActionsHelper.syncTrackingData(data, ACTIONS_TRACKING.TEST_SUITE_DUPLICATED, DISPLAYED_PAGE_TRACKING.ACTION_MENU);
      TestSuiteService.duplicate([data]);
    }
  };

  const moveHandler = () => {
    ActionsMenuHandler.moveHandler([data]);
  };

  const deleteHandler = () => {
    let displayPageTracking;
    if (testCase) {
      displayPageTracking = DISPLAYED_PAGE_TRACKING.TES_CASE_TABLE;
      ActionsHelper.syncTrackingData(
        data,
        ACTIONS_TRACKING.DELETE_TEST_CASE_TRIGGERED,
        DISPLAYED_PAGE_TRACKING.ACTION_MENU
      );
    } else if (testSuite) {
      displayPageTracking = DISPLAYED_PAGE_TRACKING.TEST_SUITE_TABLE;
      ActionsHelper.syncTrackingData(
        data,
        ACTIONS_TRACKING.DELETE_TEST_SUITE_TRIGGERED,
        DISPLAYED_PAGE_TRACKING.ACTION_MENU
      );
    }

    DomEventHandlers.createEvent(KATALON_EVENTS.openDeleteTestEntityDialog, { detail: { testEntities: [data], displayPageTracking, operationType: OperationType.INDIVIDUAL } });
  };

  const runHandler = () => {
    ActionsMenuHandler.runHandler(data, history);
  };

  const addToTestSuiteHandler = () => {
    if (testCase) {
      ActionsMenuHandler.addToTestSuiteHandler([{ testCase }]);
    } else {
      const displayedPageTracking = DISPLAYED_PAGE_TRACKING.TES_CASE_TABLE;
      ActionsHelper.syncTrackingData(
        data,
        ACTIONS_TRACKING.ADD_TO_TEST_SUITE_TRIGGERED,
        displayedPageTracking
      );
      DomEventHandlers.createEvent(
        KATALON_EVENTS.openAddToTestSuiteDialog,
        { detail: { testCase, displayedPageTracking } }
      );
    }
  };

  const actionWithHandler = actions.map((action: string) => {
    switch (action) {
      case ACTIONS.RUN:
        return {
          action,
          handler: runHandler,
          icon: <PlayCircleIcon />,
          divider: actions.length > 1
        };
      case ACTIONS.RENAME:
        return {
          action,
          handler: renameHandler,
          icon: <PenFieldIcon color={!isSystemTs && colors.gray9} />,
          isDisabled: isSystemTs,
          label: isSystemTs && t('block-actions-for-instant-run'),
        };
      case ACTIONS.EDIT:
        return {
          action,
          handler: editHandler,
          icon: <PenIcon color={!isSystemTs && colors.gray9} />,
          isDisabled: isSystemTs,
          label: isSystemTs && t('block-actions-for-instant-run'),
        };
      case ACTIONS.DUPLICATE:
        return {
          action,
          handler: duplicateHandler,
          icon: <CopyIcon color={!isSystemTs && colors.gray9} type="fa-solid" />,
          isDisabled: isSystemTs,
          label: isSystemTs && t('block-actions-for-instant-run'),
        };
      case ACTIONS.MOVE:
        return {
          action,
          handler: moveHandler,
          icon: <ArrowTurnDownRightIcon color={!isSystemTs && colors.gray9} />,
          isDisabled: isSystemTs,
          label: isSystemTs && t('block-actions-for-instant-run'),
        };
      case ACTIONS.DELETE:
        return {
          action,
          handler: deleteHandler,
          icon: <DeleteIcon color={!isSystemTs && colors.red5} />,
          color: !isSystemTs ? colors.red5 : undefined,
          isDisabled: isSystemTs,
          label: isSystemTs && t('block-actions-for-instant-run'),
        };
      case ACTIONS.ADD_TO_TEST_SUITE:
        return {
          action,
          handler: addToTestSuiteHandler,
          icon: <LayerPlusIcon />
        };
      default:
        return {};
    }
  });

  return (<ActionsMenu
    testEntity={data}
    anchorEl={anchorEl}
    setAnchorEl={setAnchorEl}
    actionsWithHandler={actionWithHandler}
  />);
}

TestManagementActions.propTypes = {
  data: PropTypes.object.isRequired,
  anchorEl: PropTypes.any,
  setAnchorEl: PropTypes.func.isRequired,
};

export default TestManagementActions;
