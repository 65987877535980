import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'reactstrap';

export default function CardBorder({ id, children, className }) {
  return (
    <Card
      id={id}
      className={className || 'card-border'}
    >
      {children}
    </Card>
  );
}

CardBorder.propTypes = {
  children: PropTypes.element,
  className: PropTypes.string
};

CardBorder.defaultProps = {
  className: null,
};
