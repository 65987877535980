import React from 'react';
import { Button, ButtonToolbar } from 'reactstrap';
import MContext from '../models/MContext';
import { IconRedirectActive, IconRedirectInactive, IconYellowWarning } from '../images/CustomNewIcon';
import AlertComponent from './AlertComponent';
import Routes from '../utils/Routes';
import { t } from '../i18n/t';
import { ObjectType } from '../utils/Constants';

const TestProjectCollaboratorWarning = ({ objectType }) => {
  const projectId = MContext.projectId;
  const teamId = MContext.teamId;

  const renderWarningNotCollaboratorOfTestProject = () => {
    const constructedLink = new Routes({
      projectId,
      teamId,
    });
    const userIntegrationDataTrackId = objectType === ObjectType.TEST_CASE ? 'update-git-credentials-from-publish-test-case-popup' : 'update-git-credentials-from-create-test-suite-popup';
    const gitSetUpDataTrackId = objectType === ObjectType.TEST_CASE ? 'connect-git-repo-from-publish-test-case-popup' : 'connect-git-repo-from-create-test-suite-popup';

    return (
      <div>
        {t('publish-test-case#not-collaborator')}
        <ButtonToolbar className="mt-2">
          <Button
            color="primary"
            href={constructedLink.user_integrations}
            target="_blank"
            rel="noopener noreferrer"
            data-trackId={userIntegrationDataTrackId}
          >
            {t('change-personal-git-credential')}
            <IconRedirectActive />
          </Button>
          <Button
            href={constructedLink.git_test_project_setup}
            color="secondary"
            variant="outlined"
            target="_blank"
            rel="noopener noreferrer"
            data-trackId={gitSetUpDataTrackId}
          >
            {t('connect-new-git-repo')}
            <IconRedirectInactive className="ml-2 publish-dialog__icon-redirect-inactive" />
          </Button>
        </ButtonToolbar>
      </div>
    );
  };

  return (
    <AlertComponent
      text={renderWarningNotCollaboratorOfTestProject()}
      icon={<IconYellowWarning />}
    />
  );
};

export default TestProjectCollaboratorWarning;

