import React from 'react';
import StatusBar from '../../../components/chart/status-bar/StatusBar';
import StatusConstants from '../../../utils/Statuses';
import { KeyesExecutionStatus } from '../../../utils/Constants';
import { VisualTestRunInitialData } from './Constants';

function KeyesExecutionStatusBar({ keyesExecution, ...props }) {

  const {
    status,
  } = keyesExecution;

  const checkpointStatistics = status === KeyesExecutionStatus.ANALYSING ? VisualTestRunInitialData : keyesExecution;

  const {
    totalCheckpoints = '-',
    passedCheckpoints = '-',
    failedCheckpoints = '-',
    unresolvedCheckpoints = '-'
  } = checkpointStatistics;

  const executionItems = [
    {
      label: StatusConstants.PASSED.label,
      value: passedCheckpoints,
      color: 'passed',
      key: `passed-${passedCheckpoints}`,
    },
    {
      label: StatusConstants.FAILED.label,
      value: failedCheckpoints,
      color: 'failed',
      key: `failed-${failedCheckpoints}`,
    },
    {
      label: StatusConstants.UNRESOLVED.label,
      value: unresolvedCheckpoints,
      color: 'unresolved',
      key: `unresolved-${unresolvedCheckpoints}`,
    },
  ];

  const totalItem = {
    label: 'Visual Checkpoints',
    value: totalCheckpoints,
    key: `total-${totalCheckpoints}`
  };

  return (
    <StatusBar
      dataChart={executionItems}
      dataInfo={[totalItem, ...executionItems]}
      {...props}
    />
  );
}


export default KeyesExecutionStatusBar;
