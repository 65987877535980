import React from 'react';

const ImageBrandCard = (props) => {
  const highlight = props.highLightCard === props.id ? 'highlight' : '';

  return (
    <div
      className={`checkout__card-details__supported-cards__card-brand ${highlight}`}
      title={props.title}
    >
      <img src={props.imageDisableUrl} />
      <img src={props.imageUrl} />
    </div>
  );
};

export default ImageBrandCard;
