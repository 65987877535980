import PropTypes from 'prop-types';
import React from 'react';
import { ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { IconMenu } from '../../images/KitIcons';

let currentRowId = null;

class ActionDropdownMenu extends React.Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      dropdownOpen: props.rowId === currentRowId,
    };
  }

  toggle() {
    currentRowId = this.state.dropdownOpen ? null : this.props.rowId;
    this.setState((prevState) => ({ dropdownOpen: !prevState.dropdownOpen }));
  }

  render() {
    const {
      header,
      hideHeader,
      children,
      direction,
      iconMenu,
      dropdownToggleClassName,
      disabled,
    } = this.props;

    const {
      dropdownOpen,
    } = this.state;

    return (
      <ButtonDropdown direction={direction} isOpen={dropdownOpen} toggle={this.toggle}>
        <DropdownToggle disabled={disabled} color="link" className={`btn-icon-only ${dropdownToggleClassName}`}>
          {iconMenu}
        </DropdownToggle>
        <DropdownMenu>
          {!hideHeader && <DropdownItem header>{header}</DropdownItem>}
          {children}
        </DropdownMenu>
      </ButtonDropdown>
    );
  }
}

ActionDropdownMenu.propTypes = {
  iconMenu: PropTypes.any,
  header: PropTypes.any,
  direction: PropTypes.oneOf(['up', 'down', 'left', 'right']), // direction of dropdown
  hideHeader: PropTypes.bool, // Whether to hide header
  disabled: PropTypes.bool, // Whether to disable dropdown toggle
};

ActionDropdownMenu.defaultProps = {
  header: 'Action',
  direction: 'right',
  hideHeader: false,
  iconMenu: <IconMenu />,
  disabled: false,
};

export default ActionDropdownMenu;
