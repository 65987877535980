import colors from '../../scss/colors.scss';
import ColorUtils from './ColorUtils';


const CategoryConstants = {
  APPLICATION: {
    label: 'Application',
    color: ColorUtils.transparentize(colors.blue),
  },
  AUTOMATION: {
    label: 'Automation',
    color: ColorUtils.transparentize(colors.yellow),
  },
  ENVIRONMENT: {
    label: 'Environment',
    color: ColorUtils.transparentize(colors.green),
  },
  UNKNOWN: {
    label: 'Unknown',
    color: ColorUtils.transparentize(colors.gray),
  },
};

export default CategoryConstants;
