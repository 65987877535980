import React from 'react';
import MTableColumnDataMapping from '../../components/table/models/MTableColumnDataMapping';
import { t } from '../../i18n/t';
import MContext from '../../models/MContext';
import DecoratorConstants from '../../utils/DecoratorConstants';
import Routes from '../../utils/Routes';
import { buildFilter } from '../search-query/FilterQueryHelper';
import StatusFilter, { StatusType } from '../search-query/filter/StatusFilter';
import DataTable from './DataTable';

class TestCaseExecutionDataTable extends React.Component {

  constructor(props) {
    super(props);
    this.teamId = MContext.teamId;
    this.projectId = MContext.projectId;
    this.team = MContext.team;
  }

  getTableHeader() {
    return [
      new MTableColumnDataMapping(
        t('status'),
        'lastExecutionTestCase.status',
        DecoratorConstants.statusDecorator,
        undefined,
        'fit-content-column',
      ),
      new MTableColumnDataMapping(
        t('name'),
        'name',
        (name, row) => {
          const constructedLink = new Routes({
            teamId: row.project.teamId,
            projectId: row.project.id,
            testCaseId: row.urlId,
          });
          return DecoratorConstants.idDecorator(name, row, constructedLink.execution_test_result_history_link);
        },
        undefined,
        'short-text-column',
      ),
      new MTableColumnDataMapping(
        t('last_execution'),
        'lastExecutionTestCase.startTime',
        (name, row) => {
          const constructedLink = new Routes({
            teamId: row.project.teamId,
            projectId: row.project.id,
            executionId: row.lastExecutionTestCase.executionOrder,
          });
          const lastExecutionElement = DecoratorConstants.timeDecorator(name, row);
          return DecoratorConstants.idDecoratorWrapper(
            lastExecutionElement,
            constructedLink.execution_details_link,
          );
        },
        undefined,
        'fit-content-column',
      ),
      new MTableColumnDataMapping(
        t('table-header#duration'),
        'lastExecutionTestCase.duration',
        DecoratorConstants.durationDecorator,
      ),
      new MTableColumnDataMapping(
        t('table-header#total'),
        'lastExecutionTestCase.totalTests',
        DecoratorConstants.totalTestRunDecorator,
      ),
      new MTableColumnDataMapping(
        DecoratorConstants.passedBadgeDecorator(t('table-header#totalPassed')),
        'lastExecutionTestCase.totalPassedTests',
        DecoratorConstants.passedCountDecorator,
      ),
      new MTableColumnDataMapping(
        DecoratorConstants.failedBadgeDecorator(t('table-header#totalFailed')),
        'lastExecutionTestCase.totalFailedTests',
        DecoratorConstants.failedCountDecorator,
      ),
      new MTableColumnDataMapping(
        DecoratorConstants.errorBadgeDecorator(t('table-header#totalError')),
        'lastExecutionTestCase.totalErrorTests',
        DecoratorConstants.errorCountDecorator,
      ),
      new MTableColumnDataMapping(
        DecoratorConstants.incompleteBadgeDecorator(t('table-header#totalIncomplete')),
        'lastExecutionTestCase.totalIncompleteTests',
        DecoratorConstants.incompleteCountDecorator,
      ),
      new MTableColumnDataMapping(
        DecoratorConstants.skippedBadgeDecorator(t('table-header#totalSkipped')),
        'lastExecutionTestCase.totalSkippedTests',
        DecoratorConstants.skippedCountDecorator,
      ),
      new MTableColumnDataMapping(
        t('table-header#flakiness'),
        'flakiness',
        DecoratorConstants.flakinessDecorator,
      ),
    ];
  }

  render() {
    const columnMapping = this.getTableHeader();
    const filterQuery = [
      buildFilter(StatusFilter, { id: 'status', type: StatusType.EXECUTION }),
    ];
    const newProps = {
      columnMapping,
      filterQuery,
      ...this.props
    };

    return (
      <DataTable
        title="Test Cases"
        key={this.lastChange}
        widgetId="DataTable"
        widgetType="DataTable"
        entityType="TestCaseExecution"
        tableId="TestCaseExecutions"
        useSearchQuery
        {...newProps}
      />
    );
  }
}

export default TestCaseExecutionDataTable;
