import React from 'react';
import { isEmpty } from 'lodash';
import { Form, FormGroup, Label, Card, CardBody, CardHeader } from 'reactstrap';
import TabComponent from '../../components/TabComponent';
import { t } from '../../i18n/t';
import { buildSearchCondition } from '../../components/search/SearchUtils';
import TestCaseExecutionStatus from '../../components/table/TestCaseExecutionStatus';
import TestCaseExecutionDataTable from '../../components/table/TestCaseExecutionDataTable';
import Input from '../../components/Input';
import ReleaseStatusLabel from '../../components/ReleaseStatusLabel';
import MFlags from '../../models/MFlags';
import Arrays from '../../utils/Arrays';

class TestCasesTab extends TabComponent {
  constructor(props) {
    super(props);
    this.meta.id = 'page-release-details-testcases';
  }

  renderExternalReleaseInfo() {
    const { externalRelease } = this.props.release;
    const { jiraReleaseStatus } = externalRelease;
    const hasJiraStatus = !isEmpty(jiraReleaseStatus);
    return (
      <>
        <FormGroup>
          <Label for="jira-project">Jira Project</Label>
          <Input plaintext id="jira-project">
            {externalRelease.externalProject.name}
          </Input>
        </FormGroup>
        <FormGroup>
          <Label for="jira-release">Jira Release</Label>
          <Input plaintext id="jira-release">
            <div className="d-flex">
              <a href={externalRelease.webUrl} target="_blank" rel="noopener noreferrer">
                {externalRelease.name}
              </a>
              {hasJiraStatus && (
                <ReleaseStatusLabel jiraReleaseStatus={jiraReleaseStatus} title={`Jira: ${externalRelease.name}`} />
              )}
            </div>
          </Input>
        </FormGroup>
      </>
    );
  }

  renderReleaseInfo() {
    const { release } = this.props;
    const { externalRelease } = release;
    return (
      <Card>
        <CardHeader>Summary</CardHeader>
        <CardBody>
          <Form className="m-0">
            <FormGroup>
              <Label for="description">Description</Label>
              <Input plaintext id="description">
                {release.description}
              </Input>
            </FormGroup>
            {externalRelease && !isEmpty(externalRelease) && this.renderExternalReleaseInfo()}
          </Form>
        </CardBody>
      </Card>
    );
  }

  renderTestCase() {
    const { release } = this.props;
    const defaultSearchConditions = [
      ...Arrays.insertIf(!MFlags.fixReleaseExecutionStatusBarEnabled, buildSearchCondition('time', '=', 2)),
      buildSearchCondition('releaseId', '=', release.id),
    ];

    return (
      <>
        <TestCaseExecutionStatus
          title={t('test_cases')}
          defaultSearchConditions={defaultSearchConditions}
          useStatusBarV2
        />
        {this.renderReleaseInfo()}
        <TestCaseExecutionDataTable
          title={t('test_cases')}
          defaultSearchConditions={defaultSearchConditions}
          disableFilterButton
          useSearchQuery
        />
      </>
    );
  }

  render() {
    return this.renderTestCase();
  }
}

export default TestCasesTab;
