import React, { ReactNode, useState } from 'react';
import { Link } from '@mui/material';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import Services from '../../../utils/Services';
import { Failure } from '../../../models/model/Failure';
import AIBlock from './AIBlock';
import { VIRTUAL_DATA_QUESTIONS } from '../../../utils/Constants';
import MContext from '../../../models/MContext';
import { t } from '../../../i18n/t';
import Helper from '../../../utils/Helper';
import GroupEvent from '../../../utils/track/GroupEvent';
import { sendAnalyticEventForAction } from '../../../utils/SegmentAnalytics';

interface CommonFailureAIBlockProps {
  row: Failure;
  totalImpactedTests: number;
  totalErrorCount: number,
  enableAi: boolean,
}

function CommonFailureAIBlock(props: CommonFailureAIBlockProps) {
  const { row, totalImpactedTests, totalErrorCount, enableAi } = props;
  const defaultText = `This errors happened in ${Helper.calculateThePercentage(row.totalImpactedTests, totalImpactedTests)}% `
   + `of failed test cases (${row.totalImpactedTests} out of ${totalImpactedTests} test cases) executed in last 7 days.`;
  const [text, setText] = useState<string | undefined>(defaultText);
  const [isDefaultState, setIsDefaultState] = useState<boolean>(true);

  const sendGenerateTrackEvent = () => {
    sendAnalyticEventForAction(
      'ra_ai_generate_ai_block_clicked',
      { 'data-groupid': GroupEvent.VIRTUAL_DATA_ANALYST, question: VIRTUAL_DATA_QUESTIONS.COMMON_FAILURES.dataTrackId }
    );
  };

  const defaultGenerateButton = () => (
    <span className="generate-insights-button">
      <AutoAwesomeIcon className="icon" />
      {/* eslint-disable-next-line @typescript-eslint/no-use-before-define */}
      <Link className="button" component="button" onClick={() => handleGenerateAction()}>{t('generate-ai-insights')}</Link>
    </span>
  );

  const [generateButton, setGenerateButton] = useState<ReactNode | undefined>(defaultGenerateButton);

  const generateAction = () => {
    setGenerateButton(undefined);
    setText(undefined);
    setIsDefaultState(false);
    const commonFailuresResource = {
      totalImpactedTests,
      totalErrorCount,
      failures: [row]
    };

    Services.getAICompletions(commonFailuresResource, VIRTUAL_DATA_QUESTIONS.COMMON_FAILURES.promptType, MContext.projectId)
      .then((response) => {
        setText(response.completions);
      });
  };

  const handleGenerateAction = () => {
    generateAction();
    sendGenerateTrackEvent();
  };

  const refresh = () => {
    generateAction();
    sendAnalyticEventForAction(
      'ra_ai_refresh_ai_block_clicked',
      { 'data-groupid': GroupEvent.VIRTUAL_DATA_ANALYST, question: VIRTUAL_DATA_QUESTIONS.COMMON_FAILURES.dataTrackId }
    );
  };

  return (
    <AIBlock
      enableAi={enableAi}
      text={text}
      generateButton={generateButton}
      isDefaultState={isDefaultState}
      refresh={refresh}
      questionId={VIRTUAL_DATA_QUESTIONS.COMMON_FAILURES.dataTrackId}
    />
  );
}

export default CommonFailureAIBlock;
