import React, { Component } from 'react';
import { PieChart, Pie, Legend, Cell, ResponsiveContainer } from 'recharts';
import DataLoader from '../table/DataLoader';
import { buildSearchCondition } from '../search/SearchUtils';
import { t } from '../../i18n/t';

const COLORS = {
  ALL_PASSED: '#6ACC95',
  ALL_FAILED: '#FF9B85',
  NEW_PASSED: '#00C758',
  NEW_FAILED: '#F66D4E'
};
class TestCaseExecutionComparisonPieChart extends Component {

  constructor(props) {
    super(props);
    this.renderData = this.renderData.bind(this);
    this.legendFormatter = this.legendFormatter.bind(this);
  }

  legendFormatter(value, entry) {
    return t(value.toLowerCase());
  }

  renderData(data) {
    return (
      <ResponsiveContainer width="90%" height={300}>
        <PieChart>
          <Pie data={data} dataKey="count" label>
            {data.map((entry, index) => <Cell name={entry.status} key={`cell-${index}`} fill={COLORS[entry.status]} />)}
          </Pie>
          <Legend formatter={this.legendFormatter} verticalAlign="bottom" height={36} />
        </PieChart>
      </ResponsiveContainer>
    );

  }

  render() {
    const { executionOrders, ...rest } = this.props;

    return (
      <DataLoader
        tableId="TestCaseExecutionComparisonStatistics"
        entityType="TestCaseExecutionComparisonStatistics"
        render={this.renderData}
        defaultSearchConditions={[
          buildSearchCondition('Execution.order', '=', executionOrders.toString())
        ]}
        {...rest}
      />

    );
  }
}

export default TestCaseExecutionComparisonPieChart;
