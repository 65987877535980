import { Typography } from '@mui/material';
import React, { Component } from 'react';
import { buildSearchCondition } from '../../search/SearchUtils';
import SearchableFilter from './SearchableFilter';
import {SearchEntity} from '../../../utils/Constants';


class ReleaseFilter extends Component {

  render() {
    const buildSearchConditions = (searchInput) => ([
      buildSearchCondition('name', 'contains', searchInput || ''),
    ]);

    const renderOption = (option) => (
      <Typography noWrap>{option.name}</Typography>
    );
    return (
      <SearchableFilter
        entityType={SearchEntity.Release}
        buildSearchConditions={buildSearchConditions}
        label="Release"
        searchKey="id"
        placeHolder="Filter releases"
        renderOption={renderOption}
        {...this.props}
      />
    );
  }
}

export default ReleaseFilter;
