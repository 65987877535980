import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { InputGroup, InputGroupAddon, InputGroupText, Spinner } from 'reactstrap';
import { IconSearch } from '../../../images/KitIcons';
import Services from '../../../utils/Services';
import Input from '../../Input';
import DropDownFilter from './DropDownFilter';

class SearchableFilter extends Component {

  constructor(props) {
    super(props);
    this.search = this.search.bind(this);
    this.debounceSearch = _.debounce(this.search, 100);
    this.onOpen = this.onOpen.bind(this);
    this.onInputSearch = this.onInputSearch.bind(this);
    this.stopPropagation = this.stopPropagation.bind(this);
    this.clear = this.clear.bind(this);
    this.filterMenu = React.createRef();
    this.state = {
      content: null,
      searchInput: null,
      loading: false,
    };
  }

  search() {
    const { searchInput } = this.state;
    const { entityType, buildSearchConditions, sorts, groupBys, dataMapping } = this.props;
    this.setState({ loading: true });
    const params = {
      pagination: {
        page: 0,
        size: 100,
        sorts,
      },
      conditions: buildSearchConditions(searchInput),
      type: entityType,
      groupBys
    };

    Services.search(params)
      .then(({ content }) => {
        const data = dataMapping(content);
        this.setState({
          loading: false,
          content: data,
        });
      });
  }

  onOpen() {
    const { content } = this.state;
    if (!content) this.search();
  }

  onInputSearch(event) {
    const searchInput = event.target.value;
    this.setState(
      { searchInput },
      this.debounceSearch
    );
  }

  /**
   * Stop propagate key down event for character key.
   * Menu item has handler catch key down event to focus item with first letter matches entered key.
   * This makes onChange of input is not fired.
   * e.g. type "a" in input => menu item "abc" is focused instead of updating input box.
   */
  stopPropagation(event) {
    switch (event.key) {
      case 'ArrowDown':
      case 'ArrowUp':
      case 'Home':
      case 'End':
        break;
      default:
        event.stopPropagation();
    }
  }

  clear() {
    this.filterMenu.current.resetSelectedOption();
    this.state = {
      content: null,
      searchInput: null,
    };
  }

  renderSearchInput() {
    const { label, placeHolder } = this.props;
    const { searchInput, loading } = this.state;
    const defaultPlaceHolder = `Filter ${label.toLowerCase()}s`;
    return (
      <InputGroup className="search-filter sticky-top">
        <Input
          className="text-dark bg-white"
          autoFocus
          onChange={this.onInputSearch}
          onKeyDown={this.stopPropagation}
          value={searchInput || ''}
          placeholder={placeHolder || defaultPlaceHolder}
        />
        <InputGroupAddon addonType="append">
          <InputGroupText>
            {loading ? <Spinner size="sm" /> : <IconSearch />}
          </InputGroupText>
        </InputGroupAddon>
      </InputGroup>
    );
  }

  renderNoMatches() {
    return (
      <div className="mt-2 ml-3">No results</div>
    );
  }

  render() {
    const { content } = this.state;
    const { entityType, buildSearchConditions, ...rest } = this.props;
    const newProps = {
      onOpen: this.onOpen,
      ...rest
    };
    return (
      <DropDownFilter
        ref={this.filterMenu}
        options={content}
        {...newProps}
      >
        {this.renderSearchInput()}
        {content && content.length === 0 && this.renderNoMatches()}
      </DropDownFilter>
    );
  }
}

export default SearchableFilter;


SearchableFilter.propTypes = {
  entityType: PropTypes.string,
  placeHolder: PropTypes.string,
  buildSearchConditions: PropTypes.func,
  sorts: PropTypes.array,
  groupBys: PropTypes.array,
  dataMapping: PropTypes.func,
};

SearchableFilter.defaultProps = {
  sorts: ['id,asc'],
  groupBys: [],
  dataMapping: (data) => data,
};
