import React from 'react';
import { next } from '../../../utils/Count';
import DataLoader from '../../../components/table/DataLoader';
import NormalCard from '../../../components/card/NormalCard';
import { buildSearchCondition, buildSearchFunction } from '../../../components/search/SearchUtils';
import { SearchEntity } from '../../../utils/Constants';
import Time from '../../../utils/Moment';
import TooltipComponent from '../../../components/TooltipComponent';
import { t } from '../../../i18n/t';
import ReportHelper from '../../../utils/ReportHelper';
import TestCasesCreatedChart from '../../../components/chart/TestCasesCreatedChart';
import MFlags from '../../../models/MFlags';

const TestCasesCreated = ({ startTime, endTime, statisticType, dataLoaderRef }) => {

  const timeUnit = ReportHelper.getStatisticsType(statisticType);

  const renderHeader = () => (
    <div className="overview-header-sub-title">
      {t('project-dashboard#test-cases-created')}
      <TooltipComponent text={t('project-dashboard#test-cases-created#tooltip')} placement="bottom-end" arrow />
    </div>
  );

  const buildChartData = (rawData) => {
    const formatedData = rawData.map((d) => d.content);
    // Convert startTime from filter to get the first day by unit(day, week, month)
    const startTimeByUnit = Time.startOf(startTime, timeUnit);
    const times = Time.timeBetween(startTimeByUnit, endTime, `${timeUnit}s`, Time.LOCAL_DATE_FORMAT);
    const chartData = times.map((t) => {
      const data = formatedData.find((d) => d.time === t);
      return data || {
        time: t,
        totalTestCases: 0
      };
    });
    return chartData;
  };

  const renderChart = (data) => {
    const chartData = buildChartData(data);
    return (
      <TestCasesCreatedChart
        data={chartData}
      />
    );
  };

  const renderBody = () => {
    const groupFunc = `group_by_${timeUnit}`;
    const defaultSearchConditions = [
      buildSearchCondition('createdAt', '>=', startTime),
      buildSearchCondition('createdAt', '<=', endTime)
    ];
    return (
      <DataLoader
        title={renderHeader()}
        key={next()}
        ref={dataLoaderRef}
        entityType={SearchEntity.TestCase}
        defaultSearchConditions={defaultSearchConditions}
        defaultSearchFunctions={[
          buildSearchFunction('totalTestCases', 'count', ['id']),
          buildSearchFunction('time', groupFunc, ['createdAt']),
        ]}
        defaultSort={[
          'totalTestCases, asc',
        ]}
        render={renderChart}
        cardClassName="project-dashboard-card-border shadow-none"
        useCache={!MFlags.testPerformanceForReportAndAnalyticsEnabled}
        useRefreshButton={false}
        useCollapseButton={false}
        headerComponent={NormalCard}
        bodyComponent={NormalCard}
        showEmptyMessage
        pageSize={300}
      />
    );
  };

  return (
    <>
      { renderBody() }
    </>
  );

};

export default TestCasesCreated;
