import React from 'react';
import { Col, Form, FormGroup, FormText, Label, Row } from 'reactstrap';
import { isEmpty } from 'lodash';
import { t } from '../../i18n/t';
import RouteConstants from '../../utils/RouteConstants';
import Services from '../../utils/Services';
import Stepper from '../../components/wizard/Stepper';
import MConfigs from '../../models/MConfigs';
import MContext from '../../models/MContext';
import Input from '../../components/Input';
import Select from '../../components/Select';
import CodeBox from '../../components/CodeBox';
import Arrays from '../../utils/Arrays';
import { dockerType, Processor } from '../../utils/Constants';
import MAuth from '../../models/MAuth';
import Link from '../../components/Link';
import MFlags from '../../models/MFlags';
import Routes from '../../utils/Routes';
import SelectApiKeyComponent from '../../components/SelectApiKeyComponent';

const DEFAULT_AGENT_NAME = 'my_docker_agent';
const AGENT_SETUP_MODE = [
  {
    label: t('docker-compose'),
    value: dockerType.DOCKER_COMPOSE,
  },
  {
    label: t('docker-cli'),
    value: dockerType.DOCKER_COMMAND,
  }
];

class AgentSetupDocker extends React.Component {

  constructor(props) {
    super(props);
    this.teamId = MContext.teamId;
    this.organizationId = MContext.organizationId;
    this.user = MAuth.user;

    this.state = {
      apiKeys: [],
      apiKey: '',
      agentName: DEFAULT_AGENT_NAME,
      config: '',
      command: '',
      mode: AGENT_SETUP_MODE[0],
      lastAccessedProject: null,
    };

    this.handleSelectApiKey = this.handleSelectApiKey.bind(this);
    this.handleChangeText = this.handleChangeText.bind(this);
    this.handleChangeMode = this.handleChangeMode.bind(this);
  }

  componentDidMount() {
    const { moveAgentToOrgLevelPhase2Enabled, searchApiKeyEnabled } = MFlags;
    if (!searchApiKeyEnabled) {
      this.getApiKeys();
    }
    if (moveAgentToOrgLevelPhase2Enabled) {
      this.getLastAccessedProject();
    }
  }

  getLastAccessedProject() {
    Services.getLastAccessedProject(this.user.id, this.organizationId)
      .then((lastAccessedProject) => {
        if (!isEmpty(lastAccessedProject)) {
          this.setState({
            lastAccessedProject: lastAccessedProject[0]
          });
        }
      });
  }

  generateConfig() {
    const { agentName, apiKey } = this.state;

    const dockerAgentTag = MFlags.moveAgentToOrgLevelPhase2Enabled ? 'latest' : '1.7.8';
    const scopeConfig = !MFlags.moveAgentToOrgLevelPhase2Enabled ? `TEAM_ID: ${this.teamId}` : `ORGANIZATION_ID: ${this.organizationId}`;
    const scopeCommand = !MFlags.moveAgentToOrgLevelPhase2Enabled ? `TEAM_ID=${this.teamId}` : `ORGANIZATION_ID=${this.organizationId}`;
    const platformConfig = MFlags.abilityToSelectProcessorEnabled ? `    platform: ${Processor.LINUX_AMD64}\n` : '';
    const platformCommand = MFlags.abilityToSelectProcessorEnabled ? ` --platform ${Processor.LINUX_AMD64}` : '';

    const config =
      'version: "3.2"\n' +
      'services:\n' +
      '  katalon-agent:\n' +
      `${platformConfig}` +
      `    image: "katalonstudio/agent:${dockerAgentTag}"\n` +
      '    restart: always\n' +
      '    deploy:\n' +
      '      mode: replicated\n' +
      '      replicas: 1\n' +
      '    environment:\n' +
      `      SERVER_URL: ${MConfigs.serverUrl}\n` +
      `      KATALON_USERNAME: ${this.user.email}\n` +
      `      AGENT_NAME: "${agentName}"\n` +
      `      ${scopeConfig}\n` +
      `      KATALON_API_KEY: ${apiKey.value}\n` +
      '      AUTO_UPGRADE_ENVIRONMENT: true\n' +
      '    volumes:\n' +
      '      - katalon-runtime-engines:/root/.katalon\n' +
      '\n' +
      'volumes:\n' +
      '  katalon-runtime-engines:';

    const command =
      'docker run -t -d --restart always' +
      `${platformCommand}` +
      ' --mount source=katalon-runtime-engines,target=/root/.katalon' +
      ` -e SERVER_URL=${MConfigs.serverUrl}` +
      ` -e KATALON_USERNAME=${this.user.email}` +
      ` -e AGENT_NAME=${agentName}` +
      ` -e ${scopeCommand}` +
      ` -e KATALON_API_KEY=${apiKey.value}` +
      ' -e AUTO_UPGRADE_ENVIRONMENT=true' +
      ` katalonstudio/agent:${dockerAgentTag}`;

    this.setState({ config, command });
  }

  getApiKeys() {
    Services.getApiKeys()
      .then((response) => {
        const apiKeys = response.map((apikey) => ({
          value: apikey.key,
          label: apikey.name,
        }));

        this.setState({
          apiKeys,
          apiKey: apiKeys[0],
        }, () => {
          this.generateConfig();
        });
      });
  }

  handleSelectApiKey(apiKey) {
    this.setState({ apiKey }, () => {
      this.generateConfig();
    });
  }

  handleChangeMode(mode) {
    this.setState({ mode }, () => {
      this.generateConfig();
    });
  }

  handleChangeText(event) {
    const value = event.target.value;
    const name = event.target.name;
    this.setState(
      { [name]: value },
      () => {
        this.generateConfig();
      }
    );
  }

  renderAgentConfiguration() {
    const { searchApiKeyEnabled } = MFlags;
    const { agentName, apiKey, apiKeys } = this.state;
    return (
      <Row>
        <Col sm="12" md="8" lg="6" xl="5">
          <FormGroup>
            <Label for="agentName">{t('agent#agent-name')}</Label>
            <Input
              type="text"
              id="agentName"
              name="agentName"
              value={agentName}
              onChange={this.handleChangeText}
            />
          </FormGroup>
          <FormGroup>
            <Label for="apiKey">{t('agent#api-key')}</Label>
            {searchApiKeyEnabled ?
              <SelectApiKeyComponent onChange={this.handleSelectApiKey} />
              :
              <Select
                clearable={false}
                id="apiKey"
                value={apiKey}
                onChange={this.handleSelectApiKey}
                options={apiKeys}
              />}
            <FormText color="muted">
              {t('agent#generate-api-key')}
              <a
                href={RouteConstants.apikey}
              >
                here.
              </a>
            </FormText>
          </FormGroup>
        </Col>
      </Row>
    );
  }

  renderDockerFileConfig() {
    const { config } = this.state;
    return (
      <FormGroup>
        <CodeBox
          header="docker-compose.yml"
          content={config}
        />
      </FormGroup>
    );
  }

  renderDockerCommandConfig() {
    const { command } = this.state;
    return (
      <FormGroup>
        <CodeBox
          content={command}
        />
      </FormGroup>
    );
  }

  renderStartDocker() {
    const startDocker = 'docker-compose up -d';
    return (
      <FormGroup>
        <CodeBox
          content={startDocker}
        />
      </FormGroup>
    );
  }

  renderSelectMode() {
    const { mode } = this.state;
    return (
      <Row>
        <Col sm="12" md="8" lg="6" xl="5">
          <FormGroup>
            <Label className="normal-label" for="start-with">{t('docker-start-with')}</Label>
            <Select
              clearable={false}
              id="start-with"
              value={mode}
              onChange={this.handleChangeMode}
              options={AGENT_SETUP_MODE}
            />
          </FormGroup>
        </Col>
      </Row>
    );
  }

  renderAssignToProjects() {
    const routes = new Routes();
    return (
      <span>After the agent successfully starts, navigate to the <Link href={routes.organization_setting_agent_link}>Test Environments</Link> and assign the agent to your projects.</span>
    );
  }

  renderCreatePlan() {
    const { lastAccessedProject } = this.state;
    let link;

    if (!lastAccessedProject) {
      const routes = new Routes();
      link = routes.organization_home_link;
    } else {
      const { id: projectId, teamId } = lastAccessedProject;
      const routes = new Routes({ teamId, projectId });
      link = routes.smart_test_scheduling_link();
    }
    return (
      <span>Once the agent has been successfully assigned to your projects, it&apos;s time to <a href={link}>schedule your test runs</a>.</span>
    );
  }

  renderStepToSetup() {
    const { moveAgentToOrgLevelPhase2Enabled } = MFlags;
    const { mode } = this.state;
    const steps = [
      {
        label: t('agent#step#config'),
        content: this.renderAgentConfiguration(),
        description: 'This information will be displayed in the agent command.',
      },
      {
        label: t('select-method'),
        content: this.renderSelectMode()
      },
      ...Arrays.insertIf(mode.value === dockerType.DOCKER_COMPOSE, {
        label: t('agent#step#configCommand'),
        content: this.renderDockerFileConfig(),
        description: 'Copy & paste this config to your docker file.',
      }, {
        label: t('agent#step#startCommand'),
        content: this.renderStartDocker(),
        description: 'Use this command to start the docker.',
      }),
      ...Arrays.insertIf(mode.value === dockerType.DOCKER_COMMAND, {
        label: t('agent#step#startCommand'),
        content: this.renderDockerCommandConfig(),
        description: 'Copy & paste this command to your Command-line Interface.',
      }),
      ...Arrays.insertIf(
        moveAgentToOrgLevelPhase2Enabled,
        {
          label: t('agent#step#assign-to-project'),
          content: this.renderAssignToProjects(),
        },
        {
          label: moveAgentToOrgLevelPhase2Enabled ? t('agent#step#schedule-test-runs') : t('agent#step#create-plan'),
          content: this.renderCreatePlan(),
        }
      )
    ];

    return (
      <Form>
        <Stepper
          id="agent-docker-setup"
          data-trackid="agent-docker-setup"
          expanded
          steps={steps}
        />
      </Form>
    );
  }

  render() {
    return this.renderStepToSetup();
  }
}

export default AgentSetupDocker;
