import React from 'react';
import PropTypes from 'prop-types';
import Avatar from 'react-avatar';
import { IconUser } from '../../images/CustomIcon';


export default class ImageProfile extends React.Component {

  generateAvatar(name) {
    const { diameter, color, maxInitials } = this.props;
    return (
      <Avatar
        className="rounded-circle"
        size={diameter}
        round={`${diameter}px`}
        name={name}
        color={color}
        maxInitials={maxInitials}
      />
    );
  }

  renderImage(imgUrl) {
    const { name, diameter } = this.props;
    return (
      <img
        width={diameter}
        height={diameter}
        className="rounded-circle"
        src={imgUrl}
        title={name}
        alt={name}
      />
    );
  }

  renderImageProfile() {
    const { imgUrl, name } = this.props;
    return (
      <div className="avatar">
        {imgUrl && this.renderImage(imgUrl)}
        {!imgUrl && name && this.generateAvatar(name)}
        {!imgUrl && !name && <IconUser />}
      </div>
    );
  }

  render() {
    return this.renderImageProfile();
  }
}

ImageProfile.propTypes = {
  imgUrl: PropTypes.string,
  name: PropTypes.string,
  diameter: PropTypes.number,
  color: PropTypes.string,
  maxInitials: PropTypes.number,
};

ImageProfile.defaultProps = {
  imgUrl: null,
  name: null,
  diameter: 144,
  color: null,
  maxInitials: 2,
};
