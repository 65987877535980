import React from 'react';
import { Button, Form, FormGroup, Label } from 'reactstrap';
import countryList from 'country-list';
import { t } from '../../i18n/t';
import Input from '../../components/Input';
import Select from '../../components/Select';
import http from '../../utils/http';
import Apis from '../../utils/Apis';
import MContext from '../../models/MContext';
import Notification from '../../utils/Notification';


export default class BillingInformationForm extends React.Component {
  get hasChanged() {
    return Object.entries(this.state || {})
      .some(([name, value]) => value !== this.originalBillingInfo[name]);
  }

  constructor(props) {
    super(props);
    this.initState();
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleCountryChange = this.handleCountryChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.isReady = this.isReady.bind(this);
    this.organizationId = MContext.organizationId;
    this.countryOptions = countryList.getData()
      .map((country) => ({
        value: country.code,
        label: country.name
      }));
    this.submitBtnRef = React.createRef();
  }

  initState() {
    this.state = this.props.billingInfo;
    this.originalBillingInfo = this.state || {};
  }

  setBillingInfo(billingInfo) {
    this.originalBillingInfo = billingInfo || {};
    this.setState(billingInfo);
  }

  dispatchOnChangeEvent() {
    if (this.props.onChange) {
      this.props.onChange();
    }
  }

  handleInputChange(event) {
    const { target: { name, value } } = event;
    this.setState({
      [name]: value
    }, () => {
      this.dispatchOnChangeEvent();
    });
  }

  handleCountryChange(country) {
    this.setState({
      country: country && country.value
    }, () => {
      this.dispatchOnChangeEvent();
    });
  }

  triggerSubmit() {
    this.submitBtnRef.current.click();
  }

  handleSubmit(event) {
    if (event) {
      event.preventDefault();
    }
    http.post(Apis.billingInformation(this.organizationId), this.state)
      .then((billingInformation) => {
        Notification.pushSuccess('', 'The Contact Information has been saved successfully!');
        if (this.props.onUpdated) {
          this.props.onUpdated(event);
        }
        this.setBillingInfo(billingInformation);
      });
  }

  isReady() {
    if (this.state === null) {
      return false;
    }
    const {
      address1,
      city,
      state,
      country,
      postalCode,
      fullBusinessName
    } = this?.state;
    return address1 && city && state && country && postalCode && fullBusinessName;
  }

  render() {
    const {
      address1 = '', address2 = '',
      city = '', state = '', country = '', postalCode = '',
      vatNumber = '', fullBusinessName = '', ccEmails = ''
    } = this.state || {};
    const {
      title = t('payment#billing_information'),
      submitLabel = t('payment#billing_information@save_changes_button')
    } = this.props;

    return (
      <Form
        data-trackid="update-billing-information"
        onSubmit={this.handleSubmit}
        target="_blank"
      >
        {title && (
          <FormGroup>
            <div className="checkout__title">{title}</div>
          </FormGroup>
        )}
        <FormGroup>
          <Label for="billing_info-cc-emails">{t('payment#billing_information@ccEmails')}</Label>
          <Input
            type="text"
            id="billing_info-cc-emails"
            name="ccEmails"
            value={ccEmails}
            placeholder={t('payment#billing_information@ccEmails')}
            onChange={this.handleInputChange}
          />
        </FormGroup>
        <FormGroup>
          <Label for="billing_info-address1" className="required">
            {t('payment#billing_information@address1')}
          </Label>
          <Input
            type="text"
            id="billing_info-address1"
            name="address1"
            value={address1}
            placeholder={t('payment#billing_information@address1')}
            onChange={this.handleInputChange}
            required
          />
        </FormGroup>
        <FormGroup>
          <Label for="billing_info-address2">
            {t('payment#billing_information@address2')}
          </Label>
          <Input
            type="text"
            id="billing_info-address2"
            name="address2"
            value={address2}
            placeholder={t('payment#billing_information@address2')}
            onChange={this.handleInputChange}
          />
        </FormGroup>
        <FormGroup>
          <Label for="billing_info-city" className="required">
            {t('payment#billing_information@city')}
          </Label>
          <Input
            type="text"
            id="billing_info-city"
            name="city"
            value={city}
            placeholder={t('payment#billing_information@city')}
            onChange={this.handleInputChange}
            required
          />
        </FormGroup>
        <FormGroup>
          <Label for="billing_info-state" className="required">
            {t('payment#billing_information@state')}
          </Label>
          <Input
            type="text"
            id="billing_info-state"
            name="state"
            value={state}
            placeholder={t('payment#billing_information@state')}
            onChange={this.handleInputChange}
            required
          />
        </FormGroup>
        <FormGroup>
          <Label for="billing_info-country" className="required">
            {t('payment#billing_information@country')}
          </Label>
          <Select
            name="country"
            options={this.countryOptions}
            placeholder={t('payment#billing_information@country')}
            value={country}
            onChange={this.handleCountryChange}
            required
          />
        </FormGroup>
        <FormGroup>
          <Label for="billing_postal-code" className="required">
            {t('payment#billing_information@postal_code')}
          </Label>
          <Input
            type="text"
            id="billing_postal-code"
            name="postalCode"
            value={postalCode}
            placeholder={t('payment#billing_information@postal_code')}
            onChange={this.handleInputChange}
            required
          />
        </FormGroup>
        <FormGroup>
          <Label for="billing_info-vat_number">{t('payment#billing_information@vat_number')}</Label>
          <Input
            type="text"
            id="billing_info-vat_number"
            name="vatNumber"
            value={vatNumber}
            placeholder={t('payment#billing_information@vat_number')}
            onChange={this.handleInputChange}
          />
        </FormGroup>
        <FormGroup>
          <Label for="billing_info-full_business_name" className="required">
            {t('payment#billing_information@full_business_name')}
          </Label>
          <Input
            type="text"
            id="billing_info-full_business_name"
            name="fullBusinessName"
            value={fullBusinessName}
            placeholder={t('payment#billing_information@full_business_name')}
            onChange={this.handleInputChange}
            required
          />
        </FormGroup>
        <FormGroup className="mt-4">
          <Button hidden={!submitLabel} innerRef={this.submitBtnRef} color="primary" disabled={!this.hasChanged}>
            {submitLabel}
          </Button>
        </FormGroup>
      </Form>
    );
  }
}
