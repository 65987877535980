import React, { useState, useEffect } from 'react';
import { FormGroup, Label } from 'reactstrap';
import { isEmpty, throttle } from 'lodash';
import { t } from '../../../i18n/t';
import SelectRepository from '../SelectRepository';
import { ObjectType, SearchEntity, TestProjectType } from '../../../utils/Constants';
import { buildSearchCondition } from '../../search/SearchUtils';
import Services from '../../../utils/Services';
import AlertComponent from '../../AlertComponent';
import { IconErrorXMark, IconYellowWarning } from '../../../images/CustomNewIcon';
import Input from '../../Input';
import SelectG5Location from '../../treeview/SelectG5Location';
import MFlags from '../../../models/MFlags';
import ConvertDataHelper from '../../../utils/ConvertDataHelper';

const PublishTestSuiteLocationStep = (props) => {
  const { testProject, setTestProject, testSuiteName, setTestSuiteName, testSuitePath, setTestSuitePath, setDisablePublish } = props;
  const [isDuplicated, setDuplicated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [hasWritePermission, setHasWritePermission] = useState(true);
  const [showAlert, setShowAlert] = useState(false);
  const [isExcludedFolderPath, setIsExcludedFolderPath] = useState(false);

  const { removeGitSupportForCloudStudioEnabled } = MFlags;

  const onChangeExcludedPathsAlert = (isExcludedFolderPath) => {
    setIsExcludedFolderPath(isExcludedFolderPath);
    setTestSuitePath('');
    setDisablePublish(isExcludedFolderPath);
  };

  const onChangeShowAlert = (isShowAlert) => {
    setShowAlert(isShowAlert);
    setDisablePublish(isShowAlert);
  };

  const validateTestSuiteNameAndPath = () => {
    setDuplicated(false);
    if (testProject.id) {
      const params = {
        pagination: {
          page: 0,
          size: 1,
          sorts: ['id,desc']
        },
        conditions: [
          buildSearchCondition('name', '=', testSuiteName),
          buildSearchCondition('path', '=', testSuitePath),
          buildSearchCondition('TestProject.id', '=', testProject.id),
        ],
        type: SearchEntity.TestSuite,
      };
      Services.search(params)
        .then(({ content }) => {
          setDuplicated(!isEmpty(content));
        });
    }
  };

  const throttledGetTestSuiteByNameAndTestProjectId = throttle(validateTestSuiteNameAndPath, 1000);

  useEffect(() => {
    throttledGetTestSuiteByNameAndTestProjectId();
    if (testSuiteName && testSuitePath && testProject) {
      setDisablePublish(false);
    } else {
      setDisablePublish(true);
    }
  }, [testSuiteName, testSuitePath, testProject]);


  useEffect(() => {
    setTestSuitePath('');
    setHasWritePermission(true);
  }, [testProject]);

  const getCloudTestProject = () => {
    Services.getTestProjectByType(TestProjectType.CLOUD)
      .then((testProjects) => {
        setTestProject(testProjects[0]);
      });
  };

  useEffect(() => {
    if (removeGitSupportForCloudStudioEnabled) {
      getCloudTestProject();
    }
  }, []);

  const onChangeTestSuiteName = (event) => {
    setTestSuiteName(event.target.value);
  };

  const renderWarningTestSuiteName = () => (
    <div>
      {t('publish-test-suite#test-suite-name-existing#title')}
      <li>{t('publish-test-suite#test-suite-name-existing#option1')}</li>
      <li>{t('publish-test-suite#test-suite-name-existing#option2')}</li>
    </div>
  );

  const renderInputTestSuiteName = () => (
    <FormGroup>
      <Label for={t('publish-test-suite#test-suite-name')}>{t('publish-test-suite#test-suite-name')}</Label>
      <Input
        required
        className="publish-dialog__text"
        type="text"
        value={testSuiteName}
        onChange={onChangeTestSuiteName}
        placeholder={t('enter-name')}
      />
      {isDuplicated &&
        <AlertComponent
          text={renderWarningTestSuiteName()}
          icon={<IconYellowWarning />}
        />}
    </FormGroup>
  );

  const renderSelectRepository = () => {
    if (removeGitSupportForCloudStudioEnabled) {
      const icon = ConvertDataHelper.renderRepositoryIcon(testProject.type);
      const testProjectTitle = ConvertDataHelper.buildIconTitle(testProject.name, icon);
      return (
        <>
          <Label>{t('repository')}</Label>
          <div className="mb-3">{testProjectTitle}</div>
        </>
      );
    }

    return (
      <SelectRepository
        testProject={testProject}
        setTestProject={setTestProject}
        setIsLoading={setIsLoading}
        hasWritePermission={hasWritePermission}
        setHasWritePermission={setHasWritePermission}
        objectType={ObjectType.EXECUTION_TEST_SUITE}
      />
    );
  };

  return (
    <>
      {renderInputTestSuiteName()}
      {renderSelectRepository()}
      {!isLoading && hasWritePermission && (
        <FormGroup>
          <SelectG5Location
            publishEntity={SearchEntity.TestSuite}
            key={testProject.id}
            testProjectId={testProject.id}
            path={testSuitePath}
            onSelectFolder={setTestSuitePath}
            showAlert={onChangeShowAlert}
            showExcludedPathsAlert={onChangeExcludedPathsAlert}
            excludedFolderPaths={['test-suites/TestOps']}
          />
          {showAlert &&
          <AlertComponent
            text={t('invalid-folder-name')}
            icon={<IconErrorXMark />}
            className="mt-2 error"
          />}
          {MFlags.preventNamingTestOpsInTestSuiteFoldersEnabled && isExcludedFolderPath &&
            <AlertComponent
              text={t('invalid-folder-name#specific-name')}
              icon={<IconErrorXMark />}
              className="mt-2 error"
            />}
        </FormGroup>
      )}
    </>
  );
};

export default PublishTestSuiteLocationStep;
