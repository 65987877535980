import React, { MouseEventHandler } from 'react';
import { DialogContent, DialogContentText, DialogActions, Stack, ThemeProvider } from '@mui/material';
import Button from '@katalon-studio/katalon-ui/Button';
import Icon from '@katalon-studio/katalon-ui/Icon';
import { t } from '../../../i18n/t';
import CloseableDialogComponent from '../../../components/dialog/CloseableDialogComponent';
import KatalonRounded from '../../../../images/integrations/katalon-rounded.svg';
import JiraRounded from '../../../../images/integrations/jira-rounded.svg';
import Routes from '../../../utils/Routes';
import MAuth from '../../../models/MAuth';
import MContext from '../../../models/MContext';
import { katalonui_theme } from '../../../katalonui-theme';

interface JiraIntegrationRequiredDialogProps {
  isOpen: boolean;
  handleClose: MouseEventHandler;
}

function JiraIntegrationRequiredDialog(props: Readonly<JiraIntegrationRequiredDialogProps>) {
  const teamId = MContext.teamId;
  const isTeamManager = MAuth.isTeamManager(teamId);
  const routes = new Routes();

  const {
    isOpen,
    handleClose,
  } = props;

  return (
    <ThemeProvider theme={katalonui_theme}>
      <CloseableDialogComponent
        id="jira-integration-required-dialog"
        isOpen={isOpen}
        handleClose={handleClose}
        maxWidth="sm"
        title={t('jira-integration-required-title')}
      >
        <DialogContent>
          <DialogContentText className="text-dark">
            <div>
              <Stack direction="row" spacing={2} justifyContent="center" alignItems="center" className="mb-4">
                <img className="img-fluid" alt="" src={KatalonRounded} />
                <Icon type="fa-solid" name="fa-link-simple" textSize="14px" />
                <img className="img-fluid" alt="" src={JiraRounded} />
              </Stack>
              {isTeamManager ? (
                <>
                  <p>{t('jira-integration-required-instruction#step1')}</p>
                  <p>{t('jira-integration-required-instruction#step2')}</p>
                </>
              ) : (
                <p>{t('jira-integration-required-instruction#notTeamManager')}</p>
              )}
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions className="popup-footer justify-content-end p-0">
          <Button
            id="close-jira-integration-required-dialog-btn"
            onClick={handleClose}
            title={t('close')}
            color="primary"
            variant="text"
            size="medium"
            className="mr-2"
          >
            {t('close')}
          </Button>
          {isTeamManager && (
          <Button
            id="go-to-jira-setting-btn"
            title={t('go-to-jira-setting')}
            color="primary"
            size="medium"
            variant="contained"
            href={routes.jira_setting_link}
            sx={{
              color: '#fff !important'
            }}
          >
            {t('jira-integration-required-btn')}
            <Icon
              type="fa-solid"
              name="fa-external-link"
              textSize="14px"
              className="ml-2"
            />
          </Button>
          )}
        </DialogActions>
      </CloseableDialogComponent>
    </ThemeProvider>
  );
}
export default JiraIntegrationRequiredDialog;
