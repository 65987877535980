import React from 'react';
import JenkinsIntegrationStepImg from '../../../../images/integrations/jenkin-integration-step.png';
import DocumentLink from '../../../utils/DocumentLink';

function JenkinsIntegration() {
  return (
    <div className="mt-2">
      <p>
        To install Jenkins - Katalon Platform integration, first log in Jenkins and navigate to&nbsp;
        <strong>
          Manage Jenkins {'>'} Manage Plugins.
        </strong>
        &nbsp;Click on the&nbsp;
        <strong>
          Available
        </strong>
        &nbsp;tab of&nbsp;
        <strong>
          Plugin Manager
        </strong>
        &nbsp;and search for the Katalon plugin.
      </p>
      <div className="mb-3">
        <img
          className="img-fluid img-thumbnail"
          alt=""
          src={JenkinsIntegrationStepImg}
        />
      </div>
      <p>
        Select the plugin and choose&nbsp;
        <strong>
          Install without restart.
        </strong>
      </p>
      <p>
        To run the test with Jenkins, you must first schedule test runs in Katalon TestOps, see&nbsp;
        <a href={DocumentLink.JENKINS_SCHEDULE_TEST_RUN} target="_blank" rel="noopener noreferrer">Schedule Test Runs.</a>
        &nbsp;
        After scheduling the tests, execute them in Jenkins, see&nbsp;
        <a href={DocumentLink.JENKINS_EXECUTE_TEST_RUN} target="_blank" rel="noopener noreferrer">Execute Katalon TestOps plan on Jenkins.</a>
      </p>
    </div>
  );
}

export default JenkinsIntegration;
