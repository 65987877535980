import React from 'react';
import Carousel, { Modal, ModalGateway } from 'react-images';
import { Header } from './Header';
import View from './View';

class FilePreviewer extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      selectedIndex: 0,
      lightboxIsOpen: false,
    };
  }

  openLightbox = (selectedIndex) => {
    this.setState({
      lightboxIsOpen: true,
      selectedIndex,
    });
  };

  closeLightbox = () => {
    this.setState({
      lightboxIsOpen: false,
    });
  }

  render() {
    const { lightboxIsOpen, selectedIndex } = this.state;
    const { images } = this.props;

    return (
      <ModalGateway>
        {lightboxIsOpen ? (
          <Modal onClose={this.closeLightbox}>
            <Carousel
              currentIndex={selectedIndex}
              frameProps={{ autoSize: 'height' }}
              views={images}
              components={{ Header, View }}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    );
  }
}

export default FilePreviewer;
