import React from 'react';
import TabComponent from '../../components/TabComponent';
import { buildSearchCondition } from '../../components/search/SearchUtils';
import ExecutionTestResultDataTable from '../../components/table/ExecutionTestResultDataTable';
import { t } from '../../i18n/t';

class RerunTab extends TabComponent {
  constructor(props) {
    super(props);

    this.meta.id = 'page-execution-test-result-details-rerun';
    this.meta.title = document.title;
  }

  render() {
    const { testResult } = this.props;
    const searchId = testResult.lastRetryTestId || -1;
    return (
      <ExecutionTestResultDataTable
        title={t('reruns')}
        defaultSearchConditions={[
          buildSearchCondition('ExecutionTestResult.id', '=', searchId),
        ]}
        defaultSort={['startTime,desc']}
      />
    );
  }
}

export default RerunTab;
