/* eslint max-len: 0 */
/* eslint no-trailing-spaces: 0 */
import React from 'react';
import {
  Container, Row, Col,
} from 'reactstrap';
import { brand } from '../i18n/t';
import { IconWave } from '../images/KitIcons';
import HowToUpload from './guide/HowToUpload';
import NoExecutionHistoryImg from '../../images/no-execution-history.svg';

class EmptyProject extends React.Component {

  render() {

    return (
      <Container className="empty-project-page">
        <Row>
          <Col>
            <p className="text-center">
              <span className="pr-2"><IconWave /> </span>
              Welcome to {brand}, the next generation of test reporting!
            </p>

            <p className="text-center">
              <img className="no-execution-history img-fluid" alt="No data" src={NoExecutionHistoryImg} />
            </p>

            <div className="text-center"><h1>Feed me some data!</h1></div>

            <p>It seems like there are no data to show. Follow the steps below to upload test execution reports to {brand}.</p>

            <HowToUpload />
          </Col>
        </Row>
      </Container>);
  }
}

export default EmptyProject;
