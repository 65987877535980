import React from 'react';
import PropTypes from 'prop-types';
import { Box, Card, CardMedia, IconButton } from '@mui/material';
import fileSize from 'filesize';
import { IconStatusPassed, IconStatusFailed, IconCloseInactive } from '../../../images/CustomIcon';
import DecoratorConstants from '../../../utils/DecoratorConstants';

const ImagePreviewCard = ({
  file = {
    uuid: '',
    src: '',
    name: '',
    size: 0,
    resolution: { width: null, height: null },
    errorMessage: ''
  },
  onRemove,
  trackCloseIcon
}) => {

  const { uuid, src, name, size, resolution, errorMessage } = file;
  const { width, height } = resolution;
  const imageResolution = `${width}x${height}`;

  const imageTitle = (
    <>
      {DecoratorConstants.textMiddleTruncateDecorator(name, '80%', name, 'wrap-image-name font-weight-bold')}
      <div className="upload-image-preview__size">{`(${fileSize(size, { round: 0 })})`}</div>
    </>
  );

  const imageDetails = (
    <>
      <span className="upload-image-preview__resolution">{imageResolution}</span>
      {errorMessage && <span className="upload-image-preview__errorMessage text-nowrap align-self-center">{errorMessage}</span>}
    </>
  );

  const iconStatus = (
    <span className="mr-1">
      {errorMessage ? <IconStatusFailed /> : <IconStatusPassed /> }
    </span>
  );

  const handleRemove = () => onRemove(uuid);
  return (
    <Card className="upload-image-preview" key={uuid}>
      <Box className="d-flex">
        <Box className="upload-image-preview__thumbnail">
          <CardMedia component="img" src={src} alt={imageTitle} />
        </Box>
        <Box className="d-flex flex-column infor-image">
          <Box className="d-flex flex-row">{imageTitle}</Box>
          <Box className="d-flex flex-row">{imageDetails}</Box>
        </Box>
      </Box>
      <Box>
        {iconStatus}
        <IconButton onClick={handleRemove} {...trackCloseIcon}>
          <IconCloseInactive width={12} height={12} />
        </IconButton>
      </Box>
    </Card>
  );
};

ImagePreviewCard.propTypes = {
  file: PropTypes.objectOf({
    uuid: PropTypes.string,
    src: PropTypes.string,
    name: PropTypes.string,
    size: PropTypes.number,
    resolution: PropTypes.objectOf({
      width: PropTypes.number,
      height: PropTypes.number,
    }),
    errorMessage: PropTypes.string,
  }),
  onRemove: PropTypes.func,
};

export default ImagePreviewCard;
