import React from 'react';
import { Button, Form, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { t } from '../../i18n/t';
import http from '../../utils/http';
import Apis from '../../utils/Apis';
import MContext from '../../models/MContext';
import Services from "../../utils/Services";
import MAuth from "../../models/MAuth";

class DeletePaymentMethodDialog extends React.Component {
  constructor(props) {
    super(props);

    this.organizationId = MContext.organizationId;
    this.organization = MContext.organization;

    this.toggle = this.toggle.bind(this);
    this.deletePaymentMethod = this.deletePaymentMethod.bind(this);
    this.cancelDelete = this.cancelDelete.bind(this);

    this.state = {
      modal: false,
    };
  }

  toggle() {
    const { modal } = this.state;
    this.setState({
      modal: !modal,
    });
  }

  deletePaymentMethod(e) {
    const user = MAuth.user;

    e.preventDefault();
    http.delete(Apis.paymentMethod(this.organizationId), {})
      .then(() => {
        Services.track('PAYMENT_METHOD', {
          category: 'Payment Method',
          email: user.email,
          first_name: user.firstName,
          label: 'Payment Method',
          last_name: user.lastName,
          organizationId: `organization-${this.organizationId}`,
          value: false
        });
        const {
          refreshData,
        } = this.props;

        if (refreshData) {
          refreshData();
        }
      });
    this.toggle();
  }

  cancelDelete() {
    this.toggle();
  }

  render() {
    return (
      <div>
        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <Form data-trackid="delete-payment-method" onSubmit={this.deletePaymentMethod}>
            <ModalHeader>{t('payment#delete_dialog@header')}</ModalHeader>
            <ModalBody>
              <>
                This action <strong>cannot</strong> be undone.
                <br />
                Your card will be permanently deleted in {this.organization.name}.
              </>
            </ModalBody>
            <ModalFooter>
              <Button type="submit" color="danger">{t('delete')}</Button>
              <Button color="secondary" onClick={this.cancelDelete}>{t('cancel')}</Button>
            </ModalFooter>
          </Form>
        </Modal>
      </div>
    );
  }
}

export default DeletePaymentMethodDialog;
