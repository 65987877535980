import { Typography, ListItemIcon } from '@mui/material';
import _ from 'lodash';
import MenuItem from '@mui/material/MenuItem';
import PropTypes from 'prop-types';
import React, { useImperativeHandle, useRef, useState } from 'react';
import DropDownFilter from './DropDownFilter';
import { IconSave } from '../../../images/KitIcons';
import { t } from '../../../i18n/t';

const ReleaseBuildDropdown = React.forwardRef(({ releases = [], ...props }, ref) => {
  const defaultLabel = 'Release/Build';
  const buildLabel = 'Build';
  const releaseLabel = 'Release';
  const [selectedValue, setSelectedValue] = useState();
  const [selectedLabel, setSelectedLabel] = useState(defaultLabel);
  const dropdownRef = useRef(null);

  const applyFilter = (id, value) => {
    const { onFilterChange } = props;
    onFilterChange(id, value);
  };

  useImperativeHandle(ref, () => ({
    clear: () => {
      setSelectedValue(null);
      setSelectedLabel(defaultLabel);
    }
  }));

  const select = (id, newOption) => {
    const searchKey = 'id';
    const value = selectedValue === newOption ? null : newOption;
    if (!value) {
      setSelectedLabel(defaultLabel);
    }
    setSelectedValue(value);
    applyFilter(id, _.get(value, searchKey));
    dropdownRef.current.handleToggle();
  };

  const onSelectBuild = (build) => {
    setSelectedLabel(buildLabel);
    applyFilter('Release.id', null);
    select('Build.id', build);
  };

  const onSelectRelease = (release) => {
    setSelectedLabel(releaseLabel);
    applyFilter('Build.id', null);
    select('Release.id', release);
  };

  const renderOption = (option) => (
    <Typography noWrap>{option.name}</Typography>
  );

  const renderNoResult = (
    <MenuItem>{t('no-result')}</MenuItem>
  );

  const renderSelectedIcon = (value) => (value === selectedValue ? '' : 'invisible');

  const menuItems = releases.map((item) => {
    const { builds } = item;
    const buildItems = builds.map((build) => (
      <MenuItem
        className="combined-filter"
        id="Build.id"
        key={build.id}
        button
        data-trackid="execution-build-filter"
        onClick={() => {
          onSelectBuild(build);
        }}
      >
        <ListItemIcon className={renderSelectedIcon(build)}>
          <IconSave />
        </ListItemIcon>
        {renderOption(build)}
      </MenuItem>
    ));
    return (
      <>
        <MenuItem
          id="Release.id"
          key={item.id}
          button
          data-trackid="execution-release-filter"
          onClick={() => {
            onSelectRelease(item);
          }}
        >
          <ListItemIcon className={renderSelectedIcon(item)}>
            <IconSave />
          </ListItemIcon>
          {renderOption(item)}
        </MenuItem>
        {buildItems}
      </>
    );
  });

  return (
    <DropDownFilter
      label={selectedLabel}
      ref={dropdownRef}
      renderOption={renderOption}
      selectedOption={selectedValue}
      icon={props.icon}
    >
      {releases.length > 0 ? menuItems : renderNoResult}
    </DropDownFilter>
  );
});

ReleaseBuildDropdown.propTypes = {
  releases: PropTypes.array,
  onFilterChange: PropTypes.func,
};

ReleaseBuildDropdown.defaultProps = {
  releases: [],
};

export default ReleaseBuildDropdown;
