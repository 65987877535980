import React from 'react';
import {
  Form, FormGroup,
  Modal, ModalHeader, ModalBody, ModalFooter,
  Button,
  Row, Col,
  Card, CardHeader, CardBody,
  Label,
  Alert,
} from 'reactstrap';
import Input from '../../components/Input';
import Select from '../../components/Select';
import PageComponent from '../../components/PageComponent';
import { notificationIds } from '../../utils/NotificationPageUtils';
import { t } from '../../i18n/t';
import MTableColumnDataMapping from '../../components/table/models/MTableColumnDataMapping';
import Services from '../../utils/Services';
import Notification from '../../utils/Notification';
import MAuth from '../../models/MAuth';
import { IconDelete } from '../../images/KitIcons';
import MContext from '../../models/MContext';
import { buildSearchCondition } from '../../components/search/SearchUtils';
import { getLicenseKeySearchConditions } from '../../components/search/LicenseUtils';
import DataTable from '../../components/table/DataTable';
import LicenseKeysDataTable from '../../components/table/LicenseKeysTable';
import PageButtonToolbar from '../../components/PageButtonToolbar';
import Routes from '../../utils/Routes';
import MachineTable from '../../components/table/MachineTable';
import DecoratorConstants from '../../utils/DecoratorConstants';
import MConfigs from '../../models/MConfigs';
import { OrganizationFeature, OrganizationRole, SearchEntity, LicenseMode } from '../../utils/Constants';
import Arrays from '../../utils/Arrays';

class BaseKSELicensesTab extends PageComponent {
  constructor(props) {
    super(props);
    // Define in child classes
    // this.meta.id = 'page-organization-kse-licenses';
    // this.meta.title = t('kse_license_keys');

    this.organizationId = MContext.organizationId;
    this.kseUserList = null;
    this.feature = props.feature;
    this.productName = this.feature === OrganizationFeature.KSE ? t('kse_license_keys') : t('unlimited_kse_license_keys');
    this.isUnlimited = !(this.feature === OrganizationFeature.KSE);
    this.fieldSuffix = this.isUnlimited ? 'UnlimitedKSE' : 'KSE';

    this.state = {
      organization: null,
      kseRemovalModal: false,
      emailToAssign: '',
      emailToRemove: '',
      idToRemove: '',
      assignableUsers: [],
    };

    this.selectChange = this.selectChange.bind(this);
    this.addKSEFeature = this.addKSEFeature.bind(this);
    this.closeKSEFeatureRemovalDialog = this.closeKSEFeatureRemovalDialog.bind(this);
    this.openKSEFeatureRemovalDialog = this.openKSEFeatureRemovalDialog.bind(this);
    this.removeKSEFeature = this.removeKSEFeature.bind(this);
  }

  openKSEFeatureRemovalDialog(email, id) {
    this.setState({
      kseRemovalModal: true,
      idToRemove: id,
      emailToRemove: email,
    });
  }

  removeKSEFeature(e) {
    e.preventDefault();
    const { idToRemove, emailToRemove } = this.state;
    Services.removeOrganizationFeatures({ organizationId: this.organizationId, userId: idToRemove, feature: this.feature })
      .then(() => {
        Notification.pushSuccess(t('license_keys#online@user_removed', { email: emailToRemove }));
        this.refreshLicensedUsersSection();
      });
    this.closeKSEFeatureRemovalDialog();
  }

  closeKSEFeatureRemovalDialog() {
    this.setState({
      kseRemovalModal: false,
    });
  }

  userHeader() {
    return [
      new MTableColumnDataMapping(t('name'), 'user.fullName'),
      new MTableColumnDataMapping(t('email'), 'user.email'),
      new MTableColumnDataMapping(
        '',
        'id',
        (name, row) => (
          <Button
            title={t('license_keys#online@remove_tooltip')}
            color="link"
            onClick={() => this.openKSEFeatureRemovalDialog(row.user.email, row.user.id)}
          >
            <IconDelete />
          </Button>
        ),
        true,
      ),
    ];
  }

  selectChange(option) {
    this.setState({
      emailToAssign: option.value,
    });
  }

  addKSEFeature(e) {
    e.preventDefault();
    const { emailToAssign } = this.state;
    if (!emailToAssign || emailToAssign.length === 0) {
      Notification.pushError(t('license_keys#online@choose_from_list'), t('license_keys#online@invalid_email'));
      return;
    }
    Services.addOrganizationFeatures({ organizationId: this.organizationId, userEmail: emailToAssign, feature: this.feature })
      .then(() => {
        Notification.pushSuccess(t('license_keys#online@invited', { email: emailToAssign }));
        this.setState(
          { emailToAssign: '' },
          () => this.refreshLicensedUsersSection(),
        );
      });
  }

  decorateAssignableUsers(userOrganization) {
    return userOrganization.map((u) => {
      const role = (u.role && u.role !== OrganizationRole.USER) ? ` (${u.role})` : '';
      return {
        value: u.user.email,
        label: `${u.user.email}${role}`,
      };
    });
  }

  fetchFilteredUserOrganizations(excludedList) {
    const params = {
      pagination: {
        page: 0,
        size: 1000,
        sorts: ['id,desc'],
      },
      conditions: [
        {
          key: 'Organization.id',
          operator: '=',
          value: this.organizationId,
        },
        ...Arrays.insertIf(excludedList.length > 0, {
          key: 'User.id',
          operator: 'not in',
          value: excludedList.toString(),
        }),
      ],
      type: SearchEntity.UserOrganization,
    };
    Services.search(params)
      .then(({ content }) => {
        this.setState({ assignableUsers: this.decorateAssignableUsers(content) });
      });
  }

  fetchExcludedUserOrganizations() {
    const params = {
      pagination: {
        page: 0,
        size: 1000,
        sorts: ['id,desc'],
      },
      conditions: [
        buildSearchCondition('Organization.id', '=', this.organizationId),
        buildSearchCondition('feature', 'in', [OrganizationFeature.KSE, OrganizationFeature.UNLIMITED_KSE].toString()),
      ],
      type: SearchEntity.UserOrganizationFeature,
    };
    Services.search(params)
      .then(({ content: excludedUserOrganizations }) => {
        this.fetchFilteredUserOrganizations(excludedUserOrganizations.map(uo => uo.user.id));
      });
  }

  fetchAssignableUserOrganizations() { // alias
    this.fetchExcludedUserOrganizations();
  }

  componentDidMount() {
    Services.organizationWithLicense(this.organizationId, this.feature)
      .then((organization) => this.setState({ organization }));
    this.fetchAssignableUserOrganizations();
  }

  renderOnlineKSELicenseKeyTable() {
    const defaultSearchConditions = getLicenseKeySearchConditions(this.organizationId, this.feature, LicenseMode.FLOATING);
    return (
      <Card>
        <CardHeader>{t('license_keys#process-running')}</CardHeader>
        <CardBody>
          <Row>
            <Col>
              <Alert color="primary">
                {t('license_keys#online-info')}
              </Alert>
            </Col>
          </Row>
          <LicenseKeysDataTable
            id="online-license"
            title={t('license_keys#online')}
            defaultSearchConditions={defaultSearchConditions}
            noCard
          />
        </CardBody>
      </Card>
    );
  }

  renderOfflineKSELicenseKeyTable() {
    if (MConfigs.isOnPremise) {
      return null;
    }
    const defaultSearchConditions = getLicenseKeySearchConditions(this.organizationId, this.feature, LicenseMode.COMMUTER);
    return (
      <Card id="offline-license">
        <CardHeader>{t('license_keys#offline')}</CardHeader>
        <CardBody>
          <LicenseKeysDataTable
            title={t('license_keys#offline')}
            defaultSearchConditions={defaultSearchConditions}
            noCard
            isOfflineLicense={true}
            showDescription
          />
        </CardBody>
      </Card>
    );
  }

  renderMachineTable() {
    const defaultSearchConditions = [
      buildSearchCondition('Organization.id', '=', this.organizationId),
      buildSearchCondition('feature', '=', this.feature),
    ];
    return (
      <>
        <MachineTable
          id="machine"
          title={t('machine')}
          organizationId={this.organizationId}
          defaultSearchConditions={defaultSearchConditions}
        />
      </>
    );
  }

  renderKSEQuota() {
    const { organization } = this.state;
    const quota = organization[`quota${this.fieldSuffix}`];
    const used = organization[`used${this.fieldSuffix}`];
    const machineQuota = this.isUnlimited ? 0 : organization['machineQuotaKSE'];
    const subscriptionExpiryDate = DecoratorConstants.timeDecorator(
      `subscriptionExpiryDate${this.fieldSuffix}`,
      organization,
    );
    return (
      <Card>
        <CardHeader>{t('license_keys#quota_header')}</CardHeader>
        <CardBody>
          <Row>
            <Col sm="12" md="8" lg="6" xl="5">
              <Form data-trackid="kse-license-key-quota">
                {quota > 0 && (
                  <FormGroup>
                    <Label htmlFor="expiry_date">{t('license_keys#expiry_date')}</Label>
                    <Input id="expiry_date" plaintext>{subscriptionExpiryDate}</Input>
                  </FormGroup>
                )}
                <FormGroup>
                  <Label htmlFor="quota">{t('license_keys#quota')}</Label>
                  <Input id="quota" plaintext>{quota}</Input>
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="remaining">{t('license_keys#remaining')}</Label>
                  <Input id="remaining" plaintext>
                    {used >= quota ? 0 : quota - used}
                  </Input>
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="machine_quota">{t('license_keys#machine_quota')}</Label>
                  <Input id="machine_quota" plaintext>{this.isUnlimited && quota > 0 ? 'Unlimited' : machineQuota}</Input>
                </FormGroup>
              </Form>
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  }

  renderCreateLicenseKey() {
    if (MConfigs.isOnPremise) {
      return null;
    }
    const { organization } = this.state;
    const organizationId = this.organizationId;
    const routes = new Routes({ organizationId });
    const quota = organization[`quota${this.fieldSuffix}`];
    const used = organization[`used${this.fieldSuffix}`];
    const canCreateOffline = organization[`canCreateOffline${this.fieldSuffix}`];
    const disabled = !canCreateOffline || (used >= quota);
    const link = this.isUnlimited ?
      routes.organization_create_unlimited_kse_license_keys_link :
      routes.organization_create_kse_license_keys_link;
    return (
      <PageButtonToolbar>
        <Button
          title={t('license_keys#create_license_key')}
          color="secondary"
          href={link}
          disabled={disabled}
        >
          {t('license_keys#create_license_key')}
        </Button>
      </PageButtonToolbar>
    );
  }

  refreshLicensedUsersSection() {
    this.kseUserList.refreshData();
    this.fetchAssignableUserOrganizations();
  }

  renderLicensedUser() {
    const {
      emailToAssign, assignableUsers, organization
    } = this.state;
    const enabled = organization[`quota${this.fieldSuffix}`] > 0;
    return (
      <Card id="LicensedUsersSection">
        <CardHeader>{t('license_keys#online@licensed_users')}</CardHeader>
        <CardBody>
          <Row>
            <Col>
              <Alert color="primary">
                {t('license_keys#online@default_notice')}
              </Alert>
            </Col>
          </Row>
          <Row>
            <Col sm="12" md="8" lg="6" xl="5">
              <Form data-trackid="add-kse-feature" onSubmit={this.addKSEFeature}>
                <FormGroup>
                  <Select
                    required
                    clearable={false}
                    id="cloudType"
                    name="cloudType"
                    value={emailToAssign}
                    options={assignableUsers}
                    onChange={this.selectChange}
                    disabled={!enabled}
                  />
                </FormGroup>
                <FormGroup>
                  <Button
                    color="primary"
                    type="submit"
                    disabled={!enabled}
                  >
                    {t('add')}
                  </Button>
                </FormGroup>
              </Form>
            </Col>
          </Row>
          <DataTable
            id="users"
            ref={(ref) => {
              this.kseUserList = ref;
            }}
            entityType={SearchEntity.UserOrganizationFeature}
            sourceFieldName="content"
            defaultSearchConditions={[
              buildSearchCondition('Organization.id', '=', this.organizationId),
              buildSearchCondition('feature', '=', this.feature),
            ]}
            noCard
            columnMapping={this.userHeader()}
          />
        </CardBody>
      </Card>
    );
  }

  renderRemovalDialog() {
    const {
      kseRemovalModal, emailToRemove,
    } = this.state;

    return (
      <Modal isOpen={kseRemovalModal}>
        <Form data-trackid="remove-kse-feature" onSubmit={this.removeKSEFeature}>
          <ModalHeader>{`${t('remove')}?`}</ModalHeader>
          <ModalBody>
          {t('license_keys#online@confirm_removal', { product: this.productName, email: emailToRemove })}
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              type="submit"
            >{t('remove')}
            </Button>
            <Button
              color="secondary"
              onClick={this.closeKSEFeatureRemovalDialog}
            >{t('cancel')}
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    );
  }

  render() {
    const isOrganizationManager = MAuth.isOrganizationManager(this.organizationId);
    const isBillingManager = MAuth.isBillingManager(this.organizationId);

    if (!isOrganizationManager && !isBillingManager) {
      Routes.goToNotificationPage(notificationIds.NO_PERMISSION);
    }

    const {
      organization,
    } = this.state;

    if (organization) {
      return (
        <>
          {isOrganizationManager && this.renderCreateLicenseKey()}
          {this.renderKSEQuota()}
          {isOrganizationManager && this.renderLicensedUser()}
          {isOrganizationManager && this.renderOnlineKSELicenseKeyTable()}
          {isOrganizationManager && this.renderOfflineKSELicenseKeyTable()}
          {isOrganizationManager && this.renderMachineTable()}
          {isOrganizationManager && this.renderRemovalDialog()}
        </>
      );
    }
    return null;
  }
}

export default BaseKSELicensesTab;
