import { IconButton, Tooltip } from '@mui/material';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import Select from '../../../components/Select';
import colors from '../../../../scss/colors.scss';
import { buildSearchCondition, buildSearchFunction } from '../../../components/search/SearchUtils';
import DataLoader from '../../../components/table/DataLoader';
import MTableColumnDataMapping from '../../../components/table/models/MTableColumnDataMapping';
import TableCore from '../../../components/table/models/TableCore';
import { t } from '../../../i18n/t';
import { IconDuration, IconStatusFailed, IconStatusPassed, IconStatusError, IconStatusIncomplete, IconStatusSkipped } from '../../../images/CustomIcon';
import { IconStatus } from '../../../images/KitIcons';
import { DayOfWeek, MAX_DAY_OF_MONTH, TestRunStatus } from '../../../utils/Constants';
import DecoratorConstants from '../../../utils/DecoratorConstants';
import Helper from '../../../utils/Helper';
import Time from '../../../utils/Moment';
import ColorUtils from '../../../utils/ColorUtils';

const NUMBER_COLOR = 7;
const statusColors = ColorUtils.colorScales([colors.failed, colors.error, colors.passed], NUMBER_COLOR);
const MAX_PAGE_SIZE = MAX_DAY_OF_MONTH * Object.keys(TestRunStatus).length;

const convertData = (data) => data.map(({ content: item }) => ({
  status: item.status,
  startTime: Time.startOf(item.startTime, 'day').format(),
  totalPassedTests: item.status === TestRunStatus.PASSED ? item.total : 0,
  totalFailedTests: item.status === TestRunStatus.FAILED ? item.total : 0,
  totalErrorTests: item.status === TestRunStatus.ERROR ? item.total : 0,
  totalIncompleteTests: item.status === TestRunStatus.INCOMPLETE ? item.total : 0,
  totalSkippedTests: item.status === TestRunStatus.SKIPPED ? item.total : 0,
  totalDuration: item.totalDuration,
}));

const calcBubbleSize = (numberTests) => {
  const logValue = Helper.getLogBase(20, numberTests);
  const size = logValue + 1.0;
  return `${size.toFixed(2)}rem`;
};

const StatusesColorScale = () => {
  const colorScale = statusColors.map((color) => <IconStatus className="m-1" key={color} fill={color} />);
  return (
    <div className="d-flex justify-content-end">
      Failed {colorScale} Passed
    </div>
  );
};

const FrequencyTooltip = ({ content }) => (
  <div className="frequency-tooltip">
    <div className="mb-2">
      {DecoratorConstants.dateDecorator('startTime', content)}
    </div>
    <div>
      <IconStatusPassed /> {content.totalPassedTests} test results
    </div>
    <div>
      <IconStatusFailed /> {content.totalFailedTests} test results
    </div>
    <div>
      <IconStatusError /> {content.totalErrorTests} test results
    </div>
    <div>
      <IconStatusIncomplete /> {content.totalIncompleteTests} test results
    </div>
    <div>
      <IconStatusSkipped /> {content.totalSkippedTests} test results
    </div>
    <div>
      <IconDuration /> {DecoratorConstants.durationDecorator('totalDuration', content)}
    </div>
  </div>
);

const DateOfMonth = ({ time, title }) => (
  <Tooltip title={title}>
    <IconButton size="large">
      <div>
        <span className="text-muted">
          {Time.formatDate(time, Time.DATE_OF_MONTH_FORMAT)}
        </span>
      </div>
    </IconButton>
  </Tooltip>
);

const FrequencyBubble = ({ value }) => {
  const totalTestsNewFormula = value.totalFailedTests + value.totalPassedTests + value.totalErrorTests + value.totalIncompleteTests + value.totalSkippedTests;

  const size = calcBubbleSize(totalTestsNewFormula);
  const passedRatio = (value.totalPassedTests / (totalTestsNewFormula - value.totalSkippedTests)).toFixed(2);

  const colorIdx = Math.round(passedRatio * (NUMBER_COLOR - 1));
  return (
    <Tooltip title={<FrequencyTooltip content={value} />}>
      <IconButton size="large">
        <IconStatus
          fill={statusColors[colorIdx]}
          style={{
            width: size,
            height: size,
          }}
        />
      </IconButton>
    </Tooltip>
  );
};

const weekOfDecorator = (name, row) => {
  const value = _.get(row, name);
  if (value) {
    return (
      <Tooltip title={Time.formatDate(value, Time.LAST_MONTHS_FORMAT)}>
        <IconButton size="large">
          {Time.formatDate(value, Time.LAST_MONTHS_FORMAT)}
        </IconButton>
      </Tooltip>
    );
  } else {
    return '';
  }
};

const dayFrequencyDecorator = (name, row) => {
  const value = _.get(row, name);
  if (value) {
    // we don't add the value totalSkippedTests because we treat the case all skipped tests as no test results
    const totalTests = value.totalFailedTests + value.totalPassedTests + value.totalErrorTests + value.totalIncompleteTests;
    if (totalTests === 0) {
      return <DateOfMonth time={value.startTime} title={DecoratorConstants.dateDecorator('startTime', value)} />;
    }

    return <FrequencyBubble value={value} />;
  } else {
    return null;
  }
};

const columnMapping = [
  new MTableColumnDataMapping(
    'Weeks',
    'startOfWeek',
    weekOfDecorator,
    undefined,
    'center-column',
  ),
  new MTableColumnDataMapping(
    'Monday',
    DayOfWeek.MONDAY,
    dayFrequencyDecorator,
    undefined,
    'center-column',
  ),
  new MTableColumnDataMapping(
    'Tuesday',
    DayOfWeek.TUESDAY,
    dayFrequencyDecorator,
    undefined,
    'center-column',
  ),
  new MTableColumnDataMapping(
    'Wednesday',
    DayOfWeek.WEDNESDAY,
    dayFrequencyDecorator,
    undefined,
    'center-column',
  ),
  new MTableColumnDataMapping(
    'Thursday',
    DayOfWeek.THURSDAY,
    dayFrequencyDecorator,
    undefined,
    'center-column',
  ),
  new MTableColumnDataMapping(
    'Friday',
    DayOfWeek.FRIDAY,
    dayFrequencyDecorator,
    undefined,
    'center-column',
  ),
  new MTableColumnDataMapping(
    'Saturday',
    DayOfWeek.SATURDAY,
    dayFrequencyDecorator,
    undefined,
    'center-column',
  ),
  new MTableColumnDataMapping(
    'Sunday',
    DayOfWeek.SUNDAY,
    dayFrequencyDecorator,
    undefined,
    'center-column',
  ),
];

class FrequencyReport extends React.Component {

  selectOptions = [];

  constructor(props) {
    super(props);

    this.selectOptions = [
      {
        // Current
        value: Time.startOf(Time.timeAgo(0, 'month'), 'month'),
        label: Time.formatDate(Time.timeAgo(0, 'month'), Time.MONTH_YEAR_FORMAT),
      },
      {
        // a month ago
        value: Time.startOf(Time.timeAgo(1, 'month'), 'month'),
        label: Time.formatDate(Time.timeAgo(1, 'month'), Time.MONTH_YEAR_FORMAT),
      },
      {
        // 2 months ago
        value: Time.startOf(Time.timeAgo(2, 'month'), 'month'),
        label: Time.formatDate(Time.timeAgo(2, 'month'), Time.MONTH_YEAR_FORMAT),
      },
    ];

    this.state = {
      from: Time.startOf(moment.now(), 'month'),
      to: Time.endOf(moment.now(), 'month'),
      selectTime: this.selectOptions[0],
      groupByCondition: 'group_by_day',
    };

    this.renderData = this.renderData.bind(this);
    this.handleOnSelect = this.handleOnSelect.bind(this);
  }

  handleOnSelect(selectTime) {
    const from = selectTime.value;
    const to = Time.endOf(from, 'month');

    this.setState({
      from,
      to,
      selectTime,
    });
  }

  renderCustomControl() {
    const {
      selectTime,
    } = this.state;

    return (
      <Select
        clearable={false}
        value={selectTime}
        options={this.selectOptions}
        onChange={this.handleOnSelect}
      />
    );
  }

  renderData(data) {
    const {
      from,
      to,
    } = this.state;
    const convertedData = convertData(data);
    const groupDataByStartTime = _.groupBy(convertedData, 'startTime') || [];
    const times = Time.timeBetween(moment(from), moment(to), 'day');
    const dailyData = times.map((time) => {
      const val = {
        startTime: time,
        totalFailedTests: 0,
        totalPassedTests: 0,
        totalErrorTests: 0,
        totalIncompleteTests: 0,
        totalSkippedTests: 0,
        totalDuration: 0,
        dayOfWeek: Time.dayOfWeek(time),
        startOfWeek: Time.startOf(time, 'week'),
      };
      if (groupDataByStartTime[time]) {
        groupDataByStartTime[time].forEach((value) => {
          val.totalFailedTests += value.totalFailedTests;
          val.totalPassedTests += value.totalPassedTests;
          val.totalErrorTests += value.totalErrorTests;
          val.totalIncompleteTests += value.totalIncompleteTests;
          val.totalSkippedTests += value.totalSkippedTests;
          val.totalDuration += value.totalDuration;
        });
      }
      return val;
    });

    const groupDataByStartOfWeek = _.groupBy(dailyData, 'startOfWeek');
    const weeklyData = [];
    Time.timeBetween(from, to, 'week').forEach((time) => {
      const startOfWeek = Time.startOf(time, 'week');
      if (groupDataByStartOfWeek[startOfWeek]) {
        const daysOfWeekData = groupDataByStartOfWeek[startOfWeek];
        const weeklyItem = {
          startOfWeek: time,
        };
        daysOfWeekData.forEach((dayData) => {
          weeklyItem[dayData.dayOfWeek] = dayData;
        });
        weeklyData.push(weeklyItem);
      }
    });

    return (
      <TableCore data={weeklyData} columnMapping={columnMapping} {...this.props} />
    );
  }

  render() {
    const {
      from,
      to,
      groupByCondition,
      selectTime,
    } = this.state;


    return (
      <DataLoader
        title={t('frequency-report')}
        customControl={this.renderCustomControl()}
        render={this.renderData}
        key={`${selectTime.value}_frequency_report`}
        entityType="ExecutionTestResult"
        defaultSearchConditions={[
          buildSearchCondition('startTime', '>', from),
          buildSearchCondition('startTime', '<', to),
        ]}
        defaultSearchFunctions={[
          buildSearchFunction('total', 'count', ['id']),
          buildSearchFunction('totalDuration', 'sum', ['duration']),
          buildSearchFunction('startTime', groupByCondition, ['startTime']),
        ]}
        groupBys={['status']}
        defaultSort={[
          'startTime, asc',
        ]}
        renderFooter={<StatusesColorScale />}
        pageSize={MAX_PAGE_SIZE}
      />
    );
  }
}

export default FrequencyReport;
