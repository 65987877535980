import PropTypes from 'prop-types';
import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { Card, Col, Row } from 'reactstrap';
import PageComponent from '../PageComponent';
import RouteConstants from '../../utils/RouteConstants';
import MConfigs from '../../models/MConfigs';
import ActivateTestOps from './ActivateTestOps';
import AdminSignUp from './AdminSignUp';
import Routes from '../../utils/Routes';

export default class StartUp extends PageComponent {
  constructor(props) {
    super(props);
    this.meta.id = 'page-startup';
    this.meta.title = 'Startup';
  }

  render() {
    const isOnStartUp = MConfigs.isOnStartUp;
    const isLicenseServerOnPremise = MConfigs.isLicenseServerOnPremise;
    const { activated, adminCreated } = MConfigs;
    const routes = new Routes();
    return (
      <Row className="login-page flex-fill align-items-center mx-0">
        <Col
          sm={{ size: 8, offset: 4 }}
          md={{ size: 7, offset: 5 }}
          lg={{ size: 4, offset: 6 }}
        >
          <Card className="login-card mx-auto shadow-none">
            <Switch>
              {!isOnStartUp && <Redirect to="/" />}
              <Route
                exact
                path={RouteConstants.startup_create_account}
                component={AdminSignUp}
              />
              <Route
                exact
                path={RouteConstants.startup_activate}
                component={ActivateTestOps}
              />
              {isLicenseServerOnPremise && !activated && <Redirect to={routes.startup_activate_link} />}
              {!adminCreated && <Redirect to={routes.startup_create_account_link} />}
            </Switch>
          </Card>
        </Col>
      </Row>
    );
  }
}

StartUp.contextTypes = {
  router: PropTypes.object,
};
