import React, { useRef, useState } from 'react';
import { get } from 'lodash';
import { MenuItem } from '@mui/material';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';
import { SearchEntity } from '../../../utils/Constants';
import { getFileUrl } from './Utils';
import { buildSearchCondition } from '../../../components/search/SearchUtils';
import { t } from '../../../i18n/t';
import RemoveBaselineConfirmDialog from '../../../components/dialog/RemoveBaselineConfirmDialog';
import BaselineImageViewDialog from '../../../components/dialog/BaselineImageViewDialog';
import GroupEvent from '../../../utils/track/GroupEvent';
import ImageThumbnail from '../../../components/gallery/ImageThumbnail';
import Gallery from '../../keyes/component/Gallery';
import { IconPencilEdit } from '../../../images/CustomNewIcon';
import BaselineImageEditingDialog from '../../../components/dialog/BaselineImageEditingDialog';
import { next } from '../../../utils/Count';
import { sendAnalyticEventForAction } from '../../../utils/SegmentAnalytics';
import TooltipComponent from '../../../components/TooltipComponent';
import MContext from '../../../models/MContext';

const BaselineImagesView = ({ baselineCollectionId, isLatestVersion, baselineCollectionGroupId, onRefreshData }) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [images, setImages] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [isOpenRemoveBaselineConfirmDialog, setOpenRemoveBaselineConfirmDialog] = useState(false);
  const [isOpenBaselineImageViewDialog, setOpenBaselineImageViewDialog] = useState(false);
  const [isOpenBaselineImageEditingDialog, setOpenBaselineImageEditingDialog] = useState(false);
  const [chosenBaseline, setChosenBaseline] = useState(null);
  const [keyEdit, setKeyEdit] = useState(next());
  const dataRef = useRef();

  const pageSize = 9;

  const handleDelete = (item) => {
    setOpenRemoveBaselineConfirmDialog(true);
    setChosenBaseline(item);
  };

  const renderButtonDelete = (item) => (
    <MenuItem
      onClick={() => handleDelete(item)}
      className="danger-menu-items"
      data-trackid="delete-baseline-image"
      data-groupid={GroupEvent.ACCESS_REPORT}
    >
      {t('delete-baseline-image')}
    </MenuItem>
  );

  const handleEdit = (event, item) => {
    event.stopPropagation();
    setOpenBaselineImageEditingDialog(true);
    setChosenBaseline(item);
    sendAnalyticEventForAction('page-visual-testing-baseline-edit', { 'data-groupid': GroupEvent.ACCESS_REPORT });
  };

  const renderButtonEdit = (item) => (
    <TooltipComponent text={t('baseline-images#editImage')} placement="bottom-end">
      <div>
        <Button
          className="image-edit-button"
          onClick={(e) => handleEdit(e, item)}
          color="none"
        >
          <IconPencilEdit />
        </Button>
      </div>
    </TooltipComponent>
  );

  const convertData = (data) => {

    // set to context to use in ConfigBaselineDialog
    MContext.baselines = data;

    return data.map((item) => {
      const name = get(item, 'screenshot.name');
      const fileName = get(item, 'uploadFile.fileName');
      return ({
        source: getFileUrl(item, 'uploadFile.fileHandleId'),
        thumbnail: getFileUrl(item, 'uploadFile.thumbnailId'),
        fileType: fileName.split('.').pop(),
        caption: <span className="had-ellipsis">{name}</span>,
        title: name,
        dropDownItems: isLatestVersion && [renderButtonDelete(item)],
        mainButtons: isLatestVersion && [renderButtonEdit(item)]
      });
    });
  };

  const handleCloseRemoveBaselineConfirmDialog = () => {
    setOpenRemoveBaselineConfirmDialog(false);
  };

  const onDeleteSuccess = () => {
    handleCloseRemoveBaselineConfirmDialog();
    onRefreshData();
  };

  const onEditSuccess = () => {
    setKeyEdit(next());
    onRefreshData();
  };

  const renderRemoveBaselineConfirmDialog = () => {
    if (chosenBaseline) {
      return (
        <RemoveBaselineConfirmDialog
          isOpen={isOpenRemoveBaselineConfirmDialog}
          handleClose={handleCloseRemoveBaselineConfirmDialog}
          baselines={[chosenBaseline]}
          baselineCollectionGroupId={baselineCollectionGroupId}
          onDeleteSuccess={onDeleteSuccess}
        />
      );
    }
    return null;
  };

  const handleCloseBaselineImageEditingDialog = () => {
    setOpenBaselineImageEditingDialog(false);
  };

  const renderBaselineImageEditingDialog = () => {
    if (chosenBaseline) {
      return (
        <BaselineImageEditingDialog
          isOpen={isOpenBaselineImageEditingDialog}
          handleClose={handleCloseBaselineImageEditingDialog}
          baseline={chosenBaseline}
          baselineCollectionId={baselineCollectionId}
          onEditSuccess={onEditSuccess}
        />
      );
    }
    return null;
  };

  const onChangeData = (data) => {
    setImages(convertData(data));
  };

  const onChangeLoading = (isLoading) => {
    setLoading(isLoading);
  };

  const openBaselineModal = (event, selectedIndex) => {
    event.preventDefault();
    setSelectedIndex(selectedIndex);
    setOpenBaselineImageViewDialog(true);
  };

  const closeBaselineModal = () => {
    setOpenBaselineImageViewDialog(false);
  };

  const getCurrentIdx = () => {
    const { currentPage } = dataRef.current.store;
    return (currentPage * pageSize) + selectedIndex;
  };

  const isFirstBaseline = () => {
    const currentIdx = getCurrentIdx();
    return currentIdx === 0;
  };

  const isLastBaseline = () => {
    const { totalItems } = dataRef.current.store;
    const currentIdx = getCurrentIdx();
    return currentIdx === totalItems - 1;
  };

  const previousBaseline = () => {
    if (selectedIndex === 0) {
      const currentPage = dataRef.current.store.currentPage;
      dataRef.current.goToPage(currentPage - 1);
      setSelectedIndex(pageSize - 1);
    } else {
      setSelectedIndex(selectedIndex - 1);
    }
  };

  const nextBaseline = () => {
    if (selectedIndex === pageSize - 1) {
      const currentPage = dataRef.current.store.currentPage;
      dataRef.current.goToPage(currentPage + 1);
      setSelectedIndex(0);
    } else {
      setSelectedIndex(selectedIndex + 1);
    }
  };

  const renderThumbnail = ({ source, caption, thumbnail, title, mainButtons, dropDownItems }) => (
    <ImageThumbnail
      imageUrl={source}
      thumbnailUrl={thumbnail}
      header={caption}
      title={title || caption}
      mainButtons={mainButtons}
      dropDownItems={dropDownItems}
    />
  );

  const renderGallery = (image, index) => (
    <div
      className="col-4 gallery-item"
      onClick={(event) => openBaselineModal(event, index)}
    >
      {renderThumbnail(image, index)}
    </div>
  );

  const renderBaselineImageViewDialog = () => {
    const isFirst = isFirstBaseline();
    const isLast = isLastBaseline();
    const image = images && images[selectedIndex];
    return (
      <BaselineImageViewDialog
        isOpen={isOpenBaselineImageViewDialog}
        handleClose={closeBaselineModal}
        isFirst={isFirst}
        isLast={isLast}
        previousBaseline={previousBaseline}
        nextBaseline={nextBaseline}
        isLoading={isLoading}
        image={image}
      />
    );
  };

  return (
    <>
      <Gallery
        ref={dataRef}
        entityType={SearchEntity.Baseline}
        renderImage={renderGallery}
        convertData={convertData}
        key={`${baselineCollectionId}-${keyEdit}`}
        pageSize={pageSize}
        defaultSort={['updatedAt, desc']}
        noCard
        defaultSearchConditions={[
          buildSearchCondition('BaselineCollection.id', '=', baselineCollectionId),
        ]}
        onChangeData={onChangeData}
        onChangeLoading={onChangeLoading}
        unitName="image"
      />
      {dataRef.current && renderBaselineImageViewDialog()}
      {renderRemoveBaselineConfirmDialog()}
      {renderBaselineImageEditingDialog()}
    </>
  );
};

BaselineImagesView.propTypes = {
  /**
   * Flag check baseline collection has is the latest version.
   */
  isLatestVersion: PropTypes.bool,
  baselineCollectionGroupId: PropTypes.number
};

BaselineImagesView.defaultProps = {
  isLatestVersion: false,
};

export default BaselineImagesView;
