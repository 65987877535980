import React from 'react';
import { Button, Form, FormGroup, Row, Col, Card, CardHeader, CardBody } from 'reactstrap';
import Input from '../components/Input';
import PageComponent from '../components/PageComponent';
import { buildSearchCondition } from '../components/search/SearchUtils';
import ObjectSummary from '../components/summary/ObjectSummary';
import MTableColumnDataMapping from '../components/table/models/MTableColumnDataMapping';
import { t } from '../i18n/t';
import MAuth from '../models/MAuth';
import MContext from '../models/MContext';
import DecoratorConstants from '../utils/DecoratorConstants';
import Notification from '../utils/Notification';
import Routes from '../utils/Routes';
import Services from '../utils/Services';
import DataTable from '../components/table/DataTable';
import DefaultLayout from '../components/DefaultLayout';
import { IconSettings } from '../images/CustomIcon';
import GroupEvent from '../utils/track/GroupEvent';
import MFlags from '../../app/models/MFlags';
import MConfigs from '../models/MConfigs';
import Apis from '../utils/Apis';

class Projects extends PageComponent {

  projectName = '';

  projectsList = null;

  constructor(props) {
    super(props);
    this.teamId = MContext.teamId;
    this.meta.id = 'page-projects-settings';
    this.meta.title = t('Projects');
    this.projectHeader = this.projectHeader.bind(this);
    this.handleProjectNameChange = this.handleProjectNameChange.bind(this);
    this.addProject = this.addProject.bind(this);
    this.renderHeader = this.renderHeader.bind(this);
    this.renderBody = this.renderBody.bind(this);
    this.state = {
      newProjectName: '',
      disabledInput: '',
    };
  }

  addProject(e) {
    e.preventDefault();
    const newProjectName = this.state.newProjectName;
    if (newProjectName) {
      const projectData = {
        name: newProjectName,
        teamId: this.teamId,
      };
      this.setState({ disabledInput: 'disabled' });
      Services.createNewProject(projectData)
        .then(() => {
          Notification.pushSuccess(`Project ${this.projectName} was created.`);
          this.projectsList.refreshData();
        })
        .finally(() => {
          this.setState({
            disabledInput: '',
            newProjectName: '',
          });
        });
    }
  }


  handleProjectNameChange(event) {
    const newProjectName = event.target.value;
    this.setState({ newProjectName });
  }

  projectHeader() {
    const { isLicenseAdminServer } = MConfigs;
    let headers = [
      new MTableColumnDataMapping(
        t('id'),
        'id',
      ),
      new MTableColumnDataMapping(
        t('name'),
        'name',
        (name, row) => {
          const constructedLink = new Routes({
            projectId: row.id,
          });
          return DecoratorConstants.idDecorator(
            name,
            row,
            isLicenseAdminServer
              ? Apis.testOpsProjectHome(row.teamId, row.id)
              : constructedLink.project_link,
          );
        },
      ),
    ];
    if (MAuth.isTeamManager(this.teamId)) {
      headers = headers.concat([
        new MTableColumnDataMapping(
          t('action'),
          'name',
          (name, row) => (
            this.renderSettingButton(name, row)
          ),
          true,
        ),
      ]);
    }
    return headers;
  }

  renderSettingButton(name, row) {
    const { isLicenseAdminServer } = MConfigs;
    const routes = new Routes({
      teamId: row.teamId,
      projectId: row.id
    });

    return (
      <a
        href={
          isLicenseAdminServer
            ? Apis.testOpsProjectSettings(row.teamId, row.id)
            : routes.project_settings_link
        }
      >
        <IconSettings />
      </a>
    );
  }

  getTeam() {
    const team = MContext.team;
    this.setState({
      team,
    });
  }

  componentDidMount() {
    this.getTeam();
  }

  renderCreateProject() {
    const { newProjectName, disabledInput } = this.state;
    return (
      <Card>
        <CardHeader>{t('create_project')}</CardHeader>
        <CardBody>
          <Row>
            <Col sm="12" md="8" lg="6" xl="5">
              <Form data-trackid="create-project" onSubmit={this.addProject} data-groupid={GroupEvent.ACCESS_REPORT}>
                <FormGroup>
                  <Input
                    id="project-name"
                    disabled={disabledInput}
                    value={newProjectName}
                    onChange={this.handleProjectNameChange}
                    type="text"
                    placeholder="Project name"
                  />
                </FormGroup>
                <FormGroup>
                  <Button id="create-project" type="submit" color="primary">Create</Button>
                </FormGroup>
              </Form>
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  }

  renderProjectTable() {
    const projectHeader = this.projectHeader();
    return (
      <DataTable
        title={t('projects')}
        ref={(ref) => {
          this.projectsList = ref;
        }}
        entityType="Project"
        defaultSearchConditions={[
          buildSearchCondition('Team.id', '=', this.teamId),
        ]}
        columnMapping={projectHeader}
      />
    );
  }

  renderObjectSummary() {
    const { team } = this.state;
    const urlParams = {
      projects: t('projects'),
      teamId: this.teamId,
      teamName: team.name,
    };
    return (
      <ObjectSummary params={urlParams} />
    );
  }

  renderHeader() {
    return (
      <>
        {this.renderObjectSummary()}
      </>
    );
  }

  renderBody() {
    const isTeamManager = MAuth.isTeamManager(this.teamId);
    const isLicenseAdminServer = MConfigs.isLicenseAdminServer;

    return (
      <>
        {(isLicenseAdminServer && isTeamManager) && this.renderCreateProject()}
        {this.renderProjectTable()}
      </>
    );
  }

  render() {
    const { team } = this.state;
    if (team) {
      return (
        <DefaultLayout
          renderHeader={this.renderHeader}
          renderBody={this.renderBody}
        />
      );
    }
    return null;
  }
}

export default Projects;
