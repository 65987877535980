import React from 'react';
import TestCaseDataTable from '../../components/table/TestCaseDataTable';
import { buildSearchCondition } from '../search/SearchUtils';

class RequirementDetailsDataTable extends React.Component {

  render() {
    if (!this.props.requirementId) {
      return null;
    }
    return (
      <TestCaseDataTable
        defaultSearchConditions={[
          buildSearchCondition('ExternalIssue.id', '=', this.props.requirementId),
        ]}
      />
    );
  }
}

export default RequirementDetailsDataTable;
