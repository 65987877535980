import React, { useState } from 'react';
import { groupBy, get } from 'lodash';
import PropTypes from 'prop-types';
import { IconButton, Tooltip, Collapse } from '@mui/material';
import DataLoader from '../../../components/table/DataLoader';
import { t } from '../../../i18n/t';
import { buildSearchFunction } from '../../../components/search/SearchUtils';
import Time from '../../../utils/Moment';
import { maxDateInChart, TestRunDailyGroupByOptions, defaultGroupByTime } from '../../../utils/Constants';
import TestRunDailyChart from './TestRunDailyChart';
import { IconEmptySet } from '../../../images/CustomIcon';
import MFlags from '../../../models/MFlags';
import DateHelper from '../../../utils/DateHelper';
import { IconCollapseGray, IconExpandGray } from '../../../images/CustomNewIcon';
import { getLocalStorageItem, setLocalStorageItem } from '../../../utils/LocalStorageHelper';
import { IS_EXPAND_TEST_RUN_DAILY_REPORT } from '../../../utils/LocalStorageKey';
import MAuth from '../../../models/MAuth';

const TestRunDailyReportV2 = ({ dateRange, timeInterval, handleChangeTimeInterval, urlSearchValue, ...props }) => {

  const userId = MAuth.user.id;
  const getDefaultExpandState = () => getLocalStorageItem(`${IS_EXPAND_TEST_RUN_DAILY_REPORT}-${userId}`) !== 'false';
  const [expand, setExpand] = useState(getDefaultExpandState());

  const startDate = DateHelper.getStartDate(dateRange);

  const endDate = DateHelper.getNormalizeEndDate(dateRange);

  const handleCollapse = () => {
    const newExpandState = !expand;
    setLocalStorageItem(`${IS_EXPAND_TEST_RUN_DAILY_REPORT}-${userId}`, newExpandState);
    setExpand(newExpandState);
  };

  const convertDataForChart = (data) => {
    // extract data
    const convertedData = data.map(({ content }) => ({
      ...content
    }));

    // group data by time
    const groupByData = groupBy(convertedData, 'time') || [];
    // Convert startTime from filter to get the first day by unit(day, week, month)
    const startTimeByUnit = Time.startOf(startDate, timeInterval);
    const timeRange = Time.timeBetween(startTimeByUnit, endDate, `${timeInterval}`, Time.LOCAL_DATE_FORMAT);

    return timeRange.map((time) => {
      const newValue = {
        time,
        total: 0,
        PASSED: 0,
        FAILED: 0,
      };
      if (groupByData[time]) {
        groupByData[time].forEach((value) => {
          newValue[value.status] = get(newValue, value.status, 0) + get(value, 'total');
          newValue.total += newValue[value.status];
        });
      }

      if (MFlags.fixPercentageTestRunChart) {
        if (newValue.PASSED + newValue.FAILED > 0) {
          newValue.ratioPASSED = newValue.PASSED / (newValue.PASSED + newValue.FAILED);
          newValue.ratioFAILED = 1 - newValue.ratioPASSED;
        } else {
          newValue.ratioPASSED = 0;
          newValue.ratioFAILED = 0;
        }
      } else if (newValue.total) {
        newValue.ratioPASSED = newValue.PASSED / newValue.total;
        newValue.ratioFAILED = 1 - newValue.ratioPASSED;
      }

      return newValue;
    });
  };

  const renderEmptyData = () => (
    <div className="empty-daily-report-chart">
      <IconEmptySet className="mb-3" />
      <div className="d-flex justify-content-center">
        <p className="warning-empty-text">
          {t('test-run-daily-report#empty-data')}
        </p>
      </div>
    </div>
  );

  const renderChart = (data) => {
    const calculateDate = (endDate || startDate).diff(startDate, defaultGroupByTime);
    const isRenderChart = calculateDate <= maxDateInChart;
    const convertedData = convertDataForChart(data);

    const renderOldCard = () => (
      <>
        <div className="header-title">{t('test-run-report-by-status')}</div>
        {
          MFlags.testPerformanceForReportAndAnalyticsEnabled || isRenderChart ?
            <TestRunDailyChart
              data={convertedData}
              customBarSize={60}
              intervalXAxis="preserveStartEnd"
            /> :
            renderEmptyData()
        }
      </>
    );

    const renderCard = () => (
      <>
        <div className="row m-0">
          <div className="col mt-1 p-0">
            <div className="header-title">{t('test-run-report-by-status')}</div>
          </div>
          <div className="d-flex align-items-center justify-content-end p-0">
            <IconButton aria-label="collapse" className="p-0" onClick={handleCollapse}>
              {expand ?
                <Tooltip title={t('collapse')} placement="bottom-end" arrow="arrow">
                  <IconCollapseGray />
                </Tooltip> :
                <Tooltip title={t('expand')} placement="bottom-end" arrow="arrow">
                  <IconExpandGray />
                </Tooltip>}
            </IconButton>
          </div>
        </div>
        <Collapse in={expand}>
          {
            MFlags.testPerformanceForReportAndAnalyticsEnabled || isRenderChart ?
              <TestRunDailyChart
                data={convertedData}
                customBarSize={60}
                intervalXAxis="preserveStartEnd"
              /> :
              renderEmptyData()
          }
        </Collapse>
      </>
    );

    return (
      <div>
        <hr className="horizontal-line mb-3" />
        {MFlags.collapseTestRunsReportChartEnabled ? renderCard() : renderOldCard()}
      </div>
    );
  };

  const onFilterChange = (filterStr) => {
    if (props.handleFilterChange) {
      props.handleFilterChange(filterStr);
    }
  };

  const onCustomFieldConditionChange = (customFieldConditions) => {
    if (props.handleCustomFieldConditionsChange) {
      props.handleCustomFieldConditionsChange(customFieldConditions);
    }
  };

  if (MFlags.fixTestRunReportPageSizeEnabled) {
    return (
      <DataLoader
        onSearchQueryChange={onFilterChange}
        onCustomFieldConditionChange={onCustomFieldConditionChange}
        defaultSearchFunctions={[
          buildSearchFunction('total', 'count_distinct', ['id']),
          buildSearchFunction('time', `group_by_${timeInterval}`, ['startTime']),
        ]}
        groupBys={['status']}
        defaultSort={[
          'time, desc',
        ]}
        pageSize={300}
        render={renderChart}
        defaultSearchString={urlSearchValue}
        {...props}
      />
    );
  }

  return (
    <DataLoader
      onSearchQueryChange={onFilterChange}
      onCustomFieldConditionChange={onCustomFieldConditionChange}
      defaultSearchFunctions={[
        buildSearchFunction('total', 'count_distinct', ['id']),
        buildSearchFunction('time', `group_by_${timeInterval}`, ['startTime']),
      ]}
      groupBys={['status']}
      defaultSort={[
        'time, desc',
      ]}
      render={renderChart}
      defaultSearchString={urlSearchValue}
      {...props}
    />
  );
};

TestRunDailyReportV2.propTypes = {
  handleFilterChange: PropTypes.func,
  handleCustomFieldConditionsChange: PropTypes.func,
  timeInterval: PropTypes.string,
  handleChangeTimeInterval: PropTypes.func,
  urlSearchValue: PropTypes.string,
};

TestRunDailyReportV2.defaultProps = {
  handleFilterChange: null,
  handleCustomFieldConditionsChange: null,
  timeInterval: TestRunDailyGroupByOptions.DAILY.value,
  handleChangeTimeInterval: null,
  urlSearchValue: '',
};

export default TestRunDailyReportV2;
