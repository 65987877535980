import React from 'react';
import {
  CardHeader,
  Button,
  Typography,
  Stack,
  Link,
  CardContent,
  Divider
} from '@mui/material';
import CopyIcon from '../../../../images/icons/katalonui/CopyIcon';
import FileIcon from '../../../../images/icons/katalonui/FileIcon';
import ClockIcon from '../../../../images/icons/katalonui/ClockIcon';
import DecoratorConstants from '../../../utils/DecoratorConstants';
import { getExceptionMessage } from '../../test_reports/test_result_reports/utils';
import Notification from '../../../utils/Notification';
import { t } from '../../../i18n/t';

interface ErrorDetailPopperProps {
  exception: string;
  lastTestResultName: string;
  lastTestResultUrl: string;
  stackTrace: string;
}

function ErrorDetailPopper(props: ErrorDetailPopperProps) {

  const {
    exception,
    lastTestResultName,
    lastTestResultUrl,
    stackTrace
  } = props;

  const handleCopyToClipBoard = () => {
    navigator.clipboard.writeText(stackTrace).then(() => {
      Notification.pushSuccess(t('error-message-copied'));
    });
  };

  return (
    <div>
      <CardHeader
        className="error-detail-popper"
        action={
          <Button onClick={handleCopyToClipBoard} aria-label="copy">
            <CopyIcon />
          </Button>
        }
        title={
          <Typography variant="h6">
            {getExceptionMessage(exception)}
          </Typography>
        }
        subheader={
          <Stack className="mt-2" direction="row" spacing={2}>
            <Typography variant="body2" color="text.secondary">
              <ClockIcon textSize="0.8rem" /> {t('last-seen')} {DecoratorConstants.timeDecorator('lastSeen', props)}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              <FileIcon textSize="0.8rem" />
              {' '}
              <Link href={lastTestResultUrl} color="inherit" underline="hover" target="_blank" rel="noopener noreferrer">
                {DecoratorConstants.truncateLeftStringDecorator(lastTestResultName, 30)}
              </Link>
            </Typography>
          </Stack>
        }
      />
      <Divider />
      <CardContent className="error-detail-popper-card-content">
        <pre className="text-failed-color text-wrap">{stackTrace}</pre>
      </CardContent>
    </div>
  );
}

export default ErrorDetailPopper;
