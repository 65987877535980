import React, { Component } from 'react';
import StatusBar from '../../../components/chart/StatusBar';
import Arrays from '../../../utils/Arrays';
import Statuses from '../../../utils/Statuses';

class ExecutionTestRunStatus extends Component {
  render() {
    const { execution } = this.props;
    const data = [
      ...Arrays.insertIf(execution.totalPassedTests, {
        label: Statuses.PASSED.label,
        value: execution.totalPassedTests,
        color: 'passed',
      }),
      ...Arrays.insertIf(execution.totalFailedTests, {
        label: Statuses.FAILED.label,
        value: execution.totalFailedTests,
        color: 'failed'
      }),
      ...Arrays.insertIf(execution.totalErrorTests, {
        label: Statuses.ERROR.label,
        value: execution.totalErrorTests,
        color: 'error'
      }),
      ...Arrays.insertIf(execution.totalIncompleteTests, {
        label: Statuses.INCOMPLETE.label,
        value: execution.totalIncompleteTests,
        color: 'incomplete'
      })
    ];
    return (
      <StatusBar items={data} {...this.props} />
    );
  }
}

export default ExecutionTestRunStatus;
