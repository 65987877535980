import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
            theme.palette.mode === 'light' ? 'rgb(121,131,145)' : theme.palette.grey[300],
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

const useStyles = makeStyles({
  button: {
    height: '2.71rem',
    paddingRight: '0.357rem',
    backgroundColor: '#276ef1',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#276ef1',
      color: '#fff',
    },
  } });

export default function MenuButton(props) {

  const classes = useStyles();
  const { title, options, onChange } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClickButton = () => {
    onChange(options[0]);
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickItem = (item) => {
    onChange(item);
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        className={classes.button}
        id="customized-button"
        aria-controls={open ? 'customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        onClick={handleClickButton}
        endIcon={
          <>
            <span className="react-select__indicator-separator css-indicatorSeparator" />
            <div onClick={(event) => handleClick(event)} className="react-select__indicator pl-0"><KeyboardArrowDownIcon /></div>
          </>
        }
      >{title}
      </Button>
      <StyledMenu
        id="customized-menu"
        MenuListProps={{
          'aria-labelledby': 'customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {options.map((item, i) => (
          <MenuItem key={i} onClick={() => handleClickItem(item)} disableRipple>
            {item.label}
          </MenuItem>
        ))}

      </StyledMenu>
    </div>
  );
}
