import { Typography } from '@mui/material';
import React, { useEffect, useRef } from 'react';
import { buildSearchCondition } from '../../search/SearchUtils';
import SearchableFilter from './SearchableFilter';
import { SearchEntity } from '../../../utils/Constants';

const ProfileFilterV2 = (props) => {

  const filterMenu = useRef();

  const buildSearchConditions = (searchInput) => ([
    buildSearchCondition('profile', 'contains', searchInput || ''),
  ]);

  const dataMapping = (data) => data.map(({ content }) => ({
    id: content.profile,
    name: content.profile
  }));

  const renderOption = (option) => (
    <Typography noWrap>{option.name}</Typography>
  );

  useEffect(() => {
    if (!props.value) {
      filterMenu.current.clear();
    }
  }, [props.value]);

  return (
    <SearchableFilter
      ref={filterMenu}
      entityType={SearchEntity.ExecutionTestResult}
      label="Profile"
      searchKey="name"
      sorts={['profile,asc']}
      groupBys={['profile']}
      dataMapping={dataMapping}
      buildSearchConditions={buildSearchConditions}
      renderOption={renderOption}
      {...props}
    />
  );
};

export default ProfileFilterV2;
