import React from 'react';
import { Button, Card, CardBody, CardHeader, Col, Form, FormGroup, FormText, Label, Row } from 'reactstrap';
import { IconButton } from '@mui/material';
import { isEqual } from 'lodash';
import DefaultLayout from '../../components/DefaultLayout';
import Input from '../../components/Input';
import PageComponent from '../../components/PageComponent';
import { buildSearchCondition } from '../../components/search/SearchUtils';
import ObjectSummary from '../../components/summary/ObjectSummary';
import JobTable from '../../components/table/JobTable';
import { t } from '../../i18n/t';
import MContext from '../../models/MContext';
import DecoratorConstants from '../../utils/DecoratorConstants';
import Notification from '../../utils/Notification';
import Services from '../../utils/Services';
import PageButtonToolbar from '../../components/PageButtonToolbar';
import Routes from '../../utils/Routes';
import Arrays from '../../utils/Arrays';
import MFlags from '../../models/MFlags';
import {
  openScheduleDialogLocalEnv,
  track
} from '../../components/smarttestscheduling/services/scheduleTestRunTracking';
import { IconArrowLeft } from '../../images/CustomIcon';
import AgentProjectLink from '../../components/agentproject/AgentProjectLink';

class AgentDetails extends PageComponent {
  constructor(props) {
    super(props);
    this.projectId = MContext.projectId;
    this.agentId = MContext.agentId;
    this.teamId = MContext.teamId;
    this.organizationId = MContext.organizationId;
    this.isOrganizationScope = !!this.organizationId;

    this.meta.id = 'page-agent-details';
    this.meta.title = 'Agent Details';

    this.renderHeader = this.renderHeader.bind(this);
    this.renderBody = this.renderBody.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.updateAgent = this.updateAgent.bind(this);
    this.handleClickScheduleTestRunButton = this.handleClickScheduleTestRunButton.bind(this);
    this.handleOnSelectChange = this.handleOnSelectChange.bind(this);
  }

  state = {
    agent: null,
    threshold: 1,
    allowUpdate: false,
    linkedProjects: [],
  };

  getAgent() {
    Services.getAgent(this.agentId)
      .then((response) => {
        const agent = response;
        this.meta.title = `Agent Details - ${agent.name}`;
        this.setState({
          agent,
          threshold: agent.threshold,
          linkedProjects: agent.filteredLinkedProjects,
        });
      });
  }

  componentDidMount() {
    this.getAgent();
  }

  handleChange(event) {
    event.preventDefault();
    const threshold = event.target.value;

    let allowUpdate = true;
    const { agent } = this.state;
    if (Number(threshold) === Number(agent.threshold)) {
      allowUpdate = false;
    }

    this.setState({
      threshold,
      allowUpdate,
    });
  }

  renderObjectSummary() {
    const urlParams = {
      agentDetails: t('agent'),
    };
    return (
      <ObjectSummary params={urlParams} />
    );
  }

  renderHeader() {
    return (
      <>
        {this.renderObjectSummary()}
      </>
    );
  }

  updateAgent(event) {
    if (event && event.preventDefault) {
      event.preventDefault();
    }

    const { agent, threshold, linkedProjects } = this.state;
    agent.threshold = threshold;

    if (MFlags.moveAgentToOrgLevelPhase2Enabled) {
      agent.projectIds = linkedProjects.map((project) => project.id);
      Services.updateAgent(agent).then(() => {
        Notification.pushSuccess(`Update agent ${agent.name} is success!`);
        this.setState({
          allowUpdate: false,
        });
      });
    } else {
      Services.updateAgentThreshold(agent)
        .then(() => {
          Notification.pushSuccess(`Update threshold for ${agent.name} is success!`);
          return this.getAgent();
        });
    }
  }

  handleOnSelectChange(selectedProjects) {
    let allowUpdate = true;
    const { agent } = this.state;

    if (isEqual(selectedProjects, agent.projects)) {
      allowUpdate = false;
    }

    this.setState({
      linkedProjects: selectedProjects,
      allowUpdate
    });
  }

  renderAgent() {
    const { agent, threshold, allowUpdate, linkedProjects } = this.state;
    if (!agent) {
      return null;
    }

    const routes = new Routes({ organizationId: this.organizationId });
    const title = agent.active ? 'Online' : 'Offline';
    return (
      <Card>
        <CardHeader>
          {MFlags.moveAgentToOrgLevelPhase2Enabled ?
            <div className="app-agent-header">
              <IconButton size="small" href={routes.organization_setting_agent_link}>
                <IconArrowLeft className="app-agent-header__back_button" />
              </IconButton>
              <div>{t('agent#details')}</div>
            </div> :
            t('agent#details')}
        </CardHeader>
        <CardBody>
          <Row>
            <Col sm="12" md="8" lg="8" xl="5">
              <Form data-trackid="update-agent" onSubmit={this.updateAgent}>
                <FormGroup>
                  <Label for="agent-name">{t('agent#name')}</Label>
                  <Input
                    plaintext
                    id="agent-name"
                    className="app-agent-row__status_agent"
                    title={title}
                  >
                    {DecoratorConstants.agentStatusDecorator(agent.active)}
                    {agent.name}
                  </Input>
                </FormGroup>
                <FormGroup>
                  <Label for="agent-version">{t('agent#version')}</Label>
                  <Input plaintext id="agent-version">{agent.agentVersion}</Input>
                </FormGroup>
                <FormGroup>
                  <Label for="agent-uuid">{t('agent#uuid')}</Label>
                  <Input plaintext id="agent-uuid">{agent.uuid}</Input>
                </FormGroup>
                <FormGroup>
                  <Label for="agent-ip">{t('agent#ip')}</Label>
                  <Input plaintext id="agent-ip">{agent.ip}</Input>
                </FormGroup>
                <FormGroup>
                  <Label for="agent-last-ping">{t('agent#last-ping')}</Label>
                  <Input plaintext id="agent-last-ping">{DecoratorConstants.timeDecoratorValue(agent.lastPing)}</Input>
                </FormGroup>
                <FormGroup>
                  <Label for="agent-hostname">{t('agent#hostname')}</Label>
                  <Input plaintext id="agent-hostname">{agent.hostname}</Input>
                </FormGroup>
                <FormGroup>
                  <Label for="agent-num-assigned-jobs">{t('agent#num-assigned-jobs')}</Label>
                  <Input plaintext id="agent-num-assigned-jobs">{agent.numAssignedJobs}</Input>
                </FormGroup>
                <FormGroup>
                  <Label for="agent-num-executing-jobs">{t('agent#num-executing-jobs')}</Label>
                  <Input plaintext id="agent-num-executing-jobs">{agent.numExecutingJobs}</Input>
                </FormGroup>
                <FormGroup>
                  <Label for="agent-threshold">{t('agent#threshold')}</Label>
                  <Input
                    required
                    id="agent-threshold"
                    className="app-agent-row__threshold"
                    type="number"
                    min={1}
                    value={threshold}
                    onChange={this.handleChange}
                    data-trackid="agent-threshold"
                  />
                  <FormText color="muted">
                    {t('agent#threshold-warning')}
                  </FormText>
                </FormGroup>
                {MFlags.moveAgentToOrgLevelPhase2Enabled && (
                <FormGroup>
                  <AgentProjectLink selectedProjects={linkedProjects} handleOnSelectChange={this.handleOnSelectChange} />
                </FormGroup>
                )}
                <FormGroup>
                  <Button
                    type="submit"
                    color="primary"
                    disabled={!allowUpdate}
                    id="agent-update"
                  >
                    {t('agent#update')}
                  </Button>
                </FormGroup>
              </Form>
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  }

  renderJobsByAgent() {
    const idTrackingProps = {
      'data-trackid': 'click-session-details-local-agent',
    };

    return (
      <JobTable
        defaultSearchConditions={[
          ...Arrays.insertIf(!MFlags.moveAgentToOrgLevelPhase2Enabled, buildSearchCondition('Project.id', '=', this.projectId)),
          ...Arrays.insertIf(MFlags.moveAgentToOrgLevelPhase2Enabled, buildSearchCondition('Organization.id', '=', this.organizationId)),
          buildSearchCondition('Agent.id', '=', this.agentId),
        ]}
        defaultSort="order,desc"
        autoUpdate
        tableId="Jobs"
        title={t('jobs')}
        idTrackingProps={idTrackingProps}
      />
    );
  }


  handleClickScheduleTestRunButton() {
    track(openScheduleDialogLocalEnv());
  }

  renderCreateTestPlan(teamId, projectId) {
    const routes = new Routes({ teamId, projectId });
    return (
      <Button
        title={t('create-plan')}
        color="secondary"
        href={routes.smart_test_scheduling_link()}
        disabled={MFlags.preventScheduleTestRunEnabled}
        onClick={() => this.handleClickScheduleTestRunButton()}
      >
        {t('create-plan')}
      </Button>
    );
  }

  renderButtons() {
    return (
      <PageButtonToolbar>
        {(!MFlags.moveAgentToOrgLevelPhase2Enabled || !this.isOrganizationScope) && this.renderCreateTestPlan(this.teamId, this.projectId)}
      </PageButtonToolbar>
    );
  }

  renderBody() {
    return (
      <div>
        {this.renderButtons()}
        {this.renderAgent()}
        {this.renderJobsByAgent()}
      </div>
    );
  }

  render() {
    return (
      <DefaultLayout
        renderHeader={this.renderHeader}
        renderBody={this.renderBody}
      />
    );
  }
}

export default AgentDetails;
