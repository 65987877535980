import React, { useState } from 'react';
import { Alert } from 'reactstrap';
import { t } from '../../i18n/t';
import DocumentLink from '../../utils/DocumentLink';

function BannerUpgradeRequest() {
  const [isShowFlag, setIsShowFlag] = useState(true);

  function handleChange() {
    setIsShowFlag(false);
  }

  return (
    <Alert
      className="test-upgrade-alert"
      color="primary"
      isOpen={isShowFlag}
      toggle={handleChange}
    >
      {t('upgrade_request_for_premium_plan')}
      <a
        href={DocumentLink.KATALON_TESTOPS_PACKAGE_DETAILS}
        target="_blank"
        rel="noreferrer noopener"
      >
        <b>{t('katalon_pricing')}</b>
      </a>
      .
    </Alert>
  );
}

export default BannerUpgradeRequest;
