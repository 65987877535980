import React from 'react';
import MPageMeta from '../models/MPageMeta';
import { t } from '../i18n/t';
import MContext from '../models/MContext';
import { analyticsPage } from '../utils/SegmentAnalytics';

class PageComponent extends React.Component {
  constructor(props) {
    super(props);
    MContext.parse(props.match.params);
    this.meta = new MPageMeta(() => {
      analyticsPage(this.meta.id, this.meta.groupId);
      document.title = `${this.meta.title} - ${t('brand_name')}`;
    });
  }
}

export default PageComponent;
