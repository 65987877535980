import React from 'react';
import { ResponsiveContainer, Tooltip, Treemap } from 'recharts';
import { buildSearchCondition } from '../../../components/search/SearchUtils';
import MContext from '../../../models/MContext';
import CustomizedTreemap from './CustomizedTreemap';
import CustomizedTooltipTreemap from './CustomizedTooltipTreemap';
import DataLoader from '../../../components/table/DataLoader';
import { t } from '../../../i18n/t';
import { IconStatus } from '../../../images/KitIcons';
import ColorUtils from '../../../utils/ColorUtils';
import ChartConstants from '../../../utils/ChartConstants';

class RequirementTestRunCoverage extends React.Component {

  constructor(props) {
    super(props);
    this.teamId = MContext.teamId;
    this.projectId = MContext.projectId;

    this.renderData = this.renderData.bind(this);
  }

  renderData(data) {
    if (data && data.length > 0) {
      data = data.filter((o) => o.totalTestRun > 0)
        .sort((a, b) => ((a.totalTestRun < b.totalTestRun) ? 1 : -1));

      return (
        <>
          <ResponsiveContainer width="99%" minWidth={200} aspect={3}>
            <Treemap
              data={data}
              dataKey="totalTestRun"
              stroke="#fff"
              isAnimationActive={false}
              content={<CustomizedTreemap />}
            >
              <Tooltip content={<CustomizedTooltipTreemap />} />
            </Treemap>
          </ResponsiveContainer>
          <div className="requirement-coverage-legend">
            {this.renderLegend()}
          </div>
        </>
      );
    }
    return <div className="text-center">{t('table#empty-message')}</div>;
  }

  renderLegend() {
    const statusColors = ColorUtils.colorScales(ChartConstants.statusColor);
    const colorScale = statusColors.map((color) => <IconStatus className="m-1" key={color} fill={color} />);
    return (
      <div className="d-flex justify-content-end default-legend">
        Failed {colorScale} Passed
      </div>
    );
  }

  render() {
    const newProps = {
      ...this.props,
    };
    return (
      <DataLoader
        entityType="RequirementTestRunCoverage"
        render={this.renderData}
        defaultSearchConditions={[
          buildSearchCondition('Project.id', '=', this.projectId)
        ]}
        defaultSort={['updatedAt,desc']}
        {...newProps}
      />
    );
  }
}

export default RequirementTestRunCoverage;
