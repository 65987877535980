import { Menu, MenuItem } from '@katalon-studio/katalon-ui/Menu';
import React from 'react';
import Tooltip from '@katalon-studio/katalon-ui/Tooltip';
import PropTypes from 'prop-types';
import { PopoverOrigin } from '@mui/material';
import { ACTIONS, TestSuiteType } from '../../../utils/Constants';
import { next } from '../../../utils/Count';
import { t } from '../../../i18n/t';
import { TestEntity } from '../../../models/model/TestEntity';
import MFlags from '../../../models/MFlags';

interface ActionsMenuProps {
  testEntity: TestEntity;
  anchorEl: HTMLElement | null;
  setAnchorEl: (value: React.SetStateAction<HTMLElement | null>) => void,
  actionsWithHandler: any,
  transformOrigin: PopoverOrigin,
  anchorOrigin: PopoverOrigin,
}

function ActionsMenu(props: ActionsMenuProps) {

  const { anchorEl, setAnchorEl, testEntity, actionsWithHandler, transformOrigin, anchorOrigin } = props;
  const { testCase, testSuite } = testEntity;
  const open = Boolean(anchorEl);
  const {
    instantRunTestCasesEnabled,
    updateDeleteTestOpsTestSuiteDialog,
    addTestCaseToTestSuiteEnabled
  } = MFlags;

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event: React.MouseEvent<HTMLLIElement, MouseEvent>, handler?: React.MouseEventHandler<HTMLLIElement>) => {
    if (handler) {
      handler(event);
    }
    setAnchorEl(null);
  };

  const getActionLabel = (action?: string) => {
    let label = `test-management-actions#${action}`;

    if (action === ACTIONS.EDIT) {
      if (testCase) {
        label = `test-management-actions#${action}#test-case`;
      } else if (testSuite) {
        label = `test-management-actions#${action}#test-suite`;
      }
    }
    return t(label);
  };

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
    >
      {actionsWithHandler.map(
        (item: {
                    icon?: React.ReactNode;
                    action?: string;
                    handler?: React.MouseEventHandler<HTMLLIElement>;
                    divider?: boolean;
                    color?: string;
                    isDisabled?: boolean;
                    label: string
                }) => {
          if (
            !updateDeleteTestOpsTestSuiteDialog &&
                        testSuite &&
                        testSuite.type === TestSuiteType.TESTOPS &&
                        item.action === ACTIONS.DELETE
          ) {
            return null;
          }
          if (testCase && !instantRunTestCasesEnabled && item.action === ACTIONS.RUN) {
            return null;
          }
          if (!addTestCaseToTestSuiteEnabled && item.action === ACTIONS.ADD_TO_TEST_SUITE) {
            return null;
          }
          return (
            <Tooltip
              key={next()}
              label={item.label}
              size="medium"
              placement="left-start"
              disableHoverListener={!item.isDisabled}
            >
              <div>
                <MenuItem
                  icon={item.icon}
                  onClick={(event) => handleClick(event, item.handler)}
                  key={next()}
                  divider={item.divider || false}
                  sx={{ width: '220px' }}
                  color={item.color}
                  disabled={item.isDisabled}
                >
                  {getActionLabel(item.action)}
                </MenuItem>
              </div>
            </Tooltip>
          );
        }
      )}
    </Menu>
  );
}

ActionsMenu.propTypes = {
  testEntity: PropTypes.object.isRequired,
  anchorEl: PropTypes.any,
  setAnchorEl: PropTypes.func.isRequired,
  actionsWithHandler: PropTypes.any.isRequired,
  transformOrigin: PropTypes.object,
};

ActionsMenu.defaultProps = {
  transformOrigin: {
    vertical: 'top',
    horizontal: 'right',
  },
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center',
  }
};

export default ActionsMenu;
