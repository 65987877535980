import React, { useState } from 'react';
import { Button } from 'reactstrap';
import classNames from 'classnames';
import Countdown from 'react-countdown';
import { IconYellowWarning } from '../../images/CustomNewIcon';
import WarningPackageAlertComponent from '../WarningPackageAlertComponent';
import MAuth from '../../models/MAuth';
import { t } from '../../i18n/t';
import Services from '../../utils/Services';
import Notification from '../../utils/Notification';
import { DISABLED_RESEND_VERIFY_EMAIL_TIMEOUT } from '../../utils/Constants';

export default function VerifyEmailUserBannerWarning() {
  const { user } = MAuth;

  const [disabledResend, setDisabledResend] = useState(false);
  const [disableUntilDate, setDisableUntilDate] = useState(null);

  const resendVerifyEmail = () => {
    Services.sendActivationEmailKatOne()
      .then(() => {
        setDisabledResend(true);
        setDisableUntilDate(Date.now() + DISABLED_RESEND_VERIFY_EMAIL_TIMEOUT);
        Notification.pushSuccess(t('verify-user-email#success', { email: MAuth.email }));
      }).catch(() => {});

  };
  const renderCountdown = (props) => <span>&nbsp;({props.total / 1000}s)</span>;
  const onStopCountdown = () => {
    setDisabledResend(false);
    setDisableUntilDate(null);
  };
  const buildWarningText = () => (
    <>
      {t('verify-user-email#title')}&nbsp;
      <Button
        onClick={resendVerifyEmail}
        color="link"
        disabled={disabledResend}
        className={classNames('p-0', disabledResend && 'disabled')}
        data-trackid="resend-verification-email-from-in-app-banner"
      >
        {t('verify-user-email#resend')}
        {disableUntilDate &&
          <Countdown
            date={disableUntilDate}
            renderer={renderCountdown}
            onComplete={onStopCountdown}
          />}
      </Button>.
    </>
  );

  return (
    <>
      {user?.verified === false && (
        <WarningPackageAlertComponent
          text={buildWarningText()}
          icon={<IconYellowWarning />}
        />
      )}
    </>
  );
}
