import Box from '@mui/material/Box';
import React from 'react';
import ExecutionContext from './ExecutionContext';
import LogTree from './LogTree';

const LogTreeContainer = ({ execution, log, maxHeight = 500 }) => (
  <Box maxHeight={maxHeight} display="block" overflow="auto">
    <ExecutionContext.Provider value={execution}>
      <LogTree logRecord={JSON.parse(log)} expanded />
    </ExecutionContext.Provider>
  </Box>
);

export default LogTreeContainer;
