import React, { ReactNode, useEffect, useState } from 'react';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import { Skeleton, Typography, Button, IconButton, Link, colors, Box } from '@mui/material';
import ReactMarkdown from 'react-markdown';
import Icon from '@katalon-studio/katalon-ui/Icon';
import GroupEvent from '../../../utils/track/GroupEvent';
import { sendAnalyticEventForAction } from '../../../utils/SegmentAnalytics';
import CopyIcon from '../../../../images/icons/katalonui/CopyIcon';
import ExpandIcon from '../../../../images/icons/katalonui/ExpandIcon';
import RotateRightIcon from '../../../../images/icons/katalonui/RotateRightIcon';
import TriangleExclamationIcon from '../../../../images/icons/katalonui/TriangleExclamationIcon';
import ThumbsUpIcon from '../../../../images/icons/katalonui/ThumbsUpIcon';
import ThumbsDownIcon from '../../../../images/icons/katalonui/ThumbsDownIcon';
import { t } from '../../../i18n/t';
import Notification from '../../../utils/Notification';
import AIBlockDialog from './AIBlockDialog';
import MFlags from '../../../models/MFlags';
import DocumentLink from '../../../utils/DocumentLink';
import { IconEmptySet } from '../../../images/CustomIcon';
import MAuth from '../../../models/MAuth';
import MContext from '../../../models/MContext';
import Apis from '../../../utils/Apis';

interface AIBlockProps {
  text: string | undefined;
  generateButton?: ReactNode;
  isDefaultState?: boolean;
  refresh?: () => void;
  questionId?: string;
  enableAi: boolean;
}

function AIBlock(props: AIBlockProps) {
  const {
    text,
    generateButton,
    refresh,
    isDefaultState = false,
    questionId,
    enableAi,
  } = props;
  const [aiText, setAiText] = useState<string | undefined>(text);
  const [open, setOpen] = useState(false);
  const [liked, setLiked] = useState<string | undefined>(undefined);
  const [disliked, setDisliked] = useState<string | undefined>(undefined);

  const organization = MContext.targetOrganization;
  const isOrganizationOwner = MAuth.isOrganizationOwner(organization.id);
  const organizationManagementUrl = Apis.katOneAccountSettingsPage({ organizationId: organization.id });

  useEffect(() => {
    setAiText(text);
  }, [text, refresh]);

  const handleClose = () => setOpen(false);
  const handleLiked = () => {
    setLiked('black');
    setDisliked(undefined);
    sendAnalyticEventForAction(
      'ra_ai_feedback_ai_block_clicked',
      {
        'data-groupid': GroupEvent.VIRTUAL_DATA_ANALYST,
        question: questionId,
        react: 'like',
      },
    );
  };

  const handleDisliked = () => {
    setDisliked('black');
    setLiked(undefined);
    sendAnalyticEventForAction(
      'ra_ai_feedback_ai_block_clicked',
      {
        'data-groupid': GroupEvent.VIRTUAL_DATA_ANALYST,
        question: questionId,
        react: 'dislike',
      },
    );
  };

  const handleRefresh = () => {
    setAiText(undefined);
    if (refresh) {
      refresh();
    }
  };

  const handleCopyToClipboard = () => {
    if (aiText === undefined) return;
    navigator.clipboard.writeText(aiText).then(() => {
      Notification.pushSuccess(t('ai-insights-copied'));
    });
    sendAnalyticEventForAction(
      'ra_ai_copy_ai_block_clicked',
      { 'data-groupid': GroupEvent.VIRTUAL_DATA_ANALYST, question: questionId }
    );
  };

  const handleOpenDialog = () => {
    setOpen(true);
    sendAnalyticEventForAction(
      'ra_ai_expand_ai_block_clicked',
      { 'data-groupid': GroupEvent.VIRTUAL_DATA_ANALYST, question: questionId }
    );
  };

  if (MFlags.genAISettingsAccountLevelEnabled && !enableAi) {
    return (
      <Box className="ai-block">
        <Box className="py-5" width="100%">
          <Box className="mb-3 d-flex justify-content-center ai-empty-icon">
            <IconEmptySet />
          </Box>
          <Box>
            <Typography className="text-center ai-block-no-permission-text" component="div" width="100%">
              {isOrganizationOwner ? t('ai-no-permission-admin#title') : t('ai-no-permission-member#title')}
            </Typography>
            <Typography className="text-center ai-block-no-permission-text" component="div" width="100%">
              {isOrganizationOwner ? t('ai-no-permission-admin#body') : t('ai-no-permission-member#body')}
            </Typography>
            {isOrganizationOwner && (
              <Box className="configuration-link-wrapper">
                <Box className="configuration-link">
                  <Link href={organizationManagementUrl}>
                    {t('ai-go-to-configuration')}
                  </Link>
                  <Icon
                    type="fa-solid"
                    name="fa-external-link"
                  />
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    );
  }

  if (aiText === undefined) {
    return (
      <div className="ai-block pr-2">
        <AutoAwesomeIcon className="svg_icons" />
        <Typography className="ml-2" component="div" width="100%" key="caption" variant="caption">
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </Typography>
      </div>
    );
  }

  const renderToolBar = () => {
    if (MFlags.dashboardAIPhase2Enabled) {
      return (
        <>
          <Button
            variant="outlined"
            className="ai-refresh ai-interactive-hide"
            onClick={handleRefresh}
          >
            <RotateRightIcon color={colors.common.black} /> {t('refresh')}
          </Button>
          <Button
            variant="outlined"
            className="ai-copy ai-interactive-hide"
            data-testid="ai-copy"
            onClick={handleCopyToClipboard}
          >
            <CopyIcon color={colors.common.black} />
          </Button>
          <Button
            variant="outlined"
            className="ai-interactive-hide"
            data-testid="ai-expand"
            onClick={handleOpenDialog}
          >
            <ExpandIcon color={colors.common.black} />
          </Button>
        </>
      );
    }
    return (
      <Button
        variant="outlined"
        className="ai-interactive-hide"
        onClick={handleCopyToClipboard}
      >
        <CopyIcon />
      </Button>
    );
  };

  const renderFeedback = () => {
    if (MFlags.dashboardAIPhase2Enabled) {
      return (
        <div className="ai-interactive-hide ai-feedback">
          <span className="ml-3 ai-feedback-text text-secondary">
            <TriangleExclamationIcon />
            {' '}{t('ai-inaccurate-misleading')}{' '}
            <Link
              href={DocumentLink.VIRTUAL_DATA_ANALYST}
              className="text-secondary"
              underline="none"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('learn_more')}
            </Link>
          </span>
          <span className="float-right mr-3">
            <IconButton onClick={handleLiked} size="small" aria-label="thumbs-up">
              <ThumbsUpIcon color={liked} />
            </IconButton>
            <IconButton onClick={handleDisliked} size="small" aria-label="thumbs-down">
              <ThumbsDownIcon color={disliked} type="fa-regular fa-flip-horizontal" />
            </IconButton>
          </span>
        </div>
      );
    }
    return null;
  };

  return (
    <div className={isDefaultState ? '' : 'ai-interactive'}>
      <div className="ai-block">
        {renderToolBar()}
        <AutoAwesomeIcon className="svg_icons" />
        <ul className="ai-block-comment">
          <ReactMarkdown>{aiText}</ReactMarkdown>
          {generateButton}
        </ul>
        <AIBlockDialog isOpen={open} onClose={handleClose} text={aiText} />
        {renderFeedback()}
      </div>
    </div>
  );
}

export default AIBlock;
