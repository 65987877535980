import jquery from 'jquery';
import Notification from '../Notification';

const githubService = {
  async getShaKey(owner, repo, path) {
    try {
      const data = await fetch(`${process.env.REACT_APP_GITHUB_API_URL}/repos/${owner}/${repo}/contents/${path}`);
      const json = await data.json();
      return json?.sha;
    } catch (e) {
      Notification.pushError(`get sha key error: ${e.message}`);
      return '';
    }
  },

  async extractBlob(owner, repo, sha) {
    try {
      const data = await fetch(`${process.env.REACT_APP_GITHUB_API_URL}/repos/${owner}/${repo}/git/blobs/${sha}`);
      const blob = await data.json();
      return blob;
    } catch (e) {
      Notification.pushError(`extract blob error: ${e.message}`);
      return '';
    }
  },

  async getXmlFileFromGithub(owner, repo, path) {
    try {
      const shaKey = await this.getShaKey(owner, repo, path);
      const blob = await this.extractBlob(owner, repo, shaKey);
      return jquery.parseXML(atob(blob.content));
    } catch (e) {
      Notification.pushError(`get xml file from github error: ${e.message}`);
      return null;
    }
  }
};

export default githubService;
