import request from '../../pages/util/request';
import MContext from '../../models/MContext';

const storageService = {
  getSignedUrl() {
    return request.get('/ees/apps/upload-url');
  },
  createApp(data) {
    return request.post('/ees/apps', data);
  },

  getAppsByStatus(status, page, size, sort) {
    const query = `status==${status} and organizationId==${MContext.team?.organizationId}`;
    return request.get(
      '/ees/apps',
      {
        params: {
          q: query,
          pageToken: page,
          size,
          sort
        }
      }
    ).then((result) => ({ items: result?.data?.items, count: result?.data?.count }));
  },

  searchAppsByName(name, page, size, sort) {
    const query = `name==*${name}* and organizationId==${MContext.team?.organizationId}`;
    return request.get(
      '/ees/apps',
      {
        params: {
          q: query,
          pageToken: page,
          size,
          sort
        }
      }
    ).then((result) => ({ items: result?.data?.items, count: result?.data?.count }));
  },

  fetchAllApps(page, size, sort) {
    return request.get(
      '/ees/apps',
      {
        params: {
          q: `organizationId==${MContext.team?.organizationId}`,
          pageToken: page,
          size,
          sort
        }
      }
    ).then((result) => ({ items: result?.data?.items, count: result?.data?.count }));
  },

  deleteApp(id) {
    return request.delete('/ees/apps', {
      data: [
        {
          id,
          purge: false
        }
      ]
    });
  }
};

export default storageService;
