import React from 'react';
import _ from 'lodash';
import { Button, Row, Col, Form, FormGroup, Card, CardBody, Label, ButtonToolbar } from 'reactstrap';
import momentLocalizer from 'react-widgets-moment';
import DateTimePicker from 'react-widgets/lib/DateTimePicker';
import PageComponent from '../../components/PageComponent';
import { t } from '../../i18n/t';
import Services from '../../utils/Services';
import Input from '../../components/Input';
import Select from '../../components/Select';
import MContext from '../../models/MContext';
import CreateLicenseKeyDialog from '../../components/dialog/CreateLicenseKeyDialog';
import MAuth from '../../models/MAuth';
import Routers from '../../utils/Routes';
import { notificationIds } from '../../utils/NotificationPageUtils';
import DefaultLayout from '../../components/DefaultLayout';
import ObjectSummary from '../../components/summary/ObjectSummary';
import Helper from '../../utils/Helper';
import Moment from '../../utils/Moment';
import MachineIdScreenshot1Img from '../../../images/machine_id_screenshot_1.png';
import MachineIdScreenshot2Img from '../../../images/machine_id_screenshot_2.png';
import { OrganizationFeature } from '../../utils/Constants';
import { getTestingLicenseFileName } from '../../utils/LicenseFileUtils';
import { TEST_EXECUTION_UNIT } from '../testops_subscription/SubscriptionConstant';

class OrganizationCreateLicenseKey extends PageComponent {
  constructor(props) {
    super(props);
    this.meta.id = 'page-organization-create-licenses';
    this.meta.title = t('license_keys');
    this.organizationId = MContext.organizationId;
    this.licenseFeature = MContext.licenseFeature.toUpperCase();
    this.defaultExpiryDate = new Date((new Date()).getTime() + (15 * 24 * 60 * 60 * 1000));

    this.createLicenseKeyDialogRef = React.createRef();

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleTestOpsOPLicenseChange = this.handleTestOpsOPLicenseChange.bind(this);
    this.handleTestOpsOPLicenseInputChange = this.handleTestOpsOPLicenseInputChange.bind(this);
    this.handleTestOpsOPExecutionQuotaInputChange = this.handleTestOpsOPExecutionQuotaInputChange.bind(this);
    this.handleTimeChange = this.handleTimeChange.bind(this);
    this.createLicenseKey = this.createLicenseKey.bind(this);
    this.createTestingLicenseKey = this.createTestingLicenseKey.bind(this);
    this.selectLicenseKey = this.selectLicenseKey.bind(this);
    this.getOrganization = this.getOrganization.bind(this);
    this.onCreatingLicenseKey = this.onCreatingLicenseKey.bind(this);
    this.onCreatingDoneLicenseKey = this.onCreatingDoneLicenseKey.bind(this);
    this.onCreatingSuccess = this.onCreatingSuccess.bind(this);
    this.renderBody = this.renderBody.bind(this);
    this.renderHeader = this.renderHeader.bind(this);

    momentLocalizer();
    this.state = {};
  }

  getDefaultState(organization) {
    let quota;
    let usedLicenses;
    switch (this.licenseFeature) {
      case OrganizationFeature.KSE:
        quota = organization.quotaKSE;
        usedLicenses = organization.usedKSE;
        break;
      case OrganizationFeature.UNLIMITED_KSE:
        quota = organization.quotaUnlimitedKSE;
        usedLicenses = organization.usedUnlimitedKSE;
        break;
      case OrganizationFeature.UNLIMITED_ENGINE:
        quota = organization.quotaUnlimitedEngine;
        usedLicenses = organization.usedUnlimitedEngine;
        break;
      case OrganizationFeature.TESTOPS:
        quota = organization.quotaTestOps;
        usedLicenses = organization.usedTestOps;
        break;
      case OrganizationFeature.ENGINE:
      default:
        quota = organization.quotaEngine;
        usedLicenses = organization.usedEngine;
    }
    return {
      disabledInput: this.licenseFeature === OrganizationFeature.TESTOPS ? false : usedLicenses >= quota,
      organization: organization,
      expDate: '',
      newLicenseKey: {
        machineId: '',
        description: '',
        number: 1,
        expirationDate: this.defaultExpiryDate,
      },
      testOpsLicense: {
        machineId: '',
        expirationDate: new Date(),
        numberUser: 1,
        kseQuota: 0,
        unlimitedKseQuota: 0,
        kreQuota: 0,
        unlimitedKreQuota: 0,
        testOpsQuota: 0,
      },
      modal: false,
    };

  }

  getOrganization() {
    Services.getOrganization(this.organizationId)
      .then((organization) => {
        this.setState(this.getDefaultState(organization));
      });
  }

  createLicenseKey(e) {
    e.preventDefault();
    this.createLicenseKeyDialogRef.current.toggle();
  }

  createTestingLicenseKey(e) {
    e.preventDefault();
    const { newLicenseKey } = this.state;
    const licenseKeyData = {
      ...newLicenseKey,
      organizationId: this.organizationId,
      type: 'ENTERPRISE',
      feature: this.licenseFeature
    };

    Services.createTestingLicense(licenseKeyData)
      .then((license) => {
        const machineId = _.get(license, 'machineId');
        const feature = _.get(license, 'feature');
        const file = new Blob([_.get(license, 'value')], { type: 'text/plain' });
        const filename = getTestingLicenseFileName(feature, machineId);
        const downloadUrl = URL.createObjectURL(file);
        Helper.download(downloadUrl, filename);
      });
  }

  onCreatingLicenseKey() {
    this.setState({ disabledInput: true });
  }

  onCreatingDoneLicenseKey() {
    this.getOrganization();
  }

  onCreatingSuccess() {

  }

  handleInputChange(event) {
    const value = event.target.value;
    const name = event.target.name;
    if (name === 'description') {
      if (value.length > 1000) {
        event.target.setCustomValidity('You have exceeded 1000 characters');
      } else {
        event.target.setCustomValidity('');
      }
    }
    const { newLicenseKey } = this.state;
    newLicenseKey[name] = value;
    this.setState({ newLicenseKey });
  }

  handleTestOpsOPLicenseInputChange(event) {
    const value = event.target.value;
    const name = event.target.name;
    this.handleTestOpsOPLicenseChange(name, value);
  }

  handleTestOpsOPExecutionQuotaInputChange(event) {
    const value = Number(event.target.value) * TEST_EXECUTION_UNIT;
    const name = event.target.name;
    this.handleTestOpsOPLicenseChange(name, value);
  }

  handleTestOpsOPLicenseChange(name, value) {
    const { testOpsLicense } = this.state;
    testOpsLicense[name] = value;
    this.setState({ testOpsLicense });
  }

  handleTimeChange(value) {
    const { newLicenseKey } = this.state;
    newLicenseKey.expirationDate = value || this.defaultExpiryDate;
    this.setState({ newLicenseKey });
  }

  selectLicenseKey(event) {
    event.target.select();
  }

  convertTestExecutionQuota(quota) {
    return quota / TEST_EXECUTION_UNIT;
  }

  renderCreateLicenseKeyDialog() {
    const isCreateTestOpsLicense = this.licenseFeature === 'TESTOPS';
    const { newLicenseKey, testOpsLicense } = this.state;
    const licenseKey = isCreateTestOpsLicense ? testOpsLicense : newLicenseKey;
    const licenseType = 'ENTERPRISE';
    const feature = this.licenseFeature;
    return <CreateLicenseKeyDialog
      ref={this.createLicenseKeyDialogRef}
      licenseKey={licenseKey}
      organizationId={this.organizationId}
      type={licenseType}
      feature={feature}
      onCreating={this.onCreatingLicenseKey}
      onCreatingDone={this.onCreatingDoneLicenseKey}
      onCreatingSuccess={this.onCreatingSuccess}
    />;
  }

  renderLicenseGeneration() {
    const { newLicenseKey, disabledInput, expDate } = this.state;
    const { machineId, expirationDate, description } = newLicenseKey;
    const minTime = new Date();
    const maxTime = new Date(expDate);

    return (
      <Row>
        <Col sm="12" md="7">
          <Card>
            <CardBody>
              <Row>
                <Col>
                  <Form onSubmit={this.createLicenseKey}>
                    <FormGroup>
                      <Label htmlFor="machine-id">{t('license_keys#machine_id')}</Label>
                      <Input
                        id="machine-id"
                        name="machineId"
                        disabled={disabledInput}
                        value={machineId}
                        onChange={this.handleInputChange}
                        type="text"
                        required
                        placeholder={t('license_keys#input_machine_id')}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label htmlFor="expirationDate">{t('license_keys#expiry_date')}</Label>
                      <DateTimePicker
                        id="expirationDate"
                        name="endTime"
                        format={Moment.DF_DATE_FORMAT}
                        value={expirationDate}
                        min={minTime}
                        max={maxTime}
                        disabled={disabledInput}
                        onChange={(value) => this.handleTimeChange(value)}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label htmlFor="description">{t('license_keys#description')}</Label>
                      <Input
                        id="description"
                        name="description"
                        disabled={disabledInput}
                        value={description}
                        onChange={this.handleInputChange}
                        type="text"
                      />
                    </FormGroup>
                    <FormGroup>
                      <ButtonToolbar>
                        <Button
                          id="create-license-key"
                          type="submit"
                          color="primary"
                          disabled={disabledInput}
                        >{t('create')}
                        </Button>
                        <Button
                          data-trackid="create-testing-license-key"
                          data-tracklabel={this.licenseFeature}
                          id="create-testing-license-key"
                          color="secondary"
                          title={t('license_keys#create_testting_license_title')}
                          disabled={disabledInput}
                          onClick={this.createTestingLicenseKey}
                        >{t('license_keys#create_testting_license')}
                        </Button>
                      </ButtonToolbar>
                    </FormGroup>
                  </Form>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col md="5">
          <Card style={({
            background: '#E9EBF2'
          })}
          >
            <CardBody>
              <Row className="justify-content-center">
                <Col>
                  <img className="img-fluid img-thumbnail" src={MachineIdScreenshot1Img} alt="How to get machine id" />
                  <img className="img-fluid img-thumbnail mt-3" src={MachineIdScreenshot2Img} alt="How to get machine id" />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    );
  }

  renderTestOpsLicenseGeneration() {
    const { disabledInput, testOpsLicense } = this.state;

    const {
      machineId,
      expirationDate,
      numberUser,
      kseQuota,
      unlimitedKseQuota,
      kreQuota,
      unlimitedKreQuota,
      testOpsQuota,
    } = testOpsLicense;

    const minTime = new Date();

    return (
      <Card>
        <CardBody>
          <Row>
            <Col>
              <Form data-trackid="create-testops-license-key" onSubmit={this.createLicenseKey}>
                <FormGroup>
                  <Label htmlFor="machine-id">{t('license_keys#machine_id')}</Label>
                  <Input
                    id="machine-id"
                    name="machineId"
                    disabled={disabledInput}
                    value={machineId}
                    onChange={this.handleTestOpsOPLicenseInputChange}
                    type="text"
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="expirationDate">{t('license_keys#expiry_date')}</Label>
                  <DateTimePicker
                    id="expirationDate"
                    name="endTime"
                    format={Moment.DF_DATE_FORMAT}
                    value={expirationDate}
                    min={minTime}
                    disabled={disabledInput}
                    onChange={(value) => this.handleTestOpsOPLicenseChange('expirationDate', value)}
                    // this field is required but required param above sometime not working
                    // let make the input of DateTimePicker required
                    inputProps={{ required: true }}
                  />
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="numberUser">{t('license_keys#num_of_user')}</Label>
                  <Input
                    id="numberUser"
                    name="numberUser"
                    disabled={disabledInput}
                    value={numberUser}
                    onChange={this.handleTestOpsOPLicenseInputChange}
                    min={1}
                    type="number"
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="kseQuota">{t('license_keys#kse_license')}</Label>
                  <Input
                    id="kseQuota"
                    name="kseQuota"
                    disabled={disabledInput}
                    value={kseQuota}
                    onChange={this.handleTestOpsOPLicenseInputChange}
                    type="number"
                    min={0}
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="unlimitedKseQuota">{t('license_keys#unlimited_kse_license')}</Label>
                  <Input
                    id="unlimitedKseQuota"
                    name="unlimitedKseQuota"
                    disabled={disabledInput}
                    value={unlimitedKseQuota}
                    onChange={this.handleTestOpsOPLicenseInputChange}
                    min={0}
                    type="number"
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="kreQuota">{t('license_keys#kre_license')}</Label>
                  <Input
                    id="kreQuota"
                    name="kreQuota"
                    disabled={disabledInput}
                    value={kreQuota}
                    onChange={this.handleTestOpsOPLicenseInputChange}
                    min={0}
                    type="number"
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="unlimitedKreQuota">{t('license_keys#unlimited_kre_license')}</Label>
                  <Input
                    id="unlimitedKreQuota"
                    name="unlimitedKreQuota"
                    disabled={disabledInput}
                    value={unlimitedKreQuota}
                    onChange={this.handleTestOpsOPLicenseInputChange}
                    min={0}
                    type="number"
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="testOpsQuota">{t('license_keys#testops_license')}</Label>
                  <Row className="align-items-center">
                    <Col xs="3" sm="3">
                      <Input
                        id="testOpsQuota"
                        name="testOpsQuota"
                        disabled={disabledInput}
                        value={this.convertTestExecutionQuota(testOpsQuota)}
                        onChange={this.handleTestOpsOPExecutionQuotaInputChange}
                        min={0}
                        type="number"
                        required
                      />
                    </Col>
                    <Col xs="3">
                      000
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup>
                  <ButtonToolbar>
                    <Button
                      id="create-license-key"
                      type="submit"
                      color="primary"
                      disabled={disabledInput}
                    >{t('create')}
                    </Button>
                  </ButtonToolbar>
                </FormGroup>
              </Form>
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  }

  renderBody() {
    const isCreateTestOpsLicense = this.licenseFeature === 'TESTOPS';

    return (
      <>
        {isCreateTestOpsLicense ? this.renderTestOpsLicenseGeneration() : this.renderLicenseGeneration()}
        {this.renderCreateLicenseKeyDialog()}
      </>
    );
  }

  renderHeader() {
    const urlParams = {
      license_keys: t('license_keys'),
    };
    switch (this.licenseFeature) {
      case OrganizationFeature.KSE:
        urlParams.new_kse_license = t('license_keys#create_kse_license_key');
        break;
      case OrganizationFeature.UNLIMITED_KSE:
        urlParams.new_unlimited_kse_license = t('license_keys#create_unlimited_kse_license_key');
        break;
      case OrganizationFeature.UNLIMITED_ENGINE:
        urlParams.new_unlimited_engine_license = t('license_keys#create_unlimited_engine_license_key');
        break;
      case OrganizationFeature.TESTOPS:
        urlParams.new_testops_license = t('license_keys#create_testops_license_key');
        break;
      case OrganizationFeature.ENGINE:
      default:
        urlParams.new_engine_license = t('license_keys#create_engine_license_key');
    }
    return (
      <ObjectSummary params={urlParams} />
    );
  }

  componentDidMount() {
    Services.organizationWithLicense(this.organizationId, this.licenseFeature)
      .then((organization) => {
        this.setState(this.getDefaultState(organization));
        this.getExpiryDate();
      });
  }

  getExpiryDate() {
    const { organization } = this.state;
    let expDate = null;

    switch (this.licenseFeature) {
      case OrganizationFeature.KSE:
        expDate = organization.subscriptionExpiryDateKSE;
        break;
      case OrganizationFeature.UNLIMITED_KSE:
        expDate = organization.subscriptionExpiryDateUnlimitedKSE
        break;
      case OrganizationFeature.UNLIMITED_ENGINE:
        expDate = organization.subscriptionExpiryDateUnlimitedEngine
        break;
      case OrganizationFeature.TESTOPS:
        expDate = organization.subscriptionExpiryDateTestOps;
        break;
      case OrganizationFeature.ENGINE:
      default:
        expDate = organization.subscriptionExpiryDateEngine;
    }
    this.setState({
      expDate,
    });
  }

  render() {
    if (this.licenseFeature === 'TESTOPS' && !MAuth.isSystemRole()) {
      Routers.goToNotificationPage(notificationIds.NO_PERMISSION);
    }

    const isOrganizationManager = MAuth.isOrganizationManager(this.organizationId);

    if (!isOrganizationManager) {
      Routers.goToNotificationPage(notificationIds.NO_PERMISSION);
    }

    const { organization } = this.state;
    if (organization) {
      return (
        <DefaultLayout
          renderHeader={this.renderHeader}
          renderBody={this.renderBody}
        />
      );
    }
    return null;

  }
}

export default OrganizationCreateLicenseKey;
