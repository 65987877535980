import { w3cwebsocket as W3CWebSocket } from 'websocket';
import MConfigs from '../models/MConfigs';


const WS_REALTOPIC_MAPPING = {
  TestRunPlanning: 'Execution',
};

function toRealTopic(topic) {
  if (WS_REALTOPIC_MAPPING[topic]) {
    return WS_REALTOPIC_MAPPING[topic];
  }
  return topic;
}

function subscribe(client, { teamId, projectId, organizationId, topics }) {
  client.send(JSON.stringify({
    action: 'subscribe',
    teamId,
    projectId,
    organizationId,
    topics,
  }));
}

export default class {
  static webSocketUrl;
  static webSocketClient;
  static handlers = {};

  static initialize() {
    if (this.webSocketUrl) {
      // initialized
      return;
    }

    this.webSocketUrl = MConfigs.webSocketUrl;
    this.webSocketClient = new W3CWebSocket(this.webSocketUrl);
  }

  static getClient() {
    if (!this.webSocketUrl) {
      this.initialize();
    }
    return this.webSocketClient;
  }

  static convertData(data) {
    const { topics } = data;
    if (topics && topics.length > 0) {
      data.topics = topics.map((topic) => toRealTopic(topic));
    }
    return data;
  }

  static subscribe(data, notifyHandler, handlerId = null) {
    try {
      const client = this.getClient();
      data = this.convertData(data);
      const { topics } = data;
      if (client.readyState === client.OPEN) {
        subscribe(client, data);
      } else {
        this.on('open', () => {
          subscribe(client, data);
        });
      }

      const messageHandler = (message) => {
        const parsedData = JSON.parse(message.data);
        if (notifyHandler && topics.includes(parsedData.topic)) {
          notifyHandler(parsedData);
        }
      };
      if (handlerId) {
        this.handlers[handlerId] = messageHandler;
      }
      this.on('message', messageHandler);
    } catch (e) {
      console.error('Error when subscribe', e);
    }
  }

  static unsubscribe(handlerId) {
    const client = this.getClient();
    const handler = this.handlers[handlerId];
    if (handler != null) {
      client.removeEventListener('message', handler);
    }
  }

  static on(event, handler) {
    this.getClient().addEventListener(event, handler);
  }
}
