import React from 'react';
import { Button } from 'reactstrap';
import PageButtonToolbar from '../../components/PageButtonToolbar';
import PageComponent from '../../components/PageComponent';
import ObjectSummary from '../../components/summary/ObjectSummary';
import DefaultLayout from '../../components/DefaultLayout';
import { t } from '../../i18n/t';
import MContext from '../../models/MContext';
import RequirementDetailsStatusBar from './RequirementDetailsStatusBar';
import RequirementDetailsDataTable from '../../components/table/RequirementDetailsDataTable';
import Services from '../../utils/Services';

const JiraToolbar = ({ issueId, requirementUrl }) => {
  if (!requirementUrl || !issueId) {
    return null;
  }
  return (
    <PageButtonToolbar>
      <Button
        title={t('view-in-jira')}
        color="secondary"
        href={requirementUrl}
        target="_blank"
        rel="noopener noreferrer"
      >
        {t('view-in-jira')}{` (${issueId})`}
      </Button>
    </PageButtonToolbar>
  );
};

class RequirementDetails extends PageComponent {
  constructor(props) {
    super(props);
    this.meta.id = 'page-requirement-details';
    this.project = MContext.project;
    this.meta.title = t('Project {{name}} - Requirement', { name: this.project.name });
    this.renderHeader = this.renderHeader.bind(this);
    this.renderBody = this.renderBody.bind(this);
    this.requirementId = MContext.requirementId;
    this.state = {
      requirement: null,
    };
  }

  getRequirement() {
    const params = {
      pagination: {
        page: 0,
        size: 1,
        sorts: 'order,desc',
      },
      conditions: [
        {
          key: 'id',
          operator: '=',
          value: this.requirementId,
        }
      ],
      type: 'ExternalRequirement',
    };
    Services.search(params)
      .then(({ content }) => {
        const [requirement] = content;
        if (requirement) {
          this.setState({ requirement });
        }
      });
  }

  componentWillMount() {
    this.getRequirement();
  }

  renderBody() {
    const { requirement } = this.state;
    if (!requirement) {
      return null;
    }
    return (
      <>
        <JiraToolbar
          requirementUrl={requirement.url}
          issueId={requirement?.issueId}
        />
        <RequirementDetailsStatusBar requirementId={requirement.id} />
        <RequirementDetailsDataTable requirementId={requirement.id} />
      </>
    );
  }

  renderHeader() {
    const { requirement } = this.state;
    const requirementName = requirement?.summary || requirement?.featureName || '';
    const urlParams = {
      requirementDetails: `${t('requirement_details')}: ${requirement?.issueId || ''} ${requirementName}`,
    };
    return (
      <ObjectSummary params={urlParams} />
    );
  }

  render() {
    const { requirement } = this.state;

    if (requirement) {
      return (
        <DefaultLayout
          renderHeader={this.renderHeader}
          renderBody={this.renderBody}
        />
      );
    }
    return null;
  }
}

export default RequirementDetails;
