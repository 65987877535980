import { groupBy } from 'lodash';
import { ProjectStatisticsType } from '../utils/Constants';

const ReportHelper = {

  /**
   * Convert data group by key and status to use for chart
   * @param {Array} data data get from dataloader(group by key and status)
   * Ex: [
        {
          content: {
            total: 4,
            status: 'PASSED',
            profile: 'Staging',
          }
        },
        {
          content: {
            total: 8,
            status: 'FAILED',
            profile: 'Dev',
          }
        },
        {
          content: {
            total: 10,
            status: 'ERROR',
            profile: 'Staging',
          }
        },
        {
          content: {
            total: 3,
            status: 'FAILED',
            profile: 'Staging',
          }
        }
     ]
   * @param {Array} statusList list of status
   * @param key String key of item to set for 'name'
   * Ex: 'profile'
   * @returns {Array} converted data
   * Ex:
   * [
        {
          name: 'Staging',
          PASSED: 4,
          FAILED: 3,
          ERROR: 10,
          INCOMPLETE: 0
        },
        {
          name: 'Dev',
          PASSED: 0,
          FAILED: 8,
          ERROR: 0,
          INCOMPLETE: 0
        }
    ]
   */
  convertData: (data, statusList, key) => {
    const extractedData = data.map((item) => item.content);

    // Group by key
    const groupByData = groupBy(extractedData, key) || [];

    let newValueTemplate = {};

    // init default value for all status
    statusList.forEach((status) => {
      newValueTemplate = {
        ...newValueTemplate,
        [status]: 0
      };
    });

    // map total of status with key to object
    const convertedData = Object.keys(groupByData).map((itemKey) => {
      const newValue = {
        ...newValueTemplate,
        name: itemKey
      };

      if (groupByData[itemKey]) {
        groupByData[itemKey].forEach((value) => {
          newValue[value.status] = value.total;
        });
      }
      return newValue;
    });
    return convertedData;
  },

  handleEmptyProfile: (data) => {
    const emptyProfileIndex = data.findIndex((item) => item.name === '');

    // move empty profile to bottom
    if (emptyProfileIndex !== -1) {
      const emptyProfile = data[emptyProfileIndex];
      data = data.filter((item) => item?.name !== '');
      emptyProfile.name = 'N/A';
      data.push(emptyProfile);
    }

    return data;
  },

  getStatisticsType: (statisticsType) => {
    if (statisticsType === ProjectStatisticsType.MONTHLY) {
      return 'month';
    }
    if (statisticsType === ProjectStatisticsType.WEEKLY) {
      return 'week';
    }
    return 'day';
  },
};

export default ReportHelper;
