import { Button, Form, FormGroup, Label } from 'reactstrap';
import React, { useEffect, useState } from 'react';
import { t } from '../../i18n/t';
import Input from '../../components/Input';
import { GitTestProjectVCSType } from '../../utils/Constants';
import Services from '../../utils/Services';
import Notification from '../../utils/Notification';

const UserGitCredentialsForm = () => {
  const [username, setUsername] = useState('');
  const [accessToken, setAccessToken] = useState('');

  useEffect(() => {
    Services.getUserGitCredentials(GitTestProjectVCSType.GITHUB)
      .then(({ username, accessToken }) => {
        setUsername(username);
        setAccessToken(accessToken);
      });
  }, []);
  const onSubmit = (e) => {
    e.preventDefault();
    const resource = { username, accessToken, type: GitTestProjectVCSType.GITHUB };
    Services.updateUserGitCredentials(resource)
      .then(() => Notification.pushSuccess(t('github-integration-connect-success', { username })));
  };

  return (
    <Form
      onSubmit={onSubmit}
      autoComplete="off"
      data-trackid="integrate-personal-github-credentials"
    >
      <FormGroup>
        <Label for="username">{t('username')}</Label>
        <Input
          id="username"
          name="username"
          type="text"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          placeholder={t('git-repository#form-title#username')}
          required
        />
      </FormGroup>
      <FormGroup>
        <Label for="accessToken">{t('test-project#git#personal-access-token')}</Label>
        <Input
          id="accessToken"
          name="accessToken"
          type="password"
          value={accessToken}
          autocomplete="new-password"
          onChange={(e) => setAccessToken(e.target.value)}
          placeholder={t('git-repository#form-title#pat')}
          required
        />
      </FormGroup>
      <FormGroup>
        <Button
          type="submit"
          color="primary"
        >
          {t('test-project#git#connect')}
        </Button>
      </FormGroup>
    </Form>
  );
};

export default UserGitCredentialsForm;
