import React from 'react';
import {
  Button,
  Form,
  FormGroup,
  Label,
  Row,
  Col,
  Collapse,
  FormText,
  Alert,
  ButtonToolbar, Card, CardHeader, CardBody
} from 'reactstrap';
import { IconButton } from '@mui/material';
import _ from 'lodash';
import Input from '../components/Input';
import Select from '../components/Select';
import PageComponent from '../components/PageComponent';
import ObjectSummary from '../components/summary/ObjectSummary';
import { t } from '../i18n/t';
import MContext from '../models/MContext';
import Apis from '../utils/Apis';
import http from '../utils/http';
import Services from '../utils/Services';
import DefaultLayout from '../components/DefaultLayout';
import Routes from '../utils/Routes';
import { IconGitHub, IconBitBucket } from '../images/CustomIcon';
import RouteConstants from '../utils/RouteConstants';
import Notification from '../utils/Notification';
import DocumentLink from '../utils/DocumentLink';
import Stepper from '../components/wizard/Stepper';
import MFlags from '../models/MFlags';
import { IconArrowLeft } from '../images/CustomIcon';
import AgentProjectLink from '../components/agentproject/AgentProjectLink';

class CircleCIAgent extends PageComponent {

  constructor(props) {
    super(props);
    this.teamId = MContext.teamId;
    this.organizationId = MContext.organizationId;
    this.circleCIAgentId = MContext.circleCIAgentId;
    this.updateMode = !!this.circleCIAgentId;

    if (this.updateMode) {
      this.meta.id = 'page-update-circle-ci-agent-setting';
    } else {
      this.meta.id = 'page-create-circle-ci-agent-setting';
    }
    this.meta.title = t('CircleCI Agent');

    this.initState();

    this.renderBody = this.renderBody.bind(this);
    this.renderHeader = this.renderHeader.bind(this);
    this.getFollowedProjects = this.getFollowedProjects.bind(this);
    this.createCircleCIAgentConfig = this.createCircleCIAgentConfig.bind(this);
    this.updateCircleCIAgentConfig = this.updateCircleCIAgentConfig.bind(this);
    this.handleAgentName = this.handleAgentName.bind(this);
    this.handleURL = this.handleURL.bind(this);
    this.handleToken = this.handleToken.bind(this);
    this.handleBranch = this.handleBranch.bind(this);
    this.generateProjectOptions = this.generateProjectOptions.bind(this);
    this.handleSelectProject = this.handleSelectProject.bind(this);
    this.handleApiKey = this.handleApiKey.bind(this);
    this.handleOnSelectChange = this.handleOnSelectChange.bind(this);
  }

  initState() {
    this.state = {
      agentName: '',
      team: null,
      circleCIAgent: null,
      apiKey: '',
      url: 'https://circleci.com',
      token: '',
      username: '',
      project: '',
      vcsType: '',
      branch: 'master',

      projects: [],
      projectOptions: [],
      selectedProjectIndex: null,
      isOpenFollowedProjectSetup: false,

      disableSubmitButton: false,
      linkedProjects: []
    };
  }

  getTeam() {
    http.get(Apis.teamId(this.teamId))
      .then((responseJson) => {
        const team = responseJson;
        this.setState({
          team,
        });
      });
  }

  getCircleCIAgentConfig() {
    http.get(Apis.circleCIAgent(this.circleCIAgentId))
      .then((responseJson) => {
        const circleCIAgent = responseJson;
        if (circleCIAgent) {
          this.setState({
            circleCIAgent,
            agentName: circleCIAgent.name,
            apiKey: circleCIAgent.apiKey,
            url: circleCIAgent.url,
            token: circleCIAgent.token,
            username: circleCIAgent.username,
            project: circleCIAgent.project,
            vcsType: circleCIAgent.vcsType,
            branch: circleCIAgent.branch,
            linkedProjects: circleCIAgent.filteredLinkedProjects || [],
          }, this.getFollowedProjects);
        }
      });
  }

  componentDidMount() {
    const { moveAgentToOrgLevelPhase2Enabled } = MFlags;
    if (!moveAgentToOrgLevelPhase2Enabled) {
      this.getTeam();
    }
    if (this.updateMode) {
      this.getCircleCIAgentConfig();
    }
  }

  handleAgentName(event) {
    const agentName = event.target.value;
    this.setState({ agentName });
  }

  handleURL(event) {
    const url = event.target.value;
    this.setState({ url, isOpenFollowedProjectSetup: false });
  }

  handleSelectProject(selectedProjectIndex) {
    const { projects } = this.state;
    const selectedProject = projects[selectedProjectIndex];
    this.setState({
      selectedProjectIndex,
      username: _.get(selectedProject, 'username'),
      project: _.get(selectedProject, 'reponame'),
      vcsType: _.get(selectedProject, 'vcs_type'),
    });
  }

  handleToken(event) {
    const token = event.target.value;
    this.setState({ token, isOpenFollowedProjectSetup: false });
  }

  handleBranch(event) {
    const branch = event.target.value;
    this.setState({ branch });
  }

  handleApiKey(event) {
    const apiKey = event.target.value;
    this.setState({ apiKey });
  }

  createCircleCIAgentConfig(e) {
    this.setState({
      disableSubmitButton: true
    });
    const { moveAgentToOrgLevelPhase2Enabled } = MFlags;
    e.preventDefault();

    const linkedProjectIds = this.state.linkedProjects.map((item) => item.id);
    let data = {
      name: this.state.agentName,
      apiKey: this.state.apiKey,
      url: this.state.url,
      token: this.state.token,
      username: this.state.username,
      project: this.state.project,
      vcsType: this.state.vcsType,
      branch: this.state.branch,
      projectIds: linkedProjectIds,
    };
    if (moveAgentToOrgLevelPhase2Enabled) {
      data = { ...data, organizationId: this.organizationId };
    } else {
      data = { ...data, teamId: this.teamId };
    }
    Services.createCircleCIAgentConfig(data).then(() => {
      if (!moveAgentToOrgLevelPhase2Enabled) {
        Routes.goToCircleCIAgentsLink();
      } else {
        Routes.goToOrganizationCircleCIAgentsLink();
      }
    });
  }

  updateCircleCIAgentConfig(e) {
    const { moveAgentToOrgLevelPhase2Enabled } = MFlags;
    this.setState({
      disableSubmitButton: true
    });
    e.preventDefault();
    const linkedProjectIds = this.state.linkedProjects.map((item) => item.id);
    const data = {
      id: this.circleCIAgentId,
      name: this.state.agentName,
      apiKey: this.state.apiKey,
      url: this.state.url,
      username: this.state.username,
      token: this.state.token,
      project: this.state.project,
      vcsType: this.state.vcsType,
      branch: this.state.branch,
      projectIds: linkedProjectIds,
    };

    Services.updateCircleCIAgentConfig(data).then(() => {
      if (!moveAgentToOrgLevelPhase2Enabled) {
        Routes.goToCircleCIAgentsLink();
      } else {
        Routes.goToOrganizationCircleCIAgentsLink();
      }
    });
  }

  getFollowedProjects() {
    const data = {
      url: this.state.url,
      token: this.state.token,
    };
    Services.getCircleCIFollowedProjects(data, (message, label) => {
      if (message instanceof Error) {
        Notification.pushError(message.message, label);
      } else {
        Notification.pushError(message, label);
      }
      this.setState({ isOpenFollowedProjectSetup: false, projects: [] }, this.generateProjectOptions);
    }).then((response) => {
      const projects = response || [];
      this.setState({ isOpenFollowedProjectSetup: true, projects }, this.generateProjectOptions);
    });
  }

  generateProjectOptions() {
    const {
      projects,
      username,
      project,
      vcsType,
    } = this.state;

    const projectOptions = _.map(projects, (({ username, reponame, vcs_type }, index) => ({
      label: (
        <div>
          {vcs_type === 'github' && <IconGitHub className="mr-4" />}
          {vcs_type === 'bitbucket' && <IconBitBucket className="mr-4" />}
          {`${username} / ${reponame}`}
        </div>
      ),
      value: index,
    })));

    let selectedProjectIndex = _.findIndex(projects, { username, reponame: project, vcs_type: vcsType });
    if (selectedProjectIndex === -1) {
      selectedProjectIndex = null;
    }

    this.setState({
      projectOptions,
    }, () => this.handleSelectProject(selectedProjectIndex));
  }

  renderPrepareStep() {
    return (
      <Row>
        <Col>
          <Alert color="primary">
            Visit
            <a
              href={DocumentLink.SETUP_CIRCLE_CI_ENV}
              target="_blank"
              rel="noreferrer noopener"
            >
              {' here '}
            </a>
            to learn how to set up an CircleCI Test Environment.
          </Alert>
          <ul>
            <li>Create a CircleCI account with Github/Bitbucket account.</li>
            <li>Fork <a target="_blank" rel="noopener noreferrer" href="https://github.com/katalon-studio-samples/testops-circleci-sample">this Github repository</a> to your Github/Bitbucket account.</li>
            <li>Follow the above project in CircleCI.</li>
          </ul>
        </Col>
      </Row>
    );
  }

  renderBody() {
    const steps = [
      {
        label: 'Prepare a CircleCI Project',
        content: this.renderPrepareStep(),
      },
      {
        label: 'Configure CircleCI credentials',
        content: this.renderSetupStep(),
      },
    ];
    const routes = new Routes({ organizationId: this.organizationId });
    return (
      <Card>
        {MFlags.moveAgentToOrgLevelPhase2Enabled &&
        <CardHeader>
          <div className='app-agent-header'>
             <IconButton size='small' href={routes.organization_setting_circle_ci_agent_link}>
               <IconArrowLeft className='app-agent-header__back_button' />
             </IconButton>
             <div>{t('circle-ci-agent#title')}</div>
           </div>
        </CardHeader>}
        <CardBody>
          <Stepper
            id="circle-ci-setup"
            data-trackid="circle-ci-setup"
            expanded
            steps={steps}
          />
        </CardBody>
      </Card>
    );
  }

  handleOnSelectChange(selectedProjects) {
    this.setState({
      linkedProjects: selectedProjects,
    });
  }

  renderSetupStep() {
    const {
      agentName,
      apiKey,
      url,
      token,
      branch,
      selectedProjectIndex,
      projectOptions,
      isOpenFollowedProjectSetup,
      disableSubmitButton,
      linkedProjects,
    } = this.state;

    return (
      <>
        <Row>
          <Col sm="12" md="8" lg="6" xl="5">
            <Form
              data-trackid="update-circle-ci-agent-config"
              onSubmit={this.updateMode ? this.updateCircleCIAgentConfig : this.createCircleCIAgentConfig}
            >
              <FormGroup>
                <Label for="circle-ci-name">{t('agent#agent-name')}</Label>
                <Input
                  required
                  type="text"
                  id="circle-ci-name"
                  value={agentName}
                  onChange={this.handleAgentName}
                />
              </FormGroup>
              <FormGroup>
                <Label for="circle-ci-url">{t('url')}</Label>
                <Input
                  required
                  type="text"
                  id="circle-ci-url"
                  value={url}
                  onChange={this.handleURL}
                />
              </FormGroup>
              <FormGroup>
                <Label for="circle-ci-token">{t('circle-ci-agent#token')}</Label>
                <Input
                  required
                  type="password"
                  id="circle-ci-token"
                  value={token}
                  onChange={this.handleToken}
                />
              </FormGroup>
              {!MFlags.moveAgentToOrgLevelPhase2Enabled && (
                <FormGroup>
                  <Label for="apiKey">{t('agent#api-key')}</Label>
                  <Input
                    required
                    type="text"
                    id="apiKey"
                    value={apiKey}
                    onChange={this.handleApiKey}
                  />
                  <FormText color="muted">
                    {t('agent#generate-api-key')}
                    <a
                      href={RouteConstants.apikey}
                    >
                      here.
                    </a>
                  </FormText>
                </FormGroup>
              )}
              <ButtonToolbar>
                <Button
                  type="button"
                  color="secondary"
                  onClick={() => this.getFollowedProjects()}
                >
                  {t('circle-ci-agent#connect')}
                </Button>
              </ButtonToolbar>

              <Collapse isOpen={isOpenFollowedProjectSetup}>
                <FormGroup>
                  <Label for="circle-ci-project">{t('circle-ci-agent#project')}</Label>
                  <Select
                    required
                    id="circle-ci-project"
                    value={selectedProjectIndex}
                    options={projectOptions}
                    onChange={(projectOption) => this.handleSelectProject(projectOption.value)}
                    clearable={false}
                  />
                  <FormText color="muted">
                    {t('circle-ci-agent#follow-project')}
                  </FormText>
                </FormGroup>
                <FormGroup>
                  <Label for="circle-ci-branch">{t('circle-ci-agent#branch')}</Label>
                  <Input
                    required
                    type="text"
                    id="circle-ci-branch"
                    value={branch}
                    onChange={this.handleBranch}
                  />
                </FormGroup>
                {MFlags.moveAgentToOrgLevelPhase2Enabled && this.updateMode && (
                  <FormGroup>
                    <AgentProjectLink selectedProjects={linkedProjects} handleOnSelectChange={this.handleOnSelectChange} />
                  </FormGroup>
                )}
                <ButtonToolbar>
                  <Button type="submit" color="primary" disabled={disableSubmitButton}>
                    {this.updateMode ? t('circle-ci-agent#update') : t('circle-ci-agent#create')}
                  </Button>
                </ButtonToolbar>
              </Collapse>
            </Form>
          </Col>
        </Row>
      </>
    );
  }

  renderObjectSummary() {
    const urlParams = {
      agent: t('agent'),
      teamId: this.teamId,
    };
    return (
      <ObjectSummary params={urlParams} />
    );
  }

  renderHeader() {
    return (
      <>
        {this.renderObjectSummary()}
      </>
    );
  }

  render() {
    const { team } = this.state;
    const { moveAgentToOrgLevelPhase2Enabled } = MFlags;
    if (!moveAgentToOrgLevelPhase2Enabled && !team) return <div>No team</div>;
    if (moveAgentToOrgLevelPhase2Enabled && !this.organizationId) return <div>No organization</div>;

    return (
      <DefaultLayout
        renderHeader={this.renderHeader}
        renderBody={this.renderBody}
      />
    );
  }
}

export default CircleCIAgent;
