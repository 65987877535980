import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import TestSuiteDataTable from '../components/table/TestSuiteDataTable';
import ObjectSummary from '../components/summary/ObjectSummary';
import PageComponent from '../components/PageComponent';
import MContext from '../models/MContext';
import RouteConstants from '../utils/RouteConstants';
import { t } from '../i18n/t';
import { TestDesignHeaderNav } from '../components/HeaderNav';
import Routes from '../utils/Routes';
import DefaultLayout from '../components/DefaultLayout';
import TestCaseDataTable from '../components/table/TestCaseDataTable';
import TestObjects from './test_objects/TestObjects';

class TestDesign extends PageComponent {
  constructor(props) {
    super(props);
    this.meta.id = 'page-test-design';
    this.projectId = MContext.projectId;
    this.project = MContext.project;
    this.teamId = MContext.teamId;
    this.meta.title = t('Project {{name}} - Test Design', { name: this.project.name });
    this.renderHeader = this.renderHeader.bind(this);
    this.renderBody = this.renderBody.bind(this);
  }

  renderObjectSummary() {
    const urlParams = {
      testDesign: t('test_design'),
    };

    return (
      <ObjectSummary params={urlParams}>
        <TestDesignHeaderNav projectId={this.projectId} teamId={this.teamId} />
      </ObjectSummary>
    );
  }

  renderHeader() {
    return (
      <>
        {this.renderObjectSummary()}
      </>
    );
  }

  renderBody() {
    const constructedLink = new Routes({
      teamId: this.teamId,
      projectId: this.projectId,
    });

    return (
      <Switch>
        <Route
          exact
          path={RouteConstants.test_cases}
          render={() => <TestCaseDataTable />}
        />
        <Route
          exact
          path={RouteConstants.test_suites}
          render={() => <TestSuiteDataTable />}
        />
        <Route
          exact
          path={RouteConstants.web_services}
          component={TestObjects}
        />
        <Redirect to={constructedLink.test_cases_link} />
      </Switch>
    );
  }

  render() {
    return (
      <DefaultLayout
        renderHeader={this.renderHeader}
        renderBody={this.renderBody}
      />
    );
  }
}

export default TestDesign;
