import React from 'react';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import ReactDOM from 'react-dom';
import jquery from 'jquery';
import Routes from '../../utils/Routes';
import { ID_MAIN_HEADER_TOOLBAR } from '../../utils/Constants';
import Link from '../Link';
import { BetaChip } from '../BetaChip';
import { IconAIPowered } from '../../images/CustomIcon';
import MFlags from '../../models/MFlags';

class ObjectSummary extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      ready: false,
    };

    this.renderBetaTag = this.renderBetaTag.bind(this);
  }

  renderUrl(urlArray) {
    return urlArray.map((url) => (
      <BreadcrumbItem key={url.link || url.name}>
        <Link data-trackid="breadcrumb-item" href={url.link}>{url.name}</Link>
      </BreadcrumbItem>
    ));
  }

  updateState() {
    this.setState((prevState) => ({
      ready: !prevState.ready,
    }));
  }

  componentDidMount() {
    this.checkExist = setInterval(this.updateState(), 50);
  }

  renderBreadcrumb(renderedUrl) {
    return (
      <div id="top-breadcrumb">
        <Breadcrumb>
          {renderedUrl}
        </Breadcrumb>
      </div>
    );
  }

  renderBetaTag() {
    const { isG5TestCase, isTrueTestTestCase } = this.props;

    if (isG5TestCase) {
      return <BetaChip />;
    }

    const { atgRemoveBetaTag } = MFlags;
    if (!atgRemoveBetaTag && isTrueTestTestCase) {
      return <BetaChip />;
    }
    return null;
  }

  render() {
    const { children, isTrueTestTestCase } = this.props;
    const constructedLink = new Routes(this.props.params);
    const { routeArray, routeType, title } = constructedLink.routeInfo;

    const renderedUrl = this.renderUrl(routeArray);
    const breadcrumbQuery = jquery('#navbar-breadcrumb');
    let breadCrumb = null;
    if (breadcrumbQuery.length > 0) {
      breadCrumb = ReactDOM.createPortal(
        this.renderBreadcrumb(renderedUrl),
        breadcrumbQuery.get(0)
      );
      clearInterval(this.checkExist);
    }

    return (
      <div className="object-summary">
        {breadCrumb || null}
        <div className="object-title">
          <div className="title-text" title={title || routeType}>
            {routeType}
          </div>
          <div className="title-icon">
            {isTrueTestTestCase && <IconAIPowered />}
            {this.renderBetaTag()}
          </div>
          <div className="button-header" id={ID_MAIN_HEADER_TOOLBAR} />
        </div>

        <div className="object-tab-info">
          {children && children}
          <div id="header-nav-right-tool-bar" />
        </div>
      </div>
    );
  }
}

export default ObjectSummary;
