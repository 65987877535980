import { TestOpsFeature, KatalonTestOpsPackage } from '../utils/Constants';

// Only support trial request for TESTOPS_BUSINESS and TESTOPS_ENTERPRISE
export function toTestOpsFeature(testOpsPackage) {
  switch (testOpsPackage) {
    case KatalonTestOpsPackage.BUSINESS:
      return TestOpsFeature.TESTOPS_BUSINESS;
    case KatalonTestOpsPackage.ENTERPRISE:
      return TestOpsFeature.TESTOPS_ENTERPRISE;
    default:
      return null;
  }
}
