import React from 'react';
import { Row, Col } from 'reactstrap';
import { buildSearchCondition } from '../../components/search/SearchUtils';
import TabComponent from '../../components/TabComponent';
import AbnormalRequestDataTable from '../../components/table/AbnormalRequestDataTable';
import ExecutionResultAnalysisGroupDataTable from '../../components/table/ExecutionResultAnalysisGroupDataTable';
import ExecutionTestResultDataTable from '../../components/table/ExecutionTestResultDataTable';
import ExecutionTestSuiteDataTable from '../../components/table/ExecutionTestSuiteDataTable';
import { t } from '../../i18n/t';
import ExecutionStatusBar from './ExecutionStatusBar';
import { JobExecutionResult } from './JobExecutionResult';
import PerformanceLineChart from '../../components/chart/PerformanceLineChart';
import DataLoader from '../../components/table/DataLoader';
import CheckpointGallery from '../keyes/component/CheckpointGallery';
import KeyesExecutionStatusBar from '../keyes/component/KeyesExecutionStatusBar';
import DecoratorConstants from '../../utils/DecoratorConstants';
import MFlags from '../../models/MFlags';
import Services from '../../utils/Services';

class ResultTab extends TabComponent {
  constructor(props) {
    super(props);
    this.executionId = props.execution.id;

    this.meta.id = 'page-execution-details-result';
    this.meta.title = document.title;
    this.renderPreviousTestRunsChart = this.renderPreviousTestRunsChart.bind(this);
  }

  componentDidMount() {
    if (MFlags.onboardingChecklistEnhancementEnabled) {
      const { execution } = this.props;
      const onboardingStep = {
        projectId: execution?.projectId,
        stepId: 'ANALYZE_TEST_RESULT',
      };
      Services.trackOnboardingStep(onboardingStep).catch(() => { /* ignore */ });
    }
  }

  renderTestSuites() {
    const { execution } = this.props;
    return (
      <ExecutionTestSuiteDataTable
        title={t('test_suites')}
        defaultSearchConditions={[
          buildSearchCondition('Execution.id', '=', execution.id),
        ]}
      />
    );
  }

  renderFailedTestRuns() {
    const { execution } = this.props;
    const isLinkedDefectDefaultValue = true;
    const defaultSearchString =
      MFlags.filterUnresolvedTestResultEnabled ? `isLinkedDefect:${!isLinkedDefectDefaultValue}` : null;

    return (
      <ExecutionTestResultDataTable
        showCountBadge
        title={t('failed_test_results')}
        hideAllFilter
        showColumnSimilarFailures
        defaultSearchConditions={[
          buildSearchCondition('Execution.id', '=', execution.id),
          buildSearchCondition('status', '!=', 'PASSED'),
          buildSearchCondition('status', '!=', 'SKIPPED'),
        ]}
        defaultSearchString={defaultSearchString}
        isLinkedDefectedDefaultValue={isLinkedDefectDefaultValue}
      />
    );
  }

  renderTestObjectAbnormal() {
    const { execution } = this.props;
    return (
      <AbnormalRequestDataTable
        defaultSearchConditions={[
          buildSearchCondition('Execution.id', '=', execution.id),
          buildSearchCondition('abnormal', '=', 'true')
        ]}
      />
    );
  }

  renderTestResultGroups() {
    const { execution } = this.props;

    return (
      <ExecutionResultAnalysisGroupDataTable
        disableCategory
        disableFilterButton
        title={t('analysis#error-groups')}
        defaultSearchConditions={[
          buildSearchCondition('Execution.id', '=', execution.id),
          buildSearchCondition('totalTestResults', '>', 1),
        ]}
        defaultSort={[
          'totalTestResults,desc',
        ]}
      />
    );
  }

  renderSessions() {
    const { execution } = this.props;
    return <JobExecutionResult executionId={execution.id} title={t('jobs')} />;
  }

  renderPreviousTestRunsChart(data) {
    const sortedData = data.sort((a, b) => a.order - b.order);
    const chartLabelsDecorate = (fieldName, row) => DecoratorConstants.dateAndOrderDecorator('', row);

    return (
      <PerformanceLineChart
        data={sortedData.map(({ elapsedDuration, order, status, startTime }) => ({
          duration: elapsedDuration,
          order,
          status,
          startTime
        }))}
        xLabel={t('executions')}
        chartLabelsDecorate={chartLabelsDecorate}
      />
    );
  }

  renderPreviousTestRuns() {
    const { execution } = this.props;
    if (execution.jobs && execution.jobs.length > 0 && execution.jobs[0].runConfigurationId) {
      const runConfigurationId = execution.jobs[0].runConfigurationId;
      return (
        <DataLoader
          defaultSearchConditions={[
            buildSearchCondition('RunConfiguration.id', '=', runConfigurationId),
            buildSearchCondition('order', '<=', execution.order)
          ]}
          defaultSort={['order,desc']}
          entityType="Execution"
          render={this.renderPreviousTestRunsChart}
          title={t('previous-test-run')}
          cardClassName="card-only-chart"
          hidePaging
        />
      );
    }
    return null;
  }

  renderFailedCheckpoints() {
    const { keyesExecution } = this.props;
    return (
      <CheckpointGallery
        title={t('keyes#execution#failed-checkpoints')}
        ref={this.checkpointsRef}
        defaultSearchConditions={[
          buildSearchCondition('KeyesExecution.id', '=', keyesExecution.id),
          buildSearchCondition('status', '!=', 'PASSED'),
        ]}
        viewOnly
      />
    );
  }

  renderStatusBar() {
    const { execution, keyesExecution } = this.props;
    if (!keyesExecution) {
      return <ExecutionStatusBar execution={execution} />;
    }
    return (
      <Row>
        <Col>
          <ExecutionStatusBar hideChart execution={execution} />
        </Col>
        <Col>
          <KeyesExecutionStatusBar hideChart keyesExecution={keyesExecution} />
        </Col>
      </Row>
    );
  }

  render() {
    const { keyesExecution } = this.props;
    return (
      <>
        {this.renderStatusBar()}
        {this.renderPreviousTestRuns()}
        {this.renderSessions()}
        {this.renderFailedTestRuns()}
        {keyesExecution && this.renderFailedCheckpoints()}
        {this.renderTestSuites()}
        {this.renderTestObjectAbnormal()}
      </>
    );
  }
}

export default ResultTab;
