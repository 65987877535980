import React from 'react';
import { IconLoading } from '../../images/CustomIcon';

export default function TableLoading({ message }) {
  return (
    <div className="text-center">
      <IconLoading className="table-loading" />
      <br />
      {message}
    </div>
  );
}
