import React from 'react';
import { Container } from '@mui/material';

export default function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Container
      sx={{ display: (value === index ? 'block' : 'none') }}
      className="pt-4 pl-0 pr-0"
      role="tabpanel"
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      { children }
    </Container>
  );
}
