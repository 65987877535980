import { matchPath } from 'react-router-dom';
import GroupEvent from './GroupEvent';
import RouteConstants from '../RouteConstants';

/**
 * Event name: access-configuration
 */
const accessConfigurationGroup = [
  RouteConstants.agent,
  RouteConstants.test_env_installation,
  RouteConstants.test_project_creation,
  RouteConstants.job,
  RouteConstants.git_test_project_setup
];

/**
 * Event name: access-test-planning
 */
const accessTestPlanningGroup = [
  RouteConstants.test_planning,
  RouteConstants.grid,
  RouteConstants.releases,
];

/**
 * Event name: access-test-management
 */
const accessTestManagementGroup = [
  RouteConstants.requirements,
  RouteConstants.test_cases,
  RouteConstants.test_suites,
];

/**
 * Event name: access-report
 */
const accessReportGroup = [
  RouteConstants.home_organization, // Access Organization dashboard
  RouteConstants.team, // Access Team Dashboard
  RouteConstants.project_dashboard, // Access Project dashboard
  RouteConstants.release_view, // Access Release details
  RouteConstants.build_view, // Access Build details
  RouteConstants.executions, // Access Test Runs report
  RouteConstants.test_report_test_execution, // Access Summary by Test Run, Test Results, frequency
  RouteConstants.execution_details, // Access Test Run details
  RouteConstants.execution_test_result_details, // Access Test Result details, Requests, Test Objects, similar failures, comments, re-runs
  RouteConstants.execution_test_run_details, // Access Test Results
  RouteConstants.execution_file_details, // Access Test Run files
  RouteConstants.execution_defect, // Access Test Run defects
  RouteConstants.keyes, // Access Visual Testing, test run, test run checkpoints, omments
  RouteConstants.baseline_collection, // Access Visual Baseline
  RouteConstants.test_report_requirement_test_run, // Access Requirement Coverage
  RouteConstants.test_report_traceability, // Access Traceability Matrix
  RouteConstants.test_report_test_maintenance, // Access active, stale, slowest, flaky, platform coverage Test Cases
  RouteConstants.web_services, // Access Web services
  RouteConstants.web_services_anomalies, // Access Anomolies
  RouteConstants.test_design, // Access Test Objects, test run history
  RouteConstants.defects, // Access Defects
  RouteConstants.import_manual_reports, // Access Import Manual Reports
  RouteConstants.baseline_collection_group_detail, // Access Baseline collection detail
  RouteConstants.baseline_collection_group_history, // Access Baseline collection group history
];

/**
 * Event name: access-reports
 */
const accessReportsGroup = [
  // TODO TOS-1602
];

/**
 * Event name: access-settings
 */
const accessSettingsGroup = [
  RouteConstants.organization_teams,
  RouteConstants.organization_projects
];

/**
 * Event name: schedule-test-run
 */
const scheduleTestRunGroup = [
];

/**
 * Event name: upload-report
 */
const uploadReportGroup = [
  RouteConstants.import_manual_reports
];

/**
 * List all groups of event.
 * Current, we are tracks
 *    - access-configuration
 *    - access-test-planning
 *    - access-test-management
 *    - access-report
 *    - access-reports
 *    - access-settings
 *    - schedule-test-run
 *    - upload-report
 *
 * Group will follow format:
 *    - paths: list all route (url, path) with the same group <RouteConstants>
 *    - groupType: type of all paths <GroupEvent>
 */
const GROUP_EVENT_TRACKS = [
  {
    paths: accessConfigurationGroup,
    groupType: GroupEvent.ACCESS_CONFIGURATION,
  },
  {
    paths: accessTestPlanningGroup,
    groupType: GroupEvent.ACCESS_TEST_PLANNING,
  },
  {
    paths: accessTestManagementGroup,
    groupType: GroupEvent.ACCESS_TEST_MANAGEMENT,
  },
  {
    paths: accessReportGroup,
    groupType: GroupEvent.ACCESS_REPORT,
  },
  {
    paths: accessReportsGroup,
    groupType: GroupEvent.ACCESS_REPORTS,
  },
  {
    paths: accessSettingsGroup,
    groupType: GroupEvent.ACCESS_SETTINGS,
  },
  {
    paths: scheduleTestRunGroup,
    groupType: GroupEvent.SCHEDULE_TEST_RUN,
  },
  {
    paths: uploadReportGroup,
    groupType: GroupEvent.UPLOAD_REPORT,
  },
]

/**
 * Find groupType from group
 * @param url
 *          example: /team/1/project/1/test-planning
 * @param group
 *          example: this group will be get from GROUP_EVENT_TRACKS define on the top file.
 * @return array
 */
const getGroupEvent = (url, group) => {
  const paths = group.paths;
  if (paths) {
    for (let i = 0; i < paths.length; i++) {
      const path = paths[i];
      const match = matchPath(url, { path });
      if (match) {
        return group.groupType;
      }
    }
  }

  return null;
}

/**
 * Get group for event
 * Auto detect group for page/click/submit event
 *
 * If you do not define data-groupid in your component or
 * not set group for meta.groupid. This function will detect
 * groupId for your page/component.
 *
 * By default, you don't worry for groupId, if you want override
 * it, add data-groupid for component or set this.meta.groupId
 * for page.
 *
 * @param url
 *          example: /team/1/project/1/test-planning
 * @return group event of null
 *          exmaple: GroupEvent.UPLOAD_REPORT
 */
const getGroup = (url) => {
  const groups = [];
  for (let i = 0; i < GROUP_EVENT_TRACKS.length; i++) {
    const group = GROUP_EVENT_TRACKS[i];
    const groupEvent = getGroupEvent(url, group);
    if (groupEvent) {
      groups.push(groupEvent);
    }
  }

  return groups;
}

/**
 * Use array for group
 *
 * @param groupId
 * @return {*[]|*}
 */
const convertGroupIdToArray = (groupId) => {
  if (!groupId) {
    return [];
  }
  if (!Array.isArray(groupId)) {
    return [groupId];
  }
  return groupId;
}

export default {
  getGroup,
  convertGroupIdToArray
}
