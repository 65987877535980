/* eslint max-len: 0 */
/* eslint no-trailing-spaces: 0 */
import React from 'react';
import {
  Row, Col, Nav, NavItem, NavLink, TabContent, TabPane
} from 'reactstrap';
import classnames from 'classnames';
import { brand } from '../../i18n/t';
import MContext from '../../models/MContext';
import Routes from '../../utils/Routes';
import UploadExecutionStep3Img from '../../../images/upload-execution-step-3.png';
import KatalonRecorderImg from '../../../images/katalon-recorder.png';
import AutomaticallyUploadReport from './AutomaticallyUploadReport';
import ManuallyUploadReport from './ManuallyUploadReport';

class HowToUpload extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      activeTab: 'katalon-studio',
    };
  }

  toggle(tab) {
    const { activeTab } = this.state;
    if (activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  render() {

    const { activeTab } = this.state;
    const tabKs = 'katalon-studio';
    const tabKr = 'katalon-recorder';
    const tabJunit = 'junit';
    const tabCli = 'cli';

    const { projectId, teamId } = MContext;
    const router = new Routes({
      projectId,
      teamId,
    });
    const uploadReportsLink = router.frameworks_integration_link;

    return (
      <div>
        <p>At this moment {brand} supports report formats from <strong>JUnit</strong>, <strong>Katalon Studio</strong>, and <strong>Katalon Recorder</strong>.</p>
        <p><img className="img-fluid img-thumbnail" alt="Automatically Upload Report" src={UploadExecutionStep3Img} /></p>

        <Nav tabs className="pt-5">
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === tabKs })}
              onClick={() => { this.toggle(tabKs); }}
            >
              Katalon Studio
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === tabKr })}
              onClick={() => { this.toggle(tabKr); }}
            >
              Katalon Recorder
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === tabJunit })}
              onClick={() => { this.toggle(tabJunit); }}
            >
              JUnit
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === tabCli })}
              onClick={() => { this.toggle(tabCli); }}
            >
              CLI Tool
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId={tabKs}>
            <Row>
              <Col>
                <br />
                <p>
                  Katalon Studio is a FREE and robust desktop automation solution for API, Web, and Mobile testing.
                  To download Katalon Studio, visit our website <a href="https://www.katalon.com/download">www.katalon.com/download.</a>
                </p>
                <ManuallyUploadReport />
                <AutomaticallyUploadReport />
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId={tabKr}>
            <Row>
              <Col>
                <br />
                <p>
                  A FREE Selenium IDE-compatible replacement add-on for latest Chrome and Firefox that helps record, play,
                  debug, manage automated tests, and export to C#, Java, Ruby, Python, Groovy, or Robot Framework. Get Katalon
                  Recorder for Chrome <a href="https://chrome.google.com/webstore/detail/katalon-recorder-selenium/ljdobmomdgdljniojadhoplhkpialdid">here</a> or
                  for Firefox <a href="https://addons.mozilla.org/en-US/firefox/addon/katalon-automation-record/">here.</a>
                </p>
                <p>To upload Katalon Recorder reports, click <strong>Analytics</strong> button and follow the instructions.</p>
                <p><img className="img-fluid img-thumbnail" alt="Manual Upload Report" src={KatalonRecorderImg} /></p>
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId={tabJunit}>
            <Row>
              <Col>
                <br />
                <p>JUnit XML is a popular format for reporting test execution results and is supported by all test automation tools.</p>
                <h4>Upload JUnit Reports Using Web Form</h4>
                {
                  projectId &&
                  (
                    <p>You can upload JUnit XML reports from <a href={uploadReportsLink}>here</a>.</p>
                  )
                }
                {
                  !projectId &&
                  (
                    <p>You can upload JUnit XML reports from a project homepage. You will need to create a new project first.</p>
                  )
                }
                <h4>Upload JUnit Reports Using CLI Tool</h4>
                <p>Please follow the <a href="https://docs.katalon.com/x/FhbR">instructions</a> to upload test reports using the CLI tool.</p>
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId={tabCli}>
            <Row>
              <Col>
                <br />
                <p>
                  Please follow the <a href="https://docs.katalon.com/x/FhbR">instructions</a> to upload test reports using the CLI tool.
                </p>
              </Col>
            </Row>
          </TabPane>
        </TabContent>

        <h4>Need Extra Help?</h4>
        <div>
          <ul>
            <li>Visit <a href="https://forum.katalon.com/">Katalon Community</a></li>
            <li>Visit <a href="https://docs.katalon.com/katalon-studio/docs/index.html">Katalon Documentation</a></li>
          </ul>
        </div>
      </div>
    );
  }
}

export default HowToUpload;
