import React from 'react';
import { Button, Card } from 'reactstrap';
import PageComponent from '../components/PageComponent';
import { t } from '../i18n/t';
import { IconKatalonPlatformColor, IconCloudCheck } from '../images/CustomNewIcon';
import Helper from '../utils/Helper';
import { KS_DOWNLOAD_TYPE } from '../components/dialog/DownloadKSDialog';
import Services from '../utils/Services';
import UrlHelper from '../utils/UrlHelper';

class WelcomeDownloadKs extends PageComponent {
  constructor(props) {
    super(props);
    this.meta.id = 'testops-welcome-download-ks';
    this.meta.title = t('survey#title');

    this.state = {
      chosenEdition: KS_DOWNLOAD_TYPE.KSE_PLATFORM
    };

    this.chooseKatalonPlatform = this.chooseKatalonPlatform.bind(this);
    this.chooseKatalonStudioStandalone = this.chooseKatalonStudioStandalone.bind(this);
    this.handleContinue = this.handleContinue.bind(this);
  }

  chooseKatalonPlatform() {
    this.setState({ chosenEdition: KS_DOWNLOAD_TYPE.KSE_PLATFORM });
  }

  chooseKatalonStudioStandalone() {
    this.setState({ chosenEdition: KS_DOWNLOAD_TYPE.KSE_STANDALONE });
  }

  getDataTrackId(chosenEdition) {
    switch (chosenEdition) {
      case KS_DOWNLOAD_TYPE.KSE_PLATFORM:
        return 'start-with-platform-edition';
      case KS_DOWNLOAD_TYPE.KSE_STANDALONE:
        return 'start-with-standalone-edition';
      default:
        return null;
    }
  }

  handleContinue() {
    const { chosenEdition } = this.state;
    if (chosenEdition !== KS_DOWNLOAD_TYPE.KSE_PLATFORM) {
      Helper.downloadKS(null, chosenEdition);
    }
    const resource = { toSurveyStartWithEdition: chosenEdition };

    Services
      .submitTestOpsSurvey(resource)
      .then(() => {
        UrlHelper.navigateToForwardURL();
      });
  }

  render() {
    const { chosenEdition } = this.state;
    const dataTrackId = this.getDataTrackId(chosenEdition);
    return (
      <div className="welcome-testops">
        <div className="welcome-header">
          <div><IconKatalonPlatformColor /></div>
          <div className="welcome-message">{t('welcome-download-ks#welcome-header')}</div>
          <div className="welcome-sub-message">{t('welcome-download-ks#welcome-subheader')}</div>
        </div>
        <div>
          <Card
            className={`pb-4 download-ks-platform-box ${chosenEdition === KS_DOWNLOAD_TYPE.KSE_PLATFORM ? 'chosen' : ''}`}
            onClick={this.chooseKatalonPlatform}
          >
            <div className="mini-header">{t('welcome-download-ks#platform-title')}</div>
            <div className="pt-1"><IconCloudCheck className="mr-2" />{t('welcome-download-ks#platform-information#1')}</div>
            <div className="pt-1"><IconCloudCheck className="mr-2" />{t('welcome-download-ks#platform-information#2')}</div>
            <div className="pt-1"><IconCloudCheck className="mr-2" />{t('welcome-download-ks#platform-information#3')}</div>
          </Card>
          <Card
            className={`pb-4 pt-4 download-ks-standalone-box ${chosenEdition === KS_DOWNLOAD_TYPE.KSE_STANDALONE ? 'chosen' : ''}`}
            onClick={this.chooseKatalonStudioStandalone}
          >
            <div className="mini-header">{t('welcome-download-ks#ks-standalone-information#1')}</div>
            <div className="standalone-description">{t('welcome-download-ks#ks-standalone-information#2')}</div>
          </Card>
        </div>
        <div className="mb-5">
          <Button
            color="primary"
            onClick={this.handleContinue}
            disabled={!chosenEdition}
            data-trackid={dataTrackId}
          >
            Continue
          </Button>
        </div>
        <div />
      </div>
    );
  }
}

export default WelcomeDownloadKs;
