import React from 'react';
import PropTypes from 'prop-types';
import MAuth from '../../../models/MAuth';
import RecentProject from './RecentProject';
import { next } from '../../../utils/Count';
import Routes from '../../../utils/Routes';
import MContext from '../../../models/MContext';
import { t } from '../../../i18n/t';
import OnBoardingOrganization from '../../home/components/OnBoardingOrganization';
import DataLoader from '../../../components/table/DataLoader';
import { buildSearchCondition } from '../../../components/search/SearchUtils';
import GroupEvent from '../../../utils/track/GroupEvent';
import MFlags from '../../../models/MFlags';
import Apis from '../../../utils/Apis';

class RecentProjects extends React.Component {

  constructor(props) {
    super(props);
    this.dataRef = React.createRef();
    this.organization = this.props.organization;
    this.maxRecentProjects = this.props.maxRecentProjects;
    this.user = MAuth.user;
    this.renderBodyCard = this.renderBodyCard.bind(this);
  }

  renderCard(project) {
    return (
      <RecentProject
        key={next()}
        project={project}
      />
    );
  }

  renderProjects(projects) {
    const data = projects.slice(0, this.maxRecentProjects);
    return (
      <div className="dashboard-card">
        {data.map((project) => this.renderCard(project))}
      </div>
    );
  }

  renderNoRecentProjects() {
    return (
      <>
        <h5>No recent projects.</h5>
      </>
    );
  }

  renderNoTeamAndNoPermission() {
    return (
      <>
        <h3>Join a team to view project activities</h3>
        <p>You'll need an invitation from your team admin to join.</p>
        <br />
      </>
    );
  }

  renderBodyCard(projects) {
    if (projects && projects.length > 0) {
      const { organizationId } = MContext;
      const viewAllProjectLink = Apis.adminOrganizationAllProjects(organizationId);
      return (
        <>
          <div className="d-flex align-items-start">
            <div className="mr-auto p-0 dashboard-header">
              {t('org-dashboard-recentProject')}
            </div>
            <a data-trackid="view-all-projects" href={viewAllProjectLink} className="p-0" data-groupid={GroupEvent.ACCESS_REPORT}>
              {t('view_projects')}
            </a>
          </div>
          {this.renderProjects(projects)}
        </>
      );
    }

    if (this.user && projects.length === 0) {
      const isOrganizationManager = MAuth.isOrganizationManager(this.organization.id);
      if (isOrganizationManager) {
        return (
          <OnBoardingOrganization
            step={1}
            organizationId={this.organization.id}
          />
        );
      } else {
        return (
          <>
            {this.renderNoTeamAndNoPermission()}
          </>
        );
      }
    }

    return <>{this.renderNoRecentProjects()}</>;
  }

  renderRecentProjects() {
    const { autoUpdate, autoUpdateIntervalTime } = this.props;

    return (
      <DataLoader
        ref={this.dataRef}
        entityType="RecentProject"
        render={this.renderBodyCard}
        defaultSearchConditions={[
          buildSearchCondition('Organization.id', '=', this.organization.id),
        ]}
        pageSize={3}
        hidePaging
        noCard
        autoUpdate={autoUpdate}
        autoUpdateIntervalTime={autoUpdateIntervalTime}
      />
    );
  }

  render() {
    return (
      <>
        {this.renderRecentProjects()}
      </>
    );
  }
}

RecentProjects.propTypes = {
  organization: PropTypes.object.isRequired,
  maxRecentProjects: PropTypes.number,
};

RecentProjects.defaultProps = {
  maxRecentProjects: 3
};

export default RecentProjects;
