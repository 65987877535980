import { matchPath } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import Cookies from 'cookies-js';
import MAuth from '../models/MAuth';
import MConfigs from '../models/MConfigs';
import MFlags from '../models/MFlags';
import http from '../utils/http';
import { DomEventHandlers } from '../utils/EventHandler';

const base64UrlEncode = (a) => {
  var str = "";
  var bytes = new Uint8Array(a);
  var len = bytes.byteLength;
  for (var i = 0; i < len; i++) {
    str += String.fromCharCode(bytes[i]);
  }
  return btoa(str)
    .replace(/\+/g, "-")
    .replace(/\//g, "_")
    .replace(/=+$/, "");
}

const generateRandomString = (length = 128) => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i += 1) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

const generatePKCE = async () => {
  const codeVerifier = generateRandomString();
  const encoder = new TextEncoder();
  const data = encoder.encode(codeVerifier);
  const digest = await window.crypto.subtle.digest("SHA-256", data);
  const codeChallenge = base64UrlEncode(digest);
  return {
    code_verifier: codeVerifier,
    code_challenge: codeChallenge,
  };
};

const exchangeAccessToken = (authorizationCode) => {
  const clientId = 'katalon-testops';
  const codeVerifier = Cookies.get('code_verifier');
  if (!codeVerifier) {
    console.log("Katalon: Not found code_verifier");
    MAuth.logout();
    return;
  }
  const data = {
    'code': authorizationCode,
    'grant_type': 'authorization_code',
    'client_id': clientId,
    'redirect_uri': Cookies.get('redirect_uri'),
    'code_verifier': codeVerifier,
  };
  return http.post(`${MConfigs.authorizationServerUrl}/realms/katalon/protocol/openid-connect/token`, data, null, true, null, null, false).then((response) => {
    Cookies.set('refresh_token', response.refresh_token);
    Cookies.set('access_token', response.access_token);
    MAuth.authenticateAndNavigate(response.access_token);
  }).catch((error) => {
    console.log("Katalon: Error when requesting access token");
    console.error(error);
    MAuth.logout();
  });
};

const refreshAccessToken = (checkExpirationDate = true) => {
  const refreshToken = Cookies.get('refresh_token');
  if (!refreshToken) {
    return;
  }

  const accessToken = Cookies.get('access_token');
  if (!accessToken) {
    console.log("Katalon: Not found access token");
    MAuth.logout(false);
    return Promise.resolve();
  }

  if (checkExpirationDate) {
    try {
      const decoded = jwtDecode(accessToken);
      const now = new Date();
      const exp = new Date(decoded.exp * 1000);
      if (exp - now > 60000) {
        return Promise.resolve();
      }
    } catch(err) {
      console.error(err);
    }
  }

  try {
    const decoded = jwtDecode(refreshToken);
    const now = new Date();
    const exp = new Date(decoded.exp * 1000);
    if (exp < now) {
      console.log("Katalon: Refresh token is expired");
      MAuth.logout(false);
      return Promise.resolve();
    }
  } catch(err) {
    console.error(err);
  }

  const clientId = 'katalon-testops';
  const data = {
    'grant_type': 'refresh_token',
    'client_id': clientId,
    'refresh_token': Cookies.get('refresh_token'),
  };
  return http.post(`${MConfigs.authorizationServerUrl}/realms/katalon/protocol/openid-connect/token`, data, null, true, null, null, false).then((response) => {
    Cookies.set('refresh_token', response.refresh_token);
    Cookies.set('access_token', response.access_token);
    MAuth.authenticate(response.access_token);
    return data;
  }).catch((error) => {
    console.log("Error when refreshing access token");
    MAuth.logout(false);
  })
};

const login = async (redirectURL) => {
  const challenge = await generatePKCE();
  Cookies.set('code_verifier', challenge.code_verifier);
  const clientId = 'katalon-testops';
  const codeChallenge = challenge.code_challenge;
  const redirectUri = !redirectURL ?
    `${window.location.protocol}//${window.location.host}/authenticate` :
    `${window.location.protocol}//${window.location.host}/authenticate?redirect=${redirectURL}`;
  const subDomain = MConfigs.isCustomDomain ? `&sub_domain=${MConfigs.subDomain}` : '';

  Cookies.set('redirect_uri', redirectUri);
  window.location.href = `${MConfigs.authorizationServerUrl}/realms/katalon/protocol/openid-connect/auth?client_id=${clientId}&redirect_uri=${window.encodeURIComponent(redirectUri)}&response_type=code&scope=openid&code_challenge=${codeChallenge}&code_challenge_method=S256${subDomain}`;
};

const logout = () => {
  if (Cookies.get('refresh_token')) {
    const data = {
      'client_id': 'katalon-testops',
      'refresh_token': Cookies.get('refresh_token'),
    };
    Cookies.set('refresh_token', undefined);
    Cookies.set('access_token', undefined);
    return http.post(`${MConfigs.authorizationServerUrl}/realms/katalon/protocol/openid-connect/logout`, data, null, true, null, null, false);
  } else {
    Cookies.set('refresh_token', undefined);
    Cookies.set('access_token', undefined);
    return Promise.resolve();
  }
};

const UserService = {
  login,
  logout,
  exchangeAccessToken,
  refreshAccessToken,
  getAccessToken: () => Cookies.get('access_token'),
  setAccessToken: (accessToken) => Cookies.set('access_token', accessToken),
  setRefreshToken: (refreshToken) => Cookies.set('refresh_token', refreshToken),
};

export default UserService;
