import _ from 'lodash';
import MAuth from '../models/MAuth';
import MFlags from '../models/MFlags';
import UserService from '../auth/UserService';
import SpinnerPage from '../auth/SpinnerPage';
import MConfigs from '../models/MConfigs';
import Services from '../utils/Services';
import PageComponent from '../components/PageComponent';

export default class Logout extends PageComponent {
  constructor(props) {
    super(props);
    this.meta.id = 'page-logout';
  }

  render() {
    console.log("Katalon: Login page");
    MAuth.logout(true);
    return <SpinnerPage />;
  }
}
