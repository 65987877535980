import React from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import MTableColumnDataMapping from '../../components/table/models/MTableColumnDataMapping';
import { t } from '../../i18n/t';
import DecoratorConstants from '../../utils/DecoratorConstants';
import DataTable from '../../components/table/DataTable';
import { TEST_CASE_TEST_SUITE_PAGE_SIZE, SearchEntity } from '../../utils/Constants';

class TestCaseDataTable extends React.Component {

  render() {
    const { tcNameTrackingProps, isScriptRepoFolder } = this.props;

    const headers = [
      new MTableColumnDataMapping(
        t('no'),
        'sortOrder',
        (name, row) => {
          if (row.sortOrder) {
            return DecoratorConstants.orderDecorator(name, row);
          } else {
            return null;
          }
        },
        undefined,
        'fit-content-column',
        undefined,
        '5%'
      ),
      new MTableColumnDataMapping(
        t('name'),
        'name',
        (name, row) => DecoratorConstants.testCaseNameDecorator(name, row.testCase, tcNameTrackingProps, true),
        'short-text-column',
        '',
        undefined,
        '40%'
      ),
      new MTableColumnDataMapping(
        t('path'),
        '',
        (name, row) => {
          const sourcePath = DecoratorConstants.addSourceOfTestCaseAndTestSuite(row.testCase.path, row.testCase.testProject);
          return DecoratorConstants.textMiddleTruncateDecorator(sourcePath);
        },
        'short-text-column',
        '',
        undefined,
        '30%'
      ),
      new MTableColumnDataMapping(
        t('maintainer'),
        'maintainer',
        (name, row) => {
          const value = get(row.testCase, name);
          if (value) {
            return DecoratorConstants.renderUserWithoutNameDecorator(value);
          }
          return null;
        }
      ),
      new MTableColumnDataMapping(
        t('last_updated'),
        'updatedAt',
        (name, row) => {
          if (row.testCase.updatedAt) {
            return DecoratorConstants.timeDecorator(name, row.testCase);
          } else {
            return null;
          }
        },
        undefined,
        'nowrap-column'
      ),
    ];
    const emptyMessage = isScriptRepoFolder ? t('table#empty-message') : t('test-cases-not-retrievable');

    return (
      <DataTable
        fixLayout
        columnMapping={headers}
        entityType={SearchEntity.TestSuiteTestCase}
        unitName="test case"
        useSortByColumn
        useTitleNoCard
        pageSize={TEST_CASE_TEST_SUITE_PAGE_SIZE}
        emptyMessage={emptyMessage}
        {...this.props}
      />
    );
  }
}

TestCaseDataTable.propTypes = {
  tcNameTrackingProps: PropTypes.object,
};

TestCaseDataTable.defaultProps = {
  tcNameTrackingProps: null,
};

export default TestCaseDataTable;