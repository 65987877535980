import React from 'react';
import { Col, Card, CardBody, Button } from 'reactstrap';
import { isNumber, union } from 'lodash';
import { ListItem } from '@mui/material';
import Icon from '@katalon-studio/katalon-ui/Icon';
import { ThemeProvider } from '@mui/material/styles';
import PageComponent from '../components/PageComponent';
import DefaultLayout from '../components/DefaultLayout';
import { t } from '../i18n/t';
import MContext from '../models/MContext';
import { buildSearchCondition } from '../components/search/SearchUtils';
import DataLoader from '../components/table/DataLoader';
import TestSuiteDataTableV2 from '../components/table/TestSuiteDataTableV2';
import TreeView from '../components/treeview/TreeView';
import { FolderType, KATALON_EVENTS, SubFoldersLevel, TestFolderType } from '../utils/Constants';
import Services from '../utils/Services';
import Routes from '../utils/Routes';
import ConvertDataHelper from '../utils/ConvertDataHelper';
import CreateNewTestSuiteDialog from '../components/dialog/CreateNewTestSuiteDialog';
import ResizingDirectoryView from '../components/resizing_directory_view/ResizingDirectoryView';
import TreeNodeTitle from '../components/treeview/TreeNodeTitle';
import PopoverDropdownMenu from '../components/action/PopoverDropdownMenu';
import Notification from '../utils/Notification';
import PageHistoryHelper, { PageHistoryComponents } from '../utils/PageHistoryHelper';
import MFlags from '../models/MFlags';
import DropDownButton from '../components/DropDownButton';
import PublishTestSuiteDialog from '../components/cloud-studio-component/testsuite/PublishTestSuiteDialog';
import { IconFolderTestCaseCloudStudio, IconFolderTestCaseKatalonStudio } from '../images/CustomIcon';
import { sendAnalyticEventForAction } from '../utils/SegmentAnalytics';
import Link from '../components/Link';
import { katalonui_theme } from '../katalonui-theme';
import RenameDialog from '../components/cloud-studio-component/testcase/RenameDialog';
import MoveToLocationDialog from '../components/cloud-studio-component/testcase/MoveToLocationDialog';
import { DomEventHandlers } from '../utils/EventHandler';
import { next } from '../utils/Count';

class TestSuitesV2 extends PageComponent {
  constructor(props) {
    super(props);
    this.meta.id = 'page-test-suites';
    this.project = MContext.project;
    this.projectId = MContext.projectId;
    this.meta.title = t('Project {{name}} - Test Suites', { name: this.project.name });
    this.renderBody = this.renderBody.bind(this);
    this.renderTreeView = this.renderTreeView.bind(this);
    this.onFolderSelected = this.onFolderSelected.bind(this);
    this.handleOpenCreateTestSuiteDialog = this.handleOpenCreateTestSuiteDialog.bind(this);
    this.handleCloseCreateTestSuiteDialog = this.handleCloseCreateTestSuiteDialog.bind(this);
    this.handleOpenPublishTestSuiteDialog = this.handleOpenPublishTestSuiteDialog.bind(this);
    this.handleClosePublishTestSuiteDialog = this.handleClosePublishTestSuiteDialog.bind(this);
    this.renderCreateNewTestSuiteButton = this.renderCreateNewTestSuiteButton.bind(this);
    this.renderFolderTitle = this.renderFolderTitle.bind(this);
    this.handleAfterCreateTestSuite = this.handleAfterCreateTestSuite.bind(this);
    this.handleAfterPublishTestSuite = this.handleAfterPublishTestSuite.bind(this);
    this.expandKeysAndUpdateHistory = this.expandKeysAndUpdateHistory.bind(this);
    this.state = {
      currentFolderId: null,
      isCreateNewTestSuiteDialogOpen: false,
      currentTestProjectId: null,
      isReady: false,
      scriptRepos: [],
      // use this for re-render table and tree when create test suite done
      createdTestSuiteId: 0,
      isPublishCloudTestSuiteDialogOpen: false,
      modifiedTestSuite: null,
    };
    this.directoryWidth = 0;
  }

  handleAfterMoveTestSuiteSuccess = (publishedTestSuites) => {
    const testSuite = publishedTestSuites[0];

    Services.getTestSuite(testSuite.id)
      .then((publishedTestSuite) => {
        if (publishedTestSuite?.testFolder && publishedTestSuite.testProject) {
          this.expandKeysAndUpdateHistory(
            publishedTestSuite.testFolder,
            publishedTestSuite.testProject.id,
            publishedTestSuite.id
          );
        }
      })
      .catch(() => {});
  };

  componentDidMount() {
    DomEventHandlers.eventListener(KATALON_EVENTS.movedG5TestSuite, ({ detail }) => {
      this.handleAfterMoveTestSuiteSuccess(detail.publishedTestSuites);
    });

    ConvertDataHelper.getScriptRepos(this.projectId)
      .then((response) => {
        this.setState({
          scriptRepos: response,
          isReady: true,
        });
      });
    const treeState = PageHistoryHelper.getState(PageHistoryComponents.TESTSUITE_TREE_VIEW);
    const testSuiteView = PageHistoryHelper.getState(PageHistoryComponents.TESTSUITE_VIEW);
    if (treeState) {
      const { selectedKeys } = treeState;
      if (selectedKeys && selectedKeys.length > 0) {
        const selectedKey = selectedKeys[0];
        if (isNumber(selectedKey)) {
          this.setState({ currentFolderId: selectedKey });
        }
      }
    }
    if (testSuiteView) {
      const { testProjectId } = testSuiteView;
      this.setState({ currentTestProjectId: testProjectId });
    }
  }

  renderMessageEmptyTestFolders() {
    const routes = new Routes();
    return (
      <div className="empty-test-folders-message">
        <p> Get started by
          <Link href={routes.import_manual_reports_link} className="ml-1 mr-1">
            {t('uploading-your-data')}
          </Link>
          or
          <Link href={routes.test_project_create_git_link} className="ml-1">
            {t('setting-up-script-repository')}
          </Link>
        </p>
      </div>
    );
  }

  refreshTSC(testProjectId) {
    Services.refreshTSC(testProjectId)
      .then(() => {
        Notification.pushSuccess(t('tree-view#refresh-test-case-noti'));
      });
  }

  renderFolderTitle(nodeData) {
    const isScriptRepoRootFolder = ConvertDataHelper.isScriptRepoRootFolder(nodeData);
    const items = [
      (
        <ListItem
          button
          component="button"
          onClick={() => this.refreshTSC(nodeData.testProjectId)}
        >
          {t('refresh')}
        </ListItem>
      )
    ];
    return (
      <>
        <TreeNodeTitle id={nodeData.key} width={this.directoryWidth} title={nodeData.title} key={this.directoryWidth} />
        {isScriptRepoRootFolder && <PopoverDropdownMenu items={items} />}
      </>
    );
  }

  /**
   * Root folder is not contain test cases data
   * and have the key with String prefix 'root-*' tend to break API
   * So I will exclude this key and replace by -1
   * When user click to root folder, the UI will clean Data and do not show error
   */
  onFolderSelected(folder, selectedNodes) {
    const node = selectedNodes[0];
    if (folder[0] && isNumber(folder[0])) {
      this.setState({ currentFolderId: folder[0] });
    } else {
      this.setState({ currentFolderId: -1 });
    }
    this.setState({ currentTestProjectId: node.testProjectId });
    this.saveHistoryTestSuiteView(node.testProjectId);
  }

  expandKeysAndUpdateHistory(testFolder, testProjectId, testSuiteId) {
    const historyState = PageHistoryHelper.getState(PageHistoryComponents.TESTSUITE_TREE_VIEW);
    const selectedKeys = [testFolder.id];
    const expandedKeysTemp = this.handleTreePathToExpand(testFolder, testProjectId);
    // if expandedKeys is null -> set it, if is not null, combine 2 array without duplicate elements
    const expandedKeys = historyState && historyState.expandedKeys
      ? union(historyState.expandedKeys, expandedKeysTemp)
      : expandedKeysTemp;
    PageHistoryHelper.saveState(PageHistoryComponents.TESTSUITE_TREE_VIEW, { selectedKeys, expandedKeys });
    this.setState({
      createdTestSuiteId: testSuiteId,
      currentFolderId: testFolder.id,
      currentTestProjectId: testProjectId,
      modifiedTestSuite: next(),
    });
  }

  handleAfterCreateTestSuite(testSuite) {
    this.handleCloseCreateTestSuiteDialog();
    if (testSuite?.testFolder && testSuite.testProject) {
      this.expandKeysAndUpdateHistory(testSuite.testFolder, testSuite.testProject.id, testSuite.id);
    }
    Notification.pushSuccess(t('test-suite-create#notification-success', { name: testSuite?.name }));
  }

  handleAfterPublishTestSuite(testSuiteId, testProjectId) {
    this.handleClosePublishTestSuiteDialog();
    if (testSuiteId && testProjectId) {
      Services.getTestSuite(testSuiteId)
        .then((publishedTestSuite) => {
          if (publishedTestSuite?.testFolder && publishedTestSuite.testProject) {
            this.expandKeysAndUpdateHistory(
              publishedTestSuite.testFolder,
              publishedTestSuite.testProject.id,
              publishedTestSuite.id
            );
          }
        })
        .catch(() => {});
    }
  }

  handleTreePathToExpand(testFolder, testProjectId) {
    const { treePath, id } = testFolder;
    const expandedKeys = treePath.split('.').map(Number);
    // first expandedKeys is Nah, so I set it is "root_{testProjectId)"
    expandedKeys[0] = `root_${testProjectId}`;
    expandedKeys.push(id);
    return expandedKeys;
  }

  saveHistoryTestSuiteView(testProjectId) {
    PageHistoryHelper.saveState(PageHistoryComponents.TESTSUITE_VIEW, { testProjectId });
  }

  loadData(key) {
    return Services.getSubFolders(key, SubFoldersLevel.NUMBER_OF_LEVEL);
  }

  handleOpenCreateTestSuiteDialog() {
    this.setState({ isCreateNewTestSuiteDialogOpen: true });
    sendAnalyticEventForAction('open-katalon-studio-ts-creation-popup', {});
  }

  handleCloseCreateTestSuiteDialog() {
    this.setState({ isCreateNewTestSuiteDialogOpen: false });
  }

  handleOpenPublishTestSuiteDialog() {
    this.setState({ isPublishCloudTestSuiteDialogOpen: true });
    sendAnalyticEventForAction('open-cloud-studio-ts-creation-popup', {});
  }

  handleClosePublishTestSuiteDialog() {
    this.setState({ isPublishCloudTestSuiteDialogOpen: false });
  }

  renderCreateNewTestSuiteButton() {
    return (
      <Button className="mr-3" color="primary" onClick={this.handleOpenCreateTestSuiteDialog}>
        {t('add_test_suite')}
      </Button>
    );
  }

  renderCreateNewTestSuiteDialog() {
    const { isCreateNewTestSuiteDialogOpen, currentTestProjectId } = this.state;
    // TODO: please check the logic of currentTestProjectId when we apply restain state for TestSuite Tree
    return (
      <CreateNewTestSuiteDialog
        id="create-test-suite-dialog"
        currentTestProjectId={currentTestProjectId}
        isOpen={isCreateNewTestSuiteDialogOpen}
        handleClose={this.handleCloseCreateTestSuiteDialog}
        handleAfterCreateTestSuite={this.handleAfterCreateTestSuite}
      />
    );
  }

  renderPublishTestSuiteDialog() {
    const { isPublishCloudTestSuiteDialogOpen } = this.state;
    return (
      <PublishTestSuiteDialog
        isOpen={isPublishCloudTestSuiteDialogOpen}
        onClose={this.handleClosePublishTestSuiteDialog}
        handleAfterPublishTestSuite={this.handleAfterPublishTestSuite}
      />
    );
  }

  goToTestSuiteSearchPage(searchParams) {
    Routes.goToTestSuiteSearchPage(searchParams);
  }

  renderDataTable() {
    const { currentFolderId, createdTestSuiteId } = this.state;
    const defaultSearchConditions = [
      buildSearchCondition('TestFolder.id', '=', currentFolderId || '-1'),
    ];
    const defaultSort = ['updatedAt, desc NULLS LAST'];
    const tsNameTrackingProps = {
      'data-trackid': 'test-suite-detail'
    };
    const conditionProps = {
      defaultSearchConditions,
      defaultSort
    };
    const renderLeftComponent = MFlags.g5Editor ? null : this.renderCreateNewTestSuiteButton;
    return (
      <Col className="scroll-container">
        <TestSuiteDataTableV2
          key={`${createdTestSuiteId}_${currentFolderId}`} // not to change key while user apply filter
          tsNameTrackingProps={tsNameTrackingProps}
          noCard
          renderLeftComponent={renderLeftComponent}
          goToResultPage={this.goToTestSuiteSearchPage}
          isSupportMultipleSelect={true}
          {...conditionProps}
        />
      </Col>
    );
  }

  renderTreeView(data) {
    const { scriptRepos, modifiedTestSuite } = this.state;
    const objectRootFolder = ConvertDataHelper.buildRootDataTree(data, scriptRepos);
    return (
      <Col xs="auto" className="scroll-container">
        {this.renderDropDownButtonAdd()}
        <TreeView
          key={modifiedTestSuite}
          className="tree-view-components"
          data={objectRootFolder.dataRootFolder}
          defaultSelectedKeys={[FolderType.ROOT_UPLOADED]}
          onSelect={this.onFolderSelected}
          loadData={this.loadData}
          titleRender={
            (nodeData) =>
              (MFlags.onCloudTestStorageEnabled
                ? ConvertDataHelper.renderFolderTitle(nodeData, this.directoryWidth)
                : this.renderFolderTitle(nodeData))
          }
          enableHistory
          componentName={PageHistoryComponents.TESTSUITE_TREE_VIEW}
        />
        {objectRootFolder.isEmpty && this.renderMessageEmptyTestFolders()}
      </Col>
    );
  }

  folderTree() {
    const { isReady } = this.state;
    if (!isReady) {
      return null;
    }
    return (
      <DataLoader
        noCard
        entityType="TestFolder"
        pageSize={300}
        fetchAllPages
        render={this.renderTreeView}
        defaultSort={['id,asc']}
        defaultSearchConditions={[
          buildSearchCondition('TestFolder.id', 'is null', ''),
          buildSearchCondition('type', '=', TestFolderType.TEST_SUITE)
        ]}
      />
    );
  }

  directoryWidthOnChange = (directoryWidth) => {
    this.directoryWidth = directoryWidth;
  };

  renderDropDownButtonAdd() {
    if (MFlags.g5Editor) {
      const options = [
        {
          icon: <IconFolderTestCaseCloudStudio width="18px" height="13px" />,
          title: t('add_test_suite#cloud-studio'),
          handleClick: this.handleOpenPublishTestSuiteDialog,
        },
        {
          icon: <IconFolderTestCaseKatalonStudio width="18px" height="13px" />,
          title: t('add_test_suite#katalon-studio'),
          handleClick: this.handleOpenCreateTestSuiteDialog,
        }
      ];
      return (
        <DropDownButton
          title={t('add_test_suite#new')}
          options={options}
          className="add-btn"
          startIcon={<Icon
            type="fa-solid"
            name="fa-plus"
            textSize="16px"
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              i: {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              },
            }}
          />}
        />
      );
    } else {
      return null;
    }
  }

  renderBody() {
    const { isCreateNewTestSuiteDialogOpen, isPublishCloudTestSuiteDialogOpen } = this.state;
    return (
      <Card className="tree-view-card">
        <CardBody className="tree-view-card-body row">
          <ResizingDirectoryView directoryWidthOnChange={this.directoryWidthOnChange}>
            {this.folderTree()}
          </ResizingDirectoryView>
          {this.renderDataTable()}
          {isCreateNewTestSuiteDialogOpen && this.renderCreateNewTestSuiteDialog()}
          {isPublishCloudTestSuiteDialogOpen && this.renderPublishTestSuiteDialog()}
          <ThemeProvider theme={katalonui_theme}>
            <MoveToLocationDialog />
            <RenameDialog />
          </ThemeProvider>
        </CardBody>
      </Card>
    );
  }

  render() {
    return (
      <DefaultLayout
        renderBody={this.renderBody}
      />
    );
  }
}

export default TestSuitesV2;
