import React, { useEffect, useState } from 'react';
import { Alert, Button, Col, Row } from 'reactstrap';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Checkbox, CircularProgress } from '@mui/material';
import Routes from '../../../utils/Routes';
import { ExternalConnection } from '../../../models/model/ExternalConnection';
import { t } from '../../../i18n/t';
import Services from '../../../utils/Services';
import MContext from '../../../models/MContext';
import Notification from '../../../utils/Notification';

interface ExternalIssueNotificationsSettingProps {
  setTabState?: () => void;
  connection: ExternalConnection,
  getConnectionAndSetState: (projectId: number) => ExternalConnection,
}

function ExternalIssueNotificationsSetting(props: Readonly<ExternalIssueNotificationsSettingProps>) {

  const connection = props.connection;
  const projectId = MContext.projectId;
  const [isSelectedManuallyOption, setIsSelectedManuallyOption] = useState(connection ? connection.notifyLinkIssueManually : true);
  const [isSelectedAutomaticallyOption, setIsSelectedAutomaticallyOption] = useState(connection ? connection.notifyLinkIssueAutomatically : true);
  const [isDisableContent, setIsDisableContent] = useState(true);
  const [slackConnection, setSlackConnection] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const getSlackConnection = () => {
    Services.getSlackConnection(projectId)
      .then(({ content }) => {
        const connection = content[0];
        if (connection) {
          setIsDisableContent(false);
          setSlackConnection(connection);
        }
        setIsLoading(false);
      });
  };

  const hasConnection = !!connection;
  const hasSlackIntegration = !!slackConnection;

  useEffect(() => {
    if (hasConnection && connection.active) {
      getSlackConnection();
    } else {
      setIsLoading(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelectManuallyOption = () => {
    setIsSelectedManuallyOption(!isSelectedManuallyOption);
  };

  const handleSelectAutomaticallyOption = () => {
    setIsSelectedAutomaticallyOption(!isSelectedAutomaticallyOption);
  };

  const route = new Routes();
  const slackSettingUrl = route.slack_setting_link;

  const jiraConfigurationRequiredInfo = () => (
    <div className="jira-notification-info">
      {t('slack-notifications-info')}
      <a href={slackSettingUrl} target="_blank" className="ml-1" rel="noreferrer">{t('incoming_webhook')}.</a>
    </div>
  );

  const slackRequiredAlert = () => (
    <Alert color="warning" className="mt-2 mb-2">
      {t('require-slack-configure-warning')}
      <a href={slackSettingUrl} target="_blank" className="ml-1" rel="noreferrer">{t('slack_setting')}.</a>
    </Alert>
  );

  const jiraConfigureRequiredAlert = () => (
    <Alert color="warning" className="mt-3 mb-4">
      {t('require-jira-configure-warning')}
      <a href="#" onClick={props.setTabState} className="ml-1">{t('configuration-tab')}.</a>
    </Alert>
  );

  const renderHeader = () => (
    <>
      {(!hasConnection || (hasConnection && !connection.active)) && jiraConfigureRequiredAlert()}
      <h6 className="fw-500">
        {t('slack-notifications-header')}
      </h6>
      {hasConnection && connection.active && !hasSlackIntegration && slackRequiredAlert()}
    </>
  );

  const icon = <CheckBoxOutlineBlankIcon />;
  const checkedIcon = <CheckBoxIcon />;

  const saveNotificationConfig = (e: React.MouseEvent<Element, MouseEvent> | React.SyntheticEvent) => {
    e.preventDefault();
    const setting = {
      projectId,
      id: props.connection.id,
      notifyLinkIssueManually: isSelectedManuallyOption,
      notifyLinkIssueAutomatically: isSelectedAutomaticallyOption,
    };
    Services.saveExternalIssueNotificationConfig(setting)
      .then(() => {
        props.getConnectionAndSetState(projectId);
        Notification.pushSuccess(t('jira-integration#setting-success'));
      });
  };

  if (isLoading) {
    return (
      <div className="d-flex w-100 justify-content-center mt-4">
        <CircularProgress size={40} />
      </div>
    );
  }

  return (
    <Row>
      <Col sm="12" md="8" lg="8" xl="8">
        <div>
          {renderHeader()}
          {hasConnection && hasSlackIntegration && jiraConfigurationRequiredInfo()}
          <div className={isDisableContent ? 'ui-state-disabled' : ''}>
            <div className="d-flex align-items-center">
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                className="mr-1"
                onChange={handleSelectManuallyOption}
                checked={isSelectedManuallyOption}
              />
              {t('slack-manually-option')}
            </div>
            <div className="d-flex align-items-center">
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                className="mr-1"
                onChange={handleSelectAutomaticallyOption}
                checked={isSelectedAutomaticallyOption}
              />
              {t('slack-automatically-option')}
            </div>
            <Button
              id="save-configure"
              color="primary"
              className="mt-3"
              onClick={saveNotificationConfig}
            >
              {t('save')}
            </Button>
          </div>
        </div>
      </Col>
    </Row>
  );
}

export default ExternalIssueNotificationsSetting;

