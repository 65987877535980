import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Form, FormFeedback, FormGroup, Label } from 'reactstrap';
import Input from '../../components/Input';
import { t } from '../../i18n/t';
import Arrays from '../../utils/Arrays';
import { formConstraints, validate } from '../../utils/FormValidation';

class ChangePasswordForm extends React.Component {

  constructor(props) {
    super(props);

    this.state = this.getInitialState();
    this.handleTextChange = this.handleTextChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  getInitialState() {
    const formData = {
      currentPassword: null,
      password: null,
      confirmPassword: null,
    };

    return {
      formData,
      formError: formData,
    };
  }

  validateForm(formData) {
    const { hideCurrentPassword } = this.props;
    const constraints = _.pick(formConstraints, [
      ...Arrays.insertIf(!hideCurrentPassword, 'currentPassword'), 'password', 'confirmPassword'
    ]);
    const formError = validate(formData, constraints);
    this.setFormError(formError);
    return formError;
  }

  setFormError(formError) {
    this.setState({ formError });
  }

  handleTextChange(event) {
    const { handleFormDataChange } = this.props;
    const name = event.target.name;
    const value = event.target.value;
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        [name]: value,
      },
    }), () => {
      if (handleFormDataChange) {
        handleFormDataChange(this.state.formData);
      }
      this.validateForm(this.state.formData);
    });
  }

  handleSubmit(event) {
    event.preventDefault();

    const { handleFormSubmission } = this.props;
    const { formData } = this.state;

    const formError = this.validateForm(formData);

    if (_.isEmpty(formError) && handleFormSubmission) {
      handleFormSubmission(event);
    }
  }

  render() {
    const { formError } = this.state;
    const { hideCurrentPassword, submitButtonTitle, ...props } = this.props;

    return (
      <>
        <Form
          data-trackid="change-password"
          onSubmit={this.handleSubmit}
          noValidate
          {...props}
        >
          {!hideCurrentPassword && (
            <FormGroup>
              <Label for="currentPassword">{t('user#old_password')}</Label>
              <Input
                required
                id="currentPassword"
                name="currentPassword"
                invalid={!!formError.currentPassword}
                onChange={this.handleTextChange}
                type="password"
              />
              <FormFeedback className="invalid-feedback">{formError.currentPassword}</FormFeedback>
            </FormGroup>
          )}
          <FormGroup>
            <Label for="password">{t('user#new_password')}</Label>
            <Input
              required
              id="password"
              name="password"
              invalid={!!formError.password}
              onChange={this.handleTextChange}
              type="password"
            />
            <FormFeedback className="invalid-feedback">{formError.password}</FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label for="confirmPassword">{t('user#confirm_new_password')}</Label>
            <Input
              required
              id="confirmPassword"
              name="confirmPassword"
              invalid={!!formError.confirmPassword}
              onChange={this.handleTextChange}
              type="password"
            />
            <FormFeedback className="invalid-feedback">{formError.confirmPassword}</FormFeedback>
          </FormGroup>
          <FormGroup>
            <Button
              type="submit"
              color="primary"
            >
              {submitButtonTitle}
            </Button>
          </FormGroup>
        </Form>
      </>
    );
  }
}

ChangePasswordForm.propTypes = {
  handleFormDataChange: PropTypes.func,
  handleFormSubmission: PropTypes.func,
  hideCurrentPassword: PropTypes.bool,
  submitButtonTitle: PropTypes.string,
};

ChangePasswordForm.defaultProps = {
  handleFormDataChange: null,
  handleFormSubmission: null,
  hideCurrentPassword: false,
  submitButtonTitle: t('user#update_password'),
};

export default ChangePasswordForm;
