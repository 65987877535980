import React, { useState, useEffect } from 'react';
import { isEmpty } from 'lodash';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ClearIcon from '@mui/icons-material/Clear';
import MContext from '../../models/MContext';
import { t } from '../../i18n/t';
import { IconYellowWarning } from '../../images/CustomNewIcon';
import Services from '../../utils/Services';
import WarningPackageAlertComponent from '../WarningPackageAlertComponent';
import Time from '../../utils/Moment';
import Apis from '../../utils/Apis';

export default function TestcloudTrialWarning() {
  const { targetOrganization } = MContext;
  const [testExecutionQuota, setTestExecutionQuota] = useState({});
  const [isHidden, setIsHidden] = useState(false);

  useEffect(() => {
    if (targetOrganization) {
      Services.getTestCloudOrganizationQuota(targetOrganization.id)
        .then((quota) => setTestExecutionQuota(quota));
    }
  }, [targetOrganization]);

  const buildWarningText = () => {
    const daysLeft = Time.countDays(Time.now(), Time.convertToDate(testExecutionQuota?.subscriptionExpiredAt));
    if (daysLeft <= 0) {
      return (
        <>
          <span className="pr-1">{t('testcloud_expired_message')}</span>
          <a
            target="_blank"
            href={Apis.katOneAccountSubscriptionManagementPage({ organizationId: targetOrganization.id })}
            rel="noreferrer"
          >{t('testcloud_buy_license')}
          </a>
          <span className="pl-1 pr-1">or</span>
          <a
            target="_blank"
            href={process.env.REACT_APP_CONTACT_URL}
            rel="noreferrer"
          > {t('testcloud_contact_sales')}
          </a>
        </>
      );
    }
    if (daysLeft <= 10) {
      return (
        <>
          <span className="pr-1">{t('testcloud_free_trial_warning')}</span>
          <span className="p-1 badge badge-pro"><AccessTimeIcon
            sx={{ width: '14px', height: '14px' }}
          /> {daysLeft} day(s)
          </span>
          <a
            className="pl-1"
            target="_blank"
            href={process.env.REACT_APP_TESTCLOUD_LEARN_MORE_URL}
            rel="noreferrer"
          > {t('testcloud_learn_more')}
          </a>
          <span className="pl-1 pr-1">or</span>
          <a
            target="_blank"
            href={process.env.REACT_APP_CONTACT_URL}
            rel="noreferrer"
          > {t('testcloud_contact_an_expert')}
          </a>
        </>
      );
    }
    return null;
  };

  if (isEmpty(testExecutionQuota) || !testExecutionQuota.trialUser || !buildWarningText()) return null;

  return (
    !isHidden &&
      <div className="warning-alert-banner">
        <WarningPackageAlertComponent
          text={buildWarningText()}
          icon={<IconYellowWarning />}
          iconClose={<ClearIcon
            onClick={() => {
              setIsHidden(true);
              localStorage.setItem('isHiddenWarning', true);
            }}
            color="warning"
            sx={{ fontSize: 24 }}
          />}
        />
      </div>
  );
}

