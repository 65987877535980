import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  createSelector,
  createAction,
} from '@reduxjs/toolkit';
import { AppState, buildSearchCondition } from '.';
import { SearchEntity } from '../utils/Constants';
import Services from '../utils/Services';

export const CUSTOM_FIELD_DIALOG_KEY = 'customFieldDialogWithTypeScript';

const customFieldDialogAdapter = createEntityAdapter();

interface CustomFieldDialogState {
  loading: boolean;
  displayName: string;
  customFieldKey: string;
  optionValues: {id?: number; value: string}[];
}

export const createInitialState = (): CustomFieldDialogState => customFieldDialogAdapter.getInitialState({
  loading: false,
  displayName: '',
  customFieldKey: '',
  optionValues: [{
    value: '',
  }],
});

export const doGetCustomFieldDefinition = createAsyncThunk(
  'getCustomFieldDefinition',
  async (customFieldDefinitionId: number) => {
    const params = {
      pagination: {
        page: 0,
        size: 1,
        sorts: ['id,desc'],
      },
      conditions: [
        buildSearchCondition('id', '=', customFieldDefinitionId),
      ],
      type: SearchEntity.CustomFieldDefinition,
    };
    const response = await Services.search(params);
    return response;
  },
);

export const doResetEntity = createAction('entity/reset');

const customFieldDialogSlice = createSlice({
  name: CUSTOM_FIELD_DIALOG_KEY,
  initialState: createInitialState(),
  reducers: {
    setDisplayName(state, action): void {
      state.displayName = action.payload;
    },
    setCustomFieldKey(state, action): void {
      state.customFieldKey = action.payload;
    },
    setOptionValues(state, action): void {
      state.optionValues = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(doGetCustomFieldDefinition.pending, (state) => {
      state.loading = false;
    });
    builder.addCase(doGetCustomFieldDefinition.fulfilled, (state, action) => {
      state.loading = true;
      state.customFieldKey = action.payload.content[0].key;
      state.displayName = action.payload.content[0].displayName;
      state.optionValues = action.payload.content[0].customFieldOptions;
    });
    builder.addCase(doResetEntity, (state) => {
      state.displayName = '';
      state.customFieldKey = '';
      state.optionValues = [{
        value: '',
      }];
    });
  },
});

const selectAll = (state: AppState) => state[CUSTOM_FIELD_DIALOG_KEY];

export const selectDisplayName = () => createSelector(
  selectAll,
  (state) => state.displayName,
);

export const selectCustomFieldKey = () => createSelector(
  selectAll,
  (state) => state.customFieldKey,
);

export const selectOptionValues = () => createSelector(
  selectAll,
  (state) => state.optionValues,
);

export const selectLoading = () => createSelector(
  selectAll,
  (state) => state.loading,
);

export const { setDisplayName, setCustomFieldKey, setOptionValues } = customFieldDialogSlice.actions;

export default customFieldDialogSlice.reducer;
