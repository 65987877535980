import React, { useState } from 'react';
import { t } from '../../../i18n/t';
import DateRangePicker from '../../../components/DateRangePicker';
import TestCasesCreated from '../reports/TestCasesCreated';
import { ProjectStatisticsType } from '../../../utils/Constants';
import DateHelper from '../../../utils/DateHelper';
import TimeSelect from '../../../components/select/TimeSelect';

const Productivity = ({ testCaseCreatedRef }) => {

  const [dateRange, setDateRange] = useState(DateHelper.getLast6Months());
  const [groupByTime, setGroupByTime] = useState(ProjectStatisticsType.MONTHLY);

  const handleDateFilterChange = (newDateRange) => {
    setDateRange(newDateRange);
  };

  const handleTimeFilterChange = (event, newTime) => {
    setGroupByTime(newTime.value);
  };

  const renderFilters = () => (
    <>
      <DateRangePicker className="project-dashboard-filter" value={dateRange} handleChange={handleDateFilterChange} />
      <TimeSelect id="productivity-time-filter" className="project-dashboard-filter autocomplete-filter ml-2" defaultValue={groupByTime} onSelectChange={handleTimeFilterChange} />
    </>
  );

  const renderHeader = () => (
    <div className="d-flex project-dashboard-header">
      <div className="project-dashboard-header-title">{t('project-dashboard#productivity')}</div>
      <div className="ml-auto align-items-center d-flex">
        {renderFilters()}
      </div>
    </div>
  );

  const renderBody = () => (
    <TestCasesCreated
      dataLoaderRef={testCaseCreatedRef}
      startTime={dateRange.startTime}
      endTime={dateRange.endTime}
      statisticType={groupByTime}
    />
  );

  return (
    <>
      {renderHeader()}
      {renderBody()}
    </>
  );
};

export default Productivity;
