import React, { useEffect } from 'react';
import { Card, CardBody } from 'reactstrap';
import { StatelessApp } from '@katalon-studio/katalon-ui-utils';
import { t } from '../../i18n/t';
import ObjectSummary from '../../components/summary/ObjectSummary';
import DefaultLayout from '../../components/DefaultLayout';
import MConfigs from '../../models/MConfigs';
import MFlags from '../../models/MFlags';
import MContext from '../../models/MContext';
import MAuth from '../../models/MAuth';
import { sendAnalyticEventForAction, analyticsPage } from '../../utils/SegmentAnalytics';
import WebSocket from '../../services/WebSocket';
import Routes from '../../utils/Routes';

function AutonomousManagement() {
  document.title = `${t('true-test-configuration')} - ${t('brand_name')}`;

  useEffect(() => {
    const organizationId = MContext.organizationId;
    const isOrganizationManager = MAuth.isOrganizationManager(organizationId);
    if (!isOrganizationManager) {
      Routes.goToAccessDeniedPage();
    }
  }, []);

  const renderHeader = () => {
    const urlParams = {
      truetestConfiguration: t('true-test-configuration'),
    };
    return (
      <ObjectSummary params={urlParams} />
    );
  };

  const TestOpsContext = {
    MContext,
    MAuth,
    MFlags,
    MConfigs,
    TestOpsUtils: {
      sendAnalyticEventForAction,
      analyticsPage,
      WebSocket
    }
  };

  const renderBody = () => (
    <Card>
      <CardBody>
        <StatelessApp
          id="katalon-autonomous"
          path={MConfigs.microAppAutonomousUrl}
          context={TestOpsContext}
        />
      </CardBody>
    </Card>
  );

  return (
    <DefaultLayout
      renderHeader={renderHeader}
      renderBody={renderBody}
    />
  );
}

export default AutonomousManagement;
