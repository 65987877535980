import React, { useState } from 'react';
import { Stack } from '@mui/material';
import Icon from '@katalon-studio/katalon-ui/Icon';
import makeStyles from '@mui/styles/makeStyles';
import Button from '@katalon-studio/katalon-ui/Button';
import { ExecutionTestResult } from '../../models/model/ExecutionTestResult';
import { t } from '../../i18n/t';
import ReportDefectDialog from '../../components/dialog/ReportDefectDialog';
import MarkAsRetestedDialog from '../../components/dialog/MarkAsRetestedDialog';
import MFlags from '../../models/MFlags';
import Routes from "../../utils/Routes";

interface ResultActionButtonGroupProps {
  readonly isRowItem?: boolean;
  readonly executionTestResult: ExecutionTestResult;
  readonly onMarkStatus: () => void;
  readonly onReportDefect: () => void;
  readonly useInnerDialog?: boolean;
  readonly isShownExternalLink?: boolean;
}

const useStyles = makeStyles({
  button: {
    backgroundColor: '#FFF',
    color: '#233145',
    boxShadow: 'none',
    border: 'solid 1px #DBDDE5',
    maxWidth: '32px',
    maxHeight: '32px',
    minWidth: '32px',
    minHeight: '32px',
    '&:hover': {
      backgroundColor: '#DBDDE5',
      boxShadow: 'none',
    },
  },
  buttonSecondary: {
    backgroundColor: '#ECEFF6',
    maxHeight: '32px',
    fontSize: '14px',
    color: '#1D3066',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#D3DFF9',
      boxShadow: 'none',
    },
  } });

function ResultActionButtons(props: ResultActionButtonGroupProps) {
  const { executionTestResult, isRowItem, onReportDefect, onMarkStatus, isShownExternalLink } = props;
  let { useInnerDialog } = props;
  const classes = useStyles();
  const statusMappings = {
    PASSED: {
      statusIcon: 'fa-xmark',
      markStatusTrackId: 'mark-as-failed',
      markStatusTitle: t('mark-as-failed'),
      markTitle: t('mark-as-failed')
    },
    FAILED: {
      statusIcon: 'fa-check-double',
      markStatusTrackId: 'mark-as-passed',
      markStatusTitle: t('mark-as-passed'),
      markTitle: t('mark-as-passed')
    },
  };

  const constructedLink = new Routes({
      executionId: executionTestResult.execution.order,
      executionTestResultId: executionTestResult.urlId,
    });
  const testResultDetailsLink = constructedLink.execution_test_result_detail_link;

  if (!MFlags.hideResultActionWhenParsingNotCompletedEnabled) {
    useInnerDialog = true;
  }
  const { status } = executionTestResult;
  // Only Passed status will be able to mark as Failed
  const statusInfo = statusMappings[status] || statusMappings.FAILED;

  const { markTitle, statusIcon, markStatusTrackId, markStatusTitle } = statusInfo;
  const [isOpenMarkAsRetestedDialog, setOpenMarkAsRetestedDialog] = useState(false);
  const [isOpenReportDefectDialog, setOpenReportDefectDialog] = useState(false);

  const handleCloseMarkAsRetestedDialog = () => {
    setOpenMarkAsRetestedDialog(false);
  };

  const handleCloseReportDefectDialog = () => {
    setOpenReportDefectDialog(false);
  };

  const renderMarkAsRetestedDialog = () => (
    <MarkAsRetestedDialog
      isOpen={isOpenMarkAsRetestedDialog}
      testRunId={executionTestResult.id}
      status={executionTestResult.status}
      handleClose={handleCloseMarkAsRetestedDialog}
    />
  );

  const renderReportDefectDialog = () => (
    <ReportDefectDialog
      isOpen={isOpenReportDefectDialog}
      executionTestResult={executionTestResult}
      handleClose={handleCloseReportDefectDialog}
    />
  );

  const handleMarkStatus = () => {
    if (useInnerDialog) {
      setOpenMarkAsRetestedDialog(true);
    } else {
      onMarkStatus();
    }
  };

  const handleReportDefect = () => {
    if (useInnerDialog) {
      setOpenReportDefectDialog(true);
    } else {
      onReportDefect();
    }
  };

  const handleExternalLink = () => {
    window.open(testResultDetailsLink, '_blank');
  }

  return (
    <Stack direction="row" spacing={1}>
      <Button
        id="report-defect-button"
        className={isRowItem ? classes.button : classes.buttonSecondary}
        color="secondary"
        variant="contained"
        title={t('report-defect')}
        onClick={handleReportDefect}
      >
        <Icon
          id="report-defect-button"
          type="fa-solid"
          name="fa-bug"
          textSize="14px"
          sx={{
            marginRight: '0 !important',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        />
        {!isRowItem && <span className="ml-2">{t('report-defect')}</span> }
      </Button>
      <Button
        id="mark-status-button"
        className={isRowItem ? classes.button : classes.buttonSecondary}
        color="secondary"
        variant="contained"
        onClick={handleMarkStatus}
        data-trackid={markStatusTrackId}
        title={markStatusTitle}
      >
        <Icon
          id="mark-status-button"
          type="fa-solid"
          name={statusIcon}
          textSize="14px"
          sx={{
            marginRight: '0 !important',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        />
        {!isRowItem && <span className="ml-2">{markTitle}</span> }
      </Button>
      {MFlags.testResultListEnhancementEnabled && isShownExternalLink && <Button
          id="external-link-button"
          className={isRowItem ? classes.button : classes.buttonSecondary}
          color="secondary"
          variant="contained"
          title={t('go-to-test-result-details')}
          onClick={handleExternalLink}
      >
        <Icon
            id="external-link-button"
            type="fa-solid"
            name="far fa-external-link"
            textSize="14px"
            sx={{
              marginRight: '0 !important',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
        />
      </Button>}
      {useInnerDialog && renderMarkAsRetestedDialog()}
      {useInnerDialog && isOpenReportDefectDialog && renderReportDefectDialog()}
    </Stack>
  );
}

export default ResultActionButtons;
