import React, { useState, useEffect } from 'react';
import { FormGroup, Label } from 'reactstrap';
import PropTypes from 'prop-types';
import { isEmpty, throttle } from 'lodash';
import Input from '../../Input';
import { t } from '../../../i18n/t';
import { ObjectType, SearchEntity } from '../../../utils/Constants';
import { buildSearchCondition } from '../../search/SearchUtils';
import Services from '../../../utils/Services';
import SelectG5Location from '../../treeview/SelectG5Location';
import AlertComponent from '../../AlertComponent';
import { IconErrorXMark, IconYellowWarning } from '../../../images/CustomNewIcon';
import SelectRepository from '../SelectRepository';

const G5TestCaseCreateView = (props) => {
  const { testProject, selectTestProject, testCasePath, setTestCasePath, testCaseName, setTestCaseName, setDisablePublish } = props;

  const [hasWritePermission, setHasWritePermission] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [isDuplicated, setDuplicated] = useState(false);

  const onChangeShowAlert = (isShowAlert) => {
    setShowAlert(isShowAlert);
    setDisablePublish(isShowAlert);
  };

  const validateTestCaseNameAndPath = () => {
    setDuplicated(false);
    if (testProject.id) {
      const params = {
        pagination: {
          page: 0,
          size: 1,
          sorts: ['id,desc']
        },
        conditions: [
          buildSearchCondition('name', '=', testCaseName),
          buildSearchCondition('path', '=', testCasePath),
          buildSearchCondition('TestProject.id', '=', testProject.id),
        ],
        type: SearchEntity.TestCase,
      };
      Services.search(params)
        .then(({ content }) => {
          setDuplicated(!isEmpty(content));
        })
        .catch(() => {});
    }
  };

  const throttledGetTestCaseByNameAndTestProjectId = throttle(validateTestCaseNameAndPath, 1000);

  useEffect(() => {
    throttledGetTestCaseByNameAndTestProjectId();
  }, [testCaseName, testCasePath, testProject]);

  const onChangeTestCaseName = (event) => {
    setTestCaseName(event.target.value);
  };

  const renderSelectRepository = () => (
    <SelectRepository
      testProject={testProject}
      setTestProject={selectTestProject}
      setIsLoading={setIsLoading}
      hasWritePermission={hasWritePermission}
      setHasWritePermission={setHasWritePermission}
      objectType={ObjectType.TEST_CASE}
    />
  );

  const renderSelectG5TestCaseLocation = () => (
    <>
      {!isLoading && hasWritePermission && (
      <FormGroup>
        <SelectG5Location
          key={testProject.id}
          testProjectId={testProject.id}
          path={testCasePath}
          onSelectFolder={setTestCasePath}
          showAlert={onChangeShowAlert}
        />
        {showAlert &&
        <AlertComponent
          text={t('invalid-folder-name')}
          icon={<IconErrorXMark />}
          className="error"
        />}
      </FormGroup>
      )}
    </>
  );

  const renderWarningTestCaseName = () => (
    <div>
      {t('publish-test-case#test-case-name-existing#title')}
      <li>{t('publish-test-case#test-case-name-existing#option1')}</li>
      <li>{t('publish-test-case#test-case-name-existing#option2')}</li>
    </div>
  );

  const renderInputTestCaseName = () => (
    <FormGroup>
      <Label for={t('publish-test-case#test-case-name')}>{t('publish-test-case#test-case-name')}</Label>
      <Input
        data-testid="test-case-name"
        required
        className="publish-dialog__text"
        type="text"
        value={testCaseName}
        onChange={onChangeTestCaseName}
      />
      {isDuplicated &&
        <AlertComponent
          text={renderWarningTestCaseName()}
          icon={<IconYellowWarning />}
        />}
    </FormGroup>
  );

  return (
    <>
      {renderSelectRepository()}
      {renderSelectG5TestCaseLocation()}
      {renderInputTestCaseName()}
    </>
  );
};

G5TestCaseCreateView.propTypes = {
  testProject: PropTypes.object.isRequired,
  selectTestProject: PropTypes.func.isRequired,
  testCasePath: PropTypes.string.isRequired,
  setTestCasePath: PropTypes.func.isRequired,
  testCaseName: PropTypes.string.isRequired,
  setTestCaseName: PropTypes.func.isRequired,
  setDisablePublish: PropTypes.func.isRequired,
};

export default G5TestCaseCreateView;
