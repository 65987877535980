import React from 'react';
import { Dialog, DialogBody, DialogFooter, DialogHeader } from '@katalon-studio/katalon-ui/Dialog';
import Button from '@katalon-studio/katalon-ui/Button';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { t } from '../../../i18n/t';

function LearnMoreDialog({ isOpen, setIsOpen }: { isOpen: boolean, setIsOpen: (isOpen: boolean) => void }) {
  const handleClose = () => {
    setIsOpen(false);
  };

  const renderBody = () => (
    <Box sx={{ width: '100%' }}>
      <Typography variant="subtitle1" gutterBottom>
        {t('gpt-service#dialog#1st-part')}
      </Typography>
      <Typography mt={4} variant="subtitle1" gutterBottom>
        {t('gpt-service#dialog#2nd-part')}
      </Typography>
      <Typography component="ul" className="pl-4">
        <Typography component="li">
          {t('gpt-service#dialog#step-1')}
        </Typography>
        <Typography component="li">
          {t('gpt-service#dialog#step-2#1')}
          <b>{t('gpt-service#dialog#step-2#2')}</b>
          {t('gpt-service#dialog#step-2#3')}
        </Typography>
      </Typography>
      <Typography mt={4} variant="subtitle1" gutterBottom>
        {t('gpt-service#dialog#3rd-part')}
      </Typography>
    </Box>
  );

  return (
    <Dialog
      open={isOpen}
      maxWidth="xs"
    >
      <DialogHeader onClick={handleClose}>
        {t('gpt-service#dialog#header')}
      </DialogHeader>
      <DialogBody>
        {renderBody()}
      </DialogBody>
      <DialogFooter>
        <Button
          size="medium"
          variant="text"
          color="primary"
          onClick={handleClose}
        >
          {t('close')}
        </Button>
      </DialogFooter>
    </Dialog>
  );
}

export default LearnMoreDialog;
