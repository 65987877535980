import React, { Component } from 'react';
import { Label } from 'reactstrap';
import PropTypes from 'prop-types';
import {
  IconStatusPassed,
  IconStatusFailed
} from '../../../images/CustomIcon';
import Select from '../../Select';
import { t } from '../../../i18n/t';
import { next } from '../../../utils/Count';
import { TestRunStatus } from '../../../utils/Constants';

const StatusOption = [
  { icon: <IconStatusPassed />, label: t('passed'), value: TestRunStatus.PASSED, id: 'passed' },
  { icon: <IconStatusFailed />, label: t('failed'), value: TestRunStatus.FAILED, id: 'failed' },
];

class StatusFilterV2 extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();

    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.buildFilter = this.buildFilter.bind(this);
  }

  getInitialState() {
    const { value } = this.props;
    const statusOption = StatusOption.filter((status) => status.id === value.toLowerCase());
    const selectedOption = statusOption.length > 0 ? statusOption[0] : null;
    return {
      selectedOption
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { value } = nextProps;
    const { selectedOption } = prevState;

    /*
     * When user click button "Clear" in component SearchQuery then value will be null.
     * I check the selectedOption if there is a value then set data to null to clear data.
    */
    if (!!selectedOption && !value) {
      return {
        selectedOption: null,
      };
    }
    return prevState;
  }

  handleSelectChange(option) {
    this.setState({
      selectedOption: option
    }, this.buildFilter(option.value));
  }

  buildFilter(value) {
    const { id, onBuildFilterSearch } = this.props;
    if (id && onBuildFilterSearch) {
      onBuildFilterSearch(id, value);
    }
  }

  render() {
    const { selectedOption } = this.state;

    return (
      <div>
        <Label>{t('status')}</Label>
        <Select
          key={next()}
          onChange={this.handleSelectChange}
          value={selectedOption}
          options={StatusOption}
          getOptionLabel={(option) => (
            <div className="d-flex align-items-center">
              {option.icon}
              <div className="ml-3">{option.label}</div>
            </div>
          )}
          placeholder={t('status-placeholder-select')}
        />
      </div>
    );
  }
}

StatusFilterV2.propTypes = {
  onBuildFilterSearch: PropTypes.func,
  /**
   * Current value of status in search bar.
   */
  value: PropTypes.string,
  /**
   * Id to build filter search.
   */
  id: PropTypes.string,
};

StatusFilterV2.defaultProps = {
  value: '',
  id: '',
};

export default StatusFilterV2;

