import React from 'react';
import { DialogContent, DialogContentText, DialogActions } from '@mui/material';
import { Button, ButtonToolbar } from 'reactstrap';
import PropTypes from 'prop-types';
import CloseableDialogComponent from './CloseableDialogComponent';
import { t } from '../../i18n/t';

function DeleteCustomFieldDefinitionDialog({ isOpen, handleClose, handleDelete, customFieldDefinition }) {
  return (
    <CloseableDialogComponent
      id="delete-custom-field-definition-dialog"
      isOpen={isOpen}
      handleClose={handleClose}
      title={t('custom-fields#delete-title')}
    >
      <DialogContent>
        <DialogContentText>
          <div className="title m-0 text-left">
            {t('custom-fields#delete-content#first-line')}<strong>{customFieldDefinition.displayName}</strong>?
            <p />
            {t('custom-fields#delete-content#second-line')}
          </div>
        </DialogContentText>
      </DialogContent>
      <DialogActions className="popup-footer justify-content-end p-0">
        <ButtonToolbar>
          <Button
            id="cancel-confirmation-delete"
            title={t('cancel')}
            color="secondary"
            onClick={handleClose}
          >
            {t('cancel')}
          </Button>
          <Button
            data-trackid="delete-custom-field"
            id="button-confirmation-delete"
            title={t('delete')}
            color="danger"
            onClick={() => handleDelete(customFieldDefinition)}
          >
            {t('delete')}
          </Button>
        </ButtonToolbar>
      </DialogActions>
    </CloseableDialogComponent>
  );
}

DeleteCustomFieldDefinitionDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  customFieldDefinition: PropTypes.object.isRequired,
};

DeleteCustomFieldDefinitionDialog.defaultProps = {
  isOpen: false,
  handleClose: null,
  handleDelete: null,
  customFieldDefinition: null,
};

export default DeleteCustomFieldDefinitionDialog;
