import React from 'react';
import userflow from 'userflow.js';
import DefaultLayout from '../../components/DefaultLayout';
import ObjectSummary from '../../components/summary/ObjectSummary';
import { t } from '../../i18n/t';
import BannerUpgradeRequest from '../../components/subscription/BannerUpgradeRequest';
import MContext from '../../models/MContext';
import TestRunCalendar from '../../components/TestRunCalendar';
import PageComponent from '../../components/PageComponent';
import MFlags from '../../models/MFlags';
import TestExecutionHeaderContainer from './components/TestExecutionHeaderContainer';
import ScheduleTestRunComponent from './components/ScheduleTestRunComponent';

class TestRunCalendarPage extends PageComponent {

  constructor(props) {
    super(props);
    this.state = {
      checkMoreThan3MonthsFlag: false,
    };
    this.projectId = MContext.project.id;
    this.project = MContext.project;
    this.meta.id = 'page-test-run-calendar';
    this.meta.title = t('Project {{name}} - {{page}}', { name: this.project.name, page: t('test-execution#test-run-calendar') });
    this.renderHeader = this.renderHeader.bind(this);
    this.renderBody = this.renderBody.bind(this);
    this.handleCheckMoveBack3Months = this.handleCheckMoveBack3Months.bind(this);
  }

  renderToolbar() {
    if (!MFlags.testcloudExecutionEnvMicroAppEnabled) {
      return null;
    }
    return (
      <TestExecutionHeaderContainer
        {...this.props}
        headerTitle={t('test-execution#test-run-calendar')}
      />
    );
  }

  handleCheckMoveBack3Months(isMoreThan3Months) {
    if (isMoreThan3Months) {
      this.setState({ checkMoreThan3MonthsFlag: isMoreThan3Months });
    }
  }

  componentDidMount() {
    if (MFlags.testCloudMobileEnvironmentReferralLinkEnabled) {
      const userFlowId = this.props?.location?.state?.userFlowId;
      if (userFlowId) {
        userflow.start(userFlowId).then((r) => r);
      }
    }
  }

  renderHeader() {
    const project = this.project;
    const urlParams = {
      testRunCalendar: t('test-execution#test-run-calendar'),
      projectId: project.id,
      project: project.name,
    };
    return (
      <>
        <ScheduleTestRunComponent {...this.props} />
        <ObjectSummary params={urlParams} />
      </>
    );
  }

  renderBody() {
    const { checkMoreThan3MonthsFlag } = this.state;
    const { isRequireUpgrade } = MContext;

    return (
      <>
        {this.renderToolbar()}
        {isRequireUpgrade && checkMoreThan3MonthsFlag && (
          <BannerUpgradeRequest />
        )}
        <TestRunCalendar
          checkIsMorethan3Months={this.handleCheckMoveBack3Months}
          {...this.props}
        />
      </>
    );
  }

  render() {
    const { projectId } = this;
    if (projectId) {
      return (
        <DefaultLayout
          renderHeader={!MFlags.testcloudExecutionEnvMicroAppEnabled ? this.renderHeader : null}
          renderBody={this.renderBody}
        />
      );
    }
    return null;
  }
}

export default TestRunCalendarPage;
