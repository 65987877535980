import React from 'react';
import Bar from 'recharts/lib/cartesian/Bar';
import CartesianGrid from 'recharts/lib/cartesian/CartesianGrid';
import Line from 'recharts/lib/cartesian/Line';
import XAxis from 'recharts/lib/cartesian/XAxis';
import YAxis from 'recharts/lib/cartesian/YAxis';
import ComposedChart from 'recharts/lib/chart/ComposedChart';
import Legend from 'recharts/lib/component/Legend';
import ResponsiveContainer from 'recharts/lib/component/ResponsiveContainer';
import Tooltip from 'recharts/lib/component/Tooltip';
import colors from '../../../../scss/colors.scss';
import StripedShape from '../components/StripedShape';
import { durationFormatter, percentFormatter, timeFormatter } from '../utils';
import { legendFormatter, tooltipFormatter, ViewModeType } from './utils';


const CustomStripedShape = (props) => (
  <StripedShape
    {...props}
    spaceWidth={5}
    stripeDistance={1}
    stripeWidth={4}
    stripeHeight={4}
    stripeProps={{
      rx: 2,
      ry: 2,
    }}
  />
);


const TestPerformanceChart = ({ data, viewMode }) => (
  <ResponsiveContainer minWidth={200} aspect={3} className="test-performance-chart">
    <ComposedChart
      margin={{
        bottom: 25,
        top: 20,
      }}
      data={data}
    >
      <Tooltip formatter={tooltipFormatter} labelFormatter={timeFormatter} />
      <Legend align="center" verticalAlign="top" formatter={legendFormatter} />
      <CartesianGrid />
      <XAxis
        tickFormatter={timeFormatter}
        dataKey="startTime"
        interval={0}
      />

      {viewMode === ViewModeType.RATIO && (
        <YAxis
          yAxisId="test-run-ratio"
          tickFormatter={percentFormatter}
        />
      )}
      {viewMode === ViewModeType.RATIO && (
        <Bar
          yAxisId="test-run-ratio"
          stackId="test-run-ratio"
          dataKey="passedTestResultRatio"
          barSize={15}
          fill={colors.passed}
        />
      )}
      {viewMode === ViewModeType.RATIO && (
        <Bar
          yAxisId="test-run-ratio"
          stackId="test-run-ratio"
          dataKey="failedTestResultRatio"
          barSize={15}
          fill={colors.failed}
        />
      )}
      {viewMode === ViewModeType.RATIO && (
        <Bar
          yAxisId="test-run-ratio"
          stackId="test-run-ratio"
          dataKey="errorTestResultRatio"
          barSize={15}
          fill={colors.error}
        />
      )}
      {viewMode === ViewModeType.RATIO && (
        <Bar
          yAxisId="test-run-ratio"
          stackId="test-run-ratio"
          dataKey="incompleteTestResultRatio"
          barSize={15}
          fill={colors.incomplete}
        />
      )}
      {viewMode === ViewModeType.RATIO && (
        <Bar
          yAxisId="test-run-ratio"
          stackId="test-run-ratio"
          dataKey="skippedTestResultRatio"
          barSize={15}
          fill={colors.skipped}
        />
      )}
      {viewMode === ViewModeType.RATIO && (
        <Bar
          yAxisId="test-run-ratio"
          stackId="test-case-ratio"
          dataKey="passedTestCaseRatio"
          barSize={15}
          fill={colors.secondPassed}
          shape={<CustomStripedShape />}
        />
      )}
      {viewMode === ViewModeType.RATIO && (
        <Bar
          yAxisId="test-run-ratio"
          stackId="test-case-ratio"
          dataKey="failedTestCaseRatio"
          barSize={15}
          fill={colors.secondFailed}
          shape={<CustomStripedShape />}
        />
      )}

      {viewMode === ViewModeType.TOTAL && (
        <YAxis
          yAxisId="test-run"
        />
      )}
      {viewMode === ViewModeType.TOTAL && (
        <Bar
          yAxisId="test-run"
          stackId="test-run"
          dataKey="passedTestResult"
          barSize={15}
          fill={colors.passed}
        />
      )}
      {viewMode === ViewModeType.TOTAL && (
        <Bar
          yAxisId="test-run"
          stackId="test-run"
          dataKey="failedTestResult"
          barSize={15}
          fill={colors.failed}
        />
      )}
      {viewMode === ViewModeType.TOTAL && (
        <Bar
          yAxisId="test-run"
          stackId="test-run"
          dataKey="errorTestResult"
          barSize={15}
          fill={colors.error}
        />
      )}
      {viewMode === ViewModeType.TOTAL && (
        <Bar
          yAxisId="test-run"
          stackId="test-run"
          dataKey="incompleteTestResult"
          barSize={15}
          fill={colors.incomplete}
        />
      )}
      {viewMode === ViewModeType.TOTAL && (
        <Bar
          yAxisId="test-run"
          stackId="test-run"
          dataKey="skippedTestResult"
          barSize={15}
          fill={colors.skipped}
        />
      )}
      {viewMode === ViewModeType.TOTAL && (
        <Bar
          yAxisId="test-run"
          stackId="test-case"
          dataKey="passedTestCase"
          barSize={15}
          fill={colors.secondPassed}
          shape={<CustomStripedShape />}
        />
      )}
      {viewMode === ViewModeType.TOTAL && (
        <Bar
          yAxisId="test-run"
          stackId="test-case"
          dataKey="failedTestCase"
          barSize={15}
          fill={colors.secondFailed}
          shape={<CustomStripedShape />}
        />
      )}

      <YAxis
        yAxisId="duration"
        tickFormatter={durationFormatter}
        orientation="right"
      />
      <Line
        yAxisId="duration"
        dataKey="totalDuration"
        stroke="#8884d8"
        strokeWidth={2}
        dot={{ r: 5 }}
        connectNulls
      />
    </ComposedChart>
  </ResponsiveContainer>
);

export default TestPerformanceChart;
