import React from 'react';

import queryString from 'query-string';
import { Jumbotron, Container } from 'reactstrap';
import PageComponent from '../components/PageComponent';
import RouteConstants from '../utils/RouteConstants';

class ErrorSSO extends PageComponent {

  constructor(props) {
    super(props);
    const params = queryString.parse(this.props.location.search);
    this.errorMessage = params['saml-error-message'];
    this.email = params?.account;
  }

  render() {
    return (
      <div>
        <Jumbotron fluid>
          <Container fluid>
            <h1 className="display-3">Single Sign-On Error</h1>
            <hr className="my-2" />
            <div className="lead">
              <p>Sorry, we cannot log you in because your account {this.email && `(${this.email})`} is not allowed to access Katalon TestOps via SSO.</p>
              <ul>
                <li>If your admin has enabled SSO settings for your account, please check your inbox and confirm.</li>
                <li>If you have not received any mail, please contact your admin to enable SSO for your account.</li>
              </ul>
              <div className="mt-5">
                {this.errorMessage && <p>Details: <span className="text-danger font-weight-bold">{this.errorMessage}</span></p>}
                <a href={RouteConstants.login}>Back to Login</a>
              </div>
            </div>
          </Container>
        </Jumbotron>
      </div>
    );
  }
}

export default ErrorSSO;