import React from 'react';
import { DialogContent, DialogContentText, DialogActions } from '@mui/material';
import { Button, ButtonToolbar } from 'reactstrap';
import PropTypes from 'prop-types';
import { t } from '../../../i18n/t';
import CloseableDialogComponent from '../../../components/dialog/CloseableDialogComponent';

function WarningJiraWebhookDialog({ isOpen, handleClose, handleRotate }) {

  return (
    <CloseableDialogComponent
      id="warning-jira-webhook-dialog"
      isOpen={isOpen}
      handleClose={handleClose}
      maxWidth="sm"
      title={t('jira-webhook#warning-dialog#title')}
    >
      <DialogContent>
        <DialogContentText className="text-dark">
          <p>{t('jira-webhook#warning-dialog#description')}</p>
        </DialogContentText>
      </DialogContent>
      <DialogActions className="popup-footer justify-content-end p-0">
        <ButtonToolbar>
          <Button
            id="close-warning-dialog-btn"
            title={t('close')}
            color="secondary"
            onClick={handleClose}
          >
            {t('close')}
          </Button>
          <Button
            id="continue-rotate-jira-webhook-btn"
            title={t('continue')}
            color="warning"
            onClick={handleRotate}
          >
            {t('continue')}
          </Button>
        </ButtonToolbar>
      </DialogActions>
    </CloseableDialogComponent>
  );
}

WarningJiraWebhookDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleRotate: PropTypes.func.isRequired,
};

export default WarningJiraWebhookDialog;
