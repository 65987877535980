import React from 'react';
import CommentComponent from '../../components/Comment/CommentComponent';
import TabComponent from '../../components/TabComponent';

class KEyesExecutionCommentsTab extends TabComponent {
  constructor(props) {
    super(props);

    this.meta.id = 'page-keyes-execution-detail-comment';
    this.meta.title = document.title;
  }

  render() {
    const { teamId, projectId, objectId, objectType } = this.props;
    return (
      <>
        <CommentComponent
          teamId={teamId}
          projectId={projectId}
          objectId={objectId}
          objectType={objectType}
        />
      </>
    );
  }
}

export default KEyesExecutionCommentsTab;
