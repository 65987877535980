import { ButtonGroup, ListItem, ListItemText } from '@mui/material';
import { Button } from 'reactstrap';
import React from 'react';
import copy from 'copy-to-clipboard';
import DropDownMenu from '../../components/navigationbar/DropDownMenu';
import { IconDropDown, IconDropDownLight } from '../../images/CustomIcon';
import Notification from '../../utils/Notification';
import { t } from '../../i18n/t';
import GroupEvent from '../../utils/track/GroupEvent';

function RunPlanDropdownButton({ name, onClick, apiSupport, dataTrackId, dataGroupId, ...props }) {

  const dropDownRef = React.useRef(null);

  const handleGetAPIRunPlan = () => {
    copy(apiSupport);
    Notification.pushSuccess('Request params has copied.');
  };

  const toggleDropdown = () => {
    dropDownRef.current.toggle();
  };

  const selectReleaseButton = (props) => (
    <Button className="drop-down" {...props}>
      <div className="icon-inactive">
        <IconDropDown />
      </div>
      <div className="icon-active">
        <IconDropDownLight />
      </div>
    </Button>
  );

  return (
    <>
      <ButtonGroup {...props} className="group-btn">
        <Button
          onClick={() => onClick()}
          data-trackid={dataTrackId}
          data-groupid={dataGroupId}
        >
          {name}
        </Button>
        <DropDownMenu
          renderAnchorElement={selectReleaseButton}
          expandDirection="right"
          ref={dropDownRef}
        >
          <ListItem
            button
            data-trackid="copy-run-plan-rest-api"
            data-groupid={GroupEvent.ACCESS_REPORT}
            onClick={() => {
              handleGetAPIRunPlan();
              toggleDropdown();
            }}
          >
            <ListItemText>
              {t('copyRequestParams')}
            </ListItemText>
          </ListItem>
        </DropDownMenu>
      </ButtonGroup>
    </>
  );
}

export default RunPlanDropdownButton;
