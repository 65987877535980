import React, { useCallback, useState } from 'react';
import { ResponsiveContainer, PieChart, Pie, Label, Cell, Legend, Sector } from 'recharts';
import styles from '../../../scss/colors.scss';

const DoughnutChartWithLabel = ({ data, legend, description, width, height }) => {

  const [activeIndex, setActiveIndex] = useState(0);
  const onPieEnter = useCallback(
    (_, index) => {
      setActiveIndex(index);
    },
    [setActiveIndex]
  );

  const chartWidth = width || '100%';

  const chartHeight = height || 450;

  const totalTestResults = data.map((item) => item.value).reduce((prev, current) => prev + current, 0);

  const percentages = data.map((item) => Math.round((item.value / totalTestResults) * 100));

  const totalPercent = percentages.reduce((prev, current) => prev + current, 0);

  if (totalPercent === 99) {
    percentages[percentages.length - 1] += 1;
  } else if (totalPercent === 101) {
    percentages[percentages.length - 1] -= 1;
  }

  const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      payload,
      percent,
      value
    } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';

    return (
      <g>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          fill={fill}
        />
        <path
          d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
          stroke={fill}
          fill="none"
        />
        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          textAnchor={textAnchor}
          fill={fill}
        >{`${payload.name}: ${value}`}
        </text>
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          dy={18}
          textAnchor={textAnchor}
          fill={styles.gray}
        >
          {`(${(percent * 100).toFixed(2)}%)`}
        </text>
      </g>
    );
  };

  return (
    <ResponsiveContainer width={chartWidth} height={chartHeight} className="doughnut_chart">
      <PieChart height={chartHeight} width={chartWidth}>
        <Pie
          activeIndex={activeIndex}
          activeShape={renderActiveShape}
          onMouseEnter={onPieEnter}
          data={data}
          cx="50%"
          cy="50%"
          dataKey="value"
          innerRadius="50%"
          outerRadius="65%"
          isAnimationActive={false}
          startAngle={90}
          endAngle={-270}
        >
          {data.map((item) => <Cell name={item.name} key={`cell-${item.value}`} fill={item.color} />)}
          { description.title &&
            <Label className="description description__title" position="center" value={description.title} />}
          { description.content &&
            <Label className="description description__content" position="center" value={description.content} />}
        </Pie>
        <Legend layout="horizontal" verticalAlign="bottom" align="right" payload={legend} />
      </PieChart>
    </ResponsiveContainer>
  );

};

export default DoughnutChartWithLabel;
