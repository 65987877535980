import { createTheme } from '@mui/material/styles';
import { theme } from '@katalon-studio/katalon-ui/Theme';

export const katalonui_theme = createTheme({
  ...theme,
  components: {
    ...theme.components,
    MuiDialogContent: {
      styleOverrides: {
        root: {
          // padding must set important here to override default DialogContent padding
          padding: `${theme.spacing(4)} ${theme.spacing(6)} ${theme.spacing(4)} ${theme.spacing(6)} !important`,
          color: theme.palette.text.primary,
          fontSize: '1rem',
          label: {
            fontWeight: '500 !important',
            marginBottom: '0px',
            paddingBottom: '7px'
          }
        }
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: ({ theme }) => ({
          padding: `${theme.spacing(4)} ${theme.spacing(6)} ${theme.spacing(0)} ${theme.spacing(6)}`,
          color: theme.palette.text.primary,
          fontSize: '24px',
          fontWeight: 500,
        }),
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: `${theme.spacing(0)} ${theme.spacing(6)} ${theme.spacing(4)} ${theme.spacing(6)}`,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          fontSize: '1rem !important',
        },
      }
    },
  }
});
