import React from 'react';
import { Alert, Card, CardBody, Col, Row, Spinner } from 'reactstrap';
import { t } from '../../i18n/t';
import { JobExecutionResult } from './JobExecutionResult';
import { RunningExecutionResultTab } from './RunningExecutionResultTab';
import ExecutionCustomFieldAndTag from '../../components/ExecutionCustomFieldAndTag';

const RunningExecutionWithCustomFieldAndTag = ({ execution }) => (
  <>
    <Alert color="primary">
      <span title={t('running-job')}><Spinner color="primary" size="sm" /></span>
        &nbsp;This test run is in progress.
    </Alert>
    <Row className="sidebar-layout">
      <Col md={9} className="sidebar-layout__main-content">
        <JobExecutionResult executionId={execution.id} title={t('jobs')} />
        <RunningExecutionResultTab executionId={execution.id} />
      </Col>
      <Col md={3} className="sidebar-layout__right-sidebar">
        <Card>
          <CardBody>
            <ExecutionCustomFieldAndTag execution={execution} />
          </CardBody>
        </Card>
      </Col>
    </Row>
  </>
);

export default RunningExecutionWithCustomFieldAndTag;
