import React from 'react';
import TextBlock from 'react-placeholder/lib/placeholders/TextBlock';

import { NOTIFICATION_PLACEHOLDER_COLOR } from './constants';

/**
 * Component to display a placeholder for notification when scrolling down to the bottom or at the first load.
 */
const NotificationItemPlaceholder = () => (
  <div
    className="notification-item"
  >
    <TextBlock
      ready={false}
      rows={1}
      color={NOTIFICATION_PLACEHOLDER_COLOR}
      showLoadingAnimation
      className="notification-item__title-placeholder"
    />
    <TextBlock
      ready={false}
      rows={1}
      color={NOTIFICATION_PLACEHOLDER_COLOR}
      showLoadingAnimation
      className="notification-item__message-placeholder"
    />
    <TextBlock
      ready={false}
      rows={2}
      color={NOTIFICATION_PLACEHOLDER_COLOR}
      showLoadingAnimation
    />
    <TextBlock
      ready={false}
      rows={1}
      color={NOTIFICATION_PLACEHOLDER_COLOR}
      showLoadingAnimation
      className="notification-item__time-placeholder"
    />
  </div>
);

export default NotificationItemPlaceholder;
