import React, { useState } from 'react';
import jquery from 'jquery';
import { t } from '../i18n/t';
import { ID_MAIN_HEADER_TOOLBAR } from '../utils/Constants';
import Services from '../utils/Services';
import GroupEvent from '../utils/track/GroupEvent';
import EditableField from './EditableField';
import DecoratorConstants from '../utils/DecoratorConstants';

function EditableBaselineCollectionGroupTitle({ baselineCollectionGroup, className }) {

  const [title, setTitle] = useState(DecoratorConstants.baselineCollectionGroupTitleDecorator(baselineCollectionGroup));
  const [previousName, setPreviousName] = useState(baselineCollectionGroup.name);
  const { unsaved } = baselineCollectionGroup.latestVersion;

  const handleSubmitEdit = (submittedName) => {
    if (previousName !== submittedName) {
      Services.updateBaselineCollectionGroup(baselineCollectionGroup.id, { name: submittedName })
        .then((respondedBaselineCollectionGroup) => {
          setPreviousName(respondedBaselineCollectionGroup.name);
          setTitle(DecoratorConstants.baselineCollectionGroupTitleDecorator(respondedBaselineCollectionGroup));
        });
    }
  };

  const hideToolbar = (open) => {
    const toolbar = jquery(`#${ID_MAIN_HEADER_TOOLBAR}`);
    if (toolbar && open) {
      toolbar.hide();
    } else if (toolbar) {
      toolbar.show();
    }
  };

  const trackingProps = {
    openTrackId: 'edit-baseline-collection-name',
    submitTrackId: 'save-baseline-collection-name',
    cancelTrackId: 'cancel-edit-baseline-collection-name',
    dataGroupId: GroupEvent.ACCESS_REPORT,
  };

  return (
    <EditableField
      text={baselineCollectionGroup.name}
      className={className}
      onChangeEditable={hideToolbar}
      onSubmitEdit={handleSubmitEdit}
      trackingProps={trackingProps}
      placeHolder={t('baseline-collection-group#editable-place-holder')}
      editButtonTooltip={t('baseline-collection-group#button-tooltip')}
    >
      <div className="d-inline-flex mw-100 align-items-center">
        <div className="title-text">{title}</div>
        {DecoratorConstants.unsavedBadgeDecorator(unsaved)}
      </div>
    </EditableField>
  );
}

export default EditableBaselineCollectionGroupTitle;
