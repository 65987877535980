import React, { Component } from 'react';

class StatusBarInfo extends Component {
  constructor(props) {
    super(props);

    this.data = this.props.data;
  }

  render() {
    const { valueFormatter, isValueSmall } = this.props;
    const small = isValueSmall ? '-small' : '';
    return (
      <>
        {this.data.map(({ key, value, label, diff }) => {
          const diffPart = diff !== undefined
            ? <> ({diff < 0 || '+'}{diff})</>
            : null;
          return (
            <div className="status-bar-v2__item" key={key}>
              <div className={`counter__value${small}`}>{valueFormatter ? valueFormatter(value) : value}</div>
              <div className="counter__label">{label}{diffPart}</div>
            </div>
          );
        })}
      </>
    );
  }
}

export default StatusBarInfo;
