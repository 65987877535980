import React from 'react';
import TestExecutionHeader from './TestExecutionHeader';

function TestExecutionHeaderContainer(props) {
  return (
    <TestExecutionHeader {...props} />
  );
}

export default TestExecutionHeaderContainer;
