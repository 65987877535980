import React from 'react';
import { TextField as MuiTextFiled } from '@mui/material';
import { styled } from '@mui/material/styles';
import colors from '../../scss/colors.scss';

const CssTextField = styled(MuiTextFiled)({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: colors.inputBorderColorHover,
    },
    '&:hover fieldset': {
      borderColor: colors.inputBorderColorHover,
    },
    '&.Mui-focused fieldset': {
      borderColor: colors.muiFocusBorderColor,
      outline: 0,
      boxShadow: '0 0 8px rgb(102 175 233 / 60%)',
      color: colors.inputTextColor,
    },
  },
});

export default function TextField(props) {
  const {
    className,
  } = props;
  const newProps = {
    ...props,
    ...{
      className: `test-ops-text-field ${className || ''}`,
    },
  };
  return <CssTextField InputProps={{ disableUnderline: true }} {...newProps} />;
}
