import React from 'react';
import Routes from '../utils/Routes';
import { t } from '../i18n/t';
import MContext from '../models/MContext';
import Link from './Link';

class TestReportsComponents extends React.Component {
  constructor(props) {
    super(props);
    this.projectId = MContext.projectId;
    this.teamId = MContext.teamId;
    this.project = MContext.project;
  }

  render() {
    const { report, trackingProps } = this.props;

    const routes = new Routes({
      teamId: this.teamId,
      projectId: this.project.id,
    });

    return (
      <Link
        className="link-card"
        href={routes[`test_report_${report.id.replaceAll("-", "_")}`]}
      >
        <div className="simple-card" {...trackingProps}>
          <div className="header" {...trackingProps}>{t(report.id)}</div>
          <div className="description" {...trackingProps}>{t(`${report.id}-description`)}</div>
        </div>
      </Link>
    );
  }
}

export default TestReportsComponents;
