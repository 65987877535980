import { FormGroup, Label } from 'reactstrap';
import React from 'react';
import { Controller } from 'react-hook-form';
import { t } from '../../../i18n/t';
import Input from '../../Input';
import ExecutionEnvironmentSelect from './ExecutionEnvironmentSelect';
import { ConfigType } from '../../../utils/Constants';
import MFlags from '../../../models/MFlags';

function GenericCommand(props) {
  const { testProject, executionEnvironments, validation, form, circleCIAgents, k8sAgents, localAgents } = props;
  const { control } = form;

  return (
    <>
      <FormGroup className="d-flex">
        <Label className="col-2 test-run-label" for="genericCommand">{t('run-configuration#generic-command')}</Label>
        <Controller
          name="runConfiguration.genericCommand"
          control={control}
          rules={{ required: validation }}
          render={({ field, formState: { errors } }) =>
            <div className="d-flex flex-column flex-grow-1">
              <Input
                {...field}
                required
                id="genericCommand"
                name="genericCommand"
                type="textarea"
                rows={5}
              />
              {errors.runConfiguration?.genericCommand && <small className="text-danger fs-6">This is required</small>}
            </div>}
        />
      </FormGroup>
      <FormGroup className="d-flex">
        <Label className="col-2 test-run-label" for="environment">{t('Environment')}</Label>
        <div className="p-0 col-10">
          <Controller
            name="runConfiguration.genericCommandAgents"
            control={control}
            rules={{ required: validation }}
            render={({ field, formState: { errors } }) =>
              <div className="d-flex flex-column flex-grow-1">
                <ExecutionEnvironmentSelect
                  {...field}
                  testProject={testProject}
                  executionEnvironments={executionEnvironments}
                  configType={ConfigType.GENERIC_COMMAND}
                  circleCIAgents={circleCIAgents}
                  k8sAgents={k8sAgents}
                  localAgents={localAgents}
                  title={t('testcloud-environment#generic-command')}
                  isShowDropdown={!MFlags.testCloudDisableTCFromScheduleDialog}
                />
                {errors.runConfiguration?.genericCommandAgents && <small className="text-danger fs-6">This is required</small>}
              </div>}
          />
        </div>
      </FormGroup>
    </>
  );
}

export default GenericCommand;
