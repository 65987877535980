import filesize from 'filesize';
import React from 'react';
import { Button, Card, CardBody, Col, Form, FormGroup, Label, ListGroup, ListGroupItem, Row } from 'reactstrap';
import { IconButton, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText } from '@mui/material';
import DefaultLayout from '../../components/DefaultLayout';
import Input from '../../components/Input';
import PageButtonToolbar from '../../components/PageButtonToolbar';
import PageComponent from '../../components/PageComponent';
import { buildSearchCondition } from '../../components/search/SearchUtils';
import ObjectSummary from '../../components/summary/ObjectSummary';
import DataLoader from '../../components/table/DataLoader';
import { t } from '../../i18n/t';
import { IconDownload, IconGitRepo, IconGrid, IconKSRepo, IconTestSuiteCollectionTable } from '../../images/CustomIcon';
import MContext from '../../models/MContext';
import Apis from '../../utils/Apis';
import http from '../../utils/http';
import Routes from '../../utils/Routes';
import DeleteTestProjectDialog from '../../components/dialog/DeleteTestProjectDialog';
import Services from '../../utils/Services';
import { TestProjectType } from '../../utils/Constants';
import Notification from '../../utils/Notification';
import GroupEvent from '../../utils/track/GroupEvent';
import MFlags from '../../models/MFlags';

class TestProjectDetails extends PageComponent {
  constructor(props) {
    super(props);
    this.meta.id = 'page-test-project-details';
    this.teamId = MContext.teamId;
    this.projectId = MContext.projectId;
    this.team = MContext.team;
    this.project = MContext.project;
    this.testProjectId = MContext.testProjectId;
    this.state = {
      testProject: null,
      testSuiteCollections: [],
    };

    this.renderPreviousFiles = this.renderPreviousFiles.bind(this);
    this.renderHeader = this.renderHeader.bind(this);
    this.renderBody = this.renderBody.bind(this);
    this.getTestProject = this.getTestProject.bind(this);
    this.renderTestProjectDetails = this.renderTestProjectDetails.bind(this);
    this.renderToolbar = this.renderToolbar.bind(this);
    this.refreshTSC = this.refreshTSC.bind(this);

    this.deleteDialogRef = React.createRef();
  }

  componentDidMount() {
    if (this.testProjectId) {
      this.getTestProject();
    }
  }

  getTestProject() {
    http.get(Apis.testProject(this.testProjectId))
      .then((responseJson) => {
        const testProject = responseJson;
        this.meta.title = t('Script Repository {{name}}', { name: testProject.name });
        this.setState({
          testProject,
        });
        this.getTSCs();
      });
  }

  getTSCs() {
    const { testProject } = this.state;
    if (!testProject.id) {
      return;
    }
    const params = {
      pagination: {
        page: 0,
        size: 1000000,
        sorts: ['name,asc'],
      },
      conditions: [
        {
          key: 'TestProject.id',
          operator: '=',
          value: testProject.id,
        },
      ],
      type: 'TestSuiteCollection',
    };
    Services.search(params)
      .then(({ content: testSuiteCollections }) => {
        if (testSuiteCollections) {
          this.setState({ testSuiteCollections });
        }
      });
  }

  refreshTSC() {
    const { testProject } = this.state;
    if (testProject?.id) {
      Services.refreshTSC(testProject.id)
        .then(() => {
          this.getTSCs();
          Notification.pushSuccess(
            'The script repository is being refreshed. It might take a while to show the latest data.',
            'Done!'
          );
        });
    }
  }

  renderObjectSummary() {
    const { testProject } = this.state;
    const icon = testProject.type === TestProjectType.KS ? <IconKSRepo /> : <IconGitRepo />;
    const urlParams = {
      projectId: this.projectId,
      project: this.project,
      testProjectId: this.testProjectId,
      testProjectName: testProject.name,
      icon,
    };
    return (
      <ObjectSummary params={urlParams} />
    );
  }

  renderHeader() {
    return (
      <>
        {this.renderObjectSummary()}
      </>
    );
  }

  renderGitTestProject() {
    const { gitRepository } = this.state.testProject;
    return (
      <>
        <FormGroup>
          <Label for="repository">{t('test-project#git#repourl')}</Label>
          <Input plaintext="true" id="repository">
            {gitRepository.repository}
          </Input>
        </FormGroup>
        <FormGroup>
          <Label for="branch">{t('test-project#git#branch')}</Label>
          <Input plaintext="true" id="branch">
            {gitRepository.branch}
          </Input>
        </FormGroup>
        {MFlags.supportTargetDirectoryOnGitRepositoryEnabled &&
          <FormGroup>
            <Label for="targetDirectory">{t('test-project#git#target-directory')}</Label>
            <Input plaintext="true" id="targetDirectory">
              {gitRepository.targetDirectory}
            </Input>
          </FormGroup>
        }
        <FormGroup>
          <Label for="username">{t('username')}</Label>
          <Input plaintext="true" id="username">
            {gitRepository.username}
          </Input>
        </FormGroup>
      </>
    );
  }

  renderTestProjectDetails() {
    const { testProject } = this.state;
    return (
      <Card>
        <CardBody>
          <Form>
            <FormGroup>
              <Label for="description">{t('description')}</Label>
              <Input plaintext="true" id="description">
                {testProject.description}
              </Input>
            </FormGroup>
            {testProject.uploadFileName &&
              <FormGroup>
                <Label for="download">{t('test-project#download')}</Label>
                <a
                  data-trackid="download-test-project-package"
                  href={Apis.downloadTestProject(this.testProjectId)}
                  className="form-control-custom"
                >
                  {testProject.uploadFileName}
                </a>
              </FormGroup>}
            {testProject.type === TestProjectType.KS
              && this.renderPreviousFiles()}
            {testProject.type === TestProjectType.GIT
              && this.renderGitTestProject()}
            {testProject.type !== TestProjectType.CLOUD && this.renderTestSuiteCollection()}
          </Form>
        </CardBody>
      </Card>
    );
  }

  renderToolbar() {
    const { testProject } = this.state;
    const { type } = testProject;
    const routes = new Routes({ testProjectId: this.testProjectId });
    const { preventScheduleTestRunEnabled } = MFlags;

    let showRefreshTSCButton = false;
    let test_project_edit_link = routes.test_project_edit_link;
    if (type === TestProjectType.GIT) {
      test_project_edit_link = routes.test_project_edit_git_link;
      showRefreshTSCButton = true;
    }

    return (
      <PageButtonToolbar>
        {type !== TestProjectType.CLOUD &&
          <>
            <Button
              color="secondary"
              onClick={() => this.props.history.push(test_project_edit_link)}
              title={t('edit')}
              data-trackid="to_imported_script_repository_edited"
            >
              {t('edit')}
            </Button>
            {showRefreshTSCButton && (
              <Button
                color="secondary"
                title={t('refresh-script-repository')}
                onClick={() => this.refreshTSC()}
              >
                {t('refresh-script-repository')}
              </Button>
            )}
            <Button
              color="danger"
              title={t('delete')}
              data-trackid="to_imported_script_repository_deleted"
              onClick={() => {
                this.deleteDialogRef.current.toggle();
              }}
            >
              {t('delete')}
            </Button>
          </>}
        <Button
          data-trackid="open-schedule-dialog-script-repository"
          data-groupid={GroupEvent.SCHEDULE_TEST_RUN}
          color="primary"
          onClick={() => this.props.history.push(routes.smart_test_scheduling_link({ testProjectId: this.testProjectId, }))}
          title={t('create-plan')}
          disabled={preventScheduleTestRunEnabled}
        >
          {t('create-plan')}
        </Button>
      </PageButtonToolbar>
    );
  }

  renderTestSuiteCollection() {
    const { testSuiteCollections } = this.state;
    const routes = new Routes();
    const { preventScheduleTestRunEnabled } = MFlags;

    const content = testSuiteCollections.map((item) => (
      <ListItem key={`test-suite-collection-${item.id}`}>
        <ListItemIcon>
          <IconTestSuiteCollectionTable />
        </ListItemIcon>
        <ListItemText primary={item.name} />
        <ListItemSecondaryAction
          data-trackid="open-schedule-dialog-tsc"
          data-groupid={GroupEvent.SCHEDULE_TEST_RUN}
        >
          <IconButton
            edge="end"
            href={routes.smart_test_scheduling_link({
              testProjectId: this.testProjectId,
              testSuiteCollectionId: item.id,
            })}
            size="large"
            disabled={preventScheduleTestRunEnabled}
          >
            <IconGrid className="small-icon" />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    ));
    return (
      <Row>
        <Col sm="12" md="8" lg="6" xl="5">
          <FormGroup>
            <Label for="test-suite-collections">{t('test-suite-collection')}</Label>
            {content.length > 0 && <List id="test-suite-collections">{content}</List>}
            {content.length === 0 && <div>{t('no-tsc')}</div>}
          </FormGroup>
        </Col>
      </Row>
    );
  }

  renderPreviousFilesData(data) {
    const content = data.map((item) => (
      <ListGroupItem key={`previous-test-project-package-${item.name}`}>
        <a
          data-trackid="download-previous-test-project-package"
          href={item.self}
        >
          <IconDownload />{' '}{item.name}
        </a>
        <span className="ml-2">{`(${filesize(item.size)})`}</span>
      </ListGroupItem>
    ));
    return content.length > 0 ? (
      <FormGroup>
        <Label for="files">Previous packages</Label>
        <ListGroup flush id="files">{content}</ListGroup>
      </FormGroup>
    ) : null;
  }

  renderPreviousFiles() {
    return (
      <DataLoader
        entityType="TestProjectFile"
        render={this.renderPreviousFilesData}
        defaultSearchConditions={[
          buildSearchCondition('TestProject.id', '=', this.testProjectId),
        ]}
        disableFilterButton
        noCard
      />
    );
  }

  renderDeleteDialogs() {
    const { testProject } = this.state;
    return (
      <DeleteTestProjectDialog
        ref={this.deleteDialogRef}
        id={this.testProjectId}
        name={testProject.name}
        projectId={this.testProjectId}
        afterCreate={() => {
          const route = new Routes();
          this.props.history.push(route.test_projects_link);
        }}
      />
    );
  }

  renderBody() {
    return (
      <>
        {this.renderToolbar()}
        {this.renderTestProjectDetails()}
        {this.renderDeleteDialogs()}
      </>
    );
  }

  render() {
    const { testProject } = this.state;
    if (testProject) {
      return (
        <DefaultLayout
          renderHeader={this.renderHeader}
          renderBody={this.renderBody}
        />
      );
    } else return null;
  }
}

export default TestProjectDetails;
