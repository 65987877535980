import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { omit, values } from 'lodash';
import CircularProgress from '@mui/material/CircularProgress';
import Services from '../../utils/Services';
import DropdownField from './DropdownField';
import JiraComponentMapper from './JiraComponentMapper';
import { IconListDropdown } from '../../images/CustomNewIcon';
import { ExecutionTestResult } from '../../models/model/ExecutionTestResult';
import { t } from '../../i18n/t';
import { JiraIssueType } from '../../models/model/JiraIssueType';
import { JiraIssueField } from '../../models/model/JiraIssueField';

interface CreateIssuesContentProps {
  projectId: number;
  setInitJsonObject: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  updateJsonObject: (key: string, value: any) => void;
  shouldValidate?: boolean;
  executionTestResult: ExecutionTestResult;
  serverUrl: string;
}

function CreateIssuesContent(props: CreateIssuesContentProps) {
  const {
    projectId,
    updateJsonObject,
    executionTestResult,
    setInitJsonObject,
    shouldValidate,
    serverUrl
  } = props;
  const [isEmpty, setEmpty] = useState(true);
  const [jiraTypes, setJiraTypes] = useState<JiraIssueType[]>([]);
  const [jiraData, setJiraData] = useState<JiraIssueField[]>([]);
  const [isLoading, setLoading] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChangeDropdown = (id: string, value: any) => {
    setLoading(true);
    Services.getJiraIssueTypeMetadataByName(projectId, value.name)
      .then((response) => {
        if (response && response.length > 0) {
          const jiraIssueField = omit(response[0].fields, ['issuetype', 'project']);
          // Convert object to array
          setJiraData(values(jiraIssueField));
          // Clear the JSON object by setting it to an empty object
          setInitJsonObject();
          // Set the JSON object with the chosen issue type
          updateJsonObject(id, value);
          setEmpty(false);
        }
      })
      .catch(() => {
        // ignore
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    Services.getAllJiraIssueTypesMetadata(projectId)
      .then((response) => {
        setJiraTypes(response);
      })
      .catch(() => {
        // ignore
      });
  }, [projectId]);

  const renderChooseType = () => (
    <DropdownField
      required={shouldValidate}
      id="issuetype"
      displayField="name"
      iconField="iconUrl"
      changeValueContributor={(id, value) => handleChangeDropdown(id, value)}
      allowedValues={jiraTypes}
    />
  );

  const renderEmptyAndLoading = () => (
    <div className={`d-flex justify-content-center${isLoading ? ' loading-type-field' : ''}`}>
      <Box
        display="flex"
        alignItems="center"
        flexDirection="column"
        textAlign="center"
        justifyContent="center"
        sx={{
          width: 300,
          height: 200,
        }}
      >
        {isLoading ? (
          <>
            <CircularProgress size="2rem" thickness={5} />
            <div>{t('table#loading-message')}</div>
          </>
        ) : (
          <>
            <div className="mb-2">
              <IconListDropdown />
            </div>
            <div className="custom-fields__empty-message">{t('select-issue-display-fields')}</div>
          </>
        )}
      </Box>
    </div>
  );

  const renderJiraFields = () => (
    <JiraComponentMapper
      required={shouldValidate}
      jiraComponents={jiraData}
      updateJsonObject={updateJsonObject}
      executionTestResult={executionTestResult}
      serverUrl={serverUrl}
    />
  );

  return (
    <div>
      {renderChooseType()}
      {isEmpty || isLoading ? (
        renderEmptyAndLoading()
      ) : (
        renderJiraFields()
      )}
    </div>
  );
}

export default CreateIssuesContent;
