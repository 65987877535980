import React from 'react';
import { Button, Card, CardBody, CardHeader, CustomInput, Form, FormGroup, FormText } from 'reactstrap';
import { t } from '../../i18n/t';
import Notification from '../../utils/Notification';
import Services from '../../utils/Services';
import DocumentLink from '../../utils/DocumentLink';
import MAuth from '../../models/MAuth';
import MContext from '../../models/MContext';

const ProjectSettingCard = ({ project }) => {
  const [projectSetting, setProjectSetting] = React.useState({});

  const handleCheckboxChange = (event) => {
    const name = event.target.name;
    setProjectSetting({
      ...projectSetting,
      [name]: !projectSetting[name],
    });
  };

  const saveSetting = (event) => {
    event.preventDefault();

    Services.updateProjectSetting(projectSetting)
      .then((projectSetting) => {
        setProjectSetting(projectSetting);
        Notification.pushSuccess('Project setting was updated successfully.');
      });
  };

  React.useEffect(() => {
    Services.getProjectSetting(project.id)
      .then((projectSetting) => {
        if (!projectSetting.projectId) {
          projectSetting.projectId = project.id;
        }
        setProjectSetting(projectSetting);
      });
  }, []);

  const teamId = MContext.teamId;
  const isTeamManager = MAuth.isTeamManager(teamId);

  return (
    <Card>
      <CardHeader>
        Configurations
      </CardHeader>
      <CardBody>
        <Form data-trackid="update-project-settings" onSubmit={saveSetting}>
          <FormGroup>
            <CustomInput
              id="bdd"
              name="bdd"
              onChange={handleCheckboxChange}
              type="checkbox"
              checked={projectSetting?.bdd}
              label={t('project-settings-bdd-label')}
              className="normal-label"
              disabled={!isTeamManager}
            />
            <FormText color="muted">
              {t('project-settings-bdd-description')}
              <a href={DocumentLink.BDD_SETTING} target="_blank" rel="noopener noreferrer">Learn more</a>
            </FormText>
          </FormGroup>
          <FormGroup>
            <Button type="submit" color="primary" disabled={!isTeamManager}>{t('update')}</Button>
          </FormGroup>
        </Form>
      </CardBody>
    </Card>
  );
};

export default ProjectSettingCard;
