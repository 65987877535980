import React from 'react';
import PageComponent from '../../components/PageComponent';
import ObjectSummary from '../../components/summary/ObjectSummary';
import MContext from '../../models/MContext';
import DefaultLayout from '../../components/DefaultLayout';
import OrganizationHomeComponent from './components/OrganizationHomeComponent';
import TrialRequestSuccessDialog from '../../components/dialog/TrialRequestSuccessDialog';
import UrlHelper from '../../utils/UrlHelper';
import Helper from '../../utils/Helper';
import MFlags from '../../models/MFlags';
import OrganizationHomeEmptyState from './OrganizationHomeEmptyState';

class OrganizationHome extends PageComponent {

  constructor(props) {
    super(props);
    this.meta.id = 'page-organization-home';
    this.organizationId = MContext.organizationId;
    this.meta.title = 'Home';

    this.state = {
      organization: null,
    };
    this.renderHeader = this.renderHeader.bind(this);
    this.renderBody = this.renderBody.bind(this);
  }

  componentDidMount() {
    const organization = MContext.organization;
    this.setState({ organization });
    this.meta.title = organization.name;
  }

  renderObjectSummary() {
    const { organization } = this.state;
    const urlParams = {
      organizationHome: organization ? `Welcome to ${organization.name}` : 'Organization',
    };
    return (
      <ObjectSummary params={urlParams} />
    );
  }

  renderHeader() {
    return (
      <>
        {this.renderObjectSummary()}
      </>
    );
  }

  renderTrialRequestSuccessPopup() {
    const testOpsFeature = UrlHelper.getSearchParam('testOpsFeature');
    if (testOpsFeature) {
      return <TrialRequestSuccessDialog testOpsFeature={testOpsFeature} />;
    }
  }

  renderBody() {
    const { organization } = this.state;
    return (
      <>
        {!Helper.isEmptyOrganization(this.organizationId) && <OrganizationHomeComponent organization={organization} />}
        {Helper.isEmptyOrganization(this.organizationId) && <OrganizationHomeEmptyState />}
        {this.renderTrialRequestSuccessPopup()}
      </>
    );
  }

  render() {
    const { organization } = this.state;
    if (organization) {
      return (
        <DefaultLayout
          renderHeader={this.renderHeader}
          renderBody={this.renderBody}
        />
      );
    }
    return null;
  }
}

export default OrganizationHome;
