import React, { useEffect, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { Button, Grid, Typography } from '@mui/material';
import { InputGroup } from 'reactstrap';
import { cloneDeep, identity, orderBy, remove, throttle, trim } from 'lodash';
import PropTypes from 'prop-types';
import Services from '../utils/Services';
import { t } from '../i18n/t';
import {
  DEFAULT_TRUNCATE_MAX_LENGTH,
  MAX_PAGE_SIZE,
  SearchEntity,
} from '../utils/Constants';
import { buildSearchCondition } from './search/SearchUtils';
import Arrays from '../utils/Arrays';
import TextField from './TextField';
import { IconXmark, IconConfirm } from '../images/CustomIcon';
import DecoratorConstants from '../utils/DecoratorConstants';

export default function CustomFieldAddNew(props) {

  const [definitions, setDefinitions] = useState([]);

  const [filteredDefinitions, setFilteredDefinitions] = useState([]);

  const [options, setOptions] = useState([]);

  const [searchDefinitionValue, setSearchDefinitionValue] = useState('');

  const [searchOptionValue, setSearchOptionValue] = useState('');

  const [selectedDefinition, setSelectedDefinition] = useState(null);

  const [selectedOption, setSelectedOption] = useState(null);

  const {
    handleAddCustomField,
    toggleOpenAddNewComponent,
    selectedCustomFields,
  } = props;

  const filterDefinitions = (listDefinitions, selectedCustomFields) => {
    const newListDefinitions = cloneDeep(listDefinitions);
    selectedCustomFields.map((selectedCustomField) => (
      remove(newListDefinitions, { key: selectedCustomField.customFieldDefinition.key })
    ));
    setDefinitions(listDefinitions);
    setFilteredDefinitions(newListDefinitions);
  };

  const getCustomFieldDefinitions = (definitionName) => {
    definitionName = trim(definitionName);
    const params = {
      pagination: {
        page: 0,
        size: MAX_PAGE_SIZE,
        sorts: ['displayName,asc'],
      },
      conditions: [
        ...Arrays.insertIf(definitionName, buildSearchCondition('displayName', 'contains', definitionName || '')),
      ],
      type: SearchEntity.CustomFieldDefinition,
    };

    Services.searchRecursively(0, params, [])
      .then((content) => {
        const listDefinitions = content.map((definition) => ({
          ...definition,
        }));
        filterDefinitions(listDefinitions, selectedCustomFields);
      });
  };

  const throttledGetCustomFieldDefinitions = throttle(getCustomFieldDefinitions, 1000);

  const getCustomFieldOptions = (optionName) => {
    optionName = trim(optionName);
    if (selectedDefinition?.customFieldOptions) {
      const options = selectedDefinition.customFieldOptions;
      let listOptions = options.filter((option) => option.value.toLowerCase().includes(optionName.toLowerCase()));
      listOptions = orderBy(listOptions, ['value'], ['asc']);
      setOptions(listOptions);
    }
  };

  const throttledGetCustomFieldOptions = throttle(getCustomFieldOptions, 1000);

  useEffect(() => {
    getCustomFieldDefinitions();
  }, []);

  useEffect(() => {
    if (selectedDefinition) {
      getCustomFieldOptions('');
      setSearchOptionValue('');
      setSelectedOption(null);
    }
  }, [selectedDefinition]);

  useEffect(() => {
    filterDefinitions(definitions, selectedCustomFields);
  }, [selectedCustomFields]);

  const saveSelectedValue = () => {
    handleAddCustomField(selectedDefinition, selectedOption);
    toggleOpenAddNewComponent();
  };

  const inputDefinition = (event, newValue) => {
    if (event) {
      event.preventDefault();
      setSearchDefinitionValue(newValue);
      setSelectedDefinition(null);
      setSearchOptionValue('');
      throttledGetCustomFieldDefinitions(newValue);
    }
  };

  const inputOption = (event, newValue) => {
    if (event) {
      event.preventDefault();
      setSearchOptionValue(newValue);
      setSelectedOption(null);
      throttledGetCustomFieldOptions(newValue);
    }
  };

  const handleChangeDefinition = (event, value) => {
    setSelectedDefinition(value);
  };

  const handleChangeOption = (event, value) => {
    setSelectedOption(value);
  };

  const { maxCharacterTruncate } = props;

  return (
    <>
      <InputGroup className="mb-3 custom-field-filter__input-group">
        <Autocomplete
          disableClearable
          clearOnEscape
          className="flex-grow-1 custom-field-filter__autocomplete"
          options={filteredDefinitions}
          onChange={handleChangeDefinition}
          getOptionLabel={(option) => option.displayName || ''}
          filterOptions={identity}
          componentsProps={{
            paper: {
              sx: {
                width: props.keyPaperWidth
              }
            }
          }}
          renderInput={(params) => <TextField placeholder={t('custom-fields#display-name')} {...params} />}
          renderOption={(props, option) => (
            <li {...props} key={option.key}>
              <Grid container>
                <Grid item xs={12}>
                  <div className="d-flex align-items-center custom-field-filter__input-group__select">
                    <Typography variant="button" color="textPrimary" title={option.displayName}>
                      {DecoratorConstants.truncateLeftStringDecorator(option.displayName, maxCharacterTruncate)}
                    </Typography>
                    <Typography variant="button" title={option.key} color="textPrimary" className="custom-field-filter__input-group__select__key">
                      {DecoratorConstants.truncateLeftStringDecorator(option.key, maxCharacterTruncate)}
                    </Typography>
                  </div>
                </Grid>
              </Grid>
            </li>
          )}
          inputValue={searchDefinitionValue}
          value={selectedDefinition}
          onInputChange={inputDefinition}
        />
        <Autocomplete
          disableClearable
          clearOnEscape
          className="flex-grow-1 ml-1 custom-field-filter__autocomplete"
          options={options}
          onChange={handleChangeOption}
          componentsProps={{
            paper: {
              sx: {
                width: props.optionPaperWidth
              }
            }
          }}
          getOptionLabel={(option) => option.value || ''}
          filterOptions={identity}
          renderInput={(params) => <TextField placeholder={t('custom-fields#value')} {...params} />}
          renderOption={(props, option) => (
            <li {...props}>
              <Grid container>
                <Grid item xs={12}>
                  <div className="d-flex align-items-center">
                    <Typography variant="button" color="textPrimary" title={option.value}>
                      {DecoratorConstants.truncateLeftStringDecorator(option.value, maxCharacterTruncate)}
                    </Typography>
                  </div>
                </Grid>
              </Grid>
            </li>
          )}
          inputValue={searchOptionValue}
          value={selectedOption}
          onInputChange={inputOption}
          disabled={selectedDefinition === null}
        />
        {
          selectedDefinition === null || selectedOption === null ?
            <Button
              title={t('add')}
              className="ml-1 custom-field-filter__icon-disabled"
              disabled
            >
              <IconConfirm />
            </Button> :
            <Button
              title={t('add')}
              className="ml-1 custom-field-filter__icon"
              onClick={saveSelectedValue}
            >
              <IconConfirm />
            </Button>
        }
        <Button
          title={t('cancel')}
          className="ml-1 custom-field-filter__icon"
          onClick={toggleOpenAddNewComponent}
        >
          <IconXmark />
        </Button>
      </InputGroup>
    </>
  );
}

CustomFieldAddNew.propTypes = {
  keyPaperWidth: PropTypes.number,
  optionPaperWidth: PropTypes.number,
  maxCharacterTruncate: PropTypes.number,
};

CustomFieldAddNew.defaultProps = {
  keyPaperWidth: 380,
  optionPaperWidth: 250,
  maxCharacterTruncate: DEFAULT_TRUNCATE_MAX_LENGTH,
};
